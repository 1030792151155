import type { Nullable } from '../../types/utility';

export const SCENE_READY = 'scene.ready';
export const SCENE_DASHBOARD = 'scene.dashboard';
export const TRANSIENT = 'transient';
type StateMachineStatus = typeof TRANSIENT | typeof SCENE_READY | typeof SCENE_DASHBOARD;

export type DeploymentName = 'STANDALONE' | 'WORKSPACE' | 'DESKTOP' | 'STANDALONE_EXPLORE';

export interface DeploymentConfiguration {
  deployment: DeploymentName;
  allowChooseDatabase: boolean;
  allowSettings: boolean;
  allowAbout: boolean;
  allowHelpAndLearn: boolean;
  allowLogout: boolean;
  allowSearchTimeout: boolean;
  allowLogoutTimeout: boolean;
  allowLoggedUser: boolean;
  allowTrakingConsentPopup: boolean;
  enableCanny: boolean;
  setPerspectiveAndSceneOnDatabaseChange: boolean;
  unsetPerspectiveOnDisconnect: boolean;
}

type LicenseResult =
  | {
      status: 'valid' | 'expired';
      daysLeft: number;
    }
  | {
      status: 'noplugin' | 'missing' | 'invalid';
    };

export interface AppState extends DeploymentConfiguration {
  watermark: Nullable<string>;
  showEmptyState: boolean;
  initialisationCompleted: boolean;
  deployment: DeploymentName;
  isCaseInsensitiveAvailable: boolean;
  license: LicenseResult;
  stateMachineStatus: StateMachineStatus;
  buttonArrayBottomPosition: Nullable<number>;
}
