import { DATA_API_AUTH_PROVIDER_TYPE } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import * as yup from 'yup';

const alphanumericRegex = /^[a-zA-Z0-9][a-zA-Z0-9-.]+[a-zA-Z0-9]$/;
const alphanumericNameText =
  'Must be 3-60 character, contain only alphanumeric characters, hyphen or dot and start/finish with an alphanumeric character.';

const schemesAllowedOrigins = ['http', 'https', 'ftp', 'ws', 'wss', 'gopher'];

export const createSchema = yup.object({
  name: yup
    .string()
    .min(3)
    .max(60)
    .matches(alphanumericRegex, alphanumericNameText)
    .required('A name for the Data API must be provided'),
  instanceId: yup.string().required('An instance must be selected'),
  instanceUsername: yup
    .string()
    .min(3)
    .max(60)
    .matches(alphanumericRegex, alphanumericNameText)
    .required('A username must be provided'),
  instancePassword: yup.string().required('A password must be provided'),
  typeDefinitions: yup.string().required('Type definitions must be provided'),
  featuresSubgraph: yup.bool(),
  authProviders: yup
    .array()
    .of(
      yup.object({
        name: yup
          .string()
          .min(3)
          .max(60)
          .matches(alphanumericRegex, alphanumericNameText)
          .required('A name must be provided'),
        url: yup
          .string()
          .url('The URL must be valid')
          .test('conditional-url', 'The URL must start with https://', (value, context) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const { type } = context.parent;
            if (type === DATA_API_AUTH_PROVIDER_TYPE.jwks) {
              return (isNotNullish(value) && value.startsWith('https://')) || false;
            }
            return true;
          }),
        enabled: yup.bool(),
        type: yup.mixed().oneOf(Object.values(DATA_API_AUTH_PROVIDER_TYPE)),
      }),
    )
    .test(
      'at-least-one-auth-provider-enabled',
      'At least one Authentication provider must be enabled',
      (authProviders) => authProviders?.some((provider) => provider.enabled) ?? false,
    ),
  allowedOrigins: yup
    .array()
    .of(
      yup
        .string()
        .required('Each origin is required')
        .min(1, 'Each origin must be specified')
        .max(2048, 'Each origin must be at most 2048 characters long')
        .test(
          'url-with-valid-scheme',
          'Each origin must be a valid URL',
          (value) =>
            schemesAllowedOrigins.some((scheme) => value.startsWith(scheme)) ||
            // eslint-disable-next-line no-sync
            yup.string().url().isValidSync(value),
        ),
    )
    .max(100, 'You can only specify up to 100 origins'),
});

export const updateSchema = yup.object({
  name: yup.string().min(3).max(60).matches(alphanumericRegex, alphanumericNameText).optional(),
  instanceId: yup.string().optional(),
  typeDefinitions: yup.string().optional(),
  featuresSubgraph: yup.bool().optional(),
  authProviders: yup
    .array()
    .of(
      yup.object({
        name: yup.string().min(3).max(60).matches(alphanumericRegex, alphanumericNameText).optional(),
        url: yup
          .string()
          .url('The URL must be valid')
          .test('conditional-url', 'The URL must start with https://', (value, context) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const { type } = context.parent;
            if (type === DATA_API_AUTH_PROVIDER_TYPE.jwks) {
              return (isNotNullish(value) && value.startsWith('https://')) || false;
            }
            return true;
          })
          .optional(),
        enabled: yup.bool().optional(),
      }),
    )
    .test(
      'at-least-one-auth-provider-enabled',
      'At least one Authentication provider must be enabled',
      (authProviders) => authProviders?.some((provider) => provider.enabled) ?? false,
    ),
  allowedOrigins: yup
    .array()
    .of(
      yup
        .string()
        .required('Each origin is required')
        .min(1, 'Each origin must be specified')
        .max(2048, 'Each origin must be at most 2048 characters long')
        .test(
          'url-with-valid-scheme',
          'Each origin must be a valid URL',
          (value) =>
            schemesAllowedOrigins.some((scheme) => value.startsWith(scheme)) ||
            // eslint-disable-next-line no-sync
            yup.string().url().isValidSync(value),
        ),
    )
    .max(100, 'You can only specify up to 100 origins')
    .optional(),
});
