/* eslint-disable @typescript-eslint/no-dynamic-delete */
import type { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { cloneDeep, isNil, mapValues } from 'lodash-es';

import type { Range } from '../../modules/Slicer/types';
import { REHYDRATE } from '../persistence/constants';
import type { RootState } from '../types';
import type { SlicerState } from './types';

export const NAME = 'slicer';

export const initialState: SlicerState = {
  isSlicerOpen: false,
  ranges: [],
  nodePositions: [],
  slicerInvisibleNodesIds: {},
  slicerInvisibleRelsIds: {},
};

// enableMapSet plugin in redux-toolkit v1 is not working correctly with vitest running tests in parallel,
// so having Sets in redux fails the tests
// futhermore using Sets in redux is discouraged
// https://redux.js.org/style-guide/#do-not-put-non-serializable-values-in-state-or-actions
const arrToSet = (record: Record<string, string[]>): Record<string, Set<string>> =>
  mapValues(record, (value) => new Set(value));
const setToArr = (record: Record<string, Set<string>>): Record<string, string[]> =>
  mapValues(record, (value) => Array.from(value));

export const getIsSlicerOpen = (state: RootState) => state[NAME].isSlicerOpen;
export const getRanges = (state: RootState): SlicerState['ranges'] => state[NAME].ranges;
export const getActiveRanges = createSelector(
  (state: RootState) => getRanges(state),
  (ranges) => ranges.filter((range) => range.isActive).map((range) => range.id),
);
export const getRangeById = createSelector(
  (state: RootState) => getRanges(state),
  (ranges) => (id: Range['id']) => ranges.find((range) => range.id === id),
);
export const getSlicerNodePositions = (state: RootState): SlicerState['nodePositions'] => state[NAME].nodePositions;
export const getSlicerInvisibleNodesIds = createSelector(
  (state: RootState) => state[NAME].slicerInvisibleNodesIds,
  arrToSet,
);
export const getSlicerInvisibleRelsIds = createSelector(
  (state: RootState) => state[NAME].slicerInvisibleRelsIds,
  arrToSet,
);

const slicerSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setIsSlicerOpen(state, action: PayloadAction<boolean>) {
      state.isSlicerOpen = action.payload;
    },
    toggleSlicer(state) {
      state.isSlicerOpen = !state.isSlicerOpen;
    },
    deactivateAllRanges(state) {
      state.ranges = cloneDeep(state.ranges);
      state.ranges.forEach((range) => {
        range.isActive = false;
      });
      state.slicerInvisibleNodesIds = {};
      state.slicerInvisibleRelsIds = {};
    },
    addRange(state, action: PayloadAction<Range>) {
      state.ranges = [...state.ranges, action.payload];
    },
    deleteRange(state, action: PayloadAction<Range['id']>) {
      state.ranges = state.ranges.filter((range) => range.id !== action.payload);
    },
    activateRange(state, action: PayloadAction<Range['id']>) {
      const rangeId = action.payload;
      state.ranges = state.ranges.map((range) => {
        if (range.id === rangeId) {
          range.isActive = true;
        }
        return range;
      });
    },
    deactivateRange(state, action: PayloadAction<Range['id']>) {
      const rangeId = action.payload;
      state.ranges = state.ranges.map((range) => {
        if (range.id === rangeId) {
          range.isActive = false;
        }
        return range;
      });
      state.slicerInvisibleNodesIds = Object.keys(state.slicerInvisibleNodesIds).reduce<Record<string, string[]>>(
        (acc, slicerId) => {
          const arr = Array.from(state.slicerInvisibleNodesIds[slicerId] ?? []).filter((id) => id !== rangeId);
          if (arr.length > 0) {
            acc[slicerId] = arr;
          }
          return acc;
        },
        {},
      );
      state.slicerInvisibleRelsIds = Object.keys(state.slicerInvisibleRelsIds).reduce<Record<string, string[]>>(
        (acc, slicerId) => {
          const arr = Array.from(state.slicerInvisibleRelsIds[slicerId] ?? []).filter((id) => id !== rangeId);
          if (arr.length > 0) {
            acc[slicerId] = arr;
          }
          return acc;
        },
        {},
      );
    },
    setPercentageCoverage(
      state,
      action: PayloadAction<{ rangeId: Range['id']; coverage: Range['percentageCoverage'] }>,
    ) {
      state.ranges = state.ranges.map((range) => {
        if (range.id === action.payload.rangeId) {
          range.percentageCoverage = action.payload.coverage;
        }
        return range;
      });
    },
    setSlicerNodesPositions(state, action: PayloadAction<SlicerState['nodePositions']>) {
      state.nodePositions = action.payload;
    },
    setSlicerInvisibleNodesIds(state, action: PayloadAction<string[] | number[]>) {
      state.slicerInvisibleNodesIds = {};
    },
    setSlicerInvisibleRelsIds(state, action: PayloadAction<string[] | number[]>) {
      state.slicerInvisibleRelsIds = {};
    },
    updateSlicerInvisibleNodesIds(state, action: PayloadAction<Record<string, Set<string>>>) {
      state.slicerInvisibleNodesIds = setToArr(action.payload);
    },
    updateSlicerInvisibleRelsIds(state, action: PayloadAction<Record<string, Set<string>>>) {
      state.slicerInvisibleRelsIds = setToArr(action.payload);
    },
    setSlicerRanges(state, action: PayloadAction<Range[]>) {
      state.ranges = action.payload ?? [];
    },
    updatePlaybackFrameWaitTime(state, action: PayloadAction<{ rangeId: Range['id']; playbackFrameWaitTime: number }>) {
      state.ranges = state.ranges.map((range) => {
        if (range.id === action.payload.rangeId) {
          range.playbackFrameWaitTime = action.payload.playbackFrameWaitTime;
        }
        return range;
      });
    },
    updatePlaybackMode(state, action: PayloadAction<{ rangeId: Range['id']; playbackMode: Range['playbackMode'] }>) {
      state.ranges = state.ranges.map((range) => {
        if (range.id === action.payload.rangeId) {
          range.playbackMode = action.payload.playbackMode;
        }
        return range;
      });
    },
    updateTimezoneTranslation(
      state,
      action: PayloadAction<{ rangeId: Range['id']; timezoneTranslation: Range['timezoneTranslation'] }>,
    ) {
      state.ranges = state.ranges.map((range) => {
        if (range.id === action.payload.rangeId) {
          range.timezoneTranslation = action.payload.timezoneTranslation;
        }
        return range;
      });
    },
    updateTimezoneTranslationEnabled(
      state,
      action: PayloadAction<{ rangeId: Range['id']; timezoneTranslationEnabled: Range['timezoneTranslationEnabled'] }>,
    ) {
      state.ranges = state.ranges.map((range) => {
        if (range.id === action.payload.rangeId) {
          range.timezoneTranslationEnabled = action.payload.timezoneTranslationEnabled;
        }
        return range;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action: AnyAction) => {
      // the action.payload contains the state of the whole app, the RootState
      if (isNil(action.payload) || isNil(action.payload[NAME])) {
        return state;
      }

      return {
        ...state,
        ...action.payload[NAME],
      };
    });
  },
});

export const {
  setIsSlicerOpen,
  toggleSlicer,
  deactivateAllRanges,
  addRange,
  deleteRange,
  activateRange,
  deactivateRange,
  setPercentageCoverage,
  setSlicerNodesPositions,
  setSlicerInvisibleNodesIds,
  updateSlicerInvisibleNodesIds,
  setSlicerInvisibleRelsIds,
  updateSlicerInvisibleRelsIds,
  setSlicerRanges,
  updatePlaybackFrameWaitTime,
  updatePlaybackMode,
  updateTimezoneTranslation,
  updateTimezoneTranslationEnabled,
} = slicerSlice.actions;

export default slicerSlice.reducer;
