import type { QueryResultWithLimit } from '@nx/constants';
import type { RequestId } from '@query/redux/requests-slice';

type CypherQueryResults = Record<string, QueryResultWithLimit>;

const cypherQueryResults: CypherQueryResults = {};

export const cacheQueryResult = (requestId: RequestId, queryResult: QueryResultWithLimit) =>
  (cypherQueryResults[requestId] = queryResult);

export const getQueryResult = (requestId: RequestId) => cypherQueryResults[requestId];

export const clearQueryResult = (requestId: RequestId) => delete cypherQueryResults[requestId];
