import type { LocalInstanceInstalledPlugin, LocalInstancePluginInstallArgs } from '../relate.types';
import type { Builder } from './types';

export const pluginEndpoints = (builder: Builder) => ({
  listInstalledPlugins: builder.query<LocalInstanceInstalledPlugin[], string>({
    query: (id) => ({ url: 'list-dbms-plugins', params: { dbmsId: id } }),
    providesTags: ['LocalInstancePlugins'],
  }),

  installPlugin: builder.mutation<string, LocalInstancePluginInstallArgs>({
    query: (args) => ({
      url: 'install-dbms-plugin',
      method: 'POST',
      body: args,
    }),
    invalidatesTags: ['LocalInstancePlugins'],
  }),

  uninstallPlugin: builder.mutation<string, LocalInstancePluginInstallArgs>({
    query: (args) => ({
      url: 'uninstall-dbms-plugin',
      method: 'POST',
      body: args,
    }),
    invalidatesTags: ['LocalInstancePlugins'],
  }),
});
