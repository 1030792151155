import { isNullish } from '@nx/stdlib';
import { produce } from 'immer';

import type { ArrowGraph, SerialisableArrowGraphState } from '../deprecated-types/arrows-types';
import type { DeprecatedDataModel } from './deprecated/types';

// Clean bad data from data model.
export const eliminateInvalidDataFromDataModel_0_0_1 = (dataModel: DeprecatedDataModel['0.0.1']) => {
  return produce(dataModel, (draft) => {
    Object.values(draft.graphModel.nodeSchemas).forEach((nodeSchema) => {
      nodeSchema.key.properties = nodeSchema.key.properties.filter(
        (keyPropertyIndex) => keyPropertyIndex >= 0 && keyPropertyIndex < nodeSchema.properties.length,
      );
    });
  });
};

// Cleanup function for when the old data model is out of sync, not needed for the latest data model (1.0.0)
// ref: https://github.com/neo4j/upx/pull/576
export const alignRelationshipDirectionsInArrowsByDataModel = <T extends keyof DeprecatedDataModel>(
  dataModel: DeprecatedDataModel[T],
  arrowGraph: ArrowGraph | SerialisableArrowGraphState,
) => {
  return produce(arrowGraph, (draft) => {
    if ('graphModel' in dataModel) {
      const { graphModel } = dataModel;
      const { relationshipSchemas } = graphModel;
      // If two graphs are un-synced, stop aligning.
      // This usually happens when dispatching two actions - one for data model and one for arrows.
      if (
        Object.keys(relationshipSchemas).length === 0 ||
        Object.keys(relationshipSchemas).length !== arrowGraph.relationships.length
      ) {
        return;
      }
      for (const arrowRelationship of draft.relationships) {
        // Assume relationshipSchema can be always found in the data model for logic simplicity.
        // Can be extended by adding missing relationship schema if we have bad data in the future.
        const relationshipSchema = relationshipSchemas[arrowRelationship.id];
        if (!isNullish(relationshipSchema)) {
          arrowRelationship.fromId = relationshipSchema.sourceNodeSchema;
          arrowRelationship.toId = relationshipSchema.targetNodeSchema;
        }
      }
    }
  });
};
