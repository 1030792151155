import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash-es';

import { runDeepLinkThunk } from '../core/deeplink.thunk';
import { runSearchThunk } from '../core/search-core.thunks';
import { fetchPropertyValueSuggestionsThunk } from './property-value-suggestions/property-value-suggestions.thunk';
import { fetchSearchPhraseSuggestionsThunk } from './search-phrase-suggestions/search-phrase-suggestions.thunk';
import { NAME } from './search-suggestions.const';
import { fetchSuggestionsThunk } from './search-suggestions.thunk';
import type { SuggestionsState } from './search-suggestions.types';
import { fetchStructuredSuggestionsThunk } from './structured-suggestions/structured-suggestions.thunk';

export const initialState: SuggestionsState = {
  suggestions: [],
  hasSearchPhraseSuggestionFilter: false,
  locked: false,
};

const asyncThunks = [fetchSuggestionsThunk, runSearchThunk] as const;

const suggestionsThunks = [
  fetchPropertyValueSuggestionsThunk,
  fetchSearchPhraseSuggestionsThunk,
  fetchStructuredSuggestionsThunk,
] as const;

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    clearSuggestions: (state) => {
      state.suggestions = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSearchPhraseSuggestionsThunk.pending, (state) => {
      state.hasSearchPhraseSuggestionFilter = false;
    });
    builder.addCase(fetchSearchPhraseSuggestionsThunk.fulfilled, (state, action) => {
      state.hasSearchPhraseSuggestionFilter = !isEmpty(action.payload);
    });
    builder.addCase(runDeepLinkThunk.pending, (state) => {
      state.locked = true;
    });
    builder.addCase(runDeepLinkThunk.fulfilled, (state) => {
      state.locked = false;
    });
    builder.addCase(runDeepLinkThunk.rejected, (state) => {
      state.locked = false;
    });
    builder.addMatcher(isAnyOf(...asyncThunks.map((thunk) => thunk.pending)), (state, action) => {
      if (!state.locked) {
        state.suggestions = [];
        state.hasSearchPhraseSuggestionFilter = false;
      }
    });
    builder.addMatcher(isAnyOf(...suggestionsThunks.map((thunk) => thunk.fulfilled)), (state, action) => {
      if (!state.locked) {
        state.suggestions = [...state.suggestions, ...action.payload];
      }
    });
  },
});

export default slice.reducer;

export const { clearSuggestions } = slice.actions;
