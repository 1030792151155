import cn from 'classnames';

import amex from './amex.png';
import diners from './dinersclub.png';
import discover from './discover.png';
import jcb from './jcb.png';
import mastercard from './mastercard.png';
import unionpay from './unionpay.png';
import unknown from './unknown.png';
import visa from './visa.png';

const getBrand = (brand: string) => {
  /* Supported brands from https://stripe.com/docs/api/cards/object#card_object-brand */

  let brandName = brand.toLowerCase().split(' ').join('-');

  let logo;

  switch (brandName) {
    case 'american-express':
      logo = amex;
      break;
    case 'diners-club':
      logo = diners;
      break;
    case 'discover':
      logo = discover;
      break;
    case 'jcb':
      logo = jcb;
      break;
    case 'mastercard':
      logo = mastercard;
      break;
    case 'unionpay':
      logo = unionpay;
      break;
    case 'visa':
      logo = visa;
      break;
    default:
      logo = unknown;
      brandName = 'unknown';
      break;
  }

  return {
    logo,
    brandName,
  };
};

export function CardBrand({ brand = 'unknown', className }: { brand?: string; className?: string }) {
  const { logo, brandName } = getBrand(brand);
  return (
    <div
      className={cn('align-middle', className)}
      style={{ height: '1.3em', width: '2em' }}
      data-testid={`card-${brandName}`}
    >
      <img className="h-full w-full" src={logo} />
    </div>
  );
}
