/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable @typescript-eslint/no-unsafe-member-access  */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable func-names */
import { IDP_TYPE, getApiError, getErrorMessage, isErrorWithMessage, isFetchBaseQueryError } from '@nx/state';
import { isNonEmptyString, isNotNullish } from '@nx/stdlib';
import * as yup from 'yup';

import type { Validation } from '../../utils/validation';
import { validateYup } from '../../utils/validation';
import type { SsoConfigFormData } from './entities';

const uriErrorMessage =
  'Either a Discovery URI or a combination of Issuer, Authorization Endpoint, Token Endpoint and JWKS URI is required.';

const isNotNullishNorEmpty = (value: unknown) => {
  return isNotNullish(value) && isNonEmptyString(value);
};

const ssoConfigSchema = yup.object().shape({
  displayName: yup.string().required().min(3).max(128).label('Display name'),
  projectLoginMethod: yup
    .boolean()
    .nullable()
    .test(
      'projectLoginMethod-and-linkedProjects',
      'Projects are required for a project based login method',
      function (value) {
        const { linkedProjects } = this.parent;
        if (value === true && !(isNotNullish(linkedProjects) && linkedProjects.length > 0)) {
          return false;
        }
        return true;
      },
    )
    .label('Project Login Method'),
  idpType: yup.string().oneOf(Object.values(IDP_TYPE)).required().label('Identity Provider (IdP)'),
  discoveryURI: yup
    .string()
    .url()
    .nullable()
    .test('discoveryURI-or-authorizationEndpoint', uriErrorMessage, function (value) {
      const { issuer, authorizationEndpoint, tokenEndpoint, jwksURI } = this.parent;
      if (
        isNotNullishNorEmpty(value) ||
        (isNotNullishNorEmpty(issuer) &&
          isNotNullishNorEmpty(authorizationEndpoint) &&
          isNotNullishNorEmpty(tokenEndpoint) &&
          isNotNullishNorEmpty(jwksURI))
      ) {
        return true;
      }
      return false;
    })
    .label('Discovery URI'),
  issuer: yup
    .string()
    .nullable()
    .test('discoveryURI-or-authorizationEndpoint', uriErrorMessage, function (value) {
      const { discoveryURI } = this.parent;
      return discoveryURI ?? value;
    })
    .label('Issuer'),
  authorizationEndpoint: yup
    .string()
    .url()
    .nullable()
    .test('discoveryURI-or-authorizationEndpoint', uriErrorMessage, function (value) {
      const { discoveryURI } = this.parent;
      return discoveryURI ?? value;
    })
    .label('Authorization Endpoint'),
  tokenEndpoint: yup
    .string()
    .url()
    .nullable()
    .test('discoveryURI-or-tokenEndpoint', uriErrorMessage, function (value) {
      const { discoveryURI } = this.parent;
      return discoveryURI ?? value;
    })
    .label('Token Endpoint'),
  jwksURI: yup
    .string()
    .url()
    .nullable()
    .test('discoveryURI-or-jwksURI', uriErrorMessage, function (value) {
      const { discoveryURI } = this.parent;
      return discoveryURI ?? value;
    })
    .label('JWKS URI'),
  clientId: yup.string().required().label('Client ID'),
  needsSecret: yup.boolean(),
  clientSecret: yup.string().when('needsSecret', {
    is: true,
    then: function (rule) {
      return rule.required().label('Client Secret');
    },
  }),
  roleMapping: yup.string().nullable().label('Role Mapping'),
});

const validateSsoConfig = (data: SsoConfigFormData): Validation<SsoConfigFormData> | null => {
  return validateYup(ssoConfigSchema, data);
};

const getErrorMessageFromUnknown = (err: unknown) => {
  let message =
    'Our system is experiencing problems right now. Try again later or contact support if the problem persists.';
  if (isFetchBaseQueryError(err)) {
    const apiError = getApiError(err);
    message = getErrorMessage(apiError);
  } else if (isErrorWithMessage(err)) {
    message = err.message;
  }

  return message;
};

export { ssoConfigSchema, validateSsoConfig, getErrorMessageFromUnknown };
