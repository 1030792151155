declare global {
  // eslint-disable-next-line no-var
  var neo4jWorkspaceDesktop: {
    credentialsManager: {
      get: (id: string) => Promise<{ username: string; password: string }>;
      delete: (id: string) => Promise<void>;
    };
  };
}

export enum APP_SCOPE {
  desktop = 'desktop',
  query = 'query',
  conversations = 'conversations',
  import = 'import',
  explore = 'explore',
  ops = 'ops',
  dashboards = 'dashboards',
  debugger = 'debugger',
  framework = 'framework',
  guides = 'guides',
  aura = 'aura',
  test = 'test',
  dataApi = 'dataApi',
}

export const isAppScope = (value: string | null | undefined): value is APP_SCOPE => {
  return typeof value === 'string' && value in APP_SCOPE;
};
