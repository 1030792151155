import { Banner, TextLink } from '@neo4j-ndl/react';

import gcpLogo from '../assets/gcp.svg';

export interface GCPBillingMessageProps {
  googleProjectId: string;
  className?: string;
}

export const GCPBillingMessage = ({ googleProjectId, className }: GCPBillingMessageProps) => {
  const title = (
    <div className="flex items-center gap-2">
      <img src={gcpLogo} className="h-6 w-6" />
      <span>
        Managed by Google Project{' '}
        <TextLink href={`https://console.cloud.google.com/home/dashboard?project=${googleProjectId}`} isExternalLink>
          {googleProjectId}
        </TextLink>
      </span>
      .
    </div>
  );

  return (
    <Banner
      type="info"
      className={className}
      description={title}
      usage="inline"
      name={`Managed by Google Project ${googleProjectId}`}
      htmlAttributes={{
        'data-testid': 'gcp-billing-message',
      }}
    />
  );
};
