import { QueryStateContext } from '@query/redux/query-state-context';
import { store } from '@query/redux/store';
import type { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

const persistor = persistStore(store);

export function QueryProvider({ children }: { children: ReactNode }) {
  return (
    <Provider context={QueryStateContext} store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
}
