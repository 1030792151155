import { changePlaylist, changePlaylistsBaseUrl } from '@nx/state';

export function setupGuidePlaylist(guidesBaseUrl?: string) {
  const { searchParams } = new URL(window.location.href);
  const playlist = searchParams.get('playlist');

  if (typeof playlist === 'string') {
    changePlaylist(playlist);
  }

  if (guidesBaseUrl !== undefined) {
    changePlaylistsBaseUrl(guidesBaseUrl);
  }
}
