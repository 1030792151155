type AbortFunctions = Record<string, () => void>;

const abortFunctions: AbortFunctions = {};

export const registerAbortFunction = (requestId: string, abortFunction: () => void) =>
  (abortFunctions[requestId] = abortFunction);

export const unregisterAbortFunction = (requestId: string) => delete abortFunctions[requestId];

export const consumeAbortFunction = (requestId: string) => {
  const abortFunction = abortFunctions[requestId];
  unregisterAbortFunction(requestId);
  return abortFunction;
};
