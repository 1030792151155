import { findProcedure } from '../../services/gds';
import { log } from '../../services/logging';
import { isFalsy } from '../../types/utility';
import { CREATE_PROJECTION, DELETE_PROJECTION } from './constants';
import type { Procedure } from './types';

export const countAndAppendStr = (stringName: string, stringList: string[] = []) => {
  if (!stringList.includes(stringName)) {
    return stringName;
  }
  let i = 1;
  while (stringList.includes(`${stringName} ${i}`)) {
    i++;
  }
  return `${stringName} ${i}`;
};

const isGDSProcedure = (procedure: string): procedure is Procedure => procedure.startsWith('gds.');

export const getGdsProcedures = (procedures: string[]): Procedure[] => procedures.filter(isGDSProcedure);
export const checkGdsAvailable = (gdsProcedures: Procedure[], gdsProcedureDescMap: Record<Procedure, string>) => {
  let gdsProceduresAvailable = true;
  const procedureUnrestrictedError =
    'is unavailable because it is sandboxed and has dependencies outside of the sandbox. Sandboxing is controlled by the dbms.security.procedures.unrestricted setting. Only unrestrict procedures you can trust with access to database internals.';

  if (gdsProcedures.length === 0) {
    log.info('No GDS procedures available - check plugin');
    gdsProceduresAvailable = false;
  } else if (isFalsy(findProcedure(CREATE_PROJECTION, gdsProcedures))) {
    log.info(`GDS ${CREATE_PROJECTION} procedure missing - check GDS version`);
    gdsProceduresAvailable = false;
  } else if (isFalsy(findProcedure(DELETE_PROJECTION, gdsProcedures))) {
    log.info(`GDS ${DELETE_PROJECTION} procedure missing - check GDS version`);
    gdsProceduresAvailable = false;
  } else if (Object.values(gdsProcedureDescMap).some((desc) => desc.includes(procedureUnrestrictedError))) {
    log.info(
      `Configuration issue detected: Graph Data Science plugin requires permission to run procedures in unrestricted mode, which can be configured in the neo4j.conf file`,
    );
    gdsProceduresAvailable = false;
  }
  return gdsProceduresAvailable;
};
