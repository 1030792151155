import type { JSONSchemaType } from 'ajv';

import type { BaseArguments } from '../types/sdk-types';

export type SchemaMetadata = {
  labels: string[];
  relationshipTypes: string[];
  propertyKeys: string[];
};

export async function getSchemaMetadata({ queryCypher }: BaseArguments): Promise<SchemaMetadata> {
  const ITEM_LIMIT = 1000;
  const query = `CALL db.labels() YIELD label
RETURN COLLECT(label)[..${ITEM_LIMIT}] AS result
UNION ALL
CALL db.relationshipTypes() YIELD relationshipType
RETURN COLLECT(relationshipType)[..${ITEM_LIMIT}] AS result
UNION ALL
CALL db.propertyKeys() YIELD propertyKey
RETURN COLLECT(propertyKey)[..${ITEM_LIMIT}] AS result`;

  const res = await queryCypher(query);

  // Types are validated in e2e tests
  // eslint-disable-next-line
  const [labels, relationshipTypes, propertyKeys] = res.records.map((r) => r.toObject().result) as [
    string[],
    string[],
    string[],
  ];

  return { labels, relationshipTypes, propertyKeys };
}

export const schemaMetadataSchema: JSONSchemaType<SchemaMetadata> = {
  type: 'object',
  properties: {
    propertyKeys: { type: 'array', items: { type: 'string' } },
    labels: { type: 'array', items: { type: 'string' } },
    relationshipTypes: { type: 'array', items: { type: 'string' } },
  },
  required: ['propertyKeys', 'labels', 'relationshipTypes'],
};
