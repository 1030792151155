import type { OmitStrict } from '@nx/stdlib';

import type { PerspectiveMetadataState as PerspectiveMetadata } from '../state/perspectives/perspectiveMetadata.types';
import type { Palette, StyleCategory, StyleRelationshipType } from './style';
import type { Template } from './template';
import type { Nullable } from './utility';

export interface SceneAction {
  name: string;
  id: string;
  createdAt: number;
  cypher: string;
  isUpdateQuery: Nullable<boolean>;
  isWriteTransactionChecked: Nullable<boolean>;
  categories: Nullable<number[]>;
  relationshipTypes: Nullable<string[]>;
  hasCypherErrors: boolean;
}

export interface CategoryPropertyKey {
  name: string;
  dataType: string;
  exclude: boolean;
}

export interface GeneralPropertyKey {
  dataType: string;
  propertyKey: string;
  type: string;
}

export interface MetadataPropertyKey {
  dataType: string;
  propertyKey: string;
}

export interface TransformedSearchCategory {
  id: number;
  name: string;
  labels: string[];
  properties: GeneralPropertyKey[];
}

export interface PerspectiveCategory {
  createdAt?: number;
  lastEditedAt?: number;
  id: number;
  name: string;
  labels: string[];
  properties: CategoryPropertyKey[];
}

export interface PerspectiveRelationshipType {
  id: string;
  name: string;
  properties: GeneralPropertyKey[];
}

export interface PathSegment {
  source: string;
  relationshipType: string;
  target: string;
}

export type TransformedPerspectivePropertyKeys = {
  labels: Record<string, GeneralPropertyKey[]>;
  relationships: Record<string, GeneralPropertyKey[]>;
};

export type TransformedPerspectiveIndex = {
  label: string;
  propertyKeys: string[];
};

// this is the transformed metadata from 'transformMetadata' in metaTransformer.js
export interface TransformedPerspectiveMetadata {
  categories: TransformedSearchCategory[];
  indexes: TransformedPerspectiveIndex[];
  labels: string[];
  pathSegments: PathSegment[];
  propertyKeys: TransformedPerspectivePropertyKeys;
  relationshipTypes: string[];
  searchPhrases: Template[];
}

export enum PerspectiveType {
  STANDARD = 'STANDARD_PERSPECTIVE',
  AUTO = 'AUTO_PERSPECTIVE',
  DEFAULT = 'DEFAULT_PERSPECTIVE',
}

export interface PerspectiveHistoryEntry {
  timestamp: number;
  userId: string;
}

export interface Perspective {
  categories: PerspectiveCategory[];
  createdAt: number;
  dbId: Nullable<string>;
  dbName: Nullable<string>;
  dbmsId: Nullable<string>;
  dbmsVersion: Nullable<string>;
  hiddenRelationshipTypes?: string[];
  hideUncategorisedData?: boolean;
  history?: PerspectiveHistoryEntry[];
  id: string;
  isPlugin?: boolean;
  labels: Record<string, GeneralPropertyKey[]>;
  lastEditedAt: number;
  metadata: PerspectiveMetadata;
  name: string;
  palette: Palette;
  parentPerspectiveId: Nullable<string>;
  relationshipTypes: PerspectiveRelationshipType[];
  sceneActions: SceneAction[];
  sha?: string;
  templates: Template[];
  type: PerspectiveType;
  version?: string;
}

export type CategoryWithStyle = PerspectiveCategory & StyleCategory;
export type RelationshipTypeWithStyle = PerspectiveRelationshipType & StyleRelationshipType;

export type CategoryWithUndefinedId = OmitStrict<PerspectiveCategory, 'id'> & { id: undefined };

export interface PerspectiveWithStyle extends Perspective {
  categories: CategoryWithStyle[];
  relationshipTypes: RelationshipTypeWithStyle[];
}
