import { IconButton, Menu } from '@neo4j-ndl/react';
import {
  ChartBarIconOutline,
  DocumentTextIconOutline,
  EllipsisHorizontalIconOutline,
  ExpandIcon,
  FitToScreenIcon,
  InformationCircleIconOutline,
} from '@neo4j-ndl/react/icons';
import type { ComponentProps } from 'react';

import ActionableWithMenu from '../../../shared/components/actionable-with-menu';

export type ChartOption = 'zoom' | 'expand' | 'info' | 'logs' | 'metricsIntegration' | 'auraLogs';
type ItemProps = ComponentProps<typeof Menu.Item>;
type OptionsStaticProps = ({ key: ChartOption } & ItemProps)[];
type OptionsRuntimeProps = {
  [key in ChartOption]?: Partial<ItemProps> | null | false;
};

/** also defines options order inside menu */
export const OPTIONS_STATIC_PROPS: OptionsStaticProps = [
  {
    key: 'info',
    icon: <InformationCircleIconOutline />,
    title: 'More info',
    htmlAttributes: { id: 'more-info' },
  },
  {
    key: 'expand',
    icon: <ExpandIcon />,
    title: 'Expand chart',
    htmlAttributes: { id: 'more-info' },
  },
  {
    key: 'zoom',
    icon: <FitToScreenIcon />,
    title: 'Reset zoom',
    description: 'Double click any chart',
    htmlAttributes: { id: 'reset-zoom' },
  },
  {
    key: 'logs',
    icon: <DocumentTextIconOutline />,
    title: 'Request logs',
    htmlAttributes: { id: 'request-log' },
  },
  {
    key: 'metricsIntegration',
    icon: <ChartBarIconOutline />,
    title: 'Metrics Integration',
    htmlAttributes: { id: 'metrics-integration' },
  },
  {
    key: 'auraLogs',
    icon: <DocumentTextIconOutline />,
    title: 'Explore query logs',
    htmlAttributes: { id: 'aura-logs' },
  },
];

/** merges `optionsRuntimeProps` with `OPTIONS_STATIC_PROPS`, omitting falsy/missing runtime options */
export const prepareActions = (optionsRuntimeProps: OptionsRuntimeProps): ItemProps[] =>
  OPTIONS_STATIC_PROPS.filter(({ key }) => Boolean(optionsRuntimeProps[key])).map((option) => ({
    ...option,
    ...optionsRuntimeProps[option.key],
  }));

export const ActionBar: React.FC<{
  outerActions?: ItemProps[];
  menuActions?: ItemProps[];
  isChartExpanded: boolean;
}> = ({ outerActions, menuActions, isChartExpanded }) => (
  <>
    {outerActions?.map((props) => (
      <IconButton
        isClean
        ariaLabel={`action-item-${props.htmlAttributes?.id}`}
        key={`action-item-${props.htmlAttributes?.id}`}
        onClick={(e) => {
          props.onClick?.(e);
        }}
        htmlAttributes={{
          'data-testid': `action-item-${props.htmlAttributes?.id}`,
        }}
      >
        {props.icon}
      </IconButton>
    ))}
    {menuActions && menuActions.length > 0 && (
      <ActionableWithMenu
        actionableType="IconButton"
        actionableProps={{
          isClean: !isChartExpanded,
          ariaLabel: 'Open chart menu',
          htmlAttributes: { 'data-testid': 'chart-menu-button' },
          children: <EllipsisHorizontalIconOutline />,
        }}
        menuProps={{}}
      >
        {({ onClose }) => (
          <Menu.Items className="z-modal mt-2" htmlAttributes={{ id: 'chart-menu' }}>
            {menuActions.map((props) => (
              <Menu.Item
                {...props}
                key={`menu-item-${props.htmlAttributes?.id}`}
                onClick={(e) => {
                  props.onClick?.(e);
                  onClose?.();
                }}
              />
            ))}
          </Menu.Items>
        )}
      </ActionableWithMenu>
    )}
  </>
);
