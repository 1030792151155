import { IconButton, Menu } from '@neo4j-ndl/react';
import { EllipsisHorizontalIconOutline } from '@neo4j-ndl/react/icons';
import type { Project } from '@nx/state';
import { ACTION, usePermissions } from '@nx/state';
import { useRef, useState } from 'react';

export enum MODAL_TYPE {
  INVITE = 'invite',
  RENAME = 'rename',
}

type ProjectActionMenuProps = {
  project: Project;
  type?: 'list' | 'table';
  onOpenModal: (modal: MODAL_TYPE | null) => void;
};

export const ProjectActionMenu = ({ project, onOpenModal, type = 'list' }: ProjectActionMenuProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const { isAllowed } = usePermissions();

  const allowUpdateProjectName = isAllowed(ACTION.UPDATE, `namespaces/${project.id}`);

  const handleClick = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  return (
    <>
      <IconButton
        ref={menuRef}
        ariaLabel="Project actions"
        onClick={handleClick}
        isClean
        size={type === 'list' ? 'medium' : 'small'}
      >
        <EllipsisHorizontalIconOutline className="h-full w-full" />
      </IconButton>
      <Menu
        isOpen={isMenuOpen}
        anchorRef={menuRef}
        onClose={handleClick}
        /* TODO: check if this is needed */
        /* anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        transformOriginOffsetFromAnchorOrigin={{ horizontal: 0, vertical: 4 }}*/
      >
        <Menu.Items htmlAttributes={{ onClick: (e) => e.stopPropagation() }}>
          <Menu.Item
            title="Rename"
            onClick={() => {
              onOpenModal(MODAL_TYPE.RENAME);
              setIsMenuOpen(false);
            }}
            isDisabled={!allowUpdateProjectName}
          />
        </Menu.Items>
      </Menu>
    </>
  );
};
