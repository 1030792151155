import { Banner, Button, Checkbox, Dialog, Radio, TextInput } from '@neo4j-ndl/react';
import type {
  Destination,
  EditLogForwarding,
  LogForwarding,
  WrappedCloudwatchDestination,
  WrappedLogAnalyticsDestination,
  WrappedStackdriverDestination,
} from '@nx/state';
import { DESTINATION_TYPE, LOG_TYPE } from '@nx/state';
import { isNonEmptyString } from '@nx/stdlib';
import React, { useState } from 'react';

interface Props<T extends Destination> {
  onEdit: (payload: EditLogForwarding) => void;
  onClose: () => void;
  open: boolean;
  loading: boolean;
  error: Error | null;
  logForwarding: LogForwarding<T>;
}

export const EditStackdriverConfigModal = ({
  onEdit,
  onClose,
  open,
  loading,
  error,
  logForwarding,
}: Props<WrappedStackdriverDestination>) => {
  const { destination } = logForwarding;
  const [name, setName] = useState(logForwarding.name);
  const [gcpProjectId, setGcpProjectId] = useState(destination.stackdriver.gcpProjectId);

  const [nameValidationError, setNameValidationError] = useState<string | null>();
  const validateName = (s: string): string | null => {
    if (!isNonEmptyString(s)) {
      return 'Choose a name.';
    }
    return null;
  };
  const handleNameBlur = () => {
    setName(name.trim());
    setNameValidationError(validateName(name));
  };
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setNameValidationError(validateName(name));
  };
  const handleGcpProjectIdChange = (e: React.ChangeEvent<HTMLInputElement>) => setGcpProjectId(e.target.value.trim());

  const handleEdit = () => {
    const payload: EditLogForwarding = {
      destination: {
        type: DESTINATION_TYPE.STACKDRIVER,
        stackdriver: {
          gcpProjectId: gcpProjectId,
        },
      },
      logType: LOG_TYPE.SECURITY,
      name: name,
    };
    onEdit(payload);
  };

  const isValidPayload = isNonEmptyString(gcpProjectId) && isNonEmptyString(name);

  return (
    <Dialog isOpen={open} onClose={onClose}>
      <Dialog.Header>Edit configuration</Dialog.Header>
      <Dialog.Content className="space-y-6">
        {error && (
          <Banner type="danger" usage="inline">
            {String(error.message)}
          </Banner>
        )}
        <TextInput
          label="Name"
          value={name}
          onChange={handleNameChange}
          isFluid
          errorText={nameValidationError}
          htmlAttributes={{
            placeholder: 'Configuration name',
            'data-testid': 'log-forwarding-name-input',
            onBlur: handleNameBlur,
          }}
        />
        <div>
          <p className="mb-2">Logs to forward:</p>
          <Radio
            isChecked
            label="Security logs"
            htmlAttributes={{
              readOnly: true,
            }}
          />
        </div>
        <TextInput
          label="Target GCP Project ID"
          value={gcpProjectId}
          onChange={handleGcpProjectIdChange}
          isFluid
          htmlAttributes={{
            placeholder: 'your-project-id',
            'data-testid': 'log-forwarding-gcp-project-id-input',
          }}
        />
      </Dialog.Content>
      <Dialog.Actions>
        <Button fill="outlined" color="neutral" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          isDisabled={!isValidPayload}
          onClick={handleEdit}
          isLoading={loading}
          htmlAttributes={{
            'data-testid': 'log-forwarding-edit',
          }}
        >
          Save changes
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export const EditLogAnalyticsConfigModal = ({
  onEdit,
  onClose,
  open,
  loading,
  error,
  logForwarding,
}: Props<WrappedLogAnalyticsDestination>) => {
  const { destination } = logForwarding;

  const [name, setName] = useState(logForwarding.name);
  const [editDestination, setEditDestination] = useState(false);
  const [azureWorkspaceId, setAzureWorkspaceId] = useState(destination.logAnalytics.customerId);
  const [azureSharedKey, setAzureSharedKey] = useState('');

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const trimName = () => setName((value) => value.trim());
  const handleAzureWorkspaceIdChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setAzureWorkspaceId(e.target.value.trim());

  const handleAzureSharedKeyChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setAzureSharedKey(e.target.value.trim());

  const handleEdit = () => {
    const payload: EditLogForwarding = {
      ...(editDestination && {
        destination: {
          type: DESTINATION_TYPE.LOG_ANALYTICS,
          logAnalytics: {
            customerId: azureWorkspaceId,
            sharedKey: azureSharedKey,
          },
        },
      }),
      logType: LOG_TYPE.SECURITY,
      name: name,
    };
    onEdit(payload);
  };

  const isValidPayload =
    name.trim() !== '' && editDestination ? azureWorkspaceId !== '' && azureSharedKey !== '' : true;
  return (
    <Dialog isOpen={open} onClose={onClose}>
      <Dialog.Header>Edit configuration</Dialog.Header>
      <Dialog.Content className="space-y-6">
        {error && (
          <Banner type="danger" usage="inline">
            {String(error.message)}
          </Banner>
        )}
        <TextInput
          label="Name"
          value={name}
          onChange={handleNameChange}
          isFluid
          htmlAttributes={{
            placeholder: 'Configuration name',
            'data-testid': 'log-forwarding-name-input',
            onBlur: trimName,
          }}
        />
        <div>
          <p className="mb-2">Logs to forward:</p>
          <Radio
            isChecked
            label="Security logs"
            htmlAttributes={{
              readOnly: true,
            }}
          />
        </div>
        <Checkbox
          isChecked={editDestination}
          onChange={(e) => setEditDestination(e.target.checked)}
          label="Edit Workspace ID"
          htmlAttributes={{
            'data-testid': 'log-forwarding-azure-edit-target',
          }}
        />
        {editDestination && (
          <>
            <TextInput
              label="Workspace ID"
              value={azureWorkspaceId}
              onChange={handleAzureWorkspaceIdChange}
              isFluid
              htmlAttributes={{
                placeholder: 'your-project-id',
                'data-testid': 'log-forwarding-azure-workspace-id-input',
              }}
            />
            <TextInput
              label="Primary Key"
              value={azureSharedKey}
              onChange={handleAzureSharedKeyChange}
              isFluid
              htmlAttributes={{
                placeholder: 'your-primary-key',
                'data-testid': 'log-forwarding-azure-shared-key-input',
              }}
            />
          </>
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Button fill="outlined" color="neutral" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          isDisabled={!isValidPayload}
          onClick={handleEdit}
          isLoading={loading}
          htmlAttributes={{
            'data-testid': 'log-forwarding-edit',
          }}
        >
          Save changes
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export const EditCloudwatchConfigModal = ({
  onEdit,
  onClose,
  open,
  loading,
  error,
  logForwarding,
}: Props<WrappedCloudwatchDestination>) => {
  const [name, setName] = useState(logForwarding.name);
  const [destination, setDestination] = useState(logForwarding.destination);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const trimName = () => setName((value) => value.trim());
  const handleGroupNameChange = ({ target: { value } }: { target: { value: string } }) =>
    setDestination((oldValue) => ({ ...oldValue, logGroupName: value }));

  const handleStreamNameChange = ({ target: { value } }: { target: { value: string } }) =>
    setDestination((oldValue) => ({ ...oldValue, logStreamName: value }));

  const handleAwsRegionChange = ({ target: { value } }: { target: { value: string } }) =>
    setDestination((oldValue) => ({ ...oldValue, region: value }));

  const handleRetentionDaysChange = ({ target: { value } }: { target: { value: string } }) =>
    setDestination((oldValue) => ({
      ...oldValue,
      logRetentionDays: value ? parseInt(value, 10) : 1,
    }));

  const handleLogArnChange = ({ target: { value } }: { target: { value: string } }) =>
    setDestination((oldValue) => ({ ...oldValue, logArn: value }));

  const handleEdit = () => {
    const payload: EditLogForwarding = {
      destination: destination,
      logType: LOG_TYPE.SECURITY,
      name: name,
    };
    onEdit(payload);
  };

  const isValidPayload =
    name.trim() !== '' &&
    destination.cloudwatch.logArn !== '' &&
    destination.cloudwatch.logGroupName !== '' &&
    destination.cloudwatch.logStreamName !== '' &&
    destination.cloudwatch.logRetentionDays > 0 &&
    destination.cloudwatch.region !== '';

  return (
    <Dialog isOpen={open} onClose={onClose}>
      <Dialog.Header>Edit configuration</Dialog.Header>
      <Dialog.Content className="space-y-6">
        {error && (
          <Banner type="danger" usage="inline">
            {String(error.message)}
          </Banner>
        )}
        <TextInput
          label="Name"
          value={name}
          onChange={handleNameChange}
          isFluid
          htmlAttributes={{
            placeholder: 'Configuration name',
            'data-testid': 'log-forwarding-name-input',
            onBlur: trimName,
          }}
        />
        <div>
          <p className="mb-2">Logs to forward:</p>
          <Radio
            isChecked
            label="Security logs"
            htmlAttributes={{
              readOnly: true,
            }}
          />
        </div>
        <TextInput
          label="Aws ARN"
          value={destination.cloudwatch.logArn}
          onChange={handleLogArnChange}
          isFluid
          htmlAttributes={{
            'data-testid': 'log-forwarding-aws-role-arn-input',
          }}
        />
        <TextInput
          label="Group name"
          value={destination.cloudwatch.logGroupName}
          onChange={handleGroupNameChange}
          isFluid
          htmlAttributes={{
            'data-testid': 'log-forwarding-aws-group-name-input',
          }}
        />
        <TextInput
          label="Stream name"
          value={destination.cloudwatch.logStreamName}
          onChange={handleStreamNameChange}
          isFluid
          htmlAttributes={{
            'data-testid': 'log-forwarding-aws-stream-name-input',
          }}
        />
        <TextInput
          label="Retention days"
          value={destination.cloudwatch.logRetentionDays}
          onChange={handleRetentionDaysChange}
          errorText={destination.cloudwatch.logRetentionDays <= 0 ? 'Retention days must be larger than 0' : ''}
          isFluid
          htmlAttributes={{
            min: 1,
            'data-testid': 'log-forwarding-aws-retention-days-input',
            type: 'number',
          }}
        />
        <TextInput
          label="AWS region"
          value={destination.cloudwatch.region}
          onChange={handleAwsRegionChange}
          isFluid
          htmlAttributes={{
            'data-testid': 'log-forwarding-aws-region-input',
          }}
        />
      </Dialog.Content>
      <Dialog.Actions>
        <Button fill="outlined" color="neutral" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          isDisabled={!isValidPayload}
          onClick={handleEdit}
          isLoading={loading}
          htmlAttributes={{
            'data-testid': 'log-forwarding-edit',
          }}
        >
          Save changes
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
