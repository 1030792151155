import { Banner, Button, Dialog, Select, TextInput } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { type Instance, consoleApi, getApiError, getErrorMessage } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';
import * as yup from 'yup';

import type { Validation } from '../utils/validation';
import { validateYup } from '../utils/validation';
import { formatOptionLabel } from './shared';

const logger = createLogger(APP_SCOPE.aura);

type CreateCustomEndpointData = {
  name: string;
  dbId: string;
};

const helpText =
  'May only contain lowercase letters (a-z), numbers (0-9) and hyphens (-), must begin with a letter and must not end with a hyphen.';

const schema = yup.object({
  name: yup
    .string()
    .min(1)
    .max(30)
    .lowercase()
    .label('Custom endpoint')
    .matches(/^$|^[a-z]$|^[a-z][a-z0-9-]*[a-z0-9]$/, { message: helpText })
    .required()
    .strict(),
  dbId: yup.string().required().label('Instance'),
});

const validate = (data: CreateCustomEndpointData) => validateYup(schema, data, false);

type Props = {
  onClose: () => void;
  instances: Instance[];
};

export const CreateCustomEndpointDialog = ({ onClose, instances }: Props) => {
  const [data, setData] = useState<CreateCustomEndpointData>({ name: '', dbId: '' });
  const [validationError, setValidationError] = useState<Validation<CreateCustomEndpointData> | null>(null);
  const [createCustomEndpoint, createCustomEndpointRes] = consoleApi.useCreateCustomEndpointMutation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const handleSelectInstance = (dbId?: string) => {
    setValidationError(null);
    setData((prev) => ({ ...prev, dbId: dbId ?? '' }));
  };

  const handleNameChange = (name: string) => {
    setValidationError(null);
    setData((prev) => ({ ...prev, name }));
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    setErrorMessage(null);

    const validation = validate(data);
    if (isNotNullish(validation)) {
      setValidationError(validation);
      return;
    }
    setValidationError(null);

    createCustomEndpoint(data)
      .unwrap()
      .then(() => {
        onClose();
      })
      .catch((error: FetchBaseQueryError | SerializedError | undefined) => {
        const apiError = getApiError(error);
        const message = getErrorMessage(apiError);
        logger.error(message);

        if (apiError.reason === 'custom-endpoint-path-already-exists') {
          // We don't want the user to think the name is occupied
          setErrorMessage('Something unexpected went wrong. Please try again.');
        }
      });
  };

  const options = instances.map((instance) => ({
    key: instance.id,
    label: instance.name,
    value: instance.id,
    description: instance.id,
    isDisabled: !instance.capabilities.create_custom_endpoint.enabled,
    disabledReason: !instance.capabilities.create_custom_endpoint.enabled
      ? instance.capabilities.create_custom_endpoint.message
      : undefined,
  }));
  const selectedInstance = instances.find((item) => item.id === data.dbId);

  return (
    <Dialog isOpen onClose={onClose}>
      <Dialog.Header>Create custom endpoint</Dialog.Header>
      <form onSubmit={handleSubmit}>
        <Dialog.Content className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <TextInput
              label="Custom endpoint"
              onChange={(event) => handleNameChange(event.target.value)}
              errorText={validationError?.name?.message}
              value={data.name}
              isFluid
              helpText={helpText}
            />
            <Select
              label="Assign to instance"
              errorText={
                validationError?.dbId?.message ??
                (isNotNullish(selectedInstance) && !selectedInstance.availableActions.link_custom_endpoint.enabled
                  ? selectedInstance.availableActions.link_custom_endpoint.message
                  : undefined)
              }
              type="select"
              size="medium"
              selectProps={{
                value: options.find((instance) => instance.key === data.dbId),
                options,
                formatOptionLabel,
                menuPosition: 'fixed',
                onChange: (obj) => handleSelectInstance(obj?.value),
                isSearchable: true,
              }}
            />
          </div>
          <Banner
            hasIcon
            type="info"
            description="It will take a short moment for the custom endpoint to be ready for use."
            usage="inline"
          />
          <Banner
            hasIcon
            type="warning"
            description="You will not be able to configure the custom endpoint for the next 3 hours."
            usage="inline"
          />
          {createCustomEndpointRes.isError && (
            <Banner
              hasIcon
              type="danger"
              description={errorMessage ?? getErrorMessage(getApiError(createCustomEndpointRes.error))}
              usage="inline"
            />
          )}
        </Dialog.Content>
        <Dialog.Actions>
          <Button color="neutral" fill="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
            isLoading={createCustomEndpointRes.isLoading}
            isDisabled={
              isNotNullish(selectedInstance) && !selectedInstance.availableActions.link_custom_endpoint.enabled
            }
          >
            Create
          </Button>
        </Dialog.Actions>
      </form>
    </Dialog>
  );
};
