import { Banner, DataGrid, DataGridComponents, Label, StatusIndicator, Typography } from '@neo4j-ndl/react';
import { type Instance, SNAPSHOT_STATUS, type Snapshot } from '@nx/state';
import { isNullish } from '@nx/stdlib';
import { DataGridHelpers } from '@nx/ui';
import { createColumnHelper, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo } from 'react';

import { formatTimestamp } from '../../utils';
import { filterAndSortSnapshots } from './entities/helpers';
import { RestoreSnapshot } from './restore/index';
import { SnapshotActions } from './snapshot-action';

type SnapshotsTableProps = {
  instance: Instance;
  snapshots?: Snapshot[];
  onCreateFromBackupClick: (snapshotId: string) => void;
  isLoading: boolean;
  errorMessage: string | null;
};

const humanize = (word: string) => {
  switch (word) {
    case 'InProgress':
      return 'In Progress';
    case 'AdHoc':
      return 'On Demand';
    default:
      return word;
  }
};

const statusToStatusType = (status: string) => {
  switch (status) {
    case 'In Progress':
    case 'Pending':
      return 'unknown';
    case 'Failed':
      return 'danger';
    default:
      return 'success';
  }
};

const NoDataPlaceholder = () => (
  <DataGridComponents.NoDataPlaceholder>
    <div role="cell" className="ndl-data-grid-placeholder">
      <h6>No snapshots available</h6>
    </div>
  </DataGridComponents.NoDataPlaceholder>
);

const helper = createColumnHelper<Snapshot>();

export const SnapshotsTable = ({
  instance,
  snapshots,
  onCreateFromBackupClick,
  isLoading,
  errorMessage,
}: SnapshotsTableProps) => {
  const sortedSnapshots = useMemo(() => filterAndSortSnapshots(snapshots ?? []), [snapshots]);

  const columns = useMemo(
    () => [
      helper.accessor('timestamp', {
        id: 'Date',
        header: 'Date',
        cell: (cx) => formatTimestamp(cx.getValue(), 'do MMM'),
        size: 120,
      }),
      helper.accessor('timestamp', {
        id: 'Time',
        header: 'Time',
        cell: (cx) => formatTimestamp(cx.getValue(), 'HH:mm zzz'),
      }),
      helper.accessor('status', {
        id: 'Status',
        header: 'Status',
        cell: (cx) => {
          const status = humanize(cx.getValue());
          const statusType = statusToStatusType(status);
          return (
            <Typography as="div" variant="label" className="flex items-center">
              <StatusIndicator type={statusType} className="mr-1" />
              {status}
            </Typography>
          );
        },
      }),
      helper.accessor('profile', {
        id: 'Type',
        header: 'Type',
        cell: (cx) => {
          return <Label fill="outlined">{humanize(cx.getValue())}</Label>;
        },
      }),
      helper.display({
        id: DataGridHelpers.ACTIONS_COLUMN_ID,
        cell: (cx) => {
          const { status, timestamp, id, type, exportable } = cx.row.original;
          const renderOptions =
            exportable && status !== SNAPSHOT_STATUS.IN_PROGRESS && status !== SNAPSHOT_STATUS.FAILED;
          const fileName = `${instance.id}-${formatTimestamp(timestamp, 'ddMMM-HH:mmx')}.dump`;
          return (
            <div className="flex w-full justify-end" data-testid={id} key={DataGridHelpers.ACTIONS_COLUMN_ID}>
              {renderOptions && (
                <SnapshotActions
                  snapshotId={id}
                  onCreateFromBackupClick={onCreateFromBackupClick}
                  fileName={fileName}
                  dbId={instance.id}
                />
              )}
              {status === SNAPSHOT_STATUS.COMPLETED && (
                <RestoreSnapshot snapshotId={id} timestamp={timestamp} profile={type} instance={instance} />
              )}
            </div>
          );
        },
        size: 110,
        meta: {
          isStickyAction: true,
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [instance.availableActions],
  );

  const snapShotsTable = useReactTable({
    data: sortedSnapshots,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div>
      {isNullish(errorMessage) ? (
        <DataGrid
          tableInstance={snapShotsTable}
          isLoading={isLoading}
          isResizable={false}
          styling={{ headerStyle: 'clean' }}
          components={{
            BodyRow: DataGridHelpers.StickyActionsBodyRow,
            Header: DataGridHelpers.StickyActionHeader,
            NoDataPlaceholder,
          }}
          isKeyboardNavigable={false}
        />
      ) : (
        <Banner type="danger" description={errorMessage} usage="inline" />
      )}
    </div>
  );
};
