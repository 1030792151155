export enum IDP_TYPE {
  OKTA = 'okta',
  AAD = 'aad',
}

export enum CHANNEL {
  FRONT = 'front_channel',
  BACK = 'back_channel',
}

export interface SsoConfig {
  id: string;
  displayName: string;
  idpType: IDP_TYPE;
  clientId: string;
  discoveryURI?: string;
  issuer?: string;
  authorizationEndpoint?: string;
  tokenEndpoint?: string;
  jwksURI?: string;
  roleMapping?: string;
  organizationId: string;
  organizationLoginMethod: boolean;
  channel: CHANNEL;
  emailDomains: string[];
}

export interface LinkedProject {
  id: string;
  name: string;
}

export interface EnrichedSsoConfig extends SsoConfig {
  clientSecret?: string;
  projectLoginMethod?: boolean;
  linkedProjects: LinkedProject[];
}
