import { isNullish } from '@nx/stdlib';

import type { DataModelJsonStruct_1_4_0, DataSourceType } from './data-model.json.type';

export const isDataSourceType = (type: unknown): type is DataSourceType => {
  const dataSourceTypes: DataSourceType[] = [
    'unset',
    'local/csv',
    'sql/postgres',
    'sql/mysql',
    'sql/sqlserver',
    'sql/oracle',
    'warehouse/snowflake',
  ];
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return dataSourceTypes.includes(type as DataSourceType);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isDataModelJsonStruct_1_4_0 = (dataModel: any): dataModel is DataModelJsonStruct_1_4_0 => {
  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  return (
    !isNullish(dataModel) &&
    !isNullish(dataModel.version) &&
    !isNullish(dataModel.graphSchemaRepresentation) &&
    !isNullish(dataModel.graphSchemaExtensionsRepresentation) &&
    !isNullish(dataModel.graphSchemaExtensionsRepresentation.nodeKeyProperties) &&
    !isNullish(dataModel.graphMappingRepresentation) &&
    !isNullish(dataModel.graphMappingRepresentation.nodeMappings) &&
    !isNullish(dataModel.graphMappingRepresentation.relationshipMappings) &&
    !isNullish(dataModel.graphMappingRepresentation.dataSourceSchema) &&
    isDataSourceType(dataModel.graphMappingRepresentation.dataSourceSchema.type) &&
    !isNullish(dataModel.graphMappingRepresentation.dataSourceSchema.tableSchema.id) &&
    !isNullish(dataModel.configurations) &&
    !isNullish(dataModel.configurations.idsToIgnore)
  );
  /* eslint-enable @typescript-eslint/no-unsafe-member-access */
};
