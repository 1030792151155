import { Code, Tooltip } from '@neo4j-ndl/react';
import { Square2StackIconOutline } from '@neo4j-ndl/react/icons';
import { useCopyWithMessage } from '@nx/ui';
import React from 'react';

interface SingleLineCodeBlockProps extends React.ComponentProps<'div'> {
  code: string;
  copyToClipboard?: boolean;
}

export const SingleLineCodeBlock = ({ code }: SingleLineCodeBlockProps) => {
  const [messageToShow, copyWithFeedback] = useCopyWithMessage();
  return (
    <Code
      language="text"
      code={code}
      className="min-h-0 w-full"
      actions={[
        {
          ariaLabel: 'Copy to clipboard',
          htmlAttributes: {
            title: 'Copy to clipboard',
          },
          children: (
            <div className="relative">
              {messageToShow !== null && (
                <Tooltip type="simple" placement="left" isOpen>
                  <Tooltip.Content className={`absolute right-[40px] top-[-10px]`}>
                    <span className="n-body-medium whitespace-nowrap px-2">{messageToShow}</span>
                  </Tooltip.Content>
                </Tooltip>
              )}
              <Square2StackIconOutline />
            </div>
          ),
          onClick: () => copyWithFeedback(code),
        },
      ]}
    />
  );
};
