import { tokens } from '@neo4j-ndl/base';
import { Logo, useMediaQuery } from '@neo4j-ndl/react';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  href: string;
  onClick: React.MouseEventHandler | undefined;
  img?: React.ReactNode;
}

export const LogoButton = ({ href, onClick, img }: Props) => {
  const { breakpoints } = tokens;
  const isTabletScreenOrAbove = useMediaQuery(`(min-width:${breakpoints.md})`);

  const logo = img ?? <Logo className="h-6 w-full md:h-16" type="full" aria-label="Neo4j Aura logo" />;

  return (
    <Link to={href} onClick={onClick} className="select-none">
      {isTabletScreenOrAbove ? logo : <Logo className="h-6 w-full md:h-16" type="icon" aria-label="Neo4j Aura logo" />}
    </Link>
  );
};
