import type { ProjectSummary } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { useCallback, useRef, useState } from 'react';

import { ProjectCard } from './project-card';

type Props = {
  projects: ProjectSummary[];
};

export const ProjectList = ({ projects }: Props) => {
  const [visibleItems, setVisibleItems] = useState(10);
  const observer = useRef<IntersectionObserver>();

  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        const [entry] = entries;
        if (isNotNullish(entry) && entry.isIntersecting && projects.length > visibleItems) {
          setVisibleItems(visibleItems + 5);
        }
      });

      if (isNotNullish(node)) {
        observer.current.observe(node);
      }
    },
    [visibleItems, projects],
  );

  return (
    <div className="h-full overflow-auto p-4">
      <div className="grid grid-cols-[repeat(auto-fit,_minmax(49%,_1fr))] gap-3">
        {projects.toSpliced(visibleItems).map((project) => (
          <ProjectCard key={project.id} projectSummary={project} ref={lastElementRef} />
        ))}
      </div>
    </div>
  );
};
