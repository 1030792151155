import type { OmitStrict } from '@nx/stdlib';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { isNil } from 'lodash-es';
import memoize from 'memoize-one';
import type { AnyAction } from 'redux';

import { DATABASE_TYPE_STANDARD } from '../../services/bolt/constants';
import type { Database } from '../../types/database';
import { REHYDRATE } from '../persistence/constants';
import type { RootState } from '../types';
import { isAdminProcedureGranted, isProcedureGranted } from './procedures';
import type { ActiveConnection, UserPrivilege } from './types';

export const NAME = 'connections';

export const DISCONNECTED_STATE = 'DISCONNECTED_STATE';
export const CONNECTED_STATE = 'CONNECTED_STATE';
export const FAILED_CONNECTION_STATE = 'FAILED_CONNECTION_STATE';

export const USER_ROLE_PUBLIC = 'PUBLIC';
export const USER_ROLE_ADMIN = 'admin';

export interface ConnectionsDuckState {
  activeConnection: ActiveConnection | null;
  connectionState: typeof DISCONNECTED_STATE | typeof CONNECTED_STATE | typeof FAILED_CONNECTION_STATE;
  discoveredAddress: string | null;
  refreshingData: boolean;
  availableProcedures: string[] | undefined;
  desktopReconnecting: boolean;
  lastUsedDbPerDbms: Record<string, string>;
  databases: Database[];
  bloomPluginVersion: string | null;
  userPrivileges?: UserPrivilege[];
  allRoles?: string[];
  userRoles?: string[];
  dbmsId?: string;
  database?: Database;
  userId?: string;
  lastErrorMessage?: string | null;
  serverVersion?: string | null;
  homeDatabase?: string | null;
}

export const initialState: ConnectionsDuckState = {
  activeConnection: null,
  connectionState: DISCONNECTED_STATE,
  discoveredAddress: null,
  refreshingData: false,
  availableProcedures: [],
  desktopReconnecting: false,
  lastUsedDbPerDbms: {},
  databases: [],
  bloomPluginVersion: null,
};

export const featureRules: Record<string, any> = {
  perspectiveSharing: {
    procedure: 'bloom.checkAuthorization',
  },
  retrieveStats: {
    procedure: 'db.stats.retrieve',
    isAdminProcedure: true,
    requireRole: [USER_ROLE_ADMIN],
  },
};

/**
 * Selectors
 */
export function getConnectionState(state: RootState): ConnectionsDuckState['connectionState'] {
  return state[NAME].connectionState ?? initialState.connectionState;
}

export function getIsConnected(state: RootState) {
  return state[NAME].connectionState === CONNECTED_STATE;
}

export function getActiveConnection(state: RootState) {
  return state[NAME].activeConnection ?? initialState.activeConnection;
}

export function getUserPrivileges(state: RootState): ConnectionsDuckState['userPrivileges'] {
  return state[NAME].userPrivileges;
}

export function getAvailableRoles(state: RootState): ConnectionsDuckState['allRoles'] {
  return state[NAME].allRoles ?? [];
}

export function getUserRoles(state: RootState): ConnectionsDuckState['userRoles'] {
  return state[NAME].userRoles ?? [];
}

export function getDbmsId(state: RootState): ConnectionsDuckState['dbmsId'] {
  return state[NAME].dbmsId;
}

export function getUserId(state: RootState): ConnectionsDuckState['userId'] {
  return state[NAME].userId;
}

export function getDatabases(state: RootState): ConnectionsDuckState['databases'] {
  return state[NAME].databases;
}

const filterCompatibleDatabases = memoize((dbs) =>
  dbs.filter((db: { type: string }) => db.type === DATABASE_TYPE_STANDARD),
);

export function getCompatibleDatabases(
  state: RootState,
): OmitStrict<Database, 'type'> & { type: typeof DATABASE_TYPE_STANDARD }[] {
  return filterCompatibleDatabases(state[NAME].databases);
}

export function getDatabase(state: RootState): ConnectionsDuckState['database'] {
  return state[NAME].database;
}

export function getInitialized(state: RootState) {
  return state[NAME].dbmsId !== undefined && state[NAME].userPrivileges !== undefined;
}

export function getDiscoveredAddress(state: RootState): ConnectionsDuckState['discoveredAddress'] {
  return state[NAME].discoveredAddress;
}

export function getAvailableProcedures(state: RootState): ConnectionsDuckState['availableProcedures'] {
  return state[NAME].availableProcedures ?? [];
}

export const getLastErrorMessage = (state: RootState): ConnectionsDuckState['lastErrorMessage'] =>
  state[NAME].lastErrorMessage;

export const getServerVersion = (state: RootState): ConnectionsDuckState['serverVersion'] => state[NAME].serverVersion;

export const isAuraConnection = createSelector(
  getServerVersion,
  (version: ConnectionsDuckState['serverVersion']): boolean => Boolean(version?.toLowerCase().includes('aura')),
);

export const getBloomPluginVersion = (state: RootState): ConnectionsDuckState['bloomPluginVersion'] =>
  state[NAME].bloomPluginVersion;

export const getDesktopReconnecting = (state: RootState): ConnectionsDuckState['desktopReconnecting'] =>
  state[NAME].desktopReconnecting;

export const getRefreshingDataState = (state: RootState): ConnectionsDuckState['refreshingData'] =>
  state[NAME].refreshingData ?? initialState.refreshingData;
export const getUserHomeDatabase = (state: RootState): ConnectionsDuckState['homeDatabase'] => state[NAME].homeDatabase;
export const getLastUsedDbIdFromCurrentDbms = (state: RootState) =>
  state[NAME].dbmsId !== undefined ? state[NAME].lastUsedDbPerDbms[state[NAME].dbmsId] : undefined;

export const getIsFeatureAvailable = (state: RootState, feature: string) => {
  const rule = featureRules[feature];
  if (isNil(rule)) {
    return false;
  }
  const { availableProcedures, serverVersion, userPrivileges, userRoles } = state[NAME];
  return checkFeature(rule, availableProcedures, userRoles, serverVersion, userPrivileges);
};

export const getAllFeaturesAvailable = (state: RootState) => {
  const { availableProcedures, serverVersion, userPrivileges, userRoles } = state[NAME];
  return Object.keys(featureRules).filter((name) => {
    return checkFeature(featureRules[name], availableProcedures, userRoles, serverVersion, userPrivileges);
  });
};

export const isPerspectiveSharingAvailable = (state: RootState) => getIsFeatureAvailable(state, 'perspectiveSharing');

export const getUserCanSaveScene = (state: RootState) => {
  return (!getIsFeatureAvailable(state, 'perspectiveSharing') || state[NAME]?.database?.userCanSaveScene) ?? false;
};

export const getUserCanSavePerspective = (state: RootState) => {
  return !getIsFeatureAvailable(state, 'perspectiveSharing') || state[NAME]?.database?.userCanSavePerspective;
};

const checkFeature = (
  rule: { procedure: string; isAdminProcedure: boolean; requireRole: string },
  availableProcedures: ConnectionsDuckState['availableProcedures'],
  userRoles: ConnectionsDuckState['userRoles'],
  version: ConnectionsDuckState['serverVersion'],
  privileges: ConnectionsDuckState['userPrivileges'],
) => {
  if (availableProcedures === undefined || !availableProcedures?.includes(rule.procedure)) {
    return false;
  }

  if (rule.isAdminProcedure) {
    if (!isAdminProcedureGranted(rule.procedure, privileges)) {
      return false;
    }
  } else {
    if (privileges === undefined || !isProcedureGranted(rule.procedure, privileges)) {
      return false;
    }
  }

  return true;
};

const connectionDuckSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setConnected: (state) => {
      state.connectionState = CONNECTED_STATE;
      state.lastErrorMessage = null;
    },
    setDisconnected: (state) => {
      state.connectionState = DISCONNECTED_STATE;
    },
    updateConnectionDetails: (state, action: PayloadAction<ActiveConnection | null | undefined>) => {
      const connection = action.payload;
      if (!isNil(connection)) {
        state.activeConnection = { ...state.activeConnection, ...connection };
      }
    },
    setFailedConnection: (state, action: PayloadAction<string>) => {
      const errorMessage = action.payload;
      state.connectionState = FAILED_CONNECTION_STATE;
      state.lastErrorMessage = errorMessage;
      state.refreshingData = false;
    },
    setUserPrivileges: (state, action: PayloadAction<UserPrivilege[]>) => {
      const userPrivileges = action.payload;
      state.userPrivileges = userPrivileges;
    },
    setAvailableRoles: (state, action: PayloadAction<string[]>) => {
      const roles = action.payload;
      state.allRoles = roles;
    },
    setAvailableProcedures: (state, action: PayloadAction<string[] | undefined>) => {
      const procedures = action.payload;
      state.availableProcedures = procedures;
    },
    setUserRoles: (state, action: PayloadAction<string[]>) => {
      const roles = action.payload;
      state.userRoles = roles;
    },
    setDbmsId: (state, action: PayloadAction<string>) => {
      const dbmsId = action.payload;
      state.dbmsId = dbmsId;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      const userId = action.payload;
      state.userId = userId;
    },
    setUserHomeDatabase: (state, action: PayloadAction<string | null | undefined>) => {
      const homeDatabase = action.payload;
      state.homeDatabase = homeDatabase;
    },
    setDiscoveredAddress: (state, action: PayloadAction<string>) => {
      const address = action.payload;
      state.discoveredAddress = address;
    },
    setServerVersion: (state, action: PayloadAction<string | null | undefined>) => {
      const serverVersion = action?.payload;
      state.serverVersion = serverVersion;
    },
    setBloomPluginVersion: (state, action: PayloadAction<string>) => {
      const bloomPluginVersion = action.payload;
      state.bloomPluginVersion = bloomPluginVersion;
    },
    setDatabases: (state, action: PayloadAction<Database[]>) => {
      const databases = action.payload;
      state.databases = databases;
    },
    setDatabase: (state, action: PayloadAction<{ database: Database; saveAsLastUsedDb?: boolean }>) => {
      const { database } = action.payload;
      // saveAsLastUsedDb is treated as true by default
      const saveAsLastUsedDb = action.payload.saveAsLastUsedDb ?? true;
      const dbmsToDbMapper = { ...state.lastUsedDbPerDbms };
      if (!isNil(database.id) && saveAsLastUsedDb && !isNil(state.dbmsId)) {
        dbmsToDbMapper[state.dbmsId] = database.id;
      }
      state.database = database;
      state.lastUsedDbPerDbms = dbmsToDbMapper;
    },
    setRefreshingDataState: (state, action: PayloadAction<boolean>) => {
      const refreshingData = action.payload;
      state.refreshingData = refreshingData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action: AnyAction) => {
      const payload = action?.payload[NAME] ?? {};
      return { ...state, ...payload };
    });
  },
});

export const {
  setConnected,
  setDisconnected,
  updateConnectionDetails,
  setFailedConnection,
  setUserPrivileges,
  setAvailableRoles,
  setAvailableProcedures,
  setUserRoles,
  setDbmsId,
  setUserId,
  setUserHomeDatabase,
  setDiscoveredAddress,
  setServerVersion,
  setBloomPluginVersion,
  setDatabases,
  setDatabase,
  setRefreshingDataState,
} = connectionDuckSlice.actions;

export const reload = createAction(`${NAME}/reload`);

export default connectionDuckSlice.reducer;

const toBoltHost = (host?: string) => {
  return `bolt://${(host ?? '') // prepend with bolt://
    .split('bolt://')
    .join('') // remove bolt://
    .split('bolt+routing://')
    .join('') // remove bolt+routing://
    .split('neo4j://')
    .join('')}`;
};

export const getDefaults = (host?: string, username?: string, password?: string) => {
  return {
    host: host ?? toBoltHost('localhost'),
    username,
    password,
    encrypted: false,
  };
};
