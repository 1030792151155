import { tokens } from '@neo4j-ndl/base';
import type { SeriesSummary } from '@nx/state';
import { isNullish } from '@nx/stdlib';
import ReactDOMServer from 'react-dom/server';
import type uPlot from 'uplot';

import type { TooltipComponentType } from '../shared/types';

export function tooltipPlacementPlugin(TooltipComponent: TooltipComponentType, seriesSummary: SeriesSummary[]) {
  let over: HTMLElement;
  let bTop: number;
  let bLeft: number;

  function syncBounds() {
    const bbox = over.getBoundingClientRect();
    bLeft = bbox.left;
    bTop = bbox.top;
  }

  const overlay = document.createElement('div');
  return {
    hooks: {
      init: (u: InstanceType<typeof uPlot>) => {
        // overlay.id = "overlay";
        // random id
        overlay.id = Math.random().toString(36).slice(2, 7);
        overlay.style.display = 'none';
        overlay.style.position = 'absolute';
        // +10 to be visible immediately after expanding
        overlay.style.zIndex = `${tokens.zIndex.alias.modal + 10}`;

        document.body.appendChild(overlay);
        over = u.over;

        over.onmouseenter = () => {
          overlay.style.display = 'block';
        };

        over.onmouseleave = () => {
          overlay.style.display = 'none';
        };

        // Avoid tooltip staying when leaving in a UI-blocking event
        over.onmouseout = () => {
          overlay.style.display = 'none';
        };
      },

      destroy: () => {
        // Destroy tooltip when uPlot instance is destroyed
        overlay.remove();
      },

      setSize: () => {
        syncBounds();
      },

      setCursor: (u: InstanceType<typeof uPlot>) => {
        const { left, top, idx } = u.cursor;
        // Return if index is not defined
        if (isNullish(idx)) {
          return;
        }
        if (left === undefined || top === undefined) {
          return;
        }

        const bbox = u.over.getBoundingClientRect();
        bLeft = bbox.left;
        bTop = bbox.top;

        const anchor = { left: left + bLeft, top: top + bTop };
        overlay.innerHTML = ReactDOMServer.renderToString(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          <TooltipComponent uPlotInstance={u} cursorIdx={idx} seriesSummary={seriesSummary} />,
        );

        /**
         * In the first condition the tooltip will
         * get out of the chart (on the right),
         * so we flip it to the left.
         *
         * We need to add a padding of some pixels based on:
         * https://github.com/leeoniya/uPlot/issues/653
         *
         * Doesn't apply for chart width < 150px
         */
        // console.log(`Cursor left is: ${u.cursor.left}`);
        // console.log(`Offset width is: ${overlay.offsetWidth}`);
        if (left + overlay.offsetWidth > u.width && u.width > 150) {
          overlay.style.top = `${anchor.top + 20}px`;
          overlay.style.left = `${anchor.left - 20 - overlay.offsetWidth}px`;
        } else {
          overlay.style.top = `${anchor.top + 20}px`;
          overlay.style.left = `${anchor.left + 20}px`;
        }
      },
    },
  };
}
