import { Banner, Button, Switch, Tooltip, Typography } from '@neo4j-ndl/react';
import { QuestionMarkCircleIconOutline } from '@neo4j-ndl/react/icons';
import { AURA_CONSOLE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import type { Instance, Project } from '@nx/state';
import { ACTION, SNAPSHOT_STATUS, consoleApi, getApiError, getErrorMessage, usePermissions } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { PermissionTooltip } from '@nx/ui';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useMemo, useState } from 'react';

import { useTrackUpxEvent } from '../../services/segment/analytics';
import { CDCRestoreSnapshotTableWarning, instanceHasCDCEnabled } from '../cdc';
import { CloneToNewModal } from '../clone/to-new/modal';
import { SnapshotsTable } from './snapshots-table';

const logger = createLogger(APP_SCOPE.framework);

type SnapshotsSectionProps = {
  instance: Instance;
  project: Project;
};

export const SnapshotsSection = ({ instance, project }: SnapshotsSectionProps) => {
  const trackEvent = useTrackUpxEvent();
  const { isAllowed } = usePermissions();
  const allowTakeSnapshot = isAllowed(ACTION.CREATE, `namespaces/${project.id}/databases/${instance.id}/snapshots`);
  const {
    data: snapshots = [],
    isError: isListError,
    isLoading,
    error: listError,
  } = consoleApi.useListSnapshotsQuery(instance.id, { pollingInterval: 30000 });
  const [takeSnapshot, takeSnapshotRes] = consoleApi.useTakeSnapshotMutation();

  const takeSnapshotErrorMessage = useMemo(() => {
    if (!takeSnapshotRes.isError) {
      return null;
    }
    const error = getApiError(takeSnapshotRes.error);
    const message = getErrorMessage(error);
    return message;
  }, [takeSnapshotRes.error, takeSnapshotRes.isError]);

  const listSnapshotErrorMessage = useMemo(() => {
    if (!isListError) {
      return null;
    }
    const error = getApiError(listError);
    const message = getErrorMessage(error);
    return message;
  }, [isListError, listError]);

  const [openCreateFromSnapshot, setOpenCreateFromSnapshot] = useState(false);
  const [snapshotId, setSnapshotId] = useState<string | null>(null);

  const hasPendingSnapshots = useMemo(() => {
    return isNotNullish(
      snapshots.find((s) => [SNAPSHOT_STATUS.IN_PROGRESS, SNAPSHOT_STATUS.PENDING].includes(s.status)),
    );
  }, [snapshots]);

  const handleTakeSnapshot = () => {
    takeSnapshot(instance.id)
      .unwrap()
      .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(e);
        if (isNotNullish(error.message)) {
          logger.error(error.message);
        }
      });

    trackEvent({
      event: AURA_CONSOLE_EVENTS.INSTANCE_TAKE_SNAPSHOT,
      scope: APP_SCOPE.aura,
    });
  };

  const [filterExportableOnly, setFilterExportableOnly] = useState<boolean>(false);

  const handleSnapshotFilterChange = () => {
    setFilterExportableOnly(!filterExportableOnly);
  };

  const handleClose = () => {
    setOpenCreateFromSnapshot(false);
    setSnapshotId(null);
  };

  const filteredSnapshots = filterExportableOnly ? snapshots.filter((s) => s.exportable) : snapshots;
  return (
    <>
      <div className="flex h-full flex-col gap-4">
        <div className="flex flex-row justify-between gap-6">
          <Typography variant="body-medium" className="text-neutral-text-weaker flex-wrap">
            Take a snapshot of your data at any point to download or dump to new instance.
          </Typography>
          <PermissionTooltip hasPermission={allowTakeSnapshot} className="shrink-0" hasButtonWrapper>
            <Button
              className="text-nowrap"
              size="small"
              isDisabled={!allowTakeSnapshot || hasPendingSnapshots}
              onClick={handleTakeSnapshot}
            >
              Take snapshot
            </Button>
          </PermissionTooltip>
        </div>
        <div className="flex items-center">
          <Switch
            onChange={handleSnapshotFilterChange}
            className="inline-block"
            label="Show exportable only"
            isChecked={filterExportableOnly}
            htmlAttributes={{
              'data-testid': 'snapshot-filter-switch',
            }}
          />
          {/* TODO: decide placement between right and left */}
          <Tooltip placement="right" type="simple" isPortaled={false}>
            <Tooltip.Trigger hasButtonWrapper>
              <QuestionMarkCircleIconOutline className="ml-2 inline-block size-4" aria-label="Size information" />
            </Tooltip.Trigger>
            <Tooltip.Content className="max-w-sm">
              <p>On-demand snapshots and full backups are exportable within the retention period.</p>
            </Tooltip.Content>
          </Tooltip>
        </div>
        {isNotNullish(takeSnapshotRes.error) && (
          <Banner
            className="my-2"
            type="danger"
            description="Failed taking a snapshot. Try again later."
            usage="inline"
          />
        )}
        {instanceHasCDCEnabled(instance) && <CDCRestoreSnapshotTableWarning />}
        <SnapshotsTable
          instance={instance}
          errorMessage={listSnapshotErrorMessage ?? takeSnapshotErrorMessage}
          isLoading={isLoading}
          snapshots={filteredSnapshots}
          onCreateFromBackupClick={(id: string) => {
            setSnapshotId(id);
            setOpenCreateFromSnapshot(true);
          }}
        />
      </div>
      {openCreateFromSnapshot && isNotNullish(snapshotId) && (
        <CloneToNewModal
          onClose={handleClose}
          onConfirm={handleClose}
          instance={instance}
          project={project}
          snapshotId={snapshotId}
        />
      )}
    </>
  );
};
