import type { BannerActionsProps } from '@neo4j-ndl/react';
import { Banner } from '@neo4j-ndl/react';
import type { FullNxNotification } from '@nx/state';
import { useConnectionSelectorModal, useNotificationActions } from '@nx/state';
import { useEffect, useState } from 'react';

import classes from './notifications.module.css';

const Notification = ({
  notification,
  closeNotification,
}: {
  notification: FullNxNotification;
  closeNotification: (id: string) => void;
}) => {
  const connectionSelectorModal = useConnectionSelectorModal();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (notification.timeout !== undefined) {
      timer = setTimeout(() => {
        closeNotification(notification.id);
      }, notification.timeout);
    }

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification.timeout]);

  const actionCallbacks: Record<'reconnect' | 'reload', BannerActionsProps> = {
    reconnect: {
      as: 'button',
      label: 'Reconnect',
      onClick() {
        connectionSelectorModal.open({ modalTabId: 'Remote' });
      },
    },
    reload: {
      as: 'button',
      label: 'Refresh page',
      onClick() {
        document.location.reload();
      },
    },
  };

  return (
    <Banner
      type={notification.type}
      title={notification.title}
      description={notification.description}
      actions={notification.actionCallbacks?.map((action) => actionCallbacks[action]).filter(Boolean)}
      hasIcon={true}
      isCloseable={true}
      onClose={() => closeNotification(notification.id)}
      className={`mb-2 ${classes.slideDown}`}
      usage="global"
    />
  );
};

export function Notifications() {
  const [notifications, setNotifications] = useState<FullNxNotification[] | []>([]);

  const { updateNotification, notifications: notificationsInState } = useNotificationActions();

  const closeNotification = (id: string) => setNotifications((prevState) => prevState.filter((n) => n.id !== id));

  useEffect(() => {
    if (notificationsInState.length !== 0) {
      const n = notificationsInState[notificationsInState.length - 1];
      if (n !== undefined && !n.hasBeenShown) {
        setNotifications((prevState) => [...prevState, { ...n }]);
        updateNotification(n);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsInState]);

  if (notifications.length === 0) {
    return null;
  }

  return (
    <section data-testid="notification" className="absolute right-6 top-[44px] z-[1000] ml-auto mr-auto max-w-[467px]">
      {notifications.map((notification) => (
        <Notification key={notification.id} notification={notification} closeNotification={closeNotification} />
      ))}
    </section>
  );
}
