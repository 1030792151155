import { createSlice } from '@reduxjs/toolkit';
import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import type { AnyAction } from 'redux';

import { isFalsy } from '../../types/utility';
import { REHYDRATE } from '../persistence/constants';
import type { RootState } from '../types';
import type { SortingOrder, UserViewState } from './types';

export const NAME = 'userview';

const defaultSortingOrder = 'asc';

const sortingOrderToggle = (currentSortingOrder: SortingOrder): SortingOrder =>
  currentSortingOrder === 'asc' ? 'desc' : 'asc';

const initialState: UserViewState = {
  orderingCategories: {},
  orderingRelationshipTypes: {},
  dataNeedsRefresh: false,
};

// selectors
export function getOrderingForCategories(state: RootState): UserViewState['orderingCategories'] {
  return state[NAME].orderingCategories;
}

export function getOrderingForRelationshipTypes(state: RootState): UserViewState['orderingRelationshipTypes'] {
  return state[NAME].orderingRelationshipTypes;
}

export const getDataNeedsRefresh = (state: RootState): UserViewState['dataNeedsRefresh'] =>
  state[NAME].dataNeedsRefresh;

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    updateOrderingPropertyForCategory: (state, action: PayloadAction<{ category: string; property: string }>) => {
      const { category, property } = action.payload;
      state.orderingCategories[category] = {
        ...state.orderingCategories[category],
        property,
        sortingOrder: defaultSortingOrder,
      };
    },
    toggleOrderingDirectionForCategory: (state, action: PayloadAction<string>) => {
      const category = action.payload;
      const currentCategoryOrder = state.orderingCategories[category];

      if (currentCategoryOrder === undefined) {
        return state;
      }

      state.orderingCategories[category] = {
        ...currentCategoryOrder,
        sortingOrder: sortingOrderToggle(currentCategoryOrder.sortingOrder),
      };
    },
    updateOrderingPropertyForRelationshipType: (
      state,
      action: PayloadAction<{ relationshipType: string; property: string }>,
    ) => {
      const { relationshipType, property } = action.payload;
      state.orderingRelationshipTypes[relationshipType] = {
        ...state.orderingRelationshipTypes[relationshipType],
        property,
        sortingOrder: defaultSortingOrder,
      };
    },
    toggleOrderingDirectionForRelationshipType: (state, action: PayloadAction<string>) => {
      const relationshipType = action.payload;
      const currentRelTypeOrder = state.orderingRelationshipTypes[relationshipType];

      if (currentRelTypeOrder === undefined) {
        return state;
      }

      state.orderingRelationshipTypes[relationshipType] = {
        ...currentRelTypeOrder,
        sortingOrder: sortingOrderToggle(currentRelTypeOrder.sortingOrder),
      };
    },
    setDataNeedsRefresh: (state, action: PayloadAction<boolean>) => {
      state.dataNeedsRefresh = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<UserViewState>) => {
    builder.addCase(REHYDRATE, (state, action: AnyAction) => {
      if (isFalsy(action.payload) || isFalsy(action.payload[NAME])) {
        return state;
      }

      return {
        ...state,
        ...action.payload[NAME],
      };
    });
  },
});

export const {
  updateOrderingPropertyForCategory,
  toggleOrderingDirectionForCategory,
  updateOrderingPropertyForRelationshipType,
  toggleOrderingDirectionForRelationshipType,
  setDataNeedsRefresh,
} = slice.actions;
export default slice.reducer;
