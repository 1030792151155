import { toast } from '@neo4j-ndl/react';
import { isNullish } from '@nx/stdlib';

export const showErrorToast = (message: string, retryAction?: () => void) => {
  return toast.danger(message, {
    isCloseable: true,
    shouldAutoClose: !isNullish(retryAction) ? undefined : true,
    actionLabel: !isNullish(retryAction) ? 'Try again' : undefined,
    shouldCloseOnAction: !isNullish(retryAction) ? true : undefined,
    onAction: !isNullish(retryAction)
      ? () => {
          void retryAction();
        }
      : undefined,
  });
};

export const showOnLoadToast = (message: string) => {
  return toast.loadingSpinner(message, {
    isCloseable: true,
  });
};

export const showSuccessToast = (message: string) => {
  return toast.success(message, {
    isCloseable: true,
    shouldAutoClose: true,
  });
};

export const showNeutralToast = (message: string) => {
  return toast.neutral(message, {
    isCloseable: true,
    shouldAutoClose: true,
  });
};
