import type { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { isFalsy } from '../../types/utility';
import { REHYDRATE } from '../persistence/constants';
import type { RootState } from '../types';

export const NAME = 'drawer';
export interface DrawerState {
  drawer: false | string;
  openedOnce: boolean;
  perspectiveHistoryLastSeen: Record<string, number>;
}

export const initialState: DrawerState = {
  drawer: false,
  openedOnce: false,
  perspectiveHistoryLastSeen: {},
};

const getState = (state: RootState): DrawerState => state[NAME] ?? initialState;
export const getDrawer = (state: RootState) => getState(state).drawer;
export const getOpenedOnce = (state: RootState) => getState(state).openedOnce;
export const getPerspectiveHistoryLastSeen = createSelector(
  (state: RootState) => getState(state).perspectiveHistoryLastSeen,
  (perspectiveHistoryLastSeen) => (perspectiveId: string) => perspectiveHistoryLastSeen[perspectiveId],
);

const drawerSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setDrawer(state, action: PayloadAction<DrawerState['drawer']>) {
      state.drawer = action.payload;
    },
    setOpenedOnce(state, action: PayloadAction<DrawerState['openedOnce']>) {
      state.openedOnce = action.payload;
    },
    setPerspectiveHistoryLastSeen(state, action: PayloadAction<{ perspectiveId: string; timestamp: number }>) {
      state.perspectiveHistoryLastSeen[action.payload.perspectiveId] = action.payload.timestamp;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action: AnyAction) => {
      if (isFalsy(action.payload?.[NAME])) return state;
      return {
        ...state,
        ...action.payload[NAME],
      };
    });
  },
});

export const { setDrawer, setOpenedOnce, setPerspectiveHistoryLastSeen } = drawerSlice.actions;

export default drawerSlice.reducer;
