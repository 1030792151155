import { IconButton, Menu } from '@neo4j-ndl/react';
import { ArrowDownTrayIconOutline, EllipsisHorizontalIconOutline, PlusIconOutline } from '@neo4j-ndl/react/icons';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { ACTION, consoleApi, getApiError, getErrorMessage, usePermissions } from '@nx/state';
import { PermissionTooltip } from '@nx/ui';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { SyntheticEvent } from 'react';
import { useRef, useState } from 'react';

import { useProjectAppContext } from '../../app-context';

const logger = createLogger(APP_SCOPE.aura);

interface Props {
  onCreateFromBackupClick: (snapshotId: string) => void;
  snapshotId: string;
  fileName: string;
  dbId: string;
}

export const SnapshotActions = ({ onCreateFromBackupClick, fileName, snapshotId, dbId }: Props) => {
  const { activeProject } = useProjectAppContext();
  const { isAllowed } = usePermissions();
  const allowCreateInstancesFromSnapshot = isAllowed(ACTION.CREATE, `namespaces/${activeProject.id}/databases`);
  const allowDownloadSnapshot = isAllowed(
    ACTION.DOWNLOAD,
    `namespaces/${activeProject.id}/databases/${dbId}/snapshots/${snapshotId}`,
  );
  const [getSnapshotUrlRes] = consoleApi.useLazyGetSnapshotUrlQuery();

  const menuRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const onExportClick = () => {
    getSnapshotUrlRes({ snapshotId, dbId })
      .unwrap()
      .then((res) => {
        const link = document.createElement('a');
        link.href = res;
        link.target = '_blank';
        link.download = fileName;
        link.click();
        link.remove();
        handleClose();
      })
      .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(e);
        const message = getErrorMessage(error);
        logger.error(message);
      });
  };

  return (
    <>
      <IconButton
        className="ml-2 self-center"
        onClick={handleOpen}
        isClean
        ref={menuRef}
        isActive={isOpen}
        ariaLabel="Additional snapshot actions"
        htmlAttributes={{
          title: 'Additional snapshot actions',
          color: 'neutral',
          'data-testid': 'snapshot-extra',
        }}
      >
        <EllipsisHorizontalIconOutline />
      </IconButton>
      <Menu isOpen={isOpen} onClose={handleClose} anchorRef={menuRef}>
        <Menu.Items>
          <Menu.Item
            title={
              <PermissionTooltip hasPermission={allowDownloadSnapshot} hasButtonWrapper>
                Export
              </PermissionTooltip>
            }
            icon={<ArrowDownTrayIconOutline />}
            onClick={onExportClick}
            style={{ display: 'flex' }}
            isDisabled={!allowDownloadSnapshot}
            htmlAttributes={{
              name: 'Export',
              'data-testid': 'export-snapshot',
            }}
          />
          <Menu.Item
            title={
              <PermissionTooltip hasPermission={allowCreateInstancesFromSnapshot} hasButtonWrapper>
                Create instance from snapshot
              </PermissionTooltip>
            }
            onClick={() => onCreateFromBackupClick(snapshotId)}
            icon={<PlusIconOutline />}
            isDisabled={!allowCreateInstancesFromSnapshot}
            htmlAttributes={{
              name: 'Create instance from snapshot',
              'data-testid': 'create-from-snapshot',
            }}
          />
        </Menu.Items>
      </Menu>
    </>
  );
};
