import { isNullish } from '@nx/stdlib';
import type * as yup from 'yup';

export type ValidationError = {
  message: string;
  error: yup.ValidationError;
};

type Path<T, Prefix extends string = ''> = {
  [K in keyof T]: T[K] extends object
    ? T[K] extends (infer U)[]
      ?
          | `${Prefix}${K & string}.general`
          | `${Prefix}${K & string}[${number}]`
          | Path<U, `${Prefix}${K & string}[${number}].`>
      : `${Prefix}${K & string}` | Path<T[K], `${Prefix}${K & string}.`>
    : `${Prefix}${K & string}` | `${Prefix}${K & string}[${number}]`;
}[keyof T];

export type Validation<T> = {
  [P in Path<T>]?: ValidationError;
};

export const validateYup = <T>(schema: yup.AnySchema, data: T, onlyRequired = false): Validation<T> | null => {
  let validation = null;
  try {
    // eslint-disable-next-line no-sync
    schema.validateSync(data, { abortEarly: false });
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const error: yup.ValidationError = e as yup.ValidationError;

    validation = error.inner.reduce((acc, err) => {
      if ((onlyRequired && err.type !== 'required') || isNullish(err.path)) {
        return acc;
      }
      if (err.type === 'at-least-one-auth-provider-enabled') {
        return { ...acc, 'authProviders.general': { message: err.message, error: err } };
      }

      return { ...acc, [err.path]: { message: err.message, error: err } };
    }, {});

    if (Object.keys(validation).length === 0) {
      validation = null;
    }
  }

  return validation;
};

export const isValid = <T, B extends T = Required<T>>(data: T, validation: Validation<T> | null): data is B =>
  isNullish(validation);
