import { isNotNullish } from '@nx/stdlib';

import type {
  CreateEncryptionKeyRequest,
  EncryptionKeyResponse,
  TransformedCreateEncryptionKeyBody,
} from '../api-types/encryption-key';
import type { EncryptionKey } from '../types/encryption-key';

export const transformEncryptionKeyResponse = (response: EncryptionKeyResponse): EncryptionKey => {
  const key: EncryptionKey = {
    encryptionKeyRef: response.encryption_key_ref,
    cloudProvider: response.cloud_provider,
    tier: response.tier,
    region: response.region,
    name: response.name,
    created: response.created,
    status: response.status,
    awsProperties: isNotNullish(response.aws_properties)
      ? {
          kmsKeyArn: response.aws_properties.kms_key_arn,
          permissions: {
            accountId: response.aws_properties.permissions.account_id,
          },
        }
      : undefined,
    gcpProperties: isNotNullish(response.gcp_properties)
      ? {
          keyId: response.gcp_properties.key_id,
          permissions: {
            projectNumber: response.gcp_properties.permissions.project_number,
            serviceAccounts: response.gcp_properties.permissions.service_accounts,
          },
        }
      : undefined,
    azureProperties: isNotNullish(response.azure_properties)
      ? {
          keyId: response.azure_properties.key_id,
          applicationURL: response.azure_properties.application_url,
          applicationID: response.azure_properties.application_id,
          applicationName: response.azure_properties.application_name,
        }
      : undefined,
  };

  return key;
};

export const transformCreateEncryptionKeyRequest = (
  request: CreateEncryptionKeyRequest,
): TransformedCreateEncryptionKeyBody => ({
  tier: request.tier,
  region: request.region,
  name: request.name,
  key_id: request.keyId,
  cloud_provider: request.cloudProvider,
});
