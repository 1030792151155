import { forIn, mapValues, pick, without } from 'lodash-es';

import { log } from '../../services/logging';
import { NAME as app } from '../app/appDuck';
import { NAME as cards } from '../cards/cards';
import { NAME as connections } from '../connections/connectionsDuck';
import { NAME as contextMenu } from '../contextmenu/contextmenu';
import { NAME as focused } from '../focused/focused';
import { NAME as modal } from '../modal/modal';
import { NAME as notifications } from '../notifications/notifications';
import { NAME as perspectives } from '../perspectives/perspectives';
import { NAME as propertyPreview } from '../propertypreview/propertyPreview';
import { NAME as scene } from '../scene/scene';
import { NAME as searchPrototype } from '../search-prototype/search-prototype.const';
import { NAME as search } from '../search/searchDuck';
import { NAME as settings } from '../settings/settings';
import { isSharedStorageAvailable, NAME as sharedStorage } from '../shared-storage/shared-storage.api';
import localStorage from '../storage/localStorage';
import { getPerspectiveWithStyle, NAME as styles } from '../styles/styles';
import { NAME as userview } from '../userview/userview';
import { STORAGE_KEY_V5, STORAGE_KEY_V6 } from './constants';
import { documentKeepList } from './documentPersistor';
import { cleanStateBeforeSaving } from './persistorHelper';

class RehydratePersistor {
  constructor() {
    this.previousState = {};
  }

  async loadFromStorage({ payload }) {
    const keys = await localStorage.keys();
    const hasSharedStorage = isSharedStorageAvailable();

    if (keys.includes(STORAGE_KEY_V5)) {
      payload = await restoreAndDeleteV5File();
      return payload;
    }

    if (keys.includes(STORAGE_KEY_V6)) {
      payload = await localStorage.getItem(STORAGE_KEY_V6);
    }

    if (hasSharedStorage) {
      delete payload[perspectives];
    }

    return payload;
  }

  async keepOldLocalStoragePerspectives(cleanedState) {
    const keys = await localStorage.keys();

    if (keys.includes(STORAGE_KEY_V6)) {
      const oldLocalStorage = await localStorage.getItem(STORAGE_KEY_V6);
      return { ...cleanedState, perspectives: oldLocalStorage.perspectives };
    }
    return cleanedState;
  }

  async saveToStorage({ state }) {
    let cleanedState = cleanStateBeforeSaving(state, rehydrateStateRemoveList, null, rehydrateStateTransformation);

    if (isSharedStorageAvailable()) {
      cleanedState = await this.keepOldLocalStoragePerspectives(cleanedState);
    }
    try {
      await localStorage.setItem(STORAGE_KEY_V6, cleanedState);
    } catch (err) {
      log.info('Save Rehydrate State:', err);
    }
  }

  shouldLoadState({ action }) {
    return !action;
  }

  shouldSaveState({ state }) {
    let shouldSaveState = false;
    const monitorSliceForChanges = ['styles'];

    forIn(state, (value, key) => {
      const isStateSliceToBeSaved = !rehydrateStateRemoveList.includes(key) || monitorSliceForChanges.includes(key);

      if (isStateSliceToBeSaved) {
        if (this.previousState[key] !== value) {
          shouldSaveState = true;
          this.previousState[key] = value;
        }
      }
    });

    return shouldSaveState;
  }
}

export default RehydratePersistor;

const rehydrateStateRemoveList = [
  contextMenu,
  modal,
  cards,
  'features',
  app,
  '_persist',
  notifications,
  search,
  scene,
  styles,
  searchPrototype,
  settings,
  sharedStorage,
  ...without(documentKeepList, 'visualization'),
];

const rehydrateStateTransformation = (key, value, state) => {
  // Every transformation need to have a corresponding "REHYDRATE" action handler in the Reducer
  switch (key) {
    case perspectives:
      value.perspectives = (value.perspectives || [])
        .filter((p) => p.isPlugin !== true)
        .map((p) => getPerspectiveWithStyle(state)(p.id));

      if (value.rest) delete value.rest;
      delete value.originalPerspective;
      break;
    case search:
      delete value.initialSearchInput;
      delete value.searchRequestId;
      break;
    case userview:
      delete value.dataNeedsRefresh;
      break;
    case connections:
      return pick(value, ['lastUsedDbPerDbms', 'desktopReconnecting']);
    case propertyPreview:
      return pick(value, ['isEnabled']);
    case focused:
      delete value.focusedNewNodeCreator;
      delete value.focusedNewRelationshipCreator;
      break;
    case 'visualization':
      return pick(value, ['showMinimap']);
  }
};

const restoreAndDeleteV5File = async () => {
  const content = await localStorage.getItem(STORAGE_KEY_V5);
  let contentObj = JSON.parse(content);
  contentObj = mapValues(contentObj, (str) => JSON.parse(str));

  delete contentObj.document;
  delete contentObj._persist;

  await localStorage.removeItem(STORAGE_KEY_V5);

  return contentObj;
};
