import { MODAL_TYPE, LEGACY_trackPage as trackPage, useConnectionStatus, useModal } from '@nx/state';
import { Notifications } from '@nx/ui';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { AuthenticatedRoute, UnauthenticatedRoute } from './auth/authenticated-route';
import { Login, LoginCallback } from './auth/login';
import { OrganizationLookupPage } from './organization-lookup';
import { AuraPricingPage } from './pricing-page';

type UpxLayoutProps = {
  children?: ReactNode;
  requireLogin: boolean;
};

export function UpxRouter({ children, requireLogin }: UpxLayoutProps) {
  const location = useLocation();
  const { areCredentialsExpired } = useConnectionStatus();
  const passwordChangeModal = useModal(MODAL_TYPE.PASSWORD_CHANGE);

  if (areCredentialsExpired && !passwordChangeModal.isOpen) {
    passwordChangeModal.open({ passwordChangeRequired: true });
  }

  useEffect(trackPage, [location.pathname]);

  return (
    <>
      <Notifications />
      <Routes location={location}>
        <Route path="/login" element={<Login />} />
        <Route path="/login-callback" element={<LoginCallback />} />
        <Route path="/logout-callback" element={<Login />} />
        <Route path="/organization-lookup" element={<OrganizationLookupPage />} />
        <Route path="/pricing" element={<AuraPricingPage />} />

        <Route element={requireLogin ? <AuthenticatedRoute /> : <UnauthenticatedRoute />}>{children}</Route>
      </Routes>
    </>
  );
}
