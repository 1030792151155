import { Banner, Button, Dialog, LoadingSpinner, Select, TextInput, Tooltip } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { consoleApi, getApiError, getErrorMessage, useInstances } from '@nx/state';
import type { Instance, Project } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { SyntheticEvent } from 'react';
import { useMemo, useState } from 'react';
import * as yup from 'yup';

import type { Validation } from '../utils/validation';
import { validateYup } from '../utils/validation';
import { constructCustomEndpointUrl } from './helpers';
import { formatOptionLabel } from './shared';

const logger = createLogger(APP_SCOPE.aura);

type UpdateCustomEndpointData = {
  endpointId: string;
};
const schema = yup.object({
  endpointId: yup.string().required().label('Custom endpoint'),
});

const validate = (data: UpdateCustomEndpointData) => validateYup(schema, data, false);

type Props = {
  onClose: () => void;
  instance: Instance;
  project: Project;
};

export const ManageInstanceCustomEndpointDialog = ({ onClose, instance, project }: Props) => {
  const { instances } = useInstances({ shouldPoll: false });
  const [validationError, setValidationError] = useState<Validation<UpdateCustomEndpointData> | null>(null);
  const [updateCustomEndpoint, updateCustomEndpointRes] = consoleApi.useUpdateCustomEndpointMutation();
  const { data: endpoints = [], isLoading } = consoleApi.useListCustomEndpointsByProjectQuery(project.id);
  const [data, setData] = useState<UpdateCustomEndpointData>({
    endpointId: endpoints.find((endpoint) => endpoint.dbId === instance.id)?.id ?? '',
  });

  const handleSelectEndpoint = (endpointId?: string) => {
    setValidationError(null);
    setData((prev) => ({ ...prev, endpointId: endpointId ?? '' }));
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    const validation = validate(data);
    if (isNotNullish(validation)) {
      setValidationError(validation);
      return;
    }
    setValidationError(null);

    updateCustomEndpoint({ dbId: instance.id, id: data.endpointId })
      .unwrap()
      .then(() => {
        onClose();
      })
      .catch((error: FetchBaseQueryError | SerializedError | undefined) => {
        const apiError = getApiError(error);
        const message = getErrorMessage(apiError);
        logger.error(message);
      });
  };

  const options = endpoints.map((item) => ({
    key: item.id,
    label: constructCustomEndpointUrl(item),
    value: item.id,
    description: '',
    isDisabled: !item.isTransferable && !(item.isRevertible && item.sourceDbId === instance.id),
    disabledReason: `Custom endpoint '${item.name}' was created or updated too recently.`,
  }));
  const selectedOption = options.find((item) => item.key === data.endpointId);
  const selectedEndpoint = endpoints.find((ep) => ep.id === data.endpointId);

  const instanceForEndpoint = useMemo(() => {
    const endpoint = endpoints.find((ep) => ep.id === data.endpointId);
    const endpointInstance = instances.find((inst) => inst.id === endpoint?.dbId);
    return endpointInstance;
  }, [instances, data, endpoints]);

  const isRevert =
    isNotNullish(selectedEndpoint) && selectedEndpoint.isRevertible && selectedEndpoint.sourceDbId === instance.id;

  return (
    <Dialog isOpen onClose={onClose}>
      <Dialog.Header>Manage custom endpoint</Dialog.Header>
      {isLoading ? (
        <LoadingSpinner className="m-auto" />
      ) : (
        <form onSubmit={handleSubmit}>
          <Dialog.Content className="flex flex-col gap-4">
            <div className="flex flex-col gap-4">
              <TextInput label="Assigned instance" value={instance.name} isReadOnly isFluid />
              <Select
                label="Custom endpoint"
                errorText={validationError?.endpointId?.message}
                type="select"
                size="medium"
                selectProps={{
                  value: selectedOption,
                  options,
                  formatOptionLabel,
                  menuPosition: 'fixed',
                  onChange: (obj) => handleSelectEndpoint(obj?.value),
                }}
              />
            </div>
            <Banner
              hasIcon
              type="info"
              description="It will take a short moment for the custom endpoint to be ready for use again."
              usage="inline"
            />
            <Banner
              hasIcon
              type="warning"
              description="You will not be able to configure the custom endpoint for the next 3 hours."
              usage="inline"
            />
            {isNotNullish(instanceForEndpoint) &&
              instance.id !== instanceForEndpoint.id &&
              isNotNullish(selectedEndpoint) &&
              (selectedEndpoint.isTransferable || isRevert) && (
                <Banner
                  hasIcon
                  type="warning"
                  description={
                    <>
                      <b>{selectedOption?.label}</b> is currently assigned to the instance{' '}
                      <b>{instanceForEndpoint.name}</b>. The custom endpoint will be transferred to the instance{' '}
                      <b>{instance.name}</b> by clicking confirm.
                      {!isRevert && ' You will be able to undo the transfer.'}
                    </>
                  }
                  usage="inline"
                />
              )}
            {updateCustomEndpointRes.isError && (
              <Banner
                type="danger"
                description={getErrorMessage(getApiError(updateCustomEndpointRes.error))}
                usage="inline"
              />
            )}
          </Dialog.Content>
          <Dialog.Actions>
            <Button color="neutral" fill="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Tooltip
              type="simple"
              isPortaled={false}
              isDisabled={instance.id !== selectedEndpoint?.dbId || !selectedEndpoint.isTransferable}
            >
              <Tooltip.Trigger hasButtonWrapper>
                <Button
                  color="primary"
                  type="submit"
                  isLoading={updateCustomEndpointRes.isLoading}
                  isDisabled={
                    isNotNullish(selectedEndpoint) &&
                    (instance.id === selectedEndpoint.dbId || !(selectedEndpoint.isTransferable || isRevert))
                  }
                >
                  Confirm
                </Button>
              </Tooltip.Trigger>
              <Tooltip.Content className="!fixed !w-[200px]">
                <b>{instance.name}</b> is already assigned to this endpoint.
              </Tooltip.Content>
            </Tooltip>
          </Dialog.Actions>
        </form>
      )}
    </Dialog>
  );
};
