import { useRouterState } from '@nx/ui';
import { useState } from 'react';

import { initialFilters } from '../../shared/constants';

export const useRawFilters = () => {
  const { timeRange, dbmsFilters, querySearchString } = useRouterState()?.opsLogs ?? {};
  const [rawFilters, setRawFilters] = useState<ReturnType<typeof initialFilters>>({
    ...initialFilters(),
    ...(dbmsFilters && { dbmsFilters: { ...initialFilters().dbmsFilters, ...dbmsFilters } }),
    ...(timeRange && { timeRange }),
    querySearchString,
  });
  return { rawFilters, setRawFilters };
};
