import type { Except } from 'type-fest';

import type { FullTextIndex } from '../../types/databaseIndexes';
import type { PathSegment } from '../../types/perspective';
import type { Prettify } from '../../types/prettify';

export const indexTypes = {
  FULL_TEXT_INDEX: 'full-text',
  NATIVE_INDEX: 'native',
} as const;

export type IndexType = (typeof indexTypes)[keyof typeof indexTypes];

export interface MetadataNativeIndex {
  type: typeof indexTypes.NATIVE_INDEX;
  label: string;
  name?: string;
  propertyKeys: { key: string; metadataProp: boolean }[];
  isMetadataPropIndex?: boolean;
}

export interface MetadataFullTextIndex
  extends Except<MetadataNativeIndex, 'type'>,
    Except<FullTextIndex, 'propertyKeys' | 'type'> {
  type: typeof indexTypes.FULL_TEXT_INDEX;
  name: string;
}

/**
 * Expected type for an index when it's supposed to be added to the perspective metadata
 */
export type AddingIndex = Prettify<
  Except<MetadataNativeIndex | MetadataFullTextIndex, 'propertyKeys'> & { propertyKeys: string[] }
>;

export interface PerspectiveMetadataState {
  pathSegments: PathSegment[];
  indexes: (MetadataNativeIndex | MetadataFullTextIndex)[];
}
