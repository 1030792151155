import {
  DateTimeFormatter,
  IsoChronology,
  LocalDate as JodaLocalDate, // @ts-expect-error: Outdated @types/js-joda
  YearConstants, // @ts-expect-error: Outdated @types/js-joda
  _ as jodaUtils,
} from '@js-joda/core';
import { isEmpty, isNaN, isNil, isNumber } from 'lodash-es';
import type { Moment } from 'moment';
import moment from 'moment';

import { log } from '../logging';
import { BaseTemporal } from './BaseTemporal';
import type { DateProperties } from './types';
import {
  DATE_FORMAT,
  DATE_REGEX,
  DAYS_0000_TO_1970,
  DAYS_PER_CYCLE,
  MAX_SUPPORTED_YEAR,
  MAX_SUPPORTED_YEAR_EQUIVALENT_EPOCH_DAY,
  MIN_SUPPORTED_YEAR,
  YEAR_OVERFLOW_ERROR,
} from './utils.const';

const { MathUtil } = jodaUtils;

const intDiv = (a: number, b: number): number => {
  return MathUtil.intDiv(a, b);
};

export class Date extends BaseTemporal {
  neo4jDate: DateProperties;

  jodaLocalDate: JodaLocalDate;

  constructor(obj: DateProperties) {
    super();

    const { year: fullYear, month, day } = obj;

    if (fullYear > MAX_SUPPORTED_YEAR || fullYear < MIN_SUPPORTED_YEAR) {
      throw Error(YEAR_OVERFLOW_ERROR);
    }

    let jodaCompatibleYear;
    if (fullYear > 0) {
      jodaCompatibleYear = fullYear > YearConstants.MAX_VALUE ? YearConstants.MAX_VALUE : fullYear;
    } else {
      jodaCompatibleYear = fullYear < YearConstants.MIN_VALUE ? YearConstants.MIN_VALUE : fullYear;
    }

    this.neo4jDate = obj;
    this.jodaLocalDate = JodaLocalDate.of(jodaCompatibleYear, month, day);
  }

  static parseNumberToString(day: number) {
    if (!isNumber(day) || isNaN(day)) {
      return '';
    }

    // make sure the max does not exceed 999999999-12-31
    const epochDay = Math.min(MAX_SUPPORTED_YEAR_EQUIVALENT_EPOCH_DAY, day);

    // algo is from https://js-joda.github.io/js-joda/file/packages/core/src/LocalDate.js.html#lineNumber183
    let adjust: number, adjustCycles: number, doyEst: number, yearEst: number, zeroDay: number;
    zeroDay = epochDay + DAYS_0000_TO_1970;
    zeroDay -= 60;
    adjust = 0;
    if (zeroDay < 0) {
      adjustCycles = MathUtil.intDiv(zeroDay + 1, DAYS_PER_CYCLE) - 1;
      adjust = adjustCycles * 400;
      zeroDay += -adjustCycles * DAYS_PER_CYCLE;
    }
    yearEst = intDiv(400 * zeroDay + 591, DAYS_PER_CYCLE);
    doyEst = zeroDay - (365 * yearEst + intDiv(yearEst, 4) - intDiv(yearEst, 100) + intDiv(yearEst, 400));
    if (doyEst < 0) {
      yearEst--;
      doyEst = zeroDay - (365 * yearEst + intDiv(yearEst, 4) - intDiv(yearEst, 100) + intDiv(yearEst, 400));
    }
    yearEst += adjust;
    const marchDoy0 = doyEst;
    const marchMonth0 = intDiv(marchDoy0 * 5 + 2, 153);
    const month = ((marchMonth0 + 2) % 12) + 1;
    const dom = marchDoy0 - intDiv(marchMonth0 * 306 + 5, 10) + 1;
    yearEst += intDiv(marchMonth0, 10);
    const year = yearEst;

    return `${year}-${month}-${dom}`;
  }

  static parseStringToNumber(date: string) {
    if (isEmpty(date)) {
      return NaN;
    }

    let year, month, day;
    try {
      const dateMatch = DATE_REGEX.exec(date);
      if (!isNil(dateMatch)) {
        year = (dateMatch[1] ?? '+') + dateMatch[2];
        month = dateMatch[3];
        day = dateMatch[4];
      }
    } catch (e) {
      log.debug('Invalid Date Time value');
    }

    const yearInt = parseInt(year ?? '');
    const monthInt = parseInt(month ?? '');
    const dayInt = parseInt(day ?? '');

    // algo is from https://js-joda.github.io/js-joda/file/packages/core/src/LocalDate.js.html#lineNumber1427
    let total = 0;
    total += 365 * yearInt;

    if (yearInt >= 0) {
      total += intDiv(yearInt + 3, 4) - intDiv(yearInt + 99, 100) + intDiv(yearInt + 399, 400);
    } else {
      total -= intDiv(yearInt, -4) - intDiv(yearInt, -100) + intDiv(yearInt, -400);
    }
    total += intDiv(367 * monthInt - 362, 12);
    total += dayInt - 1;

    if (monthInt > 2) {
      total--;
      if (!IsoChronology.isLeapYear(yearInt)) {
        total--;
      }
    }

    return total - DAYS_0000_TO_1970;
  }

  static parseStringToObj(stringVal: string) {
    const newStringVal = BaseTemporal.addSymbolToYearIfNeeded(stringVal);
    let year, month, day;
    try {
      const dateMatch = DATE_REGEX.exec(newStringVal);
      if (!isNil(dateMatch)) {
        year = (dateMatch[1] ?? '+') + dateMatch[2];
        month = dateMatch[3];
        day = dateMatch[4];
      }
    } catch (e) {
      log.debug('Invalid Date Time value');
    }

    const yearInt = parseInt(year ?? '');
    const monthInt = parseInt(month ?? '');
    const dayInt = parseInt(day ?? '');

    return new Date({
      year: yearInt,
      month: monthInt,
      day: dayInt,
    });
  }

  static parseMomentObjToObj(momentObj: Moment) {
    return new Date({
      year: momentObj.year(),
      month: momentObj.month() + 1,
      day: momentObj.date(),
    });
  }

  getYear() {
    return this.neo4jDate.year;
  }

  getMonth() {
    return this.neo4jDate.month;
  }

  getDay() {
    return this.neo4jDate.day;
  }

  hasMs() {
    return false; // interface
  }

  getTimezone() {
    return null; // interface
  }

  toString() {
    const jodaString = this.jodaLocalDate.format(DateTimeFormatter.ISO_LOCAL_DATE).toString();

    const match = DATE_REGEX.exec(jodaString);
    const withoutYear = !isNil(match) ? `${match[3]}-${match[4]}` : '';

    // replace with full year
    const dateString = `${this.getYear()}-${withoutYear}`;

    return BaseTemporal.addSymbolToYearIfNeeded(dateString);
  }

  // the toString() result of neo4j.types.LocalTime
  toNeo4jString() {
    return BaseTemporal.addZeroesToYearIfNeeded(this.toString());
  }

  toPropertyObject() {
    return {
      year: this.getYear(),
      month: this.getMonth(),
      day: this.getDay(),
    };
  }

  toMomentObj() {
    return moment(this.toString(), DATE_FORMAT);
  }
}
