import { Banner, LoadingSpinner } from '@neo4j-ndl/react';
import { useCurrentGuide } from '@nx/state';
import { GUIDES_HTML_TAGS, JsonUiConverter } from '@nx/ui';

const DATABASE_URI_PATTERN = '{neo4j-database-uri}';

const jsonUiConverter = new JsonUiConverter();

interface Props {
  uri: string;
  startHeadersIndex?: number;
}

export const GuideContent = ({ uri, startHeadersIndex = 1 }: Props) => {
  const { guide, fetchStatus } = useCurrentGuide();

  if (fetchStatus === 'loading') {
    return <LoadingSpinner />;
  }

  if (guide === undefined || fetchStatus === 'error') {
    return <Banner type="danger" title="Could not load guide" description={<p>Try again later.</p>} usage="inline" />;
  }

  const contents = guide.contents.map((page) => jsonUiConverter.replaceTextNode(page, DATABASE_URI_PATTERN, uri));

  return contents.map((page, index) => {
    return (
      <div key={index} className="body-medium">
        <h6>
          {index + startHeadersIndex}. {jsonUiConverter.getTitleFromDomJsonByTagName(page, GUIDES_HTML_TAGS.H2)}
        </h6>
        <div className="my-5">{jsonUiConverter.renderDomJson2Jsx(page)}</div>
      </div>
    );
  });
};
