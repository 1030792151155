import { InlineEdit } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import type { Project, ProjectSummary } from '@nx/state';
import { ACTION, consoleApi, getApiError, getErrorMessage, usePermissions } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import * as yup from 'yup';

import { validateYup } from '../../utils/validation';

const logger = createLogger(APP_SCOPE.framework);

export const updateProjectNameSchema = yup.object({
  name: yup.string().label('Project name').max(30).required(),
});

export const validate = (data: { name: string }, { onlyRequired = false }: { onlyRequired?: boolean } = {}) =>
  validateYup(updateProjectNameSchema, data, onlyRequired);

type ProjectRenameProps = {
  project: Project;
  projectSummary: ProjectSummary;
};

export const ProjectRename = ({ project, projectSummary }: ProjectRenameProps) => {
  const { isAllowed } = usePermissions();
  const allowUpdateProjectName = isAllowed(ACTION.UPDATE, `namespaces/${projectSummary.id}`);

  const [updateProjectName] = consoleApi.useUpdateProjectNameMutation();

  const handleRename = (value: string) => {
    // setName(value);
    updateProjectName({ projectId: project.id, name: value })
      .unwrap()
      .catch((error: FetchBaseQueryError | SerializedError | undefined) => {
        const apiError = getApiError(error);
        const errorMessage = getErrorMessage(apiError);
        logger.error(errorMessage);
      });
  };

  return (
    <div className="-ml-1 flex [&_.ndl-icon-svg]:shrink-0 [&_.ndl-inline-idle-container]:!justify-normal [&_h4]:truncate">
      {allowUpdateProjectName ? (
        <InlineEdit
          isDisabled={!allowUpdateProjectName}
          variant="h4"
          hasEditIcon
          defaultValue={project.name}
          onConfirm={handleRename}
          validate={(v) => {
            const validation = validate({ name: v });
            if (isNotNullish(validation) && validation.name !== undefined) {
              return validation.name.message;
            }
            return true;
          }}
        />
      ) : (
        <h4 className="ml-1">{project.name}</h4>
      )}
    </div>
  );
};
