import { flatten, fromPairs, keys, map, mapValues, pipe, uniq } from 'lodash/fp';
import memoize from 'memoize-one';

export const getLabelsOrRelType = (evaluation) => {
  const { type, match } = evaluation;
  let labels = null;
  let relType = null;
  switch (type) {
    case 'category':
      labels = match.labels;
      break;
    case 'label':
      labels = [match.name];
      break;
    case 'relationship':
      relType = match.name;
      break;
    case 'property-key':
    case 'property-value':
      if (match.category) {
        labels = match.labels;
      } else {
        labels = [match.name];
      }
      break;
    case 'value':
      labels = [match.label];
  }
  return {
    labels,
    relType,
  };
};

const buildPathSegmentFiltersForLockedEvaluations = (labelFilterMap, relFilterMap) => {
  if (
    labelFilterMap &&
    relFilterMap &&
    (Object.keys(labelFilterMap).length > 0 || Object.keys(relFilterMap).length > 0)
  ) {
    const metadataFilter = (item) => {
      switch (item.type) {
        case 'category':
          return (item.labels && item.labels.some((label) => labelFilterMap[label])) || false;
        case 'label':
          return labelFilterMap[item.name] || false;
        case 'relationship':
          return relFilterMap[item.name] || false;
      }
      return false;
    };

    const valueFilter = (index) => {
      if (index.type && index.type === 'full-text') {
        return (index.tokenNames && index.tokenNames.some((tokenName) => labelFilterMap[tokenName])) || false;
      }
      return (index.label && labelFilterMap[index.label]) || false;
    };

    return {
      metadataFilter,
      valueFilter,
    };
  }
  return {
    metadataFilter: null,
    valueFilter: null,
  };
};

const getPathSegmentCalculatedData = memoize((pathSegments) => {
  const relTypeToLabel = {};
  const labelToRelTypes = {};
  const labelToLabel = {};

  const addValue = (set, value) => {
    set = set || new Set();
    set.add(value);
    return set;
  };

  pathSegments.forEach(({ source, relationshipType, target }) => {
    relTypeToLabel[relationshipType] = relTypeToLabel[relationshipType] || {};
    relTypeToLabel[relationshipType][source] = addValue(relTypeToLabel[relationshipType][source], target);
    relTypeToLabel[relationshipType][target] = addValue(relTypeToLabel[relationshipType][target], source);

    labelToLabel[source] = addValue(labelToLabel[source], target);
    labelToLabel[target] = addValue(labelToLabel[target], source);

    labelToRelTypes[source] = addValue(labelToRelTypes[source], relationshipType);
    labelToRelTypes[target] = addValue(labelToRelTypes[target], relationshipType);
  });
  return {
    relTypeToLabel,
    labelToRelTypes,
    labelToLabel,
  };
});

const getLabelAndRelFilterMapForEvaluations = (pathSegments, evaluations) => {
  let labelFilterMap = {};

  if (!pathSegments || !evaluations || evaluations.length === 0) {
    return null;
  }

  const { relTypeToLabel, labelToRelTypes, labelToLabel } = getPathSegmentCalculatedData(pathSegments);

  const lastEvaluation = evaluations[evaluations.length - 1];
  const { labels, relType } = getLabelsOrRelType(lastEvaluation);
  let labelsForRelationships = labels;

  if (relType) {
    const labelMap = relTypeToLabel[relType] || {};
    let otherLabels = null;
    if (evaluations.length > 1) {
      const prevEvaluation = evaluations[evaluations.length - 2];
      const { labels: prevLabels } = getLabelsOrRelType(prevEvaluation);
      if (prevLabels) {
        otherLabels = prevLabels;
      }
    }
    if (otherLabels) {
      labelFilterMap = pipe(
        map((label) => [...(labelMap[label] || [])]),
        flatten,
        uniq,
        map((label) => [label, true]),
        fromPairs,
      )(otherLabels);
    } else {
      labelFilterMap = mapValues(() => true, labelMap);
    }

    labelsForRelationships = keys(labelFilterMap);
  } else if (labels) {
    labelFilterMap = pipe(
      map((label) => [...(labelToLabel[label] || [])]),
      flatten,
      uniq,
      map((label) => [label, true]),
      fromPairs,
    )(labels);
  }

  const relFilterMap = pipe(
    map((label) => [...(labelToRelTypes[label] || [])]),
    flatten,
    uniq,
    map((relationshipType) => [relationshipType, true]),
    fromPairs,
  )(labelsForRelationships);

  return {
    labelFilterMap,
    relFilterMap,
  };
};

const getPathSegmentFiltersForLockedEvaluations = (pathSegments, lockedEvaluations) => {
  if (!pathSegments || lockedEvaluations.length === 0) {
    return buildPathSegmentFiltersForLockedEvaluations(null, null);
  }
  const { labelFilterMap, relFilterMap } = getLabelAndRelFilterMapForEvaluations(pathSegments, lockedEvaluations);

  return buildPathSegmentFiltersForLockedEvaluations(labelFilterMap, relFilterMap);
};

export const getPathSegmentFilters = (pathSegments, lockedEvaluations) => {
  let evaluationsFilter = null;
  if (pathSegments) {
    evaluationsFilter = (evaluations) => {
      if (evaluations && evaluations.length > 1) {
        for (let i = 1; i < evaluations.length; i++) {
          const { labelFilterMap, relFilterMap } = getLabelAndRelFilterMapForEvaluations(
            pathSegments,
            evaluations.slice(0, i),
          );
          const { labels, relType } = getLabelsOrRelType(evaluations[i]);
          if (labels) {
            if (!labels.some((label) => labelFilterMap[label])) {
              return false;
            }
          } else if (relType) {
            if (!relFilterMap[relType]) {
              return false;
            }
          }
        }
      }
      return true;
    };
  }
  return {
    ...getPathSegmentFiltersForLockedEvaluations(pathSegments, lockedEvaluations),
    evaluationsFilter,
  };
};
