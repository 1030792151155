import { Banner, LoadingSpinner, Typography } from '@neo4j-ndl/react';
import type { SecurityLogCounts } from '@nx/state';

import { standardFormatAbsoluteNumber, timePeriodFormatter } from '../../../shared/ui-helpers';
import { useLogsContext } from '../hooks/use-logs-context';

const INITIAL_COUNTS = {
  infoLogs: 0,
  warningLogs: 0,
  errorLogs: 0,
};

type StatusSnackbarProps = {
  counts?: SecurityLogCounts | null;
  loading: boolean;
};

export const StatusSnackbar = ({ counts, loading }: StatusSnackbarProps) => {
  const { infoLogs, warningLogs, errorLogs } = counts ?? INITIAL_COUNTS;
  const { filterInput } = useLogsContext();
  const dateFrom = new Date(filterInput.from);
  const dateTo = new Date(filterInput.to);

  const statusText = (
    <Typography variant="body-medium" as="span" className="flex items-center gap-1">
      From {timePeriodFormatter(dateFrom)} to {timePeriodFormatter(dateTo)}, there are{' '}
      {loading ? <LoadingSpinner /> : standardFormatAbsoluteNumber(infoLogs)} info logs,{' '}
      {loading ? <LoadingSpinner /> : standardFormatAbsoluteNumber(warningLogs)} warning logs and{' '}
      {loading ? <LoadingSpinner /> : standardFormatAbsoluteNumber(errorLogs)} error logs.
    </Typography>
  );
  return <Banner name="Selection statuses" title={statusText} />;
};
