import type * as ImportShared from '@nx/import-shared';

// TODO: move to constants and sync with NVL settings
const DEFAULT_RELATIONSHIP_LENGTH = 200;
const DEFAULT_NODE_RADIUS = 50;

export const getNewPosition = (nodes: ImportShared.VisualisationState['nodes']) => {
  const newNodePosition = { x: 0, y: 0 };
  if (nodes.length > 0) {
    const yCoordinates = nodes.map((node) => node.position.y);
    const yMin = Math.min(...yCoordinates);
    const yMax = Math.max(...yCoordinates);
    const ySpread = yMax - yMin;
    const yRange = {
      yMin,
      yMax,
      ySpread,
    };
    const xCoordinates = nodes.map((node) => node.position.x);
    const xMin = Math.min(...xCoordinates);
    const xMax = Math.max(...xCoordinates);
    const xSpread = xMax - xMin;
    const xRange = {
      xMin,
      xMax,
      xSpread,
    };

    if (yRange.ySpread <= xRange.xSpread) {
      newNodePosition.y = yRange.yMax + DEFAULT_RELATIONSHIP_LENGTH + DEFAULT_NODE_RADIUS * 2;
      newNodePosition.x = xRange.xMin;
    } else {
      newNodePosition.x = xRange.xMax + DEFAULT_RELATIONSHIP_LENGTH + DEFAULT_NODE_RADIUS * 2;
      newNodePosition.y = yRange.yMin;
    }
  }
  return newNodePosition;
};

export const clearSelectedState = (state: ImportShared.VisualisationState) => {
  state.nodes.forEach((node) => {
    node.selected = false;
  });
  state.relationships.forEach((rel) => {
    rel.selected = false;
  });
};
