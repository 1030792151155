import { mapValues } from 'lodash-es';
import neo4j from 'neo4j-driver';
import type { Integer } from 'neo4j-driver';
import type { Node as Neo4jNode, Relationship as Neo4jRelationship } from 'neo4j-driver-core';

import type { Node, Relationship } from '../../types/graph';
import type { Nullable } from '../../types/utility';
import { hasOwnProperty, isTruthy } from '../../types/utility';
import { DATE, DATETIME, DURATION, LOCAL_DATETIME, LOCAL_TIME, TIME } from '../temporal/utils.const';
import type { MappedVal, NumberOrInteger, PossibleValues, SpatialObject, Val } from './mappers.types';

export const mapNeo4jTypeString = (type: string) => {
  const stringTypes = ['String', 'Char'];
  const numberTypes = ['Double', 'Float'];
  const bigintTypes = ['Long', 'Short', 'Integer', 'Byte'];

  if (type === 'Boolean') {
    return 'boolean';
  } else if (stringTypes.includes(type)) {
    return 'string';
  } else if (numberTypes.includes(type)) {
    return 'number';
  } else if (bigintTypes.includes(type)) {
    return 'bigint';
  } else if (type.endsWith('Array')) {
    return 'array';
  }
  return type;
};

export const safelyConvertNeo4jInt = (value: Integer): number | string => {
  return neo4j.integer.inSafeRange(value) ? value.toNumber() : value.toString();
};

export const mapProperty = (property: Val): MappedVal => {
  if (property === null || property === undefined) {
    return null;
  } else if (neo4j.isInt(property)) {
    return {
      value: safelyConvertNeo4jInt(property),
      type: 'bigint',
    };
  } else if (Array.isArray(property)) {
    // @ts-expect-error we do not expect array of nulls
    return { value: property.map((item) => mapProperty(item).value).join(', '), type: 'array' };
  } else if (typeof property === 'string') {
    return { value: property, type: 'string' };
  } else if (typeof property === 'boolean') {
    return { value: property, type: 'boolean' };
  }
  const spatiotemporalObject = getSpatioTemporalObject(property);

  if (spatiotemporalObject !== null) {
    let spatialProperties: Record<string, number | bigint | (() => string) | undefined> = {};
    let dateTimeProperties: Record<string, number | (() => string)> = {};
    if (spatiotemporalObject.type === 'Point') {
      spatialProperties = mapValues(spatiotemporalObject.value, (v) =>
        neo4j.types.Integer.isInteger(v) ? v.toNumber() : v,
      );
    } else {
      dateTimeProperties = mapValues(spatiotemporalObject.value, (v) =>
        neo4j.types.Integer.isInteger(v) ? v.toNumber() : v,
      );
    }

    return {
      value: spatiotemporalObject.value.toString(),
      type: spatiotemporalObject.type,
      dateTimeProperties,
      spatialProperties,
    };
  }
  // Remaining possible values from typeof are: number, bigint, symbol, function, object
  return {
    value: JSON.stringify(property),
    type: typeof property as 'number' | 'bigint' | 'symbol' | 'function' | 'object',
  };
};

export const getSpatioTemporalObject = (obj: PossibleValues): Nullable<SpatialObject> => {
  if (hasOwnProperty(obj, 'srid') && hasOwnProperty(obj, 'x') && hasOwnProperty(obj, 'y')) {
    return {
      value: new neo4j.types.Point(
        obj.srid as NumberOrInteger,
        obj.x as number,
        obj.y as number,
        // @ts-expect-error it's difficult to narrow down the type down to number | undefined
        obj.z as number | undefined,
      ),
      type: 'Point',
    };
  } else if (
    hasOwnProperty(obj, 'year') &&
    hasOwnProperty(obj, 'month') &&
    hasOwnProperty(obj, 'day') &&
    hasOwnProperty(obj, 'hour') &&
    hasOwnProperty(obj, 'minute') &&
    hasOwnProperty(obj, 'second') &&
    hasOwnProperty(obj, 'nanosecond') &&
    (hasOwnProperty(obj, 'timeZoneOffsetSeconds') || hasOwnProperty(obj, 'timeZoneId'))
  ) {
    return {
      value: new neo4j.types.DateTime(
        obj.year,
        obj.month,
        obj.day,
        obj.hour,
        obj.minute,
        obj.second,
        obj.nanosecond,
        // @ts-expect-error type inference does not work with || operator
        obj.timeZoneOffsetSeconds,
        // @ts-expect-error type inference does not work with || operator
        obj.timeZoneId,
      ),
      type: DATETIME,
    };
  } else if (
    hasOwnProperty(obj, 'year') &&
    hasOwnProperty(obj, 'month') &&
    hasOwnProperty(obj, 'day') &&
    hasOwnProperty(obj, 'hour') &&
    hasOwnProperty(obj, 'minute') &&
    hasOwnProperty(obj, 'second') &&
    hasOwnProperty(obj, 'nanosecond')
  ) {
    return {
      value: new neo4j.types.LocalDateTime(
        obj.year as NumberOrInteger,
        obj.month as NumberOrInteger,
        obj.day as NumberOrInteger,
        obj.hour as NumberOrInteger,
        obj.minute as NumberOrInteger,
        obj.second as NumberOrInteger,
        obj.nanosecond as NumberOrInteger,
      ),
      type: LOCAL_DATETIME,
    };
  } else if (
    hasOwnProperty(obj, 'hour') &&
    hasOwnProperty(obj, 'minute') &&
    hasOwnProperty(obj, 'second') &&
    hasOwnProperty(obj, 'nanosecond') &&
    hasOwnProperty(obj, 'timeZoneOffsetSeconds')
  ) {
    return {
      value: new neo4j.types.Time(
        obj.hour as NumberOrInteger,
        obj.minute as NumberOrInteger,
        obj.second as NumberOrInteger,
        obj.nanosecond as NumberOrInteger,
        obj.timeZoneOffsetSeconds as NumberOrInteger,
      ),
      type: TIME,
    };
  } else if (
    hasOwnProperty(obj, 'hour') &&
    hasOwnProperty(obj, 'minute') &&
    hasOwnProperty(obj, 'second') &&
    hasOwnProperty(obj, 'nanosecond')
  ) {
    return {
      value: new neo4j.types.LocalTime(
        obj.hour as NumberOrInteger,
        obj.minute as NumberOrInteger,
        obj.second as NumberOrInteger,
        obj.nanosecond as NumberOrInteger,
      ),
      type: LOCAL_TIME,
    };
  } else if (
    hasOwnProperty(obj, 'months') &&
    hasOwnProperty(obj, 'days') &&
    hasOwnProperty(obj, 'seconds') &&
    hasOwnProperty(obj, 'nanoseconds')
  ) {
    return {
      value: new neo4j.types.Duration(
        obj.months as NumberOrInteger,
        obj.days as NumberOrInteger,
        obj.seconds as NumberOrInteger,
        obj.nanoseconds as NumberOrInteger,
      ),
      type: DURATION,
    };
  } else if (hasOwnProperty(obj, 'year') && hasOwnProperty(obj, 'month') && hasOwnProperty(obj, 'day')) {
    return {
      value: new neo4j.types.Date(
        obj.year as NumberOrInteger,
        obj.month as NumberOrInteger,
        obj.day as NumberOrInteger,
      ),
      type: DATE,
    };
  }
  return null;
};

// map properties from neo4j type to JavaScript-friendly type
// to facilitate development in React/Redux app
// plus, store only the plain object in redux store
export const mapProperties = (properties: Record<string, unknown> | undefined) => {
  if (isTruthy(properties)) {
    return Object.keys(properties)
      .filter((key) => properties[key] !== null)
      .reduce<Record<string, ReturnType<typeof mapProperty>>>((result, key) => {
        result[key] = mapProperty(properties[key]);
        return result;
      }, {});
  }
  return null;
};

const NODE = 'Node';
export const isMappedNode = (target: { entityType?: string }): target is Node => target?.entityType === NODE;

export const mapNode = (node: Neo4jNode): Node => {
  return {
    entityType: NODE,
    ...node,
    id: node.elementId,
    labelString: node.labels.join(', '),
    mappedProperties: mapProperties(node.properties),
  };
};

const REL = 'Rel';
export const isMappedRel = (target: { entityType?: string }): target is Relationship => target?.entityType === REL;

export const mapRel = (rel: Neo4jRelationship): Relationship => {
  return {
    entityType: REL,
    ...rel,
    id: rel.elementId,
    startId: rel.startNodeElementId,
    endId: rel.endNodeElementId,
    mappedProperties: mapProperties(rel.properties),
  };
};
