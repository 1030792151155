import type { Capability } from '@nx/state';

import type { NodeSuggestion, RelationshipSuggestion, TurnedOffSuggestion } from './types';
import { SUGGESTION_TYPE } from './types';

export const TURNED_OFF_SUGGESTION: Readonly<TurnedOffSuggestion> = {
  type: SUGGESTION_TYPE.TURNED_OFF_SUGGESTION,
} as const;

export const INITIAL_LOCKED_SUGGESTIONS = [TURNED_OFF_SUGGESTION];
export const INITIAL_SELECTED_SUGGESTION_PATH_IDX = 0;
export const INITIAL_INPUT_TEXT = '';
export const INITIAL_FILTER = '';

export const RELATIONSHIP_DIRECTION_TO_LEFT = 'left';
export const RELATIONSHIP_DIRECTION_TO_RIGHT = 'right';

// FACETED_RESULTS_LIMIT is used to limit how many nodes to be fetched and used in FacetedResults
// queryResultLimit is used to limit how many nodes to be shown in scene when click add
export const FACETED_RESULTS_LIMIT = 10000;

export const SEARCH_SUGGESTION_TIMEOUT_IN_MS = 60 * 1000; // 1min
export const USER_INPUT_SUGGESTION_DEBOUNCE_DELAY = 500;

export const FULL_TEXT_SEARCH_SUGGESTION_MINIMAL_LENGTH = 3;
export const STRUCTURED_SUGGESTION_RESULT_LIMIT = 10;

export const BETWEEN_CONDITION_VALUES_SEPARATOR = '--';

export const SUGGESTION_VALUE_PLACEHOLDER = '(any)';

export const SUGGESTION_ANY_RELATIONSHIP: Readonly<RelationshipSuggestion> = {
  type: SUGGESTION_TYPE.RELATIONSHIP,
  relationshipType: SUGGESTION_VALUE_PLACEHOLDER,
};

export const SUGGESTION_ANY_NODE: Readonly<NodeSuggestion> = {
  type: SUGGESTION_TYPE.NODE,
  categoryName: SUGGESTION_VALUE_PLACEHOLDER,
};

export const moreThenOneSpaceRegex = /\s\s+/g;

export const MIN_SUGGESTION_LETTERS = 3;

export const PROPERTY_CONDITION_EQUALS = 'equals';
export const PROPERTY_CONDITION_NOT_EQUALS = 'does not equal';
export const PROPERTY_CONDITION_CONTAINS = 'contains';
export const PROPERTY_CONDITION_STARTS_WITH = 'starts with';
export const PROPERTY_CONDITION_ENDS_WITH = 'ends with';
export const PROPERTY_CONDITION_GREATER_THAN = 'greater than';
export const PROPERTY_CONDITION_GREATER_THAN_OR_EQUAL = 'greater than or equal to';
export const PROPERTY_CONDITION_LESS_THAN = 'less than';
export const PROPERTY_CONDITION_LESS_THAN_OR_EQUAL = 'less than or equal to';
export const PROPERTY_CONDITION_BEFORE = 'before';
export const PROPERTY_CONDITION_AFTER = 'after';
export const PROPERTY_CONDITION_BETWEEN = 'between';
export const PROPERTY_CONDITION_NOT_BETWEEN = 'not between';

export const BETWEEN_CONDITION_VALUES = [PROPERTY_CONDITION_BETWEEN, PROPERTY_CONDITION_NOT_BETWEEN];

export const GEN_AI_CAPABILITY_NAME: Capability = 'aura:genai';
