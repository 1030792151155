import type { CmiScrapeConfig, Instance, ProjectSummary } from '@nx/state';
import { opsApi } from '@nx/state';

function findExcludedIds(editedConfig: CmiScrapeConfig, scrapeConfig: CmiScrapeConfig, instances: Instance[]) {
  let excludedIds = [];
  const includedIds = editedConfig.instances.map((instance) => instance.instanceId);
  if (editedConfig.enableForNewInstances) {
    excludedIds = instances.map((instance) => instance.id).filter((id) => !includedIds.includes(id));
  } else {
    excludedIds = scrapeConfig.instances
      .map((instance) => instance.instanceId)
      .filter((id) => !includedIds.includes(id));
  }
  return excludedIds;
}

export const useMetricsEndpointConfig = (activeProject: ProjectSummary, instances: Instance[]) => {
  const {
    data: scrapeConfig,
    isFetching: configIsFetching,
    isLoading: configIsLoading,
    isError: configIsError,
    error: configError,
  } = opsApi.useGetCmiConfigQuery(activeProject.id, {
    refetchOnMountOrArgChange: true,
  });

  const [setConfig, configCommitStatus] = opsApi.useUpdateCmiConfigMutation();

  const commitConfig = (editedConfig: CmiScrapeConfig | undefined): Promise<void> => {
    if (editedConfig !== undefined && scrapeConfig !== undefined) {
      let excludedIds: string[] = [];
      if (editedConfig.instances.length > 0) {
        excludedIds = findExcludedIds(editedConfig, scrapeConfig, instances);
      }
      return setConfig({ config: editedConfig, excludedIds: excludedIds }).unwrap();
    }

    return new Promise((_, reject) => reject(new Error('Invalid scrape configuration')));
  };

  return {
    commitConfig,
    scrapeConfig,
    configFetchStatus: {
      error: configError,
      isError: configIsError,
      isLoading: configIsLoading,
      isFetching: configIsFetching,
    },
    configCommitStatus,
  };
};
