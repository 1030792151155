export enum BILLING_MODEL {
  PAY_AS_YOU_GO = 'Pay As You Go',
  PREPAID_CONSUMPTION = 'Prepaid Consumption',
}

export enum BILLING_PROCESSOR {
  DIRECT = 'Direct',
  AZURE_MARKETPLACE = 'Azure Marketplace',
  GCP_MARKETPLACE = 'GCP Marketplace',
  AWS_MARKETPLACE = 'AWS Marketplace',
}

export enum PAYMENT_METHOD {
  CREDIT_CARD = 'Credit Card',
  MARKETPLACE = 'Marketplace',
  BALANCE = 'Balance',
}

export enum BILLING_ACCOUNT_STATUS {
  CREATED = 'Created',
  ACTIVATED = 'Activated',
  ACTIVE_IN_USE = 'Active in Use',
  SUSPENDED = 'Suspended',
  DEACTIVATED = 'Deactivated',
}

export enum PRICING_PLAN_NAME {
  LEGACY_ENTERPRISE = 'legacy_enterprise',
  VIRTUAL_DEDICATED_CLOUD = 'virtual_dedicated_cloud',
  NON_BILLABLE = 'non_billable',
  PAY_AS_YOU_GO = 'pay_as_you_go',
}

export interface BillingAccount {
  id: string;
  name: string;
  billingModel: BILLING_MODEL;
  billingProcessor: BILLING_PROCESSOR;
  organizationId: string;
  paymentMethod: PAYMENT_METHOD;
  pricingPlan: PRICING_PLAN_NAME;
  projectIds: string[];
  email: string;
  reason: string;
  status: BILLING_ACCOUNT_STATUS;
}
