import { Typography } from '@neo4j-ndl/react';
import { ACTION, INVITE_STATUS, consoleApi, usePermissions } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import { DataGridHelpers, SearchField } from '@nx/ui';
import { skipToken } from '@reduxjs/toolkit/query';
import { createColumnHelper, getFilteredRowModel } from '@tanstack/react-table';
import classnames from 'classnames';
import { useMemo, useState } from 'react';

import { useProjectAppContext } from '../../app-context';
import { useCommonTable } from '../../components/table';
import { ROLE_TYPE } from '../entities/model';
import { isProjectRole, projectRoleFriendlyName } from '../helpers';
import { ProjectInviteRowAction, ProjectMemberRowAction, RoleLabel } from './common';
import type { UserRow } from './types';

const columnHelper = createColumnHelper<UserRow>();

export function ProjectUsers() {
  const { activeProject } = useProjectAppContext();
  const { isAllowed } = usePermissions();
  const canReadInvites = isAllowed(ACTION.READ, `namespaces/${activeProject.id}/invites`);
  const [globalFilter, setGlobalFilter] = useState('');

  const getProjectMembersRes = consoleApi.useListProjectMembersQuery(activeProject.id, { pollingInterval: 15000 });
  const getProjectInvitesRes = consoleApi.useListProjectInvitesQuery(
    canReadInvites ? { projectId: activeProject.id, status: INVITE_STATUS.PENDING } : skipToken,
    { pollingInterval: 15000 },
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('email', {
        header: 'User',
        cell: (cx) => {
          const classes = classnames('truncate', {
            'text-neutral-text-weaker': isNotNullish(cx.row.original.inviteStatus),
          });
          return (
            <Typography variant="body-medium" as="span" className={classes}>
              <DataGridHelpers.TruncatedColumn value={cx.getValue()} />
            </Typography>
          );
        },
      }),
      columnHelper.accessor('roles', {
        header: 'Project role',
        cell: (cx) => (
          <div className="flex w-full justify-between gap-2" data-testid={`role-label-${cx.row.original.email}`}>
            {cx.getValue().map((role) => (
              <RoleLabel roleType={ROLE_TYPE.PROJECT} key={`${cx.row.original.userId}-${role}`}>
                {projectRoleFriendlyName[role]}
              </RoleLabel>
            ))}
          </div>
        ),
      }),
      columnHelper.accessor('inviteStatus', {
        header: () => null,
        cell: (cx) => {
          const { inviteStatus } = cx.row.original;
          if (isNullish(inviteStatus)) {
            return null;
          }

          return (
            <Typography
              variant="body-medium"
              data-testid={`invitation-pending-${cx.row.original.email}`}
              as="div"
              className="truncate"
            >
              <DataGridHelpers.TruncatedColumn value={'<invitation pending>'}>
                &lt;invitation pending&gt;
              </DataGridHelpers.TruncatedColumn>
            </Typography>
          );
        },
      }),
      columnHelper.display({
        id: 'actions',
        cell: (cx) => {
          const row = cx.row.original;
          if (row.inviteStatus === INVITE_STATUS.PENDING) {
            return <ProjectInviteRowAction user={row} cx={cx} />;
          }

          return <ProjectMemberRowAction user={row} cx={cx} project={activeProject} />;
        },
        minSize: 90,
        maxSize: 90,
        enableResizing: false,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const data: UserRow[] = useMemo(() => {
    const members: UserRow[] =
      getProjectMembersRes.data?.map((member) => ({
        userId: member.userId,
        email: member.email,
        roles: member.roles.map((role) => role.name),
        projectId: activeProject.id,
      })) ?? [];
    // I'm not sure when the invite email would ever be undefined but it is
    // typed like this in console so leaving it here for now.
    const invites: UserRow[] =
      getProjectInvitesRes.data?.map((invite) => ({
        userId: invite.userId ?? invite.email ?? '',
        email: invite.email ?? invite.userId ?? '',
        roles: invite.roles.filter((role) => {
          // This is a temporary solution to remove the org roles in the invite
          return isProjectRole(role);
        }),
        inviteStatus: invite.status,
        inviteId: invite.id,
        projectId: activeProject.id,
      })) ?? [];
    return invites.concat(members);
  }, [activeProject.id, getProjectInvitesRes.data, getProjectMembersRes.data]);

  const table = useCommonTable({
    columns,
    data,
    initialState: {
      pagination: { pageSize: 25 },
    },
    state: {
      globalFilter,
      columnPinning: {
        right: ['actions'],
      },
    },
    defaultColumn: {
      minSize: 100,
    },
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
  });

  return (
    <DataGridHelpers.Wrapper>
      <DataGridHelpers.OuterHeader>
        <div className="flex w-full flex-wrap justify-between gap-2">
          <div className="flex basis-[400px] gap-2">
            <SearchField
              className="min-w-36 grow"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
              htmlAttributes={{ 'aria-label': 'Search for project user' }}
            />
          </div>
        </div>
      </DataGridHelpers.OuterHeader>
      <DataGridHelpers.DataGridRightColumnPinned<UserRow>
        isLoading={getProjectInvitesRes.isLoading || getProjectMembersRes.isLoading}
        tableInstance={table}
        styling={{
          headerStyle: 'clean',
          borderStyle: 'all-sides',
        }}
      />
    </DataGridHelpers.Wrapper>
  );
}
