import { Banner, TextInput } from '@neo4j-ndl/react';
import { MagnifyingGlassIconOutline } from '@neo4j-ndl/react/icons';
import type { Instance, Project } from '@nx/state';
import { opsApi } from '@nx/state';
import { DataGridHelpers } from '@nx/ui';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import { useStatusTableColumns } from './hooks/use-status-table-columns';
import { useStatusTableData } from './hooks/use-status-table-data';
import type { EndpointConfigModalContext } from './modals/endpoint-config-modal';
import EndpointConfigModal from './modals/endpoint-config-modal';
import { CMI_STATUS_API_POLLING_INTERVAL } from './shared/constants';

export const MetricsEndpointsStatusTable = ({
  activeProject,
  instances,
  viewPrometheusConfig,
  cmiDomain,
  allowMetricsGranularitySettings,
}: {
  activeProject: Project;
  instances: Instance[];
  viewPrometheusConfig: (id: string | null) => void;
  cmiDomain: string;
  allowMetricsGranularitySettings: boolean;
}) => {
  const [endpointConfigModalContext, setEndpointConfigModalContext] = useState<EndpointConfigModalContext>({
    isOpen: false,
    endpointStatus: null,
  });
  const { data: cmiStatusData, isError: isConfigStatusError } = opsApi.useGetCmiStatusQuery(activeProject.id, {
    pollingInterval: CMI_STATUS_API_POLLING_INTERVAL,
    skipPollingIfUnfocused: true,
  });
  const endpointStatus = useMemo(() => {
    return cmiStatusData ?? [];
  }, [cmiStatusData]);

  const data = useStatusTableData(instances, endpointStatus, activeProject);
  const columns = useStatusTableColumns(
    activeProject,
    cmiDomain,
    instances,
    viewPrometheusConfig,
    setEndpointConfigModalContext,
  );

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableColumnPinning: true,
    state: {
      columnPinning: {
        right: ['actions'],
      },
    },
  });

  return isConfigStatusError ? (
    <Banner type="danger" title="An error occurred" description={'Failed to fetch endpoint statuses.'} usage="inline" />
  ) : (
    <div>
      <DataGridHelpers.OuterHeader>
        <TextInput
          isFluid={true}
          className="mr-4 w-[30%]"
          size="medium"
          placeholder="Search"
          leftElement={<MagnifyingGlassIconOutline />}
          onChange={(e) => table.setGlobalFilter(String(e.target.value))}
          htmlAttributes={{ 'aria-label': 'Search metrics integration status table' }}
        />
      </DataGridHelpers.OuterHeader>
      <DataGridHelpers.Wrapper>
        <DataGridHelpers.DataGridRightColumnPinned
          tableInstance={table}
          isResizable={false}
          styling={{ headerStyle: 'clean', borderStyle: 'horizontal' }}
          rootProps={{
            'aria-label': 'Status table',
          }}
        />
      </DataGridHelpers.Wrapper>
      {endpointConfigModalContext.endpointStatus !== null && (
        <EndpointConfigModal
          open={endpointConfigModalContext.isOpen}
          onClose={() => {
            setEndpointConfigModalContext({ ...endpointConfigModalContext, isOpen: false });
          }}
          instances={instances}
          activeProject={activeProject}
          endpointStatus={endpointConfigModalContext.endpointStatus}
          allowMetricsGranularitySettings={allowMetricsGranularitySettings}
        />
      )}
    </div>
  );
};
