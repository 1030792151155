import { differenceWith } from 'lodash-es';

import bolt, { USER_QUERY } from '../bolt/bolt';
import { log } from '../logging';
import {
  countNeighbourNodesForIds,
  countNeighbourRelsForIds,
  getConnectedEntitiesForSelectedNodes,
  getLabels,
  getNeighboursForIds,
  getNodesCountByLabel,
  getRefreshDataCypher,
  getRelevantRelationshipTypes,
  getRelsByIds,
  getShortestPathQuery,
} from '../queries/queryHandler';
import { graphMapper, labelMapper, mapConnectedEntities } from '../queries/resultMapper';
import type {
  FindShortestPath,
  GetConnectedEntities,
  GetNeighbourNodesRelsCount,
  GetNeighbourNodesRelsCountByCategory,
  GetNeighbourNodesRelsCountByPair,
  GetNeighboursWithNodeId,
  GetNodesAndRelationships,
  GetRelsToReveal,
} from './types';

const MAX_NODES_PER_LABEL = 1000;

export const getLabelsWithNodesUnderLimit = async (database: string) => {
  const labelsQuery = getLabels();

  try {
    const labels = await bolt.readTransaction(labelsQuery, { database });
    const nodesCountByLabelQuery = getNodesCountByLabel(labelMapper(labels.records), MAX_NODES_PER_LABEL);
    const nodesCountByLabelCount =
      nodesCountByLabelQuery !== ''
        ? await bolt.readTransaction(nodesCountByLabelQuery, { database })
        : { records: [] };

    return labelMapper(nodesCountByLabelCount.records);
  } catch (error) {}
};

export const getNeighboursWithNodeId = async ({
  nodeId,
  visibleLabels = [],
  pathSegments = [],
  hiddenRelationshipTypes = [],
  schema,
  isHttpQueryApiEnabled,
  responseHandler,
  mapper = graphMapper,
  limit,
  serverVersion,
}: GetNeighboursWithNodeId) => {
  const relevantRelationshipTypes = getRelevantRelationshipTypes(pathSegments, hiddenRelationshipTypes);

  if (visibleLabels.length === 0 || relevantRelationshipTypes.length === 0) {
    responseHandler(graphMapper([]));
  } else {
    const { cypherForNewNodes, parameters } = getNeighboursForIds(
      [nodeId],
      undefined,
      undefined,
      relevantRelationshipTypes,
      visibleLabels,
      schema,
      limit,
      serverVersion,
      null,
      isHttpQueryApiEnabled,
    );

    const boltReadTransaction = await bolt.readTransaction(cypherForNewNodes, { parameters, type: USER_QUERY });
    responseHandler?.(mapper(boltReadTransaction.records));
  }
};

export const getConnectedEntities = async ({
  selectedNodes = [],
  visibleNodeIds = [],
  visibleRelIds = [],
  visibleLabels = [],
  pathSegments = [],
  hiddenRelationshipTypes = [],
  isV5OrGreater = false,
}: GetConnectedEntities) => {
  const relevantRelationshipTypes = getRelevantRelationshipTypes(pathSegments, hiddenRelationshipTypes);

  if (visibleLabels.length === 0 || relevantRelationshipTypes.length === 0) {
    return [];
  }

  const allowedRelationshipTypes = differenceWith(relevantRelationshipTypes, ...hiddenRelationshipTypes);
  const { cypher, parameters } = getConnectedEntitiesForSelectedNodes(
    selectedNodes,
    visibleNodeIds,
    visibleRelIds,
    visibleLabels,
    allowedRelationshipTypes,
    isV5OrGreater,
  );

  try {
    const result = await bolt.readTransaction(cypher, { parameters, type: USER_QUERY });
    return result.records.map((r) => mapConnectedEntities(r, isV5OrGreater));
  } catch (error) {
    log.error('An error occurred while searching for connected entities', error);
    return [];
  }
};

export const getNodesAndRelationships = async ({
  nodeIds,
  relationshipIds,
  visibleRelationshipTypes,
  visibleLabels,
  schema,
  responseHandler,
  isV5OrGreater,
}: GetNodesAndRelationships) => {
  const { cypher, parameters } = getRefreshDataCypher({
    nodeIds,
    relationshipIds,
    schema,
    visibleLabels,
    visibleRelationshipTypes,
    isV5OrGreater,
  });

  if (cypher != null) {
    try {
      const result = await bolt.readTransaction(cypher, { parameters, type: USER_QUERY });
      responseHandler?.(graphMapper(result.records));
    } catch (error: any) {
      responseHandler?.({ error });
    }
  } else {
    responseHandler?.(graphMapper([]));
  }
};

export const findShortestPath = async ({
  sourceNodeId,
  targetNodeId,
  schema,
  visibleLabels,
  visibleRelationshipTypes,
  responseHandler,
  isV5OrGreater,
}: FindShortestPath) => {
  const { cypher, parameters } =
    getShortestPathQuery(sourceNodeId, targetNodeId, schema, visibleRelationshipTypes, visibleLabels, isV5OrGreater) ??
    {};

  if (cypher != null && parameters != null) {
    try {
      const result = await bolt.readTransaction(cypher, { parameters, type: USER_QUERY });
      responseHandler?.(graphMapper(result.records));
    } catch (error: any) {
      responseHandler?.({ error });
    }
  } else {
    responseHandler?.({ error: 'No query could be generated for the provided input' });
  }
};

export const getNeighbourNodesRelsCount = async ({
  nodesToExpand,
  excludeNodesIds,
  excludeRelsIds,
  relType,
  visibleLabels = [],
  pathSegments = [],
  hiddenRelationshipTypes,
  direction = null,
  isV5OrGreater = false,
}: GetNeighbourNodesRelsCount) => {
  const relevantRels =
    relType != null ? [relType] : getRelevantRelationshipTypes(pathSegments, hiddenRelationshipTypes);
  const nodesCypher = countNeighbourNodesForIds(
    nodesToExpand,
    excludeNodesIds,
    relevantRels,
    visibleLabels,
    direction,
    isV5OrGreater,
  );
  const relsCypher = countNeighbourRelsForIds(
    nodesToExpand,
    excludeRelsIds,
    relevantRels,
    visibleLabels,
    direction,
    isV5OrGreater,
  );

  try {
    const nodesRecords = await bolt.readTransaction(nodesCypher.cypher, { parameters: nodesCypher.parameters });
    const relsRecords = await bolt.readTransaction(relsCypher.cypher, { parameters: relsCypher.parameters });

    return {
      nodes: nodesRecords.records[0]?.get('nodeCount').toNumber(),
      rels: relsRecords.records[0]?.get('relCount').toNumber(),
    };
  } catch (error) {
    log.error("An error occured while searching for relationships' types. ", error);
    return { nodes: 0, rels: 0 };
  }
};

export const getNeighbourNodesRelsCountByCategory = async ({
  nodesToExpand,
  excludeNodesIds,
  excludeRelsIds,
  pathSegments = [],
  hiddenRelationshipTypes,
  category,
  isV5OrGreater,
}: GetNeighbourNodesRelsCountByCategory) => {
  const relevantRels = getRelevantRelationshipTypes(pathSegments, hiddenRelationshipTypes);

  if (category.labels.length === 0 || relevantRels.length === 0) {
    return { name: null, id: null, count: { nodes: 0, rels: 0 } };
  }
  const nodesCypher = countNeighbourNodesForIds(
    nodesToExpand,
    excludeNodesIds,
    relevantRels,
    category.labels,
    null,
    isV5OrGreater,
  );
  const relsCypher = countNeighbourRelsForIds(
    nodesToExpand,
    excludeRelsIds,
    relevantRels,
    category.labels,
    null,
    isV5OrGreater,
  );

  try {
    const nodesRecords = await bolt.readTransaction(nodesCypher.cypher, { parameters: nodesCypher.parameters });
    const relsRecords = await bolt.readTransaction(relsCypher.cypher, { parameters: relsCypher.parameters });
    return {
      ...category,
      count: {
        nodes: nodesRecords.records[0]?.get('nodeCount').toNumber(),
        rels: relsRecords.records[0]?.get('relCount').toNumber(),
      },
    };
  } catch (error) {
    log.error("An error occured while searching for categories' types. ", error);
    return { name: null, id: null, count: { nodes: 0, rels: 0 } };
  }
};

export const getNeighbourNodesRelsCountByPair = async ({
  nodesToExpand,
  excludeNodesIds,
  excludeRelsIds,
  pathSegments = [],
  hiddenRelationshipTypes,
  pair,
  isV5OrGreater,
}: GetNeighbourNodesRelsCountByPair) => {
  const relevantLabels = pair.category?.labels ?? [];
  const relevantRels = pair.relationship?.name != null ? [pair.relationship.name] : [];
  const direction = pair.relationship?.direction ?? null;

  if (relevantLabels.length === 0 || relevantRels.length === 0) {
    return { id: null, name: null, count: { nodes: 0, rels: 0 } };
  }
  const nodesCypher = countNeighbourNodesForIds(
    nodesToExpand,
    excludeNodesIds,
    relevantRels,
    relevantLabels,
    direction,
    isV5OrGreater,
  );
  const relsCypher = countNeighbourRelsForIds(
    nodesToExpand,
    excludeRelsIds,
    relevantRels,
    relevantLabels,
    direction,
    isV5OrGreater,
  );
  try {
    const nodesRecords = await bolt.readTransaction(nodesCypher.cypher, { parameters: nodesCypher.parameters });
    const relsRecords = await bolt.readTransaction(relsCypher.cypher, { parameters: relsCypher.parameters });

    return {
      ...pair,
      id: `${pair.relationship?.name}-${pair.category?.name}-${pair.relationship?.direction}`,
      count: {
        nodes: nodesRecords.records[0]?.get('nodeCount').toNumber(),
        rels: relsRecords.records[0]?.get('relCount').toNumber(),
      },
    };
  } catch (error) {
    log.error("An error occurred while searching for pairs' types. ", error);
    return { id: null, count: { nodes: 0, rels: 0 } };
  }
};

export const getRelsToReveal = async ({ relIds, responseHandler, isV5OrGreater }: GetRelsToReveal) => {
  const { cypher, parameters } = getRelsByIds({ relIds, isV5OrGreater });

  try {
    const result = await bolt.readTransaction(cypher, { parameters });
    responseHandler(graphMapper(result.records));
  } catch (error) {
    log.error('An error occurred while retrieving relationships. ', error);
  }
};
