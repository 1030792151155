import { Banner, Checkbox, Select, TextInput } from '@neo4j-ndl/react';
import type { Instance } from '@nx/state';
import { NEO4J_MANAGED_KEY, TIER, useEncryptionKeys } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import { useEffect, useState } from 'react';

import { sizeStringToBytesInt } from '../../../utils/string';
import type { Validation } from '../../../utils/validation';
import { CDCCloneDBWarning, instanceHasCDCEnabled } from '../../cdc';
import type { CloneToExistingData } from './entities/model';

const getTargetInstanceOptions = (instance: Instance, targetInstances: Instance[]) => {
  // Consider including applied settings in API response for Free tier DBs
  const sourceStorage = instance.tier === TIER.FREE ? '2GB' : instance.appliedSettings.storage;

  if (isNullish(sourceStorage)) {
    return [];
  }
  return targetInstances.map((targetInstance) => {
    const option = {
      label: targetInstance.name,
      value: targetInstance,
      key: targetInstance.id,
      isDisabled: false,
      description: `Memory: ${targetInstance.appliedSettings.memory ?? '-'}; CPU: ${
        targetInstance.appliedSettings.cpu ?? '-'
      }; Storage: ${targetInstance.appliedSettings.storage ?? '-'}`,
    };
    if (
      isNullish(targetInstance.appliedSettings.storage) ||
      sizeStringToBytesInt(targetInstance.appliedSettings.storage) < sizeStringToBytesInt(sourceStorage)
    ) {
      option.label += ' (Instance is not large enough to clone into)';
      option.isDisabled = true;
      return option;
    }
    if (
      isNotNullish(instance.encryptionKeyRef) &&
      instance.encryptionKeyRef !== NEO4J_MANAGED_KEY &&
      isNullish(targetInstance.encryptionKeyRef)
    ) {
      option.label += ' (Cannot clone to an instance encrypted by Neo4j Managed Key)';
      option.isDisabled = true;
    }
    return option;
  });
};

const formatOptionLabel = (
  { label, description }: { label: string; description: string },
  { context }: { context: string },
) => {
  if (context === 'value') {
    return <div className="flex flex-row whitespace-break-spaces">{label}</div>;
  } else if (context === 'menu') {
    return (
      <div className="flex grow flex-row justify-between">
        <span className="w-1/2">{label}</span>
        <span className="text-neutral-text-weaker ml-4 break-words">{description ? description : ''}</span>
      </div>
    );
  }
  return null;
};

type CloneToExistingFormProps = {
  instance: Instance;
  targetInstances: Instance[];
  data: CloneToExistingData;
  onChange: (data: CloneToExistingData) => void;
  validation: Validation<CloneToExistingData> | null;
};

export const CloneToExistingForm = ({
  instance,
  targetInstances,
  data,
  onChange,
  validation,
}: CloneToExistingFormProps) => {
  const [isDefaultConfirmed, setIsDefaultConfirmed] = useState(false);
  const [differentEncryptionConfirmed, setDifferentEncryptionConfirmed] = useState(false);
  const options = getTargetInstanceOptions(instance, targetInstances);
  const selectedOption = options.find((o) => o.key === data.targetInstance?.id);
  const handleTargetInstanceChange = (targetInstance: Instance | null) => onChange({ ...data, targetInstance });
  const { keys } = useEncryptionKeys();
  const handleConfirmedChange = (checked: boolean) => {
    onChange({
      ...data,
      confirmed:
        data.targetInstance?.encryptionKeyRef !== instance.encryptionKeyRef
          ? checked && differentEncryptionConfirmed
          : checked,
    });
    setIsDefaultConfirmed(checked);
  };
  const handleConfirmedDifferentEncryptionKeyChange = (checked: boolean) => {
    onChange({ ...data, confirmed: isDefaultConfirmed && checked });
    setDifferentEncryptionConfirmed(checked);
  };
  useEffect(() => {
    setDifferentEncryptionConfirmed(false);
    setIsDefaultConfirmed(false);
  }, [data.targetInstance]);

  return (
    <div className="flex flex-col gap-4">
      <TextInput value={data.name} isReadOnly isFluid label="Source" />
      <Select
        size="medium"
        selectProps={{
          options,
          formatOptionLabel,
          onChange: (target) => handleTargetInstanceChange(target?.value ?? null),
          value: selectedOption,
          menuPosition: 'fixed',
          placeholder: targetInstances.length
            ? 'Select'
            : `You have no instances available that match the clone criteria`,
        }}
        type="select"
        errorText={validation?.targetInstance?.message}
        label={'Destination'}
      />
      {isNotNullish(data.targetInstance?.encryptionKeyRef) &&
        data.targetInstance.encryptionKeyRef !== instance.encryptionKeyRef && (
          <Banner type="warning" usage="inline">
            <div className="pb-1">
              <>
                <p>
                  You are about to clone to an instance that is encrypted with{' '}
                  <span className="h6">
                    {keys.find((key) => key.encryptionKeyRef === data.targetInstance?.encryptionKeyRef)?.name ??
                      data.targetInstance.encryptionKeyRef}
                  </span>
                  .
                </p>
                <p>
                  <span className="h6">Warning</span>: Source instance and destination instance use different encryption
                  keys.
                </p>
              </>
              <div className="my-2 ml-3">
                <Checkbox
                  label="I understand"
                  isChecked={differentEncryptionConfirmed}
                  onChange={(event) => handleConfirmedDifferentEncryptionKeyChange(event.target.checked)}
                />
              </div>
            </div>
          </Banner>
        )}
      <Banner
        description="Cloning into an existing instance will overwrite all of its existing data. This action cannot be undone. If you are unsure, please take a snapshot and download it before continuing."
        type="info"
        hasIcon
        usage="inline"
      />
      {instance.capabilities.vector_optimized.enabled && (
        <Banner
          description="Configurations such as Vector Optimisation or Graph Analytics are not applied to existing instances."
          type="warning"
          hasIcon
          usage="inline"
        />
      )}
      {instanceHasCDCEnabled(instance) && <CDCCloneDBWarning />}
      <Checkbox
        label="I understand the target instance will be overwritten."
        isChecked={isDefaultConfirmed}
        onChange={(event) => handleConfirmedChange(event.target.checked)}
        htmlAttributes={{
          'data-testid': 'confirm-understand',
        }}
      />
    </div>
  );
};
