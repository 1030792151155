import { isEmpty, isNil } from 'lodash-es';

import { getPlaceholderHtml } from '../../Search/GuidedSearchUtils';
import {
  BETWEEN_CONDITION_VALUES,
  BETWEEN_CONDITION_VALUES_SEPARATOR,
  SUGGESTION_ANY_NODE,
  TURNED_OFF_SUGGESTION,
  moreThenOneSpaceRegex,
} from '../SearchBar.const';
import {
  isActionSuggestion,
  isNodeSuggestion,
  isRelationshipSuggestion,
  isSearchPhraseSuggestion,
} from '../SearchBar.utils';
import type { SearchPhraseSuggestion, Suggestion } from '../types';

export const getInputPlaceHolder = (
  lockedSuggestions: Suggestion[],
  searchInputText: string,
  hasConnectionIssue: boolean,
  selectedSuggestionPath?: Suggestion | Suggestion[],
) => {
  const hasNoLockedSuggestions = lockedSuggestions.length === 1 && lockedSuggestions.includes(TURNED_OFF_SUGGESTION);
  if (isEmpty(searchInputText) && hasNoLockedSuggestions && !hasConnectionIssue) {
    return 'Search';
  }
  if (hasConnectionIssue && isEmpty(searchInputText) && hasNoLockedSuggestions) {
    return 'Search will resume once the connection is restored.';
  }
  if (isEmpty(lockedSuggestions)) {
    return getPlaceholderFromSelected(searchInputText, selectedSuggestionPath);
  }

  const top = lockedSuggestions.at(-1);
  return getPlaceholderFromLocked(searchInputText, top, selectedSuggestionPath);
};

const getPlaceholderFromLocked = (
  searchInputText: string,
  lockedSuggestion?: Suggestion,
  selectedSuggestionPath?: Suggestion | Suggestion[],
) => {
  if (isNodeSuggestion(lockedSuggestion) || isRelationshipSuggestion(lockedSuggestion)) {
    if (!isEmpty(lockedSuggestion.propertyConditionValue)) {
      return null;
    }

    if (!isEmpty(searchInputText)) {
      return null;
    }

    if (
      !isNil(lockedSuggestion.propertyCondition) &&
      BETWEEN_CONDITION_VALUES.includes(lockedSuggestion?.propertyCondition)
    ) {
      return `__ ${BETWEEN_CONDITION_VALUES_SEPARATOR} __`;
    }
    switch (lockedSuggestion.propertyType) {
      case 'Date':
        return 'YYYY-MM-DD';
      case 'Time':
      case 'LocalTime':
        return 'HH:MM:SS';
      case 'DateTime':
      case 'LocalDateTime':
        return 'YYYY-MM-DDTHH:MM:SS';
    }
  }

  if (isSearchPhraseSuggestion(lockedSuggestion) && !isNil(selectedSuggestionPath)) {
    const { displayText, inputCharTypes } = selectedSuggestionPath as SearchPhraseSuggestion;
    if (isNil(displayText.match(moreThenOneSpaceRegex))) {
      return getPlaceholderHtml(displayText, inputCharTypes, searchInputText.length);
    }
  }

  return null;
};

const getPlaceholderFromSelected = (
  searchInputText: string,
  suggestionPath?: Suggestion | Suggestion[],
): null | string => {
  if (Array.isArray(suggestionPath)) {
    const top = suggestionPath.at(-1);
    return top !== undefined ? getPlaceholderFromSelected(searchInputText, top) : null;
  }
  if (suggestionPath === SUGGESTION_ANY_NODE) {
    return 'any';
  }
  if (isNodeSuggestion(suggestionPath)) {
    const { categoryName, propertyConditionValue } = suggestionPath;
    const value = propertyConditionValue ?? categoryName;

    const match = searchInputText.toLowerCase();
    const hasNoMatch = !value.toLowerCase().startsWith(match);
    if (hasNoMatch) {
      return null;
    }
    return searchInputText + value.substring(searchInputText.length, value.length);
  }

  if (isRelationshipSuggestion(suggestionPath)) {
    return suggestionPath.relationshipType;
  }

  if (isSearchPhraseSuggestion(suggestionPath)) {
    const { displayText } = suggestionPath;
    const match = searchInputText.toLowerCase();
    const hasMatch = displayText.toLowerCase().startsWith(match);
    return hasMatch ? searchInputText + displayText.substring(searchInputText.length, displayText.length) : null;
  }

  if (isActionSuggestion(suggestionPath)) {
    const { title } = suggestionPath;
    const match = searchInputText.toLowerCase();
    const hasMatch = title.toLowerCase().startsWith(match);
    return hasMatch ? searchInputText + title.substring(searchInputText.length, title.length) : null;
  }

  return null;
};

export const turnOnSuggestions = (lockedSuggestions: Suggestion[] = []) => {
  return lockedSuggestions.includes(TURNED_OFF_SUGGESTION) ? lockedSuggestions.slice(0, -1) : lockedSuggestions;
};

export const turnOffSuggestions = (lockedSuggestions: Suggestion[] = []) => {
  return lockedSuggestions.includes(TURNED_OFF_SUGGESTION)
    ? lockedSuggestions
    : [...lockedSuggestions, TURNED_OFF_SUGGESTION];
};
