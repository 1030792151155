import type { QuerySavedCypherFolder, QuerySavedCypherItem } from '@query/services/api/generated-saved-cypher-api';

type SavedCypherBase = {
  id: string;
  name: string;
  databaseId: string | null;
  order: number;
  depth: number;
  isNew?: boolean;
};

export type SavedCypherQuery = SavedCypherBase & {
  description: string;
  query: string;
};

export type SavedCypherFolder = SavedCypherBase & {
  children: SavedCypherItem[];
};

export type SavedCypherItem = SavedCypherQuery | SavedCypherFolder;

export const isSavedCypherQuery = (item: SavedCypherItem): item is SavedCypherQuery => 'query' in item;

export const isSavedCypherFolder = (item: SavedCypherItem): item is SavedCypherFolder => !isSavedCypherQuery(item);

type FlatSavedCypherBase = {
  parentId: string | null;
  depth: number;
};

export type FlatSavedCypherFolder = FlatSavedCypherBase & SavedCypherFolder;
export type FlatSavedCypherQuery = FlatSavedCypherBase & SavedCypherQuery;
export type FlatSavedCypherItem = FlatSavedCypherQuery | FlatSavedCypherFolder;

export const isFlatSavedCypherQuery = (item: FlatSavedCypherItem | SavedCypherItem): item is FlatSavedCypherQuery =>
  'query' in item;

export const isFlatSavedCypherFolder = (item: FlatSavedCypherItem | SavedCypherItem): item is FlatSavedCypherFolder =>
  !isFlatSavedCypherQuery(item);

export type TreeItems = SavedCypherItem[];

export const isQuerySavedCypherItem = (
  item: QuerySavedCypherItem | QuerySavedCypherFolder,
): item is QuerySavedCypherItem => 'query' in item;

export const isQuerySavedCypherFolder = (
  item: QuerySavedCypherItem | QuerySavedCypherFolder,
): item is QuerySavedCypherFolder => !('query' in item);
