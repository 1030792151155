import type { AsyncThunk } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';

import bolt from '../../../services/bolt/bolt';
import { consumeAbortFunction } from '../../../services/search2_0/abortable-requests-service';
import type { RootState } from '../../types';
import { NAME } from './search-requests.const';
import { selectRequestIds } from './search-requests.selectors';
import type { RequestId } from './search-requests.types';

export const clearRequestsThunk: AsyncThunk<{ requestIds: RequestId[] }, void, { state: RootState }> = createAsyncThunk<
  { requestIds: RequestId[] },
  void,
  { state: RootState }
>(`${NAME}/clearRequestsThunk`, async (payload: void, { getState }) => {
  const state = getState();
  const requestIds = selectRequestIds(state);
  requestIds.forEach((requestId) => {
    consumeAbortFunction(requestId)?.();

    // for search phrases and structured suggestions
    void bolt.cancelTransaction(requestId);
  });
  return { requestIds };
});
