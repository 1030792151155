import { Banner, Button, Dialog, Select } from '@neo4j-ndl/react';
import { AURA_CONSOLE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import type { Instance } from '@nx/state';
import { consoleApi, getApiError, getErrorMessage, useActiveProject } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';

import { useTrackUpxEvent } from '../../services/segment/analytics';

const logger = createLogger(APP_SCOPE.framework);

const MAX_SECONDARIES = 15;

type SecondariesCountData = {
  secondariesCount: number;
};

const availableSecondariesCount: SecondariesCountData[] = Array.from({ length: MAX_SECONDARIES + 1 }, (_, i) => ({
  secondariesCount: i,
}));

const options: { value: number; key: string; label: string }[] = availableSecondariesCount.map(
  ({ secondariesCount }) => ({
    value: secondariesCount,
    label: `${
      secondariesCount > 0
        ? `${secondariesCount} ${secondariesCount === 1 ? 'Secondary' : 'Secondaries'}`
        : '0 (No Secondaries)'
    }`,
    key: `${secondariesCount}`,
  }),
);

type EditSecondariesModalProps = {
  onClose: () => void;
  onSuccess: () => void;
  instance: Instance;
};

export const EditSecondariesModal = ({ onClose, onSuccess, instance }: EditSecondariesModalProps) => {
  const trackEvent = useTrackUpxEvent();
  const [editSecondaries, editSecondariesRes] = consoleApi.useUpdateSecondariesMutation();
  const [secondariesCount, setSecondariesCount] = useState(instance.desiredSettings.secondariesCount ?? 0);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const handleEditSecondaries = (event: SyntheticEvent) => {
    event.preventDefault();
    editSecondaries({ dbId: instance.id, secondariesCount })
      .unwrap()
      .then(() => onSuccess())
      .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(e);
        const message = getErrorMessage(error);
        setErrorMessage(message);
        if (isNotNullish(error.message)) {
          logger.error(error.message);
        }
      });
    trackEvent({
      event: AURA_CONSOLE_EVENTS.INSTANCE_SECONDARIES_COUNT_UPDATE,
      properties: { secondariesCount },
      scope: APP_SCOPE.aura,
    });
  };

  const handleSecondariesChange = (value?: number) => {
    setSecondariesCount(value ?? 0);
  };

  const project = useActiveProject();
  const secondaryCost = project.tierConfigs[instance.tier]?.secondaryCost;

  const value = options.find((option) => option.value === secondariesCount) ?? options[0];
  return (
    <Dialog isOpen onClose={onClose} modalProps={{ 'data-testid': 'update-secondaries-dialog' }}>
      <Dialog.Header>Edit Secondary Count</Dialog.Header>
      <form onSubmit={handleEditSecondaries}>
        <Dialog.Content>
          <Select
            type="select"
            size="medium"
            selectProps={{
              options,
              onChange: (entry) => handleSecondariesChange(entry?.value),
              isSearchable: false,
              value,
              menuPosition: 'fixed',
            }}
            label={'Secondary Count'}
            htmlAttributes={{ 'data-testid': 'secondary-count-select' }}
          />
          {isNotNullish(secondaryCost) && (
            <Banner type="info" className="mt-2" usage="inline">
              Secondaries are billed at ${secondaryCost}/GB/hr, per secondary, based on your current pricing plan.
            </Banner>
          )}
          {isNotNullish(errorMessage) && <Banner type="danger" hasIcon description={errorMessage} usage="inline" />}
        </Dialog.Content>
        <Dialog.Actions>
          <Button color="neutral" fill="outlined" isDisabled={editSecondariesRes.isLoading} onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            isLoading={editSecondariesRes.isLoading}
            type="submit"
            isDisabled={secondariesCount === instance.desiredSettings.secondariesCount}
          >
            Update
          </Button>
        </Dialog.Actions>
      </form>
    </Dialog>
  );
};
