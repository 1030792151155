import moment from 'moment';
import { error as driverError } from 'neo4j-driver';

import { appStateError } from '../constants';

export const MSG_USER_NOT_AUTHORIZED =
  "The Neo4j instance you're connecting to has been configured to limit Bloom access to users with authorized roles as defined by the database administrator. A database administrator can configure your Neo4j account with the needed role(s), or allow access to additional Neo4j users.";
export const MSG_INVALID_SEARCH_PHRASE =
  'Invalid Search phrase. Please check the definition of the saved Search phrase.';
export const MSG_RECORD_LIMIT_EXCEEDED = 'The node query limit has been reached.';
export const MSG_SCENE_NOT_FOUND =
  'Could not find Scene. The Scene has either been deleted or the link has been modified. Contact your database administrator if the issue persists.';
export const MSG_SCENE_NO_ACCESS = 'Could not access Scene. Contact your database administrator if the issue persists.';

export const MSG_CONNECTIVITY_LOST =
  'Neo4j connectivity has been lost. Please check if your database instance is up and running.';
export const MSG_REQUIRES_AUTH_ENABLED =
  'Neo4j Bloom requires auth to be enabled. Please contact your system administrator.';
export const MSG_REQUIRES_ENTERPRISE_EDITION = 'Neo4j Bloom requires Neo4j Enterprise Edition.';
export const MSG_MISSING_LINK_TARGET = 'Could not find link target. Please check your link configuration.';
export const MSG_BLOOM_SERVER_LICENSE_EXPIRED =
  'The Neo4j Bloom Server license is expired. Please contact your system administrator.';
export const MSG_BLOOM_SERVER_PLUGIN_MISSING =
  'The Neo4j Bloom Server plugin is missing. A licensed plugin is required for remote graphs. Please contact your system administrator or your Neo4j representative.';
export const MSG_BLOOM_SERVER_LICENSE_MISSING =
  "We couldn't find a license for the Neo4j Bloom Server. Please check with your system administrator.";
export const MSG_BLOOM_SERVER_LICENSE_INVALID =
  "The Neo4j Bloom Server license couldn't be verified. Please contact your system administrator.";
export const MSG_BLOOM_SERVER_LICENSE_EXPIRING = (daysLeft, currentDate = Date()) =>
  `The Neo4j Bloom Server license expires on ${moment(currentDate).add(daysLeft, 'days').format('MMMM Do, YYYY')} (${
    daysLeft === 0 ? 'today' : `in ${daysLeft} days`
  }).`;
export const MSG_NOT_SUPPORTED_VERSION = (version) =>
  `Bloom supports Neo4j database versions ${version} and above. Please upgrade your database to a supported version.`;

const MSG_CANNOT_CONNECT_DB = 'Cannot connect to database. Please ensure that the database is up and available.';
const MSG_NO_DB =
  'You need access to a database to use Neo4j Bloom. Please login with a different user or contact your system administrator for access.';
const MSG_SSO_LOGIN_ERR = 'Please try to login again and if it still fails contact your system administrator.';
const MSG_SSO_REDIRECT_ERR = 'There was no provider discovery data found. Please verify their availability and retry.';

const MSG_PROCEDURE_UNRESTRICTED =
  'Configuration issue detected: Bloom Enterprise requires permission to run procedures in unrestricted mode, which can be configured in the neo4j.conf file';

const PROCEDURE_PROCEDURE_REGISTRATION_FAILED = 'Neo.ClientError.Procedure.ProcedureRegistrationFailed';

const noErrorMessage = '{}';

const _getErrorText = (error) => {
  const errorText = error.message || JSON.stringify(error);
  return errorText === noErrorMessage ? error.code || '' : errorText;
};

export const getErrorMessage = (error) => {
  if (!error) {
    return;
  }

  let message;
  let title;

  switch (error.code) {
    case driverError.SERVICE_UNAVAILABLE:
    case driverError.SESSION_EXPIRED:
      title = 'Cannot connect to the database';
      message = MSG_CANNOT_CONNECT_DB;
      break;
    case appStateError.NO_ACCESS_TO_DB:
      title = 'Cannot access a database';
      message = MSG_NO_DB;
      break;
    case PROCEDURE_PROCEDURE_REGISTRATION_FAILED:
      message = _getErrorText(error);
      if (message.startsWith('bloom.checkAuthorization')) {
        message = MSG_PROCEDURE_UNRESTRICTED;
      }
      break;
    default:
      message = _getErrorText(error);
      break;
  }

  return {
    code: error.code,
    title,
    message,
  };
};

export const getErrorMessageFromOrigin = (error, origin = '') => {
  if (!error) {
    return 'AN ERROR OCCURRED';
  }

  return `AN ERROR OCCURRED WHILE ${origin}:\n${_getErrorText(error)}`;
};
