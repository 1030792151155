import { tokens } from '@neo4j-ndl/base';
import { Banner, Button, Dialog, Radio, Tooltip, Typography } from '@neo4j-ndl/react';
import { ChevronDownIconOutline, InformationCircleIconOutline } from '@neo4j-ndl/react/icons';
import type { Instance } from '@nx/state';
import { MODAL_TYPE, selectCapability, LEGACY_store as store, useConnection, useModal } from '@nx/state';
import { URLs, isNotNullish, isNullish } from '@nx/stdlib';
import type { FormEvent } from 'react';
import React, { useEffect, useState } from 'react';

import {
  getFormValuesFromUrl,
  getProtocolOptions,
  getProtocols,
  validateForm,
} from '../connection-form/connection-form-utils';
import type { ConnectionFormValues } from '../connection-form/connection-form.types';
import ConnectionError from '../connection-form/form-elements/connection-error';
import { ConnectionFormBasicAuth } from '../connection-form/form-elements/input-basic-auth';
import { ConnectionFormSso } from '../connection-form/form-elements/input-sso';
import { ConnectionFormUrl } from '../connection-form/form-elements/input-url';
import { useConnect, useConnectionDiscoveryData, useFormValues } from '../connection-form/hooks';
import { DividerWithLabel } from '../divider-with-label/divider-with-label';

interface FormProps {
  formValues: ConnectionFormValues;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } }) => void;
}

function ProtocolRadioButton({
  label,
  tipText,
  tipPlacement = 'bottom',
  value,
  isDisabled,
  formProps,
}: {
  label: string;
  tipText?: string;
  tipPlacement: 'top' | 'bottom';
  value: URLs.ConnectionProtocol;
  isDisabled?: boolean;
  formProps: FormProps;
}) {
  return (
    <span className="flex items-center">
      <Radio
        label={label}
        onChange={formProps.handleInputChange}
        isChecked={formProps.formValues.protocol === value}
        htmlAttributes={{
          value,
          name: 'protocol',
        }}
        isDisabled={isDisabled}
      />
      {isNotNullish(tipText) && (
        <Tooltip placement={tipPlacement} type="simple" isPortaled={false}>
          <Tooltip.Trigger hasButtonWrapper>
            <InformationCircleIconOutline className="ml-2 inline-block size-4" aria-label={`${label} explanation`} />
          </Tooltip.Trigger>
          <Tooltip.Content className="max-w-sm">{tipText}</Tooltip.Content>
        </Tooltip>
      )}
    </span>
  );
}

const ALLOWED_AURA_PROTOCOLS: URLs.ConnectionProtocol[] = ['neo4j+s:', 'https:'];

function ConnectionMethods({ formProps }: { formProps: FormProps }) {
  const { formValues } = formProps;
  const { neo4jVersion, fetchAndSetDiscoveryData } = useConnectionDiscoveryData();

  useEffect(() => {
    fetchAndSetDiscoveryData(`${formValues.protocol}//${formValues.hostname}`);
  }, [formValues.protocol, formValues.hostname, fetchAndSetDiscoveryData]);

  const [isOpen, setIsOpen] = useState(false);

  const allOptionsAllowed = selectCapability(store.getState(), {
    key: 'framework:allow-all-connection-form-fields',
  });
  const protocols = getProtocols(formProps.formValues.hostname).filter(
    (protocol) => allOptionsAllowed || ALLOWED_AURA_PROTOCOLS.includes(protocol),
  );

  const protocolOptions = getProtocolOptions(protocols, neo4jVersion);

  return (
    <div>
      <Button
        fill="text"
        className="text-palette-primary-text relative right-2 flex h-9 items-center gap-1.5 !px-2"
        aria-label="Connection method selection"
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        <Typography variant="body-medium">Connection method</Typography>
        <ChevronDownIconOutline
          className="text-palette-primary-text h-4 w-4 shrink-0"
          style={{
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transitionDuration: tokens.transitions.values.duration.quick,
          }}
        />
      </Button>
      {isOpen && (
        <div className="mt-1 flex w-full flex-col gap-2">
          {protocolOptions.map((option, index) => (
            <ProtocolRadioButton
              key={option.friendlyLabel}
              label={option.friendlyLabel}
              tipText={option.description}
              tipPlacement={index === 0 ? 'top' : 'bottom'}
              value={option.value}
              formProps={formProps}
              isDisabled={option.isDisabled}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function Sso({ formProps }: { formProps: FormProps }) {
  const { formValues } = formProps;
  const { providers, fetchAndSetDiscoveryData } = useConnectionDiscoveryData();
  // Fetch SSO providers
  useEffect(() => {
    fetchAndSetDiscoveryData(`${formValues.protocol}//${formValues.hostname}`);
  }, [formValues.protocol, formValues.hostname, fetchAndSetDiscoveryData]);

  if (providers.length <= 0) {
    return null;
  }

  return (
    <div className="flex w-full flex-col gap-4">
      <h6>Single sign on</h6>
      <ConnectionFormSso formValues={formValues} providers={providers} />
      <DividerWithLabel label="OR" className="mt-1" />
    </div>
  );
}

function BasicAuth({ instance, formProps }: { instance?: Instance; formProps: FormProps }) {
  const allOptionsAllowed = selectCapability(store.getState(), {
    key: 'framework:allow-all-connection-form-fields',
  });

  return (
    <div className="flex w-full flex-col gap-4">
      <h6>Database credentials</h6>
      {allOptionsAllowed && (
        <ConnectionFormUrl formValues={formProps.formValues} handleInputChange={formProps.handleInputChange} />
      )}
      <ConnectionFormBasicAuth
        formValues={formProps.formValues}
        handleInputChange={formProps.handleInputChange}
        displayPasswordVisibilityToggle
      />
    </div>
  );
}

const emptyFormValues: ConnectionFormValues = {
  name: '',
  protocol: URLs.PROTOCOL_NEO4J_SECURE,
  hostname: '',
  username: '',
  password: '',
};

function getInitialFormValuesFromInstance(instance: Instance): ConnectionFormValues {
  const url = URLs.Neo4jURL.asNullable(instance.boltUrl);
  return {
    name: instance.name,
    protocol: URLs.PROTOCOL_NEO4J_SECURE,
    hostname: url?.hostname ?? '',
    username: '',
    password: '',
  };
}

export function ConnectionModal({
  instance,
  initialWarning,
  initialError,
}: {
  instance?: Instance;
  initialWarning?: string;
  initialError?: string;
}) {
  const { close: closeModal } = useModal(MODAL_TYPE.CONNECTION_FORM);
  const connection = useConnection();
  const [isConnectionError, setIsConnectionError] = useState(false);
  const [formValues, setFormValues] = useFormValues(
    instance ? getInitialFormValuesFromInstance(instance) : emptyFormValues,
  );

  const { connectBasicAuth } = useConnect();
  const { isValid } = validateForm(formValues);

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    connectBasicAuth(formValues, () => setIsConnectionError(true), isNotNullish(instance));
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } },
  ) => {
    const { name, value } = event.target;
    setFormValues({ [name]: value }, { resetInstanceName: true });
  };

  const formProps = {
    formValues,
    handleInputChange,
  };

  return (
    <Dialog
      isOpen
      size="unset"
      modalProps={{ className: 'max-w-[480px] overflow-visible mx-auto mt-12 p-10 align-top' }}
      onClose={closeModal}
    >
      <Dialog.Header>
        {instance ? (
          <>
            Connect to <span className="inline font-normal">{instance.name}</span>
          </>
        ) : (
          'Connect to an instance'
        )}
      </Dialog.Header>
      <Dialog.Content>
        {isConnectionError && (
          <div className="mb-2">
            <ConnectionError
              show
              error={connection.metadata?.error}
              discoveryUrls={isNullish(instance) ? connection.metadata?.discoveryUrls : []}
              onDiscoveryUrlSelected={(url) => setFormValues(getFormValuesFromUrl(url))}
            />
          </div>
        )}
        {!isConnectionError && (isNotNullish(initialError) || isNotNullish(initialWarning)) && (
          <Banner
            hasIcon
            type={isNotNullish(initialError) ? 'danger' : 'warning'}
            description={
              <Typography variant="body-medium">
                {isNotNullish(initialError) ? initialError : initialWarning}
              </Typography>
            }
            className="mb-2 flex items-center"
          />
        )}
        <form
          className="flex flex-col items-start gap-4"
          onSubmit={(event) => {
            void handleSubmit(event);
          }}
        >
          <ConnectionMethods formProps={formProps} />
          <Sso formProps={formProps} />
          <BasicAuth instance={instance} formProps={formProps} />
          <Button
            type="submit"
            className="mt-3 w-full"
            isDisabled={connection.status.isConnecting || connection.status.isDisconnecting || !isValid}
          >
            Connect
          </Button>
        </form>
      </Dialog.Content>
    </Dialog>
  );
}
