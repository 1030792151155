import { ORGANIZATION_ROLE_NAME, PROJECT_ROLE_NAME } from '@nx/state';

type OrgView = 'ORG' | 'PROJECT' | 'ROLES';
type ProjectView = 'PROJECT' | 'INSTANCE' | 'DB_ROLES';

type ViewConfig = { tabLabel: string; route: string };

export const OrgViewConfig = new Map<OrgView, ViewConfig>([
  ['ORG', { tabLabel: 'View by Organization', route: '' }],
  ['PROJECT', { tabLabel: 'View by Projects', route: 'by-project' }],
  ['ROLES', { tabLabel: 'View by Roles', route: 'by-role' }],
]);
export const ProjectViewConfig = new Map<ProjectView, ViewConfig>([
  ['PROJECT', { tabLabel: 'View by Project', route: '' }],
  ['INSTANCE', { tabLabel: 'View by Instances', route: 'by-instance' }],
  ['DB_ROLES', { tabLabel: 'View by Database Roles', route: 'by-database-role' }],
]);

export const projectRoleFriendlyName: Record<PROJECT_ROLE_NAME, string> = {
  [PROJECT_ROLE_NAME.PROJECT_ADMIN]: 'PROJECT_ADMIN',
  [PROJECT_ROLE_NAME.PROJECT_MEMBER]: 'PROJECT_MEMBER',
  [PROJECT_ROLE_NAME.PROJECT_METRICS_INTEGRATION_READER]: 'METRICS_READER',
  [PROJECT_ROLE_NAME.PROJECT_VIEWER]: 'PROJECT_VIEWER',
  [PROJECT_ROLE_NAME.PROJECT_DATA_VIEWER]: 'PROJECT_DATA_VIEWER',
} as const;

export const isProjectRole = (role: string): role is PROJECT_ROLE_NAME =>
  Object.values(PROJECT_ROLE_NAME)
    .map((r) => r.toString())
    .includes(role);

export const orgRoleFriendlyName: Record<ORGANIZATION_ROLE_NAME, string> = {
  [ORGANIZATION_ROLE_NAME.ORG_OWNER]: 'ORG_OWNER',
  [ORGANIZATION_ROLE_NAME.ORG_ADMIN]: 'ORG_ADMIN',
  [ORGANIZATION_ROLE_NAME.ORG_MEMBER]: 'ORG_MEMBER',
};

const ORGANIZATION_ROLE_OPTIONS = [
  {
    label: 'Member',
    key: ORGANIZATION_ROLE_NAME.ORG_MEMBER,
    value: ORGANIZATION_ROLE_NAME.ORG_MEMBER,
  },
  {
    label: 'Admin',
    key: ORGANIZATION_ROLE_NAME.ORG_ADMIN,
    value: ORGANIZATION_ROLE_NAME.ORG_ADMIN,
  },
  {
    label: 'Owner',
    key: ORGANIZATION_ROLE_NAME.ORG_OWNER,
    value: ORGANIZATION_ROLE_NAME.ORG_OWNER,
  },
];

export const getOrganizationRoleOptions = () => {
  return ORGANIZATION_ROLE_OPTIONS;
};
