import { isNonEmptyString, validate } from '@nx/stdlib';
import { Type } from '@sinclair/typebox';

// eslint-disable-next-line no-console
const logger = console.log;

const TokenPayloadSchema = Type.Object({
  exp: Type.Number(),
});

const TokenResponseSchema = Type.Object({
  access_token: Type.String(),
});

/**
 * Manages and caches an Aura Management API token. In Aura, this token is generated
 * by the Console API, and orchestrated by the Aura Management API service (`app-ingress`).
 *
 * Returns existing token if valid, otherwise fetches and caches a new one using the Auth0 token.
 * Should be used when the consumer API is proxied through Vite, or more generally, when API
 * calls are not proxied through `app-ingress` (e.g. when developing locally).
 */
export const getAuraManagementApiToken = (() => {
  let cachedToken: string | null = null;
  return async (
    auth0Token: string | undefined | null,
    projectId: string | undefined | null,
    /** Default value `/console/token` assumes Vite proxy is on */
    tokenUrl = '/console/token',
  ): Promise<string> => {
    try {
      if (!isNonEmptyString(auth0Token)) {
        throw new Error('[getAppIngressToken] invalid auth0 token');
      }

      if (!isNonEmptyString(projectId)) {
        throw new Error('[getAppIngressToken] invalid console project id');
      }

      if (isNonEmptyString(cachedToken) && cachedToken.includes('.')) {
        const [, appIngressTokenPayloadB64] = cachedToken.split('.');

        if (!isNonEmptyString(appIngressTokenPayloadB64)) {
          throw new Error('[getAppIngressToken] invalid cached token');
        }

        const payload = validate(TokenPayloadSchema, JSON.parse(atob(appIngressTokenPayloadB64)));
        const isValid = Boolean(payload.exp) && payload.exp > Math.floor(Date.now() / 1000);

        if (isValid) {
          return cachedToken;
        }
      }

      logger('[getAppIngressToken] fetching new app ingress token');
      const response = await fetch(tokenUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth0Token}`,
        },
        body: JSON.stringify({ project_id: projectId }),
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch token: ${response.statusText}`);
      }

      const { access_token } = validate(TokenResponseSchema, await response.json());

      //   eslint-disable-next-line require-atomic-updates
      cachedToken = access_token;
      return cachedToken;
    } catch (error) {
      logger('[getAppIngressToken] error fetching new app ingress token:', error);
      throw error;
    }
  };
})();
