import { Button, Code, Dialog, Tooltip, Typography } from '@neo4j-ndl/react';
import { Square2StackIconOutline } from '@neo4j-ndl/react/icons';
import type { EncryptionKey } from '@nx/state';
import { useCopyWithMessage } from '@nx/ui';

interface AwsInstructionsProps {
  encryptionKey: EncryptionKey;
  onClose: () => void;
}

export const AwsCmekPermissionsInstructions = ({ encryptionKey, onClose }: AwsInstructionsProps) => {
  const [messageToShow, copyWithFeedback] = useCopyWithMessage();

  const keyPolicy = `{
    "Sid": "Allow access for Neo4j",
    "Effect": "Allow",
    "Principal": {
        "AWS": "*"
    },
    "Action": [
        "kms:Encrypt",
        "kms:Decrypt",
        "kms:ReEncrypt*",
        "kms:GenerateDataKey*",
        "kms:DescribeKey",
        "kms:CreateGrant",
        "kms:ListGrants",
        "kms:RevokeGrant"
    ],
    "Resource": "*",
    "Condition": {
        "StringEquals": {
            "kms:CallerAccount": "${encryptionKey.awsProperties?.permissions.accountId}"
        }
    }
}`;

  return (
    <>
      <Dialog.Header>Customer Managed Key Permissions</Dialog.Header>
      <Dialog.Content className="space-y-6">
        <div>
          <p>
            In order for Neo4j components to use the provided key, you will have to add the following KMS policy to this
            key.
          </p>
          <ol className="my-6 list-decimal pl-6">
            <li>In AWS, go to KMS and select the key you want to add to Aura.</li>
            <li>Then under &quot;Key policy&quot; paste the JSON snippet into the list of policies.</li>
          </ol>
          <p>Note that you should append the policy and not replace existing ones.</p>
          <div className="my-6">
            <Code
              style={{ overflow: 'visible' }}
              actions={[
                {
                  ariaLabel: 'Copy to clipboard',
                  htmlAttributes: {
                    title: 'Copy to clipboard',
                  },
                  children: (
                    <div className="relative">
                      {messageToShow !== null && (
                        <Tooltip type="simple">
                          <Tooltip.Content className={`absolute right-[40px] top-[-10px]`}>
                            <Typography variant="body-medium" className="whitespace-nowrap px-2">
                              {messageToShow}
                            </Typography>
                          </Tooltip.Content>
                        </Tooltip>
                      )}
                      <Square2StackIconOutline />
                    </div>
                  ),
                  onClick: () => copyWithFeedback(keyPolicy),
                },
              ]}
              code={keyPolicy}
              language="json"
            />
          </div>
        </div>
      </Dialog.Content>
      <Dialog.Actions className="justify-between">
        <Button fill="outlined" color="neutral" onClick={onClose}>
          Close
        </Button>
      </Dialog.Actions>
    </>
  );
};
