import { cleanTypeDescription } from '../../data-transforms/clean-type.js';
import {
  type ArgumentDescription,
  type BaseArguments,
  type CypherVersion,
  type ReturnDescription,
  cypherVersions,
} from '../../types/sdk-types.js';
import { versionSupportsCypher25 } from '../version-and-edition.js';
import validateProcedure from './procedure-validator-generated.js';

type ProcedureMode = 'READ' | 'DBMS' | 'SCHEMA' | 'WRITE' | 'DEFAULT';

export type Procedure = {
  name: string;
  description: string;
  mode: ProcedureMode;
  // If it can run on the system database
  worksOnSystem: boolean;
  argumentDescription: ArgumentDescription[];
  returnDescription: ReturnDescription[];
  signature: string;
  admin: boolean;
  // Flexbible field, most hold if procedure is deprecated or not
  option: { deprecated: boolean } & Record<string, unknown>;
};

function cleanTypes(result: Procedure): Procedure {
  return {
    ...result,
    argumentDescription: result.argumentDescription.map(cleanTypeDescription),
    returnDescription: result.returnDescription.map(cleanTypeDescription),
  };
}

/**
 * Gets available procedures on your database
 * https://neo4j.com/docs/cypher-manual/current/clauses/listing-procedures/
 */
export async function listProcedures({
  queryCypher,
  neo4jVersion,
  cypher25Enabled = false,
}: BaseArguments): Promise<Partial<Record<CypherVersion, Procedure[]>>> {
  const procedures: Partial<Record<CypherVersion, Procedure[]>> = {};
  const pollAllNeo4jVersions = versionSupportsCypher25(neo4jVersion, cypher25Enabled);
  const versions: CypherVersion[] = pollAllNeo4jVersions ? cypherVersions : ['CYPHER 5'];

  await Promise.all(
    versions.map(async (cypherVersion) => {
      const query = `${pollAllNeo4jVersions ? cypherVersion : ''} SHOW PROCEDURES YIELD *`;
      const res = await queryCypher(query);

      procedures[cypherVersion] = res.records.map((rec) => {
        // Assign default values (e.g. isDeprecated) in case they are not present
        const objResult = rec.toObject();
        validateProcedure(objResult);
        // Type is verified in integration tests
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const result = cleanTypes(objResult as Procedure);
        return result;
      });
    }),
  );

  return procedures;
}
