import { opsApi, useActiveProject } from '@nx/state';
import { isNonEmptyString } from '@nx/stdlib';
import { useMemo } from 'react';

import { usePageInfo } from '../shared/hooks/use-page-info';
import type { PaginationHook } from '../shared/hooks/use-pagination';
import { mapQueryLogs } from '../shared/mappers';
import { useLogsContext } from './hooks/use-logs-context';
import type { SortingHook } from './hooks/use-sorting';
import { QueryLogsDataGrid } from './query-logs-data-grid';

export const AuraLogsDetails = ({
  selectedDbmsId,
  pagination,
  sorting,
}: {
  selectedDbmsId: string;
  pagination: PaginationHook;
  sorting: SortingHook<'Details'>;
}) => {
  const activeProject = useActiveProject();
  const selectedTenantId = activeProject.id;
  const { filterInput, hasRequestedData } = useLogsContext();

  const {
    data: queryLogsData,
    isFetching: queryLogsLoading,
    error: queryLogsError,
  } = opsApi.useGetQueryLogsQuery(
    {
      tenantId: selectedTenantId,
      dbmsId: selectedDbmsId,
      args: {
        filter: filterInput,
        pagination: pagination.logsPagination,
        sort: sorting.querySorting,
      },
    },
    { skip: !selectedDbmsId || !selectedTenantId || !hasRequestedData },
  );
  const logs = useMemo(
    () => (hasRequestedData ? mapQueryLogs(queryLogsData?.logs) : []),
    [hasRequestedData, queryLogsData],
  );
  const pageInfo = usePageInfo(queryLogsData?.pageInfo);

  const detailedQueryLog = isNonEmptyString(filterInput.query)
    ? logs.find((log) => log.query === filterInput.query)
    : null;

  return (
    <QueryLogsDataGrid
      dataGridType="Details"
      logs={logs}
      queryLogsLoading={queryLogsLoading}
      pageInfo={pageInfo}
      detailedQueryLog={detailedQueryLog}
      queryLogsError={queryLogsError}
      selectedDbmsId={selectedDbmsId}
      controlledPagination={pagination.controlledPagination}
      controlledSorting={sorting.controlledSorting}
      onPaginationChange={pagination.onPaginationChange}
      onSortingChange={sorting.onSortingChange}
    />
  );
};

export default AuraLogsDetails;
