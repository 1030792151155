import { IconButton, Tooltip } from '@neo4j-ndl/react';
import { EllipsisHorizontalIconOutline, PauseCircleIconOutline } from '@neo4j-ndl/react/icons';
import type { AuraDatabase, Project } from '@nx/state';

const DatabaseActionMenu = ({ database }: { database: AuraDatabase }) => {
  // Just a placeholder at the moment
  // TODO: implement the actual actions
  return (
    <>
      <Tooltip type="simple" isDisabled={false}>
        <Tooltip.Trigger hasButtonWrapper>
          <IconButton ariaLabel="More database actions" isDisabled={true} size="small" isClean>
            <EllipsisHorizontalIconOutline className="h-full w-full" />
          </IconButton>
        </Tooltip.Trigger>
        <Tooltip.Content>No further database actions are supported yet</Tooltip.Content>
      </Tooltip>
    </>
  );
};

export const DatabaseTableRowActions = ({ database, project }: { database: AuraDatabase; project: Project }) => {
  // Just a placeholder at the moment
  // TODO: implement the actual pause/resume database actions
  return (
    <div className="flex flex-grow flex-row items-center justify-end gap-2">
      <Tooltip type="simple" isDisabled={false}>
        <Tooltip.Trigger hasButtonWrapper>
          <IconButton isDisabled={true} isLoading={false} ariaLabel="Pause database" size="small">
            <PauseCircleIconOutline />
          </IconButton>
        </Tooltip.Trigger>
        <Tooltip.Content>The ability to pause a database is not yet supported</Tooltip.Content>
      </Tooltip>
      <DatabaseActionMenu database={database} />
    </div>
  );
};
