import { Button, DataGrid, DataGridComponents, TextLink, Typography } from '@neo4j-ndl/react';
import { TrashIconOutline } from '@neo4j-ndl/react/icons';
import type { OauthClient } from '@nx/state';
import { consoleApi } from '@nx/state';
import { DataGridHelpers, PermissionTooltip, SearchField } from '@nx/ui';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import { formatTimestamp } from '../../utils';
import { CreateApiKeyModal } from './create-api-key-modal';
import { DeleteApiKeyModal } from './delete-api-key-modal';

const columnHelper = createColumnHelper<OauthClient>();

const NoDataPlaceholder = () => (
  <DataGridComponents.NoDataPlaceholder>
    <h6>No API keys created</h6>
  </DataGridComponents.NoDataPlaceholder>
);

const columns = [
  columnHelper.accessor('name', {
    header: 'Name',
    minSize: 100,
  }),
  columnHelper.accessor('clientId', {
    header: 'Client ID',
    minSize: 100,
  }),
  columnHelper.accessor('createdAt', {
    header: 'Created At',
    cell: (cx) => formatTimestamp(cx.getValue(), 'dd.MM.yyyy'),
    minSize: 100,
  }),
  columnHelper.display({
    id: 'actions',
    cell: (cx) => (
      <DataGridComponents.RowActionCell
        cell={cx}
        innerIconButtonProps={{
          children: <TrashIconOutline />,
          isDanger: true,
          onClick: () => {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            (cx.table.options.meta as { onDelete: (clientId: string) => void }).onDelete(cx.row.original.clientId);
          },
          ariaLabel: `Delete API key`,
        }}
      />
    ),
    enableResizing: false,
    meta: {
      isActionCell: {
        actions: [],
      },
    },
  }),
];

function ApiKeysTable({ onDelete }: { onDelete: (clientId: string) => void }) {
  const { data: oauthClients = [], isLoading } = consoleApi.useListOauthClientsQuery();
  const [globalFilter, setGlobalFilter] = useState('');

  const table = useReactTable({
    columns,
    data: oauthClients,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    columnResizeMode: 'onChange',
    autoResetPageIndex: false,
    meta: { onDelete },
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    state: {
      globalFilter,
    },
  });

  return (
    <DataGridHelpers.Wrapper>
      <DataGridHelpers.OuterHeader>
        <div className="flex w-full flex-wrap justify-between gap-2">
          <div className="flex basis-[400px] gap-2">
            <SearchField
              className="min-w-36 grow"
              value={globalFilter}
              onChange={(event) => setGlobalFilter(event.target.value)}
              htmlAttributes={{ 'aria-label': 'Search for project user' }}
            />
          </div>
        </div>
      </DataGridHelpers.OuterHeader>{' '}
      <DataGrid
        isLoading={isLoading}
        isResizable={false}
        tableInstance={table}
        components={{
          NoDataPlaceholder,
          RowsPerPage: null,
        }}
        styling={{
          headerStyle: 'clean',
        }}
        isKeyboardNavigable={false}
      />
    </DataGridHelpers.Wrapper>
  );
}

export const ApiKeysLayout = () => {
  const { data: oauthClients } = consoleApi.useListOauthClientsQuery();
  const { data: userDetails } = consoleApi.useGetUserDetailsQuery();
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [clientIdToDelete, setClientIdToDelete] = useState<string>('');

  const allowCreateKeys = userDetails?.auraApiEnabled ?? false;

  useEffect(() => {
    if (oauthClients && clientIdToDelete && !oauthClients.some((client) => client.clientId === clientIdToDelete)) {
      setClientIdToDelete('');
    }
  }, [clientIdToDelete, oauthClients]);

  return (
    <div className="mx-auto mt-2 h-full max-w-[1024px] overflow-auto p-4">
      <div className="mb-6 flex justify-between pl-2 pt-2">
        <span>
          <Typography variant="h2" className="inline">
            API Keys
          </Typography>
          <TextLink
            className="ml-4 no-underline"
            isExternalLink
            href="https://neo4j.com/docs/aura/platform/api/overview/"
          >
            Docs
          </TextLink>
        </span>
        <PermissionTooltip hasPermission={allowCreateKeys} hasButtonWrapper className="shrink-0">
          <Button isDisabled={!allowCreateKeys} onClick={() => setCreateModalIsOpen(true)}>
            Create
          </Button>
        </PermissionTooltip>
      </div>
      <ApiKeysTable onDelete={setClientIdToDelete} />
      {createModalIsOpen && <CreateApiKeyModal onClose={() => setCreateModalIsOpen(false)} />}
      {clientIdToDelete && <DeleteApiKeyModal clientId={clientIdToDelete} onClose={() => setClientIdToDelete('')} />}
    </div>
  );
};
