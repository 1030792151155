import { differenceInMilliseconds } from 'date-fns';
import { isEqual } from 'lodash-es';

import { LOCALE_TAG } from '../metrics/shared/constants';

/**
 * Capitalize the first letter of a string.
 *
 * Ex: red -> Red
 *
 * @param {string} s
 * @returns The result of the input string with first letter capitalized
 */
export function capitalize(s: string): string {
  return s[0]?.toUpperCase() + s.slice(1);
}

export function capitalizeFirstLowerRest(s: string): string {
  return s[0]?.toUpperCase() + s.slice(1).toLowerCase();
}

/**
 * Capitalize every first letter of a word and split string.
 * Used for converting route/path to Readable text
 *
 * Ex: system-schema -> System Schema
 *
 * @param {string} s
 * @returns The result of the input string with Camel Case format
 */
export function camelcase(s: string): string {
  const camelCase = s.replace(/(^\w{1})|(\s{1}\w{1})|(-\w{1})/g, (match) => match.toUpperCase());

  return camelCase.split('-').join(' ');
}

/**
 * Find min timestamp
 * @param arr Array of timestamp strings
 * @returns minimum timestamp value
 */
export function minTimestamp(arr: string[]): string {
  return arr.reduce((a, b) => (a > b ? a : b));
}

/**
 * Find max timestamp
 * @param arr Array of timestamp strings
 * @returns maximum timestamp value
 */
export function maxTimestamp(arr: string[]): string {
  return arr.reduce((a, b) => (a < b ? a : b));
}

/**
 * Extract hostname(with port)
 *
 * Example bolt://localhost:8909 -> localhost:8909
 *
 * @param url The URL to extract hostname
 * @returns The hostname or false if the url is not proper
 */
export function extractHost(url: string): string {
  const regex = /^\w+\+?s?:\/\/(.*)/g;
  const result = regex.exec(url);
  if (result && result.length > 1) {
    return result[1] ?? '';
  }
  throw new Error('Fetched member addresses are not proper URLs');
}

/**
 * Delay helper
 *
 * @param t Delay time in ms
 * @returns A promise resolved in t ms
 */
export function delay(t: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, t);
  });
}

/**
 * Deep Prop Equality Check
 *
 * @param prevProps Previous Props Object
 * @param nextProps Next Props Object
 * @returns boolean
 */
export function arePropsDeepEqual(prevProps: unknown, nextProps: unknown) {
  return isEqual(prevProps, nextProps);
}

/**
 * Modified from:
 * https://web.archive.org/web/20120507054320/http://codeaid.net/javascript/convert-size-in-bytes-to-human-readable-format-(javascript)
 * */
export const formatBytes = (bytesParam: number | string, decimals = 2): string => {
  let bytes = bytesParam;
  if (typeof bytes === 'string') {
    bytes = parseFloat(bytes);
  }
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const formatDuration = (durationParam: number | string, secondsPrecision = 0): string => {
  let duration = durationParam;
  if (typeof duration === 'string') {
    duration = parseFloat(duration);
  }
  if (duration < 1000) {
    return `${duration.toFixed(0)} ms`;
  }

  duration /= 1000;
  const seconds = Math.floor(duration % 60);
  const minutes = Math.floor((duration % 3600) / 60);
  const hours = Math.floor((duration % 86400) / 3600);
  const days = Math.floor(duration / 86400);

  const components = [];
  if (days > 0) {
    components.push(`${days}d`);
  }
  if (hours > 0) {
    components.push(`${hours}h`);
  }
  if (minutes > 0) {
    components.push(`${minutes}m`);
  }

  /**
   * construct precision for seconds component
   * separate ms and if not 0, slice desired precision and if not 0 use it
   */
  let precision = '';
  if (secondsPrecision) {
    const msString = `${duration.toString()}`.split('.')[1] ?? '0';
    const sliced = Number.parseInt(msString, 10) > 0 ? msString.slice(0, secondsPrecision) : '0';

    if (Number.parseInt(sliced, 10) > 0) {
      precision = `.${sliced}`;
    }
  }

  components.push(`${seconds}${precision}s`);

  return components.join(':');
};

/**
 * Returns formatted duration from now() to the passed date
 * @param dateOrString Date or ISO string representation of a date
 */
export const durationToNow = (dateOrString: string | Date) =>
  formatDuration(differenceInMilliseconds(new Date(), new Date(dateOrString)));

/**
 * Format absolute numbers
 *
 * Examples:
 * `1.2e6 = 1.200.000 => 1.2M` -
 * `1.2e9 = 1.200.000.000 => 1.2B`
 */
export const compactFormatAbsoluteNumber = (input: number, precision?: number) =>
  Intl.NumberFormat(LOCALE_TAG, {
    notation: 'compact',
    compactDisplay: 'short',
    maximumFractionDigits: precision,
  }).format(input);

export const standardFormatAbsoluteNumber = (input: number) =>
  Intl.NumberFormat(LOCALE_TAG, {
    notation: 'standard',
    maximumFractionDigits: 2,
  }).format(input);

/** Formats a (number) string based on locale preferences  */
export const formatLocaleString = (value: string | number) => {
  let valueCopy = value;
  if (typeof valueCopy === 'string') {
    valueCopy = parseFloat(valueCopy);
  }
  return valueCopy.toLocaleString(LOCALE_TAG);
};

/** Check if string contains any whitespace */
export const hasWhiteSpace = (s: string) => {
  const regexWhitespace = /\s/;
  return regexWhitespace.test(s);
};

/**
 * Check that roleName only contains ASCII alphabetic characters , numeric characters, and underscores.
 * as specified here:
 * https://neo4j.com/docs/cypher-manual/current/access-control/manage-roles/#access-control-create-roles
 */
export const roleNameOnlyContainsLegalValues = (roleName: string) => {
  const validRoleRegex = /^[a-zA-Z0-9_]*$/;
  return validRoleRegex.test(roleName);
};

export const startsWithALetter = (s: string) => {
  const validRoleRegex = /^[a-zA-Z]/;
  return validRoleRegex.test(s);
};

/** Converts protocol to all lowercase */
export const sanitizeProtocol = (url: string) => {
  const [protocol, hostname] = url.split('://');
  const lowerProtocol = protocol?.toLocaleLowerCase();
  return [lowerProtocol, hostname].join('://');
};

/**
 * Remove newlines from sting
 * Useful for classnames with multiple
 * parameters
 */
export const removeNewlines = (input: string) => input.replace(/(\r\n|\n|\r)/gm, '');

/** Remove extra spaces from sting */
export const removeSpaces = (input: string) => input.replace(/\s+/g, ' ').trim();

/**
 * Simple Plural
 * (only appending "s") not suitable for words like
 * leaf, life, knife, etc.
 * @param input the thing to pluralize
 * @param quantity the quantity of the things to pluralize
 * @param inclusive should the number be included into the output
 */
export const simplePlural = (input: string, quantity: number, inclusive = false) =>
  `${inclusive ? `${quantity} ` : ''}${input}${quantity === 1 ? '' : 's'}`;

/**
 * Create a unique hex color
 * based on a string
 * Serves as a helper for an instance if the color
 * is not yet generated in the store, but multiple consumers
 * will display it in charts or elsewhere
 * (refactored from source)
 * Source: https://gist.github.com/0x263b/2bdd90886c2036a1ad5bcf06d6e6fb37
 *
 * If the input is empty, return a white HEX color
 */
export const createUniqueColor = (input: string): string => {
  let hash = 0;
  if (input.length === 0) {
    return '#ffffff';
  }
  for (let i = 0; i < input.length; i += 1) {
    // eslint-disable-next-line spaced-comment
    /*eslint no-bitwise: ["error", { "allow": ["<<", "&=", ">>","&"] }] */
    hash = input.charCodeAt(i) + ((hash << 5) - hash);
    hash &= hash;
  }
  let color = '#';
  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 255;
    color += `00${value.toString(16)}`.substring(2);
  }
  return color;
};

const getPaddedNumberString = (number: number): string => number.toString().padStart(2, '0');
/**
 * @param timestamp
 * @returns
 * Date and time if timestamp is more than 24 hours ago,
 * just time if timestamp is within the last 24 hours
 */
export const alertTimeFormatter = (timestamp: Date) => {
  const timeDifference = new Date().getTime() - timestamp.getTime();
  const hours = timestamp.getUTCHours();
  const minutes = timestamp.getUTCMinutes();
  const seconds = timestamp.getUTCSeconds();
  const timeString = `${getPaddedNumberString(hours)}:${getPaddedNumberString(
    minutes,
  )}:${getPaddedNumberString(seconds)}`;
  if (timeDifference < 24 * 60 * 60 * 1000) {
    return timeString;
  }
  const month = getPaddedNumberString(timestamp.getUTCMonth() + 1);
  const date = getPaddedNumberString(timestamp.getUTCDate());
  return `${timestamp.getUTCFullYear()}-${month}-${date} ${timeString}`;
};

const getDateString = (timestamp: Date) => {
  const month = timestamp.getMonth();
  const date = timestamp.getDate();
  if (date === new Date().getDate() && month === new Date().getMonth()) {
    return `Today`;
  }
  const monthWord = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][month];
  return `${monthWord} ${date}`;
};

function getTimeString(timestamp: Date, withMillis?: boolean) {
  const hours = timestamp.getHours();
  const minutes = timestamp.getMinutes();
  const seconds = timestamp.getSeconds();
  const timeString = `${getPaddedNumberString(hours)}:${getPaddedNumberString(
    minutes,
  )}:${getPaddedNumberString(seconds)}`;
  if (withMillis ?? false) {
    const millis = timestamp.getMilliseconds();
    return `${timeString}.${millis.toString().padStart(3, '0')}`;
  }
  return timeString;
}

/**
 * @param timestamp
 * @returns
 * Used by TimePeriodAndRefresh component
 * Returns just the time if current date
 */
export const timePeriodFormatter = (timestamp: Date, withMs = false) =>
  `${getDateString(timestamp)} ${getTimeString(timestamp, withMs)}`;

export const calculateTimeRangeLabelFromMs = (ms: number, useInclusive = false) => {
  const compare: (a: number, b: number) => boolean = useInclusive ? (a, b) => a <= b : (a, b) => a < b;
  const timeDifferenceSeconds = ms / 1000;
  if (compare(timeDifferenceSeconds, 60)) {
    return `${Math.floor(timeDifferenceSeconds)}s`;
  }
  if (compare(timeDifferenceSeconds / 60, 60)) {
    return `${Math.floor(timeDifferenceSeconds / 60)}m`;
  }
  if (compare(timeDifferenceSeconds / 3600, 24)) {
    return `${Math.floor(timeDifferenceSeconds / 3600)}h`;
  }
  return `${Math.floor(timeDifferenceSeconds / 3600 / 24)}d`;
};

/**
 * @returns
 * Used by TimePeriodAndRefresh component
 * Calculates a time difference in the most appropriate unit
 * @param start
 * @param end
 */
export const calculateTimeRangeLabel = (start: Date, end: Date) => {
  const timeDifferenceMs = end.getTime() - start.getTime();
  return calculateTimeRangeLabelFromMs(timeDifferenceMs);
};

/**
 * Checks if a Date timestamp
 * is today or not
 *
 * This needs to be performant check the bench here:
 * https://jsbench.me/mal2yl062a/1
 */
export const isToday = (date: Date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

/**
 * Receives a date and returns a string
 * to unix timestamp (seconds) to comply with the back-end
 * @param date
 * @returns int
 */
export const dateToUnixSeconds = (date: Date) => Math.floor(date.getTime() / 1000);

export const splitCypherCommand = (command: string) => command.match(/.*?; ?/g) ?? [command];

/**
 * Checks if `searchString` is contained in `s` ignoring the case.
 * @param s string to search in
 * @param searchString the string to search for
 */
export const includesIgnoreCase = (s: string | undefined | null, searchString: string): boolean =>
  Boolean(s?.toLowerCase().includes(searchString.toLowerCase()));

export const getColorWithOpacity = (hexColor: string, opacity: number) => {
  // https://www.digitalocean.com/community/tutorials/css-hex-code-colors-alpha-values#adding-an-alpha-value-to-css-hex-codes
  const alpha = (opacity * 255).toString(16);
  return `${hexColor.slice(0, 7)}${alpha}`;
};
