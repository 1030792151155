import type { JSONSchemaType } from 'ajv';

import type { BaseArguments } from '../types/sdk-types';

function parseDuration(str: string): number {
  type Units = (typeof units)[number];
  const units = ['ns', 'μs', 'ms', 's', 'm', 'h', 'd'] as const;
  const regexp = new RegExp(`^(\\d+)(${units.join('|')})$`);
  const match = str.match(regexp);

  const [, value, unit] = match ?? [];

  if (value === undefined || unit === undefined) {
    return Number.NaN;
  }

  const ratios: Record<Units, number> = {
    ns: 1e-6,
    μs: 1e-3,
    ms: 1,
    s: 1e3,
    m: 6e4,
    h: 36e5,
    d: 864e5,
  };

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const multiplier = ratios[unit as Units];

  return Number(value) * multiplier;
}

function parsePostConnectCommands(str: string): string[] {
  let commandString = str;

  const substituteStr = '@@semicolon@@';
  const substituteRe = new RegExp(substituteStr, 'g');
  const replaceFn = (m: string) => m.replace(/;/g, substituteStr);

  const qs = [/(`[^`]*?`)/g, /("[^"]*?")/g, /('[^']*?')/g];
  qs.forEach((q) => (commandString = commandString.replace(q, replaceFn)));

  const splitted = commandString
    .split(';')
    .map((item: string) => item.trim())
    .map((item: string) => item.replace(substituteRe, ';'))
    .filter((item: string) => item.length);

  return splitted.length ? splitted : [];
}

export type Configuration = {
  allowOutgoingConnections: boolean;
  allowTelemetry: boolean;
  credentialTimeoutMs: number;
  remoteContentHostnameWhitelist: string[];
  retainEditorHistory: boolean;
  retainConnectionCredentials: boolean;
  postConnectCommands: string[];
  serverMetricsPrefix: string;
  namespacesEnabled: boolean;
};

/*
 * References:
 * https://neo4j.com/docs/operations-manual/current/configuration/configuration-settings/
 * https://neo4j.com/docs/browser-manual/current/operations/browser-settings/#adjust-globally
 */
export async function getConfiguration({ queryCypher }: BaseArguments): Promise<Configuration> {
  const query = `CALL dbms.clientConfig() YIELD name, value`;

  const res = await queryCypher(query);

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const records = Object.fromEntries(
    res.records.map((rec) => rec.toObject()).map(({ name, value }) => [name, value]),
  ) as Record<string, string>;

  return {
    allowOutgoingConnections: records['browser.allow_outgoing_connections'] === 'true',
    allowTelemetry: records['client.allow_telemetry'] === 'true' || records['clients.allow_telemetry'] === 'true',
    credentialTimeoutMs: Math.floor(parseDuration(String(records['browser.credential_timeout']))),
    remoteContentHostnameWhitelist: (records['browser.remote_content_hostname_whitelist'] ?? '').split(',').map(String),
    retainEditorHistory: records['browser.retain_editor_history'] === 'true',
    retainConnectionCredentials: records['browser.retain_connection_credentials'] === 'true',
    postConnectCommands: parsePostConnectCommands(String(records['browser.post_connect_cmd'] ?? '')),
    serverMetricsPrefix: String(records['server.metrics.prefix'] ?? '') || String(records['metrics.prefix'] ?? ''),
    namespacesEnabled: records['metrics.namespaces.enabled'] !== 'false',
  };
}

export const configurationSchema: JSONSchemaType<Configuration> = {
  type: 'object',
  properties: {
    allowOutgoingConnections: { type: 'boolean' },
    allowTelemetry: { type: 'boolean' },
    credentialTimeoutMs: { type: 'number' },
    remoteContentHostnameWhitelist: { type: 'array', items: { type: 'string' } },
    retainEditorHistory: { type: 'boolean' },
    retainConnectionCredentials: { type: 'boolean' },
    postConnectCommands: { type: 'array', items: { type: 'string' } },
    serverMetricsPrefix: { type: 'string' },
    namespacesEnabled: { type: 'boolean' },
  },
  required: [
    'allowOutgoingConnections',
    'allowTelemetry',
    'credentialTimeoutMs',
    'remoteContentHostnameWhitelist',
    'retainEditorHistory',
    'retainConnectionCredentials',
    'postConnectCommands',
  ],
  additionalProperties: false,
};
