import { isNullish } from '@nx/stdlib';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, nanoid } from '@reduxjs/toolkit';

import type { RootState } from '../store';

export type ConfigurationState = {
  // Id and name are optional as it will break WS and standalone if we set them mandatory.
  importModelId?: string;
  importModelName?: string;
  dataSourceId?: string;
  sentryUserId: string;
  shouldClearLocalStorage: boolean;
};

export const initialState: ConfigurationState = {
  sentryUserId: nanoid(),
  shouldClearLocalStorage: false,
};

const configurationsSlice = createSlice({
  name: 'configurations',
  initialState,
  reducers: {
    initialiseConfigurations(state, action: PayloadAction<ConfigurationState>) {
      const { sentryUserId } = action.payload;
      if (!isNullish(sentryUserId)) {
        state.sentryUserId = sentryUserId;
      }
    },
    toggleShouldClearLocalStorage(state, action: PayloadAction<boolean>) {
      state.shouldClearLocalStorage = action.payload;
    },
    setImportModelId(state, action: PayloadAction<string | undefined>) {
      state.importModelId = action.payload;
    },
    setImportModelName(state, action: PayloadAction<string | undefined>) {
      state.importModelName = action.payload;
    },
    setDataSourceId(state, action: PayloadAction<string | undefined>) {
      state.dataSourceId = action.payload;
    },
  },
});

export const selectConfigurations = (state: RootState) => state.configurations;

export const selectDataSourceId = (state: RootState) => state.configurations.dataSourceId;

export const selectShouldClearLocalStorage = (state: RootState) => state.configurations.shouldClearLocalStorage;

export const selectImportModelId = (state: RootState) => state.configurations.importModelId;

export const {
  initialiseConfigurations,
  toggleShouldClearLocalStorage,
  setImportModelId,
  setImportModelName,
  setDataSourceId,
} = configurationsSlice.actions;
export default configurationsSlice.reducer;
