export type PluginInfo = {
  name: string;
  description: string;
  links: { github: string; documentation: string };
};

export const PLUGINS_INFO: Record<string, undefined | PluginInfo> = {
  apoc: {
    name: 'APOC',
    description:
      'The APOC library consists of many (about 450) procedures and functions to help with many different ' +
      'tasks in areas like data integration, graph algorithms or data conversion.',

    links: {
      github: 'https://github.com/neo4j-contrib/neo4j-apoc-procedures',
      documentation: 'https://neo4j.com/labs/apoc/',
    },
  },

  apoc5plus: {
    name: 'APOC',
    description:
      'The APOC library consists of many (about 450) procedures and functions to help with many different ' +
      'tasks in areas like collections manipulation, graph algorithms, and data conversion.',
    links: {
      github: 'https://github.com/neo4j/apoc',
      documentation: 'https://neo4j.com/docs/apoc/5/overview/',
    },
  },

  'graph-data-science': {
    name: 'Graph Data Science Library',
    description:
      'The Neo4j Graph Data Science (GDS) library provides extensive analytical capabilities ' +
      'centered around graph algorithms. The library includes algorithms for community detection, ' +
      'centrality, node similarity, path finding, and link prediction, as well as graph catalog procedures ' +
      'designed to support data science workflows and machine learning tasks over your graphs. ' +
      'All operations are designed for massive scale and parallelisation, ' +
      'with a custom and general API tailored for graph-global processing, ' +
      'and highly optimised compressed in-memory data structures.',

    links: {
      documentation: 'https://neo4j.com/docs/graph-data-science/current/?ref=desktop',
      github: 'https://github.com/neo4j/graph-data-science/',
    },
  },

  graphql: {
    name: 'GraphQL',
    description:
      'This is a GraphQL-Endpoint extension for Neo4j. Based on your GraphQL schema, it translates GraphQL ' +
      'Queries and Mutations into Cypher statements and executes them on Neo4j. It offers both ' +
      'an HTTP API, as well as, Neo4j Cypher Procedures to execute and manage your GraphQL API.',

    links: {
      documentation: 'https://neo4j.com/developer/graphql/?ref=desktop',
      github: 'https://github.com/neo4j-graphql/neo4j-graphql',
    },
  },
};
