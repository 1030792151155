import { isEmpty, isNil } from 'lodash-es';

import type {
  ActionSuggestion,
  FullTextSearchSuggestion,
  NodeSuggestion,
  RelationshipSuggestion,
  SearchPhraseSuggestion,
  Suggestion,
  SuggestionWithValidationState,
} from './types';
import { SUGGESTION_TYPE } from './types';

// enforce immutability at runtime
export const buildSuggestion = <SpecificSuggestion extends Suggestion>(suggestion: Suggestion): SpecificSuggestion => {
  return Object.freeze({
    ...suggestion,
  }) as SpecificSuggestion;
};

export const buildSearchPhraseSuggestion = (templateSuggestion: any) => {
  return buildSuggestion<SearchPhraseSuggestion>({
    ...templateSuggestion,
    type: SUGGESTION_TYPE.SEARCH_PHRASE,
  });
};

export const startValidatingSuggestion = (suggestion: SuggestionWithValidationState) => {
  return Object.assign({}, suggestion, { isValidating: true });
};

export const endValidatingSuggestion = (suggestion: SuggestionWithValidationState) => {
  return Object.assign({}, suggestion, { isValidating: false });
};

export const updateValidCountOnSuggestion = (suggestion: Suggestion, validCount: string) => {
  return Object.assign({}, suggestion, { validCount });
};

export const lockNodeSuggestion = (newSuggestions: Suggestion[], lockedSuggestions: Suggestion[]) => {
  const head = newSuggestions[0] as NodeSuggestion;
  const { propertyName, propertyCondition, propertyConditionValue } = head;

  // the new one replaces the current one
  if (
    isNodeSuggestion(lockedSuggestions.at(-1)) &&
    (!isNil(propertyName) || !isNil(propertyCondition) || !isNil(propertyConditionValue))
  ) {
    lockedSuggestions.pop();
  }

  return [...lockedSuggestions, ...newSuggestions];
};

export const lockRelationshipSuggestion = (newSuggestions: Suggestion[], lockedSuggestions: Suggestion[]) => {
  const head = newSuggestions[0] as RelationshipSuggestion;
  const { propertyName, propertyCondition, propertyConditionValue } = head;

  // the new one replaces the current one
  if (
    isRelationshipSuggestion(lockedSuggestions.at(-1)) &&
    (!isNil(propertyName) || !isNil(propertyCondition) || !isNil(propertyConditionValue))
  ) {
    lockedSuggestions.pop();
  }

  return [...lockedSuggestions, ...newSuggestions];
};

export const lockFullTextSearchSuggestion = (
  fullTextSearchSuggestion: FullTextSearchSuggestion,
  lockedSuggestions: Suggestion[],
) => {
  const [...newLockedSuggestions] = lockedSuggestions;
  if (fullTextSearchSuggestion.isDisabled) {
    return newLockedSuggestions;
  }

  if (isFullTextSearchSuggestion(lockedSuggestions.at(0))) {
    newLockedSuggestions.pop();
  }

  newLockedSuggestions.push(fullTextSearchSuggestion);

  return newLockedSuggestions;
};

export const findLeftEntryIdx = (
  suggestions: Suggestion[],
  entryType: keyof typeof SUGGESTION_TYPE,
  startIdx = suggestions?.length - 1,
) => {
  if (isEmpty(suggestions)) {
    return -1;
  }

  while (startIdx >= 0 && suggestions.at(startIdx)?.type !== entryType) {
    --startIdx;
  }

  return startIdx < 0 ? -1 : startIdx;
};

export const isNodeSuggestion = (suggestion?: Suggestion): suggestion is NodeSuggestion => {
  return suggestion?.type === SUGGESTION_TYPE.NODE;
};

export const isRelationshipSuggestion = (suggestion?: Suggestion): suggestion is RelationshipSuggestion => {
  return suggestion?.type === SUGGESTION_TYPE.RELATIONSHIP;
};

export const isSearchPhraseSuggestion = (suggestion?: Suggestion): suggestion is SearchPhraseSuggestion => {
  return suggestion?.type === SUGGESTION_TYPE.SEARCH_PHRASE;
};

export const isFullTextSearchSuggestion = (suggestion?: Suggestion): suggestion is FullTextSearchSuggestion => {
  return suggestion?.type === SUGGESTION_TYPE.FULL_TEXT_SEARCH;
};

export const isActionSuggestion = (suggestion?: Suggestion): suggestion is ActionSuggestion => {
  return suggestion?.type === SUGGESTION_TYPE.ACTION;
};

export const alignCase = (isCaseInsensitive: boolean, s: string) => (isCaseInsensitive ? s.toLowerCase() : s);

export const alignCaseOrUndefined = (isCaseInsensitive: boolean, s?: string) =>
  isCaseInsensitive ? s?.toLowerCase() : s;

export const wrapInEqualsQueryRegexp = (isCaseInsensitive: boolean, query: string) =>
  isCaseInsensitive ? `(?i)${query}` : query;

export const wrapInStartWithQueryRegexp = (isCaseInsensitive: boolean, query: string) =>
  isCaseInsensitive ? `(?i)${query}.*` : query;

export const wrapInContainsQueryRegexp = (isCaseInsensitive: boolean, query: string) =>
  isCaseInsensitive ? `(?i).*${query}.*` : query;
