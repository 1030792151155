import { Checkbox, TextLink, Typography } from '@neo4j-ndl/react';
import { REGIONAL_GDPR_COMPLIANCE_TIER } from '@nx/state';
import { isNullish } from '@nx/stdlib';
import React, { type PropsWithChildren } from 'react';

/*
  The Terms and Conditions content was copied here directly from Aura Console.
*/

const version = () => 'v11';
const lastUpdated = 'August 13, 2024';

function List({ bulleted, children }: PropsWithChildren<{ bulleted: boolean }>) {
  return <ul className="my-4 list-inside list-disc">{children}</ul>;
}
function ListItem({ children }: PropsWithChildren) {
  return <li className="[&>*]:inline">{children}</li>;
}
List.Item = ListItem;

const Content = () => (
  <article>
    <p>Date last updated: {lastUpdated}</p>
    <p>
      IMPORTANT &ndash; CAREFULLY READ ALL THE TERMS AND CONDITIONS OF THIS NEO4J CLOUD TERMS OF SERVICE (THIS
      &quot;AGREEMENT&quot;). BY CREATING AN ACCOUNT TO USE THE NEO4J CLOUD SERVICES (&quot;SERVICE&quot;), CLICKING
      &quot;I ACCEPT&quot;, OR PROCEEDING WITH THE USE OF THE SERVICE, INDIVIDUALLY, AND/OR YOU AS AN AUTHORIZED
      REPRESENTATIVE OF YOUR COMPANY ON WHOSE BEHALF YOU USE THE SERVICE (&quot;YOU&quot;), ARE INDICATING THAT YOU HAVE
      READ, UNDERSTOOD AND ACCEPT THIS AGREEMENT WITH NEO4J, INC. (&quot;NEO4J&quot;) AND THE NEO4J PRIVACY POLICY, AND
      THAT YOU AGREE TO BE BOUND BY THE TERMS. IF YOU DO NOT AGREE WITH ALL OF THE TERMS OF THIS AGREEMENT, YOU MAY NOT
      USE THE SERVICE. THE EFFECTIVE DATE OF THIS AGREEMENT SHALL BE THE DATE THAT YOU ACCEPT THIS AGREEMENT.
    </p>
    <p>1. SERVICE.</p>
    <p>
      (a) Provision of Service. Neo4j grants You the right to access and use the Service in accordance with this
      Agreement. You will comply with all user documentation and all laws, rules, and regulations applicable to the use
      of Service.
    </p>
    <p>
      (b) Restrictions on use of the Service. You may not: (i) modify, alter, tamper with, repair, or otherwise create
      derivative works of any software included in the Service; (ii) reverse engineer, disassemble, or decompile any
      software included in the Service or apply any other process or procedure to derive the source code of any software
      included in the Service; (iii) access or use the Service in a way intended to avoid incurring fees or exceeding
      usage limits or quotas; (iv) rent, transfer, resell, or sublicense the Service; (v) use the Service in connection
      with any work or derivative work of the Neo4j database; (vi) attempt to disable or circumvent any security,
      billing, or monitoring mechanisms used by the Service; (vii) use the Service to perform a malicious activity;
      (viii) upload or otherwise process any malicious content to or through the Service; or (ix) benchmark or perform
      competitive analysis on the Service. The specific Service level that You select may have storage and other
      limitations.
    </p>
    <p>
      (c) Updates to the Service. Neo4j may from time to time make updates to the Service as it deems reasonably
      necessary, and this Agreement shall apply to such updated Service. Your continued use of the updated Service
      indicates Your acceptance.
    </p>
    <p>2. REGISTRATION; YOUR ACCOUNT AND FEES.</p>
    <p>
      (a) Registration. To register to use the Service, You must provide Neo4j with the information requested in the
      registration process, including Your name, email address and payment information. You are responsible for all
      activities that occur under Your account; Neo4j and Neo4j&apos;s affiliates are not responsible for unauthorized
      access to Your account. You will contact Neo4j immediately if You believe an unauthorized third party may be using
      Your account or if Your account information is lost or stolen. You will provide complete and accurate information
      during the registration process and will update it to ensure it remains accurate.
    </p>
    <p>
      (b) Excess Usage. The Service has usage limitations based on the capacity or other metrics as set forth on the
      pricing schedule (&ldquo;Quantity&rdquo;). Neo4j may monitor Your use of the Service. When Your use exceeds the
      applicable Quantity, You shall upgrade to the appropriate tier of Service as set forth on the pricing schedule
      within 3 days or Neo4j will upgrade You automatically. You shall pay additional fees in accordance with Section
      2(c) below for current and future use.
    </p>
    <p>
      (c) Fees and Expenses. You shall pay Neo4j the fees for the Service on a monthly basis in arrears based on the
      hours of use of the Service and allocated RAM in the prior month, pursuant to the pricing schedule, as more
      specifically detailed within the Service. All fees are payable in the currency of the United States through our
      payment processor (&ldquo;Stripe&rdquo;). You will be responsible for all taxes resulting from the performance of
      the Service other than taxes on Neo4j&rsquo;s income. If all or any part of any payment owed to Neo4j under this
      Agreement is withheld, based upon a claim that such withholding is required pursuant to the tax laws of any
      country or its political subdivisions and/or any tax treaty between the U.S. and any such country, such payment
      shall be increased by the amount necessary to result in a net payment to Neo4j of the amounts otherwise payable
      under this Agreement. All fees paid or payable under this Agreement are non-refundable. Neo4j may change its fees
      and payment terms at its discretion.
    </p>
    <p>
      (d) Payments through Stripe. In order to make payments to Neo4j, You may be required to provide Your credit card
      details to Stripe.&nbsp;&nbsp;Payment processing services by Stripe are subject to the Stripe Security Policy,
      found here:{' '}
      <a rel="noopener noreferrer" target="_blank" href="https://stripe.com/docs/security/stripe">
        https://stripe.com/docs/security/stripe
      </a>
      , and the Stripe Privacy Policy, found here:{' '}
      <a rel="noopener noreferrer" target="_blank" href="https://stripe.com/privacy">
        https://stripe.com/privacy
      </a>
      , which Stripe may update from time to time. As a condition of Neo4j enabling payment processing services through
      Stripe, You agree to provide Neo4j accurate and complete information about You and Your business, and You
      authorize Neo4j to share it and transaction information (exclusive of any credit or debit card numbers, details or
      associated passwords) related to Your use of the payment processing services provided by Stripe.
    </p>
    <p>
      (e) Communications. If You elect to use the Service, You are expressly agreeing that Neo4j or its payment
      processor, is permitted to bill You any applicable fees, any applicable tax and any other charges You may incur
      with Neo4j in connection with Your use of the Service. The fees will be billed to the credit card or other payment
      account You provide in accordance with the billing terms in effect at the time the fees are due and payable. You
      acknowledge and agree that Neo4j will automatically charge Your credit card or other payment account on record
      with Neo4j. If payment is not received or cannot be charged to Your credit card account for any reason, Neo4j
      reserves the right to either suspend or terminate Your access to the Service and terminate this Agreement. By
      using the Service, You consent to receiving electronic communications from Neo4j. These electronic communications
      may include notices about applicable fees and charges related to the Service and transactional or other
      information concerning or related to the Service. These electronic communications are part of Your relationship
      with Neo4j and You receive them as part of Your use of the Service. You agree that any notices, agreements,
      disclosures or other communications that we send You electronically will satisfy any legal communication
      requirements, including that such communications be in writing.
    </p>
    <p>
      (f) Acceptable Use. In addition to the prohibitions set forth in Section 1(b) above, You agree not to, and not to
      allow third parties to use the Service:
    </p>
    <List bulleted>
      <List.Item>
        <p>
          to violate, or encourage the violation of, the legal rights of others (for example, infringing or
          misappropriate the intellectual property rights of others in violation of the Digital Millennium Copyright
          Act);
        </p>
      </List.Item>
      <List.Item>
        <p> to engage in, promote or encourage illegal activity;</p>{' '}
      </List.Item>
      <List.Item>
        <p>
          for any unlawful, invasive, infringing, defamatory or fraudulent purpose (for example, this may include
          phishing, creating a pyramid scheme or mirroring a website);
        </p>
      </List.Item>
      <List.Item>
        <p>
          to intentionally distribute viruses, worms, Trojan horses, corrupted files, hoaxes, or other items of a
          destructive or deceptive nature;
        </p>
      </List.Item>
      <List.Item>
        <p>
          to interfere with the use of the Service, or the equipment used to provide the Service, by customers,
          authorized resellers, or other authorized users;
        </p>
      </List.Item>
      <List.Item>
        <p>to disable, interfere with or circumvent any aspect of the Service;</p>
      </List.Item>
      <List.Item>
        <p>
          to generate, distribute, publish or facilitate unsolicited mass email, promotions, advertisings or other
          solicitations (&ldquo;spam&rdquo;); or
        </p>
      </List.Item>
      <List.Item>
        <p>
          to use the Service, or any interfaces provided with the Service in a manner that violates the terms of this
          Agreement.
        </p>
      </List.Item>
    </List>
    <p>
      If You become aware of any use or content that is in violation of the foregoing Acceptable Use restrictions, You
      agree to promptly remedy such use or content. If you fail to do so, Neo4j or its providers may suspend or disable
      access to the Service (including Your Data) until You comply.
    </p>
    <p>3. INTELLECTUAL PROPERTY RIGHTS AND OWNERSHIP.</p>
    <p>
      (a) Neo4j Rights. This Agreement does not transfer any right, title or interest in any intellectual property right
      to the other, except as expressly set forth in this Agreement. Neo4j owns all rights, title and interest in and to
      the Service. There are no implied rights. Neo4j reserves all rights not expressly granted herein. If You provide
      Neo4j with feedback on Your use of the Service, or if Neo4j receives any information reported automatically
      through the Service, You hereby irrevocably assign to Neo4j all rights in the feedback and Neo4j may use Your
      feedback without obligation to You.
    </p>
    <p>
      (b) Your Rights in Your Data. You represent and warrant to Neo4j that: (a) You or Your licensors own all right,
      title, and interest in and to any and all permitted electronic data uploaded and stored by You in the Service
      (&quot;Your Data&quot;); (b) You have all rights in Your Data necessary to grant the rights contemplated by this
      Agreement; and (c) none of Your Data violates this Agreement, any applicable law or regulation or any third
      party&apos;s intellectual property or other right.
    </p>
    <p>4. YOUR DATA.</p>
    <p>
      You are solely responsible for the development, content, operation, maintenance, and use of Your Data. Your Data
      does not include Prohibited Information as defined below. You will ensure that Your Data, and Your use of it,
      complies with this Agreement and any applicable laws and regulations. You are responsible for properly configuring
      and using the Service and taking Your own steps to maintain appropriate security, protection and backup of Your
      Data. You hereby consent that Neo4j may use Your Data, the queries You submit to the Service, and metadata about
      Your usage of the Service to measure and improve the Service and support Your usage of the Service. If You include
      any data about any individual in Your use of the Service, (1) Neo4j will hold and store Your Data on Your behalf,
      and You are the data controller of such data; (2) Neo4j will process personal data in compliance with this
      Section, Your instructions and in accordance with Neo4j&apos;s privacy policy located at{' '}
      <a rel="noopener noreferrer" target="_blank" href="https://neo4j.com/privacy-policy/">
        https://neo4j.com/privacy-policy/
      </a>
      ; (3) You agree to follow all applicable instructions to parameterize Your Data as set forth at{' '}
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://neo4j.com/docs/cypher-manual/current/syntax/parameters/"
      >
        https://neo4j.com/docs/cypher-manual/current/syntax/parameters/
      </a>
      ; and (4) You warrant that: (a) Your instructions to Neo4j comply with applicable privacy and data protection laws
      and regulations, (b) You have all appropriate consents and an appropriate lawful basis to provide the data to the
      Service, and (c) You have provided proper privacy notifications to individuals as required by applicable laws and
      regulations. If You are located in the European Union or will transmit any of Your Data that includes personal
      data regarding a resident of the European Union, You may contact us at{' '}
      <a rel="noopener noreferrer" target="_blank" href="mailto:dpa@neo4j.com">
        dpa@neo4j.com
      </a>{' '}
      to request a data processing addendum that is pre-signed by Neo4j and You agree that under this Agreement Neo4j is
      merely a data processor. Your use of the Services does not require the entry or collection of credit or debit card
      numbers, associated passwords, protected health information as defined in HIPAA (45 C.F.R. &sect; 160.103),
      information relating to a customer or consumer of a financial institution under GLBA (15 U.S.C. &sect;&sect;
      6801&ndash;6809), or other sensitive data (&ldquo;Prohibited Information&rdquo;). You represent and warrant that
      You will not use the Services to collect, manage, store or process any Prohibited Information. Neo4j will use
      commercially reasonable efforts designed to prevent the unauthorized disclosure or destruction of Your stored
      Data.
    </p>
    <p>5. CONFIDENTIAL INFORMATION</p>
    <p>
      &ldquo;Confidential Information&rdquo; means any proprietary information that is marked &ldquo;confidential&rdquo;
      or &ldquo;proprietary&rdquo; or any other similar term or in relation to which its confidentiality should by its
      nature be inferred or, if disclosed orally, is identified as being confidential at the time of disclosure and,
      within two (2) weeks thereafter, is summarized, appropriately labeled and provided in tangible form, received by
      the other party during, or prior to, entering into this Agreement including, without limitation, the Service and
      any non-public technical and business information. Confidential Information does not include information that (i)
      is or becomes generally known to the public through no fault of or breach of this Agreement by the receiving
      party; (ii) is rightfully known by the receiving party at the time of disclosure without an obligation of
      confidentiality; (iii) is independently developed by the receiving party without the use of the disclosing
      party&rsquo;s Confidential Information; or (iv) the receiving party rightfully obtains from a third party without
      restriction on use or disclosure. You and Neo4j will maintain the confidentiality of Confidential Information. The
      receiving party of any Confidential Information of the other party agrees not to use such Confidential Information
      for any purpose except as necessary to fulfill its obligations and exercise its rights under this Agreement. The
      receiving party shall protect the secrecy of and prevent disclosure and unauthorized use of the disclosing
      party&apos;s Confidential Information using the same degree of care that it takes to protect its own confidential
      information and in no event shall use less than reasonable care. The receiving party may disclose the Confidential
      Information of the disclosing party if required by judicial or administrative process, provided that the receiving
      party first provides to the disclosing party prompt notice of such required disclosure (to the extent allowed) to
      enable the disclosing party to seek a protective order. Upon termination or expiration of this Agreement, the
      receiving party will destroy (and provide written certification of such destruction) the disclosing party&rsquo;s
      Confidential Information.
    </p>
    <p>6. TERM; TERMINATION</p>
    <p>
      (a) Term; Termination. The term of this Agreement commences when You accept this Agreement (such as by creating an
      account or proceeding with the use of the Service) and will remain in effect until terminated in accordance with
      this Agreement. You may terminate this Agreement at any time by cancelling Your account. Neo4j may terminate this
      Agreement at any time on thirty (30) days advance notice. Neo4j may also terminate Your account and this
      Agreement, or suspend Your account, immediately if (i) Neo4j changes the way Neo4j provides or discontinues the
      Service; (ii) Your account was suspended under Section 7 of this Agreement and You have not remediated the reason
      for the suspension; or (iii) Neo4j determines that: (1) Your use of the Service poses a security risk to the
      Service or any third party; (2) Your use of the Service may adversely impact the Service; (3) Your use of the
      Service may subject Neo4j, Neo4j&apos;s affiliates, or any third party to liability; (4) Your use of the Service
      may be fraudulent; (5) You are in breach of this Agreement; or (6) You have ceased to operate in the ordinary
      course, made an assignment for the benefit of creditors or similar disposition of Your assets, or become the
      subject of any bankruptcy, reorganization, liquidation, dissolution or similar proceeding.
    </p>
    <p>
      (b) Effect of Termination. Upon termination of this Agreement (i) all Your rights under this Agreement immediately
      terminate and You must cease using the Service, (ii) You are solely responsible for deleting or retrieving Your
      Data from the Service prior to termination for any reason, and (iii) You must pay all unpaid fees to Neo4j. If
      either party terminates Your account or this Agreement, Neo4j will provide You with a reasonable opportunity to
      retrieve Your Data from the Service, if You so request. Such a request must be sent by email to Neo4j at{' '}
      <a rel="noopener noreferrer" target="_blank" href="mailto:cloud-recovery@neo4j.com">
        cloud-recovery@neo4j.com
      </a>{' '}
      within seven (7) days after You receive notice regarding the termination. In any event, Your Data will be deleted
      from the Service no earlier than fourteen (14) days after the termination notice regarding Your account has been
      sent to You. In case of non-payment Neo4j will terminate the Agreement and delete all data immediately after 29
      days of the first Suspension day. You will be notified about the Suspension in the Aura console and/or via email.
    </p>
    <p>
      (c) You understand and agree that Neo4j may change, suspend or discontinue any part of the Service and Service as
      a whole. Neo4j will notify You of any material change to or discontinuation of the Service by email or via
      Neo4j&apos;s website.
    </p>
    <p>7. SUSPENSION.</p>
    <p>
      Without limiting other available remedies included in this Agreement or otherwise, Neo4j may suspend Your access
      to the Service if You are in non-compliance with this Agreement.
    </p>
    <p>8. WARRANTY DISCLAIMER.</p>
    <p>
      You should regularly back up Your queries and Your Data while using the Service. NEO4J PROVIDES THE SERVICE ON AN
      &quot;AS IS&quot; BASIS. NEO4J DOES NOT MAKE ANY WARRANTIES REGARDING THE PERFORMANCE OF THE SERVICE OR UPTIME OF
      THE SERVICE, OR THAT YOUR USE OF THE SERVICES WILL BE SECURE, UNINTERRUPTED OR ERROR FREE, WHETHER EXPRESS,
      IMPLIED, STATUTORY OR OTHERWISE. NEO4J EXPRESSLY DISCLAIMS ALL WARRANTIES, INCLUDING, WITHOUT LIMITATION, THE
      IMPLIED WARRANTIES OF NON-INFRINGEMENT OF THIRD PARTY RIGHTS, TITLE, MERCHANTABILITY AND FITNESS FOR A PARTICULAR
      PURPOSE. NEO4J HAS NO RESPONSIBILITY FOR LOSS OF QUERIES OR DATA OR INABILITY TO USE THE SERVICE FOR ANY REASONS,
      INCLUDING, WITHOUT LIMITATION, IF DUE TO THE ACTS OR OMISSIONS OF ITS THIRD PARTY HOSTING PROVIDERS.
    </p>
    <p>
      NOTWITHSTANDING ANY OTHER PROVISION OF THIS AGREEMENT, NEO4J DISCLAIMS ANY AND ALL LIABILITY THAT MAY ARISE FROM
      YOUR DATA AND THE USE OF THE SERVICE TO COLLECT MANAGE, STORE OR PROCESS PROHIBITED INFORMATION.
    </p>
    <p>9. LIMITATION OF LIABILITY.</p>
    <p>
      NEITHER NEO4J, ITS AFFILIATES OR THEIR LICENSORS ARE LIABLE FOR SPECIAL, INCIDENTAL, CONSEQUENTIAL OR INDIRECT
      DAMAGES, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST SAVINGS, OR DAMAGES ARISING FROM LOSS OF USE, LOSS OF
      QUERIES, CONTENT OR DATA OR ANY ACTUAL OR ANTICIPATED DAMAGES, REGARDLESS OF THE LEGAL THEORY ON WHICH SUCH
      DAMAGES MAY BE BASED, AND EVEN IF NEO4J HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NEO4J&apos;S AND
      NEO4J&apos;S AFFILIATES&apos; AND LICENSORS&apos; AGGREGATE LIABILITY FOR ANY PERMITTED DIRECT DAMAGES UNDER THIS
      AGREEMENT WILL BE LIMITED TO THE GREATER OF (i) THE AMOUNT OF TWO HUNDRED DOLLARS; OR (ii) THE FEES THAT YOU HAVE
      ACTUALLY PAID TO NEO4J FOR THE RELEVANT SERVICES WITHIN THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE
      EVENT GIVING RISE TO THE CLAIM FOR DAMAGES. SECTION 9 ON LIMITATION OF LIABILITY AND SECTION 8 ABOVE ON WARRANTY
      DISCLAIMER FAIRLY ALLOCATE THE RISKS IN THIS AGREEMENT. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF
      THE BARGAIN BETWEEN THE PARTIES AND THAT THE LIMITATIONS SPECIFIED IN THIS SECTION 9 SHALL APPLY NOTWITHSTANDING
      ANY FAILURE OF THE ESSENTIAL PURPOSE OF THESE TERMS OR ANY LIMITED REMEDY HEREUNDER.
    </p>
    <p>10. INDEMNIFICATION.</p>
    <p>
      You will, at Neo4j&rsquo;s option, defend, indemnify, and hold harmless Neo4j, Neo4j&apos;s affiliates and
      licensors, and each of their respective employees, officers, directors, and representatives from and against any
      claims, damages, losses, liabilities, costs, and expenses (including reasonable legal fees) arising out of or
      relating to any third party claim concerning: (a) breach of this Agreement or violation of applicable law or
      regulation by You; (b) Your Data or the combination of Your Data with other applications, content or processes,
      including any claim involving alleged infringement or misappropriation of third-party rights by Your Data or by
      the use, development, design, production, advertising or marketing of Your Data; or (c) the use of the Services.
      Neo4j will promptly notify You of any claim subject to this Section, but Neo4j&apos;s failure to promptly notify
      You will only affect Your obligations to the extent that Neo4j&apos;s failure prejudices Your ability to defend
      the claim. You may: (a) use counsel of Your own choosing (subject to Neo4j&apos;s written consent) to defend
      against any claim; and (b) settle the claim as You deem appropriate, provided that You obtain Neo4j&apos;s prior
      written consent before entering into any settlement.
    </p>
    <p>11. GENERAL</p>
    <p>
      (a) Miscellaneous. Neo4j and You are independent contractors, and neither party, nor any of their respective
      affiliates, is an agent of the other for any purpose or has the authority to bind the other. This Agreement does
      not create any third party beneficiary rights in any individual or entity that is not a party to this Agreement.
      You will not assign this Agreement, or delegate or sublicense any of Your rights under this Agreement, without
      Neo4j&apos;s prior written consent. A party&apos;s failure to enforce any provision of this Agreement will not
      constitute a present or future waiver of such provision nor limit that party&apos;s right to enforce such
      provision at a later time. If any portion of this Agreement is held to be invalid or unenforceable, the remaining
      portions of this Agreement will remain in full force and effect.
    </p>
    <p>
      (b) Entire Agreement. This Agreement is the entire agreement between You and Neo4j regarding the subject matter of
      this Agreement. This Agreement supersedes all prior or contemporaneous representations, understandings,
      agreements, or communications between You and Neo4j, whether written or verbal, regarding the subject matter of
      this Agreement.
    </p>
    <p>
      (c) Notice. All communications and notices to be made or given pursuant to this Agreement must be in English.
      Neo4j may provide any notice to You under this Agreement by posting a notice in the Service or sending a message
      to the email address associated with Your account. You will be deemed to have received any email sent to the email
      address then associated with Your account when Neo4j sends the email, whether or not You actually receive the
      email. To give Neo4j notice under this Agreement, You must (1) email Neo4j at legal@neo4j.com, or (2) send Neo4j
      Your notice by certified mail, return receipt requested, to Neo4j at Neo4j, Inc., 111 East 5th Avenue, San Mateo,
      CA 94401; Attn: Legal Department.
    </p>
    <p>
      (d) Choice of Law; Jurisdiction. The laws of California govern this Agreement and any dispute of any sort that
      might arise between You and Neo4j without reference to any applicable conflict of laws rules. You consent to
      exclusive jurisdiction and venue of the federal and state courts seated in San Francisco, San Mateo and Santa
      Clara Counties, California. Either party will have injunctive or other relief in any state, federal, or national
      court of competent jurisdiction for any actual or alleged infringement of that party&apos;s or its affiliates. The
      United Nations Convention for the International Sale of Goods does not apply to this Agreement.
    </p>
    <p>
      (e) Force Majeure. Neo4j is not liable for any delay or failure to perform any obligation under this Agreement
      where the delay or failure results from any cause beyond Neo4j&apos;s reasonable control, including acts of God,
      labor disputes or other industrial disturbances, systemic electrical, telecommunications, or other utility
      failures, earthquake, storms or other elements of nature, blockages, embargoes, riots, acts or orders of
      government, acts of terrorism, or war.
    </p>
    <p>
      (f) Government Licensees. The Service is a commercial computer software program developed solely at private
      expense. As defined in U.S. Federal Acquisition Regulations (FAR) section 2.101 and U.S. Defense Federal
      Acquisition Regulations (DFAR) sections 252.227-7014(a)(1) and 252.227-7014(a)(5) (or otherwise as applicable to
      You), the Service licensed in this Agreement is deemed to be &quot;commercial items&quot; and &quot;commercial
      computer software&quot; and &quot;commercial computer software documentation.&quot; Consistent with FAR section
      12.212 and DFAR section 227.7202, (or such other similar provisions as may be applicable to You), any use,
      modification, reproduction, release, performance, display, or disclosure of such service commercial item, or
      service commercial computer software, or service commercial documentation by the U.S. government (or any agency or
      contractor thereof) shall be governed solely by the terms of this Agreement and shall be prohibited except to the
      extent expressly permitted by the terms of this Agreement.
    </p>
    <p>
      (g) Changes to the Terms. Neo4j may make changes to the terms of this Agreement from time to time. When such
      changes are made, Neo4j will make the updated Agreement available on or through the Service. Your continued use of
      the Service after such changes have been published on or through the Service shall constitute Your binding
      acceptance of such changes. If You do not agree with a modification to the Agreement, You must promptly
      discontinue use of the Service.
    </p>
    <p>
      (h) Publicity. You may state publicly that You use the Service, but You may not use Neo4j&rsquo;s trademarks
      without first obtaining written permission from Neo4j. Neo4j may include Your name on a list of Neo4j customers or
      reference You as a user of the Service.
    </p>
    <p>
      (i) Export. The Service and related software may be subject to the export and import laws and regulations of the
      United States and other countries. You agree to comply with all applicable export and import laws and regulations,
      including the Export Administration Regulations, the International Traffic in Arms Regulations, and economic
      sanctions programs implemented by the Office of Foreign Assets Control. You are solely responsible for compliance
      related to Your Data and Your use of the Service. The Service may not be exported to any US embargoed countries or
      to any person or organization on the BIS Entity List or any list of prohibited or restricted parties maintained by
      the United States, the European Union, or any other applicable government authority. You represent that You are
      not located in any embargoed country or on any such list.
    </p>
  </article>
);

const UpxTermsAndConditionsContent = ({ contentSize }: { contentSize: string }) => {
  return (
    <div className={`${contentSize} [&_a]:text-primary-text [&_a]:underline`}>
      <Content />
    </div>
  );
};

const FirstTimeMessage = () => (
  <p>
    By continuing to use this service, you agree to the{' '}
    <a href="https://neo4j.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
      Privacy Policy
    </a>{' '}
    and the Terms of Service below. Please review both and click &quot;I agree&quot; to continue.
  </p>
);

const UpdatedMessage = () => (
  <p>We&rsquo;ve updated our Terms of Service. Please review and click &quot;I agree&quot; to continue.</p>
);

const AcceptedMessage = () => <p>These are the Terms of Service you have agreed to.</p>;

const TermsTitle = ({ acceptedVersion }: { acceptedVersion?: string | null }) => {
  return isNullish(acceptedVersion) || acceptedVersion === ''
    ? 'Terms of Service and Privacy Policy'
    : 'Terms of Service';
};

const TermsSubtitle = ({ acceptedVersion }: { acceptedVersion?: string | null }) => {
  if (acceptedVersion === version()) {
    return <AcceptedMessage />;
  }
  if (Boolean(acceptedVersion) && acceptedVersion !== version()) {
    return <UpdatedMessage />;
  }

  return <FirstTimeMessage />;
};

interface MarketingOptInSectionProps {
  regionalTier: REGIONAL_GDPR_COMPLIANCE_TIER;
  marketingPreference: boolean;
  onChange: () => void;
}

const MarketingOptInSection = ({ regionalTier, marketingPreference, onChange }: MarketingOptInSectionProps) => {
  const checkboxLabel = {
    [REGIONAL_GDPR_COMPLIANCE_TIER.TIER_2]: (
      <Typography variant="body-medium">
        We would like to keep you updated on the latest news about graph database products, services & events. If
        you&nbsp;
        <span className="underline">do not</span> want to hear from us going forward, please tick this box.
      </Typography>
    ),
    [REGIONAL_GDPR_COMPLIANCE_TIER.TIER_3]: (
      <Typography variant="body-medium">
        Yes! Please keep me updated on the latest news about graph database products, services & events.
      </Typography>
    ),
  };

  return (
    <div className="flex flex-col justify-between gap-4 pt-4" data-testid="marketing-consent-section">
      <Typography variant="body-small">
        By submitting this form, you confirm you are acting for your business/organization.
      </Typography>
      {regionalTier !== REGIONAL_GDPR_COMPLIANCE_TIER.TIER_1 && (
        <Checkbox
          isChecked={marketingPreference}
          onChange={onChange}
          label={checkboxLabel[regionalTier]}
          htmlAttributes={{
            'data-testid': 'marketing-consent-checkbox',
          }}
        />
      )}
      <Typography variant="body-small">
        The information you provide will be used in accordance with&nbsp;
        <TextLink className="inline" href="https://neo4j.com/privacy-policy">
          Neo4j&apos;s Privacy Policy
        </TextLink>
        , including in respect of marketing our products, events and services. You may opt out at any time in accordance
        with our Privacy Policy
      </Typography>
    </div>
  );
};

export {
  UpxTermsAndConditionsContent,
  TermsSubtitle,
  TermsTitle,
  version as currentTsAndCsVersion,
  lastUpdated,
  MarketingOptInSection,
};
