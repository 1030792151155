import { Typography, Wizard } from '@neo4j-ndl/react';
import { AuraLogo } from '@nx/ui';
import type { ComponentProps, PropsWithChildren, ReactNode } from 'react';

import './section.css';

const steps: ComponentProps<typeof Wizard>['steps'] = [
  {
    content: 'Welcome',
  },
  {
    content: 'Project',
  },
  {
    content: 'Role',
  },
  {
    content: 'Use-case',
  },
  {
    content: 'Create',
  },
];

interface BaseSectionProps extends PropsWithChildren {
  step: number;
  title: ReactNode;
  description?: string;
}

export const BaseSection = ({ step, title, children, description }: BaseSectionProps) => {
  return (
    <div className="flex h-full w-full max-w-[480px] grow flex-col overflow-y-auto">
      <div className="mb-10 flex self-center">
        <AuraLogo />
      </div>
      <div className="mb-6 flex flex-col gap-2">
        <Typography variant="h2" className="text-center">
          {title}
        </Typography>
        <Typography variant="subheading-small" className="text-neutral-text-weak text-center">
          {description}
        </Typography>
      </div>
      <Wizard activeStepIndex={step} steps={steps} size="small" className="mb-11" />
      {children}
    </div>
  );
};
