import type { TimePeriod, TimeRange } from '@nx/state';
import type { Reducer } from 'react';
import { useCallback, useMemo, useReducer } from 'react';

import { calculateStartTime } from '../../shared/utils';
import type { ChartContext, MultiChartProps } from '../charts/types';

const getTimeRange = (timePeriod: TimePeriod, endTime = new Date()) => ({
  startTime: calculateStartTime(timePeriod, endTime),
  endTime,
});

type MetricsViewAction =
  | {
      type: 'Init';
    }
  | {
      type: 'SetTimePeriod';
      timePeriod: TimePeriod | null;
    }
  | {
      type: 'ZoomIn';
      timeRange: TimeRange | null;
    }
  | {
      type: 'SetIgnoredInstances';
      ignoredInstances: MetricsViewState['ignoredInstances'];
    }
  | {
      type: 'SetExpandedChart';
      chart: MultiChartProps | null;
    };

export type MetricsViewState = {
  timePeriod: TimePeriod;
  /** time range derived from timePeriod */
  timeRangeDerived: TimeRange;
  /** time range set explicitly via a chart zoom-in */
  timeRangeZoomedIn: TimeRange | null;
  ignoredInstances: string[];
  expandedChart: MultiChartProps | null;
};

const INITIAL_STATE: MetricsViewState = {
  timePeriod: 'LIVE',
  timeRangeDerived: getTimeRange('LIVE'),
  timeRangeZoomedIn: null,
  ignoredInstances: [],
  expandedChart: null,
};

const reducer: Reducer<MetricsViewState, MetricsViewAction> = (state, action) => {
  switch (action.type) {
    case 'Init': {
      const timeRangeDerived = getTimeRange(state.timePeriod);
      return {
        ...INITIAL_STATE,
        timePeriod: state.timePeriod,
        timeRangeDerived,
      };
    }

    case 'SetTimePeriod': {
      const timePeriod = action.timePeriod ?? state.timePeriod;
      const timeRangeDerived = getTimeRange(timePeriod);
      return {
        ...state,
        timePeriod,
        timeRangeDerived,
        timeRangeZoomedIn: null,
      };
    }

    case 'ZoomIn': {
      return { ...state, timeRangeZoomedIn: action.timeRange };
    }

    case 'SetIgnoredInstances': {
      return {
        ...state,
        ignoredInstances: action.ignoredInstances,
      };
    }

    case 'SetExpandedChart': {
      return {
        ...state,
        expandedChart: action.chart,
      };
    }
    default:
      throw new Error('Wrong reducer action');
  }
};

export const useMetricsViewState = (initState: Partial<MetricsViewState>) => {
  const [state, dispatch] = useReducer(reducer, { ...INITIAL_STATE, ...initState }, (initialState) => {
    const timeRangeDerived = initState.timeRangeDerived ?? getTimeRange(initialState.timePeriod);
    return {
      ...initialState,
      timeRangeDerived,
    };
  });

  const setIgnoredInstances = useCallback(
    (ignoredInstances: MetricsViewState['ignoredInstances']) =>
      dispatch({ type: 'SetIgnoredInstances', ignoredInstances }),
    [],
  );

  const setTimePeriod = useCallback(
    (timePeriod: MetricsViewState['timePeriod'] | undefined) =>
      dispatch({ type: 'SetTimePeriod', timePeriod: timePeriod ?? null }),
    [],
  );

  const zoomIn = useCallback(
    (timeRange: MetricsViewState['timeRangeZoomedIn']) => dispatch({ type: 'ZoomIn', timeRange }),
    [],
  );

  const refreshTimeRange = useCallback(
    () => dispatch({ type: 'SetTimePeriod', timePeriod: state.timePeriod }),
    [state.timePeriod],
  );

  const setExpandedChart = useCallback(
    (chart: MultiChartProps | null) => dispatch({ type: 'SetExpandedChart', chart }),
    [],
  );

  const chartContext = useMemo<ChartContext>(
    () => ({
      timeRange: state.timeRangeZoomedIn ?? state.timeRangeDerived,
      timePeriod: state.timePeriod,
      ignoredInstances: state.ignoredInstances,
      setIgnoredInstances,
    }),
    [setIgnoredInstances, state],
  );

  return {
    state,
    chartContext,
    dispatch,
    setTimePeriod,
    zoomIn,
    refreshTimeRange,
    setExpandedChart,
  };
};
