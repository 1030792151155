import {
  CAPTION_TYPE_CATEGORY,
  CAPTION_TYPE_LABEL,
  CAPTION_TYPE_PROPERTY,
  CAPTION_TYPE_REL,
} from '../../../state/styles/types';
import type { Caption } from '../../../state/styles/types';

export const DRAG_TYPE_CATEGORY_ACTION = 'DRAG_TYPE_CATEGORY_ACTION';
export const DRAG_TYPE_RELATIONSHIP_ACTION = 'DRAG_TYPE_RELATIONSHIP_ACTION';
export const DRAG_TYPE_CATEGORY_RULE_ACTION = 'DRAG_TYPE_CATEGORY_RULE_ACTION';
export const DRAG_TYPE_RELATIONSHIP_RULE_ACTION = 'DRAG_TYPE_RELATIONSHIP_RULE_ACTION';

const SINGLE_TYPE = 'single';

interface Property {
  exclude?: boolean;
  name?: string;
  propertyKey?: string;
  isGdsData?: boolean;
}

export interface Entry {
  properties: Property[];
  type: typeof SINGLE_TYPE | typeof CAPTION_TYPE_CATEGORY | typeof CAPTION_TYPE_REL;
  labels?: string[];
  name: string;
}

export const getSortedCaptionKeys = (entry: Entry) => {
  const properties: Caption[] | undefined = entry.properties
    .filter(
      (property) => (entry.type === CAPTION_TYPE_CATEGORY && !property.exclude) || entry.type === CAPTION_TYPE_REL,
    )
    .sort((a, b): any => {
      const p1: any = entry.type === CAPTION_TYPE_CATEGORY ? a.name : a.propertyKey;
      const p2: any = entry.type === CAPTION_TYPE_CATEGORY ? b.name : b.propertyKey;
      return p1?.localeCompare(p2);
    })
    .reduce((acc: Caption[], prop: Property): Caption[] => {
      const newProp: Caption = {
        inTooltip: false,
        styles: [],
        isCaption: false,
        key: entry.type === CAPTION_TYPE_CATEGORY ? prop.name : prop.propertyKey,
        type: CAPTION_TYPE_PROPERTY,
        isGdsData: prop.isGdsData !== undefined && prop.isGdsData,
      };
      acc = [...acc, { ...newProp }];
      return acc;
    }, []);

  const other: Caption[] | [] =
    entry.type === CAPTION_TYPE_CATEGORY
      ? (entry.labels ?? []).map((label) => ({
          inTooltip: false,
          styles: [],
          isCaption: false,
          key: label,
          type: CAPTION_TYPE_LABEL,
        }))
      : [
          {
            inTooltip: false,
            styles: [],
            isCaption: false,
            key: entry?.name,
            type: CAPTION_TYPE_REL,
          },
        ];

  return [...other, ...properties];
};

export const getDroppableType = (current: Entry, entry: Entry) => {
  if (current.type === CAPTION_TYPE_CATEGORY) return DRAG_TYPE_CATEGORY_ACTION;
  else if (current.type === CAPTION_TYPE_REL) return DRAG_TYPE_RELATIONSHIP_ACTION;
  else if (current.type === SINGLE_TYPE && entry.type === CAPTION_TYPE_CATEGORY) return DRAG_TYPE_CATEGORY_RULE_ACTION;
  else if (current.type === SINGLE_TYPE && entry.type === CAPTION_TYPE_REL) return DRAG_TYPE_RELATIONSHIP_RULE_ACTION;
};

export const getOrderedList = (list: any[], fromIndex: number, toIndex: number) => {
  if (fromIndex < toIndex) {
    list.splice(toIndex + 1, 0, list[fromIndex]);
    list.splice(fromIndex, 1);
  } else {
    list.splice(toIndex, 0, list[fromIndex]);
    list.splice(fromIndex + 1, 1);
  }

  return list;
};

export const extractIdsFromDroppableId = (droppableId: string) => {
  const firstIndex = droppableId.indexOf('-');
  const styleIndex = droppableId.substring(0, firstIndex);
  const currentId = droppableId.substring(firstIndex + 1, droppableId.length);

  return {
    currentId,
    styleIndex,
  };
};
