import { getData } from 'country-list';

export interface Country {
  key: string;
  value: string;
  label: string;
}

const completeCountryList: Country[] = getData().map((country) => ({
  key: country.code,
  value: country.code,
  label: country.name,
}));

const blockedCountryCodes = [
  // Belarus
  'BY',
  // Burundi
  'BI',
  // Congo, Democratic Republic of
  'CD',
  // Cuba
  'CU',
  // Iraq
  'IQ',
  // Iran
  'IR',
  // Lebanon
  'LB',
  // Libya
  'LY',
  // Nicaragua
  'NI',
  // North Korea
  'KP',
  // Russian Federation
  'RU',
  // Somalia
  'SO',
  // Sudan
  'SD',
  // Syria
  'SY',
  // Ukraine
  'UA',
  // Venezuela
  'VE',
  // Yemen
  'YE',
  // Zimbabwe
  'ZW',
];

const filteredCountryList = completeCountryList.filter((c) => !blockedCountryCodes.includes(c.value));

const compare = (a: Country, b: Country) => {
  const textA = a.label.toUpperCase();
  const textB = b.label.toUpperCase();

  if (textA > textB) {
    return 1;
  }
  if (textA < textB) {
    return -1;
  }
  return 0;
};

const sortedFilteredList = () => filteredCountryList.sort(compare);

export default sortedFilteredList();
