import type { EventTypeLabelProps } from '@neo4j-ndl/react';
import {
  Banner,
  DataGrid,
  DataGridComponents,
  IconButton,
  Label,
  LoadingSpinner,
  Tooltip,
  Typography,
} from '@neo4j-ndl/react';
import {
  ArrowDownTrayIconOutline,
  InformationCircleIconOutline,
  MinusSmallIconOutline,
  TrashIconOutline,
} from '@neo4j-ndl/react/icons';
import { OPS_EVENTS } from '@nx/analytics-service';
import { OpsTypes, opsApi, LEGACY_store as store, useActiveProject, useOpsContext } from '@nx/state';
import { isNonEmptyString } from '@nx/stdlib';
import { DataGridHelpers, IconButtonWithConfirmPopover, dataGridHelpersClasses } from '@nx/ui';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  type CellContext,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';

import { track } from '../../services/segment/analytics';
import { ApiErrorBanner } from '../../shared/components/api-error-banner';
import { getLogger } from '../../shared/logger';
import { standardFormatAbsoluteNumber } from '../../shared/ui-helpers';
import classes from '../logs.module.css';
import { FriendlyQueryLogFilterName, FriendlySecurityLogFilterName } from '../shared/mappers';
import { ACTIONS_COLUMN_ID } from '../shared/types';
import { capitalizeFirstLowerRest } from '../shared/utils';
import { WithUnretrievedStatusIndicator } from './common';

type TableRow = OpsTypes.Transformed.TransformedLogsDownloadsResponse[number];

/** used by default */
const NO_POLLING = 0;
/** used when at least one download status is RUNNING */
const SHORT_POLLING_INTERVAL_MS = 5_000;

const NoDataPlaceholder = () => (
  <DataGridComponents.NoDataPlaceholder>
    <h6>No downloads available</h6>
  </DataGridComponents.NoDataPlaceholder>
);

const RequestTimestampCell = (cx: CellContext<TableRow, TableRow['requestTimestampFormatted']>) => {
  const userIdentifier =
    cx.row.original.metaFileContent.userEmail ?? cx.row.original.metaFileContent.userId ?? 'Unknown user';
  const requestTimestamp = cx.getValue();
  return (
    <Tooltip type="simple">
      <Tooltip.Trigger hasButtonWrapper>
        <div className="flex w-full flex-col">
          <Typography variant="body-medium" as="span" className="">
            {requestTimestamp.friendly}
          </Typography>

          <Typography
            variant="body-small"
            as="span"
            className="text-palette-neutral-text-weak truncate"
            htmlAttributes={{ title: userIdentifier }}
          >
            {userIdentifier}
          </Typography>
        </div>
      </Tooltip.Trigger>
      <Tooltip.Content>
        <Tooltip.Body>
          Requested by {userIdentifier} at {requestTimestamp.absolute}
        </Tooltip.Body>
      </Tooltip.Content>
    </Tooltip>
  );
};

const LogTypeCell = (cx: CellContext<TableRow, TableRow['logTypeFormatted']>) => {
  return (
    <div className="flex gap-1">
      {cx.getValue().map((logType) => {
        let fill: EventTypeLabelProps['fill'] = 'outlined';
        if (logType.toUpperCase() in OpsTypes.Api.DOWNLOADS_LOG_TYPE) {
          fill = 'semi-filled';
        }
        return (
          <Label key={logType} fill={fill} color="default">
            {logType}
          </Label>
        );
      })}
    </div>
  );
};

const TimePeriodCell = (cx: CellContext<TableRow, TableRow['timePeriodFormatted']>) => {
  return (
    <Tooltip type="simple">
      <Tooltip.Trigger hasButtonWrapper>
        <span>{cx.getValue().durationFriendly}</span>
      </Tooltip.Trigger>
      <Tooltip.Content>
        <Tooltip.Body>
          <div className="flex flex-col">
            <span>{cx.getValue().interval}</span>
            <span>{cx.getValue().duration}</span>
          </div>
        </Tooltip.Body>
      </Tooltip.Content>
    </Tooltip>
  );
};

const StatusCell = (cx: CellContext<TableRow, TableRow['statusFormatted']>) => {
  // Label throws an error if rendered clean and without an icon
  return cx.row.original.isRunning ? (
    <div className="flex items-center gap-2">
      <LoadingSpinner />
      <Typography variant="label" htmlAttributes={{ color: 'muted' }}>
        {cx.getValue().text}
      </Typography>
    </div>
  ) : (
    <Label fill="clean" color={cx.getValue().color} hasIcon>
      {cx.getValue().text}
    </Label>
  );
};

const DeleteLogAction = ({ downloadInfo }: { downloadInfo: TableRow }) => {
  const activeProject = useActiveProject();
  const { selectedInstanceId } = useOpsContext();
  const [deleteLog, deleteLogRes] = opsApi.useDeleteLogsDownloadMutation({
    fixedCacheKey: downloadInfo.jobId,
  });
  const handleConfirmDelete = async () => {
    if (!isNonEmptyString(selectedInstanceId) || !isNonEmptyString(activeProject.id)) {
      getLogger().error('[DeleteLogAction] No instance or project selected');
      return;
    }
    const { data } = await deleteLog({
      tenantId: activeProject.id,
      dbmsId: selectedInstanceId,
      jobId: downloadInfo.jobId,
    });

    if (data?.deleted === true) {
      /**
       * Using `updateQueryData` alone removes the deleted item from the cache instantly, updating the list immediately.
       * Adding `invalidateTags` causes a delay as the list waits for a re-fetch.
       * Therefore, we only use `updateQueryData` for immediate updates after a successful delete.
       */
      store.dispatch(
        opsApi.util.updateQueryData(
          'getLogsDownloads',
          { tenantId: activeProject.id, dbmsId: selectedInstanceId },
          (draft) => {
            // eslint-disable-next-line no-param-reassign
            const deletedJobIndex = draft.findIndex((download) => download.jobId === downloadInfo.jobId);
            if (deletedJobIndex !== -1) {
              draft.splice(deletedJobIndex, 1);
            }
          },
        ),
      );

      track(OPS_EVENTS.LOGS_DOWNLOAD_DELETE, {
        jobId: downloadInfo.jobId,
      });
    }
  };

  return (
    <>
      {deleteLogRes.error && (
        <ApiErrorBanner.SingletonClient
          title="Error deleting log"
          description="Unable to delete the log file. Please try again or contact support if the problem persists."
          error={deleteLogRes.error}
        />
      )}

      <IconButtonWithConfirmPopover
        isClean
        ariaLabel="Delete log"
        htmlAttributes={{
          title: 'Delete log',
        }}
        isLoading={deleteLogRes.isLoading}
        className={(isPopoverOpen) =>
          classNames(
            // icon stays visible during deletion confirmation, during deletion and on row hover
            !(deleteLogRes.isLoading || isPopoverOpen) && dataGridHelpersClasses['visible-on-row-hover'],
          )
        }
        cancelIconButtonProps={{
          ariaLabel: 'Cancel delete log',
          htmlAttributes: {
            title: 'Cancel delete log',
          },
        }}
        confirmIconButtonProps={{
          ariaLabel: 'Confirm delete log',
          htmlAttributes: {
            title: 'Confirm delete log',
          },
          onClick: () => {
            void handleConfirmDelete();
          },
        }}
        isDisabled={downloadInfo.isRunning}
      >
        <TrashIconOutline />
      </IconButtonWithConfirmPopover>
    </>
  );
};

const DownloadLogAction = ({ downloadInfo }: { downloadInfo: TableRow }) => {
  const activeProject = useActiveProject();
  const { selectedInstanceId } = useOpsContext();
  const isNewDownload = !downloadInfo.metaFileContent.wasRetrieved;
  const [getLink, getLinkRes] = opsApi.useGetLogsDownloadUrlMutation();
  // awesome fixedCacheKey enables sharing of the same mutation instance across components
  const [, deleteLogRes] = opsApi.useDeleteLogsDownloadMutation({
    fixedCacheKey: downloadInfo.jobId,
  });

  const handleDownloadLog = async () => {
    if (!isNonEmptyString(selectedInstanceId) || !isNonEmptyString(activeProject.id)) {
      getLogger().error('[DownloadLogFileButton] No instance or project selected');
      return;
    }
    const res = await getLink({
      tenantId: activeProject.id,
      dbmsId: selectedInstanceId,
      jobId: downloadInfo.jobId,
    });

    if (!isNonEmptyString(res.data?.signedUrl)) {
      getLogger().error('[DownloadLogFileButton] No signed URL returned');
      return;
    }

    // Let browser use the filename from the bucket object's Content-Disposition metadata
    // header instead of manually setting it via link.download
    const link = document.createElement('a');
    link.href = res.data.signedUrl;
    link.target = '_blank';
    link.click();
    link.remove();

    track(OPS_EVENTS.LOGS_DOWNLOAD, {
      jobId: downloadInfo.jobId,
    });
  };

  return (
    <>
      {getLinkRes.error && (
        <ApiErrorBanner.SingletonClient
          title="Error downloading log"
          description="Unable to download the log file. Please try again or contact support if the problem persists."
          error={getLinkRes.error}
        />
      )}

      <WithUnretrievedStatusIndicator active={downloadInfo.isReady && !downloadInfo.metaFileContent.wasRetrieved}>
        <IconButton
          isClean
          ariaLabel={`Download log${isNewDownload ? ' (new)' : ''}`}
          onClick={() => {
            void handleDownloadLog();
          }}
          isLoading={getLinkRes.isLoading}
          isDisabled={!downloadInfo.isReady || deleteLogRes.isLoading}
          htmlAttributes={{
            title: `Download log${isNewDownload ? ' (new)' : ''}`,
          }}
        >
          <ArrowDownTrayIconOutline />
        </IconButton>
      </WithUnretrievedStatusIndicator>
    </>
  );
};

const ActionsCell = (cx: CellContext<TableRow, unknown>) => {
  return (
    <div className="flex flex-grow justify-end">
      <DeleteLogAction downloadInfo={cx.row.original} />
      <DownloadLogAction downloadInfo={cx.row.original} />
    </div>
  );
};

const FormatCell = (cx: CellContext<TableRow, TableRow['metaFileContent']['format']>) => {
  const fileFormat = cx.getValue();
  const isJson = fileFormat === OpsTypes.Api.LOG_FORMAT.JSON;
  return (
    <div>
      <Tooltip type="simple" isDisabled={isJson}>
        <Tooltip.Trigger hasButtonWrapper>
          <span>
            <Typography as="span" variant="code">
              {fileFormat}
            </Typography>
          </span>
        </Tooltip.Trigger>
        <Tooltip.Content>
          <Tooltip.Body>
            <div className="flex flex-col">
              <span>
                Include CSV headers:{' '}
                <Typography variant="code">{String(cx.row.original.metaFileContent.csvHeader)}</Typography>
              </span>
              <span>
                CSV field delimiter:{' '}
                <Typography variant="code">{cx.row.original.metaFileContent.csvDelimiter}</Typography>
              </span>
            </div>
          </Tooltip.Body>
        </Tooltip.Content>
      </Tooltip>
    </div>
  );
};

// TODO: justify end (align right) cell number value
const ExportedRowsCell = (cx: CellContext<TableRow, TableRow['exportedRows']>) => {
  return <span>{standardFormatAbsoluteNumber(cx.getValue())}</span>;
};

const queryFilterName: (keyof typeof FriendlyQueryLogFilterName)[] = [
  'statuses',
  'users',
  'drivers',
  'apps',
  'initiationTypes',
  'querySearchString',
  'errorSearchString',
  'minimumDuration',
];
const securityFilterName: (keyof typeof FriendlySecurityLogFilterName)[] = [
  'statuses',
  'drivers',
  'authenticatedUsers',
  'executingUsers',
  'messageSearchString',
];

const FiltersCell = (cx: CellContext<TableRow, TableRow['metaFileContent']>) => {
  const meta = cx.getValue();

  const [tipTrigger, tipBody] = useMemo(() => {
    let formattedFilters: { key: string; val: string }[] = [];
    switch (meta.logType) {
      case OpsTypes.Api.DOWNLOADS_LOG_TYPE.QUERY: {
        const { filters } = meta;
        formattedFilters = queryFilterName.reduce<typeof formattedFilters>((acc, key) => {
          const value = filters[key];
          if (Array.isArray(value) || typeof value === 'string') {
            acc.push({
              key: capitalizeFirstLowerRest(FriendlyQueryLogFilterName[key]),
              val: (Array.isArray(value) ? value : [value]).join(', '),
            });
          }
          if (key === 'minimumDuration' && typeof value === 'number' && value > 0) {
            acc.push({
              key: capitalizeFirstLowerRest(FriendlyQueryLogFilterName[key]),
              val: `${value} ms`,
            });
          }
          return acc;
        }, []);
        break;
      }
      case OpsTypes.Api.DOWNLOADS_LOG_TYPE.SECURITY: {
        const { filters } = meta;
        formattedFilters = securityFilterName.reduce<typeof formattedFilters>((acc, key) => {
          const value = filters[key];
          if (Array.isArray(value) || typeof value === 'string') {
            acc.push({
              key: capitalizeFirstLowerRest(FriendlySecurityLogFilterName[key]),
              val: (Array.isArray(value) ? value : [value]).join(', '),
            });
          }
          return acc;
        }, []);
        break;
      }
      default:
        break;
    }
    const hasFilters = formattedFilters.length > 0;
    const trigger = hasFilters ? (
      <InformationCircleIconOutline className={classNames('size-5', 'text-palette-neutral-text-weaker')} />
    ) : (
      <MinusSmallIconOutline className={classNames('size-5', 'text-palette-neutral-text-weakest')} />
    );
    const body = hasFilters ? (
      <div className="flex flex-col">
        {formattedFilters.map((filter) => (
          <div className="break-all" key={filter.key}>
            <span className="font-semibold">{filter.key}:</span> {filter.val}
          </div>
        ))}
      </div>
    ) : (
      <span>No filters applied</span>
    );
    return [trigger, body];
  }, [meta]);

  return (
    <div>
      <Tooltip type="simple" placement="top">
        <Tooltip.Trigger>{tipTrigger}</Tooltip.Trigger>
        <Tooltip.Content className="max-w-xs">
          <Tooltip.Body>{tipBody}</Tooltip.Body>
        </Tooltip.Content>
      </Tooltip>
    </div>
  );
};

const helper = createColumnHelper<TableRow>();

const columns = [
  helper.accessor('requestTimestampFormatted', {
    header: 'Requested',
    cell: RequestTimestampCell,
    sortingFn: (rowA, rowB) => rowA.original.requestTimestamp - rowB.original.requestTimestamp,
    size: 200,
  }),
  helper.accessor('statusFormatted', {
    header: 'Status',
    cell: StatusCell,
  }),
  helper.accessor('logTypeFormatted', {
    header: 'Type',
    cell: LogTypeCell,
    size: 200,
  }),
  helper.accessor('timePeriodFormatted', {
    id: 'timePeriod',
    header: 'Time Period',
    cell: TimePeriodCell,
  }),
  helper.accessor('exportedRows', {
    header: 'Rows',
    cell: ExportedRowsCell,
  }),
  helper.accessor('metaFileContent.format', {
    header: 'Format',
    cell: FormatCell,
  }),
  helper.accessor('metaFileContent', {
    header: 'Filters',
    cell: FiltersCell,
    size: 100,
  }),
  helper.display({
    id: 'actions',
    cell: ActionsCell,
    size: 100,
    maxSize: 100,
    enableResizing: false,
  }),
];

export const DownloadTable = () => {
  const activeProject = useActiveProject();
  const { selectedInstanceId } = useOpsContext();
  const [pollingInterval, setPollingInterval] = useState(NO_POLLING);

  /**
   * Enable `refetchOnMountOrArgChange` to ensure downloads are always refreshed
   * when the drawer is opened.
   */
  const getDownloadsRes = opsApi.useGetLogsDownloadsQuery(
    isNonEmptyString(selectedInstanceId) ? { dbmsId: selectedInstanceId, tenantId: activeProject.id } : skipToken,
    { pollingInterval, refetchOnMountOrArgChange: true },
  );

  const data = useMemo(() => {
    return getDownloadsRes.data ?? [];
  }, [getDownloadsRes.data]);

  const logsTable = useReactTable({
    columns,
    data,
    initialState: { sorting: [{ id: 'requestTimestampFormatted', desc: true }] },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    columnResizeMode: 'onChange',
    enableColumnPinning: true,
    state: {
      columnPinning: {
        right: [ACTIONS_COLUMN_ID],
      },
    },
  });

  useEffect(() => {
    if (data.some((downloadInfo) => downloadInfo.isRunning)) {
      setPollingInterval(SHORT_POLLING_INTERVAL_MS);
    } else {
      setPollingInterval(NO_POLLING);
    }
  }, [data]);

  return (
    <ApiErrorBanner.SingletonProvider>
      <ApiErrorBanner.Singleton />
      {!getDownloadsRes.error ? (
        <DataGrid
          rootProps={{
            className: `[&_.ndl-data-grid-pinned-cell-right]:!grow ${classes['clean-actions-header']}`,
          }}
          tableInstance={logsTable}
          isLoading={getDownloadsRes.isLoading}
          styling={{ headerStyle: 'clean' }}
          components={{
            BodyRow: DataGridHelpers.HoverBodyRow,
            NoDataPlaceholder,
          }}
          isKeyboardNavigable={false}
        />
      ) : (
        <Banner
          type="danger"
          description="Something went wrong while fetching downloads archive. Please try again."
          usage="inline"
        />
      )}
    </ApiErrorBanner.SingletonProvider>
  );
};
