import { Button, Typography } from '@neo4j-ndl/react';
import * as Sentry from '@sentry/react';
import type { ComponentProps } from 'react';

import ReloadImg from '../assets/reload.svg';

function getErrorMessage(error: Error) {
  const MODULE_IMPORT_ERROR = 'Failed to fetch dynamically imported module';

  if (error.message.includes(MODULE_IMPORT_ERROR)) {
    return 'A new version of the application is ready.';
  }

  return 'An unrecoverable error has occurred.';
}

export type FallbackType = ComponentProps<typeof Sentry.ErrorBoundary>['fallback'];

type Props = {
  children: JSX.Element;
  fallback?: FallbackType;
};

type FallbackComponentProps = {
  error: Error;
  eventId: string;
};

function DefaultFallbackComponent({ error, eventId }: FallbackComponentProps) {
  const isDev = import.meta.env.DEV;

  return (
    <div className="flex h-full flex-col items-center justify-center gap-16 md:flex-row">
      <img src={ReloadImg} alt="reload" draggable="false" />
      <div className="flex flex-col gap-4">
        <Typography variant="h3">Refresh required</Typography>
        <div>
          <Typography variant="body-large">{getErrorMessage(error)}</Typography>
          {isDev && (
            <div>
              <div>Error name: {error.name} </div>
              <div>Error message: {error.message} </div>
            </div>
          )}
          <Typography variant="body-small">Error event ID: {eventId}</Typography>
        </div>
        <Button className="mt-2 w-fit" type="button" color="primary" onClick={() => document.location.reload()}>
          Refresh
        </Button>
      </div>
    </div>
  );
}

export function NxErrorBoundary({ children, fallback }: Props) {
  return (
    <Sentry.ErrorBoundary
      fallback={fallback ?? (({ error, eventId }) => <DefaultFallbackComponent error={error} eventId={eventId} />)}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
