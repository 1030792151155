export enum TIER {
  FREE = 'free',
  GDS = 'gds',
  PROFESSIONAL = 'professional',
  ENTERPRISE = 'enterprise',
  AURA_DSE = 'dsenterprise',
  MTE = 'mte',
}

export enum CLOUD_PROVIDER {
  GCP = 'gcp',
  AWS = 'aws',
  AZURE = 'azure',
}
export type ActionStatus =
  | {
      enabled: true;
    }
  | { enabled: false; reason: string; message: string };

export enum PLAN_TYPE {
  SELF_SERVE = 'self_serve',
  ENTERPRISE = 'enterprise',
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const isTier = (tier: string): tier is TIER => Object.values(TIER).includes(tier as TIER);
