import { Button, LoadingSpinner } from '@neo4j-ndl/react';
import { TrialButton } from '@nx/plg';
import {
  consoleApi,
  selectConfiguration,
  useAppSelector,
  useCapability,
  useFeatureFlag,
  useInternalConfiguration,
  useToolsConfiguration,
  useUnsafeAppContext,
} from '@nx/state';
import { AuraLogo, BackButton, useCurrentScope } from '@nx/ui';
import { Header } from '@nx/upx-layout';
import { skipToken } from '@reduxjs/toolkit/query';
import { matchPath, useLocation } from 'react-router-dom';

import { ChangelogButton } from './changelog-button';
import { GuideButton } from './guide-button';
import { HelpAndLearnButton } from './help-and-learn-button';
import { selectHelpAndLearnConfig } from './help-and-learn-config';
import { PendingInviteButton } from './invite-button';
import { OrganizationNav } from './organization-nav';
import { ProfileButton } from './profile-button';

const DevModeActiveProjectFetchingSpinner = () => {
  const [isDebug] = useFeatureFlag('nx:debugger');
  const canShow = import.meta.env.DEV && isDebug;
  const { activeProjectId } = useUnsafeAppContext();
  const getProjectRes = consoleApi.useGetProjectQuery(activeProjectId ?? skipToken, { skip: !canShow });
  return canShow && getProjectRes.isFetching ? <LoadingSpinner /> : null;
};

const useShouldDisplayOrgNav = () => {
  const location = useLocation();
  const isOrgPage = matchPath('/org/:orgId/*', location.pathname) !== null;
  const isProjectPage = matchPath('/projects/:projectId/*', location.pathname) !== null;
  const toolsConfiguration = useToolsConfiguration();
  const isGuidesPage = toolsConfiguration.guides
    ? matchPath(`${toolsConfiguration.guides.route}/*`, location.pathname) !== null
    : false;
  const internalConfiguration = useInternalConfiguration();
  if (isGuidesPage) {
    return false;
  }
  return location.pathname.startsWith(internalConfiguration.basePath) || isOrgPage || isProjectPage;
};

export function ConsoleHeader({ logoRoute }: { logoRoute: string }) {
  const { userHomePath } = useUnsafeAppContext();
  const internalConfiguration = useInternalConfiguration();
  const [workspaceGuidesEnabled] = useCapability('guides:enabled');
  const currentAppScope = useCurrentScope();
  const helpAndLearnConfig = useAppSelector(selectHelpAndLearnConfig);
  const displayOrgNav = useShouldDisplayOrgNav();
  const { data: user } = consoleApi.useGetUserDetailsQuery();
  const { data: invites = [] } = consoleApi.useGetUserProjectInvitesQuery(user?.id ?? skipToken, {
    pollingInterval: 60000,
    refetchOnFocus: true,
  });
  const { pendingInvitesShown } = useAppSelector(selectConfiguration);

  return (
    <Header
      logoRoute={logoRoute}
      logoImg={<AuraLogo />}
      left={
        <>
          <div className="w-token-0" />
          {displayOrgNav ? (
            <OrganizationNav />
          ) : (
            <BackButton to={userHomePath ?? internalConfiguration.fallbackRoute} />
          )}
          <DevModeActiveProjectFetchingSpinner />
        </>
      }
      right={
        <>
          <TrialButton />
          <Button
            as="a"
            size="small"
            fill="text"
            color="primary"
            className="text-nowrap"
            htmlAttributes={{
              target: '_blank',
              href: 'https://aura.feedback.neo4j.com/',
            }}
          >
            Send feedback
          </Button>
          {invites.length > 0 && <PendingInviteButton showStatusIndicator={!pendingInvitesShown} />}
          <ChangelogButton />
          {currentAppScope !== null && <HelpAndLearnButton {...helpAndLearnConfig[currentAppScope]} />}
          <div className="w-token-2" />
          {workspaceGuidesEnabled && <GuideButton />}
          <ProfileButton />
        </>
      }
    />
  );
}
