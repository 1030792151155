import { isNotNullish, isNullish } from '@nx/stdlib';

import * as Configuration from '../../configuration/configuration-slice';
import type {
  InviteUserToProjectRequest,
  ProjectInviteDetailsResponse,
  ProjectInviteParams,
  ProjectInviteResponse,
  ProjectInvitesParams,
  UpdateProjectInviteRequest,
} from '../console.api.types';
import type { ProjectInvite, ProjectInviteDetails } from '../console.types';
import {
  transformInviteUserToProjectRequest,
  transformProjectInviteDetailsResponse,
  transformProjectInviteResponse,
  transformUpdateProjectInviteRequest,
} from '../transformers/invite';
import { type Builder, LIST_ID } from './types';

export const inviteEndpoints = (builder: Builder) => ({
  listProjectInvites: builder.query<ProjectInvite[], ProjectInvitesParams>({
    query: (params) => ({
      url: 'invites',
      params: { NamespaceId: params.projectId, ...(isNotNullish(params.status) && { Status: params.status }) },
    }),
    transformResponse: (invites: ProjectInviteResponse[]) =>
      invites.map((invite) => transformProjectInviteResponse(invite)),
    providesTags: (result, err, { projectId }) => {
      if (isNotNullish(result)) {
        return [
          ...result.map(({ id }) => ({ type: 'ProjectInvite' as const, id, projectId })),
          { type: 'ProjectInvite', id: LIST_ID, projectId },
        ];
      }
      return [{ type: 'ProjectInvite', id: LIST_ID, projectId }];
    },
  }),
  inviteUserToProject: builder.mutation<ProjectInvite, InviteUserToProjectRequest>({
    query: (request) => ({
      url: 'invites',
      method: 'POST',
      body: transformInviteUserToProjectRequest(request),
    }),
    invalidatesTags: (result, error, { projectId }) => [{ type: 'ProjectInvite', id: LIST_ID, projectId }],
  }),
  deleteProjectInvite: builder.mutation<void, ProjectInviteParams>({
    query: (params) => ({ url: `invites/${params.inviteId}`, method: 'DELETE' }),
    invalidatesTags: (response, error, { inviteId, projectId }) => [{ type: 'ProjectInvite', id: inviteId, projectId }],
  }),
  updateProjectInvite: builder.mutation<void, UpdateProjectInviteRequest>({
    query: (request) => ({
      url: `invites/${request.inviteId}`,
      method: 'PATCH',
      body: transformUpdateProjectInviteRequest(request),
    }),
    invalidatesTags: (response, error, { inviteId, projectId }) => [{ type: 'ProjectInvite', inviteId, projectId }],
  }),
  getUserProjectInvites: builder.query<ProjectInviteDetails[], string>({
    query: (userId) => `users/${userId}/invites`,
    onQueryStarted: async (_userId, { getCacheEntry, queryFulfilled, dispatch }) => {
      const { data: cached } = getCacheEntry();

      if (isNullish(cached)) {
        // pendingInvitesShown has initial state false, we don't need to do anything if cache has been cleared
        return;
      }

      await queryFulfilled.then(({ data: invites }) => {
        if (invites.filter((invite) => !cached.includes(invite)).length > 0) {
          dispatch(Configuration.setPendingInvitesShown(false));
        }
      });
    },
    transformResponse: (inviteDetails: ProjectInviteDetailsResponse[]) =>
      inviteDetails.map((invite) => transformProjectInviteDetailsResponse(invite)),
    providesTags: (result) => {
      if (isNotNullish(result)) {
        return [...result.map(({ id }) => ({ type: 'UserInvite' as const, id })), { type: 'UserInvite', id: LIST_ID }];
      }
      return [{ type: 'UserInvite', id: LIST_ID }];
    },
  }),
  acceptProjectInvite: builder.mutation<void, string>({
    query: (inviteId) => ({ url: `invites/${inviteId}/accept`, method: 'POST' }),
    invalidatesTags: (response, error, inviteId) => [
      { type: 'Project', id: LIST_ID },
      { type: 'Organization', id: LIST_ID },
      { type: 'UserInvite', id: inviteId },
      'UserDetails',
    ],
  }),
  declineProjectInvite: builder.mutation<void, string>({
    query: (inviteId) => ({ url: `invites/${inviteId}/decline`, method: 'POST' }),
    invalidatesTags: (response, error, inviteId) => [{ type: 'UserInvite', id: inviteId }],
  }),
});
