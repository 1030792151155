import type { Instance, InstanceSummary } from '@nx/state';
import { consoleApi, useActiveProject } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import { useEffect, useState } from 'react';

const isInstanceList = (instances: (Instance | undefined)[]): instances is Instance[] =>
  !instances.some((instance) => isNullish(instance));

const filterAndReplaceUndefinedInstances = (
  expected: InstanceSummary[],
  actual: (Instance | undefined)[],
  previous: Instance[],
): Instance[] => {
  // If expected instances and the actual instances are the same length without any nullish values, all succeeded
  // and we return the actual list.
  if (expected.length === actual.length && isInstanceList(actual)) {
    return actual;
  }
  const instances: Instance[] = [];
  for (const instance of expected) {
    const actualInstance = actual.find((i) => i?.id === instance.id);
    const prevInstance = previous.find((i) => i.id === instance.id);

    if (isNotNullish(actualInstance)) {
      // if latest fetched version of the instance is not nullish, use that.
      instances.push(actualInstance);
    } else if (isNotNullish(prevInstance)) {
      // Otherwise if the previous version of the instance is not nullish use the previous version of the instance.
      instances.push(prevInstance);
    }
    // Ignore all instances that we haven't been able to previously fetch
  }
  return instances;
};

export type UseInstancesProps = { shouldPoll: boolean; skip?: boolean };

export const useInstances = ({ shouldPoll, skip = false }: UseInstancesProps) => {
  const [instances, setInstances] = useState<Instance[]>([]);
  const [detailsFetchComplete, setDetailsFetchComplete] = useState(false);
  const activeProject = useActiveProject(false);
  const listInstancesRes = consoleApi.useListInstancesQuery(activeProject?.id, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    pollingInterval: shouldPoll ? 5000 : undefined,
    skip: skip || !activeProject,
  });

  const [getInstance, getInstanceRes] = consoleApi.useLazyGetInstanceQuery();
  useEffect(() => {
    void (async function fetchDetailedInstances() {
      if (listInstancesRes.data && !listInstancesRes.isFetching) {
        const promises = listInstancesRes.data.map(({ id }) => getInstance(id).unwrap());
        const detailedInstances = (await Promise.allSettled(promises))
          .filter((res) => res.status === 'fulfilled')
          .map((res) => res.value)
          .sort((a, b) => a.name.localeCompare(b.name));
        // this is to make sure we don't mutate the RTK query data
        const instanceSummaryList = [...listInstancesRes.data];
        // For some reason the getInstance request can succeed without populating the value and returning undefined.
        // For this reason we filter away the undefined values and replace them with the previous values if they exist.
        // This should remove crashes when making actions on multiple dbs i quick succession.
        const newInstances = filterAndReplaceUndefinedInstances(
          instanceSummaryList.sort((a, b) => a.name.localeCompare(b.name)),
          detailedInstances,
          instances,
        );
        setInstances(newInstances);
        setDetailsFetchComplete(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInstance, listInstancesRes.data, listInstancesRes.isFetching]);

  return {
    instances,
    isInstancesLoading: listInstancesRes.isLoading || getInstanceRes.isLoading || !detailsFetchComplete,
    error: listInstancesRes.error,
  };
};
