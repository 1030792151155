import type { ENCRYPTION_KEY_STATUS, EncryptionKey, Project } from '@nx/state';
import { CLOUD_PROVIDER } from '@nx/state';
import { format, parseISO } from 'date-fns';

import { getProductFromTier, getRegionsForAllCsps } from '../../entities/helpers';

export type CmekOptions = {
  name: string;
  region?: string;
  product: string;
  key?: string;
  created: string;
  status: ENCRYPTION_KEY_STATUS;
};

const getKey = (cmek: EncryptionKey) => {
  if (cmek.awsProperties?.kmsKeyArn !== undefined) {
    return cmek.awsProperties.kmsKeyArn;
  }
  if (cmek.azureProperties?.keyId !== undefined) {
    return cmek.azureProperties.keyId;
  }
  if (cmek.gcpProperties?.keyId !== undefined) {
    return cmek.gcpProperties.keyId;
  }
  throw new Error('No key id found');
};

export const keyHeader = (cloudProvider: CLOUD_PROVIDER | undefined) => {
  switch (cloudProvider) {
    case CLOUD_PROVIDER.AWS:
      return 'Key ARN';
    case CLOUD_PROVIDER.AZURE:
      return 'Key Identifier';
    case CLOUD_PROVIDER.GCP:
    default:
      return 'Key Resource Name';
  }
};

export const encryptionKeyToOptions = (cmeks: EncryptionKey[], project: Project): CmekOptions[] => {
  const regions = getRegionsForAllCsps(project.tierConfigs);

  const options: CmekOptions[] = cmeks.map((cmek) => {
    return {
      name: cmek.name,
      region: regions.find((r) => r.name === cmek.region)?.friendly,
      product: getProductFromTier(cmek.tier),
      key: getKey(cmek),
      created: format(parseISO(cmek.created), 'do MMM yyyy'),
      status: cmek.status,
    };
  });

  return options;
};

export const getKeyLabel = (cloudProvider: CLOUD_PROVIDER | undefined): string => {
  switch (cloudProvider) {
    case CLOUD_PROVIDER.AWS:
      return 'Encryption Key ARN';
    case CLOUD_PROVIDER.AZURE:
      return 'Key Identifier';
    case CLOUD_PROVIDER.GCP:
    default:
      return 'Encryption Key Resource Name';
  }
};
