import { Typography } from '@neo4j-ndl/react';
import type { ReactNode } from 'react';

export const InfoItem = ({
  className = '',
  label,
  value,
  children,
}: {
  className?: string;
  label: string;
  value?: ReactNode;
  children?: ReactNode;
}) => (
  <div className={`${className} flex min-w-[50px] max-w-fit flex-1 items-center gap-1`}>
    <Typography variant="body-small" className="text-palette-neutral-text-weaker">
      {label}
    </Typography>
    <Typography variant="body-small" className="overflow-hidden text-ellipsis text-nowrap">
      {value}
      {children}
    </Typography>
  </div>
);
