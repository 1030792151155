import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { omit } from 'lodash-es';

export const SETTINGS_PERSISTED_KEYS: (keyof SettingsState)[] = ['global', 'explore', 'query', 'console'];

export const EXPLORE_DEFAULT_TIMEOUT = 120;
export const EXPLORE_DEFAULT_QUERY_RESULT_LIMIT = 1000;

export type SettingsState = {
  global: {
    genaiConsent: 'consent-unknown' | 'consented' | 'consent-declined';
    crashReports?: boolean;
    usageTracking?: boolean;
    theme?: 'system' | 'dark' | 'light';
  };
  explore: {
    autoSelectNewNodes: boolean;
    compatibilityMode: boolean;
    exportAllPerspectives: boolean;
    isCaseInsensitiveEnabled: boolean;
    isPropertyPreviewEnabled: boolean;
    perspectiveAutoSyncEnabled: boolean;
    queryResultLimit: number;
    searchPhraseWriteEnabled: boolean;
    timeout: number;
    perspectivesEnabled: boolean;
  };
  import: Record<never, never>;
  query: {
    recordLimit: number;
    maxHistory: number;
    maxVizNodes: number;
    maxNewNeighbours: number;
    enableLinting: boolean;
    retainParameters: boolean;
  };
  console: {
    instanceDisplay: 'list' | 'table';
    projectDisplay: 'list' | 'table';
  };
};

export type SettingsScope = keyof SettingsState;

export type SettingsKeys = { [K in SettingsScope]: keyof SettingsState[K] };

export type SettingsKey<Scope extends SettingsScope> = SettingsKeys[Scope];

export type SettingValue<Scope extends SettingsScope, Key extends SettingsKey<Scope>> = SettingsState[Scope][Key];

export type SettingsDefaults = {
  [Scope in keyof SettingsState]: {
    [Key in SettingsKeys[Scope]]: NonNullable<SettingValue<Scope, Key>>;
  };
};

const defaults: SettingsDefaults = {
  global: {
    crashReports: true,
    usageTracking: true,
    genaiConsent: 'consent-unknown',
    theme: 'light',
  },
  explore: {
    autoSelectNewNodes: false,
    compatibilityMode: false,
    exportAllPerspectives: false,
    isCaseInsensitiveEnabled: false,
    isPropertyPreviewEnabled: true,
    perspectiveAutoSyncEnabled: true,
    queryResultLimit: EXPLORE_DEFAULT_QUERY_RESULT_LIMIT,
    searchPhraseWriteEnabled: false,
    timeout: EXPLORE_DEFAULT_TIMEOUT,
    perspectivesEnabled: false,
  },
  import: {},
  query: {
    recordLimit: 5000,
    maxHistory: 300,
    maxVizNodes: 1000,
    maxNewNeighbours: 1000,
    enableLinting: true,
    retainParameters: false,
  },
  console: {
    instanceDisplay: 'list',
    projectDisplay: 'list',
  },
};

export const initialState: SettingsState = {
  global: {
    ...defaults.global,
  },
  explore: { ...defaults.explore },
  import: {},
  query: { ...defaults.query },
  console: { ...defaults.console },
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    /**
     * Set setting value
     * @param state
     * @param action
     */
    updateSetting<Scope extends SettingsScope = SettingsScope, Key extends SettingsKey<Scope> = SettingsKey<Scope>>(
      state: SettingsState,
      action: PayloadAction<{ scope: Scope; key: Key; value: SettingValue<Scope, Key> }>,
    ) {
      state[action.payload.scope][action.payload.key] = action.payload.value;
    },
  },
});

/**
 * Action creator for updating setting value
 * @param payload
 * @returns
 */
export function update<
  Scope extends SettingsScope = SettingsScope,
  Key extends SettingsKey<Scope> = SettingsKey<Scope>,
>(payload: { scope: Scope; key: Key; value: SettingValue<Scope, Key> }) {
  // Action creator produced by `createSlice` has lax typings — it allows any
  // combination of valid scope, key and value, e.g:
  //   update({ scope: "query", key: "userTracking", value: 20 })
  // This wrapper produces typings that disallow incorrect combinations thus
  // results in strongly typed action creator.
  return settingsSlice.actions.updateSetting(payload);
}

export function makeSelectSetting<
  Scope extends keyof SettingsKeys = keyof SettingsKeys,
  Key extends SettingsKey<Scope> = SettingsKey<Scope>,
>(scope: Scope, key: Key): (state: SettingsState) => NonNullable<SettingValue<Scope, Key>> {
  return createSelector(
    (state: SettingsState) => (key in state[scope] ? state[scope][key] : undefined),
    () => defaults[scope][key],
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (value, defaultValue) => value ?? defaultValue,
  );
}

type SettingsStateV1 = SettingsState & {
  explore: SettingsState['explore'] & {
    consentTracking?: boolean;
    consentCrashReports?: boolean;
  };
};

export const migrateSettings = {
  2: (state: SettingsStateV1): SettingsState => {
    const newExploreSetting: SettingsState['explore'] = omit(state.explore, ['consentTracking', 'consentCrashReports']);
    if (state.explore.consentTracking !== undefined) {
      state.global.usageTracking = state.explore.consentTracking && state.global.usageTracking;
    }
    if (state.explore.consentCrashReports !== undefined) {
      state.global.crashReports = state.explore.consentCrashReports && state.global.crashReports;
    }
    return { ...state, explore: newExploreSetting };
  },
};

export const { updateSetting } = settingsSlice.actions;

export default settingsSlice.reducer;
