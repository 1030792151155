import type { SnapshotResponse } from '../console.api.types';
import type { Snapshot } from '../console.types';

export const transformSnapshotResponse = (response: SnapshotResponse): Snapshot => ({
  dbId: response.db_id,
  id: response.snapshot_id,
  timestamp: response.timestamp,
  profile: response.profile,
  status: response.status,
  type: response.type,
  exportable: response.exportable,
});
