import { type DeprecationLogAggregationSortInput, SORT_ORDER } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import type { ColumnSort, OnChangeFn, SortingState } from '@tanstack/react-table';
import { functionalUpdate } from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';

import { COLUMN_TO_QLA_SORT } from '../mappers';

export const DEFAULT_SORT: SortingState = [
  {
    id: 'executionCount',
    desc: true,
  },
];

const mapControlledSorting = (rules: ColumnSort[]): DeprecationLogAggregationSortInput[] => {
  return rules
    .map(({ id, desc }) => {
      const map = COLUMN_TO_QLA_SORT;
      const property = map[id];
      return isNullish(property) || typeof desc !== 'boolean'
        ? null
        : { property, sortOrder: desc ? SORT_ORDER.DESCENDING : SORT_ORDER.ASCENDING };
    })
    .filter(isNotNullish);
};

export const useDeprecationSorting = () => {
  const [controlledSorting, setControlledSorting] = useState(DEFAULT_SORT);

  const querySorting = useMemo(() => mapControlledSorting(controlledSorting), [controlledSorting]);

  const onSortingChange: OnChangeFn<SortingState> = useCallback(
    (updater) => {
      const newSorting = functionalUpdate(updater, controlledSorting);
      setControlledSorting(newSorting);
    },
    [controlledSorting],
  );

  return { controlledSorting, querySorting, onSortingChange };
};
