import { Select } from '@neo4j-ndl/react';
import { consoleApi } from '@nx/state';
import type { LinkedProject, ProjectSummary } from '@nx/state';

import type { ProjectOption } from './entities';

export interface SsoConfigFormFieldsProps {
  linkedProjects: string[];
  organizationId: string;
  setLinkedProjects: (projects: LinkedProject[]) => void;
  errorText?: string;
}

export const ProjectMultiSelect = ({
  linkedProjects,
  organizationId,
  setLinkedProjects,
  errorText,
}: SsoConfigFormFieldsProps) => {
  const { data: projects = [], isLoading } = consoleApi.useListOrganizationProjectsQuery({
    organizationId,
  });

  const projectsOptions: ProjectOption[] = projects.map((project: ProjectSummary) => ({
    label: project.name,
    key: project.id,
    value: project.id,
  }));
  const handleProjectsChange = (selectedOptions: ProjectOption[]) => {
    const selectedValues = selectedOptions.map((e: ProjectOption) => ({
      id: e.value,
      name: e.label,
    }));
    setLinkedProjects(selectedValues);
  };
  return (
    <Select
      htmlAttributes={{
        'data-testid': 'project-multi-select',
      }}
      selectProps={{
        onChange: (value) => {
          const selectedProjects = value.map((item) => item);
          handleProjectsChange(selectedProjects);
        },
        isLoading,
        value: projectsOptions.filter((option) => linkedProjects.includes(option.value)),
        options: projectsOptions,
        isMulti: true,
      }}
      size="medium"
      type="select"
      label="Projects"
      errorText={errorText}
    />
  );
};
