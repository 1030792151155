import { LoadingSpinner } from '@neo4j-ndl/react';
import { useAuth } from '@nx/state';
import { useRouterState } from '@nx/ui';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export function AuthenticatedRoute() {
  const { isAuthenticated, isLoading } = useAuth();
  const location = useLocation();
  const routerState = { ...useRouterState(), returnTo: `${location.pathname}${location.search}` };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (['/organization-lookup', '/pricing'].includes(location.pathname)) {
    return <Navigate to={location.pathname} />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={routerState} />;
  }

  return <Outlet />;
}

export function UnauthenticatedRoute() {
  return <Outlet />;
}
