import { createAsyncThunk } from '@reduxjs/toolkit';

import type { Suggestion } from '../../../modules/SearchBar/types';
import { getFullTextIndexes } from '../../perspectives/perspectiveMetadata';
import { getTemplates } from '../../perspectives/perspectives';
import TemplatedSearch from '../../search/templated/templatedSearch';
import type { RootState } from '../../types';
import { selectInputText } from '../core/search-core.selectors';
import { clearRequestsThunk } from '../requests/search-requests.thunks';
import { fetchPropertyValueSuggestionsThunk } from './property-value-suggestions/property-value-suggestions.thunk';
import { fetchSearchPhraseSuggestionsThunk } from './search-phrase-suggestions/search-phrase-suggestions.thunk';
import { NAME } from './search-suggestions.const';
import { fetchStructuredSuggestionsThunk } from './structured-suggestions/structured-suggestions.thunk';
import type {
  PathEntryVerificationResult,
  SinglePathVerificationResult,
} from './structured-suggestions/structured-suggestions.types';

export const SUGGESTIONS_TYPE = {
  SEARCH_PHRASE: 'SEARCH_PHRASE',
  PROPERTY_VALUE: 'PROPERTY_VALUE',
  STRUCTURED: 'STRUCTURED',
} as const;

type FetchSuggestionsPayload = {
  inputText?: string;
  isCaseInsensitive: boolean;
  database: string | undefined;
  pathEntryVerificationResultsCache: Map<string, PathEntryVerificationResult>;
  pathVerificationResultsCache: Map<string, SinglePathVerificationResult[]>;
  types?: (keyof typeof SUGGESTIONS_TYPE)[];
};

export const fetchSuggestionsThunk = createAsyncThunk<
  Promise<(Suggestion | Suggestion[])[]>,
  FetchSuggestionsPayload,
  { state: RootState }
>(
  `${NAME}/fetchSuggestions`,
  async (
    {
      inputText,
      isCaseInsensitive,
      database,
      pathEntryVerificationResultsCache,
      pathVerificationResultsCache,
      types = [SUGGESTIONS_TYPE.SEARCH_PHRASE, SUGGESTIONS_TYPE.PROPERTY_VALUE, SUGGESTIONS_TYPE.STRUCTURED],
    }: FetchSuggestionsPayload,
    { dispatch, getState },
  ): Promise<(Suggestion | Suggestion[])[]> => {
    await dispatch(clearRequestsThunk());
    dispatch({ type: `${NAME}/clearSuggestions` });

    const state = getState();
    const searchPhrases = getTemplates(state);
    const fullTextIndexes = getFullTextIndexes(state);
    // TODO discuss if it should be initialised once
    const templatedSearch = new TemplatedSearch(searchPhrases, isCaseInsensitive, fullTextIndexes);

    const text = inputText ?? selectInputText(state);
    const promises = types.reduce((accumulator, currentValue: keyof typeof SUGGESTIONS_TYPE) => {
      if (currentValue === SUGGESTIONS_TYPE.SEARCH_PHRASE) {
        accumulator.add(dispatch(fetchSearchPhraseSuggestionsThunk({ inputText: text, templatedSearch })).unwrap());
      }
      if (currentValue === SUGGESTIONS_TYPE.PROPERTY_VALUE) {
        accumulator.add(
          dispatch(fetchPropertyValueSuggestionsThunk({ inputText: text, isCaseInsensitive, database })).unwrap(),
        );
      }
      if (currentValue === SUGGESTIONS_TYPE.STRUCTURED) {
        accumulator.add(
          dispatch(
            fetchStructuredSuggestionsThunk({
              inputText: text,
              isCaseInsensitive,
              pathEntryVerificationResultsCache,
              pathVerificationResultsCache,
            }),
          ).unwrap(),
        );
      }
      return accumulator;
    }, new Set<Promise<any>>());
    return (await Promise.allSettled(promises))
      .filter((result) => result.status === 'fulfilled')
      .map((result) => result.value)
      .flat();
  },
);
