import { TextLink } from '@neo4j-ndl/react';
import { InformationCircleIconSolid, XMarkIconSolid } from '@neo4j-ndl/react/icons';
import { selectConfiguration, useAppSelector, useConfigurationActions } from '@nx/state';

export const CookieHint = () => {
  const { cookieHintShown } = useAppSelector(selectConfiguration);
  const { setCookieHintShown } = useConfigurationActions();

  const onClose = () => {
    setCookieHintShown(true);
  };

  return (
    <>
      {!cookieHintShown && (
        <div
          data-testid="cookie-hint"
          className="bg-primary-bg-strong text-neutral-text-inverse absolute bottom-0 z-50 h-12 w-full text-sm"
        >
          <div className="mx-4 flex h-full items-center justify-center">
            <div className="flex flex-1 items-center justify-center">
              <InformationCircleIconSolid className="mr-2 h-6 w-6" />
              <span>
                We use cookies to optimize your experience. By continuing to use the site, you agree to our use of
                cookies.
              </span>
              <TextLink
                href="https://www.cookiesandyou.com"
                className="text-neutral-text-inverse ml-1"
                htmlAttributes={{
                  target: '_blank',
                }}
              >
                Learn more
              </TextLink>
            </div>
            <XMarkIconSolid className="h-6 w-6 cursor-pointer" data-testid="close-cookie-hint" onClick={onClose} />
          </div>
        </div>
      )}
    </>
  );
};
