import type { Nullable } from '../../types/utility';
import { isEmptyArray } from '../../types/utility';
import { isVersion57orGreater, isVersion59orGreater } from '../versions/versionUtils';
import type { Constraint } from './types';

const NODE_PROPERTY_EXISTENCE = 'NODE_PROPERTY_EXISTENCE';
const RELATIONSHIP_PROPERTY_EXISTENCE = 'RELATIONSHIP_PROPERTY_EXISTENCE';
const RELATIONSHIP_KEY = 'RELATIONSHIP_KEY';
const UNIQUENESS = 'UNIQUENESS';
const RELATIONSHIP_UNIQUENESS = 'RELATIONSHIP_UNIQUENESS';
const NODE_KEY = 'NODE_KEY';
const RELATIONSHIP_PROPERTY_TYPE = 'RELATIONSHIP_PROPERTY_TYPE';
export const RELATIONSHIP_ENTITY = 'RELATIONSHIP';
export const NODE_CONSTRAINTS_TYPES = [NODE_PROPERTY_EXISTENCE, UNIQUENESS, NODE_KEY];

export const getRelationshipConstraintsTypesByVersion = (version: string) => {
  const is57xOrGreater = isVersion57orGreater(version);
  const is59xOrGreater = isVersion59orGreater(version);
  if (is59xOrGreater) {
    return [RELATIONSHIP_PROPERTY_EXISTENCE, RELATIONSHIP_UNIQUENESS, RELATIONSHIP_KEY, RELATIONSHIP_PROPERTY_TYPE];
  }
  if (is57xOrGreater) {
    return [RELATIONSHIP_PROPERTY_EXISTENCE, RELATIONSHIP_UNIQUENESS, RELATIONSHIP_KEY];
  }

  return [RELATIONSHIP_PROPERTY_EXISTENCE];
};

const isValidConstraintObject = (constraint: Partial<Constraint>) =>
  constraint.hasOwnProperty('labelsOrTypes') &&
  constraint.hasOwnProperty('type') &&
  constraint.hasOwnProperty('properties');

const getInfoFromDescriptionFor41xOrGreater = (details: string): Nullable<Partial<Constraint>> => {
  const regex = /type='(.+)', schema=(\(|(-\[)):(\w+) \{(.+)\}/g;
  const matches = regex.exec(details);
  if (isEmptyArray(matches)) {
    return null;
  }
  const type = matches[1]?.replace(/\s/g, '_');

  const parsedConstraint = {
    labelsOrTypes: matches[4] ? [matches[4]] : undefined,
    type,
    properties: matches[5]?.split(',').map((s) => s.trim()),
  };

  return isValidConstraintObject(parsedConstraint) ? parsedConstraint : null;
};

export const parseConstraint = (constraint: Partial<Constraint>) => {
  if (isValidConstraintObject(constraint)) {
    return constraint;
  }

  return getInfoFromDescriptionFor41xOrGreater(constraint?.details ?? '');
};
