import { createSelector } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import type { Relationship } from '../../types/graph';
import type { RootState } from '../types';
import nodesReducer, { getNodes, NAME as nodes } from './nodes';
import relationshipsReducer, { getVisibleRelationships, NAME as relationships } from './relationships';

export const NAME = 'graph';

// Selectors
export const getNodesForRelationship = createSelector(
  (state: RootState) => getNodes(state),
  (nodes) => (rel: Relationship) => {
    const start = Object.values(nodes).find((v) => rel.startId === v.id);
    const end = Object.values(nodes).find((v) => rel.endId === v.id);
    return { start, end };
  },
);

export const getVisibleRelationshipsForNodes = createSelector(
  (state: RootState) => getVisibleRelationships(state),
  (visibleRelationships) => (nodeIds: string[]) => {
    const relevantRelationships = visibleRelationships.filter(
      (rel) => nodeIds.includes(rel.endId) || nodeIds.includes(rel.startId),
    );

    return relevantRelationships;
  },
);

export default combineReducers({
  [nodes]: nodesReducer,
  [relationships]: relationshipsReducer,
});
