import { isNotNullish } from '@nx/stdlib';

import type {
  LinkSsoConfigIdRequest,
  ProjectMemberParams,
  ProjectMemberResponse,
  ProjectResponse,
  ProjectSummaryResponse,
  UpdateProjectMemberRolesRequest,
  UpdateProjectNameRequest,
} from '../console.api.types';
import type { Project, ProjectMember, ProjectNotification, ProjectSummary } from '../console.types';
import {
  transformLinkSsoConfigIdRequest,
  transformNotifications,
  transformProjectMemberResponse,
  transformProjectResponse,
  transformProjectSummaryResponse,
  transformUpdateProjectMemberRolesRequest,
  transformUpdateProjectNameRequest,
} from '../transformers/projects';
import { type Builder, LIST_ID } from './types';

export const projectEndpoints = (builder: Builder) => ({
  getProject: builder.query<Project, string>({
    query: (projectId) => `tenants/${projectId}`,
    transformResponse: (project: ProjectResponse) => transformProjectResponse(project),
    providesTags: (result, error, id) => [{ type: 'Project', id }],
  }),
  listProjectsByUser: builder.query<ProjectSummary[], string>({
    query: (userId) => `users/${userId}/tenants`,
    transformResponse: (projects: ProjectSummaryResponse[]) => {
      return projects.map((project) => transformProjectSummaryResponse(project));
    },
    providesTags: (result) => {
      const tags = [{ type: 'Project' as const, id: LIST_ID }];
      if (isNotNullish(result)) {
        tags.push(...result.map((project) => ({ type: 'Project' as const, id: project.id })));
      }
      return tags;
    },
  }),
  listProjectMembers: builder.query<ProjectMember[], string>({
    query: (projectId) => `tenants/${projectId}/members`,
    transformResponse: (members: ProjectMemberResponse[]) =>
      members.map((member) => transformProjectMemberResponse(member)),
    providesTags: (result, error, projectId) => [{ type: 'ProjectMember', projectId }],
  }),
  removeUserFromProject: builder.mutation<void, ProjectMemberParams>({
    query: (params) => ({ url: `tenants/${params.projectId}/members/${params.userId}`, method: 'DELETE' }),
    invalidatesTags: (res, err, { projectId, organizationId }) => [
      { type: 'ProjectMember', projectId },
      { type: 'Project', id: LIST_ID },
      'UserDetails',
      { type: 'Organization' as const, id: organizationId },
    ],
  }),
  updateProjectMemberRoles: builder.mutation<void, UpdateProjectMemberRolesRequest>({
    query: (request) => ({
      url: `tenants/${request.projectId}/members/${request.userId}`,
      method: 'PATCH',
      body: transformUpdateProjectMemberRolesRequest(request),
    }),
    invalidatesTags: (res, err, { projectId }) => [{ type: 'ProjectMember', projectId }, 'UserDetails'],
  }),
  updateProjectName: builder.mutation<void, UpdateProjectNameRequest>({
    query: (request) => ({
      url: `tenants/${request.projectId}`,
      method: 'PATCH',
      body: transformUpdateProjectNameRequest(request),
    }),
    invalidatesTags: (res, err, { projectId }) => [{ type: 'Project', id: projectId }],
  }),
  linkSsoConfigId: builder.mutation<void, LinkSsoConfigIdRequest>({
    query: (request) => ({
      url: `tenants/${request.projectId}/sso-config`,
      method: 'PUT',
      body: transformLinkSsoConfigIdRequest(request),
    }),
    invalidatesTags: (res, err, { projectId, organizationId, ssoConfigId }) => [
      { type: 'Project', id: projectId },
      { type: 'OrganizationProject', organizationId, ssoConfigId },
    ],
  }),
  unlinkSsoConfigId: builder.mutation<void, LinkSsoConfigIdRequest>({
    query: (request) => ({
      url: `tenants/${request.projectId}/sso-config`,
      method: 'DELETE',
      body: transformLinkSsoConfigIdRequest(request),
    }),
    invalidatesTags: (res, err, { projectId, organizationId, ssoConfigId }) => [
      { type: 'Project', id: projectId },
      { type: 'OrganizationProject', organizationId, ssoConfigId },
    ],
  }),
  getProjectNotifications: builder.query<ProjectNotification[], string>({
    query: (projectId) => `tenants/${projectId}`,
    transformResponse: (project: ProjectResponse) => project.notifications.map(transformNotifications),
    providesTags: (result, error, id) => [{ type: 'ProjectNotification', id }],
  }),
});
