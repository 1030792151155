import type { JTDDataType } from 'ajv/dist/jtd';

import dataSourceDefinitionValidator from './generated-validators/data-source-definition-validator-generated';

export const TEXT_CONFIGURATION_TYPE = 'text' as const;
export const USERNAME_CONFIGURATION_TYPE = 'username' as const;
export const PASSWORD_CONFIGURATION_TYPE = 'password' as const;
export const BOOLEAN_CONFIGURATION_TYPE = 'boolean' as const;
export const INTEGER_CONFIGURATION_TYPE = 'integer' as const;
const CONFIGURATION_TYPES = [
  TEXT_CONFIGURATION_TYPE,
  USERNAME_CONFIGURATION_TYPE,
  PASSWORD_CONFIGURATION_TYPE,
  BOOLEAN_CONFIGURATION_TYPE,
  INTEGER_CONFIGURATION_TYPE,
] as const;
export type ConfigurationType = (typeof CONFIGURATION_TYPES)[number];

export const AVAILABILITY_TYPES = ['available', 'internal', 'preview'] as const;
export type AvailabilityType = (typeof AVAILABILITY_TYPES)[number];

type RegexpValidationRule = {
  type: 'pattern';
  value: string;
  message: string;
};

type BaseNumberTypeRule = {
  value: number;
  message?: string;
};

type MinValidationRule = BaseNumberTypeRule & {
  type: 'minimum';
};

type ExclusiveMinValidationRule = BaseNumberTypeRule & {
  type: 'exclusiveMinimum';
};

type MaxValidationRule = BaseNumberTypeRule & {
  type: 'maximum';
};

type ExclusiveMaxValidationRule = BaseNumberTypeRule & {
  type: 'exclusiveMaximum';
};

type MinLengthValidationRule = BaseNumberTypeRule & {
  type: 'minLength';
};

type MaxLengthValidationRule = BaseNumberTypeRule & {
  type: 'maxLength';
};

type OneOfValidationRule = {
  type: 'oneOf';
  value: string[];
};

export type ValidationRule =
  | RegexpValidationRule
  | MinValidationRule
  | ExclusiveMinValidationRule
  | MaxValidationRule
  | ExclusiveMaxValidationRule
  | MinLengthValidationRule
  | MaxLengthValidationRule
  | OneOfValidationRule;

// Schema for validation
export const formFieldValueSchema = {
  discriminator: 'type',
  mapping: {
    string: {
      properties: {
        value: { type: 'string' },
      },
    },
    number: {
      properties: {
        value: { type: 'uint32' },
      },
    },
    boolean: {
      properties: {
        value: { type: 'boolean' },
      },
    },
  },
} as const;

export const fieldDefinitionSchema = {
  properties: {
    label: { type: 'string' },
    name: { type: 'string' },
    description: { type: 'string' },
    type: { enum: CONFIGURATION_TYPES },
    required: { type: 'boolean' },
  },
  optionalProperties: {
    validation: {
      elements: {
        discriminator: 'type',
        mapping: {
          pattern: {
            properties: {
              value: { type: 'string' },
              message: { type: 'string' },
            },
          },
          minimum: {
            properties: {
              value: { type: 'uint32' },
            },
            optionalProperties: {
              message: { type: 'string' },
            },
          },
          exclusiveMinimum: {
            properties: {
              value: { type: 'uint32' },
            },
            optionalProperties: {
              message: { type: 'string' },
            },
          },
          maximum: {
            properties: {
              value: { type: 'uint32' },
            },
            optionalProperties: {
              message: { type: 'string' },
            },
          },
          exclusiveMaximum: {
            properties: {
              value: { type: 'uint32' },
            },
            optionalProperties: {
              message: { type: 'string' },
            },
          },
          minLength: {
            properties: {
              value: { type: 'uint32' },
            },
            optionalProperties: {
              message: { type: 'string' },
            },
          },
          maxLength: {
            properties: {
              value: { type: 'uint32' },
            },
            optionalProperties: {
              message: { type: 'string' },
            },
          },
          oneOf: {
            properties: {
              value: { elements: { type: 'string' } },
            },
            optionalProperties: {
              message: { type: 'string' },
            },
          },
        },
      },
    },
    default: { type: 'string' },
    options: { elements: { type: 'string' } },
  },
} as const;

export type FieldDefinition = Omit<JTDDataType<typeof fieldDefinitionSchema>, 'default'> & {
  default?: FormFieldValue;
};

export const sectionDefinitionSchema = {
  properties: {
    name: { type: 'string' },
    advanced: { type: 'boolean' },
    fields: { elements: fieldDefinitionSchema },
  },
} as const;

export type SectionDefinition = Omit<JTDDataType<typeof sectionDefinitionSchema>, 'fields'> & {
  fields: FieldDefinition[];
};

export const dataSourceDefinitionSchema = {
  properties: {
    groupLabel: { type: 'string' },
    type: { type: 'string' },
    availability: { enum: AVAILABILITY_TYPES },
    sections: { elements: sectionDefinitionSchema },
  },
} as const;

export type DataSourceDefinition = Omit<JTDDataType<typeof dataSourceDefinitionSchema>, 'sections'> & {
  sections: SectionDefinition[];
};

export const validateDataSourceDefinition = (dataSourceDefinition: unknown): { valid: boolean; errors?: unknown } => {
  const valid = dataSourceDefinitionValidator(dataSourceDefinition);
  return { valid, errors: dataSourceDefinitionValidator.errors };
};

export type FormFieldValue = string | number | boolean;

type FormLabel = {
  label: string;
};

export type BaseField = {
  name: string;
  type: ConfigurationType;
  value: FormFieldValue;
};
export type NumberField = BaseField & {
  type: typeof INTEGER_CONFIGURATION_TYPE;
  value: number;
};
export type NumberFormField = NumberField & FormLabel;
export type TextField = BaseField & {
  type: typeof TEXT_CONFIGURATION_TYPE | typeof USERNAME_CONFIGURATION_TYPE | typeof PASSWORD_CONFIGURATION_TYPE;
  value: string;
};
export type TextFormField = TextField & FormLabel;
export type BooleanField = BaseField & {
  type: typeof BOOLEAN_CONFIGURATION_TYPE;
  value: boolean;
};
export type BooleanFormField = BooleanField & FormLabel;
export type Field = NumberField | TextField | BooleanField;

export type FormField = NumberFormField | TextFormField | BooleanFormField;

export type DataSourceFullConfig = {
  name: string;
  type: string;
  fields: FormField[];
};

export type DataSourceConfig = {
  name: string;
  type: string;
  fields: Field[];
};

export type ApiDataSourceDeclarations = {
  version: string;
  sources: DataSourceDefinition[];
};

export type ApiDynamicField = { type: ConfigurationType; value: FormFieldValue };

export type ApiDynamicDataSourceParamsPayload = {
  type: string;
  parameters: Record<string, ApiDynamicField>;
};
