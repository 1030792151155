import type { CypherResult } from '@neo4j/cypher-builder';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isNil } from 'lodash-es';
import type * as DriverCore from 'neo4j-driver-core';
import { v4 as generateRequestId } from 'uuid';

import type { NodeSuggestion, Suggestion } from '../../../../modules/SearchBar/types';
import { getIsDuplicatedSuggestion, updatePropertyType } from '../../../../services/bolt/bolt.utils';
import type { TransformedPerspectivePropertyKeys } from '../../../../types/perspective';
import { selectCategoryDictionaries, selectCategoryPropertyKeys } from '../../../perspectives/perspectives';
import type { RootState } from '../../../types';
import { NAME } from '../../core/search-core.const';
import {
  isActionSuggestionLockedSelector,
  isSearchPhraseLockedSelector,
  selectFullTextIndexes,
  selectLockedSuggestions,
  selectTextIndexes,
} from '../../core/search-core.selectors';
import { fetchDataThunk } from '../../core/search-core.thunks';
import { NAME as requests } from '../../requests/search-requests.const';
import { SEARCH_SUGGESTION_TIMEOUT_IN_MS } from '../search-suggestions.const';
import {
  DEFAULT_RESULTS_LIMIT,
  FULL_TEXT_SEARCH_SUGGESTION_MINIMAL_LENGTH,
  MAX_SUGGESTIONS_SIZE,
} from './property-value-suggestions.const';
import { generateFullTextQuery, mapFullTextQueryRecords } from './query/full-text';
import { generateTextQuery, mapTextQueryRecords } from './query/text';
import { getFlatSortedResults } from './query/util';

type FetchPropertyValueSuggestionsPayload = {
  inputText: string;
  isCaseInsensitive: boolean;
  database?: string;
};

type FetchedPropertyValueSuggestions = (Suggestion | Suggestion[])[];

export const fetchPropertyValueSuggestionsThunk = createAsyncThunk<
  FetchedPropertyValueSuggestions,
  FetchPropertyValueSuggestionsPayload,
  { state: RootState }
>(
  `${NAME}/fetchPropertyValueSuggestions`,
  async (
    { inputText, isCaseInsensitive, database }: FetchPropertyValueSuggestionsPayload,
    { dispatch, getState, rejectWithValue },
  ): Promise<FetchedPropertyValueSuggestions> => {
    if (inputText.length < FULL_TEXT_SEARCH_SUGGESTION_MINIMAL_LENGTH) {
      return [];
    }

    const state = getState();
    const isSearchPhraseLocked = isSearchPhraseLockedSelector(state);
    const isActionSuggestionLocked = isActionSuggestionLockedSelector(state);
    if (isSearchPhraseLocked || isActionSuggestionLocked) {
      return [];
    }

    const fullTextIndexes = selectFullTextIndexes(state);
    const textIndexes = selectTextIndexes(state);
    const hasFullTextIndexes = fullTextIndexes.length > 0;
    const hasTextIndexes = textIndexes.length > 0;
    if (!hasFullTextIndexes && !hasTextIndexes) {
      return [];
    }

    const labelPropertyKeys: TransformedPerspectivePropertyKeys['labels'] = selectCategoryPropertyKeys(state);
    const { categoryToLabelDictionary } = selectCategoryDictionaries(state);
    const lockedSuggestions = selectLockedSuggestions(state);

    const suggestions: NodeSuggestion[] = [];

    const recordLimitCallback = (mapper: any) => {
      return (newRecord: DriverCore.Record) => {
        const newMappedSuggestions = mapper({
          records: [newRecord],
          categoryToLabelDictionary,
        }).filter((suggestion: any) => !isNil(suggestion));

        const updatedSuggestion = updatePropertyType({
          labelPropertyKeys,
          suggestion: newMappedSuggestions[0],
        });

        const isDuplicate = getIsDuplicatedSuggestion({
          suggestions: suggestions,
          newSuggestion: updatedSuggestion,
        });

        if (!isDuplicate) {
          suggestions.push(updatedSuggestion);
        }

        return suggestions.length < MAX_SUGGESTIONS_SIZE;
      };
    };

    const fetchSuggestions = async (condition: boolean, query: CypherResult, mapper: any) => {
      if (condition) {
        const requestId = generateRequestId();
        dispatch({ type: `${requests}/createRequest`, payload: requestId });
        const fetchedData = dispatch(
          fetchDataThunk({
            requestId,
            cypher: query.cypher,
            parameters: query.params,
            database,
            timeout: SEARCH_SUGGESTION_TIMEOUT_IN_MS,
            recordLimit: recordLimitCallback(mapper),
          }),
        );
        await fetchedData.unwrap();
      }
    };

    try {
      const fullTextQuery = generateFullTextQuery({
        query: inputText,
        indexes: fullTextIndexes,
        limit: DEFAULT_RESULTS_LIMIT,
        isCaseInsensitive,
      });

      await fetchSuggestions(hasFullTextIndexes, fullTextQuery, mapFullTextQueryRecords);
    } catch (error) {
      rejectWithValue(error);
    }

    try {
      const textIndexQuery = generateTextQuery({
        query: inputText,
        indexes: textIndexes,
        labelPropertyKeys,
        limit: DEFAULT_RESULTS_LIMIT,
        isCaseInsensitive,
      });

      await fetchSuggestions(
        hasTextIndexes && suggestions.length < DEFAULT_RESULTS_LIMIT,
        textIndexQuery,
        mapTextQueryRecords,
      );

      const suggestionHead = lockedSuggestions.at(-1);
      return getFlatSortedResults(suggestions, suggestionHead, isCaseInsensitive);
    } catch (error) {
      rejectWithValue(error);
    }
    return [];
  },
);
