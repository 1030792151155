import type { QueryLog, QueryLogAggregation, QueryLogFilterInput } from '../api-types/logs';

export type TimeRangeQueryLog = Omit<QueryLog, 'endTime'> & {
  endTime?: Date;
};

export type TimeRangeQueryLogAggregation = Omit<QueryLogAggregation, 'from' | 'to'> & {
  from?: Date;
  to?: Date;
};

// used around dropdowns & context menus
export type QueryLogFilterableFields = keyof Pick<
  QueryLogFilterInput,
  'databases' | 'users' | 'statuses' | 'apps' | 'initiationTypes' | 'drivers' | 'queryLanguages' | 'gqlStatuses'
>;

// easier to work with "Completed" and type string vs QueryLogStatus.Completed (COMPLETED) and type QueryLogStatus
export const STATUS_MAP = {
  Completed: 'COMPLETED',
  Failed: 'FAILED',
  // Unfinished: QueryLogStatus.Unfinished,
};
export const FORMATTED_AVAILABLE_STATUSES = Object.keys(STATUS_MAP).sort();

type NormalizedFilterInput = Omit<QueryLogFilterInput, 'statuses'> & {
  statuses?: typeof FORMATTED_AVAILABLE_STATUSES;
};

// what comes from dbms, always an array
export type AvailableDbmsFilters = {
  [key in QueryLogFilterableFields]: Exclude<NormalizedFilterInput[key], null | undefined>;
};

export type LogsTimeRange = {
  endTime: Date;
  startTime?: Date;
};

// what the user selects
export type SelectedDbmsFilters = {
  [key in QueryLogFilterableFields]: AvailableDbmsFilters[key];
};

// source of truth
export type RawFilterSelection = {
  dbmsFilters: SelectedDbmsFilters;
  timeRange: LogsTimeRange;
  query?: QueryLogFilterInput['query'];
  querySearchString?: QueryLogFilterInput['querySearchString'];
  errorSearchString?: QueryLogFilterInput['errorSearchString'];
  minDuration: number;
};
