export const EQUALS = 'equals';
export const NOT_EQUALS = 'not-equals';
export const BEFORE = 'before';
export const AFTER = 'after';
export const BETWEEN = 'between';
export const NOT_BETWEEN = 'not-between';
export const LESS_THAN = 'less-than';
export const LESS_THAN_OR_EQUAL_TO = 'less-than-or-equal-to';
export const GREATER_THAN = 'greater-than';
export const GREATER_THAN_OR_EQUAL_TO = 'greater-than-or-equal-to';
export const RANGE_CONDITIONS = [BETWEEN, NOT_BETWEEN];
