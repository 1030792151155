import { isNullish } from '@nx/stdlib';

export const measureTextWidth = (text: string, fontSize: number, fontFamily: string) => {
  const canvasElement = document.createElement('canvas');

  const context = canvasElement.getContext('2d');
  if (isNullish(context)) {
    return 0;
  }
  context.font = `${fontSize}px ${fontFamily}`;
  const { width } = context.measureText(text);
  return width;
};

export const truncateSampleValue = (text: string | undefined): string => {
  const subText = text?.substring(0, 100) ?? '';
  if (text !== undefined && text.length > 100) {
    return `${subText}...`;
  }
  return subText;
};
