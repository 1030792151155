import { AURA_CONSOLE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { trackEvent } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { SigninRedirectArgs, User, UserManagerSettings } from 'oidc-client-ts';
import { ErrorResponse, UserManager, WebStorageStateStore } from 'oidc-client-ts';

import { Persist } from '../persist/persist';
import type { RootState } from '../store';
import * as Configuration from './configuration/configuration-slice';
import { createGenaiClient, updateGenaiApiStatus } from './genai-api-slice';

export const ACTIVE_ORG_KEY = 'org.active';

export enum SESSION_STATUS {
  Authenticated = 'Authenticated',
  Failed = 'Failed',
  Inactive = 'Inactive',
  Pending = 'Pending',
  Unauthenticated = 'Unauthenticated',
}

/** https://authts.github.io/oidc-client-ts/index.html#md:custom-state-in-user-object */
type CustomState = { returnTo?: string } | null;

/** Includes only necessary User fields as storing entire user throws `A non-serializable value was detected..` */
export type SerializedUser = Pick<User, 'profile'> & { state: CustomState | null };

export type SessionState =
  | { type: SESSION_STATUS.Failed; error: SerializedError }
  | { type: SESSION_STATUS.Inactive }
  | { type: SESSION_STATUS.Pending }
  | { type: SESSION_STATUS.Unauthenticated }
  | {
      type: SESSION_STATUS.Authenticated;
      userId: string;
      user: SerializedUser;
    };

let authClient: UserManager | undefined;

const isUnauthenticatedError = (error: unknown) =>
  error !== null && typeof error === 'object' && 'error' in error && error.error === 'login_required';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const serializeUser = (user: User): SerializedUser => ({ profile: user.profile, state: user.state as CustomState });

/**
 * This function is used to get the user-scoped token as a fallback for when we haven't received the
 * org-scoped token yet.
 */
async function getBaseAccessToken() {
  if (isNotNullish(authClient)) {
    const baseAuthClient = new UserManager({
      ...authClient.settings,
      userStore: new WebStorageStateStore({
        prefix: 'workspace.',
        store: window.localStorage,
      }),
      stateStore: new WebStorageStateStore({
        prefix: 'workspace.',
        store: window.localStorage,
      }),
    });

    const user = await baseAuthClient.getUser();
    return user?.access_token;
  }

  return undefined;
}

const hasTokenExpired = (user: User) => {
  const isTokenAlive = (user.expires_in ?? 0) > 0;
  return !isTokenAlive;
};

export async function login(orgId?: string, args?: SigninRedirectArgs, forceLogin = false) {
  const prefix = isNotNullish(orgId) ? `org.${orgId}.` : 'workspace.';
  localStorage.setItem(ACTIVE_ORG_KEY, prefix);
  if (isNotNullish(authClient)) {
    authClient = new UserManager({
      ...authClient.settings,
      userStore: new WebStorageStateStore({
        prefix,
        store: window.localStorage,
      }),
      stateStore: new WebStorageStateStore({
        prefix,
        store: window.localStorage,
      }),
    });
  }

  const user = await authClient?.getUser();
  if (isNotNullish(user) && !hasTokenExpired(user) && !forceLogin) {
    return;
  }

  void authClient?.signinRedirect({
    ...args,
    extraQueryParams: { ...authClient.settings.extraQueryParams, ...args?.extraQueryParams },
  });
}

export const loginRedirect = async () => {
  await authClient?.signinRedirect();
};

async function getOrgAccessToken() {
  const currentUser = await authClient?.getUser();

  if (isNotNullish(currentUser) && hasTokenExpired(currentUser)) {
    try {
      const user = await authClient?.signinSilent();
      return user?.access_token;
    } catch (error) {
      if (error instanceof ErrorResponse) {
        if (error.error === 'login_required') {
          // No session — no token
          return undefined;
        }
      }

      throw error;
    }
  }

  return currentUser?.access_token;
}

export async function getAccessToken() {
  const orgToken = await getOrgAccessToken();

  if (orgToken !== undefined) {
    return orgToken;
  }

  // Get the user-scoped token
  const baseToken = await getBaseAccessToken();
  return baseToken;
}

export async function getIdToken() {
  const user = await authClient?.getUser();
  return user?.id_token;
}

/** Clears the trailing org keys that is not cleared through the oidc-client */
const clearOrgBearerTokens = () => {
  const keys = Object.keys(localStorage);
  for (const key of keys) {
    const value = localStorage.getItem(key);
    if (isNotNullish(value) && key.startsWith('org.')) {
      localStorage.removeItem(key);
    }
  }
  localStorage.removeItem(ACTIVE_ORG_KEY);
};

/** Clears the workspace user key in the case that an org token is the current active token */
const clearBearerToken = () => {
  const keys = Object.keys(localStorage);
  for (const key of keys) {
    const value = localStorage.getItem(key);
    if (isNotNullish(value) && key.startsWith('workspace.user')) {
      localStorage.removeItem(key);
    }
  }
};

const CREDENTIALS_STORAGE_FIELD = Persist.createKey(APP_SCOPE.framework, 'currentConnection');
const ACTIVE_DATABASE_STORAGE_FIELD = Persist.createKey(APP_SCOPE.framework, 'activeDatabase');

const clearConnectionDetails = () => {
  sessionStorage.removeItem(CREDENTIALS_STORAGE_FIELD);
  sessionStorage.removeItem(ACTIVE_DATABASE_STORAGE_FIELD);
};

export const getTokenForConnectionAndEmail = async (connection: string, toEmail: string) => {
  if (isNotNullish(authClient)) {
    const client = new UserManager(authClient.settings);

    await client.signinPopup({
      max_age: 0,
      scope: 'openid email profile',
      login_hint: toEmail,
      extraQueryParams: {
        connection,
      },
    });
    const user = await client.signinSilent();
    return user?.access_token;
  }
  return undefined;
};

export type AuthAction = 'sign-in' | 'sign-in-callback' | 'sign-out-callback';

export const initialize = createAsyncThunk<
  SerializedUser | undefined,
  { config: UserManagerSettings; action: AuthAction },
  { state: Pick<RootState, 'session'> }
>(
  'session/initialize',
  async ({ config, action }, thunkApi) => {
    authClient = new UserManager(config);
    thunkApi.dispatch(updateGenaiApiStatus({ status: 'pending' }));

    authClient.events.addAccessTokenExpired(() => {
      clearConnectionDetails();
    });

    try {
      switch (action) {
        case 'sign-in': {
          const loggedInUser = await authClient.getUser();
          const accessToken = await getAccessToken();

          if (isNotNullish(loggedInUser) && !hasTokenExpired(loggedInUser) && isNotNullish(accessToken)) {
            await thunkApi.dispatch(createGenaiClient({ accessToken }));
            return serializeUser(loggedInUser);
          }

          const user = await authClient.signinSilent();
          if (user && isNotNullish(accessToken)) {
            await thunkApi.dispatch(createGenaiClient({ accessToken }));
            return serializeUser(user);
          }
          return undefined;
        }

        case 'sign-in-callback': {
          const user = await authClient.signinCallback();
          const accessToken = await getAccessToken();

          if (user && isNotNullish(accessToken)) {
            thunkApi.dispatch(
              trackEvent({
                event: AURA_CONSOLE_EVENTS.LOGIN,
                properties: { email: user.profile.email },
                scope: APP_SCOPE.aura,
              }),
            );

            thunkApi.dispatch(Configuration.setInitialPendingInvitesShown(false));
            await thunkApi.dispatch(createGenaiClient({ accessToken }));
            return serializeUser(user);
          }
          return undefined;
        }

        case 'sign-out-callback': {
          thunkApi.dispatch(updateGenaiApiStatus({ status: 'login-required' }));
          await authClient.signoutCallback();
          clearOrgBearerTokens();
          clearBearerToken();
          clearConnectionDetails();
          return undefined;
        }

        default:
          throw new Error('Invalid method');
      }
    } catch (error) {
      if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
        // eslint-disable-next-line no-console
        console.error('Authentication error:', error);
      }

      if (isUnauthenticatedError(error)) {
        thunkApi.dispatch(updateGenaiApiStatus({ status: 'login-required' }));
        return undefined;
      }
      thunkApi.dispatch(updateGenaiApiStatus({ status: 'error', errorMessage: String(error) }));

      throw error;
    }
  },
  {
    condition(_arg, { getState }) {
      const state = getState();
      return state.session.type === SESSION_STATUS.Inactive || state.session.type === SESSION_STATUS.Failed;
    },
  },
);

// Redux Toolkit infers type incorrectly from `const`
export function getInitialState(): SessionState {
  return { type: SESSION_STATUS.Inactive };
}

export function logout() {
  void authClient?.signoutRedirect();
}

export const slice = createSlice({
  name: 'session',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initialize.pending, () => {
        return { type: SESSION_STATUS.Pending };
      })
      .addCase(initialize.fulfilled, (state, action) => {
        if (state.type !== SESSION_STATUS.Pending) {
          return state;
        }
        if (action.payload && typeof action.payload === 'object') {
          return {
            type: SESSION_STATUS.Authenticated,
            userId: action.payload.profile.sub,
            user: action.payload,
          };
        }

        return { type: SESSION_STATUS.Unauthenticated };
      })
      .addCase(initialize.rejected, (_state, action) => {
        return {
          type: SESSION_STATUS.Failed,
          error: action.error,
        };
      });
  },
});

export const { reducer } = slice;
