import { APP_SCOPE } from '@nx/constants';
import type * as ImportShared from '@nx/import-shared';
import { createLogger } from '@nx/logger';
import { isNullish } from '@nx/stdlib';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

import type { DataFileMetadata } from '../file-loader/types';
import type { DataModelState } from '../state/reducers/data-model';
import type { ImportGraphDataModel } from '../types';
import { deserializeShareableState, serializeShareableState } from './serialization';

const logger = createLogger(APP_SCOPE.import);

const DATAMODEL_FILE_NAME = 'neo4j_importer_model.json';
const OSX_ZIP_FOLDER_PATH = '__MACOSX/';
const CSV_REG_EXP = /\.(csv|tsv)$/;
const ZIP_SIGNATURE = '504B0304';
enum FILE_MIME {
  APPLICATION_ZIP = 'application/zip',
  UNKNOWN = 'unknown',
}

export const openFileDialog = (accept: string, multiple: boolean, callback: (event: Event) => unknown): void => {
  const inputElement = document.createElement('input');
  inputElement.type = 'file';
  inputElement.accept = accept;
  inputElement.multiple = multiple;
  inputElement.addEventListener('change', callback);
  inputElement.dispatchEvent(new MouseEvent('click'));
};

const getMimeType = (signature: string) => {
  // https://en.wikipedia.org/wiki/List_of_file_signatures
  switch (signature) {
    case ZIP_SIGNATURE:
      return FILE_MIME.APPLICATION_ZIP;
    default:
      return FILE_MIME.UNKNOWN;
  }
};

const getMimeTypeOfFile = (file: File) => {
  return new Promise((resolve) => {
    const fileReader = new FileReader();

    fileReader.onloadend = (evt) => {
      if (evt.target?.readyState === FileReader.DONE) {
        const { result } = evt.target;
        if (!(result instanceof ArrayBuffer)) {
          throw Error('Could not process file - ArrayBuffer expected');
        }

        const uint = new Uint8Array(result);
        const bytes: string[] = [];

        uint.forEach((byte) => {
          const byteString = byte.toString(16);
          bytes.push(byteString.length === 1 ? `0${byteString}` : byteString);
        });

        const hex = bytes.join('').toUpperCase();
        const mimeType = getMimeType(hex);

        resolve(mimeType);
      }
    };

    fileReader.readAsArrayBuffer(file.slice(0, 4));
  });
};

export const importModelFile = (
  file: File,
  successCallback: (model: ImportGraphDataModel) => void,
  errorCallback: (error: unknown) => void,
): void => {
  const fileReader = new FileReader();
  fileReader.onload = (event) => {
    const fileContent = event.target?.result;
    try {
      if (!(typeof fileContent === 'string')) {
        throw Error('Could not process file - string expected');
      }
      const model = deserializeShareableState(fileContent);
      successCallback(model);
    } catch (error) {
      errorCallback(error);
    }
  };
  fileReader.onerror = (error) => {
    errorCallback(error);
  };
  fileReader.readAsText(file);
};

export const exportModelFile = (state: {
  visualisation: ImportShared.VisualisationState;
  dataModel: DataModelState;
}): void => {
  const serializedModel = serializeShareableState(state);
  const blob = new Blob([serializedModel], { type: 'text/plain;charset=utf-8' });
  const date = new Date().toISOString().split('T')[0] ?? '';
  saveAs(blob, `neo4j_importer_model_${date}.json`);
};

const stripPath = (filename: string): string => {
  const lastSlashIndex = filename.lastIndexOf('/');
  if (lastSlashIndex !== -1) {
    return filename.substring(lastSlashIndex + 1);
  }
  return filename;
};

type JSZipObjectMetadata = JSZip.JSZipObject & {
  _data: { compressedContent: Uint8Array[]; compressedSize: number; crc32: number; uncompressedSize: number };
};

export const getZipDataFiles = async (
  zipFile: File,
): Promise<{
  dataFiles: Record<string, DataFileMetadata>;
  files: Record<string, JSZip.JSZipObject>;
  model: ImportGraphDataModel | null;
}> => {
  const mimeType = await getMimeTypeOfFile(zipFile);
  if (mimeType === FILE_MIME.APPLICATION_ZIP) {
    const jsZip = await JSZip.loadAsync(zipFile);
    const { files } = jsZip;
    const fileNames = Object.keys(files);
    const csvFileNames = fileNames.filter(
      (fileName) => CSV_REG_EXP.test(fileName) && !fileName.startsWith(OSX_ZIP_FOLDER_PATH),
    );
    const modelFileNames = fileNames.filter(
      (fileName) => fileName.endsWith(DATAMODEL_FILE_NAME) && !fileName.startsWith(OSX_ZIP_FOLDER_PATH),
    );
    const dataFiles: Record<string, DataFileMetadata> = {};
    for (const fileName of csvFileNames) {
      dataFiles[stripPath(fileName)] = {
        fileName: fileName,
        // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/consistent-type-assertions
        size: (files[fileName] as JSZipObjectMetadata)._data.uncompressedSize,
      };
    }
    const modelFileName = modelFileNames.length > 0 ? modelFileNames[modelFileNames.length - 1] : undefined;
    const modelFile = !isNullish(modelFileName) ? files[modelFileName] : undefined;
    if (!isNullish(modelFile)) {
      return modelFile.async('string').then((modelFileContent) => {
        return Promise.resolve({ dataFiles, files, model: deserializeShareableState(modelFileContent) });
      });
    }
    return Promise.resolve({ dataFiles, files, model: null });
  }
  return Promise.reject(new Error('Invalid MIME Type'));
};

const addLocalFiles = (files: Record<string, File>, zip: JSZip) => {
  Object.entries(files).forEach(([fileName, file]) => zip.file(fileName, file));
};

const extractFilesFromZip = async (
  zipFile: File | null,
  zipDataFiles: Record<string, DataFileMetadata>,
  zip: JSZip,
) => {
  const zipFilenames = Object.keys(zipDataFiles);
  const filePromises: Promise<void>[] = [];

  if (zipFilenames.length > 0 && !isNullish(zipFile)) {
    const importedZip = await JSZip.loadAsync(zipFile);
    zipFilenames.forEach((zipFilename) => {
      const savedFilename = zipFilename;
      const zipDataFile = zipDataFiles[savedFilename];
      const file = isNullish(zipDataFile) ? undefined : importedZip.files[zipDataFile.fileName];
      if (!isNullish(file)) {
        const filePromise = file
          .async('uint8array')
          .then((data) => {
            zip.file(savedFilename, data);
          })
          .catch((error) => {
            logger.error('zip error, ', savedFilename, error);
            throw Error(`zip error for file ${savedFilename}`);
          });
        filePromises.push(filePromise);
      }
    });
  }

  return Promise.allSettled(filePromises);
};

const generateZipFile = (
  fileName: string,
  zip: JSZip,
  {
    onProgress,
    onComplete,
    onError,
  }: {
    onProgress: (metadata: { percent: number }) => void;
    onComplete: () => void;
    onError: (error: Error) => void;
  },
) => {
  zip
    .generateAsync(
      {
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: { level: 9 },
        streamFiles: true,
      },
      onProgress,
    )
    .then((blob) => {
      onComplete();
      const date = new Date().toISOString().split('T')[0] ?? '';
      saveAs(blob, `${fileName}-${date}.zip`);
    })
    .catch((error: Error) => {
      onError(error);
    });
};

export const exportZipFile = async (
  state: { visualisation: ImportShared.VisualisationState; dataModel: DataModelState },
  files: Record<string, File>,
  importedZipFile: File | null,
  zipDataFiles: Record<string, DataFileMetadata>,
  {
    onProgress,
    onComplete,
    onError,
  }: {
    onProgress: (metadata: { percent: number }) => void;
    onComplete: () => void;
    onError: (error: Error) => void;
  },
) => {
  const zip = new JSZip();

  addLocalFiles(files, zip);

  zip.file(DATAMODEL_FILE_NAME, serializeShareableState(state));

  try {
    const results = await extractFilesFromZip(importedZipFile, zipDataFiles, zip);
    const rejects = results.filter((result) => result.status === 'rejected');
    if (rejects.length > 0) {
      const rejectedFilesStr = rejects.map((reject: PromiseRejectedResult) => String(reject.reason)).join(', \n');
      onError(new Error(`zipfiles error: \n${rejectedFilesStr}`));
      return;
    }
    generateZipFile('data-importer', zip, { onProgress, onComplete, onError });
  } catch (error) {
    logger.error('failed to load already imported zip', error);
    onError(new Error('failed to load already imported zip'));
  }
};

export const fetchZipFromUrl = async (zipUrl: string): Promise<File> => {
  const response = await fetch(zipUrl);
  if (response.status === 200 || response.status === 0) {
    const blob = await response.blob();
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return blob as File;
  }
  throw new Error(response.statusText);
};

export const exportCypherScriptFile = (serializedScript: string) => {
  const blob = new Blob([serializedScript], { type: 'text/plain;charset=utf-8' });
  const date = new Date().toISOString().split('T')[0] ?? '';
  saveAs(blob, `neo4j_importer_cypher_script_${date}.cypher`);
};

export const exportCypherScriptAndFilesZip = async (
  cypherScript: string,
  files: Record<string, File>,
  importedZipFile: File | null,
  zipDataFiles: Record<string, DataFileMetadata>,
  {
    onProgress,
    onComplete,
    onError,
  }: {
    onProgress: (metadata: { percent: number }) => void;
    onComplete: () => void;
    onError: (error: Error) => void;
  },
) => {
  const zip = new JSZip();

  zip.file('neo4j_importer_cypher_script.cypher', cypherScript);

  addLocalFiles(files, zip);

  await extractFilesFromZip(importedZipFile, zipDataFiles, zip);

  generateZipFile('neo4j-importer-cypher', zip, { onProgress, onComplete, onError });
};
