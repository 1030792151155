import type { MarketplaceRegisterPayload, MarketplaceRegisterResponse } from '../console.api.types';
import { transformMarketplaceRegisterPayload } from '../transformers/marketplace';
import { type Builder } from './types';

export const marketplaceEndpoints = (builder: Builder) => ({
  registerMarketplaceOrder: builder.mutation<string, MarketplaceRegisterPayload>({
    query: (payload) => ({
      url: 'marketplace/register',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: transformMarketplaceRegisterPayload(payload),
    }),
    transformResponse: (response: MarketplaceRegisterResponse) => response.namespace_id,
  }),
});
