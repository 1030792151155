import { isNullish } from '@nx/stdlib';

import type {
  CountsResponse,
  CreateAuraInstanceResponse,
  CreateInstanceRequest,
  CreateInstanceResponse,
  DesiredSettingsResponse,
  GetSignedObjectUrlResponse,
  InstanceResponse,
  InstanceSummaryResponse,
  LimitsResponse,
  OverwriteInstanceRequest,
  RestoreFromSnapshotRequest,
  SettingsResponse,
  SourceSnapshotRequest,
  TransformedCreateInstanceBody,
  TransformedOverwriteInstanceBody,
  TransformedRestoreFromSnapshotBody,
  TransformedUpdateCDCModeBody,
  TransformedUpdateInstanceConfigBody,
  TransformedUpdateInstanceNameBody,
  TransformedUpdateSecondariesBody,
  TransformedUpgradeInstanceConfigBody,
  UpdateCDCModeRequest,
  UpdateInstanceConfigRequest,
  UpdateInstanceNameRequest,
  UpdateSecondariesRequest,
  UpgradeInstanceConfigRequest,
} from '../console.api.types';
import { TIER } from '../console.types';
import type { CreatedInstance, Instance, InstanceSummary, SignedObjectUrl } from '../console.types';

const transformSettingsResponse = (response?: SettingsResponse) => {
  return {
    memory: response?.memory,
    storage: response?.storage,
    cpu: response?.cpu,
    secondariesCount: response?.secondaries_count,
    cdcEnrichmentMode: response?.cdc_enrichment_mode,
    version: response?.version,
  };
};

const transformDesiredSettingsResponse = (response: DesiredSettingsResponse) => {
  return {
    ...transformSettingsResponse(response),
    memory: response.memory,
    version: response.version,
  };
};

const transformCountsResponse = (response?: CountsResponse) => {
  if (isNullish(response)) {
    return undefined;
  }
  return {
    currentNodes: response.current_nodes,
    currentRelationships: response.current_relationships,
  };
};

const transformLimitsResponse = (response?: LimitsResponse) => {
  if (isNullish(response)) {
    return undefined;
  }
  return {
    maxNodes: response.max_nodes,
    maxRelationships: response.max_relationships,
  };
};

export const transformInstanceResponse = (response: InstanceResponse): Instance => ({
  id: response.db_id,
  projectId: response.namespace_id,
  name: response.name,
  tier: response.tier,
  isBeingCloned: response.is_being_cloned,
  instanceStatus: response.database_status,
  cloudProvider: response.cloud_provider,
  monitoringStatus: response.monitoring_status,
  region: response.region,
  desiredSettings: transformDesiredSettingsResponse(response.desired_settings),
  appliedSettings: transformSettingsResponse(response.applied_settings),
  capabilities: response.capabilities,
  connectionUri: response.connection_uri,
  boltUrl: response.bolt_url,
  counts: transformCountsResponse(response.counts),
  limits: transformLimitsResponse(response.limits),
  privateBoltUrl: response.private_bolt_url,
  publicAccessEnabled: response.public_access_enabled,
  seamlessConnectionEnabled: response.seamless_connection_enabled,
  priority: response.priority,
  availableActions: response.available_actions,
  trialEndTime: response.pro_trial_end_time,
  encryptionKeyRef: response.encryption_key_ref,
  resizeThreshold: response.resize_threshold_gibibytes,
  tierDisplayName: response.tier_display_name,
  vectorOptimized: response.vector_optimized,
  gdsPlugin: response.gds_plugin,
  queryApiUrl: response.query_api_url,
});

export const transformInstanceSummaryResponse = (response: InstanceSummaryResponse): InstanceSummary => ({
  id: response.db_id,
  name: response.name,
  cloudProvider: response.cloud_provider,
  projectId: response.tenant_id,
});

export const transformCreateInstanceResponse = (response: CreateInstanceResponse): CreatedInstance => ({
  id: response.db_id,
  password: response.password,
  boltUrl: response.bolt_url,
  name: response.name,
  projectId: response.namespace,
  username: response.username,
});

export const transformCreateAuraInstanceResponse = (response: CreateAuraInstanceResponse): CreatedInstance => ({
  id: response.instance_id,
  name: response.instance_name,
  projectId: response.namespace_id,
  password: response.default_database_password,
  boltUrl: response.default_database_bolt_url,
  username: response.default_database_username,
});

const transformSourceSnapshotRequest = (request: SourceSnapshotRequest) => ({
  DbId: request.dbId,
  SnapshotId: request.snapshotId,
});

export const transformCreateInstanceRequest = (request: CreateInstanceRequest): TransformedCreateInstanceBody => ({
  Tier: request.tier,
  Name: request.name,
  Region: request.region,
  Version: request.version,
  Namespace: request.projectId,
  CloudProvider: request.cloudProvider,
  Memory: request.memory,
  SourceSnapshot:
    request.tier !== TIER.FREE && request.sourceSnapshot
      ? transformSourceSnapshotRequest(request.sourceSnapshot)
      : undefined,
  EncryptionKeyRef:
    request.tier === TIER.ENTERPRISE || request.tier === TIER.AURA_DSE ? request.encryptionKeyRef : undefined,
  IsTrial: TIER.GDS === request.tier || TIER.PROFESSIONAL === request.tier ? request.isTrial : false,
  VectorOptimized: request.vectorOptimized,
  GDSPlugin: request.tier === TIER.PROFESSIONAL ? request.gdsPlugin : false,
  Storage: request.storage,
});

export const transformUpgradeInstanceConfigRequest = (
  request: UpgradeInstanceConfigRequest,
): TransformedUpgradeInstanceConfigBody => ({
  Memory: request.memory,
  Storage: request.storage,
});

export const transformUpdateInstanceConfigRequest = (
  request: UpdateInstanceConfigRequest,
): TransformedUpdateInstanceConfigBody => ({
  Memory: request.memory,
  Cpu: request.cpu,
  Storage: request.storage,
  VectorOptimized: request.vectorOptimized,
  GDSPlugin: request.gdsPlugin,
});

export const transformOverwriteInstanceRequest = (
  request: OverwriteInstanceRequest,
): TransformedOverwriteInstanceBody => ({
  SourceDBID: request.sourceDbId,
});

export const transformUpdateInstanceNameRequest = (
  request: UpdateInstanceNameRequest,
): TransformedUpdateInstanceNameBody => ({
  Name: request.name,
});

export const transformUpdateSecondariesRequest = (
  request: UpdateSecondariesRequest,
): TransformedUpdateSecondariesBody => ({
  SecondariesCount: request.secondariesCount,
});

export const transformRestoreFromSnapshotRequest = (
  request: RestoreFromSnapshotRequest,
): TransformedRestoreFromSnapshotBody => ({
  SnapshotId: request.snapshotId,
});

export const transformUpdateCDCModeRequest = (request: UpdateCDCModeRequest): TransformedUpdateCDCModeBody => ({
  CDCEnrichmentMode: request.cdcEnrichmentMode,
});

export const transformSignedObjectUrl = (response: GetSignedObjectUrlResponse): SignedObjectUrl => ({
  cloudStorageObject: response.cloud_storage_object,
  signedUrl: response.signed_url,
});
