import { Banner, Button, Tabs, TextLink, Tooltip, Typography } from '@neo4j-ndl/react';
import { ACTION, PROJECT_BILLING_METHOD, PROJECT_TYPE, consoleApi, useAppSelector, usePermissions } from '@nx/state';
import type { Consumption, Project, Usage } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { useDarkMode } from '@nx/ui';
import cn from 'classnames';
import { useCallback, useState } from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';

import { isMarketplaceProject } from '../../instance/entities/helpers';
import { AWSBillingMessage, AzureBillingMessage, GCPBillingMessage } from '../../marketplace';
import { formatDollars } from '../../utils';
import { AddPaymentMethodModal } from './shared/modal';
import { fullPath } from './utils';

const TotalConsumption = ({ totalCost, currency, discount }: Consumption) => {
  const isDarkMode = useDarkMode();

  let outstandingAmount = parseFloat(totalCost);
  if (discount) {
    outstandingAmount -= parseFloat(discount.dollarAmountOff);
  }
  const totalCostStr = currency === 'dollars' ? formatDollars(totalCost) : totalCost;
  const outstandingAmountStr =
    currency === 'dollars' ? formatDollars(Math.abs(outstandingAmount)) : Math.abs(outstandingAmount);

  const hasDiscount = isNotNullish(discount);

  return (
    <div className="mx-2 flex flex-col gap-2">
      <Typography variant="body-medium" className="text-neutral-text-weaker">
        {outstandingAmount < 0 ? 'Total credit spent:' : 'Total amount due this month:'}{' '}
        <Tooltip type="simple" isDisabled={!hasDiscount}>
          <Tooltip.Trigger hasButtonWrapper>
            <span className={cn('font-bold', { underline: hasDiscount })}>{outstandingAmountStr}</span>
          </Tooltip.Trigger>

          {hasDiscount && (
            <Tooltip.Content className="w-[320px]">
              <Tooltip.Header>Cost breakdown</Tooltip.Header>
              <Tooltip.Body className="mt-1">
                <Typography
                  variant="body-medium"
                  as="div"
                  className={cn('flex flex-col gap-1', {
                    'text-light-neutral-text-weak': isDarkMode,
                    'text-dark-neutral-text-weak': !isDarkMode,
                  })}
                >
                  <div className="flex justify-between">
                    Subtotal:{' '}
                    <span
                      className={cn({
                        'text-light-neutral-text-default': isDarkMode,
                        'text-dark-neutral-text-default': !isDarkMode,
                      })}
                    >
                      {totalCostStr}
                    </span>
                  </div>

                  <div className="flex flex-col">
                    <div className="flex justify-between">
                      Discount:{' '}
                      <span
                        className={cn({
                          'text-light-danger-text': isDarkMode,
                          'text-dark-danger-text': !isDarkMode,
                        })}
                      >
                        -{currency === 'dollars' ? formatDollars(discount.dollarAmountOff) : discount.dollarAmountOff}
                      </span>
                    </div>
                    <Typography
                      variant="body-small"
                      as="div"
                      className={cn({
                        'text-light-neutral-text-weaker': isDarkMode,
                        'text-dark-neutral-text-weaker': !isDarkMode,
                      })}
                    >
                      {discount.promotionName}
                    </Typography>
                  </div>

                  <div className="flex justify-between">
                    Total:{' '}
                    <span
                      className={cn({
                        'text-light-neutral-text-default': isDarkMode,
                        'text-dark-neutral-text-default': !isDarkMode,
                      })}
                    >
                      {outstandingAmountStr}
                    </span>
                  </div>
                </Typography>
              </Tooltip.Body>
            </Tooltip.Content>
          )}
        </Tooltip>
        {currency === 'credits' && (
          <>
            {' '}
            <Tooltip type="simple">
              <Tooltip.Trigger>
                <span className="font-bold underline decoration-dotted">ACU</span>
              </Tooltip.Trigger>
              <Tooltip.Content>
                <Tooltip.Body>Aura Consumption Unit</Tooltip.Body>
              </Tooltip.Content>
            </Tooltip>
          </>
        )}
      </Typography>
      {currency === 'credits' && (
        <Typography variant="body-small" className="text-neutral-text-weaker mr-2 flex justify-end">
          <span>
            Need credits?{' '}
            <TextLink href="https://neo4j.com/contact-us/" isExternalLink>
              Contact us
            </TextLink>
          </span>
        </Typography>
      )}
    </div>
  );
};

export type TabKey = 'usage' | 'invoices' | 'settings';
export const TabOptions: { label: string; key: TabKey }[] = [
  {
    key: 'usage',
    label: 'Usage',
  },
  {
    key: 'invoices',
    label: 'Invoices',
  },
  {
    key: 'settings',
    label: 'Payment settings',
  },
];
const TabKeys = TabOptions.map(({ key: value }) => value);

const isTabKey = (key?: string): key is TabKey => Boolean(TabKeys.find((tabKey) => key === tabKey));

type Props = {
  project: Project;
};

export const BillingLayout = ({ project }: Props) => {
  const navigate = useNavigate();
  const { isAllowed } = usePermissions();

  const match = useMatch('/projects/:projectId/billing/:activeTabKey');
  const hasPrePaidBilling = project.billingMethod === PROJECT_BILLING_METHOD.PREPAID;
  const activeTabKey = match?.params.activeTabKey;
  const selectedTab: TabKey = isTabKey(activeTabKey) ? activeTabKey : 'usage';

  const selectedUsageTable = useAppSelector((store) => store.configuration.selectedUsageTable);
  const fetchConsumption = project.capabilities.orb_consumption_reporting && selectedUsageTable === 'new';
  const showTotal = !project.capabilities.orb_consumption_reporting || fetchConsumption;

  const defaultUsage: Usage = {
    totalCost: '0',
    totalCostType: 'dollars',
    discount: undefined,
    breakdown: [],
  };
  const { data: usage = defaultUsage } = consoleApi.useGetUsageQuery(
    {
      projectId: project.id,
    },
    { refetchOnMountOrArgChange: true, skip: selectedTab !== 'usage' || fetchConsumption },
  );

  const defaultConsumption: Consumption = {
    totalCost: '0',
    currency: 'dollars',
    discount: undefined,
    breakdown: [],
  };
  const { data: consumption = defaultConsumption } = consoleApi.useGetConsumptionQuery(
    {
      projectId: project.id,
    },
    { refetchOnMountOrArgChange: true, skip: selectedTab !== 'usage' || !fetchConsumption },
  );

  const { totalCost, currency, discount } = fetchConsumption
    ? consumption
    : { ...usage, currency: usage.totalCostType };

  const onTabChange = useCallback(
    (tabKey: TabKey) => {
      navigate(fullPath(project.id, tabKey));
    },
    [navigate, project],
  );

  const [openModal, setOpenModal] = useState<boolean>(false);

  // Billing Guard
  if (!isAllowed(ACTION.READ, `tenants/${project.id}/billing`)) {
    return (
      <div className="h-full overflow-auto p-4">
        <Typography variant="h2" className="mb-6 px-2 pt-2">
          Billing
        </Typography>
        <Banner type="warning" hasIcon usage="inline">
          Administrator permissions are required to access this page.
        </Banner>
      </div>
    );
  }

  // Marketplace Billing
  if (isMarketplaceProject(project.projectType)) {
    return (
      <div className="h-full overflow-auto p-4">
        <Typography variant="h2" className="mb-6 px-2 pt-2">
          Billing
        </Typography>
        {project.projectType === PROJECT_TYPE.N4GCP && isNotNullish(project.googleProjectId) && (
          <GCPBillingMessage googleProjectId={project.googleProjectId} />
        )}
        {project.projectType === PROJECT_TYPE.MARKETPLACE_AWS && <AWSBillingMessage />}
        {project.projectType === PROJECT_TYPE.MARKETPLACE_AZURE && <AzureBillingMessage />}
      </div>
    );
  }

  // No Billing Set
  if (!project.hasBilling && !hasPrePaidBilling) {
    return (
      <>
        <div className="h-full overflow-auto p-4">
          <Typography variant="h2" className="mb-6 px-2 pt-2">
            Billing
          </Typography>
          <div className="bg-neutral-bg-weak flex flex-row items-center justify-between gap-4 rounded-3xl p-4">
            <Banner
              type="info"
              hasIcon
              className="my-4"
              usage="inline"
              htmlAttributes={{
                'data-testid': 'no-payment-method',
              }}
            >
              There is no payment method attached to your account.
            </Banner>
            <Button
              color="neutral"
              fill="outlined"
              size="small"
              htmlAttributes={{
                title: 'Payment method',
              }}
              className="m-2"
              onClick={() => setOpenModal(true)}
            >
              Add new payment method
            </Button>
          </div>
        </div>
        {openModal && <AddPaymentMethodModal onClose={() => setOpenModal(false)} hasBilling={project.hasBilling} />}
      </>
    );
  }

  return (
    <div className="h-full overflow-auto p-4">
      <div className="flex flex-row items-end justify-between">
        <Typography variant="h2" className="mb-2 px-2 pt-2">
          Billing
        </Typography>
        {selectedTab === 'usage' && showTotal && (
          <TotalConsumption totalCost={totalCost} currency={currency} discount={discount} />
        )}
      </div>
      <div className="w-fit">
        {project.projectType === PROJECT_TYPE.ENTERPRISE ? (
          // VDC can only see usage tab
          <Tabs as="div" fill="underline" onChange={onTabChange} value={selectedTab}>
            <Tabs.Tab
              tabId="usage"
              htmlAttributes={{
                'data-testid': 'usage',
              }}
            >
              Usage
            </Tabs.Tab>
          </Tabs>
        ) : (
          <Tabs as="div" fill="underline" onChange={onTabChange} value={selectedTab}>
            {TabOptions.map((tabOption) => (
              <Tooltip type="simple" key={tabOption.key} isDisabled={!hasPrePaidBilling || tabOption.key === 'usage'}>
                <Tooltip.Trigger hasButtonWrapper>
                  <Tabs.Tab
                    tabId={tabOption.key}
                    isDisabled={hasPrePaidBilling && ['settings', 'invoices'].includes(tabOption.key)}
                    htmlAttributes={{
                      'data-testid': tabOption.key,
                    }}
                  >
                    {tabOption.label}
                  </Tabs.Tab>
                </Tooltip.Trigger>
                <Tooltip.Content>Not available for prepaid billing</Tooltip.Content>
              </Tooltip>
            ))}
          </Tabs>
        )}
      </div>

      <Outlet />
    </div>
  );
};
