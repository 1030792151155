import { Button, Dialog, TextInput } from '@neo4j-ndl/react';
import { useConnection, useModalClose } from '@nx/state';
import type { ChangeEvent } from 'react';
import { useRef, useState } from 'react';

export function InstanceNameDialog() {
  const connection = useConnection();
  const formRef = useRef<HTMLFormElement>(null);
  const handleClose = useModalClose();

  const [instanceNameValue, setInstanceNameValue] = useState(connection.metadata?.name ?? '');

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    setInstanceNameValue(event.target.value);
  }

  function handleSubmit() {
    connection.rename(instanceNameValue);
    handleClose();
  }

  return (
    <Dialog isOpen onClose={handleClose}>
      <Dialog.Header>Rename connection</Dialog.Header>
      <form
        ref={formRef}
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
      >
        <Dialog.Content>
          <div className="w-full space-y-6">
            <TextInput
              size="large"
              isFluid
              value={instanceNameValue}
              onChange={handleChange}
              label="Instance name"
              htmlAttributes={{
                'aria-label': 'Instance name',
                autoFocus: true,
                name: 'instanceName',
                placeholder: connection.metadata?.name,
              }}
            />
          </div>
        </Dialog.Content>
        <Dialog.Actions>
          <Button type="submit">Change connection name</Button>
        </Dialog.Actions>
      </form>
    </Dialog>
  );
}
