import { DateTimeFormatter, LocalTime as JodaLocalTime } from '@js-joda/core';
import { isEmpty } from 'lodash-es';
import type { Moment } from 'moment';
import moment from 'moment';

import { BaseTemporal } from './BaseTemporal';
import type { TimeProperties } from './types';
import { NANO_PER_DAY, NANO_PER_MILLISECOND, TIME_FORMAT } from './utils.const';

export class LocalTime extends BaseTemporal {
  neo4jLocalTime: TimeProperties;

  jodaLocalTime: JodaLocalTime;

  constructor(obj: TimeProperties) {
    super();

    const { hour, minute, second, nanosecond } = obj;

    this.neo4jLocalTime = obj;
    this.jodaLocalTime = JodaLocalTime.of(hour, minute, second, nanosecond);
  }

  static parseNumberToString(nanoOfDay: number) {
    // make sure the max does not exceed 23:59:59.999999999
    const nano = Math.min(nanoOfDay, NANO_PER_DAY - 1);

    return JodaLocalTime.ofNanoOfDay(nano).toString();
  }

  static parseStringToObj(stringVal: string) {
    const finalStringVal = this.addZeroToHourIfNeeded(stringVal);
    const jodaLocalTime = JodaLocalTime.parse(finalStringVal);
    return new LocalTime({
      hour: jodaLocalTime.hour(),
      minute: jodaLocalTime.minute(),
      second: jodaLocalTime.second(),
      nanosecond: jodaLocalTime.nano(),
    });
  }

  static parseMomentObjToObj(momentObj: Moment, addedNanoseconds = 0) {
    return new LocalTime({
      hour: momentObj.hour(),
      minute: momentObj.minute(),
      second: momentObj.second(),
      nanosecond: momentObj.millisecond() * NANO_PER_MILLISECOND + addedNanoseconds,
    });
  }

  static parseStringToNumber(localTime: string) {
    if (isEmpty(localTime)) {
      return NaN;
    }

    return JodaLocalTime.parse(localTime).toNanoOfDay();
  }

  getHour() {
    return this.neo4jLocalTime.hour;
  }

  getMinute() {
    return this.neo4jLocalTime.minute;
  }

  getSecond() {
    return this.neo4jLocalTime.second;
  }

  getNanosecond() {
    return this.neo4jLocalTime.nanosecond;
  }

  toNumber() {
    return this.jodaLocalTime.toNanoOfDay();
  }

  hasMs() {
    return this.jodaLocalTime.nano() > 0;
  }

  getTimezone() {
    return null; // interface
  }

  // the toString() result of neo4j.types.LocalTime
  toString() {
    return this.jodaLocalTime.format(DateTimeFormatter.ISO_LOCAL_TIME).toString();
  }

  // the toString() result of neo4j.types.LocalTime
  toNeo4jString() {
    return this.toString();
  }

  toPropertyObject() {
    return {
      hour: this.jodaLocalTime.hour(),
      minute: this.jodaLocalTime.minute(),
      second: this.jodaLocalTime.second(),
      nanosecond: this.jodaLocalTime.nano(),
    };
  }

  toMomentObj() {
    return moment(this.toString(), TIME_FORMAT);
  }
}
