import { Banner, type BannerProps, LoadingSpinner, usePrevious } from '@neo4j-ndl/react';
import type { ApiError } from '@nx/state';
import {
  getApiError,
  LEGACY_toggleCapability as toggleCapability,
  useActiveOrgQuery,
  useActiveProjectQuery,
  useUnsafeAppContext,
} from '@nx/state';
import { isNonEmptyString, isNotNullish } from '@nx/stdlib';
import { Center } from '@nx/ui';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { EmailVerifiedGuard, isAccountLinkingRequired, isEmailNotVerified } from '../components/api-error';
import { useIsOrgContext } from './hooks';
import { maybeAuthenticateWithSsoOrg, useAppContextInit } from './use-app-context-init';

const REFRESH_AND_SUPPORT_MESSAGE = 'Refresh the page and if the problem persists contact customer support.';
const UNAUTHORIZED_MESSAGE = "You don't have permission to view this resource.";

const CenteredLoadingSpinner = () => (
  <Center>
    <LoadingSpinner size="large" />
  </Center>
);

const ErrorBanner = ({ description, title = 'Error' }: Pick<BannerProps, 'description' | 'title'>) => (
  <Banner className="mx-auto my-8 h-fit w-fit" type="danger" title={title} description={description} usage="inline" />
);

const useIsFetchingAfterValueChange = (isFetching: boolean, value: string | null) => {
  const [isFetchingAfterChange, setIsFetchingAfterChange] = useState(false);
  const prevValue = usePrevious(value);
  const justChanged = isNonEmptyString(prevValue) && value !== prevValue;
  useEffect(() => {
    if (justChanged) {
      setIsFetchingAfterChange(true);
    }
    if (!isFetching && isFetchingAfterChange) {
      setIsFetchingAfterChange(false);
    }
  }, [isFetching, isFetchingAfterChange, justChanged]);

  return isFetching && (justChanged || isFetchingAfterChange);
};

type AppContextLoaderProps = {
  /**
   * If `true` app context is initialized without showing loading and error elements.
   * Instead, the Outlet component is rendered immediately.
   */
  loadInBackground?: boolean;
};

export const AppContextLoader = ({ loadInBackground = false }: AppContextLoaderProps) => {
  const { activeOrgId, activeProjectId } = useUnsafeAppContext();
  const isOrgContext = useIsOrgContext();
  const init = useAppContextInit();
  const navigate = useNavigate();

  const getOrgRes = useActiveOrgQuery({
    refetchOnMountOrArgChange: true,
  });
  const getProjectRes = useActiveProjectQuery({
    refetchOnMountOrArgChange: true,
  });

  const isFetchingAfterOrgChange = useIsFetchingAfterValueChange(getOrgRes.isFetching, activeOrgId);
  const isFetchingAfterProjectChange = useIsFetchingAfterValueChange(getProjectRes.isFetching, activeProjectId);

  const getErrorBanner = ({
    error,
    defaultMessage,
  }: {
    error: FetchBaseQueryError | SerializedError | undefined;
    defaultMessage: string;
  }) => {
    if (isNotNullish(error)) {
      const apiError = getApiError(error);
      if (apiError.code === 403) {
        return <ErrorBanner title="Access denied" description={UNAUTHORIZED_MESSAGE} />;
      }
      return <ErrorBanner description={defaultMessage} />;
    }

    return undefined;
  };

  const getApiErrorBanner = ({ error }: { error: ApiError | null }) => {
    if (isNotNullish(error) && isNotNullish(error.message)) {
      return <ErrorBanner description={error.message} />;
    }

    return undefined;
  };

  const errorBanner =
    getApiErrorBanner({ error: init.error }) ??
    getErrorBanner({
      error: getOrgRes.error,
      defaultMessage: `Failed to load organization data. ${REFRESH_AND_SUPPORT_MESSAGE}`,
    }) ??
    getErrorBanner({
      error: getProjectRes.error,
      defaultMessage: `Failed to load project data. ${REFRESH_AND_SUPPORT_MESSAGE}`,
    });

  useEffect(() => {
    toggleCapability({ key: 'aura:genai', value: getOrgRes.data?.copilotEnabled });
  }, [getOrgRes.data]);

  if (loadInBackground) {
    return <Outlet />;
  }

  if (isNonEmptyString(init.error?.reason) && isEmailNotVerified(init.error.reason)) {
    return <EmailVerifiedGuard />;
  }

  if (isNonEmptyString(init.error?.reason) && isAccountLinkingRequired(init.error.reason)) {
    navigate('/link-accounts', { state: { email: init.error.extra?.email, connection: init.error.extra?.connection } });
    return null;
  }

  if (
    init.isLoading ||
    getOrgRes.isLoading ||
    isFetchingAfterOrgChange ||
    getProjectRes.isLoading ||
    isFetchingAfterProjectChange
  ) {
    return <CenteredLoadingSpinner />;
  }

  if (isNotNullish(errorBanner)) {
    return errorBanner;
  }

  if (isOrgContext && !getOrgRes.data) {
    return null;
  }

  if (!isOrgContext && (!getOrgRes.data || !getProjectRes.data)) {
    return null;
  }

  void maybeAuthenticateWithSsoOrg(getProjectRes.data?.ssoOrganizationId);

  return <Outlet />;
};
