import { Code, Dialog } from '@neo4j-ndl/react';
import { CheckIconOutline, ClipboardDocumentIconOutline } from '@neo4j-ndl/react/icons';
import React, { useState } from 'react';

const MetricsIntegrationConfigModal = ({
  open,
  onClose,
  prometheusConfig,
}: {
  open: boolean;
  onClose: () => void;
  prometheusConfig: string;
}) => {
  const [copying, setCopying] = useState(false);

  const copyCode = async () => {
    setCopying(true);
    await navigator.clipboard.writeText(prometheusConfig);
    setTimeout(() => setCopying(false), 1500);
  };

  return (
    <Dialog isOpen={open} onClose={onClose} modalProps={{ 'data-testid': 'link-prometheus-config-modal' }}>
      <Dialog.Header>Prometheus job configuration</Dialog.Header>
      <Code
        language="yaml"
        code={prometheusConfig}
        theme="prism"
        actions={[
          {
            ariaLabel: 'copy',
            htmlAttributes: { title: 'copy' },
            children: copying ? <CheckIconOutline /> : <ClipboardDocumentIconOutline />,
            onClick: () => {
              void copyCode().then();
            },
          },
        ]}
      />
    </Dialog>
  );
};

export default MetricsIntegrationConfigModal;
