import { CLOUD_PROVIDER } from '@nx/state';
import type { ReactNode } from 'react';

import AWSDark from '../../assets/aws-dark.svg';
import AWS from '../../assets/aws.svg';
import Azure from '../../assets/azure.svg';
import GCP from '../../assets/gcp.svg';

type CloudProviderIconProps = {
  cloudProvider: CLOUD_PROVIDER;
  isDarkTheme: boolean;
};

const cloudProviderIconFuncMap: Record<CLOUD_PROVIDER, (isDarkTheme: boolean) => ReactNode> = {
  [CLOUD_PROVIDER.GCP]: () => (
    <img role="presentation" src={GCP} width="20" height="16" title="GCP" className="shrink-0" alt="GCP logo" />
  ),
  [CLOUD_PROVIDER.AWS]: (isDarkTheme: boolean) => (
    <img
      role="presentation"
      src={isDarkTheme ? AWSDark : AWS}
      width="20"
      height="16"
      title="AWS"
      className="shrink-0"
      alt="AWS logo"
    />
  ),
  [CLOUD_PROVIDER.AZURE]: () => (
    <img role="presentation" src={Azure} width="20" height="16" title="Azure" className="shrink-0" alt="Azure logo" />
  ),
};

export const CloudProviderIcon = ({ cloudProvider, isDarkTheme = false }: CloudProviderIconProps) => {
  return cloudProviderIconFuncMap[cloudProvider](isDarkTheme);
};
