import { APP_VERSION } from '../../constants';
import type { Nullable } from '../../types/utility';

const VERSION_REGEX = /v?(\d+\.\d+\.\d+)/;

export const getStrippedVersion = (version: Nullable<string>) => {
  const versionMatch = version != null ? VERSION_REGEX.exec(version) : null;
  if (versionMatch != null && versionMatch.length > 0) {
    return versionMatch[0];
  }
};

const migrationVersion = getStrippedVersion(APP_VERSION);

export const getClientVersion = () => APP_VERSION;

export const getClientMigrationVersion = () => migrationVersion;
