import { IconButton, Typography } from '@neo4j-ndl/react';
import { ArrowRightCircleIconOutline } from '@neo4j-ndl/react/icons';
import { OPS_EVENTS } from '@nx/analytics-service';
import type { TimeRangeQueryLog, TimeRangeQueryLogAggregation } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { dataGridHelpersClasses } from '@nx/ui';
import type { ColumnDefResolved, Row } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';
import { produce } from 'immer';
import { useCallback, useMemo } from 'react';

import { track } from '../../../services/segment/analytics';
import { columnPreferencesColumn } from '../../shared/components/column-preferences';
import { GQLErrorModal } from '../../shared/components/gql-error-modal';
import { QueryCell } from '../../shared/components/query-cell';
import { NumberCell, TimestampCell } from '../../shared/helpers/table-overrides';
import type { TabText, TypedColumnDef } from '../../shared/types';
import { StatusLabel } from '../helpers';
import { useLogsContext } from './use-logs-context';

type CommonKeys = keyof (TimeRangeQueryLog | TimeRangeQueryLogAggregation);
type CommonFields = Pick<TimeRangeQueryLog, CommonKeys> & Pick<TimeRangeQueryLogAggregation, CommonKeys>;

const COMPACT_SIZES: Record<string, number> = {
  status: 135,
  query: 160,
  // summary columns
  from: 100,
  to: 100,
  // details columns
  endTime: 150,
};

const columnHelperCommon = createColumnHelper<CommonFields>();
const columnHelperSummary = createColumnHelper<TimeRangeQueryLogAggregation>();
const columnHelperDetails = createColumnHelper<TimeRangeQueryLog>();

const commonColumns = {
  status: columnHelperCommon.accessor('status', {
    header: 'Status',
    size: 150,
    cell: ({ getValue }) => <StatusLabel level={getValue()} />,
    enableColumnFilter: true,
    enableResizing: false,
    meta: {
      logs: {
        isPersistent: true,
      },
    },
  }),
  query: columnHelperCommon.accessor('query', {
    header: 'Query',
    cell: ({ getValue, row, column }) => (
      <QueryCell value={getValue()} query={row.original.query} error={row.original.error} width={column.getSize()} />
    ),
    size: 400,
    meta: {
      logs: {
        isPersistent: true,
      },
    },
  }),
  queryLanguage: columnHelperCommon.accessor('queryLanguage', {
    header: 'Query language',
    enableColumnFilter: true,
    size: 210,
  }),
};

const summaryColumns = [
  commonColumns.status,
  commonColumns.query,
  columnHelperSummary.accessor('executionCount', {
    header: 'Count',
    cell: NumberCell,
    size: 110,
    meta: {
      logs: {
        isPersistent: true,
      },
    },
  }),
  columnHelperSummary.accessor('from', {
    header: 'From',
    cell: TimestampCell,
    size: 210,
    enableSorting: false,
  }),
  columnHelperSummary.accessor('to', {
    header: 'To',
    cell: TimestampCell,
    size: 210,
    enableSorting: false,
  }),
  columnHelperSummary.accessor('totalTimeSpent', {
    header: 'Total time spent (s)',
    cell: NumberCell,
    size: 190,
  }),
  columnHelperSummary.accessor('avgExecutionTimeMs', {
    header: 'Avg time (ms)',
    cell: NumberCell,
    size: 150,
  }),
  columnHelperSummary.accessor('minExecutionTimeMs', {
    header: 'Min time (ms)',
    cell: NumberCell,
    size: 150,
  }),
  columnHelperSummary.accessor('maxExecutionTimeMs', {
    header: 'Max time (ms)',
    cell: NumberCell,
    size: 150,
  }),
  columnHelperSummary.accessor('avgWaitingTimeMs', {
    header: 'Avg waiting (ms)',
    cell: NumberCell,
    size: 170,
  }),
  columnHelperSummary.accessor('avgAllocatedBytes', {
    header: 'Avg bytes',
    cell: NumberCell,
    size: 130,
  }),
  columnHelperSummary.accessor('avgPageHits', {
    header: 'Avg page hits',
    cell: NumberCell,
    size: 150,
  }),
  columnHelperSummary.accessor('avgPageFaults', {
    header: 'Avg page faults',
    cell: NumberCell,
    size: 165,
  }),
  commonColumns.queryLanguage,
  columnHelperSummary.display({
    ...columnPreferencesColumn,
    cell: function Cell({ row }) {
      const { query, id } = row.original;
      const { setRawFilters, setSelectedTab } = useLogsContext();
      const onDetails = useCallback(() => {
        setRawFilters(
          produce((draft) => {
            draft.query = query;
          }),
        );
        setSelectedTab('DETAILS');
        track(OPS_EVENTS.LOGS_VIEW_QUERY_DETAILS);
      }, [query, setRawFilters, setSelectedTab]);

      return (
        <div
          className={classNames(dataGridHelpersClasses['visible-on-row-hover'], ' gap-token-4 flex items-center !p-0')}
        >
          <IconButton
            ariaLabel="Go to details for query"
            className="bg-palette-neutral-bg-weak rounded-lg"
            isClean
            isGrouped
            onClick={onDetails}
            htmlAttributes={{
              title: 'Go to details for query',
              'data-testid': `query-details-${id}`,
            }}
          >
            <ArrowRightCircleIconOutline />
          </IconButton>
        </div>
      );
    },
  }),
];

const detailsColumns = [
  commonColumns.status,
  columnHelperDetails.accessor('query', {
    header: 'Query',
    cell: ({ getValue, row, column }) => (
      <QueryCell
        value={getValue()}
        query={row.original.query}
        error={row.original.error}
        gqlError={row.original.gqlError}
        width={column.getSize()}
      />
    ),
    size: 400,
    meta: {
      logs: {
        isPersistent: true,
      },
    },
  }),
  columnHelperDetails.accessor('endTime', {
    header: 'End time',
    cell: TimestampCell.WithMs,
    size: 210,
  }),
  columnHelperDetails.accessor('executionTimeMs', {
    header: 'Duration (ms)',
    cell: NumberCell,
    size: 150,
  }),
  columnHelperDetails.accessor('planningTimeMs', {
    header: 'Planning (ms)',
    cell: NumberCell,
    size: 150,
  }),
  columnHelperDetails.accessor('waitingTimeMs', {
    header: 'Waiting (ms)',
    cell: NumberCell,
    size: 140,
  }),
  columnHelperDetails.accessor('user', {
    header: 'User',
    enableColumnFilter: true,
  }),
  columnHelperDetails.accessor('database', {
    header: 'Database',
    enableColumnFilter: true,
    size: 160,
  }),
  columnHelperDetails.accessor('driver', {
    header: 'Driver',
    enableColumnFilter: true,
    size: 160,
  }),
  columnHelperDetails.accessor('app', {
    header: 'Application',
    enableColumnFilter: true,
    size: 160,
  }),
  columnHelperDetails.accessor('initiationType', {
    header: 'Initiation type',
    enableColumnFilter: true,
    size: 160,
  }),
  columnHelperDetails.accessor('allocatedBytes', {
    header: 'Alloc bytes',
    cell: NumberCell,
    size: 130,
  }),
  columnHelperDetails.accessor('pageHits', {
    header: 'Page hits',
    cell: NumberCell,
    size: 130,
  }),
  columnHelperDetails.accessor('pageFaults', {
    header: 'Page faults',
    cell: NumberCell,
    size: 140,
  }),
  commonColumns.queryLanguage,
  columnHelperDetails.display(columnPreferencesColumn),
];

export const useColumns = <T extends TabText>(table: T, hasData: boolean) => {
  const { filterInput } = useLogsContext();

  const columns = useMemo(() => {
    if (table === 'Summary') {
      const cols = hasData
        ? summaryColumns
        : summaryColumns.map((col) => {
            const identifier =
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              (col as ColumnDefResolved<T>).accessorKey ?? (col as ColumnDefResolved<T>).id;
            return identifier !== undefined && (COMPACT_SIZES[identifier] ?? 0)
              ? { ...col, size: COMPACT_SIZES[identifier] }
              : col;
          });
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return cols as TypedColumnDef<T>[];
    }

    if (table === 'Details') {
      const singleQueryDetails = Boolean(filterInput.query);
      const cols =
        singleQueryDetails || !hasData
          ? detailsColumns.map((col) => {
              const identifier =
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                (col as ColumnDefResolved<T>).accessorKey ?? (col as ColumnDefResolved<T>).accessorKey;

              if (!hasData && identifier !== undefined && (COMPACT_SIZES[identifier] ?? 0)) {
                return { ...col, size: COMPACT_SIZES[identifier] };
              }

              if (identifier === 'query') {
                return {
                  ...col,
                  size: COMPACT_SIZES[identifier],
                  cell: ({ row }: { row: Row<TimeRangeQueryLog> }) => (
                    <div>
                      <Typography variant={'body-medium'}>See above</Typography>
                      {isNotNullish(row.original.gqlError) && <GQLErrorModal gqlError={row.original.gqlError} />}
                    </div>
                  ),
                };
              }

              return col;
            })
          : detailsColumns;
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return cols as TypedColumnDef<T>[];
    }

    return [];
  }, [filterInput.query, hasData, table]);

  return columns;
};
