import { decode } from '@nx/stdlib';
import * as Sentry from '@sentry/browser';
import { Type } from '@sinclair/typebox';

import { SeamlessConnectionError } from './errors';
import { getAuraInstanceId } from './utils';

const SeamlessConnectionSchema = Type.Object({
  access_token: Type.String(),
  expires: Type.Number(),
});

const ErrorPayloadSchema = Type.Object({
  Message: Type.String(),
  Reason: Type.String(),
});

export const getSeamlessConnectionToken = async (
  tokenApiUrl: string,
  url: string,
  getAccessToken: () => Promise<string | undefined>,
  getUserIdToken: () => Promise<string | undefined>,
  authLog: (msg: string, type?: 'log' | 'warn' | 'error') => void,
): Promise<{ token: string; expiration: number }> => {
  let instanceId = getAuraInstanceId(url);

  // If the user isn't logged in we should implement it here once we have reworked the connection slice
  let token = await getAccessToken();

  if (instanceId === undefined && url.includes('localhost')) {
    // This is used for testing, where we don't have the same URL format as the Aura instances
    instanceId = 'localhost';
    token = await getUserIdToken();
  }

  if (instanceId === undefined) {
    authLog('Seamless connection: Failed to get required instance ID from URL', 'error');
    throw new SeamlessConnectionError('MissingInstanceId');
  }

  if (token === undefined) {
    authLog('Seamless connection: Failed to get token, user is not logged in', 'error');
    throw new SeamlessConnectionError('AuthenticationRequired');
  }

  try {
    const response = await fetch(tokenApiUrl, {
      method: 'POST',
      body: JSON.stringify({ instance_id: instanceId }),
      headers: new Headers({
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      redirect: 'follow',
    });

    if (!response.ok) {
      const body: unknown = await response.json();
      const error = decode(ErrorPayloadSchema, body);
      if (error === undefined) {
        // eslint-disable-next-line no-console
        console.error('Malformed server response', body);
      }
      throw new SeamlessConnectionError('FetchFailed', error?.Message);
    }

    const result: unknown = await response.json();

    const validatedResult = decode(SeamlessConnectionSchema, result);

    if (validatedResult === undefined) {
      authLog('Seamless connection: Failed to get required token data', 'error');
      throw new SeamlessConnectionError('FetchFailed', 'Malformed server response');
    }

    return {
      token: validatedResult.access_token,
      expiration: validatedResult.expires,
    };
  } catch (error) {
    if (error instanceof SeamlessConnectionError) {
      throw error;
    }

    if (error instanceof Error) {
      authLog(`Seamless connection: Failed to fetch token: ${error.message}`, 'error');
    }

    // This is essentially unhandled exception territory
    Sentry.captureException(error);
    throw new SeamlessConnectionError('Unknown');
  }
};
