import { APP_SCOPE } from '@nx/constants';
import { Persist } from '@nx/state';
import { isNullish } from '@nx/stdlib';

import { STANDALONE_STORAGE_KEY } from '../constants';
import { isDataModelJsonStruct } from '../data-model/data-model-json-formatter';
import type { RootState } from '../state/store';
import type { SerialisableApplicationState, SerialisableApplicationStateLegacy } from '../types';
import { deserializeUserState, serializeUserState } from './serialization';

const LEGACY_STORAGE_KEY = Persist.createScopedPrefix(APP_SCOPE.import);

const MODEL_STORAGE_KEY = Persist.createKey(APP_SCOPE.import, 'model.v1');

export const loadLegacyStateFromStorage = (): SerialisableApplicationStateLegacy | null => {
  // TO BE REMOVED AFTER SEVERAL DEV PHASES:
  // Load storage from old key, if it contains value, load state;
  // Then, clean up the old key.
  const stateFromOldStorageKey = localStorage.getItem(STANDALONE_STORAGE_KEY);
  localStorage.removeItem(STANDALONE_STORAGE_KEY);

  let loadedStateTmp = null;

  if (!isNullish(stateFromOldStorageKey)) {
    loadedStateTmp = deserializeUserState(stateFromOldStorageKey);
  } else {
    loadedStateTmp = deserializeUserState(localStorage.getItem(LEGACY_STORAGE_KEY));
  }
  let dataModel = undefined;
  // Never possible to be the latest model, the new is always stored in new key, but so typescript doesn't complain
  if (!isDataModelJsonStruct(loadedStateTmp?.dataModel)) {
    dataModel = loadedStateTmp?.dataModel;
  }

  return loadedStateTmp !== null
    ? {
        ...loadedStateTmp,
        dataModel: dataModel,
      }
    : null;
};

export const loadStateFromStorage = (): SerialisableApplicationState | null => {
  return deserializeUserState(localStorage.getItem(MODEL_STORAGE_KEY));
};

export const saveToStorage = (state: RootState) => {
  localStorage.setItem(MODEL_STORAGE_KEY, serializeUserState(state));
};

export const clearStorage = () => {
  localStorage.removeItem(MODEL_STORAGE_KEY);
};
