import { Dialog } from '@neo4j-ndl/react';
import type { EncryptionKey, Project } from '@nx/state';
import { CLOUD_PROVIDER } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { useState } from 'react';

import { getCloudProviders } from '../../entities/helpers';
import { CmekForm } from './cmek-form';
import { AwsCmekPermissionsInstructions } from './permissions/aws';
import { AzureAddCustomerKeyInstructions, AzureCreatePrincipalInstructions } from './permissions/azure';
import { GcpCmekPermissionsInstructions } from './permissions/gcp';

type CmekDialogProps = {
  project: Project;
  encryptionKey: EncryptionKey | undefined;
  onClose: () => void;
  updateEncryptionKey: (encryptionKey: EncryptionKey) => void;
};

export const CmekPermissionsInstructions = ({
  cloudProvider,
  encryptionKey,
  onClose,
}: {
  cloudProvider: CLOUD_PROVIDER;
  encryptionKey: EncryptionKey;
  onClose: () => void;
}) => {
  return (
    <>
      {cloudProvider === CLOUD_PROVIDER.GCP && (
        <GcpCmekPermissionsInstructions encryptionKey={encryptionKey} onClose={onClose} />
      )}
      {cloudProvider === CLOUD_PROVIDER.AWS && (
        <AwsCmekPermissionsInstructions encryptionKey={encryptionKey} onClose={onClose} />
      )}
      {cloudProvider === CLOUD_PROVIDER.AZURE && (
        <AzureCreatePrincipalInstructions encryptionKey={encryptionKey} onClose={onClose} />
      )}
    </>
  );
};

export const CmekDialog = ({ project, encryptionKey, onClose, updateEncryptionKey }: CmekDialogProps) => {
  const [step, setStep] = useState(0);
  const [cloudProvider] = getCloudProviders(project.tierConfigs);

  return (
    <Dialog isOpen onClose={onClose}>
      {isNotNullish(cloudProvider) && (
        <>
          {cloudProvider === CLOUD_PROVIDER.AZURE && step === 0 && (
            <AzureAddCustomerKeyInstructions updateStep={() => setStep(step + 1)} onClose={onClose} />
          )}
          {((cloudProvider !== CLOUD_PROVIDER.AZURE && step === 0) ||
            (cloudProvider === CLOUD_PROVIDER.AZURE && step === 1)) && (
            <CmekForm
              project={project}
              updateStep={() => setStep(step + 1)}
              onClose={onClose}
              updateEncryptionKey={updateEncryptionKey}
            />
          )}
          {isNotNullish(encryptionKey) &&
            ((cloudProvider !== CLOUD_PROVIDER.AZURE && step === 1) ||
              (cloudProvider === CLOUD_PROVIDER.AZURE && step === 2)) && (
              <CmekPermissionsInstructions
                cloudProvider={cloudProvider}
                encryptionKey={encryptionKey}
                onClose={onClose}
              />
            )}
        </>
      )}
    </Dialog>
  );
};
