import bolt from '../../services/bolt/bolt';
import type { Complete, TransactionError } from '../../services/search/types';

interface Options {
  parameters?: object;
  type?: string;
  requestId?: string;
  timeout?: number;
  limit?: number;
  errorHandler?: (error: TransactionError, complete: Complete) => void;
  forceAbortHandler?: () => void;
}

export const readSearchTransaction = async (input: string, options: Options = {}) =>
  bolt.readTransaction(input, options);

export const readSearchRxTransaction = async (input: string, options: Options = {}) =>
  bolt.readRxTransaction(input, options);
