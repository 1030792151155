import type { BannerCommonProps } from '@neo4j-ndl/react';
import { Banner } from '@neo4j-ndl/react';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import classNames from 'classnames';

type ApiError = {
  reason: string | undefined;
  message: string | undefined;
  field: string | undefined;
  code: number | undefined;
};

type ApiErrorData = {
  Message?: string;
  Reason?: string;
  Field?: string;
  message?: string;
  reason?: string;
  field?: string;
};

const getApiErrors = (fetchError: FetchBaseQueryError | SerializedError | undefined): ApiError[] => {
  const { data, status } =
    isNotNullish(fetchError) && 'data' in fetchError ? fetchError : { data: null, status: null, ...fetchError };

  let code = undefined;
  if (typeof status === 'number') {
    code = status;
  }

  let errors: ApiError[] = [];
  if (isNotNullish(data) && typeof data === 'object') {
    if ('errors' in data && Array.isArray(data.errors)) {
      errors = data.errors.map((error: ApiErrorData) => {
        let message;
        let reason;
        let field;
        if (
          'Message' in error &&
          typeof error.Message === 'string' &&
          'Reason' in error &&
          typeof error.Reason === 'string'
        ) {
          reason = error.Reason;
          message = error.Message;
          field = error.Field;
        }

        if (
          'message' in error &&
          typeof error.message === 'string' &&
          'reason' in error &&
          typeof error.reason === 'string'
        ) {
          reason = error.reason;
          message = error.message;
          field = error.field;
        }

        return {
          reason,
          message,
          field,
          code,
        };
      });
    } else {
      let message;
      let reason;
      if (
        'Message' in data &&
        typeof data.Message === 'string' &&
        'Reason' in data &&
        typeof data.Reason === 'string'
      ) {
        reason = data.Reason;
        message = data.Message;
      }

      if (
        'message' in data &&
        typeof data.message === 'string' &&
        'reason' in data &&
        typeof data.reason === 'string'
      ) {
        reason = data.reason;
        message = data.message;
      }

      errors.push({
        reason,
        message,
        field: undefined,
        code,
      });
    }
  }
  return errors;
};

const printableDescription = (errors: ApiError[]) => {
  return (
    <div>
      {errors.map(({ message, reason, field }, idx) => {
        const extra =
          isNotNullish(reason) || isNotNullish(field) ? ` [${reason}${isNotNullish(field) ? `, ${field}` : ''}]` : '';
        return (
          <div key={`error-${idx}`}>
            <div className={classNames(idx > 0 && 'mt-2')}>
              {message}
              {extra}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const ApiErrorBanner = ({
  error,
  className,
  hasIcon,
}: {
  error: FetchBaseQueryError | SerializedError;
} & Pick<BannerCommonProps, 'className' | 'hasIcon'>) => {
  const apiErrors = getApiErrors(error);
  const title = `API ${apiErrors.length > 1 ? 'Errors' : 'Error'}`;
  return (
    <Banner
      className={classNames(className)}
      description={printableDescription(apiErrors)}
      title={title}
      hasIcon={hasIcon}
      isCloseable={false}
      type="danger"
      usage="inline"
    />
  );
};
