import { tokens } from '@neo4j-ndl/base';
import { Button, Typography, useMediaQuery } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import type { DataApiModify, Instance } from '@nx/state';
import { DATA_API_AUTH_PROVIDER_TYPE, graphqlApi, useActiveProject } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useState } from 'react';

import { ApiErrorBanner } from '../components/api-error-banner';
import { CredentialsModal } from '../components/credentials-modal';
import { ModifyAccordion } from '../modules/modify-accordion';
import type { CredentialsModalData } from '../types';
import { MODIFY_MODE } from '../types';
import type { Validation } from '../utils/validation';
import { validateYup } from '../utils/validation';
import { createSchema } from '../utils/validation-schemas';
import { transformDataApiCreateResponse } from './modify-helper';

const logger = createLogger(APP_SCOPE.dataApi);

interface ModifyDataApiProps {
  closeModifyPage: () => void;
  runningInstances: Instance[] | null;
}

const emptyState: DataApiModify = {
  id: '',
  name: '',
  instanceId: '',
  instanceUsername: '',
  instancePassword: '',
  typeDefinitions: '',
  allowedOrigins: [],
  authProviders: [],
};

const validateCreateData = (data: DataApiModify) => validateYup(createSchema, data, false);

export const CreateDataApi = ({ closeModifyPage, runningInstances }: ModifyDataApiProps) => {
  const activeProject = useActiveProject();
  const [createDataApi, { isLoading: isCreatingDataApi, error: errorCreatingDataApi }] =
    graphqlApi.useCreateDataApiMutation();

  const [validationError, setValidationError] = useState<Validation<DataApiModify> | null>(null);
  const [data, setData] = useState<DataApiModify>(emptyState);
  const [showCredentialsModal, setShowCredentialsModal] = useState<boolean>(false);
  const [credentialsModalData, setCredentialsModalData] = useState<CredentialsModalData | null>(null);

  const isLgOrAbove = useMediaQuery(`(min-width:${tokens.breakpoints.lg})`);

  const handleCreateDataApi = () => {
    Promise.all([validateCreateData(data)])
      .then(([validation]) => {
        if (isNotNullish(validation)) {
          setValidationError(validation);
          return;
        }
        setValidationError(null);

        createDataApi({
          projectId: activeProject.id,
          ...data,
        })
          .unwrap()
          .then((createResult) => {
            const hasAuthProviderWithApiKey = createResult.data.authentication_providers.some(
              (provider) => provider.type === DATA_API_AUTH_PROVIDER_TYPE.apiKey,
            );
            if (hasAuthProviderWithApiKey) {
              setCredentialsModalData(transformDataApiCreateResponse(createResult));
              setShowCredentialsModal(true);
            } else {
              closeModifyPage();
            }
          })
          .catch((err: FetchBaseQueryError | SerializedError | undefined) => {
            logger.error(err);
          });
      })
      .catch((err) => {
        logger.error(err);
      });
  };

  const handleCloseCredentialsModal = () => {
    setCredentialsModalData(null);
    setShowCredentialsModal(false);
    closeModifyPage();
  };

  const handleAction = () => {
    const element = document.getElementById('title-wrapper');
    element?.scrollIntoView({ behavior: 'instant' });

    handleCreateDataApi();
  };

  return (
    <>
      {showCredentialsModal && (
        <CredentialsModal data={credentialsModalData} onClose={() => handleCloseCredentialsModal()} />
      )}
      <div className="flex flex-col items-center justify-center">
        <div className="w-full max-w-[65rem]">
          <div className={`flex justify-between ${isLgOrAbove ? 'items-center' : 'flex-col items-start'}`}>
            <div className="flex items-center gap-4" id="title-wrapper">
              <Typography variant="h2">Create GraphQL Data API</Typography>
            </div>
            <div className={`${!isLgOrAbove && 'ml-auto mt-4'}`}>
              <Button
                color="neutral"
                className="mr-3"
                fill="outlined"
                onClick={() => closeModifyPage()}
                isDisabled={isCreatingDataApi}
              >
                Cancel
              </Button>
              <Button isLoading={isCreatingDataApi} isDisabled={false} type="submit" onClick={handleAction}>
                Create
              </Button>
            </div>
          </div>
          <main className="my-6">
            <div className="mb-4">
              {errorCreatingDataApi && <ApiErrorBanner error={errorCreatingDataApi} hasIcon />}
            </div>
            <ModifyAccordion
              mode={MODIFY_MODE.create}
              runningInstances={runningInstances}
              isLoading={isCreatingDataApi}
              data={data}
              validationError={validationError}
              setData={setData}
              setValidationError={setValidationError}
            />
          </main>
          <div className="flex items-center justify-end">
            <Button isLoading={isCreatingDataApi} isDisabled={false} type="submit" onClick={handleAction}>
              Create
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
