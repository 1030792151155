import { useStorageApi, useUnsafeAppContext } from '@nx/state';
import { Objects } from '@nx/stdlib';
import { useAppDispatch, useAppSelector } from '@query/redux/store';
import {
  trackSavedCypherCreated as trackSaveCypherQueryCreated,
  trackNewSavedCypherFolderCreated as trackSavedCypherFolderCreated,
  trackSavedCypherOverWrite,
} from '@query/services/analytics';
import type {
  GetSavedCyphersApiResponse,
  QuerySavedCypherFolder,
  QuerySavedCypherItem,
} from '@query/services/api/generated-saved-cypher-api';
import {
  useCreateSavedCypherFolderMutation,
  useCreateSavedCypherItemMutation,
  useDeleteSavedCyphersMutation,
  useGetSavedCyphersQuery,
  usePatchSavedCypherFolderByIdMutation,
  usePatchSavedCypherItemByIdMutation,
  useUpdateSavedCypherMutation,
} from '@query/services/api/saved-cypher-api';
import { QUERY_STORAGE_LOADING_STATE } from '@query/services/api/types';
import type { FlatSavedCypherFolder, FlatSavedCypherItem, FlatSavedCypherQuery } from '@query/types/saved-cypher';
import {
  isFlatSavedCypherFolder,
  isFlatSavedCypherQuery,
  isQuerySavedCypherFolder,
  isQuerySavedCypherItem,
  isSavedCypherFolder,
} from '@query/types/saved-cypher';
import { getItemFromLocalStorage, setItemInLocalStorage } from '@query/utils/localstorage-utils';
import { cypherItemErrorMessage, dispatchToast } from '@query/utils/notification-utils';
import {
  MAXIMUM_SAVED_CYPHER_ITEMS,
  buildTree,
  findDuplicateInFolder,
  findItemDeep,
  flattenTree,
  mergeTrees,
} from '@query/utils/saved-cypher-utils';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction, createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit';
import { skipToken } from '@reduxjs/toolkit/query';
import { useState } from 'react';

import { openSidebarTab } from './sidebar-slice';
import type { RootState } from './store';

interface SaveCypherCommand {
  name: string;
  query: string;
  description?: string;
  parentId: string | null;
}

interface SaveFolderCommand {
  name: string | null;
  parentId: string | null;
}

export interface SavedCypherState {
  flattenedSavedCypherTree: FlatSavedCypherItem[];
  latestFolderSavedIn: string | null;
  folderPendingRename: string | null;
  savedCypherTreeMigrated: boolean | null;
}

export const SAVED_CYPHER_PERSISTED_KEYS = ['flattenedSavedCypherTree', 'savedCypherTreeMigrated'];

export const FOLDERS_COLLAPSED_KEY = 'foldersCollapsed';

const NEW_FOLDER_PREFIX = 'New Folder';

const getNewFolderName = (folders: FlatSavedCypherFolder[], name: string | null): string => {
  if (name !== null && name !== '') {
    return name;
  }

  const folderNameSet = new Set(folders.map((folder) => folder.name));
  let newFolderNameCount = 0;
  let folderName = '';

  for (let i = 0; i < 1000; i++) {
    folderName = newFolderNameCount === 0 ? NEW_FOLDER_PREFIX : `${NEW_FOLDER_PREFIX} ${newFolderNameCount}`;
    const folderNameExists = folderNameSet.has(folderName);

    if (folderNameExists) {
      newFolderNameCount += 1;
    } else {
      break;
    }
  }

  return folderName;
};

const mapSavedCyphersToFlatSavedCypherItems = (data: GetSavedCyphersApiResponse): FlatSavedCypherItem[] => {
  const flatCypherTree = data.map((savedItem: QuerySavedCypherItem | QuerySavedCypherFolder) => {
    if (isQuerySavedCypherItem(savedItem)) {
      return {
        id: savedItem.id,
        parentId: savedItem.parent?.id ?? null,
        depth: 0,
        order: savedItem.order,
        name: savedItem.name,
        query: savedItem.query,
        description: '',
        databaseId: null,
      };
    } else if (isQuerySavedCypherFolder(savedItem)) {
      return {
        id: savedItem.id,
        parentId: savedItem.parent?.id ?? null,
        depth: 0,
        order: savedItem.order,
        name: savedItem.name,
        children: [],
        databaseId: null,
      };
    }

    return null;
  });

  return flatCypherTree.filter((item) => item !== null);
};

const addQuery = createAction('savedCypher/addQuery', ({ name, query, description, parentId }: SaveCypherCommand) => {
  trackSaveCypherQueryCreated();
  return {
    payload: {
      id: nanoid(),
      name,
      query,
      description: description ?? '',
      parentId: parentId ?? null,
      databaseId: null /* Saving null for now, will be used in future */,
    },
  };
});

const addFolder = createAsyncThunk('savedCypher/addFolder', ({ parentId, name }: SaveFolderCommand) => {
  trackSavedCypherFolderCreated();
  return {
    id: nanoid(),
    parentId: parentId,
    databaseId: null /* Saving null for now, will be used in future */,
    collapsed: true,
    children: [],
    name,
  };
});

const selectSavedCypherTree = (state: RootState) => state.savedCypher.flattenedSavedCypherTree;

const selectSavedCypherFolders = (state: RootState) =>
  state.savedCypher.flattenedSavedCypherTree.filter((item) => isFlatSavedCypherFolder(item));

export const selectLatestFolderSavedIn = (state: RootState) => state.savedCypher.latestFolderSavedIn;

export const selectFolderPendingRename = (state: RootState) => state.savedCypher.folderPendingRename;

const selectSavedCypherTreeMigrated = (state: RootState) => state.savedCypher.savedCypherTreeMigrated ?? false;

const initialState: SavedCypherState = {
  flattenedSavedCypherTree: [],
  latestFolderSavedIn: null,
  folderPendingRename: null,
  savedCypherTreeMigrated: null,
};

const savedCypher = createSlice({
  name: 'savedCypher',
  initialState,
  reducers: {
    setTree: (state, action: PayloadAction<FlatSavedCypherItem[]>) => {
      state.flattenedSavedCypherTree = action.payload;
    },
    removeItems: (state, action: PayloadAction<string[]>) => {
      state.flattenedSavedCypherTree = state.flattenedSavedCypherTree.filter(
        (savedCypherItem) => !action.payload.includes(savedCypherItem.id),
      );
    },
    setFolderName: (state, action: PayloadAction<{ id: string; name: string }>) => {
      const { name, id } = action.payload;
      const item = state.flattenedSavedCypherTree.find((t) => t.id === id);
      if (item) {
        item.name = name;
      }

      state.folderPendingRename = null;
    },
    updateItem: (state, action: PayloadAction<{ id: string; newValues: Partial<FlatSavedCypherQuery> }>) => {
      const { newValues, id } = action.payload;
      const itemIdx = state.flattenedSavedCypherTree.findIndex((t) => t.id === id);

      if (itemIdx > -1) {
        const item = state.flattenedSavedCypherTree[itemIdx];
        if (item !== undefined && isFlatSavedCypherQuery(item)) {
          const newItem: FlatSavedCypherQuery = { ...item, ...newValues };
          state.flattenedSavedCypherTree[itemIdx] = newItem;
        }
      }
    },
    setLatestFolderSavedIn: (state, action: PayloadAction<string | null>) => {
      state.latestFolderSavedIn = action.payload;
    },
    importTree: (state, action: PayloadAction<FlatSavedCypherItem[]>) => {
      const importTree = buildTree(action.payload);
      const currentTree = buildTree(state.flattenedSavedCypherTree);
      const result = mergeTrees(currentTree, importTree);
      state.flattenedSavedCypherTree = flattenTree(result);
    },
    setFolderPendingRename: (state, action: PayloadAction<{ id: string | null }>) => {
      state.folderPendingRename = action.payload.id;
    },
    setMigrated: (state, action: PayloadAction<boolean>) => {
      state.savedCypherTreeMigrated = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(addQuery, (state, action) => {
      const parentItem = state.flattenedSavedCypherTree.find((item) => item.id === action.payload.parentId);
      const depth = parentItem !== undefined ? parentItem.depth + 1 : 0;
      const order = state.flattenedSavedCypherTree.length;

      state.flattenedSavedCypherTree.push({
        ...action.payload,
        depth,
        order,
      });
      state.latestFolderSavedIn = parentItem !== undefined ? parentItem.id : null;
    });
    builder.addCase(addFolder.fulfilled, (state, action) => {
      const folders = state.flattenedSavedCypherTree.filter(isFlatSavedCypherFolder);
      const parentItem = folders.find((item) => item.id === action.payload.parentId);
      const depth = parentItem !== undefined ? parentItem.depth + 1 : 0;
      const order = state.flattenedSavedCypherTree.length;

      if (action.payload.name !== null) {
        if (
          folders.filter((folder) => folder.depth === 0).find((folder) => folder.name === action.payload.name) !==
          undefined
        ) {
          return;
        }
        const { name, ...rest } = action.payload;
        const newFolder: FlatSavedCypherFolder = {
          ...rest,
          name,
          depth,
          order,
        };
        state.flattenedSavedCypherTree.push(newFolder);
        return;
      }

      const name = getNewFolderName(folders, action.payload.name);
      const newFolder: FlatSavedCypherFolder = {
        ...action.payload,
        name,
        depth,
        order,
      };

      state.flattenedSavedCypherTree.push(newFolder);

      if (newFolder.name.startsWith(NEW_FOLDER_PREFIX)) {
        state.folderPendingRename = newFolder.id;
      }
    });
  },
});

const {
  removeItems: removeSavedCypherItems,
  setFolderName: setSavedCypherFolderName,
  updateItem: updateSavedCypherItem,
  importTree: importSavedCypherTree,
  setTree: setSavedCypherTree,
  setLatestFolderSavedIn: setSavedCypherLatestFolderSavedIn,
  setMigrated: setSavedCypherTreeMigrated,
} = savedCypher.actions;

export const { setFolderPendingRename } = savedCypher.actions;

export function useLocalStorage<T>(key: string, initialValue: T): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = useState(() => getItemFromLocalStorage(key, initialValue));

  const setLocalStorageState: React.Dispatch<React.SetStateAction<T>> = (newState) => {
    setItemInLocalStorage(key, newState);
    setState(newState);
  };

  return [state, setLocalStorageState];
}

const useGetSavedCyphersData = () => {
  const storageApiEnabled = useStorageApi('query:saved-cypher-shared-storage');
  const { activeProjectId } = useUnsafeAppContext();
  const skip = activeProjectId === null || !storageApiEnabled;
  const { data = [], isError, isSuccess } = useGetSavedCyphersQuery(skip ? skipToken : undefined);
  let state = QUERY_STORAGE_LOADING_STATE.LOADING;

  if (isSuccess) {
    state = QUERY_STORAGE_LOADING_STATE.SUCCESS;
  } else if (isError) {
    state = QUERY_STORAGE_LOADING_STATE.ERROR;
  }

  return { data, state };
};

export const useFlatSavedCypherTree = (): {
  flatSavedCypherTree: FlatSavedCypherItem[];
  state: QUERY_STORAGE_LOADING_STATE;
} => {
  const savedCypherTree = useAppSelector(selectSavedCypherTree);
  const storageApiEnabled = useStorageApi('query:saved-cypher-shared-storage');
  const { data, state } = useGetSavedCyphersData();

  if (storageApiEnabled) {
    return { flatSavedCypherTree: mapSavedCyphersToFlatSavedCypherItems(data), state };
  }

  return { flatSavedCypherTree: savedCypherTree, state: QUERY_STORAGE_LOADING_STATE.SUCCESS };
};

export const useSavedCypherFolders = (): FlatSavedCypherFolder[] => {
  const savedCypherFolders = useAppSelector(selectSavedCypherFolders);
  const storageApiEnabled = useStorageApi('query:saved-cypher-shared-storage');
  const { data } = useGetSavedCyphersData();

  if (storageApiEnabled) {
    const flatCypherTree = mapSavedCyphersToFlatSavedCypherItems(data);
    return flatCypherTree.filter(isFlatSavedCypherFolder);
  }

  return savedCypherFolders;
};

export const useSelectLatestFolderSavedIn = () => {
  const latestFolderSavedIn = useAppSelector(selectLatestFolderSavedIn);
  const flattenedSavedCypherTree = useAppSelector(selectSavedCypherTree);
  const storageApiEnabled = useStorageApi('query:saved-cypher-shared-storage');
  const { data } = useGetSavedCyphersData();

  if (latestFolderSavedIn === null) {
    return null;
  }

  if (storageApiEnabled) {
    const flatCypherTree = mapSavedCyphersToFlatSavedCypherItems(data);
    const item = findItemDeep(flatCypherTree, latestFolderSavedIn);
    if (item === undefined || !isSavedCypherFolder(item)) {
      return null;
    }

    return item;
  }

  const item = findItemDeep(flattenedSavedCypherTree, latestFolderSavedIn);
  if (item === undefined || !isSavedCypherFolder(item)) {
    return null;
  }

  return item;
};

const usePruneFoldersCollapsed = () => {
  const [foldersCollapsed, setFoldersCollapsed] = useLocalStorage<Record<string, boolean>>(FOLDERS_COLLAPSED_KEY, {});
  return (ids: string[]) => {
    setFoldersCollapsed({ ...Objects.removeKeys<Record<string, boolean>>(foldersCollapsed, ids) });
  };
};

export const useRemoveSavedCypherItems = () => {
  const dispatch = useAppDispatch();
  const storageApiEnabled = useStorageApi('query:saved-cypher-shared-storage');
  const [removeCyphers] = useDeleteSavedCyphersMutation();
  const clearFoldersCollapsed = usePruneFoldersCollapsed();

  if (storageApiEnabled) {
    return async (ids: string[]) => {
      await removeCyphers({ deleteSavedCyphersBody: { ids } }).unwrap();
      clearFoldersCollapsed(ids);
    };
  }

  return (ids: string[]) => {
    dispatch(removeSavedCypherItems(ids));
    clearFoldersCollapsed(ids);
    return Promise.resolve();
  };
};

export const useSetSavedCypherFolderName = () => {
  const dispatch = useAppDispatch();
  const storageApiEnabled = useStorageApi('query:saved-cypher-shared-storage');
  const [updateFolder] = usePatchSavedCypherFolderByIdMutation();

  if (storageApiEnabled) {
    return (payload: { id: string; name: string; parentId: string | null }) => {
      const result = updateFolder({
        id: payload.id,
        updateSavedCypherFolderBody: { id: payload.id, name: payload.name, parentId: payload.parentId },
      });
      dispatch(setFolderPendingRename({ id: null }));
      return result.unwrap();
    };
  }

  return (payload: { id: string; name: string }) => {
    dispatch(setSavedCypherFolderName(payload));
    return Promise.resolve();
  };
};

export const useUpdateSavedCypherItem = () => {
  const dispatch = useAppDispatch();
  const storageApiEnabled = useStorageApi('query:saved-cypher-shared-storage');
  const [updateItem] = usePatchSavedCypherItemByIdMutation();

  if (storageApiEnabled) {
    return (payload: { id: string; newValues: Partial<FlatSavedCypherQuery> }) => {
      const result = updateItem({
        id: payload.id,
        updateQuerySavedCypherItemBody: {
          id: payload.id,
          name: payload.newValues.name,
          query: payload.newValues.query,
          parentId: payload.newValues.parentId ?? null,
          order: payload.newValues.order,
        },
      });
      return result.unwrap();
    };
  }

  return (payload: { id: string; newValues: Partial<FlatSavedCypherQuery> }) => {
    dispatch(updateSavedCypherItem(payload));
    return Promise.resolve();
  };
};

export const useImportSavedCypherTree = () => {
  const dispatch = useAppDispatch();
  const storageApiEnabled = useStorageApi('query:saved-cypher-shared-storage');
  const { flatSavedCypherTree } = useFlatSavedCypherTree();
  const [updateTree] = useUpdateSavedCypherMutation();

  if (storageApiEnabled) {
    return (tree: FlatSavedCypherItem[]) => {
      const newItems = tree.filter((item) => item.isNew);
      if (flatSavedCypherTree.length + newItems.length > MAXIMUM_SAVED_CYPHER_ITEMS) {
        return Promise.reject(
          new Error(`Maximum number of saved Cypher items reached (${MAXIMUM_SAVED_CYPHER_ITEMS}).`),
        );
      }
      const result = updateTree({ updateQuerySavedCypherBody: tree });
      return result.unwrap();
    };
  }

  return (tree: FlatSavedCypherItem[]) => {
    const newItems = tree.filter((item) => item.isNew);
    if (flatSavedCypherTree.length + newItems.length > MAXIMUM_SAVED_CYPHER_ITEMS) {
      return Promise.reject(new Error(`Maximum number of saved Cypher items reached (${MAXIMUM_SAVED_CYPHER_ITEMS}).`));
    }
    dispatch(importSavedCypherTree(tree));
    return Promise.resolve();
  };
};

export const useMigrateSavedCypherTree = () => {
  const dispatch = useAppDispatch();
  const savedCypherTree = useAppSelector(selectSavedCypherTree);
  const savedCypherTreeMigrated = useAppSelector(selectSavedCypherTreeMigrated);
  const { flatSavedCypherTree } = useFlatSavedCypherTree();
  const storageApiEnabled = useStorageApi('query:saved-cypher-shared-storage');
  const [updateTree] = useUpdateSavedCypherMutation();

  if (storageApiEnabled) {
    return async () => {
      if (!savedCypherTreeMigrated && savedCypherTree.length > 0) {
        const datestamp = new Intl.DateTimeFormat('en-US', {
          hour12: false,
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        }).format(new Date());
        const name = `Migrated from localstorage ${datestamp}`;

        const folder = {
          id: nanoid(),
          parentId: null,
          depth: 0,
          index: 0,
          order: 0,
          name: name,
          children: savedCypherTree,
          databaseId: null,
        };

        const newFlatTree = [
          folder,
          ...savedCypherTree.map((item) => {
            if (item.parentId === null) {
              return { ...item, parentId: folder.id, depth: item.depth + 1 };
            }

            return { ...item, depth: item.depth + 1 };
          }),
        ];

        if (flatSavedCypherTree.length + newFlatTree.length > MAXIMUM_SAVED_CYPHER_ITEMS) {
          return Promise.reject(
            new Error(`Maximum number of saved Cypher items reached (${MAXIMUM_SAVED_CYPHER_ITEMS}).`),
          );
        }

        try {
          const result = updateTree({ updateQuerySavedCypherBody: newFlatTree });
          await result.unwrap();
          dispatch(setSavedCypherTreeMigrated(true));
        } catch {
          // We don't care about the error here, we will just retry the migration next time query loads
        }
      }

      return Promise.resolve();
    };
  }

  return () => Promise.resolve();
};

export const useAddQuery = () => {
  const dispatch = useAppDispatch();
  const storageApiEnabled = useStorageApi('query:saved-cypher-shared-storage');
  const [createItem] = useCreateSavedCypherItemMutation();
  const { flatSavedCypherTree } = useFlatSavedCypherTree();

  if (storageApiEnabled) {
    return async (command: SaveCypherCommand) => {
      if (flatSavedCypherTree.length >= MAXIMUM_SAVED_CYPHER_ITEMS) {
        return Promise.reject(
          new Error(`Maximum number of saved Cypher items reached (${MAXIMUM_SAVED_CYPHER_ITEMS}).`),
        );
      }

      const result = createItem({
        createQuerySavedCypherItemBody: {
          ...command,
          id: nanoid(),
          order: flatSavedCypherTree.length,
        },
      });
      await result.unwrap();
      dispatch(setSavedCypherLatestFolderSavedIn(command.parentId));
      return Promise.resolve();
    };
  }

  return (command: SaveCypherCommand) => {
    if (flatSavedCypherTree.length >= MAXIMUM_SAVED_CYPHER_ITEMS) {
      return Promise.reject(new Error(`Maximum number of saved Cypher items reached (${MAXIMUM_SAVED_CYPHER_ITEMS}).`));
    }

    dispatch(setSavedCypherLatestFolderSavedIn(command.parentId));
    dispatch(addQuery(command));
    return Promise.resolve();
  };
};

export const useAddFolder = () => {
  const dispatch = useAppDispatch();
  const storageApiEnabled = useStorageApi('query:saved-cypher-shared-storage');
  const [createFolder] = useCreateSavedCypherFolderMutation();
  const { flatSavedCypherTree } = useFlatSavedCypherTree();

  if (storageApiEnabled) {
    return (command: SaveFolderCommand) => {
      if (flatSavedCypherTree.length >= MAXIMUM_SAVED_CYPHER_ITEMS) {
        return Promise.reject(
          new Error(`Maximum number of saved Cypher items reached (${MAXIMUM_SAVED_CYPHER_ITEMS}).`),
        );
      }

      const folderName = getNewFolderName(flatSavedCypherTree.filter(isFlatSavedCypherFolder), command.name);
      const id = nanoid();

      const result = createFolder({
        createSavedCypherFolderBody: {
          id,
          name: folderName,
          parentId: command.parentId ?? null,
          order: flatSavedCypherTree.length,
        },
      });

      if (folderName.startsWith(NEW_FOLDER_PREFIX)) {
        dispatch(setFolderPendingRename({ id }));
      }

      return result.unwrap();
    };
  }

  return (command: SaveFolderCommand) => {
    if (flatSavedCypherTree.length >= MAXIMUM_SAVED_CYPHER_ITEMS) {
      return Promise.reject(new Error(`Maximum number of saved Cypher items reached (${MAXIMUM_SAVED_CYPHER_ITEMS}).`));
    }
    return dispatch(addFolder(command));
  };
};

export const useSetSavedCypherTree = () => {
  const dispatch = useAppDispatch();
  const storageApiEnabled = useStorageApi('query:saved-cypher-shared-storage');
  const [updateTree] = useUpdateSavedCypherMutation();

  if (storageApiEnabled) {
    return (tree: FlatSavedCypherItem[]) => {
      const result = updateTree({
        updateQuerySavedCypherBody: tree.map((item, order) => {
          if (isFlatSavedCypherFolder(item)) {
            return {
              id: item.id,
              parentId: item.parentId,
              name: item.name,
              order,
            };
          }

          return {
            id: item.id,
            parentId: item.parentId,
            name: item.name,
            query: item.query,
            order,
          };
        }),
      });
      return result.unwrap();
    };
  }

  return (tree: FlatSavedCypherItem[]) => {
    const flatTree = tree.map((item, order) => ({ ...item, order }));
    dispatch(setSavedCypherTree(flatTree));
    return Promise.resolve();
  };
};

export const useHandleSaveOrUpdateQuery = () => {
  const dispatch = useAppDispatch();
  const { flatSavedCypherTree } = useFlatSavedCypherTree();
  const addNewQuery = useAddQuery();
  const updateExistingQuery = useUpdateSavedCypherItem();

  return async (name: string, folderId: string | null, query: string): Promise<boolean> => {
    const existingQuery = findDuplicateInFolder(flatSavedCypherTree, folderId, name, 'items');

    if (existingQuery) {
      // Update existing query
      trackSavedCypherOverWrite();
      try {
        await updateExistingQuery({
          id: existingQuery.id,
          newValues: { name, description: '', query, parentId: folderId },
        });
        dispatch(openSidebarTab({ tabId: 'SAVED_CYPHER', tabButtonPingAnimationEnabled: true }));
        return true;
      } catch (error) {
        dispatchToast(cypherItemErrorMessage(error), 'danger');
        return false;
      }
    } else {
      // Add new query
      try {
        await addNewQuery({
          name,
          description: '',
          query,
          parentId: folderId,
        });
        dispatch(openSidebarTab({ tabId: 'SAVED_CYPHER', tabButtonPingAnimationEnabled: true }));
        return true;
      } catch (error) {
        dispatchToast(cypherItemErrorMessage(error), 'danger');
        return false;
      }
    }
  };
};

export default savedCypher.reducer;
