import { ConnectionCmdErrors } from '@query/constants/errors';
import type { QueryError } from '@query/types/query';

export type ParsedConnectionCommand = { arg: 'connect' | 'disconnect' } | { arg: 'error'; error: QueryError };

export function parseConnectionCommand(statement: string): ParsedConnectionCommand {
  const serverPrefix = ':server';
  const hasServerPrefix = statement.toLowerCase().startsWith(serverPrefix);
  const arg = statement.slice(hasServerPrefix ? serverPrefix.length : 1).trim();

  if (arg === 'connect' || arg === 'disconnect') {
    return { arg };
  }

  return { arg: 'error', error: ConnectionCmdErrors.UnknownArgument(arg) };
}
