import { add, formatDuration, intervalToDuration, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import type { LogResponse, LogsRequest } from '../console.api.types';
import { LOG_FILE, LOG_STATUS, type Log } from '../console.types';

export const transformLogResponse = ({
  log_id: id,
  dbid,
  start,
  end,
  status,
  files,
  requested_at: requestedAt,
}: LogResponse): Log => {
  const DAYS_BEFORE_EXPIRY = 7;
  const DATE_FORMAT = 'yyyy/MM/dd HH:mm:ss';

  const calculateTimeToExpire = () => {
    const expiresAt = add(parseISO(requestedAt), { days: DAYS_BEFORE_EXPIRY });
    return intervalToDuration({ start: new Date(), end: expiresAt });
  };

  const logFileTypeMap = {
    [LOG_FILE.QUERY]: 'Query log',
    [LOG_FILE.SECURITY]: 'Security log',
  };

  const determineLogType = (): string => {
    for (const file of files) {
      if (logFileTypeMap[file]) {
        return logFileTypeMap[file];
      }
    }

    return 'Unknown type';
  };

  const startDate = parseISO(start);
  const endDate = parseISO(end);
  const expiresIn = calculateTimeToExpire();
  const logStatus = expiresIn.days !== undefined && expiresIn.days <= 1 ? LOG_STATUS.EXPIRING_SOON : status;
  const logDuration = intervalToDuration({
    start: startDate,
    end: endDate,
  });
  const timePeriod = formatDuration(logDuration).toLowerCase();
  const type = determineLogType();
  const allowDownload = [LOG_STATUS.EXPIRING_SOON, LOG_STATUS.COMPLETED].includes(status);

  const startUTC = formatInTimeZone(startDate, 'UTC', DATE_FORMAT);
  const endUTC = formatInTimeZone(endDate, 'UTC', DATE_FORMAT);

  return {
    id,
    dbid,
    status: logStatus,
    start: startUTC,
    end: endUTC,
    requestedAt,
    files,
    type,
    timePeriod,
    expiresIn,
    downloadLog: allowDownload,
  };
};

export const transformLogsRequest = (request: LogsRequest) => ({
  start: request.start,
  end: request.end,
  files: request.files,
});
