import { Typography } from '@neo4j-ndl/react';
import { useAuth } from '@nx/state';
import { ControlPanel } from '@nx/ui/src/control-panel';

import { UserAvatar } from '../../components/user-avatar';

export const ProfileLayout = () => {
  const { user } = useAuth();
  const name = user?.profile.name ?? 'Unknown name';
  const email = user?.profile.email ?? 'Unknown email';
  const picture = user?.profile.picture ?? undefined;

  return (
    <div className="mx-auto mt-2 h-full max-w-[1024px] overflow-auto p-4">
      <Typography variant="h2" className="mb-6 px-2 pt-2">
        Profile
      </Typography>

      <div className="flex flex-col gap-6">
        <ControlPanel>
          <div className="flex flex-row flex-wrap items-center gap-16">
            <UserAvatar name={name} picture={picture} size="x-large" />
            <ControlPanel.Cell label="Name">
              <Typography variant="subheading-medium">{name}</Typography>
            </ControlPanel.Cell>
            <ControlPanel.Cell label="Email">
              <Typography variant="subheading-medium">{email}</Typography>
            </ControlPanel.Cell>
          </div>
        </ControlPanel>
        {/* <ControlPanel>*/}
        {/*  <Banner description="This feature is WIP and has not yet been implemented." className="mb-4" />*/}
        {/*  <ControlPanel.Row*/}
        {/*    action={*/}
        {/*      <Button*/}
        {/*        fill="outlined"*/}
        {/*        color="danger"*/}
        {/*        onClick={() => {*/}
        {/*          // eslint-disable-next-line no-alert*/}
        {/*          alert('This feature is WIP and has not yet been implemented.');*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        Suspend account*/}
        {/*      </Button>*/}
        {/*    }*/}
        {/*    title="Account suspension"*/}
        {/*    description="You can permanently suspend your account"*/}
        {/*  ></ControlPanel.Row>*/}
        {/* </ControlPanel>*/}
      </div>
    </div>
  );
};
