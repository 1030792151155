import { Banner, Label, Radio, Select, Typography } from '@neo4j-ndl/react';
import { PROJECT_BILLING_METHOD, useActiveProject } from '@nx/state';
import type { CLOUD_PROVIDER, InstanceSize, TIER } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import Big from 'big.js';
import cn from 'classnames';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { components } from 'react-select';

import { calcMonthlyCost, formatDollars } from '../../utils';
import {
  friendlyCloudProviderName,
  gbStringToInt,
  isSizeAvailableForFormData,
  sizeUnavailableReason,
} from '../entities/helpers';
import type { InstanceSizeFormData } from '../entities/model';

export interface InstanceSizePickerProps {
  data: InstanceSizeFormData;
  options: InstanceSize[];
  hidePricing?: boolean;
  hideMonthlyCost?: boolean;
  errorMessage?: string;
  onChange: (value: InstanceSize) => void;
  initialSize?: InstanceSize;
  resizeThreshold?: number;
  tier?: TIER;
}

interface InstanceSizeOption {
  size: InstanceSize;
  title: string;
  isDisabled: boolean;
}

type CompressedInstanceSize = Pick<InstanceSize, 'memory' | 'cpu'> & {
  // A compressed size should not be a mix of trial and non trial sizes
  isTrial: boolean;
  // Is true if all options are disabled
  isDisabled: boolean;
  instanceSizeOptions: InstanceSizeOption[];
};

const getRowTestIdSuffix = (size: CompressedInstanceSize): string => {
  return `${gbStringToInt(size.memory)}${size.isTrial ? '-trial' : ''}`;
};

const getPriceDifference = (compressedSize: CompressedInstanceSize, size: InstanceSize) => {
  const options = compressedSize.instanceSizeOptions.sort(
    (a, b) => gbStringToInt(a.size.storage) - gbStringToInt(b.size.storage),
  );

  const index = options.findIndex((o) => size.sizeId === o.size.sizeId);
  if (index === -1) {
    return '';
  }

  if (index === 0) {
    return 'Included';
  }

  const [baseOption] = options;
  if (isNullish(baseOption)) {
    throw new Error('No base option');
  }
  const diff = Big(size.costPerHour).sub(Big(baseOption.size.costPerHour));
  return `+${formatDollars(diff.toString())}/hour`;
};

const getDefaultInstanceSizeOption = (compressedSize: CompressedInstanceSize): InstanceSizeOption => {
  const sortedOptions = compressedSize.instanceSizeOptions;
  sortedOptions.sort((a, b) => gbStringToInt(a.size.storage) - gbStringToInt(b.size.storage));
  const enabledOptions = sortedOptions.filter((is) => !is.isDisabled);
  for (const option of enabledOptions) {
    if (gbStringToInt(compressedSize.memory) * 2 === gbStringToInt(option.size.storage)) {
      return option;
    }
  }

  if (enabledOptions.length > 0) {
    const [enabledOption] = enabledOptions;
    if (isNullish(enabledOption)) {
      throw new Error('Enabled size is undefined');
    }
    return enabledOption;
  }

  if (sortedOptions.length === 0) {
    throw new Error('No storage sizes');
  }

  const [firstOption] = sortedOptions;
  if (isNullish(firstOption)) {
    throw new Error('Storage size undefined');
  }

  return firstOption;
};

interface PricingInfoProps {
  storageSize: InstanceSize;
  cloudProvider: CLOUD_PROVIDER | undefined;
  isPrepaidProject: boolean;
  hideMonthlyCost: boolean;
}

const PricingInfo = ({ storageSize, cloudProvider, isPrepaidProject, hideMonthlyCost }: PricingInfoProps) => {
  const { isTrial, isPrepaidOnly, sizeId } = storageSize;
  if (isNotNullish(cloudProvider) && storageSize.cloudProvider !== cloudProvider) {
    return (
      <Typography variant="body-medium" htmlAttributes={{ 'data-testid': `${sizeId}-not-available-in-cp` }}>
        This size is not available in {friendlyCloudProviderName(cloudProvider)}
      </Typography>
    );
  }
  if (isPrepaidOnly && !isPrepaidProject) {
    return (
      <Typography variant="body-medium" htmlAttributes={{ 'data-testid': `${sizeId}-prepaid-only` }}>
        Only available with prepaid billing
      </Typography>
    );
  }
  if (isTrial) {
    return (
      <Label className="lowercase" color="info" fill="outlined">
        14 day free trial
      </Label>
    );
  }
  return (
    <Typography variant="body-medium" as="div">
      {formatDollars(storageSize.costPerHour)}
      /hour {!hideMonthlyCost && `(${formatDollars(calcMonthlyCost(storageSize.costPerHour))}/month)`}
    </Typography>
  );
};

interface StorageSelectProps {
  compressedSize: CompressedInstanceSize;
  storageOption: InstanceSizeOption;
  disabled: boolean;
  onChange: (size: InstanceSizeOption, selectRow?: boolean) => void;
}

const StorageSelect = ({ compressedSize, storageOption, disabled, onChange }: StorageSelectProps) => {
  const rowOptions = compressedSize.instanceSizeOptions.map((option) => ({
    label: option.size.storage,
    value: option,
    isDisabled: option.isDisabled,
    title: option.title,
  }));

  const selectedRowOption = rowOptions.find((o) => o.value.size.storage === storageOption.size.storage);
  const biggestStorageSize = rowOptions.reduce(
    (acc, curr) => (gbStringToInt(curr.value.size.storage) > acc ? gbStringToInt(curr.value.size.storage) : acc),
    0,
  );

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className="flex max-w-32 flex-col gap-1"
    >
      <Select<{ label: string; value: InstanceSizeOption; isDisabled: boolean; title?: string }>
        type="select"
        size="small"
        isFluid
        aria-label="Select a storage size"
        htmlAttributes={{
          'aria-label': 'Select a storage size',
          'data-testid': `size-select-${getRowTestIdSuffix(compressedSize)}`,
        }}
        selectProps={{
          'aria-label': 'Select a storage size',
          // This placheolder only shows when disabled cause we set the value to null if all sizes
          // are not available
          placeholder: 'No valid sizes',
          menuPosition: 'fixed',
          options: rowOptions,
          components: {
            // Override the component to add a title to the disabled options
            Option: (optionProps) => (
              <components.Option
                {...optionProps}
                className={`ndl-select-option ${optionProps.className} min-w-48 justify-between p-1`}
                innerProps={{
                  ...optionProps.innerProps,
                  title: optionProps.data.title,
                }}
              >
                <Typography
                  variant="body-medium"
                  className={optionProps.data.isDisabled ? 'text-neutral-text-weakest' : ''}
                >
                  {optionProps.data.label}
                </Typography>
                <Typography
                  variant="body-small"
                  className={optionProps.data.isDisabled ? 'text-neutral-text-weakest' : 'text-neutral-text-weaker'}
                >
                  {getPriceDifference(compressedSize, optionProps.data.value.size)}
                </Typography>
              </components.Option>
            ),
          },
          value: disabled ? null : selectedRowOption,
          onChange: (option) => {
            if (!isNullish(option)) {
              onChange(option.value);
            }
          },
        }}
      />
      <Typography variant="body-small">Max storage: {biggestStorageSize}GB</Typography>
    </div>
  );
};

interface CompressedSizeRowProps {
  compressedSize: CompressedInstanceSize;
  value: InstanceSize | undefined;
  hidePricing: boolean;
  hideMonthlyCost: boolean;
  cloudProvider: CLOUD_PROVIDER | undefined;
  isPrepaidProject: boolean;
  onChange: (size: InstanceSize) => void;
}

const CompressedSizeRow = ({
  compressedSize,
  value,
  hidePricing,
  hideMonthlyCost,
  cloudProvider,
  isPrepaidProject,
  onChange,
}: CompressedSizeRowProps) => {
  const [storageOptionSizeId, setStorageOptionSizeId] = useState<string>(
    () => getDefaultInstanceSizeOption(compressedSize).size.sizeId,
  );

  const storageOption = useMemo(() => {
    const option = compressedSize.instanceSizeOptions.find((o) => o.size.sizeId === storageOptionSizeId);
    if (isNullish(option)) {
      throw new Error(`Could not find selected option with sizeId ${storageOptionSizeId}`);
    }
    return option;
  }, [compressedSize, storageOptionSizeId]);

  const disabled = compressedSize.isDisabled;
  const selected = compressedSize.memory === value?.memory;

  const { title } = storageOption;

  const handleChange = () => {
    if (disabled || isNullish(storageOption)) {
      return;
    }
    onChange(storageOption.size);
  };

  const handleStorageChange = (newStorageOption: InstanceSizeOption) => {
    if (disabled) {
      return;
    }

    setStorageOptionSizeId(newStorageOption.size.sizeId);
    onChange(newStorageOption.size);
  };

  // Set new selected option if the storage option becomes disabled
  useEffect(() => {
    if (storageOption.isDisabled) {
      let newSelectedOption = compressedSize.instanceSizeOptions.find((o) => !o.isDisabled);
      if (!newSelectedOption) {
        newSelectedOption = compressedSize.instanceSizeOptions[0];
      }

      if (isNullish(newSelectedOption)) {
        throw new Error("Couldn't find option to select");
      }

      setStorageOptionSizeId(newSelectedOption.size.sizeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageOption]);

  const showStorageSelect = selected && compressedSize.instanceSizeOptions.length > 1;

  const selectComponent = () => {
    // Just return the default selected value as the storage value with no select dropdown
    // if only one item
    if (!showStorageSelect) {
      return (
        <Typography
          variant="body-medium"
          htmlAttributes={{
            'data-testid': `row-storage-${gbStringToInt(storageOption.size.memory)}-${gbStringToInt(storageOption.size.storage)}`,
          }}
        >
          {storageOption.size.storage}
        </Typography>
      );
    }

    return (
      <StorageSelect
        compressedSize={compressedSize}
        storageOption={storageOption}
        disabled={disabled}
        onChange={handleStorageChange}
      />
    );
  };

  const classes = cn('hover:bg-neutral-hover flex !min-h-14 flex-1', {
    'cursor-not-allowed': disabled,
    'cursor-pointer': !disabled,
    'items-start': showStorageSelect,
    'items-center': !showStorageSelect,
  });

  const cellClasses = cn({
    'pb-1 pt-3': showStorageSelect,
  });

  const radioClasses = cn({
    'pt-4': showStorageSelect,
  });

  return (
    <div
      key={compressedSize.memory}
      className={classes}
      aria-label={title}
      aria-disabled={disabled}
      title={title}
      onClick={handleChange}
    >
      <div className={`flex-1 justify-start pl-8 ${radioClasses}`}>
        <Radio
          isDisabled={disabled}
          isChecked={selected}
          onChange={handleChange}
          ariaLabel={title}
          htmlAttributes={{
            'data-testid': `size-radio-${getRowTestIdSuffix(compressedSize)}`,
          }}
        />
      </div>
      <div className={`flex-[2] ${cellClasses}`}>
        <Typography variant="body-medium">{compressedSize.memory}</Typography>
      </div>
      <div className={`flex-[2] ${cellClasses}`}>
        <Typography variant="body-medium">{compressedSize.cpu} CPU</Typography>
      </div>
      <div className={`flex-[2] ${cellClasses}`}>{selectComponent()}</div>
      {!hidePricing && (
        <div className={`flex-[2] ${cellClasses}`}>
          <PricingInfo
            hideMonthlyCost={hideMonthlyCost}
            storageSize={storageOption.size}
            cloudProvider={cloudProvider}
            isPrepaidProject={isPrepaidProject}
          />
        </div>
      )}
    </div>
  );
};

export const InstanceSizePicker = memo((props: InstanceSizePickerProps) => {
  const {
    data,
    options,
    hidePricing = false,
    onChange,
    errorMessage,
    hideMonthlyCost = false,
    initialSize,
    resizeThreshold = 0,
    tier,
  } = props;
  const activeProject = useActiveProject();
  const isPrepaidProject = activeProject.billingMethod === PROJECT_BILLING_METHOD.PREPAID;
  const isPrepaidOnlyOptionSelected = options.find((opt) => opt === data.size && opt.isPrepaidOnly);
  const sizeAvailabilityOptions = useMemo(() => ({ initialSize, resizeThreshold }), [initialSize, resizeThreshold]);

  const isSizeAvailable = useCallback(
    (size: InstanceSize) => {
      return isSizeAvailableForFormData({ ...data, size }, activeProject, options, sizeAvailabilityOptions);
    },
    [sizeAvailabilityOptions, activeProject, options, data],
  );

  const buildContactUrl = () => {
    const contactUrlTierMap = {
      free: 'free',
      mte: 'business-critical',
      professional: 'pro',
      gds: 'gds',
      enterprise: 'vdc',
      dsenterprise: 'dsenterprise',
    };
    let params = '';

    if (isNotNullish(tier) && isNotNullish(data.size)) {
      params = `?about_me=${contactUrlTierMap[tier]}-${data.size.cloudProvider}-${data.size.memory}`;
    }

    return `https://neo4j.com/contact-us/${params}`;
  };

  const getAriaLabel = useCallback(
    (instanceSize: InstanceSize) => {
      return `${instanceSize.memory} memory with ${instanceSize.cpu} CPU and ${instanceSize.storage} storage${
        isNullish(hidePricing) ? ` for ${formatDollars(instanceSize.costPerHour)} per hour` : ''
      }.`;
    },
    [hidePricing],
  );

  const getTitleForInstanceSize = useCallback(
    (size: InstanceSize) => {
      const reason = sizeUnavailableReason({ ...data, size }, activeProject, options, sizeAvailabilityOptions);
      if (isNotNullish(reason)) {
        return reason;
      }

      return getAriaLabel(size);
    },
    [getAriaLabel, sizeAvailabilityOptions, activeProject, options, data],
  );

  const compressedSizes = useMemo(
    () =>
      Object.values(
        options.reduce<Record<string, CompressedInstanceSize>>((prev, instanceSize) => {
          if (instanceSize.memory in prev) {
            const existingItem = prev[instanceSize.memory];
            if (isNotNullish(existingItem)) {
              if (existingItem.isTrial !== instanceSize.isTrial) {
                // Trial sizes need to be kept separate from trial sizes
                // in the compressed size object
                throw new Error('A mixture of trial and non trial sizes was found');
              }

              const newOption: InstanceSizeOption = {
                size: instanceSize,
                isDisabled: !isSizeAvailable(instanceSize),
                title: getTitleForInstanceSize(instanceSize),
              };

              const updatedItem: CompressedInstanceSize = {
                ...existingItem,
                isDisabled: !newOption.isDisabled ? false : existingItem.isDisabled,
                instanceSizeOptions: [...existingItem.instanceSizeOptions, newOption],
              };
              return {
                ...prev,
                [instanceSize.memory]: updatedItem,
              };
            }
          }

          const newOption: InstanceSizeOption = {
            size: instanceSize,
            isDisabled: !isSizeAvailable(instanceSize),
            title: getTitleForInstanceSize(instanceSize),
          };

          const newItem: CompressedInstanceSize = {
            memory: instanceSize.memory,
            cpu: instanceSize.cpu,
            isTrial: instanceSize.isTrial,
            isDisabled: newOption.isDisabled,
            instanceSizeOptions: [newOption],
          };
          return {
            ...prev,
            [instanceSize.memory]: newItem,
          };
        }, {}),
      ),
    [options, getTitleForInstanceSize, isSizeAvailable],
  );

  return (
    <div className="flex flex-col">
      <div className="flex">
        <div className="flex-1 pl-8"></div>
        <Typography variant="body-large" className="flex-[2]">
          Memory
        </Typography>
        <Typography variant="body-large" className="flex-[2]">
          CPU
        </Typography>
        <Typography variant="body-large" className="flex-[2]">
          Storage
        </Typography>
        {!hidePricing && (
          <Typography variant="body-large" className="flex-[2]">
            Price
          </Typography>
        )}
      </div>
      {compressedSizes.map((compressedSize) => (
        <>
          <CompressedSizeRow
            // Add tier and cloud provider to the keys so that
            // size rows are unmounted and lose their state
            // when changing cloud provider or tier
            key={`${data.tier}-${data.cloudProvider}-${compressedSize.memory}`}
            compressedSize={compressedSize}
            value={data.size}
            onChange={onChange}
            hidePricing={hidePricing}
            hideMonthlyCost={hideMonthlyCost}
            isPrepaidProject={isPrepaidProject}
            cloudProvider={data.cloudProvider}
          />
          <div className="border-neutral-border-weak border-b" />
        </>
      ))}
      {isPrepaidOnlyOptionSelected && !isPrepaidProject && (
        <Banner
          className="mt-6"
          type="warning"
          title="Prepaid billing required"
          actions={[
            {
              href: buildContactUrl(),
              label: 'Contact sales',
              htmlAttributes: { target: '_blank' },
            },
          ]}
          hasIcon
          usage="inline"
        >
          <Typography variant="body-medium">
            Large database sizing options are only available through prepaid billing. Please contact our sales team to
            proceed.
          </Typography>
        </Banner>
      )}
      {!isNullish(errorMessage) && (
        <Banner type="danger" className="mt-2" usage="inline">
          {errorMessage}
        </Banner>
      )}
    </div>
  );
});

InstanceSizePicker.displayName = 'InstanceSizePicker';
