import { Button, Switch, Typography } from '@neo4j-ndl/react';
import { KeyIconOutline } from '@neo4j-ndl/react/icons';
import type { SSOProviderOriginal } from '@nx/constants';
import { authLog, persistor, ssoSigninRedirect, useConnection, useModalClose } from '@nx/state';
import type { SyntheticEvent } from 'react';
import React from 'react';

import { DividerWithLabel } from '../../divider-with-label/divider-with-label';
import type { ConnectionFormValues } from '../connection-form.types';

export const ConnectionFormSso = ({
  formValues,
  providers,
  showProviders,
  toggleShowProviders,
}: {
  formValues: ConnectionFormValues;
  providers: SSOProviderOriginal[];
  showProviders?: boolean;
  toggleShowProviders?: () => void;
}) => {
  const closeModal = useModalClose();
  const { provisionSso } = useConnection();

  const handleProviderSubmit = async (event: SyntheticEvent, provider: SSOProviderOriginal) => {
    event.preventDefault();

    provisionSso({ url: `${formValues.protocol}//${formValues.hostname}` });

    // Here we're ensuring that the connection data is saved to the store by calling flush before we redirect
    // to the SSO login page by calling ssoSigninRedirect, otherwise the connection will fail.
    await persistor.flush();

    // The modal is closed here so ssoSigninRedirect will persist the background
    // URL instead of the URL to the connection form, before redirecting the user.
    closeModal();

    // This is where we redirect the user.
    ssoSigninRedirect(provider).catch((error: Error) => {
      authLog(error.message, 'error');
    });
  };

  return (
    <>
      {showProviders !== undefined && (
        <div className="flex w-full gap-x-5">
          <Switch
            isDisabled={providers.length === 0}
            onChange={toggleShowProviders}
            label="Connect with SSO"
            htmlAttributes={{
              checked: showProviders,
            }}
          />
        </div>
      )}
      {((toggleShowProviders !== undefined && showProviders === true) || showProviders === undefined) &&
        providers.length !== 0 && (
          <>
            {providers.map((provider) => (
              <Button
                className="w-full justify-start rounded-lg"
                size="large"
                color="neutral"
                fill="outlined"
                key={provider.name}
                onClick={(event) => {
                  void handleProviderSubmit(event, provider);
                }}
              >
                <KeyIconOutline className="text-palette-neutral-icon h-6 w-6" />
                <Typography variant="body-medium" className="text-palette-neutral-text-default ml-4">
                  Connect with {provider.name}
                </Typography>
              </Button>
            ))}
            {showProviders && <DividerWithLabel label="or" />}
          </>
        )}
    </>
  );
};
