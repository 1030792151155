import { Banner, Button, DataGrid, DataGridComponents, IconButton, Typography } from '@neo4j-ndl/react';
import { FunnelIconOutline } from '@neo4j-ndl/react/icons';
import { useAppSelector } from '@nx/state';
import { DataGridHelpers, SearchField } from '@nx/ui';
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Link } from 'react-router-dom';

import { formatDollars } from '../../../utils';
import type { TransformedBreakdownItem } from '../entities/usage';
import { MOCK_USAGE } from '../mock-data';

const columnHelper = createColumnHelper<TransformedBreakdownItem>();

const columns = [
  columnHelper.accessor('name', {
    header: 'Instance',
    cell: ({ getValue, row }) => (
      /**
       * TODO: Navigate to project instances page or instance details page (if the instance still exists).
       * Set active project id and active instance here before navigation or after (parsed from url)
       * Or, open instance billing details page
       */
      <Link to={`/projects/${row.original.projectId}/instances`}>
        <Typography variant="body-medium" className="text-primary-text truncate">
          {getValue()}
        </Typography>
      </Link>
    ),
  }),
  columnHelper.accessor('dbid', {
    header: 'ID',
  }),
  columnHelper.accessor('projectName', {
    header: 'Project',
  }),
  columnHelper.accessor('consumptionUnits', {
    // 'Usage (GB-hour(s))'
    header: 'Usage (Hours)',
  }),
  columnHelper.accessor('unitCostInDollars', {
    // 'Unit price (Per GB-hour)'
    header: 'Unit price (GB/hour)',
  }),
  columnHelper.accessor('cost', {
    header: 'Amount',
    cell: (cx) => formatDollars(cx.getValue()),
    enableResizing: false,
  }),
  columnHelper.display({
    id: 'actions',
    cell: (cell) => <DataGridComponents.RowActionCell cell={cell} />,
    meta: {
      isActionCell: {
        actions: [{ title: 'Usage breakdown item action 1' }, { title: 'Usage breakdown item action 1' }],
      },
    },
  }),
];

export function Usage() {
  const usage = useAppSelector(() => MOCK_USAGE);
  const { totalCost, discount, breakdown = [] } = usage;

  let outstandingAmount = parseFloat(totalCost);
  if (discount) {
    outstandingAmount -= parseFloat(discount.dollarAmountOff);
  }

  const table = useReactTable({
    columns,
    data: breakdown,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    columnResizeMode: 'onChange',
  });

  if (breakdown.length === 0) {
    return (
      <Banner
        type="info"
        hasIcon
        className="my-4"
        usage="inline"
        name="notes"
        htmlAttributes={{
          'data-testid': 'billing-usage-report-no-data',
        }}
      >
        There is no usage data for this month.
      </Banner>
    );
  }

  return (
    <DataGridHelpers.Wrapper tabbed>
      <DataGridHelpers.OuterHeader>
        <div className="flex w-full flex-wrap items-center justify-between gap-x-12 gap-y-2">
          <div className="mr-auto flex basis-[400px] gap-2">
            <SearchField className="min-w-36 grow" />
            <IconButton ariaLabel="Filter">
              <FunnelIconOutline />
            </IconButton>
          </div>

          <Typography variant="body-medium" className="text-neutral-text-weaker">
            {outstandingAmount < 0 ? 'In-credit balance so far this month:' : 'Total amount due this month:'}{' '}
            <span className="font-bold">{formatDollars(Math.abs(outstandingAmount))}</span>
          </Typography>

          <Button size="medium" color="neutral" fill="outlined" className="">
            Export
          </Button>
        </div>
      </DataGridHelpers.OuterHeader>
      <DataGrid
        isResizable={true}
        tableInstance={table}
        styling={{ headerStyle: 'clean' }}
        isKeyboardNavigable={false}
      />
    </DataGridHelpers.Wrapper>
  );
}
