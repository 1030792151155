import type { GuideMetadataProps } from '../../modals/guides/types';

export type CardStatus = 'coming-soon' | 'recommended' | 'completed';

export function getCardStatus(
  recommendedId: GuideMetadataProps['id'] | null,
  guide: GuideMetadataProps,
): CardStatus | undefined {
  if (guide.comingSoon) {
    return 'coming-soon';
  }

  if (recommendedId !== null && guide.id === recommendedId) {
    return 'recommended';
  }

  if (guide.progress === 100) {
    return 'completed';
  }

  return undefined;
}
