import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { CmiScrapeConfig } from './ops/api-types';
import { CMI_METRICS_GRANULARITY } from './ops/api-types';

export interface OpsState {
  selectedInstanceId: string | undefined;
  scrapeConfig?: CmiScrapeConfig;
}

const initialState: OpsState = {
  selectedInstanceId: undefined,
};

const opsContextSlice = createSlice({
  name: 'opsContext',
  initialState,
  reducers: {
    instanceSelected(state: OpsState, action: PayloadAction<OpsState['selectedInstanceId']>) {
      state.selectedInstanceId = action.payload;
    },
    initScrapeConfig(state: OpsState, action: PayloadAction<CmiScrapeConfig>) {
      state.scrapeConfig = action.payload;
    },
    updateProjectMetricsGranularity(state: OpsState, action: PayloadAction<CMI_METRICS_GRANULARITY>) {
      if (state.scrapeConfig) {
        state.scrapeConfig.metricsGranularity = action.payload;
        // update any instance granularity to project's
        state.scrapeConfig.instances.forEach((i) => {
          i.metricsGranularity = state.scrapeConfig?.metricsGranularity ?? CMI_METRICS_GRANULARITY.BASIC;
        });
      }
    },
    updateInstanceMetricsGranularity(
      state: OpsState,
      action: PayloadAction<{ granularity: CMI_METRICS_GRANULARITY; instanceId: string }>,
    ) {
      const { granularity, instanceId } = action.payload;
      if (state.scrapeConfig) {
        const instance = state.scrapeConfig.instanceEndpoints.find((i) => i.config.instanceId === instanceId);
        if (instance) {
          instance.config.metricsGranularity = granularity;
        } else {
          state.scrapeConfig.instanceEndpoints.push({
            config: {
              instanceId: instanceId,
              metricsGranularity: granularity,
              enableForNewDatabases: false,
              databases: ['neo4j'],
            },
          });
        }
      }
    },
    updateProjectMetricsEnableForNewInstances(state: OpsState, action: PayloadAction<boolean>) {
      if (state.scrapeConfig) {
        state.scrapeConfig.enableForNewInstances = action.payload;
      }
    },
    updateProjectMetricsInstanceConfigs(
      state: OpsState,
      action: PayloadAction<{ instanceId: string; enabled: boolean }>,
    ) {
      const { instanceId, enabled } = action.payload;
      if (state.scrapeConfig) {
        const instanceIndex = state.scrapeConfig.instances.findIndex((i) => i.instanceId === instanceId);
        if (instanceIndex > -1 && !enabled) {
          state.scrapeConfig.instances.splice(instanceIndex, 1);
        } else if (instanceIndex === -1 && enabled) {
          state.scrapeConfig.instances.push({
            instanceId: instanceId,
            metricsGranularity: state.scrapeConfig.metricsGranularity,
            enableForNewDatabases: false,
            databases: ['neo4j'],
          });
        }
      }
    },
    resetScrapeConfig(state: OpsState, action: PayloadAction<CmiScrapeConfig>) {
      state.scrapeConfig = action.payload;
    },
  },
});

export const {
  instanceSelected: setSelectedInstance,
  initScrapeConfig,
  updateInstanceMetricsGranularity,
  updateProjectMetricsGranularity,
  updateProjectMetricsEnableForNewInstances,
  updateProjectMetricsInstanceConfigs,
  resetScrapeConfig,
} = opsContextSlice.actions;
export default opsContextSlice.reducer;
