import type { IMPORT_EVENTS } from '@nx/analytics-service';
import { FRAMEWORK_EVENTS } from '@nx/analytics-service';
import { addListener, createListenerMiddleware } from '@reduxjs/toolkit';
import type { Dispatch, TypedAddListener, TypedStartListening } from '@reduxjs/toolkit';

import { track } from '../../analytics/track';
import { addNode, addNodeAndRelationship, addRelationship, clearModel } from '../actions/common-actions';
import {
  removeTableSchema,
  reverseRelationship,
  setNodeKeyProperty,
  setRelationshipSourceMapping,
  setRelationshipTargetMapping,
} from '../reducers/data-model';
import type { RootState } from '../store';

const eventsToSend = [
  addNode.type,
  addNodeAndRelationship.type,
  addRelationship.type,
  clearModel.type,
  removeTableSchema.type,
  reverseRelationship.type,
  setNodeKeyProperty.type,
  setRelationshipSourceMapping.type,
  setRelationshipTargetMapping.type,
  FRAMEWORK_EVENTS.TRIGGER_WELCOME_APPCUES,
];

export const trackingListenerMiddleware = createListenerMiddleware();

export const startAppListening: TypedStartListening<RootState, Dispatch> =
  trackingListenerMiddleware.startListening.withTypes<RootState, Dispatch>();

export const addAppListener: TypedAddListener<RootState, Dispatch> = addListener.withTypes<RootState, Dispatch>();

trackingListenerMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    const { type } = action;
    return eventsToSend.includes(type);
  },
  effect: (action, listenerApi) => {
    const { type } = action;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    track(type as IMPORT_EVENTS);
  },
});
