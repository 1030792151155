import { APP_SCOPE } from '@nx/constants';
import { SIGNAL_NAME, signals } from '@nx/signals';

import { useNxNavigate } from '../hooks/use-nx-navigate';

// to be replaced by NDL version
type SmallRunnableCodeblockProps = { code: string; onClick?: () => void; className?: string };
export const SmallRunnableCodeblock = ({ code, onClick, className = '' }: SmallRunnableCodeblockProps) => {
  return (
    <button
      onClick={onClick}
      className={`bg-palette-neutral-bg-default code text-palette-primary-icon hover:bg-palette-neutral-bg-strong hover:text-palette-primary-hover-strong active:bg-palette-neutral-bg-strong active:text-palette-primary-pressed-strong
my-1 h-fit w-fit min-w-fit
rounded-sm px-1
font-mono font-medium ${className}`}
    >
      {code}
    </button>
  );
};

type RunnableCodeInlinedProps = {
  code: string;
};
export const RunnableCodeInlined = ({ code }: RunnableCodeInlinedProps) => {
  const navigate = useNxNavigate();

  const runCommandAndOpenQueryPage = async (cmd: string) => {
    navigate(APP_SCOPE.query);
    await signals.emit(SIGNAL_NAME.QueryExecuteQueryCommands, { query: cmd });
  };
  const handleRunCommand = (cmd: string) => {
    runCommandAndOpenQueryPage(cmd)
      // eslint-disable-next-line no-console
      .catch(console.error);
  };
  return <SmallRunnableCodeblock code={code} onClick={() => handleRunCommand(code)} />;
};
