import { FRAMEWORK_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import type { CypherProperty, Neo4jType } from '@nx/neo4j-sdk';
import { ParamErrors, getPropertyTypeDisplayName, serializeTypeAnnotations } from '@nx/neo4j-sdk';
import { Objects } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';

import { NoDatabaseSelectedError, type RunQueryConfig } from '../adapters/neo4j-driver-adapter';
import { createAsyncThunk } from '../context';
import { trackEvent } from '../middlewares/analytics-events';
import { selectEnabledTools } from './capabilities-slice';
import { selectActiveDatabaseName } from './connections';

type ParsedParamArguments =
  | { arg: 'set-single'; paramName: string; expression: string }
  | { arg: 'set-map'; mapExpression: string }
  | { arg: 'show' | 'clear' }
  | { arg: 'error'; error: SerializedError };

export const evaluateParameters = createAsyncThunk(
  'connections/evaluateParameters',
  async (
    payload: { params: ParsedParamArguments } & Pick<RunQueryConfig, 'metadata'>,
    { extra, signal, getState, dispatch },
  ): Promise<Record<string, unknown>> => {
    if (payload.params.arg !== 'set-map' && payload.params.arg !== 'set-single') {
      throw ParamErrors.InvalidMultistatementArgument;
    }

    const database = selectActiveDatabaseName(getState());

    if (database === undefined) {
      throw new NoDatabaseSelectedError();
    }

    const expression = payload.params.arg === 'set-single' ? payload.params.expression : payload.params.mapExpression;

    const result = await extra.driver.runQuery(
      { query: `RETURN ${expression}` },
      {
        metadata: payload.metadata,
        recordLimit: 1,
        signal,
        transactionMode: 'read',
        sessionConfig: { database },
      },
      selectEnabledTools(getState()),
    );

    const [record] = result.records;
    if (record === undefined) {
      throw ParamErrors.MissingExpression;
    }

    const resultEntries = Object.values(record.toObject());
    if (resultEntries.length === 1) {
      // Cast to Neo4jType as full validation is complicated
      const parameters =
        payload.params.arg === 'set-single'
          ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            { [payload.params.paramName]: resultEntries[0] as CypherProperty }
          : // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            (resultEntries[0] as Record<string, CypherProperty>);

      const typesOfParamsSet = Object.values(parameters).map(getPropertyTypeDisplayName);

      dispatch(
        trackEvent({
          event: FRAMEWORK_EVENTS.EVALUATE_PARAMETERS,
          properties: { typesOfParamsSet },
          scope: APP_SCOPE.framework,
        }),
      );

      // To be able to put params in redux without breaking localstorage / redux best practises
      // we serialize the parameters before they hit the reducer via the serialization method
      // that the webworker uses to pass data to the main thread.
      return Objects.mapValues(parameters, (paramValue) =>
        // neo4j/arc & nx/shared neo4j types are not TS compatible, but deal with the same values
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        serializeTypeAnnotations(paramValue as Neo4jType),
      );
    }

    throw ParamErrors.ExtraStatementError(resultEntries.length);
  },
);
