import { Button, Dialog, TextInput } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { type LocalInstance, useCloneLocalInstanceMutation } from '@nx/state';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';

const logger = createLogger(APP_SCOPE.framework);

interface Props {
  onClose: () => void;
  onConfirm: () => void;
  instance: LocalInstance;
}

export const CloneInstanceModal = ({ onClose, onConfirm, instance }: Props) => {
  const { name } = instance;
  const [newName, setNewName] = useState(`${name} clone`);
  const [clone, cloneResponse] = useCloneLocalInstanceMutation();

  const handleNewNameChange = (value: string) => {
    setNewName(value);
  };

  const handleClose = () => {
    onClose();
    setNewName('');
  };

  const handleDelete = (event: SyntheticEvent) => {
    event.preventDefault();
    clone({ dbmsId: instance.id, newName })
      .unwrap()
      .then(() => {
        onConfirm();
      })
      .catch((error) => logger.error(error));
  };

  return (
    <Dialog type="info" isOpen onClose={handleClose} size="large">
      <form onSubmit={handleDelete}>
        <Dialog.Header>Clone {name}</Dialog.Header>
        <Dialog.Subtitle>
          This action will create a copy of the selected instance with the new chosen name.
        </Dialog.Subtitle>
        <Dialog.Description>
          <TextInput
            label="Type the name of the new instance"
            value={newName}
            onChange={(e) => handleNewNameChange(e.target.value)}
            placeholder="E.g. InstanceName"
            htmlAttributes={{ autoFocus: true }}
          />
        </Dialog.Description>
        <Dialog.Actions>
          <Button color="neutral" fill="outlined" onClick={handleClose} isDisabled={cloneResponse.isLoading}>
            Cancel
          </Button>
          <Button type="submit" isDisabled={newName.trim() === ''} isLoading={cloneResponse.isLoading}>
            Clone
          </Button>
        </Dialog.Actions>
      </form>
    </Dialog>
  );
};
