import { Button, Typography } from '@neo4j-ndl/react';
import { type PropsWithChildren, useEffect, useRef, useState } from 'react';

import HighPerformanceSvg from '../../assets/high-performance.svg';
import './overlay.css';

/** Blurs out and reduces opacity of `children`. Overlays a box with an upgrade title, body and button. */
export const UpgradeOverlay = ({
  title,
  body,
  onUpgrade,
  children,
}: PropsWithChildren<{ title: string; body: string; onUpgrade: () => void }>) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const [className, setClassName] = useState('pointer-events-none opacity-40 blur-[3px]');

  useEffect(() => {
    const observer = new MutationObserver(([observed]) => {
      if (observed) {
        setClassName('rotate');
        observer.disconnect();
      }
    });
    if (overlayRef.current) {
      observer.observe(overlayRef.current, { attributes: true });
    }
    return () => observer.disconnect();
  }, []);

  return (
    <div>
      <div ref={overlayRef} id="upgrade-overlay" className={className} aria-disabled={true} {...{ inert: '' }}>
        {children}
      </div>
      <div
        className="absolute left-[50%] top-[50%] flex -translate-x-1/2 -translate-y-1/2  gap-8"
        data-testid="upgrade-overlay"
      >
        <img src={HighPerformanceSvg} className="h-[160px] w-[160px]" draggable={false} />
        <div className="flex max-w-[450px] flex-col gap-6">
          <Typography variant="h4">{title}</Typography>
          <Typography variant="body-large" className="text-palette-neutral-text-weak">
            {body}
          </Typography>
          <Button
            className="w-fit"
            onClick={onUpgrade}
            fill="filled"
            htmlAttributes={{
              tabIndex: 0,
            }}
          >
            Upgrade
          </Button>
        </div>
      </div>
    </div>
  );
};
