import { Banner, Button, LoadingSpinner, Typography } from '@neo4j-ndl/react';
import type {
  CreateLogForwarding,
  EditLogForwarding,
  LogForwarding,
  Project,
  WrappedCloudwatchDestination,
  WrappedLogAnalyticsDestination,
  WrappedStackdriverDestination,
} from '@nx/state';
import {
  ACTION,
  CLOUD_PROVIDER,
  DESTINATION_TYPE,
  consoleApi,
  useActiveProject,
  useActiveProjectQuery,
  usePermissions,
} from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import { BackButton } from '@nx/ui';
import { filter } from 'lodash-es';
import React, { useState } from 'react';

import logForwardingBlankImage from '../assets/log-forwarding.svg';
import { ConfirmDeleteModal } from './confirm-delete-modal';
import { CreateConfigModal, MAX_LOG_FWD_PROCESSES } from './create-dialog/create-config-modal';
import {
  EditCloudwatchConfigModal,
  EditLogAnalyticsConfigModal,
  EditStackdriverConfigModal,
} from './edit-config-modal';
import { getCloudProviders } from './helpers/utils';
import { useModalState } from './hooks/use-modal-state';
import { LogForwardingTable, TabbedLogForwardingTable } from './log-forwarding-table';

const LogForwardingPage = () => {
  const { data: project } = useActiveProjectQuery();
  const cloudProviders = project !== undefined ? getCloudProviders(project.tierConfigs) : [];

  const [itemToDelete, setItemToDelete] = useState<LogForwarding>();
  const [itemToEdit, setItemToEdit] = useState<LogForwarding>();
  const [cloudProviderInView, setCloudProviderInView] = useState<CLOUD_PROVIDER | undefined>(cloudProviders[0]);

  const deleteModal = useModalState();
  const createModal = useModalState();
  const editModal = useModalState();

  const {
    data: list,
    error: listError,
    refetch: refetchList,
    isLoading: listLoading,
  } = consoleApi.useListLogForwardingQuery(project?.id ?? '', {
    pollingInterval: 30000,
  });
  const {
    data: logForwardingIdentities,
    error: logForwardingIdentitiesError,
    isLoading: logForwardingIdentitiesLoading,
  } = consoleApi.useLogForwardingIdentitiesQuery(project?.id ?? '', {
    pollingInterval: 30000,
  });

  const [doCreate] = consoleApi.useLazyCreateLogForwardingQuery();

  const createLogForwarding = async (payload: CreateLogForwarding) => {
    if (isNullish(project?.id)) {
      createModal.setError(Error('Failed to fetch id'));
      return;
    }
    createModal.setLoading(true);
    await doCreate({ projectId: project.id, payload }, false)
      .unwrap()
      .then(async () => {
        await refetchList();
        createModal.close();

        let cp = undefined;
        switch (payload.destination.type) {
          default:
          case DESTINATION_TYPE.STACKDRIVER:
            cp = CLOUD_PROVIDER.GCP;
            break;
          case DESTINATION_TYPE.CLOUDWATCH:
            cp = CLOUD_PROVIDER.AWS;
            break;
          case DESTINATION_TYPE.LOG_ANALYTICS:
            cp = CLOUD_PROVIDER.AZURE;
            break;
        }
        setCloudProviderInView(cp);
      })
      .catch((err) => {
        createModal.setError({
          name: 'Create error',
          message: 'Something went wrong configuring log forwarding',
        });
      })
      .finally(() => {
        createModal.setLoading(false);
      });
  };

  const showConfirmDeletionModal = (logForwarding: LogForwarding) => {
    setItemToDelete(logForwarding);
    deleteModal.show();
  };

  const closeDeleteModal = () => {
    deleteModal.close();
    deleteModal.setError(null);
    deleteModal.setLoading(false);
  };

  const [doDelete] = consoleApi.useLazyDeleteLogForwardingQuery();

  const deleteLogForwarding = async () => {
    if (isNullish(itemToDelete?.id) || isNullish(project?.id)) {
      deleteModal.setError(Error('Failed to fetch id'));
      return;
    }
    deleteModal.setLoading(true);
    await doDelete({ projectId: project.id, logForwardingId: itemToDelete.id }, false).unwrap();
    await refetchList();
    closeDeleteModal();
  };

  const closeEditModal = () => {
    editModal.close();
    editModal.setError(null);
    editModal.setLoading(false);
  };

  const [doEdit] = consoleApi.useLazyEditLogForwardingQuery();

  const editLogForwarding = async (payload: EditLogForwarding) => {
    if (isNullish(project?.id) || isNullish(itemToEdit)) {
      editModal.setError(Error('Failed to fetch id'));
      return;
    }
    editModal.setLoading(true);
    await doEdit({ projectId: project.id, id: itemToEdit.id, payload }, false)
      .unwrap()
      .then(async () => {
        await refetchList();
        editModal.close();
      })
      .catch((err) => {
        editModal.setError({
          name: 'Edit error',
          message: 'Something went wrong editing configuration',
        });
      })
      .finally(() => {
        editModal.setLoading(false);
      });
  };

  const hasAnError = isNotNullish(listError) || isNotNullish(logForwardingIdentitiesError);
  const isLoading = listLoading || logForwardingIdentitiesLoading;
  const hasNoConfig =
    list?.length === 0 || list === undefined || logForwardingIdentities === undefined || cloudProviders.length === 0;

  const showEditModal = (logForwarding: LogForwarding) => {
    setItemToEdit(logForwarding);
    editModal.refreshKey();
    editModal.show();
  };

  const showCreateModal = () => {
    createModal.setError(null);
    createModal.refreshKey();
    createModal.show();
  };

  if (isLoading) {
    return <LoadingSpinner className="absolute left-[50%] top-[50%] self-center" size="large" />;
  }

  if (cloudProviderInView === undefined) {
    return (
      <Banner type="danger" usage="inline">
        Something went wrong. Please try again later.
      </Banner>
    );
  }

  return (
    <div className="h-full overflow-auto p-4">
      <BackButton to="../settings" />
      {hasAnError && (
        <Banner type="danger" usage="inline">
          Something went wrong. Please try again later.
        </Banner>
      )}
      {hasNoConfig ? (
        <>
          <Typography variant="h2">Log forwarding</Typography>
          <div className="text-center">
            <img src={logForwardingBlankImage} className="mx-auto" alt="Log forwarding not configured" />
            <Button
              onClick={showCreateModal}
              htmlAttributes={{
                'data-testid': 'create-log-forwarding-button',
              }}
            >
              Configure log forwarding
            </Button>
          </div>
        </>
      ) : (
        <div>
          <div className="mb-6 flex items-center justify-between px-2 pt-2">
            <Typography variant="h2">Log forwarding</Typography>
            <Button
              onClick={showCreateModal}
              htmlAttributes={{
                'data-testid': 'create-log-forwarding-button',
              }}
            >
              Create new configuration
            </Button>
          </div>
          {cloudProviders.length === 1 ? (
            <LogForwardingTable
              key={cloudProviderInView}
              data={list}
              logForwardingIdentities={logForwardingIdentities}
              cloudProvider={cloudProviderInView}
              onEdit={showEditModal}
              onDelete={showConfirmDeletionModal}
            />
          ) : (
            <TabbedLogForwardingTable
              key={cloudProviderInView}
              data={list}
              logForwardingIdentities={logForwardingIdentities}
              cloudProvider={cloudProviderInView}
              onEdit={showEditModal}
              onDelete={showConfirmDeletionModal}
              cloudProviders={cloudProviders}
              setCloudProviderInView={setCloudProviderInView}
            />
          )}
        </div>
      )}
      {logForwardingIdentities && project && (
        <CreateConfigModal
          cloudProviders={cloudProviders}
          project={project}
          onClose={createModal.close}
          onCreate={(payload) => {
            void createLogForwarding(payload);
          }}
          open={createModal.visible}
          loading={createModal.loading}
          error={createModal.error}
          key={createModal.key}
          logForwardingIdentities={logForwardingIdentities}
          hasReachedProcessLimit={(region, tier, instanceId) => {
            if (instanceId !== undefined) {
              return filter(list, { region: region, tier: tier, db_id: instanceId }).length >= MAX_LOG_FWD_PROCESSES;
            }
            return filter(list, { region: region, tier: tier }).length >= MAX_LOG_FWD_PROCESSES;
          }}
        />
      )}
      {itemToDelete && (
        <ConfirmDeleteModal
          logForwarding={itemToDelete}
          onConfirm={() => {
            void deleteLogForwarding();
          }}
          open={deleteModal.visible}
          onClose={closeDeleteModal}
          loading={deleteModal.loading}
          error={deleteModal.error}
        />
      )}
      {itemToEdit && (
        <>
          {itemToEdit.destination.type === DESTINATION_TYPE.STACKDRIVER && (
            <EditStackdriverConfigModal
              key={editModal.key}
              open={editModal.visible}
              onClose={closeEditModal}
              loading={editModal.loading}
              error={editModal.error}
              /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
              logForwarding={itemToEdit as LogForwarding<WrappedStackdriverDestination>}
              onEdit={(payload) => {
                void editLogForwarding(payload);
              }}
            />
          )}
          {itemToEdit.destination.type === DESTINATION_TYPE.LOG_ANALYTICS && (
            <EditLogAnalyticsConfigModal
              key={editModal.key}
              open={editModal.visible}
              onClose={closeEditModal}
              loading={editModal.loading}
              error={editModal.error}
              /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
              logForwarding={itemToEdit as LogForwarding<WrappedLogAnalyticsDestination>}
              onEdit={(payload) => {
                void editLogForwarding(payload);
              }}
            />
          )}
          {itemToEdit.destination.type === DESTINATION_TYPE.CLOUDWATCH && (
            <EditCloudwatchConfigModal
              key={editModal.key}
              open={editModal.visible}
              onClose={closeEditModal}
              loading={editModal.loading}
              error={editModal.error}
              /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
              logForwarding={itemToEdit as LogForwarding<WrappedCloudwatchDestination>}
              onEdit={(payload) => {
                void editLogForwarding(payload);
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

type DisabledType = {
  project: Project;
};

interface PermissionDeniedProps
  extends Omit<React.ComponentProps<typeof Banner>, 'type' | 'isCloseable' | 'onClose' | 'title' | 'description'> {
  title?: React.ComponentProps<typeof Banner>['title'];
  project: Project;
}

const NotCapable = ({ project }: DisabledType) => {
  return (
    <div className="h-full overflow-auto p-4">
      <Banner
        type="info"
        title="Missing capability"
        description={
          <div>
            <p>
              Missing capability for log forwarding in project <strong>{project.name}</strong>.
            </p>
          </div>
        }
        usage="inline"
        htmlAttributes={{
          'data-testid': 'no-lf-capability',
        }}
      />
    </div>
  );
};

const PermissionDenied = ({ title = 'Permission denied', project, ...rest }: PermissionDeniedProps) => {
  return (
    <div className="h-full overflow-auto p-4">
      <Banner
        type="warning"
        title={title}
        description={
          <div>
            <p>
              You must be an admin of the <strong>{project.name}</strong> project to configure log forwarding.
            </p>
          </div>
        }
        {...rest}
        usage="inline"
        htmlAttributes={{
          'data-testid': 'no-lf-auth',
        }}
      />
    </div>
  );
};

export const LogForwardingPageGuard = () => {
  const activeProject = useActiveProject();
  const { isAllowed } = usePermissions();
  const allowReadLogForwarding = isAllowed(ACTION.READ, `namespaces/${activeProject.id}/log-forwarding`);

  if (!activeProject.capabilities.log_forwarding && !activeProject.capabilities.single_instance_log_forwarding) {
    return <NotCapable project={activeProject} />;
  }

  if (!allowReadLogForwarding) {
    return <PermissionDenied project={activeProject} />;
  }

  return <LogForwardingPage />;
};
