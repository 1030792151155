import { difference, intersection, intersectionBy } from 'lodash-es';

export const transformMetadata = (perspective, rawMetadata) => {
  if (!perspective) return {};

  const labelsCategoryMap = {};
  let labels = [];

  const categories = perspective.categories
    .filter((category) => category.labels.length > 0)
    .map((category) => ({
      id: category.id,
      name: category.name,
      labels: category.labels,
      properties: category.properties
        .filter((property) => property.exclude !== true)
        .map((property) => ({
          propertyKey: property.name,
          dataType: property.dataType,
          type: category.name,
        })),
    }));

  if (perspective.hideUncategorisedData) {
    labels = categories.reduce((labelArray, category) => {
      const existingLabels = category.labels.filter((label) => rawMetadata.labels.includes(label));
      existingLabels.forEach((label) => {
        labelsCategoryMap[label] = category;
      });

      return labelArray.concat(existingLabels);
    }, []);
  } else {
    for (const [label, labelProperties] of Object.entries(perspective.labels)) {
      labels.push(label);
      labelsCategoryMap[label] = {
        properties: labelProperties,
      };
    }
  }

  const categoryPropertyKeys = Object.keys(rawMetadata.propertyKeys.labels)
    .filter((label) => labels.includes(label))
    .reduce((labelsPropsMap, label) => {
      const properties = rawMetadata.propertyKeys.labels[label];
      const categoryPropKeys = labelsCategoryMap[label].properties;
      labelsPropsMap[label] = intersectionBy(properties, categoryPropKeys, (prop) => prop.propertyKey);
      return labelsPropsMap;
    }, {});

  let { relationshipTypes, pathSegments } = rawMetadata;

  const filteredRelationshipTypes = difference(relationshipTypes, perspective.hiddenRelationshipTypes);

  const relationshipPropertyKeys = Object.keys(rawMetadata.propertyKeys.relationships || {})
    .filter((relationshipType) => filteredRelationshipTypes.includes(relationshipType))
    .reduce((acc, cur) => {
      acc[cur] = rawMetadata.propertyKeys.relationships[cur];
      return acc;
    }, {});

  if (filteredRelationshipTypes.length !== relationshipTypes.length && pathSegments) {
    pathSegments = pathSegments.filter((pathSegment) =>
      filteredRelationshipTypes.includes(pathSegment.relationshipType),
    );
  }

  return {
    labels,
    relationshipTypes: filteredRelationshipTypes,
    propertyKeys: {
      labels: categoryPropertyKeys,
      relationships: relationshipPropertyKeys,
    },
    indexes: filterIndexes(categoryPropertyKeys, rawMetadata.indexes),
    categories,
    pathSegments,
    searchPhrases: rawMetadata.searchPhrases,
  };
};

export const filterIndexes = (validLabelProps, indexes) => {
  if (!validLabelProps || !indexes) {
    return [];
  }

  return indexes.reduce((filteredIndexes, index) => {
    if (validLabelProps[index.label]) {
      const filteredProps = intersection(
        index.propertyKeys,
        validLabelProps[index.label].map((labelProp) => labelProp.propertyKey),
      );
      if (filteredProps.length > 0) {
        filteredIndexes.push({
          label: index.label,
          propertyKeys: filteredProps,
        });
      }
    }
    return filteredIndexes;
  }, []);
};
