import { Banner, LoadingSpinner, Typography } from '@neo4j-ndl/react';
import { getApiError, getErrorMessage, useInstances, useUnsafeAppContext } from '@nx/state';
import { isNullish } from '@nx/stdlib';
import { SearchField } from '@nx/ui';
import { useEffect, useState } from 'react';

import { InstanceList } from '../instance/instance-list';

export function InstancesDataSource({
  filter,
  onFilterChange,
}: {
  filter: string;
  onFilterChange: (filter: string) => void;
}) {
  const { activeProjectId } = useUnsafeAppContext();
  const [errorMessage, setErrorMessage] = useState('');
  const { instances, isInstancesLoading, error } = useInstances({ shouldPoll: true, skip: isNullish(activeProjectId) });

  useEffect(() => {
    if (error) {
      const e = getApiError(error);
      setErrorMessage(getErrorMessage(e));
    } else {
      setErrorMessage('');
    }
  }, [error]);

  const normalizedFilter = filter.trim().toLocaleLowerCase();
  const filteredInstances = normalizedFilter
    ? instances.filter(({ name, id, tierDisplayName, region, cloudProvider }) =>
        [name, id, tierDisplayName, region, cloudProvider].some((str) =>
          str.toLocaleLowerCase().includes(normalizedFilter),
        ),
      )
    : instances;

  if (isInstancesLoading) {
    return <LoadingSpinner size="medium" className="relative left-[50%] top-[10%]" />;
  }

  if (errorMessage) {
    return <Banner type="danger" description={errorMessage} className="m-8" usage="inline" />;
  }

  return filteredInstances.length > 0 ? (
    // eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
    <>
      <div className="mb-6">
        <SearchField
          className="ml-auto h-8 w-[320px]"
          value={filter}
          onChange={(e) => onFilterChange(e.target.value)}
          htmlAttributes={{ 'aria-label': 'Search for instances' }}
        />
      </div>

      <InstanceList type="select" instances={filteredInstances} />
    </>
  ) : (
    <div className="flex h-[218px] items-center justify-center">
      <Typography variant="body-large">No instances found</Typography>
    </div>
  );
}
