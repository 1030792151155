import { FIRST_GQL_ERRORS_SUPPORT, FIRST_GQL_NOTIFICATIONS_SUPPORT } from '@nx/neo4j-sdk/src/types/sdk-types';
import { neo4jVersionUtil } from '@nx/neo4j-version-utils';
import { useConnection, useFeatureFlag } from '@nx/state';
import { executeCypherCommandThunk } from '@query/redux/cypher-thunks';
import { cmdRan } from '@query/redux/editor-slice';
import { queryEvaluateParametersThunk } from '@query/redux/params-thunks';
import { requestDatabaseSwitch } from '@query/redux/request-database-switch';
import { framesRemove } from '@query/redux/stream-slice';
import { consumeAbortFunction } from '@query/services/abortable-frame-service';
import type { QueryError } from '@query/types/query';
import type { FormattedError } from '@query/utils/error-utils';
import { formatError, formatErrorGqlStatusObject, isQueryError } from '@query/utils/error-utils';
import type { FormattedResultSummary } from '@query/utils/status-bar-utils';
import { formatResultSummaryGqlStatusObjects, formatResultSummaryNotifications } from '@query/utils/status-bar-utils';
import { createAction, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';
import type { ResultSummary } from 'neo4j-driver-core';

import type { ParsedCommand } from './commands';
import type { RootState } from './store';
import { executeSysInfoCommandThunk } from './sysinfo-thunks';

type RequestStatus = 'idle' | 'executing' | 'canceling' | 'canceled' | 'aborting' | 'aborted' | 'error' | 'success';

type BaseRequest =
  | { status: 'idle' | 'executing' | 'canceling' | 'canceled' | 'aborting' | 'aborted' }
  | { status: 'error'; error: QueryError };

type BasicRequest = BaseRequest | { status: 'success' };

export type ParamsRequest = BaseRequest | { status: 'success'; newParameters: Record<string, unknown> };

export type Request = ParamsRequest | BasicRequest;

export type ParamsRequestId = `paramid-${string}`;
export type CypherRequestId = `cypherid-${string}`;
export type UseDbRequestId = `usedbid-${string}`;
export type SysInfoRequestId = `sysinfoid-${string}`;

export type RequestId = ParamsRequestId | CypherRequestId | UseDbRequestId | SysInfoRequestId;

export const isCypherRequestId = (requestId: string | null): requestId is CypherRequestId => {
  return requestId?.startsWith('cypherid-') ?? false;
};

export const isParamsRequestId = (requestId: string | null): requestId is ParamsRequestId => {
  return requestId?.startsWith('paramid-') ?? false;
};

export const isParamsRequest = (request?: Request): request is ParamsRequest => {
  return request?.status === 'success' && 'newParameters' in request;
};

export const isUseDbRequestId = (requestId: string | null): requestId is UseDbRequestId => {
  return requestId?.startsWith('usedbid-') ?? false;
};

export const isDefinedRequestId = (requestId: string | null): requestId is RequestId => {
  return requestId !== null;
};

export const isRequestId = (requestId: string | null): requestId is RequestId => {
  return isCypherRequestId(requestId) || isParamsRequestId(requestId) || isUseDbRequestId(requestId);
};

export const isQueryErrorPayload = (payload: unknown): payload is { error: QueryError; aborted: boolean } => {
  return (
    payload !== null &&
    typeof payload === 'object' &&
    'aborted' in payload &&
    typeof payload.aborted === 'boolean' &&
    'error' in payload &&
    isQueryError(payload.error)
  );
};

type RequestsState = {
  requests: Record<ParamsRequestId, ParamsRequest> &
    Record<CypherRequestId | UseDbRequestId | SysInfoRequestId, BasicRequest>;
};

const initialState: RequestsState = {
  requests: {},
};

export const createRequests = createAction('stream/createRequests', (parsedCmd: ParsedCommand) => ({
  payload: {
    parsedCmd,
  },
}));

export const clearRequests = createAction('stream/clearRequests', (requestIds: RequestId[]) => {
  requestIds.forEach((requestId) => {
    consumeAbortFunction(requestId)?.();
  });
  return {
    payload: requestIds,
  };
});

export type StopRequestsReason = 'cancel' | 'abort';

export const stopRequests = createAction(
  'stream/stopRequests',
  (requestIds: RequestId[], reason: StopRequestsReason) => {
    requestIds.forEach((requestId) => {
      consumeAbortFunction(requestId)?.();
    });
    return {
      payload: {
        requestIds,
        reason,
      },
    };
  },
);

export const selectRequest = createSelector(
  (state: RootState) => state.requests.requests,
  (_, requestId: RequestId) => requestId,
  (requests: RequestsState['requests'], requestId: RequestId) => requests[requestId],
);

function getCancelableRequestIds(): (state: RootState) => RequestId[] {
  return createSelector(
    (state: RootState) => state.requests.requests,
    (requests) => {
      return Object.entries(requests)
        .filter(([_, request]) => {
          return request.status === 'idle' || request.status === 'executing';
        })
        .map(([requestId]) => requestId)
        .filter(isRequestId);
    },
  );
}

function getCancelableRequestIdsByFrame(frameId: string): (state: RootState) => RequestId[] {
  return createSelector(
    (state: RootState) => state.stream.byId[frameId],
    (state: RootState) => state.requests.requests,
    (frame, requests) => {
      const requestIds = frame?.requestIds ?? [];
      return requestIds.filter(isDefinedRequestId).filter((requestId) => {
        const request = requests[requestId];
        return request?.status === 'idle' || request?.status === 'executing';
      });
    },
  );
}

export function isCancelable(frameId: string): (state: RootState) => boolean {
  return createSelector(getCancelableRequestIdsByFrame(frameId), (requestIds) => requestIds.length > 0);
}

export function cancelableRequests(): (state: RootState) => RequestId[] {
  return getCancelableRequestIds();
}

export function cancelableRequestsByFrame(frameId: string): (state: RootState) => RequestId[] {
  return getCancelableRequestIdsByFrame(frameId);
}

const asyncThunks = [
  executeCypherCommandThunk,
  queryEvaluateParametersThunk,
  requestDatabaseSwitch,
  executeSysInfoCommandThunk,
];

const requests = createSlice({
  name: 'requests',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(cmdRan, (state, action) => {
        const { parsedCmd } = action.payload;
        if (parsedCmd.type === 'clear-stream') {
          state.requests = {};
        }
      })
      .addCase(framesRemove, (state, action) => {
        const requestIds = action.payload.requestIds.map((requestId) => requestId).filter(isDefinedRequestId);
        requestIds.forEach((requestId) => {
          delete state.requests[requestId];
        });
      })
      .addCase(createRequests, (state, action) => {
        const { parsedCmd } = action.payload;
        let createdRequests = { ...state.requests };

        if (parsedCmd.requestId !== null) {
          createdRequests = { ...state.requests, [parsedCmd.requestId]: { status: 'idle' } };
        } else if ('commands' in parsedCmd) {
          createdRequests = parsedCmd.commands.reduce(
            (acc, command) => {
              if (command.parsed.requestId !== null) {
                acc[command.parsed.requestId] = {
                  status: 'idle',
                };
              }

              return acc;
            },
            { ...state.requests },
          );
        }

        return {
          ...state,
          requests: createdRequests,
        };
      })
      .addCase(stopRequests, (state, action) => {
        const { requestIds, reason } = action.payload;

        const updatedRequests = requestIds.reduce(
          (acc, requestId) => {
            const request = state.requests[requestId];

            if (request?.status === 'idle' || request?.status === 'executing') {
              let status: RequestStatus;
              if (reason === 'cancel') {
                status = request.status === 'idle' ? 'canceled' : 'canceling';
              } else {
                status = request.status === 'idle' ? 'aborted' : 'canceled';
              }

              acc[requestId] = {
                status: status,
              };
            }

            return acc;
          },
          { ...state.requests },
        );

        return {
          ...state,
          requests: updatedRequests,
        };
      })
      .addCase(clearRequests, (state, action) => {
        action.payload.forEach((requestId) => {
          delete state.requests[requestId];
        });
      })
      .addMatcher(isAnyOf(...asyncThunks.map((thunk) => thunk.pending)), (state, action) => {
        state.requests[action.meta.arg.requestId] = {
          status: 'executing',
        };
      })
      .addMatcher(isAnyOf(...asyncThunks.map((thunk) => thunk.fulfilled)), (state, action) => {
        if (isAnyOf(queryEvaluateParametersThunk.fulfilled)(action)) {
          state.requests[action.meta.arg.requestId] = {
            status: 'success',
            newParameters: action.payload.parameters,
          };
        } else {
          state.requests[action.meta.arg.requestId] = {
            status: 'success',
          };
        }
      })
      .addMatcher(isAnyOf(...asyncThunks.map((thunk) => thunk.rejected)), (state, action) => {
        const request = state.requests[action.meta.arg.requestId];
        const aborted = isQueryErrorPayload(action.payload) ? action.payload.aborted : false;

        let status: RequestStatus;
        if (aborted) {
          if (request?.status === 'canceling') {
            status = 'canceled';
          } else {
            status = 'aborted';
          }
        } else {
          status = 'error';
        }

        state.requests[action.meta.arg.requestId] = {
          status,
          error: isQueryErrorPayload(action.payload) ? action.payload.error : action.error,
        };
      });
  },
});

export default requests.reducer;

const useShowGqlErrorsAndNotifications = () => {
  const connection = useConnection();
  const [showGqlErrorsAndNotifications] = useFeatureFlag('nx:enable-gql-errors-and-notifications');

  return (version: string): boolean => {
    return connection.versionAndEdition.version !== undefined
      ? showGqlErrorsAndNotifications &&
          neo4jVersionUtil.compareLoose(connection.versionAndEdition.version, version) >= 0
      : false;
  };
};

export const useFormatResultSummary = () => {
  const showGqlErrorsAndNotifications = useShowGqlErrorsAndNotifications();
  return (resultSummary: Partial<ResultSummary>): FormattedResultSummary => {
    return showGqlErrorsAndNotifications(FIRST_GQL_NOTIFICATIONS_SUPPORT)
      ? formatResultSummaryGqlStatusObjects(resultSummary)
      : formatResultSummaryNotifications(resultSummary);
  };
};

export const useFormatError = () => {
  const showGqlErrorsAndNotifications = useShowGqlErrorsAndNotifications();

  return (error: QueryError): FormattedError | undefined => {
    if (!isQueryError(error)) {
      return undefined;
    }

    return showGqlErrorsAndNotifications(FIRST_GQL_ERRORS_SUPPORT)
      ? formatErrorGqlStatusObject(error)
      : formatError(error);
  };
};
