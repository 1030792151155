import { DataGrid, DataGridComponents, IconButton } from '@neo4j-ndl/react';
import { FunnelIconOutline } from '@neo4j-ndl/react/icons';
import { DataGridHelpers, SearchField } from '@nx/ui';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo } from 'react';

import { useCommonExpandedTable } from '../../components/table';
import { getFriendlyRoleType } from '../entities/helpers';
import type { DbRole, OrgRole, ProjectRole, User } from '../entities/model';
import { ROLE_TYPE } from '../entities/model';
import { MOCK_USERS } from '../mock-data';
import { ExpandableBodyRow, ExpandableCell, LastActive, RoleLabel } from './common';
import { mapUserToCommonUserRow } from './helpers';
import type { CommonUserRow } from './types';

type UserRow = CommonUserRow & {
  resource: string | undefined;
};

type RoleRow = (
  | {
      roleType: ROLE_TYPE.ORG;
      roleName: OrgRole['name'];
    }
  | {
      roleType: ROLE_TYPE.PROJECT;
      roleName: ProjectRole['name'];
    }
  | {
      roleType: ROLE_TYPE.DB;
      roleName: DbRole['name'];
    }
) & { users: UserRow[] };

interface TableRow {
  role: RoleRow | undefined;
  user: UserRow | undefined;
}

const groupUsersByRole = (users: User[]): TableRow[] => {
  const usersByOrgRole = new Map<OrgRole['name'], RoleRow>();
  const usersByProjectRole = new Map<ProjectRole['name'], RoleRow>();
  const usersByDbRole = new Map<DbRole['name'], RoleRow>();

  users.forEach((user) => {
    const partialUserRow: Omit<UserRow, 'resource'> = mapUserToCommonUserRow(user);

    const orgRoleName = user.orgRole.name;
    usersByOrgRole
      .set(
        orgRoleName,
        usersByOrgRole.get(orgRoleName) ?? { roleType: ROLE_TYPE.ORG, roleName: orgRoleName, users: [] },
      )
      .get(orgRoleName)
      ?.users.push({
        ...partialUserRow,
        resource: undefined,
      });

    const userProjectsByProjectRole = new Map<ProjectRole['name'], string[]>();
    user.projectRoles.forEach((projectRole) => {
      userProjectsByProjectRole
        .set(projectRole.name, userProjectsByProjectRole.get(projectRole.name) ?? [])
        .get(projectRole.name)
        ?.push(projectRole.projectName);
    });
    [...userProjectsByProjectRole].forEach(([projectRoleName, projectNames]) => {
      usersByProjectRole
        .set(
          projectRoleName,
          usersByProjectRole.get(projectRoleName) ?? {
            roleType: ROLE_TYPE.PROJECT,
            roleName: projectRoleName,
            users: [],
          },
        )
        .get(projectRoleName)
        ?.users.push({
          ...partialUserRow,
          resource: projectNames.join(', '),
        });
    });

    const userDatabasesByDbRole = new Map<DbRole['name'], string[]>();
    user.dbRoles.forEach((dbRole) => {
      userDatabasesByDbRole
        .set(dbRole.name, userDatabasesByDbRole.get(dbRole.name) ?? [])
        .get(dbRole.name)
        ?.push(dbRole.dbName);
    });
    [...userDatabasesByDbRole].forEach(([dbRoleName, dbNames]) => {
      usersByDbRole
        .set(dbRoleName, usersByDbRole.get(dbRoleName) ?? { roleType: ROLE_TYPE.DB, roleName: dbRoleName, users: [] })
        .get(dbRoleName)
        ?.users.push({
          ...partialUserRow,
          resource: dbNames.join(', '),
        });
    });
  });

  return [...usersByOrgRole.values(), ...usersByProjectRole.values(), ...usersByDbRole.values()].map((roleRow) => ({
    role: roleRow,
    user: undefined,
  }));
};

const columnHelper = createColumnHelper<TableRow>();

const columns = [
  columnHelper.accessor('role', {
    header: 'Role / User',
    cell: (cx) => {
      const role = cx.getValue();
      return cx.row.getCanExpand() && role ? (
        <ExpandableCell cx={cx}>
          <RoleLabel roleType={role.roleType}>{role.roleName}</RoleLabel>
        </ExpandableCell>
      ) : (
        cx.row.original.user?.email
      );
    },
  }),
  columnHelper.accessor('role.roleType', {
    header: 'Role type',
    cell: (cx) => cx.row.getCanExpand() && getFriendlyRoleType(cx.getValue()),
  }),
  columnHelper.accessor('user.resource', {
    header: 'Resource',
    cell: (cx) => !cx.row.getCanExpand() && cx.getValue(),
  }),
  columnHelper.accessor('user.lastActive', {
    header: 'Last active',
    cell: (cx) => !cx.row.getCanExpand() && <LastActive user={cx.row.original.user} />,
  }),
  columnHelper.display({
    id: 'actions',
    cell: (cx) => !cx.row.getCanExpand() && <DataGridComponents.RowActionCell cell={cx} />,
    meta: {
      isActionCell: {
        actions: [
          {
            title: 'User action',
            onClick: () => {
              // eslint-disable-next-line no-alert
              alert('Perform user action...');
            },
          },
        ],
      },
    },
  }),
];

export function OrgUsersByRole() {
  const data = useMemo(() => groupUsersByRole(MOCK_USERS), []);

  const table = useCommonExpandedTable({
    columns,
    data,
    getRowCanExpand: (row) => Boolean(row.original.role),
    getSubRows: (row) => row.role?.users.map((user) => ({ role: undefined, user })),
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => table.toggleAllRowsExpanded(true), []);

  return (
    <DataGridHelpers.Wrapper tabbed>
      <DataGridHelpers.OuterHeader>
        <div className="flex w-full flex-wrap justify-between gap-2">
          <div className="flex basis-[400px] gap-2">
            <SearchField className="min-w-36 grow" />
            <IconButton ariaLabel="Filter users">
              <FunnelIconOutline />
            </IconButton>
          </div>
        </div>
      </DataGridHelpers.OuterHeader>
      <DataGrid
        tableInstance={table}
        components={{ BodyRow: ExpandableBodyRow }}
        styling={{
          headerStyle: 'clean',
          borderStyle: 'all-sides',
        }}
        isKeyboardNavigable={false}
      />
    </DataGridHelpers.Wrapper>
  );
}
