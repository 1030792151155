import { DataGrid, Logo } from '@neo4j-ndl/react';
import { createColumnHelper, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import pricing_1_0 from './data/aura_pricing_1.0.json';
import pricing_1_1 from './data/aura_pricing_1.1.json';

interface PricingData {
  'product-name': string;
  'pricing-plan': string;
  capacity: string;
  'unit-of-measure': string;
  'price-acu': string;
  'consumption-sku': string;
}

const columnHelper = createColumnHelper<PricingData>();

export const AuraPricingPage = () => {
  const [queryParams] = useSearchParams();
  const [pricingData, setPricingData] = useState(pricing_1_1);
  const version = queryParams.get('version');

  useEffect(() => {
    if (version === '1.0') {
      setPricingData(pricing_1_0);
    } else {
      setPricingData(pricing_1_1);
    }
  }, [version]);

  const columns = [
    columnHelper.accessor('product-name', {
      header: 'Product name',
      cell: (info) => info.getValue(),
      minSize: 150,
      size: 180,
    }),
    columnHelper.accessor('pricing-plan', {
      header: 'Pricing plan',
      cell: (info) => info.getValue(),
      minSize: 220,
      size: 230,
    }),
    columnHelper.accessor('capacity', {
      header: 'Capacity',
      cell: (info) => info.getValue(),
      minSize: 150,
      size: 150,
    }),
    columnHelper.accessor('unit-of-measure', {
      header: 'Unit of measure  ',
      cell: (info) => info.getValue(),
      minSize: 150,
      size: 150,
    }),
    columnHelper.accessor('price-acu', {
      header: 'Price (ACU)*',
      cell: (info) => info.getValue(),
      minSize: 150,
      size: 180,
    }),
    columnHelper.accessor('consumption-sku', {
      header: 'Consumption SKU',
      cell: (info) => info.getValue(),
      minSize: 350,
      size: 360,
    }),
  ];

  const data = useMemo(() => {
    return pricingData;
  }, [pricingData]);

  const table = useReactTable({
    data: data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    enableSorting: true,
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className="mx-auto flex justify-center">
      <div className="bg-palette-neutral-bg-weak overflow-auto rounded-md p-10 drop-shadow-md">
        <Logo width={150} height={48} />

        <h4 className="mt-8">{`Neo4j Aura Pricing - Version ${version === '1.0' ? '1.0' : '1.1'}`}</h4>
        {version !== '1.0' && <p className="mb-8 text-sm">Valid from September 4, 2024</p>}
        <p className="my-6 text-xs">{'* 1 ACU (Aura Consumption Unit) = 1 USD'}</p>
        <DataGrid
          isResizable={false}
          tableInstance={table}
          components={{ Navigation: null }}
          isKeyboardNavigable={false}
        />
      </div>
    </div>
  );
};
