// Supported commands
const supportedCommands: Record<string, (args: string[]) => string> = {
  param: (args) => `:param ${args.join(' ')}`.trim(),
  params: (args) => `:params ${args.join(' ')}`.trim(),
  edit: (args) => `${args.join('\n')}`.trim(),
};

export const parseCommandFromUrlSearchParams = (
  params: string,
): { paramCommand: string; fullCommand: string } | undefined => {
  const searchParams = new URLSearchParams(params);
  const paramCommand = searchParams.get('cmd') ?? '';
  const paramArgs = searchParams.getAll('arg');
  const fullCommand = supportedCommands[paramCommand]?.(paramArgs);
  return fullCommand !== undefined ? { paramCommand, fullCommand } : undefined;
};
