import { Button, Dialog } from '@neo4j-ndl/react';
import { ArrowTopRightOnSquareIconOutline } from '@neo4j-ndl/react/icons';
import { isNotNullish } from '@nx/stdlib';

interface TermsAndConditionsModalProps {
  onAccept?: () => void;
  onClose?: () => void;
  header: React.ReactNode;
  subtitle: React.ReactNode;
  content: React.ReactNode;
  acceptButtonText: React.ReactNode;
  displayPrivacyPolicyButton?: boolean;
  isLoadingAccept?: boolean;
  checkboxes?: React.ReactNode | null;
}

const TermsAndConditionsModal = ({
  header,
  subtitle,
  content,
  acceptButtonText,
  onAccept,
  onClose,
  displayPrivacyPolicyButton = false,
  isLoadingAccept = false,
  checkboxes = null,
}: TermsAndConditionsModalProps) => {
  return (
    <Dialog
      modalProps={{ id: 'terms-and-conditions-modal' }}
      isOpen
      hasDisabledCloseButton={onAccept !== undefined}
      onClose={onClose}
    >
      <Dialog.Header>{header}</Dialog.Header>
      <Dialog.Subtitle>{subtitle}</Dialog.Subtitle>
      <Dialog.Content className="border-t-palette-neutral-border-weak mt-5 border-t pt-10">
        <div className="max-h-[50vh] overflow-y-auto" tabIndex={0}>
          {/* To be able to focus and scroll with keyboard */}
          {content}
        </div>
        {isNotNullish(checkboxes) && checkboxes}
      </Dialog.Content>
      <Dialog.Actions>
        {displayPrivacyPolicyButton ? (
          <Button
            color="neutral"
            fill="outlined"
            as="a"
            htmlAttributes={{
              href: 'https://neo4j.com/privacy-policy/',
              target: '_blank',
              rel: 'noopener noreferrer',
            }}
          >
            Privacy Policy
            <ArrowTopRightOnSquareIconOutline
              style={{ width: '0.75rem', height: '0.75rem', display: 'inline-block' }}
              className="mb-1 ml-1"
            />
          </Button>
        ) : null}
        {onAccept ? (
          <Button
            size="medium"
            onClick={onAccept}
            isLoading={isLoadingAccept}
            htmlAttributes={{
              'data-testid': 'terms-and-conditions-accept',
            }}
          >
            {acceptButtonText}
          </Button>
        ) : null}
      </Dialog.Actions>
    </Dialog>
  );
};

export default TermsAndConditionsModal;
