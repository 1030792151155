import { Button, TextInput } from '@neo4j-ndl/react';
import type { REGIONAL_GDPR_COMPLIANCE_TIER } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import cn from 'classnames';
import type { SyntheticEvent } from 'react';

import type { OnboardingData } from '../entities/model';
import { BaseSection } from './base-section';
import { MarketingOptIn } from './marketing-opt-in';
import './welcome-section.css';

type WelcomeSectionProps = {
  onNextStep: () => void;
  onDataChange: (data: Partial<OnboardingData>) => void;
  data: OnboardingData;
  isLoading: boolean;
  regionalTier: REGIONAL_GDPR_COMPLIANCE_TIER | null | undefined;
  marketingPreference: boolean;
  onMarketingPreferenceChange: () => void;
};

export const WelcomeSection = ({
  onDataChange,
  onNextStep,
  data,
  isLoading,
  marketingPreference,
  onMarketingPreferenceChange,
  regionalTier,
}: WelcomeSectionProps) => {
  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    onNextStep();
  };
  const classes = cn({
    surprise: data.companyName.toLowerCase() === 'rick astley inc',
  });
  return (
    <BaseSection
      title="Welcome"
      step={0}
      description="Thanks for signing up! We just need a little bit of information to help you best get started with Neo4j."
    >
      <div className="flex grow flex-col gap-6 overflow-y-auto">
        <form
          onSubmit={handleSubmit}
          id="welcome-section-form"
          className="flex max-h-full flex-col gap-6 overflow-y-auto"
        >
          <TextInput
            size="large"
            value={data.firstName}
            onChange={(event) => onDataChange({ firstName: event.target.value })}
            label="First (Given) Name"
            isFluid
          />
          <TextInput
            size="large"
            value={data.lastName}
            onChange={(event) => onDataChange({ lastName: event.target.value })}
            label="Last Name"
            isFluid
          />
          <TextInput
            size="large"
            value={data.companyName}
            onChange={(event) => onDataChange({ companyName: event.target.value })}
            label="Company / Institution"
            isFluid
          />

          {isNotNullish(regionalTier) && (
            <MarketingOptIn
              marketingPreference={marketingPreference}
              onChange={onMarketingPreferenceChange}
              regionalTier={regionalTier}
            />
          )}
        </form>
        <div className="self-end">
          <Button
            isDisabled={[data.firstName.trim(), data.lastName.trim(), data.companyName.trim()].includes('')}
            size="large"
            type="submit"
            htmlAttributes={{ form: 'welcome-section-form' }}
            isLoading={isLoading}
            className={classes}
          >
            Next
          </Button>
        </div>
      </div>
    </BaseSection>
  );
};
