import { selectAuraConfiguration } from '../slices/configuration';
import type { RootState } from '../store';

export function getSharedStorageUrl(state: RootState, logger?: (...msg: unknown[]) => void) {
  const storageApiUrl = selectAuraConfiguration(state)?.storageApiUrl;
  if (storageApiUrl === undefined) {
    logger?.('No storage API URL defined');
  }

  return storageApiUrl;
}
