import { Banner, TextLink } from '@neo4j-ndl/react';
import { neo4jVersionUtil } from '@nx/neo4j-version-utils';

import { isNeo4jGTE5x } from '../neo4j-version';

export const OfflineMessage = ({ version }: { version: string }) => (
  <Banner title="Versions available">
    <span className="n4ja-label">Current version:</span> {version}
    <p>No internet connection detected. Please connect to the internet in order to upgrade.</p>
  </Banner>
);

export const NotFoundMessage = () => (
  <Banner type="warning" title="An error occured">
    Desktop was unable to locate the DBMS selected for upgrading. Is the disk or network drive it is located on mounted?
  </Banner>
);

export const UnsupportedVersionMessage = ({ version }: { version: string }) => (
  <Banner type="warning" title="Unsupported version">
    Neo4j version <strong>{version}</strong> is officially not supported by Desktop anymore, if you are experiencing
    issues when upgrading we advice you to upgrade manually which offers better monitoring. For more information on
    upgrading Neo4j refer to{' '}
    <TextLink
      href="https://neo4j.com/docs/migration-guide/4.0/upgrade-single-instance/#upgrade-single-instance"
      target="_blank"
    >
      upgrade instructions
    </TextLink>
    .
  </Banner>
);

export const UpgradeNotSupportedMessage = () => (
  <Banner type="warning">
    {`Desktop doesn't support upgrading `}
    <strong>Neo4j 4.x</strong>.{` to `}
    <strong>Neo4j 5.x</strong>.
    <br />
    You can manually upgrade by installing a Neo4j 5.0 DBMS and then follow the{' '}
    <TextLink href="https://neo4j.com/docs/upgrade-migration-guide/current/" target="_blank">
      migration guide
    </TextLink>{' '}
    for more information on how to upgrade from 4.4.x → 5.x.
  </Banner>
);

export const UpgradePathRecommendationsMessage = ({ version }: { version: string }) => {
  // Filter the upgrade path to only include versions that are relevant to the
  // current DBMS version.
  const upgradePath = ['3.5', '4.0', '4.1', '4.2', '4.3', '4.4']
    .filter((v) => neo4jVersionUtil.satisfies(version, `<=${v}`))
    .map((v) => `${v}.latest`)
    .join(' → ');

  return (
    <Banner>
      It is recommended to do sequential ({upgradePath}) upgrades, see{' '}
      <TextLink href="https://neo4j.com/docs/upgrade-migration-guide/current/supported-paths/" target="_blank">
        Supported upgrade paths
      </TextLink>{' '}
      for more information.
    </Banner>
  );
};

export const NotCompatibePluginsMessage = ({ version, plugins }: { version: string; plugins: (string | null)[] }) => (
  <Banner type="warning" title={`Neo4j ${version} does not support plugins listed below`}>
    <ul>
      {plugins.map((plugin) => (
        <li key={plugin}>{plugin}</li>
      ))}
    </ul>
  </Banner>
);

export const ManualMigrationCheck = ({ version }: { version: string }) => {
  if (isNeo4jGTE5x(version)) {
    return null;
  }

  return (
    // <Banner type={neo4jHasShutdownProcedure(version) ? 'warning' : 'danger'} title="Manual migration">
    <Banner type="warning" title="Manual migration">
      <p>
        Only the Neo4j DBMS runtime will be upgraded. You will need to manually perform migration steps to make your
        DBMS usable.
      </p>

      <p>
        When the upgrade is finished, set <span className="n4ja-pre-inline">dbms.allow_upgrade=true</span> in the DBMS
        settings. Then start and stop the DBMS to finish the migration, checking the logs during this step to see that
        it has finished successfully.
        <br />
        {/* {!neo4jHasShutdownProcedure(version) && (
          <i>
            Because of limitations in how the Neo4j process is handled on Windows this step must be done from the
            Terminal. Otherwise the migration will fail.
          </i>
        )} */}
      </p>

      <p>
        Refer to the{' '}
        <TextLink href="https://neo4j.com/docs/operations-manual/current/upgrade/" target="_blank">
          Neo4j Operations Manual - Upgrade
        </TextLink>{' '}
        for more information.
      </p>
    </Banner>
  );
};
