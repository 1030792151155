import { isFrameType } from '@query/redux/commands';
import type { MultistatementCommand, SimpleFrameType } from '@query/redux/commands';
import type {
  CypherRequestId,
  ParamsRequestId,
  RequestId,
  SysInfoRequestId,
  UseDbRequestId,
} from '@query/redux/requests-slice';
import { isDefinedRequestId } from '@query/redux/requests-slice';
import { consumeAbortFunction } from '@query/services/abortable-frame-service';
import { trackFrameClosed, trackFrameCreated } from '@query/services/analytics';
import { clearQueryResult } from '@query/services/frame-queryresult-service';
import { clearCategorizedMetrics } from '@query/services/frame-sysinfo-service';
import type { ParsedConnectionCommand } from '@query/utils/connection-command-parser';
import type { ParsedParamArguments } from '@query/utils/params-arg-parser';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { cmdRan } from './editor-slice';
import type { RootState } from './store';

type BaseFrame = {
  type: SimpleFrameType;
  frameId: string;
  connection: {
    username: string | null;
    database: string | null;
    boltUrl: string | null;
    instanceName?: string;
  };
  cmd: string;
  historyId?: string;
  created: number;
  lastRerun?: number;
  tableColumnSizes?: number[];
  savedId?: string;
  requestIds: [null];
};

export type ParamsFrame = Omit<BaseFrame, 'type' | 'requestIds'> & {
  type: 'parameter';
  parsedParams: ParsedParamArguments;
  parameterSnapshot: Record<string, unknown>;
  requestIds: [ParamsRequestId | null];
};

export type CypherFrame = Omit<BaseFrame, 'type' | 'requestIds'> & {
  type: 'cypher';
  requestIds: [CypherRequestId];
};

export type UseDbFrame = Omit<BaseFrame, 'type' | 'requestIds'> & {
  type: 'use';
  database: string;
  requestIds: [UseDbRequestId];
};

export type MultistatementFrame = Omit<BaseFrame, 'type' | 'requestIds'> & {
  type: 'multistatement';
  requestIds: (RequestId | null)[];
  commands: MultistatementCommand[];
};

export type ConnectionFrame = Omit<BaseFrame, 'type' | 'requestIds'> & {
  type: 'connection';
  requestIds: [null];
  parsedCommand: ParsedConnectionCommand;
};

export type SysInfoFrame = Omit<BaseFrame, 'type' | 'requestIds'> & {
  type: 'sysinfo';
  requestIds: [SysInfoRequestId];
};

export type Frame =
  | BaseFrame
  | MultistatementFrame
  | ParamsFrame
  | CypherFrame
  | UseDbFrame
  | ConnectionFrame
  | SysInfoFrame;

type StreamState = {
  byId: Record<string, Frame>;
  showZoomTooltip: boolean;
  nodePropertiesExpandedByDefault: boolean;
  sidePanelDefaultWidth?: number;
  usePrettyPrint: boolean;
};

const initialState: StreamState = {
  byId: {},
  showZoomTooltip: true,
  nodePropertiesExpandedByDefault: true,
  usePrettyPrint: false,
};
export const STREAM_PERSISTED_KEYS = ['showZoomTooltip', 'nodePropertiesExpandedByDefault'];

/**
 * Sort on 'created' date in frames, latest date first
 * @param frame1
 * @param frame2
 */
const sortOnCreated = (frame1: Frame, frame2: Frame) => {
  return frame2.created - frame1.created;
};

export const selectNodePropertiesExpandedByDefault = (state: RootState) => state.stream.nodePropertiesExpandedByDefault;
export const selectSidePanelDefaultWidth = (state: RootState) => state.stream.sidePanelDefaultWidth;

type FrameIdSelector = (state: RootState) => string[];
export const getFrameIds: FrameIdSelector = createSelector(
  (state: RootState) => state.stream.byId,
  (frameRecord: Record<string, Frame>) => {
    const frames = Object.values(frameRecord);
    return frames.sort(sortOnCreated).map((frame) => frame.frameId);
  },
);

type LatestFrameSelector = (state: RootState) => Frame | undefined;
export const getLatestFrame: LatestFrameSelector = createSelector(
  (state: RootState) => state.stream.byId,
  (frameRecord: Record<string, Frame>) => {
    const frames = Object.values(frameRecord);
    return frames.sort(sortOnCreated)[0];
  },
);

export const framesRemove = createAction('stream/framesRemove', (frame: Frame) => {
  const requestIds = frame.requestIds.map((requestId) => requestId).filter(isDefinedRequestId);
  requestIds.forEach((requestId) => {
    clearQueryResult(requestId);
    clearCategorizedMetrics(frame.frameId);
    consumeAbortFunction(requestId)?.();
  });

  trackFrameClosed();

  return {
    payload: frame,
  };
});

const stream = createSlice({
  name: 'stream',
  initialState,
  reducers: {
    nodePropertiesToggled(state, action: PayloadAction<boolean>) {
      state.nodePropertiesExpandedByDefault = action.payload;
    },
    sidePanelWidthChanged(state, action: PayloadAction<number>) {
      state.sidePanelDefaultWidth = action.payload;
    },
    tableColumnsResized(state, action: PayloadAction<{ frameId: string; columnSizes: number[] }>) {
      const { frameId, columnSizes } = action.payload;
      const frame = state.byId[frameId];
      if (frame) {
        frame.tableColumnSizes = columnSizes;
      }
    },
    setUsePrettyPrint(state, action: PayloadAction<boolean>) {
      state.usePrettyPrint = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(framesRemove, (state, action) => {
        delete state.byId[action.payload.frameId];
      })
      .addCase(cmdRan, (state, action) => {
        const {
          frameId,
          historyId,
          source,
          cmd,
          connection,
          timestamp,
          parsedCmd,
          lintingEnabled,
          savedId,
          parameterSnapshot,
        } = action.payload;
        const wasRerun = frameId in state.byId;
        if (parsedCmd.type === 'clear-stream') {
          state.byId = {};
        } else if (isFrameType(parsedCmd.type)) {
          const base = {
            cmd,
            frameId,
            created: wasRerun && state.byId[frameId] ? state.byId[frameId].created : timestamp,
            lastRerun: wasRerun ? timestamp : undefined,
            tableColumnSizes: wasRerun ? state.byId[frameId]?.tableColumnSizes : undefined,
            savedId,
            usePrettyPrint: state.usePrettyPrint,
            parameterSnapshot,
            connection: {
              username: connection?.username ?? null,
              database: connection?.database ?? null,
              boltUrl: connection?.boltUrl ?? null,
              instanceName: connection?.instanceName,
            },
            historyId,
            type: parsedCmd.type,
          };

          let frame: Frame;
          if (parsedCmd.type === 'multistatement') {
            frame = {
              ...base,
              type: 'multistatement',
              requestIds: parsedCmd.commands.map((command) => command.parsed.requestId),
              commands: parsedCmd.commands,
            };
          } else if (parsedCmd.type === 'parameter') {
            frame = {
              ...base,
              type: 'parameter',
              requestIds: [parsedCmd.requestId],
              parsedParams: parsedCmd.args,
              parameterSnapshot: parameterSnapshot,
            };
          } else if (parsedCmd.type === 'cypher') {
            frame = { ...base, type: parsedCmd.type, requestIds: [parsedCmd.requestId] };
          } else if (parsedCmd.type === 'use') {
            frame = {
              ...base,
              type: 'use',
              database: parsedCmd.requestedDatabaseName,
              requestIds: [parsedCmd.requestId],
            };
          } else if (parsedCmd.type === 'connection') {
            frame = { ...base, type: 'connection', requestIds: [null], parsedCommand: parsedCmd.command };
          } else if (parsedCmd.type === 'sysinfo') {
            frame = { ...base, type: 'sysinfo', requestIds: [parsedCmd.requestId] };
          } else {
            frame = { ...base, type: parsedCmd.type, requestIds: [null] };
          }

          state.byId[frameId] = frame;
          trackFrameCreated({ source, frameType: frame.type, wasRerun, cmd, lintingEnabled });
        }
      });
  },
});

export const { nodePropertiesToggled, tableColumnsResized, sidePanelWidthChanged, setUsePrettyPrint } = stream.actions;

export default stream.reducer;
