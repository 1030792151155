import type { Instance } from '@nx/state';
import { INSTANCE_STATUS } from '@nx/state';
import type { SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';

interface OpenWithMigrationAssistantChildArgs {
  onClick: (e: SyntheticEvent) => void;
  disabled: boolean;
}
interface OpenWithMigrationAssistantProps {
  instance: Instance;
  children: (args: OpenWithMigrationAssistantChildArgs) => JSX.Element;
}

const isMigrationAssistantAccessible = (instance: Instance) => {
  switch (instance.instanceStatus) {
    case INSTANCE_STATUS.CREATING:
    case INSTANCE_STATUS.DESTROYED:
    case INSTANCE_STATUS.DESTROYING:
      return false;
    default:
      return true;
  }
};

export const OpenWithMigrationAssistant = ({ instance, children }: OpenWithMigrationAssistantProps) => {
  const navigate = useNavigate();

  return children({
    onClick: () => {
      navigate(`${instance.id}/migration-readiness-report/`);
    },
    disabled: !isMigrationAssistantAccessible(instance),
  });
};
