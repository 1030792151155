import { createAsyncThunk } from '@reduxjs/toolkit';
import { buildCreateApi, coreModule } from '@reduxjs/toolkit/query';
import { reactHooksModule } from '@reduxjs/toolkit/query/react';
import { createContext } from 'react';
import { createDispatchHook, createSelectorHook, createStoreHook } from 'react-redux';
import type { ReactReduxContextValue } from 'react-redux';

import type { AppDispatch, RootState } from './types';

export const BloomStateContext = createContext<ReactReduxContextValue | null>(null);

export const useAppDispatch = createDispatchHook(BloomStateContext).withTypes<AppDispatch>();
export const useAppSelector = createSelectorHook(BloomStateContext).withTypes<RootState>();

export const customCreateApi = buildCreateApi(
  coreModule(),
  reactHooksModule({
    hooks: {
      useDispatch: createDispatchHook(BloomStateContext),
      useSelector: createSelectorHook(BloomStateContext),
      useStore: createStoreHook(BloomStateContext),
    },
  }),
);

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
}>();
