import { Banner, LoadingSpinner, SegmentedControl } from '@neo4j-ndl/react';
import { Squares2X2IconOutline, TableCellsIconOutline } from '@neo4j-ndl/react/icons';
import { ACTION, consoleApi, usePermissions, useSetting, useUnsafeAppContext } from '@nx/state';
import { isNonEmptyString, isNotNullish } from '@nx/stdlib';
import { Center, SearchField } from '@nx/ui';
import { skipToken } from '@reduxjs/toolkit/query';
import { useMemo, useState } from 'react';

import { ProjectList } from './project-list';
import { ProjectTable } from './project-table';

export function Projects() {
  const { activeOrgId } = useUnsafeAppContext();
  const { isAllowed } = usePermissions();
  const {
    error,
    data: projects = [],
    isLoading,
  } = consoleApi.useListOrganizationProjectsQuery(
    isNotNullish(activeOrgId) ? { organizationId: activeOrgId } : skipToken,
  );
  const [filter, setFilter] = useState('');

  const normalizedFilter = filter.trim().toLocaleLowerCase();
  const [projectDisplayType, setProjectDisplayType] = useSetting('console', 'projectDisplay');

  const filteredProjects = useMemo(() => {
    const allowedProjects = projects.filter((project) => isAllowed(ACTION.READ, `namespaces/${project.id}`));
    if (isNonEmptyString(normalizedFilter)) {
      return allowedProjects.filter(({ name, id }) =>
        [name, id].some((str) => str.toLocaleLowerCase().includes(normalizedFilter)),
      );
    }
    return allowedProjects;
  }, [isAllowed, normalizedFilter, projects]);

  if (isLoading) {
    return (
      <Center>
        <LoadingSpinner size="medium" />
      </Center>
    );
  }

  if (error) {
    // TODO handle with a common api-error-banner
    return (
      <Banner
        type="danger"
        title="API Error"
        description={JSON.stringify(error, null, 2)}
        isCloseable={false}
        className="mx-8 mt-8"
        usage="inline"
      />
    );
  }

  return (
    <div className="relative flex h-full flex-col">
      <div className="bg-neutral-bg-weak border-neutral-border-weak flex h-14 w-full items-center justify-between gap-4 border-b py-[10px]">
        <div className="ml-6 flex basis-[400px] gap-2">
          <SearchField
            className="min-w-36 grow"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            htmlAttributes={{ 'aria-label': 'Search for project' }}
          />
        </div>
        <div className="mr-4 flex shrink-0 gap-4">
          <SegmentedControl onChange={setProjectDisplayType} hasOnlyIcons size="small" selected={projectDisplayType}>
            <SegmentedControl.Item
              value="table"
              htmlAttributes={{
                'aria-label': 'View projects in Table view',
              }}
            >
              <TableCellsIconOutline className="size-4" />
            </SegmentedControl.Item>
            <SegmentedControl.Item
              value="list"
              htmlAttributes={{
                'aria-label': 'View projects in List view',
              }}
            >
              <Squares2X2IconOutline className="size-4" />
            </SegmentedControl.Item>
          </SegmentedControl>
          {/* <Button onClick={alert.bind(null, 'Creating new project...')}>New project</Button> */}
        </div>
      </div>
      {projectDisplayType === 'list' && <ProjectList projects={filteredProjects} />}
      {projectDisplayType === 'table' && <ProjectTable projects={filteredProjects} />}
    </div>
  );
}
