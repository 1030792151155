import { isEmpty, isEqual } from 'lodash-es'
import React from 'react'

import { log } from '../../services/logging'
import errorDescriptions from '../../services/queries/errorDescriptions'
import { cancelRequest } from '../../services/search'
import { MIN_SUGGESTION_LETTERS } from '../../state/search/constants'
import { getDefaultSuggestions } from '../../state/search/structured/defaultSuggestions'
import { ACTION, FULL_TEXT_SEARCH, GRAPH_PATTERN, SEARCH_PHRASE } from '../../state/search/structured/suggestion'
import {TRANSACTION_TIMEOUT_ERROR_CODES} from "../../services/search/transactions.const";

export const getPlaceholderText = (suggestion, lockedText = '', displayText = '') => {
  if (!suggestion) {
    return ''
  }
  const type = suggestion.type

  if (type && ![GRAPH_PATTERN, SEARCH_PHRASE].includes(type)) {
    return ''
  }

  const match = `${lockedText.length ? lockedText.toLowerCase() + ' ' : ''}${displayText.toLowerCase()}`
  const hasMatch = (key) => suggestion[key].toLowerCase().startsWith(match.toLowerCase())
  switch (type) {
    case GRAPH_PATTERN:
      const description = suggestion.description
      if (!displayText.length && !lockedText.length) {
        return description
      }
      if (hasMatch('description')) {
        if (displayText.length) {
          return displayText + description.substring(match.length, description.length)
        } else {
          return lockedText.length ? description.substring(lockedText.length + 1, description.length) : ''
        }
      } else {
        return ''
      }
    case SEARCH_PHRASE:
      const text = suggestion.text
      return hasMatch('text') ? displayText + text.substring(displayText.length, text.length) : ''
    default:
      return ''
  }
}

export const getPlaceholderHtml = (text, inputCharTypes, currentCharsLength = 0) =>
  text.split('').map((c, i) => {
    const style = {
      color: '#b4b4b4',
      opacity: (i >= currentCharsLength) | 0
    }
    return inputCharTypes[i] && inputCharTypes[i].type === 'param' ? (
      <b key={i} style={style}>
        {c}
      </b>
    ) : (
      <span key={i} style={style}>
        {c}
      </span>
    )
  })

export const restrictRange = ({ length }, i) => {
  if (i >= length) {
    return 0
  } else if (i < 0) {
    return length - 1
  }
  return i
}

export const restrictRangeHistory = (length, i) => {
  if (i >= length) {
    return length - 1
  } else if (i <= 0) {
    return 0
  }
  return i
}

const getSortOrder = (suggestion) => {
  switch (suggestion.type) {
    case ACTION:
      return 1
    case SEARCH_PHRASE:
      return 2
    case GRAPH_PATTERN:
      return 3
    case FULL_TEXT_SEARCH:
      return 4
    default:
      throw new Error('Unknown suggestion type: ', suggestion.type)
  }
}

export const sortSuggestions = (suggestions, text) =>
  suggestions.sort((a, b) => {
    const sortA = getSortOrder(a)
    const sortB = getSortOrder(b)
    const textA = a.displayText || a.description || a.title
    const textB = b.displayText || b.description || b.title

    if (sortA !== sortB) {
      return sortA - sortB
    } else if (a.type === GRAPH_PATTERN) {
      if (textA !== textB && text.trim() === textA) {
        return -1
      } else if (textA !== textB && text.trim() === textB) {
        return 1
      } else {
        return (
          a.evaluations.length - b.evaluations.length ||
          a.evaluations[a.evaluations.length - 1].match.type.localeCompare(
            b.evaluations[b.evaluations.length - 1].match.type
          ) ||
          textA.localeCompare(textB)
        )
      }
    }
    return textA.localeCompare(textB)
  })

const hasCompleteSearchPhraseSuggestion = (newInput, suggestions) =>
  suggestions.some(({ type, text }) => type === SEARCH_PHRASE && text.toLowerCase() === newInput.toLowerCase())

export const shouldSearchForSuggestions = (newInput, lastSuggestedInput, lockedSubPattern, suggestions) => {
  const endsMultipleSpacesRegex = new RegExp(/\s{2,}$/)
  if (lockedSubPattern && endsMultipleSpacesRegex.test(newInput) && lockedSubPattern === newInput.trim()) {
    return false
  }
  return (
    suggestions.length === 0 ||
    newInput !== lastSuggestedInput ||
    hasCompleteSearchPhraseSuggestion(newInput, suggestions)
  )
}

export const adjustInputSize = (value, inputElement) => {
  const tmpSpan = document.createElement('span')
  const inputStyle = window.getComputedStyle(inputElement)

  tmpSpan.style.fontFamily = inputStyle.fontFamily
  tmpSpan.style.fontSize = inputStyle.fontSize
  tmpSpan.textContent = value

  document.body.appendChild(tmpSpan)

  const currentWidth = inputElement.getBoundingClientRect().width
  const newWidth = tmpSpan.getBoundingClientRect().width

  if (isEmpty(value)) {
    inputElement.style.width = null
  } else {
    if (currentWidth !== newWidth) {
      inputElement.style.width = `${newWidth + 10}px`
    }
  }

  document.body.removeChild(tmpSpan)
}

export const generateSearchMessage = (data, limit, uniqueNodesSize) => {
  if (uniqueNodesSize) {
    const getEndingNodes = uniqueNodesSize > 1 ? 's' : ''
    if (data.timeout) {
      return {
        text: 'Search result not complete. Timeout reached.',
        type: 'warning',
        change: 'timeout'
      }
    } else if (data.isSearchPhrase) {
      return {
        text: data.limitApplied
          ? `${uniqueNodesSize} node${getEndingNodes} returned after limiting to ${data.limitApplied} records.`
          : `${uniqueNodesSize} node${getEndingNodes} found.`,
        type: 'success'
      }
    } else {
      if (uniqueNodesSize < limit) {
        return {
          text: `${uniqueNodesSize} node${getEndingNodes} found.`,
          type: 'success'
        }
      } else if (uniqueNodesSize === limit) {
        return {
          text: `${uniqueNodesSize} node${getEndingNodes} found! The node query limit has been reached.`,
          type: 'success',
          change: 'limit'
        }
      }
    }
  } else {
    return {
      text: "Sorry, we couldn't find any results for this search.",
      type: 'error'
    }
  }
}

export const generateSearchErrorMessage = (error, origin) => {
  const PARAMETER_MISSING = 'Neo.ClientError.Statement.ParameterMissing'
  const TRANSACTION_TERMINATED = 'Neo.TransientError.Transaction.Terminated'

  if (error.code === TRANSACTION_TERMINATED) {
    return null
  }

  const nonModalCodes = TRANSACTION_TIMEOUT_ERROR_CODES
  if (nonModalCodes.includes(error.code)) {
    return {
      text: errorDescriptions[error.code],
      type: 'error',
      change: TRANSACTION_TIMEOUT_ERROR_CODES.includes(error.code) ? 'timeout' : false
    }
  }

  if (error.code === PARAMETER_MISSING) {
    const match = error.message.match(/:\s(\w)+/)
    const param = match[0].substring(2)
    return {
      text: `Unexpected parameter "$${param}" in one of your cypher queries. Please check your Search Phrase.`,
      type: 'error'
    }
  }

  return null
}

export const cancelPreviousSuggestions = (currentSuggestionsRequests) => {
  if (!currentSuggestionsRequests.size) {
    return
  }
  const requestsToCancel = new Set()
  const cancelledRequests = new Set()
  const notFoundRequests = new Set()
  currentSuggestionsRequests.forEach((requestId) => {
    const cancelPromise = cancelRequest(requestId)
      .then(() => {
        cancelledRequests.add(requestId)
      })
      .catch(({ message, ignore }) => {
        if (!ignore) {
          log.info('ERROR', message || '')
        } else {
          notFoundRequests.add(requestId)
        }
      })
    requestsToCancel.add(cancelPromise)
  })
  Promise.all(requestsToCancel)
    .then(() => {
      log.info('Attempted cancelling previous suggestion requests:', {
        requestsToCancel,
        notFoundRequests,
        cancelledRequests
      })
    })
    .catch((error) => {
      log.info('An error occurred trying the cancel previous suggestion requests:', error)
    })
}

export const getSearchPhraseLocked = (inputText, editorEnabled, selectedIndex, suggestions) => {
  let isSearchPhraseLocked = false
  if (editorEnabled && selectedIndex > -1 && suggestions[selectedIndex]) {
    const { type } = suggestions[selectedIndex]
    isSearchPhraseLocked = type === SEARCH_PHRASE
  }
  return isSearchPhraseLocked
}

export const getFilteredDefaultSuggestions = (inputText, lockedSubPattern, hasCompleted) =>
  inputText.length >= MIN_SUGGESTION_LETTERS && !lockedSubPattern
    ? getDefaultSuggestions(inputText)
        .filter((suggestion) => !hasCompleted || suggestion.type !== FULL_TEXT_SEARCH)
        .map((defSuggestion) => ({
          ...defSuggestion,
          text: defSuggestion.title
        }))
    : []

export const buildSearchInput = (input, lockedSubPattern) =>
  lockedSubPattern ? `${lockedSubPattern.description} ${input}` : input

export const getDisplayText = (searchInput, lockedSubPattern) =>
  lockedSubPattern ? searchInput.substring(lockedSubPattern.description.length).trimLeft() : searchInput

export const getNonSearchPhraseSuggestions = (suggestions, lockedSubPattern) =>
  lockedSubPattern ? suggestions.filter(({ type }) => type !== SEARCH_PHRASE) : suggestions

export const suggestionIsNew = (existingSuggestions, newSuggestion) =>
  !existingSuggestions.find(({ originalIndex, ...existingSuggestion }) =>
    existingSuggestion.isEqual ? existingSuggestion.isEqual(newSuggestion) : isEqual(existingSuggestion, newSuggestion)
  )
