export const SYSTEM_DATABASE = 'system';

export const DATABASE_TYPE_STANDARD = 'standard';

export const V4_ID = 'id';
export const V5_ID = 'elementId';
export const V4_START_NODE_ID = 'startId';
export const V4_END_NODE_ID = 'endId';
export const V5_START_NODE_ID = 'startNodeElementId';
export const V5_END_NODE_ID = 'endNodeElementId';
