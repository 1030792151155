import { Banner, Button, Dialog, TextInput } from '@neo4j-ndl/react';
import type { Instance } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { parseISO } from 'date-fns';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';

import { safeFormat } from '../../../utils';
import { CDCRestoreSnapshotWarning, instanceHasCDCEnabled } from '../../cdc';

interface Props {
  instance: Instance;
  timestamp: string;
  loading: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  errorMessage: string | null;
}

export const RestoreSnapshotModal = ({ instance, timestamp, loading, onConfirm, onCancel, errorMessage }: Props) => {
  const [confirmationText, setConfirmationText] = useState('');

  const handleConfirm = (event: SyntheticEvent) => {
    event.preventDefault();
    if (confirmationText === 'RESTORE') {
      onConfirm();
    }
  };

  return (
    <Dialog size="small" isOpen onClose={onCancel}>
      <form onSubmit={handleConfirm}>
        <Dialog.Header>Restore from Snapshot</Dialog.Header>
        <Dialog.Subtitle>
          <p>
            This will replace all data in <strong>{instance.name}</strong> with the backup taken{' '}
            {safeFormat(parseISO(timestamp), 'do MMM, H:mm a')}.
          </p>
          {instanceHasCDCEnabled(instance) && <CDCRestoreSnapshotWarning />}
        </Dialog.Subtitle>
        <Dialog.Content>
          <div className="flex flex-col gap-4">
            <TextInput
              onChange={(e) => setConfirmationText(e.target.value)}
              className="confirm"
              isFluid
              label="Are you sure?"
              htmlAttributes={{
                placeholder: "Type 'RESTORE' to confirm",
                autoComplete: 'off',
                autoFocus: true,
              }}
            />
            {isNotNullish(errorMessage) && (
              <Banner
                type="danger"
                description={errorMessage}
                usage="inline"
                htmlAttributes={{
                  'data-testid': 'modal-error-message',
                }}
              />
            )}
          </div>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onClick={onCancel} isDisabled={loading} fill="outlined" color="neutral">
            Cancel
          </Button>
          <Button
            color="primary"
            isDisabled={loading || confirmationText !== 'RESTORE'}
            type="submit"
            isLoading={loading}
          >
            {loading ? 'Restoring' : 'Restore'}
          </Button>
        </Dialog.Actions>
      </form>
    </Dialog>
  );
};
