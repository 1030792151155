export enum SNAPSHOT_PROFILE {
  SCHEDULED = 'Scheduled',
  RESCUE = 'Rescue',
  AD_HOC = 'AdHoc',
  LOAD = 'Load',
}

export enum SNAPSHOT_STATUS {
  IN_PROGRESS = 'InProgress',
  FAILED = 'Failed',
  COMPLETED = 'Completed',
  PENDING = 'Pending',
}

export enum SNAPSHOT_TYPE {
  SYSTEM_BACKUP = 'SystemBackup',
  CUSTOMER_DATA = 'CustomerData',
}

export type Snapshot = {
  id: string;
  dbId: string;
  timestamp: string;
  profile: SNAPSHOT_PROFILE;
  status: SNAPSHOT_STATUS;
  type: SNAPSHOT_TYPE;
  exportable: boolean;
};
