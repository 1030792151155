import type { IMPORT_EVENTS } from '@nx/analytics-service';
import { IMPORT_ACTIONS_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { LEGACY_store, trackEvent } from '@nx/state';

import { APP_VERSION } from '../constants';
import { addNodeAndRelationship, addRelationship } from '../state/actions/common-actions';
import {
  removeTableSchema,
  reverseRelationship,
  setNodeKeyProperty,
  setRelationshipSourceMapping,
  setRelationshipTargetMapping,
} from '../state/reducers/data-model';

// Map actions to have consistent names in Mixpanel UI
const eventNames: Record<string, string> = {
  [removeTableSchema.type]: IMPORT_ACTIONS_EVENTS.REMOVE_TABLE,
  [addRelationship.type]: IMPORT_ACTIONS_EVENTS.CONNECT_NODES,
  [addNodeAndRelationship.type]: IMPORT_ACTIONS_EVENTS.CREATE_NODES_AND_RELS,
  [setNodeKeyProperty.type]: IMPORT_ACTIONS_EVENTS.UPDATE_NODE_SCHEMA_ID,
  [setRelationshipSourceMapping.type]: IMPORT_ACTIONS_EVENTS.UPDATE_REL_MAPPING_SOURCE,
  [setRelationshipTargetMapping.type]: IMPORT_ACTIONS_EVENTS.UPDATE_REL_MAPPING_TARGET,
  [reverseRelationship.type]: IMPORT_ACTIONS_EVENTS.REVERSE_RELATIONSHIP,
};

// reducerName/event => event
const formatEventName = (actionType: string): string => {
  let event = eventNames[actionType] ?? actionType;
  if (event.indexOf('/')) {
    // Event comes from an RTK slice, so we should remove the part up to the /
    event = event.substring(event.indexOf('/') + 1);
  }
  return event;
};

export const track = (event: IMPORT_EVENTS, properties = {}): void => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const eventName = formatEventName(event) as IMPORT_EVENTS;
  const eventProperties = { ...properties, appVersion: APP_VERSION };
  LEGACY_store.dispatch(trackEvent({ event: eventName, properties: eventProperties, scope: APP_SCOPE.import }));
};
