import type { Session } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import { add, isBefore } from 'date-fns';

const DURATION_REGEXP = /(?:(\d+)h)?(?:(\d+)m)?(?:(\d+\.?\d*)s)?/;

const parseDuration = (duration: string) => {
  const match = duration.match(DURATION_REGEXP);

  if (isNullish(match)) {
    return null;
  }

  const hours = isNotNullish(match[1]) ? parseInt(match[1], 10) : 0;
  const minutes = isNotNullish(match[2]) ? parseInt(match[2], 10) : 0;
  const seconds = isNotNullish(match[3]) ? parseFloat(match[3]) : 0;

  return { hours, minutes, seconds };
};

export const getSessionDestroyDate = (session: Session) => {
  const currentDate = new Date();
  const expiryDate = new Date(session.expiryDate);

  if (isNullish(session.ttl)) {
    return expiryDate;
  }

  const parsedTTL = parseDuration(session.ttl);

  if (isNullish(parsedTTL)) {
    return expiryDate;
  }

  const ttlDate = add(currentDate, parsedTTL);
  return isBefore(ttlDate, expiryDate) ? ttlDate : expiryDate;
};
