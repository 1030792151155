import type { StatusIndicatorProps } from '@neo4j-ndl/react';
import { StatusIndicator } from '@neo4j-ndl/react';
import classNames from 'classnames';
import type { PropsWithChildren } from 'react';

import classes from './with-status-indicator.module.css';

export const WithStatusIndicator = ({
  active,
  indicatorProps,
  className,
  children,
}: PropsWithChildren<{
  active: boolean;
  indicatorProps: Pick<React.ComponentProps<typeof StatusIndicator>, 'type' | 'style' | 'className'> & {
    color?: StatusIndicatorProps['type'];
  };
  className?: string;
}>) => {
  const { color, className: indicatorClassName, ...rest } = indicatorProps;
  return (
    <div className={classNames('relative', className)} style={{ padding: 0 }}>
      {active && (
        <StatusIndicator {...rest} className={classNames('absolute', color && classes[color], indicatorClassName)} />
      )}
      {children}
    </div>
  );
};
