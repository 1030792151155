import {
  Case,
  Literal,
  NamedParam,
  Param,
  Union,
  Variable,
  contains,
  db,
  labels,
  minus,
  size,
  startsWith,
  toLower,
  toString,
} from '@neo4j/cypher-builder';

import { PROPERTY_CONDITION_EQUALS } from '../../../../../modules/SearchBar/SearchBar.const';
import { buildSuggestion } from '../../../../../modules/SearchBar/SearchBar.utils';
import type { NodeSuggestion } from '../../../../../modules/SearchBar/types';
import { SUGGESTION_TYPE } from '../../../../../modules/SearchBar/types';
import type { GenerateFulltextQueryParams, MapRecordsParams } from '../property-value-suggestions.types';
import { findCategoryByLabel } from './util';

export const generateFullTextQuery = ({
  query,
  indexes,
  limit,
  isCaseInsensitive = false,
}: GenerateFulltextQueryParams) => {
  const node = new Variable();
  const score = new Variable();
  const queryParam = new Param(isCaseInsensitive ? query.toLowerCase() : query);
  const originalQueryParam = new NamedParam('query', `${query}~`);

  const indexQueries = indexes.map(([index, property]) => {
    // it is possible to create a full-text index on a property which has a non-string value
    // this results in DB query error, because it crashes when you call toLower on a non-string value
    const stringValue = toString(node.property(property));
    const propertyValue = isCaseInsensitive ? toLower(stringValue) : stringValue;

    const scoreCase = new Case()
      .when(startsWith(propertyValue, queryParam))
      .then(minus(size(stringValue), new Literal(30)))
      .else(size(stringValue));

    return db.index.fulltext
      .queryNodes(index, originalQueryParam)
      .yield(['node', node])
      .where(contains(propertyValue, queryParam))
      .with(node, [scoreCase, score])
      .return(
        [labels(node), 'labels'],
        [node.property(property), 'value'],
        [new Literal(property), 'propertyName'],
        score,
      )
      .orderBy([score, 'ASC'], stringValue)
      .limit(limit);
  });

  return new Union(...indexQueries).build();
};
export const mapFullTextQueryRecords = ({ records, categoryToLabelDictionary }: MapRecordsParams): NodeSuggestion[] => {
  const labelToCategoryMap = new Map<string, string>();

  return records.map((record: any) => {
    const value = String(record.get('value'));
    const labels = record.get('labels');
    const propertyName = record.get('propertyName');

    return buildSuggestion<NodeSuggestion>({
      type: SUGGESTION_TYPE.NODE,
      categoryName: findCategoryByLabel(labels, categoryToLabelDictionary, labelToCategoryMap) ?? '',
      propertyConditionValue: value,
      isCaseInsensitive: true,
      propertyCondition: PROPERTY_CONDITION_EQUALS,
      propertyName,
      labels,
    });
  });
};
