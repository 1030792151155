/**
 *
 * @param promise promsie to guard with a timeout
 * @param ms time to wait for a promise in milliseconds
 * @returns
 */
export const promiseTimeout = <T>(promise: Promise<T>, ms: number) => {
  const error = new Error(`Promise timed out after ${ms} milliseconds`);

  const timeout = new Promise<never>((_, reject) => setTimeout(() => reject(error), ms));

  return Promise.race([promise, timeout]);
};
