import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { CLEAR_SCENE } from '../rootActions';
import type { RootState } from '../types';
import type { CardsState } from './types';

export const NAME = 'cards';

export const initialState: CardsState = {
  expandedRelationshipTypes: {},
  expandedCategories: {},
  collapsed: true,
};

/**
 * Selectors
 */
export function isCollapsed(state: RootState): CardsState['collapsed'] {
  return state[NAME].collapsed;
}

export function getExpandedCategories(state: RootState): CardsState['expandedCategories'] {
  return state[NAME].expandedCategories;
}

export function getExpandedRelationshipTypes(state: RootState): CardsState['expandedRelationshipTypes'] {
  return state[NAME].expandedRelationshipTypes;
}

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    toggleCategory: (state, action: PayloadAction<string>) => {
      const category = action.payload;
      state.expandedCategories[category] = !state.expandedCategories[category];
    },
    toggleRelationshipType: (state, action: PayloadAction<string>) => {
      const relationshipType = action.payload;
      state.expandedRelationshipTypes[relationshipType] = !state.expandedRelationshipTypes[relationshipType];
    },
    toggleCollapsed: (state) => {
      state.collapsed = !state.collapsed;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(CLEAR_SCENE, () => initialState);
  },
});

export const { toggleCategory, toggleRelationshipType, toggleCollapsed } = slice.actions;
export default slice.reducer;
