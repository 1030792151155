import { type CLOUD_PROVIDER, consoleApi } from '@nx/state';
import { isNullish } from '@nx/stdlib';

import { useDispatch, useSelector } from '../context';
import { completeUpload, failUpload, startUpload } from '../slices/upload-slice';

interface UseUploadDumpParams {
  dbid: string;
  file: File | null;
  cloudProvider: CLOUD_PROVIDER;
}

export const useUploadContext = () => {
  const progress = useSelector((state) => state.upload.progress);
  const status = useSelector((state) => state.upload.status);

  return { progress, status };
};

export const useUploadDump = ({ dbid, file, cloudProvider }: UseUploadDumpParams) => {
  const dispatch = useDispatch();
  const [getSignedUrlRes] = consoleApi.useLazyGetSignedObjectUrlQuery();
  const [uploadFile] = consoleApi.useUploadFileMutation();
  const [startImport] = consoleApi.useStartImportForCloudStorageObjectMutation();

  const uploadDump = async () => {
    if (isNullish(file)) {
      return;
    }
    try {
      const getSigneUrlPayload = {
        id: dbid,
        size: file.size,
        extension: file.name.split('.').pop() ?? 'skip',
      };

      dispatch(startUpload());

      const signedUrlObject = await getSignedUrlRes(getSigneUrlPayload).unwrap();

      const payload = {
        file: file,
        signedUrl: signedUrlObject.signedUrl,
        cloudProvider: cloudProvider,
      };

      await uploadFile(payload);

      await startImport({ dbId: dbid, object: signedUrlObject.cloudStorageObject }).unwrap();
      dispatch(completeUpload());
    } catch (e) {
      dispatch(failUpload());
    }
  };

  return { uploadDump };
};
