import { Navigate, Route, Routes } from 'react-router-dom';

import { ApiKeysLayout } from './api-keys';
import { PreferencesLayout } from './preferences';
import { ProfileLayout } from './profile';

export function AccountRoutes() {
  return (
    <Routes>
      <Route path="profile" element={<ProfileLayout />} />
      <Route path="api-keys" element={<ApiKeysLayout />} />
      <Route path="preferences" element={<PreferencesLayout />} />
      <Route path="*" element={<Navigate to="profile" replace />} />
    </Routes>
  );
}
