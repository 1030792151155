import { Banner, Button, Dialog, IconButton, LoadingSpinner, TextInput, Typography } from '@neo4j-ndl/react';
import { TrashIconOutline } from '@neo4j-ndl/react/icons';
import type { Instance, Project, TrafficConfig } from '@nx/state';
import { CLOUD_PROVIDER, TRAFFIC_ENABLEMENT, TRAFFIC_STATUS, consoleApi, getApiError, useInstances } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useEffect, useState } from 'react';

import { getProductFromTier } from '../entities/helpers';

const DeleteNetworkConfigDialog = ({
  project,
  trafficConfig,
  onClose,
}: {
  project: Project;
  trafficConfig: TrafficConfig;
  onClose: () => void;
}) => {
  const product = getProductFromTier(trafficConfig.tier);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const [affectedInstances, setAffectedInstances] = useState<Instance[]>([]);
  const [confirmText, setConfirmText] = useState('');

  const { instances, isInstancesLoading } = useInstances({ shouldPoll: true });

  const handleConfirmTextChange = (value: string) => {
    setConfirmText(value);
  };
  useEffect(() => {
    if (isNullish(instances)) {
      return;
    }
    setAffectedInstances(
      instances.filter((instance) => instance.region === trafficConfig.region && instance.tier === trafficConfig.tier),
    );
  }, [instances, trafficConfig.region, trafficConfig.tier]);

  const handleClose = () => {
    setConfirmText('');
    onClose();
  };

  const [updateTrafficConfig, updateTrafficConfigRes] = consoleApi.useUpdateTrafficConfigMutation();
  const handleDeleteKey = () => {
    const propertiesToDelete = () => {
      switch (trafficConfig.cloudProvider) {
        case CLOUD_PROVIDER.GCP:
          return { gcpProperties: { projectIds: [] } };
        case CLOUD_PROVIDER.AWS:
          return { awsProperties: { endpointIds: [] } };
        case CLOUD_PROVIDER.AZURE:
          return { azureProperties: { subscriptionIds: [], endpointIds: [] } };
        default:
          return {};
      }
    };

    updateTrafficConfig({
      projectId: project.id,
      tier: trafficConfig.tier,
      region: trafficConfig.region,
      privateTraffic: TRAFFIC_ENABLEMENT.DISABLED,
      publicTraffic: TRAFFIC_ENABLEMENT.ENABLED,
      ...propertiesToDelete(),
    })
      .unwrap()
      .then(handleClose)
      .catch((err: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(err);
        const defaultErrorMessage = `There was an error deleting the network config. Try again later. If the problem persists, contact support.`;
        if (error.code === 422) {
          setDeleteError(error.message ?? defaultErrorMessage);
        } else {
          setDeleteError(defaultErrorMessage);
        }
      });
  };

  return (
    <Dialog
      isOpen
      type="danger"
      size="large"
      modalProps={{
        'data-testid': 'delete-network-config-dialog',
      }}
      onClose={handleClose}
    >
      <Dialog.Header>Remove network config?</Dialog.Header>
      {isInstancesLoading && (
        <Dialog.Content>
          <LoadingSpinner className="m-auto" size="large" />
        </Dialog.Content>
      )}
      {!isInstancesLoading && (
        <>
          <Dialog.Content>
            <div className="flex flex-col gap-4">
              <div>
                <p>Removing this network config will:</p>
                <ul className="my-3 ml-6 list-disc">
                  <li>
                    Enable public traffic for ALL <strong>{product}</strong> instances in region{' '}
                    <strong>{trafficConfig.region}</strong>
                  </li>
                  <li>
                    Disable traffic over the private connection for ALL <strong>{product}</strong> instances in region{' '}
                    <strong>{trafficConfig.region}</strong>
                  </li>
                  <li>
                    Prevent access over the private connection URI (
                    <code>{`<dbid>.${trafficConfig.status.dnsDomain}`}</code>)
                  </li>
                </ul>
                <Banner
                  hasIcon
                  type="warning"
                  title="Update application connection URI"
                  description={
                    <>
                      <p>
                        Your applications will lose access to their instance if they are using the private connection
                        URI (<code>{`<dbid>.${trafficConfig.status.dnsDomain}`}</code>). Enable public traffic and
                        switch to the public connection URI before removing the network config.
                      </p>
                      {affectedInstances.length > 0 && (
                        <div className="mt-2">
                          The following databases will be affected:
                          <ul className="ml-4 mt-1 list-inside list-disc">
                            {affectedInstances.map((instance) => (
                              <Typography variant="label" key={instance.id} as="li">
                                {instance.name} - {instance.id}
                              </Typography>
                            ))}
                          </ul>
                        </div>
                      )}
                    </>
                  }
                  usage="inline"
                />
              </div>
              <TextInput
                isFluid
                label="Are you sure?"
                value={confirmText}
                onChange={(e) => handleConfirmTextChange(e.target.value)}
                htmlAttributes={{
                  placeholder: `Type "CONFIRM" to confirm`,
                  autoFocus: true,
                  'data-testid': 'confirm-delete-network-config',
                }}
              />
              {isNotNullish(deleteError) && <Banner description={deleteError} type="danger" usage="inline" />}
            </div>
          </Dialog.Content>
          <Dialog.Actions>
            <Button color="neutral" fill="outlined" onClick={handleClose} isDisabled={updateTrafficConfigRes.isLoading}>
              Cancel
            </Button>
            <Button
              color="danger"
              isDisabled={confirmText !== 'CONFIRM'}
              isLoading={updateTrafficConfigRes.isLoading}
              onClick={handleDeleteKey}
            >
              Delete
            </Button>
          </Dialog.Actions>
        </>
      )}
    </Dialog>
  );
};

export const DeleteNetworkConfig = ({ project, trafficConfig }: { project: Project; trafficConfig: TrafficConfig }) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <IconButton
        className="ml-1"
        ariaLabel="Delete config"
        isDanger
        onClick={() => setOpenModal(true)}
        isDisabled={trafficConfig.status.privateTraffic === TRAFFIC_STATUS.DELETING}
        htmlAttributes={{
          title: 'Delete network config',
          'data-testid': `delete-network-config-button-${trafficConfig.region}-${trafficConfig.tier}`,
        }}
      >
        <TrashIconOutline />
      </IconButton>
      {openModal && (
        <DeleteNetworkConfigDialog
          project={project}
          trafficConfig={trafficConfig}
          onClose={() => setOpenModal(false)}
        />
      )}
    </>
  );
};
