export enum SIGNAL_NAME {
  QuerySetMainEditorValue = 'query:SetMainEditorValue',
  QueryExecuteQueryCommands = 'query:ExecuteQueryCommands',
  ImportFetchModelAndFilesFromUrl = 'import:FetchModelAndFilesFromUrl',
  ImportAddNode = 'import:AddNode',
  ImportShowPropertiesMappingPane = 'import:ShowPropertiesMappingPane',
  ImportPreview = 'import:Preview',
  ImportRun = 'import:Run',
  Test = 'Test',
}

export interface SignalPayload {
  [SIGNAL_NAME.QuerySetMainEditorValue]: { query: string };
  [SIGNAL_NAME.QueryExecuteQueryCommands]: { query: string };
  [SIGNAL_NAME.ImportFetchModelAndFilesFromUrl]: { url: string };
  [SIGNAL_NAME.ImportAddNode]: Record<string, never>;
  [SIGNAL_NAME.ImportShowPropertiesMappingPane]: never;
  [SIGNAL_NAME.ImportPreview]: { previewSelected: boolean };
  [SIGNAL_NAME.ImportRun]: Record<string, never>;
  [SIGNAL_NAME.Test]: string;
}

export type SignalListener<S extends SIGNAL_NAME> = (eventData: SignalPayload[S]) => void | Promise<void>;
