import {
  Banner,
  Button,
  Dialog,
  LoadingSpinner,
  SegmentedControl,
  TextLink,
  Typography,
  Wizard,
} from '@neo4j-ndl/react';
import { AURA_CONSOLE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import type { InstanceSummary, Project, TierConfigs } from '@nx/state';
import {
  ACTION,
  MODAL_TYPE,
  NEO4J_MANAGED_KEY,
  PLAN_TYPE,
  PROJECT_PROFILE,
  TIER,
  consoleApi,
  getApiError,
  getErrorMessage,
  useActiveOrg,
  useActiveProject,
  useActiveProjectQuery,
  useModal,
  useModalClose,
  usePermissions,
} from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Suspense, lazy, useCallback, useMemo, useState } from 'react';

import { StripeFormFallback } from '../../loading-fallbacks';
import { useTrackUpxEvent } from '../../services/segment/analytics';
import type { Validation } from '../../utils/validation';
import { isValid } from '../../utils/validation';
import {
  dseDefaults,
  enterpriseDefaults,
  freeDefaults,
  gdsDefaults,
  getDefaultProduct,
  getDefaultTier,
  getStepNumber,
  getStepTitle,
  isMarketplaceProject,
  isMultiProductProject,
  mteDefaults,
  preventFormSubmission,
  proDefaults,
  validate,
} from '../entities/helpers';
import { CREATE_STEP, type InstanceFormData, type Product } from '../entities/model';
import {
  DseTierInfo,
  EnterpriseTierInfo,
  FreeTierSelector,
  GdsTierSelector,
  MTETierSelector,
  ProfessionalTierSelector,
} from '../tier-selector';
import CreateInstanceForm from './form';
import { ProfileInformationForm, validate as validateProfileInformation } from './profile-information/form';
import { type ProfileInformationFormData, profileInformationDefaults } from './profile-information/form-data';
import { transformProfileInformationFormData } from './profile-information/helpers';

const FREE_MEMORY = '1GB';
const FREE_STORAGE = '2GB';

const StripeForm = lazy(() => import('../../project/billing/shared/stripe-form'));

const logger = createLogger(APP_SCOPE.framework);

const defaults = (tier: TIER, tierConfigs: TierConfigs, project: Project, instances: InstanceSummary[] = []) => {
  switch (tier) {
    case TIER.FREE:
      if (isNullish(tierConfigs.free)) {
        throw new Error('Tier not supported');
      }
      return freeDefaults(tierConfigs.free, instances);
    case TIER.PROFESSIONAL:
      if (isNullish(tierConfigs.professional)) {
        throw new Error('Tier not supported');
      }
      return proDefaults(
        tierConfigs.professional,
        instances,
        project,
        project.availableActions.create_pro_trial.enabled && project.profile === PROJECT_PROFILE.EXTERNAL,
      );
    case TIER.ENTERPRISE:
      if (isNullish(tierConfigs.enterprise)) {
        throw new Error('Tier not supported');
      }
      return enterpriseDefaults(tierConfigs.enterprise, project);
    case TIER.MTE:
      if (isNullish(tierConfigs.mte)) {
        throw new Error('Tier not supported');
      }
      return mteDefaults(tierConfigs.mte, instances, project);
    case TIER.GDS:
      if (isNullish(tierConfigs.gds)) {
        throw new Error('Tier not supported');
      }
      return gdsDefaults(tierConfigs.gds, instances, project);
    case TIER.AURA_DSE:
      if (isNullish(tierConfigs.dsenterprise)) {
        throw new Error('Tier not supported');
      }
      return dseDefaults(tierConfigs.dsenterprise, project);
    default:
      throw new Error('Tier not supported');
  }
};

export const CreateInstanceModal = () => {
  const trackEvent = useTrackUpxEvent();
  const activeOrg = useActiveOrg();
  const closeModal = useModalClose();
  const createdInstanceModal = useModal(MODAL_TYPE.INSTANCE_CREATED);
  const activeProject = useActiveProject();
  const activeProjectQuery = useActiveProjectQuery();
  const { data: instances } = consoleApi.useListInstancesQuery(activeProject.id);
  const [createInstance, createInstanceRes] = consoleApi.useCreateInstanceMutation();
  const [createAuraInstance, createAuraInstanceRes] = consoleApi.useCreateAuraInstanceMutation();
  const { isAllowed } = usePermissions();

  const { hasBilling, requiresBilling, allowFreeDatabaseCreation, tierConfigs } = activeProject;
  const [product, setProduct] = useState<Product>(getDefaultProduct(tierConfigs));

  const [validation, setValidation] = useState<Validation<InstanceFormData> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [profileInformationValidationErrors, setProfileInformationValidationErrors] =
    useState<Validation<ProfileInformationFormData> | null>(null);
  const [profileInformation, setProfileInformation] = useState<ProfileInformationFormData>(profileInformationDefaults);
  const [addTrialCustomerInfo, addTrialCustomerInfoRes] = consoleApi.useAddTrialCustomerInfoMutation();

  const isSelfServe = activeProject.planType === PLAN_TYPE.SELF_SERVE;
  const [activeStep, setActiveStep] = useState<CREATE_STEP>(
    isSelfServe ? CREATE_STEP.TIER_SELECTION : CREATE_STEP.INSTANCE_CONFIGURATION,
  );
  const isMarketplace = isMarketplaceProject(activeProject.projectType);
  const defaultTier = getDefaultTier(isSelfServe, product);
  const [data, setData] = useState<InstanceFormData>(() =>
    defaults(defaultTier, tierConfigs, activeProject, instances),
  );

  const { data: canSubmitCustomerProfileData, error: customerProfileError } =
    consoleApi.useCheckTrialCustomerInfoCanBeSubmittedQuery(activeOrg.id, {
      skip:
        data.tier !== TIER.PROFESSIONAL ||
        !isAllowed(ACTION.READ, `organizations/${activeProject.organizationId}/trial/customer-info`),
    });

  const showProfileInformation =
    activeProject.availableActions.create_pro_trial.enabled &&
    (canSubmitCustomerProfileData ?? getApiError(customerProfileError).code === 404) &&
    (data.size?.isTrial ?? false);

  const showBilling = requiresBilling && !hasBilling && !showProfileInformation;

  let expectedSteps: CREATE_STEP[] = [CREATE_STEP.INSTANCE_CONFIGURATION];
  if (showProfileInformation) {
    expectedSteps = [
      CREATE_STEP.TIER_SELECTION,
      CREATE_STEP.INSTANCE_CONFIGURATION,
      CREATE_STEP.PRO_TRIAL_PROFILE_INFORMATION,
    ];
  } else if (showBilling) {
    expectedSteps = [CREATE_STEP.TIER_SELECTION, CREATE_STEP.INSTANCE_CONFIGURATION, CREATE_STEP.BILLING_SETUP];
  } else if (isSelfServe) {
    expectedSteps = [CREATE_STEP.TIER_SELECTION, CREATE_STEP.INSTANCE_CONFIGURATION];
  }

  const isSubmitDisabled = useMemo(() => {
    return preventFormSubmission(data, activeProject, validate(data));
  }, [data, activeProject]);

  const errorMessage = useMemo(() => {
    if (createInstanceRes.isError) {
      const error = getApiError(createInstanceRes.error);
      const message = getErrorMessage(error);
      return message;
    } else if (addTrialCustomerInfoRes.isError) {
      const error = getApiError(addTrialCustomerInfoRes.error);
      const message = getErrorMessage(error);
      return message;
    } else if (createAuraInstanceRes.isError) {
      const error = getApiError(createAuraInstanceRes.error);
      const message = getErrorMessage(error);
      return message;
    }
    return null;
  }, [
    createInstanceRes.error,
    createInstanceRes.isError,
    createAuraInstanceRes.error,
    createAuraInstanceRes.isError,
    addTrialCustomerInfoRes.error,
    addTrialCustomerInfoRes.isError,
  ]);

  const handleDataChange = useCallback(
    (newData: InstanceFormData) => {
      if (isNotNullish(validation)) {
        setValidation(null);
      }
      setData((oldData) => ({ ...oldData, ...newData }));
    },
    [validation],
  );

  const handleSubmitProfileInformation = () => {
    return addTrialCustomerInfo({
      organizationId: activeOrg.id,
      ...transformProfileInformationFormData(profileInformation),
    });
  };

  const handleCreateInstance = async (formData: InstanceFormData) => {
    setLoading(true);

    const validationError = validate(formData);
    if (!isValid(formData, validationError)) {
      setValidation(validationError);
      setActiveStep(CREATE_STEP.INSTANCE_CONFIGURATION);
      setLoading(false);
      return;
    }
    setValidation(null);

    if (activeStep === CREATE_STEP.PRO_TRIAL_PROFILE_INFORMATION) {
      const errors = validateProfileInformation(profileInformation);
      if (errors) {
        setProfileInformationValidationErrors(errors);
        setLoading(false);
        return;
      }

      try {
        await handleSubmitProfileInformation().unwrap();
      } catch (e: unknown) {
        setLoading(false);
        return;
      }
    }

    const memory = formData.tier === TIER.FREE ? FREE_MEMORY : formData.size.memory;
    const storage = formData.tier === TIER.FREE ? FREE_STORAGE : formData.size.storage;

    const payloadData = {
      tier: formData.tier,
      region: formData.region,
      version: formData.version,
      cloudProvider: formData.cloudProvider,
      name: formData.name.trim(),
      memory,
      storage,
      projectId: activeProject.id,
      ...(activeProject.capabilities.cmek &&
        [TIER.ENTERPRISE, TIER.AURA_DSE].includes(formData.tier) && {
          encryptionKeyRef: formData.encryptionKeyRef === NEO4J_MANAGED_KEY ? undefined : formData.encryptionKeyRef,
        }),
      ...([TIER.GDS, TIER.PROFESSIONAL].includes(formData.tier) && {
        isTrial: formData.size.isTrial,
      }),
      vectorOptimized: formData.vectorOptimized,
      gdsPlugin: formData.gdsPlugin,
    };

    const multiDbFree = activeProject.capabilities.multi_db_free && formData.tier === TIER.FREE;
    const multiDbEnterprise =
      activeProject.capabilities.multi_db_enterprise && [TIER.ENTERPRISE, TIER.MTE].includes(formData.tier);

    const createMutation = multiDbFree || multiDbEnterprise ? createAuraInstance : createInstance;

    createMutation(payloadData)
      .unwrap()
      .then((result) => {
        createdInstanceModal.open({ createdInstance: result });
        const eventProperties = {
          tier: formData.tier,
          ...(formData.tier === TIER.PROFESSIONAL && {
            proTrial: formData.size.isTrial ? 'proTrial_included' : 'proTrial_excluded',
          }),
        };

        trackEvent({
          event: AURA_CONSOLE_EVENTS.INSTANCE_CREATE,
          properties: eventProperties,
          scope: APP_SCOPE.aura,
        });
      })
      .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(e);
        const message = getErrorMessage(error);

        logger.error(message);
      })
      .finally(() => setLoading(false));
  };

  const handleSubmitForm = (formData: InstanceFormData) => {
    void handleCreateInstance(formData);
  };

  const proceedToNextStep = () => {
    const nextStep = expectedSteps[expectedSteps.indexOf(activeStep) + 1];
    if (isNotNullish(nextStep)) {
      setActiveStep(nextStep);
    }
  };

  const goBackToPreviousStep = () => {
    const previousStep = expectedSteps[expectedSteps.indexOf(activeStep) - 1];
    if (isNotNullish(previousStep)) {
      setActiveStep(previousStep);
    }
  };

  const handleSelectTier = (tier: TIER) => {
    setValidation(null);

    setData({ ...defaults(tier, tierConfigs, activeProject, instances) });

    proceedToNextStep();
  };

  const validateFreeTier =
    TIER.FREE in tierConfigs ? validate(defaults(TIER.FREE, tierConfigs, activeProject, instances)) : null;

  const handleProductSelect = (newProduct: Product) => {
    if (activeProject.planType === PLAN_TYPE.ENTERPRISE) {
      const newData = defaults(newProduct === 'AuraDB' ? TIER.ENTERPRISE : TIER.AURA_DSE, tierConfigs, activeProject);
      setData((oldData) => ({ ...newData, name: oldData.name }));
    }
    setProduct(newProduct);
  };

  return (
    <Dialog
      isOpen
      onClose={() => closeModal()}
      size="unset"
      modalProps={{ className: 'max-w-[1500px] mx-auto mt-12 p-10 align-top' }}
    >
      <Dialog.Header>
        <div className="flex">
          <div className="flex flex-grow flex-row items-baseline gap-7 self-center">
            New instance
            {activeStep === CREATE_STEP.TIER_SELECTION && (
              <Typography variant="body-medium">
                <TextLink
                  href={`https://neo4j.com/pricing/#${product === 'AuraDS' ? 'graph-data-science' : 'graph-database'}`}
                  className="no-underline"
                  isExternalLink
                >
                  See full comparison
                </TextLink>
              </Typography>
            )}
          </div>
          {activeStep === CREATE_STEP.TIER_SELECTION && isMultiProductProject(tierConfigs) && (
            <SegmentedControl onChange={handleProductSelect} selected={product}>
              <SegmentedControl.Item
                value="AuraDB"
                htmlAttributes={{
                  'aria-label': 'View Aura Database plans',
                }}
              >
                AuraDB
              </SegmentedControl.Item>
              <SegmentedControl.Item
                value="AuraDS"
                htmlAttributes={{
                  'aria-label': 'View Aura Data Science plans',
                }}
              >
                AuraDS
              </SegmentedControl.Item>
            </SegmentedControl>
          )}
        </div>
      </Dialog.Header>
      <Dialog.Content>
        {activeProjectQuery.isFetching ? (
          <LoadingSpinner className="mx-auto my-0" size="large" />
        ) : (
          <>
            {activeStep !== CREATE_STEP.TIER_SELECTION &&
              getStepNumber(activeStep, expectedSteps) > 0 &&
              expectedSteps.length > 1 && (
                <div className="mx-auto mb-8 flex w-4/5">
                  <Wizard
                    size="large"
                    orientation="horizontal"
                    className="w-full"
                    steps={expectedSteps.map((step) => ({
                      content: (
                        <div key={`${getStepNumber(step, expectedSteps)}`} className="mx-auto flex text-nowrap">
                          {getStepTitle(step)}
                        </div>
                      ),
                    }))}
                    activeStepIndex={getStepNumber(activeStep, expectedSteps) - 1}
                  />
                </div>
              )}
            {activeStep === CREATE_STEP.TIER_SELECTION && (
              <div className="flex flex-col gap-8">
                <div className="flex justify-between gap-8">
                  {product === 'AuraDB' ? (
                    <>
                      <FreeTierSelector
                        project={activeProject}
                        onSelect={() => handleSubmitForm(defaults(TIER.FREE, tierConfigs, activeProject, instances))}
                        isDisabled={!allowFreeDatabaseCreation || isMarketplace || isNotNullish(validateFreeTier)}
                        isLoading={createInstanceRes.isLoading || createAuraInstanceRes.isLoading}
                        disabledReason={
                          // eslint-disable-next-line no-nested-ternary
                          isMarketplace
                            ? 'The free tier is not available in this project.'
                            : isNotNullish(validateFreeTier)
                              ? 'The free tier is not available at the moment.'
                              : 'You have reached your maximum number of free instances.'
                        }
                      />
                      <ProfessionalTierSelector
                        project={activeProject}
                        proTrialAvailable={activeProject.availableActions.create_pro_trial.enabled}
                        isDisabled={isNullish(tierConfigs[TIER.PROFESSIONAL])}
                        onSelect={() => handleSelectTier(TIER.PROFESSIONAL)}
                        disabledReason="You cannot create a professional instance."
                      />
                      {activeProject.capabilities.multi_tenant_enterprise && isNotNullish(tierConfigs.mte) ? (
                        <MTETierSelector project={activeProject} onSelect={() => handleSelectTier(TIER.MTE)} />
                      ) : (
                        <EnterpriseTierInfo project={activeProject} />
                      )}
                    </>
                  ) : (
                    <div className="grid grid-cols-2 gap-8">
                      <GdsTierSelector
                        project={activeProject}
                        isDisabled={isNullish(tierConfigs[TIER.GDS])}
                        onSelect={() => handleSelectTier(TIER.GDS)}
                        disabledReason="Aura Data Science is not available in this Project."
                      />
                      <DseTierInfo project={activeProject} />
                    </div>
                  )}
                </div>
                {activeProject.capabilities.multi_tenant_enterprise &&
                  isNotNullish(tierConfigs.mte) &&
                  product === 'AuraDB' && <EnterpriseTierInfo project={activeProject} isSummary />}
              </div>
            )}
            {activeStep === CREATE_STEP.INSTANCE_CONFIGURATION && (
              <CreateInstanceForm
                data={data}
                onDataChange={handleDataChange}
                tierConfig={tierConfigs[data.tier]}
                validation={validation}
                project={activeProject}
                onProductChange={handleProductSelect}
                product={product}
              />
            )}
            {activeStep === CREATE_STEP.BILLING_SETUP && (
              <div className="mx-auto max-w-[50%]">
                <Suspense fallback={<StripeFormFallback />}>
                  <StripeForm
                    onCancel={goBackToPreviousStep}
                    onSuccess={() => handleSubmitForm(data)}
                    cancelButtonText="Back"
                    submitButtonText="Create"
                    isLoading={createInstanceRes.isLoading || createAuraInstanceRes.isLoading}
                  />
                </Suspense>
              </div>
            )}
            {activeStep === CREATE_STEP.PRO_TRIAL_PROFILE_INFORMATION && (
              <ProfileInformationForm
                data={profileInformation}
                validationErrors={profileInformationValidationErrors}
                setValidationErrors={setProfileInformationValidationErrors}
                onChange={setProfileInformation}
              />
            )}
            {isNotNullish(errorMessage) && (
              <Banner className="m-4" type="danger" description={errorMessage} usage="inline" />
            )}
          </>
        )}
      </Dialog.Content>
      {![CREATE_STEP.TIER_SELECTION, CREATE_STEP.BILLING_SETUP].includes(activeStep) && (
        <Dialog.Actions>
          <Button
            fill="outlined"
            color="neutral"
            onClick={getStepNumber(activeStep, expectedSteps) === 1 ? () => closeModal() : goBackToPreviousStep}
            isDisabled={loading}
          >
            {getStepNumber(activeStep, expectedSteps) === 1 ? 'Cancel' : 'Back'}
          </Button>
          <Button
            onClick={
              getStepNumber(activeStep, expectedSteps) === expectedSteps.length
                ? () => handleCreateInstance(data)
                : proceedToNextStep
            }
            isDisabled={isSubmitDisabled}
            isLoading={loading}
          >
            {getStepNumber(activeStep, expectedSteps) === expectedSteps.length ? 'Create' : 'Next'}
          </Button>
        </Dialog.Actions>
      )}
    </Dialog>
  );
};
