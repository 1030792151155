import { useDebounceCallback } from '@neo4j-ndl/react';
import { skipToken } from '@reduxjs/toolkit/query';

import { useDispatch, useSelector } from '../context';
import { useActiveDatabase } from '../slices/connections';
import type {
  CaptionOption,
  GraphStyling,
  NodeStyling,
  RelationStyling,
} from '../slices/graph-styling/graph-styling-slice';
import {
  batchStylingUpdate,
  selectNvlStyling,
  sortingPriorityReordered,
  stylingUpdateForLabel,
  stylingUpdateForRelType,
} from '../slices/graph-styling/graph-styling-slice';
import { useGetGraphStyleQuery, useSetGraphStyleMutation } from '../slices/graph-styling/graph-styling.api';
import { SHARED_STORAGE_LOADING_STATE } from '../slices/shared-storage/types';
import { useUnsafeAppContext } from './use-app-context';
import { useStorageApi } from './use-storage-api';

const mapCaption = (captions?: CaptionOption[]) => {
  const [caption] = captions ?? [];
  const captionKey = caption !== undefined && caption.type === 'property' ? caption.captionKey : undefined;
  return { captionType: caption?.type, captionSize: caption?.captionSize, captionKey: captionKey };
};

const mapNode = (style: Partial<NodeStyling>) => {
  return {
    color: style.color,
    size: style.size,
    ...mapCaption(style.captions),
  };
};

const mapRelationship = (style: Partial<RelationStyling>) => {
  return {
    color: style.color,
    width: style.width,
    ...mapCaption(style.captions),
  };
};

const useGetGraphStylingData = (database: string | undefined) => {
  const storageApiEnabled = useStorageApi('nx:graph-styling-shared-storage');
  const { activeProjectId } = useUnsafeAppContext();
  const skip = activeProjectId === null || !storageApiEnabled || database === undefined;
  const { data, isError, isSuccess } = useGetGraphStyleQuery(skip ? skipToken : { database });
  let state = SHARED_STORAGE_LOADING_STATE.LOADING;

  if (isSuccess) {
    state = SHARED_STORAGE_LOADING_STATE.SUCCESS;
  } else if (isError) {
    state = SHARED_STORAGE_LOADING_STATE.ERROR;
  }

  return { data, state };
};

export const useGraphStyling = (): GraphStyling => {
  const storageApiEnabled = useStorageApi('nx:graph-styling-shared-storage');
  const nvlStyling = useSelector((state) => selectNvlStyling(state));
  const activeDatabase = useActiveDatabase();
  const { data, state } = useGetGraphStylingData(activeDatabase?.selectedName);

  if (storageApiEnabled) {
    if (data !== undefined && state === SHARED_STORAGE_LOADING_STATE.SUCCESS) {
      return { node: data.node, relationship: data.relationship, stylingPrecedence: data.stylingPrecedence };
    }

    return { node: {}, relationship: {}, stylingPrecedence: [] };
  }

  return nvlStyling;
};

export const useUpdateStylingForGraphLabel = ({ debounce = 0 }: { debounce?: number } = {}) => {
  const dispatch = useDispatch();
  const storageApiEnabled = useStorageApi('nx:graph-styling-shared-storage');
  const activeDatabase = useActiveDatabase();
  const [updateStylingForGraphLabel] = useSetGraphStyleMutation();
  const debouncedUpdateStylingForGraphLabel = useDebounceCallback(updateStylingForGraphLabel, debounce);

  if (storageApiEnabled) {
    return (stylingId: string, styling: { color?: string; size?: number; captions?: CaptionOption[] }) => {
      const updateArg = {
        updateGraphStyleBody: {
          database: activeDatabase?.selectedName ?? '',
          node: { [stylingId]: mapNode(styling) },
        },
      };
      if (debounce > 0) {
        void debouncedUpdateStylingForGraphLabel(updateArg);
      } else {
        void updateStylingForGraphLabel(updateArg);
      }
    };
  }

  return (stylingId: string, styling: { color?: string; size?: number; captions?: CaptionOption[] }) => {
    void dispatch(
      stylingUpdateForLabel({
        label: stylingId,
        styling,
      }),
    );
  };
};

export const useUpdateStylingForRelType = ({ debounce = 0 }: { debounce?: number } = {}) => {
  const dispatch = useDispatch();
  const storageApiEnabled = useStorageApi('nx:graph-styling-shared-storage');
  const activeDatabase = useActiveDatabase();
  const [updateStylingForGraphRel] = useSetGraphStyleMutation();
  const debouncedUpdateStylingForGraphRel = useDebounceCallback(updateStylingForGraphRel, debounce);

  if (storageApiEnabled) {
    return (stylingId: string, styling: { color?: string; width?: number; captions?: CaptionOption[] }) => {
      const updateArg = {
        updateGraphStyleBody: {
          database: activeDatabase?.selectedName ?? '',
          relationship: {
            [stylingId]: mapRelationship(styling),
          },
        },
      };

      if (debounce > 0) {
        void debouncedUpdateStylingForGraphRel(updateArg);
      } else {
        void updateStylingForGraphRel(updateArg);
      }
    };
  }

  return (stylingId: string, styling: { color?: string; size?: number; captions?: CaptionOption[] }) => {
    void dispatch(
      stylingUpdateForRelType({
        relType: stylingId,
        styling,
      }),
    );
  };
};

export const useUpdateStylingPrecedence = () => {
  const dispatch = useDispatch();
  const storageApiEnabled = useStorageApi('nx:graph-styling-shared-storage');
  const activeDatabase = useActiveDatabase();
  const [updateStylingPrecendece] = useSetGraphStyleMutation();

  if (storageApiEnabled) {
    return (labels: string[]) => {
      void updateStylingPrecendece({
        updateGraphStyleBody: {
          database: activeDatabase?.selectedName ?? '',
          stylingPrecedence: labels,
        },
      });
    };
  }

  return (labels: string[]) => {
    void dispatch(sortingPriorityReordered(labels));
  };
};

export const useBatchUpdateStyling = () => {
  const dispatch = useDispatch();
  const storageApiEnabled = useStorageApi('nx:graph-styling-shared-storage');
  const activeDatabase = useActiveDatabase();
  const [batchUpdateStyling] = useSetGraphStyleMutation();

  if (storageApiEnabled) {
    return (graphStyling: GraphStyling) => {
      const node = Object.fromEntries(
        Object.entries(graphStyling.node).map(([label, style]) => {
          return [label, mapNode(style)];
        }),
      );

      const relationship = Object.fromEntries(
        Object.entries(graphStyling.relationship).map(([relType, style]) => {
          return [relType, mapRelationship(style)];
        }),
      );

      void batchUpdateStyling({
        updateGraphStyleBody: {
          database: activeDatabase?.selectedName ?? '',
          node,
          relationship,
        },
      });
    };
  }

  return (graphStyling: GraphStyling) => {
    void dispatch(batchStylingUpdate(graphStyling));
  };
};
