import type { Instance, SecurityLogFilterableFields, TimeRange } from '@nx/state';
import { opsApi, useActiveProject } from '@nx/state';
import { Functions, Objects, isNonEmptyString } from '@nx/stdlib';
import { skipToken } from '@reduxjs/toolkit/query';
import { produce } from 'immer';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { getListIfSubsetOrDefault } from '../../shared/utils';
import type { FilterField, SearchField } from '../shared/components/log-filters/request-dialog';
import { RequestDialog } from '../shared/components/log-filters/request-dialog';
import type { TimeRangeSelectorProps } from '../shared/components/log-filters/time-range-selector';
import { WIDTH_MD } from '../shared/constants';
import { FriendlySecurityLogFilterName } from '../shared/mappers';
import { useLogsContext } from './hooks/use-logs-context';
import { useRawFilters } from './hooks/use-raw-filters';
import { initialFilters } from './shared/constants';

export interface SecurityLogFiltersProps {
  setIsRequestDialogOpen: (a: boolean) => void;
  selectedInstance: Instance | undefined;
  timeRange: TimeRange;
  timeRangeSelectorProps: TimeRangeSelectorProps;
}

const filterNames: SecurityLogFilterableFields[] = ['statuses', 'executingUsers', 'authenticatedUsers', 'drivers'];

export const SecurityLogFilters = ({
  setIsRequestDialogOpen,
  selectedInstance,
  timeRange,
  timeRangeSelectorProps,
}: SecurityLogFiltersProps) => {
  const activeProject = useActiveProject();

  const { setRawFilters: setLocalFilters } = useRawFilters();
  const { rawFilters, setRawFilters, setHasRequestedData } = useLogsContext();
  const [shouldLoadFilters, setShouldLoadFilters] = useState(false);

  const didApplyFilters =
    filterNames.some((name) => rawFilters.securityLogFilters[name].length > 0) ||
    Boolean(rawFilters.messageSearchString);

  useEffect(() => {
    setLocalFilters(
      produce((draft) => {
        draft.securityLogFilters = rawFilters.securityLogFilters;
        draft.messageSearchString = rawFilters.messageSearchString;
      }),
    );
  }, [rawFilters.securityLogFilters, rawFilters.messageSearchString, setLocalFilters]);

  const shouldFetch = isNonEmptyString(selectedInstance?.id) && (shouldLoadFilters || didApplyFilters);
  const { data, error, isLoading } = opsApi.useGetSecurityLogFilterValuesQuery(
    shouldFetch
      ? {
          tenantId: activeProject.id,
          dbmsId: selectedInstance.id,
          from: timeRange.startTime.getTime(),
          to: timeRange.endTime.getTime(),
        }
      : skipToken,
  );

  const availableSecurityLogFilters = useMemo(() => data ?? initialFilters().securityLogFilters, [data]);

  const MakeFilterField = useCallback(
    (filterName: SecurityLogFilterableFields): FilterField => {
      const init = initialFilters();
      return {
        id: filterName,
        label: FriendlySecurityLogFilterName[filterName],
        value: getListIfSubsetOrDefault(
          rawFilters.securityLogFilters[filterName],
          availableSecurityLogFilters[filterName],
        ),
        defaultValue: init.securityLogFilters[filterName],
        values: availableSecurityLogFilters[filterName],
        valueFormatter: Functions.identity,
      };
    },
    [availableSecurityLogFilters, rawFilters.securityLogFilters],
  );

  const memoizedFilterFields = useMemo(() => {
    return Objects.fromEntries(filterNames.map((name) => [name, MakeFilterField(name)]));
  }, [MakeFilterField]);

  const memoizedSearchFields = useMemo(() => {
    const queryTextField: SearchField = {
      id: 'Message Text',
      label: 'Message text',
      value: rawFilters.messageSearchString ?? '',
      defaultValue: initialFilters().messageSearchString ?? '',
      placeholder: "e.g. 'logged in'",
    };

    return [queryTextField] satisfies [SearchField];
  }, [rawFilters]);

  return (
    <RequestDialog
      header="Fetch logs"
      modalProps={{ style: { width: WIDTH_MD } }}
      isOpen
      onClose={() => {
        setIsRequestDialogOpen(false);
        setShouldLoadFilters(false);
      }}
      onLoadFilters={() => setShouldLoadFilters(true)}
      onAccept={(fields, search) => {
        const [messageTextField] = memoizedSearchFields;
        setRawFilters(
          produce((draft) => {
            filterNames.forEach((name) => {
              if (fields[name]?.value) {
                draft.securityLogFilters[name] = fields[name].value;
              }
            });

            draft.timeRange = timeRange;
            draft.messageSearchString = search.find((f) => f.id === messageTextField.id)?.value ?? '';
          }),
        );
        setHasRequestedData(true);
      }}
      timeRange={timeRange}
      timeRangeSelectorProps={timeRangeSelectorProps}
      filterFields={memoizedFilterFields}
      searchFields={memoizedSearchFields}
      showFiltersDefault={didApplyFilters}
      isLoading={isLoading}
      filterError={error}
      hasQueries
    />
  );
};
