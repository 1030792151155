import * as std from '@nx/stdlib';
import type { Neo4jError } from 'neo4j-driver';

export type FormattedNeo4jError = {
  code?: string;
  title?: string;
  description?: string;
  documentationUrl?: string;
  originalError?: {
    gqlStatus?: string;
    gqlStatusDescription?: string;
  };
  innerError?: Error;
};

export interface InvalidUrlConnectionError {
  error: Neo4jError;
  discoveryUrls: string[];
}

export type FlattenedCause = {
  gqlStatus: string | undefined;
  gqlStatusDescription: string | undefined;
  cause: FlattenedCause | undefined;
};

export function isNeo4jError(error: unknown): error is Neo4jError {
  if (error !== null && typeof error === 'object') {
    return 'gqlStatus' in error && 'gqlStatusDescription' in error;
  }

  return false;
}

export function isSerializedError(error: unknown): error is Neo4jError {
  if (error !== null && typeof error === 'object') {
    return 'name' in error && 'message' in error && 'code' in error;
  }

  return false;
}

export function isInvalidUrlConnectionError(error: unknown): error is InvalidUrlConnectionError {
  if (error !== null && typeof error === 'object') {
    return 'error' in error && 'discoveryUrls' in error;
  }

  return false;
}

const gqlStatusIndexes = {
  title: 1,
  description: 2,
};

const formatPropertyFromStatusDescripton = (index: number, gqlStatusDescription?: string): string | undefined => {
  const matches = gqlStatusDescription?.match(/^(?:error|info|warn):\s(.+?)(?:\.(.+?))?\.?$/) ?? [];
  return matches[index] === undefined ? undefined : std.Strings.capitalize(matches[index].trim());
};

const formatTitleFromGqlStatusDescription = (gqlStatusDescription?: string): string => {
  return formatPropertyFromStatusDescripton(gqlStatusIndexes.title, gqlStatusDescription)?.trim() ?? '';
};

const formatDescriptionFromGqlStatusDescription = (gqlStatusDescription?: string): string => {
  const description =
    formatPropertyFromStatusDescripton(gqlStatusIndexes.description, gqlStatusDescription)?.trim() ?? '';
  return std.isNonEmptyString(description) && !description.endsWith('.') ? `${description}.` : description;
};

export const formatNeo4jErrorGqlStatusObject = (error?: Neo4jError): FormattedNeo4jError => {
  if (!error || !isNeo4jError(error)) {
    return {};
  }

  const gqlStatusTitle = formatTitleFromGqlStatusDescription(error.gqlStatusDescription);
  const { gqlStatus, gqlStatusDescription } = error;
  const description = formatDescriptionFromGqlStatusDescription(error.gqlStatusDescription);
  const title = std.isNonEmptyString(gqlStatusTitle) ? gqlStatusTitle : description;

  return {
    title: std.isNonEmptyString(title) ? `${gqlStatus}: ${title}` : gqlStatus,
    description,
    // TODO: This will need to be updated when status codes have their own URLs
    ...(gqlStatus && {
      documentationUrl: `https://neo4j.com/docs/status-codes/current/errors/gql-errors/#_${gqlStatus}`,
    }),
    originalError: {
      gqlStatus,
      gqlStatusDescription,
    },
    innerError: error.cause,
  };
};
export const formatNeo4jError = (error: Pick<Neo4jError, 'name' | 'code' | 'message'>): FormattedNeo4jError => {
  const { code, name: title, message: description } = error;

  return {
    code,
    title,
    description,
  };
};

const errorHasGqlFields = (
  error: Error,
): error is Error & {
  gqlStatus: string | undefined;
  gqlStatusDescription: string | undefined;
  cause: Error | undefined;
} => {
  return 'gqlStatus' in error && 'gqlStatusDescription' in error && 'cause' in error;
};

const flattenCause = (cause: Error | undefined): FlattenedCause | undefined => {
  if (cause === undefined || !errorHasGqlFields(cause)) {
    return undefined;
  }

  return {
    gqlStatus: cause.gqlStatus,
    gqlStatusDescription: cause.gqlStatusDescription,
    cause: flattenCause(cause.cause),
  };
};

export const getNeo4jErrorValues = (error: Neo4jError) => ({
  name: error.name,
  code: error.code,
  message: error.message,
  stack: error.stack,
  gqlStatus: error.gqlStatus,
  gqlStatusDescription: error.gqlStatusDescription,
  cause: flattenCause(error.cause),
});
