import { isNotNullish } from '@nx/stdlib';

export const getInitials = (name: string) =>
  name
    .split(' ')
    .map((s) => s[0])
    .join('')
    .substring(0, 2)
    .toLocaleUpperCase();

export const sizeStringToBytesInt = (sizeString: string) => {
  const unitMap: Record<string, number> = {
    B: 10 ** 1,
    KB: 10 ** 3,
    MB: 10 ** 6,
    GB: 10 ** 9,
    TB: 10 ** 12,
  };

  const match = sizeString.match(/^(\d+)\s*([a-zA-Z]+)$/);
  if (isNotNullish(match) && match.length > 2) {
    const [, num, unit] = match;
    const unitString = unitMap[unit?.toUpperCase() ?? ''] ?? 0;
    return parseInt(num ?? '0', 10) * unitString;
  }
  return 0;
};
