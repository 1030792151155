import { ForceDirectedLayoutType, HierarchicalLayoutType } from '@neo4j-nvl/base';
import { neo4jVersionUtil } from '@nx/neo4j-version-utils';

import {
  CATEGORY_LABEL_CAPTION_KEY_PREFIX,
  CATEGORY_NAME_CAPTION_KEY,
  DEFAULT_RELATIONSHIP_COLOR,
  DEFAULT_RELATIONSHIP_SIZE,
  DEFAULT_UNCATEGORISED_TEXT_ALIGN,
  DEFAULT_UNCATEGORISED_TEXT_ALIGN_RELATIONSHIP,
  DEFAULT_UNCATEGORISED_TEXT_SIZE,
  RELATIONSHIP_TYPE_CAPTION_KEY,
} from '../styles/styles.const';
import { CAPTION_TYPE_LABEL, CAPTION_TYPE_PROPERTY, CAPTION_TYPE_REL } from '../styles/types.ts';
import { CoordinateLayout, DEFAULT_COORDINATE_X_SCALE, DEFAULT_COORDINATE_Y_SCALE } from '../visualization/constants';

const { compare } = neo4jVersionUtil;

export const trasformations = (perspectiveStyle, isCytoscapeEnabled = true) => [
  {
    version: '2.2.0',
    up: (state) => {
      // copy captionKeys from perspective to scene
      return {
        ...state,
        style: {
          ...state.style,
          categories: state.style.categories.map((category) => {
            const perspectiveCategoryStyle = perspectiveStyle?.categories.find(({ id }) => category.id === id);
            return {
              ...category,
              captionKeys: perspectiveCategoryStyle ? [...perspectiveCategoryStyle.captionKeys] : [],
            };
          }),
          relationshipTypes: state.style.relationshipTypes.map((relationshipType) => {
            const perspectiveRelationshipTypeStyle = perspectiveStyle?.relationshipTypes.find(
              ({ id }) => relationshipType.id === id,
            );
            return {
              color: DEFAULT_RELATIONSHIP_COLOR,
              size: DEFAULT_RELATIONSHIP_SIZE,
              ...relationshipType,
              captionKeys: perspectiveRelationshipTypeStyle ? [...perspectiveRelationshipTypeStyle.captionKeys] : [],
            };
          }),
        },
      };
    },
  },
  {
    version: '2.4.0',
    up: (state) => {
      // move layoutDirection and isCytoscapeEnabled
      const newVisualization = {
        ...state.visualisation,
        layoutOptions: {
          [ForceDirectedLayoutType]: {
            isCytoscapeEnabled,
            simulationStopVelocity: 100,
            gravity: 150,
          },
          [HierarchicalLayoutType]: { direction: state.visualisation?.layoutDirection || 'down' },
        },
      };
      delete newVisualization.layoutDirection;
      return {
        ...state,
        visualisation: newVisualization,
      };
    },
  },
  {
    version: '2.6.0',
    up: (state) => {
      return {
        ...state,
        ranges: [],
        scrubbedOutNodeIds: {},
        scrubbedOutRelsIds: {},
      };
    },
  },
  {
    version: '2.7.0',
    up: (state) => {
      delete state.scrubbedOutNodeIds;
      delete state.scrubbedOutRelsIds;

      const coordinateOptions = state.visualisation?.layoutOptions[CoordinateLayout];
      const newVisualization = {
        ...state.visualisation,
        layoutOptions: {
          ...state.visualisation?.layoutOptions,
          [CoordinateLayout]: {
            X: coordinateOptions?.X || null,
            Y: coordinateOptions?.Y || null,
            XScale: coordinateOptions?.XScale || DEFAULT_COORDINATE_X_SCALE,
            YScale: coordinateOptions?.YScale || DEFAULT_COORDINATE_Y_SCALE,
          },
        },
      };
      return {
        ...state,
        visualisation: newVisualization,
        ranges: [],
      };
    },
  },
  {
    version: '2.8.0',
    up: (state) => {
      return {
        ...state,
        style: {
          ...state.style,
          categories: (state.style?.categories || []).map((category) => {
            const newCategoryPropertyCaptions = (category?.captionKeys || []).reduce((acc, a) => {
              if (a === CATEGORY_NAME_CAPTION_KEY) return acc;
              acc = [
                ...acc,
                {
                  key: a.startsWith(CATEGORY_LABEL_CAPTION_KEY_PREFIX)
                    ? a.replace(CATEGORY_LABEL_CAPTION_KEY_PREFIX, '')
                    : a,
                  styles: [],
                  inTooltip: true,
                  isCaption: true,
                  type: a.startsWith(CATEGORY_LABEL_CAPTION_KEY_PREFIX) ? CAPTION_TYPE_LABEL : CAPTION_TYPE_PROPERTY,
                },
              ];
              return acc;
            }, []);

            const newStyleRules = (category?.styleRules || []).map((rule) => {
              const newRulePropertyCaptions = (rule?.captionKeys || []).reduce((acc, a) => {
                if (a === CATEGORY_NAME_CAPTION_KEY) return acc;
                acc = [
                  ...acc,
                  {
                    key: a.startsWith(CATEGORY_LABEL_CAPTION_KEY_PREFIX)
                      ? a.replace(CATEGORY_LABEL_CAPTION_KEY_PREFIX, '')
                      : a,
                    styles: [],
                    inTooltip: true,
                    isCaption: true,
                    type: a.startsWith(CATEGORY_LABEL_CAPTION_KEY_PREFIX) ? CAPTION_TYPE_LABEL : CAPTION_TYPE_PROPERTY,
                  },
                ];
                return acc;
              }, []);

              return {
                ...rule,
                captions: [...newRulePropertyCaptions],
                textAlign: DEFAULT_UNCATEGORISED_TEXT_ALIGN,
                textSize: DEFAULT_UNCATEGORISED_TEXT_SIZE,
              };
            });

            return {
              ...category,
              captions: [...newCategoryPropertyCaptions],
              styleRules: [...newStyleRules],
              textAlign: DEFAULT_UNCATEGORISED_TEXT_ALIGN,
              textSize: DEFAULT_UNCATEGORISED_TEXT_SIZE,
            };
          }),
          relationshipTypes: (state.style?.relationshipTypes || []).map((relType) => {
            const newRelPropertyCaptions = (relType?.captionKeys || []).reduce((acc, a) => {
              acc = [
                ...acc,
                {
                  key: a === RELATIONSHIP_TYPE_CAPTION_KEY ? relType.id : a,
                  styles: [],
                  inTooltip: true,
                  isCaption: true,
                  type: a === RELATIONSHIP_TYPE_CAPTION_KEY ? CAPTION_TYPE_REL : CAPTION_TYPE_PROPERTY,
                },
              ];
              return acc;
            }, []);

            const newStyleRules = (relType?.styleRules || []).map((rule) => {
              const newRulePropertyCaptions = (rule?.captionKeys || []).reduce((acc, a) => {
                acc = [
                  ...acc,
                  {
                    key: a === RELATIONSHIP_TYPE_CAPTION_KEY ? relType.id : a,
                    styles: [],
                    inTooltip: true,
                    isCaption: true,
                    type: a === RELATIONSHIP_TYPE_CAPTION_KEY ? CAPTION_TYPE_REL : CAPTION_TYPE_PROPERTY,
                  },
                ];
                return acc;
              }, []);

              return {
                ...rule,
                captions: [...newRulePropertyCaptions],
                textAlign: DEFAULT_UNCATEGORISED_TEXT_ALIGN_RELATIONSHIP,
                textSize: DEFAULT_UNCATEGORISED_TEXT_SIZE,
              };
            });

            return {
              ...relType,
              captions: [...newRelPropertyCaptions],
              styleRules: [...newStyleRules],
              textAlign: DEFAULT_UNCATEGORISED_TEXT_ALIGN_RELATIONSHIP,
              textSize: DEFAULT_UNCATEGORISED_TEXT_SIZE,
            };
          }),
        },
      };
    },
  },
  {
    version: '2.15.0',
    up: (state) => {
      const prevVisualisation = state.visualisation;
      const prevForceDirectedLayoutOptions = prevVisualisation?.layoutOptions?.[ForceDirectedLayoutType];
      const newVisualization = {
        ...prevVisualisation,
        layoutOptions: {
          ...prevVisualisation.layoutOptions,
          [ForceDirectedLayoutType]: {
            ...prevForceDirectedLayoutOptions,
            enableCytoscape: prevForceDirectedLayoutOptions?.isCytoscapeEnabled,
          },
        },
      };
      delete newVisualization.layoutOptions[ForceDirectedLayoutType].isCytoscapeEnabled;
      return {
        ...state,
        visualisation: newVisualization,
      };
    },
  },
  {
    version: '2.20.0',
    up: (state) => {
      return {
        ...state,
      };
    },
  },
];

export const getLatestSceneVersion = () =>
  trasformations(null)
    .map((n) => n.version)
    .sort(compare)
    .pop();
