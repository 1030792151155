import type { CLOUD_PROVIDER, EncryptionKeyRef, InstanceSize, TIER } from '@nx/state';

export interface InstanceFormData {
  tier: TIER;
  name?: string;
  version: string;
  region?: string;
  size?: InstanceSize;
  confirmed: boolean;
  cloudProvider: CLOUD_PROVIDER;
  encryptionKeyRef?: EncryptionKeyRef;
  vectorOptimized: boolean;
  gdsPlugin: boolean;
}

export type InstanceSizeFormData = Pick<InstanceFormData, 'version' | 'region' | 'size' | 'cloudProvider' | 'tier'>;

export const INSTANCE_NAME_CHARACTER_LIMIT = 30;

export enum CREATE_STEP {
  TIER_SELECTION = 'tier-selection',
  INSTANCE_CONFIGURATION = 'instance-configuration',
  BILLING_SETUP = 'billing-setup',
  PRO_TRIAL_PROFILE_INFORMATION = 'pro-trial-profile-information',
}

export type Product = 'AuraDB' | 'AuraDS';
