import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { getRelationships } from '../graph/relationships';
import { CLEAR_SCENE } from '../rootActions';
import type { RootState } from '../types';
import type { FocusedItem, FocusedState } from './types';

export const NAME = 'focused';

// Selectors
export function getFocusedNodeId(state: RootState) {
  return 'nodeId' in state[NAME].focused ? state[NAME].focused.nodeId : undefined;
}

export function getFocusedRelationshipId(state: RootState) {
  return 'relationshipId' in state[NAME].focused ? state[NAME].focused.relationshipId : undefined;
}

export function getFocusedRelationship(state: RootState) {
  const relationshipsIndex = getRelationships(state);
  // @ts-expect-error getRelationships is not typed yet
  return relationshipsIndex[getFocusedRelationshipId(state)];
}

export function getFocusedNewNodeCreator(state: RootState): FocusedState['focusedNewNodeCreator'] {
  return state[NAME].focusedNewNodeCreator;
}

export function getFocusedNewRelationshipCreator(state: RootState): FocusedState['focusedNewRelationshipCreator'] {
  return state[NAME].focusedNewRelationshipCreator;
}

// Reducer
export const initialState: FocusedState = {
  previouslyFocused: [],
  focused: {},
  focusedRelationshipType: {},
};

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    focusNode(state, action: PayloadAction<string>) {
      if ('nodeId' in state.focused && state.focused?.nodeId !== action.payload) {
        state.previouslyFocused.push(state.focused);
      }
      state.focused = { nodeId: action.payload, relationshipId: undefined };
      state.focusedRelationshipType = {};
    },
    focusRelationship(state, action: PayloadAction<string>) {
      if ('relationshipId' in state.focused && state.focused?.relationshipId !== action.payload) {
        state.previouslyFocused.push(state.focused);
      }
      state.focused = { nodeId: undefined, relationshipId: action.payload };
      state.focusedRelationshipType = {};
    },
    defocusEntity(state) {
      state.focused = state.previouslyFocused.length > 0 ? (state.previouslyFocused.pop() as FocusedItem) : {};
      state.focusedRelationshipType = {};
    },
    defocusAllEntities(state) {
      const {
        previouslyFocused,
        focused,
        focusedRelationshipType,
        focusedNewNodeCreator,
        focusedNewRelationshipCreator,
      } = initialState;
      state.previouslyFocused = previouslyFocused;
      state.focused = focused;
      state.focusedRelationshipType = focusedRelationshipType;
      state.focusedNewNodeCreator = focusedNewNodeCreator;
      state.focusedNewRelationshipCreator = focusedNewRelationshipCreator;
    },
    focusRelationshipType(state, action: PayloadAction<FocusedState['focusedRelationshipType']>) {
      state.focusedRelationshipType = action.payload;
    },
    focusNewNodeCreator(state, action: PayloadAction<FocusedState['focusedNewNodeCreator']>) {
      if ('nodeId' in state.focused || 'relationshipId' in state.focused) {
        state.previouslyFocused.push(state.focused);
      }
      state.focused = initialState.focused;
      state.focusedNewNodeCreator = action.payload;
    },
    focusNewRelationshipCreator(state, action: PayloadAction<FocusedState['focusedNewRelationshipCreator']>) {
      if ('nodeId' in state.focused || 'relationshipId' in state.focused) {
        state.previouslyFocused.push(state.focused);
      }
      state.focused = initialState.focused;
      state.focusedNewRelationshipCreator = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(CLEAR_SCENE, () => initialState);
  },
});

export const {
  focusNode,
  focusRelationship,
  defocusEntity,
  defocusAllEntities,
  focusRelationshipType,
  focusNewNodeCreator,
  focusNewRelationshipCreator,
} = slice.actions;
export default slice.reducer;
