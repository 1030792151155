import type { JSONSchemaType } from 'ajv';
import type { Integer } from 'neo4j-driver';

import type { BaseArguments } from '../types/sdk-types';

export type GraphCounts = {
  nodeCount: number;
  relCount: number;
  timeTaken: number;
};

export async function getGraphCounts({ queryCypher }: BaseArguments): Promise<GraphCounts> {
  const query = `MATCH (n) RETURN count(n) AS result
UNION ALL
MATCH ()-[r]->() RETURN count(r) AS result`;

  const res = await queryCypher(query);

  // Types are validated in e2e tests
  // eslint-disable-next-line
  const [nodeCount, relCount] = res.records.map((r) => r.toObject().result) as [Integer, Integer];

  return {
    nodeCount: nodeCount.toNumber(),
    relCount: relCount.toNumber(),
    timeTaken: res.summary.resultAvailableAfter.toNumber() + res.summary.resultConsumedAfter.toNumber(),
  };
}

export const graphCountsSchema: JSONSchemaType<GraphCounts> = {
  type: 'object',
  properties: {
    nodeCount: { type: 'number' },
    relCount: { type: 'number' },
    timeTaken: { type: 'number' },
  },
  required: ['nodeCount', 'relCount', 'timeTaken'],
};
