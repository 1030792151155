import type { SecurityLog, SecurityLogFilterInput, SecurityLogSummary } from '../api-types';
import type { LogsTimeRange } from './logs';

export type TimeRangeSecurityLog = Omit<SecurityLog, 'time'> & {
  time?: Date;
};

export type TimeRangeSecurityLogSummary = Omit<SecurityLogSummary, 'from' | 'to'> & {
  from?: Date;
  to?: Date;
};

// used around dropdowns & context menus
export type SecurityLogFilterableFields = keyof Pick<
  SecurityLogFilterInput,
  'databases' | 'executingUsers' | 'statuses' | 'authenticatedUsers' | 'drivers'
>;

export const SECURITY_STATUS_MAP = {
  Info: 'INFO',
  Warning: 'WARNING',
  Error: 'ERROR',
  Unknown: 'UNKNOWN',
};
export const SECURITY_FORMATTED_AVAILABLE_STATUSES = Object.keys(SECURITY_STATUS_MAP).sort();

type NormalizedSecurityLogFilterInput = Omit<SecurityLogFilterInput, 'statuses'> & {
  statuses?: typeof SECURITY_FORMATTED_AVAILABLE_STATUSES;
};

// what comes from dbms, always an array
export type AvailableSecurityLogFilters = {
  [key in SecurityLogFilterableFields]: Exclude<NormalizedSecurityLogFilterInput[key], null | undefined>;
};

// what the user selects
export type SelectedSecurityLogFilters = {
  [key in SecurityLogFilterableFields]: AvailableSecurityLogFilters[key];
};

// source of truth
export type SecurityLogRawFilterSelection = {
  securityLogFilters: SelectedSecurityLogFilters;
  timeRange: LogsTimeRange;
  message?: SecurityLogFilterInput['message'];
  messageSearchString?: SecurityLogFilterInput['messageSearchString'];
};
