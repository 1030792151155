import { customCreateApi } from '../../context';
import { createDynamicBaseQuery } from '../../utils/create-dynamic-base-query';
import { selectAuraConfiguration } from '../configuration/configuration-slice';
import { getAccessToken } from '../session-slice';

export const sharedStorageApi = customCreateApi({
  baseQuery: createDynamicBaseQuery((state) => selectAuraConfiguration(state)?.storageApiUrl, {
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('Authorization', `Bearer ${accessToken}`);
    },
  }),
  reducerPath: 'sharedStorageApi',
  endpoints: () => ({}),
});
