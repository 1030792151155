import { Dialog, IconButton } from '@neo4j-ndl/react';
import { PencilIconOutline } from '@neo4j-ndl/react/icons';
import type { EncryptionKey, Project } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { useState } from 'react';

import { getCloudProviders } from '../entities/helpers';
import { CmekPermissionsInstructions } from './instructions/cmek-dialog';

export const GetKeyPermissions = ({ project, encryptionKey }: { project: Project; encryptionKey: EncryptionKey }) => {
  const [cloudProvider] = getCloudProviders(project.tierConfigs);
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <>
      <IconButton
        ariaLabel="Get encryption key"
        isClean
        onClick={() => setDialogOpen(true)}
        htmlAttributes={{
          'data-testid': `get-cmek-button-${encryptionKey.encryptionKeyRef}`,
          title: 'Get encryption key',
        }}
      >
        <PencilIconOutline />
      </IconButton>
      {dialogOpen && isNotNullish(cloudProvider) && (
        <Dialog isOpen onClose={() => setDialogOpen(false)}>
          <CmekPermissionsInstructions
            cloudProvider={cloudProvider}
            encryptionKey={encryptionKey}
            onClose={() => setDialogOpen(false)}
          />
        </Dialog>
      )}
    </>
  );
};
