import { format, isValid, parseISO } from 'date-fns';

export const safeFormat: typeof format = (date, formatStr, options) => {
  if (isValid(date)) {
    return format(date, formatStr, options);
  }
  return 'Invalid Date';
};

export const formatTimestamp = (timestamp: string, timeFormat: string) => {
  const ts = parseISO(timestamp);
  if (isValid(ts)) {
    const date = format(ts, timeFormat);
    return date;
  }
  return null;
};
