import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import type { RootState } from '@nx/state';
import { createDynamicBaseQuery, getSharedStorageUrl, prepareHeaders, LEGACY_store as store } from '@nx/state';
import { QueryStateContext } from '@query/redux/query-state-context';
import { buildCreateApi, coreModule, reactHooksModule } from '@reduxjs/toolkit/query/react';
import { createDispatchHook, createSelectorHook, createStoreHook } from 'react-redux';

const logger = createLogger(APP_SCOPE.query);

export const customCreateApi = buildCreateApi(
  coreModule(),
  reactHooksModule({
    hooks: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      useDispatch: createDispatchHook(QueryStateContext),
      useSelector: createSelectorHook(QueryStateContext),
      useStore: createStoreHook(QueryStateContext),
    },
  }),
);

export const baseApi = customCreateApi({
  reducerPath: 'storageApi',
  baseQuery: createDynamicBaseQuery(
    (state: RootState) => getSharedStorageUrl(state, logger.error),
    {
      prepareHeaders,
    },
    (): RootState => store.getState(),
  ),
  endpoints: () => ({}),
});
