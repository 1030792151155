import { Select, TextLink } from '@neo4j-ndl/react';
import type { Instance } from '@nx/state';
import { TIER, useActiveProject, useEncryptionKeys } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import { useNavigate } from 'react-router-dom';

import { encryptionKeysToOptions } from '../entities/helpers';
import type { InstanceFormData } from '../entities/model';

type EncryptionKeySectionProps = {
  errorMessage?: string;
  onChange: (value: string) => void;
  data: InstanceFormData;
  instance?: Instance;
};

export const EncryptionKeySection = ({ errorMessage, data, onChange, instance }: EncryptionKeySectionProps) => {
  const activeProject = useActiveProject();
  const { keys } = useEncryptionKeys();
  const navigate = useNavigate();

  if (!activeProject.capabilities.cmek || ![TIER.ENTERPRISE, TIER.AURA_DSE].includes(data.tier)) {
    return null;
  }
  const filteredKeys = keys.filter((key) => key.tier === data.tier && key.region === data.region);

  const encryptionKeyOptions = encryptionKeysToOptions(filteredKeys, isNullish(instance?.encryptionKeyRef));

  const handleChange = (value?: string) => {
    if (isNotNullish(value)) {
      onChange(value);
    }
  };

  return (
    <div className="encryption-settings">
      <Select
        size="medium"
        type="select"
        selectProps={{
          value: encryptionKeyOptions.find((key) => key.value === data.encryptionKeyRef),
          options: encryptionKeyOptions,
          onChange: (e) => handleChange(e?.value),
          menuPosition: 'fixed',
        }}
        errorText={errorMessage}
        label="Encryption Settings"
      />
      <span className="mt-2 text-xs font-light" data-testid="cmek-link">
        You can configure Customer Managed Keys{' '}
        <TextLink
          as="button"
          htmlAttributes={{
            onClick: () => {
              navigate(`/projects/${activeProject.id}/settings/cmek`);
            },
          }}
        >
          here
        </TextLink>
        .
      </span>
    </div>
  );
};
