import { Banner, Button, IconButton, LoadingSpinner, Typography } from '@neo4j-ndl/react';
import { FunnelIconOutline } from '@neo4j-ndl/react/icons';
import { MODAL_TYPE, useListLocalInstancesQuery, useModal } from '@nx/state';
import { SearchField } from '@nx/ui';
import { useState } from 'react';

import CreateInstance from '../assets/create-instance-empty-state.svg';
import { InstanceTable } from './instance-table';

const ApiErrorBanner = ({ error }: { error: unknown }) => {
  return (
    <Banner
      isCloseable={false}
      className="m-8"
      description={JSON.stringify(error, null, 2)}
      title="API Error"
      type="danger"
      usage="inline"
    />
  );
};

const NewInstance = () => {
  const createInstanceModal = useModal(MODAL_TYPE.INSTANCE_CREATE);

  return (
    <>
      <div className="flex h-full flex-col items-center justify-center gap-16 md:flex-row">
        <img src={CreateInstance} alt="" />
        <div className="flex max-w-[420px] flex-col gap-6">
          <h4>Create your first instance</h4>
          <Typography variant="body-large">
            Configure your instance and optionally load your data from a file.
          </Typography>
          <Button className="self-start" size="large" onClick={() => createInstanceModal.open()}>
            Create instance
          </Button>
        </div>
      </div>
    </>
  );
};

export const InstancesPage = () => {
  const {
    data: instances = [],
    isLoading,
    isError,
    error,
  } = useListLocalInstancesQuery(undefined, { pollingInterval: 5000, skipPollingIfUnfocused: true });

  const [filter, setFilter] = useState('');
  const createInstanceModal = useModal(MODAL_TYPE.INSTANCE_CREATE);

  const normalizedFilter = filter.trim().toLocaleLowerCase();
  const filteredInstances = normalizedFilter
    ? instances.filter(({ name, id }) => [name, id].some((str) => str.toLocaleLowerCase().includes(normalizedFilter)))
    : instances;

  if (isLoading) {
    return <LoadingSpinner size="large" className="relative left-1/2 top-1/2" />;
  }

  if (isError) {
    return <ApiErrorBanner error={error} />;
  }

  if (instances.length === 0) {
    return <NewInstance />;
  }

  return (
    <div className="relative flex h-full flex-col">
      <div className="bg-palette-neutral-bg-weak border-palette-neutral-border-weak flex h-14 w-full items-center justify-between gap-4 border-b py-[10px]">
        <div className="ml-6 flex basis-[400px] gap-2">
          <SearchField
            className="min-w-36 grow"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            htmlAttributes={{ 'aria-label': 'Search for instance' }}
          />
          <IconButton ariaLabel="Filter instances">
            <FunnelIconOutline />
          </IconButton>
        </div>
        <div className="mr-4 flex shrink-0 gap-4">
          <Button onClick={() => createInstanceModal.open()}>Create instance</Button>
        </div>
      </div>
      {filteredInstances.length > 0 ? (
        <InstanceTable instances={filteredInstances} />
      ) : (
        <div className="flex h-[218px] items-center justify-center">
          <h6>No instances found</h6>
        </div>
      )}
    </div>
  );
};
