/**
 * Compare equality based on the content (and lengths) of two arrays. Used
 * for example for "selected" states.
 */
export const idArraysAreEqual = <First extends unknown[], Second extends unknown[]>(arr1: First, arr2: Second) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sorted1 = arr1.sort(); // eslint-disable-line @typescript-eslint/require-array-sort-compare
  const sorted2 = arr2.sort(); // eslint-disable-line @typescript-eslint/require-array-sort-compare

  for (let i = 0; i < sorted1.length; i++) {
    if (sorted1[i] !== sorted2[i]) {
      return false;
    }
  }

  return true;
};
