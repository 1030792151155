import { Divider, IconButton, Menu } from '@neo4j-ndl/react';
import { EllipsisHorizontalIconOutline } from '@neo4j-ndl/react/icons';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { DATA_API_STATUS, type DataApiBatchResult, graphqlApi, useActiveProject } from '@nx/state';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { SyntheticEvent } from 'react';
import { useRef, useState } from 'react';

import { DeleteDataApiModal } from './delete-modal';

const logger = createLogger(APP_SCOPE.dataApi);

interface DataApiActionMenuProps {
  dataApi: DataApiBatchResult;
  onClickEdit: () => void;
  onClickMenuItemAction: () => void;
  setMenuActionError: (err: FetchBaseQueryError | SerializedError | undefined) => void;
}

export const DataApiActionMenu = ({
  dataApi,
  setMenuActionError,
  onClickEdit,
  onClickMenuItemAction,
}: DataApiActionMenuProps) => {
  const activeProject = useActiveProject();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [resumeDataApi, resumeRes] = graphqlApi.useResumeDataApiMutation();
  const [pauseDataApi, pauseRes] = graphqlApi.usePauseDataApiMutation();
  const anchorEl = useRef(null);
  const isPauseState = dataApi.status === DATA_API_STATUS.pausing || dataApi.status === DATA_API_STATUS.paused;
  const isDoingWorkState = [
    DATA_API_STATUS.pausing,
    DATA_API_STATUS.resuming,
    DATA_API_STATUS.updating,
    DATA_API_STATUS.creating,
  ].includes(dataApi.status);

  const handleResume = (event: SyntheticEvent) => {
    event.preventDefault();
    resumeDataApi({
      projectId: activeProject.id,
      instanceId: dataApi.instanceId,
      dataApiId: dataApi.id,
    })
      .unwrap()
      .catch((error: FetchBaseQueryError | SerializedError | undefined) => {
        if (resumeRes.error) {
          setMenuActionError(resumeRes.error);
        }
        logger.error(error);
      })
      .finally(() => {
        onClickMenuItemAction();
        setIsMenuOpen(false);
      });
  };

  const handlePause = (event: SyntheticEvent) => {
    event.preventDefault();
    pauseDataApi({
      projectId: activeProject.id,
      instanceId: dataApi.instanceId,
      dataApiId: dataApi.id,
    })
      .unwrap()
      .catch((error: FetchBaseQueryError | SerializedError | undefined) => {
        if (pauseRes.error) {
          setMenuActionError(pauseRes.error);
        }
        logger.error(error);
      })
      .finally(() => {
        onClickMenuItemAction();
        setIsMenuOpen(false);
      });
  };

  return (
    <>
      {isDeleteModalOpen && (
        <DeleteDataApiModal
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={() => {
            onClickMenuItemAction();
            setIsDeleteModalOpen(false);
            setIsMenuOpen(false);
          }}
          dataApi={dataApi}
        />
      )}
      <IconButton ref={anchorEl} ariaLabel="Data API actions" size="small" onClick={() => setIsMenuOpen(true)}>
        <EllipsisHorizontalIconOutline className="h-full w-full" />
      </IconButton>
      <Menu isOpen={isMenuOpen} anchorRef={anchorEl} onClose={() => setIsMenuOpen(false)}>
        <Menu.Items>
          {!isPauseState && <Menu.Item id="pause" title="Pause" isDisabled={isDoingWorkState} onClick={handlePause} />}
          {isPauseState && (
            <Menu.Item id="resume" title="Resume" isDisabled={isDoingWorkState} onClick={handleResume} />
          )}
          <Menu.Item id="edit" title="Edit" onClick={() => onClickEdit()} />
          <Divider orientation="horizontal" />
          <Menu.Item
            id="delete"
            title="Delete"
            className="text-danger-text"
            onClick={() => setIsDeleteModalOpen(true)}
          />
        </Menu.Items>
      </Menu>
    </>
  );
};
