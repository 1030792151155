import '@neo4j-ndl/base/lib/neo4j-ds-styles.css';
import { LoadingSpinner, NeedleThemeProvider } from '@neo4j-ndl/react';
import type { Logger } from '@nx/logger';
import { ReduxProvider, getBaseUrl, persistor, LEGACY_store as store, useInternalConfiguration } from '@nx/state';
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { useDarkMode } from '../hooks/use-dark-mode';
import './index.css';
import { SSORedirect } from './sso-redirect';
import WindowTitle from './window-title';

function AppWindowTitle() {
  const { windowTitle } = useInternalConfiguration();
  return <WindowTitle title={windowTitle} />;
}

type FrameworkProviderProps = React.PropsWithChildren<{ logger: Logger }>;

export function StyleProvider({ children }: { children: React.ReactNode }) {
  const isDarkModeEnabled = useDarkMode();

  return <NeedleThemeProvider theme={isDarkModeEnabled ? 'dark' : 'light'}>{children}</NeedleThemeProvider>;
}

export function FrameworkProvider({ children, logger }: FrameworkProviderProps) {
  return (
    <ReduxProvider store={store}>
      <PersistGate
        onBeforeLift={() => {
          logger.debug('State rehydrated');
        }}
        loading={<LoadingSpinner />}
        persistor={persistor}
      >
        <BrowserRouter basename={getBaseUrl()}>
          <AppWindowTitle />
          <SSORedirect />
          <React.Suspense fallback={<LoadingSpinner />}>
            <StyleProvider>
              <div className="flex h-full w-full">{children}</div>
            </StyleProvider>
          </React.Suspense>
        </BrowserRouter>
      </PersistGate>
    </ReduxProvider>
  );
}
