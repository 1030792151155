import type { EndpointBuilder } from '@reduxjs/toolkit/query';

import type { DynamicBaseQueryFn } from '../../../utils/create-dynamic-base-query';

export type TagTypes = 'Session';

export const tagTypes: TagTypes[] = ['Session'] as const;

export type Builder = EndpointBuilder<DynamicBaseQueryFn, TagTypes, 'dataScienceApi'>;

export const LIST_ID = 'LIST';
