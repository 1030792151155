import type { EntityState } from '@reduxjs/toolkit';
import { createEntityAdapter } from '@reduxjs/toolkit';

import type { WatchLocalInstanceResult } from '../relate.subscriptions';
import { WATCH_DBMSS, registerSubscription } from '../relate.subscriptions';
import type { LocalInstance, LocalInstanceInfo, LocalInstanceInstallArgs, LocalInstanceVersion } from '../relate.types';
import type { Builder } from './types';

const localInstancesAdapter = createEntityAdapter<LocalInstanceInfo>();

export const dbmsEndpoints = (builder: Builder) => ({
  getLocalInstance: builder.query<LocalInstanceInfo, string>({
    query: (id) => ({ url: 'get-dbms', params: { dbmsId: id } }),
  }),

  listLocalInstances: builder.query<LocalInstance[], void>({
    query: () => 'list-dbmss',
    transformResponse(response: LocalInstanceInfo[]) {
      return response.sort((a, b) => a.name.localeCompare(b.name));
    },
    providesTags: ['LocalInstance'],
  }),

  infoLocalInstances: builder.query<EntityState<LocalInstanceInfo, string>, string[] | void>({
    query: (dbmsIds) => ({
      url: 'info-dbmss',
      params: {
        dbmsIds,
        onlineCheck: true,
      },
    }),

    transformResponse(response: LocalInstanceInfo[]) {
      return localInstancesAdapter.addMany(localInstancesAdapter.getInitialState(), response);
    },

    onCacheEntryAdded(arg, api) {
      return registerSubscription<WatchLocalInstanceResult>(api, WATCH_DBMSS, ({ data }) => {
        const localInstanceStarted = data?.watchDbmss.started;
        const localInstanceStopped = data?.watchDbmss.stopped;
        const localInstanceInstalled = data?.watchDbmss.installed;
        const localInstanceUninstalled = data?.watchDbmss.uninstalled;

        api.updateCachedData((draft) => {
          if (localInstanceStarted) {
            localInstancesAdapter.updateOne(draft, { id: localInstanceStarted.id, changes: localInstanceStarted });
          }

          if (localInstanceStopped) {
            localInstancesAdapter.updateOne(draft, { id: localInstanceStopped.id, changes: localInstanceStopped });
          }

          if (localInstanceInstalled) {
            localInstancesAdapter.addOne(draft, localInstanceInstalled);
          }

          if (localInstanceUninstalled !== undefined && localInstanceUninstalled !== null) {
            localInstancesAdapter.removeOne(draft, localInstanceUninstalled);
          }
        });
      });
    },

    providesTags: (result) => (result?.ids ?? []).map((id) => ({ type: 'LocalInstance', id: id.toString() })),
  }),

  listNeo4jVersions: builder.query<LocalInstanceVersion[], void>({
    query: () => 'list-dbms-versions',
  }),

  startLocalInstance: builder.mutation<string, string>({
    query: (dbmsId) => ({
      url: 'start-dbmss',
      method: 'POST',
      body: {
        dbmsIds: [dbmsId],
      },
    }),
  }),

  stopLocalInstance: builder.mutation<boolean, string>({
    query: (dbmsId) => ({
      url: 'stop-dbmss',
      method: 'POST',
      body: {
        dbmsIds: [dbmsId],
      },
    }),
  }),

  installLocalInstance: builder.mutation<string, LocalInstanceInstallArgs>({
    query: (args) => ({
      url: 'install-dbms-desktop',
      method: 'POST',
      body: args,
    }),
    invalidatesTags: ['LocalInstance'],
  }),

  uninstallLocalInstance: builder.mutation<string, string>({
    query: (dbmsId) => ({
      url: 'uninstall-dbms',
      method: 'POST',
      body: {
        name: dbmsId,
      },
    }),
    invalidatesTags: ['LocalInstance'],
  }),

  upgradeLocalInstance: builder.mutation<string, { dbmsId: string; targetVersion: string }>({
    query: ({ dbmsId, targetVersion }) => ({
      url: 'upgrade-dbms',
      method: 'POST',
      body: {
        dbmsId,
        version: targetVersion,
      },
    }),

    invalidatesTags: ['LocalInstance'],
  }),

  cloneLocalInstance: builder.mutation<string, { dbmsId: string; newName: string }>({
    query: ({ dbmsId, newName }) => ({
      url: 'clone-dbms',
      method: 'POST',
      body: {
        dbmsId,
        newName,
      },
    }),
    invalidatesTags: ['LocalInstance'],
  }),
});
