import { Checkbox, TextLink, Typography } from '@neo4j-ndl/react';
import { REGIONAL_GDPR_COMPLIANCE_TIER } from '@nx/state';

type MarketingOptInProps = {
  regionalTier: REGIONAL_GDPR_COMPLIANCE_TIER;
  marketingPreference: boolean;
  onChange: () => void;
};

export const MarketingOptIn = ({ regionalTier, marketingPreference, onChange }: MarketingOptInProps) => {
  const checkboxLabel = {
    [REGIONAL_GDPR_COMPLIANCE_TIER.TIER_2]: (
      <Typography variant="body-medium">
        No, <span className="underline">do not</span> keep me updated on the latest Neo4j news and updates.
      </Typography>
    ),
    [REGIONAL_GDPR_COMPLIANCE_TIER.TIER_3]: (
      <Typography variant="body-medium">Yes, keep me updated on the latest Neo4j news and updates</Typography>
    ),
  };

  return (
    <div className="flex flex-col justify-between gap-4" data-testid="marketing-consent-section">
      <Typography variant="body-small">
        By continuing, you confirm you are acting for your business/organization.
      </Typography>
      {regionalTier !== REGIONAL_GDPR_COMPLIANCE_TIER.TIER_1 && (
        <Checkbox
          isChecked={marketingPreference}
          onChange={onChange}
          label={checkboxLabel[regionalTier]}
          htmlAttributes={{
            'data-testid': 'marketing-consent-checkbox',
          }}
        />
      )}
      <Typography variant="body-small">
        The information you provide will be used in accordance with&nbsp;
        <TextLink
          className="inline"
          href="https://legal.neo4j.com/#privacy-policy"
          htmlAttributes={{
            rel: 'noopener noreferrer',
            target: '_blank',
          }}
        >
          Neo4j&apos;s Privacy Policy
        </TextLink>
        , including in respect of marketing our products, events and services. You may opt out at any time in accordance
        with our Privacy Policy
      </Typography>
    </div>
  );
};
