import { useEffect, useMemo, useRef, useState } from 'react';
import type { SingleValue } from 'react-select';

import type { MultiChartProps } from '../charts/types';

export const useMultiChartProps = (multiChartProps: MultiChartProps, useAZGrouping?: boolean) => {
  const isMulti = Array.isArray(multiChartProps);
  const [chartProps, setChartProps] = useState(isMulti ? multiChartProps[0]! : multiChartProps);
  const azRef = useRef(useAZGrouping);

  useEffect(() => {
    if (azRef.current === useAZGrouping) {
      setChartProps(isMulti ? multiChartProps[0]! : multiChartProps);
    }
  }, [isMulti, multiChartProps, useAZGrouping]);

  const chartDropdownOptions = useMemo(() => {
    if (!isMulti) {
      return null;
    }

    const options = multiChartProps.map((p) => ({
      label: p.subtitle,
      value: p,
    }));

    return {
      options,
      onChange: (newValue: SingleValue<(typeof options)[number]>) => {
        if (newValue?.value) {
          setChartProps(newValue.value);
        }
      },
      value: {
        label: chartProps.subtitle,
        value: chartProps,
      },
    };
  }, [chartProps, isMulti, multiChartProps]);

  return { chartProps, chartDropdownOptions };
};
