import { produce, setAutoFreeze } from 'immer';
import { forIn } from 'lodash-es';

setAutoFreeze(false);

export const cleanStateBeforeSaving = (state, removeList, keepList, customTransformation) =>
  produce(state, (draftState) => {
    forIn(draftState, (value, key) => {
      if (removeList?.includes(key) || (keepList && !keepList.includes(key))) {
        delete draftState[key];
      } else {
        const completeNewValue = customTransformation ? customTransformation(key, value, state) : null;
        if (completeNewValue) {
          draftState[key] = completeNewValue;
        }
      }
    });
  });
