import { consoleApi, useModalClose } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import TermsAndConditionsModal from '@nx/ui/src/modals/terms-and-conditions/terms-and-conditions-modal';
import { useEffect, useState } from 'react';

import { getContentSize, getMarketingPreference } from './helpers';
import { useTermsAndConditionsStatus } from './hooks';
import {
  MarketingOptInSection,
  TermsSubtitle,
  TermsTitle,
  UpxTermsAndConditionsContent,
  currentTsAndCsVersion,
} from './terms-and-conditions-content';

export function TermsAndConditions() {
  const [updateTermsAndConditions, updateRes] = consoleApi.useUpdateTermsAndConditionsMutation();
  const termsRes = useTermsAndConditionsStatus();
  const closeModal = useModalClose();

  const [marketingPreference, setMarketingPreference] = useState(false);

  useEffect(() => {
    if (updateRes.isSuccess && termsRes.isAccepted) {
      closeModal();
    }
  }, [updateRes, closeModal, termsRes.isAccepted]);

  const updateTsAndCsBody = {
    acceptedTermsAndConditions: currentTsAndCsVersion(),
    ...(isNotNullish(termsRes.marketingChoiceRequired) && {
      marketingPreference: getMarketingPreference(marketingPreference, termsRes.marketingChoiceRequired),
    }),
  };
  const onAccept = () => updateTermsAndConditions(updateTsAndCsBody);

  return (
    <TermsAndConditionsModal
      header={<TermsTitle acceptedVersion={termsRes.acceptedVersion} />}
      subtitle={<TermsSubtitle acceptedVersion={termsRes.acceptedVersion} />}
      content={<UpxTermsAndConditionsContent contentSize={getContentSize(termsRes.marketingChoiceRequired)} />}
      acceptButtonText="I agree"
      onAccept={!termsRes.isAccepted || termsRes.isLoading ? onAccept : undefined}
      onClose={closeModal}
      displayPrivacyPolicyButton
      isLoadingAccept={updateRes.isLoading || updateRes.isSuccess}
      checkboxes={
        isNotNullish(termsRes.marketingChoiceRequired) && (
          <MarketingOptInSection
            marketingPreference={marketingPreference}
            onChange={() => setMarketingPreference(!marketingPreference)}
            regionalTier={termsRes.marketingChoiceRequired}
          />
        )
      }
    />
  );
}
