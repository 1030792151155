import { GUIDE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import * as StdLib from '@nx/stdlib';
import { useCallback } from 'react';

import { trackEvent } from '../actions';
import { useDispatch, useSelector } from '../context';
import type { ConnectionReadyCallback } from '../middlewares/connections';
import { handleConnectionAbort, handleConnectionReady, handleConnectionSkip } from '../middlewares/connections';
import { discoveryToConnectionDetails, useFetchDiscoveryData } from '../services/neo4j-sso/discovery';
import { useConnectionStatus } from '../slices/connections';
import type { ArgumentsOfModalType, Modal } from '../slices/modals-slice';
import { MODAL_TYPE, closeModal, openModal } from '../slices/modals-slice';

export function useModal<T extends MODAL_TYPE>(modalType: T) {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modals.openModal);

  const open = useCallback(
    (...args: ArgumentsOfModalType<T>) => dispatch(openModal({ type: modalType, args })),
    [dispatch, modalType],
  );

  const close = useCallback(() => dispatch(closeModal()), [dispatch]);

  return {
    isOpen: modal?.type === modalType,
    open,
    close,
  };
}

export function useModalClose() {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(closeModal()), [dispatch]);
}

export function useCurrentModal() {
  return useSelector((state) => state.modals.openModal);
}

export const useConnectionSelectorModal = () => {
  const modal = useModal(MODAL_TYPE.DATA_SOURCE);
  const { fetchDiscoveryData } = useFetchDiscoveryData();

  return {
    ...modal,
    close() {
      handleConnectionAbort();
      modal.close();
    },
    open(
      args?: Omit<Extract<Modal, { type: MODAL_TYPE.DATA_SOURCE }>, 'type'>,
      onConnectionReady?: ConnectionReadyCallback,
      onConnectionSkip?: () => void,
    ) {
      modal.open({
        allowSkippingDataSource: undefined,
        connectionDetails: undefined,
        modalTabId: undefined,
        showDataModificationWarning: undefined,
        ...args,
      });
      if (onConnectionReady !== undefined) {
        handleConnectionReady(onConnectionReady);
      }
      if (onConnectionSkip !== undefined) {
        handleConnectionSkip(onConnectionSkip);
      }
    },
    fetchDiscoveryProvidersAndOpen({
      url,
      instanceName,
      dbName,
      callback,
    }: {
      url: string;
      instanceName?: string | null;
      dbName?: string;
      callback?: () => void;
    }) {
      const connectURL = StdLib.URLs.Neo4jURL.asNullable(url);

      if (!StdLib.isNullish(connectURL)) {
        void fetchDiscoveryData(connectURL.toDiscoveryUrl()).then((discovery) => {
          this.open(
            {
              connectionDetails: discoveryToConnectionDetails(connectURL, discovery, instanceName, dbName),
              modalTabId: 'Remote',
            },
            callback,
          );
        });
      }
    },
  };
};

/**
 * Helper function to open the Guide Selection modal
 */
export const useGuideSelectionModal = () => {
  const guideSelectionModal = useModal(MODAL_TYPE.GUIDE_SELECTION);
  const connectionSelectorModal = useConnectionSelectorModal();
  const { isConnected } = useConnectionStatus();

  return {
    ...guideSelectionModal,
    close: guideSelectionModal.close,
    open() {
      trackEvent({ event: GUIDE_EVENTS.OPEN_GS, scope: APP_SCOPE.framework });
      if (isConnected) {
        guideSelectionModal.open();
      } else {
        connectionSelectorModal.open({ modalTabId: 'Remote' }, guideSelectionModal.open);
      }
    },
  };
};
