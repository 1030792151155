import { getAccessToken } from '../slices/session-slice';
import { store } from '../store';

const getProjectId = () => store.getState().appContext.activeProjectId;

/** Mutates the provided `headers` object by adding authorization and project headers */
export const prepareHeaders = async (headers: Headers) => {
  const accessToken = await getAccessToken();
  headers.set('Authorization', `Bearer ${accessToken}`);
  const projectId = getProjectId();
  if (projectId !== null) {
    headers.set('Project-Id', projectId);
  }
};
