import { Banner } from '@neo4j-ndl/react';

import azureLogo from '../assets/azure.svg';

export interface AzureBillingMessageProps {
  className?: string;
}

export const AzureBillingMessage = ({ className }: AzureBillingMessageProps) => {
  const title = (
    <div className="flex items-center gap-2">
      <img src={azureLogo} className="h-6 w-6" />
      Managed by your Azure subscription/resource group.
    </div>
  );

  return (
    <Banner
      type="info"
      className={className}
      description={title}
      usage="inline"
      name="Managed by Azure Marketplace"
      htmlAttributes={{
        'data-testid': 'azure-billing-message',
      }}
    />
  );
};
