import { Tooltip } from '@neo4j-ndl/react';
import type { ComponentProps } from 'react';

interface PermissionTooltipProps {
  className?: string;
  hasPermission: boolean;
  children: React.ReactNode;
  hasButtonWrapper?: boolean;
  message?: string;
  placement?: ComponentProps<typeof Tooltip>['placement'];
  isPortaled?: boolean;
}

export const PermissionTooltip = ({
  children,
  hasPermission,
  hasButtonWrapper = true,
  className,
  message,
  placement,
  isPortaled = false,
}: PermissionTooltipProps) => {
  return (
    <Tooltip type="simple" isDisabled={hasPermission} isPortaled={isPortaled} placement={placement}>
      <Tooltip.Trigger hasButtonWrapper={hasButtonWrapper}>
        <div className={className}>{children}</div>
      </Tooltip.Trigger>
      <Tooltip.Content style={{ width: 200, position: 'fixed' }}>
        {message ?? "You don't have permission to perform this action."}
      </Tooltip.Content>
    </Tooltip>
  );
};
