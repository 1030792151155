import { useSelector } from '../context';
import * as Capabilities from '../slices/capabilities-slice';

/**
 * Application mode where:
 * - `"standalone"` — single tool availability;
 * - `"composite"` — multiple tools availability.
 *
 * @deprecated Legacy approach, prefer using tool availability and capabilities
 */
export function useMode() {
  const value = useSelector(Capabilities.selectMode);
  return {
    isComposite: value === 'composite',
    isStandalone: value === 'standalone',
    value,
  };
}
