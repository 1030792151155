import type { DialogProps } from '@neo4j-ndl/react';
import { IconButton } from '@neo4j-ndl/react';
import { ArrowDownTrayIconOutline } from '@neo4j-ndl/react/icons';
import { OPS_EVENTS } from '@nx/analytics-service';
import { LOG_SUB_TYPE, OpsTypes, opsApi, useActiveProject, useOpsContext } from '@nx/state';
import { isNonEmptyString } from '@nx/stdlib';
import { PermissionTooltip } from '@nx/ui';
import { skipToken } from '@reduxjs/toolkit/query';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { track } from '../../../services/segment/analytics';
import { useSelectedInstancePermissions } from '../../../shared/hooks/usePermissions';
import { getLogger } from '../../../shared/logger';
import type { HandleInitiateDownloadProps } from '../../logs-download/download-dialog';
import { DownloadDialog } from '../../logs-download/download-dialog';
import { DRAWER_SEARCH_PARAM } from '../../logs-download/helpers';
import { useLogsContext } from '../hooks/use-logs-context';

type InitiateDownloadModalProps = Omit<DialogProps, 'children'> & {
  onClose: () => void;
};

const SECURITY_LOGS_ROW_LIMIT = 5_000_000;
const MODAL_TO_DRAWER_TRANSITION_MS = 200;

export const InitiateDownloadModal = ({ onClose, modalProps, ...props }: InitiateDownloadModalProps) => {
  const activeProject = useActiveProject();
  const { selectedInstanceId } = useOpsContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const logsContext = useLogsContext();

  const [initDetailsDownload, initDetailsDownloadRes] = opsApi.useInitiateSecurityLogsDownloadMutation();
  const [initSummaryDownload, initSummaryDownloadRes] = opsApi.useInitiateSecurityLogsSummaryDownloadMutation();

  // Should resolve immediately from RTK Query cache
  const getCountsRes = opsApi.useGetSecurityLogCountsQuery(
    isNonEmptyString(selectedInstanceId) && isNonEmptyString(selectedInstanceId)
      ? {
          tenantId: activeProject.id,
          dbmsId: selectedInstanceId,
          filter: logsContext.filterInput,
        }
      : skipToken,
  );

  // TODO disable row while deleting

  const totalSecurityLogs =
    (getCountsRes.data?.infoLogs ?? 0) + (getCountsRes.data?.errorLogs ?? 0) + (getCountsRes.data?.warningLogs ?? 0);

  const isLoading = initDetailsDownloadRes.isLoading || initSummaryDownloadRes.isLoading;

  const handleInitiateDownload = async ({
    logTypes,
    fileFormat,
    includeHeaders,
    delimiter,
  }: HandleInitiateDownloadProps) => {
    if (!isNonEmptyString(selectedInstanceId)) {
      getLogger().error('[InitiateDownloadModal] No instance selected');
      return;
    }

    // Remove potential error banners
    initDetailsDownloadRes.reset();
    initSummaryDownloadRes.reset();

    const commonPayload: Parameters<typeof initSummaryDownload>[0] & Parameters<typeof initDetailsDownload>[0] = {
      tenantId: activeProject.id,
      dbmsId: selectedInstanceId,
      args: {
        filter: logsContext.filterInput,
        // pagination not used by the endpoint
        pagination: { page: 0, perPage: 10 },
        sort: [],
      },
      format: fileFormat,
      csvHeader: includeHeaders,
      csvDelimiter: delimiter,
    };

    const trackDownloadInit = (logType: LOG_SUB_TYPE, jobId: string) => {
      track(OPS_EVENTS.LOGS_DOWNLOAD_INITIATE, {
        format: fileFormat,
        logType,
        jobId,
      });
    };

    const initSummary = async () => {
      const res = await initSummaryDownload({
        ...commonPayload,
        args: {
          ...commonPayload.args,
          sort: [
            {
              property: OpsTypes.Api.SECURITY_LOG_SUMMARY_SORT_PROPERTY.LOG_COUNT,
              order: OpsTypes.Api.SORT_ORDER.DESCENDING,
            },
          ],
        },
      });
      if (isNonEmptyString(res.data?.jobId)) {
        trackDownloadInit(LOG_SUB_TYPE.SUMMARY, res.data.jobId);
      }
      return res;
    };

    const initDetails = async () => {
      const res = await initDetailsDownload({
        ...commonPayload,
        args: {
          ...commonPayload.args,
          sort: [
            {
              property: OpsTypes.Api.SECURITY_LOG_SORT_PROPERTY.EXECUTING_USER,
              order: OpsTypes.Api.SORT_ORDER.DESCENDING,
            },
          ],
        },
      });
      if (isNonEmptyString(res.data?.jobId)) {
        trackDownloadInit(LOG_SUB_TYPE.DETAILS, res.data.jobId);
      }
      return res;
    };

    const promises: (ReturnType<typeof initDetails> | ReturnType<typeof initSummary>)[] = [];

    if (logTypes.includes('SUMMARY')) {
      promises.push(initSummary());
    }

    if (logTypes.includes('DETAILS')) {
      promises.push(initDetails());
    }

    const res = await Promise.all(promises);

    // close modal and open drawer if all inits are successful; otherwise error banner is rendered below
    if (res.every((p) => !p.error)) {
      setTimeout(() => {
        const params = new URLSearchParams(searchParams);
        params.set(DRAWER_SEARCH_PARAM, 'true');
        setSearchParams(params);
      }, MODAL_TO_DRAWER_TRANSITION_MS);
      onClose();
    }
  };

  return (
    <DownloadDialog
      {...props}
      {...modalProps}
      onClose={onClose}
      handleInitiateDownload={handleInitiateDownload}
      isLoading={isLoading}
      isStaleData={false}
      selectedTab={logsContext.selectedTab}
      totalLogs={totalSecurityLogs}
      logsRowLimit={SECURITY_LOGS_ROW_LIMIT}
      initSummaryDownloadError={initSummaryDownloadRes.error}
      initDetailsDownloadError={initDetailsDownloadRes.error}
    />
  );
};

export const InitiateDownloadButtonWithModal = ({ className }: { className?: string }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { hasRequestedData } = useLogsContext();

  // DownloadDrawerButtonWithDrawer ensures fresh permissions via
  // `refetchOnMountOrArgChange: true` so no need for it here
  const permissions = useSelectedInstancePermissions();
  const canUseDownloads = permissions?.canUseDownloads === true;

  const handleInitiateDownload = () => {
    if (!canUseDownloads) {
      return;
    }
    setIsModalOpen(true);
  };

  return (
    <>
      <PermissionTooltip hasPermission={canUseDownloads} hasButtonWrapper>
        <IconButton
          ariaLabel="Initiate logs download"
          size="medium"
          className={className}
          onClick={handleInitiateDownload}
          isDisabled={!canUseDownloads || !hasRequestedData}
          htmlAttributes={{ title: 'Initiate logs download' }}
        >
          <ArrowDownTrayIconOutline />
        </IconButton>
      </PermissionTooltip>
      {isModalOpen && <InitiateDownloadModal isOpen onClose={() => setIsModalOpen(false)} />}
    </>
  );
};
