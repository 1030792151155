import { EntitySelectorDropdown } from '@nx/ui';

export interface DatabaseSelectorProps {
  selectedDatabase: string;
  setSelectedDatabase: (database: string) => void;
  databases: string[];
}

export const DatabaseSelector = ({ selectedDatabase, setSelectedDatabase, databases }: DatabaseSelectorProps) => {
  const databaseEntities = databases
    .map((database) => ({
      id: database,
      name: database,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <EntitySelectorDropdown
      label="Database"
      entities={databaseEntities}
      selectedEntity={databaseEntities.find((entity) => entity.id === selectedDatabase)}
      setSelectedEntity={(id) => setSelectedDatabase(id ?? '')}
      MenuItem={(entity) => <span>{entity.name}</span>}
    />
  );
};
