import type { LogForwarding } from '@nx/state';
import { DangerConfirmModal } from '@nx/ui';
import React from 'react';

interface ModalProps {
  logForwarding: LogForwarding;
  open: boolean;
  loading: boolean;
  error?: Error | null;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmDeleteModal = ({ logForwarding, open, loading, error, onClose, onConfirm }: ModalProps) => {
  return (
    <DangerConfirmModal
      title={`Delete configuration '${logForwarding.name}'?`}
      content={`This will stop forwarding ${logForwarding.logType} logs in ${logForwarding.region}.`}
      confirmButtonText="Delete"
      open={open}
      error={error ? String(error) : null}
      loading={loading}
      onClose={() => onClose()}
      onConfirm={() => onConfirm()}
    />
  );
};
