import Big from 'big.js';

// By default we show at most 4 decimal places,
// always rounding up when it comes to dollar amounts
const DEFAULT_DECIMAL_PLACES = 4;

// Works out at 730 hours on average based on non leap years
const averageHoursPerMonth = (365 / 12) * 24;

export const calcMonthlyCost = (costPerHour: string, maximumDecimalPlaces: number = DEFAULT_DECIMAL_PLACES) => {
  const number = Big(costPerHour);

  // Round up always on dollar amounts
  const numberRounded = number.toFixed(maximumDecimalPlaces, 3);

  return Big(numberRounded).mul(averageHoursPerMonth).toFixed(maximumDecimalPlaces, 3);
};

export function formatDollars(
  dollarAmount: number | string,
  maximumDecimalPlaces: number = DEFAULT_DECIMAL_PLACES,
): string {
  const number = Big(dollarAmount === '' ? '0' : dollarAmount);

  // Round up always on dollar amounts
  const numberRounded = number.toFixed(maximumDecimalPlaces, 3);

  return Big(numberRounded).toNumber().toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: maximumDecimalPlaces,
  });
}
