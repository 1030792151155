import { isNullish } from '@nx/stdlib';

/**
 * Copied same types as of v0.0.2
 */
type FileSchemaField = {
  name: string;
  type: string;
  sample?: string;
  include?: boolean;
  format?: string;
  timezone?: string;
};

export type FileSchema = {
  fields: FileSchemaField[];
  expanded?: boolean;
};

type FileModel = {
  fileSchemas: Record<string, FileSchema>;
};

type MappingField = {
  field?: string;
};

type MappingDefinition = {
  fileSchema?: string;
  mappings: MappingField[];
};

export type NodeMapping = MappingDefinition & {
  nodeSchema: string;
};

export type RelationshipMapping = MappingDefinition & {
  relationshipSchema: string;
  sourceMappings: MappingField[];
  targetMappings: MappingField[];
};

type MappingModel = {
  nodeMappings: Record<string, NodeMapping>;
  relationshipMappings: Record<string, RelationshipMapping>;
};

type ErrorList = Record<number, string[]>;

export type SchemaError = {
  properties?: ErrorList;
};

export type NodeSchemaError = SchemaError & {
  label?: string;
  key?: string;
};

export type RelationshipSchemaError = SchemaError & {
  type?: string;
};

export type MappingError = {
  mappings?: ErrorList;
  fileSchema?: string;
};

export type NodeMappingError = MappingError;

export type RelationshipMappingError = MappingError & {
  sourceMappings?: string;
  targetMappings?: string;
};

export type DataModelErrors = {
  nodeErrors: Record<string, NodeSchemaError & NodeMappingError>;
  relationshipErrors: Record<string, RelationshipSchemaError & RelationshipMappingError>;
};

/**
 * Deprecated types from v0.0.1
 */
/**
 * @deprecated This type should not be used in version newer than v0.0.1
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
type Property_0_0_1 = {
  property: string;
  type: string;
};

/**
 * @deprecated This type should not be used in version newer than v0.0.1
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
type NodeKey_0_0_1 = {
  // Array of indexes into the csv file columns (currently always length 1)
  properties: number[];
  name: string;
};
/**
 * @deprecated This type should not be used in version newer than v0.0.1
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export type NodeSchema_0_0_1 = {
  label: string;
  additionLabels: string[];
  labelProperties: string[];
  properties: Property_0_0_1[];
  key: NodeKey_0_0_1;
};

/**
 * @deprecated This type should not be used in version newer than v0.0.1
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export type RelationshipSchema_0_0_1 = {
  type: string;
  sourceNodeSchema: string;
  targetNodeSchema: string;
  properties: Property_0_0_1[];
};

/**
 * @deprecated This type should not be used in version newer than v0.0.1
 */
type GraphModel = {
  nodeSchemas: Record<string, NodeSchema_0_0_1>;
  relationshipSchemas: Record<string, RelationshipSchema_0_0_1>;
};

/**
 * @deprecated This type should not be used in version newer than v0.0.1
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export type DataModel_0_0_1 = {
  fileModel: FileModel;
  graphModel: GraphModel;
  mappingModel: MappingModel;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isDataModel_0_0_1 = (dataModel: any): dataModel is DataModel_0_0_1 => {
  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  // TODO - better validation...
  return (
    !isNullish(dataModel) &&
    !isNullish(dataModel.fileModel) &&
    !isNullish(dataModel.graphModel) &&
    !isNullish(dataModel.mappingModel)
  );
  /* eslint-enable @typescript-eslint/no-unsafe-member-access */
};
