/* eslint-disable line-comment-position */
import type { TierConfigs } from '@nx/state';
import { PLAN_TYPE, TIER, consoleApi, isTier, useAuth, useUnsafeAppContext } from '@nx/state';
import { useDarkMode } from '@nx/ui';
import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect } from 'react';

const CANNY_APP_ID = '5eb66d2ff9f4665cc1bed233';

enum PRODUCT {
  AURA_DB = 'aura-db',
  AURA_DS = 'aura-ds',
}

const getProductFromTier = (tier: TIER): PRODUCT => {
  switch (tier) {
    case TIER.AURA_DSE:
    case TIER.GDS:
      return PRODUCT.AURA_DS;
    case TIER.ENTERPRISE:
    case TIER.FREE:
    case TIER.PROFESSIONAL:
    case TIER.MTE:
      return PRODUCT.AURA_DB;
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Tier ${tier} does not belong to a product`);
  }
};

const getAvailableProducts = (tierConfigs: TierConfigs): PRODUCT[] =>
  Object.keys(tierConfigs)
    .filter(isTier)
    .map((tier) => getProductFromTier(tier));

const CANNY_LABEL_IDS: {
  [planType in PLAN_TYPE | 'allPlanTypes']: {
    [product in PRODUCT | 'allProducts']: string[];
  };
} = {
  [PLAN_TYPE.SELF_SERVE]: {
    [PRODUCT.AURA_DB]: [
      '6239a04faa376c33ba74fcef', // AuraDB Free
      '6239a04aaa376c33ba74f6c0', // AuraDB Professional
    ],
    [PRODUCT.AURA_DS]: [
      '6239a058eaf5bb082db76ac6', // AuraDS Self-Start
    ],
    allProducts: [
      '6239a0c787c34c6baebda0fd', // Browser
      '6239acd058204e32b7bcd151', // Data Importer
      '62835cb66bb7970b7745d86d', // Import
    ],
  },
  [PLAN_TYPE.ENTERPRISE]: {
    [PRODUCT.AURA_DB]: [
      '62399c92aa376c33ba71511c', // AuraDB Enterprise
    ],
    [PRODUCT.AURA_DS]: [
      '6239a062aa376c33ba7513b6', // AuraDS Enterprise
    ],
    allProducts: [],
  },
  allPlanTypes: {
    [PRODUCT.AURA_DB]: [],
    [PRODUCT.AURA_DS]: [],
    allProducts: [
      '5fa16962c2207b64553f52e7', // Advanced Warning Aura Platform
      '6239a0bbcab550241dab79ee', // Bloom
      '603e2acc68e6a050d82910ed', // Maintenance Notice
      '62835caf158bcc0b63b36f4a', // Query
    ],
  },
};

const getLabelIDs = (planType: PLAN_TYPE, availableProducts: PRODUCT[]): string[] => [
  ...CANNY_LABEL_IDS.allPlanTypes.allProducts,
  ...CANNY_LABEL_IDS[planType].allProducts,
  ...availableProducts.reduce<string[]>(
    (labelIDs, product) => [
      ...labelIDs,
      ...CANNY_LABEL_IDS.allPlanTypes[product],
      ...CANNY_LABEL_IDS[planType][product],
    ],
    [],
  ),
];

declare const window: {
  // eslint-disable-next-line @typescript-eslint/ban-types
  Canny?: Function;
} & Window;

const initChangelog = (appID: string, labelIDs: string[], theme = 'light') => {
  window.Canny?.('initChangelog', {
    appID,
    position: 'bottom',
    align: 'right',
    labelIDs,
    theme,
  });
};

const identifyUser = (appID: string, email: string, name: string, picture: string) => {
  window.Canny?.('identify', {
    appID,
    user: {
      email,
      name,
      id: email,
      avatarURL: picture,
    },
  });
};

export const useCanny = () => {
  const { user } = useAuth();
  const { activeProjectId } = useUnsafeAppContext();
  const { data: project } = consoleApi.useGetProjectQuery(activeProjectId ?? skipToken);
  const isDarkMode = useDarkMode();

  useEffect(() => {
    if (project) {
      const availableProducts = getAvailableProducts(project.tierConfigs);
      const labelIDs = getLabelIDs(project.planType, availableProducts);
      const theme = isDarkMode ? 'dark' : 'light';

      initChangelog(CANNY_APP_ID, labelIDs, theme);
    }
  }, [project, isDarkMode]);

  useEffect(() => {
    if (user?.profile) {
      const { email = '', name = '', picture = '' } = user.profile;

      identifyUser(CANNY_APP_ID, email, name, picture);
    }
  }, [user]);
};

export default useCanny;
