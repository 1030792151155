import { Banner, Button, Dialog, Typography } from '@neo4j-ndl/react';
import { useModalClose } from '@nx/state';
import { CopyTextInput } from '@nx/ui';
import { useMemo } from 'react';

interface Props {
  id: string;
  password: string;
  boltUrl: string;
  name: string;
  username: string;
}

export const InstanceCredentialsModal = ({ id, password, boltUrl, name, username }: Props) => {
  const handleClose = useModalClose();
  const credentialsFilename = `Neo4j-${id}-Created-${new Date().toISOString().slice(0, 10)}.txt`;
  const credentialsURL = useMemo(() => {
    const content: string =
      '# Wait 60 seconds before connecting using these details, or login to https://console.neo4j.io to validate the Aura Instance is available\n' +
      `NEO4J_URI=${boltUrl}\n` +
      `NEO4J_USERNAME=${username}\n` +
      `NEO4J_PASSWORD=${password}\n` +
      `AURA_INSTANCEID=${id}\n` +
      `AURA_INSTANCENAME=${name}\n`;
    const blob = new Blob([content], { type: 'text/plain' });
    return window.URL.createObjectURL(blob);
  }, [boltUrl, id, name, password, username]);

  return (
    <Dialog
      modalProps={{
        className: 'generated-password-modal',
        'data-testid': 'generated-password-modal',
        'data-instance-id': id,
      }}
      size="small"
      isOpen
      hasDisabledCloseButton
    >
      <Dialog.Header>Credentials for {name}</Dialog.Header>
      <Dialog.Content className="space-y-6">
        <div className="readonly-field">
          <Typography variant="body-medium" className="text-neutral-text-weak">
            Username
          </Typography>
          <p>{username}</p>
        </div>
        <CopyTextInput label="Password" value={password} isPortaled={false} />
        <Banner
          type="warning"
          description="Note that the password will not be available after this point."
          hasIcon
          usage="inline"
        />
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          fill="outlined"
          color="neutral"
          onClick={handleClose}
          htmlAttributes={{
            'data-testid': 'close-password-modal',
          }}
        >
          Close
        </Button>
        <Button
          as="a"
          onClick={handleClose}
          htmlAttributes={{
            href: credentialsURL,
            download: credentialsFilename,
            'data-testid': 'download-and-continue',
          }}
        >
          Download and continue
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
