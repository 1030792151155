// TODO: Abstract this module into more generic components
// Initial idea was to recreate the layout of components that these fallbacks
// are used for but it seems to not be a sustainable approach as the fallbacks
// would be prone to get outdated. What would probably make more sense is
// implementing general shapes: simple form, complex form, prose, etc. The
// fallback should hint at what will appear next while communicating the loading
// state, it does not need to be a carbon copy of that.
import { Skeleton } from '@nx/ui';

function StripeAddressFieldSkeleton() {
  return (
    <div className="flex flex-col gap-4">
      <Skeleton.TextField label="Street address" />
      <div className="grid grid-cols-2 gap-4">
        <Skeleton.TextField label="City" />
        <Skeleton.TextField label="Postal code" />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <Skeleton.TextField label="Country" />
        <Skeleton.TextField label="State" />
      </div>
    </div>
  );
}

export function StripeAddressFieldFallback() {
  return (
    <Skeleton.AnimationGroup>
      <StripeAddressFieldSkeleton />
    </Skeleton.AnimationGroup>
  );
}

export function StripeFormFallback() {
  return (
    <Skeleton.AnimationGroup>
      <div className="flex flex-col gap-4">
        <Skeleton.Typography variant="h6">Billing address</Skeleton.Typography>
        <Skeleton.TextField label="Name" />
        <StripeAddressFieldSkeleton />
        <Skeleton.Typography variant="h6">Payment method</Skeleton.Typography>
        <Skeleton.TextField label="Credit/Debit card details" />
      </div>
    </Skeleton.AnimationGroup>
  );
}
