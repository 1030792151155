import type { Node as NvlNode, Relationship as NvlRelationship } from '@neo4j-nvl/base';
import { DEFAULT_NVL_NODE_RADIUS } from '@nx/state';

import tempIconData from '../../styles/iconjar/iconjar.json';
import type { Node as BloomNode, Relationship as BloomRelationship } from '../../types/graph';
import type { StyleRule } from '../../types/style';

export interface MapperProps {
  colorForNodeMapper: (node: BloomNode) => NvlNode['color'];
  getUniqueValuesForStyleRules: (nodes: BloomNode[]) => Record<string, Partial<StyleRule>>;
  captionSizeForNodeMapper: (node: BloomNode) => NvlNode['captionSize'];
  captionAlignForNodeMapper: (node: BloomNode) => NvlNode['captionAlign'];
  captionsForNodeMapper: (node: BloomNode) => NvlNode['captions'];
  captionSizeForRelationshipMapper: (rel: BloomRelationship) => NvlRelationship['captionSize'];
  captionAlignForRelationshipMapper: (rel: BloomRelationship) => NvlRelationship['captionAlign'];
  captionsForRelationshipMapper: (rel: BloomRelationship) => NvlRelationship['captions'];
  sizeForNodeMapper: (node: BloomNode) => NvlNode['size'];
  iconForNodeMapper: (node: BloomNode) => NvlNode['icon'];
  colorForRelMapper: (rel: BloomRelationship) => NvlRelationship['color'];
  sizeForRelMapper: (rel: BloomRelationship) => NvlRelationship['width'];
  getAssetUrl: (target: string) => string;
  nodeSelectedMap: Record<string, boolean>;
  activatedNodeMap: Record<string, boolean>;
  nodeDisabledMap: Record<string, boolean>;
  relDisabledMap: Record<string, boolean>;
  relSelectedMap: Record<string, boolean>;
}
const DEFAULT_NVL_DISABLED_COLOR = '#EDEDED';
export const sizeToRadius = (size: number) => Math.sqrt(size) * DEFAULT_NVL_NODE_RADIUS;
export const radiusToSize = (radius: number) => Math.pow(radius / DEFAULT_NVL_NODE_RADIUS, 2);

const getIconAssetUrl = (iconName: string, getAssetUrl: (assetName: string) => string): string => {
  const iconData = tempIconData[iconName as keyof typeof tempIconData];
  if (!iconData || !('file' in iconData)) {
    return '';
  }
  return getAssetUrl(iconData.file);
};

export const mapNodeToNvl =
  (props: MapperProps) =>
  (node: BloomNode): NvlNode => {
    const mappedIcon = props.iconForNodeMapper(node) ?? '';
    const { id } = node;
    const isDisabled = props.nodeDisabledMap[id] !== undefined;

    const mappedNode: NvlNode = {
      id,
      color: isDisabled ? DEFAULT_NVL_DISABLED_COLOR : props.colorForNodeMapper(node),
      icon: getIconAssetUrl(mappedIcon, props.getAssetUrl),
      captionSize: props.captionSizeForNodeMapper(node),
      captionAlign: props.captionAlignForNodeMapper(node),
      captions: props.captionsForNodeMapper(node),
      pinned: node.pinned,
      activated: props.activatedNodeMap[id] !== undefined,
      selected: props.nodeSelectedMap[id] !== undefined,
      disabled: isDisabled,
      size: sizeToRadius(props.sizeForNodeMapper(node) ?? DEFAULT_NVL_NODE_RADIUS),
    };
    if (node.placement && node.placement.x && node.placement.y) {
      mappedNode.x = node.placement.x;
      mappedNode.y = node.placement.y;
    }

    return mappedNode;
  };

export const mapRelationshipToNvl =
  (props: MapperProps) =>
  (rel: BloomRelationship): NvlRelationship => {
    const { id } = rel;
    const isDisabled = props.relDisabledMap[id] !== undefined;

    return {
      id,
      from: rel?.startId,
      to: rel?.endId,
      captionSize: props.captionSizeForRelationshipMapper(rel),
      captionAlign: props.captionAlignForRelationshipMapper(rel),
      captions: props.captionsForRelationshipMapper(rel),
      selected: props.relSelectedMap[id] !== undefined,
      disabled: isDisabled,
      color: props.colorForRelMapper(rel),
      width: props.sizeForRelMapper(rel),
    };
  };
