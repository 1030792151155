import { type LocalInstance } from '@nx/state';
import { isNullish } from '@nx/stdlib';
import type { ComponentProps } from 'react';
import { useCallback, useState } from 'react';

import { InstanceCard } from './instance-card';
import { InstanceDetails } from './instance-details';

interface InstanceTableProps {
  type?: ComponentProps<typeof InstanceCard>['type'];
  instances: LocalInstance[];
  onConnectionSuccess?: () => void;
}

export const InstanceTable = ({ instances, type, onConnectionSuccess }: InstanceTableProps) => {
  const [instanceDetails, setInstanceDetails] = useState<LocalInstance | null>(null);

  const onClose = useCallback(() => {
    setInstanceDetails(null);
  }, [setInstanceDetails]);

  return (
    <>
      <div className="flex flex-col gap-4 overflow-y-auto p-6">
        {instances.map((instance) => (
          <InstanceCard
            type={type}
            instanceSummary={instance}
            key={instance.id}
            onInstanceDetailsClick={() => setInstanceDetails(instance)}
            onConnectionSuccess={onConnectionSuccess}
          />
        ))}
      </div>
      {!isNullish(instanceDetails) && <InstanceDetails instanceId={instanceDetails.id} onClose={onClose} />}
    </>
  );
};
