import { Code, Dialog, TextLink, Typography } from '@neo4j-ndl/react';
import { InformationCircleIconOutline } from '@neo4j-ndl/react/icons';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const QUERY_LOG_SAMPLE = JSON.stringify(
  {
    Time: '2024-06-04 11:57:08.619+0000',
    level: 'INFO',
    Event: 'success',
    Type: 'query',
    Id: '60',
    elapsedTimeMs: 3,
    allocatedBytes: 176,
    source: 'bolt-session\tbolt\tneo4j-javascript/5.0.1\t\tclient/127.0.0.1:49402\tserver/127.0.0.1:7687>',
    Database: 'neo4j',
    Username: 'neo4j',
    executingUser: 'neo4j',
    authenticatedUser: 'neo4j',
    query: 'RETURN 1',
    queryParameters: '{}',
    Runtime: 'pipelined',
    annotationData: "{type: 'user-direct', app: 'neo4j-javascript/5.0.1'}",
  },
  null,
  2,
);

export const DriverLogsExpand = ({ aqlaUrl }: { aqlaUrl: string }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleClick = () => setIsOpen((prev) => !prev);
  const handleClose = () => setIsOpen(false);
  return (
    <div>
      <div className="flex items-center gap-x-1">
        <InformationCircleIconOutline className="size-4" />
        More information on driver versions and updating drivers can be found{' '}
        <TextLink as="button" className="cursor-pointer !p-0" htmlAttributes={{ onClick: handleClick }}>
          here
        </TextLink>
        .
      </div>

      <Dialog size="large" isOpen={isOpen} onClose={handleClose}>
        <Dialog.Header>Deprecated driver versions</Dialog.Header>
        <Dialog.Content>
          <div className="flex flex-col gap-3">
            <span className="mb-4">
              To determine whether your existing Neo4j drivers require migrating when the database moves to Aura 5, you
              should perform either a dependency analysis or review usage via the logs.
            </span>
            <Typography variant="subheading-medium">Dependency analysis</Typography>
            <span className="mb-2">
              Your applications are likely to use driver libraries to connect to Neo4j Aura. Please examine your
              application&apos;s dependencies to check which driver version you use.
            </span>
            <span>
              Neo4j provides official driver libraries for Java, Javascript, Go, Python, and .NET. The community also
              develops drivers for other languages, see{' '}
              <TextLink
                isExternalLink
                href="https://neo4j.com/docs/getting-started/languages-guides/community-drivers/"
              >
                Community-contributed libraries.
              </TextLink>
            </span>
            <span className="mb-2">
              For them to still work with Aura 5, we recommend that all driver versions are upgraded to the latest
              version 5. While 4.4 drivers might still work, we strongly recommend using only 5.x versions on Aura 5.
            </span>
            <span className="mb-4">
              For more information, see{' '}
              <TextLink
                isExternalLink
                href="https://support.neo4j.com/s/article/10845868658579-What-are-the-recommended-driver-versions-to-use-with-Aura"
              >
                list of recommended driver versions for Aura
              </TextLink>{' '}
              and{' '}
              <TextLink isExternalLink href="https://neo4j.com/developer/kb/neo4j-supported-versions/">
                list of the currently supported driver versions.
              </TextLink>
            </span>
            <span className="mb-2">
              Applications such as Neo4j Browser and Bloom also use drivers. Please consider updating applications
              listed among the potential issues. Applications provided with Neo4j Aura, such as &quot;Neo4j Query&quot;
              will not require a manual update, as they will automatically match the new Neo4j version after the
              migration.
            </span>
            <Typography variant="subheading-medium">Log Analysis</Typography>
            <span className="mb-2">
              When using Enterprise or Professional Aura Instances, you can also check your instance&apos;s query logs
              with one of the following methods:
            </span>
            <ul className="pl-10" style={{ listStyle: 'initial' }}>
              <li>
                Use{' '}
                <TextLink as="span">
                  <Link to={aqlaUrl}>Aura Query Log Analyser</Link>
                </TextLink>
              </li>
              <li>
                Use{' '}
                <TextLink isExternalLink href="https://neo4j.com/docs/aura/platform/logging/download-logs/">
                  Request Query Logs from the Aura console
                </TextLink>
              </li>
            </ul>
            <span className="mb-4">
              Each query log entry contains information about the client and the used connection. Example of a query log
              entry:
              <Code className="m-4" language="json" code={QUERY_LOG_SAMPLE} />
              In the example <Typography variant="code">annotationData.app</Typography> contains the driver and version.
              Sometimes this can be obscured by the application name or libraries/frameworks that encapsulate the
              driver, like <Typography variant="code">SPRING</Typography>. In that case please check the application
              dependencies.
            </span>
          </div>
        </Dialog.Content>
      </Dialog>
    </div>
  );
};
