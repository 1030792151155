import { Typography } from '@neo4j-ndl/react';
import cn from 'classnames';
import type { PropsWithChildren } from 'react';

function Panel({
  title,

  children,
  className,
}: PropsWithChildren<{ title?: string | JSX.Element; className?: string }>) {
  return (
    <section className={cn('bg-palette-neutral-bg-weak rounded-3xl p-6', className)}>
      {Boolean(title) && (
        <div className="mb-6">{typeof title === 'string' ? <Typography variant="h5">{title}</Typography> : title}</div>
      )}
      {children}
    </section>
  );
}

function Row({ title, description, action }: { title: string; description: string; action: JSX.Element }) {
  return (
    <div className="flex items-center">
      <div className="mr-auto flex flex-col gap-1">
        <Typography variant="body-medium">{title}</Typography>
        <Typography variant="body-medium" className="text-palette-neutral-text-weaker">
          {description}
        </Typography>
      </div>
      {action}
    </div>
  );
}

function Cell({ label, children }: { label: string; children: string | JSX.Element }) {
  return (
    <div className="flex flex-col gap-1">
      <Typography variant="body-medium" className="text-palette-neutral-text-weak">
        {label}
      </Typography>
      {typeof children === 'string' ? <Typography variant="subheading-medium">{children}</Typography> : children}
    </div>
  );
}

export const ControlPanel = Object.assign(Panel, { Row, Cell });
