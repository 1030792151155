import { APP_SCOPE, QUERY_TYPE } from '@nx/constants';
import { LEGACY_evaluateParameters as evaluateParameters } from '@nx/state';
import '@query/redux/params-slice';
import type { ParamsRequestId } from '@query/redux/requests-slice';
import type { ParsedParamArguments } from '@query/utils/params-arg-parser';
import type { AsyncThunk } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';

export type EvaluateParameterPayload = {
  parsed: ParsedParamArguments;
  requestId: ParamsRequestId;
};

export const queryEvaluateParametersThunk: AsyncThunk<
  { parameters: Record<string, unknown>; requestId: ParamsRequestId },
  EvaluateParameterPayload,
  Record<string, never>
> = createAsyncThunk('stream/executeParamsCommand', async ({ parsed, requestId }: EvaluateParameterPayload) => {
  // we call a framework thunk so that we can access the driver and run the query
  const parameters = await evaluateParameters({
    params: parsed,
    metadata: { appScope: APP_SCOPE.query, queryType: QUERY_TYPE.UserAction },
  }).unwrap();

  return { parameters, requestId };
});
