import { Banner, Button, DataGrid, Typography, usePrevious } from '@neo4j-ndl/react';
import type { OpsTypes, TimeRange } from '@nx/state';
import { opsApi, useActiveProject, useColumnPreferences } from '@nx/state';
import { Functions, Objects, isNonEmptyString } from '@nx/stdlib';
import { DataGridHelpers } from '@nx/ui';
import { skipToken } from '@reduxjs/toolkit/query';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { produce } from 'immer';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import classes from '../../../logs/logs.module.css';
import { FilterTags } from '../../../logs/shared/components/log-filters/filter-tags';
import type { FilterField, SearchField } from '../../../logs/shared/components/log-filters/request-dialog';
import { RequestDialog } from '../../../logs/shared/components/log-filters/request-dialog';
import { WIDTH_MD } from '../../../logs/shared/constants';
import {
  ColumnPreferencesHeaderCell,
  CommonNoDataPlaceholder,
  TableResults,
} from '../../../logs/shared/helpers/table-overrides';
import { usePageInfo } from '../../../logs/shared/hooks/use-page-info';
import { usePagination } from '../../../logs/shared/hooks/use-pagination';
import { ACTIONS_COLUMN_ID } from '../../../logs/shared/types';
import { datesWithin24H } from '../../../logs/shared/utils';
import { ApiErrorBanner } from '../../../shared/components';
import { timePeriodFormatter } from '../../../shared/ui-helpers';
import { getListIfSubsetOrDefault } from '../../../shared/utils';
import { initialFilters } from '../../constants';
import { useDeprecationSorting } from '../../hooks';
import { useDeprecationColumns } from '../../hooks/use-deprecation-columns';
import {
  FriendlyDeprecationLogFilterName,
  getActiveFilters,
  getActiveSearchFilters,
  mapDeprecationLogAggregations,
  mapRawFiltersToInput,
  toDisplayName,
} from '../../mappers';

const filterNames: OpsTypes.Migration.FilterableFields[] = [
  'deprecationNotificationNames',
  'users',
  'drivers',
  'apps',
  'initiationTypes',
];

const valueFormatters: Partial<Record<OpsTypes.Migration.FilterableFields, (v: string) => string>> = {
  deprecationNotificationNames: toDisplayName,
};

export const DeprecationLogs = ({
  chartTimeRange,
  hasDeprecations,
  selectedInstanceId,
}: {
  chartTimeRange: TimeRange;
  hasDeprecations: boolean;
  selectedInstanceId: string;
}) => {
  const activeProject = useActiveProject();
  const [hasRequestedData, setHasRequestedData] = useState(false);
  const [rawFilters, setRawFilters] = useState(initialFilters());
  const filterInput = useMemo(() => mapRawFiltersToInput(rawFilters), [rawFilters]);
  const previousSelectedInstanceId = usePrevious(selectedInstanceId);
  const [isRequestDialogOpen, setIsRequestDialogOpen] = useState(false);

  useEffect(() => {
    if (
      isNonEmptyString(previousSelectedInstanceId) &&
      isNonEmptyString(selectedInstanceId) &&
      previousSelectedInstanceId !== selectedInstanceId
    ) {
      setRawFilters(initialFilters());
    }
  }, [previousSelectedInstanceId, selectedInstanceId, setRawFilters]);

  const { controlledPagination, logsPagination, onPaginationChange } = usePagination();
  const { controlledSorting, querySorting, onSortingChange } = useDeprecationSorting();

  const getLogAggregationsRes = opsApi.useGetDeprecationLogAggregationsQuery(
    isNonEmptyString(selectedInstanceId) && hasRequestedData
      ? {
          tenantId: activeProject.id,
          dbmsId: selectedInstanceId,
          args: {
            filter: filterInput,
            pagination: logsPagination,
            sort: querySorting,
          },
        }
      : skipToken,
  );

  const logAggregations = useMemo(
    () => mapDeprecationLogAggregations(getLogAggregationsRes.data),
    [getLogAggregationsRes.data],
  );
  const pageInfo = usePageInfo(getLogAggregationsRes.data?.pageInfo);

  const hasData = useRef(Boolean(logAggregations.length));
  if (!getLogAggregationsRes.isFetching) {
    hasData.current = Boolean(logAggregations.length);
  }

  const columns = useDeprecationColumns(hasData.current, chartTimeRange);
  const columnPrefs = useColumnPreferences('deprecations', 'deprecated-queries', columns);

  const tableProps = useReactTable({
    columns,
    data: logAggregations,
    defaultColumn: {
      enableColumnFilter: false,
      sortDescFirst: true,
    },
    manualPagination: true,
    manualSorting: true,
    pageCount: pageInfo.pageCount,
    enableColumnPinning: true,
    state: {
      pagination: controlledPagination,
      sorting: controlledSorting,
      columnOrder: columnPrefs.prefs.columnOrder,
      columnVisibility: columnPrefs.prefs.columnVisibility,
      columnPinning: {
        right: [ACTIONS_COLUMN_ID],
      },
    },
    onPaginationChange,
    onSortingChange,
    onColumnOrderChange: columnPrefs.onColumnOrderChange,
    onColumnVisibilityChange: columnPrefs.onColumnVisibilityChange,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
  });

  // Listen to changes in table data and reset the paging accordingly
  useEffect(() => {
    tableProps.resetPagination();
    if (logAggregations.length === 0) {
      pageInfo.pageCount = 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterInput, tableProps, selectedInstanceId, activeProject.id]);

  const SmallRequestButton = useMemo(
    () => (
      <Button
        className="m-2"
        size="small"
        isDisabled={getLogAggregationsRes.isFetching}
        color="primary"
        fill="outlined"
        onClick={() => setIsRequestDialogOpen(true)}
        htmlAttributes={{
          'data-testid': 'request-log',
        }}
      >
        Fetch deprecation logs
      </Button>
    ),
    [getLogAggregationsRes.isFetching, setIsRequestDialogOpen],
  );

  const dateFrom = new Date(filterInput.from);
  const dateTo = new Date(filterInput.to);
  const isStaleData =
    chartTimeRange.startTime.getTime() !== dateFrom.getTime() || chartTimeRange.endTime.getTime() !== dateTo.getTime();
  const datesTooFarApart = useMemo(
    () => !datesWithin24H(chartTimeRange.startTime, chartTimeRange.endTime),
    [chartTimeRange],
  );
  const [shouldLoadFilters, setShouldLoadFilters] = useState(false);

  const didApplyFilters =
    filterNames.some((name) => rawFilters.dbmsFilters[name].length > 0) || Boolean(rawFilters.querySearchString);

  const shouldFetch =
    isNonEmptyString(selectedInstanceId) &&
    hasDeprecations &&
    (shouldLoadFilters || didApplyFilters) &&
    // Only fetch filter values when dialog is open to match behavior of Query/Security logs
    // where fetching logic lives inside their respective dialog components (QueryLogFilters and SecurityLogFilters)
    isRequestDialogOpen;

  const getDeprecationLogFilterValuesRes = opsApi.useGetDeprecationLogFilterValuesQuery(
    shouldFetch
      ? {
          tenantId: activeProject.id,
          dbmsId: selectedInstanceId,
          from: chartTimeRange.startTime.getTime(),
          to: chartTimeRange.endTime.getTime(),
        }
      : skipToken,
  );

  const availableDbmsFilters = useMemo(
    () => getDeprecationLogFilterValuesRes.data ?? initialFilters().dbmsFilters,
    [getDeprecationLogFilterValuesRes.data],
  );

  const MakeFilterField = useCallback(
    (filterName: OpsTypes.Migration.FilterableFields, valueFormatter: (v: string) => string): FilterField => {
      const init = initialFilters();
      return {
        id: filterName,
        label: FriendlyDeprecationLogFilterName[filterName],
        value: getListIfSubsetOrDefault(rawFilters.dbmsFilters[filterName], availableDbmsFilters[filterName]),
        defaultValue: init.dbmsFilters[filterName],
        values: availableDbmsFilters[filterName],
        valueFormatter,
      };
    },
    [availableDbmsFilters, rawFilters.dbmsFilters],
  );

  const memoizedFilterFields = useMemo(() => {
    return Objects.fromEntries(
      filterNames.map((name) => [name, MakeFilterField(name, valueFormatters[name] ?? Functions.identity)]),
    );
  }, [MakeFilterField]);

  const memoizedSearchFields = useMemo<SearchField[]>(
    () => [
      {
        id: 'Query Text',
        label: 'Query text',
        value: rawFilters.querySearchString ?? '',
        defaultValue: initialFilters().querySearchString ?? '',
        placeholder: "e.g. 'MATCH (n:Node)'",
      },
    ],
    [rawFilters],
  );

  const statusText = (
    <Typography variant="body-medium" as="span" className="flex flex-wrap items-center gap-1">
      {hasRequestedData && `Showing logs from ${timePeriodFormatter(dateFrom)} to ${timePeriodFormatter(dateTo)}. `}
      {(!isStaleData || !hasRequestedData) &&
        `Due to query caching, the deprecation occurrences in the chart do not
      necessarily equal the query counts in the table.`}
      {hasRequestedData && isStaleData && <span className="font-bold">Selection is stale.</span>}
      {hasDeprecations && isStaleData && !datesTooFarApart && SmallRequestButton}
      {!hasDeprecations && <span>Select a time range containing deprecation in the chart above.</span>}
    </Typography>
  );

  return (
    <>
      {isRequestDialogOpen && (
        <RequestDialog
          header="Fetch deprecation logs"
          modalProps={{ style: { width: WIDTH_MD } }}
          isOpen
          onClose={() => {
            setShouldLoadFilters(false);
            setIsRequestDialogOpen(false);
          }}
          onLoadFilters={() => setShouldLoadFilters(true)}
          onAccept={(fields, search) => {
            setRawFilters(
              produce((draft) => {
                filterNames.forEach((name) => {
                  if (fields[name]?.value) {
                    draft.dbmsFilters[name] = fields[name].value;
                  }
                });
                draft.timeRange = chartTimeRange;
                draft.querySearchString = search.find((f) => f.id === memoizedSearchFields[0]?.id)?.value ?? '';
              }),
            );
            setHasRequestedData(true);
          }}
          timeRange={chartTimeRange}
          hasQueries={hasDeprecations}
          filterFields={memoizedFilterFields}
          searchFields={memoizedSearchFields}
          showFiltersDefault={didApplyFilters}
          isLoading={getDeprecationLogFilterValuesRes.isFetching}
          filterError={getDeprecationLogFilterValuesRes.error}
        />
      )}
      <div className="absolute right-0 top-0 m-1.5 flex flex-row flex-wrap items-center gap-2">
        <Button
          size="medium"
          color="primary"
          isDisabled={getLogAggregationsRes.isFetching || datesTooFarApart}
          onClick={() => {
            setIsRequestDialogOpen(true);
          }}
          htmlAttributes={{
            'data-testid': 'request-log-start',
          }}
        >
          Fetch deprecation logs
        </Button>
      </div>
      <DataGridHelpers.Wrapper style={{ maxHeight: '90vh' }}>
        <div className="relative w-full">
          <div className="mb-4">
            {/* TODO shared */}
            {getLogAggregationsRes.error && (
              <ApiErrorBanner
                hasIcon
                title="Error while fetching logs"
                error={getLogAggregationsRes.error}
                className="mt-8"
              />
            )}
            {hasRequestedData && <Banner className="mt-8" title={statusText} usage="inline" name="Selection status" />}
          </div>
          <FilterTags
            activeFilters={getActiveFilters(rawFilters.dbmsFilters)}
            activeSearchFilters={getActiveSearchFilters(rawFilters)}
            activeNumericalFilters={[]}
            valueFormatters={valueFormatters}
          />
        </div>

        <DataGrid
          rootProps={{
            className: `[&_.ndl-data-grid-pinned-cell-right]:!grow [&_[role=columnheader].ndl-data-grid-pinned-cell-right]:border-l [&_[role=columnheader].ndl-data-grid-pinned-cell-right]:border-l-palette-neutral-border-weak ${classes['clean-actions-header']}`,
          }}
          styling={{ headerStyle: 'clean' }}
          isLoading={getLogAggregationsRes.isFetching}
          isAutoResizingColumns={false}
          tableInstance={tableProps}
          components={{
            HeaderCell: ColumnPreferencesHeaderCell,
            BodyRow: DataGridHelpers.HoverBodyRow,
            TableResults: () => (
              <TableResults
                pageIndex={controlledPagination.pageIndex}
                pageLength={controlledPagination.pageSize}
                rowsLength={pageInfo.itemCount}
              />
            ),
            NoDataPlaceholder: () => {
              let content: ReactNode = 'There are no queries within the selected time range.';
              if (hasRequestedData && !isStaleData) {
                content = 'No matches found for specified time range and filters.';
              } else if (datesTooFarApart) {
                content = "At most 24 hours' worth of logs can be fetched. Drag in the chart to narrow time window.";
              } else if (hasDeprecations) {
                content = <>Click {SmallRequestButton} to display deprecation details.</>;
              }
              return <CommonNoDataPlaceholder>{content}</CommonNoDataPlaceholder>;
            },
          }}
          isKeyboardNavigable={false}
        />
      </DataGridHelpers.Wrapper>
    </>
  );
};
