import { Banner } from '@neo4j-ndl/react';

export const SuccessMessage = () => (
  <Banner type="success" title="Upload complete!" data-testid="upload-successful">
    <p>{"Your database will go in to a 'loading' state as the dump is being applied."}</p>
  </Banner>
);

export const InfoMessage = ({ message, header }: { message: string; header: string }) => (
  <Banner type="info" title={header} data-testid="upload-info">
    <p>{message}</p>
  </Banner>
);

export const ErrorMessage = ({ message }: { message: string }) => (
  <Banner type="danger" title="Error" data-testid="upload-error">
    <p>{message}</p>
  </Banner>
);
