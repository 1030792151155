import { LoadingSpinner } from '@neo4j-ndl/react';
import type { DataApiBatchResult, Instance } from '@nx/state';
import { INSTANCE_STATUS, consoleApi, graphqlApi, useActiveProject } from '@nx/state';
import { isNullish } from '@nx/stdlib';
import { Center } from '@nx/ui';
import { useCallback, useEffect, useState } from 'react';

import { ApiErrorBanner } from '../components/api-error-banner';
import { EmptyState } from '../components/empty-state';
import { NoInstancesState } from '../components/no-instance-state';
import { withUpxContext } from '../components/upx-context';
import { ALLOWED_INSTANCE_TIERS, POLLING_INTERVAL_DATA_APIS } from '../settings';
import { MODIFY_MODE } from '../types';
import { CreateDataApi } from './create';
import { EditDataApi } from './edit';
import { Overview } from './overview';

const ManagementMain = () => {
  const activeProject = useActiveProject();
  const [getInstance, { error: errorInstance }] = consoleApi.useLazyGetInstanceQuery();
  const [getDataApiBatch, { data: dataApis, error: errorDataApis }] = graphqlApi.useLazyGetDataApisBatchQuery();
  const { data: instanceSummaries, error: errorInstanceSummaries } = consoleApi.useListInstancesQuery(
    activeProject.id,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [runningInstances, setRunningInstances] = useState<Instance[] | null>(null);
  const [hasCheckedInstancesInitially, setHasCheckedInstancesInitially] = useState(false);

  const [modifyMode, setModifyMode] = useState<MODIFY_MODE>(MODIFY_MODE.closed);
  const [activeModifyDataApi, setActiveModifyDataApi] = useState<DataApiBatchResult | null>(null);

  const runningInstancesWithoutDataApi =
    runningInstances?.filter((instance) => !(dataApis?.some((api) => api.instanceId === instance.id) ?? false)) ?? null;

  useEffect(() => {
    async function fetchDetailsOfRunningInstances() {
      if (instanceSummaries && instanceSummaries.length === 0) {
        setRunningInstances([]);
        setHasCheckedInstancesInitially(true);
        return;
      }
      if (instanceSummaries && instanceSummaries.length > 0) {
        const promises = instanceSummaries.map(({ id }) => getInstance(id).unwrap());
        const detailedInstances = await Promise.all(promises);

        // Filter out instances that are not running or are not of an allowed tier
        const theRunningInstances = detailedInstances.filter(
          (instance) =>
            instance.instanceStatus === INSTANCE_STATUS.RUNNING && ALLOWED_INSTANCE_TIERS.includes(instance.tier),
        );
        setRunningInstances(theRunningInstances);
        setHasCheckedInstancesInitially(true);
      }
    }
    void fetchDetailsOfRunningInstances();
  }, [getInstance, instanceSummaries]);

  const fetchDataApis = useCallback(async () => {
    if (!hasCheckedInstancesInitially || isNullish(runningInstances)) {
      return;
    }

    if (runningInstances.length > 0) {
      const args = runningInstances.map((instance) => ({
        projectId: activeProject.id,
        instanceId: instance.id,
        instanceName: instance.name,
      }));
      await getDataApiBatch(args).unwrap();
    }
  }, [activeProject.id, runningInstances, hasCheckedInstancesInitially, getDataApiBatch]);

  useEffect(() => {
    void fetchDataApis();
    const intervalId = setInterval(() => {
      if (modifyMode === MODIFY_MODE.closed) {
        void fetchDataApis();
      }
    }, POLLING_INTERVAL_DATA_APIS);
    return () => clearInterval(intervalId);
  }, [fetchDataApis, modifyMode]);

  if (!hasCheckedInstancesInitially) {
    return (
      <Center>
        <LoadingSpinner size="large" />
      </Center>
    );
  }

  if (errorInstanceSummaries) {
    return <ApiErrorBanner error={errorInstanceSummaries} hasIcon className="m-8" />;
  }

  if (errorDataApis) {
    return <ApiErrorBanner error={errorDataApis} hasIcon className="m-8" />;
  }

  if (errorInstance) {
    return <ApiErrorBanner error={errorInstance} hasIcon className="m-8" />;
  }

  if (isNullish(runningInstances) || runningInstances.length === 0) {
    return <NoInstancesState />;
  }

  if (dataApis) {
    if (modifyMode === MODIFY_MODE.closed) {
      if (dataApis.length === 0) {
        return <EmptyState onClickCreateApi={() => setModifyMode(MODIFY_MODE.create)} />;
      }

      if (dataApis.length > 0) {
        return (
          <Overview
            dataApis={dataApis}
            fetchDataApis={fetchDataApis}
            setActiveModifyDataApi={setActiveModifyDataApi}
            setModifyMode={setModifyMode}
          />
        );
      }
    }

    return (
      <div className="h-full overflow-auto p-4" data-testid="data-api-app">
        {modifyMode === MODIFY_MODE.create ? (
          <CreateDataApi
            runningInstances={runningInstancesWithoutDataApi}
            closeModifyPage={() => {
              setActiveModifyDataApi(null);
              setModifyMode(MODIFY_MODE.closed);
            }}
          />
        ) : (
          <EditDataApi
            runningInstances={runningInstancesWithoutDataApi}
            closeModifyPage={() => {
              setActiveModifyDataApi(null);
              setModifyMode(MODIFY_MODE.closed);
            }}
            activeModifyDataApi={activeModifyDataApi}
          />
        )}
      </div>
    );
  }

  // This is a fallback where there is a slight delay between the dataApis being fetched and the UI being updated
  return (
    <Center>
      <LoadingSpinner size="large" />
    </Center>
  );
};
const ManagementMainWithUpxContext = withUpxContext(ManagementMain);

export default ManagementMainWithUpxContext;
