import { TextLink, Typography } from '@neo4j-ndl/react';

export interface HelpTooltipProps {
  body: React.ReactNode;
  link: {
    href: string;
    text: string;
  } | null;
}

const HelpTooltip = ({ body, link = null }: HelpTooltipProps) => (
  <div className="max-w-xl text-left">
    <div className="pb-4">{body}</div>
    {link ? (
      <Typography variant="body-medium" className="flex gap-1">
        Learn more about{' '}
        <TextLink
          href={link.href}
          isExternalLink
          htmlAttributes={{
            rel: 'noreferrer',
          }}
        >
          {`${link.text}`}
        </TextLink>
      </Typography>
    ) : null}
  </div>
);

export default HelpTooltip;
