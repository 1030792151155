import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../types';
import type { InitiateModalPayload, ModalState } from './types';

export const NAME = 'modal';

const initialState: ModalState = {
  header: undefined,
  subtitle: undefined,
  description: undefined,
  actions: [],
  modalType: undefined,
  mandatory: false,
  open: false,
};

export const getModalState = (state: RootState): ModalState => state[NAME] ?? initialState;

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    closeModalOverlay: () => initialState,
    initiateModal: (state, action: PayloadAction<InitiateModalPayload>) => {
      const { payload } = action;
      return {
        ...initialState,
        ...payload,
        open: true,
      };
    },
    initiateErrorModal: (state, action: PayloadAction<string>) => {
      const errorMessage = action.payload;
      return {
        ...initialState,
        subtitle: errorMessage,
        actions: [{ text: 'OK' }],
        modalType: 'warning',
        mandatory: true,
        open: true,
      };
    },
  },
});

export const { closeModalOverlay, initiateModal, initiateErrorModal } = slice.actions;
export default slice.reducer;
