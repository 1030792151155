import { log } from '../../../services/logging';

/**
 * Manages a queue of asynchronous tasks, ensuring they are executed sequentially
 */
export class AsyncQueue {
  #queue: (() => Promise<void>)[] = [];

  #isProcessing = false;

  add(task: () => Promise<void>): void {
    this.#queue.push(task);
    void this.#process();
  }

  async #process(): Promise<void> {
    if (this.#isProcessing) return;
    this.#isProcessing = true;

    while (this.#queue.length > 0) {
      const currentTask = this.#queue.shift();
      try {
        await currentTask?.();
      } catch (error) {
        log.error('Task execution failed:', error);
      }
    }

    this.#isProcessing = false;
  }
}
