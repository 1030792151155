import type { InvoiceResponse } from '../console.api.types';
import type { Invoice } from '../console.types';
import { transformInvoiceResponse } from '../transformers/invoice';
import { type Builder } from './types';

export const invoiceEndpoints = (builder: Builder) => ({
  getInvoices: builder.query<Invoice[], string>({
    query: (projectId) => `tenants/${projectId}/billing/invoices`,
    transformResponse: (invoices: InvoiceResponse[]) => invoices.map((invoice) => transformInvoiceResponse(invoice)),
    providesTags: (result, error, projectId) => [{ type: 'Invoice', id: projectId }],
  }),
});
