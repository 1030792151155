import { intersection, isEmpty, isNil, max, mean, min, uniq } from 'lodash-es';

import {
  isFullTextSearchSuggestion,
  isNodeSuggestion,
  isRelationshipSuggestion,
  isSearchPhraseSuggestion,
} from '../../modules/SearchBar/SearchBar.utils';
import type { Suggestion } from '../../modules/SearchBar/types';
import type { Node, Relationship } from '../../types/graph';
import type {
  CategoryWithStyle,
  PerspectiveCategory,
  PerspectiveRelationshipType,
  RelationshipTypeWithStyle,
} from '../../types/perspective';
import type { EmptyObj } from '../../types/utility';
import { isFalsy, isNonUndefined } from '../../types/utility';
import type { GetEventPropertiesFromPerspective, GetEventPropertiesFromPerspectiveResult } from './types';

const isCategoryWithStyle = (category: PerspectiveCategory): category is CategoryWithStyle => 'styleRules' in category;
const isRelTypeWithStyle = (relType: PerspectiveRelationshipType): relType is RelationshipTypeWithStyle =>
  'styleRules' in relType;

export const getEventPropertiesFromPerspective = ({
  perspective = null,
  autoGeneratedSchema = null,
  trackType = false,
}: GetEventPropertiesFromPerspective): GetEventPropertiesFromPerspectiveResult | EmptyObj => {
  if (perspective === null) {
    return {};
  }

  let numCategories = 0;
  let numRelationshipTypes = 0;
  let numPropertyKeys = 0;
  let numStyles = 0;

  if (autoGeneratedSchema !== null) {
    numCategories = autoGeneratedSchema.categories.length;
    numRelationshipTypes = autoGeneratedSchema.relTypes.length;

    numPropertyKeys = 0;
    for (const label in autoGeneratedSchema.propertyKeysForLabelsMap) {
      const length = autoGeneratedSchema.propertyKeysForLabelsMap[label]?.length ?? 0;
      numPropertyKeys += length;
    }
    for (const rel in autoGeneratedSchema.relPropsMap) {
      const length = autoGeneratedSchema.relPropsMap[rel]?.length ?? 0;
      numPropertyKeys += length;
    }
  } else {
    const categories = perspective.categories ?? [];
    const visibleRelationshipTypes = (perspective.relationshipTypes ?? []).filter((r) =>
      isFalsy(perspective?.hiddenRelationshipTypes?.includes(r.name)),
    );

    numCategories = categories.length;
    numRelationshipTypes = visibleRelationshipTypes.length;

    categories.forEach((c) => {
      numPropertyKeys += c.properties.filter((p) => !(p.exclude ?? false)).length;

      if (isCategoryWithStyle(c)) {
        numStyles += c.styleRules.length;
      }
    });
    visibleRelationshipTypes.forEach((relType) => {
      numPropertyKeys += relType.properties.length;
      if (isRelTypeWithStyle(relType)) {
        numStyles += relType.styleRules.length;
      }
    });
  }

  return {
    perspectiveId: perspective.id,
    numSearchPhrases: perspective.templates != null ? perspective.templates.length : 0,
    ...(trackType && { type: perspective.type }),
    numCategories,
    numRelationshipTypes,
    numPropertyKeys,
    numStyles,
  };
};

export const getGPUChip = () => {
  if (isFalsy(window.CanvasRenderingContext2D)) return;
  const canvas = document.createElement('canvas');
  try {
    const gl = canvas.getContext('webgl') ?? (canvas.getContext('experimental-webgl') as WebGLRenderingContext);
    let renderer = '';
    if (gl != null) {
      const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
      if (debugInfo != null) {
        renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
      }
    }
    return renderer;
  } catch (e) {
  } finally {
    canvas.remove();
  }
};

export const getNumWordsFromInput = (inputString = '') => {
  const trimmedInput = inputString.trim();
  return trimmedInput.length > 0 ? trimmedInput.split(' ').length : 0;
};

export const getEventPropertiesFromSearchSuggestion = (suggestion?: { type: string; text: string }) => {
  return suggestion != null
    ? {
        queryType: suggestion.type,
        numWords: getNumWordsFromInput(suggestion.text),
      }
    : {};
};

export const getEventPropertiesFromSearchSuggestions = (suggestions: Suggestion[]) => {
  const head = suggestions.at(-1);
  if (isFullTextSearchSuggestion(head)) {
    return { queryType: 'fulltextV2' };
  }

  if (isSearchPhraseSuggestion(head)) {
    if (head.displayText.toLowerCase().trim() === 'show me a graph') {
      return { queryType: 'showMeGraphV2' };
    }

    return {
      queryType: 'searchPhraseV2',
      numWords: getNumWordsFromInput(head.displayText),
    };
  }

  const usedConditionals = suggestions.some((suggestion) => {
    if (isNodeSuggestion(suggestion) || isRelationshipSuggestion(suggestion)) {
      return !isNil(suggestion.propertyConditionValue);
    }
    return false;
  });
  return {
    queryType: 'patternV2',
    numWords: suggestions.length,
    usedConditionals,
  };
};

export const getEventPropertiesFromVisibleGraph = (
  visibleNodeIds: string[],
  visibleRelationshipIds: string[],
  nodesInventory: Record<string, Node>,
  relationshipsInventory: Record<string, Relationship>,
  categoryDefs: CategoryWithStyle[],
) => {
  const numNodes = visibleNodeIds.length;
  const numRelationships = visibleRelationshipIds.length;

  const visibleNodes = visibleNodeIds.map((id) => nodesInventory[id]).filter(isNonUndefined);
  const visibleRels = visibleRelationshipIds.map((id) => relationshipsInventory[id]).filter(isNonUndefined);
  const categories = visibleNodes.map((node) =>
    categoryDefs.find((cat) => intersection(cat.labels, node.labels).length > 0),
  );
  const relTypes = visibleRels.map((rel) => rel.type);
  const numCategories = uniq(categories).length;
  const numRelTypes = uniq(relTypes).length;

  const neighbourMap: Record<string, number> = {};
  let numSelfReferringRels = 0;
  visibleRels.forEach(({ startId, endId }) => {
    if (startId !== endId) {
      neighbourMap[startId] = (neighbourMap[startId] ?? 0) + 1;
      neighbourMap[endId] = (neighbourMap[endId] ?? 0) + 1;
    } else {
      numSelfReferringRels++;
    }
  });
  const neighbourCounts = isEmpty(neighbourMap) ? [0] : Object.values(neighbourMap);
  const maxNeighbours = max(neighbourCounts);
  const minNeighbours = min(neighbourCounts);
  const meanNeighbours = mean(neighbourCounts);

  return {
    numNodes,
    numRelationships,
    numSelfReferringRels,
    numCategories,
    numRelTypes,
    maxNeighbours,
    minNeighbours,
    meanNeighbours,
  };
};
