import type { OpsTypes } from '@nx/state';

export const initialFilters = (): OpsTypes.Migration.RawFilterSelection => ({
  dbmsFilters: {
    databases: [],
    users: [],
    drivers: [],
    apps: [],
    initiationTypes: [],
    deprecationNotificationNames: [],
  },
  timeRange: { endTime: new Date() },
  querySearchString: '',
});
