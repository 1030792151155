import type { ConnectionDescriptor } from '@nx/constants';
import type { SerializedError } from '@reduxjs/toolkit';
import type { Neo4jError } from 'neo4j-driver';

export interface Connection {
  /**
   * User-defined connection display name
   * Defaults to the connection ID - host and port
   */
  name: string;

  /**
   * Database credentials to authenticate with
   */
  credentials: ConnectionDescriptor;

  /**
   * Connection error as returned by driver
   */
  error?: Neo4jError | SerializedError;

  /**
   * Last connection time as Unix timestamp
   */
  lastUsedAt?: number;

  /**
   * Connection URL
   */
  url: string;

  /**
   * True if connection was established by clicking connect on an instance tile
   */
  isAuraInstanceFromAPI?: boolean;

  /**
   * Discovery URLs
   */
  discoveryUrls?: string[];
}

export function isConnectionState(input: unknown): input is Connection {
  if (typeof input !== 'object') {
    return false;
  }

  if (input === null) {
    return false;
  }

  return 'url' in input && 'credentials' in input;
}
