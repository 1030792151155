import type {
  CreateSsoConfigRequest,
  SsoConfigResponse,
  TransformedCreateSsoConfigBody,
  TransformedUpdateSsoConfigBody,
  UpdateSsoConfigRequest,
} from '../console.api.types';
import type { SsoConfig } from '../console.types';

export const transformSsoConfigResponse = (response: SsoConfigResponse): SsoConfig => {
  return {
    id: response.sso_config_id,
    displayName: response.display_name,
    channel: response.channel,
    idpType: response.idp_type,
    discoveryURI: response.discovery_uri,
    issuer: response.issuer,
    authorizationEndpoint: response.authorization_endpoint,
    tokenEndpoint: response.token_endpoint,
    jwksURI: response.jwks_uri,
    clientId: response.client_id,
    emailDomains: response.email_domains,
    roleMapping: response.role_mapping,
    organizationId: response.organization_id,
    organizationLoginMethod: response.organization_login_method,
  };
};

export const transformCreateSsoConfigRequest = (request: CreateSsoConfigRequest): TransformedCreateSsoConfigBody => {
  return {
    DisplayName: request.displayName,
    IdpType: request.idpType,
    ClientId: request.clientId,
    ClientSecret: request.clientSecret,
    OrganizationLoginMethod: request.organizationLoginMethod,
    DiscoveryUri: request.discoveryURI,
    Issuer: request.issuer,
    AuthorizationEndpoint: request.authorizationEndpoint,
    TokenEndpoint: request.tokenEndpoint,
    JwksUri: request.jwksURI,
    RoleMapping: request.roleMapping,
  };
};

export const transformUpdateSsoConfigRequest = (request: UpdateSsoConfigRequest): TransformedUpdateSsoConfigBody => {
  return {
    DisplayName: request.displayName,
    IdpType: request.idpType,
    ClientId: request.clientId,
    ClientSecret: request.clientSecret,
    OrganizationLoginMethod: request.organizationLoginMethod,
    DiscoveryUri: request.discoveryURI,
    Issuer: request.issuer,
    AuthorizationEndpoint: request.authorizationEndpoint,
    TokenEndpoint: request.tokenEndpoint,
    JwksUri: request.jwksURI,
    RoleMapping: request.roleMapping,
  };
};
