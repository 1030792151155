import type { IconButtonProps, PopoverProps } from '@neo4j-ndl/react';
import { IconButton, Popover } from '@neo4j-ndl/react';
import { CheckIconOutline, TrashIconOutline, XMarkIconOutline } from '@neo4j-ndl/react/icons';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';

type ClassName = React.ComponentProps<typeof IconButton>['className'];

type IconButtonWithConfirmPopoverProps = Omit<IconButtonProps, 'ref' | 'className'> & {
  className?: ClassName | ((isPopoverOpen: boolean) => ClassName);
  htmlAttributes?: React.ComponentProps<typeof IconButton<'button'>>['htmlAttributes'];
  // Pick more props as needed
  popoverProps?: PopoverProps;
  // Make children optional as cancel/confirm icons are already provided
  cancelIconButtonProps?: Omit<React.ComponentProps<typeof IconButton<'button'>>, 'children' | 'ariaLabel'> & {
    children?: IconButtonProps['children'];
    /** Optional because it's derived from the main icon button's ariaLabel */
    ariaLabel?: string;
  };
  confirmIconButtonProps?: Omit<React.ComponentProps<typeof IconButton<'button'>>, 'children' | 'ariaLabel'> & {
    children?: IconButtonProps['children'];
    /** Optional because it's derived from the main icon button's ariaLabel */
    ariaLabel?: string;
  };
};

export function IconButtonWithConfirmPopover({
  cancelIconButtonProps,
  confirmIconButtonProps,
  popoverProps,
  ...iconButtonProps
}: IconButtonWithConfirmPopoverProps) {
  const ref = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = (open: boolean) => {
    if (!open) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <>
      <IconButton
        {...iconButtonProps}
        htmlAttributes={{
          title: iconButtonProps.htmlAttributes?.title ?? iconButtonProps.ariaLabel,
        }}
        ref={ref}
        onClick={(e) => {
          iconButtonProps.onClick?.(e);
          toggle(false);
        }}
        className={classNames(
          typeof iconButtonProps.className === 'function'
            ? iconButtonProps.className(isOpen)
            : iconButtonProps.className,
        )}
        isActive={iconButtonProps.isActive ?? isOpen}
      >
        <TrashIconOutline />
      </IconButton>
      <Popover
        anchorElement={ref.current}
        isOpen={isOpen}
        onOpenChange={toggle}
        // TODO: Check position of the popover
      >
        <Popover.Content>
          <div className="flex gap-2 p-1">
            <IconButton
              {...cancelIconButtonProps}
              isClean={cancelIconButtonProps?.isClean ?? true}
              ariaLabel={cancelIconButtonProps?.ariaLabel ?? `Cancel ${iconButtonProps.ariaLabel.toLocaleLowerCase()}`}
              htmlAttributes={{
                title:
                  cancelIconButtonProps?.htmlAttributes?.title ??
                  `Cancel ${iconButtonProps.ariaLabel.toLocaleLowerCase()}`,
              }}
              onClick={(e) => {
                cancelIconButtonProps?.onClick?.(e);
                toggle(false);
              }}
            >
              <XMarkIconOutline />
            </IconButton>
            <IconButton
              {...confirmIconButtonProps}
              isClean={confirmIconButtonProps?.isClean ?? true}
              ariaLabel={
                confirmIconButtonProps?.ariaLabel ?? `Confirm ${iconButtonProps.ariaLabel.toLocaleLowerCase()}`
              }
              htmlAttributes={{
                title:
                  confirmIconButtonProps?.htmlAttributes?.title ??
                  `Confirm ${iconButtonProps.ariaLabel.toLocaleLowerCase()}`,
              }}
              onClick={(e) => {
                confirmIconButtonProps?.onClick?.(e);
                toggle(false);
              }}
            >
              <CheckIconOutline />
            </IconButton>
          </div>
        </Popover.Content>
      </Popover>
    </>
  );
}
