import { set, uniqBy } from 'lodash-es';
import { Integer } from 'neo4j-driver';
import type { Node as Neo4jNode, Record as Neo4jRecord, Relationship as Neo4jRelationship } from 'neo4j-driver';

import type { Node, Relationship } from '../../types/graph';
import type { PathSegment } from '../../types/perspective';
import type { Nullable } from '../../types/utility';
import { extractNodesAndRelationshipsFromRecords } from '../bolt/boltMappings';
import { parseConstraint } from '../editing/constraints';
import { perspectiveLabel } from '../initialization/queryGenerator';
import { getStrippedVersion } from '../versions/version';
import { mapNeo4jTypeString, mapNode, mapProperty, mapRel, safelyConvertNeo4jInt } from './mappers';
import { systemKeywordsRegex } from './queryHandler';
import type {
  AuthResult,
  GetBoltResultMapper,
  Index,
  IndexInfo,
  IndexMapper,
  LabelStats,
  LicenseResult,
  MapConnectedEntities,
  SettingsResult,
  UserDetails,
} from './types';

const propertyKeyRegex = /(`|'|"|:)/gi;

export const extractIndexValues = (indexRecord: Neo4jRecord): IndexInfo => {
  const indexInfo: any = {
    type: indexRecord.get('type'),
    state: indexRecord.get('state'),
    entityType: indexRecord.get('entityType'),
  };
  const { keys } = indexRecord;

  if (keys.includes('properties') && keys.includes('label')) {
    indexInfo.label = indexRecord.get('label');
    indexInfo.propertyNames = indexRecord.get('properties');
  } else if (keys.includes('indexName') && keys.includes('tokenNames') && keys.includes('properties')) {
    // 3.5
    indexInfo.tokenNames = indexRecord.get('tokenNames');
    indexInfo.name = indexRecord.get('indexName');
    indexInfo.propertyNames = indexRecord.get('properties');
    indexInfo.provider = indexRecord.get('provider');
  } else if (keys.includes('name') && keys.includes('labelsOrTypes') && keys.includes('properties')) {
    // 4.X and 5.X
    indexInfo.tokenNames = indexRecord.get('labelsOrTypes');
    indexInfo.name = indexRecord.get('name');
    indexInfo.propertyNames = indexRecord.get('properties') ?? [];

    const entityType = indexRecord.get('entityType');

    if (entityType?.toLowerCase() === 'node') {
      const fulltext = !!(indexInfo?.type.toLowerCase() === 'fulltext');
      let type = 'node_label_property';
      if (fulltext) {
        type = 'node_fulltext';
      }
      indexInfo.type = type;
    } else if (entityType?.toLowerCase() === 'relationship') {
      const fulltext = !!(indexInfo?.type.toLowerCase() === 'fulltext');
      let type = 'relationship_type_property';
      if (fulltext) {
        type = 'relationship_fulltext';
      }
      indexInfo.type = type;
    }

    const provider = indexRecord.has('indexProvider') ? indexRecord.get('indexProvider') : indexRecord.get('provider');

    const lastDashIndex: number = provider.lastIndexOf('-');
    const key = lastDashIndex !== -1 ? provider.substring(0, lastDashIndex) : provider;
    const version = lastDashIndex !== -1 ? provider.substring(lastDashIndex + 1) : '';

    indexInfo.provider = {
      key,
      version,
    };
  } else if (indexRecord.keys.includes('description')) {
    const indexDescription = indexRecord.get('description');
    const descriptionRegex = /INDEX ON :(.*)\((.*)\)/g;
    const match = descriptionRegex.exec(indexDescription);

    indexInfo.label = match != null ? match[1] : undefined;
    indexInfo.propertyNames = match != null ? match[2]?.split(', ') : undefined;
  } else {
    throw new Error(`failed to parse index record with keys: ' + ${indexRecord.keys}`);
  }

  return indexInfo as IndexInfo;
};

export const recordMapper = (key: string) => (records: Neo4jRecord[]) => records.map((r) => r.get(key));

const nodeMapper = (node: Neo4jNode) => mapNode(node);
const relationshipMapper = (rel: Neo4jRelationship) => mapRel(rel);

export const getBoltResultMapper = (
  graphMapper: {
    nodeMapper: (n: Neo4jNode) => Node;
    relationshipMapper: (r: Neo4jRelationship) => Relationship;
  },
  actualMapper = extractNodesAndRelationshipsFromRecords,
) => {
  return (records: Neo4jRecord[], limit: Nullable<number> = null): GetBoltResultMapper => {
    // eslint-disable-next-line prefer-const
    let { nodes, relationships } = actualMapper(records);

    if (limit != null && nodes != null && nodes.length > limit) {
      nodes = nodes.slice(0, limit);
    }

    return {
      nodes: nodes != null ? nodes.map((node) => graphMapper.nodeMapper(node)) : [],
      relationships: relationships != null ? relationships.map((rel) => graphMapper.relationshipMapper(rel)) : [],
    };
  };
};

export const graphMapper = getBoltResultMapper({ nodeMapper, relationshipMapper });

export const labelMapper = (records: Neo4jRecord[]): string[] => {
  return records.map((r) => {
    return r.get('label');
  });
};

export const labelStatsMapper = (records: Neo4jRecord[]): LabelStats[] => {
  return records.map((r) => {
    const info = r.get('info');
    return {
      label: info.label,
      count: info.size,
    };
  });
};

export const userDetailsMapper = (result: { records: Neo4jRecord[] }): UserDetails | undefined => {
  if (result.records.length > 0) {
    const [record] = result.records;
    if (!record) {
      return;
    }
    let roles;
    try {
      roles = record.get('roles');
    } catch (err) {
      // roles does not exist so we default to admin
      roles = ['admin'];
    }

    return {
      username: record.get('user') ?? record.get('username'),
      roles,
      home: record.get('home') ?? undefined,
    };
  }
};

export const rolesDetailsMapper = (result: { records: Neo4jRecord[] }): string[] | undefined => {
  if (result.records.length > 0) {
    let roles;
    try {
      roles = result.records.map((r) => r.get('role'));
    } catch (err) {
      roles = [];
    }

    return roles;
  }
};

export const userPrivilegesMapper = (result: { records: Neo4jRecord[] }) => {
  return result.records.map((r) => {
    const privilege: Record<PropertyKey, unknown> = {};
    r.keys.forEach((key) => {
      privilege[key] = r.get(key);
    });
    return privilege;
  });
};

export const authResultMapper = (result: { records: Neo4jRecord[] }): AuthResult => {
  const errorResult = {
    success: false,
    message: 'Unknown error',
  };

  if (result.records.length > 0) {
    const [record] = result.records;
    if (!record) {
      return errorResult;
    }
    return {
      success: record.get('success'),
      message: record.get('message'),
    };
  }
  return errorResult;
};

export const licenseResultMapper = (result: { records: Neo4jRecord[] }): LicenseResult => {
  const errorResult = {
    status: 'unknown',
    message: 'Unknown error',
  };

  if (result.records.length > 0) {
    const [record] = result.records;
    if (!record) {
      return errorResult;
    }
    return {
      status: record.get('status'),
      daysLeft: record.get('daysLeft'),
      message: record.get('message'),
      success: record.get('success'),
    };
  }
  return errorResult;
};

export const versionResultMapper = (result: { records: Neo4jRecord[] }) => {
  let version = null;

  if (result.records.length > 0) {
    const [record] = result.records;
    version = getStrippedVersion(record?.get('version') ?? null);
  }

  return version;
};

function settingValueMapper(value: string) {
  const isTrue = value === 'true';
  if (isTrue || value === 'false') {
    return isTrue;
  }
}

export const settingsMapper = (records: Neo4jRecord[]): SettingsResult => {
  const mappedSettings = {};
  for (const record of records) {
    set(mappedSettings, record.get('name'), settingValueMapper(record.get('value')));
  }
  return mappedSettings;
};

export const procedureMapper = (records: Neo4jRecord[]) =>
  records.reduce(
    (acc: { procedures: string[]; procedureDescMap: Record<string, string> }, record: Neo4jRecord) => {
      const name = record.get('name');
      acc.procedures.push(name);
      acc.procedureDescMap[name] = record.get('description');
      return acc;
    },
    { procedures: [], procedureDescMap: {} },
  );

export const updateNodeResultMapper = (result: { records: Neo4jRecord[] }) => ({
  result: graphMapper(result.records).nodes,
});

export const updateRelationshipResultMapper = (result: { records: Neo4jRecord[] }) => ({
  result: graphMapper(result.records).relationships,
});

export const metadataMapper = (records: Neo4jRecord[]) => {
  const metadata: { labels: string[]; relationshipTypes: string[] } = { labels: [], relationshipTypes: [] };

  records.forEach((r) => {
    const type = r.get('type');
    const name = r.get('name');

    switch (type) {
      case 'label':
        metadata.labels.push(name);
        break;
      case 'relationshipType':
        metadata.relationshipTypes.push(name);
        break;
    }
  });

  return metadata;
};

export const schemaPathSegmentMapper = (records: Neo4jRecord[], isV5OrGreater?: boolean): Nullable<PathSegment[]> => {
  if (records.length === 0) {
    return null;
  }

  const nodes: Neo4jNode[] = records[0]?.get('nodes');
  const relationships: Neo4jRelationship[] = records[0]?.get('relationships');
  const nodeMap: Record<string, string> = {};

  return relationships
    .map((relationship) => {
      const { start, end, startNodeElementId, endNodeElementId } = relationship;
      const startId = isV5OrGreater ? startNodeElementId : start.toString();
      const endId = isV5OrGreater ? endNodeElementId : end.toString();

      const source =
        nodeMap[startId] ??
        nodes.find((node) => (isV5OrGreater ? node?.elementId === startId : node?.identity?.toString() === startId))
          ?.labels[0];
      if (source) {
        nodeMap[startId] = source;
      }

      const target =
        nodeMap[endId] ??
        nodes.find((node) => (isV5OrGreater ? node?.elementId === endId : node?.identity?.toString() === endId))
          ?.labels[0];
      if (target) {
        nodeMap[endId] = target;
      }

      if (source == null || target == null) {
        return null;
      }

      return {
        source,
        relationshipType: relationship.type,
        target,
      };
    })
    .filter((segment) => segment != null);
};

export const propertyKeySchemaMapper = (records: Neo4jRecord[]) => {
  const getDataType = (record: Neo4jRecord) =>
    record.has('propertyTypes')
      ? mapNeo4jTypeString(record.get('propertyTypes')[0])
      : (mapProperty(record.get('propertyValue'))?.type ?? String(mapProperty(record.get('propertyValue'))));

  const mappedRecords: { propertyKey: string; type: string; dataType: string }[] = [];
  records
    .filter((r) => r.get('propertyName'))
    .forEach((r) => {
      if (r.has('relType')) {
        mappedRecords.push({
          propertyKey: r.get('propertyName'),
          type: r.get('relType').replace(propertyKeyRegex, ''),
          dataType: getDataType(r),
        });
      } else {
        const nodeLabels: string[] = r.get('nodeLabels') ?? [];
        nodeLabels.forEach((type) => {
          const propertyKey = r.get('propertyName');
          const existingRecord = mappedRecords.find(
            (existingRecord) => existingRecord.propertyKey === propertyKey && existingRecord.type === type,
          );

          if (existingRecord == null) {
            mappedRecords.push({
              propertyKey,
              type,
              dataType: getDataType(r),
            });
          }
        });
      }
    });
  return uniqBy(mappedRecords, (prop) => `${prop.type}_${prop.propertyKey}`);
};

export const getCoIncidentLabelsMapper = (records: Neo4jRecord[]): string[] => {
  return records.map((r) => {
    return r.get('label');
  });
};

export const gdsVersionMapper = (records: Neo4jRecord[]) => {
  if (records.length > 0) {
    const [record] = records;
    return record?.get('gdsVersion');
  }
};

export const indexesMapper = (records: Neo4jRecord[]): IndexMapper[] => {
  const regex = new RegExp(systemKeywordsRegex);

  const shouldSkipindex = (indexInfo: IndexInfo) =>
    indexInfo.state !== 'ONLINE' ||
    indexInfo.label?.match(regex) != null ||
    (indexInfo.tokenNames?.some((token) => token.match(regex)) ?? false) ||
    !['node_label_property', 'node_unique_property', 'node_fulltext', 'relationship_fulltext'].includes(
      indexInfo.type,
    ) ||
    (indexInfo.propertyNames.length > 1 && (indexInfo.provider == null || indexInfo.provider?.key !== 'fulltext')); // Ignore composite keys until figuring out how to make use of them in graph patterns

  return Object.values(
    records.reduce((indexesMap: Record<string, any>, indexRecord: Neo4jRecord) => {
      const indexInfo = extractIndexValues(indexRecord);
      if (shouldSkipindex(indexInfo)) {
        return indexesMap;
      }

      if (indexInfo.provider?.key === 'fulltext') {
        indexesMap[`FT_${indexInfo.name}`] = { ...indexInfo, type: 'full-text' };
      } else {
        const label = indexInfo.label ?? (indexInfo.tokenNames != null ? (indexInfo.tokenNames[0] ?? null) : null);
        const key = `LI_${label}`;
        const propertyKeys = indexInfo.propertyNames;

        if (indexesMap[key] != null) {
          propertyKeys.forEach((propKey) => {
            if (!(indexesMap[key].propertyKeys.includes(propKey) as boolean)) {
              indexesMap[key].propertyKeys.push(propKey);
            }
          });
        } else {
          indexesMap[key] = { label, type: 'native', propertyKeys };
        }
      }
      return indexesMap;
    }, []),
  );
};

export const getDiversePropertiesFromRetrieveStats = (records: Neo4jRecord[]) => {
  let diversePropertiesMap: Record<string, string[]> = {};

  if (records.length > 0) {
    const stats = records[0]?.get('data');
    if (stats != null) {
      const { indexes } = stats;
      diversePropertiesMap = indexes.reduce((diversity: Record<string, string[]>, index: Index) => {
        const { labels, properties, totalSize, estimatedUniqueSize } = index;
        if (labels?.length === 1 && properties?.length === 1) {
          const [label] = labels;
          const [property] = properties;

          const estimatedUniqueSizeAsNumber = Integer.toNumber(estimatedUniqueSize);
          const totalSizeAsNumber = Integer.toNumber(totalSize);

          if (label !== undefined && totalSizeAsNumber > 0 && estimatedUniqueSizeAsNumber / totalSizeAsNumber > 0.1) {
            return {
              ...diversity,
              [label]: [...(diversity[label] ?? []), property],
            };
          }
        }
        return diversity;
      }, {});
    }
  }

  return diversePropertiesMap;
};

export const mapUniformPropertyKeys = (records: Neo4jRecord[]) => {
  return records.reduce((diversity: Record<string, string[]>, record: Neo4jRecord) => {
    const diversityRecord = record.get('diversity');

    const uniformProperties: string[] = diversityRecord.diversities
      .filter((diversity: { diversity: number }) => diversity.diversity < 0.1)
      .map((diversity: { propertyKey: string }) => diversity.propertyKey);

    if (uniformProperties.length > 0) {
      diversity[diversityRecord.label] = uniformProperties;
    }

    return diversity;
  }, {});
};

export const checkPerspectiveUniquenessConstraint = (records?: Neo4jRecord[], serverVersion?: string) => {
  return records
    ?.map((r) => r.toObject())
    .map((r) => parseConstraint(r))
    .some((record) => {
      return (
        (record?.labelsOrTypes?.includes(perspectiveLabel) as boolean) &&
        record?.type === 'UNIQUENESS' &&
        record?.properties?.includes('id')
      );
    });
};

export const mapConnectedEntities = (record: Neo4jRecord, isV5OrGreater: boolean): MapConnectedEntities => ({
  relType: record.get('relType'),
  direction: record.get('direction'),
  expandNodeIds: isV5OrGreater ? record.get('expandNodeIds') : record.get('expandNodeIds').map(safelyConvertNeo4jInt),
  revealRelIds: isV5OrGreater ? record.get('revealRelIds') : record.get('revealRelIds').map(safelyConvertNeo4jInt),
});
