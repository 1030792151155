import { Switch } from '@neo4j-ndl/react';
import type { ComponentProps } from 'react';

export const IndeterminateSwitch = ({
  indeterminate,
  ...props
}: ComponentProps<typeof Switch> & {
  indeterminate?: boolean;
}) => (
  <Switch
    ref={(node) => {
      if (node) {
        node.indeterminate = indeterminate ?? false;
      }
    }}
    {...props}
    htmlAttributes={{
      'aria-label': 'Select all',
    }}
  />
);
