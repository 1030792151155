import { Banner, Button, Checkbox, Dialog, Label, Typography } from '@neo4j-ndl/react';
import type { InviteeProjectsWithAffectedInstances, ProjectInviteDetails } from '@nx/state';
import { MODAL_TYPE, PLAN_TYPE, consoleApi, useConfigurationActions, useModal } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import { useState } from 'react';

import { isProjectRole, projectRoleFriendlyName } from '../../users/helpers';

type ItemProps = {
  invite: ProjectInviteDetails;
  onCheck: (isChecked: boolean, invite: ProjectInviteDetails) => void;
  isChecked: boolean;
  isDisabled: boolean;
};
const PendingInviteItem = ({ invite, onCheck, isChecked, isDisabled }: ItemProps) => {
  const handleChecked = (checked: boolean) => {
    onCheck(checked, invite);
  };

  // We return both org and project roles in the invite...
  // So now we hide the org roles for now until we come up with a proper UX design for this
  const projectRoles = invite.roles.filter((role) => isProjectRole(role));
  return (
    <div className="my-2 flex-grow">
      <Checkbox
        onChange={(event) => handleChecked(event.currentTarget.checked)}
        isChecked={isChecked}
        isDisabled={isDisabled}
        ariaLabel={`Invite to project ${invite.projectName}`}
        label={
          <div className="flex flex-col gap-y-1">
            <div className="flex flex-row items-center gap-2">
              <Typography variant="body-large" as="span" className="font-bold">
                {invite.projectName}
              </Typography>
              {projectRoles.map((role) => (
                <Label fill="outlined" key={role}>
                  {projectRoleFriendlyName[role]}
                </Label>
              ))}
              <Typography variant="body-medium" as="span" className="text-neutral-text-weaker">
                Invited by {invite.invitedBy}
              </Typography>
            </div>
            <Typography variant="body-medium" as="span" className="text-neutral-text-weaker">
              {invite.projectId}
            </Typography>
          </div>
        }
      />
    </div>
  );
};

export const inviteModalWarningText = (
  currentPlanType: PLAN_TYPE,
  invitesTo: Set<PLAN_TYPE>,
  inviteeProjectsWithAffectedInstances: InviteeProjectsWithAffectedInstances[],
): string => {
  const dualInvites = invitesTo.has(PLAN_TYPE.SELF_SERVE) && invitesTo.has(PLAN_TYPE.ENTERPRISE);

  const isUpgrade = currentPlanType === PLAN_TYPE.SELF_SERVE && invitesTo.has(PLAN_TYPE.ENTERPRISE);

  const hasProjectsWithAffectedInstances = inviteeProjectsWithAffectedInstances.length > 0;

  const isDowngrade = currentPlanType === PLAN_TYPE.ENTERPRISE && invitesTo.has(PLAN_TYPE.SELF_SERVE);

  const isSameLevel = !isUpgrade && !isDowngrade;

  if (isSameLevel) {
    return '';
  }
  if (isDowngrade) {
    return '';
  }
  if (hasProjectsWithAffectedInstances) {
    const projectNames = inviteeProjectsWithAffectedInstances.map(
      (invite) => `'${invite.friendlyName}' [ID: ${invite.projectId}]`,
    );
    const projectNamesString =
      projectNames.length > 1
        ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `${projectNames.slice(0, -1).join(', ')} and ${projectNames.slice(-1)}`
        : projectNames[0];
    return `You have one or more active instances in project(s) ${projectNamesString} that you would lose access to when
    upgrading to the Virtual Dedicated Cloud plan type. Before accepting an invite to a Virtual Dedicated Cloud project, please backup and 
    destroy all active instances in these projects.`;
  }

  if (dualInvites) {
    return 'Accepting a Virtual Dedicated Cloud project invitation will remove access to any self serve projects including any current invitations.';
  }

  if (isUpgrade) {
    return 'Note that accepting a Virtual Dedicated Cloud project invitation will remove access to any self serve projects.';
  }

  return '';
};

type PendingInviteModal = {
  invites: ProjectInviteDetails[];
  onClose: () => void;
  planType: PLAN_TYPE;
};

export const PendingInviteModal = ({ invites, onClose, planType }: PendingInviteModal) => {
  const [acceptInvite, acceptInviteRes] = consoleApi.useAcceptProjectInviteMutation();
  const [declineInvite, declineInviteRes] = consoleApi.useDeclineProjectInviteMutation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [checkedInvites, setCheckedInvites] = useState<ProjectInviteDetails[]>([]);

  const affectsAccessToInstances = (invite: ProjectInviteDetails) =>
    invite.inviteeProjectsWithAffectedInstances.length > 0;
  const projectsWithAffectedInstances =
    invites.find((invite) => affectsAccessToInstances(invite))?.inviteeProjectsWithAffectedInstances ?? [];
  const hasProjectsWithAffectedInstances = projectsWithAffectedInstances.length > 0;

  const actionsDisabled = acceptInviteRes.isLoading || declineInviteRes.isLoading || checkedInvites.length === 0;

  const invitesTo = new Set<PLAN_TYPE>(invites.map((invite) => invite.planType));
  const warningText = inviteModalWarningText(planType, invitesTo, projectsWithAffectedInstances);

  const handleAccept = () => {
    setErrorMessage(null);

    Promise.all(checkedInvites.map((invite) => acceptInvite(invite.id).unwrap()))
      .then(() => {
        if (checkedInvites.length === invites.length) {
          onClose();
        }
        if (!invitesTo.has(planType)) {
          window.location.reload();
        }
      })
      .catch((e) => setErrorMessage('Failed to accept invites. Please refresh the page and try again.'));
  };

  const handleDecline = () => {
    setErrorMessage(null);

    Promise.all(checkedInvites.map((invite) => declineInvite(invite.id).unwrap()))
      .then(() => {
        if (checkedInvites.length === invites.length) {
          onClose();
        }
      })
      .catch((e) => setErrorMessage('Failed to decline invites. Please refresh the page and try again.'));
  };

  const handleChecked = (checked: boolean, invite: ProjectInviteDetails) => {
    setErrorMessage(null);
    if (!checked) {
      setCheckedInvites((prevData) => prevData.filter((i) => i.id !== invite.id));
    } else {
      setCheckedInvites((prevData) => [...prevData, invite]);
    }
  };

  return (
    <Dialog isOpen onClose={onClose}>
      <Dialog.Header>Project invites</Dialog.Header>
      <Dialog.Subtitle>
        <Typography variant="body-large">You have been invited to join the following project(s):</Typography>
        {!warningText && (
          <Dialog.Subtitle className="mt-2">
            <Typography className="text-neutral-text-weaker" variant="body-medium" as="div">
              You will still be able to access your current project(s).
            </Typography>
          </Dialog.Subtitle>
        )}
      </Dialog.Subtitle>
      <Dialog.Content className="mt-4">
        <div className="flex flex-col gap-2">
          {invites.map((invite) => (
            <PendingInviteItem
              key={invite.id}
              invite={invite}
              isChecked={checkedInvites.includes(invite)}
              onCheck={handleChecked}
              isDisabled={acceptInviteRes.isLoading || declineInviteRes.isLoading}
            />
          ))}
          {isNotNullish(errorMessage) && <Banner type="danger" description={errorMessage} usage="inline" />}
          {warningText && <Banner className="mt-2" type="warning" description={warningText} usage="inline" />}
        </div>
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          fill="outlined"
          color="danger"
          isDisabled={actionsDisabled}
          onClick={handleDecline}
          isLoading={declineInviteRes.isLoading}
        >
          Decline
        </Button>
        <Button
          isDisabled={actionsDisabled || hasProjectsWithAffectedInstances}
          onClick={handleAccept}
          isLoading={acceptInviteRes.isLoading}
        >
          Accept
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export const InviteModal = () => {
  const inviteModal = useModal(MODAL_TYPE.INVITES);
  const { setInitialPendingInvitesShown, setPendingInvitesShown } = useConfigurationActions();

  const closeModal = () => {
    inviteModal.close();
    setInitialPendingInvitesShown(true);
    setPendingInvitesShown(true);
  };

  const { data: user } = consoleApi.useGetUserDetailsQuery();

  if (isNullish(user)) {
    closeModal();
    return <Banner type="danger" description="Something went wrong" usage="inline" />;
  }

  const { data: invites = [] } = consoleApi.useGetUserProjectInvitesQuery(user.id);

  return <PendingInviteModal invites={invites} onClose={closeModal} planType={user.planType} />;
};
