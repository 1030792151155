import type { Snapshot } from '@nx/state';
import { SNAPSHOT_PROFILE, SNAPSHOT_STATUS } from '@nx/state';
import { getTime, parseISO } from 'date-fns';

const reverseChronological = (a: Snapshot, b: Snapshot) =>
  getTime(parseISO(b.timestamp)) - getTime(parseISO(a.timestamp));

const splitPending = (snapshots: Snapshot[]): [Snapshot[], Snapshot[]] => {
  const nonPendingSnapshots = [];
  const pendingSnapshots = [];
  for (const snapshot of snapshots) {
    if (snapshot.status === SNAPSHOT_STATUS.PENDING) {
      pendingSnapshots.push(snapshot);
    } else {
      nonPendingSnapshots.push(snapshot);
    }
  }
  return [pendingSnapshots, nonPendingSnapshots];
};

const adhocSnapshotIsInProgress = (snapshots: Snapshot[]): boolean =>
  snapshots.some(
    (snapshot) => snapshot.status === SNAPSHOT_STATUS.IN_PROGRESS && snapshot.profile === SNAPSHOT_PROFILE.AD_HOC,
  );

export const filterAndSortSnapshots = (snapshots: Snapshot[]): Snapshot[] => {
  const [pendingSnapshots, nonPendingSnapshots] = splitPending(snapshots);
  nonPendingSnapshots.sort(reverseChronological);

  const displayPending = !adhocSnapshotIsInProgress(nonPendingSnapshots);
  const snapshotsToDisplay = displayPending ? [...pendingSnapshots, ...nonPendingSnapshots] : nonPendingSnapshots;

  return snapshotsToDisplay;
};
