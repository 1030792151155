import { SpotlightProvider, Toaster } from '@neo4j-ndl/react';
import * as DataApi from '@nx/data-api';
import { ExploreProvider } from '@nx/explore/src/nx-entry/nx-provider';
import { Provider as ImportProvider } from '@nx/import/src/upx-entry/upx-provider';
import { LDProvider, getFlag } from '@nx/launch-darkly-service';
import { InstancesPage as LocalInstancePage } from '@nx/local';
import * as Ops from '@nx/ops';
import { GuardedOnboardingPage } from '@nx/plg';
import { QueryProvider } from '@nx/query/src/nx-entry/nx-provider';
import {
  useAppManifest,
  useFeatureFlag,
  useInternalConfiguration,
  useOnboardingConfiguration,
  useServicesConfiguration,
  useToolsConfiguration,
  useUnsafeAppContext,
} from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { PageBoundary } from '@nx/ui';
import { UpxLayout, UpxRouter } from '@nx/upx-layout';
import * as React from 'react';
import { Navigate, Outlet, Route, useLocation, useParams } from 'react-router-dom';

import { AccountRoutes } from './account';
import { AppContextLoader } from './app-context';
import { CustomEndpointsGuard } from './custom-endpoints';
import { UpxErrorPage } from './error-page';
import { CookieHint } from './footer/cookie-hint';
import { useSearchParamsHandler } from './framework/use-search-params-handler';
import { ConsoleHeader } from './header/console-header';
import { InstancesPage } from './instance';
import { getOnlineStatusColor, instanceStatusText } from './instance/entities/helpers';
import { LinkAccountsPage } from './link-account';
import {
  AWSMarketplaceRegistration,
  AzureMarketplaceRegistration,
  N4GCPMarketplaceRegistration,
} from './marketplace-integration';
import { ConsoleModals } from './modals/console-modals';
import { useModalPrompts } from './modals/useModalPrompts';
import { ConsoleNav } from './nav';
import { Notifications } from './notifications';
import * as Org from './organization';
import * as Project from './project';
import { SpotlightWelcomeTour } from './spotlight-welcome-tour';
import { useConnectionNotifications } from './use-connection-notifications';
import { useLaunchDarklyIdentityContext } from './use-launch-darkly-identity-context';
import * as Users from './users';

const upxContextForIntegratedApps: React.ComponentProps<typeof Ops.Metrics>['upxContext'] = {
  instanceStatusText,
  getOnlineStatusColor,
};

function useSubpageHomePath() {
  const { userHomePath } = useUnsafeAppContext();
  const internalConfiguration = useInternalConfiguration();
  const { orgId, projectId } = useParams();
  const projectHomePath = isNotNullish(projectId) ? `projects/${projectId}/instances` : null;
  const orgHomePath = isNotNullish(orgId) ? `org/${orgId}/projects` : null;

  return projectHomePath ?? orgHomePath ?? userHomePath ?? internalConfiguration.fallbackRoute;
}

const ExploreApp = React.lazy(() => import('@nx/explore/src/nx-entry/nx-view'));
const ImportApp = React.lazy(() => import('@nx/import/src/upx-entry/upx-view'));
const QueryApp = React.lazy(() => import('@nx/query/src/nx-entry/nx-view'));
const DashboardsApp = React.lazy(() => import('@nx/dashboards/src/upx-main'));
const ConversationsApp = React.lazy(() => import('@nx/conversations/src/upx-main'));
const GuidesApp = React.lazy(() => import('@nx/guides/src/upx-entry/upx-main'));
const DebuggerApp = React.lazy(() => import('@nx/debugger/src/nx-entry/nx-main'));

function ConsoleLayout() {
  const homePath = useSubpageHomePath();

  return (
    <SpotlightProvider hasOverlay={false}>
      <UpxLayout header={<ConsoleHeader logoRoute={homePath} />} nav={<ConsoleNav />} footerHint={<CookieHint />}>
        <Outlet />
        <Toaster />
        <ConsoleModals />
        <Notifications />
        <SpotlightWelcomeTour />
      </UpxLayout>
    </SpotlightProvider>
  );
}

function ConsoleBase() {
  const [isDashboardsEnabled] = useFeatureFlag('dashboards:enabled');
  const isConversationsEnabled = getFlag('conversationsAppEnabled', false);
  const toolsConfiguration = useToolsConfiguration();
  const onboardingConfiguration = useOnboardingConfiguration();
  const location = useLocation();
  const { userHomePath } = useUnsafeAppContext();
  useModalPrompts();
  useSearchParamsHandler();
  useConnectionNotifications();

  return (
    <PageBoundary fallback={(props) => <UpxErrorPage {...props} />}>
      <ExploreProvider>
        <QueryProvider>
          <ImportProvider>
            <UpxRouter requireLogin>
              <Route path="/link-accounts" element={<LinkAccountsPage />} />
              {onboardingConfiguration !== undefined && (
                <Route element={<AppContextLoader />}>
                  <Route path={onboardingConfiguration.route} element={<GuardedOnboardingPage />} />
                </Route>
              )}

              <Route element={<ConsoleLayout />}>
                <Route element={<AppContextLoader loadInBackground />}>
                  {toolsConfiguration.import === undefined ? null : (
                    <Route
                      path={`${toolsConfiguration.import.route}/*`}
                      element={
                        <PageBoundary>
                          <ImportApp />
                        </PageBoundary>
                      }
                    />
                  )}

                  {toolsConfiguration.query === undefined ? null : (
                    <Route
                      path={`${toolsConfiguration.query.route}`}
                      element={
                        <PageBoundary>
                          <QueryApp />
                        </PageBoundary>
                      }
                    />
                  )}

                  {toolsConfiguration.explore === undefined ? null : (
                    <Route
                      path={`${toolsConfiguration.explore.route}`}
                      element={
                        <PageBoundary>
                          <ExploreApp />
                        </PageBoundary>
                      }
                    />
                  )}

                  {toolsConfiguration.dashboards === undefined || !isDashboardsEnabled ? null : (
                    <Route
                      path={`${toolsConfiguration.dashboards.route}/*`}
                      element={
                        <PageBoundary>
                          <DashboardsApp />
                        </PageBoundary>
                      }
                    />
                  )}

                  {toolsConfiguration.conversations === undefined || !isConversationsEnabled ? null : (
                    <Route
                      path={`${toolsConfiguration.conversations.route}/*`}
                      element={
                        <PageBoundary>
                          <ConversationsApp />
                        </PageBoundary>
                      }
                    />
                  )}

                  {toolsConfiguration.guides === undefined ? null : (
                    <Route
                      path={`${toolsConfiguration.guides.route}/*`}
                      element={
                        <PageBoundary>
                          <GuidesApp />
                        </PageBoundary>
                      }
                    />
                  )}

                  {toolsConfiguration.debugger === undefined ? null : (
                    <Route
                      path={`${toolsConfiguration.debugger.route}`}
                      element={
                        <PageBoundary>
                          <DebuggerApp />
                        </PageBoundary>
                      }
                    />
                  )}
                </Route>

                <Route path="gcp-marketplace-registration" element={<N4GCPMarketplaceRegistration />} />
                <Route path="aws-marketplace-registration" element={<AWSMarketplaceRegistration />} />
                <Route path="azure-marketplace-registration" element={<AzureMarketplaceRegistration />} />

                <Route path="local/*">
                  <Route path="instances" element={<LocalInstancePage />} />
                </Route>

                <Route path="org/:orgId/*" element={<AppContextLoader />}>
                  <Route path="projects" element={<Org.Projects />} />
                  <Route path="users/*" element={<Users.OrgUsersRoutes />} />
                  {/* <Route path="billing/*" element={<Org.BillingRoutes />} /> */}
                  <Route path="settings" element={<Org.Settings />} />
                  <Route path="settings/single-sign-on" element={<Org.SsoConfigs />} />
                  <Route path="*" element={<Navigate to={`projects${location.search}`} replace />} />
                </Route>

                <Route path="account/*" element={<AccountRoutes />} />

                <Route path="projects/:projectId/*" element={<AppContextLoader />}>
                  <Route path="instances" element={<InstancesPage />} />
                  <Route
                    path="instances/:instanceId/migration-readiness-report"
                    element={<Ops.MigrationAssistant upxContext={upxContextForIntegratedApps} />}
                  />
                  <Route path="metrics" element={<Ops.Metrics upxContext={upxContextForIntegratedApps} />} />
                  <Route path="logs" element={<Ops.Logs upxContext={upxContextForIntegratedApps} />} />
                  <Route path="data-api" element={<DataApi.Management upxContext={upxContextForIntegratedApps} />} />
                  <Route path="users/*" element={<Users.ProjectUsersRoutes />} />
                  <Route path="roles/*" element={<Project.RolesRoutes />} />
                  <Route path="settings" element={<Project.Settings />} />
                  <Route path="sessions/*" element={<Project.GuardedSessionsLayout />} />
                  <Route path="billing/*" element={<Project.BillingRoutes />} />
                  <Route path="settings/cmek" element={<Project.Cmek />} />
                  <Route path="settings/network" element={<Project.Network />} />
                  <Route path="settings/log-forwarding" element={<Ops.LogForwardingPageGuard />} />
                  <Route
                    path="settings/metrics-integration"
                    element={<Ops.MetricsIntegration upxContext={upxContextForIntegratedApps} />}
                  />
                  <Route path="settings/custom-endpoints" element={<CustomEndpointsGuard />} />

                  <Route path="*" element={<Navigate to={`instances${location.search}`} replace />} />
                </Route>

                <Route element={<AppContextLoader />}>
                  <Route path="*" element={<Navigate to={userHomePath + location.search} replace />} />
                </Route>
              </Route>
            </UpxRouter>
          </ImportProvider>
        </QueryProvider>
      </ExploreProvider>
    </PageBoundary>
  );
}

export function Console() {
  const { appManifest } = useAppManifest();
  const { launchDarkly } = useServicesConfiguration();
  const launchDarklyContext = useLaunchDarklyIdentityContext();

  if (launchDarkly?.clientSideId !== undefined) {
    return (
      <LDProvider
        clientSideID={launchDarkly.clientSideId}
        options={{ application: { id: appManifest.name, version: appManifest.version } }}
        context={launchDarklyContext}
        deferInitialization
      >
        <ConsoleBase />
      </LDProvider>
    );
  }

  return <ConsoleBase />;
}
