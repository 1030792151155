import type semver from 'semver';

import { gte } from './neo4j-version-util';

export const isHttpQueryApiAvailable = (version: string | semver.SemVer) => {
  try {
    return gte(version, '5.26.0');
  } catch (e) {
    return false;
  }
};
