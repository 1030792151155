import { Label, TextLink, Typography } from '@neo4j-ndl/react';
import { TIER } from '@nx/state';
import type { Project } from '@nx/state';

import { tierDisplayName } from '../entities/helpers';
import {
  Divider,
  SelectInstanceCard,
  SelectInstanceCardButton,
  SelectInstanceCardDescription,
  SelectInstanceCardHeader,
  SelectInstanceCardItem,
  SelectInstanceCardItems,
  SelectInstanceCardTitle,
} from './tier-selector-components';

type EnterpriseTierSelectorProps = {
  project: Project;
  onSelect: () => void;
};

export const MTETierSelector = ({ project, onSelect }: EnterpriseTierSelectorProps) => {
  const mteDisplayName = tierDisplayName(project, TIER.MTE);

  return (
    <SelectInstanceCard className="bg-neutral-bg-weak">
      <div className="mb-4 flex min-h-56 max-w-full flex-col">
        <SelectInstanceCardTitle className="text-discovery-text">{mteDisplayName}</SelectInstanceCardTitle>
        <SelectInstanceCardHeader>
          <div className="flex items-baseline gap-2">
            <Typography variant="h2">$146</Typography>
            <Typography variant="body-medium">/GB/month</Typography>
          </div>
          <div>
            <Typography variant="body-small">Minimum 2GB instance</Typography>
          </div>
        </SelectInstanceCardHeader>
        <SelectInstanceCardDescription className="text-neutral-text-weak">
          Scale mission-critical apps with a highly available database, granular enterprise controls, and 24x7 support
        </SelectInstanceCardDescription>
        <Typography variant="body-medium" className="flex-grow content-end" as="div">
          <TextLink href="https://neo4j.com/contact-us/?about_me=aura-console-vdc-contact-sales" isExternalLink>
            Have credits? Contact us
          </TextLink>
        </Typography>
      </div>
      <Divider className="border-neutral-border-weak" />
      <SelectInstanceCardItems cardType="secondary" className="mb-8 mt-9">
        <SelectInstanceCardItem>Up to 512GB memory per database instance</SelectInstanceCardItem>
        <SelectInstanceCardItem>Highly available 3-zone cluster with 99.95% uptime SLA</SelectInstanceCardItem>
        <SelectInstanceCardItem>
          Daily backups with 30-day retention and hourly point-in-time restore
        </SelectInstanceCardItem>
        <SelectInstanceCardItem>Role-based access control with granular security</SelectInstanceCardItem>
        <SelectInstanceCardItem>Pay-as-you-go and prepaid consumption billing</SelectInstanceCardItem>
        <SelectInstanceCardItem>
          <TextLink className="no-underline" href="http://www.neo4j.com/support-terms/" isExternalLink>
            24x7 support
          </TextLink>
        </SelectInstanceCardItem>
      </SelectInstanceCardItems>
      <SelectInstanceCardButton color="primary" onClick={onSelect} dataTestId="tier-select-mte">
        Select
      </SelectInstanceCardButton>
    </SelectInstanceCard>
  );
};

type EnterpriseTierInfoProps = {
  project: Project;
  isSummary?: boolean;
};

export const EnterpriseTierInfo = ({ project, isSummary = false }: EnterpriseTierInfoProps) => {
  const enterpriseDisplayName = tierDisplayName(project, TIER.ENTERPRISE);

  if (isSummary) {
    return (
      <SelectInstanceCard className="!basis-[80px] py-[30px]">
        <Typography variant="subheading-large" as="div" className="text-discovery-text">
          <div className="flex flex-row items-center gap-10">
            {enterpriseDisplayName}
            <Typography variant="body-medium" className="flex grow flex-row items-baseline justify-between" as="div">
              <div className="text-neutral-text-weak">
                Deploy applications, using all capabilities of AuraDB Business Critical, but in a dedicated environment
              </div>
              <TextLink href="https://neo4j.com/pricing/" isExternalLink>
                Read more
              </TextLink>
            </Typography>
          </div>
        </Typography>
      </SelectInstanceCard>
    );
  }

  return (
    <SelectInstanceCard className="bg-neutral-bg-weak">
      <div className="mb-4 min-h-56 max-w-full">
        <SelectInstanceCardTitle className="text-discovery-text">{enterpriseDisplayName}</SelectInstanceCardTitle>
        <SelectInstanceCardHeader>
          <Typography variant="h2">Let&apos;s chat!</Typography>
        </SelectInstanceCardHeader>
        <SelectInstanceCardDescription className="text-neutral-text-weak">
          Deploy applications, using all capabilities of AuraDB Business Critical, but in a dedicated environment
        </SelectInstanceCardDescription>
      </div>
      <Divider className="border-neutral-border-weak" />
      <SelectInstanceCardItems cardType="secondary" className="mb-8 mt-9">
        <SelectInstanceCardItem>VPC isolation and dedicated infrastructure</SelectInstanceCardItem>
        <SelectInstanceCardItem>Up to 512GB memory per database instance</SelectInstanceCardItem>
        <SelectInstanceCardItem>Hourly backups with 60-day retention</SelectInstanceCardItem>
        <SelectInstanceCardItem>
          <TextLink
            href="https://neo4j.com/terms/support-terms/"
            htmlAttributes={{
              target: '_blank',
            }}
          >
            Enhanced
          </TextLink>{' '}
          24x7 support and services
        </SelectInstanceCardItem>
      </SelectInstanceCardItems>
      <SelectInstanceCardButton color="primary" href="https://neo4j.com/contact-us/" target="_blank">
        Contact Sales
      </SelectInstanceCardButton>
    </SelectInstanceCard>
  );
};

type FreeTierSelectorProps = {
  project: Project;
  onSelect?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  disabledReason: string;
};

export const FreeTierSelector = ({
  project,
  onSelect,
  isLoading,
  isDisabled = false,
  disabledReason,
}: FreeTierSelectorProps) => {
  const freeDisplayName = tierDisplayName(project, TIER.FREE);

  return (
    <SelectInstanceCard className="bg-neutral-bg-weak">
      <div className="mb-4 flex min-h-56 max-w-full flex-col">
        <SelectInstanceCardTitle className="text-success-text">{freeDisplayName}</SelectInstanceCardTitle>
        <SelectInstanceCardHeader>
          <div className="flex items-baseline">
            <Typography variant="h2">$0</Typography>
          </div>
        </SelectInstanceCardHeader>
        <SelectInstanceCardDescription className="text-neutral-text-weak">
          Start with a free offer to learn and explore with graph data
        </SelectInstanceCardDescription>
        <Typography
          variant="body-medium"
          as="div"
          className="text-neutral-text-weaker flex-grow content-end tracking-normal"
        >
          Auto-deleted after 30 days of inactivity
        </Typography>
      </div>
      <Divider className="border-neutral-border-weak" />
      <SelectInstanceCardItems cardType="secondary" className="mb-8 mt-9">
        <SelectInstanceCardItem>No credit card or other payment method required</SelectInstanceCardItem>
        <SelectInstanceCardItem>Access to all graph tools</SelectInstanceCardItem>
      </SelectInstanceCardItems>
      <div className="grow" />
      <SelectInstanceCardButton
        color="neutral"
        onClick={onSelect}
        isDisabled={isDisabled}
        isLoading={isLoading}
        tipContent={disabledReason}
        dataTestId="tier-select-free"
      >
        {isDisabled ? 'Not available' : 'Select'}
      </SelectInstanceCardButton>
    </SelectInstanceCard>
  );
};

type ProfessionalTierSelectorProps = {
  project: Project;
  proTrialAvailable?: boolean;
  onSelect: () => void;
  isDisabled: boolean;
  disabledReason: string;
};

const getProSelectButtonText = (isDisabled: boolean, proTrialAvailable: boolean): string => {
  if (isDisabled) {
    return 'Not available';
  }
  if (proTrialAvailable) {
    return 'Try for free';
  }
  return 'Select';
};

export const ProfessionalTierSelector = ({
  project,
  proTrialAvailable = false,
  isDisabled,
  disabledReason,
  onSelect,
}: ProfessionalTierSelectorProps) => {
  const proDisplayName = tierDisplayName(project, TIER.PROFESSIONAL);

  return (
    <SelectInstanceCard className="bg-primary-bg-strong border-none">
      <div className="mb-4 flex min-h-56 max-w-full flex-col">
        <SelectInstanceCardTitle className="text-neutral-text-inverse">{proDisplayName}</SelectInstanceCardTitle>
        <SelectInstanceCardHeader className="text-neutral-text-inverse">
          <div className="flex items-baseline gap-2">
            <Typography variant="h2">$65</Typography>
            <Typography variant="body-medium">/GB/month</Typography>
          </div>
          <div>
            <Typography variant="body-small">Minimum 1GB instance</Typography>
          </div>
        </SelectInstanceCardHeader>
        <SelectInstanceCardDescription className="text-neutral-text-inverse">
          Build high-performance applications on a production-ready cloud database
        </SelectInstanceCardDescription>
        {proTrialAvailable && (
          <div className="flex-grow content-end">
            <Label fill="semi-filled">Free Trial - No Credit Card Required</Label>
          </div>
        )}
      </div>
      <Divider className="border-primary-focus" />
      <SelectInstanceCardItems cardType="primary" className="mb-8 mt-9">
        <SelectInstanceCardItem>Up to 128GB memory per database instance</SelectInstanceCardItem>
        <SelectInstanceCardItem>Scalable on demand</SelectInstanceCardItem>
        <SelectInstanceCardItem>Daily backups, 30-day retention</SelectInstanceCardItem>
        <SelectInstanceCardItem>Available on Azure, AWS, and Google Cloud</SelectInstanceCardItem>
        <SelectInstanceCardItem>Advanced instance-level metrics</SelectInstanceCardItem>
      </SelectInstanceCardItems>
      <SelectInstanceCardButton
        color="primary"
        onClick={onSelect}
        isDisabled={isDisabled}
        tipContent={disabledReason}
        dataTestId="tier-select-pro"
      >
        {getProSelectButtonText(isDisabled, proTrialAvailable)}
      </SelectInstanceCardButton>
    </SelectInstanceCard>
  );
};

export const GdsTierSelector = ({ project, isDisabled, disabledReason, onSelect }: ProfessionalTierSelectorProps) => {
  const gdsDisplayName = tierDisplayName(project, TIER.GDS);

  return (
    <SelectInstanceCard className="bg-primary-bg-strong basis-[unset] border-none">
      <div className="mb-4 flex min-h-56 max-w-full flex-col">
        <SelectInstanceCardTitle className="text-neutral-text-inverse">{gdsDisplayName}</SelectInstanceCardTitle>
        <SelectInstanceCardHeader className="text-neutral-text-inverse">
          <div className="flex items-baseline gap-2">
            <Typography variant="h2">$91</Typography>
            <Typography variant="body-medium">/GB/month</Typography>
          </div>
          <div>
            <Typography variant="body-small">Minimum 8GB instance</Typography>
          </div>
        </SelectInstanceCardHeader>
        <SelectInstanceCardDescription className="text-neutral-text-inverse">
          Fastest way to take your graph data science experiments to production with monthly billing.{' '}
        </SelectInstanceCardDescription>
      </div>
      <Divider className="border-primary-focus" />
      <SelectInstanceCardItems cardType="primary" className="mb-8 mt-9">
        <SelectInstanceCardItem>Up to 96GB memory per database instance</SelectInstanceCardItem>
        <SelectInstanceCardItem>Scalable on demand</SelectInstanceCardItem>
        <SelectInstanceCardItem>Daily backups, 30-day retention</SelectInstanceCardItem>
        <SelectInstanceCardItem>Available on AWS, and Google Cloud</SelectInstanceCardItem>
        <SelectInstanceCardItem>Advanced instance-level metrics</SelectInstanceCardItem>
      </SelectInstanceCardItems>
      <SelectInstanceCardButton
        color="primary"
        onClick={onSelect}
        isDisabled={isDisabled}
        tipContent={disabledReason}
        dataTestId="tier-select-gds"
      >
        Select
      </SelectInstanceCardButton>
    </SelectInstanceCard>
  );
};

type DseTierInfoProps = {
  project: Project;
};

export const DseTierInfo = ({ project }: DseTierInfoProps) => {
  const dseDisplayName = tierDisplayName(project, TIER.AURA_DSE);

  return (
    <SelectInstanceCard className="bg-neutral-bg-weak basis-[unset]">
      <div className="mb-4 min-h-56 max-w-full">
        <SelectInstanceCardTitle className="text-discovery-text">{dseDisplayName}</SelectInstanceCardTitle>
        <SelectInstanceCardHeader>
          <Typography variant="h2">Let&apos;s chat!</Typography>
        </SelectInstanceCardHeader>
        <SelectInstanceCardDescription className="text-neutral-text-weak">
          AuraDS Enterprise offers additional scale, security, premium support, and negotiable contracts.{' '}
        </SelectInstanceCardDescription>
      </div>
      <Divider className="border-neutral-border-weak" />
      <SelectInstanceCardItems cardType="secondary" className="mb-8 mt-9">
        <SelectInstanceCardItem>VPC isolation and dedicated infrastructure</SelectInstanceCardItem>
        <SelectInstanceCardItem>Up to 384GB memory per database instance</SelectInstanceCardItem>
        <SelectInstanceCardItem>Hourly backups with 60-day retention</SelectInstanceCardItem>
        <SelectInstanceCardItem>
          <TextLink
            href="https://neo4j.com/terms/support-terms/"
            htmlAttributes={{
              target: '_blank',
            }}
          >
            Enhanced
          </TextLink>{' '}
          24x7 support and services
        </SelectInstanceCardItem>
      </SelectInstanceCardItems>
      <SelectInstanceCardButton color="primary" href="https://neo4j.com/contact-us/" target="_blank">
        Contact Sales
      </SelectInstanceCardButton>
    </SelectInstanceCard>
  );
};
