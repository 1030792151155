import { tokens } from '@neo4j-ndl/base';
import { useMediaQuery } from '@neo4j-ndl/react';
import { useAppSelector, useFeatureFlag, useMainSidebarActions } from '@nx/state';
import React, { type MouseEvent, type MouseEventHandler, useMemo } from 'react';

import { LeftNavMenuButton } from '../nav';
import { LogoButton } from './logo-button';

interface Props {
  logoRoute: string;
  logoImg?: React.ReactNode;
  left?: React.ReactNode;
  center?: React.ReactNode;
  right?: React.ReactNode;
}

function makeSecretClickHandler<T extends Element>(callback: () => void): MouseEventHandler<T> {
  let timeoutId: number;
  let secretClicks = 0;

  return function handleClick(event: MouseEvent<T>) {
    window.clearTimeout(timeoutId);

    if (event.altKey) {
      event.stopPropagation();
      event.preventDefault();

      secretClicks += 1;

      if (secretClicks < 2) {
        timeoutId = window.setTimeout(() => {
          secretClicks = 0;
        }, 1000);
      } else {
        secretClicks = 0;
        callback();
      }
    }
  };
}

export const Header = ({ logoRoute, logoImg, left, center, right }: Props) => {
  const showMainSidebar = useAppSelector((store) => store.sidebar.mainSidebar.isOpen);

  const { toggleMainSidebar } = useMainSidebarActions();
  const { breakpoints } = tokens;
  const isTabletScreen = useMediaQuery(`(min-width:${breakpoints.md})`);

  // Throw in the example tab in dev mode
  const [, toggleDebuggerEnabled] = useFeatureFlag('nx:debugger', import.meta.env.DEV);

  const handleSecretClick = useMemo(() => makeSecretClickHandler(toggleDebuggerEnabled), [toggleDebuggerEnabled]);

  return (
    <header
      {...(showMainSidebar &&
        !isTabletScreen && {
          onClick: () => toggleMainSidebar(),
        })}
    >
      <nav
        /*
        Drop down menu of the user button is shifted to the left if there is not enough space on the edge.
        appears to be a bug in the needle Menu component.
        Putting some padding with pr-4 on the right to push button away from the edge of the viewport
      */
        className="bg-palette-neutral-bg-weak flex h-12 items-center justify-between gap-2 px-4"
        role="navigation"
        data-testid="navigation"
        aria-label="main navigation"
      >
        <div className="flex min-w-80 items-center gap-3 [&>*]:shrink-0">
          <LeftNavMenuButton />
          <LogoButton href={logoRoute} img={logoImg} onClick={handleSecretClick} />
          {left}
        </div>
        {center}
        <div className="flex items-center gap-2">{right}</div>
      </nav>
    </header>
  );
};
