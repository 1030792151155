/* eslint-disable @typescript-eslint/ban-types */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-duplicate-type-constituents */

/* eslint-disable @typescript-eslint/consistent-indexed-object-style */
import { baseApi as api } from '@query/services/api/base-api';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSavedCyphers: build.query<GetSavedCyphersApiResponse, GetSavedCyphersApiArg>({
      query: () => ({ url: `/unstable/query-saved-cyphers` }),
    }),
    updateSavedCypher: build.mutation<UpdateSavedCypherApiResponse, UpdateSavedCypherApiArg>({
      query: (queryArg) => ({
        url: `/unstable/query-saved-cyphers`,
        method: 'PUT',
        body: queryArg.updateQuerySavedCypherBody,
      }),
    }),
    deleteSavedCyphers: build.mutation<DeleteSavedCyphersApiResponse, DeleteSavedCyphersApiArg>({
      query: (queryArg) => ({
        url: `/unstable/query-saved-cyphers`,
        method: 'DELETE',
        body: queryArg.deleteSavedCyphersBody,
      }),
    }),
    createSavedCypherItem: build.mutation<CreateSavedCypherItemApiResponse, CreateSavedCypherItemApiArg>({
      query: (queryArg) => ({
        url: `/unstable/query-saved-cyphers/items`,
        method: 'POST',
        body: queryArg.createQuerySavedCypherItemBody,
      }),
    }),
    createSavedCypherFolder: build.mutation<CreateSavedCypherFolderApiResponse, CreateSavedCypherFolderApiArg>({
      query: (queryArg) => ({
        url: `/unstable/query-saved-cyphers/folders`,
        method: 'POST',
        body: queryArg.createSavedCypherFolderBody,
      }),
    }),
    patchSavedCypherItemById: build.mutation<PatchSavedCypherItemByIdApiResponse, PatchSavedCypherItemByIdApiArg>({
      query: (queryArg) => ({
        url: `/unstable/query-saved-cyphers/items/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateQuerySavedCypherItemBody,
      }),
    }),
    patchSavedCypherFolderById: build.mutation<PatchSavedCypherFolderByIdApiResponse, PatchSavedCypherFolderByIdApiArg>(
      {
        query: (queryArg) => ({
          url: `/unstable/query-saved-cyphers/folders/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updateSavedCypherFolderBody,
        }),
      },
    ),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as generatedSavedCypherApi };
export type GetSavedCyphersApiResponse = /** status 200 OK */ (QuerySavedCypherFolder | QuerySavedCypherItem)[];
export type GetSavedCyphersApiArg = void;
export type UpdateSavedCypherApiResponse = /** status 200 OK */ void;
export type UpdateSavedCypherApiArg = {
  updateQuerySavedCypherBody: UpdateQuerySavedCypherBody;
};
export type DeleteSavedCyphersApiResponse = /** status 200 OK */ null | null;
export type DeleteSavedCyphersApiArg = {
  deleteSavedCyphersBody: DeleteSavedCyphersBody;
};
export type CreateSavedCypherItemApiResponse = /** status 201 Created */ QuerySavedCypherItem;
export type CreateSavedCypherItemApiArg = {
  createQuerySavedCypherItemBody: CreateQuerySavedCypherItemBody;
};
export type CreateSavedCypherFolderApiResponse = /** status 201 Created */ QuerySavedCypherFolder;
export type CreateSavedCypherFolderApiArg = {
  createSavedCypherFolderBody: CreateSavedCypherFolderBody;
};
export type PatchSavedCypherItemByIdApiResponse = /** status 200 OK */ UpdateSavedCypherItemResult;
export type PatchSavedCypherItemByIdApiArg = {
  id: string;
  updateQuerySavedCypherItemBody: UpdateQuerySavedCypherItemBody;
};
export type PatchSavedCypherFolderByIdApiResponse = /** status 200 OK */ UpdateSavedCypherFolderResult;
export type PatchSavedCypherFolderByIdApiArg = {
  id: string;
  updateSavedCypherFolderBody: UpdateSavedCypherFolderBody;
};
export type QuerySavedCypherFolder = {
  order: number;
  parent: {
    id: string;
  } | null;
  name: string;
  id: string;
};
export type QuerySavedCypherItem = {
  order: number;
  parent: {
    id: string;
  } | null;
  query: string;
  name: string;
  id: string;
};
export type Location = 'body' | 'cookies' | 'headers' | 'params' | 'query';
export type FieldValidationError = {
  /** The error message */
  msg: any;
  /** The value of the field. It might be unset if the value is hidden. */
  value?: any;
  /** The path to the field which has a validation error */
  path: string;
  /** The location within the request where this field is */
  location: Location;
  /** Indicates that the error occurred because a field had an invalid value */
  type: 'field';
};
export type AlternativeValidationError = {
  /** The list of underlying validation errors returned by validation chains in `oneOf()` */
  nestedErrors: FieldValidationError[];
  /** The error message */
  msg: any;
  /** Indicates that the error occurred because all alternatives (e.g. in `oneOf()`) were invalid */
  type: 'alternative';
};
export type GroupedAlternativeValidationError = {
  /** The list of underlying validation errors returned by validation chains in `oneOf()` */
  nestedErrors: FieldValidationError[][];
  /** The error message */
  msg: any;
  /** Indicates that the error occurred because all alternatives (e.g. in `oneOf()`) were invalid,
    and the nested errors are grouped per alternative. */
  type: 'alternative_grouped';
};
export type PickFieldInstanceExcludeKeyofFieldInstanceOriginalPathOrPathValues = {
  path: string;
  location: Location;
  value: any;
};
export type OmitFieldInstanceOriginalPathOrPathValues =
  PickFieldInstanceExcludeKeyofFieldInstanceOriginalPathOrPathValues;
export type UnknownFieldsError = {
  /** The list of fields that are unknown */
  fields: OmitFieldInstanceOriginalPathOrPathValues[];
  /** The error message */
  msg: any;
  /** Indicates that the error occurred because one or more fields are unknown in the request */
  type: 'unknown_fields';
};
export type ValidationError =
  | AlternativeValidationError
  | GroupedAlternativeValidationError
  | UnknownFieldsError
  | FieldValidationError;
export type ReadonlyArraySourceLocation = {};
export type ReadonlyArrayStringOrNumber = {};
export type GraphQlFormattedError = {
  /** A short, human-readable summary of the problem that **SHOULD NOT** change
    from occurrence to occurrence of the problem, except for purposes of
    localization. */
  message: string;
  /** If an error can be associated to a particular point in the requested
    GraphQL document, it should contain a list of locations. */
  locations?: ReadonlyArraySourceLocation;
  /** If an error can be associated to a particular field in the GraphQL result,
    it _must_ contain an entry with the key `path` that details the path of
    the response field which experienced the error. This allows clients to
    identify whether a null result is intentional or caused by a runtime error. */
  path?: ReadonlyArrayStringOrNumber;
  /** Reserved for implementors to extend the protocol however they see fit,
    and hence there are no additional restrictions on its contents. */
  extensions?: {
    [key: string]: any;
  };
};
export type UpdateQuerySavedCypherItemBody = {
  order?: number;
  parentId?: string | null;
  query?: string;
  name?: string;
  id: string;
};
export type UpdateSavedCypherFolderBody = {
  order?: number;
  parentId?: string | null;
  name?: string;
  id: string;
};
export type UpdateQuerySavedCypherBody = ((UpdateQuerySavedCypherItemBody | UpdateSavedCypherFolderBody) & {
  isNew?: boolean;
})[];
export type DeleteSavedCyphersBody = {
  ids: string[];
};
export type CreateQuerySavedCypherItemBody = {
  order: number;
  parentId: string | null;
  query: string;
  name: string;
  id: string;
};
export type CreateSavedCypherFolderBody = {
  order: number;
  parentId: string | null;
  name: string;
  id: string;
};
export type UpdateSavedCypherItemResult = {
  querySavedCypherItem?: QuerySavedCypherItem;
};
export type UpdateSavedCypherFolderResult = {
  querySavedCypherFolder?: QuerySavedCypherFolder;
};
