import { DATE_TIME_TYPE } from '../../../../services/temporal/utils.const';
import type { Caption } from '../../../../state/styles/types';
import type { StyleRule } from '../../../../types/style';
import { isFalsy } from '../../../../types/utility';

export const validTypeForUniqueValues = ['number', 'bigint', 'string', 'boolean', ...DATE_TIME_TYPE];
export const hasActiveStyleRule = (rule: StyleRule) =>
  rule.applyColor || !isFalsy(rule.applySize) || !isFalsy(rule.applyCaption);
export const updateCaptions = (
  captions: Caption[],
  caption: Caption,
  key: keyof Caption,
  value: NonNullable<(typeof caption)[typeof key]>,
) => {
  return captions.reduce<Caption[]>((acc, c) => {
    if (c.key === caption.key && c.type === caption.type) {
      acc = [
        ...acc,
        {
          ...c,
          [key]: value,
        },
      ];
    } else {
      acc = [...acc, c];
    }
    return acc;
  }, []);
};
