import { compact, flatten, isBoolean } from 'lodash-es';

import { log } from '../../../services/logging';
import { getEstimatedRowsForQuery } from '../../../services/search';
import { isVersion5OorGreater } from '../../../services/versions/versionUtils';
import { ElementFilter, Expression } from '../expression';
import { Match } from '../match';
import { readSearchTransaction } from '../readSearchTransaction';
import { transformMetadata } from '../typedGraph/metaTransformer';
import { getOperator } from '../valueTypeUtils';
import { getDictionary as getMetadataDictionary } from './asyncMetadataDictionary';
import { getDictionary as getPropertiesDictionary } from './asyncPropertyKeysDictionary';
import { getDictionary as getValuesDictionary } from './asyncValuesDictionary';
import { getQualifiers } from './qualification';
import QueryPlanResultCache from './queryPlanResultCache';
import Suggestion from './suggestion';
import SuggestionCache from './suggestionCache';
import { describeMatch } from './typeUtils';

export const suggestionVerificationQueryStopLimit = 1000;

class StructuredSearch {
  constructor(
    metadata,
    schema,
    serverVersion,
    caseInsensitive = false,
    dictionaries = null,
    queryRunner = readSearchTransaction,
    enableCypherGeneration = true,
  ) {
    const transformedMetadata = transformMetadata(metadata.perspective, metadata);
    const isV5OrGreater = isVersion5OorGreater(serverVersion);

    if (dictionaries) {
      this.metadataDictionary = dictionaries.getMetadataDictionary(transformedMetadata);
      this.propertiesDictionary = dictionaries.getPropertiesDictionary(this.metadataDictionary);
      this.valuesDictionary = dictionaries.getValuesDictionary(
        caseInsensitive,
        transformedMetadata.indexes,
        metadata.fullTextIndexes,
        null,
        transformedMetadata.categories,
        this.metadataDictionary,
      );
    } else {
      this.metadataDictionary = getMetadataDictionary(transformedMetadata);
      this.valuesDictionary = getValuesDictionary(
        caseInsensitive,
        transformedMetadata.indexes,
        metadata.fullTextIndexes,
        queryRunner,
        transformedMetadata.categories,
        this.metadataDictionary,
        isV5OrGreater,
      );
      this.propertiesDictionary = getPropertiesDictionary(this.metadataDictionary, this.valuesDictionary);
    }

    this.transformedMetadata = transformedMetadata;
    this.suggestions = {};
    this.cache = new SuggestionCache();
    this.queryPlanCache = new QueryPlanResultCache();
    this.visibleLabels = transformedMetadata.labels;
    this.visibleRelationshipTypes = transformedMetadata.relationshipTypes;
    this.lockedEvaluations = [];
    this.lockedText = '';
    this.lockedEvaluationsExpensivePromise = Promise.resolve(false);
    this.enableCypherGeneration = enableCypherGeneration;
    this.caseInsensitive = caseInsensitive;
    this.databaseCallCounter = 0;
    this.metadata = metadata;
    this.schema = schema;
    this.serverVersion = serverVersion;
  }

  reset() {
    this.cache = new SuggestionCache();
    this.queryPlanCache = new QueryPlanResultCache();
  }

  clearLockedEvaluations() {
    this.lockedEvaluations = [];
    this.lockedText = '';
    this.lockedEvaluationsExpensivePromise = Promise.resolve(false);
  }

  lockEvaluationForSuggestion(suggestion) {
    this.clearLockedEvaluations();

    if (!suggestion) return;

    this.lockedText = suggestion.description;

    let offset = 0;

    suggestion.evaluations.forEach((e) => {
      const token = describeMatch(e.match, e.match.name);
      const { length } = token.split(' ');
      e.permutation.token = token;
      e.permutation.offset = offset;
      e.permutation.length = length;

      offset += length;
    });

    this.lockedEvaluations.push(...suggestion.evaluations);

    if (this.lockedEvaluations.length > 0) {
      const query = this.enableCypherGeneration ? this.generateCypherFromSuggestion(suggestion) : '';

      this.databaseCallCounter++;
      this.lockedEvaluationsExpensivePromise = this._isQueryPlanNotExpensive(query)
        .then((isCheap) => !isCheap)
        .catch((err) => {
          log.info('Error when evaluating the complexity of the locked suggestions');
          log.error(err);
        });
    }
  }

  _suggestionIsNew(suggestionsForText, suggestion) {
    return (
      !suggestionsForText || !suggestionsForText.find((existingSuggestion) => existingSuggestion.isEqual(suggestion))
    );
  }

  _shouldSkipEmptySuggestionCheck(suggestion, text, islockedEvaluationsExpensive) {
    const lockedEvaluationsCount = this.lockedEvaluations.length;
    const { description, evaluations } = suggestion;

    if (!text && lockedEvaluationsCount === 0) {
      // skip false positive check on first click in search bar (all suggestions are possible)
      return true;
    } else if (lockedEvaluationsCount > 0) {
      // skip false positive check if the suggestion is the already-locked suggestion (so it shows up right away)
      // if the locked suggestion is already expensive then consider the sub pattern expensive as well and skip the check
      if (description.startsWith(this.lockedText) && islockedEvaluationsExpensive) {
        return true;
      }
    }

    // skip if evaluation is only one item
    if (evaluations.length === 1) {
      return true;
    }

    // skip if evaluations are only two connected items that aren't values
    if (evaluations.length === 2 && evaluations.every((e) => e.type === 'category' || e.type === 'relationship')) {
      return true;
    }

    return false;
  }

  async _isQueryPlanNotExpensive(query) {
    let queryResult = this.queryPlanCache.getCacheEntry(query);
    if (isBoolean(queryResult)) {
      return queryResult;
    }

    const estimatedRowsList = await getEstimatedRowsForQuery(query, suggestionVerificationQueryStopLimit);
    queryResult =
      estimatedRowsList &&
      estimatedRowsList.every((estimatedRows) => estimatedRows < suggestionVerificationQueryStopLimit);

    this.queryPlanCache.setCacheEntry(query, queryResult);
    return queryResult;
  }

  _filterEmptySuggestions(arr, text) {
    const promises = arr.map(async (sugg) => {
      const islockedEvaluationsExpensive = await this.lockedEvaluationsExpensivePromise;

      if (!this._shouldSkipEmptySuggestionCheck(sugg, text, islockedEvaluationsExpensive)) {
        // Workaround for regression with Query Plans in Cypher 4.3 (Aura): Calculate the plan without the LIMIT
        const queryForPlanner = this.enableCypherGeneration ? this.generateCypherFromSuggestion(sugg) : '';
        const query = `${queryForPlanner} LIMIT 1`;

        this.databaseCallCounter++;
        const isNotExpensive = await this._isQueryPlanNotExpensive(queryForPlanner);

        if (isNotExpensive) {
          const results = await readSearchTransaction(query);
          const hasResults = results.records && results.records.length > 0;
          this.databaseCallCounter++;
          return hasResults ? sugg : undefined;
        }
      }
      return sugg;
    });
    return Promise.all(promises);
  }

  getSuggestions(text) {
    this.databaseCallCounter = 0;
    const localSuggestions = [];
    const lockedText = this.lockedEvaluations.map((e) => e.permutation.token).join(' ');
    if (lockedText && text && lockedText.length && text.length) {
      if (!text.startsWith(lockedText)) {
        this.clearLockedEvaluations();
      } else if (lockedText === text) {
        text += ' '; // trigger metadata fetching
      }
    }

    const qualifiers = getQualifiers(
      text,
      this.transformedMetadata,
      this.metadataDictionary,
      this.valuesDictionary,
      this.propertiesDictionary,
      this.cache,
      this.lockedEvaluations,
    );

    const lockedSuggestion =
      this.lockedEvaluations.length && text.trim() === this.lockedText.trim()
        ? Suggestion(text.trim(), this.lockedEvaluations)
        : null;

    const lockedSuggestionPromises = lockedSuggestion ? [Promise.resolve({ suggestions: [lockedSuggestion] })] : [];

    const allPromises = lockedSuggestionPromises.concat(qualifiers.promises).map((promise) =>
      promise
        .then((suggestionData) => {
          const newSuggestions = [];
          const { suggestions } = suggestionData;

          suggestions.forEach((suggestion) => {
            if (this._suggestionIsNew(localSuggestions, suggestion)) {
              newSuggestions.push(suggestion);
              localSuggestions.push(suggestion);
            }
          });
          if (newSuggestions.length > 0) {
            return this._filterEmptySuggestions(newSuggestions, text);
          }
          return Promise.resolve([]);
        })
        .catch((err) => {
          if (!err.ignore) {
            log.info('ERROR', err);
          }
        }),
    );

    const promise = Promise.all(allPromises).then(flatten).then(compact);

    return {
      requestIds: new Set(qualifiers.requestIds.filter((rIds) => !!rIds)),
      suggestionPromises: [promise],
    };
  }

  generateCypherFromSuggestion(suggestion) {
    return this._suggestionToCypher(suggestion);
  }

  _suggestionToCypher(suggestion) {
    if (!suggestion || !suggestion.evaluations) {
      return null;
    }

    const match = new Match({
      visibleLabels: this.visibleLabels,
      visibleRelationshipTypes: this.visibleRelationshipTypes,
      serverVersion: this.serverVersion,
    });

    suggestion.evaluations.forEach((qualifier) => {
      const filterExpression = new Expression();
      filterExpression.serverVersion = this.serverVersion;

      const filter = new ElementFilter();
      switch (qualifier.type) {
        case 'category':
          match.addMultiLabel(qualifier.match.labels);
          break;
        case 'label':
          match.addLabel(qualifier.match.name);
          break;
        case 'relationship':
          match.addRelationship(qualifier.match.name);
          break;
        case 'property-key':
          if (qualifier.match.category) {
            match.addMultiLabel(qualifier.match.labels);
          } else {
            match.addLabel(qualifier.match.label);
          }
          // Filter the nodes that matched the full text query in case of case insensitive matching
          if (qualifier.match.nodeIds) {
            const idExpression = new Expression();
            idExpression.serverVersion = this.serverVersion;
            idExpression.nodeIds = qualifier.match.nodeIds;
            filter.addExpression(idExpression);
          }
          filterExpression.propertyKey = qualifier.match.propertyKey;
          filterExpression.dataType = qualifier.match.dataType;
          filterExpression.caseInsensitive = this.caseInsensitive;
          filter.addExpression(filterExpression);
          match.addFilter(filter);
          break;
        case 'property-value':
          if (qualifier.match.category) {
            match.addMultiLabel(qualifier.match.labels);
          } else {
            match.addLabel(qualifier.match.label);
          }
          // Filter the nodes that matched the full text query in case of case insensitive matching
          if (qualifier.match.nodeIds) {
            const idExpression = new Expression();
            idExpression.serverVersion = this.serverVersion;
            idExpression.nodeIds = qualifier.match.nodeIds;
            filter.addExpression(idExpression);
          }
          filterExpression.propertyKey = qualifier.match.propertyKey;
          filterExpression.operator = getOperator(qualifier.match.dataType);
          filterExpression.value = qualifier.match.value;
          filterExpression.dataType = qualifier.match.dataType;
          filterExpression.caseInsensitive = this.caseInsensitive;
          filter.addExpression(filterExpression);
          match.addFilter(filter);
          break;
        case 'value':
        default:
          match.addLabel(qualifier.match.label);
          // Filter the nodes that matched the full text query in case of case insensitive matching
          if (qualifier.match.nodeIds) {
            const idExpression = new Expression();
            idExpression.serverVersion = this.serverVersion;
            idExpression.nodeIds = qualifier.match.nodeIds;
            filter.addExpression(idExpression);
          }
          filterExpression.propertyKey = qualifier.match.propertyKey;
          filterExpression.operator = '=';
          filterExpression.value = qualifier.match.name;
          filterExpression.dataType = qualifier.match.dataType;
          filter.addExpression(filterExpression);
          match.addFilter(filter);
          break;
      }
    });

    return match.toString(this.schema);
  }
}

export default StructuredSearch;
