import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v1 as uuidv1 } from 'uuid';

import { APPLIED, APPLY_TYPE } from '../../modules/Filter/shared';
import { CLEAR_SCENE } from '../rootActions';
import type { RootState } from '../types';
import type { FilterRule, FilterState, RulePayload } from './types';

export const NAME = 'filter';

export const initialState: FilterState = {
  filterRules: [],
};

/**
 * Selectors
 */
export const getFilterRules = createSelector(
  (state: RootState) => state[NAME].filterRules,
  (filterRules) => filterRules ?? [],
);

export const getFilterRuleById = createSelector(
  (state: RootState) => getFilterRules(state),
  (filterRules) => (filterRuleId: FilterRule['id']) => {
    return filterRules.find(({ id }) => id === filterRuleId) ?? {};
  },
);

export const isAnyFilterRuleActive = createSelector(
  (state: RootState) => getFilterRules(state),
  (filterRules) => filterRules.some((rule) => rule[APPLIED] || rule[APPLY_TYPE]),
);

/**
 * Reducer and helpers
 */

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    resetFilters: (state) => {
      state.filterRules = initialState.filterRules;
    },
    addFilterRule: (state, action: PayloadAction<RulePayload>) => {
      state.filterRules.unshift({
        id: uuidv1(),
        ...action.payload,
      });
    },
    updateFilterRule: (
      state,
      action: PayloadAction<{ filterRuleId: FilterRule['id']; filterRuleUpdate: Partial<RulePayload> }>,
    ) => {
      const { filterRuleId, filterRuleUpdate = {} } = action.payload ?? {};
      state.filterRules = state.filterRules.map((rule) =>
        rule.id === filterRuleId ? { ...rule, ...filterRuleUpdate } : rule,
      );
    },
    deleteFilterRule: (state, action: PayloadAction<FilterRule['id']>) => {
      state.filterRules = state.filterRules.filter(({ id }) => id !== action.payload);
    },
    setFilterRules: (state, action: PayloadAction<FilterRule[]>) => {
      state.filterRules = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(CLEAR_SCENE, (state) => {
      state.filterRules = initialState.filterRules;
    });
  },
});

export const { resetFilters, addFilterRule, updateFilterRule, deleteFilterRule, setFilterRules } = slice.actions;
export default slice.reducer;
