import {
  consentToTracking,
  LEGACY_setDesktopContext as setDesktopContext,
  LEGACY_setRelateTokens as setRelateTokens,
  LEGACY_toggleCapability as toggleCapability,
} from '@nx/state';
import { isNullish } from '@nx/stdlib';

export function setupNtId() {
  const { searchParams } = new URL(window.location.href);
  const id = searchParams.get('ntid');
  if (!isNullish(id)) {
    consentToTracking(id);
  }
}

export function setupRelateTokens() {
  const { searchParams } = new URL(window.location.href);
  const apiToken = searchParams.get('relateApiToken');
  const clientId = searchParams.get('appId');
  const protocol = searchParams.get('relateUrlProtocol');
  const url = searchParams.get('relateUrl');

  if (!isNullish(apiToken) && !isNullish(clientId) && !isNullish(protocol) && !isNullish(url)) {
    setRelateTokens({
      protocol,
      url,
      apiToken,
      clientId,
    });

    toggleCapability({ key: 'framework:relate-api', value: true });
    setDesktopContext(true);
  }
}
