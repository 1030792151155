import { Banner, Button, ClipboardButton, LoadingSpinner, TextLink, Typography } from '@neo4j-ndl/react';
import { CHANNEL, consoleApi, getApiError, getErrorMessage, useActiveOrg } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { BackButton, Center } from '@nx/ui';
// import { KeyPairItem, KeyPairSection } from 'components/application/key-pair';
import { useState } from 'react';

import CreateSsoConfigModal from './create-modal';
import DeleteSsoConfigModal from './delete-modal';
import EditSsoConfigModal from './edit-modal';
import type { FormActionData } from './entities';
import { SSO_CONFIG_FORM_ACTIONS } from './entities';
import SsoConfigsTable from './table';

export const SsoConfigs = () => {
  const org = useActiveOrg();

  const {
    error,
    isError,
    data: ssoConfigs = [],
    isLoading: ssoConfigsIsLoading,
  } = consoleApi.useListSsoConfigsByOrganizationQuery({ organizationId: org.id });
  const [formActionData, setFormActionData] = useState<FormActionData | null>(null);
  const hasFrontChannel = ssoConfigs.some((ssoConfig) => ssoConfig.channel === CHANNEL.FRONT);
  const loginWithOrgLink = `${location.origin}/organization-lookup?org_id=${org.id}&autosubmit=true`;

  if (ssoConfigsIsLoading) {
    return (
      <Center>
        <LoadingSpinner size="medium" />
      </Center>
    );
  }

  if (isError) {
    const err = getApiError(error);
    const message = getErrorMessage(err);
    return (
      <Banner
        type="danger"
        title="API Error"
        description={message}
        isCloseable={false}
        className="m-8"
        usage="inline"
      />
    );
  }

  return (
    <div className="h-full overflow-auto p-4" data-testid="organization-sso-config">
      <BackButton to="../settings" />
      <div className="mb-6 mt-3 flex flex-nowrap items-center justify-between pl-2">
        <div className="flex items-baseline gap-2">
          <Typography variant="h4">Single Sign-Ons</Typography>
          <TextLink
            className="no-underline"
            href="https://neo4j.com/docs/aura/platform/security/single-sign-on/"
            isExternalLink
          >
            Docs
          </TextLink>
        </div>

        <Button
          onClick={() => {
            setFormActionData({ action: SSO_CONFIG_FORM_ACTIONS.CREATE, data: null });
          }}
          htmlAttributes={{ 'data-testid': 'create-new-sso-config' }}
        >
          New configuration
        </Button>
      </div>
      {isNotNullish(org.ssoOrgId) && (
        <div className="bg-neutral-bg-weak border-neutral-border-weak rounded-3xl border p-4">
          <div className="text-neutral-text-weak pr-4 font-thin">Organization SSO login</div>
          <div className="mt-1 flex gap-1">
            <div>{loginWithOrgLink}</div> <ClipboardButton size="small" textToCopy={loginWithOrgLink} />
          </div>
        </div>
      )}
      <div>
        {hasFrontChannel && (
          <Banner type="warning" htmlAttributes={{ 'data-testid': 'estimation-error' }} className="my-2">
            You have sso configurations that are managed by Aura Support, please reach out{' '}
            <TextLink isExternalLink href="https://support.neo4j.com/s/">
              here
            </TextLink>{' '}
            to update them.
          </Banner>
        )}
        <SsoConfigsTable
          ssoConfigs={ssoConfigs}
          onDataChange={setFormActionData}
          isSSOAllowed={org.capabilities.organization_sso}
          org={org}
        />
      </div>

      {formActionData?.action === SSO_CONFIG_FORM_ACTIONS.CREATE && (
        <CreateSsoConfigModal
          onClose={() => {
            setFormActionData(null);
          }}
          saveAndClose={() => {
            setFormActionData(null);
          }}
          organizationId={org.id}
          isSSOAllowed={org.capabilities.organization_sso}
        />
      )}
      {formActionData?.action === SSO_CONFIG_FORM_ACTIONS.DELETE && isNotNullish(formActionData.data) && (
        <DeleteSsoConfigModal
          open={true}
          onClose={() => {
            setFormActionData(null);
          }}
          saveAndClose={() => {
            setFormActionData(null);
          }}
          config={formActionData.data}
        />
      )}
      {formActionData?.action === SSO_CONFIG_FORM_ACTIONS.UPDATE && isNotNullish(formActionData.data) && (
        <EditSsoConfigModal
          onClose={() => {
            setFormActionData(null);
          }}
          saveAndClose={() => {
            setFormActionData(null);
          }}
          isSSOAllowed={org.capabilities.organization_sso}
          ssoConfig={formActionData.data}
        />
      )}
    </div>
  );
};
