import { Button, Label, Typography, toast } from '@neo4j-ndl/react';
import { GithubIcon } from '@neo4j-ndl/react/icons';
import {
  type LocalInstanceInstalledPlugin,
  getRelateErrors,
  useInstallPluginMutation,
  useUninstallPluginMutation,
} from '@nx/state';
import { useEffect } from 'react';

import type { PluginInfo } from './plugins-info';
import { PLUGINS_INFO } from './plugins-info';

export function Plugin({
  instanceId,
  plugin,
  pluginId,
  loading,
}: {
  instanceId: string;
  plugin?: LocalInstanceInstalledPlugin | PluginInfo;
  pluginId: string;
  loading: boolean;
}) {
  const [installPlugin, install] = useInstallPluginMutation();
  const [uninstallPlugin, uninstall] = useUninstallPluginMutation();

  const isInstalled = plugin !== undefined && 'version' in plugin;
  const pluginInfo = PLUGINS_INFO[pluginId];

  const onInstall = () => {
    void installPlugin({ dbmsIds: [instanceId], pluginName: pluginId });
  };

  const onUninstall = () => {
    void uninstallPlugin({ dbmsIds: [instanceId], pluginName: pluginId });
  };

  useEffect(() => {
    const result = install.isError ? install : uninstall;
    if (result.isError) {
      const errors = getRelateErrors(result.error);
      const toastMessage = (
        <span>
          <Typography variant="subheading-medium">{`Something went wrong while ${result === uninstall ? 'un' : ''}installing the plugin "${pluginInfo?.name}".`}</Typography>
          <br />
          {errors.length > 0 ? `Error: ${errors[0]?.message}` : null}
        </span>
      );
      toast.danger(toastMessage, { isCloseable: true });
    }
  }, [install, uninstall, pluginInfo]);

  const installUninstallButton = isInstalled ? (
    <Button size="small" color="danger" onClick={onUninstall} isLoading={uninstall.isLoading}>
      Uninstall
    </Button>
  ) : (
    <Button size="small" onClick={onInstall} isLoading={install.isLoading}>
      Install
    </Button>
  );

  return (
    <div className="my-5">
      <div className="flex items-baseline justify-between">
        <Typography variant="subheading-medium">{pluginInfo?.name ?? pluginId}</Typography>

        {isInstalled && (
          <div className="flex items-baseline gap-2">
            <Typography variant="body-medium" className="text-palette-neutral-text-weaker">
              {plugin.version.version}
            </Typography>
            <Label color="success" fill="outlined">
              Installed
            </Label>
          </div>
        )}
      </div>

      {pluginInfo && (
        <Typography variant="body-medium" as="div" className="pt-3">
          {pluginInfo.description}
        </Typography>
      )}

      <div className="mt-4 flex gap-2">
        {loading ? (
          <Button
            isLoading
            size="small"
            fill="outlined"
            color="neutral"
            htmlAttributes={{ 'aria-label': 'Loading plugin actions' }}
          >
            {''}
          </Button>
        ) : (
          installUninstallButton
        )}

        {pluginInfo && (
          <Button
            as="a"
            size="small"
            color="neutral"
            fill="outlined"
            href={pluginInfo.links.documentation}
            target="_blank"
          >
            Documentation
          </Button>
        )}

        {pluginInfo && (
          <Button as="a" size="small" color="neutral" fill="outlined" href={pluginInfo.links.github} target="_blank">
            <GithubIcon className="mr-1" /> Github
          </Button>
        )}
      </div>
    </div>
  );
}
