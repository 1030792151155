import { Select } from '@neo4j-ndl/react';
import { type Instance, TIER } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import React from 'react';

type Props = { instances: Instance[]; selectedInstance: Instance | null; setSelectedInstance: (i: Instance) => void };

export const InstanceDropdown = ({ instances, selectedInstance, setSelectedInstance }: Props) => {
  const instanceOptions: { label: string; value: Instance; key: string }[] = instances
    .filter((i) => i.tier === TIER.MTE)
    .map((i) => ({
      label: i.name,
      value: i,
      key: i.id,
    }));

  return (
    <Select
      size="medium"
      label="Instance"
      type="select"
      selectProps={{
        options: instanceOptions,
        value: selectedInstance
          ? { label: selectedInstance.name, value: selectedInstance, key: selectedInstance.id }
          : undefined,
        onChange: (option) => {
          if (isNotNullish(option)) {
            setSelectedInstance(option.value);
          }
        },
        placeholder: 'Select an instance',
        menuPosition: 'fixed',
      }}
      helpText="The configuration will apply to selected instance's logs."
      htmlAttributes={{
        'data-testid': 'log-forwarding-instance-select',
      }}
    />
  );
};
