import type { LocalInstanceInfo } from '@nx/state';
import { LOCAL_INSTANCE_STATUS } from '@nx/state';

export const getOnlineStatusColor = (instance: LocalInstanceInfo) => {
  switch (instance.status) {
    case LOCAL_INSTANCE_STATUS.STARTED:
      return 'success';
    default:
      return 'default';
  }
};

export const dbStatusText = (instance: LocalInstanceInfo) => {
  switch (instance.status) {
    case LOCAL_INSTANCE_STATUS.STARTED:
      return 'Running';
    case LOCAL_INSTANCE_STATUS.STOPPED:
      return 'Stopped';
    default:
      return 'Unknown';
  }
};
