import { Banner, Button, LoadingSpinner, Typography } from '@neo4j-ndl/react';
import type { QueryLogCounts } from '@nx/state';

import { standardFormatAbsoluteNumber, timePeriodFormatter } from '../../../shared/ui-helpers';
import { useLogsContext, useLogsContextIsStaleData } from '../hooks/use-logs-context';

const INITIAL_COUNTS = {
  completedQueries: 0,
  unfinishedQueries: 0,
  failedQueries: 0,
};

type StatusSnackbarProps = {
  counts?: QueryLogCounts | null;
  loading: boolean;
};

export const StatusSnackbar = ({ counts, loading }: StatusSnackbarProps) => {
  const { completedQueries, failedQueries } = counts ?? INITIAL_COUNTS;
  const { setIsRequestDialogOpen, filterInput } = useLogsContext();
  const dateFrom = new Date(filterInput.from);
  const dateTo = new Date(filterInput.to);
  const isStaleData = useLogsContextIsStaleData();

  const RequestButton = (
    <Button
      className="ml-4"
      size="small"
      color="primary"
      fill="outlined"
      onClick={() => setIsRequestDialogOpen(true)}
      htmlAttributes={{
        'data-testid': 'request-log',
      }}
    >
      Fetch logs
    </Button>
  );

  const statusText = (
    <Typography variant="body-medium" as="span" className="flex flex-wrap items-center gap-1">
      From {timePeriodFormatter(dateFrom)} to {timePeriodFormatter(dateTo)}, there are{' '}
      {loading ? <LoadingSpinner /> : standardFormatAbsoluteNumber(completedQueries)} completed and{' '}
      {loading ? <LoadingSpinner /> : standardFormatAbsoluteNumber(failedQueries)} failed queries.
      {isStaleData && (
        <>
          <span className="font-bold">Selection is stale.</span>
          {RequestButton}
        </>
      )}
    </Typography>
  );
  return <Banner title={statusText} usage="inline" name="Selection statuses" />;
};
