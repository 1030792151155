import { type InvalidUrlConnectionError, isInvalidUrlConnectionError } from '@nx/errors';
import type { Instance } from '@nx/state';
import {
  MODAL_TYPE,
  login,
  persistor,
  useConnection,
  useConnectionSelectorModal,
  useFeatureFlag,
  useFetchDiscoveryData,
  useModal,
  useModalClose,
  useUserId,
} from '@nx/state';
import * as StdLib from '@nx/stdlib';

import { CONNECTION_PARAMS } from '../connection-form-utils';
import type { ConnectionFormValues } from '../connection-form.types';

export const useConnect = () => {
  const [remoteConnectionsEnabled] = useFeatureFlag('upx:remote-connections');
  const connection = useConnection();
  const userId = useUserId();
  const connectionSelectorModal = useConnectionSelectorModal();
  const connectInstanceModal = useModal(MODAL_TYPE.CONNECTION_FORM);
  const closeModal = useModalClose();
  const { fetchDiscoveryData } = useFetchDiscoveryData();

  const closeModalAndCleanURL = () => {
    closeModal();
    StdLib.URLs.cleanSearchParamsAndHash([...CONNECTION_PARAMS]);
  };

  const connectSeamless = async (
    url: string,
    name?: string,
    onError?: (e: InvalidUrlConnectionError) => void,
    isAuraInstanceFromAPI = true,
  ) => {
    // The login should be moved to the seamless connection file once we have reworked the connection slice
    if (userId === null) {
      // Seamless is available but the user is not logged in the application.
      connection.provisionSso({
        url,
        name,
        credentials: { type: 'seamless' },
        isAuraInstanceFromAPI,
      });

      // Here we're ensuring that the connection data is saved to the store by calling flush before we redirect
      // to the SSO login page by calling login, otherwise the connection will fail.
      await persistor.flush();

      // The modal is closed here so login will persist the background
      // URL instead of the URL to the connection form, before redirecting the user.
      closeModalAndCleanURL();

      void login();
      return;
    }

    // Seamless is available and the user is logged in.
    connection
      .connect({
        credentials: {
          type: 'seamless',
        },
        name,
        password: '',
        url,
        isAuraInstanceFromAPI,
      })
      .unwrap()
      .then(closeModalAndCleanURL)
      .catch((e: unknown) => {
        if (onError !== undefined && isInvalidUrlConnectionError(e)) {
          onError(e);
        }
      });
  };

  const connectBasicAuth = (
    formValues: ConnectionFormValues,
    onError?: (e: InvalidUrlConnectionError) => void,
    isAuraInstanceFromAPI = false,
  ) => {
    const url = `${formValues.protocol}//${formValues.hostname}`;

    connection
      .connect({
        credentials: {
          type: 'basic',
          username: formValues.username,
        },
        name: formValues.name || undefined,
        password: formValues.password,
        url,
        isAuraInstanceFromAPI,
        dbName: formValues.dbName,
      })
      .unwrap()
      .then(closeModalAndCleanURL)
      .catch((e: unknown) => {
        if (onError !== undefined && isInvalidUrlConnectionError(e)) {
          onError(e);
        }
      });
  };

  // eslint-disable-next-line require-await, @typescript-eslint/require-await
  const doDiscoveryAndConnect = async (instance: Instance, trySeamless = true) => {
    const openConnectWithCredentials = async (connectErrorMessage?: string) => {
      if (remoteConnectionsEnabled) {
        const url = new StdLib.URLs.Neo4jURL(instance.boltUrl);
        const discovery = await fetchDiscoveryData(url.toDiscoveryUrl());
        connectionSelectorModal.open({
          connectionDetails: {
            url: instance.boltUrl,
            instanceName: instance.name,
            credentials: {
              ...(discovery.SSOProviders.length > 0
                ? {
                    type: 'sso',
                    providers: discovery.SSOProviders,
                  }
                : {
                    type: 'basic',
                    username: 'neo4j',
                    password: '',
                  }),
            },
          },
          modalTabId: 'Remote',
        });
      } else {
        const warning =
          trySeamless && !instance.seamlessConnectionEnabled
            ? 'Please use a database user and password to connect.'
            : undefined;
        connectInstanceModal.open({ instance: instance, error: connectErrorMessage, warning });
      }
    };

    if (trySeamless && instance.seamlessConnectionEnabled) {
      void connectSeamless(
        instance.boltUrl,
        instance.name,
        (e: InvalidUrlConnectionError) => {
          void openConnectWithCredentials(`Seamless Connection Error: ${e.error.message}`);
        },
        true,
      );
    } else {
      void openConnectWithCredentials();
    }
  };

  return {
    connectSeamless,
    connectBasicAuth,
    doDiscoveryAndConnect,
  };
};
