export const hasProperties = <T extends object, U extends string | number | symbol>(
  obj: T,
  ...propName: U[]
): obj is T & { [P in U]: unknown } => {
  return propName.every((x) => x in obj);
};

export function mapValues<A, B>(object: Record<string, A>, mapper: (val: A) => B): Record<string, B> {
  return Object.entries(object).reduce((res: Record<string, B>, [currKey, currVal]) => {
    res[currKey] = mapper(currVal);
    return res;
  }, {});
}

export function keys<T extends object>(o: T): (keyof T)[] {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return Object.keys(o) as (keyof T)[];
}

export function isEmpty<T extends object>(o: T): boolean {
  return Object.keys(o).length === 0;
}

export function removeKeys<T extends Record<string, unknown>>(obj: T, keysToRemove: (keyof T)[]): T {
  const copy = { ...obj };
  keysToRemove.forEach((key) => delete copy[key]);
  return copy;
}

// https://stackoverflow.com/questions/69019873/how-can-i-get-typed-object-entries-and-object-fromentries-in-typescript
export const fromEntries = <const T extends readonly (readonly [PropertyKey, unknown])[]>(
  entries: T,
): { [K in T[number] as K[0]]: K[1] } => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return Object.fromEntries(entries) as { [K in T[number] as K[0]]: K[1] };
};
