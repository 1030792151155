import type { InvoiceResponse } from '../console.api.types';
import type { Invoice } from '../console.types';

export const transformInvoiceResponse = (response: InvoiceResponse): Invoice => ({
  number: response.number,
  customerId: response.customer_id,
  created: response.created,
  periodStart: response.period_start,
  periodEnd: response.period_end,
  status: response.status,
  amountDue: response.amount_due,
  dueDate: response.due_date,
  currency: response.currency,
  invoicePdf: response.invoice_pdf,
  hostedInvoiceUrl: response.hosted_invoice_url,
});
