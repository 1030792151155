import { tokens } from '@neo4j-ndl/base';
import { Drawer, IconButton, Typography, useMediaQuery } from '@neo4j-ndl/react';
import { DocumentTextIconOutline } from '@neo4j-ndl/react/icons';
import { opsApi, useActiveProject, useAuraConfiguration, useOpsContext } from '@nx/state';
import { isNonEmptyString } from '@nx/stdlib';
import { PermissionTooltip } from '@nx/ui';
import classNames from 'classnames';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useSelectedInstancePermissions } from '../../shared/hooks/usePermissions';
import { WithUnretrievedStatusIndicator } from './common';
import { DownloadTable } from './download-table';
import { DRAWER_SEARCH_PARAM } from './helpers';

const LOG_EXPIRATION_DAYS_DEV = 1;
const LOG_EXPIRATION_DAYS_PROD = 30;

const useExpirationMessage = () => {
  const isProdLike = ['production', 'staging'].includes(useAuraConfiguration()?.environment ?? '');
  const period = isProdLike ? `${LOG_EXPIRATION_DAYS_PROD} days` : `${LOG_EXPIRATION_DAYS_DEV} day`;
  return `Requested logs will appear here for up to ${period}, at which point they will expire and be removed.`;
};

interface DownloadDrawerProps {
  onClose: () => void;
}

const DownloadDrawer = ({ onClose }: DownloadDrawerProps) => {
  const { breakpoints } = tokens;
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.sm})`);
  const defaultWidth = useMemo(() => (isMobile ? window.innerWidth : 720), [isMobile]);
  const expirationMessage = useExpirationMessage();

  return (
    <Drawer
      isExpanded
      onExpandedChange={onClose}
      position="right"
      // TODO: check if QueryTimelineCard TimePeriodAndRefresh z-10 is needed;
      className="!z-20 !border-l"
      isResizeable
      resizeableProps={{
        defaultSize: { height: '100%', width: defaultWidth },
        minWidth: defaultWidth,
      }}
    >
      <Drawer.Header className="flex items-center gap-2">
        <DocumentTextIconOutline className="size-6" />
        <Typography variant="h4">Downloads archive</Typography>
      </Drawer.Header>
      <Drawer.Body className="flex flex-col">
        <Typography variant="body-medium" className="text-palette-neutral-text-weaker flex-wrap">
          {expirationMessage}
        </Typography>
        <div className="mt-4 flex flex-grow flex-col">
          <DownloadTable />
        </div>
      </Drawer.Body>
    </Drawer>
  );
};

export const DownloadDrawerButton = ({
  className,
  onClick,
  canUseDownloads,
}: {
  className?: string;
  onClick: () => void;
  canUseDownloads: boolean;
}) => {
  const activeProject = useActiveProject();
  const { selectedInstanceId } = useOpsContext();
  const getDownloadsRes = opsApi.useGetLogsDownloadsQuery(
    { tenantId: activeProject.id, dbmsId: selectedInstanceId ?? '' },
    { refetchOnMountOrArgChange: true, skip: !(canUseDownloads && isNonEmptyString(selectedInstanceId)) },
  );

  const totalUnretrieved = useMemo(() => {
    return (
      getDownloadsRes.data?.reduce((acc, download) => acc + (download.metaFileContent.wasRetrieved ? 0 : 1), 0) ?? 0
    );
  }, [getDownloadsRes.data]);

  return (
    <WithUnretrievedStatusIndicator active={totalUnretrieved > 0} className={classNames(className)}>
      <IconButton
        isClean
        size="medium"
        onClick={onClick}
        ariaLabel={`Open downloads archive${totalUnretrieved > 0 ? ` (${totalUnretrieved} new)` : ''}`}
        htmlAttributes={{ title: `Open downloads archive${totalUnretrieved > 0 ? ` (${totalUnretrieved} new)` : ''}` }}
        isDisabled={!canUseDownloads}
      >
        <DocumentTextIconOutline />
      </IconButton>
    </WithUnretrievedStatusIndicator>
  );
};

export const DownloadDrawerButtonWithDrawer = ({ className }: { className?: string }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const showDownloads = searchParams.get(DRAWER_SEARCH_PARAM) === 'true';

  const permissions = useSelectedInstancePermissions({ refetchOnMountOrArgChange: true });
  const canUseDownloads = permissions?.canUseDownloads === true;

  const handleToggleDownloads = () => {
    if (!canUseDownloads) {
      return;
    }

    const params = new URLSearchParams(searchParams);
    const isShowing = params.get(DRAWER_SEARCH_PARAM) === 'true';
    if (isShowing) {
      params.set(DRAWER_SEARCH_PARAM, 'false');
    } else {
      params.set(DRAWER_SEARCH_PARAM, 'true');
    }
    setSearchParams(params);
  };

  const handleClose = () => {
    const params = new URLSearchParams(searchParams);
    params.set(DRAWER_SEARCH_PARAM, 'false');
    setSearchParams(params);
  };

  useEffect(() => {
    if (permissions !== null && !canUseDownloads) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canUseDownloads, permissions]);

  return (
    <>
      <PermissionTooltip hasPermission={canUseDownloads} hasButtonWrapper className={className}>
        <DownloadDrawerButton onClick={handleToggleDownloads} canUseDownloads={canUseDownloads} />
      </PermissionTooltip>
      {canUseDownloads && showDownloads && <DownloadDrawer onClose={handleClose} />}
    </>
  );
};
