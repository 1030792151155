import type { SecurityLogRawFilterSelection } from '@nx/state';
import type { SortingState } from '@tanstack/react-table';

export const DEFAULT_SORT_SUMMARY: SortingState = [
  {
    id: 'logCount',
    desc: true,
  },
];

export const DEFAULT_SORT_DETAILS: SortingState = [
  {
    id: 'status',
    desc: true,
  },
];

export const initialFilters = (): SecurityLogRawFilterSelection => ({
  securityLogFilters: {
    databases: [],
    executingUsers: [],
    authenticatedUsers: [],
    statuses: [],
    drivers: [],
  },
  timeRange: { endTime: new Date() },
  messageSearchString: '',
  message: '',
});
