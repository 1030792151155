import neo4jVersionUtil from '@nx/neo4j-version-utils/src/neo4j-version-util';
import type { JSONSchemaType } from 'ajv';

import type { BaseArguments } from '../types/sdk-types';

type ComponentsResponse = { name: string; versions: [string]; edition: 'enterprise' | 'community' };

export type VersionAndEdition = { version: string; edition: 'enterprise' | 'community' };
/**
 * Procedure documented here
 * https://neo4j.com/docs/operations-manual/current/reference/procedures/
 */
export async function getVersionAndEdition({ queryCypher }: BaseArguments): Promise<VersionAndEdition> {
  const query = 'CALL dbms.components() YIELD versions, edition;';

  const res = await queryCypher(query);

  // Type is verified in integration tests
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const versionAndEdition = res.records[0].toObject() as ComponentsResponse;

  return { version: versionAndEdition.versions[0], edition: versionAndEdition.edition };
}

export const versionAndEditionSchema: JSONSchemaType<VersionAndEdition> = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  properties: {
    edition: {
      enum: ['community', 'enterprise'],
      type: 'string',
    },
    version: {
      type: 'string',
    },
  },
  type: 'object',
  required: ['edition', 'version'],
};

export function versionSupportsCypher25(neo4jVersion: string, cypher25Enabled: boolean): boolean {
  return cypher25Enabled
    ? neo4jVersionUtil.compareLoose(neo4jVersion, '5.26.0') >= 0
    : neo4jVersionUtil.compareLoose(neo4jVersion, '2025.03.0') >= 0;
}
