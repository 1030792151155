import { isNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { Neo4jError } from 'neo4j-driver';

import { Neo4jErrorBanner } from '../../error-banner/error-banner';

const ServiceUnavailableErrorMessage = ({ active }: { active: boolean }) => {
  if (!active) {
    return null;
  }

  return (
    <>
      <h6 className="my-2">Possible causes</h6>
      <ol className="list-decimal pl-6">
        <li>The instance may be paused, offline, or non-existent.</li>
        <li>Network or internet issues, or restricted access on connection port.</li>
        <li>Typo or error in the connection URL.</li>
      </ol>

      <h6 className="my-2">How to fix</h6>
      <ol className="list-decimal pl-6">
        <li>
          If using Aura DB, confirm the instance status is 'Running' on the{' '}
          <a href="https://console.neo4j.io" target="_top">
            Neo4j Aura Console
          </a>
        </li>
        <li>Ensure stable internet connectivity and port access (e.g. port 7687).</li>
        <li>Review the connection URL, particularly the database ID.</li>
      </ol>
    </>
  );
};

const DiscoverUrlsErrorMessage = ({ discoveryUrls }: { discoveryUrls?: string[] }) => {
  if (isNullish(discoveryUrls) || (!isNullish(discoveryUrls) && discoveryUrls.length === 0)) {
    return null;
  }

  return (
    <p className="my-2">{` A lookup also detected ${
      discoveryUrls.length > 1 ? 'alternate URLs' : 'an alternate URL'
    }, try them below.`}</p>
  );
};

const ConnectionError = ({
  show,
  error,
  discoveryUrls,
  onDiscoveryUrlSelected,
}: {
  show: boolean;
  error?: Neo4jError | SerializedError;
  discoveryUrls?: string[];
  onDiscoveryUrlSelected: (url: string) => void;
}) => {
  if (!show || !error) {
    return null;
  }

  const isServiceUnavailableError = error.code === 'ServiceUnavailable';
  const isUnautharizedError = [
    'Neo.ClientError.Security.AuthenticationRateLimit',
    'Neo.ClientError.Security.Unauthorized',
  ].includes(error.code ?? '');

  return (
    <Neo4jErrorBanner
      title="Connection to Instance Failed"
      error={error}
      actions={(discoveryUrls ?? []).map((discoveryUrl) => ({
        label: `Use ${discoveryUrl}`,
        onClick: () => onDiscoveryUrlSelected(discoveryUrl),
      }))}
      id="connection-error"
    >
      {
        <>
          {!isUnautharizedError &&
            !isServiceUnavailableError &&
            'An error occurred while connecting to the instance. See details below.'}
          {isUnautharizedError && error.message}
          <ServiceUnavailableErrorMessage active={isServiceUnavailableError} />
          <DiscoverUrlsErrorMessage discoveryUrls={discoveryUrls} />
        </>
      }
    </Neo4jErrorBanner>
  );
};

export default ConnectionError;
