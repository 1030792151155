import { IconButton, TextInput, TextLink, Typography } from '@neo4j-ndl/react';
import { MagnifyingGlassIconOutline, TrashIconOutline } from '@neo4j-ndl/react/icons';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import {
  consoleApi,
  dataScienceApi,
  getApiError,
  getErrorMessage,
  useActiveProject,
  useNotificationActions,
} from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { DataGridHelpers } from '@nx/ui';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { createColumnHelper } from '@tanstack/react-table';
import { formatDistance } from 'date-fns';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import getStartedWithSessions from '../../assets/get-started-with-sessions.svg';
import { useCommonTable } from '../../components/table';
import { GdsOffering } from '../../utils/gds-offering';
import { getSessionDestroyDate } from './helpers';

const NoDataPlaceholder = () => (
  <div className="flex items-center justify-center gap-6" data-testid="no-data-placeholder">
    <img className="my-12 h-64 w-64" src={getStartedWithSessions} draggable={false} />
    <div className="flex h-64 w-96 flex-col justify-center space-y-2">
      <h4> Get started with sessions </h4>
      <p className="overflow-hidden break-words">
        Sessions are created and used via the Neo4j Graph Data Science Python client. Click below to see the
        documentation on how to get going.
      </p>
      <TextLink href="https://neo4j.com/docs/graph-data-science-client/current/gds-session/" isExternalLink>
        Learn more
      </TextLink>
    </div>
  </div>
);

type Row = {
  sessionName: string;
  sessionId: string;
  instanceName: string;
  memory: string;
  userEmail: string;
  destroyDate: Date;
};

const columnHelper = createColumnHelper<Row>();
const logger = createLogger(APP_SCOPE.aura);

interface Props {
  projectId: string;
}

export const SessionsLayout = ({ projectId }: Props) => {
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const { data: sessions, isLoading } = dataScienceApi.useGetSessionsQuery(
    { projectId },
    {
      pollingInterval: 5000,
    },
  );
  const { data: instances } = consoleApi.useListInstancesQuery(projectId, {
    pollingInterval: 5000,
  });
  const { data: projectMembers } = consoleApi.useListProjectMembersQuery(projectId, {
    pollingInterval: 5000,
  });
  const [deleteSession] = dataScienceApi.useDeleteSessionMutation();
  const { addNotification } = useNotificationActions();

  const handleDeleteSession = (sessionId: string) => {
    deleteSession(sessionId)
      .unwrap()
      .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(e);
        if (isNotNullish(error.message)) {
          logger.error(error.message);
        }
        addNotification({
          type: 'danger',
          title: 'Failed to delete session',
          description: getErrorMessage(error),
          timeout: 5000,
        });
      });
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('sessionName', {
        header: 'Session name',
        cell: (cx) => <DataGridHelpers.TruncatedColumn value={cx.getValue()} />,
      }),
      columnHelper.accessor('sessionId', {
        header: 'Session ID',
        cell: (cx) => <DataGridHelpers.TruncatedColumn value={cx.getValue()} />,
      }),
      columnHelper.accessor('instanceName', {
        header: 'Instance',
        cell: (cx) => <DataGridHelpers.TruncatedColumn value={cx.getValue()} />,
      }),
      columnHelper.accessor('memory', {
        header: 'Memory',
        cell: (cx) => <DataGridHelpers.TruncatedColumn value={cx.getValue()} />,
        size: 120,
        sortingFn: (rowA, rowB) =>
          GdsOffering.parse(rowA.original.memory).compareTo(GdsOffering.parse(rowB.original.memory)),
      }),
      columnHelper.accessor('userEmail', {
        header: 'Database user',
        cell: (cx) => (
          <DataGridHelpers.TruncatedColumn
            value={cx.getValue()}
            className="text-neutral-text-default border-neutral-border-weak bg-neutral-bg-default rounded-sm border px-1 font-mono"
          />
        ),
      }),
      columnHelper.accessor('destroyDate', {
        header: 'Time remaining',
        size: 150,
        cell: (cx) => <DataGridHelpers.TruncatedColumn value={formatDistance(new Date(), cx.getValue())} />,
      }),
      columnHelper.display({
        id: 'actions',
        cell: (cx) => (
          <div className="flex flex-grow justify-end">
            <IconButton
              ariaLabel={`Delete ${cx.row.original.sessionName}`}
              onClick={() => handleDeleteSession(cx.row.original.sessionId)}
              isDanger
              isClean
            >
              <TrashIconOutline />
            </IconButton>
          </div>
        ),
        enableResizing: false,
        size: 90,
        maxSize: 90,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const transformedData = useMemo<Row[]>(() => {
    const rows =
      sessions?.map((session) => ({
        sessionName: session.name,
        sessionId: session.id,
        instanceName: instances?.find((instance) => instance.id === session.dbId)?.name ?? 'self-hosted',
        memory: session.memory,
        userEmail: projectMembers?.find((member) => member.userId === session.userId)?.email ?? '',
        destroyDate: getSessionDestroyDate(session),
        createdAt: new Date(session.createdAt),
      })) ?? [];

    rows.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());

    return rows;
  }, [sessions, instances, projectMembers]);

  const filteredData = useMemo(() => {
    const filter = globalFilter.toLowerCase();

    return transformedData.filter((row) => row.sessionName.toLowerCase().includes(filter));
  }, [globalFilter, transformedData]);

  const table = useCommonTable({
    data: filteredData,
    columns,
    initialState: {
      pagination: { pageSize: 25 },
    },
    state: {
      columnPinning: {
        right: ['actions'],
      },
    },
    defaultColumn: {
      minSize: 70,
    },
  });

  return (
    <>
      <span className="ml-6 mt-6 flex flex-row items-baseline gap-2">
        <Typography variant="h2" className="inline">
          Sessions
        </Typography>
        <TextLink
          className="no-underline"
          href="https://neo4j.com/docs/graph-data-science-client/current/gds-session/"
          isExternalLink
        >
          Docs
        </TextLink>
      </span>
      <DataGridHelpers.Wrapper className="mx-4 my-6">
        <DataGridHelpers.OuterHeader>
          <TextInput
            isFluid
            className="min-w-36 max-w-[396px] grow"
            leftElement={<MagnifyingGlassIconOutline />}
            value={globalFilter}
            onChange={(event) => setGlobalFilter(event.target.value)}
            isDisabled={isLoading}
            htmlAttributes={{
              placeholder: 'Search for session',
              'aria-label': 'Search for session',
              'data-testid': 'search-for-session',
            }}
          />
        </DataGridHelpers.OuterHeader>
        <DataGridHelpers.DataGridRightColumnPinned<Row>
          isLoading={isLoading}
          tableInstance={table}
          styling={{
            headerStyle: 'clean',
            borderStyle: 'horizontal',
          }}
          components={{
            ...((sessions ?? []).length === 0
              ? {
                  NoDataPlaceholder,
                }
              : {}),
          }}
        />
      </DataGridHelpers.Wrapper>
    </>
  );
};

export const GuardedSessionsLayout = () => {
  const activeProject = useActiveProject();
  // const activeOrg = useActiveOrg();
  const navigate = useNavigate();

  // consolidate capability on org level after org level settings feature complete
  // if (!activeOrg.capabilities.gds_sessions || !activeProject.capabilities.gds_sessions) {
  if (!activeProject.capabilities.gds_sessions) {
    navigate('/instances');
    return <div />;
  }

  return <SessionsLayout projectId={activeProject.id} />;
};
