import { Button } from '@neo4j-ndl/react';
import { ArrowLongLeftIconOutline } from '@neo4j-ndl/react/icons';
import { Objects } from '@nx/stdlib';

import type { ONBOARDING_INTENT, OnboardingData } from '../entities/model';
import { intentLabel } from '../entities/model';
import { OnboardingItemButton } from '../onboarding-button';
import { BaseSection } from './base-section';

type IntentSectionProps = {
  onNextStep: (intent: ONBOARDING_INTENT) => void;
  onBack: () => void;
  isLoading?: boolean;
  data: OnboardingData;
};

export const IntentSection = ({ onNextStep, onBack, data, isLoading = false }: IntentSectionProps) => {
  const handleSelectIntent = (intent: ONBOARDING_INTENT) => {
    onNextStep(intent);
  };
  return (
    <BaseSection title="Why did you sign up for Neo4j Aura?" step={1}>
      <div className="flex grow flex-col justify-between gap-6 overflow-y-auto">
        <div className="flex flex-col gap-4 overflow-y-auto">
          {Objects.keys(intentLabel).map((key) => (
            <OnboardingItemButton
              key={key}
              onClick={() => handleSelectIntent(key)}
              size="large"
              isDisabled={isLoading}
              isLoading={key === data.onboardingIntent && isLoading}
            >
              {intentLabel[key]}
            </OnboardingItemButton>
          ))}
        </div>
        <div className="flex w-full justify-between self-start">
          <Button
            size="large"
            onClick={onBack}
            color="neutral"
            fill="outlined"
            className="text-neutral-text-default hover:bg-neutral-hover active:bg-palette-neutral-pressed flex gap-2 !border-none"
          >
            <ArrowLongLeftIconOutline className="size-5" />
            Back
          </Button>
        </div>
      </div>
    </BaseSection>
  );
};
