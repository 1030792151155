import { Banner, Label } from '@neo4j-ndl/react';
import { needsMigration } from '@nx/ops';
import type { Instance } from '@nx/state';
import {
  INSTANCE_PRIORITY,
  NEO4J_MANAGED_KEY_LABEL,
  TIER,
  consoleApi,
  getNeo4jVersionText,
  useActiveProject,
} from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import { useEffect, useState } from 'react';

import { constructCustomEndpointUrl } from '../custom-endpoints/helpers';
import {
  asPercentageString,
  friendlyRegionName,
  getCpuText,
  getEncryptionKeyId,
  getMemoryText,
  getStorageText,
} from './entities/helpers';
import { OpenWithMigrationAssistant } from './migration-assistant/open-with-migration-assistant';
import { PropertyDisplay } from './property-display';

export const InstanceDetailsOverview = ({ instance }: { instance: Instance }) => {
  const activeProject = useActiveProject();

  const [getEncryptionKey] = consoleApi.useLazyGetEncryptionKeyQuery();
  const { data: customEndpoints = [] } = consoleApi.useListCustomEndpointByInstanceQuery(instance.id, {
    skip: !activeProject.capabilities.custom_endpoints,
  });

  const [encryptionKeyId, setEncryptionKeyId] = useState<string | undefined>();

  useEffect(() => {
    if (isNotNullish(instance.encryptionKeyRef)) {
      getEncryptionKey({ projectId: activeProject.id, encryptionKeyRef: instance.encryptionKeyRef })
        .unwrap()
        .then((res) => {
          setEncryptionKeyId(getEncryptionKeyId(res));
        })
        .catch(() => {
          setEncryptionKeyId(undefined);
        });
    } else {
      setEncryptionKeyId(NEO4J_MANAGED_KEY_LABEL);
    }
  }, [activeProject.id, getEncryptionKey, instance]);

  const isProductionInstance = instance.priority === INSTANCE_PRIORITY.HIGHEST;
  const { publicAccessEnabled, limits } = instance;
  const { maxNodes, maxRelationships } = limits ?? {};
  const hasPrivateConnection = !isNullish(instance.privateBoltUrl);
  const showCmek =
    (isNotNullish(instance.tier) ? [TIER.ENTERPRISE, TIER.AURA_DSE].includes(instance.tier) : false) &&
    activeProject.capabilities.cmek;
  const vectorOptimizedCapable = instance.capabilities.vector_optimized.enabled;
  const gdsPluginCapable = instance.capabilities.gds_plugin.enabled;
  return (
    <div className="mb-6 flex flex-col gap-2">
      {needsMigration(instance.desiredSettings.version) && (
        <OpenWithMigrationAssistant instance={instance}>
          {({ onClick }) => (
            <Banner
              hasIcon
              className="mb-2"
              title="Migration recommended"
              type="warning"
              actions={[
                {
                  label: 'Migration readiness report',
                  onClick,
                },
              ]}
              usage="inline"
            >
              Find out what is required to migrate and benefit from the latest features and improvements.
            </Banner>
          )}
        </OpenWithMigrationAssistant>
      )}
      {isProductionInstance && (
        <Label
          fill="outlined"
          htmlAttributes={{
            'data-testid': 'production-label',
          }}
        >
          Production
        </Label>
      )}
      <PropertyDisplay label="ID" text={instance.id} allowCopyText />
      {publicAccessEnabled && (
        <>
          <PropertyDisplay label="Connection URI" text={instance.boltUrl} allowCopyText />
          <PropertyDisplay label="Query API URL" text={instance.queryApiUrl} allowCopyText />
        </>
      )}
      {customEndpoints.map((endpoint) => (
        <PropertyDisplay
          label="Custom endpoint"
          text={constructCustomEndpointUrl(endpoint)}
          allowCopyText
          key={endpoint.id}
        />
      ))}
      <PropertyDisplay label="Version" text={getNeo4jVersionText(instance.appliedSettings.version)} />
      {instance.tier !== TIER.FREE && (
        <PropertyDisplay
          label="Region"
          text={friendlyRegionName(
            instance,
            activeProject.tierConfigs[instance.tier]?.cloudProviderRegions[instance.cloudProvider],
          )}
        />
      )}
      {hasPrivateConnection && <PropertyDisplay label="Private URI" text={instance.privateBoltUrl} allowCopyText />}
      {instance.tier === TIER.FREE ? (
        <>
          <PropertyDisplay label="Nodes" text={asPercentageString(instance.counts?.currentNodes, maxNodes)} />
          <PropertyDisplay
            label="Relationships"
            text={asPercentageString(instance.counts?.currentRelationships, maxRelationships)}
          />
        </>
      ) : (
        <>
          <PropertyDisplay label="Memory" text={getMemoryText(instance)} />
          <PropertyDisplay label="CPU" text={getCpuText(instance)} />
          <PropertyDisplay label="Storage" text={getStorageText(instance)} />
        </>
      )}
      <PropertyDisplay label="Type" text={instance.tierDisplayName} />
      {showCmek && <PropertyDisplay label="Encryption Key" text={encryptionKeyId} />}
      {vectorOptimizedCapable && <PropertyDisplay label="Vector Optimized" text={String(instance.vectorOptimized)} />}
      {gdsPluginCapable && <PropertyDisplay label="Graph Analytics" text={String(instance.gdsPlugin)} />}
    </div>
  );
};
