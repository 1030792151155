import { Banner, Button, Dialog } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { consoleApi, getApiError, getErrorMessage } from '@nx/state';
import type { LinkedProject, SsoConfig } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';

import type { Validation } from '../../utils/validation';
import { SSO_CONFIG_FORM_ACTIONS } from './entities';
import type { SsoConfigFormData } from './entities';
import SsoConfigFormFields from './form-fields';
import { getErrorMessageFromUnknown, validateSsoConfig } from './helpers';

const logger = createLogger(APP_SCOPE.framework);

export interface EditSsoConfigModalProps {
  onClose: () => void;
  saveAndClose: () => void;
  ssoConfig: SsoConfig;
  isSSOAllowed: boolean;
}

const defaults = (config: SsoConfig): SsoConfigFormData => {
  return {
    ...config,
    manualUriConfiguration: isNullish(config.discoveryURI),
    clientSecret: undefined,
    linkedProjects: [],
  };
};

const EditSsoConfigModal = ({ onClose, saveAndClose, isSSOAllowed, ssoConfig }: EditSsoConfigModalProps) => {
  const { organizationId } = ssoConfig;
  const { data: linkedProjects = [], refetch } = consoleApi.useListOrganizationProjectsQuery(
    {
      organizationId,
      ssoConfigId: ssoConfig.id,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [data, setData] = useState<SsoConfigFormData>({
    ...defaults(ssoConfig),
    linkedProjects,
    projectLoginMethod: linkedProjects.length > 0,
  });
  const [validation, setValidation] = useState<Validation<SsoConfigFormData> | null>(null);
  const [hasFailedValidation, setHasFailedValidation] = useState(false);
  const [unlinkSsoConfigId] = consoleApi.useUnlinkSsoConfigIdMutation();
  const [linkSsoConfigId] = consoleApi.useLinkSsoConfigIdMutation();
  const [updateSsoConfig] = consoleApi.useUpdateSsoConfigMutation();

  const updateSsoConfigIdWithHandling = async (projectId: string, ssoConfigId: string, action: string) => {
    try {
      if (action === 'delete') {
        await unlinkSsoConfigId({
          projectId,
          ssoConfigId,
          organizationId,
        }).unwrap();
      }
      if (action === 'update') {
        await linkSsoConfigId({
          projectId,
          ssoConfigId,
          organizationId,
        }).unwrap();
      }
      return { projectId, ssoConfigId, success: true };
    } catch (err: unknown) {
      const message = getErrorMessageFromUnknown(err);
      return { projectId, ssoConfigId, success: false, error: message };
    }
  };

  const handleUpdateProjectsWithSso = async (projects: LinkedProject[]) => {
    // Get old linked project IDs
    const oldLinkedProjectIds = linkedProjects.map((project) => project.id);
    // Get new linked project IDs
    const newLinkedProjectIds = projects.map((project) => project.id);

    const addedProjects = newLinkedProjectIds.filter((projectId) => !oldLinkedProjectIds.includes(projectId));
    const deletedProjects = oldLinkedProjectIds.filter(
      (projectId) => !newLinkedProjectIds.includes(projectId) || data.projectLoginMethod === false,
    );
    const updatePromises = addedProjects.map((projectId) =>
      updateSsoConfigIdWithHandling(projectId, ssoConfig.id, 'update'),
    );
    const deletePromises = deletedProjects.map((projectId) =>
      updateSsoConfigIdWithHandling(projectId, ssoConfig.id, 'delete'),
    );
    try {
      const results = await Promise.all([...updatePromises, ...deletePromises]);
      const failedRequests = results.filter((result) => !result.success);
      if (failedRequests.length > 0) {
        setErrorMessage(
          `Failed to update SSO Config ID for projects: ${failedRequests.map((request) => request.projectId).join(', ')}`,
        );
      }
      void refetch();
    } catch (error) {
      setErrorMessage('Unknown failure when updating SSO Config ID for projects');
    }
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    setErrorMessage(null);
    const newValidation = validateSsoConfig(data);
    if (isNotNullish(newValidation)) {
      setValidation(newValidation);
      setHasFailedValidation(true);
      return;
    }
    setLoading(true);
    const { linkedProjects: projects, organizationId: orgId, projectLoginMethod, ...dataToSubmit } = data;
    updateSsoConfig({ organizationId: orgId, ssoConfigId: ssoConfig.id, ...dataToSubmit })
      .unwrap()
      .then(() => {
        void handleUpdateProjectsWithSso(projectLoginMethod === true ? projects : []);
        setLoading(false);
        saveAndClose();
      })
      .catch((err: FetchBaseQueryError | SerializedError | undefined) => {
        const apiError = getApiError(err);
        const message = getErrorMessage(apiError);
        logger.error(message);
        setLoading(false);
      });
  };

  const maybeValidate = (value: SsoConfigFormData) => {
    if (hasFailedValidation) {
      setValidation(validateSsoConfig(value));
    }
  };

  const handleChange = (newData: Partial<SsoConfigFormData>) => {
    const value = { ...data, ...newData };
    setData((oldData) => ({ ...oldData, ...newData }));
    maybeValidate(value);
  };

  return (
    <Dialog isOpen onClose={onClose} modalProps={{ 'data-testid': 'edit-sso-config-modal' }}>
      <Dialog.Header>
        Edit SSO Configuration <i>{ssoConfig.displayName}</i>
      </Dialog.Header>
      <Dialog.Description>
        <p>This will not remove SSO from instances which were created while SSO was configured.</p>
      </Dialog.Description>
      <Dialog.Content data-testid="edit-sso-config-modal-content">
        <form onSubmit={handleSubmit} id="update-sso-config-form">
          <SsoConfigFormFields
            config={data}
            onChange={handleChange}
            validation={validation}
            formAction={SSO_CONFIG_FORM_ACTIONS.UPDATE}
            organizationId={ssoConfig.organizationId}
            isSSOAllowed={isSSOAllowed}
          />
        </form>

        {isNotNullish(errorMessage) && (
          <Banner
            className="mt-2"
            type="danger"
            data-testid="edit-sso-config-error-message"
            description={`There was an error editing the SSO Config: ${errorMessage}`}
          />
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          onClick={onClose}
          color="neutral"
          fill="outlined"
          isDisabled={loading}
          data-testid="discard-sso-config-changes-button"
          htmlAttributes={{ 'data-testid': 'discard-sso-config-changes-button' }}
        >
          Discard
        </Button>
        <Button
          isLoading={loading}
          type="submit"
          htmlAttributes={{ form: 'update-sso-config-form', 'data-testid': 'update-sso-config-form' }}
        >
          Save Changes
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export default EditSsoConfigModal;
