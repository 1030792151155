import type * as ImportShared from '@nx/import-shared';
import { isNullish } from '@nx/stdlib';

import type { GraphSchemaTypes100next9 as model } from '../graph-schema-utils-types-deprecated/graph-schema-utils-types-1.0.0-next9';

export const toTableSchemaId = (idSuffix: number) => `t:${idSuffix}`;

/**
 * @deprecated This type should not be used for new models.
 */
export type FileSchemaFieldType = 'string' | 'integer' | 'float' | 'boolean' | 'datetime';

/**
 * @deprecated This type should not be used for new models.
 */
export type FileSchemaFieldJsonStruct = {
  name: string;
  type: FileSchemaFieldType;
  sample: string;
};

/**
 * @deprecated This type should not be used for new models.
 */
export type FileSchemaJsonStruct = {
  $id: string;
  fileName: string;
  expanded: boolean;
  fields: FileSchemaFieldJsonStruct[];
};

/**
 * @deprecated This type should not be used for new models.
 */
export type FileFilterJsonStruct = {
  fieldName: string;
  exactMatch: string;
};

/**
 * @deprecated This type should not be used for new models.
 */
export type NodeMappingJsonStruct = {
  node: { $ref: string };
  fileSchema: { $ref: string };
  propertyMappings: ImportShared.PropertyMappingJsonStruct[];
  fileFilter?: FileFilterJsonStruct;
};

/**
 * @deprecated This type should not be used for new models.
 */
export type RelationshipMappingJsonStruct = {
  relationship: { $ref: string };
  fileSchema: { $ref: string };
  fromMapping?: { fieldName: string };
  toMapping?: { fieldName: string };
  propertyMappings: ImportShared.PropertyMappingJsonStruct[];
  fileFilter?: FileFilterJsonStruct;
};

/**
 * @deprecated This type should not be used for new models.
 */
export type GraphMappingRepresentationJsonStruct = {
  fileSchemas: FileSchemaJsonStruct[];
  nodeMappings: NodeMappingJsonStruct[];
  relationshipMappings: RelationshipMappingJsonStruct[];
};

/**
 * @deprecated This type should not be used for new models.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export type DataModelJsonStruct_1_0_0 = {
  version: string;
  graphSchemaRepresentation: model.GraphSchemaRepresentationJsonStruct;
  graphSchemaExtensionsRepresentation: ImportShared.GraphSchemaExtensionsRepresentationJsonStruct;
  graphMappingRepresentation: GraphMappingRepresentationJsonStruct;
  configurations: ImportShared.ConfigurationsJsonStruct;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isDataModelJsonStruct_1_0_0 = (dataModel: any): dataModel is DataModelJsonStruct_1_0_0 => {
  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  return (
    !isNullish(dataModel) &&
    !isNullish(dataModel.version) &&
    !isNullish(dataModel.graphSchemaRepresentation) &&
    !isNullish(dataModel.graphSchemaExtensionsRepresentation) &&
    !isNullish(dataModel.graphSchemaExtensionsRepresentation.nodeKeyProperties) &&
    !isNullish(dataModel.graphMappingRepresentation) &&
    !isNullish(dataModel.graphMappingRepresentation.nodeMappings) &&
    !isNullish(dataModel.graphMappingRepresentation.relationshipMappings) &&
    !isNullish(dataModel.graphMappingRepresentation.fileSchemas) &&
    !isNullish(dataModel.configurations) &&
    !isNullish(dataModel.configurations.idsToIgnore)
  );
  /* eslint-enable @typescript-eslint/no-unsafe-member-access */
};
