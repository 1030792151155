import { Banner } from '@neo4j-ndl/react';
import type { OrganizationMember } from '@nx/state';
import { ACTION, consoleApi, usePermissions } from '@nx/state';
import { DataGridHelpers, SearchField } from '@nx/ui';
import { createColumnHelper, getFilteredRowModel } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useOrgAppContext } from '../../app-context';
import { useCommonTable } from '../../components/table';
import { ROLE_TYPE } from '../entities/model';
import { orgRoleFriendlyName } from '../helpers';
import { OrgMemberRowAction, RoleLabel } from './common';

const columnHelper = createColumnHelper<OrganizationMember>();

export const OrgUsers = () => {
  const { activeOrg } = useOrgAppContext();
  const { isAllowed } = usePermissions();
  const canReadOrgUsers = isAllowed(ACTION.READ, `organizations/${activeOrg.id}/users`);
  const canReadOrgRoles = isAllowed(ACTION.READ, `organizations/${activeOrg.id}/roles`);
  const navigate = useNavigate();
  const [globalFilter, setGlobalFilter] = useState('');
  // Remove null assertion when ActiveOrg becomes required
  const data = consoleApi.useListOrganizationMembersQuery(activeOrg.id, {
    skip: !canReadOrgUsers || !canReadOrgRoles,
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor('email', {
        header: 'User',
        maxSize: 350,
      }),
      columnHelper.display({
        id: 'roles',
        header: 'Organization role',
        cell: (cx) => (
          <>
            {cx.row.original.roles.map((role) => (
              <RoleLabel key={role.id} roleType={ROLE_TYPE.ORG}>
                {orgRoleFriendlyName[role.name]}
              </RoleLabel>
            ))}
          </>
        ),
        maxSize: 250,
      }),
      columnHelper.display({
        id: 'hidden_column',
        header: () => null,
      }),
      columnHelper.display({
        id: 'actions',
        cell: (cx) => {
          const row = cx.row.original;

          return <OrgMemberRowAction orgUser={row} cx={cx} />;
        },
        minSize: 90,
        maxSize: 90,
      }),
    ],
    [],
  );

  const table = useCommonTable({
    columns,
    data: data.data ?? [],
    initialState: {
      pagination: { pageSize: 25 },
    },
    defaultColumn: {
      minSize: 70,
    },
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    state: {
      globalFilter,
      columnPinning: {
        right: ['actions'],
      },
    },
  });

  if (!canReadOrgUsers || !canReadOrgRoles) {
    navigate(`/org/${activeOrg.id}/projects`, { replace: true });
    return null;
  }

  if (data.isError) {
    return (
      <Banner
        title="Something went wrong"
        description="Failed to fetch the organization users. Try again."
        type="danger"
        usage="inline"
      />
    );
  }

  return (
    <DataGridHelpers.Wrapper>
      <DataGridHelpers.OuterHeader>
        <div className="flex w-full flex-wrap justify-between gap-2">
          <div className="flex basis-[400px] gap-2">
            <SearchField
              className="min-w-36 grow"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
              htmlAttributes={{ 'aria-label': 'Search for organization user' }}
            />
          </div>
        </div>
      </DataGridHelpers.OuterHeader>
      <DataGridHelpers.DataGridRightColumnPinned<OrganizationMember>
        tableInstance={table}
        isLoading={data.isLoading}
        styling={{
          headerStyle: 'clean',
          borderStyle: 'all-sides',
        }}
        isResizable={false}
      />
    </DataGridHelpers.Wrapper>
  );
};
