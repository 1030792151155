import { selectNetworkPolicies, selectSentryConfiguration, LEGACY_store as store } from '@nx/state';
import * as Sentry from '@sentry/react';

function getVersion(version: string | undefined) {
  if (version === undefined) {
    return 'unknown';
  }

  if (version.startsWith('apps/workspace/')) {
    return version.replace('apps/workspace/', '');
  }

  return version;
}

function initSentry(sentryDsn: string | undefined, appVersion: string | undefined, releasePrefix: string | undefined) {
  const release = releasePrefix !== undefined ? `${releasePrefix}@${getVersion(appVersion)}` : getVersion(appVersion);

  if (!Sentry.isInitialized()) {
    Sentry.init({
      dsn: sentryDsn,
      release,
      tracesSampleRate: 0.2,
      beforeSend: (event) => {
        const networkPolicies = selectNetworkPolicies(store.getState());

        if (networkPolicies.crashReportsAllowed) {
          return event;
        }

        return null;
      },
    });
  }
}

export function setupIfAllowed() {
  const state = store.getState();
  const networkPolicies = selectNetworkPolicies(state);
  const configuration = selectSentryConfiguration(state);

  if (networkPolicies.crashReportsAllowed && configuration) {
    initSentry(configuration.dsn, configuration.appVersion, configuration.releasePrefix);
  }
}
