import type { Record as ResultRecord } from 'neo4j-driver';

import { assertDatabaseInstanceId, isDatabase, isDatabaseV4, isDatabaseV5 } from './database.types';
import type { Database, DatabaseInstanceId, LogicalDatabaseName } from './database.types';

export function toDatabaseObject(record: ResultRecord): Database {
  const database = record.toObject();
  if (!isDatabase(database)) {
    throw new Error(`Invalid database record: ${JSON.stringify(database)}`);
  }

  return database;
}

export function getDatabaseInstanceId(database: Database): DatabaseInstanceId {
  const instanceId = `${database.name}@${database.address}`;
  assertDatabaseInstanceId(instanceId);

  return instanceId;
}

export function sortDatabases(databases: Database[]) {
  function databaseComparator(a: Database, b: Database) {
    // disable eslint to make code more readable
    /* eslint-disable curly */
    // home is greater than default
    if (a.default && b.home) return 1;

    // otherwiser default is greater than anything else
    if (a.default) return -1;
    if (b.default) return 1;

    // system is less than anything else
    if (a.name === 'system') return 1;
    if (b.name === 'system') return -1;
    /* eslint-enable curly */

    // else sort alphabetically
    return a.name.localeCompare(b.name);
  }

  return databases.sort(databaseComparator);
}

/**
 * In clustered environments a single logical database can be backed by multiple physical database instances
 * running on a different servers.
 * This function returns a record of logical databases.
 * A database instance is uniquely identified by its name and address.
 * A logical database is uniquely identified by its name.
 * @input instances - a record of database instances
 * @output a record of logical databases
 */
export function getLogicalDatabases(
  instances: Record<DatabaseInstanceId, Database>,
): Record<LogicalDatabaseName, Database> {
  // Two databases with the same name but different properties will be merged into one
  // merging rules:
  // - if writer (leader) is present, use it, otherwise use the first one

  // Merge db2 into db1
  const mergeDatabases = (db1: Database, db2: Database): Database => {
    if (isDatabaseV4(db1) && isDatabaseV4(db2)) {
      let mergedDatabase = { ...db1 };
      if (db2.role === 'leader') {
        mergedDatabase = { ...db2 };
      }

      return mergedDatabase;
    }

    if (isDatabaseV5(db1) && isDatabaseV5(db2)) {
      let mergedDatabase = { ...db1 };
      if (db2.writer) {
        mergedDatabase = { ...db2 };
      }

      return mergedDatabase;
    }

    throw new Error('Invalid database versions');
  };

  return Object.values(instances).reduce<Record<string, Database>>((databases, instance) => {
    const key = instance.name;
    const existingDatabase = databases[key];
    const mergedDatabase = existingDatabase ? mergeDatabases(existingDatabase, instance) : instance;

    return {
      ...databases,
      [key]: mergedDatabase,
    };
  }, {});
}
