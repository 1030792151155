import { skipToken } from '@reduxjs/toolkit/query';

import consoleApi from '../slices/console/console.api';
import { useUnsafeAppContext } from './use-app-context';

export const useActiveProjectSummary = () => {
  const { activeProjectId } = useUnsafeAppContext();
  const userDetailsRes = consoleApi.useGetUserDetailsQuery();
  return consoleApi.useListProjectsByUserQuery(userDetailsRes.data?.id ?? skipToken, {
    selectFromResult: (res) => ({ project: res.data?.find((p) => p.id === activeProjectId) }),
  }).project;
};
