import { flatMap } from 'lodash-es';

import type { FullTextIndex } from '../../../types/databaseIndexes';
import type { Template as ITemplate } from '../../../types/template';
import Template from './template';

export default class TemplatedSearch {
  templates: Template[];

  constructor(templates: ITemplate[], caseInsensitive: boolean, ftsIndexes: FullTextIndex[]) {
    this.templates = templates.map((t) => new Template(t, caseInsensitive, ftsIndexes));
  }

  // TODO: remove isNewSearch when old search is replaced
  getSuggestions = (text: string, isNewSearch = false) =>
    flatMap(this.templates, (t) => t.getSuggestions(text, isNewSearch));
}
