import { persistStore } from 'redux-persist';

import { useSelector as _useSelector } from './context';
import { connectDriver as _connectDriver } from './slices/connections/connections-slice';
import { store as _store } from './store';

export { DriverConnectionError, NoDatabaseSelectedError } from './adapters/neo4j-driver-adapter';

export { selectMetadata } from './slices/metadata-slice';

export {
  addStateEventListener,
  StateEvents,
  type StateEventHandler,
  type StateListenerApi,
} from './middlewares/state-event-listeners';

export { getTransactionMetadata, isDriverConnectInfo } from './adapters/neo4j-driver-adapter';
export { neo4jDriverAdapter } from './adapters/neo4j-driver-adapter-instance';

export type { OpenAI } from 'openai';
export {
  disconnectDriver as LEGACY_disconnectDriver,
  evaluateParameters as LEGACY_evaluateParameters,
  openModal as LEGACY_openModal,
  purgeFeatureFlags as LEGACY_purgeFeatureFlags,
  resetUploadBackupState as LEGACY_resetUploadBackupState,
  setActiveOrg as LEGACY_setActiveOrg,
  setActiveProject as LEGACY_setActiveProject,
  setDesktopContext as LEGACY_setDesktopContext,
  setRelateTokens as LEGACY_setRelateTokens,
  setUserHomePath as LEGACY_setUserHomePath,
  toggleCapability as LEGACY_toggleCapability,
  toggleFeatureFlag as LEGACY_toggleFeatureFlag,
  trackPage as LEGACY_trackPage,
} from './actions';
export {
  getTextToCypherFn,
  getVertexaiClient,
  type ConsentAndGenaiState,
  type GenaiApiState,
} from './slices/genai-api-slice';

export { StateProvider as ReduxProvider } from './context';

export { reducer as rootReducer, type RootState } from './store';

/* Utilities */
export * from './utils';
/* Hooks */
export * from './hooks';

export { useCypherQuery, useDeferredCypherQuery } from './use-cypher-query';

/* Middlewares */
export { trackEvent, trackPage } from './middlewares/analytics-events';

export {
  handleConnectionAbort,
  handleConnectionReady,
  handleConnectionSkip,
  handleConnectionSuccess,
} from './middlewares/connections';
export type { ConnectionReadyCallback } from './middlewares/connections';

/* Selectors */
export { makeSelectFeatureFlag, selectEnabledFeatureFlags, selectNetworkPolicies } from './selectors';

/* Slices */
export { setActiveOrg, setActiveProject, setUserHomePath } from './slices/app-context-slice';

export {
  CAPABILITIES,
  selectCapability,
  selectEnabledTools,
  selectMode,
  toggle as toggleCapability,
  type Capability,
} from './slices/capabilities-slice';

export * from './slices/configuration';

export {
  CONNECTION_STATUS,
  CREDENTIALS_STORAGE_FIELD,
  CURRENT_STATUS,
  disconnectDriver,
  fetchLocalDiscoveryData,
  findComposite,
  guessDiscoveryApiLocation,
  isCompositeAlias,
  isDatabaseV4,
  isDatabaseV5,
  parseDiscoveryData,
  runQuery,
  selectActiveConnection,
  selectConnectionStatus,
  selectDatabases,
  setupInitialDbConnection,
  switchDatabase,
  updateDatabases,
  useActiveDatabase,
  useConnection,
  useConnections,
  useConnectionsActions,
  useConnectionStatus,
  useDatabases,
  useRawDatabases,
  useStaticConnectionUrl,
  type ActiveDatabase,
  type Connection,
  type Database,
  type RunQueryPayload,
} from './slices/connections';

export * from './slices/console';

export { evaluateParameters } from './slices/cypher-params-slice';

export { setDesktopContext, setRelateTokens } from './slices/desktop/desktop';

export * from './slices/guides';

export { default as consoleApi } from './slices/console/console.api';
export * from './slices/console/console.api.types';
export {
  MODAL_TYPE,
  openModal,
  type ArgumentsOfModalType,
  type ConnectionDetails,
  type DataSourceModalArgs,
} from './slices/modals-slice';

export { useDataSummary, useDataSummaryActions, useTypeCounts } from './slices/data-summary';

export * from './slices/desktop/relate.api';
export * from './slices/desktop/relate.error';
export * from './slices/desktop/relate.types';
export { selectProgressEvents } from './slices/desktop/selectors';

export { LOCAL_INSTANCE_SERVER_STATUS, LOCAL_INSTANCE_STATUS } from './slices/desktop/relate.types';
export type { LocalInstance, LocalInstanceInfo } from './slices/desktop/relate.types';

export {
  FEATURE_FLAGS,
  isFeatureFlag,
  purge as purgeFeatureFlags,
  toggle as toggleFeatureFlag,
  type FeatureFlag,
} from './slices/feature-flags-slice';

export {
  batchStylingUpdate,
  DEFAULT_NVL_NODE_RADIUS,
  DEFAULT_REL_WIDTH,
  selectNvlStyling,
  sortingPriorityReordered,
  stylingUpdateForLabel,
  stylingUpdateForRelType,
} from './slices/graph-styling/graph-styling-slice';

export { useMetadata } from './slices/metadata-slice';

export type { SettingsKey, SettingsState } from './slices/settings-slice';

export type {
  CaptionOption,
  GraphStyling,
  NodeStyling,
  RelationStyling,
} from './slices/graph-styling/graph-styling-slice';
export { parseBrowserGrass } from './slices/graph-styling/parse-browser-grass';
export { prettyPrintAsGrass } from './slices/graph-styling/pretty-print-as-grass';

export { addNotification } from './slices/notifications-slice';
export type { FullNxNotification } from './slices/notifications-slice';

export {
  ACTIVE_ORG_KEY,
  // Temporarily expose getAccessToken for Import components until API calls are moved to slice in this package.
  getAccessToken,
  getIdToken,
  getTokenForConnectionAndEmail,
  initialize as initializeSession,
  login,
  loginRedirect,
  logout,
  type AuthAction,
  type SerializedUser,
} from './slices/session-slice';

export { resetUploadState } from './slices/upload-slice';

export * from './slices/console/types/log-forwarding';
export * from './slices/ops/api-types/cmi';
export * from './slices/ops/api-types/logs';
export * from './slices/ops/api-types/metrics';
export * from './slices/ops/api-types/migration';
export { default as opsApi } from './slices/ops/ops.api';
export * as OpsTypes from './slices/ops/types';
export * from './slices/ops/types/logs';
export * from './slices/ops/types/metrics';
export * from './slices/ops/types/security-logs';

export * from './slices/graphql/api-types/graphql';
export { default as graphqlApi } from './slices/graphql/graphql.api';

export { default as dataScienceApi } from './slices/data-science/data-science.api';
export * from './slices/data-science/data-science.api.types';
export * from './slices/data-science/data-science.types';

// Analytics
export * as Analytics from './services/analytics';

// Error tracking
export * as ErrorTracking from './services/error-tracking';

// SSO
export { authLog } from './services/neo4j-sso/auth-log';
export {
  createUserAndStateWebStorageStores,
  getCurrentSSOProvider,
  getRedirectUri,
  getSsoWebStorageContents,
  initializeSsoRedirectUri,
  isSsoRedirect,
  NEO4J_IS_HANDLING_SSO_CALLBACK,
  NEO4J_LOCATION_BEFORE_SSO_REDIRECT,
  ssoSigninRedirect,
} from './services/neo4j-sso/auth-request';
export {
  fetchDiscoveryData,
  discoveryToConnectionDetails,
  useFetchDiscoveryData,
} from './services/neo4j-sso/discovery';

// Services
export { Persist } from './persist/persist';

export const persistor = persistStore(_store);

export function storeReady(): Promise<void> {
  function isReady() {
    const { bootstrapped } = persistor.getState();
    return bootstrapped;
  }

  return new Promise((resolve) => {
    if (isReady()) {
      resolve();
    } else {
      const unsubscribe = persistor.subscribe(() => {
        if (isReady()) {
          resolve();
          unsubscribe();
        }
      });
    }
  });
}

// TODO: Should be internal?
/**
 * @deprecated Internal hook
 */
export const useAppSelector = _useSelector;

/**
 * @deprecated Internal API
 */
export const LEGACY_store = _store;

/**
 * @deprecated Internal API
 */
export const connectDriver = _connectDriver;
