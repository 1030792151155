import type { CaptionOption, GraphStyling } from '../..';
import type { NodeStyling, RelationStyling } from './graph-styling-slice';

function prettyPrintValue(value: string | number | CaptionOption[]) {
  if (typeof value === 'string') {
    return value;
  } else if (typeof value === 'number') {
    return `${value}px`;
  }

  const [caption] = value;
  // fallback to id
  if (!caption) {
    return '"<id>"';
  }
  if (caption.type === 'property') {
    return `"{${caption.captionKey}}"`;
  } else if (caption.type === 'id') {
    return '"<id>"';
  }

  return '"<type>"';
}
function prettyPrintProperty(record: Partial<NodeStyling | RelationStyling>) {
  return Object.entries(record)
    .map(([key, value]) => `  ${key === 'captions' ? 'caption' : key}: ${prettyPrintValue(value)};`)
    .join('\n');
}

export function prettyPrintAsGrass(styling: GraphStyling) {
  return [
    ...Object.entries(styling.node).map(
      ([label, props]) => `node.${label} {
${prettyPrintProperty(props)}
}
`,
    ),
    ...Object.entries(styling.relationship).map(
      ([label, props]) => `relationship.${label} {
${prettyPrintProperty(props)}
}
`,
    ),
  ].join('\n');
}
