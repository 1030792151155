import { isNil } from 'lodash-es';

import { MAX_SUPPORTED_NANOSECOND_DIGIT } from './utils.const';

export abstract class BaseTemporal {
  static addSymbolToYearIfNeeded = (stringVal: string) => {
    let newStringVal = stringVal;
    if (!/^[+-]/.test(stringVal)) {
      const numberOfYearDigits = newStringVal.indexOf('-');
      if (numberOfYearDigits > 4) {
        newStringVal = `+${newStringVal}`;
      }
    }
    return newStringVal;
  };

  static addZeroesToYearIfNeeded = (stringVal: string) => {
    let newStringVal = stringVal;
    if (!/^[+-]/.test(stringVal)) {
      const yearDigitsIndex = newStringVal.indexOf('-');
      const yearString = stringVal.slice(0, yearDigitsIndex);
      newStringVal = yearString.padStart(4, '0') + stringVal.slice(yearDigitsIndex);
    }
    return newStringVal;
  };

  static addZeroToHourIfNeeded = (stringVal: string) => {
    let newStringVal = stringVal;
    const hourDigitsIndex = newStringVal.indexOf(':');
    const hourString = stringVal.slice(0, hourDigitsIndex);
    newStringVal = hourString.padStart(2, '0') + stringVal.slice(hourDigitsIndex);
    return newStringVal;
  };

  static convertSecondsToZoneStr = (seconds: number) => {
    const hours = Math.abs(Math.floor(seconds / 3600));
    const minutes = Math.abs(((seconds % 3600) / 3600) * 60);
    const sign = seconds >= 0 ? '+' : '-';

    const formattedHours = hours.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });

    const formattedMinutes = minutes.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });

    return `${sign}${formattedHours}:${formattedMinutes}`;
  };

  static convertNanoStringToNum = (nanoStr: string | undefined) => {
    if (isNil(nanoStr) || nanoStr.length > MAX_SUPPORTED_NANOSECOND_DIGIT || nanoStr.length === 0) {
      return '0';
    }

    const fullNano = nanoStr.padEnd(MAX_SUPPORTED_NANOSECOND_DIGIT, '0');

    return `${parseInt(fullNano)}`;
  };
}
