import { isAction, isAnyOf } from '@reduxjs/toolkit';

import { log } from '../../services/logging';
import { deletePerspective } from '../../services/perspectives';
import { deleteScenesFromPerspective } from '../../services/scene';
import type { Nullable } from '../../types/utility';
import { getDatabase, getDatabases, getIsFeatureAvailable } from '../connections/connectionsDuck';
import {
  NAME as STYLES_ACTION_PREFIX,
  STYLE_TYPE_PERSPECTIVE,
  getPerspectiveWithStyle,
  getStyleType,
  setCurrentStyleId,
} from '../styles/styles';
import type { AppMiddleware, AppStore } from '../types';
import { setDataNeedsRefresh } from '../userview/userview';
import { mergeAndUpdatePerspectives, storePerspectiveToDb } from './perspectiveStoreUtils';
import {
  NAME as PERSPECTIVE_ACTION_PREFIX,
  SET_CURRENT_PERSPECTIVE,
  addCategoryToPerspective,
  addLabelToCategory,
  addPerspective,
  addPropertiesToRelationships,
  appendPerspectives,
  hideRelationshipTypes,
  loadPerspective,
  removeCategoryFromPerspective,
  removeLabelFromCategory,
  removePerspective,
  revealRelationshipTypes,
  setHideUncategorisedData,
  updateCategoryProperty,
  updatePerspectiveHistory,
  updatePerspectiveSha,
} from './perspectives';

const actionsToIgnore = [removePerspective, appendPerspectives, updatePerspectiveSha, setCurrentStyleId];

const actionsShouldRefreshData = [
  addLabelToCategory,
  removeLabelFromCategory,
  addCategoryToPerspective,
  removeCategoryFromPerspective,
  hideRelationshipTypes,
  revealRelationshipTypes,
  setHideUncategorisedData,
  updateCategoryProperty,
];

const actionsToIgnoreForPerspectiveHistory = [
  addPropertiesToRelationships,
  updatePerspectiveHistory,
  loadPerspective,
  updatePerspectiveSha,
];

const PERSPECTIVE_FETCHER_DELAY_MS = 10000;
let perspectiveFetcherJobId: Nullable<NodeJS.Timeout> = null;
const setPerspectiveFetcher = (store: AppStore) => {
  if (!perspectiveFetcherJobId) {
    perspectiveFetcherJobId = setInterval(executePerspectiveFetcher, PERSPECTIVE_FETCHER_DELAY_MS, store);
  }
};
const clearPerspectiveFetcher = () => {
  perspectiveFetcherJobId && clearTimeout(perspectiveFetcherJobId);
  perspectiveFetcherJobId = null;
};

const executePerspectiveFetcher = (store: AppStore) => {
  const state = store.getState();
  const perspective = getPerspectiveWithStyle(state)();
  const database = getDatabase(state);

  if (perspective) {
    if (perspective.isPlugin) {
      void mergeAndUpdatePerspectives(perspective, database, store, () => clearPerspectiveFetcher());
    } else {
      clearPerspectiveFetcher();
    }
  }
};

const perspectiveStoreMiddleware: AppMiddleware = (store) => (next) => (action) => {
  if (isAnyOf(...actionsShouldRefreshData)(action)) {
    store.dispatch(setDataNeedsRefresh(true));
  }

  const shouldSyncToServer = getIsFeatureAvailable(store.getState(), 'perspectiveSharing');
  const isPerspectiveAction = isAction(action) && action.type.startsWith(`${PERSPECTIVE_ACTION_PREFIX}/`);
  const isMetadataAction = isAction(action) && action.type.startsWith('metadata/');
  const isStyleAction = isAction(action) && action.type.startsWith(`${STYLES_ACTION_PREFIX}/`);

  if (!shouldSyncToServer) return next(action);

  if (removePerspective.match(action)) {
    const { perspectiveId, dbId } = action.payload;
    const state = store.getState();
    const databases = getDatabases(state);
    const database = databases.find(({ id }) => id === dbId) ?? null;

    const deletePerspectiveAndScenes = async () => {
      try {
        await deleteScenesFromPerspective(perspectiveId);
        const result = await deletePerspective({ perspectiveId, database });
        if (!result?.success) {
          log.warn(`Perspective could not be deleted! ${result?.message ? result.message : 'Unknown error occurred'}`);
        }
      } catch (error) {
        log.error(error);
      }
    };
    void deletePerspectiveAndScenes();
  } else if ((isPerspectiveAction || isMetadataAction || isStyleAction) && !isAnyOf(...actionsToIgnore)(action)) {
    const nextResult = next(action);
    const state = store.getState();

    if (action.type !== SET_CURRENT_PERSPECTIVE && !action.type.includes('SYNC_PERSPECTIVE')) {
      let perspective;

      if (isAnyOf(addPerspective, loadPerspective)(action)) {
        perspective = action.payload;
      } else if (
        typeof action === 'object' &&
        'payload' in action &&
        typeof action.payload === 'object' &&
        action.payload !== null &&
        'perspectiveId' in action.payload &&
        typeof action?.payload?.perspectiveId === 'string'
      ) {
        perspective = getPerspectiveWithStyle(state)(action.payload.perspectiveId);
      } else {
        perspective = getPerspectiveWithStyle(state)();
      }

      if (perspective) {
        clearPerspectiveFetcher();

        if (perspective.isPlugin) {
          const isStyleChanged = isStyleAction && getStyleType(state) === STYLE_TYPE_PERSPECTIVE;
          const shouldUpdatePerspectiveHistory =
            (isPerspectiveAction || isStyleChanged) && !isAnyOf(...actionsToIgnoreForPerspectiveHistory)(action);
          storePerspectiveToDb(perspective, store, state, shouldUpdatePerspectiveHistory, setPerspectiveFetcher);
        }
      }

      return nextResult;
    }
  }
  return next(action);
};

export default perspectiveStoreMiddleware;
