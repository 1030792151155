/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Typography } from '@neo4j-ndl/react';
import { ACTION, useActiveProject, usePermissions } from '@nx/state';
import { PermissionTooltip } from '@nx/ui';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import type { ProjectUsersContext } from './contexts';
import type { User } from './entities/model';
import { InviteUserModal } from './invite-user-modal';

// const OrgRouteToTab = new Map([...OrgViewConfig.entries()].map(([tab, { route }]) => [route, tab]));
// const ProjectRouteToTab = new Map([...ProjectViewConfig.entries()].map(([tab, { route }]) => [route, tab]));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const getTabFromOrgRoute = (route: string | undefined): OrgView => {
//   return typeof route === 'undefined' || !OrgRouteToTab.has(route) ? 'ORG' : OrgRouteToTab.get(route) ?? 'ORG';
// };
// const getTabFromProjectRoute = (route: string | undefined): ProjectView => {
//   return typeof route === 'undefined' || !ProjectRouteToTab.has(route)
//     ? 'PROJECT'
//     : ProjectRouteToTab.get(route) ?? 'PROJECT';
// };

export function OrgUsersLayout() {
  // const navigate = useNavigate();
  // const orgPathMatch = useMatch('/org/:orgId/users/*');
  // const orgRoute = orgPathMatch?.params['*'];

  return (
    <div className="h-full overflow-auto p-4">
      <div className="mb-6 flex items-center justify-between px-2 pt-2">
        <Typography variant="h2">Organization users</Typography>
        {/* <Button onClick={alert.bind(null, 'Invite users...')}>Invite users</Button> */}
      </div>
      {/* <Tabs
        className="w-fit"
        fill="underline"
        onChange={(tabId) => navigate(OrgViewConfig.get(tabId)!.route)}
        value={getTabFromOrgRoute(orgRoute)}
      >
        {[...OrgViewConfig.entries()].map(([view, { tabLabel }]) => (
          <Tabs.Tab key={view} tabId={view} data-testid={view}>
            {tabLabel}
          </Tabs.Tab>
        ))}
      </Tabs> */}
      <Outlet />
    </div>
  );
}

export function ProjectUsersLayout() {
  const activeProject = useActiveProject();
  const { isAllowed } = usePermissions();
  const allowInvite = isAllowed(ACTION.CREATE, `namespaces/${activeProject.id}/invites`);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  // const navigate = useNavigate();

  const [users, setUsers] = useState<User[]>([]);

  // const handleTabChange = (tabId: ProjectView) => {
  //   const tabRoute = ProjectViewConfig.get(tabId)?.route;
  //   if (typeof tabRoute !== 'undefined') {
  //     navigate(ProjectViewConfig.get(tabId)!.route);
  //   }
  // };

  const handleInviteUsersClick = () => {
    setInviteModalOpen(true);
  };

  const handleRevokeInvite: ProjectUsersContext['revokeInvite'] = (email) =>
    setUsers(users.filter((user) => user.email !== email));

  return (
    <>
      <div className="h-full overflow-auto p-4">
        <div className="mb-6 flex items-center justify-between px-2 pt-2">
          <Typography variant="h2">Project users</Typography>
          <PermissionTooltip hasPermission={allowInvite} hasButtonWrapper>
            <Button onClick={handleInviteUsersClick} isDisabled={!allowInvite}>
              Invite users
            </Button>
          </PermissionTooltip>
        </div>
        {/* <Tabs
          className="w-fit"
          fill="underline"
          onChange={handleTabChange}
          value={getTabFromProjectRoute(projectRoute)}
          >
          {[...ProjectViewConfig.entries()].map(([view, { tabLabel }]) => (
            <Tabs.Tab key={view} tabId={view} data-testid={view}>
            {tabLabel}
            </Tabs.Tab>
            ))}
            </Tabs> */}
        <Outlet context={{ users, revokeInvite: handleRevokeInvite } satisfies ProjectUsersContext} />
      </div>
      {inviteModalOpen && (
        <InviteUserModal
          project={activeProject}
          onClose={() => {
            setInviteModalOpen(false);
          }}
        />
      )}
    </>
  );
}
