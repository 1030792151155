import type { SerializedError } from '@reduxjs/toolkit';
import { concat, slice } from 'lodash-es';

import {
  V4_END_NODE_ID,
  V4_ID,
  V4_START_NODE_ID,
  V5_END_NODE_ID,
  V5_ID,
  V5_START_NODE_ID,
} from '../../../services/bolt/constants';
import { TRANSACTION_TIMEOUT_ERROR_CODES } from '../../../services/search/transactions.const';
import type { Records } from '../../../services/search/types';
import { SEARCH_STATUS, type SearchStatus } from './search-core.types';

export const generateSearchStatus = ({
  uniqueNodesSize = 0,
  error,
  recordLimitHit = false,
  isUpdateQuery = false,
}: {
  uniqueNodesSize?: number;
  error?: unknown;
  recordLimitHit?: boolean;
  isUpdateQuery?: boolean;
}): SearchStatus => {
  if (error) {
    if (isTransactionTimeoutError(error)) {
      return {
        status: SEARCH_STATUS.WARNING,
        message:
          `${uniqueNodesSize} ${uniqueNodesSize > 1 ? 'nodes are' : 'node is'}` +
          ' found during the specified search duration. This does not cover the entire graph. For a complete result,' +
          ' please extend the search duration.',
      };
    } else if (isSerializedError(error)) {
      return {
        status: SEARCH_STATUS.FAILED,
        message:
          'name' in error && error.name === 'AbortError' ? 'Query has been terminated by request' : error.message,
      };
    }
  }
  if (uniqueNodesSize === 0) {
    return {
      message: "Sorry, we couldn't find any results.",
      status: SEARCH_STATUS.FAILED,
    };
  }
  const getEndingNodes = uniqueNodesSize > 1 ? 's' : '';
  if (recordLimitHit) {
    return {
      message: `${uniqueNodesSize} node${getEndingNodes} found! The node query limit has been reached.`,
      status: SEARCH_STATUS.WARNING,
    };
  }
  return {
    message: isUpdateQuery
      ? `${uniqueNodesSize} node${getEndingNodes} changed.`
      : `${uniqueNodesSize} node${getEndingNodes} found.`,
    status: SEARCH_STATUS.SUCCEEDED,
  };
};

const isSerializedError = (object: unknown): object is SerializedError => {
  if (object !== null && typeof object === 'object') {
    return (
      ('name' in object && typeof object.name === 'string') ||
      ('message' in object && typeof object.message === 'string') ||
      ('stack' in object && typeof object.stack === 'string') ||
      ('code' in object && typeof object.code === 'string')
    );
  }

  return false;
};

export const isTransactionTimeoutError = (object: unknown): object is SerializedError => {
  return isSerializedError(object) && TRANSACTION_TIMEOUT_ERROR_CODES.includes(object.code ?? '');
};

type Node = { id: string; elementId: string };
type Relationship = { startId: string; endId: string; startNodeElementId: string; endNodeElementId: string };

interface ResultsByUniqueNodesProps {
  previousRecords: Records;
  newRecords: Records;
  uniqueNodesIds: Set<string>;
  limit: number;
  isV5OrGreater: boolean | null;
}

export const getResultsByUniqueNodes = ({
  previousRecords,
  newRecords,
  uniqueNodesIds,
  limit,
  isV5OrGreater = true,
}: ResultsByUniqueNodesProps) => {
  const totalRecords: Records = { nodes: [], relationships: [] };
  const { nodes, relationships } = newRecords;
  const getNodeByIdKey = (node: Node) => node[isV5OrGreater ? V5_ID : V4_ID];
  const newUniqueNodes = nodes.filter((node: Node) => !uniqueNodesIds.has(getNodeByIdKey(node)));

  const hasNewNodes = newUniqueNodes.length > 0;
  const hasRelationships = relationships.length > 0 || previousRecords.relationships.length > 0; // it should return false if it's not the first iteration and no rels in previous records and no in the current record as well

  const newUniqueNodesIds = new Set(newUniqueNodes.map(getNodeByIdKey));

  const nbrOfPreviousUniqueNodesIds = uniqueNodesIds.size;
  const nbrOfNewUniqueNodesIds = newUniqueNodesIds.size;

  const isUnderLimit = nbrOfPreviousUniqueNodesIds + nbrOfNewUniqueNodesIds < limit;
  const nbrOfAllowedNewUniqueNodesIds = limit - nbrOfPreviousUniqueNodesIds;

  const validNewUniqueNodesIds = isUnderLimit
    ? newUniqueNodesIds
    : new Set(slice(Array.from(newUniqueNodesIds), 0, nbrOfAllowedNewUniqueNodesIds));

  const updatedUniqueNodesIds = new Set([...uniqueNodesIds, ...validNewUniqueNodesIds]);

  const validNewNodes = hasNewNodes
    ? hasRelationships || !isUnderLimit // if there are no relationships, it means we search categories only or single nodes, so every new record is a unique node and no need to filter them by uniqueness, if the limit is reached, we need to filter them in order to keep only the first ones that are under the limit
      ? newUniqueNodes.filter((node: Node) => validNewUniqueNodesIds.has(getNodeByIdKey(node)))
      : newUniqueNodes
    : [];

  const validNewRelationships = relationships.filter(
    (rel: Relationship) =>
      updatedUniqueNodesIds.has(rel[isV5OrGreater ? V5_START_NODE_ID : V4_START_NODE_ID]) &&
      updatedUniqueNodesIds.has(rel[isV5OrGreater ? V5_END_NODE_ID : V4_END_NODE_ID]),
  );

  totalRecords.nodes = hasNewNodes ? concat(previousRecords.nodes, validNewNodes) : previousRecords.nodes;
  totalRecords.relationships = hasRelationships
    ? concat(previousRecords.relationships, validNewRelationships)
    : previousRecords.relationships;

  return {
    totalRecords,
    uniqueNodesIds: updatedUniqueNodesIds,
    isAboveLimit: !isUnderLimit,
  };
};
