interface SizeProps {
  color: string;
  size: number;
}

export const NodeSizeRepresentation = ({ color, size }: SizeProps) => {
  const dimension = 22 * Math.sqrt(size);
  return (
    <div
      style={{ backgroundColor: color, width: `${dimension}px`, height: `${dimension}px` }}
      className="rounded-full"
    />
  );
};

export const RelSizeRepresentation = ({ color, size }: SizeProps) => {
  return <div style={{ backgroundColor: color, height: `${size * 4}px` }} className="w-full" />;
};
