import { ClipboardButton, Dialog, TextLink, Typography } from '@neo4j-ndl/react';
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  open: boolean;
  onClose: () => void;
  metricTitle: string;
  metricName: string;
  projectId: string;
};

export const MetricsIntegrationModal = ({ open, onClose, metricTitle, metricName, projectId }: Props) => (
  <Dialog size="small" isOpen={open} onClose={onClose}>
    <Dialog.Header className="h6">
      Metrics Integration
      <TextLink
        type="external"
        href="https://neo4j.com/docs/aura/metrics/metrics-integration/introduction/"
        className="ml-2"
      >
        Docs
      </TextLink>
    </Dialog.Header>
    <Dialog.Description>
      <div>
        You can forward metrics to desired platforms via{' '}
        <TextLink
          as="span"
          htmlAttributes={{
            'aria-label': 'Aura API credentials',
          }}
        >
          <Link to={`/projects/${projectId}/settings/metrics-integration`}>Metrics Integration.</Link>
        </TextLink>
      </div>
      <div>
        <Typography variant="body-medium">
          The metric &quot;{metricTitle}&quot; is exposed under the name <i>{metricName}.</i>
        </Typography>
        <ClipboardButton size="small" textToCopy={metricName} />
      </div>
    </Dialog.Description>
  </Dialog>
);
