import { Button, Dialog, TextInput, Tooltip } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import type { DataApiBatchResult } from '@nx/state';
import { graphqlApi, useActiveProject } from '@nx/state';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';

import { ApiErrorBanner } from './api-error-banner';

const logger = createLogger(APP_SCOPE.dataApi);

interface Props {
  onClose: () => void;
  onConfirm: () => void;
  dataApi: DataApiBatchResult;
}

export const DeleteDataApiModal = ({ onClose, onConfirm, dataApi }: Props) => {
  const activeProject = useActiveProject();
  const [confirmText, setConfirmText] = useState('');
  const [tipOpen, setTipOpen] = useState(false);
  const [deleteDataApi, deleteRes] = graphqlApi.useDeleteDataApiMutation();
  const { name } = dataApi;
  const handleConfirmTextChange = (value: string) => {
    setConfirmText(value);
  };

  const handleClose = () => {
    onClose();
    setConfirmText('');
  };

  const handleDelete = (event: SyntheticEvent) => {
    event.preventDefault();
    deleteDataApi({
      projectId: activeProject.id,
      instanceId: dataApi.instanceId,
      dataApiId: dataApi.id,
    })
      .unwrap()
      .then(() => {
        onConfirm();
      })
      .catch((error: FetchBaseQueryError | SerializedError | undefined) => {
        logger.error(error);
      });
  };

  useEffect(() => {
    if (tipOpen) {
      setTimeout(() => {
        setTipOpen(false);
      }, 2000);
    }
  }, [tipOpen]);

  return (
    <Dialog type="danger" isOpen onClose={handleClose} size="large">
      <Dialog.Header>
        Delete{' '}
        <Tooltip isOpen={tipOpen} type="simple" placement="right" isPortaled={false}>
          <Tooltip.Trigger
            className="cursor-text"
            htmlAttributes={{
              onClick: () => {
                void navigator.clipboard.writeText(dataApi.name);
                if (!tipOpen) {
                  setTipOpen(true);
                }
              },
              'aria-label': 'Copy text',
            }}
          >
            {dataApi.name}
          </Tooltip.Trigger>
          <Tooltip.Content className="flex items-center">Copied to clipboard</Tooltip.Content>
        </Tooltip>
      </Dialog.Header>
      <Dialog.Subtitle>This action is irreversible. We will delete your GraphQL Data API.</Dialog.Subtitle>
      <form onSubmit={handleDelete}>
        <Dialog.Description>
          <div className="flex flex-col gap-4">
            <TextInput
              value={confirmText}
              onChange={(e) => handleConfirmTextChange(e.target.value)}
              label="Type the name of the GraphQL Data API below to confirm"
              htmlAttributes={{
                autoFocus: true,
                placeholder: 'E.g. GraphQL Data API name',
                'aria-label': 'input Data API name',
              }}
            />
            {deleteRes.error && <ApiErrorBanner error={deleteRes.error} hasIcon />}
          </div>
        </Dialog.Description>
        <Dialog.Actions>
          <Button color="neutral" fill="outlined" onClick={handleClose} isDisabled={deleteRes.isLoading}>
            Cancel
          </Button>
          <Button color="danger" type="submit" isDisabled={confirmText !== name} isLoading={deleteRes.isLoading}>
            Delete
          </Button>
        </Dialog.Actions>
      </form>
    </Dialog>
  );
};
