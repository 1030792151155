import { DataGrid, DataGridComponents, Tooltip, useDataGridContext } from '@neo4j-ndl/react';
import { isNullish } from '@nx/stdlib';
import type { RowData } from '@tanstack/react-table';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import type { ComponentProps, ComponentPropsWithoutRef, PropsWithChildren } from 'react';

import classes from './datagrid.module.css';

type WrapperProps = ComponentPropsWithoutRef<'div'> & {
  /** if `true` removes top-left border radius, combining better with `<Tabs />` positioned above */
  tabbed?: boolean;
};

const Wrapper = ({ tabbed = false, ...props }: WrapperProps) => (
  <div {...props} className={classNames(classes['data-grid-wrapper'], tabbed && classes.tabbed, props.className)} />
);

const OuterHeader = (props: ComponentPropsWithoutRef<'div'>) => (
  <div
    {...props}
    className={classNames('border-b-palette-neutral-border-weak flex items-center border-b p-4', props.className)}
  />
);

type BodyRowProps<T> = React.ComponentProps<typeof DataGridComponents.BodyRow<T>>;
interface BodyCellProps<T> extends ComponentProps<typeof DataGridComponents.BodyCell<T>> {
  isStickyActionCell: boolean;
}

const StickyActionsBodyCell = <T,>({ cell, isStickyActionCell }: BodyCellProps<T>) => (
  <DataGridComponents.BodyCell
    key={cell.id}
    cell={cell}
    innerProps={{
      className: classNames('!bg-transparent', {
        '!pl-0 flex flex-grow justify-end w-full': isStickyActionCell,
      }),
    }}
  />
);

const ACTIONS_COLUMN_ID = 'action-column';

// This is to include the "isStickyAction" meta data on the column.
// https://tanstack.com/table/v8/docs/api/core/column-def#meta
declare module '@tanstack/react-table' {
  // This needs to be here for eslint to be happy, if we remove the generic vars ColumnMeta is not happy
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    isStickyAction?: boolean;
  }
}

/**
 * @deprecated After ndl v3 this component is very buggy (probably was before as well but less noticeable).
 * Use the built in `columnPinning` instead
 */
const StickyActionsBodyRow = <T,>({ row }: BodyRowProps<T>) => {
  const { components } = useDataGridContext();
  const rowClasses = classNames(classes['sticky-action-row'], 'ndl-data-grid-tr flex flex-row');
  const cellClasses = classNames(classes['sticky-action-cell'], 'sticky right-0 flex grow');

  return (
    <div className={rowClasses} key={`sticky-actions-row-${row.id}`} role="row">
      {row.getVisibleCells().map((cell) => {
        const isStickyActionCell = cell.column.columnDef.meta?.isStickyAction ?? false;
        return (
          components.BodyCell &&
          (!isStickyActionCell ? (
            <StickyActionsBodyCell cell={cell} key={`sticky-actions-cell-${cell.id}`} isStickyActionCell={false} />
          ) : (
            <div
              className={cellClasses}
              key={`sticky-actions-wrapper-cell-${cell.id}`}
              style={{
                minWidth: cell.column.columnDef.size,
              }}
            >
              <StickyActionsBodyCell cell={cell} key={`sticky-actions-cell-${cell.id}`} isStickyActionCell={true} />
            </div>
          ))
        );
      })}
    </div>
  );
};

const HoverBodyRow = <T,>({ row }: BodyRowProps<T>) => {
  const { components } = useDataGridContext();
  const rowClasses = classNames(classes['hover-row'], 'ndl-data-grid-tr flex flex-row');

  return (
    <div className={rowClasses} key={`hover-row-${row.id}`} role="row">
      {row.getVisibleCells().map((cell) => {
        return components.BodyCell && <DataGridComponents.BodyCell key={cell.id} cell={cell} />;
      })}
    </div>
  );
};

// This is a quite hacky way to get the StickyActionBodyRow to work with Headers as expected
type ElementBase<T = HTMLElement> = Omit<React.HTMLProps<T>, 'as' | 'selected' | 'size' | 'onChange' | 'ref'> & {
  /** Override the root element rendered for the component */
  as?: string | React.ComponentType<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
};

interface CommonInnerProps<T = HTMLDivElement> {
  /** Children are surfaced to allow easy overriding */
  children?: React.ReactNode;
  innerProps?: ElementBase<T>;
}

/**
 * @deprecated After ndl v3 this component is very buggy (probably was before as well but less noticeable).
 * Use the built in `columnPinning` instead
 */
const StickyActionHeader = ({ children, innerProps }: CommonInnerProps<HTMLDivElement>) => {
  const { tableProps, components } = useDataGridContext();
  const { getHeaderGroups, getState } = tableProps;
  const { className = undefined } = innerProps ?? {};
  const rowClasses = classNames(classes['sticky-action-row-header'], 'ndl-data-grid-tr flex flex-row');
  const cellClasses = classNames(classes['sticky-action-cell-header'], 'sticky right-0 flex grow');

  return (
    <div
      {...innerProps}
      role="rowgroup"
      className={classNames('ndl-data-grid-thead', className, {
        'ndl-data-grid-is-resizing': getState().columnSizingInfo.isResizingColumn,
      })}
    >
      {isNullish(children) ? (
        <>
          {getHeaderGroups().map((headerGroup) => (
            <div key={headerGroup.id} className={rowClasses} role="row">
              {headerGroup.headers.map((header) => {
                const isStickyActionCell = header.column.columnDef.meta?.isStickyAction ?? false;
                return (
                  components.HeaderCell &&
                  (!isStickyActionCell ? (
                    <components.HeaderCell key={header.id} cell={header} />
                  ) : (
                    <div
                      className={cellClasses}
                      key={`sticky-actions-wrapper-cell-${header.id}`}
                      style={{
                        minWidth: header.column.columnDef.size,
                      }}
                    >
                      <components.HeaderCell
                        key={header.id}
                        cell={header}
                        innerProps={{
                          className: '!pl-0 flex flex-grow justify-end w-full !bg-transparent',
                        }}
                      />
                    </div>
                  ))
                );
              })}
            </div>
          ))}
        </>
      ) : (
        children
      )}
    </div>
  );
};

const TruncatedColumn = ({
  value,
  children,
  className,
}: { value: string | number; className?: string } & PropsWithChildren) => {
  const [shouldDisplayTooltip, setShouldDisplayTooltip] = useState(true);
  const textElementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const newValue = (textElementRef.current?.scrollWidth ?? 0) <= (textElementRef.current?.clientWidth ?? 0);
    if (newValue !== shouldDisplayTooltip) {
      setShouldDisplayTooltip(newValue);
    }
  }, [
    textElementRef.current?.scrollWidth,
    textElementRef.current?.clientWidth,
    setShouldDisplayTooltip,
    shouldDisplayTooltip,
  ]);

  const cn = classNames('truncate', className);

  return (
    <Tooltip type="simple" isDisabled={shouldDisplayTooltip}>
      <Tooltip.Trigger hasButtonWrapper>
        <div className={cn} ref={textElementRef}>
          {children ?? value}
        </div>
      </Tooltip.Trigger>
      <Tooltip.Content>{value}</Tooltip.Content>
    </Tooltip>
  );
};

const DataGridRightColumnPinned = <T,>(props: ComponentProps<typeof DataGrid<T>>) => {
  const { className, ...restRootProps } = props.rootProps ?? {};
  const rootClasses = classNames(
    '[&_.ndl-data-grid-thead]:!border-neutral-border-weak [&_.ndl-data-grid-pinned-cell-right]:!grow [&_.ndl-data-grid-pinned-cell-right]:!border-r-0 [&_.ndl-data-grid-th]:!border-b-0 [&_.ndl-data-grid-thead]:!border-b',
    className,
    {
      '[&_.ndl-data-grid-thead_.ndl-data-grid-tr_.ndl-data-grid-pinned-cell-right]:!bg-neutral-bg-weak':
        props.styling?.headerStyle === 'clean',
    },
  );

  return (
    <DataGrid
      isKeyboardNavigable={false}
      {...props}
      rootProps={{
        ...restRootProps,
        className: rootClasses,
      }}
    />
  );
};

export const DataGridHelpers = {
  Wrapper,
  OuterHeader,
  /**
   * @deprecated After ndl v3 this component is very buggy (probably was before as well but less noticeable).
   * Use the built in `columnPinning` instead
   */
  StickyActionsBodyRow,
  HoverBodyRow,
  /**
   * @deprecated After ndl v3 this component is very buggy (probably was before as well but less noticeable).
   * Use the built in `columnPinning` instead
   */
  StickyActionHeader,
  ACTIONS_COLUMN_ID,
  TruncatedColumn,
  DataGridRightColumnPinned,
};
