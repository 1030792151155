import { createSelector } from '@reduxjs/toolkit';
import { isEmpty, memoize, size, trim } from 'lodash-es';

import { FULL_TEXT_SEARCH_SUGGESTION_MINIMAL_LENGTH } from '../../../../modules/SearchBar/SearchBar.const';
import { buildSuggestion, isFullTextSearchSuggestion } from '../../../../modules/SearchBar/SearchBar.utils';
import { type ActionSuggestion, SUGGESTION_TYPE } from '../../../../modules/SearchBar/types';
import { getFullTextIndexes } from '../../../perspectives/perspectiveMetadata';
import type { RootState } from '../../../types';
import { selectLockedSuggestions } from '../../core/search-core.selectors';

export const selectFullTextSuggestions: (
  inputText: string,
) => (state: RootState) => ReturnType<(state: RootState, inputText: string) => ActionSuggestion[]> = memoize(
  (
    inputText: string,
  ): ((state: RootState) => ReturnType<(state: RootState, inputText: string) => ActionSuggestion[]>) =>
    createSelector([getFullTextIndexes, selectLockedSuggestions], (fullTextIndexes, lockedSuggestions) => {
      const isFullTextSearchPossible = !isEmpty(fullTextIndexes);
      if (inputText.length < FULL_TEXT_SEARCH_SUGGESTION_MINIMAL_LENGTH) {
        return [];
      }

      const lockedSuggestionSize = size(lockedSuggestions);

      if (
        lockedSuggestionSize === 0 ||
        (lockedSuggestionSize === 1 && isFullTextSearchSuggestion(lockedSuggestions.at(0)))
      ) {
        const cleanText = trim(inputText)
          .replace(/\s+/g, ' ') // replace multiple string with single string
          .replace(/~/g, ''); // remove `~` because `~` triggers fuzz fulltext search

        return [
          buildSuggestion({
            type: SUGGESTION_TYPE.FULL_TEXT_SEARCH,
            text: inputText,
            sanitizedInputText: cleanText,
            isDisabled: !isFullTextSearchPossible,
          }),
        ];
      }

      return [];
    }),
);
