import type { JSONSchemaType } from 'ajv';

import { extractUniqueNodesAndRels } from '../data-transforms/extract-unique-nodes-and-relationships';
import type { BaseArguments, BasicNode, BasicRelationship } from '../types/sdk-types';
import { basicNodeSchema, basicRelationshipSchema } from '../types/sdk-types';

export type Neighbours = {
  allNeighboursCount: number;
  limitHit?: boolean;
  relationships: BasicRelationship[];
  nodes: BasicNode[];
};
export type GetNeighboursOwnArgs = {
  nodeId: string;
  currentNeighbourIds: string[];
  maxNewNeighbours: number;
};

export type GetNeighboursArgs = BaseArguments & GetNeighboursOwnArgs;

export async function getNeighbours({
  queryCypher,
  nodeId,
  currentNeighbourIds,
  maxNewNeighbours,
}: GetNeighboursArgs): Promise<Neighbours> {
  // all element ids have a '-' in them
  const useElementId = nodeId.includes('-');
  const idFunction = useElementId ? 'elementId' : 'id';
  const query =
    maxNewNeighbours > 0
      ? `MATCH (a) WHERE ${idFunction}(a) = ${useElementId ? `"${nodeId}"` : nodeId}
      WITH a, size([(a)--() | 1]) AS allNeighboursCount
      MATCH path = (a)--(o) WHERE NOT ${idFunction}(o) IN [${currentNeighbourIds.map((n) => (useElementId ? `"${n}"` : n)).join(',')}]
      RETURN path, allNeighboursCount
      ORDER BY ${idFunction}(o)
      LIMIT ${maxNewNeighbours}`
      : `MATCH p=(a)--() WHERE ${idFunction}(a) = ${useElementId ? `"${nodeId}"` : nodeId}
      RETURN count(p) as allNeighboursCount`;

  const result = await queryCypher(query);

  // Types are checked in tests
  const allNeighboursCount =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    result.records.length > 0 ? parseInt((result.records[0].get('allNeighboursCount') as number).toString(), 10) : 0;

  const { limitHit, nodes, relationships } = extractUniqueNodesAndRels(result.records, { keepDanglingRels: true });

  return { limitHit, nodes, relationships, allNeighboursCount };
}

export const getNeighboursSchema: JSONSchemaType<Neighbours> = {
  type: 'object',
  properties: {
    allNeighboursCount: { type: 'number' },
    limitHit: { type: 'boolean', nullable: true },
    relationships: {
      type: 'array',
      items: basicRelationshipSchema,
    },
    nodes: {
      type: 'array',
      items: basicNodeSchema,
    },
  },
  required: ['allNeighboursCount', 'nodes', 'relationships'],
};
