import { areTypesEqual, describeMatch } from './typeUtils';

export default (text, evaluations) => {
  return {
    type: GRAPH_PATTERN,
    text,
    evaluations,
    description: evaluations
      .map((evaluation) => describeMatch(evaluation.match, evaluation.permutation.token))
      .join(' '),
    asciiArt: evaluations.reduce((art, evaluation, index) => {
      if (index === 0 && evaluation.type === 'relationship') {
        art.push({
          name: null,
          type: 'label',
        });
      } else if (index > 0 && areTypesEqual(art[art.length - 1].type, evaluation.type)) {
        art.push({
          name: null,
          type: evaluation.type === 'relationship' ? 'label' : 'relationship',
        });
      }
      art.push({
        name: evaluation.match.name,
        type: evaluation.type,
        label: evaluation.match.label,
        category: evaluation.match.category || (evaluation.type === 'category' && evaluation.match.name),
      });
      if (index === evaluations.length - 1 && evaluation.type === 'relationship') {
        art.push({
          name: null,
          type: 'label',
        });
      }
      return art;
    }, []),
    isEqual: (suggestion) =>
      suggestion.evaluations &&
      suggestion.evaluations.length === evaluations.length &&
      evaluations.every((evaluation, index) => suggestion.evaluations[index].isEqual(evaluation)),
    complete:
      !['value', 'label', 'relationship', 'category', 'property-key'].includes(
        evaluations[evaluations.length - 1].match.type,
      ) || evaluations[evaluations.length - 1].match.name === evaluations[evaluations.length - 1].permutation.token,
  };
};

export const SEARCH_PHRASE = 'Search phrase';
export const GRAPH_PATTERN = 'Graph pattern';
export const ACTION = 'Action';
export const FULL_TEXT_SEARCH = 'Full-text search';
