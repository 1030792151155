import { Button, Typography } from '@neo4j-ndl/react';
import { useActiveProject } from '@nx/state';
import { Center } from '@nx/ui';
import { useNavigate } from 'react-router-dom';

import NoInstanceIllustrationSvg from '../assets/no-instance-illustration.svg';

export const NoInstancesState = () => {
  const navigate = useNavigate();
  const activeProject = useActiveProject();
  const instancesRoute = `/projects/${activeProject.id}/instances`;
  const navigateToInstances = () => {
    navigate({ pathname: `${instancesRoute}` });
  };

  return (
    <Center>
      <img src={NoInstanceIllustrationSvg} className="h-[160px] w-[160px]" draggable="false" />
      <div className="ml-12 flex max-w-[430px] flex-col gap-4">
        <Typography variant="h3">No GraphQL Data API found</Typography>
        <Typography variant="body-large">There are not any AuraDB instances to use a GraphQL Data API with.</Typography>
        <Typography variant="body-large">
          GraphQL Data APIs can be used with a running AuraDB instance of type Professional, Business Critical or
          Virtual Dedicated Cloud.
        </Typography>
        <Button
          className="w-fit"
          onClick={() => navigateToInstances()}
          fill="filled"
          htmlAttributes={{
            tabIndex: 0,
          }}
        >
          Create Instance
        </Button>
      </div>
    </Center>
  );
};
