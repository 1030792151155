import { IconButton, LoadingSpinner, Switch, Tabs } from '@neo4j-ndl/react';
import { XMarkIconOutline } from '@neo4j-ndl/react/icons';
import { OPS_EVENTS } from '@nx/analytics-service';
import { neo4jVersionUtil } from '@nx/neo4j-version-utils';
import type { Instance, MetricsResult, TimeRange } from '@nx/state';
import { MODAL_TYPE, TIER, useActiveProject, useModal } from '@nx/state';
import { isNonEmptyString } from '@nx/stdlib';
import { Center } from '@nx/ui';
import classNames from 'classnames';
import { useCallback, useMemo, useRef, useState } from 'react';

import { track } from '../services/segment/analytics';
import { NoInstancesOverlay, UpgradeOverlay } from '../shared/components';
import { DatabaseSelector } from '../shared/components/database-selector';
import { InstanceBar } from '../shared/components/instance-bar';
import { InstanceSelector } from '../shared/components/instance-selector';
import { withUpxContext } from '../shared/components/upx-context';
import { useInstanceSelectorProps } from '../shared/hooks/use-instance-selector-props';
import { useIsScrolled } from '../shared/hooks/use-is-scrolled';
import { fakeMetrics } from '../shared/utils';
import { AuraDatabaseCharts } from './charts/database-charts';
import type { ChartType } from './charts/fullstack-chart-props';
import { AuraInstanceCharts } from './charts/instance-charts';
import { LineCard, OptionalFeatures } from './charts/line-cards';
import { AuraSystemCharts } from './charts/system-charts';
import type { ChartContext, ChartExpandProps, MultiChartProps } from './charts/types';
import type { MetricsViewState } from './hooks';
import { chartKey, useMetricsMetadata, useMetricsQuery, useMetricsViewState } from './hooks';
import { useViewCharts } from './hooks/use-view-charts';
import classes from './metrics.module.css';
import { TimePeriodAndRefresh } from './shared/components/time-window-selector';
import type { DropdownOptions } from './shared/types';
import { getPills } from './shared/utils';

const AuraViewSwitchOptions: DropdownOptions<ChartType> = [
  {
    value: 'HOST',
    label: 'Resources',
  },
  {
    value: 'INSTANCE',
    label: 'Instance',
  },
  {
    value: 'DB',
    label: 'Database',
  },
];

const ViewCharts: Record<ChartType, MultiChartProps[]> = {
  HOST: AuraSystemCharts,
  INSTANCE: AuraInstanceCharts,
  DB: AuraDatabaseCharts,
  INSTANCE_DASHBOARD: [],
};

const TIME_RANGE_PADDED = true;
const MIN_PADDING_MS = 5 * 60 * 1000;
const PADDING_PERCENTAGE = 0.1;
const DEFAULT_TAB: ChartType = 'HOST';

const getPadding = ({ startTime, endTime }: TimeRange) => {
  const diff = endTime.getTime() - startTime.getTime();
  const padding = Math.max(MIN_PADDING_MS, diff * PADDING_PERCENTAGE);
  return padding;
};

export const padTimeRange = (timeRange: TimeRange): TimeRange => {
  const paddingMs = getPadding(timeRange);
  const startTime = new Date(timeRange.startTime.getTime() - paddingMs);
  const endTime = new Date(timeRange.endTime.getTime() + paddingMs);
  return {
    startTime,
    endTime,
  };
};

type MetricsViewProps = {
  instance: Instance;
  viewType: ChartType;
  viewState: MetricsViewState;
  chartContext: ChartContext;
  expandProps: ChartExpandProps;
  onZoom?: (timeRange: TimeRange) => void | undefined;
  onZoomReset?: () => void | undefined;
  useAZGrouping: boolean;
  showLeaderOnly?: boolean;
  database?: string;
};

const MetricsView = ({
  instance,
  viewType,
  chartContext,
  viewState,
  expandProps,
  onZoom,
  onZoomReset,
  useAZGrouping,
  showLeaderOnly,
  database,
}: MetricsViewProps) => {
  const { timeRangeZoomedIn, expandedChart } = viewState;
  const timeRange = timeRangeZoomedIn ? padTimeRange(chartContext.timeRange) : chartContext.timeRange;
  const viewChartProps = useViewCharts(instance, useAZGrouping)[viewType];
  const { metricsMetadata, isError: isMetricsMetadataErr } = useMetricsMetadata(instance);
  const isDbmsEnterprise = instance.tier === TIER.ENTERPRISE || instance.tier === TIER.MTE;

  const shallowChartProps = useMemo(() => {
    return viewChartProps.map((value) => {
      // Only request the first metric of each multi-chart
      return Array.isArray(value) && value.length > 1 ? value[0]! : value;
    });
  }, [viewChartProps]);

  const metricsResult = useMetricsQuery({
    selectedInstanceDetails: instance,
    timeRange,
    chartProps: shallowChartProps,
    chartType: viewType,
    useAggregations: isDbmsEnterprise && !useAZGrouping,
    useAZGrouping: useAZGrouping,
    showLeaderOnly: showLeaderOnly,
    database,
  });

  const instanceFulfillsVersionRequirement = (chartProps: MultiChartProps) => {
    const minVersion = (Array.isArray(chartProps) ? chartProps[0] : chartProps)?.minimumRequiredDatabaseVersion;
    if (isNonEmptyString(minVersion) && instance.desiredSettings.version) {
      return neo4jVersionUtil.gte(instance.desiredSettings.version, minVersion);
    }
    return true;
  };

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
      {viewChartProps.map(
        (chart) =>
          instanceFulfillsVersionRequirement(chart) && (
            <LineCard
              key={chartKey(chart)}
              multiChartProps={chart}
              context={chartContext}
              metricsResult={metricsResult}
              expandProps={{ ...expandProps, isExpanded: expandedChart === chart }}
              onZoom={onZoom}
              onZoomReset={onZoomReset}
              timeRangePadded={TIME_RANGE_PADDED}
              addMinMaxBands={true}
              pills={getPills(chart, useAZGrouping, metricsResult.metrics, showLeaderOnly)}
              isCmiAvailable={
                instance.tier === TIER.ENTERPRISE || instance.tier === TIER.MTE || instance.tier === TIER.AURA_DSE
              }
              optionalFeatures={OptionalFeatures.AuraWithLogs}
              metadata={!isMetricsMetadataErr ? metricsMetadata : undefined}
              timeRange={timeRange}
              instance={instance}
              useAggregations={isDbmsEnterprise && !useAZGrouping}
              useAZGrouping={useAZGrouping}
              showLeaderOnly={showLeaderOnly}
              database={database}
            />
          ),
      )}
    </div>
  );
};

const FakeMetricsView = ({
  instanceName,
  viewType,
  chartContext,
}: {
  instanceName: string;
  viewType: ChartType;
  chartContext: ChartContext;
}) => {
  const chartProps = ViewCharts[viewType];

  const fakeMetricsResult: MetricsResult = useMemo(
    () => ({
      metrics: fakeMetrics(instanceName, 'LIVE', chartProps),
      isLoading: false,
    }),
    [chartProps, instanceName],
  );

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
      {chartProps.map((chart) => (
        <LineCard
          key={chartKey(chart)}
          multiChartProps={chart}
          context={chartContext}
          metricsResult={fakeMetricsResult}
          expandProps={{ isExpanded: false }}
          timeRangePadded={TIME_RANGE_PADDED}
          addMinMaxBands={true}
          pills={getPills(chart, false, fakeMetricsResult.metrics)}
          isCmiAvailable={false}
          optionalFeatures={OptionalFeatures.Aura}
          timeRange={{ startTime: new Date(), endTime: new Date() }}
        />
      ))}
    </div>
  );
};

const UpgradeMetricsView = ({ instance, viewType, chartContext }: MetricsViewProps) => {
  const activeProject = useActiveProject();
  const upgradeModal = useModal(MODAL_TYPE.UPGRADE);

  return (
    <>
      <UpgradeOverlay
        title="Upgrade to Professional for metrics"
        body="Upgrading to professional enables access to a broad range of different instance and database metrics."
        onUpgrade={() => upgradeModal.open({ project: activeProject, instance })}
      >
        <FakeMetricsView instanceName={instance.name} viewType={viewType} chartContext={chartContext} />
      </UpgradeOverlay>
    </>
  );
};

const NoInstancesView = ({ viewType, chartContext }: { viewType: ChartType; chartContext: ChartContext }) => {
  return (
    <NoInstancesOverlay
      title="No instances found"
      body="Create an instance to enable access to a broad range of different instance and database metrics."
    >
      <div className="m-4 flex flex-col gap-4">
        <FakeMetricsView instanceName="fake" viewType={viewType} chartContext={chartContext} />
      </div>
    </NoInstancesOverlay>
  );
};

const MetricsPage = () => {
  const { selectedInstanceSummary, setSelectedInstanceId, instanceSummaries, isInstancesLoading, instances } =
    useInstanceSelectorProps();

  const [view, setView] = useState<ChartType>(DEFAULT_TAB);

  // TODO populate from API when available
  const databaseNames = ['neo4j'];
  const [currentDbName, setCurrentDbName] = useState<string>(databaseNames[0] ?? '');

  const { state, chartContext, setTimePeriod, zoomIn, refreshTimeRange, setExpandedChart } = useMetricsViewState({});
  const [useAZGrouping, setUseAZGrouping] = useState<boolean>(false);
  const [showLeaderOnly, setShowLeaderOnly] = useState<boolean>(false);
  const trackMetrics = useCallback<typeof track>(
    (event, props) => {
      return track(event, {
        tab: view,
        expandedChart: state.expandedChart && chartKey(state.expandedChart),
        ...props,
      });
    },
    [state.expandedChart, view],
  );

  const setTimePeriodTracked = useCallback<typeof setTimePeriod>(
    (timePeriod) => {
      setTimePeriod(timePeriod);
      trackMetrics(OPS_EVENTS.METRICS_CHANGE_TIME_PERIOD, {
        timePeriod,
      });
    },
    [setTimePeriod, trackMetrics],
  );

  const onZoom = useCallback(
    (timeRange: TimeRange | null = null) => {
      zoomIn(timeRange);
    },
    [zoomIn],
  );

  const onZoomReset = useCallback(() => {
    if (state.timeRangeZoomedIn) {
      // zooms out chart instantly since state.timeRangeDerived metrics are already in cache
      zoomIn(null);
      // schedules a refresh to update state.timeRangeDerived and get latest metrics
      setTimeout(() => refreshTimeRange(), 500);
    }
  }, [refreshTimeRange, state.timeRangeZoomedIn, zoomIn]);

  const onRefreshTracked = useCallback(() => {
    refreshTimeRange();
    trackMetrics(OPS_EVENTS.METRICS_REFRESH);
  }, [refreshTimeRange, trackMetrics]);

  const timePeriodAndRefresh = (
    <TimePeriodAndRefresh
      key="time-period-and-refresh"
      timeRange={chartContext.timeRange}
      timePeriod={state.timePeriod}
      onTimePeriodChange={setTimePeriodTracked}
      onRefresh={onRefreshTracked}
    />
  );

  const expandedChartProps = {
    isExpanded: false,
    onSetExpanded: setExpandedChart,
    expandedControls: {
      timePeriodAndRefresh,
      close: (
        <IconButton
          // TODO: add aria-label
          ariaLabel=""
          isClean
          onClick={() => setExpandedChart(null)}
          key="close-expanded-button"
        >
          <XMarkIconOutline />
        </IconButton>
      ),
    },
  };

  const metricsRootElRef = useRef<HTMLDivElement | null>(null);
  const { isScrolled } = useIsScrolled(metricsRootElRef.current?.parentElement);

  if (isInstancesLoading) {
    return (
      <Center>
        <LoadingSpinner size="large" />
      </Center>
    );
  }

  if (instanceSummaries.length === 0 || !selectedInstanceSummary) {
    return <NoInstancesView viewType={view} chartContext={chartContext} />;
  }

  const selectedInstance = instances.find((i) => i.id === selectedInstanceSummary.id);

  return (
    <div ref={metricsRootElRef}>
      <div className="border-palette-neutral-border-weak bg-palette-neutral-bg-weak sticky top-0 z-10">
        <div className="border-palette-neutral-border-weak bg-palette-neutral-bg-weak flex min-h-[56px] flex-wrap-reverse items-center justify-between border-b-[1px] px-4">
          <div className="flex min-h-[55px] flex-wrap gap-4">
            <Tabs
              fill="underline"
              onChange={(newView) => {
                track(OPS_EVENTS.METRICS_VIEW, { view: newView });
                zoomIn(null);
                setView(newView);
              }}
              value={view}
              className={classNames(classes.tabs, 'self-end')}
            >
              {AuraViewSwitchOptions.map((option) => (
                <Tabs.Tab
                  tabId={option.value}
                  key={option.value}
                  htmlAttributes={{
                    'data-testid': option.value,
                  }}
                >
                  {option.label}
                </Tabs.Tab>
              ))}
            </Tabs>
          </div>
          <div className="flex min-h-[55px] flex-wrap items-center gap-2">{timePeriodAndRefresh}</div>
        </div>
        <InstanceBar shadow={isScrolled}>
          <div className="flex flex-wrap gap-2">
            <InstanceSelector
              selectedInstanceSummary={selectedInstanceSummary}
              setSelectedInstanceId={setSelectedInstanceId}
              instances={instances}
              instanceSummaries={instanceSummaries}
            />
            {view === 'DB' && databaseNames.length > 1 && (
              <DatabaseSelector
                selectedDatabase={currentDbName}
                setSelectedDatabase={setCurrentDbName}
                databases={databaseNames}
              />
            )}
          </div>
          {selectedInstance && (selectedInstance.tier === TIER.ENTERPRISE || selectedInstance.tier === TIER.MTE) && (
            <div className="flex flex-wrap items-center gap-4">
              {view === 'DB' && useAZGrouping && (
                <Switch
                  htmlAttributes={{
                    'aria-label': `${showLeaderOnly ? 'Disable' : 'Enable'} leader-only view`,
                  }}
                  isChecked={showLeaderOnly}
                  onChange={() => setShowLeaderOnly(!showLeaderOnly)}
                  label="Leader only"
                />
              )}
              <Switch
                onChange={() => setUseAZGrouping(!useAZGrouping)}
                label="Detail view"
                isChecked={useAZGrouping}
                htmlAttributes={{
                  'aria-label': `${useAZGrouping ? 'Disable' : 'Enable'} detail view`,
                }}
              />
            </div>
          )}
        </InstanceBar>
      </div>
      <Tabs.TabPanel tabId={view} value={view} className="m-4 flex flex-col gap-4">
        {!selectedInstance && (
          <Center className="mt-[10%]">
            <LoadingSpinner size="large" />
          </Center>
        )}
        {selectedInstance && selectedInstance.tier === TIER.FREE && (
          <UpgradeMetricsView
            instance={selectedInstance}
            viewType={view}
            chartContext={chartContext}
            viewState={state}
            expandProps={expandedChartProps}
            useAZGrouping={useAZGrouping}
          />
        )}
        {selectedInstance && selectedInstance.tier !== TIER.FREE && (
          <MetricsView
            instance={selectedInstance}
            viewType={view}
            chartContext={chartContext}
            viewState={state}
            expandProps={expandedChartProps}
            onZoom={onZoom}
            onZoomReset={onZoomReset}
            useAZGrouping={useAZGrouping}
            showLeaderOnly={showLeaderOnly}
            database={currentDbName}
          />
        )}
      </Tabs.TabPanel>
    </div>
  );
};

const MetricsPageWithUpxContext = withUpxContext(MetricsPage);

export default MetricsPageWithUpxContext;
