import { type SSOProviderOriginal } from '@nx/constants';
import * as StdLib from '@nx/stdlib';
import { InMemoryWebStorage, UserManager, WebStorageStateStore } from 'oidc-client-ts';
import type { UserProfile } from 'oidc-client-ts';

export const NEO4J_LOCATION_BEFORE_SSO_REDIRECT = 'auth.neo4j.location';

export const createUserAndStateInMemoryStores = (webStorageContents: {
  userStoreContents: { key: string; value: string | null }[];
  stateStoreContents: { key: string; value: string | null }[];
}) => {
  const createInMemoryStore = (storeContents: { key: string; value: string | null }[]) => {
    const store = new InMemoryWebStorage();
    storeContents.forEach(({ key, value }) => {
      if (StdLib.isNotNullish(value)) {
        store.setItem(key, value);
      }
    });
    return store;
  };
  const { userStoreContents, stateStoreContents } = webStorageContents;
  return {
    userStore: new WebStorageStateStore({ prefix: '', store: createInMemoryStore(userStoreContents) }),
    stateStore: new WebStorageStateStore({ prefix: '', store: createInMemoryStore(stateStoreContents) }),
  };
};

export const ssoProviderToUserManager = (
  provider: SSOProviderOriginal,
  userAndStateStores: { userStore: WebStorageStateStore; stateStore: WebStorageStateStore },
  getRedirectUri: () => string | null,
): UserManager => {
  const { well_known_discovery_uri, auth_endpoint, auth_params, token_endpoint, token_params, params, config } =
    provider;

  const redirectUri = getRedirectUri();
  const { client_id, scope, response_type, ...extraParams } = params;

  return new UserManager({
    ...userAndStateStores,

    authority: well_known_discovery_uri ?? auth_endpoint,
    metadata: {
      authorization_endpoint: auth_endpoint,
      token_endpoint,
      code_challenge_methods_supported:
        !StdLib.isNullish(config) && !StdLib.isNullish(config.code_challenge_method)
          ? [config.code_challenge_method]
          : undefined,
    },

    client_id,
    scope,
    response_type,

    extraQueryParams: { ...extraParams, ...auth_params },
    extraTokenParams: token_params,

    // We redirect to the fallback route and not the root of the application for
    // backwards compatibility. The query params are also for backwards
    // compatibility, we're not actually using them.
    redirect_uri: StdLib.isNullish(redirectUri)
      ? ''
      : `${redirectUri}?idp_id=${provider.id}&auth_flow_step=redirect_uri`,
  });
};

const ssoUserToCredentials = (
  provider: SSOProviderOriginal,
  user: { profile: UserProfile; access_token: string; id_token?: string },
  authLog: (msg: string, type?: 'log' | 'warn' | 'error') => void,
): {
  providers: SSOProviderOriginal[];
  username: string;
  password: string;
  expiration: number;
} => {
  authLog(`Attempting to assemble credentials for idp_id: ${provider.id}`);

  const principal = provider.config?.principal ?? '';
  if (principal !== '') {
    authLog(`Credentials, provided principal in config: ${principal}`);
  }
  const username = String(
    user.profile[principal] ?? user.profile.preferred_username ?? user.profile.email ?? user.profile.sub,
  );
  authLog(`Credentials assembled with username: ${username}`);

  const tokenType = provider.config?.token_type_authentication ?? provider.config?.token_type_principal ?? '';
  let token;
  switch (tokenType) {
    case 'access_token':
      token = user.access_token;
      authLog(
        `Credentials assembled with token type "access_token" as password. If connection still does not succeed, make sure 'neo4j.conf' is set up correctly`,
      );
      break;

    case 'id_token':
      token = user.id_token ?? user.access_token;
      if (StdLib.isNullish(user.id_token)) {
        authLog(
          `Credentials, provided token type "id_token" in config, but no "id_token" was found. Defaulting to "access_token"`,
        );
      } else {
        authLog(
          `Credentials assembled with token type "id_token" as password. If connection still does not succeed, make sure 'neo4j.conf' is set up correctly`,
        );
      }

      break;

    case '':
      token = user.access_token;
      authLog('Credentials, provided no token type in config, defaulting to "access_token"');
      break;

    default:
      authLog(`Credentials, provided invalid token type "${tokenType}" in config, defaulting to "access_token"`);
      token = user.access_token;
  }

  return {
    providers: [provider],
    username,
    expiration: user.profile.exp,
    password: token,
  };
};

export async function handleSsoRedirect(
  provider: SSOProviderOriginal,
  userAndStateStores: { userStore: WebStorageStateStore; stateStore: WebStorageStateStore },
  getRedirectUri: () => string | null,
  authLog: (msg: string, type?: 'log' | 'warn' | 'error') => void,
) {
  // Redirecting to locationBeforeRedirect from here using history.pushState
  // doesn't work if the app has already been mounted, so to avoid race
  // conditions, we have an SSORedirect component that takes care of the
  // redirect through the react-router-dom navigate hook.
  const locationBeforeRedirect = window.sessionStorage.getItem(NEO4J_LOCATION_BEFORE_SSO_REDIRECT);
  if (locationBeforeRedirect !== null) {
    authLog(`Redirected back from provider "${provider.id}" location "${locationBeforeRedirect}"`);
    const authClient = ssoProviderToUserManager(provider, userAndStateStores, getRedirectUri);

    const user = await authClient.signinRedirectCallback();
    authLog(`SSO flow completed successfully`);

    return ssoUserToCredentials(provider, user, authLog);
  }

  return null;
}

export async function getSsoCredentials(
  provider: SSOProviderOriginal | null,
  userAndStateStores: { userStore: WebStorageStateStore; stateStore: WebStorageStateStore },
  getRedirectUri: () => string | null,
  authLog: (msg: string, type?: 'log' | 'warn' | 'error') => void,
): Promise<{
  providers: SSOProviderOriginal[];
  username: string;
  password: string;
  expiration: number;
} | null> {
  if (StdLib.isNullish(provider)) {
    // No SSO provider was selected, nothing to do.
    return null;
  }

  // No need for special handling, we're just getting/refreshing the access token if available.
  const authClient = ssoProviderToUserManager(provider, userAndStateStores, getRedirectUri);
  const user = await authClient.getUser();

  if (user === null) {
    return null;
  }

  authLog(`Retrieved token from existing session with provider: ${provider.id}`);
  return ssoUserToCredentials(provider, user, authLog);
}
