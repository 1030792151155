import { isNullish } from '@nx/stdlib';

import type { DataModel_0_8_0 } from '../0.8.0/types';

/**
 * @deprecated This type should not be used for new models.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export type DataModel_0_0_2 = Omit<DataModel_0_8_0, 'configurations'> & {
  configurations?: {
    idsToIgnore?: string[];
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isDataModel_0_0_2 = (dataModel: any): dataModel is DataModel_0_8_0 => {
  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  // TODO - better validation...
  return (
    !isNullish(dataModel) &&
    !isNullish(dataModel.fileModel) &&
    !isNullish(dataModel.graphModel) &&
    !isNullish(dataModel.mappingModel)
  );
  /* eslint-enable @typescript-eslint/no-unsafe-member-access */
};
