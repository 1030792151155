import { LoggerAnalyticsAdapter, SegmentAnalyticsAdapter, analyticsAdapter } from '@nx/analytics-service';
import { selectNetworkPolicies, selectSegmentConfiguration } from '@nx/state';

import { createAsyncThunk } from '../../context';
import { segmentDbIdInjectionPlugin } from './segment-dbid-plugin';

async function setupAnalyticsAdapter(eventPrefix?: string, apiKey?: string) {
  if (apiKey === undefined) {
    await analyticsAdapter.setup(new LoggerAnalyticsAdapter());
  } else {
    const segmentAnalyticsAdapter = new SegmentAnalyticsAdapter({
      apiKey,
      eventPrefix,
    });
    segmentAnalyticsAdapter.addPlugin(segmentDbIdInjectionPlugin);

    await analyticsAdapter.setup(segmentAnalyticsAdapter);
  }
}

export const setupIfAllowed = createAsyncThunk('analytics/setupIfAllowed', async (_, { getState }) => {
  const state = getState();
  const networkPolicies = selectNetworkPolicies(state);
  const configuration = selectSegmentConfiguration(state);

  if (networkPolicies.usageTrackingAllowed && configuration) {
    await setupAnalyticsAdapter(configuration.eventPrefix, configuration.apiKey);
  }
});
