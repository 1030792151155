import { Label, TextInput, useDataGridContext } from '@neo4j-ndl/react';
import { MagnifyingGlassIconOutline } from '@neo4j-ndl/react/icons';
import type { DataApiBatchResult } from '@nx/state';
import { ClipboardCopier, DataGridHelpers } from '@nx/ui';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import { ApiErrorBanner } from '../components/api-error-banner';
import { DataApiActionMenu } from '../components/data-api-action-menu';

interface DataApiTableProps {
  dataApis: DataApiBatchResult[] | undefined;
  filter: string;
  setFilter: (value: string) => void;
  onClickEdit: (modifyDataApi: DataApiBatchResult) => void;
  onClickMenuItemAction: () => void;
}

interface TableResultProps {
  manualPagination?: {
    from: number;
    to: number;
    totalRows: number;
  };
}

const dataApiTableHelper = createColumnHelper<DataApiBatchResult>();

const getStatusLabel = (status: string): JSX.Element => {
  const StatusLabel = (color: 'default' | 'info' | 'success' | 'warning' | 'danger') => {
    return (
      <Label hasIcon color={color} fill="semi-filled" className="font-normal">
        {status}
      </Label>
    );
  };

  switch (status) {
    case 'creating':
    case 'updating':
    case 'deleting':
    case 'pausing':
    case 'resuming':
      return StatusLabel('info');
    case 'paused':
      return StatusLabel('default');
    case 'error':
      return StatusLabel('danger');
    case 'ready':
      return StatusLabel('success');

    default:
      return StatusLabel('default');
  }
};

// Copy-pasted from Needle. The only reason is to change "results" to "GraphQL Data APIs"
// Would be nice if the Needle TableResults component had a "render" prop to customize the text
const TableResults = ({ manualPagination }: TableResultProps) => {
  const { tableProps } = useDataGridContext();
  const { getState, getRowModel } = tableProps;
  const {
    pagination: { pageSize, pageIndex },
  } = getState();
  const { rows } = getRowModel();

  const { from, to, totalRows } = useMemo(
    () =>
      manualPagination ?? {
        from: 1 + pageIndex * pageSize,
        to: rows.length + pageIndex * pageSize,
        totalRows: tableProps.getCoreRowModel().rows.length,
      },
    [pageIndex, pageSize, manualPagination, rows, tableProps],
  );

  return (
    <span className="ndl-data-grid-results">
      <span>
        Showing <b>{totalRows ? `${from}${to !== from ? `-${to}` : ''}` : 0}</b> of <b>{totalRows}</b> GraphQL Data APIs
      </span>
    </span>
  );
};

export const DataApiTable = ({
  dataApis,
  filter,
  setFilter,
  onClickEdit,
  onClickMenuItemAction,
}: DataApiTableProps) => {
  const [menuActionError, setMenuActionError] = useState<FetchBaseQueryError | SerializedError | undefined>(undefined);

  const columns = useMemo(
    () => [
      dataApiTableHelper.accessor('name', {
        header: () => 'Name',
        cell: (cx) => <DataGridHelpers.TruncatedColumn value={cx.getValue()} />,
        sortingFn: 'text',
        maxSize: 250,
        minSize: 100,
      }),
      dataApiTableHelper.accessor('id', {
        header: () => 'ID',
        cell: (cx) => <div>{cx.getValue()}</div>,
        maxSize: 150,
        minSize: 150,
        enableResizing: false,
      }),
      dataApiTableHelper.accessor('url', {
        header: () => 'URL',
        cell: (cx) => (
          <div className="flex max-w-max flex-row truncate break-all">
            <DataGridHelpers.TruncatedColumn value={cx.getValue()} />
            <ClipboardCopier textToCopy={cx.getValue()} ariaLabel="Copy to clipboard" />
          </div>
        ),
        minSize: 250,
        maxSize: 550,
      }),
      dataApiTableHelper.accessor('instanceName', {
        header: () => 'Instance',
        cell: (cx) => <DataGridHelpers.TruncatedColumn value={cx.getValue()} />,
        sortingFn: 'text',
        maxSize: 250,
        minSize: 100,
      }),
      dataApiTableHelper.accessor('instanceId', {
        header: () => 'Instance ID',
        cell: (cx) => <DataGridHelpers.TruncatedColumn value={cx.getValue()} />,
        sortingFn: 'text',
        maxSize: 150,
        minSize: 150,
        enableResizing: false,
      }),
      dataApiTableHelper.accessor('status', {
        header: () => 'Status',
        cell: (cx) => <div>{getStatusLabel(cx.getValue())}</div>,
        maxSize: 150,
        minSize: 150,
        enableResizing: false,
      }),
      dataApiTableHelper.display({
        id: 'actions',
        cell: (cx) => {
          const dataApi = cx.row.original;
          return (
            <DataApiActionMenu
              dataApi={dataApi}
              onClickEdit={() => onClickEdit(dataApi)}
              onClickMenuItemAction={() => onClickMenuItemAction()}
              setMenuActionError={(err) => setMenuActionError(err)}
            />
          );
        },
        size: 60,
        maxSize: 60,
        enableResizing: false,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const table = useReactTable({
    data: dataApis ?? [],
    columns,
    enableColumnPinning: true,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      columnPinning: {
        right: ['actions'],
      },
    },
  });

  return (
    <DataGridHelpers.Wrapper>
      <DataGridHelpers.OuterHeader>
        {menuActionError && <ApiErrorBanner error={menuActionError} hasIcon className="mb-4" />}
        <TextInput
          htmlAttributes={{
            type: 'text',
            'aria-label': 'Search for Data APIs',
            'data-testid': 'search-data-apis',
          }}
          isFluid
          className="max-w-80"
          placeholder="Search"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          leftElement={<MagnifyingGlassIconOutline />}
        />
      </DataGridHelpers.OuterHeader>
      <DataGridHelpers.DataGridRightColumnPinned<DataApiBatchResult>
        tableInstance={table}
        styling={{ headerStyle: 'clean' }}
        components={{
          TableResults,
        }}
      />
    </DataGridHelpers.Wrapper>
  );
};
