const SeamlessConnectionErrorMessage = {
  AuthenticationRequired: 'Login required',
  FetchFailed: 'Could not retrieve connection token',
  MissingInstanceId: 'Instance ID was not found in connection URL',
  MissingTokenApiUrl: 'Token API URL was not found in configuration',
  Unknown: 'Unknown error',
} as const satisfies Record<string, string>;

export type SeamlessConnectionErrorCode = keyof typeof SeamlessConnectionErrorMessage;

export class SeamlessConnectionError extends Error {
  code: `Workspace.SeamlessConnection.${SeamlessConnectionErrorCode}`;

  constructor(code: SeamlessConnectionErrorCode, message?: string) {
    super(
      message === undefined
        ? SeamlessConnectionErrorMessage[code]
        : `${SeamlessConnectionErrorMessage[code]}: ${message}`,
    );
    this.code = `Workspace.SeamlessConnection.${code}`;
    this.name = 'SeamlessConnectionError';
  }
}

const SsoConnectionErrorMessage = {
  NoProviders: 'No SSO providers found',
  Unknown: 'Unknown error',
} as const satisfies Record<string, string>;

export type SsoConnectionErrorCode = keyof typeof SsoConnectionErrorMessage;

export class SsoConnectionError extends Error {
  code: `Workspace.SSO.${SsoConnectionErrorCode}`;

  constructor(code: SsoConnectionErrorCode, message?: string) {
    super(message === undefined ? SsoConnectionErrorMessage[code] : `${SsoConnectionErrorMessage[code]}: ${message}`);
    this.code = `Workspace.SSO.${code}`;
    this.name = 'SsoConnectionError';
  }
}
