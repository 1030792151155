import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { filter } from 'lodash-es';
import { v1 as uuidv1 } from 'uuid';

import type { RootState } from '../types';
import type { Error, NotificationsState, Warning } from './types';

export const NAME = 'notifications';

const initialState: NotificationsState = {
  errors: [],
  warnings: [],
};

// Selectors
export const getErrorNotifications = (state: RootState): Error[] => state[NAME].errors;
export const getErrorNotificationsByTarget = (state: RootState, target: string): Error[] =>
  filter(state[NAME].errors, (error: Error) => error.target === target);
export const getWarningsByParentType =
  (state: RootState) =>
  (type: string): Warning[] =>
    filter(state[NAME].warnings, (warning: Warning) => warning.type.startsWith(`${type}/`));

const notificationsSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    addErrorNotification(
      state,
      action: PayloadAction<{ message: string; type: string; target: string; change?: string }>,
    ) {
      const id = uuidv1();
      const error: Error = { id, ...action.payload };
      state.errors.push(error);
    },
    removeErrorNotification(state, action: PayloadAction<string>) {
      const errorId = action.payload;
      state.errors = state.errors.filter(({ id }) => id !== errorId);
    },
    removeAllErrorsNotifications(state) {
      state.errors = [];
    },
    addWarningNotification(
      state,
      action: PayloadAction<{ message: string; type: string; target: string; change?: string }>,
    ) {
      const id = uuidv1();
      const warning = { id, ...action.payload };
      state.warnings.push(warning);
    },
    removeWarningNotifications(state, action: PayloadAction<string[]>) {
      const warningIds = action.payload;
      state.warnings = state.warnings.filter(({ id }) => !warningIds.includes(id));
    },
  },
});

export const {
  addErrorNotification,
  removeErrorNotification,
  removeAllErrorsNotifications,
  addWarningNotification,
  removeWarningNotifications,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
