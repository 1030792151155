import { SpotlightTarget as _SpotlightTarget } from '@neo4j-ndl/react';
import { isNonEmptyString } from '@nx/stdlib';
import React from 'react';

type SpotlightTargetProps = React.ComponentPropsWithoutRef<typeof _SpotlightTarget>;
type Props = { id?: string } & Omit<SpotlightTargetProps, 'id'>;

export const SpotlightTarget = (props: Props) => {
  if (isNonEmptyString(props.id)) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return <_SpotlightTarget {...(props as SpotlightTargetProps)} />;
  }

  return props.children;
};
