import type { StatusIndicatorProps } from '@neo4j-ndl/react';
import { IconButton, Menu, StatusIndicator, Tooltip, toast } from '@neo4j-ndl/react';
import {
  Cog6ToothIconOutline,
  DbmsIcon,
  EllipsisHorizontalIconOutline,
  FolderIconOutline,
} from '@neo4j-ndl/react/icons';
import type { Instance, Project } from '@nx/state';
import { ACTION, OpsTypes, usePermissions } from '@nx/state';
import { CopyOnHoverColumn } from '@nx/ui';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useMemo, useRef, useState } from 'react';

import type { Endpoint } from '../../../../../packages/state/src/slices/ops/types';
import { ENDPOINT_TARGET_TYPE } from '../../../../../packages/state/src/slices/ops/types';
import type { EndpointConfigModalContext } from '../modals/endpoint-config-modal';
import { metricsIntegrationApiVersion } from '../shared/constants';

const columnHelper = createColumnHelper<OpsTypes.Endpoint>();

const EndpointStatusTableActionCell = ({
  endPointStatus,
  activeProject,
  cmiDomain,
  viewPrometheusConfig,
  setEndpointConfigContext,
}: {
  endPointStatus: Endpoint;
  activeProject: Project;
  cmiDomain: string;
  viewPrometheusConfig: (id: string | null) => void;
  setEndpointConfigContext: (e: EndpointConfigModalContext) => void;
}) => {
  const { isAllowed } = usePermissions();
  const allowCmiConfigUpdate = isAllowed(ACTION.UPDATE, `namespaces/${activeProject.id}/cmi-config`);

  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const projectUrl = `https://${cmiDomain}/api/${metricsIntegrationApiVersion}/${activeProject.id}/metrics`;
  return (
    <div>
      <div className="flex">
        <IconButton
          className="flex w-8 flex-row-reverse"
          onClick={() => {
            setIsOpen(true);
          }}
          ref={anchorEl}
          ariaLabel={'Endpoint actions'}
          isClean={true}
        >
          <EllipsisHorizontalIconOutline />
        </IconButton>
        {allowCmiConfigUpdate && (
          <IconButton
            className="flex w-8 flex-row-reverse"
            onClick={() => {
              setEndpointConfigContext({ isOpen: true, endpointStatus: endPointStatus });
            }}
            ref={anchorEl}
            ariaLabel={'Configure endpoint'}
            isClean={true}
          >
            <Cog6ToothIconOutline />
          </IconButton>
        )}
      </div>
      <Menu isOpen={isOpen} onClose={() => setIsOpen(false)} anchorRef={anchorEl}>
        <Menu.Items>
          <Menu.Item
            title="Copy endpoint URL"
            onClick={() => {
              // TODO: copy the actual URL
              navigator.clipboard
                .writeText(
                  endPointStatus.type === OpsTypes.ENDPOINT_TARGET_TYPE.PROJECT
                    ? projectUrl
                    : `https://${cmiDomain}/api/${metricsIntegrationApiVersion}/${activeProject.id}/${endPointStatus.id}/metrics`,
                )
                .then(() => {
                  toast.success('Copied endpoint to clipboard!', {
                    shouldAutoClose: true,
                  });
                })
                .catch(() => {
                  toast.danger('Failed to copy endpoint to clipboard!', {
                    shouldAutoClose: true,
                  });
                });
            }}
          />
          <Menu.Item
            title="View Prometheus job configuration"
            onClick={() => {
              if (endPointStatus.type === OpsTypes.ENDPOINT_TARGET_TYPE.PROJECT) {
                viewPrometheusConfig(null);
              } else {
                viewPrometheusConfig(endPointStatus.id);
              }
            }}
          />
        </Menu.Items>
      </Menu>
    </div>
  );
};

export const useStatusTableColumns = (
  activeProject: Project,
  cmiDomain: string,
  instances: Instance[],
  viewPrometheusConfig: (id: string | null) => void,
  setEndpointConfigContext: (e: EndpointConfigModalContext) => void,
) =>
  useMemo(() => {
    return [
      columnHelper.accessor('type', {
        header: () => 'Type',
        cell: ({ row }) => {
          switch (row.original.type) {
            case ENDPOINT_TARGET_TYPE.PROJECT:
              return (
                <div className="flex items-center text-nowrap">
                  <FolderIconOutline className="mr-1 h-[24px] w-[24px]" /> <span>{row.original.type}</span>
                </div>
              );
            case ENDPOINT_TARGET_TYPE.INSTANCE:
              return (
                <div className="flex items-center text-nowrap">
                  <DbmsIcon className="mr-1 h-[24px] w-[24px]" /> <span>{row.original.type}</span>
                </div>
              );
            default:
              return null;
          }
        },
      }),
      columnHelper.accessor('target', {
        header: () => 'Endpoint target',
      }),
      columnHelper.accessor('id', {
        header: () => 'ID',
        cell: ({ row }) => <CopyOnHoverColumn value={row.original.id} />,
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        cell({ row }) {
          let type: StatusIndicatorProps['type'] = 'unknown';
          switch (row.original.status) {
            case OpsTypes.STATUS.IN_USE:
              type = 'success';
              break;
            case OpsTypes.STATUS.ERROR:
              type = 'danger';
              break;
            case OpsTypes.STATUS.NOT_IN_USE:
            default:
              type = 'unknown';
          }
          return (
            <div>
              {row.original.status === OpsTypes.STATUS.ERROR ? (
                <Tooltip type="simple">
                  <Tooltip.Trigger>
                    <StatusIndicator type={type} /> <span className="pl-1">{row.original.status}</span>
                  </Tooltip.Trigger>
                  <Tooltip.Content>{row.original.lastErrorText}</Tooltip.Content>
                </Tooltip>
              ) : (
                <>
                  <StatusIndicator type={type} /> <span className="pl-1">{row.original.status}</span>
                </>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor('lastActive', {
        header: () => 'Last active',
      }),
      columnHelper.accessor('metricCount', {
        header: () => 'Metrics count',
      }),
      columnHelper.accessor('dataVolume', {
        header: () => 'Data volume',
      }),
      columnHelper.accessor('requestRate', {
        header: () => 'Request rate',
      }),
      columnHelper.display({
        id: 'actions',
        cell: ({ row }) => (
          <EndpointStatusTableActionCell
            endPointStatus={row.original}
            activeProject={activeProject}
            cmiDomain={cmiDomain}
            viewPrometheusConfig={viewPrometheusConfig}
            setEndpointConfigContext={setEndpointConfigContext}
          />
        ),
      }),
    ];
  }, [activeProject, cmiDomain, setEndpointConfigContext, viewPrometheusConfig]);
