import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../../store';
import * as GuidePlaylists from './guide-playlists-slice';
import type { GuidesState } from './guides-slice';

const selectCurrentGuideOutput = (guidesState: GuidesState) => {
  const { guides, currentGuideId, fetchStatus, fetchError } = guidesState;

  if (currentGuideId === undefined || guides[currentGuideId] === undefined) {
    return {
      guide: undefined,
      fetchStatus,
      fetchError,
    };
  }

  return {
    guide: guides[currentGuideId],
    fetchStatus,
    fetchError,
  };
};

const selectCurrentGuideStatusOutput = (guidesState: GuidesState) => {
  const { guidesStatuses, currentGuideId: currentGuide } = guidesState;

  if (currentGuide === undefined || guidesStatuses[currentGuide] === undefined) {
    return undefined;
  }

  return guidesStatuses[currentGuide];
};

export const selectCurrentGuideStatus: (state: RootState) => ReturnType<typeof selectCurrentGuideStatusOutput> =
  createSelector((state: RootState) => state.guides, selectCurrentGuideStatusOutput);

export const selectCurrentGuide: (state: RootState) => ReturnType<typeof selectCurrentGuideOutput> = createSelector(
  (state: RootState) => state.guides,
  selectCurrentGuideOutput,
);

export const selectCurrentPlaylist: (state: RootState) => ReturnType<typeof GuidePlaylists.selectCurrentPlaylist> =
  createSelector((state: RootState) => state.guidePlaylists, GuidePlaylists.selectCurrentPlaylist);

export const selectPlaylistsBaseUrl: (state: RootState) => ReturnType<typeof GuidePlaylists.selectBaseUrl> =
  createSelector((state: RootState) => state.guidePlaylists, GuidePlaylists.selectBaseUrl);
