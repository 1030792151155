import { createAction } from '@reduxjs/toolkit';

export const clearModel = createAction('model/clearModel');

export const deleteNodesAndRelationships = createAction<{ nodeIds: string[]; relationshipIds: string[] }>(
  'model/deleteNodesAndRelationships',
);

export const addNode = createAction<{ newNodeId: string; label?: string }>('model/addNode');

export const addNodeAndRelationship = createAction<{
  sourceNodeId: string;
  targetNodeId: string;
  targetNodePosition: { x: number | undefined; y: number | undefined };
  newRelationshipId: string;
}>('model/addNodeAndRelationship');

export const addRelationship = createAction<{ sourceNodeId: string; targetNodeId: string; newRelationshipId: string }>(
  'model/addRelationship',
);
