import type { GuidePlaylist, ManifestFile } from '@neo4j-devtools/workspace-guides';
import { createSlice } from '@reduxjs/toolkit';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

import { customCreateApi } from '../../context';
import { selectNetworkPolicies } from '../../selectors';
import type { RootState } from '../../store';

export interface GuidePlaylistsState {
  baseUrl: string | undefined;
  currentPlaylist: string;
}

export const GUIDE_PLAYLISTS_PERSISTED_KEYS: (keyof GuidePlaylistsState)[] = ['baseUrl', 'currentPlaylist'];

const withoutTrailingSlash = (url: string) => url.replace(/\/$/, '');
const withoutLeadingSlash = (url: string) => url.replace(/^\//, '');

function joinUrls(base: string | undefined, url: string): string {
  if (base === undefined) {
    return url;
  }

  if (/https?:\/\//.test(url)) {
    return url;
  }

  const delimiter = base.endsWith('/') || !url.startsWith('?') ? '/' : '';
  const normalizedBase = withoutTrailingSlash(base);
  const normalizedUrl = withoutLeadingSlash(url);

  return `${normalizedBase}${delimiter}${normalizedUrl}`;
}

const initialState: GuidePlaylistsState = {
  baseUrl: undefined,
  currentPlaylist: 'beginner',
};

export function selectBaseUrl(state: GuidePlaylistsState): GuidePlaylistsState['baseUrl'] {
  return state.baseUrl;
}

export function selectCurrentPlaylist(state: GuidePlaylistsState): GuidePlaylistsState['currentPlaylist'] {
  return state.currentPlaylist;
}

const guidePlaylistsSlice = createSlice({
  name: 'guidePlaylists',
  initialState,
  reducers: {
    baseUrlChanged(state, action: { payload: string }) {
      state.baseUrl = action.payload;
      return state;
    },
    baseUrlReset(state) {
      state.baseUrl = undefined;
      return state;
    },
    playlistChanged(state, action: { payload: string }) {
      state.currentPlaylist = action.payload;
      return state;
    },
  },
});

export default guidePlaylistsSlice.reducer;

export const {
  baseUrlChanged: changeBaseUrl,
  baseUrlReset: resetBaseUrl,
  playlistChanged: changePlaylist,
} = guidePlaylistsSlice.actions;

const rawBaseQuery = fetchBaseQuery({
  baseUrl: 'https://neo4j.github.io/workspace-guides/',
});

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = (args, api, extraOptions) => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const state = api.getState() as RootState;
  const networkPolicies = selectNetworkPolicies(state);
  if (!networkPolicies.outgoingConnectionsAllowed) {
    return {
      error: {
        status: 'CUSTOM_ERROR',
        error: `Outgoing connections are disabled by ${
          networkPolicies.policySource === 'dbms' ? 'your neo4j.conf file' : 'your application settings'
        }.`,
      },
    };
  }

  const baseUrl = selectBaseUrl(state.guidePlaylists);

  const adjustedArgs =
    typeof args === 'string' ? joinUrls(baseUrl, args) : { ...args, url: joinUrls(baseUrl, args.url) };

  // provide the amended url and other params to the raw base query
  return rawBaseQuery(adjustedArgs, api, extraOptions);
};

type ArgWithBaseUrl<T> = { arg: T; baseUrl: string };
type MaybeArgWithBaseUrl<T = undefined> = T | ArgWithBaseUrl<T>;

function isArgWithBaseUrl<T>(input: MaybeArgWithBaseUrl<T>): input is ArgWithBaseUrl<T> {
  return (
    input !== null &&
    typeof input === 'object' &&
    'arg' in input &&
    'baseUrl' in input &&
    typeof input.baseUrl === 'string'
  );
}

function parseArgWithBaseUrl<T>(argToUrl: (arg: T) => string, input: MaybeArgWithBaseUrl<T>): { arg: T; url: string } {
  if (isArgWithBaseUrl(input)) {
    return {
      arg: input.arg,
      url: joinUrls(input.baseUrl, argToUrl(input.arg)),
    };
  }

  return {
    arg: input,
    url: argToUrl(input),
  };
}

export const guidePlaylistsApi = customCreateApi({
  baseQuery: dynamicBaseQuery,
  endpoints: (build) => ({
    getManifest: build.query<ManifestFile, MaybeArgWithBaseUrl>({
      query: (maybeArgWithBaseUrl) => {
        const { url } = parseArgWithBaseUrl(() => `/index.json`, maybeArgWithBaseUrl);

        return url;
      },
    }),
    getPlaylist: build.query<GuidePlaylist, MaybeArgWithBaseUrl<string>>({
      query: (maybeArgWithBaseUrl) => {
        const { url } = parseArgWithBaseUrl((arg) => `/${arg}.playlist.json`, maybeArgWithBaseUrl);

        return url;
      },
    }),
  }),
  reducerPath: 'guidePlaylistsApi',
});

export function selectManifest(state: {
  [guidePlaylistsApi.reducerPath]: ReturnType<typeof guidePlaylistsApi.reducer>;
}) {
  return guidePlaylistsApi.endpoints.getManifest.select(undefined)(state).data;
}

export const { useGetManifestQuery, useGetPlaylistQuery, useLazyGetManifestQuery, useLazyGetPlaylistQuery } =
  guidePlaylistsApi;
