import { Banner, Button, Dialog, LoadingSpinner, Select, TextLink, Typography } from '@neo4j-ndl/react';
import type { Instance, Project, TrafficConfig } from '@nx/state';
import { TIER, TRAFFIC_ENABLEMENT, consoleApi, getApiError } from '@nx/state';
import { isNonEmptyString, isNotNullish, isNullish } from '@nx/stdlib';
import { CopyTextInput } from '@nx/ui';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { ComponentProps, ReactNode } from 'react';
import { useMemo, useState } from 'react';
import * as yup from 'yup';

import type { Validation } from '../../utils/validation';
import { validateYup } from '../../utils/validation';
import { getProductFromTier, getRegionsForTier } from '../entities/helpers';
import { ConfirmCheckbox, ConnectionRequestAcceptor, PrivateConnection, VpnMessage } from './shared';
import type { RegionOption, TierOption } from './utils';

type FormData = {
  tier?: TIER;
  region?: string;
  privateTraffic: TRAFFIC_ENABLEMENT;
  publicTraffic: TRAFFIC_ENABLEMENT;
  endpointIds: string[];
  endpointIdField: string;
};

const schema = yup.object({
  tier: yup.string().oneOf(Object.values(TIER)).required().label('Instance Type'),
  region: yup.string().required().label('Region'),
  privateTraffic: yup.string().oneOf(Object.values(TRAFFIC_ENABLEMENT)).required(),
  publicTraffic: yup.string().oneOf(Object.values(TRAFFIC_ENABLEMENT)).required(),
  endpointIds: yup.array().required().of(yup.string().min(1).label('AWS Endpoint ID')),
});

const validate = (data: FormData): Validation<FormData> | null => {
  return validateYup(schema, data);
};

const defaults = (trafficConfig?: TrafficConfig): FormData => ({
  tier: trafficConfig?.tier,
  region: trafficConfig?.region,
  endpointIds: trafficConfig?.awsProperties ? trafficConfig.awsProperties.endpointIds : [''],
  endpointIdField: '',
  privateTraffic: trafficConfig?.privateTraffic ?? TRAFFIC_ENABLEMENT.ENABLED,
  publicTraffic: trafficConfig?.publicTraffic ?? TRAFFIC_ENABLEMENT.ENABLED,
});

type AWSTrafficConfigProps = {
  project: Project;
  trafficConfig?: TrafficConfig;
  existingTrafficConfigs?: TrafficConfig[];
  existingTierRegions?: Partial<Record<TIER, string[]>>;
  onClose: () => void;
  onSuccess?: (trafficConfig: TrafficConfig) => void;
  instances: Instance[];
};

/**
 * This dialog is quite complicated, so to try to simplify things
 * a bit, it has no `open` prop. The consumer should just unmount
 * and remount it. This helps make managing state easier
 * as there is no chance of bleeding state between dialog open/closes
 */
export const AWSTrafficConfigDialog = ({
  project,
  trafficConfig,
  onClose,
  onSuccess,
  instances,
  // Only needed in create mode
  // Used to prepopulate the props
  // when creating
  existingTrafficConfigs = [],
  // Only needed in create mode
  // Used to determine if a tier/region combo
  // is already configured
  existingTierRegions = {},
}: AWSTrafficConfigProps) => {
  const editMode = isNotNullish(trafficConfig);
  const [data, setData] = useState<FormData>(() => defaults(trafficConfig));
  const [validation, setValidation] = useState<Validation<FormData>>({});
  const [enableError, setEnableError] = useState<string | null>(null);
  // Default to step 2 if we've already done the initial create
  const [step, setStep] = useState(trafficConfig ? 2 : 1);
  const [confirm, setConfirm] = useState(false);
  const [endpointConnectionMessage, setEndpointConnectionMessage] = useState<{
    message: ReactNode;
    messageType: ComponentProps<typeof Banner>['type'];
  } | null>(null);
  const [updateTrafficConfig, updateTrafficConfigRes] = consoleApi.useUpdateTrafficConfigMutation();
  const affectedInstances = useMemo(() => {
    if (isNotNullish(data.region) && isNotNullish(data.tier) && data.publicTraffic === TRAFFIC_ENABLEMENT.DISABLED) {
      return instances.filter((instance) => instance.region === data.region && instance.tier === data.tier);
    }
    return [];
  }, [data.publicTraffic, data.region, data.tier, instances]);
  const handleClose = () => {
    onClose();
  };

  const handleNextStep = () => setStep(Math.min(step + 1, 4));
  const handlePreviousStep = () => setStep(Math.max(step - 1, 1));

  const handleTierChange = (option: TierOption) => {
    setData((prev) => ({ ...prev, tier: option.value, region: undefined }));
  };
  const handleRegionChange = (option: RegionOption) => {
    const newRegion = option.value;
    setData((prev) => {
      const existingTrafficConfig = existingTrafficConfigs.find((c) => c.tier === prev.tier && c.region === newRegion);
      // Existing traffic config may be undefined
      // if for example in dev environments you use the same
      // isolation id for enterprisedb and enterpriseds
      const existingEndpointIds = existingTrafficConfig?.awsProperties?.endpointIds ?? [];
      return {
        ...prev,
        region: newRegion,
        endpointIds: existingEndpointIds,
      };
    });
  };

  const handleSubmitWithData = (toSubmit: FormData, { close = false, next = true } = {}) => {
    const errors = validate(toSubmit);
    if (errors) {
      setValidation(errors);
      return;
    }
    setEnableError(null);
    setValidation({});
    if (isNullish(toSubmit.tier) || isNullish(toSubmit.region)) {
      return;
    }

    updateTrafficConfig({
      projectId: project.id,
      tier: toSubmit.tier,
      region: toSubmit.region,
      privateTraffic: toSubmit.privateTraffic,
      publicTraffic: toSubmit.publicTraffic,
      awsProperties: { endpointIds: toSubmit.endpointIds },
    })
      .unwrap()
      .then((res) => {
        if (onSuccess) {
          onSuccess(res);
        }
        if (next) {
          handleNextStep();
        }
        if (close) {
          handleClose();
        }
      })
      .catch((err: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(err);
        if (error.code === 422) {
          setEnableError(error.message ?? 'Failed to update network security configuration');
        } else {
          setEnableError('Failed to update network security configuration');
        }
      });
  };

  const handleEndpointIdFieldChange = (value: string) => {
    setEndpointConnectionMessage(null);
    const newValue = value;
    setData((prev) => {
      return { ...prev, endpointIdField: newValue };
    });
  };
  const handleAddEndpointId = () => {
    setData((prev) => {
      const endpointConnection = trafficConfig?.status.awsStatus?.endpointConnections.find(
        (conn) => conn.endpointId === prev.endpointIdField,
      );

      if (!endpointConnection) {
        setEndpointConnectionMessage({
          message:
            'Endpoint connection request could not be found. Please follow the instructions on the previous step to create an endpoint connection request, and/or check that your endpoint id is correct.',
          messageType: 'danger',
        });
        return prev;
      }

      if (prev.endpointIds.includes(endpointConnection.endpointId)) {
        setEndpointConnectionMessage({
          message: (
            <>
              The endpoint connection accept request for <b>{endpointConnection.endpointId}</b> has already been sent.
              The setup can take a couple of minutes.
            </>
          ),
          messageType: 'info',
        });
        return prev;
      }

      const newData = {
        ...prev,
        endpointIds: [...prev.endpointIds, prev.endpointIdField],
        endpointIdField: '',
      };

      handleSubmitWithData(newData, { next: false });

      setEndpointConnectionMessage({
        message: (
          <>
            The endpoint connection accept request for <b>{endpointConnection.endpointId}</b> has been sent. The setup
            can take a couple of minutes.
          </>
        ),
        messageType: 'success',
      });

      return newData;
    });
  };

  const handleConfirmChange = (checked: boolean) => {
    setConfirm(checked);
  };

  const handlePublicTrafficDisabledChange = (checked: boolean) => {
    const newValue = !checked;
    setData((prev) => ({
      ...prev,
      publicTraffic: newValue ? TRAFFIC_ENABLEMENT.ENABLED : TRAFFIC_ENABLEMENT.DISABLED,
    }));
  };

  const handleSubmit = (close = false) => {
    handleSubmitWithData(data, { close });
  };

  const handleFinishLater = () => {
    onClose();
  };

  const handleSubmitAndClose = () => handleSubmit(true);

  const regionOptions = useMemo(() => {
    if (isNullish(data.tier)) {
      return [];
    }
    const alreadyConfiguredRegions = existingTierRegions[data.tier] ?? [];
    const regionsToConfigure = existingTrafficConfigs.filter((tc) => tc.tier === data.tier).map((tc) => tc.region);
    const regions = getRegionsForTier(data.tier, project.tierConfigs).filter(
      (r) => !alreadyConfiguredRegions.includes(r.name) && regionsToConfigure.includes(r.name),
    );
    return regions.map((r) => ({
      key: r.name,
      label: r.friendly,
      value: r.name,
    }));
  }, [data.tier, existingTierRegions, existingTrafficConfigs, project.tierConfigs]);

  const tierOptions = useMemo(() => {
    const tiers = Array.from(new Set(existingTrafficConfigs.map((config) => config.tier)));
    return tiers
      .filter((t) => [TIER.ENTERPRISE, TIER.AURA_DSE].includes(t))
      .map((tier) => {
        return {
          key: tier,
          value: tier,
          label: getProductFromTier(tier),
        };
      });
  }, [existingTrafficConfigs]);

  const endpointServiceName = trafficConfig?.status.awsStatus?.endpointServiceName;
  const endpointConnections = trafficConfig?.status.awsStatus?.endpointConnections ?? [];
  const configCreating = !trafficConfig || !isNonEmptyString(endpointServiceName);

  return (
    <>
      <Dialog.Content className="flex flex-col gap-4">
        <p>Step {step} of 4</p>
        {step === 1 && (
          <>
            <Select
              size="medium"
              type="select"
              label="Instance Type"
              selectProps={{
                options: tierOptions,
                value: tierOptions.find((t) => t.value === data.tier),
                onChange: (value) => value && handleTierChange(value),
                isDisabled: editMode,
              }}
              errorText={validation.tier?.message}
              htmlAttributes={{ 'data-testid': 'aws-dialog-select-product' }}
            />

            {regionOptions.length === 0 && isNotNullish(data.tier) && (
              <Banner
                type="warning"
                title="All regions configured"
                description={`All ${getProductFromTier(data.tier)} regions have been configured.`}
                usage="inline"
              />
            )}
            {regionOptions.length > 0 && (
              <Select
                size="medium"
                type="select"
                label="Region"
                helpText="AWS PrivateLink applies to all instances in the region."
                selectProps={{
                  options: regionOptions,
                  value: regionOptions.find((r) => r.value === data.region),
                  onChange: (value) => value && handleRegionChange(value),
                  isDisabled: editMode,
                }}
                errorText={validation.region?.message}
                htmlAttributes={{ 'data-testid': 'aws-dialog-select-region' }}
              />
            )}
          </>
        )}
        {data.region === 'us-east-1' && (
          <Banner
            description="In AWS region `us-east-1`, we do not support the Availability Zone with ID `use1-az3` for private endpoints. If you have workloads running in Availability Zone `use1-az3`, they will not be able to connect directly to the PrivateLink Endpoint and will rely on cross-zone routing."
            usage="inline"
          />
        )}
        {isNotNullish(enableError) && <Banner description={enableError} type="danger" usage="inline" />}

        {step === 2 && (
          <>
            {configCreating && (
              <Banner
                title={
                  <div className="flex items-center gap-2">
                    <LoadingSpinner size="small" />
                    Configuring...
                  </div>
                }
                description="PrivateLink is being configured..."
                usage="inline"
              />
            )}
            {!configCreating && (
              <>
                <Banner hasIcon type="success" title="Accepted" usage="inline" />
                <CopyTextInput label="Endpoint Service Name" value={endpointServiceName} isPortaled={false} />
              </>
            )}
            <div className="mt-2">
              <h6>
                <span className="font-normal">Create a VPC Endpoint</span>
              </h6>
              <ol className="console-network-instructions ml-8 mt-3 list-decimal">
                <li>
                  Log in to your AWS console{' '}
                  <TextLink
                    href="https://aws.amazon.com/console/"
                    isExternalLink
                    htmlAttributes={{ rel: 'noreferrer' }}
                  >
                    https://aws.amazon.com/console/
                  </TextLink>
                </li>
                <li>
                  Navigate to the VPC Service by searching in the global search bar for <i>VPC</i>, or navigating to{' '}
                  <b>Services</b> &gt; <b>Networking & Content Delivery</b> &gt; <b>VPC</b>.
                </li>
                <li>
                  Select the <b>Endpoints</b> item in the side menu.
                </li>
                <li>
                  Click <b>Create Endpoint</b>.
                </li>

                <li>
                  Set <b>Type</b> to <b>PrivateLink Ready partner services</b>.
                </li>
                {configCreating && (
                  <li>
                    Once PrivateLink is done configuring, set <b>Service name</b> to the Endpoint Service Name.
                  </li>
                )}
                {!configCreating && (
                  <li>
                    Set <b>Service name</b> to <i>{endpointServiceName}</i>.
                  </li>
                )}
                <li>
                  Set <b>VPC</b> to the VPC you want to connect to your Neo4j instances from.
                  <ul className="ml-6 list-[lower-alpha]">
                    <li>
                      For <b>Subnets</b>, select at least one subnet. It is recommended to select 3 subnets, one in
                      every Availability Zone to prevent a single point of failure.
                    </li>
                    <li>
                      For <b>VPC Security group</b>, select a Security group that allows ports 80, 443, and 7687.
                    </li>
                  </ul>
                </li>

                <li>
                  Click <b>Create endpoint</b>.
                </li>
                <li>
                  Save <b>VPC endpoint ID</b> of the newly created endpoint. It will be used in the next step.
                </li>
              </ol>
              <Typography variant="body-medium" className="mt-4" as="div">
                *{' '}
                <TextLink
                  href="https://docs.aws.amazon.com/vpc/latest/privatelink/create-interface-endpoint.html"
                  isExternalLink
                >
                  <i>AWS PrivateLink documentation</i>
                </TextLink>
              </Typography>
            </div>
          </>
        )}
        {step === 3 && (
          <div className="flex flex-col gap-8">
            <ConnectionRequestAcceptor
              value={data.endpointIdField}
              onChange={handleEndpointIdFieldChange}
              onAccept={handleAddEndpointId}
              inputErrorText={validation.endpointIdField?.message}
              acceptMessage={endpointConnectionMessage}
              loading={updateTrafficConfigRes.isLoading}
              connectionRequests={endpointConnections.map((c) => ({
                id: c.endpointId,
                state: c.state,
              }))}
            />
            <div>
              <h6>
                <span className="font-normal">Enable Private DNS in AWS console</span>
              </h6>
              <ol className="console-network-instructions ml-8 mt-3 list-decimal">
                <li>
                  Log in to your AWS console{' '}
                  <TextLink
                    href="https://aws.amazon.com/console/"
                    isExternalLink
                    htmlAttributes={{ rel: 'noreferrer' }}
                  >
                    https://aws.amazon.com/console/
                  </TextLink>
                </li>
                <li>
                  Navigate to the VPC Service by searching in the global search bar for <i>VPC</i>, or navigating to{' '}
                  <b>Services</b> &gt; <b>Networking & Content Delivery</b> &gt; <b>VPC</b>.
                </li>
                <li>
                  Select the <b>Endpoints</b> item in the side menu.
                </li>
                <li>
                  Select the newly accepted endpoint from the table. This endpoint should have been accepted using the
                  above form.
                </li>

                <li>
                  Click <b>Actions</b>.
                </li>
                <li>
                  Click <b>Modify private DNS name</b>.
                </li>
                <li>
                  Under <b>Modify private DNS name settings</b> &gt; <b>Enable private DNS names</b>, check{' '}
                  <b>Enable for this endpoint</b>.
                </li>
                <li>
                  Click <b>Save changes</b>.
                </li>
              </ol>
              <Typography variant="body-medium" className="mt-4" as="div">
                The configuration should now be complete. Once you have an Aura instance created you should be able to
                test the connection using{' '}
                <TextLink
                  href="https://support.neo4j.com/s/article/13174783967507-How-To-Test-Connectivity-Through-The-Private-Endpoint"
                  isExternalLink
                >
                  this guide.
                </TextLink>
              </Typography>
              <Typography variant="body-medium" className="mt-4" as="div">
                *{' '}
                <TextLink
                  href="https://docs.aws.amazon.com/vpc/latest/privatelink/interface-endpoints.html#enable-private-dns-names"
                  isExternalLink
                >
                  <i>AWS PrivateLink documentation</i>
                </TextLink>
              </Typography>
            </div>
          </div>
        )}
        {step === 4 && (
          <>
            <PrivateConnection
              publicTraffic={data.publicTraffic}
              dnsDomain={trafficConfig?.status.dnsDomain}
              onPublicTrafficDisabledChange={handlePublicTrafficDisabledChange}
              affectedInstances={affectedInstances}
            />
            <VpnMessage href="https://neo4j.com/docs/aura/platform/security/#_aws_private_endpoints" />
            <ConfirmCheckbox isConfirmed={confirm} onConfirmChange={handleConfirmChange} />
          </>
        )}
      </Dialog.Content>
      <Dialog.Actions className="justify-between">
        <Button
          onClick={handleFinishLater}
          fill="outlined"
          htmlAttributes={{
            'data-testid': 'aws-dialog-finish-later',
          }}
        >
          Finish later
        </Button>
        {step === 1 && !editMode && (
          <Button
            onClick={() => handleSubmit()}
            fill="filled"
            isLoading={updateTrafficConfigRes.isLoading}
            isDisabled={isNullish(data.region) || isNullish(data.tier)}
            htmlAttributes={{ 'data-testid': 'aws-dialog-enable-privatelink' }}
          >
            Enable PrivateLink
          </Button>
        )}
        {step === 1 && editMode && (
          <Button onClick={handleNextStep} fill="filled" htmlAttributes={{ 'data-testid': 'aws-dialog-next' }}>
            Next
          </Button>
        )}
        {step === 2 && (
          <div className="flex gap-2">
            <Button onClick={handlePreviousStep} fill="outlined">
              Back
            </Button>
            <Button
              onClick={handleNextStep}
              fill="filled"
              isDisabled={isNullish(endpointServiceName)}
              htmlAttributes={{ 'data-testid': 'aws-dialog-next' }}
            >
              Next
            </Button>
          </div>
        )}
        {step === 3 && (
          <div className="flex gap-2">
            <Button onClick={handlePreviousStep} fill="outlined">
              Back
            </Button>
            <Button onClick={handleNextStep} fill="filled" htmlAttributes={{ 'data-testid': 'aws-dialog-next' }}>
              Next
            </Button>
          </div>
        )}
        {step === 4 && (
          <div className="flex gap-2">
            <Button onClick={handlePreviousStep} fill="outlined">
              Back
            </Button>
            <Button
              onClick={handleSubmitAndClose}
              fill="filled"
              isLoading={updateTrafficConfigRes.isLoading}
              isDisabled={!confirm}
              htmlAttributes={{ 'data-testid': 'aws-dialog-save' }}
            >
              Save
            </Button>
          </div>
        )}
      </Dialog.Actions>
    </>
  );
};
