import { isAnyOf } from '@reduxjs/toolkit';
import { difference, isNil } from 'lodash-es';
import { filter, flatMap, get, isEmpty, keyBy, mapValues, negate, pipe } from 'lodash/fp';

import { hasActiveStyleRule } from '../../modules/Legend/Popups/RuleBasedStyling/helpers';
import { getSortedCaptionKeys } from '../../modules/Legend/Popups/helpers';
import type { Perspective, PerspectiveCategory, PerspectiveRelationshipType } from '../../types/perspective';
import type { Style, StyleRule } from '../../types/style';
import { updateInventory } from '../graph/graph.actions';
import {
  addCategoriesToPerspective,
  addCategoryToPerspective,
  addLabelToCategory,
  addLabelsAsCategoriesToPerspective,
  addRelationshipsToPerspective,
  getCategories,
  getCategoryById,
  getRelationshipDetails,
  loadPerspective,
  removeCategoryFromPerspective,
  removeLabelFromCategory,
  removeRelationshipsFromPerspective,
} from '../perspectives/perspectives';
import { getPerspectiveById } from '../perspectives/perspectives.selector';
import type { AppDispatch, AppMiddleware, RootState } from '../types';
import { findCaptionProperty } from './styleMiddleware.utils';
import {
  STYLE_TYPE_SCENE,
  addCategories,
  addRelationshipTypes,
  forceRemoveCaptionKeyForCategory,
  forceRemoveCaptionKeyForRelType,
  getCategoriesWithCurrentStyle,
  getCurrentStyle,
  getRelationshipTypesWithCurrentStyle,
  getStyleById,
  removeCategories,
  removeRelationshipTypes,
  setCaptionsForCategory,
  updateStyleRuleForCategory,
  updateStyleRuleForRelationship,
} from './styles';
import { _OTHER } from './styles.const';
import { CAPTION_TYPE_CATEGORY, CAPTION_TYPE_LABEL, CAPTION_TYPE_PROPERTY, CAPTION_TYPE_REL } from './types';

const styleMiddleWare: AppMiddleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    const nextResult = next(action);
    const state = getState();
    if (
      isAnyOf(
        addCategoryToPerspective,
        addCategoriesToPerspective,
        addLabelsAsCategoriesToPerspective,
        removeCategoryFromPerspective,
        addRelationshipsToPerspective,
        removeRelationshipsFromPerspective,
        loadPerspective,
      )(action)
    ) {
      const perspectiveId = loadPerspective.match(action) ? action.payload.id : action.payload.perspectiveId;
      const perspective = loadPerspective.match(action) ? action.payload : getPerspectiveById(state)(perspectiveId);
      const perspectiveStyle = getStyleById(state)(perspectiveId);
      const currentStyle = getCurrentStyle(state);
      const isCurrentStyleAScene = currentStyle?.type === STYLE_TYPE_SCENE;
      const changingCurrentPerspective = !loadPerspective.match(action) && perspectiveId === state.currentPerspectiveId;

      if (perspectiveStyle !== undefined && perspective !== undefined) {
        mapPerspectiveToStyle(perspective, perspectiveStyle, dispatch);
      }

      if (changingCurrentPerspective && isCurrentStyleAScene && perspective !== undefined) {
        mapPerspectiveToStyle(perspective, currentStyle, dispatch);
      }
    }

    // set captions when auto generate perspective
    if (isAnyOf(addCategoriesToPerspective, addLabelsAsCategoriesToPerspective)(action)) {
      const { perspectiveId, newCategories = [] } = action.payload;

      for (const category of newCategories) {
        setupInitialCaptionKeysForNode(category as unknown as PerspectiveCategory, perspectiveId, dispatch);
      }
    }

    // set captions when adding label to category
    if (isAnyOf(addLabelToCategory, removeLabelFromCategory)(action)) {
      const currentStyle = getCurrentStyle(state);
      const category = getCategoryById(state)(action.payload.categoryId);

      if (category) {
        setupInitialCaptionKeysForNode(category, currentStyle?.id, dispatch);
      }
    }

    if (updateInventory.match(action)) {
      refreshCaptions(state, dispatch);
    }

    return nextResult;
  };

export const mapPerspectiveToStyle = (perspective: Perspective, style: Style, dispatch: AppDispatch) => {
  const perspCategoryIds = perspective.categories?.map(({ id }) => id) ?? [];
  const styleCategoryIds = style.categories?.map(({ id }) => id) ?? [];
  const perspRelationshipTypes = perspective.relationshipTypes?.map(({ id }) => id) ?? [];
  const styleRelationshipTypes = style.relationshipTypes?.map(({ id }) => id) ?? [];

  const newCategoriesIds = difference(perspCategoryIds, styleCategoryIds);
  const removedCategoriesIds = difference(styleCategoryIds, perspCategoryIds);
  const newRelationshipTypes = difference(perspRelationshipTypes, styleRelationshipTypes);
  const removedRelationshipTypes = difference(styleRelationshipTypes, perspRelationshipTypes);

  newCategoriesIds.length && dispatch(addCategories({ categorieIds: newCategoriesIds, styleId: style.id }));
  removedCategoriesIds.length && dispatch(removeCategories({ categorieIds: removedCategoriesIds, styleId: style.id }));
  newRelationshipTypes.length &&
    dispatch(addRelationshipTypes({ relationshipTypes: newRelationshipTypes, styleId: style.id }));
  removedRelationshipTypes.length &&
    dispatch(removeRelationshipTypes({ relationshipTypes: removedRelationshipTypes, styleId: style.id }));

  const categoryProperties = pipe(
    flatMap(({ id, properties }: PerspectiveCategory) => properties?.map((prop) => ({ catId: id, prop }))),
    filter(negate(isEmpty)),
    keyBy(({ catId, prop }) => `${catId}_${prop.name}_${prop.dataType}`),
    mapValues(get('prop')),
  )(perspective.categories);

  const relTypeProperties = pipe(
    flatMap(({ id, properties }: PerspectiveRelationshipType) => properties?.map((prop) => ({ id, prop }))),
    filter(negate(isEmpty)),
    keyBy(({ id, prop }) => `${id}_${prop.propertyKey}_${prop.dataType}`),
    mapValues(get('prop')),
  )(perspective.relationshipTypes);

  const getActiveStyleRules = pipe(
    flatMap(({ id, styleRules }: { id: string | number; styleRules: StyleRule[] }) =>
      styleRules?.map((rule, index) => ({ id, rule, index })),
    ),
    filter(negate(isEmpty)),
    filter(({ rule }) => hasActiveStyleRule(rule)),
  );

  for (const { id, rule, index } of getActiveStyleRules(style.categories)) {
    const { isGdsRule, basedOn } = rule;
    if (isGdsRule || basedOn === _OTHER || removedCategoriesIds.includes(id)) {
      continue;
    }
    const prop = categoryProperties[`${id}_${basedOn}`];
    if (!prop || prop.exclude) {
      dispatch(
        updateStyleRuleForCategory({
          styleRuleIndex: index,
          categoryId: id,
          styleId: style.id,
          styleRuleUpdate: {
            applyColor: false,
            applySize: false,
            applyCaption: false,
          },
        }),
      );
    }
  }

  for (const { id, rule, index } of getActiveStyleRules(style.relationshipTypes)) {
    const prop = relTypeProperties[`${id}_${rule.basedOn}`];
    if (!prop) {
      dispatch(
        updateStyleRuleForRelationship({
          styleRuleIndex: index,
          relType: id,
          styleId: style.id,
          styleRuleUpdate: {
            applyColor: false,
            applySize: false,
            applyCaption: false,
          },
        }),
      );
    }
  }
};

export const setupInitialCaptionKeysForNode = (
  category: PerspectiveCategory,
  styleId: Style['id'] | undefined,
  dispatch: AppDispatch,
) => {
  // set up caption only when adding the 1st label to category
  if (category.labels.length !== 1) {
    return;
  }

  const captionCandidates = getSortedCaptionKeys({
    ...category,
    type: CAPTION_TYPE_CATEGORY,
  });
  const propertyKeys = category.properties.filter((property) => !property.exclude).map((property) => property.name);
  const inferredCaption = findCaptionProperty(propertyKeys);

  if (!isEmpty(inferredCaption)) {
    const matchedCaption = captionCandidates.find((caption) => {
      return caption.key === inferredCaption;
    });

    if (!isNil(matchedCaption)) {
      matchedCaption.isCaption = true;
      matchedCaption.inTooltip = true;
    }
  }

  dispatch(setCaptionsForCategory({ categoryId: category.id, newCaptions: captionCandidates, styleId }));
};

export const refreshCaptions = (state: RootState, dispatch: AppDispatch) => {
  const categories = getCategories(state);
  const categoryStyles = getCategoriesWithCurrentStyle(state);
  const relationshipTypes = getRelationshipDetails(state);
  const relationshipTypeStyles = getRelationshipTypesWithCurrentStyle(state);
  const styleId = getCurrentStyle(state)?.id;

  for (const categoryStyle of categoryStyles) {
    const captionsToRemove = [];
    const category = categories.find(({ id }) => id === categoryStyle.id);

    for (const caption of categoryStyle.captions) {
      if (caption.type === CAPTION_TYPE_LABEL) {
        // remove the label key, if the label has been removed from category
        if (!categoryStyle.labels.includes(caption.key as string)) {
          captionsToRemove.push(caption);
        }
      } else if (caption.type === CAPTION_TYPE_PROPERTY) {
        // remove the property key, if the property has been removed from the label
        const propertyFound = category?.properties.find(({ name }) => name === caption.key);
        if (!propertyFound) {
          captionsToRemove.push(caption);
        }
      }
    }

    for (const caption of captionsToRemove) {
      dispatch(forceRemoveCaptionKeyForCategory({ caption, categoryId: categoryStyle.id, styleId }));
    }
  }

  for (const relationshipTypeStyle of relationshipTypeStyles) {
    const captionsToRemove = [];
    const relationshipType = relationshipTypes.find(({ id }) => id === relationshipTypeStyle.id);

    for (const caption of relationshipTypeStyle.captions) {
      if (caption.type !== CAPTION_TYPE_REL) {
        // remove the property key, if the property has been removed from the type
        const propertyFound = relationshipType?.properties.find(({ propertyKey }) => propertyKey === caption.key);
        if (!propertyFound) {
          captionsToRemove.push(caption);
        }
      }
    }

    for (const caption of captionsToRemove) {
      dispatch(forceRemoveCaptionKeyForRelType({ caption, relType: relationshipTypeStyle.id, styleId }));
    }
  }
};

export default styleMiddleWare;
