import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface Relate {
  apiToken?: string;
  clientId?: string;
  protocol?: string;
  url?: string;
}

export interface DesktopState {
  relate: Relate;
  desktopContext: boolean;
}

const initialState: DesktopState = {
  relate: {
    apiToken: undefined,
    clientId: undefined,
    protocol: undefined,
    url: undefined,
  },
  desktopContext: false,
};

export const DESKTOP_PERSISTED_KEYS: (keyof DesktopState)[] = ['relate'];

export const slice = createSlice({
  name: 'desktop',
  initialState,
  reducers: {
    setRelateTokens: (state: DesktopState, action: PayloadAction<Relate>) => {
      if (
        action.payload.apiToken !== undefined &&
        action.payload.clientId !== undefined &&
        action.payload.protocol !== undefined &&
        action.payload.url !== undefined
      ) {
        state.relate = { ...action.payload };
      }
    },
    setDesktopContext: (state: DesktopState, action: PayloadAction<boolean>) => {
      state.desktopContext = action.payload;
    },
  },
});

export const { setRelateTokens, setDesktopContext } = slice.actions;
export default slice.reducer;
