import React from 'react';

export const ExploreIconOutline = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="none"
    strokeWidth="1.5"
    stroke="currentColor"
    aria-hidden="true"
    className="ndl-icon-svg"
    {...props}
  >
    <g id="Hierarchy-9--Streamline-Ultimate.svg">
      <path
        id="Vector"
        d="M6.6875 7.12384C6.6875 7.41111 6.74408 7.69556 6.85401 7.96096C6.96395 8.22636 7.12508 8.46751 7.3282 8.67064C7.53133 8.87376 7.77248 9.03489 8.03788 9.14483C8.30328 9.25476 8.58773 9.31134 8.875 9.31134C9.16227 9.31134 9.44672 9.25476 9.71212 9.14483C9.97752 9.03489 10.2187 8.87376 10.4218 8.67064C10.6249 8.46751 10.7861 8.22636 10.896 7.96096C11.0059 7.69556 11.0625 7.41111 11.0625 7.12384C11.0625 6.83657 11.0059 6.55212 10.896 6.28672C10.7861 6.02132 10.6249 5.78017 10.4218 5.57704C10.2187 5.37392 9.97752 5.21279 9.71212 5.10285C9.44672 4.99292 9.16227 4.93634 8.875 4.93634C8.58773 4.93634 8.30328 4.99292 8.03788 5.10285C7.77248 5.21279 7.53133 5.37392 7.3282 5.57704C7.12508 5.78017 6.96395 6.02132 6.85401 6.28672C6.74408 6.55212 6.6875 6.83657 6.6875 7.12384Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M11.9375 2.74884C11.9375 3.09694 12.0758 3.43078 12.3219 3.67692C12.5681 3.92306 12.9019 4.06134 13.25 4.06134C13.5981 4.06134 13.9319 3.92306 14.1781 3.67692C14.4242 3.43078 14.5625 3.09694 14.5625 2.74884C14.5625 2.40074 14.4242 2.0669 14.1781 1.82076C13.9319 1.57462 13.5981 1.43634 13.25 1.43634C12.9019 1.43634 12.5681 1.57462 12.3219 1.82076C12.0758 2.0669 11.9375 2.40074 11.9375 2.74884Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M11.9375 10.1863C11.9375 10.5344 12.0758 10.8683 12.3219 11.1144C12.5681 11.3606 12.9019 11.4988 13.25 11.4988C13.5981 11.4988 13.9319 11.3606 14.1781 11.1144C14.4242 10.8683 14.5625 10.5344 14.5625 10.1863C14.5625 9.83824 14.4242 9.5044 14.1781 9.25826C13.9319 9.01212 13.5981 8.87384 13.25 8.87384C12.9019 8.87384 12.5681 9.01212 12.3219 9.25826C12.0758 9.5044 11.9375 9.83824 11.9375 10.1863Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M7.5625 13.2488C7.5625 13.5969 7.70078 13.9308 7.94692 14.1769C8.19306 14.423 8.5269 14.5613 8.875 14.5613C9.2231 14.5613 9.55694 14.423 9.80308 14.1769C10.0492 13.9308 10.1875 13.5969 10.1875 13.2488C10.1875 12.9007 10.0492 12.5669 9.80308 12.3207C9.55694 12.0746 9.2231 11.9363 8.875 11.9363C8.5269 11.9363 8.19306 12.0746 7.94692 12.3207C7.70078 12.5669 7.5625 12.9007 7.5625 13.2488Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_5"
        d="M1.4375 13.2488C1.4375 13.4212 1.47145 13.5919 1.53741 13.7511C1.60337 13.9103 1.70005 14.055 1.82192 14.1769C1.9438 14.2988 2.08849 14.3955 2.24773 14.4614C2.40697 14.5274 2.57764 14.5613 2.75 14.5613C2.92236 14.5613 3.09303 14.5274 3.25227 14.4614C3.41151 14.3955 3.5562 14.2988 3.67808 14.1769C3.79995 14.055 3.89663 13.9103 3.96259 13.7511C4.02855 13.5919 4.0625 13.4212 4.0625 13.2488C4.0625 13.0765 4.02855 12.9058 3.96259 12.7466C3.89663 12.5873 3.79995 12.4426 3.67808 12.3207C3.5562 12.1989 3.41151 12.1022 3.25227 12.0362C3.09303 11.9703 2.92236 11.9363 2.75 11.9363C2.57764 11.9363 2.40697 11.9703 2.24773 12.0362C2.08849 12.1022 1.9438 12.1989 1.82192 12.3207C1.70005 12.4426 1.60337 12.5873 1.53741 12.7466C1.47145 12.9058 1.4375 13.0765 1.4375 13.2488Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_6"
        d="M1.875 4.06134C1.875 4.40944 2.01328 4.74328 2.25942 4.98942C2.50556 5.23556 2.8394 5.37384 3.1875 5.37384C3.5356 5.37384 3.86944 5.23556 4.11558 4.98942C4.36172 4.74328 4.5 4.40944 4.5 4.06134C4.5 3.71324 4.36172 3.3794 4.11558 3.13326C3.86944 2.88712 3.5356 2.74884 3.1875 2.74884C2.8394 2.74884 2.50556 2.88712 2.25942 3.13326C2.01328 3.3794 1.875 3.71324 1.875 4.06134Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path id="Vector_7" d="M10.4214 5.578L12.3231 3.67633" strokeLinecap="round" strokeLinejoin="round" />
      <path id="Vector_8" d="M10.7964 8.17033L12.1293 9.50325" strokeLinecap="round" strokeLinejoin="round" />
      <path id="Vector_9" d="M8.875 9.31134V11.9363" strokeLinecap="round" strokeLinejoin="round" />
      <path id="Vector_10" d="M7.32974 8.66908L3.67749 12.3213" strokeLinecap="round" strokeLinejoin="round" />
      <path id="Vector_11" d="M6.83388 6.33692L4.27246 4.79984" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </svg>
);
