import { Dialog } from '@neo4j-ndl/react';
import * as React from 'react';

import { StripeFormFallback } from '../../../loading-fallbacks';
import './stripe-overrides.css';

const StripeForm = React.lazy(() => import('./stripe-form'));

interface Props {
  onClose: () => void;
  hasBilling: boolean;
}

export const AddPaymentMethodModal = ({ onClose, hasBilling }: Props) => {
  return (
    <Dialog isOpen onClose={onClose}>
      <Dialog.Header>{hasBilling ? 'Replace payment method' : 'Add payment method'}</Dialog.Header>
      <Dialog.Content>
        <React.Suspense fallback={<StripeFormFallback />}>
          <StripeForm
            onCancel={onClose}
            onSuccess={onClose}
            submitButtonText={hasBilling ? 'Replace' : 'Add'}
            cancelButtonText="Cancel"
          />
        </React.Suspense>
      </Dialog.Content>
    </Dialog>
  );
};
