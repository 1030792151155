export enum FRAMEWORK_EVENTS {
  CONNECT = 'CONNECT',
  DISCONNECT = 'DISCONNECT',
  TRIGGER_WELCOME_APPCUES = 'TRIGGER_WELCOME_APPCUES',
  EVALUATE_PARAMETERS = 'EVALUATE_PARAMETERS',
}

export enum GUIDE_EVENTS {
  OPEN_GS = 'LEARN_OPEN_SELECTION',
  OPEN_PLAYLIST = 'LEARN_OPEN_PLAYLIST',
  OPEN = 'LEARN_GUIDE_OPEN',
  OPEN_VIDEO = 'LEARN_VIDEO_OPEN',
  OPEN_GA = 'LEARN_ACADEMY_OPEN',
  NEXT = 'LEARN_GUIDE_NEXT',
  COMPLETE = 'LEARN_GUIDE_COMPLETE',
  /**
   * Closing “Guide Selection” view
   */
  CANCEL = 'LEARN_CANCEL',

  /**
   * Choosing to continue with blank database in “Guide Selection” view
   */
  BLANK_DATABASE_OPEN = 'BLANK_DATABASE_OPEN',
  MIGRATE_TO_STORAGE_API = 'MIGRATE_TO_STORAGE_API',
}

export enum UI_EVENTS {
  /**
   * Starting spotlight onboarding tour
   */
  SPOTLIGHT_WELCOME_START = 'SPOTLIGHT_WELCOME_START',

  /**
   * Completing spotlight onboarding tour fully
   */
  SPOTLIGHT_WELCOME_FINISH = 'SPOTLIGHT_WELCOME_FINISH',

  /**
   * Terms and conditions accepted
   */
  ACCEPT_TERMS_AND_CONDITIONS = 'ACCEPT_TERMS_AND_CONDITIONS',

  /**
   * Interaction with “Send Feedback” CTA
   */
  VIEW_FEEDBACK = 'VIEW_FEEDBACK',

  /**
   * User-initiated access to Learn page/“Guide Selection” view
   */
  VIEW_LEARN = 'VIEW_LEARN',

  /**
   * Interaction with a link in “Help and Learn” popover or Learn page nav menu
   */
  VIEW_RESOURCE = 'VIEW_RESOURCE',

  /**
   * Access to “Help and Learn” popover
   */
  VIEW_RESOURCES = 'VIEW_RESOURCES',

  /**
   * Access "Settings" sidebar
   */
  VIEW_SETTINGS = 'VIEW_SETTINGS',
}
