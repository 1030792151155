import { configureStore } from '@reduxjs/toolkit';

import { config } from '../../config';
import boltMiddleware from '../services/bolt/boltMiddleware';
import trackingMiddleware from '../services/tracking/trackingMiddleware';
import { initiateDisconnectSync } from './app/initiate-disconnect-sync';
import inventoryMiddleware from './graph/inventoryMiddleware';
import { persistenceMiddleware } from './persistence/persistenceMiddleware';
import perspectiveStoreMiddleware from './perspectives/perspectiveStoreMiddleware';
import { reducers } from './rootReducer';
import { sharedStorageApi } from './shared-storage/shared-storage.api';
import sharedStorageSyncMiddleware from './shared-storage/sharedStorageSyncMiddleware';
import { initiateStyleSync, syncMiddleware } from './styles/frameworkSync';
import styleMiddleware from './styles/styleMiddleware';

export const store = configureStore({
  // @ts-expect-error revisit once all reducers are typed
  reducer: reducers,
  // @ts-expect-error update when all middlewares are typed
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat(
      inventoryMiddleware,
      perspectiveStoreMiddleware,
      boltMiddleware,
      trackingMiddleware,
      styleMiddleware,
      syncMiddleware,
      persistenceMiddleware,
      sharedStorageApi.middleware,
      sharedStorageSyncMiddleware,
    ),
  devTools: config.NODE_ENV !== 'production' ? { name: 'Explore', trace: true } : false,
});

initiateStyleSync(() => store.getState(), store.dispatch);
initiateDisconnectSync(store.dispatch);
