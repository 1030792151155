/* eslint-disable require-await, @typescript-eslint/require-await */
import { APP_SCOPE } from '@nx/constants';
import type { PersistState } from 'redux-persist';

const PERSIST_VERSION = 1;
export const PERSIST_PREFIX = `nx.v${PERSIST_VERSION}`;

export class Persist {
  private static currentLocation: 'local' | 'remote' = 'local';

  static setCurrentLocation(location: 'local' | 'remote') {
    this.currentLocation = location;
  }

  static createScopedPrefix(scope: APP_SCOPE): string {
    // Converting framework to nx for backwards compatibility.
    if (scope === APP_SCOPE.framework) {
      return [PERSIST_PREFIX, 'nx'].join('.');
    }

    return [PERSIST_PREFIX, scope].join('.');
  }

  static createKey(scope: APP_SCOPE, key: string): string {
    // Converting framework to nx for backwards compatibility.
    if (scope === APP_SCOPE.framework) {
      return [PERSIST_PREFIX, 'nx', key].join('.');
    }

    return [PERSIST_PREFIX, scope, key].join('.');
  }

  constructor(
    private readonly scope: APP_SCOPE,
    private readonly localOnly = false,
  ) {}

  async setItem(key: string, value: string): Promise<void> {
    const prefixedKey = Persist.createKey(this.scope, key);

    if (Persist.currentLocation === 'local' || this.localOnly) {
      localStorage.setItem(prefixedKey, value);
      return;
    }

    throw new Error('Not implemented');
  }

  async getItem(key: string): Promise<string | null> {
    const prefixedKey = Persist.createKey(this.scope, key);

    if (Persist.currentLocation === 'local' || this.localOnly) {
      const value = localStorage.getItem(prefixedKey);
      return value;
    }

    throw new Error('Not implemented');
  }

  async removeItem(key: string): Promise<void> {
    const prefixedKey = Persist.createKey(this.scope, key);

    if (Persist.currentLocation === 'local' || this.localOnly) {
      localStorage.removeItem(prefixedKey);
      return;
    }

    throw new Error('Not implemented');
  }
}

type AppPersistedState<T> = ({ _persist: PersistState } & Partial<T>) | undefined;
export type AppPersistMigrate<T> = (state: AppPersistedState<T>) => Promise<AppPersistedState<T>>;
export const DEFAULT_PERSIST_STATE = { _persist: { version: -1, rehydrated: false } };
