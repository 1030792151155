import type { SecurityLogFilterInput, SecurityLogRawFilterSelection, TimeRange } from '@nx/state';
import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext, useMemo } from 'react';

import type { TabKey } from '../../shared/types';
import { mapSecurityLogRawFiltersToInput } from '../shared/mappers';

type LogsContextValue = {
  setIsRequestDialogOpen: (a: boolean) => void;
  selectedTab: TabKey;
  setSelectedTab: Dispatch<SetStateAction<TabKey>>;
  rawFilters: SecurityLogRawFilterSelection;
  setRawFilters: Dispatch<SetStateAction<SecurityLogRawFilterSelection>>;
  hasRequestedData: boolean;
  setHasRequestedData: Dispatch<SetStateAction<boolean>>;
  timeRange: TimeRange;
};

export type LogsContext = LogsContextValue & {
  filterInput: SecurityLogFilterInput;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const LogsContext = createContext<LogsContextValue>({} as LogsContextValue);

export const LogsContextProvider = LogsContext.Provider;

export function useLogsContext(): LogsContext {
  const logsContext = useContext(LogsContext);

  const {
    setIsRequestDialogOpen,
    selectedTab,
    setSelectedTab,
    rawFilters,
    setRawFilters,
    hasRequestedData,
    setHasRequestedData,
    timeRange,
  } = logsContext;

  const filterInput = useMemo(() => mapSecurityLogRawFiltersToInput(rawFilters), [rawFilters]);
  return {
    setIsRequestDialogOpen,
    selectedTab,
    setSelectedTab,
    rawFilters,
    setRawFilters,
    hasRequestedData,
    setHasRequestedData,
    timeRange,
    filterInput,
  };
}
