import {
  ChartBarIconOutline,
  ChatBubbleLeftRightIconOutline,
  CircleStackIconOutline,
  CloudArrowDownIconOutline,
  Cog6ToothIconOutline,
  CommandLineIconOutline,
  CreditCardIconOutline,
  CubeTransparentIconOutline,
  DocumentTextIconOutline,
  FolderIconOutline,
  ScienceMoleculeIcon,
  UserGroupIconOutline,
  WrenchScrewdriverIconOutline,
} from '@neo4j-ndl/react/icons';
import { UI_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { getFlag } from '@nx/launch-darkly-service';
import {
  ACTION,
  PLAN_TYPE,
  consoleApi,
  useDesktop,
  useFeatureFlag,
  usePermissions,
  useToolsConfiguration,
  useUnsafeAppContext,
} from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { useCurrentScope, useManifest } from '@nx/ui';
import { LeftNav, Nav } from '@nx/upx-layout';
import type { MenuItem } from '@nx/upx-layout/src/left-nav-menu/left-nav-menu';
import { skipToken } from '@reduxjs/toolkit/query';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ExploreIconOutline, RolesIconOutline } from '../components/icons';
import { useTrackUpxEvent } from '../services/segment/analytics';

type PlaylistMetadata = {
  title: string;
  url: string;
  attributes: {
    category: string;
  };
};

function GuidesLeftNavItems({ extraNavItems = [] }: { extraNavItems?: MenuItem[] }) {
  const trackEvent = useTrackUpxEvent();
  const manifest = useManifest();
  const toolsConfiguration = useToolsConfiguration();
  const guidesRoute = toolsConfiguration.guides?.route;
  const playlistGroups: Record<string, [string, PlaylistMetadata][]> = {};
  const currentScope = useCurrentScope();
  function isUsableMetadata(metadata: unknown): metadata is PlaylistMetadata {
    return (
      metadata instanceof Object &&
      'title' in metadata &&
      typeof metadata.title === 'string' &&
      'url' in metadata &&
      typeof metadata.url === 'string'
    );
  }

  function getListItems(): (MenuItem | undefined)[] {
    return Object.keys(playlistGroups)
      .map((groupName) => {
        if (isNotNullish(playlistGroups[groupName])) {
          return [
            Nav.heading(groupName),
            ...playlistGroups[groupName].map((item) => {
              return item[1].attributes.category === 'external'
                ? Nav.link({
                    name: item[1].title,
                    route: item[1].url,
                    onClick: () => {
                      trackEvent({
                        event: UI_EVENTS.VIEW_RESOURCE,
                        properties: { type: 'link', name: item[0], path: currentScope },
                        scope: APP_SCOPE.framework,
                      });
                    },
                  })
                : Nav.link({ name: item[1].title, route: `${guidesRoute}/${item[0]}` });
            }),
          ];
        }
        return [];
      })
      .flat();
  }

  Object.entries(manifest.data?.playlists ?? []).forEach(([playlistId, metadata]) => {
    if (isNotNullish(metadata.attributes)) {
      const { group, category } = metadata.attributes;
      if (
        isUsableMetadata(metadata) &&
        (category === 'app-guide' || category === 'external') &&
        isNotNullish(group) &&
        typeof group === 'string'
      ) {
        playlistGroups[group] = playlistGroups[group] ?? [];
        playlistGroups[group].push([playlistId, metadata]);
      }
    }
  });

  return <LeftNav.Target items={[...getListItems(), ...extraNavItems]} />;
}

const appIconsMap: Record<string, ReactNode> = {
  [APP_SCOPE.dashboards]: <ChartBarIconOutline />,
  [APP_SCOPE.explore]: <ExploreIconOutline />,
  [APP_SCOPE.query]: <CommandLineIconOutline />,
  [APP_SCOPE.conversations]: <ChatBubbleLeftRightIconOutline />,
};

export function ConsoleNav() {
  const toolsConfiguration = useToolsConfiguration();
  const { activeProjectId, activeOrgId } = useUnsafeAppContext();
  const { isDesktopContext } = useDesktop();
  const projectRes = consoleApi.useGetProjectQuery(activeProjectId ?? skipToken, { skip: isDesktopContext });
  const orgRes = consoleApi.useGetOrganizationQuery(activeOrgId ?? skipToken, { skip: isDesktopContext });
  const { isAllowed } = usePermissions(isDesktopContext);
  const [isDebuggerEnabled] = useFeatureFlag('nx:debugger');
  const [isDashboardsEnabled] = useFeatureFlag('dashboards:enabled');
  const isConversationsEnabled = getFlag('conversationsAppEnabled', false);

  const importConfig = toolsConfiguration[APP_SCOPE.import];
  const debuggerConfig = toolsConfiguration[APP_SCOPE.debugger];
  const guidesConfig = toolsConfiguration[APP_SCOPE.guides];

  const toolLinks = useMemo(() => {
    return [
      ...(isDashboardsEnabled ? [APP_SCOPE.dashboards] : []),
      APP_SCOPE.query,
      APP_SCOPE.explore,
      ...(isConversationsEnabled ? [APP_SCOPE.conversations] : []),
    ]
      .map((scope) => {
        const config = toolsConfiguration[scope];
        return config
          ? Nav.link({ name: config.displayName, route: config.route, icon: appIconsMap[scope] })
          : undefined;
      })
      .filter(isNotNullish);
  }, [isDashboardsEnabled, toolsConfiguration, isConversationsEnabled]);

  const debuggerItems =
    isDebuggerEnabled && debuggerConfig
      ? [
          Nav.divider(),
          Nav.heading('Internal settings'),
          Nav.link({
            name: debuggerConfig.displayName,
            route: debuggerConfig.route,
            icon: <WrenchScrewdriverIconOutline />,
          }),
        ]
      : [];

  const desktopItems = [
    Nav.heading('Data services'),
    Nav.link({ name: 'Instances', route: '/local/instances', icon: <CircleStackIconOutline /> }),
    Nav.heading('Tools'),
    ...toolLinks,
    ...debuggerItems,
  ];

  if (isDesktopContext) {
    return <LeftNav.Target items={desktopItems} />;
  }

  const auraProjectLinks = isNotNullish(projectRes.data)
    ? [
        Nav.heading('Project', 'welcome-tour-5-project'),
        Nav.link({ name: 'Users', route: `/projects/${activeProjectId}/users`, icon: <UserGroupIconOutline /> }),
        Nav.link({ name: 'Roles', route: `/projects/${activeProjectId}/roles`, icon: <RolesIconOutline /> }),
        ...(projectRes.data.planType === PLAN_TYPE.SELF_SERVE || projectRes.data.capabilities.consumption_reporting
          ? [
              Nav.link({
                name: 'Billing',
                route: `/projects/${activeProjectId}/billing`,
                icon: <CreditCardIconOutline />,
              }),
            ]
          : []),
        Nav.link({ name: 'Settings', route: `/projects/${activeProjectId}/settings`, icon: <Cog6ToothIconOutline /> }),
      ]
    : [];

  const auraOrgLinks = isNotNullish(orgRes.data)
    ? [
        Nav.heading('Organization'),
        Nav.link({ name: 'Projects', route: `/org/${activeOrgId}/projects`, icon: <FolderIconOutline /> }),
        ...(isAllowed(ACTION.READ, `organizations/${activeOrgId}/users`)
          ? [Nav.link({ name: 'Users', route: `/org/${activeOrgId}/users`, icon: <UserGroupIconOutline /> })]
          : []),
        // Nav.link('Billing', `/org/${activeOrgId}/billing`, <CreditCardIconOutline /> ),
        Nav.link({ name: 'Settings', route: `/org/${activeOrgId}/settings`, icon: <Cog6ToothIconOutline /> }),
        ...debuggerItems,
      ]
    : [];

  const gdsLinks =
    (projectRes.data?.capabilities.gds_sessions ?? false)
      ? [Nav.link({ name: 'Sessions', route: `/projects/${activeProjectId}/sessions`, icon: <ScienceMoleculeIcon /> })]
      : [];

  return (
    <Routes>
      <Route path={'org/*'} element={<LeftNav.Target items={auraOrgLinks} />} />
      <Route
        path={'account/*'}
        element={
          <LeftNav.Target
            items={[
              Nav.heading('Account settings'),
              Nav.link({ name: 'Profile', route: '/account/profile' }),
              Nav.link({ name: 'API Keys', route: '/account/api-keys' }),
              Nav.link({ name: 'Preferences', route: '/account/preferences' }),
              ...debuggerItems,
            ]}
          />
        }
      />
      {guidesConfig && (
        <Route path={`${guidesConfig.route}/*`} element={<GuidesLeftNavItems extraNavItems={debuggerItems} />} />
      )}
      <Route
        path={'*'}
        element={
          <LeftNav.Target
            items={[
              Nav.heading('Data services'),
              Nav.link({
                name: 'Instances',
                route: `/projects/${activeProjectId}/instances`,
                icon: <CircleStackIconOutline />,
                spotlightId: 'welcome-tour-2-instances',
              }),
              importConfig &&
                Nav.link({
                  name: importConfig.displayName,
                  route: importConfig.route,
                  icon: <CloudArrowDownIconOutline />,
                }),
              ...gdsLinks,
              Nav.link({
                name: 'Data APIs',
                route: `/projects/${activeProjectId}/data-api`,
                icon: <CubeTransparentIconOutline />,
                beta: true,
              }),
              Nav.heading('Tools', 'welcome-tour-3-tools'),
              ...toolLinks,
              Nav.heading('Operations', 'welcome-tour-4-operations'),
              Nav.link({
                name: 'Metrics',
                route: `/projects/${activeProjectId}/metrics`,
                icon: <ChartBarIconOutline />,
              }),
              Nav.link({ name: 'Logs', route: `/projects/${activeProjectId}/logs`, icon: <DocumentTextIconOutline /> }),
              ...auraProjectLinks,
              ...debuggerItems,
            ]}
          />
        }
      />
    </Routes>
  );
}
