import { APP_SCOPE, QUERY_TYPE } from '@nx/constants';
import type { BloomInformation, Configuration, Neo4jFunction, Procedure } from '@nx/neo4j-sdk';
import { initNeo4jSDK } from '@nx/neo4j-sdk/src/neo4j-sdk';
import type { CypherVersion } from '@nx/neo4j-sdk/src/types/sdk-types';
import { createSlice } from '@reduxjs/toolkit';

import { createAsyncThunk, useSelector } from '../context';
import { selectEnabledFeatureFlags } from '../selectors';
import type { RootState } from '../store';
import { selectEnabledTools } from './capabilities-slice';

/**
 * System-wide DBMS metadata for the current connection.
 */
export type MetadataState = {
  state: 'NOT_LOADED' | 'LOADED' | 'ERROR';
  neo4jVersion: CypherVersion | null;
  procedures: Partial<Record<CypherVersion, Procedure[]>>;
  functions: Partial<Record<CypherVersion, Neo4jFunction[]>>;
  configuration: Configuration | null;
  bloomPlugin: BloomInformation | null;
};

export const selectMetadata = (state: RootState) => state.metadata;

export function useMetadata() {
  return useSelector(selectMetadata);
}

export const initialState: MetadataState = {
  state: 'NOT_LOADED',
  neo4jVersion: null,
  procedures: {},
  functions: {},
  configuration: null,
  bloomPlugin: null,
};

export const updateMetadata = createAsyncThunk('metadata/update', async (payload, { extra, getState }) => {
  const queryCypher = (query: string) =>
    extra.driver.runQuery(
      { query },
      {
        sessionConfig: { database: 'system' },
        metadata: { appScope: APP_SCOPE.framework, queryType: QUERY_TYPE.System },
      },
      selectEnabledTools(getState()),
    );

  const neo4jSdk = await initNeo4jSDK({ queryCypher });

  const cypher25Enabled = selectEnabledFeatureFlags(getState()).includes('query:enable-cypher25-support');

  const [procedures, functions, configuration] = await Promise.allSettled([
    neo4jSdk.listProcedures(cypher25Enabled),
    neo4jSdk.listFunctions(cypher25Enabled),
    neo4jSdk.getConfiguration(),
  ]);

  let bloomPlugin: BloomInformation | null = null;
  if (procedures.status === 'fulfilled') {
    bloomPlugin = await neo4jSdk.getBloomInfo({ procedures: procedures.value['CYPHER 5'] ?? [] });
  }

  return {
    procedures: procedures.status === 'fulfilled' ? procedures.value : {},
    functions: functions.status === 'fulfilled' ? functions.value : {},
    neo4jVersion: neo4jSdk.neo4jVersion,
    configuration: configuration.status === 'fulfilled' ? configuration.value : null,
    bloomPlugin,
    state: [procedures, functions, configuration].some((result) => result.status === 'rejected') ? 'ERROR' : 'LOADED',
  } as const;
});

const metadataSlice = createSlice({
  name: 'metadata',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateMetadata.pending, () => {
      return initialState;
    });

    builder.addCase(updateMetadata.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export default metadataSlice.reducer;
