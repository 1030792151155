import type { RowData, TableOptions } from '@tanstack/react-table';
import {
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

type CommonTableOptions<T extends RowData> = Omit<TableOptions<T>, 'getCoreRowModel'>;

export const useCommonTable = <T extends RowData>(options: CommonTableOptions<T>) =>
  useReactTable({
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    columnResizeMode: 'onChange',
    ...options,
  });

export const useCommonExpandedTable = <T extends RowData>(options: CommonTableOptions<T>) =>
  useCommonTable({
    getExpandedRowModel: getExpandedRowModel(),
    paginateExpandedRows: true,
    ...options,
  });
