import { intersection, isEmpty, isNil, keyBy } from 'lodash-es';
import { pipe } from 'lodash/fp';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { ActionCreators } from 'redux-undo';

import { getLabelsWithNodesUnderLimit, getNodesAndRelationships } from '../services/discovery';
import { log } from '../services/logging';
import { getSchemaFromPerspective } from '../services/perspectives/schema';
import { isVersion5OorGreater } from '../services/versions/versionUtils';
import { setIsCaseInsensitiveAvailable } from '../state/app/appDuck';
import type { ConnectionsDuckState } from '../state/connections/connectionsDuck';
import {
  getDatabase,
  getServerVersion,
  getUserCanSaveScene,
  getUserId,
  getUserRoles,
  setRefreshingDataState,
} from '../state/connections/connectionsDuck';
import { BloomStateContext } from '../state/context';
import { focusNode, focusRelationship } from '../state/focused/focused';
import { getGdsRules, resetGds } from '../state/gds/gds';
import {
  addToInventory,
  addToVisible,
  invertSelection,
  removeFromVisible,
  updateInventory,
} from '../state/graph/graph.actions';
import {
  clearDisabled as clearDisabledNodes,
  deactivateNodes,
  getDisabledNodeMap,
  getNodes,
  getSelectedNodeMap,
  getSelectedNodes,
  getVisibleNodeIds,
  selectNodes,
} from '../state/graph/nodes';
import type { NodesState } from '../state/graph/nodes';
import {
  clearDisabled as clearDisabledRels,
  getDisabledRelationshipMap,
  getRelationships,
  getSelectedRelationshipMap,
  getVisibleRelationshipIds,
  getVisibleRelationships,
  selectRelationships,
} from '../state/graph/relationships';
import type { RelationshipsState } from '../state/graph/relationships';
import { initiateErrorModal } from '../state/modal/modal';
import { addWarningNotification } from '../state/notifications/notifications';
import { getPathSegments } from '../state/perspectives/perspectiveMetadata';
import { getVisibleLabels, getVisibleRelationshipTypes } from '../state/perspectives/perspectives';
import { getPerspective } from '../state/perspectives/perspectives.selector';
import { clearState } from '../state/rootReducer';
import { getCurrentSceneId } from '../state/scene/scene';
import { updateLabelsWithNodesUnderLimit } from '../state/search-prototype/core/search-core';
import { getAutoSelectionState, getQueryResultLimit } from '../state/settings/settings';
import { getRanges, setSlicerRanges } from '../state/slicer/slicer';
import {
  deleteStyleRulesByRuleId,
  getCategoriesWithCurrentStyle,
  getStyleById,
  getStyleType,
} from '../state/styles/styles';
import type { AppDispatch, RootState } from '../state/types';
import type { ZoomOptions } from '../state/visualization/types';
import { getLayoutType, zoomTo } from '../state/visualization/visualization';
import type { Category } from '../types/category';
import type { Database } from '../types/database';
import type { Node, Relationship } from '../types/graph';
import type { Perspective, PerspectiveCategory, PerspectiveRelationshipType } from '../types/perspective';

export const MinBlinkTime = 1000;

let nodeIds: Node['id'][];
let endTime: number | null = null;
// Why donesn't this just use a plain setTimeouts?
// Because Edge throttles timeouts when animationFrame requests are runnning so they never get called
export const setOrAddTimeout = (newNodeIds: Node['id'][], time: number, dispatch: AppDispatch) => {
  const animCb = () => {
    if (!isNil(endTime) && Date.now() >= endTime) {
      endTime = null;
      dispatch(deactivateNodes(nodeIds));
    } else {
      window.requestAnimationFrame(animCb);
    }
  };

  if (endTime !== null) {
    nodeIds = nodeIds.concat(newNodeIds);
    endTime = Math.max(endTime, Date.now() + time);
  } else {
    nodeIds = newNodeIds;
    endTime = Date.now() + time;
    window.requestAnimationFrame(animCb);
  }
};

const stateToProps = (state: RootState) => {
  const perspective = getPerspective(state);
  const selectedNodeMap = getSelectedNodeMap(state);

  return {
    autoSelectNewNodes: getAutoSelectionState(state),
    canSaveScene: getUserCanSaveScene(state),
    categories: getCategoriesWithCurrentStyle(state),
    currentSceneId: getCurrentSceneId(state),
    disabledNodes: getDisabledNodeMap(state),
    disabledRels: getDisabledRelationshipMap(state),
    gdsRules: getGdsRules(state),
    hiddenRelationshipTypes: perspective?.hiddenRelationshipTypes ?? [],
    layoutType: getLayoutType(state),
    nodesInventory: getNodes(state),
    pathSegments: getPathSegments(state),
    perspective,
    perspectiveStyle: !isNil(perspective?.id) ? getStyleById(state)(perspective.id) : {},
    queryResultLimit: getQueryResultLimit(state),
    ranges: getRanges(state),
    relationshipsInventory: getRelationships(state),
    selectedNodeIds: Object.keys(selectedNodeMap),
    selectedNodeMap,
    selectedNodes: getSelectedNodes(state),
    selectedRelationshipIds: Object.keys(getSelectedRelationshipMap(state)),
    serverVersion: getServerVersion(state),
    styleType: getStyleType(state),
    userId: getUserId(state),
    userRoles: getUserRoles(state),
    visibleLabels: getVisibleLabels(state),
    visibleNodeIds: getVisibleNodeIds(state),
    visibleRelationshipIds: getVisibleRelationshipIds(state),
    visibleRelationshipTypes: getVisibleRelationshipTypes(state),
    visibleRelationships: getVisibleRelationships(state),
  };
};

const reduxActions = {
  invertSelection,
  clearState,
  addWarningNotification,
  removeFromVisible,
  updateInventory,
  clearDisabledNodes,
  clearDisabledRels,
  focusNode,
  focusRelationship,
  resetGds,
  deleteStyleRulesByRuleId,
  undo: ActionCreators.undo,
  redo: ActionCreators.redo,
};

const dispatchToProps = (dispatch: AppDispatch) =>
  Object.keys(reduxActions).reduce(
    (dispatchActionsFunctions, name) => ({
      ...dispatchActionsFunctions,
      // @ts-expect-error don't know why TS is complaining here
      [name]: pipe(reduxActions[name], dispatch),
    }),
    {
      zoomTo: (ids: Node['id'][], options?: ZoomOptions) => dispatch(zoomTo({ nodeIds: ids, options })),
      selectNodes: (nodeIds: Node['id'][]) => {
        dispatch(selectNodes(nodeIds));
      },
      selectRelationships: (relIds: Relationship['id'][]) => {
        dispatch(selectRelationships(relIds));
      },
      setRefreshingDataState: (bool: boolean) => dispatch(setRefreshingDataState(bool)),
      showErrorPopup: (error: Error) => {
        const message = error.message ?? error.toString();
        dispatch(initiateErrorModal(message));
      },
      dispatch,
    },
  );

const buildAdjList = (rels: Relationship[]) => {
  const addRel = (relKey: string, id: Relationship['startId'] | Relationship['endId']) => {
    const relList = relMap[relKey];
    if (isNil(relList)) {
      relMap[relKey] = [id];
    } else {
      relList.push(id);
    }
  };

  const adjList: Record<string, Set<string>> = {};
  const relMap: Record<string, Relationship['id'][]> = {};
  for (const rel of rels) {
    const { startId, endId, id } = rel;
    if (isNil(adjList[startId])) {
      adjList[startId] = new Set();
    }
    if (isNil(adjList[endId])) {
      adjList[endId] = new Set();
    }
    adjList[startId]?.add(endId);
    adjList[endId]?.add(startId);
    addRel(`${startId}_${endId}`, id);
    addRel(`${endId}_${startId}`, id);
  }

  return { adjList, relMap };
};

export const findRelatedNodes = (nodeIds: Node['id'][], visibleRelationships: Relationship[]) => {
  const { adjList, relMap } = buildAdjList(visibleRelationships);
  const seenNodeIdToItem = new Set<Node['id']>();
  const seenRelsIdToItem = new Set<Relationship['id']>();

  const bfs = (initialNodeId: Node['id']) => {
    const queue: Node['id'][] = [];
    if (!seenNodeIdToItem.has(initialNodeId)) queue.push(initialNodeId);

    while (queue.length > 0) {
      // shift() can't return undefined because in the while loop we check that queue.length > 0
      const queueId = queue.shift() as string;
      seenNodeIdToItem.add(queueId);

      const nodeIds = adjList[queueId];
      if (!isNil(nodeIds)) {
        for (const nodeId of nodeIds) {
          if (!seenNodeIdToItem.has(nodeId)) {
            const relKey = `${queueId}_${nodeId}`;
            const relList = relMap[relKey];
            queue.push(nodeId);
            if (!isNil(relList)) {
              for (const relId of relList) {
                seenRelsIdToItem.add(relId);
              }
            }
          }
        }
      }
    }
  };
  for (const nodeId of nodeIds) {
    if (!seenNodeIdToItem.has(nodeId)) {
      bfs(nodeId);
    }
  }

  return { connectedNodes: seenNodeIdToItem, connectedRels: seenRelsIdToItem };
};
export const withActionHandlers = compose(
  connect(stateToProps, dispatchToProps, null, { context: BloomStateContext }),
  withHandlers({
    undo: ({ undo }: typeof ActionCreators) => undo,
    redo: ({ redo }: typeof ActionCreators) => redo,
  }),
);

export const restoreInventoryFromVisibleIds = ({
  getState,
  dispatch,
}: {
  getState: () => RootState;
  dispatch: AppDispatch;
}) => {
  const state = getState();
  const perspective = getPerspective(state);
  const visibleNodeIds = getVisibleNodeIds(state);
  const visibleRelationshipIds = getVisibleRelationshipIds(state);
  const ranges = getRanges(state);
  const nodes = getNodes(state);
  const database = getDatabase(state);
  const serverVersion = getServerVersion(state);
  const nodeIds = [...visibleNodeIds];
  const relationshipIds = [...visibleRelationshipIds];

  if (
    !isNil(perspective) &&
    !isNil(serverVersion) &&
    perspective.dbId === database?.id &&
    nodeIds.length > 0 &&
    isEmpty(nodes)
  ) {
    dispatch(setRefreshingDataState(true));
    const isV5OrGreater = isVersion5OorGreater(serverVersion) ?? false;

    void getNodesAndRelationships({
      nodeIds: visibleNodeIds,
      relationshipIds,
      schema: getSchemaFromPerspective(perspective),
      visibleRelationshipTypes: getVisibleRelationshipTypes(state),
      visibleLabels: getVisibleLabels(state),
      isV5OrGreater,
      responseHandler: (result) => {
        const { nodes = [], relationships = [], error } = result;
        if (!isNil(error)) {
          const errorMessage = typeof error === 'object' && 'message' in error ? error.message : error;
          log.error(errorMessage);
        } else {
          const prevRanges = ranges;
          dispatch(updateInventory({ nodes, relationships, categories: perspective.categories }));
          const presentNodeIds = nodes.map((node: Node) => node.id);
          dispatch(zoomTo({ nodeIds: presentNodeIds }));
          dispatch(
            addToVisible({
              nodeIds: nodes.map((node: Node) => node.id),
              relationshipIds: relationships.map((rel: Relationship) => rel.id),
              categories: perspective.categories,
            }),
          );
          dispatch(setSlicerRanges(prevRanges));
        }
        dispatch(setRefreshingDataState(false));
      },
    });
  }
};

const getVirtualNodesAndRelationships = (
  categories: { labels: string[] }[],
  hideUncategorisedData: boolean | undefined,
  nodesInventory: NodesState['inventory'],
  relationshipsInventory: RelationshipsState['inventory'],
) => {
  const hasCategory = (labels: Category['labels']) =>
    categories.find((c) => intersection(c.labels, labels).length > 0) != null;
  const nodeFilter = ({ id, labels }: Node) =>
    parseInt(id) < 0 && (!(hideUncategorisedData ?? true) || hasCategory(labels));
  const virtualNodes = Object.values(nodesInventory).filter(nodeFilter);
  const remainingNodes = keyBy(virtualNodes, 'id');
  const relFilter = ({ id, startId, endId }: Relationship) =>
    parseInt(id) < 0 &&
    (parseInt(startId) >= 0 || !isNil(remainingNodes[startId])) &&
    (parseInt(endId) >= 0 || !isNil(remainingNodes[endId]));
  const virtualRels = Object.values(relationshipsInventory).filter(relFilter);

  return { virtualNodes, virtualRels };
};

export const refreshCaseInsensitiveAvailability = async (
  dispatch: AppDispatch,
  database: Database,
  perspective: Perspective,
) => {
  try {
    const labelsWithNodesUnderLimit = (await getLabelsWithNodesUnderLimit(database.name)) ?? [];
    const hasLabelsWithNodesUnderLimit = labelsWithNodesUnderLimit.length > 0;
    const hasFullTextIndexes = perspective.metadata.indexes.some((index) => index.type === 'full-text');

    if (hasFullTextIndexes || hasLabelsWithNodesUnderLimit) {
      dispatch(setIsCaseInsensitiveAvailable(true));
    } else {
      dispatch(setIsCaseInsensitiveAvailable(false));
    }
    dispatch(updateLabelsWithNodesUnderLimit(labelsWithNodesUnderLimit));
  } catch (error) {
    log.error('Error getting stats', error);
  }
};

export const refreshData = (
  dispatch: AppDispatch,
  perspective: Perspective,
  database: Database,
  nodesInventory: NodesState['inventory'],
  relationshipsInventory: RelationshipsState['inventory'],
  visibleNodeIds: NodesState['visible'],
  visibleRelationshipIds: RelationshipsState['visible'],
  visibleRelationshipTypes: PerspectiveRelationshipType['id'][],
  visibleLabels: string[],
  serverVersion: ConnectionsDuckState['serverVersion'],
) => {
  if (perspective?.dbId !== database.id || visibleNodeIds.length === 0) {
    return;
  }

  const { categories, hideUncategorisedData } = perspective;

  dispatch(setRefreshingDataState(true));

  const { virtualNodes, virtualRels } = getVirtualNodesAndRelationships(
    categories,
    hideUncategorisedData,
    nodesInventory,
    relationshipsInventory,
  );

  const isV5OrGreater = isVersion5OorGreater(serverVersion) ?? false;

  void getNodesAndRelationships({
    nodeIds: visibleNodeIds,
    relationshipIds: visibleRelationshipIds,
    schema: getSchemaFromPerspective(perspective),
    visibleRelationshipTypes,
    visibleLabels,
    isV5OrGreater,
    responseHandler: (result) => {
      const { nodes = [], relationships = [], error } = result;
      if (!isNil(error)) {
        const errorMessage = typeof error === 'object' && 'message' in error ? error.message : error;
        log.error(errorMessage);
      } else {
        dispatch(
          updateInventory({
            nodes: [...nodes, ...virtualNodes],
            relationships: [...relationships, ...virtualRels],
            categories,
          }),
        );
      }
      dispatch(setRefreshingDataState(false));
    },
  });
};

export const addNodesAndRelationships = (
  nodes: Node[],
  relationships: Relationship[],
  categories: PerspectiveCategory[],
  dispatch: AppDispatch,
) => {
  dispatch(addToInventory({ nodes, relationships }));
  dispatch(
    addToVisible({
      nodeIds: nodes.map((node) => node.id),
      relationshipIds: relationships.map((rel) => rel.id),
      categories,
    }),
  );
};
