import { isAction, isAnyOf } from '@reduxjs/toolkit';
import { mapValues } from 'lodash-es';

import type { Category } from '../../types/category';
import type { Node, Relationship } from '../../types/graph';
import type { GeneralPropertyKey, MetadataPropertyKey, Perspective } from '../../types/perspective';
import type { Nullable } from '../../types/utility';
import { isTruthy } from '../../types/utility';
import { setShowSceneEmptyState } from '../app/appDuck';
import {
  defocusAllEntities,
  defocusEntity,
  focusNode,
  focusRelationship,
  focusRelationshipType,
} from '../focused/focused';
import {
  addPropertiesToCategory,
  addPropertiesToRelationships,
  getCategoryIdentifierForNodeMapper,
} from '../perspectives/perspectives';
import { getPerspective } from '../perspectives/perspectives.selector';
import { setSlicerRanges } from '../slicer/slicer';
import type { AppDispatch, AppMiddleware, RootState } from '../types';
import {
  addToInventory,
  clearExpanded,
  purgeInvisibleItems,
  removeFromVisible,
  updateInventory,
} from './graph.actions';
import { addToNodeInventory, getVisibleNodeIds, updateNodeInventory } from './nodes';
import { addToRelationshipInventory, setRelationshipIndex } from './relationships';

const focusActions = [
  focusNode.type,
  focusRelationship.type,
  defocusEntity.type,
  defocusAllEntities.type,
  focusRelationshipType.type,
  removeFromVisible.type,
];
const addActions = [
  updateInventory.type,
  addToInventory.type,
  updateNodeInventory.toString(),
  addToNodeInventory.toString(),
  setRelationshipIndex.toString(),
  addToRelationshipInventory.toString(),
];
const clearSlicerActions = [...addActions, removeFromVisible.type];

const requestCallback = (cb: () => void) => {
  if ('requestIdleCallback' in window) {
    requestIdleCallback(cb, { timeout: 5000 });
  } else {
    requestAnimationFrame(cb);
  }
};

type PropsByCategory = Record<Category['id'], Record<string, MetadataPropertyKey>>;

type PropsByRelType = Record<string, Record<string, GeneralPropertyKey>>;

const updatePerspectiveAndEmptyWatermark = (
  state: RootState,
  payload: { nodes?: Nullable<Node[]>; relationships?: Nullable<Relationship[]> },
  dispatch: AppDispatch,
) => {
  const perspective = getPerspective(state);
  const getCategoryId = getCategoryIdentifierForNodeMapper(state);

  if (!perspective) return;
  updatePerspectiveWithProperties(payload, perspective, getCategoryId, dispatch);

  const visibleNodeIds = getVisibleNodeIds(state);

  if (isTruthy(visibleNodeIds.length) || isTruthy(payload?.nodes?.length)) {
    dispatch(setShowSceneEmptyState(false));
  }
};

const updatePerspectiveWithProperties = (
  payload: { nodes?: Nullable<Node[]>; relationships?: Nullable<Relationship[]> },
  perspective: Perspective,
  getCategoryId: (node: Node) => Category['id'],
  dispatch: AppDispatch,
) => {
  const nodes = payload.nodes ?? [];
  const relationships = payload.relationships ?? [];
  const propsByCategory: PropsByCategory = {};
  nodes.forEach((node) => {
    const { mappedProperties } = node;
    const catId = getCategoryId(node);
    if (!(catId in propsByCategory)) {
      propsByCategory[catId] = {};
    }
    const catProps = propsByCategory[catId];

    if (mappedProperties === null || catProps === undefined) return;
    const keys = Object.keys(mappedProperties);
    keys.forEach((key) => {
      if (!(key in catProps)) {
        catProps[key] = {
          propertyKey: key,
          // @ts-expect-error unfortunately null check is not inferred
          dataType: mappedProperties[key].type,
        };
      }
    });
  });

  perspective.categories.forEach((cat) => {
    const propList = Object.values(propsByCategory[cat.id] ?? {});
    const newProps = propList.filter((prop) => !cat.properties.some((p) => p.name === prop.propertyKey));
    const hasAddedProps = newProps.length > 0;
    hasAddedProps &&
      dispatch(addPropertiesToCategory({ properties: newProps, categoryId: cat.id, perspectiveId: perspective.id }));
  });

  const propsByRelType: PropsByRelType = {};
  let hasRelAddedProps = false;
  relationships.forEach((rel) => {
    const { type, mappedProperties } = rel;
    if (!(type in propsByRelType)) {
      propsByRelType[type] = {};
    }
    const typeProps = propsByRelType[type];

    if (mappedProperties === null || typeProps === undefined) return;
    const keys = Object.keys(mappedProperties);
    keys.forEach((key) => {
      if (!(key in typeProps)) {
        hasRelAddedProps = true;
        typeProps[key] = {
          // @ts-expect-error unfortunately null check is not inferred
          dataType: mappedProperties[key].type,
          propertyKey: key,
          type,
        };
      }
    });
  });

  const propListsByRelType = mapValues(propsByRelType, (propMap) => Object.values(propMap));
  hasRelAddedProps &&
    dispatch(addPropertiesToRelationships({ relationshipsProps: propListsByRelType, perspectiveId: perspective.id }));
};

const inventoryMiddleware: AppMiddleware = ({ getState, dispatch }) => {
  return (next) => (action) => {
    const state = getState();
    if (isAction(action) && clearSlicerActions.includes(action.type)) {
      dispatch(setSlicerRanges([]));
    }

    if (isAction(action) && focusActions.includes(action.type)) {
      if (action.type === defocusAllEntities.type) {
        dispatch(clearExpanded());
      }

      requestCallback(() => dispatch(purgeInvisibleItems()));
    } else if (isAnyOf(updateNodeInventory, updateInventory, addToInventory)(action)) {
      updatePerspectiveAndEmptyWatermark(state, action.payload, dispatch);
    } else if (addToNodeInventory.match(action)) {
      updatePerspectiveAndEmptyWatermark(state, { nodes: action.payload }, dispatch);
    } else if (isAnyOf(setRelationshipIndex, addToRelationshipInventory)(action)) {
      updatePerspectiveAndEmptyWatermark(state, { relationships: action.payload }, dispatch);
    }

    return next(action);
  };
};

export default inventoryMiddleware;
