import type { UnknownAction } from 'redux';

import type { Style } from '../../types/style';
import { isPerspectiveSharingAvailable } from '../connections/connectionsDuck';
import { initialState as perspectivesInitialState } from '../perspectives/perspectives';
import { isSharedStorageAvailable, sharedStorageApi } from '../shared-storage/shared-storage.api';
import { initialState as stylesInitialState } from '../styles/styles';
import type { AppStore, RootState } from '../types';
import type { Persistor } from './types';

export class SharedStoragePersistor implements Persistor {
  async loadFromStorage({ store, payload }: { store: AppStore; payload: Partial<RootState> }) {
    const { getState } = store;
    const state = getState();

    const hasPlugin = Boolean(state?.connections?.availableProcedures) && isPerspectiveSharingAvailable(state);
    if (hasPlugin) return payload;

    const { data } = await store.dispatch(sharedStorageApi.endpoints.getPerspectives.initiate());
    /* TODO remove before merging */
    await store.dispatch(sharedStorageApi.endpoints.getScenes.initiate()).then(({ data }) => {
      // eslint-disable-next-line no-console
      console.log('printing the scenes', data);
    });
    /* END */
    if (data) {
      const { perspectives, styles } = data;

      payload.perspectives = payload.perspectives ?? { ...perspectivesInitialState };
      payload.perspectives.perspectives = perspectives;

      payload.styles = payload.styles ?? { ...stylesInitialState };
      payload.styles.styles = styles.reduce<Record<string, Style>>((acc, style) => {
        acc[style.id] = style;
        return acc;
      }, {});
    }

    return payload;
  }

  shouldLoadState({ action }: { action: UnknownAction }) {
    return !action && isSharedStorageAvailable();
  }
}
