import { getAuraInstanceId } from '@nx/neo4j-token-provider';
import * as StdLib from '@nx/stdlib';
import { createSelector, createSelectorCreator, weakMapMemoize } from '@reduxjs/toolkit';
import deepEqual from 'fast-deep-equal';
import { find } from 'lodash-es';

import type { RootState } from '../../store';
import { getAuthenticatedUrl } from './connection-utils';
import * as fromSlice from './connections-slice';
import { getLogicalDatabases, sortDatabases } from './database-utils';
import { type Database, isCompositeAlias } from './database.types';
import type { Connection } from './types';
import type { User } from './user.types';

// Default memoization function since reselect v5 is weakMapMemoize
// https://github.com/reduxjs/reselect/releases/tag/v5.0.1
const createDeepEqualSelector = createSelectorCreator(weakMapMemoize, { resultEqualityCheck: deepEqual });

// Returns augmented Connection state with computed properties
function selectConnection(state: fromSlice.Connection): Connection {
  const { protocol, hostname, port } = new StdLib.URLs.Neo4jURL(state.url);
  return {
    ...state,
    auraId: getAuraInstanceId(state.url),
    hostname,
    protocol,
    port,
    authenticatedUrl: getAuthenticatedUrl(state.url, state.credentials),
  };
}

export const selectActiveConnection: (state: RootState) => Connection | null = createDeepEqualSelector(
  (state: RootState) => fromSlice.selectActiveConnection(state.connections),
  (connection) => (connection ? selectConnection(connection) : null),
);

export const selectConnectionStatus: (state: RootState) => fromSlice.CONNECTION_STATUS = createSelector(
  (state: RootState) => fromSlice.selectConnectionStatus(state.connections),
  (url) => url,
);

export const selectCurrentUser: (state: RootState) => User | undefined = createDeepEqualSelector(
  (state: RootState) => fromSlice.selectCurrentUser(state.connections),
  (user) => user,
);

export const selectVersionAndEdition = createDeepEqualSelector(
  (state: RootState) => fromSlice.selectVersionAndEdition(state.connections),
  (versionAndEdition) => versionAndEdition,
);

export const selectConnections: (state: RootState) => Connection[] = createDeepEqualSelector(
  (state: RootState) => fromSlice.selectConnections(state.connections),
  (connections) => connections.map(selectConnection),
);

export const selectStaticConnectionUrl: (state: RootState) => ReturnType<typeof fromSlice.selectStaticConnectionUrl> =
  createSelector(
    (state: RootState) => fromSlice.selectStaticConnectionUrl(state.connections),
    (url) => url,
  );

const removeCompositeAliases = (databases: Database[]): Database[] => {
  return databases.map(({ aliases, ...db }) => ({
    ...db,
    aliases: aliases.filter((alias) => !isCompositeAlias(databases, alias)),
  }));
};

export const selectDatabases: (state: RootState) => Database[] = createDeepEqualSelector(
  (state: RootState) => fromSlice.selectDatabaseEntities(state.connections),
  (instances) => {
    const databases = Object.values(getLogicalDatabases(instances));
    const sortedDatabases = sortDatabases(databases);
    const uiDatabases = removeCompositeAliases(sortedDatabases);

    return uiDatabases;
  },
);

export const selectRawDatabases: (state: RootState) => Database[] = createDeepEqualSelector(
  (state: RootState) => fromSlice.selectAllDatabases(state.connections),
  (instances) => Object.values(instances),
);

export const selectActiveDatabaseName: (state: RootState) => ReturnType<typeof fromSlice.selectActiveDatabaseName> = (
  state: RootState,
) => fromSlice.selectActiveDatabaseName(state.connections);

export const selectActiveDatabase: (state: RootState) => Database | null = createDeepEqualSelector(
  [selectActiveDatabaseName, selectDatabases],
  (databaseName, databases) => {
    if (databaseName === undefined) {
      return null;
    }

    const database = find(databases, ({ name, aliases }) => name === databaseName || aliases.includes(databaseName));

    return database ? database : null;
  },
);
