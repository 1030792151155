import { Banner, Button, Dialog, TextInput } from '@neo4j-ndl/react';
import type { StackdriverIdentity } from '@nx/state';
import { DESTINATION_TYPE } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import React, { useState } from 'react';

import { SingleLineCodeBlock } from '../../shared/components/single-line-code-block';
import type { InnerCreateDialogProps } from '../helpers/props';

// Matching the validation in log-forwarding-api:
// components/log-forwarding-api/server/logforwarding/validate.go
const gcpProjectIdRegex = /^[a-z][-a-z0-9]{4,28}[a-z0-9]$/;
const bannedWords = ['google', 'null', 'undefined', 'ssl'];

const validateGcpProjectId = (projectId: string): string | null => {
  if (!gcpProjectIdRegex.test(projectId)) {
    return 'Must be a valid GCP Project ID: 6 to 30 characters in length, can only contain lowercase letters, numbers and hyphens, must start with a letter and cannot end with a hyphen.';
  }
  if (bannedWords.some((word) => projectId.includes(word))) {
    const bannedWordsWithQuotes = bannedWords.map((w) => `"${w}"`).join(', ');
    return `GCP Project ID must not contain any of the words ${bannedWordsWithQuotes}.`;
  }

  return null;
};

export const StackdriverCreationSteps = ({
  onCreate,
  loading,
  error,
  logForwardingIdentities,
  region,
  tier,
  step,
  setStep,
}: InnerCreateDialogProps) => {
  const [gcpProjectId, setGcpProjectId] = useState('');
  const [gcpProjectIdValidationError, setGcpProjectIdValidationError] = useState<string | null>();

  // We are making an assumption that this exists, and it _should_ exist
  // but what if it doesn't?
  const identity = logForwardingIdentities.find((id) => id.region === region && id.tier === tier);
  const serviceAccountEmail = identity
    ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      (identity as StackdriverIdentity).serviceAccountEmail
    : "Couldn't find a matching service account email!";

  const handleGcpProjectIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setGcpProjectId(value);

    if (isNotNullish(gcpProjectIdValidationError)) {
      setGcpProjectIdValidationError(validateGcpProjectId(value));
    }
  };

  const handleGcpProjectIdBlur = () => {
    setGcpProjectIdValidationError(validateGcpProjectId(gcpProjectId));
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handleCreate = () => {
    if (isNullish(region)) {
      return;
    }
    onCreate({
      stackdriver: {
        gcpProjectId: gcpProjectId,
      },
      type: DESTINATION_TYPE.STACKDRIVER,
    });
  };

  const isValidPayload = gcpProjectId !== '' && isNullish(gcpProjectIdValidationError);

  return (
    <>
      {step === 1 && (
        <>
          <Dialog.Content className="space-y-6">
            <TextInput
              label="Target GCP Project ID"
              value={gcpProjectId}
              onChange={handleGcpProjectIdChange}
              helpText='Your project ID will be listed next to the project name in parentheses as "Project ID: your-project-id"'
              isFluid
              errorText={gcpProjectIdValidationError}
              htmlAttributes={{
                placeholder: 'your-project-id',
                'data-testid': 'log-forwarding-gcp-project-id-input',
                onBlur: handleGcpProjectIdBlur,
              }}
            />
          </Dialog.Content>
          <Dialog.Actions>
            <Button fill="outlined" color="neutral" onClick={handlePrevStep}>
              Previous
            </Button>
            <Button
              isDisabled={!isValidPayload}
              color="primary"
              onClick={handleNextStep}
              htmlAttributes={{
                'data-testid': 'log-forwarding-next-step',
              }}
            >
              Next
            </Button>
          </Dialog.Actions>
        </>
      )}
      {step === 2 && (
        <>
          <Dialog.Content className="space-y-6">
            {error && (
              <Banner type="danger" usage="inline">
                {String(error.message)}
              </Banner>
            )}
            <p>
              Please grant our service account <strong>log writer role</strong> in your GCP project. Our service account
              is:
            </p>
            <SingleLineCodeBlock code={serviceAccountEmail} copyToClipboard data-testid="log-forwarding-identity" />
            <p>Follow these steps:</p>
            <ol className="my-6 list-decimal pl-6">
              <li>Go to the Google Cloud Console and select your project.</li>
              <li>
                Click on the &quot;Navigation menu&quot; button (☰) on the top-left corner of the console and select
                &quot;IAM & Admin&quot; &gt; &quot;IAM&quot;.
              </li>
              <li>
                Click on the &quot;+ Grant Access&quot; button at the top of the page to add a new principal to your
                project&apos;s IAM policy.
              </li>
              <li>
                In the &quot;New principals&quot; field, enter the email address of the service account you want to
                grant the &quot;Logs Writer&quot; role to. In this case, it should be{' '}
                <strong>{serviceAccountEmail}</strong>.
              </li>
              <li>
                In the &quot;Role&quot; field, start typing &quot;Logs Writer&quot; and select the &quot;Logs
                Writer&quot; role from the dropdown that appears.
              </li>
              <li>Click on the &quot;Save&quot; button to grant the role to the service account.</li>
            </ol>

            <Banner type="info" usage="inline">
              This process can take up to five minutes. You can check the status on this page.
            </Banner>
          </Dialog.Content>
          <Dialog.Actions>
            <Button fill="outlined" color="neutral" onClick={handlePrevStep}>
              Previous
            </Button>
            <Button
              color="primary"
              onClick={handleCreate}
              isLoading={loading}
              htmlAttributes={{
                'data-testid': 'log-forwarding-create',
              }}
            >
              Create
            </Button>
          </Dialog.Actions>
        </>
      )}
    </>
  );
};
