import { isNotNullish } from '@nx/stdlib';
import { createListenerMiddleware, isRejectedWithValue } from '@reduxjs/toolkit';

import { customCreateApi } from '../../context';
import type { RootState } from '../../store';
import { createDynamicBaseQuery } from '../../utils/create-dynamic-base-query';
import { selectCapability } from '../capabilities-slice';
import { selectAuraConfiguration } from '../configuration/configuration-slice';
import { getAccessToken, initialize, loginRedirect } from '../session-slice';
import { billingAccountEndpoints } from './endpoints/billing-account';
import { consumptionEndpoints } from './endpoints/consumption';
import { customEndpointsEndpoints } from './endpoints/custom-endpoints';
import { customerEndpoints } from './endpoints/customer';
import { databaseEndpoints } from './endpoints/database';
import { encryptionKeysEndpoints } from './endpoints/encryption-key';
import { graphDataScienceEndpoints } from './endpoints/graphdatascience';
import { instanceEndpoints } from './endpoints/instance';
import { inviteEndpoints } from './endpoints/invite';
import { invoiceEndpoints } from './endpoints/invoice';
import { logEndpoints } from './endpoints/log';
import { logForwardingEndpoints } from './endpoints/log-forwarding';
import { marketplaceEndpoints } from './endpoints/marketplace';
import { oauthEndpoints } from './endpoints/oauth';
// eslint-disable-next-line import/no-cycle
import { organizationEndpoints } from './endpoints/organization';
import { projectEndpoints } from './endpoints/project';
import { snapshotEndpoints } from './endpoints/snapshot';
import { ssoConfigEndpoints } from './endpoints/sso-config';
import { trafficConfigEndpoints } from './endpoints/traffic-config';
import type { Builder } from './endpoints/types';
import { tagTypes } from './endpoints/types';
import { usageEndpoints } from './endpoints/usage';
import { userEndpoints } from './endpoints/user';

const consoleApi = customCreateApi({
  baseQuery: createDynamicBaseQuery((state) => selectAuraConfiguration(state)?.consoleApiUrl, {
    prepareHeaders: async (headers: Headers) => {
      if (!headers.has('Authorization')) {
        const accessToken = await getAccessToken();
        headers.set('Authorization', `Bearer ${accessToken}`);
      } else if (headers.get('Authorization') === 'SKIP') {
        headers.delete('Authorization');
      }

      headers.set('Snake-Case-Response', 'Enabled');
    },
  }),
  reducerPath: 'consoleApi',
  tagTypes,
  endpoints: (builder: Builder) => ({
    ...instanceEndpoints(builder),
    ...databaseEndpoints(builder),
    ...userEndpoints(builder),
    ...projectEndpoints(builder),
    ...organizationEndpoints(builder),
    ...snapshotEndpoints(builder),
    ...inviteEndpoints(builder),
    ...encryptionKeysEndpoints(builder),
    ...invoiceEndpoints(builder),
    ...usageEndpoints(builder),
    ...consumptionEndpoints(builder),
    ...billingAccountEndpoints(builder),
    ...logEndpoints(builder),
    ...customerEndpoints(builder),
    ...oauthEndpoints(builder),
    ...ssoConfigEndpoints(builder),
    ...trafficConfigEndpoints(builder),
    ...logForwardingEndpoints(builder),
    ...customEndpointsEndpoints(builder),
    ...graphDataScienceEndpoints(builder),
    ...marketplaceEndpoints(builder),
  }),
});
type ConsoleError = {
  data: { reason: string; message: string };
  status: number;
};

const isConsoleError = (payload: unknown): payload is ConsoleError =>
  isNotNullish(payload) &&
  'status' in payload &&
  payload.status === 401 &&
  'data' in payload &&
  isNotNullish(payload.data);

export function createAuraApiListenerMiddleware() {
  const middleware = createListenerMiddleware<RootState>();
  middleware.startListening({
    matcher: isRejectedWithValue,
    effect: async (action, listenerApi) => {
      const { payload } = action;
      if (isConsoleError(payload) && payload.data.reason === 'user-logged-out') {
        await loginRedirect();
      }
      // send a message to the server here containing info from the action
    },
  });

  // Fetch aura user when the user has logged in
  middleware.startListening({
    // TODO: This should be removed once we have a real API to talk to
    actionCreator: initialize.rejected,
    effect(_, listenerApi) {
      const useMockApi = selectCapability(listenerApi.getState(), { key: 'framework:mock-api' });
      if (useMockApi) {
        void listenerApi.dispatch(consoleApi.endpoints.getUserDetails.initiate());
      }
    },
  });

  middleware.startListening({
    actionCreator: initialize.fulfilled,
    effect(_, listenerApi) {
      const useMockApi = selectCapability(listenerApi.getState(), { key: 'framework:mock-api' });
      // TODO: This check should be removed once we have a real API to talk to
      if (useMockApi) {
        void listenerApi.dispatch(consoleApi.endpoints.getUserDetails.initiate());
      }
    },
  });

  return middleware.middleware;
}

export default consoleApi;
