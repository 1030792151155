import { groupBy } from 'lodash-es';
import type { Scale } from 'uplot';

import { compactFormatAbsoluteNumber, formatBytes, formatDuration } from '../../shared/ui-helpers';
import { combineOptions } from '../plot/utils';
import { LOCALE_TAG } from '../shared/constants';
import type { YUplotValue } from '../shared/types';
import type { ChartWidgetPartialProps } from './fullstack-chart-props';
import DefaultChartTooltip from './tooltip';

const TIME_TOOLTIP_PRECISION = 3;
const SCALE_PADDING = 0.15;

/**
 * Formatting wrappers
 * we can tidy this up
 */
export const formatBytesWrapper = (value: YUplotValue) => (typeof value === 'number' ? formatBytes(value, 2) : 'NaN');

export const formatInt = (value: YUplotValue) => (typeof value === 'number' ? value.toFixed(0) : 'NaN');

export const formatDurationWrapper = (value: YUplotValue, precision = 0) => {
  return typeof value === 'number' ? formatDuration(value, precision) : 'NaN';
};

export const formatPercent = (value: YUplotValue) => {
  return typeof value === 'number'
    ? Number(value / 100).toLocaleString(LOCALE_TAG, {
        style: 'percent',
        maximumFractionDigits: 2,
      })
    : 'NaN';
};

export const formatFractionPercent = (value: YUplotValue) => {
  return typeof value === 'number'
    ? Number(value).toLocaleString(LOCALE_TAG, {
        style: 'percent',
        maximumFractionDigits: 3,
      })
    : 'NaN';
};

export const formatNanoToPercent = (value: YUplotValue) => {
  return typeof value === 'number'
    ? Number(value / 1000000000).toLocaleString(LOCALE_TAG, {
        style: 'percent',
        maximumFractionDigits: 2,
      })
    : 'NaN';
};

export const formatFloat = (value: YUplotValue) => (typeof value === 'number' ? value.toFixed(1) : 'NaN');

export const formatAbsInteger = (value: YUplotValue) => {
  return typeof value === 'number' ? compactFormatAbsoluteNumber(value) : 'NaN';
};

/**
 * Formats values with increasing precision until all formatted are unique or maxPrecision is reached
 */
export const formatLimitDuplicates = (
  formatter: (value: number, precision: number) => string,
  values: number[],
  minPrecision: number,
  maxPrecision = minPrecision,
): string[] => {
  const groupedByFmtVal = groupBy(values, (v) => formatter(v, minPrecision));
  const formattedValues = Object.entries(groupedByFmtVal).map(([fmtVal, occurrences]) => {
    if (occurrences.length > 1) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return minPrecision < maxPrecision
        ? formatLimitDuplicates(formatter, occurrences, minPrecision + 1, maxPrecision)
        : Array(occurrences.length).fill(fmtVal);
    }

    return [fmtVal];
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return formattedValues.flat();
};

export const valuesFormatter =
  (type: 'bigCount' | 'bytes' | 'time', minPrecision: number, maxPrecision: number) => (values: number[]) => {
    let formatter = compactFormatAbsoluteNumber;
    let initialPrecision = 0;

    if (type === 'bytes') {
      formatter = formatBytes;
      initialPrecision = minPrecision;
    }

    if (type === 'time') {
      formatter = formatDuration;
      initialPrecision = minPrecision;
    }

    return formatLimitDuplicates(formatter, values, initialPrecision, maxPrecision);
  };

const minutes = (min: number) => min * 60 * 1000;
const hours = (h: number) => h * minutes(60);

export const formatCumulativeDurations = (durations: number[]) => {
  const maxDuration = Math.max(...durations);
  let precision = 0;

  if (maxDuration < minutes(1)) {
    precision = 3;
  } else if (maxDuration < hours(1)) {
    precision = 2;
  } else if (maxDuration < hours(9)) {
    precision = 1;
  }

  return formatLimitDuplicates(formatDuration, durations, 0, precision);
};

/**
 * Tooltip value Formatters
 */
export const percentageTooltip = DefaultChartTooltip(formatPercent);

export const smallPercentageTooltip = DefaultChartTooltip(formatFractionPercent);

export const nanoToPercentageTooltip = DefaultChartTooltip(formatNanoToPercent);

export const bytesTooltip = DefaultChartTooltip(formatBytesWrapper);

export const timeTooltip = DefaultChartTooltip((v) => formatDurationWrapper(v, TIME_TOOLTIP_PRECISION));

export const intTooltip = DefaultChartTooltip(formatInt);

export const rateTooltip = DefaultChartTooltip(formatFloat);

type ValueFormatter = (v: number) => string;
type ValuesFormatter = (v: number[]) => string[];

export const simpleValuesFormatter =
  (formatter: ValueFormatter): ValuesFormatter =>
  (values) =>
    values.map((v) => formatter(v));

export const plotOptionsYAxisValuesFormatter = (formatter: ValuesFormatter): Partial<uPlot.Options> => ({
  axes: [{}, { values: (_, values) => formatter(values) }],
});

export const plotOptionsDuplicateBytesFormatter = plotOptionsYAxisValuesFormatter(valuesFormatter('bytes', 2, 3));

export const plotOptionsCumulativeDurationsFormatter = plotOptionsYAxisValuesFormatter(formatCumulativeDurations);

/** simplified version of uPlot internal `genIncrs` https://github.com/leeoniya/uPlot/blob/master/src/utils.js#L351 */
function genIncrs(onlyWholeNumbers: boolean) {
  const mults = [1, 1.5, 2, 2.5, 5];
  const initialExp = onlyWholeNumbers ? 0 : -4;
  const incrs: number[] = [];
  for (let exp = initialExp; exp < 16; exp += 1) {
    const magnitude = 10 ** exp;
    for (const mult of mults) {
      const incr = mult * magnitude;
      incrs.push(incr);
    }
  }
  return onlyWholeNumbers ? incrs.filter((v) => v % 1 === 0) : incrs;
}

export const WholeIncrements = genIncrs(true);

export const TinyIncrements = genIncrs(false);

/** defaults to whole increments between 1 and 1e16 */
export const plotOptionsYAxisIncrements = (
  incrs: number[] = WholeIncrements,
  range: Scale.Range | undefined = undefined,
): Partial<uPlot.Options> => ({
  axes: [{}, { incrs }],
  scales: {
    y: {
      range:
        range ??
        ((_, initMin, initMax) => (initMax > 1 ? [Math.min(0, initMin), initMax * (1 + SCALE_PADDING)] : [0, 1])),
    },
  },
});

export const plotOptionsYAxisLocalRangeIncrements = (
  incrs: number[] = WholeIncrements,
  range: Scale.Range | undefined = undefined,
): Partial<uPlot.Options> => {
  return {
    axes: [{}, { incrs }],
    scales: {
      y: {
        range: (u, min, max) => {
          // To calculate a local range that visually distributes datapoints, a simple strategy would be to use the
          // applied increments to offset the initial value and limit value to it's nearest increment instead of
          // starting with 0 and ending with maximum increment value. This localizes the Y-axiz values to only focus
          // within the absolute range.

          // first take the absolute range which is the difference between min & max
          const diff = max - min;

          // `WholeIncrements` gives a set of decimal exponents(powers of 10) and their multipliers. Uplot by default
          // chooses an increment to apply based on the exponent that divides the range from 0 to max. As Last committed
          // Transaction ID doesn't fall behind too far in lagging servers, considering only the initial increments
          // would be a good enough to offset a local range around min & max.
          // eslint-disable-next-line prefer-destructuring
          const appliedIncr = incrs[0];

          // trivial case when min and max are same, offsetting the plot to center of the Y-axis.
          if (diff === 0 || appliedIncr === undefined) {
            return [min / 2, max * 2];
          }

          return [min < appliedIncr ? min / 2 : min - appliedIncr, max + appliedIncr * 2];
        },
      },
    },
  };
};

/**
 * Scales configs
 */

export const zeroToPaddedMaxYScale = (minimumMax = 100): Partial<uPlot.Options> => ({
  scales: {
    y: {
      range: (_, __, initMax) => (initMax > 0 ? [0, initMax * (1 + SCALE_PADDING)] : [0, minimumMax]),
    },
  },
});

/**
 * Convenient configs
 */
export const PercentageChartConfig: Partial<ChartWidgetPartialProps> = {
  chartValueType: 'percentage',
  chartTooltip: percentageTooltip,
  uplotOptions: combineOptions(
    { scales: { y: { range: [0, 100] } } },
    plotOptionsYAxisValuesFormatter(simpleValuesFormatter(formatPercent)),
  ),
};

export const SmallPercentageChartConfig: Partial<ChartWidgetPartialProps> = {
  chartValueType: 'percentage',
  chartTooltip: smallPercentageTooltip,
  uplotOptions: combineOptions(
    plotOptionsYAxisValuesFormatter(simpleValuesFormatter(formatFractionPercent)),
    zeroToPaddedMaxYScale(0.01),
  ),
};

export const DashboardPercentageChartConfig: Partial<ChartWidgetPartialProps> = {
  chartValueType: 'percentage',
  chartTooltip: percentageTooltip,
  uplotOptions: combineOptions(
    plotOptionsYAxisValuesFormatter(simpleValuesFormatter(formatPercent)),
    plotOptionsYAxisIncrements([25, 50, 75, 100], [0, 100]),
  ),
};

export const BytesChartConfig: Partial<ChartWidgetPartialProps> = {
  chartValueType: 'bytes',
  chartTooltip: bytesTooltip,
  uplotOptions: combineOptions(
    plotOptionsYAxisValuesFormatter(simpleValuesFormatter(formatBytesWrapper)),
    zeroToPaddedMaxYScale(),
  ),
};

export const IntChartConfig: Partial<ChartWidgetPartialProps> = {
  chartValueType: 'int',
  chartTooltip: intTooltip,
  uplotOptions: combineOptions(
    plotOptionsYAxisValuesFormatter(simpleValuesFormatter(formatInt)),
    plotOptionsYAxisIncrements(),
  ),
};

export const DashboardIntChartConfig: Partial<ChartWidgetPartialProps> = {
  chartValueType: 'int',
  uplotOptions: combineOptions(
    plotOptionsYAxisValuesFormatter(simpleValuesFormatter(formatInt)),
    plotOptionsYAxisIncrements(),
  ),
};

export const TimeChartConfig: Partial<ChartWidgetPartialProps> = {
  chartValueType: 'time',
  chartTooltip: timeTooltip,
  uplotOptions: combineOptions(
    plotOptionsYAxisValuesFormatter(simpleValuesFormatter(formatDurationWrapper)),
    zeroToPaddedMaxYScale(),
  ),
};

export const BigCountersConfig: Partial<ChartWidgetPartialProps> = {
  chartValueType: 'int',
  uplotOptions: combineOptions(
    plotOptionsYAxisValuesFormatter(valuesFormatter('bigCount', 0, 1)),
    plotOptionsYAxisIncrements(),
  ),
};

export const RateChartConfig: Partial<ChartWidgetPartialProps> = {
  chartValueType: 'rate',
  chartTooltip: rateTooltip,
  uplotOptions: combineOptions(
    plotOptionsYAxisValuesFormatter(simpleValuesFormatter(formatFloat)),
    plotOptionsYAxisIncrements(TinyIncrements),
  ),
};

export const toPercentage: ChartWidgetPartialProps['valueMapper'] = (value) => {
  return typeof value === 'number' ? value * 100 : value;
};
