import type { MetricsData, SeriesSummary, TimeRange } from '@nx/state';
import { merge } from 'lodash-es';
import { useCallback, useReducer } from 'react';
import uPlot from 'uplot';

import type { ChartWidgetPartialProps } from '../charts/fullstack-chart-props';
import { PercentageChartConfig } from '../charts/helpers';
import DefaultChartTooltip from '../charts/tooltip';
import { UPlotOptionsBuilder, tooltipPlacementPlugin } from '../plot';
import { calculatePercentage, formatUplot, syncCursor } from '../shared/utils';

type ChartFlag = keyof Pick<ChartState, 'showTotals' | 'showPercentage'>;

type ProcessDataAction = {
  type: 'ProcessData';
  data: MetricsData;
  timeRange: TimeRange;
  chartProps: ChartWidgetPartialProps;
};

type ToggleChartFlagAction = {
  type: 'ToggleChartFlag';
  flag: ChartFlag;
};

type ChartAction = ProcessDataAction | ToggleChartFlagAction;

export type ChartState = {
  /** generated from raw (mapped) data */
  alignedData: uPlot.AlignedData;
  /** final uPlot data, can be alignedData or alignedData mapped as percent */
  alignedDataDisplay: uPlot.AlignedData;
  /** generated along with alignedData, from raw data */
  seriesOptionsList: uPlot.Series[];
  showTotals: boolean;
  showPercentage: boolean;
};

const INITIAL_STATE: ChartState = {
  alignedData: [],
  alignedDataDisplay: [],
  seriesOptionsList: [],
  showPercentage: false,
  showTotals: false,
};

export const chartKey = (chartProps: ChartWidgetPartialProps | ChartWidgetPartialProps[]) => {
  const { title, subtitle, chartType } = Array.isArray(chartProps) ? (chartProps[0] ?? {}) : chartProps;
  return [chartType, title, subtitle].join('-');
};

export const overviewPlotsSync = uPlot.sync('overview-screen');
let zoomedChart = '';

export const prepareBuilder = ({
  state,
  chartProps,
  ignoredInstances,
  isExpanded,
  onZoom,
  onDblClick,
  isDarkTheme,
  seriesSummary,
}: {
  state: ChartState;
  chartProps: ChartWidgetPartialProps | undefined;
  allChartConfigs?: ChartWidgetPartialProps[];
  ignoredInstances: string[];
  isExpanded: boolean;
  onZoom?: (timeRange?: TimeRange) => void;
  onDblClick?: () => void;
  isDarkTheme: boolean;
  seriesSummary: SeriesSummary[];
}) => {
  const { seriesOptionsList, showPercentage, showTotals } = state;

  const finalChartProps = merge({}, chartProps, showPercentage ? PercentageChartConfig : {});

  const builder = new UPlotOptionsBuilder(isDarkTheme);

  seriesOptionsList.forEach((s) => {
    if (showPercentage && s.referenceSeries?.isReference === true) {
      return;
    }
    builder.addSeries(s);
  });

  builder.setInstanceSeriesVisibility(ignoredInstances, showTotals);

  builder.addAxis(finalChartProps);

  if (!isExpanded) {
    builder.updateCursor(syncCursor(overviewPlotsSync.key));
    builder.addHook('destroy', (self) => {
      overviewPlotsSync.unsub(self);
    });
  }

  if (finalChartProps.uplotOptions?.scales) {
    builder.updateScales(finalChartProps.uplotOptions.scales);
  }

  builder.addPlugin(tooltipPlacementPlugin(finalChartProps.chartTooltip ?? DefaultChartTooltip(), seriesSummary));

  if (onZoom) {
    builder.updateCursor({
      bind: {
        mousedown: (_, __, handler) => (e) => {
          zoomedChart = chartKey(finalChartProps);
          return handler(e);
        },
      },
    });
    builder.addHook('setSelect', (u) => {
      if (zoomedChart === chartKey(finalChartProps) && u.select.width > 0) {
        const min = u.posToVal(u.select.left, 'x');
        const max = u.posToVal(u.select.left + u.select.width, 'x');
        const timeRange =
          typeof min === 'number' && typeof max === 'number'
            ? {
                startTime: new Date(Math.floor(min)),
                endTime: new Date(Math.ceil(max)),
              }
            : undefined;

        onZoom(timeRange);
      }
    });
  }

  if (onDblClick) {
    builder.updateCursor({
      bind: {
        dblclick: (_, __, handler) => (e) => {
          onDblClick();
          return handler(e);
        },
      },
    });
  }

  return builder;
};

export const prepareDashboardPlotOptionsBuilder = ({
  state,
  chartProps,
  ignoredInstances,
  isDarkTheme,
}: {
  state: ChartState;
  chartProps: ChartWidgetPartialProps | undefined;
  ignoredInstances: string[];
  isDarkTheme: boolean;
}) => {
  const { seriesOptionsList, showPercentage, showTotals } = state;

  const finalChartProps = merge({}, chartProps);

  const builder = new UPlotOptionsBuilder(isDarkTheme);

  seriesOptionsList.forEach((s) => {
    if (showPercentage && s.referenceSeries?.isReference === true) {
      return;
    }
    builder.addSeries(s);
  });

  builder.setInstanceSeriesVisibility(ignoredInstances, showTotals);

  builder.addAxis(finalChartProps);

  if (finalChartProps.uplotOptions?.scales) {
    builder.updateScales(finalChartProps.uplotOptions.scales);
  }

  builder.addPlugin(tooltipPlacementPlugin(finalChartProps.chartTooltip ?? DefaultChartTooltip(), []));

  return builder;
};

const mapAlignedDataAsPercent = (
  alignedData: uPlot.AlignedData,
  seriesOptionsList: uPlot.Series[],
): uPlot.AlignedData => {
  const [xData, ...yData] = alignedData;
  const yDataWithPercents = yData.map((instanceData, idx) => {
    const referenceInfo = seriesOptionsList[idx]?.referenceSeries;

    if (!referenceInfo || referenceInfo.isReference) {
      return instanceData;
    }

    const referenceYData = yData[referenceInfo.othersIndex];

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (instanceData as (number | null)[]).map((value, valueIdx) => {
      const referenceValue = referenceYData?.[valueIdx];
      if (typeof value !== 'number' || typeof referenceValue !== 'number') {
        return null;
      }
      return calculatePercentage(value, referenceValue);
    });
  });
  return [xData, ...yDataWithPercents];
};

type ActionHandler<T extends ChartAction> = (state: ChartState, action: T) => ChartState;

const handleProcessData: ActionHandler<ProcessDataAction> = (state, action) => {
  const { data, timeRange, chartProps } = action;

  const { alignedData, seriesOptionsList } = formatUplot(data, timeRange, chartProps);

  const alignedDataDisplay = state.showPercentage
    ? mapAlignedDataAsPercent(alignedData, seriesOptionsList)
    : alignedData;

  return {
    ...state,
    alignedData,
    alignedDataDisplay,
    seriesOptionsList,
  };
};

const handleToggleChartFlag: ActionHandler<ToggleChartFlagAction> = (state, action) => {
  const { flag } = action;

  const toggledFlag = !state[flag];
  const toggledState = { ...state, [flag]: toggledFlag };

  let { alignedDataDisplay } = toggledState;
  if (flag === 'showPercentage') {
    alignedDataDisplay = toggledFlag
      ? mapAlignedDataAsPercent(toggledState.alignedData, toggledState.seriesOptionsList)
      : toggledState.alignedData;
  }

  return {
    ...toggledState,
    alignedDataDisplay,
  };
};

const reducer = (state: ChartState, action: ChartAction): ChartState => {
  switch (action.type) {
    case 'ProcessData':
      return handleProcessData(state, action);

    case 'ToggleChartFlag':
      return handleToggleChartFlag(state, action);

    default:
      return state;
  }
};

export const useChartState = (chartProps?: ChartWidgetPartialProps) => {
  const toggle = chartProps?.referenceMetricConfig?.toggledByDefault === true;
  const [state, dispatch] = useReducer(reducer, {
    ...INITIAL_STATE,
    showPercentage: toggle,
  });

  const processData = useCallback(
    (payload: Omit<ProcessDataAction, 'type'>) =>
      dispatch({
        type: 'ProcessData',
        ...payload,
      }),
    [],
  );

  const toggleChartFlag = useCallback(
    (flag: ChartFlag) =>
      dispatch({
        type: 'ToggleChartFlag',
        flag,
      }),
    [],
  );

  return {
    state,
    dispatch,
    processData,
    toggleChartFlag,
  };
};
