import { Avatar, type AvatarProps } from '@neo4j-ndl/react';
import { useEffect, useState } from 'react';

export const formatInitials = (nameOrEmail?: string | null) => {
  const sanitized = nameOrEmail ?? '';

  // Strip @domain from email or anything after a +
  const nameOrUser = sanitized.replace(/[@+].*/, '');

  // Split by word boundary / underscore (covers most weird email address characters)
  const tokens = nameOrUser.split(/[\W_]/);

  let initials;
  switch (tokens.length) {
    case 0:
      initials = '';
      break;
    case 1:
      initials = tokens[0]?.substring(0, 2);
      break;
    case 2:
    default:
      initials = (tokens[0]?.substring(0, 1) ?? '') + tokens[tokens.length - 1]?.substring(0, 1);
      break;
  }

  initials = (initials ?? '').toUpperCase();

  return initials.match(/^[A-Z]{1,2}$/) ? initials : '';
};

export const UserAvatar = ({
  name,
  picture,
  size,
}: {
  name: string;
  picture: string | undefined;
  size?: AvatarProps['size'];
}) => {
  const [error, setError] = useState(false);
  const isValidPicture = Boolean(picture) && !error;

  useEffect(() => setError(false), [picture]);

  return (
    <Avatar
      size={size}
      source={picture}
      type={isValidPicture ? 'image' : 'letters'}
      htmlAttributes={{
        onError: () => setError(true),
      }}
    />
  );
};
