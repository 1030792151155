import { isNullish } from '@nx/stdlib';

import type { DatabaseCredentials } from './env';

export const PROTOCOL_BOLT = 'bolt:' as const;
export const PROTOCOL_BOLT_SECURE = 'bolt+s:' as const;
export const PROTOCOL_NEO4J = 'neo4j:' as const;
export const PROTOCOL_NEO4J_SECURE = 'neo4j+s:' as const;
export const PROTOCOL_HTTP = 'http:' as const;
export const PROTOCOL_HTTPS = 'https:' as const;

export const UNSECURE_PROTOCOLS = [PROTOCOL_BOLT, PROTOCOL_NEO4J, PROTOCOL_HTTP] as const;
export const SECURE_PROTOCOLS = [PROTOCOL_BOLT_SECURE, PROTOCOL_NEO4J_SECURE, PROTOCOL_HTTPS] as const;

export const CONNECTION_PROTOCOLS = [
  PROTOCOL_BOLT,
  PROTOCOL_BOLT_SECURE,
  PROTOCOL_NEO4J,
  PROTOCOL_NEO4J_SECURE,
  PROTOCOL_HTTP,
  PROTOCOL_HTTPS,
] as const;

// This is to satisfy TypeScript string comparisons
export const CONNECTION_PROTOCOLS_STRING: string[] = [...CONNECTION_PROTOCOLS];

export type ConnectionProtocol = (typeof CONNECTION_PROTOCOLS)[number];

/**
 * Type guard for Neo4j protocols
 *
 * @param input
 * @returns
 */
export function isConnectionProtocol(input: unknown): input is ConnectionProtocol {
  for (const protocol of CONNECTION_PROTOCOLS) {
    if (input === protocol) {
      return true;
    }
  }

  return false;
}

export function isLocalhost(url: string) {
  return url.includes('localhost') || url.includes('127.0.0.1');
}

/**
 * Replace `http(s)` protocol with respective `neo4j(+s)`
 *
 * @param url
 * @returns
 */
function normalizeConnectionUrl(url: string) {
  if (url.startsWith('http:')) {
    return url.replace('http:', PROTOCOL_NEO4J);
  }

  if (url.startsWith('https:')) {
    return url.replace('https:', PROTOCOL_NEO4J_SECURE);
  }

  return url;
}

/**
 * Parse a string as Neo4j URL
 *
 * Expected format: `protocol://[username]@hostname[:port][/database][?params]
 */
export class Neo4jURL extends URL {
  private originalProtocol: ConnectionProtocol;

  private httpProtocol: 'http:' | 'https:';

  constructor(url: string, { replaceHttpProtocol = false } = {}) {
    const { protocol } = new URL(replaceHttpProtocol ? normalizeConnectionUrl(url) : url);

    if (!isConnectionProtocol(protocol)) {
      throw new TypeError('Invalid protocol, expected "bolt:", "bolt+s:", "neo4j:", "neo4j+s:", "http:" or "https:"');
    }

    // Always replace with HTTP to retain the port information, as
    //    new URL('https://...:443').port === ''
    const compatibleUrl = url.replace(protocol, 'http:');
    super(compatibleUrl);

    this.originalProtocol = protocol;
    this.httpProtocol = protocol.includes('+s') ? 'https:' : 'http:';

    if (this.pathname.split('/').length > 2) {
      throw new TypeError('Invalid pathname, expected one level of depth');
    }
  }

  /**
   * Get database name extracted from pathname
   */
  get database() {
    const [, database] = super.pathname.split('/');
    return database === '' ? undefined : database;
  }

  get href() {
    return super.href.replace(super.protocol, this.protocol);
  }

  get origin() {
    return super.origin.replace(super.protocol, this.protocol);
  }

  get protocol() {
    return this.originalProtocol;
  }

  /**
   * Get SSO discovery URL with Neo4j protocol replaced by respective HTTP(S) protocol
   *
   * @returns
   */
  toDiscoveryUrl() {
    return super.origin.replace(super.protocol, this.httpProtocol);
  }

  /**
   * Get driver-compatible URL
   *
   * @returns
   */
  toDriverUrl() {
    return `${this.protocol}//${this.host}${this.search}`;
  }

  toString(): string {
    let internalUrl = super.toString();
    internalUrl = internalUrl.replace(super.protocol, this.protocol);
    if (this.pathname === '/') {
      // Pathname should not appear in URL unless it has database name
      internalUrl = internalUrl.replace(`${this.host}${this.pathname}`, this.host);
    }
    return internalUrl;
  }

  static asNullable(url: string | null | undefined) {
    if (
      url === undefined ||
      url === null ||
      url === '' ||
      CONNECTION_PROTOCOLS_STRING.includes(url.replace('//', ''))
    ) {
      return null;
    }

    try {
      // We replace ' s://' with '+s://' since in unencoded searchParams
      // the plus sign is treated as a space
      return new Neo4jURL(url.replace(/^(neo4j|bolt|http)\ss:\/\//g, '$1+s://'));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return null;
    }
  }

  static fromCredentials(credentials: DatabaseCredentials) {
    return new Neo4jURL(`${credentials.protocol}//${credentials.url}`);
  }
}

type SimpleLocation = {
  href: string;
  search: string;
  origin: string;
  pathname: string;
  hash: string;
};

export const getUrlWithCleanedSearchParamsAndHash = (location: SimpleLocation, searchParamNamesToDelete: string[]) => {
  const { search, origin, pathname, hash } = location;
  const currentUrlSearchParams = new URLSearchParams(search);
  const cleansedSearchParams: Record<string, string> = {};
  let isUrlUpdateRequired = hash !== '';
  currentUrlSearchParams.forEach((value: string, key: string) => {
    if (searchParamNamesToDelete.includes(key)) {
      isUrlUpdateRequired = true;
    } else {
      cleansedSearchParams[key] = value;
    }
  });
  if (!isUrlUpdateRequired) {
    return null;
  }
  const newUrlEnd =
    Object.keys(cleansedSearchParams).length === 0 ? '' : `?${new URLSearchParams(cleansedSearchParams).toString()}`;
  return `${origin}${pathname}${newUrlEnd}`;
};

export const cleanSearchParamsAndHash = (searchParamNamesToDelete: string[]): void => {
  const newUrl = getUrlWithCleanedSearchParamsAndHash(window.location, searchParamNamesToDelete);
  if (!isNullish(newUrl)) {
    window.history.replaceState({}, '', new URL(newUrl));
  }
};
