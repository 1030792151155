import type {
  LogsDownloadDeleteRequest,
  LogsDownloadDeleteResponse,
  LogsDownloadUrlRequest,
  LogsDownloadUrlResponse,
  LogsDownloadsRequestBody,
  LogsDownloadsResponse,
  LogsInitiateDownloadResponse,
  PaginatedQueryLogAggregations,
  PaginatedQueryLogs,
  PaginatedSecurityLogs,
  PaginatedSecurityLogsSummary,
  QueryLogCounts,
  QueryLogCountsRequest,
  QueryLogFilterValues,
  QueryLogFilterValuesRequest,
  QueryLogsInitiateDownloadDetailsRequestBody,
  QueryLogsInitiateDownloadSummaryRequestBody,
  QueryLogsRequest,
  QueryLogsSummaryRequest,
  SecurityLogCounts,
  SecurityLogCountsRequest,
  SecurityLogFilterValues,
  SecurityLogFilterValuesRequest,
  SecurityLogsInitiateDownloadDetailsRequestBody,
  SecurityLogsInitiateDownloadSummaryRequestBody,
  SecurityLogsRequest,
  SecurityLogsSummaryRequest,
} from '../api-types/logs';
import type { TransformedLogsDownloadsResponse } from '../transformers/logs';
import {
  transformQueryLogFilterValuesResponse,
  transformQueryLogsDownloadsResponse,
  transformSecurityLogFilterValuesResponse,
} from '../transformers/logs';
import type { AvailableDbmsFilters } from '../types/logs';
import type { AvailableSecurityLogFilters } from '../types/security-logs';
import type { Builder } from './types';

const QUERY_LOGS_BASE_PATH = '/logs/query-logs';
const LOGS_DOWNLOADS_BASE_PATH = `logs/downloads`;
const SECURITY_LOGS_BASE_PATH = `/logs/security-logs`;

export const logsEndpoints = (builder: Builder) => ({
  getQueryLogFilterValues: builder.query<AvailableDbmsFilters, QueryLogFilterValuesRequest>({
    query: (queryLogFilterValuesRequest) => ({
      url: `${QUERY_LOGS_BASE_PATH}/filter-values`,
      method: 'POST',
      body: queryLogFilterValuesRequest,
      headers: { 'Project-Id': queryLogFilterValuesRequest.tenantId },
    }),
    providesTags: (result, error, queryLogFilterValuesRequest) => [
      { type: 'Logs', id: queryLogFilterValuesRequest.dbmsId },
    ],
    transformResponse: (res: QueryLogFilterValues) => transformQueryLogFilterValuesResponse(res),
  }),

  getQueryLogs: builder.query<PaginatedQueryLogs, QueryLogsRequest>({
    query: (queryLogsRequest) => ({
      url: QUERY_LOGS_BASE_PATH,
      method: 'POST',
      body: queryLogsRequest,
      headers: { 'Project-Id': queryLogsRequest.tenantId },
    }),
    providesTags: (result, error, queryLogsRequest) => [{ type: 'Logs', id: queryLogsRequest.dbmsId }],
  }),

  getQueryLogAggregations: builder.query<PaginatedQueryLogAggregations, QueryLogsSummaryRequest>({
    query: (queryLogsSummaryRequest) => ({
      url: `${QUERY_LOGS_BASE_PATH}/summary`,
      method: 'POST',
      body: queryLogsSummaryRequest,
      headers: { 'Project-Id': queryLogsSummaryRequest.tenantId },
    }),
    providesTags: (result, error, queryLogsSummaryRequest) => [{ type: 'Logs', id: queryLogsSummaryRequest.dbmsId }],
  }),

  getQueryLogCounts: builder.query<QueryLogCounts, QueryLogCountsRequest>({
    query: (queryLogCountsRequest) => ({
      url: `${QUERY_LOGS_BASE_PATH}/counts`,
      method: 'POST',
      body: queryLogCountsRequest,
      headers: { 'Project-Id': queryLogCountsRequest.tenantId },
    }),
    providesTags: (result, error, queryLogCountsRequest) => [{ type: 'Logs', id: queryLogCountsRequest.dbmsId }],
  }),

  // logs download
  initiateQueryLogsDownload: builder.mutation<
    LogsInitiateDownloadResponse,
    QueryLogsInitiateDownloadDetailsRequestBody
  >({
    query: (body) => ({
      url: `${LOGS_DOWNLOADS_BASE_PATH}/query/initiate-details`,
      method: 'POST',
      body,
      headers: { 'Project-Id': body.tenantId },
    }),
    invalidatesTags: (_, __, reqBody) => [{ type: 'LogDownload', tenantId: reqBody.tenantId, dbmsId: reqBody.dbmsId }],
  }),

  initiateQueryLogsSummaryDownload: builder.mutation<
    LogsInitiateDownloadResponse,
    QueryLogsInitiateDownloadSummaryRequestBody
  >({
    query: (body) => ({
      url: `${LOGS_DOWNLOADS_BASE_PATH}/query/initiate-summary`,
      method: 'POST',
      body,
      headers: { 'Project-Id': body.tenantId },
    }),
    invalidatesTags: (_, __, reqBody) => [{ type: 'LogDownload', tenantId: reqBody.tenantId, dbmsId: reqBody.dbmsId }],
  }),

  initiateSecurityLogsDownload: builder.mutation<
    LogsInitiateDownloadResponse,
    SecurityLogsInitiateDownloadDetailsRequestBody
  >({
    query: (body) => ({
      url: `${LOGS_DOWNLOADS_BASE_PATH}/security/initiate-details`,
      method: 'POST',
      body,
      headers: { 'Project-Id': body.tenantId },
    }),
    invalidatesTags: (_, __, reqBody) => [{ type: 'LogDownload', tenantId: reqBody.tenantId, dbmsId: reqBody.dbmsId }],
  }),

  initiateSecurityLogsSummaryDownload: builder.mutation<
    LogsInitiateDownloadResponse,
    SecurityLogsInitiateDownloadSummaryRequestBody
  >({
    query: (body) => ({
      url: `${LOGS_DOWNLOADS_BASE_PATH}/security/initiate-summary`,
      method: 'POST',
      body,
      headers: { 'Project-Id': body.tenantId },
    }),
    invalidatesTags: (_, __, reqBody) => [{ type: 'LogDownload', tenantId: reqBody.tenantId, dbmsId: reqBody.dbmsId }],
  }),

  getLogsDownloads: builder.query<TransformedLogsDownloadsResponse, LogsDownloadsRequestBody>({
    query: (body) => ({
      url: `${LOGS_DOWNLOADS_BASE_PATH}?tenantId=${body.tenantId}&dbmsId=${body.dbmsId}`,
      method: 'GET',
      headers: { 'Project-Id': body.tenantId },
    }),
    providesTags: (result, _, reqBody) => {
      return Array.isArray(result) ? [{ type: 'LogDownload', tenantId: reqBody.tenantId, dbmsId: reqBody.dbmsId }] : [];
    },
    transformResponse: (res: LogsDownloadsResponse) => transformQueryLogsDownloadsResponse(res),
  }),

  getLogsDownloadUrl: builder.mutation<LogsDownloadUrlResponse, LogsDownloadUrlRequest>({
    query: (body) => ({
      url: `${LOGS_DOWNLOADS_BASE_PATH}/signed-url`,
      method: 'POST',
      body,
      headers: { 'Project-Id': body.tenantId },
    }),
    invalidatesTags: (_, __, reqBody) => [{ type: 'LogDownload', tenantId: reqBody.tenantId, dbmsId: reqBody.dbmsId }],
  }),

  deleteLogsDownload: builder.mutation<LogsDownloadDeleteResponse, LogsDownloadDeleteRequest>({
    query: (body) => ({
      url: `${LOGS_DOWNLOADS_BASE_PATH}/delete`,
      method: 'POST',
      body,
      headers: { 'Project-Id': body.tenantId },
    }),
  }),

  getSecurityLogFilterValues: builder.query<AvailableSecurityLogFilters, SecurityLogFilterValuesRequest>({
    query: (securityLogFilterValuesRequest) => ({
      url: `${SECURITY_LOGS_BASE_PATH}/filter-values`,
      method: 'POST',
      body: securityLogFilterValuesRequest,
      headers: { 'Project-Id': securityLogFilterValuesRequest.tenantId },
    }),
    transformResponse: (res: SecurityLogFilterValues) => transformSecurityLogFilterValuesResponse(res),
  }),
  getSecurityLogs: builder.query<PaginatedSecurityLogs, SecurityLogsRequest>({
    query: (securityLogsRequest) => ({
      url: `${SECURITY_LOGS_BASE_PATH}/details`,
      method: 'POST',
      body: securityLogsRequest,
      headers: { 'Project-Id': securityLogsRequest.tenantId },
    }),
  }),
  getSecurityLogsSummary: builder.query<PaginatedSecurityLogsSummary, SecurityLogsSummaryRequest>({
    query: (securityLogsSummaryRequest) => ({
      url: `${SECURITY_LOGS_BASE_PATH}/summary`,
      method: 'POST',
      body: securityLogsSummaryRequest,
      headers: { 'Project-Id': securityLogsSummaryRequest.tenantId },
    }),
  }),
  getSecurityLogCounts: builder.query<SecurityLogCounts, SecurityLogCountsRequest>({
    query: (securityLogCountsRequest) => ({
      url: `${SECURITY_LOGS_BASE_PATH}/counts`,
      method: 'POST',
      body: securityLogCountsRequest,
      headers: { 'Project-Id': securityLogCountsRequest.tenantId },
    }),
  }),
});
