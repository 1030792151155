import type { QueryResultWithLimit } from '@nx/constants';
import { isNonEmptyString } from '@nx/stdlib';
import type { Notification } from 'neo4j-driver';
import { Integer } from 'neo4j-driver-core';
import type { GqlStatusObject, NotificationPosition, ResultSummary } from 'neo4j-driver-core';

import { formatDescriptionFromGqlStatusDescription, formatTitleFromGqlStatusDescription } from './gql-status-utils';

export const formatCounters = (key: string, count: number) => {
  switch (key) {
    case 'nodesCreated':
      return count === 1 ? `created ${count} node` : `created ${count} nodes`;
    case 'nodesDeleted':
      return count === 1 ? `deleted ${count} node` : `deleted ${count} nodes`;
    case 'relationshipsCreated':
      return count === 1 ? `created ${count} relationship` : `created ${count} relationships`;
    case 'relationshipsDeleted':
      return count === 1 ? `deleted ${count} relationship` : `deleted ${count} relationships`;
    case 'propertiesSet':
      return count === 1 ? `set ${count} property` : `set ${count} properties`;
    case 'labelsAdded':
      return count === 1 ? `added ${count} label` : `added ${count} labels`;
    case 'labelsRemoved':
      return count === 1 ? `removed ${count} label` : `removed ${count} labels`;
    case 'indexesAdded':
      return count === 1 ? `added ${count} index` : `added ${count} indexes`;
    case 'indexesRemoved':
      return count === 1 ? `removed ${count} index` : `removed ${count} indexes`;
    case 'constraintsAdded':
      return count === 1 ? `added ${count} constraint` : `added ${count} constraints`;
    case 'constraintsRemoved':
      return count === 1 ? `removed ${count} constraint` : `removed ${count} constraints`;
    default:
      return `${count} ${key}`;
  }
};

const getFormatter = (unit: Intl.NumberFormatOptions['unit']) =>
  new Intl.NumberFormat('en-US', {
    style: 'unit',
    unit,
    unitDisplay: 'long',
    maximumFractionDigits: 0,
  });

export const formatTime = (n: number) => {
  const hours = getFormatter('hour').format(Math.floor(n / 3600000));
  const minutes = getFormatter('minute').format(Math.floor(n / 60000) % 60);
  const seconds = getFormatter('second').format((n / 1000) % 60);
  const milliseconds = `${n.toLocaleString()} ms`;

  const HOUR_MS = 60 * 60 * 1000;
  const MINUTE_MS = 60 * 1000;

  if (n >= HOUR_MS) {
    return `${hours} ${minutes}`;
  }
  if (n >= MINUTE_MS) {
    return `${minutes} ${seconds}`;
  }
  return milliseconds;
};

export const queryResultSummary = (queryResult: QueryResultWithLimit) => {
  const { recordLimitHit = false } = queryResult;
  const { counters } = queryResult.summary;

  const systemUpdatesMessage = counters.containsSystemUpdates()
    ? `${counters.systemUpdates() === 1 ? '1 system update' : `${counters.systemUpdates()} system updates`}`
    : null;

  const dataUpdatesMessage = counters.containsUpdates()
    ? `${Object.entries(counters.updates())
        .filter(([_key, updateCount]) => updateCount > 0)
        .map(([key, val]) => formatCounters(key, val))
        .join(', ')}`
    : null;

  const resultAvailableAfter =
    queryResult.summary.resultAvailableAfter.toNumber() === 0
      ? 'in less than 1ms'
      : `after ${formatTime(queryResult.summary.resultAvailableAfter.toNumber())}`;

  const totalTime = queryResult.summary.resultAvailableAfter.add(queryResult.summary.resultConsumedAfter);
  const totalTimeString = totalTime.toNumber() === 0 ? 'in less than 1ms' : `after ${formatTime(totalTime.toNumber())}`;

  const recordsString = queryResult.records.length === 1 ? '1 record' : `${queryResult.records.length} records`;

  const streamingMessageWithCount = recordLimitHit
    ? `Fetch limit hit at ${queryResult.records.length} records. Started streaming ${resultAvailableAfter} and completed ${totalTimeString}.`
    : `Started streaming ${recordsString} ${resultAvailableAfter} and completed ${totalTimeString}.`;
  const streamingMessageWithTimeOnly = `Completed ${totalTimeString}`;

  const fullStreamingMessage =
    queryResult.records.length > 0 ? streamingMessageWithCount : streamingMessageWithTimeOnly;

  // the http query api doesn't yet have `resultAvailableAfter` times. NEG_ONE is the fallback
  const streamingTimeUnavailable =
    queryResult.summary.resultAvailableAfter === Integer.NEG_ONE ||
    queryResult.summary.resultConsumedAfter === Integer.NEG_ONE;
  const streamingMessageNoTime = recordLimitHit
    ? `Fetch limit hit at ${queryResult.records.length} records.`
    : `Fetched ${recordsString}`;

  return {
    systemUpdatesMessage,
    dataUpdatesMessage,
    streamingMessage: streamingTimeUnavailable ? streamingMessageNoTime : fullStreamingMessage,
  };
};

export const addCaretToQuery = (query: string, position?: NotificationPosition) => {
  if (position === undefined) {
    return query;
  }

  let queryText = query;

  // meant to be 1 indexed, sometime warnings come back with 0
  if (position.line === undefined || position.column === undefined || position.line === 0 || position.column === 0) {
    return queryText;
  }

  const array = query.split('\n');
  array.splice(position.line, 0, `${' '.repeat(position.column - 1)}^`);
  queryText = array.join('\n');

  return queryText;
};

export type FormattedNotification = {
  title?: string;
  description: string;
  position?: NotificationPosition;
  code?: string | null;
};

export type FormattedResultSummary = {
  warningNotifications: FormattedNotification[];
  infoNotifications: FormattedNotification[];
};

const mapGqlStatusObjectsToFormattedNotifications = (
  statusObjects: Omit<GqlStatusObject, 'diagnosticRecordAsJsonString'>[],
): FormattedNotification[] => {
  return statusObjects.map((statusObject) => {
    const gqlStatusTitle = formatTitleFromGqlStatusDescription(statusObject.statusDescription);
    const { gqlStatus } = statusObject;
    const description = formatDescriptionFromGqlStatusDescription(statusObject.statusDescription);
    const title = isNonEmptyString(gqlStatusTitle) ? gqlStatusTitle : description;
    return {
      title: isNonEmptyString(title) ? `${gqlStatus}: ${title}` : gqlStatus,
      description,
      position: statusObject.position,
    };
  });
};

const mapNotificationsToFormattedNotifications = (notifications: Notification[]): FormattedNotification[] => {
  return notifications.map((notification) => ({
    title: notification.title,
    description: notification.description,
    position: notification.position,
    code: notification.code,
  }));
};

export const formatResultSummaryGqlStatusObjects = (resultSummary: Partial<ResultSummary>): FormattedResultSummary => {
  const warnings = resultSummary.gqlStatusObjects?.filter((n) => n.severity === 'WARNING') ?? [];
  const infos = resultSummary.gqlStatusObjects?.filter((n) => n.severity === 'INFORMATION') ?? [];
  return {
    warningNotifications: mapGqlStatusObjectsToFormattedNotifications(warnings),
    infoNotifications: mapGqlStatusObjectsToFormattedNotifications(infos),
  };
};

export const formatResultSummaryNotifications = (resultSummary: Partial<ResultSummary>) => {
  const warnings = resultSummary.notifications?.filter((n) => n.severity === 'WARNING') ?? [];
  const infos = resultSummary.notifications?.filter((n) => n.severity === 'INFORMATION') ?? [];
  return {
    warningNotifications: mapNotificationsToFormattedNotifications(warnings),
    infoNotifications: mapNotificationsToFormattedNotifications(infos),
  };
};
