import { MINIMUM_SUPPORTED_VERSION } from '@nx/neo4j-sdk';
import { neo4jVersionUtil } from '@nx/neo4j-version-utils';
import { isInt } from 'neo4j-driver-core';

const DEFAULT_VALUE = '-';

export type SysInfoClientConfig = {
  serverMetricsPrefix: string;
  namespacesEnabled: boolean;
  metricsDomain: string;
};

export type SysInfoMetric = {
  name: string;
  displayName: string;
  dbType: boolean;
  mapFunction?: (value: unknown) => number | string;
};

export type SysInfoMappedMetrics = Record<string, { value: string | number; dirty?: boolean }>;

export type SysInfoMetricCategoryKey = 'id-allocation' | 'store-size' | 'page-cache' | 'transactions';

export type SysInfoMetricCategory = {
  key: SysInfoMetricCategoryKey;
  category: string;
  metrics: Record<string, SysInfoMetric>;
};

export type SysInfoCategorizedMetrics = Record<
  SysInfoMetricCategoryKey,
  { mappedMetrics: SysInfoMappedMetrics } | null
>;

export const createClientConfig = (serverMetricsPrefix = '', namespacesEnabled = true): SysInfoClientConfig => {
  return {
    serverMetricsPrefix,
    namespacesEnabled,
    metricsDomain: `${serverMetricsPrefix}.metrics`,
  };
};

export const formatBytes = (value: unknown) => {
  if (isInt(value) && value.inSafeRange()) {
    let number = Number(value.getLowBits());

    if (!isFinite(number)) {
      return DEFAULT_VALUE;
    }

    if (number < 1024) {
      return `${number} B`;
    }

    number /= 1024;
    const units = ['KiB', 'MiB', 'GiB', 'TiB'];

    for (const unit of Array.from(units)) {
      if (number < 1024) {
        return `${number.toFixed(2)} ${unit}`;
      }
      number /= 1024;
    }

    return `${number.toFixed(2)} PiB`;
  }

  return DEFAULT_VALUE;
};

const isNumber = (value: unknown): value is number => Number.isInteger(value) || Number.isFinite(value);

export const formatPercentage = (value: unknown) => {
  if (isNumber(value)) {
    return `${(value * 100).toFixed(2)}%`;
  }

  return DEFAULT_VALUE;
};

export const isUnsupportedVersion = (version?: string) => {
  return version !== undefined && neo4jVersionUtil.compareLoose(version, MINIMUM_SUPPORTED_VERSION) === -1;
};

export const formatMetricJmxQuery = (config: SysInfoClientConfig, metric: SysInfoMetric, database?: string) => {
  const domainAndPrefix = `${config.metricsDomain}:name=${config.serverMetricsPrefix}`;

  return metric.dbType
    ? `${domainAndPrefix}${config.namespacesEnabled ? '.database.' : '.'}${database}.${metric.name}`
    : `${domainAndPrefix}${config.namespacesEnabled ? '.dbms' : ''}.${metric.name}`;
};
