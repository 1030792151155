import { tokens } from '@neo4j-ndl/base';
import type { MetricsData, MetricsResult, SeriesSummary, TimeRange } from '@nx/state';
import { Functions } from '@nx/stdlib';
import { Center, useDarkMode } from '@nx/ui';
import classNames from 'classnames';
import { memo, useEffect, useMemo } from 'react';
import useResizeObserver from 'use-resize-observer';

import { ApiErrorBanner } from '../../../shared/components';
import { arePropsDeepEqual } from '../../../shared/ui-helpers';
import { metricKey } from '../../../shared/utils';
import { chartKey, prepareBuilder, useChartState } from '../../hooks';
import { getSeriesColor } from '../../hooks/use-series-colors';
import type { UPlotOptionsBuilder } from '../../plot';
import { Plot } from '../../plot';
import { verticalLineTimeRangeSelectionPlugin } from '../../plot/timerange-selection-plugin';
import { COLOR_PALETTE_ENUM } from '../../shared/color-palettes';
import { COMPACT_CHART_HEIGHT } from '../../shared/constants';
import { truthy } from '../../shared/typeguards';
import type { ChartWidgetPartialProps } from '../fullstack-chart-props';
import type { ChartContext } from '../types';
import { LoadingBanner, NoDataBanner } from './components/banners';
import { Card } from './components/cards';
import { Pills } from './components/pills';

export interface TimelineCardProps {
  chartConfigs: ChartWidgetPartialProps[];
  /**
   * Index of the main chart config in chartConfigs for single-chart
   * functions like prepareBuilder and processData (formatUplot)
   * */
  mainChartConfigIdx: number;
  context: ChartContext;
  metricsResult: MetricsResult;
  onZoom?: (timeRange: TimeRange) => void;
  onZoomReset?: () => void;
  /**
   * DataDog extends the requested time range with padding that increases with the range size
   * (e.g., 5 mins for up to 1h, 8 mins for <= 2h, 12 mins for <= 3h, etc.).
   * Therefore, we need to manually adjust the x-axis scale to match the requested time range.
   */
  timeRangePadded?: boolean;
  pills: SeriesSummary[];
  /** Allow plotting of zero-only values for all, specific `chartConfigs`, or none */
  includeZeroValueSeries?: boolean | ChartWidgetPartialProps[];
  builderModifier?: (builder: UPlotOptionsBuilder, data: MetricsData) => void;
}

export const TimelineCard = memo(
  ({
    chartConfigs,
    mainChartConfigIdx,
    context,
    metricsResult,
    onZoom,
    onZoomReset,
    timeRangePadded,
    pills,
    includeZeroValueSeries = true,
    builderModifier = Functions.noop,
  }: TimelineCardProps) => {
    const isDarkTheme = useDarkMode();
    const plotParentRO = useResizeObserver();

    const { state, processData } = useChartState();

    const data = useMemo(
      () =>
        chartConfigs.reduce<MetricsData & Required<Pick<MetricsData, 'dataSeries'>>>(
          (allSeriesData, p) => {
            const nameAndValues = metricsResult.metrics?.[metricKey(p, false, context.selectedDB)]?.dataSeries ?? {};
            const [name = ''] = Object.keys(nameAndValues);
            const values = nameAndValues[name];
            const includeZeroOnlyValues = Array.isArray(includeZeroValueSeries)
              ? includeZeroValueSeries.includes(p)
              : includeZeroValueSeries;
            if (Array.isArray(values) && (includeZeroOnlyValues ? true : values.some((d) => (d.value ?? 0) > 0))) {
              allSeriesData.dataSeries[name] = values;
            }

            return allSeriesData;
          },
          { dataSeries: {} },
        ),
      [chartConfigs, metricsResult.metrics, context.selectedDB, includeZeroValueSeries],
    );

    const dataExists =
      Object.keys(data.dataSeries).length > 0 &&
      Object.values(data.dataSeries).some((elementMetrics) => elementMetrics.length > 0);

    const contextualPills = useMemo(() => {
      if (dataExists) {
        const fetchedSeries = Object.keys(data.dataSeries);
        return pills.filter((pill) => fetchedSeries.includes(pill.id));
      }
      return [];
    }, [data.dataSeries, dataExists, pills]);

    useEffect(() => {
      if (dataExists && chartConfigs[mainChartConfigIdx]) {
        processData({ data, timeRange: context.timeRange, chartProps: chartConfigs[mainChartConfigIdx] });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, dataExists, processData]);

    const plotOptions = useMemo(() => {
      const builder = prepareBuilder({
        state,
        chartProps: chartConfigs[mainChartConfigIdx],
        ignoredInstances: context.ignoredInstances,
        isExpanded: false,
        onZoom: (timeRange) => {
          if (timeRange && onZoom) {
            onZoom(timeRange);
          }
        },
        onDblClick: onZoomReset,
        isDarkTheme,
        seriesSummary: pills,
      });

      if (truthy(timeRangePadded)) {
        const { startTime, endTime } = context.timeRange;
        builder.updateScales({
          x: {
            min: startTime.getTime(),
            max: endTime.getTime(),
          },
        });
      }

      builder.addPlugin(
        verticalLineTimeRangeSelectionPlugin({
          durationThreshold: { hours: 24 },
          lineStroke: tokens.colors.neutral[35],
        }),
      );

      builderModifier(builder, data);

      return builder.getOptions();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.ignoredInstances, state, isDarkTheme]);

    return (
      <>
        {metricsResult.isLoading && !dataExists && (
          <div
            className="flex justify-center"
            style={{
              height: `${COMPACT_CHART_HEIGHT}px`,
            }}
          >
            <LoadingBanner />
          </div>
        )}
        {(dataExists || !metricsResult.isLoading) && (
          <Card
            className={classNames('w-full', '!p-0')}
            controls={[]}
            key={chartConfigs[mainChartConfigIdx] && chartKey(chartConfigs[mainChartConfigIdx])}
            style={{
              minWidth: '250px',
              minHeight: `${COMPACT_CHART_HEIGHT}px`,
            }}
          >
            <div className="relative h-full w-full" ref={plotParentRO.ref}>
              {metricsResult.error && <ApiErrorBanner error={metricsResult.error} />}
              {!metricsResult.error && (
                <>
                  {dataExists || metricsResult.isLoading ? null : <NoDataBanner />}
                  {metricsResult.isLoading && (
                    <Center className="z-overlay !absolute h-full w-full">
                      <div className="backdrop-blur">
                        <LoadingBanner />
                      </div>
                    </Center>
                  )}
                  {dataExists && (
                    <div className="cursor-ew-resize">
                      <Plot
                        width={plotParentRO.width ?? 0}
                        height={COMPACT_CHART_HEIGHT}
                        data={state.alignedDataDisplay}
                        options={plotOptions}
                      />
                    </div>
                  )}
                </>
              )}
            </div>

            {pills.length > 0 && dataExists && !metricsResult.error && (
              <Pills
                updateIgnoredIds={context.setIgnoredInstances}
                pills={contextualPills}
                currentIgnoredIds={context.ignoredInstances}
                getColor={
                  (chartConfigs[mainChartConfigIdx]?.yAxisLabel ?? '').includes('Deprecation')
                    ? getSeriesColor(COLOR_PALETTE_ENUM.ExcludingCountColor)
                    : getSeriesColor(COLOR_PALETTE_ENUM.Standard)
                }
              />
            )}
          </Card>
        )}
      </>
    );
  },
  arePropsDeepEqual,
);

TimelineCard.displayName = 'TimelineCard';
