import type { DbList } from '../relate.types';
import type { Builder } from './types';

export const dbEndpoints = (builder: Builder) => ({
  listDatabases: builder.query<DbList, string>({
    query: (id) => ({ url: 'list-dbs-desktop', params: { dbmsId: id } }),
    providesTags: ['Database'],
  }),
  createDatabase: builder.mutation<string, { dbmsId: string; database: string }>({
    query: ({ dbmsId, database }) => ({
      url: 'create-db-desktop',
      method: 'POST',
      params: { dbmsId, database },
    }),
    invalidatesTags: ['Database'],
  }),
  dropDatabase: builder.mutation<string, { dbmsId: string; database: string }>({
    query: ({ dbmsId, database }) => ({
      url: 'drop-db-desktop',
      method: 'POST',
      params: { dbmsId, database },
    }),
    invalidatesTags: ['Database'],
  }),
  dumpDatabase: builder.mutation<string, { dbmsId: string; database: string; to?: string }>({
    query: ({ dbmsId, database, to }) => ({
      url: 'dump-db',
      method: 'POST',
      params: { dbmsId, database, to },
    }),
  }),
  loadDatabase: builder.mutation<string, { dbmsId: string; database: string; from?: string; force?: boolean }>({
    query: ({ dbmsId, database, from, force }) => ({
      url: 'load-db',
      method: 'POST',
      params: { dbmsId, database, from, force },
    }),
  }),
});
