import { isNullish } from '@nx/stdlib';
import JSZip from 'jszip';
import type { DataEventCallback, EndEventCallback, JSZipMetadata } from 'jszip';

import type { DataFileMetadata, EnhancedJSZipObject } from './types';

export const createZipFileStream = (
  zipFiles: Record<string, EnhancedJSZipObject>,
  zipDataFiles: Record<string, DataFileMetadata>,
  fileSchema: string,
  chunkSize: number,
): NodeJS.ReadableStream => {
  const zipDataFile = zipDataFiles[fileSchema];
  if (isNullish(zipDataFile)) {
    throw new Error(`Could not find ${fileSchema} in zip file`);
  }

  const { fileName, size } = zipDataFile;
  const zipFile = zipFiles[fileName];
  if (isNullish(zipFile)) {
    throw new Error('Could not find zip file');
  }

  const zipStream = zipFile.internalStream('string');
  // resume needs to be caused as it starts paused

  type OnData = (chunkData: string, chunkMeta: Partial<JSZipMetadata>) => void;
  type OnEnd = () => void;

  let onData: OnData = (chunkData: string, chunkMeta: Partial<JSZipMetadata>) => undefined;
  let onEnd: OnEnd = () => undefined;
  let savedChunkDataLength = 0;
  let savedChunkData: string[] = [];

  const internalOnData: DataEventCallback<string> = (chunkData, chunkMeta) => {
    const chunkDataLength = chunkData.length;
    if (savedChunkDataLength + chunkDataLength >= chunkSize) {
      const extraChunkLength = savedChunkDataLength + chunkDataLength - chunkSize;
      if (extraChunkLength > 0) {
        const chunkSplitLength = chunkDataLength - extraChunkLength;
        onData(savedChunkData.join('') + chunkData.substring(0, chunkSplitLength), chunkMeta);
        savedChunkDataLength = extraChunkLength;
        savedChunkData = [chunkData.substring(chunkSplitLength)];
      } else {
        onData(savedChunkData.join(''), chunkMeta);
        savedChunkDataLength = 0;
        savedChunkData = [];
      }
    } else {
      savedChunkData.push(chunkData);
      savedChunkDataLength += chunkDataLength;
    }
  };

  const internalOnEnd: EndEventCallback = () => {
    if (savedChunkDataLength > 0) {
      onData(savedChunkData.join(''), { percent: 1 });
      savedChunkDataLength = 0;
      savedChunkData = [];
    }
    onEnd();
  };

  zipStream.on('data', internalOnData);
  zipStream.on('end', internalOnEnd);
  const zipFileStream: NodeJS.ReadableStream = {
    // @ts-ignore size doesn't exist in NodeJS.ReadableStream. We need to evaluate if it can be removed in the future.
    size,
    readable: true,
    read: () => '',
    on(eventName, listener) {
      if (eventName === 'data') {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        onData = listener as OnData;
      } else if (eventName === 'end') {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        onEnd = listener as OnEnd;
      } else if (eventName === 'error') {
        zipStream.on(eventName, listener);
      }
      return zipFileStream;
    },
    pause(...args) {
      zipStream.pause(...args);
      return zipFileStream;
    },
    resume(...args) {
      zipStream.resume(...args);
      return zipFileStream;
    },
    removeListener: () => zipFileStream,
  };
  return zipFileStream;
};

export const getZipFiles = (zipFile: File): Promise<Record<string, EnhancedJSZipObject>> =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  JSZip.loadAsync(zipFile).then(({ files }) => files as Record<string, EnhancedJSZipObject>);

export const getFileForReading = (
  fileSchema: string,
  files: Record<string, File | string>,
  zipFiles: Record<string, EnhancedJSZipObject>,
  zipDataFiles: Record<string, DataFileMetadata>,
  chunkSize: number,
): File | NodeJS.ReadableStream | string =>
  files[fileSchema] ?? createZipFileStream(zipFiles, zipDataFiles, fileSchema, chunkSize);
