import { DataGrid, IconButton, Label, Tabs } from '@neo4j-ndl/react';
import { PencilIconOutline, TrashIconOutline } from '@neo4j-ndl/react/icons';
import type {
  LogForwarding,
  LogForwardingIdentity,
  WrappedCloudwatchDestination,
  WrappedLogAnalyticsDestination,
  WrappedStackdriverDestination,
} from '@nx/state';
import { CLOUD_PROVIDER, DESTINATION_TYPE, useInstances } from '@nx/state';
import { DataGridHelpers, SearchField } from '@nx/ui';
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { upperCase } from 'lodash-es';
import { useMemo, useState } from 'react';

import { CommonNoDataPlaceholder, HeaderCell } from '../logs/shared/helpers/table-overrides';
import { getProductFromTier, regionNameWithInstanceCount } from './helpers/utils';

interface Props {
  data: LogForwarding[];
  cloudProvider: CLOUD_PROVIDER;
  onEdit: (logForwarding: LogForwarding) => void;
  onDelete: (logForwarding: LogForwarding) => void;
  logForwardingIdentities: LogForwardingIdentity[];
}

const helper = createColumnHelper<LogForwarding>();

const destinationColumns = (
  cloudProvider: CLOUD_PROVIDER,
  logForwardingIdentities: LogForwardingIdentity[],
): ReturnType<typeof helper.accessor | typeof helper.display>[] => {
  switch (cloudProvider) {
    case CLOUD_PROVIDER.GCP:
      return [
        helper.display({
          id: 'destination',
          header: 'Target GCP Project ID',
          minSize: 100,
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          cell: (cell) => (cell.row.original.destination as WrappedStackdriverDestination).stackdriver.gcpProjectId,
        }),
        helper.display({
          id: 'service-account-email',
          header: 'Service Account Email',
          minSize: 100,
          cell: (cell) => {
            const { region } = cell.row.original;
            const { tier } = cell.row.original;
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            const identity = logForwardingIdentities.find((id) => id.region === region && id.tier === tier);
            return identity?.destinationType === DESTINATION_TYPE.STACKDRIVER ? (
              <span className="truncate" title={identity.serviceAccountEmail}>
                {identity.serviceAccountEmail === '' ? 'unknown' : identity.serviceAccountEmail}
              </span>
            ) : (
              <></>
            );
          },
        }),
      ];
    case CLOUD_PROVIDER.AZURE:
      return [
        helper.display({
          id: 'destination',
          header: 'Target Workspace ID',
          minSize: 200,
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          cell: (cell) => (cell.row.original.destination as WrappedLogAnalyticsDestination).logAnalytics.customerId,
        }),
      ];
    case CLOUD_PROVIDER.AWS:
      return [
        helper.display({
          id: 'destination',
          header: 'AWS Region',
          minSize: 200,
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          cell: (cell) => (cell.row.original.destination as WrappedCloudwatchDestination).cloudwatch.region,
        }),
        helper.display({
          id: 'log-arn',
          header: 'Role ARN',
          minSize: 200,
          cell: (cell) =>
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            (cell.row.original.destination as WrappedCloudwatchDestination).cloudwatch.logArn,
        }),
        helper.display({
          id: 'group-name',
          header: 'Log group name',
          minSize: 100,
          cell: (cell) =>
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            (cell.row.original.destination as WrappedCloudwatchDestination).cloudwatch.logGroupName,
        }),
        helper.display({
          id: 'stream-name',
          header: 'Log stream name',
          minSize: 100,
          cell: (cell) =>
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            (cell.row.original.destination as WrappedCloudwatchDestination).cloudwatch.logStreamName,
        }),
        helper.display({
          id: 'retention-days',
          header: 'Retention days',
          minSize: 100,
          cell: (cell) =>
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            (cell.row.original.destination as WrappedCloudwatchDestination).cloudwatch.logRetentionDays,
        }),
        helper.display({
          id: 'neo4j-log-arn',
          header: 'Neo4j ARN',
          minSize: 150,
          cell: (cell) => {
            const { region } = cell.row.original;
            const { tier } = cell.row.original;
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            const identity = logForwardingIdentities.find((id) => id.region === region && id.tier === tier);
            return identity?.destinationType === DESTINATION_TYPE.CLOUDWATCH ? (
              <span className="truncate" title={identity.logArn}>
                {identity.logArn === '' ? 'unknown' : identity.logArn}
              </span>
            ) : (
              <></>
            );
          },
        }),
        helper.display({
          id: 'neo4j-account-id',
          header: 'Neo4j Account Id',
          minSize: 150,
          cell: (cell) => {
            const { region } = cell.row.original;
            const { tier } = cell.row.original;
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            const identity = logForwardingIdentities.find((id) => id.region === region && id.tier === tier);
            return identity?.destinationType === DESTINATION_TYPE.CLOUDWATCH ? (
              <span className="truncate" title={identity.accountId}>
                {identity.accountId === '' ? 'unknown' : identity.accountId}
              </span>
            ) : (
              <></>
            );
          },
        }),
      ];
    default:
      return [];
  }
};

export const LogForwardingTable = ({ data, cloudProvider, onEdit, onDelete, logForwardingIdentities }: Props) => {
  const { instances } = useInstances({ shouldPoll: false });
  const [searchString, setSearchString] = useState<string>('');
  const filteredData = useMemo(() => {
    return data
      .filter(
        (lf) =>
          lf.name.startsWith(searchString) || lf.region.startsWith(searchString) || lf.logType.startsWith(searchString),
      )
      .filter((lf) => {
        switch (cloudProvider) {
          default:
          case CLOUD_PROVIDER.GCP:
            return DESTINATION_TYPE.STACKDRIVER === lf.destination.type;
          case CLOUD_PROVIDER.AWS:
            return DESTINATION_TYPE.CLOUDWATCH === lf.destination.type;
          case CLOUD_PROVIDER.AZURE:
            return DESTINATION_TYPE.LOG_ANALYTICS === lf.destination.type;
        }
      });
  }, [cloudProvider, data, searchString]);

  const columns = useMemo(() => {
    let colDefs = [
      helper.accessor('logType', {
        header: 'Logs',
        minSize: 100,
        cell: (cell) => <span className="capitalize">{cell.getValue()}</span>,
      }),
      helper.accessor('tier', {
        cell: (c) => {
          return getProductFromTier(c.getValue());
        },
        header: 'Product',
      }),
      helper.accessor('region', {
        header: 'Region',
        minSize: 100,
        cell: (cell) => {
          const { region } = cell.row.original;
          const { tier } = cell.row.original;
          const dbs = instances.filter((db) => db.region === region && db.tier === tier);
          return regionNameWithInstanceCount(region, dbs);
        },
      }),
      ...destinationColumns(cloudProvider, logForwardingIdentities),
      helper.accessor('ready', {
        header: 'Status',
        minSize: 100,
        cell: (cell) => {
          return cell.getValue() ? (
            <Label hasIcon fill="semi-filled" color="success">
              Forwarding
            </Label>
          ) : (
            <Label hasIcon fill="semi-filled">
              Setting up
            </Label>
          );
        },
      }),
      helper.display({
        id: 'actions',
        cell: (cell) => (
          <span className="flex w-full justify-end gap-3">
            <IconButton
              onClick={() => onEdit(cell.row.original)}
              ariaLabel="Edit configuration"
              size="medium"
              isClean
              htmlAttributes={{
                title: 'Edit configuration',
                'data-testid': 'edit-log-forwarding-button',
              }}
            >
              <PencilIconOutline />
            </IconButton>
            <IconButton
              onClick={() => onDelete(cell.row.original)}
              ariaLabel="Delete configuration"
              size="medium"
              isDanger
              htmlAttributes={{
                title: 'Delete configuration',
                'data-testid': 'delete-log-forwarding-button',
              }}
            >
              <TrashIconOutline />
            </IconButton>
          </span>
        ),
        meta: {
          isStickyAction: true,
        },
        size: 110,
      }),
    ];

    if (data.find((lf) => lf.db_id)) {
      colDefs = [
        helper.display({
          header: 'Instance',
          minSize: 100,
          cell: ({ row }) => {
            const instance = instances.find((i) => i.id === row.original.db_id);
            return instance ? instance.name : 'unknown';
          },
        }),
        ...colDefs,
      ];
    } else {
      colDefs = [
        helper.display({
          header: 'Configuration',
          minSize: 100,
          cell: ({ row }) => row.original.name,
        }),
        ...colDefs,
      ];
    }
    return colDefs;
  }, [cloudProvider, logForwardingIdentities, data, instances, onEdit, onDelete]);

  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    columns,
    data: filteredData,
  });

  return (
    <DataGridHelpers.Wrapper>
      <DataGridHelpers.OuterHeader>
        <div className="flex w-full flex-wrap items-center justify-between gap-x-12 gap-y-2">
          <div className="mr-auto flex basis-[400px] gap-2">
            <SearchField
              className="min-w-36 grow"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
              htmlAttributes={{ 'aria-label': 'Search for instances' }}
            />
          </div>
        </div>
      </DataGridHelpers.OuterHeader>
      <DataGrid
        styling={{ headerStyle: 'clean' }}
        isLoading={false}
        isAutoResizingColumns={false}
        tableInstance={table}
        components={{
          Header: DataGridHelpers.StickyActionHeader,
          HeaderCell,
          BodyRow: DataGridHelpers.StickyActionsBodyRow,
          NoDataPlaceholder: () => {
            return <CommonNoDataPlaceholder>No matching rows</CommonNoDataPlaceholder>;
          },
        }}
        isKeyboardNavigable={false}
      />
    </DataGridHelpers.Wrapper>
  );
};

export const TabbedLogForwardingTable = ({
  data,
  cloudProvider,
  cloudProviders,
  onEdit,
  onDelete,
  logForwardingIdentities,
  setCloudProviderInView,
}: Props & { cloudProviders: CLOUD_PROVIDER[]; setCloudProviderInView: (cp: CLOUD_PROVIDER) => void }) => {
  return (
    <>
      <Tabs
        onChange={(cp) => {
          setCloudProviderInView(cp);
        }}
        value={cloudProvider}
      >
        {cloudProviders.map((cp) => (
          <Tabs.Tab tabId={cp} key={cp}>
            {upperCase(cp)}
          </Tabs.Tab>
        ))}
      </Tabs>
      <Tabs.TabPanel tabId={cloudProvider} value={cloudProvider}>
        <LogForwardingTable
          key={cloudProvider}
          data={data}
          logForwardingIdentities={logForwardingIdentities}
          cloudProvider={cloudProvider}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </Tabs.TabPanel>
    </>
  );
};
