import { useDispatch } from '../context';
import * as Sidebar from '../slices/sidebar-slice';

export function useGuidesSidebarActions() {
  const dispatch = useDispatch();
  return {
    toggleGuidesSidebar: () => {
      return dispatch(Sidebar.toggleGuidesSidebar());
    },
  };
}
