import { DataGrid, DataGridComponents, IconButton, Label } from '@neo4j-ndl/react';
import { FunnelIconOutline } from '@neo4j-ndl/react/icons';
import { useAppSelector } from '@nx/state';
import { DataGridHelpers, SearchField } from '@nx/ui';
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { parseISO } from 'date-fns';

import { formatDollars, safeFormat } from '../../../utils';
import type { Invoice } from '../entities/invoice';
import { MOCK_INVOICES } from '../mock-data';

const columnHelper = createColumnHelper<Invoice>();

const columns = [
  columnHelper.accessor('number', {
    header: 'Invoice',
  }),
  columnHelper.accessor('period_start', {
    header: 'Period',
    cell: (cx) => safeFormat(parseISO(cx.getValue()), 'MMM yyyy'),
  }),
  columnHelper.accessor('created', {
    header: 'Invoice date',
    cell: (cx) => safeFormat(parseISO(cx.getValue()), 'dd MMM yyyy'),
  }),
  columnHelper.accessor('due_date', {
    header: 'Due date',
    cell: (cx) => {
      const dueDate = cx.getValue() ?? '';
      return dueDate.length > 0 ? safeFormat(parseISO(dueDate), 'dd MMM yyyy') : '';
    },
  }),
  columnHelper.accessor('amount_due', {
    header: 'Amount',
    cell: (cx) => {
      const currency = cx.row.original.currency ?? '';
      if (currency.toLowerCase() === 'usd') {
        return formatDollars(cx.getValue());
      } else if (currency.length > 0) {
        return (
          <>
            <span>{cx.getValue()}</span>
            <span className="ml-2">({currency.toUpperCase()})</span>
          </>
        );
      }
      return cx.getValue();
    },
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    cell: (cx) => {
      const status = cx.getValue() ?? '';
      return (
        status.length > 0 && (
          <Label
            color={status.toLowerCase() === 'paid' ? 'success' : 'default'}
            className="capitalize"
            fill="outlined"
            htmlAttributes={{
              'data-testid': 'invoice-status',
            }}
          >
            {status}
          </Label>
        )
      );
    },
    enableResizing: false,
  }),
  columnHelper.display({
    id: 'actions',
    cell: (cell) => <DataGridComponents.RowActionCell cell={cell} />,
    meta: {
      isActionCell: {
        actions: [{ title: 'Usage breakdown item action 1' }, { title: 'Usage breakdown item action 1' }],
      },
    },
  }),
];

export function Invoices() {
  const data = useAppSelector(() => MOCK_INVOICES);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    columnResizeMode: 'onChange',
  });

  return (
    <DataGridHelpers.Wrapper tabbed>
      <DataGridHelpers.OuterHeader>
        <div className="flex w-full flex-wrap justify-between gap-2">
          <div className="flex basis-[400px] gap-2">
            <SearchField className="min-w-36 grow" />
            <IconButton ariaLabel="Filter invoices">
              <FunnelIconOutline />
            </IconButton>
          </div>
        </div>
      </DataGridHelpers.OuterHeader>
      <DataGrid
        isResizable={true}
        tableInstance={table}
        styling={{ headerStyle: 'clean' }}
        isKeyboardNavigable={false}
      />
    </DataGridHelpers.Wrapper>
  );
}
