import type { Record as Neo4jRecord } from 'neo4j-driver';
import { v4 as generateRequestId } from 'uuid';

import type { Database } from '../../types/database';
import { isFalsy } from '../../types/utility';
import bolt from '../bolt/bolt';
import { perfLog } from '../logging';
import {
  getApocSampleSchemaForLabelsQuery,
  getApocSampleSchemaForRelationshipTypesQuery,
  getCoIncidentLabelsQuery,
  getFullSchemaForLabelsQuery,
  getFullSchemaForRelationshipTypesQuery,
  getGdsVersionQuery,
  getIndexes as getIndexesQuery,
  getLabelStatsQuery,
  getLegacyIndexes as getLegacyIndexesQuery,
  getMetadataQuery,
  getMinimumSampledSchemaForLabelsQuery,
  getMinimumSampledSchemaForRelationshipTypesQuery,
  getPropertyKeysDiversityQuery,
  getSampledSchemaForLabelsQuery,
  getSampledSchemaForRelationshipTypesQuery,
  getSchemaQuery,
  retrieveStatsQuery,
} from '../queries/queryHandler';
import {
  gdsVersionMapper,
  getCoIncidentLabelsMapper,
  getDiversePropertiesFromRetrieveStats,
  indexesMapper,
  labelStatsMapper,
  mapUniformPropertyKeys,
  metadataMapper,
  propertyKeySchemaMapper,
  schemaPathSegmentMapper,
} from '../queries/resultMapper';
import { isVersion5OorGreater } from '../versions/versionUtils';
import profile from './CategorizedLabels';

export const categorizeLabels = profile;

type RequestIdRegistry = Record<string, Record<string, string>>;

const requestIdRegistry: RequestIdRegistry = {};

export const METADATA_SCAN_FULL = 'METADATA_SCAN_FULL';
export const METADATA_SCAN_SAMPLE = 'METADATA_SCAN_SAMPLE';
const METADATA_SCAN_SAMPLE_APOC = 'METADATA_SCAN_SAMPLE_APOC';
export const METADATA_AUTO_SCAN_SAMPLE = 'METADATA_AUTO_SCAN_SAMPLE';

export const getOptions = (database: Database | '*', parentRequestId?: string) => {
  const resultOptions: { database?: string; requestId?: string } = {};

  if (database != null && database !== '*') {
    resultOptions.database = database.name;
  }

  if (!isFalsy(parentRequestId)) {
    const newRequestId = generateRequestId();
    requestIdRegistry[parentRequestId] = requestIdRegistry[parentRequestId] ?? {};
    requestIdRegistry[parentRequestId][newRequestId] = 'id';
    resultOptions.requestId = newRequestId;
  }
  return resultOptions;
};

const removeSubRequestIdFromRegistry = (parentRequestId?: string, requestId?: string) => {
  if (!isFalsy(parentRequestId) && requestIdRegistry[parentRequestId] != null) {
    const parentRequest = requestIdRegistry[parentRequestId];
    if (!parentRequest) return;
    if (!isFalsy(requestId) && parentRequest[requestId] != null) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete parentRequest[requestId];
    }
    if (Object.keys(parentRequest).length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete requestIdRegistry[parentRequestId];
    }
  }
};

type GenericMapper<T> = (records: Neo4jRecord[], isV5OrGreater?: boolean) => T;

const executeQuery = async <T>({
  mapper,
  database,
  parentRequestId,
  query,
  isV5OrGreater = false,
}: {
  mapper: GenericMapper<T>;
  database: Database | '*';
  parentRequestId?: string;
  query?: string;
  isV5OrGreater?: boolean;
}): Promise<T> => {
  const options = getOptions(database, parentRequestId);
  if (!isFalsy(query)) {
    try {
      const result = await bolt.readTransaction(query, options);
      return mapper(result.records, isV5OrGreater);
    } finally {
      removeSubRequestIdFromRegistry(parentRequestId, options.requestId);
    }
  } else {
    removeSubRequestIdFromRegistry(parentRequestId, options.requestId);
    return mapper([]);
  }
};

export const getPropertyKeysForLabels = async ({
  labels = [],
  database,
  scanMode = METADATA_SCAN_FULL,
  parentRequestId,
}: {
  labels: string[];
  database: Database;
  scanMode?: string;
  parentRequestId?: string;
}) => {
  let cypher;
  if (scanMode === METADATA_SCAN_SAMPLE) {
    cypher = getSampledSchemaForLabelsQuery(labels);
  } else if (scanMode === METADATA_AUTO_SCAN_SAMPLE) {
    cypher = getMinimumSampledSchemaForLabelsQuery(labels);
  } else if (scanMode === METADATA_SCAN_SAMPLE_APOC) {
    cypher = getApocSampleSchemaForLabelsQuery(labels);
  } else {
    cypher = getFullSchemaForLabelsQuery(labels);
  }
  const startTime = Date.now();

  const results = await executeQuery({
    mapper: propertyKeySchemaMapper,
    database,
    parentRequestId,
    query: cypher ?? undefined,
  });
  const timeTaken = Date.now() - startTime;
  perfLog.info('Label Properties Query', timeTaken);
  return results;
};

export const getPropertyKeysForRelationshipTypes = async ({
  relationshipTypes = [],
  labels = [],
  database,
  scanMode = METADATA_SCAN_FULL,
  parentRequestId,
}: {
  relationshipTypes: string[];
  labels: string[];
  database: Database;
  scanMode?: string;
  parentRequestId?: string;
}) => {
  let cypher;
  if (scanMode === METADATA_SCAN_SAMPLE) {
    cypher = getSampledSchemaForRelationshipTypesQuery(relationshipTypes, labels);
  } else if (scanMode === METADATA_AUTO_SCAN_SAMPLE) {
    cypher = getMinimumSampledSchemaForRelationshipTypesQuery(relationshipTypes, labels);
  } else if (scanMode === METADATA_SCAN_SAMPLE_APOC) {
    cypher = getApocSampleSchemaForRelationshipTypesQuery(relationshipTypes);
  } else {
    cypher = getFullSchemaForRelationshipTypesQuery(relationshipTypes);
  }
  const startTime = Date.now();

  const results = await executeQuery({
    mapper: propertyKeySchemaMapper,
    database,
    parentRequestId,
    query: cypher ?? undefined,
  });
  const timeTaken = Date.now() - startTime;
  perfLog.info('Rel Properties Query', timeTaken);
  return results;
};

export const getMetadata = async ({ database, parentRequestId }: { database: Database; parentRequestId?: string }) =>
  executeQuery({
    mapper: metadataMapper,
    database,
    parentRequestId,
    query: getMetadataQuery(),
  });

export const getSchema = async ({
  serverVersion,
  database,
  parentRequestId,
}: {
  serverVersion: string;
  database: Database;
  parentRequestId?: string;
}) =>
  executeQuery({
    mapper: schemaPathSegmentMapper,
    database,
    parentRequestId,
    query: getSchemaQuery(),
    isV5OrGreater: isVersion5OorGreater(serverVersion) ?? false,
  });

export const getCoIncidentLabels = async ({
  label,
  database,
  parentRequestId,
}: {
  label: string;
  database: Database;
  parentRequestId?: string;
}) =>
  executeQuery({
    mapper: getCoIncidentLabelsMapper,
    database,
    parentRequestId,
    query: getCoIncidentLabelsQuery(label),
  });

export const getIndexes = async ({
  database,
  parentRequestId,
}: {
  database: Database | '*';
  parentRequestId?: string;
}) =>
  executeQuery({
    mapper: indexesMapper,
    database,
    parentRequestId,
    query: getIndexesQuery(),
  });

export const getGdsVersion = async ({
  database,
  parentRequestId,
}: {
  database: Database | '*';
  parentRequestId?: string;
}) =>
  executeQuery({
    mapper: gdsVersionMapper,
    database,
    parentRequestId,
    query: getGdsVersionQuery(),
  });

export const getIndexesWithLegacyProcs = async ({
  database,
  parentRequestId,
}: {
  database: Database;
  parentRequestId?: string;
}) =>
  executeQuery({
    mapper: indexesMapper,
    database,
    parentRequestId,
    query: getLegacyIndexesQuery(),
  });

export const detectUniformPropertyKeys = async ({
  indexes,
  database,
  parentRequestId,
}: {
  indexes: { label: string; propertyKeys: string[] }[];
  database: Database;
  parentRequestId?: string;
}) =>
  executeQuery({
    mapper: mapUniformPropertyKeys,
    database,
    parentRequestId,
    query: getPropertyKeysDiversityQuery(indexes),
  });

export const getRareLabels = async ({
  labels,
  database,
  parentRequestId,
}: {
  labels: string[];
  database: Database;
  parentRequestId?: string;
}) =>
  executeQuery({
    mapper: labelStatsMapper,
    database,
    parentRequestId,
    query: getLabelStatsQuery(labels),
  });

export const retrieveDiverseProperties = async ({
  database,
  parentRequestId,
}: {
  database: Database;
  parentRequestId?: string;
}) =>
  executeQuery({
    mapper: getDiversePropertiesFromRetrieveStats,
    database,
    parentRequestId,
    query: retrieveStatsQuery,
  });

export const cancelRequest = async (requestId: string) => {
  const entry = requestIdRegistry[requestId];
  if (entry != null) {
    const requestIds = Object.keys(entry);
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete requestIdRegistry[requestId];
    return Promise.all(requestIds.map(async (requestId) => bolt.cancelTransaction(requestId)));
  }
  return bolt.cancelTransaction(requestId);
};
