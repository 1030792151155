import { APP_SCOPE } from '@nx/constants';
import type { Capability, ConfigurationState } from '@nx/state';
import {
  setLaunchDarklyConfiguration,
  setSegmentConfiguration,
  setSentryConfiguration,
  LEGACY_toggleCapability as toggleCapability,
  updateAuraConfiguration,
  updateBasePath,
  updateFallbackRoute,
  updateOnboardingConfiguration,
  updateToolConfiguration,
  updateWindowTitle,
} from '@nx/state';

export function setupCapabilities(
  {
    basePath,
    fallbackRoute,
    windowTitle,
    tools,
    aura,
    sentry,
    segment,
    launchDarkly,
    onboarding,
  }: ConfigurationState['internal'],
  capabilities: (Capability | false)[],
) {
  if (typeof basePath === 'string') {
    updateBasePath(basePath);
  }

  if (typeof fallbackRoute === 'string') {
    updateFallbackRoute(fallbackRoute);
  }

  if (typeof windowTitle === 'string') {
    updateWindowTitle(windowTitle);
  }

  if (sentry !== undefined) {
    setSentryConfiguration(sentry);
  }

  if (segment !== undefined) {
    setSegmentConfiguration(segment);
  }

  if (launchDarkly !== undefined) {
    setLaunchDarklyConfiguration(launchDarkly);
  }

  if (aura !== undefined) {
    updateAuraConfiguration(aura);
  }

  if (onboarding !== undefined) {
    updateOnboardingConfiguration(onboarding);
  }

  if (tools.dashboards !== undefined) {
    updateToolConfiguration({
      scope: APP_SCOPE.dashboards,
      config: tools.dashboards,
    });
  }
  toggleCapability({ key: 'dashboards:enabled', value: tools.dashboards !== undefined });

  if (tools.conversations !== undefined) {
    updateToolConfiguration({
      scope: APP_SCOPE.conversations,
      config: tools.conversations,
    });
  }

  toggleCapability({ key: 'conversations:enabled', value: tools.conversations !== undefined });

  if (tools.explore !== undefined) {
    updateToolConfiguration({
      scope: APP_SCOPE.explore,
      config: tools.explore,
    });
  }
  toggleCapability({ key: 'explore:enabled', value: tools.explore !== undefined });

  if (tools.import !== undefined) {
    updateToolConfiguration({
      scope: APP_SCOPE.import,
      config: tools.import,
    });
  }
  toggleCapability({ key: 'import:enabled', value: tools.import !== undefined });

  if (tools.query !== undefined) {
    updateToolConfiguration({
      scope: APP_SCOPE.query,
      config: tools.query,
    });
  }
  toggleCapability({ key: 'query:enabled', value: tools.query !== undefined });

  if (tools.guides !== undefined) {
    updateToolConfiguration({
      scope: APP_SCOPE.guides,
      config: tools.guides,
    });
  }
  toggleCapability({ key: 'guides:enabled', value: tools.guides !== undefined });

  capabilities.forEach(
    (capability) => typeof capability === 'string' && toggleCapability({ key: capability, value: true }),
  );
}
