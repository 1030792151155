import { APP_SCOPE, QUERY_TYPE, type QueryResultWithLimit } from '@nx/constants';
import { runQuery } from '@nx/state';
import { type AsyncThunk, type ThunkDispatch, type UnknownAction, createAsyncThunk } from '@reduxjs/toolkit';
import type * as DriverCore from 'neo4j-driver-core';
import { v4 as generateRequestId } from 'uuid';

import { isFullTextSearchSuggestion } from '../../../modules/SearchBar/SearchBar.utils';
import { graphMapper as mapper } from '../../../services/queries/resultMapper';
import { registerAbortFunction, unregisterAbortFunction } from '../../../services/search2_0/abortable-requests-service';
import { checkCypherUpdateClauses, generateCypher } from '../../../services/search2_0/query-generator';
import type { Records } from '../../../services/search/types';
import {
  trackActionExecution,
  trackUserQueryFromSearchV2,
  trackUserQueryFulltextFacetedResults,
} from '../../../services/tracking';
import { isVersion5OorGreater } from '../../../services/versions/versionUtils';
import type { Node, Relationship } from '../../../types/graph';
import type { SceneAction, TransformedSearchCategory } from '../../../types/perspective';
import { getServerVersion } from '../../connections/connectionsDuck';
import { addToInventory, addToVisible } from '../../graph/graph.actions';
import { selectNodes } from '../../graph/nodes';
import { getFullTextIndexes } from '../../perspectives/perspectiveMetadata';
import { getPerspective } from '../../perspectives/perspectives.selector';
import { type RootState } from '../../types';
import { zoomTo } from '../../visualization/visualization';
import { NAME as requests } from '../requests/search-requests.const';
import { clearRequestsThunk } from '../requests/search-requests.thunks';
import { DISMISS_TIMER, NAME as searchCore } from './search-core.const';
import { selectLockedSuggestions } from './search-core.selectors';
import type { SearchStatus } from './search-core.types';
import { SEARCH_STATUS } from './search-core.types';
import { generateSearchStatus, getResultsByUniqueNodes, isTransactionTimeoutError } from './search-core.utils';

type FetchDataPayload = {
  requestId: string;
  cypher: string;
  parameters: Record<string, unknown>;
  timeout: number;
  database?: string;
  recordLimit?: (newRecord: DriverCore.Record) => boolean;
};

type FetchedData = {
  requestId: string;
  recordLimitHit?: boolean;
};

export const fetchDataThunk = createAsyncThunk<FetchedData, FetchDataPayload, { state: RootState }>(
  `${searchCore}/fetchDataThunk`,
  async (payload: FetchDataPayload): Promise<FetchedData> => {
    const { requestId, cypher, parameters, recordLimit, timeout, database } = payload;
    const isUpdateQuery = checkCypherUpdateClauses(cypher);
    try {
      const query = runQuery({
        parameters,
        recordLimit,
        sessionConfig: { database },
        query: cypher,
        timeout,
        metadata: { appScope: APP_SCOPE.explore, queryType: QUERY_TYPE.UserDirect },
        transactionMode: isUpdateQuery ? 'write' : 'read',
      });

      registerAbortFunction(requestId, query.abort);
      const queryResult: QueryResultWithLimit = await query.unwrap();
      return { recordLimitHit: queryResult.recordLimitHit, requestId };
    } finally {
      unregisterAbortFunction(requestId);
    }
  },
);

let messageTimerId: ReturnType<typeof setTimeout> | null = null;

export const updateStatusWithTimeoutThunk: AsyncThunk<void, SearchStatus, { state: RootState }> = createAsyncThunk(
  `${searchCore}/updateStatusWithTimeout`,
  async (payload: SearchStatus, { dispatch }) => {
    if (messageTimerId !== null) {
      clearTimeout(messageTimerId);
    }
    dispatch({ type: `${searchCore}/updateStatus`, payload });

    messageTimerId = setTimeout(() => {
      dispatch({ type: `${searchCore}/updateStatus`, payload: { status: SEARCH_STATUS.IDLE } });
    }, DISMISS_TIMER);
  },
);

type RunSearchPayload = {
  queryResultLimit: number;
  timeout: number;
  isCaseInsensitive: boolean;
  autoSelectNewNodes: boolean;
  categories: TransformedSearchCategory[];
  visibleRelationships: string[];
  isHttpQueryApiEnabled: boolean;
  database?: string;
};

export const runSearchThunk = createAsyncThunk<
  { nodes?: Node[] | undefined; relationships?: Relationship[] | undefined } | undefined,
  RunSearchPayload,
  { state: RootState }
>(`${searchCore}/runSearch`, async (payload: RunSearchPayload, { dispatch, getState }) => {
  await dispatch(clearRequestsThunk());

  const {
    queryResultLimit,
    timeout,
    isCaseInsensitive,
    categories,
    visibleRelationships,
    database,
    autoSelectNewNodes,
    isHttpQueryApiEnabled,
  } = payload;
  const state = getState();
  const lockedSuggestions = selectLockedSuggestions(state);
  const fullTextIndexes = getFullTextIndexes(state);
  const isFullTextSearch = lockedSuggestions.some(isFullTextSearchSuggestion);
  const isV5OrGreater = isVersion5OorGreater(getServerVersion(state));

  let nodes, relationships, recordLimitHit, isUpdateQuery;
  const requestId = generateRequestId();

  dispatch({ type: `${requests}/createRequest`, payload: requestId });
  trackUserQueryFromSearchV2(lockedSuggestions);

  let totalRecords: Records = { nodes: [], relationships: [] };
  let mappedRecords: Records = { nodes: [], relationships: [] };
  let uniqueNodesIds = new Set<string>();

  try {
    const { cypher, params } = await generateCypher({
      lockedSuggestions,
      categories,
      visibleRelationships,
      fullTextIndexes,
      isCaseInsensitive,
      isHttpQueryApiEnabled,
    });
    isUpdateQuery = checkCypherUpdateClauses(cypher);

    const recordLimit = (newRecord: DriverCore.Record) => {
      mappedRecords = mapper([newRecord]);
      const resultsByUniqueNodes = getResultsByUniqueNodes({
        previousRecords: totalRecords,
        newRecords: mappedRecords,
        uniqueNodesIds,
        limit: queryResultLimit,
        isV5OrGreater,
      });

      totalRecords = { ...resultsByUniqueNodes.totalRecords };
      uniqueNodesIds = new Set(resultsByUniqueNodes.uniqueNodesIds);

      return !resultsByUniqueNodes.isAboveLimit;
    };

    const fetchedData = dispatch(
      fetchDataThunk({ requestId, cypher, parameters: params, database, timeout, recordLimit }),
    );
    ({ recordLimitHit } = await fetchedData.unwrap());
    ({ nodes, relationships } = totalRecords);
  } catch (error) {
    ({ nodes, relationships } = totalRecords);
    if (isTransactionTimeoutError(error)) {
      dispatchShowNodesAndRelationships(dispatch, nodes, relationships, autoSelectNewNodes, categories);
    }
    const status = generateSearchStatus({
      uniqueNodesSize: nodes.length,
      error,
    });
    await dispatch(updateStatusWithTimeoutThunk(status));
    return;
  }

  const uniqueNodesSize = nodes.length;
  const status = generateSearchStatus({
    uniqueNodesSize: nodes.length,
    recordLimitHit,
    isUpdateQuery,
  });

  const hasFacetedResults = isFullTextSearch && recordLimitHit === true;
  if (hasFacetedResults) {
    trackUserQueryFulltextFacetedResults();
    dispatch({ type: `${searchCore}/updateStatus`, payload: { status: SEARCH_STATUS.SUCCEEDED } });
    return { nodes, relationships };
  }

  if (uniqueNodesSize) {
    dispatchShowNodesAndRelationships(dispatch, nodes, relationships, autoSelectNewNodes, categories);
  }

  await dispatch(updateStatusWithTimeoutThunk(status));
});

type SceneActionParameters = {
  nodes: (DriverCore.Integer | string)[];
  relationships: (DriverCore.Integer | string)[];
};

type RunSceneActionPayload = {
  action: SceneAction;
  params: SceneActionParameters;
  queryResultLimit: number;
  timeout: number;
  autoSelectNewNodes: boolean;
  categories: TransformedSearchCategory[];
  database?: string;
};

export const runSceneActionThunk = createAsyncThunk<void, RunSceneActionPayload, { state: RootState }>(
  `${searchCore}/runSceneAction`,
  async (payload: RunSceneActionPayload, { dispatch, getState }) => {
    const {
      action: { id, cypher },
      params,
      queryResultLimit,
      timeout,
      categories,
      database,
      autoSelectNewNodes,
    } = payload;
    const state = getState();
    const perspective = getPerspective(state);

    const isV5OrGreater = isVersion5OorGreater(getServerVersion(state));
    let nodes, relationships, recordLimitHit;
    const requestId = generateRequestId();
    dispatch({ type: `${requests}/createRequest`, payload: requestId });
    const isUpdateQuery = checkCypherUpdateClauses(cypher);

    let totalRecords: Records = { nodes: [], relationships: [] };
    let mappedRecords: Records = { nodes: [], relationships: [] };
    let uniqueNodesIds = new Set<string>();

    try {
      const recordLimit = (newRecord: DriverCore.Record) => {
        mappedRecords = mapper([newRecord]);
        const resultsByUniqueNodes = getResultsByUniqueNodes({
          previousRecords: totalRecords,
          newRecords: mappedRecords,
          uniqueNodesIds,
          limit: queryResultLimit,
          isV5OrGreater,
        });

        totalRecords = { ...resultsByUniqueNodes.totalRecords };
        uniqueNodesIds = new Set(resultsByUniqueNodes.uniqueNodesIds);

        return !resultsByUniqueNodes.isAboveLimit;
      };

      const fetchedData = dispatch(
        fetchDataThunk({ requestId, cypher, parameters: params, database, timeout, recordLimit }),
      );
      ({ recordLimitHit } = await fetchedData.unwrap());
      ({ nodes, relationships } = totalRecords);
      trackActionExecution(
        id,
        perspective?.id ?? '',
        params.nodes?.length,
        params.relationships?.length,
        isUpdateQuery,
        nodes.length,
        relationships.length,
      );
    } catch (error) {
      ({ nodes, relationships } = totalRecords);
      if (isTransactionTimeoutError(error)) {
        dispatchShowNodesAndRelationships(dispatch, nodes, relationships, autoSelectNewNodes, categories);
      }
      const status = generateSearchStatus({
        uniqueNodesSize: nodes.length,
        error,
      });
      await dispatch(updateStatusWithTimeoutThunk(status));
      return;
    }

    const uniqueNodesSize = nodes.length;
    const status = generateSearchStatus({ uniqueNodesSize: nodes.length, recordLimitHit });
    if (uniqueNodesSize) {
      dispatchShowNodesAndRelationships(dispatch, nodes, relationships, autoSelectNewNodes, categories);
    }

    await dispatch(updateStatusWithTimeoutThunk(status));
  },
);

const dispatchShowNodesAndRelationships = (
  dispatch: ThunkDispatch<RootState, unknown, UnknownAction>,
  nodes: Node[],
  relationships: Relationship[],
  autoSelectNewNodes: boolean,
  categories: TransformedSearchCategory[],
) => {
  const nodeIds = nodes.map((node: { id: string }) => node.id);
  const relationshipIds = relationships.map((rel: { id: string }) => rel.id);
  dispatch(addToInventory({ nodes, relationships }));
  dispatch(addToVisible({ nodeIds, relationshipIds, categories }));
  dispatch(zoomTo({ nodeIds }));
  if (autoSelectNewNodes) {
    dispatch(selectNodes(nodeIds));
  }
};
