import { Button, SegmentedControl, Select, Switch, TextInput, Typography } from '@neo4j-ndl/react';
import { ExclamationTriangleIconSolid } from '@neo4j-ndl/react/icons';
import type { SettingsKey, SettingsState } from '@nx/state';
import { useSetting } from '@nx/state';
import cx from 'classnames';
import { debounce } from 'lodash-es';
import type { ForwardedRef } from 'react';
import React, { forwardRef, useCallback, useState } from 'react';

interface SettingsWrapperProps {
  subtitle?: string;
  inline?: boolean;
  settingId: SettingsKey<keyof SettingsState>;
  horizontal?: boolean;
}

interface SettingsComponentProps extends SettingsWrapperProps {
  scope: keyof SettingsState;
  title: string;
  bold?: boolean;
  description?: string;
  testId?: string;
  isCompact?: boolean;
  overrideValue?: boolean;
}

export const SettingWrapper = ({
  children,
  subtitle,
  inline,
  settingId,
  horizontal,
}: React.PropsWithChildren & SettingsWrapperProps) => (
  <div
    data-settingid={settingId}
    key={settingId}
    className={cx('mb-3.5', {
      'flex flex-wrap justify-between': inline === true,
      'flex-row items-center': horizontal === true,
    })}
  >
    {children}
    {subtitle !== undefined && <span className="text-palette-neutral-text-weakest italic">{` (${subtitle})`}</span>}
  </div>
);

export const Headline = ({ title }: { title: string }) => <h6>{title}</h6>;

export const SettingsSwitch = forwardRef<HTMLDivElement, SettingsComponentProps>(
  (
    {
      title,
      subtitle,
      description,
      bold,
      inline,
      scope,
      settingId,
      isCompact = false,
      testId,
      overrideValue,
    }: SettingsComponentProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [value, toggleValue] = useSetting(scope, settingId);
    const checked = overrideValue ?? Boolean(value);
    const hasOverride = overrideValue !== undefined;

    return (
      <SettingWrapper subtitle={subtitle} settingId={settingId} inline={inline}>
        <div className="w-full flex-col" ref={ref}>
          <Switch
            isDisabled={hasOverride}
            onChange={(e) => {
              if (typeof value === 'boolean') {
                toggleValue(!value);
              }
            }}
            hasLabelBefore
            isFluid
            isChecked={checked}
            label={
              <Typography
                className={cx('mr-auto', { 'font-bold': bold })}
                variant={isCompact ? 'body-medium' : 'subheading-small'}
              >
                {title}
              </Typography>
            }
            htmlAttributes={{
              'data-testid': testId,
            }}
          />
          {description !== undefined && (
            <Typography
              className="text-palette-neutral-text-weaker mt-2 w-full"
              variant={isCompact ? 'body-medium' : 'body-large'}
            >
              {description}
            </Typography>
          )}
        </div>
      </SettingWrapper>
    );
  },
);
SettingsSwitch.displayName = 'SettingsSwitch';

export const SettingsDropdown = ({
  title,
  description,
  subtitle,
  bold,
  inline,
  scope,
  settingId,
  options,
}: SettingsComponentProps & {
  options: { label: string; value: string }[];
}) => {
  const [value, toggleValue] = useSetting(scope, settingId);
  return (
    <SettingWrapper subtitle={subtitle} settingId={settingId} inline={inline}>
      <Select
        size="small"
        type="select"
        helpText={description}
        selectProps={{
          options,
          value: options.find((option) => option.value === value),
          onChange: (e) => toggleValue(e?.value ?? value),
        }}
        label={
          <Typography className={cx('mb-1 mr-auto', { 'font-bold': bold })} variant="subheading-small">
            {title}
          </Typography>
        }
      />
    </SettingWrapper>
  );
};

export const SettingsTextInput = ({
  description,
  subtitle,
  title,
  bold,
  inline,
  scope,
  settingId,
}: SettingsComponentProps) => {
  const [value, toggleValue] = useSetting(scope, settingId);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => toggleValue(e.target.value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(debounce(onChange, 300), []);

  if (typeof value !== 'string') {
    return null;
  }

  return (
    <SettingWrapper subtitle={subtitle} settingId={settingId} inline={inline}>
      <TextInput
        value={value}
        onChange={debouncedOnChange}
        helpText={description}
        label={
          <Typography className={cx({ 'font-bold': bold })} variant="subheading-small">
            {title}
          </Typography>
        }
      />
    </SettingWrapper>
  );
};

export const SettingsNumberInput = ({
  title,
  description,
  subtitle,
  bold = false,
  inline,
  scope,
  min,
  max,
  settingId,
  allowNegative = false,
  step = 1,
  warnAbove,
  isCompact = false,
  testId,
}: SettingsComponentProps & {
  allowNegative?: boolean;
  min?: number;
  max?: number;
  warnAbove?: number;
  step?: number;
  isCompact?: boolean;
}) => {
  const [currentSettingValue, toggleValue] = useSetting(scope, settingId);

  const [localValue, setLocalValue] = useState<number | undefined>(
    typeof currentSettingValue === 'number' ? currentSettingValue : undefined,
  );

  const handleUpdate = () => {
    const currentValueFallback = typeof currentSettingValue === 'number' ? currentSettingValue : (min ?? 0);
    const adjustedToLimits =
      typeof localValue === 'number'
        ? Math.max(min ?? Number.MIN_SAFE_INTEGER, Math.min(localValue, max ?? Number.MAX_SAFE_INTEGER))
        : currentValueFallback;

    const valueHasChanged = currentSettingValue !== adjustedToLimits;
    if (!valueHasChanged) {
      return;
    }

    toggleValue(adjustedToLimits);
    setLocalValue(adjustedToLimits);
  };

  return (
    <SettingWrapper subtitle={subtitle} settingId={settingId} inline={inline} horizontal={isCompact}>
      {isCompact && (
        <Typography className={cx('text-palette-neutral-text-default', { 'font-bold': bold })} variant="body-medium">
          {title}
        </Typography>
      )}
      <TextInput
        size={isCompact ? 'small' : 'medium'}
        onChange={({ target }) => {
          const input = target.value;
          const valNumber = Number(input);

          const validNumber = input !== '' && !isNaN(valNumber) && (valNumber >= 0 || allowNegative);
          if (validNumber) {
            setLocalValue(valNumber);
          } else {
            setLocalValue(undefined);
          }
        }}
        helpText={
          <div className="flex flex-col">
            {typeof warnAbove === 'number' && warnAbove < (localValue ?? 0) && (
              <div className="flex items-center gap-1">
                <ExclamationTriangleIconSolid className="text-palette-warning-icon h-4 w-4" />
                <Typography className=" text-palette-warning-text" variant="body-small">
                  High values may degrade performance.
                </Typography>
              </div>
            )}
            {description}
          </div>
        }
        value={localValue}
        label={
          isCompact ? null : (
            <Typography className={cx({ 'font-bold': bold })} variant="subheading-small">
              {title}
            </Typography>
          )
        }
        htmlAttributes={{
          min: min,
          max: max,

          onKeyDown: (e) => {
            e.stopPropagation();
          },

          onBlur: handleUpdate,
          onMouseUp: handleUpdate,

          type: 'number',
          step: step,
          'data-testid': testId,
        }}
      />
    </SettingWrapper>
  );
};

export const SettingsButton = ({ title, scope, settingId }: SettingsComponentProps) => {
  const [value, toggleValue] = useSetting(scope, settingId);
  return (
    <Button
      onClick={() => {
        if (typeof value === 'boolean') {
          toggleValue(!value);
        }
      }}
      size="small"
      fill="outlined"
      color="neutral"
    >
      {title}
    </Button>
  );
};

export const SettingsSegmentedControl = ({
  title,
  bold,
  scope,
  settingId,
  isCompact = false,
  testId,
  options,
}: SettingsComponentProps & {
  options: { label: string; value: string }[];
}) => {
  const [value, setValue] = useSetting(scope, settingId);

  if (typeof value !== 'string') {
    return null;
  }

  return (
    <SettingWrapper settingId={settingId}>
      <div className="flex items-center justify-center gap-1.5">
        <Typography
          className={cx('text-palette-neutral-text-default mr-auto', { 'font-bold': bold })}
          variant={isCompact ? 'body-medium' : 'subheading-small'}
        >
          {title}
        </Typography>
        <SegmentedControl
          onChange={setValue}
          selected={value}
          size="small"
          data-testid={testId}
          className="bg-palette-neutral-bg-weak grow-1 !h-auto py-0.5"
        >
          {options.map((option) => (
            <SegmentedControl.Item
              key={option.value}
              value={option.value}
              className="!h-auto border border-transparent !py-0"
            >
              {option.label}
            </SegmentedControl.Item>
          ))}
        </SegmentedControl>
      </div>
    </SettingWrapper>
  );
};
