import { cleanType, cleanTypeDescription } from '../../data-transforms/clean-type.js';
import {
  type ArgumentDescription,
  type BaseArguments,
  type CypherVersion,
  cypherVersions,
} from '../../types/sdk-types.js';
import { versionSupportsCypher25 } from '../version-and-edition.js';
import validateFunction from './function-validator-generated.js';

export type Neo4jFunction = {
  name: string;
  category: string;
  description: string;
  isBuiltIn: boolean;
  argumentDescription: ArgumentDescription[];
  returnDescription: string;
  signature: string;
  aggregating: boolean;
  isDeprecated: boolean;
};

function cleanTypes(result: Neo4jFunction): Neo4jFunction {
  return {
    ...result,
    argumentDescription: result.argumentDescription.map(cleanTypeDescription),
    returnDescription: cleanType(result.returnDescription),
  };
}

/**
 * Gets available functions in your database
 * https://neo4j.com/docs/cypher-manual/current/clauses/listing-functions/
 */
export async function listFunctions({
  queryCypher,
  neo4jVersion,
  cypher25Enabled = false,
}: BaseArguments): Promise<Partial<Record<CypherVersion, Neo4jFunction[]>>> {
  const fns: Partial<Record<CypherVersion, Neo4jFunction[]>> = {};
  const pollAllNeo4jVersions = versionSupportsCypher25(neo4jVersion, cypher25Enabled);
  const versions: CypherVersion[] = pollAllNeo4jVersions ? cypherVersions : ['CYPHER 5'];

  await Promise.all(
    versions.map(async (cypherVersion) => {
      const query = `${pollAllNeo4jVersions ? cypherVersion : ''} SHOW FUNCTIONS YIELD *`;
      const res = await queryCypher(query);

      fns[cypherVersion] = res.records.map((rec) => {
        // Assign default values (e.g. isDeprecated) in case they are not present
        const objResult = rec.toObject();
        validateFunction(objResult);
        // Type is verified in integration tests
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const result = cleanTypes(objResult as Neo4jFunction);
        return result;
      });
    }),
  );

  return fns;
}
