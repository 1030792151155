export enum OPS_EVENTS {
  // Metrics page events

  /**
   * Access one of the Metrics views (tabs).
   */
  METRICS_VIEW = 'METRICS_VIEW',

  METRICS_CHANGE_TIME_PERIOD = 'METRICS_CHANGE_TIME_PERIOD',

  /**
   * Click on refresh button.
   */
  METRICS_REFRESH = 'METRICS_REFRESH',

  // Metrics chart events

  /**
   * Toggle some flag on the chart, (e.g. show series as percentage, show "totals" series etc).
   */
  METRICS_CHART_TOGGLE_FLAG = 'METRICS_CHART_TOGGLE_FLAG',

  /**
   * Zoom in on the chart.
   */
  METRICS_CHART_ZOOM_IN = 'METRICS_CHART_ZOOM_IN',

  /**
   * Select option on the chart (i.e. top level "Expand" icon or context menu item).
   */
  METRICS_CHART_SELECT_MENU_OPTION = 'METRICS_CHART_SELECT_MENU_OPTION',

  /**
   * Switch to related metric from dropdown within chart (some charts only).
   */
  METRICS_CHART_CHANGE_CHART = 'METRICS_CHART_CHANGE_CHART',

  /**
   * Request logs from chart context menu (some charts only).
   */
  METRICS_CHART_REQUEST_LOGS = 'METRICS_CHART_REQUEST_LOGS',

  // Instance card metrics events

  /**
   * Expand instance card to show resources metrics.
   */
  INSTANCE_CARD_VIEW_RESOURCES = 'INSTANCE_CARD_VIEW_RESOURCES',

  /**
   * Navigate to metrics page for the instance.
   */
  INSTANCE_CARD_VIEW_ALL_METRICS = 'INSTANCE_CARD_VIEW_ALL_METRICS',

  // Logs page events

  /**
   * Access one of the Logs views (tabs).
   */
  LOGS_VIEW = 'LOGS_VIEW',

  /**
   * View query log details for specific query.
   */
  LOGS_VIEW_QUERY_DETAILS = 'LOGS_VIEW_QUERY_DETAILS',

  /**
   * Request logs from dialog.
   */
  LOGS_REQUEST = 'LOGS_REQUEST',

  /**
   * Initiate log download.
   */
  LOGS_DOWNLOAD_INITIATE = 'LOGS_DOWNLOAD_INITIATE',

  /**
   * Download log from downloads archive.
   */
  LOGS_DOWNLOAD = 'LOGS_DOWNLOAD',

  /**
   * Delete log from downloads archive.
   */
  LOGS_DOWNLOAD_DELETE = 'LOGS_DOWNLOAD_DELETE',
}
