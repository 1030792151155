import type { Perspective } from '../../../types/perspective';
import type { LoadedPerspectives, SerializedPerspective, StoragePerspective } from '../api-types/perspective';
import {
  transformCreatePerspectivePayload,
  transformGetPerspectiveResult,
  transformUpdatePerspectivePayload,
} from '../transformers/perspectives';
import type { Builder } from '../types';

export const perspectiveEndpoints = (builder: Builder) => ({
  getPerspectives: builder.query<LoadedPerspectives, void>({
    query: () => ({ url: 'explore-perspectives', method: 'GET' }),
    transformResponse: (perspectives: SerializedPerspective[]) => transformGetPerspectiveResult(perspectives),
  }),
  createPerspective: builder.mutation<{ id: string }, StoragePerspective>({
    query: (perspective) => ({
      url: 'explore-perspectives',
      method: 'POST',
      body: transformCreatePerspectivePayload(perspective),
    }),
  }),
  updatePerspective: builder.mutation<void, { perspectiveId: Perspective['id']; update: Partial<StoragePerspective> }>({
    query: ({ perspectiveId, update }) => ({
      url: `explore-perspectives/${perspectiveId}`,
      method: 'PUT',
      body: transformUpdatePerspectivePayload(update),
    }),
  }),
  deletePerspective: builder.mutation<{ id: string }, string>({
    query: (perspectiveId) => ({
      url: `explore-perspectives/${perspectiveId}`,
      method: 'DELETE',
    }),
  }),
});
