import { setDatabase } from '../../state/connections/connectionsDuck';
import type { AppMiddleware } from '../../state/types';
import bolt from './bolt';

const boltMiddleware: AppMiddleware = () => (next) => (action) => {
  if (setDatabase.match(action)) {
    const dbName = action.payload.database.name;
    const name = dbName.length > 0 ? dbName : undefined;
    bolt.setCurrentDatabase(name);
  }
  return next(action);
};

export default boltMiddleware;
