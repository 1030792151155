import type { ExecutionResult } from 'graphql-ws';
import { createClient } from 'graphql-ws';

import type { DesktopState, Relate } from './desktop';
import type { Builder } from './endpoints/types';
import type { LocalInstanceInfo } from './relate.types';

export type WatchLocalInstanceResult = {
  watchDbmss: {
    started: null | LocalInstanceInfo;
    stopped: null | LocalInstanceInfo;
    installed: null | LocalInstanceInfo;
    uninstalled: null | string;
  };
};

export function isDesktopState(state: unknown): state is { desktop: DesktopState } {
  if (state !== null && typeof state === 'object' && 'desktop' in state) {
    return true;
  }
  return false;
}

function watchSubscription<T>(
  subscription: string,
  onNext: (value: ExecutionResult<T, T>) => void,
  relateTokens?: Relate,
  onComplete?: () => void,
) {
  if (!relateTokens) {
    return () => undefined;
  }

  const { apiToken, clientId, url = 'localhost:4000' } = relateTokens;

  const client = createClient({
    url: `ws://${url.replace('/api', '')}/graphql`,
    connectionParams: () => ({
      'X-API-Token': apiToken,
      'X-Client-Id': clientId,
    }),
  });

  function subscribe(query: string, complete: () => void = () => undefined) {
    return client.subscribe(
      { query },
      {
        next: onNext,
        error: (error) => {
          throw error;
        },
        complete,
      },
    );
  }

  return subscribe(subscription, onComplete);
}

type QueryCacheLifecycleApi = Parameters<NonNullable<Parameters<Builder['query']>[0]['onCacheEntryAdded']>>[1];
export async function registerSubscription<T>(
  api: QueryCacheLifecycleApi,
  query: string,
  onNext: (value: ExecutionResult<T, T>) => void,
) {
  let unsubscribe: () => void = () => undefined;
  const state = api.getState();
  let relate;
  if (isDesktopState(state)) {
    relate = { ...state.desktop.relate };
  }

  try {
    await api.cacheDataLoaded;

    unsubscribe = watchSubscription<T>(query, onNext, relate);
  } catch {
    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
    // in which case `cacheDataLoaded` will throw
  }

  // cacheEntryRemoved will resolve when the cache subscription is no longer active
  await api.cacheEntryRemoved;
  unsubscribe();
}

export const WATCH_PROGRESS = `
  subscription {
    progress {
      operation
      operationId
      message
      status
      error
      percent
    }
  }
`;

export const WATCH_DBMSS = `
  subscription {
    watchDbmss {
        started {
        id
        name
        status
        serverStatus
      }

      stopped {
        id
        name
        status
        serverStatus
      }

      installed {
        id
        name
        status
        serverStatus
      }

      uninstalled
    }
  }
`;
