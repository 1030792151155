import type { Scene } from '../../../types/scene';
import type { Style } from '../../../types/style';
import type {
  CreateScenePayload,
  LoadedScenes,
  SerializedScene,
  StorageScene,
  UpdateSceneSerializedPayload,
} from '../api-types/scene';

export const transformCreateScenePayload = (data: StorageScene): CreateScenePayload => {
  const payload: CreateScenePayload = {
    createdBy: data.createdBy ?? '',
    filters: JSON.stringify(data.filters ?? []),
    gds: data.gds ? JSON.stringify(data.gds) : undefined,
    id: data.id,
    name: data.name,
    nodes: JSON.stringify(data.nodes ?? []),
    numOfNodes: data.numOfNodes,
    numOfRels: data.numOfRels,
    perspectiveId: data.perspectiveId ?? '',
    ranges: JSON.stringify(data.ranges ?? []),
    relationships: data.relationships ?? [],
    roles: data.roles,
    version: data.version,
    visualisation: JSON.stringify(data.visualisation),
  };

  if (data.style) {
    payload.style = JSON.stringify(data.style);
  }

  return payload;
};

export const transformGetSceneResult = (data: SerializedScene[]): LoadedScenes => {
  const result: LoadedScenes = {
    scenes: [],
    styles: [],
  };

  for (const s of data) {
    const scene = {
      createdAt: new Date(s.createdAt),
      createdBy: s.createdBy,
      filters: s.filters ? (JSON.parse(s.filters) as Scene['filters']) : undefined,
      gds: s.gds ? (JSON.parse(s.gds) as Scene['gds']) : undefined,
      id: s.id,
      lastModified: s.updatedAt ? new Date(s.updatedAt) : undefined,
      name: s.name,
      nodes: JSON.parse(s.nodes),
      numOfNodes: s.numOfNodes,
      numOfRels: s.numOfRels,
      perspectiveId: s.perspectiveId,
      raxs4rfvnges: s.ranges ? (JSON.parse(s.ranges) as Scene['ranges']) : undefined,
      relationships: s.relationships,
      roles: s.roles,
      version: s.version,
      visualisation: s.visualisation ? (JSON.parse(s.visualisation) as Scene['visualisation']) : undefined,
    };

    result.scenes.push(scene);

    const style = typeof s.style === 'string' ? (JSON.parse(s.style) as Style) : null;
    style && result.styles.push(style);
  }

  return result;
};

export const transformUpdateScenePayload = (data: Partial<StorageScene>): UpdateSceneSerializedPayload => {
  const payload: UpdateSceneSerializedPayload = {};

  if ('name' in data) {
    payload.name = data.name;
  }

  if ('numOfNodes' in data) {
    payload.numOfNodes = data.numOfNodes;
  }

  if ('numOfRels' in data) {
    payload.numOfRels = data.numOfRels;
  }

  if ('roles' in data) {
    payload.roles = data.roles;
  }

  if ('ranges' in data) {
    payload.ranges = JSON.stringify(data.ranges);
  }

  if ('version' in data) {
    payload.version = data.version;
  }

  if ('nodes' in data) {
    payload.nodes = JSON.stringify(data.nodes);
  }

  if ('relationships' in data) {
    payload.relationships = data.relationships;
  }

  if ('filters' in data) {
    payload.filters = JSON.stringify(data.filters);
  }

  if ('gds' in data) {
    payload.gds = JSON.stringify(data.gds);
  }

  if ('visualisation' in data) {
    payload.visualisation = JSON.stringify(data.visualisation);
  }

  if ('style' in data) {
    payload.style = JSON.stringify(data.style);
  }

  return payload;
};
