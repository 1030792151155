import { Label, Typography } from '@neo4j-ndl/react';
import { ExternalLinkIcon, PlayIconSolid } from '@neo4j-ndl/react/icons';
import type { DomJson } from '@nx/constants';
import { isNotNullish, isNullish } from '@nx/stdlib';
import cx from 'classnames';
import type { ComponentProps } from 'react';
import React, { useEffect } from 'react';

import { useNxNavigate } from '../../hooks/use-nx-navigate';
import type { SvgIcon } from '../../svg-icon';
import { JsonUiConverter } from '../asciidoc-ui-converter';
import type { CardStatus } from './get-card-status';

type GuideCardProps = {
  progress?: number;
  duration?: number;
  title: string;
  label?: string;
  type: 'guide' | 'external-guide' | 'video' | 'feedback';
  description: DomJson | string | undefined;
  iconActiveSrc?: string;
  iconDefaultSrc?: string;
  thumbnail?: string;
  contentUrl?: string;
  Icon?: SvgIcon;
  cardStatus?: CardStatus;
  id: string;
  className?: string;
  titleClassName?: string;
  contentUrlText?: string;
  onSelect: () => void;
  hideStatusLabel?: boolean;
};

const jsonUiConverter = new JsonUiConverter();

const GuideDescription = (props: { value: DomJson | string }) => {
  const navigate = useNxNavigate();

  useEffect(() => {
    jsonUiConverter.registerNavigation(navigate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (typeof props.value === 'string') {
    return (
      <Typography
        as="div"
        variant="body-medium"
        htmlAttributes={{
          dangerouslySetInnerHTML: { __html: props.value },
        }}
      />
    );
  }

  return (
    <Typography as="div" variant="body-medium" className="[&_div]:m-0 [&_ul]:pl-2">
      {jsonUiConverter.renderDomJson2Jsx(props.value)}
    </Typography>
  );
};

const getStatusLabelProps = (
  progress: number | undefined,
  cardStatus: CardStatus | undefined,
  cardType: GuideCardProps['type'],
): { value: string; color: ComponentProps<typeof Label>['color'] } | null => {
  if (cardType === 'external-guide') {
    return { value: 'Self-paced learning', color: 'default' };
  }

  if (cardStatus === 'coming-soon') {
    return { value: 'Coming soon', color: 'default' };
  }

  if (cardType === 'guide') {
    if (!isNullish(progress) && progress > 0) {
      return { value: `${progress}% completed`, color: cardStatus === 'recommended' ? 'info' : 'success' };
    } else if (cardStatus === 'recommended') {
      return {
        value: 'Recommended',
        color: 'info',
      };
    }
    return { value: `Not started`, color: 'default' };
  }

  return null;
};

function getLinkText(type: string) {
  if (type === 'external-guide') {
    return 'To GraphAcademy';
  }
  if (type === 'video') {
    return 'To YouTube';
  }
  if (type === 'feedback') {
    return 'Send feedback';
  }
  return null;
}

const GuideCardContainer = (props: React.HTMLAttributes<HTMLButtonElement> & { children: React.ReactNode }) => {
  return <button {...props}>{props.children}</button>;
};

const ExternalLinkCardContainer = (props: React.HTMLAttributes<HTMLAnchorElement> & { children: React.ReactNode }) => {
  return (
    <a target="_blank" rel="noreferrer" {...props}>
      {props.children}
    </a>
  );
};

export const ConsoleGuideCard = ({
  progress,
  iconActiveSrc,
  iconDefaultSrc,
  type = 'guide',
  title,
  description,
  cardStatus,
  label,
  thumbnail,
  contentUrl,
  Icon,
  id,
  onSelect,
  className,
  titleClassName,
  contentUrlText,
  hideStatusLabel,
}: GuideCardProps) => {
  const statusLabel = getStatusLabelProps(progress, cardStatus, type);
  const iconSrc = iconDefaultSrc ?? iconActiveSrc;
  const linkText = getLinkText(type) ?? contentUrlText;
  const isExternalLink = isNotNullish(linkText) && isNotNullish(contentUrl);

  const CardContainer = type === 'guide' ? GuideCardContainer : ExternalLinkCardContainer;
  const containerProps = {
    onClick: cardStatus !== 'coming-soon' ? onSelect : undefined,
    href: contentUrl,
  };

  return (
    <CardContainer
      {...containerProps}
      className={cx(
        'rounded-4xl focus:outline-palette-primary-focus relative flex h-[392px] w-[250px] max-w-[294px] flex-col pb-5 text-left lg:w-[270px]',
        'overflow-hidden',
        cardStatus === 'recommended' && ['bg-palette-primary-bg-strong', 'text-palette-neutral-text-inverse'],
        cardStatus !== 'recommended' && [
          'bg-palette-neutral-bg-weak',
          'border',
          'border-palette-neutral-border-weak',
          'hover:border-palette-neutral-border-strong',
        ],
        className,
      )}
      title={title}
      id={id}
      data-testid={id}
    >
      {Icon !== undefined && iconDefaultSrc === undefined ? (
        <div
          className={cx(
            'absolute -right-10 -top-8 z-0 flex h-[200px] w-[200px] items-end rounded-full pb-14 pl-14',
            cardStatus === 'recommended' && ['bg-baltic-35'],
            cardStatus === 'completed' && ['text-palette-neutral-text-weakest'],
            cardStatus === undefined && ['bg-neutral-30', 'text-palette-neutral-text-weaker'],
          )}
        >
          <Icon className="h-[5rem] w-[5rem] stroke-[0.5]" />
        </div>
      ) : null}
      {isNotNullish(contentUrl) && isNotNullish(thumbnail) ? (
        <div className="relative left-[5px] top-1.5 mb-2 flex w-[96%]">
          <img className="inset-0 h-[156px] rounded-2xl object-fill" alt="Guide icon" src={thumbnail} />
          <div className="absolute inset-0 m-auto h-12 w-12 rounded-full drop-shadow-lg">
            <div className="bg-neutral-10 h-full w-full rounded-full"></div>
            <PlayIconSolid className="fill-neutral-60 absolute inset-0 m-auto h-5 w-5" />
          </div>
        </div>
      ) : null}
      {isNotNullish(iconSrc) ? (
        <img
          className="absolute right-0 top-6 z-0 h-[148px] w-[148px] object-contain object-right"
          alt="Guide icon"
          src={iconSrc}
        />
      ) : null}
      {isNotNullish(label) ? (
        <section className="guide-card-label-wrapper absolute mb-2 flex flex-col justify-between">
          <p className={'font-italic n-body-medium absolute inset-4 whitespace-nowrap'}>{label.toUpperCase()}</p>
        </section>
      ) : null}
      <section className={cx('guide-card-content-wrapper relative px-4', { 'pb-5': isExternalLink })}>
        {type === 'video' ? (
          <div className="h-6" />
        ) : (
          <>
            <div className="h-[152px]" />
            {isNotNullish(statusLabel) && hideStatusLabel !== true ? (
              <Label color={statusLabel.color} fill="semi-filled" className="mb-1">
                {statusLabel.value}
              </Label>
            ) : (
              <div className="h-[27px]" />
            )}
          </>
        )}
        <Typography className={cx('mb-3', titleClassName)} variant="h5">
          {title}
        </Typography>
        {description !== undefined ? (
          <div className="relative">
            <GuideDescription value={description} />
          </div>
        ) : null}
      </section>
      {isExternalLink ? (
        <Typography
          variant="body-medium"
          className="text-palette-primary-text absolute bottom-4 right-4 mt-2 flex w-full items-center justify-end"
        >
          {linkText || ''}
          <ExternalLinkIcon className="stroke-palette-primary-text ml-0.5 h-3 w-3 [&>path]:stroke-2" />
        </Typography>
      ) : null}
    </CardContainer>
  );
};

export const WorkspaceGuideCard = ({
  progress,
  duration,
  iconActiveSrc,
  iconDefaultSrc,
  title,
  description,
  cardStatus,
  Icon,
  id,
  onSelect,
}: GuideCardProps) => {
  const statusLabel = getStatusLabelProps(progress, cardStatus, 'guide');

  return (
    <div
      className={cx(
        'px-6',
        'py-7',
        'relative',
        'flex',
        'flex-col',
        'h-[300px] lg:h-[396px]',
        'max-w-[294px]',
        'rounded-5xl',
        { 'hover:shadow-overlay cursor-pointer': cardStatus !== 'coming-soon' },
        'overflow-hidden',
        cardStatus === 'recommended' && ['bg-palette-primary-bg-strong', 'text-palette-neutral-text-inverse'],
        cardStatus !== 'recommended' && [
          'bg-palette-neutral-bg-default',
          'border',
          'border-palette-neutral-border-weak',
        ],
      )}
      title={title}
      onClick={cardStatus !== 'coming-soon' ? onSelect : undefined}
      id={id}
    >
      {Icon !== undefined && iconDefaultSrc === undefined ? (
        <div
          className={cx(
            'absolute -right-10 -top-8 z-0 flex h-[200px] w-[200px] items-end rounded-full pb-14 pl-14',
            cardStatus === 'recommended' && ['bg-baltic-35'],
            cardStatus === 'completed' && ['text-palette-neutral-text-weakest'],
            cardStatus === undefined && ['bg-neutral-30', 'text-palette-neutral-text-weaker'],
          )}
        >
          <Icon className="h-[5rem] w-[5rem] stroke-[0.5]" />
        </div>
      ) : null}
      {iconDefaultSrc !== undefined ? (
        <img
          className="absolute -right-4 z-0 h-[150px] w-[150px] object-contain object-right"
          alt="Guide icon"
          src={cardStatus === 'recommended' && iconActiveSrc !== undefined ? iconActiveSrc : iconDefaultSrc}
        />
      ) : null}
      <section className="relative mb-2 flex flex-1 flex-col justify-between">
        {duration !== undefined ? <p className={cx('font-italic', 'n-body-medium')}>~{duration} min</p> : null}
        {!isNullish(statusLabel) && (
          <Label color={statusLabel.color} fill="semi-filled">
            {statusLabel.value}
          </Label>
        )}
      </section>
      <section className="relative">
        <Typography className="mb-1" variant="h5">
          {title}
        </Typography>
        {description !== undefined ? <GuideDescription value={description} /> : null}
      </section>
    </div>
  );
};
