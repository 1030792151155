import type { AllMetricNames } from '../metrics/charts/fullstack-chart-props';

const deprecationsPageRef = 'cypher-deprecations-additions-removals-5.0';

export type DeprecationMetadata = {
  metricName: AllMetricNames;
  deprecationName: string;
  docsRef: string;
};

export const deprecationMetricMetadata: DeprecationMetadata[] = [
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedBtreeIndexSyntax_total',
    deprecationName: 'DeprecatedBtreeIndexSyntax',
    docsRef: deprecationsPageRef,
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedCreatePropertyExistenceConstraintSyntax_total',
    deprecationName: 'DeprecatedCreatePropertyExistenceConstraintSyntax',
    docsRef: 'cypher-5_0-r_7',
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedDefaultDatabaseSyntax_total',
    deprecationName: 'DeprecatedDefaultDatabaseSyntax',
    docsRef: 'cypher-5_0-r_14',
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedDefaultGraphSyntax_total',
    deprecationName: 'DeprecatedDefaultGraphSyntax',
    docsRef: 'cypher-5_0-r_15',
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedDropConstraintSyntax_total',
    deprecationName: 'DeprecatedDropConstraintSyntax',
    docsRef: 'cypher-5_0-r_5',
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedCreateConstraintOnAssertSyntax_total',
    deprecationName: 'DeprecatedCreateConstraintOnAssertSyntax',
    docsRef: 'cypher-5_0-r_7',
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedDropIndexSyntax_total',
    deprecationName: 'DeprecatedDropIndexSyntax',
    docsRef: 'cypher-5_0-r_4',
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedFieldNotification_total',
    deprecationName: 'DeprecatedFieldNotification',
    // no single link works here, multiple fields were deprecated
    docsRef: deprecationsPageRef,
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedFunctionNotification_total',
    deprecationName: 'DeprecatedFunctionNotification',
    // no single link works here, multiple functions were deprecated
    docsRef: deprecationsPageRef,
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedHexLiteralSyntax_total',
    deprecationName: 'DeprecatedHexLiteralSyntax',
    docsRef: 'cypher-5_0-r_20',
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedOctalLiteralSyntax_total',
    deprecationName: 'DeprecatedOctalLiteralSyntax',
    docsRef: 'cypher-5_0-r_19',
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedParameterSyntax_total',
    deprecationName: 'DeprecatedParameterSyntax',
    // TODO: This needs an additional link to even older deprecation table
    docsRef: deprecationsPageRef,
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedPatternExpressionOutsideExistsSyntax_total',
    deprecationName: 'DeprecatedPatternExpressionOutsideExistsSyntax',
    docsRef: 'cypher-5_0-r_28',
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedPeriodicCommit_total',
    deprecationName: 'DeprecatedPeriodicCommit',
    docsRef: 'cypher-5_0-r_25',
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedPointsComparison_total',
    deprecationName: 'DeprecatedPointsComparison',
    docsRef: 'cypher-5_0-r_24',
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedProcedureNotification_total',
    deprecationName: 'DeprecatedProcedureNotification',
    // no single link works here, multiple procedures were deprecated
    docsRef: deprecationsPageRef,
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedPropertyExistenceSyntax_total',
    deprecationName: 'DeprecatedPropertyExistenceSyntax',
    docsRef: 'cypher-5_0-r_17',
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedRelTypeSeparatorNotification_total',
    deprecationName: 'DeprecatedRelTypeSeparatorNotification',
    docsRef: 'cypher-5_0-d_4',
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedRepeatedRelVarInPatternExpression_total',
    deprecationName: 'DeprecatedRepeatedRelVarInPatternExpression',
    docsRef: 'cypher-5_0-r_21',
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedSelfReferenceToVariableInCreatePattern_total',
    deprecationName: 'DeprecatedSelfReferenceToVariableInCreatePattern',
    docsRef: 'cypher-5_0-r_26',
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedShowExistenceConstraintSyntax_total',
    deprecationName: 'DeprecatedShowExistenceConstraintSyntax',
    docsRef: 'cypher-5_0-r_1',
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedShowSchemaSyntax_total',
    deprecationName: 'DeprecatedShowSchemaSyntax',
    // Too many things produce this name
    docsRef: deprecationsPageRef,
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedStartNotification_total',
    deprecationName: 'DeprecatedStartNotification',
    // TODO: This needs an additional link to even older deprecation table
    docsRef: deprecationsPageRef,
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedUseOfNullInCaseExpression_total',
    deprecationName: 'DeprecatedUseOfNullInCaseExpression',
    docsRef: 'cypher-5_0-u_12',
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedAmbiguousGroupingNotification_total',
    deprecationName: 'DeprecatedAmbiguousGroupingNotification',
    docsRef: 'cypher-5_0-r_29',
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_DeprecatedCoercionOfListToBoolean_total',
    deprecationName: 'DeprecatedCoercionOfListToBoolean',
    docsRef: 'cypher-5_0-r_22',
  },
  {
    metricName: 'neo4j_dbms_cypher_internal_notification_count_MissingAliasNotification_total',
    deprecationName: 'MissingAliasNotification',
    docsRef: 'cypher-5_0-r_27',
  },
];

export const getDeprecationsDocsLink = (deprecationName: string) => {
  const metadata = deprecationMetricMetadata.find((m) => m.deprecationName === deprecationName);
  if (metadata) {
    return `https://neo4j.com/docs/cypher-manual/current/deprecations-additions-removals-compatibility/#${metadata.docsRef}`;
  }
  // TODO: restore logging
  // nothing found
  // logging(
  //   'ERROR',
  //   `Encountered a deprecation name that is not covered by docsLink mapping function: ${deprecationName}`,
  // );
  // Something went wrong, lets use the default page
  return `https://neo4j.com/docs/cypher-manual/current/deprecations-additions-removals-compatibility/#${deprecationsPageRef}`;
};
