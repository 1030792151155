import { Banner, DataGrid, Label, Radio, Typography } from '@neo4j-ndl/react';
import { CursorArrowRippleIconOutline } from '@neo4j-ndl/react/icons';
import { TIER } from '@nx/state';
import type { Instance } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { Center } from '@nx/ui';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo } from 'react';

interface SizingTableProps {
  selectedInstance: Instance | undefined;
}

interface Sizing {
  checked: boolean;
  memory: string;
  price: {
    [TIER.PROFESSIONAL]: string;
    [TIER.MTE]: string;
  };
}

const data: Sizing[] = [
  {
    checked: true,
    memory: '256 MB',
    price: {
      [TIER.PROFESSIONAL]: '$0.0225/hour',
      [TIER.MTE]: '$0.0500/hour',
    },
  },
  {
    checked: false,
    memory: '512 MB',
    price: {
      [TIER.PROFESSIONAL]: '$0.0450/hour',
      [TIER.MTE]: '$0.1000/hour',
    },
  },
  {
    checked: false,
    memory: '1024 MB',
    price: {
      [TIER.PROFESSIONAL]: '$0.0900/hour',
      [TIER.MTE]: '$0.2000/hour',
    },
  },
  {
    checked: false,
    memory: '2048 MB',
    price: {
      [TIER.PROFESSIONAL]: '$0.1800/hour',
      [TIER.MTE]: '$0.4000/hour',
    },
  },
  {
    checked: false,
    memory: '4096 MB',
    price: {
      [TIER.PROFESSIONAL]: '$0.3600/hour',
      [TIER.MTE]: '$0.8000/hour',
    },
  },
];

const sizingTableHelper = createColumnHelper<Sizing>();

export const SizingTable = ({ selectedInstance }: SizingTableProps) => {
  const columns = useMemo(() => {
    const isEnterpriseTier = isNotNullish(selectedInstance) && selectedInstance.tier === TIER.ENTERPRISE;

    const cols = [
      sizingTableHelper.accessor('checked', {
        header: () => '',
        cell: (cx) => <Radio isChecked={cx.getValue()} isDisabled isFluid label="" ariaLabel="select Data API size" />,
        size: 60,
      }),
      sizingTableHelper.accessor('memory', {
        header: () => 'Memory',
        cell: (cx) => {
          const { checked } = cx.row.original;
          return (
            <>
              <span className="mr-2">{cx.getValue()}</span>
              {checked && isEnterpriseTier && <Label color="warning">free for now</Label>}
            </>
          );
        },
        maxSize: isEnterpriseTier ? 2000 : 250,
        minSize: 200,
      }),
    ];

    if (!isEnterpriseTier) {
      const extendedCols = [
        ...cols,
        sizingTableHelper.accessor('price', {
          header: () => 'Price',
          cell: (cx) => {
            const { checked } = cx.row.original;
            const price =
              isNotNullish(selectedInstance) &&
              (selectedInstance.tier === TIER.PROFESSIONAL || selectedInstance.tier === TIER.MTE)
                ? cx.getValue()[selectedInstance.tier]
                : 'Select an instance';
            return (
              <>
                <span className="mr-2 line-through">{price}</span>
                {checked && <Label color="warning">free for now</Label>}
              </>
            );
          },
          minSize: 200,
        }),
      ];
      return extendedCols;
    }

    return cols;
  }, [selectedInstance]);

  const table = useReactTable({
    data: data,
    columns,
    enableSorting: false,
    enableMultiRowSelection: false,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
  });

  if (!selectedInstance) {
    return (
      <div className="h-24">
        <Center>
          <CursorArrowRippleIconOutline className="n-size-token-7 mr-2" />
          <Typography variant="body-large">Select an instance</Typography>
        </Center>
      </div>
    );
  }

  return (
    <div className="w-full">
      {selectedInstance.tier === TIER.ENTERPRISE && (
        <Banner
          className="my-3 w-full font-light"
          hasIcon
          usage="inline"
          htmlAttributes={{
            'data-testid': 'custom-pricing',
          }}
        >
          Please refer to your contract for pricing.
        </Banner>
      )}
      <DataGrid
        isResizable={false}
        tableInstance={table}
        components={{
          Navigation: null,
        }}
        styling={{
          headerStyle: 'clean',
          borderStyle: 'horizontal',
        }}
      />
    </div>
  );
};
