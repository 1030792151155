import { Banner, Button, Dialog, Typography } from '@neo4j-ndl/react';
import { AURA_CONSOLE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { INSTANCE_PRIORITY, type Instance, consoleApi, getApiError, getErrorMessage } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { type SyntheticEvent, useMemo } from 'react';

import { useTrackUpxEvent } from '../../services/segment/analytics';

type Props = {
  onClose: () => void;
  onSuccess: () => void;
  instance: Instance;
  priority: INSTANCE_PRIORITY.HIGH | INSTANCE_PRIORITY.HIGHEST;
};

const config = {
  [INSTANCE_PRIORITY.HIGHEST]: {
    header: 'Mark as Production',
    content: (instanceName: string) => (
      <Typography as="p" variant="body-medium" className="mb-4">
        Set this label to inform Neo4j that <strong className="italic">{instanceName}</strong> is mission critical to
        your organization.
      </Typography>
    ),
    trackingEvent: AURA_CONSOLE_EVENTS.INSTANCE_MARK_AS_PRODUCTION,
  },
  [INSTANCE_PRIORITY.HIGH]: {
    header: 'Unmark as Production',
    content: (instanceName: string) => (
      <Typography as="p" variant="body-medium" className="mb-4">
        This will remove the production label from <strong className="italic">{instanceName}</strong>.
      </Typography>
    ),
    trackingEvent: AURA_CONSOLE_EVENTS.INSTANCE_UNMARK_AS_PRODUCTION,
  },
};

export const UpdateInstancePriorityModal = ({ onClose, onSuccess, instance, priority }: Props) => {
  const trackEvent = useTrackUpxEvent();
  const [setPriority, setPriorityResponse] = consoleApi.useUpdateInstancePriorityMutation();
  const priorityConfig = config[priority];

  const errorMessage = useMemo(() => {
    if (!setPriorityResponse.isError) {
      return null;
    }
    const error = getApiError(setPriorityResponse.error);
    return getErrorMessage(error);
  }, [setPriorityResponse.error, setPriorityResponse.isError]);

  const handleFormSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    void setPriority({ dbId: instance.id, priority }).then(onSuccess);
    trackEvent({ event: priorityConfig.trackingEvent, scope: APP_SCOPE.aura });
  };

  return (
    <Dialog type="info" onClose={onClose} isOpen>
      <Dialog.Header>{priorityConfig.header}</Dialog.Header>
      <form onSubmit={handleFormSubmit}>
        <Dialog.Content>
          {priorityConfig.content(instance.name)}
          <Typography as="p" variant="body-medium">
            During this process, your instance will update. Read/write availability is maintained, however other
            instance actions (pause, clone, configure) will be temporarily unavailable until the update is complete.
          </Typography>
          {isNotNullish(errorMessage) && <Banner type="danger" description={errorMessage} usage="inline" />}
        </Dialog.Content>
        <Dialog.Actions>
          <Button
            onClick={onClose}
            fill="outlined"
            color="neutral"
            isDisabled={setPriorityResponse.isLoading}
            htmlAttributes={{
              'data-testid': 'update-instance-priority-cancel-button',
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            isLoading={setPriorityResponse.isLoading}
            htmlAttributes={{
              'data-testid': 'update-instance-priority-confirm-button',
            }}
          >
            Confirm
          </Button>
        </Dialog.Actions>
      </form>
    </Dialog>
  );
};
