import {
  Banner,
  Button,
  Checkbox,
  Dialog,
  LoadingSpinner,
  Select,
  Switch,
  TextLink,
  Tooltip,
  Typography,
  toast,
} from '@neo4j-ndl/react';
import { InformationCircleIconOutline, InformationCircleIconSolid } from '@neo4j-ndl/react/icons';
import type { CmiScrapeConfig, Instance, ProjectSummary } from '@nx/state';
import { CMI_METRICS_GRANULARITY, useOpsContext } from '@nx/state';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { useDispatch } from '../../../../../packages/state/src/context';
import {
  initScrapeConfig,
  resetScrapeConfig,
  updateInstanceMetricsGranularity,
  updateProjectMetricsEnableForNewInstances,
  updateProjectMetricsGranularity,
  updateProjectMetricsInstanceConfigs,
} from '../../../../../packages/state/src/slices/ops-context-slice';
import type { Endpoint } from '../../../../../packages/state/src/slices/ops/types';
import { ENDPOINT_TARGET_TYPE } from '../../../../../packages/state/src/slices/ops/types';
import { ApiErrorBanner } from '../../shared/components';
import { useMetricsEndpointConfig } from '../hooks/use-metrics-endpoint-config';

export type EndpointConfigModalContext = {
  isOpen: boolean;
  endpointStatus: Endpoint | null;
};

const InstanceSelection = ({
  instance,
  scrapeConfig,
  onChange,
}: {
  instance: Instance;
  scrapeConfig: CmiScrapeConfig;
  onChange: (enable: { instanceId: string; enabled: boolean }) => void;
}) => {
  const [checked, setChecked] = useState<boolean>(
    scrapeConfig.instances.findIndex((i) => i.instanceId === instance.id) !== -1,
  );
  return (
    <Checkbox
      ariaLabel={`Select ${instance.name}`}
      label={instance.name}
      isChecked={checked}
      onChange={() => {
        onChange({ instanceId: instance.id, enabled: !checked });
        setChecked(!checked);
      }}
    />
  );
};

const ScrapeConfigToggle = ({
  label,
  initValue,
  onChange,
}: {
  label: string;
  initValue: boolean;
  onChange: (toggled: boolean) => void;
}) => {
  const [checked, setChecked] = useState<boolean>(initValue);
  return (
    <Switch
      className="self-center"
      ariaLabel={`Toggle ${label}`}
      label={label}
      isChecked={checked}
      onChange={() => {
        onChange(!checked);
        setChecked(!checked);
      }}
    />
  );
};

const MetricsGranularitySelector = ({
  initValue,
  options,
  onSelection,
  additionalClasses,
}: {
  initValue: CMI_METRICS_GRANULARITY;
  options: { label: string; value: CMI_METRICS_GRANULARITY }[];
  onSelection: (selected: CMI_METRICS_GRANULARITY) => void;
  additionalClasses?: string;
}) => {
  return (
    <Select
      aria-label={`Select metrics granularity`}
      className={`mr-4 w-fit ${additionalClasses}`}
      type="select"
      size="medium"
      selectProps={{
        delimiter: '=>',
        placeholder: initValue,
        options,
        menuPosition: 'fixed',
        onChange: (e) => {
          if (e) {
            onSelection(e.value);
          }
        },
      }}
    />
  );
};

function getInitMetricsGranularity(
  endpointStatus: Endpoint,
  editedScrapeConfig: CmiScrapeConfig,
): CMI_METRICS_GRANULARITY {
  if (endpointStatus.type === ENDPOINT_TARGET_TYPE.PROJECT) {
    return editedScrapeConfig.metricsGranularity;
  }

  const instanceEndpoint = editedScrapeConfig.instanceEndpoints.find(
    (ie) => ie.config.instanceId === endpointStatus.id,
  );
  return instanceEndpoint ? instanceEndpoint.config.metricsGranularity : CMI_METRICS_GRANULARITY.BASIC;
}

function removeDeletedInstanceConfigs(scrapeConfig: CmiScrapeConfig, instances: Instance[]): CmiScrapeConfig {
  const sc = { ...scrapeConfig };
  const activeIds = instances.map((i) => i.id);
  sc.instanceEndpoints = sc.instanceEndpoints.filter((i) => activeIds.includes(i.config.instanceId));
  sc.instances = sc.instances.filter((i) => activeIds.includes(i.instanceId));
  return sc;
}

const configItemDescription = (content: string) => (
  <Tooltip type="simple" isPortaled={false}>
    <Tooltip.Trigger aria-label="Describe configuration item">
      <InformationCircleIconOutline className={classNames('size-5', 'text-palette-neutral-text-weaker', 'ml-1')} />
    </Tooltip.Trigger>
    <Tooltip.Content className="w-[50%]">
      <Typography variant="body-medium">{content}</Typography>
    </Tooltip.Content>
  </Tooltip>
);

const EndpointConfigModal = ({
  activeProject,
  instances,
  open,
  onClose,
  endpointStatus,
  allowMetricsGranularitySettings,
}: {
  activeProject: ProjectSummary;
  open: boolean;
  onClose: () => void;
  instances: Instance[];
  endpointStatus: Endpoint;
  allowMetricsGranularitySettings: boolean;
}) => {
  const dispatch = useDispatch();
  const { scrapeConfig: editedScrapeConfig } = useOpsContext();
  const { scrapeConfig, configCommitStatus, commitConfig, configFetchStatus } = useMetricsEndpointConfig(
    activeProject,
    instances,
  );

  useEffect(() => {
    if (scrapeConfig) {
      dispatch(initScrapeConfig(removeDeletedInstanceConfigs(scrapeConfig, instances)));
    }
  }, [dispatch, instances, scrapeConfig]);

  const handleSubmit = () => {
    commitConfig(editedScrapeConfig)
      .then((res) => {
        toast.success('Endpoint scrape configuration updated', { shouldAutoClose: true });
        if (scrapeConfig) {
          dispatch(resetScrapeConfig(scrapeConfig));
        }
        onClose();
      })
      .catch(() => {
        toast.danger('Failed to update endpoint scrape configuration', { shouldAutoClose: true });
      });
  };
  const handleCancel = () => {
    if (scrapeConfig) {
      dispatch(resetScrapeConfig(scrapeConfig));
    }
    onClose();
  };
  const handleClose = () => {
    if (scrapeConfig) {
      dispatch(resetScrapeConfig(scrapeConfig));
    }
    onClose();
  };

  return (
    <Dialog isOpen={open} onClose={() => handleClose()} modalProps={{ 'data-testid': 'link-prometheus-config-modal' }}>
      <Dialog.Header className="mb-2">{`Configure ${endpointStatus.type === ENDPOINT_TARGET_TYPE.PROJECT ? 'project' : 'instance'} endpoint`}</Dialog.Header>
      {editedScrapeConfig &&
      scrapeConfig !== undefined &&
      !configFetchStatus.isFetching &&
      !configFetchStatus.isLoading ? (
        <div className="flex flex-col">
          {endpointStatus.type === ENDPOINT_TARGET_TYPE.PROJECT && (
            <div>
              <div className="align-center mb-2 flex">
                <span>Select instances to include in this endpoint</span>
                {configItemDescription(
                  'This is a project endpoint specific setting which allows users to choose specific instances to scrape metrics for.',
                )}
              </div>

              {instances.map((i) => (
                <div key={i.id} className="my-2">
                  <InstanceSelection
                    scrapeConfig={editedScrapeConfig}
                    instance={i}
                    onChange={(enable) => {
                      dispatch(updateProjectMetricsInstanceConfigs(enable));
                    }}
                  />
                </div>
              ))}
            </div>
          )}

          {endpointStatus.type === ENDPOINT_TARGET_TYPE.PROJECT && (
            <div className="my-4 flex">
              <ScrapeConfigToggle
                label={'Include new instances'}
                initValue={editedScrapeConfig.enableForNewInstances}
                onChange={(isEnabled) => {
                  dispatch(updateProjectMetricsEnableForNewInstances(isEnabled));
                }}
              />
              {configItemDescription(
                'This setting enables scraping for newly created instance without manually updating the Project scrape configuration.',
              )}
            </div>
          )}

          {!allowMetricsGranularitySettings && (
            <Banner className="my-2">
              <InformationCircleIconSolid className="mr-1 inline h-6 w-6" />
              <Typography className="my-4 self-center text-wrap" variant="body-medium">
                Contact{' '}
                <TextLink
                  isExternalLink
                  href="https://neo4j.com/docs/aura/platform/metrics-integration/"
                  htmlAttributes={{
                    target: '_blank',
                    rel: 'noreferrer',
                  }}
                >
                  Customer Support
                </TextLink>{' '}
                to enable more granular metrics of instances for your project.
              </Typography>
            </Banner>
          )}
          <div>
            <div className="align-center my-2 flex">
              <Typography variant="subheading-small">Metrics granularity</Typography>
              {configItemDescription(
                'This setting allows scraping more granular metrics (Comprehensive) for each of the instances enabled.',
              )}
            </div>

            <MetricsGranularitySelector
              aria-disabled={!allowMetricsGranularitySettings}
              additionalClasses={
                allowMetricsGranularitySettings ? '' : 'opacity-50 pointer-events-none border-gray-900'
              }
              initValue={getInitMetricsGranularity(endpointStatus, editedScrapeConfig)}
              options={[CMI_METRICS_GRANULARITY.BASIC, CMI_METRICS_GRANULARITY.COMPREHENSIVE].map((g) => ({
                label: g,
                value: g,
              }))}
              onSelection={(selected) => {
                if (endpointStatus.type === ENDPOINT_TARGET_TYPE.PROJECT) {
                  dispatch(updateProjectMetricsGranularity(selected));
                } else {
                  dispatch(updateInstanceMetricsGranularity({ instanceId: endpointStatus.id, granularity: selected }));
                }
              }}
            />
          </div>

          {(endpointStatus.type === ENDPOINT_TARGET_TYPE.PROJECT || allowMetricsGranularitySettings) && (
            <div className="flex flex-row-reverse">
              <Button
                className="mr-2"
                isDisabled={configCommitStatus.isLoading}
                fill="filled"
                color="primary"
                onClick={() => handleSubmit()}
                isLoading={configCommitStatus.isLoading}
              >
                Confirm
              </Button>
              <Button className="mr-4" fill="outlined" color="neutral" onClick={() => handleCancel()}>
                Cancel
              </Button>
            </div>
          )}

          {configCommitStatus.isError && <ApiErrorBanner className="mt-4" error={configCommitStatus.error} />}
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </Dialog>
  );
};

export default EndpointConfigModal;
