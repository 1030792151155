import { Banner, Dialog, TextLink, Typography } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { consoleApi, getApiError, getErrorMessage } from '@nx/state';
import type { Project } from '@nx/state';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Suspense, lazy } from 'react';

import { StripeFormFallback } from '../loading-fallbacks';

const StripeForm = lazy(() => import('../project/billing/shared/stripe-form'));

const logger = createLogger(APP_SCOPE.framework);

interface Props {
  onClose: () => void;
  project: Project;
}

export const ExtendTrialModal = ({ onClose, project }: Props) => {
  const { organizationId } = project;
  const [extendTrial, extendTrialRes] = consoleApi.useExtendTrialMutation();

  const handleSuccess = () => {
    extendTrial({ organizationId: organizationId })
      .unwrap()
      .then(() => onClose())
      .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(e);
        const errorMessage = getErrorMessage(error);
        logger.error(errorMessage);
      });
  };
  return (
    <Dialog isOpen onClose={onClose}>
      <Dialog.Header>Extend trial</Dialog.Header>
      <Dialog.Description>Get an additional 7 days of trial for free by adding your payment details</Dialog.Description>
      <Dialog.Content className="!mt-4">
        <Typography variant="body-medium" className="flex flex-col gap-2" as="div">
          <Banner hasIcon>
            The instance will be charged $0.18/hour ($129.60/month) after the trial expires unless the instance has been
            deleted before expiration.
          </Banner>
          <TextLink href="https://neo4j.com/pricing/" type="external" className="mb-4">
            Read more about what features you access to.
          </TextLink>
        </Typography>
        <Suspense fallback={<StripeFormFallback />}>
          <StripeForm
            onCancel={onClose}
            submitButtonText="Extend trial"
            cancelButtonText="Cancel"
            onSuccess={handleSuccess}
            isLoading={extendTrialRes.isLoading}
            isTrialExtension
          />
        </Suspense>
      </Dialog.Content>
    </Dialog>
  );
};
