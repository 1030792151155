import { tokens } from '@neo4j-ndl/base';
import { Button, TextLink, Typography, useMediaQuery } from '@neo4j-ndl/react';
import type { DataApiBatchResult, GetDataApisBatchResponse } from '@nx/state';
import { useState } from 'react';

import { DataApiTable } from '../modules/data-api-table';
import { MODIFY_MODE } from '../types';

interface OverviewProps {
  dataApis: GetDataApisBatchResponse | undefined;
  setActiveModifyDataApi: (modifyDataApi: DataApiBatchResult) => void;
  setModifyMode: (mode: MODIFY_MODE) => void;
  fetchDataApis: () => Promise<void>;
}

export const Overview = ({ fetchDataApis, setActiveModifyDataApi, dataApis, setModifyMode }: OverviewProps) => {
  const [filter, setFilter] = useState('');
  const isLgOrAbove = useMediaQuery(`(min-width:${tokens.breakpoints.lg})`);
  const normalisedFilter = filter.trim().toLocaleLowerCase();

  const filteredDataApis = normalisedFilter
    ? dataApis?.filter(({ name, id }) => [name, id].some((str) => str.toLocaleLowerCase().includes(normalisedFilter)))
    : dataApis;

  return (
    <div className="h-full overflow-auto p-4" data-testid="data-api-app">
      <div className={`mx-3 mb-6 flex justify-between ${isLgOrAbove ? 'items-center' : 'flex-col items-start'}`}>
        <div className={`flex items-center gap-4 ${!isLgOrAbove && 'mb-4 mr-auto'}`}>
          <Typography variant="h2">GraphQL Data API</Typography>
          <TextLink className="no-underline" href="https://neo4j.com/docs/graphql/6/aura-graphql" type="external">
            Docs
          </TextLink>
        </div>
        <div className={`${!isLgOrAbove && 'ml-auto'}`}>
          <Button onClick={() => setModifyMode(MODIFY_MODE.create)}>Create API</Button>
        </div>
      </div>
      {filteredDataApis && (
        <DataApiTable
          filter={filter}
          setFilter={(val) => setFilter(val)}
          dataApis={filteredDataApis}
          onClickEdit={(modifyDataApi) => {
            setActiveModifyDataApi(modifyDataApi);
            setModifyMode(MODIFY_MODE.edit);
          }}
          onClickMenuItemAction={() => {
            void fetchDataApis();
          }}
        />
      )}
    </div>
  );
};
