import consoleApi from '../slices/console/console.api';
import { ENCRYPTION_KEY_STATUS } from '../slices/console/console.types';
import { useActiveProject } from './use-app-context';

export const useEncryptionKeys = () => {
  const activeProject = useActiveProject();
  const { data: encryptionKeys = [], isError } = consoleApi.useListEncryptionKeysQuery(activeProject.id, {
    skip: !activeProject.capabilities.cmek,
  });
  const availableKeys = encryptionKeys.filter((key) => key.status === ENCRYPTION_KEY_STATUS.READY);

  return { keys: availableKeys, isError };
};
