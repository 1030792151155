import { isNumber } from 'lodash-es';

export default [
  {
    version: '2.1.0',
    up: (state) => {
      return {
        ...state,
        logoutTimeout: isNumber(state.logoutTimeout) ? state.logoutTimeout * 60 : state.logoutTimeout,
      };
    },
  },
];
