import type { AuthPermissionsRequestBody, AuthPermissionsResponse } from '../api-types';
import type { Builder } from './types';

const AUTH_BASE_PATH = `/auth`;

export const authEndpoints = (builder: Builder) => ({
  getAuthPermissions: builder.query<AuthPermissionsResponse, AuthPermissionsRequestBody>({
    query: (body) => ({
      url: `${AUTH_BASE_PATH}/permissions?tenantId=${body.tenantId}&dbmsId=${body.dbmsId}`,
      method: 'GET',
      headers: { 'Project-Id': body.tenantId },
    }),
  }),
});
