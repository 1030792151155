import type { ZoneId } from '@js-joda/core';
import { isEmpty, isNil } from 'lodash-es';
import type { Moment } from 'moment';
import neo4j from 'neo4j-driver';

import type { Nullable } from '../../types/utility';
import { isFalsy } from '../../types/utility';
import { log } from '../logging';
import { Date } from './Date';
import { DateTime } from './DateTime';
import { LocalDateTime } from './LocalDateTime';
import { LocalTime } from './LocalTime';
import { Time } from './Time';
import type { DateTimeProperties, TEMPORAL_TYPES, TEMPORAL_TYPES_STRING_REPRESENTATION } from './types';
import {
  DATE,
  DATETIME,
  DATE_TIME_TYPE,
  DURATION,
  LOCAL_DATETIME,
  LOCAL_TIME,
  TIME,
  TIME_PICKER_TYPES,
  ZONED_TYPE,
} from './utils.const';

export const isZonedType = (type: string): type is typeof TIME | typeof DATETIME => ZONED_TYPE.includes(type);
export const isDateTimeType = (
  type: unknown,
): type is typeof DATE | typeof LOCAL_TIME | typeof LOCAL_DATETIME | typeof TIME | typeof DATETIME =>
  DATE_TIME_TYPE.includes(type as string);
export const isDurationType = (type: string): type is typeof DURATION => type === DURATION;
const isTimeObject = (obj: unknown): obj is Time | DateTime => obj instanceof Time || obj instanceof DateTime;

/**
 * Parser function naming convention
 * 1. name the parser like parseAToB()
 * 2. A and B could be string(redux state value), number, obj(Temporal wrapper class), jodaObj, momentObj, inputString(user input value)
 */

export const parseStringToNumber = (
  stringVal: string,
  type: TEMPORAL_TYPES_STRING_REPRESENTATION,
  selectedTimeZone?: string,
  isTimeZoneConvertEnabled?: boolean,
) => {
  try {
    switch (type) {
      case DATE:
        return Date.parseStringToNumber(stringVal);
      case LOCAL_TIME:
        return LocalTime.parseStringToNumber(stringVal);
      case LOCAL_DATETIME:
        return LocalDateTime.parseStringToNumber(stringVal);
      case TIME:
        return Time.parseStringToNumber(stringVal, selectedTimeZone, isTimeZoneConvertEnabled);
      case DATETIME:
        return DateTime.parseStringToNumber(stringVal);
    }
  } catch (e) {
    log.debug('temporal, utils', 'parseStringToNumber', e);
    return NaN;
  }

  return NaN;
};

export const parseNumberToString = (
  value: number,
  type: TEMPORAL_TYPES_STRING_REPRESENTATION,
  hideTime?: boolean,
  timezone?: string,
) => {
  try {
    switch (type) {
      case LOCAL_TIME:
        return LocalTime.parseNumberToString(value);
      case DATE:
        return Date.parseNumberToString(value);
      case LOCAL_DATETIME:
        return LocalDateTime.parseNumberToString(value);
      case DATETIME:
        return DateTime.parseNumberToString(value, hideTime);
      case TIME:
        return Time.parseNumberToString(value, timezone);
      default:
        return '';
    }
  } catch (e) {
    log.debug('temporal, utils', 'parseNumberToString', e);
  }
  return '';
};

export const parseObjToStringWithoutTimeZone = (obj: TEMPORAL_TYPES, type: TEMPORAL_TYPES_STRING_REPRESENTATION) => {
  try {
    switch (type) {
      case DATE:
      case LOCAL_DATETIME:
      case LOCAL_TIME:
        return obj.toString();
      case TIME:
        return (obj as Time).localTime.toString();
      case DATETIME:
        return (obj as DateTime).localDateTime.toString();
    }
  } catch (e) {
    log.debug('temporal, utils', 'parseObjToString', e);
  }

  return '';
};

export const parseStringToObj = (
  stringVal: string,
  type: TEMPORAL_TYPES_STRING_REPRESENTATION,
): Nullable<TEMPORAL_TYPES> => {
  let newDateTime = null;

  if (isEmpty(stringVal)) {
    return newDateTime;
  }

  try {
    switch (type) {
      case DATE:
        newDateTime = Date.parseStringToObj(stringVal);
        break;
      case LOCAL_DATETIME:
        newDateTime = LocalDateTime.parseStringToObj(stringVal);
        break;
      case LOCAL_TIME:
        newDateTime = LocalTime.parseStringToObj(stringVal);
        break;
      case TIME:
        newDateTime = Time.parseStringToObj(stringVal);
        break;
      case DATETIME:
        newDateTime = DateTime.parseStringToObj(stringVal);
        break;
    }
  } catch (e) {
    log.debug('temporal, utils', 'parseStringToObj', e);
  }

  return newDateTime;
};

type TIME_PICKER_TEMPORAL_TYPES = LocalTime | Time | LocalDateTime | DateTime;
export const parseMomentObjToObj = (
  momentObj: Moment,
  type: TEMPORAL_TYPES_STRING_REPRESENTATION,
  originalDateTime: Nullable<TEMPORAL_TYPES>,
  originalTimezone?: string | ZoneId,
) => {
  let newDateTime = null;

  let nanoseconds = 0;
  if (TIME_PICKER_TYPES.includes(type)) {
    nanoseconds = !isNil(originalDateTime) ? (originalDateTime as TIME_PICKER_TEMPORAL_TYPES).getNanosecond() : 0;
  }

  const zoneStr = !isNil(originalTimezone) ? originalTimezone.toString() : 'Z';

  switch (type) {
    case DATE:
      newDateTime = Date.parseMomentObjToObj(momentObj);
      break;
    case LOCAL_DATETIME:
      newDateTime = LocalDateTime.parseMomentObjToObj(momentObj, nanoseconds);
      break;
    case LOCAL_TIME:
      newDateTime = LocalTime.parseMomentObjToObj(momentObj, nanoseconds);
      break;
    case DATETIME:
      newDateTime = DateTime.parseMomentObjToObj(momentObj, nanoseconds, zoneStr);
      break;
    case TIME:
      newDateTime = Time.parseMomentObjToObj(momentObj, nanoseconds, zoneStr);
      break;
  }

  return newDateTime;
};

export const convertDateTimePropertiesToObj = (
  dateTimeProperties: DateTimeProperties,
  type: TEMPORAL_TYPES_STRING_REPRESENTATION,
) => {
  let dateTimeObj;

  try {
    switch (type) {
      case DATE:
        dateTimeObj = new Date(dateTimeProperties);
        break;
      case LOCAL_DATETIME:
        dateTimeObj = new LocalDateTime(dateTimeProperties);
        break;
      case LOCAL_TIME:
        dateTimeObj = new LocalTime(dateTimeProperties);
        break;
      case DATETIME:
        dateTimeObj = new DateTime(dateTimeProperties);
        break;
      case TIME:
        dateTimeObj = new Time(dateTimeProperties);
    }
  } catch (e) {
    log.debug('temporal, utils', 'convertDateTimePropertiesToObj', e);
    return null;
  }

  return dateTimeObj;
};

export const compareTemporalValue = (
  type: TEMPORAL_TYPES_STRING_REPRESENTATION,
  temporal1: TEMPORAL_TYPES,
  temporal2: TEMPORAL_TYPES,
  selectedTimeZone: string,
  isTimeZoneConvertEnabled: boolean,
) => {
  switch (type) {
    case DATE:
      return compareDate(temporal1 as Date, temporal2 as Date);
    case LOCAL_TIME:
    case TIME:
      return compareTime(
        temporal1 as LocalTime | Time,
        temporal2 as LocalTime | Time,
        selectedTimeZone,
        isTimeZoneConvertEnabled,
      );
    case LOCAL_DATETIME:
    case DATETIME:
      return compareDateTime(
        temporal1 as LocalDateTime | DateTime,
        temporal2 as LocalDateTime | DateTime,
        selectedTimeZone,
        isTimeZoneConvertEnabled,
      );
  }

  return NaN;
};

const setTimezoneShiftTo = (dateTimeObj: Nullable<TEMPORAL_TYPES>, zoneStr: string) => {
  return isTimeObject(dateTimeObj) ? dateTimeObj.setTimezoneShiftTo(zoneStr) : null;
};

const setTimezone = (dateTimeObj: Nullable<TEMPORAL_TYPES>, zoneStr: string) => {
  return isTimeObject(dateTimeObj) ? dateTimeObj.setTimezone(zoneStr) : null;
};

export const setTimezoneForString = (stringVal: string, type: TEMPORAL_TYPES_STRING_REPRESENTATION, zoneStr = 'Z') => {
  return setTimezone(parseStringToObj(stringVal, type), zoneStr)?.toString();
};

export const setTimezoneShiftToForString = (
  stringVal: string,
  type: TEMPORAL_TYPES_STRING_REPRESENTATION,
  zoneStr = 'Z',
) => {
  return setTimezoneShiftTo(parseStringToObj(stringVal, type), zoneStr)?.toString();
};

export const compareTime = (
  dateTimeObj1: LocalTime | Time,
  dateTimeObj2: LocalTime | Time,
  selectedTimeZone: string,
  isTimeZoneConvertEnabled: boolean,
) => {
  if (isFalsy(dateTimeObj1) || isFalsy(dateTimeObj2)) {
    return;
  }
  let newDateTimeObj1: Nullable<Time | LocalTime> = dateTimeObj1;
  let newDateTimeObj2 = dateTimeObj2;

  if (isTimeZoneConvertEnabled) {
    newDateTimeObj1 = setTimezoneShiftTo(dateTimeObj1, selectedTimeZone) as Time;
    newDateTimeObj2 = setTimezoneShiftTo(dateTimeObj2, selectedTimeZone) as Time;
  }

  if (newDateTimeObj1 !== null && newDateTimeObj2 !== null) {
    const hour1 = newDateTimeObj1.getHour();
    const minute1 = newDateTimeObj1.getMinute();
    const second1 = newDateTimeObj1.getSecond();
    const nanosecond1 = newDateTimeObj1.getNanosecond();

    const hour2 = newDateTimeObj2.getHour();
    const minute2 = newDateTimeObj2.getMinute();
    const second2 = newDateTimeObj2.getSecond();
    const nanosecond2 = newDateTimeObj2.getNanosecond();

    if (
      hour1 > hour2 ||
      (hour1 === hour2 && minute1 > minute2) ||
      (hour1 === hour2 && minute1 === minute2 && second1 > second2) ||
      (hour1 === hour2 && minute1 === minute2 && second1 === second2 && nanosecond1 > nanosecond2)
    ) {
      return 1;
    } else if (
      hour1 < hour2 ||
      (hour1 === hour2 && minute1 < minute2) ||
      (hour1 === hour2 && minute1 === minute2 && second1 < second2) ||
      (hour1 === hour2 && minute1 === minute2 && second1 === second2 && nanosecond1 < nanosecond2)
    ) {
      return -1;
    }
  }

  return 0;
};

export const compareDate = (dateTimeObj1: Date, dateTimeObj2: Date) => {
  if (isFalsy(dateTimeObj1) || isFalsy(dateTimeObj2)) {
    return;
  }

  const year1 = dateTimeObj1.getYear();
  const month1 = dateTimeObj1.getMonth();
  const day1 = dateTimeObj1.getDay();

  const year2 = dateTimeObj2.getYear();
  const month2 = dateTimeObj2.getMonth();
  const day2 = dateTimeObj2.getDay();

  if (year1 > year2 || (year1 === year2 && month1 > month2) || (year1 === year2 && month1 === month2 && day1 > day2)) {
    return 1;
  } else if (
    year1 < year2 ||
    (year1 === year2 && month1 < month2) ||
    (year1 === year2 && month1 === month2 && day1 < day2)
  ) {
    return -1;
  }

  return 0;
};

export const compareDateTime = (
  dateTimeObj1: LocalDateTime | DateTime,
  dateTimeObj2: LocalDateTime | DateTime,
  selectedTimeZone: string,
  isTimeZoneConvertEnabled: boolean,
) => {
  if (isFalsy(dateTimeObj1) || isFalsy(dateTimeObj2)) {
    return;
  }

  let newDateTimeObj1 = dateTimeObj1;
  let newDateTimeObj2 = dateTimeObj2;

  if (isTimeZoneConvertEnabled) {
    newDateTimeObj1 = setTimezoneShiftTo(dateTimeObj1, selectedTimeZone) as DateTime;
    newDateTimeObj2 = setTimezoneShiftTo(dateTimeObj2, selectedTimeZone) as DateTime;
  }

  if (!isNil(newDateTimeObj1) && !isNil(newDateTimeObj2)) {
    const year1 = newDateTimeObj1.getYear();
    const month1 = newDateTimeObj1.getMonth();
    const day1 = newDateTimeObj1.getDay();
    const hour1 = newDateTimeObj1.getHour();
    const minute1 = newDateTimeObj1.getMinute();
    const second1 = newDateTimeObj1.getSecond();
    const nanosecond1 = newDateTimeObj1.getNanosecond();

    const year2 = newDateTimeObj2.getYear();
    const month2 = newDateTimeObj2.getMonth();
    const day2 = newDateTimeObj2.getDay();
    const hour2 = newDateTimeObj2.getHour();
    const minute2 = newDateTimeObj2.getMinute();
    const second2 = newDateTimeObj2.getSecond();
    const nanosecond2 = newDateTimeObj2.getNanosecond();

    if (
      year1 > year2 ||
      (year1 === year2 && month1 > month2) ||
      (year1 === year2 && month1 === month2 && day1 > day2) ||
      (year1 === year2 && month1 === month2 && day1 === day2 && hour1 > hour2) ||
      (year1 === year2 && month1 === month2 && day1 === day2 && hour1 === hour2 && minute1 > minute2) ||
      (year1 === year2 &&
        month1 === month2 &&
        day1 === day2 &&
        hour1 === hour2 &&
        minute1 === minute2 &&
        second1 > second2) ||
      (year1 === year2 &&
        month1 === month2 &&
        day1 === day2 &&
        hour1 === hour2 &&
        minute1 === minute2 &&
        second1 === second2 &&
        nanosecond1 > nanosecond2)
    ) {
      return 1;
    } else if (
      year1 < year2 ||
      (year1 === year2 && month1 < month2) ||
      (year1 === year2 && month1 === month2 && day1 < day2) ||
      (year1 === year2 && month1 === month2 && day1 === day2 && hour1 < hour2) ||
      (year1 === year2 && month1 === month2 && day1 === day2 && hour1 === hour2 && minute1 < minute2) ||
      (year1 === year2 &&
        month1 === month2 &&
        day1 === day2 &&
        hour1 === hour2 &&
        minute1 === minute2 &&
        second1 < second2) ||
      (year1 === year2 &&
        month1 === month2 &&
        day1 === day2 &&
        hour1 === hour2 &&
        minute1 === minute2 &&
        second1 === second2 &&
        nanosecond1 < nanosecond2)
    ) {
      return -1;
    }
  }

  return 0;
};

export const getNeo4jDateTimeType = (value: unknown) => {
  if (neo4j.temporal.isLocalDateTime(value)) {
    return LOCAL_DATETIME;
  }
  if (neo4j.temporal.isDateTime(value)) {
    return DATETIME;
  }
  if (neo4j.temporal.isLocalTime(value)) {
    return LOCAL_TIME;
  }
  if (neo4j.temporal.isTime(value)) {
    return TIME;
  }
  if (neo4j.temporal.isDate(value)) {
    return DATE;
  }
  return null;
};

export const setQueryDateByType = (value: string, type: TEMPORAL_TYPES_STRING_REPRESENTATION) => {
  switch (type) {
    case DATE:
      return `date('${value}')`;
    case DATETIME:
      return `datetime('${value}')`;
    case LOCAL_DATETIME:
      return `localdatetime('${value}')`;
    case TIME:
      return `time('${value}')`;
    case LOCAL_TIME:
      return `localtime('${value}')`;
  }
};
