import type { EntityState } from '@reduxjs/toolkit';
import { createEntityAdapter } from '@reduxjs/toolkit';

import { WATCH_PROGRESS, registerSubscription } from '../relate.subscriptions';
import type { OperationName, ProgressEvent } from '../relate.types';
import type { Builder } from './types';

export type ProgressEventsEntity = { id: string; events: Record<string, ProgressEvent> };

const progressEventsAdapter = createEntityAdapter<ProgressEventsEntity>();

export const eventEndpoints = (builder: Builder) => ({
  watchProgress: builder.query<
    EntityState<ProgressEventsEntity, string>,
    { operation: OperationName; operationId: string[] }
  >({
    query: (args) => ({ url: 'progress-events', params: args }),

    transformResponse(response: { operation: string; operationId: string; events: ProgressEvent[] }) {
      const entity = {
        id: response.operation + response.operationId,
        events: response.events.reduce<Record<string, ProgressEvent>>((obj, e) => ({ ...obj, [e.message]: e }), {}),
      };

      return progressEventsAdapter.upsertOne(progressEventsAdapter.getInitialState(), entity);
    },

    onCacheEntryAdded(_arg, api) {
      return registerSubscription<{ progress: ProgressEvent }>(api, WATCH_PROGRESS, ({ data }) => {
        const event = data?.progress;
        if (event === undefined) {
          return;
        }

        api.updateCachedData((draft) => {
          const entityId = event.operation + event.operationId;
          const existingEvents = draft.entities[entityId]?.events ?? {};

          progressEventsAdapter.upsertOne(draft, {
            id: entityId,
            events: {
              ...existingEvents,
              [event.message]: event,
            },
          });
        });
      });
    },
  }),
});
