import { isEqual, isNil } from 'lodash-es';

import { log } from '../../services/logging';
import migrate from '../../services/migrate';
import { getClientMigrationVersion } from '../../services/versions/version';
import type { DrawerState } from '../drawer/drawer';
import type { SceneState } from '../scene/scene.types';
import localStorage from '../storage/localStorage';
import migrations from './migrations';

interface Document {
  currentPerspectiveId: string;
  dbmsId: string | undefined;
  dbId: string;
  userId: string;
  version: string;
  drawer: Pick<DrawerState, 'perspectiveHistoryLastSeen'>;
  scene: Pick<SceneState, 'currentSceneId'>;
}

const Prefix = 'neo4j.bloomDocument.';

const pendingChanges: Record<string, Document> = {};

function getStorageKey(dbmsId: string | undefined, dbId: string, userId: string) {
  return isNil(dbmsId) ? `${Prefix}${dbId}.${userId}` : `${Prefix}${dbmsId}.${dbId}.${userId}`;
}

export async function getDocument(dbmsId: string | undefined, dbId: string, userId: string): Promise<Document | null> {
  const key = getStorageKey(dbmsId, dbId, userId);
  return (
    (pendingChanges[key] as Document) ??
    localStorage.getItem(key).then((document: unknown) => {
      if (!isNil(document)) {
        document = migrate(document, migrations, (document as Document).version);
        const version = getClientMigrationVersion();
        if (isNil(version)) {
          log.debug('Failed to get client migration version');
        }
        (document as Document).version = version ?? (document as Document).version;
        return document;
      }
      return null;
    })
  );
}

export async function storeDocument(document: Document, onDone?: () => void) {
  const key = getStorageKey(document.dbmsId, document.dbId, document.userId);
  const version = getClientMigrationVersion();
  if (isNil(version)) {
    log.debug('Failed to get client migration version');
  }
  document.version = version ?? document.version;

  if (isEqual(pendingChanges[key], document)) {
    return;
  }

  pendingChanges[key] = document;
  return localStorage.setItem(key, document).then(() => {
    if (pendingChanges[key] === document) {
      delete pendingChanges[key];
    }
    if (!isNil(onDone)) onDone();
  });
}
