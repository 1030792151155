import { combineReducers } from '@reduxjs/toolkit';

import searchCoreReducer from './core/search-core';
import { NAME as core } from './core/search-core.const';
import searchRequestsReducer from './requests/search-requests';
import { NAME as requests } from './requests/search-requests.const';
import suggestionsReducer from './suggestions/search-suggestions';
import { NAME as suggestions } from './suggestions/search-suggestions.const';

export default combineReducers({
  [core]: searchCoreReducer,
  [suggestions]: suggestionsReducer,
  [requests]: searchRequestsReducer,
});
