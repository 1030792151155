import type { BannerType } from '@neo4j-ndl/react';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, nanoid } from '@reduxjs/toolkit';

export type NxNotification = {
  type: BannerType;
  title: string;
  description: string;
  actionCallbacks?: ('reconnect' | 'reload')[];
  timeout?: number;
};

export type FullNxNotification = NxNotification & { id: string; timestamp: number; hasBeenShown: boolean };

export interface NotificationsState {
  notifications: FullNxNotification[];
}
const initialState: NotificationsState = {
  notifications: [],
};

export const NOTIFICATIONS_PERSISTED_KEYS: (keyof NotificationsState)[] = ['notifications'];

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<NxNotification>) => {
      const notification = {
        ...action.payload,
        id: nanoid(),
        timestamp: Date.now(),
        hasBeenShown: false,
      };

      state.notifications = state.notifications.slice(1, 20);
      state.notifications.push(notification);
    },
    updateNotification: (state, action: PayloadAction<FullNxNotification>) => {
      state.notifications = state.notifications.map((n) => {
        if (n.id === action.payload.id) {
          return { ...action.payload, hasBeenShown: true };
        }
        return n;
      });
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter((n) => n.id !== action.payload);
    },
  },
});

export const { addNotification, updateNotification, removeNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
