import type {
  QUERY_LOG_STATUS,
  QueryLog,
  QueryLogAggregation,
  QueryLogFilterInput,
  QueryLogFilterableFields,
  RawFilterSelection,
  SecurityLogFilterInput,
  SecurityLogFilterableFields,
  SelectedDbmsFilters,
  TimeRangeQueryLog,
  TimeRangeQueryLogAggregation,
} from '@nx/state';
import { FORMATTED_AVAILABLE_STATUSES, STATUS_MAP } from '@nx/state';
import { Objects, isNonEmptyString } from '@nx/stdlib';

export const mapQueryLogs = (data?: QueryLog[]): TimeRangeQueryLog[] => {
  const logs = data;

  if (!logs) {
    return [];
  }

  return logs.map((l) => ({
    ...l,
    endTime: l.endTime ? new Date(l.endTime) : undefined,
  }));
};

export const mapQueryLogAggregations = (data?: QueryLogAggregation[]): TimeRangeQueryLogAggregation[] => {
  const aggregations = data;

  if (!aggregations) {
    return [];
  }

  return aggregations.map((agg) => ({
    ...agg,
    from: agg.from ? new Date(agg.from) : undefined,
    to: agg.to ? new Date(agg.to) : undefined,
  }));
};

export const mapFormattedStatuses = (statuses: typeof FORMATTED_AVAILABLE_STATUSES) =>
  statuses
    .filter((s): s is keyof typeof STATUS_MAP => FORMATTED_AVAILABLE_STATUSES.includes(s))
    .map((s) => STATUS_MAP[s]);

export const mapRawFiltersToInput = (rawFilters: RawFilterSelection): QueryLogFilterInput => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const input = {} as QueryLogFilterInput;

  Objects.keys(rawFilters.dbmsFilters).forEach((filterName) => {
    const selected = [...rawFilters.dbmsFilters[filterName]].sort();

    if (selected.length === 0) {
      return;
    }

    if (filterName === 'statuses') {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      input[filterName] = mapFormattedStatuses(selected) as QUERY_LOG_STATUS[];
    } else {
      input[filterName] = selected;
    }
  });

  // endTime should always be set
  const { endTime, startTime = new Date(endTime) } = rawFilters.timeRange;

  input.from = startTime.getTime();
  input.to = endTime.getTime();
  input.minimumDuration = rawFilters.minDuration;

  if (isNonEmptyString(rawFilters.query)) {
    input.query = rawFilters.query;
  }

  if (isNonEmptyString(rawFilters.querySearchString)) {
    input.querySearchString = rawFilters.querySearchString;
  }

  if (isNonEmptyString(rawFilters.errorSearchString)) {
    input.errorSearchString = rawFilters.errorSearchString;
  }

  return input;
};

const SORTED_TAGGABLE_FILTERS: (keyof SelectedDbmsFilters)[] = [
  'statuses',
  'gqlStatuses',
  'users',
  'databases',
  'drivers',
  'apps',
  'initiationTypes',
  'queryLanguages',
];

export const getActiveFilters = (selectedDbmsFilters: SelectedDbmsFilters) =>
  SORTED_TAGGABLE_FILTERS.flatMap((filterName) => {
    const filterValue = selectedDbmsFilters[filterName];
    return filterValue.map((value): [typeof filterName, typeof value] => [filterName, value]);
  });

export const getActiveSearchFilters = (rawFilters: RawFilterSelection) =>
  [rawFilters.querySearchString, rawFilters.errorSearchString].filter(isNonEmptyString);

export const getActiveNumericalFilters = (rawFilters: RawFilterSelection) =>
  [{ friendly: `> ${rawFilters.minDuration} ms`, value: rawFilters.minDuration }]
    .filter((filter) => filter.value > 0)
    .map((filter) => filter.friendly);

export const FriendlyQueryLogFilterName: Record<
  | QueryLogFilterableFields
  | keyof Pick<QueryLogFilterInput, 'querySearchString' | 'errorSearchString' | 'minimumDuration'>,
  string
> = {
  statuses: 'status',
  gqlStatuses: 'gql status',
  users: 'user',
  drivers: 'driver',
  apps: 'application',
  initiationTypes: 'initiation type',
  databases: 'database',
  minimumDuration: 'minimum duration',
  queryLanguages: 'query language',
  querySearchString: 'query search',
  errorSearchString: 'error search',
};

export const FriendlySecurityLogFilterName: Record<
  SecurityLogFilterableFields | keyof Pick<SecurityLogFilterInput, 'messageSearchString'>,
  string
> = {
  statuses: 'status',
  executingUsers: 'executing user',
  authenticatedUsers: 'authenticated user',
  drivers: 'driver',
  databases: 'database',
  messageSearchString: 'message search',
};
