import { Banner, Button, Dialog, Select, TextInput, Tooltip } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { consoleApi, getApiError, getErrorMessage } from '@nx/state';
import type { CustomEndpoint, Instance } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';
import * as yup from 'yup';

import type { Validation } from '../utils/validation';
import { validateYup } from '../utils/validation';
import { constructCustomEndpointUrl } from './helpers';
import { formatOptionLabel } from './shared';

const logger = createLogger(APP_SCOPE.aura);

type UpdateCustomEndpointData = {
  dbId: string;
};
const schema = yup.object({
  dbId: yup.string().required().label('Instance'),
});

const validate = (data: UpdateCustomEndpointData) => validateYup(schema, data, false);

type Props = {
  onClose: () => void;
  instances: Instance[];
  instance?: Instance;
  endpoint: CustomEndpoint;
};

export const ConfigureCustomEndpointDialog = ({ onClose, instance, instances, endpoint }: Props) => {
  const [data, setData] = useState<UpdateCustomEndpointData>({ dbId: instance?.id ?? '' });
  const [validationError, setValidationError] = useState<Validation<UpdateCustomEndpointData> | null>(null);
  const [updateCustomEndpoint, updateCustomEndpointRes] = consoleApi.useUpdateCustomEndpointMutation();

  const handleSelectInstance = (dbId?: string) => {
    setValidationError(null);
    setData((prev) => ({ ...prev, dbId: dbId ?? '' }));
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    const validation = validate(data);
    if (isNotNullish(validation)) {
      setValidationError(validation);
      return;
    }
    setValidationError(null);

    updateCustomEndpoint({ ...data, id: endpoint.id })
      .unwrap()
      .then(() => {
        onClose();
      })
      .catch((error: FetchBaseQueryError | SerializedError | undefined) => {
        const apiError = getApiError(error);
        const message = getErrorMessage(apiError);
        logger.error(message);
      });
  };

  const options = instances.map((item) => ({
    key: item.id,
    label: item.name,
    value: item.id,
    description: item.id,
    isDisabled: !item.capabilities.assign_custom_endpoint.enabled,
    disabledReason: !item.capabilities.assign_custom_endpoint.enabled
      ? item.capabilities.assign_custom_endpoint.message
      : undefined,
  }));
  const selectedInstance = instances.find((item) => item.id === data.dbId);

  return (
    <Dialog isOpen onClose={onClose}>
      <Dialog.Header>Configure custom endpoint</Dialog.Header>
      <form onSubmit={handleSubmit}>
        <Dialog.Content className="flex flex-col gap-4">
          <div className="flex flex-col gap-4">
            <TextInput label="Custom endpoint" value={constructCustomEndpointUrl(endpoint)} isReadOnly isFluid />
            <Select
              label="Assign to instance"
              errorText={
                validationError?.dbId?.message ??
                (isNotNullish(selectedInstance) &&
                selectedInstance.id !== instance?.id &&
                !selectedInstance.availableActions.link_custom_endpoint.enabled
                  ? selectedInstance.availableActions.link_custom_endpoint.message
                  : undefined)
              }
              type="select"
              size="medium"
              selectProps={{
                value: options.find((item) => item.key === data.dbId),
                options,
                menuPosition: 'fixed',
                onChange: (obj) => handleSelectInstance(obj?.value),
                formatOptionLabel,
              }}
            />
          </div>
          <Banner
            hasIcon
            type="info"
            description="It will take a short moment for this custom endpoint to be ready for use again."
            usage="inline"
          />
          <Banner
            hasIcon
            type="warning"
            description={
              <span>
                You will not be able to configure this custom endpoint for the next 3 hours.
                {isNotNullish(instance)
                  ? ' Although, you will be able to transfer this custom endpoint back to the currently assigned instance.'
                  : ''}
              </span>
            }
            usage="inline"
          />
          {updateCustomEndpointRes.isError && (
            <Banner
              hasIcon
              type="danger"
              description={getErrorMessage(getApiError(updateCustomEndpointRes.error))}
              usage="inline"
            />
          )}
        </Dialog.Content>
        <Dialog.Actions>
          <Button color="neutral" fill="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Tooltip type="simple" isDisabled={instance?.id !== data.dbId} isPortaled={false}>
            <Tooltip.Trigger hasButtonWrapper>
              <Button
                color="primary"
                type="submit"
                isLoading={updateCustomEndpointRes.isLoading}
                isDisabled={
                  (isNotNullish(selectedInstance) && !selectedInstance.availableActions.link_custom_endpoint.enabled) ||
                  instance?.id === data.dbId
                }
              >
                Confirm
              </Button>
            </Tooltip.Trigger>
            <Tooltip.Content className="!fixed !w-[200px]">
              <b>{instance?.name}</b> is already assigned to this endpoint.
            </Tooltip.Content>
          </Tooltip>
        </Dialog.Actions>
      </form>
    </Dialog>
  );
};
