import { createSelector } from '@reduxjs/toolkit';
import { isEqual } from 'lodash-es';

import { TURNED_OFF_SUGGESTION } from '../../../modules/SearchBar/SearchBar.const';
import {
  isActionSuggestion,
  isFullTextSearchSuggestion,
  isSearchPhraseSuggestion,
} from '../../../modules/SearchBar/SearchBar.utils';
import type { TransformedPerspectiveIndex, TransformedPerspectivePropertyKeys } from '../../../types/perspective';
import { getFullTextIndexes, getIndexes } from '../../perspectives/perspectiveMetadata';
import { selectCategoryPropertyKeys } from '../../perspectives/perspectives';
import { filterIndexes } from '../../search/typedGraph/metaTransformer';
import type { RootState } from '../../types';
import { NAME as searchPrototype } from '../search-prototype.const';
import type {
  FullTextIndexArgument,
  TextIndexArgument,
} from '../suggestions/property-value-suggestions/property-value-suggestions.types';
import { NAME } from './search-core.const';
import type { SearchCoreState } from './search-core.types';

const selectSearchState = (state: RootState): SearchCoreState => state[searchPrototype][NAME];

export const selectFilter = (state: RootState): SearchCoreState['filter'] => selectSearchState(state).filter;
export const selectStatus = (state: RootState): SearchCoreState['status']['status'] =>
  selectSearchState(state).status.status;
export const selectMessage = (state: RootState): SearchCoreState['status']['message'] =>
  selectSearchState(state).status.message;
export const selectInputText = (state: RootState): SearchCoreState['inputText'] => selectSearchState(state).inputText;
export const selectSelectedSuggestionPathIdx = (state: RootState): SearchCoreState['selectedSuggestionPathIdx'] =>
  selectSearchState(state).selectedSuggestionPathIdx;
export const selectLockedSuggestions = (state: RootState): SearchCoreState['lockedSuggestions'] =>
  selectSearchState(state).lockedSuggestions;
export const selectLabelsWithNodesUnderLimit = (state: RootState): SearchCoreState['labelsWithNodesUnderLimit'] =>
  selectSearchState(state).labelsWithNodesUnderLimit;
export const selectAutoRun = (state: RootState): SearchCoreState['autoRun'] => selectSearchState(state).autoRun;
export const selectInitialFocus = (state: RootState): SearchCoreState['initialFocus'] =>
  selectSearchState(state).initialFocus;
export const selectIsGenAIAssistantEnabled = (state: RootState): SearchCoreState['isGenAIAssistantEnabled'] =>
  selectSearchState(state).isGenAIAssistantEnabled;
export const selectGenAiLastAskedQuestion = (state: RootState): SearchCoreState['genAiLastAskedQuestion'] =>
  selectSearchState(state).genAiLastAskedQuestion;

export const isSearchPhraseLockedSelector = createSelector(selectLockedSuggestions, (lockedSuggestions) =>
  lockedSuggestions.some(isSearchPhraseSuggestion),
);
export const isActionSuggestionLockedSelector = createSelector(selectLockedSuggestions, (lockedSuggestions) =>
  lockedSuggestions.some(isActionSuggestion),
);
export const isFullTextSearchLockedSelector = createSelector(selectLockedSuggestions, (lockedSuggestions) =>
  lockedSuggestions.some(isFullTextSearchSuggestion),
);
export const isSuggestionsTurnedOffSelector = createSelector(selectLockedSuggestions, (lockedSuggestions) =>
  isEqual(lockedSuggestions.at(-1), TURNED_OFF_SUGGESTION),
);

export const selectFullTextIndexes = createSelector(getFullTextIndexes, (perspectiveFullTextIndexes) => {
  const fullTextIndexes = perspectiveFullTextIndexes
    .filter(({ entityType }) => entityType === 'NODE')
    .reduce((indexes: FullTextIndexArgument[], index) => {
      const { name, propertyNames } = index;
      const flattened = propertyNames.reduce<FullTextIndexArgument[]>((pairs, propertyName) => {
        return [...pairs, [name, propertyName]];
      }, []);
      return [...indexes, ...flattened];
    }, []);
  return fullTextIndexes;
});

export const selectTextIndexes = createSelector(
  getIndexes,
  selectCategoryPropertyKeys,
  (perspectiveIndexes, labelPropertyKeys: TransformedPerspectivePropertyKeys['labels']) => {
    const filteredIndexes: TransformedPerspectiveIndex[] = filterIndexes(labelPropertyKeys, perspectiveIndexes);
    const textIndexes = filteredIndexes.reduce<TextIndexArgument[]>(
      (mapped: TextIndexArgument[], index: { label: string; propertyKeys: string[] }) => {
        const arr = index.propertyKeys.map((propertyKey: string) => {
          const propertyType = labelPropertyKeys?.[index.label]?.find(
            (property) => property?.propertyKey === propertyKey,
          )?.dataType;
          return [index.label, propertyKey, propertyType];
        });
        return [...mapped, ...arr] as TextIndexArgument[];
      },
      [],
    );
    return textIndexes;
  },
);
