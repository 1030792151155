import { tokens } from '@neo4j-ndl/base';

export const blackActive = '#848484';

export const whiteActive = '#FFFFFF';

export const gray = '#6F757E';
export const grayLight = '#CFD1D4';
export const grayLighter = '#E2E3E5';

export const blue = tokens.theme.light.palette.primary.bg.strong;
export const blueActive = tokens.theme.light.palette.primary.bg.status;

export const red = '#FD766E';
export const redActive = '#E36962';

export const labelColorPalette = [
  '#FFE081',
  '#C990C0',
  '#F79767',
  '#57C7E3',
  '#F16667',
  '#D9C8AE',
  '#8DCC93',
  '#ECB5C9',
  '#4C8EDA',
  '#FFC454',
  '#DA7194',
  '#569480',
  '#848484',
  '#D9D9D9',
];
