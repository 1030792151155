import classNames from 'classnames';
import type { PropsWithChildren } from 'react';

/**
 * Centers its child horizontally and vertically using `display: flex`.
 */
export const Center = ({
  className,
  debugLabel,
  children,
}: PropsWithChildren<{ className?: string; debugLabel?: string }>) => {
  return (
    <div className={classNames('relative flex h-full w-full items-center justify-center', className)}>
      {children}
      {import.meta.env.DEV && Boolean(debugLabel) && (
        <div className="text-neutral-30 absolute left-1/2 top-1/2 -translate-x-[50%] -translate-y-[200%]">
          {debugLabel}
        </div>
      )}
    </div>
  );
};
