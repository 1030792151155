import { formatters } from '@neo4j/graph-schema-utils';
import * as ImportShared from '@nx/import-shared';
import { isNullish } from '@nx/stdlib';

import type { Configurations, RelationshipModel, TableSchemaField } from './data-model';
import {
  DataModel,
  DataSourceSchema,
  GraphMappingRepresentation,
  GraphSchemaExtensionsRepresentation,
  NodeKeyProperty,
  NodeMapping,
  NodeModel,
  PropertyMapping,
  RelationshipMapping,
  TableSchema,
  TableSchemaCloudField,
  TableSchemaLocalField,
} from './data-model';
import { toIdFromRef, toRefObject } from './data-model.json.helpers';

const nodeKeyPropertyJson = {
  toJson: (nodeKey: NodeKeyProperty): ImportShared.NodeKeyPropertyJsonStruct => ({
    node: toRefObject(nodeKey.node.$id),
    keyProperty: toRefObject(nodeKey.keyProperty.$id),
  }),
};

const graphSchemaExtensionsRepresentationJson = {
  toJson: (
    extensions: GraphSchemaExtensionsRepresentation,
  ): ImportShared.GraphSchemaExtensionsRepresentationJsonStruct => ({
    nodeKeyProperties: extensions.nodeKeyProperties.map(nodeKeyPropertyJson.toJson),
  }),
};

const nodeMappingJson = {
  toJson: (mapping: NodeMapping): ImportShared.NodeMappingJsonStruct => ({
    node: toRefObject(mapping.node.$id),
    tableName: mapping.tableSchema.name,
    propertyMappings: mapping.propertyMappings.map((propertyMapping) => ({
      property: toRefObject(propertyMapping.property.$id),
      fieldName: propertyMapping.fieldName,
    })),
    mappingFilter: mapping.mappingFilter
      ? {
          fieldName: mapping.mappingFilter.fieldName,
          exactMatch: mapping.mappingFilter.exactMatch,
        }
      : undefined,
  }),
};

const relationshipMappingJson = {
  toJson: (mapping: RelationshipMapping): ImportShared.RelationshipMappingJsonStruct => ({
    relationship: toRefObject(mapping.relationship.$id),
    tableName: mapping.tableSchema.name,
    fromMapping: mapping.fromMapping ? { fieldName: mapping.fromMapping.fieldName } : undefined,
    toMapping: mapping.toMapping ? { fieldName: mapping.toMapping.fieldName } : undefined,
    propertyMappings: mapping.propertyMappings.map((propertyMapping) => ({
      property: toRefObject(propertyMapping.property.$id),
      fieldName: propertyMapping.fieldName,
    })),
    mappingFilter: mapping.mappingFilter
      ? {
          fieldName: mapping.mappingFilter.fieldName,
          exactMatch: mapping.mappingFilter.exactMatch,
        }
      : undefined,
  }),
};

const tableSchemaFieldJson = {
  toJson: (field: TableSchemaField): ImportShared.TableSchemaFieldJsonStruct => {
    if (field instanceof TableSchemaLocalField) {
      return {
        name: field.name,
        recommendedType: field.recommendedType,
        sample: field.sample,
      };
    }
    return {
      name: field.name,
      rawType: field.rawType,
      recommendedType: field.recommendedType,
      supportedTypes: field.supportedTypes,
    };
  },
};

const tableSchemaJson = {
  toJson: (table: TableSchema): ImportShared.TableSchemaJsonStruct => ({
    ...table,
    fields: table.fields.map((field) => tableSchemaFieldJson.toJson(field)),
  }),
};

const dataSourceSchemaJson = {
  toJson: (dataSource: DataSourceSchema): ImportShared.DataSourceSchemaJsonStruct => ({
    type: dataSource.type,
    tableSchemas: dataSource.tableSchemas.map(tableSchemaJson.toJson),
  }),
};

const graphMappingRepresentationJson = {
  toJson: (mapping: GraphMappingRepresentation): ImportShared.GraphMappingRepresentationJsonStruct => ({
    dataSourceSchema: dataSourceSchemaJson.toJson(mapping.dataSourceSchema),
    nodeMappings: mapping.nodeMappings.map(nodeMappingJson.toJson),
    relationshipMappings: mapping.relationshipMappings.map(relationshipMappingJson.toJson),
  }),
};

const configurationsJson = {
  toJson: (config: Configurations): ImportShared.ConfigurationsJsonStruct => ({
    idsToIgnore: config.idsToIgnore,
  }),
};

function toJsonStruct(dataModel: DataModel): ImportShared.DataModelJsonStruct {
  const graphSchema: formatters.json.types.RootSchemaJsonStruct = formatters.json.toJsonStruct(
    dataModel.graphSchemaRepresentation,
  );

  return {
    version: dataModel.version,
    graphSchemaRepresentation: graphSchema.graphSchemaRepresentation,
    graphSchemaExtensionsRepresentation: graphSchemaExtensionsRepresentationJson.toJson(
      dataModel.graphSchemaExtensionsRepresentation,
    ),
    graphMappingRepresentation: graphMappingRepresentationJson.toJson(dataModel.graphMappingRepresentation),
    configurations: configurationsJson.toJson(dataModel.configurations),
  };
}

function fromJsonStruct(json: ImportShared.DataModelJsonStruct): DataModel {
  const graphSchemaRepresentation = formatters.json.fromJsonStruct({
    graphSchemaRepresentation: json.graphSchemaRepresentation,
  });

  const nodeModels = graphSchemaRepresentation.nodeObjectTypes.map((nodeObjectType) => new NodeModel(nodeObjectType));

  const nodeKeyProperties = json.graphSchemaExtensionsRepresentation.nodeKeyProperties.reduce(
    (accumulator: NodeKeyProperty[], nodeKeyPropertyJsonStruct) => {
      const nodeId = toIdFromRef(nodeKeyPropertyJsonStruct.node.$ref);
      const nodeModel = nodeModels.find((node) => node.nodeObjectType.$id === nodeId);
      const nodeObjectType = graphSchemaRepresentation.nodeObjectTypes.find((node) => node.$id === nodeId);
      if (isNullish(nodeModel) || isNullish(nodeObjectType)) {
        throw new Error('Not all node references in node ids are defined');
      }

      const property = nodeObjectType
        .getProperties()
        .find((p) => p.$id === toIdFromRef(nodeKeyPropertyJsonStruct.keyProperty.$ref));

      if (isNullish(property)) {
        throw new Error('Not all property references in node ids are defined');
      }
      const nodeKeyProperty = new NodeKeyProperty(nodeObjectType, property);
      nodeModel.nodeKeyProperty = nodeKeyProperty;
      return [...accumulator, nodeKeyProperty];
    },
    [],
  );
  const graphSchemaExtensionsRepresentation = new GraphSchemaExtensionsRepresentation(nodeKeyProperties);

  const isTableSchemaFieldUsedByMapping =
    (tableName: string, fieldName: string) =>
    (mapping: ImportShared.NodeMappingJsonStruct | ImportShared.RelationshipMappingJsonStruct) => {
      const isFieldUsedByPropertyMapping = (propertyMapping: ImportShared.PropertyMappingJsonStruct) =>
        propertyMapping.fieldName === fieldName;
      const isRelationshipMapping = 'fromMapping' in mapping;
      const isFieldUsedByRelationshipFromOrTo = (m: ImportShared.RelationshipMappingJsonStruct) =>
        m.fromMapping?.fieldName === fieldName || m.toMapping?.fieldName === fieldName;
      return (
        mapping.tableName === tableName &&
        (mapping.propertyMappings.some(isFieldUsedByPropertyMapping) ||
          (isRelationshipMapping && isFieldUsedByRelationshipFromOrTo(mapping)))
      );
    };

  const tableSchemas = json.graphMappingRepresentation.dataSourceSchema.tableSchemas.map((jsonTableSchema) => {
    return new TableSchema(
      jsonTableSchema.name,
      jsonTableSchema.expanded,
      jsonTableSchema.fields.map((jsonField) => {
        const isUsed =
          json.graphMappingRepresentation.nodeMappings.some(
            isTableSchemaFieldUsedByMapping(jsonTableSchema.name, jsonField.name),
          ) ||
          json.graphMappingRepresentation.relationshipMappings.some(
            isTableSchemaFieldUsedByMapping(jsonTableSchema.name, jsonField.name),
          );
        if (ImportShared.isTableSchemaLocalFieldJsonStruct(jsonField)) {
          return new TableSchemaLocalField(jsonField.name, jsonField.recommendedType, jsonField.sample, isUsed);
        }
        return new TableSchemaCloudField(
          jsonField.name,
          jsonField.rawType,
          jsonField.recommendedType,
          jsonField.supportedTypes,
          isUsed,
        );
      }),
      jsonTableSchema.primaryKeys,
      jsonTableSchema.foreignKeys,
    );
  });
  const dataSourceSchema = new DataSourceSchema(json.graphMappingRepresentation.dataSourceSchema.type, tableSchemas);
  const nodeMappings = json.graphMappingRepresentation.nodeMappings.reduce(
    (nodeMappingsAccumulator: NodeMapping[], nodeMappingJsonStruct) => {
      const nodeId = toIdFromRef(nodeMappingJsonStruct.node.$ref);
      const nodeModel = nodeModels.find((node) => node.nodeObjectType.$id === nodeId);
      const nodeObjectType = graphSchemaRepresentation.nodeObjectTypes.find(
        (node) => node.$id === toIdFromRef(nodeMappingJsonStruct.node.$ref),
      );
      if (isNullish(nodeModel) || isNullish(nodeObjectType)) {
        throw new Error('Not all node references in node mappings are defined');
      }
      const tableSchema = tableSchemas.find((table) => table.name === nodeMappingJsonStruct.tableName);
      if (isNullish(tableSchema)) {
        throw new Error('Not all table schema references in node mappings are defined');
      }
      const propertyMappings = nodeMappingJsonStruct.propertyMappings.reduce(
        (propertyMappingsAccumulator: PropertyMapping[], propertyMappingJsonStruct) => {
          const property = nodeObjectType
            .getProperties()
            .find((p) => p.$id === toIdFromRef(propertyMappingJsonStruct.property.$ref));
          if (isNullish(property)) {
            throw new Error('Not all property references in node mappings are defined');
          }
          if (isNullish(tableSchema.fields.find((field) => field.name === propertyMappingJsonStruct.fieldName))) {
            throw new Error('Not all field name references in node mappings are defined');
          }
          return [...propertyMappingsAccumulator, new PropertyMapping(property, propertyMappingJsonStruct.fieldName)];
        },
        [],
      );
      const nodeMapping = new NodeMapping(
        nodeObjectType,
        tableSchema,
        propertyMappings,
        nodeMappingJsonStruct.mappingFilter,
      );
      nodeModel.nodeMapping = nodeMapping;
      return [...nodeMappingsAccumulator, nodeMapping];
    },
    [],
  );

  const relationshipModels: RelationshipModel[] = graphSchemaRepresentation.relationshipObjectTypes.map(
    (relationshipObjectType) => {
      const fromNodeModel = nodeModels.find((n) => n.nodeObjectType.$id === relationshipObjectType.from.$id);
      const toNodeModel = nodeModels.find((n) => n.nodeObjectType.$id === relationshipObjectType.to.$id);
      if (isNullish(fromNodeModel) || isNullish(toNodeModel)) {
        throw new Error('Not all node references in relationship are defined');
      }
      return {
        relationshipObjectType,
        from: new NodeModel(fromNodeModel.nodeObjectType, fromNodeModel.nodeMapping, fromNodeModel.nodeKeyProperty),
        to: new NodeModel(toNodeModel.nodeObjectType, toNodeModel.nodeMapping, toNodeModel.nodeKeyProperty),
      };
    },
  );
  const relationshipMappings = json.graphMappingRepresentation.relationshipMappings.reduce(
    (relationshipMappingsAccumulator: RelationshipMapping[], relationshipMappingJsonStruct) => {
      const relationshipId = toIdFromRef(relationshipMappingJsonStruct.relationship.$ref);
      const relationshipModel = relationshipModels.find(
        (relationship) => relationship.relationshipObjectType.$id === relationshipId,
      );
      const relationshipObjectType = graphSchemaRepresentation.relationshipObjectTypes.find(
        (relationship) => relationship.$id === relationshipId,
      );
      if (isNullish(relationshipModel) || isNullish(relationshipObjectType)) {
        throw new Error('Not all relationship references in relationship mappings are defined');
      }
      const tableSchema = tableSchemas.find((table) => table.name === relationshipMappingJsonStruct.tableName);
      if (isNullish(tableSchema)) {
        throw new Error('Not all table schema references in relationship mappings are defined');
      }
      const propertyMappings = relationshipMappingJsonStruct.propertyMappings.reduce(
        (propertyMappingsAccumulator: PropertyMapping[], propertyMappingJsonStruct) => {
          const relationshipProperties = relationshipObjectType.getProperties();
          const property = relationshipProperties.find(
            (p) => p.$id === toIdFromRef(propertyMappingJsonStruct.property.$ref),
          );
          if (isNullish(property)) {
            throw new Error('Not all property references in relationship mappings are defined');
          }
          if (isNullish(tableSchema.fields.find((field) => field.name === propertyMappingJsonStruct.fieldName))) {
            throw new Error('Not all field name references in relationship mappings are defined');
          }
          return [...propertyMappingsAccumulator, new PropertyMapping(property, propertyMappingJsonStruct.fieldName)];
        },
        [],
      );
      const relationshipMapping = new RelationshipMapping(
        relationshipObjectType,
        tableSchema,
        relationshipMappingJsonStruct.fromMapping,
        relationshipMappingJsonStruct.toMapping,
        propertyMappings,
        relationshipMappingJsonStruct.mappingFilter,
      );
      relationshipModel.relationshipMapping = relationshipMapping;
      return [...relationshipMappingsAccumulator, relationshipMapping];
    },
    [],
  );
  const graphMappingRepresentation = new GraphMappingRepresentation(
    dataSourceSchema,
    nodeMappings,
    relationshipMappings,
  );

  return new DataModel(
    json.version,
    graphSchemaRepresentation,
    graphSchemaExtensionsRepresentation,
    graphMappingRepresentation,
    nodeModels,
    relationshipModels,
    json.configurations,
  );
}

export const dataModelJsonFormatter = {
  toJsonStruct,
  fromJsonStruct,
  tableSchemaFieldJson,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isDataModelJsonStruct = (dataModel: any): dataModel is ImportShared.DataModelJsonStruct => {
  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  // TODO - better validation...
  return (
    !isNullish(dataModel) &&
    !isNullish(dataModel.version) &&
    !isNullish(dataModel.graphSchemaRepresentation) &&
    !isNullish(dataModel.graphSchemaRepresentation.graphSchema) &&
    !isNullish(dataModel.graphSchemaRepresentation.graphSchema.indexes) &&
    !isNullish(dataModel.graphSchemaRepresentation.graphSchema.constraints) &&
    !isNullish(dataModel.graphSchemaExtensionsRepresentation) &&
    !isNullish(dataModel.graphSchemaExtensionsRepresentation.nodeKeyProperties) &&
    !isNullish(dataModel.graphMappingRepresentation) &&
    !isNullish(dataModel.graphMappingRepresentation.nodeMappings) &&
    !isNullish(dataModel.graphMappingRepresentation.relationshipMappings) &&
    !isNullish(dataModel.graphMappingRepresentation.dataSourceSchema) &&
    !isNullish(dataModel.configurations) &&
    !isNullish(dataModel.configurations.idsToIgnore)
  );
};
