import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export enum METRIC_TYPE {
  Counter = 'COUNTER',
  Gauge = 'GAUGE',
  Histogram = 'HISTOGRAM',
  Summary = 'SUMMARY',
}

export type SeriesSummary = {
  id: string;
  name: string;
  displayName?: string;
  areaAlpha?: number;
};

export type MetricsByInstanceId = Record<string, MetricValue[]>;

export type MetricsByName = Record<string, MetricValue[]>;

export type MetricsData = {
  dataSeries?: MetricsByInstanceId;
  refSeries?: MetricsByInstanceId | MetricValue[];
  transformerSeries?: MetricsByInstanceId;
};

export type MetricsByNameAndInstanceId = Record<string, MetricsData>;

export type MetricsResult = {
  isLoading: boolean;
  error?: SerializedError | FetchBaseQueryError;
  metrics: MetricsByNameAndInstanceId | null;
};

export type MetaMetricValue = {
  max: number;
  min: number;
  numberOfDataPoints: number;
};

export type MetricValue = {
  timestamp: number | undefined;
  value: number | undefined;
  /** value of a reference metric */
  maxValue?: number;
  metaValue?: MetaMetricValue;
};

// LIVE in our case is a window [-30 min, now]
export type TimePeriod = 'LIVE' | '1H' | '2H' | '6H' | '24H' | '3D' | '1W' | '2W' | '1M';

export type TimeRange = {
  startTime: Date;
  endTime: Date;
};
