// From linked polyfill https://developer.mozilla.org/en-US/docs/Web/API/Window/requestIdleCallback
// Disable all eslint rules in this file
/* eslint-disable */
if (!window.requestIdleCallback) {
  // @ts-ignore
  window.requestIdleCallback = function (callback, options = {}) {
    const relaxation = 400;
    const timeout = options.timeout || relaxation;
    const start = performance.now();
    return setTimeout(function () {
      callback({
        get didTimeout() {
          return options.timeout ? false : performance.now() - start - relaxation > timeout;
        },
        timeRemaining: function () {
          return Math.max(0, relaxation + (performance.now() - start));
        },
      });
    }, relaxation);
  };
}

if (!window.cancelIdleCallback) {
  window.cancelIdleCallback = function (id) {
    clearTimeout(id);
  };
}

export default {};
