import { tokens } from '@neo4j-ndl/base';

export const LIGHT_MODE_SHADE = '50';
export const NEUTRAL_SHADE = '40';
export const DARK_MODE_SHADE = '20';

type ColorWithShades = {
  '20': string;
  '40': string;
  '50': string;
};

export type ColorPalette = {
  staticColors: Record<string, string>;
  assignableColors: string[];
};

export enum COLOR_PALETTE_ENUM {
  Standard = 0,
  ExcludingCountColor,
  SingleColor,
}

const standardStaticColors: Record<string, string> = {
  MIN: tokens.colors.forest[NEUTRAL_SHADE],
  AVG: tokens.colors.baltic[NEUTRAL_SHADE],
  MAX: tokens.colors.hibiscus[NEUTRAL_SHADE],
  SUM: tokens.colors.lavender[NEUTRAL_SHADE],
  reference: tokens.colors.neutral[NEUTRAL_SHADE],
  Count: tokens.colors.forest[NEUTRAL_SHADE],
  'Failure Count': tokens.colors.hibiscus[NEUTRAL_SHADE],
  Successful: tokens.colors.forest[NEUTRAL_SHADE],
  Failed: tokens.colors.hibiscus[NEUTRAL_SHADE],
  Latency: tokens.colors.baltic[NEUTRAL_SHADE],
};

const getShadedColors = (
  colorArray: ColorWithShades[],
  shades: (typeof LIGHT_MODE_SHADE | typeof NEUTRAL_SHADE | typeof DARK_MODE_SHADE)[],
) => shades.flatMap((shade) => colorArray.map((colorClass) => colorClass[shade]));

export const getColorPalette = (colorPaletteEnum: COLOR_PALETTE_ENUM): ColorPalette => {
  switch (colorPaletteEnum) {
    case COLOR_PALETTE_ENUM.ExcludingCountColor:
      return {
        staticColors: standardStaticColors,
        assignableColors: getShadedColors(
          [
            tokens.colors.baltic,
            tokens.colors.hibiscus,
            tokens.colors.lemon,
            tokens.colors.lavender,
            tokens.colors.earth,
          ],
          [NEUTRAL_SHADE, LIGHT_MODE_SHADE],
        ),
      };
    case COLOR_PALETTE_ENUM.SingleColor:
      return {
        staticColors: standardStaticColors,
        assignableColors: [tokens.colors.baltic[NEUTRAL_SHADE]],
      };
    case COLOR_PALETTE_ENUM.Standard:
    default:
      return {
        staticColors: standardStaticColors,
        assignableColors: getShadedColors(
          [
            tokens.colors.baltic,
            tokens.colors.forest,
            tokens.colors.hibiscus,
            tokens.colors.lemon,
            tokens.colors.lavender,
            tokens.colors.earth,
          ],
          [NEUTRAL_SHADE, LIGHT_MODE_SHADE],
        ),
      };
  }
};
