import type { ConnectionDescriptor } from '@nx/constants';
import { APP_SCOPE } from '@nx/constants';
import type { Connection, StateEventHandler, StateListenerApi } from '@nx/state';
import {
  CONNECTION_STATUS,
  MODAL_TYPE,
  Persist,
  addStateEventListener,
  discoveryToConnectionDetails,
  fetchDiscoveryData,
  guessDiscoveryApiLocation,
  LEGACY_openModal as openModal,
  parseDiscoveryData,
  selectActiveConnection,
  selectCapability,
  selectConnectionStatus,
  selectMetadata,
} from '@nx/state';
import * as StdLib from '@nx/stdlib';
import { getConnectionParams } from '@nx/ui';
import { executeCommand } from '@query/redux/command-thunks';
import requests, { cancelableRequests, stopRequests } from '@query/redux/requests-slice';
import { baseApi } from '@query/services/api/base-api';
import { trackPreviewPageLoad } from '@query/services/preview-service';
import { parseCommandFromUrlSearchParams } from '@query/utils/search-param-parser';
import type { Reducer } from '@reduxjs/toolkit';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { createDispatchHook, createSelectorHook } from 'react-redux';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { setInitialEditorValue, updateRetainHistory } from './editor-slice';
import { editorHistorySyncMiddleware } from './middleware/editor-history-sync-middleware';
import { persistedMigratedEditor } from './migrations';
import { persistedParams, updateRetainParameters } from './params-slice';
import { QueryStateContext } from './query-state-context';
import savedCypher, { SAVED_CYPHER_PERSISTED_KEYS } from './saved-cypher-slice';
import sidebar, { SIDEBAR_PERSISTED_KEYS } from './sidebar-slice';
import stream, { STREAM_PERSISTED_KEYS, getLatestFrame } from './stream-slice';

const getConnectionSettingsFromConnection = (
  connection: Connection,
): { username: string | null; database: string | null; boltUrl: string; instanceName: string } => {
  const username = connection.credentials.type === 'basic' ? connection.credentials.username : null;
  return {
    username,
    database: null,
    boltUrl: connection.authenticatedUrl,
    instanceName: connection.name,
  };
};

function setupPersist<T>(reducer: Reducer<T>, name: string, persistedKeys: string[] = []) {
  return persistReducer(
    { key: Persist.createKey(APP_SCOPE.query, name), storage, version: 1, throttle: 100, whitelist: persistedKeys },
    reducer,
  );
}

const reducer = combineReducers({
  stream: setupPersist(stream, 'stream', STREAM_PERSISTED_KEYS),
  editor: persistedMigratedEditor,
  params: persistedParams,
  savedCypher: setupPersist(savedCypher, 'savedCypher', SAVED_CYPHER_PERSISTED_KEYS),
  sidebar: setupPersist(sidebar, 'sidebar', SIDEBAR_PERSISTED_KEYS),
  requests,
  [baseApi.reducerPath]: baseApi.reducer,
});

export const store = configureStore({
  reducer,
  devTools: { name: 'Query', trace: true },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(baseApi.middleware, editorHistorySyncMiddleware.middleware),
});

const parsedParamCommand = parseCommandFromUrlSearchParams(window.location.search);

if (parsedParamCommand !== undefined) {
  void store.dispatch(setInitialEditorValue(parsedParamCommand.fullCommand));
}

setupListeners(store.dispatch);

addStateEventListener('metadataUpdate', (listenerApi) => {
  const state = listenerApi.getState();
  const respectDbmsConf = selectCapability(listenerApi.getState(), {
    key: 'framework:respect-dbms-neo4j-conf-settings',
  });

  const { configuration } = selectMetadata(state);

  store.dispatch(
    updateRetainHistory({
      retainEditorHistory: !respectDbmsConf || (configuration?.retainEditorHistory ?? false),
    }),
  );

  const connection = selectActiveConnection(state);

  if (connection && configuration?.postConnectCommands !== undefined) {
    const connectionSettings = getConnectionSettingsFromConnection(connection);
    const { postConnectCommands } = configuration;

    postConnectCommands.forEach((cmd) => {
      void store.dispatch(
        executeCommand({
          cmd: `:${cmd}`,
          source: 'post-connect-cmd',
          connection: connectionSettings,
          lintingEnabled: state.settings.query.enableLinting,
          maxHistory: state.settings.query.maxHistory,
        }),
      );
    });
  }
});

addStateEventListener('onSettingsUpdate', (listenerApi) => {
  const state = listenerApi.getState();

  store.dispatch(
    updateRetainParameters({
      retainParameters: state.settings.query.retainParameters,
    }),
  );
});

addStateEventListener('analyticsAdapterSetup', () => {
  trackPreviewPageLoad();
});

const abortRunningRequests = (): void => {
  const runningRequests = cancelableRequests()(store.getState());
  void store.dispatch(stopRequests(runningRequests, 'abort'));
};

const createConnectFrame: StateEventHandler = (listenerApi) => {
  const state = listenerApi.getState();
  void store.dispatch(
    executeCommand({
      cmd: ':connect',
      connection: {
        username: null,
        database: null,
        boltUrl: 'abc',
      },
      source: 'background-cmd',
      lintingEnabled: state.settings.query.enableLinting,
      maxHistory: state.settings.query.maxHistory,
    }),
  );
};

const handleStartupConnectionFailed = async (listenerApi: StateListenerApi) => {
  const state = listenerApi.getState();

  const connectionStatus = selectConnectionStatus(state);
  const modalOnMissingConn = selectCapability(state, { key: 'query:open-modal-on-missing-connection' });

  if (connectionStatus !== CONNECTION_STATUS.CONNECTED) {
    createConnectFrame(listenerApi);

    // This logic should be moved to the framework, so other apps can use it as well
    const searchParams = new URLSearchParams(window.location.search);
    const { url, dbName, instanceName } = getConnectionParams(searchParams);
    const neo4jUrl = StdLib.URLs.Neo4jURL.asNullable(url);
    if (neo4jUrl !== null) {
      const discovery = await fetchDiscoveryData(neo4jUrl.toDiscoveryUrl());
      const connectionDetails = discoveryToConnectionDetails(neo4jUrl, discovery, instanceName, dbName);
      openModal({
        type: MODAL_TYPE.DATA_SOURCE,
        args: [
          {
            connectionDetails,
            modalTabId: 'remote',
            allowSkippingDataSource: undefined,
            showDataModificationWarning: undefined,
          },
        ],
      });
    } else if (modalOnMissingConn) {
      // we could have connection data (but no password) from an older connection
      const activeConnection = selectActiveConnection(state);

      let connectionDetails:
        | {
            url: string;
            credentials: ConnectionDescriptor;
            dbName?: string | undefined;
            instanceName?: string | undefined;
          }
        | undefined = activeConnection ?? undefined;

      if (activeConnection === null) {
        // look for the bundled discovery API
        const discovery = await fetchDiscoveryData(guessDiscoveryApiLocation(window.location.href));
        const discoveredUrl = parseDiscoveryData(discovery.otherDataDiscovered);

        if (discoveredUrl !== null) {
          connectionDetails = discoveryToConnectionDetails(
            new StdLib.URLs.Neo4jURL(discoveredUrl),
            discovery,
            instanceName,
            dbName,
          );
        }
      }

      openModal({
        type: MODAL_TYPE.DATA_SOURCE,
        args: [
          {
            connectionDetails,
            modalTabId: 'remote',
            allowSkippingDataSource: undefined,
            showDataModificationWarning: undefined,
          },
        ],
      });
    }
  }
};

// There was no automatic db connection to setup
addStateEventListener('setupInitialDbConnectionFulfilled', (listenerApi) => {
  void handleStartupConnectionFailed(listenerApi);
});

// There was was one, but it failed
addStateEventListener('setupInitialDbConnectionRejected', (listenerApi) => {
  void handleStartupConnectionFailed(listenerApi);
});

let switchingState: 'switching' | null = null;

addStateEventListener('connectionSwitching', () => {
  switchingState = 'switching';
});

addStateEventListener('connectionSwitched', () => {
  switchingState = null;
});

addStateEventListener('disconnectDriver', (listenerApi) => {
  abortRunningRequests();

  if (switchingState === null) {
    createConnectFrame(listenerApi);
  }
});

addStateEventListener('connectDriver', (listenerApi) => {
  const state = listenerApi.getState();
  const connection = selectActiveConnection(state);

  const lastFrameIsWelcome = getLatestFrame(store.getState())?.type === 'welcome';

  if (connection !== null && !lastFrameIsWelcome) {
    const connectionSettings = getConnectionSettingsFromConnection(connection);

    void store.dispatch(
      executeCommand({
        cmd: ':welcome',
        source: 'background-cmd',
        connection: connectionSettings,
        lintingEnabled: state.settings.query.enableLinting,
        maxHistory: state.settings.query.maxHistory,
      }),
    );
  }
});

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = createDispatchHook(QueryStateContext).withTypes<AppDispatch>();
export const useAppSelector = createSelectorHook(QueryStateContext).withTypes<RootState>();
