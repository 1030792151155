import type { model } from '@neo4j/graph-schema-utils';

export const SUPPORTED_CSV_TYPE_OPTIONS = ['string', 'integer', 'float', 'boolean', 'datetime'] as const;

export type SupportedCsvPropertyDataType = (typeof SUPPORTED_CSV_TYPE_OPTIONS)[number];

export type DataSourceLocation = null | 'cloud' | 'local';

export enum VALIDATION_ERROR_CODE {
  INCOMPLETE = 'INCOMPLETE',
  DUPLICATED = 'DUPLICATED',
  NO_SUPPORTED_TYPES = 'NO_SUPPORTED_TYPES',
}

export type ErrorModel = {
  code: VALIDATION_ERROR_CODE[keyof VALIDATION_ERROR_CODE];
  message: string;
};

export type ForeignKey = { referencedTable: string; fields: { field: string; referencedField: string }[] };

type ApiFieldSchema = {
  name: string;
  rawType: string;
  recommendedType?: { type: model.PrimitivePropertyTypes };
  supportedTypes?: { type: model.PrimitivePropertyTypes }[];
  size: number;
  sample?: string;
};
export type ApiTableSchema = {
  name: string;
  fields: ApiFieldSchema[];
  foreignKeys?: ForeignKey[];
  primaryKeys?: string[];
};

export type ApiDataSourceSchema = {
  type: string;
  tableSchemas: ApiTableSchema[];
};

export type CandidateGraphRequestBody = {
  schema: ApiDataSourceSchema;
  ai?: boolean;
};
