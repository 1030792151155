import type { BannerCommonProps } from '@neo4j-ndl/react';
import { Banner } from '@neo4j-ndl/react';
import type { DataApiError } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import classNames from 'classnames';

const printableDescription = (errors: DataApiError[]) => {
  return (
    <div>
      {errors.map(({ message, reason }, idx) => {
        const extra = isNotNullish(reason) ? ` [${reason}]` : '';
        return (
          <div key={`error-${idx}`}>
            <div className={classNames(idx > 0 && 'mt-2')}>
              {message}
              {extra}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const DataApiErrorBanner = ({
  errors,
  className,
  hasIcon,
}: {
  errors: DataApiError[];
} & Pick<BannerCommonProps, 'className' | 'hasIcon'>) => {
  const title = `Data API ${errors.length > 1 ? 'Errors' : 'Error'}`;
  return (
    <Banner
      className={classNames(className)}
      description={printableDescription(errors)}
      title={title}
      hasIcon={hasIcon}
      isCloseable={false}
      type="warning"
      usage="inline"
    />
  );
};
