import { addStateEventListener } from '@nx/state';

import bolt from '../../services/bolt/bolt';
import { setDisconnected } from '../connections/connectionsDuck';
import { closeModalOverlay } from '../modal/modal';
import { clearState, unsetCurrentPerspective } from '../rootReducer';
import { resetSearchState } from '../search-prototype/core/search-core';
import { deactivateAllRanges, setIsSlicerOpen } from '../slicer/slicer';
import type { AppDispatch } from '../types';
import { setStateMachineStatus } from './appDuck';

export const initiateDisconnectSync = (dispatch: AppDispatch) => {
  addStateEventListener('disconnectDriver', (_listenerApi) => {
    dispatch(setDisconnected());
    bolt.clearDriver();

    // TODO include those to reducers on setDisconnected
    // TODO clean up the state machine actions whenever possible
    dispatch(setStateMachineStatus('connection.idle'));
    dispatch(clearState());
    dispatch(closeModalOverlay());
    dispatch(resetSearchState());
    dispatch(setIsSlicerOpen(false));
    dispatch(deactivateAllRanges());
    dispatch(unsetCurrentPerspective());
  });
};
