import {
  CheckCircleIconSolid,
  ClockIconSolid,
  ExclamationCircleIconSolid,
  InformationCircleIconSolid,
  XCircleIconSolid,
} from '@neo4j-ndl/react/icons';

export type StatusType = 'info' | 'success' | 'error' | 'canceled' | 'waiting' | 'aborted' | 'warning';
type StatusIconProps = { statusType: StatusType; title?: string; className?: string };

function Icon({ statusType, className = '' }: StatusIconProps) {
  if (statusType === 'info') {
    return (
      <InformationCircleIconSolid
        className={`text-palette-primary-icon h-5 w-5 ${className}`}
        data-testid="info-icon"
      />
    );
  }
  if (statusType === 'success') {
    return (
      <CheckCircleIconSolid className={`text-palette-success-icon h-5 w-5 ${className}`} data-testid="success-icon" />
    );
  }
  if (statusType === 'error') {
    return (
      <ExclamationCircleIconSolid
        className={`text-palette-danger-icon h-5 w-5 ${className}`}
        data-testid="error-icon"
      />
    );
  }
  if (statusType === 'canceled') {
    return (
      <XCircleIconSolid className={`text-palette-neutral-text-weak h-5 w-5 ${className}`} data-testid="canceled-icon" />
    );
  }
  if (statusType === 'aborted') {
    return (
      <XCircleIconSolid
        className={`text-palette-neutral-text-weaker h-5 w-5 ${className}`}
        data-testid="aborted-icon"
      />
    );
  }
  if (statusType === 'warning') {
    return <ExclamationCircleIconSolid className={`text-palette-warning-icon h-5 w-5 ${className}`} />;
  }
  // waiting
  return (
    <ClockIconSolid className={`text-palette-neutral-text-weaker h-5 w-5 ${className}`} data-testid="waiting-icon" />
  );
}

export function StatusIcon({ statusType, title, className }: StatusIconProps) {
  if (title !== undefined) {
    return (
      <span title={title} className={className}>
        <Icon statusType={statusType} />
      </span>
    );
  }

  return <Icon statusType={statusType} className={className} />;
}
