import { scale } from 'chroma-js';
import { isNil } from 'lodash-es';

import { isDateTimeType, parseStringToNumber } from '../../../../services/temporal/utils';

export const defaultRangeColors = ['#D5EEE2', '#81CCA8', '#428C6A'];

export const getRangeColorOptionsFromRule = (rule) => [
  {
    color: rule.minColor || defaultRangeColors[0],
    title: 'Minpoint',
    name: 'minColor',
    key: 'minColorValue',
    value: isNil(rule.minColorValue) ? '' : rule.minColorValue,
  },
  {
    color: rule.midColor || defaultRangeColors[1],
    title: 'Midpoint',
    name: 'midColor',
    key: 'midColorValue',
    value: isNil(rule.midColorValue) ? '' : rule.midColorValue,
  },
  {
    color: rule.maxColor || defaultRangeColors[2],
    title: 'Maxpoint',
    name: 'maxColor',
    key: 'maxColorValue',
    value: isNil(rule.maxColorValue) ? '' : rule.maxColorValue,
  },
];

export const getPreviewGradient = (colors, type) => {
  let val0 = colors[0].value;
  let val1 = colors[1].value;
  let val2 = colors[2].value;
  if (isDateTimeType(type)) {
    val0 = parseStringToNumber(val0, type);
    val1 = parseStringToNumber(val1, type);
    val2 = parseStringToNumber(val2, type);
  }

  const minToMaxRange = parseFloat(val2) - parseFloat(val0) || 0.0001;
  const minToMidRange = parseFloat(val1) - parseFloat(val0);
  const midPerc = (minToMidRange / minToMaxRange) * 100;
  const gradientSteps = [midPerc < 0 ? midPerc : 0, midPerc, 100];
  const gradientArray = colors.map(({ color }, index) => `${color} ${gradientSteps[index]}%`);
  if (val1 === undefined || val1 === '' || isNaN(midPerc) || !isFinite(midPerc)) {
    gradientArray.splice(1, 1);
  }
  return gradientArray.toString();
};

const getColors = (scale) => [0.15, 0.5, 0.7].map(scale).map((x) => x.hex().toUpperCase());

export const getRangeColors = (midColor) => {
  if (isNil(midColor)) {
    return {
      minColor: defaultRangeColors[0],
      midColor: defaultRangeColors[1],
      maxColor: defaultRangeColors[2],
    };
  }
  const colors = getColors(scale(['#ffffff', midColor, '#000000']));
  return {
    minColor: colors[0],
    midColor: colors[1],
    maxColor: colors[2],
  };
};

export const fullPallete = [
  '#FFE081',
  '#C990C0',
  '#F79767',
  '#57C7E3',
  '#F16667',
  '#D9C8AE',
  '#8DCC93',
  '#ECB5C9',
  '#4C8EDA',
  '#FFC454',
  '#DA7194',
  '#FFFFA3',
  '#EDB2E3',
  '#FFB987',
  '#7FEBFF',
  '#FF8987',
  '#FDEBD1',
  '#AFF0B5',
  '#FFD8EC',
  '#73AFFE',
  '#FFE776',
  '#FF93B6',
  '#FFFFC6',
  '#FFD5FF',
  '#FFDDA9',
  '#A5FFFF',
  '#FFACA8',
  '#FFFFF4',
  '#D3FFD8',
  '#FFFCFF',
  '#99D2FF',
  '#FFFF98',
  '#FFB6D8',
];
