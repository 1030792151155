import { Button, LoadingSpinner, Tabs } from '@neo4j-ndl/react';
import { OPS_EVENTS } from '@nx/analytics-service';
import type { Instance, InstanceSummary, TimeRange } from '@nx/state';
import { MODAL_TYPE, TIER, useActiveProject, useModal } from '@nx/state';
import { Center, DataGridHelpers } from '@nx/ui';
import { type Dispatch, type SetStateAction, useState } from 'react';

import { track } from '../../services/segment/analytics';
import { NoInstancesOverlay, UpgradeOverlay } from '../../shared/components';
import { usePagination } from '../shared/hooks/use-pagination';
import { DEFAULT_TAB, TAB_OPTIONS } from '../shared/types';
import { FakeSecurityLogs } from './fake-security-logs';
import { LogsContextProvider } from './hooks/use-logs-context';
import { useRawFilters } from './hooks/use-raw-filters';
import { useSorting } from './hooks/use-sorting';
import { SecurityLogFilters } from './security-log-filters';
import { SecurityLogsDetails } from './security-logs-details';
import { InitiateDownloadButtonWithModal } from './security-logs-download';
import { SecurityLogsSummary } from './security-logs-summary';

const SecurityLogs = ({
  selectedInstance,
  hasRequestedData,
  setHasRequestedData,
}: {
  selectedInstance: Instance | undefined;
  hasRequestedData: boolean;
  setHasRequestedData: Dispatch<SetStateAction<boolean>>;
}) => {
  const [selectedTab, setSelectedTab] = useState(DEFAULT_TAB);

  const [isRequestDialogOpen, setIsRequestDialogOpen] = useState(false);
  const [timeRange, setTimeRange] = useState<TimeRange>({ startTime: new Date(), endTime: new Date() });
  const [timePeriod, setTimePeriod] = useState(0.5);
  const { rawFilters, setRawFilters } = useRawFilters();

  // Define pagination and sorting hooks to not reset them when switching tabs
  const summaryPagination = usePagination();
  const detailsPagination = usePagination();
  const summarySorting = useSorting('Summary');
  const detailsSorting = useSorting('Details');

  const onTabChange = (tab: typeof DEFAULT_TAB) => {
    // Reset detailed query when switching tabs
    setRawFilters((f) => ({ ...f, message: undefined }));
    setSelectedTab(tab);
    track(OPS_EVENTS.LOGS_VIEW, { view: tab });
  };

  const securityLogFilterProps = {
    setIsRequestDialogOpen,
    selectedInstance,
    timeRange,
    timeRangeSelectorProps: {
      onTimeRangeChange: setTimeRange,
      timePeriod,
      setTimePeriod,
    },
  };

  return (
    selectedInstance && (
      <div className="logs m-4">
        <DataGridHelpers.Wrapper>
          <LogsContextProvider
            value={{
              setIsRequestDialogOpen,
              selectedTab,
              setSelectedTab,
              rawFilters,
              setRawFilters,
              hasRequestedData,
              setHasRequestedData,
              timeRange: {
                startTime: timeRange.startTime,
                endTime: timeRange.endTime,
              },
            }}
          >
            <div className="mx-4 mt-4 flex justify-between">
              <div className="flex items-center">
                <Tabs value={selectedTab} onChange={onTabChange} className="logs-tab-header w-fit" fill="filled">
                  {TAB_OPTIONS.map((t) => (
                    <Tabs.Tab tabId={t.key} key={t.key}>
                      {t.text}
                    </Tabs.Tab>
                  ))}
                </Tabs>
              </div>
              <div className="flex gap-4">
                {selectedInstance.tier !== TIER.FREE && <InitiateDownloadButtonWithModal />}
                <Button onClick={() => setIsRequestDialogOpen(true)}>Fetch logs</Button>
              </div>
            </div>
            <Tabs.TabPanel value={selectedTab} tabId="SUMMARY">
              <SecurityLogsSummary
                selectedDbmsId={selectedInstance.id}
                pagination={summaryPagination}
                sorting={summarySorting}
              />
            </Tabs.TabPanel>
            <Tabs.TabPanel value={selectedTab} tabId="DETAILS">
              <SecurityLogsDetails
                selectedDbmsId={selectedInstance.id}
                pagination={detailsPagination}
                sorting={detailsSorting}
              />
            </Tabs.TabPanel>
            {isRequestDialogOpen && <SecurityLogFilters {...securityLogFilterProps} />}
          </LogsContextProvider>
        </DataGridHelpers.Wrapper>
      </div>
    )
  );
};

export const UpgradeQueryLogOverlay = ({ instance }: { instance: Instance | undefined }) => {
  const activeProject = useActiveProject();
  const upgradeModal = useModal(MODAL_TYPE.UPGRADE);
  return (
    <UpgradeOverlay
      title="Upgrade to Professional for logs"
      body="Upgrading to professional enables access to a broad range of different instance and database logs."
      onUpgrade={() => instance && upgradeModal.open({ project: activeProject, instance })}
    >
      <FakeSecurityLogs />
    </UpgradeOverlay>
  );
};

export const SecurityLogsView = ({
  isInstancesLoading,
  instanceSummaries,
  selectedInstanceSummary,
  selectedInstance,
  hasRequestedData,
  setHasRequestedData,
}: {
  isInstancesLoading: boolean;
  instanceSummaries: InstanceSummary[];
  selectedInstanceSummary: InstanceSummary | undefined;
  selectedInstance: Instance | undefined;
  hasRequestedData: boolean;
  setHasRequestedData: Dispatch<SetStateAction<boolean>>;
}) => {
  if (isInstancesLoading) {
    return (
      <Center>
        <LoadingSpinner size="large" />
      </Center>
    );
  }

  if (instanceSummaries.length === 0 || !selectedInstanceSummary) {
    return (
      <NoInstancesOverlay
        title="No instances found"
        body="Create an instance to enable access to a broad range of different instance and database logs."
      >
        <FakeSecurityLogs />
      </NoInstancesOverlay>
    );
  }

  return (
    <div>
      {!selectedInstance && (
        <Center className="mt-[10%]">
          <LoadingSpinner size="large" />
        </Center>
      )}
      {selectedInstance && selectedInstance.tier === TIER.FREE && (
        <UpgradeQueryLogOverlay instance={selectedInstance} />
      )}
      {selectedInstance && selectedInstance.tier !== TIER.FREE && (
        <>
          <SecurityLogs
            selectedInstance={selectedInstance}
            hasRequestedData={hasRequestedData}
            setHasRequestedData={setHasRequestedData}
          />
        </>
      )}
    </div>
  );
};
