import { Banner, Button, Checkbox, Dialog } from '@neo4j-ndl/react';
import { AURA_CONSOLE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import type { Instance, Project } from '@nx/state';
import { TIER, consoleApi, getApiError, getErrorMessage, useActiveProject } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useMemo, useState } from 'react';

import { useTrackUpxEvent } from '../../services/segment/analytics';
import { CDCPauseDBWarning, instanceHasCDCEnabled } from '../cdc';
import { formatSizeString, getPauseCostHourString, getPauseCostMonthString } from '../entities/helpers';

const logger = createLogger(APP_SCOPE.framework);

interface ContentProps {
  instance: Instance;
  project: Project;
}

const ModalTextContent = ({ instance, project }: ContentProps) => {
  const config = project.tierConfigs[instance.tier];
  const size = config?.sizes.find((s) => s.memory === instance.desiredSettings.memory);
  const memory = formatSizeString(instance.desiredSettings.memory);
  const pauseCostString = isNotNullish(size) ? getPauseCostHourString(size.costPerHour) : null;
  const pauseCostMonthString = isNotNullish(size) ? getPauseCostMonthString(size.costPerHour) : null;

  if ([TIER.MTE, TIER.PROFESSIONAL].includes(instance.tier)) {
    return (
      <>
        <div>
          You will be charged {pauseCostString} per hour while an instance of this size is paused, and leaving this
          database paused for 1 month will cost {pauseCostMonthString} based on a 730 hour average month.
        </div>
        <div className="mt-3">All charges are rounded to the nearest cent.</div>
        <div className="mt-3">
          Your instance will be automatically resumed in 30 days, at which point you will be charged the full running
          cost.
        </div>
      </>
    );
  }

  if (instance.tier === TIER.GDS) {
    return (
      <>
        <div>
          You will be charged {pauseCostString} per hour while your {memory} instance is paused.
        </div>
        <div className="mt-3">
          Your instance will be automatically resumed in 30 days, at which point you will be charged the full running
          cost.
        </div>
      </>
    );
  }

  if ([TIER.ENTERPRISE, TIER.AURA_DSE].includes(instance.tier)) {
    return (
      <>
        <ol>
          <li>Paused instances will consume credits at 20% of the normal running rate.</li>
          <li>Backups will be retained in line with usual retention periods.</li>
          <li>
            Unless you resume or destroy your instance, it will remain paused for the next 30 days, at which point Aura
            will automatically resume your instance for you.
          </li>
        </ol>
        <p>Please refer to your contract for more information.</p>
      </>
    );
  }

  return null;
};

interface Props {
  onClose: () => void;
  onConfirm: () => void;
  instance: Instance;
}

export const PauseInstanceModal = ({ onClose, onConfirm, instance }: Props) => {
  const trackEvent = useTrackUpxEvent();
  const activeProject = useActiveProject();
  const [userConfirmed, setUserConfirmed] = useState(false);
  const [pause, pauseRes] = consoleApi.usePauseInstanceMutation();

  const errorMessage = useMemo(() => {
    if (!pauseRes.isError) {
      return null;
    }
    const error = getApiError(pauseRes.error);
    const message = getErrorMessage(error);
    return message;
  }, [pauseRes.error, pauseRes.isError]);

  const handlePause = () => {
    pause(instance.id)
      .unwrap()
      .then(() => {
        onConfirm();
      })
      .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(e);
        if (isNotNullish(error.message)) {
          logger.error(error.message);
        }
      });

    trackEvent({
      event: AURA_CONSOLE_EVENTS.INSTANCE_PAUSE,
      properties: { tier: instance.tier },
      scope: APP_SCOPE.aura,
    });
  };

  const handleUserConfirm = () => {
    setUserConfirmed(!userConfirmed);
  };

  const handleClose = () => {
    onClose();
    setUserConfirmed(false);
  };

  return (
    <Dialog isOpen size="medium" onClose={handleClose}>
      <Dialog.Header
        htmlAttributes={{
          'data-testid': 'pause-db-modal-header',
        }}
      >
        Are you sure you want to pause {instance.name}?
      </Dialog.Header>
      <Dialog.Content
        htmlAttributes={{
          'data-testid': 'pause-db-modal-content',
        }}
      >
        <div className="flex flex-col gap-4">
          <Banner
            description="You can pause your database during periods where you don't need it, and resume
                      at anytime."
            usage="inline"
          />
          {instanceHasCDCEnabled(instance) && <CDCPauseDBWarning />}
          {instance.tier !== TIER.FREE && <ModalTextContent instance={instance} project={activeProject} />}
          {isNotNullish(errorMessage) && (
            <Banner
              type="danger"
              description={errorMessage}
              usage="inline"
              htmlAttributes={{
                'data-testid': 'modal-error-message',
              }}
            />
          )}
        </div>
      </Dialog.Content>
      <Dialog.Actions className="flex items-center justify-between">
        <Checkbox onChange={handleUserConfirm} isChecked={userConfirmed} label="I accept" />
        <div className="flex gap-4">
          <Button onClick={handleClose} isDisabled={pauseRes.isLoading} color="neutral" fill="outlined">
            Cancel
          </Button>
          <Button isDisabled={!userConfirmed} onClick={handlePause} isLoading={pauseRes.isLoading}>
            {pauseRes.isLoading ? 'Loading' : 'Pause'}
          </Button>
        </div>
      </Dialog.Actions>
    </Dialog>
  );
};
