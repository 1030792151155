import type { model } from '@neo4j/graph-schema-utils';
import type * as ImportShared from '@nx/import-shared';
import { isNullish } from '@nx/stdlib';
import { uniq } from 'lodash-es';

import { showSuccessToast } from '../components/import-models/toasts';
import { IMPORT_MODEL_STORAGE_MESSAGES } from '../constants';
import type { TableSchemaErrors } from '../data-model/data-model-errors';

/**
 * Get first available prefix+counter string that isn't in the list of strings
 */
export const getUniqCounterWithPrefixString = (currentStrings: string[], prefix = '', initialValue = 1) => {
  let counter = initialValue;
  const map = currentStrings.reduce((m: Record<string, boolean>, currentString) => {
    if (!isNullish(currentString)) {
      m[currentString] = true;
    }
    return m;
  }, {});
  while (!isNullish(map[`${prefix}${counter}`])) {
    counter += 1;
  }
  return `${prefix}${counter}`;
};

/**
 * Get all relationships to delete. Not only the selected ones, also the ones that are connected to the selected nodes.
 */
export const getRelationshipIdsToDelete = (
  allRelationshipIds: { id: string; fromNodeObjectId: string; toNodeObjectId: string }[],
  selectedNodeIds: string[],
  selectedRelationshipIds: string[],
) => {
  const relationshipsOfSelectedNodes = allRelationshipIds.filter(
    (relationship) =>
      selectedNodeIds.includes(relationship.fromNodeObjectId) || selectedNodeIds.includes(relationship.toNodeObjectId),
  );
  const allRelationshipsToDelete = uniq([...selectedRelationshipIds, ...relationshipsOfSelectedNodes.map((r) => r.id)]);
  return allRelationshipsToDelete;
};

/**
 * Check only if there are errors in the used parts of the table schemas
 */
export const hasErrorsInUsedTableSchemas = (tableErrors: TableSchemaErrors[]) => {
  const sumErrorsOfUsedFields = tableErrors.reduce((acc, curr) => acc + (curr.usedFieldErrors ?? []).length, 0);
  return sumErrorsOfUsedFields > 0;
};

/** Map to import model TableSchema from Api version  */
export const mapToTableSchemaFromApiToModel = (
  apiTableSchema: ImportShared.ApiTableSchema,
): ImportShared.TableSchemaJsonStruct => {
  const { name: tableName, fields: columns, primaryKeys, foreignKeys } = apiTableSchema;

  const fields: ImportShared.TableSchemaCloudFieldJsonStruct[] = columns.map((col) => {
    return {
      name: col.name,
      rawType: col.rawType,
      recommendedType: col.recommendedType,
      supportedTypes: col.supportedTypes,
    };
  });
  return { name: tableName, expanded: true, fields: fields, primaryKeys, foreignKeys };
};

/**
 * Generate visualisation state from nodes and relationships, put them at position 0,0
 */
export const generateVisualisationFromNodesAndRelationships = (
  nodes: model.NodeObjectType[],
  relationships: model.RelationshipObjectType[],
): ImportShared.VisualisationState => {
  return {
    nodes: nodes.map((nodeObjectType) => ({
      id: nodeObjectType.$id,
      position: { x: 0, y: 0 },
      selected: false,
    })),
    relationships: relationships.map((relationshipObjectType) => ({
      id: relationshipObjectType.$id,
      selected: false,
    })),
  };
};

export const showGeneratedGraphModelToast = (containsRelationships: boolean) => {
  const message = containsRelationships
    ? IMPORT_MODEL_STORAGE_MESSAGES.onNewImportModelCreatedSuccessWithFullCandidateGraph
    : IMPORT_MODEL_STORAGE_MESSAGES.onNewImportModelCreatedSuccessWithCandidateGraphNoRelationships;
  showSuccessToast(message);
};
