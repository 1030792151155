import type { AddTrialCustomerInfoBody } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';

import type { ProfileInformationFormData } from './form-data';
import subdivisions from './subdivisions';

export enum USE_CASE {
  GenerativeAI = 'generative_ai',
  FraudDetection = 'fraud_detection',
  Customer360 = 'customer_360',
  SupplyChainAnalytics = 'supply_chain_analytics',
  DigitalTwin = 'digital_twin',
  Recommendations = 'recommendations',
  KnowledgeGraph = 'knowledge_graph',
  Other = 'other',
  None = 'none',
}

export enum JOB_ROLE {
  SoftwareEngineer = 'software_engineer',
  SolutionArchitect = 'solution_architect',
  DataScientist = 'data_scientist',
  OtherTechnical = 'other_technical',
  OtherBusiness = 'other_business',
  Student = 'student',
}

const useCaseLabels: { [key in USE_CASE]: string } = {
  [USE_CASE.GenerativeAI]: 'Generative AI',
  [USE_CASE.FraudDetection]: 'Fraud Detection',
  [USE_CASE.Customer360]: 'Customer 360',
  [USE_CASE.SupplyChainAnalytics]: 'Supply Chain Analytics',
  [USE_CASE.DigitalTwin]: 'Digital Twin',
  [USE_CASE.Recommendations]: 'Recommendations',
  [USE_CASE.KnowledgeGraph]: 'Knowledge Graph',
  [USE_CASE.Other]: 'Other',
  [USE_CASE.None]: "I don't have a use case",
};

export const useCaseOptions: { key: string; value: string; label: string }[] = [
  USE_CASE.GenerativeAI,
  USE_CASE.FraudDetection,
  USE_CASE.Customer360,
  USE_CASE.SupplyChainAnalytics,
  USE_CASE.DigitalTwin,
  USE_CASE.Recommendations,
  USE_CASE.KnowledgeGraph,
  USE_CASE.Other,
  USE_CASE.None,
].map((useCase) => ({
  key: useCase,
  value: useCase,
  label: useCaseLabels[useCase],
}));

const jobRoleLabels: { [key in JOB_ROLE]: string } = {
  [JOB_ROLE.SoftwareEngineer]: 'Software Engineer / Developer',
  [JOB_ROLE.SolutionArchitect]: 'Solution Architect / Engineer Team Lead',
  [JOB_ROLE.DataScientist]: 'Data Scientist / Data Engineer / ML Engineer / Analyst',
  [JOB_ROLE.OtherTechnical]: 'Other - Technical Role',
  [JOB_ROLE.OtherBusiness]: 'Other - Business Role',
  [JOB_ROLE.Student]: 'Student',
};

export const jobRoleOptions: { key: string; value: string; label: string }[] = [
  JOB_ROLE.SoftwareEngineer,
  JOB_ROLE.SolutionArchitect,
  JOB_ROLE.DataScientist,
  JOB_ROLE.OtherTechnical,
  JOB_ROLE.OtherBusiness,
  JOB_ROLE.Student,
].map((jobRole) => ({
  key: jobRole,
  value: jobRole,
  label: jobRoleLabels[jobRole],
}));

export const isUnspecifiedJobRole = (jobRole?: string) => {
  return (
    isNotNullish(jobRole) && [JOB_ROLE.OtherBusiness.toString(), JOB_ROLE.OtherTechnical.toString()].includes(jobRole)
  );
};

export const requiresSubDivision = (country?: string) => {
  return isNotNullish(country) && ['US', 'CA'].includes(country);
};

export const getSubdivisionOptions = (country: 'US' | 'CA'): { key: string; value: string; label: string }[] =>
  Object.entries(subdivisions[country]).map(([code, name]) => ({
    key: code,
    value: code,
    label: name,
  }));

export const transformProfileInformationFormData = (data: ProfileInformationFormData): AddTrialCustomerInfoBody => {
  return {
    ...data,
    jobRole: data.jobRole!,
    primaryBusinessLocationCountry: data.country!,
    primaryBusinessLocationSubdivision: data.subDivision,
  };
};
