import * as Analytics from './middlewares/analytics-events';
import * as AppContext from './slices/app-context-slice';
import * as Capabilities from './slices/capabilities-slice';
import * as Connections from './slices/connections/connections-slice';
import * as CypherParams from './slices/cypher-params-slice';
import * as Desktop from './slices/desktop/desktop';
import * as FeatureFlags from './slices/feature-flags-slice';
import * as Modals from './slices/modals-slice';
import * as OpsContext from './slices/ops-context-slice';
import * as Upload from './slices/upload-slice';
import { store } from './store';

/**
 * @deprecated Pre-bound action creators will be removed, prefer using
 * `dispatch` with original action creator or React hook wrappers if available
 */
export function purgeFeatureFlags(...args: Parameters<typeof FeatureFlags.purge>) {
  return store.dispatch(FeatureFlags.purge(...args));
}

/**
 * @deprecated Pre-bound action creators will be removed, prefer using
 * `dispatch` with original action creator or React hook wrappers if available
 */
export function toggleFeatureFlag(...args: Parameters<typeof FeatureFlags.toggle>) {
  return store.dispatch(FeatureFlags.toggle(...args));
}

/**
 * @deprecated Pre-bound action creators will be removed, prefer using
 * `dispatch` with original action creator or React hook wrappers if available
 */
export function evaluateParameters(...args: Parameters<typeof CypherParams.evaluateParameters>) {
  return store.dispatch(CypherParams.evaluateParameters(...args));
}

/**
 * @deprecated Pre-bound action creators will be removed, prefer using
 * `dispatch` with original action creator or React hook wrappers if available
 */
export function setRelateTokens(...args: Parameters<typeof Desktop.setRelateTokens>) {
  return store.dispatch(Desktop.setRelateTokens(...args));
}

/**
 * @deprecated Pre-bound action creators will be removed, prefer using
 * `dispatch` with original action creator or React hook wrappers if available
 */
export function setDesktopContext(...args: Parameters<typeof Desktop.setDesktopContext>) {
  return store.dispatch(Desktop.setDesktopContext(...args));
}

/**
 * @deprecated Pre-bound action creators will be removed, prefer using
 * `dispatch` with original action creator or React hook wrappers if available
 */
export function setActiveOrg(...args: Parameters<typeof AppContext.setActiveOrg>) {
  return store.dispatch(AppContext.setActiveOrg(...args));
}

/**
 * @deprecated Pre-bound action creators will be removed, prefer using
 * `dispatch` with original action creator or React hook wrappers if available
 */
export function setUserHomePath(...args: Parameters<typeof AppContext.setUserHomePath>) {
  return store.dispatch(AppContext.setUserHomePath(...args));
}

/**
 * @deprecated Pre-bound action creators will be removed, prefer using
 * `dispatch` with original action creator or React hook wrappers if available
 */
export function setActiveProject(...args: Parameters<typeof AppContext.setActiveProject>) {
  return store.dispatch(AppContext.setActiveProject(...args));
}

/**
 * @deprecated Pre-bound action creators will be removed, prefer using
 * `dispatch` with original action creator or React hook wrappers if available
 */
export function toggleCapability(...args: Parameters<typeof Capabilities.toggle>) {
  return store.dispatch(Capabilities.toggle(...args));
}

/**
 * @deprecated Pre-bound action creators will be removed, prefer using
 * `dispatch` with original action creator or React hook wrappers if available
 */
export function trackEvent(...args: Parameters<typeof Analytics.trackEvent>) {
  store.dispatch(Analytics.trackEvent(...args));
}

/**
 * @deprecated Pre-bound action creators will be removed, prefer using
 * `dispatch` with original action creator or React hook wrappers if available
 */
export function trackPage() {
  store.dispatch(Analytics.trackPage());
}

/**
 * @deprecated Pre-bound action creators will be removed, prefer using
 * `dispatch` with original action creator or React hook wrappers if available
 */
export function setSelectedInstance(...args: Parameters<typeof OpsContext.setSelectedInstance>) {
  store.dispatch(OpsContext.setSelectedInstance(...args));
}

/**
 * @deprecated Pre-bound action creators will be removed, prefer using
 * `dispatch` with original action creator or React hook wrappers if available
 */
export function openModal(...args: Parameters<typeof Modals.openModal>) {
  store.dispatch(Modals.openModal(...args));
}

/**
 * @deprecated Pre-bound action creators will be removed, prefer using
 * `dispatch` with original action creator or React hook wrappers if available
 */
export function disconnectDriver() {
  return store.dispatch(Connections.disconnectDriver());
}

/**
 * @deprecated Pre-bound action creators will be removed, prefer using
 * `dispatch` with original action creator or React hook wrappers if available
 */
export function resetUploadBackupState(...args: Parameters<typeof Upload.resetUploadState>) {
  store.dispatch(Upload.resetUploadState(...args));
}
