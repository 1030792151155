import { Navigate, Route, Routes } from 'react-router-dom';

import { OrgViewConfig, ProjectViewConfig } from './helpers';
import { OrgUsersLayout, ProjectUsersLayout } from './layouts';
import {
  OrgUsers,
  OrgUsersByProject,
  OrgUsersByRole,
  ProjectUsers,
  ProjectUsersByDbRole,
  ProjectUsersByInstance,
} from './tables';

export function OrgUsersRoutes() {
  return (
    <Routes>
      <Route element={<OrgUsersLayout />}>
        <Route path={OrgViewConfig.get('ORG')?.route} element={<OrgUsers />} />
        <Route path={OrgViewConfig.get('PROJECT')?.route} element={<OrgUsersByProject />} />
        <Route path={OrgViewConfig.get('ROLES')?.route} element={<OrgUsersByRole />} />
        <Route path={'*'} element={<Navigate to={OrgViewConfig.get('ORG')!.route} />} />
      </Route>
    </Routes>
  );
}

export function ProjectUsersRoutes() {
  return (
    <Routes>
      <Route element={<ProjectUsersLayout />}>
        <Route path={ProjectViewConfig.get('PROJECT')?.route} element={<ProjectUsers />} />
        <Route path={ProjectViewConfig.get('INSTANCE')?.route} element={<ProjectUsersByInstance />} />
        <Route path={ProjectViewConfig.get('DB_ROLES')?.route} element={<ProjectUsersByDbRole />} />
        <Route path={'*'} element={<Navigate to={ProjectViewConfig.get('PROJECT')!.route} />} />
      </Route>
    </Routes>
  );
}
