export const APPLIED = 'applied';
export const APPLY_TYPE = 'applyType';
export const TYPE = 'type';
export const CATEGORY = 'category';
export const RELATIONSHIP = 'relationship';
export const ENTRY_ID = 'entryId';
export const BASED_ON = 'basedOn';
export const BOOL_VALUES = 'boolValues';
export const CONDITION = 'condition';
export const CONDITION_VALUE = 'conditionValue';
export const CONDITION_VALUE_RANGE = 'conditionValueRange';
export const STRING_VALUES = 'stringValues';
export const IS_TIME_ZONE_CONVERT_ENABLED = 'isTimeZoneConvertEnabled';
export const SELECTED_TIME_ZONE = 'selectedTimeZone';

const DROPDOWN_WRAPPER_KEY = 'dropdown-wrapper-key';
const ENTER_KEY_CODE = 13;

const boolValuesInitialState = [];
const conditionInitialState = null;
const conditionValuesInitialState = null;
export const stringValuesInitialState = [];

export const defaultFilterRule = {
  [APPLIED]: false,
  [APPLY_TYPE]: false,
};

export const defaultPropertyBulkUpdate = {
  [BOOL_VALUES]: boolValuesInitialState,
  [CONDITION]: conditionInitialState,
  [CONDITION_VALUE]: conditionValuesInitialState,
  [CONDITION_VALUE_RANGE]: conditionValuesInitialState,
  [STRING_VALUES]: stringValuesInitialState,
  [IS_TIME_ZONE_CONVERT_ENABLED]: false,
  [SELECTED_TIME_ZONE]: 'Z',
};

const appliedDataTypes = [
  'string',
  'boolean',
  'bool',
  'number',
  'bigint',
  'date',
  'localdatetime',
  'localtime',
  'time',
  'datetime',
];

const _getCategoryById = (categories = [], categoryId) => categories.find(({ id }) => id === categoryId);

const _getRelationshipTypeById = (relationshipTypes = [], relationshipId) =>
  relationshipTypes.find(({ id }) => id === relationshipId);

export const isClickOutsideOnNoSelectedValue = ({ target, keyCode }) => {
  if (keyCode === ENTER_KEY_CODE) return false;
  return target && target.classList && target.classList.contains(DROPDOWN_WRAPPER_KEY);
};

export const filterAppliedDataTypes = (property) => appliedDataTypes.includes(property.dataType.toLowerCase());

export const getBasedOnValueFromProperty = (property) => {
  return property ? `${property.name || property.propertyKey}_${property.dataType}` : '';
};

export const getNameFromProperty = (property) => property.name || property.propertyKey;

export const getIdFromEntry = (entry) => entry.id || entry.name;

export const assembleUniqueId = (property, filterRuleId, value) =>
  `${filterRuleId}_${getBasedOnValueFromProperty(property)}__${value}`;

export const getStoredFullEntry = ({ filterRuleId, getRuleByFilterRuleId, categories, relationshipTypes }) => {
  const ruleContent = getRuleByFilterRuleId(filterRuleId);
  const type = ruleContent[TYPE];
  const [baseEntries, getFullEntry] =
    type === CATEGORY ? [categories, _getCategoryById] : [relationshipTypes, _getRelationshipTypeById];
  const fullEntry = getFullEntry(baseEntries, ruleContent[ENTRY_ID]);
  if (!fullEntry || !type) return null;
  return {
    ...getFullEntry(baseEntries, ruleContent[ENTRY_ID]),
    type,
  };
};

export const getStoredProperty = (props) => {
  const entry = getStoredFullEntry({ ...props });
  if (!entry || !entry.properties) return null;
  const basedOnValue = getStoredBasedOnValue({ ...props });
  return entry.properties.find((prop) => basedOnValue === getBasedOnValueFromProperty(prop));
};

export const getStoredAppliedValue = ({ filterRuleId, getRuleByFilterRuleId }) => {
  const ruleContent = getRuleByFilterRuleId(filterRuleId);
  return ruleContent[APPLIED] || false;
};

export const getStoredApplyTypeValue = ({ filterRuleId, getRuleByFilterRuleId }) => {
  const ruleContent = getRuleByFilterRuleId(filterRuleId);
  return ruleContent[APPLY_TYPE] || false;
};

const getStoredBasedOnValue = ({ filterRuleId, getRuleByFilterRuleId }) => {
  const ruleContent = getRuleByFilterRuleId(filterRuleId);
  return ruleContent[BASED_ON] || '';
};

export const getStoredBoolValues = ({ filterRuleId, getRuleByFilterRuleId }) => {
  const ruleContent = getRuleByFilterRuleId(filterRuleId);
  return ruleContent[BOOL_VALUES] || boolValuesInitialState;
};

export const getStoredStringValues = ({ getRuleByFilterRuleId, filterRuleId }) => {
  const ruleContent = getRuleByFilterRuleId(filterRuleId);
  return ruleContent[STRING_VALUES] || stringValuesInitialState;
};
