import { Divider } from '@neo4j-ndl/react';
import cn from 'classnames';
import { Children, Fragment, useMemo } from 'react';
import type { JSXElementConstructor, PropsWithChildren, ReactElement, ReactNode, ReactPortal } from 'react';

const willRender = (node: unknown) => node !== null && node !== undefined && typeof node !== 'boolean' && node !== '';

type DividerProps =
  | { divider: true; dividerTop?: boolean; dividerBottom?: boolean }
  | { divider?: false; dividerTop?: never; dividerBottom?: never };

type StackProps = PropsWithChildren<
  {
    /** takes css `margin` values */
    space?: string;
    className?: string;
  } & DividerProps
>;

/**
 * Stacks its children vertically using `display: flex` and optionally adds dividers before/between/after
 * the children.
 *
 * Top and bottom dividers can only be set along with regular dividers.
 */
export function Stack({
  children,
  space = '0',
  divider = false,
  dividerTop = false,
  dividerBottom = false,
  className,
}: StackProps) {
  const childrenWithDividers = useMemo(() => {
    const filteredChildren: (
      | string
      | number
      | boolean
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      | ReactElement<any, string | JSXElementConstructor<any>>
      | Iterable<ReactNode>
      | ReactPortal
      | null
      | undefined
    )[] = [];

    Children.forEach(children, (child, _idx) => {
      if (willRender(child)) {
        filteredChildren.push(child);
      }
    });

    return divider
      ? filteredChildren.map((child, idx) => {
          const displayDivider = idx > 0;
          return (
            <Fragment key={idx}>
              {displayDivider && <Divider />}
              {child}
            </Fragment>
          );
        })
      : children;
  }, [children, divider]);

  return (
    <div
      className={cn('flex flex-col justify-start', className)}
      style={{ gap: divider ? `calc((${space} - 1px)/2)` : space }}
    >
      {divider && dividerTop && <Divider />}
      {childrenWithDividers}
      {divider && dividerBottom && <Divider />}
    </div>
  );
}
