import type { ButtonProps } from '@neo4j-ndl/react';
import { Button } from '@neo4j-ndl/react';
import { useOpenDirectoryMutation } from '@nx/state';

export function OpenPathButton({
  path,
  children,
  size,
}: { path: string; children: React.ReactNode } & Pick<ButtonProps, 'size'>) {
  const [openDirectory] = useOpenDirectoryMutation();

  return (
    <Button
      size={size ?? 'small'}
      fill="outlined"
      color="neutral"
      className="text-nowrap"
      onClick={() => {
        void openDirectory(path);
      }}
    >
      {children}
    </Button>
  );
}
