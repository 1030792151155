import { isNullish } from '@nx/stdlib';
import type * as yup from 'yup';

export type ValidationError = {
  message: string;
  error: yup.ValidationError;
};

export type Validation<T> = {
  [K in keyof T]?: ValidationError;
};

export const validateYup = <T>(schema: yup.AnySchema, data: T, onlyRequired = false): Validation<T> | null => {
  let validation = null;
  try {
    // eslint-disable-next-line no-sync
    schema.validateSync(data, { abortEarly: false });
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const error: yup.ValidationError = e as yup.ValidationError;

    validation = error.inner.reduce((acc, err) => {
      if ((onlyRequired && err.type !== 'required') || isNullish(err.path)) {
        return acc;
      }

      return { ...acc, [err.path]: { message: err.message, error: err } };
    }, {});

    if (Object.keys(validation).length === 0) {
      validation = null;
    }
  }

  return validation;
};

export const isValid = <T, B extends T = Required<T>>(data: T, validation: Validation<T> | null): data is B =>
  isNullish(validation);

export type ConditionalSchema<T> = T extends string
  ? yup.StringSchema
  : T extends number
    ? yup.NumberSchema
    : T extends boolean
      ? yup.BooleanSchema
      : T extends Record<string | number | symbol, unknown>
        ? yup.AnyObjectSchema
        : T extends unknown[]
          ? yup.ArraySchema<unknown[] | null | undefined, unknown>
          : yup.AnySchema;

export type Shape<Fields> = {
  [Key in keyof Fields]: ConditionalSchema<Fields[Key]>;
};
