import { getFlag } from '@nx/launch-darkly-service';
import { makeSelectFeatureFlag, LEGACY_store as rootStore, selectCapability } from '@nx/state';
import { executeCommand } from '@query/redux/command-thunks';
import type { CmdHistoryEntry } from '@query/redux/editor-slice';
import { cmdRan, synchronizeHistoryEntries } from '@query/redux/editor-slice';
import type { AppDispatch, RootState } from '@query/redux/store';
import { editorHistoryApi } from '@query/services/api/editor-history-api';
import type { ListenerEffectAPI } from '@reduxjs/toolkit';
import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { debounce } from 'lodash-es';

const MAX_SYNC_INTERVAL = 5000;
const MAX_WAIT_INTERVAL = 10000;

const mapCmdHistoryEntriesToEditorHistoryEntries = (entries: CmdHistoryEntry[]) => {
  return entries.map((entry) => ({
    id: entry.id,
    cmd: entry.cmd,
    timestamp: entry.timestamp.toString(),
    database: entry.database,
    queryState: entry.queryState ?? null,
    savedId: entry.savedId ?? null,
  }));
};

const syncHistoryToStorageApi = async (listenerApi: ListenerEffectAPI<RootState, AppDispatch>) => {
  const cmdHistoryEntries = listenerApi.getState().editor.cmdHistory;

  if (cmdHistoryEntries.length === 0) {
    return;
  }

  const rootState = rootStore.getState();
  const { maxHistory } = rootState.settings.query;

  if (cmdHistoryEntries.length >= maxHistory) {
    const oldestTimestamp = Math.min(...cmdHistoryEntries.map((item) => item.timestamp));

    await listenerApi.dispatch(
      editorHistoryApi.endpoints.deleteEditorHistoryEntriesOlderThanTimestamp.initiate({
        timestamp: oldestTimestamp.toString(),
      }),
    );
  }

  const pendingItems = cmdHistoryEntries.filter((item: CmdHistoryEntry) => !(item.synchronized ?? false));
  const pendingEditorHistoryEntries = mapCmdHistoryEntriesToEditorHistoryEntries(pendingItems);
  await listenerApi.dispatch(
    editorHistoryApi.endpoints.updateEditorHistoryEntries.initiate({
      updateQueryEditorHistoryEntriesBody: pendingEditorHistoryEntries,
    }),
  );

  listenerApi.dispatch(
    synchronizeHistoryEntries({ ids: pendingItems.filter((item) => item.queryState).map((item) => item.id) }),
  );
};

const debouncedSync = debounce(syncHistoryToStorageApi, MAX_SYNC_INTERVAL, { maxWait: MAX_WAIT_INTERVAL });

const shouldSyncHistoryToStorageApi = () => {
  const rootState = rootStore.getState();

  const isStorageApiEnabled = selectCapability(rootState, {
    key: 'framework:storage-api',
  });
  const queryLaunchStorageApi = getFlag('queryLaunchStorageApi', false);
  const isFeatureFlagEnabled = makeSelectFeatureFlag('query:editor-history-shared-storage')(rootState);
  return isStorageApiEnabled && (isFeatureFlagEnabled || queryLaunchStorageApi);
};

const editorHistorySyncMiddleware = createListenerMiddleware<RootState>();
const startListening = editorHistorySyncMiddleware.startListening.withTypes<RootState, AppDispatch>();

startListening({
  matcher: isAnyOf(executeCommand.fulfilled, executeCommand.rejected, cmdRan),
  effect: async (_, listenerApi) => {
    if (shouldSyncHistoryToStorageApi()) {
      await debouncedSync(listenerApi);
    }
  },
});

export { editorHistorySyncMiddleware };
