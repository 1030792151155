import { isNotNullish } from '@nx/stdlib';

import type {
  ConsumptionBreakdownItemResponse,
  ConsumptionDiscountResponse,
  ConsumptionResponse,
} from '../console.api.types';
import type { Consumption, ConsumptionBreakdownItem, ConsumptionDiscount } from '../console.types';

const transformDiscountResponse = (response: ConsumptionDiscountResponse): ConsumptionDiscount => ({
  promotionName: response.promotion_name,
  voucherCode: response.voucher_code,
  dollarAmountOff: response.dollar_amount_off,
});

const transformBreakdownResponse = (response: ConsumptionBreakdownItemResponse): ConsumptionBreakdownItem => ({
  dbid: response.dbid,
  name: response.name,
  tier: response.tier,
  productName: response.product_name,
  pricingPlan: response.pricing_plan,
  usage: response.usage,
  cost: response.cost,
  currency: response.currency,
  unitCostInDollars: response.unit_cost_in_dollars,
  unitOfMeasure: response.unit_of_measure,
  billingStatus: response.billing_status,
});

export const transformConsumptionResponse = (response: ConsumptionResponse): Consumption => ({
  totalCost: response.total_cost,
  currency: response.currency,
  totalBreakdownCount: response.total_breakdown_count,
  breakdown: response.breakdown?.map((breakdown) => transformBreakdownResponse(breakdown)),
  usagePeriodEnd: response.usage_period_end,
  usagePeriodStart: response.usage_period_start,
  discount: isNotNullish(response.discount) ? transformDiscountResponse(response.discount) : undefined,
});
