import { tokens } from '@neo4j-ndl/base';

import type { COLOR_PALETTE_ENUM } from '../shared/color-palettes';
import { getColorPalette } from '../shared/color-palettes';

type ColorState = {
  colorIndices: Record<
    string,
    {
      colorIndex?: number;
    }
  >;
  series: Record<
    string,
    {
      color?: string;
      version?: string;
      dbmsId?: string;
    }
  >;
};

const SESSION_KEY = 'series';
const SESSION_KEY_PREFIX = 'nompersist:';

const initialState: ColorState = { series: {}, colorIndices: {} };

const getCurrentInstanceState = () => {
  const key = `${SESSION_KEY_PREFIX}${SESSION_KEY}`;
  let value: ColorState = initialState;

  // try to get from storage
  const previousValue = sessionStorage.getItem(key);
  if (previousValue !== null) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const parsed = JSON.parse(previousValue);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (Object.keys(parsed).findIndex((k) => !(k in ['colorIndices', 'series']))) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        value = parsed;
      } else {
        value = initialState;
      }
    } catch {
      // it wasn't JSON, assume a valid value
      value = initialState;
    }
  }

  return value;
};

const setSeriesColor = (seriesId: string, color: string, nextIndex: number, view: string) => {
  const key = `${SESSION_KEY_PREFIX}${SESSION_KEY}`;
  const value = getCurrentInstanceState();

  const { series, colorIndices } = value;
  series[seriesId] = { color: color };
  colorIndices[view] = { colorIndex: nextIndex };
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getSeriesColor = (paletteEnum: COLOR_PALETTE_ENUM) => (seriesId: string) => {
  const palette = getColorPalette(paletteEnum);
  const view = `palette${paletteEnum.toString()}`;
  const seriesKey = `${view}${seriesId}`;
  const value: ColorState = getCurrentInstanceState();

  let color;
  const staticColor = palette.staticColors[seriesId];
  if (staticColor !== undefined) {
    color = staticColor;
  } else {
    color = value.series[seriesKey]?.color;
  }

  if (color === undefined) {
    const colors = palette.assignableColors;

    const currentIndex = value.colorIndices[view]?.colorIndex ?? 0;
    color = colors[currentIndex] ?? tokens.colors.neutral[10];
    setSeriesColor(seriesKey, color, (currentIndex + 1) % colors.length, view);
  }

  return color;
};
