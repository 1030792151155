import { Label, Menu, SideNavigation, TextLink, Typography } from '@neo4j-ndl/react';
import { useFeatureFlag } from '@nx/state';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import classes from './left-nav.module.css';
import { SpotlightTarget } from './spotlight-target';

export type LinkMenuItem = {
  type: 'link';
  name: string;
  beta: boolean;
  icon?: React.ReactNode;
  route: string;
  spotlightId?: string;
  onClick?: () => void;
};

export type MenuItem = { type: 'heading'; name: string; spotlightId?: string } | LinkMenuItem | { type: 'divider' };

type LeftNavMenuProps = {
  items: (MenuItem | undefined)[];
  expanded: boolean;
  className?: string;
  isTemporaryOpen: boolean;
  onMouseLeave?: React.MouseEventHandler<HTMLElement>;
};

const COMPACT_WIDTH = 226;

export function LeftNavMenu({ items, isTemporaryOpen, expanded, className, onMouseLeave }: LeftNavMenuProps) {
  const [enter, setEnter] = useState(false);
  const combinedClassName = cn(
    classes.nav,
    expanded && classes.open,
    enter && classes.enter,
    isTemporaryOpen ? '[&>*:first-child]:pb-16' : 'md:[&>*:first-child]:pb-4',
    className,
  );

  const [manageProjectRolesEnabled] = useFeatureFlag('upx:manage-project-roles', false);

  useEffect(() => setEnter(expanded), [expanded]);

  const showIcons = items.some((item) => item?.type === 'link' && item.icon);

  return (
    <SideNavigation
      isExpanded={expanded}
      position="left"
      className={combinedClassName}
      htmlAttributes={{
        onMouseLeave,
        style: { width: COMPACT_WIDTH, minWidth: COMPACT_WIDTH },
      }}
      size="small"
      hasIconMenu={showIcons}
    >
      <SideNavigation.List>
        {items
          // Hide Roles unless feature flag is enabled
          .filter((item) => !(!manageProjectRolesEnabled && item?.type === 'link' && ['Roles'].includes(item.name)))
          .map((item, i) => {
            if (!item) {
              return null;
            }

            switch (item.type) {
              case 'heading':
                return (
                  <SpotlightTarget
                    key={item.name}
                    id={item.spotlightId}
                    indicatorVariant="point"
                    htmlAttributes={{ 'aria-label': undefined }}
                  >
                    <SideNavigation.GroupHeader>
                      <Typography variant="h6" className="text-palette-neutral-text-default">
                        {item.name}
                      </Typography>
                    </SideNavigation.GroupHeader>
                  </SpotlightTarget>
                );
              case 'link':
                return item.route.startsWith('http') ? (
                  <SpotlightTarget
                    key={item.name}
                    id={item.spotlightId}
                    indicatorVariant="point"
                    htmlAttributes={{ 'aria-label': undefined }}
                  >
                    <SideNavigation.Item as="span" icon={showIcons ? (item.icon ?? <></>) : undefined}>
                      <TextLink
                        as="a"
                        href={item.route}
                        isExternalLink
                        className="text-palette-neutral-text-default hover:text-palette-neutral-text-default flex h-full w-full items-center no-underline"
                        htmlAttributes={{
                          onClick: item.onClick,
                          rel: 'noreferrer',
                        }}
                      >
                        {item.name}
                      </TextLink>
                    </SideNavigation.Item>
                  </SpotlightTarget>
                ) : (
                  <NavLink key={item.name} to={item.route} data-testid={'left-nav-link'} onClick={item.onClick}>
                    {({ isActive }) => (
                      <SpotlightTarget
                        key={item.name}
                        id={item.spotlightId}
                        indicatorVariant="point"
                        htmlAttributes={{ 'aria-label': undefined }}
                      >
                        <SideNavigation.Item
                          as="span"
                          isSelected={isActive}
                          icon={showIcons ? (item.icon ?? <></>) : undefined}
                        >
                          <span className="flex w-full items-center justify-between pr-2">
                            {item.name}
                            {item.beta && (
                              <Label color="info" className="h-4 px-1.5">
                                Beta
                              </Label>
                            )}
                          </span>
                        </SideNavigation.Item>
                      </SpotlightTarget>
                    )}
                  </NavLink>
                );
              case 'divider':
                return <Menu.Divider key={i} />;
              default:
                return null;
            }
          })}
      </SideNavigation.List>
    </SideNavigation>
  );
}
