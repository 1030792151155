import type { CmiEndpointStatus, Instance, ProjectSummary } from '@nx/state';
import { ENDPOINT_TYPE, ERROR_TYPE, OpsTypes } from '@nx/state';
import { formatDistanceToNow } from 'date-fns';
import { useMemo } from 'react';

import { STATUS } from '../../../../../packages/state/src/slices/ops/types';
import { calculateTimeRangeLabelFromMs, formatBytes } from '../../shared/ui-helpers';

function hasRecentError(cmiStatus: CmiEndpointStatus) {
  return (
    (cmiStatus.lastErrorEpochSeconds !== null &&
      cmiStatus.lastErrorType !== null &&
      cmiStatus.lastMetricCountEpochSeconds === null) ||
    (cmiStatus.lastErrorEpochSeconds !== null &&
      cmiStatus.lastErrorType !== null &&
      cmiStatus.lastMetricCountEpochSeconds !== null &&
      cmiStatus.lastErrorEpochSeconds > cmiStatus.lastMetricCountEpochSeconds)
  );
}

function mapToTarget(
  cmiStatus: OpsTypes.Api.CmiEndpointStatus,
  activeProject: ProjectSummary | undefined,
  instanceIdToNameMap: Record<string, string>,
): string | null {
  if (cmiStatus.endpointType === ENDPOINT_TYPE.TENANT && activeProject) {
    return activeProject.name;
  }

  if (cmiStatus.endpointType === ENDPOINT_TYPE.INSTANCE && cmiStatus.instanceId === undefined) {
    return null;
  }

  return cmiStatus.endpointType === ENDPOINT_TYPE.INSTANCE && cmiStatus.instanceId !== undefined
    ? (instanceIdToNameMap[cmiStatus.instanceId] ?? 'unknown')
    : 'unknown';
}

function mapToId(cmiStatus: OpsTypes.Api.CmiEndpointStatus, activeProject: ProjectSummary | undefined): string {
  if (cmiStatus.endpointType === ENDPOINT_TYPE.TENANT && activeProject) {
    return activeProject.id;
  }

  if (cmiStatus.endpointType === ENDPOINT_TYPE.INSTANCE && cmiStatus.instanceId === undefined) {
    return 'unknown';
  }

  return cmiStatus.endpointType === ENDPOINT_TYPE.INSTANCE && cmiStatus.instanceId !== undefined
    ? (cmiStatus.instanceId ?? 'unknown')
    : 'unknown';
}

function mapToEndpointStatus(cmiStatus: CmiEndpointStatus): OpsTypes.STATUS {
  if (hasRecentError(cmiStatus)) {
    return STATUS.ERROR;
  }

  if (cmiStatus.lastMetricCountEpochSeconds !== null) {
    return STATUS.IN_USE;
  }

  return STATUS.NOT_IN_USE;
}

function mapToErrorText(errorType: OpsTypes.Api.ERROR_TYPE | null): string | null {
  switch (errorType) {
    case ERROR_TYPE.INSTANCE_NOT_FOUND:
      return 'Instance not found';
    case ERROR_TYPE.INTERNAL_ERROR:
      return 'Internal error';
    case ERROR_TYPE.UNAUTHORIZED:
      return 'Unauthorized';
    case ERROR_TYPE.NO_INSTANCES_ENABLED:
      return 'No instances enabled';
    default:
      return null;
  }
}

export const useStatusTableData = (
  instances: Instance[],
  endpointStatus: CmiEndpointStatus[],
  activeProject: ProjectSummary,
) => {
  const instanceIdToNameMap = useMemo(
    () =>
      instances.reduce<Record<string, string>>((a: Record<string, string>, b: Instance) => {
        a[b.id] = b.name;
        return a;
      }, {}),
    [instances],
  );
  const data = useMemo(() => {
    const mappedData = [
      ...endpointStatus.map((cmiStatus) => {
        return {
          id: mapToId(cmiStatus, activeProject),
          target: mapToTarget(cmiStatus, activeProject, instanceIdToNameMap),
          status: mapToEndpointStatus(cmiStatus),
          type:
            cmiStatus.endpointType === ENDPOINT_TYPE.INSTANCE
              ? OpsTypes.ENDPOINT_TARGET_TYPE.INSTANCE
              : OpsTypes.ENDPOINT_TARGET_TYPE.PROJECT,
          lastActive:
            cmiStatus.lastMetricCountEpochSeconds !== null
              ? `${formatDistanceToNow(new Date(cmiStatus.lastMetricCountEpochSeconds * 1000))} ago`
              : '',
          metricCount: cmiStatus.lastMetricCount ? `${cmiStatus.lastMetricCount}` : '',
          dataVolume: `${formatBytes(cmiStatus.responseBytes)}/${calculateTimeRangeLabelFromMs(cmiStatus.responseBytesPeriodSeconds * 1000).replace('1', '')}`,
          requestRate: `${cmiStatus.responseCount}/${calculateTimeRangeLabelFromMs(cmiStatus.responseCountPeriodSeconds * 1000).replace('1', '')}`,
          lastErrorText: `${hasRecentError(cmiStatus) ? mapToErrorText(cmiStatus.lastErrorType) : null}`,
        };
      }),
      ...instances
        .filter((i) => endpointStatus.findIndex((e) => e.instanceId === i.id) === -1)
        .map((i) => ({
          id: i.id,
          target: i.name,
          status: STATUS.NOT_IN_USE,
          type: OpsTypes.ENDPOINT_TARGET_TYPE.INSTANCE,
          lastActive: null,
          metricCount: null,
          dataVolume: null,
          requestRate: '',
          lastErrorText: null,
        })),
    ];

    // case when project endpoint is not used
    const projectEndpointStatus = mappedData.find((cd) => cd.type === OpsTypes.ENDPOINT_TARGET_TYPE.PROJECT);
    if (!projectEndpointStatus) {
      mappedData.push({
        id: activeProject.id,
        target: activeProject.name,
        status: STATUS.NOT_IN_USE,
        type: OpsTypes.ENDPOINT_TARGET_TYPE.PROJECT,
        lastActive: null,
        metricCount: null,
        dataVolume: null,
        requestRate: '',
        lastErrorText: null,
      });
    }

    return mappedData;
  }, [activeProject, endpointStatus, instanceIdToNameMap, instances]);
  return data;
};
