import { useSetting, useStorageApi, useUnsafeAppContext } from '@nx/state';
import { executeCommand } from '@query/redux/command-thunks';
import type { RootState } from '@query/redux/store';
import { useAppDispatch, useAppSelector } from '@query/redux/store';
import type { CommandSource } from '@query/services/analytics';
import {
  useDeleteEditorHistoryEntriesByIdMutation,
  useGetEditorHistoryEntriesQuery,
} from '@query/services/api/editor-history-api';
import type { GetEditorHistoryEntriesApiResponse } from '@query/services/api/generated-editor-history-api';
import { QUERY_STORAGE_LOADING_STATE } from '@query/services/api/types';
import { timestampGenerator } from '@query/utils/date-time-utils';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction, createSelector, createSlice, nanoid } from '@reduxjs/toolkit';
import { skipToken } from '@reduxjs/toolkit/query';

import type { ParsedCommand } from './commands';

export type Command = {
  cmd: string;
  savedId?: string;
  connection: {
    username: string | null;
    boltUrl: string;
    database: string | null;
    instanceName?: string;
    version?: string;
  } | null;
  source: CommandSource;
  maxHistory: number;
  // this is only passed to be sent with analytics event
  wasFormatted?: boolean;
  lintingEnabled: boolean;
  parsedCmd: ParsedCommand;
  frameId: string;
  parameterSnapshot: Record<string, unknown>;
  recordLimit?: number;
};

const commandSourcesNotRequiringHistoryIds = ['background-cmd', 'sysinfo-frame-auto-refresh'];

export const cmdRan = createAction('editor/cmdRan', (cmd: Command) => ({
  payload: {
    historyId: commandSourcesNotRequiringHistoryIds.includes(cmd.source) ? undefined : nanoid(),
    timestamp: timestampGenerator(),
    ...cmd,
  },
}));

type EditorHistoryEntrySelector = (state: RootState) => string[];

const deduplicateCmdHistoryEntries = (cmdHistoryEntries: CmdHistoryEntry[]) => {
  return cmdHistoryEntries
    .map(({ cmd }) => cmd)
    .reduce<string[]>((acc, curr) => (curr === acc.at(-1) ? acc : [...acc, curr]), []);
};

const selectDeduplicatedEditorHistoryEntries: EditorHistoryEntrySelector = createSelector(
  (state: RootState) => state.editor.cmdHistory,
  (cmdHistory: CmdHistoryEntry[]) => deduplicateCmdHistoryEntries(cmdHistory),
);

const selectFullHistoryEntries = (state: RootState) => state.editor.cmdHistory;

export const selectInitialEditorValue = (state: RootState) => state.editor.initialEditorValue;

export type QueryState = 'failed' | 'canceled' | 'success';
export type CmdHistoryEntry = {
  cmd: string;
  timestamp: number;
  database: string | null;
  id: string;
  queryState?: QueryState;
  savedId?: string | null;
  synchronized?: boolean;
};

export type EditorState = {
  cmdHistory: CmdHistoryEntry[];
  retainEditorHistory?: boolean;
  initialEditorValue?: string | null;
};

export const EDITOR_PERSISTED_KEYS = ['codemirrorEnabled', 'lintingEnabled', 'cmdHistory', 'retainEditorHistory'];

const initialState: EditorState = {
  cmdHistory: [],
  retainEditorHistory: false,
  initialEditorValue: null,
};

const editor = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    removeHistoryEntries: (state, action: PayloadAction<{ ids: string[] }>) => {
      state.cmdHistory = state.cmdHistory.filter((entry) => !action.payload.ids.includes(entry.id));
    },
    updateRetainHistory: (state, action: PayloadAction<{ retainEditorHistory: boolean }>) => {
      state.retainEditorHistory = action.payload.retainEditorHistory;
      // re-setting the cmdHistory to trigger the redux persist's transform
      state.cmdHistory = [...state.cmdHistory];
    },
    synchronizeHistoryEntries: (state, action: PayloadAction<{ ids: string[] }>) => {
      const synchronizedEntries = state.cmdHistory.map((entry) => {
        if (action.payload.ids.includes(entry.id)) {
          entry.synchronized = true;
        }
        return entry;
      });
      state.cmdHistory = synchronizedEntries;
    },
    setInitialEditorValue: (state, action: PayloadAction<string>) => {
      state.initialEditorValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cmdRan, (state, action) => {
        const { cmd, connection, timestamp, historyId, maxHistory, savedId } = action.payload;
        const database = connection?.database ?? null;
        if (historyId !== undefined) {
          const newEntry: CmdHistoryEntry = { cmd, database, timestamp, id: historyId, savedId, synchronized: false };
          state.cmdHistory = [newEntry, ...state.cmdHistory].slice(0, maxHistory);
        }
      })
      .addCase(executeCommand.fulfilled, (state, action) => {
        const { historyId } = action.payload;
        if (historyId !== undefined) {
          const entry = state.cmdHistory.find((e) => e.id === historyId);

          if (entry !== undefined) {
            entry.queryState = 'success';
            entry.synchronized = false;
          }
        }
      })
      .addCase(executeCommand.rejected, (state, action) => {
        if (action.payload) {
          const { historyId, canceled } = action.payload;
          if (historyId !== undefined) {
            const entry = state.cmdHistory.find((e) => e.id === historyId);

            if (entry !== undefined) {
              entry.queryState = canceled ? 'canceled' : 'failed';
              entry.synchronized = false;
            }
          }
        }
      });
  },
});

const { removeHistoryEntries } = editor.actions;
export const { updateRetainHistory, synchronizeHistoryEntries, setInitialEditorValue } = editor.actions;

export const useDeleteHistoryEntries = () => {
  const dispatch = useAppDispatch();
  const storageApiEnabled = useStorageApi('query:editor-history-shared-storage');
  const [deleteEntries] = useDeleteEditorHistoryEntriesByIdMutation();

  if (storageApiEnabled) {
    return async (ids: string[]) => {
      await deleteEntries({ deleteQueryEditorHistoryEntriesByIdBody: { ids } });
      dispatch(removeHistoryEntries({ ids }));
    };
  }

  return (ids: string[]) => {
    dispatch(removeHistoryEntries({ ids }));
    return Promise.resolve();
  };
};

const useGetEditorHistoryEntries = () => {
  const [maxHistory] = useSetting('query', 'maxHistory');
  const storageApiEnabled = useStorageApi('query:editor-history-shared-storage');
  const { activeProjectId } = useUnsafeAppContext();
  const skip = activeProjectId === null || !storageApiEnabled;
  const { data = [], isError, isSuccess } = useGetEditorHistoryEntriesQuery(skip ? skipToken : { limit: maxHistory });
  let state = QUERY_STORAGE_LOADING_STATE.LOADING;

  if (isSuccess) {
    state = QUERY_STORAGE_LOADING_STATE.SUCCESS;
  } else if (isError) {
    state = QUERY_STORAGE_LOADING_STATE.ERROR;
  }

  return { data, state };
};

const mapApiResponseToCmdHistoryEntries = (data: GetEditorHistoryEntriesApiResponse): CmdHistoryEntry[] => {
  return data.map((entry) => ({
    ...entry,
    timestamp: parseInt(entry.timestamp, 10),
    queryState: entry.queryState ?? undefined,
    synchronized: true,
  }));
};

const useZipHistoryEntries = () => {
  const [maxHistory] = useSetting('query', 'maxHistory');
  return (localEntries: CmdHistoryEntry[], cloudEntries: CmdHistoryEntry[]) => {
    const mergedEntries = [...localEntries, ...cloudEntries];
    const entryMap = new Map(mergedEntries.map((entry) => [entry.id, entry]));
    return Array.from(entryMap.values())
      .sort((a, b) => b.timestamp - a.timestamp)
      .slice(0, maxHistory);
  };
};

export const useGetFullEditorHistory = (): {
  editorHistoryEntries: CmdHistoryEntry[];
  state: QUERY_STORAGE_LOADING_STATE;
} => {
  const editorHistoryEntries = useAppSelector(selectFullHistoryEntries);
  const storageApiEnabled = useStorageApi('query:editor-history-shared-storage');
  const { data, state } = useGetEditorHistoryEntries();
  const zipHistoryEntries = useZipHistoryEntries();

  if (storageApiEnabled) {
    const mappedEditorHistoryEntries = mapApiResponseToCmdHistoryEntries(data);
    const zippedHistoryEntries = zipHistoryEntries(editorHistoryEntries, mappedEditorHistoryEntries);
    return { editorHistoryEntries: zippedHistoryEntries, state };
  }

  return { editorHistoryEntries, state: QUERY_STORAGE_LOADING_STATE.SUCCESS };
};

export const useGetDeduplicatedEditorHistory = (): {
  editorHistoryEntries: string[];
  state: QUERY_STORAGE_LOADING_STATE;
} => {
  const editorHistoryEntries = useAppSelector(selectFullHistoryEntries);
  const deDuplicatedEditorHistoryEntries = useAppSelector(selectDeduplicatedEditorHistoryEntries);
  const storageApiEnabled = useStorageApi('query:editor-history-shared-storage');
  const { data, state } = useGetEditorHistoryEntries();
  const zipHistoryEntries = useZipHistoryEntries();

  if (storageApiEnabled) {
    const mappedEditorHistoryEntries = mapApiResponseToCmdHistoryEntries(data);
    const zippedHistoryEntries = zipHistoryEntries(editorHistoryEntries, mappedEditorHistoryEntries);
    const deDuplicatedZippedEditorHistoryEntries = deduplicateCmdHistoryEntries(zippedHistoryEntries);
    return { editorHistoryEntries: deDuplicatedZippedEditorHistoryEntries, state };
  }

  return { editorHistoryEntries: deDuplicatedEditorHistoryEntries, state: QUERY_STORAGE_LOADING_STATE.SUCCESS };
};

export default editor.reducer;

export function cmdNotInWorkspace(cmd: string): boolean {
  return (
    [
      ':config',
      ':dbs',
      ':debug',
      ':schema',
      ':style',
      ':guide',
      ':help',
      ':http',
      ':play',
      ':queries',
      ':sysinfo',
    ].find((browserCmd) => cmd.startsWith(browserCmd)) !== undefined
  );
}

// Some editor actions require a reference to the editor instance which only
// the Editor react component holds. The most lightweight solution we found was to
// use the browser event system
declare global {
  interface DocumentEventMap {
    seteditorvalue: CustomEvent<string>;
    focuseditor: CustomEvent<undefined>;
  }
}

export const setEditorValue = (val: string) =>
  document.dispatchEvent(new CustomEvent('seteditorvalue', { detail: val }));

export const focusEditor = () => document.dispatchEvent(new CustomEvent('focuseditor'));
