import { Dialog, LoadingSpinner } from '@neo4j-ndl/react';
import type { Project, TIER, TrafficConfig } from '@nx/state';
import { CLOUD_PROVIDER, useInstances } from '@nx/state';

import { getCloudProviders } from '../entities/helpers';
import { AWSTrafficConfigDialog } from './aws-dialog';
import { AzureTrafficConfigDialog } from './azure-dialog';
import { GCPTrafficConfigDialog } from './gcp-dialog';

type TrafficConfigDialogProps = {
  project: Project;
  trafficConfig?: TrafficConfig;
  title: string;
  existingTrafficConfigs?: TrafficConfig[];
  existingTierRegions?: Partial<Record<TIER, string[]>>;
  onClose: () => void;
  onSuccess?: (trafficConfig: TrafficConfig) => void;
};

export const TrafficConfigDialog = ({
  project,
  trafficConfig,
  title,
  onClose,
  onSuccess,
  // Used to prepopulate the props (e.g. ProjectIds)
  // when creating
  existingTrafficConfigs = [],
  // Used to determine if a tier/region combo
  // is already configured
  existingTierRegions = {},
}: TrafficConfigDialogProps) => {
  const cloudProviders = getCloudProviders(project.tierConfigs);
  const { instances, isInstancesLoading } = useInstances({ shouldPoll: true });

  const [cloudProvider] = cloudProviders;
  return (
    <Dialog isOpen onClose={onClose} size="large">
      <Dialog.Header>{title}</Dialog.Header>
      {isInstancesLoading && <LoadingSpinner size="large" className="m-auto" />}
      {!isInstancesLoading && (
        <>
          {cloudProvider === CLOUD_PROVIDER.GCP && (
            <GCPTrafficConfigDialog
              project={project}
              trafficConfig={trafficConfig}
              onClose={onClose}
              onSuccess={onSuccess}
              existingTierRegions={existingTierRegions}
              existingTrafficConfigs={existingTrafficConfigs}
              instances={instances}
            />
          )}
          {cloudProvider === CLOUD_PROVIDER.AWS && (
            <AWSTrafficConfigDialog
              project={project}
              trafficConfig={trafficConfig}
              onClose={onClose}
              onSuccess={onSuccess}
              existingTierRegions={existingTierRegions}
              existingTrafficConfigs={existingTrafficConfigs}
              instances={instances}
            />
          )}
          {cloudProvider === CLOUD_PROVIDER.AZURE && (
            <AzureTrafficConfigDialog
              project={project}
              trafficConfig={trafficConfig}
              onClose={onClose}
              onSuccess={onSuccess}
              existingTierRegions={existingTierRegions}
              existingTrafficConfigs={existingTrafficConfigs}
              instances={instances}
            />
          )}
        </>
      )}
    </Dialog>
  );
};
