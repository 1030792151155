import type { AnalyticsTrackPayload } from '@nx/analytics-service';
import * as React from 'react';

import { useDispatch } from '../context';
import { trackEvent } from '../middlewares/analytics-events';

/**
 * Acquire function used to track analytics events
 *
 * Accepts type argument to allow narrowing down expected events
 *
 * @example
 * // Basic usage
 * const trackEvent = useTrackEvent()
 *
 * <button
 *   onClick={() => {
 *     onClose();
 *     trackEvent({ event: FRAMEWORK_EVENTS.DISCONNECT });
 *   }}
 * >
 *   Disconnect
 * </button>
 *
 * @example
 * // Narrow down allowed event types
 * const useTrackUiEvent = useTrackEvent<AnalyticsTrackPayload & { event: UI_EVENTS }>
 */
export function useTrackEvent<Payload extends AnalyticsTrackPayload>() {
  const dispatch = useDispatch();
  return React.useCallback(
    (payload: Payload) => {
      dispatch(trackEvent(payload));
    },
    [dispatch],
  );
}
