import type { AuthToken, AuthTokenAndExpiration, QueryResult } from 'neo4j-driver';

import type { APP_SCOPE } from '.';

// argument types
type CypherQueryParameters = Record<string, unknown>;

export type CypherTransactionQuery = {
  query: string;
  parameters?: CypherQueryParameters;
};

export enum QUERY_TYPE {
  // Query automatically run by the app.
  System = 'system',
  // Query the user directly submitted to/through the app.
  UserDirect = 'user-direct',
  // Query resulting from an action the user performed.
  UserAction = 'user-action',
  // Query that has been derived from the user input.
  UserTranspiled = 'user-transpiled',
}

export type CypherTransactionMetadata = {
  app: APP_SCOPE | 'bloom' | 'nx';
  container_app: 'workspace' | 'standalone';
  vendor: 'neo4j';
  type: QUERY_TYPE;
  version?: string;
};

// return types
export type QueryResultWithLimit = QueryResult & { recordLimitHit?: boolean };
export type CypherQueryAsyncResult = {
  resultPromise: Promise<QueryResultWithLimit>;
  transactionId: string;
};

export type TransactionMetadataConfig = {
  appScope: APP_SCOPE;
  queryType: QUERY_TYPE;
  version?: string;
};

export interface CypherTransactionUtils {
  cancelTransaction: (transactionId: string) => Promise<void>;

  getTransactionMetadata: (
    txMetadataConfig: TransactionMetadataConfig,
    enabledTools: string[],
  ) => CypherTransactionMetadata;
}

export type DriverConnectInfo = {
  url: string;
  authToken: AuthToken;
  databaseId?: string;
};

export type DriverConnectInfoWithManager = {
  url: string;
  tokenProvider: () => Promise<AuthTokenAndExpiration>;
};
