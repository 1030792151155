import { Banner, Button, Dialog } from '@neo4j-ndl/react';
import { isNotNullish } from '@nx/stdlib';
import type { ReactNode } from 'react';

type DangerConfirmModalProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: ReactNode;
  content: ReactNode;
  loading: boolean;
  error: string | null;
  confirmButtonText: string;
};

export const DangerConfirmModal = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
  loading,
  error,
  confirmButtonText,
}: DangerConfirmModalProps) => {
  return (
    <Dialog isOpen={open} onClose={onClose} type="danger">
      <Dialog.Header>{title}</Dialog.Header>
      <Dialog.Content>
        <div className="flex flex-col gap-4">
          {content}
          {isNotNullish(error) && <Banner description={error} type="danger" usage="inline" />}
        </div>
      </Dialog.Content>
      <Dialog.Actions>
        <Button onClick={onClose} color="neutral" fill="outlined">
          Cancel
        </Button>
        <Button onClick={onConfirm} isLoading={loading}>
          {confirmButtonText}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
