import { Button, Typography } from '@neo4j-ndl/react';
import { ACTION, consoleApi, useActiveProject, useInstances, usePermissions } from '@nx/state';
import { BackButton, PermissionTooltip } from '@nx/ui';
import { useState } from 'react';

import { CreateCustomEndpointDialog } from './create-dialog';
import { CustomEndpointsTable } from './table';

interface Props {
  message: string;
}

export const ComingSoon = ({ message }: Props) => {
  return (
    <div className="flex h-full flex-col items-center justify-center gap-16 md:flex-row">
      <div className="flex max-w-[430px] flex-col gap-6">
        <h3>Coming soon...</h3>
        <Typography variant="body-large">{message}</Typography>
      </div>
    </div>
  );
};

const CustomEndpoints = () => {
  const [openCreate, setOpenCreate] = useState(false);
  const project = useActiveProject();
  const { instances = [] } = useInstances({ shouldPoll: true });
  const { isAllowed } = usePermissions();
  const isAllowedToCreateCustomEndpoint = isAllowed(ACTION.CREATE, `namespaces/${project.id}/custom-endpoints`);
  const { data: endpoints = [], isLoading } = consoleApi.useListCustomEndpointsByProjectQuery(project.id, {
    skip: !project.capabilities.custom_endpoints,
    pollingInterval: 10000,
  });

  return (
    <>
      <div className="h-full overflow-auto p-4">
        <BackButton to="../settings" />
        <div className="mb-6 flex items-center justify-between px-2 pt-2">
          <Typography variant="h2">Custom endpoints</Typography>
          <PermissionTooltip hasButtonWrapper hasPermission={isAllowedToCreateCustomEndpoint}>
            <Button
              isDisabled={!isAllowedToCreateCustomEndpoint || !project.capabilities.custom_endpoints}
              onClick={() => setOpenCreate(true)}
              htmlAttributes={{
                'data-testid': 'create-custom-endpoint-btn',
              }}
            >
              Create custom endpoint
            </Button>
          </PermissionTooltip>
        </div>
        <CustomEndpointsTable instances={instances} endpoints={endpoints} isLoading={isLoading} />
      </div>
      {openCreate && <CreateCustomEndpointDialog onClose={() => setOpenCreate(false)} instances={instances} />}
    </>
  );
};

export const CustomEndpointsGuard = () => {
  const project = useActiveProject();

  if (!project.capabilities.custom_endpoints) {
    return <ComingSoon message="This page will soon contain something exciting!" />;
  }

  return <CustomEndpoints />;
};
