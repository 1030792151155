import { isAction, isAnyOf } from '@reduxjs/toolkit';

import type { Perspective } from '../../types/perspective';
import type { Style } from '../../types/style';
import type { PerspectiveAction } from '../perspectives/perspectives';
import {
  addCategoriesToPerspective,
  addCategoryToPerspective,
  addLabelToCategory,
  addLabelsAsCategoriesToPerspective,
  addPropertiesToCategory,
  addPropertiesToRelationships,
  addRelationshipsToPerspective,
  addSceneAction,
  addSearchTemplate,
  hideRelationshipTypes,
  removeCategoryFromPerspective,
  removeLabelFromCategory,
  removeLabelsFromPerspective,
  removePropertiesFromCategory,
  removePropertiesFromRelationships,
  removeRelationshipsFromPerspective,
  removeSceneAction,
  removeSearchTemplate,
  revealRelationshipTypes,
  setHideUncategorisedData,
  setMetaLabelsToPerspective,
  updateCategoryName,
  updateCategoryOrder,
  updateCategoryProperty,
  updatePerspectiveName,
  updateSceneAction,
  updateSceneActionOrder,
  updateSearchTemplate,
} from '../perspectives/perspectives';
import { getPerspectiveById } from '../perspectives/perspectives.selector';
import type { StylesAction } from '../styles/styles';
import { getStyleById, NAME as styles } from '../styles/styles';
import type { AppMiddleware, RootState } from '../types';
import type { StoragePerspective } from './api-types/perspective';
import { isSharedStorageAvailable, sharedStorageApi } from './shared-storage.api';
import { AsyncQueue } from './util/async-queue';
import { batchedUpdatePerspective } from './util/batched-update-perspective';

const getUpdatedPerspective = (
  state: RootState,
  action: PerspectiveAction,
): { perspectiveId: string | undefined; perspective: Perspective | undefined } => {
  if (!action.payload || !('perspectiveId' in action.payload)) {
    return { perspectiveId: undefined, perspective: undefined };
  }
  const { perspectiveId } = action.payload;
  const perspective = getPerspectiveById(state)(perspectiveId);
  return { perspectiveId, perspective };
};

const isStylesAction = (action: unknown): action is StylesAction =>
  isAction(action) && action.type.startsWith(`${styles}/`);

const getUpdatedStyle = (
  state: RootState,
  action: StylesAction,
): { perspectiveId: string | undefined; style: Style | undefined } => {
  const styleId =
    typeof action.payload === 'object' && 'styleId' in action.payload
      ? action.payload.styleId
      : (state.styles.currentStyleId ?? undefined);
  const style = getStyleById(state)(styleId);

  if (style && style.type === 'perspective') {
    return { perspectiveId: styleId, style };
  }

  return { perspectiveId: undefined, style: undefined };
};

const queue = new AsyncQueue();
const batchedUpdate = batchedUpdatePerspective();

const sharedStorageSyncMiddleware: AppMiddleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    const result = next(action);

    if (!isSharedStorageAvailable()) {
      return result;
    }

    const state = getState();

    // TODO extract to a separate perspectives file once we have more logic
    const perspectiveUpdater = (perspectiveId: string, update: Partial<StoragePerspective>) => {
      queue.add(async () => {
        await dispatch(
          sharedStorageApi.endpoints.updatePerspective.initiate({
            perspectiveId,
            update,
          }),
        );
      });
    };

    if (isStylesAction(action)) {
      const { perspectiveId, style } = getUpdatedStyle(state, action);
      if (!perspectiveId || !style) return;

      batchedUpdate(perspectiveId, { style }, perspectiveUpdater);

      return result;
    }

    if (updatePerspectiveName.match(action)) {
      const { name, perspectiveId } = action.payload;

      batchedUpdate(perspectiveId, { name }, perspectiveUpdater);

      return result;
    }

    if (
      isAnyOf(
        addCategoryToPerspective,
        addCategoriesToPerspective,
        addLabelToCategory,
        addLabelsAsCategoriesToPerspective,
        addPropertiesToCategory,
        removeCategoryFromPerspective,
        removeLabelFromCategory,
        removeLabelsFromPerspective,
        removePropertiesFromCategory,
        setMetaLabelsToPerspective,
        updateCategoryName,
        updateCategoryOrder,
        updateCategoryProperty,
      )(action)
    ) {
      const { perspective, perspectiveId } = getUpdatedPerspective(state, action);
      if (!perspective || !perspectiveId) return;

      batchedUpdate(perspectiveId, { categories: perspective.categories }, perspectiveUpdater);

      return result;
    }

    if (setHideUncategorisedData.match(action)) {
      const { perspective, perspectiveId } = getUpdatedPerspective(state, action);
      if (!perspective || !perspectiveId) return;

      batchedUpdate(perspectiveId, { hideUncategorisedData: perspective.hideUncategorisedData }, perspectiveUpdater);

      return result;
    }

    if (
      isAnyOf(
        addPropertiesToRelationships,
        addRelationshipsToPerspective,
        hideRelationshipTypes,
        removePropertiesFromRelationships,
        removeRelationshipsFromPerspective,
        revealRelationshipTypes,
      )(action)
    ) {
      const { perspective, perspectiveId } = getUpdatedPerspective(state, action);
      if (!perspective || !perspectiveId) return;

      batchedUpdate(perspectiveId, { relationshipTypes: perspective.relationshipTypes }, perspectiveUpdater);

      return result;
    }

    if (isAnyOf(addSearchTemplate, removeSearchTemplate, updateSearchTemplate)(action)) {
      const { perspective, perspectiveId } = getUpdatedPerspective(state, action);
      if (!perspective || !perspectiveId) return;

      batchedUpdate(perspectiveId, { templates: perspective.templates }, perspectiveUpdater);

      return result;
    }

    if (isAnyOf(addSceneAction, removeSceneAction, updateSceneAction, updateSceneActionOrder)(action)) {
      const { perspective, perspectiveId } = getUpdatedPerspective(state, action);
      if (!perspective || !perspectiveId) return;

      batchedUpdate(perspectiveId, { sceneActions: perspective.sceneActions }, perspectiveUpdater);

      return result;
    }

    return result;
  };

export default sharedStorageSyncMiddleware;
