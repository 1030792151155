import { Select } from '@neo4j-ndl/react';
import type { TIER } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import React from 'react';
import type { SingleValue } from 'react-select';

interface Props {
  tierOptions: { key: string; value: TIER; label: string }[];
  tier: TIER;
  setTier: (tier: TIER) => void;
}

export const TierDropdown = ({ tierOptions, tier, setTier }: Props) => {
  const handleTierChange = (option: SingleValue<{ key: string; label: string; value: TIER }>) => {
    if (isNotNullish(option)) {
      setTier(option.value);
    }
  };

  return (
    <Select
      size="medium"
      type="select"
      label="Product"
      selectProps={{
        options: tierOptions,
        value: tierOptions.find((option) => option.value === tier),
        onChange: handleTierChange,
      }}
      htmlAttributes={{
        'data-testid': 'log-forwarding-product-select',
      }}
    />
  );
};
