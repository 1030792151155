import { REGIONAL_GDPR_COMPLIANCE_TIER } from '@nx/state';
import { isNullish } from '@nx/stdlib';

export const getMarketingPreference = (marketingPreference: boolean, regionalTier: REGIONAL_GDPR_COMPLIANCE_TIER) => {
  if (regionalTier === REGIONAL_GDPR_COMPLIANCE_TIER.TIER_1) {
    return true;
  }
  return regionalTier !== REGIONAL_GDPR_COMPLIANCE_TIER.TIER_2 ? marketingPreference : !marketingPreference;
};

export const getContentSize = (marketingChoiceRequired?: REGIONAL_GDPR_COMPLIANCE_TIER | null) => {
  if (isNullish(marketingChoiceRequired)) {
    return 'max-h-[50vh]';
  }
  if (marketingChoiceRequired === REGIONAL_GDPR_COMPLIANCE_TIER.TIER_1) {
    return 'max-h-[48vh]';
  }
  return 'max-h-[43vh]';
};
