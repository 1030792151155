import type { DialogProps } from '@neo4j-ndl/react';
import { Banner, Button, DataGrid, Dialog, Switch, Typography } from '@neo4j-ndl/react';
import type { TimeRange } from '@nx/state';
import { opsApi, useActiveProject, useColumnPreferences } from '@nx/state';
import { isNonEmptyString } from '@nx/stdlib';
import { DataGridHelpers } from '@nx/ui';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import { isEqual } from 'lodash-es';
import type { ReactNode } from 'react';
import { useMemo, useRef, useState } from 'react';

import classes from '../../../logs/logs.module.css';
import { ColumnPreferencesHeaderCell, CommonNoDataPlaceholder } from '../../../logs/shared/helpers/table-overrides';
import { ACTIONS_COLUMN_ID } from '../../../logs/shared/types';
import { datesWithin24H } from '../../../logs/shared/utils';
import { ApiErrorBanner } from '../../../shared/components';
import { timePeriodFormatter } from '../../../shared/ui-helpers';
import { useDriverColumns } from '../../hooks/use-driver-columns';
import { mapDriverLogAggregations } from '../../mappers';
import { DriverLogsExpand } from './driver-logs-expand';

type ToggleValues = {
  onlyShowIssues: boolean;
  onlyShowNonSystemDatabaseAccess: boolean;
};

const defaultValues = {
  onlyShowIssues: true,
  onlyShowNonSystemDatabaseAccess: false,
};

type RequestDialogProps = Omit<DialogProps, 'children'> & {
  toggleValues: ToggleValues;
  hasQueries: boolean;
  timeRange: TimeRange;
  onAccept: (values: ToggleValues) => void;
};

const RequestDialog = ({ toggleValues, hasQueries, timeRange, onAccept, modalProps, ...props }: RequestDialogProps) => {
  const [localToggleValues, setLocalToggleValues] = useState(toggleValues);

  return (
    <Dialog
      {...props}
      modalProps={{
        ...modalProps,
        className: classNames('mt-12 align-top mx-auto', modalProps?.className),
      }}
    >
      <Dialog.Header>Fetch driver statistics</Dialog.Header>
      <Dialog.Content>
        <div className="mb-6 flex flex-col gap-4">
          <div className="mb-2 flex flex-col">
            <Typography className="mb-2" variant="label">
              Current time selection
            </Typography>
            <Typography variant="body-medium">
              {`${timePeriodFormatter(timeRange.startTime)} - ${timePeriodFormatter(timeRange.endTime)}`}
            </Typography>
            <Typography variant="body-small">The time range can be changed from the timeline chart</Typography>
          </div>
          {!hasQueries && (
            <Banner
              hasIcon
              type="warning"
              description="There are no queries within the selected time range."
              usage="inline"
            />
          )}
          <Switch
            onChange={() =>
              setLocalToggleValues({
                ...localToggleValues,
                onlyShowIssues: !localToggleValues.onlyShowIssues,
              })
            }
            label="Only show driver usage with potential issues"
            isChecked={localToggleValues.onlyShowIssues}
          />
          <Switch
            onChange={() =>
              setLocalToggleValues({
                ...localToggleValues,
                onlyShowNonSystemDatabaseAccess: !localToggleValues.onlyShowNonSystemDatabaseAccess,
              })
            }
            label="Only show drivers accessing non-system database"
            isChecked={localToggleValues.onlyShowNonSystemDatabaseAccess}
          />
        </div>
        <div className="mt-4 w-full text-right">
          <Button
            className="ml-auto mr-2"
            fill="outlined"
            color="neutral"
            isDisabled={isEqual(localToggleValues, defaultValues)}
            onClick={() => {
              setLocalToggleValues(defaultValues);
            }}
          >
            Reset
          </Button>
          <Button
            size="medium"
            color="primary"
            isDisabled={!hasQueries}
            onClick={() => {
              onAccept(localToggleValues);
            }}
            htmlAttributes={{
              'data-testid': 'request-log',
            }}
          >
            Fetch
          </Button>
        </div>
      </Dialog.Content>
    </Dialog>
  );
};

export const DriverLogs = ({
  aqlaUrl,
  chartTimeRange,
  hasQueries,
  selectedInstanceId,
}: {
  aqlaUrl: string;
  hasQueries: boolean;
  chartTimeRange: TimeRange;
  selectedInstanceId: string;
}) => {
  const activeProject = useActiveProject();

  const [isRequestDialogOpen, setIsRequestDialogOpen] = useState(false);
  const [hasRequestedData, setHasRequestedData] = useState(false);

  const [selectedTimeRange, setSelectedTimeRange] = useState({
    startTime: chartTimeRange.startTime,
    endTime: chartTimeRange.endTime,
  });

  const getDriverLogsRes = opsApi.useGetDriverLogAggregationsQuery(
    isNonEmptyString(selectedInstanceId) && hasRequestedData
      ? {
          tenantId: activeProject.id,
          dbmsId: selectedInstanceId,
          from: selectedTimeRange.startTime.getTime(),
          to: selectedTimeRange.endTime.getTime(),
        }
      : skipToken,
  );

  const [toggleValues, setToggleValues] = useState<ToggleValues>(defaultValues);
  const driverLogAggregations = useMemo(
    () =>
      mapDriverLogAggregations(
        getDriverLogsRes.data,
        toggleValues.onlyShowIssues,
        toggleValues.onlyShowNonSystemDatabaseAccess,
      ),
    [getDriverLogsRes.data, toggleValues.onlyShowIssues, toggleValues.onlyShowNonSystemDatabaseAccess],
  );

  const dateFrom = new Date(selectedTimeRange.startTime);
  const dateTo = new Date(selectedTimeRange.endTime);
  const isStaleData =
    chartTimeRange.startTime.getTime() !== dateFrom.getTime() || chartTimeRange.endTime.getTime() !== dateTo.getTime();

  const datesTooFarApart = useMemo(
    () => !datesWithin24H(chartTimeRange.startTime, chartTimeRange.endTime),
    [chartTimeRange],
  );

  const SmallRequestButton = useMemo(
    () => (
      <Button
        className="m-2"
        size="small"
        color="primary"
        fill="outlined"
        isDisabled={getDriverLogsRes.isFetching || datesTooFarApart}
        onClick={() => {
          setIsRequestDialogOpen(true);
        }}
        htmlAttributes={{
          'data-testid': 'request-driver-statistics',
        }}
      >
        Fetch driver statistics
      </Button>
    ),
    [getDriverLogsRes.isFetching, datesTooFarApart],
  );
  const statusText = (
    <Typography variant="body-medium" as="span" className="flex flex-wrap items-center gap-1">
      Showing statistics for {toggleValues.onlyShowIssues ? 'drivers with potential issues' : 'all drivers'}{' '}
      {toggleValues.onlyShowNonSystemDatabaseAccess ? 'accessing a non-system database' : ''} from{' '}
      {timePeriodFormatter(dateFrom)} to {timePeriodFormatter(dateTo)}
      {isStaleData && <span className="font-bold">Selection is stale.</span>}
      {hasQueries && isStaleData && !datesTooFarApart && SmallRequestButton}
      {!hasQueries && <span>Select a time range containing query logs in the chart above.</span>}
    </Typography>
  );

  const hasData = useRef(Boolean(driverLogAggregations.length));
  if (!getDriverLogsRes.isFetching) {
    hasData.current = Boolean(driverLogAggregations.length);
  }

  const columns = useDriverColumns(hasData.current, selectedTimeRange);
  const columnPrefs = useColumnPreferences('deprecations', 'deprecated-drivers', columns);

  const tableProps = useReactTable({
    columns,
    data: driverLogAggregations,
    initialState: {
      sorting: [
        { id: 'driverVersionStatus', desc: false },
        { id: 'client', desc: false },
      ],
      columnVisibility: {
        id: false,
      },
    },
    enableColumnPinning: true,
    state: {
      columnOrder: columnPrefs.prefs.columnOrder,
      columnVisibility: columnPrefs.prefs.columnVisibility,
      columnPinning: {
        right: [ACTIONS_COLUMN_ID],
      },
    },
    onColumnOrderChange: columnPrefs.onColumnOrderChange,
    onColumnVisibilityChange: columnPrefs.onColumnVisibilityChange,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    columnResizeMode: 'onChange',
  });

  return (
    <>
      {isRequestDialogOpen && (
        <RequestDialog
          isOpen={isRequestDialogOpen}
          toggleValues={toggleValues}
          hasQueries={hasQueries}
          timeRange={chartTimeRange}
          onClose={() => {
            setIsRequestDialogOpen(false);
          }}
          onAccept={(dialogToggleValues) => {
            setSelectedTimeRange({
              startTime: chartTimeRange.startTime,
              endTime: chartTimeRange.endTime,
            });
            setHasRequestedData(true);
            setToggleValues(dialogToggleValues);
            setIsRequestDialogOpen(false);
          }}
        />
      )}
      <div className="absolute right-0 top-0 m-1.5 flex flex-row flex-wrap items-center gap-2">
        <Button
          size="medium"
          color="primary"
          isDisabled={getDriverLogsRes.isFetching || datesTooFarApart}
          onClick={() => {
            setIsRequestDialogOpen(true);
          }}
          htmlAttributes={{
            'data-testid': 'request-log',
          }}
        >
          Fetch driver statistics
        </Button>
      </div>
      <DataGridHelpers.Wrapper tabbed className="table--full-height" style={{ maxHeight: '90vh' }}>
        <div className="relative w-full">
          <div className="mb-4">
            {getDriverLogsRes.error && (
              <ApiErrorBanner
                hasIcon
                title="Error while fetching logs"
                error={getDriverLogsRes.error}
                className="mt-4"
              />
            )}
            {hasRequestedData && <Banner className="mt-4" title={statusText} usage="inline" />}
          </div>
        </div>

        <DataGrid
          rootProps={{
            className: `[&_.ndl-data-grid-pinned-cell-right]:!grow [&_[role=columnheader].ndl-data-grid-pinned-cell-right]:border-l [&_[role=columnheader].ndl-data-grid-pinned-cell-right]:border-l-palette-neutral-border-weak ${classes['clean-actions-header']}`,
          }}
          styling={{ headerStyle: 'clean' }}
          isLoading={getDriverLogsRes.isFetching}
          isAutoResizingColumns={false}
          tableInstance={tableProps}
          components={{
            HeaderCell: ColumnPreferencesHeaderCell,
            BodyRow: DataGridHelpers.HoverBodyRow,
            NoDataPlaceholder: () => {
              let content: ReactNode = 'There are no queries within the selected time range.';
              if (hasRequestedData && !isStaleData) {
                content = 'No matches found for specified time range and filters.';
              } else if (datesTooFarApart) {
                content = "At most 24 hours' worth of logs can be fetched. Drag in the chart to narrow time window.";
              } else if (hasQueries) {
                content = <>Click {SmallRequestButton} to display driver statistics.</>;
              }
              return <CommonNoDataPlaceholder>{content}</CommonNoDataPlaceholder>;
            },
          }}
          isKeyboardNavigable={false}
        />
      </DataGridHelpers.Wrapper>
      <DriverLogsExpand aqlaUrl={aqlaUrl} />
    </>
  );
};
