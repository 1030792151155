import { APP_SCOPE } from '@nx/constants';
import { isNotNullish, setIdleTimeout } from '@nx/stdlib';
import type { ListenerEffectAPI } from '@reduxjs/toolkit';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import type { Neo4jDriverAdapter } from '../../adapters/neo4j-driver-adapter';
import * as Analytics from '../../services/analytics';
import * as ErrorTracking from '../../services/error-tracking';
import { type AppDispatch, type RootState } from '../../store';
import { selectCapability } from '../capabilities-slice';
import { selectToolConfigurations } from '../configuration/configuration-slice';
import { getCurrentScope } from '../configuration/selectors';
import { updateDataSummary } from '../data-summary/data-summary-slice';
import { updateMetadata } from '../metadata-slice';
import {
  ACTIVE_DATABASE_STORAGE_FIELD,
  CONNECTION_STATUS,
  CREDENTIALS_STORAGE_FIELD,
  connectDriver,
  disconnectDriver,
  interrupted,
  recovered,
  runQuery,
  selectDatabases,
  selectDefaultDatabases,
  selectHomeDatabase,
  switchedDatabase,
  updateDatabases,
  updateDbmsMetadata,
} from './connections-slice';
import { nxMetadata } from './constants';
import { assertLogicalDatabaseName } from './database.types';

export function createConnectionMiddleware({ driver }: { driver: Neo4jDriverAdapter }) {
  let healthCheckTimer: number | undefined = undefined;
  const cancelHealthCheckProbe = () => {
    if (healthCheckTimer !== undefined) {
      window.clearTimeout(healthCheckTimer);
    }
  };

  let dataSummaryPollingTimer: number | undefined = undefined;
  const cancelDataSummaryPolling = () => {
    if (dataSummaryPollingTimer !== undefined) {
      window.clearTimeout(dataSummaryPollingTimer);
    }
  };

  // Defined here so we can unregister the on visibilitychange listener
  let pollIfVisible: (() => void) | undefined = undefined;

  async function startHealthCheckProbe(listenerApi: ListenerEffectAPI<RootState, AppDispatch>) {
    const HEALTHY_CHECK_INTERVAL = 30_000;
    const UNHEALTHY_CHECK_INTERVAL = 3_000;

    cancelHealthCheckProbe();

    const state = listenerApi.getState();

    const ok = await driver.healthCheck();

    if (!ok && state.connections.status !== CONNECTION_STATUS.RECONNECTING) {
      void listenerApi.dispatch(interrupted());
    }

    if (ok && state.connections.status !== CONNECTION_STATUS.CONNECTED) {
      void listenerApi.dispatch(recovered());
    }

    if (ok && state.connections.status === CONNECTION_STATUS.CONNECTED) {
      void listenerApi.dispatch(updateDatabases({ metadata: nxMetadata }));
    }

    const checkInterval = ok ? HEALTHY_CHECK_INTERVAL : UNHEALTHY_CHECK_INTERVAL;

    healthCheckTimer = window.setTimeout(() => {
      void startHealthCheckProbe(listenerApi);
    }, checkInterval);
  }

  const middleware = createListenerMiddleware<RootState>();

  const startListening = middleware.startListening.withTypes<RootState, AppDispatch>();

  // Connection health
  startListening({
    actionCreator: connectDriver.fulfilled,
    effect(_, listenerApi) {
      void startHealthCheckProbe(listenerApi);
    },
  });

  startListening({
    actionCreator: disconnectDriver.pending,
    effect() {
      cancelHealthCheckProbe();
      cancelDataSummaryPolling();

      if (pollIfVisible !== undefined) {
        document.removeEventListener('visibilitychange', pollIfVisible);
      }
    },
  });

  // Connection persistence
  startListening({
    actionCreator: connectDriver.pending,
    effect() {
      window.sessionStorage.removeItem(CREDENTIALS_STORAGE_FIELD);
    },
  });

  startListening({
    actionCreator: connectDriver.fulfilled,
    effect() {
      const driverInfo = driver.getDriverConnectInfo();

      window.sessionStorage.setItem(CREDENTIALS_STORAGE_FIELD, JSON.stringify(driverInfo));
    },
  });

  startListening({
    actionCreator: disconnectDriver.pending,
    effect() {
      window.sessionStorage.removeItem(CREDENTIALS_STORAGE_FIELD);
      window.sessionStorage.removeItem(ACTIVE_DATABASE_STORAGE_FIELD);
    },
  });

  let cancelIdleTimeout: (() => void) | undefined = undefined;

  startListening({
    actionCreator: updateMetadata.fulfilled,
    effect(action, listenerApi) {
      if (action.payload.configuration === null) {
        return;
      }

      const respectDbmsConf = selectCapability(listenerApi.getState(), {
        key: 'framework:respect-dbms-neo4j-conf-settings',
      });

      if (respectDbmsConf) {
        const { credentialTimeoutMs } = action.payload.configuration;

        cancelIdleTimeout?.();

        if (credentialTimeoutMs > 0) {
          cancelIdleTimeout = setIdleTimeout(() => {
            window.sessionStorage.removeItem(CREDENTIALS_STORAGE_FIELD);
            void listenerApi.dispatch(disconnectDriver());
          }, credentialTimeoutMs);
        }
      }
    },
  });

  // Setup analytics and error tracking.
  // Network policies might come from the DBMS configuration.
  // We only respect them if 'framework:respect-dbms-neo4j-conf-settings' capability is enabled
  startListening({
    actionCreator: updateMetadata.fulfilled,
    effect(_, listenerApi) {
      void listenerApi.dispatch(Analytics.setupIfAllowed());
      ErrorTracking.setupIfAllowed();
    },
  });

  // update databases and current user on connection
  // also update metadata slice and register visibilitychange listener
  startListening({
    actionCreator: connectDriver.fulfilled,
    effect(action, listenerApi) {
      void listenerApi.dispatch(updateDbmsMetadata({ requestedDbName: action.payload.requestedDbName }));
      void listenerApi.dispatch(updateMetadata());

      if (pollIfVisible !== undefined) {
        document.removeEventListener('visibilitychange', pollIfVisible);
      }
      pollIfVisible = () => {
        const currentScope = getCurrentScope(
          selectToolConfigurations(listenerApi.getState().configuration),
          window.location,
        );
        const isInTool = currentScope !== null && [APP_SCOPE.explore, APP_SCOPE.query].includes(currentScope);

        if (document.visibilityState === 'visible' && isInTool) {
          void listenerApi.dispatch(updateDataSummary());
        }
      };
      document.addEventListener('visibilitychange', pollIfVisible);
    },
  });

  // update databases and current user if query made system updates
  startListening({
    actionCreator: runQuery.fulfilled,
    effect(action, listenerApi) {
      const { summary } = action.payload;
      if (summary.counters.systemUpdates() > 0) {
        void listenerApi.dispatch(updateDbmsMetadata());
      }

      const couldbeLoadingDataWithApoc = summary.query.text.toLowerCase().includes('apoc');
      if (summary.counters.containsUpdates() || couldbeLoadingDataWithApoc) {
        void listenerApi.dispatch(updateDataSummary());
      }
    },
  });

  startListening({
    actionCreator: updateDataSummary.fulfilled,
    effect() {
      // Everytime data summary is updated, cancel any scheduled poll
      // and schedule a new one in 30 seconds. This ensures we
      // poll at least every 30 seconds without the risk of spamming the
      // database with requests.
      cancelDataSummaryPolling();
      if (pollIfVisible !== undefined) {
        dataSummaryPollingTimer = window.setTimeout(pollIfVisible, 30_000);
      }
    },
  });

  // save active database name in session storage on database change
  // update data summary on database change
  startListening({
    predicate(_, currentState, previousState) {
      return currentState.connections.activeDatabaseName !== previousState.connections.activeDatabaseName;
    },
    effect(_, listenerApi) {
      void listenerApi.dispatch(updateDataSummary({ reset: true }));

      const { activeDatabaseName } = listenerApi.getState().connections;
      if (activeDatabaseName) {
        window.sessionStorage.setItem(ACTIVE_DATABASE_STORAGE_FIELD, activeDatabaseName);
      }
    },
  });

  // change active database on database list or current user update
  startListening({
    actionCreator: updateDbmsMetadata.fulfilled,
    effect(action, listenerApi) {
      const state = listenerApi.getState().connections;
      const { dispatch } = listenerApi;
      const { activeDatabaseName } = state;
      const homeDatabase = selectHomeDatabase(state);

      if (state.databases.ids.length === 0) {
        return;
      }

      // if active database is still available - do nothing
      if (activeDatabaseName && selectDatabases(state, activeDatabaseName).length > 0) {
        return;
      }

      const { requestedDbName } = action.payload;

      if (requestedDbName !== undefined) {
        assertLogicalDatabaseName(requestedDbName);

        if (selectDatabases(state, requestedDbName).length > 0) {
          dispatch(switchedDatabase(requestedDbName));
          return;
        }
      }

      const storedActiveDatabaseName = window.sessionStorage.getItem(ACTIVE_DATABASE_STORAGE_FIELD);
      if (storedActiveDatabaseName !== null) {
        assertLogicalDatabaseName(storedActiveDatabaseName);

        if (selectDatabases(state, storedActiveDatabaseName).length > 0) {
          dispatch(switchedDatabase(storedActiveDatabaseName));
          return;
        }
      }

      // try switch to user's home database
      if (isNotNullish(homeDatabase)) {
        assertLogicalDatabaseName(homeDatabase);

        if (selectDatabases(state, homeDatabase).length > 0) {
          dispatch(switchedDatabase(homeDatabase));
          return;
        }
      }

      // otherwise switch to first default database
      const defaultDatabases = selectDefaultDatabases(state);
      if (!defaultDatabases[0]) {
        throw new Error('No default databases found');
      }
      const defaultDatabaseName = defaultDatabases[0].name;

      dispatch(switchedDatabase(defaultDatabaseName));
    },
  });

  return middleware.middleware;
}
