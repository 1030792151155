import { Code, TextLink, Typography } from '@neo4j-ndl/react';
import { CheckIconOutline, ClipboardDocumentIconOutline } from '@neo4j-ndl/react/icons';
import { useState } from 'react';

const INDEX_QUERY = `CALL {
  CALL db.indexes()
  YIELD name, type, entityType, labelsOrTypes, properties
  WHERE type = "RANGE" OR type = "TEXT" OR type = "POINT"
  RETURN entityType, labelsOrTypes, properties
  UNION
  RETURN "" AS entityType, [] AS labelsOrTypes, [] AS properties
}
WITH collect({entityType: entityType, labelsOrTypes: labelsOrTypes, properties: properties}) as collectionRTPIndexes
CALL {
  CALL db.constraints()
  YIELD name
  RETURN collect(name) AS collectionConstraintNames
}
CALL db.indexes()
YIELD name AS oldName, type, entityType AS oldEntityType, labelsOrTypes AS oldLabelsOrTypes, properties AS oldProperties
WHERE NOT {entityType: oldEntityType, labelsOrTypes: oldLabelsOrTypes, properties: oldProperties} IN collectionRTPIndexes AND type = "BTREE"
RETURN oldName, oldName IN collectionConstraintNames as constraintBacking, type, oldEntityType, oldLabelsOrTypes, oldProperties`;

export const DeprecatedIndexTypes = () => {
  const [copying, setCopying] = useState(false);

  const copyCode = () => {
    setCopying(true);
    void navigator.clipboard.writeText(INDEX_QUERY);
    setTimeout(() => setCopying(false), 1500);
  };

  return (
    <div className="flex flex-col gap-2">
      <p>
        Before you can successfully migrate to Aura 5, you have to decide what happens to your existing BTREE indexes.
        BTREE indexes do not exist in Aura 5 anymore and therefore you can decide to create replacement indexes before
        the migration (pre-create) or have the migration process create them for you. These replacement indexes can
        co-exist alongside the old BTREE indexes until you finally migrate. The migration process will automatically
        remove the old ones. This also applies to constraints that are backed by BTREE indexes.
      </p>
      <p>
        Please be aware that pre-creating indexes needs additional disk space to be available. Make sure your instance
        has enough space left before creating the replacement indexes and resize your instance if required. The space
        used by the old indexes will be freed up after the migration process.
      </p>
      <p>
        You can also choose to not pre-create indexes. Please be aware that this will lead to a longer migration process
        as well as migrate all currently existing BTREE Indexes to RANGE indexes. Depending on your application and data
        model this might not be optimal for you.
      </p>
      <p>
        You can run the following Cypher query to find the names of indexes without a replacement.
        <Code
          className="m-4"
          language="cypher"
          code={INDEX_QUERY}
          actions={[
            {
              ariaLabel: 'copy',
              htmlAttributes: { title: 'copy' },
              children: copying ? <CheckIconOutline /> : <ClipboardDocumentIconOutline />,
              onClick: copyCode,
            },
          ]}
        />
      </p>
      <p>
        Indexes that are backing constraints have <Typography variant="code">constraintBacking: true</Typography> in the
        query output. Be aware that replacement indexes for them can only be RANGE indexes. Indexes of a different kind
        will not be correctly picked up by the migration process. This will lead to a longer migration time as well as
        an unused index afterwards.
      </p>
      <p>
        Please check{' '}
        <TextLink
          isExternalLink
          href="https://neo4j.com/docs/upgrade-migration-guide/current/version-5/migration/planning/#_prepare_indexes"
        >
          Prepare indexes
        </TextLink>{' '}
        on how this process works in detail, how to choose replacement indexes and what Cypher queries to run to create
        those.
      </p>
    </div>
  );
};
