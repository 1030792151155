import { tokens } from '@neo4j-ndl/base';
import { IconButton } from '@neo4j-ndl/react';
import { ChevronDownIconSolid } from '@neo4j-ndl/react/icons';
import type {
  InstanceSeamlessConnectionEnabled,
  ProjectDefaultSeamlessConnectionEnabled,
  ProjectSeamlessProfile,
} from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { ComponentPropsWithoutRef } from 'react';
import React, { useState } from 'react';

import type { SeamlessConfigState } from '../settings';
import {
  DefaultSeamlessConnectionCheckbox,
  InstanceSeamlessConnectionCheckbox,
  MultiInstanceSeamlessConnectionCheckbox,
} from './checkboxes';
import './project-seamless-connection-settings.css';

type Props = {
  projectSeamlessProfile: ProjectSeamlessProfile;
  state: SeamlessConfigState;
  updateState: ({
    instances,
    projects,
  }: {
    instances?: InstanceSeamlessConnectionEnabled[];
    projects?: ProjectDefaultSeamlessConnectionEnabled[];
  }) => void;
} & ComponentPropsWithoutRef<'div'>;

export const ProjectSeamlessConnectionSettings = ({ projectSeamlessProfile, state, updateState, ...rest }: Props) => {
  const canExpand = projectSeamlessProfile.instances.length > 0;
  const [expanded, setExpanded] = useState<boolean>(true);

  const isEnabledForSome = projectSeamlessProfile.instances
    .map((instance) => state.instances[instance.id])
    .some((v) => v);
  const isEnabledForAll = projectSeamlessProfile.instances
    .map((instance) => state.instances[instance.id])
    .every((v) => v);

  return (
    <div {...rest}>
      <div className="project-table rounded-lg py-2">
        <div className={expanded ? 'header-expanded pb-2' : ''}>
          <div className="flex flex-row items-center justify-between px-2">
            <MultiInstanceSeamlessConnectionCheckbox
              id={projectSeamlessProfile.id}
              name={projectSeamlessProfile.name}
              checked={isEnabledForSome}
              indeterminate={isEnabledForSome && !isEnabledForAll}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (isNotNullish(projectSeamlessProfile)) {
                  const updatedInstances: InstanceSeamlessConnectionEnabled[] = projectSeamlessProfile.instances.map(
                    (instance) => ({
                      dbId: instance.id,
                      seamlessConnectionEnabled: instance.immutable
                        ? instance.seamlessConnectionEnabled
                        : e.target.checked,
                    }),
                  );

                  updateState({ instances: updatedInstances });
                }
              }}
              disabled={projectSeamlessProfile.instances.length === 0}
              className="mx-2 my-1"
            />
            <div className={`flex max-w-[265px] flex-row items-center ${!canExpand && 'pr-[52px]'}`}>
              <DefaultSeamlessConnectionCheckbox
                name={projectSeamlessProfile.name}
                id={projectSeamlessProfile.id}
                checked={state.projects[projectSeamlessProfile.id] ?? false}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateState({
                    projects: [
                      { projectId: projectSeamlessProfile.id, defaultSeamlessConnectionEnabled: e.target.checked },
                    ],
                  })
                }
                className="m-2"
              />
              {canExpand && (
                <IconButton
                  isClean
                  className="mx-2"
                  onClick={() => setExpanded((prev) => !prev)}
                  ariaLabel={expanded ? 'Contract' : 'Expand'}
                  isActive={false}
                  htmlAttributes={{
                    'data-testid': `expand-${projectSeamlessProfile.id}`,
                  }}
                >
                  <ChevronDownIconSolid
                    className="size-4"
                    style={{
                      transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                      transitionDuration: tokens.transitions.values.duration.quick,
                    }}
                  />
                </IconButton>
              )}
            </div>
          </div>
        </div>
        {expanded &&
          projectSeamlessProfile.instances.map((instance) => (
            <InstanceSeamlessConnectionCheckbox
              id={instance.id}
              name={instance.name}
              immutable={instance.immutable}
              immutableReason={instance.immutableReason}
              checked={state.instances[instance.id] ?? false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateState({ instances: [{ dbId: instance.id, seamlessConnectionEnabled: e.target.checked }] })
              }
              key={instance.id}
              className="mx-2 my-4 px-2"
            />
          ))}
      </div>
    </div>
  );
};
