const AURA_URL_PATTERN = /\/\/([0-9a-f]+)(-.*)?\.(databases|production-orch-\d+)\.neo4j(-dev)?\.io/;

export const getAuraInstanceId = (url: string): string | undefined => {
  const match = url.match(AURA_URL_PATTERN);
  if (match === null) {
    return undefined;
  }
  const [, id] = match;
  return id;
};
