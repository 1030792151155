import { Button, Dialog } from '@neo4j-ndl/react';
import React from 'react';

interface Props {
  onAccept: () => void;
  onDecline: () => void;
}

export const DisclaimerDialogStep = ({ onAccept, onDecline }: Props) => {
  return (
    <>
      <Dialog.Content className="space-y-6">
        <p>
          Aura Database and Analytics services are business critical for our users. We have requests to introduce more
          capabilities enabling access to logs and metrics to derive actionable insights using your choice of monitoring
          platform.
        </p>
        <p>
          We have a strong roadmap of observability sharing features including security logs, query logs and other
          capabilities. Many of these logs can be of significant size hence we will introduce in the future a new
          consumption based billing model including cloud egress costs.
        </p>
        <p>
          We believe security is of paramount importance hence we have decided to make security logs available for you
          initially at no extra charge.
        </p>
      </Dialog.Content>
      <Dialog.Actions>
        <Button fill="outlined" color="neutral" onClick={onDecline}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={onAccept}
          htmlAttributes={{
            'data-testid': 'log-forwarding-disclaimer-accept',
          }}
        >
          Acknowledge
        </Button>
      </Dialog.Actions>
    </>
  );
};
