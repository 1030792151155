import {
  Banner,
  Button,
  DatePicker,
  IconButton,
  LoadingSpinner,
  Menu,
  Radio,
  Tag,
  TextInput,
  Typography,
} from '@neo4j-ndl/react';
import { FunnelIconOutline, MagnifyingGlassIconOutline } from '@neo4j-ndl/react/icons';
import type { Project, UsageBreakdownItem, UsageQueryParams, Usage as UsageType } from '@nx/state';
import { PLAN_TYPE, PROJECT_BILLING_METHOD, consoleApi } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import { DataGridHelpers } from '@nx/ui';
import { ControlPanel } from '@nx/ui/src/control-panel';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { addDays, format, subDays } from 'date-fns';
import type { SyntheticEvent } from 'react';
import { useRef, useState } from 'react';

import { formatDollars } from '../../../../utils';
import { downloadUsage } from '../../shared/helpers';
import { TotalConsumption } from './total-consumption-statement';

const columnHelper = createColumnHelper<UsageBreakdownItem>();

const columns = [
  columnHelper.accessor('name', {
    header: 'Instance',
    cell: (cx) => <DataGridHelpers.TruncatedColumn value={cx.getValue()} />,
  }),
  columnHelper.accessor('dbid', {
    header: 'ID',
    cell: (cx) => <DataGridHelpers.TruncatedColumn value={cx.getValue()} />,
  }),
  columnHelper.accessor('productName', {
    header: 'Product name',
    cell: (cx) => <DataGridHelpers.TruncatedColumn value={cx.getValue()} />,
  }),
  columnHelper.accessor('pricingPlan', {
    header: 'Pricing plan',
    cell: (cx) => <DataGridHelpers.TruncatedColumn value={cx.getValue()} />,
  }),
  columnHelper.accessor('endTime', {
    header: 'Billing status',
    cell: (cx) => {
      const value = cx.getValue();
      const status = value ? 'Ended' : 'Ongoing';
      return <DataGridHelpers.TruncatedColumn value={status} />;
    },
  }),
  columnHelper.accessor('consumptionUnits', {
    header: 'Usage',
    cell: (cx) => <DataGridHelpers.TruncatedColumn value={cx.getValue()} />,
  }),
  columnHelper.accessor('unitCostInDollars', {
    header: 'Unit price (GB/hour)',
    enableHiding: true,
    cell: (cx) => <DataGridHelpers.TruncatedColumn value={cx.getValue()} />,
  }),
  columnHelper.display({
    header: 'Unit of measure',
    cell: () => <DataGridHelpers.TruncatedColumn value="GB-hours" />,
  }),
  columnHelper.accessor('cost', {
    id: 'self-serve-cost',
    header: 'Amount',
    cell: (cx) => <DataGridHelpers.TruncatedColumn value={formatDollars(cx.getValue())} />,
    enableResizing: false,
    enableHiding: true,
  }),
  columnHelper.accessor('cost', {
    id: 'enterprise-consumption',
    header: 'Credits consumed',
    cell: (cx) => <DataGridHelpers.TruncatedColumn value={cx.getValue()} />,
    enableResizing: false,
    enableHiding: true,
  }),
];

type Props = {
  project: Project;
};

/** List of possible days to filter usage results by. */
const MAXIMUM_DAYS = 365;

enum DATE_FILTERS {
  LAST_24_HOURS = 'last24Hours',
  LAST_7_DAYS = 'last7Days',
  LAST_30_DAYS = 'last30Days',
  LAST_90_DAYS = 'last90Days',
  LAST_YEAR = 'lastYear',
  CUSTOM_RANGE = 'customRange',
}

const DATE_SELECTION_OPTIONS = [
  { label: 'Last 24 hours', value: DATE_FILTERS.LAST_24_HOURS, noDays: 1 },
  { label: 'Last 7 days', value: DATE_FILTERS.LAST_7_DAYS, noDays: 7 },
  { label: 'Last 30 days', value: DATE_FILTERS.LAST_30_DAYS, noDays: 30 },
  { label: 'Last 90 days', value: DATE_FILTERS.LAST_90_DAYS, noDays: 90 },
  { label: 'Last year', value: DATE_FILTERS.LAST_YEAR, noDays: 365 },
  { label: 'Custom range', value: DATE_FILTERS.CUSTOM_RANGE },
];

const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'Z'";

const getSortingKey = (id: string) => {
  switch (id) {
    case 'endTime':
      return 'end_time';
    case 'consumptionUnits':
      return 'consumption_units';
    case 'unitCostInDollars':
      return 'unit_cost_in_dollars';
    case 'costType':
      return 'cost_type';
    case 'self-serve-cost':
    case 'enterprise-consumption':
      return 'cost';
    case 'productName':
      return 'product_name';
    case 'pricingPlan':
      return 'pricing_plan';
    default:
      return id;
  }
};

type DateRange = [Date | null, Date | null];

export const UsageHistoryUsage = ({ project }: Props) => {
  const [dateRange, setDateRange] = useState<DateRange>([null, null]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [sorting, setSorting] = useState([{ id: 'name', desc: false }]);
  const [filter, setFilter] = useState('');
  const [usageFilters, setUsageFilters] = useState<UsageQueryParams>({});
  const defaultUsage: UsageType = {
    totalCost: '0',
    totalCostType: 'dollars',
    discount: undefined,
    breakdown: [],
    hasPricingPlanChange: false,
  };
  const { data: usage = defaultUsage, isLoading } = consoleApi.useGetUsageQuery(
    {
      projectId: project.id,
      ...usageFilters,
      // transform table state -> query params
      page: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      sortBy: isNotNullish(sorting[0]?.id) ? getSortingKey(sorting[0].id) : undefined,
      sortOrder: isNotNullish(sorting[0]?.desc) && sorting[0].desc ? 'desc' : 'asc',
      search: filter.trim().toLocaleLowerCase(),
    },
    { refetchOnMountOrArgChange: true },
  );
  const { totalCost, totalCostType, discount, breakdown = [], hasPricingPlanChange = false } = usage;
  const { planType } = project;
  const [showCostDisclaimerBanner, setShowCostDisclaimerBanner] = useState<boolean>(true);

  /**
   * Updates usage state given a diff.
   *
   * @param {UsageQueryParams} diff - Object of Usage Query Parameters to update or change.
   */
  const updateUsageFilters = (diff: UsageQueryParams) => {
    setUsageFilters((prevState) => {
      const newState = {
        ...prevState,
        ...diff,
      };
      return newState;
    });
  };

  // DATE FILTERING
  const now = new Date();
  const [filterSelection, setFilterSelection] = useState<DATE_FILTERS>();

  // Handles Opening and closing of menu
  const menuRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleDateOpen = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(true);
  };
  const handleDateClose = () => {
    setIsOpen(false);
  };

  const handleDateSubmit = () => {
    // We add 1 day to the endDate, so the full endDate is included in the Consumption range
    updateUsageFilters({
      startDate: dateRange[0]?.toISOString(),
      endDate: dateRange[1] ? addDays(dateRange[1], 1).toISOString() : undefined,
    });
    handleDateClose();
  };

  const buildTag = (): string => {
    const start = isNotNullish(usageFilters.startDate) ? format(usageFilters.startDate, DATE_FORMAT).split('T')[0] : '';
    const end = isNotNullish(usageFilters.endDate)
      ? format(subDays(usageFilters.endDate, 1), DATE_FORMAT).split('T')[0]
      : now.toISOString().split('T')[0];

    return `${start} - ${end}`;
  };

  // TABLE:
  const table = useReactTable({
    columns,
    initialState: {
      columnVisibility: {
        unitCostInDollars: project.planType === PLAN_TYPE.SELF_SERVE,
        'self-serve-cost': project.billingMethod === PROJECT_BILLING_METHOD.PAYG,
        'enterprise-consumption': project.billingMethod === PROJECT_BILLING_METHOD.PREPAID,
      },
    },
    data: breakdown,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onChange',
    // Pagination
    manualPagination: true,
    onPaginationChange: setPagination,
    pageCount:
      usage.totalBreakdownCount! % pagination.pageSize === 0
        ? usage.totalBreakdownCount! / pagination.pageSize
        : Math.ceil(usage.totalBreakdownCount! / pagination.pageSize),
    // Sorting
    manualSorting: true,
    enableSorting: !isLoading,
    onSortingChange: setSorting,
    // Filtering / Search
    manualFiltering: true,
    onGlobalFilterChange: setFilter,
    // State
    state: {
      pagination,
      sorting,
      globalFilter: filter,
      columnPinning: {
        right: ['enterprise-consumption', 'self-serve-cost'],
      },
    },
    defaultColumn: {
      minSize: 70,
    },
  });

  if (isLoading) {
    return (
      <ControlPanel className="flex justify-center rounded-tl-none">
        <LoadingSpinner size="large" />
      </ControlPanel>
    );
  }

  return (
    <>
      <DataGridHelpers.Wrapper tabbed>
        <DataGridHelpers.OuterHeader>
          <div className="flex w-full flex-wrap items-center gap-y-2">
            <div className="flex w-full flex-wrap items-center gap-2">
              <div className="mr-auto flex basis-[400px] gap-2">
                <TextInput
                  isFluid
                  className="min-w-36 grow"
                  leftElement={<MagnifyingGlassIconOutline />}
                  onChange={(event) =>
                    // Debounce searching
                    (event.target.value.length >= 3 || event.target.value.length === 0) && setFilter(event.target.value)
                  }
                  htmlAttributes={{
                    placeholder: 'Search',
                    'aria-label': 'Filter usage',
                  }}
                />

                <>
                  <IconButton
                    ariaLabel="Filter Reports"
                    onClick={handleDateOpen}
                    ref={menuRef}
                    isActive={isOpen}
                    htmlAttributes={{
                      title: 'Filter Reports',
                      'data-testid': 'filter-reports',
                    }}
                  >
                    <FunnelIconOutline aria-label="Filter Reports" />
                  </IconButton>
                  <Menu className="overflow-visible" isOpen={isOpen} anchorRef={menuRef} onClose={handleDateClose}>
                    <Menu.Header title="Filter" htmlAttributes={{ onClick: handleDateClose }} />
                    <Menu.Subheader title="Time Range" />
                    <Menu.Items>
                      {DATE_SELECTION_OPTIONS.map(({ label, value, noDays }) => {
                        return (
                          <div key={value} role="menuitem" className="ndl-menu-item">
                            <Radio
                              label={label}
                              ariaLabel={label}
                              isChecked={filterSelection === value}
                              isReadOnly
                              onClick={() => {
                                setFilterSelection(value);
                                if (value !== DATE_FILTERS.CUSTOM_RANGE) {
                                  setDateRange([subDays(new Date(), noDays!), null]);
                                }
                              }}
                            />
                          </div>
                        );
                      })}
                      <div
                        role="menuitem"
                        style={{ display: filterSelection !== DATE_FILTERS.CUSTOM_RANGE ? 'none' : 'block' }}
                        className="ndl-menu-item"
                      >
                        <DatePicker
                          isDisabled={filterSelection !== DATE_FILTERS.CUSTOM_RANGE}
                          textInputProps={{
                            htmlAttributes: {
                              'aria-label': 'Select a custom time range',
                            },
                          }}
                          reactDatePickerProps={{
                            minDate: subDays(now, MAXIMUM_DAYS),
                            maxDate: now,
                            startDate: dateRange[0],
                            endDate: dateRange[1],
                            selectsRange: true,
                            popperProps: {
                              strategy: 'fixed',
                            },
                            disabledKeyboardNavigation: true,
                            onChange: (dates: DateRange) => setDateRange(dates),
                          }}
                        />
                      </div>
                      <div role="menuitem" className="z-1 flex justify-end gap-x-2 px-3 py-5">
                        <Button onClick={handleDateClose} size="small" fill="outlined">
                          Cancel
                        </Button>
                        <Button onClick={handleDateSubmit} isDisabled={dateRange.every(isNullish)} size="small">
                          Apply
                        </Button>
                      </div>
                    </Menu.Items>
                  </Menu>
                </>
              </div>

              <div className="flex items-center gap-4">
                {isNotNullish(usageFilters.startDate) && (
                  <TotalConsumption totalCost={totalCost} currency={totalCostType} discount={discount} />
                )}
                {breakdown.length !== 0 && (
                  <Button fill="outlined" onClick={() => downloadUsage(usage)}>
                    Export
                  </Button>
                )}
              </div>
            </div>

            <div>
              {Object.keys(usageFilters).length > 0 && (
                <Tag
                  onRemove={() => {
                    setUsageFilters({});
                    setFilterSelection(undefined);
                  }}
                  isRemovable={true}
                >
                  {buildTag()}
                </Tag>
              )}
            </div>
          </div>
        </DataGridHelpers.OuterHeader>
        <DataGridHelpers.DataGridRightColumnPinned<UsageBreakdownItem>
          isResizable={true}
          tableInstance={table}
          styling={{ headerStyle: 'clean', borderStyle: 'horizontal' }}
        />
      </DataGridHelpers.Wrapper>
      {planType === PLAN_TYPE.ENTERPRISE && (
        <Typography variant="body-small" as="div" className="text-neutral-text-weaker mt-2">
          *Usage for secondaries is not included in this view.
        </Typography>
      )}
      {hasPricingPlanChange && showCostDisclaimerBanner && (
        <Banner
          type="warning"
          isCloseable
          onClose={() => setShowCostDisclaimerBanner(false)}
          className="mt-2"
          hasIcon
          usage="inline"
        >
          <p>
            The displayed data does not account for any change in pricing. Consumed credits are calculated based on the
            currently applied pricing contract of the project.
          </p>
        </Banner>
      )}
    </>
  );
};
