import type { APP_SCOPE } from '@nx/constants';
import * as SegmentJS from '@segment/analytics-next';
import * as Sentry from '@sentry/react';

import type { AnalyticsAdapter, AnalyticsTrackPayload } from './analytics-adapter';

// TODO: Remove once Explore is migrated away from `window.analytics`
declare global {
  interface Window {
    analytics: SegmentJS.AnalyticsBrowser;
  }
}

const PREFIX_BY_SCOPE: { [key in APP_SCOPE]: string } = {
  query: 'QRY',
  import: 'IMP',
  explore: 'BL',
  debugger: 'EXA',
  ops: 'OPS',
  framework: 'CON',
  guides: 'CON',
  aura: 'CON',
  test: 'TEST',
  desktop: 'DESK',
  dashboards: 'DASH',
  dataApi: 'DAPI',
  conversations: 'CONV',
};

export class SegmentNotFoundError extends Error {
  constructor() {
    super('Segment instance is unavailable');
    this.name = 'SegmentNotFoundError';
  }
}

export type SegmentAnalyticsAdapterConfig = {
  /** Segment API key */
  apiKey: string;
  /**
   * Event prefix override; ignores "scope" passed as part of tracked event
   *
   * Useful for standalone apps.
   */
  eventPrefix?: string;
};

/**
 * Analytics adapter for Segment Analytics.js
 */
export class SegmentAnalyticsAdapter<Event extends string> implements AnalyticsAdapter<Event> {
  private analytics: SegmentJS.AnalyticsBrowser;

  private plugins: SegmentJS.Plugin[] = [];

  constructor(private config: SegmentAnalyticsAdapterConfig) {
    const analytics = new SegmentJS.AnalyticsBrowser();
    this.analytics = analytics;
    window.analytics = analytics;
  }

  async init() {
    this.analytics.load({ writeKey: this.config.apiKey });

    if (this.plugins.length > 0) {
      await this.ready();
      await this.analytics.register(...this.plugins);
    }
  }

  async identify({ userId, orgId }: { userId: string; orgId: string | null }) {
    await this.ready();

    const user = await this.analytics.user();
    const existingId = user.id();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const existingOrgId = user.traits()?.orgId;

    if (userId !== existingId || (orgId !== existingOrgId && orgId !== null)) {
      await this.analytics.identify(userId, { orgId });

      // We also set the user ID in Sentry
      Sentry.setUser({ id: userId });
    }
  }

  async trackNavigation() {
    await this.ready();

    await this.analytics.page();
  }

  async trackEvent(payload: AnalyticsTrackPayload<Event, SegmentJS.EventProperties>) {
    await this.ready();

    const eventPrefix = this.config.eventPrefix ?? PREFIX_BY_SCOPE[payload.scope];
    const eventName = payload.event
      // Convert someName to some_Name
      .replace(/[a-z][A-Z]/g, (letters: string): string => `${letters[0] ?? ''}_${letters[1] ?? ''}`)
      .toUpperCase();

    await this.analytics.track(`${eventPrefix}_${eventName}`, payload.properties);
  }

  addPlugin(plugin: SegmentJS.Plugin) {
    this.plugins.push(plugin);
  }

  private async ready() {
    await this.analytics.ready();
  }
}
