import { tokens } from '@neo4j-ndl/base';
import { Accordion, Banner, LoadingSpinner, TextLink, Typography } from '@neo4j-ndl/react';
import type { SeriesSummary, TimeRange } from '@nx/state';
import { METRIC_TYPE, consoleApi, useOpsContext } from '@nx/state';
import { BackButton, Center, useDarkMode } from '@nx/ui';
import { skipToken } from '@reduxjs/toolkit/query';
import { some } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { datesWithin24H } from '../logs/shared/utils';
import type { ChartWidgetPartialProps } from '../metrics/charts/fullstack-chart-props';
import { IntChartConfig } from '../metrics/charts/helpers';
import { TimelineCard } from '../metrics/charts/line-cards';
import { QueryExecutionsSuccessRate } from '../metrics/charts/timeline-charts';
import { useMetricsQuery, useMetricsViewState } from '../metrics/hooks';
import { COLOR_PALETTE_ENUM, DARK_MODE_SHADE, LIGHT_MODE_SHADE } from '../metrics/shared/color-palettes';
import { TimePeriodAndRefresh } from '../metrics/shared/components/time-window-selector';
import { withUpxContext } from '../shared/components/upx-context';
import { metricKey } from '../shared/utils';
import { needsMigration } from './helpers';
import { toDisplayName } from './mappers';
import { deprecationMetricMetadata } from './metadata';
import './migration-assistant-page.css';
import { DeprecatedIndexTypes, DeprecationLogs, DriverLogs } from './migration-sections';
import { TestMigrationExpand } from './test-migration-expand';

const { allProps: allChartProps, pills: chartPills } = (() => {
  const leftProps: ChartWidgetPartialProps[] = deprecationMetricMetadata.map((m) => ({
    title: m.metricName,
    subtitle: m.metricName,
    yLabel: m.metricName,
    yAxisLabel: 'Deprecation occurrences',
    metricType: METRIC_TYPE.Gauge,
    metricName: m.metricName,
    chartType: 'HOST',
    aggregations: ['MAX'],
    chartPathType: 'LINE',
    colorPalette: COLOR_PALETTE_ENUM.ExcludingCountColor,
    ...IntChartConfig,
  }));
  const pills: SeriesSummary[] = [
    {
      id: 'Count',
      name: 'Queries per minute',
      displayName: 'Queries per minute',
      areaAlpha: 70,
    },
    ...deprecationMetricMetadata.map((m) => ({
      id: m.metricName,
      name: m.metricName,
      displayName: toDisplayName(m.metricName),
    })),
  ];

  return { allProps: [QueryExecutionsSuccessRate, ...leftProps], pills };
})();

const seriesNameMapper = (props: ChartWidgetPartialProps) => props.subtitle ?? '';

export const MigrationAssistantPage = () => {
  const isDarkTheme = useDarkMode();
  const pathParams = useParams<'projectId' | 'instanceId'>();
  const { projectId, instanceId } = pathParams;

  const { setSelectedInstance } = useOpsContext();
  useEffect(() => {
    // Set the instance state in case user navigates to QLA or Metrics
    setSelectedInstance(instanceId);
  }, [instanceId, setSelectedInstance]);

  const { data: instance, isLoading, error } = consoleApi.useGetInstanceQuery(instanceId ?? skipToken);

  const { chartContext, setTimePeriod, zoomIn, refreshTimeRange } = useMetricsViewState({
    timePeriod: '24H',
  });

  // TODO shared metric chart handling?
  const metricsResult = useMetricsQuery({
    chartProps: allChartProps,
    chartType: 'DB',
    timeRange: chartContext.timeRange,
    selectedInstanceDetails: instance,
    useAggregations: false,
    seriesNameMapper,
    database: '*',
  });

  const hasQueries = useMemo(() => {
    const dataPointsGreaterThan0 = (chartProps: ChartWidgetPartialProps) =>
      metricsResult.metrics &&
      some(metricsResult.metrics[metricKey(chartProps, false, instanceId)]?.dataSeries, (metrics) =>
        some(metrics, (metric) => (metric.value ?? 0) > 0),
      );
    return some(allChartProps, (chartProps) => dataPointsGreaterThan0(chartProps));
  }, [metricsResult.metrics, instanceId]);

  const onRefresh = useCallback(() => {
    refreshTimeRange();
  }, [refreshTimeRange]);

  const onZoom = useCallback(
    (zoomedTimeRange: TimeRange | null = null) => {
      zoomIn(zoomedTimeRange);
    },
    [zoomIn],
  );

  const onZoomReset = useCallback(() => {
    // Instantly zooms out since timeRangeDerived metrics are cached
    zoomIn(null);
  }, [zoomIn]);

  const [selectedTimelineAccordionItems, setSelectedTimelineAccordionItems] = useState([1]);
  const [selectedAccordionItems, setSelectedAccordionItems] = useState([1, 2, 3]);

  const datesTooFarApart = useMemo(
    () => !datesWithin24H(chartContext.timeRange.startTime, chartContext.timeRange.endTime),
    [chartContext.timeRange],
  );

  if (instance && !needsMigration(instance.desiredSettings.version)) {
    return (
      <Banner className="m-4" usage="inline">
        Instance does not need migration
      </Banner>
    );
  }

  if (isLoading) {
    return (
      <Center>
        <LoadingSpinner size="large" />
      </Center>
    );
  }

  if (error) {
    return (
      <Banner className="m-4" usage="inline">
        An error occurred fetching the instance
      </Banner>
    );
  }

  return (
    <div>
      <div className="h-full overflow-auto px-4 pt-4">
        <BackButton to="../" />
        <div className="justify-left mb-6 flex items-baseline gap-2 px-2 pt-2">
          <Typography variant="h2">Migration readiness report</Typography>
          <Typography variant="h3" className="font-normal">
            {instance?.name}
          </Typography>
          <TextLink isExternalLink href="https://neo4j.com/docs/aura/tutorials/upgrade/">
            Docs
          </TextLink>
        </div>
      </div>
      <div className="px-4">
        <div className="bg-palette-neutral-bg-weak mb-8 w-full rounded-3xl">
          <Accordion
            className="relative p-1"
            expandedItemIds={selectedTimelineAccordionItems}
            isMultiple
            onChange={setSelectedTimelineAccordionItems}
          >
            <div className="z-10 flex justify-end">
              <div className="absolute m-1.5 flex flex-row flex-wrap items-center gap-2">
                <TimePeriodAndRefresh
                  timePeriod={chartContext.timePeriod}
                  timeRange={chartContext.timeRange}
                  warningText={
                    datesTooFarApart
                      ? "At most 24 hours' worth of logs can be fetched. Drag in the chart to narrow time window."
                      : undefined
                  }
                  onTimePeriodChange={setTimePeriod}
                  onRefresh={onRefresh}
                />
              </div>
            </div>
            <Accordion.Item
              itemId={1 /* If 0 then it stops working */}
              className="accordion-item-no-padding borderless-accordion accordion-hover-rounded-3xl !border-b-0"
              title="Deprecations and query timeline"
            >
              <TimelineCard
                key="timeline-chart"
                context={chartContext}
                onZoom={onZoom}
                onZoomReset={onZoomReset}
                metricsResult={metricsResult}
                chartConfigs={allChartProps}
                mainChartConfigIdx={1}
                pills={chartPills}
                includeZeroValueSeries={[QueryExecutionsSuccessRate]}
                builderModifier={(builder) => {
                  builder.addExtraAxis({
                    label: QueryExecutionsSuccessRate.yAxisLabel,
                    stroke: isDarkTheme
                      ? tokens.colors.forest[DARK_MODE_SHADE]
                      : tokens.colors.forest[LIGHT_MODE_SHADE],
                  });

                  builder.series.forEach((series) => {
                    if (series.label === seriesNameMapper(QueryExecutionsSuccessRate)) {
                      series.scale = 'right';
                      series.points = { show: false };
                      series.fill =
                        typeof series.stroke === 'string' ? `${series.stroke}${chartPills[0]?.areaAlpha}` : series.fill;
                    }
                  });
                }}
                timeRangePadded
              />
            </Accordion.Item>
          </Accordion>
        </div>

        <div className="bg-palette-neutral-bg-weak w-full rounded-3xl">
          <Typography variant="body-medium" className="text-palette-neutral-text-weak flex flex-col gap-4 p-4" as="div">
            <p>
              This report page shows and advises you on anything that would stop or hinder from migrating to the latest
              Aura version. It provides information based on your current usage.
            </p>
            <p>
              The process to become &quot;Ready to migrate&quot; most likely includes manual changes such as replacing
              deprecated Cypher functionalities in your queries and updating older drivers and indexes. The following
              passages will help you with that in detail.
            </p>
          </Typography>

          <Accordion
            className="relative p-1"
            expandedItemIds={selectedAccordionItems}
            isMultiple
            onChange={setSelectedAccordionItems}
          >
            <Accordion.Item
              itemId={1}
              title="Cypher deprecations"
              className="accordion-item-no-padding borderless-accordion accordion-hover-rounded-3xl relative !border-b-0"
            >
              <DeprecationLogs
                chartTimeRange={chartContext.timeRange}
                hasDeprecations={hasQueries}
                selectedInstanceId={instanceId ?? ''}
              />
            </Accordion.Item>

            <Accordion.Item
              itemId={2}
              title="Deprecated driver usage"
              className="accordion-item-no-padding borderless-accordion accordion-hover-rounded-3xl relative !border-b-0"
            >
              <DriverLogs
                aqlaUrl={`/projects/${projectId}/logs?database=${instanceId}`}
                chartTimeRange={chartContext.timeRange}
                hasQueries={hasQueries}
                selectedInstanceId={instanceId ?? ''}
              />
            </Accordion.Item>

            <Accordion.Item
              itemId={3}
              title="Deprecated index types"
              className="accordion-item-no-padding borderless-accordion accordion-hover-rounded-3xl !border-b-0"
            >
              <DeprecatedIndexTypes />
            </Accordion.Item>
          </Accordion>
        </div>

        <div className="p-4">
          <TestMigrationExpand />
        </div>
      </div>
    </div>
  );
};

const MigrationAssistantPageWrapperWithUpxContext = withUpxContext(MigrationAssistantPage);

export default MigrationAssistantPageWrapperWithUpxContext;
