import consoleApi from '../slices/console/console.api';
import { type ACTION, type Permission } from '../slices/console/console.types';

// Copied over from the Aura Console
const checkPermission = (permission: Permission, action: ACTION, resource: string) => {
  if (permission.action !== action) {
    return false;
  }

  const permissionNodes = permission.resource.split('/');
  const requestNodes = resource.split('/');

  if (requestNodes.length > permissionNodes.length) {
    return false;
  }

  for (let i = 0; i < permissionNodes.length; i++) {
    if (permissionNodes[i] !== '*' && permissionNodes[i] !== requestNodes[i]) {
      return false;
    }
  }

  return true;
};

const covers = (permissions: Permission[], action: ACTION, resource: string) => {
  return permissions.some((permission: Permission) => checkPermission(permission, action, resource));
};

const allowFor = (permissions: Permission[]) => (action: ACTION, resource: string) => {
  return covers(permissions, action, resource);
};

const denyFor = (permissions: Permission[]) => (action: ACTION, resource: string) => {
  return !covers(permissions, action, resource);
};

export const usePermissions = (skip = false) => {
  const { data: user } = consoleApi.useGetUserDetailsQuery(undefined, {
    // refetch on every component mount, even if data is in cache
    // refetchOnMountOrArgChange: true,
    // refetch after n seconds, if we observe that
    // fetching on every mount is redundant or too heavy on the server
    // refetchOnMountOrArgChange: 25,
    skip,
  });

  return {
    isAllowed: allowFor(user?.permissions ?? []),
    isDenied: denyFor(user?.permissions ?? []),
  };
};
