import * as pkg from '../package.json';

export const APP_VERSION = pkg.version;

export const appStateError = {
  NO_ACCESS_TO_DB: 'NoAccessToDatabase',
};

export const securityError = {
  SECURITY_FORBIDDEN_ERROR: 'Neo.ClientError.Security.Forbidden',
};

export const PRIVILEGE_SHOW_INDEX = 'bloom_show_index';
export const PRIVILEGE_SHOW_CONSTRAINT = 'bloom_show_constraint';
export const MAX_HTTP_QUERY_API_LIMIT = 20000;
export const HTTP_PROTOCOLS = ['http:', 'https:'];
/**
 * Timeout
 */
export const ERROR_MESSAGE_TIMEOUT = 5000; // TODO check with designer
