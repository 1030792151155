import { ZoneId, ZoneOffset } from '@js-joda/core';
import { first, isEmpty, last } from 'lodash-es';

import { isDateTimeType, parseNumberToString, parseStringToNumber } from '../../services/temporal/utils';
import {
  DATE,
  DATETIME,
  LOCAL_DATETIME,
  LOCAL_TIME,
  MILLISECOND_PER_DAY,
  MILLISECOND_PER_HOUR,
  MILLISECOND_PER_SECOND,
  NANO_PER_DAY,
  NANO_PER_HOUR,
  NANO_PER_SECOND,
  TIME,
} from '../../services/temporal/utils.const';
import { AFTER, BEFORE, GREATER_THAN, LESS_THAN } from '../../state/perspectives/ruleEvaluators.const';
import { numericTypes } from '../../state/search/valueTypeUtils';

export const initializeConditionValue = (conditionValue, sortedNumericPropertyValues = [], type) => {
  let value = conditionValue;

  // initialize condition value to be the minimal value
  if (value === null && !isEmpty(sortedNumericPropertyValues)) {
    value = first(sortedNumericPropertyValues);

    if (isDateTimeType(type)) {
      value = parseNumberToString(value, type);
    }
  }

  return value;
};

export const initializeConditionValueRange = (conditionValueRange, sortedNumericPropertyValues = [], type) => {
  let value = conditionValueRange;

  // initialize condition value range to be the maximal value
  if (value === null && !isEmpty(sortedNumericPropertyValues)) {
    const max = last(sortedNumericPropertyValues);
    const minimalInterval = getMinimalInterval(type);
    // increase the max by minimal interval, so we can include all possible values
    value = max + minimalInterval;

    if (isDateTimeType(type)) {
      value = parseNumberToString(value, type);
    }
  }

  return value;
};

export const getMinimalInterval = (conditionPropertyType) => {
  switch (conditionPropertyType) {
    case DATE:
    case DATETIME:
    case LOCAL_DATETIME:
      // 1 day
      return 1;
    case TIME:
      // 1 hour
      return MILLISECOND_PER_HOUR;
    case LOCAL_TIME:
      // 1 hour
      return NANO_PER_HOUR;
    default:
      return 1;
  }
};

export const isComparablePropertyType = (basedOnType, propertyType) => {
  if (isDateTimeType(basedOnType)) {
    return propertyType === basedOnType;
  }

  if (numericTypes.includes(basedOnType)) {
    return numericTypes.includes(propertyType);
  }

  return false;
};

export const quantifyProperty = (property, basedOnType, options = {}) => {
  const { timeZoneOverride, isTimeZoneConvertEnabled } = options;
  const { type, value } = property || {};

  if (isDateTimeType(basedOnType)) {
    return parseStringToNumber(value, type, timeZoneOverride, isTimeZoneConvertEnabled);
  } else {
    return Number(value);
  }
};

export const mapTemporalConditionToNumberCondition = (condition) => {
  switch (condition) {
    case BEFORE:
      return LESS_THAN;
    case AFTER:
      return GREATER_THAN;
    default:
      return condition;
  }
};

export const getNumberToStringMapper = (type, timeZoneOverride, isTimezoneOverrideEnabled) => (value) => {
  let label;

  switch (type) {
    case DATE:
      return parseNumberToString(Math.trunc(value), type);
    case LOCAL_DATETIME:
      return parseNumberToString(Math.trunc(value), type, true);
    case DATETIME:
      // only display YYYY-MM-DD
      label = parseNumberToString(Math.trunc(value), type, true);

      if (isTimezoneOverrideEnabled) {
        const isTimeOffset = ZoneId.of(timeZoneOverride) instanceof ZoneOffset;
        let timeZoneOverrideString;
        if (isTimeOffset) {
          timeZoneOverrideString = timeZoneOverride === 'Z' ? '(Z)' : `(GMT${timeZoneOverride})`;
        } else {
          timeZoneOverrideString = `(${timeZoneOverride})`;
        }
        label = [label?.replace(timeZoneOverride, ''), timeZoneOverrideString];
      } else {
        label = [label?.replace('Z', ''), `(${LOCAL_DATETIME})`];
      }

      return label;
    case LOCAL_TIME:
      // make sure the max does not exceed 23:59:59.999999999
      const validLocalTime = Math.min(value, NANO_PER_DAY - 1);

      // only display HH:MM:SS
      const localTimeWithoutNanosecond = Math.trunc(validLocalTime / NANO_PER_SECOND) * NANO_PER_SECOND;

      return parseNumberToString(localTimeWithoutNanosecond, type);
    case TIME:
      // make sure the max does not exceed 23:59:59.999
      const validTime = Math.min(value, MILLISECOND_PER_DAY - 1);

      // only display HH:MM:SS
      const timeWithoutMillisecond = Math.trunc(validTime / MILLISECOND_PER_SECOND) * MILLISECOND_PER_SECOND;

      label = parseNumberToString(timeWithoutMillisecond, type, false, timeZoneOverride);

      if (isTimezoneOverrideEnabled) {
        const timeZoneOverrideString = timeZoneOverride === 'Z' ? '(Z)' : `(GMT${timeZoneOverride})`;
        label = [label?.replace(timeZoneOverride, ''), timeZoneOverrideString];
      } else {
        label = [label?.replace('Z', ''), `(${LOCAL_TIME})`];
      }

      return label;
    default:
      return value;
  }
};
