import { cloneDeep } from 'lodash-es';

import { compareVersions } from './versions/versionUtils';

export const FIRST_VERSION = '0.8.1';

const byVersion = (first, second) => compareVersions(first.version, second.version);

const migrate = (state, transforms, from = FIRST_VERSION) => {
  const startingState = cloneDeep(state);

  return transforms
    .sort(byVersion)
    .filter(({ version }) => compareVersions(version, from) > 0)
    .reduce((migration, { up }) => up(migration), startingState);
};

export default migrate;
