import type { CypherResult, Predicate } from '@neo4j/cypher-builder';
import Cypher, {
  List,
  Literal,
  Match,
  Node,
  Param,
  Pattern,
  Relationship,
  Return,
  Union,
  Unwind,
  Variable,
  all,
  and,
  any,
  concat,
  isNotNull,
  matches,
  startsWith,
  toLower,
  toString,
  type,
} from '@neo4j/cypher-builder';
import type { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { flatten, isEmpty, isNil, trim } from 'lodash-es';
import type { Record, RecordShape } from 'neo4j-driver';
import { v4 as uuidv4 } from 'uuid';

import { SEARCH_SUGGESTION_TIMEOUT_IN_MS } from '../../../../modules/SearchBar/SearchBar.const';
import { wrapInStartWithQueryRegexp } from '../../../../modules/SearchBar/SearchBar.utils';
import bolt from '../../../../services/bolt/bolt';
import { log } from '../../../../services/logging';
import type { Result } from '../../../../services/queries/summaryMapper';
import { getEstimatedRows } from '../../../../services/queries/summaryMapper';
import { DATE_TIME_TYPE } from '../../../../services/temporal/utils.const';
import type { GeneralPropertyKey } from '../../../../types/perspective';
import type { Nullable } from '../../../../types/utility';
import { readSearchTransaction } from '../../../search/readSearchTransaction';
import { suggestionVerificationQueryStopLimit } from '../../../search/structured/structuredSearch';
import type { RootState } from '../../../types';
import { clearRequests, createRequests } from '../../requests/search-requests';
import type { TextIndexArgument } from '../property-value-suggestions/property-value-suggestions.types';
import { shouldMatchForProperty } from '../property-value-suggestions/query/util';
import type {
  NodeEntryVerificationResult,
  Partition,
  PathEntryVerificationResult,
  SinglePathVerificationResult,
} from './structured-suggestions.types';
import { isNodeEntryVerificationResult, isRelationshipEntryVerificationResult } from './structured-suggestions.utils';
import { getCacheKeyFromPath, getKeyFromPartition } from './useVerificationResultsCache';

const verifyPathEntry = async (
  pathEntry: Partition,
  textIndexes: TextIndexArgument[],
  suggestionResultLimit: number,
  isCaseInsensitive: boolean,
  categoryToLabelDictionary: Map<string, string[]>,
  pathEntryVerificationResultsCache: Map<string, PathEntryVerificationResult>,
  requestId: string,
  labelPropertyKeys: RecordShape<string, GeneralPropertyKey[]>,
) => {
  const id = getKeyFromPartition(pathEntry);
  const cachedPathEntryVerificationResults = pathEntryVerificationResultsCache.get(id);

  if (!isNil(cachedPathEntryVerificationResults)) {
    return cachedPathEntryVerificationResults;
  }
  const { type, value } = pathEntry;

  let verificationCypher = null;

  if (type === 'Category') {
    verificationCypher = buildQueryToVerifyNode(
      value,
      isCaseInsensitive,
      suggestionResultLimit,
      categoryToLabelDictionary,
    );
  } else if (type === 'Relationship') {
    // since relationship type appears in perspective, it's deemed exist, no need to query
    return value;
  } else {
    verificationCypher = buildQueryToVerifyNodeTextIndexedPropertyValue(
      value.join(' '),
      textIndexes,
      suggestionResultLimit,
      isCaseInsensitive,
      labelPropertyKeys,
    );
  }
  const nodeEntryVerificationResults = await sendVerification(verificationCypher, requestId);

  if (isNil(nodeEntryVerificationResults) || isEmpty(nodeEntryVerificationResults.records)) {
    pathEntryVerificationResultsCache.set(id, []);
    return [];
  }
  const nodeEntryVerificationResultAsObjects = nodeEntryVerificationResults.records.map((record: Record) => {
    return record.toObject() as NodeEntryVerificationResult;
  });
  pathEntryVerificationResultsCache.set(id, nodeEntryVerificationResultAsObjects);

  return nodeEntryVerificationResultAsObjects;
};

export const buildQueryToVerifyNode = (
  pathEntry: string[],
  isCaseInsensitive: boolean,
  suggestionResultLimit: number,
  categoryToLabelDictionary: Map<string, string[]>,
) => {
  const categoryName = pathEntry.at(0) ?? '';
  const propertyName = pathEntry.at(1) ?? '';

  const labels = categoryToLabelDictionary.get(categoryName) ?? [];
  const node = new Node({ labels });

  const propertyConditionValueString = pathEntry.slice(2).join(' ');
  const canPerformPropertyCheck = !isEmpty(propertyName);
  const canPerformPropertyValueCheck = canPerformPropertyCheck && !isEmpty(propertyConditionValueString);

  if (canPerformPropertyValueCheck) {
    // toString(property) makes cypher slow, skip it if isCaseInsensitive is false
    const propertyValue = isCaseInsensitive
      ? toLower(toString(node.property(propertyName)))
      : node.property(propertyName);
    const propertyConditionValueParam = new Param(
      isCaseInsensitive ? propertyConditionValueString.toLowerCase() : propertyConditionValueString,
    );
    const propertyConditionValueStringParam = new Param(propertyConditionValueString);

    return new Match(node)
      .where(startsWith(propertyValue, propertyConditionValueParam))
      .return(
        [new Literal(labels), 'labels'],
        [new Literal(propertyName), 'propertyName'],
        [propertyConditionValueStringParam, 'propertyValue'],
        [new Literal(''), 'propertyType'],
      )
      .distinct()
      .limit(suggestionResultLimit)
      .build();
  } else if (canPerformPropertyCheck) {
    return new Match(node)
      .where(isNotNull(node.property(propertyName)))
      .return(
        [new Literal(labels), 'labels'],
        [new Literal(propertyName), 'propertyName'],
        [new Literal(''), 'propertyValue'],
        [new Literal(''), 'propertyType'],
      )
      .limit(1)
      .build();
  }
  // still need to query db, since this category could have no labels
  return new Match(node)
    .return(
      [new Literal(labels), 'labels'],
      [new Literal(''), 'propertyName'],
      [new Literal(''), 'propertyValue'],
      [new Literal(''), 'propertyType'],
    )
    .distinct()
    .limit(1)
    .build();
};

export const buildQueryToVerifyNodeTextIndexedPropertyValue = (
  propertyConditionValueString: string,
  textIndexes: TextIndexArgument[],
  suggestionResultLimit: number,
  isCaseInsensitive: boolean,
  labelPropertyKeys: RecordShape<string, GeneralPropertyKey[]>,
) => {
  if (isEmpty(textIndexes)) {
    return null;
  }
  const propertyConditionValueStringParam = new Param(propertyConditionValueString);
  const node = new Node();
  const queryParamStartsWithString = new Param(
    wrapInStartWithQueryRegexp(isCaseInsensitive, propertyConditionValueString),
  );
  const queryParamForArrayType = new Param(
    propertyConditionValueString
      .split(',')
      .map(trim)
      .map((value: string) => wrapInStartWithQueryRegexp(isCaseInsensitive, value)),
  );

  const texIndexQueries = textIndexes.reduce((acc: Return[], [label, propertyName, indexPropertyType = '']) => {
    const labelPropertyKey = labelPropertyKeys ? labelPropertyKeys[label] : undefined;
    const propertyType =
      indexPropertyType ??
      labelPropertyKey?.find((generalProperty: GeneralPropertyKey) => generalProperty.propertyKey === propertyName)
        ?.dataType ??
      '';
    const isTemporal = DATE_TIME_TYPE.includes(propertyType);
    const propertyValue = node.property(propertyName);

    if (shouldMatchForProperty(propertyConditionValueString, propertyType)) {
      const predicates: Predicate[] = [node.hasLabel(label)];
      if (propertyType === 'string') {
        predicates.push(
          isCaseInsensitive
            ? matches(propertyValue, queryParamStartsWithString)
            : startsWith(propertyValue, queryParamStartsWithString),
        );
      } else if (propertyType === 'array') {
        const variable = new Variable();
        const exprVariable = new Variable();

        predicates.push(
          all(
            variable,
            queryParamForArrayType,
            any(
              exprVariable,
              propertyValue,
              isCaseInsensitive
                ? matches(toString(exprVariable), variable)
                : startsWith(toString(exprVariable), variable),
            ),
          ),
        );
      } else if (isTemporal) {
        predicates.push(
          startsWith(
            toString(propertyValue),
            new Param(isCaseInsensitive ? propertyConditionValueString.toUpperCase() : propertyConditionValueString),
          ),
        );
      } else {
        predicates.push(startsWith(toString(propertyValue), new Param(propertyConditionValueString)));
      }

      acc = [
        ...acc,
        new Match(node)
          .where(and(...predicates))
          .return(
            [new Literal([label]), 'labels'],
            [new Literal(propertyName), 'propertyName'],
            [propertyConditionValueStringParam, 'propertyValue'],
            [new Literal(propertyType), 'propertyType'],
          )
          .distinct()
          .limit(suggestionResultLimit),
      ];
    }
    return acc;
  }, []);

  return new Union(...texIndexQueries).build();
};

const verifyPath = async (
  pathToVerify: Partition[],
  pathEntryVerificationResults: PathEntryVerificationResult[],
  isCaseInsensitive: boolean,
  relationshipToPropertyDictionary: Map<string, Set<string>>,
  pathVerificationResultsCache: Map<string, SinglePathVerificationResult[]>,
  requestId1: string,
  requestId2: string,
) => {
  const pathVerificationResultsKey = getCacheKeyFromPath(pathToVerify);
  const cachedPathVerificationResults = pathVerificationResultsCache.get(pathVerificationResultsKey);

  if (!isNil(cachedPathVerificationResults)) {
    return cachedPathVerificationResults;
  }
  // each path could become multiple verified path
  const verifiedPaths: SinglePathVerificationResult[] = [];
  const verifiedPathSofar: SinglePathVerificationResult = [];
  const relationshipTypes = [...relationshipToPropertyDictionary.keys()];
  const startIdx = 0;

  await verifyEntriesInPath(
    verifiedPaths,
    verifiedPathSofar,
    pathEntryVerificationResults,
    startIdx,
    isCaseInsensitive,
    relationshipTypes,
    requestId1,
    requestId2,
  );

  if (isEmpty(verifiedPaths)) {
    pathVerificationResultsCache.set(pathVerificationResultsKey, []);
    return [];
  }
  pathVerificationResultsCache.set(pathVerificationResultsKey, verifiedPaths);
  return verifiedPaths;
};

// backtrack to verify each path
const verifyEntriesInPath = async (
  verifiedPaths: SinglePathVerificationResult[],
  verifiedPathSofar: SinglePathVerificationResult,
  pathEntryVerificationResults: PathEntryVerificationResult[],
  idx: number,
  isCaseInsensitive: boolean,
  relationshipTypes: string[],
  requestId1: string,
  requestId2: string,
) => {
  if (idx === pathEntryVerificationResults.length) {
    verifiedPaths.push([...verifiedPathSofar]);
    return;
  }

  const pathEntryResults = pathEntryVerificationResults[idx];

  if (isRelationshipEntryVerificationResult(pathEntryResults)) {
    // relationship entry result
    verifiedPathSofar.push(pathEntryResults);

    await verifyEntriesInPath(
      verifiedPaths,
      verifiedPathSofar,
      pathEntryVerificationResults,
      idx + 1,
      isCaseInsensitive,
      relationshipTypes,
      requestId1,
      requestId2,
    );

    // backtrack
    verifiedPathSofar.pop();
  } else if (pathEntryResults) {
    // node entry results
    for (const nodeEntryResult of pathEntryResults) {
      const areNodeEntriesConnected = await verifyConnectionBetweenNodeEntries(
        verifiedPathSofar,
        nodeEntryResult,
        isCaseInsensitive,
        relationshipTypes,
        requestId1,
        requestId2,
      );
      if (!areNodeEntriesConnected) {
        continue;
      }

      verifiedPathSofar.push(nodeEntryResult);

      await verifyEntriesInPath(
        verifiedPaths,
        verifiedPathSofar,
        pathEntryVerificationResults,
        idx + 1,
        isCaseInsensitive,
        relationshipTypes,
        requestId1,
        requestId2,
      );

      // backtrack
      verifiedPathSofar.pop();
    }
  }
};

const verifyConnectionBetweenNodeEntries = async (
  verifiedPathSofar: SinglePathVerificationResult,
  nodeEntryResult: NodeEntryVerificationResult,
  isCaseInsensitive: boolean,
  relationshipTypes: string[],
  requestId1: string,
  requestId2: string,
) => {
  if (verifiedPathSofar.length === 0) {
    return true;
  }

  const pathToVerify = [...verifiedPathSofar, nodeEntryResult];
  const pathVerificationQuery = buildQueryForPathVerification(pathToVerify, isCaseInsensitive, relationshipTypes);
  const isPathVerificationFast = await explainPathVerification(pathVerificationQuery, requestId1);

  if (isPathVerificationFast) {
    const result = await sendVerification(pathVerificationQuery, requestId2);
    const hasRecords = !isEmpty(result?.records);
    return hasRecords;
  }
  log.debug('search 2.0', 'skipped expensive path verification: ', pathVerificationQuery);
  return true;
};

export const buildQueryForPathVerification = (
  path: SinglePathVerificationResult,
  isCaseInsensitive: boolean,
  relationshipTypes: string[],
) => {
  let pattern = null;
  const graphEntities = [];
  const conditionPredicates = [];

  for (const pathEntry of path) {
    if (isNodeEntryVerificationResult(pathEntry)) {
      const { labels, propertyName, propertyValue: propertyConditionValue } = pathEntry;
      const node = new Node({ labels });
      graphEntities.push(node);

      if (!isNil(propertyName) && propertyName !== '') {
        // toString(property) makes cypher slow, skip it if isCaseInsensitive is false
        const propertyValue = isCaseInsensitive
          ? toLower(toString(node.property(propertyName)))
          : node.property(propertyName);
        const propertyConditionValueParam = new Param(
          isCaseInsensitive ? propertyConditionValue.toLowerCase() : propertyConditionValue,
        );
        const conditionPredicate = startsWith(propertyValue, propertyConditionValueParam);
        conditionPredicates.push(conditionPredicate);
      }

      if (isNil(pattern)) {
        // the previous one is empty
        pattern = new Pattern(node);
      } else if (pattern instanceof Pattern) {
        // the previous one is a node
        const relationship = new Relationship();
        graphEntities.push(relationship);
        pattern = pattern.related(relationship).withDirection('undirected').to(node);

        const relationshipTypeList = new List(relationshipTypes.map((type) => new Literal(type)));
        const conditionPredicate = Cypher.in(type(relationship), relationshipTypeList);
        conditionPredicates.push(conditionPredicate);
      } else {
        // the previous one is a relationship
        pattern = pattern.to(node);
      }
    } else {
      const [relationshipType] = pathEntry;
      const relationship = new Relationship({ type: relationshipType });
      graphEntities.push(relationship);

      if (isNil(pattern)) {
        // the previous one is empty
        const node = new Node();
        graphEntities.push(node);
        pattern = new Pattern(node).related(relationship).withDirection('undirected');
      } else if (pattern instanceof Pattern) {
        // the previous one is a node
        pattern = pattern.related(relationship).withDirection('undirected');
      } else {
        // the previous one is a relationship
        const node = new Node();
        graphEntities.push(node);
        pattern = pattern.to(node).related(relationship).withDirection('undirected');
      }
    }
  }

  if (isNil(pattern)) {
    return null;
  }

  // add a node in the end
  // pattern is of type Pattern or PartialPattern, but PartialPattern is not exposed from lib
  if (!(pattern instanceof Pattern)) {
    const node = new Node();
    graphEntities.push(node);
    pattern = pattern.to(node);
  }

  const matchClause = new Match(pattern).where(and(...conditionPredicates));

  const graphEntity = new Variable();
  const unwindClause = new Unwind([new List(graphEntities), graphEntity]);
  const returnClause = new Return(graphEntity).distinct().limit(1);

  return concat(matchClause, unwindClause, returnClause).build();
};

const explainPathVerification = async (cypherResult: Nullable<CypherResult>, requestId: string) => {
  if (isNil(cypherResult)) {
    return false;
  }

  const { cypher, params: parameters } = cypherResult;
  const explainQuery = `EXPLAIN ${cypher}`;

  const result = (await readSearchTransaction(explainQuery, { parameters, requestId })) as Result;
  const estimatedRowsList = getEstimatedRows(result, suggestionVerificationQueryStopLimit);
  const isPathVerificationFast = estimatedRowsList?.every(
    (estimatedRows) => estimatedRows < suggestionVerificationQueryStopLimit,
  );

  return isPathVerificationFast ?? false;
};

const sendVerification = async (cypherResult: Nullable<CypherResult>, requestId: string) => {
  const { cypher, params: parameters } = cypherResult ?? {};

  if (isNil(cypher)) {
    log.debug('search 2.0', 'verification cypher is empty');
    return null;
  }

  return bolt.readTransaction(cypher, {
    parameters,
    timeout: SEARCH_SUGGESTION_TIMEOUT_IN_MS,
    requestId,
  });
};

export const verifyPaths = async (
  pathsToVerify: Partition[][],
  textIndexes: TextIndexArgument[],
  suggestionResultLimit: number,
  isCaseInsensitive: boolean,
  relationshipToPropertyDictionary: Map<string, Set<string>>,
  categoryToLabelDictionary: Map<string, string[]>,
  pathEntryVerificationResultsCache: Map<string, PathEntryVerificationResult>,
  pathVerificationResultsCache: Map<string, SinglePathVerificationResult[]>,
  labelPropertyKeys: RecordShape<string, GeneralPropertyKey[]>,
  dispatch: ThunkDispatch<RootState, unknown, UnknownAction>,
) => {
  const pathsVerifications: Promise<SinglePathVerificationResult[]>[] = [];
  const verifyPathsRequestIds: string[] = [];

  for (const pathToVerify of pathsToVerify) {
    const pathEntryVerifications: Promise<PathEntryVerificationResult>[] = [];

    for (const pathEntry of pathToVerify) {
      const requestId = uuidv4();
      verifyPathsRequestIds.push(requestId);
      const pathEntryVerification = verifyPathEntry(
        pathEntry,
        textIndexes,
        suggestionResultLimit,
        isCaseInsensitive,
        categoryToLabelDictionary,
        pathEntryVerificationResultsCache,
        requestId,
        labelPropertyKeys,
      );
      pathEntryVerifications.push(pathEntryVerification);
    }
    const pathEntryVerificationResults = await Promise.all(pathEntryVerifications);

    const requestId1 = uuidv4();
    const requestId2 = uuidv4();
    verifyPathsRequestIds.push(requestId1);
    verifyPathsRequestIds.push(requestId2);

    const pathVerifications = verifyPath(
      pathToVerify,
      pathEntryVerificationResults,
      isCaseInsensitive,
      relationshipToPropertyDictionary,
      pathVerificationResultsCache,
      requestId1,
      requestId2,
    );
    pathsVerifications.push(pathVerifications);
  }

  dispatch(createRequests(verifyPathsRequestIds));
  const pathsVerificationResults = await Promise.all(pathsVerifications);
  dispatch(clearRequests(verifyPathsRequestIds));

  return flatten(pathsVerificationResults);
};
