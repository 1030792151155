import { ClipboardButton } from '@neo4j-ndl/react';
import cn from 'classnames';
import type { PropsWithChildren } from 'react';
import { useState } from 'react';

export const CopyOnHoverColumn = ({
  value,
  children,
  className,
}: { value: string; className?: string } & PropsWithChildren) => {
  const [onHover, setOnHover] = useState(false);

  const classes = cn('truncate', className);

  return (
    <div
      className="flex w-full flex-row items-center gap-1.5"
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <div className={classes}>{children ?? value}</div>
      {onHover && <ClipboardButton size="small" textToCopy={value} />}
    </div>
  );
};
