import { isArray, isEmpty, isNil } from 'lodash-es';

import { SUGGESTION_ANY_NODE, SUGGESTION_ANY_RELATIONSHIP } from '../../../modules/SearchBar/SearchBar.const';
import {
  alignCase,
  alignCaseOrUndefined,
  isNodeSuggestion,
  isRelationshipSuggestion,
  isSearchPhraseSuggestion,
} from '../../../modules/SearchBar/SearchBar.utils';
import type { Suggestion } from '../../../modules/SearchBar/types';
import { FILTER, SUGGESTION_TYPE } from '../../../modules/SearchBar/types';
import { isCategorySuggestion, isNodePropertyEqualitySuggestion } from './search-suggestions.types';

export const filterSuggestions = (
  suggestions: (Suggestion | Suggestion[])[],
  filterText: string,
  selectedFilter?: string,
  isCaseInsensitive = false,
): (Suggestion | Suggestion[])[] => {
  return suggestions
    .filter((suggestion) => {
      if (!Array.isArray(suggestion)) {
        return true;
      }

      const isGraphPattern =
        suggestion.length === 2 &&
        suggestion.some((s) => s === SUGGESTION_ANY_NODE || s === SUGGESTION_ANY_RELATIONSHIP);
      // always return structured search suggestions, except for FILTER.SearchPhrase
      if (!isGraphPattern && selectedFilter !== FILTER.SearchPhrase) {
        return true;
      }

      const filteredArray = filterSuggestions(suggestion, filterText, selectedFilter, isCaseInsensitive);
      const hasOnlyStub =
        filteredArray.length === 1 &&
        (filteredArray[0] === SUGGESTION_ANY_NODE || filteredArray[0] === SUGGESTION_ANY_RELATIONSHIP);
      return filteredArray.length > 0 && !hasOnlyStub;
    })
    .filter((suggestion) => {
      if (isNil(selectedFilter) || isEmpty(selectedFilter)) {
        return true;
      }

      if (Array.isArray(suggestion)) {
        return true;
      }

      if (selectedFilter === FILTER.SearchPhrase) {
        return isSearchPhraseSuggestion(suggestion);
      } else if (selectedFilter === FILTER.Nodes) {
        return isNodeSuggestion(suggestion);
      } else if (selectedFilter === FILTER.Relationships) {
        return isRelationshipSuggestion(suggestion);
      } else if (selectedFilter === FILTER.Properties) {
        return (
          (isNodeSuggestion(suggestion) || isRelationshipSuggestion(suggestion)) && !isNil(suggestion.propertyName)
        );
      }

      return false;
    })
    .filter((suggestion) => {
      if (isNil(filterText) || isEmpty(filterText)) {
        return true;
      }

      if (Array.isArray(suggestion)) {
        return true;
      }

      const filterTextValue = alignCase(isCaseInsensitive, filterText);

      if (isNodeSuggestion(suggestion)) {
        const { categoryName, propertyName, propertyCondition, propertyConditionValue } = suggestion;
        const caseAlignedCategoryName = alignCase(isCaseInsensitive, categoryName);
        const caseAlignedPropertyName = alignCaseOrUndefined(isCaseInsensitive, propertyName);
        const caseAlignedPropertyCondition = alignCaseOrUndefined(isCaseInsensitive, propertyCondition);
        const caseAlignedPropertyConditionValue = alignCaseOrUndefined(isCaseInsensitive, propertyConditionValue);
        return (
          caseAlignedCategoryName.includes(filterTextValue) ||
          (caseAlignedPropertyName?.includes(filterTextValue) ?? false) ||
          (caseAlignedPropertyCondition?.includes(filterTextValue) ?? false) ||
          (caseAlignedPropertyConditionValue?.includes(filterTextValue) ?? false)
        );
      } else if (isRelationshipSuggestion(suggestion)) {
        const { relationshipType, propertyName, propertyCondition, propertyConditionValue } = suggestion;
        const caseAlignedRelationshipType = alignCase(isCaseInsensitive, relationshipType);
        const caseAlignedPropertyName = alignCaseOrUndefined(isCaseInsensitive, propertyName);
        const caseAlignedPropertyCondition = alignCaseOrUndefined(isCaseInsensitive, propertyCondition);
        const caseAlignedPropertyConditionValue = alignCaseOrUndefined(isCaseInsensitive, propertyConditionValue);

        return (
          caseAlignedRelationshipType.includes(filterTextValue) ||
          (caseAlignedPropertyName?.includes(filterTextValue) ?? false) ||
          (caseAlignedPropertyCondition?.includes(filterTextValue) ?? false) ||
          (caseAlignedPropertyConditionValue?.includes(filterTextValue) ?? false)
        );
      } else if (isSearchPhraseSuggestion(suggestion)) {
        const { description, displayText, text } = suggestion;
        const caseAlignedDescription = alignCase(isCaseInsensitive, description);
        const caseAlignedDisplayText = alignCase(isCaseInsensitive, displayText);
        const caseAlignedText = alignCase(isCaseInsensitive, text);

        return (
          caseAlignedDescription.includes(filterTextValue) ||
          caseAlignedDisplayText.includes(filterTextValue) ||
          caseAlignedText.includes(filterTextValue)
        );
      }

      return false;
    });
};

const getSortOrder = (suggestion: Suggestion | Suggestion[]) => {
  if (Array.isArray(suggestion)) {
    return 5;
  }
  switch (suggestion.type) {
    case SUGGESTION_TYPE.ACTION:
      return 1;
    case SUGGESTION_TYPE.SEARCH_PHRASE:
      return 2;
    case SUGGESTION_TYPE.NODE:
      return 3;
    case SUGGESTION_TYPE.RELATIONSHIP:
      return 4;
    case SUGGESTION_TYPE.FULL_TEXT_SEARCH:
      return 6;
    default:
      return 7;
  }
};

const typeComparator = (orderA: number, orderB: number) => {
  if (orderA === orderB) {
    return 0;
  }
  if (orderA > orderB) {
    return 1;
  }
  return -1;
};

const getCaseAlignedSuggestionTextValue = (
  isCaseInsensitiveSearch: boolean,
  suggestion: Suggestion | Suggestion[],
  typeGuard: typeof isSearchPhraseSuggestion | typeof isCategorySuggestion | typeof isNodePropertyEqualitySuggestion,
  textProp: string,
) =>
  alignCaseOrUndefined(
    isCaseInsensitiveSearch,
    !isArray(suggestion) && typeGuard(suggestion) ? suggestion[textProp as keyof Suggestion] : undefined,
  );

const exactMatchComparator = (input: string, valueA: string | undefined, valueB: string | undefined) => {
  if (valueA === input && valueB === input) {
    return 0;
  }
  if (valueA === input) {
    return -1;
  }
  if (valueB === input) {
    return 1;
  }
  return 0;
};

export const sortByExactMatchFirst = (
  suggestions: (Suggestion | Suggestion[])[],
  inputString: string,
  isCaseInsensitiveSearch: boolean,
) => {
  const input = alignCase(isCaseInsensitiveSearch, inputString.trim());

  return suggestions.toSorted((suggestionA, suggestionB) => {
    const searchPhraseA = getCaseAlignedSuggestionTextValue(
      isCaseInsensitiveSearch,
      suggestionA,
      isSearchPhraseSuggestion,
      'displayText',
    );
    const searchPhraseB = getCaseAlignedSuggestionTextValue(
      isCaseInsensitiveSearch,
      suggestionB,
      isSearchPhraseSuggestion,
      'displayText',
    );

    const categoryA = getCaseAlignedSuggestionTextValue(
      isCaseInsensitiveSearch,
      suggestionA,
      isCategorySuggestion,
      'categoryName',
    );
    const categoryB = getCaseAlignedSuggestionTextValue(
      isCaseInsensitiveSearch,
      suggestionB,
      isCategorySuggestion,
      'categoryName',
    );

    const propertyValueA = getCaseAlignedSuggestionTextValue(
      isCaseInsensitiveSearch,
      suggestionA,
      isNodePropertyEqualitySuggestion,
      'propertyConditionValue',
    );
    const propertyValueB = getCaseAlignedSuggestionTextValue(
      isCaseInsensitiveSearch,
      suggestionB,
      isNodePropertyEqualitySuggestion,
      'propertyConditionValue',
    );

    return (
      exactMatchComparator(input, searchPhraseA, searchPhraseB) ||
      exactMatchComparator(input, categoryA, categoryB) ||
      exactMatchComparator(input, propertyValueA, propertyValueB) ||
      typeComparator(getSortOrder(suggestionA), getSortOrder(suggestionB))
    );
  });
};
