import type {
  SysInfoCategorizedMetrics,
  SysInfoMappedMetrics,
  SysInfoMetricCategoryKey,
} from '@query/utils/sysinfo-utils';

const categorizedMetrics: Record<string, SysInfoCategorizedMetrics> = {};

export const cacheCategoryMetrics = (
  frameId: string,
  categoryKey: SysInfoMetricCategoryKey,
  mappedMetrics: SysInfoMappedMetrics,
) => {
  if (!categorizedMetrics[frameId]) {
    categorizedMetrics[frameId] = {
      'id-allocation': null,
      'store-size': null,
      'page-cache': null,
      transactions: null,
    };
  }

  const existingMetrics = categorizedMetrics[frameId][categoryKey]?.mappedMetrics;

  if (existingMetrics !== undefined) {
    Object.keys(mappedMetrics).forEach((key) => {
      if (mappedMetrics[key] !== undefined) {
        mappedMetrics[key].dirty = mappedMetrics[key].value !== existingMetrics[key]?.value;
      }
    });
  }

  categorizedMetrics[frameId][categoryKey] = { mappedMetrics };
};

export const getCategorizedMetrics = (frameId: string) => categorizedMetrics[frameId];

export const clearCategorizedMetrics = (frameId: string) => delete categorizedMetrics[frameId];
