import type {
  InviteUserToProjectRequest,
  InviteeProjectsWithAffectedInstancesResponse,
  ProjectInviteDetailsResponse,
  ProjectInviteResponse,
  TransformedInviteUserToProjectBody,
  TransformedUpdateProjectInviteBody,
  UpdateProjectInviteRequest,
} from '../console.api.types';
import type { InviteeProjectsWithAffectedInstances, ProjectInvite, ProjectInviteDetails } from '../console.types';

export const transformProjectInviteResponse = (response: ProjectInviteResponse): ProjectInvite => ({
  id: response.invite_id,
  status: response.status,
  projectId: response.namespace_id,
  roles: response.roles,

  invitedBy: response.invited_by,
  invitedAt: response.invited_at,
  expiresAt: response.expires_at,

  email: response.email,
  userId: response.user_id,
  subjectId: response.subject_id,

  acceptedAt: response.accepted_at,
  revokedAt: response.revoked_at,
  declinedAt: response.declined_at,
});

const transformInviteeProjectsWithAffectedProject = (
  res: InviteeProjectsWithAffectedInstancesResponse,
): InviteeProjectsWithAffectedInstances => ({
  friendlyName: res.friendly_name,
  projectId: res.tenant_id,
});

export const transformProjectInviteDetailsResponse = (
  response: ProjectInviteDetailsResponse,
): ProjectInviteDetails => ({
  id: response.invite_id,
  email: response.email,
  projectId: response.namespace_id,
  projectName: response.friendly_name,
  invitedBy: response.invited_by,
  planType: response.plan_type,
  roles: response.roles,
  hasRunningInstance: response.user_has_running_instances,
  inviteeProjectsWithAffectedInstances:
    response.invitee_tenants_with_affected_instances?.map((affectedProject) =>
      transformInviteeProjectsWithAffectedProject(affectedProject),
    ) ?? [],
});

export const transformUpdateProjectInviteRequest = (
  request: UpdateProjectInviteRequest,
): TransformedUpdateProjectInviteBody => ({
  Roles: request.roles,
});

export const transformInviteUserToProjectRequest = (
  request: InviteUserToProjectRequest,
): TransformedInviteUserToProjectBody => ({
  NamespaceId: request.projectId,
  Roles: request.roles,
  Email: request.email,
});
