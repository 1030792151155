import React from 'react'

import { DATE_TIME_TYPE } from '../../../../services/temporal/utils.const'
import { Typography } from '@neo4j-ndl/react';

// TODO: enable remaining Neo4j data types: 'DateTime', 'Time', 'Duration', 'Point', 'array'
export const ruleBaseTypes = ['string', 'bigint', 'number', 'boolean', 'other', ...DATE_TIME_TYPE]

const ruleConditions = [
  {
    key: 'equals-condition',
    supportedDataTypes: ['string', 'bigint', 'number', 'Point', 'Duration', ...DATE_TIME_TYPE],
    supportedEntryType: ['category', 'relationship'],
    text: 'equals',
    value: 'equals'
  },
  {
    key: 'not-equals-condition',
    supportedDataTypes: ['string', 'bigint', 'number', 'Point', 'Duration', ...DATE_TIME_TYPE],
    supportedEntryType: ['category', 'relationship'],
    text: 'does not equal',
    value: 'not-equals'
  },
  {
    key: 'greater-than-condition',
    supportedDataTypes: ['bigint', 'number', 'Duration'],
    supportedEntryType: ['category', 'relationship'],
    text: 'greater than',
    value: 'greater-than'
  },
  {
    key: 'less-than-condition',
    supportedDataTypes: ['bigint', 'number', 'Duration'],
    supportedEntryType: ['category', 'relationship'],
    text: 'less than',
    value: 'less-than'
  },
  {
    key: 'greater-than-or-equal-to-condition',
    supportedDataTypes: ['bigint', 'number', 'Duration'],
    supportedEntryType: ['category', 'relationship'],
    text: 'greater than or equal to',
    value: 'greater-than-or-equal-to'
  },
  {
    key: 'less-than-or-equal-to-condition',
    supportedDataTypes: ['bigint', 'number', 'Duration'],
    supportedEntryType: ['category', 'relationship'],
    text: 'less than or equal to',
    value: 'less-than-or-equal-to'
  },
  {
    key: 'between-condition',
    supportedDataTypes: ['bigint', 'number', 'Duration', ...DATE_TIME_TYPE],
    supportedEntryType: ['category', 'relationship'],
    text: 'between',
    value: 'between'
  },
  {
    key: 'not-between-condition',
    supportedDataTypes: ['bigint', 'number', 'Duration', ...DATE_TIME_TYPE],
    supportedEntryType: ['category', 'relationship'],
    text: 'not between',
    value: 'not-between'
  },
  {
    key: 'before',
    supportedDataTypes: DATE_TIME_TYPE,
    supportedEntryType: ['category', 'relationship'],
    text: 'before',
    value: 'before'
  },
  {
    key: 'after',
    supportedDataTypes: DATE_TIME_TYPE,
    supportedEntryType: ['category', 'relationship'],
    text: 'after',
    value: 'after'
  },
  {
    key: 'contains-condition',
    supportedDataTypes: ['string', 'array'],
    supportedEntryType: ['category', 'relationship'],
    text: 'contains',
    value: 'contains'
  },
  {
    key: 'starts-with-condition',
    supportedDataTypes: ['string'],
    supportedEntryType: ['category', 'relationship'],
    text: 'starts with',
    value: 'starts-with'
  },
  {
    key: 'ends-with-condition',
    supportedDataTypes: ['string'],
    supportedEntryType: ['category', 'relationship'],
    text: 'ends with',
    value: 'ends-with'
  },
  {
    key: 'is-true',
    supportedDataTypes: ['boolean'],
    supportedEntryType: ['category', 'relationship'],
    text: 'is true',
    value: 'is-true'
  },
  {
    key: 'is-false',
    supportedDataTypes: ['boolean'],
    supportedEntryType: ['category', 'relationship'],
    text: 'is false',
    value: 'is-false'
  },
  {
    key: 'has-label',
    supportedDataTypes: ['other'],
    supportedEntryType: ['category'],
    text: 'has label',
    value: 'has-label'
  },
  {
    key: 'does-not-have-label',
    supportedDataTypes: ['other'],
    supportedEntryType: ['category'],
    text: 'does not have label',
    value: 'does-not-have-label'
  },
  {
    key: 'has-property',
    supportedDataTypes: ['other'],
    supportedEntryType: ['category', 'relationship'],
    text: 'has property',
    value: 'has-property'
  },
  {
    key: 'does-not-have-property',
    supportedDataTypes: ['other'],
    supportedEntryType: ['category', 'relationship'],
    text: 'does not have property',
    value: 'does-not-have-property'
  }
]

export const getBaseTypeSuffixFromRuleBase = (basedOn) => {
  if (!basedOn || !basedOn.length) return
  const splits = basedOn.split('_')
  if (splits.length < 2) {
    return
  }
  const dataType = splits[splits.length - 1]
  if (ruleBaseTypes.indexOf(dataType) === -1) {
    return
  }
  return dataType
}

export const getTitleFromRuleBase = (basedOn) => {
  if (!basedOn || !basedOn.length) return
  const splits = basedOn.split('_')
  if (splits.length < 2) {
    return
  }
  const title = splits.slice(0, splits.length - 1)
  return title.length > 1 ? title.join('_') : title[0]
}

export const getValidConditionsForBaseType = (baseType, entryType) =>
  ruleConditions
    .filter(
      (condition) => condition.supportedDataTypes.includes(baseType) && condition.supportedEntryType.includes(entryType)
    )
    .map((option) => ({
      key: option.key,
      text: option.text,
      value: option.value,
      label: <span data-testid="style-condition-rule-base">{option.text}</span>
    }))

export const getFilterRuleConditionsForDataType = (dataType = 'number') =>
  ruleConditions
    .filter((condition) => condition.supportedDataTypes.includes(dataType))
    .map((option) => ({
      key: option.key,
      label: (
        <Typography variant="body-medium" htmlAttributes={{
          "data-testid": "filter-dropdown-text"
        }}>
          {option.text}
        </Typography>
      ),
      value: option.value
    }))

export const getConditionBasedOnPropertyType = (type) => {
  if (!type) {
    return []
  }

  return ruleConditions
    .filter((condition) => condition.supportedDataTypes.includes(type))
    .map((condition) => condition.text)
}
