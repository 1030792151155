import type { BannerProps } from '@neo4j-ndl/react';
import { Banner, TextLink, Typography } from '@neo4j-ndl/react';
import type { Project } from '@nx/state';
import { MODAL_TYPE, PROJECT_TYPE, consoleApi, useActiveProjectQuery, useModal } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import cn from 'classnames';
import hash from 'object-hash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ExtendTrialModal } from '../extend-trial';
import {
  dismissProjectNotification,
  getDismissedProjectNotifications,
  mapNotificationLevelToBannerType,
  substituteDates,
} from './helpers';
import { getActionsForNotification } from './project-notification-action';

type NotificationBannerProps = Omit<Extract<BannerProps, { usage: 'global' }>, 'onClose' | 'usage' | 'isCloseable'> & {
  maxWidth?: number;
  'data-testid'?: string;
  onClose?: () => void;
  isVisible?: boolean;
};

const NotificationBanner = ({
  type,
  title,
  'data-testid': dataTestId,
  maxWidth,
  children,
  onClose,
  ...extra
}: NotificationBannerProps) => {
  const [show, setShow] = useState(true);
  const classes = cn('opacity-0 transition-[opacity] duration-500 ease-linear', { 'opacity-100': show });
  const handleClose = () => {
    setShow(false);

    setTimeout(() => {
      if (isNotNullish(onClose)) {
        onClose();
      }
    }, 250);
  };
  return (
    <Banner
      className={classes}
      hasIcon
      type={type}
      title={title}
      style={{ maxWidth }}
      {...extra}
      {...(isNotNullish(onClose) ? { onClose: handleClose, isCloseable: true } : { isClosable: false })}
      usage="global"
      htmlAttributes={{
        'data-testid': dataTestId,
      }}
    >
      <Typography variant="body-medium" as="div">
        {children}
      </Typography>
    </Banner>
  );
};

const ProjectSuspendedNotification = ({ children, maxWidth, ...rest }: NotificationBannerProps) => {
  return (
    <NotificationBanner
      type="danger"
      title="Project suspended"
      data-testid="project-suspended-alert-banner"
      maxWidth={maxWidth ?? 620}
      {...rest}
    >
      {children}
    </NotificationBanner>
  );
};

type UnpaidInvoiceNotificationProps = {
  onClose: () => void;
  isVisible: boolean;
  project: Project;
};

export const UnpaidInvoiceNotification = ({ onClose, project, isVisible }: UnpaidInvoiceNotificationProps) => {
  const navigate = useNavigate();
  const { hasUnpaidInvoices, id } = project;
  if (isNullish(hasUnpaidInvoices) || !hasUnpaidInvoices || !isVisible) {
    return null;
  }

  return (
    <NotificationBanner
      title="Unpaid invoice"
      actions={[
        {
          as: 'button',
          label: 'Pay Invoice',
          onClick: (e) => {
            e.preventDefault();
            navigate(`/projects/${id}/billing/invoices`);
          },
        },
        {
          label: 'Contact Support',
          href: 'https://support.neo4j.com/s/',
          target: '_blank',
        },
      ]}
      type="warning"
      onClose={onClose}
      maxWidth={620}
      data-testid="project-unpaid-invoices-alert-banner"
    >
      We have been unable to collect payment for your latest invoice. Please update your card details and/or pay the
      invoice to avoid service disruption. This project will be suspended 7 days after the invoice due date.
    </NotificationBanner>
  );
};

type ProjectNotificationProps = {
  project: Project;
};

const ProjectNotifications = ({ project }: ProjectNotificationProps) => {
  const { id } = project;
  const { data: notifications } = consoleApi.useGetProjectNotificationsQuery(id, {
    pollingInterval: 30000,
  });
  const navigate = useNavigate();
  const shownNotificationsStorage = getDismissedProjectNotifications();
  const [shownNotifications, setShownNotification] = useState(shownNotificationsStorage);
  const [openExtendTrial, setOpenExtendTrial] = useState(false);
  const modal = useModal(MODAL_TYPE.INSTANCE_CREATE);
  const callbacks = {
    onOpenExtendTrial: () => setOpenExtendTrial(true),
    onOpenCreateTrial: () => {
      modal.open();
    },
  };

  return (
    <>
      {notifications
        ?.filter((n) => !(hash(n) in shownNotifications))
        .map((notification) => (
          <NotificationBanner
            key={hash(notification)}
            title={substituteDates(notification.title, notification.dates)}
            actions={getActionsForNotification(notification, id, callbacks, navigate).map((action) => ({
              label: action.label,
              onClick: () => {
                action.onClick();
                setShownNotification((prev) => {
                  prev[hash(notification)] = new Date().getTime() + notification.dismissalPeriod;
                  return prev;
                });
              },
            }))}
            type={mapNotificationLevelToBannerType(notification.level)}
            onClose={() => {
              dismissProjectNotification(notification);
              setShownNotification((prev) => {
                prev[hash(notification)] = new Date().getTime() + notification.dismissalPeriod;
                return prev;
              });
            }}
            maxWidth={620}
          >
            {substituteDates(notification.message, notification.dates)}
          </NotificationBanner>
        ))}
      {openExtendTrial && <ExtendTrialModal onClose={() => setOpenExtendTrial(false)} project={project} />}
    </>
  );
};

export const TypeSpecificSuspendedNotification = ({ project }: { project: Project }) => {
  const navigate = useNavigate();

  if (!project.suspended) {
    return null;
  }

  switch (project.projectType) {
    case PROJECT_TYPE.PERSONAL:
      return (
        <ProjectSuspendedNotification
          actions={[
            {
              as: 'button',
              label: 'Pay invoice',
              onClick: (e) => {
                e.preventDefault();
                navigate(`/projects/${project.id}/billing/invoices`);
              },
            },
          ]}
        >
          This project is suspended due to non-payment. Please finalize any overdue invoices. Your instances will be
          deleted 30 days after suspension if payment is not made.
        </ProjectSuspendedNotification>
      );

    case PROJECT_TYPE.N4GCP:
      return (
        <ProjectSuspendedNotification>
          The GCP project linked to this project has been deleted or moved into a new account without an active Neo4j
          Aura subscription. Please contact{' '}
          <TextLink href="https://support.neo4j.com/s/" isExternalLink>
            Aura Support
          </TextLink>{' '}
          if you believe this is a mistake.
        </ProjectSuspendedNotification>
      );

    case PROJECT_TYPE.MARKETPLACE_AWS:
    case PROJECT_TYPE.MARKETPLACE_AZURE:
      return (
        <ProjectSuspendedNotification>
          The marketplace project linked to this project has been deleted or moved into a new account without an active
          Neo4j Aura subscription. Please contact{' '}
          <TextLink href="https://support.neo4j.com/s/" isExternalLink>
            Aura Support
          </TextLink>{' '}
          if you believe this is a mistake.
        </ProjectSuspendedNotification>
      );

    case PROJECT_TYPE.ENTERPRISE:
      return (
        <ProjectSuspendedNotification>
          Your project has been suspended. Please contact{' '}
          <TextLink href="https://support.neo4j.com/s/" isExternalLink>
            Aura Support
          </TextLink>{' '}
          urgently if you believe this is a mistake.
        </ProjectSuspendedNotification>
      );

    default:
      return null;
  }
};

export const Notifications = () => {
  const { data: project } = useActiveProjectQuery();
  const [invoiceNotificationVisible, setInvoiceNotificationVisible] = useState(project?.hasUnpaidInvoices);

  useEffect(() => {
    if (isNotNullish(invoiceNotificationVisible)) {
      return;
    }
    if (isNullish(invoiceNotificationVisible) && isNotNullish(project?.hasUnpaidInvoices)) {
      setInvoiceNotificationVisible(project.hasUnpaidInvoices);
    }
  }, [invoiceNotificationVisible, project]);

  if (
    isNullish(project) ||
    isNullish(invoiceNotificationVisible) ||
    !location.pathname.startsWith(`/projects/${project.id}`) ||
    location.pathname.startsWith(`/projects/${project.id}/billing`)
  ) {
    return null;
  }

  return (
    <div className="fixed right-2 top-14 z-20 flex flex-col gap-2">
      <TypeSpecificSuspendedNotification project={project} />
      <UnpaidInvoiceNotification
        project={project}
        onClose={() => setInvoiceNotificationVisible(false)}
        isVisible={!project.suspended && invoiceNotificationVisible}
      />
      <ProjectNotifications project={project} />
    </div>
  );
};
