import { IconButton, StatusIndicator } from '@neo4j-ndl/react';
import { EnvelopeIconOutline } from '@neo4j-ndl/react/icons';
import { MODAL_TYPE, useModal } from '@nx/state';

export const PendingInviteButton = ({ showStatusIndicator }: { showStatusIndicator: boolean }) => {
  const inviteModal = useModal(MODAL_TYPE.INVITES);
  const openModal = () => {
    inviteModal.open();
  };

  return (
    <>
      <IconButton
        isClean
        ariaLabel="Pending invites"
        onClick={openModal}
        className="relative"
        htmlAttributes={{
          'data-testid': 'pending-invites-button',
        }}
      >
        <EnvelopeIconOutline />
        {showStatusIndicator && <StatusIndicator className="absolute -right-0.5 top-0.5" type="info" />}
      </IconButton>
    </>
  );
};
