import { Button, Dialog, Dropzone, ProgressBar, TextLink } from '@neo4j-ndl/react';
import type { Instance, Project } from '@nx/state';
import { ACTION, INSTANCE_STATUS, TIER, usePermissions, useUploadContext, useUploadDump } from '@nx/state';
import { isNullish } from '@nx/stdlib';
import { useState } from 'react';

import { formatBytes } from '../../utils/int';
import { InfoMessage, SuccessMessage } from './message';

const ConfirmationModal = ({
  onCancel,
  onConfirm,
  file,
}: {
  onCancel: () => void;
  onConfirm: () => unknown;
  file: File;
}) => {
  return (
    <Dialog onClose={onCancel} isOpen>
      <Dialog.Header>Are you sure?</Dialog.Header>
      <Dialog.Content>
        All existing data in your database will be replaced with {file.name} ({formatBytes(file.size)})
      </Dialog.Content>

      <Dialog.Actions>
        <Button onClick={onCancel} color="neutral" fill="outlined">
          Cancel
        </Button>
        <Button onClick={onConfirm}>Upload</Button>
      </Dialog.Actions>
    </Dialog>
  );
};

const acceptedFileExtensions = ['.dump', '.backup', '.tar'];

export const RestoreSection = ({ project, instance }: { project: Project; instance: Instance }) => {
  const [dropFile, setDropFile] = useState<File | null>(null);
  const [modalVisibile, setModalVisible] = useState(false);

  const { isAllowed } = usePermissions();
  const allowImport = isAllowed(ACTION.OVERWRITE, `namespaces/${project.id}/databases/${instance.id}`);
  const importEnabled = instance.availableActions.import.enabled && allowImport;

  const { uploadDump } = useUploadDump({ dbid: instance.id, file: dropFile!, cloudProvider: instance.cloudProvider });
  const { progress, status } = useUploadContext();

  if (instance.instanceStatus === INSTANCE_STATUS.LOADING_DATA) {
    return (
      <InfoMessage
        message="Import feature will be available again once current data loading completes"
        header="Loading in progress"
      />
    );
  }

  if (status === 'complete') {
    return <SuccessMessage />;
  }

  const onDrop = (acceptedFiles: File[]) => {
    // eslint-disable-next-line prefer-destructuring
    const file = acceptedFiles[0];
    if (isNullish(file)) {
      return;
    }
    setDropFile(file);
    setModalVisible(true);
  };

  const handleSubmit = async () => {
    setModalVisible(false);
    if (dropFile) {
      await uploadDump();
    }
  };

  const product =
    instance.tier === TIER.AURA_DSE || instance.tier === TIER.GDS
      ? 'https://neo4j.com/docs/aura/auradb/importing/import-database/#_neo4j_admin_database_upload'
      : 'https://neo4j.com/docs/aura/aurads/importing-data/import-db/#_neo4j_admin_database_upload';

  return (
    <div>
      <Dropzone
        dropZoneOptions={{
          onDrop,
          disabled: !importEnabled,
        }}
        className="h-full p-4"
        supportedFilesDescription={
          <>
            <p>
              Want to upload a dump or backup larger than 4GB?{' '}
              <TextLink href={product} isExternalLink>
                Click here
              </TextLink>{' '}
              to view the Neo4j Admin &apos;upload&apos; command in the Aura Docs.
            </p>
            {instance.tier === TIER.FREE &&
              !isNullish(instance.limits?.maxNodes) &&
              !isNullish(instance.limits.maxRelationships) && (
                <p>
                  The graph you upload should be smaller than {instance.limits.maxNodes.toLocaleString()} nodes and{' '}
                  {instance.limits.maxRelationships.toLocaleString()} relationships.
                </p>
              )}
          </>
        }
        loadingComponent={
          status === 'uploading' && (
            <>
              <ProgressBar
                data-testid="uploading-progress"
                className="m-auto w-48"
                value={Math.round(progress)}
                size="large"
                heading="Uploading"
              />
              <div className="my-4 font-light">{dropFile?.name}</div>
            </>
          )
        }
        customTitle={
          <>
            <span className="font-light">
              Drag & Drop your Neo4j <span className="font-semibold">.dump</span> or{' '}
              <span className="font-semibold">.backup</span> or {<span className="font-semibold">.tar </span>}
              file here (4GB or less)
            </span>
          </>
        }
        acceptedFileExtensions={acceptedFileExtensions}
      />
      {modalVisibile && dropFile && (
        <ConfirmationModal onCancel={() => setModalVisible(false)} onConfirm={() => handleSubmit()} file={dropFile} />
      )}
    </div>
  );
};
