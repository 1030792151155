import { Typography } from '@neo4j-ndl/react';
import { ArrowLongLeftIconSolid } from '@neo4j-ndl/react/icons';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

interface BackButtonProps {
  to: LinkProps['to'];
}

export const BackButton: React.FC<BackButtonProps> = ({ to }) => {
  return (
    <div className="flex">
      <Link to={to}>
        <span className="text-palette-primary-text flex items-center px-2">
          <ArrowLongLeftIconSolid className="mr-2 inline size-4" />
          <Typography variant="body-medium" className="font-bold">
            Go back
          </Typography>
        </span>
      </Link>
    </div>
  );
};
