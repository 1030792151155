import { Button, Dialog, TextLink } from '@neo4j-ndl/react';
import type { EncryptionKey } from '@nx/state';
import { CopyTextInput } from '@nx/ui';
import React from 'react';

interface AzureAddCustomerKeyInstructionsProps {
  updateStep: () => void;
  onClose: () => void;
}

export const AzureAddCustomerKeyInstructions = ({ updateStep, onClose }: AzureAddCustomerKeyInstructionsProps) => {
  return (
    <>
      <Dialog.Header>Adding an Azure Customer Managed Key</Dialog.Header>
      <Dialog.Content className="space-y-6">
        <div>
          <p className="mb-4">
            In order for Neo4j to use your Azure key, you have to set up your Azure Key Vault and Key following the
            checklists.
          </p>

          <h5>Checklist to create an Azure Key Vault</h5>
          <p className="my-2">Ensure your Azure Key Vault complies with the requirements below:</p>
          <ul className="my-2 list-disc pl-6">
            <li>The Key Vault is in the same region as the Neo4j instance you want to encrypt</li>
            <li>The Key Vault has the &quot;Enable purge protection&quot; option enabled</li>
            <li>
              In the Access configuration settings of the Key Vault, the &quot;Permission model&quot; is set to
              &quot;Azure role-based access control&quot;
            </li>
            <li>
              In the Access configuration settings of your Key Vault, under &quot;Resource access&quot; the &quot;Azure
              Disk Encryption for volume encryption&quot; option is enabled
            </li>
            <li>
              In the Networking settings of your Key Vault, the &quot;Enable public access&quot; option is enabled
            </li>
          </ul>
          <h5>Checklist to create a key</h5>
          <ul className="my-2 list-disc pl-6">
            <li>Ensure the key is enabled</li>
            <li>Ensure all the checkboxes are ticked under Permitted operations</li>
          </ul>
        </div>
      </Dialog.Content>
      <Dialog.Actions>
        <Button fill="outlined" color="neutral" onClick={onClose}>
          Cancel
        </Button>
        <Button
          fill="outlined"
          color="primary"
          onClick={() => {
            updateStep();
          }}
        >
          Next
        </Button>
      </Dialog.Actions>
    </>
  );
};

interface AzureCreatePrincipalInstructionsProps {
  encryptionKey: EncryptionKey;
  onClose: () => void;
}

export const AzureCreatePrincipalInstructions = ({ encryptionKey, onClose }: AzureCreatePrincipalInstructionsProps) => {
  return (
    <>
      <Dialog.Header>Adding an Azure Customer Managed Key</Dialog.Header>
      <Dialog.Content className="space-y-6">
        <div>
          <h5 className="mt-2">Create a service principal</h5>
          <p className="mt-2">
            Create a service principal linked to the Neo4j CMK Application with the following application ID:{' '}
            <code>{encryptionKey.azureProperties?.applicationName}</code>. To create the service principal using the{' '}
            <a
              href="https://learn.microsoft.com/en-us/cli/azure/ad/sp?view=azure-cli-latest#az-ad-sp-create"
              target="_blank"
              rel="noreferrer"
            >
              Azure CLI
            </a>
            {', '}
            run the following command:
          </p>
          <CopyTextInput
            value={`az ad sp create --id ${encryptionKey.azureProperties?.applicationID}`}
            label=""
            isPortaled={false}
          />

          <h5 className="mt-6">Grant Key Permissions </h5>
          <ol className="my-2 list-decimal pl-6">
            <li>
              To add role assignment to the Azure key, inside the key, go to &quot;Access control (IAM)&quot; and add
              &quot;role assignment&quot;.
            </li>
            <li>In the &quot;Role&quot; tab, select &quot;Key Vault Crypto Officer&quot;.</li>
            <li>In the &quot;Member&quot; tab, select &quot;User, group or service principal&quot;.</li>
            <li>
              &quot;Select members&quot; and paste following &quot;Neo4j CMK Application name&quot;:
              <CopyTextInput value={`${encryptionKey.azureProperties?.applicationName}`} label="" isPortaled={false} />
            </li>
            <li>
              The &quot;Neo4j CMK Application&quot; should appear, select this application then &quot;Review +
              Assign&quot;.
            </li>
          </ol>
          <p className="pt-1">
            See{' '}
            <TextLink isExternalLink href="https://neo4j.com/docs/aura/platform/security/encryption/#_azure_keys">
              Azure keys
            </TextLink>{' '}
            on the Neo4j Aura Documentation site for more info.
          </p>
        </div>
      </Dialog.Content>
      <Dialog.Actions>
        <Button fill="outlined" color="primary" onClick={onClose}>
          Close
        </Button>
      </Dialog.Actions>
    </>
  );
};
