import { Tabs } from '@neo4j-ndl/react';
import type { TimeRangeQueryLogAggregation } from '@nx/state';
import { QUERY_LOG_STATUS } from '@nx/state';
import { DataGridHelpers } from '@nx/ui';
import { random } from 'lodash-es';

import type { ChartWidgetPartialProps } from '../../metrics/charts/fullstack-chart-props';
import timelineCharts from '../../metrics/charts/timeline-charts';
import { useMetricsViewState } from '../../metrics/hooks';
import { fakeMetrics } from '../../shared/utils';
import { DEFAULT_SORT_SUMMARY } from '../shared/constants';
import { TAB_OPTIONS } from '../shared/types';
import { LogsContextProvider } from './hooks/use-logs-context';
import { useRawFilters } from './hooks/use-raw-filters';
import { QueryLogsDataGrid } from './query-logs-data-grid';
import { QueryTimelineCard } from './query-timeline-card';

function generateFakeQueryLog(): TimeRangeQueryLogAggregation[] {
  const exampleQueries = [
    `MATCH (a:Agent)-[:RUNS_ON]->(:Host)<-[:RUNS_ON]-(i:Instance)-[:HOSTS]->(:DatabaseInstance)-[:INSTANCE_OF]->(:Database)<-[:CONTAINS]-(dbms:DBMS {id: $dbmsId})
    WITH collect(DISTINCT i.id) AS instanceIds, collect(DISTINCT a.id) as agentIds
    MATCH (n:Metric:\`name:neo4j.dbms.page_cache.hit_ratio\`)
    WHERE n.timestamp > $cutoff AND CASE WHEN n.labels IS NOT NULL AND NOT isEmpty($qualifiers) THEN any(q IN $qualifiers WHERE n.labels CONTAINS q[******] AND n.labels CONTAINS q[******]) ELSE ****** END
    WITH instanceIds, agentIds, n ORDER BY n.timestamp DESC LIMIT ******
    WHERE any(agent in agentIds WHERE ******+agent IN labels(n))
     OR any(instance in instanceIds WHERE ******+instance IN labels(n))
    WITH collect(n) AS matchingMetrics, max(n.timestamp) AS maxTimestamp
    UNWIND matchingMetrics AS metric
    WITH metric, maxTimestamp
    WHERE metric.timestamp = maxTimestamp
    RETURN MIN(COALESCE(metric.value, metric.count)) AS min, MAX(COALESCE(metric.value, metric.count)) AS max`,
    'MATCH (n:MetricSpec)-[*]-(:Condition) RETURN n',
    'MATCH (n:Evaluation) WHERE n.generatedAt < $newest DETACH DELETE n',
    `UNWIND $props AS properties
    MATCH (condition:Condition {id: properties.conditionId})
    
    CREATE (evaluation:Evaluation {
         id: properties.id,
         result: properties.result,
         explanation: properties.explanation,
         generatedAt: properties.generatedAt
    })-[:RESULTS_FROM]->(condition)`,
    'MATCH (n:Condition)-[r:EVALUATES|MATCHES|RAISED*..******]->(n2) WHERE n.enabled = ****** RETURN n, collect(r), collect(n2)',
  ];

  const queries = [];
  for (let i = 0; i < 10; i++) {
    queries.push({
      id: '1',
      queryId: '1',
      query: exampleQueries[random(0, exampleQueries.length - 1)] ?? '',
      queryLanguage: 'CYPHER 5',
      error: '',
      from: new Date(),
      to: new Date(),
      executionCount: random(0, 10000),
      totalTimeSpent: random(0, 10000),
      status: QUERY_LOG_STATUS.COMPLETED,
      avgExecutionTimeMs: random(0, 10000),
      minExecutionTimeMs: random(0, 10000),
      maxExecutionTimeMs: random(0, 10000),
      avgPlanningTimeMs: random(0, 10000),
      avgWaitingTimeMs: random(0, 10000),
      avgAllocatedBytes: random(0, 10000),
      avgPageHits: random(0, 10000),
      avgPageFaults: random(0, 10000),
    });
  }
  return queries.sort((a, b) => b.executionCount - a.executionCount);
}

export const FakeQueryLogs = () => {
  const { rawFilters, setRawFilters } = useRawFilters();
  const timeRangeFromFilters = rawFilters.timeRange;
  const checkedTimeRange = timeRangeFromFilters.startTime
    ? {
        startTime: timeRangeFromFilters.startTime,
        endTime: timeRangeFromFilters.endTime,
      }
    : undefined;
  const { chartContext, setTimePeriod, zoomIn, refreshTimeRange, setExpandedChart } = useMetricsViewState({
    timeRangeDerived: checkedTimeRange,
  });

  return (
    <>
      <QueryTimelineCard
        metricsResult={{
          isLoading: false,
          metrics: fakeMetrics(
            'fake',
            'LIVE',
            timelineCharts,
            20,
            (props: ChartWidgetPartialProps) => props.subtitle ?? '',
          ),
        }}
        setIsRequestDialogOpen={() => {
          /* eslint-disable-next-line no-empty-function */
        }}
        chartContext={chartContext}
        setTimePeriod={setTimePeriod}
        zoomIn={zoomIn}
        refreshTimeRange={refreshTimeRange}
        setExpandedChart={setExpandedChart}
        setHasChangedSelection={() => {
          /* eslint-disable-next-line no-empty-function */
        }}
        hasChangedSelection={false}
        hasQueries={true}
        setHasQueries={() => {
          /* eslint-disable-next-line no-empty-function */
        }}
      />
      <div className="logs m-4">
        <DataGridHelpers.Wrapper>
          <LogsContextProvider
            value={{
              setIsRequestDialogOpen: () => {
                /* eslint-disable-next-line no-empty-function */
              },
              selectedTab: 'SUMMARY',
              setSelectedTab: () => {
                /* eslint-disable-next-line no-empty-function */
              },
              rawFilters,
              setRawFilters,
              hasRequestedData: true,
              setHasRequestedData: () => {
                /* eslint-disable-next-line no-empty-function */
              },
              chartTimeRange: {
                startTime: chartContext.timeRange.startTime,
                endTime: chartContext.timeRange.endTime,
              },
            }}
          >
            <div className="mx-4 mt-4 flex justify-between">
              <div className="flex items-center">
                <Tabs
                  value={'SUMMARY'}
                  onChange={() => {
                    /* eslint-disable-next-line no-empty-function */
                  }}
                  className="logs-tab-header w-fit"
                >
                  {TAB_OPTIONS.map((t) => (
                    <Tabs.Tab tabId={t.key} key={t.key}>
                      {t.text}
                    </Tabs.Tab>
                  ))}
                </Tabs>
              </div>
            </div>
            <Tabs.TabPanel value={'SUMMARY'} tabId="SUMMARY">
              <QueryLogsDataGrid
                dataGridType={'Summary'}
                logs={generateFakeQueryLog()}
                queryLogsLoading={false}
                pageInfo={{ pageCount: 15, itemCount: 150 }}
                queryLogsError={undefined}
                selectedDbmsId={''}
                controlledPagination={{
                  pageIndex: 0,
                  pageSize: 10,
                }}
                controlledSorting={DEFAULT_SORT_SUMMARY}
              />
            </Tabs.TabPanel>
          </LogsContextProvider>
        </DataGridHelpers.Wrapper>
      </div>
    </>
  );
};
