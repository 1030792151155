import type { Middleware } from '@reduxjs/toolkit';
import { isAnyOf } from '@reduxjs/toolkit';

import { runDataModelValidation } from '../reducers/data-model';
import { setShouldFitToError } from '../reducers/ui';
import type { RootState } from '../store';

export const uiMiddleware: Middleware<Record<string, unknown>, RootState> =
  (storeApi) => (next) => (action: unknown) => {
    if (isAnyOf(runDataModelValidation)(action)) {
      storeApi.dispatch(setShouldFitToError(true));
    }
    return next(action);
  };
