import type { DataSourceModalArgs } from '@nx/state';
import { useActiveProjectQuery, useFeatureFlag } from '@nx/state';
import { DataSourceModal as DataSourceModalUI, RemoteDataSource } from '@nx/ui';
import { useState } from 'react';

import { InstancesDataSource } from './instances-data-source';

export function DataSourceModal(props: DataSourceModalArgs) {
  const [filter, setFilter] = useState('');
  const activeProjectRes = useActiveProjectQuery();
  const [remoteConnectionsEnabled] = useFeatureFlag('upx:remote-connections');

  return (
    <DataSourceModalUI
      dataSources={[
        {
          name: activeProjectRes.data?.name ?? '',
          element: <InstancesDataSource filter={filter} onFilterChange={setFilter} />,
        },
        { name: 'Remote', title: 'Authenticate', element: <RemoteDataSource /> },
      ].filter(({ name }) => remoteConnectionsEnabled || name !== 'Remote')}
      {...props}
    />
  );
}
