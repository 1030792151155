export interface SSOProviderOriginal {
  id: string;
  name: string;
  auth_flow: string;
  auth_endpoint: string;
  token_endpoint?: string;
  well_known_discovery_uri?: string;
  params: {
    client_id: string;
    redirect_uri: string;
    response_type: string;
    scope: string;
  };
  auth_params?: Record<string, string>;
  token_params?: Record<string, string>;
  config?: {
    implicit_flow_requires_nonce?: boolean;
    principal?: string;
    token_type_principal?: string;
    token_type_authentication?: string;
    code_challenge_method?: string;
  };
  visible: boolean;
}

export type SSOProvider4dot4Format = Omit<SSOProviderOriginal, 'params'> & {
  redirect_uri: string;
  params: { client_id: string; response_type: string; scope: string };
};

export type BasicCredentials = {
  type: 'basic';
  username: string;
  password: string;
};

export type SsoCredentials = {
  type: 'sso';
  providers: SSOProviderOriginal[];
};

export type SeamlessConnectionCredentials = {
  type: 'seamless';
};

export type ConnectionCredentials = BasicCredentials | SsoCredentials | SeamlessConnectionCredentials;

export type ConnectionDescriptor = Omit<BasicCredentials, 'password'> | SsoCredentials | SeamlessConnectionCredentials;

export const DiscoverySuccess = 'Success';
export const DiscoveryFetchError = 'FetchError';
export const DiscoveryNoProviderError = 'NoProviderError';
type DiscoveryResultStatus = typeof DiscoverySuccess | typeof DiscoveryFetchError | typeof DiscoveryNoProviderError;

export interface DiscoveryResult {
  status: DiscoveryResultStatus;
  message: string;
  SSOProviders: SSOProviderOriginal[];
  otherDataDiscovered: Record<string, unknown>;
  seamless?: boolean;
  neo4jVersion?: string;
  neo4jEdition?: string;
}
