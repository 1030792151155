import { Banner, Button, Typography } from '@neo4j-ndl/react';
import { ACTION, consoleApi, getApiError, getErrorMessage, useActiveProject, usePermissions } from '@nx/state';
import type { EncryptionKey, Project } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { BackButton, PermissionTooltip } from '@nx/ui';
import { useMemo, useState } from 'react';

import { CmekTable } from './cmek-table';
import { CmekDialog } from './instructions/cmek-dialog';

type DisabledType = {
  project: Project;
};

interface PermissionDeniedProps
  extends Omit<React.ComponentProps<typeof Banner>, 'type' | 'isCloseable' | 'onClose' | 'title' | 'description'> {
  title?: React.ComponentProps<typeof Banner>['title'];
  project: Project;
}

const FeatureDisabled = ({ project }: DisabledType) => {
  return (
    <Banner
      type="info"
      title="Feature disabled"
      description={
        <div>
          <p>
            The <strong>Customer Manged Keys</strong> feature is not enabled for project {project.name}.
          </p>
        </div>
      }
      usage="inline"
    />
  );
};

const PermissionDenied = ({ title = 'Permission denied', project, ...rest }: PermissionDeniedProps) => {
  return (
    <Banner
      type="warning"
      title={title}
      description={
        <div>
          Customer Managed Keys
          <p>
            You must be an admin of the <strong>{project.name}</strong> project to read encryption key configurations.
          </p>
        </div>
      }
      {...rest}
      usage="inline"
    />
  );
};

const CmekContent = ({
  project,
  instructionsOpen,
  handleClose,
}: {
  project: Project;
  instructionsOpen: boolean;
  handleClose: () => void;
}) => {
  const [encryptionKey, setEncryptionKey] = useState<EncryptionKey>();

  const {
    data: cmeks = [],
    isError: isListError,
    isLoading,
    error: listError,
  } = consoleApi.useListEncryptionKeysQuery(project.id, { pollingInterval: 5000 });

  const listCmeksErrorMessage = useMemo(() => {
    if (!isListError) {
      return null;
    }
    const error = getApiError(listError);
    return getErrorMessage(error);
  }, [isListError, listError]);

  if (isNotNullish(listCmeksErrorMessage)) {
    return <Banner type="danger" description={listCmeksErrorMessage} usage="inline" />;
  }
  return (
    <>
      <CmekTable project={project} cmeks={cmeks} isLoading={isLoading} />
      {instructionsOpen && (
        <CmekDialog
          project={project}
          encryptionKey={encryptionKey}
          updateEncryptionKey={(key) => setEncryptionKey(key)}
          onClose={handleClose}
        />
      )}
    </>
  );
};

const CmekGuardedContent = ({
  instructionsOpen,
  handleClose,
}: {
  instructionsOpen: boolean;
  handleClose: () => void;
}) => {
  const activeProject = useActiveProject();
  const { isAllowed } = usePermissions();
  const allowReadCmek = isAllowed(ACTION.READ, `namespaces/${activeProject.id}/encryption-keys`);

  if (!activeProject.capabilities.cmek) {
    return <FeatureDisabled project={activeProject} />;
  }

  if (!allowReadCmek) {
    return <PermissionDenied project={activeProject} />;
  }

  return (
    <>
      <CmekContent project={activeProject} instructionsOpen={instructionsOpen} handleClose={handleClose} />
    </>
  );
};

export const Cmek = () => {
  const activeProject = useActiveProject();
  const { isAllowed } = usePermissions();
  const allowCreateCmek = isAllowed(ACTION.CREATE, `namespaces/${activeProject.id}/encryption-keys`);
  const [instructionsOpen, setInstructionsOpen] = useState(false);
  return (
    <div className="h-full overflow-auto p-4">
      <BackButton to="../settings" />
      <div className="mb-6 flex items-center justify-between px-2 pt-2">
        <Typography variant="h2">Encryption Keys</Typography>
        <PermissionTooltip hasPermission={allowCreateCmek}>
          <Button
            onClick={() => setInstructionsOpen(true)}
            isDisabled={!allowCreateCmek || !activeProject.capabilities.cmek}
            htmlAttributes={{
              'data-testid': 'new-encryption-key-btn',
            }}
          >
            Create encryption key
          </Button>
        </PermissionTooltip>
      </div>
      <CmekGuardedContent instructionsOpen={instructionsOpen} handleClose={() => setInstructionsOpen(false)} />
    </div>
  );
};
