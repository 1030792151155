import { DataGridComponents, IconButton, LoadingSpinner, Tooltip, Typography } from '@neo4j-ndl/react';
import {
  CheckCircleIconSolid,
  PencilIconOutline,
  PuzzlePieceIconOutline,
  TrashIconOutline,
} from '@neo4j-ndl/react/icons';
import { ACTION, CHANNEL, consoleApi, usePermissions } from '@nx/state';
import type { Organization, SsoConfig } from '@nx/state';
import { isNullish } from '@nx/stdlib';
import { DataGridHelpers, PermissionTooltip } from '@nx/ui';
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo } from 'react';

import type { FormActionData } from './entities';
import { SSO_CONFIG_FORM_ACTIONS } from './entities';
import { OrgLoginMethods } from './login-methods';

const NoDataPlaceholder = () => {
  return (
    <DataGridComponents.NoDataPlaceholder>
      <div className="flex h-72 w-44 flex-col items-center justify-center gap-2">
        <PuzzlePieceIconOutline className="text-neutral-icon size-16" />
        <Typography variant="body-medium" className="text-neutral-text-weak text-center">
          No Single sign ons are setup
        </Typography>
      </div>
    </DataGridComponents.NoDataPlaceholder>
  );
};

const headerHelper = createColumnHelper<SsoConfig>();

type EditIconProps = {
  allowEdit: boolean;
  isFrontChannel: boolean;
  onClick: () => void;
};

const EditIcon = ({ allowEdit, isFrontChannel, onClick }: EditIconProps) => {
  let message: string | null = null;
  if (!allowEdit) {
    message = "You don't have permission to edit SSO Configurations.";
  } else if (isFrontChannel) {
    message = 'This SSO Configuration is managed by Neo4j customer support';
  }

  return (
    <Tooltip type="simple" isDisabled={isNullish(message)}>
      <Tooltip.Trigger hasButtonWrapper>
        <IconButton onClick={onClick} ariaLabel="Edit SSO Config" isDisabled={!allowEdit} isClean>
          <PencilIconOutline />
        </IconButton>
      </Tooltip.Trigger>
      <Tooltip.Content>{message}</Tooltip.Content>
    </Tooltip>
  );
};

type DeleteIconProps = {
  allowDelete: boolean;
  onClick: () => void;
};

const DeleteIcon = ({ allowDelete, onClick }: DeleteIconProps) => (
  <PermissionTooltip hasPermission={allowDelete}>
    <IconButton ariaLabel="Delete SSO Config" isClean isDanger isDisabled={!allowDelete} onClick={onClick}>
      <TrashIconOutline />
    </IconButton>
  </PermissionTooltip>
);

type LinkedProjectsProps = {
  orgId: string;
  ssoId: string;
};

const LinkedProjects = ({ orgId, ssoId }: LinkedProjectsProps) => {
  const { data: projects = [], isLoading } = consoleApi.useListOrganizationProjectsQuery({
    organizationId: orgId,
    ssoConfigId: ssoId,
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return <DataGridHelpers.TruncatedColumn value={projects.map((s) => `${s.name} (${s.id})`).join(', ')} />;
};

type SsoConfigTableProps = {
  ssoConfigs: SsoConfig[];
  onDataChange: (data: FormActionData) => void;
  isSSOAllowed: boolean;
  org: Organization;
};

const SsoConfigsTable = ({ ssoConfigs = [], onDataChange, isSSOAllowed, org }: SsoConfigTableProps) => {
  const { isAllowed } = usePermissions();

  const data = useMemo(() => ssoConfigs, [ssoConfigs]);

  const columns = useMemo(
    () => [
      headerHelper.accessor('id', {
        header: 'ID',
        cell: (c) => <DataGridHelpers.TruncatedColumn value={c.getValue()} />,
      }),
      headerHelper.accessor('displayName', {
        header: 'Display Name',
        cell: (c) => <DataGridHelpers.TruncatedColumn value={c.getValue()} />,
      }),
      headerHelper.accessor('idpType', {
        header: 'IdP',
        cell: (c) => <DataGridHelpers.TruncatedColumn value={c.getValue()} />,
      }),
      headerHelper.accessor('clientId', {
        header: 'Client ID',
        cell: (c) => <DataGridHelpers.TruncatedColumn value={c.getValue()} />,
      }),
      headerHelper.accessor('organizationLoginMethod', {
        header: 'Org Login',
        cell: (c) => {
          if (!c.getValue()) {
            return <></>;
          }
          const { id } = c.row.original;
          return (
            <div data-testid={`org-login-enabled-${id}`}>
              <CheckCircleIconSolid className="text-success-icon size-5" />
            </div>
          );
        },
      }),
      headerHelper.display({
        id: 'linkedProjects',
        header: 'Projects',
        cell: (cx) => {
          const ssoConfig = cx.row.original;
          return <LinkedProjects orgId={org.id} ssoId={ssoConfig.id} />;
        },
      }),
      headerHelper.display({
        id: 'actions',
        header: () => null,
        cell: (cx) => {
          const ssoConfig = cx.row.original;
          const allowEditSsoConfig =
            isAllowed(ACTION.UPDATE, `organizations/${org.id}/sso-configs/${ssoConfig.id}`) &&
            ssoConfig.channel !== CHANNEL.FRONT &&
            isSSOAllowed;

          const allowDeleteSsoConfig = isAllowed(
            ACTION.DELETE,
            `organizations/${ssoConfig.organizationId}/sso-configs/${ssoConfig.id}`,
          );

          return (
            <div className="flex flex-grow justify-end gap-2">
              <div data-testid={`edit-${ssoConfig.id}`}>
                <EditIcon
                  allowEdit={allowEditSsoConfig}
                  onClick={() => onDataChange({ action: SSO_CONFIG_FORM_ACTIONS.UPDATE, data: ssoConfig })}
                  isFrontChannel={ssoConfig.channel === CHANNEL.FRONT}
                />
              </div>
              <div data-testid={`delete-${ssoConfig.id}`}>
                <DeleteIcon
                  allowDelete={allowDeleteSsoConfig}
                  onClick={() => onDataChange({ action: SSO_CONFIG_FORM_ACTIONS.DELETE, data: ssoConfig })}
                />
              </div>
            </div>
          );
        },
        size: 120,
        maxSize: 120,
        enableResizing: false,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    columnResizeMode: 'onChange',
    enableColumnPinning: true,
    state: {
      columnPinning: {
        right: ['actions'],
      },
    },
    defaultColumn: {
      minSize: 70,
    },
  });

  let disabledReason: string | null = null;
  if (ssoConfigs.length === 0) {
    disabledReason =
      'There are no SSO Configurations that can be used. Add a new Configuration to be able to turn off Username/Password and Google social login.';
  } else if (isAllowed(ACTION.UPDATE, `organizations/${org.id}`)) {
    disabledReason = "You don't have permission to perform this action";
  }

  return (
    <DataGridHelpers.Wrapper className="mt-4">
      <DataGridHelpers.OuterHeader>
        <OrgLoginMethods organization={org} isDisabled={ssoConfigs.length === 0} disabledReason={disabledReason} />
      </DataGridHelpers.OuterHeader>
      <DataGridHelpers.DataGridRightColumnPinned<SsoConfig>
        tableInstance={table}
        data-testid="sso-config-table"
        styling={{
          headerStyle: 'clean',
          borderStyle: 'horizontal',
        }}
        components={{
          NoDataPlaceholder,
        }}
      />
    </DataGridHelpers.Wrapper>
  );
};

export default SsoConfigsTable;
