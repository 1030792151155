import { DatePicker, Select } from '@neo4j-ndl/react';
import type { TimeRange } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { min } from 'date-fns';
import React, { useEffect, useState } from 'react';

import '../../../logs.module.css';

const timeRangeOptions: { value: number; label: string }[] = [
  {
    value: 0.5,
    label: 'Last 30 minutes',
  },
  {
    value: 1,
    label: 'Last hour',
  },
  {
    value: 2,
    label: 'Last 2 hours',
  },
  {
    value: 6,
    label: 'Last 6 hours',
  },
  {
    value: 24,
    label: 'Last 24 hours',
  },
  {
    value: -1,
    label: 'Custom...',
  },
];

const MAX_HOURS = 24;
const MAX_DAYS_BACK = 30;

export type TimeRangeSelectorProps = {
  onTimeRangeChange: (timeRange: TimeRange) => void;
  timePeriod: number;
  setTimePeriod: (timePeriod: number) => void;
};

type TimeRangeSelectorProperties = TimeRangeSelectorProps & {
  timeRange: TimeRange;
};

const TimeRangeSelector: React.FC<TimeRangeSelectorProperties> = ({
  timeRange,
  onTimeRangeChange,
  timePeriod,
  setTimePeriod,
}) => {
  const { startTime, endTime } = timeRange;
  const [localStartTime, setLocalStartTime] = useState<Date>(startTime);
  const [localEndTime, setLocalEndTime] = useState<Date>(endTime);
  const [localTimePeriod, setLocalTimePeriod] = useState<number>(timePeriod);
  const isCustomTimeRange = localTimePeriod === -1;

  useEffect(() => {
    setTimePeriod(localTimePeriod);
    if (isCustomTimeRange) {
      onTimeRangeChange({ startTime: localStartTime, endTime: localEndTime });
      return;
    }
    const newStartDate = new Date(new Date().getTime() - localTimePeriod * 60 * 60 * 1000);
    onTimeRangeChange({ startTime: newStartDate, endTime: new Date() });
  }, [localTimePeriod, localEndTime, onTimeRangeChange, localStartTime, isCustomTimeRange, setTimePeriod]);

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      setLocalStartTime(date);
      const newEndDate = min([new Date(date.getTime() + MAX_HOURS * 60 * 60 * 1000), new Date()]);
      setLocalEndTime(newEndDate);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date && date.getTime() - localStartTime.getTime() <= MAX_HOURS * 60 * 60 * 1000) {
      setLocalEndTime(date);
    }
  };

  const minStartDate = new Date();
  minStartDate.setDate(minStartDate.getDate() - MAX_DAYS_BACK);

  return (
    <div className="flex flex-col gap-4">
      <Select
        size="medium"
        type="select"
        label="Time range"
        isFluid
        selectProps={{
          isSearchable: false,
          'aria-label': 'time window dropdown selector',
          isMulti: false,
          closeMenuOnSelect: true,
          onChange: (e) => isNotNullish(e) && setLocalTimePeriod(e.value),
          value: timeRangeOptions.find((option) => option.value === localTimePeriod),
          options: timeRangeOptions,
          menuPosition: 'fixed',
        }}
        htmlAttributes={{
          'aria-label': 'time window dropdown selector',
          'data-testid': 'time-window-selector',
        }}
      />
      {isCustomTimeRange && (
        <>
          <DatePicker
            reactDatePickerProps={{
              selected: localStartTime,
              onChange: handleStartDateChange,
              popperProps: {
                strategy: 'fixed',
              },
              calendarStartDay: 1,
              minDate: minStartDate,
              maxDate: new Date(),
              dateFormat: 'yyyy-MM-dd HH:mm',
              showTimeInput: true,
              shouldCloseOnSelect: false,
            }}
            textInputProps={{
              isFluid: true,
              label: 'From date',
            }}
          />
          <DatePicker
            reactDatePickerProps={{
              selected: localEndTime,
              onChange: handleEndDateChange,
              popperProps: {
                strategy: 'fixed',
              },
              calendarStartDay: 1,
              minDate: localStartTime,
              maxDate: min([new Date(localStartTime.getTime() + MAX_HOURS * 60 * 60 * 1000), new Date()]),
              dateFormat: 'yyyy-MM-dd HH:mm',
              showTimeInput: true,
              shouldCloseOnSelect: false,
            }}
            textInputProps={{
              isFluid: true,
              label: 'To date',
            }}
          />
        </>
      )}
    </div>
  );
};

export default TimeRangeSelector;
