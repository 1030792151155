import type { Event } from '@nx/analytics-service';
import { EXPLORE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { LEGACY_store, trackEvent } from '@nx/state';

import { CATEGORY, RELATIONSHIP } from '../../modules/Filter/shared';
import { getBaseTypeSuffixFromRuleBase } from '../../modules/Legend/Popups/RuleBasedStyling/rules';
import type { Suggestion } from '../../modules/SearchBar/types';
import { EXPAND_INSIDE_RANGE, EXPAND_RANGE_TO_END, SIDE_RANGE_TO_END } from '../../modules/Slicer/utils';
import { isAura } from '../../state/connections/helpers';
import type { Node, Relationship } from '../../types/graph';
import type { CategoryWithStyle, Perspective, PerspectiveWithStyle } from '../../types/perspective';
import type { StyleRule } from '../../types/style';
import type { Nullable } from '../../types/utility';
import { isTruthy } from '../../types/utility';
import { log } from '../logging';
import { isZonedType } from '../temporal/utils';
import { DATE, DATETIME, LOCAL_DATETIME, LOCAL_TIME, TIME } from '../temporal/utils.const';
import { getClientVersion } from '../versions/version';
import {
  getEventPropertiesFromPerspective,
  getEventPropertiesFromSearchSuggestion,
  getEventPropertiesFromSearchSuggestions,
  getEventPropertiesFromVisibleGraph,
  getGPUChip,
  getNumWordsFromInput,
} from './helpers';
import type { AutoGeneratedSchema } from './types';

const bloomVersion = getClientVersion();
const gpuChip = getGPUChip();

let dbmsVersion: string | null | undefined;
let dbmsId: string;
let remoteConnection: boolean;
let pluginPresent: boolean;
let numDatabases: number;
let currentPerspectives: Perspective[] = [];
let isWorkspace = false;

export const middleWareActions = {
  setCurrentDbmsId: (newDbmsId: string) => {
    dbmsId = newDbmsId;
  },
  setCurrentServerVersion: (serverVersion: string | null | undefined) => {
    dbmsVersion = serverVersion;
  },
  setIsRemoteConnection: (isRemoteConnection: boolean) => {
    remoteConnection = isRemoteConnection;
  },
  setCurrentNumDatabases: (newNumDatabases: number) => {
    numDatabases = newNumDatabases;
  },
  setCurrentPerspectives: (newPerspectives: Perspective[]) => {
    currentPerspectives = newPerspectives;
  },
  setPluginPresent: (isPluginPresent: boolean) => {
    pluginPresent = isPluginPresent;
  },
  setIsWorkspace: (isWorkspaceDeploy: boolean) => {
    isWorkspace = isWorkspaceDeploy;
  },
};

export const fireTrackingEvent = (eventLabel: Event, eventProperties = {}) => {
  const isAuraLabel = isAura(window.location.href) ? 'console' : null;
  const source = isAuraLabel ?? 'unknown';

  const isWorkspaceType = isWorkspace ? 'workspace' : null;
  const openedFrom = isWorkspaceType != null ? `${source}-${isWorkspaceType}` : source;

  const enrichedEventProperties = {
    bloomVersion,
    gpuChip,
    dbmsVersion,
    dbmsId,
    remoteConnection,
    pluginPresent,
    numDatabases,
    openedFrom,
    bloomDomain: window.location.href,
    ...eventProperties,
  };

  LEGACY_store.dispatch(
    trackEvent({
      event: eventLabel,
      properties: enrichedEventProperties,
      scope: APP_SCOPE.explore,
    }),
  );
};

export const perspectiveCreationTypes = {
  imported: 'Imported',
  blank: 'Blank',
  generated: 'Generated',
};

export const userQueryTypes = {
  revealRelationships: 'Reveal relationships',
  expansion: 'Expansion',
  advancedExpansion: 'Advanced Expansion',
  shortestPath: 'Shortest Path',
  inspector: 'Inspector',
  createNode: 'Create Node',
  createRelationship: 'Create Relationship',
  deleteNodes: 'Delete Nodes',
  deleteRelationships: 'Delete Relationships',
  duplicateNode: 'Duplicate Node',
  editLabel: 'Edit Label',
  editProperty: 'Edit Property',
  refreshData: 'Refresh data',
  sceneAction: 'Scene Action',
};

export const applyFilterActionTypes = {
  applyToggle: 'appy-toggle',
  hide: 'hide',
};

export const dismissFilteredElementsOrigins = {
  contextMenu: 'Context menu',
  filterPanel: 'Filter panel',
};

export const screenshotExportOrigins = {
  contextMenu: 'Context menu',
  exportControls: 'Export controls',
};

export const searchPhraseTransactionModes = {
  write: 'write',
  read: 'read',
};

export const actionOrigin = {
  contextMenu: 'Context menu',
  searchBar: 'Search bar',
  keyboardShortcut: 'Keyboard shortcut',
};

export const actionName = {
  undo: 'Undo',
  redo: 'Redo',
  jumpToNodeRel: 'JumpToNodeRel',
  inspect: 'Inspect',
  editNode: 'EditNode',
  fitToSelection: 'FitToSelection',
  invertSelection: 'InvertSelection',
  expand: 'Expand',
  reveal: 'Reveal',
  path: 'Path',
  dismiss: 'Dismiss',
  dismissOther: 'DismissOther',
  createRel: 'CreateRel',
  createNode: 'CreateNode',
  duplicate: 'Duplicate',
  clearScene: 'ClearScene',
  selectAll: 'SelectAll',
  zoomIn: 'ZoomIn',
  zoomOut: 'ZoomOut',
  refreshData: 'RefreshData',
};

export const trackLoadPerspective = (perspective: PerspectiveWithStyle) => {
  const perspectiveProperties = getEventPropertiesFromPerspective({
    perspective,
    trackType: true,
  });

  fireTrackingEvent(EXPLORE_EVENTS.PERSPECTIVE_LOAD, perspectiveProperties);
};

export const trackCreatePerspective = (
  perspective: Nullable<Perspective>,
  perspectiveType: string,
  autoGeneratedSchema?: AutoGeneratedSchema,
  createTime?: number,
) => {
  const perspectiveProperties = getEventPropertiesFromPerspective({
    perspective,
    autoGeneratedSchema,
  });
  const eventProperties = {
    perspectiveType,
    ...perspectiveProperties,
    createTime,
  };
  fireTrackingEvent(EXPLORE_EVENTS.PERSPECTIVE_CREATE, eventProperties);
};

export const trackRemovePerspective = (perspective: Perspective) => {
  const perspectiveProperties = getEventPropertiesFromPerspective({
    perspective,
    trackType: true,
  });
  fireTrackingEvent(EXPLORE_EVENTS.PERSPECTIVE_REMOVE, perspectiveProperties);
};

export const trackRefreshPerspective = (perspective: Perspective, refreshTime: number) => {
  const perspectiveProperties = getEventPropertiesFromPerspective({
    perspective,
    trackType: true,
  });
  const eventProperties = {
    ...perspectiveProperties,
    refreshTime,
  };
  fireTrackingEvent(EXPLORE_EVENTS.PERSPECTIVE_REFRESH, eventProperties);
};

export const trackCsvExport = (exportData?: { data: unknown[] }[]) => {
  const eventProperties = isTruthy(exportData)
    ? {
        numNodes: isTruthy(exportData[0]) ? exportData[0].data.length : 0,
        numRelationships: isTruthy(exportData[1]) ? exportData[1].data.length : 0,
      }
    : {};
  fireTrackingEvent(EXPLORE_EVENTS.INTERACTIONS_CSV_EXPORT, eventProperties);
};

export const trackStyleRuleApply = (legendTab: string, rule: StyleRule) => {
  let elementType = null;

  if (legendTab === 'categories') {
    elementType = 'Node';
  } else if (legendTab === 'relationships') {
    elementType = 'Relationship';
  }

  let conditionType = null;
  let timezoneChanged = null;
  let ruleSuffix = null;

  if (isTruthy(rule)) {
    const { type, basedOn, condition, isTimeZoneConvertEnabled } = rule;
    ruleSuffix = getBaseTypeSuffixFromRuleBase(basedOn);

    if (type === 'single') {
      conditionType = condition;
    } else if (type === 'range') {
      conditionType = type;
    }

    if (isZonedType(ruleSuffix)) {
      timezoneChanged = isTimeZoneConvertEnabled;
    }
  }
  const eventProperties = {
    conditionType,
    elementType,
    dataType: ruleSuffix,
    timezoneChanged,
  };
  fireTrackingEvent(EXPLORE_EVENTS.INTERACTIONS_STYLE_RULE_APPLY, eventProperties);
};

export const trackBloomReady = (loadingTime: number) => {
  const perspectivesInGallery = currentPerspectives
    .map((p) => ({ ...p, isPlugin: isTruthy(p.isPlugin) }))
    .filter((p) => p.isPlugin === pluginPresent && p.dbmsId === dbmsId);
  const eventProperties = {
    loadingTime,
    numPerspectives: perspectivesInGallery.length,
  };
  fireTrackingEvent(EXPLORE_EVENTS.ENVIRONMENTAL_CONFIG, eventProperties);
};

export const trackUserQueryFromBloomScene = (queryType: string) => {
  const eventProperties = { queryType };
  fireTrackingEvent(EXPLORE_EVENTS.QUERIES_USER_QUERY, eventProperties);
};

export const trackUserQueryFromSearchV2 = (suggestions: Suggestion[]) => {
  const eventProperties = getEventPropertiesFromSearchSuggestions(suggestions);
  fireTrackingEvent(EXPLORE_EVENTS.QUERIES_USER_QUERY, eventProperties);
};

export const trackUserQueryFulltextFacetedResults = () => {
  fireTrackingEvent(EXPLORE_EVENTS.QUERIES_USER_QUERY, {
    queryType: 'fulltextV2',
    wasFacetedResult: true,
  });
};

export const trackUserQueryFromSearchBar = (suggestion: { type: string; text: string }) => {
  const eventProperties = getEventPropertiesFromSearchSuggestion(suggestion);
  fireTrackingEvent(EXPLORE_EVENTS.QUERIES_USER_QUERY, eventProperties);
};

export const trackUserQueryCancel = (suggestion: { type: string; text: string }) => {
  const eventProperties = getEventPropertiesFromSearchSuggestion(suggestion);
  fireTrackingEvent(EXPLORE_EVENTS.QUERIES_USER_QUERY_CANCEL, eventProperties);
};

export const trackSuggestionQuery = (inputText: string, numCombinations: number, suggestionsAreProactive: boolean) => {
  const eventProperties = {
    numWords: getNumWordsFromInput(inputText),
    numCombinations,
    suggestionsAreProactive,
  };
  fireTrackingEvent(EXPLORE_EVENTS.QUERIES_SUGGESTION_QUERY, eventProperties);
};

export const trackSceneMetrics = (
  visibleNodeIds: string[] = [],
  visibleRelationshipIds: string[] = [],
  nodesInventory: Record<string, Node>,
  relationshipsInventory: Record<string, Relationship>,
  categories: CategoryWithStyle[],
) => {
  if (visibleNodeIds.length > 0) {
    const eventProperties = getEventPropertiesFromVisibleGraph(
      visibleNodeIds,
      visibleRelationshipIds,
      nodesInventory,
      relationshipsInventory,
      categories,
    );
    fireTrackingEvent(EXPLORE_EVENTS.STATISTICS_SCENE_METRICS, eventProperties);
  }
};

export const trackCardListFindFocused = (searchValueLength: number) => {
  const eventProperties = { searchValueLength };
  fireTrackingEvent(EXPLORE_EVENTS.INTERACTIONS_CARD_LIST_FIND, eventProperties);
};

export const trackScreenshotExport = (origin: string) => {
  const eventProperties = { origin };
  fireTrackingEvent(EXPLORE_EVENTS.INTERACTIONS_SCREENSHOT_EXPORT, eventProperties);
};

export const trackApplyFilter = (
  actionType: string,
  filterRule?: Nullable<{ type: string; basedOn: string; condition: string; isTimeZoneConvertEnabled: boolean }>,
) => {
  const { type, basedOn, condition, isTimeZoneConvertEnabled } = filterRule ?? {};

  let elementType = null;
  if (type === CATEGORY) {
    elementType = 'Node';
  } else if (type === RELATIONSHIP) {
    elementType = 'Relationship';
  }

  let filterType = null;
  let timezoneChanged = null;
  const ruleSuffix = getBaseTypeSuffixFromRuleBase(basedOn);
  if (actionType === applyFilterActionTypes.applyToggle) {
    switch (ruleSuffix) {
      case 'boolean':
      case 'bool':
        filterType = 'boolean';
        break;
      case 'number':
      case 'bigint':
      case DATETIME:
      case DATE:
      case TIME:
      case LOCAL_TIME:
      case LOCAL_DATETIME:
        filterType = condition;
        break;
      case 'string':
        filterType = 'string';
        break;
      default:
        filterRule = null;
        break;
    }
  }

  if (isZonedType(ruleSuffix)) {
    timezoneChanged = isTimeZoneConvertEnabled;
  }

  const eventProperties = { actionType, elementType, filterType, dataType: ruleSuffix, timezoneChanged };
  fireTrackingEvent(EXPLORE_EVENTS.INTERACTIONS_FILTER_APPLY, eventProperties);
};

export const trackDismissFilteredElements = (origin: string) => {
  const eventProperties = { origin };
  fireTrackingEvent(EXPLORE_EVENTS.INTERACTIONS_FILTERED_ELEMENTS_DISMISS, eventProperties);
};

export const trackSearchPhraseTransactionMode = (searchPhraseTransactionMode: string) => {
  const eventProperties = { searchPhraseTransactionMode };
  fireTrackingEvent(EXPLORE_EVENTS.INTERACTIONS_SEARCH_PHRASE_TRANSACTION_MODE, eventProperties);
};

export const trackSceneCreate = (sceneId: string, perspectiveId: string) => {
  fireTrackingEvent(EXPLORE_EVENTS.SCENE_CREATE, { sceneId, perspectiveId });
};

export const trackSceneDuplicate = (sceneId: string) => {
  fireTrackingEvent(EXPLORE_EVENTS.SCENE_DUPLICATE, { sceneId });
};

export const trackSceneShare = (sceneId: string) => {
  fireTrackingEvent(EXPLORE_EVENTS.SCENE_SHARE, { sceneId });
};

export const trackSceneLinkCopy = (sceneId: string) => {
  fireTrackingEvent(EXPLORE_EVENTS.SCENE_LINK_COPY, { sceneId });
};

export const trackSceneEditToggle = (sceneId: string, editMode: string) => {
  fireTrackingEvent(EXPLORE_EVENTS.SCENE_EDIT_TOGGLE, { sceneId, newState: editMode });
};

export const trackNewPerspectiveStyleButton = (sceneId: string, hasWriteAccess: boolean, styleType: string) => {
  fireTrackingEvent(EXPLORE_EVENTS.PERSPECTIVE_STYLE_APPLY_BUTTON, {
    sceneId,
    hasWriteAccess,
    newState: styleType,
  });
};

export const trackSceneLoad = (
  sceneId: string,
  perspectiveId: string,
  numNodes: number,
  numRelationships: number,
  numStyles: number,
  viaLink = false,
) => {
  fireTrackingEvent(EXPLORE_EVENTS.SCENE_LOAD, {
    sceneId,
    perspectiveId,
    numNodes,
    numRelationships,
    numStyles,
    viaLink,
  });
};

export const trackActionExecution = (
  sceneActionId: string,
  perspectiveId: string,
  selectedNodes: number,
  selectedRelationships: number,
  isUpdateQuery: boolean,
  resultNodes: number,
  resultRelationships: number,
) => {
  fireTrackingEvent(EXPLORE_EVENTS.SCENE_ACTION_EXECUTION, {
    sceneActionId,
    perspectiveId,
    selectedNodes,
    selectedRelationships,
    isUpdateQuery,
    resultNodes,
    resultRelationships,
  });
};

export const trackGdsAlgoRun = (
  hasAlgorithmRan: boolean,
  algorithmType: string,
  nodeCount: number,
  relCount: number,
  timeTaken: number,
  errorMessage = '',
) => {
  const eventProperties = { algorithmType, nodeCount, relCount, timeTaken, errorMessage };
  hasAlgorithmRan
    ? fireTrackingEvent(EXPLORE_EVENTS.GDS_RE_RUN_ALGORITHM, eventProperties)
    : fireTrackingEvent(EXPLORE_EVENTS.GDS_RUN_ALGORITHM, eventProperties);
};

export const trackGdsAddAlgo = (algorithmType: string) => {
  const eventProperties = { algorithmType };
  fireTrackingEvent(EXPLORE_EVENTS.GDS_ADD_ALGORITHM, eventProperties);
};

export const trackGdsAddRuleBasedStyling = (ruleBasedStylingAdded: boolean) => {
  const eventProperties = { ruleBasedStylingAdded };
  fireTrackingEvent(EXPLORE_EVENTS.GDS_ADD_RULE_BASED_STYLING, eventProperties);
};

export const trackGdsPanelOpen = (isGdsPluginAvailable: boolean) => {
  const eventProperties = { isGdsPluginAvailable };
  fireTrackingEvent(EXPLORE_EVENTS.GDS_PANEL_OPEN, eventProperties);
};

export const trackSlicerLaunched = (numNodes: number, numRels: number, numRanges: number) => {
  const eventProperties = { numNodes, numRels, numRanges };
  fireTrackingEvent(EXPLORE_EVENTS.SLICER_LAUNCH, eventProperties);
};

export const trackRangeAdded = (dataType: string) => {
  const eventProperties = { dataType };
  fireTrackingEvent(EXPLORE_EVENTS.SLICER_ADDRANGE, eventProperties);
};

type PLAY_MODE_TRACKING_NAME = typeof SIDE_RANGE_TO_END | typeof EXPAND_RANGE_TO_END | typeof EXPAND_INSIDE_RANGE;
const PLAY_MODE_TRACKING_NAME_MAPPING: { [key in PLAY_MODE_TRACKING_NAME]: string } = {
  [SIDE_RANGE_TO_END]: 'slide_to_end',
  [EXPAND_RANGE_TO_END]: 'start_to_end',
  [EXPAND_INSIDE_RANGE]: 'within',
};

export const trackSlicerPlay = (mode: PLAY_MODE_TRACKING_NAME) => {
  const eventProperties = { playType: PLAY_MODE_TRACKING_NAME_MAPPING[mode] };
  fireTrackingEvent(EXPLORE_EVENTS.SLICER_PLAY, eventProperties);
};

export const trackKeepSceneAndClose = (numNodes: number, numRels: number) => {
  const eventProperties = { numNodes, numRels };
  fireTrackingEvent(EXPLORE_EVENTS.SLICER_KEEPCLOSE, eventProperties);
};

export const trackSlicerClosed = () => {
  fireTrackingEvent(EXPLORE_EVENTS.SLICER_CLOSE);
};

const triggeredFromValues = Object.values(actionOrigin);
const actionNameValues = Object.values(actionName);
export const trackShortcutActions = (triggeredFrom: string, actionName: string) => {
  if (!triggeredFromValues.includes(triggeredFrom) || !actionNameValues.includes(actionName)) {
    log.warn(`Tracking value ${EXPLORE_EVENTS.SHORTCUT_ACTION} ${triggeredFrom} ${actionName} is not recognized`);
    return;
  }
  fireTrackingEvent(EXPLORE_EVENTS.SHORTCUT_ACTION, {
    triggeredFrom,
    actionName,
  });
};

export const trackGenAISuggestionGenerated = (eventProperties: { success: boolean; error?: string }) => {
  fireTrackingEvent(EXPLORE_EVENTS.GEN_AI_SUGGESTION, eventProperties);
};
