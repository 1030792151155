import type { Node as NvlNode, Relationship as NvlRelationship } from '@neo4j-nvl/base';
import type { OmitStrict } from '@nx/stdlib';
import type { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { cloneDeep, isEmpty, isNil, keyBy } from 'lodash-es';

import { fullPallete } from '../../modules/Legend/Popups/RuleBasedStyling/colorHelpers';
import { getTitleFromRuleBase } from '../../modules/Legend/Popups/RuleBasedStyling/rules';
import { getOrderedList } from '../../modules/Legend/Popups/helpers';
import migrate from '../../services/migrate';
import { labelColorPalette as defaultPalette } from '../../styles/colors';
import type { Category } from '../../types/category';
import type { Node as BloomNode, Relationship as BloomRelationship } from '../../types/graph';
import type {
  CategoryWithStyle,
  Perspective,
  PerspectiveRelationshipType,
  PerspectiveWithStyle,
  RelationshipTypeWithStyle,
} from '../../types/perspective';
import type { Scene } from '../../types/scene';
import type { CategoryIcon, Style, StyleCategory, StyleRelationshipType, StyleRule } from '../../types/style';
import type { NonUndefined, Nullable } from '../../types/utility';
import { REHYDRATE } from '../persistence/constants';
import { DEFAULT_UNCATEGORISED_ID } from '../perspectives/constants';
import { getLatestPerspectiveVersion, trasformations } from '../perspectives/migrations';
import {
  addPerspective,
  clearPerspectives,
  getAllSortedPerspectives,
  getCategories,
  getCategoryByName,
  getCategoryForLabel,
  getCategoryForNode,
  getRelationshipDetails,
  loadPerspective,
  removePerspective,
} from '../perspectives/perspectives';
import { getPerspective, getPerspectiveById } from '../perspectives/perspectives.selector';
import {
  getRuleBasedCaptions,
  getRuleBasedColor,
  getRuleBasedSize,
  getRuleBasedTextAlign,
  getRuleBasedTextSize,
} from '../perspectives/ruleEvaluators';
import { addScene, clearScenes, duplicateScene, removeScene } from '../scene/scene';
import type { RootState, SliceActionsUnion } from '../types';
import { findCaptionProperty } from './styleMiddleware.utils';
import {
  COLOR_MAPPING_THRESHOLD,
  DEFAULT_RELATIONSHIP_COLOR,
  DEFAULT_RELATIONSHIP_SIZE,
  DEFAULT_UNCATEGORISED_COLOR,
  DEFAULT_UNCATEGORISED_ICON,
  DEFAULT_UNCATEGORISED_SIZE,
  DEFAULT_UNCATEGORISED_TEXT_ALIGN,
  DEFAULT_UNCATEGORISED_TEXT_ALIGN_RELATIONSHIP,
  DEFAULT_UNCATEGORISED_TEXT_SIZE,
} from './styles.const';
import type { Caption, CaptionStyle } from './types';
import { CAPTION_TYPE_REL } from './types';

export const NAME = 'styles';

export const STYLE_TYPE_PERSPECTIVE = 'perspective' as const;
export const STYLE_TYPE_SCENE = 'scene' as const;

export type StyleType = typeof STYLE_TYPE_PERSPECTIVE | typeof STYLE_TYPE_SCENE;

export const getOtherCategory = (): OmitStrict<StyleCategory, 'id'> => ({
  color: DEFAULT_UNCATEGORISED_COLOR,
  size: DEFAULT_UNCATEGORISED_SIZE,
  icon: DEFAULT_UNCATEGORISED_ICON,
  captionKeys: [], // make caption backward-compatible for <= Bloom 2.7.1
  captions: [], // caption for >= Bloom 2.8.0
  textSize: DEFAULT_UNCATEGORISED_TEXT_SIZE,
  textAlign: DEFAULT_UNCATEGORISED_TEXT_ALIGN,
  styleRules: [],
});

export const getDefaultRelStyling = (
  type: PerspectiveRelationshipType['id'],
): OmitStrict<StyleRelationshipType, 'id'> => ({
  color: DEFAULT_RELATIONSHIP_COLOR,
  size: DEFAULT_RELATIONSHIP_SIZE,
  captionKeys: [], // make caption backward-compatible for <= Bloom 2.7.1
  captions: [
    // caption for >= Bloom 2.8.0
    {
      inTooltip: true,
      isCaption: true,
      styles: [],
      key: type,
      type: CAPTION_TYPE_REL,
    },
  ],
  textSize: DEFAULT_UNCATEGORISED_TEXT_SIZE,
  textAlign: DEFAULT_UNCATEGORISED_TEXT_ALIGN_RELATIONSHIP,
  styleRules: [],
});

const getStyleFromStoredCategory = (cat: CategoryWithStyle): StyleCategory => {
  return {
    id: cat.id,
    color: cat.color,
    size: cat.size,
    icon: cat.icon,
    textSize: cat.textSize,
    textAlign: cat.textAlign,
    captions: cat.captions,
    captionKeys: cat.captionKeys, // make caption backward-compatible for <= Bloom 2.7.1
    styleRules: cat.styleRules ?? [],
  };
};

const getStyleFromStoredRelationshipType = (relType: RelationshipTypeWithStyle): StyleRelationshipType => {
  return {
    id: relType.id,
    color: relType.color,
    size: relType.size,
    textSize: relType.textSize,
    textAlign: relType.textAlign,
    captions: relType.captions,
    captionKeys: relType.captionKeys, // make caption backward-compatible for <= Bloom 2.7.1
    styleRules: relType.styleRules ?? [],
  };
};

const getStyleEntryFromPerspective = (perspective: PerspectiveWithStyle): Style => {
  return {
    id: perspective.id,
    type: STYLE_TYPE_PERSPECTIVE,
    palette: perspective.palette ?? { colors: defaultPalette, currentIndex: 0 },
    categories: perspective.categories?.map(getStyleFromStoredCategory),
    relationshipTypes: perspective.relationshipTypes?.map(getStyleFromStoredRelationshipType),
  };
};

const getStyleEntryFromScene = (scene: Scene): Style => {
  return {
    palette: { colors: defaultPalette, currentIndex: 0 },
    ...scene.style,
    id: scene.id,
    type: STYLE_TYPE_SCENE,
    relationshipTypes: scene.style?.relationshipTypes ?? [],
    categories: scene.style?.categories ?? [],
  };
};

// Basic Selectors
export const getCurrentStyle = (state: RootState): Style | undefined =>
  !isNil(state[NAME].currentStyleId) ? state[NAME].styles[state[NAME].currentStyleId] : undefined;
export const getStyleById = createSelector(
  (state: RootState) => state[NAME].styles,
  (styles) => (id: Style['id'] | undefined) => (!isNil(id) ? styles?.[id] : undefined),
);
export const getStyleType = (state: RootState): StyleState['currentStyleType'] => state[NAME].currentStyleType;

export interface StyleState {
  styles: Record<Style['id'], Style>;
  currentStyleId: Nullable<Style['id']>;
  currentStyleType: typeof STYLE_TYPE_SCENE | typeof STYLE_TYPE_PERSPECTIVE;
}

export const initialState: StyleState = {
  styles: {},
  currentStyleId: null,
  currentStyleType: STYLE_TYPE_SCENE,
};

// Combined Selectors

export const getCategoryStyleForNode = createSelector(
  (state: RootState) => getCurrentStyle(state),
  (state: RootState) => getCategoryForNode(state),
  (style, mapper) =>
    (node: BloomNode): StyleCategory => {
      const cat = mapper(node);
      return (
        style?.categories?.find(({ id }) => id === cat.id) ?? { ...getOtherCategory(), id: DEFAULT_UNCATEGORISED_ID }
      );
    },
);

export const getCategoriesWithCurrentStyle = createSelector(
  (state: RootState) => getCategories(state),
  (state: RootState) => getCurrentStyle(state),
  (state: RootState) => getCategoriesWithPerspectiveStyle(state),
  (categories, style, categoriesWithPerspectiveStyle): CategoryWithStyle[] => {
    if (isNil(style)) {
      return categoriesWithPerspectiveStyle;
    }

    const perspCategories: Perspective['categories'] = cloneDeep(categories);
    const styleCat = keyBy(style.categories, 'id');

    return perspCategories?.map((cat) => ({
      ...cat,
      ...(styleCat[cat.id] ?? getOtherCategory()),
    }));
  },
);

export const getCategoriesWithPerspectiveStyle = createSelector(
  (state: RootState) => getPerspective(state),
  (state: RootState) => getStyleById(state),
  (perspective, getStyleById) => {
    if (isNil(perspective)) {
      return [];
    }

    const style = getStyleById(perspective.id);

    const perspCategories = perspective.categories;
    const styleCat = keyBy(style?.categories, 'id');

    return perspCategories?.map((cat) => ({
      ...cat,
      ...(styleCat[cat.id] ?? getOtherCategory()),
    }));
  },
);

export const getRelationshipTypesWithCurrentStyle = createSelector(
  (state: RootState) => getCurrentStyle(state),
  (state: RootState) => getRelationshipTypesWithPerspectiveStyle(state),
  (state: RootState) => getRelationshipDetails(state),
  (style, relationshipTypesWithPerspectiveStyle, perspRelTypes): RelationshipTypeWithStyle[] => {
    if (isNil(style)) {
      return relationshipTypesWithPerspectiveStyle;
    }

    const styleRelTypes = keyBy(style.relationshipTypes, 'id');

    return perspRelTypes.map((relType: PerspectiveRelationshipType) => ({
      ...relType,
      ...(styleRelTypes[relType.id] as StyleRelationshipType),
    }));
  },
);

export const getRelationshipTypesWithCurrentStyleMap = createSelector(
  (state: RootState) => getCurrentStyle(state),
  (state: RootState) => getRelationshipTypesWithPerspectiveStyle(state),
  (state: RootState) => getRelationshipDetails(state),
  (style, relationshipTypesWithPerspectiveStyle, perspRelTypes): any => {
    if (isNil(style)) {
      return relationshipTypesWithPerspectiveStyle;
    }

    const styleRelTypes = keyBy(style.relationshipTypes, 'id');

    const relTypeMap: Record<string, StyleRelationshipType> = {};

    perspRelTypes.forEach((relType: PerspectiveRelationshipType) => {
      relTypeMap[relType.name] = {
        ...relType,
        ...(styleRelTypes[relType.id] as StyleRelationshipType),
      };
    });

    return relTypeMap;
  },
);

export const getRelationshipTypesWithPerspectiveStyle = createSelector(
  (state: RootState) => getPerspective(state),
  (state: RootState) => getStyleById(state),
  (state: RootState) => getRelationshipDetails(state),
  (perspective, getStyleById, perspRelTypes): RelationshipTypeWithStyle[] => {
    if (isNil(perspective)) {
      return [];
    }

    const style = getStyleById(perspective.id);
    if (isNil(style)) {
      return [];
    }

    const styleRelTypes = keyBy(style.relationshipTypes, 'id');

    return perspRelTypes.map((relType: PerspectiveRelationshipType) => ({
      ...relType,
      ...(styleRelTypes[relType.id] as StyleRelationshipType),
    }));
  },
);

const mergePerspectiveWithStyle =
  (state: RootState) =>
  (perspectiveId?: Perspective['id'], styleId?: Style['id']): Nullable<PerspectiveWithStyle> => {
    const perspective = !isNil(perspectiveId) ? getPerspectiveById(state)(perspectiveId) : getPerspective(state);

    if (isNil(perspective)) {
      return null;
    }

    const style = getStyleById(state)(styleId ?? perspective.id);

    if (isNil(style)) {
      return null;
    }
    const perspectiveWithStyle = {
      ...perspective,
      palette: style.palette,
    };

    if (!isNil(perspective.categories)) {
      const styleCat = !isNil(style.categories) ? keyBy(style.categories, 'id') : {};
      perspectiveWithStyle.categories = perspective.categories.map<CategoryWithStyle>((cat) => ({
        ...cat,
        ...(styleCat[cat.id] ?? getOtherCategory()),
      }));
    }

    if (!isNil(perspective.relationshipTypes)) {
      const styleRelTypes = !isNil(style.relationshipTypes) ? keyBy(style.relationshipTypes, 'id') : {};
      perspectiveWithStyle.relationshipTypes = perspective.relationshipTypes?.map(
        (relType: PerspectiveRelationshipType) => Object.assign({}, relType, styleRelTypes[relType.id]),
      );
    }

    return perspectiveWithStyle as PerspectiveWithStyle;
  };
const setLatestPespectiveVersion = (perspective: Perspective) => ({
  ...perspective,
  version: getLatestPerspectiveVersion(),
});

export const getPerspectiveWithStyle = createSelector(
  mergePerspectiveWithStyle,
  (mapper) => (perspectiveId?: Perspective['id']) => mapper(perspectiveId),
);

export const getPerspectiveWithCurrentStyle = createSelector(
  getCurrentStyle,
  mergePerspectiveWithStyle,
  (style, mapper) => mapper(undefined, style?.id),
);

export const getAllSortedPerspectivesWithStyles = createSelector(
  getAllSortedPerspectives,
  getPerspectiveWithStyle,
  // @ts-expect-error perspective slice is not converted yet
  (perspectives, mapper) => perspectives.map((persp) => mapper(persp.id)),
);

export const getColorForRelMapper = createSelector(
  (state: RootState) => getCurrentStyle(state),
  (style) =>
    (rel: BloomRelationship): NonUndefined<NvlRelationship['color']> => {
      if (isNil(style) || isNil(style?.relationshipTypes)) {
        return DEFAULT_RELATIONSHIP_COLOR;
      }
      const relTypeDetails = style.relationshipTypes.find(({ id }) => id === rel.type) ?? {
        color: DEFAULT_RELATIONSHIP_COLOR,
        styleRules: undefined,
      };
      const color = relTypeDetails?.color ?? DEFAULT_RELATIONSHIP_COLOR;
      const override = getRuleBasedColor(relTypeDetails?.styleRules, rel) as string | undefined;
      return override ?? color;
    },
);

export const getSizeForRelMapper = createSelector(
  (state: RootState) => getCurrentStyle(state),
  (style) =>
    (rel: BloomRelationship): NvlRelationship['width'] => {
      if (isNil(style?.relationshipTypes)) {
        return DEFAULT_RELATIONSHIP_SIZE;
      }
      const relTypeDetails = style?.relationshipTypes.find(({ id }) => id === rel.type) ?? {
        size: DEFAULT_RELATIONSHIP_SIZE,
        styleRules: undefined,
      };
      const size = relTypeDetails?.size ?? DEFAULT_RELATIONSHIP_SIZE;
      const override = getRuleBasedSize(relTypeDetails?.styleRules, rel);
      return override !== undefined ? override : size;
    },
);

export const getIconForNodeMapper = createSelector(
  (state: RootState) => getCategoryStyleForNode(state),
  (mapper) =>
    (node: BloomNode): NvlNode['icon'] => {
      const cat = mapper(node);
      return cat?.icon;
    },
);

export const getColorForNodeMapper = createSelector(
  (state: RootState) => getCategoryStyleForNode(state),
  (mapper) =>
    (node: BloomNode): NvlNode['color'] => {
      const category = mapper(node);
      const color = category.color || DEFAULT_UNCATEGORISED_COLOR;
      const override = getRuleBasedColor(category.styleRules, node, node.labels);
      return override !== undefined ? override : color;
    },
);

export const getCaptionSizeForNodeMapper = createSelector(
  (state: RootState) => getCategoryStyleForNode(state),
  (mapper) =>
    (node: BloomNode): NvlNode['captionSize'] => {
      const category = mapper(node);

      if ('id' in category && category.id === DEFAULT_UNCATEGORISED_ID) {
        return DEFAULT_UNCATEGORISED_SIZE;
      }

      const override = getRuleBasedTextSize(category.styleRules, node, node.labels);
      return override !== undefined ? override : category.textSize;
    },
);

export const getCaptionAlignForNodeMapper = createSelector(
  (state: RootState) => getCategoryStyleForNode(state),
  (mapper) =>
    (node: BloomNode): NvlNode['captionAlign'] => {
      const category = mapper(node);

      if ('id' in category && category.id === DEFAULT_UNCATEGORISED_ID) {
        return DEFAULT_UNCATEGORISED_TEXT_ALIGN;
      }

      const alignmentOverride = getRuleBasedTextAlign(category.styleRules, node, node.labels);
      const alignmentSetting = alignmentOverride ?? category.textAlign;

      // if there is no icon, text is always in the middle
      return category.icon === 'no-icon' || isEmpty(category.icon) ? 'center' : alignmentSetting;
    },
);

export const getCaptionsForNodeMapper = createSelector(
  (state: RootState) => getCategoryStyleForNode(state),
  (state: RootState) => getCategoryForNode(state),
  (categoryStyleForNodeMapper, categoryForNodeMapper) =>
    (node: BloomNode): NvlNode['captions'] => {
      const category = categoryStyleForNodeMapper(node);
      const override = getRuleBasedCaptions(category.styleRules, node, node.labels);

      if ('id' in category && category.id === DEFAULT_UNCATEGORISED_ID) {
        return [
          {
            styles: [],
            value: `<ID> ${node.id}`,
          },
        ];
      }

      // Default caption if no configuration selected
      if (isNil(override) && isNil(category?.captions)) {
        const categoryForNode = categoryForNodeMapper(node);
        const propertyKeys = categoryForNode.properties
          .filter((property) => !property.exclude)
          .map((property) => property.name);
        const captionPropertyKey = findCaptionProperty(propertyKeys);
        const propertyValue =
          captionPropertyKey !== null ? node.mappedProperties?.[captionPropertyKey]?.value : undefined;

        return [
          {
            styles: [],
            value: typeof propertyValue === 'string' ? propertyValue : '',
          },
        ];
      }

      return (override !== undefined ? override : (category?.captions ?? [])).reduce(
        (acc: { styles: CaptionStyle[]; value?: string }[], a: Caption) => {
          if (a.isCaption) {
            const propertyValue =
              a.type === 'property'
                ? !isNil(a.key) && !isNil(node?.mappedProperties?.[a.key])
                  ? `${node.mappedProperties?.[a.key]?.value}`
                  : ''
                : a.key;
            if (a.type === 'label' && !node.labels.includes(a.key as string)) {
              acc = [...acc];
            } else if (propertyValue?.length !== 0) {
              acc = [...acc, { styles: a.styles, value: propertyValue }];
            }
          }
          return acc;
        },
        [],
      );
    },
);

export const getCaptionForNodeMapper = createSelector(
  (state: RootState) => getCategoryStyleForNode(state),
  (state: RootState) => getCategoryForNode(state),
  (categoryStyleForNodeMapper, categoryForNodeMapper) => (node: BloomNode) => {
    const category = categoryStyleForNodeMapper(node);
    const override = getRuleBasedCaptions(category.styleRules, node, node.labels);

    if ('id' in category && category.id === DEFAULT_UNCATEGORISED_ID) {
      return `<ID> ${node.id}`;
    }

    // Default caption if no configuration selected
    if (isNil(override) && isNil(category?.captions)) {
      const categoryForNode = categoryForNodeMapper(node);
      const propertyKeys = categoryForNode.properties
        .filter((property) => !property.exclude)
        .map((property) => property.name);
      const captionPropertyKey = findCaptionProperty(propertyKeys);
      const propertyValue =
        captionPropertyKey !== null ? node.mappedProperties?.[captionPropertyKey]?.value : undefined;

      return propertyValue;
    }

    const captions: Caption[] = (override !== undefined ? override : (category?.captions ?? [])).reduce(
      (acc: { styles: CaptionStyle[]; value?: string }[], a: Caption) => {
        if (a.isCaption) {
          const propertyValue =
            a.type === 'property'
              ? !isNil(a.key) && !isNil(node?.mappedProperties?.[a.key])
                ? `${node.mappedProperties?.[a.key]?.value}`
                : ''
              : a.key;
          if (a.type === 'label' && !node.labels.includes(a.key as string)) {
            acc = [...acc];
          } else if (propertyValue?.length !== 0) {
            acc = [...acc, { styles: a.styles, value: propertyValue }];
          }
        }
        return acc;
      },
      [],
    );

    return captions.map(({ value }) => value).join(', ');
  },
);

export const getCaptionSizeForRelationshipMapper = createSelector(
  (state: RootState) => getCurrentStyle(state),
  (style) =>
    (rel: BloomRelationship): NvlRelationship['width'] => {
      const relTypeStyles = style?.relationshipTypes?.find(({ id }) => id === rel.type);

      if (isNil(style?.relationshipTypes) || isNil(relTypeStyles)) {
        return DEFAULT_UNCATEGORISED_TEXT_SIZE;
      }

      const override = getRuleBasedTextSize(relTypeStyles?.styleRules, rel);
      return override !== undefined ? override : relTypeStyles.textSize;
    },
);

export const getCaptionAlignForRelationshipMapper = createSelector(
  (state: RootState) => getCurrentStyle(state),
  (style) =>
    (rel: BloomRelationship): NvlRelationship['captionAlign'] => {
      const relTypeStyles = style?.relationshipTypes?.find(({ id }) => id === rel.type);

      if (isNil(style) || isNil(style.relationshipTypes) || isNil(relTypeStyles)) {
        return DEFAULT_UNCATEGORISED_TEXT_ALIGN_RELATIONSHIP;
      }

      const override = getRuleBasedTextAlign(relTypeStyles.styleRules, rel);
      return override !== undefined ? override : relTypeStyles.textAlign;
    },
);

export const getCaptionsForRelationshipMapper = createSelector(
  (state: RootState) => getCurrentStyle(state),
  (style) =>
    (rel: BloomRelationship): NvlRelationship['captions'] => {
      if (isNil(style) || isNil(style?.relationshipTypes)) {
        return [];
      }

      const relTypeStyles = style.relationshipTypes.find(({ id }) => id === rel.type);
      if (isNil(relTypeStyles)) return [];
      const override: Caption[] | undefined = getRuleBasedCaptions(relTypeStyles.styleRules, rel);

      if (isNil(override) && isNil(relTypeStyles?.captions)) {
        return [
          {
            styles: [],
            value: rel.type,
          },
        ];
      }

      return (override ?? relTypeStyles?.captions ?? []).reduce((acc: NvlRelationship['captions'] = [], a: Caption) => {
        if (a.isCaption) {
          const propertyValue =
            a.type === 'property'
              ? !isNil(a.key) && !isNil(rel?.mappedProperties?.[a?.key])
                ? `${rel.mappedProperties?.[a.key]?.value}`
                : ''
              : a.key;

          if (!isNil(propertyValue)) {
            const styles = a.styles as [];
            acc = [...acc, { styles, value: propertyValue }];
          }
        }
        return acc;
      }, []);
    },
);

export const getCaptionForRelationshipMapper = createSelector(
  (state: RootState) => getCurrentStyle(state),
  (style) =>
    (rel: BloomRelationship): string => {
      if (isNil(style) || isNil(style?.relationshipTypes)) {
        return '';
      }

      const relTypeStyles = style.relationshipTypes.find(({ id }) => id === rel.type);
      if (isNil(relTypeStyles)) return '';
      const override = getRuleBasedCaptions(relTypeStyles.styleRules, rel);

      if (isNil(override) && isNil(relTypeStyles?.captions)) {
        return rel.type;
      }

      const captions = (override !== undefined ? override : (relTypeStyles?.captions ?? [])).reduce(
        (
          acc: {
            value: string;
            styles: CaptionStyle[];
          }[],
          a: Caption,
        ) => {
          if (a.isCaption) {
            const propertyValue =
              a.type === 'property'
                ? !isNil(a.key) && !isNil(rel?.mappedProperties?.[a?.key])
                  ? `${rel.mappedProperties?.[a.key]?.value}`
                  : ''
                : a.key;

            if (!isNil(propertyValue)) {
              acc = [...acc, { styles: a.styles, value: propertyValue }];
            }
          }
          return acc;
        },
        [],
      );

      return captions.map(({ value }: { value: string }) => value).join(', ');
    },
);

export const getUniqueValuesForStyleRules = createSelector(
  (state: RootState) => getCategoryStyleForNode(state),
  (mapper) => (nodes: BloomNode[]) => {
    const result: Record<string, Partial<StyleRule>> = {};

    for (const node of nodes) {
      const category = mapper(node);
      const colorByValueRules: StyleRule[] | undefined = category?.styleRules?.filter(
        (rule: StyleRule) => rule.type === 'unique-values',
      );

      if (!isNil(colorByValueRules) && colorByValueRules?.length > 0) {
        colorByValueRules.forEach((colorByValueRule) => {
          const property = getTitleFromRuleBase(colorByValueRule.basedOn);

          result[colorByValueRule.id] = result[colorByValueRule.id] ?? { valuesMapper: [], existingValues: [] };
          const singleRule = result[colorByValueRule.id];

          if (singleRule === undefined) return;
          if (singleRule.valuesMapper && singleRule.valuesMapper.length >= COLOR_MAPPING_THRESHOLD) {
            const value = singleRule.valuesMapper[singleRule.valuesMapper.length - 1];
            if (!value) return;
            value.hasMore = true;
          } else {
            const nodeValue = node.mappedProperties?.[property]?.value;
            if (
              !isNil(nodeValue) &&
              singleRule.existingValues &&
              singleRule.valuesMapper &&
              !singleRule.existingValues.includes(nodeValue as string)
            ) {
              const color = fullPallete[singleRule.existingValues.length % fullPallete.length];
              color !== undefined &&
                singleRule.valuesMapper.push({
                  value: nodeValue,
                  color,
                  hasMore: false,
                });
              singleRule.existingValues.push(nodeValue as string);
            }
          }
        });
      }
    }
    return result;
  },
);

export const getSizeForNodeMapper = createSelector(
  (state: RootState) => getCategoryStyleForNode(state),
  (mapper) =>
    (node: BloomNode): NvlNode['size'] => {
      const category = mapper(node);
      const size = category.size || DEFAULT_UNCATEGORISED_SIZE;
      const override = getRuleBasedSize(category.styleRules, node, node.labels);
      return override ?? size;
    },
);

export const getColorForCategoryMapper = createSelector(
  (state: RootState) => getCurrentStyle(state),
  (style) => (categoryId: Category['id']) => {
    const category = style?.categories?.find(({ id }) => id === categoryId);
    return category?.color ?? DEFAULT_UNCATEGORISED_COLOR;
  },
);

export const getCaptionsFromCategoryMapper = createSelector(
  (state: RootState) => getCurrentStyle(state),
  (style) => (categoryId: Category['id']) => {
    if (isNil(style)) return [];
    const category = style?.categories?.find(({ id }) => id === categoryId);
    return category?.captions ?? [];
  },
);

export const getCaptionsFromRelationshipMapper = createSelector(
  (state: RootState) => getCurrentStyle(state),
  (style) => (relType: string) => {
    if (isNil(style)) return [];
    const relationship = style?.relationshipTypes?.find(({ id }) => id === relType);
    return relationship?.captions ?? [];
  },
);

export const getColorForCategoryMapperByName = createSelector(
  (state: RootState) => getCurrentStyle(state),
  (state: RootState) => getCategoryByName(state),
  (style, categoryMapper) => (categoryName: Category['name']) => {
    const category = categoryMapper(categoryName);
    if (isNil(style)) return DEFAULT_UNCATEGORISED_COLOR;
    const styleCategory = style?.categories?.find(({ id }) => id === category?.id);
    return styleCategory?.color ?? DEFAULT_UNCATEGORISED_COLOR;
  },
);

export const getColorForLabelMapper = (state: RootState) => (label: any, fallbackToDefault: boolean | undefined) => {
  const category = getCategoryForLabel(label, state, fallbackToDefault);
  const style = getCurrentStyle(state);
  const styleCategory = style?.categories?.find(({ id }) => id === category?.id);
  return styleCategory?.color ?? DEFAULT_UNCATEGORISED_COLOR;
};

export const getStyleRulesForCategory = (state: RootState, catId: Category['id']) => {
  const style = getCurrentStyle(state);
  if (isNil(style)) return undefined;
  const styleCategory = style?.categories?.find(({ id }: { id: StyleCategory['id'] }) => id === catId);
  return styleCategory?.styleRules;
};

const stylesSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    addCategories(
      state,
      action: PayloadAction<{
        categorieIds: Category['id'][];
        styleId?: Style['id'];
      }>,
    ) {
      const { categorieIds, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      const palette = style.palette ?? { colors: defaultPalette, currentIndex: 0 };
      style.categories = style.categories ?? [];
      for (const id of categorieIds) {
        const color = palette.colors[palette.currentIndex % palette.colors.length];
        color !== undefined &&
          style.categories.push({
            ...getOtherCategory(),
            id,
            color,
          });
        palette.currentIndex += 1;
      }
      state.styles[actionStyleId] = style;
    },
    removeCategories(
      state,
      action: PayloadAction<{
        categorieIds: Category['id'][];
        styleId?: Style['id'];
      }>,
    ) {
      const { categorieIds, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      style.categories = style.categories?.filter(({ id }) => !categorieIds.includes(id)) ?? [];
      state.styles[actionStyleId] = style;
    },
    addRelationshipTypes(
      state,
      action: PayloadAction<{ relationshipTypes: PerspectiveRelationshipType['id'][]; styleId?: Style['id'] }>,
    ) {
      const { relationshipTypes, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      if (isNil(style.relationshipTypes)) style.relationshipTypes = [];
      style.relationshipTypes = [
        ...style.relationshipTypes,
        ...relationshipTypes.map((id) => ({ ...getDefaultRelStyling(id), id })),
      ];
      state.styles[actionStyleId] = style;
    },
    removeRelationshipTypes(
      state,
      action: PayloadAction<{ relationshipTypes: PerspectiveRelationshipType['id'][]; styleId?: Style['id'] }>,
    ) {
      const { relationshipTypes, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId) || isNil(style.relationshipTypes)) return;
      style.relationshipTypes = style.relationshipTypes.filter(({ id }) => !relationshipTypes.includes(id)) ?? [];
      state.styles[actionStyleId] = style;
    },
    setStyleById(state, action: PayloadAction<{ styleId: Style['id']; style: Style }>) {
      const { styleId, style } = action.payload;
      state.styles[styleId] = style;
    },
    setCurrentStyleId(state, action: PayloadAction<Style['id']>) {
      const styleId = action.payload;
      state.currentStyleType = state.styles[styleId]?.type ?? STYLE_TYPE_PERSPECTIVE;
      state.currentStyleId = styleId;
    },
    addStyleRuleForCategory(
      state,
      action: PayloadAction<{
        categoryId: Category['id'];
        styleRule: StyleRule;
      }>,
    ) {
      const { categoryId, styleRule } = action.payload;
      const { currentStyleId } = state;
      const style = !isNil(currentStyleId) ? state.styles[currentStyleId] : undefined;
      const category =
        !isNil(style) && !isNil(categoryId) ? style.categories?.find(({ id }) => id === categoryId) : undefined;
      if (!isNil(category)) {
        category.styleRules = [...(category.styleRules ?? []), styleRule];
      }
    },
    addStyleRuleForRelationship(
      state,
      action: PayloadAction<{
        relType: PerspectiveRelationshipType['id'];
        styleRule: StyleRule;
      }>,
    ) {
      const { relType, styleRule } = action.payload;
      const { currentStyleId } = state;
      const style = !isNil(currentStyleId) ? state.styles[currentStyleId] : undefined;
      if (isNil(style)) return;
      if (isNil(style.relationshipTypes)) style.relationshipTypes = [];
      const currentRelType = style.relationshipTypes.find(({ id }) => id === relType);
      if (!isNil(currentRelType)) {
        currentRelType.styleRules = [...(currentRelType.styleRules ?? []), styleRule];
      }
    },
    deleteStyleRuleForCategory(
      state,
      action: PayloadAction<{
        categoryId: Category['id'];
        styleRuleIndex: number;
      }>,
    ) {
      const { categoryId, styleRuleIndex } = action.payload;
      const { currentStyleId } = state;
      const style = !isNil(currentStyleId) ? state.styles[currentStyleId] : undefined;
      const category = style?.categories?.find(({ id }) => id === categoryId);
      if (isNil(style) || isNil(category) || isNil(style.categories)) return;
      category.styleRules?.splice(styleRuleIndex, 1);
    },
    deleteStyleRulesByRuleId(state, action: PayloadAction<{ styleRuleId: StyleRule['id']; styleId?: Style['id'] }>) {
      const { styleRuleId, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      style.categories?.forEach((category) => {
        const index = category.styleRules?.findIndex(({ id }) => id === styleRuleId);
        if (index !== undefined && index >= 0) category.styleRules?.splice(index, 1);
      });
      state.styles[actionStyleId] = style;
    },
    deleteStyleRuleForRelationship(
      state,
      action: PayloadAction<{
        styleRuleIndex: number;
        relType: string;
      }>,
    ) {
      const { styleRuleIndex, relType } = action.payload;
      const { currentStyleId } = state;
      const style = !isNil(currentStyleId) ? state.styles[currentStyleId] : undefined;
      if (isNil(style) || isNil(style.relationshipTypes)) return;
      const relTypeDetails = style.relationshipTypes.find(({ id }) => id === relType);
      if (isNil(relTypeDetails)) return;
      relTypeDetails.styleRules?.splice(styleRuleIndex, 1);
    },
    updateColorForCategory(
      state,
      action: PayloadAction<{
        categoryId: Category['id'];
        newColor: string;
        styleId?: Style['id'];
      }>,
    ) {
      const { categoryId, newColor, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      style.categories = style.categories?.map((category) => {
        if (category.id === categoryId) {
          return {
            ...category,
            color: newColor,
          };
        }
        return category;
      });
      state.styles[actionStyleId] = style;
    },
    updateStyleRuleForCategory(
      state,
      action: PayloadAction<{
        categoryId: Category['id'];
        styleRuleUpdate: Partial<StyleRule>;
        styleRuleIndex: number;
        styleId?: Style['id'];
      }>,
    ) {
      const { categoryId, styleRuleUpdate, styleRuleIndex, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      style.categories = style.categories?.map((category) => {
        if (category.id === categoryId) {
          return {
            ...category,
            styleRules: category.styleRules.map((rule, index) => {
              if (index === styleRuleIndex) {
                return {
                  ...rule,
                  ...styleRuleUpdate,
                };
              }
              return rule;
            }),
          };
        }
        return category;
      });
      state.styles[actionStyleId] = style;
    },
    updateStyleRuleForRelationship(
      state,
      action: PayloadAction<{
        relType: BloomRelationship['id'];
        styleRuleUpdate: Partial<StyleRule>;
        styleRuleIndex: number;
        styleId?: Style['id'];
      }>,
    ) {
      const { relType, styleRuleUpdate, styleRuleIndex, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      if (isNil(style.relationshipTypes)) style.relationshipTypes = [];
      style.relationshipTypes = style.relationshipTypes.map((rel) => {
        if (rel.id === relType) {
          return {
            ...rel,
            styleRules: rel.styleRules?.map((rule, index) => {
              if (index === styleRuleIndex) {
                return {
                  ...rule,
                  ...styleRuleUpdate,
                };
              }
              return rule;
            }),
          };
        }
        return rel;
      });
      state.styles[actionStyleId] = style;
    },
    updateSizeForCategory(
      state,
      action: PayloadAction<{
        categoryId: Category['id'];
        newSize: number;
        styleId?: Style['id'];
      }>,
    ) {
      const { categoryId, newSize, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      style.categories = style.categories?.map((category) => {
        if (category.id === categoryId) {
          return {
            ...category,
            size: newSize,
          };
        }
        return category;
      });
      const actionStyle = state.styles[actionStyleId];
      if (actionStyle === undefined) return;
      actionStyle.categories = style.categories;
    },
    updateTextSizeForCategory(
      state,
      action: PayloadAction<{
        categoryId: Category['id'];
        newSize: number;
        styleId?: Style['id'];
      }>,
    ) {
      const { categoryId, newSize, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      style.categories = style.categories?.map((category) => {
        if (category.id === categoryId) {
          return {
            ...category,
            textSize: newSize,
          };
        }
        return category;
      });
      state.styles[actionStyleId] = style;
    },
    updateTextAlignmentForCategory(
      state,
      action: PayloadAction<{
        categoryId: Category['id'];
        newAlignment: string;
        styleId?: Style['id'];
      }>,
    ) {
      const { categoryId, newAlignment, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      style.categories = style.categories?.map((category) => {
        if (category.id === categoryId) {
          return {
            ...category,
            textAlign: newAlignment,
          };
        }
        return category;
      });
      state.styles[actionStyleId] = style;
    },
    updateColorForRelType(
      state,
      action: PayloadAction<{
        relType: string;
        newColor: string;
        styleId?: Style['id'];
      }>,
    ) {
      const { relType, newColor, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      style.relationshipTypes = style.relationshipTypes?.map((rel) => {
        if (rel.id === relType) {
          return {
            ...rel,
            color: newColor,
          };
        }
        return rel;
      });
      state.styles[actionStyleId] = style;
    },
    updateSizeForRelType(
      state,
      action: PayloadAction<{
        relType: string;
        newSize: number;
        styleId?: Style['id'];
      }>,
    ) {
      const { relType, newSize, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      style.relationshipTypes = style.relationshipTypes?.map((rel) => {
        if (rel.id === relType) {
          return {
            ...rel,
            size: newSize,
          };
        }
        return rel;
      });
      state.styles[actionStyleId] = style;
    },
    updateTextSizeForRelType(
      state,
      action: PayloadAction<{
        relType: string;
        newSize: number;
        styleId?: Style['id'];
      }>,
    ) {
      const { relType, newSize, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      style.relationshipTypes = style.relationshipTypes?.map((rel) => {
        if (rel.id === relType) {
          return {
            ...rel,
            textSize: newSize,
          };
        }
        return rel;
      });
      state.styles[actionStyleId] = style;
    },
    updateTextAlignmentForRelType(
      state,
      action: PayloadAction<{
        relType: string;
        newAlignment: string;
        styleId?: Style['id'];
      }>,
    ) {
      const { relType, newAlignment, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      style.relationshipTypes = style.relationshipTypes?.map((rel) => {
        if (rel.id === relType) {
          return {
            ...rel,
            textAlign: newAlignment,
          };
        }
        return rel;
      });
      state.styles[actionStyleId] = style;
    },
    updateIconForCategory(
      state,
      action: PayloadAction<{ categoryId: Category['id']; newIcon: CategoryIcon; styleId?: Style['id'] }>,
    ) {
      const { categoryId, newIcon, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      style.categories = style.categories?.map((category) => {
        if (category.id === categoryId) {
          return {
            ...category,
            icon: newIcon,
          };
        }
        return category;
      });
      state.styles[actionStyleId] = style;
    },
    updateCaptionStylesForCategory(
      state,
      action: PayloadAction<{ categoryId: Category['id']; caption: Caption; styles: CaptionStyle[] }>,
    ) {
      const { categoryId, caption, styles } = action.payload;
      const actionStyleId = state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      style.categories = style.categories?.map((category) => {
        if (category.id === categoryId) {
          const captionToUpdate = category.captions?.find((c) => c.key === caption.key && c.type === caption.type);
          if (captionToUpdate !== undefined) {
            captionToUpdate.styles = styles;
          }
          return { ...category };
        }
        return category;
      });
      state.styles[actionStyleId] = style;
    },
    updateCaptionTooltipForCategory(
      state,
      action: PayloadAction<{ categoryId: Category['id']; caption: Caption; styleId?: Style['id'] }>,
    ) {
      const { categoryId, caption, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      style.categories = style.categories?.map((category) => {
        if (category.id === categoryId) {
          return {
            ...category,
            captions: category.captions.map((c) => {
              if (c.key === caption?.key && c.type === caption?.type) {
                return {
                  ...c,
                  inTooltip: !caption.inTooltip,
                };
              }
              return c;
            }),
          };
        }
        return category;
      });
      state.styles[actionStyleId] = style;
    },
    addCaptionKeyForCategory(
      state,
      action: PayloadAction<{ categoryId: Category['id']; caption: Caption; styleId?: Style['id'] }>,
    ) {
      const { categoryId, caption, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      style.categories = style.categories?.map((category) => {
        if (category.id === categoryId) {
          return {
            ...category,
            captions: category.captions.reduce((acc: Caption[], c) => {
              if (c.key === caption?.key && c.type === caption?.type) {
                acc = [...acc, { ...c, isCaption: true }];
              } else {
                acc = [...acc, c];
              }
              return acc;
            }, []),
          };
        }
        return category;
      });
      state.styles[actionStyleId] = style;
    },
    removeCaptionKeyForCategory(
      state,
      action: PayloadAction<{ categoryId: Category['id']; caption: Caption; styleId?: Style['id'] }>,
    ) {
      const { categoryId, caption, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      style.categories = style.categories?.map((category) => {
        if (category.id === categoryId) {
          return {
            ...category,
            captions: category.captions.reduce((acc: Caption[], c) => {
              if (c.key === caption?.key && c.type === caption?.type) {
                acc = [...acc, { ...c, isCaption: false }];
              } else {
                acc = [...acc, c];
              }
              return acc;
            }, []),
          };
        }
        return category;
      });
      state.styles[actionStyleId] = style;
    },
    forceRemoveCaptionKeyForCategory(
      state,
      action: PayloadAction<{ categoryId: Category['id']; caption: Caption; styleId?: Style['id'] }>,
    ) {
      const { categoryId, caption, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      style.categories = style.categories?.map((category) => {
        if (category.id === categoryId) {
          return {
            ...category,
            captions: category.captions.filter((c) => c.key !== caption.key),
          };
        }
        return category;
      });
      state.styles[actionStyleId] = style;
    },
    forceRemoveCaptionKeyForRelType(
      state,
      action: PayloadAction<{ relType: string; caption: Caption; styleId?: Style['id'] }>,
    ) {
      const { relType, caption, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      style.relationshipTypes = style.relationshipTypes?.map((rel) => {
        if (rel.id === relType) {
          return {
            ...rel,
            captions: rel.captions.filter((c) => c.key !== caption.key),
          };
        }
        return rel;
      });
      state.styles[actionStyleId] = style;
    },
    addCaptionKeyForRelType(
      state,
      action: PayloadAction<{ relType: string; caption: Caption; styleId?: Style['id'] }>,
    ) {
      const { relType, caption, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      style.relationshipTypes = style.relationshipTypes?.map((rel) => {
        if (rel.id === relType) {
          return {
            ...rel,
            captions: rel.captions.reduce((acc: Caption[], c) => {
              if (c.key === caption?.key && c.type === caption?.type) {
                acc = [...acc, { ...c, isCaption: true }];
              } else {
                acc = [...acc, c];
              }
              return acc;
            }, []),
          };
        }
        return rel;
      });
      state.styles[actionStyleId] = style;
    },
    removeCaptionKeyForRelType(
      state,
      action: PayloadAction<{ relType: string; caption: Caption; styleId?: Style['id'] }>,
    ) {
      const { relType, caption, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      style.relationshipTypes = style.relationshipTypes?.map((rel) => {
        if (rel.id === relType) {
          return {
            ...rel,
            captions: rel.captions.reduce((acc: Caption[], c) => {
              if (c.key === caption?.key && c.type === caption?.type) {
                acc = [...acc, { ...c, isCaption: false }];
              } else {
                acc = [...acc, c];
              }
              return acc;
            }, []),
          };
        }
        return rel;
      });
      state.styles[actionStyleId] = style;
    },
    updateCaptionStylesForRelType(
      state,
      action: PayloadAction<{ relType: string; captionKey: string; styles: CaptionStyle[]; styleId?: Style['id'] }>,
    ) {
      const { relType, captionKey, styles, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      style.relationshipTypes = style.relationshipTypes?.map((rel) => {
        if (rel.id === relType) {
          return {
            ...rel,
            captions: rel.captions.map((c) => {
              if (c.key === captionKey) {
                return {
                  ...c,
                  styles,
                };
              }
              return c;
            }),
          };
        }
        return rel;
      });
      state.styles[actionStyleId] = style;
    },
    updateCaptionTooltipForRelType(
      state,
      action: PayloadAction<{ relType: string; caption: Caption; styleId?: Style['id'] }>,
    ) {
      const { relType, caption, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      style.relationshipTypes = style.relationshipTypes?.map((rel) => {
        if (rel.id === relType) {
          return {
            ...rel,
            captions: rel.captions.map((c) => {
              if (c.key === caption?.key && c.type === caption?.type) {
                return {
                  ...c,
                  inTooltip: !caption.inTooltip,
                };
              }
              return c;
            }),
          };
        }
        return rel;
      });
      state.styles[actionStyleId] = style;
    },
    updateOrderOfCaptionsForCategory(
      state,
      action: PayloadAction<{
        categoryId: Category['id'];
        fromSceneActionIndex: number;
        toSceneActionIndex: number;
      }>,
    ) {
      const { categoryId, fromSceneActionIndex, toSceneActionIndex } = action.payload;
      const actionStyleId = state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      style.categories = style.categories?.map((category) => {
        if (category.id === categoryId) {
          const [removed] = category.captions.splice(fromSceneActionIndex, 1);
          removed && category.captions.splice(toSceneActionIndex, 0, removed);
        }
        return category;
      });
      state.styles[actionStyleId] = style;
    },
    updateOrderOfCaptionsForRelationship(
      state,
      action: PayloadAction<{
        relType: string;
        fromSceneActionIndex: number;
        toSceneActionIndex: number;
      }>,
    ) {
      const { relType, fromSceneActionIndex, toSceneActionIndex } = action.payload;
      const actionStyleId = state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      style.relationshipTypes = style.relationshipTypes?.map((rel) => {
        if (rel.id === relType) {
          const [removed] = rel.captions.splice(fromSceneActionIndex, 1);
          removed && rel.captions.splice(toSceneActionIndex, 0, removed);
        }
        return rel;
      });
      state.styles[actionStyleId] = style;
    },
    updateOrderOfCaptionsForCategoryRule(
      state,
      action: PayloadAction<{
        categoryId: Category['id'];
        fromSceneActionIndex: number;
        toSceneActionIndex: number;
        styleRuleIndex: number;
      }>,
    ) {
      const { categoryId, fromSceneActionIndex, toSceneActionIndex, styleRuleIndex } = action.payload;
      const actionStyleId = state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      const actionStyle = state.styles[actionStyleId];
      if (actionStyle === undefined) return;
      actionStyle.categories = actionStyle.categories?.map((category) => {
        if (category.id === categoryId && !isNil(category.styleRules)) {
          const categoryStyleRule = category.styleRules[styleRuleIndex];
          if (categoryStyleRule === undefined) return category;
          categoryStyleRule.captions = getOrderedList(
            [...(categoryStyleRule.captions ?? [])],
            fromSceneActionIndex,
            toSceneActionIndex,
          );
        }
        return category;
      });
    },
    updateOrderOfCaptionsForRelationshipRule(
      state,
      action: PayloadAction<{
        relType: string;
        fromSceneActionIndex: number;
        toSceneActionIndex: number;
        styleRuleIndex: number;
      }>,
    ) {
      const { relType, fromSceneActionIndex, toSceneActionIndex, styleRuleIndex } = action.payload;
      const actionStyleId = state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      const actionStyle = state.styles[actionStyleId];
      if (actionStyle === undefined) return;
      actionStyle.relationshipTypes = actionStyle.relationshipTypes?.map((rel) => {
        if (rel.id === relType && !isNil(rel.styleRules)) {
          const relStyleRule = rel.styleRules[styleRuleIndex];
          if (relStyleRule === undefined) return rel;
          relStyleRule.captions = getOrderedList(
            [...(relStyleRule.captions ?? [])],
            fromSceneActionIndex,
            toSceneActionIndex,
          );
        }
        return rel;
      });
    },
    setCaptionsForCategory(
      state,
      action: PayloadAction<{ categoryId: Category['id']; newCaptions: Caption[]; styleId?: Style['id'] }>,
    ) {
      const { categoryId, newCaptions, styleId } = action.payload;
      const actionStyleId = styleId ?? state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      const actionStyle = state.styles[actionStyleId];
      if (actionStyle === undefined) return;
      actionStyle.categories = actionStyle.categories?.map((category) => {
        if (category.id === categoryId) {
          return {
            ...category,
            captions: newCaptions,
          };
        }
        return category;
      });
    },
    setCaptionsForRelationship(state, action: PayloadAction<{ relType: string; newCaptions: Caption[] }>) {
      const { relType, newCaptions } = action.payload;
      const actionStyleId = state.currentStyleId;
      const style = !isNil(actionStyleId) ? state.styles[actionStyleId] : undefined;
      if (isNil(style) || isNil(actionStyleId)) return;
      const actionStyle = state.styles[actionStyleId];
      if (actionStyle === undefined) return;
      actionStyle.relationshipTypes = actionStyle.relationshipTypes?.map((rel) => {
        if (rel.id === relType) {
          return {
            ...rel,
            captions: newCaptions,
          };
        }
        return rel;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action: AnyAction) => {
      if (isNil(action.payload) || Object.keys(action.payload).length === 0) return;
      if (Array.isArray(action.payload.perspectives?.perspectives)) {
        const migrated = migrate(action.payload.perspectives, trasformations, action.payload.version);

        const perspectiveStyles = (
          migrated.perspectives.map(setLatestPespectiveVersion) as Nullable<PerspectiveWithStyle>[]
        )
          .filter((persp): persp is PerspectiveWithStyle => !isNil(persp))
          .map(getStyleEntryFromPerspective);
        state.styles = keyBy(perspectiveStyles, 'id');

        if (!isNil(action.payload.currentPerspectiveId)) {
          state.currentStyleType = STYLE_TYPE_PERSPECTIVE;
          state.currentStyleId = action.payload.currentPerspectiveId;
        }
        if (!isNil(action.payload.scene?.currentSceneId)) {
          state.currentStyleType = STYLE_TYPE_SCENE;
          state.currentStyleId = action.payload.scene?.currentSceneId;
        }
      }
      const styles = action.payload?.styles?.styles as Record<string, Style> | undefined;
      if (styles) {
        state.styles = { ...state.styles, ...styles };
      }
    });
    builder.addCase(addPerspective, (state, action) => {
      const perspective = action.payload;
      if (isNil(perspective)) return;
      // @ts-expect-error TODO typing for this is broken, because we run this on perspectives without styles as well
      const style = getStyleEntryFromPerspective(perspective);
      state.styles[style.id] = style;
    });
    builder.addCase(loadPerspective, (state, action) => {
      const perspective = action.payload;
      if (isNil(perspective)) return;
      const style = getStyleEntryFromPerspective(perspective);
      state.styles[style.id] = style;
    });
    builder.addCase(removePerspective, (state, action) => {
      const { perspectiveId } = action.payload;
      delete state.styles[perspectiveId];
    });
    builder.addCase(clearPerspectives, (state) => {
      Object.values(state.styles)
        .filter(({ type }) => type === STYLE_TYPE_PERSPECTIVE)
        .map(({ id }) => id)
        .forEach((id) => delete state.styles[id]);
    });
    builder.addCase(addScene, (state, action) => {
      const scene = action.payload;
      if (!isNil(scene.style)) {
        const styleSceneEntry = getStyleEntryFromScene(scene);
        state.styles[styleSceneEntry.id] = styleSceneEntry;
      }
    });
    builder.addCase(duplicateScene, (state, action) => {
      const scene = action.payload;
      if (!isNil(scene.style)) {
        const styleSceneEntry = getStyleEntryFromScene(scene);
        state.styles[styleSceneEntry.id] = styleSceneEntry;
      }
    });
    builder.addCase(removeScene, (state, action) => {
      const sceneId = action.payload;
      delete state.styles[sceneId];
    });
    builder.addCase(clearScenes, (state) => {
      Object.values(state.styles)
        .filter(({ type }) => type === STYLE_TYPE_SCENE)
        .map(({ id }) => id)
        .forEach((id) => delete state.styles[id]);
    });
  },
});

export const {
  addCategories,
  removeCategories,
  addRelationshipTypes,
  removeRelationshipTypes,
  setStyleById,
  setCurrentStyleId,
  addStyleRuleForCategory,
  addStyleRuleForRelationship,
  deleteStyleRuleForCategory,
  deleteStyleRulesByRuleId,
  deleteStyleRuleForRelationship,
  updateColorForCategory,
  updateStyleRuleForCategory,
  updateStyleRuleForRelationship,
  updateSizeForCategory,
  updateTextSizeForCategory,
  updateTextAlignmentForCategory,
  updateColorForRelType,
  updateSizeForRelType,
  updateTextSizeForRelType,
  updateTextAlignmentForRelType,
  updateIconForCategory,
  updateCaptionStylesForCategory,
  updateCaptionTooltipForCategory,
  addCaptionKeyForCategory,
  removeCaptionKeyForCategory,
  forceRemoveCaptionKeyForCategory,
  forceRemoveCaptionKeyForRelType,
  addCaptionKeyForRelType,
  removeCaptionKeyForRelType,
  updateCaptionStylesForRelType,
  updateCaptionTooltipForRelType,
  updateOrderOfCaptionsForCategory,
  updateOrderOfCaptionsForRelationship,
  updateOrderOfCaptionsForCategoryRule,
  updateOrderOfCaptionsForRelationshipRule,
  setCaptionsForCategory,
  setCaptionsForRelationship,
} = stylesSlice.actions;

export default stylesSlice.reducer;

export type StylesAction = SliceActionsUnion<typeof stylesSlice.actions>;
