import type { Integer, Point } from 'neo4j-driver';
import neo4j from 'neo4j-driver';

import { getSpatioTemporalObject } from '../../services/queries/mappers';
import type {
  PossibleValues,
  SpatialObjectDate,
  SpatialObjectDateTime,
  SpatialObjectDuration,
  SpatialObjectLocalDateTime,
  SpatialObjectLocalTime,
  SpatialObjectPoint,
  SpatialObjectTime,
  WrappedValues,
} from '../../services/queries/mappers.types';
import type { Node, Relationship } from '../../types/graph';
import { hasOwnProperty, isTruthy } from '../../types/utility';

const aliasPrefixes = 'abcdefghijklmnopqrstuvwxyz'.split('');
const defaultAliasMaxNum = 99;

export const getSafeBackTicksString = (string: string) => string.replace(/`/g, '``');
export const getEscapedSingleQuoteString = (string: string) => string.replace(/\\/g, '\\\\').replace(/'/g, "\\'");
export const getEscapedDoubleQuoteString = (string = '') => string.replace(/\\/g, '\\\\').replace(/"/g, '\\"');
export const getPropValueParameterString = (propertyKey: string) => propertyKey.replace(/ /g, '_');

export interface Graph {
  nodes: Node[];
  relationships: Relationship[];
}

interface MappedNode extends Node {
  alias: string;
  visited: boolean;
  label: () => string;
  toString: () => string;
}
export const graphToCypher = (graph: Graph) => {
  if (graph.nodes.length === 0) {
    return null;
  }

  let currentPrefix = 0;
  let currentAliasNo = 0;
  let aliasMaxNum = defaultAliasMaxNum;
  const aliases: string[] = [];

  const nextAlias = () => {
    if (currentAliasNo > aliasMaxNum) {
      currentPrefix++;

      if (currentPrefix === aliasPrefixes.length) {
        currentPrefix = 0;
        aliasMaxNum = Number.MAX_SAFE_INTEGER;
      } else {
        currentAliasNo = 0;
      }
    }

    return `${aliasPrefixes[currentPrefix]}${currentAliasNo++}`;
  };

  const nodesMap = graph.nodes.reduce<Record<string, MappedNode>>((map, node) => {
    const mappedNode = {
      ...node,
      alias: nextAlias(),
      visited: false,
      label: function () {
        return typeof this.labels[0] === 'string' ? this.labels[0] : '';
      },
      toString: function () {
        return `(${graphItemToString(this.properties, this.alias, this.label(), this.visited)})`;
      },
    };
    map[node.id] = mappedNode;
    aliases.push(mappedNode.alias);
    return map;
  }, {});

  const patternLines: string[] = [];

  graph.relationships.forEach((rel) => {
    const source = nodesMap[rel.startId];
    const target = nodesMap[rel.endId];

    const relationship = {
      ...rel,
      visited: true,
      alias: nextAlias(),
      toString: function () {
        return `[${graphItemToString(this.properties, this.alias, this.type, false)}]`;
      },
    };

    const pairPattern = `MATCH ${source?.toString()}-${relationship.toString()}->${target?.toString()}`;
    patternLines.push(pairPattern);
    aliases.push(relationship.alias);

    if (source) {
      source.visited = true;
    }
    if (target) {
      target.visited = true;
    }
  });

  Object.values(nodesMap).forEach((node) => {
    if (!node.visited) {
      patternLines.push(`MATCH ${node.toString()}`);
    }
  });

  const cypher = `${patternLines.join('\n')}
return ${aliases.join(', ')}`;

  return cypher;
};

const graphItemToString = (
  properties: Node['properties'] | Relationship['properties'],
  alias: string,
  type: string,
  visited = false,
) => {
  let propsString = '';

  if (!visited && isTruthy(properties)) {
    propsString = Object.keys(properties)
      .filter((k) => properties[k])
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-base-to-string
      .map((key) => `${key}: ${wrapValue(properties[key])}`)
      .join(', ');
    if (propsString.length > 0) {
      propsString = ` {${propsString}}`;
    }
  }

  return `${alias}${visited ? '' : `:${type}`}${propsString}`;
};

const isValidValue = <Type>(val: Type): val is NonNullable<Type> => val !== undefined && val !== null;
const isLatLongPoint = <Type extends Point<number | Integer | bigint>>(
  point: Type,
): point is Type & { latitude: NonNullable<unknown>; longitude: NonNullable<unknown> } =>
  // @ts-expect-error this might be an error since Point is not supposed to have latitude or longitude
  isValidValue(point.latitude) && isValidValue(point.longitude);
const buildOptionalPropertyString = (point: Point, key: keyof Point) =>
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  isValidValue(point[key]) ? `, ${key}: ${point[key]}` : '';

const wrapValue = (value: PossibleValues): WrappedValues => {
  const spatioTemporalObject = getSpatioTemporalObject(value);

  if (spatioTemporalObject !== null) {
    const val = spatioTemporalObject.value;
    switch (spatioTemporalObject.type) {
      case 'Point':
        const coords = isLatLongPoint(val as SpatialObjectPoint['value'])
          ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            `{ srid: ${(val as SpatialObjectPoint['value']).srid}, latitude: ${
              // @ts-expect-error might be an error - Point is not supposed to have 'latitude' or 'longitude'
              (val as SpatialObjectPoint['value']).latitude
              // @ts-expect-error might be an error - Point is not supposed to have 'latitude' or 'longitude'
            }, longitude: ${(val as SpatialObjectPoint['value']).longitude} ${buildOptionalPropertyString(
              // @ts-expect-error might be an error - Point is not supposed to have 'height'
              val as SpatialObjectPoint['value'],
              'height',
            )} }`
          : // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            `{ srid: ${(val as SpatialObjectPoint['value']).srid}, x: ${(val as SpatialObjectPoint['value']).x}, y: ${
              (val as SpatialObjectPoint['value']).y
              // @ts-expect-error might be an error - Point is not supposed to have 'height'
            } ${buildOptionalPropertyString(val as SpatialObjectPoint['value'], 'height')} }`;

        return `point(${coords})`;
      case 'Date':
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return `date({ year: ${(val as SpatialObjectDate['value']).year}, month: ${
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          (val as SpatialObjectDate['value']).month
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        }, day: ${(val as SpatialObjectDate['value']).day} })`;
      case 'DateTime':
        const timezoneString =
          'timeZoneId' in (val as SpatialObjectDateTime['value'])
            ? `, timezone: ${(val as SpatialObjectDateTime['value']).timeZoneId}`
            : '';
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return `datetime({ year: ${(val as SpatialObjectDateTime['value']).year}, month: ${
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          (val as SpatialObjectDateTime['value']).month
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        }, day: ${(val as SpatialObjectDateTime['value']).day}, hour: ${
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          (val as SpatialObjectDateTime['value']).hour
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        }, minute: ${(val as SpatialObjectDateTime['value']).minute}, second: ${
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          (val as SpatialObjectDateTime['value']).second
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        }, nanosecond: ${(val as SpatialObjectDateTime['value']).nanosecond} ${timezoneString} })`;
      case 'LocalDateTime':
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return `localdatetime({ year: ${(val as SpatialObjectLocalDateTime['value']).year}, month: ${
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          (val as SpatialObjectLocalDateTime['value']).month
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        }, day: ${(val as SpatialObjectLocalDateTime['value']).day}, hour: ${
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          (val as SpatialObjectLocalDateTime['value']).hour
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        }, minute: ${(val as SpatialObjectLocalDateTime['value']).minute}, second: ${
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          (val as SpatialObjectLocalDateTime['value']).second
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        }, nanosecond: ${(val as SpatialObjectLocalDateTime['value']).nanosecond} })`;
      case 'Time':
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return `time({ hour: ${(val as SpatialObjectTime['value']).hour}, minute: ${
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          (val as SpatialObjectTime['value']).minute
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        }, second: ${(val as SpatialObjectTime['value']).second}, nanosecond: ${
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          (val as SpatialObjectTime['value']).nanosecond
        } })`;
      case 'LocalTime':
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return `localtime({ hour: ${(val as SpatialObjectLocalTime['value']).hour}, minute: ${
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          (val as SpatialObjectLocalTime['value']).minute
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        }, second: ${(val as SpatialObjectLocalTime['value']).second}, nanosecond: ${
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          (val as SpatialObjectLocalTime['value']).nanosecond
        } })`;
      case 'Duration':
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return `duration({ months: ${(val as SpatialObjectDuration['value']).months}, days: ${
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          (val as SpatialObjectDuration['value']).days
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        } }, seconds: ${(val as SpatialObjectDuration['value']).seconds} }, nanoseconds: ${
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          (val as SpatialObjectDuration['value']).nanoseconds
        } })`;
      default:
        throw new Error('Unrecognized Spatial/Temporal value');
    }
  } else if (Array.isArray(value)) {
    return `[${value.map((item) => wrapValue(item)).join(', ')}]`;
  } else if (
    typeof value === 'boolean' ||
    typeof value === 'number' ||
    value instanceof neo4j.int ||
    (hasOwnProperty(value, 'low') &&
      hasOwnProperty(value, 'high') &&
      typeof value.low === 'number' &&
      typeof value.high === 'number')
  ) {
    return value as boolean | number | Integer | { low: number; high: number };
  } else {
    // @ts-expect-error value is expected to have toString method
    return `"${getEscapedDoubleQuoteString(getSafeBackTicksString(value.toString()))}"`;
  }
};
