import type {
  MetricAggregation,
  MetricInstanceMode,
  MetricValue,
  MetricsByNameAndInstanceId,
  MetricsResponse,
} from '@nx/state';

import { metricKey } from '../../shared/utils';
import type { ChartWidgetPartialProps } from './fullstack-chart-props';

const mapAuraSeries = (metric: { timestamps: number[]; values: number[] }, chartProp: ChartWidgetPartialProps) => {
  const { values, timestamps } = metric;
  if (timestamps.length !== values.length) {
    throw new Error('Lengths of values and timestamps should always be the same');
  }
  const mapValue = typeof chartProp.valueMapper === 'function' ? chartProp.valueMapper : <T>(v: T) => v;
  const series = values.map<MetricValue>((v, i) => ({
    timestamp: timestamps[i],
    value: mapValue(v),
  }));
  return series;
};
const aggregationLabel = (aggregation: MetricAggregation | null | undefined) => {
  switch (aggregation) {
    case 'AVG':
      return 'Average';
    case 'MAX':
      return 'Max';
    case 'MIN':
      return 'Min';
    case 'SUM':
      return 'Sum';
    default:
      return '';
  }
};
const getAzGroupingSeriesName = (
  primaryOrSecondary: MetricInstanceMode,
  AZTag: string | undefined,
  aggregation: MetricAggregation | null | undefined,
) => {
  if (primaryOrSecondary === 'NONE') {
    return AZTag !== undefined ? `AZTag` : `${aggregationLabel(aggregation)}`;
  }

  const primaryOrSecondaryLabel = primaryOrSecondary === 'PRIMARY' ? 'Primaries' : 'Secondaries';
  return AZTag !== undefined
    ? `${primaryOrSecondaryLabel} in ${AZTag}`
    : `${aggregationLabel(aggregation)} of ${primaryOrSecondaryLabel}`;
};

function mapAuraMetric(
  chartProp: ChartWidgetPartialProps,
  name: string,
  metric: {
    name: string;
    timestamps: number[];
    values: number[];
    tags: Record<string, string>;
  },
  metrics: MetricsByNameAndInstanceId,
  aggregation: string | null | undefined,
) {
  const isRef = chartProp.metricName !== name && chartProp.referenceMetricConfig?.name === name;
  const series: MetricValue[] = mapAuraSeries(metric, chartProp);
  const seriesForMetric = metrics[metricKey(chartProp, false)] ?? {
    dataSeries: {},
    refSeries: undefined,
    transformerSeries: undefined,
  };
  if (isRef) {
    seriesForMetric.refSeries = series;
  } else if (
    chartProp.metricName !== name &&
    chartProp.transformerMetricConfig &&
    chartProp.transformerMetricConfig.name === name
  ) {
    seriesForMetric.transformerSeries = seriesForMetric.transformerSeries ? seriesForMetric.transformerSeries : {};
    seriesForMetric.transformerSeries[aggregation ?? 'default'] = series;
  } else if (
    chartProp.metricName !== name &&
    chartProp.additionalMetric?.metricName === name &&
    seriesForMetric.dataSeries &&
    chartProp.additionalMetric.subtitle !== undefined
  ) {
    seriesForMetric.dataSeries[chartProp.additionalMetric.subtitle] = series;
  } else if (chartProp.additionalMetric && seriesForMetric.dataSeries && chartProp.subtitle !== undefined) {
    seriesForMetric.dataSeries[chartProp.subtitle] = series;
  } else if (seriesForMetric.dataSeries) {
    seriesForMetric.dataSeries[aggregation ?? 'default'] = series;
  }
  return seriesForMetric;
}

function mapAuraMetricWithAZGrouping(
  chartProp: ChartWidgetPartialProps,
  name: string,
  metric: {
    name: string;
    timestamps: number[];
    values: number[];
    tags: Record<string, string>;
  },
  metrics: MetricsByNameAndInstanceId,
  aggregation: string | null | undefined,
  showLeaderOnly?: boolean,
) {
  const isRef = chartProp.azMetricName !== name && chartProp.referenceMetricConfig?.name === name;
  const series: MetricValue[] = mapAuraSeries(metric, chartProp);
  const seriesForMetric = metrics[metricKey(chartProp, true)] ?? {
    dataSeries: {},
    refSeries: undefined,
    transformerSeries: undefined,
  };
  if (isRef) {
    seriesForMetric.refSeries = series;
  } else if (
    chartProp.azMetricName !== name &&
    chartProp.transformerMetricConfig &&
    chartProp.transformerMetricConfig.name === name
  ) {
    seriesForMetric.transformerSeries = seriesForMetric.transformerSeries ? seriesForMetric.transformerSeries : {};
    seriesForMetric.transformerSeries[aggregation ?? 'default'] = series;
  } else if (seriesForMetric.dataSeries) {
    seriesForMetric.dataSeries[aggregation ?? 'default'] = series;
  }
  return seriesForMetric;
}

export const mapAuraMetrics = (
  data: MetricsResponse,
  chartProps: ChartWidgetPartialProps[],
  dbmsName: string,
  seriesNameMapper?: ((props: ChartWidgetPartialProps) => string) | undefined,
) => {
  const metrics: MetricsByNameAndInstanceId = {};

  data.forEach((metric) => {
    const { name } = metric;
    const chartPropMatches = chartProps.filter(
      (c) => c.metricName === name || c.referenceMetricConfig?.name === name || c.additionalMetric?.metricName === name,
    );
    chartPropMatches.forEach((chartProp) => {
      metrics[metricKey(chartProp, false)] = mapAuraMetric(
        chartProp,
        name,
        metric,
        metrics,
        seriesNameMapper ? seriesNameMapper(chartProp) : dbmsName,
      );
    });
  });

  return metrics;
};

export const mapAuraMetricsWithAggregations = (data: MetricsResponse, chartProps: ChartWidgetPartialProps[]) => {
  const metrics: MetricsByNameAndInstanceId = {};
  data.forEach((metric) => {
    const { name, aggregation } = metric;
    const chartProp = chartProps.find(
      (c) => c.metricName === name || c.referenceMetricConfig?.name === name || c.additionalMetric?.metricName === name,
    );
    if (chartProp) {
      metrics[metricKey(chartProp, false)] = mapAuraMetric(chartProp, name, metric, metrics, aggregation);
    }
  });

  return metrics;
};

export const mapAuraMetricsWithAZGrouping = (
  data: MetricsResponse,
  chartProps: ChartWidgetPartialProps[],
  showLeaderOnly: boolean,
) => {
  const metrics: MetricsByNameAndInstanceId = {};

  data.forEach((metric) => {
    const { name, aggregation, primaryOrSecondary } = metric;
    const AZTag = metric.tags['availability-zone'];
    const seriesName = getAzGroupingSeriesName(primaryOrSecondary, AZTag, aggregation);
    const chartPropMatches = chartProps.filter(
      (c) =>
        (c.azMetricName ?? c.metricName) === name ||
        c.referenceMetricConfig?.name === name ||
        c.transformerMetricConfig?.name === name,
    );
    chartPropMatches.forEach((chartProp) => {
      if (!chartProp.defaultAggregation || chartProp.defaultAggregation === aggregation) {
        metrics[metricKey(chartProp, true)] = mapAuraMetricWithAZGrouping(
          chartProp,
          name,
          metric,
          metrics,
          seriesName,
          showLeaderOnly,
        );
      }
    });
  });

  return metrics;
};
