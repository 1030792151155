import { Tooltip, Typography } from '@neo4j-ndl/react';
import cn from 'classnames';

export const formatOptionLabel = (
  {
    label,
    description,
    isDisabled = false,
    disabledReason = 'This feature is not enabled for this instance',
  }: { label: string; description: string; isDisabled?: boolean; disabledReason?: string },
  { context }: { context: string },
) => {
  if (context === 'value') {
    return <div className="flex flex-row whitespace-break-spaces">{label}</div>;
  } else if (context === 'menu') {
    return (
      <Tooltip isPortaled={false} type="simple" isDisabled={!isDisabled}>
        <Tooltip.Trigger htmlAttributes={{ type: 'button' }} hasButtonWrapper>
          <div className="flex grow flex-col justify-between">
            <div className="flex flex-row justify-between">
              {/* type defaults to 'submit' -- form submits when pressed  (as of @neo4j-ndl/react@3.0.24) */}
              <span className="w-1/2">{label}</span>
            </div>
            <Typography
              variant="body-small"
              className={cn('break-words', {
                'text-neutral-text-weaker': !isDisabled,
                'text-neutral-text-weakest': isDisabled,
              })}
            >
              {description ? description : ''}
            </Typography>
          </div>
        </Tooltip.Trigger>
        <Tooltip.Content className="!fixed !w-[300px]">{disabledReason}</Tooltip.Content>
      </Tooltip>
    );
  }
  return null;
};
