import { Breadcrumbs, SpotlightTarget, Typography } from '@neo4j-ndl/react';
import { CloudIconOutline, ComputerDesktopIconOutline } from '@neo4j-ndl/react/icons';
import type { ProjectSummary } from '@nx/state';
import {
  PLAN_TYPE,
  PROJECT_TYPE,
  consoleApi,
  LEGACY_setActiveOrg as setActiveOrg,
  LEGACY_setActiveProject as setActiveProject,
  LEGACY_setUserHomePath as setUserHomePath,
  useActiveOrgSummary,
  useActiveProjectSummary,
  useCapability,
  useDesktop,
  useDesktopActions,
} from '@nx/state';
import { isNonEmptyString, isNotNullish } from '@nx/stdlib';
import { skipToken } from '@reduxjs/toolkit/query';
import cn from 'classnames';
import { useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { getMarketplaceProjectFriendlyName, isMarketplaceProject } from '../instance/entities/helpers';
import classes from './organization-nav.module.css';

export const OrganizationNav = () => {
  const [isProjectsMenuOpen, setIsProjectsMenuOpen] = useState(false);
  const [isOrgMenuOpen, setIsOrgMenuOpen] = useState(false);
  const navigate = useNavigate();
  const userDetailsRes = consoleApi.useGetUserDetailsQuery();
  const { data: organizations = [] } = consoleApi.useListOrganizationsByUserQuery(userDetailsRes.data?.id ?? skipToken);
  const listUserProjectsRes = consoleApi.useListProjectsByUserQuery(userDetailsRes.data?.id ?? skipToken);

  const [relateApiEnabled] = useCapability('framework:relate-api');
  const { setDesktopContext } = useDesktopActions();
  const { isDesktopContext } = useDesktop();

  const { orgId, projectId } = useParams<{ orgId: string; projectId: string }>();
  const location = useLocation();
  const activeOrg = useActiveOrgSummary();
  const activeProject = useActiveProjectSummary();
  const isInOrgContext = isNonEmptyString(orgId);
  const { data: user } = consoleApi.useGetUserDetailsQuery();

  let userOrgs = organizations;
  let userProjects = useMemo(() => listUserProjectsRes.data ?? [], [listUserProjectsRes.data]);
  const isEnterpriseUser = userOrgs.some((org) => org.planType === PLAN_TYPE.ENTERPRISE);
  if (isEnterpriseUser) {
    userOrgs = userOrgs.filter((org) => org.planType === PLAN_TYPE.ENTERPRISE);
    userProjects = userProjects.filter((project) => project.planType === PLAN_TYPE.ENTERPRISE);
  }

  const projectMenuItems = useMemo(() => {
    const projects = userProjects.filter(
      (project) => isNonEmptyString(activeOrg?.id) && project.organizationId === activeOrg.id,
    );

    const gcpProjects = projects.filter((project) => project.projectType === PROJECT_TYPE.N4GCP);
    const awsProjects = projects.filter((project) => project.projectType === PROJECT_TYPE.MARKETPLACE_AWS);
    const azureProjects = projects.filter((project) => project.projectType === PROJECT_TYPE.MARKETPLACE_AZURE);
    const projectsInOrder: (string | ProjectSummary)[] = projects.filter(
      (project) => ![...gcpProjects, ...awsProjects, ...azureProjects].includes(project),
    );

    if (gcpProjects.length > 0) {
      projectsInOrder.push(...['divider', ...gcpProjects]);
    }
    if (awsProjects.length > 0) {
      projectsInOrder.push(...['divider', ...awsProjects]);
    }
    if (azureProjects.length > 0) {
      projectsInOrder.push(...['divider', ...azureProjects]);
    }

    return projectsInOrder.map((project, idx) => {
      if (typeof project === 'string') {
        return (
          <div key={`${project}-${idx}`} className="text-neutral-text-weaker my-1">
            {project === 'divider' && <div className="border-neutral-border-weak border-b" />}
          </div>
        );
      }

      const projectRoute =
        isNonEmptyString(projectId) && location.pathname.includes(projectId)
          ? `${location.pathname.replace(projectId, project.id)}${location.search}`
          : `/projects/${project.id}`;

      const isActiveProject = activeProject?.id === project.id && !isInOrgContext;
      const isOtherProject = activeProject?.id !== project.id || isInOrgContext;
      return (
        <Breadcrumbs.MenuItem
          key={project.id}
          href="/"
          onClick={() => {
            setActiveProject(project.id);
            setUserHomePath(`projects/${project.id}/instances`);
            setActiveOrg(project.organizationId);
            localStorage.setItem(`default-project-${user?.id}`, project.id);
            setIsProjectsMenuOpen(false);
            navigate(projectRoute);
          }}
          isCurrentPage={isActiveProject}
          htmlAttributes={{
            tabIndex: undefined,
          }}
        >
          <div className="flex flex-col">
            <Typography variant="body-medium" as="span" className={cn({ 'text-primary-text': isActiveProject })}>
              {project.name}
            </Typography>
            <Typography
              variant="body-small"
              as="span"
              className={cn({
                'text-neutral-text-weaker': isOtherProject,
                'text-primary-text': isActiveProject,
              })}
            >
              {project.id}
            </Typography>
            {isMarketplaceProject(project.projectType) && (
              <Typography
                variant="body-small"
                as="span"
                className={cn({
                  'text-neutral-text-weaker': isOtherProject,
                  'text-primary-text': isActiveProject,
                })}
              >
                {getMarketplaceProjectFriendlyName(project.projectType)} Marketplace
              </Typography>
            )}
          </div>
        </Breadcrumbs.MenuItem>
      );
    });
  }, [
    activeOrg?.id,
    activeProject?.id,
    isInOrgContext,
    location.pathname,
    location.search,
    navigate,
    projectId,
    user?.id,
    userProjects,
  ]);

  if (!relateApiEnabled && isDesktopContext) {
    setDesktopContext(false);
  }

  const OrgItem = useMemo(() => {
    let contents;

    if (isDesktopContext) {
      contents = (
        <>
          <ComputerDesktopIconOutline className="size-token-7 mr-3 inline-block" />
          Localhost
        </>
      );
    } else if (activeOrg) {
      contents = <Link to={`org/${activeOrg.id}`}>{activeOrg.displayName}</Link>;
    } else if (relateApiEnabled) {
      contents = (
        <>
          <CloudIconOutline className="size-token-7 mr-3 inline-block" />
          Aura cloud
        </>
      );
    }

    if (contents) {
      return (
        <Breadcrumbs.Item
          href=""
          as="span"
          hasSeparator={false}
          htmlAttributes={{
            tabIndex: undefined,
          }}
          className={cn({
            '!text-neutral-text-default': isInOrgContext,
          })}
        >
          <SpotlightTarget id="welcome-tour-1-organization" indicatorVariant="point">
            {contents}
          </SpotlightTarget>
        </Breadcrumbs.Item>
      );
    }

    return null;
  }, [activeOrg, isDesktopContext, isInOrgContext, relateApiEnabled]);

  const orgItemMenu = useMemo(() => {
    const orgMenuItems = userOrgs.map((org) => (
      <Breadcrumbs.MenuItem
        key={org.id}
        href="/"
        onClick={() => {
          setActiveOrg(org.id);
          if (isNotNullish(userProjects)) {
            const orgProject = userProjects.find((project) => project.organizationId === org.id);
            if (isNotNullish(orgProject)) {
              setActiveProject(orgProject.id);
              localStorage.setItem(`default-project-${user?.id}`, orgProject.id);
            }
          }
          navigate(`org/${org.id}/projects`);
          setUserHomePath(`org/${org.id}/projects`);
          setIsOrgMenuOpen(false);
        }}
        isCurrentPage={activeOrg?.id === org.id}
        htmlAttributes={{
          tabIndex: undefined,
        }}
      >
        <div className="flex flex-col">
          <Typography variant="body-medium" as="span" className={cn({ 'text-primary-text': activeOrg?.id === org.id })}>
            {org.displayName}
          </Typography>
          <Typography
            variant="body-small"
            as="span"
            className={cn({
              'text-neutral-text-weaker': activeOrg?.id !== org.id,
              'text-primary-text': activeOrg?.id === org.id,
            })}
          >
            {org.id}
          </Typography>
        </div>
      </Breadcrumbs.MenuItem>
    ));

    return (
      <>
        {orgMenuItems.length > 0 ? (
          orgMenuItems
        ) : (
          <Breadcrumbs.MenuItem
            key="aura"
            href="/"
            onClick={() => {
              setDesktopContext(false);
              navigate('/projects');
              setIsOrgMenuOpen(false);
            }}
            isCurrentPage={!isDesktopContext}
            className={cn({ 'text-neutral-text-weaker': !isDesktopContext })}
            htmlAttributes={{
              tabIndex: undefined,
            }}
          >
            <div className="flex gap-2">Aura</div>
          </Breadcrumbs.MenuItem>
        )}
        {relateApiEnabled && (
          <>
            <div className="border-neutral-border-weak my-1 border-b" />

            <Breadcrumbs.MenuItem
              key="localhost"
              href="/"
              onClick={() => {
                setDesktopContext(true);
                navigate('/local/instances');
                setIsOrgMenuOpen(false);
              }}
              isCurrentPage={isDesktopContext}
              className={cn({ 'text-neutral-text-weaker': isDesktopContext })}
              htmlAttributes={{
                tabIndex: undefined,
              }}
            >
              <div className="flex gap-2">Localhost</div>
              <Typography variant="body-small" className="text-neutral-text-weaker">
                Local workspace
              </Typography>
            </Breadcrumbs.MenuItem>
          </>
        )}
      </>
    );
  }, [
    activeOrg?.id,
    isDesktopContext,
    navigate,
    relateApiEnabled,
    setDesktopContext,
    user?.id,
    userOrgs,
    userProjects,
  ]);

  const showOrgMenu = relateApiEnabled || userOrgs.length > 1;
  return (
    <Breadcrumbs className={`${classes.nav} !shrink`}>
      {OrgItem}
      {showOrgMenu && (
        <>
          {isOrgMenuOpen ? (
            <Breadcrumbs.ItemMenu key="item-menu" isOpen onClose={() => setIsOrgMenuOpen(false)}>
              {orgItemMenu}
            </Breadcrumbs.ItemMenu>
          ) : (
            <Breadcrumbs.ItemMenu onClick={() => setIsOrgMenuOpen(true)} isOpen={false} key="item-menu-empty">
              <></>
            </Breadcrumbs.ItemMenu>
          )}
        </>
      )}
      {!isDesktopContext && (
        <>
          <Breadcrumbs.Item
            href=""
            as="span"
            isCurrentPage
            htmlAttributes={{
              tabIndex: undefined,
            }}
          >
            {isInOrgContext ? (
              <div
                className="text-neutral-text-weaker hover:text-neutral-text-default "
                onClick={() => navigate(`org/${activeOrg?.id}/projects`)}
              >
                Select
              </div>
            ) : (
              <Link to={`projects/${activeProject?.id}`}>{activeProject?.name}</Link>
            )}
          </Breadcrumbs.Item>

          <Breadcrumbs.GroupItem className="shrink-0">
            {/**
             * Hacky conditional rendering necessary to close the menu on item click because
             * Breadcrumbs.ItemMenu cannot be completely controlled by external state
             */}
            {isProjectsMenuOpen ? (
              <Breadcrumbs.ItemMenu key="item-menu" isOpen onClose={() => setIsProjectsMenuOpen(false)}>
                {projectMenuItems}
              </Breadcrumbs.ItemMenu>
            ) : (
              <Breadcrumbs.ItemMenu onClick={() => setIsProjectsMenuOpen(true)} isOpen={false} key="item-menu-empty">
                <></>
              </Breadcrumbs.ItemMenu>
            )}
          </Breadcrumbs.GroupItem>
        </>
      )}
    </Breadcrumbs>
  );
};
