import { Button, Dialog } from '@neo4j-ndl/react';

interface Props {
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
}

export const DeactivateSeamlessConnectionModal = ({ onClose, onConfirm, isLoading }: Props) => {
  return (
    <Dialog isOpen onClose={onClose}>
      <Dialog.Header>Deactivate tool authentication with Aura user</Dialog.Header>
      <Dialog.Content>
        <p>
          Users will be required to connect with credentials for all new and existing instances within this
          organization.
        </p>
      </Dialog.Content>
      <Dialog.Actions>
        <Button fill="outlined" color="neutral" onClick={onClose}>
          Cancel
        </Button>
        <Button color="danger" onClick={onConfirm} isLoading={isLoading}>
          Confirm
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
