import { Banner, Button, Dialog, Select, TextInput } from '@neo4j-ndl/react';
import { PROJECT_ROLE_NAME, consoleApi, getApiError, getErrorMessage, useActiveProject } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useState } from 'react';

import { projectRoleFriendlyName } from '../../helpers';
import { type UserRow } from '../types';

type EditProjectMemberRoleModalProps = {
  user: UserRow;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const roleHierarchy = {
  [PROJECT_ROLE_NAME.PROJECT_METRICS_INTEGRATION_READER]: 1,
  [PROJECT_ROLE_NAME.PROJECT_VIEWER]: 2,
  [PROJECT_ROLE_NAME.PROJECT_DATA_VIEWER]: 5,
  [PROJECT_ROLE_NAME.PROJECT_MEMBER]: 10,
  [PROJECT_ROLE_NAME.PROJECT_ADMIN]: 100,
} as const;

const isUpgrade = (oldRole: PROJECT_ROLE_NAME, newRole: PROJECT_ROLE_NAME) =>
  roleHierarchy[oldRole] < roleHierarchy[newRole];
const isDowngrade = (oldRole: PROJECT_ROLE_NAME, newRole: PROJECT_ROLE_NAME) =>
  roleHierarchy[oldRole] > roleHierarchy[newRole];

const changeRolePermissionMessage = (oldRole?: PROJECT_ROLE_NAME, newRole?: PROJECT_ROLE_NAME) => {
  if (isNullish(oldRole) || isNullish(newRole)) {
    return undefined;
  }
  if (isUpgrade(oldRole, newRole)) {
    return 'Changing to a more permissive role will grant additional access and privileges to the user.';
  }

  if (isDowngrade(oldRole, newRole)) {
    return 'Changing to a less permissive role may stop the user from accessing certain pages and performing certain actions.';
  }
  return undefined;
};

const mapRoleToOption = (role?: PROJECT_ROLE_NAME) => {
  if (isNotNullish(role)) {
    return {
      label: projectRoleFriendlyName[role],
      value: role,
    };
  }
  return undefined;
};

export const EditProjectMemberRoleModal = ({ user, open, onClose, onConfirm }: EditProjectMemberRoleModalProps) => {
  const activeProject = useActiveProject();

  const [editRole, request] = consoleApi.useUpdateProjectMemberRolesMutation();
  const [currentRole] = user.roles;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [newRole, setNewRole] = useState<PROJECT_ROLE_NAME | undefined>(currentRole);

  const handleConfirm = () => {
    setErrorMessage(null);
    if (isNotNullish(user.userId) && isNotNullish(newRole)) {
      editRole({ userId: user.userId, projectId: user.projectId, roles: [newRole] })
        .unwrap()
        .then(() => onConfirm())
        .catch((err: FetchBaseQueryError | SerializedError | undefined) => {
          const apiError = getApiError(err);
          const message = getErrorMessage(apiError);
          setErrorMessage(message);
        });
    }
  };

  const handleRoleChange = (value?: PROJECT_ROLE_NAME) => {
    setNewRole(value);
  };

  const options = activeProject.availableRoles.map((role) => ({
    value: role,
    label: projectRoleFriendlyName[role],
  }));

  const warningMessage = changeRolePermissionMessage(currentRole, newRole);

  return (
    <Dialog isOpen={open} onClose={onClose}>
      <Dialog.Header>Edit role for {user.email}</Dialog.Header>
      <Dialog.Content>
        <div className="flex flex-col gap-4">
          <TextInput value={user.email} isReadOnly isFluid label="User" />
          <Select
            size="medium"
            selectProps={{
              options,
              value: mapRoleToOption(newRole),
              onChange: (value) => handleRoleChange(value?.value),
              menuPosition: 'fixed',
            }}
            type="select"
            label="Project role"
          />
          {currentRole !== newRole && <Banner description={warningMessage} type="warning" hasIcon usage="inline" />}
          {isNotNullish(errorMessage) && <Banner description={errorMessage} type="danger" usage="inline" />}
        </div>
      </Dialog.Content>
      <Dialog.Actions>
        <Button onClick={onClose} color="neutral" fill="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          isLoading={request.isLoading}
          isDisabled={isNullish(newRole) || user.roles.includes(newRole)}
        >
          Save
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
