import type { ConnectionDescriptor } from '@nx/constants';
import * as StdLib from '@nx/stdlib';

export function getConnectionIdFromUrl(url: string) {
  const parsedUrl = new StdLib.URLs.Neo4jURL(url);
  return parsedUrl.host;
}

type RemoteDiscoveryData = { bolt_routing: string };

type LocalDiscoveryData = { bolt: string } | { bolt_routing: string } | { bolt_direct: string };

export function isLocalDiscoveryData(object: unknown): object is LocalDiscoveryData {
  if (object !== null && typeof object === 'object') {
    return 'bolt' in object || 'bolt_routing' in object || 'bolt_direct' in object;
  }

  return false;
}

function isRemoteDiscoveryData(object: unknown): object is RemoteDiscoveryData {
  if (object !== null && typeof object === 'object') {
    return 'bolt_routing' in object;
  }

  return false;
}

export const fetchDiscoveryProviders = async (url: string): Promise<RemoteDiscoveryData | null> => {
  try {
    const response = await fetch(url, { cache: 'no-cache' });

    if (!response.ok) {
      return null;
    }

    const json: unknown = await response.json();

    if (isRemoteDiscoveryData(json)) {
      return json;
    }

    return null;
  } catch {
    return null;
  }
};

export const checkDiscoveryForUrls = (url: StdLib.URLs.Neo4jURL) => {
  // We assume that the discovery url is using port 7474 for local instances
  const neo4jUrl = StdLib.URLs.isLocalhost(url.hostname) ? `${url.hostname}:7474` : url.host;

  const possibleDiscoveryEndpoints = [`https://${neo4jUrl}`];
  if (document.location.protocol !== 'https:' || StdLib.URLs.isLocalhost(url.hostname)) {
    possibleDiscoveryEndpoints.push(`http://${neo4jUrl}`);
  }
  return Promise.all(possibleDiscoveryEndpoints.map(fetchDiscoveryProviders)).then((responses) => {
    return responses
      .filter(Boolean)
      .filter(
        (response) => !StdLib.URLs.CONNECTION_PROTOCOLS.some((protocol) => response?.bolt_routing.startsWith(protocol)),
      )
      .filter((response) => response?.bolt_routing !== url.toString())
      .map((response) => response?.bolt_routing);
  });
};

export function getAuthenticatedUrl(url: string, credentials: ConnectionDescriptor): string {
  const parsedUrl = new StdLib.URLs.Neo4jURL(url);

  if (credentials.type === 'basic') {
    parsedUrl.username = credentials.username;
  }

  return parsedUrl.toString();
}

export function guessDiscoveryApiLocation(url: string) {
  const currentUrl = new URL(url);

  currentUrl.pathname = currentUrl.pathname
    .replace(/\/$/, '')
    .replace(/\/preview$/, '')
    .replace(/\/browser$/, '');

  return currentUrl.toString();
}

export function parseDiscoveryData(result: Record<string, unknown>) {
  if (isLocalDiscoveryData(result)) {
    if ('bolt_routing' in result) {
      return result.bolt_routing;
    } else if ('bolt' in result) {
      return result.bolt;
    } else if ('bolt_direct' in result) {
      return result.bolt_direct;
    }
  }

  return null;
}
