import { type EventTypeLabelProps, Label } from '@neo4j-ndl/react';
import { SECURITY_LOG_STATUS } from '@nx/state';

import { capitalizeFirstLowerRest } from '../../shared/utils';

export const StatusLabel = ({ level }: { level: SECURITY_LOG_STATUS }) => {
  let color: EventTypeLabelProps['color'] = 'success';
  const fill: EventTypeLabelProps['fill'] = 'outlined';

  switch (level) {
    case SECURITY_LOG_STATUS.INFO:
      break;
    case SECURITY_LOG_STATUS.WARNING:
      color = 'warning';
      break;
    case SECURITY_LOG_STATUS.ERROR:
      color = 'danger';
      break;
    case SECURITY_LOG_STATUS.UNKNOWN:
      color = 'default';
      break;
    default:
  }

  return (
    <Label fill={fill} color={color} hasIcon>
      {capitalizeFirstLowerRest(level)}
    </Label>
  );
};
