import { keyBy } from 'lodash-es';

import type {
  MetricMetadata,
  MetricsMetadataResponse,
  MetricsRequest,
  MetricsResponse,
  MetricsWithAggregationsRequest,
} from '../api-types/metrics';
import { transformMetrics } from '../transformers/metrics';
import type { Builder } from './types';

export const metricsEndpoints = (builder: Builder) => ({
  getMetrics: builder.query<MetricsResponse, MetricsRequest>({
    query: (metricRequest) => ({
      url: '/metrics',
      method: 'POST',
      body: metricRequest,
      headers: { 'Project-Id': metricRequest.tenantId },
    }),
    transformResponse: (metrics: MetricsResponse) => {
      return transformMetrics(metrics);
    },
    providesTags: (result, error, metricRequest) => [{ type: 'Metrics', id: metricRequest.dbmsId }],
  }),
  getMetricsWithAggregations: builder.query<MetricsResponse, MetricsWithAggregationsRequest>({
    query: (metricRequest) => ({
      url: '/metrics/aggregations',
      method: 'POST',
      body: metricRequest,
      headers: { 'Project-Id': metricRequest.tenantId },
    }),
    transformResponse: (metrics: MetricsResponse) => {
      return transformMetrics(metrics);
    },
    providesTags: (result, error, metricRequest) => [{ type: 'Metrics', id: metricRequest.dbmsId }],
  }),
  getMetricsWithAZGrouping: builder.query<MetricsResponse, MetricsRequest>({
    query: (metricRequest) => ({
      url: '/metrics/az-grouping',
      method: 'POST',
      body: metricRequest,
      headers: { 'Project-Id': metricRequest.tenantId },
    }),
    transformResponse: (metrics: MetricsResponse) => {
      return transformMetrics(metrics);
    },
    providesTags: (result, error, metricRequest) => [{ type: 'Metrics', id: metricRequest.dbmsId }],
  }),
  getMetricsMetadata: builder.query<Record<string, MetricMetadata>, string>({
    query: (tenantId) => ({ url: '/metrics/metadata', headers: { 'Project-Id': tenantId } }),
    transformResponse: (metricsMetadataRes: MetricsMetadataResponse): Record<string, MetricMetadata> => {
      return keyBy(metricsMetadataRes, (metadata) => metadata.name);
    },
  }),
});
