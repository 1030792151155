import { Banner, Button, Dialog, LoadingSpinner, Select, TextInput, TextLink, Typography } from '@neo4j-ndl/react';
import { PlusIconOutline, TrashIconOutline } from '@neo4j-ndl/react/icons';
import type { Instance, Project, TrafficConfig } from '@nx/state';
import { TIER, TRAFFIC_ENABLEMENT, consoleApi, getApiError } from '@nx/state';
import { isNonEmptyString, isNotNullish, isNullish } from '@nx/stdlib';
import { CopyTextInput } from '@nx/ui';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useMemo, useState } from 'react';
import * as yup from 'yup';

import type { Validation } from '../../utils/validation';
import { validateYup } from '../../utils/validation';
import { getProductFromTier, getRegionsForTier } from '../entities/helpers';
import { ConfirmCheckbox, PrivateConnection, VpnMessage } from './shared';
import type { RegionOption, TierOption } from './utils';

type FormData = {
  tier?: TIER;
  region?: string;
  privateTraffic: TRAFFIC_ENABLEMENT;
  publicTraffic: TRAFFIC_ENABLEMENT;
  projectIds: string[];
};

const schema = yup.object({
  tier: yup.string().oneOf(Object.values(TIER)).required().label('Instance Type'),
  region: yup.string().required().label('Region'),
  privateTraffic: yup.string().oneOf(Object.values(TRAFFIC_ENABLEMENT)).required(),
  publicTraffic: yup.string().oneOf(Object.values(TRAFFIC_ENABLEMENT)).required(),
  projectIds: yup
    .array()
    .min(1)
    .required()
    .of(
      yup
        .string()
        .min(6)
        .max(30)
        .matches(
          /^[a-z][a-z0-9-]+[a-z0-9]$/,
          'GCP Project ID must be 6 to 30 lowercase letters, digits, or hyphens. It must start with a letter and not end with a hyphen.',
        )
        // https://cloud.google.com/resource-manager/reference/rest/v1/projects
        .label('GCP Project ID'),
    ),
});

const validate = (data: FormData): Validation<FormData> | null => {
  return validateYup(schema, data);
};

const defaults = (trafficConfig?: TrafficConfig): FormData => ({
  tier: trafficConfig?.tier,
  region: trafficConfig?.region,
  projectIds: trafficConfig?.gcpProperties ? trafficConfig.gcpProperties.projectIds : [''],
  privateTraffic: trafficConfig?.privateTraffic ?? TRAFFIC_ENABLEMENT.ENABLED,
  publicTraffic: trafficConfig?.publicTraffic ?? TRAFFIC_ENABLEMENT.ENABLED,
});

type GCPTrafficConfigProps = {
  project: Project;
  trafficConfig?: TrafficConfig;
  existingTrafficConfigs?: TrafficConfig[];
  existingTierRegions?: Partial<Record<TIER, string[]>>;
  onClose: () => void;
  onSuccess?: (trafficConfig: TrafficConfig) => void;
  instances: Instance[];
};

/**
 * This dialog is quite complicated, so to try to simplify things
 * a bit, it has no `open` prop. The consumer should just unmount
 * and remount it. This helps make managing state easier
 * as there is no chance of bleeding state between dialog open/closes
 */
export const GCPTrafficConfigDialog = ({
  project,
  trafficConfig,
  onClose,
  onSuccess,
  instances,
  // Only needed in create mode
  // Used to prepopulate the props (e.g. ProjectIds)
  // when creating
  existingTrafficConfigs = [],
  // Only needed in create mode
  // Used to determine if a tier/region combo
  // is already configured
  existingTierRegions = {},
}: GCPTrafficConfigProps) => {
  const editMode = isNotNullish(trafficConfig);
  const [data, setData] = useState<FormData>(() => defaults(trafficConfig));
  const [validation, setValidation] = useState<Validation<FormData>>({});
  const [enableError, setEnableError] = useState<string | null>(null);
  const [step, setStep] = useState(1);
  const [confirm, setConfirm] = useState(false);
  const [updateTrafficConfig, updateTrafficConfigRes] = consoleApi.useUpdateTrafficConfigMutation();
  const affectedInstances = useMemo(() => {
    if (isNotNullish(data.region) && isNotNullish(data.tier) && data.publicTraffic === TRAFFIC_ENABLEMENT.DISABLED) {
      return instances.filter((instance) => instance.region === data.region && instance.tier === data.tier);
    }
    return [];
  }, [data.publicTraffic, data.region, data.tier, instances]);

  const handleClose = () => {
    onClose();
  };

  const handleFinishLater = () => {
    onClose();
  };

  const handleTierChange = (option: TierOption) => {
    setData((prev) => ({ ...prev, tier: option.value, region: undefined }));
  };
  const handleRegionChange = (option: RegionOption) => {
    const newRegion = option.value;
    setData((prev) => {
      const existingTrafficConfig = existingTrafficConfigs.find((c) => c.tier === prev.tier && c.region === newRegion);
      // Existing traffic config may be undefined
      // if for example in dev environments you use the same
      // isolation id for enterprisedb and enterpriseds
      const existingProjectIds = existingTrafficConfig?.gcpProperties?.projectIds ?? [];
      return {
        ...prev,
        region: newRegion,
        projectIds: [...existingProjectIds, ''],
      };
    });
  };

  const handleProjectIdChange = (value: string, index: number) => {
    const newValue = value;
    setData((prev) => {
      prev.projectIds[index] = newValue;
      const newProjectIds = [...prev.projectIds];
      return { ...prev, projectIds: newProjectIds };
    });
  };
  const handleDeleteProjectId = (index: number) => {
    setData((prev) => ({ ...prev, projectIds: data.projectIds.filter((_, i) => i !== index) }));
  };
  const handleAddProjectId = () => {
    setData((prev) => ({ ...prev, projectIds: [...prev.projectIds, ''] }));
  };

  const handleConfirmChange = (checked: boolean) => {
    setConfirm(checked);
  };

  const handlePublicTrafficDisabledChange = (checked: boolean) => {
    const newValue = !checked;
    setData((prev) => ({
      ...prev,
      publicTraffic: newValue ? TRAFFIC_ENABLEMENT.ENABLED : TRAFFIC_ENABLEMENT.DISABLED,
    }));
  };

  const handleNextStep = () => setStep(Math.min(step + 1, 3));
  const handlePreviousStep = () => setStep(Math.max(step - 1, 1));

  const handleSubmit = (close = false) => {
    const errors = validate(data);
    if (errors) {
      setValidation(errors);
      return;
    }
    setEnableError(null);
    setValidation({});

    if (isNullish(data.tier) || isNullish(data.region)) {
      return;
    }

    updateTrafficConfig({
      projectId: project.id,
      tier: data.tier,
      region: data.region,
      privateTraffic: data.privateTraffic,
      publicTraffic: data.publicTraffic,
      gcpProperties: { projectIds: data.projectIds },
    })
      .unwrap()
      .then((res) => {
        if (onSuccess) {
          onSuccess(res);
        }
        handleNextStep();
        if (close) {
          handleClose();
        }
      })
      .catch((err: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(err);
        if (error.code === 422) {
          setEnableError(error.message ?? 'Failed to update network security configuration');
        } else {
          setEnableError('Failed to update network security configuration');
        }
      });
  };

  const handleSubmitAndClose = () => handleSubmit(true);

  const regionOptions = useMemo(() => {
    if (isNullish(data.tier)) {
      return [];
    }
    const alreadyConfiguredRegions = existingTierRegions[data.tier] ?? [];
    const regionsToConfigure = existingTrafficConfigs.filter((tc) => tc.tier === data.tier).map((tc) => tc.region);
    const regions = getRegionsForTier(data.tier, project.tierConfigs).filter(
      (r) => !alreadyConfiguredRegions.includes(r.name) && regionsToConfigure.includes(r.name),
    );
    return regions.map((r) => ({
      key: r.name,
      label: r.friendly,
      value: r.name,
    }));
  }, [data.tier, existingTierRegions, existingTrafficConfigs, project.tierConfigs]);

  const tierOptions = useMemo(() => {
    const tiers = Array.from(new Set(existingTrafficConfigs.map((config) => config.tier)));
    return tiers
      .filter((t) => [TIER.ENTERPRISE, TIER.AURA_DSE].includes(t))
      .map((tier) => {
        return {
          key: tier,
          value: tier,
          label: getProductFromTier(tier),
        };
      });
  }, [existingTrafficConfigs]);

  const serviceAttachmentUrl = trafficConfig?.status.gcpStatus?.serviceAttachmentUrl;
  const configCreating = !trafficConfig || !isNonEmptyString(serviceAttachmentUrl);

  return (
    <>
      <Dialog.Content className="flex flex-col gap-4">
        <p>Step {step} of 3</p>
        {step === 1 && (
          <>
            <Select
              size="medium"
              type="select"
              label="Instance Type"
              selectProps={{
                options: tierOptions,
                value: tierOptions.find((t) => t.value === data.tier),
                onChange: (value) => value && handleTierChange(value),
                isDisabled: editMode,
              }}
              errorText={validation.tier?.message}
              htmlAttributes={{
                'data-testid': 'gcp-dialog-select-product',
              }}
            />
            {regionOptions.length === 0 && isNotNullish(data.tier) && (
              <Banner
                type="warning"
                title="All regions configured"
                description={`All ${getProductFromTier(data.tier)} regions have been configured.`}
                usage="inline"
              />
            )}
            {regionOptions.length > 0 && (
              <Select
                size="medium"
                type="select"
                label="Region"
                helpText="GCP Private Service Connect applies to all instances in the region."
                selectProps={{
                  options: regionOptions,
                  value: regionOptions.find((r) => r.value === data.region),
                  onChange: (value) => value && handleRegionChange(value),
                  isDisabled: editMode,
                  placeholder:
                    regionOptions.length === 0
                      ? 'Private service connect is already configured for all regions'
                      : undefined,
                }}
                errorText={validation.region?.message}
                htmlAttributes={{
                  'data-testid': 'gcp-dialog-select-region',
                }}
              />
            )}
            {isNotNullish(data.region) && (
              <>
                {data.projectIds.map((projectId, index, arr) => {
                  // Disable the first N items where N is the number of items already existing
                  // in the project ids config
                  const disabled = (trafficConfig?.gcpProperties?.projectIds ?? []).length > index;
                  const validationKey = `projectIds[${index}]`;
                  // Array validation creates keys not in FormData.
                  // To make typescript happy we transform the object to a map with string keys
                  const validationItem = new Map(Object.entries(validation)).get(validationKey);

                  return (
                    <TextInput
                      isFluid
                      key={index}
                      label={index === 0 ? "Target GCP Project ID's" : ''}
                      value={projectId}
                      onChange={(v) => handleProjectIdChange(v.target.value, index)}
                      errorText={validationItem?.message}
                      helpText={
                        index === arr.length - 1
                          ? 'Your project ID will be listed next to the project name in parentheses as "Project ID: your-project-id"'
                          : undefined
                      }
                      isDisabled={disabled}
                      htmlAttributes={{
                        'data-testid': 'gcp-dialog-project-id',
                        'aria-label': 'Target GCP Project ID',
                        placeholder: 'Enter a GCP Project ID...',
                      }}
                      rightElement={
                        index > 0 && !disabled ? (
                          <TrashIconOutline
                            onClick={() => handleDeleteProjectId(index)}
                            data-testid={`delete-project-id-button-${index + 1}`}
                          />
                        ) : undefined
                      }
                    />
                  );
                })}
                <div>
                  <Button
                    fill="outlined"
                    onClick={handleAddProjectId}
                    htmlAttributes={{
                      'data-testid': 'gcp-dialog-add-id',
                    }}
                  >
                    <PlusIconOutline className="h-full" />
                    Add project ID
                  </Button>
                </div>
                {isNotNullish(enableError) && <Banner description={enableError} type="danger" usage="inline" />}
              </>
            )}
          </>
        )}

        {step === 2 && (
          <>
            {configCreating && (
              <Banner
                title={
                  <div className="flex items-center gap-2">
                    <LoadingSpinner size="small" />
                    Configuring...
                  </div>
                }
                description="Private service connect is being configured..."
                usage="inline"
              />
            )}
            {!configCreating && (
              <>
                <Banner hasIcon type="success" title="Accepted" usage="inline" />
                <CopyTextInput label="Service Attachment URL" value={serviceAttachmentUrl} isPortaled={false} />
              </>
            )}
            <h6>
              <span className="font-normal">Create a Private Service Connect Endpoint and DNS Record:</span>
            </h6>
            <ul className="console-network-instructions ml-8 list-decimal">
              <li>
                Log in to your Google Cloud Console:{' '}
                <TextLink isExternalLink href="https://console.cloud.google.com/">
                  https://console.cloud.google.com/
                </TextLink>
              </li>
              <li>
                Navigate to <b>Network services</b> by searching for it in the search bar or finding it in the side menu
                under the <b>Networking</b> section.
              </li>
              <li>
                Under the Network services side menu, click <b>Private Service Connect</b> and ensure you are on the{' '}
                <b>CONNECTED ENDPOINTS</b> tab.
              </li>
              <li>
                Create a Connected Endpoint:
                <ul className="ml-6 list-[lower-alpha]">
                  <li>
                    Click <b>+ CONNECT ENDPOINT</b>.
                  </li>
                  <li>
                    Set <b>Target</b> to <b>Published Service.</b>
                  </li>
                  <li>
                    Set <b>Target Service</b> to <i>{serviceAttachmentUrl}</i>.
                  </li>
                  <li>
                    Set <b>Endpoint name</b> to an appropriate name.
                  </li>
                  <li>
                    Set <b>Network</b> to the VPC you want to connect to your Aura instances from.
                  </li>
                  <li>
                    Set <b>Subnetwork</b> to the subnet of the VPC you want to connect to your Aura instance from.
                  </li>
                  <li>
                    Set <b>IP address</b> to an existing IP address. If you need a new IP address you can create one
                    from the <b>IP address</b> dropdown by selecting <b>CREATE IP ADDRESS</b>, entering a <b>Name</b>,
                    and clicking <b>Reserve</b>.
                  </li>
                  <li>
                    Click <b>ADD ENDPOINT</b>.
                  </li>
                </ul>
              </li>

              <li>
                Under the Network services side menu, click <b>Cloud DNS</b>.
              </li>
              <li>
                Create a Response Policy Zone:
                <ul className="ml-6 list-[lower-alpha]">
                  <li>
                    Navigate to the <b>RESPONSE POLICY ZONES</b> tab.
                  </li>
                  <li>
                    Click <b>CREATE RESPONSE POLICY</b>.
                  </li>
                  <li>
                    Set <b>Name</b> and <b>Description</b> to appropriate values.
                  </li>
                  <li>
                    Set <b>Network</b> as the network of the Connected Endpoint.
                  </li>
                  <li>
                    Click <b>CREATE</b>.
                  </li>
                </ul>
              </li>
              <li>
                Add a Response Policy Zone rule:
                <ul className="ml-6 list-[lower-alpha]">
                  <li>
                    Click <b>+ ADD RULE</b>
                  </li>
                  <li>
                    Set <b>Name</b> to an appropriate name.
                  </li>
                  <li>
                    Set <b>DNS Name</b> to <code>*.production-orch-&lt;orchNumber&gt;.neo4j.io</code>.
                  </li>
                  <li>
                    Set <b>Action</b> to Local Data.
                  </li>
                  <li>
                    Click <b>ADD A RESOURCE RECORD SET</b>.
                    <ul>
                      <li>
                        Set <b>Resource Record Type</b> to A.
                      </li>
                      <li>
                        Set <b>IPv4 Address</b> to the IP addresses you had reserved for the Connected Endpoint earlier.
                      </li>
                      <li>
                        Click <b>DONE</b>.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Click <b>CREATE</b>.
                  </li>
                </ul>
              </li>
            </ul>
            <div className="mt-4">
              The configuration should now be complete. Once you have an Aura instance created you should be able to
              test the connection using{' '}
              <TextLink
                href="https://support.neo4j.com/s/article/13174783967507-How-To-Test-Connectivity-Through-The-Private-Endpoint"
                isExternalLink
              >
                this guide.
              </TextLink>
            </div>
            <Typography variant="body-medium" className="mt-4" as="div">
              *{' '}
              <TextLink
                href="https://cloud.google.com/vpc/docs/configure-private-service-connect-services"
                isExternalLink
              >
                <i>Private Service Connect documentation</i>
              </TextLink>
            </Typography>
          </>
        )}
        {step === 3 && (
          <>
            <PrivateConnection
              publicTraffic={data.publicTraffic}
              dnsDomain={trafficConfig?.status.dnsDomain}
              onPublicTrafficDisabledChange={handlePublicTrafficDisabledChange}
              affectedInstances={affectedInstances}
            />
            <VpnMessage href="https://neo4j.com/docs/aura/platform/security/#_gcp_private_endpoints" />
            <ConfirmCheckbox isConfirmed={confirm} onConfirmChange={handleConfirmChange} />
          </>
        )}
      </Dialog.Content>
      <Dialog.Actions className="justify-between">
        <Button
          onClick={handleFinishLater}
          fill="outlined"
          htmlAttributes={{
            'data-testid': 'gcp-dialog-later',
          }}
        >
          Finish later
        </Button>
        {step === 1 && !editMode && (
          <Button
            onClick={() => handleSubmit()}
            fill="filled"
            isLoading={updateTrafficConfigRes.isLoading}
            isDisabled={isNullish(data.region) || isNullish(data.tier)}
            htmlAttributes={{
              'data-testid': 'gcp-dialog-enable-connect',
            }}
          >
            Enable Private Service Connect
          </Button>
        )}
        {step === 1 && editMode && (
          <Button
            onClick={() => handleSubmit()}
            fill="filled"
            isLoading={updateTrafficConfigRes.isLoading}
            htmlAttributes={{
              'data-testid': 'gcp-dialog-next',
            }}
          >
            Next
          </Button>
        )}
        {step === 2 && (
          <div className="flex gap-2">
            <Button onClick={handlePreviousStep} fill="outlined">
              Back
            </Button>
            <Button
              onClick={handleNextStep}
              fill="filled"
              isDisabled={isNullish(serviceAttachmentUrl)}
              htmlAttributes={{
                'data-testid': 'gcp-dialog-next',
              }}
            >
              Next
            </Button>
          </div>
        )}
        {step === 3 && (
          <div className="flex gap-2">
            <Button onClick={handlePreviousStep} fill="outlined">
              Back
            </Button>
            <Button
              onClick={handleSubmitAndClose}
              fill="filled"
              isLoading={updateTrafficConfigRes.isLoading}
              isDisabled={!confirm}
              htmlAttributes={{
                'data-testid': 'gcp-dialog-save',
              }}
            >
              Save
            </Button>
          </div>
        )}
      </Dialog.Actions>
    </>
  );
};
