import type { PropsWithChildren } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

import type { MenuItem } from '../left-nav-menu/left-nav-menu';

type LeftNavContext<T extends HTMLElement = HTMLDivElement> = {
  leftNav: T | null;
  setLeftNav: (el: T | null) => void;
  items: (MenuItem | undefined)[];
  setItems: (items: (MenuItem | undefined)[]) => void;
};

const Context = createContext<LeftNavContext>({
  leftNav: null,
  setLeftNav: () => null,
  items: [],
  setItems: () => null,
});

/**
 * Provides context necessary for LeftNav.Target and LeftNav.MenuButton
 */
export function LeftNavProvider({ children }: PropsWithChildren) {
  const [leftNav, setLeftNav] = useState<LeftNavContext['leftNav']>(null);
  const [items, setItems] = useState<LeftNavContext['items']>([]);

  const value = useMemo<LeftNavContext>(() => ({ leftNav, setLeftNav, items, setItems }), [leftNav, items]);
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useLeftNavContext() {
  return useContext(Context);
}
