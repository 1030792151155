import type { ObjectSchema } from 'yup';
import { array, boolean, mixed, number, object, string } from 'yup';

import type {
  CategoryPropertyKey,
  CategoryWithStyle,
  GeneralPropertyKey,
  PerspectiveWithStyle,
  RelationshipTypeWithStyle,
  SceneAction,
} from '../../../types/perspective';
import type { StyleRule } from '../../../types/style';
import { CategoryIconKeys } from '../../../types/style';
import type { Param, Template } from '../../../types/template';
import {
  DEFAULT_UNCATEGORISED_COLOR,
  DEFAULT_UNCATEGORISED_ICON,
  DEFAULT_UNCATEGORISED_SIZE,
  DEFAULT_UNCATEGORISED_TEXT_ALIGN,
  DEFAULT_UNCATEGORISED_TEXT_ALIGN_RELATIONSHIP,
  DEFAULT_UNCATEGORISED_TEXT_SIZE,
} from '../../styles/styles.const';
import type { Caption } from '../../styles/types';
import { CAPTION_TYPE_CATEGORY, CAPTION_TYPE_LABEL, CAPTION_TYPE_PROPERTY, CAPTION_TYPE_REL } from '../../styles/types';
import type { PerspectiveMetadataState } from '../perspectiveMetadata.types';

const categoryPropertyBasicSchema: ObjectSchema<CategoryPropertyKey> = object({
  name: string().required(),
  exclude: boolean().required(),
  dataType: string().required(),
});

const searchPhraseParamSchema: ObjectSchema<Param> = object({
  name: string().required(),
  suggestionLabel: string().defined().nullable(),
  suggestionProp: string().defined().nullable(),
  cypher: string().defined().nullable(),
  collapsed: boolean().defined().default(false),
  dataType: string().defined(),
  suggestionBoolean: boolean().defined().default(false),
});

const searchPhraseSchema: ObjectSchema<Template> = object({
  id: string().required(),
  createdAt: number().defined(),
  name: string().required(),
  text: string().defined(),
  cypher: string().defined(),
  params: array().of(searchPhraseParamSchema.defined()).defined(),
  hasCypherErrors: boolean().defined().default(false),
  isUpdateQuery: boolean().defined().nullable().default(null),
  isWriteTransactionChecked: boolean().defined().nullable().default(null),
});

const sceneActionSchema: ObjectSchema<SceneAction> = object({
  id: string().required(),
  createdAt: number().required(),
  name: string().required(),
  cypher: string().default(''),
  isUpdateQuery: boolean().defined().nullable().default(null),
  isWriteTransactionChecked: boolean().defined().nullable().default(null),
  categories: array().of(number().defined()).defined().nullable(),
  relationshipTypes: array().of(string().defined()).defined().nullable(),
  hasCypherErrors: boolean().defined().default(false),
});

const captionSchema: ObjectSchema<Caption> = object({
  inTooltip: boolean().required(),
  styles: array()
    .of(string().oneOf(['underline', 'bold', 'italic']).defined())
    .required(),
  isCaption: boolean().required(),
  key: string().required(),
  type: string().oneOf([CAPTION_TYPE_CATEGORY, CAPTION_TYPE_LABEL, CAPTION_TYPE_REL, CAPTION_TYPE_PROPERTY]).required(),
  isGdsData: boolean(),
  value: string(),
});

// These fields are difficult to validate by yup, minPoint and maxPoint have not a correct TS definition
// Other fields might be null when exported but they are not null in redux state
type ExportedStyleRule = Omit<StyleRule, 'valuesMapper' | 'rangeValue' | 'minPoint' | 'maxPoint' | 'basedOn'> & {
  valuesMapper?: any;
  rangeValue?: any;
  basedOn: string | null;
};

export const styleSchema: ObjectSchema<ExportedStyleRule> = object({
  id: string().required(),
  type: string().required(),
  size: number().required().default(1),
  minSize: number(),
  maxSize: number(),
  minColor: string(),
  maxColor: string(),
  midColor: string(),
  minColorValue: string(),
  midColorValue: string(),
  maxColorValue: string(),
  maxSizeValue: string(),
  minSizeValue: string(),
  applyColor: boolean().required().default(false),
  applySize: boolean(),
  applyCaption: boolean(),
  textAlign: string(),
  textSize: number(),
  captions: array().of(captionSchema),
  color: string(),
  isTimeZoneConvertEnabled: boolean().default(false),
  selectedTimeZone: string(),
  basedOn: string().nullable().default(''),
  conditionValue: string().nullable().default(null),
  rangeValue: mixed().nullable(),
  condition: string().nullable().default(''),
  isGdsRule: boolean(),
  gdsRuleDetails: object({
    gdsRuleId: string().defined(),
    selectedAlgorithm: string().defined(),
  })
    .default(undefined)
    .optional(), // See https://github.com/jquense/yup?tab=readme-ov-file#object-schema-defaults
  valuesMapper: array().of(object()),
  existingValues: array().of(string().required()),
});

const generalPropertyKeySchema: ObjectSchema<GeneralPropertyKey> = object({
  dataType: string().required(),
  propertyKey: string().required(),
  type: string().required(),
});

const relationshipTypeWithStyleSchema: ObjectSchema<RelationshipTypeWithStyle> = object({
  id: string().required(),
  name: string().required(),
  properties: array().of(generalPropertyKeySchema.required()).required(),
  color: string().required(),
  size: number().required(),
  textSize: number().required().default(DEFAULT_UNCATEGORISED_TEXT_SIZE),
  textAlign: string().required().default(DEFAULT_UNCATEGORISED_TEXT_ALIGN_RELATIONSHIP),
  captions: array().of(captionSchema).required().default([]),
  captionKeys: array().of(string().required()).required(),
  styleRules: array().of(styleSchema).required().default([]),
});

const categoryWithStyleSchema: ObjectSchema<CategoryWithStyle> = object({
  id: number().required(),
  name: string().required(),
  color: string().required().default(DEFAULT_UNCATEGORISED_COLOR),
  icon: string().oneOf(CategoryIconKeys).required().default(DEFAULT_UNCATEGORISED_ICON),
  size: number().required().default(DEFAULT_UNCATEGORISED_SIZE),
  textSize: number().required().default(DEFAULT_UNCATEGORISED_TEXT_SIZE),
  textAlign: string().required().default(DEFAULT_UNCATEGORISED_TEXT_ALIGN),
  labels: array().of(string().required()).required(),
  properties: array().of(categoryPropertyBasicSchema.required()).required(),
  captions: array().of(captionSchema).required().default([]),
  createdAt: number(),
  lastEditedAt: number(),
  styleRules: array().of(styleSchema).required().default([]),
  captionKeys: array().of(string().required()).required(),
});

type ExportedPerspectiveMetadata = Omit<PerspectiveMetadataState, 'indexes'> & { indexes?: any };

const metadataSchema: ObjectSchema<ExportedPerspectiveMetadata> = object({
  pathSegments: array()
    .of(
      object({
        source: string().required(),
        relationshipType: string().required(),
        target: string().required(),
      }),
    )
    .defined()
    .default([]),
  indexes: array().of(object()).default([]),
});

export type ExportedPerspectiveWithStyle = Omit<
  PerspectiveWithStyle,
  'dbId' | 'dbName' | 'dbmsId' | 'dbmsVersion' | 'labels' | 'type' | 'metadata' | 'parentPerspectiveId'
> & {
  metadata?: ExportedPerspectiveMetadata;
};

export const perspectiveSchemaComplete: ObjectSchema<ExportedPerspectiveWithStyle> = object({
  name: string().required(),
  id: string().required(),
  categories: array().of(categoryWithStyleSchema).required(),
  relationshipTypes: array().of(relationshipTypeWithStyleSchema.required()).required(),
  palette: object({
    colors: array().of(string().required()).required(),
    currentIndex: number().required().default(0),
  }),
  templates: array().of(searchPhraseSchema).required(),
  createdAt: number().required(),
  lastEditedAt: number().required(),
  hiddenRelationshipTypes: array().of(string().required()).required(),
  hideUncategorisedData: boolean(),
  labels: object(),
  version: string(),
  history: array().of(
    object({
      timestamp: number().required(),
      userId: string().required(),
    }),
  ),
  isPlugin: boolean(),
  metadata: metadataSchema.optional(),
  sceneActions: array().of(sceneActionSchema).required().default([]),
  sha: string(),
});
