import { SizedTag } from './sized-tag';

type MessageTagProps = {
  message: string;
  error?: string | null;
  onRemove: () => void;
};

const TAG_WIDTH = 315;
const CHARS_AFTER_ELLIPSIS = 15;
const WORDS_AFTER_ELLIPSIS = 2;

export const MessageTag = ({ message, error, onRemove }: MessageTagProps) => (
  <SizedTag
    htmlAttributes={{ 'data-testid': 'message-tag' }}
    isRemovable
    onRemove={onRemove}
    text={message}
    tagWidth={TAG_WIDTH}
    tailLength={CHARS_AFTER_ELLIPSIS}
    tailWords={WORDS_AFTER_ELLIPSIS}
    tooltipContent={
      <div className="max-h-[110px] w-max max-w-[350px] overflow-auto">
        <p className=" whitespace-pre-wrap">{message}</p>
        {Boolean(error) && <p className="text-palette-danger-border-weak whitespace-pre-wrap">ERROR: {error}</p>}
      </div>
    }
  />
);
