import type { Duration } from 'date-fns';

export enum LOG_STATUS {
  IN_PROGRESS = 'InProgress',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  EXPIRING_SOON = 'ExpiringSoon',
}

export enum LOG_FILE {
  QUERY = 'query.log',
  SECURITY = 'security.log',
}

export interface Log {
  id: string;
  dbid: string;
  status: LOG_STATUS;
  start: string;
  end: string;
  requestedAt: string;
  files: LOG_FILE[];
  type: string;
  timePeriod: string;
  expiresIn: Duration;
  downloadLog: boolean;
}
