/**
 * TODO for conversion
 * [x] convert actions to RTK
 * [x] rewrite reducer to RTK
 * [ ] rename action variables name to shorter ones
 * [ ] check that middlewares are using RTK utilities instead of plain text matching
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck we'll be doing conversion in several steps
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
  cloneDeep,
  difference,
  flatMap,
  intersection,
  intersectionBy,
  isEmpty,
  maxBy,
  pick,
  union,
  uniq,
} from 'lodash-es';
import { get, isBoolean, keyBy as keyByFp, mapValues, orderBy, pipe } from 'lodash/fp';
import memoize from 'memoize-one';

import migrate from '../../services/migrate';
import type { PropertyMap, UpdatedMetadata } from '../../services/perspectives/types';
import type { Node } from '../../types/graph';
import type {
  CategoryPropertyKey,
  CategoryWithUndefinedId,
  GeneralPropertyKey,
  MetadataPropertyKey,
  PathSegment,
  Perspective,
  PerspectiveCategory,
  PerspectiveWithStyle,
  SceneAction,
  TransformedSearchCategory,
} from '../../types/perspective';
import type { Template } from '../../types/template';
import { REHYDRATE } from '../persistence/constants';
import { DEFAULT_UNCATEGORISED_COLOR, DEFAULT_UNCATEGORISED_ICON } from '../styles/styles.const';
import type { RootState, SliceActionsUnion } from '../types';
import { DEFAULT_UNCATEGORISED_ID, DEFAULT_UNCATEGORISED_NAME } from './constants';
import { getLatestPerspectiveVersion, trasformations } from './migrations';
import type { PerspectiveState, UpdateSearchTemplatePayload } from './perspective.types';
import perspectiveMetadataReducer, { getPathSegments, NAME as perspectiveMetadataName } from './perspectiveMetadata';
import { getPerspective } from './perspectives.selector';

export const NAME = 'perspectives';

export const SET_CURRENT_PERSPECTIVE = 'SET_CURRENT_PERSPECTIVE';
export const UNSET_CURRENT_PERSPECTIVE = 'UNSET_CURRENT_PERSPECTIVE';

const SHOWCASE_SEARCH_NAME = 'Show me a graph';
const SHOWCASE_SEARCH_DESCRIPTION = 'Search phrase that returns a sample of your data';
const SHOWCASE_SEARCH_CYPHER = 'MATCH (n) OPTIONAL MATCH p=(n)--() RETURN p, n LIMIT 100';

export const showCaseTemplate = {
  name: SHOWCASE_SEARCH_NAME,
  description: SHOWCASE_SEARCH_DESCRIPTION,
  cypher: SHOWCASE_SEARCH_CYPHER,
};

export const otherCategory: PerspectiveCategory = {
  id: DEFAULT_UNCATEGORISED_ID,
  name: DEFAULT_UNCATEGORISED_NAME,
  labels: [],
  properties: [],
};

export const checkForNodeParameterExistence = (cypher: string) => /\$nodes/gi.test(cypher);
export const checkForRelationshipsParameterExistence = (cypher: string) => /\$relationships/gi.test(cypher);
export const checkForParameterExistence = (cypher: string) =>
  checkForNodeParameterExistence(cypher) || checkForRelationshipsParameterExistence(cypher);

// Selectors
export const getAllPerspectives = (state) => state[NAME]?.perspectives ?? [];

export const getAllSortedPerspectives = createSelector(
  (state: RootState) => getAllPerspectives(state),
  (perspectives) => {
    if (!perspectives.length) return [];

    return orderBy([(o) => o.createdAt], ['asc'])(perspectives);
  },
);

export const getOriginalPerspective = (state) => {
  return state[NAME].originalPerspective;
};

export const getIdFromName = createSelector(
  (state: RootState) => getAllPerspectives(state),
  (perspectives) => (name) => {
    const perspective = perspectives.find((p) => p.name === name);
    return perspective && perspective.id;
  },
);

export const getCategories = createSelector(
  (state: RootState) => getPerspective(state),
  (currentPerspective) => {
    if (currentPerspective && currentPerspective.categories) {
      return currentPerspective.categories;
    }
    return [];
  },
);

export const selectCategoriesWithoutExcludedProperties = createSelector(
  (state: RootState) => getCategories(state),
  (categories): TransformedSearchCategory[] => {
    return categories
      .filter((category) => category.labels.length > 0)
      .map(({ id, name, labels, properties }) => ({
        id,
        name,
        labels,
        properties: properties
          .filter((property) => !property.exclude)
          .map((property) => ({
            propertyKey: property.name,
            dataType: property.dataType,
            type: name,
          })),
      }));
  },
);

export const getCategoryForNode = createSelector(
  (state: RootState) => getCategories(state),
  (categories) => (node?: Pick<Node, 'labels'>) => {
    const found = categories.find((category) => intersection(category.labels, node?.labels).length > 0);
    return found ?? otherCategory;
  },
);

export const getCategoryForLabel = (label, state, fallbackToDefault = true) => {
  const categories = getCategories(state);
  const found = categories.find((category) => category.labels.includes(label));
  if (fallbackToDefault) {
    return found ?? otherCategory;
  }
  return found;
};

export const getCategoryById = createSelector(
  (state: RootState) => getPerspective(state),
  (currentPerspective) => (categoryId: number) => {
    let result: PerspectiveCategory | null = null;
    if (currentPerspective && currentPerspective.categories) {
      result = currentPerspective.categories.find(({ id }) => id === categoryId);
    }
    if (!result && categoryId === DEFAULT_UNCATEGORISED_ID) {
      result = otherCategory;
    }
    return result;
  },
);

export const getCategoryByName = createSelector(
  (state: RootState) => getPerspective(state),
  (currentPerspective) => (categoryName) => {
    let result = null;
    if (currentPerspective && currentPerspective.categories) {
      result = currentPerspective.categories.find(({ name }) => name === categoryName);
    }
    if (!result && categoryName === otherCategory.name) {
      result = otherCategory;
    }
    return result;
  },
);

export const getCategoryIdentifierForNodeMapper = createSelector(
  (state: RootState) => getCategoryForNode(state),
  (mapper) => (node?: Pick<Node, 'labels'>) => mapper(node).id,
);
export const getCategoryNameForNodeMapper = createSelector(
  (state: RootState) => getCategoryForNode(state),
  (mapper) => (node?: Pick<Node, 'labels'>) => mapper(node).name,
);
export const getCategoryPropertiesForNodeMapper = createSelector(
  (state: RootState) => getCategoryForNode(state),
  (state: RootState) => getPropertyKeysForLabelsMap(state),
  (mapper, propKeysLabelMap) => (node?: Node) => {
    const cat = mapper(node);

    // this node belongs to the "Other" category and therefore we show all its properties
    if (cat.id !== DEFAULT_UNCATEGORISED_ID) {
      return cat.properties;
    }

    const { labels } = node;
    return flatMap(labels, (label) =>
      (propKeysLabelMap[label] ?? []).map(({ propertyKey, dataType }) => ({
        name: propertyKey,
        exclude: false,
        dataType,
      })),
    );
  },
);

export const getCategoryNameFromId = createSelector(
  (state: RootState) => getCategoryById(state),
  (categoryByIdMapper) => (categoryId: number) => {
    const category = categoryByIdMapper(categoryId);
    return category && category.name;
  },
);

export const getTemplates = createSelector(
  (state: RootState) => getPerspective(state),
  (currentPerspective) => {
    return (currentPerspective && currentPerspective.templates) ?? [];
  },
);

export const getSceneActions = createSelector(
  (state: RootState) => getPerspective(state),
  (currentPerspective) => (currentPerspective && currentPerspective.sceneActions) ?? [],
);

export const getVisibleLabels = createSelector(
  (state: RootState) => getPerspective(state),
  (state: RootState) => getLabels(state),
  (currentPerspective, labels) => {
    if (!currentPerspective) return [];

    const { categories, hideUncategorisedData } = currentPerspective;

    if (hideUncategorisedData) {
      return labels.filter((label) => categories.some((category) => category.labels.includes(label)));
    }
    return labels;
  },
);

export const getVisibleRelationshipTypes = createSelector(
  (state: RootState) => getPerspective(state),
  (state: RootState) => getRelationshipTypes(state),
  (currentPerspective, relationshipTypes) => {
    if (!currentPerspective) return [];

    const { hiddenRelationshipTypes } = currentPerspective;
    return difference(relationshipTypes, hiddenRelationshipTypes);
  },
);

export const getHiddenRelationshipTypes = createSelector(
  (state: RootState) => getPerspective(state),
  (currentPerspective) => {
    if (!currentPerspective) return [];

    const { hiddenRelationshipTypes } = currentPerspective;
    return hiddenRelationshipTypes ?? [];
  },
);
export const getRelationshipDetails = createSelector(
  (state: RootState) => getPerspective(state),
  (currentPerspective) => {
    if (!currentPerspective) return [];

    return currentPerspective.relationshipTypes;
  },
);

const sortRelationshipTypes = memoize((relationshipTypes) => relationshipTypes.map(({ name }) => name).sort());
export const getRelationshipTypes = createSelector(
  (state: RootState) => getPerspective(state),
  (currentPerspective): string[] => {
    if (!currentPerspective) return [];

    return sortRelationshipTypes(currentPerspective.relationshipTypes);
  },
);

export const selectVisiblePathSegments = createSelector(
  getPathSegments,
  getRelationshipTypes,
  getVisibleRelationshipTypes,
  (pathSegments, relationshipTypes, visibleRelationshipTypes) => {
    return relationshipTypes.length !== visibleRelationshipTypes.length && pathSegments
      ? pathSegments.filter((pathSegment: PathSegment) =>
          visibleRelationshipTypes.includes(pathSegment.relationshipType),
        )
      : pathSegments;
  },
);

export const getPropertyKeysForRelationshipTypesMap = createSelector(
  (state: RootState) => getPerspective(state),
  (currentPerspective) => {
    if (!currentPerspective) return {};

    const props = pipe(keyByFp(get('id')), mapValues(get('properties')))(currentPerspective.relationshipTypes);
    return props;
  },
);

export const selectPropertyKeysForVisibleRelationshipTypes = createSelector(
  getVisibleRelationshipTypes,
  getPropertyKeysForRelationshipTypesMap,
  (visibleRelationshipTypes, propertyKeys) => {
    return visibleRelationshipTypes.reduce((acc: Record<string, GeneralPropertyKey[]>, relationshipType) => {
      acc[relationshipType] = propertyKeys[relationshipType];
      return acc;
    }, {});
  },
);

/**
 * Facilitate fast category lookups for generating search suggestions
 */
export const selectCategoryDictionaries = createSelector(selectCategoriesWithoutExcludedProperties, (categories) => {
  const categoryToPropertyDictionary = new Map<string, Set<string>>();
  // why we need categoryName -> [label1, label2], not possible to be label -> categoryName
  // because a node's category is decided by both
  // 1. the order of categories defined in perspective
  // 2. the order of labels assigned to a node
  // we need to store all labels per a category, then do the check with a nodes' labels
  // for instance:
  // (node1 :Person :Movie), given categories
  // [ { name: PersonCategory, labels: [Person] },
  //   { name: MovieCategory, labels: [Movie] }    ]
  // node1 is of category PersonCategory,
  // but if n (node1 :Movie :Person)
  // node1 is of category MovieCategory
  const categoryToLabelDictionary = new Map<string, string[]>();

  for (const category of categories ?? []) {
    const { name, properties, labels } = category;

    const propertyKeys = properties.map((property) => property.propertyKey);
    const categoryProperties = new Set<string>(propertyKeys);
    categoryToPropertyDictionary.set(name, categoryProperties);

    categoryToLabelDictionary.set(name, labels);
  }

  return {
    categoryToPropertyDictionary,
    categoryToLabelDictionary,
  };
});

/**
 * Facilitate fast relationship lookups for generating search suggestions
 */
export const selectRelationshipToPropertyDictionary = createSelector(
  getVisibleRelationshipTypes,
  selectPropertyKeysForVisibleRelationshipTypes,
  (relationshipTypes, relationshipPropertyKeys) => {
    const relationshipToPropertyDictionary = new Map<string, Set<string>>();

    for (const relationship of relationshipTypes ?? []) {
      const propertyKeys = relationshipPropertyKeys[relationship]?.map((property) => property.propertyKey);
      const relationshipProperties = new Set<string>(propertyKeys);
      relationshipToPropertyDictionary.set(relationship, relationshipProperties);
    }

    return relationshipToPropertyDictionary;
  },
);

export const getPropertyKeysForLabelsMap = createSelector(
  (state: RootState) => getPerspective(state),
  (currentPerspective): Perspective['labels'] => {
    if (!currentPerspective) return [];

    return currentPerspective.labels;
  },
);

const sortLabels = memoize((labels) => Object.keys(labels).sort());

export const getLabels = createSelector(
  (state: RootState) => getPerspective(state),
  (currentPerspective) => {
    if (!currentPerspective || !currentPerspective.labels) return [];
    return sortLabels(currentPerspective.labels);
  },
);

export const selectCategoryPropertyKeys = createSelector(
  getPerspective,
  selectCategoriesWithoutExcludedProperties,
  getPropertyKeysForLabelsMap,
  getLabels,
  (currentPerspective, categories, propertyKeysForLabelsMap, labels) => {
    if (!currentPerspective) return {};

    const { hideUncategorisedData } = currentPerspective;
    const labelsCategoryMap = {};

    if (hideUncategorisedData) {
      categories.forEach((category) => {
        category.labels
          .filter((label) => labels.includes(label))
          .forEach((label) => {
            labelsCategoryMap[label] = category;
          });
      });
    } else {
      for (const [label, labelProperties] of Object.entries(currentPerspective.labels)) {
        labelsCategoryMap[label] = {
          properties: labelProperties,
        };
      }
    }

    const categoryPropertyKeys = Object.keys(propertyKeysForLabelsMap)
      .filter((label) => Object.keys(labelsCategoryMap).includes(label))
      .reduce((labelsPropsMap, label) => {
        const properties = propertyKeysForLabelsMap[label] ?? [];
        const categoryPropKeys = labelsCategoryMap[label]?.properties ?? [];
        labelsPropsMap[label] = intersectionBy(properties, categoryPropKeys, (prop) => prop.propertyKey);
        return labelsPropsMap;
      }, {});

    return categoryPropertyKeys;
  },
);

const propertiesToExtract = ['id', 'name', 'labels', 'properties', 'createdAt', 'lastEditedAt'];
const getReducerPerspectiveFromStoredPerspective = (perspective) => ({
  ...perspective,
  palette: undefined,
  categories: perspective.categories?.map((cat) => pick(cat, propertiesToExtract)),
  relationshipTypes: perspective.relationshipTypes?.map((relType) => pick(relType, propertiesToExtract)),
});

export const initialState: PerspectiveState = { perspectives: [] };

const getCurrentPerspective = (state: PerspectiveState, perspectiveId: string) =>
  state?.perspectives?.find(({ id }) => id === perspectiveId);
const getCategory = (perspective: Perspective, categoryId: PerspectiveCategory['id']) =>
  perspective.categories.find(({ id }) => id === categoryId) ?? null;

const getNextCategoryId = (perspective: Perspective) => {
  const lastId =
    perspective.categories.length > 0
      ? (maxBy(perspective.categories, 'id')?.id ?? DEFAULT_UNCATEGORISED_ID)
      : DEFAULT_UNCATEGORISED_ID;
  return lastId + 1;
};

const updateLastEditedAt = (category: PerspectiveCategory) => {
  category.lastEditedAt = Date.now();
};

const perspectiveSlice = createSlice({
  name: NAME,
  initialState,
  reducers: (create) => ({
    addSearchTemplate: create.preparedReducer(
      ({
        templateName,
        perspectiveId,
        templateData,
      }: {
        templateName: Template['name'];
        perspectiveId: Perspective['id'];
        templateData?: Partial<
          Pick<Template, 'text' | 'cypher' | 'isUpdateQuery' | 'isWriteTransactionChecked' | 'params'>
        >;
      }) => {
        const searchPhrase: Template = {
          name: templateName,
          id: `tmpl:${Date.now()}`,
          createdAt: Date.now(),
          text: templateData?.text ?? '',
          cypher: templateData?.cypher ?? '',
          isUpdateQuery: isBoolean(templateData?.isUpdateQuery) ? templateData.isUpdateQuery : null,
          isWriteTransactionChecked: isBoolean(templateData?.isWriteTransactionChecked)
            ? templateData.isWriteTransactionChecked
            : null,
          params: templateData?.params ?? [],
          hasCypherErrors: false,
        };

        return {
          payload: {
            perspectiveId,
            searchPhrase,
          },
        };
      },
      (state, action) => {
        const { perspectiveId, searchPhrase } = action.payload;
        const currentPerspective = getCurrentPerspective(state, perspectiveId);
        if (!currentPerspective) return;

        currentPerspective.templates.unshift(searchPhrase);
      },
    ),
    removeSearchTemplate: create.reducer<{ templateId: string; perspectiveId: string }>((state, action) => {
      const { templateId, perspectiveId } = action.payload;
      const currentPerspective = getCurrentPerspective(state, perspectiveId);
      if (!currentPerspective) return;
      currentPerspective.templates = currentPerspective.templates.filter((t) => t.id !== templateId);
    }),
    updateSearchTemplate: create.reducer<UpdateSearchTemplatePayload>((state, action) => {
      const { perspectiveId, templateId, templateData } = action.payload;
      const template = getCurrentPerspective(state, perspectiveId)?.templates.find((t) => t.id === templateId);
      if (!template) {
        return;
      }

      if (templateData.name !== undefined) template.name = templateData.name;
      if (templateData.text !== undefined) template.text = templateData.text;
      if (templateData.cypher !== undefined) template.cypher = templateData.cypher;
      if (isBoolean(templateData.isUpdateQuery)) template.isUpdateQuery = templateData.isUpdateQuery;
      if (isBoolean(templateData.isWriteTransactionChecked)) {
        template.isWriteTransactionChecked = templateData.isWriteTransactionChecked;
      }
      if (templateData.params !== undefined) template.params = templateData.params;
      if (templateData.hasCypherErrors !== undefined) template.hasCypherErrors = templateData.hasCypherErrors;
    }),
    addSceneAction: create.reducer<{ sceneActionId: string; sceneActionName: string; perspectiveId: string }>(
      (state, action) => {
        const { sceneActionId, sceneActionName, perspectiveId } = action.payload;
        const currentPerspective = getCurrentPerspective(state, perspectiveId);
        if (!currentPerspective) return;

        currentPerspective.sceneActions.unshift({
          name: sceneActionName ?? 'Undefined',
          id: sceneActionId,
          createdAt: Date.now(),
          cypher: '',
          isUpdateQuery: null,
          isWriteTransactionChecked: null,
          categories: null,
          relationshipTypes: null,
          hasCypherErrors: false,
        });
      },
    ),
    removeSceneAction: create.reducer<{ sceneActionId: string; perspectiveId: string }>((state, action) => {
      const { sceneActionId, perspectiveId } = action.payload;
      const currentPerspective = getCurrentPerspective(state, perspectiveId);
      if (!currentPerspective) return;

      currentPerspective.sceneActions = currentPerspective.sceneActions.filter((action) => action.id !== sceneActionId);
    }),
    updateSceneAction: create.reducer<{
      sceneActionId: string;
      sceneActionData: Partial<SceneAction>;
      perspectiveId: string;
    }>((state, action) => {
      const { sceneActionId, perspectiveId, sceneActionData } = action.payload;
      const currentPerspective = getCurrentPerspective(state, perspectiveId);
      if (!currentPerspective) return;

      const currentAction = currentPerspective.sceneActions.find((action) => action.id === sceneActionId);
      if (!currentAction) return;
      Object.assign(currentAction, sceneActionData);
    }),
    updateSceneActionOrder: create.reducer<{
      fromSceneActionIndex: number;
      toSceneActionIndex: number;
      perspectiveId: string;
    }>((state, action) => {
      const { fromSceneActionIndex, toSceneActionIndex, perspectiveId } = action.payload;
      const currentPerspective = getCurrentPerspective(state, perspectiveId);
      const sceneActionAtFromIndex = currentPerspective?.sceneActions?.[fromSceneActionIndex];
      if (!currentPerspective || sceneActionAtFromIndex === undefined) return;

      if (fromSceneActionIndex < toSceneActionIndex) {
        currentPerspective.sceneActions.splice(toSceneActionIndex + 1, 0, sceneActionAtFromIndex);
        currentPerspective.sceneActions.splice(fromSceneActionIndex, 1);
      } else {
        currentPerspective.sceneActions.splice(toSceneActionIndex, 0, sceneActionAtFromIndex);
        currentPerspective.sceneActions.splice(fromSceneActionIndex + 1, 1);
      }
    }),
    addLabelToCategory: create.reducer<{
      labelName: string;
      categoryId: number;
      perspectiveId: string;
      properties?: CategoryPropertyKey[];
    }>((state, action) => {
      const { perspectiveId, categoryId, labelName, properties } = action.payload;
      const currentPerspective = getCurrentPerspective(state, perspectiveId);
      if (!currentPerspective) return;

      const category = getCategory(currentPerspective, categoryId);
      if (!category) return;

      category.labels.push(labelName);
      if (properties) {
        mergePropertiesToCategory(category, properties);
      }
      updateLastEditedAt(category);
    }),
    removeLabelFromCategory: create.reducer<{
      labelName: string;
      categoryId: number;
      perspectiveId: string;
      propertyKeysForLabelsMap: Perspective['labels'];
    }>((state, action) => {
      const { perspectiveId, categoryId, labelName, propertyKeysForLabelsMap } = action.payload;
      const currentPerspective = getCurrentPerspective(state, perspectiveId);
      if (!currentPerspective) return;
      const category = getCategory(currentPerspective, categoryId);
      if (!category) return;

      category.labels = category.labels.filter((label) => label !== labelName);
      if (propertyKeysForLabelsMap) {
        removeLabelPropertiesFromCategory(category, labelName, propertyKeysForLabelsMap);
      }
      updateLastEditedAt(category);
    }),
    removeLabelsFromPerspective: create.reducer<{ perspectiveId: string; labels: string[] }>((state, action) => {
      const { perspectiveId, labels } = action.payload;
      const currentPerspective = getCurrentPerspective(state, perspectiveId);
      if (!currentPerspective) return;

      for (const category of currentPerspective.categories) {
        category.labels = category.labels.filter((label) => !labels.includes(label));
      }
    }),
    addCategoryToPerspective: create.preparedReducer(
      (newCategoryName: string, perspectiveId: string, labelName?: string) => {
        const creationTime = Date.now();
        const newCategory: CategoryWithUndefinedId = {
          ...otherCategory,
          id: undefined,
          name: newCategoryName,
          labels: labelName ? [labelName] : [],
          createdAt: creationTime,
          lastEditedAt: creationTime,
          color: DEFAULT_UNCATEGORISED_COLOR,
          icon: DEFAULT_UNCATEGORISED_ICON,
        };

        return {
          payload: {
            perspectiveId,
            newCategory,
          },
        };
      },
      (state, action) => {
        const { perspectiveId, newCategory } = action.payload;
        const currentPerspective = getCurrentPerspective(state, perspectiveId);
        if (!currentPerspective) return;

        const labelAlreadyCategorised = !!currentPerspective.categories.find(
          (oldCat) => intersection(newCategory.labels, oldCat.labels).length > 0,
        );
        if (labelAlreadyCategorised) return;

        newCategory.id = getNextCategoryId(currentPerspective);
        currentPerspective.categories.unshift(newCategory);
      },
    ),
    addCategoriesToPerspective: create.preparedReducer(
      (categories: string[][], perspectiveId: string, properties: Record<string, GeneralPropertyKey[]>) => {
        const creationTime = Date.now();
        const newCategories: CategoryWithUndefinedId[] = categories.map((category) => ({
          ...otherCategory,
          id: undefined,
          labels: category,
          name: category[0] as string,
          createdAt: creationTime,
          lastEditedAt: creationTime,
        }));

        return {
          payload: {
            perspectiveId,
            newCategories,
            properties,
          },
        };
      },
      (
        state,
        action: PayloadAction<{
          newCategories: CategoryWithUndefinedId[];
          properties: Record<string, GeneralPropertyKey[]>;
          perspectiveId: string;
        }>,
      ) => {
        const { perspectiveId, newCategories, properties } = action.payload;
        const currentPerspective = getCurrentPerspective(state, perspectiveId);
        if (!currentPerspective) return;

        currentPerspective.categories = mergeCategories(currentPerspective, newCategories, properties);
      },
    ),
    addLabelsAsCategoriesToPerspective: create.preparedReducer(
      (labels: string[], perspectiveId: string, properties: Record<string, GeneralPropertyKey[]>) => {
        const creationTime = Date.now();
        const newCategories: CategoryWithUndefinedId[] = labels.map((label) => ({
          ...otherCategory,
          id: undefined,
          labels: [label],
          name: label,
          createdAt: creationTime,
          lastEditedAt: creationTime,
        }));

        return {
          payload: {
            perspectiveId,
            newCategories,
            properties,
          },
        };
      },
      (
        state,
        action: PayloadAction<{
          newCategories: CategoryWithUndefinedId[];
          properties: Record<string, GeneralPropertyKey[]>;
          perspectiveId: string;
        }>,
      ) => {
        const { perspectiveId, newCategories, properties } = action.payload;
        const currentPerspective = getCurrentPerspective(state, perspectiveId);
        if (!currentPerspective) return;

        currentPerspective.categories = mergeCategories(currentPerspective, newCategories, properties);
      },
    ),
    removeCategoryFromPerspective: create.reducer<{ categoryId: number; perspectiveId: string }>((state, action) => {
      const { perspectiveId, categoryId } = action.payload;
      const currentPerspective = getCurrentPerspective(state, perspectiveId);
      if (!currentPerspective) return;

      currentPerspective.categories = currentPerspective.categories.filter(({ id }) => id !== categoryId);
    }),
    addRelationshipsToPerspective: create.reducer<{
      perspectiveId: string;
      propertyKeysForRelationshipsTypesMap: UpdatedMetadata['relPropsMap'];
      relationshipTypes: string[];
    }>((state, action) => {
      const { perspectiveId, propertyKeysForRelationshipsTypesMap, relationshipTypes } = action.payload;
      const currentPerspective = getCurrentPerspective(state, perspectiveId);
      if (!currentPerspective) return;

      const rels = relationshipTypes.map((rel) => ({
        properties: propertyKeysForRelationshipsTypesMap[rel] || [],
        name: rel,
        id: rel,
      }));

      currentPerspective.relationshipTypes = uniq([...currentPerspective.relationshipTypes, ...rels]);
    }),
    addPropertiesToCategory: create.reducer<{
      properties: MetadataPropertyKey[];
      categoryId: number;
      perspectiveId: string;
    }>((state, action) => {
      const { properties, categoryId, perspectiveId } = action.payload;
      const currentPerspective = getCurrentPerspective(state, perspectiveId);
      if (!currentPerspective) return;
      const category = getCategory(currentPerspective, categoryId);
      if (!category) return;

      mergePropertiesToCategory(category, properties);
      updateLastEditedAt(category);
    }),
    removePropertiesFromCategory: create.reducer<{
      perspectiveId: string;
      categoryId: number;
      properties: string[];
    }>((state, action) => {
      const { properties, categoryId, perspectiveId } = action.payload;
      const currentPerspective = getCurrentPerspective(state, perspectiveId);
      if (!currentPerspective) return;
      const category = getCategory(currentPerspective, categoryId);
      if (!category) return;

      category.properties = category.properties.filter(({ name }) => !properties.includes(name));
      updateLastEditedAt(category);
    }),
    removePropertiesFromRelationships: create.reducer<{
      relationshipsProps: PropertyMap;
      perspectiveId: string;
    }>((state, action) => {
      const { relationshipsProps, perspectiveId } = action.payload;
      const currentPerspective = getCurrentPerspective(state, perspectiveId);
      if (!currentPerspective) return;

      for (const rel of currentPerspective.relationshipTypes) {
        if (Object.keys(relationshipsProps).includes(rel.id)) {
          const keys = relationshipsProps[rel.id]?.map((r) => r.propertyKey) ?? [];
          rel.properties = rel.properties.filter((p) => !keys.includes(p.propertyKey));
        }
      }
    }),
    addPropertiesToRelationships: create.reducer<{
      relationshipsProps: PropertyMap;
      perspectiveId: string;
    }>((state, action) => {
      const { relationshipsProps, perspectiveId } = action.payload;
      const currentPerspective = getCurrentPerspective(state, perspectiveId);
      if (!currentPerspective) return;

      for (const rel of currentPerspective.relationshipTypes) {
        const propertiesIds = rel.properties.map((p) => p.propertyKey);
        const properties = relationshipsProps[rel.id];
        if (!properties) continue;

        for (const property of properties) {
          if (!propertiesIds.includes(property.propertyKey)) {
            rel.properties.push(property);
          }
        }
      }
    }),
    removeRelationshipsFromPerspective: create.reducer<{ perspectiveId: string; relationshipTypes: string[] }>(
      (state, action) => {
        const { perspectiveId, relationshipTypes } = action.payload;
        const currentPerspective = getCurrentPerspective(state, perspectiveId);
        if (!currentPerspective) return;

        currentPerspective.relationshipTypes = currentPerspective.relationshipTypes.filter(
          ({ id }) => !relationshipTypes.includes(id),
        );
      },
    ),
    updateCategoryName: create.reducer<{
      newCategoryName: string;
      categoryId: number;
      perspectiveId: string;
    }>((state, action) => {
      const { newCategoryName, categoryId, perspectiveId } = action.payload;
      const currentPerspective = getCurrentPerspective(state, perspectiveId);
      if (!currentPerspective) return;
      const category = getCategory(currentPerspective, categoryId);
      if (!category) return;

      category.name = newCategoryName;
      updateLastEditedAt(category);
    }),
    updateCategoryProperty: create.reducer<{
      propertyIndex: number;
      categoryId: number;
      newProperty: CategoryPropertyKey;
      perspectiveId: string;
    }>((state, action) => {
      const { propertyIndex, categoryId, newProperty, perspectiveId } = action.payload;
      const currentPerspective = getCurrentPerspective(state, perspectiveId);
      if (!currentPerspective) return;
      const category = getCategory(currentPerspective, categoryId);
      if (!category) return;

      category.properties[propertyIndex] = Object.assign({}, category.properties[propertyIndex], newProperty);
      updateLastEditedAt(category);
    }),
    setMetaLabelsToPerspective: create.reducer<{
      perspectiveId: string;
      propertyKeysForLabelsMap: Perspective['labels'];
    }>((state, action) => {
      const { perspectiveId, propertyKeysForLabelsMap } = action.payload;
      const currentPerspective = getCurrentPerspective(state, perspectiveId);
      if (!currentPerspective) return;

      currentPerspective.labels = propertyKeysForLabelsMap;
    }),
    updateCategoryOrder: create.reducer<{
      fromCategoryIndex: number;
      toCategoryIndex: number;
      perspectiveId: string;
    }>((state, action) => {
      const { fromCategoryIndex, toCategoryIndex, perspectiveId } = action.payload;
      const currentPerspective = getCurrentPerspective(state, perspectiveId);
      const categoryAtFromIndex = currentPerspective?.categories?.[fromCategoryIndex];
      if (!currentPerspective || categoryAtFromIndex === undefined) return;

      if (fromCategoryIndex < toCategoryIndex) {
        currentPerspective.categories.splice(toCategoryIndex + 1, 0, categoryAtFromIndex);
        currentPerspective.categories.splice(fromCategoryIndex, 1);
      } else {
        currentPerspective.categories.splice(toCategoryIndex, 0, categoryAtFromIndex);
        currentPerspective.categories.splice(fromCategoryIndex + 1, 1);
      }
    }),
    addPerspective: create.reducer<Perspective>((state, action) => {
      state.perspectives.push(action.payload);
    }),
    removePerspective: create.reducer<{ perspectiveId: string; dbId?: string }>((state, action) => {
      const { perspectiveId } = action.payload;
      state.perspectives = state.perspectives.filter(({ id }) => id !== perspectiveId);
    }),
    clearPerspectives: create.reducer((state) => {
      state.perspectives = [];
    }),
    appendPerspectives: create.reducer<PerspectiveWithStyle[]>((state, action) => {
      const additionalPerspectives = action.payload.filter(
        (storedPerspective) => !state.perspectives.find((perspective) => perspective.id === storedPerspective.id),
      );
      state.perspectives = [...state.perspectives, ...additionalPerspectives];
    }),
    loadPerspective: create.reducer<PerspectiveWithStyle>((state, action) => {
      state.perspectives = state.perspectives.filter((p) => p.id !== action.payload.id);

      let processedPerspective = cloneDeep(action.payload);
      processedPerspective = getReducerPerspectiveFromStoredPerspective(processedPerspective);

      state.perspectives.push(processedPerspective);
      state.currentPerspectiveId = processedPerspective.id;
      // Original perspective is needed for multi-editing perspective, we need the stored perspective
      state.originalPerspective = cloneDeep(action.payload);
    }),
    updatePerspectiveName: create.reducer<{ name: string; perspectiveId: string }>((state, action) => {
      const { perspectiveId, name } = action.payload;
      const currentPerspective = getCurrentPerspective(state, perspectiveId);
      if (!currentPerspective) return;

      currentPerspective.name = name;
    }),
    updatePerspectiveSha: create.reducer<{ sha: string; perspectiveId: string }>((state, action) => {
      const { sha, perspectiveId } = action.payload;
      const currentPerspective = getCurrentPerspective(state, perspectiveId);
      if (!currentPerspective) return;

      currentPerspective.sha = sha;
    }),
    updatePerspectiveHistory: create.reducer<{ userId: string; timestamp: number; perspectiveId: string }>(
      (state, action) => {
        const { userId, timestamp, perspectiveId } = action.payload;
        const currentPerspective = getCurrentPerspective(state, perspectiveId);
        if (!currentPerspective) return;
        const newHistory = currentPerspective.history?.filter((entry) => entry.userId !== userId) ?? [];
        newHistory.push({
          userId,
          timestamp,
        });

        currentPerspective.history = newHistory.slice(-10);
        state.originalPerspective = cloneDeep(currentPerspective);
      },
    ),
    hideRelationshipTypes: create.reducer<{ perspectiveId: string; relationshipTypes: string[] }>((state, action) => {
      const { perspectiveId, relationshipTypes } = action.payload;
      const currentPerspective = getCurrentPerspective(state, perspectiveId);
      if (!currentPerspective) return;

      currentPerspective.hiddenRelationshipTypes = union(
        currentPerspective.hiddenRelationshipTypes ?? [],
        relationshipTypes,
      );
    }),
    revealRelationshipTypes: create.reducer<{ perspectiveId: string; relationshipTypes: string[] }>((state, action) => {
      const { perspectiveId, relationshipTypes } = action.payload;
      const currentPerspective = getCurrentPerspective(state, perspectiveId);
      if (!currentPerspective) return;

      currentPerspective.hiddenRelationshipTypes = (currentPerspective.hiddenRelationshipTypes ?? []).filter(
        (hiddenRelationship) => !relationshipTypes.includes(hiddenRelationship),
      );
    }),
    setHideUncategorisedData: create.reducer<{ perspectiveId: string; hideUncategorisedData: boolean }>(
      (state, action) => {
        const { perspectiveId, hideUncategorisedData } = action.payload;
        const currentPerspective = getCurrentPerspective(state, perspectiveId);
        if (!currentPerspective) return;

        currentPerspective.hideUncategorisedData = hideUncategorisedData;
      },
    ),
  }),
  extraReducers: (builder) => {
    builder.addCase(SET_CURRENT_PERSPECTIVE, (state, action) => {
      const perspectiveId = action.payload ? action.payload?.perspectiveId : action?.perspectiveId;
      const currentPerspective = getCurrentPerspective(state, perspectiveId);
      if (!currentPerspective) return;

      state.originalPerspective = currentPerspective;
    });
    builder.addCase(REHYDRATE, (state, action) => {
      // If local storage does not have the correct structure with a different key for the perspectives, then we cannot blacklist them because we need to migrate them to the new version
      // this means we have to handle the perspectives as part of the full state rehydration and create the new key in storage
      // In this situation, since we don't blacklist them, they will be replicated in both storage keys
      if (!action.payload || !Object.keys(action.payload).length > 0) return state;
      const perspectiveVersion = getLatestPerspectiveVersion();
      // When getting local storage with previous v5 structure
      if (
        Array.isArray(action.payload.perspectives?.perspectives) &&
        action.payload.perspectives?.perspectives.length > 0
      ) {
        const { perspectives } = action.payload.perspectives;
        const migratedPerspectives = perspectives.map((p) => {
          return migrate({ perspectives: [p] }, trasformations, p.version ?? perspectiveVersion).perspectives.map(
            (perspective) => {
              return { ...perspective, version: perspectiveVersion };
            },
          )[0];
        });
        return {
          ...state,
          perspectives: migratedPerspectives.map(getReducerPerspectiveFromStoredPerspective),
        };
      }
    });
    builder.addDefaultCase((state, action) => {
      const perspectiveId = action.payload ? action.payload?.perspectiveId : action?.perspectiveId;
      const currentPerspective = getCurrentPerspective(state, perspectiveId);

      if (!isEmpty(currentPerspective)) {
        const newMetadata = perspectiveMetadataReducer(currentPerspective[perspectiveMetadataName], action);
        currentPerspective[perspectiveMetadataName] = newMetadata;
        return state;
      }
      return isEmpty(state) ? initialState : state;
    });
  },
});

export const {
  addCategoriesToPerspective,
  addCategoryToPerspective,
  addLabelToCategory,
  addLabelsAsCategoriesToPerspective,
  addPerspective,
  addPropertiesToCategory,
  addPropertiesToRelationships,
  addRelationshipsToPerspective,
  addSceneAction,
  addSearchTemplate,
  appendPerspectives,
  clearPerspectives,
  hideRelationshipTypes,
  loadPerspective,
  removeCategoryFromPerspective,
  removeLabelFromCategory,
  removeLabelsFromPerspective,
  removePerspective,
  removePropertiesFromCategory,
  removePropertiesFromRelationships,
  removeRelationshipsFromPerspective,
  removeSceneAction,
  removeSearchTemplate,
  revealRelationshipTypes,
  setHideUncategorisedData,
  setMetaLabelsToPerspective,
  updateCategoryName,
  updateCategoryOrder,
  updateCategoryProperty,
  updatePerspectiveHistory,
  updatePerspectiveName,
  updatePerspectiveSha,
  updateSceneAction,
  updateSceneActionOrder,
  updateSearchTemplate,
} = perspectiveSlice.actions;

export default perspectiveSlice.reducer;

export type PerspectiveAction = SliceActionsUnion<typeof perspectiveSlice.actions>;

// Common reducer logic
const mergePropertiesToCategory = (category, properties) => {
  properties.forEach((prop) => {
    const existingProp = category.properties.find((exProp) => exProp.name === prop.propertyKey);
    if (existingProp) {
      if (existingProp.dataType !== prop.dataType) {
        existingProp.dataType = 'Various';
      }
    } else {
      category.properties.push({
        name: prop.propertyKey,
        exclude: false,
        dataType: prop.dataType,
      });
    }
  });
};

const removeLabelPropertiesFromCategory = (category, label, propertyKeysForLabelsMap) => {
  const remainingProps = category.labels
    .filter((exLabel) => exLabel !== label)
    .reduce((props, remLabel) => {
      return props.concat(propertyKeysForLabelsMap[remLabel] || []);
    }, []);
  const propertiesToRemove = [];

  if (propertyKeysForLabelsMap[label]) {
    propertyKeysForLabelsMap[label].forEach((property) => {
      const duplicateProps = remainingProps.filter((remProp) => remProp.propertyKey === property.propertyKey);
      if (duplicateProps.length === 0) {
        propertiesToRemove.push(property.propertyKey);
      } else {
        const categoryProp = category.properties.find((catProp) => catProp.name === property.propertyKey);
        if (categoryProp.dataType === 'Various') {
          let [{ dataType }] = duplicateProps;
          for (let i = 1; i < duplicateProps.length; i++) {
            if (dataType !== duplicateProps[i].dataType) {
              dataType = 'Various';
              break;
            }
          }

          if (dataType !== categoryProp.dataType) {
            categoryProp.dataType = dataType;
          }
        }
      }
    });

    if (propertiesToRemove.length > 0) {
      category.properties = category.properties.filter(({ name }) => !propertiesToRemove.includes(name));
    }
  } else {
    category.properties = category.properties.filter(({ name }) =>
      remainingProps.find((remainingProp) => remainingProp.propertyKey === name),
    );
  }
};

const mergeCategories = (
  currentPerspective: Perspective,
  newCategories: CategoryWithUndefinedId[],
  properties: Record<string, GeneralPropertyKey[]>,
): PerspectiveCategory[] => {
  const categoriesToAdd = newCategories.filter(
    (newCat) => !currentPerspective.categories.find((oldCat) => intersection(newCat.labels, oldCat.labels).length > 0),
  );
  const categories = [...currentPerspective.categories, ...categoriesToAdd];
  const nextCategoryId = getNextCategoryId(currentPerspective);
  const otherCategory = categories.filter((c) => c.id === DEFAULT_UNCATEGORISED_ID);
  const restCategories = categoriesToAdd
    .filter((c) => c.id !== DEFAULT_UNCATEGORISED_ID)
    .map((category, index) => {
      category.id = nextCategoryId + index;
      category.labels.map((label) => {
        const propertiesByLabel = properties[label];
        if (propertiesByLabel) {
          category.properties = propertiesByLabel.map((key) => ({
            name: key.propertyKey,
            exclude: false,
            dataType: key.dataType,
          }));
        }
        return null;
      });
      return category;
    });
  return [...currentPerspective.categories, ...otherCategory, ...restCategories];
};
