const uuidRegex = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

export const validateUUID = (uuid: string): string | null => {
  if (!uuidRegex.test(uuid)) {
    return 'Must be a valid uuid.';
  }
  return null;
};

const arnRegex = /^arn(:.*){5}/;

export const isValidArn = (arn: string): boolean => {
  return arnRegex.test(arn);
};
