import type { model } from '@neo4j/graph-schema-utils';
import type * as ImportShared from '@nx/import-shared';

export class NodeKeyProperty {
  node: model.NodeObjectType;

  keyProperty: model.Property;

  constructor(node: model.NodeObjectType, keyProperty: model.Property) {
    this.node = node;
    this.keyProperty = keyProperty;
  }
}

export class GraphSchemaExtensionsRepresentation {
  nodeKeyProperties: NodeKeyProperty[];

  constructor(nodeKeyProperties: NodeKeyProperty[]) {
    this.nodeKeyProperties = nodeKeyProperties;
  }
}

export class TableSchemaLocalField {
  name: string;

  recommendedType: { type: model.PrimitivePropertyTypes };

  sample: string;

  isUsed: boolean;

  constructor(name: string, recommendedType: { type: model.PrimitivePropertyTypes }, sample: string, isUsed: boolean) {
    this.name = name;
    this.recommendedType = recommendedType;
    this.sample = sample;
    this.isUsed = isUsed;
  }
}

export class TableSchemaCloudField {
  name: string;

  rawType: string;

  recommendedType?: { type: model.PrimitivePropertyTypes };

  supportedTypes?: model.PrimitivePropertyType[];

  isUsed: boolean;

  constructor(
    name: string,
    rawType: string,
    recommendedType: { type: model.PrimitivePropertyTypes } | undefined,
    supportedTypes: { type: model.PrimitivePropertyTypes }[] | undefined,
    isUsed: boolean,
  ) {
    this.name = name;
    this.rawType = rawType;
    this.recommendedType = recommendedType;
    this.supportedTypes = supportedTypes;
    this.isUsed = isUsed;
  }
}

export type TableSchemaField = TableSchemaCloudField | TableSchemaLocalField;

export class DataSourceSchema {
  type: ImportShared.DataSourceLocation;

  tableSchemas: TableSchema[];

  constructor(type: ImportShared.DataSourceLocation, tableSchemas: TableSchema[]) {
    this.type = type;
    this.tableSchemas = tableSchemas;
  }
}

export class TableSchema {
  name: string;

  expanded: boolean;

  fields: TableSchemaField[];

  primaryKeys?: string[];

  foreignKeys?: ImportShared.ForeignKey[];

  constructor(
    name: string,
    expanded: boolean,
    fields: TableSchemaField[],
    primaryKeys?: string[],
    foreignKeys?: ImportShared.ForeignKey[],
  ) {
    this.name = name;
    this.expanded = expanded;
    this.fields = fields;
    this.primaryKeys = primaryKeys;
    this.foreignKeys = foreignKeys;
  }
}

export class PropertyMapping {
  property: model.Property;

  fieldName: string;

  constructor(property: model.Property, fieldName: string) {
    this.property = property;
    this.fieldName = fieldName;
  }
}

export class NodeMapping {
  node: model.NodeObjectType;

  tableSchema: TableSchema;

  propertyMappings: PropertyMapping[];

  mappingFilter?: ImportShared.MappingFilterJsonStruct;

  constructor(
    node: model.NodeObjectType,
    tableSchema: TableSchema,
    propertyMappings: PropertyMapping[],
    mappingFilter?: ImportShared.MappingFilterJsonStruct,
  ) {
    this.node = node;
    this.tableSchema = tableSchema;
    this.propertyMappings = propertyMappings;
    this.mappingFilter = mappingFilter;
  }
}

export class RelationshipMapping {
  relationship: model.RelationshipObjectType;

  tableSchema: TableSchema;

  fromMapping?: { fieldName: string };

  toMapping?: { fieldName: string };

  propertyMappings: PropertyMapping[];

  mappingFilter?: ImportShared.MappingFilterJsonStruct;

  constructor(
    relationship: model.RelationshipObjectType,
    tableSchema: TableSchema,
    fromMapping: { fieldName: string } | undefined,
    toMapping: { fieldName: string } | undefined,
    propertyMappings: PropertyMapping[],
    mappingFilter?: ImportShared.MappingFilterJsonStruct,
  ) {
    this.relationship = relationship;
    this.tableSchema = tableSchema;
    this.fromMapping = fromMapping;
    this.toMapping = toMapping;
    this.propertyMappings = propertyMappings;
    this.mappingFilter = mappingFilter;
  }
}

export class GraphMappingRepresentation {
  dataSourceSchema: DataSourceSchema;

  nodeMappings: NodeMapping[];

  relationshipMappings: RelationshipMapping[];

  constructor(
    dataSourceSchema: DataSourceSchema,
    nodeMappings: NodeMapping[],
    relationshipMappings: RelationshipMapping[],
  ) {
    this.dataSourceSchema = dataSourceSchema;
    this.nodeMappings = nodeMappings;
    this.relationshipMappings = relationshipMappings;
  }
}

export class NodeModel {
  nodeObjectType: model.NodeObjectType;

  nodeMapping?: NodeMapping;

  nodeKeyProperty?: NodeKeyProperty;

  constructor(nodeObjectType: model.NodeObjectType, nodeMapping?: NodeMapping, nodeKeyProperty?: NodeKeyProperty) {
    this.nodeObjectType = nodeObjectType;
    this.nodeMapping = nodeMapping;
    this.nodeKeyProperty = nodeKeyProperty;
  }
}

export class RelationshipModel {
  relationshipObjectType: model.RelationshipObjectType;

  from: NodeModel;

  to: NodeModel;

  relationshipMapping?: RelationshipMapping;

  constructor(
    relationshipObjectType: model.RelationshipObjectType,
    from: NodeModel,
    to: NodeModel,
    relationshipMapping?: RelationshipMapping,
  ) {
    this.relationshipObjectType = relationshipObjectType;
    this.from = from;
    this.to = to;
    this.relationshipMapping = relationshipMapping;
  }
}

export class Configurations {
  idsToIgnore: string[];

  constructor(idsToIgnore: string[]) {
    this.idsToIgnore = idsToIgnore;
  }
}

export class DataModel {
  version: string;

  graphSchemaRepresentation: model.GraphSchema;

  graphSchemaExtensionsRepresentation: GraphSchemaExtensionsRepresentation;

  graphMappingRepresentation: GraphMappingRepresentation;

  nodeModels: NodeModel[];

  relationshipModels: RelationshipModel[];

  configurations: Configurations;

  constructor(
    version: string,
    graphSchemaRepresentation: model.GraphSchema,
    graphSchemaExtensionsRepresentation: GraphSchemaExtensionsRepresentation,
    graphMappingRepresentation: GraphMappingRepresentation,
    nodeModels: NodeModel[],
    relationshipModels: RelationshipModel[],
    configurations: Configurations,
  ) {
    this.version = version;
    this.graphSchemaRepresentation = graphSchemaRepresentation;
    this.graphSchemaExtensionsRepresentation = graphSchemaExtensionsRepresentation;
    this.graphMappingRepresentation = graphMappingRepresentation;
    this.nodeModels = nodeModels;
    this.relationshipModels = relationshipModels;
    this.configurations = configurations;
  }
}
