import { Menu, Select, TextInput } from '@neo4j-ndl/react';
import { useEffect, useReducer, useRef } from 'react';

import { getProtocolOptions, getProtocols } from '../connection-form-utils';
import type { ConnectionFormValues } from '../connection-form.types';
import { useConnectionDiscoveryData } from '../hooks';
import { ConnectionTip } from './connection-tip';

const isInstanceId = (str: string) => /^[0-9a-f]{8}$/i.test(str);

const getAuraInstanceUrl = (instanceId: string): string => {
  const AURA_HOSTNAME = 'databases.neo4j.io';
  return `${instanceId}.${AURA_HOSTNAME}`;
};

export const ConnectionFormUrl = ({
  formValues,
  handleInputChange,
  handleHostPasteChange,
}: {
  formValues: ConnectionFormValues;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } }) => void;
  handleHostPasteChange?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
}) => {
  const { neo4jVersion, fetchAndSetDiscoveryData } = useConnectionDiscoveryData();
  const inputRef = useRef<HTMLInputElement>(null);
  const menuItemRef = useRef<HTMLButtonElement>(null);
  const [isMenuOpen, toggleMenu] = useReducer((s: boolean) => !s, false);

  useEffect(() => {
    fetchAndSetDiscoveryData(`${formValues.protocol}//${formValues.hostname}`);
  }, [formValues.protocol, formValues.hostname, fetchAndSetDiscoveryData]);

  const protocols = getProtocols(formValues.hostname);
  const protocolOptions = getProtocolOptions(protocols, neo4jVersion);

  // Toggle Aura URL suggestion menu for instance IDs
  if (isInstanceId(formValues.hostname) && !isMenuOpen) {
    toggleMenu();
  }

  if (!isInstanceId(formValues.hostname) && isMenuOpen) {
    toggleMenu();
  }

  const handleMenuSelect = () => {
    handleInputChange({ target: { name: 'hostname', value: getAuraInstanceUrl(formValues.hostname) } });
  };

  const focusMenuItem = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowDown' && isMenuOpen) {
      menuItemRef.current?.focus();
    }
  };

  const focusInput = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'ArrowUp') {
      inputRef.current?.focus();
    }
  };

  return (
    <div className="relative flex gap-x-4">
      <ConnectionTip formValues={formValues} />
      <Select
        className="basis-[148px]"
        size="medium"
        type="select"
        selectProps={{
          menuPosition: 'fixed',
          options: protocolOptions,
          value: protocolOptions.find(({ value }) => value === formValues.protocol),
          name: 'protocol',
          onChange: (newProtocol, meta) => {
            if (newProtocol && meta.name !== undefined) {
              handleInputChange({ target: { name: meta.name, value: newProtocol.value } });
            }
          },
        }}
        label="Protocol"
      />
      <TextInput
        ref={inputRef}
        className="grow"
        isFluid
        size="medium"
        value={formValues.hostname}
        onChange={handleInputChange}
        label="Connection URL"
        htmlAttributes={{
          onKeyUp: focusMenuItem,
          onPaste: handleHostPasteChange,
          autoFocus: !formValues.hostname,
          'aria-label': 'Connection URL',
          name: 'hostname',
        }}
      />
      {/* TODO: backdrop disable does not exist. Is it needed */}
      <Menu isOpen={isMenuOpen} anchorRef={inputRef} className="z-modal">
        <Menu.Items
          htmlAttributes={{
            onFocus: (e) => {
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              if ((e.target as unknown as HTMLButtonElement) !== menuItemRef.current) {
                inputRef.current?.focus();
              }
            },
          }}
        >
          <Menu.Item
            ref={menuItemRef}
            title={getAuraInstanceUrl(formValues.hostname)}
            onClick={handleMenuSelect}
            htmlAttributes={{
              onKeyUp: focusInput,
            }}
          />
        </Menu.Items>
      </Menu>
    </div>
  );
};
