import { isNullish } from '@nx/stdlib';

import { GraphSchemaTypes100next9 as model } from '../graph-schema-utils-types-deprecated/graph-schema-utils-types-1.0.0-next9';
import type {
  ConfigurationsJsonStruct,
  DataModelJsonStruct_1_2_0,
  DataSourceSchemaJsonStruct,
  DataSourceType,
  ForeignKeyReferenceJsonStruct,
  GraphMappingRepresentationJsonStruct,
  GraphSchemaExtensionsRepresentationJsonStruct,
  MappingFilterJsonStruct,
  NodeKeyPropertyJsonStruct,
  NodeMappingJsonStruct,
  PropertyMappingJsonStruct,
  RelationshipMappingJsonStruct,
  TableSchemaFieldJsonStruct,
  TableSchemaFieldType,
  TableSchemaJsonStruct,
} from './data-model.json.type';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isDataModelJsonStruct_1_2_0 = (dataModel: any): dataModel is DataModelJsonStruct_1_2_0 => {
  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  // TODO - better validation...
  return (
    !isNullish(dataModel) &&
    !isNullish(dataModel.version) &&
    !isNullish(dataModel.graphSchemaRepresentation) &&
    !isNullish(dataModel.graphSchemaExtensionsRepresentation) &&
    !isNullish(dataModel.graphSchemaExtensionsRepresentation.nodeKeyProperties) &&
    !isNullish(dataModel.graphMappingRepresentation) &&
    !isNullish(dataModel.graphMappingRepresentation.nodeMappings) &&
    !isNullish(dataModel.graphMappingRepresentation.relationshipMappings) &&
    !isNullish(dataModel.graphMappingRepresentation.dataSourceSchema) &&
    !isNullish(dataModel.configurations) &&
    !isNullish(dataModel.configurations.idsToIgnore)
  );
  /* eslint-enable @typescript-eslint/no-unsafe-member-access */
};

export class NodeKeyProperty {
  node: model.NodeObjectType;

  keyProperty: model.Property;

  constructor(node: model.NodeObjectType, keyProperty: model.Property) {
    this.node = node;
    this.keyProperty = keyProperty;
  }

  toJsonStruct(): NodeKeyPropertyJsonStruct {
    return {
      node: this.node.toRef(),
      keyProperty: this.keyProperty.toRef(),
    };
  }
}

export class GraphSchemaExtensionsRepresentation {
  nodeKeyProperties: NodeKeyProperty[];

  constructor(nodeKeyProperties: NodeKeyProperty[]) {
    this.nodeKeyProperties = nodeKeyProperties;
  }

  toJsonStruct(): GraphSchemaExtensionsRepresentationJsonStruct {
    return {
      nodeKeyProperties: this.nodeKeyProperties.map((nodeKeyProperty) => nodeKeyProperty.toJsonStruct()),
    };
  }
}

export class ForeignKeyReference {
  tableSchema: TableSchema;

  fieldName: string;

  constructor(tableSchema: TableSchema, fieldName: string) {
    this.tableSchema = tableSchema;
    this.fieldName = fieldName;
  }

  toJsonStruct(): ForeignKeyReferenceJsonStruct {
    return {
      tableSchema: this.tableSchema.toRef(),
      fieldName: this.fieldName,
    };
  }
}

export class TableSchemaField {
  name: string;

  type: TableSchemaFieldType;

  sample: string;

  isUsed: boolean;

  isPrimary?: boolean;

  foreignKeyReference?: ForeignKeyReference;

  constructor(
    name: string,
    type: TableSchemaFieldType,
    sample: string,
    isUsed: boolean,
    isPrimary?: boolean,
    foreignKeyReference?: ForeignKeyReference,
  ) {
    this.name = name;
    this.type = type;
    this.sample = sample;
    this.isUsed = isUsed;
    if (!isNullish(isPrimary)) {
      this.isPrimary = isPrimary;
    }
    if (!isNullish(foreignKeyReference)) {
      this.foreignKeyReference = foreignKeyReference;
    }
  }

  toJsonStruct(): TableSchemaFieldJsonStruct {
    const jsonTableSchema: TableSchemaFieldJsonStruct = {
      name: this.name,
      type: this.type,
      sample: this.sample,
    };
    if (!isNullish(this.isPrimary)) {
      jsonTableSchema.isPrimary = this.isPrimary;
    }
    if (!isNullish(this.foreignKeyReference)) {
      jsonTableSchema.foreignKeyReference = this.foreignKeyReference.toJsonStruct();
    }
    return jsonTableSchema;
  }
}

export class DataSourceSchema {
  type: DataSourceType;

  database?: string;

  tableSchemas: TableSchema[];

  constructor(type: DataSourceType, tableSchemas: TableSchema[], database?: string) {
    this.type = type;
    this.tableSchemas = tableSchemas;
    this.database = database;
  }

  toJsonStruct(): DataSourceSchemaJsonStruct {
    return {
      type: this.type,
      tableSchemas: this.tableSchemas.map((tableSchema) => tableSchema.toJsonStruct()),
      database: this.database,
    };
  }
}

export class TableSchema {
  $id: string;

  name: string;

  expanded: boolean;

  fields: TableSchemaField[];

  constructor($id: string, name: string, expanded: boolean, fields: TableSchemaField[]) {
    this.$id = $id;
    this.name = name;
    this.expanded = expanded;
    this.fields = fields;
  }

  toJsonStruct(): TableSchemaJsonStruct {
    return {
      $id: this.$id,
      name: this.name,
      expanded: this.expanded,
      fields: this.fields.map((field) => field.toJsonStruct()),
    };
  }

  toRef() {
    return {
      $ref: `#${this.$id}`,
    };
  }
}

export class PropertyMapping {
  property: model.Property;

  fieldName: string;

  constructor(property: model.Property, fieldName: string) {
    this.property = property;
    this.fieldName = fieldName;
  }

  toJsonStruct(): PropertyMappingJsonStruct {
    return {
      property: this.property.toRef(),
      fieldName: this.fieldName,
    };
  }
}

export class NodeMapping {
  node: model.NodeObjectType;

  tableSchema: TableSchema;

  propertyMappings: PropertyMapping[];

  mappingFilter?: MappingFilterJsonStruct;

  constructor(
    node: model.NodeObjectType,
    tableSchema: TableSchema,
    propertyMappings: PropertyMapping[],
    mappingFilter?: MappingFilterJsonStruct,
  ) {
    this.node = node;
    this.tableSchema = tableSchema;
    this.propertyMappings = propertyMappings;
    this.mappingFilter = mappingFilter;
  }

  toJsonStruct(): NodeMappingJsonStruct {
    return {
      node: this.node.toRef(),
      tableSchema: this.tableSchema.toRef(),
      propertyMappings: this.propertyMappings.map((mapping) => mapping.toJsonStruct()),
      mappingFilter: this.mappingFilter,
    };
  }
}

export class RelationshipMapping {
  relationship: model.RelationshipObjectType;

  tableSchema: TableSchema;

  fromMapping?: { fieldName: string };

  toMapping?: { fieldName: string };

  propertyMappings: PropertyMapping[];

  mappingFilter?: MappingFilterJsonStruct;

  constructor(
    relationship: model.RelationshipObjectType,
    tableSchema: TableSchema,
    fromMapping: { fieldName: string } | undefined,
    toMapping: { fieldName: string } | undefined,
    propertyMappings: PropertyMapping[],
    mappingFilter?: MappingFilterJsonStruct,
  ) {
    this.relationship = relationship;
    this.tableSchema = tableSchema;
    this.fromMapping = fromMapping;
    this.toMapping = toMapping;
    this.propertyMappings = propertyMappings;
    this.mappingFilter = mappingFilter;
  }

  toJsonStruct(): RelationshipMappingJsonStruct {
    return {
      relationship: this.relationship.toRef(),
      tableSchema: this.tableSchema.toRef(),
      fromMapping: !isNullish(this.fromMapping) ? { fieldName: this.fromMapping.fieldName } : undefined,
      toMapping: !isNullish(this.toMapping) ? { fieldName: this.toMapping.fieldName } : undefined,
      propertyMappings: this.propertyMappings.map((mapping) => mapping.toJsonStruct()),
      mappingFilter: this.mappingFilter,
    };
  }
}

export class GraphMappingRepresentation {
  dataSourceSchema: DataSourceSchema;

  nodeMappings: NodeMapping[];

  relationshipMappings: RelationshipMapping[];

  constructor(
    dataSourceSchema: DataSourceSchema,
    nodeMappings: NodeMapping[],
    relationshipMappings: RelationshipMapping[],
  ) {
    this.dataSourceSchema = dataSourceSchema;
    this.nodeMappings = nodeMappings;
    this.relationshipMappings = relationshipMappings;
  }

  toJsonStruct(): GraphMappingRepresentationJsonStruct {
    return {
      dataSourceSchema: this.dataSourceSchema.toJsonStruct(),
      nodeMappings: this.nodeMappings.map((nodeMapping) => nodeMapping.toJsonStruct()),
      relationshipMappings: this.relationshipMappings.map((relationshipMapping) => relationshipMapping.toJsonStruct()),
    };
  }
}

export class NodeModel {
  nodeObjectType: model.NodeObjectType;

  nodeMapping?: NodeMapping;

  nodeKeyProperty?: NodeKeyProperty;

  constructor(nodeObjectType: model.NodeObjectType, nodeMapping?: NodeMapping, nodeKeyProperty?: NodeKeyProperty) {
    this.nodeObjectType = nodeObjectType;
    this.nodeMapping = nodeMapping;
    this.nodeKeyProperty = nodeKeyProperty;
  }
}

export class RelationshipModel {
  relationshipObjectType: model.RelationshipObjectType;

  from: NodeModel;

  to: NodeModel;

  relationshipMapping?: RelationshipMapping;

  constructor(
    relationshipObjectType: model.RelationshipObjectType,
    from: NodeModel,
    to: NodeModel,
    relationshipMapping?: RelationshipMapping,
  ) {
    this.relationshipObjectType = relationshipObjectType;
    this.from = from;
    this.to = to;
    this.relationshipMapping = relationshipMapping;
  }
}

export class DataModel {
  version: string;

  graphSchemaRepresentation: model.GraphSchemaRepresentation;

  graphSchemaExtensionsRepresentation: GraphSchemaExtensionsRepresentation;

  graphMappingRepresentation: GraphMappingRepresentation;

  nodeModels: NodeModel[];

  relationshipModels: RelationshipModel[];

  configurations: ConfigurationsJsonStruct;

  constructor(
    version: string,
    graphSchemaRepresentation: model.GraphSchemaRepresentation,
    graphSchemaExtensionsRepresentation: GraphSchemaExtensionsRepresentation,
    graphMappingRepresentation: GraphMappingRepresentation,
    nodeModels: NodeModel[],
    relationshipModels: RelationshipModel[],
    configurations: ConfigurationsJsonStruct,
  ) {
    this.version = version;
    this.graphSchemaRepresentation = graphSchemaRepresentation;
    this.graphSchemaExtensionsRepresentation = graphSchemaExtensionsRepresentation;
    this.graphMappingRepresentation = graphMappingRepresentation;
    this.nodeModels = nodeModels;
    this.relationshipModels = relationshipModels;
    this.configurations = configurations;
  }

  toJson() {
    return JSON.stringify(this.toJsonStruct());
  }

  toJsonStruct() {
    return {
      version: this.version,
      graphSchemaRepresentation: this.graphSchemaRepresentation.toJsonStruct().graphSchemaRepresentation,
      graphSchemaExtensionsRepresentation: this.graphSchemaExtensionsRepresentation.toJsonStruct(),
      graphMappingRepresentation: this.graphMappingRepresentation.toJsonStruct(),
      configurations: this.configurations,
    };
  }

  static parseJson(jsonString: string) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const json = JSON.parse(jsonString) as DataModelJsonStruct_1_2_0;
    return this.parseJsonStruct(json);
  }

  static parseJsonStruct(json: DataModelJsonStruct_1_2_0) {
    const graphSchemaRepresentation = model.GraphSchemaRepresentation.parseJsonStruct(json);

    const nodeModels = graphSchemaRepresentation.graphSchema.nodeObjectTypes.map(
      (nodeObjectType) => new NodeModel(nodeObjectType),
    );

    const nodeKeyProperties = json.graphSchemaExtensionsRepresentation.nodeKeyProperties.reduce(
      (accumulator: NodeKeyProperty[], nodeKeyPropertyJsonStruct) => {
        const nodeId = nodeKeyPropertyJsonStruct.node.$ref.slice(1);
        const nodeModel = nodeModels.find((node) => node.nodeObjectType.$id === nodeId);
        const nodeObjectType = graphSchemaRepresentation.graphSchema.nodeObjectTypes.find(
          (node) => node.$id === nodeId,
        );
        if (isNullish(nodeModel) || isNullish(nodeObjectType)) {
          throw new Error('Not all node references in node ids are defined');
        }
        const property = nodeObjectType.properties.find(
          (p) => p.$id === nodeKeyPropertyJsonStruct.keyProperty.$ref.slice(1),
        );
        if (isNullish(property)) {
          throw new Error('Not all property references in node ids are defined');
        }
        const nodeKeyProperty = new NodeKeyProperty(nodeObjectType, property);
        nodeModel.nodeKeyProperty = nodeKeyProperty;
        return [...accumulator, nodeKeyProperty];
      },
      [],
    );
    const graphSchemaExtensionsRepresentation = new GraphSchemaExtensionsRepresentation(nodeKeyProperties);

    const isTableSchemaFieldUsedByMapping =
      (tableSchemaId: string, fieldName: string) =>
      (mapping: NodeMappingJsonStruct | RelationshipMappingJsonStruct) => {
        const isFieldUsedByPropertyMapping = (propertyMapping: PropertyMappingJsonStruct) =>
          propertyMapping.fieldName === fieldName;
        const isRelationshipMapping = 'fromMapping' in mapping;
        const isFieldUsedByRelationshipFromOrTo = (m: RelationshipMappingJsonStruct) =>
          m.fromMapping?.fieldName === fieldName || m.toMapping?.fieldName === fieldName;
        return (
          mapping.tableSchema.$ref === `#${tableSchemaId}` &&
          (mapping.propertyMappings.some(isFieldUsedByPropertyMapping) ||
            (isRelationshipMapping && isFieldUsedByRelationshipFromOrTo(mapping)))
        );
      };
    const tableSchemas = json.graphMappingRepresentation.dataSourceSchema.tableSchemas.map((tableSchema) => {
      return new TableSchema(
        tableSchema.$id,
        tableSchema.name,
        tableSchema.expanded,
        tableSchema.fields.map((field) => {
          const isUsed =
            json.graphMappingRepresentation.nodeMappings.some(
              isTableSchemaFieldUsedByMapping(tableSchema.$id, field.name),
            ) ||
            json.graphMappingRepresentation.relationshipMappings.some(
              isTableSchemaFieldUsedByMapping(tableSchema.$id, field.name),
            );
          return new TableSchemaField(field.name, field.type, field.sample, isUsed);
        }),
      );
    });
    const dataSourceSchema = new DataSourceSchema(
      json.graphMappingRepresentation.dataSourceSchema.type,
      tableSchemas,
      json.graphMappingRepresentation.dataSourceSchema.database,
    );
    const nodeMappings = json.graphMappingRepresentation.nodeMappings.reduce(
      (nodeMappingsAccumulator: NodeMapping[], nodeMappingJsonStruct) => {
        const nodeId = nodeMappingJsonStruct.node.$ref.slice(1);
        const nodeModel = nodeModels.find((node) => node.nodeObjectType.$id === nodeId);
        const nodeObjectType = graphSchemaRepresentation.graphSchema.nodeObjectTypes.find(
          (node) => node.$id === nodeMappingJsonStruct.node.$ref.slice(1),
        );
        if (isNullish(nodeModel) || isNullish(nodeObjectType)) {
          throw new Error('Not all node references in node mappings are defined');
        }
        const tableSchema = tableSchemas.find((table) => table.$id === nodeMappingJsonStruct.tableSchema.$ref.slice(1));
        if (isNullish(tableSchema)) {
          throw new Error('Not all table schema references in node mappings are defined');
        }
        const propertyMappings = nodeMappingJsonStruct.propertyMappings.reduce(
          (propertyMappingsAccumulator: PropertyMapping[], propertyMappingJsonStruct) => {
            const property = nodeObjectType.properties.find(
              (p) => p.$id === propertyMappingJsonStruct.property.$ref.slice(1),
            );
            if (isNullish(property)) {
              throw new Error('Not all property references in node mappings are defined');
            }
            if (isNullish(tableSchema.fields.find((field) => field.name === propertyMappingJsonStruct.fieldName))) {
              throw new Error('Not all field name references in node mappings are defined');
            }
            return [...propertyMappingsAccumulator, new PropertyMapping(property, propertyMappingJsonStruct.fieldName)];
          },
          [],
        );
        const nodeMapping = new NodeMapping(
          nodeObjectType,
          tableSchema,
          propertyMappings,
          nodeMappingJsonStruct.mappingFilter,
        );
        nodeModel.nodeMapping = nodeMapping;
        return [...nodeMappingsAccumulator, nodeMapping];
      },
      [],
    );

    const relationshipModels: RelationshipModel[] = graphSchemaRepresentation.graphSchema.relationshipObjectTypes.map(
      (relationshipObjectType) => {
        const fromNodeModel = nodeModels.find((n) => n.nodeObjectType.$id === relationshipObjectType.from.$id);
        const toNodeModel = nodeModels.find((n) => n.nodeObjectType.$id === relationshipObjectType.to.$id);
        if (isNullish(fromNodeModel) || isNullish(toNodeModel)) {
          throw new Error('Not all node references in relationship are defined');
        }
        return {
          relationshipObjectType,
          from: new NodeModel(fromNodeModel.nodeObjectType, fromNodeModel.nodeMapping, fromNodeModel.nodeKeyProperty),
          to: new NodeModel(toNodeModel.nodeObjectType, toNodeModel.nodeMapping, toNodeModel.nodeKeyProperty),
        };
      },
    );
    const relationshipMappings = json.graphMappingRepresentation.relationshipMappings.reduce(
      (relationshipMappingsAccumulator: RelationshipMapping[], relationshipMappingJsonStruct) => {
        const relationshipId = relationshipMappingJsonStruct.relationship.$ref.slice(1);
        const relationshipModel = relationshipModels.find(
          (relationship) => relationship.relationshipObjectType.$id === relationshipId,
        );
        const relationshipObjectType = graphSchemaRepresentation.graphSchema.relationshipObjectTypes.find(
          (relationship) => relationship.$id === relationshipId,
        );
        if (isNullish(relationshipModel) || isNullish(relationshipObjectType)) {
          throw new Error('Not all relationship references in relationship mappings are defined');
        }
        const tableSchema = tableSchemas.find(
          (fSchema) => fSchema.$id === relationshipMappingJsonStruct.tableSchema.$ref.slice(1),
        );
        if (isNullish(tableSchema)) {
          throw new Error('Not all table schema references in relationship mappings are defined');
        }
        const propertyMappings = relationshipMappingJsonStruct.propertyMappings.reduce(
          (propertyMappingsAccumulator: PropertyMapping[], propertyMappingJsonStruct) => {
            const property = relationshipObjectType.properties.find(
              (p) => p.$id === propertyMappingJsonStruct.property.$ref.slice(1),
            );
            if (isNullish(property)) {
              throw new Error('Not all property references in relationship mappings are defined');
            }
            if (isNullish(tableSchema.fields.find((field) => field.name === propertyMappingJsonStruct.fieldName))) {
              throw new Error('Not all field name references in relationship mappings are defined');
            }
            return [...propertyMappingsAccumulator, new PropertyMapping(property, propertyMappingJsonStruct.fieldName)];
          },
          [],
        );
        const relationshipMapping = new RelationshipMapping(
          relationshipObjectType,
          tableSchema,
          relationshipMappingJsonStruct.fromMapping,
          relationshipMappingJsonStruct.toMapping,
          propertyMappings,
          relationshipMappingJsonStruct.mappingFilter,
        );
        relationshipModel.relationshipMapping = relationshipMapping;
        return [...relationshipMappingsAccumulator, relationshipMapping];
      },
      [],
    );
    const graphMappingRepresentation = new GraphMappingRepresentation(
      dataSourceSchema,
      nodeMappings,
      relationshipMappings,
    );

    return new DataModel(
      json.version,
      graphSchemaRepresentation,
      graphSchemaExtensionsRepresentation,
      graphMappingRepresentation,
      nodeModels,
      relationshipModels,
      json.configurations,
    );
  }
}
