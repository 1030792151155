import { filter, intersection } from 'lodash-es';

import { getEscapedSingleQuoteString } from '../../state/graph/cypherUtils';
import { DEFAULT_UNCATEGORISED_ID } from '../../state/perspectives/constants';
import type { Perspective } from '../../types/perspective';
import { isEmptyArray, isTruthy } from '../../types/utility';
import type { GetSchemaCypher, Schema } from './types';

// This is loosely based on http://json-schema.org/
export const getSchemaFromPerspective = (perspective: Perspective): Schema => {
  const schema: Partial<Schema> = {};

  filter(perspective.categories, (c) => c.id !== DEFAULT_UNCATEGORISED_ID).forEach((c) => {
    schema[c.name] = {
      $id: c.id,
      $labels: c.labels.slice(),
      $excludedProperties: c.properties
        .filter((p) => p.exclude)
        .map((p) => p.name)
        .filter(isTruthy),
    };
  });

  schema.$order = filter(perspective.categories, (c) => c.id !== DEFAULT_UNCATEGORISED_ID).map((c) => c.id);

  return schema as Schema;
};

const reduceLabels = (acc: string, label: string, i: number) =>
  `${acc}${i > 0 ? ', ' : ''}'${getEscapedSingleQuoteString(label)}'`;
const reduceProperties = (acc: string, prop: string, i: number) =>
  `${acc}${i > 0 ? ', ' : ''}'${getEscapedSingleQuoteString(prop)}'`;
const reduceCategories = (acc: string, category: string, i: number) => acc + (i > 0 ? '\n    ' : '') + category;

export const getSchemaCypher = ({
  schema,
  variable = 'n',
  path = null,
  labels = null,
  isV5OrGreater = false,
}: GetSchemaCypher) => {
  const categoryFilters = schema.$order
    .map((id: number) => {
      const category = Object.values(schema).find(
        (c) => c.$id === id && (labels !== null ? intersection(labels, c.$labels).length : true),
      );
      if (isTruthy(category) && !isEmptyArray(category.$excludedProperties)) {
        const excludedProps = `[${category.$excludedProperties.reduce(reduceProperties, '')}]`;
        const reducedLabels = category.$labels.reduce(reduceLabels, '');

        return `WHEN any(l in labels(${variable}) WHERE l IN [${reducedLabels}])
            THEN {
              identity: id(${variable}),${isV5OrGreater ? ` elementId: elementId(${variable}),` : ''}
              propKeys: [key IN keys(properties(${variable})) WHERE none(excl IN ${excludedProps} WHERE excl = key)],
              propValues: [key2 IN [key IN keys(properties(${variable})) WHERE none(excl IN ${excludedProps} WHERE excl = key)] | properties(${variable})[key2]],
              labels: labels(${variable})
            }`;
      }
      return false;
    })
    .filter(isTruthy);

  if (categoryFilters.length === 0) {
    return `${variable}`;
  }

  return `CASE
    ${path !== null ? `WHEN ${variable} IN relationships(${path}) THEN ${variable}` : ''}
    ${categoryFilters.reduce(reduceCategories, '')}
    ELSE ${variable}
    END`;
};
