import { IconButton, Menu, Tooltip } from '@neo4j-ndl/react';
import { EllipsisHorizontalIconOutline } from '@neo4j-ndl/react/icons';
import { AURA_CONSOLE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { needsMigration } from '@nx/ops';
import type { Instance } from '@nx/state';
import { ACTION, INSTANCE_PRIORITY, MODAL_TYPE, TIER, useActiveProject, useModal, usePermissions } from '@nx/state';
import { PermissionTooltip, WithStatusIndicator } from '@nx/ui';
import { useRef, useState } from 'react';

import { useTrackUpxEvent } from '../services/segment/analytics';
import { hasActiveProTrial, isCDCEnabled, isConfigurable, isSecondariesEnabled } from './entities/helpers';
import { OpenWithMigrationAssistant } from './migration-assistant/open-with-migration-assistant';

type InstanceActionMenuProps = {
  instance: Instance;
  onOpenDetails: () => void;
  type?: 'table' | 'list';
};

const updatePrioDisabledReason = (instance: Instance, hasPermission: boolean): string | undefined => {
  if (!hasPermission) {
    return "You don't have permission to perform this action.";
  }
  if (!instance.availableActions.update_priority.enabled) {
    return instance.availableActions.update_priority.message;
  }
  if (instance.isBeingCloned) {
    return 'This action cannot be used while the instance is being cloned.';
  }

  return undefined;
};

export const InstanceActionMenu = ({ instance, onOpenDetails, type = 'list' }: InstanceActionMenuProps) => {
  const trackEvent = useTrackUpxEvent();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const activeProject = useActiveProject();
  const isProTrial = hasActiveProTrial(instance);
  const { isAllowed } = usePermissions();
  const allowUpdate = isAllowed(ACTION.UPDATE, `namespaces/${activeProject.id}/databases/${instance.id}`);
  const allowUpdatePriority = isAllowed(
    ACTION.UPDATE,
    `namespaces/${activeProject.id}/databases/${instance.id}/priority`,
  );
  const allowDelete = isAllowed(ACTION.DELETE, `namespaces/${activeProject.id}/databases/${instance.id}`);
  const allowClear = isAllowed(ACTION.OVERWRITE, `namespaces/${activeProject.id}/databases/${instance.id}`);
  const allowClone = isAllowed(ACTION.CREATE, `namespaces/${activeProject.id}/databases`);
  const allowUpdateEndpoint = isAllowed(ACTION.UPDATE, `namespaces/${activeProject.id}/custom-endpoints/*`);

  const showMrr = type === 'table' && needsMigration(instance.desiredSettings.version);

  const availablePriorities = activeProject.tierConfigs[instance.tier]?.priorities ?? [];

  const actionItems = {
    clone: !isProTrial,
    upgrade:
      (type === 'table' && instance.tier === TIER.FREE) ||
      (activeProject.capabilities.multi_tenant_enterprise && instance.tier === TIER.PROFESSIONAL),
    secondaries: isSecondariesEnabled(instance),
    cdc: isCDCEnabled(instance),
    markAsProduction:
      instance.priority !== INSTANCE_PRIORITY.HIGHEST && availablePriorities.includes(INSTANCE_PRIORITY.HIGHEST),
    unmarkAsProduction:
      instance.priority === INSTANCE_PRIORITY.HIGHEST && availablePriorities.includes(INSTANCE_PRIORITY.HIGH),
    resetToBlank: [TIER.FREE, TIER.PROFESSIONAL].includes(instance.tier),
    configure: type === 'table' && instance.tier !== TIER.FREE && !isProTrial,
    migrationAssistant: showMrr,
    customEndpoint: activeProject.capabilities.custom_endpoints && instance.capabilities.assign_custom_endpoint.enabled,
  };

  const cloneToNewModal = useModal(MODAL_TYPE.CLONE_TO_NEW);
  const cloneToExistingModal = useModal(MODAL_TYPE.CLONE_TO_EXISTING);
  const upgradeModal = useModal(MODAL_TYPE.UPGRADE);
  const secondariesModal = useModal(MODAL_TYPE.SECONDARIES);
  const cdcModal = useModal(MODAL_TYPE.CDC);
  const updatePriorityModal = useModal(MODAL_TYPE.UPDATE_INSTANCE_PRIORITY);
  const manageCustomEndpointModal = useModal(MODAL_TYPE.MANAGE_INSTANCE_ENDPOINT);
  const clearModal = useModal(MODAL_TYPE.CLEAR);
  const configureModal = useModal(MODAL_TYPE.CONFIGURE);
  const destroyModal = useModal(MODAL_TYPE.DESTROY);

  const disableUpdatePriorityReason = updatePrioDisabledReason(instance, allowUpdatePriority);
  const canUpdatePriority = disableUpdatePriorityReason === undefined;

  return (
    <>
      <IconButton
        ref={menuRef}
        ariaLabel="More instance actions"
        onClick={() => setIsMenuOpen(true)}
        {...(type === 'table' && { isClean: true, size: 'small' })}
      >
        <WithStatusIndicator
          active={showMrr}
          indicatorProps={{ type: 'success', color: 'warning', style: { top: '-4px', right: '-8px' } }}
        >
          <EllipsisHorizontalIconOutline className="h-full w-full" />
        </WithStatusIndicator>
      </IconButton>
      <Menu isOpen={isMenuOpen} anchorRef={menuRef} onClose={() => setIsMenuOpen(false)}>
        <Menu.Items>
          <Menu.Item
            title="Inspect"
            onClick={() => {
              onOpenDetails();
            }}
          />
          {actionItems.clone && (
            <Menu title="Clone to" isDisabled={!instance.availableActions.clone.enabled}>
              <Menu.Items>
                <Menu.Item
                  title={<PermissionTooltip hasPermission={allowClone}>New instance</PermissionTooltip>}
                  onClick={() => {
                    cloneToNewModal.open({ instance, project: activeProject });
                  }}
                  isDisabled={!instance.availableActions.clone.enabled || !allowClone}
                />
                <Menu.Item
                  title={<PermissionTooltip hasPermission={allowClone}>Existing instance</PermissionTooltip>}
                  onClick={() => {
                    cloneToExistingModal.open({ instance });
                  }}
                  isDisabled={!instance.availableActions.clone.enabled || !allowClone}
                  htmlAttributes={{
                    tabIndex: 0,
                  }}
                />
              </Menu.Items>
            </Menu>
          )}
          {actionItems.upgrade && (
            <Menu.Item
              title={<PermissionTooltip hasPermission={allowClone}>Upgrade</PermissionTooltip>}
              onClick={() => {
                upgradeModal.open({
                  instance,
                  project: activeProject,
                });
              }}
              isDisabled={!instance.availableActions.clone.enabled || !allowClone}
            ></Menu.Item>
          )}
          {actionItems.secondaries && (
            <Menu.Item
              title={<PermissionTooltip hasPermission={allowUpdate}>Edit Secondary Count</PermissionTooltip>}
              isDisabled={!instance.availableActions.secondaries.enabled || !allowUpdate}
              onClick={() => {
                secondariesModal.open({ instance });
              }}
            />
          )}
          {actionItems.cdc && (
            <Menu.Item
              title={<PermissionTooltip hasPermission={allowUpdate}>Edit CDC mode</PermissionTooltip>}
              isDisabled={!instance.availableActions.cdc_enrichment_mode.enabled || !allowUpdate}
              onClick={() => {
                cdcModal.open({ instance });
              }}
            />
          )}
          {actionItems.resetToBlank && (
            <Menu.Item
              title={<PermissionTooltip hasPermission={allowClear}>Reset to blank</PermissionTooltip>}
              isDisabled={instance.isBeingCloned || !allowClear || !instance.availableActions.overwrite.enabled}
              onClick={() => {
                clearModal.open({ instance });
              }}
            />
          )}
          {actionItems.configure && (
            <Menu.Item
              title={<PermissionTooltip hasPermission={allowUpdate}>Configure</PermissionTooltip>}
              isDisabled={!isConfigurable(instance) || !allowUpdate}
              onClick={() => {
                trackEvent({
                  event: AURA_CONSOLE_EVENTS.INSTANCE_CONFIGURE_OPEN_MODAL,
                  scope: APP_SCOPE.aura,
                });
                configureModal.open({
                  instance,
                  project: activeProject,
                });
              }}
              htmlAttributes={{
                'aria-label': 'Configure instance',
              }}
            />
          )}
          {actionItems.migrationAssistant && (
            <>
              <Menu.Divider />
              <WithStatusIndicator
                active
                className="ndl-menu-item"
                indicatorProps={{
                  type: 'success',
                  color: 'warning',
                  style: { top: '4px', right: '4px', zIndex: 3 },
                }}
              >
                <OpenWithMigrationAssistant instance={instance}>
                  {({ onClick }) => <Menu.Item title="Migration readiness report" onClick={onClick} />}
                </OpenWithMigrationAssistant>
              </WithStatusIndicator>
            </>
          )}
          {actionItems.customEndpoint && (
            <>
              <Menu.Divider />
              <Menu.Item
                title={
                  <Tooltip
                    isDisabled={
                      allowUpdateEndpoint &&
                      !(
                        !instance.availableActions.link_custom_endpoint.enabled &&
                        instance.availableActions.link_custom_endpoint.reason === 'invalid-action-has-custom-endpoint'
                      )
                    }
                    type="simple"
                    isPortaled={false}
                  >
                    <Tooltip.Trigger hasButtonWrapper>Manage custom endpoint</Tooltip.Trigger>
                    <Tooltip.Content style={{ width: 250, position: 'fixed' }}>
                      {allowUpdateEndpoint ? (
                        <span>
                          Instance <b>{instance.name}</b> is already assigned to a custom endpoint.
                        </span>
                      ) : (
                        "You don't have permission to perform this action."
                      )}
                    </Tooltip.Content>
                  </Tooltip>
                }
                description="Used for database management and migration"
                isDisabled={!allowUpdateEndpoint || !instance.availableActions.link_custom_endpoint.enabled}
                onClick={() => {
                  manageCustomEndpointModal.open({ instance, project: activeProject });
                }}
              />
            </>
          )}
          {actionItems.markAsProduction && (
            <>
              <Menu.Divider />
              <Menu.Item
                title={
                  <PermissionTooltip hasPermission={canUpdatePriority} message={disableUpdatePriorityReason}>
                    Mark as Production
                  </PermissionTooltip>
                }
                isDisabled={!canUpdatePriority}
                onClick={() => {
                  updatePriorityModal.open({ instance, priority: INSTANCE_PRIORITY.HIGHEST });
                }}
              />
            </>
          )}
          {actionItems.unmarkAsProduction && (
            <>
              <Menu.Divider />
              <Menu.Item
                title={
                  <PermissionTooltip hasPermission={canUpdatePriority} message={disableUpdatePriorityReason}>
                    Unmark as Production
                  </PermissionTooltip>
                }
                isDisabled={!canUpdatePriority}
                onClick={() => {
                  updatePriorityModal.open({ instance, priority: INSTANCE_PRIORITY.HIGH });
                }}
              />
            </>
          )}
          <Menu.Divider />
          <Menu.Item
            title={<PermissionTooltip hasPermission={allowDelete}>Delete</PermissionTooltip>}
            className="text-danger-text"
            isDisabled={!instance.availableActions.delete.enabled || !allowDelete || instance.isBeingCloned}
            onClick={() => {
              destroyModal.open({ instance });
            }}
          />
        </Menu.Items>
      </Menu>
    </>
  );
};
