import { CUSTOM_ENDPOINT_STATUS, type CustomEndpoint } from '@nx/state';

export const constructCustomEndpointUrl = (endpoint: CustomEndpoint) =>
  `neo4j+s://${endpoint.name}-${endpoint.suffix}.endpoints.neo4j.io`;

const STATUS_TO_FRIENDLY = {
  [CUSTOM_ENDPOINT_STATUS.DISCONNECTED]: 'DISCONNECTED',
  [CUSTOM_ENDPOINT_STATUS.ACTIVE]: 'ACTIVE',
  [CUSTOM_ENDPOINT_STATUS.ERROR]: 'ERROR',
  [CUSTOM_ENDPOINT_STATUS.SUBMITTED]: 'LOADING',
  // Fail-safe, unlikely to be displayed
  [CUSTOM_ENDPOINT_STATUS.UNKNOWN]: 'UNKNOWN',
  // Also unlikely to be displayed
  [CUSTOM_ENDPOINT_STATUS.DELETED]: 'DELETED',
};

export const friendlyCustomEndpointStatus = (status: CUSTOM_ENDPOINT_STATUS) => STATUS_TO_FRIENDLY[status];
