import type { DataApiAuthProvider, GetDataApiAuthProvidersBatchResponse } from '@nx/state';

export enum MODIFY_MODE {
  create = 'create',
  edit = 'edit',
  closed = 'closed',
}

export interface CredentialsModalData {
  id: string;
  name: string;
  url: string;
  authProviders: DataApiAuthProvider[];
}

export type OriginalDataApiModify = {
  authProviders: GetDataApiAuthProvidersBatchResponse;
  id: string;
  name: string;
  instanceId: string;
  instanceUsername: string;
  instancePassword: string;
  typeDefinitions: string;
  featuresSubgraph?: boolean;
  allowedOrigins: string[];
};
