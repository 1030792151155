import type { BasicNode, BasicRelationship } from '@nx/neo4j-sdk';
import type { CaptionOption } from '@nx/state';

type GraphStatsLabels = Record<
  string,
  {
    count: number;
    properties: Record<string, string>;
  }
>;

type GraphStatsRelationshipTypes = Record<
  string,
  {
    count: number;
    properties: Record<string, string>;
  }
>;

export function getLabelStats(nodes: BasicNode[]): GraphStatsLabels {
  const labelStats: GraphStatsLabels = {};
  labelStats['*'] = { count: nodes.length, properties: {} };

  nodes.forEach((node) => {
    node.labels.forEach((label) => {
      if (label !== '*') {
        if (!labelStats[label]) {
          labelStats[label] = { count: 0, properties: {} };
        }

        labelStats[label]!.count += 1;
        labelStats[label]!.properties = {
          ...labelStats[label]!.properties,
          ...node.propertyTypes,
        };
      }
    });
  });

  return labelStats;
}

export function getRelTypeStats(relationships: BasicRelationship[]): GraphStatsRelationshipTypes {
  const relTypeStats: GraphStatsRelationshipTypes = {};

  relationships.forEach((rel) => {
    if (relTypeStats['*']) {
      relTypeStats['*'].count += 1;
    } else {
      relTypeStats['*'] = {
        count: 1,
        properties: {},
      };
    }
    const currentRelTypeStats = relTypeStats[rel.type];
    if (currentRelTypeStats) {
      currentRelTypeStats.count += 1;
      currentRelTypeStats.properties = {
        ...currentRelTypeStats.properties,
        ...rel.propertyTypes,
      };
    } else {
      relTypeStats[rel.type] = {
        count: 1,
        properties: rel.propertyTypes,
      };
    }
  });

  return relTypeStats;
}

export const getAllAvailableNodeCaptions = (properties: Record<string, string>): CaptionOption[] => {
  const captions: CaptionOption[] = Object.keys(properties).map((property) => ({
    type: 'property',
    captionKey: property,
  }));

  return [...captions, { type: 'id' }, { type: 'type' }];
};

export const getAllAvailableRelationshipCaptions = (properties: Record<string, string>): CaptionOption[] => {
  const captions: CaptionOption[] = Object.keys(properties).map((property) => ({
    type: 'property',
    captionKey: property,
  }));

  return [...captions, { type: 'id' }, { type: 'type' }];
};
