import {
  type RootState as FrameworkRootState,
  createDynamicBaseQuery,
  getSharedStorageUrl,
  makeSelectFeatureFlag,
  prepareHeaders,
  selectCapability,
  LEGACY_store as store,
  useCapability,
  useFeatureFlag,
} from '@nx/state';

import { log } from '../../services/logging';
import { customCreateApi } from '../context';
import { perspectiveEndpoints } from './endpoints/perspectives';
import { sceneEndpoints } from './endpoints/scenes';
import { type Builder, tagTypes } from './types';

export const NAME = 'shared-storage-api';

/**
 * Utility function to check if we can use Shared Storage outside of UI,
 * such as in redux.
 */
export const isSharedStorageAvailable = (): boolean => {
  const isCapabilityEnabled = selectCapability(store.getState(), { key: 'framework:storage-api' });
  const isFeatureFlagEnabled = makeSelectFeatureFlag('explore:shared-storage')(store.getState());

  return isCapabilityEnabled && isFeatureFlagEnabled;
};

/**
 * React hook to check if we can use Shared Storage
 */
export const useHasSharedStorage = (): boolean => {
  const [isCapabilityEnabled] = useCapability('framework:storage-api');
  const [isFeatureFlagEnabled] = useFeatureFlag('explore:shared-storage');
  return isCapabilityEnabled && isFeatureFlagEnabled;
};

export const sharedStorageApi = customCreateApi({
  reducerPath: NAME,
  tagTypes,
  baseQuery: createDynamicBaseQuery(
    (state: FrameworkRootState) => `${getSharedStorageUrl(state, log.warn)}/unstable`,
    {
      prepareHeaders,
    },
    (): FrameworkRootState => store.getState(),
  ),
  endpoints: (builder: Builder) => ({
    ...perspectiveEndpoints(builder),
    ...sceneEndpoints(builder),
  }),
});

export const { useDeletePerspectiveMutation } = sharedStorageApi;
