import { isNotNullish } from '@nx/stdlib';

import type { DiscountResponse, UsageBreakdownItemResponse, UsageResponse } from '../console.api.types';
import type { Usage, UsageBreakdownItem, UsageDiscount } from '../console.types';

const transformDiscountResponse = (response: DiscountResponse): UsageDiscount => ({
  promotionName: response.promotion_name,
  voucherCode: response.voucher_code,
  dollarAmountOff: response.dollar_amount_off,
});

const transformBreakdownResponse = (response: UsageBreakdownItemResponse): UsageBreakdownItem => ({
  dbid: response.dbid,
  name: response.name,
  consumptionUnits: response.consumption_units,
  unitType: response.unit_type,
  unitCostInDollars: response.unit_cost_in_dollars,
  cost: response.cost,
  costType: response.cost_type,
  billingCategory: response.billing_category,
  endTime: response.end_time,
  tier: response.tier,
  pricingPlan: response.pricing_plan,
  productName: response.product_name,
});

export const transformUsageResponse = (response: UsageResponse): Usage => ({
  totalCost: response.total_cost,
  totalCostType: response.total_cost_type,
  totalBreakdownCount: response.total_breakdown_count,
  discount: isNotNullish(response.discount) ? transformDiscountResponse(response.discount) : undefined,
  breakdown: response.breakdown?.map((breakdown) => transformBreakdownResponse(breakdown)),
  hasPricingPlanChange: response.has_pricing_plan_change,
  usagePeriodEnd: response.usage_period_end,
  usagePeriodStart: response.usage_period_start,
});
