export default [
  {
    version: '0.8.2',
    up: (state) => state,
  },
  {
    version: '1.3.0',
    up: (state) => {
      const { storeId, ...rest } = state;
      return {
        ...rest,
        dbmsId: storeId,
      };
    },
  },
  {
    version: '1.9.0',
    up: (state) => {
      const { graph, perspectiveId, ...rest } = state;
      return {
        ...rest,
        graph: { present: graph },
        currentPerspectiveId: perspectiveId,
      };
    },
  },
];
