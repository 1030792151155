import type * as ImportShared from '@nx/import-shared';
import { isNullish } from '@nx/stdlib';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import type { DataModel } from '../../data-model/data-model';
import {
  addNode,
  addNodeAndRelationship,
  addRelationship,
  clearModel,
  deleteNodesAndRelationships,
} from '../actions/common-actions';
import type { RootState } from '../store';
import { selectDataModel } from './data-model';
import { clearSelectedState, getNewPosition } from './visualisation.utils';

export const emptyVisualisationState: ImportShared.VisualisationState = { nodes: [], relationships: [] };

const visualisationSlice = createSlice({
  name: 'visualisation',
  initialState: emptyVisualisationState,
  reducers: {
    setVisualisation(state, action: PayloadAction<ImportShared.VisualisationState>) {
      state.nodes = action.payload.nodes;
      state.relationships = action.payload.relationships;
    },
    updateNodePositions(state, action: PayloadAction<{ nodePositions: { nodeId: string; x: number; y: number }[] }>) {
      const { nodePositions } = action.payload;
      nodePositions.forEach((position) => {
        const existingNode = state.nodes.find((node) => node.id === position.nodeId);
        if (!isNullish(existingNode)) {
          existingNode.position = { x: position.x, y: position.y };
        }
      });
    },
    deselectAll(state) {
      clearSelectedState(state);
    },
    toggleSelected(state, action: PayloadAction<{ nodeIds: string[]; relationshipIds: string[] }>) {
      const { nodeIds, relationshipIds } = action.payload;
      state.nodes.filter((node) => nodeIds.includes(node.id)).forEach((node) => (node.selected = !node.selected));
      state.relationships
        .filter((relationship) => relationshipIds.includes(relationship.id))
        .forEach((relationship) => (relationship.selected = !relationship.selected));
    },
    setToSelectedAndDeselectRest(state, action: PayloadAction<{ nodeIds: string[]; relationshipIds: string[] }>) {
      const { nodeIds, relationshipIds } = action.payload;
      state.nodes.forEach((node) => {
        node.selected = nodeIds.includes(node.id);
      });
      state.relationships.forEach((relationship) => {
        relationship.selected = relationshipIds.includes(relationship.id);
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearModel, (state) => {
      state.nodes = [];
      state.relationships = [];
    });
    builder.addCase(deleteNodesAndRelationships, (state, action) => {
      const { nodeIds, relationshipIds } = action.payload;
      state.nodes = state.nodes.filter((node) => !nodeIds.includes(node.id));
      state.relationships = state.relationships.filter((relationship) => !relationshipIds.includes(relationship.id));
    });
    builder.addCase(addNode, (state, action) => {
      const { newNodeId } = action.payload;
      const position = getNewPosition(state.nodes);
      clearSelectedState(state);
      state.nodes.push({ id: newNodeId, position, selected: true });
    });
    builder.addCase(addNodeAndRelationship, (state, action) => {
      const { targetNodeId, targetNodePosition, newRelationshipId } = action.payload;
      clearSelectedState(state);
      state.nodes.push({
        id: targetNodeId,
        position: { x: targetNodePosition.x ?? 0, y: targetNodePosition.y ?? 0 },
        selected: true,
      });
      state.relationships.push({
        id: newRelationshipId,
        selected: false,
      });
    });
    builder.addCase(addRelationship, (state, action) => {
      const { newRelationshipId } = action.payload;
      clearSelectedState(state);
      state.relationships.push({
        id: newRelationshipId,
        selected: true,
      });
    });
  },
});

export const selectVisualisationState: (
  state: RootState,
) => ReturnType<(state: RootState) => ImportShared.VisualisationState> = createSelector(
  (state: RootState) => state,
  (state) => state.visualisation,
);

export const selectSelectedNodes = createSelector(
  selectVisualisationState,
  (visualisation: ImportShared.VisualisationState) => {
    return visualisation.nodes.filter((n) => n.selected);
  },
);

export const selectSelectedNodeIds = createSelector(selectSelectedNodes, (selectedNodes) =>
  selectedNodes.map((n) => n.id),
);

export const selectSelectedRelationships = createSelector(
  selectVisualisationState,
  selectDataModel,
  (visualisation: ImportShared.VisualisationState, dataModel: DataModel) => {
    const { relationshipModels } = dataModel;
    return visualisation.relationships
      .filter((rel) => rel.selected)
      .map((rel) => {
        const matchingRelationshipModel = relationshipModels.find((r) => r.relationshipObjectType.$id === rel.id);
        return {
          ...rel,
          fromId: matchingRelationshipModel?.from.nodeObjectType.$id ?? '',
          toId: matchingRelationshipModel?.to.nodeObjectType.$id ?? '',
        };
      });
  },
);

export const selectSelectedRelationshipIds = createSelector(selectSelectedRelationships, (selectedRelationships) =>
  selectedRelationships.map((r) => r.id),
);

export const selectCountSelectedEntities = (state: RootState) => {
  const selectedNodes = selectSelectedNodes(state);
  const selectedRelationships = selectSelectedRelationships(state);
  return selectedNodes.length + selectedRelationships.length;
};

export const { setVisualisation, updateNodePositions, deselectAll, toggleSelected, setToSelectedAndDeselectRest } =
  visualisationSlice.actions;

export default visualisationSlice.reducer;
