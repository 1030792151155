import type { StaticDecode, TSchema } from '@sinclair/typebox';

import { validate } from './validate';

export function decode<T extends TSchema>(schema: T, data: unknown): StaticDecode<T> | undefined {
  let validatedResult: StaticDecode<typeof schema>;

  try {
    validatedResult = validate(schema, data);
  } catch {
    validatedResult = undefined;
  }

  return validatedResult;
}
