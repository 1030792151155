import { Label } from '@neo4j-ndl/react';
import { useActiveProjectSummary } from '@nx/state';
import type { Instance, InstanceSummary } from '@nx/state';
import { EntitySelectorDropdown } from '@nx/ui';
import type { ComponentProps } from 'react';

import { useUpxContext } from './upx-context';

export interface InstanceOption {
  onlineStatusColor: ComponentProps<typeof Label>['color'];
  instanceStatusText: string;
  instanceName: string;
}

export interface InstanceSelectorProps {
  selectedInstanceSummary: InstanceSummary | undefined;
  setSelectedInstanceId: (instance?: string) => void;
  instanceSummaries: InstanceSummary[];
  instances: Instance[];
  isInstancesLoading?: boolean;
  onInstanceChange?: () => void;
}

const MenuOption = (props: InstanceOption) => {
  return (
    <div className="flex flex-col" id={`option-${props.instanceName}`} data-testid={`option-${props.instanceName}`}>
      {/* Safeguard but should not truncate as max name length is 30 chars */}
      <div className="max-w-[31ch] overflow-hidden text-ellipsis">{props.instanceName}</div>
      {
        <Label fill="clean" hasIcon color={props.onlineStatusColor} className="font-normal">
          {props.instanceStatusText}
        </Label>
      }
    </div>
  );
};

export const InstanceSelector = ({
  selectedInstanceSummary,
  setSelectedInstanceId,
  instances,
  onInstanceChange,
}: InstanceSelectorProps) => {
  const activeProject = useActiveProjectSummary();
  const { instanceStatusText, getOnlineStatusColor } = useUpxContext();
  const instanceEntities = instances
    .map((instance) => ({
      id: instance.id,
      name: instance.name,
      instance: instance,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <EntitySelectorDropdown
      label="Instance"
      entities={instanceEntities}
      selectedEntity={instanceEntities.find((entity) => entity.id === selectedInstanceSummary?.id)}
      setSelectedEntity={setSelectedInstanceId}
      onEntityChange={onInstanceChange}
      MenuItem={(entity) => (
        <MenuOption
          onlineStatusColor={getOnlineStatusColor(entity.instance)}
          instanceStatusText={instanceStatusText(entity.instance, activeProject ?? null)}
          instanceName={entity.instance.name}
        />
      )}
    />
  );
};
