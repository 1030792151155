import { LoadingSpinner, Tabs } from '@neo4j-ndl/react';
import { TIER } from '@nx/state';
import { Center } from '@nx/ui';
import classNames from 'classnames';
import { useState } from 'react';

import { NoInstancesOverlay } from '../shared/components';
import { InstanceBar } from '../shared/components/instance-bar';
import { InstanceSelector } from '../shared/components/instance-selector';
import { withUpxContext } from '../shared/components/upx-context';
import { useInstanceSelectorProps } from '../shared/hooks/use-instance-selector-props';
import { DownloadDrawerButtonWithDrawer } from './logs-download';
import classes from './logs.module.css';
import { FakeQueryLogs } from './query-logs/fake-query-logs';
import { QueryLogsView } from './query-logs/query-logs';
import { SecurityLogsView } from './security-logs/security-logs';
import { LOG_DEFAULT_TAB, LOG_TAB_OPTIONS } from './shared/types';

const LogsView = () => {
  const {
    selectedInstanceSummary,
    selectedInstance,
    setSelectedInstanceId,
    instances,
    isInstancesLoading,
    instanceSummaries,
  } = useInstanceSelectorProps();

  const [selectedTab, setSelectedTab] = useState(LOG_DEFAULT_TAB);
  const [hasRequestedData, setHasRequestedData] = useState(false);

  if (isInstancesLoading) {
    return (
      <Center>
        <LoadingSpinner size="large" />
      </Center>
    );
  }

  if (instanceSummaries.length === 0 || !selectedInstanceSummary) {
    return (
      <NoInstancesOverlay
        title="No instances found"
        body="Create an instance to enable access to a broad range of different instance and database logs."
      >
        <FakeQueryLogs />
      </NoInstancesOverlay>
    );
  }

  return (
    <div>
      <div className="border-palette-neutral-border-weak bg-palette-neutral-bg-weak">
        <div className="border-palette-neutral-border-weak bg-palette-neutral-bg-weak flex h-[56px] w-full items-center border-b-[1px] px-4">
          <Tabs
            fill="underline"
            value={selectedTab}
            onChange={(tab) => {
              setSelectedTab(tab);
              setHasRequestedData(false);
            }}
            className={classNames(classes.tabs, 'self-end')}
          >
            {LOG_TAB_OPTIONS.map((t) => (
              <Tabs.Tab tabId={t.key} key={t.key}>
                {t.text}
              </Tabs.Tab>
            ))}
          </Tabs>

          {selectedInstance && selectedInstance.tier !== TIER.FREE && (
            <DownloadDrawerButtonWithDrawer className="ml-auto" />
          )}
        </div>

        <InstanceBar>
          <InstanceSelector
            selectedInstanceSummary={selectedInstanceSummary}
            setSelectedInstanceId={setSelectedInstanceId}
            instances={instances}
            instanceSummaries={instanceSummaries}
            onInstanceChange={() => setHasRequestedData(false)}
          />
        </InstanceBar>
      </div>

      <Tabs.TabPanel value={selectedTab} tabId="QUERY">
        <QueryLogsView
          selectedInstance={selectedInstance}
          selectedInstanceSummary={selectedInstanceSummary}
          isInstancesLoading={isInstancesLoading}
          instanceSummaries={instanceSummaries}
          hasRequestedData={hasRequestedData}
          setHasRequestedData={setHasRequestedData}
        />
      </Tabs.TabPanel>
      <Tabs.TabPanel value={selectedTab} tabId="SECURITY">
        <SecurityLogsView
          selectedInstance={selectedInstance}
          selectedInstanceSummary={selectedInstanceSummary}
          isInstancesLoading={isInstancesLoading}
          instanceSummaries={instanceSummaries}
          hasRequestedData={hasRequestedData}
          setHasRequestedData={setHasRequestedData}
        />
      </Tabs.TabPanel>
    </div>
  );
};

const LogsWithUpxContext = withUpxContext(LogsView);

export default LogsWithUpxContext;
