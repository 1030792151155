import type { DataModelJsonStruct } from './data-model.json.types';
import type { ApiDynamicDataSourceParamsPayload } from './data-source-dynamic-form.types';
import type { LegacyDataSourceConfig, LegacyDataSourceType } from './data-source-legacy.types';
import type { SerialisableVisualisationState } from './visualisation.types';

export const API_IMPORT_JOB_STATUS = ['Completed', 'Queued', 'Running'] as const;

export type ApiImportJobStatus = (typeof API_IMPORT_JOB_STATUS)[number] | '';

export const API_IMPORT_JOB_EXIT_STATUS = ['Success', 'Failure'] as const;

export type ApiImportJobExitStatus = (typeof API_IMPORT_JOB_EXIT_STATUS)[number];

export type ApiImportJobResult = {
  state: ApiImportJobExitStatus;
  message: string;
};

export type ApiImportJobOverview = {
  id: string;
  status: ApiImportJobStatus;
  submittedTime: string;
  startTime?: string;
  result?: ApiImportJobResult;
  completionTime?: string;
  dataSourceMetadata: { driver: LegacyDataSourceType; upxDataSourceId: string };
  auraMetadata: { dbId: string; userId: string; projectId: string };
};

export type DataSourceConnectParams = Omit<LegacyDataSourceConfig, 'name'>;

export type NewDataSourceConnectParams = Record<string, string>;

export type AuraConnectParams = {
  dbId: string;
  user: string;
  password: string;
};

/**
 * @deprecated will be removed once we fully switch to dynamic data source params
 */
export type LegacyCloudDataImportRequestBody = {
  dataSourceCredentials: DataSourceConnectParams & { upxDataSourceId: string };
  auraCredentials: AuraConnectParams;
  importConfig: {
    ignoreErrors: boolean;
    threads: number;
    batchSize: number;
  };
  importModelMetadata?: {
    version: string;
    importModelId: string;
    dataModel: DataModelJsonStruct;
    visualisation: SerialisableVisualisationState;
  };
};

export type CloudDataImportRequestBody = {
  dataSource: ApiDynamicDataSourceParamsPayload & {
    upxDataSourceId: string;
  };
  auraCredentials: AuraConnectParams;
  importConfig: {
    ignoreErrors: boolean;
    threads: number;
    batchSize: number;
  };
  importModelMetadata: {
    version: string;
    importModelId: string;
    dataModel: DataModelJsonStruct;
    visualisation: SerialisableVisualisationState;
  };
};
