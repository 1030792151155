import { ZoneId } from '@js-joda/core';

export const DATE = 'Date';
export const LOCAL_TIME = 'LocalTime';
export const LOCAL_DATETIME = 'LocalDateTime';
export const TIME = 'Time';
export const DATETIME = 'DateTime';
export const DURATION = 'Duration';

const HOUR_PER_DAY = 24;
export const NANO_PER_SECOND = 1000000000;
export const MILLISECOND_PER_SECOND = 1000;
export const NANO_PER_MILLISECOND = 1000000;
export const NANO_PER_HOUR = 60 * 60 * NANO_PER_SECOND;
export const NANO_PER_DAY = HOUR_PER_DAY * NANO_PER_HOUR;
export const MILLISECOND_PER_HOUR = 1 * 60 * 60 * 1000;
export const MILLISECOND_PER_DAY = HOUR_PER_DAY * MILLISECOND_PER_HOUR;
export const MAX_SUPPORTED_YEAR = 999999999;
export const MIN_SUPPORTED_YEAR = -999999999;
export const MAX_SUPPORTED_YEAR_EQUIVALENT_EPOCH_DAY = 365241780471; // days since 1970-01-01 to 999999999-12-31

export const MAX_SUPPORTED_NANOSECOND_DIGIT = 9;
export const YEAR_OVERFLOW_ERROR = `Supported year is between ${MAX_SUPPORTED_YEAR} and ${MIN_SUPPORTED_YEAR} `;

export const ZONE_Z = ZoneId.of('Z');
// taken from Joda.js

export const DAYS_PER_CYCLE = 146097; // The number of days in a 400 year cycle.

export const DAYS_0000_TO_1970 = DAYS_PER_CYCLE * 5 - (30 * 365 + 7);

export const DATE_TIME_TYPE = [DATE, LOCAL_TIME, LOCAL_DATETIME, TIME, DATETIME];
export const DATE_TIME_TYPES_LOWERCASE = DATE_TIME_TYPE.map((type) => type.toLocaleLowerCase());
export const ZONED_TYPE = [TIME, DATETIME];
export const DATE_PICKER_TYPES = [DATE, LOCAL_DATETIME, DATETIME];
export const TIME_PICKER_TYPES = [LOCAL_DATETIME, DATETIME, LOCAL_TIME, TIME];
export const TIME_OFFSET_PICKER_TYPES = [TIME];
export const TIME_ZONE_PICKER_TYPES = [DATETIME];

export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm:ss';
export const DATETIME_FORMAT = `${DATE_FORMAT}T${TIME_FORMAT}`;

export const DATE_REGEX = /^([+-])?(\d{1,9})-(\d{1,2})-(\d{1,2})/;
export const TIME_REGEX = /^(\d{1,2}):(\d{1,2}):(\d{1,2})[.]?(\d{0,9})([Z+-]){1}(\d{0,2}:?\d{0,2})/;
export const DATETIME_REGEX =
  /^([+-])?(\d{1,9})-(\d{1,2})-(\d{1,2})T(\d{1,2}):(\d{1,2}):(\d{1,2})[.]?(\d{0,9})?([+-].+)?(.+)?/;
