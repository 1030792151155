/* eslint-disable @typescript-eslint/ban-types */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-duplicate-type-constituents */

/* eslint-disable @typescript-eslint/consistent-indexed-object-style */
import { sharedStorageApi as api } from '../shared-storage/shared-storage.api';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getGraphStyle: build.query<GetGraphStyleApiResponse, GetGraphStyleApiArg>({
      query: (queryArg) => ({ url: `/unstable/graph-styles`, params: { database: queryArg.database } }),
    }),
    setGraphStyle: build.mutation<SetGraphStyleApiResponse, SetGraphStyleApiArg>({
      query: (queryArg) => ({ url: `/unstable/graph-styles`, method: 'PUT', body: queryArg.updateGraphStyleBody }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as generatedGraphStylingApi };
export type GetGraphStyleApiResponse = /** status 200 OK */ QueriedGraphStyle;
export type GetGraphStyleApiArg = {
  database: string;
};
export type SetGraphStyleApiResponse = /** status 200 OK */ number;
export type SetGraphStyleApiArg = {
  updateGraphStyleBody: UpdateGraphStyleBody;
};
export type PartialRelStyle = {
  relationship?: string;
  color?: string;
  width?: number;
  captionType?: 'id' | 'type' | 'property';
  captionSize?: number;
  captionKey?: string;
};
export type RecordStringPartialRelStyle = {
  [key: string]: PartialRelStyle;
};
export type PartialNodeStyle = {
  label?: string;
  color?: string;
  size?: number;
  captionType?: 'id' | 'type' | 'property';
  captionSize?: number;
  captionKey?: string;
};
export type RecordStringPartialNodeStyle = {
  [key: string]: PartialNodeStyle;
};
export type QueriedGraphStyle = {
  stylingPrecedence: string[];
  relationship: RecordStringPartialRelStyle;
  node: RecordStringPartialNodeStyle;
};
export type Location = 'body' | 'cookies' | 'headers' | 'params' | 'query';
export type FieldValidationError = {
  /** The error message */
  msg: any;
  /** The value of the field. It might be unset if the value is hidden. */
  value?: any;
  /** The path to the field which has a validation error */
  path: string;
  /** The location within the request where this field is */
  location: Location;
  /** Indicates that the error occurred because a field had an invalid value */
  type: 'field';
};
export type AlternativeValidationError = {
  /** The list of underlying validation errors returned by validation chains in `oneOf()` */
  nestedErrors: FieldValidationError[];
  /** The error message */
  msg: any;
  /** Indicates that the error occurred because all alternatives (e.g. in `oneOf()`) were invalid */
  type: 'alternative';
};
export type GroupedAlternativeValidationError = {
  /** The list of underlying validation errors returned by validation chains in `oneOf()` */
  nestedErrors: FieldValidationError[][];
  /** The error message */
  msg: any;
  /** Indicates that the error occurred because all alternatives (e.g. in `oneOf()`) were invalid,
    and the nested errors are grouped per alternative. */
  type: 'alternative_grouped';
};
export type PickFieldInstanceExcludeKeyofFieldInstanceOriginalPathOrPathValues = {
  path: string;
  location: Location;
  value: any;
};
export type OmitFieldInstanceOriginalPathOrPathValues =
  PickFieldInstanceExcludeKeyofFieldInstanceOriginalPathOrPathValues;
export type UnknownFieldsError = {
  /** The list of fields that are unknown */
  fields: OmitFieldInstanceOriginalPathOrPathValues[];
  /** The error message */
  msg: any;
  /** Indicates that the error occurred because one or more fields are unknown in the request */
  type: 'unknown_fields';
};
export type ValidationError =
  | AlternativeValidationError
  | GroupedAlternativeValidationError
  | UnknownFieldsError
  | FieldValidationError;
export type ReadonlyArraySourceLocation = {};
export type ReadonlyArrayStringOrNumber = {};
export type GraphQlFormattedError = {
  /** A short, human-readable summary of the problem that **SHOULD NOT** change
    from occurrence to occurrence of the problem, except for purposes of
    localization. */
  message: string;
  /** If an error can be associated to a particular point in the requested
    GraphQL document, it should contain a list of locations. */
  locations?: ReadonlyArraySourceLocation;
  /** If an error can be associated to a particular field in the GraphQL result,
    it _must_ contain an entry with the key `path` that details the path of
    the response field which experienced the error. This allows clients to
    identify whether a null result is intentional or caused by a runtime error. */
  path?: ReadonlyArrayStringOrNumber;
  /** Reserved for implementors to extend the protocol however they see fit,
    and hence there are no additional restrictions on its contents. */
  extensions?: {
    [key: string]: any;
  };
};
export type UpdateGraphStyleBody = {
  stylingPrecedence?: string[];
  relationship?: RecordStringPartialRelStyle;
  node?: RecordStringPartialNodeStyle;
  database: string;
};
