import { isNullish } from '@nx/stdlib';

export type PropertyDataType = 'string' | 'float' | 'integer' | 'boolean' | 'datetime';

export type Property = {
  identifier: string;
  property: string;
  type: PropertyDataType;
};

export enum VALIDATION_ERROR_CODE {
  INCOMPLETE = 'INCOMPLETE',
  DUPLICATED = 'DUPLICATED',
}

export type ErrorModel = {
  code: VALIDATION_ERROR_CODE[keyof VALIDATION_ERROR_CODE];
  message: string;
};

export type NodeKey = {
  // Array of property identifiers into the csv file columns (currently always length 1)
  // Question: can we support this not being a property in the graph/db?
  properties: string[];
};

export type NodeSchema = {
  label: string;
  additionLabels: string[];
  labelProperties: string[];
  properties: Property[];
  key: NodeKey;
};

export type RelationshipSchema = {
  // Could be derived from a column in a file
  type: string;
  sourceNodeSchema: string;
  targetNodeSchema: string;
  properties: Property[];
};

export type MappingField = {
  field?: string;
};

export type FileFilter = {
  field?: string;
  exactMatch: string;
};

export type FieldMapping = {
  nodeSchema?: string;
  relationshipSchema?: string;
  keyIndex?: number;
  source?: boolean;
  target?: boolean;
  propertyIndex?: number;
};

export type MappingDefinition = {
  fileSchema?: string;
  mappings: MappingField[];
  fileFilter?: FileFilter;
};

export type NodeMapping = MappingDefinition & {
  nodeSchema: string;
};

export type RelationshipMapping = MappingDefinition & {
  relationshipSchema: string;
  sourceMappings: MappingField[];
  targetMappings: MappingField[];
};

export type ErrorList = Record<number, ErrorModel[]>;

export type FileSchemaError = Record<string, ErrorModel>;

export type SchemaError = {
  properties?: ErrorList;
};

export type NodeSchemaError = SchemaError & {
  label?: ErrorModel;
  key?: ErrorModel;
};

export type RelationshipSchemaError = SchemaError & {
  type?: ErrorModel;
};

export type MappingError = {
  mappings?: ErrorList;
  fileSchema?: ErrorModel;
};

export type NodeMappingError = MappingError;

export type RelationshipMappingError = MappingError & {
  sourceMappings?: ErrorModel;
  targetMappings?: ErrorModel;
};

export type DataModelErrors = {
  fileErrors: Record<string, FileSchemaError>;
  nodeErrors: Record<string, NodeSchemaError & NodeMappingError>;
  relationshipErrors: Record<string, RelationshipSchemaError & RelationshipMappingError>;
};

export type MappingModel = {
  nodeMappings: Record<string, NodeMapping>;
  relationshipMappings: Record<string, RelationshipMapping>;
};

export type FileSchemaField = {
  name: string;
  type: PropertyDataType;
  sample?: string;
  include?: boolean;
  format?: string;
  timezone?: string;
};

export type FileSchema = {
  fields: FileSchemaField[];
  expanded?: boolean;
};

export type FileModel = {
  fileSchemas: Record<string, FileSchema>;
};

type GraphModel = {
  nodeSchemas: Record<string, NodeSchema>;
  relationshipSchemas: Record<string, RelationshipSchema>;
};

export type Configurations = {
  idsToIgnore?: string[];
};

/**
 * @deprecated This type should not be used for new models.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export type DataModel_0_8_0 = {
  fileModel: FileModel;
  graphModel: GraphModel;
  mappingModel: MappingModel;
  configurations: Configurations;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isDataModel_0_8_0 = (dataModel: any): dataModel is DataModel_0_8_0 => {
  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  // TODO - better validation...
  return (
    !isNullish(dataModel) &&
    !isNullish(dataModel.fileModel) &&
    !isNullish(dataModel.graphModel) &&
    !isNullish(dataModel.mappingModel) &&
    !isNullish(dataModel.configurations)
  );
  /* eslint-enable @typescript-eslint/no-unsafe-member-access */
};
