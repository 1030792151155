import type { InstanceSnapshotParams, SnapshotResponse } from '../console.api.types';
import type { Snapshot } from '../console.types';
import { transformSnapshotResponse } from '../transformers/snapshot';
import { type Builder, LIST_ID } from './types';

export const snapshotEndpoints = (builder: Builder) => ({
  listSnapshots: builder.query<Snapshot[], string>({
    query: (dbId) => ({ url: `databases/${dbId}/snapshots`, params: { status: 'ALL' } }),
    transformResponse: (snapshots: SnapshotResponse[]) =>
      snapshots.map((snapshot) => transformSnapshotResponse(snapshot)),
    providesTags: (result, error, instanceId) => [{ type: 'Snapshot', id: LIST_ID, dbId: instanceId }],
  }),
  takeSnapshot: builder.mutation<void, string>({
    query: (dbId) => ({ url: `databases/${dbId}/snapshots`, method: 'POST' }),
    invalidatesTags: (result, error, dbId) => [{ type: 'Snapshot', dbId, id: LIST_ID }],
  }),
  getSnapshotUrl: builder.query<string, InstanceSnapshotParams>({
    query: (params) => `databases/${params.dbId}/snapshots/${params.snapshotId}/url`,
  }),
});
