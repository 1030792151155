import type {
  CreatedImportDataSource,
  FailedToParseImportModel,
  ImportModel,
  ImportModelBase,
  ImportModelToMutate,
  LegacyImportDataSource,
  SavedImportDataSource,
  SerialisedImportModel,
} from '@nx/api';
import type * as ImportShared from '@nx/import-shared';
import type { RootState } from '@nx/state';
import { createDynamicBaseQuery, prepareHeaders, selectAuraConfiguration, LEGACY_store as store } from '@nx/state';
import { isNullish } from '@nx/stdlib';
import { createApi } from '@reduxjs/toolkit/query/react';

import {
  getLegacyDataSourceToSave,
  getSavedImportDataSourceFromLegacy,
} from '../../components/data-source-form/migration';
import { APP_VERSION } from '../../constants';
import { emptyDataModel } from '../../data-model/data-model.json.helpers';
import { serialize } from '../../helpers/serialization';
import { getUniqCounterWithPrefixString } from '../../utils/import-model-utils';
import { emptyVisualisationState } from '../reducers/visualisation';
import { extractImportModelFromSerialisation, mapImportModelToImportModelMetadata, sortByTimeDesc } from './utils';

const getSharedStorageUrl__Unstable = (state: RootState) => {
  const storageApiUrl = selectAuraConfiguration(state)?.storageApiUrl;
  if (storageApiUrl === undefined) {
    throw new Error('No storage API URL defined');
  }

  return `${storageApiUrl}/unstable`;
};

const DATA_SOURCES_URL = '/import-data-sources' as const;

const IMPORT_MODELS_URL = '/import-models' as const;

export const IMPORT_DATA_SOURCES_TAG = 'ImportDataSources' as const;
export const IMPORT_MODELS_TAG = 'ImportModels' as const;
export const SHARED_STORAGE_IMPORT_REDUCER_KEY = 'sharedStorageImport' as const;

export const sharedStorageSlice = createApi({
  tagTypes: [IMPORT_DATA_SOURCES_TAG, IMPORT_MODELS_TAG],
  reducerPath: SHARED_STORAGE_IMPORT_REDUCER_KEY,
  baseQuery: createDynamicBaseQuery(
    (state: RootState) => getSharedStorageUrl__Unstable(state),
    {
      prepareHeaders,
    },
    (): RootState => store.getState(),
  ),
  endpoints: (builder) => ({
    getDataSources: builder.query<
      SavedImportDataSource[],
      { dataSourceDeclarations: ImportShared.DataSourceDefinition[] | undefined }
    >({
      query: () => DATA_SOURCES_URL,
      providesTags: (result, _, __) => {
        return result
          ? [
              ...result.map(({ id }) => ({ type: IMPORT_DATA_SOURCES_TAG, id })),
              // Makes sure we refetch data sources when the user adds the first data source
              { type: IMPORT_DATA_SOURCES_TAG, id: 'all' },
            ]
          : [IMPORT_DATA_SOURCES_TAG];
      },
      transformResponse: (
        data: LegacyImportDataSource[] | undefined,
        _,
        { dataSourceDeclarations },
      ): SavedImportDataSource[] => {
        const sorted = (data ?? []).sort((a, b) => sortByTimeDesc(a.updatedAt, b.updatedAt));

        const newFormat = sorted
          .map((legacy) => {
            const dataSourceDefinition = dataSourceDeclarations?.find((m) => m.type === legacy.driver);
            if (!isNullish(dataSourceDefinition)) {
              return getSavedImportDataSourceFromLegacy(legacy, dataSourceDefinition);
            }
            return undefined;
          })
          .filter((d) => !isNullish(d));
        return newFormat;
      },
    }),
    getDataSourceById: builder.query<
      SavedImportDataSource | undefined,
      { dataSourceId: string; dataSourceDeclarations: ImportShared.DataSourceDefinition[] | undefined }
    >({
      query: ({ dataSourceId }) => `${DATA_SOURCES_URL}/${dataSourceId}`,
      transformResponse: (
        data: LegacyImportDataSource,
        _,
        { dataSourceDeclarations },
      ): SavedImportDataSource | undefined => {
        const dataSourceDefinition = dataSourceDeclarations?.find((m) => m.type === data.driver);
        if (!isNullish(dataSourceDefinition)) {
          return getSavedImportDataSourceFromLegacy(data, dataSourceDefinition);
        }
        return undefined;
      },
    }),
    createNewDataSource: builder.mutation<CreatedImportDataSource, ImportShared.DataSourceConfig>({
      query: (dataSource) => {
        const legacyFormat: ImportShared.LegacyDataSourceToSave = getLegacyDataSourceToSave(dataSource);
        return {
          url: DATA_SOURCES_URL,
          method: 'POST',
          body: legacyFormat,
        };
      },
      invalidatesTags: [IMPORT_DATA_SOURCES_TAG],
    }),
    deleteDataSourceById: builder.mutation<void, string>({
      query: (dataSourceId) => ({
        url: `${DATA_SOURCES_URL}/${dataSourceId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: IMPORT_DATA_SOURCES_TAG, id: arg }],
    }),
    getImportModelsMetadata: builder.query<(ImportShared.ImportModelMetadata | FailedToParseImportModel)[], void>({
      query: () => IMPORT_MODELS_URL,
      transformResponse: (response: SerialisedImportModel[]) => {
        const extractedModels = response
          .map(extractImportModelFromSerialisation)
          .sort((a, b) => sortByTimeDesc(a.createdAt, b.createdAt));
        return extractedModels.map(mapImportModelToImportModelMetadata);
      },
      providesTags: (result, _, __) => {
        return result ? [...result.map(({ id }) => ({ type: IMPORT_MODELS_TAG, id }))] : [IMPORT_MODELS_TAG];
      },
    }),
    getImportModelsNames: builder.query<ImportModelBase[], void>({
      query: () => ({ url: IMPORT_MODELS_URL, params: { fields: ['name'] } }),
      transformResponse: (response: ImportModelBase[]) => {
        const extractedModels = response.sort((a, b) => sortByTimeDesc(a.createdAt, b.createdAt));
        return extractedModels;
      },
    }),
    getImportModelById: builder.query<ImportModel | FailedToParseImportModel, string>({
      query: (importModelId) => `${IMPORT_MODELS_URL}/${importModelId}`,
      transformResponse: (response: SerialisedImportModel) => {
        return extractImportModelFromSerialisation(response);
      },
    }),
    createNewImportModel: builder.mutation<
      SerialisedImportModel,
      {
        visualisation?: ImportShared.SerialisableVisualisationState;
        dataModel?: ImportShared.DataModelJsonStruct;
        dataSourceId?: string;
        dataSourceName?: string;
        importModelsNames?: string[];
      }
    >({
      query: ({ visualisation, dataModel, dataSourceId, dataSourceName, importModelsNames }) => {
        let baseName = 'Untitled model ';
        if (!isNullish(dataSourceName)) {
          baseName = `${dataSourceName} model `;
        }
        const name = getUniqCounterWithPrefixString(importModelsNames ?? [], baseName);

        const body: ImportModelToMutate = {
          name,
          dataModel: serialize(dataModel ?? emptyDataModel),
          visualisation: serialize(visualisation ?? emptyVisualisationState),
          dataSourceId,
          version: APP_VERSION,
        };
        return {
          url: IMPORT_MODELS_URL,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [IMPORT_MODELS_TAG],
    }),
    updateImportModel: builder.mutation<void, { id: string; body: ImportModelToMutate }>({
      query: ({ id, body }) => ({
        url: `${IMPORT_MODELS_URL}/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, __, arg) => [{ type: IMPORT_MODELS_TAG, id: arg.id }],
    }),
    patchImportModel: builder.mutation<void, { id: string; body: Partial<ImportModelToMutate> }>({
      query: ({ id, body }) => ({
        url: `${IMPORT_MODELS_URL}/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_, __, arg) => [{ type: IMPORT_MODELS_TAG, id: arg.id }],
    }),
    deleteImportModelById: builder.mutation<void, string>({
      query: (importModelId) => ({
        url: `${IMPORT_MODELS_URL}/${importModelId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [IMPORT_MODELS_TAG],
    }),
  }),
});

export const {
  useGetDataSourcesQuery,
  useGetDataSourceByIdQuery,
  useCreateNewDataSourceMutation,
  useDeleteDataSourceByIdMutation,
  useGetImportModelsMetadataQuery,
  useGetImportModelsNamesQuery,
  useGetImportModelByIdQuery,
  useLazyGetImportModelByIdQuery,
  useCreateNewImportModelMutation,
  useUpdateImportModelMutation,
  usePatchImportModelMutation,
  useDeleteImportModelByIdMutation,
} = sharedStorageSlice;
