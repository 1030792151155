import type { TreeItem, TreeItemComponentProps } from '@neo4j-ndl/react';
import { IconButton, Tooltip, TreeView } from '@neo4j-ndl/react';
import { ArrowsUpDownIconOutline } from '@neo4j-ndl/react/icons';
import type { BasicNode, BasicRelationship } from '@nx/neo4j-sdk';
import type { GraphStyling } from '@nx/state';
import { useSetting, useUpdateStylingPrecedence } from '@nx/state';
import React, { forwardRef, useMemo, useState } from 'react';

import { ShowAll } from '../show-all/show-all';
import { StatusIcon } from '../status-icon';
import { GraphChip } from './graph-chip';
import {
  getAllAvailableNodeCaptions,
  getAllAvailableRelationshipCaptions,
  getLabelStats,
  getRelTypeStats,
} from './graph-utils';
import { NvlSidePanel } from './nvl-side-panel';
import type { StylePicker } from './style-picker';
import { StylingPicker } from './style-picker';

export const OVERVIEW_STEP_SIZE = 25;
const sortAlphabetically = (a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase());

type OverviewPaneNvlProps = {
  nodes: BasicNode[];
  relationships: BasicRelationship[];
  limitHit: boolean;
  enableStylePicker: boolean;
  graphStyling: GraphStyling;
};

const CustomTreeItemComponent = forwardRef<
  HTMLElement,
  React.PropsWithChildren<TreeItemComponentProps<{ label: string }>> & { graphStyling: GraphStyling }
>((props: React.PropsWithChildren<TreeItemComponentProps<{ label: string }>> & { graphStyling: GraphStyling }, ref) => {
  const { graphStyling } = props;
  return (
    // wrong types in needle
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <TreeView.TreeItemWrapper
      {...props}
      ref={ref}
      style={{
        ...props.style,
        height: 'unset',
      }}
      dragHandleProps={{
        ...props.dragHandleProps,
        style: {
          opacity: '1',
        },
      }}
    >
      <GraphChip label={props.item.data.label} type="node" tabIndex={-1} className="my-1" graphStyling={graphStyling} />
    </TreeView.TreeItemWrapper>
  );
});
CustomTreeItemComponent.displayName = 'CustomTreeItemComponent';

export function NvlOverviewPane({
  nodes,
  relationships,
  limitHit,
  graphStyling,
  enableStylePicker = true,
}: OverviewPaneNvlProps): JSX.Element {
  const [maxVizNodes] = useSetting('query', 'maxVizNodes');
  const [showPrioList, setShowPrioList] = useState(false);
  const updateStylingPrecedence = useUpdateStylingPrecedence();

  const labelsSorted = useMemo(() => {
    const labelStats = getLabelStats(nodes);
    return Object.entries(labelStats).sort((a, b) => sortAlphabetically(a[0], b[0]));
  }, [nodes]);

  const relationshipsSorted = useMemo(() => {
    const relTypeStats = getRelTypeStats(relationships);
    return Object.entries(relTypeStats).sort((a, b) => sortAlphabetically(a[0], b[0]));
  }, [relationships]);

  const togglePrioList = () => {
    setShowPrioList((prevShowPrioList) => !prevShowPrioList);
  };

  const handleTreeViewChange = (newItems: TreeItem<Record<string, unknown>>[]) => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    updateStylingPrecedence(newItems.map((item) => item.data.label as string));
  };

  const [shownStylingPicker, setShownStylingPicker] = useState<{
    stylePicker: StylePicker;
    anchor: HTMLElement;
  } | null>(null);

  return (
    <>
      <NvlSidePanel.Title>
        <h6>Results overview</h6>
      </NvlSidePanel.Title>
      <NvlSidePanel.Content>
        <div className="mx-4 flex flex-col gap-2">
          {labelsSorted.length > 0 && (
            <div className="flex flex-col gap-2">
              <div className={`flex items-center justify-between text-sm`}>
                <span>Nodes{` (${nodes.length.toLocaleString()})`}</span>
                {enableStylePicker && (
                  <Tooltip type="simple" placement="left">
                    <Tooltip.Trigger hasButtonWrapper>
                      <IconButton
                        size="small"
                        onClick={togglePrioList}
                        isClean
                        isActive={showPrioList}
                        ariaLabel="update styling priority"
                      >
                        <ArrowsUpDownIconOutline />
                      </IconButton>
                    </Tooltip.Trigger>
                    <Tooltip.Content>Update styling priority</Tooltip.Content>
                  </Tooltip>
                )}
              </div>
              {limitHit && (
                <div className="flex items-center gap-1 text-xs">
                  <StatusIcon statusType="info" />
                  Display limit hit at {maxVizNodes} nodes.
                </div>
              )}

              {showPrioList ? (
                <div className="overflow-y-auto">
                  <TreeView
                    items={graphStyling.stylingPrecedence.map((label) => ({
                      id: label,
                      isCollapsed: false,
                      canHaveSubItems: false,
                      isSelected: false,
                      data: { label },
                    }))}
                    onItemsChanged={(newItems) => handleTreeViewChange(newItems)}
                    TreeItemComponent={(props) => {
                      return <CustomTreeItemComponent {...props} graphStyling={graphStyling} />;
                    }}
                  />
                </div>
              ) : (
                <div className="flex flex-row flex-wrap gap-x-1.5 gap-y-1 leading-tight">
                  <ShowAll initiallyShown={OVERVIEW_STEP_SIZE} isButtonGroup>
                    {labelsSorted.map(
                      ([label, { count, properties }]) =>
                        function GraphChipWrapper() {
                          return (
                            <GraphChip
                              onClick={
                                enableStylePicker
                                  ? (e) => {
                                      setShownStylingPicker({
                                        stylePicker: {
                                          type: 'LABEL',
                                          stylingId: label,
                                          captionOptions: getAllAvailableNodeCaptions(properties),
                                        },
                                        anchor: e.currentTarget,
                                      });
                                    }
                                  : undefined
                              }
                              type="node"
                              key={label}
                              label={label}
                              count={count}
                              tabIndex={-1}
                              graphStyling={graphStyling}
                            />
                          );
                        },
                    )}
                  </ShowAll>
                </div>
              )}
            </div>
          )}

          {relationshipsSorted.length > 0 && (
            <div className="flex flex-col gap-2">
              <span className="text-sm">Relationships{` (${relationships.length.toLocaleString()})`}</span>
              <div className="flex flex-row flex-wrap gap-x-1.5 gap-y-1 leading-tight">
                <ShowAll initiallyShown={OVERVIEW_STEP_SIZE} isButtonGroup>
                  {relationshipsSorted.map(([relType, { count, properties }]) => (
                    <GraphChip
                      type="relationship"
                      key={relType}
                      label={relType}
                      count={count}
                      onClick={
                        enableStylePicker
                          ? (e) => {
                              setShownStylingPicker({
                                stylePicker: {
                                  type: 'RELATIONSHIP',
                                  stylingId: relType,
                                  captionOptions: getAllAvailableRelationshipCaptions(properties),
                                },
                                anchor: e.currentTarget,
                              });
                            }
                          : undefined
                      }
                      graphStyling={graphStyling}
                    />
                  ))}
                </ShowAll>
              </div>
            </div>
          )}
          {shownStylingPicker !== null && (
            <StylingPicker
              stylePicker={shownStylingPicker.stylePicker}
              onClickOutside={() => setShownStylingPicker(null)}
              anchorEl={shownStylingPicker.anchor}
              graphStyling={graphStyling}
            />
          )}
        </div>
      </NvlSidePanel.Content>
    </>
  );
}
