import { Button, LoadingSpinner, SegmentedControl, TextLink, Tooltip, Typography } from '@neo4j-ndl/react';
import { Squares2X2IconOutline, TableCellsIconOutline } from '@neo4j-ndl/react/icons';
import { AURA_CONSOLE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import {
  ACTION,
  MODAL_TYPE,
  PROJECT_BILLING_METHOD,
  consoleApi,
  LEGACY_resetUploadBackupState as resetUploadBackupState,
  useActiveProject,
  useInstances,
  useModal,
  usePermissions,
  useSetting,
  useToolsConfiguration,
} from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { SearchField } from '@nx/ui';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CloudGroup from '../assets/group-2462.svg';
import { useTrackUpxEvent } from '../services/segment/analytics';
import { InstanceDetails } from './instance-details';
import { InstanceList } from './instance-list';
import { InstanceTable } from './instance-table';

type NewInstancePageProps = {
  hasPermission: boolean;
  projectSuspended: boolean;
  tipMessage: string;
};

const NewInstance = ({ hasPermission, projectSuspended, tipMessage }: NewInstancePageProps) => {
  const toolsConfiguration = useToolsConfiguration();
  const importRoute = toolsConfiguration.import?.route;
  const navigate = useNavigate();
  const createInstanceModal = useModal(MODAL_TYPE.INSTANCE_CREATE);
  const navigateToImport = () => {
    navigate({ pathname: `${importRoute ?? ''}` });
  };

  return (
    <>
      <div className="flex h-full flex-col items-center justify-center gap-16 md:flex-row">
        <img src={CloudGroup} alt="cloud with nodes" draggable="false" />
        <div className="flex max-w-[420px] flex-col gap-6">
          <h4>Create your first instance</h4>
          <Typography variant="body-large">
            Configure your instance and start realizing the endless possibilities with graphs.
          </Typography>
          <Tooltip type="simple" isDisabled={!projectSuspended && hasPermission}>
            <Tooltip.Trigger hasButtonWrapper>
              <Button
                onClick={() => createInstanceModal.open()}
                isDisabled={projectSuspended || !hasPermission}
                htmlAttributes={{
                  'data-testid': 'create-instance-button',
                }}
              >
                Create instance
              </Button>
            </Tooltip.Trigger>
            <Tooltip.Content>{tipMessage}</Tooltip.Content>
          </Tooltip>
          <Typography variant="body-small">
            Have data?{' '}
            <TextLink as="button" htmlAttributes={{ onClick: navigateToImport }} className="cursor-pointer">
              Import
            </TextLink>{' '}
            existing data into Neo4j.
          </Typography>
        </div>
      </div>
    </>
  );
};

type InstanceDisplayType = 'list' | 'table';

export const InstancesPage = () => {
  const trackEvent = useTrackUpxEvent();
  const activeProject = useActiveProject();
  const { isAllowed } = usePermissions();
  const allowCreateInstance = isAllowed(ACTION.CREATE, `namespaces/${activeProject.id}/databases`);
  const [instanceDisplayType, setInstanceDisplayType] = useSetting('console', 'instanceDisplay');
  const { instances, isInstancesLoading } = useInstances({ shouldPoll: true });

  const { data: usage } = consoleApi.useGetUsageQuery(
    { projectId: activeProject.id },
    {
      skip: activeProject.billingMethod !== PROJECT_BILLING_METHOD.PAYG,
    },
  );
  const [instanceDetails, setInstanceDetails] = useState<string | null>(null);

  const handleSetInstanceDisplayType = (displayType: InstanceDisplayType) => {
    setInstanceDisplayType(displayType);
    trackEvent({
      event: AURA_CONSOLE_EVENTS.INSTANCES_DISPLAY_MODE_CHANGE,
      properties: { displayMode: displayType },
      scope: APP_SCOPE.aura,
    });
  };

  const handleDisplayInstanceDetails = (instanceId: string) => {
    setInstanceDetails(instanceId);
    trackEvent({
      event: AURA_CONSOLE_EVENTS.INSTANCE_VIEW_DETAILS,
      scope: APP_SCOPE.aura,
    });
  };

  const handleCloseInstanceDetails = () => {
    resetUploadBackupState();
    setInstanceDetails(null);
  };

  const [filter, setFilter] = useState('');
  const createInstanceModal = useModal(MODAL_TYPE.INSTANCE_CREATE);

  let createDisableReason = '';

  useEffect(() => {
    trackEvent({
      event: AURA_CONSOLE_EVENTS.INSTANCE_PAGE_NAVIGATE,
      properties: {
        planType: activeProject.planType,
        projectType: activeProject.projectType,
        projectId: activeProject.id,
      },
      scope: APP_SCOPE.aura,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (activeProject.suspended) {
    createDisableReason = 'Creating a new instance in a suspended project is not permitted.';
  } else if (!allowCreateInstance) {
    createDisableReason = "You don't have permission to perform this action.";
  }

  if (isInstancesLoading) {
    return <LoadingSpinner size="large" className="relative left-1/2 top-1/2" />;
  }

  if (instances.length === 0) {
    return (
      <NewInstance
        hasPermission={allowCreateInstance}
        tipMessage={createDisableReason}
        projectSuspended={activeProject.suspended}
      />
    );
  }

  return (
    <div className="relative flex h-full flex-col">
      <div className="bg-neutral-bg-weak border-neutral-border-weak flex h-14 w-full items-center justify-between gap-4 border-b py-[10px]">
        <div className="ml-6 flex basis-[400px] gap-2">
          <SearchField
            className="min-w-36 grow"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            htmlAttributes={{ 'aria-label': 'Search for instances' }}
          />
        </div>
        <div className="mr-4 flex shrink-0 gap-4">
          <SegmentedControl
            onChange={(changedId) => handleSetInstanceDisplayType(changedId)}
            hasOnlyIcons
            size="small"
            selected={instanceDisplayType}
          >
            <SegmentedControl.Item
              value="table"
              htmlAttributes={{
                'aria-label': 'View instances in Table view',
              }}
            >
              <TableCellsIconOutline className="size-4" />
            </SegmentedControl.Item>
            <SegmentedControl.Item
              value="list"
              htmlAttributes={{
                'aria-label': 'View instances in List view',
              }}
            >
              <Squares2X2IconOutline className="size-4" />
            </SegmentedControl.Item>
          </SegmentedControl>
          <Tooltip type="simple" isDisabled={!activeProject.suspended && allowCreateInstance}>
            <Tooltip.Trigger hasButtonWrapper>
              <Button
                onClick={() => createInstanceModal.open()}
                isDisabled={activeProject.suspended || !allowCreateInstance}
                htmlAttributes={{
                  'data-testid': 'create-instance-button',
                }}
              >
                Create instance
              </Button>
            </Tooltip.Trigger>
            <Tooltip.Content>{createDisableReason}</Tooltip.Content>
          </Tooltip>
        </div>
      </div>
      {instanceDisplayType === 'list' ? (
        <div className="overflow-y-auto p-3">
          <InstanceList
            instances={instances}
            usage={usage}
            onDisplayInstanceDetails={handleDisplayInstanceDetails}
            filter={filter}
          />
        </div>
      ) : (
        <InstanceTable
          instances={instances}
          onDisplayInstanceDetails={handleDisplayInstanceDetails}
          filter={filter}
          setFilter={setFilter}
        />
      )}
      {isNotNullish(instanceDetails) && (
        <InstanceDetails instanceId={instanceDetails} onClose={handleCloseInstanceDetails} />
      )}
    </div>
  );
};
