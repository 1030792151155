import { store } from '../../store';
import * as fromSlice from './connections-slice';

export function runQuery(...args: Parameters<typeof fromSlice.runQuery>) {
  return store.dispatch(fromSlice.runQuery(...args));
}

export function updateDatabases(...args: Parameters<typeof fromSlice.updateDatabases>) {
  return store.dispatch(fromSlice.updateDatabases(...args));
}

export function switchDatabase(...args: Parameters<typeof fromSlice.switchedDatabase>) {
  return store.dispatch(fromSlice.switchedDatabase(...args));
}

export function provisionSso(...args: Parameters<typeof fromSlice.provisionedSso>) {
  return store.dispatch(fromSlice.provisionedSso(...args));
}

export function renameConnection(...args: Parameters<typeof fromSlice.renamed>) {
  return store.dispatch(fromSlice.renamed(...args));
}
