import type { ForceDirectedOptions, HierarchicalOptions, NvlOptions } from '@neo4j-nvl/base';
import { ForceDirectedLayoutType, HierarchicalLayoutType } from '@neo4j-nvl/base';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash-es';
import type { AnyAction } from 'redux';

import { FitZoomCeiling, FitZoomFloor } from '../../modules/Visualization/zoom';
import migrate from '../../services/migrate';
import { isFalsy } from '../../types/utility';
import { REHYDRATE } from '../persistence/constants';
import { CLEAR_SCENE } from '../rootActions';
import type { RootState } from '../types';
import { CoordinateLayout, DEFAULT_COORDINATE_X_SCALE, DEFAULT_COORDINATE_Y_SCALE } from './constants';
import migrations from './migrations';
import type {
  CoordinateLayoutOptions,
  FittingState,
  Layout,
  LayoutOptionsUpdate,
  ViewportState,
  VisualizationState,
  ZoomOptions,
} from './types';

export const NAME = 'visualization';

const initialFittingState: FittingState = {
  zoomedTo: [],
  zoomedToCounter: 0,
};

const initialViewportState: ViewportState = {
  zoomLevel: 0.75,
  panCoordinates: {
    panX: 0,
    panY: 0,
  },
};

export const initialState: VisualizationState = {
  ...initialFittingState,
  ...initialViewportState,
  showMinimap: false,
  layoutType: ForceDirectedLayoutType,
  layoutIsComputing: false,
  exportScreenshotCounter: 0,
  layoutOptions: {
    [ForceDirectedLayoutType]: {
      enableCytoscape: true,
      simulationStopVelocity: 100,
      gravity: 150,
    },
    [HierarchicalLayoutType]: { direction: 'down' },
    [CoordinateLayout]: {
      X: null,
      Y: null,
      XScale: DEFAULT_COORDINATE_X_SCALE,
      YScale: DEFAULT_COORDINATE_Y_SCALE,
    },
  },
};

/**
 * Selectors
 */
export const getVizState = (state: RootState): VisualizationState => state[NAME];

export const getLayoutType = (state: RootState): VisualizationState['layoutType'] => {
  return state[NAME].layoutType ?? initialState.layoutType;
};

export const getMinimapDisplayStatus = (state: RootState): VisualizationState['showMinimap'] =>
  state[NAME].showMinimap ?? initialState.showMinimap;

export const getLayoutComputing = (state: RootState): VisualizationState['layoutIsComputing'] =>
  state[NAME].layoutIsComputing ?? initialState.layoutIsComputing;

export const getZoomedTo = (state: RootState): VisualizationState['zoomedTo'] =>
  state[NAME].zoomedTo ?? initialState.zoomedTo;

export const getZoomLevel = (state: RootState): VisualizationState['zoomLevel'] =>
  state[NAME].zoomLevel ?? initialState.zoomLevel;

export const getPanCoordinates = (state: RootState): VisualizationState['panCoordinates'] =>
  state[NAME].panCoordinates ?? initialState.panCoordinates;

export const getZoomOptions = createSelector(
  (state: RootState) => state[NAME].zoomOptions,
  (zoomOptions) => zoomOptions ?? {},
);

export const getZoomedToCounter = (state: RootState): VisualizationState['zoomedToCounter'] =>
  state[NAME].zoomedToCounter ?? initialState.zoomedToCounter;

export const getExportScreenshotCounter = (state: RootState): VisualizationState['exportScreenshotCounter'] =>
  state[NAME].exportScreenshotCounter ?? initialState.exportScreenshotCounter;

export function getLayoutOptionsForLayoutType(
  state: RootState,
  layoutType: typeof ForceDirectedLayoutType,
): ForceDirectedOptions;
export function getLayoutOptionsForLayoutType(
  state: RootState,
  layoutType: typeof HierarchicalLayoutType,
): HierarchicalOptions;
export function getLayoutOptionsForLayoutType(
  state: RootState,
  layoutType: typeof CoordinateLayout,
): CoordinateLayoutOptions;
export function getLayoutOptionsForLayoutType(state: RootState, layoutType: Exclude<Layout, 'grid'>) {
  return !isFalsy(state[NAME].layoutOptions) && state[NAME].layoutOptions[layoutType];
}

export const getLayoutOptions = (state: RootState) => state[NAME].layoutOptions ?? initialState.layoutOptions;

export const getCoordinateOptions = (state: RootState) => state[NAME]?.layoutOptions[CoordinateLayout];

export const getNvlLayoutOptions = createSelector(
  (state: RootState) => getLayoutType(state),
  (state: RootState) => getLayoutOptions(state),
  (layoutType, layoutOptions): NvlOptions['layoutOptions'] => {
    if (layoutType === ForceDirectedLayoutType) {
      return layoutOptions[ForceDirectedLayoutType];
    }
    if (layoutType === HierarchicalLayoutType) {
      return layoutOptions[HierarchicalLayoutType];
    }
  },
);

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setPanCoordinates(state, action: PayloadAction<VisualizationState['panCoordinates']>) {
      state.panCoordinates = action.payload;
    },
    setZoomLevel(state, action: PayloadAction<VisualizationState['zoomLevel']>) {
      state.zoomLevel = action.payload;
    },
    zoomTo(state, action: PayloadAction<{ nodeIds: string[]; options?: ZoomOptions }>) {
      const { nodeIds, options } = action.payload;
      if (isFalsy(nodeIds) || nodeIds.length === 0) {
        return;
      }
      let counter = state.zoomedToCounter;

      // if we zoom to the same node(s) again, we increase the counter to
      // be able to detect that. Otherwise we reset it
      if (isEqual(nodeIds, state.zoomedTo)) {
        counter++;
      } else {
        counter = 0;
      }

      state.zoomedTo = nodeIds;
      state.zoomOptions = {
        minZoom: FitZoomFloor,
        maxZoom: FitZoomCeiling,
        ...options,
      };
      state.zoomedToCounter = counter;
    },
    setLayoutType(state, action: PayloadAction<VisualizationState['layoutType']>) {
      state.layoutType = action.payload ?? ForceDirectedLayoutType;
    },
    toggleMinimap(state) {
      state.showMinimap = !state.showMinimap;
    },
    setLayoutComputing(state, action: PayloadAction<VisualizationState['layoutIsComputing']>) {
      state.layoutIsComputing = action.payload;
    },
    exportScreenshot(state) {
      state.exportScreenshotCounter += 1;
    },
    setLayoutOptionsByLayoutType(state, action: PayloadAction<LayoutOptionsUpdate>) {
      const { type, options } = action.payload;
      // @ts-expect-error TypeScript is not able to infer 'options' type from 'type' name, even though the type is correct
      state.layoutOptions[type] = { ...state.layoutOptions[type], ...options };
    },
    setLayoutOptions(state, action: PayloadAction<VisualizationState['layoutOptions']>) {
      state.layoutOptions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action: AnyAction) => {
      const { payload } = action;

      if (isFalsy(action.payload) || isFalsy(action.payload[NAME])) {
        return state;
      }

      const migratedVisualization = migrate(
        payload[NAME],
        migrations(payload?.settings?.isCytoscapeEnabled),
        payload.version,
      );

      return {
        ...state,
        ...migratedVisualization,
      };
    });
    builder.addCase(CLEAR_SCENE, (state) => {
      return {
        ...state,
        ...initialViewportState,
        ...initialFittingState,
      };
    });
  },
});

export const {
  setPanCoordinates,
  setZoomLevel,
  zoomTo,
  setLayoutType,
  toggleMinimap,
  setLayoutComputing,
  exportScreenshot,
  setLayoutOptionsByLayoutType,
  setLayoutOptions,
} = slice.actions;
export default slice.reducer;
