import { Button, Dialog, TextLink, Typography } from '@neo4j-ndl/react';
import { useState } from 'react';

export const TestMigrationExpand = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleClick = () => setIsOpen((prev) => !prev);
  const handleClose = () => setIsOpen(false);
  return (
    <div>
      <Button size="medium" color="primary" onClick={handleClick}>
        Test Migration
      </Button>
      <Dialog size="large" isOpen={isOpen} onClose={handleClose}>
        <Dialog.Header>Next step: Testing the migration</Dialog.Header>
        <Dialog.Content>
          <div className="flex flex-col gap-3">
            <Typography variant="body-medium" className="mb-2">
              After you have implemented all the recommended fixes from the report on the previous page, you can now
              test the migration. To do that, please follow the steps outlined in the{' '}
              <TextLink isExternalLink href="https://neo4j.com/docs/aura/tutorials/upgrade/#_perform_the_upgrade">
                Docs
              </TextLink>{' '}
              to perform this manual migration.
            </Typography>
            <p className="mb-2">
              Additional capabilities for a more automatic migration process will be added here in the future.
            </p>
          </div>
        </Dialog.Content>
      </Dialog>
    </div>
  );
};
