import type { TimeRangeQueryLog, TimeRangeQueryLogAggregation } from '@nx/state';
import { QUERY_LOG_AGGREGATION_SORT_PROPERTY, QUERY_LOG_SORT_PROPERTY } from '@nx/state';
import type { ColumnDef } from '@tanstack/react-table';

import type { DropdownOptions } from '../../metrics/shared/types';

export type TabKey = 'SUMMARY' | 'DETAILS';
export type TabText = `${Capitalize<Lowercase<TabKey>>}`;
export type LogTabKey = 'QUERY' | 'SECURITY';
export type LogTabText = `${Capitalize<Lowercase<LogTabKey>>}`;
export const TAB_OPTIONS: { key: TabKey; text: TabText }[] = [
  {
    key: 'SUMMARY',
    text: 'Summary',
  },
  {
    key: 'DETAILS',
    text: 'Details',
  },
];
export const TAB_KEYS = TAB_OPTIONS.map(({ key }) => key);
export const DEFAULT_TAB: TabKey = 'SUMMARY';

export const LOG_TAB_OPTIONS: { key: LogTabKey; text: LogTabText }[] = [
  {
    key: 'QUERY',
    text: 'Query',
  },
  {
    key: 'SECURITY',
    text: 'Security',
  },
];

export const LOG_DEFAULT_TAB: LogTabKey = 'QUERY';

/**
 * https://tanstack.com/table/v8/docs/api/core/column-def#id
 */
export type ColumnId = string;

export const OPTIONS_LOG_TYPE: DropdownOptions = [
  { label: 'Query log', value: 'dbms-query-log' },
  // { label: "Security log", value: "dbms-security-log" },
  // { label: "Debug log", value: "dbms-debug-log" },
  // { label: "User log", value: "dbms-user-log" },
  // { label: "NOM server log", value: "nom-server-log" },
  // { label: "NOM agent log", value: "nom-agent-log" },
];

export const COLUMN_TO_QL_SORT: Record<ColumnId, QUERY_LOG_SORT_PROPERTY> = {
  allocatedBytes: QUERY_LOG_SORT_PROPERTY.ALLOCATED_BYTES,
  app: QUERY_LOG_SORT_PROPERTY.APP,
  database: QUERY_LOG_SORT_PROPERTY.DATABASE,
  driver: QUERY_LOG_SORT_PROPERTY.DRIVER,
  executionTimeMs: QUERY_LOG_SORT_PROPERTY.ELAPSED,
  initiationType: QUERY_LOG_SORT_PROPERTY.INITIATION_TYPE,
  pageFaults: QUERY_LOG_SORT_PROPERTY.PAGE_FAULTS,
  pageHits: QUERY_LOG_SORT_PROPERTY.PAGE_HITS,
  planningTimeMs: QUERY_LOG_SORT_PROPERTY.PLANNING_TIME,
  query: QUERY_LOG_SORT_PROPERTY.QUERY,
  status: QUERY_LOG_SORT_PROPERTY.STATUS,
  endTime: QUERY_LOG_SORT_PROPERTY.TIMESTAMP,
  user: QUERY_LOG_SORT_PROPERTY.USER,
  waitingTimeMs: QUERY_LOG_SORT_PROPERTY.WAITING_TIME,
  queryLanguage: QUERY_LOG_SORT_PROPERTY.QUERY_LANGUAGE,
};

export const COLUMN_TO_QLA_SORT: Record<ColumnId, QUERY_LOG_AGGREGATION_SORT_PROPERTY> = {
  avgAllocatedBytes: QUERY_LOG_AGGREGATION_SORT_PROPERTY.AVG_ALLOCATED_BYTES,
  avgCpuTimeMs: QUERY_LOG_AGGREGATION_SORT_PROPERTY.AVG_CPU_TIME,
  avgExecutionTimeMs: QUERY_LOG_AGGREGATION_SORT_PROPERTY.AVG_ELAPSED,
  avgPageFaults: QUERY_LOG_AGGREGATION_SORT_PROPERTY.AVG_PAGE_FAULTS,
  avgPageHits: QUERY_LOG_AGGREGATION_SORT_PROPERTY.AVG_PAGE_HITS,
  avgPlanningTimeMs: QUERY_LOG_AGGREGATION_SORT_PROPERTY.AVG_PLANNING_TIME,
  avgWaitingTimeMs: QUERY_LOG_AGGREGATION_SORT_PROPERTY.AVG_WAITING_TIME,
  executionCount: QUERY_LOG_AGGREGATION_SORT_PROPERTY.COUNT,
  totalTimeSpent: QUERY_LOG_AGGREGATION_SORT_PROPERTY.TOTAL_TIME_SPENT,
  maxExecutionTimeMs: QUERY_LOG_AGGREGATION_SORT_PROPERTY.MAX_ELAPSED,
  minExecutionTimeMs: QUERY_LOG_AGGREGATION_SORT_PROPERTY.MIN_ELAPSED,
  query: QUERY_LOG_AGGREGATION_SORT_PROPERTY.QUERY,
  status: QUERY_LOG_AGGREGATION_SORT_PROPERTY.STATUS,
  timestamp: QUERY_LOG_AGGREGATION_SORT_PROPERTY.TIMESTAMP,
  queryLanguage: QUERY_LOG_AGGREGATION_SORT_PROPERTY.QUERY_LANGUAGE,
};

export type TypedColumnDef<T extends TabText> = T extends 'Summary'
  ? ColumnDef<TimeRangeQueryLogAggregation>
  : ColumnDef<TimeRangeQueryLog>;

export const ACTIONS_COLUMN_ID = 'actions';
