import { createAsyncThunk } from '@reduxjs/toolkit';

import { STRUCTURED_SUGGESTION_RESULT_LIMIT } from '../../../../modules/SearchBar/SearchBar.const';
import type { Suggestion } from '../../../../modules/SearchBar/types';
import {
  selectCategoryDictionaries,
  selectCategoryPropertyKeys,
  selectRelationshipToPropertyDictionary,
} from '../../../perspectives/perspectives';
import type { RootState } from '../../../types';
import {
  isActionSuggestionLockedSelector,
  isSearchPhraseLockedSelector,
  selectTextIndexes,
} from '../../core/search-core.selectors';
import { NAME } from '../search-suggestions.const';
import { verifyPaths } from './structured-suggestions.query';
import type { PathEntryVerificationResult, SinglePathVerificationResult } from './structured-suggestions.types';
import {
  buildPathSuggestions,
  getPaths,
  partitionGraphEntity,
  partitionValue,
  sortPathsInLengthAscendingOrder,
} from './structured-suggestions.utils';

interface FetchStructuredSuggestionsPayload {
  inputText: string;
  isCaseInsensitive: boolean;
  pathEntryVerificationResultsCache: Map<string, PathEntryVerificationResult>;
  pathVerificationResultsCache: Map<string, SinglePathVerificationResult[]>;
}

type FetchedStructuredSuggestions = (Suggestion | Suggestion[])[];

export const fetchStructuredSuggestionsThunk = createAsyncThunk<
  FetchedStructuredSuggestions,
  FetchStructuredSuggestionsPayload,
  { state: RootState }
>(
  `${NAME}/fetchStructuredSuggestions`,
  async (
    {
      inputText,
      isCaseInsensitive,
      pathEntryVerificationResultsCache,
      pathVerificationResultsCache,
    }: FetchStructuredSuggestionsPayload,
    { getState, rejectWithValue, dispatch },
  ): Promise<FetchedStructuredSuggestions> => {
    const state = getState();
    const isSearchPhraseLocked = isSearchPhraseLockedSelector(state);
    const isActionLocked = isActionSuggestionLockedSelector(state);

    if (isSearchPhraseLocked || isActionLocked) {
      return [];
    }

    const cleanInput = inputText.trim().replace(/\s+/g, ' ');
    if (cleanInput.split(' ').length <= 1) {
      return [];
    }

    const labelPropertyKeys = selectCategoryPropertyKeys(state);
    const { categoryToPropertyDictionary, categoryToLabelDictionary } = selectCategoryDictionaries(state);
    const relationshipToPropertyDictionary = selectRelationshipToPropertyDictionary(state);
    const textIndexes = selectTextIndexes(state);

    const partitions = partitionGraphEntity(
      cleanInput,
      categoryToPropertyDictionary,
      relationshipToPropertyDictionary,
      isCaseInsensitive,
    );

    const subPartitions = partitions.map(partitionValue);
    const pathsToVerify = getPaths(...subPartitions);
    const sortedPaths = sortPathsInLengthAscendingOrder(pathsToVerify);

    try {
      const verifiedPaths = await verifyPaths(
        sortedPaths,
        textIndexes,
        STRUCTURED_SUGGESTION_RESULT_LIMIT,
        isCaseInsensitive,
        relationshipToPropertyDictionary,
        categoryToLabelDictionary,
        pathEntryVerificationResultsCache,
        pathVerificationResultsCache,
        labelPropertyKeys ?? {},
        dispatch,
      );

      const suggestions = buildPathSuggestions(verifiedPaths, categoryToLabelDictionary);
      return suggestions;
    } catch (error) {
      rejectWithValue(error);
    }
    return [];
  },
);
