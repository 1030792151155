import { Route, Routes } from 'react-router-dom';

import { Resources } from './resources';
import { Roles } from './roles';

export function RolesRoutes() {
  return (
    <Routes>
      <Route path=":roleId/resources" element={<Resources />} />
      <Route path="*" element={<Roles />} />
    </Routes>
  );
}
