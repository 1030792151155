import type { QueryResultWithLimit } from '@nx/constants';
import { APP_SCOPE, QUERY_TYPE } from '@nx/constants';
import { MINIMUM_SUPPORTED_VERSION, cypherDataToString, isCypherBasicPropertyType } from '@nx/neo4j-sdk';
import type { Database } from '@nx/state';
import {
  DriverConnectionError,
  NoDatabaseSelectedError,
  isDatabaseV4,
  isDatabaseV5,
  runQuery,
  selectDatabases,
  selectMetadata,
  LEGACY_store as store,
} from '@nx/state';
import { isNullish } from '@nx/stdlib';
import { SysInfoCmdErrors } from '@query/constants/errors';
import { registerAbortFunction, unregisterAbortFunction } from '@query/services/abortable-frame-service';
import {
  NON_TRACKABLE_COMMAND_SOURCES,
  trackFailedSysInfoCmd,
  trackSuccessfulSysInfoCmd,
} from '@query/services/analytics';
import { cacheCategoryMetrics } from '@query/services/frame-sysinfo-service';
import type { QueryError } from '@query/types/query';
import { isQueryError } from '@query/utils/error-utils';
import type { SysInfoMappedMetrics, SysInfoMetric, SysInfoMetricCategory } from '@query/utils/sysinfo-utils';
import {
  createClientConfig,
  formatBytes,
  formatMetricJmxQuery,
  formatPercentage,
  isUnsupportedVersion,
} from '@query/utils/sysinfo-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

import type { ExecuteCommandPayload } from './command-thunks';
import type { SysInfoRequestId } from './requests-slice';

const RESULT_LIMIT = 1000;

const metricCategories: SysInfoMetricCategory[] = [
  {
    key: 'id-allocation',
    category: 'ID Allocation',
    metrics: {
      'Property ID': { name: 'ids_in_use.property', displayName: 'Property ID', dbType: true },
      'Relationship ID': { name: 'ids_in_use.relationship', displayName: 'Relationship ID', dbType: true },
      'Relationship Type ID': {
        name: 'ids_in_use.relationship_type',
        displayName: 'Relationship Type ID',
        dbType: true,
      },
    },
  },
  {
    key: 'store-size',
    category: 'Store Size',
    metrics: {
      Total: {
        name: 'store.size.total',
        displayName: 'Total',
        dbType: true,
        mapFunction: (value) => formatBytes(value),
      },
      Database: {
        name: 'store.size.database',
        displayName: 'Database',
        dbType: true,
        mapFunction: (value) => formatBytes(value),
      },
    },
  },
  {
    key: 'page-cache',
    category: 'Page Cache',
    metrics: {
      Hits: { name: 'page_cache.hits', displayName: 'Hits', dbType: false },
      'Hit Ratio': {
        name: 'page_cache.hit_ratio',
        displayName: 'Hit Ratio',
        dbType: false,
        mapFunction: (value) => formatPercentage(value),
      },
      'Usage Ratio': {
        name: 'page_cache.usage_ratio',
        displayName: 'Usage Ratio',
        dbType: false,
        mapFunction: (value) => formatPercentage(value),
      },
      'Page Faults': { name: 'page_cache.page_faults', displayName: 'Page Faults', dbType: false },
    },
  },
  {
    key: 'transactions',
    category: 'Transactions',
    metrics: {
      'Last Tx Id': { name: 'transaction.last_committed_tx_id', displayName: 'Last Tx Id', dbType: true },
      'Current Read': { name: 'transaction.active_read', displayName: 'Current Read', dbType: true },
      'Current Write': { name: 'transaction.active_write', displayName: 'Current Write', dbType: true },
      'Peak Transactions': {
        name: 'transaction.peak_concurrent',
        displayName: 'Peak Transactions',
        dbType: true,
      },
      'Committed Read': { name: 'transaction.committed_read', displayName: 'Committed Read', dbType: true },
      'Committed Write': { name: 'transaction.committed_write', displayName: 'Committed Write', dbType: true },
    },
  },
];

const isSystemOrCompositeDb = (database: Database) => {
  if (isDatabaseV5(database)) {
    const dbType = database.type;
    return dbType === 'system' || dbType === 'composite' || database.name === 'system';
  } else if (isDatabaseV4(database)) {
    return database.name === 'system';
  }

  return false;
};

const queryAndCacheMetrics = async (frameId: string, requestId: SysInfoRequestId, database: string): Promise<void> => {
  const { configuration } = selectMetadata(store.getState());
  const clientConfig = createClientConfig(configuration?.serverMetricsPrefix, configuration?.namespacesEnabled);

  const processMetricCategory = async (category: SysInfoMetricCategory) => {
    const metricsQuery = runQuery({
      recordLimit: RESULT_LIMIT,
      parameters: {
        metrics: Object.values(category.metrics).map((metric) => ({
          name: formatMetricJmxQuery(clientConfig, metric, database),
          displayName: metric.displayName,
        })),
      },
      sessionConfig: { database },
      query: `
        UNWIND $metrics as metric
        CALL dbms.queryJmx(metric.name) YIELD name, attributes
        WITH metric.displayName AS Name, attributes.Value.value AS Value, attributes.Count.value AS Count
        RETURN Name, CASE WHEN Value IS NOT NULL then Value ELSE Count END AS Value;`,
      metadata: { appScope: APP_SCOPE.query, queryType: QUERY_TYPE.UserAction },
      transactionMode: 'read',
    });

    registerAbortFunction(requestId, metricsQuery.abort);

    const rawMetrics: QueryResultWithLimit = await metricsQuery.unwrap();
    const result: SysInfoMappedMetrics = {};

    rawMetrics.records.forEach((record) => {
      const name: unknown = record.get('Name');
      const key = isCypherBasicPropertyType(name) ? cypherDataToString(name, 'json', false) : '';
      const metric: SysInfoMetric | undefined = category.metrics[key];
      const mapFn = metric?.mapFunction ?? cypherDataToString;
      const value: unknown = record.get('Value');

      result[key] = isCypherBasicPropertyType(value) ? { value: mapFn(value) } : { value: '-' };
    });

    cacheCategoryMetrics(frameId, category.key, result);
  };

  await Promise.all(metricCategories.map(processMetricCategory));
};

export type ExecutedSysInfoCommandPayload = {
  frameId: string;
  requestId: SysInfoRequestId;
  command: ExecuteCommandPayload;
};

export type ExecutedSysInfoCommand = { requestId: SysInfoRequestId };

export const executeSysInfoCommandThunk = createAsyncThunk<
  ExecutedSysInfoCommand,
  ExecutedSysInfoCommandPayload,
  {
    rejectValue: { error: QueryError; aborted: boolean };
  }
>('stream/executeSysInfoCommand', async (payload: ExecutedSysInfoCommandPayload, { rejectWithValue }) => {
  const { requestId, command } = payload;
  const { connection, source } = command;

  if (isNullish(connection)) {
    const error = new DriverConnectionError();
    return rejectWithValue({
      error: { code: error.code, message: error.message },
      aborted: false,
    });
  }

  const { database, version } = connection;
  const databases = selectDatabases(store.getState());
  const selectedDatabase = databases.find((db) => db.name === database);

  try {
    if (isNullish(selectedDatabase)) {
      const error = new NoDatabaseSelectedError();
      return rejectWithValue({
        error: { code: error.code, message: error.message },
        aborted: false,
      });
    }

    if (isSystemOrCompositeDb(selectedDatabase)) {
      return rejectWithValue({
        error: SysInfoCmdErrors.CompositeDatabaseNotSupported(),
        aborted: false,
      });
    }

    if (isUnsupportedVersion(version)) {
      return rejectWithValue({
        error: SysInfoCmdErrors.UnsupportedVersion(MINIMUM_SUPPORTED_VERSION),
        aborted: false,
      });
    }

    await queryAndCacheMetrics(payload.frameId, requestId, selectedDatabase.name);

    if (!NON_TRACKABLE_COMMAND_SOURCES.includes(source)) {
      trackSuccessfulSysInfoCmd();
    }
  } catch (error) {
    if (isQueryError(error)) {
      trackFailedSysInfoCmd(error.code);
      return rejectWithValue({ error, aborted: error.name === 'AbortError' });
    }

    trackFailedSysInfoCmd();
    return rejectWithValue({ error: { code: 'Unknown', message: 'An unknown error occurred' }, aborted: false });
  } finally {
    unregisterAbortFunction(requestId);
  }

  return { requestId: payload.requestId };
});
