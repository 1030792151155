import type { TimeRangeSecurityLog, TimeRangeSecurityLogSummary } from '@nx/state';
import { SECURITY_LOG_SORT_PROPERTY, SECURITY_LOG_SUMMARY_SORT_PROPERTY } from '@nx/state';
import type { ColumnDef } from '@tanstack/react-table';

import type { DropdownOptions } from '../../../metrics/shared/types';
import type { TabText } from '../../shared/types';

/**
 * https://tanstack.com/table/v8/docs/api/core/column-def#id
 */
export type ColumnId = string;

export const OPTIONS_LOG_TYPE: DropdownOptions = [
  { label: 'Security log', value: 'dbms-security-log' },
  // { label: "Debug log", value: "dbms-debug-log" },
  // { label: "User log", value: "dbms-user-log" },
  // { label: "NOM server log", value: "nom-server-log" },
  // { label: "NOM agent log", value: "nom-agent-log" },
];

export const COLUMN_TO_SL_SORT: Record<ColumnId, SECURITY_LOG_SORT_PROPERTY> = {
  authenticatedUser: SECURITY_LOG_SORT_PROPERTY.AUTHENTICATED_USER,
  database: SECURITY_LOG_SORT_PROPERTY.DATABASE,
  driver: SECURITY_LOG_SORT_PROPERTY.DRIVER,
  message: SECURITY_LOG_SORT_PROPERTY.MESSAGE,
  status: SECURITY_LOG_SORT_PROPERTY.STATUS,
  time: SECURITY_LOG_SORT_PROPERTY.TIMESTAMP,
  executingUser: SECURITY_LOG_SORT_PROPERTY.EXECUTING_USER,
};

export const COLUMN_TO_SLS_SORT: Record<ColumnId, SECURITY_LOG_SUMMARY_SORT_PROPERTY> = {
  logCount: SECURITY_LOG_SUMMARY_SORT_PROPERTY.LOG_COUNT,
  executingUser: SECURITY_LOG_SUMMARY_SORT_PROPERTY.EXECUTING_USER,
  authenticatedUsers: SECURITY_LOG_SUMMARY_SORT_PROPERTY.AUTHENTICATED_USERS,
  message: SECURITY_LOG_SUMMARY_SORT_PROPERTY.MESSAGE,
  status: SECURITY_LOG_SUMMARY_SORT_PROPERTY.STATUS,
  timestamp: SECURITY_LOG_SUMMARY_SORT_PROPERTY.TIMESTAMP,
};

export type TypedColumnDef<T extends TabText> = T extends 'Summary'
  ? ColumnDef<TimeRangeSecurityLogSummary>
  : ColumnDef<TimeRangeSecurityLog>;
