import { Banner, Button, Dialog, Radio, TextInput } from '@neo4j-ndl/react';
import type { CreateLogForwarding, Destination, Instance } from '@nx/state';
import { CLOUD_PROVIDER, LOG_TYPE, TIER, useInstances } from '@nx/state';
import { isNonEmptyString, isNotNullish, isNullish } from '@nx/stdlib';
import React, { useEffect, useState } from 'react';

import type { InnerCreateDialogProps, OuterCreateDialogProps } from '../helpers/props';
import { getTiers } from '../helpers/utils';
import { useTierAndRegionOptions } from '../hooks/use-tier-and-region-options';
import { DisclaimerDialogStep } from '../shared/disclaimer-dialog-step';
import { InstanceDropdown } from '../shared/instance-dropdown';
import { RegionDropdown } from '../shared/region-dropdown';
import { TierDropdown } from '../shared/tier-dropdown';
import { CloudwatchCreationSteps } from './cloudwatch-creation-steps';
import { LogAnalyticsCreationSteps } from './log-analytics-creation-steps';
import { StackdriverCreationSteps } from './stackdriver-creation-steps';

// The following constant defines the maximum allowed number of log fwd to be created per tier/region
export const MAX_LOG_FWD_PROCESSES = 1;

const getCloudSpecificDialogContent = (cloudProvider: CLOUD_PROVIDER, innerProps: InnerCreateDialogProps) => {
  switch (cloudProvider) {
    case CLOUD_PROVIDER.GCP:
      return <StackdriverCreationSteps {...innerProps} />;
    case CLOUD_PROVIDER.AZURE:
      return <LogAnalyticsCreationSteps {...innerProps} />;
    case CLOUD_PROVIDER.AWS:
      return <CloudwatchCreationSteps {...innerProps} />;
    default:
      return <span>invalid cloud provider</span>;
  }
};

export const CreateConfigModal = ({
  project,
  cloudProviders,
  open,
  onClose,
  hasReachedProcessLimit,
  onCreate,
  loading,
  logForwardingIdentities,
  error,
}: OuterCreateDialogProps) => {
  const [step, setStep] = useState(-1);

  const [name, setName] = useState('');
  const [nameValidationError, setNameValidationError] = useState<string | null>();
  const validateName = (s: string): string | null => {
    if (!isNonEmptyString(s)) {
      return 'Choose a name.';
    }
    return null;
  };
  const handleNameBlur = () => {
    setName(name.trim());
    setNameValidationError(validateName(name));
  };
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setNameValidationError(validateName(name));
  };

  const [region, setRegion] = useState<string>();
  const availableTiers = getTiers(project.tierConfigs);
  const [tier, setTier] = useState<TIER>(availableTiers[0] ?? TIER.FREE);
  const { instances } = useInstances({ shouldPoll: false });
  const [instance, setInstance] = useState<Instance | null>(null);

  useEffect(() => {
    if (instance !== null) {
      setRegion(instance.region);
      setTier(instance.tier);
      setName(instance.name);
    }
  }, [instance]);

  const validFirstStep = isNotNullish(region) && isNonEmptyString(name);

  const handleCreate = (destination: Destination) => {
    if (isNullish(region)) {
      return;
    }
    const payload: CreateLogForwarding = {
      destination: destination,
      logType: LOG_TYPE.SECURITY,
      name: name,
      region: region,
      tier: tier,
      db_id: instance !== null ? instance.id : undefined,
    };
    onCreate(payload);
  };

  // eslint-disable-next-line prefer-destructuring
  let cloudProvider = cloudProviders[0];
  if (instance !== null) {
    cloudProvider = instance.cloudProvider;
  }

  if (cloudProvider === undefined) {
    cloudProvider = CLOUD_PROVIDER.GCP;
  }

  const { tierOptions, regionOptions } = useTierAndRegionOptions(instances, tier, cloudProvider, project.tierConfigs);

  return (
    <Dialog isOpen={open} onClose={onClose}>
      <Dialog.Header>New configuration</Dialog.Header>
      {step === -1 && <DisclaimerDialogStep onAccept={() => setStep(0)} onDecline={onClose} />}
      {step === 0 && (
        <>
          <Dialog.Content className="space-y-6">
            {project.capabilities.log_forwarding && !project.capabilities.single_instance_log_forwarding && (
              <TextInput
                label="Name"
                value={name}
                onChange={handleNameChange}
                isFluid
                errorText={nameValidationError}
                htmlAttributes={{
                  placeholder: 'Pick any name',
                  'data-testid': 'log-forwarding-name-input',
                  onBlur: handleNameBlur,
                }}
              />
            )}
            <div>
              <p className="mb-2">Logs to forward:</p>
              <Radio
                isChecked
                label="Security logs"
                htmlAttributes={{
                  readOnly: true,
                }}
              />
            </div>
            {isNotNullish(region) && hasReachedProcessLimit(region, tier, instance?.id) && (
              <Banner
                hasIcon
                type="warning"
                description="The selected product and region already has a log forwarding configuration"
                usage="inline"
              />
            )}

            {project.capabilities.log_forwarding && !project.capabilities.single_instance_log_forwarding && (
              <>
                <TierDropdown tierOptions={tierOptions} tier={tier} setTier={setTier} />
                <RegionDropdown regionOptions={regionOptions} region={region} setRegion={setRegion} />
              </>
            )}

            {project.capabilities.single_instance_log_forwarding && !project.capabilities.log_forwarding && (
              <InstanceDropdown instances={instances} selectedInstance={instance} setSelectedInstance={setInstance} />
            )}
          </Dialog.Content>
          <Dialog.Actions>
            <Button fill="outlined" color="neutral" onClick={onClose}>
              Cancel
            </Button>
            <Button
              isDisabled={!validFirstStep || hasReachedProcessLimit(region, tier, instance?.id)}
              color="primary"
              onClick={() => {
                setStep(step + 1);
              }}
              htmlAttributes={{
                'data-testid': 'log-forwarding-next-step',
              }}
            >
              Next
            </Button>
          </Dialog.Actions>
        </>
      )}
      {step > 0 &&
        getCloudSpecificDialogContent(cloudProvider, {
          project,
          open,
          onClose,
          onCreate: handleCreate,
          loading,
          logForwardingIdentities,
          error,
          region: region ?? '',
          tier,
          step,
          setStep,
        })}
    </Dialog>
  );
};
