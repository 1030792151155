import { Banner, Button, Dialog, TextInput, Tooltip } from '@neo4j-ndl/react';
import { AURA_CONSOLE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { type Instance, consoleApi, getApiError, getErrorMessage } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { SyntheticEvent } from 'react';
import { useEffect, useMemo, useState } from 'react';

import { useTrackUpxEvent } from '../../services/segment/analytics';

const logger = createLogger(APP_SCOPE.framework);

interface Props {
  onClose: () => void;
  onConfirm: () => void;
  instance: Instance;
}

export const DeleteInstanceModal = ({ onClose, onConfirm, instance }: Props) => {
  const trackEvent = useTrackUpxEvent();
  const [confirmText, setConfirmText] = useState('');
  const [tipOpen, setTipOpen] = useState(false);
  const [destroy, destroyRes] = consoleApi.useDestroyInstanceMutation();
  const { name } = instance;
  const handleConfirmTextChange = (value: string) => {
    setConfirmText(value);
  };

  const errorMessage = useMemo(() => {
    if (!destroyRes.isError) {
      return null;
    }
    const error = getApiError(destroyRes.error);
    const message = getErrorMessage(error);
    return message;
  }, [destroyRes.error, destroyRes.isError]);

  const handleClose = () => {
    onClose();
    setConfirmText('');
  };

  const handleDelete = (event: SyntheticEvent) => {
    event.preventDefault();
    destroy(instance.id)
      .unwrap()
      .then(() => {
        onConfirm();
      })
      .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(e);
        if (isNotNullish(error.message)) {
          logger.error(error.message);
        }
      });

    trackEvent({
      event: AURA_CONSOLE_EVENTS.INSTANCE_DESTROY,
      properties: { tier: instance.tier },
      scope: APP_SCOPE.aura,
    });
  };

  useEffect(() => {
    if (tipOpen) {
      setTimeout(() => {
        setTipOpen(false);
      }, 2000);
    }
  }, [tipOpen]);

  return (
    <Dialog type="danger" isOpen onClose={handleClose} size="large">
      <Dialog.Header>
        Delete {/* TODO: @team-console should placement be left or right */}
        <Tooltip isOpen={tipOpen} type="simple" placement="right" isPortaled={false}>
          <Tooltip.Trigger
            className="cursor-text"
            htmlAttributes={{
              onClick: () => {
                void navigator.clipboard.writeText(instance.name);
                if (!tipOpen) {
                  setTipOpen(true);
                }
              },
              'aria-label': 'Copy text',
            }}
          >
            {instance.name}
          </Tooltip.Trigger>
          <Tooltip.Content className="flex items-center">Copied to clipboard</Tooltip.Content>
        </Tooltip>{' '}
      </Dialog.Header>
      <Dialog.Subtitle>
        This action is irreversible. We will delete your instance and all associated snapshots.
      </Dialog.Subtitle>
      <form onSubmit={handleDelete}>
        <Dialog.Description>
          <div className="flex flex-col gap-4">
            <TextInput
              value={confirmText}
              onChange={(e) => handleConfirmTextChange(e.target.value)}
              label="Type the name of the instance below to confirm"
              htmlAttributes={{
                autoFocus: true,
                placeholder: 'E.g. InstanceName',
              }}
            />
            {isNotNullish(errorMessage) && <Banner type="danger" description={errorMessage} usage="inline" />}
          </div>
        </Dialog.Description>
        <Dialog.Actions>
          <Button color="neutral" fill="outlined" onClick={handleClose} isDisabled={destroyRes.isLoading}>
            Cancel
          </Button>
          <Button color="danger" type="submit" isDisabled={confirmText !== name} isLoading={destroyRes.isLoading}>
            Delete
          </Button>
        </Dialog.Actions>
      </form>
    </Dialog>
  );
};
