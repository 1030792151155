import type { TextInputProps } from '@neo4j-ndl/react';
import { LoadingSpinner, TextInput } from '@neo4j-ndl/react';
import { MagnifyingGlassIconOutline } from '@neo4j-ndl/react/icons';

export const SearchField = ({
  value,
  onChange,
  htmlAttributes,
  isLoading,
  ...props
}: TextInputProps & { isLoading?: boolean }) => {
  return (
    <TextInput
      isFluid
      leftElement={
        <span className="flex w-5 justify-center">
          {isLoading === true ? <LoadingSpinner /> : <MagnifyingGlassIconOutline />}
        </span>
      }
      value={value}
      onChange={onChange}
      htmlAttributes={{
        type: 'search',
        placeholder: 'Search',
        'aria-label': 'Search',
        ...htmlAttributes,
      }}
      {...props}
    />
  );
};
