import { Banner, Button, Dialog, TextLink, Typography, Wizard } from '@neo4j-ndl/react';
import { AURA_CONSOLE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { getFlag } from '@nx/launch-darkly-service';
import { createLogger } from '@nx/logger';
import type { CreateInstanceRequest, Instance, Project, TierConfig, UpgradeInstanceConfigRequest } from '@nx/state';
import {
  MODAL_TYPE,
  NEO4J_MANAGED_KEY,
  PLAN_TYPE,
  TIER,
  consoleApi,
  getApiError,
  getApiErrorMessage,
  useActiveOrg,
  useModal,
  useTrackEvent,
} from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Suspense, lazy, useMemo, useState } from 'react';

import { StripeFormFallback } from '../../loading-fallbacks';
import type { Validation } from '../../utils/validation';
import { isValid } from '../../utils/validation';
import CreateInstanceFormWrapper from '../create/form';
import {
  enterpriseDefaults,
  gbStringToInt,
  getDefaultName,
  getStepNumber,
  getStepTitle,
  getValidSize,
  isUpgrade,
  mteDefaults,
  preventFormSubmission,
  proDefaults,
  tierConfigForClone,
  validate,
} from '../entities/helpers';
import type { InstanceFormData } from '../entities/model';
import { CREATE_STEP } from '../entities/model';
import { EnterpriseTierInfo, FreeTierSelector, MTETierSelector, ProfessionalTierSelector } from '../tier-selector';
import UpgradeInstanceFormWrapper from './form';

const StripeForm = lazy(() => import('../../project/billing/shared/stripe-form'));

const logger = createLogger(APP_SCOPE.framework);

export const defaults = (
  instance: Instance,
  targetTier: TIER,
  tierConfig: TierConfig,
  project: Project,
): InstanceFormData => {
  const defaultName = getDefaultName(instance.name, 'Upgrade');

  let data: InstanceFormData;

  switch (targetTier) {
    case TIER.FREE:
    case TIER.GDS:
    case TIER.PROFESSIONAL:
      data = {
        ...proDefaults(tierConfig, [], project, false),
        name: defaultName,
        cloudProvider: instance.cloudProvider,
        region: instance.region,
      };
      break;
    case TIER.MTE:
      data = {
        ...mteDefaults(tierConfig, [], project),
        name: instance.name,
        cloudProvider: instance.cloudProvider,
        region: instance.region,
      };
      break;
    case TIER.AURA_DSE:
    case TIER.ENTERPRISE:
      data = {
        ...enterpriseDefaults(tierConfig, project),
        name: defaultName,
        cloudProvider: instance.cloudProvider,
        region: instance.region,
        encryptionKeyRef: instance.encryptionKeyRef ?? NEO4J_MANAGED_KEY,
      };
      break;
    default:
      throw new Error('Tier not supported');
  }

  const size = getValidSize(data, project, tierConfig.sizes, {
    resizeThreshold: isNotNullish(instance.desiredSettings.storage)
      ? gbStringToInt(instance.desiredSettings.storage)
      : undefined,
    isTrial: false,
    defaultMemory: instance.desiredSettings.memory,
  });
  return { ...data, size };
};

type CloneToNewModalProps = {
  instance: Instance;
  project: Project;
  onClose: () => void;
  onConfirm: () => void;
};

export const UpgradeInstanceModal = ({ instance, onClose, project }: CloneToNewModalProps) => {
  const trackEvent = useTrackEvent();
  const { tierConfigs, requiresBilling, hasBilling, planType } = project;
  const defaultTierConfig = tierConfigs[instance.tier];
  const freeCrossCspCloning = getFlag('freeCrossCspCloning', false);
  const { featureToggles } = useActiveOrg();
  const isTierUpgradeEnabled = featureToggles['console-tier-upgrade'];
  if (isNullish(defaultTierConfig)) {
    throw new Error(`TierConfig for ${instance.tier} is missing.`);
  }
  const createdInstanceModal = useModal(MODAL_TYPE.INSTANCE_CREATED);

  const [createInstance, createInstanceRes] = consoleApi.useCreateInstanceMutation();
  const [upgradeInstance, upgradeInstanceRes] = consoleApi.useUpgradeInstanceMutation();

  const isSelfServe = planType === PLAN_TYPE.SELF_SERVE;
  const showBilling = requiresBilling && !hasBilling;

  let expectedSteps: CREATE_STEP[] = [CREATE_STEP.INSTANCE_CONFIGURATION];
  if (showBilling) {
    expectedSteps = [CREATE_STEP.TIER_SELECTION, CREATE_STEP.INSTANCE_CONFIGURATION, CREATE_STEP.BILLING_SETUP];
  } else if (isSelfServe) {
    expectedSteps = [CREATE_STEP.TIER_SELECTION, CREATE_STEP.INSTANCE_CONFIGURATION];
  }

  const [activeStep, setActiveStep] = useState<CREATE_STEP>(
    isSelfServe ? CREATE_STEP.TIER_SELECTION : CREATE_STEP.INSTANCE_CONFIGURATION,
  );

  const [data, setData] = useState<InstanceFormData>(() =>
    defaults(instance, instance.tier, defaultTierConfig, project),
  );
  const [validation, setValidation] = useState<Validation<InstanceFormData> | null>(null);

  const isSubmitDisabled = useMemo(() => {
    return preventFormSubmission(data, project, validation);
  }, [data, project, validation]);

  const errorMessage = useMemo(() => {
    if (!createInstanceRes.isError && !upgradeInstanceRes.isError) {
      return null;
    }
    const createMessage = getApiErrorMessage(createInstanceRes.error);
    const upgradeMessage = getApiErrorMessage(upgradeInstanceRes.error);
    return createMessage || upgradeMessage;
  }, [createInstanceRes.error, createInstanceRes.isError, upgradeInstanceRes.error, upgradeInstanceRes.isError]);

  const realTierUpgrade = (oldInstance: Instance, newTier: TIER) => {
    if (newTier === TIER.MTE && oldInstance.capabilities.tier_upgrade.enabled) {
      return true;
    }
    return false;
  };

  const handleCreate = (formData: InstanceFormData | null) => {
    if (isNullish(formData)) {
      return;
    }
    const validationError = validate(formData);
    if (!isValid(formData, validationError)) {
      setValidation(validationError);
      return;
    }
    setValidation(null);

    if (realTierUpgrade(instance, data.tier)) {
      const payloadData: UpgradeInstanceConfigRequest = {
        dbId: instance.id,
        memory: formData.size.memory,
        storage: formData.size.storage,
      };
      upgradeInstance(payloadData)
        .unwrap()
        .then((result) => {
          trackEvent({ event: AURA_CONSOLE_EVENTS.INSTANCE_UPGRADE, scope: APP_SCOPE.aura });
          onClose();
        })
        .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
          const error = getApiError(e);
          if (isNotNullish(error.message)) {
            logger.error(error.message);
          }
        });
    } else {
      const payloadData: CreateInstanceRequest = {
        tier: formData.tier,
        region: formData.region,
        version: formData.version,
        cloudProvider: formData.cloudProvider,
        name: formData.name,
        memory: formData.size.memory,
        storage: formData.size.storage,
        projectId: project.id,
        sourceSnapshot: {
          dbId: instance.id,
        },
        ...(project.capabilities.cmek &&
          instance.tier === TIER.ENTERPRISE && {
            encryptionKeyRef: formData.encryptionKeyRef === NEO4J_MANAGED_KEY ? undefined : formData.encryptionKeyRef,
          }),
        isTrial: false,
      };
      createInstance(payloadData)
        .unwrap()
        .then((result) => {
          const event = {
            tier: payloadData.tier,
          };
          trackEvent({ event: AURA_CONSOLE_EVENTS.INSTANCE_CLONE_TO_NEW, properties: event, scope: APP_SCOPE.aura });
          onClose();
          createdInstanceModal.open({ createdInstance: result });
        })
        .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
          const error = getApiError(e);
          if (isNotNullish(error.message)) {
            logger.error(error.message);
          }
        });
    }
  };

  const getFinalButtonText = () => {
    const stepNumber = getStepNumber(activeStep, expectedSteps);

    if (stepNumber === expectedSteps.length) {
      if (realTierUpgrade(instance, data.tier)) {
        return 'Upgrade';
      }
      return 'Create';
    }
    return 'Next';
  };

  const handleDataChange = (newData: InstanceFormData) => {
    if (isNotNullish(validation)) {
      setValidation(null);
    }
    setData((oldData) => ({ ...oldData, ...newData }));
  };

  const proceedToNextStep = () => {
    const nextStep = expectedSteps[expectedSteps.indexOf(activeStep) + 1];
    if (isNotNullish(nextStep)) {
      setActiveStep(nextStep);
    }
  };

  const goBackToPreviousStep = () => {
    const previousStep = expectedSteps[expectedSteps.indexOf(activeStep) - 1];
    if (isNotNullish(previousStep)) {
      setActiveStep(previousStep);
    }
  };

  const handleSelectTier = (tier: TIER) => {
    setValidation(null);
    const tierConfig = tierConfigs[tier]!;
    setData({ ...defaults(instance, tier, tierConfig, project) });
    proceedToNextStep();
  };

  return (
    <Dialog
      isOpen
      onClose={onClose}
      size="unset"
      modalProps={{ className: 'max-w-[1500px]', 'data-testid': 'upgrade-modal' }}
    >
      <Dialog.Header>
        <div className="flex flex-row items-center gap-7">
          Upgrade instance
          {activeStep === CREATE_STEP.TIER_SELECTION && (
            <Typography variant="body-medium">
              <TextLink href="https://neo4j.com/pricing/" className="no-underline" isExternalLink>
                See full comparison
              </TextLink>
            </Typography>
          )}
        </div>
      </Dialog.Header>
      {activeStep !== CREATE_STEP.TIER_SELECTION &&
        getStepNumber(activeStep, expectedSteps) > 0 &&
        expectedSteps.length > 2 && (
          <div className="mx-auto mb-8 flex w-4/5">
            <Wizard
              className="w-full"
              orientation="horizontal"
              steps={expectedSteps.map((step) => ({
                content: (
                  <div key={`${getStepNumber(step, expectedSteps)}`} className="flex text-nowrap">
                    {getStepTitle(step)}
                  </div>
                ),
              }))}
              activeStepIndex={getStepNumber(activeStep, expectedSteps) - 1}
            />
          </div>
        )}
      {activeStep === CREATE_STEP.TIER_SELECTION && (
        <Dialog.Content>
          <div className="flex flex-col gap-8">
            <div className="flex justify-between gap-8">
              <FreeTierSelector
                project={project}
                isLoading={createInstanceRes.isLoading || upgradeInstanceRes.isLoading}
                disabledReason="You can not downgrade to a Free instance."
                isDisabled
              />
              <ProfessionalTierSelector
                project={project}
                isDisabled={!isUpgrade(instance.tier, TIER.PROFESSIONAL) || isNullish(tierConfigs.professional)}
                onSelect={() => handleSelectTier(TIER.PROFESSIONAL)}
                disabledReason={
                  isNotNullish(tierConfigs.professional)
                    ? 'You can not downgrade to a Professional instance.'
                    : 'This option is not available.'
                }
              />

              {project.capabilities.multi_tenant_enterprise && isNotNullish(tierConfigs.mte) ? (
                <MTETierSelector project={project} onSelect={() => handleSelectTier(TIER.MTE)} />
              ) : (
                <EnterpriseTierInfo project={project} />
              )}
            </div>
            {project.capabilities.multi_tenant_enterprise && isNotNullish(tierConfigs.mte) && (
              <EnterpriseTierInfo project={project} isSummary />
            )}
          </div>
        </Dialog.Content>
      )}
      {activeStep === CREATE_STEP.INSTANCE_CONFIGURATION && (
        <>
          <Dialog.Content>
            {realTierUpgrade(instance, data.tier) && isTierUpgradeEnabled ? (
              <UpgradeInstanceFormWrapper
                tierConfig={tierConfigForClone(tierConfigs, instance, data.tier, freeCrossCspCloning) ?? undefined}
                data={data}
                onDataChange={handleDataChange}
                validation={validation}
                project={project}
                instance={instance}
              />
            ) : (
              <CreateInstanceFormWrapper
                tierConfig={tierConfigForClone(tierConfigs, instance, data.tier, freeCrossCspCloning) ?? undefined}
                data={data}
                onDataChange={handleDataChange}
                validation={validation}
                project={project}
              />
            )}
            {isNotNullish(errorMessage) && <Banner type="danger" description={errorMessage} usage="inline" />}
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              fill="outlined"
              color="neutral"
              onClick={getStepNumber(activeStep, expectedSteps) === 1 ? onClose : goBackToPreviousStep}
              isDisabled={createInstanceRes.isLoading || upgradeInstanceRes.isLoading}
            >
              {getStepNumber(activeStep, expectedSteps) === 1 ? 'Cancel' : 'Back'}
            </Button>
            <Button
              onClick={
                getStepNumber(activeStep, expectedSteps) === expectedSteps.length
                  ? () => handleCreate(data)
                  : proceedToNextStep
              }
              isDisabled={isSubmitDisabled}
              isLoading={createInstanceRes.isLoading || upgradeInstanceRes.isLoading}
            >
              {getFinalButtonText()}
            </Button>
          </Dialog.Actions>
        </>
      )}
      {activeStep === CREATE_STEP.BILLING_SETUP && (
        <Dialog.Content>
          <div className="mx-auto max-w-[50%]">
            <Suspense fallback={<StripeFormFallback />}>
              <StripeForm
                onCancel={() => setActiveStep(CREATE_STEP.INSTANCE_CONFIGURATION)}
                onSuccess={() => handleCreate(data)}
                cancelButtonText="Back"
                submitButtonText="Create"
                isLoading={createInstanceRes.isLoading || upgradeInstanceRes.isLoading}
              />
            </Suspense>
          </div>
        </Dialog.Content>
      )}
    </Dialog>
  );
};
