import { Banner, DataGridComponents, IconButton, Label, StatusIndicator, Tooltip, Typography } from '@neo4j-ndl/react';
import { ArrowDownTrayIconOutline } from '@neo4j-ndl/react/icons';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { type Log, consoleApi, getApiError, getErrorMessage } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { DataGridHelpers, PermissionTooltip } from '@nx/ui';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { createColumnHelper, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import { formatDuration } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';

import { getStatusColour, getStatusValue } from './entities/helpers';

type LogsTableProps = {
  logs: Log[];
  isLoading: boolean;
  isError: boolean;
  allowDownload: boolean;
};

const logger = createLogger(APP_SCOPE.framework);

const helper = createColumnHelper<Log>();

const NoDataPlaceholder = () => (
  <DataGridComponents.NoDataPlaceholder>
    <DataGridComponents.NoDataPlaceholderContentWrapper>
      <DataGridComponents.NoDataIllustration />
      <Typography variant="h6">No logs available</Typography>
    </DataGridComponents.NoDataPlaceholderContentWrapper>
  </DataGridComponents.NoDataPlaceholder>
);

export const LogTable = ({ logs, isLoading, isError, allowDownload }: LogsTableProps) => {
  const [getLogDownloadLink, getLogDownloadLinkRes] = consoleApi.useLazyGetLogDownloadLinkQuery();
  const [activeLogId, setActiveLogId] = useState<string | null>(null);

  const errorMessage = useMemo(() => {
    if (!getLogDownloadLinkRes.isError) {
      return null;
    }
    const error = getApiError(getLogDownloadLinkRes.error);
    const message = getErrorMessage(error);
    return message;
  }, [getLogDownloadLinkRes.error, getLogDownloadLinkRes.isError]);

  const onDownloadClick = useCallback(
    (fileName: string, logId: string, dbId: string) => {
      setActiveLogId(logId);
      getLogDownloadLink({ logId, dbId })
        .unwrap()
        .then((res) => {
          const link = document.createElement('a');
          link.href = res.url;
          link.target = '_blank';
          link.download = fileName;
          link.click();
          link.remove();
        })
        .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
          const error = getApiError(e);
          if (isNotNullish(error.message)) {
            logger.error(error.message);
          }
        })
        .finally(() => setActiveLogId(null));
    },
    [getLogDownloadLink],
  );

  const columns = useMemo(
    () => [
      helper.accessor('start', {
        header: 'Log Start',
        minSize: 150,
      }),
      helper.accessor('type', {
        header: 'Type',
        minSize: 125,
      }),
      helper.accessor('status', {
        header: 'Status',
        minSize: 140,
        cell: (cx) => {
          return (
            <Typography as="div" variant="label">
              <StatusIndicator type={getStatusColour(cx.getValue())} />
              <span>{getStatusValue(cx.getValue())}</span>
            </Typography>
          );
        },
      }),
      helper.accessor('timePeriod', {
        header: 'Time Period',
        cell: (cx) => {
          return (
            <Label className="time-period" color="default" fill="outlined">
              {cx.getValue()}
            </Label>
          );
        },
        minSize: 130,
      }),
      helper.accessor('expiresIn', {
        header: 'Expires In',
        cell: (cx) => {
          const expiresIn = cx.getValue();
          const shortExpiry = expiresIn.days === 0 ? '< 1 day' : formatDuration(expiresIn, { format: ['days'] });
          const fullExpiry = formatDuration(expiresIn, { format: ['days', 'hours', 'minutes'] });
          // TODO: @team-console should placement be top or bottom
          return (
            <Tooltip placement="top" type="simple">
              <Tooltip.Trigger>
                <span aria-label={`Log expires in ${fullExpiry}`}>{shortExpiry}</span>
              </Tooltip.Trigger>
              <Tooltip.Content>{fullExpiry}</Tooltip.Content>
            </Tooltip>
          );
        },
      }),
      helper.display({
        id: 'logs-actions',
        cell: (cx) => {
          const log: Log = cx.row.original;
          if (!log.downloadLog) {
            return null;
          }
          const { dbid, id, start } = log;
          const filename = `query-logs-${dbid}-${start.replace(':', '').replace(' ', '_')}.log`;

          return (
            <div className="flex flex-grow justify-end">
              <PermissionTooltip hasPermission={allowDownload} hasButtonWrapper>
                <IconButton
                  className="download-logs"
                  ariaLabel="Download logs"
                  htmlAttributes={{
                    title: 'Download logs',
                    'data-testid': 'download-logs',
                  }}
                  onClick={() => onDownloadClick(filename, id, dbid)}
                  isClean
                  isLoading={getLogDownloadLinkRes.isLoading && activeLogId === id}
                >
                  <ArrowDownTrayIconOutline />
                </IconButton>
              </PermissionTooltip>
            </div>
          );
        },
        size: 90,
        maxSize: 90,
      }),
    ],
    [activeLogId, allowDownload, getLogDownloadLinkRes.isLoading, onDownloadClick],
  );
  const logsTable = useReactTable({
    data: logs,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      columnPinning: {
        right: ['log-actions'],
      },
    },
  });

  return (
    <div>
      {!isError ? (
        <DataGridHelpers.DataGridRightColumnPinned<Log>
          tableInstance={logsTable}
          isLoading={isLoading}
          isResizable={false}
          styling={{ headerStyle: 'clean' }}
          components={{
            NoDataPlaceholder,
          }}
        />
      ) : (
        <Banner
          type="danger"
          description="Something went wrong while fetching logs. Please try again."
          usage="inline"
        />
      )}
      {getLogDownloadLinkRes.isError && (
        <Banner className="m-4" type="danger" description={errorMessage} usage="inline" />
      )}
    </div>
  );
};
