import { CLOUD_PROVIDER, type Region, TIER, type TierConfig, type TierConfigs } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';

export const getProductFromTier = (tier: TIER): 'AuraDB' | 'AuraDS' => {
  switch (tier) {
    case TIER.AURA_DSE:
    case TIER.GDS:
      return 'AuraDS';
    case TIER.ENTERPRISE:
    case TIER.FREE:
    case TIER.PROFESSIONAL:
      return 'AuraDB';
    default:
      throw new Error(`Tier ${tier} does not belong to a product`);
  }
};

export const getCloudProviders = (tier: TierConfigs): CLOUD_PROVIDER[] => {
  const cloudProviders = new Set<string>();
  Object.values(tier).forEach((tierConfig: TierConfig) => {
    Object.keys(tierConfig.cloudProviderRegions).forEach((cloudProvider) => cloudProviders.add(cloudProvider));
  });
  return Object.values(CLOUD_PROVIDER).filter((c) => cloudProviders.has(c));
};

export const getRegionsForAllCsps = (tier: TierConfigs): Region[] => {
  const availableRegionsSet = new Set<Region>();
  Object.values(tier).forEach((tierConfig: TierConfig) => {
    Object.values(tierConfig.cloudProviderRegions).forEach((regions: Region[]) => {
      regions.forEach((region) => availableRegionsSet.add(region));
    });
  });
  return Array.from(availableRegionsSet);
};

export const getRegionsForTier = (tier: TIER, tierConfigs: TierConfigs): Region[] => {
  const csps = tierConfigs[tier]?.cloudProviderRegions;
  return isNotNullish(csps) ? Object.values(csps).flat() : [];
};
