import type { ROLE_TYPE } from './model';

export const getFriendlyRoleType = (roleType: ROLE_TYPE) =>
  (
    ({
      ORG: 'Organization',
      PROJECT: 'Project',
      DB: 'Database',
    }) satisfies Record<ROLE_TYPE, string>
  )[roleType] || 'Unknown role type';
