import type { RouterState } from '@nx/constants';
import { useUnsafeAppContext } from '@nx/state';
import type { AvailableDbmsFilters, TimeRange } from '@nx/state';
import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const useNavigateToLogsAction = () => {
  const appContext = useUnsafeAppContext();
  const navigate = useNavigate();
  const [urlSearchParams] = useSearchParams();
  const navigateToLogsAction = useCallback(
    ({
      timeRange,
      dbmsFilters,
      querySearchString,
      requestLogsOnLoad,
    }: {
      timeRange: TimeRange;
      dbmsFilters?: AvailableDbmsFilters;
      querySearchString?: string;
      requestLogsOnLoad?: boolean;
    }) => {
      const state: RouterState = {
        opsLogs: { timeRange, dbmsFilters, querySearchString, requestLogsOnLoad },
      };

      navigate(`/projects/${appContext.activeProjectId}/logs?${urlSearchParams.toString()}`, {
        state,
      });
    },
    [appContext.activeProjectId, navigate, urlSearchParams],
  );

  return { navigateToLogsAction };
};
