import type { ProjectSummary } from '@nx/state';
import { isNonEmptyString } from '@nx/stdlib';

const getDomainSuffix = (environment: string | undefined) => {
  if (isNonEmptyString(environment)) {
    if (environment === 'production' || environment === 'staging') {
      return '.neo4j.io';
    }
    return '.neo4j-dev.io';
  }
  return '';
};

const getHostnameSuffix = (environment: string | undefined) => {
  if (isNonEmptyString(environment)) {
    if (environment === 'production') {
      return '';
    }
    return `-${environment}`;
  }
  return '';
};

export const formatAuraApiDomain = (environment: string | undefined) =>
  `api${getHostnameSuffix(environment)}${getDomainSuffix(environment)}`;

export const formatCmiDomain = (environment: string | undefined) =>
  `customer-metrics-api${getHostnameSuffix(environment)}${getDomainSuffix(environment)}`;

export function getMetricsPathParam(activeProject: ProjectSummary | undefined, dbId: string | null) {
  return dbId !== null && dbId !== '' ? `${activeProject?.id}/${dbId}` : activeProject?.id;
}
