import type { Logger } from '@nx/logger';
import {
  type CreateDataApiAuthProviderRequest,
  type CreateDataApiAuthProviderResponse,
  type CreateDataApiResponse,
  DATA_API_AUTH_PROVIDER_TYPE,
  type DataApiBatchResult,
  type DataApiModify,
  type DeleteDataApiAuthProviderRequest,
  type DeleteDataApiAuthProviderResponse,
  type DynamicBaseQueryFn,
  type Project,
  type UpdateDataApiAuthProviderRequest,
  type UpdateDataApiAuthProviderResponse,
  type UpdateDataApiRequest,
  type UpdateDataApiResponse,
} from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError, MutationActionCreatorResult, MutationDefinition } from '@reduxjs/toolkit/query';

import type { CredentialsModalData, OriginalDataApiModify } from '../types';
import { isAuthProviderModified, isDataApiModified } from '../utils/utils';

enum REQUEST_TYPE {
  updateDataApi = 'updateDataApi',
  createAuthProvider = 'createAuthProvider',
  updateAuthProvider = 'updateAuthProvider',
  deleteAuthProvider = 'deleteAuthProvider',
}

type RequestResult =
  | {
      type: REQUEST_TYPE.updateDataApi;
      result: UpdateDataApiResponse;
    }
  | {
      type: REQUEST_TYPE.createAuthProvider;
      result: CreateDataApiAuthProviderResponse;
    }
  | {
      type: REQUEST_TYPE.updateAuthProvider;
      result: UpdateDataApiAuthProviderResponse;
    }
  | {
      type: REQUEST_TYPE.deleteAuthProvider;
      result: DeleteDataApiAuthProviderResponse;
    };

interface HandleDataApiUpdateArgs {
  activeProject: Project;
  activeModifyDataApi: DataApiBatchResult;
  originalDataApi: OriginalDataApiModify | null;
  data: DataApiModify;
  logger: Logger;
  updateDataApi: (
    arg: UpdateDataApiRequest,
  ) => MutationActionCreatorResult<
    MutationDefinition<UpdateDataApiRequest, DynamicBaseQueryFn, 'GraphQL', UpdateDataApiResponse, 'graphqlApi'>
  >;
  createAuthProvider: (
    arg: CreateDataApiAuthProviderRequest,
  ) => MutationActionCreatorResult<
    MutationDefinition<
      CreateDataApiAuthProviderRequest,
      DynamicBaseQueryFn,
      'GraphQL',
      CreateDataApiAuthProviderResponse,
      'graphqlApi'
    >
  >;
  updateAuthProvider: (
    arg: UpdateDataApiAuthProviderRequest,
  ) => MutationActionCreatorResult<
    MutationDefinition<
      UpdateDataApiAuthProviderRequest,
      DynamicBaseQueryFn,
      'GraphQL',
      UpdateDataApiAuthProviderResponse,
      'graphqlApi'
    >
  >;
  deleteAuthProvider: (
    arg: DeleteDataApiAuthProviderRequest,
  ) => MutationActionCreatorResult<
    MutationDefinition<
      DeleteDataApiAuthProviderRequest,
      DynamicBaseQueryFn,
      'GraphQL',
      DeleteDataApiAuthProviderResponse,
      'graphqlApi'
    >
  >;
  showCredentialsModal: (data: CredentialsModalData | null) => void;
  onFinished: () => void;
}

export const handleDataApiUpdate = ({
  activeProject,
  activeModifyDataApi,
  originalDataApi,
  data,
  logger,
  updateDataApi,
  createAuthProvider,
  updateAuthProvider,
  deleteAuthProvider,
  showCredentialsModal,
  onFinished,
}: HandleDataApiUpdateArgs): void => {
  const currentAuthProviders = data.authProviders;
  const currentAuthProviderIds = currentAuthProviders.map((provider) => provider.id);
  const toDeleteAuthProviders = originalDataApi?.authProviders.filter(
    (authProvider) => !currentAuthProviderIds.includes(authProvider.id),
  );
  const promises: Promise<RequestResult>[] = [];

  const isModifiedDataApi = isDataApiModified(originalDataApi, data);
  if (isModifiedDataApi) {
    promises.push(
      new Promise<RequestResult>((resolve, reject) => {
        updateDataApi({
          projectId: activeProject.id,
          dataApiId: activeModifyDataApi.id,
          ...data,
        })
          .unwrap()
          .then((result) => resolve({ result, type: REQUEST_TYPE.updateDataApi }))
          .catch((err: FetchBaseQueryError | SerializedError | undefined) => reject(err));
      }),
    );
  }

  currentAuthProviders.forEach((authProvider) => {
    const authProviderId = authProvider.id;
    if (isNotNullish(authProviderId)) {
      // Auth provider exists already, if has changes, update it
      const isModified = isAuthProviderModified(originalDataApi, authProvider);
      if (isModified) {
        promises.push(
          new Promise<RequestResult>((resolve, reject) => {
            updateAuthProvider({
              projectId: activeProject.id,
              instanceId: data.instanceId,
              dataApiId: activeModifyDataApi.id,
              authProviderId,
              name: authProvider.name,
              enabled: authProvider.enabled,
              url: authProvider.url,
            })
              .unwrap()
              .then((result) => resolve({ result, type: REQUEST_TYPE.updateAuthProvider }))
              .catch((err: FetchBaseQueryError | SerializedError | undefined) => reject(err));
          }),
        );
      }
    } else {
      // Auth provider does not exist, create it
      promises.push(
        new Promise<RequestResult>((resolve, reject) => {
          createAuthProvider({
            projectId: activeProject.id,
            instanceId: data.instanceId,
            dataApiId: activeModifyDataApi.id,
            name: authProvider.name,
            enabled: authProvider.enabled,
            url: authProvider.url,
            type: authProvider.type,
          })
            .unwrap()
            .then((result) => resolve({ result, type: REQUEST_TYPE.createAuthProvider }))
            .catch((err: FetchBaseQueryError | SerializedError | undefined) => reject(err));
        }),
      );
    }
  });

  if (isNotNullish(toDeleteAuthProviders) && toDeleteAuthProviders.length > 0) {
    toDeleteAuthProviders.forEach((authProvider) => {
      promises.push(
        new Promise<RequestResult>((resolve, reject) => {
          deleteAuthProvider({
            projectId: activeProject.id,
            instanceId: data.instanceId,
            dataApiId: activeModifyDataApi.id,
            authProviderId: authProvider.id,
          })
            .unwrap()
            .then((result) => resolve({ result, type: REQUEST_TYPE.deleteAuthProvider }))
            .catch((err: FetchBaseQueryError | SerializedError | undefined) => reject(err));
        }),
      );
    });
  }

  Promise.all(promises)
    .then((values) => {
      const createAuthProviderResults = values.filter((value) => value.type === REQUEST_TYPE.createAuthProvider);
      const createdAuthProviders = createAuthProviderResults.map((res) => res.result.data);

      const hasAuthProvidersWithApiKey = createdAuthProviders.some(
        (provider) => provider.type === DATA_API_AUTH_PROVIDER_TYPE.apiKey,
      );
      if (hasAuthProvidersWithApiKey) {
        const credentialsModalData: CredentialsModalData = {
          id: activeModifyDataApi.id,
          name: activeModifyDataApi.name,
          url: activeModifyDataApi.url,
          authProviders: createdAuthProviders,
        };
        showCredentialsModal(credentialsModalData);
      }

      logger.info({ values });
      onFinished();
    })
    .catch((err: FetchBaseQueryError | SerializedError | undefined) => {
      logger.error(err);
      onFinished();
    });
};

export const transformDataApiCreateResponse = (response: CreateDataApiResponse): CredentialsModalData => ({
  id: response.data.id,
  name: response.data.name,
  url: response.data.url,
  authProviders: response.data.authentication_providers,
});
