import type { Instance, Region, TierConfig, TierConfigs } from '@nx/state';
import { CLOUD_PROVIDER, TIER } from '@nx/state';

export const regionNameWithInstanceCount = (region: string, instances: Instance[]): string => {
  const unit = instances.length === 1 ? 'instance' : 'instances';
  return `${region} - ${instances.length} ${unit}`;
};

export const getCloudProviders = (tier: TierConfigs): CLOUD_PROVIDER[] => {
  const cloudProviders = new Set<string>();
  Object.values(tier)
    .filter((tc) => [TIER.ENTERPRISE, TIER.AURA_DSE, TIER.MTE].includes(tc.tier))
    .forEach((tierConfig: TierConfig) => {
      Object.keys(tierConfig.cloudProviderRegions).forEach((cloudProvider) => cloudProviders.add(cloudProvider));
    });
  return Object.values(CLOUD_PROVIDER).filter((c) => cloudProviders.has(c));
};

export const getTiers = (tiers: TierConfigs): TIER[] => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return Object.keys(tiers) as TIER[];
};

export const getProductFromTier = (tier: TIER): string => {
  switch (tier) {
    case TIER.AURA_DSE:
    case TIER.GDS:
      return 'AuraDS';
    case TIER.ENTERPRISE:
    case TIER.FREE:
    case TIER.PROFESSIONAL:
    case TIER.MTE:
      return 'AuraDB';
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Tier ${tier} does not belong to a product`);
  }
};

export const getRegionsForTier = (tierConfigs: TierConfigs, tier: TIER, cloudProvider: CLOUD_PROVIDER): Region[] => {
  return Object.values(tierConfigs).flatMap((tierConfig) => {
    if (tierConfig.tier === tier) {
      const regions = tierConfig.cloudProviderRegions[cloudProvider];
      if (regions !== undefined) {
        return regions;
      }
    }
    return [];
  });
};
