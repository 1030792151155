import { tokens } from '@neo4j-ndl/base';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { Functions, isNonEmptyString } from '@nx/stdlib';
import { useMemo, useState } from 'react';
import type uPlot from 'uplot';

import { calculateTimeRangeLabelFromMs } from '../../shared/ui-helpers';

const logger = createLogger(APP_SCOPE.framework);

const LOG_ENABLED = true;
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
const log = LOG_ENABLED && import.meta.env.DEV ? logger.info : Functions.noop;

enum SELECTION_DIRECTION {
  LEFT_TO_RIGHT = 1,
  RIGHT_TO_LEFT = -1,
}

type TimeRangeSelectionPluginOptions = {
  durationThreshold: {
    // Extend with additional time units (e.g., minutes, days) as required
    hours: number;
  };
  onSelectionExceedsThreshold: (u: uPlot, direction: SELECTION_DIRECTION) => void;
  onSelectionBelowThreshold: (u: uPlot) => void;
  extraHooks?: Omit<uPlot.Plugin['hooks'], 'init' | 'setCursor'>;
};

export function createTimeRangeSelectionPlugin({
  durationThreshold: { hours },
  onSelectionExceedsThreshold,
  onSelectionBelowThreshold,
  extraHooks,
}: TimeRangeSelectionPluginOptions): uPlot.Plugin {
  const thresholdMs = hours * 60 * 60 * 1000;
  let startCursor: { left: number; xVal: number } | undefined;
  let thresholdReached = false;

  const handleMouseDown = (u: uPlot) => {
    if (typeof u.cursor.left === 'number' && isNonEmptyString(u.series[0]?.scale)) {
      startCursor = {
        left: u.cursor.left,
        xVal: Math.floor(u.posToVal(u.cursor.left, u.series[0].scale)),
      };
    }
    log('Mouse down at', startCursor);
  };

  const handleMouseUp = (u: uPlot) => {
    startCursor = undefined;
    thresholdReached = false;
    onSelectionBelowThreshold(u);
    log('Mouse up, reset startCursorLeft', startCursor);
  };

  const setupClickHandlers = (u: uPlot) => {
    u.over.addEventListener('mousedown', () => {
      handleMouseDown(u);
      /**
       * Add mouseup listener to the document instead of the plot area.
       * This ensures we capture the event even if the cursor
       * is released outside the plot boundaries.
       */
      document.addEventListener('mouseup', () => handleMouseUp(u), { once: true });
    });
  };

  const handleCursorMove = (u: uPlot) => {
    if (!(startCursor && typeof u.cursor.left === 'number' && isNonEmptyString(u.series[0]?.scale))) {
      return;
    }

    const currentCursorXVal = Math.floor(u.posToVal(u.cursor.left, u.series[0].scale));
    const diff = currentCursorXVal - startCursor.xVal;
    const absDiff = Math.abs(diff);
    const direction = diff >= 0 ? SELECTION_DIRECTION.LEFT_TO_RIGHT : SELECTION_DIRECTION.RIGHT_TO_LEFT;
    if (u.cursor.left < 0) {
      /**
       * Cursor is outside of plot area (cursor.left and cursor.top set to -10).
       * We ignore this case until cursor returns to plot area.
       */
    } else if (absDiff >= thresholdMs && !thresholdReached) {
      thresholdReached = true;
      log('threshold exceeded', startCursor);
      onSelectionExceedsThreshold(u, direction);
    } else if (absDiff < thresholdMs && thresholdReached) {
      thresholdReached = false;
      onSelectionBelowThreshold(u);
      log('threshold restored', startCursor);
    }
  };

  return {
    hooks: {
      init: setupClickHandlers,
      setCursor: handleCursorMove,
      ...extraHooks,
    },
  };
}

export const useTimeRangeSelectionPlugin = ({ hours }: TimeRangeSelectionPluginOptions['durationThreshold']) => {
  const [isOverThreshold, setIsOverThreshold] = useState(false);
  const plugin = useMemo(
    () =>
      createTimeRangeSelectionPlugin({
        durationThreshold: {
          hours,
        },
        onSelectionExceedsThreshold: () => setIsOverThreshold(true),
        onSelectionBelowThreshold: () => setIsOverThreshold(false),
      }),
    [hours],
  );

  return { plugin, isOverThreshold };
};

export const verticalLineTimeRangeSelectionPlugin = ({
  durationThreshold,
  lineWidth = 1,
  lineStroke = tokens.colors.hibiscus[45],
}: Pick<TimeRangeSelectionPluginOptions, 'durationThreshold'> & {
  lineWidth?: number;
  /**
   * Line color, can be a static value or a function.
   * If function, it's called with (uPlot, seriesIndex=0).
   * Defaults to Needle's `tokens.colors.hibiscus[45]`
   */
  lineStroke?: uPlot.Series.Stroke;
}) => {
  let x: number | null = null;
  let y1: number | null = null;
  let y2: number | null = null;
  let direction: SELECTION_DIRECTION | null = null;

  return createTimeRangeSelectionPlugin({
    durationThreshold,
    onSelectionExceedsThreshold: (u, dir) => {
      direction = dir;
      const cursorLeft = u.bbox.left + (u.cursor.left ?? 0) * window.devicePixelRatio;
      const { top, height } = u.bbox;
      x = cursorLeft;
      y1 = top;
      y2 = top + height;
      u.redraw();
    },
    onSelectionBelowThreshold: (u) => {
      x = null;
      y1 = null;
      y2 = null;
      u.redraw();
    },
    extraHooks: {
      draw: (u) => {
        if (x !== null && y1 !== null && y2 !== null) {
          const { left, top, width, height } = u.bbox;
          const color = typeof lineStroke === 'function' ? lineStroke(u, 0) : lineStroke;
          const fontSize = tokens.font.size['subheading-large'];
          const fontFamily = tokens.font['font-family'].code;
          let textAlign: CanvasTextAlign = 'right';
          let textDX = -6;
          const textDY = 6;
          if (direction === SELECTION_DIRECTION.RIGHT_TO_LEFT) {
            textAlign = 'left';
            textDX *= -1;
          }

          u.ctx.save();
          // Set clipping region to restrict drawing within specified boundaries
          u.ctx.beginPath();
          u.ctx.rect(left, top, width, height);
          u.ctx.clip();
          // Configure stroke style and render vertical threshold line
          u.ctx.strokeStyle = color;
          u.ctx.lineWidth = lineWidth;
          u.ctx.beginPath();
          u.ctx.moveTo(x, y1);
          u.ctx.lineTo(x, y2);
          u.ctx.stroke();
          // Configure text alignment, style, and render threshold label
          u.ctx.textAlign = textAlign;
          u.ctx.textBaseline = 'top';
          u.ctx.fillStyle = color;
          u.ctx.font = `${fontSize} "${fontFamily}", sans-serif`;
          u.ctx.fillText(
            calculateTimeRangeLabelFromMs(durationThreshold.hours * 60 * 60 * 1000, true),
            x + textDX,
            y1 + textDY,
          );
          u.ctx.restore();
        }
      },
    },
  });
};
