import type { DataApiModify, ModifyDataApiAuthProvider } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import deepEqual from 'fast-deep-equal';

export const isAuthProviderModified = (
  originalData: DataApiModify | null,
  updated: ModifyDataApiAuthProvider,
): boolean => {
  const original = originalData?.authProviders.find((provider) => provider.id === updated.id);
  if (!original) {
    return false;
  }

  if (original.name !== updated.name) {
    return true;
  }
  if (original.enabled !== updated.enabled) {
    return true;
  }
  if (isNotNullish(original.url) && original.url !== updated.url) {
    return true;
  }
  return false;
};

export const isDataApiModified = (original: DataApiModify | null, updated: DataApiModify): boolean => {
  if (!original) {
    return false;
  }

  if (original.name !== updated.name) {
    return true;
  }
  if (updated.instancePassword) {
    return true;
  }
  if (updated.instanceUsername) {
    return true;
  }
  if (original.typeDefinitions !== updated.typeDefinitions) {
    return true;
  }
  if (original.featuresSubgraph !== updated.featuresSubgraph) {
    return true;
  }
  if (!deepEqual(original.allowedOrigins, updated.allowedOrigins)) {
    return true;
  }
  return false;
};
