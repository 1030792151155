import { isNonEmptyString } from '@nx/stdlib';
import type { QueryError } from '@query/types/query';

import { formatDescriptionFromGqlStatusDescription, formatTitleFromGqlStatusDescription } from './gql-status-utils';

export function isQueryError(object: unknown): object is QueryError {
  if (object !== null && typeof object === 'object') {
    return 'name' in object || 'code' in object || 'gqlStatus' in object;
  }

  return false;
}

export type FormattedError = {
  title?: string;
  description?: string;
  innerError?: QueryError;
};

const mapQueryErrorToFormattedError = (error: QueryError): FormattedError => {
  const gqlStatusTitle = formatTitleFromGqlStatusDescription(error.gqlStatusDescription);
  const { gqlStatus } = error;
  const description = formatDescriptionFromGqlStatusDescription(error.gqlStatusDescription);
  const title = isNonEmptyString(gqlStatusTitle) ? gqlStatusTitle : description;
  return {
    title: isNonEmptyString(title) ? `${gqlStatus}: ${title}` : gqlStatus,
    description,
  };
};

export const formatErrorGqlStatusObject = (error: QueryError): FormattedError => {
  return {
    ...mapQueryErrorToFormattedError(error),
    innerError: error.cause,
  };
};

export const formatError = (error: QueryError): FormattedError => {
  const { code: title, message: description } = error;

  return {
    title,
    description,
  };
};
