import type { EventTypeLabelProps } from '@neo4j-ndl/react';
import { format, formatDistanceStrict, formatDistanceToNowStrict, formatDuration, intervalToDuration } from 'date-fns';

import type {
  LogDownloadInfo,
  LogsDownloadsResponse,
  QueryLogFilterValues,
  SecurityLogFilterValues,
} from '../api-types';
import { DOWNLOADS_LOG_TYPE, DOWNLOAD_JOB_STATUS, LOG_SUB_TYPE } from '../api-types';
import { capitalizeFirstLowerRest } from '../lib/utils';
import type { AvailableDbmsFilters } from '../types';
import type { AvailableSecurityLogFilters } from '../types/security-logs';

const DATE_FORMAT = 'yyyy/MM/dd HH:mm:ss';

const logTypeMap = {
  [DOWNLOADS_LOG_TYPE.QUERY]: 'Query',
  [DOWNLOADS_LOG_TYPE.SECURITY]: 'Security',
};

const logSubTypeMap = {
  [LOG_SUB_TYPE.SUMMARY]: 'Summary',
  [LOG_SUB_TYPE.DETAILS]: 'Details',
};

type StatusFormatted = { text: string; color: EventTypeLabelProps['color'] };
const statusMap: Record<DOWNLOAD_JOB_STATUS, StatusFormatted> = {
  [DOWNLOAD_JOB_STATUS.RUNNING]: { text: 'Preparing', color: 'warning' },
  [DOWNLOAD_JOB_STATUS.DONE]: { text: 'Ready', color: 'success' },
  [DOWNLOAD_JOB_STATUS.FAILED]: { text: 'Failed', color: 'danger' },
  [DOWNLOAD_JOB_STATUS.UNKNOWN]: { text: 'Unknown', color: 'default' },
};

export type TransformedLogsDownloadsResponse = (LogDownloadInfo & {
  requestTimestampFormatted: {
    friendly: string;
    absolute: string;
  };
  logTypeFormatted: string[];
  timePeriodFormatted: {
    interval: string;
    duration: string;
    durationFriendly: string;
  };
  statusFormatted: StatusFormatted;
  isRunning: boolean;
  isReady: boolean;
})[];

const formatTimePeriod = (from: number, to: number) => {
  const [start, end] = [new Date(from), new Date(to)];
  return {
    interval: `${format(start, DATE_FORMAT)} - ${format(end, DATE_FORMAT)}`,
    duration: formatDuration(intervalToDuration({ start: start, end: end })),
    durationFriendly: formatDistanceStrict(start, end),
  };
};

export function transformQueryLogsDownloadsResponse(response: LogsDownloadsResponse): TransformedLogsDownloadsResponse {
  return response.map((item) => {
    const requestDate = new Date(item.requestTimestamp);

    const transformedItem = {
      ...item,
      requestTimestampFormatted: {
        absolute: format(requestDate, DATE_FORMAT),
        friendly: formatDistanceToNowStrict(requestDate, { addSuffix: true }),
      },
      statusFormatted: statusMap[item.status],
      isRunning: item.status === DOWNLOAD_JOB_STATUS.RUNNING,
      isReady: item.status === DOWNLOAD_JOB_STATUS.DONE,
    };

    switch (item.metaFileContent.logType) {
      case DOWNLOADS_LOG_TYPE.QUERY: {
        return {
          ...transformedItem,
          logTypeFormatted: [logTypeMap[item.metaFileContent.logType], logSubTypeMap[item.metaFileContent.logSubType]],
          timePeriodFormatted: formatTimePeriod(item.metaFileContent.filters.from, item.metaFileContent.filters.to),
        };
      }
      case DOWNLOADS_LOG_TYPE.SECURITY: {
        return {
          ...transformedItem,
          logTypeFormatted: [logTypeMap[item.metaFileContent.logType], logSubTypeMap[item.metaFileContent.logSubType]],
          timePeriodFormatted: formatTimePeriod(item.metaFileContent.filters.from, item.metaFileContent.filters.to),
        };
      }
      default:
        throw new Error(`Unexpected log type`);
    }
  });
}

export function transformQueryLogFilterValuesResponse(res: QueryLogFilterValues): AvailableDbmsFilters {
  return {
    databases: [...(res.databases ?? [])].sort(),
    users: [...(res.users ?? [])].sort(),
    drivers: [...(res.drivers ?? [])].sort(),
    apps: [...(res.apps ?? [])].sort(),
    initiationTypes: [...(res.initiationTypes ?? [])].sort(),
    statuses: [...(res.statuses ?? [])].map((status) => capitalizeFirstLowerRest(status)).sort(),
    queryLanguages: [...(res.queryLanguages ?? [])].sort(),
    gqlStatuses: [...(res.gqlStatuses ?? [])].sort(),
  };
}

export function transformSecurityLogFilterValuesResponse(res: SecurityLogFilterValues): AvailableSecurityLogFilters {
  return {
    databases: [...(res.databases ?? [])].sort(),
    executingUsers: [...(res.executingUsers ?? [])].sort(),
    drivers: [...(res.drivers ?? [])].sort(),
    authenticatedUsers: [...(res.authenticatedUsers ?? [])].sort(),
    statuses: [...(res.statuses ?? [])].map((status) => capitalizeFirstLowerRest(status)).sort(),
  };
}
