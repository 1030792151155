import type { formatters, model } from '@neo4j/graph-schema-utils';

export type DataSourceLocation = null | 'cloud' | 'local';

export type ForeignKey = { referencedTable: string; fields: { field: string; referencedField: string }[] };

export type GraphSchemaExtensionsRepresentationJsonStruct = {
  nodeKeyProperties: NodeKeyPropertyJsonStruct[];
};

export type NodeKeyPropertyJsonStruct = {
  node: { $ref: string };
  keyProperty: { $ref: string };
};

export type TableSchemaLocalFieldJsonStruct = {
  name: string;
  type: model.PrimitivePropertyTypes;
  sample: string;
};

export type TableSchemaCloudFieldJsonStruct = {
  name: string;
  rawType: string;
  recommendedType?: { type: model.PrimitivePropertyTypes };
  supportedTypes?: { type: model.PrimitivePropertyTypes }[];
};

export type TableSchemaFieldJsonStruct = TableSchemaLocalFieldJsonStruct | TableSchemaCloudFieldJsonStruct;

export const isTableSchemaLocalFieldJsonStruct = (
  schema: TableSchemaFieldJsonStruct,
): schema is TableSchemaLocalFieldJsonStruct => {
  return 'sample' in schema && 'type' in schema;
};

export type TableSchemaJsonStruct = {
  name: string;
  expanded: boolean;
  fields: TableSchemaFieldJsonStruct[];
  primaryKeys?: string[];
  foreignKeys?: ForeignKey[];
};

export type DataSourceSchemaJsonStruct = {
  type: DataSourceLocation;
  tableSchemas: TableSchemaJsonStruct[];
};

export type MappingFilterJsonStruct = {
  fieldName: string;
  exactMatch: string;
};

export type PropertyMappingJsonStruct = { property: { $ref: string }; fieldName: string };

export type NodeMappingJsonStruct = {
  node: { $ref: string };
  tableName: string;
  propertyMappings: PropertyMappingJsonStruct[];
  mappingFilter?: MappingFilterJsonStruct;
};

export type RelationshipMappingJsonStruct = {
  relationship: { $ref: string };
  tableName: string;
  fromMapping?: { fieldName: string };
  toMapping?: { fieldName: string };
  propertyMappings: PropertyMappingJsonStruct[];
  mappingFilter?: MappingFilterJsonStruct;
};

export type GraphMappingRepresentationJsonStruct = {
  dataSourceSchema: DataSourceSchemaJsonStruct;
  nodeMappings: NodeMappingJsonStruct[];
  relationshipMappings: RelationshipMappingJsonStruct[];
};

export type ConfigurationsJsonStruct = {
  idsToIgnore: string[];
};

export type IndexesJsonStruct = formatters.json.types.IndexJsonStruct;
export type ConstraintsJsonStruct = formatters.json.types.ConstraintJsonStruct;

/**
 * @deprecated This type should not be used for new models.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export type DataModelJsonStruct_2_2_0 = {
  version: string;
  graphSchemaRepresentation: formatters.json.types.GraphSchemaRepresentationJsonStruct;
  graphSchemaExtensionsRepresentation: GraphSchemaExtensionsRepresentationJsonStruct;
  graphMappingRepresentation: GraphMappingRepresentationJsonStruct;
  configurations: ConfigurationsJsonStruct;
};
