import { isNonEmptyString } from '@nx/stdlib';
import type { AsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import OpenAI from 'openai';

let vertexaiClient: OpenAI | undefined;

export type GenaiApiState =
  | { status: 'no-backend-available' }
  | { status: 'pending' }
  | { status: 'login-required' }
  | { status: 'error'; errorMessage: string }
  | { status: 'loaded' };

// Part of the state that is affects usage of the Genai API is owned by other slices.
export type ConsentAndGenaiState =
  | GenaiApiState
  | { status: 'consent-unknown' | 'no-login-endpoint' | 'consent-declined' };

export function getVertexaiClient() {
  return vertexaiClient;
}

let cypherToTextFn: (args: {
  schema: string;
  prompt: string;
  appendEditorValue: (val: string) => void;
  startGeneration: () => void;
}) => Promise<void> | undefined;

export function getTextToCypherFn() {
  return cypherToTextFn;
}

const BACKEND_BASE_URL = isNonEmptyString(import.meta.env.R_VITE_AURA_GENAI_API_BASE_URL)
  ? import.meta.env.R_VITE_AURA_GENAI_API_BASE_URL
  : import.meta.env.VITE_AURA_GENAI_API_BASE_URL;

const hasBackendUrl = typeof BACKEND_BASE_URL === 'string' && BACKEND_BASE_URL.length > 0;
function getInitialState(): GenaiApiState {
  if (!hasBackendUrl) {
    return { status: 'no-backend-available' };
  }
  return { status: 'login-required' };
}

type ResponseChunk = {
  Candidates: [
    {
      Index: number;
      Content: { Role: 'model'; Parts: string[] };
      FinishReason: number;
      SafetyRatings: never[];
      FinishMessage: string;
      CitationMetadata: null;
    },
  ];
  PromptFeedback: null;
  UsageMetadata: {
    PromptTokenCount: number;
    CandidatesTokenCount: number;
    TotalTokenCount: number;
  };
};

export const slice = createSlice({
  name: 'genaiApi',
  initialState: getInitialState(),
  reducers: {
    updateGenaiApiStatus: (_: GenaiApiState, action: PayloadAction<GenaiApiState>) => {
      return action.payload;
    },
  },
});

export const createGenaiClient: AsyncThunk<void, { accessToken: string }, Record<string, never>> = createAsyncThunk(
  'genai/createLlmClient',
  (payload: { accessToken: string }, thunkApi) => {
    // This is a thunk to mark it's doing a side effect
    if (typeof BACKEND_BASE_URL === 'string') {
      vertexaiClient = new OpenAI({
        apiKey: payload.accessToken,
        dangerouslyAllowBrowser: true,
        baseURL: `${BACKEND_BASE_URL}/vertexai/`,
      });

      cypherToTextFn = async ({ prompt: query, schema, appendEditorValue, startGeneration }) => {
        const response = await fetch(`${BACKEND_BASE_URL}/neo4j/text2cypher`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${payload.accessToken}`,
            accept: 'application/json',
            'x-referring-client': 'workspace-query',
          },
          body: JSON.stringify({
            schema,
            query,
            streaming: true,
            // Keys below are not used by the backend, but need to be sent
            // this key needs to be a hardcoded date string for now
            requested: '2024-10-16T13:54:42.123Z',
            auraDbId: 'N/A',
            extendedContext: 'N/A',
            sessionId: 'N/A',
            userId: 'N/A',
          }),
        });

        if (!response.ok) {
          switch (response.status) {
            case 401:
              throw new Error('Unauthorized');
            case 400:
              throw new Error('Invalid request format');
            case 422:
              throw new Error('Could not parse database schema');
            case 429:
              throw new Error('Too many requests, please try again later');
            case 500:
              throw new Error('Internal server error');
            default:
              throw new Error('Failed to convert text to cypher');
          }
        }

        if (response.body === null) {
          throw new Error('Response body is missing');
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');

        let buffer = '';
        let startedAppending = false;

        // for await of `response.body` is not widely supported in browser yet, so we do a while loop
        // eslint-disable-next-line no-constant-condition
        while (true) {
          // eslint-disable-next-line no-await-in-loop
          const { done, value } = await reader.read();

          if (done) {
            break;
          }

          // Decode the current chunk and add it to the buffer
          buffer += decoder.decode(value, { stream: true });

          // Split the buffer by newlines to get complete JSON objects
          const lines = buffer.split('\n');

          // Keep the last partial line in the buffer
          buffer = lines.pop()!;

          for (const line of lines) {
            if (line.trim()) {
              let cypherChunk: string | undefined;
              try {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const data = JSON.parse(line) as ResponseChunk;
                cypherChunk = data.Candidates[0].Content.Parts[0];
              } catch (e) {
                throw new Error('Error parsing response');
              }

              if (!startedAppending) {
                // eslint-disable-next-line max-depth
                if (cypherChunk?.trim() === 'FAIL') {
                  throw new Error('Please try rephrasing your request');
                }
                startGeneration();
                startedAppending = true;
              }

              appendEditorValue(cypherChunk ?? '');
            }
          }
        }

        if (buffer.trim()) {
          try {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const data = JSON.parse(buffer) as ResponseChunk;
            const [cypherChunk] = data.Candidates[0].Content.Parts;

            appendEditorValue(cypherChunk ?? '');
          } catch (e) {
            throw new Error('Error parsing response');
          }
        }
      };
      thunkApi.dispatch(slice.actions.updateGenaiApiStatus({ status: 'loaded' }));
    } else {
      thunkApi.dispatch(slice.actions.updateGenaiApiStatus({ status: 'no-backend-available' }));
    }
  },
);

export const { updateGenaiApiStatus } = slice.actions;
export const { reducer, reducerPath } = slice;
export default slice.reducer;
