import type { ArgumentDescription, ReturnDescription } from '../types/sdk-types';

export function cleanType(type: string): string {
  let resultType = type;

  if (resultType.startsWith('LIST? OF ')) {
    resultType = resultType.replace('LIST? OF ', 'LIST<');
    resultType += '>';
  }
  return resultType.replace(/\?/g, '');
}

export function cleanTypeDescription(arg: ArgumentDescription | ReturnDescription) {
  return { ...arg, type: cleanType(arg.type) };
}
