import {
  isSsoRedirect,
  useAppContextLoaded,
  useConnectionSelectorModal,
  useConnectionStatus,
  useGuidesActions,
} from '@nx/state';
import { isNullish } from '@nx/stdlib';
import { getConnectionParams, useManifest } from '@nx/ui';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useSearchParamsHandler = () => {
  const [searchParams] = useSearchParams();
  const { openGuide } = useGuidesActions();
  const { isDisconnected } = useConnectionStatus();
  const connectionSelectorModal = useConnectionSelectorModal();
  const appContextLoaded = useAppContextLoaded();

  const { data: guidesManifest } = useManifest();

  const openConnectionModalFromSearchParams = () => {
    // If redirected from SSO don't open connection model.
    if (!isDisconnected || isSsoRedirect()) {
      return;
    }

    // If a connectUrl param is present in the url, open a connection dialog
    // with details pre-populated from connectUrl.
    const { url, dbName, instanceName } = getConnectionParams(searchParams);
    if (!isNullish(url)) {
      connectionSelectorModal.fetchDiscoveryProvidersAndOpen({ url, instanceName, dbName });
    }
  };

  const openCommandFromSearchParams = () => {
    const cmd = searchParams.get('cmd');
    const arg = searchParams.get('arg');
    switch (cmd) {
      case 'guide':
        if (!isNullish(arg) && guidesManifest?.guides && Object.keys(guidesManifest.guides).includes(arg)) {
          void openGuide({
            id: arg,
            title: guidesManifest.guides[arg]?.title ?? '',
            url: guidesManifest.guides[arg]?.url ?? '',
            target: 'sidebar',
          });
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (appContextLoaded) {
      openConnectionModalFromSearchParams();
      openCommandFromSearchParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, isDisconnected, isSsoRedirect, appContextLoaded]);
};
