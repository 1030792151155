import { Button } from '@neo4j-ndl/react';
import { ArrowLongLeftIconOutline } from '@neo4j-ndl/react/icons';
import { Objects } from '@nx/stdlib';

import type { OnboardingData, USE_CASE } from '../entities/model';
import { useCaseLabels } from '../entities/model';
import { OnboardingItemButton } from '../onboarding-button';
import { BaseSection } from './base-section';

type UseCaseSectionProps = {
  onNextStep: (useCase: USE_CASE) => void;
  onBack: () => void;
  data: OnboardingData;
  isLoading: boolean;
};

export const UseCaseSection = ({ onNextStep, onBack, data, isLoading }: UseCaseSectionProps) => {
  const handleSelectUseCase = (useCase: USE_CASE) => {
    onNextStep(useCase);
  };

  return (
    <BaseSection
      title={
        <>
          What is your
          <br />
          use case?
        </>
      }
      step={3}
    >
      <div className="flex grow flex-col justify-between gap-6 overflow-y-auto">
        <div className="use-case-grid grow gap-4 overflow-y-auto">
          {Objects.keys(useCaseLabels).map((key) => (
            <OnboardingItemButton
              key={key}
              onClick={() => handleSelectUseCase(key)}
              size="medium"
              isLoading={key === data.primaryUseCase && isLoading}
              isDisabled={isLoading}
            >
              {useCaseLabels[key]}
            </OnboardingItemButton>
          ))}
        </div>
        <div className="flex w-full justify-between self-start">
          <Button
            size="large"
            onClick={onBack}
            color="neutral"
            fill="outlined"
            className="text-neutral-text-default hover:bg-neutral-hover active:bg-palette-neutral-pressed flex gap-2 !border-none"
          >
            <ArrowLongLeftIconOutline className="size-5" />
            Back
          </Button>
        </div>
      </div>
    </BaseSection>
  );
};
