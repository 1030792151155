import { opsApi, useActiveProject } from '@nx/state';
import { isNonEmptyString } from '@nx/stdlib';
import { useMemo } from 'react';

import { usePageInfo } from '../shared/hooks/use-page-info';
import type { PaginationHook } from '../shared/hooks/use-pagination';
import { useLogsContext } from './hooks/use-logs-context';
import type { SortingHook } from './hooks/use-sorting';
import { SecurityLogsDataGrid } from './security-logs-data-grid';
import { mapSecurityLogs } from './shared/mappers';

export const SecurityLogsDetails = ({
  selectedDbmsId,
  pagination,
  sorting,
}: {
  selectedDbmsId: string;
  pagination: PaginationHook;
  sorting: SortingHook<'Details'>;
}) => {
  const activeProject = useActiveProject();
  const selectedTenantId = activeProject.id;
  const { filterInput, hasRequestedData } = useLogsContext();

  const { controlledPagination, onPaginationChange, logsPagination } = pagination;
  const { controlledSorting, onSortingChange, logsSorting } = sorting;

  const {
    data: securityLogsData,
    isFetching: securityLogsLoading,
    error: securityLogsError,
  } = opsApi.useGetSecurityLogsQuery(
    {
      tenantId: selectedTenantId,
      dbmsId: selectedDbmsId,
      args: {
        filter: filterInput,
        pagination: logsPagination,
        sort: logsSorting,
      },
    },
    { skip: !selectedDbmsId || !selectedTenantId || !hasRequestedData },
  );
  const logs = useMemo(
    () => (hasRequestedData ? mapSecurityLogs(securityLogsData?.logs) : []),
    [hasRequestedData, securityLogsData],
  );
  const pageInfo = usePageInfo(securityLogsData?.pageInfo);

  const detailedSecurityLog = isNonEmptyString(filterInput.message)
    ? logs.find((log) => log.message === filterInput.message)
    : null;

  return (
    <SecurityLogsDataGrid
      dataGridType="Details"
      logs={logs}
      securityLogsLoading={securityLogsLoading}
      pageInfo={pageInfo}
      detailedSecurityLog={detailedSecurityLog}
      securityLogsError={securityLogsError}
      selectedDbmsId={selectedDbmsId}
      controlledPagination={controlledPagination}
      controlledSorting={controlledSorting}
      onPaginationChange={onPaginationChange}
      onSortingChange={onSortingChange}
    />
  );
};
