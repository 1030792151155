import { IconButton } from '@neo4j-ndl/react';
import type { ComponentProps } from 'react';
import React, { useState } from 'react';

/**
 *
 * Some buttons are dispatching async actions,
 * thus we are unable to know a-posteriory what happened
 * to the request/promise.
 *
 * This button is just an "illusion" to show users that we
 * received the action and we execute it for 0.8 seconds.
 *
 */
export const LoadingProxyButton = ({ ...props }: ComponentProps<typeof IconButton>) => {
  const [loading, setLoading] = useState(false);

  const startAction = (e: React.MouseEvent<HTMLButtonElement>) => {
    setLoading(true);
    if (typeof props.onClick === 'function') {
      props.onClick(e);
    }
    setTimeout(() => setLoading(false), 800);
  };

  return (
    <IconButton
      isLoading={loading}
      htmlAttributes={{ 'aria-label': 'reload button' }}
      {...props}
      onClick={(e) => startAction(e)}
    >
      {!loading && props.children}
    </IconButton>
  );
};

export default LoadingProxyButton;
