import type { CLOUD_PROVIDER, TIER } from '@nx/state';

export type OnboardingData = {
  firstName: string;
  lastName: string;
  companyName: string;
  onboardingIntent: ONBOARDING_INTENT | null;
  onboardingIntentOther?: string | null;
  jobRole: JOB_ROLE | null;
  jobRoleOther?: string | null;
  primaryUseCase: USE_CASE | null;
  primaryUseCaseOther?: string | null;
};

export interface InstanceFormData {
  tier: TIER;
  name: string;
  version: string;
  region?: string;
  memory: string;
  storage: string;
  cloudProvider: CLOUD_PROVIDER;
}

export enum ONBOARDING_INTENT {
  Work = 'work_project',
  Personal = 'personal_project',
}

export const intentLabel = {
  [ONBOARDING_INTENT.Work]: 'Working on a commercial project',
  [ONBOARDING_INTENT.Personal]: 'Working on a personal project',
};

export enum USE_CASE {
  GenerativeAI = 'generative_ai',
  KnowledgeGraph = 'knowledge_graph',
  FraudDetection = 'fraud_detection',
  Iam = 'iam',
  MasterDataManagement = 'master_data_management',
  NetworkAndItOperations = 'network_and_it_operations',
  Recommendations = 'recommendations',
  DataPrivacyRiskAndCompliance = 'data_privacy_risk_and_compliance',
  SupplyChainAnalytics = 'supply_chain_analytics',
  Other = 'other',
}

export const useCaseLabels: { [key in USE_CASE]: string } = {
  [USE_CASE.GenerativeAI]: 'Generative AI',
  [USE_CASE.KnowledgeGraph]: 'Knowledge Graph',
  [USE_CASE.FraudDetection]: 'Fraud Detection',
  [USE_CASE.Iam]: 'Identity Access Management',
  [USE_CASE.MasterDataManagement]: 'Master Data Management',
  [USE_CASE.NetworkAndItOperations]: 'Network and IT Operations',
  [USE_CASE.Recommendations]: 'Recommendations',
  [USE_CASE.DataPrivacyRiskAndCompliance]: 'Data Privacy, Risk, and Compliance',
  [USE_CASE.SupplyChainAnalytics]: 'Supply Chain Analytics',
  [USE_CASE.Other]: 'Other',
};

export enum JOB_ROLE {
  SoftwareEngineer = 'software_engineer',
  SolutionArchitect = 'solution_architect',
  DataScientist = 'data_scientist',
  DataAnalyst = 'data_analyst',
  Operations = 'operations',
  Other = 'other',
  Student = 'student',
}

export const jobRoleLabels: { [key in JOB_ROLE]: string } = {
  [JOB_ROLE.SoftwareEngineer]: 'Software Developer',
  [JOB_ROLE.DataScientist]: 'Data Scientist',
  [JOB_ROLE.DataAnalyst]: 'Data Analyst',
  [JOB_ROLE.Operations]: 'Operations Manager',
  [JOB_ROLE.SolutionArchitect]: 'Architect / Tech lead',
  [JOB_ROLE.Student]: 'Student',
  [JOB_ROLE.Other]: 'Other',
};
