import { Banner, Button, Dialog } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { IDP_TYPE, consoleApi, getApiError, getErrorMessage } from '@nx/state';
import type { LinkedProject } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';

import type { Validation } from '../../utils/validation';
import type { SsoConfigFormData } from './entities';
import { SSO_CONFIG_FORM_ACTIONS } from './entities';
import SsoConfigFormFields from './form-fields';
import { getErrorMessageFromUnknown, validateSsoConfig } from './helpers';

const logger = createLogger(APP_SCOPE.framework);

export interface CreateSsoConfigModalProps {
  onClose: () => void;
  saveAndClose: () => void;
  organizationId: string;
  isSSOAllowed: boolean;
}

const defaultValues = (organizationId: string): SsoConfigFormData => ({
  displayName: '',
  idpType: IDP_TYPE.OKTA,
  discoveryURI: undefined,
  issuer: undefined,
  authorizationEndpoint: undefined,
  tokenEndpoint: undefined,
  jwksURI: undefined,
  clientId: '',
  roleMapping: undefined,
  needsSecret: true,
  clientSecret: '',
  organizationId,
  organizationLoginMethod: false,
  linkedProjects: [],
  projectLoginMethod: false,
  manualUriConfiguration: false,
});

const CreateSsoConfigModal = ({ onClose, saveAndClose, organizationId, isSSOAllowed }: CreateSsoConfigModalProps) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [data, setData] = useState<SsoConfigFormData>(defaultValues(organizationId));
  const [validation, setValidation] = useState<Validation<SsoConfigFormData> | null>(null);
  const [createSsoConfig] = consoleApi.useCreateSsoConfigMutation();
  const [linkSsoConfigId] = consoleApi.useLinkSsoConfigIdMutation();

  const updateSsoConfigIdWithHandling = async (projectId: string, ssoConfigId: string) => {
    try {
      await linkSsoConfigId({
        projectId,
        ssoConfigId,
        organizationId,
      }).unwrap();
    } catch (err: unknown) {
      const message = getErrorMessageFromUnknown(err);

      return {
        projectId,
        ssoConfigId,
        success: false,
        error: message,
      };
    }
    return { projectId, ssoConfigId, success: true };
  };

  const handleUpdateProjectsWithSso = async (ssoConfigId: string, linkedProjects: LinkedProject[]) => {
    const updatePromises = linkedProjects.map(({ id: projectId }) =>
      updateSsoConfigIdWithHandling(projectId, ssoConfigId),
    );
    try {
      const results = await Promise.all(updatePromises);
      const failures = results.filter((result) => !result.success);
      if (failures.length > 0) {
        setErrorMessage(
          `Failed to update SSO Config ID for projects: ${failures.map((failure) => failure.projectId).join(', ')}`,
        );
      }
    } catch (error) {
      setErrorMessage('Unknown failure when updating SSO Config ID for projects');
    }
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    setErrorMessage(null);
    const newValidation = validateSsoConfig(data);
    if (isNotNullish(newValidation)) {
      setValidation(newValidation);
      return;
    }

    setLoading(true);
    const { linkedProjects, clientSecret, ...dataToSubmit } = data;
    if (isNullish(clientSecret)) {
      return;
    }
    createSsoConfig({ clientSecret, ...dataToSubmit })
      .unwrap()
      .then(async (config) => {
        if (dataToSubmit.projectLoginMethod === true) {
          await handleUpdateProjectsWithSso(config.id, linkedProjects);
        }
        setLoading(false);
        saveAndClose();
      })
      .catch((err: FetchBaseQueryError | SerializedError | undefined) => {
        const apiError = getApiError(err);
        const message = getErrorMessage(apiError);
        logger.error(message);
        setErrorMessage(message);
        setLoading(false);
      });
  };

  const maybeValidate = (newData: SsoConfigFormData) => {
    if (isNotNullish(validation)) {
      const validationMsgs = validateSsoConfig(newData);
      setValidation(validationMsgs);
    }
  };

  const handleChange = (newData: Partial<SsoConfigFormData>) => {
    const values = { ...data, ...newData };
    setData((oldData) => ({ ...oldData, ...newData }));
    maybeValidate(values);
  };

  return (
    <Dialog isOpen onClose={onClose} modalProps={{ 'data-testid': 'create-sso-config-modal' }}>
      <form id="create-sso-config-form" onSubmit={handleSubmit}>
        <Dialog.Header>New SSO Configuration</Dialog.Header>
        <Dialog.Content data-testid="create-sso-config-content">
          <Banner
            hasIcon
            type="info"
            description="To create an SSO Config, either a Discovery URI or a combination of Issuer,
Authorization Endpoint, Token Endpoint and JWKS URI is required."
          />
          <SsoConfigFormFields
            config={data}
            onChange={handleChange}
            validation={validation}
            formAction={SSO_CONFIG_FORM_ACTIONS.CREATE}
            organizationId={data.organizationId}
            isSSOAllowed={isSSOAllowed}
          />

          {isNotNullish(errorMessage) && (
            <Banner
              className="mt-2"
              type="danger"
              data-testid="create-sso-config-error-message"
              description={`There was an error creating the SSO Config: ${errorMessage}`}
              hasIcon
            />
          )}
        </Dialog.Content>
        <Dialog.Actions>
          <Button
            color="neutral"
            fill="outlined"
            isDisabled={loading}
            onClick={onClose}
            htmlAttributes={{ 'data-testid': 'discard-create-sso-config-button' }}
          >
            Discard
          </Button>
          <Button
            isLoading={loading}
            type="submit"
            htmlAttributes={{ form: 'create-sso-config-form', 'data-testid': 'create-sso-config-button' }}
          >
            Create
          </Button>
        </Dialog.Actions>
      </form>
    </Dialog>
  );
};

export default CreateSsoConfigModal;
