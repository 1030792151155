import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { cloneDeep, isEmpty, isEqual, isNil, union } from 'lodash-es';
import memoize from 'memoize-one';
import type { Except } from 'type-fest';

import type { Perspective } from '../../types/perspective';
import type { Prettify } from '../../types/prettify';
import type { Nullable } from '../../types/utility';
import type { RootState } from '../types';
import type {
  AddingIndex,
  IndexType,
  MetadataFullTextIndex,
  MetadataNativeIndex,
  PerspectiveMetadataState,
} from './perspectiveMetadata.types';
import { indexTypes } from './perspectiveMetadata.types';
import { getPerspective } from './perspectives.selector';

export const NAME = 'metadata';

export const initialState: PerspectiveMetadataState = {
  pathSegments: [],
  indexes: [],
};

export const filterNativeIndexes = memoize((indexes: PerspectiveMetadataState['indexes']) => {
  const isNativeIndex = (index: MetadataNativeIndex | MetadataFullTextIndex): index is MetadataNativeIndex =>
    index.type === indexTypes.NATIVE_INDEX;
  return indexes.reduce<Prettify<Except<MetadataNativeIndex, 'propertyKeys'> & { propertyKeys: string[] }>[]>(
    (acc, index) => {
      if (isNativeIndex(index)) {
        acc.push({
          ...index,
          propertyKeys: index?.propertyKeys?.map(({ key }) => key),
        });
      }
      return acc;
    },
    [],
  );
});

export const filterFullTextIndexes = memoize((indexes: PerspectiveMetadataState['indexes']) => {
  const isFullTextIndex = (index: MetadataNativeIndex | MetadataFullTextIndex): index is MetadataFullTextIndex =>
    index.type === indexTypes.FULL_TEXT_INDEX;
  return indexes.reduce<Prettify<Except<MetadataFullTextIndex, 'propertyKeys'> & { propertyKeys: string[] }>[]>(
    (acc, index) => {
      if (isFullTextIndex(index)) {
        acc.push({
          ...index,
          propertyKeys: index?.propertyKeys?.map(({ key }) => key),
        });
      }
      return acc;
    },
    [],
  );
});

const findIdenticalIndex = (
  indexes: PerspectiveMetadataState['indexes'],
  newIndex: {
    label: Nullable<string>;
    propertyKeys: string[];
    type: IndexType;
    tokenNames?: string[];
    propertyNames?: string[];
  },
) =>
  indexes.findIndex((index) => {
    if (index.type === indexTypes.FULL_TEXT_INDEX && newIndex.type === indexTypes.FULL_TEXT_INDEX) {
      return isEqual(index.tokenNames, newIndex.tokenNames) && isEqual(index.propertyNames, newIndex.propertyNames);
    }
    return index.type === newIndex.type && index.label === newIndex.label;
  });

const getMetadataState = createSelector(getPerspective, (currentPerspective) => {
  if (isNil(currentPerspective) || isEmpty(currentPerspective[NAME])) return cloneDeep(initialState);
  return currentPerspective[NAME];
});

export const getPathSegments = (state: RootState) => getMetadataState(state).pathSegments;

export const getAllIndexes = (state: RootState) => getMetadataState(state).indexes;

export const getIndexes = (state: RootState) => filterNativeIndexes(getMetadataState(state).indexes);

export const getFullTextIndexes = (state: RootState) => filterFullTextIndexes(getMetadataState(state).indexes);

const perspectiveMetadataSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setPathSegments(
      state,
      action: PayloadAction<{
        pathSegments: PerspectiveMetadataState['pathSegments'];
        perspectiveId: Perspective['id'];
      }>,
    ) {
      const { pathSegments } = action.payload;
      state.pathSegments = [...pathSegments];
    },
    resetPathSegments(state, action: PayloadAction<{ perspectiveId: Perspective['id'] }>) {
      state.pathSegments = initialState.pathSegments;
    },
    clearIndexes(state, action: PayloadAction<{ perspectiveId: Perspective['id'] }>) {
      state.indexes = [];
    },
    addIndexes(
      state,
      action: PayloadAction<{
        indexes: AddingIndex[];
        isMetadataProp: boolean;
        perspectiveId: Perspective['id'];
      }>,
    ) {
      const { indexes, isMetadataProp } = action.payload;
      const currentIndexes = state.indexes.slice(0);
      const newIndexes = indexes.reduce((updatedIndexes, addedIndex) => {
        const updatedPropList = addedIndex?.propertyKeys?.map((val) => ({ key: val, metadataProp: isMetadataProp }));
        const existingEntryIndex = findIdenticalIndex(updatedIndexes, addedIndex);
        if (existingEntryIndex >= 0) {
          const existingEntry = updatedIndexes[existingEntryIndex];
          if (!existingEntry?.propertyKeys) {
            return updatedIndexes;
          }
          const updatedProperty = {
            propertyKeys: union(existingEntry.propertyKeys, updatedPropList).reduce<
              { key: string; metadataProp: boolean }[]
            >((updatedProbsList, current) => {
              const isDuplicatedItem = updatedProbsList.find((item) => item.key === current.key) != null;
              return !isDuplicatedItem ? updatedProbsList.concat([current]) : updatedProbsList;
            }, []),
          };
          const updatedEntry = { ...existingEntry, ...updatedProperty };
          updatedIndexes.splice(existingEntryIndex, 1, updatedEntry);
        } else {
          const updatedPropertyIndex = { ...addedIndex, propertyKeys: updatedPropList };
          updatedIndexes.push(updatedPropertyIndex);
        }
        return updatedIndexes;
      }, currentIndexes);
      state.indexes = newIndexes;
    },
    clearFullTextIndexes(state, action: PayloadAction<{ perspectiveId: Perspective['id'] }>) {
      const existingIndexes = state.indexes.slice(0);
      const indexesWithoutFullText = existingIndexes.filter((index) => index.type !== indexTypes.FULL_TEXT_INDEX);
      state.indexes = indexesWithoutFullText;
    },
    removeIndexedProperties(
      state,
      action: PayloadAction<{
        properties: Record<string, { key: string; metadataProp: boolean }[]>;
        perspectiveId: Perspective['id'];
      }>,
    ) {
      const { properties: indexes } = action.payload;
      const existingIndexes = state.indexes.slice(0);
      const reducedIndexes = Object.keys(indexes).reduce((updatedIndexes, label) => {
        const existingEntry = updatedIndexes.find((labelIndex) => labelIndex.label === label);
        if (existingEntry != null) {
          existingEntry.propertyKeys = existingEntry.propertyKeys.filter(
            ({ key }) => indexes[label]?.filter((keyObj) => keyObj.key === key).length === 0,
          );
        }

        if (existingEntry?.propertyKeys.length === 0) {
          const removeIndex = updatedIndexes.findIndex((labelIndex) => labelIndex.label === label);
          updatedIndexes.splice(removeIndex, 1);
        }
        return updatedIndexes;
      }, existingIndexes);
      state.indexes = reducedIndexes;
    },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state) => state);
  },
});

export const {
  setPathSegments,
  resetPathSegments,
  clearIndexes,
  clearFullTextIndexes,
  addIndexes,
  removeIndexedProperties,
} = perspectiveMetadataSlice.actions;

export default perspectiveMetadataSlice.reducer;
