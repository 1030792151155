import { createSelector } from '@reduxjs/toolkit';

import { useSelector } from '../context';
import * as Selectors from '../selectors';
import { SESSION_STATUS } from '../slices/session-slice';
import type { CombinedRootState } from '../store';

const selectAuth = createSelector(
  (state: CombinedRootState) => state.session,
  Selectors.selectUserId,
  (session, userId) => {
    return {
      userId,
      user: session.type === SESSION_STATUS.Authenticated ? session.user : null,
      isAuthenticated: session.type === SESSION_STATUS.Authenticated,
      isLoading: session.type === SESSION_STATUS.Pending,
      error: session.type === SESSION_STATUS.Failed ? session.error : null,
    };
  },
);

export function useAuth() {
  return useSelector(selectAuth);
}
