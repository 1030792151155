import { type RequestId } from '@query/redux/requests-slice';

type AbortFunctions = Record<string, () => void>;

const abortFunctions: AbortFunctions = {};

export const registerAbortFunction = (requestId: RequestId, abortFunction: () => void) =>
  (abortFunctions[requestId] = abortFunction);

export const unregisterAbortFunction = (requestId: RequestId) => delete abortFunctions[requestId];

export const consumeAbortFunction = (requestId: RequestId) => {
  const abortFunction = abortFunctions[requestId];
  unregisterAbortFunction(requestId);
  return abortFunction;
};
