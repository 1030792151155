import { securityError } from '../../constants';

export type WithCode =
  | {
      code?: string;
    }
  | null
  | undefined;
export const isForbiddenError = (err: any): err is WithCode =>
  err != null && 'code' in err && err.code === securityError.SECURITY_FORBIDDEN_ERROR;

export const isNoProcedureError = (err: any): err is WithCode =>
  err != null && 'code' in err && err.code === 'Neo.ClientError.Procedure.ProcedureNotFound';

interface BoltErrorOptions {
  message?: string;
  ignore?: boolean;
  code?: string;
  title?: string;
}

export class BoltError extends Error {
  constructor({ message, ignore, code, title }: BoltErrorOptions = {}) {
    super(message);
    this.ignore = ignore;
    this.code = code;
    this.title = title;
  }

  ignore?: boolean;

  code?: string;

  title?: string;
}

export const isBoltCompatibleError = (error: unknown): error is BoltError => error instanceof Error;
