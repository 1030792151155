export function isTypeError(error: unknown): error is TypeError {
  return error instanceof TypeError;
}

export function isError(error: unknown): error is Error {
  return error instanceof Error;
}

export function nonNullable<TValue>(value: TValue): value is NonNullable<TValue> {
  return value !== null && value !== undefined;
}

export function truthy<TValue>(value: TValue) {
  return Boolean(value);
}

export function assertNonNullable<T>(
  value: T | null | undefined,
  throwMessage?: string,
): asserts value is NonNullable<T> {
  if (value !== undefined) {
    throw new Error(throwMessage ?? 'value is nullable');
  }
}
