import type { DomJson } from '@nx/constants';
import type { PersistedState } from 'redux-persist';

import type { GuideStatus, GuidesState } from './guides-slice';

type GuideV1 = {
  id: string;
  title: string;
  url?: string;
  localGuide?: string;
  contents: DomJson[];

  totalPages: number;
  currentPage: number;
  isCompleted: boolean;
};

type GuidesStateV1 = {
  guides: Record<string, GuideV1>;
  currentGuide: string | undefined;
};

function isGuideV1(input: unknown): input is GuideV1 {
  if (input === null || typeof input !== 'object') {
    return false;
  }

  return ['id', 'contents', 'totalPages', 'currentPage', 'isCompleted', 'title'].every((key) => key in input);
}

function isGuidesStateV1(input: unknown): input is GuidesStateV1 {
  if (input === null || typeof input !== 'object') {
    return false;
  }

  const hasGuidesProperty = 'guides' in input;
  const hasCurrentGuideProperty = 'currentGuide' in input;
  if (!hasGuidesProperty || !hasCurrentGuideProperty || typeof input.guides !== 'object' || input.guides === null) {
    return false;
  }

  const guidesRecords = input.guides;
  const guides = Object.values(guidesRecords);

  return guides.every(isGuideV1);
}

export const mapV1toCurrent = (
  state: GuidesStateV1,
): Pick<GuidesState, 'guides' | 'guidesStatuses' | 'currentGuideId'> => {
  const { guides, currentGuide } = state;

  const guidesStatuses: Record<string, GuideStatus> = {};
  Object.values(guides).forEach((guide) => {
    guidesStatuses[guide.id] = {
      currentPage: guide.currentPage,
      isCompleted: guide.isCompleted,
    };
  });

  return {
    guides,
    guidesStatuses,
    currentGuideId: currentGuide,
  };
};

export const migrateGuides = (state: PersistedState) => {
  if (isGuidesStateV1(state)) {
    mapV1toCurrent(state);
  }
  return Promise.resolve(state);
};
