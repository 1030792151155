import { Accordion, Checkbox, LoadingSpinner, Select, TextArea, TextInput, Tooltip } from '@neo4j-ndl/react';
import { InformationCircleIconOutline } from '@neo4j-ndl/react/icons';
import type { DataApiModify, Instance } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import { Center } from '@nx/ui';
import { useState } from 'react';

import { MODIFY_MODE } from '../types';
import type { Validation } from '../utils/validation';
import { AllowedOriginsList } from './allowed-origins-list';
import { AuthProviderList } from './auth-provider-list';
import { SizingTable } from './sizing-table';

interface ModifyAccordionProps {
  mode: MODIFY_MODE;
  runningInstances: Instance[] | null;
  isLoading: boolean;
  data: DataApiModify;
  setData: React.Dispatch<React.SetStateAction<DataApiModify>>;
  validationError: Validation<DataApiModify> | null;
  setValidationError: React.Dispatch<React.SetStateAction<Validation<DataApiModify> | null>>;
}

export const ModifyAccordion = ({
  mode,
  data,
  isLoading,
  runningInstances,
  validationError,
  setValidationError,
  setData,
}: ModifyAccordionProps) => {
  const initialOpenAccordionItems =
    mode === MODIFY_MODE.create ? ['details', 'typeDefs', 'corsPolicy', 'authProviders', 'sizing'] : ['details'];
  const credentialInputPlaceholder = mode === MODIFY_MODE.edit ? '********' : '';

  const [selectedAccordionItems, setSelectedAccordionItems] = useState(initialOpenAccordionItems);

  const handleNameUpdate = (name: string) => {
    setValidationError(null);
    setData((prev) => ({ ...prev, name }));
  };

  const handleInstanceIdUpdate = (instanceId: string) => {
    setValidationError(null);
    setData((prev) => ({ ...prev, instanceId }));
  };

  const handleUsernameUpdate = (username: string) => {
    setValidationError(null);
    setData((prev) => ({ ...prev, instanceUsername: username }));
  };

  const handlePasswordUpdate = (password: string) => {
    setValidationError(null);
    setData((prev) => ({ ...prev, instancePassword: password }));
  };

  const handleTypeDefsUpdate = (typeDefs: string) => {
    setValidationError(null);
    setData((prev) => ({ ...prev, typeDefinitions: typeDefs }));
  };

  const handleSubgraphUpdate = (isEnabled: boolean) => {
    setValidationError(null);
    setData((prev) => ({ ...prev, featuresSubgraph: isEnabled }));
  };

  const LabelSelectInstance = () => {
    let text = 'Only running instances of tier Professional, Business Critical and Enterprise can be selected';
    if (isNotNullish(runningInstances) && runningInstances.length === 0) {
      text = 'Every running instance can only be connected to one Data API';
    }

    return (
      <div className="flex flex-row">
        Instance
        <Tooltip type="simple" placement="bottom">
          <Tooltip.Trigger>
            <InformationCircleIconOutline className="ml-1 h-4 w-4" />
          </Tooltip.Trigger>
          <Tooltip.Content className="left-3/4 z-50">{text}</Tooltip.Content>
        </Tooltip>
      </div>
    );
  };

  const LabelEnableSubgraph = () => {
    return (
      <div className="flex flex-row">
        Enable GraphQL subgraph
        <Tooltip type="simple" placement="left">
          <Tooltip.Trigger>
            <InformationCircleIconOutline className="ml-1 h-4 w-4" />
          </Tooltip.Trigger>
          <Tooltip.Content className="left-3/4 z-50">
            Whether or not to enable this GraphQL Data API as GraphQL federated subgraph
          </Tooltip.Content>
        </Tooltip>
      </div>
    );
  };

  const LoadingState = (height: 'small' | 'large') => {
    const containerHeight = height === 'small' ? 'h-64' : 'h-80';
    return (
      <div className={containerHeight}>
        <Center>
          <LoadingSpinner size="medium" />
        </Center>
      </div>
    );
  };

  return (
    <Accordion className="p-0" isMultiple expandedItemIds={selectedAccordionItems} onChange={setSelectedAccordionItems}>
      <Accordion.Item
        title="Details"
        itemId="details"
        className="bg-neutral-bg-weak border-palette-neutral-border-weak accordion-item-no-padding rounded-4xl mb-6 [&>div:last-child]:hidden"
      >
        {isLoading ? (
          LoadingState('large')
        ) : (
          <div className="flex w-80 flex-col space-y-4">
            {mode === MODIFY_MODE.edit && (
              <TextInput
                label="API ID"
                isFluid
                isReadOnly
                htmlAttributes={{
                  type: 'text',
                  'aria-label': 'readonly input data api id',
                }}
                value={data.id}
              />
            )}
            <TextInput
              label="API name"
              isFluid
              htmlAttributes={{
                type: 'text',
                'aria-label': 'input api name',
              }}
              value={data.name}
              onChange={(event) => handleNameUpdate(event.target.value)}
              errorText={validationError?.name?.message}
            />
            {mode === MODIFY_MODE.create && (
              <Select
                label={LabelSelectInstance()}
                type="select"
                size="medium"
                aria-label="Select a running instance"
                htmlAttributes={{
                  'aria-label': 'Select a running instance',
                }}
                selectProps={{
                  'aria-label': 'Select a running instance',
                  isSearchable: false,
                  options: runningInstances?.map((instance) => ({
                    label: instance.name,
                    value: instance.id,
                  })),
                  value: {
                    value: data.instanceId,
                    label: runningInstances?.find((instance) => instance.id === data.instanceId)?.name ?? '',
                  },
                  onChange: (option) => {
                    if (!isNullish(option)) {
                      handleInstanceIdUpdate(option.value);
                    }
                  },
                }}
                errorText={validationError?.instanceId?.message}
                isDisabled={isNotNullish(runningInstances) && runningInstances.length === 0}
              />
            )}
            <TextInput
              label="Instance ID"
              isFluid
              isReadOnly
              htmlAttributes={{
                type: 'text',
                'aria-label': 'readonly input instance id',
              }}
              value={data.instanceId}
            />
            <TextInput
              label="Instance username"
              isFluid
              htmlAttributes={{
                type: 'text',
                'aria-label': 'input instance username',
              }}
              placeholder={credentialInputPlaceholder}
              value={data.instanceUsername}
              onChange={(event) => handleUsernameUpdate(event.target.value)}
              errorText={validationError?.instanceUsername?.message}
              informationIconText={mode === MODIFY_MODE.edit && 'This will overwrite the current value'}
            />
            <TextInput
              label="Instance password"
              isFluid
              htmlAttributes={{
                type: 'password',
                'aria-label': 'input instance username',
              }}
              placeholder={credentialInputPlaceholder}
              value={data.instancePassword}
              onChange={(event) => handlePasswordUpdate(event.target.value)}
              errorText={validationError?.instancePassword?.message}
              informationIconText={mode === MODIFY_MODE.edit && 'This will overwrite the current value'}
            />
          </div>
        )}
      </Accordion.Item>
      <Accordion.Item
        title="Type definitions"
        itemId="typeDefs"
        className="bg-neutral-bg-weak border-palette-neutral-border-weak accordion-item-no-padding rounded-4xl mb-6 [&>div:last-child]:hidden"
      >
        {isLoading ? (
          LoadingState('large')
        ) : (
          <div className="flex flex-col space-y-2">
            <div className="ml-auto">
              <Checkbox
                label={LabelEnableSubgraph()}
                ariaLabel="checkbox enable subgraph"
                htmlAttributes={{
                  'aria-label': 'checkbox enable subgraph',
                }}
                isChecked={data.featuresSubgraph ?? false}
                onChange={(event) => handleSubgraphUpdate(event.target.checked)}
              />
            </div>
            <TextArea
              size="medium"
              isFluid
              ariaLabel="textarea type definitions"
              htmlAttributes={{
                'aria-label': 'textarea type definitions',
                onChange: (event) => handleTypeDefsUpdate(event.target.value),
              }}
              className="[&>label>div>textarea]:h-[500px]"
              style={{ resize: 'both' }}
              value={data.typeDefinitions}
              errorText={validationError?.typeDefinitions?.message}
            />
          </div>
        )}
      </Accordion.Item>
      <Accordion.Item
        title="Cross-Origin Resource Sharing (CORS) policy"
        itemId="corsPolicy"
        className="bg-neutral-bg-weak border-palette-neutral-border-weak accordion-item-no-padding rounded-4xl mb-6 [&>div:last-child]:hidden"
      >
        {isLoading ? (
          LoadingState('small')
        ) : (
          <AllowedOriginsList
            data={data}
            setData={setData}
            validationError={validationError}
            setValidationError={setValidationError}
          />
        )}
      </Accordion.Item>
      <Accordion.Item
        title="Authentication providers"
        itemId="authProviders"
        className="bg-neutral-bg-weak border-palette-neutral-border-weak accordion-item-no-padding rounded-4xl mb-6 [&>div:last-child]:hidden"
      >
        {isLoading ? (
          LoadingState('small')
        ) : (
          <AuthProviderList
            mode={mode}
            data={data}
            setData={setData}
            validationError={validationError}
            setValidationError={setValidationError}
          />
        )}
      </Accordion.Item>
      <Accordion.Item
        title="Sizing"
        itemId="sizing"
        className="bg-neutral-bg-weak border-palette-neutral-border-weak accordion-item-no-padding rounded-4xl [&>div:last-child]:hidden"
      >
        {isLoading ? (
          LoadingState('small')
        ) : (
          <SizingTable selectedInstance={runningInstances?.find((instance) => instance.id === data.instanceId)} />
        )}
      </Accordion.Item>
    </Accordion>
  );
};
