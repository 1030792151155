import moment from 'moment';

import { log } from '../../services/logging';
import { getEscapedDoubleQuoteString, getSafeBackTicksString } from '../graph/cypherUtils';

export const integerTypes = ['integer', 'int', 'bigint'];
export const numericTypes = ['number', 'float', ...integerTypes];
const binaryTypes = ['bool', 'boolean'];

const specificDateTimeFormats = [
  'YYYY',
  'YYYY-M',
  'YYYY-M-D',
  'H:',
  'H:m',
  'H:m:s',
  'H:m:s.SSSSSSSSS',
  'H:mZZ',
  'H:m:sZZ',
  'H:m:s.SSSSSSSSSZZ',
  'YYYY-M-DTH',
  'YYYY-M-DTH:m',
  'YYYY-M-DTH:m:s',
  'YYYY-M-DTH:m:s.SSSSSSSSS',
  'YYYY-M-DTH:m:s.SSSSSSSSSZZ',
  moment.ISO_8601,
];
const nanosecondRegex = /\.(\d{0,9})/i;
const relativeYearRegex = /^(?:(\d{5,9})(.*)|([+-]\d{4,9})(.*))$/i;

export const getOperator = (dataType) =>
  numericTypes.includes(dataType.toLowerCase()) || binaryTypes.includes(dataType.toLowerCase()) ? '=' : 'STARTS WITH';
export const adjustValue = (value, dataType) => {
  const normalisedType = dataType.toLowerCase();

  if (numericTypes.includes(normalisedType)) {
    return Number(value);
  } else if (binaryTypes.includes(normalisedType)) {
    return value.toLowerCase() === 'true';
  } else {
    return value;
  }
};

export const wrapString = (value) => {
  if (/^".*"$/.test(value)) {
    return value;
  } else {
    return value.replace ? `"${getEscapedDoubleQuoteString(value)}"` : `"${value}"`;
  }
};

export const wrapValue = (value, dataType) => {
  const normalisedType = dataType && dataType.toLowerCase();

  if (numericTypes.includes(normalisedType)) {
    return Number(value);
  } else if (binaryTypes.includes(normalisedType)) {
    return value;
  } else if (value === 'true' || value === 'false') {
    return value === 'true';
  } else if (/^".*"$/.test(value)) {
    return value;
  } else {
    return value.replace ? `"${getEscapedDoubleQuoteString(value)}"` : value;
  }
};

export const getDatetimeRangeCypher = (propertyKey, dataType, value, alias = 'n') => {
  const parsedDateTime = parseDateTimeForSearch(value);
  let range = null;
  const normalisedType = dataType.toLowerCase();

  if (parsedDateTime) {
    let neoType = '';
    let dateFormat = '';
    const momValue = parsedDateTime.momValue;

    const getPeriodRange = (part, minDateFormat) => {
      const maxDateFormat = minDateFormat.replace('S', 'SSS');
      return parsedDateTime[part]
        ? [
            formatDateTimeWithModifiers(moment(momValue).endOf(part), maxDateFormat, parsedDateTime),
            formatDateTimeWithModifiers(moment(momValue).startOf(part), minDateFormat, parsedDateTime),
          ]
        : null;
    };

    switch (normalisedType) {
      case 'date':
        neoType = 'date';
        dateFormat = "'YYYY-MM-DD'";

        if (parsedDateTime.time || parsedDateTime.timezone) {
          return null;
        }

        if (parsedDateTime.day) {
          range = [formatDateTimeWithModifiers(momValue, dateFormat, parsedDateTime)];
        } else {
          range = getPeriodRange('month', dateFormat) || getPeriodRange('year', dateFormat);
        }
        break;
      case 'localtime':
        neoType = 'localTime';
        dateFormat = '[{ hour: ]H[, minute: ]m[, second: ]s[, millisecond: ]S[ }]';

        if (parsedDateTime.date || parsedDateTime.timezone) {
          return null;
        }

        if (parsedDateTime.millis) {
          range = formatDateTimeWithNanoseconds(momValue, dateFormat, parsedDateTime);
        } else {
          range =
            getPeriodRange('second', dateFormat) ||
            getPeriodRange('minute', dateFormat) ||
            getPeriodRange('hour', dateFormat);
        }
        break;
      case 'localdatetime':
        neoType = 'localDateTime';
        dateFormat = '[{ year: ]YYYY[, month: ]M[, day: ]D[, hour: ]H[, minute: ]m[, second: ]s[, millisecond: ]S[ }]';

        if (parsedDateTime.timezone) {
          return null;
        }

        if (parsedDateTime.millis) {
          range = formatDateTimeWithNanoseconds(momValue, dateFormat, parsedDateTime);
        } else {
          range =
            getPeriodRange('second', dateFormat) ||
            getPeriodRange('minute', dateFormat) ||
            getPeriodRange('hour', dateFormat) ||
            getPeriodRange('day', dateFormat) ||
            getPeriodRange('month', dateFormat) ||
            getPeriodRange('year', dateFormat);
        }
        break;
      case 'time':
        neoType = 'time';
        dateFormat = '[{ hour: ]H[, minute: ]m[, second: ]s[, millisecond: ]S[ }]';

        if (parsedDateTime.date) {
          return null;
        }

        if (parsedDateTime.millis) {
          range = formatDateTimeWithNanoseconds(momValue, dateFormat, parsedDateTime);
        } else {
          range =
            getPeriodRange('second', dateFormat) ||
            getPeriodRange('minute', dateFormat) ||
            getPeriodRange('hour', dateFormat);
        }
        break;
      case 'datetime':
        neoType = 'datetime';
        dateFormat = '[{ year: ]YYYY[, month: ]M[, day: ]D[, hour: ]H[, minute: ]m[, second: ]s[, millisecond: ]S[ }]';

        if (parsedDateTime.millis) {
          range = formatDateTimeWithNanoseconds(momValue, dateFormat, parsedDateTime);
        } else {
          range =
            getPeriodRange('second', dateFormat) ||
            getPeriodRange('minute', dateFormat) ||
            getPeriodRange('hour', dateFormat) ||
            getPeriodRange('day', dateFormat) ||
            getPeriodRange('month', dateFormat) ||
            getPeriodRange('year', dateFormat);
        }
        break;

      default:
        log.info('Type not recognised');
        return null;
    }

    if (range?.length === 1) {
      return `${alias}.\`${getSafeBackTicksString(propertyKey)}\` = ${neoType}(${range[0]})`;
    }
    if (range?.length === 2) {
      return `${neoType}(${range[0]}) >= ${alias}.\`${getSafeBackTicksString(propertyKey)}\` >= ${neoType}(${range[1]})`;
    }
    return null;
  }
};

export const parseDateTimeForSearch = (value) => {
  // detect if relative year is present, cannot be handled by Moment
  const valueWithRelativeYear = parseDateTimeWithRelativeYear(value);
  if (valueWithRelativeYear) {
    return valueWithRelativeYear;
  }

  // Try a specific DateTime parsing
  let momValue = specificDateTimeFormats.map((format) => moment(value, format, true)).find((mom) => mom.isValid());

  if (!momValue) {
    return null;
  }
  const momFormat = momValue.creationData().format;

  if (!momFormat) {
    return null;
  }

  let timeZoneOffsetSeconds = 0;
  if (momFormat.match('Z')) {
    momValue = moment.parseZone(value, momFormat);
    timeZoneOffsetSeconds = momValue.utcOffset() * 60;
  }

  let nanosecondRange = [];
  let nanoseconds = 0;
  if (momFormat.match('S')) {
    nanoseconds = `${momValue.millisecond()}`;
    const match = value.match(nanosecondRegex);
    if (match) {
      nanoseconds = match[1];
    }
    nanosecondRange =
      nanoseconds.length === 9
        ? [parseInt(nanoseconds)]
        : [parseInt(nanoseconds.padEnd(9, '9')), parseInt(nanoseconds.padEnd(9, '0'))];
  }

  return {
    format: momFormat,
    momValue,
    nanoseconds: nanosecondRange[1] || nanosecondRange[0] || 0,
    nanosecondRange,
    timeZoneOffsetSeconds,
    timezone: !!momFormat.match('Z'),
    year: !!momFormat.match('Y'),
    month: !!momFormat.match('M'),
    day: !!momFormat.match('D'),
    second: !!momFormat.match('s'),
    minute: !!momFormat.match('m'),
    millis: !!momFormat.match('S'),
    hour: !!momFormat.match('H'),
    time: !!(momFormat.match('s') || momFormat.match('m') || momFormat.match('H')),
    date: !!(momFormat.match('Y') || momFormat.match('M') || momFormat.match('D')),
  };
};

const parseDateTimeWithRelativeYear = (value) => {
  const relativeYearMatch = value && value.match(relativeYearRegex);

  if (relativeYearMatch) {
    const relativeYear = parseInt(relativeYearMatch[1] || relativeYearMatch[3]);
    const rest = relativeYearMatch[2] || relativeYearMatch[4];

    // used a leap year 2020 so we are compatibile with most of the years
    const valueWithFakeYear = `2020${rest}`;
    const restParsed = parseDateTimeForSearch(valueWithFakeYear);
    if (restParsed) {
      restParsed.relativeYear = relativeYear;
      return restParsed;
    }
  }
  return null;
};

const formatDateTimeWithModifiers = (momValue, format, parseObj) => {
  if (parseObj.year && parseObj.relativeYear) {
    // Replace YYYY with the relativeYear
    format = format.replace('YYYY', `${parseObj.relativeYear >= 0 ? '+' : ''}${parseObj.relativeYear}`);
  }

  if (parseObj.timezone) {
    // Add Timezone
    format = format.replace(' }]', ", timezone:']Z[' }]");
  }

  return momValue.format(format);
};

const formatDateTimeWithNanoseconds = (momValue, format, parseObj) => {
  if (parseObj.year && parseObj.relativeYear) {
    // Replace YYYY with the relativeYear
    format = format.replace('YYYY', `${parseObj.relativeYear >= 0 ? '+' : ''}${parseObj.relativeYear}`);
  }

  if (parseObj.timezone) {
    // Add Timezone
    format = format.replace(' }]', ", timezone:']Z[' }]");
  }

  if (parseObj.millis && parseObj.nanosecondRange.length > 0) {
    // Replace milliseconds with nanoseconds
    format = format.replace('millisecond', 'nanosecond');
    return parseObj.nanosecondRange.map((ns) => momValue.format(format.replace(']S[', ns)));
  }

  return [];
};
