import { Banner, Button, Checkbox, Dialog, TextInput, Tooltip } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { consoleApi, getApiError, getErrorMessage } from '@nx/state';
import type { CustomEndpoint, Instance } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';

import { constructCustomEndpointUrl } from './helpers';

const logger = createLogger(APP_SCOPE.aura);

type Props = {
  onClose: () => void;
  instance?: Instance;
  endpoint: CustomEndpoint;
};

export const DeleteCustomEndpointDialog = ({ onClose, instance, endpoint }: Props) => {
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const [deleteCustomEndpoint, deleteCustomEndpointRes] = consoleApi.useDeleteCustomEndpointMutation();

  const handleConfirm = (checked: boolean) => {
    setHasConfirmed(checked);
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    deleteCustomEndpoint({ id: endpoint.id })
      .unwrap()
      .then(() => {
        onClose();
      })
      .catch((error: FetchBaseQueryError | SerializedError | undefined) => {
        const apiError = getApiError(error);
        const message = getErrorMessage(apiError);
        logger.error(message);
      });
  };

  return (
    <Dialog isOpen onClose={onClose}>
      <Dialog.Header>Delete custom endpoint</Dialog.Header>
      <form onSubmit={handleSubmit}>
        <Dialog.Content className="flex flex-col gap-5">
          <div className="flex flex-col gap-4">
            <TextInput label="Custom endpoint" value={constructCustomEndpointUrl(endpoint)} isReadOnly isFluid />
            {isNotNullish(instance) && <TextInput label="Assigned instance" value={instance.name} isReadOnly isFluid />}
          </div>
          {isNotNullish(instance) ? (
            <Banner
              hasIcon
              type="warning"
              description="Deleting this custom endpoint without updating your application may cause disruptions requiring manual resolution. Customer Support will be unable to assist with resulting issues."
              usage="inline"
            />
          ) : (
            <Banner
              hasIcon
              type="info"
              description="This custom endpoint is not assigned to an instance, so deleting it should not cause any disruptions. Please note that the URL cannot be reused for any new custom endpoint."
              usage="inline"
            />
          )}
          {deleteCustomEndpointRes.isError && (
            <Banner
              hasIcon
              type="danger"
              description={getErrorMessage(getApiError(deleteCustomEndpointRes.error))}
              usage="inline"
            />
          )}
        </Dialog.Content>
        <Dialog.Actions className="flex items-center justify-between">
          <Checkbox
            isChecked={hasConfirmed}
            onChange={(event) => handleConfirm(event.target.checked)}
            label="I understand"
          />
          <div className="flex gap-4 ">
            <Button color="neutral" fill="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Tooltip type="simple" isDisabled={hasConfirmed} placement="top" isPortaled={false}>
              <Tooltip.Trigger hasButtonWrapper>
                <Button
                  color="danger"
                  type="submit"
                  isLoading={deleteCustomEndpointRes.isLoading}
                  isDisabled={!hasConfirmed}
                >
                  Delete
                </Button>
              </Tooltip.Trigger>
              <Tooltip.Content className="!fixed !w-[200px]">
                You need to confirm that you understand the consequences of deleting a custom endpoint
              </Tooltip.Content>
            </Tooltip>
          </div>
        </Dialog.Actions>
      </form>
    </Dialog>
  );
};
