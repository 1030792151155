import { createSlice } from '@reduxjs/toolkit';

export interface UploadDumpState {
  progress: number;
  status: 'idle' | 'uploading' | 'complete' | 'error';
  message?: string;
  error?: string;
}

const initialState: UploadDumpState = {
  progress: 0,
  status: 'idle',
};

const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    startUpload: (state) => {
      state.status = 'uploading';
      state.progress = 0;
    },
    updateUploadProgress: (state, action) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      state.progress = action.payload;
    },
    completeUpload: (state) => {
      state.status = 'complete';
    },
    failUpload: (state) => {
      state.status = 'error';
    },
    resetUploadState: (state) => {
      if (state.status !== 'uploading') {
        state.status = 'idle';
        state.progress = 0;
        state.message = undefined;
        state.error = undefined;
      }
    },
  },
});

export const { startUpload, updateUploadProgress, completeUpload, failUpload, resetUploadState } = uploadSlice.actions;
export const { reducer, reducerPath } = uploadSlice;
export default uploadSlice.reducer;
