import type { APP_SCOPE } from '@nx/constants';
import { makeSelectFeatureFlag, LEGACY_store as store } from '@nx/state';
import type { CSPair } from 'ansi-styles';
import styles from 'ansi-styles';
import loglevel from 'loglevel';
import prefix from 'loglevel-plugin-prefix';

export interface Logger {
  trace: (...msg: unknown[]) => void;
  debug: (...msg: unknown[]) => void;
  info: (...msg: unknown[]) => void;
  warn: (...msg: unknown[]) => void;
  error: (...msg: unknown[]) => void;
}

const COLORS: Record<string, CSPair> = {
  TRACE: styles.magenta,
  DEBUG: styles.cyan,
  INFO: styles.blue,
  WARN: styles.yellow,
  ERROR: styles.red,
};

const withColor = (color: CSPair | undefined, msg: string) => `${color?.open ?? ''}${msg}${color?.close ?? ''}`;

prefix.reg(loglevel);
const configurePrefix = (logger: loglevel.Logger) => {
  prefix.apply(logger, {
    format(level, _, timestamp) {
      const color = COLORS[level.toUpperCase()];

      return `${withColor(styles.gray, timestamp.toString())} ${withColor(color, level)}`;
    },
  });
};

export function createLogger(scope: APP_SCOPE): Logger {
  const logger = loglevel.getLogger(scope);
  const defaultLoggerLevel = import.meta.env.DEV ? logger.levels.INFO : logger.levels.ERROR;
  logger.setLevel(defaultLoggerLevel);
  configurePrefix(logger);

  const selectDebugEnabled = makeSelectFeatureFlag('nx:enable-debug');
  store.subscribe(() => {
    const debugEnabled = selectDebugEnabled(store.getState());
    const targetLevel = debugEnabled ? logger.levels.TRACE : defaultLoggerLevel;
    if (targetLevel !== logger.getLevel()) {
      logger.setLevel(targetLevel);
    }
  });

  const log = (level: loglevel.LogLevelNames, msg: unknown[]) =>
    logger[level](`${withColor(styles.green, scope)}:`, ...msg);

  return {
    trace: (...msg) => log('trace', msg),
    debug: (...msg) => log('debug', msg),
    info: (...msg) => log('info', msg),
    warn: (...msg) => log('warn', msg),
    error: (...msg) => log('error', msg),
  };
}
