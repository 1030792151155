import type { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';

import type * as Configuration from '../slices/configuration/configuration-slice';
import type * as Desktop from '../slices/desktop/desktop';

export type DynamicBaseQueryFn = BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  object,
  FetchBaseQueryMeta
>;

export function createDynamicBaseQuery<
  State extends {
    [Configuration.slice.name]: Configuration.ConfigurationState;
    [Desktop.slice.name]: Desktop.DesktopState;
  },
>(
  apiUrlSelector: (state: State) => string | undefined,
  baseQueryParams: Parameters<typeof fetchBaseQuery>[0] = {},
  stateSelector: (() => State) | undefined = undefined,
): DynamicBaseQueryFn {
  const rawBaseQuery = fetchBaseQuery(baseQueryParams);

  // This is to build the base URL dynamically from state
  // https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#constructing-a-dynamic-base-url-using-redux-state
  return async (args, api, extraOptions) => {
    const getState = stateSelector ?? api.getState;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const apiUrl = apiUrlSelector(getState() as State);
    const urlPath = typeof args === 'string' ? args : args.url;

    if (apiUrl === undefined || apiUrl.trim().length === 0) {
      return {
        error: {
          error: 'API URL not defined',
          status: 400,
          data: 'API URL not defined',
        },
      };
    }

    const adjustedUrl = `${apiUrl.replace(/\/$/, '')}/${urlPath.replace(/^\//, '')}`;

    // provide the amended url and other params to the raw base query
    const adjustedBaseQueryArgs = typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };

    try {
      return await rawBaseQuery(adjustedBaseQueryArgs, api, extraOptions);
    } catch (error) {
      return {
        error: {
          // FIXME: Implement more granular error handling
          error: 'Unknown error',
          status: 400,
        },
      };
    }
  };
}
