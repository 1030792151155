import type { NewOauthClientResponse, OauthClientResponse } from '../console.api.types';
import type { NewOauthClient, OauthClient } from '../console.types';

export const transformOauthClientResponse = (response: OauthClientResponse): OauthClient => ({
  name: response.name,
  clientId: response.client_id,
  createdAt: response.created_at,
  userId: response.user_id,
});

export const transformNewOauthClientResponse = (response: NewOauthClientResponse): NewOauthClient => ({
  ...transformOauthClientResponse(response),
  clientSecret: response.client_secret,
});
