import { ForceDirectedLayoutType } from '@neo4j-nvl/base';
import type { UnknownAction } from '@reduxjs/toolkit';
import { isEqual, isNil, omit, pick } from 'lodash-es';

import { isForbiddenError } from '../../services/errors/errorUtils';
import { log } from '../../services/logging';
import { saveNewScene, updateScene } from '../../services/scene';
import { isVersion5OorGreater } from '../../services/versions/versionUtils';
import type { Scene } from '../../types/scene';
import type { Style } from '../../types/style';
import type { Nullable } from '../../types/utility';
import {
  getIsFeatureAvailable,
  getServerVersion,
  getUserCanSaveScene,
  getUserId,
  setDatabase,
} from '../connections/connectionsDuck';
import { getFilterRules } from '../filter/filter';
import { getGdsState } from '../gds/gds';
import { getVisibleRelationships } from '../graph/relationships';
import type { Persistor } from '../persistence/types';
import { getVisibleLabels, getVisibleRelationshipTypes } from '../perspectives/perspectives';
import { getPerspective } from '../perspectives/perspectives.selector';
import { unsetCurrentPerspective } from '../rootReducer';
import { getEnableCytoscape } from '../settings/settings';
import { getRanges } from '../slicer/slicer';
import type { store as RootStore } from '../store';
import { STYLE_TYPE_SCENE, getCurrentStyle, getStyleById, getStyleType } from '../styles/styles';
import type { AppDispatch, RootState } from '../types';
import { getLayoutType, getVizState } from '../visualization/visualization';
import { getLatestSceneVersion } from './migrations';
import {
  SCENE_EDIT_MODE_EDITING,
  duplicateScene,
  getCurrentNodePositions,
  getCurrentScene,
  getIsLoadingScene,
  getSceneById,
  getSceneEditMode,
  getScenes,
  setEditMode,
  updateSceneGraphCounters,
  updateSceneLastModified,
  updateSceneName,
  updateSceneRoles,
  updateTemporarySceneData,
} from './scene';
import { applySelectedScene } from './sceneActions';

class ScenePersistor implements Persistor {
  previousScene: Partial<Scene>;

  writePermission: undefined;

  constructor() {
    this.previousScene = {};
    this.writePermission = undefined;
  }

  shouldLoadState({ state, action }: { state: RootState; action: UnknownAction }) {
    if (isNil(state?.connections?.availableProcedures)) {
      return false;
    }
    const hasPlugin = getIsFeatureAvailable(state, 'perspectiveSharing');

    return hasPlugin && this.needToCheckForWritePermissions(action);
  }

  needToCheckForWritePermissions(action: Nullable<UnknownAction>) {
    return isNil(action) || setDatabase.match(action);
  }

  async loadFromStorage({
    store,
    payload,
    action,
  }: {
    store: typeof RootStore;
    payload: object;
    action: UnknownAction;
  }) {
    const { dispatch, getState } = store;
    const state = getState();

    const isFirstLogin = isNil(getPerspective(state));

    if (isNil(action)) {
      if (!isFirstLogin) {
        dispatch(unsetCurrentPerspective());
      }
    }

    return payload;
  }

  shouldSaveState({ state, action }: { state: RootState; action: Nullable<UnknownAction> }) {
    const perspective = !isNil(state.perspectives) ? getPerspective(state) : null;
    const sceneDetails = !isNil(state.scene) ? getCurrentScene(state) : null;
    const userCanSaveScenePrivilege = getUserCanSaveScene(state);
    const userIsSceneOwner = sceneDetails?.createdBy === getUserId(state);
    const userCanEdit = !isNil(state.scene) ? getSceneEditMode(state) === SCENE_EDIT_MODE_EDITING : false;
    const perspectiveIsNotSaved = isNil(perspective?.sha);
    const hasDatabaseChanged = !isNil(action) && setDatabase.match(action);
    const isTemporaryScene = !isNil(sceneDetails?.temporaryData);

    if (hasDatabaseChanged) {
      return false;
    }

    if ((perspectiveIsNotSaved || !userCanSaveScenePrivilege) && !isTemporaryScene) {
      return false;
    }

    if (!isNil(action) && duplicateScene.match(action)) {
      return true;
    }

    if (!userCanEdit) {
      return false;
    }

    if (action?.type === updateSceneName.toString()) {
      return true;
    }

    if (action?.type === updateSceneRoles.toString()) {
      return true;
    }

    if (userIsSceneOwner && !getIsLoadingScene(state)) {
      const newFullScene = this.getFullSceneFromCurrentGraph(state, sceneDetails);
      const newSceneForComparison = this.keepPropertiesThatCanTriggerSceneSaving(newFullScene);
      const isSceneEqual = isEqual(this.previousScene, newSceneForComparison);
      return !isSceneEqual;
    }

    return false;
  }

  async saveToStorage({ state, dispatch, action }: { state: RootState; dispatch: AppDispatch; action: UnknownAction }) {
    if (duplicateScene.match(action)) {
      await this.saveDuplicateScene(state, dispatch, action.payload);
      return;
    }

    if ((updateSceneName.match(action) || updateSceneRoles.match(action)) && !isNil(action.payload.sceneId)) {
      await this.saveOnlySceneDetails(state, dispatch, action.payload.sceneId);
      return;
    }

    const sceneDetails = getCurrentScene(state);
    const isTemporaryScene = Boolean(sceneDetails?.temporaryData);

    if (isTemporaryScene) {
      await this.saveTemporaryScene(state, dispatch);
    } else {
      await this.saveCurrentScene(state, dispatch);
    }
  }

  keepPropertiesThatCanTriggerSceneSaving(fullScene: Scene) {
    // Do not save when LayoutType changes but only when the node position changes
    return omit(fullScene, ['lastModified', 'visualisation.zoomLevel', 'visualisation.layoutType']);
  }

  async saveDuplicateScene(state: RootState, dispatch: AppDispatch, scene: Scene) {
    const currentStyle: Partial<Style> = {
      ...getCurrentStyle(state),
      id: scene.id,
      type: STYLE_TYPE_SCENE,
    };
    const sceneToStore = this.getFullSceneFromCurrentGraph(state, scene, currentStyle);

    try {
      await updateScene(sceneToStore);
      dispatch(setEditMode(SCENE_EDIT_MODE_EDITING));
      await this.selectScene(state, dispatch, scene.id);
    } catch (err) {
      if (isForbiddenError(err)) {
        log.info('User is not allowed to update Scene');
        log.debug(err);
      } else {
        log.error('Error while duplicating scene');
        log.error(err);
      }
    }
  }

  async saveOnlySceneDetails(state: RootState, dispatch: AppDispatch, sceneId: Scene['id']) {
    const sceneDetails = getSceneById(state)(sceneId);
    try {
      if (sceneDetails === undefined) {
        throw new Error('Scene not found');
      }
      const savedScene = await updateScene(sceneDetails);
      if (isNil(savedScene)) {
        throw new Error('Scene could not be updated');
      }
      dispatch(updateSceneLastModified({ sceneId: savedScene.id, lastModified: savedScene.lastModified }));
    } catch (err) {
      if (isForbiddenError(err)) {
        log.info('User is not allowed to update Scene');
        log.debug(err);
      } else {
        log.error('Error while saving scene');
        log.error(err);
      }
    }
  }

  async saveTemporaryScene(state: RootState, dispatch: AppDispatch) {
    const perspective = getPerspective(state);
    if (!perspective) return;
    const sceneDetails = getCurrentScene(state);
    const fullScene = this.getFullSceneFromCurrentGraph(state, sceneDetails);
    fullScene.perspectiveId = perspective.id;

    dispatch(updateTemporarySceneData({ sceneId: fullScene.id, temporaryData: fullScene }));
    dispatch(
      updateSceneGraphCounters({
        numOfNodes: fullScene.numOfNodes,
        numOfRels: fullScene.numOfRels,
      }),
    );

    this.previousScene = this.keepPropertiesThatCanTriggerSceneSaving(fullScene);
    delete this.previousScene.perspectiveId;
  }

  async saveCurrentScene(state: RootState, dispatch: AppDispatch) {
    const perspective = getPerspective(state);
    const sceneDetails = getCurrentScene(state);
    const fullScene = this.getFullSceneFromCurrentGraph(state, sceneDetails);
    const sceneToStore = { ...fullScene };
    const isNewScene = isNil(sceneDetails?.lastModified);

    try {
      if (!perspective) {
        throw new Error('Perspective not found');
      }
      if (isNewScene) {
        await saveNewScene(perspective.id, sceneToStore);
      }

      if (isEqual(this.previousScene.nodes, sceneToStore.nodes)) {
        delete sceneToStore.nodes;
        delete sceneToStore.relationships;

        if (this.previousScene.visualisation === sceneToStore.visualisation) {
          delete sceneToStore.visualisation;
        }
        sceneToStore.numOfRels = sceneDetails?.numOfRels ?? 0;
      }

      if (this.previousScene.filters === sceneToStore.filters) {
        delete sceneToStore.filters;
      }

      if (this.previousScene.ranges === sceneToStore.ranges) {
        delete sceneToStore.ranges;
      }

      if (this.previousScene.gds === sceneToStore.gds) {
        delete sceneToStore.gds;
      }

      if (sceneToStore.style != null && isEqual(this.previousScene.style, sceneToStore.style)) {
        delete sceneToStore.style;
      }

      const savedScene = await updateScene(sceneToStore);

      if (isNil(savedScene)) {
        throw new Error('Scene not found');
      }

      if (sceneDetails?.numOfNodes !== savedScene.numOfNodes || sceneDetails?.numOfRels !== savedScene.numOfRels) {
        dispatch(
          updateSceneGraphCounters({
            numOfNodes: savedScene.numOfNodes,
            numOfRels: savedScene.numOfRels,
          }),
        );
      }

      dispatch(updateSceneLastModified({ sceneId: savedScene.id, lastModified: savedScene.lastModified }));

      this.previousScene = this.keepPropertiesThatCanTriggerSceneSaving(fullScene);
    } catch (err) {
      if (isForbiddenError(err)) {
        log.info('User is not allowed to update scene');
        log.debug(err);
      } else {
        log.error('Error while saving scene');
        log.error(err);
      }
    }
  }

  async selectScene(state: RootState, dispatch: AppDispatch, sceneId: Scene['id']) {
    const perspective = getPerspective(state);
    if (!perspective) return;
    const userId = getUserId(state);
    const perspectiveStyle = getStyleById(state)(perspective.id);
    const enableCytoscape = getEnableCytoscape(state);
    const scenes = getScenes(state);
    const isV5OrGreater = isVersion5OorGreater(getServerVersion(state)) ?? false;

    if (!perspective || !userId || !perspectiveStyle) {
      return;
    }
    return applySelectedScene({
      dispatch,
      perspective,
      userId,
      perspectiveStyle,
      visibleRelationshipTypes: getVisibleRelationshipTypes(state),
      visibleLabels: getVisibleLabels(state),
      styleType: getStyleType(state),
      layoutType: getLayoutType(state),
      enableCytoscape,
      scenes,
      isV5OrGreater,
    })(sceneId);
  }

  getFullSceneFromCurrentGraph(state: RootState, scene?: Nullable<Scene>, style?: Partial<Style>): Scene {
    const sceneDetails = scene ?? getCurrentScene(state);
    const nodePositions = getCurrentNodePositions(state) ?? [];
    const relationships =
      nodePositions.length > 0 && !isNil(state?.graph?.present?.relationships?.visible)
        ? getVisibleRelationships(state).map(({ id }) => id)
        : [];
    const filters = getFilterRules(state) ?? [];
    const ranges = getRanges(state) ?? [];
    const gds = pick(getGdsState(state) ?? {}, ['gdsRules', 'gdsResults']);
    const visualisation = pick(getVizState(state) ?? {}, [
      'layoutType',
      'layoutOptions',
      'zoomLevel',
      'panCoordinates',
      'layoutDirection',
    ]);
    const styleToSave = style ?? getStyleById(state)(sceneDetails?.id);
    const version = getLatestSceneVersion();
    if (isNil(version)) throw new Error('Client version not available');

    const sceneToReturn: Scene = {
      ...omit(sceneDetails, 'temporaryData'),
      version,
      numOfNodes: nodePositions.length,
      numOfRels: relationships.length,
      // @ts-expect-error ts cant conclude valid layoutDirection
      visualisation: { layoutDirection: ForceDirectedLayoutType, ...visualisation },
      nodes: nodePositions,
      filters,
      ranges: ranges.map((range) => ({
        ...range,
        isActive: false,
      })),
      gds,
      relationships,
      // @ts-expect-error cant infer valid scene type
      style: { type: STYLE_TYPE_SCENE, ...styleToSave },
    };
    return sceneToReturn;
  }
}

export default ScenePersistor;
