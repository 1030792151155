import type { OnChangeFn, SortingState } from '@tanstack/react-table';
import { functionalUpdate } from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';

import { DEFAULT_SORT_DETAILS, DEFAULT_SORT_SUMMARY } from '../../shared/constants';
import type { TabText } from '../../shared/types';
import type { LogOrAggregationSortInput } from '../../shared/utils';
import { mapControlledSorting } from '../../shared/utils';

export type SortingHook<T extends TabText> = {
  controlledSorting: SortingState;
  querySorting: LogOrAggregationSortInput<T>[];
  onSortingChange: OnChangeFn<SortingState>;
};

type UseSorting = <T extends TabText>(tableType: T) => SortingHook<T>;

export const useSorting: UseSorting = (tableType) => {
  const initialSorting = tableType === 'Summary' ? DEFAULT_SORT_SUMMARY : DEFAULT_SORT_DETAILS;
  const [controlledSorting, setControlledSorting] = useState(initialSorting);

  const querySorting = useMemo(
    () => mapControlledSorting(controlledSorting, tableType),
    [controlledSorting, tableType],
  );

  const onSortingChange: OnChangeFn<SortingState> = useCallback(
    (updater) => {
      const newSorting = functionalUpdate(updater, controlledSorting);
      setControlledSorting(newSorting);
    },
    [controlledSorting],
  );

  return { controlledSorting, querySorting, onSortingChange };
};
