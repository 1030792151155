import { IconButton } from '@neo4j-ndl/react';
import { MegaphoneIconOutline } from '@neo4j-ndl/react/icons';

import useCanny from '../services/canny/canny';
import classes from './canny-widget.module.css';

export function ChangelogButton() {
  useCanny();

  return (
    <IconButton
      isClean
      className={classes['canny-button']}
      ariaLabel="Changelog"
      onClick={() => undefined}
      htmlAttributes={{
        'data-canny-changelog': 'true',
        title: 'Changelog',
      }}
    >
      <MegaphoneIconOutline />
    </IconButton>
  );
}
