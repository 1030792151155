import { usePrevious } from '@neo4j-ndl/react';
import { useEffect, useRef } from 'react';
import UPlot from 'uplot';
import 'uplot/dist/uPlot.min.css';

import type { IsEqualProps } from '../charts/types';
import './plot.css';

type PlotProps = {
  width: number;
  height: number;
  data: UPlot.AlignedData;
  options: UPlot.Options;
  target?: ConstructorParameters<typeof UPlot>[2];
  onCreate?: (plot: UPlot) => void;
  onDestroy?: (plot: UPlot) => void;
};

type SameProps = IsEqualProps<PlotProps>;

const sameSize: SameProps = (prev, next) => prev.height === next.height && prev.width === next.width;

const sameOptions: SameProps = (prev, next) => prev.options === next.options;

const sameData: SameProps = (prev, next) => prev.data === next.data;

export const Plot = (props: PlotProps) => {
  const plotRef = useRef<UPlot | null>(null);
  const targetDivRef = useRef<HTMLDivElement | null>(null);

  const prevProps = usePrevious(props);
  const { width, height, options, data, target, onCreate, onDestroy } = props;

  const create = () => {
    const plotTarget = target ?? targetDivRef.current;
    if (!plotTarget) {
      return;
    }
    if (width === 0 && height === 0) {
      return;
    }

    const newPlot = new UPlot({ ...options, width, height }, data, plotTarget);
    plotRef.current = newPlot;
    onCreate?.(newPlot);
  };

  const destroy = (plot: UPlot | null) => {
    if (plot) {
      onDestroy?.(plot);
      plot.destroy();
      plotRef.current = null;
    }
  };

  useEffect(() => {
    create();

    return () => {
      destroy(plotRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (prevProps === undefined) {
      return;
    }

    if (!sameSize(prevProps, props)) {
      plotRef.current?.setSize({ width, height });
    }

    if (!sameOptions(prevProps, props)) {
      destroy(plotRef.current);
      create();
    } else if (!sameData(prevProps, props)) {
      if (!plotRef.current) {
        create();
      } else {
        plotRef.current.setData(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height, options, data, target]);

  return <div data-testid="uplot-main-div" ref={targetDivRef} />;
};
