import { Banner, Button, Divider, IconButton, Label, LoadingSpinner, Menu, Typography, toast } from '@neo4j-ndl/react';
import { EllipsisHorizontalIconOutline, PlayCircleIconOutline, StopCircleIconOutline } from '@neo4j-ndl/react/icons';
import type { LocalInstance, LocalInstanceInfo } from '@nx/state';
import {
  LOCAL_INSTANCE_STATUS,
  MODAL_TYPE,
  getRelateErrors,
  useConnection,
  useConnectionSelectorModal,
  useGetLocalInstanceQuery,
  useLazyGetCredentialsQuery,
  useListDatabasesQuery,
  useModal,
  useStartLocalInstanceMutation,
  useStopLocalInstanceMutation,
} from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import { ClipboardCopier } from '@nx/ui';
import cx from 'classnames';
import type { PropsWithChildren } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { OpenPathButton } from '../components/open-path-button';
import { CloneInstanceModal } from './clone-modal';
import { DeleteInstanceModal } from './delete';
import { dbStatusText, getOnlineStatusColor } from './entities/helpers';
import { DatabaseList } from './instance-card/database-list';

interface ContentProps extends PropsWithChildren {
  instance: LocalInstance | LocalInstanceInfo;
  fullPadding?: boolean;
}

const DbmsRootPath = ({ path }: { path: string | undefined }) => {
  if (isNullish(path)) {
    return null;
  }

  return (
    <div className="border-palette-neutral-border-weak flex justify-between rounded-lg border p-3">
      <div className="flex items-center gap-1 truncate">
        <Typography variant="body-small" className="text-palette-neutral-text-weak">
          Path:
        </Typography>
        <Typography variant="body-small" className="truncate text-ellipsis pr-6">
          {path}
        </Typography>
      </div>

      <OpenPathButton size="small" path={path}>
        Open folder
      </OpenPathButton>
    </div>
  );
};

const CardContent = ({ instance, fullPadding = false, children }: ContentProps) => {
  const classes = cx('mb-3 flex items-start justify-between gap-4 overflow-hidden ', {
    'p-6': fullPadding,
    'px-6 pt-6': !fullPadding,
  });
  return (
    <div className={classes} aria-label={`Instance card for ${instance.name}`}>
      <div className="flex min-w-32 flex-col flex-wrap gap-2 gap-x-4 gap-y-2 overflow-hidden">
        <h5 className="w-full truncate">{instance.name}</h5>

        <div className="flex items-center gap-2 py-1">
          <div className="flex items-center">
            <Typography
              variant="body-medium"
              className="text-palette-neutral-text-weaker truncate text-ellipsis text-nowrap italic"
            >
              ID: {instance.id}
            </Typography>
            <ClipboardCopier textToCopy={instance.id} ariaLabel="Copy to clipboard" />
          </div>
          <Divider orientation="vertical" />
          {'version' in instance && isNotNullish(instance.version) && (
            <Typography variant="body-medium" as="div" className="text-palette-neutral-text-weak flex flex-row gap-1">
              Version: {instance.version}
            </Typography>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};

interface WrapperProps extends PropsWithChildren {
  type?: 'config' | 'select';
}

const CardWrapper = ({ children }: WrapperProps) => {
  return (
    <div className="border-palette-neutral-border-strong bg-palette-neutral-bg-weak relative shrink-0 grow basis-[136px] rounded-3xl border">
      {children}
    </div>
  );
};

interface InstanceCardProps {
  instanceSummary: LocalInstance;
  onInstanceDetailsClick: () => void;
  onConnectionSuccess?: () => void;
  type?: 'config' | 'select';
}

const useToggleInstanceRunStatus = ({ instanceSummary }: { instanceSummary: LocalInstance }) => {
  const { data: instance } = useGetLocalInstanceQuery(instanceSummary.id, { pollingInterval: 5000 });
  const [start, startResult] = useStartLocalInstanceMutation({ fixedCacheKey: `dbms-start-${instanceSummary.id}` });
  const [stop, stopResult] = useStopLocalInstanceMutation();

  useEffect(() => {
    const result = startResult.isError ? startResult : stopResult;
    if (result.isError) {
      const errors = getRelateErrors(result.error);
      const toastMessage = (
        <span>
          <Typography variant="subheading-medium">{`Something went wrong while ${startResult.isError ? 'starting' : 'stopping'} instance "${instanceSummary.name}"`}</Typography>
          <br />
          {errors.length > 0 ? `Error: ${errors[0]?.message}` : null}
          {instance ? (
            <span className="mt-2 flex justify-end">
              <OpenPathButton path={`${instance.rootPath}\\logs\\neo4j.log`}>Locate log file</OpenPathButton>
            </span>
          ) : null}
        </span>
      );
      toast.danger(toastMessage, { isCloseable: true });
      result.reset();
    }
  }, [startResult, stopResult, instanceSummary, instance]);

  return {
    toggleRunStatus: instance?.status === LOCAL_INSTANCE_STATUS.STOPPED ? start : stop,
    isStopped: instance?.status === LOCAL_INSTANCE_STATUS.STOPPED,
    isStarted: instance?.status === LOCAL_INSTANCE_STATUS.STARTED,
    isLoading: startResult.isLoading || stopResult.isLoading,
  };
};

export const InstanceCard = ({
  instanceSummary,
  type = 'config',
  onInstanceDetailsClick,
  onConnectionSuccess,
}: InstanceCardProps) => {
  const {
    data: instance,
    isLoading,
    isError,
  } = useGetLocalInstanceQuery(instanceSummary.id, { pollingInterval: 5000 });
  const { data: databases } = useListDatabasesQuery(instanceSummary.id);
  const {
    toggleRunStatus,
    isStopped,
    isStarted,
    isLoading: startOrStopIsLoading,
  } = useToggleInstanceRunStatus({ instanceSummary });
  const [getInstanceCredentials] = useLazyGetCredentialsQuery();
  const { connect, disconnect, status, metadata } = useConnection();

  const createDatabaseDumpModal = useModal(MODAL_TYPE.LOCAL_INSTANCE_DB_CREATE_DUMP);
  const connectionSelectorModal = useConnectionSelectorModal();

  const menuRef = useRef(null);
  const [openDestroy, setOpenDestroy] = useState(false);
  const [openClone, setOpenClone] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onConnect = useCallback(() => {
    getInstanceCredentials(instanceSummary.id)
      .then(async ({ data }) => {
        if (data === null || data === undefined) {
          throw new Error('Credentials not found');
        }

        await connect({
          credentials: { type: 'basic', username: data.username },
          isAuraInstanceFromAPI: false,
          password: data.password,
          url: instanceSummary.connectionUri,
        });

        if (onConnectionSuccess) {
          onConnectionSuccess();
        }
      })
      .catch(() => {
        connectionSelectorModal.open({
          modalTabId: 'Remote',
          connectionDetails: {
            url: instanceSummary.connectionUri,
            credentials: { type: 'basic', username: 'neo4j' },
          },
        });
      });
  }, [connectionSelectorModal, instanceSummary, getInstanceCredentials, connect, onConnectionSuccess]);

  if (isLoading) {
    return (
      <CardWrapper type={type}>
        <CardContent instance={instanceSummary}>
          <div className="absolute inset-0 flex flex-col justify-center gap-1 place-self-center px-6 text-center">
            <LoadingSpinner size="large" className="mb-1 w-full self-center" />
          </div>
        </CardContent>
      </CardWrapper>
    );
  }

  if (isNullish(instance) && isError) {
    return (
      <CardWrapper type="config">
        <CardContent instance={instanceSummary} />
        {/* TODO: Improve error handling */}
        <div className="p-6">
          <Banner description="Something went wrong fetching your instance." type="danger" usage="inline" />
        </div>
      </CardWrapper>
    );
  }

  if (isNullish(instance)) {
    return null;
  }

  const isConnected = status.isConnected && isStarted && metadata?.url === instanceSummary.connectionUri;

  const disableActions = startOrStopIsLoading;

  return (
    <>
      <CardWrapper type={type}>
        <CardContent instance={instance}>
          <div className="flex gap-2">
            <Label fill="clean" hasIcon color={getOnlineStatusColor(instance)} className="font-normal uppercase">
              {dbStatusText(instance)}
            </Label>
            {isStopped ? (
              <IconButton
                onClick={() => {
                  void toggleRunStatus(instance.id);
                }}
                isDisabled={disableActions}
                ariaLabel="Start instance"
                size="small"
              >
                <PlayCircleIconOutline />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  void toggleRunStatus(instance.id);
                }}
                isDisabled={disableActions}
                ariaLabel="Stop instance"
                size="small"
              >
                <StopCircleIconOutline />
              </IconButton>
            )}
            <Button
              fill="outlined"
              color="neutral"
              size="small"
              isDisabled={isStopped || status.isConnecting}
              onClick={isConnected ? disconnect : onConnect}
            >
              {isConnected ? 'Disconnect' : 'Connect'}
            </Button>
            {type === 'config' ? (
              <IconButton ref={menuRef} ariaLabel="More instance actions" onClick={handleClick} size="small">
                <EllipsisHorizontalIconOutline className="h-full w-full" />
              </IconButton>
            ) : null}
            <Menu
              isOpen={isMenuOpen}
              anchorRef={menuRef}
              onClose={handleClick}
              // TODO: Fix menu positioning
              /* anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              transformOriginOffsetFromAnchorOrigin={{ horizontal: 0, vertical: 4 }}*/
            >
              <Menu.Items>
                <Menu.Item
                  title="Inspect"
                  onClick={() => {
                    onInstanceDetailsClick();
                    setIsMenuOpen(false);
                  }}
                />
                <Menu.Item
                  title="Export databases to .dump"
                  onClick={() => {
                    createDatabaseDumpModal.open({ dbmsId: instance.id, rootPath: instance.rootPath });
                    setIsMenuOpen(false);
                  }}
                  isDisabled={instance.status !== LOCAL_INSTANCE_STATUS.STOPPED}
                  htmlAttributes={{
                    title:
                      instance.status !== LOCAL_INSTANCE_STATUS.STOPPED ? 'Pause instance to export .dump' : undefined,
                  }}
                />
                <Menu.Item
                  title="Clone"
                  onClick={() => {
                    setOpenClone(true);
                    setIsMenuOpen(false);
                  }}
                />
                <Menu.Divider />
                <Menu.Item
                  title="Delete"
                  className="text-palette-danger-text"
                  isDisabled={false}
                  onClick={() => {
                    setOpenDestroy(true);
                    setIsMenuOpen(false);
                  }}
                />
              </Menu.Items>
            </Menu>
          </div>
        </CardContent>

        <div className="flex flex-col gap-2 p-6 pt-0">
          <DbmsRootPath path={instance.rootPath} />
          <DatabaseList
            databases={databases}
            dbmsId={instance.id}
            dbmsStatus={instance.status}
            rootPath={instance.rootPath}
          />
        </div>
      </CardWrapper>
      {openDestroy && (
        <DeleteInstanceModal
          onClose={() => setOpenDestroy(false)}
          onConfirm={() => setOpenDestroy(false)}
          instance={instance}
        />
      )}
      {openClone && (
        <CloneInstanceModal
          onClose={() => setOpenClone(false)}
          onConfirm={() => setOpenClone(false)}
          instance={instance}
        />
      )}
    </>
  );
};
