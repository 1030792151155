import { IconButton } from '@neo4j-ndl/react';
import { Bars3IconOutline } from '@neo4j-ndl/react/icons';
import { useAppSelector, useMainSidebarActions } from '@nx/state';
import classNames from 'classnames';

import { LeftNavPortal } from './left-nav-portal';
import classes from './menu-button.module.css';

export const LeftNavMenuButton = () => {
  const { toggleMainSidebar, enterMainSidebar, leaveMainSidebar } = useMainSidebarActions();
  const mainSidebar = useAppSelector((store) => store.sidebar.mainSidebar);

  const handleOnClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const clickedOnMenu = e.currentTarget.contains(e.target as Node);
    /** ignore onClick when triggered by LeftNavPortal */
    if (clickedOnMenu) {
      toggleMainSidebar();
    }
  };

  const handleOnMouseEnter = () => {
    void enterMainSidebar();
  };

  const handleOnMouseLeave = () => {
    void leaveMainSidebar();
  };

  return (
    <IconButton
      isClean
      ariaLabel="Toggle main navigation menu"
      isActive={mainSidebar.isOpen && !mainSidebar.isTemporaryOpen}
      onClick={handleOnClick}
      className={classNames(
        mainSidebar.isOpen && !mainSidebar.isTemporaryOpen && classes['hover-background-pressed'],
        mainSidebar.isTemporaryOpen && classes['background-hover'],
      )}
      htmlAttributes={{
        onMouseEnter: handleOnMouseEnter,
        onMouseLeave: handleOnMouseLeave,
      }}
    >
      <Bars3IconOutline />
      <LeftNavPortal />
    </IconButton>
  );
};
