import { Accordion, Banner, Checkbox, Select, TextInput, TextLink, Typography } from '@neo4j-ndl/react';
import type { Instance, InstanceSize, Project, TierConfig } from '@nx/state';
import { CLOUD_PROVIDER, NEO4J_MANAGED_KEY } from '@nx/state';
import { Objects, isNotNullish, isNullish } from '@nx/stdlib';
import { useDarkMode } from '@nx/ui';
import { useCallback, useMemo, useState } from 'react';

import { calcMonthlyCost, formatDollars } from '../../utils';
import type { Validation } from '../../utils/validation';
import { MINIMUM_GDS_PLUGIN_VECTOR_MEMORY } from '../constants';
import { CloudProviderCard } from '../create/form';
import { InstanceSizePicker } from '../create/size-picker';
import { gbStringToInt, isPAYG } from '../entities/helpers';
import type { InstanceFormData } from '../entities/model';
import { EncryptionKeySection } from '../shared/encryption-keys';

enum UPGRADE_FORM_ITEM {
  PRODUCT_TYPE = 'product-type',
  CP_AND_REGION = 'cloud-provider-and-region',
  INSTANCE_DETAILS = 'instance-details',
  INSTANCE_SIZE = 'instance-size',
  ADDITIONAL_SETTINGS = 'additional-settings',
}

interface UpgradeInstanceFormProps {
  data: InstanceFormData;
  onDataChange: (data: InstanceFormData) => void;
  tierConfig: TierConfig;
  validation: Validation<InstanceFormData> | null;
  project: Project;
  instance: Instance;
}

export const UpgradeInstanceForm = ({
  data,
  onDataChange,
  tierConfig,
  validation,
  project,
  instance,
}: UpgradeInstanceFormProps) => {
  const isDarkTheme = useDarkMode();

  const [expandedItemIds, setExpandedItemIds] = useState<UPGRADE_FORM_ITEM[]>([
    UPGRADE_FORM_ITEM.CP_AND_REGION,
    UPGRADE_FORM_ITEM.INSTANCE_SIZE,
    UPGRADE_FORM_ITEM.INSTANCE_DETAILS,
  ]);
  const cloudProviders = Objects.keys(tierConfig.cloudProviderRegions);
  const regions = useMemo(() => {
    if (isNotNullish(data.cloudProvider)) {
      return tierConfig.cloudProviderRegions[data.cloudProvider];
    }
    return [];
  }, [data.cloudProvider, tierConfig]);

  const showCmekWarning = project.capabilities.cmek && data.encryptionKeyRef !== NEO4J_MANAGED_KEY;

  const regionOptions = useMemo(
    () => regions?.map((region) => ({ label: region.friendly, value: region.name, key: region.name })) ?? [],
    [regions],
  );
  const selectedRegion = useMemo(() => {
    return regionOptions.find((region) => region.value === data.region) ?? null;
  }, [regionOptions, data.region]);

  const parseSize = (size: string): number => parseInt(size.replace('GB', ''), 10);
  const availableSizes = useMemo(() => {
    let { sizes } = tierConfig;
    if (isNotNullish(data.cloudProvider)) {
      sizes = sizes.filter((size) => size.cloudProvider === data.cloudProvider);
    }
    sizes = sizes.filter((size) => parseSize(size.memory) >= parseSize(instance.desiredSettings.memory));
    return sizes.filter((size) => !size.isTrial);
  }, [data.cloudProvider, tierConfig, instance]);

  const costPerHour = data.size?.costPerHour ?? '0';
  const costPerMonth = calcMonthlyCost(costPerHour);

  const handleAccordionChange = (items: UPGRADE_FORM_ITEM[]) => {
    setExpandedItemIds(items);
  };

  const handleSizeChange = useCallback(
    (size: InstanceSize) => {
      const memoryInt = gbStringToInt(size.memory);
      let { vectorOptimized, gdsPlugin } = data;
      if (memoryInt < MINIMUM_GDS_PLUGIN_VECTOR_MEMORY) {
        vectorOptimized = false;
        gdsPlugin = false;
      }
      onDataChange({ ...data, size, vectorOptimized, gdsPlugin });
    },
    [data, onDataChange],
  );

  const handleConfirmedChange = (confirmed: boolean) => {
    onDataChange({ ...data, confirmed });
  };

  const handleEncryptionKeyChange = (key: string) => {
    onDataChange({ ...data, encryptionKeyRef: key });
  };

  return (
    <div>
      <Accordion expandedItemIds={expandedItemIds} isMultiple onChange={handleAccordionChange}>
        <Accordion.Item title="Cloud provider and region" itemId={UPGRADE_FORM_ITEM.CP_AND_REGION}>
          <div className="flex flex-col gap-6">
            <Typography variant="subheading-medium" as="div" className="mb-2">
              Provider
            </Typography>
            <div className="flex gap-4">
              {cloudProviders.includes(CLOUD_PROVIDER.GCP) && (
                <CloudProviderCard
                  icon="gcp"
                  aria-label="Select cloud provider GCP"
                  selected={data.cloudProvider === CLOUD_PROVIDER.GCP}
                  disabled={data.cloudProvider !== CLOUD_PROVIDER.GCP}
                />
              )}
              {cloudProviders.includes(CLOUD_PROVIDER.AWS) && (
                <CloudProviderCard
                  aria-label="Select cloud provider AWS"
                  icon={isDarkTheme ? 'aws-dark' : 'aws'}
                  selected={data.cloudProvider === CLOUD_PROVIDER.AWS}
                  disabled={data.cloudProvider !== CLOUD_PROVIDER.AWS}
                />
              )}
              {cloudProviders.includes(CLOUD_PROVIDER.AZURE) && (
                <CloudProviderCard
                  icon="azure"
                  aria-label="Select cloud provider Azure"
                  selected={data.cloudProvider === CLOUD_PROVIDER.AZURE}
                  disabled={data.cloudProvider !== CLOUD_PROVIDER.AZURE}
                />
              )}
            </div>
            <Select
              type="select"
              size="medium"
              errorText={validation?.region?.message}
              isDisabled={true}
              selectProps={{
                menuPosition: 'fixed',
                isMulti: false,
                value: selectedRegion,
                // Need to have due to bug in ndl
                onKeyDown: (e) => e.stopPropagation(),
              }}
              label="Region"
            />
          </div>
        </Accordion.Item>
        <Accordion.Item title="Instance size" itemId={UPGRADE_FORM_ITEM.INSTANCE_SIZE}>
          <div className="mt-4 flex flex-col gap-4">
            <InstanceSizePicker
              data={data}
              options={availableSizes}
              errorMessage={validation?.size?.message}
              onChange={handleSizeChange}
              hidePricing={!isPAYG(data.tier, project.billingMethod)}
              tier={data.tier}
            />
          </div>
        </Accordion.Item>
        <Accordion.Item title="Instance details" itemId={UPGRADE_FORM_ITEM.INSTANCE_DETAILS}>
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-2">
              <TextInput
                isFluid
                value={data.name}
                isDisabled={true}
                label="Instance Name"
                htmlAttributes={{
                  // Need to have due to bug in ndl
                  onKeyDown: (e) => e.stopPropagation(),
                  'data-testid': 'instance-name',
                }}
              />
              <EncryptionKeySection
                data={data}
                onChange={handleEncryptionKeyChange}
                errorMessage={validation?.encryptionKeyRef?.message}
                instance={instance}
              />
            </div>
            {showCmekWarning && (
              <Banner
                description="By encrypting this instance with a Customer Managed Key, you take full responsibility for the management and security of the key. Neo4j cannot recover your data if the key is lost or invalid. There is a risk of permanent data loss."
                type="warning"
                hasIcon
                usage="inline"
              />
            )}
          </div>
        </Accordion.Item>
      </Accordion>
      <Banner usage="inline">
        <Typography variant="body-large" as="div" className="flex flex-col gap-4">
          This instance costs {formatDollars(data.size?.costPerHour ?? '0')} per hour. Continuous running for 1 month
          will cost {formatDollars(costPerMonth)} based on a 730 hour average month.
          <div>
            During the Subscription Term, Neo4j will provide Support Services - Support Level &quot;Regional&quot; -
            according to current terms at{' '}
            <TextLink href="http://www.neo4j.com/support-terms/" isExternalLink>
              Neo4j Product &amp; Support Terms
            </TextLink>{' '}
            and Services availability according to the{' '}
            <TextLink href="https://neo4j.com/terms/sla/aurabc/" isExternalLink>
              Neo4j Aura Service Level
            </TextLink>
            .
          </div>
          <div>
            Neo4j may modify Support Services and availability Service Levels provided no such modification shall result
            in a material reduction in Support Services or Services availability during the Subscription Term.
          </div>
        </Typography>
      </Banner>
      {!isNullish(data.size) && (
        <div className="m-4 flex flex-col gap-4">
          <Checkbox
            isChecked={data.confirmed}
            onChange={(event) => handleConfirmedChange(event.target.checked)}
            key="confirm-checkbox"
            label="I accept"
          />
        </div>
      )}
    </div>
  );
};

interface UpgradeInstanceWrapperProps {
  data: InstanceFormData;
  onDataChange: (data: InstanceFormData) => void;
  tierConfig?: TierConfig;
  validation: Validation<InstanceFormData> | null;
  project: Project;
  instance: Instance;
}

const UpgradeInstanceFormWrapper = ({ tierConfig, ...rest }: UpgradeInstanceWrapperProps) => {
  if (isNullish(tierConfig)) {
    return (
      <Banner
        description="There is something wrong with the configuration of your project. Reload the page and if the error persists contact customer support."
        type="danger"
        className="mt-4"
        usage="inline"
      />
    );
  }
  return <UpgradeInstanceForm tierConfig={tierConfig} {...rest} />;
};

export default UpgradeInstanceFormWrapper;
