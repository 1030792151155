import { selectActiveConnection, LEGACY_store as store } from '@nx/state';
import type * as SegmentJS from '@segment/analytics-next';

export const segmentDbIdInjectionPlugin: SegmentJS.Plugin = {
  name: 'DB ID injection',
  type: 'enrichment',
  version: '1.0.0',

  isLoaded: () => true,
  load: () => Promise.resolve(),

  track: (ctx) => {
    const connection = selectActiveConnection(store.getState());

    if (connection?.auraId !== undefined) {
      ctx.updateEvent('properties.dbid', connection.auraId);
    }

    return ctx;
  },
};
