import type { CLOUD_PROVIDER, TIER } from './shared';

// A special constant value we don't want to confuse
// with actual CMEK / encryption key references
export const NEO4J_MANAGED_KEY = 'neo4j-managed-key';
export const NEO4J_MANAGED_KEY_LABEL = 'Neo4j Managed Key';

export type EncryptionKeyRef = string;

export type EncryptionKey = {
  encryptionKeyRef: string;
  cloudProvider: CLOUD_PROVIDER;
  tier: TIER;
  region: string;
  name: string;
  created: string;
  status: ENCRYPTION_KEY_STATUS;
  awsProperties?: AwsProperties;
  gcpProperties?: GcpProperties;
  azureProperties?: AzureProperties;
};

export enum ENCRYPTION_KEY_STATUS {
  READY = 'ready',
  PENDING = 'pending',
  DELETED = 'deleted',
  ERROR = 'error',
}

export type GcpProperties = {
  keyId: string;
  permissions: GcpKeyPermissions;
};

export type AwsProperties = {
  kmsKeyArn: string;
  permissions: AwsKeyPermissions;
};

export type AzureProperties = {
  keyId: string;
  applicationURL: string;
  applicationName: string;
  applicationID: string;
};

export type AwsKeyPermissions = {
  accountId?: string;
};

export type GcpKeyPermissions = {
  projectNumber?: string;
  serviceAccounts?: string[];
};
