import { APP_SCOPE } from '@nx/constants';
import { Persist } from '@nx/state';
import type { EditorState } from '@query/redux/editor-slice';
import editor, { EDITOR_PERSISTED_KEYS } from '@query/redux/editor-slice';
import { nanoid } from '@reduxjs/toolkit';
import { createMigrate, createTransform, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/es/storage';

type EditorStateV1 = { cmdHistory: { cmd: string; timestamp: number; database: string | null }[] };
const migrations = {
  // Editor state v1 didn't have an id field on the cmdHistory entries.
  // This migrates from v1 to v2
  2: (state: EditorStateV1): EditorState => {
    return {
      ...state,
      cmdHistory: state.cmdHistory.map((entry) => ({ ...entry, id: nanoid() })),
    };
  },
};

export const persistedMigratedEditor = persistReducer<EditorState>(
  {
    key: Persist.createKey(APP_SCOPE.query, 'editor'),
    storage,
    version: 2,
    throttle: 100,
    whitelist: EDITOR_PERSISTED_KEYS,
    stateReconciler: autoMergeLevel2,
    transforms: [
      createTransform((inboundState, key, state: EditorState) => {
        if (key === 'cmdHistory' && state.retainEditorHistory !== true) {
          return [];
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return inboundState;
      }),
    ],
    // @ts-ignore redux-persist types are not good enough https://github.com/rt2zz/redux-persist/issues/1065#issuecomment-554538845
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    migrate: createMigrate(migrations),
  },
  editor,
);
