import { Button } from '@neo4j-ndl/react';
import { useConnection, useStaticConnectionUrl } from '@nx/state';
import * as StdLib from '@nx/stdlib';
import { isNullish } from '@nx/stdlib';
import type { SyntheticEvent } from 'react';
import React, { useEffect, useState } from 'react';

import {
  getFormValuesFromUrl,
  getInitialFormValues,
  mapConnectionToFormValues,
  validateForm,
} from './connection-form-utils';
import type { Connection } from './connection-form.types';
import ConnectionError from './form-elements/connection-error';
import { ActionRequiresConnectionInfo, SafariUnencryptedConnectionWarning } from './form-elements/form-banners';
import { ConnectionFormBasicAuth } from './form-elements/input-basic-auth';
import { ConnectionFormSso } from './form-elements/input-sso';
import { ConnectionFormUrl } from './form-elements/input-url';
import { RecentConnections } from './form-elements/recent-connections';
import { useConnect, useConnectionDiscoveryData, useFirstLoad, useFormValues, useInitialConnection } from './hooks';

export const ConnectionFormUpx = () => {
  useFirstLoad();
  const fixedConnectionUrl = useStaticConnectionUrl();
  const initialConnection = useInitialConnection(fixedConnectionUrl);
  const initialFormValues = getInitialFormValues(
    initialConnection?.connection,
    getFormValuesFromUrl(fixedConnectionUrl),
  );
  const [formValues, setFormValues] = useFormValues(initialFormValues);

  const { isValid } = validateForm(formValues);

  const connection = useConnection();
  const { connectBasicAuth } = useConnect();
  const { providers, fetchAndSetDiscoveryData } = useConnectionDiscoveryData();

  const [isConnectionError, setIsConnectionError] = useState(false);
  const [userHasInteracted, setUserHasInteracted] = useState(false);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } },
  ) => {
    const { name, value } = event.target;
    setFormValues({ [name]: value }, { resetInstanceName: true });
    setUserHasInteracted(true);
  };

  const handleHostPasteChange: React.ClipboardEventHandler<HTMLInputElement> = (event) => {
    event.clipboardData.items[0]?.getAsString((value) => {
      // If the pasted url doesn't start with a protocol, prepend the selected protocol
      let url = value;
      const urlParts = url.split('://');
      const urlHost = urlParts.pop();
      const urlProtocol = urlParts.pop();
      if (urlProtocol === undefined) {
        const protocol = StdLib.URLs.isLocalhost(url) ? StdLib.URLs.PROTOCOL_NEO4J : formValues.protocol;
        url = `${protocol}//${urlHost}`;
      }

      setFormValues(getFormValuesFromUrl(url), { resetInstanceName: true });
    });
  };

  const handleRecentConnection = (recentConnection: Connection) => {
    const values = mapConnectionToFormValues(recentConnection);
    setFormValues(values);
  };

  const handleSubmit = (event: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
    event.preventDefault();
    connectBasicAuth(formValues, () => setIsConnectionError(true));
  };

  // Fetch SSO providers
  useEffect(() => {
    fetchAndSetDiscoveryData(`${formValues.protocol}//${formValues.hostname}`);
  }, [formValues.protocol, formValues.hostname, fetchAndSetDiscoveryData]);

  // Reading the fixedConnectionUrl can happen slightly after the component has been initialized
  useEffect(() => {
    if (!isNullish(fixedConnectionUrl)) {
      setFormValues(getFormValuesFromUrl(fixedConnectionUrl));
    }
  }, [fixedConnectionUrl, setFormValues]);

  return (
    <form
      className="flex grow flex-col gap-6"
      onSubmit={(event) => {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        void handleSubmit(event as SyntheticEvent<HTMLFormElement, SubmitEvent>);
      }}
    >
      <div className="flex justify-between">
        <h6 className="font-semibold">Neo4j Credentials:</h6>
        {isNullish(fixedConnectionUrl) && <RecentConnections onSelect={handleRecentConnection} />}
      </div>
      <ConnectionError
        show={isConnectionError}
        error={connection.metadata?.error}
        discoveryUrls={connection.metadata?.discoveryUrls}
        onDiscoveryUrlSelected={(url) => setFormValues(getFormValuesFromUrl(url))}
      />
      <ActionRequiresConnectionInfo />
      <SafariUnencryptedConnectionWarning formValues={formValues} />
      <ConnectionFormUrl
        formValues={formValues}
        handleInputChange={handleInputChange}
        handleHostPasteChange={handleHostPasteChange}
      />
      <ConnectionFormBasicAuth
        autoFocus={!userHasInteracted}
        formValues={formValues}
        handleInputChange={handleInputChange}
      />
      <Button
        className="ml-auto w-full"
        size="medium"
        type="submit"
        isDisabled={connection.status.isConnecting || connection.status.isDisconnecting || !isValid}
        htmlAttributes={{
          value: 'basicAuth',
          name: 'authType',
        }}
      >
        Connect
      </Button>
      {providers.length !== 0 && <ConnectionFormSso formValues={formValues} providers={providers} />}
    </form>
  );
};
