import { DiscoverySuccess, type SSOProviderOriginal } from '@nx/constants';
import { useFetchDiscoveryData } from '@nx/state';
import * as StdLib from '@nx/stdlib';
import { debounce } from 'lodash-es';
import { useCallback, useState } from 'react';

import { useConnectionDetails } from './use-connection-details';

export const useConnectionDiscoveryData = () => {
  const connectionDetails = useConnectionDetails();

  const [neo4jVersion, setNeo4jVersion] = useState<string | undefined>(undefined);
  const [neo4jEdition, setNeo4jEdition] = useState<string | undefined>(undefined);

  const [providers, setProviders] = useState<SSOProviderOriginal[]>(
    connectionDetails?.credentials.type === 'sso' ? connectionDetails.credentials.providers : [],
  );
  const [showProviders, setShowProviders] = useState(providers.length > 0);
  const { fetchDiscoveryData } = useFetchDiscoveryData();

  const toggleShowProviders = () => {
    setShowProviders((previousState) => !previousState);
  };

  const fetchAndSetDiscoveryData = (url: string) => {
    const neo4jUrl = StdLib.URLs.Neo4jURL.asNullable(url);
    if (!StdLib.isNullish(neo4jUrl)) {
      void fetchDiscoveryData(neo4jUrl.toDiscoveryUrl()).then((discoveryData) => {
        if (discoveryData.status === DiscoverySuccess) {
          setNeo4jVersion(discoveryData.neo4jVersion);
          setNeo4jEdition(discoveryData.neo4jEdition);
          setProviders(discoveryData.SSOProviders);
        } else {
          setNeo4jVersion(undefined);
          setNeo4jEdition(undefined);
          setProviders([]);
          setShowProviders(false);
        }
      });
    } else {
      setNeo4jVersion(undefined);
      setNeo4jEdition(undefined);
      setProviders([]);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchAndSetDiscoveryData = useCallback(debounce(fetchAndSetDiscoveryData, 300), []);

  return {
    neo4jVersion,
    neo4jEdition,
    providers,
    showProviders,
    toggleShowProviders,
    fetchAndSetDiscoveryData: debouncedFetchAndSetDiscoveryData,
  };
};
