import type { SecurityLogSort, SecurityLogSummarySort } from '@nx/state';
import { SORT_ORDER } from '@nx/state';
import type { ColumnSort } from '@tanstack/react-table';

import type { TabText } from '../../shared/types';
import { COLUMN_TO_SLS_SORT, COLUMN_TO_SL_SORT } from './types';

export type LogOrSummarySortInput<T extends TabText> = T extends 'Details' ? SecurityLogSort : SecurityLogSummarySort;

export const mapControlledSorting = <T extends TabText>(
  rules: ColumnSort[],
  tableType: T,
): LogOrSummarySortInput<T>[] => {
  if (rules.length === 0) {
    return [];
  }

  return rules
    .map(({ id, desc }) => {
      const map = tableType === 'Details' ? COLUMN_TO_SL_SORT : COLUMN_TO_SLS_SORT;
      const property = map[id];

      if (typeof desc !== 'boolean') {
        return null;
      }

      const order = desc ? SORT_ORDER.DESCENDING : SORT_ORDER.ASCENDING;

      return { property, order };
    })
    .filter((sortItem): sortItem is LogOrSummarySortInput<T> => Boolean(sortItem));
};
