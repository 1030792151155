import type { HelpTooltipProps } from '../../help-tooltip';

export const QueryExecutions: HelpTooltipProps = {
  link: null,
  body: (
    <div>
      <p>Queries per minute is the number of successful queries executed per minute on this database.</p>
      <p>Failed queries per minute is the number of falied queries executed per minute on this database.</p>
    </div>
  ),
};
