import { Box, Label, TextLink, Typography } from '@neo4j-ndl/react';
import { useCurrentGuide } from '@nx/state';

type Resource = {
  title: string;
  label: string;
  href: string;
  strong?: boolean;
  description?: string;
};

const HELP_RESOURCES: Record<string, Resource[]> = {
  Python: [
    {
      title: 'Neo4j Python Driver',
      label: 'Documentation',
      href: 'https://neo4j.com/docs/api/python-driver/current/',
    },
    {
      title: 'Free Neo4j Python Courses',
      label: 'Course',
      href: 'https://graphacademy.neo4j.com/categories/python/',
    },
  ],
  '.NET': [
    {
      title: 'Neo4j Bolt Driver for .NET',
      label: 'Documentation',
      href: 'https://neo4j.com/docs/api/dotnet-driver/current/',
    },
    {
      title: 'Free Neo4j .NET Courses',
      label: 'Course',
      href: 'https://graphacademy.neo4j.com/categories/dotnet/',
    },
  ],
  JavaScript: [
    {
      title: 'Neo4j Driver for JavaScript',
      label: 'Documentation',
      href: 'https://neo4j.com/docs/api/javascript-driver/current/',
    },
    {
      title: 'Free Neo4j Node.js Courses',
      label: 'Course',
      href: 'https://graphacademy.neo4j.com/categories/nodejs/',
    },
  ],
  Go: [
    {
      title: 'Neo4j Driver for Go',
      label: 'Documentation',
      href: 'https://pkg.go.dev/github.com/neo4j/neo4j-go-driver/v5/neo4j',
    },
    {
      title: 'Free Neo4j Go Courses',
      label: 'Course',
      href: 'https://graphacademy.neo4j.com/categories/go/',
    },
  ],
  Java: [
    {
      title: 'Neo4j Driver for Java',
      label: 'Documentation',
      href: 'https://neo4j.com/docs/api/java-driver/current/',
    },
    {
      title: 'Free Neo4j Java Courses',
      label: 'Course',
      href: 'https://graphacademy.neo4j.com/categories/java/',
    },
  ],
};

export const Resources = () => {
  const { guide } = useCurrentGuide();

  if (!guide) {
    return null;
  }

  const helpResources = HELP_RESOURCES[guide.title];

  if (!helpResources) {
    return null;
  }

  return (
    <>
      <Typography variant="h6" className="pb-4">
        What’s next?
      </Typography>
      <div className="flex gap-4">
        {helpResources.map(({ title, href, label, strong, description }) => (
          <Box
            borderRadius="xl"
            padding="5"
            className="bg-neutral-bg-weak border-neutral-border-weak flex-1 border"
            key={title}
            htmlAttributes={{
              id: title,
            }}
          >
            <TextLink isExternalLink href={href} className="pb-5">
              {title}
            </TextLink>
            <Label fill="semi-filled" color={strong === true ? 'info' : 'default'}>
              {label}
            </Label>
            <Typography variant="body-small">{description}</Typography>
          </Box>
        ))}
      </div>
    </>
  );
};
