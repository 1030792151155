import type { Address, Consumption, ConsumptionBreakdownItem, Usage, UsageBreakdownItem } from '@nx/state';
import { saveAs } from 'file-saver';

import type { BillingFormData } from './billing-form-data';

export const isSameAddress = (address: Address, serviceAddress: Address) =>
  JSON.stringify(address) === JSON.stringify(serviceAddress);

export const defaultStripeAddress = (): Address => ({
  line1: '',
  city: '',
  postalCode: '',
  state: '',
  country: 'US',
});

export const defaults = (): BillingFormData => ({
  name: '',
  companyName: '',
  email: '',
  address: defaultStripeAddress(),
  serviceAddress: undefined,
});

/**
 * Converts an Array of Objects to a CSV string.
 *
 * @param {T[]} objectArray - Array of generic objects to be converted.
 */
function objectArrayToCSV<T extends object>(objectArray: T[]): string {
  const [firstItem] = objectArray;
  const csvHeader = `${Object.keys(firstItem!).join(',')}\n`;
  const csvContent = objectArray.map((e) => Object.values(e).join(',')).join('\n');
  return `data:text/csv;charset=utf-8,${csvHeader}${csvContent}`;
}

export function downloadUsage(usage: Usage) {
  const startDateISO = new Date(usage.usagePeriodStart!).toISOString();
  const endDateISO = new Date(usage.usagePeriodEnd!).toISOString();
  const fileName = `usage_${startDateISO}_${endDateISO}.csv`;
  const csv = objectArrayToCSV<UsageBreakdownItem>(usage.breakdown!);
  saveAs(csv, fileName);
}

export function downloadConsumption(consumption: Consumption) {
  const startDateISO = new Date(consumption.usagePeriodStart!).toISOString();
  const endDateISO = new Date(consumption.usagePeriodEnd!).toISOString();
  const fileName = `consumption_${startDateISO}_${endDateISO}.csv`;
  const csv = objectArrayToCSV<ConsumptionBreakdownItem>(consumption.breakdown!);
  saveAs(csv, fileName);
}
