import { Banner, Button, Dialog } from '@neo4j-ndl/react';
import { AURA_CONSOLE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import type { Instance } from '@nx/state';
import { consoleApi, getApiError, getErrorMessage } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useState } from 'react';

import { useTrackUpxEvent } from '../../services/segment/analytics';

const logger = createLogger(APP_SCOPE.framework);

type ClearInstanceModalProps = {
  instance: Instance;
  onSuccess: () => void;
  onClose: () => void;
};

export const ClearInstanceModal = ({ instance, onSuccess, onClose }: ClearInstanceModalProps) => {
  const trackEvent = useTrackUpxEvent();
  const [clearInstance, clearInstanceRes] = consoleApi.useClearInstanceMutation();
  const [error, setError] = useState<string | null>(null);
  const handleSubmit = () => {
    setError(null);
    clearInstance(instance.id)
      .unwrap()
      .catch((err: FetchBaseQueryError | SerializedError | undefined) => {
        const apiError = getApiError(err);
        const errorMessage = getErrorMessage(apiError);
        logger.error(errorMessage);
        setError(errorMessage);
      });

    trackEvent({
      event: AURA_CONSOLE_EVENTS.INSTANCE_RESET_TO_BLANK,
      properties: { tier: instance.tier },
      scope: APP_SCOPE.aura,
    });
    onSuccess();
  };

  return (
    <Dialog isOpen modalProps={{ 'data-testid': 'clear-db-modal' }} onClose={onClose}>
      {isNotNullish(error) && <Banner type="danger" description={error} usage="inline" />}
      <Dialog.Header>Reset '{instance.name}' to blank?</Dialog.Header>
      <Dialog.Content>
        <Banner
          title="Existing data will be erased"
          description={`Resetting '${instance.name}' to a blank state will erase all of its data. This action cannot be undone. If you are unsure, please take a snapshot and download it before continuing.`}
          type="warning"
          hasIcon
          usage="inline"
        />
      </Dialog.Content>
      <Dialog.Actions>
        <Button fill="outlined" color="neutral" onClick={onClose} isDisabled={clearInstanceRes.isLoading}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          isLoading={clearInstanceRes.isLoading}
          color="danger"
          htmlAttributes={{
            'data-testid': 'clear-instance-confirm',
          }}
        >
          Reset
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
