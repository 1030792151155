import * as std from '@nx/stdlib';
import type { Node, Path, Record, Relationship } from 'neo4j-driver-core';
import { isNode, isPath, isRelationship } from 'neo4j-driver-core';

import type { DeduplicatedBasicNodesAndRels } from '../types/sdk-types';
import { getPropertyTypeDisplayName } from './cypher-type-names';
import { propertyToString } from './record-to-string';

export const extractUniqueNodesAndRels = (
  records: Record[],
  { nodeLimit, keepDanglingRels = false }: { nodeLimit?: number; keepDanglingRels?: boolean } = {},
): DeduplicatedBasicNodesAndRels => {
  let limitHit = false;
  if (records.length === 0) {
    return { nodes: [], relationships: [] };
  }

  const items = new Set<unknown>();

  for (const record of records) {
    for (const key of record.keys) {
      items.add(record.get(key));
    }
  }

  const paths: Path[] = [];

  const nodeMap = new Map<string, Node>();
  function addNode(n: Node) {
    if (!limitHit) {
      const id = n.elementId.toString();
      if (!nodeMap.has(id)) {
        nodeMap.set(id, n);
      }
      if (typeof nodeLimit === 'number' && nodeMap.size === nodeLimit) {
        limitHit = true;
      }
    }
  }

  const relMap = new Map<string, Relationship>();
  function addRel(r: Relationship) {
    const id = r.elementId.toString();
    if (!relMap.has(id)) {
      relMap.set(id, r);
    }
  }

  const findAllEntities = (item: unknown) => {
    if (typeof item !== 'object' || !item) {
      return;
    }

    if (isRelationship(item)) {
      addRel(item);
    } else if (isNode(item)) {
      addNode(item);
    } else if (isPath(item)) {
      paths.push(item);
    } else if (Array.isArray(item)) {
      item.forEach(findAllEntities);
    } else {
      Object.values(item).forEach(findAllEntities);
    }
  };

  findAllEntities(Array.from(items));

  for (const path of paths) {
    addNode(path.start);
    addNode(path.end);
    for (const segment of path.segments) {
      addNode(segment.start);
      addNode(segment.end);
      addRel(segment.relationship);
    }
  }

  const nodes = Array.from(nodeMap.values()).map((item) => {
    return {
      id: item.elementId.toString(),
      elementId: item.elementId,
      labels: item.labels,
      properties: std.Objects.mapValues(item.properties, propertyToString),
      propertyTypes: std.Objects.mapValues(item.properties, getPropertyTypeDisplayName),
    };
  });

  const relationships = Array.from(relMap.values())
    .filter((item) => {
      if (keepDanglingRels) {
        return true;
      }

      // We'd get dangling relationships from
      // match ()-[a:ACTED_IN]->() return a;
      // or from hitting the node limit
      const start = item.startNodeElementId.toString();
      const end = item.endNodeElementId.toString();
      return nodeMap.has(start) && nodeMap.has(end);
    })
    .map((item) => {
      return {
        id: item.elementId.toString(),
        elementId: item.elementId,
        startNodeId: item.startNodeElementId.toString(),
        endNodeId: item.endNodeElementId.toString(),
        type: item.type,
        properties: std.Objects.mapValues(item.properties, propertyToString),
        propertyTypes: std.Objects.mapValues(item.properties, getPropertyTypeDisplayName),
      };
    });
  return { nodes, relationships, limitHit };
};
