/* eslint-disable @typescript-eslint/ban-types */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-duplicate-type-constituents */

/* eslint-disable @typescript-eslint/consistent-indexed-object-style */
import { baseApi as api } from '@query/services/api/base-api';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEditorHistoryEntries: build.query<GetEditorHistoryEntriesApiResponse, GetEditorHistoryEntriesApiArg>({
      query: (queryArg) => ({ url: `/unstable/query-editor-history`, params: { limit: queryArg.limit } }),
    }),
    updateEditorHistoryEntries: build.mutation<UpdateEditorHistoryEntriesApiResponse, UpdateEditorHistoryEntriesApiArg>(
      {
        query: (queryArg) => ({
          url: `/unstable/query-editor-history`,
          method: 'PUT',
          body: queryArg.updateQueryEditorHistoryEntriesBody,
        }),
      },
    ),
    deleteEditorHistoryEntriesById: build.mutation<
      DeleteEditorHistoryEntriesByIdApiResponse,
      DeleteEditorHistoryEntriesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/unstable/query-editor-history`,
        method: 'DELETE',
        body: queryArg.deleteQueryEditorHistoryEntriesByIdBody,
      }),
    }),
    deleteEditorHistoryEntriesOlderThanTimestamp: build.mutation<
      DeleteEditorHistoryEntriesOlderThanTimestampApiResponse,
      DeleteEditorHistoryEntriesOlderThanTimestampApiArg
    >({
      query: (queryArg) => ({
        url: `/unstable/query-editor-history/entries-older-than/${queryArg.timestamp}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as generatedEditorHistoryApi };
export type GetEditorHistoryEntriesApiResponse = /** status 200 OK */ QueryEditorHistoryEntry[];
export type GetEditorHistoryEntriesApiArg = {
  limit: number;
};
export type UpdateEditorHistoryEntriesApiResponse = /** status 200 OK */ number;
export type UpdateEditorHistoryEntriesApiArg = {
  updateQueryEditorHistoryEntriesBody: UpdateQueryEditorHistoryEntriesBody;
};
export type DeleteEditorHistoryEntriesByIdApiResponse = /** status 200 OK */ null | null;
export type DeleteEditorHistoryEntriesByIdApiArg = {
  deleteQueryEditorHistoryEntriesByIdBody: DeleteQueryEditorHistoryEntriesByIdBody;
};
export type DeleteEditorHistoryEntriesOlderThanTimestampApiResponse = /** status 200 OK */ null | null;
export type DeleteEditorHistoryEntriesOlderThanTimestampApiArg = {
  timestamp: string;
};
export type QueryEditorHistoryEntry = {
  saved: {
    id: string;
  } | null;
  timestamp: string;
  queryState: ('failed' | 'canceled' | 'success' | null) | null;
  database: string | null;
  cmd: string;
  id: string;
};
export type Location = 'body' | 'cookies' | 'headers' | 'params' | 'query';
export type FieldValidationError = {
  /** The error message */
  msg: any;
  /** The value of the field. It might be unset if the value is hidden. */
  value?: any;
  /** The path to the field which has a validation error */
  path: string;
  /** The location within the request where this field is */
  location: Location;
  /** Indicates that the error occurred because a field had an invalid value */
  type: 'field';
};
export type AlternativeValidationError = {
  /** The list of underlying validation errors returned by validation chains in `oneOf()` */
  nestedErrors: FieldValidationError[];
  /** The error message */
  msg: any;
  /** Indicates that the error occurred because all alternatives (e.g. in `oneOf()`) were invalid */
  type: 'alternative';
};
export type GroupedAlternativeValidationError = {
  /** The list of underlying validation errors returned by validation chains in `oneOf()` */
  nestedErrors: FieldValidationError[][];
  /** The error message */
  msg: any;
  /** Indicates that the error occurred because all alternatives (e.g. in `oneOf()`) were invalid,
    and the nested errors are grouped per alternative. */
  type: 'alternative_grouped';
};
export type PickFieldInstanceExcludeKeyofFieldInstanceOriginalPathOrPathValues = {
  path: string;
  location: Location;
  value: any;
};
export type OmitFieldInstanceOriginalPathOrPathValues =
  PickFieldInstanceExcludeKeyofFieldInstanceOriginalPathOrPathValues;
export type UnknownFieldsError = {
  /** The list of fields that are unknown */
  fields: OmitFieldInstanceOriginalPathOrPathValues[];
  /** The error message */
  msg: any;
  /** Indicates that the error occurred because one or more fields are unknown in the request */
  type: 'unknown_fields';
};
export type ValidationError =
  | AlternativeValidationError
  | GroupedAlternativeValidationError
  | UnknownFieldsError
  | FieldValidationError;
export type ReadonlyArraySourceLocation = {};
export type ReadonlyArrayStringOrNumber = {};
export type GraphQlFormattedError = {
  /** A short, human-readable summary of the problem that **SHOULD NOT** change
    from occurrence to occurrence of the problem, except for purposes of
    localization. */
  message: string;
  /** If an error can be associated to a particular point in the requested
    GraphQL document, it should contain a list of locations. */
  locations?: ReadonlyArraySourceLocation;
  /** If an error can be associated to a particular field in the GraphQL result,
    it _must_ contain an entry with the key `path` that details the path of
    the response field which experienced the error. This allows clients to
    identify whether a null result is intentional or caused by a runtime error. */
  path?: ReadonlyArrayStringOrNumber;
  /** Reserved for implementors to extend the protocol however they see fit,
    and hence there are no additional restrictions on its contents. */
  extensions?: {
    [key: string]: any;
  };
};
export type UpdateQueryEditorHistoryEntry = {
  savedId: string | null;
  timestamp: string;
  queryState: ('failed' | 'canceled' | 'success' | null) | null;
  database: string | null;
  cmd: string;
  id: string;
};
export type UpdateQueryEditorHistoryEntriesBody = UpdateQueryEditorHistoryEntry[];
export type DeleteQueryEditorHistoryEntriesByIdBody = {
  ids: string[];
};
