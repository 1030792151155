import { Accordion, Button, IconButton, TextInput, TextLink } from '@neo4j-ndl/react';
import { TrashIconOutline } from '@neo4j-ndl/react/icons';
import type { DataApiModify } from '@nx/state';
import { useState } from 'react';

import type { Validation } from '../utils/validation';

interface AllowedOriginsListProps {
  data: DataApiModify;
  setData: React.Dispatch<React.SetStateAction<DataApiModify>>;
  validationError: Validation<DataApiModify> | null;
  setValidationError: React.Dispatch<React.SetStateAction<Validation<DataApiModify> | null>>;
}

export const AllowedOriginsList = ({ data, validationError, setData, setValidationError }: AllowedOriginsListProps) => {
  const [expandedItemId, setExpandedItemId] = useState<string | null>(null);

  const handleOriginUpdate = (origin: string, index: number) => {
    setValidationError(null);
    const allowedOrigins = data.allowedOrigins.map((currentOrigin, idx) => (index === idx ? origin : currentOrigin));
    setData((prev) => ({ ...prev, allowedOrigins }));
  };

  const handleAdd = () => {
    setData((prev: DataApiModify) => ({
      ...prev,
      allowedOrigins: [...prev.allowedOrigins, ''],
    }));
  };

  const handleDelete = (index: number) => {
    const allowedOrigins = data.allowedOrigins.filter((_, idx) => index !== idx);
    setData((prev) => ({ ...prev, allowedOrigins }));
  };

  const OriginRow = (origin: string, idx: number) => {
    return (
      <div
        key={`origin-${idx}`}
        className="bg-palette-neutral-bg-default rounded-4xl mb-2 flex w-full flex-row items-end justify-between p-4"
      >
        <div className="flex flex-row justify-normal">
          <TextInput
            isFluid
            className="w-72"
            htmlAttributes={{
              'aria-label': 'input origin',
            }}
            value={origin}
            onChange={(event) => handleOriginUpdate(event.target.value, idx)}
            errorText={validationError?.[`allowedOrigins[${idx}]`]?.message}
          />
        </div>

        <div className="flex flex-row items-center">
          <IconButton isClean ariaLabel="Delete origin" onClick={() => handleDelete(idx)}>
            <TrashIconOutline className="h-full w-full" />
          </IconButton>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="p-2">
        <Accordion
          isMultiple={false}
          expandedItemId={expandedItemId}
          onChange={() => {
            if (expandedItemId === null) {
              setExpandedItemId('what-is-cors');
            } else {
              setExpandedItemId(null);
            }
          }}
        >
          <Accordion.Item title="What is CORS?" itemId="what-is-cors" className="[&>div:last-child]:hidden">
            <div className="p-2">
              CORS is a security mechanism that specifies which origins (web pages) are allowed to make a request to
              this GraphQL Data API. This helps to protect against unauthorized data access.{' '}
              <TextLink type="external" href="https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS">
                More information on CORS can be found in the MDN documentation.
              </TextLink>
            </div>
          </Accordion.Item>
        </Accordion>
      </div>
      {data.allowedOrigins.map((origin, idx) => OriginRow(origin, idx))}
      <Button fill="outlined" size="small" className="mb-4 ml-4 mt-3" onClick={() => handleAdd()}>
        Add allowed origin
      </Button>
    </div>
  );
};
