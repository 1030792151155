import type { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { isNil, keyBy } from 'lodash-es';
import { v1 as uuidv1 } from 'uuid';

import type { Scene } from '../../types/scene';
import { isFalsy } from '../../types/utility';
import { REHYDRATE } from '../persistence/constants';
import type { RootState } from '../types';
import type { SceneEditMode, SceneState } from './scene.types';

export const NAME = 'scene';

export const SCENE_EDIT_MODE_VIEWING = 'viewing';
export const SCENE_EDIT_MODE_EDITING = 'editing';

export const initialState: SceneState = {
  scenes: {},
  currentSceneId: undefined,
  currentNodePositions: [],
  isLoadingScene: false,
  editMode: SCENE_EDIT_MODE_EDITING,
  startWithNewScene: false,
  startDeepLinkedScene: null,
  showViewingModeMessage: true,
};

/**
 * Selectors
 */

export const isStartWithNewSceneActive = (state: RootState): boolean => state[NAME].startWithNewScene;
export const getStartDeepLinkedScene = (state: RootState): string | null => state[NAME].startDeepLinkedScene;

export const getScenes = createSelector(
  (state: RootState) => state[NAME].scenes,
  (scenes) => {
    const allScenes = Object.values(scenes);
    return allScenes.sort((firstEl, secondEl) => secondEl.createdAt.getTime() - firstEl.createdAt.getTime());
  },
);

export const getCurrentSceneId = (state: RootState) => state[NAME].currentSceneId;
export const getCurrentScene = (state: RootState) =>
  state[NAME].currentSceneId !== undefined ? state[NAME].scenes[state[NAME].currentSceneId] : undefined;
export const getSceneById =
  (state: RootState) =>
  (id: Scene['id']): Scene | undefined =>
    state[NAME].scenes[id];

export const getCurrentNodePositions = (state: RootState): SceneState['currentNodePositions'] =>
  state[NAME].currentNodePositions;
export const getIsLoadingScene = (state: RootState): boolean => state[NAME].isLoadingScene;
export const getSceneEditMode = (state: RootState): SceneEditMode => state[NAME].editMode;
export const showViewingModeMessage = (state: RootState) => state[NAME].showViewingModeMessage;
export const isCurrentSceneTemporary = (state: RootState): boolean => Boolean(getCurrentScene(state)?.temporaryData);

export const buildScene = (name: string, userId: string, version: string, temporary = false): Scene => {
  const temporaryData = temporary ? {} : undefined;

  return {
    name,
    id: uuidv1(),
    createdBy: userId,
    createdAt: new Date(),
    numOfNodes: 0,
    numOfRels: 0,
    roles: [],
    ranges: [],
    version,
    temporaryData,
  };
};

/**
 * Reducer and helpers
 */

const sceneSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setScenes(state, action: PayloadAction<Scene[]>) {
      const newScenes = action.payload;
      state.scenes = keyBy(newScenes, 'id');
    },
    setCurrentSceneId(state, action: PayloadAction<Scene['id']>) {
      state.currentSceneId = action.payload;
    },
    setCurrentNodePositions(state, action: PayloadAction<SceneState['currentNodePositions']>) {
      state.currentNodePositions = action.payload;
    },
    setLoadingScene(state, action: PayloadAction<SceneState['isLoadingScene']>) {
      state.isLoadingScene = action.payload;
    },
    setEditMode(state, action: PayloadAction<SceneState['editMode']>) {
      state.editMode = action.payload;
    },
    resetEditMode(state) {
      state.editMode = SCENE_EDIT_MODE_EDITING;
    },
    addScene(state, action: PayloadAction<Scene>) {
      const newScene = action.payload;
      state.scenes[newScene.id] = newScene;
    },
    duplicateScene(state, action: PayloadAction<Scene>) {
      const newScene = action.payload;
      state.scenes[newScene.id] = newScene;
    },
    updateScene(state, action: PayloadAction<Scene>) {
      const scene = action.payload;
      state.scenes[scene.id] = scene;
    },
    updateSceneName(state, action: PayloadAction<{ sceneId: Scene['id']; newName: Scene['name'] }>) {
      const { sceneId, newName } = action.payload;
      const scene = state.scenes[sceneId];
      if (scene) {
        scene.name = newName;
      }
    },
    updateTemporarySceneData(
      state,
      action: PayloadAction<{ sceneId: Scene['id']; temporaryData: Scene['temporaryData'] }>,
    ) {
      const { sceneId, temporaryData } = action.payload;
      const scene = state.scenes[sceneId];
      if (scene) {
        scene.temporaryData = temporaryData;
      }
    },
    updateSceneRoles(state, action: PayloadAction<{ sceneId: Scene['id']; newRoles: Scene['roles'] }>) {
      const { sceneId, newRoles } = action.payload;
      const scene = state.scenes[sceneId];
      if (scene) {
        scene.roles = newRoles;
      }
    },
    updateSceneLastModified(
      state,
      action: PayloadAction<{ sceneId: Scene['id']; lastModified: Scene['lastModified'] }>,
    ) {
      const { sceneId, lastModified } = action.payload;
      const scene = state.scenes[sceneId];
      if (scene) {
        scene.lastModified = lastModified;
      }
    },
    updateSceneGraphCounters(
      state,
      action: PayloadAction<{ numOfNodes: Scene['numOfNodes']; numOfRels: Scene['numOfRels'] }>,
    ) {
      if (isNil(state.currentSceneId)) return;
      const { numOfNodes, numOfRels } = action.payload;
      const scene = state.scenes[state.currentSceneId];
      if (scene) {
        scene.numOfNodes = numOfNodes;
        scene.numOfRels = numOfRels;
      }
    },
    removeScene(state, action: PayloadAction<Scene['id']>) {
      const sceneId = action.payload;
      delete state.scenes[sceneId];
      if (sceneId === state.currentSceneId) state.currentSceneId = undefined;
    },
    clearScenes(state) {
      state.scenes = {};
      state.currentSceneId = undefined;
    },
    setStartWithNewScene(state, action: PayloadAction<SceneState['startWithNewScene']>) {
      state.startWithNewScene = action.payload;
    },
    setDeepLinkedScene(state, action: PayloadAction<SceneState['startDeepLinkedScene']>) {
      state.startDeepLinkedScene = action.payload;
    },
    setShowViewingModeMessage(state, action: PayloadAction<SceneState['showViewingModeMessage']>) {
      state.showViewingModeMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action: AnyAction) => {
      const { payload } = action;
      if (isFalsy(payload?.[NAME])) return state;
      return {
        ...state,
        ...payload[NAME],
      };
    });
  },
});

export const {
  setScenes,
  setCurrentSceneId,
  setCurrentNodePositions,
  setLoadingScene,
  setEditMode,
  resetEditMode,
  addScene,
  duplicateScene,
  updateScene,
  updateSceneName,
  updateTemporarySceneData,
  updateSceneRoles,
  updateSceneLastModified,
  updateSceneGraphCounters,
  removeScene,
  clearScenes,
  setStartWithNewScene,
  setDeepLinkedScene,
  setShowViewingModeMessage,
} = sceneSlice.actions;

export default sceneSlice.reducer;
