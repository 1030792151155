import type { ComparisonOp, PredicateFunction, Property, Return } from '@neo4j/cypher-builder';
import {
  Literal,
  Match,
  Node,
  Param,
  Pattern,
  Union,
  Variable,
  any,
  contains,
  eq,
  labels,
  matches,
  startsWith,
  toString,
  toStringOrNull,
} from '@neo4j/cypher-builder';
import { isNil } from 'lodash-es';

import { PROPERTY_CONDITION_EQUALS } from '../../../../../modules/SearchBar/SearchBar.const';
import {
  alignCase,
  buildSuggestion,
  wrapInContainsQueryRegexp,
  wrapInEqualsQueryRegexp,
  wrapInStartWithQueryRegexp,
} from '../../../../../modules/SearchBar/SearchBar.utils';
import type { NodeSuggestion } from '../../../../../modules/SearchBar/types';
import { SUGGESTION_TYPE } from '../../../../../modules/SearchBar/types';
import { DATE_TIME_TYPE } from '../../../../../services/temporal/utils.const';
import type { GenerateTextQueryParams, MapRecordsParams } from '../property-value-suggestions.types';
import { findCategoryByLabel, shouldMatchForProperty } from './util';

export const generateTextQuery = ({
  query,
  indexes,
  labelPropertyKeys,
  limit,
  isCaseInsensitive = false,
}: GenerateTextQueryParams) => {
  const inputParam = new Param(query);
  const queryParamEqualString = new Param(wrapInEqualsQueryRegexp(isCaseInsensitive, query));
  const queryParamStartsWithString = new Param(wrapInStartWithQueryRegexp(isCaseInsensitive, query));
  const queryParamContainsString = new Param(wrapInContainsQueryRegexp(isCaseInsensitive, query));

  const indexQueriesEqual: Return[] = [];
  const indexQueriesStartsWith: Return[] = [];
  const indexQueriesContains: Return[] = [];

  for (const [label, property, indexPropertyType] of indexes) {
    const node = new Node({ labels: [label] });
    const pattern = new Pattern(node);
    const labelPropertyKey = labelPropertyKeys ? labelPropertyKeys[label] : undefined;
    const propertyType =
      indexPropertyType ??
      labelPropertyKey?.find((generalProperty) => generalProperty.propertyKey === property)?.dataType ??
      '';
    const isTemporal = DATE_TIME_TYPE.includes(propertyType);
    const propertyValue = node.property(property);

    if (shouldMatchForProperty(alignCase(isCaseInsensitive, query), propertyType)) {
      let equalConditionOperator: ComparisonOp | PredicateFunction;
      let startWithConditionOperator: ComparisonOp | PredicateFunction | undefined;
      let containsConditionOperator: ComparisonOp | PredicateFunction | undefined;

      if (propertyType === 'string') {
        if (isCaseInsensitive) {
          equalConditionOperator = matches(propertyValue, queryParamEqualString);
          startWithConditionOperator = matches(propertyValue, queryParamStartsWithString);
          containsConditionOperator = matches(propertyValue, queryParamContainsString);
        } else {
          equalConditionOperator = eq(propertyValue, inputParam);
          startWithConditionOperator = startsWith(propertyValue, inputParam);
          containsConditionOperator = contains(propertyValue, inputParam);
        }
      } else if (propertyType === 'array') {
        const variable = new Variable();
        const variableValue = toStringOrNull(variable);

        if (isCaseInsensitive) {
          equalConditionOperator = any(variable, propertyValue, matches(variableValue, queryParamEqualString));
          startWithConditionOperator = any(variable, propertyValue, matches(variableValue, queryParamStartsWithString));
          containsConditionOperator = any(variable, propertyValue, matches(variableValue, queryParamContainsString));
        } else {
          equalConditionOperator = any(variable, propertyValue, eq(variableValue, inputParam));
          startWithConditionOperator = any(variable, propertyValue, startsWith(variableValue, inputParam));
          containsConditionOperator = any(variable, propertyValue, contains(variableValue, inputParam));
        }
      } else if (propertyType === 'boolean') {
        const value = alignCase(isCaseInsensitive, query) === 'true';
        equalConditionOperator = eq(propertyValue, new Param(value));
      } else if (isTemporal) {
        equalConditionOperator = eq(
          toString(propertyValue),
          isCaseInsensitive ? new Param(query.toUpperCase()) : inputParam,
        );
      } else {
        equalConditionOperator = eq(toString(propertyValue), inputParam);
      }

      const equalMatch = new Match(pattern).where(equalConditionOperator);
      const equalReturnClause = addReturn(
        equalMatch,
        node,
        property,
        propertyType,
        propertyValue,
        isNil(startWithConditionOperator) && isNil(containsConditionOperator) ? limit : 1,
      );
      indexQueriesEqual.push(equalReturnClause);
      if (!isNil(startWithConditionOperator)) {
        const startWithMatch = new Match(pattern).where(startWithConditionOperator);
        const startWithReturnClause = addReturn(startWithMatch, node, property, propertyType, propertyValue, limit);
        indexQueriesStartsWith.push(startWithReturnClause);
      }

      if (!isNil(containsConditionOperator)) {
        const containsMatch = new Match(pattern).where(containsConditionOperator);
        const containsReturnClause = addReturn(containsMatch, node, property, propertyType, propertyValue, limit);
        indexQueriesContains.push(containsReturnClause);
      }
    }
  }

  return new Union(...indexQueriesEqual, ...indexQueriesStartsWith, ...indexQueriesContains).build();
};

const addReturn = (
  matchClause: Match,
  node: Node,
  propertyName: string,
  propertyType: string,
  propertyValue: Property,
  limit: number,
) =>
  matchClause
    .return(
      [labels(node), 'labels'],
      [propertyValue, 'value'],
      [new Literal(propertyName), 'propertyName'],
      [new Literal(propertyType), 'propertyType'],
    )
    .limit(limit);

export const mapTextQueryRecords = ({ records, categoryToLabelDictionary }: MapRecordsParams) =>
  records.map((record: any) => {
    const labelToCategoryMap = new Map<string, string>();
    const labels = record.get('labels');
    const propertyName = record.get('propertyName');
    const propertyType = record.get('propertyType');
    const value = String(record.get('value'));

    return buildSuggestion<NodeSuggestion>({
      type: SUGGESTION_TYPE.NODE,
      categoryName: findCategoryByLabel(labels, categoryToLabelDictionary, labelToCategoryMap) ?? '',
      propertyConditionValue: value,
      isCaseInsensitive: true,
      propertyCondition: PROPERTY_CONDITION_EQUALS,
      propertyName,
      propertyType,
      labels,
    });
  });
