import type { DataModel_0_0_2 } from './0.0.2/types';
import { isDataModel_0_0_2 } from './0.0.2/types';
import { type DataModel_0_8_0, isDataModel_0_8_0 } from './0.8.0/types';
import { type DataModelJsonStruct_1_0_0, isDataModelJsonStruct_1_0_0 } from './1.0.0/data-model.json.type';
import { isDataModelJsonStruct_1_2_0 } from './1.2.0/data-model';
import type { DataModelJsonStruct_1_2_0 } from './1.2.0/data-model.json.type';
import { isDataModelJsonStruct_1_4_0 } from './1.4.0/data-model';
import type { DataModelJsonStruct_1_4_0 } from './1.4.0/data-model.json.type';
import { isDataModelJsonStruct_2_1_0 } from './2.1.0/data-model';
import type { DataModelJsonStruct_2_1_0 } from './2.1.0/data-model.json.type';
import { isDataModelJsonStruct_2_2_0 } from './2.2.0/data-model';
import type { DataModelJsonStruct_2_2_0 } from './2.2.0/data-model.json.type';
import type { DataModel_0_0_1 } from './lte-0.0.1/types';
import { isDataModel_0_0_1 } from './lte-0.0.1/types';

export type DeprecatedDataModel = {
  '0.0.1': DataModel_0_0_1;
  '0.0.2': DataModel_0_0_2;
  '0.8.0': DataModel_0_8_0;
  '1.0.0': DataModelJsonStruct_1_0_0;
  '1.2.0': DataModelJsonStruct_1_2_0;
  '1.4.0': DataModelJsonStruct_1_4_0;
  '2.1.0': DataModelJsonStruct_2_1_0;
  '2.2.0': DataModelJsonStruct_2_2_0;
};

export type AnyDeprecatedDataModel = DeprecatedDataModel[keyof DeprecatedDataModel];

export const isAnyDeprecatedDataModel = (dataModel: unknown): dataModel is AnyDeprecatedDataModel => {
  return (
    isDataModelJsonStruct_2_2_0(dataModel) ||
    isDataModelJsonStruct_2_1_0(dataModel) ||
    isDataModelJsonStruct_1_4_0(dataModel) ||
    isDataModelJsonStruct_1_2_0(dataModel) ||
    isDataModelJsonStruct_1_0_0(dataModel) ||
    isDataModel_0_8_0(dataModel) ||
    isDataModel_0_0_2(dataModel) ||
    isDataModel_0_0_1(dataModel)
  );
};
