import { zipObject } from 'lodash-es';
import type { Record as BoltRecord } from 'neo4j-driver';
import neo4j from 'neo4j-driver';
import type { Integer, Node, Path, PathSegment, Record, RecordShape, Relationship } from 'neo4j-driver-core';
import { isNode, isPath, isRelationship } from 'neo4j-driver-core';

import type { Nullable } from '../../types/utility';
import { isFalsy } from '../../types/utility';

const filterUnique = <T extends Node | Relationship>(list: T[]): T[] => {
  const ids: RecordShape<string, true> = {};
  return list.filter((item) => {
    const id = item.elementId;
    const result = !ids[id];
    ids[id] = true;
    return result;
  });
};

const resultContainsGraphKeys = <Key>(keys: Key[]) => {
  return keys.includes('nodes' as Key) && keys.includes('relationships' as Key);
};

export const flattenArray = <Item>(arr: Item[][]): Item[] => {
  return arr.reduce((all, curr) => {
    if (Array.isArray(curr)) return all.concat(flattenArray(curr as Item[][]));
    return all.concat(curr);
  }, []);
};

type Extracted<Item> = Node | Relationship | Path | boolean | Item | Item[];
export const recursivelyExtractGraphItems = <Item>(item: Item | Item[]): Extracted<Item> => {
  if (Array.isArray(item)) {
    // @ts-expect-error I couldn't figure out how to type this properly even with generics
    return item.map((i) => recursivelyExtractGraphItems(i));
  }

  if (['number', 'string', 'boolean'].includes(typeof item)) return false;
  if (item === null) return false;
  if (typeof item === 'object') {
    if (isNode(item) || isRelationship(item) || isPath(item)) return item;

    return Object.values(item).map((i) => recursivelyExtractGraphItems(i));
  }
  return item;
};

export const extractNodesAndRelationshipsFromRecords = (
  records?: Nullable<BoltRecord[]>,
): {
  nodes: Node[];
  relationships: Relationship[];
} => {
  if (isFalsy(records) || records.length === 0 || isFalsy(records[0])) {
    return { nodes: [], relationships: [] };
  }
  const [{ keys }] = records;
  let rawNodes: Node[] = [];
  let rawRels: Relationship[] = [];
  if (resultContainsGraphKeys(keys) && keys[0] && keys[1]) {
    rawNodes = [...rawNodes, ...records[0].get(keys[0])];
    rawRels = [...rawRels, ...records[0].get(keys[1])];
  } else {
    records.forEach((record) => {
      let graphItems = keys.map((key) => record.get(key));
      graphItems = flattenArray(recursivelyExtractGraphItems(graphItems)).filter((item) => item !== false);
      rawNodes = [...rawNodes, ...graphItems.filter((item) => isNode(item))];
      rawRels = [...rawRels, ...graphItems.filter((item) => isRelationship(item))];
      const paths: Path[] = graphItems.filter((item) => isPath(item));
      paths.forEach((item) => extractNodesAndRelationshipsFromPath(item, rawNodes, rawRels));
    });
  }

  return {
    nodes: filterUnique(rawNodes),
    relationships: filterUnique(rawRels),
  };
};

export const extractNodesAndRelationshipsFromPath = (
  item: Path | Path[],
  rawNodes: Node[],
  rawRels: Relationship[],
) => {
  // this check does not make sense, since we are doing isPath check in the caller, meaning item is always a Path
  const paths = Array.isArray(item) ? item : [item];
  paths.forEach((path) => {
    let { segments } = path;
    // Zero length path. No relationship, end === start
    if (!Array.isArray(path.segments) || path.segments.length < 1) {
      // @ts-expect-error this shows TS error - TS2322: Type 'null' is not assignable to type 'Node<Integer, Properties, string>'.
      segments = [{ ...path, end: null }];
    }

    const nodeMap: RecordShape<string, true> = {};

    segments.forEach((segment) => {
      // According to type declaration segment is always supposed to have 'start', 'end' and 'relationship',
      // so eslint warns us that this check always returns true
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (segment.start && !nodeMap[segment.start.toString()]) {
        rawNodes.push(segment.start);
        nodeMap[segment.start.toString()] = true;
      }
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (segment.end && !nodeMap[segment.end.toString()]) {
        rawNodes.push(segment.end);
        nodeMap[segment.end.toString()] = true;
      }
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (segment.relationship) {
        rawRels.push(segment.relationship);
      }
    });
  });
};

export const applyGraphTypes = (
  item: BoltRecord['_fields'],
): Node | Relationship | Integer | PathSegment | Path | RecordShape<string, unknown> | BoltRecord['_fields'] => {
  if (item === null || item === undefined) {
    return item;
  } else if (Array.isArray(item)) {
    return item.map(applyGraphTypes);
  } else if (
    item.hasOwnProperty('labels') &&
    item.hasOwnProperty('properties') &&
    (item.hasOwnProperty('identity') || item.hasOwnProperty('elementId'))
  ) {
    return new neo4j.types.Node(
      applyGraphTypes(item.identity),
      item.labels,
      applyGraphTypes(item.properties),
      item.elementId,
    );
  } else if (
    item.hasOwnProperty('labels') &&
    item.hasOwnProperty('propKeys') &&
    item.hasOwnProperty('propValues') &&
    (item.hasOwnProperty('identity') || item.hasOwnProperty('elementId'))
  ) {
    return new neo4j.types.Node(
      applyGraphTypes(item.identity),
      item.labels,
      applyGraphTypes(zipObject(item.propKeys, item.propValues)),
      item.elementId,
    );
  } else if (item.hasOwnProperty('segments') && item.hasOwnProperty('start') && item.hasOwnProperty('end')) {
    const start = applyGraphTypes(item.start) as Node;
    const end = applyGraphTypes(item.end) as Node;
    const segments = item.segments.map(applyGraphTypes);
    return new neo4j.types.Path(start, end, segments);
  } else if (item.hasOwnProperty('relationship') && item.hasOwnProperty('start') && item.hasOwnProperty('end')) {
    const start = applyGraphTypes(item.start) as Node;
    const end = applyGraphTypes(item.end) as Node;
    const relationship = applyGraphTypes(item.relationship) as Relationship;
    return new neo4j.types.PathSegment(start, relationship, end);
  } else if (
    (item.hasOwnProperty('identity') || item.hasOwnProperty('elementId')) &&
    (item.hasOwnProperty('start') || item.hasOwnProperty('startNodeElementId')) &&
    (item.hasOwnProperty('end') || item.hasOwnProperty('endNodeElementId')) &&
    item.hasOwnProperty('type')
  ) {
    return new neo4j.types.Relationship(
      applyGraphTypes(item.identity),
      applyGraphTypes(item.start),
      applyGraphTypes(item.end),
      item.type,
      applyGraphTypes(item.properties),
      item.elementId,
      item.startNodeElementId,
      item.endNodeElementId,
    );
  } else if (
    item.hasOwnProperty('low') &&
    item.hasOwnProperty('high') &&
    typeof item.low === 'number' &&
    typeof item.high === 'number'
  ) {
    return neo4j.int(item);
  } else if (typeof item === 'object') {
    const typedObject: RecordShape<string, unknown> = {};
    Object.keys(item).forEach((key) => {
      typedObject[key] = applyGraphTypes(item[key]);
    });
    return typedObject;
  }
  return item;
};

export const recordMapper = (typedRecord: Record) => {
  // @ts-expect-error we rely on internals here
  if (typedRecord._fields != null) {
    // @ts-expect-error and here
    typedRecord._fields = typedRecord._fields.map((field) => applyGraphTypes(field));
  }
  return typedRecord;
};
