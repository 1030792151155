import type {
  FlatSavedCypherFolder,
  FlatSavedCypherItem,
  FlatSavedCypherQuery,
  SavedCypherItem,
  TreeItems,
} from '@query/types/saved-cypher';
import {
  isFlatSavedCypherFolder,
  isFlatSavedCypherQuery,
  isSavedCypherFolder,
  isSavedCypherQuery,
} from '@query/types/saved-cypher';
import { nanoid } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import type { ParseResult } from 'papaparse';
import Papa from 'papaparse';

const CYPHER_EXTENSION = '.cypher';

export const SAVED_CYPHER_NAME_LIMIT = 500;
export const MAXIMUM_FOLDER_DEPTH = 7;
export const MAXIMUM_FOLDER_DEPTH_ERROR = 'Maximum depth of folders exceeded';
export const MAXIMUM_SAVED_CYPHER_ITEMS = 500;
export const MAXIMUM_SAVED_CYPHER_ITEMS_ERROR = 'Maximum number of items exceeded';
export const ERROR_MESSAGE_SAVED_CYPHER_NAME_LIMIT = `Name needs to be less than ${SAVED_CYPHER_NAME_LIMIT} characters`;
export const ERROR_MESSAGE_SAVED_CYPHER_NAME_REQUIRED = 'Name is required';
export const ERROR_MESSAGE_SAVED_CYPHER_NOT_ONLY_WHITESPACE = `Name can't contain only spaces`;
export const ERROR_MESSAGE_SAVED_CYPHER_NAME_TAKEN = (newName: string) => `The name "${newName}" is already taken.`;

export const FOLDER_NAME_LIMIT = 500;
const ERROR_MESSAGE_SAVED_CYPHER_FOLDER_NAME_LIMIT = `Name needs to be less than ${FOLDER_NAME_LIMIT} characters`;

const keysToGather = ['name', 'description', 'query', 'id', 'parentId'];
const requiredKeys = [...keysToGather, 'isFolder'];

type FlattenedTreeItemCsv = FlatSavedCypherItem & { isFolder: string | undefined };

export const savedCypherToCsvString = (data: FlatSavedCypherItem[]): string => {
  const header = [...keysToGather, 'isFolder'];
  const idMap: Record<string, number> = {};
  let currentIdMapIncrement = 0;

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const castData = data as Record<string, string | number | null | boolean>[];

  const newData = castData.map((row) => {
    const newRow: (string | number | null | undefined | boolean)[] = keysToGather.map((key) => {
      if (key === 'id' || key === 'parentId') {
        const id = row[key];
        if (id === null || id === undefined) {
          return null;
        }
        // Map id to a incremental number
        const mappedId = idMap[id.toString()];
        if (mappedId === undefined) {
          idMap[id.toString()] = currentIdMapIncrement;
          currentIdMapIncrement += 1;
          return idMap[id.toString()];
        }
        return mappedId;
      }
      return row[key];
    });

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    newRow.push(Boolean(isFlatSavedCypherFolder(row as FlatSavedCypherQuery)));
    return newRow;
  });
  const result = Papa.unparse([header, ...newData]);
  return result;
};

export const exportSavedCypherAsCsv = (data: FlatSavedCypherItem[]) => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const filename = `neo4j_query_saved_cypher_${year}-${month}-${day}.csv`;

  const csvContent = savedCypherToCsvString(data);
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' });
  saveAs(blob, filename);
};

function flatten(items: TreeItems, parentId: string | null = null, depth = 0): FlatSavedCypherItem[] {
  return items
    .sort((a, b) => (a.order < b.order ? -1 : 1))
    .reduce<FlatSavedCypherItem[]>((acc, item, index) => {
      let children: TreeItems = [];
      if (isSavedCypherFolder(item)) {
        children = item.children;
      }
      return [...acc, { ...item, parentId, depth, order: index }, ...flatten(children, item.id, depth + 1)];
    }, []);
}

export function flattenTree(items: TreeItems): FlatSavedCypherItem[] {
  return flatten(items);
}

export function findItem(items: SavedCypherItem[], itemId: string) {
  return items.find(({ id }) => id === itemId);
}

/* merges to tree into one tree. The new items overwrite the old items */
export function mergeTrees(items: TreeItems, newItems: TreeItems): TreeItems {
  const itemsCopy: TreeItems = [...items];
  newItems.forEach((item) => {
    const existingItem = itemsCopy.find(({ name }) => name === item.name);
    if (existingItem !== undefined) {
      if (isSavedCypherQuery(existingItem) && isSavedCypherQuery(item)) {
        existingItem.name = item.name;
        existingItem.databaseId = item.databaseId;
        existingItem.query = item.query;
        existingItem.description = item.description;
      } else if (isSavedCypherFolder(existingItem) && isSavedCypherFolder(item)) {
        existingItem.name = item.name;
        existingItem.databaseId = item.databaseId;
        existingItem.children = mergeTrees(existingItem.children, item.children);
      }
    } else {
      // If items does not exist at the root at it to the tree
      itemsCopy.push(item);
    }
  });

  return itemsCopy;
}

export function numberOfOverlapsBetweenTrees(
  items: TreeItems,
  newItems: TreeItems,
): { folderCount: number; itemsCount: number } {
  let itemsCount = 0;
  let folderCount = 0;

  newItems.forEach((item) => {
    const existingItem = items.find(({ name }) => name === item.name);

    if (existingItem !== undefined) {
      if (isSavedCypherQuery(existingItem) && isSavedCypherQuery(item)) {
        itemsCount += 1;
      } else if (isSavedCypherFolder(existingItem) && isSavedCypherFolder(item)) {
        folderCount += 1;
        const counts = numberOfOverlapsBetweenTrees(existingItem.children, item.children);
        folderCount += counts.folderCount;
        itemsCount += counts.itemsCount;
      }
    }
  });
  return { folderCount, itemsCount };
}

// nodes is for optimization, to find parent faster if already used it
export function buildTree(flattenedItems: FlatSavedCypherItem[]): TreeItems {
  const root: { id: string; children: SavedCypherItem[]; depth: number } = { id: 'root', children: [], depth: -1 };
  const nodes: Record<string, { id: string; children: SavedCypherItem[]; depth: number }> = { [root.id]: root };
  const items = flattenedItems.map((item) => {
    return {
      ...item,
      depth: 0,
      children: [],
    };
  });
  for (const item of items) {
    const { id } = item;

    const parentId = item.parentId ?? root.id;

    const parent = parentId in nodes ? nodes[parentId] : findItem(items, parentId);

    nodes[id] = { id: item.id, children: item.children, depth: (parent?.depth ?? 0) + 1 };

    if (parent !== undefined && 'children' in parent) {
      parent.children.push({ ...item, depth: parent.depth + 1 });
    }
  }

  return root.children;
}

export function findItemDeep(items: TreeItems, itemId: string): SavedCypherItem | undefined {
  for (const item of items) {
    if (item.id === itemId) {
      return item;
    }

    if (!isSavedCypherFolder(item)) {
      continue;
    } else if (item.children.length) {
      const child = findItemDeep(item.children, itemId);

      if (child) {
        return child;
      }
    }
  }

  return undefined;
}

export const findDuplicateInFolder = (
  tree: FlatSavedCypherItem[],
  folder: string | null,
  newName: string,
  typesToConsider: 'all' | 'items' | 'folders',
): SavedCypherItem | undefined => {
  const parentId = folder ?? 'root';
  const builtTree: TreeItems = [
    { name: 'root', databaseId: null, children: buildTree(tree), id: 'root', order: 0, depth: -1 },
  ];
  const parent = findItemDeep(builtTree, parentId);
  if (parent === undefined) {
    return undefined;
  }

  if (isSavedCypherFolder(parent)) {
    const childNames = parent.children
      .filter((child) => {
        switch (typesToConsider) {
          case 'folders':
            return isSavedCypherFolder(child);
          case 'items':
            return isSavedCypherQuery(child);
          default:
            return true;
        }
      })
      .map((child) => child);
    return childNames.find((child) => child.name === newName);
  }
  return undefined;
};

export const nameExistsInFolder = (
  tree: FlatSavedCypherItem[],
  folder: string | null,
  newName: string,
  typesToConsider: 'all' | 'items' | 'folders',
): boolean => Boolean(findDuplicateInFolder(tree, folder, newName, typesToConsider));

export const verifyFlatFolder = (
  newItem: Pick<FlatSavedCypherFolder, 'parentId' | 'name'>,
  oldItem: Pick<FlatSavedCypherFolder, 'parentId' | 'name'> | null,
  fullTree: FlatSavedCypherItem[],
): { success: true } | { success: false; message: string } => {
  if (newItem.name.length === 0) {
    return { success: false, message: ERROR_MESSAGE_SAVED_CYPHER_NAME_REQUIRED };
  } else if (newItem.name.trim().length === 0) {
    return { success: false, message: ERROR_MESSAGE_SAVED_CYPHER_NOT_ONLY_WHITESPACE };
  } else if (newItem.name.length > FOLDER_NAME_LIMIT) {
    return { success: false, message: ERROR_MESSAGE_SAVED_CYPHER_FOLDER_NAME_LIMIT };
  } else if (
    (oldItem?.name !== newItem.name || oldItem.parentId !== newItem.parentId) &&
    nameExistsInFolder(fullTree, newItem.parentId, newItem.name, 'folders')
  ) {
    return { success: false, message: ERROR_MESSAGE_SAVED_CYPHER_NAME_TAKEN(newItem.name) };
  }
  return { success: true };
};

export const verifyFlatQuery = (
  newItem: Pick<FlatSavedCypherQuery, 'parentId' | 'name'>,
  oldItem: Pick<FlatSavedCypherQuery, 'parentId' | 'name'> | null,
  fullTree: FlatSavedCypherItem[],
): { success: true } | { success: false; message: string } => {
  if (newItem.name === '') {
    return { success: false, message: ERROR_MESSAGE_SAVED_CYPHER_NAME_REQUIRED };
  } else if (newItem.name.trim().length === 0) {
    return { success: false, message: ERROR_MESSAGE_SAVED_CYPHER_NOT_ONLY_WHITESPACE };
  } else if (newItem.name.length > SAVED_CYPHER_NAME_LIMIT) {
    return { success: false, message: ERROR_MESSAGE_SAVED_CYPHER_NAME_LIMIT };
  } else if (
    (oldItem?.name !== newItem.name || oldItem.parentId !== newItem.parentId) &&
    nameExistsInFolder(fullTree, newItem.parentId, newItem.name, 'items')
  ) {
    return { success: false, message: ERROR_MESSAGE_SAVED_CYPHER_NAME_TAKEN(newItem.name) };
  }
  return { success: true };
};

export const getAllChildren = (items: FlatSavedCypherItem[], parent: FlatSavedCypherFolder): string[] => {
  const children = items.filter((item) => item.parentId === parent.id);

  if (children.length === 0) {
    return [];
  }

  const result = children.reduce<string[]>((acc, child) => {
    if (isFlatSavedCypherFolder(child)) {
      return [...acc, child.id, ...getAllChildren(items, child)];
    }
    return [...acc, child.id];
  }, []);

  return result;
};

export const updateSelected = (
  item: FlatSavedCypherItem,
  newSelected: Record<string, boolean>,
  flatItems: FlatSavedCypherItem[],
): Record<string, boolean> => {
  const parent = flatItems.find((flatItem) => flatItem.id === item.parentId);
  if (newSelected[item.id] === true) {
    if (parent) {
      updateSelected(parent, newSelected, flatItems);
    }
    return newSelected;
  }
  if (!isFlatSavedCypherFolder(item)) {
    return newSelected;
  }
  const children = getAllChildren(flatItems, item);
  const childrenSelected = children.filter((childId) => newSelected[childId]);
  const newState = childrenSelected.length === children.length;
  if (newState) {
    newSelected[item.id] = true;
    if (parent) {
      updateSelected(parent, newSelected, flatItems);
    }
  }
  return newSelected;
};
export const multiSelectCheckboxes = (
  prev: Record<string, boolean>,
  item: FlatSavedCypherItem,
  flattenedAndRemovedCollapsedItems: FlatSavedCypherItem[],
  flattenedItems: FlatSavedCypherItem[],
  lastSelectedId: string,
): Record<string, boolean> => {
  const prevCopy = { ...prev };
  const idToItem = new Map(flattenedItems.map((i) => [i.id, i]));
  // Avoid going through all children multiple times if a parent has already handled it
  const alreadyHandled = new Set();
  const markItemAndChildren = (itemToMark: FlatSavedCypherItem) => {
    if (alreadyHandled.has(itemToMark.id)) {
      return;
    }
    prevCopy[itemToMark.id] = true;
    alreadyHandled.add(itemToMark.id);
    if (isSavedCypherFolder(itemToMark)) {
      for (const child of itemToMark.children) {
        const [childFlattened] = flatten([child]);
        if (!childFlattened) {
          continue;
        }
        markItemAndChildren(childFlattened);
      }
    }
  };
  const lastSelectedIndex = flattenedAndRemovedCollapsedItems.findIndex(({ id }) => id === lastSelectedId);
  const currentIndex = flattenedAndRemovedCollapsedItems.findIndex(({ id }) => id === item.id);
  const start = Math.min(lastSelectedIndex, currentIndex);
  const end = Math.max(lastSelectedIndex, currentIndex);
  flattenedAndRemovedCollapsedItems.slice(start, end + 1).forEach((currentItem) => {
    markItemAndChildren(currentItem);
    if (currentItem.parentId !== null && idToItem.has(currentItem.parentId)) {
      const parent = idToItem.get(currentItem.parentId);
      if (parent) {
        updateSelected(parent, prevCopy, flattenedItems);
      }
    }
  });
  return prevCopy;
};
export interface PrepareZipErrors {
  success: false;
  errorType: 'general';
  message: string;
}

export async function prepareZipImport(
  file: File,
): Promise<{ success: true; items: FlatSavedCypherItem[] } | PrepareZipErrors> {
  const toFlatSavedCypherFolder = (relativePath: string): FlatSavedCypherFolder => {
    const fixedPath = relativePath.slice(0, -1);
    const parentId = fixedPath.split('/').slice(0, -1).join('/');
    const folder: FlatSavedCypherFolder = {
      id: fixedPath,
      name: fixedPath.split('/').pop() ?? '',
      databaseId: null,
      children: [],
      parentId: parentId ? parentId : null,
      depth: 0,
      order: 0,
    };
    return folder;
  };

  const toFlatSavedCypherQuery = (relativePath: string, content: string | undefined): FlatSavedCypherQuery => {
    const parentPath = relativePath.split('/').slice(0, -1).join('/');
    const query: FlatSavedCypherQuery = {
      id: nanoid(),
      name: relativePath.split('/').pop() ?? '',
      databaseId: null,
      query: content ?? '',
      description: '',
      parentId: parentPath ? parentPath : null,
      depth: 0,
      order: 0,
    };
    return query;
  };

  const folderMap = new Map<string, FlatSavedCypherFolder>();
  const folders: FlatSavedCypherFolder[] = [];
  const queries: FlatSavedCypherItem[] = [];

  try {
    const arrayBuffer = await file.arrayBuffer();
    const zip = new JSZip();
    const unzipped = await zip.loadAsync(arrayBuffer);
    const entries = Object.values(unzipped.files);

    const processEntry = async (zipEntry: JSZip.JSZipObject) => {
      const relativePath = zipEntry.name;
      if (!relativePath || relativePath.startsWith('__MACOSX')) {
        return;
      }
      if (zipEntry.dir) {
        const folder = toFlatSavedCypherFolder(relativePath);
        folderMap.set(relativePath, folder);
        folders.push(folder);
      } else {
        if (!relativePath.endsWith(CYPHER_EXTENSION)) {
          return;
        }
        const content = await zipEntry.async('text');
        const query = toFlatSavedCypherQuery(relativePath, content);
        queries.push(query);
      }
    };

    await Promise.all([...entries.map(processEntry)]);
  } catch (error) {
    return { success: false, errorType: 'general', message: 'Failed to parse ZIP file.' };
  }

  const remapped = new Map<string, string>();
  const result = [...folders, ...queries];

  result.forEach((item, index) => {
    const newId = nanoid();
    remapped.set(item.id, newId);
    item.id = newId;
    item.order = index;
  });

  result.forEach((item) => {
    if (item.parentId !== null) {
      item.parentId = remapped.get(item.parentId) ?? null;
    }
  });

  return { success: true, items: result };
}

export type PrepareCsvErrors =
  | { success: false; errorType: 'missingKey'; missingKeys: string[] }
  | { success: false; errorType: 'duplicateId'; duplicateId: string }
  | { success: false; errorType: 'general'; message: string };

export const prepareCsvImport = async (
  file: File,
): Promise<{ success: true; items: FlatSavedCypherItem[] } | PrepareCsvErrors> => {
  const csvFlatTreeItemToFlatTreeItem = (item: FlattenedTreeItemCsv): FlatSavedCypherItem => {
    const { isFolder } = item;

    if (
      isFolder?.toLowerCase() === 'true' ||
      !isFlatSavedCypherQuery(item) ||
      (isFolder === undefined && isFlatSavedCypherQuery(item) && item.query === '')
    ) {
      return {
        id: typeof item.id === 'string' && item.id !== '' ? item.id : nanoid(),
        parentId: typeof item.parentId === 'string' && item.parentId !== '' ? item.parentId : null,
        name: typeof item.name === 'string' ? item.name : '',
        children: [],
        databaseId: null,
        depth: 0,
        order: 0,
      };
    }

    return {
      id: typeof item.id === 'string' && item.id !== '' ? item.id : nanoid(),
      parentId: typeof item.parentId === 'string' && item.parentId !== '' ? item.parentId : null,
      name: typeof item.name === 'string' ? item.name : '',
      query: typeof item.query === 'string' ? item.query : '',
      description: typeof item.description === 'string' ? item.description : '',
      databaseId: null,
      depth: 0,
      order: 0,
    };
  };

  const parsedCsv = await new Promise<FlattenedTreeItemCsv[]>((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      download: true,
      skipEmptyLines: true,
      delimiter: ',',
      complete: (results: ParseResult<FlattenedTreeItemCsv>) => {
        resolve(results.data);
      },
      error: (err: Error) => {
        return {
          success: false,
          errorType: 'general',
          message: 'Failed to parse CSV file.',
        };
      },
    });
  });

  const [first] = parsedCsv;
  if (first === undefined) {
    return {
      success: false,
      errorType: 'general',
      message: 'CSV file is empty.',
    };
  }

  const parsedKeys = Object.keys(first);

  const missingKeys = requiredKeys.filter((requiredKey) => !parsedKeys.includes(requiredKey));
  if (missingKeys.length !== 0) {
    return { success: false, errorType: 'missingKey', missingKeys };
  }

  // Check for duplicate ids
  const idsSoFar: string[] = [];

  for (const { id } of parsedCsv) {
    if (idsSoFar.includes(id)) {
      return { success: false, errorType: 'duplicateId', duplicateId: id };
    }
    idsSoFar.push(id);
  }

  const parsed = parsedCsv.map(csvFlatTreeItemToFlatTreeItem);
  const idMap: Record<string, string> = {};

  const parsedWithNewIds = parsed.map((item) => {
    const itemCopy = { ...item };
    const mappedId = idMap[item.id];

    if (mappedId === undefined) {
      const newId = nanoid();
      idMap[item.id] = newId;
      itemCopy.id = newId;
    } else {
      itemCopy.id = mappedId;
    }

    if (item.parentId === null || item.parentId === '') {
      itemCopy.parentId = null;
      return itemCopy;
    }

    const mappedParentId = idMap[item.parentId];
    if (mappedParentId === undefined) {
      const newId = nanoid();
      idMap[item.parentId] = newId;
      itemCopy.parentId = newId;
    } else {
      itemCopy.parentId = mappedParentId;
    }
    return itemCopy;
  });

  const uniqueFolders: FlatSavedCypherFolder[] = [];
  const folders = new Map<string, FlatSavedCypherItem>(
    parsedWithNewIds.filter(isFlatSavedCypherFolder).map((folder) => [folder.id, folder]),
  );
  const uniqueQueries: FlatSavedCypherQuery[] = [];
  const preparedItems: FlatSavedCypherItem[] = [];

  for (const [index, originalItem] of parsedWithNewIds.entries()) {
    const item = { ...originalItem, order: index };

    if (item.parentId !== null && !folders.has(item.parentId)) {
      item.parentId = null;
      item.depth = 0;
    } else if (item.parentId !== null && folders.has(item.parentId)) {
      const parent = folders.get(item.parentId);
      item.depth = (parent?.depth ?? 0) + 1;

      if (folders.has(item.id)) {
        folders.set(item.id, { ...folders.get(item.id), ...item });
      }
    }

    if (isFlatSavedCypherFolder(item)) {
      if (
        uniqueFolders.some((uniqueFolder) => uniqueFolder.name === item.name && item.parentId === uniqueFolder.parentId)
      ) {
        return {
          success: false,
          errorType: 'general',
          message: `Found two sibling folders with the same name "${item.name}" in import file. Please rename one of them and try again.`,
        };
      }
      uniqueFolders.push(item);
    } else {
      if (
        uniqueQueries.some((uniqueQuery) => uniqueQuery.name === item.name && item.parentId === uniqueQuery.parentId)
      ) {
        return {
          success: false,
          errorType: 'general',
          message: `Found two items with the same name and parent id: "${item.name}" in import file. Please rename one of them and try again.`,
        };
      }
      uniqueQueries.push(item);
    }
    preparedItems.push(item);
  }

  return { success: true, items: preparedItems };
};

export const recursivelyRemapData = (
  newParentId: string | null,
  oldParentId: string | null,
  existingItems: FlatSavedCypherItem[],
  importedItems: FlatSavedCypherItem[],
  depth: number,
) => {
  if (depth > MAXIMUM_FOLDER_DEPTH) {
    throw new Error(`Imported Cypher exceeds the maximum folder depth (${MAXIMUM_FOLDER_DEPTH}).`);
  }

  const idMap = new Map<string, string>();

  const updatedImportedItems = importedItems
    .filter((importedItem) => importedItem.parentId === oldParentId)
    .map((importedItem) => {
      const existingItem = existingItems.find((e) => {
        const existingFlatId = e.parentId !== null ? `${e.parentId}::${e.name}` : e.name;
        const importedFlatId = newParentId !== null ? `${newParentId}::${importedItem.name}` : importedItem.name;
        return existingFlatId === importedFlatId;
      });

      if (existingItem !== undefined) {
        if (!('query' in importedItem)) {
          idMap.set(existingItem.id, importedItem.id);
        }

        return { ...importedItem, id: existingItem.id, parentId: existingItem.parentId, order: importedItem.order };
      }

      if (!('query' in importedItem)) {
        idMap.set(importedItem.id, importedItem.id);
      }
      return { ...importedItem, parentId: newParentId, order: importedItem.order, isNew: true };
    });

  const updatedChildItems: FlatSavedCypherItem[] = Array.from(idMap.entries()).flatMap(
    ([newId, oldId]: [string, string]) => recursivelyRemapData(newId, oldId, existingItems, importedItems, depth + 1),
  );
  const mergedItems = [...updatedImportedItems, ...updatedChildItems];

  if (mergedItems.length > MAXIMUM_SAVED_CYPHER_ITEMS) {
    throw new Error(`Imported Cypher would exceed the maximum number of items (${MAXIMUM_SAVED_CYPHER_ITEMS}).`);
  }

  return mergedItems;
};
