import type { Perspective } from '../../../types/perspective';
import type { Style } from '../../../types/style';
import type {
  CreatePerspectivePayload,
  LoadedPerspectives,
  SerializedPerspective,
  StoragePerspective,
  UpdatePerspectiveSerializedPayload,
} from '../api-types/perspective';

export const transformCreatePerspectivePayload = (data: StoragePerspective): CreatePerspectivePayload => {
  const payload: CreatePerspectivePayload = {
    categories: JSON.stringify(data.categories),
    dbId: String(data.dbId),
    dbName: String(data.dbName),
    dbmsId: String(data.dbmsId),
    dbmsVersion: String(data.dbmsVersion),
    hiddenRelationshipTypes: data.hiddenRelationshipTypes ?? [],
    hideUncategorisedData: data.hideUncategorisedData ?? false,
    id: data.id,
    labels: JSON.stringify(data.labels),
    metadata: JSON.stringify(data.metadata),
    name: data.name,
    palette: JSON.stringify(data.palette),
    relationshipTypes: JSON.stringify(data.relationshipTypes),
    sceneActions: JSON.stringify(data.sceneActions),
    searchPhrases: JSON.stringify(data.templates),
    type: data.type,
  };

  if (data.style) {
    payload.style = JSON.stringify(data.style);
  }

  return payload;
};

export const transformGetPerspectiveResult = (data: SerializedPerspective[]): LoadedPerspectives => {
  const result: LoadedPerspectives = {
    perspectives: [],
    styles: [],
  };

  for (const p of data) {
    const perspective = {
      categories: JSON.parse(p.categories) as Perspective['categories'],
      createdAt: new Date(p.createdAt).valueOf(),
      dbId: p.dbId,
      dbName: p.dbName,
      dbmsId: p.dbmsId,
      dbmsVersion: p.dbmsVersion,
      hiddenRelationshipTypes: p.hiddenRelationshipTypes,
      hideUncategorisedData: p.hideUncategorisedData,
      id: p.id,
      labels: JSON.parse(p.labels) as Perspective['labels'],
      lastEditedAt: new Date(p.updatedAt).valueOf(),
      metadata: JSON.parse(p.metadata) as Perspective['metadata'],
      name: p.name,
      palette: JSON.parse(p.palette) as Perspective['palette'],
      parentPerspectiveId: null,
      relationshipTypes: JSON.parse(p.relationshipTypes) as Perspective['relationshipTypes'],
      sceneActions: JSON.parse(p.sceneActions) as Perspective['sceneActions'],
      templates: JSON.parse(p.searchPhrases) as Perspective['templates'],
      type: p.type as Perspective['type'],
    };
    result.perspectives.push(perspective);

    const style = typeof p.style === 'string' ? (JSON.parse(p.style) as Style) : null;
    style && result.styles.push(style);
  }

  return result;
};

export const transformUpdatePerspectivePayload = (
  data: Partial<StoragePerspective>,
): UpdatePerspectiveSerializedPayload => {
  const payload: UpdatePerspectiveSerializedPayload = {};

  if ('categories' in data) {
    payload.categories = JSON.stringify(data.categories);
  }

  if ('hiddenRelationshipTypes' in data) {
    payload.hiddenRelationshipTypes = data.hiddenRelationshipTypes ?? [];
  }

  if ('hideUncategorisedData' in data) {
    payload.hideUncategorisedData = data.hideUncategorisedData ?? false;
  }

  if ('labels' in data) {
    payload.labels = JSON.stringify(data.labels);
  }

  if ('metadata' in data) {
    payload.metadata = JSON.stringify(data.metadata);
  }

  if ('name' in data) {
    payload.name = data.name;
  }

  if ('palette' in data) {
    payload.palette = JSON.stringify(data.palette);
  }

  if ('relationshipTypes' in data) {
    payload.relationshipTypes = JSON.stringify(data.relationshipTypes);
  }

  if ('sceneActions' in data) {
    payload.sceneActions = JSON.stringify(data.sceneActions);
  }

  if ('style' in data) {
    payload.style = JSON.stringify(data.style);
  }

  if ('templates' in data) {
    payload.searchPhrases = JSON.stringify(data.templates);
  }

  return payload;
};
