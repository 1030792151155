import cn from 'classnames';
import type { ComponentPropsWithoutRef } from 'react';

interface DotProps extends ComponentPropsWithoutRef<'span'> {
  color: string;
  selected?: boolean;
  disabled?: boolean;
  className?: string;
}
export const Dot = ({ color, selected, disabled, className, style = {}, ...restProps }: DotProps) => {
  return (
    <span
      className={cn(
        'block h-6 w-6 rounded-[50%]',
        selected === true && 'border-palette-neutral-bg-weak border-2 border-solid shadow-[0_0_10px_5px_#d8d8d8]',
        disabled === true && 'cursor-not-allowed',
        className,
      )}
      style={{
        backgroundColor: color,
        ...style,
      }}
      color={color}
      {...restProps}
    />
  );
};
