import type {
  LinkUserRequest,
  UpdateMarketingOptInRequest,
  UpdateTermsAndConditionsRequest,
  UserDetailsResponse,
} from '../api-types/user';
import {
  transformMarketingOptInRequest,
  transformUpdateTermsAndConditionsRequest,
  transformUserDetailsResponse,
} from '../transformers/user';
import type { User } from '../types/user';
import type { Builder } from './types';

export const userEndpoints = (builder: Builder) => ({
  getUserDetails: builder.query<User, void>({
    query: () => 'user-details',
    transformResponse: (user: UserDetailsResponse) => transformUserDetailsResponse(user),
    providesTags: ['UserDetails'],
  }),
  updateTermsAndConditions: builder.mutation<User, UpdateTermsAndConditionsRequest>({
    query: (request) => ({
      url: 'user-details',
      body: transformUpdateTermsAndConditionsRequest(request),
      method: 'PATCH',
    }),
    invalidatesTags: ['UserDetails'],
  }),
  updateMarketingOptIn: builder.mutation<User, UpdateMarketingOptInRequest>({
    query: (request) => ({
      url: 'user-details',
      body: transformMarketingOptInRequest(request),
      method: 'PATCH',
    }),
    invalidatesTags: ['UserDetails'],
  }),
  emailVerification: builder.mutation<void, void>({
    query: () => ({
      url: '/verification-email-request',
      method: 'POST',
    }),
  }),
  linkLogins: builder.mutation<void, LinkUserRequest>({
    query: ({ currentBearerToken, oldAccountToken }) => ({
      url: 'users/link',
      method: 'POST',
      body: {
        link_to_token: currentBearerToken,
      },
      headers: {
        Authorization: `Bearer ${oldAccountToken}`,
      },
    }),
  }),
  logout: builder.mutation<void, void>({
    query: () => ({
      url: '/logout',
      method: 'POST',
    }),
  }),
});
