import { isNullish } from '@nx/stdlib';

import type { CustomEndpointDetailsResponse, CustomEndpointResponse } from '../console.api.types';
import { CUSTOM_ENDPOINT_STATUS } from '../console.types';
import type { CustomEndpoint, CustomEndpointDetails } from '../console.types';

export const transformCustomEndpointResponse = (response: CustomEndpointResponse): CustomEndpoint => ({
  id: response.id,
  name: response.name,
  projectId: response.namespace_id,
  dbId: response.dbid,
  suffix: response.suffix,
  isTransferable: response.is_transferable,
  isRevertible: response.is_revertible,
  sourceDbId: response.source_dbid,
});

const transformStatus = (status: CUSTOM_ENDPOINT_STATUS, dbId?: string) => {
  if (status === CUSTOM_ENDPOINT_STATUS.ACTIVE && isNullish(dbId)) {
    return CUSTOM_ENDPOINT_STATUS.DISCONNECTED;
  }

  return status;
};

export const transformCustomEndpointDetailsResponse = (
  response: CustomEndpointDetailsResponse,
): CustomEndpointDetails => ({
  ...transformCustomEndpointResponse(response),
  status: transformStatus(response.status, response.dbid),
});
