import { isNil } from 'lodash-es';

import { buildSuggestion } from '../../modules/SearchBar/SearchBar.utils';
import type { NodeSuggestion } from '../../modules/SearchBar/types';

type Truthy = true | 'true' | 'yes' | 1 | '1';
export const isConfigValTruthy = (val: unknown): val is Truthy => [true, 'true', 'yes', 1, '1'].includes(val as Truthy);

export const updatePropertyType = ({ labelPropertyKeys, suggestion }: any) => {
  if (!isNil(suggestion.propertyType)) return suggestion;

  const labelsForSuggestion = suggestion.labels.reduce((acc: string[], label: string) => {
    if (isNil(labelPropertyKeys[label])) return acc;
    return [...acc, ...labelPropertyKeys[label]];
  }, []);

  if (labelsForSuggestion.length === 0) return suggestion;

  const type = labelsForSuggestion.find(
    ({ propertyKey }: { propertyKey: string }) => propertyKey === suggestion.propertyName,
  )?.dataType;

  if (isNil(type)) return suggestion;

  return buildSuggestion<NodeSuggestion>({ ...suggestion, propertyType: type });
};

export const getIsDuplicatedSuggestion = ({
  newSuggestion,
  suggestions,
}: {
  newSuggestion: NodeSuggestion;
  suggestions: NodeSuggestion[];
}) =>
  !isNil(
    suggestions.find(
      (suggestion: NodeSuggestion) =>
        suggestion.propertyConditionValue === newSuggestion.propertyConditionValue &&
        suggestion.categoryName === newSuggestion.categoryName &&
        suggestion.propertyName === newSuggestion.propertyName &&
        suggestion.propertyType === newSuggestion.propertyType,
    ),
  );
