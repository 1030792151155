import type { APP_SCOPE, RouterState } from '@nx/constants';
import { isAppScope } from '@nx/constants';
import { useToolsConfiguration } from '@nx/state';
import { useCallback, useEffect } from 'react';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export const useNxNavigate = () => {
  const toolsConfiguration = useToolsConfiguration();
  const navigate = useNavigate();
  return useCallback(
    (scope: APP_SCOPE, search?: Record<string, string | string[]>, state?: RouterState) => {
      if (isAppScope(scope)) {
        const config = toolsConfiguration[scope];
        if (typeof config === 'undefined') {
          return;
        }
        const { route } = config;
        navigate({ pathname: route, search: createSearchParams(search).toString() }, { state });
      }
    },
    [toolsConfiguration, navigate],
  );
};

export const useNxDeeplinks = (
  cb: (params: Record<string, string | string[]>) => void,
  keys: string[],
  routes?: string[],
) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  useEffect(() => {
    if (keys.some((key) => searchParams.has(key)) && routes !== undefined && routes.includes(location.pathname)) {
      const params = Object.fromEntries(searchParams.entries());
      cb(params);
    }
  }, [searchParams, keys, cb, routes, location.pathname]);
};
