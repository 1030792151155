import { skipToken } from '@reduxjs/toolkit/query';

import { useSelector } from '../context';
import type { Organization, Project } from '../slices/console';
import consoleApi from '../slices/console/console.api';

// TODO: Find better name: “context” is an ambiguous term as it can
// relate to React Context, additionally “app context” is too generic
// and does not reflect what data is handled by slice
// TODO: Refactor the hook: this hook returns the state as is which a
// brittle implementation, hooks (and selectors) should return
// consumer-tailored data and guard from breaking changes that can be
// caused by slice API changes
export function useUnsafeAppContext() {
  return useSelector((state) => state.appContext);
}

export const useActiveOrgSummary = () => {
  const { activeOrgId } = useUnsafeAppContext();
  const userDetailsRes = consoleApi.useGetUserDetailsQuery();
  return consoleApi.useListOrganizationsByUserQuery(userDetailsRes.data?.id ?? skipToken, {
    selectFromResult: (res) => ({ org: res.data?.find((p) => p.id === activeOrgId) }),
  }).org;
};

// Options has to be defined manually otherwise it messes up the hooks' return type
type Options = { refetchOnMountOrArgChange?: boolean; refetchOnFocus?: boolean };

export const useActiveOrgQuery = (options?: Options) => {
  const { activeOrgId } = useUnsafeAppContext();
  return consoleApi.useGetOrganizationQuery(activeOrgId ?? skipToken, options);
};

export const useActiveProjectQuery = (options?: Options) => {
  const { activeProjectId } = useUnsafeAppContext();
  return consoleApi.useGetProjectQuery(activeProjectId ?? skipToken, options);
};

export const useAppContextLoaded = () => {
  const { activeOrgId, activeProjectId } = useUnsafeAppContext();
  const { org } = consoleApi.useGetOrganizationQuery(activeOrgId ?? skipToken, {
    selectFromResult: (res) => ({ org: res.data }),
  });
  const { activeProject } = consoleApi.useGetProjectQuery(activeProjectId ?? skipToken, {
    selectFromResult: (res) => ({ activeProject: res.data }),
  });
  return org && activeProject;
};

export function useActiveOrg(): Organization;
export function useActiveOrg(shouldThrow: true): Organization;
export function useActiveOrg(shouldThrow: false): Organization | undefined;
export function useActiveOrg(shouldThrow = true) {
  const { activeOrgId } = useUnsafeAppContext();
  const { org } = consoleApi.useGetOrganizationQuery(activeOrgId ?? skipToken, {
    selectFromResult: (res) => ({ org: res.data }),
  });
  if (!org && shouldThrow) {
    throw new Error(
      [
        'Active org not in cache. Before calling useActiveOrg ensure',
        'getOrganization is subscribed to and has loaded data at least once',
      ].join(' '),
    );
  }
  return org;
}

export function useActiveProject(): Project;
export function useActiveProject(shouldThrow: true): Project;
export function useActiveProject(shouldThrow: false): Project | undefined;
export function useActiveProject(shouldThrow = true) {
  const { activeProjectId } = useUnsafeAppContext();
  const { activeProject } = consoleApi.useGetProjectQuery(activeProjectId ?? skipToken, {
    selectFromResult: (res) => ({ activeProject: res.data }),
  });
  if (!activeProject && shouldThrow) {
    throw new Error(
      [
        'Active project not in cache. Before calling useActiveProject ensure',
        'getProject is subscribed to and has loaded data at least once',
      ].join(' '),
    );
  }
  return activeProject;
}
