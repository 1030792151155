import { useFlags } from '@nx/launch-darkly-service';

import type * as FeatureFlags from '../slices/feature-flags-slice';
import { useCapability } from './use-capability';
import { useFeatureFlag } from './use-feature-flag';

export const useStorageApi = (key: FeatureFlags.FeatureFlag): boolean => {
  const [storageApiCapabilityEnabled] = useCapability('framework:storage-api');
  const [storageFeatureFlagEnabled] = useFeatureFlag(key);
  const { queryLaunchStorageApi } = useFlags();
  return storageApiCapabilityEnabled && (storageFeatureFlagEnabled || queryLaunchStorageApi);
};
