export enum STATUS {
  IN_USE = 'In use',
  NOT_IN_USE = 'Not in use',
  ERROR = 'Error',
}

export enum ENDPOINT_TARGET_TYPE {
  PROJECT = 'Project',
  INSTANCE = 'Instance',
}

export interface Endpoint {
  target: string | null;
  status: STATUS;
  type: ENDPOINT_TARGET_TYPE;
  lastActive: string | null;
  metricCount: string | null;
  // volume in mulitples of bytes
  dataVolume: string | null;
  // per hour
  requestRate: string;
  id: string;
  lastErrorText: string | null;
}
