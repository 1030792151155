import { createAction } from '@reduxjs/toolkit';

import type { WithCategories, WithElements, WithIds } from './graph.types';

export const addToVisible = createAction<WithIds & WithCategories>('addToVisible');
export const removeFromVisible = createAction<WithIds & WithCategories>('removeFromVisible');
export const setSelected = createAction<WithIds>('setSelected');
export const deselectAll = createAction('deselectAll');
export const updateInventory = createAction<WithElements & WithCategories>('updateInventory');
export const addToInventory = createAction<WithElements>('addToInventory');
export const invertSelection = createAction('invertSelection');
export const addToExpanded = createAction<WithIds>('addToExpanded');
export const clearExpanded = createAction('clearExpanded');
export const purgeInvisibleItems = createAction('purgeInvisibleItems');
