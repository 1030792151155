import { createDynamicMiddleware, createListenerMiddleware } from '@reduxjs/toolkit';

import { setupIfAllowed } from '../services/analytics';
import {
  connectDriver,
  connectionSwitched,
  connectionSwitching,
  disconnectDriver,
  setupInitialDbConnection,
} from '../slices/connections/connections-slice';
import { updateDataSummary } from '../slices/data-summary/data-summary-slice';
import {
  batchStylingUpdate,
  sortingPriorityReordered,
  stylingUpdateForLabel,
  stylingUpdateForRelType,
} from '../slices/graph-styling/graph-styling-slice';
import { updateMetadata } from '../slices/metadata-slice';
import { updateSetting } from '../slices/settings-slice';
import type { AppDispatch, CombinedRootState } from '../store';

interface MiddlewareApiConfig {
  state: CombinedRootState;
  dispatch: AppDispatch;
}

export const eventListenerMiddleware = createDynamicMiddleware();

const addEventMiddleware = eventListenerMiddleware.addMiddleware.withTypes<MiddlewareApiConfig>();

export const StateEvents = {
  metadataUpdate: updateMetadata.fulfilled,
  connectDriver: connectDriver.fulfilled,
  disconnectDriver: disconnectDriver.fulfilled,
  connectionSwitching,
  connectionSwitched,
  setupInitialDbConnectionFulfilled: setupInitialDbConnection.fulfilled,
  setupInitialDbConnectionRejected: setupInitialDbConnection.rejected,
  stylingUpdate: [
    stylingUpdateForLabel,
    stylingUpdateForRelType,
    batchStylingUpdate,
    sortingPriorityReordered,
    updateDataSummary.fulfilled,
  ],
  analyticsAdapterSetup: setupIfAllowed.fulfilled,
  onSettingsUpdate: updateSetting,
};

type Event = keyof typeof StateEvents;
export type StateListenerApi = { getState: () => CombinedRootState };
export type StateEventHandler = (listenerApi: StateListenerApi) => void;

// In theory we could pass along the full arguments from the `effect` callback
// but it's tricky to get the `withTypes` narrowing to work correctly and
// there's no harm in keeping the API simpler / more specialized until we need it
export function addStateEventListener(event: Event, onEvent: StateEventHandler) {
  const listenerMiddlware = createListenerMiddleware();
  const startListening = listenerMiddlware.startListening.withTypes<CombinedRootState, AppDispatch>();

  const actionCreators = Array.isArray(StateEvents[event]) ? StateEvents[event] : [StateEvents[event]];
  actionCreators.forEach((actionCreator) =>
    startListening({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      actionCreator,
      effect: (_action, listenerApi) => {
        onEvent({ getState: () => listenerApi.getState() });
      },
    }),
  );

  addEventMiddleware(listenerMiddlware.middleware);

  return listenerMiddlware.clearListeners;
}
