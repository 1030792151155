export interface FormatChange {
  cmdBefore: string;
  cmdAfter: string;
}

export function levenshteinDistance({ cmdBefore, cmdAfter }: FormatChange): number {
  const matrix = Array.from({ length: cmdBefore.length + 1 }, () =>
    Array.from({ length: cmdAfter.length + 1 }, () => 0),
  );

  for (let i = 0; i <= cmdBefore.length; i++) {
    matrix[i]![0] = i;
  }
  for (let j = 0; j <= cmdAfter.length; j++) {
    matrix[0]![j] = j;
  }

  for (let i = 1; i <= cmdBefore.length; i++) {
    for (let j = 1; j <= cmdAfter.length; j++) {
      const cost = cmdBefore[i - 1] === cmdAfter[j - 1] ? 0 : 1;
      // Cost of: Deletion, insertion and substitution
      matrix[i]![j] = Math.min(matrix[i - 1]![j]! + 1, matrix[i]![j - 1]! + 1, matrix[i - 1]![j - 1]! + cost);
    }
  }

  return matrix[cmdBefore.length]![cmdAfter.length] ?? -1;
}

export function getSpaceCount({ cmdBefore, cmdAfter }: FormatChange) {
  return {
    before: cmdBefore.match(/ /g)?.length ?? 0,
    after: cmdAfter.match(/ /g)?.length ?? 0,
  };
}

export function getNewlineCount({ cmdBefore, cmdAfter }: FormatChange) {
  return {
    before: cmdBefore.match(/\n/g)?.length ?? 0,
    after: cmdAfter.match(/\n/g)?.length ?? 0,
  };
}

function countIndentations(str: string): number {
  const lines = str.split('\n');
  return lines.reduce((total, line) => {
    // Match either tabs or double spaces at the start of the line
    const indentMatch = line.match(/^(\t|( {2}))+/)?.[0];

    if (indentMatch === undefined) {
      return total;
    }

    // Count each tab or double-space pair as one indentation
    const indentCount = indentMatch.split('').reduce((count, char) => {
      return count + (char === '\t' ? 1 : 0.5);
    }, 0);

    return total + indentCount;
  }, 0);
}

export function getIndentationCount({ cmdBefore, cmdAfter }: FormatChange) {
  return {
    before: countIndentations(cmdBefore),
    after: countIndentations(cmdAfter),
  };
}
