import { Switch, TextLink, Tooltip, Typography } from '@neo4j-ndl/react';
import { TIER } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';

import { MINIMUM_GDS_PLUGIN_VECTOR_MEMORY } from '../constants';
import { gbStringToInt } from '../entities/helpers';
import type { InstanceFormData } from '../entities/model';

type InstanceGdsPluginFormData = Pick<InstanceFormData, 'size' | 'version' | 'gdsPlugin' | 'tier'>;

const gdsPluginDisabledReason = (data: InstanceGdsPluginFormData) => {
  if (isNullish(data.size)) {
    return 'You must select a size before enabling this feature';
  }

  if (![TIER.PROFESSIONAL].includes(data.tier)) {
    return 'GDS plugin is not available for this tier';
  }

  if (data.version !== '5') {
    return 'GDS plugin is not available for the selected version';
  }

  if (gbStringToInt(data.size.memory) < MINIMUM_GDS_PLUGIN_VECTOR_MEMORY) {
    return '4GB memory or greater is required for the GDS plugin configuration';
  }

  return null;
};

export const getValidGdsPluginValue = (data: InstanceGdsPluginFormData) => {
  if (!gdsPluginDisabledReason(data)) {
    return data.gdsPlugin;
  }

  return false;
};

type GdsPluginCreateProps = {
  data: InstanceGdsPluginFormData;
  onChange: (gdsPlugin: boolean) => void;
};

export const GdsPluginField = ({ data, onChange }: GdsPluginCreateProps) => {
  const handleGdsPluginChange = (value: boolean) => {
    onChange(value);
  };

  const disabledReason = gdsPluginDisabledReason(data);
  const isDisabled = isNotNullish(disabledReason);
  const label = 'Toggle to install GDS plugin on instance';

  return (
    <div className="flex flex-row items-center justify-between py-2">
      <div className="flex flex-col gap-2">
        <Typography variant="subheading-medium">Graph Analytics</Typography>
        <Typography variant="body-medium" as="div" className="text-neutral-text-weak">
          Enable Graph Analytics libraries on this instance. See{' '}
          <TextLink href="https://neo4j.com/docs/aura/graph-analytics/" isExternalLink>
            documentation
          </TextLink>{' '}
          for details.
        </Typography>
      </div>
      <div>
        <Tooltip type="simple" isPortaled={false} isDisabled={!isDisabled}>
          <Tooltip.Trigger>
            <Switch
              htmlAttributes={{
                'aria-label': label,
                'data-testid': 'gds-plugin-switch',
              }}
              ariaLabel={label}
              isChecked={data.gdsPlugin}
              onChange={(e) => handleGdsPluginChange(e.target.checked)}
              isDisabled={isDisabled}
            />
          </Tooltip.Trigger>
          <Tooltip.Content>{disabledReason}</Tooltip.Content>
        </Tooltip>
      </div>
    </div>
  );
};

type InstanceVectorOptimizedFormData = Pick<InstanceFormData, 'size' | 'version' | 'vectorOptimized' | 'tier'>;

const vectorOptimizedDisabledReason = (data: InstanceVectorOptimizedFormData) => {
  if (isNullish(data.size)) {
    return 'You must select a size before enabling this feature';
  }

  if (![TIER.PROFESSIONAL, TIER.MTE, TIER.ENTERPRISE].includes(data.tier)) {
    return 'Vector index configuration is not available for this tier';
  }

  if (data.version !== '5') {
    return 'Vector index configuration is not available for the selected version';
  }

  if (gbStringToInt(data.size.memory) < MINIMUM_GDS_PLUGIN_VECTOR_MEMORY) {
    return '4GB memory or greater is required for the vector index configuration';
  }

  return null;
};

export const getValidVectorOptimizedValue = (data: InstanceVectorOptimizedFormData) => {
  if (!vectorOptimizedDisabledReason(data)) {
    return data.vectorOptimized;
  }

  return false;
};

type VectorOptimizedCreateProps = {
  data: InstanceVectorOptimizedFormData;
  onChange: (vectorOptimized: boolean) => void;
};

export const VectorOptimizedField = ({ data, onChange }: VectorOptimizedCreateProps) => {
  const disabledReason = vectorOptimizedDisabledReason(data);
  const isDisabled = isNotNullish(disabledReason);
  const label = 'Toggle on Allocate vector index memory';

  return (
    <div className="flex flex-row items-center justify-between py-2">
      <div className="flex flex-col gap-2">
        <Typography variant="subheading-medium">Vector-optimized configuration</Typography>
        <Typography variant="body-medium" as="div" className="text-neutral-text-weak">
          Optimize Neo4j for GraphRAG, GenAI, and semantic search applications that index and search vector embeddings.
          See{' '}
          <TextLink
            href="https://neo4j.com/docs/aura/managing-instances/instance-details/#aura-vector-optimization"
            isExternalLink
          >
            documentation
          </TextLink>{' '}
          for details.
        </Typography>
      </div>
      <div>
        <Tooltip type="simple" isPortaled={false} isDisabled={!isDisabled}>
          <Tooltip.Trigger>
            <Switch
              htmlAttributes={{
                'aria-label': label,
                'data-testid': 'vector-optimized-switch',
              }}
              ariaLabel={label}
              isChecked={data.vectorOptimized}
              onChange={(e) => onChange(e.target.checked)}
              isDisabled={isDisabled}
            />
          </Tooltip.Trigger>
          <Tooltip.Content>{disabledReason}</Tooltip.Content>
        </Tooltip>
      </div>
    </div>
  );
};
