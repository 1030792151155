import type { SSOProviderOriginal } from '@nx/constants';
import * as Neo4j from 'neo4j-driver';
import type * as DriverCore from 'neo4j-driver-core';
import type { WebStorageStateStore } from 'oidc-client-ts';

import { SsoConnectionError } from './errors';
import { getSeamlessConnectionToken } from './seamless-connection';
import { getSsoCredentials } from './sso-connection';

/**
 * Creates a function which can be used to refresh the token for seamless connections.
 *
 * Used by the main thread and a worker thread. In the worker thread, redux state can't be
 * read directly, so values for the tokenApiUrl, access token and user id token are passed in.
 *
 * @param tokenApiUrl
 * @param connectionUrl
 * @param getAccessToken
 * @param getUserIdToken
 * @param authLog
 * @returns
 */
export const createTokenProviderSeamless =
  (
    tokenApiUrl: string,
    connectionUrl: string,
    getAccessToken: () => Promise<string | undefined>,
    getUserIdToken: () => Promise<string | undefined>,
    authLog: (msg: string, type?: 'log' | 'warn' | 'error') => void,
  ) =>
  async (): Promise<DriverCore.AuthTokenAndExpiration> => {
    authLog(`Refreshing seamless token for instance "${connectionUrl}"`);

    const seamlessToken = await getSeamlessConnectionToken(
      tokenApiUrl,
      connectionUrl,
      getAccessToken,
      getUserIdToken,
      authLog,
    );

    return {
      expiration: new Date(Number(seamlessToken.expiration) * 1000),
      token: Neo4j.auth.bearer(seamlessToken.token),
    };
  };

/**
 * Creates a function which can be used to refresh the token for SSO connections.
 *
 * Used by the main thread and a worker thread. In the worker thread, local & session storage
 * can't be read directly, so a provider is passed in and in memory user & state stores (which
 * are initialised with the web storage contents). The worker thread also can't initialise an
 * SSO connection, so it passes in null values for the redirect strings.
 *
 * @param provider
 * @param userAndStateStores
 * @param getRedirectUri
 * @param authLog
 * @returns
 */
export const createTokenProviderSso =
  (
    provider: SSOProviderOriginal | null,
    userAndStateStores: { userStore: WebStorageStateStore; stateStore: WebStorageStateStore },
    getRedirectUri: () => string | null,
    authLog: (msg: string, type?: 'log' | 'warn' | 'error') => void,
  ) =>
  async (): Promise<DriverCore.AuthTokenAndExpiration> => {
    authLog('Refreshing token');

    const credentials = await getSsoCredentials(provider, userAndStateStores, getRedirectUri, authLog);

    if (credentials === null) {
      throw new SsoConnectionError('NoProviders');
    }

    return {
      expiration: new Date(Number(credentials.expiration) * 1000),
      token: Neo4j.auth.bearer(credentials.password),
    };
  };
