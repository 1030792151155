import { Typography } from '@neo4j-ndl/react';
import { isNullish } from '@nx/stdlib';
import { ClipboardCopier } from '@nx/ui';

interface Props {
  label: string;
  value?: string;
  allowCopyText?: boolean;
}

export const PropertyDisplay = ({ label, value, allowCopyText = false }: Props) => {
  return (
    <div className="flex flex-col gap-1">
      <Typography variant="body-medium" className="text-neutral-text-weak">
        {label}
      </Typography>
      <Typography variant="body-medium" className="text-neutral-text-default">
        {value ?? '-'}
        {allowCopyText && !isNullish(value) && (
          <ClipboardCopier className="!h-auto align-text-top" textToCopy={value} ariaLabel="Copy to clipboard" />
        )}
      </Typography>
    </div>
  );
};
