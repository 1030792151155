import type { AnalyticsAdapter, AnalyticsTrackPayload } from './analytics-adapter';

/**
 * Mock analytics adapter that outputs all events and actions to console
 */
export class LoggerAnalyticsAdapter<Event extends string> implements AnalyticsAdapter<Event> {
  init() {
    return Promise.resolve();
  }

  identify({ userId, orgId }: { userId: string; orgId: string | null }) {
    // eslint-disable-next-line no-console
    console.log('[Analytics]', `Identity changed to userId: "${userId}", orgId: "${orgId}`);
    return Promise.resolve();
  }

  trackNavigation() {
    // eslint-disable-next-line no-console
    console.log('[Analytics]', 'Navigation tracked');
    return Promise.resolve();
  }

  trackEvent(payload: AnalyticsTrackPayload<Event>) {
    // eslint-disable-next-line no-console
    console.log('[Analytics]', `Event "${payload.event}" tracked`, payload.properties);
    return Promise.resolve();
  }
}
