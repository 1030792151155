import type * as ImportShared from '@nx/import-shared';
import { isNullish } from '@nx/stdlib';
import { difference } from 'lodash-es';
import papaparse from 'papaparse';
import type { ParseError, ParseResult, Parser } from 'papaparse';

import { isValidJSDateTime, isValidLuxonDateTime } from '../utils/parsing';
import type { DataFileMetadata } from './types';

const STRICT_PREVIEW = false;
const STRICT_LOAD = false;
const MAX_PREVIEW_ROWS = 100;
// 0021 or 21 or +21 or -21
const INT_REG = /^[+-]?\d+$/;
// 21.08 or 0021.08 or 21. or .08 or +21.08 or -21.08
const FLOAT_REG = /^[+-]?(\d+\.\d*|\d*\.\d+)$/;
const CHUNK_SIZE = 10000;

type ParseResultField = {
  name: string;
  type: ImportShared.SupportedCsvPropertyDataType;
  sample: string[];
  include: boolean;
};

const isBoolean = (data: string[]) =>
  data.every((d) => {
    const trimmedLower = d.trim().toLowerCase();
    return trimmedLower === '1' || trimmedLower === '0' || trimmedLower === 'true' || trimmedLower === 'false';
  });

const isInteger = (data: string[]) => data.every((d) => INT_REG.test(d));

const isFloat = (data: string[]) => data.every((d) => FLOAT_REG.test(d) || INT_REG.test(d));

const isDateTime = (data: string[]) => data.every((d) => isValidLuxonDateTime(d) && isValidJSDateTime(d));

export const guessFieldType = (
  record: Record<string, string>[],
  field: string,
): ImportShared.SupportedCsvPropertyDataType => {
  const data = record.map((r) => r[field]?.trim()).filter((d): d is string => !isNullish(d) && d !== '');
  if (data.length === 0) {
    return 'string';
  }
  if (isBoolean(data)) {
    return 'boolean';
  } else if (isInteger(data)) {
    return 'integer';
  } else if (isFloat(data)) {
    return 'float';
  } else if (isDateTime(data)) {
    return 'datetime';
  }
  return 'string';
};

const getFieldsFromResults = (data: Record<string, string>[], fields?: string[]): ParseResultField[] => {
  return (fields ?? []).map(
    (field: string): ParseResultField => ({
      name: field,
      type: guessFieldType(data, field),
      sample: data.map((d) => d[field] ?? ''),
      include: true,
    }),
  );
};

const getStrictPreviewError = (results: ParseResult<Record<string, string>>): ParseError | null => {
  const error = results.errors.length > 0 ? results.errors[0] : null;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return STRICT_PREVIEW && !isNullish(error) ? error : null;
};

const getStrictLoadError = (results: ParseResult<Record<string, string>>): ParseError | null => {
  const error = results.errors.length > 0 ? results.errors[0] : null;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return STRICT_LOAD && !isNullish(error) ? error : null;
};

export const previewCsv = (
  file: File | NodeJS.ReadableStream | string,
  maxPreviewRows = MAX_PREVIEW_ROWS,
): Promise<{ resultFields: ParseResultField[]; bytesScanned: number }> => {
  let chunkSum = 0;
  let fields: string[] | undefined;
  let data: Record<string, string>[] = [];
  return new Promise((resolve, reject) => {
    let cursor = 0;
    papaparse.parse(file, {
      header: true,
      // In bytes. We’ll want an upper limit on chunk size still so we don’t keep reading a rogue file and
      // crash the app.
      chunkSize: CHUNK_SIZE,
      skipEmptyLines: true,
      transformHeader(h: string): string {
        return h.trim();
      },
      // Warning: Duplicate field names will overwrite values in previous fields having the same name.
      chunk(results: ParseResult<Record<string, string>>, parser: Parser): void {
        cursor = results.meta.cursor;
        fields = results.meta.fields;
        chunkSum += 1;
        // Abort parsing after 150 iteration (~1500kb) to avoid potential OOM issue.
        if (chunkSum > 150) {
          parser.abort();
          // Quit silently.
          return;
        }
        const strictError = getStrictPreviewError(results);
        if (strictError) {
          reject(strictError);
          parser.abort();
          return;
        }
        data = data.concat(results.data);
        // It's not guaranteed one chunk can read the whole row as some rows may be too large.
        if (data.length < 1) {
          // Have multiple chunks to retrive at least one row before resolving.
          parser.resume();
        }
      },
      preview: maxPreviewRows,
      complete(results: ParseResult<Record<string, string>> | undefined): void {
        if (data.length > 0) {
          const resultFields = getFieldsFromResults(data, fields);
          resolve({ resultFields, bytesScanned: cursor + CHUNK_SIZE });
        }
        if (!results) {
          return;
        }
        const strictError = getStrictPreviewError(results);
        if (strictError) {
          reject(strictError);
        }
      },
      error(error: unknown) {
        reject(error);
      },
    });
    if (typeof file !== 'string' && 'resume' in file) {
      // Force jszip stream to start if present.
      file.resume();
    }
  });
};

export const parseCsv = (
  file: File | NodeJS.ReadableStream | string,
  chunkSize: number,
  chunkCallback: (parseResult: ParseResult<Record<string, string>>, parser: Parser) => void,
  completeCallback: (parseResult: ParseResult<Record<string, string>>) => void,
  errorCallback: (error: ParseError | Error) => void,
): void => {
  papaparse.parse<Record<string, string>>(file, {
    header: true,
    chunkSize,
    skipEmptyLines: true,
    transformHeader(h) {
      return h.trim();
    },
    chunk(results, parser) {
      const strictError = getStrictLoadError(results);
      if (strictError) {
        errorCallback(strictError);
        parser.abort();
        return;
      }
      chunkCallback(results, parser);
    },
    complete(results: papaparse.ParseResult<Record<string, string>>) {
      // if results.meta.aborted, results are still valuable to be returned.
      const strictError = getStrictLoadError(results);
      if (strictError) {
        errorCallback(strictError);
        return;
      }
      completeCallback(results);
    },
    error(error: papaparse.ParseError | Error) {
      errorCallback(error);
    },
  });
  if (typeof file !== 'string' && 'resume' in file) {
    // Force jszip stream to start if present.
    file.resume();
  }
};

const getMissingFilenamesByFileNames = (
  fileNames: string[],
  files: Record<string, File>,
  zipDataFiles: Record<string, DataFileMetadata>,
) => fileNames.filter((fileName) => isNullish(files[fileName]) && isNullish(zipDataFiles[fileName]));

const isFileReadable = async (file: File) => {
  const result = await new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => resolve(true);
    fileReader.onerror = (ev) => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      reject(new Error((ev.currentTarget as FileReader).error?.message));
    };
    // Don't read the full file into memory before processing it. This inefficiency can be solved by streaming the file
    // (reading chunks of a small size), so we only need to hold a part of the file in memory.
    const fileSlice = file.slice(0, 1024);
    fileReader.readAsArrayBuffer(fileSlice);
  });
  return result;
};

const getUnreadableFilenamesByFileNames = async (fileNames: string[], files: Record<string, File>) => {
  const missingFileNames = new Set<string>();
  const fileReaderPromises: (() => Promise<void>)[] = [];
  for (const filename of fileNames) {
    fileReaderPromises.push(async () => {
      const file = files[filename];
      if (!isNullish(file)) {
        await isFileReadable(file);
      }
    });
  }
  // Rather than putting await inside of loop and executing reader sequentially,
  // executing all independent async functions in parallel to enhance the performance.
  const results = await Promise.allSettled(fileReaderPromises.map((f) => f()));
  results.forEach((result, i) => {
    const file = fileNames[i];
    if (result.status === 'rejected' && !isNullish(file)) {
      missingFileNames.add(file);
    }
  });

  return Array.from(missingFileNames);
};

export const checkFileAccessibilityByFileNames = async (
  fileNames: string[],
  files: Record<string, File>,
  zipDataFiles: Record<string, DataFileMetadata>,
) => {
  const missingFileNames = getMissingFilenamesByFileNames(fileNames, files, zipDataFiles);
  // Filter missing filenames to skip readable validation.
  const unreadableFilenames = await getUnreadableFilenamesByFileNames(
    // Check if any non-missing, non-zip files are unreadable. We aren't checking zip csv files as it's not a
    // common usecase for those to be edited after upload.
    difference(fileNames, missingFileNames).filter((fileName) => !Object.keys(zipDataFiles).includes(fileName)),
    files,
  );
  return [...missingFileNames, ...unreadableFilenames];
};
