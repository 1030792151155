import { isNotNullish } from '@nx/stdlib';

import type {
  CreateEncryptionKeyRequest,
  EncryptionKeyResponse,
  ProjectEncryptionKeyParams,
} from '../console.api.types';
import type { EncryptionKey } from '../console.types';
import { transformCreateEncryptionKeyRequest, transformEncryptionKeyResponse } from '../transformers/encryption-key';
import { type Builder, LIST_ID } from './types';

export const encryptionKeysEndpoints = (builder: Builder) => ({
  getEncryptionKey: builder.query<EncryptionKey, ProjectEncryptionKeyParams>({
    query: (params) => ({ url: `tenants/${params.projectId}/encryption-keys/${params.encryptionKeyRef}` }),
    transformResponse: (encryptionKey: EncryptionKeyResponse) => transformEncryptionKeyResponse(encryptionKey),
    providesTags: (result, error, { projectId, encryptionKeyRef }) => [
      { type: 'EncryptionKey' as const, id: encryptionKeyRef, projectId },
    ],
  }),
  listEncryptionKeys: builder.query<EncryptionKey[], string>({
    query: (projectId) => `tenants/${projectId}/encryption-keys`,
    transformResponse: (encryptionKeys: EncryptionKeyResponse[]) =>
      encryptionKeys.map((key) => transformEncryptionKeyResponse(key)),
    providesTags: (result, error, projectId) => {
      if (isNotNullish(result)) {
        return [
          ...result.map(({ encryptionKeyRef }) => ({
            type: 'EncryptionKey' as const,
            id: encryptionKeyRef,
            projectId,
          })),
          { type: 'EncryptionKey', id: LIST_ID, projectId },
        ];
      }
      return [{ type: 'EncryptionKey', id: LIST_ID, projectId }];
    },
  }),
  createEncryptionKey: builder.mutation<EncryptionKey, CreateEncryptionKeyRequest>({
    query: (request) => ({
      url: `tenants/${request.projectId}/encryption-keys`,
      body: transformCreateEncryptionKeyRequest(request),
      method: 'POST',
    }),
    transformResponse: (encryptionKey: EncryptionKeyResponse) => transformEncryptionKeyResponse(encryptionKey),
    invalidatesTags: (result, error, projectId) => [{ type: 'EncryptionKey', id: LIST_ID, projectId }],
  }),
  deleteEncryptionKey: builder.mutation<void, ProjectEncryptionKeyParams>({
    query: (params) => ({
      url: `tenants/${params.projectId}/encryption-keys/${params.encryptionKeyRef}`,
      method: 'DELETE',
    }),
    invalidatesTags: (result, error, { projectId, encryptionKeyRef }) => [
      { type: 'EncryptionKey', id: encryptionKeyRef, projectId },
    ],
  }),
});
