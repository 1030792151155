import type { EntityState } from '@reduxjs/toolkit';
import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../../store';
import type { Relate } from './desktop';
import type { ProgressEventsEntity } from './endpoints/events';
import type { OperationName } from './relate.types';

export const selectDesktopContext: (state: RootState) => boolean = createSelector(
  (state: RootState) => state.desktop,
  (desktop) => desktop.desktopContext,
);

export const selectRelateApiEndpoint: (state: RootState) => Relate = createSelector(
  (state: RootState) => state.desktop,
  (desktop) => desktop.relate,
);

export const selectProgressEvents = (
  state: EntityState<ProgressEventsEntity, string> | undefined,
  operation: OperationName,
  operationId: string[],
) => {
  return state?.entities[operation + operationId.join('|')]?.events;
};
