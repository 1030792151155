import { Checkbox, LoadingSpinner, Select, Typography } from '@neo4j-ndl/react';
import { Functions } from '@nx/stdlib';
import classNames from 'classnames';
import { isEqual } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import type { ActionMeta, GroupBase, MultiValue, OptionProps } from 'react-select';

import type { DropdownOption } from '../../../../metrics/shared/types';
import { capitalizeFirstLowerRest, simplePlural } from '../../../../shared/ui-helpers';
import classes from './filter-dropdown.module.css';

type FilterDropdownProps = {
  field: string;
  allValues: string[];
  selectedValues: string[];
  onChange: (newValues: string[]) => void;
  errorMessage?: string;
  loading?: boolean;
  valueFormatter: (v: string) => string;
};

const ALL_VALUE = '*';
const ARRAY_WITH_ALL_VALUE = [ALL_VALUE];
const EMPTY_ARRAY: string[] = [];
const MAX_DISPLAYED_SELECTIONS = 4;

const formatSelected = (name: string, count: number) =>
  `${simplePlural(name.toLocaleLowerCase() || '', count, true)} selected`;

const Option = ({ innerProps, data, isSelected }: OptionProps<DropdownOption, true, GroupBase<DropdownOption>>) => {
  return (
    <div {...innerProps} className={classNames(classes['dropdown-option'], innerProps.className)}>
      <Checkbox key={data.value} ariaLabel={data.label} isChecked={isSelected} onChange={Functions.noop} />
      <Typography variant="body-medium" className="text-palette-neutral-text-default">
        {data.label}
      </Typography>
    </div>
  );
};

export const createDropdownOptions = (
  options: FilterDropdownProps['allValues'],
  valueFormatter: FilterDropdownProps['valueFormatter'],
): DropdownOption[] =>
  options.map((v) => ({
    label: v === ALL_VALUE ? 'All' : valueFormatter(v),
    value: v,
  }));

export const FilterDropdown = ({
  allValues,
  selectedValues,
  valueFormatter,
  onChange,
  field,
  errorMessage,
  loading,
}: FilterDropdownProps) => {
  const selectedValuesResolved = selectedValues.length === 0 ? ARRAY_WITH_ALL_VALUE : selectedValues;
  const selectedCount = selectedValuesResolved.length;

  const optionsWithAll = useMemo<DropdownOption[]>(
    () => [{ label: 'All', value: ALL_VALUE }, ...createDropdownOptions(allValues, valueFormatter)],
    [allValues, valueFormatter],
  );

  const onChangeHandler = useCallback(
    (latestValues: MultiValue<DropdownOption>, actionMeta: ActionMeta<DropdownOption>) => {
      if (actionMeta.action === 'select-option') {
        const selectedOption = actionMeta.option;
        const optionIsAll = selectedOption?.value === ALL_VALUE;
        const allIsAlreadySelected = latestValues.some((o) => o.value === ALL_VALUE);

        if (!selectedOption) {
          return;
        }

        if (optionIsAll) {
          onChange(EMPTY_ARRAY);
          return;
        }

        if (allIsAlreadySelected) {
          onChange([selectedOption.value]);
          return;
        }
      }

      onChange(latestValues.map((v) => v.value));
    },
    [onChange],
  );

  return (
    <Select
      size="medium"
      errorText={errorMessage}
      type="select"
      selectProps={{
        isDisabled: loading,
        options: optionsWithAll,
        value: createDropdownOptions(selectedValuesResolved, valueFormatter),
        onChange: onChangeHandler,
        isMulti: true,
        menuPlacement: 'auto',
        closeMenuOnSelect: false,
        hideSelectedOptions: false,
        placeholder: selectedCount <= MAX_DISPLAYED_SELECTIONS ? undefined : formatSelected(field, selectedCount),
        controlShouldRenderValue: selectedCount <= MAX_DISPLAYED_SELECTIONS,
        components: {
          Option,
        },
        onMenuClose: () => {
          // If empty or selected all one by one, default to "All"
          if (
            selectedValuesResolved.length === 0 ||
            isEqual([...selectedValuesResolved].sort(), [...allValues].sort())
          ) {
            onChange(EMPTY_ARRAY);
          }
        },
      }}
      label={
        <div className="flex items-center gap-2">
          {`${capitalizeFirstLowerRest(field)}`}
          {(loading ?? false) && <LoadingSpinner />}
        </div>
      }
      // silences a bunch of console warnings
      aria-label={`${field} dropdown selector`}
      htmlAttributes={{
        'aria-label': `${field} dropdown selector`,
        id: `select-log-${field}`,
      }}
    />
  );
};
