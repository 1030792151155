import { isNotNullish } from '@nx/stdlib';

import type { DatabaseResponse, DatabaseSummaryResponse } from '../console.api.types';
import type { AuraDatabase, AuraDatabaseSummary } from '../console.types';
import { transformDatabaseResponse, transformDatabaseSummaryResponse } from '../transformers/databases';
import { type Builder, LIST_ID } from './types';

export const databaseEndpoints = (builder: Builder) => ({
  listDatabases: builder.query<AuraDatabaseSummary[], string | undefined>({
    query: (instanceId) => `aura-instances/${instanceId}/aura-databases`,
    transformResponse: (response: DatabaseSummaryResponse) => {
      return transformDatabaseSummaryResponse(response);
    },
    providesTags: (result) => {
      if (isNotNullish(result)) {
        return [...result.map(({ id }) => ({ type: 'Database' as const, id })), { type: 'Database', id: LIST_ID }];
      }
      return [{ type: 'Database', id: LIST_ID }];
    },
  }),
  getDatabase: builder.query<AuraDatabase, { instanceId: string; id: string }>({
    query: ({ instanceId, id }) => `aura-instances/${instanceId}/aura-databases/${id}`,
    transformResponse: (response: DatabaseResponse) => {
      return transformDatabaseResponse(response);
    },
    providesTags: (result, error, { id }) => [{ type: 'Database', id }],
  }),
});
