import { Typography } from '@neo4j-ndl/react';
import { isNonEmptyString } from '@nx/stdlib';
import cx from 'classnames';

interface PillProps {
  value: string;
  enabled?: boolean;
  color?: string;
  onClick?: () => void;
  dashed?: boolean;
  areaAlpha?: number;
}

export const Pill = ({ enabled = true, value, color, onClick, dashed = false, areaAlpha, ...props }: PillProps) => {
  const height = typeof areaAlpha === 'number' ? '16px' : '3px';
  const backgroundColor = typeof areaAlpha === 'number' ? `${color}${areaAlpha}` : undefined;
  return (
    <div
      className={cx('flex select-none flex-row items-center', {
        'cursor-pointer': onClick,
        'line-through': !enabled,
      })}
      onClick={onClick}
      {...props}
    >
      {/* Empty span is the colored pill */}
      <span
        className={cx('mr-1 flex-none border-t-[3px] align-middle', {
          'border-dashed': dashed,
          'border-palette-neutral-text-weak': enabled && !isNonEmptyString(color),
          'border-palette-neutral-text-weakest': !enabled && !isNonEmptyString(color),
        })}
        style={{
          height,
          width: '16px',
          backgroundColor,
          borderColor: color,
        }}
      />
      <Typography
        variant="body-small"
        className={cx({
          'text-palette-neutral-text-weakest': !enabled,
          'text-palette-neutral-text-weak': enabled,
        })}
      >
        {value}
      </Typography>
    </div>
  );
};
