import { store } from '../../store';
import * as GuidePlaylists from './guide-playlists-slice';

export function changePlaylist(...args: Parameters<typeof GuidePlaylists.changePlaylist>) {
  return store.dispatch(GuidePlaylists.changePlaylist(...args));
}

export function changePlaylistsBaseUrl(...args: Parameters<typeof GuidePlaylists.changeBaseUrl>) {
  return store.dispatch(GuidePlaylists.changeBaseUrl(...args));
}

export function resetPlaylistsBaseUrl(...args: Parameters<typeof GuidePlaylists.resetBaseUrl>) {
  return store.dispatch(GuidePlaylists.resetBaseUrl(...args));
}
