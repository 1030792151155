import { Button, Dialog, IconButton, Popover, Tooltip, Typography } from '@neo4j-ndl/react';
import { ArrowUpTrayIconOutline } from '@neo4j-ndl/react/icons';
import type { CaptionOption, GraphStyling } from '@nx/state';
import {
  DEFAULT_NVL_NODE_RADIUS,
  DEFAULT_REL_WIDTH,
  parseBrowserGrass,
  prettyPrintAsGrass,
  useBatchUpdateStyling,
  useFeatureFlag,
  useUpdateStylingForGraphLabel,
  useUpdateStylingForRelType,
} from '@nx/state';
import { calculateDefaultNodeColors, mostReadable } from '@nx/word-color';
import { rgbToHex } from '@nx/word-color/src/utils';
import cn from 'classnames';
import type { ChangeEvent } from 'react';
import { useRef, useState } from 'react';
import type { RGBColor } from 'react-color';

import ColorPicker from '../color-picker/color-picker';
import { getDefaultCaptionKey } from '../nvl-utils/map-to-nvl';
import { GraphChip } from './graph-chip';

const TEXT_COLOR_LIGHT = '#fff';
const TEXT_COLOR_DARK = '#151E29';

export type StylePicker =
  | {
      type: 'LABEL';
      stylingId: string;
      captionOptions: CaptionOption[];
    }
  | {
      type: 'RELATIONSHIP';
      stylingId: string;
      captionOptions: CaptionOption[];
    };

const COLORS: { color: string; border: string; textColor: string }[] = [
  { color: '#FFE081', border: '#cfb66d' },
  { color: '#C990C0', border: '#966e90' },
  { color: '#F79767', border: '#b56e4a' },
  { color: '#57C7E3', border: '#398091' },
  { color: '#F16667', border: '#a64949' },
  { color: '#D9C8AE', border: '#9c8e7b' },
  { color: '#8DCC93', border: '#649169' },
  { color: '#ECB5C9', border: '#997683' },
  { color: '#4C8EDA', border: '#2f5887' },
  { color: '#FFC454', border: '#a6823d' },
  { color: '#DA7194', border: '#87485d' },
  { color: '#569480', border: '#2c4d42' },
  { color: '#848484', border: '#424242' },
  { color: '#D9D9D9', border: '#9e9e9e' },
].map((entry) => ({
  ...entry,
  textColor: mostReadable(entry.color, [TEXT_COLOR_LIGHT, TEXT_COLOR_DARK], true),
}));

const DEFAULT_REL_COLOR = '#D9D9D9';

const SIZES = [
  { display: 9, radius: 18 },
  { display: 12, radius: 25 },
  { display: 15, radius: 30 },
  { display: 18, radius: 36 },
  { display: 21, radius: 42 },
  { display: 24, radius: 48 },
  { display: 27, radius: 54 },
  { display: 30, radius: 60 },
  { display: 33, radius: 66 },
  { display: 36, radius: 72 },
  { display: 39, radius: 78 },
];

const LINE_WIDTHS: { display: number; width: number }[] = [
  { display: 5, width: 1 },
  { display: 7, width: 3 },
  { display: 9, width: 5 },
  { display: 11, width: 7 },
  { display: 15, width: 10 },
  { display: 20, width: 12 },
  { display: 25, width: 20 },
];

type StylingPickerProps = {
  stylePicker: StylePicker;
  onClickOutside: () => void;
  anchorEl: HTMLElement;
  graphStyling: GraphStyling;
};

const NodeStylingPicker = ({
  stylingId,
  captionOptions,
  graphStyling,
}: {
  stylingId: string;
  captionOptions: CaptionOption[];
  graphStyling: GraphStyling;
}) => {
  const updateStylingForGraphLabel = useUpdateStylingForGraphLabel({ debounce: 100 });

  const currentNodeStyling = graphStyling.node[stylingId] ?? {};
  const [customColorPickerOpen, setcustomColorPickerOpen] = useState(false);

  const selectedSize = currentNodeStyling.size ?? DEFAULT_NVL_NODE_RADIUS;
  const defaultColor = calculateDefaultNodeColors(stylingId).backgroundColor;
  const defaultCaption = getDefaultCaptionKey(captionOptions);
  const currentBackgroundColor: string = currentNodeStyling.color ?? defaultColor;

  const handleColorChange = (color: { hex: string }) => {
    updateStylingForGraphLabel(stylingId, { color: color.hex });
  };

  const handleInputChange = (rgb: RGBColor) => (value: Partial<{ HEX: string; R: string; G: string; B: string }>) => {
    if (value.HEX !== undefined && value.HEX !== '') {
      updateStylingForGraphLabel(stylingId, { color: value.HEX });
    } else {
      const newColor = rgbToHex({
        r: value.R !== undefined ? parseInt(value.R, 10) : rgb.r,
        g: value.G !== undefined ? parseInt(value.G, 10) : rgb.g,
        b: value.B !== undefined ? parseInt(value.B, 10) : rgb.b,
      });
      updateStylingForGraphLabel(stylingId, { color: newColor });
    }
  };
  const handleCustomClick = () => {
    setcustomColorPickerOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <>
      <span className="n-subheading-small py-2">Color:</span>
      <div className="grid grid-cols-12 gap-1.5 pb-1">
        {COLORS.map(({ color }) => (
          <button
            aria-label={`select-color-${color}`}
            key={color}
            style={{ backgroundColor: color }}
            className={`inline-block h-[25px] w-[25px] cursor-pointer rounded-full ${
              color === currentBackgroundColor && !customColorPickerOpen
                ? ' outline-palette-primary-focus outline '
                : ''
            }`}
            onClick={() => {
              setcustomColorPickerOpen(false);
              updateStylingForGraphLabel(stylingId, { color });
            }}
          />
        ))}
        <button
          aria-label={`select-default-color`}
          key={'default-color'}
          style={{ backgroundColor: defaultColor }}
          className={`mr-1 inline-block h-[25px] w-[25px] cursor-pointer rounded-full ${
            defaultColor === currentBackgroundColor && !customColorPickerOpen
              ? 'outline-palette-primary-focus outline'
              : ''
          }`}
          onClick={() => {
            setcustomColorPickerOpen(false);
            updateStylingForGraphLabel(stylingId, { color: defaultColor });
          }}
        />
        <button
          aria-label="select-custom-color"
          key="custom-color"
          style={{
            background: `
            conic-gradient(
    hsl(360, 100%, 70%),
    hsl(315, 100%, 70%),
    hsl(270, 100%, 70%),
    hsl(225, 100%, 70%),
    hsl(180, 100%, 70%),
    hsl(135, 100%, 70%),
    hsl(90, 100%, 70%),
    hsl(45, 100%, 70%),
    hsl(0, 100%, 70%)
`,
          }}
          className={`mr-1 inline-block  h-[25px] w-[25px] cursor-pointer rounded-full ${
            customColorPickerOpen ? ' outline-palette-primary-focus outline ' : ''
          }`}
          onClick={handleCustomClick}
        />
      </div>
      {customColorPickerOpen && (
        <ColorPicker
          rgb={{ r: 0, g: 0, b: 0 }}
          hex={{ hex: currentBackgroundColor }}
          hsl={{ h: 0, s: 0, l: 0 }}
          hsv={{ h: 0, s: 0, v: 0 }}
          oldHue={0}
          onInputChange={handleInputChange}
          color={currentBackgroundColor}
          onChange={handleColorChange}
          disabled={false}
          className="w-[368px] !pt-1 pb-2"
        />
      )}
      <span className="n-subheading-small">Size:</span>
      <div className="mb-1 flex items-center justify-between">
        {SIZES.map((size) => {
          return (
            <button
              aria-label={`select-size-${size.radius}px`}
              key={size.display}
              style={{ width: `${size.display}px`, height: `${size.display}px` }}
              className={cn(
                'bg-palette-neutral-bg-strong cursor-pointer rounded-full',
                size.radius === selectedSize && 'outline-palette-primary-focus outline',
              )}
              onClick={() => updateStylingForGraphLabel(stylingId, { size: size.radius })}
            />
          );
        })}
      </div>

      <span className="n-subheading-small">Caption:</span>
      <div className="flex flex-row flex-wrap gap-x-1.5 gap-y-1 leading-tight">
        {captionOptions.map((caption) => {
          const firstCaption = currentNodeStyling.captions?.at(0) ?? defaultCaption;
          const captionIsSelected =
            firstCaption !== undefined &&
            ((firstCaption.type === 'property' &&
              caption.type === 'property' &&
              firstCaption.captionKey === caption.captionKey) ||
              (caption.type === firstCaption.type && firstCaption.type !== 'property'));

          return (
            <GraphChip
              type="propertyKey"
              key={caption.type === 'property' ? caption.captionKey : `<${caption.type}>`}
              label={caption.type === 'property' ? caption.captionKey : `<${caption.type}>`}
              className={`${captionIsSelected ? ' outline-palette-primary-focus outline ' : ''}`}
              onClick={() => {
                updateStylingForGraphLabel(stylingId, { captions: [caption] });
              }}
              graphStyling={graphStyling}
            />
          );
        })}
      </div>
    </>
  );
};

const RelationshipStylingPicker = ({
  stylingId,
  captionOptions,
  graphStyling,
}: {
  stylingId: string;
  captionOptions: CaptionOption[];
  graphStyling: GraphStyling;
}) => {
  const updateStylingForRelType = useUpdateStylingForRelType({ debounce: 100 });
  const currentStyling = graphStyling.relationship[stylingId];
  const selectedWidth = currentStyling?.width ?? DEFAULT_REL_WIDTH;
  const selectedCaption = currentStyling?.captions?.at(0) ?? { type: 'type' };

  const [customColorPickerOpen, setcustomColorPickerOpen] = useState(false);

  const currentBackgroundColor: string | undefined = currentStyling?.color ?? DEFAULT_REL_COLOR;

  const handleColorChange = (color: { hex: string }) => {
    updateStylingForRelType(stylingId, { color: color.hex });
  };
  const handleInputChange =
    (rgb: RGBColor) =>
    (value: Partial<{ HEX: string; R: string; G: string; B: string }>, evt: ChangeEvent<HTMLInputElement>) => {
      if (value.HEX !== undefined && value.HEX !== '') {
        updateStylingForRelType(stylingId, { color: value.HEX });
      } else {
        const newColor = rgbToHex({
          r: value.R !== undefined ? parseInt(value.R, 10) : rgb.r,
          g: value.G !== undefined ? parseInt(value.G, 10) : rgb.g,
          b: value.B !== undefined ? parseInt(value.B, 10) : rgb.b,
        });
        updateStylingForRelType(stylingId, { color: newColor });
      }
    };

  const handleCustomClick = () => {
    setcustomColorPickerOpen((prevIsOpen) => !prevIsOpen);
  };
  return (
    <>
      <span className="n-subheading-small">Color:</span>
      <div>
        {COLORS.map(({ color }) => (
          <button
            aria-label={`select-color-${color}`}
            key={color}
            style={{ backgroundColor: color }}
            className={`mr-1 inline-block h-[25px] w-[25px] cursor-pointer rounded-full ${
              color === currentBackgroundColor && !customColorPickerOpen
                ? ' outline-palette-primary-focus outline '
                : ''
            }`}
            onClick={() => {
              setcustomColorPickerOpen(false);
              updateStylingForRelType(stylingId, { color: color });
            }}
          />
        ))}
        <button
          aria-label="select-custom-color"
          key="custom-color"
          style={{
            background: 'conic-gradient(red, yellow, lime, aqua, blue, magenta, red)',
          }}
          className={`mr-1 inline-block h-[25px] w-[25px] cursor-pointer rounded-full ${
            customColorPickerOpen ? 'outline-palette-primary-focus outline' : ''
          }`}
          onClick={handleCustomClick}
        ></button>
      </div>
      {customColorPickerOpen && (
        <ColorPicker
          rgb={{ r: 0, g: 0, b: 0 }}
          hex={{ hex: currentBackgroundColor }}
          hsl={{ h: 0, s: 0, l: 0 }}
          hsv={{ h: 0, s: 0, v: 0 }}
          oldHue={0}
          onInputChange={handleInputChange}
          color={currentBackgroundColor}
          onChange={handleColorChange}
          disabled={false}
          className="w-[350px] !pt-4 pb-2"
        />
      )}
      <span className="n-subheading-small">Width:</span>
      <div className="mb-2 flex items-center">
        {LINE_WIDTHS.map(({ display, width }) => (
          <button
            aria-label={`select-width-${width}px`}
            key={width}
            style={{ width: `${display}px`, height: `20px` }}
            className={`bg-palette-neutral-bg-strong mr-2 cursor-pointer ${
              width === selectedWidth ? ' outline-palette-primary-focus outline ' : ''
            }`}
            onClick={() => updateStylingForRelType(stylingId, { width: width })}
          />
        ))}
      </div>
      <span className="n-subheading-small">Caption:</span>
      <div className="flex flex-row flex-wrap gap-x-1.5 gap-y-1 leading-tight">
        {captionOptions.map((caption) => {
          const captionIsSelected =
            (selectedCaption.type === 'property' &&
              caption.type === 'property' &&
              selectedCaption.captionKey === caption.captionKey) ||
            (caption.type === selectedCaption.type && selectedCaption.type !== 'property');

          return (
            <GraphChip
              type="propertyKey"
              key={caption.type === 'property' ? caption.captionKey : `<${caption.type}>`}
              label={caption.type === 'property' ? caption.captionKey : `<${caption.type}>`}
              className={captionIsSelected ? 'outline-palette-primary-focus outline' : ''}
              onClick={() => {
                updateStylingForRelType(stylingId, { captions: [caption] });
              }}
              graphStyling={graphStyling}
            />
          );
        })}
      </div>
    </>
  );
};

type ImportModalState =
  | { state: 'closed' }
  | { state: 'error'; error: string }
  | { state: 'success'; stylingToLoad: GraphStyling };

export function StylingPicker({ stylePicker, onClickOutside, anchorEl, graphStyling }: StylingPickerProps) {
  const { captionOptions, stylingId, type } = stylePicker;
  const fileInput = useRef<HTMLInputElement>(null);
  const [showImportButton] = useFeatureFlag('query:import-grass');
  const [modalState, setModalState] = useState<ImportModalState>({ state: 'closed' });
  const batchUpdateStyling = useBatchUpdateStyling();

  const handleFileInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files === null) {
      return;
    }
    const [file] = files;
    file
      ?.text()
      .then((text) => {
        const parsed = parseBrowserGrass(text);
        setModalState({ state: 'success', stylingToLoad: parsed });
      })
      .catch((e) => {
        setModalState({ state: 'error', error: String(e) });
      });

    event.target.value = '';
  };

  const closeModal = () => setModalState({ state: 'closed' });

  return (
    <>
      <Popover
        isOpen={true}
        anchorElement={anchorEl}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            onClickOutside();
          }
        }}
        // TODO: Fix anchorOrigin
        /* anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}*/
      >
        <Popover.Content className="flex max-h-[45rem] max-w-[400px] gap-2 overflow-auto p-4">
          <div className="flex items-center justify-between pr-1">
            <Typography variant="h6">Customize Style</Typography>
            {showImportButton && (
              <Tooltip type="simple" placement="bottom">
                <Tooltip.Trigger hasButtonWrapper>
                  <IconButton
                    onClick={() => {
                      fileInput.current?.click();
                    }}
                    ariaLabel="Import GraSS"
                    size="small"
                    isClean
                  >
                    <ArrowUpTrayIconOutline />
                  </IconButton>
                </Tooltip.Trigger>
                <input
                  type="file"
                  accept=".style,.grass,.txt"
                  ref={fileInput}
                  className="hidden"
                  onChange={handleFileInput}
                />
                <Tooltip.Content>Import GraSS style file</Tooltip.Content>
              </Tooltip>
            )}
          </div>
          <div style={{ zIndex: '100' }}>
            {type === 'LABEL' ? (
              <NodeStylingPicker stylingId={stylingId} captionOptions={captionOptions} graphStyling={graphStyling} />
            ) : (
              <RelationshipStylingPicker
                stylingId={stylingId}
                captionOptions={captionOptions}
                graphStyling={graphStyling}
              />
            )}
          </div>
        </Popover.Content>
      </Popover>
      <Dialog
        isOpen={modalState.state !== 'closed'}
        modalProps={{
          className: 'w-full',
          id: 'default-menu',
        }}
        onClose={closeModal}
      >
        <Dialog.Header>GraSS Import Preview</Dialog.Header>
        {modalState.state === 'success' && (
          <Dialog.Description>
            <Typography
              className="bg-palette-neutral-bg-strong block max-h-96 max-w-full overflow-auto whitespace-pre p-3"
              variant="code"
            >
              {prettyPrintAsGrass(modalState.stylingToLoad)}
            </Typography>
            <Typography variant="body-medium" className="mt-1 block">
              <b>Note:</b> Only a subset the following GraSS properties <Typography variant="code">caption</Typography>,{' '}
              <Typography variant="code">color</Typography>, <Typography variant="code">diameter</Typography>,{' '}
              <Typography variant="code">shaft-width</Typography> are supported at this time, so some styling may not be
              imported.
            </Typography>
          </Dialog.Description>
        )}
        {modalState.state === 'error' && <Dialog.Subtitle>{modalState.error}</Dialog.Subtitle>}

        <Dialog.Actions>
          <Button color="neutral" fill="outlined" onClick={closeModal} size="large">
            Cancel
          </Button>
          {modalState.state === 'success' && (
            <Button
              onClick={() => {
                batchUpdateStyling(modalState.stylingToLoad);
                closeModal();
              }}
              size="large"
            >
              Import
            </Button>
          )}
        </Dialog.Actions>
      </Dialog>
    </>
  );
}
