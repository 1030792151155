import { Banner, Button, Dialog, TextInput } from '@neo4j-ndl/react';
import type { NewOauthClient } from '@nx/state';
import { consoleApi } from '@nx/state';
import { CopyTextInput } from '@nx/ui';
import type { FormEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';

import { validateYup } from '../../utils/validation';

const nameSchema = yup.object({
  name: yup
    .string()
    .trim()
    .max(30)
    .required()
    .matches(/^[ a-zA-Z0-9_-]+$/, 'Must only contain spaces, alphanumeric characters, hyphen or underscore')
    .label('Name'),
});

export const validate = (data: { name: string }) => validateYup(nameSchema, data);

function ApiKeyCreationForm({
  onClose,
  onSuccess,
}: {
  onClose: () => void;
  onSuccess: (newClient: NewOauthClient) => void;
}) {
  const [name, setName] = useState('');
  const [createOauthClient, createOauthClientRes] = consoleApi.useCreateOauthClientMutation();
  const [validationError, setValidationError] = useState('');

  useEffect(() => {
    if (createOauthClientRes.data) {
      onSuccess(createOauthClientRes.data);
    }
  }, [createOauthClientRes.data, onSuccess]);

  const loadingKeyCreation = createOauthClientRes.isLoading || createOauthClientRes.isSuccess;

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const trimmed = name.trim();
      const error = validate({ name: trimmed })?.name?.message ?? '';
      setValidationError(error);

      if (!error) {
        void createOauthClient(trimmed);
      }
    },
    [createOauthClient, name],
  );

  return (
    <Dialog
      isOpen
      hasDisabledCloseButton={loadingKeyCreation}
      onClose={loadingKeyCreation ? undefined : onClose}
      size="small"
    >
      <Dialog.Header>Create API key</Dialog.Header>
      <form onSubmit={onSubmit}>
        <TextInput
          isFluid
          size="medium"
          value={name}
          errorText={validationError}
          onChange={(e) => setName(e.target.value)}
          label="Name"
          htmlAttributes={{
            name: 'name',
          }}
        />
        <Dialog.Actions>
          <Button fill="outlined" onClick={onClose} isDisabled={loadingKeyCreation} color="neutral">
            Cancel
          </Button>
          <Button isDisabled={!name} type="submit" isLoading={loadingKeyCreation}>
            Create
          </Button>
        </Dialog.Actions>
      </form>
    </Dialog>
  );
}

function ApiKeyCreationSuccessForm({ client, onClose }: { client: NewOauthClient; onClose: () => void }) {
  const credentialsFilename = `Neo4j-credentials-${client.name}-Created-${new Date().toISOString().slice(0, 10)}.txt`;
  const credentialsURL = () => {
    const content = `CLIENT_SECRET=${client.clientSecret}\nCLIENT_ID=${client.clientId}\nCLIENT_NAME=${client.name}\n`;
    const blob = new Blob([content], { type: 'text/plain' });
    return URL.createObjectURL(blob);
  };

  return (
    <Dialog isOpen onClose={onClose} size="unset" modalProps={{ className: 'max-w-[720px] w-[80%]' }}>
      <Dialog.Header>Create API key</Dialog.Header>
      <form>
        <div className="flex flex-col gap-6">
          <CopyTextInput value={client.clientId} label="Client ID" isPortaled={false} />
          <CopyTextInput value={client.clientSecret} label="Client Secret" isPortaled={false} />
          <Banner
            hasIcon
            type="warning"
            title="IMPORTANT"
            description="After closing this window, you will no longer be able to access your API key. We recommend either securely storing it or downloading it for safekeeping."
            usage="inline"
          />
        </div>
        <Dialog.Actions>
          <Button
            as="a"
            fill="outlined"
            htmlAttributes={{
              href: credentialsURL(),
              download: credentialsFilename,
            }}
          >
            Download
          </Button>
          <Button onClick={onClose}>Done</Button>
        </Dialog.Actions>
      </form>
    </Dialog>
  );
}

export function CreateApiKeyModal({ onClose }: { onClose: () => void }) {
  const [createdClient, setCreatedClient] = useState<NewOauthClient | null>(null);

  return createdClient ? (
    <ApiKeyCreationSuccessForm client={createdClient} onClose={onClose} />
  ) : (
    <ApiKeyCreationForm onClose={onClose} onSuccess={setCreatedClient} />
  );
}
