import type { SerializedError } from '@reduxjs/toolkit';
import deepEqual from 'fast-deep-equal';
import type { Integer, Record, RecordShape, ResultSummary } from 'neo4j-driver-core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { RunQueryInput } from './adapters/neo4j-driver-adapter';
import { useDispatch } from './context';
import * as connections from './slices/connections/connections-slice';
import type { RunQueryActionConfig } from './slices/connections/connections-slice';

type QueryPromise<Entries extends RecordShape> = {
  abort: () => void;
  unwrap: () => Promise<{
    records: Record<Entries>[];
    recordLimitHit: boolean;
    summary: ResultSummary;
  }>;
};

/**
 * Memoize value using deep comparison and return the same object if its
 * contents have not changed. Allows to use objects in other hooks’ dependency
 * arrays that check equality by reference
 *
 * Returned properties and methods have stable references, i.e. could be safely
 * used in other hooks’ dependency arrays or shallow compared if needed.
 *
 * @param nextValue
 * @returns
 */
function useDeepMemo<T>(nextValue: T): T {
  const value = useRef(nextValue);
  return useMemo(() => {
    if (!deepEqual(value.current, nextValue)) {
      value.current = nextValue;
    }

    return value.current;
  }, [value, nextValue]);
}

export function useDeferredCypherQuery<Entries extends RecordShape = RecordShape>(
  input: Omit<RunQueryInput, 'parameters'>,
  config: RunQueryActionConfig,
): [
  {
    error?: SerializedError;
    fetching: boolean;
    records?: Record<Entries>[];
    stale: boolean;
    summary?: ResultSummary;
  },
  (parameters: RunQueryInput['parameters']) => QueryPromise<RecordShape>,
] {
  // Input and config are objects that cannot be compared by reference.
  // `useDeepMemo` ensures stable reference if objects are equal by value.
  const memoizedInput = useDeepMemo(input);
  const memoizedConfig = useDeepMemo(config);
  const dispatch = useDispatch();

  // runQuery has stable reference, i.e. it won’t change if parameters don’t
  const runQuery = useCallback(
    (parameters: RunQueryInput['parameters']) =>
      dispatch(
        connections.runQuery({
          query: memoizedInput.query,
          parameters,
          metadata: memoizedConfig.metadata,
          recordLimit: memoizedConfig.recordLimit,
          sessionConfig: memoizedConfig.sessionConfig,
          timeout: memoizedConfig.timeout,
          transactionCommitType: memoizedConfig.transactionCommitType,
          transactionMode: memoizedConfig.transactionMode,
        }),
      ),
    // TODO: comment why memoizedConfig.timeout is excluded
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      memoizedInput.query,
      memoizedConfig.metadata,
      memoizedConfig.recordLimit,
      memoizedConfig.sessionConfig,
      memoizedConfig.transactionCommitType,
      memoizedConfig.transactionMode,
      dispatch,
    ],
  );

  const [fetching, setFetching] = useState(false);
  const [stale, setStale] = useState(false);

  const [error, setError] = useState<SerializedError>();
  const [records, setRecords] = useState<Record<RecordShape>[]>();
  const [summary, setSummary] = useState<ResultSummary>();

  const [queryPromise, setQueryPromise] = useState<QueryPromise<RecordShape>>();

  useEffect(() => {
    if (queryPromise === undefined) {
      return;
    }

    setFetching(true);

    queryPromise
      .unwrap()
      .then((payload) => {
        setRecords(payload.records);
        setSummary(payload.summary);
      })
      .catch((rejectionError: SerializedError) => {
        setError(rejectionError);
        setRecords(undefined);
        setSummary(undefined);
      })
      .finally(() => {
        setFetching(false);
        setStale(false);
      });

    // eslint-disable-next-line consistent-return
    return () => {
      setError(undefined);
      setFetching(false);
      setStale(true);
      void queryPromise.abort();
    };
  }, [queryPromise]);

  // executeMutation has stable reference, i.e. it won’t change if parameters don’t
  const executeMutation = useCallback(
    (...args: Parameters<typeof runQuery>) => {
      const action = runQuery(...args);
      setQueryPromise(action);
      return action;
    },
    [runQuery],
  );

  return [
    {
      error,
      fetching,
      records,
      stale,
      summary,
    },
    executeMutation,
  ];
}

/**
 * React Hook for Cypher query execution
 *
 * Aborting queries: in-flight query can be aborted by setting `pause` property
 * to `false` or unmounting component that uses the hook.
 *
 * @param input
 * @param config
 * @returns
 */
export function useCypherQuery<Entries extends RecordShape = RecordShape>(
  { parameters, ...input }: RunQueryInput,
  config: RunQueryActionConfig & { paused?: boolean },
): [
  {
    error?: SerializedError;
    fetching: boolean;
    records?: Record<Entries>[];
    stale: boolean;
    summary?: ResultSummary<Integer>;
  },
  () => void,
] {
  // `parameters` is an object that cannot be compared by reference.
  // `useDeepMemo` ensures stable reference if objects are equal by value.
  const memoizedParameters = useDeepMemo(parameters);
  const [response, execute] = useDeferredCypherQuery(input, config);

  useEffect(() => {
    if (config.paused === true) {
      return;
    }

    const queryPromise = execute(memoizedParameters);

    // eslint-disable-next-line consistent-return
    return () => {
      void queryPromise.abort();
    };
  }, [config.paused, execute, memoizedParameters]);

  // invalidate has stable reference, i.e. it won’t change if parameters don’t
  const invalidate = useCallback(() => {
    execute(memoizedParameters);
  }, [execute, memoizedParameters]);

  return [response, invalidate];
}
