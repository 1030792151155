import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../types';
import type { ContextMenuState } from './types';

export const NAME = 'contextmenu';

export const initialState: ContextMenuState = {
  enabled: false,
};

export function getContextMenuState(state: RootState) {
  return state[NAME] ?? initialState;
}
export function getContextMenuEnabled(state: RootState): boolean {
  return state[NAME]?.enabled ?? false;
}
export const getContextMenuParams = createSelector(
  (state: RootState) => state[NAME]?.params,
  (params) => params ?? {},
);
export const getNewNode = createSelector(
  (state: RootState): { id: string } | undefined => state[NAME]?.newNode,
  (newNode) => newNode,
);

const contextMenuSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    initiateContextMenu: (state, action: PayloadAction<ContextMenuState['params']>) => {
      const params = action.payload;
      state.params = params;
      state.enabled = true;
    },
    setNewNode: (state, action: PayloadAction<ContextMenuState['newNode']>) => {
      const newNode = action.payload;
      state.newNode = newNode;
    },
    closeContextMenuOverlay: () => {
      return initialState;
    },
  },
});

export const { initiateContextMenu, closeContextMenuOverlay, setNewNode } = contextMenuSlice.actions;

export default contextMenuSlice.reducer;
