import { Select, Tag, Tooltip, Typography } from '@neo4j-ndl/react';
import { ArrowPathIconOutline } from '@neo4j-ndl/react/icons';
import type { TimePeriod, TimeRange } from '@nx/state';
import { isNonEmptyString } from '@nx/stdlib';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import type { GroupBase, ValueContainerProps } from 'react-select';
import { components } from 'react-select';
import type {} from 'react-select/base';

import { calculateTimeRangeLabel, timePeriodFormatter } from '../../../shared/ui-helpers';
import type { DropdownOptions } from '../types';
import LoadingProxyButton from './loading-proxy-button';

// https://react-select.com/typescript#custom-select-props
declare module 'react-select/base' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
    opsMetricsTimeRange?: TimeRange;
  }
}

const DefaultTimePeriodOptions: DropdownOptions<TimePeriod> = [
  {
    value: 'LIVE',
    label: 'Last 30 minutes',
  },
  {
    value: '1H',
    label: 'Last hour',
  },
  {
    value: '2H',
    label: 'Last 2 hours',
  },
  {
    value: '6H',
    label: 'Last 6 hours',
  },
  {
    value: '24H',
    label: 'Last 24 hours',
  },
  {
    value: '3D',
    label: 'Last 3 days',
  },
  {
    value: '1W',
    label: 'Last week',
  },
  {
    value: '2W',
    label: 'Last 2 weeks',
  },
  {
    value: '1M',
    label: 'Last 30 days',
  },
];

const ValueContainer = ({
  children,
  ...rest
}: ValueContainerProps<
  {
    value: TimePeriod;
    label: string;
  },
  false,
  GroupBase<{
    value: TimePeriod;
    label: string;
  }>
> & {
  opsMetricsTimeRange?: TimeRange;
}) => {
  const { opsMetricsTimeRange: timeRange } = rest;
  if (!timeRange) {
    return null;
  }
  const unit = calculateTimeRangeLabel(timeRange.startTime, timeRange.endTime);
  const range = `${timePeriodFormatter(timeRange.startTime)} - ${timePeriodFormatter(timeRange.endTime)}`;
  return (
    <components.ValueContainer {...rest} className={classNames(rest.className, '')}>
      <div className="absolute flex items-center gap-2 px-1">
        <Tag type="default">{unit}</Tag>
        <Typography variant="body-medium">{range}</Typography>
      </div>
      {children}
    </components.ValueContainer>
  );
};

export type TimePeriodAndRefreshProps = {
  timePeriod: TimePeriod;
  timeRange: TimeRange;
  onTimePeriodChange: (timePeriod: TimePeriod) => void;
  warningText?: string;
  onRefresh: () => void;
  timePeriodOptions?: DropdownOptions<TimePeriod>;
};

export const TimePeriodAndRefresh = ({
  timePeriod,
  timeRange,
  onTimePeriodChange,
  warningText,
  onRefresh,
  timePeriodOptions = DefaultTimePeriodOptions,
}: TimePeriodAndRefreshProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const isTooltipEnabled = isNonEmptyString(warningText);

  useEffect(() => {
    if (isTooltipEnabled) {
      setIsTooltipOpen(true);
    }
  }, [isTooltipEnabled]);

  useEffect(() => {
    let tId: NodeJS.Timeout;
    if (isTooltipOpen) {
      tId = setTimeout(() => {
        setIsTooltipOpen(false);
      }, 2000);
    }

    return () => clearTimeout(tId);
  }, [isTooltipOpen]);

  const dropdown = useMemo(() => {
    return (
      <Select
        size="medium"
        type="select"
        style={{ width: 350 }}
        selectProps={{
          isSearchable: false,
          'aria-label': `${timePeriodFormatter(timeRange.startTime)} - ${timePeriodFormatter(timeRange.endTime)}`,
          isMulti: false,
          closeMenuOnSelect: true,
          onChange: (e) => onTimePeriodChange(e?.value ?? timePeriodOptions[0]?.value ?? 'LIVE'),
          value: {
            value: timePeriod,
            label: '',
          },
          options: timePeriodOptions,
          components: { ValueContainer: (props) => <ValueContainer {...props} opsMetricsTimeRange={timeRange} /> },
        }}
        htmlAttributes={{
          'aria-label': 'time window dropdown selector',
          'data-testid': 'time-window-selector',
        }}
      />
    );
  }, [onTimePeriodChange, timePeriod, timePeriodOptions, timeRange]);

  return (
    <Tooltip
      type="simple"
      isDisabled={!isTooltipEnabled}
      isOpen={isTooltipOpen}
      onOpenChange={(open) => {
        if (isTooltipEnabled && open) {
          setIsTooltipOpen(true);
        }
      }}
    >
      <Tooltip.Trigger hasButtonWrapper>
        <div className="flex gap-2">
          {dropdown}
          <LoadingProxyButton ariaLabel="refresh" size="medium" data-testid="refresh-dashboard" onClick={onRefresh}>
            <ArrowPathIconOutline className="h-full w-full" />
          </LoadingProxyButton>
        </div>
      </Tooltip.Trigger>
      <Tooltip.Content>{warningText}</Tooltip.Content>
    </Tooltip>
  );
};
