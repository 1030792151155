export enum AURA_CONSOLE_EVENTS {
  // Instance events

  /**
   * Change instances view display mode.
   */
  INSTANCES_DISPLAY_MODE_CHANGE = 'INSTANCES_DISPLAY_MODE_CHANGE',

  /**
   * Pause instance.
   */
  INSTANCE_PAUSE = 'INSTANCE_PAUSE',

  /**
   * Resume instance.
   */
  INSTANCE_RESUME = 'INSTANCE_RESUME',

  /**
   * Click develop button on instance.
   */
  INSTANCE_DEVELOP = 'INSTANCE_DEVELOP',

  /**
   * Open instance resize modal.
   */
  INSTANCE_CONFIGURE_OPEN_MODAL = 'INSTANCE_CONFIGURE_OPEN_MODAL',

  /**
   * Send update instance config request.
   */
  INSTANCE_CONFIG_UPDATE = 'INSTANCE_CONFIG_UPDATE',

  /**
   * Send update instance cdc request.
   */
  INSTANCE_CDC_UPDATE = 'INSTANCE_CDC_UPDATE',

  /**
   * Send update instance secondaries count request.
   */
  INSTANCE_SECONDARIES_COUNT_UPDATE = 'INSTANCE_SECONDARIES_COUNT_UPDATE',

  /**
   * View instance details.
   */
  INSTANCE_VIEW_DETAILS = 'INSTANCE_VIEW_DETAILS',

  /**
   * Take snapshot of instance.
   */
  INSTANCE_TAKE_SNAPSHOT = 'INSTANCE_TAKE_SNAPSHOT',

  /**
   * Create a new instance.
   */
  INSTANCE_CREATE = 'INSTANCE_CREATE',

  /**
   * Clone an existing instance to a new instance.
   */
  INSTANCE_CLONE_TO_NEW = 'INSTANCE_CLONE_TO_NEW',

  /**
   * Clone an existing instance into a running instance.
   */
  INSTANCE_CLONE_TO_EXISTING = 'INSTANCE_CLONE_TO_EXISTING',

  /**
   * Upgrade an existing prof instance to buisness critical instance.
   */
  INSTANCE_UPGRADE = 'INSTANCE_UPGRADE',

  /**
   * Reset an instance to a blank slate.
   */
  INSTANCE_RESET_TO_BLANK = 'INSTANCE_RESET_TO_BLANK',

  /**
   * Destroy an instance.
   */
  INSTANCE_DESTROY = 'INSTANCE_DESTROY',

  /**
   * Toggle pro trial sizes.
   */
  INSTANCE_PRO_TRIAL_TOGGLE = 'PRO_TRIAL_TOGGLE',

  /**
   * Untoggle pro trial sizes.
   */
  INSTANCE_PRO_TRIAL_UNTOGGLE = 'PRO_TRIAL_UNTOGGLE',

  /**
   * Mark an instance as Production.
   */
  INSTANCE_MARK_AS_PRODUCTION = 'INSTANCE_MARK_AS_PRODUCTION',

  /**
   * Remove Production mark from an instance.
   */
  INSTANCE_UNMARK_AS_PRODUCTION = 'INSTANCE_UNMARK_AS_PRODUCTION',

  /**
   * Remove Production mark from an instance.
   */
  INSTANCE_PAGE_NAVIGATE = 'NAVIGATE_INSTANCE_PAGE',

  /**
   * Instance status updates to 'running' from 'creating'.
   */
  INSTANCE_RUNNING = 'INSTANCE_RUNNING',

  /**
   * User logs in.
   */
  LOGIN = 'LOGIN',

  /**
   * User clicks support link from marketplace order registration page
   */
  MARKETPLACE_SUPPORT_LINK_CLICK = 'MARKETPLACE_SUPPORT_LINK_CLICK',
}
