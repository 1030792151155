import { Modal, Select } from '@neo4j-ndl/react';
import { OPS_EVENTS } from '@nx/analytics-service';
import type { Instance, MetricMetadata, MetricsResult, SeriesSummary, TimeRange } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { useDarkMode } from '@nx/ui';
import classNames from 'classnames';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import type uPlot from 'uplot';
import useResizeObserver from 'use-resize-observer';

import { track } from '../../../services/segment/analytics';
import { ApiErrorBanner } from '../../../shared/components';
import { useNavigateToLogsAction } from '../../../shared/hooks/use-navigate-to-logs-action';
import { arePropsDeepEqual } from '../../../shared/ui-helpers';
import { metricKey } from '../../../shared/utils';
import {
  chartKey,
  overviewPlotsSync,
  prepareBuilder,
  useChartState,
  useMetricsQuery,
  useMultiChartProps,
} from '../../hooks';
import { getSeriesColor } from '../../hooks/use-series-colors';
import { CHART_MODAL, MetricInfoModal, MetricsIntegrationModal } from '../../modals';
import { Plot } from '../../plot';
import { COLOR_PALETTE_ENUM } from '../../shared/color-palettes';
import { DEFAULT_CHART_HEIGHT } from '../../shared/constants';
import { analyticsPropsFromTimeRange } from '../../shared/utils';
import type { ChartWidgetPartialProps } from '../fullstack-chart-props';
import { isMetricAvailableInCmi } from '../fullstack-chart-props';
import type { ChartContext, ChartExpandProps, MultiChartProps } from '../types';
import classes from './../../metrics.module.css';
import { ActionBar, OPTIONS_STATIC_PROPS, prepareActions } from './components/action-bar';
import { LoadingBanner, NoDataBanner } from './components/banners';
import { Card } from './components/cards';
import { Pills } from './components/pills';

type LineCardOptionalFeatures = 'colored-reference-pill' | 'auraLogs';

export const OptionalFeatures: Record<'Aura' | 'AuraWithLogs', ReadonlySet<LineCardOptionalFeatures>> = {
  Aura: new Set(['colored-reference-pill']),
  AuraWithLogs: new Set(['colored-reference-pill', 'auraLogs']),
};

const getTooltip = (chart: ChartWidgetPartialProps | undefined, metadata?: Record<string, MetricMetadata>) => {
  if (chart && metadata) {
    return {
      link: null,
      body: <div>{metadata[chart.metricName]?.description}</div>,
    };
  }
  return {
    link: null,
    body: <div />,
  };
};

interface LineCardProps {
  multiChartProps: MultiChartProps;
  context: ChartContext;
  expandProps: ChartExpandProps;
  metricsResult: MetricsResult;
  optionalFeatures: ReadonlySet<LineCardOptionalFeatures>;
  onZoom?: (timeRange: TimeRange) => void;
  onZoomReset?: () => void;
  timeRangePadded?: boolean;
  addMinMaxBands?: boolean;
  pills: SeriesSummary[];
  metadata?: Record<string, MetricMetadata>;
  isCmiAvailable: boolean;
  timeRange: TimeRange;
  instance?: Instance;
  useAggregations?: boolean;
  useAZGrouping?: boolean;
  showLeaderOnly?: boolean;
  database?: string;
}

export const LineCard = memo(
  ({
    multiChartProps,
    context,
    expandProps,
    metricsResult,
    optionalFeatures = new Set(),
    onZoom,
    onZoomReset,
    timeRangePadded,
    addMinMaxBands,
    pills,
    metadata,
    timeRange,
    instance,
    useAggregations,
    useAZGrouping,
    showLeaderOnly = false,
    database,
    isCmiAvailable,
  }: LineCardProps) => {
    const isDarkTheme = useDarkMode();
    const { chartProps, chartDropdownOptions } = useMultiChartProps(multiChartProps, useAZGrouping);
    const { state, processData, toggleChartFlag } = useChartState(chartProps);
    const plotParentRO = useResizeObserver();
    const [plot, setPlot] = useState<uPlot | null>(null);
    const [modal, setModal] = useState<CHART_MODAL | null>(null);
    const { navigateToLogsAction } = useNavigateToLogsAction();

    const trackChart = useCallback<typeof track>(
      (event, props) => {
        return track(event, {
          chart: chartKey(chartProps),
          isExpanded: expandProps.isExpanded,
          ...props,
        });
      },
      [chartProps, expandProps.isExpanded],
    );

    const toggleChartFlagTracked = useCallback<typeof toggleChartFlag>(
      (flag) => {
        toggleChartFlag(flag);
        trackChart(OPS_EVENTS.METRICS_CHART_TOGGLE_FLAG, {
          flag,
          flagValue: !state[flag],
        });
      },
      [state, toggleChartFlag, trackChart],
    );

    const trackOptionClick = useCallback(
      (optionKey: (typeof OPTIONS_STATIC_PROPS)[number]['key']) => {
        const menuOption = OPTIONS_STATIC_PROPS.find((option) => option.key === optionKey)?.title;
        trackChart(OPS_EVENTS.METRICS_CHART_SELECT_MENU_OPTION, { menuOption });
      },
      [trackChart],
    );

    const nestedChartSelected =
      Array.isArray(multiChartProps) &&
      multiChartProps.length > 0 &&
      isNotNullish(multiChartProps[0]) &&
      ((useAZGrouping ?? false) && multiChartProps[0].azMetricName
        ? multiChartProps[0].azMetricName !== chartProps.azMetricName
        : multiChartProps[0].metricName !== chartProps.metricName);

    const nestedMetricsResult = useMetricsQuery({
      selectedInstanceDetails: instance,
      timeRange,
      // Guaranteed by nestedChartSelected
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      chartProps: multiChartProps as ChartWidgetPartialProps[],
      chartType: chartProps.chartType,
      useAggregations: useAggregations,
      useAZGrouping: useAZGrouping,
      active: nestedChartSelected,
      showLeaderOnly,
      database,
    });

    const currentMetricsResult = !nestedChartSelected ? metricsResult : nestedMetricsResult;

    const data = useMemo(
      () => currentMetricsResult.metrics?.[metricKey(chartProps, useAZGrouping ?? false, context.selectedDB)],
      [chartProps, context.selectedDB, currentMetricsResult.metrics, useAZGrouping],
    );

    const dataExistence =
      (data &&
        Object.keys(data.dataSeries ?? {}).length > 0 &&
        Object.values(data.dataSeries ?? {}).some((elementMetrics) => elementMetrics.length > 0)) ??
      false;

    const contextualPills = useMemo(() => {
      if (dataExistence) {
        const fetchedSeries = Object.keys(data?.dataSeries ?? {});
        return pills.filter((pill) => fetchedSeries.some((key) => pill.id === key));
      }
      return [];
    }, [data, dataExistence, pills]);

    const plotOptions = useMemo(() => {
      const builder = prepareBuilder({
        state,
        chartProps,
        ignoredInstances: context.ignoredInstances,
        isExpanded: expandProps.isExpanded,
        onZoom: (tr) => {
          if (tr && onZoom) {
            onZoom(tr);
            trackChart(OPS_EVENTS.METRICS_CHART_ZOOM_IN, analyticsPropsFromTimeRange(tr));
          }
        },
        onDblClick: onZoomReset,
        isDarkTheme,
        seriesSummary: pills,
      });

      if (addMinMaxBands ?? false) {
        builder.addMinMaxBands(contextualPills);
      }

      if (chartProps.additionalMetric?.showBands ?? false) {
        builder.addAdditionalDataBands(contextualPills);
      }

      if (timeRangePadded ?? false) {
        const { startTime, endTime } = context.timeRange;
        builder.updateScales({
          x: {
            min: startTime.getTime(),
            max: endTime.getTime(),
          },
        });
      }

      return builder.getOptions();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartProps, context.ignoredInstances, expandProps.isExpanded, state, isDarkTheme]);

    const tooltipComponent = useMemo(
      () => (chartProps.tooltipComponent ? chartProps.tooltipComponent : getTooltip(chartProps, metadata)),
      [chartProps, metadata],
    );

    const chartActionOptions = useMemo(
      () =>
        prepareActions({
          expand: !expandProps.isExpanded && {
            onClick: () => {
              expandProps.onSetExpanded?.(multiChartProps);
              trackOptionClick('expand');
            },
          },
        }),
      [expandProps, multiChartProps, trackOptionClick],
    );

    const chartMenuOptions = useMemo(
      () =>
        prepareActions({
          info: {
            onClick: () => {
              setModal(CHART_MODAL.ShowInfo);
              trackOptionClick('info');
            },
          },
          zoom: {
            onClick: () => {
              (expandProps.isExpanded ? [plot] : overviewPlotsSync.plots).forEach((p) => {
                p?.setScale('x', {
                  min: context.timeRange.startTime.getTime(),
                  max: context.timeRange.endTime.getTime(),
                });
              });
              onZoomReset?.();
              trackOptionClick('zoom');
            },
          },
          auraLogs: optionalFeatures.has('auraLogs') &&
            (chartProps.showLinkToLogs ?? false) && {
              onClick: () => {
                navigateToLogsAction({ timeRange: context.timeRange });
                trackChart(OPS_EVENTS.METRICS_CHART_REQUEST_LOGS, analyticsPropsFromTimeRange(context.timeRange));
              },
            },
          metricsIntegration: isCmiAvailable &&
            isMetricAvailableInCmi(chartProps.cmiMetricName) && {
              onClick: () => {
                setModal(CHART_MODAL.MetricsIntegration);
                trackOptionClick('metricsIntegration');
              },
            },
        }),
      [
        chartProps.showLinkToLogs,
        chartProps.cmiMetricName,
        context.timeRange,
        expandProps.isExpanded,
        navigateToLogsAction,
        onZoomReset,
        optionalFeatures,
        plot,
        isCmiAvailable,
        trackChart,
        trackOptionClick,
      ],
    );

    const subtitle =
      !chartProps.referenceMetricConfig || (chartProps.referenceMetricConfig.hidden ?? false) ? (
        `${chartProps.additionalMetric ? '' : chartProps.yLabel}`
      ) : (
        <Select
          className={classNames(classes['dropdown-left-flush'], classes['dropdown-full-list-width'], '-ml-0.5')}
          selectProps={{
            isSearchable: false,
            'aria-label': 'Select alternative data series for this metric',
            options: [
              {
                label: chartProps.yLabel,
                value: 'absolute',
              },
              {
                label: 'Percentage',
                value: 'percentage',
              },
            ],
            onChange: (option) => {
              if (option?.value ?? '') {
                toggleChartFlagTracked('showPercentage');
              }
            },
            value: {
              label: state.showPercentage ? 'Percentage' : chartProps.yLabel,
              value: state.showPercentage ? 'percentage' : 'absolute',
            },
          }}
          size="small"
          type="select"
          key="%-dropdown"
          hasBorder={false}
        />
      );

    useEffect(() => {
      if (dataExistence) {
        processData({ data: data ?? {}, timeRange: context.timeRange, chartProps });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, dataExistence, processData, chartProps]);

    const card = (
      <Card
        aria-label={`Metric card for ${chartProps.metricName}`}
        title={chartProps.title}
        subtitle={subtitle}
        loading={dataExistence && currentMetricsResult.isLoading}
        controls={[
          expandProps.isExpanded && expandProps.expandedControls?.timePeriodAndRefresh,
          chartDropdownOptions && (
            <Select
              className="dropdown-full-list-width"
              selectProps={{
                isSearchable: false,
                'aria-label': 'Select alternative data series for this metric',
                ...chartDropdownOptions,
                onChange: (newValue) => {
                  chartDropdownOptions.onChange(newValue);
                  trackChart(
                    OPS_EVENTS.METRICS_CHART_CHANGE_CHART,
                    newValue?.value && { chart: chartKey(newValue.value) },
                  );
                },
              }}
              size="medium"
              type="select"
              key="chart-dropdown"
              htmlAttributes={{
                'aria-label': 'Chart dropdown options',
              }}
            />
          ),
          <ActionBar
            outerActions={chartActionOptions}
            menuActions={chartMenuOptions}
            isChartExpanded={expandProps.isExpanded}
            key="chart-menu"
          />,
          expandProps.isExpanded && expandProps.expandedControls?.close,
        ]}
        key={chartKey(chartProps)}
        className={classNames({ 'h-full': expandProps.isExpanded })}
        style={{
          minWidth: '250px',
          minHeight: '250px',
        }}
      >
        <div className="h-full w-full" ref={plotParentRO.ref}>
          {currentMetricsResult.error && <ApiErrorBanner error={currentMetricsResult.error} />}
          {currentMetricsResult.isLoading && !dataExistence && <LoadingBanner />}
          {!currentMetricsResult.error && (
            <>
              {dataExistence || currentMetricsResult.isLoading ? null : <NoDataBanner />}
              {dataExistence && (
                <Plot
                  width={plotParentRO.width ?? 0}
                  height={expandProps.isExpanded ? (plotParentRO.height ?? 0) : DEFAULT_CHART_HEIGHT}
                  data={state.alignedDataDisplay}
                  options={plotOptions}
                  onCreate={setPlot}
                />
              )}
            </>
          )}
        </div>

        {pills.length > 0 && Boolean(dataExistence) && !currentMetricsResult.error && (
          <Pills
            updateIgnoredIds={context.setIgnoredInstances}
            pills={contextualPills}
            currentIgnoredIds={context.ignoredInstances}
            getColor={getSeriesColor(COLOR_PALETTE_ENUM.Standard)}
            showTotals={
              !state.showPercentage && chartProps.referenceMetricConfig
                ? {
                    show: state.showTotals,
                    update: () => toggleChartFlagTracked('showTotals'),
                    label: `Total ${chartProps.yLabel}`,
                    fetchReferenceColor: optionalFeatures.has('colored-reference-pill'),
                  }
                : undefined
            }
          />
        )}
      </Card>
    );

    return (
      <>
        {modal === CHART_MODAL.ShowInfo && (
          <MetricInfoModal
            open
            onClose={() => setModal(null)}
            metricTitle={chartProps.title}
            tooltipProps={tooltipComponent}
          />
        )}

        {modal === CHART_MODAL.MetricsIntegration && (
          <MetricsIntegrationModal
            open
            onClose={() => setModal(null)}
            metricTitle={chartProps.title}
            metricName={chartProps.cmiMetricName ?? chartProps.metricName}
            projectId={instance?.projectId ?? ''}
          />
        )}

        {expandProps.isExpanded ? (
          <Modal
            isOpen
            onClose={() => expandProps.onSetExpanded?.(null)}
            modalProps={{
              className: '!max-w-[90%] !w-[90%] max-h-[85%] h-[85%]',
            }}
            key={chartKey(chartProps)}
          >
            {card}
          </Modal>
        ) : (
          card
        )}
      </>
    );
  },
  arePropsDeepEqual,
);

LineCard.displayName = 'LineCard';
