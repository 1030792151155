import { AURA_CONSOLE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { isNotNullish, isNullish } from '@nx/stdlib';

import { trackEvent } from '../../../middlewares/analytics-events';
import { updateUploadProgress } from '../../upload-slice';
import type {
  CreateAuraInstanceResponse,
  CreateInstanceRequest,
  CreateInstanceResponse,
  GetSignedObjectUrlPayload,
  GetSignedObjectUrlResponse,
  InstanceResponse,
  InstanceSummaryResponse,
  OverwriteInstanceRequest,
  RestoreFromSnapshotRequest,
  UpdateCDCModeRequest,
  UpdateInstanceConfigRequest,
  UpdateInstanceNameRequest,
  UpdateInstancePriorityRequest,
  UpdateSecondariesRequest,
  UpgradeInstanceConfigRequest,
} from '../console.api.types';
import {
  CLOUD_PROVIDER,
  type CreatedInstance,
  INSTANCE_STATUS,
  type Instance,
  type InstanceSummary,
  type SignedObjectUrl,
  TIER,
} from '../console.types';
import {
  transformCreateAuraInstanceResponse,
  transformCreateInstanceRequest,
  transformCreateInstanceResponse,
  transformInstanceResponse,
  transformInstanceSummaryResponse,
  transformOverwriteInstanceRequest,
  transformRestoreFromSnapshotRequest,
  transformSignedObjectUrl,
  transformUpdateCDCModeRequest,
  transformUpdateInstanceConfigRequest,
  transformUpdateInstanceNameRequest,
  transformUpdateSecondariesRequest,
  transformUpgradeInstanceConfigRequest,
} from '../transformers/instances';
import type { Builder } from './types';
import { LIST_ID } from './types';

type CreateInstanceTransformer = (response: CreateInstanceResponse) => CreatedInstance;
type CreateAuraInstanceTransformer = (response: CreateAuraInstanceResponse) => CreatedInstance;
type CreateTransformer = CreateInstanceTransformer | CreateAuraInstanceTransformer;

const buildCreateInstanceMutation = (builder: Builder, url: string, transformResponse: CreateTransformer) => {
  return builder.mutation<CreatedInstance, CreateInstanceRequest>({
    query: (request) => ({ url, body: transformCreateInstanceRequest(request), method: 'POST' }),
    transformResponse,
    invalidatesTags: (result, error, request) => {
      const invalidTags: { type: 'Instance' | 'Project' | 'ProjectNotification'; id?: string; dbId?: string }[] = [
        { type: 'Instance' as const, id: LIST_ID },
        { type: 'Project' as const, id: result?.projectId },
        { type: 'ProjectNotification' as const, id: result?.projectId },
      ];

      // If the instance is being created as a part of cloning we invalidate the instance that it is being cloned from
      if (request.tier !== TIER.FREE && request.sourceSnapshot) {
        invalidTags.push({ type: 'Instance', dbId: request.sourceSnapshot.dbId });
      }

      return invalidTags;
    },
  });
};

export const instanceEndpoints = (builder: Builder) => ({
  getInstance: builder.query<Instance, string>({
    query: (dbId) => `databases/${dbId}`,
    onQueryStarted: async (dbid, { dispatch, getCacheEntry, queryFulfilled }) => {
      const { data: cached } = getCacheEntry();
      if (isNullish(cached)) {
        return;
      }

      await queryFulfilled.then(({ data: instance }) => {
        if (instance.instanceStatus === INSTANCE_STATUS.RUNNING && cached.instanceStatus === INSTANCE_STATUS.CREATING) {
          dispatch(
            trackEvent({
              event: AURA_CONSOLE_EVENTS.INSTANCE_RUNNING,
              properties: {
                DbId: dbid,
                tier: instance.tier,
                DbSize: instance.desiredSettings.memory,
              },
              scope: APP_SCOPE.aura,
            }),
          );
        }
      });
    },
    transformResponse: (instance: InstanceResponse) => {
      return transformInstanceResponse(instance);
    },
    providesTags: (result, error, dbId) => [{ type: 'Instance', dbId }],
  }),
  createInstance: buildCreateInstanceMutation(builder, 'databases', transformCreateInstanceResponse),
  // New multi-DB capable version of the createInstance endpoint
  createAuraInstance: buildCreateInstanceMutation(builder, 'aura-instances', transformCreateAuraInstanceResponse),
  listInstances: builder.query<InstanceSummary[], string | undefined>({
    query: (projectId) => ({ url: 'databases', params: { Namespace: projectId } }),
    transformResponse: (instances: InstanceSummaryResponse[]) => {
      return instances.map((instance) => transformInstanceSummaryResponse(instance));
    },
    providesTags: (result) => {
      if (isNotNullish(result)) {
        return [...result.map(({ id }) => ({ type: 'Instance' as const, id })), { type: 'Instance', id: LIST_ID }];
      }
      return [{ type: 'Instance', id: LIST_ID }];
    },
  }),
  pauseInstance: builder.mutation<Instance, string>({
    query: (dbId) => ({ url: `databases/${dbId}/pause`, method: 'POST' }),
    transformResponse: (instance: InstanceResponse) => transformInstanceResponse(instance),
    invalidatesTags: (result, error, dbId) => [{ type: 'Instance', dbId }],
  }),
  resumeInstance: builder.mutation<Instance, string>({
    query: (dbId) => ({ url: `databases/${dbId}/resume`, method: 'POST' }),
    transformResponse: (instance: InstanceResponse) => transformInstanceResponse(instance),
    invalidatesTags: (result, error, dbId) => [{ type: 'Instance', dbId }],
  }),
  destroyInstance: builder.mutation<Instance, string>({
    query: (dbId) => ({ url: `databases/${dbId}`, method: 'DELETE' }),
    transformResponse: (instance: InstanceResponse) => transformInstanceResponse(instance),
    invalidatesTags: (result, error, dbId) => [
      { type: 'Instance', dbId },
      { type: 'Project', id: result?.projectId },
      { type: 'ProjectNotification', id: result?.projectId },
    ],
  }),
  upgradeInstance: builder.mutation<Instance, UpgradeInstanceConfigRequest>({
    query: (request) => ({
      url: `databases/${request.dbId}/upgrade`,
      body: transformUpgradeInstanceConfigRequest(request),
      method: 'PATCH',
    }),
    transformResponse: (instance: InstanceResponse) => transformInstanceResponse(instance),
    invalidatesTags: (result, error, { dbId }) => [{ type: 'Instance', dbId }],
  }),
  updateInstanceConfig: builder.mutation<Instance, UpdateInstanceConfigRequest>({
    query: (request) => ({
      url: `databases/${request.dbId}`,
      body: transformUpdateInstanceConfigRequest(request),
      method: 'PATCH',
    }),
    transformResponse: (instance: InstanceResponse) => transformInstanceResponse(instance),
    invalidatesTags: (result, error, { dbId }) => [{ type: 'Instance', dbId }],
  }),
  updateSecondaries: builder.mutation<Instance, UpdateSecondariesRequest>({
    query: (request) => ({
      url: `databases/${request.dbId}`,
      body: transformUpdateSecondariesRequest(request),
      method: 'PATCH',
    }),
    transformResponse: (instance: InstanceResponse) => transformInstanceResponse(instance),
    invalidatesTags: (result, error, { dbId }) => [{ type: 'Instance', dbId }],
  }),
  updateInstanceName: builder.mutation<Instance, UpdateInstanceNameRequest>({
    query: (request) => ({
      url: `databases/${request.dbId}`,
      body: transformUpdateInstanceNameRequest(request),
      method: 'PATCH',
    }),
    transformResponse: (instance: InstanceResponse) => transformInstanceResponse(instance),
    invalidatesTags: (result, error, { dbId }) => [{ type: 'Instance', dbId }],
  }),
  updateInstancePriority: builder.mutation<Instance, UpdateInstancePriorityRequest>({
    query: ({ dbId, priority }) => ({
      url: `databases/${dbId}/priority`,
      body: { priority },
      method: 'PUT',
    }),
    transformResponse: transformInstanceResponse,
    invalidatesTags: (result, error, { dbId }) => [{ type: 'Instance', dbId }],
  }),
  clearInstance: builder.mutation<void, string>({
    query: (dbId) => ({
      url: `databases/${dbId}/clear`,
      method: 'POST',
    }),
    invalidatesTags: (result, error, dbId) => [{ type: 'Instance', dbId }],
  }),
  overwriteInstance: builder.mutation<void, OverwriteInstanceRequest>({
    query: (request) => ({
      url: `databases/${request.dbId}/overwrite`,
      body: transformOverwriteInstanceRequest(request),
      method: 'POST',
    }),
    invalidatesTags: (result, error, { dbId }) => [{ type: 'Instance', dbId }],
  }),
  restoreInstanceFromSnapshot: builder.mutation<void, RestoreFromSnapshotRequest>({
    query: (request) => ({
      url: `databases/${request.dbId}/restore`,
      method: 'POST',
      body: transformRestoreFromSnapshotRequest(request),
    }),
    invalidatesTags: (result, error, { dbId }) => [{ type: 'Instance', id: dbId }],
  }),
  updateCDCMode: builder.mutation<Instance, UpdateCDCModeRequest>({
    query: (request) => ({
      url: `databases/${request.dbId}`,
      body: transformUpdateCDCModeRequest(request),
      method: 'PATCH',
    }),
    transformResponse: (instance: InstanceResponse) => transformInstanceResponse(instance),
    invalidatesTags: (result, error, { dbId }) => [{ type: 'Instance', dbId }],
  }),
  getSignedObjectUrl: builder.query<SignedObjectUrl, GetSignedObjectUrlPayload>({
    query: ({ id, size, extension }) => ({
      url: `databases/${id}/upload`,
      params: {
        size: size,
        extension: extension,
      },
      method: 'GET',
    }),
    transformResponse: (response: GetSignedObjectUrlResponse) => transformSignedObjectUrl(response),
  }),
  uploadFile: builder.mutation({
    queryFn: (
      { signedUrl, file, cloudProvider }: { signedUrl: string; file: File; cloudProvider: CLOUD_PROVIDER },
      { dispatch },
    ) => {
      const xhr = new XMLHttpRequest();

      xhr.open('PUT', signedUrl);

      xhr.upload.onprogress = (event: ProgressEvent) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          // Dispatch an action to update progress in the Redux store
          dispatch(updateUploadProgress(percentComplete));
        }
      };

      xhr.setRequestHeader('Content-Type', '');
      xhr.setRequestHeader('Access-Control-Allow-Origin', `https://${window.location.hostname}`);
      if (cloudProvider === CLOUD_PROVIDER.AZURE) {
        xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');
      }
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        xhr.onload = () => resolve({ data: xhr.response, error: xhr.response });
        xhr.onerror = () => reject(xhr.statusText);
        xhr.send(file);
      });
    },
  }),
  startImportForCloudStorageObject: builder.mutation<unknown, unknown>({
    query: ({ dbId, object }) => ({
      url: `databases/${dbId}/upload`,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      body: { CloudStorageObject: object },
      method: 'POST',
    }),
  }),
});
