import * as localForage from 'localforage';

import type { WithClearingCacheInProgress } from './types';

declare let window: WithClearingCacheInProgress;

const localForageWrapper = {
  getItem: localForage.getItem,
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  setItem(...args: Parameters<typeof localForage.setItem>) {
    if (window._clearingCacheInProgress) {
      return Promise.resolve(false);
    }
    return localForage.setItem(...args);
  },
  removeItem: localForage.removeItem,
  keys: localForage.keys,
  clear: localForage.clear,
  iterate: localForage.iterate,
};

export default localForageWrapper;
