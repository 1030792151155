import * as ImportShared from '@nx/import-shared';
import type { RootState } from '@nx/state';
import { createDynamicBaseQuery, prepareHeaders, LEGACY_store as store } from '@nx/state';
import { isNullish } from '@nx/stdlib';
import { createApi } from '@reduxjs/toolkit/query/react';

import { getLegacyDataSourceConfig } from '../../components/data-source-form/migration';
import { APP_VERSION } from '../../constants';
import { dehydrateVisualisationState } from '../../visualisation/utils';
import { buildDynamicDataSourceParamsPayloadByDataSourceConfig, getImportUrls, sortByTimeDesc } from './utils';

export const CLOUD_IMPORT_JOB_TAG = 'CloudImportJob' as const;
export const CLOUD_IMPORT_JOB_REDUCER_KEY = 'cloudImportJob' as const;

// @TODO: enrich type.
type CloudDataImportRequestResponse = {
  message: string;
};

const IMPORT_JOB_STATUS = [...ImportShared.API_IMPORT_JOB_STATUS, 'Submitted', 'Error', 'Unknown'] as const;

export type ImportJobStatus = (typeof IMPORT_JOB_STATUS)[number];

export type ImportJobsStatusSummary = {
  status: ImportJobStatus;
  error?: string;
};

export type ImportJobsOverview = {
  submittedTime: string;
  startTime?: string;
  completionTime?: string;
  statusSummary: ImportJobsStatusSummary;
  id: string;
  dataSourceMetadata: { driver: ImportShared.LegacyDataSourceType };
  auraMetadata: { dbId: string };
};

const getOverallStatus = (
  status: ImportShared.ApiImportJobStatus,
  result?: ImportShared.ApiImportJobResult,
): ImportJobsStatusSummary => {
  if (result?.state === 'Failure') {
    return { status: 'Error', error: result.message };
  } else if (status === '') {
    return { status: 'Submitted' };
  } else if (!ImportShared.API_IMPORT_JOB_STATUS.includes(status)) {
    return { status: 'Unknown' };
  }
  return { status };
};

const parseTimeString = (timeString?: string) => {
  // before backend has got the time it can be empty string or undefined
  if (isNullish(timeString) || timeString === '') {
    return 'N/A';
  }
  const time = Date.parse(timeString);
  // not expected to happen that the string is not a valid date
  if (isNaN(time)) {
    return 'Invalid date';
  }
  const date = new Date(time);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

export type SpawnImportJobParams = {
  useDynamicDataSource?: boolean;
  dataSourceConfig: ImportShared.DataSourceConfig;
  auraParams: ImportShared.AuraConnectParams;
  dataSourceId: string;
  importModelId: string;
  dataModel: ImportShared.DataModelJsonStruct;
  visualisation: ImportShared.VisualisationState;
};
export const cloudImportJobsSlice = createApi({
  tagTypes: [CLOUD_IMPORT_JOB_TAG],
  reducerPath: CLOUD_IMPORT_JOB_REDUCER_KEY,
  baseQuery: createDynamicBaseQuery(
    (state: RootState) => getImportUrls(state).rootEndpoint,
    {
      prepareHeaders,
    },
    (): RootState => store.getState(),
  ),
  endpoints: (builder) => ({
    spawnNewImportJob: builder.mutation<CloudDataImportRequestResponse, SpawnImportJobParams>({
      query: ({
        // TODO: remove useDynamicDataSource once dynamic data sources are fully released
        useDynamicDataSource = false,
        dataSourceConfig,
        auraParams,
        dataSourceId,
        importModelId,
        dataModel,
        visualisation,
      }) => {
        const legacyConfig: ImportShared.LegacyDataSourceConfig = getLegacyDataSourceConfig(dataSourceConfig);

        const requestBody: ImportShared.LegacyCloudDataImportRequestBody = {
          dataSourceCredentials: { ...legacyConfig, upxDataSourceId: dataSourceId },
          auraCredentials: auraParams,
          importConfig: {
            ignoreErrors: true,
            threads: 1,
            batchSize: 5000,
          },
          importModelMetadata: {
            version: APP_VERSION,
            importModelId,
            dataModel: dataModel,
            visualisation: dehydrateVisualisationState(visualisation),
          },
        };

        const requestBodyWithDynamicDataSourceParams: ImportShared.CloudDataImportRequestBody = {
          dataSource: {
            ...buildDynamicDataSourceParamsPayloadByDataSourceConfig(dataSourceConfig),
            upxDataSourceId: dataSourceId,
          },
          auraCredentials: auraParams,
          importConfig: {
            ignoreErrors: true,
            threads: 1,
            batchSize: 5000,
          },
          importModelMetadata: {
            version: APP_VERSION,
            importModelId,
            dataModel: dataModel,
            visualisation: dehydrateVisualisationState(visualisation),
          },
        };

        return {
          url: '/v1/jobs',
          method: 'POST',
          body: useDynamicDataSource ? requestBodyWithDynamicDataSourceParams : requestBody,
        };
      },
      invalidatesTags: [CLOUD_IMPORT_JOB_TAG],
    }),
    getCloudImportJobs: builder.query<ImportJobsOverview[], void>({
      providesTags: (result, _, __) => {
        return result
          ? [...result.map(({ id }) => ({ type: CLOUD_IMPORT_JOB_TAG, id })), CLOUD_IMPORT_JOB_TAG]
          : [CLOUD_IMPORT_JOB_TAG];
      },
      query: () => '/v1/jobs',
      transformResponse: (data: ImportShared.ApiImportJobOverview[] | null): ImportJobsOverview[] =>
        (data ?? [])
          .sort((a, b) => sortByTimeDesc(a.submittedTime, b.submittedTime))
          .map(
            (item): ImportJobsOverview => ({
              submittedTime: parseTimeString(item.submittedTime),
              startTime: parseTimeString(item.startTime),
              id: item.id,
              statusSummary: getOverallStatus(item.status, item.result),
              completionTime: parseTimeString(item.completionTime),
              dataSourceMetadata: item.dataSourceMetadata,
              auraMetadata: item.auraMetadata,
            }),
          ),
    }),
  }),
});

export const { useSpawnNewImportJobMutation, useGetCloudImportJobsQuery } = cloudImportJobsSlice;
