import type { OrganizationCapabilities } from '../console.api.types';
import type { PLAN_TYPE } from './shared';

export enum ORGANIZATION_ROLE_NAME {
  ORG_OWNER = 'organization-owner',
  ORG_ADMIN = 'organization-admin',
  ORG_MEMBER = 'organization-member',
}

type OrgFeatureToggle =
  | 'console-flexible-storage-options'
  | 'console-gds-sessions'
  | 'enable_billing_account_for_console'
  | 'enable_organization_sso'
  | 'enable_seamless_connection'
  | 'console-custom-endpoints'
  | 'console-custom-endpoint-creation-assignment-capability'
  | 'enable_default_upx_opt_in'
  | 'plg-new-onboarding'
  | 'console-tier-upgrade';

export type OrgFeatureToggles = Record<OrgFeatureToggle, boolean>;

export type OrganizationProfile = 'internal' | 'external' | 'canary';

export interface Organization {
  id: string;
  ssoOrgId?: string;
  name: string;
  displayName: string;
  passwordLoginEnabled: boolean;
  googleLoginEnabled: boolean;
  createdAt?: string;
  planType: PLAN_TYPE;
  copilotEnabled: boolean;
  capabilities: OrganizationCapabilities;
  gdsSessions: {
    enabled: boolean;
    maxConcurrentSessions: number;
    maxMemoryPerSession: string;
  };
  featureToggles: OrgFeatureToggles;
  profile: OrganizationProfile;
  onboardingCompleted: boolean;
  trialEndTime?: string;
}

export type OrganizationRole = {
  id: string;
  userId: string;
  name: ORGANIZATION_ROLE_NAME;
  organizationId?: string;
};

export type OrganizationMember = {
  id: string;
  email: string;
  roles: OrganizationRole[];
  organizationId: string;
};

export type TrialCustomerInfo = {
  firstName?: string;
  lastName?: string;
  jobRole?: string;
  jobRoleOther?: string;
  primaryUseCase?: string;
  primaryUseCaseOther?: string;
  companyName?: string;
  primaryBusinessLocationCountry?: string;
  primaryBusinessLocationSubDivision?: string;
};

export type ProfilingInfo = {
  firstName?: string;
  lastName?: string;
  companyName?: string;
  jobRole?: string;
  jobRoleOther?: string;
  primaryUseCase?: string;
  primaryUseCaseOther?: string;
  onboardingIntent?: string;
  onboardingIntentOther?: string;
};

export type OrganizationSsoId = {
  id: string;
  ssoId: string;
};

export type InstanceSeamlessProfile = {
  id: string;
  name: string;
  seamlessConnectionEnabled: boolean;
  immutable: boolean;
  immutableReason: string | null;
};

export type ProjectSeamlessProfile = {
  id: string;
  name: string;
  defaultSeamlessConnectionEnabled: boolean;
  instances: InstanceSeamlessProfile[];
};

export type OrganizationSeamlessProfile = {
  id: string;
  name: string;
  projects: ProjectSeamlessProfile[];
};
