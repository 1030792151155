import { createSelector } from '@reduxjs/toolkit';
import { memoize } from 'lodash-es';

import type { Suggestion } from '../../../../modules/SearchBar/types';
import {
  getVisibleRelationshipTypes,
  selectCategoriesWithoutExcludedProperties,
  selectPropertyKeysForVisibleRelationshipTypes,
  selectVisiblePathSegments,
} from '../../../perspectives/perspectives';
import type { RootState } from '../../../types';
import {
  isFullTextSearchLockedSelector,
  isSearchPhraseLockedSelector,
  isSuggestionsTurnedOffSelector,
  selectFilter,
  selectLockedSuggestions,
} from '../../core/search-core.selectors';
import { filterSuggestions } from '../search-suggestions.utils';
import type { GraphPatternSuggestions } from './graph-pattern-suggestions';
import {
  EMPTY_GRAPH_PATTERN_SUGGESTIONS,
  buildGraphPatternSuggestions,
  sortGraphPatternSuggestions,
} from './graph-pattern-suggestions';

type GraphPatternSuggestionsData = {
  graphPatternSuggestions: GraphPatternSuggestions;
  filteredGraphPatternSuggestions: (Suggestion | Suggestion[])[];
};
export const selectGraphPatternSuggestions: (
  inputText: string,
) => (state: RootState) => ReturnType<(state: RootState, inputText: string) => GraphPatternSuggestionsData> = memoize(
  (
    inputText: string,
  ): ((state: RootState) => ReturnType<(state: RootState, inputText: string) => GraphPatternSuggestionsData>) =>
    createSelector(
      [
        selectLockedSuggestions,
        selectFilter,
        selectCategoriesWithoutExcludedProperties,
        getVisibleRelationshipTypes,
        selectVisiblePathSegments,
        selectPropertyKeysForVisibleRelationshipTypes,
        isSearchPhraseLockedSelector,
        isSuggestionsTurnedOffSelector,
        isFullTextSearchLockedSelector,
      ],
      (
        lockedSuggestions,
        filter,
        categories,
        relationshipTypes,
        pathSegments,
        relationshipPropertyKeys,
        isSearchPhraseLocked,
        isSuggestionsTurnedOff,
        isFullTextSearchLocked,
      ) => {
        if (isSearchPhraseLocked || isFullTextSearchLocked || isSuggestionsTurnedOff) {
          return {
            graphPatternSuggestions: EMPTY_GRAPH_PATTERN_SUGGESTIONS,
            filteredGraphPatternSuggestions: [],
          };
        }

        const graphPatternSuggestions = buildGraphPatternSuggestions({
          lockedSuggestions,
          categories,
          relationshipTypes,
          pathSegments,
          relationshipPropertyKeys,
        });
        const suggestionHead = lockedSuggestions.at(-1);
        const sorted = sortGraphPatternSuggestions(graphPatternSuggestions, suggestionHead);
        const filteredGraphPatternSuggestions = filterSuggestions(sorted, inputText.trim(), filter, true);
        return {
          graphPatternSuggestions,
          filteredGraphPatternSuggestions,
        };
      },
    ),
);
