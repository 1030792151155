export const ParamErrors = {
  ExtraStatementError: (count?: number) => ({
    code: 'Params.ExtraStatementError' as const,
    message: `Expected single return column, got ${count ?? 'multiple'}.`,
  }),
  UnknownArgument: (arg = '') => ({
    code: 'Params.UnknownArgumentError' as const,
    message: `Unknown :param argument${arg ? ` "${arg}".` : '.'}`,
  }),
  InvalidMultistatementArgument: {
    code: 'Params.InvalidMultistatementArgumentError',
    message: 'Multistatement only supports setting parameters.',
  },
  MissingParamName: {
    code: 'Params.MissingNameError',
    message: 'Parameter name missing.',
  },
  ParamNameStartsWithDollar: {
    code: 'Params.IllegalNameStartError',
    message: "Parameter names can't start with $.",
  },
  ParamNameIllegalCharacters: (paramName: string) => ({
    code: 'Params.IllegalCharactersInNameError' as const,
    message: `${
      paramName ? `${paramName} is not a legal param name.` : ''
    } Parameter names can only use letters, numbers, underscore (_) and $ unless escaped with backticks (\`).`,
  }),
  MissingExpression: {
    code: 'Params.MissingExpressionError',
    message: 'Parameter expression missing.',
  },
} as const;
