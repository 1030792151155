import { isNotNullish } from '@nx/stdlib';
import { format } from 'date-fns';

import type { UsageParams, UsageResponse } from '../console.api.types';
import type { Usage } from '../console.types';
import { transformUsageResponse } from '../transformers/usage';
import { type Builder } from './types';

const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'Z'";

export const usageEndpoints = (builder: Builder) => ({
  getUsage: builder.query<Usage, UsageParams>({
    query: (params) => {
      // Validate query params and build object
      const queryParams = {
        ...(isNotNullish(params.startDate) && { StartDate: encodeURI(format(params.startDate, DATE_FORMAT)) }),
        ...(isNotNullish(params.endDate) && { EndDate: encodeURI(format(params.endDate, DATE_FORMAT)) }),
        ...{ Page: params.page },
        ...{ PageSize: params.pageSize },
        ...{ SortBy: params.sortBy },
        ...{ SortOrder: params.sortOrder },
        ...{ Search: params.search },
      };

      return {
        url: `tenants/${params.projectId}/usage`,
        params: queryParams,
      };
    },
    transformResponse: (usage: UsageResponse) => transformUsageResponse(usage),
    providesTags: (result, error, params) => [{ type: 'Usage', id: params.projectId }],
  }),
});
