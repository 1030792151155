import { createAsyncThunk } from '@reduxjs/toolkit';
import { v1 as uuidv1 } from 'uuid';

import { log } from '../../services/logging';
import { labelColorPalette as defaultPalette } from '../../styles/colors';
import type { Perspective } from '../../types/perspective';
import { PerspectiveType } from '../../types/perspective';
import { isSharedStorageAvailable, sharedStorageApi } from '../shared-storage/shared-storage.api';
import type { RootState } from '../types';
import { getLatestPerspectiveVersion } from './migrations';
import { initialState as initialMetadataState } from './perspectiveMetadata';

// duplicated to resolve circular imports error with the reducer
const NAME = 'perspectives';

export const buildPerspective = (
  name: string,
  {
    dbmsId = null,
    dbmsVersion = null,
    dbId = null,
    dbName = null,
    isPlugin,
    parentPerspectiveId = null,
    id = uuidv1(),
    type = PerspectiveType.STANDARD,
  }: Pick<Perspective, 'dbmsId' | 'dbmsVersion' | 'dbId' | 'dbName' | 'isPlugin' | 'parentPerspectiveId' | 'type'> & {
    id?: string;
  },
): Perspective => {
  const creationTime = Date.now();
  return {
    name,
    id,
    categories: [],
    labels: {},
    relationshipTypes: [],
    palette: {
      colors: defaultPalette,
      currentIndex: 0,
    },
    createdAt: creationTime,
    lastEditedAt: creationTime,
    templates: [],
    sceneActions: [],
    hiddenRelationshipTypes: [],
    hideUncategorisedData: false,
    type,
    dbmsId,
    dbmsVersion,
    dbId,
    dbName,
    ...(isPlugin ? { isPlugin: true, history: [] } : {}),
    parentPerspectiveId,
    metadata: initialMetadataState,
    version: getLatestPerspectiveVersion(),
  };
};

type CreatePerspectivePayload = {
  perspectiveName: Perspective['name'];
  dbmsId: Perspective['dbmsId'];
  dbmsVersion: Perspective['dbmsVersion'];
  dbId: Perspective['dbId'];
  dbName: Perspective['dbName'];
  isPlugin: Perspective['isPlugin'];
  parentPerspectiveId: Perspective['parentPerspectiveId'];
  type?: Perspective['type'];
};
export const createPerspectiveThunk = createAsyncThunk<
  Perspective | null,
  CreatePerspectivePayload,
  { state: RootState }
>(
  `${NAME}/createPerspectiveThunk`,
  async (
    {
      perspectiveName,
      dbmsId,
      dbmsVersion,
      dbId,
      dbName,
      isPlugin,
      parentPerspectiveId,
      type = PerspectiveType.STANDARD,
    }: CreatePerspectivePayload,
    { dispatch },
  ) => {
    const perspective = buildPerspective(perspectiveName, {
      dbmsId,
      dbmsVersion,
      dbId: dbId ?? null,
      dbName: dbName ?? null,
      isPlugin,
      parentPerspectiveId,
      type,
    });

    if (isSharedStorageAvailable()) {
      const result = await dispatch(sharedStorageApi.endpoints.createPerspective.initiate(perspective));
      const id = result.data?.id;
      if (typeof id !== 'string') {
        log.error('Could not get perspective id from Shared Storage');
        return null;
      }
      perspective.id = id;
    }

    return perspective;
  },
);
