import { tokens } from '@neo4j-ndl/base';
import type { TextLinkProps } from '@neo4j-ndl/react';
import { Button, Menu, TextLink, Typography } from '@neo4j-ndl/react';
import {
  ArrowRightOnRectangleIconOutline,
  BeakerIconOutline,
  CheckIconOutline,
  ChevronDownIconOutline,
  Cog6ToothIconOutline,
  ExternalLinkIcon,
  KeyIconOutline,
  PaintBrushIconOutline,
  ShieldCheckIconOutline,
  ShieldExclamationIconOutline,
} from '@neo4j-ndl/react/icons';
import type { SerializedUser } from '@nx/state';
import { logout, selectConfiguration, useAppSelector, useAuth, useCapability, useSetting } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserAvatar } from '../components/user-avatar';

const UserInfo = ({ user }: { user: SerializedUser | null }) => {
  const name = user?.profile.name ?? 'Unknown name';
  const email = user?.profile.email ?? 'Unknown email';

  return (
    <div className="flex flex-col justify-center p-2">
      <Typography variant="subheading-small" className="max-w-60 overflow-hidden text-ellipsis whitespace-nowrap">
        {name}
      </Typography>
      <Typography
        variant="body-small"
        className="text-palette-neutral-text-weaker max-w-60 overflow-hidden text-ellipsis whitespace-nowrap"
      >
        {email}
      </Typography>
    </div>
  );
};

const MenuExternalLink = ({
  href,
  target,
  children,
  icon,
  showExternalLinkIcon = true,
}: {
  href: TextLinkProps['href'];
  target?: TextLinkProps['target'];
  children: React.ReactNode;
  icon?: React.ReactNode;
  showExternalLinkIcon?: boolean;
}) => {
  return (
    <TextLink
      href={href}
      className="hover:bg-palette-neutral-bg-default no-underline hover:no-underline"
      target={target}
    >
      <span className="flex w-full items-center gap-2.5 py-2 pl-2">
        <span className="text-palette-neutral-icon w-3.5">{isNotNullish(icon) ? icon : null}</span>
        <span className="text-palette-neutral-text-default flex w-full items-center justify-between text-sm">
          {children}
          {showExternalLinkIcon && <ExternalLinkIcon className="mr-2" />}
        </span>
      </span>
    </TextLink>
  );
};

const ExperienceMenu = () => {
  const {
    internal: { aura },
  } = useAppSelector(selectConfiguration);

  if (aura?.oldConsoleFrontendUrl === undefined) {
    return null;
  }

  return (
    <Menu title="Experience" description="New experience" icon={<BeakerIconOutline />}>
      <Menu.Items>
        <Menu.Item title="New experience" icon={<CheckIconOutline />} />
        <MenuExternalLink href={`${aura.oldConsoleFrontendUrl}?upx=false`} showExternalLinkIcon={false}>
          Old experience
        </MenuExternalLink>
      </Menu.Items>
    </Menu>
  );
};

const ThemeMenu = () => {
  const [theme, setTheme] = useSetting('global', 'theme');

  const getIcon = (option: string) => {
    return option === theme ? <CheckIconOutline /> : undefined;
  };

  return (
    <Menu title="Theme" icon={<PaintBrushIconOutline />}>
      <Menu.Items>
        <Menu.Item title="Light" onClick={() => setTheme('light')} icon={getIcon('light')} />
        <Menu.Item title="Dark" onClick={() => setTheme('dark')} icon={getIcon('dark')} />
        <Menu.Item title="System" onClick={() => setTheme('system')} icon={getIcon('system')} />
      </Menu.Items>
    </Menu>
  );
};

export const ProfileButton = () => {
  const [isThemeSwitcherOn] = useCapability('framework:theme-switcher');
  const buttonRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user } = useAuth();
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const navigate = useNavigate();

  const name = user?.profile.name ?? 'Unknown name';
  const picture = user?.profile.picture ?? undefined;

  return (
    <>
      <Button
        ref={buttonRef}
        fill="outlined"
        color="neutral"
        className="flex h-9 items-center gap-3 !px-2"
        onClick={toggleMenu}
        htmlAttributes={{
          'aria-label': 'User info',
          'aria-haspopup': 'true',
          'aria-expanded': isMenuOpen,
        }}
      >
        <UserAvatar name={name} picture={picture} />
        <Typography
          variant="subheading-small"
          className="hidden max-w-44 overflow-hidden text-ellipsis whitespace-nowrap md:block"
        >
          {name}
        </Typography>
        <ChevronDownIconOutline
          className="stroke-neutral-text-weaker ml-2 h-4 w-4 shrink-0"
          style={{
            transform: isMenuOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transitionDuration: tokens.transitions.values.duration.quick,
          }}
        />
      </Button>
      <Menu
        isOpen={isMenuOpen}
        onClose={toggleMenu}
        isPortaled={false}
        anchorRef={buttonRef}
        placement={'bottom-end-top-end'}
      >
        <Menu.Items>
          <UserInfo user={user} />
          <Menu.Divider />
          <Menu.Item
            title="Account settings"
            icon={<Cog6ToothIconOutline />}
            onClick={() => navigate('/account/profile')}
          />
          <Menu.Item title="API Keys" icon={<KeyIconOutline />} onClick={() => navigate('/account/api-keys')} />
          <Menu.Divider />
          <ExperienceMenu />
          {isThemeSwitcherOn && <ThemeMenu />}
          <Menu.Divider />
          <MenuExternalLink
            href="https://neo4j.com/privacy-policy/"
            target="_blank"
            icon={<ShieldExclamationIconOutline className="h-4 w-4" />}
          >
            Privacy
          </MenuExternalLink>
          <MenuExternalLink
            href="https://trust.neo4j.com/"
            target="_blank"
            icon={<ShieldCheckIconOutline className="h-4 w-4" />}
          >
            Trust center
          </MenuExternalLink>
          <Menu.Divider />
          <Menu.Item title="Log out" onClick={logout} icon={<ArrowRightOnRectangleIconOutline />} />
        </Menu.Items>
      </Menu>
    </>
  );
};
