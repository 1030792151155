import { Banner, Button, LoadingSpinner, Logo, TextInput } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { consoleApi, getApiError, getErrorMessage, isFetchBaseQueryError, login } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import type { SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const logger = createLogger(APP_SCOPE.framework);

export const OrganizationLookupPage = () => {
  const [loading, setLoading] = useState(true);
  const [queryParams] = useSearchParams();

  const queryError = queryParams.get('error_description');
  const queryOrgId = queryParams.get('org_id');
  const [getOrganizationSsoId, getOrganizationSsoIdRes] = consoleApi.useLazyGetOrganizationSsoIdQuery();

  const [orgId, setOrgId] = useState(queryOrgId ?? '');
  const handleOrgIdChange = (value: string) => {
    setOrgId(value);
  };

  const handleSubmit = async (event?: SyntheticEvent) => {
    event?.preventDefault();
    try {
      let ssoId = orgId;
      if (!orgId.startsWith('org_')) {
        const res = await getOrganizationSsoId(orgId).unwrap();
        ssoId = res.ssoId;
      }
      void login(orgId, { extraQueryParams: { organization: ssoId, screen_hint: 'signin' } });
    } catch (e) {
      if (isFetchBaseQueryError(e)) {
        // Error messages are displayed from the response so we don't need to handle them here
        const apiError = getApiError(e);
        const message = getErrorMessage(apiError);
        logger.error(message);
      } else {
        logger.error(e);
      }
    }
  };

  useEffect(() => {
    if (isNullish(queryError) && isNotNullish(queryOrgId) && queryParams.get('autosubmit') === 'true') {
      void handleSubmit();
    } else {
      // Hide loading when not auto-submitting
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div className="m-auto">
        <LoadingSpinner size="medium" />
      </div>
    );
  }

  const error = getOrganizationSsoIdRes.error
    ? getErrorMessage(getApiError(getOrganizationSsoIdRes.error))
    : queryError;

  return (
    <div className="mx-auto flex justify-center">
      <div className="bg-palette-neutral-bg-weak mt-12 flex h-fit w-[500px] flex-1 flex-col gap-4 rounded-md p-8 drop-shadow-md">
        <Logo width={100} height={32} />

        <h5>Login with organization SSO</h5>
        <form
          onSubmit={(e) => {
            void handleSubmit(e);
          }}
          className="flex flex-col gap-4"
        >
          <TextInput
            value={orgId}
            onChange={(e) => handleOrgIdChange(e.target.value)}
            label="Organization ID"
            isFluid
            htmlAttributes={{
              'data-testid': 'organization-id-input',
              placeholder: 'Enter Organization ID',
            }}
          />
          {isNotNullish(error) && <Banner type="danger" title="An error occurred" description={error} usage="inline" />}
          <div className="flex justify-end gap-2">
            <Button as="a" href="/" fill="outlined">
              Cancel
            </Button>
            <Button
              type="submit"
              isDisabled={!orgId}
              isLoading={getOrganizationSsoIdRes.isLoading}
              htmlAttributes={{
                'data-testid': 'organization-submit-button',
              }}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
