import { TextInput } from '@neo4j-ndl/react';
import cn from 'classnames';
import type { ChangeEvent, ComponentType } from 'react';
import { useEffect, useState } from 'react';
import type { ColorChangeHandler, HEXColor, HSLColor, HSVColor, RGBColor } from 'react-color';
import { CustomPicker } from 'react-color';
import Hue from 'react-color/lib/components/common/Hue';
import Saturation from 'react-color/lib/components/common/Saturation';

import { Dot } from './dot';

type ColorPickerProps = {
  rgb: RGBColor;
  hex: HEXColor;
  hsl: HSLColor;
  hsv: HSVColor;
  oldHue: number;
  onChange: ColorChangeHandler;
  onInputChange: (
    rgb: RGBColor,
  ) => (value: Partial<{ HEX: string; R: string; G: string; B: string }>, evt: ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
  color: string;
  className?: string;
} & ComponentType<HTMLDivElement>;

const HuePointer = () => (
  <div className="bg-palette-neutral-bg-weak h-6 w-3 translate-x-[-0.5em] rounded-sm shadow-[0_1px_4px_0_rgba(0,0,0,0.37)]" />
);

const ColorPicker = ({
  rgb,
  hex,
  hsl,
  hsv,
  oldHue,
  onInputChange,
  disabled,
  color,
  onChange,
  className,
  ...rest
}: ColorPickerProps) => {
  const [localHex, setLocalHex] = useState('');
  const [localR, setLocalR] = useState(0);
  const [localG, setLocalG] = useState(0);
  const [localB, setLocalB] = useState(0);

  useEffect(() => {
    setLocalHex(hex.hex);
    setLocalR(rgb.r);
    setLocalG(rgb.g);
    setLocalB(rgb.b);
  }, [rgb, hex]);

  useEffect(() => {
    setLocalHex(color);
    setLocalR(rgb.r);
    setLocalG(rgb.g);
    setLocalB(rgb.b);
  }, [color, rgb]);

  return (
    <div className={cn('relative pt-6', className)} {...rest}>
      <div className="relative mb-2 h-[228px] overflow-hidden rounded-t-[2px]">
        <Saturation hsl={hsl} hsv={hsv} oldHue={oldHue} onChange={onChange} />
      </div>
      <div className="mb-2 flex">
        <div className="relative mr-3 h-6 flex-1">
          <Hue hsl={hsl} hsv={hsv} oldHue={oldHue} onChange={onChange} pointer={HuePointer} />
        </div>
        <Dot color={localHex} />
      </div>
      <div className="flex flex-row gap-1" data-testid="color-selector">
        <TextInput
          className="flex-1"
          isDisabled={disabled}
          value={localHex}
          size="small"
          isFluid
          onChange={(evt) => {
            setLocalHex(evt.target.value);
            onInputChange(rgb)({ HEX: evt.target.value }, evt);
          }}
          label="HEX"
        />
        <TextInput
          className="w-[72px]"
          isDisabled={disabled}
          size="small"
          value={localR}
          isFluid
          onChange={(evt) => {
            setLocalR(Number(evt.target.value));
            onInputChange(rgb)({ R: evt.target.value }, evt);
          }}
          label="R"
          htmlAttributes={{
            type: 'number',
            'aria-label': 'Red',
            min: 0,
            max: 255,
          }}
        />
        <TextInput
          className="w-[72px]"
          isDisabled={disabled}
          size="small"
          value={localG}
          isFluid
          onChange={(evt) => {
            setLocalG(Number(evt.target.value));
            onInputChange(rgb)({ G: evt.target.value }, evt);
          }}
          label="G"
          htmlAttributes={{
            type: 'number',
            'aria-label': 'Green',
            min: 0,
            max: 255,
          }}
        />
        <TextInput
          isDisabled={disabled}
          size="small"
          className="w-[72px]"
          isFluid
          value={localB}
          onChange={(evt) => {
            setLocalB(Number(evt.target.value));
            onInputChange(rgb)({ B: evt.target.value }, evt);
          }}
          label="B"
          htmlAttributes={{
            type: 'number',
            'aria-label': 'Blue',
            min: 0,
            max: 255,
          }}
        />
      </div>
      {disabled && (
        <div
          className="absolute inset-x-0 bottom-[-24px] top-0 cursor-not-allowed"
          data-testid="color-picker-disabled"
          onClick={(ev) => {
            ev.stopPropagation();
          }}
        />
      )}
    </div>
  );
};
export default CustomPicker(ColorPicker);
