import { Banner, Button, Dialog, TextInput } from '@neo4j-ndl/react';
import { APP_SCOPE, QUERY_TYPE } from '@nx/constants';
import { useConnection, useDeferredCypherQuery, useModalClose, useNotificationActions } from '@nx/state';
import type { SerializedError } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { useRef, useState } from 'react';

enum OPERATION_ERROR {
  INVALID_CREDENTIALS,
  UNKNOWN,
}

type HTMLInputChangeHandler = React.ChangeEventHandler<HTMLInputElement>;

type FormValidator = () => boolean;

type FormData = {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

type FormErrors = {
  currentPassword?: string;
  newPassword?: string;
  newPasswordConfirmation?: string;
};

const OPERATION_ERROR_MESSAGE = {
  [OPERATION_ERROR.INVALID_CREDENTIALS]: 'The existing password you’ve entered was incorrect',
  // TODO: Change to more explicit “Unknown error” message
  [OPERATION_ERROR.UNKNOWN]: 'Unknown error',
};

const INITIAL_STATE: FormData = {
  currentPassword: '',
  newPassword: '',
  newPasswordConfirmation: '',
};

function toOperationError(error: SerializedError): OPERATION_ERROR {
  if (
    error.name === 'Neo4jError' &&
    error.code === 'Neo.ClientError.Statement.ArgumentError' &&
    error.message !== undefined &&
    error.message.includes('Invalid principal or credentials')
  ) {
    return OPERATION_ERROR.INVALID_CREDENTIALS;
  }

  return OPERATION_ERROR.UNKNOWN;
}

function validateInput(formData: FormData): FormErrors | null {
  if (!formData.currentPassword) {
    return { currentPassword: 'Existing password is required' };
  }

  if (!formData.newPassword) {
    return { newPassword: 'New password is required' };
  }

  if (formData.newPassword.length < 8) {
    return { newPassword: 'New password must be at least 8 characters long' };
  }

  if (formData.currentPassword === formData.newPassword) {
    return { newPassword: 'Old password and new password cannot be the same' };
  }

  if (formData.newPassword !== formData.newPasswordConfirmation) {
    return { newPasswordConfirmation: 'Confirmation does not match the password' };
  }

  return null;
}

// TODO: Make reusable?
function useFormState(): [FormData, FormErrors, HTMLInputChangeHandler, FormValidator] {
  const [formData, setFormData] = useState<FormData>(INITIAL_STATE);
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const handleChange: HTMLInputChangeHandler = (event) => {
    setFormData((state) => {
      if (event.target.name in formData) {
        return { ...state, [event.target.name]: event.target.value };
      }

      return state;
    });

    setFormErrors((state) => {
      if (event.target.name in formData) {
        return { ...state, [event.target.name]: undefined };
      }

      return state;
    });
  };

  const handleValidate = () => {
    const validationResult = validateInput(formData);

    if (validationResult !== null) {
      setFormErrors(validationResult);
      return false;
    }

    return true;
  };

  return [formData, formErrors, handleChange, handleValidate];
}

export function PasswordChangeDialog({ passwordChangeRequired }: { passwordChangeRequired: boolean }) {
  const { disconnect, clearConnectionError, metadata } = useConnection();
  const formRef = useRef<HTMLFormElement>(null);
  const [formData, formErrors, handleChange, handleValidate] = useFormState();
  const [operationError, setOperationError] = useState<OPERATION_ERROR | null>(null);
  const { addNotification } = useNotificationActions();
  const closeModal = useModalClose();

  const [changePasswordResponse, changePassword] = useDeferredCypherQuery(
    {
      query: 'ALTER CURRENT USER SET PASSWORD FROM $currentPassword TO $newPassword',
    },
    {
      metadata: { appScope: APP_SCOPE.framework, queryType: QUERY_TYPE.UserAction },
      sessionConfig: { database: 'system' },
      transactionMode: 'write',
    },
  );

  const reconnectRequired = passwordChangeRequired || Boolean(metadata?.protocol.startsWith('http'));

  function handleClose() {
    if (passwordChangeRequired) {
      clearConnectionError();
    }
    closeModal();
  }

  function handleSubmit() {
    if (handleValidate()) {
      const resultPromise = changePassword({
        currentPassword: formData.currentPassword,
        newPassword: formData.newPassword,
      });

      resultPromise
        .unwrap()
        .then(() =>
          addNotification({
            type: 'success',
            title: 'Password changed',
            description: reconnectRequired ? 'Reconnect to this instance to continue' : '',
            actionCallbacks: reconnectRequired ? ['reconnect'] : undefined,
          }),
        )
        .then(() => {
          if (reconnectRequired) {
            return disconnect();
          }
          return undefined;
        })
        .then(() => handleClose())
        .catch((rawError: Error) => {
          const error = toOperationError(rawError);
          setOperationError(error);

          if (error === OPERATION_ERROR.UNKNOWN) {
            Sentry.captureException(rawError);
          }
        });
    }
  }

  return (
    <Dialog isOpen onClose={handleClose}>
      <Dialog.Header>Change database user password</Dialog.Header>
      <form
        ref={formRef}
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
      >
        <Dialog.Content>
          {passwordChangeRequired ? (
            <Banner
              className="mb-4"
              title="Password expired"
              description="Set new password to continue using this instance"
              type="warning"
              hasIcon
              usage="inline"
            />
          ) : null}
          {operationError !== null ? (
            <Banner
              className="mb-4"
              title="Failed to change the password"
              description={OPERATION_ERROR_MESSAGE[operationError]}
              type="danger"
              hasIcon
              usage="inline"
            />
          ) : null}
          <div className="w-full space-y-6">
            <TextInput
              size="large"
              errorText={formErrors.currentPassword}
              isFluid
              value={formData.currentPassword}
              onChange={handleChange}
              label="Existing password"
              htmlAttributes={{
                type: 'password',
                'aria-label': 'Existing password',
                autoComplete: 'current-password',
                autoFocus: true,
                name: 'currentPassword',
              }}
            />
            <TextInput
              size="large"
              errorText={formErrors.newPassword}
              isFluid
              value={formData.newPassword}
              onChange={handleChange}
              label="New password"
              htmlAttributes={{
                type: 'password',
                'aria-label': 'New password',
                autoComplete: 'new-password',
                name: 'newPassword',
              }}
            />
            <TextInput
              size="large"
              errorText={formErrors.newPasswordConfirmation}
              isFluid
              value={formData.newPasswordConfirmation}
              onChange={handleChange}
              label="New password confirmation"
              htmlAttributes={{
                type: 'password',
                'aria-label': 'New password confirmation',
                autoComplete: 'new-password',
                name: 'newPasswordConfirmation',
              }}
            />
          </div>
        </Dialog.Content>
        <Dialog.Actions>
          <Button isLoading={changePasswordResponse.fetching} type="submit">
            Change password
          </Button>
        </Dialog.Actions>
      </form>
    </Dialog>
  );
}
