import { Button, Typography } from '@neo4j-ndl/react';

import BarrierIcon from './assets/barrier.svg';

export function UpxErrorPage({ eventId }: { eventId: string }) {
  return (
    <div className="m-auto flex h-full flex-col items-center justify-center gap-16 p-8 md:flex-row">
      <img src={BarrierIcon} alt="reload" draggable="false" height="170" />
      <div className="flex max-w-96 flex-col gap-6">
        <div className="flex flex-col gap-4">
          <Typography variant="h4" className="font-syne">
            An unknown error occurred
          </Typography>
          <Typography variant="body-large" className="text-neutral-text-weak" as="div">
            We apologize for the inconvenience. Please reload the application or contact support if the issue persists.
          </Typography>
          <Typography variant="body-small" className="text-neutral-text-weak" as="div">
            Sentry event ID: {eventId}
          </Typography>
        </div>
        <div className="flex gap-4">
          <Button onClick={() => document.location.reload()}>Reload page</Button>
          <Button as="a" htmlAttributes={{ href: 'https://support.neo4j.com/s/', target: '_blank' }} fill="text">
            Contact support
          </Button>
        </div>
      </div>
    </div>
  );
}
