import { Button, Dialog, Select, Typography } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { consoleApi, getApiError, getErrorMessage, useActiveOrg, useNotifications } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useState } from 'react';

import { maxConcurrentSessionOptions } from './options';

const logger = createLogger(APP_SCOPE.framework);

interface Props {
  onClose: () => void;
}

export const EnableGdsSessionsModal = ({ onClose }: Props) => {
  const { addNotification } = useNotifications();
  const [selectedMaxMemoryPerSession, setSelectedMaxMemoryPerSession] = useState({ value: '16GB', label: '16GB' });
  const [selectedMaxConcurrentSessions, setSelectedMaxConcurrentSessions] = useState({ value: 100, label: '100' });

  const activeOrg = useActiveOrg();

  const [updateOrg] = consoleApi.useUpdateOrganizationMutation();

  const gdsOfferingsQuery = consoleApi.useGetGdsOfferingsQuery();

  const sizingOptions = gdsOfferingsQuery.isSuccess
    ? gdsOfferingsQuery.data.offerings.map(({ offering }) => ({ label: offering, value: offering }))
    : [];

  const handleEnableGdsSessions = () => {
    updateOrg({
      organizationId: activeOrg.id,
      gdsSessionsEnabled: true,
      gdsSessionsMaxMemoryPerSession: selectedMaxMemoryPerSession.value,
      gdsSessionsMaxConcurrentSessions: selectedMaxConcurrentSessions.value,
    })
      .unwrap()
      .catch((e: FetchBaseQueryError | SerializedError) => {
        const error = getApiError(e);
        if (isNotNullish(error.message)) {
          logger.error(error.message);
        }
        addNotification({
          type: 'danger',
          title: `Failed to enable GDS Sessions`,
          description: getErrorMessage(error),
          timeout: 5000,
        });
      });
    // deliberately close before promise resolved
    onClose();
  };

  return (
    <Dialog isOpen onClose={onClose}>
      <Dialog.Header>Enable Sessions</Dialog.Header>
      <Dialog.Content>
        <Typography variant="h6" className="mb-4 font-bold">
          Limits
        </Typography>
        <div className="flex flex-col gap-y-4">
          <Select
            type="select"
            size="medium"
            selectProps={{
              menuPosition: 'fixed',
              placeholder: 'Select',
              options: sizingOptions,
              value: selectedMaxMemoryPerSession,
              onChange: (value) => {
                if (value) {
                  setSelectedMaxMemoryPerSession(value);
                }
              },
            }}
            label="Max memory per session"
            htmlAttributes={{
              'aria-label': 'Dropdown menu for Max memory per session',
            }}
          />

          <Select
            type="select"
            size="medium"
            isDisabled={false}
            selectProps={{
              menuPosition: 'fixed',
              placeholder: 'Select',
              options: maxConcurrentSessionOptions,
              value: selectedMaxConcurrentSessions,
              onChange: (value) => {
                if (value) {
                  setSelectedMaxConcurrentSessions(value);
                }
              },
            }}
            label="Max concurrent sessions in organization"
            htmlAttributes={{
              'aria-label': 'Dropdown menu for Max concurrent sessions in organization',
            }}
          />
        </div>
      </Dialog.Content>
      <Dialog.Actions>
        <Button fill="outlined" color="neutral" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleEnableGdsSessions}>
          Confirm
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
