import { DataGrid, StatusIndicator, Typography } from '@neo4j-ndl/react';
import { ChevronDownIconSolid } from '@neo4j-ndl/react/icons';
import { DataGridHelpers, SearchField } from '@nx/ui';
import { createColumnHelper, getFilteredRowModel } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import { useCommonExpandedTable } from '../../components/table';
import { useProjectUsers } from '../contexts';
import { type DbRole, ROLE_TYPE, type User } from '../entities/model';
import { ExpandableBodyRow, ExpandableCell, LastActive, RoleLabel, UserRowAction } from './common';
import { mapUserToCommonUserRow } from './helpers';
import type { CommonUserRow } from './types';

type UserRow = CommonUserRow & {
  dbRole: DbRole;
};

type InstanceRow = {
  dbId: string;
  dbName: string;
  users: UserRow[];
};

type TableRow = {
  instance: InstanceRow | undefined;
  user: UserRow | undefined;
};

const groupUsersByInstance = (users: User[]): TableRow[] => {
  const groupedUsers: Record<DbRole['dbId'], InstanceRow> = {};
  users.forEach((user) =>
    user.dbRoles.forEach((dbRole) => {
      groupedUsers[dbRole.dbId] ??= { dbId: dbRole.dbId, dbName: dbRole.dbName, users: [] };
      groupedUsers[dbRole.dbId]?.users.push({
        ...mapUserToCommonUserRow(user),
        dbRole,
      });
    }),
  );
  return Object.values(groupedUsers).map((instanceRow) => ({ instance: instanceRow, user: undefined }));
};

const columnHelper = createColumnHelper<TableRow>();

const columns = [
  columnHelper.accessor('instance.dbName', {
    header: () => 'Instance / User',
    cell: (cx) => {
      return cx.row.getCanExpand() ? (
        <ExpandableCell cx={cx}>
          <div className="flex items-center gap-2">
            <StatusIndicator type="success" />
            <Typography variant="subheading-medium">{cx.getValue()}</Typography>
          </div>
        </ExpandableCell>
      ) : (
        cx.row.original.user?.email
      );
    },
  }),
  columnHelper.accessor('user.dbRole.name', {
    header: () => 'Database role',
    cell: (cx) =>
      !cx.row.getCanExpand() && (
        <div className="flex w-full justify-between gap-2">
          <RoleLabel roleType={ROLE_TYPE.DB}>{cx.getValue()}</RoleLabel>
          <ChevronDownIconSolid className="size-6 min-w-6" />
        </div>
      ),
  }),
  columnHelper.accessor('user.metadata', {
    header: () => 'Metadata',
  }),
  columnHelper.accessor('user.lastActive', {
    header: () => 'Last active',
    cell: (cx) => !cx.row.getCanExpand() && <LastActive user={cx.row.original.user} />,
  }),
  columnHelper.display({
    id: 'actions',
    cell: (cx) => <UserRowAction user={cx.row.original.user} cx={cx} />,
    meta: {
      isActionCell: {
        actions: [
          {
            title: 'User action',
            onClick: () => {
              // eslint-disable-next-line no-alert
              alert('Perform user action...');
            },
          },
        ],
      },
    },
  }),
];

export function ProjectUsersByInstance() {
  const { users } = useProjectUsers();
  const data = useMemo(() => groupUsersByInstance(users), [users]);
  const [globalFilter, setGlobalFilter] = useState('');
  const table = useCommonExpandedTable({
    columns,
    data,
    getRowCanExpand: (row) => Boolean(row.original.instance),
    getSubRows: (row) => row.instance?.users.map((user) => ({ instance: undefined, user })),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    state: {
      globalFilter,
    },
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => table.toggleAllRowsExpanded(true), []);

  return (
    <DataGridHelpers.Wrapper tabbed>
      <DataGridHelpers.OuterHeader>
        <div className="flex w-full flex-wrap justify-between gap-2">
          <div className="flex basis-[400px] gap-2">
            <SearchField
              className="min-w-36 grow"
              onChange={(e) => setGlobalFilter(e.target.value)}
              htmlAttributes={{
                'aria-label': 'Filter users',
              }}
            />
          </div>
        </div>
      </DataGridHelpers.OuterHeader>
      <DataGrid
        tableInstance={table}
        components={{ BodyRow: ExpandableBodyRow }}
        styling={{
          headerStyle: 'clean',
          borderStyle: 'all-sides',
        }}
        isKeyboardNavigable={false}
      />
    </DataGridHelpers.Wrapper>
  );
}
