import { isNotNullish } from '@nx/stdlib';

import type {
  CreateCustomEndpointBody,
  CustomEndpointDetailsResponse,
  CustomEndpointParam,
  CustomEndpointResponse,
  UpdateCustomEndpointRequest,
} from '../console.api.types';
import type { CustomEndpoint, CustomEndpointDetails } from '../console.types';
import {
  transformCustomEndpointDetailsResponse,
  transformCustomEndpointResponse,
} from '../transformers/custom-endpoints';
import { type Builder, LIST_ID } from './types';

// Well the naming praxis here is a bit unfortunate
export const customEndpointsEndpoints = (builder: Builder) => ({
  createCustomEndpoint: builder.mutation<CustomEndpoint, CreateCustomEndpointBody>({
    query: ({ dbId, name }) => ({
      url: 'custom-endpoints',
      method: 'POST',
      body: {
        dbid: dbId,
        name,
      },
    }),
    transformResponse: (customEndpoint: CustomEndpointResponse) => transformCustomEndpointResponse(customEndpoint),
    invalidatesTags: (result) => [
      { type: 'CustomEndpoint', id: result?.id },
      { type: 'CustomEndpointDetails', id: result?.id },
      { type: 'CustomEndpointDetails', id: LIST_ID },
    ],
  }),
  getCustomEndpoint: builder.query<CustomEndpoint, string>({
    query: (id) => `custom-endpoints/${id}`,
    transformResponse: (customEndpoint: CustomEndpointResponse) => transformCustomEndpointResponse(customEndpoint),
    providesTags: (result, error, id) => [{ type: 'CustomEndpoint', id }],
  }),
  listCustomEndpointByInstance: builder.query<CustomEndpoint[], string>({
    query: (id) => `databases/${id}/custom-endpoints`,
    transformResponse: (customEndpoints: CustomEndpointResponse[]) =>
      customEndpoints.map((customEndpoint) => transformCustomEndpointResponse(customEndpoint)),
    providesTags: (result, error) => {
      if (isNotNullish(result)) {
        return [
          ...result.map(({ id, dbId }) => ({ type: 'CustomEndpoint' as const, id })),
          { type: 'CustomEndpoint', id: LIST_ID },
        ];
      }
      return [{ type: 'CustomEndpoint', id: LIST_ID }];
    },
  }),
  listCustomEndpointsByProject: builder.query<CustomEndpointDetails[], string>({
    query: (projectId) => `namespaces/${projectId}/custom-endpoints`,
    transformResponse: (customEndpoints: CustomEndpointDetailsResponse[]) =>
      customEndpoints.map((customEndpoint) => transformCustomEndpointDetailsResponse(customEndpoint)),
    providesTags: (result, error, projectId) => {
      if (isNotNullish(result)) {
        return [
          ...result.map(({ id }) => ({ type: 'CustomEndpointDetails' as const, id })),
          { type: 'CustomEndpointDetails', id: LIST_ID },
        ];
      }
      return [{ type: 'CustomEndpointDetails', id: LIST_ID }];
    },
  }),
  deleteCustomEndpoint: builder.mutation<CustomEndpoint, CustomEndpointParam>({
    query: ({ id }) => ({ url: `custom-endpoints/${id}`, method: 'DELETE' }),
    invalidatesTags: (result, error, { id }) => [
      { type: 'CustomEndpoint', id },
      { type: 'CustomEndpointDetails', id },
    ],
  }),
  updateCustomEndpoint: builder.mutation<CustomEndpoint, UpdateCustomEndpointRequest>({
    query: ({ id, dbId }) => ({
      url: `custom-endpoints/${id}`,
      method: 'PATCH',
      body: {
        dbid: dbId,
      },
    }),
    transformResponse: (customEndpoint: CustomEndpointResponse) => transformCustomEndpointResponse(customEndpoint),
    invalidatesTags: (result) => [
      { type: 'CustomEndpoint', id: result?.id },
      { type: 'CustomEndpointDetails', id: result?.id },
    ],
  }),
});
