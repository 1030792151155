export enum GUIDES_HTML_TAGS {
  DIV = 'DIV',
  TABLE = 'TABLE',
  THEAD = 'THEAD',
  TBODY = 'TBODY',
  TR = 'TR',
  P = 'P',
  UL = 'UL',
  OL = 'OL',
  LI = 'LI',
  DL = 'DL',
  DT = 'DT',
  DD = 'DD',
  IMG = 'IMG',
  A = 'A',
  CODE = 'CODE',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
  SPAN = 'SPAN',
  EM = 'EM',
  STRONG = 'STRONG',
  KBD = 'KBD',
  DETAILS = 'DETAILS',
  SUMMARY = 'SUMMARY',
  TEXT = '#text',
}

export enum GUIDES_CUSTOM_TAGS {
  CODEBLOCK = 'CODEBLOCK',
  INLINECODE = 'INLINECODE',
  LISTINGBLOCK = 'LISTINGBLOCK',
  CONTENT = 'CONTENT',
  NOTE = 'NOTE',
  TIP = 'TIP',
  IMPORTANT = 'IMPORTANT',
  BUTTON = 'BUTTON',
  CAUTION = 'CAUTION',
  WARNING = 'WARNING',
  ICON = 'ICON',
  PAGELAUNCH = 'PAGELAUNCH',
  INLINEBUTTON = 'INLINEBUTTON',
  HIGHLIGHT = 'HIGHLIGHT',
}

export enum GUIDES_ACTIONS {
  NX_IMPORT_LOAD = 'NX_IMPORT_LOAD',
  NX_IMPORT_ADD_NODE = 'NX_IMPORT_ADD_NODE',
  NX_IMPORT_PREVIEW = 'NX_IMPORT_PREVIEW',
  NX_IMPORT_RUN = 'NX_IMPORT_RUN',
  NX_EXPLORE_SEARCH = 'NX_EXPLORE_SEARCH',
  NX_TAB_NAV = 'NX_TAB_NAV',
  NX_HIGHLIGHT = 'NX_HIGHLIGHT',
  NX_HIGHLIGHT_REMOVE = 'NX_HIGHLIGHT_REMOVE',
}
