import type { Scene } from '../../../types/scene';
import type { LoadedScenes, SerializedScene, StorageScene } from '../api-types/scene';
import {
  transformCreateScenePayload,
  transformGetSceneResult,
  transformUpdateScenePayload,
} from '../transformers/scene';
import type { Builder } from '../types';

export const sceneEndpoints = (builder: Builder) => ({
  getScenes: builder.query<LoadedScenes, void>({
    query: () => ({ url: 'explore-scenes', method: 'GET' }),
    transformResponse: (scenes: SerializedScene[]) => transformGetSceneResult(scenes),
  }),
  createScene: builder.mutation<{ id: string }, StorageScene>({
    query: (scene) => ({
      url: 'explore-scenes/',
      method: 'POST',
      body: transformCreateScenePayload(scene),
    }),
  }),
  updateScene: builder.mutation<void, { sceneId: Scene['id']; update: Partial<StorageScene> }>({
    query: ({ sceneId, update }) => ({
      url: `explore-scenes/${sceneId}`,
      method: 'PUT',
      body: transformUpdateScenePayload(update),
    }),
  }),
  deleteScene: builder.mutation<{ id: string }, string>({
    query: (sceneId) => ({
      url: `explore-scenes/${sceneId}`,
      method: 'DELETE',
    }),
  }),
});
