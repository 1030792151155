import { PROJECT_BILLING_METHOD } from '@nx/state';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useProjectAppContext } from '../../app-context';
import type { TabKey } from './layout';
import { BillingLayout } from './layout';
import { Invoices, Settings, Usage } from './tabs';

/** ensures type safe route paths */
const path = (typedPath: TabKey | `${TabKey}/*`) => typedPath;

export function BillingRoutes() {
  const { activeProject } = useProjectAppContext();
  const { hasBilling, billingMethod } = activeProject;
  const hasPrePaidBilling = billingMethod === PROJECT_BILLING_METHOD.PREPAID;

  return (
    <Routes>
      <Route path="/*" element={<BillingLayout project={activeProject} />}>
        {(hasBilling || hasPrePaidBilling) && (
          // Always show Usage page if some form of billing
          <>
            <Route path="*" element={<Navigate to={'usage' satisfies TabKey} replace />} />

            {/* USAGE */}
            <Route path={path('usage')}>
              <Route path="" element={<Usage project={activeProject} />} />
            </Route>

            {/* SETTINGS */}
            <Route path={path('settings')}>
              <Route path="" element={<Settings projectId={activeProject.id} />} />
            </Route>

            {!hasPrePaidBilling && (
              <Route path={path('invoices')}>
                <Route path="" element={<Invoices projectId={activeProject.id} />} />
              </Route>
            )}
          </>
        )}
      </Route>
    </Routes>
  );
}
