import { tokens } from '@neo4j-ndl/base';
import type { DialogProps } from '@neo4j-ndl/react';
import { Banner, Button, Dialog, TextInput, Typography } from '@neo4j-ndl/react';
import { ChevronDownIconOutline } from '@neo4j-ndl/react/icons';
import type { TimeRange } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import classNames from 'classnames';
import { produce } from 'immer';
import { isEqual } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

import { timePeriodFormatter } from '../../../../shared/ui-helpers';
import { FilterDropdown } from './filter-dropdown';
import type { TimeRangeSelectorProps } from './time-range-selector';
import TimeRangeSelector from './time-range-selector';

type FieldBase<T> = {
  id: string;
  label: string;
  value: T;
  defaultValue: T;
};

export type FilterField = FieldBase<string[]> & {
  values: string[];
  valueFormatter: (v: string) => string;
};

export type FilterFieldsNormalized = Record<FilterField['id'], FilterField>;

export type SearchField = FieldBase<string> & {
  placeholder: string;
};

export type NumericField = FieldBase<number>;

export type RequestDialogProps = Omit<DialogProps, 'children'> & {
  onClose: () => void;
  onAccept: (fields: FilterFieldsNormalized, search: SearchField[], numericFields: NumericField[]) => void;
  onLoadFilters: () => void;
  filterError: FetchBaseQueryError | SerializedError | undefined;
  header: string;
  isLoading: boolean;
  hasQueries: boolean;
  searchFields: SearchField[];
  filterFields: FilterFieldsNormalized;
  numericFields?: NumericField[];
  timeRange: TimeRange;
  timeRangeSelectorProps?: TimeRangeSelectorProps;
  showFiltersDefault?: boolean;
};

const ERROR_MESSAGE = 'There was an error fetching the available values';

export const RequestDialog = ({
  onClose,
  onAccept,
  onLoadFilters,
  header,
  filterError,
  isLoading,
  hasQueries,
  searchFields,
  filterFields,
  numericFields,
  timeRange,
  timeRangeSelectorProps,
  modalProps,
  showFiltersDefault = false,
  ...props
}: RequestDialogProps) => {
  const [showFilters, setShowFilters] = useState(showFiltersDefault);

  const [localFilterFields, setLocalFilterFields] = useState(filterFields);
  const [localSearchFields, setLocalSearchFields] = useState(searchFields);
  const [localNumericFields, setLocalNumericFields] = useState(numericFields ?? []);

  const fieldResets = useMemo(() => {
    const filterFieldsReset = produce(filterFields, (draft) => {
      Object.values(draft).forEach((field) => {
        field.value = field.defaultValue;
      });
    });
    const searchFieldsReset = produce(searchFields, (draft) => {
      draft.forEach((field) => {
        field.value = field.defaultValue;
      });
    });
    const numericFieldsReset = produce(numericFields ?? [], (draft) => {
      draft.forEach((field) => {
        field.value = field.defaultValue;
      });
    });
    return { filterFields: filterFieldsReset, searchFields: searchFieldsReset, numericFields: numericFieldsReset };
  }, [filterFields, searchFields, numericFields]);

  const onToggleShowFilters = () => {
    const nextShowFilters = !showFilters;
    setShowFilters(nextShowFilters);
    if (nextShowFilters) {
      onLoadFilters();
    }
  };

  const handleResetClick = () => {
    setLocalFilterFields(fieldResets.filterFields);
    setLocalSearchFields(fieldResets.searchFields);
    setLocalNumericFields(fieldResets.numericFields);
  };

  const handleFetchClick = () => {
    const sanitizedSearchFields = localSearchFields.map((field) => ({ ...field, selected: field.value.trim() }));
    onClose();
    onAccept(localFilterFields, sanitizedSearchFields, localNumericFields);
  };

  useEffect(() => {
    if (!isEqual(filterFields, localFilterFields)) {
      setLocalFilterFields(filterFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterFields]);

  const availableDbmsFiltersError = filterError && ERROR_MESSAGE;

  return (
    <Dialog
      {...props}
      modalProps={{
        'data-testid': 'query-logs-filters-modal',
        ...modalProps,
        className: classNames('mt-12 align-top mx-auto', modalProps?.className),
      }}
      onClose={onClose}
    >
      <Dialog.Header>{header}</Dialog.Header>
      <Dialog.Content>
        <div className="mb-6 flex flex-col gap-4">
          {isNullish(timeRangeSelectorProps) && (
            <div className="mb-2 flex flex-col">
              <Typography className="mb-2" variant="label">
                Current time selection
              </Typography>
              <Typography variant="body-medium">{`${timePeriodFormatter(timeRange.startTime)} - ${timePeriodFormatter(timeRange.endTime)}`}</Typography>
              <Typography variant="body-small">The time range can be changed from the timeline chart</Typography>
            </div>
          )}

          {isNotNullish(timeRangeSelectorProps) && (
            <TimeRangeSelector timeRange={timeRange} {...timeRangeSelectorProps} />
          )}

          {!hasQueries && (
            <Banner
              hasIcon
              type="warning"
              description="There are no queries within the selected time range."
              usage="inline"
            />
          )}

          <div
            className="text-palette-primary-text flex cursor-pointer select-none items-center gap-1"
            onClick={onToggleShowFilters}
            aria-label="Show Filters"
          >
            <Typography variant="body-medium">Filters</Typography>
            <ChevronDownIconOutline
              className="size-5"
              style={{
                transform: showFilters ? 'rotate(180deg)' : 'rotate(0deg)',
                transitionDuration: tokens.transitions.values.duration.quick,
              }}
            />
          </div>

          {showFilters && (
            <>
              {Object.values(localFilterFields).map((field) => (
                <FilterDropdown
                  key={field.id}
                  loading={isLoading}
                  field={field.label}
                  allValues={field.values}
                  selectedValues={field.value}
                  valueFormatter={field.valueFormatter}
                  errorMessage={availableDbmsFiltersError}
                  onChange={(newValues) => {
                    setLocalFilterFields(
                      produce((draft) => {
                        const updatedField = draft[field.id];
                        if (updatedField) {
                          updatedField.value = newValues;
                        }
                      }),
                    );
                  }}
                />
              ))}
              {localNumericFields.map((field) => (
                <TextInput
                  isFluid
                  key={field.id}
                  data-testid={field.label}
                  label={field.label}
                  value={field.value}
                  htmlAttributes={{
                    name: field.label,
                    type: 'number',
                    min: 0,
                  }}
                  onChange={(changeEvent) => {
                    setLocalNumericFields(
                      produce((draft) => {
                        const newNumber = Number(changeEvent.target.value);
                        if (changeEvent.target.validity.valid) {
                          const searched = draft.find((f) => f.id === field.id);
                          if (searched) {
                            searched.value = newNumber;
                          }
                        }
                      }),
                    );
                  }}
                />
              ))}
              <div className="flex flex-col gap-4">
                {localSearchFields.map((field) => (
                  <TextInput
                    isFluid
                    key={field.id}
                    value={field.value}
                    onChange={(changeEvent) => {
                      setLocalSearchFields(
                        produce((draft) => {
                          const searched = draft.find((f) => f.id === field.id);
                          if (searched) {
                            searched.value = changeEvent.target.value;
                          }
                        }),
                      );
                    }}
                    label={field.label}
                    htmlAttributes={{
                      name: field.label,
                      'aria-label': `Search ${field.label}`,
                      placeholder: field.placeholder,
                      'data-testid': field.label,
                    }}
                  />
                ))}
              </div>
            </>
          )}
        </div>

        <div className="mt-4 w-full text-right">
          <Button
            className="ml-auto mr-2"
            fill="outlined"
            color="neutral"
            isDisabled={
              isEqual(fieldResets.filterFields, localFilterFields) &&
              isEqual(fieldResets.searchFields, localSearchFields) &&
              isEqual(fieldResets.numericFields, localNumericFields)
            }
            onClick={handleResetClick}
          >
            Reset
          </Button>
          <Button
            size="medium"
            color="primary"
            isDisabled={!hasQueries}
            onClick={handleFetchClick}
            htmlAttributes={{
              'data-testid': 'request-log',
            }}
          >
            Fetch
          </Button>
        </div>
      </Dialog.Content>
    </Dialog>
  );
};
