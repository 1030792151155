import type * as ImportShared from '@nx/import-shared';

import { NODE_ID_PREFIX, RELATIONSHIP_ID_PREFIX } from '../constants';
import type { DataModel } from '../data-model/data-model';
import { getUniqCounterWithPrefixString } from '../utils/import-model-utils';

export const getNextAvailableNodeId = (visualisation: ImportShared.VisualisationState) => {
  const entityIds = visualisation.nodes.map((node) => node.id);
  return getUniqCounterWithPrefixString(entityIds, NODE_ID_PREFIX);
};

export const getNextAvailableRelationshipId = (visualisation: ImportShared.VisualisationState) => {
  const entityIds = visualisation.relationships.map((rel) => rel.id);
  return getUniqCounterWithPrefixString(entityIds, RELATIONSHIP_ID_PREFIX);
};

export const dehydrateVisualisationState = (
  state: ImportShared.VisualisationState,
): ImportShared.SerialisableVisualisationState => ({
  nodes: state.nodes.map((node) => ({ id: node.id, position: node.position })),
});

export const hydrateVisualisationState = (
  state: ImportShared.SerialisableVisualisationState,
  dataModel: ImportShared.DataModelJsonStruct,
): ImportShared.VisualisationState => ({
  nodes: dataModel.graphSchemaRepresentation.graphSchema.nodeObjectTypes.map((nodeObjectType) => {
    const position: ImportShared.NodePosition = state.nodes.find((node) => node.id === nodeObjectType.$id)
      ?.position ?? {
      x: 0,
      y: 0,
    };
    return { id: nodeObjectType.$id, position, selected: false };
  }),
  relationships: dataModel.graphSchemaRepresentation.graphSchema.relationshipObjectTypes.map(
    (relationshipObjectType) => ({
      id: relationshipObjectType.$id,
      selected: false,
    }),
  ),
});

export const getFullVisualisationModelFromVisualisationState = (
  visualisationState: ImportShared.VisualisationState,
  dataModel: DataModel,
): ImportShared.FullGraphVisualizationModel => {
  return {
    nodes: visualisationState.nodes.map((node) => ({
      id: node.id,
      position: { x: node.position.x, y: node.position.y },
      caption: dataModel.nodeModels.map((n) => n.nodeObjectType).find((n) => n.$id === node.id)?.labels[0]?.token ?? '',
    })),
    relationships: visualisationState.relationships.map((relationship) => ({
      id: relationship.id,
      fromId:
        dataModel.relationshipModels.find((r) => r.relationshipObjectType.$id === relationship.id)?.from.nodeObjectType
          .$id ?? '',
      toId:
        dataModel.relationshipModels.find((r) => r.relationshipObjectType.$id === relationship.id)?.to.nodeObjectType
          .$id ?? '',
      caption:
        dataModel.relationshipModels.map((r) => r.relationshipObjectType).find((r) => r.$id === relationship.id)?.type
          .token ?? '',
    })),
  };
};
