import { Banner, Button, Logo } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import {
  consoleApi,
  getAccessToken,
  getApiError,
  getErrorMessage,
  getTokenForConnectionAndEmail,
  logout,
  useNotificationActions,
} from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const logger = createLogger(APP_SCOPE.aura);

const isRecord = (data: unknown): data is Record<string, unknown> => {
  return isNotNullish(data) && typeof data === 'object';
};

export const LinkAccountsPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [linkLogins, linkLoginsRes] = consoleApi.useLinkLoginsMutation();
  const notifications = useNotificationActions();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { state } = location;
  if (!isRecord(state)) {
    return (
      <div className="m-auto mt-12 flex justify-center">
        <Banner type="danger" title="Load failed" description="Missing account link details." usage="inline" />
      </div>
    );
  }
  const { connection, email } = state;

  if (isNullish(connection) || typeof connection !== 'string' || isNullish(email) || typeof email !== 'string') {
    return (
      <div className="m-auto mt-12 flex justify-center">
        <Banner
          type="danger"
          title="Something went wrong"
          description="Email and/or connection are invalid for linking."
          usage="inline"
        />
      </div>
    );
  }

  const handleLink = async () => {
    setIsLoading(true);
    let token;
    const currentBearerToken = await getAccessToken();
    try {
      token = await getTokenForConnectionAndEmail(connection, email);
    } catch (e) {
      setIsLoading(false);
      notifications.addNotification({
        title: 'Error',
        description: 'Failed to link accounts',
        timeout: 5000,
        type: 'danger',
      });
      return;
    }
    if (isNullish(currentBearerToken) || isNullish(token)) {
      return;
    }

    linkLogins({ currentBearerToken, oldAccountToken: token })
      .unwrap()
      .then(() => {
        const url = new URL(window.location.href);
        url.pathname = '/';
        // Navigate to home after linking with a full refresh of the page
        window.location.replace(url);
      })
      .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(e);
        const message = getErrorMessage(error);
        logger.error(message);
        notifications.addNotification({
          title: 'Error',
          description: 'Failed to link accounts',
          timeout: 5000,
          type: 'danger',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleLogout = () => {
    logout();
  };

  const error = getApiError(linkLoginsRes.error);

  return (
    <div className="flex grow flex-col">
      <div className="p-2">
        <Logo width={100} height={32} />
      </div>
      <div className="mt-12 flex  justify-center">
        <div className="bg-neutral-bg-weak shadow-raised flex max-w-md flex-col gap-4 rounded-md p-8">
          <h3>Link Accounts</h3>
          <p>
            There is already an account using the email address <code>{email}</code>.
          </p>
          <p>
            Link your accounts by logging in using your previous login method via the <b>Link</b> button below.
          </p>
          <div className="flex flex-nowrap gap-3">
            <Button onClick={handleLogout} fill="outlined">
              Logout
            </Button>
            <Button
              onClick={() => {
                void handleLink();
              }}
              isLoading={isLoading}
              htmlAttributes={{
                'data-testid': 'link-button',
              }}
            >
              Link
            </Button>
          </div>
          {linkLoginsRes.isError && (
            <Banner
              type="danger"
              title={`Failed to authenticate: ${error.reason}`}
              description={error.message}
              usage="inline"
            />
          )}
        </div>
      </div>
    </div>
  );
};
