import type { STYLE_TYPE_PERSPECTIVE, STYLE_TYPE_SCENE } from '../state/styles/styles';
import type { Caption } from '../state/styles/types';
import iconData from '../styles/iconjar/iconjar.json';
import type { PropertyValueTypes } from './graph';
import type { Nullable } from './utility';

// WIP
export interface Style {
  id: string;
  palette: Palette;
  categories?: StyleCategory[];
  relationshipTypes: StyleRelationshipType[];
  type: typeof STYLE_TYPE_SCENE | typeof STYLE_TYPE_PERSPECTIVE;
}

export interface StyleRule {
  id: string;
  type: string;
  size: number;
  minSize?: number;
  maxSize?: number;
  color?: string;
  minColor?: string;
  midColor?: string;
  maxColor?: string;
  minColorValue?: string;
  midColorValue?: string;
  maxColorValue?: string;
  minPoint?: number;
  maxPoint?: number;
  maxSizeValue?: string;
  minSizeValue?: string;
  basedOn: Nullable<string>;
  applyColor: boolean;
  condition?: Nullable<string>;
  conditionValue?: Nullable<string>;
  applySize?: boolean;
  applyCaption?: boolean;
  isGdsRule?: boolean;
  gdsRuleDetails?: {
    gdsRuleId: string;
    selectedAlgorithm: string;
  };
  valuesMapper?: {
    value: PropertyValueTypes;
    color: string;
    hasMore: boolean;
  }[];
  existingValues?: string[];
  captions?: Caption[];
  isTimeZoneConvertEnabled?: boolean;
  rangeValue?: unknown;
  selectedTimeZone?: string;
  textAlign?: string;
  textSize?: number;
}

export interface Palette {
  colors: string[];
  currentIndex: number;
}

export type CategoryIcon = keyof typeof iconData | 'no-icon';
export const CategoryIconKeys = ['no-icon', ...Object.keys(iconData)] as CategoryIcon[];

export interface StyleCategory {
  id: number | string;
  color: string;
  size: number;
  icon: CategoryIcon;
  styleRules: StyleRule[];
  textAlign: string;
  textSize: number;
  captions: Caption[];
  captionKeys: string[];
}

export interface StyleRelationshipType {
  id: string;
  color: string;
  size: number;
  styleRules: StyleRule[];
  textAlign: string;
  textSize: number;
  captions: Caption[];
  captionKeys: string[];
}
