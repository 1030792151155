import type { EnrichedSsoConfig, SsoConfig } from '@nx/state';

export interface ProjectOption {
  label: string;
  key: string;
  value: string;
}

export enum SSO_CONFIG_FORM_ACTIONS {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

export type SsoConfigFormData = Omit<EnrichedSsoConfig, 'channel' | 'emailDomains' | 'ssoConfigId' | 'id'> & {
  manualUriConfiguration: boolean;
  /**
   * Indicates that the client secret field needs to be populated.
   * Should be set to true in these scenarios:
   * - Creating backchannel config
   * - Updating config and channel is switched to backchannel
   *
   * We do not need the secret when updating a backchannel config as
   * the secret is already set
   */
  needsSecret?: boolean;
};

export interface FormActionData {
  action: SSO_CONFIG_FORM_ACTIONS;
  data: SsoConfig | null;
}
