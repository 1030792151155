import { METRIC_TYPE } from '@nx/state';

import { COLOR_PALETTE_ENUM } from '../shared/color-palettes';
import type { ChartWidgetPartialProps } from './fullstack-chart-props';
import * as tooltips from './help-tooltips';
import { DashboardPercentageChartConfig } from './helpers';

export const CPUTotalProps: ChartWidgetPartialProps = {
  title: 'CPU Usage',
  subtitle: 'Total',
  yLabel: '',
  metricType: METRIC_TYPE.Gauge,
  metricName: 'neo4j_aura_cpu_usage',
  chartType: 'HOST',
  referenceMetricConfig: {
    toggledByDefault: true,
    name: 'neo4j_aura_cpu_limit',
    type: METRIC_TYPE.Gauge,
    aggregation: 'MAX',
    hidden: true,
  },
  colorPalette: COLOR_PALETTE_ENUM.SingleColor,
  ...DashboardPercentageChartConfig,
};

export const StorageUsedChartPropsOld: ChartWidgetPartialProps = {
  title: 'Storage',
  subtitle: 'Total',
  yLabel: '',
  metricType: METRIC_TYPE.Gauge,
  metricName: 'neo4j_database_store_size_database',
  chartType: 'HOST',
  controlledBy: [{ name: 'useVirtualDiskMetricForStorageLimit', state: 'OFF' }],
  referenceMetricConfig: {
    toggledByDefault: true,
    name: 'neo4j_aura_storage_limit_calculated',
    type: METRIC_TYPE.Gauge,
    aggregation: 'MAX',
    hidden: true,
  },
  colorPalette: COLOR_PALETTE_ENUM.SingleColor,
  ...DashboardPercentageChartConfig,
};

export const StorageUsedChartProps: ChartWidgetPartialProps = {
  title: 'Storage',
  subtitle: 'Total',
  yLabel: '',
  metricType: METRIC_TYPE.Gauge,
  metricName: 'neo4j_database_store_size_database',
  chartType: 'HOST',
  controlledBy: [{ name: 'useVirtualDiskMetricForStorageLimit', state: 'ON' }],
  referenceMetricConfig: {
    toggledByDefault: true,
    name: 'neo4j_aura_storage_limit',
    type: METRIC_TYPE.Gauge,
    aggregation: 'MAX',
    hidden: true,
  },
  colorPalette: COLOR_PALETTE_ENUM.SingleColor,
  ...DashboardPercentageChartConfig,
};

export const QueryExecutions: ChartWidgetPartialProps = {
  title: 'Query Rate',
  subtitle: 'Successful',
  seriesName: 'Queries per minute',
  yLabel: 'Count',
  additionalMetric: {
    title: 'Query Rate',
    metricName: 'neo4j_db_query_execution_failure_per_minute',
    seriesName: 'Failed queries per minute',
    subtitle: 'Failed',
    yLabel: 'Count',
    showBands: false,
    metricType: METRIC_TYPE.Counter,
    chartType: 'DB',
  },
  metricName: 'neo4j_db_query_execution_success_per_minute',
  metricType: METRIC_TYPE.Counter,
  chartType: 'DB',
  tooltipComponent: tooltips.QueryExecutions,
  aggregations: ['SUM'],
  showLinkToLogs: true,
};

export const InstanceDashboardCharts = [
  CPUTotalProps,
  StorageUsedChartPropsOld,
  StorageUsedChartProps,
  QueryExecutions,
];
