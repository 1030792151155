import type {
  CardResponse,
  CreateCardRequest,
  CreateCustomerRequest,
  CustomerResponse,
  UpdateCustomerDetailsRequest,
  UpdateCustomerEmailRequest,
} from '../console.api.types';
import type { CardDetails, Customer } from '../console.types';
import {
  transformCardResponse,
  transformCreateCardRequest,
  transformCreateCustomerRequest,
  transformCustomerResponse,
  transformUpdateCustomerDetailsRequest,
  transformUpdateCustomerEmailRequest,
} from '../transformers/customer';
import { type Builder } from './types';

export const customerEndpoints = (builder: Builder) => ({
  getCard: builder.query<CardDetails, string>({
    query: (projectId) => `tenants/${projectId}/billing/card`,
    transformResponse: (card: CardResponse) => transformCardResponse(card),
    providesTags: (result, error, projectId) => [{ type: 'Card', id: projectId }],
  }),
  addCard: builder.mutation<CardDetails, CreateCardRequest>({
    query: (request) => ({
      url: `tenants/${request.projectId}/billing/card`,
      method: 'POST',
      body: transformCreateCardRequest(request),
    }),
    transformResponse: (card: CardResponse) => transformCardResponse(card),
    invalidatesTags: (result, error, { projectId }) => [
      { type: 'Card', id: projectId },
      { type: 'Project', id: projectId },
      'UserDetails',
    ],
  }),
  getCustomer: builder.query<Customer, string>({
    query: (projectId) => `tenants/${projectId}/billing`,
    transformResponse: (customer: CustomerResponse) => transformCustomerResponse(customer),
    providesTags: (result, error, projectId) => [{ type: 'Customer', id: projectId }],
  }),

  addCustomer: builder.mutation<Customer, CreateCustomerRequest>({
    query: (request) => ({
      url: `tenants/${request.projectId}/billing`,
      method: 'POST',
      body: transformCreateCustomerRequest(request),
    }),
    invalidatesTags: (result, error, { projectId }) => [
      { type: 'Customer', id: projectId },
      { type: 'Project', id: projectId },
      'UserDetails',
    ],
  }),
  updateCustomerDetails: builder.mutation<Customer, UpdateCustomerDetailsRequest>({
    query: (request) => {
      return {
        url: `tenants/${request.projectId}/billing`,
        method: 'PATCH',
        body: transformUpdateCustomerDetailsRequest(request),
      };
    },
    invalidatesTags: (result, error, { projectId }) => [
      { type: 'Customer', id: projectId },
      { type: 'Project', id: projectId },
      'UserDetails',
    ],
  }),
  updateCustomerEmail: builder.mutation<Customer, UpdateCustomerEmailRequest>({
    query: (request) => ({
      url: `tenants/${request.projectId}/billing`,
      method: 'PATCH',
      body: transformUpdateCustomerEmailRequest(request),
    }),
    invalidatesTags: (result, error, { projectId }) => [
      { type: 'Customer', id: projectId },
      { type: 'Project', id: projectId },
      'UserDetails',
    ],
  }),
});
