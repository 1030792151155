import * as ImportShared from '@nx/import-shared';

import {
  duplicatedErrorMessage,
  emptyFieldErrorMessage,
  noKeyPropertyErrorMessage,
  noSupportedTypesErrorMessage,
  nonSpecifiedErrorMessage,
} from '../constants';

export const emptyFieldError: ImportShared.ErrorModel = {
  code: ImportShared.VALIDATION_ERROR_CODE.INCOMPLETE,
  message: emptyFieldErrorMessage,
};
export const duplicatedError: ImportShared.ErrorModel = {
  code: ImportShared.VALIDATION_ERROR_CODE.DUPLICATED,
  message: duplicatedErrorMessage,
};
export const noSupportedTypesError: ImportShared.ErrorModel = {
  code: ImportShared.VALIDATION_ERROR_CODE.NO_SUPPORTED_TYPES,
  message: noSupportedTypesErrorMessage,
};
export const noKeyPropertyError: ImportShared.ErrorModel = {
  code: ImportShared.VALIDATION_ERROR_CODE.INCOMPLETE,
  message: noKeyPropertyErrorMessage,
};
export const nonSpecifiedError: ImportShared.ErrorModel = {
  code: ImportShared.VALIDATION_ERROR_CODE.INCOMPLETE,
  message: nonSpecifiedErrorMessage,
};
