import { Banner, Button, IconButton } from '@neo4j-ndl/react';
import { AcademicCapIconOutline, ArrowPathIconOutline, XMarkIconOutline } from '@neo4j-ndl/react/icons';
import type { DomJson } from '@nx/constants';
import { TEST_GUIDE_PAGES } from '@nx/constants';
import type { GuideWithStatus } from '@nx/state';
import {
  useConnection,
  useConnectionSelectorModal,
  useCurrentGuide,
  useGuidesActions,
  useGuidesSidebarActions,
} from '@nx/state';
import { useEffect, useRef } from 'react';

import { JsonUiConverter } from '../guides/asciidoc-ui-converter';
import { GUIDES_CUSTOM_TAGS, GUIDES_HTML_TAGS } from '../guides/constants';
import { useNxNavigate } from '../hooks/use-nx-navigate';

const jsonUiConverter = new JsonUiConverter();

const MISSING_PAGE_MSG: DomJson = {
  name: 'H2',
  type: 1,
  value: null,
  classList: [],
  children: [
    {
      name: '#text',
      type: 3,
      value: 'Error. Failed to load guides. The required content is missing.',
      classList: [],
      children: [],
    },
  ],
};
const getPage = (contents: DomJson[], pageNumber: number): DomJson =>
  contents[pageNumber] ?? contents[0] ?? MISSING_PAGE_MSG;

export const GuideContent = ({
  guideId,
  guide,
  onComplete,
}: {
  guideId: string;
  guide: GuideWithStatus;
  onComplete?: () => void;
}) => {
  const {
    status: { isDisconnected },
  } = useConnection();
  const datasourceModal = useConnectionSelectorModal();
  const navigate = useNxNavigate();
  const { setGuideCurrentPage, openGuide } = useGuidesActions();
  const { toggleGuidesSidebar } = useGuidesSidebarActions();
  const currentGuide = useCurrentGuide();
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const { currentPage, totalPages, contents } = guide;

  useEffect(() => {
    jsonUiConverter.registerNavigation(navigate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClose() {
    toggleGuidesSidebar();
  }

  function handleComplete() {
    toggleGuidesSidebar();
    if (onComplete) {
      onComplete();
    }
  }

  function handlePageChange(delta: number) {
    const pageNumber = Math.min(Math.max(currentPage + delta, 0), totalPages - 1);
    setGuideCurrentPage({ id: guideId, pageNumber });

    const initialCallback = jsonUiConverter.getPageLaunchCallbackFromDomJson(getPage(contents, pageNumber));

    if (initialCallback) {
      try {
        initialCallback();
      } catch (e) {
        // ignore error
      }
    }
  }

  const reloadGuide = () => {
    if (currentGuide.guide) {
      void openGuide({ ...currentGuide.guide, target: 'sidebar' });
      void setGuideCurrentPage({ id: guideId, pageNumber: 0 });
    }
  };

  useEffect(() => {
    // Scroll the container to the top when launching the new page.
    scrollableContainerRef.current?.scrollTo({ top: 0 });
  }, [currentPage]);

  const hasCodeBlock = contents.some((page) =>
    jsonUiConverter.hasTag(page, GUIDES_CUSTOM_TAGS.CODEBLOCK, { language: 'cypher' }),
  );

  return (
    <article className="contents" title={guide.title}>
      <div data-testid="guide-header" className="n-subheading-small flex items-center justify-between px-6">
        <div className="flex items-center">
          <AcademicCapIconOutline className="mr-2 w-5" /> <span>{guide.title}</span>
        </div>
        <IconButton
          className="z-10"
          isClean
          ariaLabel="Close guide"
          htmlAttributes={{
            title: 'Close guide',
          }}
          onClick={handleClose}
        >
          <XMarkIconOutline className="text-palette-neutral-weak" />
        </IconButton>
      </div>
      <div ref={scrollableContainerRef} className={`nx-scrollbox my-5 grow basis-0 px-[24px]`}>
        {totalPages > 1 && (
          <span
            className="n-subheading-small"
            role="progressbar"
            aria-label="Step"
            aria-valuemin={1}
            aria-valuemax={totalPages}
            aria-valuenow={currentPage + 1}
          >
            Step {currentPage + 1}/{totalPages}
          </span>
        )}
        <h5 className="mb-6 flex items-center">
          {jsonUiConverter.getTitleFromDomJsonByTagName(getPage(contents, currentPage), GUIDES_HTML_TAGS.H2)}
        </h5>
        {isDisconnected && hasCodeBlock && (
          <Banner
            hasIcon
            type="warning"
            title="Connection to an instance is required to run queries or import data in this guide."
            className="mb-4"
            actions={[
              {
                label: 'Connect to instance',
                onClick: () => datasourceModal.open(),
              },
            ]}
            usage="inline"
          />
        )}
        <div className="n-body-medium">{jsonUiConverter.renderDomJson2Jsx(getPage(contents, currentPage))}</div>
      </div>
      {totalPages > 1 && (
        <div className="flex justify-end gap-4 px-6">
          {guideId === TEST_GUIDE_PAGES.NX_INTERNAL_TEST.toString() && (
            /* TODO: add ariaLabel */
            <IconButton ariaLabel="" className="mr-auto" onClick={reloadGuide}>
              <ArrowPathIconOutline />
            </IconButton>
          )}

          {currentPage > 0 && (
            <Button
              fill="outlined"
              onClick={() => {
                void handlePageChange(-1);
              }}
            >
              Previous
            </Button>
          )}

          <Button
            onClick={() => {
              if (currentPage === totalPages - 1) {
                handleComplete();
              } else {
                void handlePageChange(1);
              }
            }}
          >
            {currentPage === totalPages - 1 ? 'Finish' : 'Next'}
          </Button>
        </div>
      )}
    </article>
  );
};
