import type { Integer, Record as Neo4jRecord } from 'neo4j-driver';
import neo4j from 'neo4j-driver';

import type { Result } from '../../state/gds/types';
import { isTruthy } from '../../types/utility';

export interface MapperResult {
  data: Result['data'];
  minVal: Result['minVal'];
  maxVal: Result['maxVal'];
}

export const mapper = (
  result: { records: Neo4jRecord[] },
  isCentrality: boolean,
  numIdMap?: Map<string, string> | undefined,
): MapperResult => {
  if (isTruthy(result?.records?.[0])) {
    const [record] = result.records;
    const minVal: Result['minVal'] = record.get('minVal');
    const maxVal: Result['maxVal'] = record.get('maxVal');
    if (isCentrality) {
      const data: Result['data'] = (record.get('data') ?? []).reduce(
        (acc: Record<number | string, number>, itr: { nodeId: Integer; score: number }) => {
          const { nodeId, score } = itr;
          if (neo4j.isInt(nodeId)) {
            const localId = numIdMap ? numIdMap?.get(nodeId.toString()) : nodeId.toNumber();
            if (localId) {
              acc[localId] = score;
            }
          }
          return acc;
        },
        {},
      );
      return { data, minVal, maxVal };
    }
    const data: Result['data'] = (record.get('data') ?? []).reduce(
      (acc: Record<number | string, number>, itr: { nodeId: Integer; communityId: Integer }) => {
        const { nodeId, communityId } = itr;
        if (neo4j.isInt(nodeId)) {
          const localId = numIdMap ? numIdMap?.get(nodeId.toString()) : nodeId.toNumber();
          if (localId) {
            acc[localId] = neo4j.isInt(communityId) ? communityId.toNumber() : communityId;
          }
        }
        return acc;
      },
      {},
    );
    return { data, minVal, maxVal };
  }
  return { data: undefined, minVal: undefined, maxVal: undefined };
};
