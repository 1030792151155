import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { CLEAR_SCENE } from '../rootActions';
import type { RootState } from '../types';
import {
  DESKTOP_DEPLOYMENT,
  STANDALONE_DEPLOYMENT,
  STANDALONE_EXPLORE_DEPLOYMENT,
  WORKSPACE_DEPLOYMENT,
} from './deploymentConfigurations';
import type { AppState, DeploymentConfiguration, DeploymentName } from './types';
import { SCENE_DASHBOARD, SCENE_READY, TRANSIENT } from './types';

export const NAME = 'app';

const initialState: AppState = {
  showEmptyState: true,
  initialisationCompleted: false,
  license: { status: 'noplugin' },
  watermark: null,
  isCaseInsensitiveAvailable: false,
  stateMachineStatus: TRANSIENT,
  buttonArrayBottomPosition: null,
  ...STANDALONE_DEPLOYMENT,
};

const getState = (state: RootState): AppState => state[NAME];

export const getWatermark = (state: RootState) => {
  return getLicenseStatus(state) === 'expired' ? 'Expired License' : null;
};

export const getLicenseStatus = (state: RootState) => getState(state).license.status;
export const getLicenseDaysLeft = (state: RootState) => {
  const { license } = getState(state);
  if (license.status === 'valid' || license.status === 'expired') {
    return license.daysLeft;
  }
  return null;
};
export const getShowSceneEmptyState = (state: RootState) => getState(state).showEmptyState;
export const getIsInitialisationCompleted = (state: RootState) => getState(state).initialisationCompleted;

const getDeployment = (state: RootState) => getState(state).deployment;
export const getBoltContainerApp = (state: RootState) => (getIsWorkspace(state) ? 'workspace' : 'standalone');

export const selectStateMachineStatus = (state: RootState) => getState(state).stateMachineStatus;

// Deployment Configuration Selectors
export const getIsDesktop = (state: RootState) => getDeployment(state) === 'DESKTOP';
export const getIsWorkspace = (state: RootState) => getDeployment(state) === 'WORKSPACE';

export const getAllowSettings = (state: RootState) => getState(state).allowSettings;

export const getAllowChooseDatabase = (state: RootState) => getState(state).allowChooseDatabase;
export const getEnableCanny = () => false;
export const getSetPerspectiveAndSceneOnDatabaseChange = (state: RootState) =>
  getState(state).setPerspectiveAndSceneOnDatabaseChange;
export const getUnsetPerspectiveOnDisconnect = (state: RootState) => getState(state).unsetPerspectiveOnDisconnect;
export const getButtonArrayBottomPosition = (state: RootState) => getState(state).buttonArrayBottomPosition;

const appDuckSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setLicense(state, action: PayloadAction<AppState['license']>) {
      state.license = action.payload;
    },
    setShowSceneEmptyState(state, action: PayloadAction<AppState['showEmptyState']>) {
      state.showEmptyState = action.payload;
    },
    setInitialisationCompleted(state, action: PayloadAction<AppState['initialisationCompleted']>) {
      state.initialisationCompleted = action.payload;
    },
    setIsCaseInsensitiveAvailable(state, action: PayloadAction<boolean>) {
      state.isCaseInsensitiveAvailable = action.payload;
    },
    setButtonArrayBottomPosition(state, action: PayloadAction<number>) {
      state.buttonArrayBottomPosition = action.payload;
    },
    setDeployment(state, action: PayloadAction<DeploymentName>) {
      let deploymentConfig: DeploymentConfiguration;
      switch (action.payload) {
        case 'WORKSPACE':
          deploymentConfig = WORKSPACE_DEPLOYMENT;
          break;
        case 'DESKTOP':
          deploymentConfig = DESKTOP_DEPLOYMENT;
          break;
        case 'STANDALONE_EXPLORE':
          deploymentConfig = STANDALONE_EXPLORE_DEPLOYMENT;
          break;
        default:
          deploymentConfig = STANDALONE_DEPLOYMENT;
      }

      return {
        ...state,
        ...deploymentConfig,
      };
    },
    setStateMachineStatus(state, action: PayloadAction<string>) {
      switch (action.payload) {
        case 'scene.ready':
          state.stateMachineStatus = SCENE_READY;
          break;
        case 'scene.dashboard':
          state.stateMachineStatus = SCENE_DASHBOARD;
          break;
        default:
          state.stateMachineStatus = TRANSIENT;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(CLEAR_SCENE, (state) => {
      state.showEmptyState = true;
    });
  },
});

export const {
  setLicense,
  setShowSceneEmptyState,
  setInitialisationCompleted,
  setIsCaseInsensitiveAvailable,
  setDeployment,
  setStateMachineStatus,
  setButtonArrayBottomPosition,
} = appDuckSlice.actions;

export default appDuckSlice.reducer;
