import { Banner, Button, Dialog, TextInput } from '@neo4j-ndl/react';
import { DESTINATION_TYPE, LOG_TYPE } from '@nx/state';
import type { CloudwatchDestination, CloudwatchIdentity } from '@nx/state';
import { isNullish } from '@nx/stdlib';
import React, { useState } from 'react';

import { SingleLineCodeBlock } from '../../shared/components/single-line-code-block';
import type { InnerCreateDialogProps } from '../helpers/props';
import { isValidArn } from '../helpers/validation';

export const CloudwatchCreationSteps = ({
  onCreate,
  loading,
  error,
  logForwardingIdentities,
  region,
  tier,
  step,
  setStep,
}: InnerCreateDialogProps) => {
  const [destination, setDestination] = useState<CloudwatchDestination>({
    region: '',
    logGroupName: 'neo4j',
    logRetentionDays: 14,
    logArn: '',
    logStreamName: LOG_TYPE.SECURITY,
  });

  const identity = logForwardingIdentities.find((id) => id.region === region && id.tier === tier);
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const logArn = identity ? (identity as CloudwatchIdentity).logArn : 'Nothing found';
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const accountId = identity ? (identity as CloudwatchIdentity).accountId : 'Nothing found';

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const jsonText = `{
    "Version": "2012-10-17",
    "Statement": [
        {
            "Effect": "Allow",
            "Action": [
                "logs:CreateLogGroup",
                "logs:CreateLogStream",
                "logs:PutLogEvents",
                "logs:DescribeLogStreams"
            ],
            "Resource": [
                "arn:aws:logs:*:*:*"
            ]
        }
    ]
}`;

  const handleCreate = () => {
    if (isNullish(region)) {
      return;
    }
    onCreate({
      cloudwatch: destination,
      type: DESTINATION_TYPE.CLOUDWATCH,
    });
  };

  const isValidLogArn = Boolean(destination.logArn) && isValidArn(destination.logArn);

  const isValidDestination =
    Boolean(destination.logGroupName) &&
    destination.logRetentionDays &&
    Boolean(destination.logStreamName) &&
    Boolean(destination.region);

  const isValidPayload = isValidLogArn && isValidDestination;

  return (
    <>
      {step === 1 && (
        <>
          <Dialog.Content className="space-y-6">
            {error && (
              <Banner type="danger" usage="inline">
                {String(error.message)}
              </Banner>
            )}
            <ol className="my-6 list-decimal space-y-6 pl-6">
              <li>
                Access the AWS Console:
                <ul className="list-inside list-disc">
                  <li>Log in to the AWS Management Console.</li>
                  <li>Navigate to IAM (Identity and Access Management).</li>
                  <li>Navigate to Policies.</li>
                  <li>Click &quot;Create Policy&quot;.</li>
                  <li>Select the &quot;JSON&quot; tab, and copy and paste the following policy:</li>
                  <div className="my-6">
                    <SingleLineCodeBlock copyToClipboard code={jsonText} />
                  </div>
                  <li>Click &quot;Next.&quot;</li>
                  <li>Choose a name for the policy and click &quot;Create.&quot;</li>
                </ul>
              </li>
            </ol>
          </Dialog.Content>
          <Dialog.Actions>
            <Button fill="outlined" color="neutral" onClick={handlePrevStep}>
              Previous
            </Button>
            <Button
              color="primary"
              onClick={handleNextStep}
              htmlAttributes={{
                'data-testid': 'log-forwarding-next-step',
              }}
            >
              Next
            </Button>
          </Dialog.Actions>
        </>
      )}
      {step === 2 && (
        <>
          <Dialog.Content className="space-y-6">
            {error && (
              <Banner type="danger" usage="inline">
                {String(error.message)}
              </Banner>
            )}
            <ol className="my-6 list-decimal space-y-6 pl-6" start={2}>
              <li>
                Navigate to Roles and create a Role:
                <ul className="list-inside list-disc">
                  <li>Choose &quot;AWS account&quot; as the trusted entity.</li>
                  <li>Select &quot;Another AWS account&quot; and enter the Neo4j account ID below</li>
                </ul>
              </li>
              <SingleLineCodeBlock code={accountId} copyToClipboard />
              <ul className="list-inside list-disc">
                <li>Click &quot;Next.&quot;</li>
                <li>Search for and select the policy you created in the previous step.</li>
                <li>Name the role.</li>
                <li>Choose &quot;Create role.&quot;</li>
                <li>Open the role you just created from the list of available roles.</li>
                <li>Select the &quot;Trust relationships&quot; tab, then &quot;Edit trust policy.&quot;</li>
                <li>Replace the existing role ARN with the Neo4j AWS Role ARN below:</li>
              </ul>
              <SingleLineCodeBlock code={logArn} copyToClipboard />
              <ul className="list-inside list-disc">
                <li>Click &quot;Update policy.&quot;</li>
              </ul>

              <li>
                Copy the Role ARN:
                <ul className="list-inside list-disc">
                  <li>Once the trust entity has been updated, click on the new role.</li>
                  <li>From that page, copy the generated ARN.</li>
                  <li>Paste the ARN in the specified field below:</li>
                </ul>
              </li>
              <TextInput
                label="Role ARN"
                value={destination.logArn}
                onChange={(event) => setDestination((prev) => ({ ...prev, logArn: event.target.value }))}
                errorText={isValidLogArn ? null : 'Must be a valid ARN'}
                isFluid
                htmlAttributes={{
                  'data-testid': 'log-forwarding-aws-role-arn-input',
                }}
              />
            </ol>
          </Dialog.Content>
          <Dialog.Actions>
            <Button fill="outlined" color="neutral" onClick={handlePrevStep}>
              Previous
            </Button>
            <Button
              isDisabled={!isValidLogArn}
              color="primary"
              onClick={handleNextStep}
              htmlAttributes={{
                'data-testid': 'log-forwarding-next-step',
              }}
            >
              Next
            </Button>
          </Dialog.Actions>
        </>
      )}
      {step === 3 && (
        <>
          <Dialog.Content className="space-y-6">
            {error && (
              <Banner type="danger" usage="inline">
                {String(error.message)}
              </Banner>
            )}
            <TextInput
              label="Group name"
              value={destination.logGroupName}
              onChange={(event) => setDestination((prev) => ({ ...prev, logGroupName: event.target.value }))}
              isFluid
              htmlAttributes={{
                'data-testid': 'log-forwarding-aws-group-name-input',
              }}
            />
            <TextInput
              label="Stream name"
              value={destination.logStreamName}
              onChange={(event) => setDestination((prev) => ({ ...prev, logStreamName: event.target.value }))}
              isFluid
              htmlAttributes={{
                'data-testid': 'log-forwarding-aws-stream-name-input',
              }}
            />
            <TextInput
              label="Retention days"
              value={destination.logRetentionDays}
              onChange={(event) =>
                setDestination((prev) => ({
                  ...prev,
                  logRetentionDays: event.target.value ? parseInt(event.target.value, 10) : 1,
                }))
              }
              helpText='The number of days until AWS marks a log as “expired"'
              isFluid
              htmlAttributes={{
                min: 1,
                'data-testid': 'log-forwarding-aws-retention-days-input',
                type: 'number',
              }}
            />
            <TextInput
              label="AWS Region"
              value={destination.region}
              onChange={(event) => setDestination((prev) => ({ ...prev, region: event.target.value }))}
              helpText="Your AWS Region (can be different from your Neo4j region)."
              isFluid
              htmlAttributes={{
                'data-testid': 'log-forwarding-aws-region-input',
              }}
            />
          </Dialog.Content>
          <Dialog.Actions>
            <Button fill="outlined" color="neutral" onClick={handlePrevStep}>
              Previous
            </Button>
            <Button
              color="primary"
              onClick={handleCreate}
              isLoading={loading}
              isDisabled={isValidPayload !== true}
              htmlAttributes={{
                'data-testid': 'log-forwarding-create',
              }}
            >
              Create
            </Button>
          </Dialog.Actions>
        </>
      )}
    </>
  );
};
