import { useSelector } from '../context';
import * as Selectors from '../selectors';

/**
 * Get current Auth0 user ID
 * @returns User ID if user is authenticated, `null` otherwise
 */

export function useUserId() {
  return useSelector(Selectors.selectUserId);
}
