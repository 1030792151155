import type { APP_SCOPE } from '@nx/constants';
import { useMemo } from 'react';

import { useDispatch, useSelector } from '../../context';
import * as Configuration from './configuration-slice';
import { makeSelectToolConfiguration, selectInternalConfiguration, selectToolConfigurations } from './selectors';

// TODO: Refactor into `useState`-like hook
export function useConfigurationActions() {
  const dispatch = useDispatch();
  return {
    appUpdateNotificationShown: (...args: Parameters<typeof Configuration.appUpdateNotificationShown>) => {
      return dispatch(Configuration.appUpdateNotificationShown(...args));
    },
    consentToTracking: (...args: Parameters<typeof Configuration.consentToTracking>) => {
      return dispatch(Configuration.consentToTracking(...args));
    },
    setTermsAndConditionsAccepted: (...args: Parameters<typeof Configuration.setTermsAndConditionsAccepted>) => {
      return dispatch(Configuration.setTermsAndConditionsAccepted(...args));
    },
    setWelcomeAppCuesShown: (...args: Parameters<typeof Configuration.setWelcomeAppCuesShown>) => {
      return dispatch(Configuration.setWelcomeAppCuesShown(...args));
    },
    setIsFirstLoad: (...args: Parameters<typeof Configuration.setIsFirstLoad>) => {
      return dispatch(Configuration.setIsFirstLoad(...args));
    },
    setFirstVisitGuidesShown: (...args: Parameters<typeof Configuration.setFirstVisitGuidesShown>) => {
      return dispatch(Configuration.setFirstVisitGuidesShown(...args));
    },
    setIsFirstTimeUser: (...args: Parameters<typeof Configuration.setIsFirstTimeUser>) => {
      return dispatch(Configuration.setIsFirstTimeUser(...args));
    },
    setCookieHintShown: (...args: Parameters<typeof Configuration.setCookieHintShown>) => {
      return dispatch(Configuration.setCookieHintShown(...args));
    },
    setInitialPendingInvitesShown: (...args: Parameters<typeof Configuration.setInitialPendingInvitesShown>) => {
      return dispatch(Configuration.setInitialPendingInvitesShown(...args));
    },
    setPendingInvitesShown: (...args: Parameters<typeof Configuration.setPendingInvitesShown>) => {
      return dispatch(Configuration.setPendingInvitesShown(...args));
    },
    setSelectedUsageTable: (...args: Parameters<typeof Configuration.setSelectedUsageTable>) => {
      return dispatch(Configuration.setSelectedUsageTable(...args));
    },
  };
}

export function useAppManifest() {
  return useSelector(Configuration.selectAppManifest);
}

export function useInternalConfiguration() {
  return useSelector(selectInternalConfiguration);
}

export function useServicesConfiguration() {
  const segment = useSelector(Configuration.selectSegmentConfiguration);
  const sentry = useSelector(Configuration.selectSentryConfiguration);
  const launchDarkly = useSelector(Configuration.selectLaunchDarklyConfiguration);
  return { segment, sentry, launchDarkly };
}

export function useToolConfiguration(toolId: APP_SCOPE) {
  const selectToolConfiguration = useMemo(() => makeSelectToolConfiguration(toolId), [toolId]);
  return useSelector(selectToolConfiguration);
}

export function useToolsConfiguration() {
  return useSelector(selectToolConfigurations);
}

export function useOnboardingConfiguration() {
  return useSelector(Configuration.selectOnboardingConfiguration);
}

export function useAuraConfiguration() {
  return useSelector(Configuration.selectAuraConfiguration);
}
