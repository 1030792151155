import type { ActionSuggestion } from '../../../../modules/SearchBar/types';
import { SUGGESTION_TYPE } from '../../../../modules/SearchBar/types';

export type ActionName =
  | 'InvertSelection'
  | 'FitToSelection'
  | 'Expand'
  | 'ClearScene'
  | 'Dismiss'
  | 'DismissOther'
  | 'RefreshData'
  | 'Redo'
  | 'Undo';

export const ACTIONS: ActionSuggestion[] = [
  {
    type: SUGGESTION_TYPE.ACTION,
    title: 'Invert selection',
    actionName: 'InvertSelection',
  },
  {
    type: SUGGESTION_TYPE.ACTION,
    title: 'Fit to selection',
    actionName: 'FitToSelection',
  },
  {
    type: SUGGESTION_TYPE.ACTION,
    title: 'Expand selection',
    actionName: 'Expand',
  },
  {
    type: SUGGESTION_TYPE.ACTION,
    title: 'Clear Scene',
    actionName: 'ClearScene',
  },
  {
    type: SUGGESTION_TYPE.ACTION,
    title: 'Dismiss',
    actionName: 'Dismiss',
  },
  {
    type: SUGGESTION_TYPE.ACTION,
    title: 'Dismiss others',
    actionName: 'DismissOther',
  },
  {
    type: SUGGESTION_TYPE.ACTION,
    title: 'Refresh data',
    actionName: 'RefreshData',
  },
  {
    type: SUGGESTION_TYPE.ACTION,
    title: 'Redo',
    actionName: 'Redo',
  },
  {
    type: SUGGESTION_TYPE.ACTION,
    title: 'Undo',
    actionName: 'Undo',
  },
];
