import { DropdownButton, Menu, Tooltip } from '@neo4j-ndl/react';
import { useRef, useState } from 'react';

import { InfoItem } from '../connection-bar/info-item';

export type EntitySelectorProps<T extends EntityOption> = {
  label: string;
  selectedEntity: T | undefined;
  setSelectedEntity: (id?: string) => void;
  entities: T[];
  MenuItem: (entity: T) => JSX.Element;
  onEntityChange?: () => void;
};

export interface EntityOption {
  id: string;
  name: string;
}

export const EntitySelectorDropdown = <T extends EntityOption>({
  label,
  selectedEntity,
  setSelectedEntity,
  entities,
  MenuItem,
  onEntityChange,
}: EntitySelectorProps<T>) => {
  const buttonRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const selectedName = selectedEntity?.name ?? 'unknown';

  return (
    <header className="bg-palette-neutral-bg-weak border-palette-neutral-border-weak flex min-h-8">
      <div className="flex items-center gap-1">
        <Tooltip type="simple" isDisabled={selectedName.length < 31}>
          <Tooltip.Trigger>
            <DropdownButton
              aria-label="Toggle instance menu"
              htmlAttributes={{ 'data-testid': `${label.toLowerCase()}-menu-button` }}
              size="small"
              ref={buttonRef}
              isOpen={isMenuOpen}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="border-none"
            >
              {/* In order not to truncate max length (30) char instances, the limit unintuitively needs to be 31 */}
              <InfoItem
                label={`${label}:`}
                value={
                  <div data-testid={`selected-${selectedName}`} className="max-w-[31ch] overflow-hidden text-ellipsis">
                    {selectedName}
                  </div>
                }
              />
            </DropdownButton>
          </Tooltip.Trigger>
          <Tooltip.Content>{selectedName}</Tooltip.Content>
        </Tooltip>
      </div>
      <Menu className="max-h-[70vh]" isOpen={isMenuOpen} anchorRef={buttonRef} onClose={() => setIsMenuOpen(false)}>
        {entities.map((option, i) => (
          <Menu.Item
            className="w-full"
            key={i}
            title={<MenuItem {...option} />}
            onClick={() => {
              if (onEntityChange) {
                onEntityChange();
              }
              setSelectedEntity(option.id);
            }}
            isDisabled={selectedEntity?.id === option.id}
          />
        ))}
      </Menu>
    </header>
  );
};
