import { MODAL_TYPE, useCurrentModal, useModalClose } from '@nx/state';
import { ConnectionModal, GuideSelectionModal, InstanceNameDialog, PasswordChangeDialog } from '@nx/ui';

import { ManageInstanceCustomEndpointDialog } from '../custom-endpoints/manage-instance-endpoint-dialog';
import { DataSourceModal } from '../data-source/data-source-modal';
import { ExtendTrialModal } from '../extend-trial';
import { EditCDCModeModal } from '../instance/cdc';
import { ClearInstanceModal } from '../instance/clear';
import { CloneToExistingModal } from '../instance/clone/to-existing';
import { CloneToNewModal } from '../instance/clone/to-new';
import { ConfigureInstanceModal } from '../instance/configure';
import { ConnectInstanceModal } from '../instance/connect';
import { CreateInstanceModal } from '../instance/create';
import { InstanceCredentialsModal } from '../instance/create/instance-credentials-modal';
import { DeleteInstanceModal } from '../instance/delete';
import { PauseInstanceModal } from '../instance/pause';
import { UpdateInstancePriorityModal } from '../instance/priority';
import { ResumeInstanceModal } from '../instance/resume';
import { EditSecondariesModal } from '../instance/secondaries';
import { UpgradeInstanceModal } from '../instance/upgrade';
import { AddPaymentMethodModal } from '../project/billing/shared/modal';
import { InviteModal } from './pending-invite/pending-invite-modal';
import { TermsAndConditions } from './terms/terms-and-conditions';

export const ConsoleModals = () => {
  const modal = useCurrentModal();
  const onCloseModal = useModalClose();

  if (!modal) {
    return null;
  }

  return (
    <>
      {modal.type === MODAL_TYPE.GUIDE_SELECTION && <GuideSelectionModal />}
      {modal.type === MODAL_TYPE.PASSWORD_CHANGE && <PasswordChangeDialog {...modal} />}
      {modal.type === MODAL_TYPE.INSTANCE_CREATED && <InstanceCredentialsModal {...modal.createdInstance} />}
      {modal.type === MODAL_TYPE.AURA_TERMS && <TermsAndConditions />}
      {modal.type === MODAL_TYPE.CONNECTION_FORM && (
        <ConnectionModal instance={modal.instance} initialWarning={modal.warning} initialError={modal.error} />
      )}
      {modal.type === MODAL_TYPE.DATA_SOURCE && <DataSourceModal {...modal} />}
      {modal.type === MODAL_TYPE.INSTANCE_NAME && <InstanceNameDialog />}
      {modal.type === MODAL_TYPE.INVITES && <InviteModal />}
      {modal.type === MODAL_TYPE.INSTANCE_CREATE && <CreateInstanceModal />}
      {modal.type === MODAL_TYPE.MANAGE_INSTANCE_ENDPOINT && (
        <ManageInstanceCustomEndpointDialog onClose={onCloseModal} instance={modal.instance} project={modal.project} />
      )}
      {modal.type === MODAL_TYPE.RESUME && (
        <ResumeInstanceModal onClose={onCloseModal} onConfirm={onCloseModal} instance={modal.instance} />
      )}
      {modal.type === MODAL_TYPE.PAUSE && (
        <PauseInstanceModal onClose={onCloseModal} onConfirm={onCloseModal} instance={modal.instance} />
      )}
      {modal.type === MODAL_TYPE.DESTROY && (
        <DeleteInstanceModal onClose={onCloseModal} onConfirm={onCloseModal} instance={modal.instance} />
      )}
      {modal.type === MODAL_TYPE.CLONE_TO_NEW && (
        <CloneToNewModal
          onClose={onCloseModal}
          onConfirm={onCloseModal}
          instance={modal.instance}
          project={modal.project}
        />
      )}
      {modal.type === MODAL_TYPE.CONFIGURE && (
        <ConfigureInstanceModal
          onClose={onCloseModal}
          onConfirm={onCloseModal}
          instance={modal.instance}
          project={modal.project}
        />
      )}
      {modal.type === MODAL_TYPE.UPGRADE && (
        <UpgradeInstanceModal
          onClose={onCloseModal}
          onConfirm={onCloseModal}
          instance={modal.instance}
          project={modal.project}
        />
      )}
      {modal.type === MODAL_TYPE.CONNECT && (
        <ConnectInstanceModal open onClose={onCloseModal} instance={modal.instance} />
      )}
      {modal.type === MODAL_TYPE.SECONDARIES && (
        <EditSecondariesModal onClose={onCloseModal} onSuccess={onCloseModal} instance={modal.instance} />
      )}
      {modal.type === MODAL_TYPE.CLEAR && (
        <ClearInstanceModal onClose={onCloseModal} onSuccess={onCloseModal} instance={modal.instance} />
      )}
      {modal.type === MODAL_TYPE.CLONE_TO_EXISTING && (
        <CloneToExistingModal onClose={onCloseModal} onSuccess={onCloseModal} instance={modal.instance} />
      )}
      {modal.type === MODAL_TYPE.CDC && (
        <EditCDCModeModal onClose={onCloseModal} onSuccess={onCloseModal} instance={modal.instance} />
      )}
      {modal.type === MODAL_TYPE.UPDATE_INSTANCE_PRIORITY && (
        <UpdateInstancePriorityModal
          onClose={onCloseModal}
          onSuccess={onCloseModal}
          instance={modal.instance}
          priority={modal.priority}
        />
      )}
      {modal.type === MODAL_TYPE.EXTEND_TRIAL && <ExtendTrialModal onClose={onCloseModal} project={modal.project} />}
      {modal.type === MODAL_TYPE.ADD_PAYMENT_DETAILS && (
        <AddPaymentMethodModal onClose={onCloseModal} hasBilling={modal.hasBilling} />
      )}
    </>
  );
};
