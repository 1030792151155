import { opsApi, useActiveProject } from '@nx/state';
import { useMemo } from 'react';

import { usePageInfo } from '../shared/hooks/use-page-info';
import type { PaginationHook } from '../shared/hooks/use-pagination';
import { useLogsContext } from './hooks/use-logs-context';
import type { SortingHook } from './hooks/use-sorting';
import { SecurityLogsDataGrid } from './security-logs-data-grid';
import { mapSecurityLogSummary } from './shared/mappers';

export const SecurityLogsSummary = ({
  selectedDbmsId,
  pagination,
  sorting,
}: {
  selectedDbmsId: string;
  pagination: PaginationHook;
  sorting: SortingHook<'Summary'>;
}) => {
  const activeProject = useActiveProject();
  const selectedTenantId = activeProject.id;
  const { filterInput, hasRequestedData } = useLogsContext();

  const { controlledPagination, logsPagination, onPaginationChange } = pagination;
  const { controlledSorting, logsSorting, onSortingChange } = sorting;

  const { data, isFetching, error } = opsApi.useGetSecurityLogsSummaryQuery(
    {
      tenantId: selectedTenantId,
      dbmsId: selectedDbmsId,
      args: {
        filter: filterInput,
        pagination: logsPagination,
        sort: logsSorting,
      },
    },
    { skip: !selectedDbmsId || !selectedTenantId || !hasRequestedData },
  );
  const logSummary = useMemo(
    () => (hasRequestedData ? mapSecurityLogSummary(data?.logs) : []),
    [hasRequestedData, data],
  );
  const pageInfo = usePageInfo(data?.pageInfo);

  return (
    <SecurityLogsDataGrid
      dataGridType="Summary"
      logs={logSummary}
      securityLogsLoading={isFetching}
      pageInfo={pageInfo}
      securityLogsError={error}
      selectedDbmsId={selectedDbmsId}
      controlledPagination={controlledPagination}
      controlledSorting={controlledSorting}
      onPaginationChange={onPaginationChange}
      onSortingChange={onSortingChange}
    />
  );
};
