import { METRIC_TYPE } from '@nx/state';

import type { ChartWidgetPartialProps } from './fullstack-chart-props';
import { RateChartConfig } from './helpers';

export const QueryExecutionsSuccessRate: ChartWidgetPartialProps = {
  title: 'Query timeline',
  subtitle: 'Count',
  yLabel: 'Queries',
  yAxisLabel: 'Query rate',
  metricType: METRIC_TYPE.Counter,
  metricName: 'neo4j_db_query_execution_success_per_minute',
  chartType: 'HOST',
  aggregations: ['SUM'],
  chartPathType: 'AREA',
  ...RateChartConfig,
};

const QueryExecutionsFailureRate: ChartWidgetPartialProps = {
  title: 'Query timeline',
  subtitle: 'Failure Count',
  yLabel: 'Queries',
  yAxisLabel: 'Query rate',
  metricType: METRIC_TYPE.Counter,
  metricName: 'neo4j_db_query_execution_failure_per_minute',
  chartType: 'HOST',
  aggregations: ['SUM'],
  chartPathType: 'AREA',
  ...RateChartConfig,
};

const QueryLatencyQ99: ChartWidgetPartialProps = {
  title: 'Query timeline',
  subtitle: 'Latency',
  yLabel: 'Query latency',
  yAxisLabel: 'Query latency (ms)',
  metricType: METRIC_TYPE.Gauge,
  metricName: 'neo4j_db_query_execution_internal_latency_q99',
  chartType: 'HOST',
  aggregations: ['MAX'],
  chartPathType: 'LINE',
  ...RateChartConfig,
};

export default [QueryExecutionsSuccessRate, QueryExecutionsFailureRate, QueryLatencyQ99];
