import { Banner, Button, Dialog, TextInput } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import type { Project } from '@nx/state';
import { consoleApi, getApiError, getErrorMessage } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';
import * as yup from 'yup';

import type { Validation } from '../../utils/validation';
import { validateYup } from '../../utils/validation';

const logger = createLogger(APP_SCOPE.framework);

export const updateProjectNameSchema = yup.object({
  name: yup.string().label('Project name').max(30).required(),
});

export const validate = (data: { name: string }, { onlyRequired = false }: { onlyRequired?: boolean } = {}) =>
  validateYup(updateProjectNameSchema, data, onlyRequired);

type ProjectRenameProps = {
  project: Project;
  onClose: () => void;
};

export const ProjectRenameDialog = ({ project, onClose }: ProjectRenameProps) => {
  const [name, setName] = useState(project.name);
  const [validationError, setValidationError] = useState<Validation<{ name: string }> | null>(null);

  const [updateProjectName, updateProjectNameRes] = consoleApi.useUpdateProjectNameMutation();

  const handleNameChange = (value: string) => {
    if (isNotNullish(validationError)) {
      setValidationError(null);
    }
    setName(value);
  };

  const handleRename = (event: SyntheticEvent) => {
    event.preventDefault();
    const validation = validate({ name });
    if (isNotNullish(validation)) {
      setValidationError(validation);
      return;
    }
    updateProjectName({ projectId: project.id, name: name.trim() })
      .unwrap()
      .then(() => {
        onClose();
      })
      .catch((error: FetchBaseQueryError | SerializedError | undefined) => {
        const apiError = getApiError(error);
        const errorMessage = getErrorMessage(apiError);
        logger.error(errorMessage);
      });
  };

  return (
    <Dialog isOpen onClose={onClose}>
      <Dialog.Header>Rename {project.name}</Dialog.Header>
      <form onSubmit={handleRename}>
        <Dialog.Content className="flex flex-col gap-4">
          <TextInput
            value={name}
            onChange={(event) => handleNameChange(event.target.value)}
            isFluid
            errorText={validationError?.name?.message}
            label="Name"
          />
          {updateProjectNameRes.isError && (
            <Banner type="danger" hasIcon usage="inline">
              {getErrorMessage(getApiError(updateProjectNameRes.error))}
            </Banner>
          )}
        </Dialog.Content>
        <Dialog.Actions>
          <Button color="neutral" fill="outlined" onClick={onClose}>
            Close
          </Button>
          <Button
            color="primary"
            type="submit"
            isLoading={updateProjectNameRes.isLoading}
            isDisabled={isNotNullish(validationError)}
          >
            Update
          </Button>
        </Dialog.Actions>
      </form>
    </Dialog>
  );
};
