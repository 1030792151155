import type { RawFilterSelection } from '@nx/state';
import type { SortingState } from '@tanstack/react-table';

export const DEFAULT_SORT_SUMMARY: SortingState = [
  {
    id: 'executionCount',
    desc: true,
  },
];

export const DEFAULT_SORT_DETAILS: SortingState = [
  {
    id: 'status',
    desc: true,
  },
];

export const initialFilters = (): RawFilterSelection => ({
  minDuration: 0,
  dbmsFilters: {
    gqlStatuses: [],
    queryLanguages: [],
    databases: [],
    users: [],
    statuses: [],
    drivers: [],
    apps: [],
    initiationTypes: [],
  },
  timeRange: { endTime: new Date() },
  querySearchString: '',
  errorSearchString: '',
  query: '',
});

export const WIDTH_MD = 448;
