import { useDispatch } from '../context';
import * as Desktop from '../slices/desktop/desktop';

export function useDesktopActions() {
  const dispatch = useDispatch();

  return {
    setRelateTokens: (...args: Parameters<typeof Desktop.setRelateTokens>) => {
      return dispatch(Desktop.setRelateTokens(...args));
    },
    setDesktopContext: (...args: Parameters<typeof Desktop.setDesktopContext>) => {
      return dispatch(Desktop.setDesktopContext(...args));
    },
  };
}
