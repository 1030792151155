import { uniqWith } from 'lodash-es';

import { SUGGESTION_ANY_RELATIONSHIP } from '../../../../../modules/SearchBar/SearchBar.const';
import { buildSuggestion, isNodeSuggestion } from '../../../../../modules/SearchBar/SearchBar.utils';
import type { NodeSuggestion, Suggestion } from '../../../../../modules/SearchBar/types';
import { DATE_TIME_TYPE } from '../../../../../services/temporal/utils.const';

export const findCategoryByLabel = (
  labels: string[],
  categoryToLabelDictionary: Map<string, string[]>,
  labelToCategoryMap: Map<string, string>, // result cache
): string | undefined => {
  for (const label of labels) {
    const mappedCategory = labelToCategoryMap.get(label);
    if (typeof mappedCategory === 'string') return mappedCategory;
    for (const [categoryName, categoryLabels] of categoryToLabelDictionary.entries()) {
      if (categoryLabels.includes(label)) {
        labelToCategoryMap.set(label, categoryName);
        return categoryName;
      }
    }
  }
  return undefined;
};

export const getFlatSortedResults = (
  results: NodeSuggestion[],
  lockedSuggestionsHead?: Suggestion,
  isCaseInsensitive = false,
) => {
  return uniqWith(results, isEqual)
    .sort((suggestionA, suggestionB) => {
      return (suggestionA.propertyConditionValue?.length ?? 0) - (suggestionB.propertyConditionValue?.length ?? 0);
    })
    .map((suggestion) => buildSuggestion<NodeSuggestion>({ ...suggestion, isCaseInsensitive }))
    .map((suggestion) => normalizeGraphPatternSuggestion(suggestion, lockedSuggestionsHead));
};

const isEqual = (value: NodeSuggestion, other: NodeSuggestion) => {
  return (
    value.categoryName === other.categoryName &&
    value.propertyName === other.propertyName &&
    value.propertyConditionValue === other.propertyConditionValue
  );
};

const normalizeGraphPatternSuggestion = (suggestion: NodeSuggestion, lockedSuggestionsHead?: Suggestion) => {
  return isNodeSuggestion(lockedSuggestionsHead) ? [SUGGESTION_ANY_RELATIONSHIP, suggestion] : suggestion;
};

export const shouldMatchForProperty = (input: string, type: string) => {
  const isInteger = !isNaN(Number(input)) && !input.toString().includes('.');
  const isFloat = !isNaN(Number(input)) && input.toString().includes('.');

  const includesNumbers = new RegExp(/\d/).test(input);
  const includesOnlyNumbers = new RegExp(/^\d+$/).test(input);

  if (type === 'string') {
    return true;
  } else if (type === 'boolean' && ['true', 'false'].includes(input)) {
    return true;
  } else if (type === 'bigint' && isInteger) {
    return true;
  } else if (type === 'array') {
    return true;
  } else if (type === 'number' && (isFloat || includesOnlyNumbers)) {
    return true;
  }
  return DATE_TIME_TYPE.includes(type) && includesNumbers;
};
