import { v1 as uuidv1 } from 'uuid';

import type { Scene } from '../../types/scene';
import bolt, { MANAGED_ROLLBACK_ERROR } from '../bolt/bolt';
import { isForbiddenError } from '../errors/errorUtils';
import type { WithCode } from '../errors/errorUtils';
import { log } from '../logging';
import {
  checkSceneWritePermissionQuery,
  deleteSceneQuery,
  deleteScenesFromPerspectiveQuery,
  duplicateSceneQuery,
  getAllScenesQuery,
  getFullSceneQuery,
  getPerspectiveAndScenePermissionQuery,
  saveNewSceneQuery,
  updateSceneQuery,
} from './queryGenerator';
import { perspectiveAndScenePermissionMapper, sceneMapper, scenesMapper } from './resultMapper';

export const getAllScenes = async (
  perspectiveId: string,
  username: string,
  userRoles: string[],
): Promise<Scene[] | undefined> => {
  const { cypher, parameters } = getAllScenesQuery(perspectiveId, username, userRoles);

  try {
    const result = await bolt.readTransaction(cypher, { parameters });
    return scenesMapper(result);
  } catch (err) {
    log.error(err);
    return undefined;
  }
};

export const saveNewScene = async (perspectiveId: string, scene: Scene) => {
  const { cypher, parameters } = saveNewSceneQuery(perspectiveId, scene);

  const result = await bolt.writeTransaction(cypher, { parameters });
  return sceneMapper(result);
};

export const duplicateScene = async (perspectiveId: string, username: string, fromSceneId: string) => {
  const sceneId = uuidv1();

  const { cypher, parameters } = duplicateSceneQuery(perspectiveId, fromSceneId, sceneId, username, new Date());

  const result = await bolt.writeTransaction(cypher, { parameters });
  return sceneMapper(result);
};

export const deleteScene = async (perspectiveId: string, sceneId: string) => {
  const { cypher, parameters } = deleteSceneQuery(perspectiveId, sceneId);

  try {
    return await bolt.writeTransaction(cypher, { parameters });
  } catch (err) {
    log.error(err);
  }
};

export const deleteScenesFromPerspective = async (perspectiveId: string) => {
  const { cypher, parameters } = deleteScenesFromPerspectiveQuery(perspectiveId);

  try {
    return await bolt.writeTransaction(cypher, { parameters });
  } catch (err) {
    log.error(err);
  }
};

export const updateScene = async (scene: Scene) => {
  const { cypher, parameters } = updateSceneQuery(scene);

  const result = await bolt.writeTransaction(cypher, { parameters });
  return sceneMapper(result);
};

export const getFullScene = async (sceneId: string) => {
  const { cypher, parameters } = getFullSceneQuery(sceneId);

  try {
    const result = await bolt.readTransaction(cypher, { parameters });
    return sceneMapper(result);
  } catch (err) {
    log.error(`Scene ${sceneId} could not be loaded:`, err);
  }
};

export const checkSceneWritePermission = async (database: string) => {
  const { cypher, parameters } = checkSceneWritePermissionQuery();
  try {
    await bolt.writeAndRollbackTransaction(cypher, { parameters, database });
    return true;
  } catch (err) {
    if ((err as { message: string }).message === MANAGED_ROLLBACK_ERROR) {
      log.debug('User is allowed to update Scene');
      return true;
    }
    if (isForbiddenError(err as WithCode)) {
      log.info('User is not allowed to update Scene');
      log.debug(err);
    }
  }
  return false;
};

export const getPerspectiveAndScenePermission = async (
  sceneId: string,
  databases: { name: string }[],
  username: string,
  userRoles: string[],
) => {
  const { cypher, parameters } = getPerspectiveAndScenePermissionQuery(sceneId, username, userRoles);
  try {
    for (const db of databases) {
      const result = await bolt.readTransaction(cypher, { parameters, database: db.name });
      if (result?.records?.length > 0) {
        return perspectiveAndScenePermissionMapper(result);
      }
    }
  } catch (err) {
    log.error(err);
  }
  return { sceneExist: false };
};
