import { Banner, Button, Checkbox, ClipboardButton, Label, TextInput, TextLink, Typography } from '@neo4j-ndl/react';
import type { Instance } from '@nx/state';
import { AWS_ENDPOINT_CONNECTION_STATE, AZURE_ENDPOINT_CONNECTION_STATE, TRAFFIC_ENABLEMENT } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { ComponentProps, ReactNode } from 'react';

export interface ConnectionRequest {
  id: string;
  state: AWS_ENDPOINT_CONNECTION_STATE | AZURE_ENDPOINT_CONNECTION_STATE;
}

interface Props {
  value: string;
  loading: boolean;
  inputErrorText?: string;
  connectionRequests: ConnectionRequest[];
  acceptMessage: {
    message: ReactNode;
    messageType: ComponentProps<typeof Banner>['type'];
  } | null;
  onChange: (value: string) => void;
  onAccept: () => void;
}

export const ConnectionRequestAcceptor = ({
  value,
  loading,
  inputErrorText,
  connectionRequests,
  acceptMessage,
  onChange,
  onAccept,
}: Props) => {
  const getConnectionStateLabelColor = (state: AWS_ENDPOINT_CONNECTION_STATE | AZURE_ENDPOINT_CONNECTION_STATE) => {
    if (state === AWS_ENDPOINT_CONNECTION_STATE.ACCEPTED || state === AZURE_ENDPOINT_CONNECTION_STATE.APPROVED) {
      return 'success';
    }

    if (state === AWS_ENDPOINT_CONNECTION_STATE.REJECTED || state === AZURE_ENDPOINT_CONNECTION_STATE.REJECTED) {
      return 'danger';
    }

    return 'default';
  };
  return (
    <div className="flex flex-col gap-4">
      <h6>Endpoint Connection Requests</h6>
      <div className="ml-6">
        {connectionRequests.length === 0 && (
          <div className="flex flex-col gap-2">
            <p>No endpoint connections requests</p>
            <p className="text-color-secondary">
              Follow the instructions on the previous step to make an endpoint connection request.
            </p>
          </div>
        )}

        {connectionRequests.length > 0 && (
          <ul className="ml-6 flex list-disc flex-col gap-2">
            {connectionRequests.map((conn) => {
              return (
                <li key={conn.id}>
                  <div className="flex flex-nowrap items-center justify-between gap-2">
                    <p
                      className="overflow-hidden text-ellipsis whitespace-nowrap"
                      title={conn.id}
                      data-testid="connection-endpoint"
                    >
                      {conn.id}
                    </p>
                    <span className="flex flex-nowrap items-center gap-1">
                      <ClipboardButton textToCopy={conn.id} />
                      <Label
                        fill="semi-filled"
                        color={getConnectionStateLabelColor(conn.state)}
                        htmlAttributes={{
                          'data-testid': 'connection-state-label',
                          title: conn.state,
                        }}
                      >
                        {conn.state}
                      </Label>
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div className="ml-6 flex flex-nowrap items-end gap-2">
        <TextInput
          label="Accept endpoint connection request"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          errorText={inputErrorText}
          className="flex-1"
          isFluid
          htmlAttributes={{
            placeholder: 'Enter endpoint ID...',
            'data-testid': 'endpoint-id',
          }}
        />
        <Button
          onClick={onAccept}
          isLoading={loading}
          fill="outlined"
          htmlAttributes={{
            'data-testid': 'accept-endpoint',
          }}
        >
          Accept
        </Button>
      </div>
      {isNotNullish(acceptMessage?.message) && (
        <Banner type={acceptMessage.messageType} description={acceptMessage.message} usage="inline" />
      )}
    </div>
  );
};

interface PrivateConnectionProps {
  publicTraffic: TRAFFIC_ENABLEMENT;
  onPublicTrafficDisabledChange: (checked: boolean) => void;
  dnsDomain?: string;
  affectedInstances: Instance[];
}

export const PrivateConnection = ({
  publicTraffic,
  onPublicTrafficDisabledChange,
  dnsDomain,
  affectedInstances,
}: PrivateConnectionProps) => {
  return (
    <>
      <Checkbox
        isChecked={publicTraffic !== TRAFFIC_ENABLEMENT.ENABLED}
        onChange={(e) => onPublicTrafficDisabledChange(e.target.checked)}
        label="Disable public traffic"
        htmlAttributes={{
          'data-testid': 'dialog-disable-public',
        }}
      />
      {publicTraffic === TRAFFIC_ENABLEMENT.DISABLED && (
        <>
          <Banner
            hasIcon
            type="warning"
            description={
              <>
                <p>
                  Ensure you have changed the application connection string to <code>{`<dbid>.${dnsDomain}`}</code>,
                  otherwise your applications will be unable to connect to the database.
                </p>
                {affectedInstances.length > 0 && (
                  <div className="mt-2">
                    The following databases will be affected:
                    <ul className="ml-4 mt-1 list-inside list-disc">
                      {affectedInstances.map((instance) => (
                        <Typography variant="label" key={instance.id} as="li">
                          {instance.name} - {instance.id}
                        </Typography>
                      ))}
                    </ul>
                  </div>
                )}
              </>
            }
            usage="inline"
          />
          {affectedInstances.length > 0 && (
            <Banner
              hasIcon
              type="warning"
              description={
                <>
                  <p>
                    If you have SSO configured for any of the above instances you will need to update the Redirect URLs
                    in your IDP configuration. For more information, please review our{' '}
                    <a
                      href={'https://neo4j.com/docs/operations-manual/current/tutorial/tutorial-sso-configuration/'}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      documentation
                    </a>{' '}
                    or contact support.
                  </p>
                </>
              }
              usage="inline"
            />
          )}
        </>
      )}
    </>
  );
};

interface ConfirmCheckboxProps {
  isConfirmed: boolean;
  onConfirmChange: (checked: boolean) => void;
}

export const ConfirmCheckbox = ({ isConfirmed, onConfirmChange }: ConfirmCheckboxProps) => {
  return (
    <Checkbox
      isChecked={isConfirmed}
      onChange={(e) => onConfirmChange(e.target.checked)}
      ariaLabel="I have read and acknowledged the VPN statement, and have verified the
        functionality of my private endpoint connection."
      label={
        <div>
          I have read and acknowledged the VPN statement, and have verified the functionality of my private endpoint
          connection. To test your connectivity, please follow this{' '}
          <TextLink
            href="https://support.neo4j.com/s/article/13174783967507-How-To-Test-Connectivity-Through-The-Private-Endpoint"
            isExternalLink
          >
            guide.
          </TextLink>
        </div>
      }
      htmlAttributes={{
        'data-testid': 'dialog-confirm',
      }}
    />
  );
};

interface VpnMessageProps {
  href: string;
}

export const VpnMessage = ({ href }: VpnMessageProps) => {
  return (
    <Banner
      hasIcon
      type="warning"
      description={
        <>
          <p>
            To continue accessing Browser and Bloom, you can configure a VPN (Virtual Private Network) in your VPC and
            connect to these services over the VPN.
          </p>
          <p className="mt-3">
            See{' '}
            <TextLink
              href={href}
              isExternalLink
              htmlAttributes={{
                rel: 'noreferrer',
              }}
            >
              Aura Docs
            </TextLink>{' '}
            for more information on setting up a VPN.
          </p>
        </>
      }
      usage="inline"
    />
  );
};
