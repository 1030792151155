import { Dialog, IconButton } from '@neo4j-ndl/react';
import { ArrowsPointingOutIconOutline } from '@neo4j-ndl/react/icons';
import { isNullish } from '@nx/stdlib';
import { useState } from 'react';

export const Image = ({ src, alt, title }: { src: string; alt?: string; title?: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showExpandIcon, setShowExpandIcon] = useState(false);
  return (
    <>
      <div className="border-palette-neutral-bg-strong relative flex w-full justify-center rounded-lg border-[1px]">
        <img
          className="p-2"
          src={src}
          onLoad={() => setShowExpandIcon(true)}
          onError={() => setShowExpandIcon(false)}
          alt={alt}
        />
        {showExpandIcon && (
          <div className="absolute bottom-1 right-1">
            <IconButton ariaLabel="Zoom" isClean onClick={() => setIsOpen(true)}>
              <ArrowsPointingOutIconOutline className="text-palette-neutral-text-weak" />
            </IconButton>
          </div>
        )}
      </div>
      {!isNullish(title) && title !== '' && (
        <div className="n-body-small text-palette-neutral-text-weak mt-1 w-full text-center">{title}</div>
      )}
      <Dialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {!isNullish(title) && title !== '' && <Dialog.Header>{title}</Dialog.Header>}
        <img className={isNullish(title) || title === '' ? 'pt-12' : ''} src={src} />
      </Dialog>
    </>
  );
};
