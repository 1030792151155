import { opsApi, useActiveProject } from '@nx/state';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import classNames from 'classnames';
import type { PropsWithChildren } from 'react';

import { ApiErrorBanner } from '../../../shared/components';
import { useLogsContext } from '../hooks/use-logs-context';
import { StatusSnackbar } from './status-snackbar';

export const TableControls: React.FunctionComponent<
  PropsWithChildren<{
    fetchError?: FetchBaseQueryError | SerializedError | undefined;
    selectedDbmsId: string;
    className?: string;
  }>
> = ({ fetchError, selectedDbmsId, children, className }) => {
  const activeProject = useActiveProject();
  const selectedTenantId = activeProject.id;
  const { filterInput, hasRequestedData } = useLogsContext();

  const { data: countsData, isFetching: countsLoading } = opsApi.endpoints.getQueryLogCounts.useQuery(
    {
      tenantId: selectedTenantId,
      dbmsId: selectedDbmsId,
      filter: filterInput,
    },
    { skip: !selectedDbmsId || !selectedTenantId || !hasRequestedData },
  );

  return (
    <div className={classNames('relative w-full', className)}>
      <div className="flex flex-col gap-2">
        {fetchError && <ApiErrorBanner error={fetchError} />}

        {hasRequestedData && <StatusSnackbar counts={countsData} loading={countsLoading} />}
      </div>
      {children}
    </div>
  );
};
