import { CommandErrors, ParamErrors } from '@query/constants/errors';
import type { QueryError } from '@query/types/query';

export type ParsedParamArguments =
  | { arg: 'set-single'; paramName: string; expression: string }
  | { arg: 'set-map'; mapExpression: string }
  | { arg: 'show' | 'clear' }
  | { arg: 'error'; error: QueryError };

/**
 * Parse arguments sent to ":param"
 */
export function parseParamArgs(paramsCmd: string): ParsedParamArguments {
  const paramsPrefix = ':params';
  const paramPrefix = ':param';
  const hasParamsPrefix = paramsCmd.toLowerCase().startsWith(paramsPrefix);
  const hasParamPrefix = paramsCmd.toLowerCase().startsWith(paramPrefix);

  if (!hasParamPrefix && !hasParamsPrefix) {
    return { arg: 'error', error: CommandErrors.UnknownCommand(paramsCmd) };
  }

  const paramArgument = paramsCmd.slice(hasParamsPrefix ? paramsPrefix.length : paramPrefix.length).trim();

  if (paramArgument === '' || paramArgument === 'list') {
    return { arg: 'show' };
  }

  if (paramArgument.toLowerCase() === 'clear') {
    return { arg: 'clear' };
  }

  const isSetSingleParam = paramArgument.includes('=>');
  const isSetMultiple = paramArgument.startsWith('{');

  if (isSetMultiple) {
    return { arg: 'set-map', mapExpression: paramArgument };
  }

  if (isSetSingleParam) {
    const [untrimmedName, ...rest] = paramArgument.split('=>');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const paramName = untrimmedName.trim();
    const expression = rest.join('=>').trim();

    if (paramName === '') {
      return { arg: 'error', error: ParamErrors.MissingParamName };
    }

    // if name is backticked it can contain basically anything.
    // parsing backticks 100% correctly is hard so we use a heuristic
    const seemsBackticked = paramName.length >= 2 && paramName.startsWith('`') && paramName.endsWith('`');

    if (!seemsBackticked) {
      // For non backticked names, the naming rules for variables apply see doc link
      // https://neo4j.com/docs/cypher-manual/current/syntax/naming/
      // with the expection that numbers are allowed
      if (paramName.startsWith('$')) {
        return { arg: 'error', error: ParamErrors.ParamNameStartsWithDollar };
      }
      // Check white space and most common symbols
      if (/[-!%^&*()+|~=`{}[\]:";'<>?,./\s]/.test(paramName)) {
        return { arg: 'error', error: ParamErrors.ParamNameIllegalCharacters(paramName) };
      }
    }

    if (expression === '') {
      return { arg: 'error', error: ParamErrors.MissingExpression };
    }

    return { arg: 'set-single', paramName: seemsBackticked ? paramName.slice(1, -1) : paramName, expression };
  }

  return { arg: 'error', error: ParamErrors.UnknownArgument(paramArgument) };
}
