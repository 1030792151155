import { APP_SCOPE } from '@nx/constants';
import type * as ImportShared from '@nx/import-shared';
import { createLogger } from '@nx/logger';
import { neo4jVersionUtil } from '@nx/neo4j-version-utils';
import { isNullish } from '@nx/stdlib';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { APP_VERSION } from '../constants';
import { emptyDataModel } from '../data-model/data-model.json.helpers';
import { migrateDataModelToLatestVersion } from '../data-model/migrations';
import { loadLegacyStateFromStorage, loadStateFromStorage } from '../helpers/storage';
import type { ApplicationState } from '../types';
import {
  getDataModelFromAnyModel,
  getVisualisationFromAnyModel,
  migrateArrowsGraphToVisualisationState,
} from '../utils/migrations';
import { loadModel } from './actions/actions';
import { cloudImportJobsSlice } from './apis/cloud-import-jobs';
import { sourceSchemaSlice } from './apis/data-sources';
import { sharedStorageSlice } from './apis/storage';
import { storageListenerMiddleware } from './middleware/storage';
import { trackingListenerMiddleware } from './middleware/tracking';
import { uiMiddleware } from './middleware/ui';
import configurations, { initialiseConfigurations, toggleShouldClearLocalStorage } from './reducers/configurations';
import dataModel from './reducers/data-model';
import ui from './reducers/ui';
import visualisation, { emptyVisualisationState } from './reducers/visualisation';

const logger = createLogger(APP_SCOPE.import);

const rootReducer = combineReducers({
  dataModel,
  configurations,
  ui,
  visualisation,
  [cloudImportJobsSlice.reducerPath]: cloudImportJobsSlice.reducer,
  [sourceSchemaSlice.reducerPath]: sourceSchemaSlice.reducer,
  [sharedStorageSlice.reducerPath]: sharedStorageSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(storageListenerMiddleware.middleware, trackingListenerMiddleware.middleware)
      .concat(
        uiMiddleware,
        cloudImportJobsSlice.middleware,
        sourceSchemaSlice.middleware,
        sharedStorageSlice.middleware,
      ),
  devTools: { name: 'Import', trace: true },
});

const setToLoadedState = (dispatch: AppDispatch, loadedState: ApplicationState) => {
  loadModel(dispatch)({
    dataModel: loadedState.dataModel,
    visualisation: loadedState.visualisation,
  });
  dispatch(initialiseConfigurations(loadedState.configurations));
};

export const loadState = () => {
  // state loaded from old key
  const loadedLegacyState = loadLegacyStateFromStorage();
  const loadedState = loadStateFromStorage();

  // If there already exists a model then load it
  if (!isNullish(loadedState)) {
    let latestDataModel: ImportShared.DataModelJsonStruct | undefined = undefined;
    try {
      latestDataModel = getDataModelFromAnyModel(loadedState);
    } catch {
      logger.error('No model found. Loading empty model');
    }
    if (isNullish(latestDataModel)) {
      setToLoadedState(store.dispatch, {
        ...loadedState,
        dataModel: emptyDataModel,
        visualisation: emptyVisualisationState,
      });
    } else if (neo4jVersionUtil.lt(APP_VERSION, loadedState.version)) {
      // If version loaded is newer than current version of app, then open dialog about clearing localStorage
      store.dispatch(toggleShouldClearLocalStorage(true));
    } else {
      let vizState: ImportShared.VisualisationState | undefined = undefined;
      try {
        vizState = getVisualisationFromAnyModel(loadedState, latestDataModel);
      } catch {
        logger.error('No visualisation state found. Loading empty visualisation state');
        vizState = emptyVisualisationState;
      }
      setToLoadedState(store.dispatch, {
        ...loadedState,
        visualisation: vizState,
        dataModel: latestDataModel,
      });
    }
  } else if (!isNullish(loadedLegacyState) && loadedLegacyState.dataModel !== undefined) {
    // No new model exists, but there exists a legacy model, then it means that it is not yet migrated, so migrate it
    const migratedDataModel =
      // Migrate old data structure from localStorage to latest version.
      migrateDataModelToLatestVersion(loadedLegacyState.dataModel, loadedLegacyState.version);

    // That also means that the visualisations state is still an arrows graph so migrate that as well
    const arrowsGraph = loadedLegacyState.graph ?? { nodes: [], relationships: [] };
    const visState = migrateArrowsGraphToVisualisationState(arrowsGraph);

    setToLoadedState(store.dispatch, {
      version: APP_VERSION,
      configurations: { ...loadedLegacyState.configurations },
      visualisation: visState,
      dataModel: migratedDataModel,
    });
  }
};

loadState();

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
