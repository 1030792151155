import { Banner, Button, Checkbox, Dialog } from '@neo4j-ndl/react';
import { AURA_CONSOLE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import type { Instance, Project } from '@nx/state';
import { PROJECT_BILLING_METHOD, TIER, consoleApi, getApiError, getErrorMessage } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { SyntheticEvent } from 'react';
import { useMemo, useState } from 'react';

import { useTrackUpxEvent } from '../../services/segment/analytics';
import { getVisibleSizes } from '../create/helper';
import { gbStringToInt, getValidSize } from '../entities/helpers';
import { ConfigureInstanceForm, PriceDescription, validate } from './form';
import type { InstanceConfigureFormData } from './form-data';

const logger = createLogger(APP_SCOPE.framework);

type Props = {
  onClose: () => void;
  onConfirm: () => void;
  instance: Instance;
  project: Project;
};

export const ConfigureInstanceModal = ({ onClose, instance, project }: Props) => {
  const trackEvent = useTrackUpxEvent();
  const visibleSizes = useMemo(
    () =>
      getVisibleSizes(project.tierConfigs[instance.tier]?.sizes ?? [], {
        version: instance.desiredSettings.version,
        region: instance.region,
        cloudProvider: instance.cloudProvider,
      }),
    [project, instance],
  );
  const [updateInstanceConfig, updateInstanceConfigRes] = consoleApi.useUpdateInstanceConfigMutation();

  const errorMessage = useMemo(() => {
    if (!updateInstanceConfigRes.isError) {
      return null;
    }
    const error = getApiError(updateInstanceConfigRes.error);
    const message = getErrorMessage(error);
    return message;
  }, [updateInstanceConfigRes.error, updateInstanceConfigRes.isError]);

  const initialSize = useMemo(
    () =>
      visibleSizes.find(
        (size) => size.memory === instance.desiredSettings.memory && size.storage === instance.desiredSettings.storage,
      ),
    [visibleSizes, instance],
  );

  const [data, setData] = useState<InstanceConfigureFormData>(() => {
    const defaults = {
      confirmed: false,
      size: undefined,
      vectorOptimized: instance.vectorOptimized,
      gdsPlugin: instance.gdsPlugin,
      tier: instance.tier,
      version: instance.desiredSettings.version,
      cloudProvider: instance.cloudProvider,
      region: instance.region,
    };

    return {
      ...defaults,
      size: getValidSize(defaults, project, visibleSizes, {
        defaultMemory: initialSize?.memory,
        resizeThreshold: instance.resizeThreshold,
      }),
    };
  });

  const handleConfirmChange = (checked: boolean) => setData({ ...data, confirmed: checked });

  const handleSubmitNewConfiguration = (event: SyntheticEvent) => {
    event.preventDefault();
    if (isNotNullish(data.size) && isNotNullish(data.vectorOptimized) && isNotNullish(data.gdsPlugin)) {
      updateInstanceConfig({
        dbId: instance.id,
        memory: data.size.memory,
        storage: data.size.storage,
        cpu: data.size.cpu,
        vectorOptimized: data.vectorOptimized,
        gdsPlugin: data.gdsPlugin,
      })
        .unwrap()
        .then(() => {
          trackEvent({
            event: AURA_CONSOLE_EVENTS.INSTANCE_CONFIG_UPDATE,
            scope: APP_SCOPE.aura,
          });
          onClose();
        })
        .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
          const error = getApiError(e);
          if (isNotNullish(error.message)) {
            logger.error(error.message);
          }
        });
    }
  };

  const dataHasChanged =
    data.size !== initialSize ||
    data.vectorOptimized !== instance.vectorOptimized ||
    data.gdsPlugin !== instance.gdsPlugin;

  const validationOk = useMemo(() => validate(data, initialSize), [data, initialSize]);

  const isPrepaidProject = project.billingMethod === PROJECT_BILLING_METHOD.PREPAID;
  const isPrepaidOnlyOptionSelected = useMemo(() => data.size?.isPrepaidOnly, [data]);
  const disableResize = useMemo(() => {
    return !dataHasChanged || !validationOk || (Boolean(isPrepaidOnlyOptionSelected) && !isPrepaidProject);
  }, [validationOk, isPrepaidOnlyOptionSelected, isPrepaidProject, dataHasChanged]);

  const configurationMessage = [TIER.GDS, TIER.AURA_DSE].includes(instance.tier)
    ? 'Re-configuring your instance will schedule a small amount of unavailability.'
    : 'Read / write availability is maintained during a configuration change.';

  return (
    <Dialog
      isOpen
      onClose={onClose}
      modalProps={{
        'data-testid': 'configure-instance-modal',
      }}
      size="large"
    >
      <Dialog.Header>Configure instance</Dialog.Header>
      <form onSubmit={handleSubmitNewConfiguration} data-testid="configure-db-form">
        <Dialog.Content className="flex flex-col gap-6">
          {/* RESIZE TABLE */}
          <div>
            <Dialog.Subtitle className="text-xl">Resize</Dialog.Subtitle>
            <div className="flex flex-col gap-4">
              <ConfigureInstanceForm
                data={data}
                onChange={setData}
                instance={instance}
                project={project}
                initialSize={initialSize}
                sizes={visibleSizes}
              />
              {isNotNullish(errorMessage) && <Banner type="danger" description={errorMessage} usage="inline" />}
            </div>
          </div>

          {/* ALERTS & FINAL CONFIRMATION */}
          <Banner description={configurationMessage} type="info" usage="inline" />
          {dataHasChanged && (
            <>
              {isNotNullish(data.gdsPlugin) && data.gdsPlugin !== instance.gdsPlugin && !data.gdsPlugin && (
                <Banner description="GDS Plugin has changed." type="info" usage="inline">
                  {gbStringToInt(data.size!.memory) < 4
                    ? `Resizing to a ${gbStringToInt(data.size!.memory)}GB instance will remove `
                    : 'You are removing '}
                  the GDS plugin from this instance.
                </Banner>
              )}

              {isNotNullish(data.vectorOptimized) &&
                data.vectorOptimized !== instance.vectorOptimized &&
                !data.vectorOptimized && (
                  <Banner description="Vector Optimized has changed." type="info" usage="inline">
                    {gbStringToInt(data.size!.memory) < 4
                      ? `Resizing to a ${gbStringToInt(data.size!.memory)}GB instance will remove  `
                      : 'You are removing '}
                    vector-optimized configuration from this instance. Vector Search performance might be affected.
                  </Banner>
                )}

              {data.size && data.size !== initialSize && (
                <>
                  <PriceDescription planType={project.planType} tier={instance.tier} size={data.size} />
                  <Checkbox
                    isChecked={data.confirmed}
                    onChange={({ target }) => handleConfirmChange(target.checked)}
                    label="I accept"
                    htmlAttributes={{
                      'data-testid': 'pricing-confirmation-check',
                    }}
                  />
                </>
              )}
            </>
          )}
        </Dialog.Content>

        {/* FORM SUBMISSION */}
        <Dialog.Actions>
          <Button
            onClick={onClose}
            fill="outlined"
            color="neutral"
            isDisabled={updateInstanceConfigRes.isLoading}
            htmlAttributes={{
              'data-testid': 'clone-cancel-button',
            }}
          >
            Cancel
          </Button>
          <Button
            isDisabled={disableResize}
            type="submit"
            isLoading={updateInstanceConfigRes.isLoading}
            htmlAttributes={{
              'data-testid': 'update-instance',
            }}
          >
            Configure
          </Button>
        </Dialog.Actions>
      </form>
    </Dialog>
  );
};
