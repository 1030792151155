import { isNotNullish } from '@nx/stdlib';

import type {
  TrafficConfigResponse,
  TransformedUpdateTrafficConfigBody,
  UpdateTrafficConfigRequest,
} from '../console.api.types';
import type { TrafficConfig } from '../console.types';

export const transformTrafficConfigResponse = (response: TrafficConfigResponse): TrafficConfig => ({
  cloudProvider: response.cloud_provider,
  tier: response.tier,
  region: response.region,
  privateTraffic: response.private_traffic,
  publicTraffic: response.public_traffic,
  gcpProperties: isNotNullish(response.gcp_properties)
    ? { projectIds: response.gcp_properties.project_ids }
    : undefined,
  awsProperties: isNotNullish(response.aws_properties)
    ? { endpointIds: response.aws_properties.endpoint_ids }
    : undefined,
  azureProperties: isNotNullish(response.azure_properties)
    ? {
        endpointIds: response.azure_properties.endpoint_ids,
        subscriptionIds: response.azure_properties.subscription_ids,
      }
    : undefined,
  status: {
    publicTraffic: response.status.public_traffic,
    privateTraffic: response.status.private_traffic,
    dnsDomain: response.status.dns_domain,
    gcpStatus: isNotNullish(response.status.gcp_status)
      ? { serviceAttachmentUrl: response.status.gcp_status.service_attachment_url }
      : undefined,
    awsStatus: isNotNullish(response.status.aws_status)
      ? {
          endpointServiceName: response.status.aws_status.endpoint_service_name,
          endpointConnections: response.status.aws_status.endpoint_connections.map((conn) => ({
            endpointId: conn.endpoint_id,
            state: conn.state,
          })),
        }
      : undefined,
    azureStatus: isNotNullish(response.status.azure_status)
      ? {
          privateLinkServiceName: response.status.azure_status.private_link_service_name,
          privateEndpointConnections: response.status.azure_status.private_endpoint_connections.map((conn) => ({
            privateEndpointId: conn.private_endpoint_id,
            state: conn.state,
          })),
        }
      : undefined,
  },
});

export const transformUpdateTrafficConfigRequest = (
  request: UpdateTrafficConfigRequest,
): TransformedUpdateTrafficConfigBody => ({
  PrivateTraffic: request.privateTraffic,
  PublicTraffic: request.publicTraffic,
  GcpProperties: isNotNullish(request.gcpProperties) ? { ProjectIds: request.gcpProperties.projectIds } : undefined,
  AwsProperties: isNotNullish(request.awsProperties) ? { EndpointIds: request.awsProperties.endpointIds } : undefined,
  AzureProperties: isNotNullish(request.azureProperties)
    ? {
        SubscriptionIds: request.azureProperties.subscriptionIds,
        EndpointIds: request.azureProperties.endpointIds,
      }
    : undefined,
});
