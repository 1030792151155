import type { Instance, TimePeriod, TimeRange } from '@nx/state';
import { some } from 'lodash-es';
import { useEffect, useMemo } from 'react';

import type { ChartWidgetPartialProps } from '../../metrics/charts/fullstack-chart-props';
import timelineCharts from '../../metrics/charts/timeline-charts';
import type { ChartContext, MultiChartProps } from '../../metrics/charts/types';
import { useMetricsQuery } from '../../metrics/hooks';
import { metricKey } from '../../shared/utils';
import { QueryTimelineCard } from './query-timeline-card';

interface QueryTimelineProps {
  selectedInstance: Instance | undefined;
  setIsRequestDialogOpen: (a: boolean) => void;
  chartContext: ChartContext;
  setTimePeriod: (t: TimePeriod | undefined) => void;
  zoomIn: (t: TimeRange | null) => void;
  refreshTimeRange: () => void;
  setExpandedChart: (m: MultiChartProps | null) => void;
  setHasChangedSelection: (a: boolean) => void;
  hasChangedSelection: boolean;
  setHasQueries: (a: boolean) => void;
}

const seriesNameMapper = (props: ChartWidgetPartialProps) => props.subtitle ?? '';

export const QueryTimeline = ({
  chartContext,
  setTimePeriod,
  zoomIn,
  refreshTimeRange,
  setExpandedChart,
  selectedInstance,
  setIsRequestDialogOpen,
  setHasChangedSelection,
  hasChangedSelection,
  setHasQueries,
}: QueryTimelineProps) => {
  const metricsResult = useMetricsQuery({
    selectedInstanceDetails: selectedInstance,
    timeRange: chartContext.timeRange,
    chartProps: timelineCharts,
    chartType: 'HOST',
    seriesNameMapper,
    useAggregations: false,
  });

  const [QueryExecutionsSuccessRate, QueryExecutionsFailureRate] = timelineCharts;
  const hasQueries = useMemo(() => {
    const dataPointsGreaterThan0 = (chartProps: ChartWidgetPartialProps) =>
      Boolean(metricsResult.metrics) &&
      some(metricsResult.metrics?.[metricKey(chartProps, false, selectedInstance?.id)]?.dataSeries, (metrics) =>
        some(metrics, (metric) => (metric.value ?? 0) > 0),
      );
    return (
      (QueryExecutionsSuccessRate !== undefined && dataPointsGreaterThan0(QueryExecutionsSuccessRate)) ||
      (QueryExecutionsFailureRate !== undefined && dataPointsGreaterThan0(QueryExecutionsFailureRate))
    );
  }, [QueryExecutionsFailureRate, QueryExecutionsSuccessRate, metricsResult.metrics, selectedInstance?.id]);

  useEffect(() => {
    setHasQueries(hasQueries);
  }, [hasQueries, setHasQueries]);

  return (
    <QueryTimelineCard
      metricsResult={metricsResult}
      setIsRequestDialogOpen={setIsRequestDialogOpen}
      chartContext={chartContext}
      setTimePeriod={setTimePeriod}
      zoomIn={zoomIn}
      refreshTimeRange={refreshTimeRange}
      setExpandedChart={setExpandedChart}
      setHasChangedSelection={setHasChangedSelection}
      hasChangedSelection={hasChangedSelection}
      hasQueries={hasQueries}
      setHasQueries={setHasQueries}
    />
  );
};
