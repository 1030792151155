import type { SeriesSummary } from '@nx/state';
import { IndeterminateSwitch } from '@nx/ui';
import { isEqual } from 'lodash-es';
import { memo } from 'react';

import { Pill } from './pill';

type CollapsiblePillProps = {
  show: boolean;
  label: string;
  update?: () => void;
  fetchReferenceColor?: boolean;
};

export const Pills = memo(
  ({
    pills,
    currentIgnoredIds,
    updateIgnoredIds,
    getColor,
    showTotals,
  }: {
    pills: SeriesSummary[];
    currentIgnoredIds: string[];
    updateIgnoredIds: (values: string[]) => void;
    getColor: (id: string) => string;
    showTotals?: CollapsiblePillProps;
  }) => {
    const checked = !pills.some((pill) => currentIgnoredIds.includes(pill.id));
    const indeterminate = !checked && !pills.every((pill) => currentIgnoredIds.includes(pill.id));
    return (
      <div className="flex flex-wrap gap-4">
        {pills.map((pill) => (
          <Pill
            // eslint-disable-next-line no-confusing-arrow
            onClick={() =>
              currentIgnoredIds.includes(pill.id)
                ? updateIgnoredIds(currentIgnoredIds.filter((id) => id !== pill.id))
                : updateIgnoredIds(currentIgnoredIds.concat(pill.id))
            }
            key={pill.id}
            value={pill.displayName ?? pill.name}
            color={getColor(pill.id)}
            enabled={!currentIgnoredIds.includes(pill.id)}
            areaAlpha={pill.areaAlpha}
          />
        ))}
        {Boolean(showTotals) && (
          <Pill
            dashed
            key="available"
            value={showTotals?.label ?? ''}
            enabled={showTotals?.show}
            onClick={showTotals?.update}
            color={(showTotals?.fetchReferenceColor ?? false) ? getColor('reference') : undefined}
          />
        )}
        {pills.length > 3 && (
          <IndeterminateSwitch
            indeterminate={indeterminate}
            isChecked={checked}
            label="Show all"
            onChange={() => {
              const pillIds = pills.map((pill) => pill.id);
              if (checked) {
                updateIgnoredIds(currentIgnoredIds.concat(pillIds));
              } else {
                updateIgnoredIds(currentIgnoredIds.filter((id) => !pillIds.includes(id)));
              }
            }}
          />
        )}
      </div>
    );
  },
  isEqual,
);

Pills.displayName = 'Pills';
