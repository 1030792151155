import { Banner, Code, Dialog, TextLink } from '@neo4j-ndl/react';
import { CheckIconOutline, ClipboardDocumentIconOutline } from '@neo4j-ndl/react/icons';
import { useState } from 'react';

export const CellExpand = ({
  message,
  header,
  error,
  isCode,
}: {
  message: string | undefined;
  header: string | undefined;
  error?: string;
  isCode: boolean;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleClick = () => setIsOpen((prev) => !prev);
  const handleClose = () => setIsOpen(false);
  const [copying, setCopying] = useState(false);

  const copyCode = async () => {
    setCopying(true);
    await navigator.clipboard.writeText(message ?? '');
    setTimeout(() => setCopying(false), 1500);
  };
  return (
    <div>
      <TextLink as="button" className="cursor-pointer !p-0" htmlAttributes={{ onClick: handleClick }}>
        View more
      </TextLink>
      <Dialog size="large" isOpen={isOpen} onClose={handleClose}>
        <Dialog.Header>{header}</Dialog.Header>
        <Dialog.Content>
          <div className="flex flex-col gap-3">
            {isCode && (
              <Code
                language="cypher"
                code={message ?? ''}
                actions={[
                  {
                    ariaLabel: 'copy',
                    htmlAttributes: { title: 'copy' },
                    children: copying ? <CheckIconOutline /> : <ClipboardDocumentIconOutline />,
                    onClick: () => {
                      void copyCode().then();
                    },
                  },
                ]}
              />
            )}
            {!isCode && <span>{message}</span>}
            {Boolean(error) && <Banner type="danger" isCloseable={false} description={error} usage="inline" />}
          </div>
        </Dialog.Content>
      </Dialog>
    </div>
  );
};
