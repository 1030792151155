import { APP_SCOPE, isAppScope } from '@nx/constants';
import { createSelector } from '@reduxjs/toolkit';

import type * as Capabilities from '../../slices/capabilities-slice';
import type { RootState } from '../../store';
import * as Configuration from './configuration-slice';

export const selectConfiguration = (state: Pick<RootState, 'configuration'>) => state.configuration;

export const selectInternalConfiguration: (state: Pick<RootState, 'configuration'>) => {
  basePath: string;
  fallbackRoute: string;
  windowTitle?: string;
} = createSelector(
  (state: Pick<RootState, 'configuration'>) => state.configuration,
  Configuration.selectInternalConfiguration,
);

const CAPABILITY_BY_TOOL_ID: Partial<Record<APP_SCOPE, Capabilities.Capability>> = {
  [APP_SCOPE.explore]: 'explore:enabled',
  [APP_SCOPE.import]: 'import:enabled',
  [APP_SCOPE.query]: 'query:enabled',
  [APP_SCOPE.guides]: 'guides:enabled',
  [APP_SCOPE.dashboards]: 'dashboards:enabled',
  [APP_SCOPE.conversations]: 'conversations:enabled',
};

export function makeSelectToolConfiguration(
  toolId: APP_SCOPE,
): (state: Pick<RootState, 'capabilities' | 'configuration'>) => Configuration.ToolConfiguration | undefined {
  const selectToolConfiguration = Configuration.makeSelectToolConfiguration(toolId);
  return createSelector(
    (state: Pick<RootState, 'capabilities'>) => state.capabilities,
    (state: Pick<RootState, 'configuration'>) => selectToolConfiguration(state.configuration),
    (capabilities, configuration) => {
      if (toolId === APP_SCOPE.debugger) {
        return configuration;
      }

      const requiredCapability = CAPABILITY_BY_TOOL_ID[toolId];

      if (requiredCapability === undefined || !capabilities.includes(requiredCapability)) {
        return undefined;
      }

      return configuration;
    },
  );
}

export const selectToolConfigurations: (
  state: Pick<RootState, 'capabilities' | 'configuration'>,
) => ReturnType<typeof Configuration.selectToolConfigurations> = createSelector(
  makeSelectToolConfiguration(APP_SCOPE.debugger),
  makeSelectToolConfiguration(APP_SCOPE.dashboards),
  makeSelectToolConfiguration(APP_SCOPE.explore),
  makeSelectToolConfiguration(APP_SCOPE.import),
  makeSelectToolConfiguration(APP_SCOPE.query),
  makeSelectToolConfiguration(APP_SCOPE.guides),
  makeSelectToolConfiguration(APP_SCOPE.conversations),
  (debuggerConfig, dashboardsConfig, exploreConfig, importConfig, queryConfig, guidesConfig, conversationsConfig) =>
    Object.entries({
      [APP_SCOPE.debugger]: debuggerConfig,
      [APP_SCOPE.dashboards]: dashboardsConfig,
      [APP_SCOPE.explore]: exploreConfig,
      [APP_SCOPE.import]: importConfig,
      [APP_SCOPE.query]: queryConfig,
      [APP_SCOPE.guides]: guidesConfig,
      [APP_SCOPE.conversations]: conversationsConfig,
    })
      // The condition is necessary as there are cases in which there is a key for
      // the tool, but no configuration.
      .filter(([_, conf]) => conf !== undefined)
      .reduce((toolsConf, [key, value]) => ({ ...toolsConf, [key]: value }), {}),
);

export function getCurrentScope(
  toolsConfiguration: Partial<
    Record<
      APP_SCOPE,
      {
        displayName: string;
        route: string;
      }
    >
  >,
  location: { pathname: string },
): APP_SCOPE | null {
  const toolConfiguration = Object.entries(toolsConfiguration).find(
    ([, { route }]) => location.pathname === route || location.pathname.startsWith(`${route}/`),
  );
  const toolId = toolConfiguration?.[0];

  if (
    location.pathname.startsWith('/org/') ||
    location.pathname.startsWith('/projects/') ||
    location.pathname.startsWith('/account/')
  ) {
    return APP_SCOPE.aura;
  }

  if (toolId === undefined || !isAppScope(toolId)) {
    return null;
  }

  return toolId;
}
