import { blackActive, gray } from '../../styles/colors';

export const COLOR_MAPPING_THRESHOLD = 100;

// prefix 0 and 1 to guarantee category name and labels always precede property when displayed
export const CATEGORY_NAME_CAPTION_KEY = '0_CATEGORY_NAME_CAPTION_KEY';
export const CATEGORY_LABEL_CAPTION_KEY_PREFIX = '1_CATEGORY_LABEL_CAPTION_KEY_PREFIX_';
export const DEFAULT_CATEGORY_CAPTION_KEYS = [CATEGORY_NAME_CAPTION_KEY];

export const RELATIONSHIP_TYPE_CAPTION_KEY = '0_REL_TYPE_CAPTION_KEY';

export const DEFAULT_RELATIONSHIP_COLOR = blackActive;
export const DEFAULT_RELATIONSHIP_SIZE = 1;

export const DEFAULT_UNCATEGORISED_COLOR = gray;
export const DEFAULT_UNCATEGORISED_ICON = 'no-icon';
export const DEFAULT_UNCATEGORISED_SIZE = 1;
export const DEFAULT_UNCATEGORISED_TEXT_SIZE = 1;
export const DEFAULT_UNCATEGORISED_TEXT_ALIGN = 'top';
export const DEFAULT_UNCATEGORISED_TEXT_ALIGN_RELATIONSHIP = 'top';

export const _OTHER = '_other';
