import type { Suggestion } from '../../../modules/SearchBar/types';

export const SEARCH_STATUS = {
  IDLE: 'idle',
  FETCHING_DATA: 'fetching-data',
  FETCHING_SUGGESTIONS: 'fetching-suggestions',
  DEBOUNCING: 'debouncing',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
  WARNING: 'warning',
} as const;

type SearchStatusKey = keyof typeof SEARCH_STATUS;

export type SearchStatus = {
  status: (typeof SEARCH_STATUS)[SearchStatusKey];
  message?: string | undefined;
};

export interface SearchCoreState {
  filter: string;
  inputText: string;
  // suggestion path that is selected in dropdown
  selectedSuggestionPathIdx: number;
  // suggestions in search box
  lockedSuggestions: Suggestion[];
  labelsWithNodesUnderLimit: string[];
  autoRun: boolean;
  initialFocus: boolean;
  isGenAIAssistantEnabled: boolean;
  status: SearchStatus;
  genAiLastAskedQuestion?: string | undefined;
}
