import { Box, Label, TextLink } from '@neo4j-ndl/react';

import { ConnectionFormUpx } from '../connection-form/connection-form-upx';

const helpResources = [
  {
    title: 'Creating local databases',
    href: '/',
    label: 'Documentation',
  },
  {
    title: 'Connect to local or remote databases',
    href: '/',
    label: 'Documentation',
  },
  {
    title: 'A complete guide to graph tools',
    href: '/',
    label: 'Documentation',
  },
  {
    title: 'Creating apps with the GenAIStack',
    href: '/',
    label: 'Blog',
  },
];

const Resources = ({ show = false }: { show?: boolean }) => (
  <aside className="hidden min-w-fit grow basis-[400px] flex-col gap-4 xl:flex">
    {show && (
      <>
        <h6>Resources</h6>
        {helpResources.map(({ title, href, label }) => (
          <Box
            key={title}
            borderRadius="xl"
            padding="5"
            className="bg-palette-neutral-bg-weak border-palette-neutral-border-weak border"
            htmlAttributes={{
              id: title,
            }}
          >
            <TextLink isExternalLink href={href} className="pb-5">
              {title}
            </TextLink>
            <Label fill="semi-filled">{label}</Label>
          </Box>
        ))}
      </>
    )}
  </aside>
);

export function RemoteDataSource() {
  return (
    <div className="mt-6 flex gap-10">
      <ConnectionFormUpx />
      <Resources />
    </div>
  );
}
