import type { Database } from '@nx/state';

export const CommandErrors = {
  UnknownCommand: (cmd?: string) => ({
    code: 'Commands.UnknownCommandError' as const,
    message: `Unknown command${cmd !== undefined ? ` "${cmd}"` : ''}.`,
  }),
} as const;

export const MultistatementErrors = {
  InvalidMultistatementCommand: {
    code: 'Multistatement.InvalidCommandError',
    message: 'Multistatement only supports cypher queries, switching databases and setting parameters.',
  },
} as const;

export const ParamErrors = {
  ExtraStatementError: (count?: number) => ({
    code: 'Params.ExtraStatementError' as const,
    message: `Expected single return column, got ${count ?? 'multiple'}.`,
  }),
  UnknownArgument: (arg = '') => ({
    code: 'Params.UnknownArgumentError' as const,
    message: `Unknown :param argument${arg ? ` "${arg}".` : '.'}`,
  }),
  InvalidMultistatementArgument: {
    code: 'Params.InvalidMultistatementArgumentError',
    message: 'Multistatement only supports setting parameters.',
  },
  MissingParamName: {
    code: 'Params.MissingNameError',
    message: 'Parameter name missing.',
  },
  ParamNameStartsWithDollar: {
    code: 'Params.IllegalNameStartError',
    message: "Parameter names can't start with $.",
  },
  ParamNameIllegalCharacters: (paramName: string) => ({
    code: 'Params.IllegalCharactersInNameError' as const,
    message: `${
      paramName ? `${paramName} is not a legal param name.` : ''
    } Parameter names can only use letters, numbers, underscore (_) and $ unless escaped with backticks (\`).`,
  }),
  MissingExpression: {
    code: 'Params.MissingExpressionError',
    message: 'Parameter expression missing.',
  },
} as const;

export const ConnectionCmdErrors = {
  UnknownArgument: (arg = '') => ({
    code: 'ConnectionCmd.UnknownArgumentError' as const,
    message: `Unknown :server argument${arg ? ` "${arg}".` : '.'}`,
  }),
} as const;

export const databaseNotFoundErrorCode = 'UseDb.DatabaseNotFound' as const;
export const UseDbErrors = {
  DatabaseNotFound: (dbName: string) => ({
    code: databaseNotFoundErrorCode,
    message: `Could not find database ${dbName}.`,
  }),
  DatabaseStatusUnknown: (dbName: string) => ({
    code: 'UseDb.DatabaseStatusUnknown' as const,
    message: `Database ${dbName} status is unknown, perhaps it's starting up`,
  }),
  DatabaseUnavailable: (dbName: string, status: string) => ({
    code: 'UseDb.DatabaseUnavailable' as const,
    message: `Database ${dbName} is not online, it's status is ${status}`,
  }),
  CannotSwitchToCompositeAlias: (composite: Database, dbName: string) => ({
    code: 'UseDb.CannotSwitchToCompositeAlias' as const,
    message: `Cannot switch to '${dbName}', as it's a constituent of composite database '${composite.name}'. Switch to '${composite.name}' and query it using a USE clause 'USE ${dbName} ...'`,
  }),
};

export const SysInfoCmdErrors = {
  CompositeDatabaseNotSupported: () => ({
    code: 'SysInfo.CompositeDatabaseNotSupported' as const,
    message: 'The :sysinfo command is not supported while using the system or a composite database.',
  }),
  UnsupportedVersion: (version: string) => ({
    code: 'SysInfo.UnsupportedVersion' as const,
    message: `:sysinfo is only supported since ${version}`,
  }),
} as const;
