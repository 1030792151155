import { useConnections } from '@nx/state';
import * as StdLib from '@nx/stdlib';
import { useState } from 'react';

import type { ConnectionFormValues } from '../connection-form.types';

export const useFormValues = (initialValues: ConnectionFormValues) => {
  const recentConnections = useConnections();
  const [formValues, setFormValues] = useState<ConnectionFormValues>(initialValues);

  const updateFormValues = (values: Partial<ConnectionFormValues>) =>
    setFormValues((prevValues) => ({ ...prevValues, ...values }));

  const handleFormChange = (
    values: Partial<ConnectionFormValues>,
    options?: { editingInstanceName?: boolean; resetInstanceName?: boolean },
  ) => {
    updateFormValues(values);

    // Automatically switch from 'bolt' to 'neo4j' as the protocol for localhost
    if (values.hostname !== undefined) {
      if (StdLib.URLs.isLocalhost(values.hostname) && formValues.protocol.startsWith('bolt')) {
        updateFormValues({ protocol: StdLib.URLs.PROTOCOL_NEO4J });
      }
    }

    // reset instance name if URL has changed
    if (options?.resetInstanceName === true && values.hostname !== undefined) {
      updateFormValues({ name: undefined });
    }

    // Revert instance name if URL was reverted
    if (options?.editingInstanceName === false && values.hostname !== undefined) {
      const resolvedInstanceName = values.hostname === initialValues.hostname ? initialValues.name : '';
      updateFormValues({ name: resolvedInstanceName });
    }

    // Set connection name if form fields match some recent connection
    if (values.name === undefined && (values.hostname !== undefined || values.protocol !== undefined)) {
      const recentConnection = recentConnections.find(
        ({ hostname, protocol }) =>
          hostname === (values.hostname ?? formValues.hostname) &&
          protocol === (values.protocol ?? formValues.protocol),
      );

      if (recentConnection && recentConnection.name !== formValues.name) {
        updateFormValues({ name: recentConnection.name });
      }
    }
  };

  return [formValues, handleFormChange] as const;
};
