import { useEffect } from 'react';
import { createPortal } from 'react-dom';

import type { MenuItem } from '../left-nav-menu/left-nav-menu';
import { LeftNav } from './left-nav';
import { useLeftNavContext } from './left-nav-context';

export const LeftNavPortal: React.FC = () => {
  const { leftNav } = useLeftNavContext();
  return leftNav ? createPortal(<LeftNav />, leftNav) : null;
};

export function LeftNavTarget({ items }: { items: (MenuItem | undefined)[] }) {
  const { setLeftNav, setItems } = useLeftNavContext();

  useEffect(() => {
    setItems(items);
  }, [setItems, items]);

  return <div className="w-max" ref={setLeftNav} />;
}
