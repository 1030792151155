import type { Date, DateTime, Integer, LocalDateTime, LocalTime, Time } from 'neo4j-driver';

import type { ActionName } from '../../state/search-prototype/suggestions/action-suggestions/action-suggestions.consts';
import type {
  PROPERTY_CONDITION_AFTER,
  PROPERTY_CONDITION_BEFORE,
  PROPERTY_CONDITION_BETWEEN,
  PROPERTY_CONDITION_CONTAINS,
  PROPERTY_CONDITION_ENDS_WITH,
  PROPERTY_CONDITION_EQUALS,
  PROPERTY_CONDITION_GREATER_THAN,
  PROPERTY_CONDITION_GREATER_THAN_OR_EQUAL,
  PROPERTY_CONDITION_LESS_THAN,
  PROPERTY_CONDITION_LESS_THAN_OR_EQUAL,
  PROPERTY_CONDITION_NOT_BETWEEN,
  PROPERTY_CONDITION_NOT_EQUALS,
  PROPERTY_CONDITION_STARTS_WITH,
  RELATIONSHIP_DIRECTION_TO_LEFT,
  RELATIONSHIP_DIRECTION_TO_RIGHT,
  TURNED_OFF_SUGGESTION,
} from './SearchBar.const';

export const SUGGESTION_TYPE = {
  NODE: 'NODE',
  RELATIONSHIP: 'RELATIONSHIP',
  SEARCH_PHRASE: 'SEARCH_PHRASE',
  FULL_TEXT_SEARCH: 'FULL_TEXT_SEARCH',
  ACTION: 'ACTION',
  TURNED_OFF_SUGGESTION: 'TURNED_OFF_SUGGESTION',
} as const;

export interface NodeSuggestion {
  type: typeof SUGGESTION_TYPE.NODE;
  categoryName: string;
  propertyName?: string;
  propertyType?: string;
  propertyCondition?: PropertyCondition;
  propertyConditionValue?: string;
  isCaseInsensitive?: boolean;
  labels?: string[];
}

export interface RelationshipSuggestion {
  type: typeof SUGGESTION_TYPE.RELATIONSHIP;
  relationshipType: string;
  direction?: RelationshipDirection;
  propertyName?: string;
  propertyType?: string;
  propertyCondition?: PropertyCondition;
  propertyConditionValue?: string;
}

type SearchPhraseParameter = Record<
  string,
  string | boolean | number | Integer | Date | LocalDateTime | LocalTime | DateTime | Time
>;

interface SearchPhraseQuery {
  cypher: string;
  parameters?: SearchPhraseParameter;
}

export interface SearchPhraseSuggestion {
  id: string;
  type: typeof SUGGESTION_TYPE.SEARCH_PHRASE;
  complete: boolean;
  description: string;
  hasCypherErrors: boolean;
  displayText: string;
  text: string;
  inputCharTypes: { pos: number; type: 'text' | 'param' }[];
  isUpdateQuery: boolean | null;
  query: SearchPhraseQuery | null;
}

export interface FullTextSearchSuggestion {
  type: typeof SUGGESTION_TYPE.FULL_TEXT_SEARCH;
  text: string; // the original user input
  sanitizedInputText: string;
  isDisabled: boolean;
}

export interface ActionSuggestion {
  type: typeof SUGGESTION_TYPE.ACTION;
  title: string;
  actionName: ActionName;
}

export interface TurnedOffSuggestion {
  type: typeof SUGGESTION_TYPE.TURNED_OFF_SUGGESTION;
}

export type Suggestion =
  | NodeSuggestion
  | RelationshipSuggestion
  | SearchPhraseSuggestion
  | FullTextSearchSuggestion
  | ActionSuggestion
  | typeof TURNED_OFF_SUGGESTION;

export const FILTER = {
  SearchPhrase: 'Saved Cypher',
  Nodes: 'Nodes',
  Relationships: 'Relationships',
  Properties: 'Properties',
} as const;

export type SuggestionWithValidationState = NodeSuggestion | RelationshipSuggestion;

export type RelationshipDirection = typeof RELATIONSHIP_DIRECTION_TO_LEFT | typeof RELATIONSHIP_DIRECTION_TO_RIGHT;

export type PropertyCondition =
  | typeof PROPERTY_CONDITION_EQUALS
  | typeof PROPERTY_CONDITION_NOT_EQUALS
  | typeof PROPERTY_CONDITION_CONTAINS
  | typeof PROPERTY_CONDITION_STARTS_WITH
  | typeof PROPERTY_CONDITION_ENDS_WITH
  | typeof PROPERTY_CONDITION_GREATER_THAN
  | typeof PROPERTY_CONDITION_GREATER_THAN_OR_EQUAL
  | typeof PROPERTY_CONDITION_LESS_THAN
  | typeof PROPERTY_CONDITION_LESS_THAN_OR_EQUAL
  | typeof PROPERTY_CONDITION_BEFORE
  | typeof PROPERTY_CONDITION_AFTER
  | typeof PROPERTY_CONDITION_BETWEEN
  | typeof PROPERTY_CONDITION_NOT_BETWEEN;
