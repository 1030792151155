import { isNotNullish } from '@nx/stdlib';

import type {
  ProjectParam,
  ProjectTrafficConfigParams,
  TrafficConfigResponse,
  UpdateTrafficConfigRequest,
} from '../console.api.types';
import type { TrafficConfig } from '../console.types';
import { transformTrafficConfigResponse, transformUpdateTrafficConfigRequest } from '../transformers/traffic-config';
import { type Builder, LIST_ID } from './types';

export const trafficConfigEndpoints = (builder: Builder) => ({
  getTrafficConfig: builder.query<TrafficConfig, ProjectTrafficConfigParams>({
    query: ({ projectId, tier, region }) => `tenants/${projectId}/traffic-configs/tiers/${tier}/regions/${region}`,
    transformResponse: (trafficConfig: TrafficConfigResponse) => transformTrafficConfigResponse(trafficConfig),
    providesTags: (result, error, { projectId, tier, region }) => [{ type: 'TrafficConfig', projectId, region, tier }],
  }),
  listTrafficConfigs: builder.query<TrafficConfig[], ProjectParam>({
    query: ({ projectId }) => `tenants/${projectId}/traffic-configs`,
    transformResponse: (trafficConfigs: TrafficConfigResponse[]) =>
      trafficConfigs.map((trafficConfig) => transformTrafficConfigResponse(trafficConfig)),
    providesTags: (result, error, { projectId }) => {
      if (isNotNullish(result)) {
        return [
          ...result.map(({ region, tier }) => ({ type: 'TrafficConfig' as const, projectId, region, tier })),
          { type: 'TrafficConfig', id: LIST_ID, projectId },
        ];
      }
      return [{ type: 'TrafficConfig', id: LIST_ID, projectId }];
    },
  }),
  updateTrafficConfig: builder.mutation<TrafficConfig, UpdateTrafficConfigRequest>({
    query: (config) => ({
      url: `tenants/${config.projectId}/traffic-configs/tiers/${config.tier}/regions/${config.region}`,
      method: 'PATCH',
      body: transformUpdateTrafficConfigRequest(config),
    }),
    transformResponse: (trafficConfig: TrafficConfigResponse) => transformTrafficConfigResponse(trafficConfig),
    invalidatesTags: (result, error, { projectId, tier, region }) => [
      { type: 'TrafficConfig', projectId, region, tier },
    ],
  }),
});
