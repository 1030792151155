import { isNotNullish } from '@nx/stdlib';

import type {
  CreateLogForwardingPayload,
  DestinationResponse,
  EditLogForwardingPayload,
  LogForwardingIdentityResponse,
  LogForwardingResponse,
} from '../api-types/log-forwarding';
import type {
  CreateLogForwarding,
  Destination,
  EditLogForwarding,
  LogForwarding,
  LogForwardingIdentity,
} from '../types/log-forwarding';
import { DESTINATION_TYPE } from '../types/log-forwarding';

export const unTransformLogForwarding = (tlf: LogForwardingResponse): LogForwarding => {
  let destination: Destination;
  // eslint-disable-next-line default-case
  switch (tlf.destination.type) {
    case DESTINATION_TYPE.STACKDRIVER:
      destination = {
        type: DESTINATION_TYPE.STACKDRIVER,
        stackdriver: {
          gcpProjectId: tlf.destination.stackdriver.gcp_project_id,
        },
      };
      break;
    case DESTINATION_TYPE.LOG_ANALYTICS:
      destination = {
        type: DESTINATION_TYPE.LOG_ANALYTICS,
        logAnalytics: {
          customerId: tlf.destination.log_analytics.customer_id,
          sharedKey: '',
        },
      };
      break;
    case DESTINATION_TYPE.CLOUDWATCH:
      destination = {
        type: DESTINATION_TYPE.CLOUDWATCH,
        cloudwatch: {
          region: tlf.destination.cloudwatch.region,
          logGroupName: tlf.destination.cloudwatch.log_group_name,
          logStreamName: tlf.destination.cloudwatch.log_stream_name,
          logArn: tlf.destination.cloudwatch.log_arn,
          logRetentionDays: tlf.destination.cloudwatch.log_retention_days,
        },
      };
      break;
  }

  return {
    destination: destination,
    id: tlf.id,
    logType: tlf.log_type,
    name: tlf.name,
    ready: tlf.ready,
    region: tlf.region,
    tier: tlf.tier,
    db_id: tlf.db_id,
  };
};

export const unTransformLogForwardingArray = (tlfs: LogForwardingResponse[]): LogForwarding[] => {
  return tlfs.map(unTransformLogForwarding);
};

export const transformDestination = (d: Destination): DestinationResponse => {
  switch (d.type) {
    case DESTINATION_TYPE.STACKDRIVER:
      return {
        type: DESTINATION_TYPE.STACKDRIVER,
        stackdriver: {
          gcp_project_id: d.stackdriver.gcpProjectId,
        },
      };
    case DESTINATION_TYPE.LOG_ANALYTICS:
      return {
        type: DESTINATION_TYPE.LOG_ANALYTICS,
        log_analytics: {
          customer_id: d.logAnalytics.customerId,
          shared_key: '',
        },
      };
    case DESTINATION_TYPE.CLOUDWATCH:
    default:
      return {
        type: DESTINATION_TYPE.CLOUDWATCH,
        cloudwatch: {
          region: d.cloudwatch.region,
          log_group_name: d.cloudwatch.logGroupName,
          log_stream_name: d.cloudwatch.logStreamName,
          log_arn: d.cloudwatch.logArn,
          log_retention_days: d.cloudwatch.logRetentionDays,
        },
      };
  }
};

export const unTransformLogForwardingIdentity = (lfi: LogForwardingIdentityResponse): LogForwardingIdentity => {
  switch (lfi.destination_type) {
    case DESTINATION_TYPE.STACKDRIVER:
      return {
        region: lfi.region,
        tier: lfi.tier,
        destinationType: DESTINATION_TYPE.STACKDRIVER,
        serviceAccountEmail: lfi.service_account_email,
      };
    case DESTINATION_TYPE.LOG_ANALYTICS:
      return {
        region: lfi.region,
        tier: lfi.tier,
        destinationType: DESTINATION_TYPE.LOG_ANALYTICS,
      };
    case DESTINATION_TYPE.CLOUDWATCH:
    default:
      return {
        region: lfi.region,
        tier: lfi.tier,
        destinationType: DESTINATION_TYPE.CLOUDWATCH,
        logArn: lfi.log_arn,
        accountId: lfi.account_id,
      };
  }
};

export const unTransformLogForwardingIdentitiesArray = (
  tlfs: LogForwardingIdentityResponse[],
): LogForwardingIdentity[] => {
  return tlfs.map(unTransformLogForwardingIdentity);
};

export const transformCreateLogForwardingPayload = (payload: CreateLogForwarding): CreateLogForwardingPayload => {
  const destination = transformDestination(payload.destination);

  return {
    Region: payload.region,
    Tier: payload.tier,
    Name: payload.name,
    LogType: payload.logType,
    Destination: destination,
    DbId: payload.db_id,
  };
};

export const transformEditLogForwardingPayload = (payload: EditLogForwarding): EditLogForwardingPayload => {
  return {
    Name: payload.name,
    LogType: payload.logType,
    ...(isNotNullish(payload.destination) && { Destination: transformDestination(payload.destination) }),
  };
};
