import { neo4jVersionFeatureUtil as util } from '@nx/neo4j-version-utils';
import type { ConnectionDetails } from '@nx/state';
import { selectCapability, LEGACY_store as store } from '@nx/state';
import * as StdLib from '@nx/stdlib';

import { useRouterState } from '../hooks/use-router-state';
import type { Connection, ConnectionFormValues } from './connection-form.types';

const SUPPORTED_PROTOCOLS = [
  StdLib.URLs.PROTOCOL_BOLT,
  StdLib.URLs.PROTOCOL_BOLT_SECURE,
  StdLib.URLs.PROTOCOL_NEO4J,
  StdLib.URLs.PROTOCOL_NEO4J_SECURE,
  StdLib.URLs.PROTOCOL_HTTP,
  StdLib.URLs.PROTOCOL_HTTPS,
];

export const CONNECTION_PARAMS: readonly string[] = ['connectURL', 'dbms', 'instanceName', 'db'] as const;
export const getConnectionParams = (params: URLSearchParams) => ({
  url: params.get('connectURL') ?? params.get('dbms'),
  instanceName: params.get('instanceName'),
  dbName: params.get('db') ?? undefined,
});

const getAllowedProtocols = () => {
  const state = store.getState();
  const httpQueryApiEnabled = selectCapability(state, { key: 'framework:http-query-api' });

  return httpQueryApiEnabled
    ? SUPPORTED_PROTOCOLS
    : SUPPORTED_PROTOCOLS.filter((protocol) => !protocol.startsWith('http'));
};

const browserEnv = {
  isHttps: document.location.protocol === 'https:',
  isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
};

const fallbackProtocol = browserEnv.isHttps ? StdLib.URLs.PROTOCOL_NEO4J_SECURE : StdLib.URLs.PROTOCOL_NEO4J;

function validateProtocolOrDefault(protocol?: string): StdLib.URLs.ConnectionProtocol {
  // @ts-ignore https://github.com/microsoft/TypeScript/issues/54422
  return getAllowedProtocols().includes(protocol) ? protocol : fallbackProtocol;
}

type ProtocolInfo = { label: string; friendlyLabel: string; description?: string; isDisabled?: boolean };
const PROTOCOL_INFO_MAP: Record<string, ProtocolInfo> = {
  [StdLib.URLs.PROTOCOL_BOLT]: { label: 'bolt', friendlyLabel: 'Bolt (not secure)' },
  [StdLib.URLs.PROTOCOL_BOLT_SECURE]: {
    label: 'bolt+s',
    friendlyLabel: 'Bolt',
    description:
      'Connect through the Bolt protocol using a WebSocket connection to the Neo4j Bolt port. Note: This protocol is obsolete and unsuitable for cluster environments. Use the Neo4j protocol instead for better performance and stability.',
  },
  [StdLib.URLs.PROTOCOL_NEO4J]: { label: 'neo4j', friendlyLabel: 'Neo4j (not secure)' },
  [StdLib.URLs.PROTOCOL_NEO4J_SECURE]: {
    label: 'neo4j+s',
    friendlyLabel: 'Neo4j',
    description:
      'Connect through the Neo4j protocol using a WebSocket connection to the Neo4j port. Use for best performance and stability.',
  },
  [StdLib.URLs.PROTOCOL_HTTP]: { label: 'http', friendlyLabel: 'Query API (not secure)' },
  [StdLib.URLs.PROTOCOL_HTTPS]: {
    label: 'https',
    friendlyLabel: 'Query API',
    description:
      'Connect to Neo4j through the Query REST API on the Neo4j HTTP port. Use for debugging or when Bolt is unavailable due to network configuration.',
  },
};

type ProtocolOption<T> = ProtocolInfo & { value: T };
export const getProtocolOptions = (
  protocols: StdLib.URLs.ConnectionProtocol[],
  neo4jVersion?: string,
): ProtocolOption<StdLib.URLs.ConnectionProtocol>[] => {
  return protocols.map((protocol) => {
    const info = PROTOCOL_INFO_MAP[protocol];
    const isDisabled =
      (protocol === StdLib.URLs.PROTOCOL_HTTP || protocol === StdLib.URLs.PROTOCOL_HTTPS) &&
      neo4jVersion !== undefined &&
      !util.isHttpQueryApiAvailable(neo4jVersion);
    const httpDisabledMessage = 'Requires Neo4j 5.26.0 or later. Not available in this version.';

    return {
      value: protocol,
      label: `${info?.label}://`,
      friendlyLabel: info ? info.friendlyLabel : '',
      description: info?.description + (isDisabled ? ` ${httpDisabledMessage}` : ''),
      isDisabled: isDisabled,
    };
  });
};

export const getProtocols = (url: string): StdLib.URLs.ConnectionProtocol[] => {
  const isLocalhost = StdLib.URLs.isLocalhost(url);
  const protocols = getAllowedProtocols().filter((protocol) => {
    // widen the type to allow .includes
    const secureProtocols: readonly StdLib.URLs.ConnectionProtocol[] = StdLib.URLs.SECURE_PROTOCOLS;
    if (browserEnv.isHttps && !secureProtocols.includes(protocol) && !isLocalhost) {
      return false;
    }

    return true;
  });

  return protocols;
};

export function useConnectionRouterState() {
  const routerState = useRouterState();

  const actionRequiresConnection = routerState?.actionRequiresConnection ?? false;

  return { actionRequiresConnection };
}

export const validateForm = (formValues: ConnectionFormValues) => {
  const isFormUrlLocalhost = StdLib.URLs.isLocalhost(formValues.hostname);

  const widenedSecureProtocols: readonly StdLib.URLs.ConnectionProtocol[] = StdLib.URLs.SECURE_PROTOCOLS;
  const isFormProtocolInsecure = !widenedSecureProtocols.includes(formValues.protocol);

  const isSafariUnsecure = browserEnv.isSafari && isFormUrlLocalhost && isFormProtocolInsecure;

  const isUnsecure = !browserEnv.isSafari && browserEnv.isHttps && isFormProtocolInsecure;

  const isValid = Boolean(formValues.hostname);

  return { isValid, isSafariUnsecure, isUnsecure, isFormUrlLocalhost };
};

export const mapConnectionDetailsToConnection = (connection: NonNullable<ConnectionDetails>): Connection => {
  return {
    url: connection.url,
    name: connection.instanceName ?? connection.url,
    credentials: connection.credentials,
    dbName: connection.dbName,
  };
};

export const mapConnectionToFormValues = (connection: Connection): ConnectionFormValues => {
  const neo4jUrl = StdLib.URLs.Neo4jURL.asNullable(connection.url);
  const protocol = validateProtocolOrDefault(neo4jUrl?.protocol);

  return {
    name: connection.name,
    protocol,
    hostname: neo4jUrl?.host ?? '',
    username: connection.credentials.type === 'basic' ? connection.credentials.username : '',
    password: '',
    dbName: connection.dbName,
  };
};

export const getInitialFormValues = (
  initialConnection?: Connection,
  defaultOverrides?: Partial<ConnectionFormValues>,
): ConnectionFormValues => {
  if (initialConnection) {
    return mapConnectionToFormValues(initialConnection);
  }

  return {
    hostname: '',
    protocol: validateProtocolOrDefault(defaultOverrides?.protocol),
    name: '',
    username: 'neo4j',
    password: '',
    ...defaultOverrides,
  };
};

export const getFormValuesFromUrl = (url?: string): Partial<ConnectionFormValues> => {
  if (StdLib.isNullish(url)) {
    return {};
  }

  const neo4jUrl = StdLib.URLs.Neo4jURL.asNullable(url);
  const protocol = validateProtocolOrDefault(neo4jUrl?.protocol);

  if (neo4jUrl) {
    const { host, username } = neo4jUrl;
    return {
      hostname: host,
      protocol,
      ...(username ? { username: username } : undefined),
    };
  }
  return {};
};
