import { intersection, isEmpty, isNil } from 'lodash-es';

import { getConditionBasedOnPropertyType } from '../../../../modules/Legend/Popups/RuleBasedStyling/rules';
import {
  PROPERTY_CONDITION_EQUALS,
  RELATIONSHIP_DIRECTION_TO_LEFT,
  RELATIONSHIP_DIRECTION_TO_RIGHT,
  SUGGESTION_ANY_NODE,
  SUGGESTION_ANY_RELATIONSHIP,
} from '../../../../modules/SearchBar/SearchBar.const';
import {
  buildSuggestion,
  findLeftEntryIdx,
  isNodeSuggestion,
  isRelationshipSuggestion,
} from '../../../../modules/SearchBar/SearchBar.utils';
import type {
  NodeSuggestion,
  PropertyCondition,
  RelationshipSuggestion,
  Suggestion,
} from '../../../../modules/SearchBar/types';
import { SUGGESTION_TYPE } from '../../../../modules/SearchBar/types';
import type { GeneralPropertyKey, PathSegment, TransformedSearchCategory } from '../../../../types/perspective';
import { getSafeBackTicksString } from '../../../graph/cypherUtils';

export interface GraphPatternSuggestions {
  properties: Suggestion[];
  nodes: (Suggestion | Suggestion[])[];
  relationships: (Suggestion | Suggestion[])[];
}

export const EMPTY_GRAPH_PATTERN_SUGGESTIONS: Readonly<GraphPatternSuggestions> = {
  properties: [],
  nodes: [],
  relationships: [],
};

export const buildGraphPatternSuggestions = ({
  lockedSuggestions = [],
  categories,
  relationshipTypes,
  pathSegments,
  relationshipPropertyKeys,
}: {
  lockedSuggestions: Suggestion[];
  categories: TransformedSearchCategory[];
  relationshipTypes: string[];
  pathSegments: PathSegment[];
  relationshipPropertyKeys: Record<string, GeneralPropertyKey[]>;
}): GraphPatternSuggestions => {
  if (isNil(categories) || isEmpty(categories)) {
    return EMPTY_GRAPH_PATTERN_SUGGESTIONS;
  }

  const lockedSuggestionsTop = lockedSuggestions.at(-1);

  if (isNil(lockedSuggestionsTop)) {
    return {
      ...EMPTY_GRAPH_PATTERN_SUGGESTIONS,
      nodes: buildSuggestionFromCategories(categories).concat(SUGGESTION_ANY_NODE),
      relationships: buildSuggestionFromRelationshipTypes(relationshipTypes),
    };
  } else if (isNodeSuggestion(lockedSuggestionsTop)) {
    return buildGraphPatternSuggestionsForNode(lockedSuggestionsTop, categories, relationshipTypes, pathSegments);
  } else if (isRelationshipSuggestion(lockedSuggestionsTop)) {
    return buildGraphPatternSuggestionsForRelationship(
      lockedSuggestionsTop,
      lockedSuggestions,
      categories,
      relationshipTypes,
      pathSegments,
      relationshipPropertyKeys,
    );
  }

  return EMPTY_GRAPH_PATTERN_SUGGESTIONS;
};

const buildGraphPatternSuggestionsForNode = (
  nodeSuggestion: NodeSuggestion,
  categories: TransformedSearchCategory[],
  relationshipTypes: string[],
  pathSegments: PathSegment[],
) => {
  const { propertyName, propertyCondition, propertyConditionValue } = nodeSuggestion;

  if (isNil(categories) || isNil(pathSegments) || isNil(relationshipTypes)) {
    return EMPTY_GRAPH_PATTERN_SUGGESTIONS;
  }

  const nodeSuggestions: (Suggestion | Suggestion[])[] = [];
  const relationshipSuggestions: RelationshipSuggestion[] = [];
  const propertySuggestions: NodeSuggestion[] = [];

  if (isNil(propertyName) || (!isNil(propertyCondition) && !isNil(propertyConditionValue))) {
    buildNextNodeAndRelationshipSuggestionsForNode(
      nodeSuggestions,
      relationshipSuggestions,
      nodeSuggestion,
      categories,
      pathSegments,
      relationshipTypes,
    );
    buildPropertySuggestionsForNode(propertySuggestions, nodeSuggestion, categories);
  } else if (isNil(propertyCondition)) {
    buildPropertyConditionSuggestions<NodeSuggestion>(propertySuggestions, nodeSuggestion);
  }

  return {
    nodes: nodeSuggestions,
    relationships: relationshipSuggestions,
    properties: propertySuggestions,
  };
};

const buildNextNodeAndRelationshipSuggestionsForNode = (
  nodeSuggestions: (Suggestion | Suggestion[])[],
  relationshipSuggestions: RelationshipSuggestion[],
  nodeSuggestion: NodeSuggestion,
  categories: TransformedSearchCategory[],
  pathSegments: PathSegment[],
  relationshipTypes: string[],
) => {
  // leftNode --relationship-- rightNode
  // looking for relationship suggestions
  const leftNodeSuggestion = nodeSuggestion;
  const leftNodeLabels = getCategoryLabels(categories, leftNodeSuggestion.categoryName);
  const rightNodeCategories = new Set<TransformedSearchCategory>();

  if (leftNodeSuggestion === SUGGESTION_ANY_NODE) {
    relationshipSuggestions.push(SUGGESTION_ANY_RELATIONSHIP);

    for (const relationshipType of relationshipTypes) {
      relationshipSuggestions.push(
        buildSuggestion<RelationshipSuggestion>({
          type: SUGGESTION_TYPE.RELATIONSHIP,
          relationshipType,
        }),
      );
    }

    nodeSuggestions.push([SUGGESTION_ANY_RELATIONSHIP, SUGGESTION_ANY_NODE]);

    for (const category of categories) {
      nodeSuggestions.push([
        SUGGESTION_ANY_RELATIONSHIP,
        buildSuggestion({
          type: SUGGESTION_TYPE.NODE,
          categoryName: category.name,
        }),
      ]);
    }

    return;
  }

  for (const { relationshipType, source: sourceLabel, target: targetLabel } of pathSegments) {
    // leftNode --relationship-->
    let hasOutgoingRelationship = false;
    if (leftNodeLabels.includes(sourceLabel)) {
      hasOutgoingRelationship = true;

      const rightNodeCategory = getCategoryByLabel(categories, targetLabel);
      if (!isNil(rightNodeCategory)) {
        rightNodeCategories.add(rightNodeCategory);
      }

      const outgoingRelationshipAdded = relationshipSuggestions.some(
        ({ relationshipType: relType, direction }) =>
          relationshipType === relType && direction === RELATIONSHIP_DIRECTION_TO_RIGHT,
      );
      if (!outgoingRelationshipAdded) {
        relationshipSuggestions.push(
          buildSuggestion<RelationshipSuggestion>({
            type: SUGGESTION_TYPE.RELATIONSHIP,
            relationshipType,
            direction: RELATIONSHIP_DIRECTION_TO_RIGHT,
          }),
        );
      }
    }

    // leftNode <--relationship--
    let hasIncomingRelationship = false;
    if (leftNodeLabels.includes(targetLabel)) {
      hasIncomingRelationship = true;

      const rightNodeCategory = getCategoryByLabel(categories, sourceLabel);
      if (!isNil(rightNodeCategory)) {
        rightNodeCategories.add(rightNodeCategory);
      }

      const incomingRelationshipAdded = relationshipSuggestions.some(
        ({ relationshipType: relType, direction }) =>
          relationshipType === relType && direction === RELATIONSHIP_DIRECTION_TO_LEFT,
      );
      if (!incomingRelationshipAdded) {
        relationshipSuggestions.push(
          buildSuggestion<RelationshipSuggestion>({
            type: SUGGESTION_TYPE.RELATIONSHIP,
            relationshipType,
            direction: RELATIONSHIP_DIRECTION_TO_LEFT,
          }),
        );
      }
    }

    // leftNode --relationship--
    if (hasOutgoingRelationship && hasIncomingRelationship) {
      const nondirectionalRelationshipAdded = relationshipSuggestions.some(
        ({ relationshipType: relType, direction }) => relationshipType === relType && direction === undefined,
      );
      if (!nondirectionalRelationshipAdded) {
        relationshipSuggestions.push(
          buildSuggestion<RelationshipSuggestion>({
            type: SUGGESTION_TYPE.RELATIONSHIP,
            relationshipType,
          }),
        );
      }
    }
  }

  if (!isEmpty(relationshipSuggestions)) {
    relationshipSuggestions.unshift(SUGGESTION_ANY_RELATIONSHIP);
  }

  // leftNode --relationship-- rightNode
  // looking for rightNode suggestions
  for (const category of rightNodeCategories.values()) {
    nodeSuggestions.push([
      SUGGESTION_ANY_RELATIONSHIP,
      buildSuggestion({
        type: SUGGESTION_TYPE.NODE,
        categoryName: category.name,
      }),
    ]);
  }
};

const buildPropertySuggestionsForNode = (
  propertySuggestions: NodeSuggestion[],
  nodeSuggestion: NodeSuggestion,
  categories: TransformedSearchCategory[],
) => {
  // leftNode --relationship-- rightNode
  // looking for property suggestions for the leftNode
  if (isNil(nodeSuggestion.propertyName)) {
    // if user has defined a property on the node, no property suggestion will be shown
    categories
      .find((category) => category.name === nodeSuggestion.categoryName)
      ?.properties?.forEach((property) => {
        const backTickedPropertyKey = getSafeBackTicksString(property.propertyKey);

        propertySuggestions.push(
          buildSuggestion<NodeSuggestion>({
            ...nodeSuggestion,
            propertyName: `${backTickedPropertyKey}`,
            propertyType: property.dataType,
          }),
        );
      });
  }
};

const buildPropertyConditionSuggestions = <SuggestionType extends NodeSuggestion | RelationshipSuggestion>(
  propertySuggestions: SuggestionType[],
  suggestion: SuggestionType,
) => {
  const conditions =
    suggestion.propertyType === 'boolean'
      ? [PROPERTY_CONDITION_EQUALS]
      : getConditionBasedOnPropertyType(suggestion.propertyType);

  conditions.forEach((propertyCondition) => {
    const conditionSuggestion = buildSuggestion<SuggestionType>({
      ...suggestion,
      propertyCondition: propertyCondition as PropertyCondition,
    });

    propertySuggestions.push(conditionSuggestion);
  });
};

const buildGraphPatternSuggestionsForRelationship = (
  relationshipSuggestion: RelationshipSuggestion,
  lockedSuggestions: Suggestion[] = [],
  categories: TransformedSearchCategory[],
  relationshipTypes: string[],
  pathSegments: PathSegment[],
  relationshipPropertyKeys: Record<string, GeneralPropertyKey[]>,
) => {
  const { relationshipType, propertyName, propertyCondition, propertyConditionValue } = relationshipSuggestion;

  if (isNil(categories) || isNil(pathSegments) || isNil(relationshipTypes)) {
    return EMPTY_GRAPH_PATTERN_SUGGESTIONS;
  }

  const nodeSuggestions: NodeSuggestion[] = [];
  const relationshipSuggestions: RelationshipSuggestion[] = [];
  const propertySuggestions: RelationshipSuggestion[] = [];

  if (isNil(propertyName) || (!isNil(propertyCondition) && !isNil(propertyConditionValue))) {
    buildNextNodeAndRelationshipSuggestionsForRelationship(
      nodeSuggestions,
      relationshipSuggestion,
      lockedSuggestions,
      categories,
      pathSegments,
    );
    buildPropertySuggestionsForRelationship(
      propertySuggestions,
      relationshipSuggestion,
      relationshipPropertyKeys?.[relationshipType],
    );
  }

  if (isNil(propertyCondition)) {
    buildPropertyConditionSuggestions<RelationshipSuggestion>(propertySuggestions, relationshipSuggestion);
  }

  return {
    properties: propertySuggestions,
    nodes: nodeSuggestions,
    relationships: relationshipSuggestions,
  };
};

const buildNextNodeAndRelationshipSuggestionsForRelationship = (
  nodeSuggestions: NodeSuggestion[],
  relationshipSuggestion: RelationshipSuggestion,
  lockedSuggestions: Suggestion[],
  categories: TransformedSearchCategory[],
  pathSegments: PathSegment[],
) => {
  nodeSuggestions.push(SUGGESTION_ANY_NODE);

  if (lockedSuggestions.at(-1) === SUGGESTION_ANY_RELATIONSHIP) {
    for (const category of categories) {
      nodeSuggestions.push(
        buildSuggestion({
          type: SUGGESTION_TYPE.NODE,
          categoryName: category.name,
        }),
      );
    }

    return;
  }

  const leftNodeIdx = findLeftEntryIdx(lockedSuggestions, SUGGESTION_TYPE.NODE);
  const leftNode = lockedSuggestions.at(leftNodeIdx);

  if (isNodeSuggestion(leftNode)) {
    // leftNode --relationship-- rightNode
    const leftNodeLabels = getCategoryLabels(categories, leftNode.categoryName);
    const rightNodeLabels: string[] = [];

    for (const { source: sourceLabel, relationshipType: relType, target: targetLabel } of pathSegments) {
      if (relationshipSuggestion.relationshipType !== relType) {
        continue;
      }

      if (relationshipSuggestion.direction === RELATIONSHIP_DIRECTION_TO_LEFT) {
        // leftNode <--relationship-- rightNode
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (leftNodeLabels.includes(targetLabel)) {
          rightNodeLabels.push(sourceLabel);
        }
      } else if (relationshipSuggestion.direction === RELATIONSHIP_DIRECTION_TO_RIGHT) {
        // leftNode --relationship--> rightNode
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (leftNodeLabels.includes(sourceLabel)) {
          rightNodeLabels.push(targetLabel);
        }
      } else {
        if (leftNode === SUGGESTION_ANY_NODE) {
          rightNodeLabels.push(targetLabel);
        }
        // leftNode --relationship-- rightNode
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (leftNodeLabels.includes(sourceLabel)) {
          rightNodeLabels.push(targetLabel);
        }
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (leftNodeLabels.includes(targetLabel)) {
          rightNodeLabels.push(sourceLabel);
        }
      }
    }

    const categoriesForNodeSuggestions = categories.filter(({ labels }) => {
      return !isEmpty(intersection(rightNodeLabels, labels));
    });

    nodeSuggestions.push(...buildSuggestionFromCategories(categoriesForNodeSuggestions));
  }
};

const buildPropertySuggestionsForRelationship = (
  propertySuggestions: RelationshipSuggestion[],
  relationshipSuggestion: RelationshipSuggestion,
  propertyKeys?: GeneralPropertyKey[],
) => {
  // if user has defined a property on the node, no property suggestion will be shown
  if (isNil(relationshipSuggestion.propertyName)) {
    propertyKeys?.forEach((property) => {
      const backTickedPropertyKey = getSafeBackTicksString(property.propertyKey);

      const propertySuggestion = buildSuggestion<RelationshipSuggestion>({
        ...relationshipSuggestion,
        propertyName: `${backTickedPropertyKey}`,
        propertyType: property.dataType,
      });

      propertySuggestions.push(propertySuggestion);
    });
  }
};

export const sortGraphPatternSuggestions = (
  graphPatternSuggestions: GraphPatternSuggestions,
  suggestionHead?: Suggestion,
) => {
  if (isEmpty(graphPatternSuggestions)) {
    return [];
  }

  const keyOrder: (keyof GraphPatternSuggestions)[] = [];

  if (isNil(suggestionHead)) {
    keyOrder.push('nodes', 'relationships');
  }
  if (suggestionHead?.type === SUGGESTION_TYPE.NODE) {
    keyOrder.push('relationships', 'properties', 'nodes');
  } else if (suggestionHead?.type === SUGGESTION_TYPE.RELATIONSHIP) {
    keyOrder.push('nodes', 'properties');
  }

  const sortedSuggestions: (Suggestion | Suggestion[])[] = [];

  for (const key of keyOrder) {
    sortedSuggestions.push(...graphPatternSuggestions[key]);
  }

  return sortedSuggestions;
};

export const getCategoryLabels = (categories: TransformedSearchCategory[], categoryName: string): string[] => {
  return categories.find((category) => category.name === categoryName)?.labels ?? [];
};

const getCategoryByLabel = (
  categories: TransformedSearchCategory[],
  label: string,
): TransformedSearchCategory | undefined => {
  return categories.find((category) => category.labels?.includes(label));
};

export const buildSuggestionFromCategories = (categories: TransformedSearchCategory[] = []) => {
  return categories.map((category) => {
    return buildSuggestion<NodeSuggestion>({
      type: SUGGESTION_TYPE.NODE,
      categoryName: category.name,
    });
  });
};

export const buildSuggestionFromRelationshipTypes = (relationshipTypes: string[] = []) => {
  return relationshipTypes.map((relationshipType) => {
    return [
      SUGGESTION_ANY_NODE,
      buildSuggestion<RelationshipSuggestion>({
        type: SUGGESTION_TYPE.RELATIONSHIP,
        relationshipType,
      }),
    ];
  });
};
