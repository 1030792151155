import { Button, DataGrid, LoadingSpinner, Typography } from '@neo4j-ndl/react';
import { CircleStackIconOutline, UserGroupIconOutline } from '@neo4j-ndl/react/icons';
import {
  ACTION,
  consoleApi,
  LEGACY_setActiveProject as setActiveProject,
  LEGACY_setUserHomePath as setUserHomePath,
  usePermissions,
} from '@nx/state';
import type { Project, ProjectSummary } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { DataGridHelpers } from '@nx/ui';
import type { CellContext, PaginationState } from '@tanstack/react-table';
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MODAL_TYPE, ProjectActionMenu } from './project-action-menu';
import { ProjectRenameDialog } from './project-rename-dialog';

const getProjectData = (id: string) => {
  const { data: project } = consoleApi.useGetProjectQuery(id);

  return project;
};

export const projectTableHelper = createColumnHelper<ProjectSummary>();

const NameColumn = (cx: CellContext<ProjectSummary, string>) => {
  const { id, name } = cx.row.original;
  const project = getProjectData(id);

  return <DataGridHelpers.TruncatedColumn value={project?.name ?? name} />;
};

const MembersColumn = (cx: CellContext<ProjectSummary, unknown>) => {
  const { id } = cx.row.original;
  const { isAllowed } = usePermissions();
  const canReadProjectMembers =
    isAllowed(ACTION.READ, `namespaces/${id}/users`) && isAllowed(ACTION.READ, `namespaces/${id}/roles`);

  const { data: members = [], isLoading } = consoleApi.useListProjectMembersQuery(id, {
    skip: !canReadProjectMembers,
  });

  if (!canReadProjectMembers) {
    return null;
  }

  return (
    <div className="flex items-center gap-2">
      <UserGroupIconOutline className="text-neutral-icon h-6 w-6" />
      {isLoading ? (
        <LoadingSpinner size="small" />
      ) : (
        <Typography variant="body-medium">
          <span className="font-bold">{members.length}</span>{' '}
        </Typography>
      )}
    </div>
  );
};

const InstancesColumn = (cx: CellContext<ProjectSummary, unknown>) => {
  const { id } = cx.row.original;
  const { isAllowed } = usePermissions();
  const canReadProjectInstances = isAllowed(ACTION.READ, `namespaces/${id}/databases`);

  const { data: instances = [], isLoading } = consoleApi.useListInstancesQuery(id, {
    skip: !canReadProjectInstances,
  });

  if (!canReadProjectInstances) {
    return null;
  }

  return (
    <div className="flex items-center gap-2">
      <CircleStackIconOutline className="text-neutral-icon h-6 w-6" />
      {isLoading ? (
        <LoadingSpinner size="small" />
      ) : (
        <Typography variant="body-medium">
          <span className="font-bold">{instances.length}</span>
        </Typography>
      )}
    </div>
  );
};

type ProjectTableProps = {
  projects: ProjectSummary[];
};

export const ProjectTable = ({ projects }: ProjectTableProps) => {
  const [openModal, setOpenModal] = useState<{ modal: MODAL_TYPE | null; project: Project } | null>(null);
  const navigate = useNavigate();

  // Control pagination externally, otherwise the table will reset the page index on every render
  // since `projects` do not have stable reference (e.e. is a new array on every render)
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 10 });

  // Reset pagination if the number of projects is less than the current page range
  if (projects.length <= pagination.pageIndex * pagination.pageSize) {
    setPagination({ ...pagination, pageIndex: 0 });
  }

  const columns = useMemo(
    () => [
      projectTableHelper.accessor('name', {
        header: 'Name',
        cell: NameColumn,
        sortingFn: 'text',
        minSize: 100,
      }),
      projectTableHelper.accessor('id', {
        header: 'ID',
        cell: (cx) => <DataGridHelpers.TruncatedColumn value={cx.getValue()} />,
      }),
      projectTableHelper.display({
        id: 'members',
        header: 'Members',
        cell: MembersColumn,
      }),
      projectTableHelper.display({
        id: 'instances',
        header: 'Instances',
        cell: InstancesColumn,
      }),
      projectTableHelper.display({
        id: 'actions',
        cell: (cx) => {
          const projectId = cx.row.original.id;
          const project = getProjectData(projectId);
          return (
            <div className="flex items-center gap-2">
              <Button
                size="small"
                fill="outlined"
                color="neutral"
                onClick={() => {
                  setActiveProject(projectId);
                  setUserHomePath(`/projects/${projectId}/instances`);
                  navigate(`/projects/${projectId}/instances`);
                }}
              >
                Open
              </Button>
              {isNotNullish(project) && (
                <ProjectActionMenu
                  project={project}
                  onOpenModal={(modal) => setOpenModal({ modal, project })}
                  type="table"
                />
              )}
            </div>
          );
        },
        size: 250,
        meta: {
          isStickyAction: true,
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const table = useReactTable({
    data: projects,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
    autoResetPageIndex: false,
  });

  return (
    <>
      <div className="flex flex-col overflow-y-auto">
        <DataGrid
          tableInstance={table}
          styling={{ headerStyle: 'clean' }}
          isResizable={false}
          components={{
            Header: DataGridHelpers.StickyActionHeader,
            BodyRow: DataGridHelpers.StickyActionsBodyRow,
          }}
          isKeyboardNavigable={false}
        />
      </div>
      {isNotNullish(openModal) && (
        <>
          {openModal.modal === MODAL_TYPE.RENAME && (
            <ProjectRenameDialog project={openModal.project} onClose={() => setOpenModal(null)} />
          )}
        </>
      )}
    </>
  );
};
