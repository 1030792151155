import { Banner, Button, Dialog, Tabs } from '@neo4j-ndl/react';
import type { Instance } from '@nx/state';
import { useManifest, usePlaylist } from '@nx/ui';

import { Playlist } from './playlist';

interface Props {
  open: boolean;
  onClose: () => void;
  instance: Instance;
}

export const ConnectInstanceModal = ({ open, onClose, instance }: Props) => {
  const manifest = useManifest();
  const playlist = usePlaylist();

  // get 'connect' playlists from manifest
  const connectPlaylists = Object.entries(manifest.data?.playlists ?? []).filter(
    ([, metadata]) => metadata.attributes?.category === 'connect',
  );

  // ensure 'connect' playlist is selected
  if (connectPlaylists.find(([id]) => id === playlist.id) === undefined && connectPlaylists[0] !== undefined) {
    playlist.changePlaylist(connectPlaylists[0][0]);
  }

  const { name, boltUrl } = instance;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      isOpen={open}
      onClose={handleClose}
      size="large"
      modalProps={{
        // fix dialog position to avoid jumping on tab change due to size difference
        className: 'p-10 align-top',
      }}
    >
      <Dialog.Header>Connect to {name}</Dialog.Header>
      <Dialog.Content className="flex min-h-0 flex-1 flex-col">
        {connectPlaylists.length > 1 && (
          <Tabs fill="underline" onChange={playlist.changePlaylist} value={playlist.id}>
            {connectPlaylists.map(([playlistId, item]) => (
              <Tabs.Tab key={playlistId} tabId={playlistId}>
                {item.title}
              </Tabs.Tab>
            ))}
          </Tabs>
        )}

        {connectPlaylists.length > 0 ? (
          <Playlist uri={boltUrl} />
        ) : (
          <Banner
            type="danger"
            title="Could not find connect guides"
            description={<p>Try again later.</p>}
            usage="inline"
          />
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Button size="large" color="neutral" fill="outlined" onClick={handleClose}>
          Close
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
