import { type Static, Type } from '@sinclair/typebox';
import { Value } from '@sinclair/typebox/value';

const User = Type.Object({
  user: Type.String(),
  roles: Type.Union([Type.Array(Type.String()), Type.Null()]),
  passwordChangeRequired: Type.Boolean(),
  suspended: Type.Union([Type.Boolean(), Type.Null()]),
  home: Type.Union([Type.String(), Type.Null()]),
});

export type User = Static<typeof User>;

export const isUser = (input: unknown): input is User => Value.Check(User, input);
