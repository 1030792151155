/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Button, DataGrid, DataGridComponents, Dialog, TextInput, Tooltip } from '@neo4j-ndl/react';
import { ExclamationCircleIconSolid, TrashIconOutline } from '@neo4j-ndl/react/icons';
import type { Project } from '@nx/state';
import {
  INVITE_STATUS,
  PROJECT_ROLE_NAME,
  consoleApi,
  getApiError,
  getErrorMessage,
  isErrorWithMessage,
  isFetchBaseQueryError,
  useNotificationActions,
} from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import type { CellContext } from '@tanstack/react-table';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import classNames from 'classnames';
import { isNumber } from 'lodash-es';
import { useMemo, useRef, useState } from 'react';
import * as yup from 'yup';

import type { Validation } from '../utils/validation';
import { validateYup } from '../utils/validation';
import type { User } from './entities/model';
import { isProjectRole, projectRoleFriendlyName } from './helpers';

type InviteRow = Pick<User, 'email' | 'metadata'> & {
  projectRole: PROJECT_ROLE_NAME;
  errorReason?: string;
};

const columnHelper = createColumnHelper<InviteRow>();

const emailSchema = yup.object({
  email: yup.string().email().lowercase().required().strict().label('Email'),
});

const validateEmail = (data: { email: string }): Validation<{ email: string }> | null => {
  return validateYup(emailSchema, data);
};

type InviteUserModalProps = {
  onClose: () => void;
  project: Project;
};

export function InviteUserModal({ project, onClose }: InviteUserModalProps) {
  const [invites, setInvites] = useState<InviteRow[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const emailInputRef = useRef<HTMLInputElement | null>(null);
  const [emailInputErrorText, setEmailInputErrorText] = useState('');
  const [inviteUserToProject, request] = consoleApi.useInviteUserToProjectMutation();
  const { addNotification } = useNotificationActions();

  const { availableRoles } = project;
  const projectRoleOptions = availableRoles.map((role) => {
    return { value: role, label: projectRoleFriendlyName[role] };
  });

  const getPendingInvites = consoleApi.useListProjectInvitesQuery({
    projectId: project.id,
    status: INVITE_STATUS.PENDING,
  });
  const getProjectMembers = consoleApi.useListProjectMembersQuery(project.id);

  const onRemoveInvite = (cx: CellContext<InviteRow, unknown>) =>
    setInvites((prevData) => prevData.filter((invite) => invite.email !== cx.row.original.email));

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('email', {
        header: 'Email',
        cell: (cx) => {
          const { errorReason } = cx.row.original;
          const classes = classNames('flex grow gap-2 items-center justify-between', {
            'text-danger-text': isNotNullish(errorReason),
          });
          return (
            <Tooltip type="simple" isDisabled={isNullish(errorReason)} isPortaled={false}>
              <Tooltip.Trigger hasButtonWrapper>
                <div className={classes}>
                  {cx.getValue()} {isNotNullish(errorReason) && <ExclamationCircleIconSolid className="size-4" />}
                </div>
              </Tooltip.Trigger>
              <Tooltip.Content style={{ position: 'fixed' }}>{errorReason}</Tooltip.Content>
            </Tooltip>
          );
        },
        maxSize: 320,
      }),
      columnHelper.accessor('projectRole', {
        header: 'Project role',
        maxSize: 265,
        cell: (cx) => (
          <div aria-label={`Select role for ${cx.row.original.email}`} className="w-full">
            <DataGridComponents.DropDownCell
              cell={cx}
              options={projectRoleOptions}
              // TODO: below does not work as expected; DropDownCell is (still) hidden behind modal.
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
              portalTarget={document.querySelector('ndl-modal-root')! as HTMLElement}
            />
          </div>
        ),
        meta: {
          isDropDownCell: {
            onChange: (newValue, cell) => {
              if (isNullish(newValue) || !isProjectRole(newValue)) {
                throw new TypeError(`onChange was called with a non-project role: ${newValue}`);
              }

              setInvites((prevData: InviteRow[]) => {
                return prevData.map((row: InviteRow) => {
                  if (row.email === cell.row.original.email) {
                    return {
                      ...row,
                      projectRole: newValue,
                    };
                  }
                  return row;
                });
              });
            },
          },
        },
      }),
      // Placeholder column to 'push' delete column to the right
      columnHelper.display({
        id: 'filler',
        header: undefined,
        cell: () => null,
      }),
      columnHelper.display({
        id: 'actions',
        header: undefined,
        cell: (cx) => (
          <DataGridComponents.RowActionCell
            cell={cx}
            innerIconButtonProps={{
              children: <TrashIconOutline />,
              onClick: () => onRemoveInvite(cx),
              ariaLabel: `Remove invite for ${cx.row.original.email}`,
            }}
          />
        ),
        meta: { isActionCell: { actions: [] } },
      }),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const table = useReactTable({
    columns,
    data: invites,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onChange',
    enableSorting: false,
    enableColumnResizing: false,
  });

  const handleEmailChange = () => {
    if (emailInputErrorText) {
      setEmailInputErrorText('');
    }
  };

  const checkEmailInput = (email: string) => {
    const emailValidation = validateEmail({ email });
    if (isNotNullish(emailValidation)) {
      return emailValidation.email?.message;
    } else if (invites.some((invite) => invite.email === email)) {
      return 'Email already added';
    } else if (
      getPendingInvites.data &&
      getPendingInvites.data.some((pendingInvite) => pendingInvite.email === email)
    ) {
      return 'Invite already exists for this email';
    } else if (
      getProjectMembers.data &&
      getProjectMembers.data.some((projectMember) => projectMember.email === email)
    ) {
      return 'User is already a member';
    }
    return null;
  };

  const handleAddEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!emailInputRef.current) {
      return;
    }

    const email = emailInputRef.current.value.trim();
    const err = checkEmailInput(email);

    if (isNotNullish(err)) {
      setEmailInputErrorText(err);
    } else {
      const invite = {
        email,
        projectRole: PROJECT_ROLE_NAME.PROJECT_VIEWER,
      };
      setInvites((prevData) => prevData.concat(invite));
      emailInputRef.current.value = '';
    }
  };

  const handleOnClose = () => {
    setLoading(false);
    setInvites([]);
    onClose();
  };

  const handleOnInvite = async () => {
    function hasCodeMember(error: unknown): error is SerializedError {
      return isNotNullish(error) && 'code' in error;
    }

    function hasStatusMember(error: unknown): error is FetchBaseQueryError {
      return isNotNullish(error) && 'status' in error;
    }

    const notifyServerError = (email: string) => {
      addNotification({
        type: 'danger',
        title: `Server error`,
        description: `Failed to invite ${email}. Please try again later.`,
        timeout: 5000,
      });
    };

    setLoading(true);

    const failedInvites: [string, string | undefined][] = [];
    for await (const invite of invites) {
      try {
        await inviteUserToProject({
          projectId: project.id,
          email: invite.email,
          roles: [invite.projectRole],
        }).unwrap();
      } catch (error: unknown) {
        let errorReason: string | undefined = undefined;
        let validationErrors: Record<string, string[]> | undefined = undefined;
        if (
          (hasCodeMember(error) && isNotNullish(error.code) && error.code.startsWith('5')) ||
          (hasStatusMember(error) && isNumber(error.status) && error.status >= 500)
        ) {
          notifyServerError(invite.email);
        }
        if (isFetchBaseQueryError(error)) {
          const apiError = getApiError(error);
          errorReason = getErrorMessage(apiError);
          validationErrors = apiError.validationErrors;
        } else if (isErrorWithMessage(error)) {
          errorReason = error.message;
        }
        failedInvites.push([invite.email, validationErrors?.email?.[0] ?? errorReason]);
      }
    }
    setLoading(false);
    if (failedInvites.length === 0) {
      handleOnClose();
      return;
    }

    const updatedInvites = invites
      .filter((inviteRow) => failedInvites.some((invite) => invite[0] === inviteRow.email))
      .map((invite) => ({
        ...invite,
        errorReason: failedInvites.find((failedInvite) => failedInvite[0] === invite.email)?.[1],
      }));

    setInvites(updatedInvites);
  };

  return (
    <Dialog isOpen onClose={handleOnClose} size="unset" modalProps={{ className: 'max-w-[1000px] w-[50%]' }}>
      <Dialog.Header>
        Invite users to <span className="font-normal">{project.name}</span>
      </Dialog.Header>
      <Dialog.Content>
        <form onSubmit={handleAddEmail}>
          <div className="align-items-center mb-7 flex flex-row gap-4">
            <div className="grow">
              <TextInput
                ref={emailInputRef}
                isFluid
                size="medium"
                onChange={handleEmailChange}
                errorText={emailInputErrorText}
                label="Email"
                htmlAttributes={{
                  name: 'email',
                  placeholder: 'john.doe@company.com',
                }}
              />
            </div>
            <Button color="neutral" fill="outlined" type="submit" size="medium" className="mt-[25px]">
              Add
            </Button>
          </div>
        </form>

        <DataGrid
          tableInstance={table}
          components={{
            Navigation: undefined,
            NoDataPlaceholder: () => (
              <DataGridComponents.NoDataPlaceholder>
                <h6>No users added</h6>
              </DataGridComponents.NoDataPlaceholder>
            ),
          }}
          styling={{
            headerStyle: 'clean',
            borderStyle: 'all-sides',
          }}
          isKeyboardNavigable={false}
        />
      </Dialog.Content>
      <Dialog.Actions>
        <Button color="neutral" fill="outlined" onClick={handleOnClose}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            void handleOnInvite();
          }}
          isLoading={loading || request.isLoading}
          isDisabled={invites.length === 0}
        >
          Invite
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}
