import type { CLOUD_PROVIDER, CloudProviderRegionMap, InstanceSize } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';

import { isSizeAvailableForVersion, isSizeAvailableInRegion } from '../entities/helpers';

export const getVisibleSizes = (
  sizes: InstanceSize[],
  {
    cloudProvider,
    region,
    version,
    isTrial = false,
  }: { cloudProvider?: CLOUD_PROVIDER; region?: string; version?: string; isTrial?: boolean },
) => {
  return sizes.filter((size) => {
    if (size.deprecated) {
      return false;
    }

    if (isNotNullish(cloudProvider) && size.cloudProvider !== cloudProvider) {
      return false;
    }

    if (isNotNullish(region) && !isSizeAvailableInRegion(size, region)) {
      return false;
    }

    if (isNotNullish(version) && !isSizeAvailableForVersion(size, version)) {
      return false;
    }

    if (size.isTrial !== isTrial) {
      return false;
    }

    return true;
  });
};

export const getValidRegion = (
  cloudProviderRegions: CloudProviderRegionMap,
  cloudProvider: CLOUD_PROVIDER,
  currentRegion?: string,
): string | undefined => {
  const cspRegions = cloudProviderRegions[cloudProvider];
  if (isNullish(cspRegions)) {
    return undefined;
  }

  if (isNullish(currentRegion)) {
    return cspRegions[0]?.name;
  }

  const availableRegions = cspRegions.map((region) => region.name);
  if (availableRegions.includes(currentRegion)) {
    return currentRegion;
  }

  return cspRegions[0]?.name;
};
