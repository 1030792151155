import type { PLAN_TYPE } from './shared';

export enum ACTION {
  READ = 'read',
  PUT = 'put',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  PAUSE = 'pause',
  RESUME = 'resume',
  TRANSFER = 'transfer',
  OVERWRITE = 'overwrite',
  SUSPEND = 'suspend',
  DOWNLOAD = 'download',
}

export enum REGIONAL_GDPR_COMPLIANCE_TIER {
  TIER_1 = 'tier-1',
  TIER_2 = 'tier-2',
  TIER_3 = 'tier-3',
}

export enum ONBOARDING_EXPERIENCE {
  Trial = 'trial',
  Free = 'free',
}

// TODO: Update the API to return lowercase items
export interface Permission {
  action: ACTION;
  resource: string;
}

export interface User {
  acceptedTermsAndConditions: string | null;
  defaultProject: string;
  id: string;
  permissions: Permission[];
  marketingChoiceRequired: REGIONAL_GDPR_COMPLIANCE_TIER | null;
  auraApiEnabled: boolean;
  planType: PLAN_TYPE;
  email: string;
  onboardingExperience: ONBOARDING_EXPERIENCE;
}
