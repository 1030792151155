import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { isEmpty, isNil } from 'lodash-es';

import type { Node, Relationship } from '../../types/graph';
import { getNodesWithGdsData } from '../combined/gdsCombinedSelectors';
import { getDisabledNodeMap } from '../graph/nodes';
import { getDisabledRelationshipMap, getRelationships } from '../graph/relationships';
import { getCategoryForNode } from '../perspectives/perspectives';
import { getRuleBasedCaptions } from '../perspectives/ruleEvaluators';
import { getCategoryStyleForNode, getColorForNodeMapper, getCurrentStyle } from '../styles/styles';
import { DEFAULT_UNCATEGORISED_COLOR } from '../styles/styles.const';
import type { Caption, CaptionType } from '../styles/types';
import type { RootState } from '../types';

export const NAME = 'propertypreview';

export interface PropertyPreviewState {
  isEnabled: boolean;
  targetId?: string;
  targetType?: 'node' | 'relationship';
  position: { x?: number; y?: number };
}

export type PropertyPreviewParams = {
  target: { id: string; type: 'node' | 'relationship' };
  position: { x: number; y: number };
};

export const initialState: PropertyPreviewState = {
  isEnabled: true,
  targetId: undefined,
  targetType: undefined,
  position: { x: undefined, y: undefined },
};

function targetIsNode(
  target: Node | Relationship | undefined,
  targetType: ReturnType<typeof getTargetType>,
): target is Node {
  return targetType === 'node' && !isNil(target);
}

function targetIsRelationship(
  target: Node | Relationship | undefined,
  targetType: ReturnType<typeof getTargetType>,
): target is Relationship {
  return targetType === 'relationship' && !isNil(target);
}

const getPropertyPreview = (state: RootState): PropertyPreviewState => state[NAME];
export const getIsEnabled = (state: RootState) => getPropertyPreview(state).isEnabled;
export const getTargetId = (state: RootState) => getPropertyPreview(state).targetId;
export const getTargetType = (state: RootState) => getPropertyPreview(state).targetType;
export const getPosition = (state: RootState) => getPropertyPreview(state).position;

export const getCaptions = createSelector(
  (state: RootState) => getTarget(state),
  (state: RootState) => getTargetType(state),
  (state: RootState) => getCategoryForNode(state),
  (state: RootState) => getCategoryStyleForNode(state),
  (state: RootState) => getCurrentStyle(state),
  (
    target,
    targetType,
    categoryForNodeMapper,
    categoryStyleForNodeMapper,
    style,
  ): {
    key: string;
    value: string;
    type: CaptionType;
  }[] => {
    if (targetIsNode(target, targetType)) {
      const categoryStyles = categoryStyleForNodeMapper(target);
      const { captions } = categoryStyles;
      const overriddenCaptions = getRuleBasedCaptions(categoryStyles.styleRules, target, target.labels);
      const keys = overriddenCaptions ?? captions;
      const currentNode = target.labels;
      if (isNil(keys)) return [];
      const keysInTooltip = keys.filter(
        (key: Caption) =>
          (key.type === 'property' || (key.type === 'label' && currentNode.includes(key.key as string))) &&
          key.inTooltip,
      );
      const category = categoryForNodeMapper(target);
      // TODO: remove explicit typing once RuleEvaluators is typed, should be inherited
      const selectedCaptions = keysInTooltip
        .map(({ key, type }: { key: string; type: string }) => ({
          key,
          value: type === 'property' ? (target.mappedProperties?.[key]?.value.toString() ?? '') : key,
          type,
        }))
        .filter(({ value }: { value?: string }) => !isNil(value));
      const categoryCaption = [
        {
          key: category.name,
          value: category.name,
          type: 'category',
        },
      ];

      return [...categoryCaption, ...selectedCaptions];
    } else if (targetIsRelationship(target, targetType)) {
      const relTypeStyles = style?.relationshipTypes.find(({ id }) => id === target.type);
      const captionKeys = relTypeStyles?.captions;
      const overriddenCaptionKeys = getRuleBasedCaptions(relTypeStyles?.styleRules, target);
      const keys = overriddenCaptionKeys ?? captionKeys;
      if (isNil(keys)) return [];

      const keysInTooltip = keys.filter((key: Caption) => key.inTooltip);

      return keysInTooltip
        .map(({ key, type }: { key: string; type: string }) => ({
          key,
          value: type === 'property' ? (target.mappedProperties?.[key]?.value.toString() ?? '') : key,
          type,
        }))
        .filter(({ value }: { value: string }) => !isNil(value));
    }
    // target is undefined
    return [];
  },
);

export const getColor = (state: RootState) => {
  const node = getTarget(state);
  const type = getTargetType(state);

  if (targetIsNode(node, type)) {
    return getColorForNodeMapper(state)(node) ?? DEFAULT_UNCATEGORISED_COLOR;
  }
  return 'transparent';
};

const getTargetNode = (state: RootState) => {
  const targetId = getTargetId(state);
  if (isNil(targetId)) {
    return undefined;
  }

  const disabledNodes = getDisabledNodeMap(state);
  const isNodeDisabled = !isNil(disabledNodes[targetId]);
  const nodes = getNodesWithGdsData(state);

  if (isEmpty(nodes) || isNodeDisabled) {
    return undefined;
  }

  return nodes[targetId];
};

const getTargetRel = (state: RootState) => {
  const targetId = getTargetId(state);
  if (isNil(targetId)) {
    return undefined;
  }

  const disabledRels = getDisabledRelationshipMap(state);
  const isRelDisabled = !isNil(disabledRels[targetId]);
  const rels = getRelationships(state);

  if (isEmpty(rels) || isRelDisabled) {
    return undefined;
  }

  return rels[targetId];
};

const getTarget = (state: RootState) => {
  return getTargetType(state) === 'node' ? getTargetNode(state) : getTargetRel(state);
};

const propertyPreviewSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    showPropertyPreview(state, action: PayloadAction<PropertyPreviewParams>) {
      state.targetId = action.payload.target.id;
      state.targetType = action.payload.target.type;
      state.position = action.payload.position;
    },
    hidePropertyPreview(state) {
      state.targetId = undefined;
      state.targetType = undefined;
      state.position = { x: undefined, y: undefined };
    },
    togglePropertyPreviewFeature(state) {
      state.isEnabled = !state.isEnabled;
      state.targetId = undefined;
      state.targetType = undefined;
      state.position = { x: undefined, y: undefined };
    },
  },
});

export const { showPropertyPreview, hidePropertyPreview, togglePropertyPreviewFeature } = propertyPreviewSlice.actions;
export default propertyPreviewSlice.reducer;
