import type { ButtonProps } from '@neo4j-ndl/react';
import { Button, Tooltip, Typography } from '@neo4j-ndl/react';
import { CheckIconOutline } from '@neo4j-ndl/react/icons';
import { isNullish } from '@nx/stdlib';
import cn from 'classnames';
import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';

type SelectInstanceCardProps = React.HTMLAttributes<HTMLDivElement>;

export const SelectInstanceCard = ({ children, className }: SelectInstanceCardProps) => {
  const classes = cn(
    'border-neutral-border-strong flex-grow-1 flex min-w-52 basis-[450px] flex-col flex-wrap rounded-3xl border p-10',
    className,
  );
  return <div className={classes}>{children}</div>;
};
export const SelectInstanceCardTitle = ({ className, children }: React.HTMLAttributes<HTMLDivElement>) => {
  const classes = cn(className, 'mb-4');
  return (
    <Typography variant="subheading-large" className={classes} as="div">
      {children}
    </Typography>
  );
};

export const SelectInstanceCardHeader = ({ className, children }: React.HTMLAttributes<HTMLDivElement>) => {
  const classes = cn(className, 'mb-2 flex min-h-[70px] flex-col justify-start');

  return <div className={classes}>{children}</div>;
};

export const SelectInstanceCardDescription = ({ className, children }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={className}>
      <Typography variant="body-medium" as="span" className="tracking-normal">
        {children}
      </Typography>
    </div>
  );
};

type ItemsContextType = {
  cardType: 'primary' | 'secondary';
};

const ItemsContext = createContext<ItemsContextType>({ cardType: 'secondary' });

const useItemsContext = () => {
  const context = useContext(ItemsContext);
  if (isNullish(context)) {
    throw new Error('Accordion used without context');
  }
  return context;
};

interface SelectInstanceCardItemsProps extends React.HTMLAttributes<HTMLDivElement> {
  cardType: 'primary' | 'secondary';
}

export const SelectInstanceCardItems = ({ children, cardType, className }: SelectInstanceCardItemsProps) => {
  const classes = cn(className, '*:mb-4');
  return (
    <ItemsContext.Provider value={{ cardType }}>
      <ul className={classes}>{children}</ul>
    </ItemsContext.Provider>
  );
};

export const SelectInstanceCardItem = ({ className, children }: React.HTMLAttributes<HTMLDivElement>) => {
  const { cardType } = useItemsContext();
  const classes = cn(className, 'flex gap-4', {
    'text-neutral-text-inverse': cardType === 'primary',
    'text-neutral-text-weak': cardType === 'secondary',
  });
  const iconClasses = cn('h-4 w-4 flex-shrink-0 self-center', {
    'text-primary-border-weak': cardType === 'primary',
    'text-primary-bg-status': cardType === 'secondary',
  });

  return (
    <li className={classes}>
      <CheckIconOutline className={iconClasses} />
      <Typography variant="body-medium" as="span" className="tracking-normal">
        {children}
      </Typography>
    </li>
  );
};

export const Divider = ({ className }: React.HTMLAttributes<HTMLDivElement>) => {
  const classes = cn(className, 'w-full border');
  return <div className={classes} />;
};

type SelectInstanceCardButtonProps = Omit<Extract<ButtonProps, { fill: 'outlined' }>, 'fill'> & {
  className?: string;
  tipContent?: ReactNode;
  href?: string;
  target?: string;
  dataTestId?: string;
};

export const SelectInstanceCardButton = ({
  className,
  children,
  tipContent,
  href,
  target,
  dataTestId,
  isDisabled = false,
  ...rest
}: SelectInstanceCardButtonProps) => {
  const classes = cn(className, 'w-full self-end');
  return (
    <>
      <div className="grow" />
      <div className="bg-neutral-bg-weak rounded-md">
        <Tooltip type="simple" isDisabled={!isDisabled} isPortaled={false}>
          <Tooltip.Trigger hasButtonWrapper>
            {href !== undefined ? (
              <Button
                as="a"
                className={classes}
                size="large"
                fill="outlined"
                isDisabled={isDisabled}
                htmlAttributes={{ target, 'data-testid': dataTestId, href }}
                {...rest}
              >
                {children}
              </Button>
            ) : (
              <Button
                className={classes}
                size="large"
                fill="outlined"
                isDisabled={isDisabled}
                htmlAttributes={{ target, 'data-testid': dataTestId }}
                {...rest}
              >
                {children}
              </Button>
            )}
          </Tooltip.Trigger>
          <Tooltip.Content>{tipContent}</Tooltip.Content>
        </Tooltip>
      </div>
    </>
  );
};
