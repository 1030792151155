import type { ProjectNotification, ProjectNotificationDate } from '@nx/state';
import { PROJECT_NOTIFICATION_LEVEL } from '@nx/state';
import { format } from 'date-fns';
import hash from 'object-hash';

export const substituteDates = (str: string, dates: ProjectNotificationDate[]): string =>
  dates.reduce(
    (acc, { name, format: f, timestamp }) => acc.replace(`{{${name}}}`, format(new Date(timestamp), f)),
    str,
  );

export const mapNotificationLevelToBannerType = (level: PROJECT_NOTIFICATION_LEVEL) => {
  if (level === PROJECT_NOTIFICATION_LEVEL.ERROR) {
    return 'danger';
  }
  return level;
};

export const SHOWN_NOTIFICATION_KEY = 'project.notifications';

type DismissedProjectNotifications = Record<string, number>;

export const filterExpiredProjectNotifications = (
  dismissedProjectNotifications: DismissedProjectNotifications,
): DismissedProjectNotifications =>
  Object.entries(dismissedProjectNotifications).reduce<DismissedProjectNotifications>(
    (filteredNotifications, [key, expiresAt]) => {
      if (expiresAt > new Date().getTime()) {
        filteredNotifications[key] = expiresAt;
      }
      return filteredNotifications;
    },
    {},
  );

export const getDismissedProjectNotifications = () => {
  const dismissedProjectNotificationsStr = localStorage.getItem(SHOWN_NOTIFICATION_KEY) ?? '{}';
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const dismissedProjectNotifications = JSON.parse(dismissedProjectNotificationsStr) as DismissedProjectNotifications;
  return filterExpiredProjectNotifications(dismissedProjectNotifications);
};

export const dismissProjectNotification = (notification: ProjectNotification) => {
  const notificationKey = hash(notification);
  const dismissedNotifications = getDismissedProjectNotifications();
  dismissedNotifications[notificationKey] = new Date().getTime() + notification.dismissalPeriod;

  localStorage.setItem(SHOWN_NOTIFICATION_KEY, JSON.stringify(dismissedNotifications));
};
