import { tokens } from '@neo4j-ndl/base';
import { Menu } from '@neo4j-ndl/react';
import { ChevronDownIconOutline } from '@neo4j-ndl/react/icons';
import { useConnections } from '@nx/state';
import { useReducer, useRef } from 'react';

import type { Connection } from '../connection-form.types';

export const RecentConnections = ({ onSelect }: { onSelect: (connection: Connection) => void }) => {
  const buttonRef = useRef<HTMLParagraphElement>(null);
  const [isMenuOpen, toggleMenu] = useReducer((s: boolean) => !s, false);
  const recentConnections = useConnections().filter((connection) => connection.isAuraInstanceFromAPI !== false);

  if (recentConnections.length === 0) {
    return undefined;
  }

  return (
    <>
      <p ref={buttonRef} onClick={() => toggleMenu()}>
        <span>Recent connections</span>
        <ChevronDownIconOutline
          className="text-palette-neutral-text-weak ml-2 inline h-4 w-4"
          style={{
            transform: isMenuOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transitionDuration: tokens.transitions.values.duration.quick,
          }}
        />
      </p>
      <Menu
        isOpen={isMenuOpen}
        anchorRef={buttonRef}
        className="z-modal"
        /* TODO: check if this is needed */
        /* anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOriginOffsetFromAnchorOrigin={{ horizontal: 0, vertical: 4 }}*/
        onClose={(e) => {
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          if (e !== undefined && e.target instanceof Node && buttonRef.current?.contains(e.target)) {
            return;
          }
          toggleMenu();
        }}
        /* TODO: check if this is needed */
        //  disableBackdrop
      >
        <Menu.Items>
          {recentConnections.map((connection, i) => {
            return (
              <Menu.Item
                title={connection.name}
                description={connection.url}
                key={i}
                onClick={() => {
                  toggleMenu();
                  onSelect(connection);
                }}
              />
            );
          })}
        </Menu.Items>
      </Menu>
    </>
  );
};
