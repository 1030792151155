import type { TIER } from './shared';

export enum DESTINATION_TYPE {
  STACKDRIVER = 'stackdriver',
  LOG_ANALYTICS = 'log_analytics',
  CLOUDWATCH = 'cloudwatch',
}

export enum LOG_TYPE {
  SECURITY = 'security',
}

export interface StackdriverDestination {
  gcpProjectId: string;
}

export interface LogAnalyticsDestination {
  customerId: string;
  sharedKey: string;
}

export interface CloudwatchDestination {
  region: string;
  logGroupName: string;
  logStreamName: string;
  logArn: string;
  logRetentionDays: number;
}

export type WrappedStackdriverDestination = {
  type: DESTINATION_TYPE.STACKDRIVER;
  stackdriver: StackdriverDestination;
};

export type WrappedLogAnalyticsDestination = {
  type: DESTINATION_TYPE.LOG_ANALYTICS;
  logAnalytics: LogAnalyticsDestination;
};

export type WrappedCloudwatchDestination = {
  type: DESTINATION_TYPE.CLOUDWATCH;
  cloudwatch: CloudwatchDestination;
};

export type Destination = WrappedStackdriverDestination | WrappedLogAnalyticsDestination | WrappedCloudwatchDestination;

export interface LogForwarding<T extends Destination = Destination> {
  region: string;
  tier: TIER;
  id: string;
  name: string;
  destination: T;
  logType: LOG_TYPE;
  ready: boolean;
  db_id?: string;
}

export interface StackdriverIdentity {
  region: string;
  tier: TIER;
  destinationType: DESTINATION_TYPE.STACKDRIVER;
  serviceAccountEmail: string;
}

export interface LogAnalyticsIdentity {
  region: string;
  tier: TIER;
  destinationType: DESTINATION_TYPE.LOG_ANALYTICS;
}

export interface CloudwatchIdentity {
  region: string;
  tier: TIER;
  destinationType: DESTINATION_TYPE.CLOUDWATCH;
  logArn: string;
  accountId: string;
}

export type LogForwardingIdentity = StackdriverIdentity | LogAnalyticsIdentity | CloudwatchIdentity;

export interface CreateLogForwarding<T extends Destination = Destination> {
  region: string;
  tier: TIER;
  name: string;
  logType: string;
  destination: T;
  db_id?: string;
}

export interface EditLogForwarding<T extends Destination = Destination> {
  name?: string;
  logType?: string;
  destination?: T;
}
