const MAX_LOG_LINES = 200;
const AUTH_LOGGING_PREFIX = 'OIDC/OAuth#';
const AUTH_STORAGE_LOGS = `/auth#logs`;

export const authLog = (msg: string, type: 'log' | 'warn' | 'error' = 'log'): void => {
  const isAuthDebuggingEnabled = window.localStorage.getItem('OIDC/OAuth#EnableAuthDebugging') === 'true';

  if (isAuthDebuggingEnabled) {
    // eslint-disable-next-line no-console
    console[type](`${AUTH_LOGGING_PREFIX} ${msg}`);
  }

  const messageNoNewlines = msg.replace('\n', ' ');
  const log = `${AUTH_LOGGING_PREFIX} [${new Date().toISOString()}] '${type}' ${messageNoNewlines}`;
  const logs = sessionStorage.getItem(AUTH_STORAGE_LOGS) ?? '';
  const logsLines = logs.split('\n');

  const truncatedOldLogs = logsLines.length > MAX_LOG_LINES ? logsLines.slice(1 - MAX_LOG_LINES).join('\n') : logs;

  sessionStorage.setItem(AUTH_STORAGE_LOGS, `${truncatedOldLogs}${log}\n`);
};
