/**
 * Sets a timeout that will be triggered after the user has been idle for a certain amount of time.
 *
 * @param callback callback function to be called when the user is idle
 * @param idleTime user idle time in milliseconds
 * @returns a function to cancel the idle timeout
 */
export function setIdleTimeout(callback: () => void, idleTime: number): () => void {
  const POLL_INTERVAL = 1000;
  let idleTimer: number | undefined = undefined;

  const pollingTimer = window.setInterval(() => {
    const { isActive } = navigator.userActivation;

    if (isActive) {
      window.clearTimeout(idleTimer);
      idleTimer = undefined;
    }

    if (!isActive && idleTimer === undefined) {
      idleTimer = window.setTimeout(() => {
        window.clearInterval(pollingTimer);
        callback();
      }, idleTime);
    }
  }, POLL_INTERVAL);

  const cancelIdleTimeout = () => {
    window.clearInterval(pollingTimer);
    window.clearTimeout(idleTimer);
  };

  return cancelIdleTimeout;
}
