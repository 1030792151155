import { Spotlight, SpotlightTour, useSpotlightContext } from '@neo4j-ndl/react';
import { UI_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { consoleApi, getApiError, useAppSelector, useConfigurationActions, useMainSidebarActions } from '@nx/state';
import { isNonEmptyString } from '@nx/stdlib';
import { useEffect } from 'react';

import { isEmailNotVerified } from '../components/api-error';
import { useTrackUpxEvent } from '../services/segment/analytics';

const spotlights: React.ComponentProps<typeof Spotlight>[] = [
  {
    target: `welcome-tour-0-learn`,
    placement: 'bottom-middle-top-middle',
    children: (
      <>
        <Spotlight.Header>Learn</Spotlight.Header>
        <Spotlight.Body>
          Learn the basics with interactive guides and sample datasets, discover in-depth courses and documentation.
        </Spotlight.Body>
      </>
    ),
  },
  {
    target: `welcome-tour-1-organization`,
    placement: 'bottom-middle-top-middle',
    children: (
      <>
        <Spotlight.Header>Organization</Spotlight.Header>
        <Spotlight.Body>Manage organization-wide settings, including users and feature access.</Spotlight.Body>
      </>
    ),
  },
  {
    target: `welcome-tour-2-instances`,
    placement: 'top-end-top-start',
    children: (
      <>
        <Spotlight.Header>Instances</Spotlight.Header>
        <Spotlight.Body>Deploy and manage your AuraDB instances.</Spotlight.Body>
      </>
    ),
  },
  {
    target: `welcome-tour-3-tools`,
    placement: 'top-end-top-start',
    children: (
      <>
        <Spotlight.Header>Tools</Spotlight.Header>
        <Spotlight.Body>Run queries and visually explore your graph data.</Spotlight.Body>
      </>
    ),
  },
  {
    target: `welcome-tour-4-operations`,
    placement: 'top-end-top-start',
    children: (
      <>
        <Spotlight.Header>Operations</Spotlight.Header>
        <Spotlight.Body>Monitor the health of your instances and analyze Cypher query logs.</Spotlight.Body>
      </>
    ),
  },
  {
    target: `welcome-tour-5-project`,
    placement: 'top-end-top-start',
    children: (
      <>
        <Spotlight.Header>Projects</Spotlight.Header>
        <Spotlight.Body>Manage your projects, users, settings, and billing.</Spotlight.Body>
      </>
    ),
  },
];

export const SpotlightWelcomeTour = () => {
  const trackEvent = useTrackUpxEvent();
  const { setActiveSpotlight, setIsOpen, isOpen } = useSpotlightContext();
  const { setWelcomeAppCuesShown } = useConfigurationActions();
  const welcomeAppCuesShown = useAppSelector((store) => store.configuration.welcomeAppCuesShown);
  const { toggleMainSidebar } = useMainSidebarActions();
  const isSidebarOpen = useAppSelector((store) => store.sidebar.mainSidebar.isOpen);
  const { data, error, isLoading } = consoleApi.useGetUserDetailsQuery();

  useEffect(() => {
    // Show the welcome tour only after the email is verified and the user has accepted the terms and conditions.
    const apiError = getApiError(error);
    if (
      isLoading ||
      data?.acceptedTermsAndConditions === undefined ||
      data.acceptedTermsAndConditions === null ||
      (isNonEmptyString(apiError.reason) && isEmailNotVerified(apiError.reason))
    ) {
      return;
    }

    if (!welcomeAppCuesShown && !isOpen) {
      if (!isSidebarOpen) {
        toggleMainSidebar(true);
      }
      setActiveSpotlight('welcome-tour-0-learn');
      setIsOpen(true);
      trackEvent({ event: UI_EVENTS.SPOTLIGHT_WELCOME_START, scope: APP_SCOPE.framework });
      setWelcomeAppCuesShown();
    }
  }, [
    error,
    welcomeAppCuesShown,
    isOpen,
    isLoading,
    isSidebarOpen,
    setActiveSpotlight,
    setIsOpen,
    setWelcomeAppCuesShown,
    toggleMainSidebar,
    trackEvent,
    data?.acceptedTermsAndConditions,
  ]);

  return (
    <SpotlightTour
      spotlights={spotlights}
      onAction={(_, action) => {
        if (action === 'close') {
          trackEvent({ event: UI_EVENTS.SPOTLIGHT_WELCOME_FINISH, scope: APP_SCOPE.framework });
        }
      }}
    />
  );
};
