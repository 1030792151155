import { neo4jVersionUtil } from '@nx/neo4j-version-utils';
import { isNullish } from '@nx/stdlib';

import type { Nullable } from '../../types/utility';

const { coerce, compare, diff, gt, gte, lt } = neo4jVersionUtil;

const TWO_THREE_ONE_VERSION = '2.3.1';
const TWO_FOUR_VERSION = '2.4.0';
const FOUR_SERVER_VERSION = '4.0.0';
const THREE_SERVER_VERSION = '3.0.0';
const FIVE_SERVER_VERSION = '5.0.0';
const FIVE_SEVEN_VERSION = '5.7.0';
const FIVE_NINE_VERSION = '5.9.0';

const compareVersions = (v1: string, v2: string) => compare(v1, v2);

const isVersionGreaterThanOrEqual = (currentVersion?: Nullable<string>, desiredVersion?: Nullable<string>) => {
  if (isNullish(currentVersion) || isNullish(desiredVersion) || currentVersion === '' || desiredVersion === '') {
    return null;
  }
  return gte(currentVersion, desiredVersion);
};

const isVersionGreaterThan = (currentVersion?: Nullable<string>, desiredVersion?: Nullable<string>) => {
  if (isNullish(currentVersion) || isNullish(desiredVersion) || currentVersion === '' || desiredVersion === '') {
    return null;
  }

  return gt(currentVersion, desiredVersion);
};

const isAura = (version: string) => version.toLowerCase().includes('aura');

type SupportedVersion = typeof THREE_SERVER_VERSION | typeof FOUR_SERVER_VERSION;

type SupportedVersions = {
  [version in SupportedVersion]: {
    threshold: string;
  };
};

const supportedVersions: SupportedVersions = {
  [THREE_SERVER_VERSION]: {
    threshold: '3.5.0',
  },
  [FOUR_SERVER_VERSION]: {
    threshold: '4.4.0',
  },
};
const getSupportedVersion = (version: string) => {
  if (isAura(version)) {
    return version;
  }

  const semver = coerce(version);
  if (semver == null) {
    return undefined;
  }

  if (lt(semver, THREE_SERVER_VERSION)) {
    return supportedVersions[THREE_SERVER_VERSION].threshold;
  }
  const notSupportedVersion = Object.keys(supportedVersions).find((v) => {
    const diffBetweenVersions = diff(semver, v);
    const isSameMajor = diffBetweenVersions == null || !['major', 'premajor'].includes(diffBetweenVersions);
    const isSmallerThanThreshold = lt(semver, supportedVersions[v as SupportedVersion].threshold);
    return isSameMajor && isSmallerThanThreshold;
  });
  return notSupportedVersion != null ? supportedVersions[notSupportedVersion as SupportedVersion].threshold : version;
};

const isVersion231OrGreater = (version: string | null | undefined) =>
  isVersionGreaterThanOrEqual(version, TWO_THREE_ONE_VERSION);
const isVersion240OrGreater = (version: string | null | undefined) =>
  isVersionGreaterThanOrEqual(version, TWO_FOUR_VERSION);

const isVersion5OorGreater = (version: string | null | undefined) =>
  isVersionGreaterThanOrEqual(version, FIVE_SERVER_VERSION);
const isVersion57orGreater = (version: string | null | undefined) =>
  isVersionGreaterThanOrEqual(version, FIVE_SEVEN_VERSION);
const isVersion59orGreater = (version: string | null | undefined) =>
  isVersionGreaterThanOrEqual(version, FIVE_NINE_VERSION);

export const isVersion219orGreater = (version: string | null | undefined) =>
  isVersionGreaterThanOrEqual(version, '2.19');

export {
  compareVersions,
  getSupportedVersion,
  isVersion231OrGreater,
  isVersion240OrGreater,
  isVersion57orGreater,
  isVersion59orGreater,
  isVersion5OorGreater,
  isVersionGreaterThan,
  isVersionGreaterThanOrEqual,
};
