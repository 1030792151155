import { Typography, useDataGridContext } from '@neo4j-ndl/react';
import type { Instance } from '@nx/state';
import { TIER, useActiveProject } from '@nx/state';
import { isNullish } from '@nx/stdlib';
import { CopyOnHoverColumn, DataGridHelpers } from '@nx/ui';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo } from 'react';

import {
  friendlyRegionName,
  getCpuSortFn,
  getCpuText,
  getMemorySortFn,
  getMemoryText,
  getRemainingTrialTimeMessage,
  getStorageSortFn,
  getStorageText,
  isProTrialInstance,
} from './entities/helpers';
import { InstanceTableRowActions } from './instance-table-row';
import { InstanceStatus } from './shared/instance-status';

export const instanceTableHelper = createColumnHelper<Instance>();

export interface TableResultProps {
  manualPagination?: {
    from: number;
    to: number;
    totalRows: number;
  };
}

// Copy-pasted from Needle. The only reason is to change "results" to "instances"
// Could be nice if Needle TableResults component had a "render" prop to customize the text
const TableResults = ({ manualPagination }: TableResultProps) => {
  const { tableProps } = useDataGridContext();
  const { getState, getRowModel } = tableProps;
  const {
    pagination: { pageSize, pageIndex },
  } = getState();
  const { rows } = getRowModel();

  const { from, to, totalRows } = useMemo(
    () =>
      manualPagination ?? {
        from: 1 + pageIndex * pageSize,
        to: rows.length + pageIndex * pageSize,
        totalRows: tableProps.getCoreRowModel().rows.length,
      },
    [pageIndex, pageSize, manualPagination, rows, tableProps],
  );

  return (
    <span className="ndl-data-grid-results">
      <span>
        Showing <b>{totalRows ? `${from}${to !== from ? `-${to}` : ''}` : 0}</b> of <b>{totalRows}</b> instances
      </span>
    </span>
  );
};

type InstanceTableProps = {
  instances: Instance[];
  onDisplayInstanceDetails: (instanceId: string) => void;
  filter: string;
  setFilter: (filter: string) => void;
};

export const InstanceTable = ({ instances, onDisplayInstanceDetails, filter, setFilter }: InstanceTableProps) => {
  const activeProject = useActiveProject();

  const columns = useMemo(
    () => [
      instanceTableHelper.accessor('name', {
        header: 'Name',
        cell: (cx) => <DataGridHelpers.TruncatedColumn value={cx.getValue()} />,
        sortingFn: 'text',
        size: 300,
        minSize: 100,
        filterFn: 'includesString',
      }),
      instanceTableHelper.accessor('id', {
        header: 'ID',
        cell: (cx) => (
          <CopyOnHoverColumn value={cx.getValue()}>
            <Typography variant="body-medium" className="truncate ">
              {cx.getValue()}
            </Typography>
          </CopyOnHoverColumn>
        ),
        size: 200,
        minSize: 100,
        filterFn: 'includesString',
      }),
      instanceTableHelper.accessor('instanceStatus', {
        header: 'Status',
        cell: (cx) => {
          const instance = cx.row.original;
          return <InstanceStatus instance={instance} project={activeProject} />;
        },
        size: 200,
        minSize: 100,
      }),
      instanceTableHelper.accessor('tierDisplayName', {
        header: 'Type',
        cell: (cx) => {
          const instance = cx.row.original;
          return (
            <div className="flex flex-col gap-0.5 truncate">
              <DataGridHelpers.TruncatedColumn value={instance.tierDisplayName} />
              {isProTrialInstance(instance) && (
                <Typography variant="body-small">{getRemainingTrialTimeMessage(instance)}</Typography>
              )}
            </div>
          );
        },
        size: 200,
        minSize: 50,
      }),
      instanceTableHelper.accessor('region', {
        header: 'Region',
        cell: (cx) => {
          const instance = cx.row.original;
          if (instance.tier === TIER.FREE) {
            return null;
          }
          const friendlyName = friendlyRegionName(
            instance,
            activeProject.tierConfigs[instance.tier]?.cloudProviderRegions[instance.cloudProvider],
          );

          return <DataGridHelpers.TruncatedColumn value={friendlyName} />;
        },
        size: 200,
        minSize: 50,
      }),
      instanceTableHelper.accessor('appliedSettings', {
        id: 'memory',
        header: 'Memory',
        cell: (cx) => {
          const instance = cx.row.original;
          const text = getMemoryText(instance);
          if (instance.tier === TIER.FREE || isNullish(text)) {
            return null;
          }
          return <DataGridHelpers.TruncatedColumn value={text} />;
        },
        size: 120,
        minSize: 50,
        sortingFn: (row1, row2) => getMemorySortFn(row1.original, row2.original),
      }),
      instanceTableHelper.accessor('appliedSettings', {
        id: 'storage',
        header: 'Storage',
        cell: (cx) => {
          const instance = cx.row.original;
          const text = getStorageText(instance);
          if (instance.tier === TIER.FREE || isNullish(text)) {
            return null;
          }
          return <DataGridHelpers.TruncatedColumn value={text} />;
        },
        size: 120,
        minSize: 50,
        sortingFn: (row1, row2) => getStorageSortFn(row1.original, row2.original),
      }),
      instanceTableHelper.accessor('appliedSettings', {
        id: 'cpu',
        header: 'CPU',
        cell: (cx) => {
          const instance = cx.row.original;
          const text = getCpuText(instance);
          if (instance.tier === TIER.FREE || isNullish(text)) {
            return null;
          }
          return <DataGridHelpers.TruncatedColumn value={text} />;
        },
        size: 70,
        minSize: 60,
        sortingFn: (row1, row2) => getCpuSortFn(row1.original, row2.original),
      }),
      instanceTableHelper.display({
        id: 'actions',
        cell: (cx) => {
          const instance = cx.row.original;
          return (
            <InstanceTableRowActions
              instance={instance}
              project={activeProject}
              onOpenInstanceDetails={() => onDisplayInstanceDetails(instance.id)}
            />
          );
        },
        size: 200,
        maxSize: 200,
        enableResizing: false,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const table = useReactTable({
    data: instances,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setFilter,
    autoResetPageIndex: false,
    columnResizeMode: 'onChange',
    enableColumnPinning: true,
    state: {
      globalFilter: filter,
      columnPinning: {
        right: ['actions'],
      },
    },
  });

  return (
    <div className="border-neutral-border-weak m-3 flex flex-col gap-4 overflow-y-auto rounded-2xl border">
      <DataGridHelpers.DataGridRightColumnPinned<Instance>
        tableInstance={table}
        styling={{ headerStyle: 'filled', borderStyle: 'all-sides' }}
        components={{
          TableResults,
        }}
      />
    </div>
  );
};
