import type { BooleanOp, ComparisonOp, CypherResult, PredicateFunction, Property } from '@neo4j/cypher-builder';
import {
  ListComprehension,
  Match,
  Node,
  Param,
  Path,
  Pattern,
  Relationship,
  Variable,
  all,
  and,
  any,
  contains,
  date,
  datetime,
  endsWith,
  eq,
  gt,
  gte,
  in as inOp,
  localdatetime,
  localtime,
  lt,
  lte,
  matches,
  not,
  or,
  size,
  startsWith,
  time,
  toString,
} from '@neo4j/cypher-builder';
import { isEmpty, isEqual, isNil, toNumber, trim } from 'lodash-es';

import { MAX_HTTP_QUERY_API_LIMIT } from '../../../../constants';
import {
  BETWEEN_CONDITION_VALUES_SEPARATOR,
  PROPERTY_CONDITION_AFTER,
  PROPERTY_CONDITION_BEFORE,
  PROPERTY_CONDITION_BETWEEN,
  PROPERTY_CONDITION_CONTAINS,
  PROPERTY_CONDITION_ENDS_WITH,
  PROPERTY_CONDITION_EQUALS,
  PROPERTY_CONDITION_GREATER_THAN,
  PROPERTY_CONDITION_GREATER_THAN_OR_EQUAL,
  PROPERTY_CONDITION_LESS_THAN,
  PROPERTY_CONDITION_LESS_THAN_OR_EQUAL,
  PROPERTY_CONDITION_NOT_BETWEEN,
  PROPERTY_CONDITION_NOT_EQUALS,
  PROPERTY_CONDITION_STARTS_WITH,
  SUGGESTION_ANY_NODE,
  SUGGESTION_ANY_RELATIONSHIP,
  SUGGESTION_VALUE_PLACEHOLDER,
} from '../../../../modules/SearchBar/SearchBar.const';
import {
  isNodeSuggestion,
  isRelationshipSuggestion,
  wrapInContainsQueryRegexp,
} from '../../../../modules/SearchBar/SearchBar.utils';
import type { Suggestion } from '../../../../modules/SearchBar/types';
import { DATE, DATETIME, LOCAL_DATETIME, LOCAL_TIME, TIME } from '../../../../services/temporal/utils.const';
import type { TransformedSearchCategory } from '../../../../types/perspective';
import type { Nullable } from '../../../../types/utility';
import { getCategoryLabels } from './graph-pattern-suggestions';

export const generateGraphPatternCypher = (
  lockedSuggestions: Suggestion[] = [],
  categories: TransformedSearchCategory[] = [],
  visibleRelationships: string[] = [],
  isCaseInsensitive: boolean,
  isHttpQueryApiEnabled: boolean,
): Nullable<CypherResult> => {
  if (!validateGraphPattern(lockedSuggestions, categories, new Set(visibleRelationships))) {
    return null;
  }

  let pattern: Nullable<Pattern> = null;
  const conditionOperators: (ComparisonOp | BooleanOp | PredicateFunction)[] = [];

  for (let idx = 0; idx < lockedSuggestions.length; ++idx) {
    const suggestion = lockedSuggestions[idx];

    if (isNodeSuggestion(suggestion)) {
      const { categoryName, propertyName, propertyType, propertyCondition, propertyConditionValue } = suggestion;
      const categoryLabels = getCategoryLabels(categories, categoryName);
      const node = new Node();
      const hasLabels = categoryLabels.map((label) => node.hasLabel(label));
      pattern = new Pattern(node).where(or(...hasLabels));

      collectConditionOperator(
        conditionOperators,
        node,
        isCaseInsensitive,
        propertyName,
        propertyType,
        propertyCondition ?? getDefaultPropertyCondition(propertyType, propertyConditionValue),
        propertyConditionValue,
      );
    } else if (isRelationshipSuggestion(suggestion)) {
      const { propertyName, propertyType, propertyCondition, propertyConditionValue } = suggestion;
      const relationship = new Relationship({
        type: suggestion.relationshipType === SUGGESTION_VALUE_PLACEHOLDER ? '' : suggestion.relationshipType,
      });
      const relationshipDirection = isNil(suggestion.direction) ? 'undirected' : suggestion.direction;

      collectConditionOperator(
        conditionOperators,
        relationship,
        isCaseInsensitive,
        propertyName,
        propertyType,
        propertyCondition ?? getDefaultPropertyCondition(propertyType, propertyConditionValue),
        propertyConditionValue,
      );

      const nextSuggestion = lockedSuggestions[idx + 1];
      if (isNodeSuggestion(nextSuggestion)) {
        const { categoryName, propertyName, propertyType, propertyCondition, propertyConditionValue } = nextSuggestion;
        const categoryLabels = getCategoryLabels(categories, categoryName);
        const nextNode = new Node();
        const hasLabels = categoryLabels.map((label) => nextNode.hasLabel(label));

        collectConditionOperator(
          conditionOperators,
          nextNode,
          isCaseInsensitive,
          propertyName,
          propertyType,
          propertyCondition ?? getDefaultPropertyCondition(propertyType, propertyConditionValue),
          propertyConditionValue,
        );

        if (!isNil(pattern)) {
          pattern = pattern
            ?.related(relationship)
            .withDirection(relationshipDirection)
            .to(nextNode)
            .where(or(...hasLabels));
        }

        // nextNode has been processed, move idx to the next one
        idx++;
      }
    }
  }

  if (isNil(pattern)) {
    return null;
  }

  const path = new Path();

  let matchClause = new Match(pattern).assignToPath(path);

  if (!isEmpty(conditionOperators)) {
    matchClause = matchClause.where(and(...conditionOperators));
  }

  // limit is not included in the query, we handle it using the limit parameter at the async iterators call for non http query api calls
  const returnClause = isHttpQueryApiEnabled
    ? matchClause.return(path).limit(MAX_HTTP_QUERY_API_LIMIT)
    : matchClause.return(path);

  return returnClause.build();
};

const getDefaultPropertyCondition = (propertyType?: string, propertyConditionValue?: string) => {
  if (isNil(propertyConditionValue)) {
    return undefined;
  }
  if (propertyType === 'string' || propertyType === 'array') {
    return PROPERTY_CONDITION_CONTAINS;
  }
  return PROPERTY_CONDITION_EQUALS;
};

const collectConditionOperator = (
  conditionOperators: (ComparisonOp | BooleanOp | PredicateFunction)[],
  graphElement: Node | Relationship,
  isCaseInsensitive: boolean,
  propertyName?: string,
  propertyType?: string,
  propertyCondition?: string,
  propertyConditionValue?: string,
) => {
  let conditionOperator: Nullable<ComparisonOp | BooleanOp | PredicateFunction> = null;
  if (isNil(propertyName) || isNil(propertyCondition) || isNil(propertyConditionValue)) {
    return;
  }

  const property = graphElement.property(propertyName);

  let value: any = null;

  switch (propertyType) {
    case 'number':
    case 'bigint':
      value = toNumber(propertyConditionValue);
      break;

    case 'boolean':
      getBooleanConditionOperator(conditionOperators, property, propertyConditionValue);
      return;

    case 'array':
      getArrayConditionOperator(
        conditionOperators,
        property,
        propertyCondition,
        propertyConditionValue,
        isCaseInsensitive,
      );
      return;

    case TIME:
    case DATE:
    case DATETIME:
    case LOCAL_TIME:
    case LOCAL_DATETIME:
      getTemporalConditionOperator(
        conditionOperators,
        property,
        propertyType,
        propertyCondition,
        propertyConditionValue,
        isCaseInsensitive,
      );
      return;

    case undefined:
      value = propertyConditionValue.toString();
      break;

    default:
      value = propertyConditionValue;
      break;
  }

  const propertyConditionValueParam = new Param(value);

  switch (propertyCondition) {
    case PROPERTY_CONDITION_EQUALS:
      // if propertyType is empty, it means that the property comes from full text index
      conditionOperator = eq(!isNil(propertyType) ? property : toString(property), propertyConditionValueParam);
      break;
    case PROPERTY_CONDITION_NOT_EQUALS:
      conditionOperator = not(eq(property, propertyConditionValueParam));
      break;
    case PROPERTY_CONDITION_CONTAINS:
      conditionOperator = contains(property, propertyConditionValueParam);
      break;
    case PROPERTY_CONDITION_STARTS_WITH:
      conditionOperator = startsWith(property, propertyConditionValueParam);
      break;
    case PROPERTY_CONDITION_ENDS_WITH:
      conditionOperator = endsWith(property, propertyConditionValueParam);
      break;
    case PROPERTY_CONDITION_GREATER_THAN:
      conditionOperator = gt(property, propertyConditionValueParam);
      break;
    case PROPERTY_CONDITION_GREATER_THAN_OR_EQUAL:
      conditionOperator = gte(property, propertyConditionValueParam);
      break;
    case PROPERTY_CONDITION_LESS_THAN:
      conditionOperator = lt(property, propertyConditionValueParam);
      break;
    case PROPERTY_CONDITION_LESS_THAN_OR_EQUAL:
      conditionOperator = lte(property, propertyConditionValueParam);
      break;
    case PROPERTY_CONDITION_BETWEEN:
    case PROPERTY_CONDITION_NOT_BETWEEN:
      const [lowerBoundParam, upperBoundParam] = propertyConditionValue
        .split(BETWEEN_CONDITION_VALUES_SEPARATOR)
        .map((value) => new Param(toNumber(value)));

      if (lowerBoundParam && upperBoundParam) {
        if (propertyCondition === PROPERTY_CONDITION_BETWEEN) {
          conditionOperator = and(gte(property, lowerBoundParam), lte(property, upperBoundParam));
        } else {
          conditionOperator = or(lt(property, lowerBoundParam), gt(property, upperBoundParam));
        }
      }
  }

  if (!isNil(conditionOperator)) {
    conditionOperators.push(conditionOperator);
  }
};

const getBooleanConditionOperator = (
  conditionOperators: (ComparisonOp | BooleanOp | PredicateFunction)[],
  property: Property,
  conditionValue: string,
) => {
  const value = conditionValue.toLowerCase() === 'true';
  conditionOperators.push(eq(property, new Param(value)));
};

const getTemporalConditionOperator = (
  conditionOperators: (ComparisonOp | BooleanOp | PredicateFunction)[],
  property: Property,
  propertyType: string,
  condition: string,
  conditionValue: string,
  isCaseInsensitive: boolean,
) => {
  const value = isCaseInsensitive ? conditionValue.toString().toUpperCase() : conditionValue.toString();
  const conditionValueParam = new Param(value);

  let temporalConditionValueParam = null;

  switch (propertyType) {
    case DATE:
      temporalConditionValueParam = date(conditionValueParam);
      break;
    case TIME:
      temporalConditionValueParam = time(conditionValueParam);
      break;
    case LOCAL_TIME:
      temporalConditionValueParam = localtime(conditionValueParam);
      break;
    case DATETIME:
      temporalConditionValueParam = datetime(conditionValueParam);
      break;
    case LOCAL_DATETIME:
      temporalConditionValueParam = localdatetime(conditionValueParam);
      break;
  }

  if (isNil(temporalConditionValueParam)) {
    return;
  }

  let conditionOperator: Nullable<ComparisonOp | BooleanOp | PredicateFunction> = null;

  switch (condition) {
    case PROPERTY_CONDITION_EQUALS:
      conditionOperator = eq(property, temporalConditionValueParam);
      break;
    case PROPERTY_CONDITION_NOT_EQUALS:
      conditionOperator = not(eq(property, temporalConditionValueParam));
      break;
    case PROPERTY_CONDITION_BEFORE:
      conditionOperator = lt(property, temporalConditionValueParam);
      break;
    case PROPERTY_CONDITION_AFTER:
      conditionOperator = gt(property, temporalConditionValueParam);
      break;
    case PROPERTY_CONDITION_BETWEEN:
    case PROPERTY_CONDITION_NOT_BETWEEN:
      const [lowerBoundParam, upperBoundParam] = conditionValue
        .split(BETWEEN_CONDITION_VALUES_SEPARATOR)
        .map((conditionValue) => {
          switch (propertyType) {
            case DATE:
              return date(new Param(conditionValue));
            case TIME:
              return time(new Param(conditionValue));
            case LOCAL_TIME:
              return localtime(new Param(conditionValue));
            case DATETIME:
              return datetime(new Param(conditionValue));
            case LOCAL_DATETIME:
              return localdatetime(new Param(conditionValue));
            default:
              return null;
          }
        });

      if (isNil(lowerBoundParam) || isNil(upperBoundParam)) {
        return;
      }

      if (condition === PROPERTY_CONDITION_BETWEEN) {
        conditionOperator = and(gte(property, lowerBoundParam), lte(property, upperBoundParam));
      } else {
        conditionOperator = or(lt(property, lowerBoundParam), gt(property, upperBoundParam));
      }

      break;
  }

  if (!isNil(conditionOperator)) {
    conditionOperators.push(conditionOperator);
  }
};

const getArrayConditionOperator = (
  conditionOperators: (ComparisonOp | BooleanOp | PredicateFunction)[],
  property: Property,
  condition: string,
  conditionValue: string,
  isCaseInsensitive: boolean,
) => {
  const conditionValues = conditionValue.split(',').map(trim);
  const variable = new Variable();
  const exprVariable = new Variable();
  let queryParam: Param;

  switch (condition) {
    case PROPERTY_CONDITION_EQUALS:
      queryParam = new Param(conditionValues);
      conditionOperators.push(
        all(
          variable,
          queryParam,
          inOp(variable, new ListComprehension(exprVariable).in(property).map(toString(exprVariable))),
        ),
        eq(size(queryParam), size(property)),
      );
      break;
    case PROPERTY_CONDITION_CONTAINS:
      queryParam = new Param(conditionValues.map((value) => wrapInContainsQueryRegexp(isCaseInsensitive, value)));
      conditionOperators.push(
        all(
          variable,
          queryParam,
          any(
            exprVariable,
            property,
            isCaseInsensitive ? matches(toString(exprVariable), variable) : contains(toString(exprVariable), variable),
          ),
        ),
      );
      break;
  }
};

const validateGraphPattern = (
  lockedSuggestions: Suggestion[],
  categories: TransformedSearchCategory[],
  visibleRelationships: Set<string>,
) => {
  if (isEmpty(lockedSuggestions) || isEmpty(categories)) {
    return false;
  }
  lockedSuggestions.forEach((suggestion) => {
    if (isNodeSuggestion(suggestion) && !isEqual(suggestion, SUGGESTION_ANY_NODE)) {
      const { categoryName } = suggestion;
      if (getCategoryLabels(categories, categoryName).length === 0) {
        throw Error(`Your graph pattern includes the ${categoryName} category, which has been excluded.`);
      }
    }
    if (isRelationshipSuggestion(suggestion) && !isEqual(suggestion, SUGGESTION_ANY_RELATIONSHIP)) {
      const { relationshipType } = suggestion;
      if (!visibleRelationships.has(relationshipType)) {
        throw Error(`Your graph pattern includes a hidden relationship: ${relationshipType}.`);
      }
    }
  });
  return true;
};
