import { trackPreviewPageLoaded, trackPreviewUiSwitched } from '@query/services/analytics';

export const PREVIEW_SUFFIX = '/preview/';

export const isPreview = () => window.location.pathname.endsWith(PREVIEW_SUFFIX);

export const trackPreviewUiSwitch = () => {
  const now = Date.now();
  localStorage.setItem('prefersOldBrowser', 'true');

  const timeSinceLastSwitchMs = localStorage.getItem('timeSinceLastSwitchMs') ?? null;
  localStorage.setItem('timeSinceLastSwitchMs', now.toString());

  let timeSinceLastSwitch = null;
  if (timeSinceLastSwitchMs !== null) {
    timeSinceLastSwitch = now - parseInt(timeSinceLastSwitchMs, 10);
  }

  if (isPreview()) {
    trackPreviewUiSwitched({ switchedTo: 'browser', timeSinceLastSwitch: timeSinceLastSwitch });
  }
};

export const trackPreviewPageLoad = () => {
  if (isPreview()) {
    trackPreviewPageLoaded({ previewUI: true, hasTriedPreviewUI: true });
  }
};
