import { type Wrapper, type WrapperSession } from '@neo4j-labs/experimental-query-api-wrapper';
import type {
  ConnectionCredentials,
  CypherTransactionMetadata,
  CypherTransactionUtils,
  DriverConnectInfo,
  SSOProviderOriginal,
  TransactionMetadataConfig,
} from '@nx/constants';
import { APP_SCOPE } from '@nx/constants';
import * as StdLib from '@nx/stdlib';
import { promiseTimeout } from '@nx/stdlib';
import type { Driver } from 'neo4j-driver';
import type * as DriverCore from 'neo4j-driver-core';

import { clearSSOData } from '../services/neo4j-sso/auth-request';
import type { Neo4jApi, Neo4jError, SessionConfig } from './neo4j-api';
import { isNeo4jBoltApi, isNeo4jHttpApi, neo4jBoltApi, neo4jHttpApi } from './neo4j-api';

type TokenProvider = () => Promise<DriverCore.AuthTokenAndExpiration>;

class TokenProviderError extends Error {
  static staticCode = 'Connection.TokenProviderError';

  code: string;

  constructor() {
    super('A token provider was not provided to the driver');
    this.name = 'DriverTokenProviderError';
    this.code = TokenProviderError.staticCode;
  }
}

class DriverAuthenticationError extends Error {
  static code = 'Connection.AuthenticationError';

  code: string;

  constructor() {
    super('Driver is not authenticated');
    this.name = 'DriverAuthenticationError';
    this.code = DriverAuthenticationError.code;
  }
}

export class DriverConnectionError extends Error {
  static code = 'Connection.ConnectionError';

  code: string;

  constructor() {
    super('Driver is not connected');
    this.name = 'DriverConnectionError';
    this.code = DriverConnectionError.code;
  }
}

export class NoDatabaseSelectedError extends Error {
  static code = 'Connection.MissingDatabaseError';

  code: string;

  constructor() {
    super('No active database');
    this.name = 'ActiveDatabaseError';
    this.code = NoDatabaseSelectedError.code;
  }
}

export type RunQueryInput = {
  query: DriverCore.types.Query;
  parameters?: DriverCore.types.Parameters;
};

export type RunQueryConfig = {
  metadata: TransactionMetadataConfig;
  recordLimit?: ((newRecord: DriverCore.Record) => boolean) | number;
  sessionConfig: SessionConfig;
  signal?: AbortSignal;
  timeout?: DriverCore.TransactionConfig['timeout'];
  transactionCommitType?: 'implicit' | 'auto';
  transactionMode?: 'write' | 'read';
};

type RunQueryResult<Entries extends DriverCore.RecordShape = DriverCore.RecordShape> = {
  records: DriverCore.Record<Entries>[];
  recordLimitHit: boolean;
  summary: DriverCore.ResultSummary;
};

export const isDriverConnectInfo = (item: unknown): item is DriverConnectInfo =>
  typeof item === 'object' && item !== null && 'authToken' in item && 'url' in item;

const isCallInTransactionError = ({ code, message }: Neo4jError) =>
  (code === 'Neo.DatabaseError.Statement.ExecutionFailed' ||
    code === 'Neo.DatabaseError.Transaction.TransactionStartFailed') &&
  /in an implicit transaction/i.test(message);

const isPeriodicCommitError = ({ code, message }: Neo4jError) =>
  code === 'Neo.ClientError.Statement.SemanticError' &&
  [/in an open transaction is not possible/i, /tried to execute in an explicit transaction/i].some((reg) =>
    reg.test(message),
  );

const isImplicitTransactionError = (error: Neo4jError): boolean =>
  isPeriodicCommitError(error) || isCallInTransactionError(error);

export const getTransactionMetadata: CypherTransactionUtils['getTransactionMetadata'] = (
  { appScope, queryType, version },
  enabledTools: string[],
) => {
  const metadata: CypherTransactionMetadata = {
    app: appScope,
    container_app: 'workspace',
    type: queryType,
    vendor: 'neo4j',
  };

  if (metadata.app === APP_SCOPE.framework) {
    // Rename framework to nx for backwards compatibility with the analytics pipeline.
    metadata.app = 'nx';
  }

  if (enabledTools.length === 1 && enabledTools[0] === 'explore:enabled') {
    metadata.app = 'bloom';
    metadata.container_app = 'standalone';
  }

  if (enabledTools.length === 1 && enabledTools[0] === 'import:enabled') {
    metadata.app = APP_SCOPE.import;
    metadata.container_app = 'standalone';
  }

  if (version !== undefined) {
    metadata.version = version;
  }

  return metadata;
};

export class Neo4jDriverAdapter {
  constructor() {
    this.tokenProvider = () => {
      throw new TokenProviderError();
    };
    this.neo4jApi = neo4jBoltApi;
  }

  /**
   * URL and auth token used to create driver instance.
   *
   * `undefined` if no connection is present.
   */
  connectInfo?: DriverConnectInfo;

  /**
   * Underlying driver instance.
   *
   * `undefined` if no connection is present.
   */
  driver?: Driver | Wrapper;

  /**
   * Refresh SSO auth token using providers given during connection.
   *
   * @throws {TokenProvidersError} Throws if no providers found (potentially non-SSO connection)
   */
  tokenProvider: TokenProvider;

  /**
   * SSO providers used to retrieve auth tokens from
   *
   * `undefined` for non-SSO connections.
   */
  private providers?: SSOProviderOriginal[];

  private neo4jApi: Neo4jApi;

  private setNeo4jApi = (url: string) => {
    const { protocol } = new StdLib.URLs.Neo4jURL(url);

    const queryApiProtocols: string[] = [StdLib.URLs.PROTOCOL_HTTP, StdLib.URLs.PROTOCOL_HTTPS];
    this.neo4jApi = queryApiProtocols.includes(protocol) ? neo4jHttpApi : neo4jBoltApi;
  };

  /**
   * Create driver instance
   */
  async connect(url: string, credentials: ConnectionCredentials, tokenProvider?: TokenProvider): Promise<void> {
    if (this.driver !== undefined) {
      await this.disconnect();
    }

    this.setNeo4jApi(url);

    this.providers = credentials.type === 'sso' ? credentials.providers : undefined;

    if (tokenProvider !== undefined) {
      this.tokenProvider = tokenProvider;
    }

    const authToken =
      credentials.type === 'sso' || credentials.type === 'seamless'
        ? this.neo4jApi.authTokenManagers.bearer({
            tokenProvider: () => this.tokenProvider(),
          })
        : this.neo4jApi.auth.basic(credentials.username, credentials.password);

    this.connectInfo = {
      url,
      authToken: 'getToken' in authToken ? await authToken.getToken() : authToken,
    };

    const config: DriverCore.types.Config = {};
    if (StdLib.URLs.UNSECURE_PROTOCOLS.some((p) => url.startsWith(p))) {
      config.encrypted = 'ENCRYPTION_OFF';
    }

    if (isNeo4jHttpApi(this.neo4jApi)) {
      this.driver = this.neo4jApi.wrapper(url, authToken, config);
    } else if (isNeo4jBoltApi(this.neo4jApi)) {
      this.driver = this.neo4jApi.driver(url, authToken, config);
    } else {
      throw new Error('Unsupported Neo4j API');
    }

    await this.driver.verifyConnectivity({ database: 'system' });

    if (await this.driver.supportsSessionAuth()) {
      if (!(await this.driver.verifyAuthentication({ database: 'system' }))) {
        throw new DriverAuthenticationError();
      }
    }
  }

  /**
   * Destroy driver instance
   */
  async disconnect(shouldClearSSOData = false): Promise<void> {
    if (this.driver !== undefined) {
      if (shouldClearSSOData) {
        await clearSSOData();
      }

      await this.driver.close();
      delete this.connectInfo;
      delete this.driver;
      delete this.providers;
    }
  }

  /**
   * Verify if that the Neo4j system database is reachable
   */
  async healthCheck() {
    if (this.driver === undefined) {
      return false;
    }

    try {
      // We need to pass a database name to verify connectivity. Otherwise, the driver will the discard routing table
      // that could lead to unstable driver connections in clustered environments
      await promiseTimeout(this.driver.verifyConnectivity({ database: 'system' }), 2000);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Get auth token that were used to instantiate a driver instance.
   */
  getAuthToken() {
    return this.connectInfo?.authToken;
  }

  /**
   * Get URL and auth token that were used to instantiate a driver instance.
   */
  getDriverConnectInfo(): DriverConnectInfo | null {
    return this.connectInfo ?? null;
  }

  /**
   * Get underlying Neo4j Driver instance
   *
   * This method is provided as an escape hatch, avoid using it if other means
   * to achieve desired result are available.
   */
  getDriverInstance() {
    return this.driver;
  }

  /**
   * Execute Cypher query
   *
   * @throws {DriverConnectionError} Throws if driver is not connected to database.
   */
  async runQuery<Entries extends DriverCore.RecordShape = DriverCore.RecordShape>(
    input: RunQueryInput,
    { metadata, recordLimit, signal, sessionConfig, timeout, transactionCommitType, transactionMode }: RunQueryConfig,
    enabledTools: string[],
  ): Promise<RunQueryResult<Entries>> {
    if (this.driver === undefined) {
      throw new DriverConnectionError();
    }

    const txConfig = {
      metadata: getTransactionMetadata(metadata, enabledTools),
      timeout,
    };

    const session = this.driver.session({
      bookmarks: sessionConfig.bookmarks,
      database: sessionConfig.database,
    });

    const { neo4jApi } = this;

    async function workFn(txOrSession: DriverCore.ManagedTransaction | DriverCore.Session | WrapperSession) {
      const result = txOrSession.run<Entries>(input.query, input.parameters, txConfig);

      const records = [];
      let recordLimitHit = false;

      for await (const record of result) {
        if (
          recordLimit === undefined ||
          (typeof recordLimit === 'number' && records.length < recordLimit) ||
          (typeof recordLimit === 'function' && recordLimit(record))
        ) {
          records.push(record);
        } else {
          recordLimitHit = true;
          break;
        }
      }

      const summary = await result.summary();
      // TODO: Remove this once the driver returns `resultAvailableAfter` and `resultConsumedAfter` in the summary
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      summary.resultAvailableAfter = summary.resultAvailableAfter ?? neo4jApi.types.Integer.NEG_ONE;
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      summary.resultConsumedAfter = summary.resultConsumedAfter ?? neo4jApi.types.Integer.NEG_ONE;

      return { records, summary, recordLimitHit };
    }

    if (signal !== undefined) {
      signal.addEventListener('abort', () => {
        void session.close();
      });
    }

    try {
      if (transactionCommitType === 'auto') {
        return await workFn(session);
      }

      if (transactionMode === 'write') {
        return await session.executeWrite(workFn, txConfig);
      }

      return await session.executeRead(workFn, txConfig);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      if (error instanceof neo4jApi.Neo4jError && isImplicitTransactionError(error)) {
        return this.runQuery<Entries>(
          input,
          {
            metadata,
            recordLimit,
            signal,
            sessionConfig,
            timeout,
            transactionCommitType: 'auto',
            transactionMode,
          },
          enabledTools,
        );
      }

      throw error;
    } finally {
      await session.close();
    }
  }
}
