export type CmiStatusResponse = CmiEndpointStatus[];

export enum ENDPOINT_TYPE {
  INSTANCE = 'INSTANCE',
  TENANT = 'TENANT',
}

export enum ERROR_TYPE {
  UNAUTHORIZED = 'UNAUTHORIZED',
  INSTANCE_NOT_FOUND = 'INSTANCE_NOT_FOUND',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  NO_INSTANCES_ENABLED = 'NO_INSTANCES_ENABLED',
}

export interface CmiEndpointStatus {
  endpointType: ENDPOINT_TYPE;
  instanceId?: string;
  responseBytes: number;
  responseBytesPeriodSeconds: number;
  lastMetricCount: number;
  lastMetricCountEpochSeconds: number | null;
  lastErrorEpochSeconds: number | null;
  lastErrorType: ERROR_TYPE | null;
  responseCount: number;
  responseCountPeriodSeconds: number;
}

export enum CMI_METRICS_GRANULARITY {
  BASIC = 'Basic',
  COMPREHENSIVE = 'Comprehensive',
}

export type CmiUsageLimits = {
  maxInstances: number;
  maxDatabases: number;
};

export type InstanceScrapeConfig = {
  instanceId: string;
  metricsGranularity: CMI_METRICS_GRANULARITY;
  enableForNewDatabases: false;
  databases: string[];
};

export type InstanceEndpointConfig = {
  config: InstanceScrapeConfig;
};

export type CmiScrapeConfig = {
  projectId: string;
  metricsGranularity: CMI_METRICS_GRANULARITY;
  enableForNewInstances: boolean;
  instances: InstanceScrapeConfig[];
  instanceEndpoints: InstanceEndpointConfig[];
  allowCustomEndpoints: boolean;
  usageLimits: CmiUsageLimits;
};

export type UpdateScrapeConfigRequest = {
  config: CmiScrapeConfig;
  excludedIds: string[];
};
