import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';

type RelateError = { data: { errors: ErrorInfo[] } };
type ErrorInfo = { message: string };

function isRelateError(errorResponse: unknown): errorResponse is RelateError {
  if (isNotNullish(errorResponse) && 'data' in errorResponse) {
    const { data } = errorResponse;
    if (isNotNullish(data) && 'errors' in data) {
      const { errors } = data;
      if (Array.isArray(errors) && errors.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const [error] = errors;
        if (isNotNullish(error) && typeof error === 'object' && 'message' in error) {
          return true;
        }
      }
    }
  }
  return false;
}

export function getRelateErrors(error: FetchBaseQueryError | SerializedError | undefined): ErrorInfo[] {
  if (isRelateError(error)) {
    return error.data.errors;
  }
  return [];
}
