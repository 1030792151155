import { APP_SCOPE } from '@nx/constants';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { createAsyncThunk } from '../../context';
import type { AppPersistMigrate } from '../../persist/persist';
import { DEFAULT_PERSIST_STATE } from '../../persist/persist';
import { getBaseUrl } from '../../utils/get-base-url';

export interface AppManifest {
  name?: string;
  layout?: string;
  version?: string;
  gitSha?: string;
  builtAt?: string;
}

export interface ToolConfiguration {
  displayName: string;
  route: string;
}

export interface AuraConfiguration {
  environment?: string;
  consoleApiUrl?: string;
  importApiUrl?: string;
  oldConsoleFrontendUrl?: string;
  consoleFrontendUrl?: string;
  opsManagerApiUrl?: string;
  storageApiUrl?: string;
  tokenApiUrl?: string;
  graphqlApiUrl?: string;
  dataScienceApiUrl?: string;
}

export interface OnboardingConfiguration {
  route: string;
}

export interface SentryConfiguration {
  dsn?: string;
  appVersion?: string;
  releasePrefix?: string;
}

export interface SegmentConfiguration {
  apiKey?: string;
  eventPrefix?: string;
}

export interface LaunchDarklyConfiguration {
  clientSideId?: string;
}

export interface InternalConfiguration {
  windowTitle?: string;
  basePath?: string;
  fallbackRoute?: string;
  tools: Partial<Record<APP_SCOPE, ToolConfiguration>>;
  aura?: AuraConfiguration;
  sentry?: SentryConfiguration;
  segment?: SegmentConfiguration;
  launchDarkly?: LaunchDarklyConfiguration;
  onboarding?: OnboardingConfiguration;
}

export interface ConfigurationState {
  appManifest: AppManifest;
  remoteAppManifest: AppManifest;
  internal: InternalConfiguration;
  tracking: {
    id?: string;
    accepted: boolean;
  };
  welcomeAppCuesShown: boolean;
  isFirstLoad: boolean;
  firstVisitGuidesShown: boolean;
  termsAndConditionsAccepted: boolean;
  isFirstTimeUser: boolean;
  cookieHintShown: boolean;
  initialPendingInvitesShown: boolean;
  pendingInvitesShown: boolean;
  appUpdateNotificationShownTimestamp?: number;
  selectedUsageTable: 'old' | 'new';
}

export const CONFIGURATION_PERSISTED_KEYS: (keyof ConfigurationState)[] = [
  'welcomeAppCuesShown',
  'termsAndConditionsAccepted',
  'firstVisitGuidesShown',
  'isFirstTimeUser',
  'cookieHintShown',
  'appUpdateNotificationShownTimestamp',
  'initialPendingInvitesShown',
  'pendingInvitesShown',
];

// createMigrate only works when changing the shape of the stored state, it
// doesn't work when switching between different persistence implementations, so
// here we need to migrate manually.
// https://github.com/rt2zz/redux-persist/issues/806
export const migrateConfiguration: AppPersistMigrate<ConfigurationState> = (state = DEFAULT_PERSIST_STATE) => {
  const welcomeAppCuesShownOldKey = 'nx.v1.import.welcomeAppCuesShown';
  const termsAndConditionsAcceptedOldKey = 'nx.v1.nx.termsAndConditionsAccepted';

  const welcomeAppCuesShown = localStorage.getItem(welcomeAppCuesShownOldKey);
  if (state.welcomeAppCuesShown === undefined && welcomeAppCuesShown !== null) {
    state.welcomeAppCuesShown = welcomeAppCuesShown === 'true';
  }

  const termsAndConditionsAccepted = localStorage.getItem(termsAndConditionsAcceptedOldKey);
  if (state.termsAndConditionsAccepted === undefined && termsAndConditionsAccepted !== null) {
    state.termsAndConditionsAccepted = termsAndConditionsAccepted === 'true';
  }

  return Promise.resolve(state);
};

export function selectBasePath(state: ConfigurationState): string {
  return state.internal.basePath ?? '';
}

export function selectFallbackRoute(state: ConfigurationState): string {
  return state.internal.fallbackRoute ?? '/';
}

export function selectWindowTitle(state: ConfigurationState): string | undefined {
  return state.internal.windowTitle;
}

export const selectInternalConfiguration: (state: ConfigurationState) => {
  basePath: string;
  fallbackRoute: string;
  windowTitle?: string;
} = createSelector(selectBasePath, selectFallbackRoute, selectWindowTitle, (basePath, fallbackRoute, windowTitle) => ({
  basePath,
  fallbackRoute,
  windowTitle,
}));

export function makeSelectToolConfiguration(
  toolId: APP_SCOPE,
): (state: ConfigurationState) => ToolConfiguration | undefined {
  return createSelector(
    selectBasePath,
    (state: ConfigurationState) => state.internal.tools[toolId],
    (basePath, configuration) => {
      if (toolId === APP_SCOPE.debugger) {
        return {
          displayName: 'Debug',
          route: `${basePath}/debug`,
        };
      }

      if (configuration === undefined) {
        return undefined;
      }

      return {
        ...configuration,
        route: configuration.route,
      };
    },
  );
}

export const selectToolConfigurations: (
  state: ConfigurationState,
) => Partial<Record<APP_SCOPE, { displayName: string; route: string }>> = createSelector(
  makeSelectToolConfiguration(APP_SCOPE.debugger),
  makeSelectToolConfiguration(APP_SCOPE.explore),
  makeSelectToolConfiguration(APP_SCOPE.import),
  makeSelectToolConfiguration(APP_SCOPE.query),
  makeSelectToolConfiguration(APP_SCOPE.guides),
  makeSelectToolConfiguration(APP_SCOPE.conversations),
  (debuggerConfig, exploreConfig, importConfig, queryConfig, guidesConfig, conversationsConfig) => ({
    [APP_SCOPE.debugger]: debuggerConfig,
    [APP_SCOPE.explore]: exploreConfig,
    [APP_SCOPE.import]: importConfig,
    [APP_SCOPE.query]: queryConfig,
    [APP_SCOPE.guides]: guidesConfig,
    [APP_SCOPE.conversations]: conversationsConfig,
  }),
);

const initialState: ConfigurationState = {
  remoteAppManifest: {},
  appManifest: {},
  internal: {
    tools: {},
    aura: undefined,
    sentry: {},
    segment: {},
    launchDarkly: {},
  },
  tracking: {
    id: undefined,
    accepted: false,
  },
  welcomeAppCuesShown: false,
  termsAndConditionsAccepted: false,
  isFirstLoad: false,
  firstVisitGuidesShown: false,
  isFirstTimeUser: true,
  cookieHintShown: false,
  initialPendingInvitesShown: false,
  pendingInvitesShown: false,
  selectedUsageTable: 'new',
};

function isManifest(object: unknown): object is AppManifest {
  return (
    object !== null &&
    typeof object === 'object' &&
    ['name', 'version', 'layout', 'gitSha', 'builtAt'].every((key) => key in object)
  );
}

export const fetchAppManifest = createAsyncThunk('configuration/fetchAppManifest', async () => {
  const response = await fetch(`${getBaseUrl().replace(/\/$/, '')}/manifest.json`, {
    method: 'get',
    headers: {
      Accept: 'application/json',
    },
  });

  if (response.ok) {
    const result: unknown = await response.json();
    if (isManifest(result)) {
      return result;
    }
  }

  throw new Error('Failed to fetch app manifest');
});

export const slice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    updateAppManifest: (state: ConfigurationState, action: PayloadAction<AppManifest>) => {
      state.appManifest = action.payload;
    },
    appUpdateNotificationShown: (state: ConfigurationState) => {
      state.appUpdateNotificationShownTimestamp = Date.now();
    },
    consentToTracking: (state: ConfigurationState, action: PayloadAction<string>) => {
      if (action.payload.length) {
        state.tracking = {
          id: action.payload,
          accepted: true,
        };
      }
    },
    setWelcomeAppCuesShown: (state: ConfigurationState) => {
      state.welcomeAppCuesShown = true;
    },
    setTermsAndConditionsAccepted: (state: ConfigurationState) => {
      state.termsAndConditionsAccepted = true;
    },
    setIsFirstLoad: (state: ConfigurationState) => {
      state.isFirstLoad = true;
    },
    setFirstVisitGuidesShown: (state: ConfigurationState) => {
      state.firstVisitGuidesShown = true;
    },
    updateBasePath: (state: ConfigurationState, action: PayloadAction<string>) => {
      // Strip trailing slash if present
      state.internal.basePath = action.payload.replace(/\/$/, '');
    },
    updateFallbackRoute: (state: ConfigurationState, action: PayloadAction<string>) => {
      state.internal.fallbackRoute = action.payload;
    },
    setSentryConfiguration: (state: ConfigurationState, action: PayloadAction<SentryConfiguration>) => {
      state.internal.sentry = action.payload;
    },
    setSegmentConfiguration: (state: ConfigurationState, action: PayloadAction<SegmentConfiguration>) => {
      state.internal.segment = action.payload;
    },
    setLaunchDarklyConfiguration: (state: ConfigurationState, action: PayloadAction<LaunchDarklyConfiguration>) => {
      state.internal.launchDarkly = action.payload;
    },
    updateTool: (state: ConfigurationState, action: PayloadAction<{ scope: APP_SCOPE; config: ToolConfiguration }>) => {
      state.internal.tools[action.payload.scope] = action.payload.config;
    },
    updateAura: (state: ConfigurationState, action: PayloadAction<AuraConfiguration>) => {
      state.internal.aura = action.payload;
    },
    updateOnboarding: (state: ConfigurationState, action: PayloadAction<OnboardingConfiguration>) => {
      state.internal.onboarding = action.payload;
    },
    updateWindowTitle: (state: ConfigurationState, action: PayloadAction<string | undefined>) => {
      state.internal.windowTitle = action.payload;
    },
    setIsFirstTimeUser: (state: ConfigurationState, action: PayloadAction<boolean>) => {
      state.isFirstTimeUser = action.payload;
    },
    setCookieHintShown: (state: ConfigurationState, action: PayloadAction<boolean>) => {
      state.cookieHintShown = action.payload;
    },
    setInitialPendingInvitesShown: (state: ConfigurationState, action: PayloadAction<boolean>) => {
      state.initialPendingInvitesShown = action.payload;
    },
    setPendingInvitesShown: (state: ConfigurationState, action: PayloadAction<boolean>) => {
      state.pendingInvitesShown = action.payload;
    },
    setSelectedUsageTable: (state: ConfigurationState, action: PayloadAction<'old' | 'new'>) => {
      state.selectedUsageTable = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAppManifest.fulfilled, (state, action) => {
      state.remoteAppManifest = action.payload;
    });
  },
  selectors: {
    selectSentryConfiguration: (state): SentryConfiguration | undefined => {
      return state.internal.sentry;
    },
    selectSegmentConfiguration: (state): SegmentConfiguration | undefined => {
      return state.internal.segment;
    },
    selectLaunchDarklyConfiguration: (state): LaunchDarklyConfiguration | undefined => {
      return state.internal.launchDarkly;
    },
    selectAuraConfiguration: (state): AuraConfiguration | undefined => {
      return state.internal.aura;
    },
    selectAppManifest: ({ appManifest, remoteAppManifest }) => {
      return { appManifest, remoteAppManifest };
    },
    selectOnboardingConfiguration: (state): OnboardingConfiguration | undefined => {
      return state.internal.onboarding;
    },
  },
});

export const {
  appUpdateNotificationShown,
  consentToTracking,
  setWelcomeAppCuesShown,
  setTermsAndConditionsAccepted,
  setIsFirstLoad,
  setFirstVisitGuidesShown,
  setIsFirstTimeUser,
  setCookieHintShown,
  setInitialPendingInvitesShown,
  setPendingInvitesShown,
  setSentryConfiguration,
  setSegmentConfiguration,
  setLaunchDarklyConfiguration,
  setSelectedUsageTable,
  updateAppManifest,
  updateBasePath,
  updateFallbackRoute,
  updateTool,
  updateAura,
  updateWindowTitle,
  updateOnboarding,
} = slice.actions;
export const {
  selectAppManifest,
  selectSegmentConfiguration,
  selectSentryConfiguration,
  selectLaunchDarklyConfiguration,
  selectAuraConfiguration,
  selectOnboardingConfiguration,
} = slice.selectors;
export default slice.reducer;
