import { Button, TextLink, Typography } from '@neo4j-ndl/react';
import {
  type Address,
  type CardDetails,
  MODAL_TYPE,
  PROJECT_BILLING_METHOD,
  getApiError,
  useActiveProject,
  useModal,
} from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { ControlPanel } from '@nx/ui/src/control-panel';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import classNames from 'classnames';

import { CardBrand } from '../../../components/card-brands';

interface BillingCardSectionProps {
  cardDetails?: CardDetails;
  fetchError: FetchBaseQueryError | SerializedError | undefined;
  isDisabled?: boolean;
}

const toAddressString = (address: Address) => {
  return `${isNotNullish(address.line1) ? `${address.line1}, ` : ' '}${address.city}, ${address.postalCode} ${address.country}`;
};

export function BillingCardSection({ cardDetails, fetchError, isDisabled = false }: BillingCardSectionProps) {
  const modal = useModal(MODAL_TYPE.ADD_PAYMENT_DETAILS);
  const activeProject = useActiveProject();
  let content = null;

  const hasPrePaidBilling = activeProject.billingMethod === PROJECT_BILLING_METHOD.PREPAID;

  if (isNotNullish(fetchError)) {
    const error = getApiError(fetchError);
    content = <span data-testid="fetching-card-error">{error.message}</span>;
  } else if (isNotNullish(cardDetails)) {
    const expiryDate = `${cardDetails.expMonth.padStart(2, '0')}/${cardDetails.expYear.substring(2)}`;
    content = (
      <>
        <ControlPanel.Cell label="Credit/Debit card">
          <div className="flex flex-row flex-wrap items-center gap-2">
            <CardBrand brand={cardDetails.brand} />
            <Typography variant="h5">
              **** **** **** {cardDetails.last4} {expiryDate}
            </Typography>
          </div>
        </ControlPanel.Cell>
        <ControlPanel.Cell label="Billing address">
          <Typography variant="h5">{toAddressString(cardDetails.address)}</Typography>
        </ControlPanel.Cell>
      </>
    );
  }

  return (
    <>
      <ControlPanel className="rounded-tl-none">
        <div className="mb-6 flex flex-row justify-between">
          <h5>Payment method</h5>
          <div className="flex gap-4">
            {activeProject.capabilities.multi_tenant_enterprise && (
              <Typography variant="body-medium">
                <TextLink href="https://neo4j.com/contact-us/" isExternalLink>
                  {hasPrePaidBilling
                    ? 'Switch to credit card billing. Contact us'
                    : 'Switch to prepaid billing. Contact us'}
                </TextLink>
              </Typography>
            )}
            {!hasPrePaidBilling && (
              <Button
                color="neutral"
                fill="outlined"
                size="small"
                onClick={() => modal.open({ hasBilling: activeProject.hasBilling })}
              >
                Replace card
              </Button>
            )}
          </div>
        </div>
        {hasPrePaidBilling && (
          <Typography variant="subheading-large" as="div" className="text-neutral-text-weak mb-6">
            This project has prepaid billing
          </Typography>
        )}
        <div className={classNames('flex flex-wrap gap-x-[8%] gap-y-8', { 'opacity-20': hasPrePaidBilling })}>
          {content}
        </div>
      </ControlPanel>
    </>
  );
}
