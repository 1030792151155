import { Banner, Button, Dialog, type DialogProps, Tabs, TextLink } from '@neo4j-ndl/react';
import * as Icons from '@neo4j-ndl/react/icons';
import { GUIDE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import {
  selectConfiguration,
  useAppSelector,
  useConfigurationActions,
  useConnectionStatus,
  useGuidesActions,
  useModalClose,
  useTrackEvent,
} from '@nx/state';
import cx from 'classnames';

import { WorkspaceGuideCard, getCardStatus } from '../../guides';
import { findRecommended, useGuideWarning, useManifest, usePlaylist } from './guide-selection-service';

const GuideWarning = () => (
  <>
    <p className="n-body-medium">
      These guides contain different datasets. For guides to work properly you have to reset your Aura instance to
      blank.
    </p>
    <TextLink href="TODO" isExternalLink className="n-body-medium">
      Reset instance in Aura
    </TextLink>
  </>
);

type GuideSelectionDialogWrapperProps = {
  children: DialogProps['children'];
  onClose: DialogProps['onClose'];
};

function GuideSelectionDialogWrapper(props: GuideSelectionDialogWrapperProps) {
  return (
    <Dialog
      isOpen
      size="unset"
      onClose={props.onClose}
      modalProps={{
        // fix dialog position to avoid jumping on tab change due to size difference
        className: 'max-w-[1500px] w-[80%] p-8 align-top mt-12 mx-auto',
        id: 'guide-selection-modal',
      }}
    >
      {props.children}
    </Dialog>
  );
}

export function GuideSelectionModal() {
  const trackEvent = useTrackEvent();
  const { openGuide } = useGuidesActions();
  const manifest = useManifest();
  const playlist = usePlaylist();
  const recommendedId = findRecommended(playlist.data?.steps ?? []);
  const { isConnected } = useConnectionStatus();
  const { setFirstVisitGuidesShown } = useConfigurationActions();
  const { firstVisitGuidesShown } = useAppSelector(selectConfiguration);
  const closeModal = useModalClose();

  const advanced = playlist.id === 'advanced';
  const warning = useGuideWarning(!isConnected);

  function handleClose() {
    closeModal();

    if (!firstVisitGuidesShown) {
      trackEvent({ event: GUIDE_EVENTS.CANCEL, scope: APP_SCOPE.framework });
      setFirstVisitGuidesShown();
    }
  }

  if (manifest.isFetching || playlist.isFetching) {
    return (
      <GuideSelectionDialogWrapper onClose={handleClose}>
        <Dialog.Header>
          <div className="bg-palette-neutral-bg-strong rounded-5xl h-8 w-[33%] animate-pulse" />
        </Dialog.Header>
        <Dialog.Subtitle className="mb-[57px] space-y-3">
          <div className="bg-palette-neutral-bg-strong rounded-5xl h-3 animate-pulse" />
          <div className="bg-palette-neutral-bg-strong rounded-5xl h-3 animate-pulse" />
          <div className="bg-palette-neutral-bg-strong rounded-5xl h-3 w-[85%] animate-pulse" />
        </Dialog.Subtitle>
        <Dialog.Content className="grid w-full grid-cols-2 gap-4 lg:grid-cols-4">
          <div className="bg-palette-neutral-bg-strong rounded-5xl h-[348px] w-[294px] animate-pulse" />
          <div className="bg-palette-neutral-bg-strong rounded-5xl h-[348px] w-[294px] animate-pulse" />
          <div className="bg-palette-neutral-bg-strong rounded-5xl h-[348px] w-[294px] animate-pulse" />
          <div className="bg-palette-neutral-bg-strong rounded-5xl h-[348px] w-[294px] animate-pulse" />
        </Dialog.Content>
      </GuideSelectionDialogWrapper>
    );
  }

  // get 'app-guide' playlists from manifest
  const playlistMetadata = Object.entries(manifest.data?.playlists ?? []).filter(
    ([, metadata]) => metadata.attributes?.category === 'app-guide' || metadata.attributes === undefined,
  );

  // ensure a playlist in category 'app-guide' is selected
  if (playlistMetadata.find(([id]) => id === playlist.id) === undefined && playlistMetadata[0] !== undefined) {
    playlist.changePlaylist(playlistMetadata[0][0]);
  }

  if (manifest.isError || playlist.isError) {
    return (
      <GuideSelectionDialogWrapper onClose={handleClose}>
        <Dialog.Header>Error</Dialog.Header>
        <Dialog.Content>
          <Banner type="danger" title="Could not load guides" description={<p>Try again later.</p>} usage="inline" />
        </Dialog.Content>
      </GuideSelectionDialogWrapper>
    );
  }

  if (manifest.data === undefined || playlist.data === undefined) {
    return null;
  }

  return (
    <GuideSelectionDialogWrapper onClose={handleClose}>
      <Dialog.Header>Neo4j Guides</Dialog.Header>
      <Dialog.Subtitle className="pb-6">
        Curated sets of guides to adapt to your needs and learning style.
      </Dialog.Subtitle>
      {playlistMetadata.length > 1 ? (
        <Dialog.Content className="pb-8">
          <Tabs
            onChange={(playlistId) => {
              trackEvent({
                event: GUIDE_EVENTS.OPEN_PLAYLIST,
                properties: { playlistId },
                scope: APP_SCOPE.framework,
              });
              playlist.changePlaylist(playlistId);
            }}
            value={playlist.id}
          >
            {playlistMetadata.map(([key, playlistMetadatum]) => (
              <Tabs.Tab
                key={key}
                tabId={key}
                htmlAttributes={{
                  'aria-controls': undefined,
                }}
              >
                {playlistMetadatum.title}
              </Tabs.Tab>
            ))}
          </Tabs>
        </Dialog.Content>
      ) : null}
      <Dialog.Content className="pb-9">
        {playlist.data.preamble !== undefined && <div dangerouslySetInnerHTML={{ __html: playlist.data.preamble }} />}
      </Dialog.Content>
      <Dialog.Content
        className={cx('grid w-fit gap-4', {
          'grid-cols-2': playlist.data.steps.length >= 2,
          'lg:grid-cols-3': playlist.data.steps.length === 3,
          'lg:grid-cols-4': playlist.data.steps.length >= 4,
        })}
        htmlAttributes={{
          id: 'guide-selection-modal-content',
        }}
      >
        {advanced && warning ? (
          <Banner
            className="col-span-full"
            title="To use these guides reset your free instance in Aura DB"
            children={<GuideWarning />}
            type="warning"
            hasIcon
            usage="inline"
          />
        ) : null}
        {playlist.data.steps.map((guide, i) => (
          <WorkspaceGuideCard
            id={`guide-selection-modal-guide-${i}`}
            key={guide.id}
            title={guide.title}
            description={guide.description}
            iconActiveSrc={guide.iconActive}
            iconDefaultSrc={guide.iconDefault}
            Icon={guide.icon !== undefined ? Icons[guide.icon] : undefined}
            duration={guide.duration}
            progress={guide.progress}
            cardStatus={getCardStatus(recommendedId, guide)}
            type={guide.type}
            onSelect={() => {
              trackEvent({ event: GUIDE_EVENTS.OPEN, properties: { guideId: guide.id }, scope: APP_SCOPE.framework });
              const manifestEntry = manifest.data.guides[guide.id.toString()];
              void openGuide({
                id: guide.id,
                title: guide.title,
                url: manifestEntry?.url ?? '',
                target: 'sidebar',
              });
              handleClose();
            }}
          />
        ))}
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          fill="text"
          onClick={() => {
            trackEvent({ event: GUIDE_EVENTS.BLANK_DATABASE_OPEN, scope: APP_SCOPE.framework });
            handleClose();
          }}
        >
          Continue with blank database
        </Button>
      </Dialog.Actions>
    </GuideSelectionDialogWrapper>
  );
}
