import { APP_SCOPE } from '@nx/constants';
import type { CypherProperty } from '@nx/neo4j-sdk';
import { deserializeTypeAnnotations } from '@nx/neo4j-sdk';
import { Persist } from '@nx/state';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice, nanoid } from '@reduxjs/toolkit';
import { createMigrate, createTransform, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/es/storage';

import { cmdRan } from './editor-slice';
import { queryEvaluateParametersThunk } from './params-thunks';
import type { RootState } from './store';

export const PARAMS_PERSISTED_KEYS = ['parameters', 'retainParameters'];

type DeserializedParamStoreType = {
  id: string;
  key: string;
  value: CypherProperty;
};

type ParamStoreType = {
  id: string;
  key: string;
  value: unknown;
};

export const selectParametersSnapshot: (state: RootState) => Record<string, CypherProperty> = createSelector(
  (state: RootState) => state.params.parameters,
  (params) =>
    Object.fromEntries(
      params
        .filter((p) => p.key !== '' && p.value !== undefined)
        .map((param) => [param.key, deserializeTypeAnnotations(param.value)]),
    ),
);

export const selectDeserializedParameters: (state: RootState) => DeserializedParamStoreType[] = createSelector(
  (state: RootState) => state.params.parameters,
  (params) => {
    return params.map((param) => ({
      ...param,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      value: deserializeTypeAnnotations(param.value),
    }));
  },
);

export const selectParameters = (state: RootState) => state.params.parameters;

export type ParamsState = {
  parameters: ParamStoreType[];
  retainParameters: boolean;
};

const initialState: ParamsState = {
  parameters: [],
  retainParameters: false,
};

const addOrUpdateParameterFunc = (
  state: ParamsState,
  action: PayloadAction<{ parameters: Record<string, unknown> }>,
) => {
  Object.entries(action.payload.parameters).forEach(([paramName, serializedParamValue]) => {
    const existingIndex = state.parameters.findLastIndex((param) => param.key === paramName);
    if (existingIndex !== -1 && state.parameters[existingIndex] !== undefined) {
      state.parameters[existingIndex] = {
        ...state.parameters[existingIndex],
        value: serializedParamValue,
      };
    } else {
      state.parameters.push({
        id: nanoid(),
        key: paramName,
        value: serializedParamValue,
      });
    }
  });
};

const params = createSlice({
  name: 'params',
  initialState,
  reducers: {
    addOrUpdateParameter: addOrUpdateParameterFunc,
    updateParameterValue: (state, action: PayloadAction<{ id: string; serializedParamValue: unknown }>) => {
      const param = state.parameters.find((p) => p.id === action.payload.id);
      if (param !== undefined) {
        param.value = action.payload.serializedParamValue;
      }
    },
    updateParameterKey: (state, action: PayloadAction<{ id: string; newKey: string }>) => {
      const param = state.parameters.find((p) => p.id === action.payload.id);
      if (param !== undefined) {
        param.key = action.payload.newKey;
      }
    },
    updateRetainParameters: (state, action: PayloadAction<{ retainParameters: boolean }>) => {
      state.retainParameters = action.payload.retainParameters;
      // re-setting the parameters to trigger the redux persist's transform
      state.parameters = [...state.parameters];
    },
    deleteParameters: (state, action: PayloadAction<string[]>) => {
      state.parameters = state.parameters.filter((param) => !action.payload.includes(param.id));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cmdRan, (state, action) => {
        const { parsedCmd } = action.payload;
        if (parsedCmd.type === 'parameter' && parsedCmd.args.arg === 'clear') {
          return initialState;
        }
        return state;
      })
      .addCase(queryEvaluateParametersThunk.fulfilled, addOrUpdateParameterFunc);
  },
});

type ParamsStateV2 = {
  parameters: Record<string, unknown>;
  retainParameters: boolean;
};

const migrations = {
  3: (state: ParamsStateV2): ParamsState => {
    return {
      ...state,
      parameters: Object.keys(state.parameters).map((key) => ({
        id: nanoid(),
        key: key,
        value: state.parameters[key],
      })),
    };
  },
};

export const persistedParams = persistReducer<ParamsState>(
  {
    key: Persist.createKey(APP_SCOPE.query, 'params'),
    storage,
    version: 3,
    throttle: 100,
    whitelist: PARAMS_PERSISTED_KEYS,
    stateReconciler: autoMergeLevel2,
    // @ts-ignore redux-persist types are not good enough https://github.com/rt2zz/redux-persist/issues/1065#issuecomment-554538845
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    migrate: createMigrate(migrations),
    transforms: [
      createTransform((inboundState: unknown, key, state: ParamsState) => {
        if (key === 'parameters' && !state.retainParameters) {
          return [];
        }
        return inboundState;
      }),
    ],
  },
  params.reducer,
);

export const {
  addOrUpdateParameter,
  updateParameterValue,
  updateParameterKey,
  updateRetainParameters,
  deleteParameters,
} = params.actions;

export default params.reducer;
