import type { Integer } from 'neo4j-driver';
import neo4j from 'neo4j-driver';

import { MAX_HTTP_QUERY_API_LIMIT } from '../../constants';
import type { ConnectionsDuckState } from '../../state/connections/connectionsDuck';
import { getEscapedSingleQuoteString, getSafeBackTicksString } from '../../state/graph/cypherUtils';
import { DEFAULT_QUERY_RESULT_LIMIT } from '../../state/settings/settings';
import type { Node } from '../../types/graph';
import type { PathSegment } from '../../types/perspective';
import type { Nullable } from '../../types/utility';
import { getSchemaCypher } from '../perspectives/schema';
import type { Schema } from '../perspectives/types';
import { isVersion5OorGreater } from '../versions/versionUtils';

const escapeStrings = (array: string[]) => array.map((r) => `\`${getSafeBackTicksString(r)}\``);

export const systemKeywordsRegex = '^_Bloom_.+_$';
export const LIMIT_QUERY_HANDLER = 10000;
export const LIMIT_SAMPLE = 1000;

export const getAllPaths = (limit: number) => ({
  cypher: 'MATCH p=()-->() return p limit $limit',
  parameters: { limit },
});

export const getNeighboursForIds = (
  ids: string[] = [],
  visibleNodeIds: string[] = [],
  excludeRelsIds: string[] = [],
  relationshipTypes: string[] = [],
  labels: string[] = [],
  schema?: Schema,
  limit = DEFAULT_QUERY_RESULT_LIMIT,
  serverVersion?: ConnectionsDuckState['serverVersion'],
  direction: Nullable<string> = null,
  isHttpQueryApiEnabled = false,
) => {
  const isV5OrGreater = isVersion5OorGreater(serverVersion) ?? false;
  const maxHttpQueryApiLimit = isHttpQueryApiEnabled ? `LIMIT ${MAX_HTTP_QUERY_API_LIMIT}` : '';

  const idsToParameters = (ids: string[]) => (isV5OrGreater ? ids : ids.map((id) => neo4j.int(id)));
  const schemaCypher =
    schema != null
      ? getSchemaCypher({
          schema,
          variable: 'node',
          isV5OrGreater,
        })
      : 'node';
  const escapedRels = escapeStrings(relationshipTypes);
  const escapedLabels = escapeStrings(labels);
  const idScalarFunc = isV5OrGreater ? 'elementId' : 'id';

  const qualifyingNeighbourQuery = `
    MATCH (a)${direction === 'incoming' ? '<-' : '-'}[r${escapedRels.length > 0 ? `:${escapedRels.join('|')}` : ''}]${
      direction === 'outgoing' ? '->' : '-'
    }(node)
    WHERE ${idScalarFunc}(a) IN $ids AND NOT ${idScalarFunc}(r) IN $excludeRelsIds
  `;

  return {
    cypherForNewNodes: `
      ${qualifyingNeighbourQuery}
      ${escapedLabels.length > 0 ? `AND (${escapedLabels.map((label) => `node:${label}`).join(' OR ')})` : ''}
      AND NOT ${idScalarFunc}(node) IN $visibleNodeIds
      WITH DISTINCT node LIMIT ${limit}
      ${qualifyingNeighbourQuery}
      RETURN r, ${schemaCypher} ${maxHttpQueryApiLimit}`,
    cypherForExistingNodes: `
      ${qualifyingNeighbourQuery}
      AND ${idScalarFunc}(node) IN $visibleNodeIds
      RETURN r ${maxHttpQueryApiLimit}`,
    parameters: {
      ids: idsToParameters(ids),
      excludeRelsIds: idsToParameters(excludeRelsIds),
      visibleNodeIds: idsToParameters(visibleNodeIds),
    },
  };
};

export const getConnectedEntitiesForSelectedNodes = (
  selectedNodes: Node[] = [],
  visibleNodeIds: string[] = [],
  visibleRelIds: string[] = [],
  labels: string[] = [],
  allowedRelationshipTypes: string[] = [],
  isV5OrGreater = false,
) => {
  const escapedLabels = escapeStrings(labels);
  const selectedNodeIds = selectedNodes.map((node) => (isV5OrGreater ? node.id : neo4j.int(node.id)));
  const transformedVisibleNodeIds = isV5OrGreater ? visibleNodeIds : visibleNodeIds?.map((id) => neo4j.int(id));
  const transformedVisibleRelIds = isV5OrGreater ? visibleRelIds : visibleRelIds?.map((id) => neo4j.int(id));

  const idScalarFunc = isV5OrGreater ? 'elementId' : 'id';

  return {
    cypher: `
      MATCH (a)-[r]-(o) 
      WHERE ${idScalarFunc}(a) IN $selectedNodeIds ${
        escapedLabels.length > 0 ? ` AND (${escapedLabels.map((label) => `o:${label}`).join(' OR ')})` : ''
      }
        AND NOT ${idScalarFunc}(r) IN $visibleRelIds
        AND type(r) in $allowedRelationshipTypes
      RETURN 
        type(r) as relType,
        CASE WHEN startNode(r) = a THEN 'outgoing' ELSE 'incoming' END AS direction,
        collect(DISTINCT (CASE WHEN NOT ${idScalarFunc}(o) in $visibleNodeIds THEN ${idScalarFunc}(o) END)) AS expandNodeIds,
        collect(DISTINCT (CASE WHEN ${idScalarFunc}(o) in $selectedNodeIds THEN ${idScalarFunc}(r) END)) AS revealRelIds
      `,
    parameters: {
      selectedNodeIds,
      visibleNodeIds: transformedVisibleNodeIds,
      visibleRelIds: transformedVisibleRelIds,
      allowedRelationshipTypes,
    },
  };
};

export const countNeighbourNodesForIds = (
  nodesToExpand: Node[],
  excludeNodesIds: string[] = [],
  relationshipTypes: string[] = [],
  labels: string[] = [],
  direction: Nullable<string> = null,
  isV5OrGreater = false,
) => {
  const escapedRels = escapeStrings(relationshipTypes);
  const escapedLabels = escapeStrings(labels);
  const idScalarFunc = isV5OrGreater ? 'elementId' : 'id';
  const ids = nodesToExpand.map((node) => (isV5OrGreater ? node.id : neo4j.int(node.id)));
  const transformedExcludeNodesIds = isV5OrGreater
    ? excludeNodesIds
    : excludeNodesIds?.map((nodeId) => neo4j.int(nodeId));
  return {
    cypher: `
      MATCH (a)${direction === 'incoming' ? '<-' : '-'}[r${escapedRels.length > 0 ? `:${escapedRels.join('|')}` : ''}]${
        direction === 'outgoing' ? '->' : '-'
      }(o)
      WHERE ${idScalarFunc}(a) IN $ids${
        escapedLabels.length > 0 ? ` AND (${escapedLabels.map((label) => `o:${label}`).join(' OR ')})` : ''
      } AND NOT ${idScalarFunc}(o) IN $excludeNodesIds
      RETURN COUNT(DISTINCT o) AS nodeCount`,
    parameters: {
      ids,
      excludeNodesIds: transformedExcludeNodesIds,
    },
  };
};

export const countNeighbourRelsForIds = (
  nodesToExpand: Node[],
  excludeRelsIds: string[] = [],
  relationshipTypes: string[] = [],
  labels: string[] = [],
  direction: Nullable<string> = null,
  isV5OrGreater = false,
) => {
  const escapedRels = escapeStrings(relationshipTypes);
  const escapedLabels = escapeStrings(labels);
  const idScalarFunc = isV5OrGreater ? 'elementId' : 'id';
  const ids = nodesToExpand.map((node) => (isV5OrGreater ? node.id : neo4j.int(node.id)));
  const transformedExcludeRelIds = isV5OrGreater ? excludeRelsIds : excludeRelsIds?.map((relId) => neo4j.int(relId));
  return {
    cypher: `
      MATCH (a)${direction === 'incoming' ? '<-' : '-'}[r${escapedRels.length > 0 ? `:${escapedRels.join('|')}` : ''}]${
        direction === 'outgoing' ? '->' : '-'
      }(o)
      WHERE ${idScalarFunc}(a) IN $ids${
        escapedLabels.length > 0 ? ` AND (${escapedLabels.map((label) => `o:${label}`).join(' OR ')})` : ''
      } AND NOT ${idScalarFunc}(r) IN $excludeRelsIds
      RETURN COUNT(DISTINCT r) AS relCount`,
    parameters: {
      ids,
      excludeRelsIds: transformedExcludeRelIds,
    },
  };
};

export const getNodesByIdsQuery = (ids: (Integer | string)[], isV5OrGreater = false) => ({
  cypher: `MATCH (entity) WHERE ${isV5OrGreater ? 'elementId' : 'id'}(entity) IN $ids return entity`,
  parameters: { ids },
});

export const getLabels = (exclude = systemKeywordsRegex) => `call db.labels() yield label
  where not label =~ '${exclude}'
  return label`;
export const getTypes = () => 'CALL db.relationshipTypes()';
export const getPropertyKeys = () => 'CALL db.propertyKeys()';
export const getSchemaQuery = () => 'CALL db.schema.visualization()';
export const getIndexes = () => 'SHOW INDEXES';
export const getLegacyIndexes = () => 'CALL db.indexes()';
export const getGdsVersionQuery = () => 'RETURN gds.version() as gdsVersion';
export const getMetadataQuery = (exclude = systemKeywordsRegex) => `
  CALL db.labels() yield label as name with name
  where not name =~ '${exclude}'
  return name, 'label' as type
  UNION ALL
  CALL db.relationshipTypes() yield relationshipType as name
  where not name =~ '${exclude}'
  return name, 'relationshipType' as type
  `;

export const getFullSchemaForLabelsQuery = (labels: string[] | null | undefined) => {
  if (labels == null || labels.length === 0) {
    return null;
  }

  return 'call db.schema.nodeTypeProperties()';
};

export const getFullSchemaForRelationshipTypesQuery = (relationshipTypes: string[] | null | undefined) => {
  if (relationshipTypes == null || relationshipTypes.length === 0) {
    return null;
  }
  return 'call db.schema.relTypeProperties()';
};

export const getApocSampleSchemaForLabelsQuery = (labels: string[] | null | undefined) => {
  if (labels == null || labels.length === 0) {
    return null;
  }

  return 'call apoc.meta.nodeTypeProperties()';
};

export const getApocSampleSchemaForRelationshipTypesQuery = (relationshipTypes: string[] | null | undefined) => {
  if (relationshipTypes == null || relationshipTypes.length === 0) {
    return null;
  }
  return 'call apoc.meta.relTypeProperties()';
};

export const getMinimumSampledSchemaForLabelsQuery = (labels: string[] | null | undefined) => {
  if (labels == null || labels.length === 0) {
    return null;
  }

  return labels
    .map(
      (label) => `    
    MATCH(n:\`${getSafeBackTicksString(label)}\`)
    WITH n
    LIMIT 1
    UNWIND keys(n) as propertyName
    RETURN ['${getEscapedSingleQuoteString(
      label,
    )}'] as nodeLabels,propertyName, collect(n[propertyName])[0] as propertyValue `,
    )
    .join('UNION ALL');
};

export const getMinimumSampledSchemaForRelationshipTypesQuery = (
  relationshipTypes: string[] | null | undefined,
  labels: string[] | null | undefined,
) => {
  if (relationshipTypes == null || relationshipTypes.length === 0 || labels == null || labels.length === 0) {
    return null;
  }

  // It is much faster sampling the nodes and then get all the relationships
  return labels
    .map(
      (label) => `
  MATCH(n:\`${getSafeBackTicksString(label)}\`)-[m]-()
  WITH m
  LIMIT 1
  UNWIND keys(m) as propertyName
  RETURN type(m) as relType,propertyName, collect(m[propertyName])[0] as propertyValue
  `,
    )
    .join('UNION ');
};

export const getSampledSchemaForLabelsQuery = (labels: string[] | null | undefined) => {
  if (labels == null || labels.length === 0) {
    return null;
  }

  return labels
    .map(
      (label) => `    
    MATCH(n:\`${getSafeBackTicksString(label)}\`)
    WITH n
    LIMIT ${LIMIT_SAMPLE}
    UNWIND keys(n) as propertyName
    RETURN ['${getEscapedSingleQuoteString(
      label,
    )}'] as nodeLabels,propertyName, collect(n[propertyName])[0] as propertyValue `,
    )
    .join('UNION ALL');
};

export const getSampledSchemaForRelationshipTypesQuery = (
  relationshipTypes: string[] | null | undefined,
  labels: string[] | null | undefined,
) => {
  if (relationshipTypes == null || relationshipTypes.length === 0 || labels == null || labels.length === 0) {
    return null;
  }

  // It is much faster sampling the nodes and then get all the relationships
  return labels
    .map(
      (label) => `
  MATCH(n:\`${getSafeBackTicksString(label)}\`)
  WITH n
  LIMIT ${LIMIT_SAMPLE / 10}
  MATCH(n)-[m]-()
  WITH m
  LIMIT ${LIMIT_SAMPLE * 10}
  UNWIND keys(m) as propertyName
  RETURN type(m) as relType,propertyName, collect(m[propertyName])[0] as propertyValue
  `,
    )
    .join('UNION ');
};

export const getCoIncidentLabelsQuery = (label: string) => `
  match (n:\`${getSafeBackTicksString(label)}\`)
  with n limit ${LIMIT_QUERY_HANDLER}
  unwind labels(n) as label
  return  distinct label
  `;

export const getShortestPathQuery = (
  source: string | null | undefined,
  target: string | null | undefined,
  schema: Schema,
  visibleRelationshipTypes: string[],
  visibleLabels: string[],
  isV5OrGreater: boolean,
) => {
  if (source != null && target != null) {
    const transformedSourceNodeId = isV5OrGreater ? source : neo4j.int(source);
    const transformedTargetNodeId = isV5OrGreater ? target : neo4j.int(target);
    const idScalarFunc = isV5OrGreater ? 'elementId' : 'id';

    return {
      cypher: `MATCH p = allShortestPaths((n1)-[*..20]-(n2))
        WHERE ${idScalarFunc}(n1) = $source AND ${idScalarFunc}(n2) = $target
        WITH collect(p) AS plist
        WITH [p IN plist WHERE
          all(n IN nodes(p) WHERE any(l IN labels(n) WHERE l IN $visibleLabels)) AND
          all(r IN relationships(p) WHERE type(r) IN $visibleRelationshipTypes) | p
        ] AS fplist
        WITH fplist[0] AS path
        WITH reduce(res = [], n IN nodes(path) | res +
          ${getSchemaCypher({
            schema,
            isV5OrGreater,
          })}
        ) AS mappedNodes, path
        RETURN mappedNodes + relationships(path)`,
      parameters: {
        source: transformedSourceNodeId,
        target: transformedTargetNodeId,
        visibleRelationshipTypes,
        visibleLabels,
      },
    };
  }
  return null;
};

export const getLabelStatsQuery = (labels: string[]) =>
  labels
    .map(
      (label) =>
        `MATCH (n) WHERE n:\`${getSafeBackTicksString(label)}\` RETURN {label:'${getEscapedSingleQuoteString(
          label,
        )}', size: count(*)} as info`,
    )
    .join(' UNION ALL ');

export const getNodesCountByLabel = (labels: string[], limit: 1000) =>
  labels
    .map(
      (label) =>
        `MATCH (n:\`${getSafeBackTicksString(
          label,
        )}\`) WITH count(n) as size WHERE size < ${limit} RETURN '${getEscapedSingleQuoteString(label)}' as label, size`,
    )
    .join(' UNION ALL ');

export const getRelevantRelationshipTypes = (pathSegments: PathSegment[], hiddenRelationshipTypes: string[] = []) => {
  return Array.from(
    pathSegments.reduce((relTypes, segment) => {
      if (!hiddenRelationshipTypes.includes(segment.relationshipType)) {
        relTypes.add(segment.relationshipType);
      }
      return relTypes;
    }, new Set()),
  ) as string[];
};

export const getPropertyKeysDiversityQuery = (indexes: { label: string; propertyKeys: string[] }[]) => {
  const query = indexes
    .filter((labelIndex) => labelIndex.label)
    .map((labelIndex) => {
      const { label, propertyKeys } = labelIndex;
      const labelQuery = `MATCH (e:\`${getSafeBackTicksString(label)}\`)
    WITH e LIMIT ${LIMIT_QUERY_HANDLER}
    RETURN {
      label: '${getEscapedSingleQuoteString(label)}',
      diversities: [${propertyKeys
        .map(
          (propertyKey) => `{
        diversity: count(distinct(e.\`${getSafeBackTicksString(
          propertyKey,
        )}\`)) * 100.0 / (case count(e) when 0 then 1 else count(e) end),
        propertyKey: '${getEscapedSingleQuoteString(propertyKey)}'
        }`,
        )
        .join(', ')}]
    } as diversity`;
      return labelQuery;
    })
    .join('\nUNION ');

  return query;
};

export const retrieveStatsQuery = "CALL db.stats.retrieve('GRAPH COUNTS')";

interface GetRefreshDataCypherParams {
  nodeIds: string[];
  relationshipIds: string[];
  schema: Schema;
  visibleLabels: string[];
  visibleRelationshipTypes: string[];
  isV5OrGreater: boolean;
}

export const getRefreshDataCypher = ({
  nodeIds,
  relationshipIds,
  schema,
  visibleLabels,
  visibleRelationshipTypes,
  isV5OrGreater,
}: GetRefreshDataCypherParams) => {
  if (visibleLabels.length > 0 && (nodeIds.length > 0 || relationshipIds.length > 0)) {
    const returnCypher = getSchemaCypher({
      schema,
      isV5OrGreater,
    });
    const nodeIdsAreIntegers = !isNaN(Number(nodeIds[0]));
    const shouldUseElementId = isV5OrGreater && !nodeIdsAreIntegers;
    const idScalarFunc = shouldUseElementId ? 'elementId' : 'id';

    const listLabelsOrRelTypes = (list: string[], alias: string, joinOp = ' OR ') =>
      list.map((item) => `${alias}\`${getSafeBackTicksString(item)}\``).join(`${joinOp}`);
    let cypher = `MATCH (n)
    WHERE ${idScalarFunc}(n) IN $nodeIds
    AND ( ${listLabelsOrRelTypes(visibleLabels, 'n:')} )
    RETURN ${returnCypher} as n, null as r`;

    if (visibleRelationshipTypes.length > 0) {
      cypher += ` UNION 
        UNWIND $relationshipIds as relId
        MATCH (n)-[r:${listLabelsOrRelTypes(visibleRelationshipTypes, '', '|')}]-(m)
        WHERE ${idScalarFunc}(n) in $nodeIds and ${idScalarFunc}(r) = relId and ${idScalarFunc}(m) in $nodeIds
        AND (${listLabelsOrRelTypes(visibleLabels, 'n:')})
        AND (${listLabelsOrRelTypes(visibleLabels, 'm:')})
        RETURN null as n, r`;
    }

    const nodeIdsParam = shouldUseElementId ? nodeIds : nodeIds.map((id) => neo4j.int(id));
    const relationshipIdsParam = shouldUseElementId ? relationshipIds : relationshipIds.map((id) => neo4j.int(id));

    return {
      cypher,
      parameters: {
        nodeIds: nodeIdsParam,
        relationshipIds: relationshipIdsParam,
      },
    };
  }
  return {};
};

export const getRelsByIds = ({ relIds, isV5OrGreater }: { relIds: string[]; isV5OrGreater: boolean }) => {
  const relationshipIds = isV5OrGreater ? relIds : relIds.map((rel) => neo4j.int(rel));

  return {
    cypher: `
        MATCH (a)-[r]-(o)
        WHERE ${isV5OrGreater ? 'elementId' : 'id'}(r) IN $relIds
        RETURN DISTINCT r
      `,
    parameters: { relIds: relationshipIds },
  };
};
