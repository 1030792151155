import { createSelector } from '@reduxjs/toolkit';
import { isEmpty, isNil, memoize } from 'lodash-es';

import { MIN_SUGGESTION_LETTERS } from '../../../../modules/SearchBar/SearchBar.const';
import type { ActionSuggestion } from '../../../../modules/SearchBar/types';
import type { RootState } from '../../../types';
import { isSuggestionsTurnedOffSelector, selectFilter } from '../../core/search-core.selectors';
import { ACTIONS } from './action-suggestions.consts';

export const selectActionSuggestions: (
  inputText: string,
) => (state: RootState) => ReturnType<(state: RootState, inputText: string) => ActionSuggestion[]> = memoize(
  (
    inputText: string,
  ): ((state: RootState) => ReturnType<(state: RootState, inputText: string) => ActionSuggestion[]>) =>
    createSelector([selectFilter, isSuggestionsTurnedOffSelector], (filter, isSuggestionsTurnedOff) => {
      if (isSuggestionsTurnedOff || isNil(inputText) || inputText.length < MIN_SUGGESTION_LETTERS || !isEmpty(filter)) {
        return [];
      }

      return ACTIONS.filter((action) => action.title.toLowerCase().startsWith(inputText.toLowerCase()));
    }),
);
