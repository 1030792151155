import { isNil } from 'lodash-es';

import { isFullTextSearchSuggestion, isSearchPhraseSuggestion } from '../../modules/SearchBar/SearchBar.utils';
import type { Suggestion } from '../../modules/SearchBar/types';
import { MSG_INVALID_SEARCH_PHRASE } from '../../modules/errorHandler';
import { generateFullTextSearchCypher } from '../../state/search-prototype/suggestions/full-text-suggestion/full-text-suggestion.query';
import { generateGraphPatternCypher } from '../../state/search-prototype/suggestions/graph-pattern-suggestions/graph-pattern-suggestions.query';
import type { FullTextIndex } from '../../types/databaseIndexes';
import type { TransformedSearchCategory } from '../../types/perspective';

export const checkCypherUpdateClauses = (query: string) =>
  !!query.match(/((^[.]*)|(\r\n|\r|\n|\s))(CREATE|DELETE|SET|REMOVE|MERGE)((^[.]*)|(\r\n|\r|\n|\s))/gim);

export const generateCypher = async ({
  lockedSuggestions,
  categories,
  visibleRelationships,
  fullTextIndexes,
  isCaseInsensitive,
  isHttpQueryApiEnabled,
}: {
  lockedSuggestions: Suggestion[];
  categories: TransformedSearchCategory[];
  visibleRelationships: string[];
  fullTextIndexes: FullTextIndex[];
  isCaseInsensitive: boolean;
  isHttpQueryApiEnabled: boolean;
}) => {
  const searchPhraseSuggestion = lockedSuggestions.find(isSearchPhraseSuggestion);
  if (!isNil(searchPhraseSuggestion)) {
    if (searchPhraseSuggestion.hasCypherErrors || !searchPhraseSuggestion.query?.cypher) {
      throw Error(MSG_INVALID_SEARCH_PHRASE);
    }

    if (!searchPhraseSuggestion.complete) {
      throw Error('Please fill in all required parameter values');
    }

    return {
      cypher: searchPhraseSuggestion.query?.cypher,
      params: searchPhraseSuggestion.query?.parameters ?? {},
    };
  }

  const fullTextSearchSuggestion = lockedSuggestions.find(isFullTextSearchSuggestion);
  const isFullTextSearch = !isNil(fullTextSearchSuggestion);

  const generatedCypher = isFullTextSearch
    ? generateFullTextSearchCypher(fullTextSearchSuggestion.sanitizedInputText, fullTextIndexes, isCaseInsensitive)
    : generateGraphPatternCypher(
        lockedSuggestions,
        categories,
        visibleRelationships,
        isCaseInsensitive,
        isHttpQueryApiEnabled,
      );

  if (!generatedCypher) {
    throw Error('Something went wrong, please try again.');
  }
  return generatedCypher;
};
