import { isNotNullish } from '@nx/stdlib';

import type {
  AddTrialCustomerInfoRequest,
  InstanceSeamlessConnectionEnabled,
  InstanceSeamlessProfileResponse,
  OrganizationMemberResponse,
  OrganizationResponse,
  OrganizationRoleResponse,
  OrganizationSeamlessConnectionEnabled,
  OrganizationSeamlessProfileResponse,
  OrganizationSsoIdResponse,
  ProfilingInfoResponse,
  ProjectDefaultSeamlessConnectionEnabled,
  ProjectSeamlessProfileResponse,
  TransformedAddTrialCustomerInfoBody,
  TransformedInstanceSeamlessConnectionEnabled,
  TransformedOrganizationSeamlessConnectionEnabled,
  TransformedProjectDefaultSeamlessConnectionEnabled,
  TransformedUpdateOnboardingProfilingBody,
  TransformedUpdateOrganizationBody,
  TransformedUpdateOrganizationSeamlessProfileBody,
  TrialCustomerInfoResponse,
  UpdateOnboardingProfileRequest,
  UpdateOrganizationMemberRolesRequest,
  UpdateOrganizationRequest,
  UpdateOrganizationSeamlessProfileRequest,
} from '../console.api.types';
import type {
  InstanceSeamlessProfile,
  Organization,
  OrganizationMember,
  OrganizationRole,
  OrganizationSeamlessProfile,
  OrganizationSsoId,
  ProfilingInfo,
  ProjectSeamlessProfile,
  TrialCustomerInfo,
} from '../console.types';

export const transformOrganizationResponse = (response: OrganizationResponse): Organization => ({
  id: response.id,
  ssoOrgId: response.sso_org_id,
  displayName: response.display_name,
  name: response.name,
  passwordLoginEnabled: response.password_login_enabled,
  googleLoginEnabled: response.google_login_enabled,
  planType: response.plan_type,
  // TODO expose value from api
  createdAt: new Date(Date.now() - 10 * 24 * 3600 * 1000).toISOString(),
  copilotEnabled: response.copilot_enabled,
  capabilities: response.capabilities,
  gdsSessions: {
    enabled: response.gds_sessions_enabled,
    maxConcurrentSessions: response.gds_sessions_max_concurrent_sessions,
    maxMemoryPerSession: response.gds_sessions_max_memory_per_session,
  },
  featureToggles: response.feature_toggles,
  profile: response.profile,
  onboardingCompleted: response.onboarding_completed,
  trialEndTime: response.trial_end_time,
});

const transformOrganizationRoleResponse = (response: OrganizationRoleResponse): OrganizationRole => ({
  id: response.role_id,
  userId: response.user_id,
  organizationId: response.organization_id,
  name: response.name,
});

export const transformOrganizationMemberResponse = (response: OrganizationMemberResponse): OrganizationMember => {
  return {
    id: response.user_id,
    email: response.email,
    roles: response.roles.map((role) => transformOrganizationRoleResponse(role)),
    organizationId: response.organization_id,
  };
};

export const transformTrialCustomerInfoResponse = (response: TrialCustomerInfoResponse): TrialCustomerInfo => ({
  firstName: response.first_name,
  lastName: response.last_name,
  jobRole: response.job_role,
  jobRoleOther: response.job_role_other,
  primaryUseCase: response.primary_use_case,
  primaryUseCaseOther: response.primary_use_case_other,
  companyName: response.company_name,
  primaryBusinessLocationCountry: response.primary_business_location_country,
  primaryBusinessLocationSubDivision: response.primary_business_location_sub_division,
});

export const transformOrganizationSeamlessProfileResponse = (
  response: OrganizationSeamlessProfileResponse,
): OrganizationSeamlessProfile => {
  const transformProjectSeamlessProfileResponse = (
    projectSeamlessProfileResponse: ProjectSeamlessProfileResponse,
  ): ProjectSeamlessProfile => {
    const transformInstanceSeamlessProfileResponse = (
      instanceSeamlessProfileResponse: InstanceSeamlessProfileResponse,
    ): InstanceSeamlessProfile => ({
      id: instanceSeamlessProfileResponse.id,
      name: instanceSeamlessProfileResponse.name,
      seamlessConnectionEnabled: instanceSeamlessProfileResponse.seamless_connection_enabled,
      immutable: instanceSeamlessProfileResponse.immutable,
      immutableReason: instanceSeamlessProfileResponse.immutable_reason,
    });

    return {
      id: projectSeamlessProfileResponse.id,
      name: projectSeamlessProfileResponse.name,
      defaultSeamlessConnectionEnabled: projectSeamlessProfileResponse.default_seamless_connection_enabled,
      instances: projectSeamlessProfileResponse.instances.map((resp) => transformInstanceSeamlessProfileResponse(resp)),
    };
  };

  return {
    id: response.id,
    name: response.name,
    projects: response.namespaces.map((resp) => transformProjectSeamlessProfileResponse(resp)),
  };
};

export const transformUpdateOrganizationMemberRolesRequest = (request: UpdateOrganizationMemberRolesRequest) => ({
  roles: request.roles,
});

export const transformUpdateOrganizationRequest = (
  request: UpdateOrganizationRequest,
): TransformedUpdateOrganizationBody => ({
  ...(isNotNullish(request.name) && { display_name: request.name }),
  ...(isNotNullish(request.copilotEnabled) && { copilot_enabled: request.copilotEnabled }),
  ...(isNotNullish(request.gdsSessionsEnabled) && { gds_sessions_enabled: request.gdsSessionsEnabled }),
  ...(isNotNullish(request.gdsSessionsMaxConcurrentSessions) && {
    gds_sessions_max_concurrent_sessions: request.gdsSessionsMaxConcurrentSessions,
  }),
  ...(isNotNullish(request.gdsSessionsMaxMemoryPerSession) && {
    gds_sessions_max_memory_per_session: request.gdsSessionsMaxMemoryPerSession,
  }),
  ...(isNotNullish(request.passwordLoginEnabled) && {
    password_login_enabled: request.passwordLoginEnabled,
  }),
  ...(isNotNullish(request.googleLoginEnabled) && {
    google_login_enabled: request.googleLoginEnabled,
  }),
});

export const transformAddTrialCustomerInfoRequest = (
  request: AddTrialCustomerInfoRequest,
): TransformedAddTrialCustomerInfoBody => ({
  FirstName: request.firstName,
  LastName: request.lastName,
  JobRole: request.jobRole,
  JobRoleOther: request.jobRoleOther ?? null,
  PrimaryUseCase: request.primaryUseCase ?? null,
  PrimaryUseCaseOther: request.primaryUseCaseOther ?? null,
  CompanyName: request.companyName,
  PrimaryBusinessLocationCountry: request.primaryBusinessLocationCountry,
  PrimaryBusinessLocationSubdivision: request.primaryBusinessLocationSubdivision ?? null,
});

export const transformUpdateOnboardingProfileRequest = (
  request: UpdateOnboardingProfileRequest,
): TransformedUpdateOnboardingProfilingBody => ({
  FirstName: request.firstName ?? null,
  LastName: request.lastName ?? null,
  JobRole: request.jobRole ?? null,
  JobRoleOther: request.jobRoleOther ?? null,
  PrimaryUseCase: request.primaryUseCase ?? null,
  PrimaryUseCaseOther: request.primaryUseCaseOther ?? null,
  CompanyName: request.companyName ?? null,
  OnboardingIntent: request.onboardingIntent ?? null,
  OnboardingIntentOther: request.onboardingIntentOther ?? null,
});

export const transformUpdateOnboardingProfileResponse = (request: ProfilingInfoResponse): ProfilingInfo => ({
  firstName: request.first_name,
  lastName: request.last_name,
  companyName: request.company_name,
  jobRole: request.job_role,
  jobRoleOther: request.job_role_other,
  primaryUseCase: request.primary_use_case,
  primaryUseCaseOther: request.primary_use_case_other,
  onboardingIntent: request.onboarding_intent,
  onboardingIntentOther: request.onboarding_intent_other,
});

export const transformOrganizationSsoIdResponse = (response: OrganizationSsoIdResponse): OrganizationSsoId => ({
  id: response.id,
  ssoId: response.sso_id,
});

export const transformUpdateOrganizationSeamlessProfileRequest = (
  request: UpdateOrganizationSeamlessProfileRequest,
): TransformedUpdateOrganizationSeamlessProfileBody => {
  const transformOrganizationSeamlessConnectionEnabled = (
    orgField: OrganizationSeamlessConnectionEnabled,
  ): TransformedOrganizationSeamlessConnectionEnabled => ({
    seamless_connection_enabled: orgField.seamlessConnectionEnabled,
  });
  const transformProjectDefaultSeamlessConnectionEnabled = (
    projectField: ProjectDefaultSeamlessConnectionEnabled,
  ): TransformedProjectDefaultSeamlessConnectionEnabled => ({
    namespace_id: projectField.projectId,
    default_seamless_connection_enabled: projectField.defaultSeamlessConnectionEnabled,
  });
  const transformInstanceSeamlessConnectionEnabled = (
    instanceField: InstanceSeamlessConnectionEnabled,
  ): TransformedInstanceSeamlessConnectionEnabled => ({
    instance_id: instanceField.dbId,
    seamless_connection_enabled: instanceField.seamlessConnectionEnabled,
  });

  return {
    ...(isNotNullish(request.organization) && {
      organization: transformOrganizationSeamlessConnectionEnabled(request.organization),
    }),
    ...(isNotNullish(request.projects) && {
      namespaces: request.projects.map((projectItem) => transformProjectDefaultSeamlessConnectionEnabled(projectItem)),
    }),
    ...(isNotNullish(request.instances) && {
      instances: request.instances.map((instanceItem) => transformInstanceSeamlessConnectionEnabled(instanceItem)),
    }),
  };
};
