import { IconButton, Tooltip } from '@neo4j-ndl/react';
import { ArrowRightCircleIconOutline } from '@neo4j-ndl/react/icons';
import type { AvailableDbmsFilters, TimeRange } from '@nx/state';
import { isEqual } from 'lodash-es';
import { useCallback } from 'react';

import { useNavigateToLogsAction } from '../../shared/hooks/use-navigate-to-logs-action';

type NavigateToLogsButtonProps = {
  timeRange: TimeRange;
  dbmsFilters?: AvailableDbmsFilters;
  querySearchString?: string;
  databases: string[];
};

export const NavigateToLogsButton = ({
  timeRange,
  dbmsFilters,
  querySearchString,
  databases,
}: NavigateToLogsButtonProps) => {
  const { navigateToLogsAction } = useNavigateToLogsAction();

  const onDetails = useCallback(() => {
    navigateToLogsAction({
      timeRange,
      querySearchString,
      requestLogsOnLoad: true,
      dbmsFilters,
    });
  }, [dbmsFilters, navigateToLogsAction, querySearchString, timeRange]);

  const isSystemDatabaseOnly = isEqual(databases, ['system']);

  return (
    <div className="visible-on-row-hover gap-token-4 flex items-center !p-0">
      <Tooltip type="simple">
        <Tooltip.Trigger hasButtonWrapper>
          <IconButton
            ariaLabel="Show query logs"
            className="bg-palette-neutral-bg-weak rounded-lg"
            isClean
            isGrouped
            isDisabled={isSystemDatabaseOnly}
            onClick={onDetails}
            htmlAttributes={{
              'data-testid': 'driver-logs-details',
            }}
          >
            <ArrowRightCircleIconOutline />
          </IconButton>
        </Tooltip.Trigger>
        {isSystemDatabaseOnly && (
          <Tooltip.Content>Can not show query logs for queries against the system database.</Tooltip.Content>
        )}
        {!isSystemDatabaseOnly && <Tooltip.Content>Show query logs</Tooltip.Content>}
      </Tooltip>
    </div>
  );
};
