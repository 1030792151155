import type {
  CDC_ENRICHMENT_MODE,
  CloudProviderRegionMap,
  EncryptionKey,
  Instance,
  InstanceSize,
  InstanceSummary,
  Project,
  ProjectSummary,
  Region,
  TierConfig,
  TierConfigs,
} from '@nx/state';
import {
  CLOUD_PROVIDER,
  INSTANCE_STATUS,
  MONITORING_STATUS,
  NEO4J_MANAGED_KEY,
  NEO4J_MANAGED_KEY_LABEL,
  NEO4J_PROTOCOL,
  ONLINE_STATUS,
  PROJECT_BILLING_METHOD,
  PROJECT_TYPE,
  TIER,
} from '@nx/state';
import { Objects, isNotNullish, isNullish } from '@nx/stdlib';
import { differenceInHours } from 'date-fns';
import * as yup from 'yup';

import { formatDollars } from '../../utils';
import type { Validation } from '../../utils/validation';
import { validateYup } from '../../utils/validation';
import { CREATE_STEP, INSTANCE_NAME_CHARACTER_LIMIT } from './model';
import type { InstanceFormData, InstanceSizeFormData, Product } from './model';

export const getInstanceOnlineStatus = (instance: Instance) => {
  const { monitoringStatus, instanceStatus } = instance;
  const status = isNotNullish(monitoringStatus) ? monitoringStatus : MONITORING_STATUS.OK;

  switch (instanceStatus) {
    case INSTANCE_STATUS.ENCRYPTION_KEY_DELETED:
    case INSTANCE_STATUS.ENCRYPTION_KEY_ERROR:
      return ONLINE_STATUS.DEGRADED;

    case INSTANCE_STATUS.RUNNING:
    case INSTANCE_STATUS.UPDATING:
    case INSTANCE_STATUS.LOADING_DATA:
    case INSTANCE_STATUS.LOADING_FAILED:
    case INSTANCE_STATUS.OVERWRITING:
      switch (status) {
        case MONITORING_STATUS.OK:
          return ONLINE_STATUS.ONLINE;

        case MONITORING_STATUS.WARNING:
          return ONLINE_STATUS.DEGRADED;

        case MONITORING_STATUS.CRITICAL:
          return ONLINE_STATUS.OFFLINE;

        default:
          return ONLINE_STATUS.OFFLINE;
      }

    case INSTANCE_STATUS.CREATING:
    case INSTANCE_STATUS.DESTROYING:
    case INSTANCE_STATUS.DESTROYED:
    case INSTANCE_STATUS.RESTORING:
    case INSTANCE_STATUS.PAUSING:
    case INSTANCE_STATUS.SUSPENDING:
    case INSTANCE_STATUS.PAUSED:
    case INSTANCE_STATUS.SUSPENDED:
    case INSTANCE_STATUS.RESUMING:
      return ONLINE_STATUS.OFFLINE;

    default:
      return ONLINE_STATUS.OFFLINE;
  }
};

export const getOnlineStatusColor = (instance: Instance) => {
  const onlineStatus = getInstanceOnlineStatus(instance);

  // For single-instance products, the DB becomes unavailable during updates
  const isSingleInstanceTier = [TIER.AURA_DSE, TIER.FREE, TIER.GDS].includes(instance.tier);
  if (isSingleInstanceTier && instance.instanceStatus === INSTANCE_STATUS.UPDATING) {
    return 'default';
  }

  switch (onlineStatus) {
    case ONLINE_STATUS.ONLINE:
      return 'success';
    case ONLINE_STATUS.DEGRADED:
      return 'warning';
    case ONLINE_STATUS.OFFLINE:
      return 'default';
    default:
      return 'default';
  }
};

export const boltConnectionValues = (boltUrl: string) => {
  const hostname = boltUrl ? boltUrl.replace(/^[^:]+:\/\//, '') : '';
  const withDefaultProtocol = hostname ? `${NEO4J_PROTOCOL}${hostname}` : '';

  return {
    hostname,
    withDefaultProtocol,
  };
};

export const asPercentageString = (partialValue?: number, maxValue?: number) => {
  const value = partialValue ?? 0;
  const formattedValue = new Intl.NumberFormat().format(value);
  if (!isNullish(maxValue)) {
    const formatted = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value / maxValue);

    return `${formattedValue} (${formatted})`;
  }
  return `${formattedValue}`;
};

export const getPercentage = (partialValue?: number, maxValue?: number) => {
  const value = partialValue ?? 0;
  if (!isNullish(maxValue)) {
    const percentage = (100 * value) / maxValue;
    return percentage;
  }
  return 0;
};

export const connectionStatusText = (
  status: {
    isConnected: boolean;
    isConnecting: boolean;
    isDisconnected: boolean;
    isDisconnecting: boolean;
    isReconnecting: boolean;
  },
  isCurrentConnection: boolean,
) => {
  if (status.isConnected && isCurrentConnection) {
    return 'Disconnect';
  }
  if (status.isConnecting && isCurrentConnection) {
    return 'Connecting';
  }
  if (status.isDisconnecting && isCurrentConnection) {
    return 'Disconnecting';
  }
  if (status.isReconnecting && isCurrentConnection) {
    return 'Reconnecting';
  }
  return 'Connect';
};

export const instanceStatusText = (instance: Instance, project: ProjectSummary | null) => {
  // TODO: ideally this wouldn't be based on tier.
  // It would be better if it were a property of the instance entity,
  // either persisted or computed/derived.
  const isSingleInstance = [TIER.FREE, TIER.GDS, TIER.AURA_DSE].includes(instance.tier);
  switch (instance.instanceStatus) {
    case INSTANCE_STATUS.RESIZING:
      return 'resizing instance';
    case INSTANCE_STATUS.UPDATING:
      if (
        instance.desiredSettings.secondariesCount !== undefined &&
        instance.appliedSettings.secondariesCount !== instance.desiredSettings.secondariesCount
      ) {
        return 'Updating Secondary Count';
      }
      if (isSingleInstance) {
        // Single instances go offline during updates, so we should
        // tell users about it
        return 'updating';
      }

    // Otherwise it's a rolling update to a cluster, which we don't tell users
    // about. In theory it should be indistinguishable from the Running state.
    // Falls through here to RUNNING case to make that link more explicit.
    case INSTANCE_STATUS.RUNNING:
      break;

    case INSTANCE_STATUS.PAUSING:
      return isNotNullish(project?.suspended) && project.suspended
        ? INSTANCE_STATUS.SUSPENDING
        : INSTANCE_STATUS.PAUSING;

    case INSTANCE_STATUS.PAUSED:
      return isNotNullish(project?.suspended) && project.suspended ? INSTANCE_STATUS.SUSPENDED : INSTANCE_STATUS.PAUSED;

    case INSTANCE_STATUS.CREATING:
    default:
      return instance.instanceStatus;
  }

  const onlineStatus = getInstanceOnlineStatus(instance);

  switch (onlineStatus) {
    case ONLINE_STATUS.ONLINE:
      return INSTANCE_STATUS.RUNNING;
    case ONLINE_STATUS.DEGRADED:
    case ONLINE_STATUS.OFFLINE:
      return onlineStatus;
    default:
      return ONLINE_STATUS.OFFLINE;
  }
};

export const friendlyCloudProviderName = (cloudProvider: CLOUD_PROVIDER) => {
  switch (cloudProvider) {
    case CLOUD_PROVIDER.GCP:
      return 'GCP';
    case CLOUD_PROVIDER.AWS:
      return 'AWS';
    case CLOUD_PROVIDER.AZURE:
      return 'Azure';
    default:
      return null;
  }
};

export const friendlyRegionName = (instance: Instance, regions: Region[] = []): string => {
  const cloudProviderName = friendlyCloudProviderName(instance.cloudProvider);
  const region = regions.find((r) => r.name === instance.region);
  const regionName = isNotNullish(region) ? region.friendly : instance.region;

  return `${isNotNullish(cloudProviderName) ? `${cloudProviderName} / ` : ''}${regionName || ''}`;
};

const resumeVisibleMap: Record<INSTANCE_STATUS, boolean> = {
  [INSTANCE_STATUS.CREATING]: false,
  [INSTANCE_STATUS.RUNNING]: false,
  [INSTANCE_STATUS.PAUSING]: true,
  [INSTANCE_STATUS.SUSPENDING]: true,
  [INSTANCE_STATUS.PAUSED]: true,
  [INSTANCE_STATUS.SUSPENDED]: true,
  [INSTANCE_STATUS.RESUMING]: false,
  [INSTANCE_STATUS.LOADING_DATA]: false,
  [INSTANCE_STATUS.LOADING_FAILED]: false,
  [INSTANCE_STATUS.UPDATING]: false,
  [INSTANCE_STATUS.RESIZING]: false,
  [INSTANCE_STATUS.DESTROYING]: false,
  [INSTANCE_STATUS.DESTROYED]: false,
  [INSTANCE_STATUS.RESTORING]: false,
  [INSTANCE_STATUS.OVERWRITING]: false,
  [INSTANCE_STATUS.ENCRYPTION_KEY_DELETED]: false,
  [INSTANCE_STATUS.ENCRYPTION_KEY_ERROR]: false,
};

export const isResumeVisible = (instance: Instance) => {
  return resumeVisibleMap[instance.instanceStatus];
};

const isPausableOnlineStatusMap: Record<ONLINE_STATUS, boolean> = {
  [ONLINE_STATUS.ONLINE]: true,
  [ONLINE_STATUS.DEGRADED]: false,
  [ONLINE_STATUS.OFFLINE]: false,
};

export const isPausable = (instance: Instance) => {
  const onlineStatus = getInstanceOnlineStatus(instance);
  return (
    !instance.isBeingCloned &&
    instance.availableActions.pause.enabled &&
    isPausableOnlineStatusMap[onlineStatus] &&
    instance.capabilities.pause.enabled
  );
};

export const getNotPauseableMessage = (instance: Instance) => {
  if (!instance.capabilities.pause.enabled) {
    return instance.capabilities.pause.message;
  }

  if (!instance.availableActions.pause.enabled) {
    return instance.availableActions.pause.message;
  }

  if (instance.isBeingCloned) {
    return 'Instance cannot be paused while being cloned.';
  }

  return 'This action is not available.';
};

export const formatNumber = (n?: number) => new Intl.NumberFormat(navigator.language).format(n ?? 0);

export const encryptionKeysToOptions = (encryptionKeys: EncryptionKey[], includeDefault = true) => {
  const defaultOption = {
    key: 'neo4j-managed-option',
    label: NEO4J_MANAGED_KEY_LABEL,
    value: NEO4J_MANAGED_KEY,
  };
  const restOptions = encryptionKeys.map((key) => ({
    value: key.encryptionKeyRef,
    label: key.name,
    key: key.encryptionKeyRef,
  }));

  return includeDefault ? [defaultOption].concat(restOptions) : restOptions;
};

export const getEncryptionKeyId = (key: EncryptionKey) => {
  return key.awsProperties?.kmsKeyArn ?? key.gcpProperties?.keyId ?? key.azureProperties?.keyId;
};

export const isMarketplaceProject = (projectType: PROJECT_TYPE) =>
  [PROJECT_TYPE.MARKETPLACE_AWS, PROJECT_TYPE.MARKETPLACE_AZURE, PROJECT_TYPE.N4GCP].includes(projectType);

export const getMarketplaceProjectFriendlyName = (projectType: PROJECT_TYPE) => {
  if (projectType === PROJECT_TYPE.MARKETPLACE_AWS) {
    return 'AWS';
  } else if (projectType === PROJECT_TYPE.MARKETPLACE_AZURE) {
    return 'Azure';
  } else if (projectType === PROJECT_TYPE.N4GCP) {
    return 'GCP';
  }

  return projectType.toString();
};

export const gbStringToInt = (memory: string): number => parseInt(memory.replace(/Gi?B/, ''), 10);

export const isResizing = (instance: Instance) => {
  return (
    instance.instanceStatus === INSTANCE_STATUS.UPDATING &&
    isNotNullish(instance.desiredSettings.memory) &&
    isNotNullish(instance.appliedSettings.memory) &&
    instance.desiredSettings.memory !== instance.appliedSettings.memory
  );
};

export const isResizable = (instance: Instance) =>
  !instance.isBeingCloned && !isResizing(instance) && instance.availableActions.resize.enabled;

export const isVectorOptimizable = (instance: Instance) => instance.availableActions.vector_optimized.enabled;

// isConfigurable captures if an instance can be resized, or additional settings are able to be updated.
export const isConfigurable = (instance: Instance) => isResizable(instance) || isVectorOptimizable(instance);

type InstanceSettingValue = string | number | CDC_ENRICHMENT_MODE | undefined;

const transitionValue = (
  appliedValue: InstanceSettingValue,
  desiredValue: InstanceSettingValue,
  status: INSTANCE_STATUS,
) => {
  if (
    isNotNullish(desiredValue) &&
    [
      INSTANCE_STATUS.CREATING,
      INSTANCE_STATUS.PAUSING,
      INSTANCE_STATUS.PAUSED,
      INSTANCE_STATUS.RESUMING,
      INSTANCE_STATUS.DESTROYING,
    ].includes(status)
  ) {
    return `${desiredValue}`;
  }

  if (isNotNullish(appliedValue) && isNotNullish(desiredValue) && appliedValue !== desiredValue) {
    return `${appliedValue} → ${desiredValue}`;
  }

  if (isNotNullish(appliedValue)) {
    return `${appliedValue}`;
  }

  return null;
};

export const getMemoryText = (instance: Instance) => {
  return transitionValue(instance.appliedSettings.memory, instance.desiredSettings.memory, instance.instanceStatus);
};

export const getCpuText = (instance: Instance) => {
  return transitionValue(instance.appliedSettings.cpu, instance.desiredSettings.cpu, instance.instanceStatus);
};

export const getStorageText = (instance: Instance) => {
  return transitionValue(instance.appliedSettings.storage, instance.desiredSettings.storage, instance.instanceStatus);
};

export const getSecondariesText = (instance: Instance) => {
  return transitionValue(
    instance.appliedSettings.secondariesCount,
    instance.desiredSettings.secondariesCount,
    instance.instanceStatus,
  );
};

export const getCDCText = (instance: Instance) => {
  return transitionValue(
    instance.appliedSettings.cdcEnrichmentMode,
    instance.desiredSettings.cdcEnrichmentMode,
    instance.instanceStatus,
  );
};

export const isPAYG = (tier: TIER, billingMethod: PROJECT_BILLING_METHOD) =>
  [TIER.PROFESSIONAL, TIER.MTE, TIER.GDS].includes(tier) &&
  [PROJECT_BILLING_METHOD.MARKETPLACE, PROJECT_BILLING_METHOD.PAYG].includes(billingMethod);

export const isSizeAvailableInRegion = (size: InstanceSize, region: string): boolean => {
  const unavailable = size.unavailableRegions.includes(region);

  return !unavailable;
};

export const isWithinStorageThreshold = (storage: string, resizeThreshold = 0) => {
  return gbStringToInt(storage) >= resizeThreshold;
};

export const isSizeEnabledByToggle = (memory: string, project: Project, cloudProvider: CLOUD_PROVIDER) => {
  if (memory === '512GB' && cloudProvider === CLOUD_PROVIDER.AZURE) {
    return project.capabilities.azure_512_gi;
  }

  return true;
};

export const isSizeAvailableForVersion = (size: InstanceSize, version: string) => {
  if (isNullish(size.minimumRequiredVersion)) {
    return true;
  }

  return Number(size.minimumRequiredVersion) <= Number(version);
};

type SizeAvailabilityOptions = {
  resizeThreshold?: number;
  isTrial?: boolean;
};

export const sizeUnavailableReason = (
  data: InstanceSizeFormData,
  project: Project,
  sizes: InstanceSize[],
  options: SizeAvailabilityOptions = {},
) => {
  const { resizeThreshold = 0, isTrial } = options;
  const size = sizes.find((obj) => obj.sizeId === data.size?.sizeId);

  if (isNullish(data.region)) {
    return 'You must select a region to see if this size is available';
  }

  if (isNullish(data.version)) {
    return 'You must select a version to see if this size is available';
  }

  if (isNullish(size)) {
    return 'Could not find size';
  }

  if (isNullish(data.cloudProvider)) {
    return 'Cloud provider is missing';
  }

  if (size.deprecated) {
    return 'This size has been deprecated';
  }

  if (isNotNullish(isTrial) && size.isTrial !== isTrial) {
    return isTrial ? 'The selected size is not a trial size' : 'The selected size is a trial size';
  }

  if (!isSizeAvailableInRegion(size, data.region)) {
    return 'Size is not available in the selected region';
  }

  if (!isSizeAvailableForVersion(size, data.version)) {
    return 'Size is not available for the selected version';
  }

  if (size.cloudProvider !== data.cloudProvider) {
    return 'Size cloud provider does not match the currently selected cloud provider';
  }

  if (!isSizeEnabledByToggle(size.memory, project, data.cloudProvider)) {
    return 'This size is disabled via a feature toggle. Please raise a support ticket to request this option for your project';
  }

  if (!isWithinStorageThreshold(size.storage, resizeThreshold)) {
    return 'Existing data is too large for an instance of this size';
  }

  return null;
};

export const isSizeAvailableForFormData = (...args: Parameters<typeof sizeUnavailableReason>) => {
  if (isNullish(sizeUnavailableReason(...args))) {
    return true;
  }

  return false;
};

const defaultMemoryForTier: Record<TIER, string> = {
  [TIER.FREE]: '1GB',
  [TIER.PROFESSIONAL]: '4GB',
  [TIER.GDS]: '8GB',
  [TIER.MTE]: '4GB',
  [TIER.ENTERPRISE]: '4GB',
  [TIER.AURA_DSE]: '8GB',
};

export const getValidSize = (
  data: InstanceSizeFormData,
  project: Project,
  sizes: InstanceSize[],
  options: SizeAvailabilityOptions & { defaultMemory?: string } = {},
) => {
  if (isSizeAvailableForFormData(data, project, sizes, options)) {
    return data.size;
  }

  const memory = isNotNullish(options.defaultMemory) ? options.defaultMemory : defaultMemoryForTier[data.tier];

  // Match the selected size to a default memory value
  const sizesForMemory = sizes.filter((size) => size.memory === memory);
  const validSizeForMemory = sizesForMemory.find((size) =>
    isSizeAvailableForFormData({ ...data, size }, project, sizes, options),
  );
  if (validSizeForMemory) {
    return validSizeForMemory;
  }

  // Pick any valid size
  const validSize = sizes.find((size) => isSizeAvailableForFormData({ ...data, size }, project, sizes, options));
  return validSize;
};

export const getDefaultSelfServeDatabaseName = (instances: InstanceSummary[]) => {
  const pattern = /^Instance(\d+)$/;

  if (instances.length === 0) {
    return 'Instance01';
  }

  // Find instance names of the format "InstanceXX"
  const numbers = instances.map((instance) => {
    const match = instance.name.match(pattern);
    const numberString = isNotNullish(match) && match.length > 1 ? (match[1] ?? '0') : '0';
    return parseInt(numberString, 10);
  });

  // Find the highest number used in those instance names and increment
  const maxNum = Math.max(...numbers);
  const nextNum = maxNum + 1;

  return `Instance${String(nextNum).padStart(2, '0')}`;
};

export const getDefaultName = (sourceDbName: string, suffix: string) => {
  // e.g. ' Clone'
  const padding = suffix.length + 1;
  // e.g. '... Clone'
  const cutoff = suffix.length + 4;
  return `${
    sourceDbName.length + padding > INSTANCE_NAME_CHARACTER_LIMIT
      ? sourceDbName.substring(0, INSTANCE_NAME_CHARACTER_LIMIT - cutoff).concat('...')
      : sourceDbName
  } ${suffix}`;
};

const getDefaultCloudProvider = (
  cloudProviderRegions: CloudProviderRegionMap,
  fallbackCloudProvider?: CLOUD_PROVIDER,
) => {
  const cloudProviders = Objects.keys(cloudProviderRegions);
  let cloudProvider: CLOUD_PROVIDER | undefined;
  if (cloudProviders.length === 1) {
    cloudProvider = cloudProviders[0];
  }

  if (isNullish(cloudProvider) && isNotNullish(fallbackCloudProvider)) {
    cloudProvider = cloudProviders.find((cp) => cp === fallbackCloudProvider);
  }

  if (isNullish(cloudProvider)) {
    throw new Error('Missing cloud providers');
  }

  return cloudProvider;
};

export const enterpriseDefaults = (tierConfig: TierConfig, project: Project): InstanceFormData => {
  const tier = TIER.ENTERPRISE;
  const { cloudProviderRegions } = tierConfig;

  const cloudProvider = getDefaultCloudProvider(cloudProviderRegions);
  const [region] = isNotNullish(cloudProvider) ? (cloudProviderRegions[cloudProvider] ?? []) : [];

  const defaults = {
    name: '',
    region: region?.name,
    version: tierConfig.defaultVersion,
    cloudProvider,
    size: undefined,
    confirmed: false,
    tier,
    encryptionKeyRef: NEO4J_MANAGED_KEY,
    vectorOptimized: false,
    gdsPlugin: false,
  };

  return { ...defaults, size: getValidSize(defaults, project, tierConfig.sizes) };
};

export const dseDefaults = (tierConfig: TierConfig, project: Project): InstanceFormData => {
  const tier = TIER.AURA_DSE;
  const { cloudProviderRegions } = tierConfig;

  const cloudProvider = getDefaultCloudProvider(cloudProviderRegions);
  const [region] = isNotNullish(cloudProvider) ? (cloudProviderRegions[cloudProvider] ?? []) : [];

  const defaults = {
    name: '',
    region: region?.name,
    version: tierConfig.defaultVersion,
    cloudProvider,
    size: undefined,
    confirmed: false,
    tier,
    encryptionKeyRef: NEO4J_MANAGED_KEY,
    vectorOptimized: false,
    gdsPlugin: false,
  };

  return { ...defaults, size: getValidSize(defaults, project, tierConfig.sizes) };
};

export const mteDefaults = (
  tierConfig: TierConfig,
  instances: InstanceSummary[],
  project: Project,
): InstanceFormData => {
  const tier = TIER.MTE;
  const { cloudProviderRegions } = tierConfig;

  // Default to the first cloud provider when we are in a marketplace project. Other projects get defaulted to GCP.
  const cloudProvider = getDefaultCloudProvider(cloudProviderRegions, CLOUD_PROVIDER.GCP);
  const [region] = isNotNullish(cloudProvider) ? (cloudProviderRegions[cloudProvider] ?? []) : [];

  const defaults = {
    name: getDefaultSelfServeDatabaseName(instances),
    region: region?.name,
    version: tierConfig.defaultVersion,
    cloudProvider,
    size: undefined,
    confirmed: false,
    vectorOptimized: false,
    gdsPlugin: false,
    tier,
  };

  return { ...defaults, size: getValidSize(defaults, project, tierConfig.sizes) };
};

export const freeDefaults = (tierConfig: TierConfig, instances: InstanceSummary[]): InstanceFormData => {
  const freeTierCloudProvider = CLOUD_PROVIDER.GCP;
  const region = tierConfig.cloudProviderRegions[freeTierCloudProvider]?.[0]?.name;
  return {
    name: getDefaultSelfServeDatabaseName(instances),
    tier: TIER.FREE,
    region,
    cloudProvider: freeTierCloudProvider,
    confirmed: true,
    version: tierConfig.defaultVersion,
    size: undefined,
    vectorOptimized: false,
    gdsPlugin: false,
  };
};

export const proDefaults = (
  tierConfig: TierConfig,
  instances: InstanceSummary[],
  project: Project,
  isTrial: boolean,
): InstanceFormData => {
  const { cloudProviderRegions } = tierConfig;

  // Default to the first cloud provider when we are in a marketplace project. Other projects get defaulted to GCP.
  const cloudProvider = getDefaultCloudProvider(cloudProviderRegions, CLOUD_PROVIDER.GCP);
  const [region] = isNotNullish(cloudProvider) ? (cloudProviderRegions[cloudProvider] ?? []) : [];

  const defaults = {
    name: getDefaultSelfServeDatabaseName(instances),
    version: tierConfig.defaultVersion,
    tier: TIER.PROFESSIONAL,
    region: region?.name,
    size: undefined,
    cloudProvider: cloudProvider,
    confirmed: false,
    vectorOptimized: false,
    gdsPlugin: false,
  };
  return { ...defaults, size: getValidSize(defaults, project, tierConfig.sizes, { isTrial }) };
};

export const gdsDefaults = (
  tierConfig: TierConfig,
  instances: InstanceSummary[],
  project: Project,
): InstanceFormData => {
  const { cloudProviderRegions } = tierConfig;

  // Default to the first cloud provider when we are in a marketplace project. Other projects get defaulted to GCP.
  const cloudProvider = getDefaultCloudProvider(cloudProviderRegions, CLOUD_PROVIDER.GCP);
  if (isNullish(cloudProvider)) {
    throw new Error('Missing cloud providers');
  }
  const [region] = isNotNullish(cloudProvider) ? (cloudProviderRegions[cloudProvider] ?? []) : [];

  const defaults = {
    name: getDefaultSelfServeDatabaseName(instances),
    version: tierConfig.defaultVersion,
    tier: TIER.GDS,
    region: region?.name,
    size: undefined,
    cloudProvider: cloudProvider,
    confirmed: false,
    vectorOptimized: false,
    gdsPlugin: false,
  };

  return { ...defaults, size: getValidSize(defaults, project, tierConfig.sizes) };
};

// eslint-disable-next-line no-control-regex
const NO_CONTROL_CHARACTERS = /^[^\x00-\x1F\x7F]*$/;

// Lowest common denominator schema for all tiers
// But some fields are overridden for free tier
const basicSchema = {
  name: yup
    .string()
    .max(INSTANCE_NAME_CHARACTER_LIMIT, 'Instance name must be at most 30 characters')
    .matches(NO_CONTROL_CHARACTERS, 'Instance name cannot contain control characters')
    .label('Instance name')
    .required(),
  region: yup.string().required().label('Region'),
  version: yup.string().required().label('Version'),
  size: yup.object().required(),
  vectorOptimized: yup.bool().required(),
};

const commonSchema = {
  ...basicSchema,
  cloudProvider: yup.string().required().label('Cloud Provider'),
  tier: yup.string().required(),
  confirmed: yup
    .bool()
    .required('You must check that you understand the costs of creating a database')
    .isTrue('You must check that you understand the costs of creating a database'),
};

export const gdsSchema = yup.object({ ...commonSchema });

export const proSchema = yup.object({ ...commonSchema });

export const mteSchema = yup.object({ ...commonSchema });

export const freeSchema = yup.object({
  ...basicSchema,
  tier: yup.string().required(),
  region: yup.string().required().label('Region'),
  version: yup.string().label('Version'),
  vectorOptimized: yup.bool(),
  size: yup.object(),
  confirmed: yup.bool(),
});

export const enterpriseSchema = yup.object({
  ...basicSchema,
  encryptionKeyRef: yup.string().required().label('Encryption Key'),
  confirmed: yup
    .bool()
    .required('You must confirm that you understand the costs of creating a database')
    .isTrue('You must confirm that you understand the costs of creating a database'),
});

export const dseSchema = yup.object({
  ...basicSchema,
  encryptionKeyRef: yup.string().required().label('Encryption Key'),
  confirmed: yup
    .bool()
    .required('You must confirm that you understand the costs of creating a database')
    .isTrue('You must confirm that you understand the costs of creating a database'),
});

export const validate = (data: InstanceFormData, { onlyRequired = false }: { onlyRequired?: boolean } = {}) => {
  switch (data.tier) {
    case TIER.PROFESSIONAL:
      return validateYup(proSchema, data, onlyRequired);
    case TIER.FREE:
      return validateYup(freeSchema, data, onlyRequired);
    case TIER.ENTERPRISE:
      return validateYup(enterpriseSchema, data, onlyRequired);
    case TIER.MTE:
      return validateYup(mteSchema, data, onlyRequired);
    case TIER.GDS:
      return validateYup(gdsSchema, data, onlyRequired);
    case TIER.AURA_DSE:
      return validateYup(dseSchema, data, onlyRequired);
    default:
      throw new Error("Trying to validate a tier that doesn't exist");
  }
};

// Prevent clicking on the Create/Next/Upgrade/Clone/whatever button
// if basic prerequisites are not satisfied
export const preventFormSubmission = (
  data: InstanceFormData,
  project: Project,
  validation: Validation<InstanceFormData> | null,
): boolean => {
  if (project.suspended) {
    return true;
  }
  const errors = validate(data);
  if (isNotNullish(errors?.confirmed) || (data.name?.length ?? 0) > 30) {
    return true;
  }
  if (isNotNullish(validation)) {
    return true;
  }
  if (Boolean(data.size?.isPrepaidOnly) && project.billingMethod !== PROJECT_BILLING_METHOD.PREPAID) {
    return true;
  }
  return false;
};

export const isDowngrade = (currentTier: TIER, targetTier: TIER) => {
  if (currentTier === targetTier) {
    return false;
  }
  if (currentTier === TIER.GDS && [TIER.PROFESSIONAL, TIER.MTE].includes(targetTier)) {
    return false;
  }

  if (
    ([TIER.PROFESSIONAL, TIER.GDS].includes(currentTier) && [TIER.ENTERPRISE, TIER.AURA_DSE].includes(targetTier)) ||
    currentTier === TIER.FREE
  ) {
    return false;
  }
  return true;
};

export const isUpgrade = (currentTier: TIER, targetTier: TIER) => {
  return (
    (currentTier === TIER.FREE && targetTier !== TIER.FREE) ||
    ([TIER.PROFESSIONAL, TIER.GDS].includes(currentTier) && [TIER.ENTERPRISE, TIER.AURA_DSE].includes(targetTier))
  );
};

const getCloneTier = (instance: Instance) => {
  switch (instance.tier) {
    case TIER.FREE:
    case TIER.GDS:
    case TIER.PROFESSIONAL:
      return TIER.PROFESSIONAL;
    case TIER.MTE:
      return TIER.MTE;
    case TIER.AURA_DSE:
    case TIER.ENTERPRISE:
      return TIER.ENTERPRISE;
    default:
      throw new Error('Unknown tier');
  }
};

export const tierConfigForClone = (
  tierConfigs: TierConfigs,
  instance: Instance,
  targetTier?: TIER,
  enableFreeCrossCsp = false,
): TierConfig | null => {
  const { cloudProvider, desiredSettings } = instance;
  const clonedTier = targetTier ?? getCloneTier(instance);
  const tierConfig = tierConfigs[clonedTier];
  if (isNullish(tierConfig)) {
    return null;
  }
  const cloudProviderRegions = tierConfig.cloudProviderRegions[cloudProvider];

  if (isNullish(cloudProviderRegions)) {
    return null;
  }

  const storage = gbStringToInt(
    isNotNullish(desiredSettings.storage) ? desiredSettings.storage : (tierConfig.sizes[0]?.storage ?? ''),
  );
  const availableSizes = tierConfig.sizes.filter((size) => gbStringToInt(size.storage) >= storage && !size.isTrial);
  const versions = tierConfig.versions.filter(
    (version) => parseInt(version, 10) >= parseInt(desiredSettings.version, 10),
  );

  return {
    sizes: availableSizes,
    defaultVersion: tierConfig.defaultVersion,
    // Allow users to clone from Free to any other CSP.
    cloudProviderRegions:
      instance.tier === TIER.FREE && enableFreeCrossCsp
        ? tierConfig.cloudProviderRegions
        : {
            [instance.cloudProvider]: cloudProviderRegions,
          },
    tier: instance.tier,
    tierDisplayName: instance.tierDisplayName,
    versions,
    secondaryCost: tierConfig.secondaryCost,
    priorities: tierConfig.priorities,
  };
};

// TODO: remove this final fallback for display name and always rely on the tier configs instead
function hardCodedTierDisplayName(tier: TIER): string {
  switch (tier) {
    case TIER.FREE:
      return 'AuraDB Free';
    case TIER.GDS:
      return 'AuraDS';
    case TIER.PROFESSIONAL:
      return 'AuraDB Professional';
    case TIER.MTE:
      return 'AuraDB Business Critical';
    case TIER.ENTERPRISE:
      return 'Enterprise';
    case TIER.AURA_DSE:
      return 'AuraDS Enterprise';
    default:
      return 'Unknown';
  }
}

export function tierDisplayName(project: Project, tier: TIER): string {
  return (
    project.tierConfigs[tier]?.tierDisplayName ??
    project.unavailableTiers[tier]?.tierDisplayName ??
    hardCodedTierDisplayName(tier)
  );
}

export const getTierLabelName = (project: Project, tier: TIER) => {
  return tierDisplayName(project, tier);
};

export const isProTrialInstance = (instance: Instance): boolean => isNotNullish(instance.trialEndTime);

export const hasActiveProTrial = (instance: Instance): boolean => {
  const trialEndTime = isNotNullish(instance.trialEndTime) ? new Date(instance.trialEndTime) : null;
  if (isNullish(trialEndTime)) {
    return false;
  }

  const now = new Date();

  return trialEndTime > now;
};

export const getRemainingTrialTimeMessage = (instance: Instance): string | null => {
  const trialEndTime = isNotNullish(instance.trialEndTime) ? new Date(instance.trialEndTime) : null;

  const now = new Date();
  if (isNullish(trialEndTime)) {
    return null;
  }

  if (trialEndTime < now) {
    return 'Trial expired';
  }

  const hoursRemaining = Math.max(differenceInHours(trialEndTime, now) + 1, 0);

  if (hoursRemaining <= 1) {
    return '<1 hour trial remaining';
  }
  if (hoursRemaining < 24) {
    return `${hoursRemaining} hours trial remaining`;
  }
  if (hoursRemaining === 24) {
    return '1 day trial remaining';
  }

  const daysRemaining = Math.floor(hoursRemaining / 24);
  return `${daysRemaining} days trial remaining`;
};

/**
 * Ensure there is always one space between the value and the unit
 * and replaces power of 2 based prefixes with corresponding power of 10 variant.
 *
 * e.g "8GB" => "8 GB", "16  GiB" => "16 GB"
 */
export const formatSizeString = (str: string | undefined): string | undefined => {
  const match = (str ?? '').match(/(\d+)\s*([A-Za-z]+)/);

  if (!match) {
    // Unknown input format
    return str;
  }

  const [, size, unit] = match;

  // Console API returns a mix of gibibyte / gigabyte units.
  // We spare the end user from such details to keep it simple.
  const powerOfTenUnit =
    unit?.replace('KiB', 'KB').replace('MiB', 'MB').replace('GiB', 'GB').replace('TiB', 'TB') ?? '';

  return `${size} ${powerOfTenUnit}`;
};

const getCostHour = (costPerHour: string): number => parseFloat(costPerHour);

// 24 hours * 30 days
const getCostMonth = (costPerHour: string): number => parseFloat(costPerHour) * 24 * 30;

export const getCostHourString = (costPerHour: string): string => {
  const cost = getCostHour(costPerHour);
  return formatDollars(cost);
};

export const getCostMonthString = (costPerHour: string): string => {
  const cost = getCostMonth(costPerHour);
  return formatDollars(cost);
};

const PAUSE_COST_FACTOR = 0.2;

export const getPauseCostHourString = (costPerHour: string): string => {
  const pauseCost = getCostHour(costPerHour) * PAUSE_COST_FACTOR;
  return formatDollars(pauseCost);
};

export const getPauseCostMonthString = (costPerHour: string): string => {
  const pauseCost = getCostMonth(costPerHour) * PAUSE_COST_FACTOR;
  return formatDollars(pauseCost);
};

export const isCDCEnabled = (instance: Instance) => instance.capabilities.cdc_enrichment_mode.enabled;

export const isSecondariesEnabled = (instance: Instance) => instance.capabilities.secondaries.enabled;

export const getStepNumber = (step: CREATE_STEP, expectedSteps: CREATE_STEP[]) => expectedSteps.indexOf(step) + 1;

export const getStepTitle = (step: CREATE_STEP) => {
  switch (step) {
    case CREATE_STEP.TIER_SELECTION:
      return 'Tier selection';
    case CREATE_STEP.INSTANCE_CONFIGURATION:
      return 'Configuration';
    case CREATE_STEP.BILLING_SETUP:
      return 'Payment option';
    case CREATE_STEP.PRO_TRIAL_PROFILE_INFORMATION:
      return 'Profile information';
    default:
      // should never get here
      return null;
  }
};

const getSortFn = (value1: number | undefined | null, value2: number | undefined | null) => {
  if (isNullish(value1) || isNullish(value2)) {
    return -1;
  }
  return value1 - value2;
};

export const getMemorySortFn = (instance1: Instance, instance2: Instance) => {
  const str1 = instance1.appliedSettings.memory ?? instance1.desiredSettings.memory;
  const str2 = instance2.appliedSettings.memory ?? instance2.desiredSettings.memory;
  const memory1 = instance1.tier !== TIER.FREE && isNotNullish(str1) ? gbStringToInt(str1) : null;
  const memory2 = instance1.tier !== TIER.FREE && isNotNullish(str2) ? gbStringToInt(str2) : null;
  return getSortFn(memory1, memory2);
};

export const getStorageSortFn = (instance1: Instance, instance2: Instance) => {
  const storageStr1 = instance1.appliedSettings.storage ?? instance1.desiredSettings.storage;
  const storageStr2 = instance2.appliedSettings.storage ?? instance2.desiredSettings.storage;
  const storage1 = isNotNullish(storageStr1) ? gbStringToInt(storageStr1) : null;
  const storage2 = isNotNullish(storageStr2) ? gbStringToInt(storageStr2) : null;
  return getSortFn(storage1, storage2);
};

export const getCpuSortFn = (instance1: Instance, instance2: Instance) => {
  const cpu1 =
    instance1.tier !== TIER.FREE ? (instance1.appliedSettings.cpu ?? instance1.desiredSettings.cpu) : undefined;
  const cpu2 =
    instance2.tier !== TIER.FREE ? (instance2.appliedSettings.cpu ?? instance2.desiredSettings.cpu) : undefined;
  return getSortFn(cpu1, cpu2);
};

export const getDefaultProduct = (tierConfigs: TierConfigs): Product => {
  if (Objects.keys(tierConfigs).some((tier) => [TIER.PROFESSIONAL, TIER.MTE, TIER.ENTERPRISE].includes(tier))) {
    return 'AuraDB';
  }
  return 'AuraDS';
};

export const isMultiProductProject = (tierConfigs: TierConfigs) => {
  const availableTiers = Objects.keys(tierConfigs);
  return (
    availableTiers.some((tier) => [TIER.PROFESSIONAL, TIER.MTE, TIER.ENTERPRISE].includes(tier)) &&
    availableTiers.some((tier) => [TIER.GDS, TIER.AURA_DSE].includes(tier))
  );
};

export const getDefaultTier = (isSelfServe: boolean, product: Product) => {
  if (isSelfServe) {
    return TIER.PROFESSIONAL;
  }
  if (product === 'AuraDB') {
    return TIER.ENTERPRISE;
  }

  return TIER.AURA_DSE;
};

export const getAvailableProducts = (tierConfigs: TierConfigs) => {
  const products: Product[] = [];
  const availableTiers = Objects.keys(tierConfigs);
  if (availableTiers.some((tier) => [TIER.PROFESSIONAL, TIER.MTE, TIER.ENTERPRISE].includes(tier))) {
    products.push('AuraDB');
  }

  if (availableTiers.some((tier) => [TIER.GDS, TIER.AURA_DSE].includes(tier))) {
    products.push('AuraDS');
  }

  return products;
};
