import { getSafeBackTicksString } from '../../state/graph/cypherUtils';
import { isVersion5OorGreater } from '../versions/versionUtils';

export const perspectiveLabel = '_Bloom_Perspective_';

export const getAuthSetting = () => "call dbms.listConfig('dbms.security.auth_enabled')";

export const getCurrentUserDetails = () => 'SHOW CURRENT USER';

export const getCurrentUserPrivileges = (userName: string) =>
  `SHOW USER \`${getSafeBackTicksString(userName)}\` PRIVILEGES`;

export const getAvailableRoles = () => 'SHOW ALL ROLES';

export const getAllProcedures = () => 'SHOW PROCEDURES';

export const getConstraints = () => 'SHOW CONSTRAINTS';

export const createPerspectiveUniqueConstraint = (serverVersion: string) =>
  (isVersion5OorGreater(serverVersion) ?? false)
    ? `CREATE CONSTRAINT FOR (n:\`${perspectiveLabel}\`) REQUIRE n.id IS UNIQUE`
    : `CREATE CONSTRAINT ON (n:\`${perspectiveLabel}\`) ASSERT n.id IS UNIQUE`;

export const getAuthorization = 'CALL bloom.checkAuthorization()';

export const getLicense = 'CALL bloom.checkLicenseCompliance()';

export const getServerPluginVersion = 'CALL bloom.getPluginVersion()';

export const getDatabaseComponents = 'CALL dbms.components()';

export const getAllDatabases = 'SHOW DATABASES';

export const getCurrentDatabaseInfo = 'CALL db.info()';

export const getUpgradeStatus = 'CALL dbms.upgradeStatus()';
