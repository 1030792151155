import { isNotNullish } from '@nx/stdlib';

import type { NewOauthClientResponse, OauthClientResponse } from '../console.api.types';
import type { NewOauthClient, OauthClient } from '../console.types';
import { transformNewOauthClientResponse, transformOauthClientResponse } from '../transformers/oauth';
import { type Builder, LIST_ID } from './types';

export const oauthEndpoints = (builder: Builder) => ({
  listOauthClients: builder.query<OauthClient[], string | void>({
    query: () => 'oauth',
    transformResponse: (clients: OauthClientResponse[]) =>
      clients.map((client) => transformOauthClientResponse(client)),
    providesTags: (result) => {
      if (isNotNullish(result)) {
        return [
          ...result.map(({ clientId }) => ({ type: 'OauthClient' as const, id: clientId })),
          { type: 'OauthClient', id: LIST_ID },
        ];
      }
      return [{ type: 'OauthClient', id: LIST_ID }];
    },
  }),
  createOauthClient: builder.mutation<NewOauthClient, string>({
    query: (name) => ({ url: `oauth`, method: 'POST', body: { Name: name } }),
    transformResponse: (client: NewOauthClientResponse) => transformNewOauthClientResponse(client),
    invalidatesTags: (result) => (result ? [{ type: 'OauthClient', id: LIST_ID }] : []),
  }),
  deleteOauthClient: builder.mutation<void, string>({
    query: (clientId) => ({ url: `oauth/${clientId}`, method: 'DELETE' }),
    invalidatesTags: (result, error, clientId) => [{ type: 'OauthClient', id: clientId }],
  }),
});
