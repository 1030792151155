import type { ReactNode } from 'react';
import { Provider } from 'react-redux';

import { BloomStateContext } from '../state/context';
import { store } from '../state/store';

export const ExploreProvider = ({ children }: { children: ReactNode }) => {
  return (
    <Provider store={store} context={BloomStateContext}>
      {children}
    </Provider>
  );
};
