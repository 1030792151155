import { Banner, Button, Dialog, SegmentedControl, Wizard } from '@neo4j-ndl/react';
import { AURA_CONSOLE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { getFlag } from '@nx/launch-darkly-service';
import { createLogger } from '@nx/logger';
import type { Instance, Project, TierConfig } from '@nx/state';
import {
  MODAL_TYPE,
  NEO4J_MANAGED_KEY,
  PLAN_TYPE,
  TIER,
  consoleApi,
  getApiError,
  getErrorMessage,
  useModal,
} from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Suspense, lazy, useCallback, useMemo, useState } from 'react';

import { StripeFormFallback } from '../../../loading-fallbacks';
import { getProductFromTier } from '../../../project/entities/helpers';
import { useTrackUpxEvent } from '../../../services/segment/analytics';
import type { Validation } from '../../../utils/validation';
import { isValid } from '../../../utils/validation';
import { CDCCloneDBWarning, instanceHasCDCEnabled } from '../../cdc';
import CreateInstanceFormWrapper from '../../create/form';
import {
  dseDefaults,
  enterpriseDefaults,
  gbStringToInt,
  gdsDefaults,
  getDefaultName,
  getStepNumber,
  getStepTitle,
  getValidSize,
  isDowngrade,
  isMultiProductProject,
  mteDefaults,
  preventFormSubmission,
  proDefaults,
  tierConfigForClone,
  validate,
} from '../../entities/helpers';
import type { InstanceFormData, Product } from '../../entities/model';
import { CREATE_STEP } from '../../entities/model';
import {
  DseTierInfo,
  EnterpriseTierInfo,
  FreeTierSelector,
  GdsTierSelector,
  MTETierSelector,
  ProfessionalTierSelector,
} from '../../tier-selector';

const StripeForm = lazy(() => import('../../../project/billing/shared/stripe-form'));

const logger = createLogger(APP_SCOPE.framework);

export const defaults = (
  instance: Instance,
  targetTier: TIER,
  tierConfig: TierConfig,
  project: Project,
): InstanceFormData => {
  const defaultName = getDefaultName(instance.name, 'Clone');

  let data: InstanceFormData;

  switch (targetTier) {
    case TIER.FREE:
    case TIER.GDS:
      data = {
        ...gdsDefaults(tierConfig, [], project),
        name: defaultName,
        cloudProvider: instance.cloudProvider,
        region: instance.region,
        vectorOptimized: instance.vectorOptimized,
      };
      break;
    case TIER.PROFESSIONAL:
      data = {
        ...proDefaults(tierConfig, [], project, false),
        name: defaultName,
        cloudProvider: instance.cloudProvider,
        region: instance.region,
        vectorOptimized: instance.vectorOptimized,
        gdsPlugin: instance.gdsPlugin,
      };
      break;
    case TIER.MTE:
      data = {
        ...mteDefaults(tierConfig, [], project),
        name: defaultName,
        cloudProvider: instance.cloudProvider,
        region: instance.region,
        vectorOptimized: instance.vectorOptimized,
      };
      break;
    case TIER.AURA_DSE:
      data = {
        ...dseDefaults(tierConfig, project),
        name: defaultName,
        cloudProvider: instance.cloudProvider,
        region: instance.region,
        encryptionKeyRef: instance.encryptionKeyRef ?? NEO4J_MANAGED_KEY,
        vectorOptimized: instance.vectorOptimized,
      };
      break;
    case TIER.ENTERPRISE:
      data = {
        ...enterpriseDefaults(tierConfig, project),
        name: defaultName,
        cloudProvider: instance.cloudProvider,
        region: instance.region,
        encryptionKeyRef: instance.encryptionKeyRef ?? NEO4J_MANAGED_KEY,
        vectorOptimized: instance.vectorOptimized,
      };
      break;
    default:
      throw new Error('Tier not supported');
  }

  const size = getValidSize(data, project, tierConfig.sizes, {
    resizeThreshold: isNotNullish(instance.desiredSettings.storage)
      ? gbStringToInt(instance.desiredSettings.storage)
      : undefined,
    isTrial: false,
    defaultMemory: instance.desiredSettings.memory,
  });
  return { ...data, size };
};

type CloneToNewModalProps = {
  instance: Instance;
  project: Project;
  onClose: () => void;
  onConfirm: () => void;
  snapshotId?: string;
};

export const CloneToNewModal = ({ instance, onClose, project, snapshotId }: CloneToNewModalProps) => {
  const trackEvent = useTrackUpxEvent();
  const { tierConfigs, requiresBilling, hasBilling, planType, allowFreeDatabaseCreation } = project;
  const defaultTierConfig = tierConfigs[instance.tier];
  const freeCrossCspCloning = getFlag('freeCrossCspCloning', false);
  const [product, setProduct] = useState<Product>(getProductFromTier(instance.tier));
  if (isNullish(defaultTierConfig)) {
    throw new Error(`TierConfig for ${instance.tier} is missing.`);
  }
  const createdInstanceModal = useModal(MODAL_TYPE.INSTANCE_CREATED);

  const [createInstance, createInstanceRes] = consoleApi.useCreateInstanceMutation();

  const isSelfServe = planType === PLAN_TYPE.SELF_SERVE;
  const showBilling = requiresBilling && !hasBilling;

  let expectedSteps: CREATE_STEP[] = [CREATE_STEP.INSTANCE_CONFIGURATION];
  if (showBilling) {
    expectedSteps = [CREATE_STEP.TIER_SELECTION, CREATE_STEP.INSTANCE_CONFIGURATION, CREATE_STEP.BILLING_SETUP];
  } else if (isSelfServe) {
    expectedSteps = [CREATE_STEP.TIER_SELECTION, CREATE_STEP.INSTANCE_CONFIGURATION];
  }

  const [activeStep, setActiveStep] = useState<CREATE_STEP>(
    isSelfServe ? CREATE_STEP.TIER_SELECTION : CREATE_STEP.INSTANCE_CONFIGURATION,
  );

  const [data, setData] = useState<InstanceFormData>(() =>
    defaults(instance, instance.tier, defaultTierConfig, project),
  );
  const [validation, setValidation] = useState<Validation<InstanceFormData> | null>(null);

  const isSubmitDisabled = useMemo(() => {
    return preventFormSubmission(data, project, validation);
  }, [data, project, validation]);

  const errorMessage = useMemo(() => {
    if (!createInstanceRes.isError) {
      return null;
    }
    const error = getApiError(createInstanceRes.error);
    const message = getErrorMessage(error);
    return message;
  }, [createInstanceRes.error, createInstanceRes.isError]);

  const createFormTierConfig = useMemo(
    () => tierConfigForClone(tierConfigs, instance, data.tier, freeCrossCspCloning) ?? undefined,
    [tierConfigs, instance, data.tier, freeCrossCspCloning],
  );

  const handleCreate = (formData: InstanceFormData | null) => {
    if (isNullish(formData)) {
      return;
    }
    const validationError = validate(formData);
    if (!isValid(formData, validationError)) {
      setValidation(validationError);
      return;
    }
    setValidation(null);

    const payloadData = {
      tier: formData.tier,
      region: formData.region,
      version: formData.version,
      cloudProvider: formData.cloudProvider,
      name: formData.name,
      memory: formData.size.memory,
      storage: formData.size.storage,
      projectId: project.id,
      sourceSnapshot: {
        dbId: instance.id,
        ...(isNotNullish(snapshotId) ? { snapshotId } : {}),
      },
      ...(project.capabilities.cmek &&
        [TIER.ENTERPRISE, TIER.AURA_DSE].includes(formData.tier) && {
          encryptionKeyRef: formData.encryptionKeyRef === NEO4J_MANAGED_KEY ? undefined : formData.encryptionKeyRef,
        }),
      isTrial: false,
    };

    createInstance(payloadData)
      .unwrap()
      .then((result) => {
        onClose();
        createdInstanceModal.open({ createdInstance: result });
      })
      .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(e);
        if (isNotNullish(error.message)) {
          logger.error(error.message);
        }
      });

    trackEvent({
      event: AURA_CONSOLE_EVENTS.INSTANCE_CLONE_TO_NEW,
      properties: {
        sourceTier: instance.tier,
        targetTier: formData.tier,
      },
      scope: APP_SCOPE.aura,
    });
  };

  const handleDataChange = useCallback(
    (newData: InstanceFormData) => {
      if (isNotNullish(validation)) {
        setValidation(null);
      }
      setData((oldData) => ({ ...oldData, ...newData }));
    },
    [validation],
  );

  const proceedToNextStep = () => {
    const nextStep = expectedSteps[expectedSteps.indexOf(activeStep) + 1];
    if (isNotNullish(nextStep)) {
      setActiveStep(nextStep);
    }
  };

  const goBackToPreviousStep = () => {
    const previousStep = expectedSteps[expectedSteps.indexOf(activeStep) - 1];
    if (isNotNullish(previousStep)) {
      setActiveStep(previousStep);
    }
  };

  const handleSelectTier = (tier: TIER) => {
    setValidation(null);
    const tierConfig = tierConfigs[tier];
    if (isNullish(tierConfig)) {
      // This should never happen...
      logger.error(`Tier '${tier}' was selected, but no config exists for it in project ${project.id}.`);
      return;
    }

    setData({ ...defaults(instance, tier, tierConfig, project) });
    proceedToNextStep();
  };

  const handleProductSelect = (newProduct: Product) => {
    if (project.planType === PLAN_TYPE.ENTERPRISE) {
      const tier = newProduct === 'AuraDB' ? TIER.ENTERPRISE : TIER.AURA_DSE;
      const tierConfig = tierConfigs[tier];
      if (isNullish(tierConfig)) {
        // This should never happen...
        logger.error(`Tier '${tier}' was selected, but no config exists for it in project ${project.id}.`);
        return;
      }
      setData(defaults(instance, tier, tierConfig, project));
    }
    setProduct(newProduct);
  };

  return (
    <Dialog
      isOpen
      onClose={onClose}
      size="unset"
      modalProps={{ className: 'max-w-[1500px] mx-auto mt-12 p-10 align-top', 'data-testid': 'clone-to-new-modal' }}
    >
      <Dialog.Header>
        <div className="flex items-baseline justify-between">
          {isNullish(snapshotId) ? 'Clone instance' : 'Create instance from snapshot'}{' '}
          {activeStep === CREATE_STEP.TIER_SELECTION &&
            isMultiProductProject(tierConfigs) &&
            instance.tier !== TIER.MTE && (
              <SegmentedControl onChange={handleProductSelect} selected={product}>
                <SegmentedControl.Item
                  value="AuraDB"
                  htmlAttributes={{
                    'aria-label': 'View Aura Database plan',
                    'data-testid': 'select-product-auradb',
                  }}
                >
                  AuraDB
                </SegmentedControl.Item>
                <SegmentedControl.Item
                  value="AuraDS"
                  htmlAttributes={{
                    'aria-label': 'View Aura Data Science plan',
                    'data-testid': 'select-product-aurads',
                  }}
                >
                  AuraDS
                </SegmentedControl.Item>
              </SegmentedControl>
            )}
        </div>
      </Dialog.Header>
      {activeStep !== CREATE_STEP.TIER_SELECTION &&
        getStepNumber(activeStep, expectedSteps) > 0 &&
        expectedSteps.length > 2 && (
          <div className="mx-auto mb-8 flex w-1/2">
            <Wizard
              orientation="horizontal"
              className="w-full"
              steps={expectedSteps.map((step) => ({
                content: (
                  <div key={`${getStepNumber(step, expectedSteps)}`} className="flex text-nowrap">
                    {getStepTitle(step)}
                  </div>
                ),
              }))}
              activeStepIndex={getStepNumber(activeStep, expectedSteps) - 1}
            />
          </div>
        )}
      {activeStep === CREATE_STEP.TIER_SELECTION && (
        <Dialog.Content>
          <div className="flex flex-col gap-8">
            <div className="flex justify-between gap-8">
              {product === 'AuraDB' ? (
                <>
                  <FreeTierSelector
                    project={project}
                    isLoading={createInstanceRes.isLoading}
                    disabledReason="You can not clone to a Free instance."
                    isDisabled
                  />
                  <ProfessionalTierSelector
                    project={project}
                    isDisabled={isDowngrade(instance.tier, TIER.PROFESSIONAL) || isNullish(tierConfigs.professional)}
                    onSelect={() => handleSelectTier(TIER.PROFESSIONAL)}
                    disabledReason={
                      isNotNullish(tierConfigs.professional)
                        ? 'You can not clone a Business Critical instance to a Professional instance.'
                        : 'This option is not available.'
                    }
                  />

                  {project.capabilities.multi_tenant_enterprise && isNotNullish(tierConfigs.mte) ? (
                    <MTETierSelector project={project} onSelect={() => handleSelectTier(TIER.MTE)} />
                  ) : (
                    <EnterpriseTierInfo project={project} />
                  )}
                </>
              ) : (
                <div className="grid grid-cols-2 gap-8">
                  <GdsTierSelector
                    project={project}
                    isDisabled={isNullish(tierConfigs[TIER.GDS])}
                    onSelect={() => handleSelectTier(TIER.GDS)}
                    disabledReason="Aura Data Science is not available in this Project."
                  />
                  <DseTierInfo project={project} />
                </div>
              )}
            </div>
            {project.capabilities.multi_tenant_enterprise && isNotNullish(tierConfigs.mte) && product === 'AuraDB' && (
              <EnterpriseTierInfo project={project} isSummary />
            )}
          </div>
        </Dialog.Content>
      )}
      {activeStep === CREATE_STEP.INSTANCE_CONFIGURATION && (
        <>
          <Dialog.Description>
            Cloning your instance enables you to safely test without affecting your production instance.
          </Dialog.Description>
          <Dialog.Content>
            {instanceHasCDCEnabled(instance) && <CDCCloneDBWarning />}
            {instance.tier === TIER.FREE && (
              <Banner
                description={`You are now creating a paid AuraDB ${data.tier === TIER.PROFESSIONAL ? 'Professional' : 'Business Critical'} instance.${!allowFreeDatabaseCreation ? 'The max number of free AuraDB instances in this project has been reached.' : ''}`}
                className="my-6"
                usage="inline"
              />
            )}
            <CreateInstanceFormWrapper
              tierConfig={createFormTierConfig}
              data={data}
              onDataChange={handleDataChange}
              validation={validation}
              project={project}
              instance={instance}
              product={product}
              onProductChange={handleProductSelect}
            />
            {isNotNullish(errorMessage) && <Banner type="danger" description={errorMessage} usage="inline" />}
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              fill="outlined"
              color="neutral"
              onClick={getStepNumber(activeStep, expectedSteps) === 1 ? onClose : goBackToPreviousStep}
              isDisabled={createInstanceRes.isLoading}
            >
              {getStepNumber(activeStep, expectedSteps) === 1 ? 'Cancel' : 'Back'}
            </Button>
            <Button
              onClick={
                getStepNumber(activeStep, expectedSteps) === expectedSteps.length
                  ? () => handleCreate(data)
                  : proceedToNextStep
              }
              isDisabled={isSubmitDisabled}
              isLoading={createInstanceRes.isLoading}
            >
              {getStepNumber(activeStep, expectedSteps) === expectedSteps.length ? 'Create' : 'Next'}
            </Button>
          </Dialog.Actions>
        </>
      )}
      {activeStep === CREATE_STEP.BILLING_SETUP && (
        <>
          <Dialog.Content>
            <div className="mx-auto max-w-[50%]">
              <Suspense fallback={<StripeFormFallback />}>
                <StripeForm
                  onCancel={() => setActiveStep(CREATE_STEP.INSTANCE_CONFIGURATION)}
                  onSuccess={() => handleCreate(data)}
                  cancelButtonText="Back"
                  submitButtonText="Create"
                  isLoading={createInstanceRes.isLoading}
                />
              </Suspense>
            </div>
          </Dialog.Content>
        </>
      )}
    </Dialog>
  );
};
