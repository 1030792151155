import type { ConnectionDescriptor } from '@nx/constants';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { CreatedInstance, INSTANCE_PRIORITY, Instance } from './console/types/instance';
import type { Project } from './console/types/project';
import type { DbList } from './desktop/relate.types';

export enum MODAL_TYPE {
  ACCOUNT = 'account',
  DATA_SOURCE = 'data_source',
  CONNECTION_FORM = 'connection-form',
  GUIDE_SELECTION = 'guide-selection',
  INSTANCE_NAME = 'instance-name',
  INSTANCE_CREATE = 'instance-create',
  INSTANCE_CREATED = 'instance-created',
  PASSWORD_CHANGE = 'password-change',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  AURA_TERMS = 'aura-terms',
  INVITES = 'invites',
  RESUME = 'resume',
  PAUSE = 'pause',
  DESTROY = 'destroy',
  CLONE_TO_NEW = 'clone-to-new',
  UPGRADE = 'upgrade',
  CONFIGURE = 'configure',
  CONNECT = 'connect',
  SECONDARIES = 'edit-secondaries',
  CLEAR = 'clear',
  CLONE_TO_EXISTING = 'clone-to-existing',
  CDC = 'cdc',
  UPDATE_INSTANCE_PRIORITY = 'update-instance-priority',
  EXTEND_TRIAL = 'extend-trial',
  MANAGE_INSTANCE_ENDPOINT = 'manage-instance-custom-endpoint',
  DBMS_UPGRADE = 'dbms-upgrade',
  LOCAL_INSTANCE_DB_CREATE = 'local-instance-db-create',
  LOCAL_INSTANCE_DB_CREATE_DUMP = 'local-instance-db-create-dump',
  ADD_PAYMENT_DETAILS = 'add-payment-details',
}

export type ConnectionDetails = {
  instanceName?: string;
  dbName?: string;
  credentials: ConnectionDescriptor;
  password?: string;
  url: string;
};

export type Modal =
  | { type: MODAL_TYPE.ACCOUNT }
  | {
      type: MODAL_TYPE.DATA_SOURCE;
      connectionDetails?: ConnectionDetails;
      modalTabId?: string;
      showDataModificationWarning?: boolean;
      allowSkippingDataSource?: boolean;
    }
  | { type: MODAL_TYPE.CONNECTION_FORM; instance: Instance; warning?: string; error?: string }
  | { type: MODAL_TYPE.GUIDE_SELECTION }
  | { type: MODAL_TYPE.INSTANCE_NAME }
  | { type: MODAL_TYPE.INSTANCE_CREATE }
  | { type: MODAL_TYPE.INSTANCE_CREATED; createdInstance: CreatedInstance }
  | { type: MODAL_TYPE.PASSWORD_CHANGE; passwordChangeRequired: boolean }
  | { type: MODAL_TYPE.TERMS_AND_CONDITIONS }
  | { type: MODAL_TYPE.AURA_TERMS }
  | { type: MODAL_TYPE.INVITES }
  | { type: MODAL_TYPE.RESUME; instance: Instance }
  | { type: MODAL_TYPE.PAUSE; instance: Instance }
  | { type: MODAL_TYPE.DESTROY; instance: Instance }
  | { type: MODAL_TYPE.CLONE_TO_NEW; project: Project; instance: Instance }
  | { type: MODAL_TYPE.UPGRADE; project: Project; instance: Instance }
  | { type: MODAL_TYPE.CONFIGURE; project: Project; instance: Instance }
  | { type: MODAL_TYPE.CONNECT; instance: Instance }
  | { type: MODAL_TYPE.SECONDARIES; instance: Instance }
  | { type: MODAL_TYPE.CLEAR; instance: Instance }
  | { type: MODAL_TYPE.CLONE_TO_EXISTING; instance: Instance }
  | { type: MODAL_TYPE.CDC; instance: Instance }
  | {
      type: MODAL_TYPE.UPDATE_INSTANCE_PRIORITY;
      instance: Instance;
      priority: INSTANCE_PRIORITY.HIGH | INSTANCE_PRIORITY.HIGHEST;
    }
  | { type: MODAL_TYPE.EXTEND_TRIAL; project: Project }
  | {
      type: MODAL_TYPE.MANAGE_INSTANCE_ENDPOINT;
      project: Project;
      instance: Instance;
    }
  | {
      type: MODAL_TYPE.DBMS_UPGRADE;
      dbmsId: string;
      dbmsName: string;
      targetVersion: string;
    }
  | { type: MODAL_TYPE.LOCAL_INSTANCE_DB_CREATE; dbmsId: string; rootPath: string | undefined; databases: DbList }
  | { type: MODAL_TYPE.LOCAL_INSTANCE_DB_CREATE_DUMP; dbmsId: string; rootPath: string | undefined }
  | {
      type: MODAL_TYPE.ADD_PAYMENT_DETAILS;
      hasBilling: boolean;
    };

type OtherKeys<T> = Exclude<keyof T, 'type'>;

type GetArgs<T> = OtherKeys<T> extends never ? [] : [{ [K in OtherKeys<T>]: T[K] }];

// transforms the type from: { type: MODAL_TYPE.CDC; project: Project; instance: Instance }
// to:                      { type: MODAL_TYPE.CDC; args: [{ project: Project; instance: Instance }] }
export type ModalArg = {
  [K in Modal['type']]: { type: K; args: GetArgs<Extract<Modal, { type: K }>> };
}[Modal['type']];

export type ArgumentsOfModalType<T> = Extract<ModalArg, { type: T }>['args'];
export type DataSourceModalArgs = Partial<Omit<Extract<Modal, { type: MODAL_TYPE.DATA_SOURCE }>, 'type'>>;

export interface ModalState {
  openModal: null | Modal;
}

const initialState: ModalState = { openModal: null };

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal<T extends MODAL_TYPE>(
      state: ModalState,
      action: PayloadAction<{ type: T; args: ArgumentsOfModalType<T> }>,
    ) {
      const { type, args } = action.payload;
      // typescript isn't smart enough to see that the enum is narrowed to only have matching arguments
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      state.openModal = { type, ...args[0] } as Modal;
    },
    closeModal(state: ModalState) {
      state.openModal = null;
    },
  },
});

export const { openModal, closeModal } = modalsSlice.actions;
export default modalsSlice.reducer;
