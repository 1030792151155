import { LoadingSpinner, Typography } from '@neo4j-ndl/react';
import classNames from 'classnames';
import React, { forwardRef } from 'react';

export type CardProps = React.HTMLAttributes<HTMLDivElement> & {
  title?: string;
  icon?: React.ReactElement;
  subtitle?: string | React.ReactElement;
  controls: React.ReactNode[];
  loading?: boolean | null;
};

export const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ title, icon, subtitle, controls, children, loading = null, className, ...props }, ref) => (
    <div
      {...props}
      className={classNames(
        'bg-palette-neutral-bg-weak flex min-w-max flex-col justify-center rounded-3xl p-6',
        className,
      )}
      id={title}
      ref={ref}
    >
      <div className="mb-3 flex flex-row gap-2">
        <div className="flex flex-col items-start justify-start text-left align-middle">
          <div className="flex flex-row items-center gap-2">
            <Typography variant="h6">{title}</Typography>
            {icon}
            {(loading ?? false) && <LoadingSpinner />}
          </div>
          <Typography variant="body-medium" className="text-palette-neutral-text-weak">
            {subtitle}
          </Typography>
        </div>

        <div className="ml-auto flex flex-row items-center gap-2">{controls}</div>
      </div>

      {children}
    </div>
  ),
);

Card.displayName = 'Card';

export const DashboardCard = forwardRef<HTMLDivElement, CardProps>(
  ({ title, children, loading = null, className, ...props }, ref) => (
    <div {...props} className={classNames('flex flex-col', className)} id={title} ref={ref}>
      <div className="mb-5 flex flex-row items-center gap-2 pl-2">
        <Typography variant="body-small" className="text-palette-neutral-text-weaker">
          {title}
        </Typography>
        {Boolean(loading) && <LoadingSpinner />}
      </div>

      {children}
    </div>
  ),
);

DashboardCard.displayName = 'Card';
