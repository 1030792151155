import { Banner, Button, Dialog, Typography } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import type { SsoConfig } from '@nx/state';
import { consoleApi, getApiError, getErrorMessage } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';

const logger = createLogger(APP_SCOPE.framework);

export interface DeleteSsoConfigModalProps {
  open: boolean;
  onClose: () => void;
  saveAndClose: () => void;
  config: SsoConfig;
}

const DeleteSsoConfigModal = ({ onClose, saveAndClose, config }: DeleteSsoConfigModalProps) => {
  const [deleteSsoConfig, deleteSsoConfigRes] = consoleApi.useDeleteSsoConfigMutation();

  const onClickDelete = () => {
    deleteSsoConfig({ organizationId: config.organizationId, ssoConfigId: config.id })
      .unwrap()
      .then(() => {
        saveAndClose();
      })
      .catch((err: FetchBaseQueryError | SerializedError | undefined) => {
        const apiError = getApiError(err);
        const errorMessage = getErrorMessage(apiError);
        logger.error(errorMessage);
      });
  };

  return (
    <Dialog isOpen onClose={onClose} modalProps={{ 'data-testid': 'delete-sso-config-modal' }}>
      <Dialog.Header>
        Delete SSO Configuration <i>{config.displayName}</i>
      </Dialog.Header>
      <Dialog.Description>
        <div className="flex flex-col gap-2">
          <Typography variant="body-large" as="div">
            <p>
              Are you sure you want to delete the SSO Configuration <b>{config.displayName}</b> with ID &apos;
              {config.id}
              &apos;?
            </p>
          </Typography>
        </div>
      </Dialog.Description>
      <Dialog.Content>
        <Banner
          hasIcon
          type="info"
          description="This will not remove SSO from instances which were created while SSO was configured."
        />
        {isNotNullish(deleteSsoConfigRes.error) && (
          <Banner
            className="mt-2"
            type="danger"
            data-testid="delete-sso-config-error-message"
            description={getErrorMessage(getApiError(deleteSsoConfigRes.error))}
            hasIcon
          />
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          onClick={onClose}
          color="neutral"
          fill="outlined"
          isDisabled={deleteSsoConfigRes.isLoading}
          htmlAttributes={{ 'data-testid': 'cancel-delete-sso-config-button' }}
        >
          Cancel
        </Button>
        <Button
          onClick={onClickDelete}
          isLoading={deleteSsoConfigRes.isLoading}
          color="danger"
          htmlAttributes={{ 'data-testid': 'cancel-delete-sso-config-button' }}
        >
          Delete
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export default DeleteSsoConfigModal;
