import { tokens } from '@neo4j-ndl/base';
import { Drawer, InlineEdit, Tabs, useMediaQuery } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import type { Instance } from '@nx/state';
import {
  ACTION,
  INSTANCE_STATUS,
  consoleApi,
  getApiError,
  getErrorMessage,
  useActiveProject,
  useNotificationActions,
  usePermissions,
} from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useMemo, useState } from 'react';
import * as yup from 'yup';

import type { Validation } from '../utils/validation';
import { validateYup } from '../utils/validation';
import { InstanceDatabasesList } from './databases/instance-databases-table';
import { InstanceDetailsOverview } from './instance-details-overview';
import { LogsSection } from './logs';
import { InstanceStatus } from './shared/instance-status';
import { SnapshotsSection } from './snapshots';
import { RestoreSection } from './upload';

const logger = createLogger(APP_SCOPE.framework);

enum TABS {
  OVERVIEW = 'overview',
  DATABASES = 'databases',
  SNAPSHOTS = 'snapshots',
  RESTORE = 'restore',
  LOG = 'log',
}

const isTabEnabled = (instance: Instance) =>
  [
    INSTANCE_STATUS.CREATING,
    INSTANCE_STATUS.RUNNING,
    INSTANCE_STATUS.RESTORING,
    INSTANCE_STATUS.UPDATING,
    INSTANCE_STATUS.LOADING_FAILED,
  ].includes(instance.instanceStatus);

const isSnapshotsTabEnabled = (instance: Instance) =>
  ![INSTANCE_STATUS.DESTROYING, INSTANCE_STATUS.DESTROYED, INSTANCE_STATUS.PAUSED, INSTANCE_STATUS.PAUSING].includes(
    instance.instanceStatus,
  );

interface InstanceDetailsProps {
  instanceId: string;
  onClose: () => void;
}

export const updateInstanceNameSchema = yup.object({
  name: yup
    .string()
    .max(30, 'Instance name can be no longer than 30 characters')
    .required('Instance name cannot be empty'),
});

export const validate = (data: { name: string }, { onlyRequired = false }: { onlyRequired?: boolean } = {}) =>
  validateYup(updateInstanceNameSchema, data, onlyRequired);

export const InstanceDetails = ({ instanceId, onClose }: InstanceDetailsProps) => {
  const { data: instance, isSuccess } = consoleApi.useGetInstanceQuery(instanceId);
  const { breakpoints } = tokens;
  const { addNotification } = useNotificationActions();

  const isMobile = useMediaQuery(`(max-width: ${breakpoints.sm})`);

  const activeProject = useActiveProject();

  const [updateInstanceName] = consoleApi.useUpdateInstanceNameMutation();
  const { isAllowed } = usePermissions();

  const [selectedTab, setSelectedTab] = useState(TABS.OVERVIEW);
  const [validationError, setValidationError] = useState<Validation<{
    name: string;
  }> | null>(null);
  const defaultWidth = useMemo(() => {
    if (isMobile) {
      return window.innerWidth;
    }
    return 720;
  }, [isMobile]);

  if (!isSuccess) {
    return null;
  }

  const allowEditInstanceName = isAllowed(ACTION.UPDATE, `namespaces/${activeProject.id}/databases/${instance.id}`);

  const isDatabasesTabEnabled = instance.capabilities.multi_database.enabled;

  const handleOnSave = (value: string) => {
    const validation = validate({ name: value });
    if (isNotNullish(validation)) {
      setValidationError(validation);
      return;
    }
    setValidationError(null);
    updateInstanceName({ dbId: instance.id, name: value.trim() })
      .unwrap()
      .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(e);
        if (isNotNullish(error.message)) {
          logger.error(error.message);
        }
        addNotification({
          type: 'danger',
          title: '',
          description: getErrorMessage(error),
          timeout: 5000,
        });
      });
  };

  return (
    <Drawer
      isExpanded
      onExpandedChange={onClose}
      position="right"
      className="!border-l"
      isResizeable
      resizeableProps={{
        defaultSize: { height: '100%', width: defaultWidth },
        minWidth: defaultWidth,
      }}
    >
      <Drawer.Header className="flex items-center gap-2">
        {allowEditInstanceName ? (
          <InlineEdit
            defaultValue={instance.name}
            onConfirm={handleOnSave}
            errorText={validationError?.name?.message}
            validate={(name) => validate({ name })?.name?.message ?? true}
            variant="h5"
            hasEditIcon
          />
        ) : (
          <h5 className="ml-1">{instance.name}</h5>
        )}
        <InstanceStatus instance={instance} project={activeProject} />
      </Drawer.Header>
      <Drawer.Body className="flex flex-col">
        <div className="flex flex-grow flex-col">
          <Tabs value={selectedTab} onChange={setSelectedTab} size="small">
            <Tabs.Tab tabId={TABS.OVERVIEW}>Overview</Tabs.Tab>
            {isDatabasesTabEnabled && <Tabs.Tab tabId={TABS.DATABASES}>Databases</Tabs.Tab>}
            <Tabs.Tab tabId={TABS.SNAPSHOTS} isDisabled={!isSnapshotsTabEnabled(instance)}>
              Snapshots
            </Tabs.Tab>
            <Tabs.Tab tabId={TABS.RESTORE} isDisabled={!isTabEnabled(instance)}>
              Restore from backup file
            </Tabs.Tab>
            <Tabs.Tab tabId={TABS.LOG} isDisabled={!isTabEnabled(instance)}>
              Logs
            </Tabs.Tab>
          </Tabs>
          <div className="mt-4 flex-grow">
            <Tabs.TabPanel tabId={TABS.OVERVIEW} value={selectedTab}>
              <InstanceDetailsOverview instance={instance} />
            </Tabs.TabPanel>
            {isDatabasesTabEnabled && (
              <Tabs.TabPanel tabId={TABS.DATABASES} value={selectedTab}>
                <InstanceDatabasesList instance={instance} />
              </Tabs.TabPanel>
            )}
            <Tabs.TabPanel tabId={TABS.SNAPSHOTS} value={selectedTab}>
              <SnapshotsSection project={activeProject} instance={instance} />
            </Tabs.TabPanel>
            <Tabs.TabPanel tabId={TABS.RESTORE} value={selectedTab} className="h-full">
              <RestoreSection project={activeProject} instance={instance} />
            </Tabs.TabPanel>
            <Tabs.TabPanel tabId={TABS.LOG} value={selectedTab} className="h-full">
              <LogsSection instance={instance} />
            </Tabs.TabPanel>
          </div>
        </div>
      </Drawer.Body>
    </Drawer>
  );
};
