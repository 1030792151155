import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash-es';

import { buildSearchPhraseSuggestion } from '../../../../modules/SearchBar/SearchBar.utils';
import type { SearchPhraseSuggestion } from '../../../../modules/SearchBar/types';
import type TemplatedSearch from '../../../search/templated/templatedSearch';
import type { RootState } from '../../../types';
import { isSearchPhraseLockedSelector, selectLockedSuggestions } from '../../core/search-core.selectors';
import { NAME as requests } from '../../requests/search-requests.const';
import { NAME as suggestions } from '../search-suggestions.const';
import { filterDuplication, sortAlphabetically } from './search-phrase-suggestions.utils';

interface FetchSearchPhraseSuggestionsPayload {
  inputText: string;
  templatedSearch: TemplatedSearch | undefined;
}

export const fetchSearchPhraseSuggestionsThunk = createAsyncThunk<
  SearchPhraseSuggestion[],
  FetchSearchPhraseSuggestionsPayload,
  { state: RootState }
>(
  `${suggestions}/fetchSearchPhraseSuggestions`,
  async (
    { inputText, templatedSearch }: FetchSearchPhraseSuggestionsPayload,
    { getState, rejectWithValue, dispatch },
  ) => {
    const state = getState();
    const lockedSuggestions = selectLockedSuggestions(state);
    const isSearchPhraseLocked = isSearchPhraseLockedSelector(state);
    const hasLockedSuggestion = !isEmpty(lockedSuggestions) && !isSearchPhraseLocked;
    if (hasLockedSuggestion) {
      return [];
    }

    const suggestionPromises: any = templatedSearch?.getSuggestions(inputText, true);
    if (isEmpty(suggestionPromises)) {
      return [];
    }
    let requestIds: string[] = [];
    try {
      const promises: Promise<SearchPhraseSuggestion[]>[] = suggestionPromises.flatMap(
        ({ suggestions }: { suggestions: any[] }) => suggestions,
      );
      requestIds = suggestionPromises.flatMap(({ requestIds }: { requestIds: string[] }) => requestIds);
      dispatch({ type: `${requests}/createRequests`, payload: requestIds });

      const results = await Promise.all(promises);
      const tempSuggestions: SearchPhraseSuggestion[] = [];
      const generatedSuggestions = results
        .flat()
        .filter((suggestion) => filterDuplication(suggestion, tempSuggestions))
        .sort(sortAlphabetically)
        .map(buildSearchPhraseSuggestion);
      dispatch({ type: `${requests}/clearRequests`, payload: requestIds });

      return generatedSuggestions;
    } catch (error) {
      dispatch({ type: `${requests}/clearRequests`, payload: requestIds });
      return rejectWithValue(error);
    }
  },
);
