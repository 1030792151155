import { Tooltip } from '@neo4j-ndl/react';
import { ExclamationTriangleIconOutline } from '@neo4j-ndl/react/icons';

import { validateForm } from '../connection-form-utils';
import type { ConnectionFormValues } from '../connection-form.types';

export const ConnectionTip = ({ formValues }: { formValues: ConnectionFormValues }) => {
  const { isUnsecure, isFormUrlLocalhost } = validateForm(formValues);

  if (!isUnsecure) {
    return undefined;
  }

  return (
    <Tooltip type="simple" isPortaled={false}>
      <Tooltip.Trigger>
        <div className="absolute bottom-3 right-full mr-1">
          <ExclamationTriangleIconOutline className="text-lemon-60 h-6 w-6" />
        </div>
      </Tooltip.Trigger>
      <Tooltip.Content>
        {isFormUrlLocalhost
          ? `This protocol will not establish an encrypted connection`
          : 'Unencrypted connections are only supported for local instances'}
      </Tooltip.Content>
    </Tooltip>
  );
};
