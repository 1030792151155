import { createAsyncThunk } from '@reduxjs/toolkit';

import { turnOffSuggestions } from '../../../modules/SearchBar/SearchBoxInput/SearchBoxInput.utils';
import type { TransformedSearchCategory } from '../../../types/perspective';
import { getVisibleRelationshipTypes } from '../../perspectives/perspectives';
import type { RootState } from '../../types';
import { selectActionSuggestions } from '../suggestions/action-suggestions/action-suggestions.selectors';
import { selectFullTextSuggestions } from '../suggestions/full-text-suggestion/full-text-suggestion.selector';
import { selectGraphPatternSuggestions } from '../suggestions/graph-pattern-suggestions/graph-pattern-suggestions.selectors';
import { SUGGESTIONS_TYPE, fetchSuggestionsThunk } from '../suggestions/search-suggestions.thunk';
import { sortByExactMatchFirst } from '../suggestions/search-suggestions.utils';
import type {
  PathEntryVerificationResult,
  SinglePathVerificationResult,
} from '../suggestions/structured-suggestions/structured-suggestions.types';
import { NAME } from './search-core.const';
import { runSearchThunk } from './search-core.thunks';

type RunDeepLinkPayload = {
  deepLinkedSearchInput: string;
  isCaseInsensitive: boolean;
  queryResultLimit: number;
  timeout: number;
  autoSelectNewNodes: boolean;
  categories: TransformedSearchCategory[];
  pathEntryVerificationResultsCache: Map<string, PathEntryVerificationResult>;
  pathVerificationResultsCache: Map<string, SinglePathVerificationResult[]>;
  isHttpQueryApiEnabled: boolean;
  database?: string;
};

export const runDeepLinkThunk = createAsyncThunk<void, RunDeepLinkPayload, { state: RootState }>(
  `${NAME}/runDeepLink`,
  async (payload: RunDeepLinkPayload, { dispatch, getState }) => {
    const {
      deepLinkedSearchInput,
      isCaseInsensitive,
      queryResultLimit,
      timeout,
      categories,
      database,
      autoSelectNewNodes,
      pathEntryVerificationResultsCache,
      pathVerificationResultsCache,
      isHttpQueryApiEnabled,
    } = payload;
    dispatch({ type: `${NAME}/updateLockedSuggestions`, payload: [] });
    const state = getState();

    let fetchSuggestions = dispatch(
      fetchSuggestionsThunk({
        inputText: deepLinkedSearchInput,
        isCaseInsensitive,
        database,
        pathEntryVerificationResultsCache,
        pathVerificationResultsCache,
        types: [SUGGESTIONS_TYPE.SEARCH_PHRASE],
      }),
    );

    let fetchedSuggestions = await fetchSuggestions.unwrap();
    if (fetchedSuggestions.length === 0) {
      fetchSuggestions = dispatch(
        fetchSuggestionsThunk({
          inputText: deepLinkedSearchInput,
          isCaseInsensitive,
          database,
          pathEntryVerificationResultsCache,
          pathVerificationResultsCache,
          types: [SUGGESTIONS_TYPE.PROPERTY_VALUE, SUGGESTIONS_TYPE.STRUCTURED],
        }),
      );
      fetchedSuggestions = await fetchSuggestions.unwrap();
    }

    const actionSuggestions = selectActionSuggestions(deepLinkedSearchInput)(state);
    const { filteredGraphPatternSuggestions } = selectGraphPatternSuggestions(deepLinkedSearchInput)(state);
    const fullTextSuggestions = selectFullTextSuggestions(deepLinkedSearchInput)(state);
    const sortedSuggestions = sortByExactMatchFirst(
      [...actionSuggestions, ...filteredGraphPatternSuggestions, ...fetchedSuggestions, ...fullTextSuggestions],
      deepLinkedSearchInput,
      isCaseInsensitive,
    );

    const autoSelectedSuggestion = sortedSuggestions.at(0);
    if (autoSelectedSuggestion === undefined) return;
    const autoSelectedArray = Array.isArray(autoSelectedSuggestion) ? autoSelectedSuggestion : [autoSelectedSuggestion];

    dispatch({ type: `${NAME}/updateLockedSuggestions`, payload: turnOffSuggestions(autoSelectedArray) });
    const visibleRelationships = getVisibleRelationshipTypes(state);

    await dispatch(
      runSearchThunk({
        queryResultLimit,
        timeout: timeout * 1000,
        isCaseInsensitive,
        categories,
        visibleRelationships,
        database,
        autoSelectNewNodes,
        isHttpQueryApiEnabled,
      }),
    );
  },
);
