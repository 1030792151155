import { Button, ClipboardButton, LoadingSpinner, Typography } from '@neo4j-ndl/react';
import { CircleStackIconOutline, UserGroupIconOutline } from '@neo4j-ndl/react/icons';
import {
  ACTION,
  type ProjectSummary,
  consoleApi,
  LEGACY_setActiveProject as setActiveProject,
  LEGACY_setUserHomePath as setUserHomePath,
  usePermissions,
} from '@nx/state';
import cx from 'classnames';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { RandomBlobs } from './blobs';
import { ProjectRename } from './project-rename-inline';

type CountIndicatorProps = {
  type: 'instances' | 'members';
  count: number;
  isLoading: boolean;
};

function CountIndicator({ type, count, isLoading }: CountIndicatorProps) {
  const Icon = type === 'instances' ? CircleStackIconOutline : UserGroupIconOutline;
  return (
    <div className="flex items-center gap-2">
      <Icon className="text-neutral-icon h-6 w-6" />
      {isLoading ? (
        <LoadingSpinner size="small" />
      ) : (
        <Typography variant="body-medium">
          <span className="font-bold">{count}</span> <span className="text-neutral-text-weaker capitalize">{type}</span>
        </Typography>
      )}
    </div>
  );
}

type ProjectCardProps = {
  projectSummary: ProjectSummary;
  className?: string;
};

export const ProjectCard = React.forwardRef<HTMLDivElement, ProjectCardProps>(({ projectSummary, className }, ref) => {
  const { isAllowed } = usePermissions();
  const { data: project, isLoading, isSuccess } = consoleApi.useGetProjectQuery(projectSummary.id);
  const canReadProjectInstances = isAllowed(ACTION.READ, `namespaces/${projectSummary.id}/databases`);
  const canReadProjectMembers =
    isAllowed(ACTION.READ, `namespaces/${projectSummary.id}/users`) &&
    isAllowed(ACTION.READ, `namespaces/${projectSummary.id}/roles`);
  const debug = useSearchParams()[0].has('debug');
  const navigate = useNavigate();
  const { data: user } = consoleApi.useGetUserDetailsQuery();

  // TODO fetch in parent, before rendering this card
  const listProjectInstancesRes = consoleApi.useListInstancesQuery(projectSummary.id, {
    skip: !canReadProjectInstances,
  });
  const listProjectMembersRes = consoleApi.useListProjectMembersQuery(projectSummary.id, {
    skip: !canReadProjectMembers,
  });

  const instancesCount = listProjectInstancesRes.data?.length ?? 0;
  const membersCount = listProjectMembersRes.data?.length ?? 0;
  if (isLoading) {
    return (
      <div
        className={cx(
          'bg-neutral-bg-weak border-neutral-border-strong relative flex h-[240px] rounded-3xl border',
          className,
        )}
        aria-label={`Navigate to project with id ${projectSummary.id}`}
        ref={ref}
      >
        <LoadingSpinner className="m-auto" size="medium" />
        <div className={cx('absolute inset-0 p-6', { 'overflow-hidden': !debug })}>
          <RandomBlobs />
        </div>
      </div>
    );
  }

  if (!isSuccess) {
    return null;
  }

  return (
    <>
      <div
        className={cx('bg-neutral-bg-weak border-neutral-border-strong relative grid rounded-3xl border', className)}
        ref={ref}
      >
        <div className="z-[1] flex w-[calc(100%-100px)] flex-col truncate py-6 pl-6">
          <ProjectRename project={project} projectSummary={projectSummary} />
          <div className="mb-3 mt-1 flex items-center gap-2">
            <Typography variant="body-medium" className="font-bold">
              ID:
            </Typography>
            <Typography variant="body-medium" className="text-neutral-text-weaker italic">
              {project.id}
            </Typography>
            <ClipboardButton textToCopy={project.id} htmlAttributes={{ 'aria-label': 'Copy ID to Clipboard' }} />
          </div>
          <Typography variant="body-medium" as="p" className="text-neutral-text-weak mb-5 line-clamp-4 h-[80px]">
            {/* Add description here when we have it */}
          </Typography>
          <div className="mt-auto flex gap-4">
            {canReadProjectInstances && (
              <CountIndicator type="instances" count={instancesCount} isLoading={listProjectInstancesRes.isLoading} />
            )}
            {canReadProjectMembers && (
              <CountIndicator type="members" count={membersCount} isLoading={listProjectMembersRes.isLoading} />
            )}
          </div>
        </div>
        <div className="absolute right-0 flex h-full w-1/2 items-start justify-end p-6">
          <div className="z-[2] flex items-center gap-2">
            <Button
              fill="outlined"
              color="neutral"
              onClick={() => {
                setActiveProject(project.id);
                setUserHomePath(`/projects/${project.id}/instances`);
                navigate(`/projects/${project.id}/instances`);
                localStorage.setItem(`default-project-${user?.id}`, project.id);
              }}
              htmlAttributes={{
                'aria-label': `Navigate to project with id ${project.id}`,
              }}
            >
              Open
            </Button>
          </div>

          <div className={cx('absolute inset-0', { 'overflow-hidden': !debug })}>
            <RandomBlobs />
          </div>
        </div>
      </div>
    </>
  );
});

ProjectCard.displayName = 'ProjectCard';
