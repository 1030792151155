import { tokens } from '@neo4j-ndl/base';
import { Banner, Button, Drawer, LoadingSpinner, Tooltip, useMediaQuery } from '@neo4j-ndl/react';
import type {
  InstanceSeamlessConnectionEnabled,
  OrganizationSeamlessProfile,
  ProjectDefaultSeamlessConnectionEnabled,
} from '@nx/state';
import { useMemo } from 'react';

import type { SeamlessConfigState } from '../settings';
import { ProjectSeamlessConnectionSettings } from './project-seamless-connection-settings';

interface Props {
  data: OrganizationSeamlessProfile;
  state: SeamlessConfigState;
  onClose: () => void;
  onSubmit: () => void;
  updateState: ({
    instances,
    projects,
  }: {
    instances?: InstanceSeamlessConnectionEnabled[];
    projects?: ProjectDefaultSeamlessConnectionEnabled[];
  }) => void;
  fetchIsLoading: boolean;
  submitIsLoading: boolean;
  canSubmit: boolean;
}

export const SeamlessConnectionSettingsDrawer = ({
  data,
  state,
  onClose,
  onSubmit,
  updateState,
  fetchIsLoading,
  submitIsLoading,
  canSubmit,
}: Props) => {
  const { breakpoints } = tokens;
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.sm})`);

  const defaultWidth = useMemo(() => {
    if (isMobile) {
      return window.innerWidth;
    }
    return 720;
  }, [isMobile]);

  return (
    <Drawer
      isExpanded
      onExpandedChange={onClose}
      position="right"
      className="h-full !border-l"
      isResizeable
      resizeableProps={{
        defaultSize: { height: '100%', width: defaultWidth },
        minWidth: defaultWidth,
      }}
      isCloseable
    >
      <div className="flex h-full flex-col gap-4">
        <div className="mx-6 mb-1 flex flex-col gap-4">
          <h3>Tool authentication with Aura user</h3>
          <p className="text-neutral-text-weaker">
            Instances without this setting will require{' '}
            <Tooltip type="simple">
              <Tooltip.Trigger className="underline decoration-dotted">basic authentication</Tooltip.Trigger>
              <Tooltip.Content>Users are required to supply a database username and password</Tooltip.Content>
            </Tooltip>{' '}
            to connect to tools.
          </p>
          <Banner
            hasIcon
            type="warning"
            description="SSO Users: Turning this setting on for instances will override settings implemented with your Identity Provider (IdP)."
          />
        </div>
        <div className="grow overflow-auto">
          {fetchIsLoading ? (
            <LoadingSpinner size="large" className="mx-auto" />
          ) : (
            <div className="flex flex-col gap-6 px-6">
              {data.projects.map((project) => (
                <ProjectSeamlessConnectionSettings
                  key={project.id}
                  projectSeamlessProfile={project}
                  state={state}
                  updateState={updateState}
                />
              ))}
            </div>
          )}
        </div>

        <div className="mb-6 mr-6 mt-2 flex flex-row justify-end gap-4">
          <Button
            onClick={onClose}
            isDisabled={submitIsLoading || fetchIsLoading}
            color="neutral"
            fill="outlined"
            htmlAttributes={{
              'data-testid': 'close-seamless-connection-settings-button',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            isDisabled={fetchIsLoading || !canSubmit}
            isLoading={submitIsLoading}
            htmlAttributes={{
              'data-testid': 'submit-seamless-connection-settings-button',
            }}
          >
            Apply changes
          </Button>
        </div>
      </div>
    </Drawer>
  );
};
