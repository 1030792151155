import { difference, keys, omitBy } from 'lodash-es';

import { log } from '../../services/logging';
import {
  METADATA_SCAN_FULL,
  detectUniformPropertyKeys,
  getIndexes,
  getIndexesWithLegacyProcs,
  getMetadata,
  getPropertyKeysForLabels,
  getPropertyKeysForRelationshipTypes,
  getRareLabels,
  getSchema,
  retrieveDiverseProperties,
} from '../../services/metagraph';
import { isVersion5OorGreater } from '../../services/versions/versionUtils';

const constructTypePropertyKeys = (typePropertyKeyList) => {
  const typePropKeys = {};
  typePropertyKeyList.reduce((propKeyMap, propKey) => {
    if (!propKeyMap[propKey.type]) {
      propKeyMap[propKey.type] = [];
    }
    propKeyMap[propKey.type].push(propKey);
    return propKeyMap;
  }, typePropKeys);
  return typePropKeys;
};

const addLabelPropsToIndexes = (propertyKeysMap, labels) =>
  Object.keys(propertyKeysMap).reduce((indexesArray, label) => {
    if (labels.includes(label)) {
      indexesArray.push({
        label,
        type: 'native',
        isMetadataPropIndex: true,
        propertyKeys: propertyKeysMap[label].map((property) => property.propertyKey),
      });
    }
    return indexesArray;
  }, []);

export async function getMetadataForApp(
  serverVersion,
  database,
  retrieveStatsEnabled = false,
  scanMode = METADATA_SCAN_FULL,
  parentRequestId,
) {
  const isV5OrGreater = isVersion5OorGreater(serverVersion);
  const [metadataResult, indexesResult] = await Promise.all([
    fetchLabelsRelationshipsAndPropertyKeyMaps(serverVersion, database, scanMode, parentRequestId, isV5OrGreater),
    fetchIndexes(database, retrieveStatsEnabled, parentRequestId),
  ]);
  return { ...metadataResult, ...indexesResult };
}

const getIndexesFromDiverseProperties = async (indexes, database, parentRequestId) => {
  const responseHandler = (diverseIndexLabels) => {
    const diverseIndexes = indexes.reduce((indexedLabels, index) => {
      if (index.type === 'full-text') {
        indexedLabels.push(index);
      } else if (diverseIndexLabels[index.label]) {
        const diverseIndex = { ...index, propertyKeys: diverseIndexLabels[index.label] };
        indexedLabels.push(diverseIndex);
      }
      return indexedLabels;
    }, []);
    return {
      indexes: diverseIndexes,
      uniformProperties: {},
    };
  };
  try {
    const diverseIndexLabels = await retrieveDiverseProperties({
      database,
      parentRequestId,
    });
    return responseHandler(diverseIndexLabels);
  } catch (err) {
    log.error(err);
  }
};

export async function fetchIndexes(database, retrieveStatsEnabled, parentRequestId) {
  const indexes = await getIndexes({
    database,
    parentRequestId,
  });
  if (retrieveStatsEnabled) {
    return getIndexesFromDiverseProperties(indexes, database, parentRequestId);
  }
  return detectUniformPropertyKeys({
    database,
    indexes,
    parentRequestId,
  }).then((uniformProperties) => ({
    indexes,
    uniformProperties,
  }));
}

export async function fetchIndexesWithLegacyProcs(database, retrieveStatsEnabled, parentRequestId) {
  const indexes = await getIndexesWithLegacyProcs({
    database,
    parentRequestId,
  });
  if (retrieveStatsEnabled) {
    return getIndexesFromDiverseProperties(indexes, database, parentRequestId);
  }
  return detectUniformPropertyKeys({
    database,
    indexes,
    parentRequestId,
  }).then((uniformProperties) => ({
    indexes,
    uniformProperties,
  }));
}

async function fetchLabelsRelationshipsAndPropertyKeyMaps(
  serverVersion,
  database,
  scanMode,
  parentRequestId,
  isV5OrGreater,
) {
  const schemaPromise = getSchema({
    database,
    serverVersion,
    parentRequestId,
  });

  const metadataPromise = getMetadata({
    database,
    parentRequestId,
  });

  const [pathSegments, metadata] = await Promise.all([schemaPromise, metadataPromise]);

  const propertyKeysPromise = getPropertyKeysForLabels({
    database,
    labels: metadata.labels,
    scanMode,
    parentRequestId,
  }).then(constructTypePropertyKeys);

  const propertyKeysForRelationshipTypesPromise = getPropertyKeysForRelationshipTypes({
    database,
    relationshipTypes: metadata.relationshipTypes,
    labels: metadata.labels,
    serverVersion,
    scanMode,
    parentRequestId,
  }).then(constructTypePropertyKeys);

  const rareLabelsPromise = getRareLabels({
    labels: metadata.labels,
    database,
    parentRequestId,
  }).then((labelStats) => labelStats.filter((labelStat) => labelStat.count < 1000).map((labelStat) => labelStat.label));

  const result = await Promise.all([propertyKeysPromise, propertyKeysForRelationshipTypesPromise, rareLabelsPromise]);

  const [propertyKeysForLabelsMapRaw, propertyKeysForRelationshipsTypesMap, rareLabels] = result;
  const propertyKeysForLabelsMap = omitBy(propertyKeysForLabelsMapRaw, (_, key) => !metadata.labels.includes(key));
  let metadataPropsIndexes;

  if (propertyKeysForLabelsMap) {
    metadataPropsIndexes = addLabelPropsToIndexes(propertyKeysForLabelsMap, rareLabels);
  } else {
    const rareLabelsProperties = await getPropertyKeysForLabels({
      labels: rareLabels,
      serverVersion,
      parentRequestId,
    });
    metadataPropsIndexes = addLabelPropsToIndexes(constructTypePropertyKeys(rareLabelsProperties), rareLabels);
  }

  difference(metadata.labels, keys(propertyKeysForLabelsMap)).forEach((label) => {
    propertyKeysForLabelsMap[label] = [];
  });
  difference(metadata.relationshipTypes, keys(propertyKeysForRelationshipsTypesMap)).forEach((relType) => {
    propertyKeysForRelationshipsTypesMap[relType] = [];
  });

  return {
    labelsMap: propertyKeysForLabelsMap,
    relPropsMap: propertyKeysForRelationshipsTypesMap,
    labels: metadata.labels,
    relTypes: metadata.relationshipTypes,
    pathSegments,
    metadataPropsIndexes,
  };
}
