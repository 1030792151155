import { useEffect } from 'react';

export default function WindowTitle(props: { title?: string }): null {
  const { title } = props;
  useEffect(() => {
    if (title === undefined || title.length === 0) {
      return;
    }
    window.document.title = title;
  }, [title]);
  return null;
}
