// eslint-disable-next-line import/no-cycle
import dataScienceApi from '../data-science.api';
import type { GetSessionsRequest } from '../data-science.api.types';
import type { Session } from '../data-science.types';
import { transformGetSessionsResponse } from '../transformers/session';
import { type Builder, LIST_ID } from './types';

export const sessionEndpoints = (builder: Builder) => ({
  getSessions: builder.query<Session[], GetSessionsRequest>({
    query: ({ instanceId, projectId, organizationId }) => ({
      url: `sessions`,
      params: { tenantId: projectId, instanceId, organizationId },
    }),
    transformResponse: transformGetSessionsResponse,
    providesTags: (result, error, projectId) => [{ type: 'Session' as const, id: LIST_ID, projectId }],
  }),
  deleteSession: builder.mutation<void, string>({
    query: (sessionId) => ({
      url: `sessions/${sessionId}`,
      method: 'DELETE',
    }),
    onQueryStarted: (sessionId, { getState, dispatch, queryFulfilled }) => {
      const state = getState();
      const cacheUpdates = dataScienceApi.util.selectCachedArgsForQuery(state, 'getSessions').map((projectId) =>
        dispatch(
          dataScienceApi.util.updateQueryData('getSessions', projectId, (draft) => {
            const index = draft.findIndex(({ id }) => id === sessionId);
            if (index !== -1) {
              draft.splice(index, 1);
            }
          }),
        ),
      );
      queryFulfilled.catch(() => {
        cacheUpdates.forEach((update) => update.undo());
      });
    },
    invalidatesTags: (result, error, sessionId) => [{ type: 'Session', id: sessionId }],
  }),
});
