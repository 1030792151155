import type { INVITE_STATUS, PROJECT_ROLE_NAME } from '@nx/state';

export enum ORG_ROLE_NAME {
  MEMBER = 'MEMBER',
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
}

export enum ROLE_TYPE {
  ORG = 'ORG',
  PROJECT = 'PROJECT',
  DB = 'DB',
}

export type RoleType = 'org' | 'project' | 'db';

export type OrgRole = {
  name: ORG_ROLE_NAME;
};

export type ProjectRole = {
  name: PROJECT_ROLE_NAME;
  projectId: string;
  projectName: string;
};

export type DbRoleName = 'admin' | 'reader' | 'custom_role1';

export type DbRole = {
  name: DbRoleName;
  dbId: string;
  dbName: string;
};

export type User = {
  id: string;
  email: string;
  lastActive: Date | undefined;
  metadata?: string;
  orgRole: OrgRole;
  projectRoles: ProjectRole[];
  dbRoles: DbRole[];
  inviteStatus: INVITE_STATUS | undefined;
};
