import type { INSTANCE_PRIORITY, InstanceSizes } from './instance';
import type { ActionStatus, CLOUD_PROVIDER, PLAN_TYPE, TIER } from './shared';

export enum PROJECT_TYPE {
  PERSONAL = 'personal',
  N4GCP = 'n4gcp',
  MARKETPLACE_AWS = 'marketplace_aws',
  MARKETPLACE_AZURE = 'marketplace_azure',
  ENTERPRISE = 'enterprise',
  MTE_GCP = 'mte_gcp',
  MTE_AWS = 'mte_aws',
  MTE_AZURE = 'mte_azure',
}

export enum PROJECT_PROFILE {
  EXTERNAL = 'external',
  INTERNAL = 'internal',
  CANARY = 'canary',
}

export enum PROJECT_BILLING_METHOD {
  PAYG = 'payg',
  PREPAID = 'prepaid',
  MARKETPLACE = 'marketplace',
}

export type Region = {
  name: string;
  /** User-friendly name */
  friendly: string;
  country: string;
};

export type TierConfigs = {
  [key in TIER]?: TierConfig;
};

export type CloudProviderRegionMap = {
  [key in CLOUD_PROVIDER]?: Region[];
};

export type TierConfig = {
  tier: TIER;
  tierDisplayName: string;
  cloudProviderRegions: CloudProviderRegionMap;
  sizes: InstanceSizes;
  versions: string[];
  defaultVersion: string;
  secondaryCost?: string;
  priorities: INSTANCE_PRIORITY[];
};

export enum PROJECT_NOTIFICATION_LEVEL {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}

export type ProjectNotificationAction = {
  label: string;
  actionId: string;
};

export type ProjectNotificationDate = {
  name: string;
  format: string;
  timestamp: number;
};

export type ProjectNotification = {
  level: PROJECT_NOTIFICATION_LEVEL;
  message: string;
  title: string;
  actions: ProjectNotificationAction[];
  dismissalPeriod: number;
  dates: ProjectNotificationDate[];
};

export type ProjectCapabilities = {
  cmek: boolean;
  log_forwarding: boolean;
  single_instance_log_forwarding: boolean;
  traffic_config: boolean;
  metrics_integration: boolean;
  secondaries: boolean;
  multi_tenant_enterprise: boolean;
  pro_trial: boolean;
  cdc_enrichment_mode: boolean;
  migration_assistant: boolean;
  query_log_analyzer: boolean;
  consumption_reporting: boolean;
  gds_sessions: boolean;
  gds_plugin: boolean;
  custom_endpoints: boolean;
  azure_512_gi: boolean;
  orb_consumption_reporting: boolean;
  multi_db_free: boolean;
  multi_db_enterprise: boolean;
};

export type ProjectAvailableActions = {
  create_pro_trial: ActionStatus;
  extend_pro_trial: ActionStatus;
};

export interface ProjectSummary {
  id: string;
  name: string;
  planType: PLAN_TYPE;
  projectType: PROJECT_TYPE;
  suspended: boolean;
  hasBilling: boolean;
  requiresBilling: boolean;
  organizationId: string;
  ssoOrganizationId?: string;

  /** googleProjectId is defined if projectType is N4GCP  */
  googleProjectId?: string;
  createdAt?: string;
}

export interface Project extends ProjectSummary {
  hasUnpaidInvoices?: boolean;
  allowFreeDatabaseCreation: boolean;
  capabilities: ProjectCapabilities;
  availableActions: ProjectAvailableActions;
  tierConfigs: TierConfigs;
  unavailableTiers: TierConfigs;
  availableRoles: PROJECT_ROLE_NAME[];
  billingMethod: PROJECT_BILLING_METHOD;
  notifications: ProjectNotification[];
  profile: PROJECT_PROFILE;
}

export enum PROJECT_ROLE_NAME {
  PROJECT_VIEWER = 'namespace-viewer',
  PROJECT_DATA_VIEWER = 'namespace-data-viewer',
  PROJECT_MEMBER = 'namespace-member',
  PROJECT_ADMIN = 'namespace-admin',
  PROJECT_METRICS_INTEGRATION_READER = 'namespace-metrics-integration-reader',
}

export type ProjectRole = {
  id: string;
  userId: string;
  name: PROJECT_ROLE_NAME;
  projectId: string;
};

export type ProjectMember = {
  projectId: string;
  userId?: string;
  email: string;
  roles: ProjectRole[];
};
