import { type OrganizationProfile, type PLAN_TYPE, useActiveOrg, useAuth } from '@nx/state';
import type { LDContextCommon, LDMultiKindContext } from 'launchdarkly-react-client-sdk';

const getEmailDomain = (email: string) => {
  const [, domain] = email.split('@');

  if (typeof domain === 'string' && domain.length > 0) {
    return domain;
  }

  return undefined;
};

interface LDOrganizationContext extends LDContextCommon {
  key: string;
  name: string;
  attributes?: {
    plan_type?: PLAN_TYPE;
    profile?: OrganizationProfile;
  };
}

interface LDUserContext extends LDContextCommon {
  key: string;
  name: string;
  attributes?: {
    email?: string;
  };
}

interface LDIdentityContext extends LDMultiKindContext {
  kind: 'multi';
  organization: LDOrganizationContext;
  user: LDUserContext;
}

export function useLaunchDarklyIdentityContext() {
  const { user } = useAuth();
  const org = useActiveOrg(false);

  const emailDomain = getEmailDomain(user?.profile.email ?? '');

  if (org !== undefined && emailDomain !== undefined) {
    const context: LDIdentityContext = {
      kind: 'multi',
      organization: {
        key: org.id,
        name: org.name,
        attributes: {
          plan_type: org.planType,
          profile: org.profile,
        },
      },
      user: {
        key: emailDomain,
        name: emailDomain,
        attributes: {
          email: user?.profile.email,
        },
      },
    };

    return context;
  }

  return undefined;
}
