import { APP_SCOPE, QUERY_TYPE } from '@nx/constants';
import type { GraphCounts, SchemaMetadata } from '@nx/neo4j-sdk';
import { initNeo4jSDK } from '@nx/neo4j-sdk/src/neo4j-sdk';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { NoDatabaseSelectedError } from '../../adapters/neo4j-driver-adapter';
import { createAsyncThunk } from '../../context';
import type { RootState } from '../../store';
import { selectEnabledTools } from '../capabilities-slice';
import { selectActiveDatabase } from '../connections/selectors';

/**
 * Schema related and statistical information of the selected database.
 */
export type DataSummaryState = {
  status: 'INITIALIZING' | 'LOADING' | 'LOADED' | 'FAILED_TO_LOAD';
  data?: Omit<GraphCounts, 'timeTaken'> & SchemaMetadata;
  lastSuccessfulLoad?: number;
  errorMessage?: string;
  graphCountsEnabled: boolean;
};

export const selectDataSummary = (state: RootState) => state.dataSummary;

const selectGraphCountsEnabled = (state: RootState) => state.dataSummary.graphCountsEnabled;

export const selectTypeCounts: (state: RootState) => {
  labels: string[];
  propertyKeys: string[];
  relationshipTypes: string[];
} = createSelector(
  (state: RootState) => state.dataSummary.data,
  (data) => {
    const { labels = [], propertyKeys = [], relationshipTypes = [] } = data ?? {};
    return { labels, propertyKeys, relationshipTypes };
  },
);

function sortSchemaMetadata(data: SchemaMetadata): SchemaMetadata {
  const sortAlphabetically = (a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase());

  return {
    ...data,
    labels: data.labels.slice().sort(sortAlphabetically),
    propertyKeys: data.propertyKeys.slice().sort(sortAlphabetically),
    relationshipTypes: data.relationshipTypes.slice().sort(sortAlphabetically),
  };
}

export const initialState: DataSummaryState = { status: 'INITIALIZING', graphCountsEnabled: true };

type UpdatePayload = { reset?: boolean; force?: boolean } | undefined;

export const updateDataSummary = createAsyncThunk(
  'dataSummary/update',
  async (payload: UpdatePayload, { extra, getState, dispatch }): Promise<SchemaMetadata & Partial<GraphCounts>> => {
    const activeDatabase = selectActiveDatabase(getState());

    if (!activeDatabase) {
      throw new NoDatabaseSelectedError();
    }

    if (activeDatabase.name === 'system') {
      return { labels: [], relationshipTypes: [], propertyKeys: [], nodeCount: 0, relCount: 0 };
    }

    const queryCypher = (query: string) =>
      extra.driver.runQuery(
        { query },
        {
          sessionConfig: { database: activeDatabase.name },
          metadata: { appScope: APP_SCOPE.framework, queryType: QUERY_TYPE.System },
        },
        selectEnabledTools(getState()),
      );

    const neo4jSdk = await initNeo4jSDK({ queryCypher });

    const graphCountsEnabled = selectGraphCountsEnabled(getState());

    if (graphCountsEnabled || payload?.force === true) {
      const [graphCounts, schemaMetadata] = await Promise.all([
        neo4jSdk.getGraphCounts(),
        neo4jSdk.getSchemaMetadata(),
      ]);

      return {
        ...graphCounts,
        ...schemaMetadata,
      };
    }

    return neo4jSdk.getSchemaMetadata();
  },
  {
    // only run if not already loading
    condition: (payload, { getState }) => getState().dataSummary.status !== 'LOADING',
  },
);

const dataSummarySlice = createSlice({
  name: 'dataSummary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // eslint-disable-next-line consistent-return
    builder.addCase(updateDataSummary.pending, (state, action) => {
      if (action.meta.arg?.reset === true) {
        return initialState;
      }

      state.status = 'LOADING';
    });

    builder.addCase(updateDataSummary.fulfilled, (state, action) => {
      state.status = 'LOADED';
      const { timeTaken, nodeCount, relCount } = action.payload;
      state.data = {
        ...sortSchemaMetadata(action.payload),
        // we know the numbers must have loaded once, otherwise the polling would not been disabled
        nodeCount: nodeCount ?? state.data!.nodeCount,
        relCount: relCount ?? state.data!.relCount,
      };
      state.lastSuccessfulLoad = Date.now();
      state.graphCountsEnabled = typeof timeTaken === 'number' && timeTaken < 500;
    });

    builder.addCase(updateDataSummary.rejected, (state, action) => {
      state.status = 'FAILED_TO_LOAD';
      state.errorMessage = action.error.message;
    });
  },
});
export default dataSummarySlice.reducer;
