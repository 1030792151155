import { isNil } from 'lodash-es';

import { PRIVILEGE_SHOW_CONSTRAINT, PRIVILEGE_SHOW_INDEX } from '../../constants';
import { isVersion5OorGreater } from '../../services/versions/versionUtils';
import { isFalsy } from '../../types/utility';
import type { UserPrivilege } from './types';

const StandardProcs = [
  'db.indexes',
  'dbms.components',
  // Not required
  //  'dbms.listConfig',
  'dbms.showCurrentUser',
  'dbms.procedures',
  'db.info',
  'db.schema.visualization',
  'db.labels',
  'db.relationshipTypes',
  // Not required
  //  'db.stats.retrieve',
  'db.schema.nodeTypeProperties',
  'db.schema.relTypeProperties',
];

const UpdatedDatabaseActions = [PRIVILEGE_SHOW_INDEX, PRIVILEGE_SHOW_CONSTRAINT];

const PluginProcs = [
  'bloom.checkAuthorization',
  'bloom.checkLicenseCompliance',
  'bloom.getPluginVersion',
  'bloom.storePerspective',
  'bloom.fetchPerspectives',
  'bloom.fetchPerspectiveRoles',
  'bloom.assignPerspective',
  'bloom.removeRole',
];

export const getNoProcedurePermissionMessage = (
  missingProcedure?: string,
  user?: string | null,
  roles?: string | string[] | null,
) => {
  if (isNil(missingProcedure)) {
    return;
  }
  const explanation = UpdatedDatabaseActions.includes(missingProcedure)
    ? 'Bloom requires visibility of database indexes and constraints to function. '
    : '';

  let command: string;
  switch (missingProcedure) {
    case PRIVILEGE_SHOW_INDEX:
      command = 'SHOW INDEX';
      break;
    case PRIVILEGE_SHOW_CONSTRAINT:
      command = 'SHOW CONSTRAINT';
      break;
    default:
      command = missingProcedure;
  }

  const userString = !isNil(user) && user.length > 0 ? `User "${user}"` : 'The current user';
  const roleString =
    !isNil(roles) && roles?.length > 0 ? ` with role(s) ${typeof roles === 'string' ? roles : roles.join(', ')}` : '';
  return `${explanation}${userString}${roleString} needs to have permission granted to execute "${command}". Please contact your database administrator.`;
};

const filterProcedurePrivileges = (
  records: UserPrivilege[],
): {
  isGranted: boolean;
  isDenied: boolean;
  procedureAction: string;
}[] => {
  const privileges = records.map(({ access, segment }) => {
    const match = segment.match(/PROCEDURE\((.*)\)/);
    return !isNil(match) && match[1] !== undefined
      ? {
          isGranted: access === 'GRANTED',
          isDenied: access === 'DENIED',
          procedureAction: match[1],
        }
      : null;
  });

  return privileges.filter((p): p is Exclude<typeof p, null> => !isNil(p));
};

const filterDatabasePrivileges = (
  records: UserPrivilege[],
): {
  isGranted: boolean;
  isDenied: boolean;
  procedureAction: string;
}[] =>
  records
    .filter((p) => p.segment === 'database')
    .map((p) => ({
      isGranted: p.access === 'GRANTED',
      isDenied: p.access === 'DENIED',
      procedureAction: p.action,
    }));

const isGranted = (proc: string, privileges: ReturnType<typeof filterDatabasePrivileges>) => {
  let isGranted = false;
  let isDenied = false;

  privileges.forEach((privilege) => {
    const { procedureAction, isGranted: privGranted, isDenied: privDenied } = privilege;
    const starIndex = procedureAction.indexOf('*');
    const matches = starIndex === -1 ? proc === procedureAction : proc.startsWith(procedureAction.substr(0, starIndex));
    if (matches) {
      isGranted = isGranted || privGranted;
      isDenied = isDenied || privDenied;
    }
  });

  return isGranted && !isDenied;
};

const checkProcedureList = (
  list: typeof StandardProcs | typeof PluginProcs | typeof UpdatedDatabaseActions,
  privileges: ReturnType<typeof filterDatabasePrivileges>,
) => list.find((proc) => !isGranted(proc, privileges));

export const checkAvailableProcedures = (
  procedures: string[],
  allPrivileges: UserPrivilege[],
  version: string | null | undefined,
  user: string | null | undefined,
  roles: string[] | null | undefined,
) => {
  const privileges = filterProcedurePrivileges(allPrivileges);
  let missingProcedure = checkProcedureList(StandardProcs, privileges);

  const hasPlugin = !isNil(procedures.find((proc) => proc.startsWith('bloom.')));
  if (hasPlugin && isNil(missingProcedure)) {
    missingProcedure = checkProcedureList(PluginProcs, privileges);
  }

  if (isNil(missingProcedure)) {
    const databasePrivileges = filterDatabasePrivileges(allPrivileges);
    missingProcedure = checkProcedureList(UpdatedDatabaseActions, databasePrivileges);
  }

  const result: {
    success: boolean;
    message?: string;
    alternatives?: { text: string }[];
  } = !isNil(missingProcedure)
    ? { success: false, message: getNoProcedurePermissionMessage(missingProcedure, user, roles) }
    : { success: true };

  if (
    !isFalsy(missingProcedure) &&
    UpdatedDatabaseActions.includes(missingProcedure) &&
    isFalsy(isVersion5OorGreater(version))
  ) {
    result.alternatives = [{ text: 'Dismiss' }];
  }
  return result;
};

export const isProcedureGranted = (procedure: string, allPrivileges: UserPrivilege[]) => {
  const privileges = filterProcedurePrivileges(allPrivileges);
  return isGranted(procedure, privileges);
};

export const isAdminProcedureGranted = (procedure: string, privileges?: UserPrivilege[]) => {
  if (isNil(privileges)) return false;

  const actionsHierarchy = ['dbms_actions', 'execute_admin'];
  const dbPrivileges = privileges.filter((p) => ['*'].includes(p.graph));

  const actionPrivileges = dbPrivileges.filter((p) => actionsHierarchy.includes(p.action));

  const procedurePrivileges = dbPrivileges
    .filter((p) => p.action === 'execute_boosted')
    .filter(({ segment }) => {
      const match = segment.match(/PROCEDURE\((.*)\)/);
      if (!isNil(match)) {
        return match[1] === '*' || (match[1] !== undefined && procedure.startsWith(match[1].replace('*', '')));
      }
      return false;
    });

  const relevantPrivileges = [...actionPrivileges, ...procedurePrivileges];

  const existsGrantedPrivilege = relevantPrivileges.find((p) => p.access === 'GRANTED') != null;
  const existsDeniedPrivilege = relevantPrivileges.find((p) => p.access === 'DENIED') != null;

  return existsGrantedPrivilege && !existsDeniedPrivilege;
};
