import { Banner, Button, Checkbox, Dialog, TextInput, Tooltip } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { consoleApi, getApiError, getErrorMessage } from '@nx/state';
import type { CustomEndpoint, Instance } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';

import { constructCustomEndpointUrl } from './helpers';

const logger = createLogger(APP_SCOPE.aura);

type Props = {
  onClose: () => void;
  instance?: Instance;
  sourceInstance: Instance;
  endpoint: CustomEndpoint;
};

export const UndoTransferDialog = ({ onClose, instance, sourceInstance, endpoint }: Props) => {
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const [updateCustomEndpoint, updateCustomEndpointRes] = consoleApi.useUpdateCustomEndpointMutation();

  const handleConfirm = (checked: boolean) => {
    setHasConfirmed(checked);
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    updateCustomEndpoint({ id: endpoint.id, dbId: sourceInstance.id })
      .unwrap()
      .then(() => {
        onClose();
      })
      .catch((error: FetchBaseQueryError | SerializedError | undefined) => {
        const apiError = getApiError(error);
        const message = getErrorMessage(apiError);
        logger.error(message);
      });
  };

  return (
    <Dialog isOpen onClose={onClose}>
      <Dialog.Header>Undo custom endpoint transfer</Dialog.Header>
      <form onSubmit={handleSubmit}>
        <Dialog.Content className="flex flex-col gap-5">
          <div className="flex flex-col gap-4">
            <TextInput label="Custom endpoint" value={constructCustomEndpointUrl(endpoint)} isReadOnly isFluid />
            {isNotNullish(instance) && <TextInput label="Assigned instance" value={instance.name} isReadOnly isFluid />}
          </div>
          <Banner
            hasIcon
            type="info"
            description="It will take a short moment for this custom endpoint to be ready for use again."
            usage="inline"
          />
          <Banner
            hasIcon
            type="warning"
            usage="inline"
            description={
              <span>
                This action will assign this custom endpoint back to instance <b>{sourceInstance.name}</b>. You will not
                be able to configure this custom endpoint for the next 3 hours.
              </span>
            }
          />
          {updateCustomEndpointRes.isError && (
            <Banner
              hasIcon
              type="danger"
              description={getErrorMessage(getApiError(updateCustomEndpointRes.error))}
              usage="inline"
            />
          )}
        </Dialog.Content>
        <Dialog.Actions className="flex items-center justify-between">
          <Checkbox
            isChecked={hasConfirmed}
            onChange={(event) => handleConfirm(event.target.checked)}
            label="I understand"
          />
          <div className="flex gap-4 ">
            <Button color="neutral" fill="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Tooltip type="simple" isDisabled={hasConfirmed} placement="top" isPortaled={false}>
              <Tooltip.Trigger hasButtonWrapper>
                <Button
                  color="primary"
                  type="submit"
                  isLoading={updateCustomEndpointRes.isLoading}
                  isDisabled={!hasConfirmed}
                >
                  Confirm
                </Button>
              </Tooltip.Trigger>
              <Tooltip.Content className="!fixed !w-[200px]">
                You need to confirm that you understand the consequences of undoing a transfer
              </Tooltip.Content>
            </Tooltip>
          </div>
        </Dialog.Actions>
      </form>
    </Dialog>
  );
};
