import { Typography } from '@neo4j-ndl/react';
import {
  ACTION,
  MODAL_TYPE,
  PROJECT_PROFILE,
  useActiveOrgQuery,
  useActiveProjectQuery,
  useModal,
  usePermissions,
} from '@nx/state';
import { isNullish } from '@nx/stdlib';
import cn from 'classnames';
import { differenceInHours } from 'date-fns';

const getRemainingTrialTime = (trialEndTime: string): number => {
  const trialEndDate = new Date(trialEndTime);

  const now = new Date();

  if (trialEndDate < now) {
    return -1;
  }

  const hoursRemaining = Math.max(differenceInHours(trialEndDate, now) + 1, 0);

  const daysRemaining = Math.floor(hoursRemaining / 24);
  return daysRemaining;
};

const getRemainingTrialTimeMessage = (trialEndTime: number): string => {
  if (trialEndTime < 0) {
    return 'expired';
  }
  if (trialEndTime < 2) {
    return `${trialEndTime} day left`;
  }

  return `${trialEndTime} days left`;
};

const getTrialButtonColor = (daysLeft: number) => {
  if (daysLeft <= 3) {
    return 'danger';
  }
  if (daysLeft <= 7) {
    return 'warning';
  }

  return 'success';
};

export const TrialButton = () => {
  const { data: project } = useActiveProjectQuery({ refetchOnMountOrArgChange: true });
  const { data: org } = useActiveOrgQuery({ refetchOnMountOrArgChange: true });
  const extendTrialModal = useModal(MODAL_TYPE.EXTEND_TRIAL);
  const addPaymentMethodModal = useModal(MODAL_TYPE.ADD_PAYMENT_DETAILS);
  const { isAllowed } = usePermissions();

  if (
    isNullish(org) ||
    isNullish(org.trialEndTime) ||
    isNullish(project) ||
    ((project.hasBilling || project.profile === PROJECT_PROFILE.INTERNAL) &&
      getRemainingTrialTime(org.trialEndTime) === -1)
  ) {
    return null;
  }
  const remainingTrialTime = getRemainingTrialTime(org.trialEndTime);
  const trialButtonColor = getTrialButtonColor(remainingTrialTime);
  const allowExtend =
    isAllowed(ACTION.UPDATE, `organizations/${org.id}`) && isAllowed(ACTION.CREATE, `namespaces/${project.id}/billing`);
  const canExtend = project.availableActions.extend_pro_trial.enabled && remainingTrialTime <= 7;

  const classes = cn('flex shrink-0 gap-2 rounded-sm border px-2 py-1', {
    'bg-success-bg-weak border-success-border-weak': trialButtonColor === 'success',
    'bg-warning-bg-weak border-warning-border-weak': trialButtonColor === 'warning',
    'bg-danger-bg-weak border-danger-border-weak': trialButtonColor === 'danger',
    'cursor-default': !allowExtend,
  });

  return (
    <Typography
      variant="body-small"
      as="button"
      className={classes}
      htmlAttributes={{
        onClick: () => {
          if (canExtend && allowExtend) {
            extendTrialModal.open({ project });
          } else if (allowExtend) {
            addPaymentMethodModal.open({ hasBilling: project.hasBilling });
          }
        },
      }}
    >
      <span>
        Trial{' '}
        {remainingTrialTime !== -1 ? (
          <>
            - <b>{getRemainingTrialTimeMessage(remainingTrialTime)}</b>
          </>
        ) : (
          getRemainingTrialTimeMessage(remainingTrialTime)
        )}
      </span>
      {canExtend && <span className="underline">Extend</span>}
    </Typography>
  );
};
