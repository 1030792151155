import type { SearchPhraseSuggestion } from '../../../../modules/SearchBar/types';

export const filterDuplication = (suggestion: SearchPhraseSuggestion, tempSuggestions: SearchPhraseSuggestion[]) => {
  if (
    tempSuggestions.find(
      (tempSuggestion) =>
        tempSuggestion?.displayText === suggestion.displayText &&
        tempSuggestion?.description === suggestion.description,
    ) != null
  ) {
    return false;
  }
  tempSuggestions.push(suggestion);
  return true;
};

export const sortAlphabetically = (suggestionA: SearchPhraseSuggestion, suggestionB: SearchPhraseSuggestion) => {
  const textA = suggestionA.displayText || suggestionA.description;
  const textB = suggestionB.displayText || suggestionB.description;
  return textA.localeCompare(textB);
};
