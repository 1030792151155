import type {
  SECURITY_LOG_STATUS,
  SecurityLog,
  SecurityLogFilterInput,
  SecurityLogRawFilterSelection,
  SecurityLogSummary,
  SelectedSecurityLogFilters,
  TimeRangeSecurityLog,
  TimeRangeSecurityLogSummary,
} from '@nx/state';
import { SECURITY_FORMATTED_AVAILABLE_STATUSES, SECURITY_STATUS_MAP } from '@nx/state';
import { Objects, isNonEmptyString } from '@nx/stdlib';

export const mapSecurityLogs = (data?: SecurityLog[]): TimeRangeSecurityLog[] => {
  const logs = data;

  if (!logs) {
    return [];
  }

  return logs.map((l) => ({
    ...l,
    time: l.time ? new Date(l.time) : undefined,
  }));
};

export const mapSecurityLogSummary = (data?: SecurityLogSummary[]): TimeRangeSecurityLogSummary[] => {
  const summary = data;

  if (!summary) {
    return [];
  }

  return summary.map((agg) => ({
    ...agg,
    from: agg.from ? new Date(agg.from) : undefined,
    to: agg.to ? new Date(agg.to) : undefined,
  }));
};

export const mapFormattedStatuses = (statuses: typeof SECURITY_FORMATTED_AVAILABLE_STATUSES) =>
  statuses
    .filter((s): s is keyof typeof SECURITY_STATUS_MAP => SECURITY_FORMATTED_AVAILABLE_STATUSES.includes(s))
    .map((s) => SECURITY_STATUS_MAP[s]);

export const mapSecurityLogRawFiltersToInput = (rawFilters: SecurityLogRawFilterSelection): SecurityLogFilterInput => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const input = {} as SecurityLogFilterInput;

  Objects.keys(rawFilters.securityLogFilters).forEach((filterName) => {
    const selected = [...rawFilters.securityLogFilters[filterName]].sort();

    if (selected.length === 0) {
      return;
    }

    if (filterName === 'statuses') {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      input[filterName] = mapFormattedStatuses(selected) as SECURITY_LOG_STATUS[];
    } else {
      input[filterName] = selected;
    }
  });

  // endTime should always be set
  const { endTime, startTime = new Date(endTime) } = rawFilters.timeRange;

  input.from = startTime.getTime();
  input.to = endTime.getTime();

  if (isNonEmptyString(rawFilters.message)) {
    input.message = rawFilters.message;
  }

  if (isNonEmptyString(rawFilters.messageSearchString)) {
    input.messageSearchString = rawFilters.messageSearchString;
  }

  return input;
};

const SORTED_TAGGABLE_FILTERS: (keyof SelectedSecurityLogFilters)[] = [
  'statuses',
  'executingUsers',
  'databases',
  'drivers',
  'authenticatedUsers',
];

export const getActiveFilters = (selectedSecurityLogFilters: SelectedSecurityLogFilters) =>
  SORTED_TAGGABLE_FILTERS.flatMap((filterName) => {
    const filterValue = selectedSecurityLogFilters[filterName];
    return filterValue.map((value): [typeof filterName, typeof value] => [filterName, value]);
  });

export const getActiveSearchFilters = (rawFilters: SecurityLogRawFilterSelection) =>
  [rawFilters.messageSearchString].filter(isNonEmptyString);
