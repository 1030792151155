import type {
  DeprecationLogFilterInput,
  DriverLogAggregation as DriverLogAggregationRaw,
  OpsTypes,
  PaginatedDeprecationLogAggregations,
} from '@nx/state';
import { DEPRECATION_LOG_AGGREGATION_SORT_PROPERTY, DRIVER_VERSION_STATUS } from '@nx/state';
import { Objects, isNonEmptyString } from '@nx/stdlib';
import { isEqual } from 'lodash-es';

import type { ColumnId } from '../logs/shared/types';

export const mapDeprecationLogAggregations = (
  data?: PaginatedDeprecationLogAggregations,
): OpsTypes.Migration.DeprecationLogAggregation[] => {
  const aggregations = data?.logAggregations ?? [];

  const toDeprecationNotifications = (
    deprecationNotificationsString: string | null | undefined,
  ): OpsTypes.Migration.DeprecationNotification[] => {
    const unknownDeprecationNotifications: OpsTypes.Migration.DeprecationNotification[] = [
      {
        position: { id: -1, offset: -1, line: -1, column: -1 },
        name: 'Unknown deprecation',
        description: deprecationNotificationsString ?? 'Unknown deprecation notifications',
      },
    ];
    if (!isNonEmptyString(deprecationNotificationsString)) {
      return unknownDeprecationNotifications;
    }
    try {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return JSON.parse(deprecationNotificationsString) as OpsTypes.Migration.DeprecationNotification[];
    } catch {
      return unknownDeprecationNotifications;
    }
  };

  return aggregations.map((agg) => ({
    ...agg,
    lastTimestamp: new Date(agg.lastTimestamp),
    deprecationNotifications: toDeprecationNotifications(agg.deprecationNotifications),
  }));
};

export const mapRawFiltersToInput = (rawFilters: OpsTypes.Migration.RawFilterSelection): DeprecationLogFilterInput => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const input = {} as DeprecationLogFilterInput;

  Objects.keys(rawFilters.dbmsFilters).forEach((filterName) => {
    const selected = [...rawFilters.dbmsFilters[filterName]].sort();
    if (selected.length === 0) {
      return;
    }
    input[filterName] = selected;
  });

  // endTime should always be set
  const { endTime, startTime = new Date(endTime) } = rawFilters.timeRange;

  input.from = startTime.getTime();
  input.to = endTime.getTime();

  if (isNonEmptyString(rawFilters.querySearchString)) {
    input.querySearchString = rawFilters.querySearchString;
  }

  return input;
};

const SORTED_TAGGABLE_FILTERS: OpsTypes.Migration.FilterableFields[] = [
  'users',
  'databases',
  'drivers',
  'apps',
  'initiationTypes',
  'deprecationNotificationNames',
];

export const getActiveFilters = (selectedDbmsFilters: OpsTypes.Migration.SelectedDbmsFilters) =>
  SORTED_TAGGABLE_FILTERS.flatMap((filterName) => {
    const filterValue = selectedDbmsFilters[filterName];
    return filterValue.map((value): [typeof filterName, typeof value] => [filterName, value]);
  });

export const getActiveSearchFilters = (rawFilters: OpsTypes.Migration.RawFilterSelection) =>
  [rawFilters.querySearchString].filter(isNonEmptyString);

export const mapDriverLogAggregations = (
  aggregations: DriverLogAggregationRaw[] | undefined,
  onlyShowIssues: boolean,
  onlyShowNonSystemDatabaseAccess: boolean,
): OpsTypes.Migration.DriverLogAggregation[] => {
  return (aggregations ?? [])
    .map((agg) => ({
      ...agg,
      lastTimestamp: typeof agg.lastTimestamp === 'number' ? new Date(agg.lastTimestamp) : undefined,
    }))
    .filter(
      (agg) =>
        (!onlyShowIssues || agg.driverVersionStatus !== DRIVER_VERSION_STATUS.Compatible) &&
        (!onlyShowNonSystemDatabaseAccess || !isEqual(agg.databases, ['system'])),
    );
};

export const FriendlyDeprecationLogFilterName: Record<OpsTypes.Migration.FilterableFields, string> = {
  deprecationNotificationNames: 'deprecation name',
  users: 'user',
  drivers: 'driver',
  apps: 'application',
  initiationTypes: 'initiation type',
  databases: 'database',
};

export const COLUMN_TO_QLA_SORT: Record<ColumnId, OpsTypes.Api.DEPRECATION_LOG_AGGREGATION_SORT_PROPERTY> = {
  executionCount: DEPRECATION_LOG_AGGREGATION_SORT_PROPERTY.COUNT,
  query: DEPRECATION_LOG_AGGREGATION_SORT_PROPERTY.QUERY,
  lastTimestamp: DEPRECATION_LOG_AGGREGATION_SORT_PROPERTY.LAST_TIMESTAMP,
  deprecationNotifications: DEPRECATION_LOG_AGGREGATION_SORT_PROPERTY.DEPRECATION_NOTIFICATIONS,
};

export const toDriverStatusPriority = (driverVersionStatus: DRIVER_VERSION_STATUS) => {
  switch (driverVersionStatus) {
    case DRIVER_VERSION_STATUS.Compatible:
      return 0;
    case DRIVER_VERSION_STATUS.UpgradeRecommended:
      return 1;
    case DRIVER_VERSION_STATUS.CommunityDriver:
      return 2;
    case DRIVER_VERSION_STATUS.Incompatible:
      return 3;
    case DRIVER_VERSION_STATUS.DeprecatedDriver:
      return 4;
    case DRIVER_VERSION_STATUS.UnknownDriver:
      return 5;
    case DRIVER_VERSION_STATUS.UnknownDriverVersion:
    default:
      return 6;
  }
};

export const toDisplayName = (input: string) => {
  const parts = input.split('_');
  const deprecationNotificationName = parts.length > 1 ? parts[parts.length - 2] : parts[0];
  const words = deprecationNotificationName?.split(/(?=[A-Z])/);
  return (words ?? [])
    .filter((w) => !['Deprecated', 'Notification', 'Syntax'].includes(w))
    .map((w, i) => {
      if (i === 0) {
        return w;
      }
      return w[0]?.toLowerCase() + w.slice(1);
    })
    .join(' ');
};
