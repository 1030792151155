export type RGB = {
  r: number;
  g: number;
  b: number;
};

/**
 * converts a hex color to rgb format
 * @param {string} hex The color in hex format to convert.
 * @return {RGB} The color in RGB format.
 */
export const hexToRgb = (hex: string): RGB | null => {
  if (hex.length === 4) {
    const r = parseInt(hex.slice(1, 2).repeat(2), 16);
    const g = parseInt(hex.slice(2, 3).repeat(2), 16);
    const b = parseInt(hex.slice(3, 4).repeat(2), 16);

    return { r, g, b };
  } else if (hex.length === 7) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return { r, g, b };
  }
  return null;
};

const componentToHex = (c: number) => {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};
/** 
   converts a rgb color to hex format
   * @param {RGB} rgb The color in rgb format to convert.
   * @return {string} The color in hex format.
  */
export const rgbToHex = (rgb: RGB) => {
  return `#${componentToHex(rgb.r)}${componentToHex(rgb.g)}${componentToHex(rgb.b)}`;
};

const lchToLab = (l: number, c: number, h: number) => {
  const hRad = (h * Math.PI) / 180;

  return {
    l: l,
    a: Math.cos(hRad) * c,
    b: Math.sin(hRad) * c,
  };
};

const lrgbToRgb = (c: number) => {
  const abs = Math.abs(c);
  if (abs > 0.0031308) {
    return (Math.sign(c) || 1) * (1.055 * Math.pow(abs, 1 / 2.4) - 0.055);
  }
  return c * 12.92;
};

const oklabToRgb = (L: number, a: number, b: number) => {
  const l = Math.pow(L + 0.3963377774 * a + 0.2158037573 * b, 3);
  const m = Math.pow(L - 0.1055613458 * a - 0.0638541728 * b, 3);
  const s = Math.pow(L - 0.0894841775 * a - 1.291485548 * b, 3);

  return {
    r: 255 * lrgbToRgb(+4.0767416621 * l - 3.3077115913 * m + 0.2309699292 * s),
    g: 255 * lrgbToRgb(-1.2684380046 * l + 2.6097574011 * m - 0.3413193965 * s),
    b: 255 * lrgbToRgb(-0.0041960863 * l - 0.7034186147 * m + 1.707614701 * s),
  };
};

const clamp = (n: number, lowerBound: number, upperBound: number) => Math.max(lowerBound, Math.min(n, upperBound));

const clampRgb = (n: number) => clamp(n, 0, 255);

/* Uses the formula described By Björn ottosson in A perceptual color space for image processing 
     https://bottosson.github.io/posts/oklab/ 
  */
/** 
   converts a oklch color to rgb format
   * @param {number} l The precentage of precived lightness [0,1].
   * @param {number} c The precentage of chroma [0,1].
   * @param {number} h The hue in degress [0, 365].
   * @return {RGB} The color in RGB format.
  */
export const oklchToRgb = (l: number, c: number, h: number): RGB => {
  const { l: L, a, b: b_ } = lchToLab(l, c, h);
  const { r, g, b } = oklabToRgb(L, a, b_);

  return { r: clampRgb(Math.round(r)), g: clampRgb(Math.round(g)), b: clampRgb(Math.round(b)) };
};

const luminance = (rgb: RGB) => {
  const [lr, lg, lb] = Object.values(rgb).map((v) => {
    const V = v / 255;
    return V <= 0.03928 ? V / 12.92 : Math.pow((V + 0.055) / 1.055, 2.4);
  });

  if (lr === undefined || lg === undefined || lb === undefined) {
    return 0;
  }

  return lr * 0.2126 + lg * 0.7152 + lb * 0.0722;
};

/** 
   returns the most contrast between two colors 
   * @param {string} color1 The first color to be compared in hex format.
   * @param {string} color2 The Second color to be compared in hex format.
   * @return {number} The contrast between the two colors.
  */
export const contrast = (hex1: string, hex2: string) => {
  const rgb1 = hexToRgb(hex1);
  const rgb2 = hexToRgb(hex2);

  if (!rgb1 || !rgb2) {
    return 0;
  }
  const lum1 = luminance(rgb1);
  const lum2 = luminance(rgb2);
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
};
