import { v4 as generateProjectionId } from 'uuid';

import { isVersion240OrGreater } from '../../services/versions/versionUtils';
import type { ProcedureKey } from '../../state/gds/constants';
import {
  BETWEENNESS_CENTRALITY,
  CREATE_PROJECTION,
  DEGREE_CENTRALITY,
  DELETE_PROJECTION,
  EIGENVECTOR_CENTRALITY,
  LABEL_PROPAGATION,
  LOUVAIN,
  PAGE_RANK_CENTRALITY, // TRIANGLE_COUNT,
  WCC,
  gdsProceduresMap,
} from '../../state/gds/constants';
import type { Algorithm, Orientation, Procedure } from '../../state/gds/types';
import type { Relationship } from '../../types/graph';
import type { Nullable } from '../../types/utility';
import bolt from '../bolt/bolt';
import { log } from '../logging';
import {
  callBetweennessQuery,
  callCentralityQuery,
  callCommunityQuery, // callTriangleCountQuery,
  callWccQuery,
  createGdsProjectionQuery,
  deleteGdsProjectionQuery,
} from './queryGenerator';
import type { MapperResult } from './resultMapper';
import { mapper } from './resultMapper';

const createProjection = async (
  projectionId: string,
  procedure: Procedure,
  selectedNodeIds: string[],
  selectedRelIds: string[],
  relationships: Record<string, Relationship>,
  useDeprecatedCypherProjections: boolean,
  selectedRelWeightedProperty: Nullable<string>,
  selectedOrientation: Orientation,
  isV5OrGreater: boolean,
) => {
  const {
    cypher: createGdsCypher,
    parameters: createGdsParams,
    numIdMap,
  } = createGdsProjectionQuery(
    projectionId,
    procedure,
    selectedNodeIds,
    selectedRelIds,
    relationships,
    useDeprecatedCypherProjections,
    selectedRelWeightedProperty,
    selectedOrientation,
    isV5OrGreater,
  );
  await bolt.readTransaction(createGdsCypher, { parameters: createGdsParams });

  return numIdMap;
};

const deleteProjection = async (projectionId: string, procedure: Procedure) => {
  const { cypher: deleteGdsCypher, parameters: deleteGdsParams } = deleteGdsProjectionQuery(projectionId, procedure);
  await bolt.readTransaction(deleteGdsCypher, { parameters: deleteGdsParams });
};

export const findProcedure = (algorithm: ProcedureKey, gdsProcedures: Procedure[]) => {
  for (const procedure of gdsProceduresMap[algorithm]) {
    if (gdsProcedures.includes(procedure)) {
      return procedure;
    }
  }
};

export const runGdsAlgo = async (
  algorithm: Algorithm,
  gdsProcedures: Procedure[],
  selectedNodeIds: string[],
  selectedRelIds: string[],
  relationships: Record<string, Relationship>,
  gdsVersion: string,
  selectedRelWeightedProperty: Nullable<string>,
  selectedOrientation: Orientation,
  isV5OrGreater: boolean,
): Promise<{ results: Nullable<MapperResult>; timeTaken: number } | { error: unknown }> => {
  const startTime = window.performance.now();
  let results = null;
  let error = null;
  const createProjectionProcedure = findProcedure(CREATE_PROJECTION, gdsProcedures);
  if (createProjectionProcedure == null) {
    return { error: 'No create projection procedure found' };
  }
  const deleteProjectionProcedure = findProcedure(DELETE_PROJECTION, gdsProcedures);
  if (deleteProjectionProcedure == null) {
    return { error: 'No delete projection procedure found' };
  }
  const projectionId = `bloom-gds-${generateProjectionId()}`;

  const isVersionLessThan240 = !isVersion240OrGreater(gdsVersion);
  try {
    const numIdMap = await createProjection(
      projectionId,
      createProjectionProcedure,
      selectedNodeIds,
      selectedRelIds,
      relationships,
      isVersionLessThan240,
      selectedRelWeightedProperty,
      selectedOrientation,
      isV5OrGreater,
    );
    const procedure = findProcedure(algorithm, gdsProcedures);

    if (procedure == null) {
      return { error: 'No procedure found' };
    }
    switch (algorithm) {
      case BETWEENNESS_CENTRALITY:
        const { cypher: betweennessCypher, parameters: betweennessParams } = callBetweennessQuery(
          projectionId,
          procedure,
          !isVersionLessThan240,
          selectedRelWeightedProperty,
        );
        const betweennessResponse = await bolt.readTransaction(betweennessCypher, { parameters: betweennessParams });
        results = mapper(betweennessResponse, true, numIdMap);
        break;

      case DEGREE_CENTRALITY:
      case EIGENVECTOR_CENTRALITY:
      case PAGE_RANK_CENTRALITY:
        const { cypher: degreeCypher, parameters: degreeParams } = callCentralityQuery(
          projectionId,
          procedure,
          !isVersionLessThan240,
          selectedRelWeightedProperty,
        );
        const degreeResponse = await bolt.readTransaction(degreeCypher, { parameters: degreeParams });
        results = mapper(degreeResponse, true, numIdMap);
        break;

      // case CLOSENESS:
      //   const { cypher: closenessCypher, parameters: closenessParams } = callClosenessQuery(procedure)
      //   results = await bolt.readTransaction(closenessCypher, { parameters: closenessParams })
      //     .then((result) => mapper(result, true))
      //   break

      case LABEL_PROPAGATION:
      case LOUVAIN:
        const { cypher: louvainCypher, parameters: louvainParams } = callCommunityQuery(
          projectionId,
          procedure,
          !isVersionLessThan240,
          selectedRelWeightedProperty,
        );
        const louvainResponse = await bolt.readTransaction(louvainCypher, { parameters: louvainParams });
        results = mapper(louvainResponse, false, numIdMap);
        break;

      // case TRIANGLE_COUNT:
      //   const { cypher: triangleCypher, parameters: triangleParams } = callTriangleCountQuery(projectionId, procedure)
      //   results = await bolt.readTransaction(triangleCypher, { parameters: triangleParams }).then((result) => mapper(result, false))
      //   break

      case WCC:
        const { cypher: wccCypher, parameters: wccParams } = callWccQuery(
          projectionId,
          procedure,
          !isVersionLessThan240,
          selectedRelWeightedProperty,
        );
        const wccResponse = await bolt.readTransaction(wccCypher, { parameters: wccParams });
        results = mapper(wccResponse, false, numIdMap);
        break;

      default:
        break;
    }
  } catch (e) {
    error = e;
    log.error(e);
  } finally {
    try {
      await deleteProjection(projectionId, deleteProjectionProcedure); // try to clear any projected graph
    } catch (e) {
      log.info(e);
    }
  }

  if (error !== null) return { error };
  const endTime = window.performance.now();
  return { results, timeTaken: endTime - startTime };
};
