import { isNil } from 'lodash-es';
import type { Moment } from 'moment';
import moment from 'moment';

import { log } from '../logging';
import { BaseTemporal } from './BaseTemporal';
import { Date } from './Date';
import { LocalTime } from './LocalTime';
import type { DateTimeProperties } from './types';
import { DATETIME_FORMAT, DATETIME_REGEX } from './utils.const';

export class LocalDateTime extends BaseTemporal {
  date: Date;

  time: LocalTime;

  constructor(obj: DateTimeProperties) {
    super();

    this.date = new Date(obj);
    this.time = new LocalTime(obj);
  }

  static parseStringToObj(stringVal: string) {
    const newStringVal = this.addSymbolToYearIfNeeded(stringVal);
    let year, month, day, hour, minute, second, nanosecond;

    try {
      const localDateTimeMatch = DATETIME_REGEX.exec(newStringVal);

      if (!isNil(localDateTimeMatch)) {
        year = (localDateTimeMatch[1] ?? '+') + localDateTimeMatch[2];
        month = localDateTimeMatch[3];
        day = localDateTimeMatch[4];
        hour = localDateTimeMatch[5];
        minute = localDateTimeMatch[6];
        second = localDateTimeMatch[7];
        nanosecond = this.convertNanoStringToNum(localDateTimeMatch[8]);
      }
    } catch (e) {
      log.debug('Invalid Date Time value');
    }

    const yearInt = parseInt(year ?? '');
    const monthInt = parseInt(month ?? '');
    const dayInt = parseInt(day ?? '');
    const hourInt = parseInt(hour ?? '');
    const minuteInt = parseInt(minute ?? '');
    const secondInt = parseInt(second ?? '');
    const nanosecondInt = parseInt(nanosecond ?? '');

    return new LocalDateTime({
      year: yearInt,
      month: monthInt,
      day: dayInt,
      hour: hourInt,
      minute: minuteInt,
      second: secondInt,
      nanosecond: nanosecondInt,
    });
  }

  static parseMomentObjToObj(momentObj: Moment, addedNanoseconds = 0) {
    return new LocalDateTime({
      year: momentObj.year(),
      month: momentObj.month() + 1,
      day: momentObj.date(),
      hour: momentObj.hour(),
      minute: momentObj.minute(),
      second: momentObj.second(),
      nanosecond: momentObj.millisecond() * 1000000 + addedNanoseconds,
    });
  }

  static parseNumberToString(localDateTime: number, hideTime?: boolean) {
    return this.convertNumberToDateTime(localDateTime, hideTime);
  }

  static parseStringToNumber(localDateTimeString: string) {
    const dateTimeMatch = DATETIME_REGEX.exec(localDateTimeString);
    const localDateTimeStringWithTime = !isNil(dateTimeMatch) ? dateTimeMatch[0] : localDateTimeString;
    return this.convertDateTimeToNumber(localDateTimeStringWithTime, !isNil(dateTimeMatch));
  }

  getYear() {
    return this.date.getYear();
  }

  getMonth() {
    return this.date.getMonth();
  }

  getDay() {
    return this.date.getDay();
  }

  getHour() {
    return this.time.getHour();
  }

  getMinute() {
    return this.time.getMinute();
  }

  getSecond() {
    return this.time.getSecond();
  }

  getNanosecond() {
    return this.time.getNanosecond();
  }

  hasMs() {
    return this.getNanosecond() > 0;
  }

  getTimezone() {
    return null; // interface
  }

  // the returned string should be identical as neo4j.types.LocalDateTime.toString()
  toString() {
    return `${this.date.toString()}T${this.time.toString()}`;
  }

  // the toString() result of neo4j.types.LocalTime
  toNeo4jString() {
    return BaseTemporal.addZeroesToYearIfNeeded(this.toString());
  }

  toPropertyObject() {
    return {
      year: this.date.getYear(),
      month: this.date.getMonth(),
      day: this.date.getDay(),
      hour: this.time.getHour(),
      minute: this.time.getMinute(),
      second: this.time.getSecond(),
      nanosecond: this.time.getNanosecond(),
    };
  }

  toMomentObj() {
    return moment(this.toString(), DATETIME_FORMAT);
  }

  // We consider the date without timezone in input as UTC date for simplification (the timezone will be removed when we convert to string)
  static convertDateTimeToNumber = (date: string, isDateTime: boolean) => {
    const dateWithZ = isDateTime && !date.includes('.') ? `${date}.000Z` : date;
    return new window.Date(dateWithZ).getTime();
  };

  // The timeInt is supposed to be in UTC, and we will remove the timezone part (it is the dual function of convertDateTimeToNumber)
  static convertNumberToDateTime = (timeInt: number, hideTime = false) => {
    const dt = new window.Date(timeInt).toISOString();
    const [date, timeWithZone] = dt.split('T');
    const [time] = `${timeWithZone}`.split('.');
    return hideTime ? date : `${date}T${time}`;
  };
}
