import type { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { difference, isNil, reduce, union, uniq } from 'lodash-es';

import type { MappedProperty, Node, Relationship } from '../../types/graph';
import { REHYDRATE } from '../persistence/constants';
import { CLEAR_SCENE } from '../rootActions';
import type { RootState } from '../types';
import {
  addToExpanded,
  addToInventory,
  addToVisible,
  clearExpanded,
  deselectAll as graphDeselectAll,
  invertSelection,
  purgeInvisibleItems,
  removeFromVisible,
  setSelected,
  updateInventory,
} from './graph.actions';
import { idArraysAreEqual } from './util';

export const NAME = 'relationships';
const GRAPH = 'graph';

export interface RelationshipsState {
  inventory: Record<Relationship['id'], Relationship>;
  visible: string[];
  visibleRels: Relationship[];
  selected: Record<string, boolean>;
  expanded: Record<string, boolean>;
  relsPerType: Record<string, string[]>;
  disabled: Record<string, true>;
  selectedArray?: string[];
}

export const initialState: RelationshipsState = {
  inventory: {},
  visible: [],
  visibleRels: [],
  selected: {},
  expanded: {},
  relsPerType: {},
  disabled: {},
};

/**
 * Selectors
 */

export const getRelationshipsState = (state: RootState): RelationshipsState => state[GRAPH].present[NAME];

export function getRelationships(state: RootState): RelationshipsState['inventory'] {
  return getRelationshipsState(state)?.inventory ?? initialState.inventory;
}
export function getSelectedRelationshipMap(state: RootState): RelationshipsState['selected'] {
  return getRelationshipsState(state)?.selected ?? initialState.selected;
}
export function getDisabledRelationshipMap(state: RootState): RelationshipsState['disabled'] {
  return getRelationshipsState(state)?.disabled ?? initialState.disabled;
}
export function getVisibleRelationshipIds(state: RootState): RelationshipsState['visible'] {
  return getRelationshipsState(state)?.visible ?? initialState.visible;
}
export function getVisibleRelationships(state: RootState): RelationshipsState['visibleRels'] {
  return getRelationshipsState(state)?.visibleRels ?? initialState.visibleRels;
}
export function getVisibleRelsPerType(state: RootState): RelationshipsState['relsPerType'] {
  return getRelationshipsState(state)?.relsPerType ?? initialState.relsPerType;
}
export const getAllSelectedRelationships = createSelector(getRelationshipsState, (relState) =>
  Object.keys(relState?.selected),
);

/**
 * Reducer and helpers
 */
function reduceRelationship(obj: Record<Relationship['id'], Relationship>, relationship: Relationship) {
  obj[relationship.id] = relationship;
  return obj;
}

function getRelsPerTypeMapper(visibleRelsIds: Relationship['id'][], relsInventory: RelationshipsState['inventory']) {
  return reduce(
    relsInventory,
    (types, relationship) => (types.includes(relationship.type) ? types : [...types, relationship.type]),
    [] as string[],
  ).reduce<RelationshipsState['relsPerType']>(
    (obj, type) => ({
      [type]: visibleRelsIds.filter((id) => relsInventory[id]?.type === type),
      ...obj,
    }),
    {},
  );
}

function getVisibleRelsMapper(visibleRelsIds: Relationship['id'][], relsInventory: RelationshipsState['inventory']) {
  return visibleRelsIds.reduce<Relationship[]>((acc, id) => {
    const rel = relsInventory[id];
    if (isNil(rel)) return acc;
    return [...acc, rel];
  }, []);
}

export const getSelectedRelationships = createSelector(
  getRelationships,
  getSelectedRelationshipMap,
  (relationships, selectedRelationships) => {
    return Object.keys(selectedRelationships).map((id) => relationships[id]);
  },
);

export const getVisibleRelationshipTypePropertyGroups = createSelector(
  getRelationships,
  getVisibleRelsPerType,
  (relInventory, visRelsByType) => {
    const visibleRelTypePropertyGroups: Record<string, Record<string, string[]>> = {};

    Object.keys(visRelsByType).forEach((relType) => {
      const rels = visRelsByType[relType];
      if (isNil(rels)) return;
      rels
        .reduce<Relationship[]>((acc, relId) => {
          const rel = relInventory[relId];
          return rel ? [...acc, rel] : acc;
        }, [])
        .forEach((rel) => {
          Object.keys(rel.properties).forEach((prop) => {
            if (isNil(visibleRelTypePropertyGroups[relType])) {
              visibleRelTypePropertyGroups[relType] = {};
            }
            if (isNil(visibleRelTypePropertyGroups[relType][prop])) {
              visibleRelTypePropertyGroups[relType][prop] = [];
            }
            visibleRelTypePropertyGroups[relType][prop].push(rel.id);
          });
        });
    });
    return visibleRelTypePropertyGroups;
  },
);

export const makeSelectMappedProperties = () =>
  createSelector(
    getRelationships,
    getVisibleRelsPerType,
    (state: RootState, relationshipType: Relationship['type']) => relationshipType,
    (state: RootState, categoryId: string, propertyName: string) => propertyName,
    (relInventory, visibleRelsPerType, relationshipType, propertyName) => {
      const values: MappedProperty[] = [];

      const visibleRelIds = visibleRelsPerType[relationshipType];
      if (!isNil(visibleRelIds)) {
        visibleRelIds
          .map((relId) => relInventory[relId])
          .forEach((rel) => {
            const value = rel?.mappedProperties?.[propertyName];
            if (isNil(value)) return;
            values.push(value);
          });
      }
      return values;
    },
  );

export const getPropertyValuesOfVisibleRelationships = createSelector(
  getRelationships,
  getVisibleRelsPerType,
  (relInventory, visibleRelsPerType) => (relationshipType: Relationship['type'] | null, propertyKey: string | null) => {
    if (isNil(relationshipType) || isNil(propertyKey)) return [];
    const result: string[] = [];
    const visRelsByType = visibleRelsPerType[relationshipType];

    if (isNil(visRelsByType) || visRelsByType?.length === 0) return result;

    visRelsByType
      .map((relId) => relInventory[relId])
      .forEach((rel) => {
        const prop = rel?.properties?.[propertyKey];
        if (!isNil(prop) && prop.length > 0) result.push(prop);
      });
    return uniq(result);
  },
);

const relationshipsSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setRelationshipIndex(state, action: PayloadAction<Relationship[]>) {
      if (isNil(action.payload)) return;
      const relationships = action.payload;
      const { selected, disabled } = state;
      const updatedRelationshipIds = relationships.map((relationship) => relationship.id);
      const newSelected: RelationshipsState['selected'] = {};
      updatedRelationshipIds.forEach((id) => {
        if (selected[id] && !disabled[id]) newSelected[id] = true;
      });
      const relsInventory = relationships.reduce(reduceRelationship, {});
      const visibleRelationshipsList = getVisibleRelsMapper(updatedRelationshipIds, relsInventory);
      const relsPerType = getRelsPerTypeMapper(updatedRelationshipIds, relsInventory);

      state.inventory = relsInventory;
      state.visible = updatedRelationshipIds;
      state.visibleRels = visibleRelationshipsList;
      state.relsPerType = relsPerType;
      state.selected = newSelected;
    },
    addToRelationshipInventory(state, action: PayloadAction<Relationship[]>) {
      const relationships = action.payload;
      if (isNil(relationships)) return;
      state.inventory = relationships.reduce(reduceRelationship, { ...state.inventory });
    },
    removeFromRelationshipInventory(state, action: PayloadAction<Relationship['id'][]>) {
      const relationshipIds = action.payload;
      if (isNil(relationshipIds)) {
        return;
      }
      const { inventory, selected, disabled, visible } = state;
      const relsInventory = inventory;

      relationshipIds.forEach((relId) => {
        delete relsInventory[relId];
      });
      const relList = Object.values(relsInventory);

      const newSelected = { ...selected };
      const newDisabled = { ...disabled };
      relationshipIds.forEach((id) => {
        delete newSelected[id];
        delete newDisabled[id];
      });
      const visibleIds = visible.filter((visibleId) => !relationshipIds.includes(visibleId));
      const visibleRelationshipsList = getVisibleRelsMapper(visibleIds, relsInventory);

      state.inventory = relList.reduce(reduceRelationship, {});
      state.visible = visibleIds;
      state.visibleRels = visibleRelationshipsList;
      state.selected = newSelected;
      state.disabled = newDisabled;
    },
    addToVisibleRelationships(state, action: PayloadAction<Relationship['id'][]>) {
      const relationshipIds = action.payload;
      if (isNil(relationshipIds) || idArraysAreEqual(state.visible, relationshipIds)) {
        return;
      }
      const visibleIds = union(state.visible, relationshipIds);
      const visibleRelationshipsList = getVisibleRelsMapper(visibleIds, state.inventory);
      const relsPerType = getRelsPerTypeMapper(visibleIds, state.inventory);

      state.visible = visibleIds;
      state.visibleRels = visibleRelationshipsList;
      state.relsPerType = relsPerType;
    },
    removeFromVisibleRelationships(state, action: PayloadAction<Relationship['id'][]>) {
      const relationshipIds = action.payload;
      if (isNil(relationshipIds)) {
        return;
      }

      const newVisibleIds = difference(state.visible, relationshipIds);
      const newVisibleRelationshipsList = getVisibleRelsMapper(newVisibleIds, state.inventory);
      const newRelsPerType = getRelsPerTypeMapper(newVisibleIds, state.inventory);

      state.visible = newVisibleIds;
      state.visibleRels = newVisibleRelationshipsList;
      state.relsPerType = newRelsPerType;
    },
    refreshRelationshipPerTypes(state) {
      state.relsPerType = getRelsPerTypeMapper(state.visible, state.inventory);
    },
    selectRelationship(state, action: PayloadAction<Relationship['id']>) {
      const relationshipId = action.payload;
      if (isNil(relationshipId) || state.selected[relationshipId]) {
        return;
      }

      const newSelected = { ...state.selected };
      if (!state.disabled[relationshipId]) {
        newSelected[relationshipId] = true;
      } else {
        delete newSelected[relationshipId];
      }

      state.selected = newSelected;
    },
    selectRelationships(state, action: PayloadAction<Relationship['id'][]>) {
      const relationshipIds = action.payload;
      if (isNil(relationshipIds)) {
        return;
      }
      const newSelected = { ...state.selected };
      relationshipIds.forEach((id) => {
        if (!state.disabled[id]) {
          newSelected[id] = true;
        } else {
          delete newSelected[id];
        }
      });
      state.selected = newSelected;
    },
    deselectRelationship(state, action: PayloadAction<Relationship['id']>) {
      const relationshipId = action.payload;
      if (isNil(relationshipId)) {
        return;
      }
      const newSelected = { ...state.selected };
      delete newSelected[relationshipId];

      state.selected = newSelected;
    },
    deselectRelationships(state, action: PayloadAction<Relationship['id'][]>) {
      const relationshipIds = action.payload;
      if (isNil(relationshipIds)) {
        return;
      }
      const newSelected = { ...state.selected };
      relationshipIds.forEach((id) => delete newSelected[id]);

      state.selected = newSelected;
    },
    deselectAll(state) {
      state.selected = {};
    },
    setSelectedRelationships(state, action: PayloadAction<Relationship['id'][]>) {
      const relationshipIds = action.payload;
      if (isNil(relationshipIds)) {
        return;
      }

      const currentSelected = Object.keys(state.selected);
      if (
        relationshipIds.length === currentSelected.length &&
        relationshipIds.reduce<boolean>((acc, val) => acc && !!state.selected[val], true)
      ) {
        return;
      }

      const newSelected: RelationshipsState['selected'] = {};
      relationshipIds.forEach((id) => {
        if (!state.disabled[id]) {
          newSelected[id] = true;
        } else {
          delete newSelected[id];
        }
      });

      state.selected = newSelected;
    },
    disableRelationships(state, action: PayloadAction<Relationship['id'][]>) {
      const relationshipIds = action.payload;
      if (isNil(relationshipIds)) {
        return;
      }
      const newDisabled: RelationshipsState['disabled'] = {};
      const newSelected = { ...state.selected };
      relationshipIds.forEach((id) => {
        newDisabled[id] = true;
        delete newSelected[id];
      });

      state.disabled = newDisabled;
      state.selected = newSelected;
    },
    enableRelationships(state, action: PayloadAction<Relationship['id'][]>) {
      const relationshipIds = action.payload;
      if (isNil(relationshipIds)) {
        return;
      }
      const newDisabled = { ...state.disabled };
      relationshipIds.forEach((id) => delete newDisabled[id]);

      state.disabled = newDisabled;
    },
    clearDisabled(state) {
      state.disabled = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action: AnyAction) => {
      const { payload } = action;
      if (isNil(payload) || isNil(payload[GRAPH])) return { ...state };
      const relationships = payload[GRAPH].present[NAME];

      return {
        ...state,
        ...relationships,
      };
    });
    builder.addCase(CLEAR_SCENE, () => initialState);
    builder.addCase(updateInventory, (state, action) => {
      const { relationships } = action.payload;
      if (isNil(relationships)) return;
      const { selected, disabled } = state;
      const updatedRelationshipIds = relationships.map((relationship) => relationship.id);
      const newSelected: RelationshipsState['selected'] = {};
      updatedRelationshipIds.forEach((id) => {
        if (selected[id] && !disabled[id]) newSelected[id] = true;
      });
      const relsInventory = relationships.reduce(reduceRelationship, {});
      const visibleRelationshipsList = getVisibleRelsMapper(updatedRelationshipIds, relsInventory);
      const relsPerType = getRelsPerTypeMapper(updatedRelationshipIds, relsInventory);

      state.inventory = relsInventory;
      state.visible = updatedRelationshipIds;
      state.visibleRels = visibleRelationshipsList;
      state.relsPerType = relsPerType;
      state.selected = newSelected;
    });
    builder.addCase(addToInventory, (state, action) => {
      const { relationships } = action.payload;
      if (isNil(relationships)) return;
      state.inventory = relationships.reduce(reduceRelationship, { ...state.inventory });
    });
    builder.addCase(addToVisible, (state, action) => {
      const { relationshipIds } = action.payload;
      if (isNil(relationshipIds) || idArraysAreEqual(state.visible, relationshipIds)) {
        return;
      }
      const visibleIds = union(state.visible, relationshipIds);
      const visibleRelationshipsList = getVisibleRelsMapper(visibleIds, state.inventory);
      const relsPerType = getRelsPerTypeMapper(visibleIds, state.inventory);

      state.visible = visibleIds;
      state.visibleRels = visibleRelationshipsList;
      state.relsPerType = relsPerType;
    });
    builder.addCase(removeFromVisible, (state, action) => {
      const { relationshipIds, nodeIds } = action.payload;
      if (isNil(relationshipIds)) return;
      const { inventory, visible, visibleRels, selected, disabled } = state;
      const relsInventory = inventory;

      const newSelected = { ...selected };
      const newDisabled = { ...disabled };
      relationshipIds.forEach((id) => {
        delete newSelected[id];
        delete newDisabled[id];
      });

      const visibleRelsIds = visible.filter((current) => {
        const relationship = relsInventory[current];
        return !(
          relationshipIds.includes(current) ||
          (nodeIds?.includes(relationship?.startId as string) ?? false) ||
          (nodeIds?.includes(relationship?.endId as string) ?? false)
        );
      });
      const relsPerType = getRelsPerTypeMapper(visibleRelsIds, relsInventory);

      state.visible = visibleRelsIds;
      state.visibleRels = visibleRels.filter(
        (currentRel) =>
          !(
            relationshipIds.includes(currentRel.id) ||
            (nodeIds?.includes(currentRel.startId) ?? false) ||
            (nodeIds?.includes(currentRel.endId) ?? false)
          ),
      );
      state.selected = newSelected;
      state.disabled = newDisabled;
      state.relsPerType = relsPerType;
    });
    builder.addCase(graphDeselectAll, (state) => {
      state.selected = {};
    });
    builder.addCase(setSelected, (state, action) => {
      const { relationshipIds } = action.payload;
      if (isNil(relationshipIds)) {
        return;
      }

      const currentSelected = Object.keys(state.selected);
      if (
        relationshipIds.length === currentSelected.length &&
        relationshipIds.reduce<boolean>((acc, val) => acc && !!state.selected[val], true)
      ) {
        return;
      }

      const newSelected: RelationshipsState['selected'] = {};
      relationshipIds.forEach((id) => {
        if (!state.disabled[id]) {
          newSelected[id] = true;
        } else {
          delete newSelected[id];
        }
      });
      state.selected = newSelected;
    });
    builder.addCase(invertSelection, (state) => {
      const { selected, disabled, visible } = state;

      if (Object.keys(selected).length > 0 || visible?.length > 0) {
        const invertedSelected: RelationshipsState['selected'] = {};
        visible.forEach((id) => {
          if (!selected[id] && !disabled[id]) invertedSelected[id] = true;
        });
        state.selected = invertedSelected;
        state.selectedArray = Object.keys(invertedSelected);
      }
    });
    builder.addCase(addToExpanded, (state, action) => {
      const { relationshipIds } = action.payload;
      if (isNil(relationshipIds)) return;
      const newExpanded = {
        ...state.expanded,
        ...relationshipIds.reduce<Record<string, boolean>>((map, relId) => {
          map[relId] = true;
          return map;
        }, {}),
      };
      state.expanded = newExpanded;
    });
    builder.addCase(clearExpanded, (state) => {
      state.expanded = {};
    });
    builder.addCase(purgeInvisibleItems, (state) => {
      const { inventory, visible, expanded } = state;
      const visibleMap = visible.reduce<Record<string, boolean>>((map, id) => {
        map[id] = true;
        return map;
      }, {});

      const newInventory: RelationshipsState['inventory'] = {};

      Object.keys(inventory).forEach((id) => {
        if (visibleMap[id] ?? expanded[id]) {
          const item = inventory[id];
          if (!item) return;
          newInventory[id] = item;
        }
      });

      state.inventory = newInventory;
    });
  },
});

export const {
  setRelationshipIndex,
  addToRelationshipInventory,
  removeFromRelationshipInventory,
  addToVisibleRelationships,
  removeFromVisibleRelationships,
  refreshRelationshipPerTypes,
  selectRelationship,
  selectRelationships,
  deselectRelationship,
  deselectRelationships,
  deselectAll,
  setSelectedRelationships,
  disableRelationships,
  enableRelationships,
  clearDisabled,
} = relationshipsSlice.actions;

export default relationshipsSlice.reducer;

export function getRelationshipBetweenNodes(
  relationshipInventory: RelationshipsState['inventory'],
  sourceNodeId: Node['id'],
  targetNodeId: Node['id'],
  matchDirection = false,
) {
  let result = null;
  Object.keys(relationshipInventory).forEach((relKey) => {
    const relationship = relationshipInventory[relKey];
    if (
      (relationship?.startId === sourceNodeId && relationship?.endId === targetNodeId) ||
      (!matchDirection && relationship?.endId === sourceNodeId && relationship?.startId === targetNodeId)
    ) {
      result = relationship;
    }
  });
  return result;
}
