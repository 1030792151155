import type { Scene } from '../../types/scene';

export const getAllScenesQuery = (perspectiveId: string, username: string, userRoles: string[] = []) => ({
  cypher: `
    MATCH (n:\`_Bloom_Perspective_\`)-[r:\`_Bloom_HAS_SCENE_\`]->(s:\`_Bloom_Scene_\`)
    WHERE n.id = $perspectiveId
      AND (s.createdBy = $username OR (any(role IN $userRoles WHERE role IN s.roles )))
    RETURN s`,
  parameters: {
    perspectiveId,
    username,
    userRoles,
  },
});

export const getPerspectiveAndScenePermissionQuery = (sceneId: string, username: string, userRoles: string[] = []) => ({
  cypher: `
  MATCH (n:\`_Bloom_Perspective_\`)-[r:\`_Bloom_HAS_SCENE_\`]->(s:\`_Bloom_Scene_\`)
  WHERE s.id = $sceneId
  RETURN 
    n.id AS perspectiveId,
    s.createdBy = $username OR (any(role IN $userRoles WHERE role IN s.roles )) AS userCanAccessScene`,
  parameters: {
    sceneId,
    username,
    userRoles,
  },
});

export const saveNewSceneQuery = (perspectiveId: string, scene: Scene) => ({
  cypher: `MATCH (n:\`_Bloom_Perspective_\`)
    WHERE n.id = $perspectiveId
    CREATE (n)-[:\`_Bloom_HAS_SCENE_\`]->(s:\`_Bloom_Scene_\`)
    SET s.id = $sceneId,
        s.name = $name,
        s.nodesv2 = '[]',
        s.relationshipsv2 = '[]',
        s.style = $style,
        s.filter = null, 
        s.ranges = null,
        s.numOfNodes = 0,
        s.numOfRels = 0,
        s.createdBy = $createdBy,
        s.createdAt = ${Date.now()},
        s.lastModified = ${Date.now()},
        s.version = $version,
        s.roles = []
    RETURN s`,
  parameters: {
    name: scene.name,
    perspectiveId,
    sceneId: scene.id,
    createdBy: scene.createdBy,
    version: scene.version,
    style: JSON.stringify(scene.style ?? {}, null, 0),
  },
});

export const duplicateSceneQuery = (
  perspectiveId: string,
  fromSceneId: string,
  sceneId: string,
  username: string,
  currentDatetime: Date,
) => ({
  cypher: `
    MATCH (n:\`_Bloom_Perspective_\`)-[:\`_Bloom_HAS_SCENE_\`]->(s1:\`_Bloom_Scene_\`)
    WHERE n.id = $perspectiveId AND s1.id = $fromSceneId
    CREATE (n)-[:\`_Bloom_HAS_SCENE_\`]->(s:\`_Bloom_Scene_\`)
    SET s = s1,
        s.id = $sceneId,
        s.name = s1.name + ' (Copy)',
        s.createdBy = $username,
        s.createdAt = ${currentDatetime.getTime()},
        s.lastModified = ${currentDatetime.getTime()},
        s.style = replace(s.style, $fromSceneId, $sceneId),
        s.roles = []
    RETURN s`,
  parameters: {
    perspectiveId,
    sceneId,
    fromSceneId,
    username,
    currentDatetime,
  },
});

export const deleteSceneQuery = (perspectiveId: string, sceneId: string) => ({
  cypher: `MATCH (n:\`_Bloom_Perspective_\`)-[r:\`_Bloom_HAS_SCENE_\`]->(s:\`_Bloom_Scene_\`)
    WHERE n.id = $perspectiveId AND s.id = $sceneId
    DELETE r,s`,
  parameters: {
    perspectiveId,
    sceneId,
  },
});

export const deleteScenesFromPerspectiveQuery = (perspectiveId: string) => ({
  cypher: `MATCH (n:\`_Bloom_Perspective_\`)-[r:\`_Bloom_HAS_SCENE_\`]->(s:\`_Bloom_Scene_\`)
    WHERE n.id = $perspectiveId
    DELETE r,s`,
  parameters: {
    perspectiveId,
  },
});

export const updateSceneQuery = (scene: Scene) => {
  // Saving in nodesv2 and relationshipsv2 in order to keep the old ids in "nodes" and "relationships"
  // we will migrate in the future to nodes / relationships when the migration has been done (2.20+)

  const cleanCoordinates = (key: string, value: number) => (key === 'x' || key === 'y' ? Math.round(value) : value);
  const nodesv2 = JSON.stringify(scene.nodes ?? [], cleanCoordinates);
  const jsonProperties = [];
  const relationshipsv2 = JSON.stringify(scene.relationships ?? []);
  const filters = JSON.stringify(scene.filters ?? []);
  const ranges = JSON.stringify(scene.ranges ?? []);
  const gds = JSON.stringify(scene.gds ?? []);
  const visualisation = JSON.stringify(scene.visualisation ?? {});
  const style = JSON.stringify(scene.style ?? {});

  scene.nodes != null && jsonProperties.push('nodesv2');
  scene.relationships != null && jsonProperties.push('relationshipsv2');
  scene.filters != null && jsonProperties.push('filters');
  scene.ranges != null && jsonProperties.push('ranges');
  scene.gds != null && jsonProperties.push('gds');
  scene.visualisation != null && jsonProperties.push('visualisation');
  scene.style != null && jsonProperties.push('style');

  let propertyQuery = '';
  if (jsonProperties.length > 0) {
    propertyQuery = `, ${jsonProperties.map((prop) => `s.${prop} = $${prop}`).join(',')}`;
  }

  // TODO: remove scrubbedOutNodeIds, scrubbedOutRelsIds on 2.8.0 release
  return {
    cypher: `MATCH (s:\`_Bloom_Scene_\`)
      WHERE s.id = $id
      SET s.name = $name,
          s.numOfNodes = ${scene.numOfNodes},
          s.numOfRels = ${scene.numOfRels},
          s.createdBy = $createdBy,
          s.createdAt = ${scene.createdAt.getTime()},
          s.lastModified = ${Date.now()},
          s.version = $version,
          s.roles = $roles,
          s.scrubbedOutNodeIds = null,
          s.scrubbedOutRelsIds = null
          ${propertyQuery}
      RETURN s
    `,
    parameters: {
      id: scene.id,
      name: scene.name,
      createdBy: scene.createdBy,
      roles: scene.roles ?? [],
      version: scene.version,
      visualisation,
      nodesv2,
      relationshipsv2,
      filters,
      ranges,
      gds,
      style,
    },
  };
};

export const getFullSceneQuery = (sceneId: string) => ({
  cypher: `
  MATCH (s:\`_Bloom_Scene_\`)
  WHERE s.id = $id
  RETURN s
  `,
  parameters: {
    id: sceneId,
  },
});

export const checkSceneWritePermissionQuery = () => ({
  cypher: `
  CREATE (s:\`_Bloom_Scene_\`)
  SET s.id = 'test'
  SET s.name = 'test'
  SET s.nodes = []
  SET s.relationships = []
  SET s.style = ''
  SET s.visualisation = ''
  RETURN s
  `,
  parameters: {},
});
