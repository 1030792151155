import { customCreateApi } from '../../context';
import { createDynamicBaseQuery } from '../../utils/create-dynamic-base-query';
import { getAuraManagementApiToken } from '../../utils/get-aura-management-api-token';
import { selectAuraConfiguration } from '../configuration/configuration-slice';
import { getAccessToken } from '../session-slice';
import { authEndpoints } from './endpoints/auth';
import { cmiEndpoints } from './endpoints/cmi';
import { logsEndpoints } from './endpoints/logs';
import { metricsEndpoints } from './endpoints/metrics';
import { migrationEndpoints } from './endpoints/migration';
import type { Builder } from './endpoints/types';
import { tagTypes } from './endpoints/types';

/**
 * In UPX development, Vite Proxy facilitates direct API requests to individual
 * upstream services (e.g., ops-manager-api, console-api), bypassing app-ingress.
 * To access ops-manager-api, a scoped token must be fetched using the Auth0 token.
 * This scoped token, typically generated by app-ingress, is required for API requests.
 */
const useViteProxy = import.meta.env.VITE_AURA_MANAGEMENT_API_PROXY === '1';

const opsApi = customCreateApi({
  baseQuery: createDynamicBaseQuery((state) => selectAuraConfiguration(state)?.opsManagerApiUrl, {
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('Authorization', `Bearer ${accessToken}`);

      if (useViteProxy) {
        const projectId = headers.get('project-id');
        const appIngressToken = await getAuraManagementApiToken(accessToken, projectId);
        headers.delete('Authorization');
        headers.set('Authorization', `Bearer ${appIngressToken}`);
      }
    },
  }),
  reducerPath: 'opsApi',
  tagTypes,
  endpoints: (builder: Builder) => ({
    ...authEndpoints(builder),
    ...logsEndpoints(builder),
    ...metricsEndpoints(builder),
    ...migrationEndpoints(builder),
    ...cmiEndpoints(builder),
  }),
});

export default opsApi;
