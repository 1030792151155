import type { MetricValue, MetricsByNameAndInstanceId, MetricsData, TimeRange } from '@nx/state';
import { formatDuration, intervalToDuration } from 'date-fns';
import uPlot from 'uplot';

import { metricKey } from '../../shared/utils';
import type { ChartPathType, ChartWidgetPartialProps } from '../charts/fullstack-chart-props';
import type { MultiChartProps } from '../charts/types';
import { getSeriesColor } from '../hooks/use-series-colors';
import { COLOR_PALETTE_ENUM } from './color-palettes';
import { nonNullable } from './typeguards';

export const calculatePercentage = (value: number, maxValue?: number): number => {
  if (nonNullable(maxValue)) {
    return Math.min((value / maxValue) * 100, 100);
  }
  return 0;
};

const seriesOptions = (
  seriesKey: string,
  aggregationMeta: uPlot.Series['aggregationMeta'],
  colorPalette: COLOR_PALETTE_ENUM,
  chartPathType: ChartPathType = 'LINE',
): uPlot.Series => {
  const s: uPlot.Series = {
    label: seriesKey,
    stroke: getSeriesColor(colorPalette)(seriesKey),
    aggregationMeta,
  };
  if (chartPathType === 'BAR') {
    s.paths = uPlot.paths.bars?.({ size: [0.6, 1, 1], align: 1 });
    s.points = { show: false };
  } else if (chartPathType === 'AREA') {
    s.points = { size: 5 };
    s.fill = `${getSeriesColor(colorPalette)(seriesKey)}20`;
  } else {
    s.points = { size: 5 };
  }
  return s;
};

export const sortedAggregations = [
  { id: 'SUM', name: 'SUM', displayName: 'Sum' },
  { id: 'MAX', name: 'MAX', displayName: 'Max' },
  { id: 'AVG', name: 'AVG', displayName: 'Average' },
  { id: 'MIN', name: 'MIN', displayName: 'Min' },
  { id: 'base-series', name: 'default', displayName: 'default' },
  {
    id: 'additional-series',
    name: 'default additional',
    displayName: 'default additional',
  },
];

export const getPills = (
  chartProps: MultiChartProps,
  useAZGrouping: boolean,
  metrics?: MetricsByNameAndInstanceId | null,
  showLeaderOnly?: boolean,
) => {
  const chartPropsList = Array.isArray(chartProps) ? chartProps : [chartProps];
  const additionalSeriesNames = chartPropsList.flatMap((chartProp) => {
    return chartProp.additionalMetric && !useAZGrouping
      ? [
          {
            id: chartProp.subtitle ?? chartProp.title,
            name: chartProp.seriesName ?? chartProp.title,
            displayName: chartProp.seriesName ?? chartProp.title,
          },
          {
            id: chartProp.additionalMetric.subtitle ?? chartProp.additionalMetric.title,
            name: chartProp.additionalMetric.seriesName ?? chartProp.additionalMetric.title,
            displayName: chartProp.additionalMetric.seriesName ?? chartProp.title,
          },
        ]
      : [];
  });
  const pills = [...additionalSeriesNames];
  const [chartProp] = chartPropsList;
  const dataSeries = chartProp && metrics?.[metricKey(chartProp, useAZGrouping)]?.dataSeries;
  if (useAZGrouping && chartProp && dataSeries && !additionalSeriesNames.length) {
    pills.push(
      ...Object.keys(dataSeries)
        .filter((az) => !sortedAggregations.some((sa) => sa.id === az))
        .filter((az) => (nonNullable(showLeaderOnly) && showLeaderOnly ? !az.includes('Secondaries') : true))
        .map((az) => ({
          id: az,
          name: az,
          displayName: az,
        })),
    );
    pills.sort((pill1, pill2) => pill1.displayName.localeCompare(pill2.displayName));
  } else {
    pills.push(...sortedAggregations);
  }
  return pills;
};

export const getMostRecentRefMetric = (
  idx: number,
  referenceMetrics: MetricValue[],
  timestamp: number,
): [number, number | null] => {
  if (timestamp < (referenceMetrics[idx]?.timestamp ?? 0) * 1000) {
    return [idx, null];
  }
  if (referenceMetrics[idx + 1] && timestamp >= (referenceMetrics[idx + 1]?.timestamp ?? 0) * 1000) {
    return [idx + 1, referenceMetrics[idx + 1]?.value ?? null];
  }
  return [idx, referenceMetrics[idx]?.value ?? null];
};

export const interpolateRefData = (
  tss: [number, (number | null)[]][],
  referenceMetrics: MetricValue[],
  referenceSeriesIdx: number,
) => {
  let referenceMetricIdx = 0;

  tss.forEach((ts) => {
    const result = getMostRecentRefMetric(referenceMetricIdx, referenceMetrics, ts[0]);
    referenceMetricIdx = result[0];

    const [, vectorRef] = ts;
    vectorRef[referenceSeriesIdx] = result[1];
  });
};

/**
 * We need to format data based on these reqs:
 * https://github.com/leeoniya/uPlot/blob/master/docs/README.md#data-format
 */
export const formatUplot = (
  metricsData: MetricsData,
  timeRange: TimeRange,
  chartProps: ChartWidgetPartialProps,
): {
  alignedData: uPlot.AlignedData;
  /**
   * Ordered metadata based on series keys
   * to update uPlot settings
   */
  seriesOptionsList: uPlot.Series[];
} => {
  const { refSeries } = metricsData;
  const { transformerSeries } = metricsData;
  const hasRefSeries = Boolean(chartProps.referenceMetricConfig) && Boolean(refSeries);
  const hasSingleRefSeries = hasRefSeries && Array.isArray(refSeries);
  const hasMultipleRefSeries = hasRefSeries && !Array.isArray(refSeries);
  const hasTransformerSeries = Boolean(chartProps.transformerMetricConfig) && nonNullable(transformerSeries);
  // Get all series keys except the ones that are ignored
  const seriesKeys = Object.keys(metricsData.dataSeries ?? {}).sort((key1, key2) => {
    const aggIndex1 = sortedAggregations.findIndex((agg) => agg.id === key1);
    const aggIndex2 = sortedAggregations.findIndex((agg) => agg.id === key2);

    return aggIndex1 >= 0 && aggIndex2 >= 0 ? aggIndex1 - aggIndex2 : 0;
  });

  const palette = chartProps.colorPalette ?? COLOR_PALETTE_ENUM.Standard;

  const numSeries = hasRefSeries ? seriesKeys.length + (hasSingleRefSeries ? 1 : seriesKeys.length) : seriesKeys.length;

  const seriesOptionsList: uPlot.Series[] = [];

  // The timeMap will hold all the unique timestamps
  // and respective values for each series
  const timeMap = new Map<number, (number | null)[]>();

  // Initialize timeMap with start and end times
  timeMap.set(timeRange.startTime.valueOf(), new Array<number | null>(numSeries).fill(null));
  timeMap.set(timeRange.endTime.valueOf(), new Array<number | null>(numSeries).fill(null));

  seriesKeys.forEach((seriesKey, seriesIdx) => {
    const aggregationMeta: uPlot.Series['aggregationMeta'] = {};
    const referenceSeriesIdx = seriesIdx + seriesKeys.length;
    let referenceMetricIdx = 0;

    metricsData.dataSeries?.[seriesKey]?.forEach((metric) => {
      const { timestamp, value, metaValue } = metric;

      const timestampMs = (timestamp ?? 0) * 1000;

      if (!timeMap.has(timestampMs)) {
        // Initialize a null vector for the specific timestamp
        timeMap.set(timestampMs, new Array<number | null>(numSeries).fill(null));
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const vectorRef = timeMap.get(timestampMs)!;

      if (hasTransformerSeries && chartProps.transformerMetricConfig) {
        vectorRef[seriesIdx] = chartProps.transformerMetricConfig.transform(transformerSeries[seriesKey], metric);
      } else {
        vectorRef[seriesIdx] = value ?? null;
      }

      if (hasMultipleRefSeries && refSeries?.[seriesKey]) {
        const referenceMetrics = refSeries[seriesKey];
        const result = getMostRecentRefMetric(referenceMetricIdx, referenceMetrics, timestampMs);
        referenceMetricIdx = result[0];

        if (hasTransformerSeries && chartProps.transformerMetricConfig && result[1] !== null) {
          vectorRef[referenceSeriesIdx] = chartProps.transformerMetricConfig.transform(
            transformerSeries[seriesKey] ?? [],
            referenceMetrics[referenceMetricIdx],
          );
        } else {
          vectorRef[referenceSeriesIdx] = result[1];
        }
      }
      if (metaValue) {
        aggregationMeta[timestamp ?? 0] = metaValue;
      }
    });
    seriesOptionsList[seriesIdx] = seriesOptions(seriesKey, aggregationMeta, palette, chartProps.chartPathType);
    if (hasMultipleRefSeries) {
      const refSeriesLabel = seriesKey;
      seriesOptionsList[referenceSeriesIdx] = {
        label: refSeriesLabel,
        dash: [10, 5],
        referenceSeries: { isReference: true, othersIndex: seriesIdx },
      };

      const seriesOpts = seriesOptionsList[seriesIdx];
      seriesOpts.referenceSeries = {
        isReference: false,
        stroke: getSeriesColor(palette)(refSeriesLabel),
        othersIndex: referenceSeriesIdx,
      };
    }
  });
  // Ascending sorting
  const allOrderedTimestampEntries = Array.from(timeMap.entries()).sort((a, b) => a[0] - b[0]);
  if (hasSingleRefSeries && refSeries.length > 0) {
    const referenceSeriesIdx = seriesKeys.length;

    const referenceMetrics = refSeries;
    interpolateRefData(allOrderedTimestampEntries, referenceMetrics, referenceSeriesIdx);
    const refSeriesLabel = 'reference';
    seriesOptionsList[referenceSeriesIdx] = {
      label: refSeriesLabel,
      dash: [10, 5],
      stroke: getSeriesColor(palette)(refSeriesLabel),
      referenceSeries: { isReference: true, othersIndex: 0 },
    };
    seriesKeys.forEach((_, i) => {
      const seriesOpt = seriesOptionsList[i];
      if (seriesOpt !== undefined) {
        seriesOpt.referenceSeries = {
          isReference: false,
          othersIndex: referenceSeriesIdx,
        };
      }
    });
  }

  // N Rows: Timestamps + the number of series
  // M Columns: Each unique timestamp
  // Avoid using .fill here because all the rows will be with the exact same reference:
  // https://stackoverflow.com/a/38940598
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const alignedData = Array.from({ length: numSeries + 1 }, () =>
    Array.from<unknown, null | number>({ length: timeMap.size }, () => null),
  ) as uPlot.AlignedData;

  allOrderedTimestampEntries.forEach((timestampEntry, x) => {
    // eslint-disable-next-line prefer-destructuring
    const numValues = alignedData[0];
    const [timestamp, values] = timestampEntry;
    numValues[x] = timestamp;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    for (let y = 0; y < numSeries; y += 1) {
      const alignedValues = alignedData[y + 1];
      if (alignedValues) {
        alignedValues[x] = values[y] ?? null;
      }
    }
  });

  return {
    alignedData,
    seriesOptionsList,
  };
};

export const syncCursor = (key: string) =>
  ({
    sync: {
      key,
      setSeries: true,
    },
  }) satisfies uPlot.Cursor;

export const analyticsPropsFromTimeRange = ({ startTime: start, endTime: end }: TimeRange) => ({
  start: start.toISOString(),
  end: end.toISOString(),
  duration: formatDuration(intervalToDuration({ start, end })),
});
