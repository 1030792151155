import { Label } from '@neo4j-ndl/react';
import type { EncryptionKey, Project } from '@nx/state';
import { ENCRYPTION_KEY_STATUS } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { DataGridHelpers } from '@nx/ui';
import { createColumnHelper, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo } from 'react';

import { getCloudProviders } from '../entities/helpers';
import { DeleteEncryptionKey } from './delete-encryption-key';
import type { CmekOptions } from './entities/helpers';
import { encryptionKeyToOptions, keyHeader } from './entities/helpers';
import { GetKeyPermissions } from './get-key-permissions';

type CmekTableProps = {
  project: Project;
  cmeks: EncryptionKey[];
  isLoading: boolean;
};

type EncryptionKeyStatusLabelProps = {
  value: ENCRYPTION_KEY_STATUS;
};

const columnHelper = createColumnHelper<CmekOptions>();

const EncryptionKeyStatusLabel = ({ value }: EncryptionKeyStatusLabelProps) => {
  let color: 'default' | 'danger' | 'success';

  if (value === ENCRYPTION_KEY_STATUS.READY) {
    color = 'success';
  } else if (value === ENCRYPTION_KEY_STATUS.PENDING) {
    color = 'default';
  } else {
    color = 'danger';
  }

  return (
    <Label fill="clean" color={color} hasIcon>
      {value}
    </Label>
  );
};

export const CmekTable = ({ project, cmeks }: CmekTableProps) => {
  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
      }),
      columnHelper.accessor('region', {
        header: 'Region',
        minSize: 200,
      }),
      columnHelper.accessor('product', {
        header: 'Instance Type',
      }),
      columnHelper.accessor('key', {
        header: keyHeader(getCloudProviders(project.tierConfigs)[0]),
        minSize: 400,
      }),
      columnHelper.accessor('created', {
        header: 'Created',
      }),
      columnHelper.accessor('status', {
        cell: (c) => {
          const status = c.getValue();
          return <EncryptionKeyStatusLabel value={status} />;
        },
        header: 'Status',
      }),
      columnHelper.display({
        id: 'actions',
        cell: (c) => {
          const key = c.row.original;
          const encryptionKey = cmeks.find((value) => value.name === key.name);
          return (
            <div className="flex w-full justify-end gap-3">
              {isNotNullish(encryptionKey) && (
                <>
                  <GetKeyPermissions project={project} encryptionKey={encryptionKey} />
                  <DeleteEncryptionKey project={project} encryptionKey={encryptionKey} />
                </>
              )}
            </div>
          );
        },
        size: 120,
        maxSize: 120,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cmeks],
  );

  const data = useMemo(() => encryptionKeyToOptions(cmeks, project), [cmeks, project]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      columnPinning: {
        right: ['actions'],
      },
    },
  });

  return (
    <DataGridHelpers.Wrapper>
      <DataGridHelpers.DataGridRightColumnPinned<CmekOptions>
        tableInstance={table}
        isResizable={false}
        styling={{ headerStyle: 'clean', borderStyle: 'horizontal' }}
      />
    </DataGridHelpers.Wrapper>
  );
};
