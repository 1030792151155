import type { OpsTypes } from '@nx/state';
import { useMemo } from 'react';

import { toDisplayName } from '../mappers';

type DeprecationCellProps = {
  deprecationNotifications: OpsTypes.Migration.DeprecationNotification[];
};

export const DeprecationCell = ({ deprecationNotifications }: DeprecationCellProps) => {
  const uniqueNames = useMemo(
    () => [...new Set(deprecationNotifications.map((d) => d.name))],
    [deprecationNotifications],
  );

  return (
    <div className="gap-token-4 flex flex-col">
      <div className="line-clip whitespace-pre-wrap" title={uniqueNames.map((name) => toDisplayName(name)).join(', ')}>
        {uniqueNames.map((name: string) => toDisplayName(name))}
      </div>
    </div>
  );
};
