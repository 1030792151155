import { customCreateApi } from '../../context';
import { createDynamicBaseQuery } from '../../utils/create-dynamic-base-query';
import { selectAuraConfiguration } from '../configuration/configuration-slice';
import { getAccessToken } from '../session-slice';
import { graphqlEndpoints } from './endpoints/graphql';
import type { Builder } from './endpoints/types';
import { tagTypes } from './endpoints/types';

const MAJOR_VERSION_GRAPHQL_BACKEND = 'v1';

const graphqlApi = customCreateApi({
  baseQuery: createDynamicBaseQuery(
    (state) => `${selectAuraConfiguration(state)?.graphqlApiUrl}/${MAJOR_VERSION_GRAPHQL_BACKEND}`,
    {
      prepareHeaders: async (headers) => {
        const accessToken = await getAccessToken();
        headers.set('Authorization', `Bearer ${accessToken}`);
      },
    },
  ),
  reducerPath: 'graphqlApi',
  tagTypes,
  endpoints: (builder: Builder) => ({
    ...graphqlEndpoints(builder),
  }),
});

export default graphqlApi;
