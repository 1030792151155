import { extractUniqueNodesAndRels } from '../data-transforms/extract-unique-nodes-and-relationships';
import type { BaseArguments, BasicRelationship } from '../types/sdk-types';

export type ConnectNodesOwnArgs = {
  nodeIds: string[];
};

export type ConnectNodesArgs = BaseArguments & ConnectNodesOwnArgs;

export async function connectNodes({ queryCypher, nodeIds }: ConnectNodesArgs): Promise<BasicRelationship[]> {
  // all element ids have a '-' in them
  const [firstId] = nodeIds;
  // use elementId by for empty list
  const useElementId = firstId === undefined ? true : firstId.includes('-');
  const idFunction = useElementId ? 'elementId' : 'id';
  const stringIds = `[${nodeIds.map((id) => (useElementId ? `"${id}"` : id)).join(', ')}]`;
  const query = `MATCH (a) WHERE ${idFunction}(a) IN ${stringIds}
  MATCH (a)-[r]->(b) WHERE ${idFunction}(b) IN ${stringIds}
  RETURN r`;
  const result = await queryCypher(query);
  const { relationships } = extractUniqueNodesAndRels(result.records, { keepDanglingRels: true });
  return relationships;
}
