/**
 * Get the base URL for the app using import.meta.url
 * This makes the Router aware if the app is served from a subdirectory, so the Client side routing
 * won't override the base url.
 * This is needed when we serve the application from a relative path like standalone explore.
 * There is a special handling when we are in the DEV mode because Vite Dev load all the files not only the bundled js.
 * @returns
 */
export function getBaseUrl() {
  if (import.meta.env.DEV) {
    return '';
  }
  const url = new URL(import.meta.url);
  const pathWithoutResource = url.pathname.replace(/assets\/[^//]+$/g, '');
  return pathWithoutResource;
}
