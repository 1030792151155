import { Banner, Button, IconButton, Label, LoadingSpinner, Menu, Tooltip, Typography } from '@neo4j-ndl/react';
import {
  ChevronDownIconOutline,
  KeyIconOutline,
  PauseCircleIconOutline,
  PlayCircleIconOutline,
  ShieldCheckIconOutline,
  ShieldExclamationIconOutline,
} from '@neo4j-ndl/react/icons';
import { AURA_CONSOLE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { InstanceResources, needsMigration } from '@nx/ops';
import type { Instance, Project } from '@nx/state';
import {
  ACTION,
  INSTANCE_PRIORITY,
  INSTANCE_STATUS,
  MODAL_TYPE,
  PLAN_TYPE,
  PROJECT_BILLING_METHOD,
  TIER,
  consoleApi,
  getApiError,
  useActiveProject,
  useConnection,
  useModal,
  usePermissions,
} from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import { ClipboardCopier, PermissionTooltip, useConnect, useDarkMode } from '@nx/ui';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import cx from 'classnames';
import type { PropsWithChildren, SyntheticEvent } from 'react';
import { useRef, useState } from 'react';

import { useTrackUpxEvent } from '../services/segment/analytics';
import { formatDollars } from '../utils';
import { ConnectMenu } from './connect-menu';
import {
  connectionStatusText,
  formatNumber,
  friendlyRegionName,
  getCDCText,
  getCpuText,
  getMemoryText,
  getNotPauseableMessage,
  getPercentage,
  getRemainingTrialTimeMessage,
  getSecondariesText,
  getStorageText,
  isCDCEnabled,
  isConfigurable,
  isPausable,
  isProTrialInstance,
  isResizing,
  isResumeVisible,
  isSecondariesEnabled,
} from './entities/helpers';
import { InstanceActionMenu } from './instance-action-menu';
import { OpenWithMigrationAssistant } from './migration-assistant/open-with-migration-assistant';
import { SmallPropertyDisplay } from './property-display';
import { CloudProviderIcon } from './shared/cloud-provider-icon';
import { InstanceStatus } from './shared/instance-status';

const logger = createLogger(APP_SCOPE.framework);

const dbLoadingStateMap: Record<INSTANCE_STATUS, boolean> = {
  [INSTANCE_STATUS.CREATING]: true,
  [INSTANCE_STATUS.RUNNING]: false,
  [INSTANCE_STATUS.PAUSING]: false,
  [INSTANCE_STATUS.SUSPENDING]: false,
  [INSTANCE_STATUS.PAUSED]: false,
  [INSTANCE_STATUS.SUSPENDED]: false,
  [INSTANCE_STATUS.RESUMING]: false,
  [INSTANCE_STATUS.LOADING_DATA]: false,
  [INSTANCE_STATUS.LOADING_FAILED]: false,
  [INSTANCE_STATUS.UPDATING]: false,
  [INSTANCE_STATUS.RESIZING]: false,
  [INSTANCE_STATUS.DESTROYING]: true,
  [INSTANCE_STATUS.DESTROYED]: false,
  [INSTANCE_STATUS.RESTORING]: false,
  [INSTANCE_STATUS.OVERWRITING]: false,
  [INSTANCE_STATUS.ENCRYPTION_KEY_DELETED]: false,
  [INSTANCE_STATUS.ENCRYPTION_KEY_ERROR]: false,
};

type TrialContentProps = {
  instance: Instance;
};

const TrialContent = ({ instance }: TrialContentProps) => {
  if (isNullish(instance.trialEndTime)) {
    return null;
  }

  const trialDaysRemainingMessage = getRemainingTrialTimeMessage(instance);

  return (
    <>
      <Typography variant="body-small" as="div" className="text-neutral-text-weaker">
        {trialDaysRemainingMessage}
      </Typography>
    </>
  );
};

const ItemSeparator = () => <div className="border-neutral-border-weak h-3 border-r" />;

interface ContentProps extends PropsWithChildren {
  instance: Instance;
  project?: Project;
  fullPadding?: boolean;
  showProductionLabel?: boolean;
}

const CardContent = ({
  instance,
  project,
  fullPadding = false,
  showProductionLabel = false,
  children,
}: ContentProps) => {
  const classes = cx('mb-3 flex items-start justify-between gap-4 overflow-hidden ', {
    'p-4': fullPadding,
    'px-4 pt-4': !fullPadding,
  });

  return (
    <div className={classes}>
      <div className="flex min-w-32 flex-col flex-wrap gap-x-4 gap-y-1 overflow-hidden">
        <h5 className="flex w-full items-center gap-2">
          {instance.name}
          {showProductionLabel && (
            <Label
              fill="outlined"
              htmlAttributes={{
                'data-testid': 'production-label',
              }}
            >
              Production
            </Label>
          )}
        </h5>
        {project && <InstanceStatus instance={instance} project={project} />}
      </div>
      {children}
    </div>
  );
};

interface WrapperProps extends PropsWithChildren {
  type?: 'config' | 'select';
  isDisabled?: boolean;
  onClick?: (event: SyntheticEvent) => void;
  instance: Instance;
}

const CardWrapper = ({ type, children, isDisabled = false, onClick, instance }: WrapperProps) => {
  const classes = cx(
    'border-neutral-border-weak bg-neutral-bg-weak relative shrink-0 grow basis-[136px] rounded-2xl border',
    {
      'hover:cursor-not-allowed': type === 'select' && isDisabled,
    },
  );

  return (
    <div
      className={classes}
      onClick={onClick}
      aria-label={`Instance card for ${instance.name}`}
      data-testid="instance-card"
      data-instance-id={instance.id}
      data-instance-name={instance.name}
    >
      {children}
    </div>
  );
};

interface InstanceCardProps {
  instance: Instance;
  onOpenDetails: () => void;
  type?: 'config' | 'select';
  usage?: number;
}

export const InstanceCard = ({ instance, type = 'config', onOpenDetails, usage }: InstanceCardProps) => {
  const trackEvent = useTrackUpxEvent();
  const isDarkTheme = useDarkMode();
  const activeProject = useActiveProject();
  const { isAllowed } = usePermissions();
  const allowPause = isAllowed(ACTION.PAUSE, `namespaces/${activeProject.id}/databases/${instance.id}`);
  const allowResume = isAllowed(ACTION.RESUME, `namespaces/${activeProject.id}/databases/${instance.id}`);
  const allowUpdate = isAllowed(ACTION.UPDATE, `namespaces/${activeProject.id}/databases/${instance.id}`);
  const allowClone = isAllowed(ACTION.CREATE, `namespaces/${activeProject.id}/databases`);
  const { doDiscoveryAndConnect } = useConnect();
  const connection = useConnection();
  const [resume, resumeRes] = consoleApi.useResumeInstanceMutation();
  const [pause, pauseRes] = consoleApi.usePauseInstanceMutation();

  const resumeModal = useModal(MODAL_TYPE.RESUME);
  const pauseModal = useModal(MODAL_TYPE.PAUSE);
  const configureModal = useModal(MODAL_TYPE.CONFIGURE);
  const upgradeModal = useModal(MODAL_TYPE.UPGRADE);

  const connectMenuButtonRef = useRef<HTMLButtonElement>(null);
  const [connectMenuOpened, setConnectMenuOpened] = useState(false);

  if (isNullish(instance)) {
    return null;
  }

  const isProductionInstance = instance.priority === INSTANCE_PRIORITY.HIGHEST;
  const isDbLoadingState = dbLoadingStateMap[instance.instanceStatus];

  if (isDbLoadingState) {
    return (
      <CardWrapper type={type} isDisabled={instance.instanceStatus !== INSTANCE_STATUS.RUNNING} instance={instance}>
        <CardContent instance={instance} fullPadding showProductionLabel={isProductionInstance}>
          <div className="absolute inset-0 flex flex-col justify-center gap-1 place-self-center px-6 text-center">
            <LoadingSpinner size="large" className="mb-1 w-full self-center" />
            <Typography variant="subheading-medium">
              <span className="capitalize">{instance.instanceStatus}...</span>
            </Typography>
            {instance.instanceStatus === INSTANCE_STATUS.CREATING && (
              <Typography variant="body-small" className="text-neutral-text-weaker">
                (this will only take a minute)
              </Typography>
            )}
          </div>
        </CardContent>
      </CardWrapper>
    );
  }

  const hasPrivateConnection = !isNullish(instance.privateBoltUrl);
  const { maxNodes, maxRelationships } = instance.limits ?? {};
  const nodesPercentage = getPercentage(instance.counts?.currentNodes, maxNodes);
  const relationshipsPercentage = getPercentage(instance.counts?.currentRelationships, maxRelationships);
  const isProTrial = isProTrialInstance(instance);

  const handleConnect = async (event: SyntheticEvent) => {
    event.stopPropagation();
    if (type === 'select') {
      if (instance.id === connection.metadata?.auraId) {
        await connection.disconnect();
      } else if (instance.instanceStatus === INSTANCE_STATUS.RUNNING) {
        await doDiscoveryAndConnect(instance);
      }
    }
  };

  const handleConnectWithCredentials = async (event: SyntheticEvent) => {
    event.stopPropagation();
    if (instance.instanceStatus === INSTANCE_STATUS.RUNNING) {
      await doDiscoveryAndConnect(instance, false);
    }
  };

  const handleResume = () => {
    resume(instance.id)
      .unwrap()
      .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(e);
        if (isNotNullish(error.message)) {
          logger.error(error.message);
        }
      });

    trackEvent({
      event: AURA_CONSOLE_EVENTS.INSTANCE_RESUME,
      properties: { tier: instance.tier },
      scope: APP_SCOPE.aura,
    });
  };

  const connectButtonEnabled =
    !connection.status.isDisconnecting && !connection.status.isConnecting && instance.availableActions.connect.enabled;

  const handlePause = () => {
    pause(instance.id)
      .unwrap()
      .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(e);
        if (isNotNullish(error.message)) {
          logger.error(error.message);
        }
      });

    trackEvent({
      event: AURA_CONSOLE_EVENTS.INSTANCE_PAUSE,
      properties: { tier: instance.tier },
      scope: APP_SCOPE.aura,
    });
  };

  return (
    <>
      <CardWrapper type={type} isDisabled={instance.instanceStatus !== INSTANCE_STATUS.RUNNING} instance={instance}>
        <CardContent instance={instance} project={activeProject} showProductionLabel={isProductionInstance}>
          <div className="flex gap-2">
            {type === 'config' && <ConnectMenu isDisabled={!connectButtonEnabled} instance={instance} />}
            {type === 'select' && (
              <>
                <Tooltip type="simple" isDisabled={instance.availableActions.connect.enabled} isPortaled={false}>
                  <Tooltip.Trigger hasButtonWrapper>
                    <span className="m-0 flex h-6 gap-[1px]">
                      <Button
                        onClick={(event) => {
                          void handleConnect(event);
                        }}
                        isDisabled={!connectButtonEnabled}
                        className="rounded-r-none"
                      >
                        {connectionStatusText(connection.status, instance.id === connection.metadata?.auraId)}
                      </Button>
                      <Button
                        ref={connectMenuButtonRef}
                        isDisabled={!connectButtonEnabled}
                        onClick={() => setConnectMenuOpened(!connectMenuOpened)}
                        size="medium"
                        className="bg-palette-primary-bg-strong hover:bg-palette-primary-hover-strong rounded-l-none"
                        style={{ padding: '8px' }}
                      >
                        <ChevronDownIconOutline
                          className={cx('stroke-palette-neutral-text-inverse h-4 w-4 shrink-0', {
                            'rotate-180': connectMenuOpened,
                          })}
                        />
                      </Button>
                    </span>
                  </Tooltip.Trigger>
                  <Tooltip.Content>
                    {!instance.availableActions.connect.enabled && instance.availableActions.connect.message}
                  </Tooltip.Content>
                </Tooltip>
                <Menu
                  isOpen={connectMenuOpened}
                  anchorRef={connectMenuButtonRef}
                  onClose={() => setConnectMenuOpened(false)}
                  // TODO: Use better method of displaying menu above modal
                  className="z-60"
                >
                  <Menu.Items>
                    <Menu.Item
                      title="Connect with credentials"
                      onClick={(event) => {
                        void handleConnectWithCredentials(event);
                      }}
                    />
                  </Menu.Items>
                </Menu>
              </>
            )}
            {isResumeVisible(instance) ? (
              <PermissionTooltip hasPermission={allowResume} hasButtonWrapper>
                <Tooltip
                  type="simple"
                  isDisabled={instance.availableActions.resume.enabled || !allowResume || resumeRes.isLoading}
                  isPortaled={false}
                >
                  <Tooltip.Trigger hasButtonWrapper>
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        if (instance.tier === TIER.FREE || isProTrial) {
                          handleResume();
                        } else {
                          resumeModal.open({ instance });
                        }
                      }}
                      isDisabled={!instance.availableActions.resume.enabled || !allowResume}
                      isLoading={resumeRes.isLoading}
                      ariaLabel="Resume instance"
                    >
                      <PlayCircleIconOutline />
                    </IconButton>
                  </Tooltip.Trigger>
                  <Tooltip.Content>
                    {!instance.availableActions.resume.enabled ? instance.availableActions.resume.message : ''}
                  </Tooltip.Content>
                </Tooltip>
              </PermissionTooltip>
            ) : (
              <PermissionTooltip hasPermission={allowPause} hasButtonWrapper>
                <Tooltip type="simple" isDisabled={isPausable(instance) || !allowPause} isPortaled={false}>
                  <Tooltip.Trigger hasButtonWrapper>
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        if (isProTrial) {
                          handlePause();
                        } else {
                          pauseModal.open({ instance });
                        }
                      }}
                      isDisabled={!isPausable(instance) || !allowPause}
                      isLoading={pauseRes.isLoading}
                      ariaLabel="Pause instance"
                    >
                      <PauseCircleIconOutline />
                    </IconButton>
                  </Tooltip.Trigger>
                  <Tooltip.Content>{getNotPauseableMessage(instance)}</Tooltip.Content>
                </Tooltip>
              </PermissionTooltip>
            )}
            {type === 'config' && <InstanceActionMenu instance={instance} onOpenDetails={onOpenDetails} />}
          </div>
        </CardContent>
        <div className="flex flex-row justify-between px-4 pb-3">
          <div className="flex flex-row items-center gap-2">
            <Typography
              variant="body-medium"
              as="div"
              className="text-neutral-text-weaker flex flex-row items-center gap-1"
            >
              <b>ID: </b>
              <span>{instance.id}</span>
              <ClipboardCopier textToCopy={instance.id} ariaLabel="Copy to clipboard" />
            </Typography>
            <ItemSeparator />
            {isSecondariesEnabled(instance) && (
              <>
                <Typography variant="body-medium" as="div" className="text-neutral-text-weak flex flex-row gap-1">
                  <b>{getSecondariesText(instance) ?? 0}</b>
                  <span>Secondaries</span>
                </Typography>
                <ItemSeparator />
              </>
            )}
            {isCDCEnabled(instance) && (
              <>
                <Typography variant="body-medium" as="div" className="flex flex-row gap-1">
                  <span className="text-neutral-text-weak">CDC Mode</span>
                  <b>{getCDCText(instance) ?? 'OFF'}</b>
                </Typography>
                <ItemSeparator />
              </>
            )}
            {hasPrivateConnection && (
              <div className="flex items-center gap-2">
                <Tooltip type="simple">
                  <Tooltip.Trigger>
                    {instance.publicAccessEnabled ? (
                      <ShieldExclamationIconOutline
                        className="text-neutral-icon h-5 w-5"
                        aria-label="Private connection with public access enabled"
                      />
                    ) : (
                      <ShieldCheckIconOutline
                        className="text-neutral-icon h-5 w-5"
                        aria-label="Private connection with public access disabled"
                      />
                    )}
                  </Tooltip.Trigger>
                  <Tooltip.Content>
                    {instance.publicAccessEnabled
                      ? 'Private Connection with public access enabled'
                      : 'Private Connection'}
                  </Tooltip.Content>
                </Tooltip>
                <Typography variant="body-medium" as="span" className="text-neutral-text-weak">
                  Private Connection
                </Typography>
              </div>
            )}
            {isNotNullish(instance.encryptionKeyRef) && (
              <div className="flex items-center gap-2">
                <ItemSeparator />
                <Tooltip type="simple">
                  <Tooltip.Trigger>
                    <KeyIconOutline className="text-neutral-icon h-5 w-5" aria-label="CMK encrypted instance" />
                  </Tooltip.Trigger>
                  <Tooltip.Content>Instance encrypted with CMK</Tooltip.Content>
                </Tooltip>
                <Typography variant="body-medium" as="span" className="text-neutral-text-weak">
                  CMK
                </Typography>
              </div>
            )}
          </div>
          {instance.isBeingCloned && (
            <div className="flex flex-row items-center gap-2">
              <LoadingSpinner size="small" />
              <Typography variant="body-small" as="span" className="text-neutral-text-weak">
                Cloning in progress
              </Typography>
            </div>
          )}
          {isResizing(instance) && (
            <div className="flex flex-row items-center gap-2">
              <LoadingSpinner size="small" />
              <Typography variant="body-small" as="span" className="text-neutral-text-weak">
                Resizing instance
              </Typography>
            </div>
          )}
        </div>
        <div>
          {type !== 'select' && needsMigration(instance.desiredSettings.version) && (
            <OpenWithMigrationAssistant instance={instance}>
              {({ onClick }) => (
                <Banner
                  hasIcon
                  description="Migration recommended"
                  className="m-3 mt-0"
                  type="warning"
                  actions={[
                    {
                      label: 'Migration readiness report',
                      onClick: onClick,
                    },
                  ]}
                ></Banner>
              )}
            </OpenWithMigrationAssistant>
          )}
        </div>
        <div className="border-neutral-border-weak mx-3 mb-3 flex flex-row justify-between rounded-[8px] border px-3 py-3">
          <div className="flex flex-wrap gap-x-4 gap-y-2">
            <SmallPropertyDisplay label="Type" text={instance.tierDisplayName} />

            {instance.tier === TIER.FREE ? (
              <>
                <SmallPropertyDisplay
                  label="Nodes"
                  text={
                    <>
                      {formatNumber(instance.counts?.currentNodes)}{' '}
                      <span className={nodesPercentage > 70 ? 'text-danger-text' : 'text-neutral-text-weaker'}>
                        {isNotNullish(maxNodes) ? `(${nodesPercentage.toFixed()}%)` : ''}
                      </span>
                    </>
                  }
                />
                <SmallPropertyDisplay
                  label="Relationships"
                  text={
                    <>
                      {formatNumber(instance.counts?.currentRelationships)}{' '}
                      <span className={relationshipsPercentage > 70 ? 'text-danger-text' : 'text-neutral-text-weaker'}>
                        {isNotNullish(maxRelationships) ? `(${relationshipsPercentage.toFixed()}%)` : ''}
                      </span>
                    </>
                  }
                />
              </>
            ) : (
              <>
                <SmallPropertyDisplay label="Memory" text={getMemoryText(instance)} />
                <SmallPropertyDisplay label="CPU" text={getCpuText(instance)} />
                <SmallPropertyDisplay
                  label="Storage"
                  text={getStorageText(instance)}
                  aria-label={`instance-storage-${getStorageText(instance)}`}
                />
              </>
            )}
            {instance.tier !== TIER.FREE && (
              <SmallPropertyDisplay
                text={
                  <div className="flex flex-row items-center gap-2" data-testid={`region-${instance.region}`}>
                    <CloudProviderIcon cloudProvider={instance.cloudProvider} isDarkTheme={isDarkTheme} />
                    {friendlyRegionName(
                      instance,
                      activeProject.tierConfigs[instance.tier]?.cloudProviderRegions[instance.cloudProvider],
                    )}
                  </div>
                }
              />
            )}
          </div>
          {type === 'config' && (
            <div className="flex items-center gap-4">
              {activeProject.billingMethod === PROJECT_BILLING_METHOD.PAYG &&
                isNotNullish(usage) &&
                isNullish(instance.trialEndTime) && (
                  <Typography variant="body-small">
                    <span className="text-neutral-text-weaker">Monthly usage:</span> {formatDollars(usage)}
                  </Typography>
                )}
              <TrialContent instance={instance} />
              {activeProject.billingMethod === PROJECT_BILLING_METHOD.PREPAID &&
                activeProject.planType === PLAN_TYPE.SELF_SERVE && (
                  <Typography variant="body-small" as="span" className="text-neutral-text-weaker">
                    This instance is prepaid
                  </Typography>
                )}
              {instance.tier !== TIER.FREE && !isProTrial && (
                <PermissionTooltip hasPermission={allowUpdate} hasButtonWrapper>
                  <Button
                    size="small"
                    fill="outlined"
                    color="neutral"
                    isDisabled={!isConfigurable(instance) || !allowUpdate}
                    onClick={() => {
                      configureModal.open({ instance, project: activeProject });
                    }}
                    htmlAttributes={{
                      'aria-label': 'Configure instance',
                    }}
                  >
                    Configure
                  </Button>
                </PermissionTooltip>
              )}
              {instance.tier === TIER.FREE && (
                <PermissionTooltip hasPermission={allowClone} hasButtonWrapper>
                  <Button
                    size="small"
                    fill="outlined"
                    color="neutral"
                    isDisabled={!instance.availableActions.clone.enabled || !allowClone}
                    onClick={() => {
                      upgradeModal.open({ instance, project: activeProject });
                    }}
                    htmlAttributes={{
                      'aria-label': 'Upgrade instance',
                    }}
                  >
                    Upgrade
                  </Button>
                </PermissionTooltip>
              )}
            </div>
          )}
        </div>

        {type === 'config' && (
          <div className="bg-neutral-bg-on-bg-weak m-3 rounded-[8px] border border-transparent">
            <InstanceResources instance={instance} />
          </div>
        )}
      </CardWrapper>
    </>
  );
};
