import { isAction } from '@reduxjs/toolkit';

import { getIsWorkspace, setDeployment } from '../../state/app/appDuck';
import {
  getIsFeatureAvailable,
  setDatabases,
  setDbmsId,
  setServerVersion,
  updateConnectionDetails,
} from '../../state/connections/connectionsDuck';
import { isRemoteConnection } from '../../state/connections/helpers';
import { REHYDRATE } from '../../state/persistence/constants';
import { appendPerspectives, getAllPerspectives } from '../../state/perspectives/perspectives';
import type { AppMiddleware } from '../../state/types';
import { middleWareActions } from './index';

const trackingMiddleware: AppMiddleware = (store) => (next: (action: unknown) => unknown) => (action: unknown) => {
  if (setDbmsId.match(action)) {
    middleWareActions.setCurrentDbmsId(action.payload);
  }
  if (setServerVersion.match(action)) {
    middleWareActions.setCurrentServerVersion(action.payload);
  }
  if (updateConnectionDetails.match(action)) {
    middleWareActions.setIsRemoteConnection(isRemoteConnection(action.payload));
  }
  if (setDatabases.match(action)) {
    middleWareActions.setCurrentNumDatabases(action.payload.length);
    const isPluginPresent = getIsFeatureAvailable(store.getState(), 'perspectiveSharing');
    middleWareActions.setPluginPresent(isPluginPresent);
  }

  const result = next(action);

  if (isAction(action) && [REHYDRATE, appendPerspectives.toString()].includes(action.type)) {
    const state = store.getState();
    const currentPerspectives = getAllPerspectives(state);
    middleWareActions.setCurrentPerspectives(currentPerspectives);
  }

  if (setDeployment.match(action)) {
    const state = store.getState();
    middleWareActions.setIsWorkspace(getIsWorkspace(state));
  }

  return result;
};

export default trackingMiddleware;
