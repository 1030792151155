import { TextLink, Typography } from '@neo4j-ndl/react';

import type { HelpTooltipProps } from '../../help-tooltip';

export const StoreSizeAllocated: HelpTooltipProps = {
  link: {
    text: 'store size metrics',
    href: 'https://neo4j.com/docs/aura/auradb/managing-databases/advanced-metrics/#_store_size_metrics',
  },
  body: (
    <div>
      <p>Allocated space refers to the total amount of space currently allocated to the database, measured in bytes.</p>
      <p>
        Used space is the portion of the allocated space that the database is actively utilizing, also measured in
        bytes. It is calculated by subtracting the available allocated space from the total allocated space.
      </p>
      <p>
        <Typography variant="body-medium">
          If there is a big difference between the used space and the allocated space, consider{' '}
          <TextLink
            href="https://support.neo4j.com/s/article/23560594934419-How-to-use-Neo4j-Desktop-to-compact-your-Aura-instance-s-database-store"
            isExternalLink
            htmlAttributes={{
              rel: 'noreferrer',
            }}
          >
            compacting your database.
          </TextLink>
        </Typography>
      </p>
      <p />
      <p className="pt-4">
        <b>
          Please note that these metrics are updated every 10 minutes due to the cost of measurement. Consequently,
          there might be a delay between data additions or deletions and their reflection in this chart.
        </b>
      </p>
    </div>
  ),
};
