import { tokens } from '@neo4j-ndl/base';
import { useMediaQuery } from '@neo4j-ndl/react';
import { useAppSelector, useMainSidebarActions } from '@nx/state';
import { useDarkMode } from '@nx/ui';
import { useEffect } from 'react';

import type { LinkMenuItem } from '../left-nav-menu/left-nav-menu';
import { LeftNavMenu } from '../left-nav-menu/left-nav-menu';
import { useLeftNavContext } from './left-nav-context';

const heading = (name: string, spotlightId?: string) => ({ type: 'heading' as const, name, spotlightId });
const divider = () => ({ type: 'divider' as const });
const link = ({
  name,
  route,
  icon,
  spotlightId,
  onClick,
  beta = false,
}: {
  name: string;
  route: string;
  icon?: React.ReactNode;
  spotlightId?: string;
  onClick?: () => void;
  beta?: boolean;
}): LinkMenuItem => ({
  type: 'link',
  name,
  icon,
  route,
  spotlightId,
  onClick,
  beta,
});

export const Nav = {
  heading,
  divider,
  link,
};

export function LeftNav() {
  const { items } = useLeftNavContext();
  const { toggleMainSidebar, leaveMainSidebar } = useMainSidebarActions();
  const mainSidebar = useAppSelector((store) => store.sidebar.mainSidebar);
  const isTabletScreen = useMediaQuery(`(min-width:${tokens.breakpoints.md})`);
  const showOverlay = !isTabletScreen && mainSidebar.isOpen;
  const isDarkMode = useDarkMode();

  const className = mainSidebar.isTemporaryOpen
    ? `${isDarkMode ? 'shadow-dark-overlay' : 'shadow-overlay'} absolute top-0 left-0`
    : 'absolute md:relative';

  useEffect(() => {
    if (showOverlay) {
      toggleMainSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabletScreen]);

  return (
    <>
      <LeftNavMenu
        expanded={mainSidebar.isOpen}
        isTemporaryOpen={mainSidebar.isTemporaryOpen}
        className={className}
        // Without overlay LeftNav=LeftNavMenu, with overlay LeftNav=LeftNavMenu+overlay
        // and so the main, upstream onMouseLeave doesn't trigger when leaving LeftNavMenu
        // and entering the overlay. This handles leaving LeftNavMenu with overlay active.
        onMouseLeave={() => {
          if (showOverlay) {
            void leaveMainSidebar();
          }
        }}
        items={items}
      />

      {showOverlay && (
        <div
          className="absolute bottom-0 left-0 right-0 top-0 z-[11] bg-[#000] opacity-40"
          onClick={() => toggleMainSidebar()}
          role="presentation"
        />
      )}
    </>
  );
}
