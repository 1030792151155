/*
  The component implements Neo4j Card design pattern.
  https://www.neo4j.design/40a8cff71/p/469285-cards
  https://www.figma.com/file/k2m1Gj3NZdebUZpvcPhRyA/Patterns-library?type=design&node-id=0-1&mode=design&t=FwMsTXC17UfjNzgq-0

  Usage example:

  <Card>
    <Card.MenuButton />
    <Card.Image src={GraphImgUrl} className="object-cover" />
    <Card.Content>
      <Card.Header>
        <Card.Title>Header</Card.Title>
        <Card.Subtitle>Subtitle</Card.Subtitle>
        <Card.Label>Label</Card.Label>
      </Card.Header>
      <Card.Description>Description</Card.Description>
      <Card.Footer>
        <Card.Meta>Meta info</Card.Meta>
        <Card.Actions>Read more...</Card.Actions>
      </Card.Footer>
    </Card.Content>
  </Card>
 */
import { IconButton } from '@neo4j-ndl/react';
import { EllipsisHorizontalIconOutline } from '@neo4j-ndl/react/icons';
import cx from 'classnames';
import type { ComponentPropsWithRef, ComponentPropsWithoutRef, ReactNode } from 'react';
import React, { createContext, isValidElement, useContext } from 'react';

import { tw } from '../utils';

interface CardProps {
  type?: 'elevated' | 'filled' | 'outlined' | 'disabled';
  horizontal?: boolean;
  fluid?: boolean;
  children: ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

const CardContext = createContext({ horizontal: false });
const useCard = () => useContext(CardContext);

const Card = ({ type = 'elevated', horizontal = false, fluid = false, onClick, children }: CardProps) => {
  const classes: Record<Exclude<CardProps['type'], undefined>, string> = {
    elevated: tw`shadow-raised text-palette-neutral-text-default hover:shadow-overlay`,
    filled: tw`bg-palette-primary-bg-strong text-palette-neutral-text-inverse hover:bg-palette-primary-hover-strong`,
    outlined: tw`text-palette-neutral-text-default border-palette-neutral-border-strong hover:shadow-overlay bg-palette-neutral-bg-weak border`,
    disabled: tw`bg-palette-neutral-bg-strong`,
  };

  const vertical = !horizontal;

  return (
    <article
      tabIndex={0}
      className={cx(
        classes[type],
        'n-body-medium relative overflow-hidden rounded-3xl',
        vertical && `flex w-[340px] flex-col`,
        horizontal && 'flex w-[740px]',
        fluid && '!w-auto',
      )}
      onClick={onClick}
    >
      <CardContext.Provider value={{ horizontal }}>{children}</CardContext.Provider>
    </article>
  );
};

const Image = ({ className, ...props }: ComponentPropsWithoutRef<'img'>) => {
  const { horizontal } = useCard();

  const vertical = !horizontal;

  return (
    <img
      {...props}
      className={cx(className, 'min-h-0 min-w-0', vertical && 'basis-[180px]', horizontal && 'basis-[240px]')}
    />
  );
};

const Content = ({ className, ...props }: ComponentPropsWithoutRef<'div'>) => {
  return <div className={cx('flex grow flex-col gap-6 p-6', className)} {...props} />;
};

const Title = (props: ComponentPropsWithoutRef<'h4'>) => <h4 {...props} />;

const Subtitle = ({ className, ...props }: ComponentPropsWithoutRef<'span'>) => {
  return <span className={cx('text-palette-neutral-text-weaker', className)} {...props} />;
};

const Label = (props: ComponentPropsWithoutRef<'div'>) => <div {...props} />;

const Header = ({ className, children, ...props }: ComponentPropsWithoutRef<'header'>) => {
  let TitleElement;
  let SubtitleElement;
  let LabelElement;

  React.Children.forEach(children, (child) => {
    if (!isValidElement(child) || typeof child.type !== 'function') {
      return;
    }

    // The usual child.type === Title breaks with Vite Hot Module Replacement
    // Other HMR implementations has the similar problems. e.g. https://github.com/gaearon/react-hot-loader/issues/304
    // Instead rely on a function name. Though less reliable.
    if (child.type.name === Title.name) {
      TitleElement = child;
    }

    if (child.type.name === Subtitle.name) {
      SubtitleElement = child;
    }

    if (child.type.name === Label.name) {
      LabelElement = child;
    }
  });

  return (
    <header className={cx('flex justify-between align-baseline', className)} {...props}>
      <div>
        {TitleElement}
        {SubtitleElement}
      </div>
      {LabelElement}
    </header>
  );
};

const Description = (props: ComponentPropsWithoutRef<'p'>) => <p {...props} />;

const MenuButton = ({ className, onClick, ...props }: React.ComponentProps<typeof IconButton>) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onClick?.(e);
  };

  return (
    <IconButton className={cx('absolute right-3 top-3', className)} isClean onClick={handleClick} {...props}>
      <EllipsisHorizontalIconOutline />
    </IconButton>
  );
};

const Footer = ({ className, ...props }: ComponentPropsWithoutRef<'footer'>) => {
  return <footer className={cx('flex justify-between', className)} {...props} />;
};

const Actions = (props: ComponentPropsWithRef<'div'>) => <div {...props} />;

const Meta = (props: ComponentPropsWithRef<'div'>) => <div {...props} />;

Card.Image = Image;
Card.Content = Content;
Card.Header = Header;
Card.Title = Title;
Card.Subtitle = Subtitle;
Card.Label = Label;
Card.Description = Description;
Card.MenuButton = MenuButton;
Card.Footer = Footer;
Card.Actions = Actions;
Card.Meta = Meta;

export { Card };
