import { Button, TextLink, Typography } from '@neo4j-ndl/react';
import { Center } from '@nx/ui';

import ApiIllustration from '../assets/api-illustration.svg';

interface EmptyStateProps {
  onClickCreateApi: () => void;
}

export const EmptyState = ({ onClickCreateApi }: EmptyStateProps) => {
  return (
    <Center>
      <img src={ApiIllustration} alt="GraphQL Data APIs illustration" draggable="false" />
      <div className="ml-12 flex max-w-[430px] flex-col gap-4">
        <Typography variant="h3">Deploy a GraphQL Data API</Typography>
        <Typography variant="body-large">Create a GraphQL API to use the power of GraphQL with AuraDB.</Typography>
        <Typography variant="body-large">
          For information on GraphQL Data APIs, see the{' '}
          <TextLink className="no-underline" href="https://neo4j.com/docs/graphql/6/aura-graphql" isExternalLink>
            Docs
          </TextLink>
          .
        </Typography>
        <Button
          onClick={() => onClickCreateApi()}
          className="w-fit"
          fill="filled"
          htmlAttributes={{
            tabIndex: 0,
          }}
        >
          Create API
        </Button>
      </div>
    </Center>
  );
};
