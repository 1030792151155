import { IconButton } from '@neo4j-ndl/react';
import { ArrowUturnLeftIconOutline } from '@neo4j-ndl/react/icons';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { ACTION, type Instance, consoleApi, getApiError, getErrorMessage, usePermissions } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { PermissionTooltip } from '@nx/ui';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useMemo, useState } from 'react';

import { RestoreSnapshotModal } from './modal';

const logger = createLogger(APP_SCOPE.framework);

export interface RestoreSnapshotProps {
  snapshotId: string;
  instance: Instance;
  timestamp: string;
  profile: string;
}

export const RestoreSnapshot = ({ snapshotId, instance, timestamp, profile }: RestoreSnapshotProps) => {
  const [showModalDialog, setShowModalDialog] = useState(false);
  const [restoreInstance, restoreInstanceRes] = consoleApi.useRestoreInstanceFromSnapshotMutation();
  const { isAllowed } = usePermissions();
  const isAllowedToRestore = isAllowed(ACTION.OVERWRITE, `namespaces/${instance.projectId}/databases/${instance.id}`);

  const errorMessage = useMemo(() => {
    if (!restoreInstanceRes.isError) {
      return null;
    }
    const error = getApiError(restoreInstanceRes.error);
    const message = getErrorMessage(error);
    return message;
  }, [restoreInstanceRes.error, restoreInstanceRes.isError]);

  const showModalAction = (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    e.preventDefault();
    setShowModalDialog(true);
  };

  const restoreSnapshot = () => {
    restoreInstance({ dbId: instance.id, snapshotId })
      .unwrap()
      .then(() => {
        setShowModalDialog(false);
      })
      .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(e);
        if (isNotNullish(error.message)) {
          logger.error(error.message);
        }
      });
  };

  const cancelModal = () => {
    setShowModalDialog(false);
  };

  return (
    <>
      <PermissionTooltip hasPermission={isAllowedToRestore} hasButtonWrapper isPortaled>
        <IconButton
          isDisabled={!instance.availableActions.restore.enabled || !isAllowedToRestore}
          ariaLabel="Restore from snapshot"
          isClean
          onClick={showModalAction}
          isActive={showModalDialog}
          htmlAttributes={{
            title: 'Restore from snapshot',
          }}
        >
          <ArrowUturnLeftIconOutline />
        </IconButton>
      </PermissionTooltip>
      {showModalDialog && (
        <RestoreSnapshotModal
          instance={instance}
          timestamp={timestamp}
          onConfirm={() => restoreSnapshot()}
          onCancel={() => cancelModal()}
          loading={restoreInstanceRes.isLoading}
          errorMessage={errorMessage}
        />
      )}
    </>
  );
};
