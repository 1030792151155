import type { ActionStatus, CLOUD_PROVIDER, TIER } from './shared';

export type InstanceCapability =
  | {
      enabled: true;
    }
  | { enabled: false; reason: string; message: string };

export type InstanceCapabilities = {
  pause: InstanceCapability;
  cdc_enrichment_mode: InstanceCapability;
  secondaries: InstanceCapability;
  vector_optimized: InstanceCapability;
  gds_plugin: InstanceCapability;
  create_custom_endpoint: InstanceCapability;
  assign_custom_endpoint: InstanceCapability;
  multi_database: InstanceCapability;
  tier_upgrade: InstanceCapability;
};

export type InstanceAvailableActions = {
  clone: ActionStatus;
  delete: ActionStatus;
  import: ActionStatus;
  overwrite: ActionStatus;
  pause: ActionStatus;
  resize: ActionStatus;
  restore: ActionStatus;
  resume: ActionStatus;
  take_snapshot: ActionStatus;
  secondaries: ActionStatus;
  cdc_enrichment_mode: ActionStatus;
  update_priority: ActionStatus;
  link_custom_endpoint: ActionStatus;
  vector_optimized: ActionStatus;
  gds_plugin: ActionStatus;
  connect: ActionStatus;
};

export interface Instance {
  tier: TIER;
  instanceStatus: INSTANCE_STATUS;
  monitoringStatus: MONITORING_STATUS;
  cloudProvider: CLOUD_PROVIDER;
  id: string;
  name: string;
  isBeingCloned: boolean;
  connectionUri: string;
  boltUrl: string;
  region: string;
  appliedSettings: {
    memory?: string;
    storage?: string;
    cpu?: number;
    secondariesCount?: number;
    cdcEnrichmentMode?: CDC_ENRICHMENT_MODE;
    version?: string;
  };
  desiredSettings: {
    memory: string;
    storage?: string;
    cpu?: number;
    version: string;
    secondariesCount?: number;
    cdcEnrichmentMode?: CDC_ENRICHMENT_MODE;
  };
  counts?: { currentNodes: number; currentRelationships: number };
  limits?: { maxNodes: number; maxRelationships: number };
  privateBoltUrl?: string;
  publicAccessEnabled: boolean;
  seamlessConnectionEnabled: boolean;
  encryptionKeyRef?: string;
  projectId: string;
  priority: INSTANCE_PRIORITY;
  capabilities: InstanceCapabilities;
  availableActions: InstanceAvailableActions;
  trialEndTime?: string;
  resizeThreshold?: number;
  tierDisplayName: string;
  vectorOptimized: boolean;
  gdsPlugin: boolean;
  queryApiUrl?: string;
}

export type InstanceSummary = {
  id: string;
  name: string;
  projectId: string;
  cloudProvider: string;
};

export interface InstanceSize {
  sizeId: string;
  memory: string;
  cpu: string;
  storage: string;
  costPerHour: string;
  cloudProvider: CLOUD_PROVIDER;
  unavailableRegions: string[];
  deprecated: boolean;
  isTrial: boolean;
  minimumRequiredVersion?: string;
  isPrepaidOnly: boolean;
}

export type InstanceSizes = InstanceSize[];

export enum INSTANCE_STATUS {
  CREATING = 'creating',
  RUNNING = 'running',
  LOADING_DATA = 'loading',
  LOADING_FAILED = 'loading failed',
  UPDATING = 'updating',
  RESIZING = 'resizing',
  RESTORING = 'restoring',
  DESTROYING = 'destroying',
  DESTROYED = 'destroyed',
  PAUSING = 'pausing',
  PAUSED = 'paused',
  RESUMING = 'resuming',
  OVERWRITING = 'overwriting',
  // These are currently client-side only statuses
  // They correspond to when a DB is Paus-ing/ed within
  // a suspended tenant.
  SUSPENDING = 'suspending',
  SUSPENDED = 'suspended',
  // Derived from the Encryption Key Status
  ENCRYPTION_KEY_DELETED = 'encryption key deleted',
  ENCRYPTION_KEY_ERROR = 'encryption key error',
}

export enum MONITORING_STATUS {
  OK = 'ok',
  WARNING = 'warning',
  CRITICAL = 'critical',
}

export const BOLT_ROUTING_PROTOCOL = 'bolt+routing://';
export const NEO4J_PROTOCOL = 'neo4j+s://';

export enum ONLINE_STATUS {
  ONLINE = 'online',
  DEGRADED = 'degraded',
  OFFLINE = 'offline',
}

export enum INSTANCE_PRIORITY {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  HIGHEST = 'highest',
}

export type CreatedInstance = {
  id: string;
  password: string;
  boltUrl: string;
  name: string;
  projectId: string;
  username: string;
};

export enum CARDINALITY {
  SINGLE_DB = 'single_db',
  MULTI_DB = 'multi_db',
}

export enum SUBSTRATE {
  DEDICATED = 'dedicated_instance',
  SHARED_MULTI_DB_CLUSTER = 'shared_multi_db_cluster',
}

export const isAuraInstance = (object: unknown): object is Instance => {
  if (object !== null && typeof object === 'object') {
    return 'DbId' in object && 'DatabaseStatus' in object;
  }

  return false;
};

export const isAuraInstances = (array: unknown): array is Instance[] => {
  if (array !== null && Array.isArray(array)) {
    return array.every((item) => isAuraInstance(item));
  }

  return false;
};

const neo5regex = /^(5$|5\.[0-9])/;
const neo4regex = /^(4$|4\.[0-9])/;
const neo3regex = /^(3$|3\.[0-9])/;

export const getNeo4jVersionText = (input?: string, customerFacing = true): string => {
  if (input === undefined || input === '') {
    return '';
  }
  if (neo3regex.exec(input)) {
    return '3.5';
  }
  if (neo4regex.exec(input)) {
    return '4';
  }
  if (neo5regex.exec(input)) {
    return customerFacing ? 'Latest' : '5';
  }
  const match = input.match(/^\d{4}\.\d{2}/);
  return match ? match[0] : '';
};

export enum CDC_ENRICHMENT_MODE {
  OFF = 'OFF',
  DIFF = 'DIFF',
  FULL = 'FULL',
}

export type SignedObjectUrl = {
  cloudStorageObject: string;
  signedUrl: string;
};
