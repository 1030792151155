import type { ConnectionDescriptor, TransactionMetadataConfig } from '@nx/constants';
import { APP_SCOPE } from '@nx/constants';
import { getNeo4jErrorValues, isInvalidUrlConnectionError, isNeo4jError, isSerializedError } from '@nx/errors';
import { MINIMUM_SUPPORTED_VERSION } from '@nx/neo4j-sdk';
import {
  SeamlessConnectionError,
  createTokenProviderSeamless,
  createTokenProviderSso,
  getSsoCredentials,
  handleSsoRedirect,
} from '@nx/neo4j-token-provider';
import { neo4jVersionUtil } from '@nx/neo4j-version-utils';
import * as StdLib from '@nx/stdlib';
import type { EntityState, PayloadAction } from '@reduxjs/toolkit';
import { createAction, createEntityAdapter, createSlice, isRejectedWithValue } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import * as DriverCore from 'neo4j-driver-core';

import {
  DriverConnectionError,
  NoDatabaseSelectedError,
  type RunQueryConfig,
  type RunQueryInput,
  isDriverConnectInfo,
} from '../../adapters/neo4j-driver-adapter';
import { createAsyncThunk } from '../../context';
import { Persist } from '../../persist/persist';
import { authLog } from '../../services/neo4j-sso/auth-log';
import {
  NEO4J_IS_HANDLING_SSO_CALLBACK,
  createUserAndStateWebStorageStores,
  getCurrentSSOProvider,
  getRedirectUri,
} from '../../services/neo4j-sso/auth-request';
import { getBaseUrl } from '../../utils/get-base-url';
import { selectEnabledTools } from '../capabilities-slice';
import { selectAuraConfiguration } from '../configuration/configuration-slice';
import { addNotification } from '../notifications-slice';
import { getAccessToken, getIdToken } from '../session-slice';
import { checkDiscoveryForUrls, getConnectionIdFromUrl, isLocalDiscoveryData } from './connection-utils';
import { type Connection, isConnectionState } from './connection.types';
import { nxMetadata } from './constants';
import { getDatabaseInstanceId, toDatabaseObject } from './database-utils';
import {
  type Database,
  type DatabaseInstanceId,
  type LogicalDatabaseName,
  assertLogicalDatabaseName,
} from './database.types';
import type { ConnectionsPersistedState } from './persist';
import { toUserObject } from './user-utils';
import type { User } from './user.types';

export * from './connection.types';

export const CREDENTIALS_STORAGE_FIELD = Persist.createKey(APP_SCOPE.framework, 'currentConnection');
export const ACTIVE_DATABASE_STORAGE_FIELD = Persist.createKey(APP_SCOPE.framework, 'activeDatabase');

const AURA_QUERY_API_PORT = '443';

type Neo4jEdition = 'community' | 'enterprise';
const stringIsEdition = (edition: string): edition is Neo4jEdition => ['community', 'enterprise'].includes(edition);

class InvalidConnectionError extends TypeError {
  constructor() {
    super('Provided object is not a valid connection record');
    this.name = 'InvalidConnectionError';
  }
}

export enum CONNECTION_STATUS {
  CONNECTED = 'connected',
  CONNECTING = 'connecting',
  RECONNECTING = 'reconnecting',
  DISCONNECTING = 'disconnecting',
  DISCONNECTED = 'disconnected',
}

export enum CONNECTION_ERROR {
  CREDENTIALS_EXPIRED = 'Neo.ClientError.Security.CredentialsExpired',
  GQL_STATUS_CREDENTIALS_EXPIRED = '42NFE',
}

/**
 * Slice state
 * Manages connection to Neo4j DBMS
 * TODO: Should it be called DBMS? ConnectionManager? Neo4jConnection?
 */
export type ConnectionState = {
  /**
   * Static connection URL from customer's discovery.json file
   * TODO: Should it be a part of the connection object?
   * It's only used to fetch local discovery data and based on that prepopulate connection form
   * and hide connections dropdown
   */
  staticConnectionUrl: string | undefined;
  /**
   * ID referencing currently used connection object
   */
  activeConnectionId: string | undefined;
  /**
   * ID referencing currently used database
   * A database is identified by its name or alias
   */
  activeDatabaseName: LogicalDatabaseName | undefined;
  /**
   * Connections to a Neo4j DBMS
   * - Entity ID: a connection is uniquely identified by its host (hostname and port).
   * - Entity ID format: `${hostname}:${port}`
   */
  connections: EntityState<Connection, string>;
  /**
   * _Database instances_.
   * In clustered environments, databases might have copies(instances) on different servers.
   * One _logical_ Neo4j database can be backed up by one or instances.
   * i.e. there might be multiple databases with the same name.
   * - Entity ID: a database instance is uniquely identified by its name and server address
   * - Entity ID format: `${databaseName}@${serverAddress}`
   */
  databases: EntityState<Database, DatabaseInstanceId>;
  /**
   * The currently logged-in DBMS user
   * The `home` field from the current user's settings determines the user's home database.
   * This field might reference either an actual database name or an alias, as specified by the user.
   *
   * Databases table also has `home` field but directly querying the `home` field from the databases table can be
   * misleading because:
   * - The databases table does not clarify whether the `home` database name or an alias is being used.
   * - The `home` field in the databases table set to `true` for default database even if user has unset home database.
   * Hence, to accurately determine a user's home database, we must use user info.
   */
  currentUser: User | undefined;
  /**
   * Current driver status
   */
  status: CONNECTION_STATUS;

  neo4jVersion: string | undefined;
  neo4jEdition: Neo4jEdition | undefined;
};

const connectionsAdapter = createEntityAdapter({
  selectId: (connection: Connection) => getConnectionIdFromUrl(connection.url),
  sortComparer: (a: Connection, b: Connection) => (b.lastUsedAt ?? 0) - (a.lastUsedAt ?? 0),
});

const databasesAdapter = createEntityAdapter({
  selectId: (database: Database) => getDatabaseInstanceId(database),
  sortComparer: (a, b) => getDatabaseInstanceId(a).localeCompare(getDatabaseInstanceId(b)),
});

const connectionsSelectors = connectionsAdapter.getSelectors((state: ConnectionState) => state.connections);
const sliceConnections = Object.assign({}, connectionsAdapter, connectionsSelectors);

const databasesSelectors = databasesAdapter.getSelectors((state: ConnectionState) => state.databases);
const sliceDatabases = Object.assign({}, databasesAdapter, databasesSelectors);

export const initialState: ConnectionState = {
  staticConnectionUrl: undefined,
  activeConnectionId: undefined,
  activeDatabaseName: undefined,
  connections: sliceConnections.getInitialState(),
  databases: sliceDatabases.getInitialState(),
  currentUser: undefined,
  status: CONNECTION_STATUS.DISCONNECTED,
  neo4jVersion: undefined,
  neo4jEdition: undefined,
};

export function selectActiveConnection(state: ConnectionState): Connection | undefined {
  if (state.activeConnectionId === undefined) {
    return undefined;
  }

  return sliceConnections.selectById(state, state.activeConnectionId);
}

export function selectConnectionStatus(state: ConnectionState): CONNECTION_STATUS {
  return state.status;
}

export const selectConnections = (state: ConnectionState): Connection[] => sliceConnections.selectAll(state);

export const selectCurrentUser = (state: ConnectionState) => state.currentUser;

export const selectVersionAndEdition = (state: ConnectionState) => ({
  version: state.neo4jVersion,
  edition: state.neo4jEdition,
});

export const selectStaticConnectionUrl = (state: ConnectionState) => state.staticConnectionUrl;

export const selectAllDatabases = (state: ConnectionState) => sliceDatabases.selectAll(state);

export const selectDatabaseEntities = (state: ConnectionState) => sliceDatabases.selectEntities(state);

export const selectActiveDatabaseName = (state: ConnectionState) => state.activeDatabaseName;

export const selectHomeDatabase = (state: ConnectionState) => state.currentUser?.home;

/**
 * Select database instances by name or alias
 */
export const selectDatabases = (state: ConnectionState, name: LogicalDatabaseName) =>
  sliceDatabases.selectAll(state).filter((db) => db.name === name || db.aliases.includes(name));

/**
 * Select database instances for default database
 */
export const selectDefaultDatabases = (state: ConnectionState) =>
  sliceDatabases.selectAll(state).filter((db) => db.default);

export const skipConnection = createAction('connections/skipConnection');

export const connectionSwitching = createAction('connections/connectionSwitching');

export const connectionSwitched = createAction('connections/connectionSwitched');

export const fetchLocalDiscoveryData = createAsyncThunk('connections/fetchLocalDiscovery', async () => {
  const response = await fetch(`${getBaseUrl().replace(/\/$/, '')}/discovery.json`, {
    method: 'get',
    headers: {
      Accept: 'application/json',
    },
  });

  if (response.ok) {
    const result: unknown = await response.json();
    if (isLocalDiscoveryData(result)) {
      if ('bolt' in result) {
        return result.bolt;
      } else if ('bolt_direct' in result) {
        return result.bolt_direct;
      } else if ('bolt_routing' in result) {
        return result.bolt_routing;
      }
    }
  }
  return null;
});

export const updateVersionAndEdition = createAsyncThunk(
  'connections/updateVersionAndEdition',
  async (_, { extra, signal, getState }) => {
    // TODO: A type safe version of this query is available in neo4j-sdk. It
    // would make sense to just import the getVersionAndEdition function
    // from there to avoid having inline cypher in the state package.

    const { records } = await extra.driver.runQuery(
      { query: 'CALL dbms.components() YIELD versions, edition;' },
      { metadata: nxMetadata, transactionMode: 'read', sessionConfig: { database: 'system' }, signal },
      selectEnabledTools(getState()),
    );

    const resultVersion = String(records[0]?.get(0));
    const coerceVersion = String(neo4jVersionUtil.coerce(resultVersion));
    const resultEdition = String(records[0]?.get(1));

    return { version: coerceVersion, edition: stringIsEdition(resultEdition) ? resultEdition : undefined };
  },
);

export const connectDriver = createAsyncThunk(
  'connections/connect',
  async (
    payload: {
      credentials: ConnectionDescriptor;
      name?: string | undefined;
      password: string;
      url: string;
      dbName?: string;
      isAuraInstanceFromAPI: boolean;
    },
    { extra, rejectWithValue, dispatch, getState },
  ) => {
    const url = new StdLib.URLs.Neo4jURL(payload.url);

    const credentials =
      payload.credentials.type === 'basic'
        ? { ...payload.credentials, password: payload.password }
        : payload.credentials;

    let tokenProvider;
    if (payload.credentials.type === 'sso') {
      tokenProvider = createTokenProviderSso(
        getCurrentSSOProvider(),
        createUserAndStateWebStorageStores(),
        getRedirectUri,
        authLog,
      );
    }

    if (payload.credentials.type === 'seamless') {
      const auraConfiguration = selectAuraConfiguration(getState());
      const tokenApiUrl = auraConfiguration?.tokenApiUrl;
      if (tokenApiUrl === undefined) {
        throw new SeamlessConnectionError('MissingTokenApiUrl');
      }
      tokenProvider = createTokenProviderSeamless(tokenApiUrl, payload.url, getAccessToken, getIdToken, authLog);
    }

    if (payload.isAuraInstanceFromAPI && url.protocol === 'https:' && !url.port) {
      url.port = AURA_QUERY_API_PORT;
    }

    try {
      await extra.driver.connect(url.toDriverUrl(), credentials, tokenProvider);
    } catch (error) {
      Sentry.captureException(error, (scope) => {
        scope.setTag('incident', 'connection_failure');
        return scope;
      });
      const discoveryUrls = await checkDiscoveryForUrls(url);
      return rejectWithValue({
        discoveryUrls,
        error: {
          ...(isNeo4jError(error) && getNeo4jErrorValues(error)),
        },
      });
    }

    try {
      const { version } = await dispatch(updateVersionAndEdition()).unwrap();

      if (neo4jVersionUtil.compareLoose(version, MINIMUM_SUPPORTED_VERSION) === -1) {
        // TODO: It would make more sense to move getting the version and
        // edition to the connectDriver.fulfilled middleware and store it in
        // Redux. It is very likely we will need to know the edition or version
        // at a later stage and that we'd not have to refetch it. It would also
        // allow us to get rid of this dispatch, the warning would read redux
        // state and see we're on an unsupported version.

        dispatch(
          addNotification({
            type: 'warning',
            title: `Neo4j ${version} is not officially supported.`,
            description: `Workspace supports Neo4j versions >= ${MINIMUM_SUPPORTED_VERSION}.`,
          }),
        );
      }
    } catch (error) {
      if (
        (isSerializedError(error) && error.code === String(CONNECTION_ERROR.CREDENTIALS_EXPIRED)) ||
        (isNeo4jError(error) && error.gqlStatus === String(CONNECTION_ERROR.GQL_STATUS_CREDENTIALS_EXPIRED))
      ) {
        return {
          error: getNeo4jErrorValues(error),
        };
      }

      const errorMessage = `Application supports Neo4j versions >= ${MINIMUM_SUPPORTED_VERSION}.
        Connecting to an unsupported version may lead to incompatibilities, reduced functionality, unexpected bugs, and other issues.
        Error: ${isNeo4jError(error) ? error.message : JSON.stringify(error, null, 2)};`;

      dispatch(
        addNotification({
          type: 'warning',
          title: 'Failed to check Neo4j version.',
          description: errorMessage,
        }),
      );
    }
    return { requestedDbName: payload.dbName };
  },
);

export const disconnectDriver = createAsyncThunk('connections/disconnect', async (_, { extra }) => {
  await extra.driver.disconnect(true);
});

export const disconnectAndConnect = createAsyncThunk(
  'connections/disconnectAndConnect',
  async (
    payload: {
      credentials: ConnectionDescriptor;
      name?: string | undefined;
      password: string;
      url: string;
      dbName?: string;
      isAuraInstanceFromAPI: boolean;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    const state = getState();
    if (state.connections.status === CONNECTION_STATUS.CONNECTED) {
      dispatch(connectionSwitching());
    }

    if (state.connections.status !== CONNECTION_STATUS.DISCONNECTED) {
      await dispatch(disconnectDriver());
    }

    try {
      const result = await dispatch(connectDriver(payload)).unwrap();
      if (state.connections.status === CONNECTION_STATUS.CONNECTED) {
        dispatch(connectionSwitched());
      }
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export type RunQueryActionConfig = Omit<RunQueryConfig, 'signal' | 'sessionConfig'> & {
  sessionConfig?: DriverCore.SessionConfig;
};

export type RunQueryPayload = RunQueryInput &
  RunQueryActionConfig & { metadata: Omit<TransactionMetadataConfig, 'enabledTools'> } & {
    useFrameworkStoreRecordLimit?: boolean;
  };

export const runQuery = createAsyncThunk(
  'connections/runQuery',
  async (payload: RunQueryPayload, { getState, extra, signal, rejectWithValue }) => {
    const state = getState();
    const activeConnection = selectActiveConnection(state.connections);

    if (!activeConnection) {
      throw new DriverConnectionError();
    }

    const activeDatabaseName = selectActiveDatabaseName(state.connections);
    const recordLimit =
      (payload.useFrameworkStoreRecordLimit ?? false) ? state.settings.query.recordLimit : payload.recordLimit;

    const databaseName = payload.sessionConfig?.database ?? activeDatabaseName;
    if (databaseName === undefined) {
      throw new NoDatabaseSelectedError();
    }

    try {
      const result = await extra.driver.runQuery(
        payload,
        {
          metadata: payload.metadata,
          sessionConfig: {
            ...payload.sessionConfig,
            database: databaseName,
          },
          signal,
          transactionCommitType: payload.transactionCommitType,
          transactionMode: payload.transactionMode,
          timeout: payload.timeout,
          recordLimit: recordLimit,
        },
        selectEnabledTools(getState()),
      );

      return result;
    } catch (error) {
      if (error instanceof DriverCore.Neo4jError) {
        return rejectWithValue(getNeo4jErrorValues(error));
      }
      return rejectWithValue({ code: 'Unknown', message: 'An unknown error occurred' });
    }
  },
);

export const updateDatabases = createAsyncThunk(
  'databases/update',
  async (payload: Pick<RunQueryConfig, 'metadata'>, { extra, signal, getState }) => {
    // TODO: Move this to the neo4j-sdk so we can keep the cypher queries in
    // one place and get proper types automatically
    const result = await extra.driver.runQuery(
      { query: 'SHOW DATABASES' },
      {
        metadata: payload.metadata,
        sessionConfig: { database: 'system' },
        signal,
      },
      selectEnabledTools(getState()),
    );

    return result.records.map(toDatabaseObject);
  },
);

export const updateCurrentUser = createAsyncThunk(
  'connections/updateCurrentUser',
  async (payload: Pick<RunQueryConfig, 'metadata'>, { extra, signal, getState }) => {
    // TODO: Move this to the neo4j-sdk so we can keep the cypher queries in
    // one place and get proper types automatically
    const result = await extra.driver.runQuery(
      { query: 'SHOW CURRENT USER' },
      {
        metadata: payload.metadata,
        sessionConfig: { database: 'system' },
        signal,
      },
      selectEnabledTools(getState()),
    );

    return result.records.map(toUserObject)[0];
  },
);

export const setupInitialDbConnection = createAsyncThunk(
  'connections/setupInitialDbConnection',
  async (_, { getState, dispatch }) => {
    const storedConnectInfo = window.sessionStorage.getItem(CREDENTIALS_STORAGE_FIELD);
    const parsedConnectInfo: unknown = JSON.parse(storedConnectInfo ?? 'null');
    const currentConnection = selectActiveConnection(getState().connections) ?? null;

    if (currentConnection !== null && currentConnection.credentials.type === 'seamless') {
      await dispatch(
        connectDriver({
          credentials: currentConnection.credentials,
          password: '',
          url: currentConnection.url,
          isAuraInstanceFromAPI: Boolean(currentConnection.isAuraInstanceFromAPI),
        }),
      ).unwrap();
      return;
    }

    // We have both the selected connection and the credentials, this is the case
    // when the user connected to a DB and refreshed the page.
    if (storedConnectInfo !== null && currentConnection !== null && isDriverConnectInfo(parsedConnectInfo)) {
      if (currentConnection.credentials.type === 'sso' || parsedConnectInfo.authToken.principal !== undefined) {
        await dispatch(
          connectDriver({
            credentials: currentConnection.credentials,
            password: parsedConnectInfo.authToken.credentials,
            url: currentConnection.url,
            isAuraInstanceFromAPI: Boolean(currentConnection.isAuraInstanceFromAPI),
          }),
        ).unwrap();
        return;
      }
    }

    // We have the selected connection but no credentials, this is the case when
    // the user is redirected back to the application from the identity provider
    // in the SSO flow, or when the user leaves the page (and session storage is
    // cleared) and comes back after having connected to a DB via SSO or seamless.
    try {
      if (currentConnection !== null) {
        if (currentConnection.credentials.type === 'seamless') {
          // We need a user session before we can try get a token for the seamless
          // so this step depends on the app session being available.
          await dispatch(
            connectDriver({
              credentials: {
                type: 'seamless' as const,
              },
              password: '',
              url: currentConnection.url,
              isAuraInstanceFromAPI: Boolean(currentConnection.isAuraInstanceFromAPI),
            }),
          ).unwrap();
          return;
        }

        const currentProvider = getCurrentSSOProvider();
        if (currentProvider === null) {
          return;
        }

        // There is an SSO provider defined, so we need to complete the SSO flow.
        const isHandlingSsoRedirect = sessionStorage.getItem(NEO4J_IS_HANDLING_SSO_CALLBACK) === 'true';
        let credentials: Awaited<ReturnType<typeof handleSsoRedirect>> = null;
        if (!isHandlingSsoRedirect) {
          // This is used to avoid a race condition where the SSORedirect
          // component clears the NEO4J_LOCATION_BEFORE_SSO_REDIRECT entry
          // before we're done handling the callback, the DB connection is
          // triggered again and then fails because the handling isn't done.
          // It's a hack, but it works.
          sessionStorage.setItem(NEO4J_IS_HANDLING_SSO_CALLBACK, 'true');
          credentials = await handleSsoRedirect(
            currentProvider,
            createUserAndStateWebStorageStores(),
            getRedirectUri,
            authLog,
          );
          sessionStorage.removeItem(NEO4J_IS_HANDLING_SSO_CALLBACK);
        }

        if (StdLib.isNullish(credentials)) {
          credentials = await getSsoCredentials(
            currentProvider,
            createUserAndStateWebStorageStores(),
            getRedirectUri,
            authLog,
          );

          // This is a fallback in case we never reach the cleanup in the previous
          // step.
          sessionStorage.removeItem(NEO4J_IS_HANDLING_SSO_CALLBACK);
        }

        if (!StdLib.isNullish(credentials)) {
          await dispatch(
            connectDriver({
              credentials: {
                type: 'sso' as const,
                providers: credentials.providers,
              },
              password: credentials.password,
              url: currentConnection.url,
              isAuraInstanceFromAPI: Boolean(currentConnection.isAuraInstanceFromAPI),
            }),
          ).unwrap();
        }
      }
    } catch (error) {
      // do the sso auth log and rethrow the error for the sentry/other error tracking
      authLog(`Error while trying to restore connection: ${String(error)}`, 'error');
      throw error;
    }
  },
);

export const updateDbmsMetadata = createAsyncThunk(
  'connections/updateDbmsMetadata',
  async (payload: { requestedDbName?: string } | undefined, { dispatch }) => {
    await Promise.all([
      dispatch(updateCurrentUser({ metadata: nxMetadata })),
      dispatch(updateDatabases({ metadata: nxMetadata })),
    ]);

    return { requestedDbName: payload?.requestedDbName };
  },
);

export const slice = createSlice({
  name: 'connections',
  initialState,
  reducers: {
    interrupted(state: ConnectionState) {
      if (state.status === CONNECTION_STATUS.CONNECTED) {
        state.status = CONNECTION_STATUS.RECONNECTING;
      }
    },
    provisionedSso(
      state: ConnectionState,
      action: PayloadAction<Partial<Connection> & Pick<Connection, 'url' | 'isAuraInstanceFromAPI'>>,
    ) {
      const id = getConnectionIdFromUrl(action.payload.url);

      const connection = {
        name: id,
        credentials: {
          type: action.payload.credentials?.type === 'seamless' ? ('seamless' as const) : ('sso' as const),
          providers: [],
        },
        url: action.payload.url,
        lastUsedAt: Math.floor(Date.now() / 1000),
        isAuraInstanceFromAPI: action.payload.isAuraInstanceFromAPI,
      };

      sliceConnections.setOne(state.connections, connection);

      state.activeConnectionId = id;

      state.databases = sliceDatabases.removeAll(state.databases);
      state.status = CONNECTION_STATUS.DISCONNECTED;
    },
    recovered(state: ConnectionState) {
      if (state.status === CONNECTION_STATUS.RECONNECTING) {
        state.status = CONNECTION_STATUS.CONNECTED;
      }
    },
    renamed(state: ConnectionState, action: PayloadAction<string>) {
      const connection = selectActiveConnection(state);
      if (!isConnectionState(connection)) {
        throw new InvalidConnectionError();
      }

      const name = action.payload || state.activeConnectionId;

      sliceConnections.updateOne(state.connections, { id: sliceConnections.selectId(connection), changes: { name } });
    },
    switchedDatabase(state: ConnectionState, action: PayloadAction<string>) {
      const connection = selectActiveConnection(state);

      if (!isConnectionState(connection)) {
        throw new InvalidConnectionError();
      }

      assertLogicalDatabaseName(action.payload);
      const databases = selectDatabases(state, action.payload);

      if (databases.length === 0) {
        return;
      }

      state.activeDatabaseName = action.payload;
    },
    rehydrate(state: ConnectionState, action: PayloadAction<ConnectionsPersistedState>) {
      const hydratedState = action.payload;
      sliceConnections.upsertMany(state.connections, hydratedState.connections.entities);
    },
    clearActiveConnectionError(state: ConnectionState) {
      const connection = selectActiveConnection(state);

      if (!isConnectionState(connection)) {
        return;
      }

      sliceConnections.updateOne(state.connections, {
        id: sliceConnections.selectId(connection),
        changes: { error: undefined },
      });
    },
  },
  extraReducers: (builder) => {
    // connectDriver
    builder
      .addCase(connectDriver.pending, (state, action) => {
        const id = getConnectionIdFromUrl(action.meta.arg.url);
        const url = new StdLib.URLs.Neo4jURL(action.meta.arg.url);

        const existingConnection = sliceConnections.selectById(state, id);

        const connection = {
          credentials: action.meta.arg.credentials,
          lastUsedAt: Math.floor(Date.now() / 1000),
          name: action.meta.arg.name ?? existingConnection?.name ?? id,
          url: url.toDriverUrl(),
          isAuraInstanceFromAPI: action.meta.arg.isAuraInstanceFromAPI,
        };

        state.activeDatabaseName = undefined;
        sliceDatabases.removeAll(state.databases);

        sliceConnections.setOne(state.connections, connection);
        state.activeConnectionId = id;
        state.status = CONNECTION_STATUS.CONNECTING;
      })
      .addCase(connectDriver.rejected, (state, action) => {
        const connection = selectActiveConnection(state);

        if (!isConnectionState(connection)) {
          throw new InvalidConnectionError();
        }

        const changes: Partial<Connection> = {};
        if (isRejectedWithValue(action) && isInvalidUrlConnectionError(action.payload)) {
          changes.error = action.payload.error;
          changes.discoveryUrls = action.payload.discoveryUrls;
        } else {
          changes.error = action.error;
        }

        sliceConnections.updateOne(state.connections, { id: sliceConnections.selectId(connection), changes });
        state.status = CONNECTION_STATUS.DISCONNECTED;
        state.currentUser = undefined;
        state.neo4jVersion = undefined;
        state.neo4jEdition = undefined;
      })
      .addCase(connectDriver.fulfilled, (state, action) => {
        const connection = selectActiveConnection(state);

        if (!isConnectionState(connection)) {
          throw new InvalidConnectionError();
        }

        if (action.payload.error) {
          const { error } = action.payload;
          sliceConnections.updateOne(state.connections, {
            id: sliceConnections.selectId(connection),
            changes: { error },
          });
        }

        state.status = CONNECTION_STATUS.CONNECTED;
      });

    // disconnectDriver
    builder
      .addCase(disconnectDriver.pending, (state) => {
        state.status = CONNECTION_STATUS.DISCONNECTING;
      })
      .addCase(disconnectDriver.rejected, () => {
        // TODO: Can this happen? Should this be handled?
      })
      .addCase(disconnectDriver.fulfilled, (state) => {
        slice.caseReducers.clearActiveConnectionError(state);

        state.activeDatabaseName = undefined;
        sliceDatabases.removeAll(state.databases);
        state.status = CONNECTION_STATUS.DISCONNECTED;
        state.activeConnectionId = undefined;
        state.currentUser = undefined;
        state.neo4jVersion = undefined;
        state.neo4jEdition = undefined;
      });

    // updateDatabases
    builder
      .addCase(updateDatabases.rejected, (state) => {
        slice.caseReducers.interrupted(state);
        sliceDatabases.removeAll(state.databases);
      })
      .addCase(updateDatabases.fulfilled, (state, action) => {
        sliceDatabases.setAll(state.databases, action.payload);
      });

    // updateCurrentUser
    builder
      .addCase(updateCurrentUser.rejected, (state) => {
        state.currentUser = undefined;
      })
      .addCase(updateCurrentUser.fulfilled, (state, action) => {
        state.currentUser = action.payload;
      });

    builder
      .addCase(updateVersionAndEdition.rejected, (state) => {
        state.neo4jVersion = undefined;
        state.neo4jEdition = undefined;
      })
      .addCase(updateVersionAndEdition.fulfilled, (state, action) => {
        state.neo4jVersion = action.payload.version;
        state.neo4jEdition = action.payload.edition;
      });

    // Local discovery
    builder.addCase(fetchLocalDiscoveryData.fulfilled, (state, action) => {
      const url = action.payload;
      if (url === null) {
        return;
      }
      const neo4jUrl = StdLib.URLs.Neo4jURL.asNullable(url);
      if (!StdLib.isNullish(neo4jUrl)) {
        state.staticConnectionUrl = neo4jUrl.origin;
      }
    });
  },
});

export const {
  interrupted,
  recovered,
  provisionedSso,
  renamed,
  switchedDatabase,
  rehydrate,
  clearActiveConnectionError,
} = slice.actions;

export default slice;
