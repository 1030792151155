import { analyticsAdapter } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import type { Action, Reducer } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { combineReducers } from 'redux';
import type { PersistMigrate } from 'redux-persist';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, createMigrate, persistReducer } from 'redux-persist';
import type { PersistPartial } from 'redux-persist/lib/persistReducer';

import { neo4jDriverAdapter } from './adapters/neo4j-driver-adapter-instance';
import createAnalyticsEventsMiddleware from './middlewares/analytics-events';
import postConnectionMiddleware from './middlewares/connections';
import { eventListenerMiddleware } from './middlewares/state-event-listeners';
import { Persist } from './persist/persist';
import appContextReducer from './slices/app-context-slice';
import * as Capabilities from './slices/capabilities-slice';
import configuration, {
  CONFIGURATION_PERSISTED_KEYS,
  migrateConfiguration,
} from './slices/configuration/configuration-slice';
import { createConfigurationListenerMiddleware } from './slices/configuration/listeners';
import { migrateConnections } from './slices/connections/connections-migration';
import connections from './slices/connections/connections-slice';
import { createConnectionMiddleware } from './slices/connections/listeners';
import consoleApi, { createAuraApiListenerMiddleware } from './slices/console/console.api';
import dataScienceApi from './slices/data-science/data-science.api';
import dataSummary from './slices/data-summary/data-summary-slice';
import desktop, { DESKTOP_PERSISTED_KEYS } from './slices/desktop/desktop';
import relateApi from './slices/desktop/relate.api';
import featureFlags, { FEATURE_FLAGS_PERSISTED_KEYS } from './slices/feature-flags-slice';
import * as genaiApi from './slices/genai-api-slice';
import graphStyling, { GRAPH_STYLE_PERSISTED_KEYS } from './slices/graph-styling/graph-styling-slice';
import { stylingPrecedenceMiddleware } from './slices/graph-styling/styling-precedence.middleware';
import graphqlApi from './slices/graphql/graphql.api';
import guidePlaylists, {
  GUIDE_PLAYLISTS_PERSISTED_KEYS,
  guidePlaylistsApi,
} from './slices/guides/guide-playlists-slice';
import { migrateGuides } from './slices/guides/guides-migration';
import guides, { GUIDES_PERSISTED_KEYS } from './slices/guides/guides-slice';
import guidesApi from './slices/guides/guides.api';
import { createGuidesMiddleware } from './slices/guides/listeners';
import metadata from './slices/metadata-slice';
import modals from './slices/modals-slice';
import network, { networkDown, networkUp } from './slices/network-slice';
import notifications, { NOTIFICATIONS_PERSISTED_KEYS } from './slices/notifications-slice';
import opsContextReducer from './slices/ops-context-slice';
import opsApi from './slices/ops/ops.api';
import * as Session from './slices/session-slice';
import settings, { SETTINGS_PERSISTED_KEYS, migrateSettings } from './slices/settings-slice';
import { sharedStorageApi } from './slices/shared-storage/shared-storage.api';
import sidebar, { SIDEBAR_PERSISTED_KEYS } from './slices/sidebar-slice';
import uiElements, { UIELEMENTS_PERSISTED_KEYS } from './slices/ui-elements-slice';
import upload from './slices/upload-slice';

const storage = new Persist(APP_SCOPE.framework);

function setupPersist<S, A extends Action>(
  reducer: Reducer<S, A>,
  name: string,
  persistedKeys: string[],
  migrate?: PersistMigrate,
): Reducer<S & PersistPartial, A> {
  return persistReducer(
    {
      key: name,
      storage,
      version: 1,
      throttle: 100,
      whitelist: persistedKeys,
      keyPrefix: '',
      migrate,
    },
    reducer,
  );
}

export const reducer = combineReducers({
  [consoleApi.reducerPath]: consoleApi.reducer,
  modals,
  [relateApi.reducerPath]: relateApi.reducer,
  appContext: appContextReducer,
  [Capabilities.reducerPath]: Capabilities.reducer,
  configuration: setupPersist(configuration, 'configuration', CONFIGURATION_PERSISTED_KEYS, migrateConfiguration),
  connections: persistReducer(
    {
      key: 'connections',
      storage,
      version: 3,
      throttle: 100,
      whitelist: ['activeConnectionId', 'connections', 'settings'],
      keyPrefix: '',
      migrate: migrateConnections,
    },
    connections.reducer,
  ),
  dataSummary,
  desktop: setupPersist(desktop, 'desktop', DESKTOP_PERSISTED_KEYS),
  featureFlags: setupPersist(featureFlags, 'featureFlags', FEATURE_FLAGS_PERSISTED_KEYS),
  guides: setupPersist(guides, 'guides', GUIDES_PERSISTED_KEYS, migrateGuides),
  guidePlaylists: setupPersist(guidePlaylists, 'guidePlaylists', GUIDE_PLAYLISTS_PERSISTED_KEYS),
  [guidePlaylistsApi.reducerPath]: guidePlaylistsApi.reducer,
  metadata,
  notifications: setupPersist(notifications, 'notifications', NOTIFICATIONS_PERSISTED_KEYS),
  session: Session.reducer,
  settings: persistReducer(
    {
      key: 'settings',
      storage,
      version: 2,
      throttle: 100,
      whitelist: SETTINGS_PERSISTED_KEYS,
      keyPrefix: '',
      // @ts-ignore redux-persist types are not good enough https://github.com/rt2zz/redux-persist/issues/1065#issuecomment-554538845
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      migrate: createMigrate(migrateSettings),
    },
    settings,
  ),
  sidebar: setupPersist(sidebar, 'sidebar', SIDEBAR_PERSISTED_KEYS),
  uiElements: setupPersist(uiElements, 'uiElements', UIELEMENTS_PERSISTED_KEYS),
  graphStyling: setupPersist(graphStyling, 'graphStyling', GRAPH_STYLE_PERSISTED_KEYS),
  network,
  [guidesApi.reducerPath]: guidesApi.reducer,
  [genaiApi.reducerPath]: genaiApi.reducer,
  [opsApi.reducerPath]: opsApi.reducer,
  [graphqlApi.reducerPath]: graphqlApi.reducer,
  opsContext: opsContextReducer,
  [dataScienceApi.reducerPath]: dataScienceApi.reducer,
  upload: upload,
  [sharedStorageApi.reducerPath]: sharedStorageApi.reducer,
});

export const store = configureStore({
  reducer,
  devTools: { name: 'Framework Root', trace: true },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          // redux-persist actions
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          // this action contains cypher query result
          'connections/runQuery/fulfilled',
        ],
      },
      thunk: {
        extraArgument: { driver: neo4jDriverAdapter },
      },
    })
      .prepend(
        createConfigurationListenerMiddleware(),
        createConnectionMiddleware({ driver: neo4jDriverAdapter }),
        createAuraApiListenerMiddleware(),
        postConnectionMiddleware,
        createGuidesMiddleware(),
        createAnalyticsEventsMiddleware({ analytics: analyticsAdapter }),
      )
      .concat(
        guidesApi.middleware,
        guidePlaylistsApi.middleware,
        consoleApi.middleware,
        relateApi.middleware,
        opsApi.middleware,
        graphqlApi.middleware,
        eventListenerMiddleware.middleware,
        dataScienceApi.middleware,
        sharedStorageApi.middleware,
        stylingPrecedenceMiddleware.middleware,
      ),
});

setupListeners(store.dispatch);

window.addEventListener('online', () => {
  store.dispatch(networkUp());
});

window.addEventListener('offline', () => {
  store.dispatch(networkDown());
});

/**
 * Enhanced root state (as returned from `combineReducers`)
 */
export type CombinedRootState = ReturnType<typeof reducer>;

/**
 * Plain combined state
 *
 * Represents root state structure with pure state slices
 *
 * @see {@link CombinedRootState}
 */
export type RootState = {
  [Key in keyof CombinedRootState]: NoInfer<Omit<CombinedRootState[Key], keyof PersistPartial>>;
};

export type AppDispatch = typeof store.dispatch;
