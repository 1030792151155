import { useActiveOrg, useActiveProject } from '@nx/state';
import { useLocation, useParams } from 'react-router-dom';

export const useIsOrgContext = () => useLocation().pathname.startsWith('/org/');

export const useAppContextParams = () => useParams<'projectId' | 'orgId'>();

export const useOrgAppContext = () => {
  const isOrgContext = useIsOrgContext();
  if (!isOrgContext) {
    throw new Error('useOrgAppContext used outside of org context (org routes)');
  }
  const activeOrg = useActiveOrg();
  return { activeOrg };
};

export const useProjectAppContext = () => {
  const isOrgContext = useIsOrgContext();
  if (isOrgContext) {
    throw new Error('useProjectAppContext used outside of project context (project routes)');
  }
  const activeOrg = useActiveOrg();
  const activeProject = useActiveProject();
  return { activeOrg, activeProject };
};
