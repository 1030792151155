import { LoadingSpinner } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { consoleApi, getApiError } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import { ControlPanel } from '@nx/ui/src/control-panel';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useEffect, useState } from 'react';

import type { StripeData } from '../shared/billing-address-section';
import { BillingAddressSection } from '../shared/billing-address-section';
import { BillingCardSection } from '../shared/billing-card-section';
import { isSameAddress } from '../shared/helpers';

const logger = createLogger(APP_SCOPE.framework);

type Props = {
  projectId: string;
};

export const Settings = ({ projectId }: Props) => {
  const cardRes = consoleApi.useGetCardQuery(projectId);
  const customerRes = consoleApi.useGetCustomerQuery(projectId);
  const [updateCustomer, updateCustomerRes] = consoleApi.useUpdateCustomerDetailsMutation();
  const [data, setData] = useState<StripeData | null>(null);

  useEffect(() => {
    const customer = customerRes.data;
    const card = cardRes.data;
    if (isNotNullish(customer) && isNotNullish(card) && isNotNullish(customer.address)) {
      setData({
        ...customer,
        address: isNotNullish(customer.serviceAddress) ? customer.serviceAddress : customer.address,
        companyName: card.name === customer.companyName ? '' : customer.companyName,
        isServiceAddress: isNotNullish(customer.serviceAddress)
          ? isSameAddress(customer.address, customer.serviceAddress)
          : true,
      });
    }
  }, [cardRes.data, customerRes.data]);

  if (cardRes.isLoading || customerRes.isLoading) {
    return (
      <ControlPanel className="flex justify-center rounded-tl-none">
        <LoadingSpinner size="large" />
      </ControlPanel>
    );
  }

  if (cardRes.isError || customerRes.isError) {
    return null;
  }

  if (!cardRes.isSuccess || !customerRes.isSuccess || isNullish(data)) {
    return null;
  }

  const handleDataChange = (newData: StripeData) => {
    setData((prev) => ({ ...prev, ...newData }));
  };

  const handleUpdateCustomer = () => {
    updateCustomer({
      projectId,
      ...(!data.isServiceAddress && {
        shipping: {
          address: data.address,
          // We need to have the billingName even though it is static since stripe requires it.
          name: cardRes.data.name,
        },
      }),
      address: customerRes.data.address,
      name: isNotNullish(data.companyName) ? data.companyName : undefined,
      email: data.email,
    }).catch((e: FetchBaseQueryError | SerializedError | undefined) => {
      const error = getApiError(e);
      if (isNotNullish(error.message)) {
        logger.error(error.message);
      }
    });
  };

  return (
    <div className="flex flex-col gap-y-10">
      <BillingCardSection cardDetails={cardRes.data} fetchError={cardRes.error} />

      <BillingAddressSection
        originalData={{ ...customerRes.data }}
        isLoading={updateCustomerRes.isLoading}
        error={updateCustomerRes.error}
        cardAddress={cardRes.data.address}
        stripeData={data}
        onDataChange={handleDataChange}
        onUpdateCustomer={handleUpdateCustomer}
      />
    </div>
  );
};
