import { LanguageSupport } from '@neo4j-cypher/react-codemirror';
import { makeSelectFeatureFlag, LEGACY_store as store } from '@nx/state';
import type {
  CypherRequestId,
  ParamsRequestId,
  RequestId,
  SysInfoRequestId,
  UseDbRequestId,
} from '@query/redux/requests-slice';
import { type ParsedConnectionCommand, parseConnectionCommand } from '@query/utils/connection-command-parser';
import type { ParsedParamArguments } from '@query/utils/params-arg-parser';
import { parseParamArgs } from '@query/utils/params-arg-parser';
import { nanoid } from '@reduxjs/toolkit';

// eslint-disable-next-line no-underscore-dangle
LanguageSupport._internalFeatureFlags.consoleCommands = true;

export type ClientCommandWithCompletions = ':param' | ':clear' | ':history' | ':use' | ':connect' | ':disconnect';

const simpleFrameTypes = ['no-such-command', 'snake', 'welcome'] as const;

export type SimpleFrameType = (typeof simpleFrameTypes)[number];

const frameTypes = [
  ...simpleFrameTypes,
  'multistatement',
  'parameter',
  'cypher',
  'use',
  'connection',
  'sysinfo',
] as const;

export type FrameType = (typeof frameTypes)[number];

export const isFrameType = (type: string): type is FrameType => {
  const options: readonly string[] = frameTypes;
  return options.includes(type);
};

export const commandTypeRequiresConnection = (type: ParsedCommand['type']): boolean => {
  return ['cypher', 'use', 'parameter', 'multistatement', 'sysinfo'].includes(type);
};

type ParsedCommandBase = { requestId: RequestId | null };

export type MultistatementCommand = { raw: string; parsed: ParsedCommand };

export type ParsedCommand = ParsedCommandBase &
  (
    | { type: SimpleFrameType }
    | { type: 'multistatement'; commands: MultistatementCommand[] }
    | { type: 'clear-stream' | 'open-history-sidebar' }
    | { type: 'connection'; command: ParsedConnectionCommand }
    | { requestId: ParamsRequestId | null; type: 'parameter'; args: ParsedParamArguments }
    | { requestId: UseDbRequestId; type: 'use'; requestedDatabaseName: string }
    | { requestId: CypherRequestId; type: 'cypher' }
    | { requestId: SysInfoRequestId; type: 'sysinfo' }
  );

interface ConsoleCommand {
  name: string;
  description?: string;
  commands?: ConsoleCommand[];
}

export const commandAutocompletionData: Record<ClientCommandWithCompletions, ConsoleCommand> = {
  ':clear': {
    name: ':clear',
    description: 'remove all frames from the stream',
  },
  ':param': {
    name: ':param',
    description: 'manage parameters',
    commands: [
      { name: 'list', description: 'show all parameters' },
      { name: 'clear', description: 'delete all parameters' },
    ],
  },
  ':history': {
    name: ':history',
    description: 'open history sidebar',
  },
  ':use': {
    name: ':use',
    description: 'switch database',
  },
  ':connect': {
    name: ':connect',
    description: 'connect to an instance',
  },
  ':disconnect': {
    name: ':disconnect',
    description: 'disconnect from an instance',
  },
};

export const sysInfoCommandAutocompletionData: Record<':sysinfo', ConsoleCommand> = {
  ':sysinfo': {
    name: ':sysinfo',
    description: 'display system information',
  },
};

export const removeCommentsAndTrailingSemi = (rawCommand: string) => {
  const lines = rawCommand.split('\n');
  const result: string[] = [];
  let multiLineComment = false;
  let inString = ``;
  let str = '';

  lines.forEach((line) => {
    for (let idx = 0; idx < line.length; ++idx) {
      if ([`"`, `'`].includes(line[idx] ?? '')) {
        if (!inString) {
          inString = line[idx] ?? '';
        } else if (inString === line[idx]) {
          inString = '';
        }
      }
      if (!multiLineComment && !inString) {
        if (line[idx] === '/' && line[idx + 1] === '/') {
          break;
        } else if (line[idx] === '/' && line[idx + 1] === '*') {
          multiLineComment = true;
          idx += 1;
          continue;
        }
      } else if (multiLineComment && line[idx] === '*' && line[idx + 1] === '/') {
        multiLineComment = false;
        idx += 1;
        continue;
      }
      if (!multiLineComment) {
        str += line[idx];
      }
    }
    if (str.length && !multiLineComment) {
      result.push(str);
      str = '';
    }
  });
  const withoutComments = result
    .map((line) => line.trim())
    .filter((line) => line.length > 0)
    .join('\n');

  return withoutComments.endsWith(';') ? withoutComments.slice(0, -1) : withoutComments;
};

export const extractClientCommandAndSimpleArgsFromStatement = (
  statement: string,
): { clientCommand: string; args: string[] } => {
  const [name = '', ...args] = statement.split(/\s+/g);
  return { clientCommand: name.toLowerCase(), args };
};

export function parseCommand(cmd: string): ParsedCommand {
  const statements = LanguageSupport.parseStatementsStrs(cmd);

  const isSysInfoFrameEnabled = makeSelectFeatureFlag('query:enable-sysinfo-frame')(store.getState());

  if (statements.length > 1) {
    return {
      type: 'multistatement',
      commands: statements.flatMap((raw) => {
        const trimmed = raw.trim();
        const sanitized = removeCommentsAndTrailingSemi(trimmed);
        return sanitized ? [{ raw: trimmed, parsed: parseCommand(sanitized) }] : [];
      }),
      requestId: null,
    };
  }
  const [rawStatement] = statements;

  if (rawStatement === undefined) {
    return { type: 'no-such-command', requestId: null };
  }

  const statement = removeCommentsAndTrailingSemi(rawStatement.trim());

  if (!statement.startsWith(':')) {
    return { type: 'cypher', requestId: `cypherid-${nanoid()}` };
  }

  const { clientCommand, args } = extractClientCommandAndSimpleArgsFromStatement(statement);

  switch (clientCommand) {
    case ':param':
    case ':params': {
      const parsedParams = parseParamArgs(statement);
      const setRequestId = parsedParams.arg === 'set-single' || parsedParams.arg === 'set-map';

      return {
        type: 'parameter',
        args: parsedParams,
        requestId: setRequestId ? `paramid-${nanoid()}` : null,
      };
    }
    case ':clear':
      return { type: 'clear-stream', requestId: null };
    case ':history':
      return { type: 'open-history-sidebar', requestId: null };
    case ':snake':
      return { type: 'snake', requestId: null };
    case ':welcome':
      return { type: 'welcome', requestId: null };
    case ':connect':
    case ':disconnect':
    case ':server': {
      const command = parseConnectionCommand(statement);
      return { type: 'connection', command, requestId: null };
    }
    case ':use': {
      let dbName = args[0]?.trim()?.toLowerCase() ?? '';
      if (dbName.startsWith('`') && dbName.endsWith('`')) {
        dbName = dbName.slice(1, dbName.length - 1);
      }
      return { type: 'use', requestedDatabaseName: dbName, requestId: `usedbid-${nanoid()}` };
    }
    case ':sysinfo':
      return isSysInfoFrameEnabled
        ? { type: 'sysinfo', requestId: `sysinfoid-${nanoid()}` }
        : { type: 'no-such-command', requestId: null };
    default:
      return { type: 'no-such-command', requestId: null };
  }
}
