import { Banner, Button, Dialog, LoadingSpinner, Tooltip } from '@neo4j-ndl/react';
import { AURA_CONSOLE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import type { Instance } from '@nx/state';
import { consoleApi, getApiError, getErrorMessage, useActiveProject } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useEffect, useState } from 'react';

import { useTrackUpxEvent } from '../../../services/segment/analytics';
import type { Validation } from '../../../utils/validation';
import { filterAvailableCloneInstances, validate } from './entities/helper';
import type { CloneToExistingData } from './entities/model';
import { CloneToExistingForm } from './form';

const logger = createLogger(APP_SCOPE.framework);

type CloneToExistingModalProps = {
  instance: Instance;
  onClose: () => void;
  onSuccess: () => void;
};

export const CloneToExistingModal = ({ instance, onClose, onSuccess }: CloneToExistingModalProps) => {
  const trackEvent = useTrackUpxEvent();
  const { id: projectId } = useActiveProject();
  const [getInstance] = consoleApi.useLazyGetInstanceQuery();
  const { data: instanceSummaries = [], ...res } = consoleApi.useListInstancesQuery(projectId);
  const [overwriteInstance, overwriteInstanceRes] = consoleApi.useOverwriteInstanceMutation();
  const [instances, setInstances] = useState<Instance[]>([]);
  const [isLoading, setIsLoading] = useState(res.isLoading);
  const [data, setData] = useState<CloneToExistingData>({
    name: instance.name,
    confirmed: false,
    targetInstance: null,
  });
  const [validation, setValidation] = useState<Validation<CloneToExistingData> | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleChange = (newData: CloneToExistingData) => {
    if (isNotNullish(validation)) {
      setValidation(null);
    }
    setData((prev) => ({ ...prev, ...newData }));
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.all(instanceSummaries.map((i) => getInstance(i.id).unwrap()))
      .then((list) => setInstances(filterAvailableCloneInstances(instance, list)))
      .catch((err: FetchBaseQueryError | SerializedError | undefined) => {
        const apiError = getApiError(err);
        const message = getErrorMessage(apiError);
        setErrorMessage(message);
        logger.error(message);
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    const error = validate(data);
    if (isNotNullish(error)) {
      setValidation(error);
    }
    if (isNullish(data.targetInstance)) {
      return;
    }
    setValidation(null);
    overwriteInstance({ sourceDbId: instance.id, dbId: data.targetInstance.id })
      .unwrap()
      .then(() => onSuccess())
      .catch((err: FetchBaseQueryError | SerializedError | undefined) => {
        const apiError = getApiError(err);
        const message = getErrorMessage(apiError);
        setErrorMessage(message);
        logger.error(message);
      });

    trackEvent({
      event: AURA_CONSOLE_EVENTS.INSTANCE_CLONE_TO_EXISTING,
      properties: {
        sourceTier: instance.tier,
        targetTier: data.targetInstance.tier,
      },
      scope: APP_SCOPE.aura,
    });
  };

  return (
    <Dialog isOpen onClose={onClose} modalProps={{ 'data-testid': 'clone-to-existing-modal' }}>
      <Dialog.Header>Clone to existing</Dialog.Header>
      {isLoading ? (
        <LoadingSpinner size="medium" className="m-auto" />
      ) : (
        <>
          <Dialog.Content>
            <CloneToExistingForm
              validation={validation}
              onChange={handleChange}
              instance={instance}
              targetInstances={instances}
              data={data}
            />
            {isNotNullish(errorMessage) && (
              <Banner description={errorMessage} type="danger" className="mt-4" hasIcon usage="inline" />
            )}
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              color="neutral"
              fill="outlined"
              onClick={onClose}
              isDisabled={overwriteInstanceRes.isLoading}
              htmlAttributes={{
                'data-testid': 'clone-to-existing-db-modal-cancel',
              }}
            >
              Cancel
            </Button>
            <Tooltip
              placement="top"
              type="simple"
              isDisabled={data.confirmed || instances.length === 0}
              isPortaled={false}
            >
              <Tooltip.Trigger hasButtonWrapper>
                <Button
                  color="primary"
                  onClick={handleSubmit}
                  isLoading={overwriteInstanceRes.isLoading}
                  isDisabled={!data.confirmed || instances.length === 0}
                  htmlAttributes={{
                    'data-testid': 'clone-to-existing-db-modal-clone',
                  }}
                >
                  Clone
                </Button>
              </Tooltip.Trigger>
              <Tooltip.Content style={{ width: 200, position: 'fixed' }}>
                You must confirm that you understand the consequences of cloning into an existing instance.
              </Tooltip.Content>
            </Tooltip>
          </Dialog.Actions>
        </>
      )}
    </Dialog>
  );
};
