import { Banner, Button, Dialog, Select } from '@neo4j-ndl/react';
import { AURA_CONSOLE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { CDC_ENRICHMENT_MODE, type Instance, consoleApi, getApiError, getErrorMessage } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';

import { useTrackUpxEvent } from '../../services/segment/analytics';
import { CDCDisableWarning, CDCEnableWarning } from './warnings';

const logger = createLogger(APP_SCOPE.framework);

interface Props {
  onClose: () => void;
  onSuccess: () => void;
  instance: Instance;
}

const options = [
  {
    value: CDC_ENRICHMENT_MODE.OFF,
    label: 'OFF',
    key: CDC_ENRICHMENT_MODE.OFF,
  },
  {
    value: CDC_ENRICHMENT_MODE.DIFF,
    label: 'DIFF',
    key: CDC_ENRICHMENT_MODE.DIFF,
  },
  {
    value: CDC_ENRICHMENT_MODE.FULL,
    label: 'FULL',
    key: CDC_ENRICHMENT_MODE.FULL,
  },
];

export const EditCDCModeModal = ({ onClose, onSuccess, instance }: Props) => {
  const trackEvent = useTrackUpxEvent();
  const initialCDCMode = instance.desiredSettings.cdcEnrichmentMode;
  const [editCDCMode, editCDCModeRes] = consoleApi.useUpdateCDCModeMutation();
  const [cdcMode, setCDCMode] = useState(initialCDCMode ?? CDC_ENRICHMENT_MODE.OFF);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleEditCDCMode = (event: SyntheticEvent) => {
    event.preventDefault();
    editCDCMode({ dbId: instance.id, cdcEnrichmentMode: cdcMode })
      .unwrap()
      .then(() => onSuccess())
      .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(e);
        const message = getErrorMessage(error);
        setErrorMessage(message);
        if (isNotNullish(error.message)) {
          logger.error(error.message);
        }
      });
    trackEvent({
      event: AURA_CONSOLE_EVENTS.INSTANCE_CDC_UPDATE,
      properties: { cdcMode },
      scope: APP_SCOPE.aura,
    });
  };

  const handleCDCModeChange = (value?: CDC_ENRICHMENT_MODE) => {
    setCDCMode(value ?? CDC_ENRICHMENT_MODE.OFF);
  };

  const value = options.find((option) => option.value === cdcMode) ?? options[0];

  return (
    <Dialog isOpen onClose={onClose} modalProps={{ 'data-testid': 'change-cdc-mode-dialog' }}>
      <Dialog.Header>Edit CDC Mode</Dialog.Header>
      <form onSubmit={handleEditCDCMode}>
        <Dialog.Content>
          <Select
            type="select"
            size="medium"
            selectProps={{
              options,
              onChange: (entry) => handleCDCModeChange(entry?.value),
              isSearchable: false,
              value,
              menuPosition: 'fixed',
            }}
            label="CDC Mode"
          />
          {isNotNullish(errorMessage) && <Banner type="danger" hasIcon description={errorMessage} usage="inline" />}
          {initialCDCMode === CDC_ENRICHMENT_MODE.OFF && cdcMode !== CDC_ENRICHMENT_MODE.OFF && <CDCEnableWarning />}
          {initialCDCMode !== CDC_ENRICHMENT_MODE.OFF && cdcMode === CDC_ENRICHMENT_MODE.OFF && <CDCDisableWarning />}
        </Dialog.Content>
        <Dialog.Actions>
          <Button color="neutral" fill="outlined" isDisabled={editCDCModeRes.isLoading} onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            isLoading={editCDCModeRes.isLoading}
            type="submit"
            isDisabled={cdcMode === instance.desiredSettings.cdcEnrichmentMode}
          >
            Update
          </Button>
        </Dialog.Actions>
      </form>
    </Dialog>
  );
};
