// TODO: consolidate to /constants folder
import type { NvlOptions } from '@neo4j-nvl/base';
import { neo4jVersionUtil } from '@nx/neo4j-version-utils';

import * as pkg from '../package.json';
import type { BannerConfig } from './types';

export const APP_VERSION = pkg.version;
export const GRAPH_SCHEMA_VERSION: string = neo4jVersionUtil
  .coerce(pkg.dependencies['@neo4j/graph-schema-utils'])
  .toString();
export const STANDALONE_STORAGE_KEY = 'neo4j-data-importer';

export const IMPORT_CHUNK_SIZE = 1000000;

export const TOOLTIPS = {
  nodeLabel: `A Label identifies the type of a node. You may for example use the label "Person" to represent People \
in your graph data model.`,
  nodeFile: 'The file containing information related to the node that will be used for property definitions.',
  nodeTable: 'The table containing information related to the node that will be used for property definitions.',
  nodeProperties:
    'A node can have one or more properties. It must have at least one property that is configured for use as the ID.',
  nodeId: 'A property that is used to uniquely identify the node.',
  relationshipType: `Type should be a name that describes the relationship between two nodes. For example a relationship \
between Person and Address nodes might be given the type LIVES_AT to represent that a Person lives at the linked Address.`,
  relationshipFile: `The file that contains the IDs that link the Nodes at each end of the relationship. This file could \
  also contain properties pertaining to the relationship.`,
  relationshipTable: `The table that contains the IDs that link the Nodes at each end of the relationship. This table could \
also contain properties pertaining to the relationship.`,
  relationshipProperties: 'Optionally, any properties that should be associated with the relationship.',
  deleteNodeAndRelationship: `Delete selection`,
  addNode: 'Add node label',
  idColumnHelp: 'Set the property that is used to uniquely identify the node',
};

export const COMMON_NVL_OPTIONS: NvlOptions = {
  disableWebGL: true,
  useWebGL: false,
  relationshipThreshold: 0.62,
  disableTelemetry: true,
};

// Validation messages to be reused in e2e tests:
// Can not be defined in validation-errors.ts since importing that file causes import of state package (types) which
// in turn then cause the e2e test to fail because vite variables are not defined (import.meta.env stuff).
// Those variables are planned to be removed but until then need to define these messages here.
// (ref: https://trello.com/c/cN5dIodb)
export const emptyFieldErrorMessage = 'Cannot be empty';
export const duplicatedErrorMessage = 'Must be unique';
export const noSupportedTypesErrorMessage = 'No supported neo4j type conversions';
export const noKeyPropertyErrorMessage =
  'An ID property is required to run an import. Click a key to define an identifier.';
export const nonSpecifiedErrorMessage = 'Must be specified';

// Id prefixes for the different types of entities in the import model
export const NODE_ID_PREFIX = 'n:';
export const NODE_LABEL_ID_PREFIX = 'nl:';
export const RELATIONSHIP_ID_PREFIX = 'r:';
export const RELATIONSHIP_TYPE_ID_PREFIX = 'rt:';
export const PROPERTY_ID_PREFIX = 'p:';
export const INDEX_ID_PREFIX = 'i:';
export const CONSTRAINT_ID_PREFIX = 'c:';

export const ERROR_LOADING_DATA_SOURCE: BannerConfig = {
  type: 'danger',
  title: 'Could not load the data source',
  description: 'Error loading the data source: ',
};

export const IMPORT_MODEL_STORAGE_MESSAGES = {
  onNewImportModelCreating: 'Creating a new import model...',
  onNewImportModelCreatedSuccess: 'Graph model created!',
  onNewImportModelCreateFailure: 'Failed to create model. Check your internet connection.',
  onNewImportModelCreatedSuccessWithFullCandidateGraph: 'Graph model generated. Please review the model.',
  onNewImportModelCreatedSuccessWithCandidateGraphNoRelationships:
    'Graph model generated. We couldn’t generate any relationships, please review the model.',
  onUpdateImportModelCreateFailure: 'Failed to save model to server. Check your internet connection.',
};

export const DATA_SOURCE_SCHEMA_FETCH_MESSAGES = {
  onLoadDataSourceSchemaSuccess: 'Schema loaded successfully.',
  onLoadDataSourceSchemaSuccessWithEmptySchema: 'The schema is empty.',
  onRefreshDataSourceSchemaSuccess: 'Schema is successfully updated',
};

export const CANDIDATE_GRAPH_FETCH_MESSAGES = {
  onLoadCandidateGraphFailure: 'Failed to load suggested graph.',
};
