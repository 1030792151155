import type { LegacyImportDataSource, SavedImportDataSource } from '@nx/api';
import * as ImportShared from '@nx/import-shared';
import { isNullish } from '@nx/stdlib';

import {
  getAllFieldsInDefinition,
  getFieldValueFromDataSourceConfig,
  getFieldWithCorrectType,
  getNumberValue,
} from '../data-sources/data-sources-utils';

const getFieldValueFromLegacyConfig = (
  fieldName: string,
  dataSourceData: ImportShared.LegacyDataSourceConfig | ImportShared.LegacyDataSourceToSave,
): ImportShared.FormFieldValue => {
  // To make this function work for both LegacyDataSourceConfig and LegacyDataSourceToSave, we need to check that
  // the password field is present separately
  if (fieldName === 'password' && 'password' in dataSourceData) {
    return dataSourceData[fieldName];
  }

  // Switch (or some other check that strings are available in DataSourceToSave) is needed to keep typescript happy
  switch (fieldName) {
    case 'port': {
      const value = dataSourceData[fieldName];
      return getNumberValue(value);
    }
    case 'name':
    case 'driver':
    case 'host':
    case 'user':
    case 'database':
    case 'schema':
    case 'warehouse':
    case 'role':
    case 'service': {
      const value = dataSourceData[fieldName];
      return value ?? '';
    }
    default:
      return '';
  }
};

const getFieldWithValueFromLegacyConfig = (
  field: ImportShared.FieldDefinition,
  dataSourceData: ImportShared.LegacyDataSourceConfig | ImportShared.LegacyDataSourceToSave,
): ImportShared.Field => {
  const value = getFieldValueFromLegacyConfig(field.name, dataSourceData);
  return getFieldWithCorrectType(field, value);
};

const getFieldStringValueFromDynamicConfig = (
  fieldName: string,
  dataSourceConfig: ImportShared.DataSourceConfig,
): string | undefined => {
  const value = getFieldValueFromDataSourceConfig(fieldName, dataSourceConfig);
  if (typeof value !== 'string') {
    return undefined;
  } else if (value === '') {
    return undefined;
  }
  return value;
};

const getFieldNumberValueFromDynamicConfig = (
  fieldName: string,
  dataSourceConfig: ImportShared.DataSourceConfig,
): number | undefined => {
  const value = getFieldValueFromDataSourceConfig(fieldName, dataSourceConfig);
  const numberValue = Number(value);
  if (isNaN(numberValue)) {
    return undefined;
  }
  return numberValue;
};

const getDataSourceConfigFromLegacy = (
  dataSourceData: ImportShared.LegacyDataSourceToSave | ImportShared.LegacyDataSourceConfig,
  dataSourceDefinition: ImportShared.DataSourceDefinition,
): ImportShared.DataSourceConfig | undefined => {
  const fields = getAllFieldsInDefinition(dataSourceDefinition);
  const fieldsWithValue = fields.map((field) => {
    return getFieldWithValueFromLegacyConfig(field, dataSourceData);
  });
  return {
    name: dataSourceData.name,
    type: dataSourceData.driver,
    fields: fieldsWithValue,
  };
};

export const getSavedImportDataSourceFromLegacy = (
  dataSourceData: LegacyImportDataSource,
  dataSourceDefinition: ImportShared.DataSourceDefinition,
): SavedImportDataSource | undefined => {
  const data = getDataSourceConfigFromLegacy(dataSourceData, dataSourceDefinition);
  if (!isNullish(data)) {
    return {
      ...data,
      id: dataSourceData.id,
      createdAt: dataSourceData.createdAt,
      updatedAt: dataSourceData.updatedAt,
    };
  }

  return undefined;
};

// TODO: When migrating save of data sources from shared storage to import service storage,
// can get rid of all places using the legacy format and save in the new format instead (fields separate)
export const getLegacyDataSourceConfig = (
  sourceConfig: ImportShared.DataSourceConfig | SavedImportDataSource,
): ImportShared.LegacyDataSourceConfig => {
  if (!ImportShared.isLegacyDataSource(sourceConfig.type)) {
    throw Error(`Data source type not yet supported: ${sourceConfig.type}`);
  }
  return {
    name: sourceConfig.name,
    driver: sourceConfig.type,
    user: getFieldStringValueFromDynamicConfig('user', sourceConfig) ?? '',
    password: getFieldStringValueFromDynamicConfig('password', sourceConfig) ?? '',
    host: getFieldStringValueFromDynamicConfig('host', sourceConfig) ?? '',
    port: getFieldNumberValueFromDynamicConfig('port', sourceConfig) ?? 0,
    database: getFieldStringValueFromDynamicConfig('database', sourceConfig),
    schema: getFieldStringValueFromDynamicConfig('schema', sourceConfig),
    warehouse: getFieldStringValueFromDynamicConfig('warehouse', sourceConfig),
    service: getFieldStringValueFromDynamicConfig('service', sourceConfig),
    role: getFieldStringValueFromDynamicConfig('role', sourceConfig),
  };
};

export const getLegacyDataSourceToSave = (
  sourceConfig: ImportShared.DataSourceConfig,
): ImportShared.LegacyDataSourceToSave => {
  const oldConf = getLegacyDataSourceConfig(sourceConfig);
  if (!ImportShared.isLegacyDataSource(sourceConfig.type)) {
    throw Error(`Data source type not yet supported: ${sourceConfig.type}`);
  }
  return {
    name: oldConf.name,
    driver: oldConf.driver,
    user: oldConf.user,
    host: oldConf.host,
    port: oldConf.port,
    database: oldConf.database,
    schema: oldConf.schema,
    warehouse: oldConf.warehouse,
    service: oldConf.service,
    role: oldConf.role,
  };
};
