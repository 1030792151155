import { useConnectionRouterState, validateForm } from '../connection-form-utils';
import type { ConnectionFormValues } from '../connection-form.types';
import { FormBanner } from './form-banner';

export const SafariUnencryptedConnectionWarning = ({ formValues }: { formValues: ConnectionFormValues }) => {
  const { isSafariUnsecure } = validateForm(formValues);

  if (!isSafariUnsecure) {
    return undefined;
  }

  return (
    <FormBanner
      hasIcon
      description="Unencrypted connections are not supported in Safari. Try another browser."
      type="warning"
    />
  );
};

export const ActionRequiresConnectionInfo = () => {
  const { actionRequiresConnection } = useConnectionRouterState();

  if (!actionRequiresConnection) {
    return undefined;
  }

  return <FormBanner hasIcon description="This action requires a database connection." type="info" />;
};

export const FromExternalLinkInfo = () => (
  <FormBanner
    description={`Username and password are stored in the file that you
              downloaded when you created this instance.`}
    type="info"
  />
);
