import { LoadingSpinner, TextLink, Typography } from '@neo4j-ndl/react';
import { InformationCircleIconOutline } from '@neo4j-ndl/react/icons';
import { useFlags } from '@nx/launch-darkly-service';
import type { Instance } from '@nx/state';
import { TIER, useActiveProject, useActiveProjectSummary, useAuraConfiguration, useInstances } from '@nx/state';
import { BackButton } from '@nx/ui';
import { ControlPanel } from '@nx/ui/src/control-panel';
import { memo, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { withUpxContext } from '../shared/components/upx-context';
import { arePropsDeepEqual } from '../shared/ui-helpers';
import { MetricsEndpointsStatusTable } from './metrics-endpoints-status';
import './metrics-integration.css';
import MetricsIntegrationConfigModal from './modals/prometheus-config-modal';
import promConfigTemplate from './modals/prometheus-config-template';
import { NoEligibleInstances } from './shared/components';
import { formatAuraApiDomain, formatCmiDomain, getMetricsPathParam } from './shared/utils';

const MetricsIntegrationView = memo(
  ({
    instances,
    cmiDomain,
    viewPrometheusConfig,
    allowMetricsGranularitySettings,
  }: {
    viewPrometheusConfig: (dbId: string | null) => void;
    instances: Instance[];
    cmiDomain: string;
    allowMetricsGranularitySettings: boolean;
  }) => {
    const activeProject = useActiveProject();
    const eligibleInstances = instances.filter((instance) =>
      [TIER.ENTERPRISE, TIER.MTE, TIER.AURA_DSE].includes(instance.tier),
    );
    return eligibleInstances.length > 0 ? (
      <div
        className="mb-6 flex flex-col gap-6"
        data-testid="metrics-integration-status"
        aria-label="Metrics integration view"
      >
        <ControlPanel className="!p-0">
          <MetricsEndpointsStatusTable
            allowMetricsGranularitySettings={allowMetricsGranularitySettings}
            viewPrometheusConfig={viewPrometheusConfig}
            activeProject={activeProject}
            instances={eligibleInstances}
            cmiDomain={cmiDomain}
          />
        </ControlPanel>
        <ControlPanel className="!p-2">
          <div className="my-4 flex">
            <div>
              <InformationCircleIconOutline className="mx-[10px] my-[1px] h-[20px] w-[20px]" />
            </div>
            <div>
              <Typography variant="body-large" className="text-palette-neutral-text-weaker">
                Accessing metric endpoints requires Aura API credentials. You can find and create them{' '}
                <TextLink
                  as="span"
                  htmlAttributes={{
                    'aria-label': 'Aura API credentials',
                  }}
                >
                  <Link to="/account/api-keys">here</Link>
                </TextLink>
                .
              </Typography>
            </div>
          </div>
        </ControlPanel>
      </div>
    ) : (
      <NoEligibleInstances />
    );
  },
  arePropsDeepEqual,
);

MetricsIntegrationView.displayName = 'MetricsIntegrationView';

export const MetricsIntegrationPage = () => {
  const activeProjectSummary = useActiveProjectSummary();
  const { enableCmiComprehensiveMetrics } = useFlags();
  const { instances, isInstancesLoading } = useInstances({ shouldPoll: false });
  const auraConfig = useAuraConfiguration();

  const auraDomain = formatAuraApiDomain(auraConfig?.environment);
  const cmiDomain = formatCmiDomain(auraConfig?.environment);

  const [isPrometheusConfigModalOpen, setIsPrometheusConfigModalOpen] = useState(false);
  const [promConfig, setPromConfig] = useState('');

  const showPrometheusConfigTemplate = useCallback(
    (dbId: string | null) => {
      let jobName = 'aura-metrics';
      if (dbId !== null && dbId !== '') {
        const db = instances.find((i) => i.id === dbId);
        jobName = db ? `${db.name}-${jobName}` : `${dbId}-${jobName}`;
      } else {
        jobName = activeProjectSummary ? `${activeProjectSummary.name}-${jobName}` : jobName;
      }
      const metricsPathParam = getMetricsPathParam(activeProjectSummary, dbId);
      setPromConfig(promConfigTemplate(metricsPathParam, auraDomain, cmiDomain, jobName));
      setIsPrometheusConfigModalOpen(true);
    },
    [activeProjectSummary, auraDomain, cmiDomain, instances],
  );

  if (activeProjectSummary === undefined) {
    return (
      <span className="n-body-large text-palette-neutral-text-default" data-testid="no-instances-message">
        You have no active projects.
      </span>
    );
  }

  if (!auraDomain || !cmiDomain) {
    return (
      <span className="n-body-large text-palette-neutral-text-default" data-testid="no-instances-message">
        Missing configuration.
      </span>
    );
  }

  return (
    <>
      <div className="metrics-integration p-4">
        <BackButton to="../settings" />

        <div aria-label="Metrics integration header" className="flex items-baseline gap-2">
          <Typography variant="h2" className="mb-6 px-2 pt-2">
            Metrics Integration
          </Typography>
          <TextLink
            type="external"
            href="https://neo4j.com/docs/aura/metrics/metrics-integration/introduction/"
            htmlAttributes={{
              target: '_blank',
              rel: 'noreferrer',
            }}
          >
            Docs
          </TextLink>
        </div>

        {isInstancesLoading && <LoadingSpinner className="mx-auto" />}
        {!isInstancesLoading && instances.length === 0 && (
          <span className="n-body-large text-palette-neutral-text-default" data-testid="no-instances-message">
            You have no running instances.
          </span>
        )}

        {!isInstancesLoading && !(instances.length === 0) && (
          <MetricsIntegrationView
            viewPrometheusConfig={showPrometheusConfigTemplate}
            instances={instances}
            cmiDomain={cmiDomain}
            allowMetricsGranularitySettings={enableCmiComprehensiveMetrics}
          />
        )}

        <MetricsIntegrationConfigModal
          prometheusConfig={promConfig}
          open={isPrometheusConfigModalOpen}
          onClose={() => setIsPrometheusConfigModalOpen(false)}
        />
      </div>
    </>
  );
};

const MetricsIntegrationPageWrapperWithUpxContext = withUpxContext(MetricsIntegrationPage);

export default MetricsIntegrationPageWrapperWithUpxContext;
