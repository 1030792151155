import { Typography } from '@neo4j-ndl/react';
import cn from 'classnames';
import type { MouseEventHandler } from 'react';

import type { Tab } from '../../types';
import { NodeSizeRepresentation, RelSizeRepresentation } from './size-representation';

export const SIZE_OPTIONS = [0.25, 0.5, 1, 2, 4];

interface SizePickerProps {
  tab: Tab;
  current?: number;
  color: string;
  setSize: (size: number) => MouseEventHandler<HTMLDivElement>;
  disabled: boolean;
}

export const SizePicker = ({ setSize, tab, color, current = 1, disabled }: SizePickerProps) => (
  <div className={disabled ? 'cursor-not-allowed' : undefined}>
    {SIZE_OPTIONS.map((size) => (
      <div
        className="full-width flex flex-row"
        data-testid="size-picker-entry"
        key={size}
        onClick={!disabled ? setSize(size) : undefined}
      >
        <div
          className={cn(
            'my-2 flex h-[72px] w-full flex-row justify-between rounded-sm border',
            current === size
              ? 'border-palette-primary-focus border-[2px]'
              : 'border-palette-neutral-border-strong hover:border-palette-primary-border-strong hover:bg-palette-neutral-bg-default',
            {
              'cursor-pointer': !disabled,
            },
          )}
          data-testid="size-option"
        >
          <Typography
            variant="body-medium"
            className="full-height flex flex-col justify-center pl-6"
            htmlAttributes={{
              'data-testid': 'size-option-text',
            }}
          >
            {`${size}x`}
          </Typography>
          <div className="mr-6 flex w-[46px] items-center justify-center">
            {tab === 'categories' ? (
              <NodeSizeRepresentation color={color} size={size} />
            ) : (
              <RelSizeRepresentation color={color} size={size} />
            )}
          </div>
        </div>
      </div>
    ))}
  </div>
);
