import { useCallback } from 'react';

import { useDispatch, useSelector } from '../context';
import * as Selectors from '../selectors';
import * as Notifications from '../slices/notifications-slice';

export function useNotifications() {
  const dispatch = useDispatch();
  const notifications = useSelector(Selectors.selectNotifications);

  const addNotification = useCallback(
    (payload: Notifications.NxNotification) => dispatch(Notifications.addNotification(payload)),
    [dispatch],
  );

  const updateNotification = useCallback(
    (payload: Notifications.FullNxNotification) => dispatch(Notifications.updateNotification(payload)),
    [dispatch],
  );

  const removeNotification = useCallback((id: string) => dispatch(Notifications.removeNotification(id)), [dispatch]);

  return {
    addNotification,
    updateNotification,
    removeNotification,
    notifications,
  };
}

/**
 * @see {useNotifications}
 * @deprecated Use {useNotifications}
 */
export const useNotificationActions = useNotifications;
