import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable';
import { Checkbox, Menu } from '@neo4j-ndl/react';
import { DragIcon, TableCellsIconOutline } from '@neo4j-ndl/react/icons';
import type { DisplayColumnDef, Table } from '@tanstack/react-table';
import classNames from 'classnames';
import { useCallback } from 'react';

import ActionableWithMenu from '../../../metrics/shared/components/actionable-with-menu';
import { ACTIONS_COLUMN_ID } from '../types';

type SortableColumnItemProps = {
  id: string;
  headerText: string;
  checked: boolean;
  disabled: boolean;
  onCheck: React.ChangeEventHandler<HTMLInputElement>;
};

const SortableColumnItem = ({ id, headerText, checked, disabled, onCheck }: SortableColumnItemProps) => {
  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({ id });

  // could use CSS.Transform.toString() from @dnd-kit/utilities
  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={classNames('flex justify-between rounded-lg p-2 pb-1', {
        'bg-palette-neutral-bg-weak shadow-overlay z-10': isDragging,
      })}
    >
      <Checkbox key={id} isChecked={checked} isDisabled={disabled} onChange={onCheck} value={id} label={headerText} />
      <div {...attributes} {...listeners}>
        <DragIcon
          className={classNames('ndl-icon n-w-6 n-h-6 cursor-pointer', {
            'cursor-grab': isDragging,
          })}
        />
      </div>
    </div>
  );
};

/**
 * This component consists of one icon button and a context menu toggled by the button.
 * It does not keep an internal state for the column preferences (order, visibility), instead
 * it reads from and mutates the table instance directly.
 */

export const ColumnPreferences = ({ table }: { table: Table<unknown> }) => {
  const isOnlyOneChecked = table.getVisibleLeafColumns().length === 2;

  const onCheckHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const clickedColumnId = e.target.value;
      const column = table.getAllFlatColumns().find((c) => c.id === clickedColumnId);

      if (!column) {
        return;
      }

      if (isOnlyOneChecked && column.getIsVisible()) {
        throw new Error('Unreachable action, last checked column should be disabled. Cannot hide all table columns.');
      }

      column.toggleVisibility(!column.getIsVisible());
    },
    [isOnlyOneChecked, table],
  );

  const configurableColumns = table
    .getAllLeafColumns()
    /* exclude  non-string headers ("action") and columns explicitly configured to be off preferences */
    .filter(
      /* https://stackoverflow.com/a/54317362/3247715 */
      (c): c is typeof c & { columnDef: { header: string } } =>
        typeof c.columnDef.header === 'string' && !(c.columnDef.meta?.logs?.offPreferences ?? false),
    );

  const onDragEnd = useCallback(
    (e: DragEndEvent) => {
      const activeId = e.active.id;
      const overId = e.over?.id;

      if (overId !== undefined && activeId !== overId) {
        table.setColumnOrder((prevOrder) => {
          const activeIndex = prevOrder.findIndex((i) => i === activeId);
          const overIndex = prevOrder.findIndex((i) => i === overId);

          return arrayMove(prevOrder, activeIndex, overIndex);
        });
      }
    },
    [table],
  );

  return (
    <ActionableWithMenu
      actionableType="IconButton"
      actionableProps={{
        isClean: true,
        size: 'medium',
        'data-testid': 'column-preferences',
        ariaLabel: 'Open column preferences',
        children: <TableCellsIconOutline />,
      }}
      menuProps={{
        minWidth: 290,
        className: 'translate-y-1 p-6 pb-4',
      }}
    >
      <Menu.Header title="Columns" />
      <Menu.Items className="ml-2 mt-2">
        <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd}>
          <SortableContext items={configurableColumns}>
            {configurableColumns.map(({ id, columnDef: { header, meta }, getIsVisible }) => {
              const isVisible = getIsVisible();
              return (
                <SortableColumnItem
                  key={id}
                  id={id}
                  headerText={header}
                  onCheck={onCheckHandler}
                  checked={isVisible}
                  disabled={(isOnlyOneChecked && isVisible) || (meta?.logs?.isPersistent ?? false)}
                />
              );
            })}
          </SortableContext>
        </DndContext>
      </Menu.Items>
    </ActionableWithMenu>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const columnPreferencesColumn: DisplayColumnDef<any> = {
  id: ACTIONS_COLUMN_ID,
  size: 85,
  maxSize: 85,
  enableResizing: false,
  header: ({ table: tableInstance }) => (
    <div className="mx-auto">
      <ColumnPreferences table={tableInstance} />
    </div>
  ),
};
