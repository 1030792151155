import type { CypherResult } from '@neo4j/cypher-builder';
import {
  Call,
  Match,
  NamedRelationship,
  Node,
  Null,
  Param,
  Pattern,
  Return,
  Union,
  Unwind,
  Variable,
  collect,
  concat,
  db,
  plus,
} from '@neo4j/cypher-builder';
import { isEmpty, isNil, toLower as toLowerCase } from 'lodash-es';

import { FACETED_RESULTS_LIMIT } from '../../../../modules/SearchBar/SearchBar.const';
import type { FullTextIndex } from '../../../../types/databaseIndexes';
import type { Perspective } from '../../../../types/perspective';
import type { Nullable } from '../../../../types/utility';

const generateSingleFullTextSearchCypher = (index: FullTextIndex, searchValue: string) => {
  const { name, entityType } = index;

  // eslint-disable-next-line
  // https://lucene.apache.org/core/9_4_2/core/org/apache/lucene/search/FuzzyQuery.html
  // const exactMatchConditions = isEmpty(index.propertyNames)
  //   ? ''
  //   : 'WHERE ' + propertyNames.map((property) => `node['${property}'] CONTAINS '${searchValue}'`).join(' OR ')
  // const fuzzMatchExpression = `${searchValue}~1`
  // const exactMatchExpression = `"${searchValue}"`
  // const containingMatchExpression = `*${searchValue}*`
  const containingAnyMatchExpression = searchValue
    .split(' ')
    .map((s) => `*${s}*`)
    .join(' AND ');

  if (toLowerCase(entityType) === 'node') {
    return `
      CALL db.index.fulltext.queryNodes('${name}', '${containingAnyMatchExpression}')     
      YIELD node 
      with node, NULL AS relationship    
      RETURN node, relationship 
    `;
  } else if (toLowerCase(entityType) === 'relationship') {
    // return connected nodes as well
    return `
      CALL db.index.fulltext.queryRelationships('${name}', '${containingAnyMatchExpression}')
      YIELD relationship
      MATCH (n1) - [relationship] - (n2)
      WITH collect(n1) + collect(n2) as n, relationship
      UNWIND n as node
      RETURN node, relationship   
    `;
  }

  return null;
};

// This method generate fulltext search cypher using string template
// It's kept for debugging purpose, it's easy to debug with string template than cypher builder
export const generateFullTextSearchCypherWithStringTemplate = (
  fullTextSearchValue: Nullable<string>,
  fullTextIndexes: FullTextIndex[],
  perspectiveMetaData: Perspective['metadata'],
  resultLimit: number,
  isCaseInsensitive: boolean,
) => {
  if (isNil(fullTextSearchValue) || isEmpty(fullTextSearchValue) || isEmpty(fullTextIndexes)) {
    return {};
  }

  const cypher = `
    CALL {
      ${fullTextIndexes.map((index) => generateSingleFullTextSearchCypher(index, fullTextSearchValue)).join(`
      UNION ALL
      `)} 
    }
    RETURN DISTINCT node, relationship
    LIMIT ${FACETED_RESULTS_LIMIT}
  `;

  return { cypher };
};

export const generateFullTextSearchCypher = (
  fullTextSearchValue: Nullable<string>,
  fullTextIndexes: FullTextIndex[],
  isCaseInsensitive = false,
): Nullable<CypherResult> => {
  if (isNil(fullTextSearchValue) || isEmpty(fullTextSearchValue) || isEmpty(fullTextIndexes)) {
    return null;
  }

  // eslint-disable-next-line
  // https://lucene.apache.org/core/9_4_2/core/org/apache/lucene/search/FuzzyQuery.html
  // there are other expressions that can be used:
  // const fuzzMatchExpression = `${fullTextSearchValue}~1`
  // const exactMatchExpression = `"${fullTextSearchValue}"`
  // const containingMatchExpression = `*${fullTextSearchValue}*`
  const containingAnyMatchExpression = fullTextSearchValue
    .split(' ')
    .map((s) => `*${s}*`)
    .join(' AND ');

  const node = new Variable();
  const relationship = new NamedRelationship('relationship');
  const queryParam = new Param(containingAnyMatchExpression);

  const queries = fullTextIndexes.map(({ entityType, name }) => {
    if (entityType === 'NODE') {
      return db.index.fulltext
        .queryNodes(name, queryParam)
        .yield(['node', node])
        .with(node, [Null, 'relationship'])
        .return(node, relationship)
        .distinct()
        .limit(FACETED_RESULTS_LIMIT);
    }
    const node1 = new Node();
    const node2 = new Node();
    const pattern = new Pattern(node1).related(relationship).withDirection('undirected').to(node2);

    return concat(
      db.index.fulltext.queryRelationships(name, queryParam).yield(['relationship', relationship]),
      new Match(pattern).with([plus(collect(node1), collect(node2)), node], relationship),
      new Unwind([node, 'node']),
      new Return(node, relationship).limit(FACETED_RESULTS_LIMIT),
    );
  });

  const cypher = new Call(new Union(...queries).all())
    .return(node, relationship)
    .distinct()
    .limit(FACETED_RESULTS_LIMIT)
    .build();

  return cypher;
};
