/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { formatters } from '@neo4j/graph-schema-utils';
import type * as ImportShared from '@nx/import-shared';
import { neo4jVersionUtil } from '@nx/neo4j-version-utils';
import { isNullish } from '@nx/stdlib';
import { nanoid } from '@reduxjs/toolkit';
import { produce } from 'immer';

import { APP_VERSION, GRAPH_SCHEMA_VERSION } from '../constants';
import { truncateSampleValue } from '../utils/text';
import {
  getIndexOrConstraintName,
  getNodeOrRelationshipProperty,
  toConstraintId,
  toIdFromRef,
  toIndexId,
  toNodeLabelId,
  toNodeObjectTypeId,
  toPropertyId,
  toRefObject,
  toRefString,
  toRelationshipObjectTypeId,
  toRelationshipTypeId,
} from './data-model.json.helpers';
import { getDataModelErrors } from './deprecated/0.8.0/data-model';
import type {
  FileSchemaField,
  MappingField,
  MappingModel,
  NodeKey,
  NodeMapping,
  Property,
  RelationshipMapping,
} from './deprecated/0.8.0/types';
import * as deprecatedV1JsonTypes from './deprecated/1.0.0/data-model.json.type';
import type * as deprecatedV12JsonTypes from './deprecated/1.2.0/data-model.json.type';
import * as DataModelTypes2_2_0 from './deprecated/2.2.0/data-model.json.type';
import type { GraphSchemaTypes100next9 } from './deprecated/graph-schema-utils-types-deprecated/graph-schema-utils-types-1.0.0-next9';
import type { AnyDeprecatedDataModel, DeprecatedDataModel } from './deprecated/types';

const LATEST_VERSION_BREAKPOINT = '2.3.0';
export const isLatestDataModelVersion = (version: string | undefined): boolean => {
  return neo4jVersionUtil.gte(version ?? '', LATEST_VERSION_BREAKPOINT);
};

export const migrate_0_0_1_to_0_0_2 = (oldDataModel: DeprecatedDataModel['0.0.1']): DeprecatedDataModel['0.0.2'] => {
  // Add nanoid as property identifier and replace key properties from index array to uuid array for node schema.
  const tmpDataModel = produce(oldDataModel, (draft) => {
    Object.entries(draft.graphModel.nodeSchemas).forEach(([nodeId, nodeSchema]) => {
      const newKeyPropertyIdentifiers: string[] = [];
      nodeSchema.properties.forEach((property, i) => {
        const newPropertyIdentifier = nanoid();
        if (nodeSchema.key.properties.includes(i)) {
          newKeyPropertyIdentifiers.push(newPropertyIdentifier);
        }
        (property as Property).identifier = newPropertyIdentifier;
      });
      (nodeSchema.key as unknown as NodeKey).properties = newKeyPropertyIdentifiers;
    });
    // Add nanoid as property identifier for relationship schema.
    Object.values(draft.graphModel.relationshipSchemas).forEach((relationshipSchema) => {
      relationshipSchema.properties.forEach((property) => ((property as Property).identifier = nanoid()));
    });
  });
  // Make sure that configurations are set as they weren't present on version 0.0.1 format
  const newDataModel = {
    configurations: { idsToIgnore: [] },
    ...tmpDataModel,
  } as unknown as DeprecatedDataModel['0.0.2'];
  return newDataModel;
};

/**
 * Is used to update values of old data model files based on error.
 * Previously, we allowed invalid table columns to be mapped to the graph entity, e.g.
 * duplicated column name and empty column name, which will result in import failure.
 * This issue is fixed when PR#622 was introduced.
 *  @param oldDataModel
 */
export const migrate_0_0_2_to_0_6_2 = (oldDataModel: DeprecatedDataModel['0.0.2']): DeprecatedDataModel['0.0.2'] => {
  const errors = getDataModelErrors(oldDataModel);
  const newNodeMappings = { ...oldDataModel.mappingModel.nodeMappings };
  const newRelationshipMappings = { ...oldDataModel.mappingModel.relationshipMappings };
  if (errors) {
    const { fileErrors } = errors;
    const { fileModel, mappingModel } = oldDataModel;

    for (const filename of Object.keys(fileErrors)) {
      const fileError = fileErrors[filename];
      const invalidFields: string[] = (fileModel.fileSchemas[filename]?.fields ?? [])
        .filter((_, i) => Object.keys(fileError ?? {}).includes(String(i)))
        .map((field) => field.name);
      const { nodeMappings, relationshipMappings } = mappingModel;
      // Nodes
      for (const nodeId of Object.keys(nodeMappings)) {
        let newMappingFieldsForNodeId: undefined | MappingField[];
        const nodeMapping = nodeMappings[nodeId];
        if (!isNullish(nodeMapping)) {
          const { fileSchema, mappings } = nodeMapping;
          // eslint-disable-next-line max-depth
          if (fileSchema === filename) {
            newMappingFieldsForNodeId = mappings.filter((mapping) => {
              return !isNullish(mapping.field) && !invalidFields.includes(mapping.field);
            });
            const newNodeMapping: NodeMapping = {
              ...nodeMapping,
              mappings: newMappingFieldsForNodeId,
            };
            newNodeMappings[nodeId] = newNodeMapping;
          }
        }
      }

      // Relationships
      for (const relationshipId of Object.keys(relationshipMappings)) {
        const relationshipMapping = relationshipMappings[relationshipId];
        let newMappingFieldsForRelationship: undefined | MappingField[];
        if (!isNullish(relationshipMapping)) {
          const { fileSchema, mappings, sourceMappings, targetMappings } = relationshipMapping;
          // eslint-disable-next-line max-depth
          if (fileSchema === filename) {
            newMappingFieldsForRelationship = mappings.filter((mapping) => {
              return !isNullish(mapping.field) && !invalidFields.includes(mapping.field);
            });
            const newSourceMappingFieldsForRelationship = sourceMappings.filter((mapping) => {
              return !isNullish(mapping.field) && !invalidFields.includes(mapping.field);
            });
            const newTargetMappingFieldsForRelationship = targetMappings.filter((mapping) => {
              return !isNullish(mapping.field) && !invalidFields.includes(mapping.field);
            });
            const newRelationshipMapping: RelationshipMapping = {
              ...relationshipMapping,
              mappings: newMappingFieldsForRelationship,
              sourceMappings: newSourceMappingFieldsForRelationship,
              targetMappings: newTargetMappingFieldsForRelationship,
            };
            newRelationshipMappings[relationshipId] = newRelationshipMapping;
          }
        }
      }
    }
  }

  const mappingModel: MappingModel = {
    ...oldDataModel.mappingModel,
    nodeMappings: newNodeMappings,
    relationshipMappings: newRelationshipMappings,
  };

  const newDataModel: DeprecatedDataModel['0.0.2'] = {
    ...oldDataModel,
    mappingModel,
  };
  return newDataModel;
};

/**
 * Before version 0.8.0 it is not for sure that the configurations object is defined
 * @param oldDataModel
 */
export const migrate_0_6_2_to_0_8_0 = (oldDataModel: DeprecatedDataModel['0.0.2']): DeprecatedDataModel['0.8.0'] => {
  const configurations: ImportShared.ConfigurationsJsonStruct = {
    idsToIgnore:
      !isNullish(oldDataModel.configurations) && !isNullish(oldDataModel.configurations.idsToIgnore)
        ? oldDataModel.configurations.idsToIgnore
        : [],
  };
  const newDataModel: DeprecatedDataModel['0.8.0'] = {
    ...oldDataModel,
    configurations,
  };
  return newDataModel;
};

export const migrate_0_8_0_to_1_0_0 = (oldDataModel: DeprecatedDataModel['0.8.0']): DeprecatedDataModel['1.0.0'] => {
  const { nodeMappings, relationshipMappings } = oldDataModel.mappingModel;
  const { nodeSchemas, relationshipSchemas } = oldDataModel.graphModel;

  // Nodes
  const nodeLabels: GraphSchemaTypes100next9.NodeLabelJsonStruct[] = [];
  const nodeObjectTypes: GraphSchemaTypes100next9.NodeObjectTypeJsonStruct[] = [];
  const nodeKeyProperties: ImportShared.NodeKeyPropertyJsonStruct[] = [];

  let propertiesCount = 0;

  if (!isNullish(nodeSchemas)) {
    Object.entries(nodeSchemas).forEach(([nodeId, nodeSchema]) => {
      // Need to keep the old id as suffix to connect to the arrows graph model objects
      const nodeObjectId = toNodeObjectTypeId(nodeId);
      const migratedProperties: GraphSchemaTypes100next9.PropertyJsonStruct[] = [];
      const { label, properties, key } = nodeSchema;
      const nodeLabelId = toNodeLabelId(nodeId);
      const nodeLabel = {
        $id: nodeLabelId,
        token: label,
      };
      nodeLabels.push(nodeLabel);

      properties.forEach((p) => {
        propertiesCount += 1;
        const { type, property, identifier } = p;
        const propertyType: GraphSchemaTypes100next9.PropertyTypeJsonStruct = {
          type: type,
        };
        const propertyId = toPropertyId(propertiesCount);
        const propertyJsonStruct = {
          $id: propertyId,
          token: property,
          type: propertyType,
          nullable: false,
        };
        // Assuming only one since we never used several in the old data model
        const isKey: boolean = key.properties[0] === identifier;
        if (isKey) {
          const nodeKeyProperty: ImportShared.NodeKeyPropertyJsonStruct = {
            node: { $ref: toRefString(nodeObjectId) },
            keyProperty: { $ref: toRefString(propertyId) },
          };
          nodeKeyProperties.push(nodeKeyProperty);
        }
        migratedProperties.push(propertyJsonStruct);
      });

      const nodeObjectType = {
        $id: nodeObjectId,
        labels: [{ $ref: toRefString(nodeLabelId) }],
        properties: migratedProperties,
      };
      nodeObjectTypes.push(nodeObjectType);
    });
  }

  // Relationships
  const relationshipTypes: GraphSchemaTypes100next9.RelationshipTypeJsonStruct[] = [];
  const relationshipObjectTypes: GraphSchemaTypes100next9.RelationshipObjectTypeJsonStruct[] = [];
  if (!isNullish(relationshipSchemas)) {
    Object.entries(relationshipSchemas).forEach(([relationshipId, relationshipSchema]) => {
      const { type, properties, sourceNodeSchema, targetNodeSchema } = relationshipSchema;
      const migratedProperties: GraphSchemaTypes100next9.PropertyJsonStruct[] = [];
      // Need to keep the old id as suffix to connect to the arrows graph model
      const relationShipTypeId = toRelationshipTypeId(relationshipId);
      const relationshipType = {
        $id: relationShipTypeId,
        token: type,
      };
      relationshipTypes.push(relationshipType);

      properties.forEach((p) => {
        propertiesCount += 1;
        const { property } = p;
        const propertyType: GraphSchemaTypes100next9.PropertyTypeJsonStruct = {
          type: p.type,
        };
        const propertyId = toPropertyId(propertiesCount);
        const propertyJsonStruct = {
          $id: propertyId,
          token: property,
          type: propertyType,
          nullable: true,
        };
        migratedProperties.push(propertyJsonStruct);
      });

      const relationshipObjectId = toRelationshipObjectTypeId(relationshipId);
      const sourceNodeId = toNodeObjectTypeId(sourceNodeSchema);
      const targetNodeId = toNodeObjectTypeId(targetNodeSchema);
      const relationShipObjectType: GraphSchemaTypes100next9.RelationshipObjectTypeJsonStruct = {
        $id: relationshipObjectId,
        type: { $ref: toRefString(relationShipTypeId) },
        from: { $ref: toRefString(sourceNodeId) },
        to: { $ref: toRefString(targetNodeId) },
        properties: migratedProperties,
      };
      relationshipObjectTypes.push(relationShipObjectType);
    });
  }

  // Graph mapping representation

  // File schemas
  const fileSchemas: deprecatedV1JsonTypes.FileSchemaJsonStruct[] = [];
  Object.entries(oldDataModel.fileModel.fileSchemas).forEach(([fileSchemaId, fileSchema]) => {
    const newFields: deprecatedV1JsonTypes.FileSchemaFieldJsonStruct[] = fileSchema.fields.map(
      (field: FileSchemaField) => {
        const fileSchemaField: deprecatedV1JsonTypes.FileSchemaFieldJsonStruct = {
          name: field.name,
          type: field.type,
          sample: truncateSampleValue(field.sample),
        };
        return fileSchemaField;
      },
    );

    const fileSchemaJsonStruct: deprecatedV1JsonTypes.FileSchemaJsonStruct = {
      $id: deprecatedV1JsonTypes.toTableSchemaId(fileSchemas.length + 1),
      fileName: fileSchemaId,
      expanded: fileSchema.expanded ?? false,
      fields: newFields,
    };
    fileSchemas.push(fileSchemaJsonStruct);
  });

  // Node mappings
  const migratedNodeMappings: deprecatedV1JsonTypes.NodeMappingJsonStruct[] = [];
  Object.entries(nodeMappings).forEach(([nodeId, mapping]) => {
    const fileSchema = fileSchemas.find((f) => f.fileName === mapping.fileSchema);
    const fileSchemaFieldNames = fileSchema?.fields.map(({ name }) => name) ?? [];
    const nodeProperties = nodeObjectTypes.find((n) => n.$id === toNodeObjectTypeId(nodeId))?.properties ?? [];
    const propertyMappings: ImportShared.PropertyMappingJsonStruct[] = mapping.mappings
      .map((m, index) => {
        const propertyId = nodeProperties[index]?.$id;
        if (propertyId === undefined || m.field === undefined || !fileSchemaFieldNames.includes(m.field)) {
          return undefined;
        }
        const propertyMapping: ImportShared.PropertyMappingJsonStruct = {
          property: { $ref: toRefString(propertyId) },
          fieldName: m.field,
        };
        return propertyMapping;
      })
      .filter((m): m is ImportShared.PropertyMappingJsonStruct => m !== undefined);
    const fileFilter: deprecatedV1JsonTypes.FileFilterJsonStruct | undefined =
      mapping.fileFilter !== undefined
        ? {
            fieldName:
              !isNullish(mapping.fileFilter.field) && fileSchemaFieldNames.includes(mapping.fileFilter.field)
                ? mapping.fileFilter.field
                : '',
            exactMatch: mapping.fileFilter.exactMatch,
          }
        : undefined;
    if (fileSchema?.$id !== undefined) {
      const nodeMapping: deprecatedV1JsonTypes.NodeMappingJsonStruct = {
        node: { $ref: toRefString(toNodeObjectTypeId(nodeId)) },
        fileSchema: { $ref: toRefString(fileSchema.$id) },
        propertyMappings: propertyMappings,
        fileFilter,
      };
      migratedNodeMappings.push(nodeMapping);
    }
  });

  // Relationship mappings
  const migratedRelationshipMappings: deprecatedV1JsonTypes.RelationshipMappingJsonStruct[] = [];
  Object.entries(relationshipMappings).forEach(([relationshipId, mapping]) => {
    const fileSchema = fileSchemas.find((f) => f.fileName === mapping.fileSchema);
    const fileSchemaFieldNames = fileSchema?.fields.map(({ name }) => name) ?? [];
    const relationshipProperties =
      relationshipObjectTypes.find((n) => n.$id === toRelationshipObjectTypeId(relationshipId))?.properties ?? [];
    const propertyMappings: ImportShared.PropertyMappingJsonStruct[] = mapping.mappings
      .map((m, index) => {
        const propertyId = relationshipProperties[index]?.$id;
        if (propertyId === undefined || m.field === undefined || !fileSchemaFieldNames.includes(m.field)) {
          return undefined;
        }
        const propertyMapping: ImportShared.PropertyMappingJsonStruct = {
          property: { $ref: toRefString(propertyId) },
          fieldName: m.field,
        };
        return propertyMapping;
      })
      .filter((m): m is ImportShared.PropertyMappingJsonStruct => m !== undefined);
    const fileFilter: deprecatedV1JsonTypes.FileFilterJsonStruct | undefined =
      mapping.fileFilter !== undefined
        ? {
            fieldName:
              !isNullish(mapping.fileFilter.field) && fileSchemaFieldNames.includes(mapping.fileFilter.field)
                ? mapping.fileFilter.field
                : '',
            exactMatch: mapping.fileFilter.exactMatch,
          }
        : undefined;
    const fromMappingField = mapping.sourceMappings[0]?.field;
    const fromMapping =
      fromMappingField !== undefined && fileSchemaFieldNames.includes(fromMappingField)
        ? { fieldName: fromMappingField }
        : undefined;
    const toMappingField = mapping.targetMappings[0]?.field;
    const toMapping =
      toMappingField !== undefined && fileSchemaFieldNames.includes(toMappingField)
        ? { fieldName: toMappingField }
        : undefined;
    if (fileSchema?.$id !== undefined) {
      const relationshipMapping: deprecatedV1JsonTypes.RelationshipMappingJsonStruct = {
        relationship: { $ref: toRefString(toRelationshipObjectTypeId(relationshipId)) },
        fileSchema: { $ref: toRefString(fileSchema.$id) },
        fileFilter,
        fromMapping,
        toMapping,
        propertyMappings: propertyMappings,
      };
      migratedRelationshipMappings.push(relationshipMapping);
    }
  });
  const graphSchema: GraphSchemaTypes100next9.GraphSchemaJsonStruct = {
    nodeLabels,
    nodeObjectTypes,
    relationshipTypes,
    relationshipObjectTypes,
  };
  const graphSchemaRepresentation: GraphSchemaTypes100next9.GraphSchemaRepresentationJsonStruct = {
    version: GRAPH_SCHEMA_VERSION,
    graphSchema,
  };
  const graphSchemaExtensionsRepresentation: ImportShared.GraphSchemaExtensionsRepresentationJsonStruct = {
    nodeKeyProperties,
  };

  const graphMappingRepresentation: deprecatedV1JsonTypes.GraphMappingRepresentationJsonStruct = {
    fileSchemas,
    nodeMappings: migratedNodeMappings,
    relationshipMappings: migratedRelationshipMappings,
  };

  // NOTE: if writing with optional chaining it gets auto rewritten by eslint so not checking if configuration empty
  // is due to that we need to add better validation earlier that the old data model is actually before type casting
  const configurations: ImportShared.ConfigurationsJsonStruct = {
    idsToIgnore:
      !isNullish(oldDataModel.configurations) && !isNullish(oldDataModel.configurations.idsToIgnore)
        ? oldDataModel.configurations.idsToIgnore
        : [],
  };

  const newDataModel: DeprecatedDataModel['1.0.0'] = {
    version: '1.0.0',
    graphSchemaRepresentation,
    graphSchemaExtensionsRepresentation,
    graphMappingRepresentation,
    configurations,
  };

  return newDataModel;
};

export const migrate_1_0_0_to_1_2_0 = (oldDataModel: DeprecatedDataModel['1.0.0']): DeprecatedDataModel['1.2.0'] => {
  const { graphMappingRepresentation } = oldDataModel;
  const migrateFileSchema = (
    fileSchema: deprecatedV1JsonTypes.FileSchemaJsonStruct,
  ): deprecatedV12JsonTypes.TableSchemaJsonStruct => {
    const { $id, fileName, expanded, fields } = fileSchema;
    return { $id, name: fileName, expanded, fields };
  };
  const migrateNodeMapping = (
    nodeMapping: deprecatedV1JsonTypes.NodeMappingJsonStruct,
  ): deprecatedV12JsonTypes.NodeMappingJsonStruct => {
    const { node, fileSchema, propertyMappings, fileFilter } = nodeMapping;
    return { node, tableSchema: fileSchema, propertyMappings, mappingFilter: fileFilter };
  };
  const migrateRelationshipMapping = (
    relationshipMapping: deprecatedV1JsonTypes.RelationshipMappingJsonStruct,
  ): deprecatedV12JsonTypes.RelationshipMappingJsonStruct => {
    const { relationship, fileSchema, fromMapping, toMapping, propertyMappings, fileFilter } = relationshipMapping;
    return {
      relationship,
      tableSchema: fileSchema,
      fromMapping,
      toMapping,
      propertyMappings,
      mappingFilter: fileFilter,
    };
  };
  return {
    version: '1.2.0',
    graphSchemaRepresentation: oldDataModel.graphSchemaRepresentation,
    graphSchemaExtensionsRepresentation: oldDataModel.graphSchemaExtensionsRepresentation,
    graphMappingRepresentation: {
      dataSourceSchema: {
        type: 'local/csv',
        tableSchemas: graphMappingRepresentation.fileSchemas.map(migrateFileSchema),
      },
      nodeMappings: graphMappingRepresentation.nodeMappings.map(migrateNodeMapping),
      relationshipMappings: graphMappingRepresentation.relationshipMappings.map(migrateRelationshipMapping),
    },
    configurations: oldDataModel.configurations,
  };
};

export const migrate_1_2_0_to_1_3_0 = (oldDataModel: DeprecatedDataModel['1.2.0']): DeprecatedDataModel['1.4.0'] => {
  // Move properties from nodeObjectTypes and relationshipObjectTypes to nodeLabels and relationshipTypes
  // and add indexes and constraints
  const nodeLabels: formatters.json.types.NodeLabelJsonStruct[] = [];
  const nodeObjectTypes: formatters.json.types.NodeObjectTypeJsonStruct[] = [];
  const relationshipTypes: formatters.json.types.RelationshipTypeJsonStruct[] = [];
  const relationshipObjectTypes: formatters.json.types.RelationshipObjectTypeJsonStruct[] = [];
  const nodeLabelIdToPropertiesMap = new Map<string, formatters.json.types.PropertyJsonStruct[]>();
  const relTypeIdToPropertiesMap = new Map<string, formatters.json.types.PropertyJsonStruct[]>();

  oldDataModel.graphSchemaRepresentation.graphSchema.nodeObjectTypes.forEach((nodeObjectType) => {
    const newNodeObjectType: formatters.json.types.NodeObjectTypeJsonStruct = {
      $id: nodeObjectType.$id,
      labels: nodeObjectType.labels,
    };
    const properties: formatters.json.types.PropertyJsonStruct[] = nodeObjectType.properties.map((p) => {
      const newProperty: formatters.json.types.PropertyJsonStruct = {
        $id: p.$id,
        token: p.token,
        type: p.type,
        nullable: p.nullable,
      };
      return newProperty;
    });
    const nodeLabelRef = nodeObjectType.labels[0]?.$ref;
    if (!isNullish(nodeLabelRef)) {
      nodeLabelIdToPropertiesMap.set(toIdFromRef(nodeLabelRef), properties);
    } else {
      throw new Error('Node label referenced not found');
    }

    nodeObjectTypes.push(newNodeObjectType);
  });

  oldDataModel.graphSchemaRepresentation.graphSchema.relationshipObjectTypes.forEach((relationshipObjectType) => {
    const newRelationshipObjectType: formatters.json.types.RelationshipObjectTypeJsonStruct = {
      $id: relationshipObjectType.$id,
      type: relationshipObjectType.type,
      from: relationshipObjectType.from,
      to: relationshipObjectType.to,
    };
    const properties: formatters.json.types.PropertyJsonStruct[] = relationshipObjectType.properties.map((p) => {
      const newProperty: formatters.json.types.PropertyJsonStruct = {
        $id: p.$id,
        token: p.token,
        type: p.type,
        nullable: p.nullable,
      };
      return newProperty;
    });
    const typeRef = relationshipObjectType.type.$ref;
    if (!isNullish(typeRef)) {
      relTypeIdToPropertiesMap.set(toIdFromRef(typeRef), properties);
    } else {
      throw new Error('Relationship type referenced not found');
    }
    relationshipObjectTypes.push(newRelationshipObjectType);
  });

  oldDataModel.graphSchemaRepresentation.graphSchema.nodeLabels.forEach((nodeLabel) => {
    const properties = nodeLabelIdToPropertiesMap.get(nodeLabel.$id) ?? [];
    const newNodeLabel: formatters.json.types.NodeLabelJsonStruct = {
      $id: nodeLabel.$id,
      token: nodeLabel.token,
      properties,
    };
    nodeLabels.push(newNodeLabel);
  });

  oldDataModel.graphSchemaRepresentation.graphSchema.relationshipTypes.forEach((relationshipType) => {
    const properties = relTypeIdToPropertiesMap.get(relationshipType.$id) ?? [];
    const newRelationshipType: formatters.json.types.RelationshipTypeJsonStruct = {
      $id: relationshipType.$id,
      token: relationshipType.token,
      properties,
    };
    relationshipTypes.push(newRelationshipType);
  });

  const graphSchema: formatters.json.types.GraphSchemaJsonStruct = {
    nodeLabels,
    nodeObjectTypes,
    relationshipTypes,
    relationshipObjectTypes,
    indexes: [],
    constraints: [],
  };

  const graphSchemaRepresentation: formatters.json.types.GraphSchemaRepresentationJsonStruct = {
    version: GRAPH_SCHEMA_VERSION,
    graphSchema,
  };

  return {
    version: '1.3.0',
    graphSchemaRepresentation: graphSchemaRepresentation,
    graphSchemaExtensionsRepresentation: oldDataModel.graphSchemaExtensionsRepresentation,
    graphMappingRepresentation: oldDataModel.graphMappingRepresentation,
    configurations: oldDataModel.configurations,
  };
};

// Added support for indexes and constraints, so need to add an index and constraint for all key ids in old model
export const migrate_1_3_0_to_1_4_0 = (oldDataModel: DeprecatedDataModel['1.4.0']): DeprecatedDataModel['1.4.0'] => {
  const { graphSchema } = oldDataModel.graphSchemaRepresentation;
  const { graphSchemaExtensionsRepresentation } = oldDataModel;

  const indexes: formatters.json.types.IndexJsonStruct[] = [];
  const constraints: formatters.json.types.ConstraintJsonStruct[] = [];

  graphSchemaExtensionsRepresentation.nodeKeyProperties.forEach((nodeKeyProperty, idx) => {
    const nodeObject = oldDataModel.graphSchemaRepresentation.graphSchema.nodeObjectTypes.find(
      (n) => n.$id === toIdFromRef(nodeKeyProperty.node.$ref),
    );
    const nodeObjectLabel = nodeObject?.labels[0];
    const nodeLabel = !isNullish(nodeObjectLabel)
      ? oldDataModel.graphSchemaRepresentation.graphSchema.nodeLabels.find(
          (n) => n.$id === toIdFromRef(nodeObjectLabel.$ref),
        )
      : undefined;
    const property = !isNullish(nodeLabel)
      ? getNodeOrRelationshipProperty(nodeLabel, toIdFromRef(nodeKeyProperty.keyProperty.$ref))
      : undefined;
    if (!isNullish(nodeLabel) && !isNullish(property)) {
      const name = getIndexOrConstraintName(property.token, nodeLabel.token, true);
      const index: formatters.json.types.NodeLabelIndexJsonStruct = {
        $id: toIndexId(idx),
        name: name,
        indexType: 'default',
        entityType: 'node',
        nodeLabel: toRefObject(nodeLabel.$id),
        properties: [nodeKeyProperty.keyProperty],
        // TODO: fix type in library so can remove this
        relationshipType: undefined,
      };
      indexes.push(index);
      const constraint: formatters.json.types.NodeLabelConstraintJsonStruct = {
        $id: toConstraintId(idx),
        name: name,
        constraintType: 'uniqueness',
        entityType: 'node',
        nodeLabel: toRefObject(nodeLabel.$id),
        properties: [nodeKeyProperty.keyProperty],
        // TODO: fix type in graph-schema-utils library so can remove this
        relationshipType: undefined,
      };
      constraints.push(constraint);
    }
  });
  const newDataModel: DeprecatedDataModel['1.4.0'] = {
    ...oldDataModel,
    version: '1.4.0',
    graphSchemaRepresentation: {
      ...oldDataModel.graphSchemaRepresentation,
      graphSchema: {
        ...graphSchema,
        indexes,
        constraints,
      },
    },
  };
  return newDataModel;
};

// Reference tables by name rather than $id
export const migrate_1_4_0_to_2_1_0 = (oldDataModel: DeprecatedDataModel['1.4.0']): DeprecatedDataModel['2.1.0'] => {
  const oldTableSchemas = oldDataModel.graphMappingRepresentation.dataSourceSchema.tableSchemas;
  const newDataModel: DeprecatedDataModel['2.1.0'] = {
    ...oldDataModel,
    version: '2.1.0',
    graphMappingRepresentation: {
      dataSourceSchema: {
        ...oldDataModel.graphMappingRepresentation.dataSourceSchema,
        tableSchemas: oldTableSchemas.map(({ $id, ...table }) => {
          const convertedForeignKey = table.foreignKeys
            ? table.foreignKeys.map((key) => {
                return {
                  referencedTable: key.referencedTable,
                  fields: key.columns.map((col) => {
                    return {
                      field: col.column,
                      referencedField: col.referencedColumn,
                    };
                  }),
                };
              })
            : [];
          return {
            name: table.name,
            expanded: table.expanded,
            fields: table.fields,
            primaryKeys: table.primaryKeys ?? [],
            foreignKeys: convertedForeignKey,
          };
        }),
      },
      nodeMappings: oldDataModel.graphMappingRepresentation.nodeMappings.reduce(
        (accumulatedNodeMappings: ImportShared.NodeMappingJsonStruct[], { tableSchema, ...nodeMapping }) => {
          const foundTableSchema = oldTableSchemas.find((t) => t.$id === toIdFromRef(tableSchema.$ref));
          return isNullish(foundTableSchema)
            ? accumulatedNodeMappings
            : [...accumulatedNodeMappings, { ...nodeMapping, tableName: foundTableSchema.name }];
        },
        [],
      ),
      relationshipMappings: oldDataModel.graphMappingRepresentation.relationshipMappings.reduce(
        (
          accumulatedRelationshipMappings: ImportShared.RelationshipMappingJsonStruct[],
          { tableSchema, ...relationshipMapping },
        ) => {
          const foundTableSchema = oldTableSchemas.find((t) => t.$id === toIdFromRef(tableSchema.$ref));
          return isNullish(foundTableSchema)
            ? accumulatedRelationshipMappings
            : [...accumulatedRelationshipMappings, { ...relationshipMapping, tableName: foundTableSchema.name }];
        },
        [],
      ),
    },
  };
  return newDataModel;
};

// mapping the TableSchemaFieldJsonStruct
// change type under dataSourceSchema
export const migrate_2_1_0_to_2_2_0 = (oldDataModel: DeprecatedDataModel['2.1.0']): DeprecatedDataModel['2.2.0'] => {
  const oldType: string = oldDataModel.graphMappingRepresentation.dataSourceSchema.type;
  let newLocationType: DataModelTypes2_2_0.DataSourceLocation = 'cloud';
  if (oldType === 'unset') {
    newLocationType = null;
  } else if (oldType.includes('local')) {
    newLocationType = 'local';
  }
  const oldTableSchemas = oldDataModel.graphMappingRepresentation.dataSourceSchema.tableSchemas;
  const TableSchemaJsonStructs: DataModelTypes2_2_0.TableSchemaJsonStruct[] = oldTableSchemas.map((tableSchema) => {
    const { fields } = tableSchema;
    let newTableSchemas;
    if (fields[0] !== undefined && 'type' in fields[0]) {
      newTableSchemas = fields.map((field) => {
        return {
          name: field.name,
          type: field.type ?? 'string',
          sample: field.sample,
        };
      });
    } else {
      newTableSchemas = fields.map((field) => {
        return {
          name: field.name,
          rawType: 'rawType' in field && field.rawType !== undefined ? field.rawType : '',
          recommendedType: field.recommendedType,
          supportedTypes: field.supportedTypes,
        };
      });
    }
    return {
      ...tableSchema,
      fields: newTableSchemas,
    };
  });

  const newDataModel: DeprecatedDataModel['2.2.0'] = {
    ...oldDataModel,
    version: '2.2.0',
    graphMappingRepresentation: {
      ...oldDataModel.graphMappingRepresentation,
      dataSourceSchema: {
        type: newLocationType,
        tableSchemas: TableSchemaJsonStructs,
      },
    },
  };
  return newDataModel;
};

// TableSchemaLocalFieldJsonStruct.type -> TableSchemaLocalFieldJsonStruct.recommendedType
export const migrate_2_2_0_to_2_3_0 = (
  oldDataModel: DeprecatedDataModel['2.2.0'],
): ImportShared.DataModelJsonStruct => {
  const { tableSchemas } = oldDataModel.graphMappingRepresentation.dataSourceSchema;

  const newTableSchemas = tableSchemas.map((tableSchema) => {
    const { fields } = tableSchema;
    const newFields = fields.map((f) => {
      return DataModelTypes2_2_0.isTableSchemaLocalFieldJsonStruct(f)
        ? { name: f.name, sample: f.sample, recommendedType: { type: f.type } }
        : f;
    });
    return {
      ...tableSchema,
      fields: newFields,
    };
  });

  const newDataModel: ImportShared.DataModelJsonStruct = {
    ...oldDataModel,
    version: APP_VERSION,
    graphMappingRepresentation: {
      ...oldDataModel.graphMappingRepresentation,
      dataSourceSchema: {
        ...oldDataModel.graphMappingRepresentation.dataSourceSchema,
        tableSchemas: newTableSchemas,
      },
    },
  };

  return newDataModel;
};

export const migrateDataModelToLatestVersion = <T extends keyof DeprecatedDataModel>(
  oldDataModel: DeprecatedDataModel[T] | ImportShared.DataModelJsonStruct,
  oldVersion: string | undefined,
): ImportShared.DataModelJsonStruct => {
  let newDataModel: AnyDeprecatedDataModel | ImportShared.DataModelJsonStruct = oldDataModel;
  let version = oldVersion;

  // Note! semver.satisfies(version, '<=0.0.1') returns true in dev and returns false in prod.
  if (isNullish(version) || neo4jVersionUtil.lt(version, '0.0.2')) {
    newDataModel = migrate_0_0_1_to_0_0_2(oldDataModel as DeprecatedDataModel['0.0.1']);
    version = '0.0.2';
  }

  // 0.6.2 is the same as in 0.0.2 with just a change of cleanup of data that could happen before that version
  if (neo4jVersionUtil.lt(version, '0.6.2')) {
    newDataModel = migrate_0_0_2_to_0_6_2(newDataModel as DeprecatedDataModel['0.0.2']);
    version = '0.6.2';
  }

  // 0.8.0 is the same as in 0.6.2 with just that configurations now always is available
  if (neo4jVersionUtil.lt(version, '0.8.0')) {
    newDataModel = migrate_0_6_2_to_0_8_0(newDataModel as DeprecatedDataModel['0.0.2']);
    version = '0.8.0';
  }

  // 1.0.0-beta.0 is aligned with the shared graph schema format
  if (neo4jVersionUtil.lt(version, '1.0.0-beta.0')) {
    newDataModel = migrate_0_8_0_to_1_0_0(newDataModel as DeprecatedDataModel['0.8.0']);
    version = '1.0.0';
  }

  // 1.2.0-beta.0 is the same as 1.0.0 except graphMappingRepresentation has been enhanced
  // to support SQL and CDW data sources
  if (neo4jVersionUtil.lt(version, '1.2.0-beta.0')) {
    newDataModel = migrate_1_0_0_to_1_2_0(newDataModel as DeprecatedDataModel['1.0.0']);
    version = '1.2.0';
  }

  // 1.3.0 is due to changes in the core graph schema format due to separating out formatters
  // and adding indexes and constraints
  if (neo4jVersionUtil.lt(version, '1.3.0-beta.0')) {
    newDataModel = migrate_1_2_0_to_1_3_0(newDataModel as DeprecatedDataModel['1.2.0']);
    version = '1.3.0';
  }

  // 1.4.0 has the same structure as in 1.3.0 but now added support for indexes (and key constraint) also in the app,
  // so need to add an index and constraint for all keys in older models
  if (neo4jVersionUtil.lt(version, '1.3.1-beta.0')) {
    newDataModel = migrate_1_3_0_to_1_4_0(newDataModel as DeprecatedDataModel['1.4.0']);
    version = '1.4.0';
  }

  // 2.1.0 reference tables by name rather than $id
  if (neo4jVersionUtil.lt(version, '2.1.0-beta.0')) {
    newDataModel = migrate_1_4_0_to_2_1_0(newDataModel as DeprecatedDataModel['1.4.0']);
    version = '2.1.0';
  }

  // 2.2.0 has different format for oldDataModel.graphMappingRepresentation.dataSourceSchema.type
  if (neo4jVersionUtil.lt(version, '2.2.0')) {
    newDataModel = migrate_2_1_0_to_2_2_0(newDataModel as DeprecatedDataModel['2.1.0']);
    version = '2.2.0';
  }

  // 2.3.0 and above is currently the latest model
  // 2.3.0 rename the TableSchemaLocalFieldJsonStruct.type -> TableSchemaLocalFieldJsonStruct.recommendedType
  if (neo4jVersionUtil.lt(version, '2.3.0')) {
    newDataModel = migrate_2_2_0_to_2_3_0(newDataModel as DeprecatedDataModel['2.2.0']);
    version = LATEST_VERSION_BREAKPOINT;
  }

  // More migrations should go into 'if' conditions incrementally.

  return newDataModel as ImportShared.DataModelJsonStruct;
};
