export type CustomEndpoint = {
  id: string;
  dbId?: string;
  projectId: string;
  suffix: string;
  name: string;
  isTransferable: boolean;
  isRevertible: boolean;
  sourceDbId?: string;
};

export enum CUSTOM_ENDPOINT_STATUS {
  // ACTIVE, but no assigned instance
  DISCONNECTED = 'DISCONNECTED',
  // Unrecognized status:
  UNKNOWN = 'UNKNOWN',
  // Traffic Config API States:
  SUBMITTED = 'SUBMITTED',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  ERROR = 'ERROR',
}

export interface CustomEndpointDetails extends CustomEndpoint {
  status: CUSTOM_ENDPOINT_STATUS;
}
