import { DropdownButton, Menu, TextLink, Tooltip } from '@neo4j-ndl/react';
import { CommandLineIconOutline } from '@neo4j-ndl/react/icons';
import { AURA_CONSOLE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { type Instance, MODAL_TYPE, useModal } from '@nx/state';
import { useConnect, useNxNavigate } from '@nx/ui';
import { useRef, useState } from 'react';

import { ExploreIconOutline } from '../components/icons';
import { useTrackUpxEvent } from '../services/segment/analytics';

type ConnectMenuProps = {
  instance: Instance;
  isDisabled: boolean;
  type?: 'table' | 'list';
};

export const ConnectMenu = ({ instance, type = 'list', isDisabled }: ConnectMenuProps) => {
  const trackEvent = useTrackUpxEvent();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const connectModal = useModal(MODAL_TYPE.CONNECT);
  const nxNavigate = useNxNavigate();
  const { doDiscoveryAndConnect } = useConnect();

  const handleClick = () => setIsMenuOpen((prevState) => !prevState);

  const handleConnect = async (app: APP_SCOPE) => {
    nxNavigate(app);
    await doDiscoveryAndConnect(instance);
  };

  return (
    <>
      <Tooltip type="simple" isDisabled={instance.availableActions.connect.enabled}>
        <Tooltip.Trigger hasButtonWrapper>
          <DropdownButton
            ref={menuRef}
            isOpen={isMenuOpen}
            onClick={handleClick}
            size={type === 'list' ? 'medium' : 'small'}
            isDisabled={!instance.availableActions.connect.enabled}
            htmlAttributes={{
              'aria-label': 'Connection menu',
            }}
          >
            Connect
          </DropdownButton>
        </Tooltip.Trigger>
        <Tooltip.Content>
          {!instance.availableActions.connect.enabled && instance.availableActions.connect.message}
        </Tooltip.Content>
      </Tooltip>
      <Menu isOpen={isMenuOpen} anchorRef={menuRef} onClose={handleClick}>
        <Menu.Items>
          <Menu.Item
            title="Query"
            icon={<CommandLineIconOutline />}
            isDisabled={isDisabled}
            onClick={() => {
              void handleConnect(APP_SCOPE.query);
            }}
          />
          <Menu.Item
            title="Explore"
            icon={<ExploreIconOutline />}
            isDisabled={isDisabled}
            onClick={() => {
              void handleConnect(APP_SCOPE.explore);
            }}
          />
          <Menu.Divider />
          <Menu.Item
            title="Drivers"
            onClick={() => {
              connectModal.open({ instance });
              trackEvent({
                event: AURA_CONSOLE_EVENTS.INSTANCE_DEVELOP,
                scope: APP_SCOPE.aura,
              });
            }}
          />
          <Menu.Item
            as="div"
            title={
              <TextLink
                className="text-neutral-text-default flex w-full no-underline"
                href="https://neo4j.com/developer/"
                isExternalLink
              >
                Developer center
              </TextLink>
            }
          />
        </Menu.Items>
      </Menu>
    </>
  );
};
