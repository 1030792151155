import { useAppSelector } from '@nx/state';
import { GuideSidebar } from '@nx/ui';
import React from 'react';

import { Header } from './header/header';
import { LeftNav } from './nav';

type Props = React.PropsWithChildren<
  ({ header: React.ReactNode } | { headerArgs: Parameters<typeof Header>[0] }) &
    ({ nav: React.ReactNode } | { navArgs: Parameters<typeof LeftNav.Target>[0] }) & { footerHint?: React.ReactNode }
>;

export function UpxLayout({ children, ...props }: Props) {
  const showGuidesSidebar = useAppSelector((store) => store.sidebar.guidesIsOpen);

  return (
    <LeftNav.Provider>
      <div className="flex h-full min-w-0 shrink grow flex-col" data-testid="upx-layout">
        <div className="border-b-palette-neutral-border-weak z-[15] -mb-px border-b border-solid">
          {'header' in props ? props.header : <Header {...props.headerArgs} />}
        </div>

        <div className="relative mt-[1px] flex h-full min-h-0 w-full">
          <GuideSidebar open={showGuidesSidebar} />
          {'nav' in props ? props.nav : <LeftNav.Target {...props.navArgs} />}
          <div className="w-full overflow-auto">{children}</div>
        </div>
        {'footerHint' in props ? props.footerHint : null}
      </div>
    </LeftNav.Provider>
  );
}
