import type { Record } from 'neo4j-driver';

import { processForImport } from '../../state/perspectives/validation/validations';
import type { Perspective } from '../../types/perspective';
import { isEmptyArray, isTruthy } from '../../types/utility';
import { log } from '../logging';

export const perspectiveResultMapper = (result: {
  records: Record[];
}):
  | {
      success: boolean;
      message: string;
      code: string;
    }
  | undefined => {
  if (!isEmptyArray(result.records)) {
    const [rec] = result.records;
    return {
      success: rec?.get('success'),
      message: rec?.get('message'),
      code: rec?.has('code') ? rec.get('code') : '',
    };
  }
};

export const fetchPerspectivesResultMapper = (result: { records: Record[] }) => {
  if (!isEmptyArray(result.records)) {
    const compatiblePerspectives: Perspective[] = [];
    result.records.forEach((record) => {
      const unparsedPerspective = record.get('data');
      const sha = record.has('sha') ? record.get('sha') : undefined;
      const name = record.get('name');

      const validationResult = processForImport([unparsedPerspective]);

      if (isTruthy(validationResult.perspectives)) {
        const [validatedPerspective] = validationResult.perspectives;

        if (isTruthy(validatedPerspective)) {
          validatedPerspective.sha = sha;
          compatiblePerspectives.push(validatedPerspective as unknown as Perspective);
        } else {
          log.warn(`Could not load the perspective named ${name}. Unrecognised format.`);
        }
      } else {
        log.warn(`Could not load the perspective named ${name}. ${validationResult.error}`);
      }
    });
    return compatiblePerspectives;
  }
  return [];
};

export const fetchPerspectiveRolesResultMapper = (result: { records: Record[] }): string[] => {
  if (!isEmptyArray(result.records)) {
    return result.records.map((record) => record.get('name'));
  }
  return [];
};

export const fetchPerspectivesShaResultMapper = (result: {
  records: Record[];
}): {
  sha: string;
  id: string;
}[] => {
  if (!isEmptyArray(result.records)) {
    return result.records.map((record) => ({
      sha: record.get('sha'),
      id: record.get('id'),
    }));
  }
  return [];
};
