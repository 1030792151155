import type { DeploymentConfiguration } from './types';

export const STANDALONE_DEPLOYMENT: DeploymentConfiguration = {
  deployment: 'STANDALONE',
  allowSettings: true,
  allowAbout: true,
  allowHelpAndLearn: true,
  allowChooseDatabase: true,
  allowLogout: true,
  allowSearchTimeout: true,
  allowLogoutTimeout: true,
  allowLoggedUser: true,
  enableCanny: true,
  unsetPerspectiveOnDisconnect: false,
  setPerspectiveAndSceneOnDatabaseChange: false,
  allowTrakingConsentPopup: true,
};

export const DESKTOP_DEPLOYMENT: DeploymentConfiguration = {
  deployment: 'DESKTOP',
  allowSettings: true,
  allowAbout: true,
  allowHelpAndLearn: true,
  allowChooseDatabase: true,
  allowLogout: false,
  allowSearchTimeout: true,
  allowLogoutTimeout: true,
  allowLoggedUser: true,
  enableCanny: true,
  unsetPerspectiveOnDisconnect: false,
  setPerspectiveAndSceneOnDatabaseChange: false,
  allowTrakingConsentPopup: true,
};

export const WORKSPACE_DEPLOYMENT: DeploymentConfiguration = {
  deployment: 'WORKSPACE',
  allowSettings: false,
  allowAbout: false,
  allowHelpAndLearn: false,
  allowChooseDatabase: false,
  allowLogout: false,
  allowSearchTimeout: false,
  allowLogoutTimeout: true,
  allowLoggedUser: true,
  enableCanny: false,
  unsetPerspectiveOnDisconnect: true,
  setPerspectiveAndSceneOnDatabaseChange: true,
  allowTrakingConsentPopup: false,
};

export const STANDALONE_EXPLORE_DEPLOYMENT: DeploymentConfiguration = {
  deployment: 'STANDALONE_EXPLORE',
  allowSettings: false,
  allowAbout: false,
  allowHelpAndLearn: false,
  allowChooseDatabase: false,
  allowLogout: false,
  allowSearchTimeout: false,
  allowLogoutTimeout: false,
  allowLoggedUser: false,
  enableCanny: false,
  unsetPerspectiveOnDisconnect: true,
  setPerspectiveAndSceneOnDatabaseChange: true,
  allowTrakingConsentPopup: false,
};
