import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export const CAPABILITIES = [
  'explore:enabled',
  'explore:new-upx-header',
  'explore:default-perspective',
  'import:changelog',
  'import:enabled',
  'import:welcome-tutorial',
  'query:enabled',
  'query:open-modal-on-missing-connection',
  'dashboards:enabled',
  'guides:enabled',
  'framework:theme-switcher',
  'framework:connection-bar',
  'framework:connection-bar-show-version',
  'framework:http-query-api',
  'framework:allow-all-connection-form-fields',
  'framework:mock-api',
  'framework:mock-self-serve',
  'framework:mock-enterprise',
  'framework:relate-api',
  'framework:seamless-connection',
  'framework:respect-dbms-neo4j-conf-settings',
  'framework:storage-api',
  'framework:app-controlled-settings',
  'aura:genai',
  'conversations:enabled',
  'framework:mock-first-time-user',
] as const;

export type Capability = (typeof CAPABILITIES)[number];

export type CapabilitiesState = Capability[];

export function isCapability(input: string): input is Capability {
  return CAPABILITIES.some((capability) => capability === input);
}

export const initialState: CapabilitiesState = [];

const capabilitiesSlice = createSlice({
  name: 'capabilities',
  initialState,
  reducers: {
    /**
     * Toggle or set feature flag value
     */
    toggle(state: CapabilitiesState, action: PayloadAction<{ key: Capability; value?: boolean }>) {
      const index = state.indexOf(action.payload.key);
      const currentState = index >= 0;
      const desiredState = action.payload.value ?? !currentState;

      if (desiredState !== currentState) {
        if (desiredState) {
          state.push(action.payload.key);
        } else {
          state.splice(index, 1);
        }
      }
    },
  },
  selectors: {
    /**
     * Get capability status optionally taking dependencies into account
     */
    selectCapability: (state, props: { deps?: Capability[]; key: Capability }): boolean => {
      const depsSatisfied = (props.deps ?? []).every((dep) => state.includes(dep));
      const isEnabled = state.includes(props.key);

      return depsSatisfied && isEnabled;
    },
    /**
     * Application mode where:
     * - `"standalone"` — single tool availability;
     * - `"composite"` — multiple tools availability.
     *
     * @deprecated Legacy approach, prefer using tool availability and capabilities
     */
    selectMode: (state): 'composite' | 'standalone' => {
      const enabledTools = state.filter(
        (capability) =>
          capability === 'explore:enabled' || capability === 'import:enabled' || capability === 'query:enabled',
      );
      return enabledTools.length === 1 ? 'standalone' : 'composite';
    },

    /**
     * Array of available tools.
     *
     * @deprecated Legacy approach, prefer using specific tool availability and capabilities
     */
    selectEnabledTools: (state): string[] => {
      return state.filter((capability) => ['explore:enabled', 'import:enabled', 'query:enabled'].includes(capability));
    },
  },
});

export const { reducer, reducerPath } = capabilitiesSlice;
export const { toggle } = capabilitiesSlice.actions;
export const { selectCapability, selectMode, selectEnabledTools } = capabilitiesSlice.selectors;

export default capabilitiesSlice.reducer;
