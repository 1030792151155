import type { model } from '@neo4j/graph-schema-utils';
import type * as ImportShared from '@nx/import-shared';
import { isNullish } from '@nx/stdlib';

import { type DataModel, type PropertyMapping, TableSchemaCloudField, type TableSchemaField } from './data-model';
import { dataModelJsonFormatter } from './data-model-json-formatter';
import { toIdFromRef, toRefObject } from './data-model.json.helpers';
import {
  duplicatedError,
  emptyFieldError,
  noKeyPropertyError,
  noSupportedTypesError,
  nonSpecifiedError,
} from './validation-errors';

const isEmptyString = (string?: string) => string === undefined || string.trim().length === 0;

const findArrayDuplicates = (array: string[]) => array.filter((item, index) => array.indexOf(item) !== index);

export enum VALIDATION_ERROR_CODE {
  INCOMPLETE = 'INCOMPLETE',
  DUPLICATED = 'DUPLICATED',
}

export type ErrorModel = {
  code: VALIDATION_ERROR_CODE[keyof VALIDATION_ERROR_CODE];
  message: string;
};

export type TableFieldError = {
  fieldIndex: number;
} & ErrorModel;

export type TableSchemaErrors = {
  tableName: string;
  fieldErrors: TableFieldError[];
  usedFieldErrors?: TableFieldError[];
};

export type PropertyError = { property: { $ref: string } } & ErrorModel;

export type PropertyMappingError = { property: { $ref: string } } & ErrorModel;

export type NodeErrors = {
  node: { $ref: string };
  schemaErrors: {
    labelError?: ErrorModel;
    keyError?: ErrorModel;
    propertyErrors?: PropertyError[];
  };
  mappingErrors: {
    tableSchemaError?: ErrorModel;
    propertyMappingErrors?: PropertyMappingError[];
  };
  visible: boolean;
};

export type RelationshipErrors = {
  relationship: { $ref: string };
  schemaErrors: {
    typeError?: ErrorModel;
    propertyErrors?: PropertyError[];
  };
  mappingErrors: {
    tableSchemaError?: ErrorModel;
    propertyMappingErrors?: PropertyMappingError[];
    sourceMappingError?: ErrorModel;
    targetMappingError?: ErrorModel;
  };
  visible: boolean;
};

export type DataModelErrors = {
  tableSchemaErrors: TableSchemaErrors[];
  nodeErrors: NodeErrors[];
  relationshipErrors: RelationshipErrors[];
};

const getTableSchemaErrors = (dataModel: DataModel): TableSchemaErrors[] =>
  dataModel.graphMappingRepresentation.dataSourceSchema.tableSchemas
    .map((tableSchema) => {
      const { fields } = tableSchema;
      const duplicatedFields = findArrayDuplicates(fields.map((field) => field.name));
      const fieldErrors = fields.reduce(
        (fieldErrorsAcc: TableFieldError[], field: TableSchemaField, fieldIndex: number) => {
          const { name } = field;
          if (isEmptyString(name)) {
            return [...fieldErrorsAcc, { fieldIndex, ...emptyFieldError }];
          } else if (duplicatedFields.includes(name)) {
            return [...fieldErrorsAcc, { fieldIndex, ...duplicatedError }];
          }
          if (field instanceof TableSchemaCloudField) {
            const { recommendedType, supportedTypes } = field;
            if (isNullish(recommendedType) || supportedTypes?.length === 0) {
              return [...fieldErrorsAcc, { fieldIndex, ...noSupportedTypesError }];
            }
          }
          return fieldErrorsAcc;
        },
        [],
      );
      const usedFieldErrors = fieldErrors.filter((fieldError) => {
        const field = fields[fieldError.fieldIndex];
        return !isNullish(field) && field.isUsed;
      });
      return { tableName: tableSchema.name, fieldErrors, usedFieldErrors };
    })
    .filter(({ fieldErrors }) => fieldErrors.length > 0);

const getPropertyErrors = (properties: model.Property[]) => {
  const duplicatedPropertyNames = findArrayDuplicates(properties.map((property) => property.token));
  return properties.reduce((propertyErrorsAcc: PropertyError[], property: model.Property) => {
    const name = property.token;
    if (isEmptyString(name)) {
      return [...propertyErrorsAcc, { property: toRefObject(property.$id), ...emptyFieldError }];
    } else if (duplicatedPropertyNames.includes(property.token)) {
      return [...propertyErrorsAcc, { property: toRefObject(property.$id), ...duplicatedError }];
    }
    return propertyErrorsAcc;
  }, []);
};

const getPropertyMappingErrors = (properties: model.Property[], propertyMappings: PropertyMapping[]) =>
  properties
    .filter((property) => !propertyMappings.some((mapping) => mapping.property.$id === property.$id))
    .map((property) => ({ property: toRefObject(property.$id), ...nonSpecifiedError }));

const getNodeErrors = (dataModel: DataModel, previousNodeErrors: NodeErrors[]): NodeErrors[] =>
  dataModel.nodeModels
    .map((node) => {
      const { nodeObjectType, nodeMapping, nodeKeyProperty } = node;
      const isLabelMissing = nodeObjectType.labels.length === 0 || isEmptyString(nodeObjectType.labels[0]?.token);
      const isKeyMissing = isNullish(nodeKeyProperty);
      const propertyErrors = getPropertyErrors(nodeObjectType.getProperties());
      const propertyMappingErrors = getPropertyMappingErrors(
        nodeObjectType.getProperties(),
        nodeMapping?.propertyMappings ?? [],
      );
      const isTableSchemaMissing = isNullish(nodeMapping);
      const isNodeErrorVisible =
        previousNodeErrors.find((nodeError) => toIdFromRef(nodeError.node.$ref) === nodeObjectType.$id)?.visible ??
        false;
      return {
        node: toRefObject(nodeObjectType.$id),
        schemaErrors: {
          labelError: isLabelMissing ? emptyFieldError : undefined,
          keyError: isKeyMissing ? noKeyPropertyError : undefined,
          propertyErrors: propertyErrors.length > 0 ? propertyErrors : undefined,
        },
        mappingErrors: {
          tableSchemaError: isTableSchemaMissing ? nonSpecifiedError : undefined,
          propertyMappingErrors: propertyMappingErrors.length > 0 ? propertyMappingErrors : undefined,
        },
        visible: isNodeErrorVisible,
      };
    })
    .filter(
      (nodeError) =>
        !isNullish(nodeError.schemaErrors.labelError) ||
        !isNullish(nodeError.schemaErrors.keyError) ||
        !isNullish(nodeError.schemaErrors.propertyErrors) ||
        !isNullish(nodeError.mappingErrors.tableSchemaError) ||
        !isNullish(nodeError.mappingErrors.propertyMappingErrors),
    );

const getRelationshipErrors = (
  dataModel: DataModel,
  previousRelationshipErrors: RelationshipErrors[],
): RelationshipErrors[] =>
  dataModel.relationshipModels
    .map((relationship) => {
      const { relationshipObjectType, relationshipMapping } = relationship;
      const isTypeMissing = isEmptyString(relationshipObjectType.type.token);
      const propertyErrors = getPropertyErrors(relationshipObjectType.getProperties());
      const propertyMappingErrors = getPropertyMappingErrors(
        relationshipObjectType.getProperties(),
        relationshipMapping?.propertyMappings ?? [],
      );
      const isTableSchemaMissing = isNullish(relationshipMapping);
      const isSourceMappingMissing = isNullish(relationship.relationshipMapping?.fromMapping);
      const isTargetMappingMissing = isNullish(relationship.relationshipMapping?.toMapping);
      const isRelationshipErrorVisible =
        previousRelationshipErrors.find(
          (relationshipError) => toIdFromRef(relationshipError.relationship.$ref) === relationshipObjectType.$id,
        )?.visible ?? false;
      return {
        relationship: toRefObject(relationshipObjectType.$id),
        schemaErrors: {
          typeError: isTypeMissing ? emptyFieldError : undefined,
          propertyErrors: propertyErrors.length > 0 ? propertyErrors : undefined,
        },
        mappingErrors: {
          tableSchemaError: isTableSchemaMissing ? nonSpecifiedError : undefined,
          propertyMappingErrors: propertyMappingErrors.length > 0 ? propertyMappingErrors : undefined,
          sourceMappingError: isSourceMappingMissing ? nonSpecifiedError : undefined,
          targetMappingError: isTargetMappingMissing ? nonSpecifiedError : undefined,
        },
        visible: isRelationshipErrorVisible,
      };
    })
    .filter(
      (relationshipError) =>
        !isNullish(relationshipError.schemaErrors.typeError) ||
        !isNullish(relationshipError.schemaErrors.propertyErrors) ||
        !isNullish(relationshipError.mappingErrors.tableSchemaError) ||
        !isNullish(relationshipError.mappingErrors.sourceMappingError) ||
        !isNullish(relationshipError.mappingErrors.targetMappingError) ||
        !isNullish(relationshipError.mappingErrors.propertyMappingErrors),
    );

export const getDataModelErrors = (
  dataModelState: ImportShared.DataModelJsonStruct & { errors: DataModelErrors },
): DataModelErrors => {
  const dataModel = dataModelJsonFormatter.fromJsonStruct(dataModelState);
  const previousErrors = dataModelState.errors;
  return {
    tableSchemaErrors: getTableSchemaErrors(dataModel),
    nodeErrors: getNodeErrors(dataModel, previousErrors.nodeErrors),
    relationshipErrors: getRelationshipErrors(dataModel, previousErrors.relationshipErrors),
  };
};

export const setDataModelErrorsVisible = (errors: DataModelErrors) => {
  errors.nodeErrors.forEach((nodeError) => {
    nodeError.visible = true;
  });
  errors.relationshipErrors.forEach((relationshipError) => {
    relationshipError.visible = true;
  });
};
