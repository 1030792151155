// default fixed height for plots
export const DEFAULT_CHART_HEIGHT = 400;
export const COMPACT_CHART_HEIGHT = 200;
// ms
export const POLLING_INTERVAL = 2_000;
// ms
export const LONG_POLLING_INTERVAL = 15_000;
// ms
export const VERY_LONG_POLLING_INTERVAL = 60_000;
export const MEGABYTE_DIVISION = 1024 ** 2;
export const GIGABYTE_DIVISION = 1024 ** 3;
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale/Locale
export const LOCALE_TAG = 'en-US';
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale/timeZones
export const UTC_TIMEZONE = 'UTC';
/** Time window to ignore identical errors, when for example a member
 * is removed, because Routing Table will still show it as available */
// ms
export const IDENTICAL_ERROR_DISCARD_TIME = 30_000;
