import { Button, LoadingSpinner, Select, TextLink, Typography } from '@neo4j-ndl/react';
import { CheckCircleIconSolid, XCircleIconSolid } from '@neo4j-ndl/react/icons';
import { neo4jVersionUtil } from '@nx/neo4j-version-utils';
import type { LocalInstanceInfo, ProgressEvent } from '@nx/state';
import {
  MODAL_TYPE,
  PROGRESS_STATUS,
  selectProgressEvents,
  useListNeo4jVersionsQuery,
  useModal,
  useUpgradeLocalInstanceMutation,
  useWatchProgressQuery,
} from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';

import { isNeo4j4x, isNeo4jGTE5x, isNeo4jSupportedUpgradeVersion } from '../neo4j-version';
import {
  UnsupportedVersionMessage,
  UpgradeNotSupportedMessage,
  UpgradePathRecommendationsMessage,
} from './upgrade-messages';

function ProgressStep({ step }: { step: ProgressEvent }) {
  if (step.status === PROGRESS_STATUS.SUCCESS) {
    return (
      <div className="flex gap-3">
        <CheckCircleIconSolid className="size-token-7 fill-success-icon" />
        <Typography variant="body-large">{step.message}</Typography>
      </div>
    );
  }

  if (step.status === PROGRESS_STATUS.LOADING) {
    return (
      <div className="flex gap-3">
        <LoadingSpinner size="medium" />
        <Typography variant="body-large">{step.message}</Typography>
      </div>
    );
  }

  return (
    <div className="flex gap-3">
      <XCircleIconSolid className="size-token-7 fill-danger-icon" />
      <Typography variant="body-large">{step.message}</Typography>
    </div>
  );
}

export function Upgrade({ instance }: { instance: LocalInstanceInfo }) {
  const { version: currentVersion } = instance;
  const { data: versions } = useListNeo4jVersionsQuery();
  const [targetVersion, setTargetVersion] = useState<{ value: string; label: string } | undefined>();
  const [, upgradeData] = useUpgradeLocalInstanceMutation({ fixedCacheKey: `dbms-upgrade-${instance.id}` });
  const { data: progressEvents } = useWatchProgressQuery({ operation: 'dbmsUpgrade', operationId: [instance.id] });

  const { open: openModal } = useModal(MODAL_TYPE.DBMS_UPGRADE);

  const supportsUpgrade = !(isNeo4j4x(currentVersion) && isNeo4jGTE5x(targetVersion?.value));

  const options = (versions ?? [])
    .filter((v) => neo4jVersionUtil.gt(v.version, currentVersion ?? '5.0.0'))
    .map((v) => ({ label: v.version, value: v.version }))
    .sort((a, b) => neo4jVersionUtil.rcompare(a.value, b.value));

  const versionString: string =
    targetVersion === undefined || neo4jVersionUtil.gte(targetVersion.value, '5.0.0')
      ? '5'
      : targetVersion.value.replaceAll('.', '-');
  const releaseNotesUrl = `https://neo4j.com/release-notes/database/neo4j-${versionString}/`;

  const onSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    if (targetVersion === undefined) {
      // This is to please Typescript, we shouldn't actually reach this point
      // since we don't enable the submit button if targetVersion is not
      // defined.
      return;
    }

    openModal({
      dbmsId: instance.id,
      dbmsName: instance.name,
      targetVersion: targetVersion.value,
    });
  };

  const steps = selectProgressEvents(progressEvents, 'dbmsUpgrade', [instance.id]);

  return (
    <form className="flex flex-col gap-4 pt-4">
      <Select
        size="medium"
        type="select"
        selectProps={{ isDisabled: true, value: { label: currentVersion, value: currentVersion } }}
        label="Current version"
      />
      <Select
        size="medium"
        type="select"
        selectProps={{
          isDisabled: upgradeData.isLoading,
          options,
          onChange: (value) => setTargetVersion(value ?? undefined),
        }}
        label="New version"
      />
      {targetVersion && (
        <TextLink isExternalLink href={releaseNotesUrl} target="_blank">
          Release notes
        </TextLink>
      )}

      {!supportsUpgrade && <UpgradeNotSupportedMessage />}

      {supportsUpgrade && isNotNullish(currentVersion) && isNeo4j4x(currentVersion) && (
        <UpgradePathRecommendationsMessage version={currentVersion} />
      )}

      {isNotNullish(currentVersion) && !isNeo4jSupportedUpgradeVersion(currentVersion) && (
        <UnsupportedVersionMessage version={currentVersion} />
      )}

      <div className="flex flex-row justify-end gap-4 pt-8">
        <Button
          type="submit"
          isDisabled={targetVersion === undefined}
          onClick={onSubmit}
          isLoading={upgradeData.isLoading}
        >
          {upgradeData.isLoading ? 'Upgrading' : 'Upgrade'}
        </Button>
      </div>
      {steps !== undefined && <Typography variant="h5">Upgrade progress</Typography>}
      {steps !== undefined &&
        Object.entries(steps).map(([message, step]) => <ProgressStep step={step} key={message} />)}
    </form>
  );
}
