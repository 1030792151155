import { neo4jVersionUtil } from '@nx/neo4j-version-utils';
import { cloneDeep } from 'lodash-es';

import { alphabeticalOrder } from '../../services/sort';
import { labelColorPalette as defaultPalette } from '../../styles/colors';
import uncategorizedIcon from '../../styles/icons/node-trapezoid.svg?raw';
import { PerspectiveType } from '../../types/perspective';
import {
  CATEGORY_LABEL_CAPTION_KEY_PREFIX,
  DEFAULT_CATEGORY_CAPTION_KEYS,
  DEFAULT_RELATIONSHIP_COLOR,
  DEFAULT_RELATIONSHIP_SIZE,
  DEFAULT_UNCATEGORISED_ICON,
  DEFAULT_UNCATEGORISED_TEXT_ALIGN,
  DEFAULT_UNCATEGORISED_TEXT_ALIGN_RELATIONSHIP,
  DEFAULT_UNCATEGORISED_TEXT_SIZE,
  RELATIONSHIP_TYPE_CAPTION_KEY,
} from '../styles/styles.const';
import { CAPTION_TYPE_LABEL, CAPTION_TYPE_PROPERTY, CAPTION_TYPE_REL } from '../styles/types';
import { DEFAULT_UNCATEGORISED_ID } from './constants';

// We cant use deep equal or slt because the object in local storage doesn't have class information on itself and members
const svgEqual = (first, second) =>
  first &&
  first.id &&
  first.viewBox &&
  first.content &&
  second &&
  second.id &&
  second.viewBox &&
  second.content &&
  first.id === second.id &&
  first.viewBox === second.viewBox &&
  first.content === second.content;

const migrateType = (type) => (['Boolean', 'Number', 'String'].includes(type) ? type.toLowerCase() : type);

const migrateDate = (date = Date.now()) => new Date(date).getTime() || undefined;
const { compare } = neo4jVersionUtil;
export const trasformations = [
  {
    version: '0.8.2',
    up: (state) => ({ ...state }),
  },
  {
    version: '0.8.11',
    up: (state) => {
      return {
        ...state,
        perspectives: state.perspectives.map((p) => ({
          ...p,
          categories: (p.categories || []).map((c) => ({
            ...c,
            icon: svgEqual(c.icon, uncategorizedIcon) ? DEFAULT_UNCATEGORISED_ICON : c.icon,
            properties: c.properties.map((prop) => ({
              ...prop,
              dataType: migrateType(prop.dataType),
            })),
          })),
        })),
      };
    },
  },
  {
    version: '1.0.5',
    up: (state) => ({
      ...state,
      currentPerspectiveId: undefined,
    }),
  },
  {
    version: '1.0.6',
    up: (state) => {
      return {
        ...state,
        perspectives: state.perspectives.map((p) => ({
          ...p,
          hiddenRelationshipTypes: p.hiddenRelationshipTypes || [],
          hideUncategorisedData: p.hideUncategorisedData || false,
          categories: (p.categories || []).map((c) => ({
            ...c,
            hiddenLabels: c.hiddenLabels || [],
          })),
        })),
      };
    },
  },
  {
    version: '1.1.0',
    up: (state) => {
      const migrated = { ...state };
      delete migrated.shouldPickPerspective;
      return migrated;
    },
  },
  {
    version: '1.2.0',
    up: (state) => ({
      ...state,
      perspectives: state.perspectives.map((p) => ({
        ...p,
        relationshipTypes: p.relationshipTypes || [],
        categories: (p.categories || []).map((c, index) => ({
          ...c,
          size: c.size || 1,
        })),
        palette: {
          colors: defaultPalette,
          currentIndex: p.palette ? p.palette.currentIndex : 0,
        },
        templates: (p.templates || []).map((t) => ({
          ...t,
          params: t.params.map((param) => ({
            dataType: 'String',
            ...param,
          })),
        })),
      })),
    }),
  },
  {
    version: '1.3.0',
    up: (state) => ({
      ...state,
      perspectives: state.perspectives.map((p) => ({
        ...p,
        createdAt: migrateDate(p.createdAt),
        lastEditedAt: migrateDate(p.lastEditedAt),
        categories: (p.categories || []).map((c) => ({
          ...c,
          createdAt: c.createdAt ? migrateDate(c.createdAt) : undefined,
          lastEditedAt: c.lastEditedAt ? migrateDate(c.lastEditedAt) : undefined,
        })),
        templates: (p.templates || []).map((t) => ({
          ...t,
          createdAt: migrateDate(t.createdAt),
        })),
        dbmsId: null,
        dbmsVersion: null,
        dbId: null,
        dbName: null,
        parentPerspectiveId: null,
      })),
    }),
  },
  {
    version: '1.3.2',
    up: (state) => ({
      ...state,
      perspectives: state.perspectives.map((p) => {
        const { categoryIndex, ...newPerspective } = p;
        return {
          ...newPerspective,
          categories: (newPerspective.categories || []).map((c, index) => ({
            ...c,
            id: index,
          })),
        };
      }),
    }),
  },
  {
    version: '1.4.0',
    up: (state) => {
      return {
        ...state,
        perspectives: state.perspectives.map((p) => ({
          ...p,
          categories: (p.categories || []).map((category) => {
            const { hiddenLabels, ...newCategory } = category;
            return newCategory;
          }),
          relationshipTypes: (p.relationshipTypes || []).map((relType) => ({
            ...relType,
            properties: relType.properties || [],
          })),
        })),
      };
    },
  },
  {
    version: '1.5.0',
    up: (state) => {
      return {
        ...state,
        perspectives: state.perspectives.map((p) => ({
          ...p,
          relationshipTypes: (p.relationshipTypes || []).map((relType) => ({
            ...relType,
            properties: relType.properties || [],
          })),
          labels: p.labels || {},
          metadata: p.metadata || {
            indexes: [],
            pathSegments: [],
            stats: {},
          },
        })),
      };
    },
  },
  {
    version: '1.6.0',
    up: (state) => {
      return {
        ...state,
        perspectives: state.perspectives.map((p) => ({
          ...p,
          // Remove "other" category
          categories: (p.categories || []).filter((category) => category.id !== DEFAULT_UNCATEGORISED_ID),
        })),
      };
    },
  },
  {
    version: '1.7.0',
    up: (state) => {
      const DEFAULT_PERSPECTIVE_ID = 'default-perspective-id';
      return {
        ...state,
        perspectives: state.perspectives
          .filter((p) => p.id !== DEFAULT_PERSPECTIVE_ID)
          .map((p) => ({
            ...p,
            relationshipTypes: (p.relationshipTypes || []).map((relType) => ({
              ...relType,
              properties: relType.properties || [],
            })),
            isAuto: !!(p.isAuto && p.isAuto === true),
          })),
        currentPerspectiveId:
          state.currentPerspectiveId && state.currentPerspectiveId !== DEFAULT_PERSPECTIVE_ID
            ? state.currentPerspectiveId
            : null,
      };
    },
  },
  {
    version: '1.8.0',
    up: (state) => {
      const migrated = {
        ...state,
        perspectives: state.perspectives.map((p) => ({
          ...p,
          categories: (p.categories || []).map((category) => ({
            ...category,
            styleRules: (category.styleRules || []).map((styleRule) => ({
              ...styleRule,
              id: `rule:${Date.now() - Math.floor(Math.random() * 10000)}`,
            })),
          })),
          relationshipTypes: (p.relationshipTypes || []).map((relType) => ({
            ...relType,
            styleRules: (relType.styleRules || []).map((styleRule) => ({
              ...styleRule,
              id: `rule:${Date.now() - Math.floor(Math.random() * 10000)}`,
            })),
          })),
        })),
      };
      migrated.currentPerspectiveId && delete migrated.currentPerspectiveId;
      return migrated;
    },
  },
  {
    version: '2.2.0',
    up: (state) => {
      return {
        ...state,
        perspectives: state.perspectives.map((perspective) => {
          return {
            ...perspective,
            categories: perspective.categories?.map((category) => {
              // if the migration already done by PluginPersistor, no need to continue
              if (category.captionKeys) {
                return category;
              }

              delete category.caption;

              const captionKeys = [...DEFAULT_CATEGORY_CAPTION_KEYS];
              const updatedProperties = [];

              for (const property of category.properties) {
                // cloneDeep the property so we don't change the rehydrating document
                // since we are need isCaption in style migration
                const updateProperty = cloneDeep(property);

                if (updateProperty.isCaption) {
                  captionKeys.push(updateProperty.name);
                }

                delete updateProperty.isCaption;
                updatedProperties.push(updateProperty);
              }

              return {
                ...category,
                captionKeys: captionKeys.toSorted(alphabeticalOrder),
                properties: updatedProperties,
              };
            }),
            relationshipTypes: perspective.relationshipTypes?.map((relationshipType) => {
              return {
                color: DEFAULT_RELATIONSHIP_COLOR,
                size: DEFAULT_RELATIONSHIP_SIZE,
                captionKeys: [RELATIONSHIP_TYPE_CAPTION_KEY],
                ...relationshipType,
              };
            }),
          };
        }),
      };
    },
  },
  {
    version: '2.6.0',
    up: (state) => {
      return {
        ...state,
        perspectives: state.perspectives.map((perspective) => {
          return {
            ...perspective,
            metadata: {
              ...perspective.metadata,
              indexes: perspective.metadata.indexes.map((index) => {
                return { ...index, propertyKeys: index.propertyKeys?.map((key) => ({ key, metadataProp: false })) };
              }),
            },
          };
        }),
      };
    },
  },
  {
    version: '2.7.0',
    up: (state) => {
      return {
        ...state,
        perspectives: state.perspectives.map((perspective) => {
          return {
            ...perspective,
            metadata: {
              ...perspective.metadata,
              indexes: perspective.metadata.indexes?.map((index) => {
                if (index.type === 'full-text') {
                  return {
                    ...index,
                    entityType: 'NODE',
                  };
                }
                return index;
              }),
            },
          };
        }),
      };
    },
  },
  {
    version: '2.7.1',
    up: (state) => {
      return {
        ...state,
        perspectives: state.perspectives.map((perspective) => {
          return {
            ...perspective,
            dbId: perspective.dbName,
          };
        }),
      };
    },
  },
  {
    version: '2.8.0',
    up: (state) => {
      return {
        ...state,
        perspectives: (state.perspectives || []).map((perspective) => {
          return {
            ...perspective,
            categories: (perspective?.categories || []).map((category) => {
              const properties = category.properties.filter((prop) => !prop.exclude);
              const newCategoryPropertyCaptions = properties.reduce((acc, a) => {
                const isCaption = (category?.captionKeys || []).includes(a.name);
                acc = [
                  ...acc,
                  {
                    key: a.name,
                    styles: [],
                    inTooltip: isCaption,
                    isCaption,
                    type: CAPTION_TYPE_PROPERTY,
                  },
                ];
                return acc;
              }, []);

              const existingLabelsCaptions = (category?.captionKeys || []).filter((a) =>
                a.startsWith(CATEGORY_LABEL_CAPTION_KEY_PREFIX),
              );
              const existingLabelsCaptionsKeys = existingLabelsCaptions.map((a) =>
                a.replace(CATEGORY_LABEL_CAPTION_KEY_PREFIX, ''),
              );
              const newCategoryLabelsCaptions = category.labels.reduce((acc, a) => {
                const isCaption = existingLabelsCaptionsKeys.includes(a);
                acc = [
                  ...acc,
                  {
                    key: a,
                    styles: [],
                    inTooltip: isCaption,
                    isCaption,
                    type: CAPTION_TYPE_LABEL,
                  },
                ];
                return acc;
              }, []);

              const newStyleRules = (category?.styleRules || []).map((rule) => {
                const newRulePropertyCaptions = properties.reduce((acc, a) => {
                  const isCaption = (rule?.captionKeys || []).includes(a.name);
                  acc = [
                    ...acc,
                    {
                      key: a.name,
                      styles: [],
                      inTooltip: isCaption,
                      isCaption,
                      type: CAPTION_TYPE_PROPERTY,
                    },
                  ];
                  return acc;
                }, []);

                const existingRuleLabelsCaptions = (rule?.captionKeys || []).filter((a) =>
                  a.startsWith(CATEGORY_LABEL_CAPTION_KEY_PREFIX),
                );
                const existingRuleLabelsCaptionsKeys = existingRuleLabelsCaptions.map((a) =>
                  a.replace(CATEGORY_LABEL_CAPTION_KEY_PREFIX, ''),
                );
                const newRuleLabelsCaptions = category.labels.reduce((acc, a) => {
                  const isCaption = existingRuleLabelsCaptionsKeys.includes(a);
                  acc = [
                    ...acc,
                    {
                      key: a,
                      styles: [],
                      inTooltip: isCaption,
                      isCaption,
                      type: CAPTION_TYPE_LABEL,
                    },
                  ];
                  return acc;
                }, []);

                return {
                  ...rule,
                  captions: [...newRuleLabelsCaptions, ...newRulePropertyCaptions],
                  textAlign: DEFAULT_UNCATEGORISED_TEXT_ALIGN,
                  textSize: DEFAULT_UNCATEGORISED_TEXT_SIZE,
                };
              });

              return {
                ...category,
                captions: [...newCategoryLabelsCaptions, ...newCategoryPropertyCaptions],
                styleRules: [...newStyleRules],
                textAlign: DEFAULT_UNCATEGORISED_TEXT_ALIGN,
                textSize: DEFAULT_UNCATEGORISED_TEXT_SIZE,
              };
            }),
            relationshipTypes: (perspective.relationshipTypes || []).map((relType) => {
              const newRelPropertyCaptions = relType.properties.reduce((acc, a) => {
                const isCaption = (relType?.captionKeys || []).includes(a.propertyKey);
                acc = [
                  ...acc,
                  {
                    key: a.propertyKey,
                    styles: [],
                    inTooltip: isCaption,
                    isCaption,
                    type: CAPTION_TYPE_PROPERTY,
                  },
                ];
                return acc;
              }, []);

              const isRelTypeCaption = !!(relType?.captionKeys || []).find((a) => a === RELATIONSHIP_TYPE_CAPTION_KEY);
              const newRelTypeCaptions = [
                {
                  key: relType.name,
                  styles: [],
                  inTooltip: isRelTypeCaption,
                  isCaption: isRelTypeCaption,
                  type: CAPTION_TYPE_REL,
                },
              ];

              const newStyleRules = (relType?.styleRules || []).map((rule) => {
                const newRulePropertyCaptions = relType.properties.reduce((acc, a) => {
                  const isCaption = (rule?.captionKeys || []).includes(a.propertyKey);
                  acc = [
                    {
                      key: a.propertyKey,
                      styles: [],
                      inTooltip: isCaption,
                      isCaption,
                      type: CAPTION_TYPE_PROPERTY,
                    },
                  ];
                  return acc;
                }, []);

                const isRelTypeCaption = !!(rule?.captionKeys || []).find((a) => a === RELATIONSHIP_TYPE_CAPTION_KEY);
                const newRelTypeCaptions = [
                  {
                    key: relType.name,
                    styles: [],
                    inTooltip: isRelTypeCaption,
                    isCaption: isRelTypeCaption,
                    type: CAPTION_TYPE_REL,
                  },
                ];

                return {
                  ...rule,
                  captions: [...newRelTypeCaptions, ...newRulePropertyCaptions],
                  textAlign: DEFAULT_UNCATEGORISED_TEXT_ALIGN_RELATIONSHIP,
                  textSize: DEFAULT_UNCATEGORISED_TEXT_SIZE,
                };
              });

              return {
                ...relType,
                captions: [...newRelTypeCaptions, ...newRelPropertyCaptions],
                styleRules: [...newStyleRules],
                textAlign: DEFAULT_UNCATEGORISED_TEXT_ALIGN_RELATIONSHIP,
                textSize: DEFAULT_UNCATEGORISED_TEXT_SIZE,
              };
            }),
          };
        }),
      };
    },
  },
  {
    version: '2.13.0',
    up: (state) => {
      return {
        ...state,
        perspectives: state.perspectives.map((perspective) => {
          const type = perspective.isAuto ? PerspectiveType.AUTO : PerspectiveType.STANDARD;
          delete perspective.isAuto;
          return {
            ...perspective,
            type,
          };
        }),
      };
    },
  },
  {
    version: '2.20.0',
    up: (state) => {
      return {
        ...state,
      };
    },
  },
];
export const getLatestPerspectiveVersion = () =>
  trasformations
    .map((n) => n.version)
    .sort(compare)
    .pop();
