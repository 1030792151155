import { IconButton, InlineEdit, Typography } from '@neo4j-ndl/react';
import { ArrowRightIconOutline } from '@neo4j-ndl/react/icons';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import type { BillingAccount } from '@nx/state';
import {
  ACTION,
  BILLING_MODEL,
  BILLING_PROCESSOR,
  PAYMENT_METHOD,
  PROJECT_BILLING_METHOD,
  consoleApi,
  getApiError,
  getErrorMessage,
  useActiveOrg,
  useActiveProject,
  useNotificationActions,
  usePermissions,
} from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import { ClipboardCopier, Stack } from '@nx/ui';
import { ControlPanel } from '@nx/ui/src/control-panel';
import type { SerializedError } from '@reduxjs/toolkit';
import { type FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { validateYup } from '../../utils/validation';

const logger = createLogger(APP_SCOPE.framework);

export const updateProjectNameSchema = yup.object({
  name: yup
    .string()
    .max(30, 'Project name can be no longer than 30 characters')
    .required('Project name cannot be empty'),
});

export const validate = (data: { name: string }, { onlyRequired = false }: { onlyRequired?: boolean } = {}) =>
  validateYup(updateProjectNameSchema, data, onlyRequired);

export function Settings() {
  const activeProject = useActiveProject();
  const { addNotification } = useNotificationActions();
  const { isAllowed } = usePermissions();
  const allowUpdateProjectName = isAllowed(ACTION.UPDATE, `namespaces/${activeProject.id}`);
  const allowLogForwarding = isAllowed(ACTION.READ, `namespaces/${activeProject.id}/log-forwarding`);
  const navigate = useNavigate();
  const showLogForwarding =
    (activeProject.capabilities.single_instance_log_forwarding || activeProject.capabilities.log_forwarding) &&
    allowLogForwarding;
  const showNetworkConfig =
    activeProject.capabilities.traffic_config &&
    isAllowed(ACTION.READ, `namespaces/${activeProject.id}/traffic-configs`);
  const showCmek =
    activeProject.capabilities.cmek && isAllowed(ACTION.READ, `namespaces/${activeProject.id}/encryption-keys`);
  const showCustomEndpoints =
    activeProject.capabilities.custom_endpoints &&
    isAllowed(ACTION.READ, `namespaces/${activeProject.id}/custom-endpoints`);

  const org = useActiveOrg(false);
  const showBillingCell = org?.capabilities.billing_account ?? false;

  const {
    data: billingAccount,
    isLoading: isLoadingBilling,
    isFetching: isFetchingBilling,
  } = consoleApi.useGetBillingAccountForProjectQuery(showBillingCell ? activeProject.id : skipToken);

  const isPaygBilling = activeProject.billingMethod === PROJECT_BILLING_METHOD.PAYG;
  const canReadCard = isAllowed(ACTION.READ, `tenants/${activeProject.id}/billing/card`);
  const cardRes = consoleApi.useGetCardQuery(isPaygBilling && canReadCard ? activeProject.id : skipToken);

  const [updateProjectName] = consoleApi.useUpdateProjectNameMutation();

  const handleUpdateProjectName = (name: string) => {
    updateProjectName({ name: name.trim(), projectId: activeProject.id })
      .unwrap()
      .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(e);
        if (isNotNullish(error.message)) {
          logger.error(error.message);
        }
        addNotification({ type: 'danger', title: '', description: getErrorMessage(error), timeout: 5000 });
      });
  };

  const getBillingAccountInfo = (account: BillingAccount) => {
    if (account.billingProcessor === BILLING_PROCESSOR.AWS_MARKETPLACE) {
      return 'Billed through AWS Marketplace.';
    } else if (account.billingProcessor === BILLING_PROCESSOR.GCP_MARKETPLACE) {
      return 'Billed through GCP Marketplace.';
    } else if (account.billingProcessor === BILLING_PROCESSOR.AZURE_MARKETPLACE) {
      return 'Billed through Azure Marketplace.';
    }

    if (account.billingModel === BILLING_MODEL.PAY_AS_YOU_GO && account.paymentMethod === PAYMENT_METHOD.CREDIT_CARD) {
      if (isNullish(cardRes) || isNullish(cardRes.data)) {
        return 'Credit/Debit Card';
      }

      const expiryDate = `${cardRes.data.expMonth.padStart(2, '0')}/${cardRes.data.expYear.substring(2)}`;
      return `Credit/Debit Card: **** **** **** ${cardRes.data.last4} ${expiryDate}`;
    }

    // All other cases are Prepaid Credits
    return 'Prepaid Credits';
  };

  return (
    <div className="h-full overflow-auto p-4">
      <Typography variant="h2" className="mb-6 px-2 pt-2">
        Project settings
      </Typography>
      <div className="flex flex-col gap-6">
        <ControlPanel>
          <div className="flex flex-wrap gap-x-[8%] gap-y-4">
            <ControlPanel.Cell label="Project name">
              {allowUpdateProjectName ? (
                <div data-testid="edit-project-name">
                  <InlineEdit
                    defaultValue={activeProject.name}
                    validate={(name) => validate({ name })?.name?.message ?? true}
                    onConfirm={(name) => handleUpdateProjectName(name)}
                    hasEditIcon
                    variant="subheading-medium"
                    htmlAttributes={{
                      'aria-label': 'Project name',
                    }}
                  />
                </div>
              ) : (
                <h5 className="ml-1">{activeProject.name}</h5>
              )}
            </ControlPanel.Cell>

            <ControlPanel.Cell label="Project ID">
              <div className="flex items-center gap-1.5">
                <Typography variant="subheading-medium">{activeProject.id}</Typography>
                <ClipboardCopier
                  ariaLabel="Copy project ID"
                  title="Copy project ID"
                  textToCopy={activeProject.id}
                  iconButtonSize="small"
                />
              </div>
            </ControlPanel.Cell>

            {showBillingCell && (
              <ControlPanel.Cell label="Billing">
                {isLoadingBilling || isFetchingBilling || isNullish(billingAccount) ? (
                  <p>Loading...</p>
                ) : (
                  <p>{getBillingAccountInfo(billingAccount)}</p>
                )}
              </ControlPanel.Cell>
            )}

            {/* {isNonEmptyString(activeProject.createdAt) && (
              <ControlPanel.Cell label="Created on">
                {activeProject.createdAt.slice(0, 16).replace('T', ' - ')}
              </ControlPanel.Cell>
            )} */}
          </div>
        </ControlPanel>

        <ControlPanel>
          <div className="flex flex-col gap-6">
            <Typography variant={'h5'}>Observability</Typography>
            <Stack space="48px" divider>
              <ControlPanel.Row
                title="Metrics integration"
                description="Configure scraping of Neo4j Aura instance metric data for monitoring purposes"
                action={
                  <IconButton
                    isClean
                    // TODO: confirm aria label
                    ariaLabel="Metrics integration settings"
                    onClick={() => navigate(`/projects/${activeProject.id}/settings/metrics-integration`)}
                    htmlAttributes={{
                      'data-testid': 'cmi-settings',
                    }}
                  >
                    <ArrowRightIconOutline />
                  </IconButton>
                }
              />
              {showLogForwarding && (
                <ControlPanel.Row
                  title="Log forwarding"
                  description="Forward logs to your own cloud project"
                  action={
                    <IconButton
                      isClean
                      // TODO: confirm aria label
                      ariaLabel="Log forwarding settings"
                      onClick={() => navigate(`/projects/${activeProject.id}/settings/log-forwarding/`)}
                      htmlAttributes={{
                        'data-testid': 'log-forwarding-settings',
                      }}
                    >
                      <ArrowRightIconOutline />
                    </IconButton>
                  }
                />
              )}
            </Stack>
          </div>
        </ControlPanel>
        {(showNetworkConfig || showCmek || showCustomEndpoints) && (
          <ControlPanel>
            <div className="flex flex-col gap-6">
              <Typography variant={'h5'}>Security & Networking</Typography>
              <Stack space="48px" divider>
                {showCmek && (
                  <ControlPanel.Row
                    title="Encryption keys"
                    description="Use your own encryption keys instead of keys managed by Neo4j"
                    action={
                      <IconButton
                        isClean
                        onClick={() => navigate(`/projects/${activeProject.id}/settings/cmek`)}
                        // TODO: add aria label
                        ariaLabel=""
                        htmlAttributes={{
                          'data-testid': 'cmek-settings',
                        }}
                      >
                        <ArrowRightIconOutline />
                      </IconButton>
                    }
                  />
                )}
                {showNetworkConfig && (
                  <ControlPanel.Row
                    title="Private endpoints"
                    description="Configure network access to your instance"
                    action={
                      <IconButton
                        isClean
                        /* TODO: add aria label*/
                        ariaLabel=""
                        onClick={() => navigate(`/projects/${activeProject.id}/settings/network`)}
                        htmlAttributes={{
                          'data-testid': 'network-settings',
                        }}
                      >
                        <ArrowRightIconOutline />
                      </IconButton>
                    }
                  />
                )}
                {showCustomEndpoints && (
                  <ControlPanel.Row
                    title="Custom endpoints"
                    description="Used for database management and migration"
                    action={
                      <IconButton
                        isClean
                        // TODO: confirm aria label
                        ariaLabel="Custom endpoints settings"
                        onClick={() => navigate(`/projects/${activeProject.id}/settings/custom-endpoints`)}
                        htmlAttributes={{
                          'data-testid': 'custom-endpoints-settings',
                        }}
                      >
                        <ArrowRightIconOutline />
                      </IconButton>
                    }
                  />
                )}
              </Stack>
            </div>
          </ControlPanel>
        )}
      </div>
    </div>
  );
}
