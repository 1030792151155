// Neo4j Docs: https://neo4j.com/docs/operations-manual/current/database-administration/standard-databases/manage-databases/#manage-databases-list
// Minimal supported version is 4.4.0
// TODO: Move to neo4j-sdk together with SHOW DATABASES query.
import type { CypherVersion } from '@nx/neo4j-sdk/src/types/sdk-types';
import type { Opaque } from 'type-fest';

export const REQUESTED_STATUS = {
  ONLINE: 'online',
  OFFLINE: 'offline',
} as const;

export const CURRENT_STATUS = {
  ONLINE: 'online',
  OFFLINE: 'offline',
  STARTING: 'starting',
  STOPPING: 'stopping',
  STORE_COPYING: 'store copying',
  INITIAL: 'initial',
  DEALLOCATING: 'deallocating',
  DIRTY: 'dirty',
  QUARANTINED: 'quarantined',
  UNKNOWN: 'unknown',
} as const;

export const ACCESS = {
  READ_WRITE: 'read-write',
  READ_ONLY: 'read-only',
} as const;

export const TYPE = {
  SYSTEM: 'system',
  STANDARD: 'standard',
  COMPOSITE: 'composite',
} as const;

export const ROLE_V5 = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  UNKNOWN: 'unknown',
} as const;

export const ROLE_V4 = {
  STANDALONE: 'standalone',
  LEADER: 'leader',
  FOLLOWER: 'follower',
} as const;

export type Access = (typeof ACCESS)[keyof typeof ACCESS];
export type Type = (typeof TYPE)[keyof typeof TYPE];
export type RoleV5 = (typeof ROLE_V5)[keyof typeof ROLE_V5];
export type RoleV4 = (typeof ROLE_V4)[keyof typeof ROLE_V4];
export type RequestedStatus = (typeof REQUESTED_STATUS)[keyof typeof REQUESTED_STATUS];
export type CurrentStatus = (typeof CURRENT_STATUS)[keyof typeof CURRENT_STATUS];

export type DatabaseV5 = {
  name: string;
  type: Type;
  aliases: string[];
  access: Access;
  address: string | null;
  role: RoleV5 | null;
  writer: boolean;
  requestedStatus: RequestedStatus;
  currentStatus: CurrentStatus;
  statusMessage: string;
  default: boolean;
  home: boolean;
  constituents: string[];
  defaultLanguage?: CypherVersion;
};

export type DatabaseV4 = {
  name: string;
  aliases: string[];
  access: Access;
  address: string | null;
  role: RoleV4;
  requestedStatus: RequestedStatus;
  currentStatus: CurrentStatus;
  error: string;
  default: boolean;
  home: boolean;
};

/**
 * A single Database type represents both, logical and physical databases instances.
 * TODO: (?) Refactor Database type into two types to separate logical and physical databases,
 * with logical database having a list of physical instances?
 * e.g. `type LogicalDatabase = {name: string, instance s: Database[]}`
 */
export type Database = DatabaseV5 | DatabaseV4;

/**
 * Database type (and database name) is used in two differen t contexts:
 * - logical database
 * - physical database instance
 * They are the same, but we want to treat them differently based on the situation.
 * To distinguish one from another, we use different types for the names referencing them.
 * LogicalDatabaseName is used for logical databases, and DatabaseInstanceId is used for physical instances.
 * Both are strings but have different types to prevent accidently mixing them up in the code.
 * Tagged types are used to achieve this. (ref: https://github.com/sindresorhus/type-fest/blob/main/source/opaque.d.ts)
 */

/**
 * A logical database is uniquely identified by its name.
 * - format: non-empty string without '@'. '@' is reserved for DatabaseInstanceId.
 */
export type LogicalDatabaseName = Opaque<string, 'DatabaseName'>;

/**
 * A database instance is uniquely identified by its name and address.
 * - ID format: `${databaseName}@${serverAddress}`
 */
export type DatabaseInstanceId = Opaque<string, 'DatabaseInstanceId'>;

export function isLogicalDatabaseName(input: unknown): input is LogicalDatabaseName {
  return typeof input === 'string' && !input.includes('@') && input.length > 0;
}

export function isDatabaseInstanceId(input: unknown): input is DatabaseInstanceId {
  if (typeof input !== 'string') {
    return false;
  }

  const parts = input.split('@');
  return (
    parts.length === 2 &&
    typeof parts[0] === 'string' &&
    typeof parts[1] === 'string' &&
    parts[0].length > 0 &&
    parts[1].length > 0
  );
}

export function assertLogicalDatabaseName(input: string): asserts input is LogicalDatabaseName {
  if (!isLogicalDatabaseName(input)) {
    throw Error(`Invalid logical database name: ${input}`);
  }
}

export function assertDatabaseInstanceId(input: string): asserts input is DatabaseInstanceId {
  if (!isDatabaseInstanceId(input)) {
    throw Error(`Invalid database instance name: ${input}`);
  }
}

export function isAccess(input: unknown): input is Access {
  return typeof input === 'string' && Object.values<string>(ACCESS).includes(input);
}

export function isRoleV5(input: unknown): input is RoleV5 {
  return typeof input === 'string' && Object.values<string>(ROLE_V5).includes(input);
}

export function isRoleV4(input: unknown): input is RoleV5 {
  return typeof input === 'string' && Object.values<string>(ROLE_V4).includes(input);
}

export function isType(input: unknown): input is Type {
  return typeof input === 'string' && Object.values<string>(TYPE).includes(input);
}

export function isRequestedStatus(input: unknown): input is RequestedStatus {
  return typeof input === 'string' && Object.values<string>(REQUESTED_STATUS).includes(input);
}

export function isCurrentStatus(input: unknown): input is CurrentStatus {
  return typeof input === 'string' && Object.values<string>(CURRENT_STATUS).includes(input);
}

// TODO: User proper validation library to get error messages.
//       Will be taken care when moving updateDatabases to neo4j-sdk
export function isDatabaseV4(input: unknown): input is DatabaseV4 {
  if (typeof input !== 'object' || input === null) {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const database = input as DatabaseV4;

  return (
    typeof database.name === 'string' &&
    Array.isArray(database.aliases) &&
    database.aliases.every((alias) => typeof alias === 'string') &&
    isAccess(database.access) &&
    (database.address === null || typeof database.address === 'string') &&
    isRoleV4(database.role) &&
    isRequestedStatus(database.requestedStatus) &&
    isCurrentStatus(database.currentStatus) &&
    typeof database.error === 'string' &&
    typeof database.default === 'boolean' &&
    typeof database.home === 'boolean'
  );
}

// TODO: User proper validation library to get error messages
export function isDatabaseV5(input: unknown): input is DatabaseV5 {
  if (typeof input !== 'object' || input === null) {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const database = input as DatabaseV5;

  return (
    typeof database.name === 'string' &&
    isType(database.type) &&
    Array.isArray(database.aliases) &&
    database.aliases.every((alias) => typeof alias === 'string') &&
    isAccess(database.access) &&
    (database.address === null || typeof database.address === 'string') &&
    (database.role === null || isRoleV5(database.role)) &&
    typeof database.writer === 'boolean' &&
    isRequestedStatus(database.requestedStatus) &&
    isCurrentStatus(database.currentStatus) &&
    typeof database.statusMessage === 'string' &&
    typeof database.default === 'boolean' &&
    typeof database.home === 'boolean' &&
    Array.isArray(database.constituents) &&
    database.constituents.every((constituent) => typeof constituent === 'string')
  );
}

export function isDatabase(input: unknown): input is Database {
  return isDatabaseV4(input) || isDatabaseV5(input);
}

export function findComposite(databases: Database[], alias: string): Database | undefined {
  const compositeDatabases = databases.filter((db) => isDatabaseV5(db) && db.type === 'composite');
  return compositeDatabases.find((db) => isDatabaseV5(db) && db.constituents.includes(alias));
}

export function isCompositeAlias(databases: Database[], alias: string): boolean {
  return findComposite(databases, alias) !== undefined;
}
