import type { FailedToParseImportModel, ImportModel, SerialisedImportModel } from '@nx/api';
import { APP_SCOPE } from '@nx/constants';
import type * as ImportShared from '@nx/import-shared';
import { createLogger } from '@nx/logger';
import type { RootState } from '@nx/state';
import { isFetchBaseQueryError, selectAuraConfiguration } from '@nx/state';
import { isNullish } from '@nx/stdlib';

import { APP_VERSION } from '../../constants';
import { dataModelJsonFormatter, isDataModelJsonStruct } from '../../data-model/data-model-json-formatter';
import { emptyDataModel } from '../../data-model/data-model.json.helpers';
import type { AnyDeprecatedDataModel } from '../../data-model/deprecated/types';
import { isLatestDataModelVersion, migrateDataModelToLatestVersion } from '../../data-model/migrations';
import { getFullVisualisationModelFromVisualisationState, hydrateVisualisationState } from '../../visualisation/utils';

const logger = createLogger(APP_SCOPE.import);

export function getImportUrls(state: RootState) {
  let rootEndpoint = selectAuraConfiguration(state)?.importApiUrl;

  if (rootEndpoint === undefined) {
    logger.error('Import API URL not defined');
  }

  rootEndpoint = (rootEndpoint ?? '').replace(/\/$/, '');

  return {
    rootEndpoint,
  };
}

export const sortByTimeDesc = (a: string, b: string) => {
  const aTime = Date.parse(a);
  const bTime = Date.parse(b);
  if (isNaN(aTime) && isNaN(bTime)) {
    return 0;
  } else if (isNaN(aTime)) {
    return -1;
  } else if (isNaN(bTime)) {
    return 1;
  }
  return bTime - aTime;
};

// TODO: should create a /migration folder to consolidate all migration functions.
export const extractImportModelFromSerialisation = (
  serialisedImportModel: SerialisedImportModel,
): ImportModel | FailedToParseImportModel => {
  try {
    const { dataModel: serialisedDataModel, visualisation: serialisedVisualisation } = serialisedImportModel;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const dataModel: AnyDeprecatedDataModel | ImportShared.DataModelJsonStruct = JSON.parse(serialisedDataModel);
    const dataModelVersion = 'version' in dataModel ? dataModel.version : '0.0.1';
    let modelToLoad = emptyDataModel;
    if (isDataModelJsonStruct(dataModel) && isLatestDataModelVersion(dataModelVersion)) {
      modelToLoad = dataModel;
    }
    // Loaded model is an old version, migrate it to the latest version and clean up before loading it
    else {
      modelToLoad = migrateDataModelToLatestVersion(dataModel, dataModelVersion);
    }

    // Verify the data model is valid. If not, this function will throw an error for later catch.
    dataModelJsonFormatter.fromJsonStruct(modelToLoad);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const visualisation: ImportShared.SerialisableVisualisationState = JSON.parse(serialisedVisualisation);

    return {
      ...serialisedImportModel,
      dataModel: modelToLoad,
      visualisation,
      // version is now migrated to latest version
      version: APP_VERSION,
    };
  } catch (e) {
    logger.error('Error import model to load', e);
    return {
      ...serialisedImportModel,
      dataModel: null,
      visualisation: null,
    };
  }
};

// For some reason get runtime error if this function is in  '@nx/api' so putting it here instead
export const isFailedToParseImportModel = (model: unknown): model is FailedToParseImportModel => {
  return (
    typeof model === 'object' &&
    model !== null &&
    'id' in model &&
    'version' in model &&
    'createdAt' in model &&
    'updatedAt' in model &&
    'dataModel' in model &&
    'visualisation' in model &&
    model.dataModel === null &&
    model.visualisation === null
  );
};

export const mapImportModelToImportModelMetadata = (
  importModel: ImportModel | FailedToParseImportModel,
): ImportShared.ImportModelMetadata | FailedToParseImportModel => {
  if (isFailedToParseImportModel(importModel)) {
    return importModel;
  }
  const { dataModel, visualisation } = importModel;

  const hydratedDataModel = dataModelJsonFormatter.fromJsonStruct(dataModel);
  const hydratedVisualisation = hydrateVisualisationState(visualisation, dataModel);

  const fullGraphVisualisationModel = getFullVisualisationModelFromVisualisationState(
    hydratedVisualisation,
    hydratedDataModel,
  );

  return {
    id: importModel.id,
    name: importModel.name ?? 'Untitled model',
    fullGraphVisualisationModel,
  };
};

export const mapFromApiTableSchemaToTableSchemaJsonStruct =
  (isLocalDataSource: boolean) =>
  (tableSchema: ImportShared.ApiTableSchema): ImportShared.TableSchemaJsonStruct => ({
    name: tableSchema.name,
    fields: tableSchema.fields.map((field) => {
      if (isLocalDataSource) {
        const localField: ImportShared.TableSchemaLocalFieldJsonStruct = {
          name: field.name,
          sample: '',
          recommendedType: field.recommendedType ?? { type: 'string' },
        };
        return localField;
      }
      const cloudField: ImportShared.TableSchemaCloudFieldJsonStruct = {
        name: field.name,
        rawType: field.rawType,
        recommendedType: field.recommendedType,
        supportedTypes: field.supportedTypes,
      };
      return cloudField;
    }),
    primaryKeys: tableSchema.primaryKeys,
    foreignKeys: tableSchema.foreignKeys,
    expanded: true,
  });

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const isMeta = (meta: unknown): meta is { response: { status: number } } => {
  if (typeof meta === 'object' && meta !== null && 'response' in meta) {
    const { response } = meta;
    if (typeof response === 'object' && response !== null && 'status' in response) {
      return typeof response.status === 'number';
    }
  }
  return false;
};

export const isSpawnNewSourceSchemaJobResultData = (data: unknown): data is { id: string } => {
  return typeof data === 'object' && data !== null && 'id' in data && typeof data.id === 'string';
};

export const getErrorMessage = (error: unknown, statusCode?: number): string => {
  const UNDEFINED_ERROR_MESSAGE = 'Unknown error';
  const STATUS_CODE = statusCode === undefined ? 'Status code not available' : String(statusCode);
  if (isFetchBaseQueryError(error)) {
    if (typeof error.data === 'object' && error.data !== null && 'message' in error.data) {
      return `[${STATUS_CODE}] ${String(error.data.message)}`;
    }
    return `[${STATUS_CODE}] ${isNullish(error.data) ? UNDEFINED_ERROR_MESSAGE : String(error.data)}`;
  }
  return `[${STATUS_CODE}] ${isNullish(error) ? UNDEFINED_ERROR_MESSAGE : String(error)}`;
};

export const isApiDataSourceSchema = (data: unknown): data is ImportShared.ApiDataSourceSchema => {
  return (
    typeof data === 'object' &&
    data !== null &&
    'type' in data &&
    typeof data.type === 'string' &&
    'tableSchemas' in data &&
    Array.isArray(data.tableSchemas)
  );
};

export const getErrorMessageFromApiHook = (e: unknown): { message: string | undefined; isAbortError: boolean } => {
  let message: string | undefined = typeof e === 'string' ? e : JSON.stringify(e);
  let isAbortError = false;
  // If the error is an Error object, use the message property
  if (typeof e === 'object' && e !== null && 'name' in e && 'message' in e) {
    if (!isNullish(e.name) && typeof e.name === 'string' && e.name === 'AbortError') {
      message = undefined;
      isAbortError = true;
    } else {
      message = typeof e.message === 'string' ? e.message : JSON.stringify(e.message);
    }
  }

  return { message, isAbortError };
};

export const buildDynamicDataSourceParamsPayloadByDataSourceConfig = (
  dataSourceConfig: ImportShared.DataSourceConfig,
): {
  type: string;
  parameters: Record<string, ImportShared.ApiDynamicField>;
} => {
  const { type, fields } = dataSourceConfig;
  const emptyDynamicFields: Record<string, ImportShared.ApiDynamicField> = {};
  const parameters: Record<string, ImportShared.ApiDynamicField> = fields.reduce((acc, field) => {
    acc[field.name] = {
      type: field.type,
      value: field.value,
    };
    return acc;
  }, emptyDynamicFields);

  return { type, parameters };
};
