import { useLayoutEffect, useRef, useState } from 'react';

import { CellExpand } from '../../shared/components/cell-expand';

type MessageCellProps = {
  value?: string | undefined;
  message?: string | undefined;
  width?: number;
};

export const MessageCell = ({ value, message, width }: MessageCellProps) => {
  const [hasEllipsis, setHasEllipsis] = useState(false);
  const messageRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    const messageEl = messageRef.current;
    if (!messageEl) {
      return;
    }

    setHasEllipsis(messageEl.scrollHeight > messageEl.clientHeight);
  }, [message, width]);

  return (
    <div data-testid="query-cell">
      <div className="gap-token-4 flex flex-col">
        <p ref={messageRef} className="line-clamp-3 whitespace-pre-wrap" title={value ?? undefined}>
          {value?.trim()}
        </p>
      </div>
      {hasEllipsis && <CellExpand message={message} header="Message" isCode={false} />}
    </div>
  );
};
