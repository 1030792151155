import { neo4jVersionUtil } from '@nx/neo4j-version-utils';

// When version is not defined we assume a neo4j 5 instance.

export const isNeo4jSupportedUpgradeVersion = (version: string): boolean =>
  neo4jVersionUtil.satisfies(version, '>=4.1.*', {
    includePrerelease: true,
  });

export const isNeo4j4x = (version?: string): boolean => {
  if (version === undefined) {
    return false;
  }

  return neo4jVersionUtil.satisfies(version, '4.*', {
    includePrerelease: true,
  });
};

export const isNeo4jGTE4x = (version?: string): boolean => {
  if (version === undefined) {
    return true;
  }

  return neo4jVersionUtil.satisfies(version, '>=4.*', {
    includePrerelease: true,
  });
};

export const isNeo4jLT4x = (version?: string): boolean => {
  if (version === undefined) {
    return false;
  }
  return neo4jVersionUtil.satisfies(version, '<4.*', {
    includePrerelease: true,
  });
};

export const isNeo4j5x = (version?: string): boolean => {
  if (version === undefined) {
    return true;
  }

  return neo4jVersionUtil.satisfies(version, '5.*', {
    includePrerelease: true,
  });
};

export const isNeo4jLT5x = (version?: string): boolean => {
  if (version === undefined) {
    return false;
  }
  return neo4jVersionUtil.satisfies(version, '<5.*', {
    includePrerelease: true,
  });
};

export const isNeo4jGTE5x = (version?: string): boolean => {
  if (version === undefined) {
    return true;
  }
  return neo4jVersionUtil.satisfies(version, '>=5.*', {
    includePrerelease: true,
  });
};
