import { Select } from '@neo4j-ndl/react';
import type { Instance } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import React from 'react';
import type { SingleValue } from 'react-select';

interface Props {
  regionOptions: { key: string; label: string; value: string; databases: Instance[] }[];
  region: string | undefined;
  setRegion: (region: string) => void;
}

export const RegionDropdown = ({ regionOptions, region, setRegion }: Props) => {
  const formatOptionLabel = (
    { label, value, databases }: { label: string; value: string; databases: Instance[] },
    { context }: { context: string },
  ) => {
    if (context === 'value') {
      return <div>{label}</div>;
    } else if (context === 'menu') {
      return (
        <div data-testid={`region-${value}`}>
          <p>{label}</p>
          <p className="pl-4">{databases.map((db) => db.name).join(', ')}</p>
        </div>
      );
    }
    // We should never get here but needed for eslint
    return <div>unsupported dropdown context</div>;
  };

  const handleRegionChange = (
    option: SingleValue<{ key: string; label: string; value: string; databases: Instance[] }>,
  ) => {
    if (isNotNullish(option)) {
      setRegion(option.value);
    }
  };

  return (
    <Select
      size="medium"
      label="Region"
      type="select"
      selectProps={{
        options: regionOptions,
        value: regionOptions.find((option) => option.value === region),
        onChange: handleRegionChange,
        formatOptionLabel,
      }}
      helpText="The configuration will apply to all future instances' logs in the selected region."
      htmlAttributes={{
        'data-testid': 'log-forwarding-region-select',
      }}
    />
  );
};
