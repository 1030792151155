import { type Instance, LOG_FILE, LOG_STATUS, TIER } from '@nx/state';
import { sub } from 'date-fns';

export const isSecurityLogsEnabled = (instance: Instance): boolean => {
  if (![TIER.AURA_DSE, TIER.ENTERPRISE].includes(instance.tier)) {
    return false;
  }

  return true;
};

export const getStatusColour = (value: LOG_STATUS) => {
  switch (value) {
    case LOG_STATUS.COMPLETED:
      return 'success';
    case LOG_STATUS.IN_PROGRESS:
      return 'unknown';
    case LOG_STATUS.EXPIRING_SOON:
      return 'warning';
    case LOG_STATUS.FAILED:
      return 'danger';
    default:
      return 'unknown';
  }
};

export const getStatusValue = (value: LOG_STATUS) => {
  switch (value) {
    case LOG_STATUS.COMPLETED:
      return 'Completed';
    case LOG_STATUS.IN_PROGRESS:
      return 'In progress';
    case LOG_STATUS.EXPIRING_SOON:
      return 'Expiring soon';
    case LOG_STATUS.FAILED:
      return 'Failed';
    default:
      return 'Unknown';
  }
};

// Request Modal Helpers

export enum REQUEST_LOGS_TIME_SELECTION {
  LAST_15 = 'last15',
  LAST_HOUR = 'lastHour',
  LAST_6_HOURS = 'last6Hours',
  LAST_12_HOURS = 'last12Hours',
}

export const settingsForFile = {
  [LOG_FILE.QUERY]: {
    sliderDefault: 5,
    sliderMin: 1,
    sliderMax: 60,
    sliderStep: 1,
    timeSelectionOptions: [
      { label: 'Last 15 min', value: REQUEST_LOGS_TIME_SELECTION.LAST_15 },
      { label: 'Last hour', value: REQUEST_LOGS_TIME_SELECTION.LAST_HOUR },
    ],
  },
  [LOG_FILE.SECURITY]: {
    sliderDefault: 60,
    sliderMin: 5,
    sliderMax: 60 * 12,
    sliderStep: 5,
    timeSelectionOptions: [
      { label: 'Last 6 hours', value: REQUEST_LOGS_TIME_SELECTION.LAST_6_HOURS },
      { label: 'Last 12 hours', value: REQUEST_LOGS_TIME_SELECTION.LAST_12_HOURS },
    ],
  },
};

export const determineInterval = (timeSelection: REQUEST_LOGS_TIME_SELECTION | null) => {
  if (timeSelection === null) {
    return [null, null];
  }
  const now = new Date();

  const intervalMapping = {
    [REQUEST_LOGS_TIME_SELECTION.LAST_15]: [sub(now, { minutes: 15 }), now],
    [REQUEST_LOGS_TIME_SELECTION.LAST_HOUR]: [sub(now, { hours: 1 }), now],
    [REQUEST_LOGS_TIME_SELECTION.LAST_6_HOURS]: [sub(now, { hours: 6 }), now],
    [REQUEST_LOGS_TIME_SELECTION.LAST_12_HOURS]: [sub(now, { hours: 12 }), now],
  };

  return intervalMapping[timeSelection];
};
