import { isNotNullish } from '@nx/stdlib';

import type {
  TransformedMarketingOptInBody,
  TransformedUpdateTermsAndConditionsBody,
  UpdateMarketingOptInRequest,
  UpdateTermsAndConditionsRequest,
  UserDetailsResponse,
} from '../console.api.types';
import { type User } from '../console.types';

export const transformUserDetailsResponse = (response: UserDetailsResponse): User => ({
  id: response.user_id,
  planType: response.plan_type,
  defaultProject: response.default_namespace,
  permissions: response.permissions,
  acceptedTermsAndConditions: response.accepted_terms_and_conditions,
  marketingChoiceRequired: response.marketing_choice_required,
  auraApiEnabled: response.aura_api_enabled,
  email: response.email,
  onboardingExperience: response.onboarding_experience,
});

export const transformUpdateTermsAndConditionsRequest = (
  request: UpdateTermsAndConditionsRequest,
): TransformedUpdateTermsAndConditionsBody => {
  const transformedBody: TransformedUpdateTermsAndConditionsBody = {
    AcceptedTermsAndConditions: request.acceptedTermsAndConditions,
  };

  if (isNotNullish(request.marketingPreference)) {
    transformedBody.MarketingPreference = request.marketingPreference;
  }

  return transformedBody;
};

export const transformMarketingOptInRequest = (
  request: UpdateMarketingOptInRequest,
): TransformedMarketingOptInBody => ({
  MarketingPreference: request.marketingPreference,
});
