import '@neo4j-ndl/base/lib/neo4j-ds-styles.css';
import type { Logger } from '@nx/logger';
import type { AppManifest, Capability, selectConfiguration } from '@nx/state';
import {
  Analytics,
  ErrorTracking,
  fetchLocalDiscoveryData,
  getBaseUrl,
  initializeSsoRedirectUri,
  setupInitialDbConnection,
  startAppUpdateCheck,
  LEGACY_store as store,
  storeReady,
  updateAppManifest,
} from '@nx/state';
import * as Sentry from '@sentry/react';
import * as React from 'react';
import * as ReactDOMClient from 'react-dom/client';

import { FrameworkProvider } from './framework-provider';
import { setupCapabilities } from './setup/setup-capabilities';
import { setupFeatureFlags } from './setup/setup-feature-flags';
import { setupGuidePlaylist } from './setup/setup-guide-playlist';
import { setupNtId, setupRelateTokens } from './setup/setup-search-params';
import type { AuthConfig } from './setup/setup-session';
import { setupAppLogin } from './setup/setup-session';

const enableMocking = async (
  logger: Logger,
  planType: 'enterprise' | 'self-serve' = 'self-serve',
  isFirstTimeUser = false,
) => {
  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  logger.info('Starting the mocking worker');
  const { loadWorker } = await import('@nx/api-mocks');
  const worker = await loadWorker(planType, isFirstTimeUser);

  return worker?.start({
    onUnhandledRequest: 'bypass',
  });
};

export type FrameworkInitOptions = {
  appManifest?: AppManifest;
  auth?: AuthConfig;
  capabilities: (Capability | false)[];
  configuration: ReturnType<typeof selectConfiguration>['internal'] & {
    guidesBaseUrl?: string;
    ssoRedirectPath: string;
  };
  // If true, the app will check for new versions by periodicaly fetching 'manifest.json' and notify the user
  enableAppUpdateCheck?: boolean;
  logger: Logger;
  preRender?: (() => Promise<void>) | (() => void);
  skipReconnect?: boolean;
};

export async function frameworkInit(
  app: React.ReactNode,
  {
    appManifest = {},
    auth,
    capabilities,
    configuration,
    enableAppUpdateCheck = false,
    logger,
    preRender,
    skipReconnect = false,
  }: FrameworkInitOptions,
) {
  try {
    await storeReady();

    updateAppManifest(appManifest);

    if (appManifest.version !== undefined && enableAppUpdateCheck) {
      void startAppUpdateCheck();
    }

    // Some of the setup functions below are dispatching Redux actions, so they
    // need to wait until the persistance wrapper of the store is setup or any
    // changes made by them will be reverted once the store is hydrated.
    setupCapabilities(configuration, capabilities);

    if (capabilities.includes('framework:mock-api')) {
      const isEnterprise = capabilities.includes('framework:mock-enterprise');
      const isFirstTimeUser = capabilities.includes('framework:mock-first-time-user');

      await enableMocking(logger, isEnterprise ? 'enterprise' : 'self-serve', isFirstTimeUser).catch((e) =>
        logger.warn('Could not enable API mocking', e),
      );
    }

    // Persist SSO redirect URI
    const baseUrl = location.origin + getBaseUrl();
    const redirectUrl = new URL(configuration.ssoRedirectPath.replace(/^\//, ''), baseUrl);
    initializeSsoRedirectUri(redirectUrl.toString());

    try {
      setupNtId();
      // Analytics and Error tracking are depending on network policies
      // and setup could be deferred until network policies are ready.
      void store.dispatch(Analytics.setupIfAllowed());
      ErrorTracking.setupIfAllowed();
    } catch (e) {
      logger.warn('Could not initialize analytics', e);
    }

    setupFeatureFlags();
    setupRelateTokens();
    setupGuidePlaylist(configuration.guidesBaseUrl);

    await setupAppLogin(logger, auth);

    if (!skipReconnect) {
      // The DB connection must run after the app login, as some of the connection
      // methods require the user to be logged in.
      void store
        .dispatch(setupInitialDbConnection())
        .unwrap()
        .catch((e) => {
          logger.warn('Could not setup initial DB connection', e);
          Sentry.captureException(e);
        });

      // Check if there is a connection defined on discovery.json
      void store
        .dispatch(fetchLocalDiscoveryData())
        .unwrap()
        .catch(() => logger.info('Local discovery configuration not found'));
    }

    if (preRender !== undefined) {
      await preRender();
    }
  } catch (e) {
    logger.error('App initialization error', e);
  } finally {
    const rootElement = document.getElementById('root');
    const reactTree = (
      <React.StrictMode>
        <FrameworkProvider logger={logger}>{app}</FrameworkProvider>
      </React.StrictMode>
    );

    if (rootElement !== null) {
      const root = ReactDOMClient.createRoot(rootElement);
      root.render(reactTree);
    }
  }
}
