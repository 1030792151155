import { isNullish } from '@nx/stdlib';

import type { DataModelJsonStruct_2_2_0, DataSourceLocation } from './data-model.json.type';

export const isDataSourceLocationType = (type: unknown): type is DataSourceLocation => {
  const dataSourceLocationTypes: DataSourceLocation[] = [null, 'cloud', 'local'];
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return dataSourceLocationTypes.includes(type as DataSourceLocation);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isDataModelJsonStruct_2_2_0 = (dataModel: any): dataModel is DataModelJsonStruct_2_2_0 => {
  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  return (
    !isNullish(dataModel) &&
    !isNullish(dataModel.version) &&
    !isNullish(dataModel.graphSchemaRepresentation) &&
    !isNullish(dataModel.graphSchemaExtensionsRepresentation) &&
    !isNullish(dataModel.graphSchemaExtensionsRepresentation.nodeKeyProperties) &&
    !isNullish(dataModel.graphMappingRepresentation) &&
    !isNullish(dataModel.graphMappingRepresentation.nodeMappings) &&
    !isNullish(dataModel.graphMappingRepresentation.relationshipMappings) &&
    !isNullish(dataModel.graphMappingRepresentation.dataSourceSchema) &&
    isDataSourceLocationType(dataModel.graphMappingRepresentation.dataSourceSchema.type) &&
    !isNullish(dataModel.configurations) &&
    !isNullish(dataModel.configurations.idsToIgnore)
  );
  /* eslint-enable @typescript-eslint/no-unsafe-member-access */
};
