import { customCreateApi } from '../../context';
import { createDynamicBaseQuery } from '../../utils/create-dynamic-base-query';
import { selectAuraConfiguration } from '../configuration/configuration-slice';
import { getAccessToken } from '../session-slice';
// eslint-disable-next-line import/no-cycle
import { sessionEndpoints } from './endpoints/session';
import type { Builder } from './endpoints/types';
import { tagTypes } from './endpoints/types';

const dataScienceApi = customCreateApi({
  baseQuery: createDynamicBaseQuery((state) => selectAuraConfiguration(state)?.dataScienceApiUrl, {
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('Authorization', `Bearer ${accessToken}`);
    },
  }),
  reducerPath: 'dataScienceApi',
  tagTypes,
  endpoints: (builder: Builder) => ({ ...sessionEndpoints(builder) }),
});

export default dataScienceApi;
