import { Objects } from '@nx/stdlib';

import type { GraphStyling, NodeStyling, RelationStyling } from './graph-styling-slice';

function convertCommonProperties(old: Record<string, string>): Partial<NodeStyling | RelationStyling> {
  const result: Partial<NodeStyling | RelationStyling> = {};

  if (typeof old.color === 'string') {
    result.color = old.color;
  }

  if (typeof old.caption === 'string') {
    if (old.caption === '<id>') {
      result.captions = [{ type: 'id' }];
    } else if (old.caption === '<type>') {
      result.captions = [{ type: 'type' }];
    } else if (old.caption.startsWith('{') && old.caption.endsWith('}')) {
      const captionKey = old.caption.substring(1, old.caption.length - 1);
      result.captions = [{ type: 'property', captionKey }];
    }
  }

  return result;
}

function convertOldNodePropertiesToNew(old: Record<string, string>): Partial<NodeStyling> {
  const result: Partial<NodeStyling> = { ...convertCommonProperties(old) };
  if (typeof old.diameter === 'string') {
    const parsedDiameter = parseInt(old.diameter, 10);
    if (!isNaN(parsedDiameter)) {
      result.size = Math.floor(parsedDiameter / 2 / 0.93);
    }
  }

  // also parse & prefer new wording "size"
  if (typeof old.size === 'string') {
    const size = parseInt(old.size, 10);
    if (!isNaN(size)) {
      result.size = size;
    }
  }

  return result;
}

function convertOldRelPropertiesToNew(old: Record<string, string>): Partial<RelationStyling> {
  const result: Partial<RelationStyling> = { ...convertCommonProperties(old) };

  if (typeof old['shaft-width'] === 'string') {
    const parsedWidth = parseInt(old['shaft-width'], 10);
    if (!isNaN(parsedWidth)) {
      result.width = parsedWidth;
    }
  }

  // also parse & prefer new wording "width"
  if (typeof old.width === 'string') {
    const width = parseInt(old.width, 10);
    if (!isNaN(width)) {
      result.width = width;
    }
  }

  return result;
}

function parseGrassCSS(string: string) {
  const chars = string.split('');
  let insideString = false;
  let insideProps = false;
  let insideBinding = false;
  let keyword = '';
  let props = '';
  const rules: Record<string, string> = {};
  let i;
  let j;

  for (i = 0; i < chars.length; i++) {
    const c = chars[i];
    let skipThis = true;
    switch (c) {
      case '{':
        if (insideString) {
          skipThis = false;
        } else if (insideProps) {
          insideBinding = true;
        } else {
          insideProps = true;
        }
        break;
      case '}':
        if (insideString) {
          skipThis = false;
        } else if (insideBinding) {
          insideBinding = false;
        } else {
          insideProps = false;
          rules[keyword] = props;
          keyword = '';
          props = '';
        }
        break;
      case "'":
      case '"':
        insideString = !insideString;
        break;
      default:
        skipThis = false;
        break;
    }

    if (skipThis) {
      continue;
    }

    if (insideProps) {
      props += c;
    } else if (!c?.match(/[\s\n]/)) {
      keyword += c;
    }
  }

  const letParsedRules: Record<string, Record<string, string>> = {};
  const keys = Object.keys(rules);
  for (i = 0; i < keys.length; i++) {
    const val = rules[keys[i]!];
    letParsedRules[keys[i]!] = {};
    const properties = val?.split(';');

    for (j = 0; j < (properties?.length ?? 0); j++) {
      const propKeyVal = properties![j]?.split(':');
      if (propKeyVal && propKeyVal.length === 2) {
        const prop = propKeyVal[0]!.trim();
        const value = propKeyVal[1]!.trim();
        letParsedRules[keys[i]!]![prop] = value;
      }
    }
  }

  return letParsedRules;
}

function parseGrass(string: string) {
  try {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return JSON.parse(string) as Record<string, Record<string, string>>;
  } catch (e) {
    return parseGrassCSS(string);
  }
}

export function parseBrowserGrass(string: string): GraphStyling {
  const parsed = parseGrass(string);
  if (typeof parsed !== 'object') {
    return { node: {}, relationship: {}, stylingPrecedence: [] };
  }
  return Object.entries(parsed).reduce<GraphStyling>(
    (acc, [key, value]) => {
      const [entity, type] = key.trim().split('.');
      const cleanType = type?.split(/\s/)[0];

      if (cleanType === undefined || cleanType === '' || cleanType === '*') {
        return acc;
      }

      if (typeof value !== 'object') {
        return acc;
      }

      if (entity === 'node') {
        const styleProperties = convertOldNodePropertiesToNew(value);
        if (Objects.isEmpty(styleProperties)) {
          return acc;
        }

        return {
          ...acc,
          node: {
            ...acc.node,
            [cleanType]: convertOldNodePropertiesToNew(value),
          },
        };
      }

      if (entity === 'relationship') {
        const styleProperties = convertOldRelPropertiesToNew(value);
        if (Objects.isEmpty(styleProperties)) {
          return acc;
        }

        return {
          ...acc,
          relationship: {
            ...acc.relationship,
            [cleanType]: styleProperties,
          },
        };
      }
      return acc;
    },
    { node: {}, relationship: {}, stylingPrecedence: [] },
  );
}
