import { DataGrid, DataGridComponents, IconButton, Label, Typography } from '@neo4j-ndl/react';
import { FunnelIconOutline } from '@neo4j-ndl/react/icons';
import { DataGridHelpers, SearchField } from '@nx/ui';
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import classes from './roles.module.css';

interface Role {
  id: string;
  name: string;
  description: string;
  users: number;
  type: string;
}

const MOCK_ROLES: Role[] = [
  {
    id: 'project-admin',
    name: 'PROJECT_ADMIN',
    description: 'Administrator of a project',
    users: 1,
    type: 'project',
  },
  {
    id: 'project-viewer',
    name: 'PROJECT_VIEWER',
    description: 'Can view project',
    users: 12,
    type: 'project',
  },
  {
    id: 'aml-write',
    name: 'AML_Write_Access',
    description: 'Can modify project',
    users: 5,
    type: 'project',
  },
];

const columnHelper = createColumnHelper<Role>();

const columns = [
  columnHelper.accessor('name', {
    header: 'Name',
    cell: ({ getValue, row }) => (
      <Link to={`/projects/proj23/roles/${row.original.id}/resources`}>
        <Typography variant="body-medium" className="text-primary-text truncate">
          <Label color="default" fill="semi-filled">
            {getValue()}
          </Label>
        </Typography>
      </Link>
    ),
    enableResizing: false,
  }),
  columnHelper.accessor('description', {
    header: 'Description',
  }),
  columnHelper.accessor('users', {
    header: 'Users',
    cell: ({ getValue }) => {
      const users = getValue();
      const plural = users === 1 ? 'user' : 'users';
      return `${users} ${plural}`;
    },
  }),
  columnHelper.accessor('type', {
    header: 'Type',
  }),
  columnHelper.display({
    id: 'actions',
    cell: (cell) => (
      <DataGridComponents.RowActionCell
        cell={cell}
        innerMenuProps={{
          children: (
            <>
              <div className="ndl-menu-item" role="menuitem" tabIndex={-1}>
                <Link to={`/projects/proj23/roles/${cell.row.original.id}/resources`}>Inspect permissions</Link>
              </div>
              <div className="ndl-menu-item" role="menuitem" tabIndex={-1}>
                View users
              </div>
            </>
          ),
        }}
      />
    ),
    meta: {
      // Action's onClick don't have access to the cell to get role id.
      // Using innerMenuProps instead to create menu items manually
      isActionCell: { actions: [] },
    },
  }),
];

const filterRoles = (roles: Role[], filter: string) => {
  const normalizedFilter = filter.trim().toLocaleLowerCase();
  const filteredRoles = normalizedFilter
    ? roles.filter(({ name }) => name.toLocaleLowerCase().includes(normalizedFilter))
    : roles;

  return filteredRoles;
};

export function Roles() {
  const [filter, setFilter] = useState('');

  const roles = MOCK_ROLES;

  const filteredRoles = filterRoles(roles, filter);

  const table = useReactTable({
    columns,
    data: filteredRoles,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: { pageSize: filteredRoles.length },
    },
    columnResizeMode: 'onChange',
  });

  return (
    <div className={`h-full overflow-auto p-4 ${classes['grid-table']}`}>
      <Typography variant="h2" className="mb-6 pl-2 pt-2">
        Roles
      </Typography>
      <DataGridHelpers.Wrapper>
        <DataGridHelpers.OuterHeader>
          <div className="flex basis-[400px] gap-2">
            <SearchField
              className="min-w-36 grow"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              htmlAttributes={{ 'aria-label': 'Filter roles' }}
            />
            <IconButton ariaLabel="Filter roles">
              <FunnelIconOutline />
            </IconButton>
          </div>
        </DataGridHelpers.OuterHeader>
        <DataGrid
          isResizable={true}
          tableInstance={table}
          styling={{ headerStyle: 'clean' }}
          components={{
            Navigation: null,
          }}
          isKeyboardNavigable={false}
        />
      </DataGridHelpers.Wrapper>
    </div>
  );
}
