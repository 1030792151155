import { merge } from 'lodash-es';
import uPlot from 'uplot';

const Y_AXIS_DEFAULT_SIZE = 50;

// based on https://github.com/leeoniya/uPlot/blob/master/demos/axis-autosize.html#L110
export const axisAutoSize: uPlot.Axis.Size = (self, values, axisIdx, cycleNum) => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const axis = self.axes[axisIdx] as uPlot.Axis & { _size: number };

  // eslint-disable-next-line no-underscore-dangle
  if (!axis._size) {
    return Y_AXIS_DEFAULT_SIZE;
  }

  // bail out, force convergence
  if (cycleNum > 1) {
    // eslint-disable-next-line no-underscore-dangle
    return axis._size;
  }

  let axisSize = (axis.ticks?.size ?? 0) + (axis.gap ?? 0);

  // find longest value
  const longestVal = values.reduce((acc, val) => (val.length > acc.length ? val : acc), '');

  if (longestVal !== '') {
    if (axis.font?.[0] !== undefined) {
      // eslint-disable-next-line no-param-reassign
      self.ctx.font = axis.font[0];
    }
    axisSize += self.ctx.measureText(longestVal).width / devicePixelRatio;
  }

  axisSize = Math.max(Math.ceil(axisSize), Y_AXIS_DEFAULT_SIZE);

  return axisSize;
};

/**
 *  Minimum time between two points to form a Gap is MAX_INTERVAL * 1.5 where MAX_INTERVAL is:
 * 1. for regular metrics: 15 minutes, largest `mustReportInterval` (ignoring cpu.count which is a static metric)
 * https://github.com/neo-technology/neo4j-ops-manager-backend/blob/main/server/app/src/main/resources/neo4j/migrations/V071__StoreSizeMetricsConfig.cypher#L49
 *
 * 2. for aggregated metrics: 1 hour, `hourlyCronExpr`
 * https://github.com/neo-technology/neo4j-ops-manager-backend/blob/main/server/app/src/main/java/com/neo4j/opsmanager/server/core/MetricsAggregatingService.java#LL40C66-L40C76
 */
enum MAX_CONNECTED_GAP_DURATION {
  Normal = 1_000 * 60 * 15 * 1.5,
  Aggregated = 1_000 * 60 * 60 * 1.5,
}

/** Generates gaps if duration between two consecutive y values (ignoring in between nulls)
 *  is more than MaxConnectedGapDuration */
const isNum = Number.isFinite;

export const lineGapper: uPlot.Series.GapsRefiner = (u, seriesIdx) => {
  const series = u.series[seriesIdx];

  // eslint-disable-next-line prefer-destructuring
  const xs = u.data[0];
  const ys = u.data[seriesIdx];

  const valueXs = xs.filter((_, i) => isNum(ys ? ys[i] : NaN));

  const gaps: uPlot.Series.Gaps = [];

  const maxDistance = (valueXs[valueXs.length - 1] ?? 0) - (valueXs[0] ?? 0);
  const maxConnectedDurationRelative = maxDistance / 30;

  for (let i = 1, len = valueXs.length; i < len; i += 1) {
    const x = valueXs[i];
    const prevX = valueXs[i - 1];

    const isAggregated = Boolean(series?.aggregationMeta?.[x ?? 0 / 1000]);
    const maxConnectedDurationAbsolute = isAggregated
      ? MAX_CONNECTED_GAP_DURATION.Aggregated
      : MAX_CONNECTED_GAP_DURATION.Normal;

    const gap = (x ?? 0) - (prevX ?? 0);
    if (gap > Math.max(maxConnectedDurationRelative, maxConnectedDurationAbsolute)) {
      uPlot.addGap(gaps, Math.round(u.valToPos(prevX ?? 0, 'x', true)), Math.round(u.valToPos(x ?? 0, 'x', true)));
    }
  }

  gaps.sort((a, b) => a[0] - b[0]);

  return gaps;
};

export const combineOptions = (...options: (Partial<uPlot.Options> | undefined)[]) =>
  merge<uPlot.Options, Partial<uPlot.Options> | undefined>(
    {
      width: 0,
      height: 0,
      series: [],
    },
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    ...(options as [Partial<uPlot.Options> | undefined]),
  );

/**
 * Extracts from `options` the configured `axisId` axis values formatter, adjusted to format a single value.
 * Assumes the configured formatter is a pure function, not relying on the uPlot instance state.
 * */
export const extractPureValueFormatter = (
  options: Partial<uPlot.Options> | undefined,
  // y axis
  axisIdx = 1,
) => {
  const formatter = options?.axes?.[axisIdx]?.values;
  const emptyOptions: uPlot.Options = {
    width: 0,
    height: 0,
    series: [],
  };
  const unusedPlot: uPlot = new uPlot(emptyOptions);
  const unusedArg = 0;
  return typeof formatter === 'function'
    ? (v: number) => formatter(unusedPlot, [v], unusedArg, unusedArg, unusedArg)[0]
    : null;
};
