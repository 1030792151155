import { type ProjectNotification } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import type { NavigateFunction } from 'react-router-dom';

export type ActionCallback = {
  onOpenExtendTrial?: () => void;
  onOpenCreateTrial?: () => void;
};

const getActionById = (projectId: string, actionId: string, callback: ActionCallback, navigate: NavigateFunction) => {
  switch (actionId) {
    case 'extend-pro-trial':
      return () => {
        if (isNotNullish(callback.onOpenExtendTrial)) {
          callback.onOpenExtendTrial();
        }
      };
    case 'enter-billing-details':
      return () => {
        navigate(`/projects/${projectId}/billing`);
      };
    case 'create-pro-trial-db':
      return () => {
        if (isNotNullish(callback.onOpenCreateTrial)) {
          navigate(`/projects/${projectId}/instances`);
          callback.onOpenCreateTrial();
        }
      };
    default:
      // eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
      return () => {};
  }
};

export const getActionsForNotification = (
  projectNotification: ProjectNotification,
  projectId: string,
  callback: ActionCallback,
  navigate: NavigateFunction,
): { label: string; onClick: () => void }[] => {
  if (isNullish(projectNotification)) {
    // eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
    return [];
  }

  return projectNotification.actions.map(({ label, actionId }) => ({
    label,
    onClick: getActionById(projectId, actionId, callback, navigate),
  }));
};
