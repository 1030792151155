import { Banner, Button, Checkbox, Dialog } from '@neo4j-ndl/react';
import { AURA_CONSOLE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { TIER, consoleApi, getApiError, getErrorMessage, useActiveProject } from '@nx/state';
import type { Instance, Project } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useMemo, useState } from 'react';

import { useTrackUpxEvent } from '../../services/segment/analytics';
import { formatSizeString, getCostHourString, getCostMonthString } from '../entities/helpers';

const logger = createLogger(APP_SCOPE.framework);

interface ContentProps {
  instance: Instance;
  project: Project;
}

const ModalTextContent = ({ instance, project }: ContentProps) => {
  const config = project.tierConfigs[instance.tier];
  const size = config?.sizes.find((s) => s.memory === instance.desiredSettings.memory);
  const memory = formatSizeString(instance.desiredSettings.memory);
  const costPerHour = isNotNullish(size) ? getCostHourString(size.costPerHour) : null;
  const costPerMonth = isNotNullish(size) ? getCostMonthString(size.costPerHour) : null;

  if ([TIER.MTE, TIER.PROFESSIONAL].includes(instance.tier)) {
    return (
      <>
        You will be charged {costPerHour} per hour while an instance of this size is running, and continuously running
        this database for 1 month will cost {costPerMonth} based on a 730 hour average month.
      </>
    );
  }

  if (instance.tier === TIER.GDS) {
    return (
      <>
        You will be charged {costPerHour} per hour while your {memory} instance is running.
      </>
    );
  }

  if ([TIER.ENTERPRISE, TIER.AURA_DSE].includes(instance.tier)) {
    return (
      <>
        <p>Resuming this instance will consume credits at the normal running rate.</p>
        <p>Please refer to your contract for more information.</p>
      </>
    );
  }

  return null;
};

interface Props {
  onClose: () => void;
  onConfirm: () => void;
  instance: Instance;
}

export const ResumeInstanceModal = ({ onClose, onConfirm, instance }: Props) => {
  const trackEvent = useTrackUpxEvent();
  const activeProject = useActiveProject();
  const [resume, resumeRes] = consoleApi.useResumeInstanceMutation();

  const errorMessage = useMemo(() => {
    if (!resumeRes.isError) {
      return null;
    }
    const error = getApiError(resumeRes.error);
    const message = getErrorMessage(error);
    return message;
  }, [resumeRes.error, resumeRes.isError]);

  const [userConfirmed, setUserConfirmed] = useState(false);
  const handleUserConfirm = (value: boolean) => {
    setUserConfirmed(value);
  };

  const handleResume = () => {
    resume(instance.id)
      .unwrap()
      .then(() => {
        onConfirm();
      })
      .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(e);
        if (isNotNullish(error.message)) {
          logger.error(error.message);
        }
      });

    trackEvent({
      event: AURA_CONSOLE_EVENTS.INSTANCE_RESUME,
      properties: { tier: instance.tier },
      scope: APP_SCOPE.aura,
    });
  };

  const handleClose = () => {
    onClose();
    setUserConfirmed(false);
  };

  return (
    <Dialog size="large" isOpen onClose={handleClose}>
      <Dialog.Header>Are you sure you want to resume {instance.name}?</Dialog.Header>
      <Dialog.Content
        htmlAttributes={{
          'data-testid': 'resume-db-modal-content',
        }}
      >
        <div className="flex flex-col gap-4">
          {instance.tier !== TIER.FREE && <ModalTextContent instance={instance} project={activeProject} />}

          {isNotNullish(errorMessage) && (
            <Banner
              type="danger"
              description={errorMessage}
              usage="inline"
              htmlAttributes={{
                'data-testid': 'modal-error-message',
              }}
            />
          )}
        </div>
      </Dialog.Content>
      <Dialog.Actions className="item-center flex justify-between">
        <Checkbox onChange={(e) => handleUserConfirm(e.target.checked)} isChecked={userConfirmed} label="I accept" />
        <div className="flex gap-4">
          <Button
            onClick={handleClose}
            isDisabled={resumeRes.isLoading}
            color="neutral"
            fill="outlined"
            htmlAttributes={{
              'data-testid': 'resume-close-button',
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleResume} isLoading={resumeRes.isLoading} isDisabled={!userConfirmed}>
            Resume
          </Button>
        </div>
      </Dialog.Actions>
    </Dialog>
  );
};
