import { Banner, Checkbox, Radio, Select, TextInput, Typography } from '@neo4j-ndl/react';
import type { LinkedProject } from '@nx/state';
import { IDP_TYPE } from '@nx/state';
import type { ChangeEvent } from 'react';
import { useState } from 'react';

import type { Validation } from '../../utils/validation';
import type { SsoConfigFormData } from './entities';
import { SSO_CONFIG_FORM_ACTIONS } from './entities';
import { ProjectMultiSelect } from './project-multi-select';

export interface SsoConfigFormFieldsProps {
  config: SsoConfigFormData;
  onChange: (data: Partial<SsoConfigFormData>) => void;
  validation: Validation<SsoConfigFormData> | null;
  formAction: SSO_CONFIG_FORM_ACTIONS;
  organizationId: string;
  isSSOAllowed: boolean;
}

const SsoConfigFormFields = ({
  config,
  onChange,
  validation,
  formAction,
  organizationId,
  isSSOAllowed,
}: SsoConfigFormFieldsProps) => {
  const isEdit = formAction === SSO_CONFIG_FORM_ACTIONS.UPDATE;
  const [isManualConfiguration, setIsManualConfiguration] = useState(config.manualUriConfiguration);

  const handleDisplayNameChange = ({ target: { value: displayName } }: ChangeEvent<HTMLInputElement>) => {
    onChange({ displayName });
  };

  const handleIdpTypeChange = (idpType?: IDP_TYPE) => {
    onChange({ idpType });
  };

  const handleClientSecretChange = ({ target: { value: clientSecret } }: ChangeEvent<HTMLInputElement>) => {
    onChange({ clientSecret });
  };

  const handleRoleMappingChange = ({ target: { value: roleMapping } }: ChangeEvent<HTMLInputElement>) => {
    onChange({ roleMapping });
  };

  const handleDiscoveryURIChange = ({ target: { value: discoveryURI } }: ChangeEvent<HTMLInputElement>) => {
    onChange({ discoveryURI });
  };

  const handleIssuerChange = ({ target: { value: issuer } }: ChangeEvent<HTMLInputElement>) => {
    onChange({ issuer });
  };

  const handleAuthorizationEndpointChange = ({
    target: { value: authorizationEndpoint },
  }: ChangeEvent<HTMLInputElement>) => {
    onChange({ authorizationEndpoint });
  };

  const handleTokenEndpointChange = ({ target: { value: tokenEndpoint } }: ChangeEvent<HTMLInputElement>) => {
    onChange({ tokenEndpoint });
  };

  const handleJwksURIChange = ({ target: { value: jwksURI } }: ChangeEvent<HTMLInputElement>) => {
    onChange({ jwksURI });
  };

  const handleClientIDChange = ({ target: { value: clientId } }: ChangeEvent<HTMLInputElement>) => {
    onChange({ clientId });
  };

  const handleOrganizationLoginMethodChange = ({
    target: { checked: organizationLoginMethod },
  }: ChangeEvent<HTMLInputElement>) => {
    onChange({ organizationLoginMethod });
  };

  const handleProjectLoginMethodChange = ({
    target: { checked: projectLoginMethod },
  }: ChangeEvent<HTMLInputElement>) => {
    onChange({ projectLoginMethod });
  };

  const handleLinkedProjectsChange = (projects: LinkedProject[]) => {
    onChange({ linkedProjects: projects });
  };

  const idpOptions = [
    {
      label: 'Okta',
      key: IDP_TYPE.OKTA,
      value: IDP_TYPE.OKTA,
    },
    {
      label: 'Microsoft Entra ID',
      key: IDP_TYPE.AAD,
      value: IDP_TYPE.AAD,
    },
  ];

  return (
    <div className="mt-4 flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <Checkbox
          label="Use as login method for the Organization"
          isChecked={config.organizationLoginMethod}
          onChange={handleOrganizationLoginMethodChange}
          htmlAttributes={{ 'data-testid': 'sso-organization-login-method-check' }}
        />
        <Checkbox
          label="Use as login method for instances within Projects in this Organization"
          isChecked={config.projectLoginMethod}
          onChange={handleProjectLoginMethodChange}
          htmlAttributes={{ 'data-testid': 'sso-project-login-method-check' }}
        />
        {config.projectLoginMethod === true && (
          <div className="mt-2 flex flex-col gap-4">
            <Banner
              hasIcon
              type="warning"
              description="Adding or deleting sso configs will only be applied to new instances in the project. Existing instances will not be affected."
            />
            {isSSOAllowed && (
              <Banner
                hasIcon
                type="warning"
                description="Instance SSO is only available for Business Critical instances. Professional and Free instances within your selected projects will not have SSO configured on them."
              />
            )}
          </div>
        )}
      </div>
      {config.projectLoginMethod === true && (
        <div>
          <ProjectMultiSelect
            linkedProjects={config.linkedProjects.map((t: LinkedProject) => t.id)}
            organizationId={organizationId}
            setLinkedProjects={handleLinkedProjectsChange}
            errorText={validation?.projectLoginMethod?.message}
          />
        </div>
      )}

      <TextInput
        isFluid
        label="Display Name"
        htmlAttributes={{ 'data-testid': 'sso-config-display-name-input', 'aria-label': 'Display name' }}
        value={config.displayName}
        onChange={handleDisplayNameChange}
        errorText={validation?.displayName?.message}
        helpText="Users will see this name when they try to login to Aura Console or an instance via SSO."
      />

      <Select
        selectProps={{
          isSearchable: false,
          value: idpOptions.find((idp) => idp.value === config.idpType),
          options: idpOptions,
          onChange: (value) => {
            handleIdpTypeChange(value?.value);
          },
        }}
        size="medium"
        type="select"
        label="Identity Provider (IdP)"
      />

      <TextInput
        isFluid
        label="Client ID"
        htmlAttributes={{ 'data-testid': 'sso-config-client-id-input', 'aria-label': 'SSO config client id' }}
        value={config.clientId}
        onChange={handleClientIDChange}
        errorText={validation?.clientId?.message}
        helpText="Provided by your IdP."
      />

      <TextInput
        isFluid
        label="Client Secret"
        placeholder={isEdit ? 'Replace current client secret' : undefined}
        htmlAttributes={{ 'data-testid': 'sso-config-client-secret-input', 'aria-label': 'SSO config client secret' }}
        value={config.clientSecret ?? ''}
        onChange={handleClientSecretChange}
        errorText={validation?.clientSecret?.message}
        helpText="Provided by your IdP."
      />

      <Typography variant="subheading-small">Choose discovery method</Typography>
      <div className="flex gap-4">
        <Radio
          label="Discovery URI"
          isChecked={!isManualConfiguration}
          onChange={() => setIsManualConfiguration(false)}
        />
        <Radio
          label="Manual Configuration"
          isChecked={isManualConfiguration}
          onChange={() => setIsManualConfiguration(true)}
        />
      </div>
      {isManualConfiguration ? (
        <>
          <TextInput
            isFluid
            label="Issuer"
            htmlAttributes={{ 'data-testid': 'sso-config-issuer-input', 'aria-label': 'SSO config issuer' }}
            value={config.issuer ?? ''}
            onChange={handleIssuerChange}
            errorText={validation?.issuer?.message}
            helpText='Provided by your IdP. For example "https://some-project.okta.com"'
          />

          <TextInput
            isFluid
            label="Authorization Endpoint"
            htmlAttributes={{
              'aria-label': 'SSO config authorization endpoint',
              'data-testid': 'sso-config-authorization-endpoint-input',
            }}
            value={config.authorizationEndpoint ?? ''}
            onChange={handleAuthorizationEndpointChange}
            errorText={validation?.authorizationEndpoint?.message}
            helpText='Provided by your IdP. For example "https://some-project.okta.com/oauth2/v1/authorize"'
          />

          <TextInput
            isFluid
            label="Token Endpoint"
            htmlAttributes={{
              'aria-label': 'SSO config token endpoint',
              'data-testid': 'sso-config-token-endpoint-input',
            }}
            value={config.tokenEndpoint ?? ''}
            onChange={handleTokenEndpointChange}
            errorText={validation?.tokenEndpoint?.message}
            helpText='Provided by your IdP. For example "https://some-project.okta.com/oauth2/v1/token"'
          />

          <TextInput
            isFluid
            label="JWKS URI"
            htmlAttributes={{ 'aria-label': 'SSO config jwks uri', 'data-testid': 'sso-config-jwks-uri-input' }}
            value={config.jwksURI ?? ''}
            onChange={handleJwksURIChange}
            errorText={validation?.jwksURI?.message}
            helpText='URI for JSON Web Key Sets provided by your IdP. For example "https://some-project.okta.com/oauth2/v1/keys"'
          />
        </>
      ) : (
        <TextInput
          isFluid
          label="Discovery URI"
          htmlAttributes={{ 'data-testid': 'sso-config-discovery-uri-input', 'aria-label': 'SSO config discovery uri' }}
          value={config.discoveryURI ?? ''}
          onChange={handleDiscoveryURIChange}
          errorText={validation?.discoveryURI?.message}
          helpText='Provided by your IdP. For example "https://some-project.okta.com/.well-known/openid-configuration"'
        />
      )}

      <div className="border-neutral-border-weak w-full border-b" />

      <TextInput
        isFluid
        htmlAttributes={{
          'aria-label': 'SSO config role mapping',
          'data-testid': 'sso-config-role-mapping-input',
        }}
        label="Role Mapping"
        value={config.roleMapping ?? ''}
        onChange={handleRoleMappingChange}
        errorText={validation?.roleMapping?.message}
        helpText='Role mapping only applies for Instance SSO. For example "group1"=role1;"group2"=role2'
      />
    </div>
  );
};

export default SsoConfigFormFields;
