import { isAnyOf } from '@reduxjs/toolkit';
import { debounce, isEmpty } from 'lodash-es';

import { restoreInventoryFromVisibleIds } from '../../modules/actionHandler';
import { log } from '../../services/logging';
import { reload, setDatabase, setDisconnected, setFailedConnection } from '../connections/connectionsDuck';
import { addToVisible } from '../graph/graph.actions';
import { addIndexes } from '../perspectives/perspectiveMetadata';
import { SET_CURRENT_PERSPECTIVE, removePerspective } from '../perspectives/perspectives';
import { addScene, duplicateScene, updateSceneName } from '../scene/scene';
import ScenePersistor from '../scene/scenePersistor';
import { setShowRestoreModal } from '../settings/settings';
import { REHYDRATE } from './constants';
import DocumentPersistor from './documentPersistor';
import PluginPersistor from './pluginPersistor';
import RehydratePersistor from './rehydratePersistor';
import { SharedStoragePersistor } from './sharedStoragePersistor';

/**
 * Add more actions if we need to persist the data immediately.
 * It is easier to define what it should not be in this list, frequent actions with not relevant change to state (example POOR_CONNECTION, SET_ZOOM)
 * These actions need to persist immediately because:
 *  - They make relevant changes to the state ( removing perspective, adding nodes )
 *  - E2E tests are failing if we don't persist the state immediately
 */
const persistImmediateActions = [
  removePerspective,
  addToVisible,
  setShowRestoreModal,
  addIndexes,
  addScene,
  duplicateScene,
  updateSceneName,
];

const loadActions = [SET_CURRENT_PERSPECTIVE];

export const persistenceMiddleware = ({ getState, dispatch }) => {
  const load = (action) => loadFromStorage({ getState, dispatch })(null, action);
  const persistWithPromise = saveToStorage({ getState, dispatch });
  const persistDebounced = debounce(saveToStorage({ getState, dispatch }), 500);

  let active = false;
  return (next) => (action) => {
    const nextResult = next(action);

    if ([setFailedConnection.toString(), setDisconnected.toString(), reload.toString()].includes(action.type)) {
      active = false;
      persistDebounced.cancel();
    }
    if (active) {
      if (setDatabase.match(action)) {
        active = false;
        persistDebounced.cancel();
        persistWithPromise(action)
          .then(() => load(action))
          .then(() => restoreInventoryFromVisibleIds({ getState, dispatch }))
          .catch((err) => {
            log.error(err);
          });
      } else if (isAnyOf(...persistImmediateActions)(action)) {
        persistDebounced(action);
        persistDebounced.flush();
      } else {
        persistDebounced(action);
      }

      if (loadActions.includes(action.type)) {
        load(action);
      }
    }

    if (action.type === REHYDRATE) {
      active = true;
    }

    return nextResult;
  };
};

const orderedStatePersistenceProviders = [
  new RehydratePersistor(),
  new DocumentPersistor(),
  new PluginPersistor(),
  new ScenePersistor(),
  new SharedStoragePersistor(),
];

export const loadFromStorage = (store) => async (callback, action) => {
  const { dispatch, getState } = store;
  const state = getState();
  let payload = {};
  for (const persistenceProvider of orderedStatePersistenceProviders) {
    const canLoad = !persistenceProvider.shouldLoadState || persistenceProvider.shouldLoadState({ state, action });

    if (canLoad) {
      if (!persistenceProvider.loadFromStorage) {
        log.info('Method loadFromStorage is not defined for a state persistence provider');
      } else {
        payload = await persistenceProvider.loadFromStorage({ store, payload, callback, action });
      }
    }
  }

  if (!isEmpty(payload)) {
    // Dispatch rehydrate state
    dispatch(rehydrateAction(payload));
  }
  callback && callback();
};

const rehydrateAction = (payload) => ({
  type: REHYDRATE,
  payload,
});

export const saveToStorage =
  ({ getState, dispatch }) =>
  async (action) => {
    const state = getState();
    for (const persistenceProvider of orderedStatePersistenceProviders) {
      const canSave =
        persistenceProvider.saveToStorage &&
        (!persistenceProvider.shouldSaveState || persistenceProvider.shouldSaveState({ state, action }));
      if (canSave) {
        await persistenceProvider.saveToStorage({ state, action, dispatch });
      }
    }
  };
