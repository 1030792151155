import { Button, IconButton, Label, LoadingSpinner, Tooltip } from '@neo4j-ndl/react';
import { TrashIconOutline } from '@neo4j-ndl/react/icons';
import type { CustomEndpoint, CustomEndpointDetails, Instance } from '@nx/state';
import { ACTION, CUSTOM_ENDPOINT_STATUS, usePermissions } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { CopyOnHoverColumn, DataGridHelpers, PermissionTooltip, SearchField } from '@nx/ui';
import type { CellContext } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import type { ComponentProps } from 'react';
import { useMemo, useState } from 'react';

import { useCommonTable } from '../components/table/common-table';
import { ConfigureCustomEndpointDialog } from './configure-dialog';
import { DeleteCustomEndpointDialog } from './delete-dialog';
import { constructCustomEndpointUrl, friendlyCustomEndpointStatus } from './helpers';
import { UndoTransferDialog } from './undo-transfer-dialog';

type CustomEndpointAction = {
  action: 'configure' | 'undo-transfer' | 'delete';
  customEndpoint: CustomEndpoint;
  instance?: Instance;
  sourceInstance?: Instance;
};

const columnHelper = createColumnHelper<CustomEndpointDetails>();

const ActionRow = <T, U>({
  endpoint,
  instance,
  sourceInstance,
  setCurrentAction,
  cx,
}: {
  endpoint: CustomEndpointDetails;
  instance?: Instance;
  sourceInstance?: Instance;
  setCurrentAction: (action: CustomEndpointAction | null) => void;
  cx: CellContext<T, U>;
}) => {
  const { isAllowed } = usePermissions();
  const canEditEndpoint = isAllowed(ACTION.UPDATE, `namespaces/${endpoint.projectId}/custom-endpoints/${endpoint.id}`);
  const canDeleteEndpoint = isAllowed(
    ACTION.DELETE,
    `namespaces/${endpoint.projectId}/custom-endpoints/${endpoint.id}`,
  );

  return (
    <>
      <div className="flex grow justify-end gap-2">
        {endpoint.isRevertible && isNotNullish(sourceInstance) ? (
          <Tooltip
            type="simple"
            isDisabled={canEditEndpoint && sourceInstance.availableActions.link_custom_endpoint.enabled}
            placement="left"
          >
            <Tooltip.Trigger hasButtonWrapper>
              <Button
                size="small"
                fill="outlined"
                color="danger"
                isDisabled={!canEditEndpoint || !sourceInstance.availableActions.link_custom_endpoint.enabled}
                onClick={() =>
                  setCurrentAction({
                    action: 'undo-transfer',
                    customEndpoint: endpoint,
                    instance: instance,
                    sourceInstance: sourceInstance,
                  })
                }
              >
                Undo transfer
              </Button>
            </Tooltip.Trigger>
            <Tooltip.Content className="!fixed !w-[300px]">
              {canEditEndpoint ? (
                <span>
                  {/* Hand-crafted message because it may not be clear which instance link_custom_endpoint.message
                      is referring to */}
                  The previously assigned instance <b>{sourceInstance.name}</b> is currently not available for custom
                  endpoint assignment.
                </span>
              ) : (
                "You don't have permission to perform this action."
              )}
            </Tooltip.Content>
          </Tooltip>
        ) : (
          <Tooltip type="simple" isDisabled={canEditEndpoint && endpoint.isTransferable} placement="left">
            <Tooltip.Trigger hasButtonWrapper>
              <Button
                size="small"
                fill="outlined"
                color="neutral"
                isDisabled={!canEditEndpoint || !endpoint.isTransferable}
                onClick={() =>
                  setCurrentAction({
                    action: 'configure',
                    customEndpoint: endpoint,
                    instance: instance,
                  })
                }
              >
                Configure
              </Button>
            </Tooltip.Trigger>
            <Tooltip.Content className="!fixed !w-[300px]">
              {canEditEndpoint ? (
                <span>Custom endpoint {<b>{endpoint.name}</b>} was updated or created too recently.</span>
              ) : (
                "You don't have permission to perform this action."
              )}
            </Tooltip.Content>
          </Tooltip>
        )}
        <PermissionTooltip hasButtonWrapper hasPermission={canDeleteEndpoint} placement="left">
          <IconButton
            ariaLabel={`Delete custom endpoint ${endpoint.name}`}
            isClean
            isDanger
            isDisabled={!canDeleteEndpoint}
            onClick={() =>
              setCurrentAction({
                action: 'delete',
                customEndpoint: endpoint,
                instance: instance,
              })
            }
            size="small"
          >
            <TrashIconOutline />
          </IconButton>
        </PermissionTooltip>
      </div>
    </>
  );
};

type Props = {
  instances: Instance[];
  endpoints: CustomEndpointDetails[];
  isLoading: boolean;
};

export const CustomEndpointsTable = ({ instances, endpoints, isLoading }: Props) => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [currentAction, setCurrentAction] = useState<CustomEndpointAction | null>(null);
  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'URL',
        cell: (cx) => {
          const url = constructCustomEndpointUrl(cx.row.original);
          return <CopyOnHoverColumn value={url} />;
        },
        size: 450,
      }),
      columnHelper.accessor('dbId', {
        header: 'Instance',
        cell: (cx) => {
          const instance = instances.find((i) => i.id === cx.getValue());
          return <DataGridHelpers.TruncatedColumn value={instance?.name ?? ''} />;
        },
        size: 250,
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        cell: (cx) => {
          const status = cx.getValue();
          const { id } = cx.row.original;

          let color: ComponentProps<typeof Label>['color'] = 'default';
          switch (status) {
            case CUSTOM_ENDPOINT_STATUS.ACTIVE:
              color = 'success';
              break;

            case CUSTOM_ENDPOINT_STATUS.ERROR:
              color = 'danger';
              break;
            case CUSTOM_ENDPOINT_STATUS.DISCONNECTED:
              color = 'info';
              break;
            case CUSTOM_ENDPOINT_STATUS.SUBMITTED:
              return (
                <div className="flex flex-row gap-1" data-testid={`loading-status-${id}`}>
                  <LoadingSpinner className="self-center" /> <p>Loading...</p>
                </div>
              );
            case CUSTOM_ENDPOINT_STATUS.UNKNOWN:
              color = 'warning';
              break;
            case CUSTOM_ENDPOINT_STATUS.DELETED:
            default:
              color = 'default';
          }

          return (
            <Label color={color} fill="semi-filled" data-testid={`status-${id}`}>
              {friendlyCustomEndpointStatus(status)}
            </Label>
          );
        },
      }),
      columnHelper.display({
        id: 'actions',
        cell: (cx) => {
          const endpoint = cx.row.original;
          const instance = instances.find((inst) => inst.id === endpoint.dbId);
          const sourceInstance = instances.find((inst) => inst.id === endpoint.sourceDbId);

          if (endpoint.status === CUSTOM_ENDPOINT_STATUS.DELETED) {
            return <></>;
          }

          return (
            <ActionRow
              endpoint={endpoint}
              instance={instance}
              sourceInstance={sourceInstance}
              setCurrentAction={setCurrentAction}
              cx={cx}
            />
          );
        },
        size: 200,
        maxSize: 200,
        enableResizing: false,
      }),
    ],
    [instances],
  );

  const filteredData = useMemo(() => {
    const normalizedFilter = globalFilter.trim().toLocaleLowerCase();
    return endpoints.filter((endpoint) => {
      const url = constructCustomEndpointUrl(endpoint);
      const instance = instances.find((i) => i.id === endpoint.dbId);
      const items = [url, friendlyCustomEndpointStatus(endpoint.status)];
      if (isNotNullish(endpoint.dbId)) {
        items.push(endpoint.dbId);
      }
      if (isNotNullish(instance)) {
        items.push(instance.name);
      }
      return items.some((str) => str.toLocaleLowerCase().includes(normalizedFilter));
    });
  }, [endpoints, globalFilter, instances]);

  const table = useCommonTable({
    columns,
    data: filteredData,
    initialState: {
      pagination: { pageSize: 25 },
    },
    enableColumnPinning: true,
    state: {
      columnPinning: {
        right: ['actions'],
      },
    },
  });

  return (
    <>
      <DataGridHelpers.Wrapper>
        <DataGridHelpers.OuterHeader>
          <div className="flex w-full flex-wrap justify-between gap-2">
            <div className="flex basis-[400px] gap-2">
              <SearchField
                className="min-w-36 grow"
                value={globalFilter}
                onChange={(event) => setGlobalFilter(event.target.value)}
                htmlAttributes={{
                  'aria-label': 'Search for custom endpoint',
                }}
              />
            </div>
          </div>
        </DataGridHelpers.OuterHeader>
        <DataGridHelpers.DataGridRightColumnPinned<CustomEndpointDetails>
          isLoading={isLoading}
          tableInstance={table}
          styling={{
            headerStyle: 'clean',
            borderStyle: 'horizontal',
          }}
        />
      </DataGridHelpers.Wrapper>
      {currentAction?.action === 'delete' && (
        <DeleteCustomEndpointDialog
          endpoint={currentAction.customEndpoint}
          instance={currentAction.instance}
          onClose={() => setCurrentAction(null)}
        />
      )}
      {currentAction?.action === 'configure' && (
        <ConfigureCustomEndpointDialog
          endpoint={currentAction.customEndpoint}
          instance={currentAction.instance}
          instances={instances}
          onClose={() => setCurrentAction(null)}
        />
      )}
      {currentAction?.action === 'undo-transfer' && isNotNullish(currentAction.sourceInstance) && (
        <UndoTransferDialog
          endpoint={currentAction.customEndpoint}
          instance={currentAction.instance}
          sourceInstance={currentAction.sourceInstance}
          onClose={() => setCurrentAction(null)}
        />
      )}
    </>
  );
};
