import { isNil } from 'lodash-es';
import parseUrl from 'url-parse';

import { config } from '../../../config';
import type { Nullable } from '../../types/utility';
import type { UserPrivilege } from './types';

const LocalHosts = ['localhost', '[::1]', '127.0.0.1', '0.0.0.0'];
const AuraHosts = ['neo4j.io', 'neo4j-dev.io', 'customers.neo4j.com', 'nx-trunk.nw.r.appspot.com', 'surge.sh'];
const BloomAppUrl = 'https://bloom.neo4j.io';

export const builtInRoles = {
  admin: [
    { role: 'admin', access: 'GRANTED', action: 'read', resource: 'all_properties', graph: '*', segment: 'NODE(*)' },
    { role: 'admin', access: 'GRANTED', action: 'write', resource: 'all_properties', graph: '*', segment: 'NODE(*)' },
    { role: 'admin', access: 'GRANTED', action: 'traverse', resource: 'graph', graph: '*', segment: 'NODE(*)' },
    {
      role: 'admin',
      access: 'GRANTED',
      action: 'read',
      resource: 'all_properties',
      graph: '*',
      segment: 'RELATIONSHIP(*)',
    },
    {
      role: 'admin',
      access: 'GRANTED',
      action: 'write',
      resource: 'all_properties',
      graph: '*',
      segment: 'RELATIONSHIP(*)',
    },
    { role: 'admin', access: 'GRANTED', action: 'traverse', resource: 'graph', graph: '*', segment: 'RELATIONSHIP(*)' },
    { role: 'admin', access: 'GRANTED', action: 'access', resource: 'database', graph: '*', segment: 'database' },
    { role: 'admin', access: 'GRANTED', action: 'admin', resource: 'database', graph: '*', segment: 'database' },
    { role: 'admin', access: 'GRANTED', action: 'schema', resource: 'database', graph: '*', segment: 'database' },
    { role: 'admin', access: 'GRANTED', action: 'token', resource: 'database', graph: '*', segment: 'database' },
    { role: 'admin', access: 'GRANTED', action: 'dbms_actions', resource: 'database', graph: '*', segment: 'database' },
    {
      role: 'admin',
      access: 'GRANTED',
      action: 'index',
      resource: 'database',
      graph: '*',
      segment: 'database',
      note: 'from 4.3',
    },
    {
      role: 'admin',
      access: 'GRANTED',
      action: 'execute',
      resource: 'database',
      graph: '*',
      segment: 'PROCEDURE(*)',
      note: 'from 4.2',
    },
  ],
  reader: [
    { role: 'reader', access: 'GRANTED', action: 'read', resource: 'all_properties', graph: '*', segment: 'NODE(*)' },
    { role: 'reader', access: 'GRANTED', action: 'traverse', resource: 'graph', graph: '*', segment: 'NODE(*)' },
    {
      role: 'reader',
      access: 'GRANTED',
      action: 'read',
      resource: 'all_properties',
      graph: '*',
      segment: 'RELATIONSHIP(*)',
    },
    {
      role: 'reader',
      access: 'GRANTED',
      action: 'traverse',
      resource: 'graph',
      graph: '*',
      segment: 'RELATIONSHIP(*)',
    },
    { role: 'reader', access: 'GRANTED', action: 'access', resource: 'database', graph: '*', segment: 'database' },
  ],
  editor: [
    {
      role: 'publisher',
      access: 'GRANTED',
      action: 'read',
      resource: 'all_properties',
      graph: '*',
      segment: 'NODE(*)',
    },
    {
      role: 'publisher',
      access: 'GRANTED',
      action: 'write',
      resource: 'all_properties',
      graph: '*',
      segment: 'NODE(*)',
    },
    { role: 'publisher', access: 'GRANTED', action: 'traverse', resource: 'graph', graph: '*', segment: 'NODE(*)' },
    {
      role: 'publisher',
      access: 'GRANTED',
      action: 'read',
      resource: 'all_properties',
      graph: '*',
      segment: 'RELATIONSHIP(*)',
    },
    {
      role: 'publisher',
      access: 'GRANTED',
      action: 'write',
      resource: 'all_properties',
      graph: '*',
      segment: 'RELATIONSHIP(*)',
    },
    {
      role: 'publisher',
      access: 'GRANTED',
      action: 'traverse',
      resource: 'graph',
      graph: '*',
      segment: 'RELATIONSHIP(*)',
    },
    { role: 'publisher', access: 'GRANTED', action: 'access', resource: 'database', graph: '*', segment: 'database' },
  ],
  publisher: [
    {
      role: 'publisher',
      access: 'GRANTED',
      action: 'read',
      resource: 'all_properties',
      graph: '*',
      segment: 'NODE(*)',
    },
    {
      role: 'publisher',
      access: 'GRANTED',
      action: 'write',
      resource: 'all_properties',
      graph: '*',
      segment: 'NODE(*)',
    },
    { role: 'publisher', access: 'GRANTED', action: 'traverse', resource: 'graph', graph: '*', segment: 'NODE(*)' },
    {
      role: 'publisher',
      access: 'GRANTED',
      action: 'read',
      resource: 'all_properties',
      graph: '*',
      segment: 'RELATIONSHIP(*)',
    },
    {
      role: 'publisher',
      access: 'GRANTED',
      action: 'write',
      resource: 'all_properties',
      graph: '*',
      segment: 'RELATIONSHIP(*)',
    },
    {
      role: 'publisher',
      access: 'GRANTED',
      action: 'traverse',
      resource: 'graph',
      graph: '*',
      segment: 'RELATIONSHIP(*)',
    },
    { role: 'publisher', access: 'GRANTED', action: 'access', resource: 'database', graph: '*', segment: 'database' },
    { role: 'publisher', access: 'GRANTED', action: 'token', resource: 'database', graph: '*', segment: 'database' },
  ],
  architect: [
    {
      role: 'architect',
      access: 'GRANTED',
      action: 'read',
      resource: 'all_properties',
      graph: '*',
      segment: 'NODE(*)',
    },
    {
      role: 'architect',
      access: 'GRANTED',
      action: 'write',
      resource: 'all_properties',
      graph: '*',
      segment: 'NODE(*)',
    },
    { role: 'architect', access: 'GRANTED', action: 'traverse', resource: 'graph', graph: '*', segment: 'NODE(*)' },
    {
      role: 'architect',
      access: 'GRANTED',
      action: 'read',
      resource: 'all_properties',
      graph: '*',
      segment: 'RELATIONSHIP(*)',
    },
    {
      role: 'architect',
      access: 'GRANTED',
      action: 'write',
      resource: 'all_properties',
      graph: '*',
      segment: 'RELATIONSHIP(*)',
    },
    {
      role: 'architect',
      access: 'GRANTED',
      action: 'traverse',
      resource: 'graph',
      graph: '*',
      segment: 'RELATIONSHIP(*)',
    },
    { role: 'architect', access: 'GRANTED', action: 'access', resource: 'database', graph: '*', segment: 'database' },
    { role: 'architect', access: 'GRANTED', action: 'schema', resource: 'database', graph: '*', segment: 'database' },
    { role: 'architect', access: 'GRANTED', action: 'token', resource: 'database', graph: '*', segment: 'database' },
  ],
};

export const getBloomAppUrl = (version: string | null | undefined) => {
  if (isNil(version)) return;
  let url = `${BloomAppUrl}/${version}/`;
  if (window.location.search.length > 1) url += window.location.search;
  if (window.location.hash.length > 1) url += window.location.hash;
  return url;
};

const getUrlInfo = (url: any) => {
  let protocolMissing = false;

  if (typeof url !== 'string') {
    return null;
  }

  // prepend a default protocol, if none was found
  if (url.match(/^(.+:\/\/)?/)?.[1] === undefined) {
    url = `http://${url}`;
    protocolMissing = true;
  }

  const { protocol, username, password, host, hostname, port, pathname, query: search, hash } = parseUrl(url, {});

  return {
    protocol: protocolMissing ? '' : protocol,
    username,
    password,
    host,
    hostname,
    port,
    pathname,
    search,
    hash,
  };
};

export const userHasFullWritePrivilegesOnDatabase = (userPrivileges: UserPrivilege[], database: { name: string }) => {
  if (isNil(database) || isNil(userPrivileges)) return false;

  const grantedWriteSegments = userPrivileges
    .filter((privilege) => {
      return (
        ['*', database.name].includes(privilege.graph) && privilege.access === 'GRANTED' && privilege.action === 'write'
      );
    })
    .map((p) => p.segment);
  const deniedWriteSegments = userPrivileges
    .filter((privilege) => {
      return (
        ['*', database.name].includes(privilege.graph) && privilege.access === 'DENIED' && privilege.action === 'write'
      );
    })
    .map((p) => p.segment);

  if (deniedWriteSegments.length > 0) {
    return false;
  }
  return grantedWriteSegments.includes('NODE(*)') && grantedWriteSegments.includes('RELATIONSHIP(*)');
};

const isPriviledgeGranted = (
  privileges: UserPrivilege[] | null,
  database: { name: string } | null,
  actionsHierarchy: string | string[],
) => {
  if (isNil(database) || isNil(privileges)) return false;

  const dbPrivileges = privileges.filter((p) => ['*', database.name].includes(p.graph));
  const existsGrantedPrivilege = !isNil(
    dbPrivileges.find((p) => p.access === 'GRANTED' && actionsHierarchy.includes(p.action)),
  );
  const existsDeniedPrivilege = !isNil(
    dbPrivileges.find((p) => p.access === 'DENIED' && actionsHierarchy.includes(p.action)),
  );

  return existsGrantedPrivilege && !existsDeniedPrivilege;
};

export const canUserListRoles = (privileges: UserPrivilege[], database: { name: string } | null) => {
  return isPriviledgeGranted(privileges, database, ['dbms_actions', 'role_management', 'admin', 'show_role']);
};

export const canUserCreateRoles = (privileges: UserPrivilege[], database: { name: string } | null) => {
  return isPriviledgeGranted(privileges, database, ['dbms_actions', 'role_management', 'admin', 'create_role']);
};

export const isLocalHost = (url: any) => {
  if (String(config.NO_CHECKS) === 'true') {
    return true;
  }
  const info = getUrlInfo(url);
  if (isNil(info) || isNil(info.hostname)) {
    return false;
  }
  return LocalHosts.includes(info.hostname);
};

export const isAura = (url: any) => {
  const info = getUrlInfo(url);
  if (isNil(info) || isNil(info.hostname)) {
    return false;
  }
  return AuraHosts.find((h) => info.hostname.endsWith(h)) !== undefined;
};

export const isRemoteConnection = (connectionDetails?: Nullable<{ host: string }>) => {
  if (isNil(connectionDetails) || isNil(connectionDetails?.host)) {
    return false;
  }
  return !connectionDetails.host.includes('://localhost');
};
