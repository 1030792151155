export const profileInformationDefaults: ProfileInformationFormData = {
  firstName: '',
  lastName: '',
  jobRole: undefined,
  jobRoleOther: undefined,
  primaryUseCase: undefined,
  primaryUseCaseOther: undefined,
  companyName: '',
  country: undefined,
  subDivision: undefined,
};

export interface ProfileInformationFormData {
  firstName: string;
  lastName: string;
  jobRole?: string;
  jobRoleOther?: string;
  primaryUseCase?: string;
  primaryUseCaseOther?: string;
  companyName: string;
  country?: string;
  subDivision?: string;
}
