import { Button, IconButton, Menu, Typography } from '@neo4j-ndl/react';
import { ChevronDownIconOutline, ChevronUpIconOutline, EllipsisHorizontalIconSolid } from '@neo4j-ndl/react/icons';
import { LOCAL_INSTANCE_STATUS, MODAL_TYPE, useDropDatabaseMutation, useModal } from '@nx/state';
import type { Db4, Db5, DbList } from '@nx/state';
import { isNullish } from '@nx/stdlib';
import { DataGridHelpers } from '@nx/ui';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo, useRef, useState } from 'react';

function DatabaseActions({
  database,
  dbmsId,
  dbmsStatus,
}: {
  database: Db4 | Db5;
  dbmsId: string;
  dbmsStatus: LOCAL_INSTANCE_STATUS;
}) {
  const [openMenu, setOpenMenu] = useState(false);
  const [dropDatabase] = useDropDatabaseMutation();
  const actionsButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <div className="flex w-full items-center justify-end gap-1">
      <IconButton isClean ref={actionsButtonRef} ariaLabel="Open menu" size="medium" onClick={() => setOpenMenu(true)}>
        <EllipsisHorizontalIconSolid />
      </IconButton>
      <Menu isOpen={openMenu} onClose={() => setOpenMenu(false)} anchorRef={actionsButtonRef}>
        <Menu.Items>
          <Menu.Item
            isDisabled={dbmsStatus !== LOCAL_INSTANCE_STATUS.STARTED}
            title="Delete"
            onClick={() => {
              void dropDatabase({ dbmsId, database: database.name });
              setOpenMenu(false);
            }}
          />
        </Menu.Items>
      </Menu>
    </div>
  );
}

export function DatabaseList({
  databases,
  dbmsId,
  dbmsStatus,
  rootPath,
}: {
  databases: DbList | undefined;
  dbmsId: string;
  dbmsStatus: LOCAL_INSTANCE_STATUS;
  rootPath?: string;
}) {
  const createDatabaseModal = useModal(MODAL_TYPE.LOCAL_INSTANCE_DB_CREATE);

  const columnHelper = createColumnHelper<DbList['dbs'][number]>();
  const [isOpen, toggleOpen] = useState(false);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
      }),

      columnHelper.display({
        id: 'actions',
        cell: (cx) => {
          const database = cx.row.original;
          return <DatabaseActions database={database} dbmsId={dbmsId} dbmsStatus={dbmsStatus} />;
        },
      }),
    ],
    [columnHelper, dbmsId, dbmsStatus],
  );

  const table = useReactTable({
    columns,
    data: databases?.dbs ?? [],
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnPinning: {
        right: ['actions'],
      },
    },
  });

  if (isNullish(databases)) {
    return null;
  }

  return (
    <details className="bg-palette-neutral-bg-on-bg-weak rounded-lg">
      <summary
        className="hover:bg-palette-neutral-hover flex cursor-pointer items-center gap-3 p-3"
        onClick={() => toggleOpen(!isOpen)}
      >
        {isOpen ? <ChevronUpIconOutline className="h-4" /> : <ChevronDownIconOutline className="h-4" />}
        <Typography variant="subheading-medium">Databases ({databases.dbs.length})</Typography>
        <Button
          fill="outlined"
          size="small"
          color="neutral"
          className="ml-auto"
          onClick={(e) => {
            e.stopPropagation();
            createDatabaseModal.open({ dbmsId, rootPath, databases });
          }}
          isDisabled={dbmsStatus !== LOCAL_INSTANCE_STATUS.STARTED}
        >
          Create database
        </Button>
      </summary>

      {isOpen && (
        <div className="[&_.ndl-data-grid-td]:!bg-palette-neutral-bg-on-bg-weak rounded-b-lg p-3">
          {databases.cached && (
            <Typography variant="body-small" as="div" className="mb-2">
              This list of databases is cached, start the instance to refresh the list.
            </Typography>
          )}
          <DataGridHelpers.DataGridRightColumnPinned
            tableInstance={table}
            components={{
              Navigation: null,
              Header: null,
            }}
            isResizable={false}
          />
        </div>
      )}
    </details>
  );
}
