import { createListenerMiddleware } from '@reduxjs/toolkit';

import type { RootState } from '../../store';
import { addNotification } from '../notifications-slice';
import { appUpdateNotificationShown } from './configuration-slice';

export function createConfigurationListenerMiddleware() {
  const middleware = createListenerMiddleware<RootState>();

  // Prompt the user to refresh the page when a new version of the application is available
  middleware.startListening({
    predicate(action, currentState, previousState) {
      return (
        currentState.configuration.remoteAppManifest.version !== previousState.configuration.remoteAppManifest.version
      );
    },
    effect(action, listenerApi) {
      const { appManifest, remoteAppManifest, appUpdateNotificationShownTimestamp } =
        listenerApi.getState().configuration;

      const newVersionAvailable =
        appManifest.version !== undefined &&
        remoteAppManifest.version !== undefined &&
        remoteAppManifest.version !== appManifest.version;

      const MS_PER_DAY = 1000 * 60 * 60 * 24;
      const NOTIFICATION_INTERVAL = 2;
      const daysSinceLastShown =
        appUpdateNotificationShownTimestamp !== undefined
          ? Math.floor((Date.now() - appUpdateNotificationShownTimestamp) / MS_PER_DAY)
          : Number.MAX_VALUE;

      const showNotification = newVersionAvailable && daysSinceLastShown > NOTIFICATION_INTERVAL;

      if (showNotification) {
        listenerApi.dispatch(
          addNotification({
            type: 'info',
            title: 'A new version of the application is ready',
            description: 'Please refresh the page to get the latest update',
            actionCallbacks: ['reload'],
          }),
        );
        listenerApi.dispatch(appUpdateNotificationShown());
      }
    },
  });

  return middleware.middleware;
}
