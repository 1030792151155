import { createAsyncThunk as _createAsyncThunk } from '@reduxjs/toolkit';
import type { Store } from '@reduxjs/toolkit';
import { coreModule } from '@reduxjs/toolkit/query';
import { buildCreateApi, reactHooksModule } from '@reduxjs/toolkit/query/react';
import * as React from 'react';
import type { ReactReduxContextValue, TypedUseSelectorHook } from 'react-redux';
import { Provider, createDispatchHook, createSelectorHook, createStoreHook } from 'react-redux';

import type { Neo4jDriverAdapter } from './adapters/neo4j-driver-adapter';
import type { AppDispatch, CombinedRootState, RootState } from './store';

const StateContext = React.createContext<ReactReduxContextValue | null>(null);

type StateProviderProps = {
  children: React.ReactChild;
  store: Store<CombinedRootState>;
};

/**
 * Framework Redux store provider
 *
 * Uses custom context to allow to reliably reach the store if there’s
 * another Redux provider in tree.
 */
export function StateProvider(props: StateProviderProps) {
  return (
    <Provider context={StateContext} store={props.store}>
      {props.children}
    </Provider>
  );
}

type CreateAsyncThunk = ReturnType<
  typeof _createAsyncThunk.withTypes<{
    state: RootState;
    dispatch: AppDispatch;
    extra: {
      driver: Neo4jDriverAdapter;
    };
  }>
>;

export const createAsyncThunk: CreateAsyncThunk = _createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  extra: {
    driver: Neo4jDriverAdapter;
  };
}>();

/**
 * Get reference to Framework Redux store
 */
export const useStore = createStoreHook<RootState>(StateContext);

/**
 * Get reference to Framework Redux store dispatch function
 */
export const useDispatch: () => AppDispatch = createDispatchHook(StateContext);

/**
 * Derive value from Framework Redux store state using provided selector
 */
export const useSelector: TypedUseSelectorHook<CombinedRootState> = createSelectorHook(StateContext);

export const customCreateApi = buildCreateApi(
  coreModule(),
  reactHooksModule({
    hooks: {
      useDispatch: createDispatchHook(StateContext),
      useSelector: createSelectorHook(StateContext),
      useStore: createStoreHook(StateContext),
    },
  }),
);
