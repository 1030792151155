import { useConnections } from '@nx/state';

import type { ConnectionDetails } from '../connection-form.types';
import { usePassedConnectionDetails } from './use-passed-connection-details';

export const useConnectionDetails = (): ConnectionDetails | undefined => {
  const [latestConnection] = useConnections();

  const passedConn = usePassedConnectionDetails();
  if (passedConn !== undefined) {
    return passedConn;
  }

  if (latestConnection !== undefined) {
    return {
      credentials: latestConnection.credentials,
      instanceName: latestConnection.name,
      password: '',
      url: latestConnection.url,
    };
  }

  return undefined;
};
