import { flatten, isArray, isEmpty, isNil, isString } from 'lodash-es';

import {
  PROPERTY_CONDITION_STARTS_WITH,
  SUGGESTION_ANY_NODE,
  SUGGESTION_ANY_RELATIONSHIP,
} from '../../../../modules/SearchBar/SearchBar.const';
import { alignCase, buildSuggestion } from '../../../../modules/SearchBar/SearchBar.utils';
import type { NodeSuggestion, RelationshipSuggestion } from '../../../../modules/SearchBar/types';
import { SUGGESTION_TYPE } from '../../../../modules/SearchBar/types';
import { stopWordsSet } from '../../../search/structured/stopWords';
import { findCategoryByLabel } from '../property-value-suggestions/query/util';
import type {
  NodeEntryVerificationResult,
  Partition,
  RelationshipEntryVerificationResult,
  SinglePathVerificationResult,
} from './structured-suggestions.types';

const normalize = (s: string) => s.replace(/[_-\s]/g, ' ');

export const startsWithEntity = (entity: string, normalizedInput: string, position: number) => {
  const restNormalizedInput = normalizedInput.substring(position);
  const normalizedEntity = normalize(entity);
  if (restNormalizedInput.length === 0) return false;
  if (restNormalizedInput.length <= normalizedEntity.length) {
    return normalizedInput.startsWith(
      alignCase(true, normalizedEntity.substring(0, normalizedInput.length - position)),
      position,
    );
  }
  return restNormalizedInput.startsWith(alignCase(true, normalizedEntity));
};

/**
 * Given a string containing words separated by a single space, return a list of partitions (Category, Relationship or Value)
 */
const tokenizeString = (
  input: string,
  categoryToPropertyDictionary: Map<string, Set<string>>,
  relationshipToPropertyDictionary: Map<string, Set<string>>,
) => {
  const sortByLongestFirst = (first: string, second: string) => second.length - first.length;
  const categories = Array.from(categoryToPropertyDictionary.keys()).sort(sortByLongestFirst);
  const relationships = Array.from(relationshipToPropertyDictionary.keys()).sort(sortByLongestFirst);
  const normalizedInput = alignCase(true, normalize(input));

  const partitions: Partition[] = [];
  let lastIndex = 0;
  while (lastIndex < input.length) {
    // Checking for a Category
    let isCategoryFound = false;
    for (const category of categories) {
      if (startsWithEntity(category, normalizedInput, lastIndex)) {
        partitions.push({ type: 'Category', value: [category] });
        lastIndex += category.length + 1;
        isCategoryFound = true;
        break;
      }
    }
    if (isCategoryFound) continue;

    // Checking for a Relationship
    let isRelationshipFound = false;
    for (const relationship of relationships) {
      if (startsWithEntity(relationship, normalizedInput, lastIndex)) {
        partitions.push({ type: 'Relationship', value: [relationship] });
        lastIndex += relationship.length + 1;
        isRelationshipFound = true;
        break;
      }
    }
    if (isRelationshipFound) continue;

    // everything else is a Value
    const nextSpaceIndex = input.indexOf(' ', lastIndex);
    const word = input.slice(lastIndex, nextSpaceIndex !== -1 ? nextSpaceIndex : input.length);
    partitions.push({ type: 'Value', value: [word] });
    lastIndex = nextSpaceIndex !== -1 ? nextSpaceIndex + 1 : input.length + 1;
  }

  return partitions;
};

type Result = { canBuild: false } | { canBuild: true; values: string[]; lastValueIndex: number };
/**
 * Verifies if partitions can be combined in one of the patterns:
 * - Category - Property Name
 * - Category - Property Name - Property Values
 * - Relationship - Property Name
 * - Relationship - Property Name - Property Values
 * If yes, returns combined partitions and last value index to continue the loop from
 */
const canBuildPropertyValuePattern = (
  partitions: Partition[],
  index: number,
  propertyDictionary: Map<string, Set<string>>,
  isCaseInsensitive: boolean,
): Result => {
  const current = partitions[index];
  const next = partitions[index + 1];
  if (current === undefined) return { canBuild: false };

  if (!['Category', 'Relationship'].includes(current.type) || next?.type !== 'Value') {
    return { canBuild: false };
  }

  const [entityName] = current.value;

  const consequentialValues: string[] = [];
  let lastValueIndex = 1;
  for (let i = index + 1; i < partitions.length; i++) {
    const current = partitions[i];
    if (current === undefined) {
      break;
    }
    if (current.type === 'Value') {
      consequentialValues.push(current.value[0] as string);
      if (i === partitions.length - 1) {
        lastValueIndex = i;
      }
    } else {
      lastValueIndex = i - 1;
      break;
    }
  }

  const matchPropertyName = (values: string[]) => {
    let longestMatch = '';
    let potentialPropertyName = '';
    const propertyNames = propertyDictionary.get(entityName!) ?? [];
    for (const value of values) {
      if (potentialPropertyName.length === 0) {
        potentialPropertyName = alignCase(isCaseInsensitive, value);
      } else {
        potentialPropertyName = `${potentialPropertyName} ${alignCase(isCaseInsensitive, value)}`;
      }
      for (const propertyName of propertyNames) {
        if (alignCase(isCaseInsensitive, propertyName) === potentialPropertyName) {
          longestMatch = propertyName;
          break;
        }
      }
    }
    return longestMatch;
  };

  let longestMatch = '';

  if (stopWordsSet.has(consequentialValues[0] as string)) {
    longestMatch = matchPropertyName(consequentialValues.slice(1));
  }
  if (longestMatch.length > 0) {
    consequentialValues.shift();
  } else {
    longestMatch = matchPropertyName(consequentialValues.slice(0));
  }

  return longestMatch.length > 0
    ? {
        canBuild: true,
        values: [entityName!, longestMatch, ...consequentialValues.slice(longestMatch.split(' ').length)],
        lastValueIndex,
      }
    : { canBuild: false };
};

/**
 * Combines partitions with the following patterns:
 * - Category - Property Name - Property Values -> a single Category partition with combined values
 * - consequential Value partitions -> a single Value partition with combines values
 */
const combineConsequentialValues = (
  partitions: Partition[],
  categoryToPropertyDictionary: Map<string, Set<string>>,
  relationshipToPropertyDictionary: Map<string, Set<string>>,
  isCaseInsensitive: boolean,
): Partition[] => {
  const combinedPartitions: Partition[] = [];
  let combinedCategory: Partition | null = null;
  let combinedValue: Partition | null = null;

  type Status = 'not combining' | 'combining value';
  let status: Status = 'not combining';

  const isValue = (partition: Partition | undefined) => partition?.type === 'Value';

  const canCombineValues = (currentIndex: number) => {
    const current = partitions[currentIndex];
    const next = partitions[currentIndex + 1];

    return isValue(current) && isValue(next);
  };

  const pushAndResetCombinedValues = () => {
    if (combinedCategory !== null) {
      combinedPartitions.push(combinedCategory);
      combinedCategory = null;
    }
    if (combinedValue !== null) {
      combinedPartitions.push(combinedValue);
      combinedValue = null;
    }
  };

  for (let i = 0; i < partitions.length; i++) {
    const current = partitions[i];
    const [value] = current?.value ?? [];

    switch (current?.type) {
      case 'Category':
        pushAndResetCombinedValues();

        const categoryPatternResult = canBuildPropertyValuePattern(
          partitions,
          i,
          categoryToPropertyDictionary,
          isCaseInsensitive,
        );
        if (categoryPatternResult.canBuild) {
          combinedPartitions.push({ type: 'Category', value: categoryPatternResult.values });
          i = categoryPatternResult.lastValueIndex;
        } else {
          combinedPartitions.push(current);
        }
        status = 'not combining';

        break;
      case 'Relationship':
        pushAndResetCombinedValues();

        const relationshipPatternResult = canBuildPropertyValuePattern(
          partitions,
          i,
          relationshipToPropertyDictionary,
          isCaseInsensitive,
        );
        if (relationshipPatternResult.canBuild) {
          combinedPartitions.push({ type: 'Relationship', value: relationshipPatternResult.values });
          i = relationshipPatternResult.lastValueIndex;
        } else {
          combinedPartitions.push(current);
        }
        status = 'not combining';

        break;
      case 'Value':
        switch (status) {
          case 'not combining':
            if (canCombineValues(i)) {
              combinedValue = { ...current };
              status = 'combining value';
            } else {
              pushAndResetCombinedValues();

              combinedPartitions.push(current);
              status = 'not combining';
            }
            break;
          case 'combining value':
            combinedValue?.value.push(value as string);
        }
    }
  }

  pushAndResetCombinedValues();

  return combinedPartitions;
};

export const filterSingleStopWords = (partitions: Partition[]) => {
  return partitions.filter((partition) => {
    if (partition.type !== 'Value' || partition.value.length > 1) return true;
    const word = partition?.value?.[0]?.toLowerCase() as string;
    return !stopWordsSet.has(word);
  });
};

export const partitionGraphEntity = (
  input: string,
  categoryToPropertyDictionary: Map<string, Set<string>>,
  relationshipToPropertyDictionary: Map<string, Set<string>>,
  isCaseInsensitive: boolean,
): Partition[] => {
  const partitions = tokenizeString(input, categoryToPropertyDictionary, relationshipToPropertyDictionary);
  const combinedPartitions = combineConsequentialValues(
    partitions,
    categoryToPropertyDictionary,
    relationshipToPropertyDictionary,
    isCaseInsensitive,
  );
  return filterSingleStopWords(combinedPartitions);
};

export const partitionValue = (partition: Partition): Partition[][] => {
  const { type, value } = partition;

  if (type === 'Category' || type === 'Relationship') {
    // in this case: [category/relationship - PropertyName - PropertyValue]
    // only PropertyValues needs to be partitioned
    const entityWithPropertyName = value.slice(0, 2);
    const propertyValues = value.slice(2);

    if (isEmpty(propertyValues)) {
      return [[partition]];
    }

    const partitionedPropertyValues = partitionWords(propertyValues);

    const combinedPartitions = partitionedPropertyValues.map((propertyValue) => {
      const combinedPartition = [
        {
          ...partition,
          value: [...entityWithPropertyName, propertyValue[0] as string],
        },
      ];
      if (propertyValue.length > 1) {
        for (let i = 1; i < propertyValue.length; i++) {
          const word = propertyValue[i] as string;
          combinedPartition.push({ type: 'Value', value: [word] });
        }
      }

      return combinedPartition;
    });

    return combinedPartitions;
  }
  return partitionWords(value).map((words) => words.map((word) => ({ type: 'Value', value: [word] })));
};

const partitionWords = (words: string[]) => {
  const partitions: string[][] = [];

  const collectPartitions = (start: number, partition: string[]) => {
    if (start === words.length) {
      partitions.push([...partition]);
      return;
    }

    for (let end = start + 1; end <= words.length; end++) {
      const currentPartition = words.slice(start, end);

      partition.push(currentPartition.join(' '));
      collectPartitions(end, partition);
      partition.pop();
    }
  };

  collectPartitions(0, []);

  return partitions;
};

/**
 * get Cartesian Product for string[][]
 * [
 *  [[A, B], [AB]],  <--- pick one here
 *  [[C, D], [CD]],  <--- pick another one here
 * ]
 *
 * @returns
 * [
 *  [A, B, C, D]
 *  [A, B, CD]
 *  [AB, C, D]
 *  [AB, C, D]
 * ]
 *
 */
export const getPaths = (...inputs: Partition[][][]) => {
  return inputs.reduce((acc, cur) =>
    acc.flatMap((partition1) =>
      cur.map((partition2) => {
        return [partition1, partition2].flat();
      }),
    ),
  );
};

export const sortPathsInLengthAscendingOrder = (paths: Partition[][]) => {
  return paths.sort((path1, path2) => path1.length - path2.length);
};

export const buildPathSuggestions = (
  verifiedPaths: SinglePathVerificationResult[],
  categoryToLabelDictionary: Map<string, string[]>,
) => {
  const labelToCategoryMap = new Map<string, string>();

  const pathSuggestions = verifiedPaths
    .filter((path) => !isNil(path))
    .map((path) => {
      const pathSuggestion = path.map((pathEntry, pathEntryIdx) => {
        if (isRelationshipEntryVerificationResult(pathEntry)) {
          const relationshipType = pathEntry.at(0) ?? '';
          const propertyName = pathEntry.at(1);

          let relationshipSuggestion = null;
          if (isNil(propertyName)) {
            relationshipSuggestion = buildSuggestion<RelationshipSuggestion>({
              type: SUGGESTION_TYPE.RELATIONSHIP,
              relationshipType,
            });
          } else {
            const propertyValue = pathEntry.slice(2).join(' ');
            relationshipSuggestion = buildSuggestion<NodeSuggestion>({
              type: SUGGESTION_TYPE.RELATIONSHIP,
              relationshipType,
              propertyName,
              propertyCondition: PROPERTY_CONDITION_STARTS_WITH,
              propertyConditionValue: propertyValue,
            });
          }

          if (pathEntryIdx === 0) {
            // this relationship is the first one
            return [SUGGESTION_ANY_NODE, relationshipSuggestion];
          } else if (isNodeEntryVerificationResult(path.at(pathEntryIdx - 1))) {
            // this relationship has a node before it
            return relationshipSuggestion;
          }
          // this relationship has no node before it
          return [SUGGESTION_ANY_NODE, relationshipSuggestion];
        }
        const { labels, propertyName, propertyValue, propertyType } = pathEntry;
        const categoryName = findCategoryByLabel(labels, categoryToLabelDictionary, labelToCategoryMap) ?? '';

        let nodeSuggestion = null;
        if (isEmpty(propertyName)) {
          nodeSuggestion = buildSuggestion<NodeSuggestion>({
            type: SUGGESTION_TYPE.NODE,
            categoryName,
          });
        } else {
          nodeSuggestion = buildSuggestion<NodeSuggestion>({
            type: SUGGESTION_TYPE.NODE,
            categoryName,
            propertyName,
            propertyCondition: PROPERTY_CONDITION_STARTS_WITH,
            propertyConditionValue: propertyValue,
            propertyType,
          });
        }

        if (pathEntryIdx === 0) {
          // this node is the first one
          return nodeSuggestion;
        } else if (isRelationshipEntryVerificationResult(path.at(pathEntryIdx - 1))) {
          // this node has a relationship before it
          return nodeSuggestion;
        }
        // this node has no relationship before it
        return [SUGGESTION_ANY_RELATIONSHIP, nodeSuggestion];
      });

      return flatten(pathSuggestion);
    });

  return pathSuggestions;
};

export const isNodeEntryVerificationResult = (result: any): result is NodeEntryVerificationResult => {
  return (
    isArray(result?.labels) &&
    isString(result?.propertyName) &&
    isString(result?.propertyValue) &&
    isString(result?.propertyType)
  );
};

export const isRelationshipEntryVerificationResult = (result: any): result is RelationshipEntryVerificationResult => {
  return isArray(result) && !isEmpty(result) && result.every(isString);
};
