import { Tabs } from '@neo4j-ndl/react';
import type { TimeRangeSecurityLogSummary } from '@nx/state';
import { SECURITY_LOG_STATUS } from '@nx/state';
import { DataGridHelpers } from '@nx/ui';
import { random } from 'lodash-es';

import { useMetricsViewState } from '../../metrics/hooks';
import { TAB_OPTIONS } from '../shared/types';
import { LogsContextProvider } from './hooks/use-logs-context';
import { useRawFilters } from './hooks/use-raw-filters';
import { SecurityLogsDataGrid } from './security-logs-data-grid';
import { DEFAULT_SORT_SUMMARY } from './shared/constants';

function generateFakeQueryLog(): TimeRangeSecurityLogSummary[] {
  const queries = [];
  for (let i = 0; i < 10; i++) {
    queries.push({
      id: '1',
      message: 'logged in',
      from: new Date(),
      to: new Date(),
      logCount: random(1, 100),
      executingUser: 'neo4j',
      authenticatedUsers: 'neo4j',
      status: SECURITY_LOG_STATUS.INFO,
    });
  }
  return queries.sort((a, b) => b.logCount - a.logCount);
}

export const FakeSecurityLogs = () => {
  const { rawFilters, setRawFilters } = useRawFilters();
  const timeRangeFromFilters = rawFilters.timeRange;
  const checkedTimeRange = timeRangeFromFilters.startTime
    ? {
        startTime: timeRangeFromFilters.startTime,
        endTime: timeRangeFromFilters.endTime,
      }
    : undefined;
  const { chartContext } = useMetricsViewState({
    timeRangeDerived: checkedTimeRange,
  });

  return (
    <>
      <div className="logs m-4">
        <DataGridHelpers.Wrapper>
          <LogsContextProvider
            value={{
              setIsRequestDialogOpen: () => {
                /* eslint-disable-next-line no-empty-function */
              },
              selectedTab: 'SUMMARY',
              setSelectedTab: () => {
                /* eslint-disable-next-line no-empty-function */
              },
              rawFilters,
              setRawFilters,
              hasRequestedData: true,
              setHasRequestedData: () => {
                /* eslint-disable-next-line no-empty-function */
              },
              timeRange: {
                startTime: chartContext.timeRange.startTime,
                endTime: chartContext.timeRange.endTime,
              },
            }}
          >
            <div className="mx-4 mt-4 flex justify-between">
              <div className="flex items-center">
                <Tabs
                  value={'SUMMARY'}
                  onChange={() => {
                    /* eslint-disable-next-line no-empty-function */
                  }}
                  className="logs-tab-header w-fit"
                >
                  {TAB_OPTIONS.map((t) => (
                    <Tabs.Tab tabId={t.key} key={t.key}>
                      {t.text}
                    </Tabs.Tab>
                  ))}
                </Tabs>
              </div>
            </div>
            <Tabs.TabPanel value={'SUMMARY'} tabId="SUMMARY">
              <SecurityLogsDataGrid
                dataGridType={'Summary'}
                logs={generateFakeQueryLog()}
                securityLogsLoading={false}
                pageInfo={{ pageCount: 15, itemCount: 150 }}
                securityLogsError={undefined}
                selectedDbmsId={''}
                controlledPagination={{
                  pageIndex: 0,
                  pageSize: 10,
                }}
                controlledSorting={DEFAULT_SORT_SUMMARY}
              />
            </Tabs.TabPanel>
          </LogsContextProvider>
        </DataGridHelpers.Wrapper>
      </div>
    </>
  );
};
