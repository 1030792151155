import type { LDClient, ProviderConfig } from 'launchdarkly-react-client-sdk';
import { LDProvider as _LDProvider, useLDClient } from 'launchdarkly-react-client-sdk';

/**
 * LaunchDarkly provides two APIs to access flags:
 * 1. JavaScript SDK (https://docs.launchdarkly.com/sdk/client-side/javascript)
 * 2. React SDK (https://docs.launchdarkly.com/sdk/client-side/react)
 *
 *
 * In JavaScript SDK flags are accessed using `LDClient` instance.
 * React SDK is a wrapper around JavaScript SDK. `<LDProvider />` creates an instance of `LDClient`.
 * `useLDClient` hook is used to access the instance of `LDClient` in the components.
 *
 * To access flags via JavaScript SDK outside of React components,
 * we grab the instance of `LDClient` and make it available globally.
 * By doing this we can bridge the React SDK and non-React parts of the application (Redux, etc).
 */

export let ldClient: LDClient | undefined;

let ldClientReadyResolve: ((client: LDClient) => void) | undefined;

export const waitForLDClient = (): Promise<LDClient> => {
  return new Promise<LDClient>((resolve) => {
    if (ldClient) {
      resolve(ldClient);
      return;
    }

    ldClientReadyResolve = resolve;
  });
};

const LDClientExporter = ({ children }: { children: React.ReactNode }) => {
  ldClient = useLDClient();

  if (ldClient) {
    ldClientReadyResolve?.(ldClient);
  }

  return children;
};

type Props = ProviderConfig & {
  children?: React.ReactNode;
};

export function LDProvider({ children, ...props }: Props) {
  return (
    <_LDProvider {...props}>
      <LDClientExporter>{children}</LDClientExporter>
    </_LDProvider>
  );
}
