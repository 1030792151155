import type { Record } from 'neo4j-driver';

import type { Scene } from '../../types/scene';
import type { Nullable } from '../../types/utility';
import { isFalsy } from '../../types/utility';
import { isVersion219orGreater } from '../versions/versionUtils';

export const scenesMapper = (result?: { records?: Record[] }): Scene[] =>
  result?.records != null && result.records.length > 0
    ? result.records.map((record) => {
        const scene = record.get('s');
        const { id, name, numOfNodes, numOfRels, createdBy, createdAt, lastModified, roles, version } =
          scene.properties;
        return {
          id,
          name,
          createdBy,
          createdAt: new Date(createdAt.toNumber()),
          lastModified: new Date(lastModified.toNumber()),
          numOfNodes: numOfNodes.toInt(),
          numOfRels: numOfRels.toInt(),
          version,
          roles,
        };
      })
    : [];

export const sceneMapper = (result?: { records?: Record[] }): Nullable<Scene> => {
  if (result?.records != null && result.records.length > 0) {
    const scene = result.records[0]?.get('s');
    const {
      id,
      name,
      numOfNodes,
      numOfRels,
      createdBy,
      createdAt,
      lastModified,
      roles,
      nodesv2,
      relationshipsv2,
      visualisation,
      filters,
      ranges,
      gds,
      style,
      version,
    } = scene.properties;

    let { nodes = [], relationships = [] } = scene.properties;

    // In version 2.18.0 the nodes and relationships contains Ids and we want to keep them for 2.19.0 and later
    // In version 2.19.0 we introduced nodesv2 and relationshipsv2 which contains the elementIds
    // In the rest of the application this difference is hidden, the plan is that after the migration in one of the
    // future version we will overrides the "nodes"/"relationships" also in the database
    // Query for rolling back Scenes and Perspectives to 2.18.0
    // MATCH (n:_Bloom_Scene_)-[]-(m:_Bloom_Perspective_) SET n.version='2.18.0' SET m.version='2.18.0'
    // SET m.data = replace(m.data, '2.19.0', '2.18.0')
    if (isVersion219orGreater(version) && nodesv2 !== undefined && relationshipsv2 !== undefined) {
      nodes = nodesv2;
      relationships = relationshipsv2;
    }

    return {
      id,
      name,
      createdBy,
      createdAt: new Date(createdAt.toNumber()),
      lastModified: new Date(lastModified.toNumber()),
      numOfNodes: numOfNodes.toInt(),
      numOfRels: numOfRels.toInt(),
      roles,
      nodes: !isFalsy(nodes) ? JSON.parse(nodes) : nodes,
      relationships: !isFalsy(relationships) ? JSON.parse(relationships) : relationships,
      visualisation: !isFalsy(visualisation) ? JSON.parse(visualisation) : visualisation,
      filters: !isFalsy(filters) ? JSON.parse(filters) : filters,
      ranges: !isFalsy(ranges) ? JSON.parse(ranges) : ranges,
      gds: !isFalsy(gds) ? JSON.parse(gds) : gds,
      style: !isFalsy(style) ? JSON.parse(style) : style,
      version,
    };
  }
  return null;
};

export const perspectiveAndScenePermissionMapper = (result?: { records?: Record[] }) =>
  result?.records != null && result.records.length > 0
    ? {
        perspectiveId: result.records[0]?.get('perspectiveId'),
        userCanAccessScene: result.records[0]?.get('userCanAccessScene'),
        sceneExist: true,
      }
    : null;
