import { Button, ClipboardButton, LoadingSpinner, Typography } from '@neo4j-ndl/react';
import { INSTANCE_STATUS, consoleApi, useUnsafeAppContext } from '@nx/state';
import type { CreatedInstance, Project } from '@nx/state';
import { AuraLogo } from '@nx/ui';
import { useEffect, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';

type WaitingPageProps = {
  createdInstance: CreatedInstance;
  project: Project;
};

export const WaitingPage = ({ createdInstance, project }: WaitingPageProps) => {
  const { userHomePath } = useUnsafeAppContext();
  const { data: instance } = consoleApi.useGetInstanceQuery(createdInstance.id, {
    pollingInterval: 5000,
  });
  const [showVideo, setShowVideo] = useState(false);
  const navigate = useNavigate();
  const { id, password, boltUrl, name, username } = createdInstance;
  const credentialsFilename = `Neo4j-${id}-Created-${new Date().toISOString().slice(0, 10)}.txt`;
  const credentialsURL = useMemo(() => {
    const content: string =
      '# Wait 60 seconds before connecting using these details, or login to https://console.neo4j.io to validate the Aura Instance is available\n' +
      `NEO4J_URI=${boltUrl}\n` +
      `NEO4J_USERNAME=${username}\n` +
      `NEO4J_PASSWORD=${password}\n` +
      `AURA_INSTANCEID=${id}\n` +
      `AURA_INSTANCENAME=${name}\n`;
    const blob = new Blob([content], { type: 'text/plain' });
    return window.URL.createObjectURL(blob);
  }, [boltUrl, id, name, password, username]);
  useEffect(() => {
    if (instance?.instanceStatus === INSTANCE_STATUS.RUNNING) {
      navigate(userHomePath ?? `/projects/${project.id}/instances`);
    }
  }, [instance, navigate, project.id, userHomePath]);
  return (
    <div className="flex h-full w-full flex-wrap justify-center overflow-y-auto" data-testid="waiting-page">
      <div className="flex h-full basis-[940px] flex-col gap-9 p-6">
        <div className="self-center">
          <AuraLogo />
        </div>
        <div className="flex flex-col items-center gap-2 self-center">
          <div className="flex items-center gap-2">
            <LoadingSpinner size="large" />
            <h2>Creating your instance...</h2>
          </div>

          <Typography variant="body-large" className="text-neutral-text-weak">
            (this takes a couple of minutes)
          </Typography>
        </div>
        <div className="flex flex-col gap-4">
          <Typography variant="subheading-medium" className="text-neutral-text-weak">
            Download the admin credentials for your instance. These are only shown once.
          </Typography>
          <div className="bg-neutral-bg-weak flex flex-wrap items-center justify-between gap-4 rounded-lg p-6">
            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-2">
                <Typography variant="body-medium" className="text-neutral-text-weak">
                  Username:
                </Typography>
                <Typography
                  variant="code"
                  className="bg-neutral-bg-default border-neutral-border-weak h-5 w-fit rounded-sm border px-0.5"
                >
                  {username}
                </Typography>
              </div>
              <div className="flex items-center gap-2">
                <Typography variant="body-medium" className="text-neutral-text-weak">
                  Password:
                </Typography>
                <div className="flex items-center gap-1">
                  <Typography
                    variant="code"
                    className="bg-neutral-bg-default border-neutral-border-weak h-5 w-fit truncate rounded-sm border px-0.5"
                  >
                    {password}
                  </Typography>
                  <ClipboardButton textToCopy={password} size="small" />
                </div>
              </div>
            </div>
            <Button
              as="a"
              className="shrink-0"
              htmlAttributes={{
                href: credentialsURL,
                download: credentialsFilename,
                'data-testid': 'download-and-continue',
                onClick: () => setShowVideo(true),
              }}
            >
              Download to continue
            </Button>
          </div>
        </div>
        {showVideo && (
          <div className="flex min-h-[500px] basis-[65vh] flex-col gap-4">
            <Typography variant="subheading-medium" className="text-neutral-text-weak">
              While you wait, see what Neo4j Aura can do
            </Typography>
            <div className="bg-neutral-bg-weak h-full w-full content-center justify-items-center self-center rounded-lg px-4 py-14">
              <ReactPlayer
                title="Introduction to Neo4j"
                url="https://fast.wistia.com/embed/bl0phjaazv"
                width="100%"
                height="100%"
                config={{
                  wistia: {
                    options: {
                      fitStrategy: 'contain',
                      doNotTrack: true,
                      autoPlay: true,
                      endVideoBehavior: 'reset',
                      silentAutoPlay: true,
                      playbar: true,
                      seo: false,
                      wmode: 'transparent',
                      playerColor: '0A6190',
                      plugin: {
                        'captions-v1': {
                          onByDefault: true,
                          language: 'eng',
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
