import { Button } from '@neo4j-ndl/react';
import { type Project, useAppSelector, useConfigurationActions } from '@nx/state';

import { Consumption } from './consumption';
import { OldUsage } from './old-usage';
import { UsageHistoryUsage } from './usage-history-usage';

// Constant for the Date from which the Orb Consumption will become the source of truth.
// The exact date is not decided as of January 2025. The new Consumption table will only display data from
// this date, while the OldUsage table will only show data up to that date.
const ORB_REPORTING_CUTOFF_DATE = new Date(2025, 0, 1, 0, 0, 0);

type Props = {
  project: Project;
};

// Conditional display of the Consumption Report. There are 3 tables:
// 1. The "UsageHistoryUsage" which is the old component and will be deprecated soon
// 2. The new "Consumption" component which uses data from Orb
// 3. The "OldUsage" component which is based on the old component and uses the UsageHistory data
// Orb data has a "cutoff" date from which the data is available. In "Consumption" users can see that data
// up to the cutoff date. To see older data they can go to "OldUsage", but only up to the
// cutoff date. These 2 components are currently behind an FT. After releasing the new billing, "UsageHistoryUsage"
// will become deprecated.
export const Usage = ({ project }: Props) => {
  const selectedTable = useAppSelector((store) => store.configuration.selectedUsageTable);
  const { setSelectedUsageTable } = useConfigurationActions();
  const { capabilities } = project;
  const orbConsumptionReportingEnabled = capabilities.orb_consumption_reporting;

  const handleViewOlderData = () => {
    setSelectedUsageTable('old');
  };

  const handleViewNewData = () => {
    setSelectedUsageTable('new');
  };

  if (!orbConsumptionReportingEnabled) {
    return <UsageHistoryUsage project={project} />;
  }

  return (
    <>
      {selectedTable === 'new' ? (
        <Consumption project={project} orb_reporting_cutoff_date={ORB_REPORTING_CUTOFF_DATE} />
      ) : (
        <OldUsage project={project} orb_reporting_cutoff_date={ORB_REPORTING_CUTOFF_DATE} />
      )}
      {selectedTable === 'new' ? (
        <Button className="mt-4" onClick={handleViewOlderData} size="small" fill="outlined">
          View older usage data
        </Button>
      ) : (
        <Button className="mt-4" onClick={handleViewNewData} size="small" fill="outlined">
          Return to new consumption view
        </Button>
      )}
    </>
  );
};
