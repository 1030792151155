import { groupBy, size } from 'lodash-es';
import { v4 as uuidV4 } from 'uuid';

import { log } from '../../services/logging';
import { fetchAllPerspectives, replacePerspectiveNodeId } from '../../services/perspectives';
import { getCompatibleDatabases, getDbmsId, getIsFeatureAvailable } from '../connections/connectionsDuck';

class PluginPersistor {
  constructor() {
    this.previousState = {};
  }

  async loadFromStorage({ store, payload }) {
    const { getState } = store;
    const state = getState();

    if (state?.connections?.availableProcedures && getIsFeatureAvailable(state, 'perspectiveSharing')) {
      const dbmsId = getDbmsId(state);
      const databases = getCompatibleDatabases(state);

      payload.perspectives = payload.perspectives || {};
      const perspState = payload.perspectives;

      let perspectiveList = perspState.perspectives || [];
      perspectiveList = perspectiveList.filter(({ isPlugin }) => !isPlugin);

      const pluginPerspectives = await fetchAllPerspectives({ dbmsId, databases });

      // make sure perspective id is unique, if not, re-generate the id
      const perspectivesById = groupBy(pluginPerspectives, (perspective) => perspective.id);
      for (const perspectives of Object.values(perspectivesById)) {
        if (size(perspectives) > 1) {
          // when db is backup-ed and restored, the restored perspective preserves the original id
          for (let idx = 1; idx < size(perspectives); ++idx) {
            const { id } = perspectives[idx];
            const newId = uuidV4();
            perspectives[idx].id = newId;
            await replacePerspectiveNodeId(id, newId, perspectives[idx].dbName);
          }
        }
      }

      if (pluginPerspectives.error) {
        log.error(pluginPerspectives.error);
        throw pluginPerspectives.error;
      }

      if (pluginPerspectives.length > 0) {
        payload.perspectives.perspectives = [...perspectiveList, ...pluginPerspectives];
      }
    }

    return payload;
  }

  shouldLoadState({ action }) {
    return !action;
  }
}

export default PluginPersistor;
