import { isNotNullish } from '@nx/stdlib';

import type { GraphDataModelState } from '../../types';
import { setDataSourceId, setImportModelId, setImportModelName } from '../reducers/configurations';
import { initialiseDataModel } from '../reducers/data-model';
import { setVisualisation } from '../reducers/visualisation';
import type { AppDispatch } from '../store';

export const loadModel =
  (dispatch: AppDispatch) =>
  (model: GraphDataModelState): void => {
    dispatch(initialiseDataModel(model.dataModel));
    dispatch(setVisualisation(model.visualisation));
    if (isNotNullish(model.importModelId)) {
      dispatch(setImportModelId(model.importModelId));
    }
    if (isNotNullish(model.importModelName)) {
      dispatch(setImportModelName(model.importModelName));
    }
    if (isNotNullish(model.dataSourceId)) {
      dispatch(setDataSourceId(model.dataSourceId));
    }
  };
