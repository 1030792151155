import type { QueryLogAggregationSort, QueryLogSort } from '@nx/state';
import { SORT_ORDER } from '@nx/state';
import type { ColumnSort } from '@tanstack/react-table';

import { LOCALE_TAG } from '../../metrics/shared/constants';
import type { TabText } from './types';
import { COLUMN_TO_QLA_SORT, COLUMN_TO_QL_SORT } from './types';

export function capitalizeFirstLowerRest(s: string): string {
  return s[0]?.toUpperCase() + s.slice(1).toLowerCase();
}

export const formatTimestamp = (d: Date) =>
  new Intl.DateTimeFormat(LOCALE_TAG, {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    hourCycle: 'h23',
    minute: '2-digit',
    second: '2-digit',
  })
    .format(d)
    .replace(/,/g, '');

// toISOString always returns GMT+00:00 (Zulu time)
export const toLocalISOString = (date: Date) => {
  const timezoneOffsetMs = date.getTimezoneOffset() * 60 * 1000;
  return new Date(date.getTime() - timezoneOffsetMs).toISOString();
};

export type LogOrAggregationSortInput<T extends TabText> = T extends 'Details' ? QueryLogSort : QueryLogAggregationSort;

export const mapControlledSorting = <T extends TabText>(
  rules: ColumnSort[],
  tableType: T,
): LogOrAggregationSortInput<T>[] => {
  if (rules.length === 0) {
    return [];
  }

  return rules
    .map(({ id, desc }) => {
      const map = tableType === 'Details' ? COLUMN_TO_QL_SORT : COLUMN_TO_QLA_SORT;
      const property = map[id];

      if (typeof desc !== 'boolean') {
        return null;
      }

      const sortOrder = desc ? SORT_ORDER.DESCENDING : SORT_ORDER.ASCENDING;

      return { property, sortOrder };
    })
    .filter((sortItem): sortItem is LogOrAggregationSortInput<T> => Boolean(sortItem));
};

// check if a date is before a reference date, optionally extend reference date in past/future
type TimeUnits = { hours?: number };
export const dateEarlierThan = (testDate: Date, referenceDate: Date, referenceDateModifiers: TimeUnits = {}) => {
  const { hours = 0 } = referenceDateModifiers;
  const delta = hours * 60 * 60 * 1000;

  return testDate.getTime() < referenceDate.getTime() + delta;
};

export const datesWithin24H = (startDate: Date, endDate: Date) => dateEarlierThan(endDate, startDate, { hours: 24.1 });

export const isLetter = (s: string) => s.length === 1 && Boolean(s.match(/[a-z]/i));

export const isDigit = (s: string) => s.length === 1 && Boolean(s.match(/\d/));
