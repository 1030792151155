import type { Instance } from '@nx/state';
import { consoleApi, useActiveProject, useConnection, useOpsContext } from '@nx/state';
import { isNonEmptyString } from '@nx/stdlib';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const defaultFilter: (i: Instance) => boolean = () => true;

export const useInstanceSelectorProps = (filter = defaultFilter) => {
  const activeProject = useActiveProject();
  const { selectedInstanceId, setSelectedInstance } = useOpsContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { metadata: connectionMetadata } = useConnection();
  const [instances, setInstances] = useState<Instance[]>([]);

  const handleSelectInstanceId = useCallback(
    (instanceId?: string) => {
      if (isNonEmptyString(instanceId)) {
        setSelectedInstance(instanceId);
        setSearchParams((oldParams) => {
          const newParams = new URLSearchParams(oldParams);
          newParams.set('instance', instanceId);
          return newParams;
        });
      }
    },
    [setSearchParams, setSelectedInstance],
  );

  const listInstancesRes = consoleApi.useListInstancesQuery(activeProject.id, {
    refetchOnMountOrArgChange: true,
  });
  const [getInstance, getInstanceRes] = consoleApi.useLazyGetInstanceQuery();

  // Filter out instance summaries that are not in the list of (filtered) instances
  const instanceSummaries = useMemo(
    () =>
      (listInstancesRes.data ?? []).filter(
        (instanceSummary) => instances.find((instance) => instance.id === instanceSummary.id) !== undefined,
      ),
    [instances, listInstancesRes.data],
  );

  useEffect(() => {
    async function fetchDetailedInstances() {
      if (listInstancesRes.data && listInstancesRes.data.length > 0) {
        const promises = listInstancesRes.data.map(({ id }) => getInstance(id).unwrap());
        const detailedInstances = await Promise.all(promises);
        const filteredInstances = detailedInstances.filter(filter);
        setInstances(filteredInstances);
      }
    }
    void fetchDetailedInstances();
  }, [filter, getInstance, listInstancesRes.data]);

  useEffect(() => {
    if (
      instanceSummaries.length > 0 &&
      (selectedInstanceId === undefined || !instanceSummaries.find((i) => i.id === selectedInstanceId))
    ) {
      const instanceIdFromSearch = searchParams.get('instance');
      const instanceFromSearch = instanceSummaries.find((i) => i.id === instanceIdFromSearch);
      const instanceFromConnection = instanceSummaries.find((i) => i.id === connectionMetadata?.auraId);
      const [firstInstance] = instanceSummaries;
      handleSelectInstanceId(instanceFromSearch?.id ?? instanceFromConnection?.id ?? firstInstance?.id);
    }
  }, [connectionMetadata?.auraId, handleSelectInstanceId, instanceSummaries, searchParams, selectedInstanceId]);

  const [selectedInstance, selectedInstanceSummary] = useMemo(() => {
    return instanceSummaries.length > 0
      ? ([
          instances.find((i) => i.id === selectedInstanceId),
          instanceSummaries.find((i) => i.id === selectedInstanceId),
        ] as const)
      : [];
  }, [instances, instanceSummaries, selectedInstanceId]);

  return {
    isInstancesLoading:
      listInstancesRes.isLoading ||
      getInstanceRes.isLoading ||
      listInstancesRes.isFetching ||
      getInstanceRes.isFetching,
    instanceSummaries,
    instances,
    selectedInstanceSummary,
    selectedInstance,
    setSelectedInstanceId: handleSelectInstanceId,
  };
};
