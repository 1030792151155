import { useDispatch, useSelector } from '../context';
import * as Sidebar from '../slices/sidebar-slice';

export function useMainSidebarActions() {
  const dispatch = useDispatch();
  const { isOpen, isTemporaryOpen } = useSelector((state) => state.sidebar.mainSidebar);

  return {
    toggleMainSidebar: (...args: Parameters<typeof Sidebar.toggleMainSidebar>) => {
      return dispatch(Sidebar.toggleMainSidebar(...args));
    },
    enterMainSidebar: () => {
      if (isOpen && !isTemporaryOpen) {
        return null;
      }
      return dispatch(Sidebar.enterMainSidebar());
    },
    leaveMainSidebar: () => {
      if (isOpen && !isTemporaryOpen) {
        return null;
      }
      return dispatch(Sidebar.leaveMainSidebar());
    },
  };
}
