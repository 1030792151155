import type { CreateLogForwarding, EditLogForwarding, LogForwarding, LogForwardingIdentity } from '../console.types';
import {
  transformCreateLogForwardingPayload,
  transformEditLogForwardingPayload,
  unTransformLogForwardingArray,
  unTransformLogForwardingIdentitiesArray,
} from '../transformers/log-forwarding';
import type { Builder } from './types';

export const logForwardingEndpoints = (builder: Builder) => ({
  listLogForwarding: builder.query<LogForwarding[], string>({
    query: (projectId) => ({
      url: `/tenants/${projectId}/log-forwarding`,
      method: 'GET',
    }),
    transformResponse: unTransformLogForwardingArray,
    providesTags: (_, __, tenantId) => [{ type: 'LogForwardingList', id: tenantId }],
  }),
  logForwardingIdentities: builder.query<LogForwardingIdentity[], string>({
    query: (projectId) => ({
      url: `/tenants/${projectId}/log-forwarding-identities`,
      method: 'GET',
    }),
    transformResponse: unTransformLogForwardingIdentitiesArray,
    providesTags: (_, __, tenantId) => [{ type: 'LogForwardingIdentities', id: tenantId }],
  }),
  deleteLogForwarding: builder.query<void, { projectId: string; logForwardingId: string }>({
    query: ({ projectId, logForwardingId }) => ({
      url: `/tenants/${projectId}/log-forwarding/${logForwardingId}`,
      method: 'DELETE',
    }),
  }),
  createLogForwarding: builder.query<void, { projectId: string; payload: CreateLogForwarding }>({
    query: ({ projectId, payload }) => ({
      url: `/tenants/${projectId}/log-forwarding`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: transformCreateLogForwardingPayload(payload),
    }),
  }),
  editLogForwarding: builder.query<void, { projectId: string; id: string; payload: EditLogForwarding }>({
    query: ({ projectId, id, payload }) => ({
      url: `/tenants/${projectId}/log-forwarding/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: transformEditLogForwardingPayload(payload),
    }),
  }),
});
