import type { Perspective } from '../../types/perspective';
import type { RootState } from '../types';

const NAME = 'perspectives';

export const getPerspective = (state: RootState): Perspective | undefined =>
  getPerspectiveById(state)(state.currentPerspectiveId);

export const getPerspectiveById =
  (state: RootState) =>
  (perspectiveId: string): Perspective | undefined =>
    state[NAME]?.perspectives?.find(({ id }) => id === perspectiveId);
