import { getFlag } from '@nx/launch-darkly-service';
import type { UnknownAction } from '@reduxjs/toolkit';
import { createListenerMiddleware } from '@reduxjs/toolkit';
import { isEqual } from 'lodash-es';

import { makeSelectFeatureFlag } from '../../selectors';
import type { AppDispatch, RootState } from '../../store';
import { selectCapability } from '../capabilities-slice';
import { selectActiveDatabaseName } from '../connections/selectors';
import { updateDataSummary } from '../data-summary/data-summary-slice';
import { graphStylingApi } from './graph-styling.api';

const shouldSyncStylingPrecedenceToStorageApi = (state: RootState) => {
  const isStorageApiEnabled = selectCapability(state, {
    key: 'framework:storage-api',
  });
  const queryLaunchStorageApi = getFlag('queryLaunchStorageApi', false);

  const isFeatureFlagEnabled = makeSelectFeatureFlag('nx:graph-styling-shared-storage')(state);
  return isStorageApiEnabled && (isFeatureFlagEnabled || queryLaunchStorageApi);
};

const stylingPrecedenceMiddleware = createListenerMiddleware<RootState>();
const startListening = stylingPrecedenceMiddleware.startListening.withTypes<RootState, AppDispatch>();

startListening({
  predicate: (action: UnknownAction): action is ReturnType<typeof updateDataSummary.fulfilled> =>
    action.type === updateDataSummary.fulfilled.type,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState();
    const { payload } = action;
    const database = selectActiveDatabaseName(state);

    if (database !== undefined && shouldSyncStylingPrecedenceToStorageApi(state)) {
      const graphStyling = await listenerApi.dispatch(graphStylingApi.endpoints.getGraphStyle.initiate({ database }));
      const { stylingPrecedence = [] } = graphStyling.data ?? {};
      const updatedStylingPreference = [...stylingPrecedence];

      for (const label of payload.labels) {
        if (!stylingPrecedence.includes(label)) {
          updatedStylingPreference.push(label);
        }
      }

      if (!isEqual(updatedStylingPreference, stylingPrecedence)) {
        await listenerApi.dispatch(
          graphStylingApi.endpoints.setGraphStyle.initiate({
            updateGraphStyleBody: {
              database,
              stylingPrecedence: updatedStylingPreference,
            },
          }),
        );
      }
    }
  },
});

export { stylingPrecedenceMiddleware };
