import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { frameworkInit } from '@nx/ui';

// Using dynamic import breaks signals that are emitted when app is rendered
// for the first time. This happens due to signal listeners being registered
// on next tick at earliest or when modules is downloaded at latest.
import { Console } from './console-layout';
import {
  auraManagementApiUrl,
  getApiUrl,
  getEnv,
  getFirstEnv,
  useMockApi,
  useMockFirstTimeUser,
  useMockPlanType,
} from './get-aura-api-url';

const logger = createLogger(APP_SCOPE.framework);

const auraEnvironment = getEnv(import.meta.env.R_VITE_AURA_ENVIRONMENT);
const oldConsoleFrontendUrl =
  getEnv(import.meta.env.R_VITE_AURA_OLD_CONSOLE_URL) ?? auraManagementApiUrl?.replace('app-ingress', 'console');

const oidcAuthority = getFirstEnv(import.meta.env.R_VITE_OIDC_AUTHORITY, import.meta.env.VITE_OIDC_AUTHORITY);
const oidcClientId = getFirstEnv(import.meta.env.R_VITE_OIDC_CLIENT_ID, import.meta.env.VITE_OIDC_CLIENT_ID);
const oidcAudience = getFirstEnv(import.meta.env.R_VITE_OIDC_AUDIENCE, import.meta.env.VITE_OIDC_AUDIENCE);
const segmentApiKey = getFirstEnv(import.meta.env.R_VITE_SEGMENT_API_KEY, import.meta.env.VITE_SEGMENT_API_KEY);
const launchDarklyClientSideId = getFirstEnv(
  import.meta.env.R_VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID,
);

const location = new URL(window.location.href);
const skipReconnect = location.pathname === '/login-callback' || location.pathname === '/logout-callback';

void frameworkInit(<Console />, {
  appManifest: {
    name: 'upx',
    layout: 'upx',
    version: import.meta.env.VITE_APP_VERSION,
  },
  enableAppUpdateCheck: true,
  logger,
  configuration: {
    windowTitle: 'Neo4j Aura',
    basePath: '/tools',
    fallbackRoute: '/tools/query',
    ssoRedirectPath: '/tools/query',
    guidesBaseUrl: 'https://neo4j.github.io/console-guides',
    onboarding: {
      route: '/onboarding',
    },
    tools: {
      explore: {
        displayName: 'Explore',
        route: '/tools/explore',
      },
      import: {
        displayName: 'Import',
        route: '/tools/import',
      },
      query: {
        displayName: 'Query',
        route: '/tools/query',
      },
      dashboards: {
        displayName: 'Dashboards',
        route: '/tools/dashboards',
      },
      guides: {
        displayName: 'Guides',
        route: '/guides',
      },
      conversations: {
        displayName: 'Conversations',
        route: '/tools/conversations',
      },
    },
    sentry: {
      dsn: import.meta.env.VITE_SENTRY_DSN,
      appVersion: import.meta.env.VITE_APP_VERSION,
      releasePrefix: 'console',
    },
    segment: {
      apiKey: segmentApiKey,
      eventPrefix: undefined,
    },
    launchDarkly: {
      clientSideId: launchDarklyClientSideId,
    },
    aura: {
      environment: auraEnvironment,
      consoleApiUrl: getApiUrl('console'),
      opsManagerApiUrl: getApiUrl('opsManager'),
      storageApiUrl: getApiUrl('storage'),
      importApiUrl: getApiUrl('import'),
      tokenApiUrl: getApiUrl('token'),
      graphqlApiUrl: getApiUrl('graphql'),
      oldConsoleFrontendUrl,
      dataScienceApiUrl: getApiUrl('dataScience'),
    },
  },
  capabilities: [
    'framework:seamless-connection',
    'guides:enabled',
    'framework:storage-api',
    'framework:connection-bar',
    'explore:new-upx-header',
    'framework:app-controlled-settings',
    'framework:theme-switcher',
    useMockApi && 'framework:mock-api',
    useMockPlanType === 'self-serve' && 'framework:mock-self-serve',
    useMockPlanType === 'enterprise' && 'framework:mock-enterprise',
    useMockFirstTimeUser === '1' && 'framework:mock-first-time-user',
    'explore:default-perspective',
  ],
  auth: {
    authority: oidcAuthority,
    clientId: oidcClientId,
    audience: oidcAudience,
    loginRequired: true,
  },
  skipReconnect,
});
