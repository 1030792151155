import { consoleApi, useAuth } from '@nx/state';
import { isNullish } from '@nx/stdlib';

import { currentTsAndCsVersion } from './terms-and-conditions-content';

export function useTermsAndConditionsStatus() {
  const auth = useAuth();
  const userDetailsRes = consoleApi.useGetUserDetailsQuery(undefined, {
    skip: isNullish(auth.user),
  });
  return {
    isLoading: userDetailsRes.isUninitialized || userDetailsRes.isLoading,
    isAccepted: Boolean(userDetailsRes.data?.acceptedTermsAndConditions === currentTsAndCsVersion()),
    acceptedVersion: userDetailsRes.data?.acceptedTermsAndConditions,
    marketingChoiceRequired: userDetailsRes.data?.marketingChoiceRequired,
  };
}
