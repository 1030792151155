import { Banner, Button, Dialog, Tabs } from '@neo4j-ndl/react';
import type { DataSourceModalArgs } from '@nx/state';
import {
  MODAL_TYPE,
  handleConnectionAbort,
  useConnection,
  useConnectionSelectorModal,
  useCurrentModal,
  useModalClose,
} from '@nx/state';
import { URLs } from '@nx/stdlib';
import cx from 'classnames';
import { useId } from 'react';

import { CONNECTION_PARAMS } from '../connection-form/connection-form-utils';

type DataSource = { name: string; element: JSX.Element; title?: string };

type DataSourceModalProps = {
  dataSources: DataSource[];
} & DataSourceModalArgs;

const DataSources = ({ dataSources, connectionDetails, showDataModificationWarning = false }: DataSourceModalProps) => {
  const modal = useCurrentModal();
  const connectionModal = useConnectionSelectorModal();

  if (modal?.type !== MODAL_TYPE.DATA_SOURCE) {
    throw new Error('Invalid modal type');
  }

  const tabId =
    modal.modalTabId ??
    (connectionDetails && dataSources.some(({ name }) => name === 'Remote') ? 'Remote' : dataSources[0]?.name);

  const setTabId = (id: string) => {
    connectionModal.open({ ...modal, modalTabId: id });
  };

  const currentSource = dataSources.find(({ name }) => name === tabId) ?? dataSources[0];

  if (dataSources.length === 0) {
    return (
      <Banner type="danger" usage="inline">
        No data sources found
      </Banner>
    );
  }

  if (currentSource === undefined) {
    return (
      <Banner type="danger" usage="inline">
        Data source not found
      </Banner>
    );
  }

  if (tabId === undefined) {
    return (
      <Banner type="danger" usage="inline">
        Unknown data source
      </Banner>
    );
  }

  if (dataSources.length === 1) {
    return dataSources[0]?.element;
  }

  return (
    <>
      <Tabs fill="filled" className="mb-8 mt-2 flex" onChange={setTabId} value={tabId}>
        {dataSources.map(({ name, title }) => (
          <Tabs.Tab tabId={name} key={name}>
            {title ?? name}
          </Tabs.Tab>
        ))}
      </Tabs>
      {showDataModificationWarning ? (
        <Banner
          hasIcon
          type="warning"
          title="Connection to an instance is required to run queries or import data in this guide."
          description="Skipping this step will still allow you to view the guide."
          className="mb-6"
          usage="inline"
        />
      ) : null}
      {dataSources.map(({ name, element }) => (
        <Tabs.TabPanel tabId={name} value={tabId} key={name}>
          {element}
        </Tabs.TabPanel>
      ))}
    </>
  );
};

export function DataSourceModal(props: DataSourceModalProps) {
  const dialogHeaderId = useId();
  const closeModal = useModalClose();
  const connection = useConnection();

  function handleClose() {
    handleConnectionAbort();
    closeModal();
    URLs.cleanSearchParamsAndHash([...CONNECTION_PARAMS]);
  }

  const isSingleDataSource = props.dataSources.length === 1;

  return (
    <Dialog
      size="unset"
      modalProps={{
        'aria-labelledby': dialogHeaderId,

        // fix dialog position to avoid jumping on tab change due to size difference
        className: cx(
          { 'w-[800px]': isSingleDataSource },
          { 'w-[80%] max-w-[1500px]': !isSingleDataSource },
          'mx-auto mt-12 p-10 align-top',
        ),
      }}
      isOpen
      onClose={handleClose}
    >
      <Dialog.Header
        htmlAttributes={{
          id: dialogHeaderId,
        }}
      >
        Connect to instance
      </Dialog.Header>
      <Dialog.Content>
        <DataSources {...props} />
      </Dialog.Content>
      {props.allowSkippingDataSource === true && (
        <Dialog.Actions>
          <Button
            isDisabled={connection.status.isConnecting || connection.status.isConnected}
            fill="outlined"
            color="neutral"
            onClick={() => {
              closeModal();
              connection.skipConnection();
            }}
          >
            Continue
          </Button>
        </Dialog.Actions>
      )}
    </Dialog>
  );
}
