import { Banner, Button, LoadingSpinner, Tooltip, Typography } from '@neo4j-ndl/react';
import type { Project, TIER, TrafficConfig } from '@nx/state';
import { ACTION, TRAFFIC_STATUS, consoleApi, useActiveProject, usePermissions } from '@nx/state';
import { BackButton } from '@nx/ui';
import { useState } from 'react';

import { TrafficConfigDialog } from './dialog';
import { TrafficConfigsTable } from './table';
import { isSameTrafficConfig } from './utils';

type NetworkContentProps = {
  project: Project;
  createOpen: boolean;
  onCreateClose: () => void;
};

/**
 * This component mostly exists to make sure we only trigger
 * the traffic configs query after we have loaded in our project
 */
const NetworkContent = ({ project, createOpen, onCreateClose }: NetworkContentProps) => {
  const {
    data: trafficConfigs = [],
    isError,
    isLoading,
  } = consoleApi.useListTrafficConfigsQuery({ projectId: project.id }, { pollingInterval: 5000 });

  const [createdTrafficConfig, setCreatedTrafficConfig] = useState<TrafficConfig | null>(null);

  const handleClose = () => {
    setCreatedTrafficConfig(null);
    onCreateClose();
  };

  const handleCreateSuccess = (config: TrafficConfig) => {
    setCreatedTrafficConfig(config);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center">
        <LoadingSpinner size="large" />
      </div>
    );
  }

  const liveCreatedTrafficConfig = createdTrafficConfig
    ? trafficConfigs.find((c) => isSameTrafficConfig(createdTrafficConfig, c))
    : undefined;

  /**
   * How do we determine if a config has been configured by a user or not?
   * - If they go through the create flow, the private traffic is enabled
   * - They cannot disable private traffic by editing the config, they can
   *   only disable public traffic
   * - By default, public is enabled and private is disabled
   * - To disable private traffic they have to "delete" the config
   * - Therefore, if private traffic is enabled, then the user has configured
   *   the network config
   * - Private traffic can be disabled, but the status will still be `deleting`
   * - So we want to show all configs where private traffic is not `deleted`
   *   as that means all enabled private traffic configs and all disabled
   *   private traffic configs that are still deleting
   */
  const configuredConfigs = trafficConfigs.filter((c) => c.status.privateTraffic !== TRAFFIC_STATUS.DELETED);

  const existingTierRegions: Partial<Record<TIER, string[]>> = configuredConfigs.reduce(
    (acc: Record<string, string[]>, config) => {
      if (!(config.tier in acc)) {
        acc[config.tier] = [config.region];
      } else {
        acc[config.tier]?.push(config.region);
      }
      return acc;
    },
    {},
  );

  return (
    <>
      {isError && <Banner type="danger" description="Error loading network access configurations" usage="inline" />}
      <TrafficConfigsTable project={project} configs={configuredConfigs} />
      {createOpen && (
        <TrafficConfigDialog
          title="New network access configuration"
          onClose={handleClose}
          project={project}
          trafficConfig={liveCreatedTrafficConfig}
          existingTrafficConfigs={trafficConfigs}
          existingTierRegions={existingTierRegions}
          onSuccess={handleCreateSuccess}
        />
      )}
    </>
  );
};

interface PermissionDeniedProps
  extends Omit<React.ComponentProps<typeof Banner>, 'type' | 'isCloseable' | 'onClose' | 'title' | 'description'> {
  title?: React.ComponentProps<typeof Banner>['title'];
  project: Project;
}

const PermissionDenied = ({ title = 'Permission denied', project, ...rest }: PermissionDeniedProps) => {
  return (
    <Banner
      type="warning"
      title={title}
      description={
        <div>
          <p>
            You must be an admin of the <strong>{project.name}</strong> project to read network access configurations.
          </p>
        </div>
      }
      {...rest}
      usage="inline"
    />
  );
};

const FeatureDisabled = ({ project }: { project: Project }) => {
  return (
    <Banner
      type="info"
      title="Feature disabled"
      description={
        <div>
          <p>Network access configuration is not available for project {project.name}.</p>
        </div>
      }
      usage="inline"
      htmlAttributes={{
        'data-testid': 'network-access-feature-disabled-alert',
      }}
    />
  );
};

export const Network = () => {
  const activeProject = useActiveProject();

  const [createOpen, setCreateOpen] = useState(false);
  const { isAllowed } = usePermissions();

  const handleNewNetwork = () => setCreateOpen(true);
  const handleClose = () => setCreateOpen(false);

  const canCreateTrafficConfig =
    activeProject.capabilities.traffic_config &&
    isAllowed(ACTION.UPDATE, `namespaces/${activeProject.id}/traffic-configs/tiers/*/regions/*`);

  const canReadTrafficConfigs = isAllowed(ACTION.READ, `namespaces/${activeProject.id}/traffic-configs`);

  return (
    <div className="h-full overflow-auto p-4">
      <BackButton to="../settings" />
      <div className="mb-6 flex items-center justify-between px-2 pt-2">
        <Typography variant="h2">Network Access</Typography>
        <div className="flex gap-4">
          <Tooltip type="simple" isPortaled={false}>
            <Tooltip.Trigger hasButtonWrapper>
              <Button
                onClick={handleNewNetwork}
                isDisabled={!canCreateTrafficConfig}
                htmlAttributes={{ 'data-testid': 'create-traffic-config' }}
              >
                New network access configuration
              </Button>
            </Tooltip.Trigger>
            {!canCreateTrafficConfig && (
              <Tooltip.Content className="max-w-sm">
                <p>
                  You need to be an admin of the <strong>{activeProject.name}</strong> project to create a network
                  access configuration.
                </p>
              </Tooltip.Content>
            )}
          </Tooltip>
        </div>
      </div>
      {!activeProject.capabilities.traffic_config && <FeatureDisabled project={activeProject} />}
      {!canReadTrafficConfigs && <PermissionDenied project={activeProject} />}
      {canReadTrafficConfigs && activeProject.capabilities.traffic_config && (
        <NetworkContent project={activeProject} createOpen={createOpen} onCreateClose={handleClose} />
      )}
    </div>
  );
};
