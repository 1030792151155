import type { Algorithm, AlgorithmOption, AlgorithmType, EveryAlgorithm, Procedure } from './types';

// Orientations
export const NATURAL_ORIENTATION = 'NATURAL_ORIENTATION';
export const REVERSED_ORIENTATION = 'REVERSED_ORIENTATION';
export const UNDIRECTED_ORIENTATION = 'UNDIRECTED_ORIENTATION';

// Style rule types
export const SIZE_SCALING = 'SIZE_SCALING';
export const COLOR_GRADIENT = 'COLOR_GRADIENT';
export const UNIQUE_COLORS = 'UNIQUE_COLORS';

// Procedures
export const CREATE_PROJECTION = 'CREATE_PROJECTION';
export const DELETE_PROJECTION = 'DELETE_PROJECTION';

// Algorithms
export const EIGENVECTOR_CENTRALITY = 'EIGENVECTOR_CENTRALITY';
export const PAGE_RANK_CENTRALITY = 'PAGE_RANK_CENTRALITY';
export const DEGREE_CENTRALITY = 'DEGREE_CENTRALITY';
export const BETWEENNESS_CENTRALITY = 'BETWEENNESS_CENTRALITY';
export const CLOSENESS = 'CLOSENESS';
export const LOUVAIN = 'LOUVAIN';
export const LABEL_PROPAGATION = 'LABEL_PROPAGATION';
export const TRIANGLE_COUNT = 'TRIANGLE_COUNT';
export const WCC = 'WCC';

// Algorithm types
export const CENTRALITY_ALGO = 'CENTRALITY_ALGO';
export const COMMUNITY_ALGO = 'COMMUNITY_ALGO';

export const hasRelationshipWeightProperty = [
  BETWEENNESS_CENTRALITY,
  DEGREE_CENTRALITY,
  EIGENVECTOR_CENTRALITY,
  PAGE_RANK_CENTRALITY,
  LOUVAIN,
  LABEL_PROPAGATION,
  WCC,
] as const;

export type ProcedureKey = typeof CREATE_PROJECTION | typeof DELETE_PROJECTION | EveryAlgorithm;
export const gdsProceduresMap: Readonly<Record<ProcedureKey, Procedure[]>> = {
  [CREATE_PROJECTION]: ['gds.graph.create.cypher', 'gds.graph.project.cypher'],
  [DELETE_PROJECTION]: ['gds.graph.drop'],
  [BETWEENNESS_CENTRALITY]: ['gds.betweenness.stream'],
  [EIGENVECTOR_CENTRALITY]: ['gds.eigenvector.stream'],
  [PAGE_RANK_CENTRALITY]: ['gds.pageRank.stream'],
  [DEGREE_CENTRALITY]: ['gds.degree.stream'],
  [CLOSENESS]: ['gds.beta.closeness.stream'],
  [LOUVAIN]: ['gds.louvain.stream'],
  [LABEL_PROPAGATION]: ['gds.labelPropagation.stream'],
  [TRIANGLE_COUNT]: ['gds.triangleCount.stream'],
  [WCC]: ['gds.wcc.stream'],
};

export const algoTypeDisplayMap: Readonly<Record<AlgorithmType, string>> = {
  [CENTRALITY_ALGO]: 'Centrality',
  [COMMUNITY_ALGO]: 'Community detection',
};

export const algorithmOptionsMap: Readonly<Record<Algorithm, AlgorithmOption>> = {
  [BETWEENNESS_CENTRALITY]: {
    key: BETWEENNESS_CENTRALITY,
    name: 'Betweenness Centrality',
    algoType: CENTRALITY_ALGO,
    link: 'https://neo4j.com/docs/graph-data-science/current/algorithms/betweenness-centrality/',
    desc: 'Calculates shortest paths between all pairs of nodes in a graph.',
    available_orientations: [NATURAL_ORIENTATION, REVERSED_ORIENTATION, UNDIRECTED_ORIENTATION],
  },
  [DEGREE_CENTRALITY]: {
    key: DEGREE_CENTRALITY,
    name: 'Degree Centrality',
    algoType: CENTRALITY_ALGO,
    link: 'https://neo4j.com/docs/graph-data-science/current/algorithms/degree-centrality/',
    desc: 'Measures the number of incoming or outgoing (or both) relationships from a node, depending on the orientation of a relationship projection',
    available_orientations: [NATURAL_ORIENTATION, REVERSED_ORIENTATION, UNDIRECTED_ORIENTATION],
  },
  [EIGENVECTOR_CENTRALITY]: {
    key: EIGENVECTOR_CENTRALITY,
    name: 'Eigenvector Centrality',
    algoType: CENTRALITY_ALGO,
    link: 'https://neo4j.com/docs/graph-data-science/current/algorithms/eigenvector-centrality/',
    desc: 'An algorithm that measures the transitive influence of nodes. Relationships originating from high-scoring nodes contribute more to the score of a node than connections from low-scoring nodes.',
    available_orientations: [NATURAL_ORIENTATION, REVERSED_ORIENTATION, UNDIRECTED_ORIENTATION],
  },
  [PAGE_RANK_CENTRALITY]: {
    key: PAGE_RANK_CENTRALITY,
    name: 'Page Rank Centrality',
    algoType: CENTRALITY_ALGO,
    link: 'https://neo4j.com/docs/graph-data-science/current/algorithms/page-rank/',
    desc: 'Measures the importance of each node within the graph, based on the number incoming relationships and the importance of the corresponding source nodes',
    available_orientations: [NATURAL_ORIENTATION, REVERSED_ORIENTATION, UNDIRECTED_ORIENTATION],
  },
  [LABEL_PROPAGATION]: {
    key: LABEL_PROPAGATION,
    name: 'Label Propagation',
    algoType: COMMUNITY_ALGO,
    link: 'https://neo4j.com/docs/graph-data-science/current/algorithms/label-propagation/',
    desc: 'A fast algorithm for finding communities in a graph. It detects these communities using network structure alone as its guide, and does not require a pre-defined objective function or prior information about the communities',
    available_orientations: [NATURAL_ORIENTATION, REVERSED_ORIENTATION, UNDIRECTED_ORIENTATION],
  },
  [LOUVAIN]: {
    key: LOUVAIN,
    name: 'Louvain',
    algoType: COMMUNITY_ALGO,
    link: 'https://neo4j.com/docs/graph-data-science/current/algorithms/louvain/',
    desc: 'An algorithm to detect communities in large networks. It maximizes a modularity score for each community, where the modularity quantifies the quality of an assignment of nodes to communities.',
    available_orientations: [NATURAL_ORIENTATION, REVERSED_ORIENTATION, UNDIRECTED_ORIENTATION],
  },
  [WCC]: {
    key: WCC,
    name: 'Weakly Connected Components',
    algoType: COMMUNITY_ALGO,
    link: 'https://neo4j.com/docs/graph-data-science/current/algorithms/wcc/',
    desc: 'Finds sets of connected nodes in an undirected graph, where all nodes in the same set form a connected component.',
    available_orientations: [NATURAL_ORIENTATION, REVERSED_ORIENTATION, UNDIRECTED_ORIENTATION],
  },

  // Commented out algorithms will be introduced in future releases.
  // [CLOSENESS]: { key: CLOSENESS,
  //   name: 'Closeness',
  //   algoType: CENTRALITY_ALGO,
  //   link: 'https://neo4j.com/docs/graph-data-science/current/algorithms/closeness-centrality/',
  //   desc: 'Calculates the sum of its distances to all other nodes, based on calculating the shortest paths between all pairs of nodes'
  // },
  // [TRIANGLE_COUNT]: { key: TRIANGLE_COUNT,
  //   name: 'Triangle Count',
  //   algoType: COMMUNITY_ALGO,
  //   link: 'https://neo4j.com/docs/graph-data-science/current/algorithms/triangle-count/',
  //   desc: 'Counts the number of triangles for each node in the graph. A triangle is a set of three nodes where each node has a relationship to the other two.',
  //   available_orientations: [ UNDIRECTED_ORIENTATION ]
  // },
};
