import { Objects, isNotNullish } from '@nx/stdlib';

import type {
  LinkSsoConfigIdRequest,
  ProjectMemberResponse,
  ProjectNotificationActionResponse,
  ProjectNotificationResponse,
  ProjectResponse,
  ProjectRoleResponse,
  ProjectSummaryResponse,
  TierConfigResponse,
  TierConfigSizeResponse,
  TierConfigsResponse,
  TransformedLinkSsoConfigIdBody,
  TransformedUpdateProjectNameBody,
  UpdateProjectMemberRolesRequest,
  UpdateProjectNameRequest,
} from '../console.api.types';
import type {
  InstanceSizes,
  Project,
  ProjectMember,
  ProjectNotification,
  ProjectNotificationAction,
  ProjectRole,
  ProjectSummary,
  TierConfig,
  TierConfigs,
} from '../console.types';

export const transformProjectSummaryResponse = (response: ProjectSummaryResponse): ProjectSummary => {
  const result: ProjectSummary = {
    id: response.namespace_id,
    name: response.friendly_name,
    projectType: response.namespace_type,
    planType: response.plan_type,
    suspended: response.suspended,
    hasBilling: response.has_billing,
    requiresBilling: response.requires_billing,
    organizationId: response.organization_id,
    ssoOrganizationId: response.sso_organization_id,
    // TODO expose value from api
    createdAt: new Date(Date.now() - 10 * 24 * 3600 * 1000).toISOString(),
  };
  if (isNotNullish(response.google_project_id)) {
    result.googleProjectId = response.google_project_id;
  }

  return result;
};

const transformTierConfigSizeResponses = (response: TierConfigSizeResponse[]): InstanceSizes => {
  return response.reduce<InstanceSizes>((acc, size) => {
    const base = {
      sizeId: size.size_id,
      storage: size.storage,
      cpu: size.cpu,
      costPerHour: size.cost_per_hour,
      memory: size.memory,
      deprecated: size.deprecated,
      isTrial: size.is_trial,
      minimumRequiredVersion: size.minimum_required_version,
      isPrepaidOnly: size.prepaid_only,
    };

    if ('cloud_provider' in size) {
      return [
        ...acc,
        {
          ...base,
          cloudProvider: size.cloud_provider,
          unavailableRegions: size.unavailable_regions,
        },
      ];
    }

    const expandedSizes = size.cloud_providers.reduce<InstanceSizes>((acc2, csp) => {
      return [
        ...acc2,
        {
          ...base,
          cloudProvider: csp,
          unavailableRegions: size.unavailable_regions.filter((ur) => ur.cloud_provider === csp).map((ur) => ur.region),
        },
      ];
    }, []);

    return [...acc, ...expandedSizes];
  }, []);
};

const transformTierConfigResponse = (response: TierConfigResponse): TierConfig => ({
  tier: response.tier,
  tierDisplayName: response.tier_display_name,
  cloudProviderRegions: response.cloud_provider_regions,
  sizes: transformTierConfigSizeResponses(response.sizes),
  versions: response.versions,
  defaultVersion: response.default_version,
  secondaryCost: response.secondary_cost,
  priorities: response.priorities,
});

const transformTierConfigResponses = (response: TierConfigsResponse): TierConfigs =>
  Objects.mapValues(response, transformTierConfigResponse);

const transformNotificationAction = (response: ProjectNotificationActionResponse): ProjectNotificationAction => ({
  actionId: response.action_id,
  label: response.label,
});

export const transformNotifications = (response: ProjectNotificationResponse): ProjectNotification => ({
  title: response.title,
  dates: response.dates,
  dismissalPeriod: response.dismissal_period,
  level: response.level,
  message: response.message,
  actions: response.actions.map(transformNotificationAction),
});

export const transformProjectResponse = (response: ProjectResponse): Project => {
  const result: Project = {
    id: response.namespace_id,
    name: response.friendly_name,
    projectType: response.namespace_type,
    planType: response.plan_type,
    suspended: response.suspended,
    allowFreeDatabaseCreation: response.allow_free_database_creation,
    tierConfigs: transformTierConfigResponses(response.tier_configs),
    unavailableTiers: transformTierConfigResponses(response.unavailable_tiers),
    capabilities: response.capabilities,
    hasUnpaidInvoices: response.has_unpaid_invoices,
    hasBilling: response.has_billing,
    requiresBilling: response.requires_billing,
    organizationId: response.organization_id,
    ssoOrganizationId: response.sso_organization_id,
    availableRoles: response.available_roles,
    availableActions: response.available_actions,
    notifications: response.notifications.map(transformNotifications),
    // TODO expose value from api
    createdAt: new Date(Date.now() - 10 * 24 * 3600 * 1000).toISOString(),
    billingMethod: response.billing_method,
    profile: response.namespace_profile,
  };

  if (isNotNullish(response.google_project_id)) {
    result.googleProjectId = response.google_project_id;
  }

  return result;
};

const transformProjectRoleResponse = (response: ProjectRoleResponse): ProjectRole => ({
  id: response.role_id,
  userId: response.user_id,
  projectId: response.namespace_id,
  name: response.name,
});

export const transformProjectMemberResponse = (response: ProjectMemberResponse): ProjectMember => ({
  userId: response.user_id,
  projectId: response.namespace_id,
  roles: response.roles.map((role) => transformProjectRoleResponse(role)),
  email: response.email,
});

export const transformUpdateProjectMemberRolesRequest = (request: UpdateProjectMemberRolesRequest) => ({
  roles: request.roles,
});

export const transformUpdateProjectNameRequest = (
  request: UpdateProjectNameRequest,
): TransformedUpdateProjectNameBody => ({
  FriendlyName: request.name,
});

export const transformLinkSsoConfigIdRequest = (request: LinkSsoConfigIdRequest): TransformedLinkSsoConfigIdBody => ({
  SsoConfigId: request.ssoConfigId,
});
