import type { BillingAccountResponse } from '../api-types/billing-account';
import { transformGetBillingAccountResponse } from '../transformers/billing-account';
import type { BillingAccount } from '../types/billing-account';
import { type Builder } from './types';

export const billingAccountEndpoints = (builder: Builder) => ({
  getBillingAccountForProject: builder.query<BillingAccount, string>({
    query: (projectId) => {
      return {
        url: `tenants/${projectId}/billing-account`,
      };
    },
    transformResponse: (acc: BillingAccountResponse) => transformGetBillingAccountResponse(acc),
    providesTags: (result, error, params) => [{ type: 'BillingAccount', id: result?.id }],
  }),
});
