import { Switch, Tooltip } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { consoleApi, getApiError, getErrorMessage, useNotifications } from '@nx/state';
import type { Organization } from '@nx/state';
import { isNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';

const logger = createLogger(APP_SCOPE.aura);

export interface OrgLoginMethodsProps {
  organization: Organization;
  isDisabled: boolean;
  disabledReason: string | null;
}

export const OrgLoginMethods = ({ organization, isDisabled, disabledReason }: OrgLoginMethodsProps) => {
  const [updateOrganization, updateOrganizationRes] = consoleApi.useUpdateOrganizationMutation();
  const { addNotification } = useNotifications();
  const updateLoginMethod = (loginMethods: { passwordLoginEnabled?: boolean; googleLoginEnabled?: boolean }) => {
    updateOrganization({
      ...loginMethods,
      organizationId: organization.id,
    })
      .unwrap()
      .catch((err: FetchBaseQueryError | SerializedError | undefined) => {
        const apiError = getApiError(err);
        const errorMessage = getErrorMessage(apiError);
        logger.error(errorMessage);
        addNotification({
          type: 'danger',
          title: `Failed to update`,
          description: errorMessage,
          timeout: 5000,
        });
      });
  };

  return (
    <div className="flex gap-4">
      <Tooltip type="simple" isDisabled={isNullish(disabledReason) || !isDisabled}>
        <Tooltip.Trigger hasButtonWrapper>
          <Switch
            isChecked={organization.passwordLoginEnabled}
            label="Allow email/password login"
            isDisabled={updateOrganizationRes.isLoading || isDisabled}
            onChange={(event) => updateLoginMethod({ passwordLoginEnabled: event.target.checked })}
            data-testid="email-password-toggle"
          />
        </Tooltip.Trigger>
        <Tooltip.Content className="max-w-52">{disabledReason}</Tooltip.Content>
      </Tooltip>
      <Tooltip type="simple" isDisabled={isNullish(disabledReason) || !isDisabled}>
        <Tooltip.Trigger hasButtonWrapper>
          <Switch
            isChecked={organization.googleLoginEnabled}
            label="Allow Google social login"
            isDisabled={updateOrganizationRes.isLoading || isDisabled}
            onChange={(event) => updateLoginMethod({ googleLoginEnabled: event.target.checked })}
            data-testid="google-toggle"
          />
        </Tooltip.Trigger>
        <Tooltip.Content className="max-w-52">{disabledReason}</Tooltip.Content>
      </Tooltip>
    </div>
  );
};
