import type { UpdaterInput } from '@nx/constants';
import { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from '../context';
import * as Capabilities from '../slices/capabilities-slice';

export function useCapability(key: Capabilities.Capability, deps: Capabilities.Capability[] = []) {
  const dispatch = useDispatch();

  const props = useMemo(() => ({ key, deps }), [key, deps]);
  const value = useSelector((state) => Capabilities.selectCapability(state, props));

  const updater = useCallback(
    (nextValue?: UpdaterInput<typeof value>) => {
      if (nextValue instanceof Function) {
        dispatch(Capabilities.toggle({ key, value: nextValue(value) }));
      } else {
        dispatch(Capabilities.toggle({ key, value: nextValue }));
      }
    },
    [dispatch, value, key],
  );

  return [value, updater] as const;
}
