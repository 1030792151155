/**
 * Get the default caption for labels
 * Derived from Browser https://github.com/neo4j/neo4j-browser/blob/d332dc415952a38eb2772e0d1840ad07ff808409/src/neo4j-arc/graph-visualization/models/GraphStyle.ts#L283
 */
export const findCaptionProperty = (propertyKeys: string[]) => {
  const captionPriority = [/^name$/i, /^title$/i, /^label$/i, /name$/i, /description$/i, /^.+/];

  const caption: string = captionPriority.reduceRight<string>(
    (leading, regex) => {
      const hits = propertyKeys.filter((caption) => !!caption.match(regex));
      return hits.length > 0 ? (hits[0] as string) : leading;
    },
    (propertyKeys[0] as string) || '',
  );

  if (caption && caption.length > 0) {
    return propertyKeys.find((p) => p === caption) ?? null;
  }
  return null;
};
