import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../types';

export const NAME = 'contentexport';

export interface ContentExportState {
  enabled: boolean;
  isCsvExportModalOpen: boolean;
}

export const initialState: ContentExportState = {
  enabled: true,
  isCsvExportModalOpen: false,
};

const getState = (state: RootState): ContentExportState => state[NAME];
export const getExportEnabled = (state: RootState) => getState(state).enabled;
export const getIsCsvExportModalOpen = (state: RootState) => getState(state).isCsvExportModalOpen;

const contentexportSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    exportEnabled(state, action: PayloadAction<boolean>) {
      state.enabled = action.payload;
    },
    setIsCsvExportModalOpen(state, action: PayloadAction<boolean>) {
      state.isCsvExportModalOpen = action.payload;
    },
  },
});

export const { exportEnabled, setIsCsvExportModalOpen } = contentexportSlice.actions;

export default contentexportSlice.reducer;
