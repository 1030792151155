import { Button, Typography } from '@neo4j-ndl/react';
import { useActiveProject } from '@nx/state';
import type { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

import HighPerformanceSvg from '../../assets/high-performance.svg';

export const NoInstancesOverlay = ({ title, body, children }: PropsWithChildren<{ title: string; body: string }>) => {
  const navigate = useNavigate();
  const activeProject = useActiveProject();
  const instancesRoute = `/projects/${activeProject.id}/instances`;
  const navigateToInstances = () => {
    navigate({ pathname: `${instancesRoute}` });
  };

  return (
    <div>
      <div className="pointer-events-none opacity-40 blur-[3px]" aria-disabled={true} {...{ inert: '' }}>
        {children}
      </div>
      <div
        className="absolute left-[50%] top-[50%] flex -translate-x-1/2 -translate-y-1/2  gap-8"
        data-testid="no-instances-overlay"
      >
        <img src={HighPerformanceSvg} className="h-[160px] w-[160px]" draggable={false} />
        <div className="flex max-w-[450px] flex-col gap-6">
          <Typography variant="h4">{title}</Typography>
          <Typography variant="body-large" className="text-palette-neutral-text-weak">
            {body}
          </Typography>
          <Button
            className="w-fit"
            onClick={() => navigateToInstances()}
            fill="filled"
            htmlAttributes={{
              tabIndex: 0,
            }}
          >
            Go to instances
          </Button>
        </div>
      </div>
    </div>
  );
};
