import type { AnalyticsAdapter, AnalyticsTrackPayload } from './analytics-adapter';

/**
 * "Magic" meta-adapter that wraps another adapter
 *
 * This is necessary to lazily initialize real analytics adapter until store
 * factory is implemented
 *
 * It is intentionally named "compat" instead of "lazy" to remind that this
 * should be removed in future
 */
export class CompatAnalyticsAdapter<Event extends string> implements AnalyticsAdapter<Event> {
  adapter?: AnalyticsAdapter<Event>;

  async init() {
    if (this.ready()) {
      await this.adapter.init();
    }
  }

  async identify({ userId, orgId }: { userId: string; orgId: string | null }) {
    if (this.ready()) {
      await this.adapter.identify({ userId, orgId });
    }
  }

  async trackNavigation() {
    if (this.ready()) {
      await this.adapter.trackNavigation();
    }
  }

  async setup(adapter: AnalyticsAdapter) {
    // Disallow overriding adapter once set up
    if (!this.ready()) {
      this.adapter = adapter;
      await this.adapter.init();
    }
  }

  async trackEvent(payload: AnalyticsTrackPayload<Event>) {
    if (this.ready()) {
      await this.adapter.trackEvent(payload);
    }
  }

  private ready(): this is Required<Pick<CompatAnalyticsAdapter<Event>, 'adapter'>> {
    return this.adapter !== undefined;
  }
}
