import type {
  Instance,
  MetricAggregation,
  MetricWithAggregations,
  MetricsRequest,
  MetricsResult,
  MetricsWithAggregationsRequest,
  TimeRange,
} from '@nx/state';
import { opsApi } from '@nx/state';
import { useEffect, useMemo, useState } from 'react';

import { dateToUnixSeconds } from '../../shared/ui-helpers';
import type { ChartType, ChartWidgetPartialProps } from '../charts/fullstack-chart-props';
import { mapAuraMetrics, mapAuraMetricsWithAZGrouping, mapAuraMetricsWithAggregations } from '../charts/mappers';
import type { MultiChartProps } from '../charts/types';
import { nonNullable } from '../shared/typeguards';

export const useMetricsQuery = ({
  selectedInstanceDetails,
  timeRange,
  chartProps,
  chartType,
  seriesNameMapper,
  useAggregations,
  useAZGrouping,
  active = true,
  showLeaderOnly = false,
  database = 'neo4j',
}: {
  selectedInstanceDetails: Instance | undefined;
  timeRange: TimeRange;
  chartProps: MultiChartProps[];
  chartType: ChartType;
  seriesNameMapper?: (props: ChartWidgetPartialProps) => string;
  useAggregations?: boolean;
  useAZGrouping?: boolean;
  active?: boolean;
  showLeaderOnly?: boolean;
  database?: string;
}): MetricsResult => {
  const [processedMetrics, setProcessedMetrics] = useState<MetricsResult['metrics']>(null);
  const [isLoading, setIsLoading] = useState<MetricsResult['isLoading']>(false);
  const [error, setError] = useState<MetricsResult['error']>(undefined);

  const startTime = dateToUnixSeconds(timeRange.startTime);
  const endTime = dateToUnixSeconds(timeRange.endTime);
  const metricNames: MetricWithAggregations[] = useMemo(() => {
    if (!active) {
      return [];
    }
    const allAggregations: MetricAggregation[] = ['MAX', 'AVG', 'MIN'];
    const maxOnlyAggregations: MetricAggregation[] = ['MAX'];
    const names = chartProps
      .flat()
      .flatMap((c) => [
        {
          metric: (useAZGrouping ?? false) ? (c.azMetricName ?? c.metricName) : c.metricName,
          aggregations: c.aggregations ? c.aggregations : allAggregations,
        },
        c.referenceMetricConfig
          ? {
              metric: c.referenceMetricConfig.name,
              aggregations: c.referenceMetricConfig.aggregation
                ? [c.referenceMetricConfig.aggregation]
                : maxOnlyAggregations,
            }
          : undefined,
        c.additionalMetric && !(useAZGrouping ?? false)
          ? {
              metric: c.additionalMetric.metricName,
              aggregations: c.aggregations ? c.aggregations : allAggregations,
            }
          : undefined,
        (useAZGrouping ?? false) && showLeaderOnly && c.transformerMetricConfig
          ? {
              metric: c.transformerMetricConfig.name,
              aggregations: c.transformerMetricConfig.aggregation
                ? [c.transformerMetricConfig.aggregation]
                : allAggregations,
            }
          : undefined,
      ])
      .filter(nonNullable);
    return names.filter((metric, pos) => names.findIndex((item) => item.metric === metric.metric) === pos);
  }, [active, chartProps, showLeaderOnly, useAZGrouping]);

  const [fetchMetrics] = opsApi.endpoints.getMetrics.useLazyQuery();
  const [fetchMetricsWithAggregations] = opsApi.endpoints.getMetricsWithAggregations.useLazyQuery();
  const [fetchMetricsWithAZGrouping] = opsApi.endpoints.getMetricsWithAZGrouping.useLazyQuery();

  const hasAlreadyFetched = processedMetrics !== null;

  useEffect(() => {
    if (!selectedInstanceDetails) {
      return;
    }

    const shouldDoFirstFetch = active && !hasAlreadyFetched;
    if (!shouldDoFirstFetch && !hasAlreadyFetched) {
      return;
    }

    if (!active) {
      return;
    }

    const resolveMetrics = async (): Promise<void> => {
      setIsLoading(true);
      setError(undefined);

      try {
        if (useAggregations ?? false) {
          const metricsWithAggregationsRequest: MetricsWithAggregationsRequest = {
            dbName: chartType === 'DB' ? database : undefined,
            tenantId: selectedInstanceDetails.projectId,
            dbmsId: selectedInstanceDetails.id,
            metrics: metricNames,
            startTime: startTime,
            endTime: endTime,
          };
          const metricsWithAggregations = await fetchMetricsWithAggregations(
            metricsWithAggregationsRequest,
            true,
          ).unwrap();
          setProcessedMetrics(mapAuraMetricsWithAggregations(metricsWithAggregations, chartProps.flat()));
        } else if (useAZGrouping ?? false) {
          const metricsRequest: MetricsRequest = {
            dbName: chartType === 'DB' ? database : undefined,
            tenantId: selectedInstanceDetails.projectId,
            dbmsId: selectedInstanceDetails.id,
            name: metricNames.map((mn) => mn.metric),
            startTime: startTime,
            endTime: endTime,
          };
          const metrics = await fetchMetricsWithAZGrouping(metricsRequest, true).unwrap();
          setProcessedMetrics(mapAuraMetricsWithAZGrouping(metrics, chartProps.flat(), showLeaderOnly));
        } else {
          const metricsRequest: MetricsRequest = {
            dbName: chartType === 'DB' ? database : undefined,
            tenantId: selectedInstanceDetails.projectId,
            dbmsId: selectedInstanceDetails.id,
            name: metricNames.map((mn) => mn.metric),
            startTime: startTime,
            endTime: endTime,
          };
          const metrics = await fetchMetrics(metricsRequest, true).unwrap();
          setProcessedMetrics(
            mapAuraMetrics(metrics, chartProps.flat(), selectedInstanceDetails.name, seriesNameMapper),
          );
        }
      } catch (e) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        setError(e as MetricsResult['error']);
      } finally {
        setIsLoading(false);
      }
    };

    void resolveMetrics().then().catch();
  }, [
    active,
    chartType,
    chartProps,
    endTime,
    fetchMetrics,
    fetchMetricsWithAZGrouping,
    fetchMetricsWithAggregations,
    hasAlreadyFetched,
    selectedInstanceDetails,
    seriesNameMapper,
    startTime,
    metricNames,
    useAZGrouping,
    useAggregations,
    showLeaderOnly,
    database,
  ]);

  return { isLoading, error, metrics: processedMetrics };
};

export const useMetricsMetadata = (selectedInstance: Instance | undefined) => {
  const { data: metricsMetadata, isError } = opsApi.endpoints.getMetricsMetadata.useQuery(
    selectedInstance?.projectId ?? '',
    { skip: !selectedInstance },
  );

  return { metricsMetadata, isError };
};
