import {
  filter,
  find,
  has,
  includes,
  isUndefined,
  keys,
  last,
  mapValues,
  size,
  some,
  sortBy,
  uniq,
  uniqBy,
} from 'lodash-es';
import { concat, flatten, forEach, groupBy, map, pipe, reduce } from 'lodash/fp';
import moment from 'moment';

import { isDateTimeType, parseStringToNumber } from '../../services/temporal/utils';
import { INVISIBLE_BAR_COLOR, PADDING, VISIBLE_BAR_COLOR } from './utils-histogram';

const CATEGORY_ID = 'categoryId';
const REL_ID = 'type';
export const NODES = 'nodes';
const RELATIONSHIPS = 'relationships';
export const DEFAULT_PLAYBACK_FRAME_WAIT_TIME = 150;
export const MAX_NUMBER_OF_GROUPS = 35;
export const COMMON_NAME = '(all)';
const SIDE_BOX_WIDTH = 16;
const SLICER_VALID_PROPERTY_TYPES = [
  'DateTime',
  'Date',
  'Time',
  'LocalTime',
  'LocalDateTime',
  'Duration',
  'bigint',
  'integer',
  'number',
  'float',
];
export const SIDE_RANGE_TO_END = 'side-range-to-end';
export const EXPAND_RANGE_TO_END = 'expand-range-to-end';
export const EXPAND_INSIDE_RANGE = 'expand-inside-range';
export const MODES_NAMING_MAPPING = {
  [EXPAND_INSIDE_RANGE]: 'Within range',
  [SIDE_RANGE_TO_END]: 'Slide range to end',
  [EXPAND_RANGE_TO_END]: 'Start of Range to end',
};

export const buildPropertiesList = (nodes, rels, gdsRules = [], categoryIdentifier) => {
  const gdsRulesIds = gdsRules.map((r) => r.id);
  const nodesByCategory = getNodesByCategoryGroup(nodes, categoryIdentifier);
  const relsByType = getRelsByTypeGroup(rels);
  const propertiesByCategory = getUniquePropertiesByGroup(nodesByCategory, NODES, CATEGORY_ID);
  const propertiesByRelType = getUniquePropertiesByGroup(relsByType, RELATIONSHIPS, REL_ID);
  const mappedPropertiesByType = flatten([propertiesByCategory, propertiesByRelType]);

  const commonProperties = getCommonPropertiesBetweenGroups(mappedPropertiesByType);

  return getPropertiesFullInfo(mappedPropertiesByType, commonProperties, gdsRulesIds);
};

export const getNodesByCategoryGroup = (nodes, categoryIdentifier) =>
  pipe(
    map((node) => ({ ...node, [CATEGORY_ID]: categoryIdentifier(node) })),
    groupBy(CATEGORY_ID),
    map((group) => ({ [CATEGORY_ID]: group[0][CATEGORY_ID], nodes: group })),
  )(nodes);

export const getRelsByTypeGroup = (rels) =>
  pipe(
    groupBy(REL_ID),
    map((group) => ({ [REL_ID]: group[0][REL_ID], relationships: group })),
  )(rels);

const filterValidTypes = (arr) => filter(arr, (o) => SLICER_VALID_PROPERTY_TYPES.includes(o.propertyType));

export const getUniquePropertiesByGroup = (arr, type, typeId) =>
  pipe(
    map((group) => ({
      ...group,
      properties: pipe(
        map('mappedProperties'),
        reduce(
          (res, prop) =>
            uniqBy(
              pipe(
                map((p) => ({
                  propertyId: `${p}-${prop[p]?.type}`, // for example 'name-string'
                  propertyKey: p, // for example 'name'
                  propertyType: prop[p]?.type, // for example 'string'
                  propertyGroupId: group[typeId], // number of category or relType
                  propertyGroupType: type, // 'nodes' or 'relationships'
                })),
                concat(res),
              )(keys(prop)),
              (o) => [o.propertyKey, o.propertyType].join(),
            ),
          [],
        ),
        filterValidTypes,
      )(group[type]),
    })),
  )(arr);

const filterDuplicates = (arr) => filter(arr, (val, i, iteratee) => includes(iteratee, val, i + 1));

export const getCommonPropertiesBetweenGroups = (arr) =>
  pipe(
    reduce((result, val) => concat(result, val.properties), []),
    map((p) => p.propertyId),
    filterDuplicates,
  )(arr);

const getPropertiesFullInfo = (groups, commonProperties, gdsRulesIds = []) =>
  sortBy(
    uniqBy(
      pipe(
        reduce((result, val) => concat(result, val.properties), []),
        map(({ propertyKey, propertyGroupType, propertyGroupId, propertyType, propertyId }) => {
          const isCommonProperty = commonProperties.includes(propertyId);
          const isNodeProperty = getIsNodeProperty(propertyId, groups);
          const isRelProperty = getIsRelProperty(propertyId, groups);
          const nonCommonRange = {
            id: `${propertyGroupType}-${propertyGroupId}-${propertyId}`,
            propertyKey, // for example 'name'
            propertyType, // number, bigint, etc...
            propertyGroupId, // id of category or rel type
            propertyGroupType, // nodes or relationships or (all)
            isCommon: false, // is shared property between different categories or rels
            isNodeProperty,
            isRelProperty,
            isGds: gdsRulesIds.includes(propertyKey),
          };
          if (isCommonProperty) {
            return [
              {
                id: `*-all-${propertyGroupId}-${propertyId}`,
                propertyKey, // for example 'name'
                propertyType, // number, bigint, etc...
                propertyGroupId: COMMON_NAME, // id of category or rel type
                propertyGroupType: COMMON_NAME, // nodes or relationships or (all)
                isCommon: true, // is shared property between different categories or rels
                isNodeProperty,
                isRelProperty,
                isGds: gdsRulesIds.includes(propertyKey),
              },
              { ...nonCommonRange },
            ];
          } else {
            return { ...nonCommonRange };
          }
        }),
        flatten,
      )(groups),
      (o) => [o.propertyGroupId, o.propertyKey, o.propertyType].join(),
    ),
    ['id', 'propertyGroupType', 'propertyGroupId', 'propertyKey'],
  );

export const getIsNodeProperty = (id, groups) =>
  some(
    pipe(
      (arr) => filter(arr, (val) => val.categoryId),
      map((m) => find(m.properties, (val) => val.propertyId === id)),
    )(groups),
    (val) => !isUndefined(val),
  );

export const getIsRelProperty = (id, groups) =>
  some(
    pipe(
      (arr) => filter(arr, (val) => val.type),
      map((m) => find(m.properties, (val) => val.propertyId === id)),
    )(groups),
    (val) => !isUndefined(val),
  );

export const getCurrentRangeAllUniqueValues = (nodes, rels, currentRange, categoryIdentifier) => {
  const { isCommon, isNodeProperty, isRelProperty, propertyKey, propertyGroupId, propertyGroupType } = currentRange;

  let input = [];

  if (isCommon) {
    if (isNodeProperty && isRelProperty) {
      input = [...nodes, ...rels];
    } else if (isRelProperty) {
      input = [...rels];
    } else {
      input = [...nodes];
    }
  } else if (propertyGroupType === NODES) {
    input = [...nodes];
  } else {
    input = [...rels];
  }

  return uniq(
    reduce((result, val) => {
      if (
        isCommon ||
        (!isCommon && propertyGroupType === NODES && categoryIdentifier(val) === propertyGroupId) ||
        (!isCommon && propertyGroupType === RELATIONSHIPS && val?.type === propertyGroupId)
      ) {
        if (val.mappedProperties[propertyKey] && val.mappedProperties[propertyKey].type === currentRange.propertyType) {
          const value = getValueTransform(val.mappedProperties[propertyKey], currentRange);
          result = [...result, value];
        }
      }
      return result;
    }, [])(input),
  );
};

const getValueTransform = (obj, currentRange) => {
  const { value } = obj;
  const { propertyType, timezoneTranslation, timezoneTranslationEnabled } = currentRange;
  const isNumber = propertyType === 'number';
  const isFloat = isNumber && value.toString().includes('.');
  const isDateTime = isDateTimeType(propertyType);

  if (isFloat) {
    return parseFloat(value);
  } else if (isNumber) {
    return parseInt(value);
  } else if (isDateTime) {
    return parseStringToNumber(value, propertyType, timezoneTranslation, timezoneTranslationEnabled);
  } else {
    return value;
  }
};

export const getValuesHashMaps = ({
  valuesMapper,
  nodes,
  rels,
  currentRange,
  slicerInvisibleNodesIds,
  slicerInvisibleRelsIds,
  categoryIdentifier,
}) => {
  const visibleValuesMapper = mapValues(valuesMapper, (a) => ({
    nodesIds: new Set(),
    relationshipsIds: new Set(),
  }));
  const nonVisibleValuesMapper = mapValues(valuesMapper, (a) => ({
    nodesIds: new Set(),
    relationshipsIds: new Set(),
  }));

  const nonRelatedNodesRels = {
    nodesIds: [],
    relationshipsIds: [],
  };

  pipe(
    forEach((node) => {
      const { mappedProperties, id } = node;
      const hasRangePropertyKey = currentRange.isCommon
        ? has(mappedProperties, currentRange.propertyKey)
        : currentRange.propertyGroupId === categoryIdentifier(node) &&
          currentRange.propertyGroupType === NODES &&
          has(mappedProperties, currentRange.propertyKey);

      if (hasRangePropertyKey) {
        const propertyType = mappedProperties[currentRange.propertyKey].type;
        const propertyValue = getValueTransform(mappedProperties[currentRange.propertyKey], currentRange);

        if (propertyType === currentRange.propertyType) {
          if (currentRange.propertyGroupType === NODES) {
            if (!slicerInvisibleNodesIds[id]) {
              visibleValuesMapper[propertyValue].nodesIds.add(id);
            } else {
              nonVisibleValuesMapper[propertyValue].nodesIds.add(id);
            }
          }
          if (currentRange.propertyGroupType === COMMON_NAME) {
            if (!slicerInvisibleRelsIds[id] && !slicerInvisibleNodesIds[id]) {
              visibleValuesMapper[propertyValue].nodesIds.add(id);
            } else {
              nonVisibleValuesMapper[propertyValue].nodesIds.add(id);
            }
          }
        } else {
          nonRelatedNodesRels.nodesIds.push(id);
        }
      } else {
        nonRelatedNodesRels.nodesIds.push(id);
      }
    }),
  )(nodes);

  pipe(
    forEach((rel) => {
      const { mappedProperties, id } = rel;
      const hasRangePropertyKey = currentRange.isCommon
        ? has(mappedProperties, currentRange.propertyKey)
        : currentRange.propertyGroupId === rel.type &&
          currentRange.propertyGroupType === RELATIONSHIPS &&
          has(mappedProperties, currentRange.propertyKey);
      if (hasRangePropertyKey) {
        const propertyType = mappedProperties[currentRange.propertyKey].type;
        const propertyValue = getValueTransform(mappedProperties[currentRange.propertyKey], currentRange);

        if (propertyType === currentRange.propertyType) {
          if (currentRange.propertyGroupType === RELATIONSHIPS) {
            if (!slicerInvisibleRelsIds[id]) {
              visibleValuesMapper[propertyValue].relationshipsIds.add(id);
            } else {
              nonVisibleValuesMapper[propertyValue].relationshipsIds.add(id);
            }
          }
          if (currentRange.propertyGroupType === COMMON_NAME) {
            if (!slicerInvisibleRelsIds[id] && !slicerInvisibleNodesIds[id]) {
              visibleValuesMapper[propertyValue].relationshipsIds.add(id);
            } else {
              nonVisibleValuesMapper[propertyValue].relationshipsIds.add(id);
            }
          }
        } else {
          nonRelatedNodesRels.relationshipsIds.push(id);
        }
      } else {
        nonRelatedNodesRels.relationshipsIds.push(id);
      }
    }),
  )(rels);

  return {
    nonRelatedNodesRels,
    visibleValuesMapper,
    nonVisibleValuesMapper,
  };
};

export const getGroupSize = (labels = [], maxGroups) => {
  const leftValues = size(labels) % maxGroups;
  return Math.floor(size(labels) / maxGroups) + (leftValues ? 1 : 0);
};

export const getGroupsLabels = (labels, groupSize) => {
  const labelsSize = size(labels);
  const groupsNum = Math.floor(labelsSize / groupSize) + (labelsSize % groupSize === 0 ? 0 : 1);
  const labelsToString = labels.map((l) => `${l}`);

  return Array.from(
    {
      length: groupsNum,
    },
    (_, i) => {
      if (labelsToString[i * groupSize]) {
        return `[${labelsToString[i * groupSize]}, ${
          labelsToString[i * groupSize + (groupSize - 1)] || last(labelsToString)
        }]`;
      }
      return null;
    },
  );
};

export const getGroupedData = (data, groupSize, groupsLabels) => {
  const { visibleNodesRelsArray, nonVisibleNodesRelsArray } = data;

  const mergeSets = (values, index, key) =>
    values.reduce((acc, m, j) => {
      if (j >= index * groupSize && j < index * groupSize + groupSize) {
        acc = new Set([...acc, ...m[key]]);
      }
      return acc;
    }, []);

  return {
    labels: groupsLabels,
    visibleNodesRelsArray: groupsLabels.map((_, i) => ({
      nodesIds: mergeSets(visibleNodesRelsArray, i, 'nodesIds'),
      relationshipsIds: mergeSets(visibleNodesRelsArray, i, 'relationshipsIds'),
    })),
    nonVisibleNodesRelsArray: groupsLabels.map((_, i) => ({
      nodesIds: mergeSets(nonVisibleNodesRelsArray, i, 'nodesIds'),
      relationshipsIds: mergeSets(nonVisibleNodesRelsArray, i, 'relationshipsIds'),
    })),
  };
};

export const getValuesMapper = (nodes, rels, currentRange, categoryIdentifier) =>
  reduce((result, val) => {
    result[val] = val;
    return result;
  }, {})(getCurrentRangeAllUniqueValues(nodes, rels, currentRange, categoryIdentifier));

export const buildPlotData = (
  nodes,
  rels,
  currentRange,
  slicerInvisibleNodesIds,
  slicerInvisibleRelsIds,
  valuesMapper,
  groupSize,
  groupedLabels,
  labels,
  categoryIdentifier,
) => {
  const { nonRelatedNodesRels, visibleValuesMapper, nonVisibleValuesMapper } = getValuesHashMaps({
    valuesMapper,
    nodes,
    rels,
    currentRange,
    slicerInvisibleNodesIds,
    slicerInvisibleRelsIds,
    categoryIdentifier,
  });

  const data = {
    labels,
    ...labels.reduce(
      (acc, a) => {
        const { visibleNodesRelsArray, nonVisibleNodesRelsArray } = acc;
        visibleNodesRelsArray.push(visibleValuesMapper[a]);
        nonVisibleNodesRelsArray.push(nonVisibleValuesMapper[a]);
        return acc;
      },
      { visibleNodesRelsArray: [], nonVisibleNodesRelsArray: [] },
    ),
  };

  const groupedData = size(labels) > MAX_NUMBER_OF_GROUPS ? getGroupedData(data, groupSize, groupedLabels) : data;

  return {
    data,
    nonRelatedNodesRels,
    normalized: groupedData,
  };
};

export const getPixelsByPercentage = (pMin, pMax, width) => ({
  start: Math.round((width * pMin) / 100),
  end: Math.round((width * pMax) / 100 - PADDING),
});

export const getPercentageByPixels = (start, end, width) => ({
  start: Math.round((100 * start) / width),
  end: Math.round((100 * end) / width),
});

export const getSlicerInvisibleNodesIds = (visibleIds, nonVisibleIds, previousIds, rangeId) => {
  const newSlicerInvisibleNodesIds = { ...previousIds };
  const revealedNodeIds = new Set([]);

  for (const id of visibleIds) {
    if (newSlicerInvisibleNodesIds[id]) {
      newSlicerInvisibleNodesIds[id].delete(rangeId);
      if (newSlicerInvisibleNodesIds[id].size === 0) {
        delete newSlicerInvisibleNodesIds[id];
        revealedNodeIds.add(id);
      }
    }
  }

  for (const id of nonVisibleIds) {
    if (!newSlicerInvisibleNodesIds[id]) {
      newSlicerInvisibleNodesIds[id] = new Set();
    }
    newSlicerInvisibleNodesIds[id].add(rangeId);
  }

  return {
    newSlicerInvisibleNodesIds,
    revealedNodeIds,
  };
};

export const getSlicerInvisibleRelsIds = (
  visibleIds,
  nonVisibleIds,
  previousIds,
  rangeId,
  nodesRelsMapper,
  revealedNodeIds,
) => {
  const newSlicerInvisibleRelsIds = { ...previousIds };

  for (const id of visibleIds) {
    if (newSlicerInvisibleRelsIds[id]) {
      newSlicerInvisibleRelsIds[id].delete(rangeId);
      if (newSlicerInvisibleRelsIds[id].size === 0) {
        delete newSlicerInvisibleRelsIds[id];
      }
    }
  }

  revealedNodeIds.forEach((rNodeId) => {
    nodesRelsMapper[rNodeId].forEach((relId) => {
      if (newSlicerInvisibleRelsIds[relId]) {
        newSlicerInvisibleRelsIds[relId].delete(rangeId);
        if (newSlicerInvisibleRelsIds[relId].size === 0) {
          delete newSlicerInvisibleRelsIds[relId];
        }
      }
    });
  });

  for (const id of nonVisibleIds) {
    if (!newSlicerInvisibleRelsIds[id]) {
      newSlicerInvisibleRelsIds[id] = new Set();
    }
    newSlicerInvisibleRelsIds[id].add(rangeId);
  }

  return newSlicerInvisibleRelsIds;
};

export const getFramesByMode = (mode, chartWidth, initRangePosition) => {
  const step = 1;
  const initFrames = Array.from({ length: chartWidth }, (_, i) => i);
  const maxStart = Math.min(chartWidth - SIDE_BOX_WIDTH * 2, initRangePosition.end - SIDE_BOX_WIDTH * 2);
  const maxEnd = chartWidth;
  const initStart = Math.ceil(initRangePosition.start);
  const initEnd = Math.ceil(initRangePosition.end);

  switch (mode) {
    case SIDE_RANGE_TO_END:
      return initFrames.reduce((acc, a) => {
        const newStart = initStart + a;
        const newEnd = initEnd + a;
        if (newEnd <= chartWidth) {
          acc = [
            ...acc,
            {
              start: newStart,
              end: newEnd,
            },
          ];
        }
        return acc;
      }, []);
    case EXPAND_RANGE_TO_END:
      return initFrames.reduce((acc, a) => {
        const newEnd = initEnd + a * step;
        if (newEnd <= maxEnd) {
          acc = [
            ...acc,
            {
              start: initStart,
              end: Math.round(newEnd),
            },
          ];
        }
        return acc;
      }, []);
    case EXPAND_INSIDE_RANGE:
      return initFrames.reduce((acc, a) => {
        const newStart = initStart + a * step;
        if (newStart <= maxStart) {
          acc = [
            ...acc,
            {
              start: Math.round(newStart),
              end: initEnd,
            },
          ];
        }
        return acc;
      }, []);
    default:
      return [];
  }
};

export const findClosestStart = (arr = [], barWidth, num) => {
  if (arr.length === 1) {
    return arr[0];
  }
  for (let i = 1; i < arr.length; i++) {
    if (num > arr[i - 1] + barWidth / 2 && num < arr[i] + barWidth / 2) {
      return arr[i];
    }
  }
  return arr[0];
};

export const findClosestEnd = (arr = [], barWidth, num) => {
  if (arr.length === 1) {
    return arr[0];
  }
  for (let i = 0; i < arr.length - 1; i++) {
    if (num > arr[i] - barWidth / 2 && num < arr[i + 1] - barWidth / 2) {
      return arr[i];
    }
  }
  return arr[arr.length - 1];
};

export const getInvisible = (o) =>
  Object.keys(o).reduce((acc, a) => {
    if (o[a].size > 0) {
      acc = [...acc, a];
    }
    return acc;
  }, []);

export const getOtherScrubbedOutIds = (currentRange, sliceInvisibleIds) =>
  Object.entries(sliceInvisibleIds).reduce((acc, a) => {
    a[1].delete(currentRange.id);
    if (a[1].size !== 0) {
      acc[a[0]] = a[1];
    }
    return acc;
  }, {});

export const getInitDatasets = (currentRange, plotData) => {
  const getTooltipText = (text) => {
    if (!currentRange.isCommon) return `${text} ${currentRange.propertyGroupType}`;
    else if (currentRange.isNodeProperty && currentRange.isRelProperty) return `${text}`;
    else if (currentRange.isNodeProperty) return `${text} ${NODES}`;
    else if (currentRange.isRelProperty) return `${text} ${RELATIONSHIPS}`;
    else return text;
  };

  return [
    {
      label: getTooltipText('Visible'),
      data: plotData.normalized.visibleNodesRelsArray.map(
        ({ nodesIds, relationshipsIds }) => nodesIds.size + relationshipsIds.size,
      ),
      backgroundColor: VISIBLE_BAR_COLOR,
    },
    {
      label: getTooltipText('Sliced out'),
      data: plotData.normalized.nonVisibleNodesRelsArray.map(
        ({ nodesIds, relationshipsIds }) => nodesIds.size + relationshipsIds.size,
      ),
      backgroundColor: INVISIBLE_BAR_COLOR,
    },
  ];
};

export const getDatasetsForNonCommonRange = ({ visData, startIndex, endIndex }) => ({
  newVisibleDataset: visData.vis.initialDatasets[0].data.map((d, i) => (i < startIndex || i > endIndex - 1 ? 0 : d)),
  newNonVisibleDataset: visData.vis.initialDatasets[1].data.map((d, i) =>
    i < startIndex || i > endIndex - 1
      ? visData.vis.visiblePerValue[i].nodesIds.size +
        visData.vis.visiblePerValue[i].relationshipsIds.size +
        visData.vis.nonVisiblePerValue[i].nodesIds.size +
        visData.vis.nonVisiblePerValue[i].relationshipsIds.size
      : d,
  ),
});

export const getDatasetsForCommonRange = ({
  visData,
  currentRange,
  slicerInvisibleNodesIds,
  slicerInvisibleRelsIds,
}) => {
  const { labels, visiblePerValue, nonVisiblePerValue } = visData.vis;
  const slicerInvisibleNodesIdsByCurrentRange = labels.map(
    (_, i) =>
      Array.from(visiblePerValue[i]?.nodesIds).filter(
        (nodeId) =>
          slicerInvisibleNodesIds[nodeId] &&
          slicerInvisibleNodesIds[nodeId].size === 1 &&
          slicerInvisibleNodesIds[nodeId].has(currentRange.id),
      ).length || 0,
  );

  const slicerInvisibleRelsIdsByCurrentRange = labels.map(
    (_, i) =>
      Array.from(visiblePerValue[i]?.relationshipsIds).filter(
        (relId) =>
          slicerInvisibleRelsIds[relId] &&
          slicerInvisibleRelsIds[relId].size === 1 &&
          slicerInvisibleRelsIds[relId].has(currentRange.id),
      ).length || 0,
  );

  const visible = labels.map((_, i) => {
    const numOfNodes = visiblePerValue[i]?.nodesIds.size - slicerInvisibleNodesIdsByCurrentRange[i];
    const numOfRels = visiblePerValue[i]?.relationshipsIds.size - slicerInvisibleRelsIdsByCurrentRange[i];
    return numOfNodes + numOfRels;
  });

  const invisible = labels.map((_, i) => {
    const numOfNodes = nonVisiblePerValue[i]?.nodesIds.size + slicerInvisibleNodesIdsByCurrentRange[i];
    const numOfRels = nonVisiblePerValue[i]?.relationshipsIds.size + slicerInvisibleRelsIdsByCurrentRange[i];
    return numOfNodes + numOfRels;
  });

  return {
    newVisibleDataset: labels.map((_, i) => visible[i]),
    newNonVisibleDataset: labels.map((_, i) => invisible[i]),
  };
};

export const getUpdatedVisibleData = ({
  startIndex,
  endIndex,
  nonRelatedNodesRels,
  currentRange,
  nodesRelsMapper,
  visiblePerValue,
  nonVisiblePerValue,
}) => {
  const visibleNodes = new Set(nonRelatedNodesRels.nodesIds);
  const visibleRels = new Set(nonRelatedNodesRels.relationshipsIds);
  const nonVisibleNodes = new Set(nonVisiblePerValue.nodesIds);
  const nonVisibleRels = new Set(nonVisiblePerValue.relationshipsIds);

  if (currentRange.propertyGroupType === NODES || currentRange.propertyGroupType === COMMON_NAME) {
    visiblePerValue.forEach((value, i) => {
      if (i < startIndex || i > endIndex - 1) {
        value.nodesIds.forEach((id) => {
          nonVisibleNodes.add(id);
          nodesRelsMapper[id].forEach((relId) => {
            nonVisibleRels.add(relId);
            visibleRels.delete(relId);
          });
        });
      }
    });

    visiblePerValue.forEach((value, i) => {
      if (i >= startIndex && i <= endIndex - 1) {
        value.nodesIds.forEach((id) => {
          !nonVisibleNodes.has(id) && visibleNodes.add(id);
          nodesRelsMapper[id].forEach((relId) => {
            !nonVisibleRels.has(id) && visibleRels.add(relId);
          });
        });
      }
    });

    if (currentRange.propertyGroupType === COMMON_NAME) {
      visiblePerValue.forEach((value, i) => {
        if (i >= startIndex && i <= endIndex - 1) {
          value.relationshipsIds.forEach((id) => {
            if (!nonVisibleRels.has(id)) {
              visibleRels.add(id);
            }
          });
        } else {
          value.relationshipsIds.forEach((id) => {
            nonVisibleRels.add(id);
          });
        }
      });
    }
  } else if (currentRange.propertyGroupType === RELATIONSHIPS) {
    visiblePerValue.forEach((value, i) => {
      if (i >= startIndex && i <= endIndex - 1) {
        value.relationshipsIds.forEach((id) => {
          if (!nonVisibleRels.has(id)) {
            visibleRels.add(id);
          }
        });
      } else {
        value.relationshipsIds.forEach((id) => {
          nonVisibleRels.add(id);
        });
      }
    });
  }

  return {
    visibleNodes,
    visibleRels,
    nonVisibleNodes,
    nonVisibleRels,
  };
};

export const getNodesRelsMapper = (nodesInventory, relsInventory) => {
  const nodesMapper = nodesInventory.reduce((acc, a) => {
    acc[a.id] = new Set([]);
    return acc;
  }, {});

  relsInventory.forEach((rel) => {
    nodesMapper[rel.startId].add(rel.id);
    nodesMapper[rel.endId].add(rel.id);
  });

  return nodesMapper;
};

export const getSortDuration = (values) => {
  const valuesObject = values.map((v) => {
    const momentDuration = moment.duration(v);
    return {
      label: v,
      years: momentDuration.get('years'),
      months: momentDuration.get('months'),
      days: momentDuration.get('days'),
      hours: momentDuration.get('hours'),
      minutes: momentDuration.get('minutes'),
      seconds: momentDuration.get('seconds'),
      milliseconds: momentDuration.get('milliseconds'),
    };
  });

  const sortedObject = sortBy(valuesObject, ['years', 'months', 'days', 'hours', 'minutes', 'seconds', 'milliseconds']);

  return map('label')(sortedObject);
};
