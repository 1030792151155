import { createSlice } from '@reduxjs/toolkit';

export const UIELEMENTS_PERSISTED_KEYS: (keyof UIElementState)[] = ['guideTooltipTimesShown'];

export interface UIElementState {
  guideTooltipTimesShown: number;
}

const initialState: UIElementState = {
  guideTooltipTimesShown: 0,
};

const uiElementsSlice = createSlice({
  name: 'uiElements',
  initialState,
  reducers: {
    showGuideTooltip: (state) => {
      state.guideTooltipTimesShown += 1;
    },
  },
});

export const { showGuideTooltip } = uiElementsSlice.actions;
export default uiElementsSlice.reducer;
