import type { CLOUD_PROVIDER, TIER } from './shared';

export enum TRAFFIC_ENABLEMENT {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export enum TRAFFIC_STATUS {
  CREATING = 'creating',
  UPDATING = 'updating',
  RUNNING = 'running',
  DELETING = 'deleting',
  DELETED = 'deleted',
}

export enum AWS_ENDPOINT_CONNECTION_STATE {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
  PENDING_ACCEPTANCE = 'pendingAcceptance',
  REJECTED = 'rejected',
  // The following connection types
  // are possible, but the infra traffic config
  // api doesn't use them atm
  // AVAILABLE = 'available',
  // DELETING = 'deleting',
  // DELETED = 'deleted',
  // FAILED = 'failed',
  // EXPIRED = 'expired',
}

export type AwsEndpointConnection = {
  endpointId: string;
  state: AWS_ENDPOINT_CONNECTION_STATE;
};

export type GcpTrafficConfigStatus = {
  serviceAttachmentUrl: string;
};

export type AwsTrafficConfigStatus = {
  endpointServiceName: string;
  endpointConnections: AwsEndpointConnection[];
};

export enum AZURE_ENDPOINT_CONNECTION_STATE {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  REMOVED = 'removed',
}

export type AzureEndpointConnection = {
  privateEndpointId: string;
  state: AZURE_ENDPOINT_CONNECTION_STATE;
};

export type AzureTrafficConfigStatus = {
  privateLinkServiceName: string;
  privateEndpointConnections: AzureEndpointConnection[];
};

export type GcpTrafficConfigProperties = {
  projectIds: string[];
};

export type AwsTrafficConfigProperties = {
  endpointIds: string[];
};

export type AzureTrafficConfigProperties = {
  subscriptionIds: string[];
  endpointIds: string[];
};

export type TrafficConfigStatus = {
  publicTraffic: TRAFFIC_STATUS;
  privateTraffic: TRAFFIC_STATUS;
  dnsDomain: string;
  gcpStatus?: GcpTrafficConfigStatus;
  awsStatus?: AwsTrafficConfigStatus;
  azureStatus?: AzureTrafficConfigStatus;
};

export type TrafficConfig = {
  cloudProvider: CLOUD_PROVIDER;
  tier: TIER;
  region: string;
  privateTraffic: TRAFFIC_ENABLEMENT;
  publicTraffic: TRAFFIC_ENABLEMENT;
  awsProperties?: AwsTrafficConfigProperties;
  gcpProperties?: GcpTrafficConfigProperties;
  azureProperties?: AzureTrafficConfigProperties;
  status: TrafficConfigStatus;
};
