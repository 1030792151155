import { APP_SCOPE, QUERY_TYPE } from '@nx/constants';
import type { Database } from '@nx/state';
import { findComposite, switchDatabase, updateDatabases } from '@nx/state';
import { UseDbErrors } from '@query/constants/errors';
import type { UseDbRequestId } from '@query/redux/requests-slice';
import type { AsyncThunk } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';

function findDatabaseByNameOrAlias(databases: Database[], name: string): Database | undefined {
  const lowerCaseName = name.toLowerCase();

  const composite = findComposite(databases, lowerCaseName);
  if (composite !== undefined) {
    throw UseDbErrors.CannotSwitchToCompositeAlias(composite, name);
  }

  const matchingDatabases = databases.filter(
    (db: Database) =>
      db.name.toLowerCase() === lowerCaseName || db.aliases.find((alias) => alias.toLowerCase() === lowerCaseName),
  );

  // Each cluster member can have it's own copy of a database, if we have multiples we prefer the online one
  return matchingDatabases.find((db: Database) => db.currentStatus === 'online') ?? matchingDatabases[0];
}

export type FrameIdAndDatabaseName = { requestId: UseDbRequestId; requestedDatabaseName: string };

export const requestDatabaseSwitch: AsyncThunk<
  {
    requestId: UseDbRequestId;
    requestedDatabaseName: string;
  },
  FrameIdAndDatabaseName,
  Record<string, never>
> = createAsyncThunk(
  'stream/requestDatabaseSwitch',
  async ({ requestId, requestedDatabaseName }: FrameIdAndDatabaseName) => {
    // make sure database list is up to date with neo4j
    // also conveniently gives us the current databases
    const databases = await updateDatabases({
      metadata: { appScope: APP_SCOPE.query, queryType: QUERY_TYPE.System },
    }).unwrap();

    const database = findDatabaseByNameOrAlias(databases, requestedDatabaseName);

    if (database === undefined) {
      throw UseDbErrors.DatabaseNotFound(requestedDatabaseName);
    }

    if (database.currentStatus === 'unknown') {
      throw UseDbErrors.DatabaseStatusUnknown(requestedDatabaseName);
    }

    if (database.currentStatus !== 'online') {
      throw UseDbErrors.DatabaseUnavailable(requestedDatabaseName, database.currentStatus);
    }

    switchDatabase(requestedDatabaseName);
    return { requestId, requestedDatabaseName };
  },
);
