import { isEmpty, keyBy, omit } from 'lodash-es';
import moment from 'moment';

import { getNodesAndRelationships } from '../../services/discovery';
import { log } from '../../services/logging';
import migrate from '../../services/migrate';
import { getSchemaFromPerspective } from '../../services/perspectives/schema';
import { getFullScene } from '../../services/scene';
import { trackSceneLoad } from '../../services/tracking/index';
import type { Node, Relationship } from '../../types/graph';
import type { Perspective } from '../../types/perspective';
import type { Scene } from '../../types/scene';
import type { Style, StyleCategory, StyleRelationshipType } from '../../types/style';
import { setFilterRules } from '../filter/filter';
import { setGdsState } from '../gds/gds';
import { addToInventory, addToVisible } from '../graph/graph.actions';
import { clearState } from '../rootReducer';
import { setSlicerRanges } from '../slicer/slicer';
import { mapPerspectiveToStyle } from '../styles/styleMiddleware';
import { STYLE_TYPE_SCENE, setCurrentStyleId, setStyleById } from '../styles/styles';
import { setLayoutOptions, setLayoutType, setPanCoordinates, setZoomLevel } from '../visualization/visualization';
import { getLatestSceneVersion, trasformations } from './migrations';
import {
  SCENE_EDIT_MODE_EDITING,
  SCENE_EDIT_MODE_VIEWING,
  addScene,
  buildScene,
  setCurrentNodePositions,
  setCurrentSceneId,
  setEditMode,
  setLoadingScene,
  updateScene,
} from './scene';

const getStyleRulesCount = (style: Style) => {
  let numStyleRules = 0;
  const countStyleRules = (entries: (StyleCategory | StyleRelationshipType)[]) =>
    entries.reduce((acc, entry) => acc + (entry.styleRules?.length || 0), 0);

  if (style?.categories) {
    numStyleRules += countStyleRules(style.categories);
  }

  if (style?.relationshipTypes) {
    numStyleRules += countStyleRules(style.relationshipTypes);
  }

  return numStyleRules;
};

export const applyNewEmptyScene =
  ({
    perspectiveStyle,
    canSaveScene,
    userId,
    dispatch,
  }: {
    perspectiveStyle: Style | undefined;
    canSaveScene: boolean;
    userId: string | undefined;
    dispatch: any;
  }) =>
  (scenes: Scene[]) => {
    const scene = generateNewScene({
      perspectiveStyle,
      canSaveScene,
      userId,
      scenes,
      dispatch,
    })();
    dispatch(clearState());
    return scene;
  };

const getLoadedScene = async (scenes: Scene[], sceneId: string) => {
  const scene = scenes?.find((s) => s.id === sceneId);
  let loadedScene = null;
  let isTemporaryScene = false;

  if (scene && !isEmpty(scene?.temporaryData)) {
    loadedScene = scene.temporaryData;
    isTemporaryScene = true;
  }

  if (!scene || scene.lastModified) {
    loadedScene = await getFullScene(sceneId);
  }

  return { loadedScene, isTemporaryScene };
};

export const applySelectedScene =
  ({
    dispatch,
    perspective,
    perspectiveStyle,
    visibleRelationshipTypes,
    visibleLabels,
    styleType,
    layoutType,
    userId,
    enableCytoscape,
    scenes,
    isV5OrGreater,
  }: {
    dispatch: any;
    perspective: Perspective | undefined;
    perspectiveStyle: Style | undefined;
    visibleRelationshipTypes: string[];
    visibleLabels: string[];
    styleType: string;
    layoutType: string;
    userId: string | undefined;
    enableCytoscape: boolean;
    scenes: Scene[];
    isV5OrGreater: boolean;
  }) =>
  async (sceneId: string, trackingEnabled = false, viaLink = false) => {
    dispatch(setLoadingScene(true));

    const { loadedScene, isTemporaryScene } = await getLoadedScene(scenes, sceneId);

    if (!loadedScene) {
      // Is not saved on the db yet
      // populate with perspective style
      dispatch(setCurrentSceneId(sceneId));
      if (styleType === STYLE_TYPE_SCENE) {
        dispatch(setCurrentStyleId(sceneId));
      }
      dispatch(clearState());
      dispatch(setCurrentNodePositions([]));
      dispatch(setLoadingScene(false));
      return;
    }

    const migratedScene: Scene = migrate(
      loadedScene,
      trasformations(perspectiveStyle, enableCytoscape),
      loadedScene.version,
    );

    const {
      id,
      createdBy,
      nodes,
      relationships: visibleRelationshipIds,
      visualisation,
      filters = [],
      gds,
      style,
      ranges = [],
    } = migratedScene;
    const sceneForReducer = omit(migratedScene, [
      'nodes',
      'relationships',
      'visualisation',
      'filters',
      'gds',
      'style',
      'ranges',
    ]);
    const isUserSceneOwner = createdBy === userId;

    dispatch(setCurrentSceneId(id));

    if (!isTemporaryScene) {
      dispatch(updateScene(sceneForReducer));
    }

    let styleRuleCount = 0;
    if (style !== undefined && perspective !== undefined) {
      dispatch(setStyleById({ styleId: id, style }));
      styleRuleCount = getStyleRulesCount(style);

      setTimeout(() => {
        mapPerspectiveToStyle(perspective, style, dispatch);
      }, 0);
    }

    if (trackingEnabled && perspective) {
      const numNodes = nodes?.length ?? 0;
      const numRelationships = visibleRelationshipIds?.length ?? 0;
      trackSceneLoad(id, perspective.id, numNodes, numRelationships, styleRuleCount, viaLink);
    }

    if (!nodes || !perspective) {
      dispatch(setLoadingScene(false));
      return;
    }

    const visibleNodeIds = nodes.map(({ id }) => id);
    const { nodes: inventoryNodes, relationships: inventoryRelationships } = await loadNodesAndRelationships(
      visibleNodeIds,
      visibleRelationshipIds ?? [],
      perspective,
      visibleRelationshipTypes,
      visibleLabels,
      isV5OrGreater,
    ).catch((error) => {
      log.error('Failed to load nodes and relationships:', error);
      dispatch(setLoadingScene(false));
      return { nodes: [], relationships: [] };
    });

    const { actualVisibleNodeIds, actualVisibleRelationshipIds, actualPositionNodes } = actualiseNodeAndRelationshipIds(
      inventoryNodes,
      inventoryRelationships,
      visibleRelationshipIds ?? [], // ?.map((id) => new Integer(Number.parseInt(id))) ?? [],
      nodes,
      isV5OrGreater,
    );

    dispatch(clearState());

    const setVisualization = async () => {
      if (!visualisation) {
        return;
      }
      dispatch(setZoomLevel(visualisation.zoomLevel));
      if (visualisation.panCoordinates) {
        dispatch(setPanCoordinates(visualisation.panCoordinates));
      }
      if (layoutType !== visualisation.layoutType) {
        dispatch(setLayoutType(visualisation.layoutType));
      }
      if (visualisation.layoutOptions) {
        dispatch(setLayoutOptions(visualisation.layoutOptions));
      }
    };

    const setInventory = () =>
      new Promise<void>((resolve) => {
        const newSceneRanges = ranges;
        setTimeout(() => {
          dispatch(
            addToInventory({
              nodes: inventoryNodes,
              relationships: inventoryRelationships,
            }),
          );
          dispatch(
            addToVisible({
              nodeIds: actualVisibleNodeIds,
              relationshipIds: actualVisibleRelationshipIds,
              categories: perspective.categories,
            }),
          );
          dispatch(setFilterRules(filters));
          dispatch(setSlicerRanges(newSceneRanges));
          if (gds) {
            dispatch(setGdsState(gds));
          }
          resolve();
        }, 0);
      });

    const setPositions = () =>
      new Promise<void>((resolve) => {
        setTimeout(() => {
          dispatch(setCurrentNodePositions(actualPositionNodes));
          dispatch(setLoadingScene(false));
          resolve();
        }, 0);
      });

    visualisation && (await setVisualization());
    await setInventory();

    if (styleType === STYLE_TYPE_SCENE) {
      dispatch(setCurrentStyleId(id));
    }

    if (!isUserSceneOwner) {
      dispatch(setCurrentStyleId(id));
      dispatch(setEditMode(SCENE_EDIT_MODE_VIEWING));
    } else {
      dispatch(setEditMode(SCENE_EDIT_MODE_EDITING));
    }

    await setPositions();
  };

export const generateNewScene =
  ({
    perspectiveStyle,
    userId,
    scenes,
    canSaveScene,
    dispatch,
  }: {
    perspectiveStyle: Style | undefined;
    userId: string | undefined;
    scenes: Scene[];
    canSaveScene: boolean;
    dispatch: any;
  }) =>
  () => {
    const sceneVersion = getLatestSceneVersion();

    if (!userId || !perspectiveStyle || !sceneVersion) {
      return;
    }

    const name = canSaveScene ? `Untitled Scene${scenes.length > 0 ? ` ${scenes.length + 1}` : ''}` : moment().format();
    const newScene = buildScene(name, userId, sceneVersion, !canSaveScene);
    const style = {
      ...perspectiveStyle,
      id: newScene.id,
      type: STYLE_TYPE_SCENE,
    };
    dispatch(setStyleById({ styleId: newScene.id, style }));
    dispatch(addScene(newScene));
    return newScene;
  };

const loadNodesAndRelationships = (
  visibleNodeIds: string[],
  visibleRelationshipIds: string[],
  perspective: Perspective,
  visibleRelationshipTypes: string[],
  visibleLabels: string[],
  isV5OrGreater: boolean,
): Promise<{ nodes: Node[]; relationships: Relationship[] }> =>
  new Promise<{ nodes: Node[]; relationships: Relationship[] }>((resolve, reject) => {
    return getNodesAndRelationships({
      nodeIds: visibleNodeIds,
      relationshipIds: visibleRelationshipIds,
      schema: getSchemaFromPerspective(perspective),
      visibleRelationshipTypes,
      visibleLabels,
      responseHandler: (result: any) => {
        const { nodes, relationships, error } = result;
        if (error) {
          log.error(error.message || error);
          reject(error.message || error);
        } else {
          resolve({ nodes, relationships });
        }
      },
      isV5OrGreater,
    });
  });

export const actualiseNodeAndRelationshipIds = (
  inventoryNodes: Node[],
  inventoryRelationships: Relationship[],
  visibleRelationshipIds: string[],
  nodePositions: { id: string; x: number; y: number }[],
  isV5OrGreater: boolean,
) => {
  const inventoryNodeIdsMap = keyBy(inventoryNodes, 'id');
  const inventoryRelIdsMap = keyBy(inventoryRelationships, 'id');
  let actualPositionNodes = [];
  let actualVisibleRelationshipIds = [];

  const nodeIdsAreIntegers = !isNaN(Number(nodePositions[0]?.id));
  if (isV5OrGreater && nodeIdsAreIntegers) {
    const inventoryNodesIdentityMap = keyBy(inventoryNodes, 'identity');
    actualPositionNodes = nodePositions.map((node) => ({
      ...node,
      id: inventoryNodesIdentityMap[node.id]?.id ?? node.id,
    }));
  } else {
    actualPositionNodes = nodePositions;
  }

  const relationshipIdsAreIntegers = !isNaN(Number(visibleRelationshipIds[0]));
  if (isV5OrGreater && relationshipIdsAreIntegers) {
    const inventoryRelationshipsIdentityMap = keyBy(inventoryRelationships, 'identity');
    actualVisibleRelationshipIds = visibleRelationshipIds.map((id) => inventoryRelationshipsIdentityMap[id]?.id ?? id);
  } else {
    actualVisibleRelationshipIds = visibleRelationshipIds;
  }

  actualPositionNodes = actualPositionNodes.filter((item) => inventoryNodeIdsMap[item.id]);

  return {
    actualVisibleNodeIds: actualPositionNodes.map(({ id }) => id),
    actualVisibleRelationshipIds: actualVisibleRelationshipIds.filter((id) => inventoryRelIdsMap[id]),
    actualPositionNodes,
  };
};
