import { Button, Select, TextLink, Typography } from '@neo4j-ndl/react';
import type { Project, TierConfig } from '@nx/state';
import { CLOUD_PROVIDER, ONBOARDING_EXPERIENCE, useUnsafeAppContext } from '@nx/state';
import { Objects, isNotNullish } from '@nx/stdlib';
import { useDarkMode } from '@nx/ui';
import type { SyntheticEvent } from 'react';
import { useMemo } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useNavigate } from 'react-router-dom';

import AwsDark from '../../assets/aws-dark.svg';
import Aws from '../../assets/aws.svg';
import Azure from '../../assets/azure.svg';
import Gcp from '../../assets/gcp.svg';
import { friendlyCloudProviderName } from '../entities/helpers';
import type { InstanceFormData } from '../entities/model';
import { BaseSection } from './base-section';

const providerImgs = (cloudProvider: CLOUD_PROVIDER, isDarkMode: boolean) => {
  if (cloudProvider === CLOUD_PROVIDER.GCP) {
    return Gcp;
  }
  if (cloudProvider === CLOUD_PROVIDER.AZURE) {
    return Azure;
  }

  if (isDarkMode) {
    return AwsDark;
  }
  return Aws;
};

export const formatCspLabel = (
  { label, icon }: { label: string | null; value: CLOUD_PROVIDER; key: CLOUD_PROVIDER; icon: string },
  { context }: { context: string },
) => {
  if (context === 'value') {
    return (
      <div className="flex flex-row gap-2 whitespace-break-spaces">
        <img src={icon} width="23" height="24" title="" className="shrink-0" />
        {label}
      </div>
    );
  } else if (context === 'menu') {
    return (
      <div className="flex flex-row gap-2">
        <img src={icon} width="23" height="24" title="" className="shrink-0" />
        <span>{label}</span>
      </div>
    );
  }
  return null;
};

export const formatRegionLabel = (
  { label, country }: { label: string | null; value: string; key: string; country: string },
  { context }: { context: string },
) => {
  const regionNames = new Intl.DisplayNames(['en'], { type: 'region', fallback: 'code' });
  if (context === 'value') {
    return (
      <div className="flex flex-row gap-2 whitespace-break-spaces">
        <ReactCountryFlag
          countryCode={country}
          title={isNotNullish(country) ? regionNames.of(country) : 'Unknown country flag'}
          className="size-8 shrink-0 self-center"
          style={{ width: '24px', height: '24px' }}
          svg
        />
        {label}
      </div>
    );
  } else if (context === 'menu') {
    return (
      <div className="flex flex-row gap-2">
        <ReactCountryFlag
          countryCode={country}
          title={isNotNullish(country) ? regionNames.of(country) : 'Unknown country flag'}
          className="shrink-0 self-center"
          style={{ width: '24px', height: '24px' }}
          svg
        />
        {/* type defaults to 'submit' -- form submits when pressed  (as of @neo4j-ndl/react@3.0.24) */}
        <span>{label}</span>
      </div>
    );
  }
  return null;
};

type CreateInstanceSectionProps = {
  onCreate: () => void;
  isLoading: boolean;
  instanceType: ONBOARDING_EXPERIENCE;
  tierConfig: TierConfig;
  project: Project;
  data: InstanceFormData;
  onDataChange: (data: Partial<InstanceFormData>) => void;
};

export const CreateInstanceSection = ({
  instanceType,
  onCreate,
  isLoading,
  tierConfig,
  project,
  data,
  onDataChange,
}: CreateInstanceSectionProps) => {
  const navigate = useNavigate();
  const { userHomePath } = useUnsafeAppContext();
  const isDarkMode = useDarkMode();

  const cloudProviderOptions = Objects.keys(tierConfig.cloudProviderRegions).map((csp) => ({
    label: friendlyCloudProviderName(csp),
    value: csp,
    key: csp,
    icon: providerImgs(csp, isDarkMode),
  }));

  const selectedCsp = useMemo(() => {
    return cloudProviderOptions.find((csp) => csp.value === data.cloudProvider) ?? null;
  }, [cloudProviderOptions, data.cloudProvider]);

  const regions = useMemo(() => {
    return tierConfig.cloudProviderRegions[data.cloudProvider];
  }, [data.cloudProvider, tierConfig.cloudProviderRegions]);

  const regionOptions = useMemo(() => {
    return (
      regions?.map((region) => ({
        label: region.friendly,
        value: region.name,
        key: region.name,
        country: region.country,
      })) ?? []
    );
  }, [regions]);

  const selectedRegion = useMemo(() => {
    return regionOptions.find((region) => region.value === data.region) ?? null;
  }, [regionOptions, data.region]);

  const handleCspChange = (cloudProvider: CLOUD_PROVIDER | undefined) => {
    if (isNotNullish(cloudProvider)) {
      const [newRegion] = tierConfig.cloudProviderRegions[cloudProvider] ?? [];
      onDataChange({ cloudProvider, region: newRegion?.name });
    }
  };

  const handleRegionChange = (region: string | undefined) => {
    if (isNotNullish(region)) {
      onDataChange({ region });
    }
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    onCreate();
  };

  const title =
    instanceType === ONBOARDING_EXPERIENCE.Trial ? 'Where do you want to deploy?' : 'Get started with a free instance';
  const buttonText = instanceType === ONBOARDING_EXPERIENCE.Trial ? 'Start 14 days free trial' : 'Create instance';
  const altText =
    instanceType === ONBOARDING_EXPERIENCE.Trial
      ? 'Not looking to start a free trial?'
      : 'Not looking to create a free instance?';

  return (
    <BaseSection title={title} step={4}>
      <div className="flex grow flex-col gap-8 overflow-y-auto">
        <form id="create-instance-form" onSubmit={handleSubmit}>
          {instanceType === ONBOARDING_EXPERIENCE.Trial && (
            <div className="flex flex-col gap-6">
              <Select
                label="Cloud provider"
                type="select"
                selectProps={{
                  value: selectedCsp,
                  options: cloudProviderOptions,
                  formatOptionLabel: formatCspLabel,
                  onChange: (option) => handleCspChange(option?.value),
                }}
                size="large"
                isFluid
              />
              <Select
                label="Region"
                type="select"
                selectProps={{
                  value: selectedRegion,
                  options: regionOptions,
                  onChange: (option) => handleRegionChange(option?.value),
                  formatOptionLabel: formatRegionLabel,
                }}
                size="large"
                isFluid
              />
            </div>
          )}
        </form>
        <div className="flex flex-col items-center justify-center gap-1">
          <Button
            size="large"
            type="submit"
            htmlAttributes={{ form: 'create-instance-form' }}
            color="primary"
            className="w-full"
            isLoading={isLoading}
          >
            {buttonText}
          </Button>
          <Typography variant="body-small" className="text-neutral-text-weak">
            No credit card required
          </Typography>
        </div>
        <div className="flex flex-col items-center justify-center gap-1">
          <div className="text-neutral-text-weak">{altText}</div>
          <TextLink
            as="button"
            htmlAttributes={{
              onClick: () => navigate(userHomePath ?? `/projects/${project.id}/instances`),
              'data-testid': 'navigate-home',
            }}
            className="no-underline"
          >
            Select another instance type
          </TextLink>
        </div>
      </div>
    </BaseSection>
  );
};
