import { tokens } from '@neo4j-ndl/base';
import type { SeriesSummary } from '@nx/state';
import { cloneDeep, merge } from 'lodash-es';
import type uPlot from 'uplot';

import { getColorWithOpacity } from '../../shared/ui-helpers';
import type { ChartWidgetPartialProps } from '../charts/fullstack-chart-props';
import { axisAutoSize, lineGapper } from './utils';

const DEFAULT_INITIAL_WIDTH = 200;
const DEFAULT_HEIGHT = 400;
const LEFT_PADDING = 5;
const X_AXIS_BORDER_STROKE = tokens.theme.dark.palette.neutral.border.strong;
const X_AXIS_BORDER_STROKE_DARK = tokens.theme.dark.palette.neutral.border.strong;
const X_AXIS_BORDER_STROKE_LIGHT = tokens.theme.light.palette.neutral.border.strong;
const X_AXIS_STROKE_DARK = tokens.theme.dark.palette.neutral.text.weaker;
const X_AXIS_STROKE_LIGHT = tokens.theme.light.palette.neutral.text.weaker;
const GRID_STROKE_LIGHT = tokens.theme.light.palette.neutral.border.weak;
const GRID_STROKE_DARK = tokens.theme.dark.palette.neutral.border.weak;

const DEFAULT_X_SERIES: uPlot.Series = {
  label: 'Date',
};

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const DEFAULT_X_AXIS: uPlot.Axis = {
  scale: 'x',
  space: 60,
  grid: {
    show: false,
  },
  border: { stroke: X_AXIS_BORDER_STROKE, width: 2, show: true },
  ticks: {
    width: 1,
  },
  values: [
    // [0]:   minimum num secs in found axis split (tick incr)
    // [1]:   default tick format
    // [2-7]: rollover tick formats
    // [8]:   mode: 0: replace [1] -> [2-7], 1: concat [1] + [2-7]
    // [tick incr, default, year, month, day, hour, min, sec, mode]
    [DAY, '{MMM} {D}', null, null, null, null, null, null, 1],
    [HOUR, '{HH}:{mm}', null, null, '\n{MMM} {D}', null, null, null, 1],
    [MINUTE, '{HH}:{mm}', null, null, '\n{MMM} {D}', null, null, null, 1],
    [SECOND, '{HH}:{mm}:{ss}', null, null, '\n{MMM} {D}', null, null, null, 1],
  ],
};

const getDefaultXAxis = (isDarkTheme: boolean) => {
  const stroke = isDarkTheme ? X_AXIS_STROKE_DARK : X_AXIS_STROKE_LIGHT;
  const borderStroke = isDarkTheme ? X_AXIS_BORDER_STROKE_DARK : X_AXIS_BORDER_STROKE_LIGHT;
  const border = { stroke: borderStroke, width: 2, show: true };
  return { ...DEFAULT_X_AXIS, stroke, border };
};

const DEFAULT_CURSOR: uPlot.Cursor = {
  focus: { prox: 16 },
  // avoid zooming on accidental micro drags
  drag: { dist: 5 },
};

const DEFAULT_PLOT_CONFIG: uPlot.Options = {
  width: DEFAULT_INITIAL_WIDTH,
  height: DEFAULT_HEIGHT,
  padding: [null, null, null, LEFT_PADDING],
  legend: {
    // Hide default legend
    show: false,
  },
  series: [],
  // enable ms timestamps
  ms: 1,
};

export class UPlotOptionsBuilder {
  series: uPlot.Series[] = [DEFAULT_X_SERIES];

  private isDarkTheme: boolean;

  private axes: uPlot.Axis[] = [];

  private scales: uPlot.Scales = {};

  private hooks: uPlot.Hooks.Arrays = {};

  private plugins: uPlot.Plugin[] = [];

  private cursor: uPlot.Cursor = DEFAULT_CURSOR;

  private bands: uPlot.Band[] | undefined = undefined;

  private select: uPlot.Select | undefined = DEFAULT_PLOT_CONFIG.select;

  public addSeries(newSeries: uPlot.Series) {
    this.series.push({
      width: 1.5,
      gaps: lineGapper,
      ...newSeries,
    });
  }

  constructor(isDarkTheme: boolean) {
    this.isDarkTheme = isDarkTheme;
    this.axes = [getDefaultXAxis(isDarkTheme)];
  }

  private setSeriesVisibility(seriesIdx: number, visible: boolean) {
    if (seriesIdx < 1 || seriesIdx > this.series.length) {
      return;
    }

    const series = this.series[seriesIdx];
    if (series) {
      series.show = visible;
    }
  }

  public setInstanceSeriesVisibility(ignoredInstances: string[], showTotals: boolean) {
    this.series.slice(1).forEach((s, idx) => {
      const isIgnored = Boolean(s.label) && ignoredInstances.includes(s.label ?? '');
      const isTotalAndIsIgnored = (s.referenceSeries?.isReference ?? false) && !showTotals;
      const isVisible = !(isIgnored || isTotalAndIsIgnored);

      if (s.show !== isVisible) {
        this.setSeriesVisibility(idx + 1, isVisible);
      }
    });
  }

  public addAxis(chartProps: ChartWidgetPartialProps) {
    const newAxisIdx = this.axes.length;
    const newAxisStaticConfig = chartProps.uplotOptions?.axes?.[newAxisIdx];
    const newAxis: uPlot.Axis = {
      label: chartProps.yAxisLabel,
      grid: {
        width: 1,
        stroke: this.isDarkTheme ? GRID_STROKE_DARK : GRID_STROKE_LIGHT,
      },
      ticks: {
        width: 1,
      },
      size: axisAutoSize,
      stroke: X_AXIS_STROKE_LIGHT,
    };
    this.axes.push(merge(newAxis, newAxisStaticConfig));
  }

  public addExtraAxis(customAxisConfig: uPlot.Axis) {
    const { stroke, ...rest } = customAxisConfig;
    const defaultStroke = this.isDarkTheme ? X_AXIS_STROKE_DARK : undefined;
    const defaultAxisConfig: uPlot.Axis = {
      scale: 'right',
      grid: { show: false },
      size: axisAutoSize,
      stroke: stroke ?? defaultStroke,
      side: 1,
      ticks: {
        width: 0,
        size: 0,
      },
    };
    this.axes.push(merge(defaultAxisConfig, rest));
  }

  public updateXAxis(axis: uPlot.Axis) {
    // eslint-disable-next-line prefer-destructuring
    const xAxis = this.axes[0];
    this.axes[0] = { ...xAxis, ...axis };
  }

  public updateScales(scales: uPlot.Scales) {
    this.scales = merge(this.scales, scales);
  }

  public addHook<T extends keyof uPlot.Hooks.Defs>(type: T, hook: uPlot.Hooks.Defs[T]) {
    this.hooks[type] = [];
    this.hooks[type].push(hook);
  }

  public addPlugin(plugin: uPlot.Plugin) {
    this.plugins.push(plugin);
  }

  public updateCursor(cursor: uPlot.Cursor) {
    this.cursor = merge({}, this.cursor, cursor);
  }

  public updateSelect(select: uPlot.Select) {
    this.select = merge({}, this.select, select);
  }

  public addMinMaxBands(pills: SeriesSummary[]) {
    const maxSeriesIndex = pills.findIndex((pill) => pill.id === 'MAX');
    const minSeriesIndex = pills.findIndex((pill) => pill.id === 'MIN');

    if (maxSeriesIndex >= 0 && minSeriesIndex >= 0) {
      this.bands = [
        {
          series: [maxSeriesIndex + 1, minSeriesIndex + 1],
          fill: getColorWithOpacity(tokens.colors.baltic[20], 0.2),
        },
      ];
    }
  }

  public addAdditionalDataBands(pills: SeriesSummary[]) {
    if (pills.length === 2) {
      this.bands = [
        {
          series: [1, 2],
          fill: getColorWithOpacity(tokens.colors.forest[10], 0.2),
        },
      ];
    }
  }

  public getOptions(): uPlot.Options {
    const options = cloneDeep(DEFAULT_PLOT_CONFIG);

    options.series = this.series;
    options.axes = this.axes;
    options.scales = this.scales;
    options.plugins = this.plugins;
    options.hooks = this.hooks;
    options.cursor = this.cursor;
    options.bands = this.bands;
    options.select = this.select;

    return options;
  }
}
