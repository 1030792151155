import { APP_SCOPE, QUERY_TYPE } from '@nx/constants';
import type { DriverConnectInfo } from '@nx/constants';
import type { Dispatch, ListenerEffectAPI, UnknownAction } from '@reduxjs/toolkit';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import { neo4jDriverAdapter } from '../adapters/neo4j-driver-adapter-instance';
import type { Database } from '../slices/connections';
import { connectDriver, skipConnection } from '../slices/connections/connections-slice';
import {
  selectActiveConnection,
  selectActiveDatabase,
  selectActiveDatabaseName,
} from '../slices/connections/selectors';
import type { Connection } from '../slices/connections/types';
import { updateDataSummary } from '../slices/data-summary/data-summary-slice';
import { type AppDispatch, type RootState } from '../store';

export const METADATA = {
  appScope: APP_SCOPE.framework,
  queryType: QUERY_TYPE.System,
};

const middleware = createListenerMiddleware<RootState>();

const startListening = middleware.startListening.withTypes<RootState, AppDispatch>();

/**
 * Add one-time effect triggered when connection to database is established.
 */
export function handleConnectionSuccess(callback: () => void) {
  function effect() {
    middleware.stopListening({ actionCreator: connectDriver.fulfilled, effect });
    callback();
  }

  startListening({
    actionCreator: connectDriver.fulfilled,
    effect,
  });
}

export type ConnectionReadyCallback = (state: {
  driverConnectInfo: DriverConnectInfo | null;
  activeConnection: Connection | null;
  activeDatabase: Database | null;
  activeDatabaseName: string | undefined;
}) => void;

/**
 * Add one-time effect triggered when connection is ready to run queries.
 * Connection is ready to run queries when active database is selected.
 * To select active database, we need to fetch current user information and list of databases.
 * DataSummary is updated last, after active database is selected.
 * Listening for DataSummary update tells us that connection is ready.
 */
export function handleConnectionReady(callback: ConnectionReadyCallback) {
  // one-time effect, remove other listeners if there are any
  middleware.clearListeners();

  function effect(action: UnknownAction, listenerApi: ListenerEffectAPI<RootState, Dispatch<UnknownAction>>) {
    middleware.stopListening({ actionCreator: updateDataSummary.fulfilled, effect });

    const state = listenerApi.getState();
    const driverConnectInfo = neo4jDriverAdapter.getDriverConnectInfo();
    const activeConnection = selectActiveConnection(state);
    const activeDatabaseName = selectActiveDatabaseName(state);
    const activeDatabase = selectActiveDatabase(state);

    callback({ driverConnectInfo, activeConnection, activeDatabase, activeDatabaseName });
  }

  startListening({
    actionCreator: updateDataSummary.fulfilled,
    effect,
  });
}

/**
 * Clears all connection listeners
 */
export function handleConnectionAbort() {
  middleware.clearListeners();
}

/**
 * Effect for when connecting to a DB is optional for a certain action,
 * and the user decides to skip connecting.
 */
export function handleConnectionSkip(callback: () => void) {
  function onSkip() {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    stopListening();
    callback();
  }
  function onConnectionSuccess() {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    stopListening();
  }
  function stopListening() {
    middleware.stopListening({ actionCreator: skipConnection, effect: onSkip });
    middleware.stopListening({ actionCreator: connectDriver.fulfilled, effect: onConnectionSuccess });
  }

  startListening({
    actionCreator: skipConnection,
    effect: onSkip,
  });
  startListening({
    actionCreator: connectDriver.fulfilled,
    effect: onConnectionSuccess,
  });
}

export default middleware.middleware;
