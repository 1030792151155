import type { GQLError } from '@nx/state';
import { useLayoutEffect, useRef, useState } from 'react';

import { CellExpand } from './cell-expand';
import { GQLErrorModal } from './gql-error-modal';

type QueryCellProps = {
  value?: string | undefined;
  query?: string | undefined;
  error?: string | undefined;
  gqlError?: GQLError | null;
  width?: number;
};

export const QueryCell = ({ value, query, error, gqlError, width }: QueryCellProps) => {
  const [hasEllipsis, setHasEllipsis] = useState(false);
  const queryRef = useRef<HTMLDivElement | null>(null);
  const errorRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    const queryEl = queryRef.current;
    const errorEl = errorRef.current;
    if (!queryEl) {
      return;
    }

    const elHasEllipsis =
      queryEl.scrollHeight > queryEl.clientHeight || (errorEl && errorEl.scrollHeight > errorEl.clientHeight);

    setHasEllipsis(elHasEllipsis ?? false);
  }, [query, error, width]);

  return (
    <div data-testid="query-cell">
      <div className="gap-token-4 flex flex-col">
        <p ref={queryRef} className="line-clamp-3 whitespace-pre-wrap" title={value ?? undefined}>
          {value?.trim()}
        </p>
        {Boolean(error) && (
          <p ref={errorRef} className="text-palette-danger-icon line-clamp-3 whitespace-pre-wrap">
            ERROR: {error}
          </p>
        )}
      </div>
      {hasEllipsis && <CellExpand message={query} header="Query" error={!gqlError ? error : undefined} isCode />}
      {gqlError && <GQLErrorModal gqlError={gqlError} />}
    </div>
  );
};
