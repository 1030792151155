import type {
  AddressResponse,
  CardResponse,
  CreateCardRequest,
  CreateCustomerRequest,
  CustomerResponse,
  TransformedUpdateCustomerDetailsBody,
  UpdateCustomerDetailsRequest,
  UpdateCustomerEmailRequest,
} from '../console.api.types';
import type { Address, CardDetails, Customer } from '../console.types';

export const transformAddressResponse = (response: AddressResponse): Address => ({
  city: response.city,
  line1: response.line1,
  postalCode: response.postal_code,
  state: response.state,
  country: response.country,
});

export const transformCardResponse = (response: CardResponse): CardDetails => ({
  address: transformAddressResponse(response.address),
  last4: response.last4,
  brand: response.brand,
  expMonth: response.exp_month,
  expYear: response.exp_year,
  name: response.name,
});

export const transformCustomerResponse = (response: CustomerResponse): Customer => ({
  billingName: response.billing_name,
  companyName: response.company_name,
  address: response.address ? transformAddressResponse(response.address) : undefined,
  serviceAddress: response.service_address ? transformAddressResponse(response.service_address) : undefined,
  email: response.email,
});

export const transformUpdateCustomerDetailsRequest = (
  request: UpdateCustomerDetailsRequest,
): TransformedUpdateCustomerDetailsBody => {
  const transformAddress = (address: Address): AddressResponse => {
    return {
      city: address.city,
      line1: address.line1,
      postal_code: address.postalCode,
      state: address.state,
      country: address.country,
    };
  };

  return {
    data: {
      address: request.address ? transformAddress(request.address) : undefined,
      shipping: request.shipping
        ? { ...request.shipping, address: transformAddress(request.shipping.address) }
        : undefined,
      name: request.name,
    },
    email: request.email,
  };
};

export const transformUpdateCustomerEmailRequest = (request: UpdateCustomerEmailRequest) => ({
  email: request.email,
});

export const transformCreateCardRequest = (request: CreateCardRequest) => request.token;

export const transformCreateCustomerRequest = (request: CreateCustomerRequest) => ({
  token: request.token.token,
  is_trial_extension: request.is_trial_extension ?? false,
});
