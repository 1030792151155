import { Button } from '@neo4j-ndl/react';
import { ArrowLongLeftIconOutline } from '@neo4j-ndl/react/icons';
import { Objects } from '@nx/stdlib';

import { jobRoleLabels } from '../entities/model';
import type { JOB_ROLE, OnboardingData } from '../entities/model';
import { OnboardingItemButton } from '../onboarding-button';
import { BaseSection } from './base-section';

type RoleSectionProps = {
  onNextStep: (jobRole: JOB_ROLE) => void;
  onBack: () => void;
  isLoading: boolean;
  data: OnboardingData;
};

export const RoleSection = ({ onNextStep, onBack, isLoading, data }: RoleSectionProps) => {
  const handleSelectRole = (role: JOB_ROLE) => {
    onNextStep(role);
  };
  return (
    <BaseSection title="What best describes your role?" step={2}>
      <div className="flex grow flex-col justify-between gap-6 overflow-y-auto">
        <div className="role-grid gap-4 overflow-y-auto">
          {Objects.keys(jobRoleLabels).map((key) => (
            <OnboardingItemButton
              key={key}
              onClick={() => handleSelectRole(key)}
              size="medium"
              isLoading={key === data.jobRole && isLoading}
              isDisabled={isLoading}
            >
              {jobRoleLabels[key]}
            </OnboardingItemButton>
          ))}
        </div>
        <div className="self-start">
          <Button
            size="large"
            onClick={onBack}
            color="neutral"
            fill="outlined"
            className="text-neutral-text-default hover:bg-neutral-hover active:bg-palette-neutral-pressed flex gap-2 !border-none"
          >
            <ArrowLongLeftIconOutline className="size-5" />
            Back
          </Button>
        </div>
      </div>
    </BaseSection>
  );
};
