import { Banner } from '@neo4j-ndl/react';
import { useDarkMode } from '@nx/ui';

import awsDarkLogo from '../assets/aws-dark.svg';
import awsLogo from '../assets/aws.svg';

export interface AWSBillingMessageProps {
  className?: string;
}

export const AWSBillingMessage = ({ className }: AWSBillingMessageProps) => {
  const darkTheme = useDarkMode();
  const title = (
    <div className="flex items-center gap-2">
      <img src={darkTheme ? awsDarkLogo : awsLogo} className="h-6 w-6" />
      Managed by your AWS Marketplace account.
    </div>
  );

  return (
    <Banner
      type="info"
      className={className}
      description={title}
      usage="inline"
      name="Managed by AWS Marketplace"
      htmlAttributes={{
        'data-testid': 'aws-billing-message',
      }}
    />
  );
};
