import { isNotNullish } from '@nx/stdlib';

import { DATA_API_AUTH_PROVIDER_TYPE } from '../api-types/graphql';
import type {
  CreateDataApiAuthProviderRequest,
  CreateDataApiAuthProviderRequestBody,
  CreateDataApiRequest,
  CreateDataApiRequestBody,
  GetDataApiRequest,
  GetDataApiResponse,
  GetDataApiResponseTransformed,
  UpdateDataApiAuthProviderRequest,
  UpdateDataApiAuthProviderRequestBody,
  UpdateDataApiRequest,
  UpdateDataApiRequestBody,
} from '../api-types/graphql';

export const transformCreateDataApiRequest = (request: CreateDataApiRequest): CreateDataApiRequestBody => {
  const body: CreateDataApiRequestBody = {
    name: request.name,
    aura_instance: {
      username: request.instanceUsername,
      password: request.instancePassword,
    },
    type_definitions: window.btoa(request.typeDefinitions),
  };

  if (typeof request.featuresSubgraph === 'boolean') {
    body.features = {
      subgraph: request.featuresSubgraph,
    };
  }
  if (isNotNullish(request.authProviders) && request.authProviders.length > 0) {
    body.security = {
      authentication_providers: request.authProviders,
    };
  } else {
    // Adding a "default" auth provider if none is specified
    body.security = {
      authentication_providers: [
        {
          name: 'default',
          type: DATA_API_AUTH_PROVIDER_TYPE.apiKey,
          enabled: true,
        },
      ],
    };
  }
  if (isNotNullish(request.allowedOrigins) && request.allowedOrigins.length > 0) {
    body.security = {
      authentication_providers: body.security.authentication_providers,
      cors_policy: {
        allowed_origins: request.allowedOrigins,
      },
    };
  }

  return body;
};

export const transformGetDataApiResponse = (
  response: GetDataApiResponse,
  request: GetDataApiRequest,
): GetDataApiResponseTransformed => ({
  data: {
    id: response.data.id,
    name: response.data.name,
    instanceId: request.instanceId,
    instancePassword: '',
    instanceUsername: '',
    typeDefinitions: window.atob(response.data.type_definitions),
    featuresSubgraph: response.data.features.subgraph,
    allowedOrigins: response.data.security.cors_policy.allowed_origins,
    authProviders: [],
  },
  errors: response.errors,
});

export const transformUpdateDataApiRequest = (request: UpdateDataApiRequest): UpdateDataApiRequestBody => {
  const body: UpdateDataApiRequestBody = {};

  if (request.name) {
    body.name = request.name;
  }
  if (request.typeDefinitions) {
    body.type_definitions = window.btoa(request.typeDefinitions);
  }
  if (request.instancePassword && request.instancePassword.length > 1) {
    body.aura_instance = {
      ...body.aura_instance,
      password: request.instancePassword,
    };
  }
  if (request.instanceUsername && request.instanceUsername.length > 1) {
    body.aura_instance = {
      ...body.aura_instance,
      username: request.instanceUsername,
    };
  }
  if (typeof request.featuresSubgraph === 'boolean') {
    body.features = {
      ...body.features,
      subgraph: request.featuresSubgraph,
    };
  }
  if (isNotNullish(request.allowedOrigins) && request.allowedOrigins.length > 0) {
    body.security = {
      ...body.security,
      cors_policy: {
        allowed_origins: request.allowedOrigins,
      },
    };
  }

  return body;
};

export const transformCreateDataApiAuthProviderRequest = (
  request: CreateDataApiAuthProviderRequest,
): CreateDataApiAuthProviderRequestBody => {
  const body: CreateDataApiAuthProviderRequestBody = {
    name: request.name,
    type: request.type,
    enabled: request.enabled,
  };

  if (isNotNullish(request.url)) {
    body.url = request.url;
  }

  return body;
};

export const transformUpdateDataApiAuthProviderRequest = (
  request: UpdateDataApiAuthProviderRequest,
): UpdateDataApiAuthProviderRequestBody => {
  const body: UpdateDataApiAuthProviderRequestBody = {};

  if (isNotNullish(request.name)) {
    body.name = request.name;
  }
  if (typeof request.enabled === 'boolean') {
    body.enabled = request.enabled;
  }
  if (isNotNullish(request.url)) {
    body.url = request.url;
  }

  return body;
};
