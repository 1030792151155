import type { BillingAccountResponse } from '../api-types/billing-account';
import type { BillingAccount } from '../types/billing-account';

export const transformGetBillingAccountResponse = (res: BillingAccountResponse): BillingAccount => ({
  id: res.billing_account_id,
  name: res.name,
  billingModel: res.billing_model,
  billingProcessor: res.billing_processor,
  organizationId: res.organization_id,
  paymentMethod: res.payment_method,
  pricingPlan: res.pricing_plan,
  projectIds: res.project_ids,
  email: res.email,
  reason: res.reason,
  status: res.status,
});
