import type { Record as ResultRecord } from 'neo4j-driver';

import { type User, isUser } from './user.types';

export function toUserObject(record: ResultRecord): User {
  const user = record.toObject();
  if (!isUser(user)) {
    throw new Error(`Invalid user record: ${JSON.stringify(user)}`);
  }

  return user;
}
