import type { DynamicBaseQueryFn, OpsTypes } from '@nx/state';
import { opsApi, useActiveProject, useOpsContext } from '@nx/state';
import { isNonEmptyString } from '@nx/stdlib';
import type { TypedUseQueryHookResult } from '@reduxjs/toolkit/query/react';

type QueryOptions = Parameters<typeof opsApi.useGetAuthPermissionsQuery>[1];
type QueryResult = TypedUseQueryHookResult<OpsTypes.Api.AuthPermissionsResponse, string, DynamicBaseQueryFn>;

export function useSelectedInstancePermissions(queryOpts?: QueryOptions): OpsTypes.Api.AuthPermissionsResponse | null {
  const activeProject = useActiveProject();
  const { selectedInstanceId } = useOpsContext();

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const permissionsRes = opsApi.useGetAuthPermissionsQuery(
    {
      tenantId: activeProject.id,
      dbmsId: selectedInstanceId ?? '',
    },
    {
      skip: !isNonEmptyString(selectedInstanceId),
      ...queryOpts,
    },
  ) as unknown as QueryResult;

  return permissionsRes.data ?? null;
}
