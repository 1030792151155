import type { LDFlagSet } from '@nx/launch-darkly-service';
import type { METRIC_TYPE, MetricValue, TIER } from '@nx/state';
import type uPlot from 'uplot';

import type { COLOR_PALETTE_ENUM } from '../shared/color-palettes';
import type { TooltipComponentType } from '../shared/types';
import type { HelpTooltipProps } from './help-tooltips/help-tooltip';

// Without Labels
const MetricNames = [
  '**.vm.memory.buffer.direct.used',
  '**.vm.file.descriptors.count',
  '**.vm.heap.used',
  '**.vm.heap.committed',
  'memory.used',
  'memory.free',
  'memory.total',
  'memory.swap_free',
  'memory.swap_total',
  'memory.swap_used',
  '**.page_cache.hits',
  '**.page_cache.hit_ratio',
  '**.page_cache.usage_ratio',
  '**.page_cache.page_faults',
  '**.bolt.connections_running',
  '**.bolt.connections_opened',
  '**.bolt.connections_idle',
  '**.bolt.connections_closed',
  '**.vm.gc.time.g1_young_generation',
  '**.vm.gc.time.g1_old_generation',
] as const;

const MetricsNamesLabeled = [
  // CPU related
  'cpu.count',
  'cpu.idle',
  'cpu.system',
  'cpu.user',
  // Disk related
  'disk.free',
  'disk.used',
  'disk.total',
] as const;

const MetricNamesDB = [
  // Examples:
  // '<prefix>.system.transaction.last_committed_tx_id',
  // '<prefix>.neo4j.transaction.last_committed_tx_id',
  '**.transaction.last_committed_tx_id',
  '**.cypher.replan_events',
  '**.ids_in_use.property',
  '**.ids_in_use.relationship',
  '**.ids_in_use.node',
  '**.transaction.active_read',
  '**.transaction.active_write',
  '**.transaction.committed',
  '**.transaction.peak_concurrent',
  '**.transaction.rollbacks',
  'disk.used',
  'store.size',
  '**.check_point.events',
  '**.check_point.total_time',
  '**.check_point.duration',
  '**.neo4j.count.relationship',
  '**.neo4j.count.node',
  '**.store.size.total',
  '**.store.size.database',
] as const;

const MetricNamesAuraDB = [
  'neo4j_db_query_execution_internal_latency_q50',
  'neo4j_db_query_execution_internal_latency_q75',
  'neo4j_db_query_execution_internal_latency_q99',
  'neo4j_db_query_execution_failure_per_minute',
  'neo4j_db_query_execution_success_per_minute',
  'neo4j_db_query_execution_failure_total',
  'neo4j_db_query_execution_success_total',
  'neo4j_aura_cpu_usage',
  'neo4j_aura_cpu_limit',
  'neo4j_aura_storage_limit',
  'neo4j_aura_storage_limit_calculated',
  'neo4j_aura_out_of_memory_errors_total',
  'neo4j_dbms_vm_gc_time_total',
  'neo4j_dbms_vm_gc_time_ratio',
  'neo4j_dbms_vm_gc_time_g1_young_generation_total',
  'neo4j_dbms_vm_gc_time_g1_young_generation_ratio',
  'neo4j_dbms_vm_gc_time_g1_old_generation_total',
  'neo4j_dbms_vm_gc_time_g1_old_generation_ratio',
  'neo4j_dbms_vm_heap_used_ratio',
  'neo4j_dbms_page_cache_hit_ratio_per_minute',
  'neo4j_dbms_page_cache_usage_ratio',
  'neo4j_dbms_page_cache_evictions_per_minute',
  'neo4j_dbms_page_cache_evictions_total',
  'neo4j_dbms_bolt_connections_running',
  'neo4j_dbms_bolt_connections_opened_total',
  'neo4j_dbms_bolt_connections_closed_total',
  'neo4j_dbms_bolt_connections_opened_per_minute',
  'neo4j_dbms_bolt_connections_closed_per_minute',
  'neo4j_dbms_bolt_connections_idle',
  'neo4j_database_count_node',
  'neo4j_database_count_relationship',
  'neo4j_database_count_node_per_minute',
  'neo4j_database_count_relationship_per_minute',
  'neo4j_database_transaction_active_read',
  'neo4j_database_transaction_active_write',
  'neo4j_database_transaction_committed_total',
  'neo4j_database_transaction_committed_per_minute',
  'neo4j_database_transaction_peak_concurrent_total',
  'neo4j_database_transaction_rollbacks_total',
  'neo4j_database_transaction_rollbacks_per_minute',
  'neo4j_database_transaction_last_committed_tx_id_total',
  'neo4j_database_store_size_database',
  'neo4j_database_store_size_available_reserved',
  'neo4j_database_store_size_used',
  'neo4j_database_cypher_replan_events_total',
  'neo4j_database_cypher_replan_events_per_minute',
  'neo4j_database_check_point_events_total',
  'neo4j_database_check_point_events_per_minute',
  'neo4j_database_check_point_total_time_total',
  'neo4j_database_check_point_duration',
  // TODO this one is not in the presentation
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedBtreeIndexSyntax_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedCreatePropertyExistenceConstraintSyntax_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedDefaultDatabaseSyntax_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedDefaultGraphSyntax_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedDropConstraintSyntax_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedCreateConstraintOnAssertSyntax_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedDropIndexSyntax_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedFieldNotification_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedFunctionNotification_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedHexLiteralSyntax_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedOctalLiteralSyntax_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedParameterSyntax_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedPatternExpressionOutsideExistsSyntax_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedPeriodicCommit_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedPointsComparison_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedProcedureNotification_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedPropertyExistenceSyntax_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedRelTypeSeparatorNotification_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedRepeatedRelVarInPatternExpression_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedSelfReferenceToVariableInCreatePattern_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedShowExistenceConstraintSyntax_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedShowSchemaSyntax_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedStartNotification_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedUseOfNullInCaseExpression_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedAmbiguousGroupingNotification_total',
  'neo4j_dbms_cypher_internal_notification_count_DeprecatedCoercionOfListToBoolean_total',
  'neo4j_dbms_cypher_internal_notification_count_MissingAliasNotification_total',
  'neo4j_cluster_raft_is_leader',
] as const;

export type AllMetricNames =
  | (typeof MetricNames)[number]
  | (typeof MetricNamesDB)[number]
  | (typeof MetricNamesAuraDB)[number]
  | (typeof MetricsNamesLabeled)[number];

export type ChartType = 'HOST' | 'DB' | 'INSTANCE' | 'INSTANCE_DASHBOARD';

export type ChartPathType = 'LINE' | 'BAR' | 'AREA';

export type ChartAggregation = 'MIN' | 'MAX' | 'AVG' | 'SUM';

export type TransformerMetricConfig = {
  name: AllMetricNames;
  type?: METRIC_TYPE;
  aggregation?: ChartAggregation;
  transform: (transformerSeries: MetricValue[] | undefined, tagrgetMetric: MetricValue | undefined) => number | null;
};

type NoCmiMetric = 'NO_CMI_METRIC';

export const isMetricAvailableInCmi = (cmiMetricName: AllMetricNames | NoCmiMetric | undefined) =>
  typeof cmiMetricName === 'undefined' || cmiMetricName !== 'NO_CMI_METRIC';

export type ChartWidgetPartialProps = {
  /** Title of the card */
  title: string;
  /** In case of multiple charts per card */
  subtitle?: string;
  metricName: AllMetricNames;
  azMetricName?: AllMetricNames;
  cmiMetricName?: AllMetricNames | NoCmiMetric;
  minimumRequiredDatabaseVersion?: string;
  additionalMetric?: ChartWidgetPartialProps;
  labels?: Record<string, string>;
  /** Metric Type */
  metricType: METRIC_TYPE;
  /** Help tooltip component */
  tooltipComponent?: HelpTooltipProps;
  /** Metric context */
  chartType: ChartType;
  chartValueType?: 'percentage' | 'bytes' | 'int' | 'time' | 'rate';
  /** Additional Classnames */
  classNames?: string;

  /** Y label (rendered outside the chart lib) */
  yLabel: string;

  yAxisLabel?: string;

  /** Uplot Related Options */
  uplotOptions?: Partial<uPlot.Options>;

  /** Tooltip Ticker Formatter */
  chartTooltip?: TooltipComponentType;

  /** Data point mapper */
  valueMapper?: (value: number) => number;

  referenceMetricConfig?: {
    toggledByDefault: boolean;
    name: AllMetricNames;
    type?: METRIC_TYPE;
    aggregation?: ChartAggregation;
    hidden?: boolean;
  };

  chartPathType?: ChartPathType;

  aggregations?: ChartAggregation[];

  showLinkToLogs?: boolean;

  colorPalette?: COLOR_PALETTE_ENUM;

  defaultAggregation?: ChartAggregation;

  enabledFor?: TIER[];
  controlledBy?: { name: keyof LDFlagSet; state: 'ON' | 'OFF' }[];

  transformerMetricConfig?: TransformerMetricConfig;
  showBands?: boolean;
  seriesName?: string;
};
