import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type AppContextSliceState = {
  activeOrgId: string | null;
  activeProjectId: string | null;
  userHomePath: string | null;
};

export interface AppState extends AppContextSliceState {
  activeProjectId: string;
}

const initialState: AppContextSliceState = {
  activeOrgId: null,
  activeProjectId: null,
  userHomePath: null,
};

export const slice = createSlice({
  name: 'appContext',
  initialState,
  reducers: {
    activeOrgSelected(state: AppContextSliceState, action: PayloadAction<AppState['activeOrgId']>) {
      state.activeOrgId = action.payload;
    },
    activeProjectSelected(state: AppContextSliceState, action: PayloadAction<AppState['activeProjectId']>) {
      state.activeProjectId = action.payload;
    },
    userHomePathUpdated(state: AppContextSliceState, action: PayloadAction<AppState['userHomePath']>) {
      state.userHomePath = action.payload;
    },
  },
});

export const {
  activeOrgSelected: setActiveOrg,
  activeProjectSelected: setActiveProject,
  userHomePathUpdated: setUserHomePath,
} = slice.actions;
export default slice.reducer;
