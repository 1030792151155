/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { Neo4jColors } from '@neo4j-ndl/base';
import type { Code } from '@neo4j-ndl/react';
import { Banner, Button, TextLink } from '@neo4j-ndl/react';
import {
  ArrowRightCircleIconOutline,
  ChevronRightIconOutline,
  PlayCircleIconOutline,
  Square2StackIconOutline,
} from '@neo4j-ndl/react/icons';
import type { DomJson, RouterState } from '@nx/constants';
import { APP_SCOPE, isAppScope } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { SIGNAL_NAME, signals } from '@nx/signals';
import { isNullish } from '@nx/stdlib';
import type { ColumnDef } from '@tanstack/react-table';
import cx from 'classnames';
import { isMatch } from 'lodash-es';
import { Fragment, memo, useState } from 'react';

import { registerHighlight } from '../highlight/highlight';
import { useCurrentScope } from '../hooks/use-current-scope';
import { Image } from '../image/image';
import { RunnableCodeInlined } from '../inlined-runnable-codeblock/inlined-runnable-codeblock';
import RunnableCodeBlock from '../runnable-code-block/runnable-code-block';
import { SimpleTable } from '../simple-table/simple-table';
import type { SvgIcon } from '../svg-icon';
import classes from './asciidoc-ui-converter.module.css';
import { GUIDES_ACTIONS, GUIDES_CUSTOM_TAGS, GUIDES_HTML_TAGS } from './constants';

type TableDataFormat = Record<string, DomJson>;

function isHtmlTag(tag: unknown): tag is GUIDES_HTML_TAGS | GUIDES_CUSTOM_TAGS {
  if (tag !== null && typeof tag === 'string') {
    return tag in GUIDES_HTML_TAGS || tag in GUIDES_CUSTOM_TAGS || tag === '#text';
  }

  return false;
}

function isCodeLanguage(language: unknown): language is React.ComponentProps<typeof Code>['language'] {
  // Supported Needle launguages
  const Languages = [
    'asciidoc',
    'bash',
    'c',
    'csharp',
    'css',
    'css-extras',
    'cypher',
    'docker',
    'json',
    'go',
    'graphql',
    'java',
    'javadoc',
    'javascript',
    'jsx',
    'kotlin',
    'php',
    'python',
    'regex',
    'rust',
    'sql',
    'typescript',
    'yaml',
  ];

  return language !== null && typeof language === 'string' && Languages.includes(language);
}

function guideButtonCallback(
  json: DomJson,
  navigate: (scope: APP_SCOPE, search?: Record<string, string | string[]>, state?: RouterState) => void,
): (() => void) | undefined {
  switch (json.attributes?.role) {
    case GUIDES_ACTIONS.NX_IMPORT_LOAD:
      return () => {
        navigate(APP_SCOPE.import);
        // @TODO: The signals (navigate also) were awaited before, make sure everything works as expected
        void signals.emit(SIGNAL_NAME.ImportFetchModelAndFilesFromUrl, {
          url: json.attributes?.endpoint ?? '',
        });
      };

    case GUIDES_ACTIONS.NX_EXPLORE_SEARCH:
      return () => {
        // @TODO: Make sure the explore deeplink works as expected
        navigate(APP_SCOPE.explore, { search: json.attributes?.search ?? '', run: 'true' });
      };

    case GUIDES_ACTIONS.NX_TAB_NAV:
      return () => {
        const scope = json.attributes?.tab?.toLocaleLowerCase();
        if (isAppScope(scope)) {
          navigate(scope);
        }
      };

    case GUIDES_ACTIONS.NX_IMPORT_ADD_NODE:
      return () => {
        navigate(APP_SCOPE.import);
        // @TODO: The signals (navigate also) were awaited before, make sure everything works as expected
        void signals.emit(SIGNAL_NAME.ImportAddNode, {});
      };

    case GUIDES_ACTIONS.NX_IMPORT_PREVIEW:
      return () => {
        navigate(APP_SCOPE.import);
        // @TODO: The signals (navigate also) were awaited before, make sure everything works as expected
        void signals.emit(SIGNAL_NAME.ImportPreview, { previewSelected: false });
      };

    case GUIDES_ACTIONS.NX_IMPORT_RUN:
      return () => {
        navigate(APP_SCOPE.import);
        // @TODO: The signals (navigate also) were awaited before, make sure everything works as expected
        void signals.emit(SIGNAL_NAME.ImportRun, {});
      };

    default:
      return undefined;
  }
}

// eslint-disable-next-line react/display-name
const HighlightComponent = memo(
  ({
    json,
    json2Jsx,
  }: {
    json: DomJson;
    json2Jsx: (json: DomJson, parentJson: DomJson, key?: number) => JSX.Element | null;
  }) => {
    const currentScope = useCurrentScope();

    const scope = json.attributes?.tab?.toLocaleLowerCase();
    const element = json.attributes?.element ?? undefined;

    const { addHighlight, removeHighlight } = registerHighlight(element);

    return (
      <span
        className="cursor-default font-bold underline decoration-dotted"
        onMouseEnter={() => {
          if (currentScope === scope) {
            addHighlight();
          }
        }}
        onMouseLeave={removeHighlight}
      >
        {json.children.map((child, i) => json2Jsx(child, json, i))}
      </span>
    );
  },
);

export class JsonUiConverter {
  private navigate: (scope: APP_SCOPE, search?: Record<string, string | string[]>, state?: RouterState) => void;

  private renderer: Record<GUIDES_HTML_TAGS | GUIDES_CUSTOM_TAGS | string, typeof this.json2Jsx>;

  private isSafari: boolean;

  constructor() {
    const logger = createLogger(APP_SCOPE.framework);

    // eslint-disable-next-line no-underscore-dangle
    this.renderer = {
      [GUIDES_HTML_TAGS.DIV]: this.div.bind(this),
      [GUIDES_HTML_TAGS.TABLE]: this.table.bind(this),
      [GUIDES_HTML_TAGS.P]: this.p.bind(this),
      [GUIDES_HTML_TAGS.IMG]: this.img.bind(this),
      [GUIDES_HTML_TAGS.A]: this.a.bind(this),
      [GUIDES_HTML_TAGS.CODE]: this.code.bind(this),
      [GUIDES_HTML_TAGS.UL]: this.ul.bind(this),
      [GUIDES_HTML_TAGS.OL]: this.ol.bind(this),
      [GUIDES_HTML_TAGS.LI]: this.li.bind(this),
      [GUIDES_HTML_TAGS.H3]: this.h3.bind(this),
      [GUIDES_HTML_TAGS.H4]: this.h4.bind(this),
      [GUIDES_HTML_TAGS.SPAN]: this.span.bind(this),
      [GUIDES_HTML_TAGS.EM]: this.em.bind(this),
      [GUIDES_HTML_TAGS.STRONG]: this.strong.bind(this),
      [GUIDES_HTML_TAGS.KBD]: this.kbd.bind(this),
      [GUIDES_HTML_TAGS.DL]: this.dl.bind(this),
      [GUIDES_HTML_TAGS.DT]: this.dt.bind(this),
      [GUIDES_HTML_TAGS.DD]: this.dd.bind(this),
      [GUIDES_HTML_TAGS.TEXT]: this.text.bind(this),
      [GUIDES_HTML_TAGS.SUMMARY]: this.summary.bind(this),
      [GUIDES_HTML_TAGS.DETAILS]: this.details.bind(this),
      [GUIDES_CUSTOM_TAGS.CODEBLOCK]: this.codeblock.bind(this),
      [GUIDES_CUSTOM_TAGS.INLINECODE]: this.inlineCode.bind(this),
      [GUIDES_CUSTOM_TAGS.LISTINGBLOCK]: this.listingblock.bind(this),
      [GUIDES_CUSTOM_TAGS.ICON]: this.icon.bind(this),
      [GUIDES_CUSTOM_TAGS.NOTE]: this.alert.bind(this),
      [GUIDES_CUSTOM_TAGS.TIP]: this.alert.bind(this),
      [GUIDES_CUSTOM_TAGS.IMPORTANT]: this.alert.bind(this),
      [GUIDES_CUSTOM_TAGS.CAUTION]: this.alert.bind(this),
      [GUIDES_CUSTOM_TAGS.WARNING]: this.alert.bind(this),
      [GUIDES_CUSTOM_TAGS.BUTTON]: this.button.bind(this),
      [GUIDES_CUSTOM_TAGS.INLINEBUTTON]: this.inlineButton.bind(this),
      [GUIDES_CUSTOM_TAGS.HIGHLIGHT]: this.highlight.bind(this),
    };
    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    this.navigate = (scope: APP_SCOPE, search?: Record<string, string | string[]>, state?: RouterState): void => {
      logger.warn('No navigation callback registered');
    };
  }

  private findNode(json: DomJson, predicate: (value: DomJson) => boolean) {
    if (predicate(json)) {
      return json;
    }

    if (json.children.length === 0) {
      return null;
    }
    const childrenResult: (DomJson | null)[] = json.children.map((child) => this.findNode(child, predicate));
    const result: DomJson | null | undefined = childrenResult.find((child) => child !== null);
    return result ?? null;
  }

  private findNodeByKeyValue<T extends keyof DomJson>(
    json: DomJson,
    key: T,
    predicate: (value: DomJson[T]) => boolean,
  ) {
    return this.findNode(json, (node) => predicate(node[key]));
  }

  replaceTextNode(json: DomJson, pattern: string, replacement: string) {
    const newJson = structuredClone(json);

    const textNode = this.findNode(
      newJson,
      (node) => node.name === '#text' && typeof node.value === 'string' && node.value.includes(pattern),
    );

    if (typeof textNode?.value === 'string') {
      textNode.value = textNode.value.replace(pattern, replacement);
    }

    return newJson;
  }

  private compareTag(value: string, tag: GUIDES_HTML_TAGS | GUIDES_CUSTOM_TAGS) {
    if (isHtmlTag(value)) {
      return value === tag;
    }
    return false;
  }

  hasTag(
    json: DomJson | undefined,
    tagName: GUIDES_HTML_TAGS | GUIDES_CUSTOM_TAGS,
    attributes: Record<string, string> = {},
  ) {
    if (isNullish(json)) {
      return false;
    }

    const node = this.findNodeByKeyValue(json, 'name', (value) => this.compareTag(value, tagName));

    if (node === null) {
      return false;
    }

    return isMatch(node.attributes ?? {}, attributes);
  }

  getTitleFromDomJsonByTagName(json: DomJson | undefined, tagName: GUIDES_HTML_TAGS | GUIDES_CUSTOM_TAGS) {
    if (isNullish(json)) {
      return '';
    }
    const h2Element = this.findNodeByKeyValue(json, 'name', (value) => this.compareTag(value, tagName));
    if (h2Element === null) {
      return '';
    }
    const textElement = this.findNodeByKeyValue(h2Element, 'name', (value) =>
      this.compareTag(value, GUIDES_HTML_TAGS.TEXT),
    );
    return textElement?.value ?? '';
  }

  getPageLaunchCallbackFromDomJson(json: DomJson | undefined) {
    if (isNullish(json)) {
      return undefined;
    }
    const pageLaunchElement = this.findNodeByKeyValue(json, 'name', (value) =>
      this.compareTag(value, GUIDES_CUSTOM_TAGS.PAGELAUNCH),
    );
    if (pageLaunchElement === null) {
      return undefined;
    }
    if (pageLaunchElement.attributes?.role === GUIDES_ACTIONS.NX_TAB_NAV) {
      return () => {
        const scope = pageLaunchElement.attributes?.tab?.toLocaleLowerCase();
        if (isAppScope(scope) && typeof this.navigate !== 'undefined') {
          this.navigate(scope);
        }
      };
    }
    return undefined;
  }

  private div(json: DomJson, parentJson: DomJson, key?: number) {
    return (
      <div className={`${json.classList.map((c) => cx(classes[c])).join(' ')} ${cx(classes.div)}`} key={key}>
        {json.children.map((child, i) => this.json2Jsx(child, json, i))}
      </div>
    );
  }

  private table(json: DomJson, parentJson: DomJson, key?: number) {
    let columns: ColumnDef<TableDataFormat>[] = [];
    let data: TableDataFormat[] = [];
    const theadElement = this.findNodeByKeyValue(json, 'name', (value) =>
      this.compareTag(value, GUIDES_HTML_TAGS.THEAD),
    );
    const tbodyElement = this.findNodeByKeyValue(json, 'name', (value) =>
      this.compareTag(value, GUIDES_HTML_TAGS.TBODY),
    );
    if (tbodyElement !== null && tbodyElement.children.length > 0) {
      const sampleTrElementChildren = tbodyElement.children[0]?.children ?? [];
      columns = sampleTrElementChildren.map((_, i) => ({
        id: String(i),
        accessorKey: String(i),
        cell: (props) => {
          const value = props.getValue() as DomJson;
          return <>{value.children.map((child, index) => this.json2Jsx(child, json, index))}</>;
        },
      }));
      data = tbodyElement.children.map((child) =>
        child.children.reduce<TableDataFormat>((prev, curr, index) => ({ ...prev, [index]: curr }), {}),
      );
    }
    if (theadElement !== null) {
      const headTrElement = this.findNodeByKeyValue(theadElement, 'name', (value) =>
        this.compareTag(value, GUIDES_HTML_TAGS.TR),
      );
      if (headTrElement !== null) {
        headTrElement.children.forEach((child, i) => {
          const column = columns[i];
          if (!isNullish(column)) {
            column.header = () => <div>{child.children.map((c, index) => this.json2Jsx(c, child, index))}</div>;
          }
        });
      }
    }
    return (
      <div key={key} className={`my-4 ${cx(classes.div)}`}>
        <SimpleTable columns={columns} data={data} />
      </div>
    );
  }

  private p(json: DomJson, parentJson: DomJson, key?: number) {
    return this.compareTag(parentJson.name, GUIDES_HTML_TAGS.LI) ? (
      <Fragment key={key}>{json.children.map((child, i) => this.json2Jsx(child, json, i))}</Fragment>
    ) : (
      <div key={key} className={`my-4 ${cx(classes.div)}`}>
        {json.children.map((child, i) => this.json2Jsx(child, json, i))}
      </div>
    );
  }

  private img(json: DomJson, parentJson: DomJson, key?: number) {
    if (isNullish(json.attributes?.src)) {
      return null;
    }
    return (
      <div key={key} className={`my-4 ${cx(classes.div)}`}>
        <Image
          src={json.attributes.src}
          alt={json.attributes.alt ?? undefined}
          title={json.attributes.caption ?? undefined}
        />
      </div>
    );
  }

  private a(json: DomJson, parentJson: DomJson, key?: number) {
    const textElement = this.findNodeByKeyValue(json, 'name', (value) => this.compareTag(value, GUIDES_HTML_TAGS.TEXT));
    return (
      textElement && (
        <TextLink key={key} isExternalLink href={json.attributes?.href ?? undefined}>
          {textElement.value ?? ''}
        </TextLink>
      )
    );
  }

  private code(json: DomJson, parentJson: DomJson, key?: number) {
    return (
      <code
        key={key}
        className="bg-palette-neutral-bg-default text-palette-neutral-text-default mx-0.5 rounded-sm px-1"
      >
        {json.children.map((child, i) => this.json2Jsx(child, json, i))}
      </code>
    );
  }

  private ul(json: DomJson, parentJson: DomJson, key?: number) {
    return (
      <ul key={key} className={`${this.isSafari ? 'list-inside' : 'ml-[12.5px] list-outside'} list-disc`}>
        {json.children.map((child, i) => this.json2Jsx(child, json, i))}
      </ul>
    );
  }

  private ol(json: DomJson, parentJson: DomJson, key?: number) {
    return (
      <ol key={key} className={`${this.isSafari ? 'list-inside' : 'ml-[15px] list-outside'} list-decimal`}>
        {json.children.map((child, i) => this.json2Jsx(child, json, i))}
      </ol>
    );
  }

  private li(json: DomJson, parentJson: DomJson, key?: number) {
    return (
      <li className="list-item" key={key}>
        <div className={`overflow-x-auto ${cx(classes['nested-item'])}`}>
          {json.children.map((child, i) => this.json2Jsx(child, json, i))}
        </div>
      </li>
    );
  }

  private h3(json: DomJson, parentJson: DomJson, key?: number) {
    return (
      <h6 key={key} className="mb-4 mt-5">
        {json.children.map((child, i) => this.json2Jsx(child, json, i))}
      </h6>
    );
  }

  private h4(json: DomJson, parentJson: DomJson, key?: number) {
    return <strong key={key}>{json.children.map((child, i) => this.json2Jsx(child, json, i))}</strong>;
  }

  private span(json: DomJson, parentJson: DomJson, key?: number) {
    return <span key={key}>{json.children.map((child, i) => this.json2Jsx(child, json, i))}</span>;
  }

  private em(json: DomJson, parentJson: DomJson, key?: number) {
    return <em key={key}>{json.children.map((child, i) => this.json2Jsx(child, json, i))}</em>;
  }

  private strong(json: DomJson, parentJson: DomJson, key?: number) {
    return <strong key={key}>{json.children.map((child, i) => this.json2Jsx(child, json, i))}</strong>;
  }

  private kbd(json: DomJson, parentJson: DomJson, key?: number) {
    return (
      <kbd key={key} className="mx-1">
        {json.children.map((child, i) => this.json2Jsx(child, json, i))}
      </kbd>
    );
  }

  private dl(json: DomJson, parentJson: DomJson, key?: number) {
    return (
      <dl key={key} className="ml-1">
        {json.children.map((child, i) => this.json2Jsx(child, json, i))}
      </dl>
    );
  }

  private dt(json: DomJson, parentJson: DomJson, key?: number) {
    return (
      <dt key={key} className="n-subheading-medium mb-2 mt-4">
        {json.children.map((child, i) => this.json2Jsx(child, json, i))}
      </dt>
    );
  }

  private dd(json: DomJson, parentJson: DomJson, key?: number) {
    return <dd key={key}>{json.children.map((child, i) => this.json2Jsx(child, json, i))}</dd>;
  }

  private inlineCode(json: DomJson, parentJson: DomJson, key?: number) {
    const textElement = this.findNodeByKeyValue(json, 'name', (value) => value === '#text');
    const cmd = textElement?.value ?? '';
    return <RunnableCodeInlined code={cmd} key={key} />;
  }

  private codeblock(json: DomJson, parentJson: DomJson, key?: number) {
    const contentElement = this.findNodeByKeyValue(json, 'name', (value) =>
      this.compareTag(value, GUIDES_CUSTOM_TAGS.CONTENT),
    );

    if (contentElement === null) {
      return null;
    }

    const titleElement = this.findNodeByKeyValue(json, 'name', (value) => this.compareTag(value, GUIDES_HTML_TAGS.DIV));
    const contentTextElement = this.findNodeByKeyValue(contentElement, 'name', (value) =>
      this.compareTag(value, GUIDES_HTML_TAGS.TEXT),
    );
    const language = json.attributes?.language ?? 'text';
    const showCopyIcon = json.attributes?.copy === 'true';

    return (
      <div key={key} className={`my-4 ${cx(classes.div)}`}>
        <RunnableCodeBlock
          headerTitle={
            titleElement && titleElement.children.length > 0 ? (
              <div>{titleElement.children.map((child, i) => this.json2Jsx(child, json, i))}</div>
            ) : null
          }
          language={isCodeLanguage(language) ? language : 'text'}
          code={contentTextElement?.value ?? ''}
          showCopyIcon={showCopyIcon}
        />
      </div>
    );
  }

  private listingblock(json: DomJson, parentJson: DomJson, key?: number) {
    const contentElement = this.findNodeByKeyValue(json, 'name', (value) =>
      this.compareTag(value, GUIDES_CUSTOM_TAGS.CONTENT),
    );
    if (contentElement === null) {
      return null;
    }
    const contentTextElement = this.findNodeByKeyValue(contentElement, 'name', (value) =>
      this.compareTag(value, GUIDES_HTML_TAGS.TEXT),
    );
    return (
      <pre key={key} className="w-full overflow-x-auto">
        <code>{contentTextElement?.value}</code>
      </pre>
    );
  }

  private icon(json: DomJson, parentJson: DomJson, key?: number) {
    const ICONS_LEGACY_MAPPING: Record<string, string> = {
      ArrowRight: 'ArrowRightCircleIconOutline',
      DuplicateIcon: 'Square2StackIconOutline',
      'play circle': 'PlayCircleIconOutline',
      'play-circle': 'PlayCircleIconOutline',
      PlayIcon: 'PlayCircleIconOutline',
    };

    const ICONS: Record<string, SvgIcon> = {
      ArrowRightCircleIconOutline,
      PlayCircleIconOutline,
      Square2StackIconOutline,
    };

    if (typeof json.attributes?.alt === 'undefined' || json.attributes.alt === null) {
      return null;
    }

    const iconName = ICONS_LEGACY_MAPPING[json.attributes.alt];

    if (typeof iconName === 'undefined') {
      return null;
    }

    const Icon = ICONS[iconName];

    if (typeof Icon === 'undefined') {
      return null;
    }

    return <Icon key={key} className="mx-1 inline h-4 w-4" />;
  }

  private alert(json: DomJson, parentJson: DomJson, key?: number) {
    const warningTags: string[] = [GUIDES_CUSTOM_TAGS.CAUTION, GUIDES_CUSTOM_TAGS.WARNING];
    const titleElement = this.findNodeByKeyValue(json, 'classList', (value) => value.includes('title'));
    const descriptionElement = this.findNodeByKeyValue(json, 'classList', (value) => value.includes('content'));
    return (
      <Banner
        key={key}
        className={`my-4 ${cx(classes.div)}`}
        type={warningTags.includes(json.name) ? 'warning' : 'info'}
        title={titleElement && this.json2Jsx(titleElement, parentJson, key)}
        usage="inline"
        name={json.name}
      >
        {descriptionElement &&
          descriptionElement.children.length > 0 &&
          descriptionElement.children.map((child, i) => this.json2Jsx(child, json, i))}
      </Banner>
    );
  }

  private button(json: DomJson, parentJson: DomJson, key?: number) {
    if (isNullish(json.attributes?.role)) {
      return null;
    }
    const GuideButton = ({ cb }: { cb: () => void; color?: Exclude<Neo4jColors, 'blueberry' | 'mint'> }) => {
      const [isLoading, setIsLoading] = useState(false);

      return (
        <Button
          fill="outlined"
          isLoading={isLoading}
          size="small"
          onClick={() => {
            setIsLoading(true);
            cb();
            setIsLoading(false);
          }}
        >
          {json.children.map((child, i) => this.json2Jsx(child, json, i))}
        </Button>
      );
    };

    const cb = guideButtonCallback(json, this.navigate);

    return cb ? <GuideButton key={key} cb={cb} /> : null;
  }

  private inlineButton(json: DomJson, parentJson: DomJson, key?: number) {
    if (isNullish(json.attributes?.role)) {
      return null;
    }

    const cb = guideButtonCallback(json, this.navigate);

    return cb ? (
      <span
        className="cursor-pointer font-bold underline"
        key={key}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={cb}
      >
        {json.children.map((child, i) => this.json2Jsx(child, json, i))}
      </span>
    ) : null;
  }

  private highlight(json: DomJson, parentJson: DomJson, key?: number) {
    return <HighlightComponent key={key} json={json} json2Jsx={this.json2Jsx.bind(this)} />;
  }

  private text(json: DomJson, parentJson: DomJson, key?: number) {
    return <span key={key}>{json.value}</span>;
  }

  private details(json: DomJson, parentJson: DomJson, key?: number) {
    return (
      <details key={key} className="border-palette-neutral-border-weak my-8 border-b border-t py-4">
        {json.children.map((child, i) => this.json2Jsx(child, json, i))}
      </details>
    );
  }

  private summary(json: DomJson, parentJson: DomJson, key?: number) {
    return (
      <summary className="title flex flex-grow cursor-pointer items-center justify-between font-bold" key={key}>
        {json.children.map((child, i) => this.json2Jsx(child, json, i))}
        <ChevronRightIconOutline className="ml-2 w-4 rotate-90" />
      </summary>
    );
  }

  private json2Jsx(json: DomJson, parentJson: DomJson, key?: number): JSX.Element | null {
    const renderer = this.renderer[json.name];
    if (isNullish(renderer)) {
      return null;
    }
    return renderer(json, parentJson, key);
  }

  renderDomJson2Jsx(json: DomJson | undefined) {
    if (isNullish(json)) {
      return null;
    }
    const parentJson: DomJson = { name: GUIDES_HTML_TAGS.DIV, value: null, classList: [], children: [], type: 0 };
    const startJson = this.findNodeByKeyValue(json, 'classList', (value) => value.includes('sectionbody'));
    return startJson ? this.json2Jsx(startJson, parentJson) : null;
  }

  registerNavigation(
    navigate: (scope: APP_SCOPE, search?: Record<string, string | string[]>, state?: RouterState) => void,
  ) {
    this.navigate = navigate;
  }
}
