import { Banner, TextLink } from '@neo4j-ndl/react';

const NEO4J_DOC_CDC_PREFIX = 'https://neo4j.com/docs/cdc/current';
const NEO4J_DOC_CDC_AURA = `${NEO4J_DOC_CDC_PREFIX}/get-started/aura`;
const CDCEnableLink = `${NEO4J_DOC_CDC_AURA}/#toggle-cdc-mode`;
const CDCDisableLink = `${NEO4J_DOC_CDC_AURA}/#disable-cdc`;
const PauseResumeLink = `${NEO4J_DOC_CDC_AURA}/#pause-resume`;
const RestoreLink = `${NEO4J_DOC_CDC_PREFIX}/backup-restore`;

export const CDCCloneDBWarning = () => (
  <Banner
    type="warning"
    description={
      <div className="pb-3">
        The instance you are cloning from has Change Data Capture enabled. The enrichment mode of the cloned instance
        will default to "OFF". Please re-enable your desired enrichment mode when your instance is cloned. See{' '}
        <TextLink href={CDCEnableLink} isExternalLink>
          Enable CDC
        </TextLink>{' '}
        for more information.
      </div>
    }
    usage="inline"
  />
);

export const CDCPauseDBWarning = () => (
  <Banner
    type="warning"
    description={
      <div className="pb-3">
        Pause/Resume is not recommended on a Change Data Capture enabled database, please stop your CDC application and
        reset your application cursor. Please read{' '}
        <TextLink href={PauseResumeLink} isExternalLink>
          Paused/Resumed databases
        </TextLink>{' '}
        for more info.
      </div>
    }
    usage="inline"
  />
);

export const CDCEnableWarning = () => (
  <>
    <Banner
      type="warning"
      description={
        <div className="pb-3">
          You are enabling Change Data Capture on the instance. Please read{' '}
          <TextLink href={NEO4J_DOC_CDC_AURA} isExternalLink>
            CDC on Neo4j Aura
          </TextLink>
          .
        </div>
      }
      className="my-6"
      usage="inline"
      htmlAttributes={{
        'data-testid': 'enable-cdc-warning',
      }}
    />
    <Banner
      type="warning"
      description="Before you turn on CDC, please ensure you have disabled any programmatic pausing of the database. If you need to pause your instance, please disable CDC first."
      className="mt-6"
      usage="inline"
    />
  </>
);

export const CDCDisableWarning = () => (
  <Banner
    type="warning"
    description={
      <div>
        Please note, applications dependent on Change Data Capture will stop working. See{' '}
        <TextLink href={CDCDisableLink} isExternalLink>
          Disable CDC
        </TextLink>{' '}
        for more info.
      </div>
    }
    className="mt-6"
    usage="inline"
    htmlAttributes={{
      'data-testid': 'disable-cdc-warning',
    }}
  />
);

export const CDCRestoreSnapshotWarning = () => (
  <Banner
    type="warning"
    description={
      <div>
        <div className="pb-3">
          Change Data Capture will default to "OFF" when restored from snapshot. Please re-enable your desired
          enrichment mode as soon as your instance has been restored. See{' '}
          <TextLink href={CDCEnableLink} isExternalLink>
            Enable CDC
          </TextLink>
          for more information.
        </div>
        <div>
          Please stop your Change Data Capture application and reset your application cursor. Please refer to{' '}
          <TextLink href={RestoreLink} isExternalLink>
            Guidance on Back and Restore
          </TextLink>{' '}
          for more info.
        </div>
      </div>
    }
    className="my-6"
    usage="inline"
  />
);

export const CDCRestoreSnapshotTableWarning = () => (
  <Banner
    type="warning"
    description={
      <p>
        If Change Data Capture is enabled on your instance, restoring from snapshot will default the enrichment mode of
        your instance to "OFF". Please re-enable your desired enrichment mode when your instance is restored. See{' '}
        <TextLink href={CDCEnableLink} isExternalLink>
          Toggle CDC mode
        </TextLink>{' '}
        for more information.
      </p>
    }
    className="my-6"
    usage="inline"
  />
);
