import { Typography } from '@neo4j-ndl/react';
import { isNullish } from '@nx/stdlib';
import randomColor from 'randomcolor';

import { standardFormatAbsoluteNumber } from '../../shared/ui-helpers';
import { LOCALE_TAG } from '../shared/constants';
import type { TooltipComponentType, YUplotValue } from '../shared/types';

const defaultValueFormatter: (value: YUplotValue) => string = (v) => {
  if (typeof v === 'number') {
    return standardFormatAbsoluteNumber(v).toString();
  }
  return 'No value';
};

const SideBorder = ({ dashed, color }: { dashed: boolean; color: string }) => (
  <div
    style={{
      width: '4px',
      height: '100%',
      left: '0',
      top: '0',
      zIndex: 2,
      borderRadius: '4px 0 0 4px',
      backgroundColor: dashed ? undefined : color,
      background: dashed ? `repeating-linear-gradient(0deg, ${color}, ${color} 4px, white 4px, white 5px)` : undefined,
    }}
  />
);

const DefaultChartTooltip = (valueFormatter = defaultValueFormatter): TooltipComponentType =>
  function TooltipComponent({ cursorIdx: index, uPlotInstance, seriesSummary }) {
    const { series } = uPlotInstance;
    const values = uPlotInstance.data.map((d) => d[index]);
    const allSeriesHidden = series.slice(1).every((s) => s.show === false);

    if (values.length === 0 || allSeriesHidden) {
      return null;
    }

    const [rawTimestamp] = values;
    if (isNullish(rawTimestamp)) {
      return null;
    }
    const timestamp = rawTimestamp / 1000;

    return (
      <div className="bg-palette-neutral-bg-weak shadow-overlay gap-token-4 border-palette-neutral-border-weak z-20 flex flex-col rounded-md border p-2">
        {series.map((s, i) => {
          const value = values[i];
          const invalidValue = isNullish(value);
          const seriesHidden = s.show === false;

          if (seriesHidden || invalidValue) {
            return null;
          }

          // s.stroke is undefined for the first (timestamps) series
          const stroke = typeof s.stroke === 'function' ? s.stroke(uPlotInstance, i) : undefined;
          const lineColor = typeof stroke === 'string' ? stroke : randomColor();
          const dashed = Boolean(s.dash);

          const { aggregationMeta } = s;

          const metadata = aggregationMeta?.[timestamp];

          const displayName = seriesSummary.find((ss) => ss.id === s.label)?.displayName;

          /**
           * The first index will always be the date
           * and then are all the tabular values
           */
          return i === 0 ? (
            <div key={s.label} className="flex flex-row gap-1">
              <span className="subheading-small text-palette-neutral-text-default">
                {new Date(value).toLocaleString(LOCALE_TAG, {
                  timeZoneName: 'short',
                  hour12: false,
                })}
              </span>
            </div>
          ) : (
            <div key={s.label} className="flex flex-row gap-1">
              <div className="isolate flex w-full flex-row">
                <SideBorder dashed={dashed} color={lineColor} />
                <div
                  className="py-token-4 px-token-5 gap-token-2 border-palette-neutral-border-strong relative flex w-full flex-col rounded-sm border"
                  style={{ zIndex: 1, left: '-4px' }}
                >
                  <Typography variant="subheading-medium" className="text-palette-neutral-text-weak">
                    {valueFormatter(value)}
                  </Typography>
                  <Typography variant="body-medium" className="text-palette-neutral-text-weak">
                    {displayName}
                  </Typography>
                  {metadata && (
                    <div className="gap-token-1 text-palette-neutral-text-weak py-token-2 flex flex-col">
                      <div className="gap-token-4 flex flex-row">
                        <Typography variant="body-small">Min: {valueFormatter(metadata.min)}</Typography>{' '}
                        <Typography variant="body-small">Max: {valueFormatter(metadata.max)}</Typography>{' '}
                        <Typography variant="body-small">Data points: {metadata.numberOfDataPoints} </Typography>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

export default DefaultChartTooltip;
