import { Label, LoadingSpinner, Typography } from '@neo4j-ndl/react';
import type { Instance, Project } from '@nx/state';
import { INSTANCE_STATUS } from '@nx/state';
import cx from 'classnames';
import React from 'react';

import { getOnlineStatusColor, instanceStatusText } from '../entities/helpers';

export const InstanceStatus = ({ instance, project }: { instance: Instance; project: Project }) => {
  const showLoadingSpinner = [
    INSTANCE_STATUS.DESTROYING,
    INSTANCE_STATUS.CREATING,
    INSTANCE_STATUS.RESUMING,
    INSTANCE_STATUS.PAUSING,
  ].includes(instance.instanceStatus);

  const statusText = instanceStatusText(instance, project);

  if (showLoadingSpinner) {
    return (
      <span className="flex h-6 flex-row items-center gap-1 uppercase tracking-normal">
        <LoadingSpinner aria-label={`Instance is ${statusText}`} />
        <Typography variant="body-medium">{statusText}</Typography>
      </span>
    );
  }

  return (
    <Label
      fill="clean"
      hasIcon
      color={getOnlineStatusColor(instance)}
      className={cx('truncate font-normal uppercase [&_.ndl-icon-svg]:shrink-0', {
        '[&_.ndl-label-text]:!text-success-text': instance.instanceStatus === INSTANCE_STATUS.RUNNING,
        '[&_.ndl-label-text]:!text-neutral-text-weaker': instance.instanceStatus === INSTANCE_STATUS.PAUSED,
      })}
    >
      {statusText}
    </Label>
  );
};
