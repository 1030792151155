import { useCurrentGuide, useNotificationActions } from '@nx/state';
import { isNullish } from '@nx/stdlib';
import cx from 'classnames';
import { Resizable } from 're-resizable';
import { useEffect } from 'react';

import { GuideContent } from './guide-content';

export type SidebarProps = {
  open: boolean;
  onGuideComplete?: () => void;
};

export function GuideSidebar({ open, onGuideComplete }: SidebarProps) {
  const currentGuide = useCurrentGuide();
  const { addNotification } = useNotificationActions();

  useEffect(() => {
    if (currentGuide.fetchStatus === 'error' && !isNullish(currentGuide.fetchError)) {
      addNotification({
        type: 'danger',
        title: 'Failed to load guide',
        description: currentGuide.fetchError.message ?? '',
      });
    }
  }, [addNotification, currentGuide]);

  if (currentGuide.fetchStatus === 'error' && !isNullish(currentGuide.fetchError)) {
    return null;
  }

  if (!open || isNullish(currentGuide.guide)) {
    return null;
  }

  return (
    <Resizable
      minWidth={380}
      maxWidth={'40vw'}
      defaultSize={{ width: 401, height: '100%' }}
      className={cx(
        'flex',
        'flex-col',
        'justify-between',
        'py-8',
        'px-0',
        'border-solid',
        'bg-palette-neutral-bg-weak',
        'border-palette-neutral-border-weak',
        'absolute',
        'md:relative',
        'left-0',
        'z-10',
        'border-r',
        'ndl-theme-dark',
      )}
      style={{ position: undefined }}
      enable={{
        top: false,
        right: false,
        bottom: false,
        left: true,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      as="aside"
    >
      <GuideContent guideId={currentGuide.guide.id} guide={currentGuide.guide} onComplete={onGuideComplete} />
    </Resizable>
  );
}
