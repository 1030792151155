import { Tooltip, Typography } from '@neo4j-ndl/react';
import type { Consumption } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { useDarkMode } from '@nx/ui';
import cn from 'classnames';

import { formatDollars } from '../../../../utils';

export const TotalConsumption = ({ totalCost, currency, discount }: Consumption) => {
  const isDarkMode = useDarkMode();

  let outstandingAmount = parseFloat(totalCost);
  if (discount) {
    outstandingAmount -= parseFloat(discount.dollarAmountOff);
  }
  const totalCostStr = currency === 'dollars' ? formatDollars(totalCost) : totalCost;
  const outstandingAmountStr =
    currency === 'dollars' ? formatDollars(Math.abs(outstandingAmount)) : Math.abs(outstandingAmount);

  const hasDiscount = isNotNullish(discount);

  return (
    <Typography variant="body-medium" className="text-neutral-text-weaker mx-2">
      {outstandingAmount < 0
        ? 'Credits spent during the selected timeframe:'
        : 'Amount used in the selected timeframe:'}{' '}
      <Tooltip type="simple" isDisabled={!hasDiscount}>
        <Tooltip.Trigger hasButtonWrapper>
          <span className={cn('font-bold', { underline: hasDiscount })}>{outstandingAmountStr}</span>
        </Tooltip.Trigger>

        {hasDiscount && (
          <Tooltip.Content className="w-[320px]">
            <Tooltip.Header>Cost breakdown</Tooltip.Header>
            <Tooltip.Body className="mt-1">
              <Typography
                variant="body-medium"
                as="div"
                className={cn('flex flex-col gap-1', {
                  'text-light-neutral-text-weak': isDarkMode,
                  'text-dark-neutral-text-weak': !isDarkMode,
                })}
              >
                <div className="flex justify-between">
                  Subtotal:{' '}
                  <span
                    className={cn({
                      'text-light-neutral-text-default': isDarkMode,
                      'text-dark-neutral-text-default': !isDarkMode,
                    })}
                  >
                    {totalCostStr}
                  </span>
                </div>

                <div className="flex flex-col">
                  <div className="flex justify-between">
                    Discount:{' '}
                    <span
                      className={cn({
                        'text-light-danger-text': isDarkMode,
                        'text-dark-danger-text': !isDarkMode,
                      })}
                    >
                      -{currency === 'dollars' ? formatDollars(discount.dollarAmountOff) : discount.dollarAmountOff}
                    </span>
                  </div>
                  <Typography
                    variant="body-small"
                    as="div"
                    className={cn({
                      'text-light-neutral-text-weaker': isDarkMode,
                      'text-dark-neutral-text-weaker': !isDarkMode,
                    })}
                  >
                    {discount.promotionName}
                  </Typography>
                </div>

                <div className="flex justify-between">
                  Total:{' '}
                  <span
                    className={cn({
                      'text-light-neutral-text-default': isDarkMode,
                      'text-dark-neutral-text-default': !isDarkMode,
                    })}
                  >
                    {outstandingAmountStr}
                  </span>
                </div>
              </Typography>
            </Tooltip.Body>
          </Tooltip.Content>
        )}
      </Tooltip>
    </Typography>
  );
};
