// Replaces process.env configuration we had in vite.config
const isDev = import.meta.env.DEV;
export const config = {
  NODE_ENV: isDev ? 'development' : 'production',
  PERSISTSTORE: true,
  SERVER: 'local',
  NO_CHECKS: false,
  SETUP_SENTRY: false,
  SETUP_SEGMENT: false,
  TARGET: 'standalone',
} as const;
