export type RouterState =
  | {
      actionRequiresConnection?: boolean;
      returnTo?: string;
      opsLogs?: {
        timeRange?: {
          startTime: Date;
          endTime: Date;
        };
        dbmsFilters?: {
          drivers?: string[];
        };
        querySearchString?: string;
        requestLogsOnLoad?: boolean;
      };
    }
  | undefined;

/**
 * RouterState type guard.
 *
 * @param state Router state
 * @returns
 */
export function isRouterState(state: unknown): state is RouterState {
  if (typeof state !== 'object') {
    return false;
  }

  if (state === null) {
    return false;
  }

  return true;
}
