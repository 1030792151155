import { Banner, Dialog } from '@neo4j-ndl/react';
import { PLAN_TYPE, TIER } from '@nx/state';
import type { Instance, InstanceSize, Project } from '@nx/state';
import { useCallback } from 'react';

import { calcMonthlyCost, formatDollars } from '../../utils';
import {
  GdsPluginField,
  VectorOptimizedField,
  getValidGdsPluginValue,
  getValidVectorOptimizedValue,
} from '../create/form-additional-settings';
import { InstanceSizePicker } from '../create/size-picker';
import type { InstanceConfigureFormData } from './form-data';

interface PriceDescriptionProps {
  planType: PLAN_TYPE;
  tier: TIER;
  size: InstanceSize;
}

export const PriceDescription = ({ planType, tier, size }: PriceDescriptionProps) => {
  if (planType === PLAN_TYPE.ENTERPRISE) {
    return (
      <Banner
        description="Please refer to your contract for pricing."
        type="info"
        usage="inline"
        htmlAttributes={{
          'data-testid': 'custom-pricing',
        }}
      />
    );
  }

  if (tier === TIER.GDS) {
    const costPerHour = formatDollars(size.costPerHour);

    return (
      <Banner
        description={`You will be charged ${costPerHour} per hour while an instance of this size is running.`}
        type="warning"
        usage="inline"
        htmlAttributes={{
          'data-testid': 'aurads-pricing',
        }}
      />
    );
  }

  if ([TIER.PROFESSIONAL, TIER.MTE].includes(tier)) {
    const costPerHour = formatDollars(size.costPerHour);
    const costPerMonth = formatDollars(calcMonthlyCost(size.costPerHour));

    return (
      <Banner
        type="warning"
        usage="inline"
        htmlAttributes={{
          'data-testid': 'auradb-pricing',
        }}
      >
        You will be charged {costPerHour} per hour while an instance of this size is running, and continuously running
        this database for 1 month will cost {costPerMonth} based on a 730 hour average month.
      </Banner>
    );
  }

  throw new Error(`No price description is defined for tier ${tier}`);
};

type ResizeInstanceProps = {
  data: InstanceConfigureFormData;
  onChange: (data: InstanceConfigureFormData) => void;
  instance: Instance;
  project: Project;
  // Optional because sometimes we are a unicorn that doesn't
  // match a regular size
  initialSize?: InstanceSize;
  sizes: InstanceSize[];
};

export const validate = (data: InstanceConfigureFormData, initialSize?: InstanceSize) => {
  if (!data.size) {
    return false;
  }

  const sizeChanged = data.size.sizeId !== initialSize?.sizeId;
  if (sizeChanged) {
    return data.confirmed;
  }
  return true;
};

export const ConfigureInstanceForm = ({
  data,
  onChange,
  instance,
  project,
  initialSize,
  sizes,
}: ResizeInstanceProps) => {
  const resetDataIfNeeded = useCallback((newData: InstanceConfigureFormData) => {
    const resetData = {
      ...newData,
      gdsPlugin: getValidGdsPluginValue(newData),
      vectorOptimized: getValidVectorOptimizedValue(newData),
    };

    return resetData;
  }, []);

  const handleSizeChange = useCallback(
    (size: InstanceSize | undefined) => onChange(resetDataIfNeeded({ ...data, size })),
    [onChange, data, resetDataIfNeeded],
  );

  const handleGdsPluginChange = useCallback(
    (gdsPlugin: boolean) => onChange(resetDataIfNeeded({ ...data, gdsPlugin })),
    [onChange, data, resetDataIfNeeded],
  );

  const handleVectorOptimizedChange = useCallback(
    (vectorOptimized: boolean) => onChange(resetDataIfNeeded({ ...data, vectorOptimized })),
    [onChange, data, resetDataIfNeeded],
  );

  const showUnitPricing = project.planType === PLAN_TYPE.SELF_SERVE;
  const showMonthlyCost = showUnitPricing && [TIER.PROFESSIONAL, TIER.ENTERPRISE].includes(instance.tier);

  if (!instance.availableActions.resize.enabled) {
    return <p>Resizing unavailable: {instance.availableActions.resize.message}</p>;
  }

  return (
    <>
      <InstanceSizePicker
        onChange={handleSizeChange}
        data={data}
        hidePricing={!showUnitPricing}
        options={sizes}
        hideMonthlyCost={!showMonthlyCost}
        initialSize={initialSize}
        resizeThreshold={instance.resizeThreshold}
      />
      {/* ADDITIONAL SETTINGS */}
      <div>
        <Dialog.Subtitle className="text-xl">Additional Settings</Dialog.Subtitle>
        {project.capabilities.gds_plugin && instance.tier === TIER.PROFESSIONAL && (
          <GdsPluginField data={data} onChange={handleGdsPluginChange} />
        )}
        <VectorOptimizedField data={data} onChange={handleVectorOptimizedChange} />
      </div>
    </>
  );
};
