import type { DynamicBaseQueryFn } from '@nx/state';
import type { EndpointBuilder } from '@reduxjs/toolkit/query';

import type { NAME } from './shared-storage.api';

export type TagTypes = 'Perspective';

export const tagTypes: TagTypes[] = ['Perspective'] as const;

export type Builder = EndpointBuilder<DynamicBaseQueryFn, TagTypes, typeof NAME>;
