import { useConnections } from '@nx/state';
import { isNullish } from '@nx/stdlib';

import { mapConnectionDetailsToConnection } from '../connection-form-utils';
import type { Connection } from '../connection-form.types';
import { usePassedConnectionDetails } from './use-passed-connection-details';

export const useInitialConnection = (fixedConnectionUrl?: string): { connection: Connection } | undefined => {
  const recentConnections = useConnections();
  const latestConnection = isNullish(fixedConnectionUrl)
    ? (recentConnections.find((connection) => connection.isAuraInstanceFromAPI !== false) ?? recentConnections[0])
    : recentConnections.find((connection) => `${connection.protocol}//${connection.hostname}` === fixedConnectionUrl);

  const passedConn = usePassedConnectionDetails();

  if (isNullish(fixedConnectionUrl) && passedConn) {
    return {
      connection: { ...mapConnectionDetailsToConnection(passedConn) },
    };
  }

  if (latestConnection) {
    return { connection: latestConnection };
  }

  return undefined;
};
