import { Dialog } from '@neo4j-ndl/react';

import type { HelpTooltipProps } from '../charts/help-tooltips/help-tooltip';
import HelpTooltip from '../charts/help-tooltips/help-tooltip';

type Props = {
  open: boolean;
  onClose: () => void;
  metricTitle: string;
  tooltipProps: HelpTooltipProps;
};

export const MetricInfoModal = ({ open, onClose, metricTitle, tooltipProps }: Props) => (
  <Dialog size="small" isOpen={open} onClose={onClose}>
    <Dialog.Header className="h6">{metricTitle}</Dialog.Header>
    <Dialog.Description>
      <HelpTooltip {...tooltipProps} />
    </Dialog.Description>
  </Dialog>
);
