/**
 * Module with Redux middleware triggering analytics events based on Redux actions.
 */
import {
  type AnalyticsAdapter,
  type AnalyticsTrackPayload,
  FRAMEWORK_EVENTS,
  GUIDE_EVENTS,
} from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { isInvalidUrlConnectionError, isNeo4jError } from '@nx/errors';
import { URLs } from '@nx/stdlib';
import type { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { createAction, createListenerMiddleware, isAnyOf, isRejectedWithValue } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { selectAppContext, selectNetworkPolicies, selectUserId } from '../selectors';
import type { AppContextSliceState } from '../slices/app-context-slice';
import { setActiveProject } from '../slices/app-context-slice';
import type { CapabilitiesState } from '../slices/capabilities-slice';
import type { ConfigurationState } from '../slices/configuration/configuration-slice';
import { selectConfiguration } from '../slices/configuration/selectors';
import type { ConnectionState } from '../slices/connections/connections-slice';
import { connectDriver, disconnectDriver } from '../slices/connections/connections-slice';
import type { GuidesState } from '../slices/guides';
import { setGuideCurrentPage } from '../slices/guides/guides-slice';
import type { MetadataState } from '../slices/metadata-slice';
import type { SessionState } from '../slices/session-slice';
import type { SettingsState } from '../slices/settings-slice';

export const trackPage = createAction('analytics/page');
export const trackEvent = createAction<AnalyticsTrackPayload>('analytics/track');

export default function createAnalyticsEventsMiddleware<
  State extends {
    appContext: AppContextSliceState;
    configuration: ConfigurationState;
    guides: GuidesState;
    metadata: MetadataState;
    session: SessionState;
    settings: SettingsState;
    capabilities: CapabilitiesState;
    connections: ConnectionState;
  },
  Dispatch extends ThunkDispatch<State, { analytics: AnalyticsAdapter }, UnknownAction>,
>(config: { analytics: AnalyticsAdapter }) {
  const middleware = createListenerMiddleware<State, Dispatch, { analytics: AnalyticsAdapter }>({
    extra: {
      analytics: config.analytics,
    },
  });

  // Forward corresponding actions to analytics adapter
  middleware.startListening({
    predicate(action, currentState) {
      if (!trackEvent.match(action) && !trackPage.match(action)) {
        return false;
      }

      // Ignore everything if tracking is disallowed
      return selectNetworkPolicies(currentState).usageTrackingAllowed;
    },
    async effect(action, listenerApi) {
      if (trackEvent.match(action)) {
        await listenerApi.extra.analytics.trackEvent(action.payload);
      }

      if (trackPage.match(action)) {
        await listenerApi.extra.analytics.trackNavigation();
      }
    },
  });

  // Update tracking ID if it has changed in state or if the user is logged in
  middleware.startListening({
    predicate(_, currentState, previousState) {
      return (
        selectNetworkPolicies(currentState).usageTrackingAllowed &&
        (selectUserId(currentState) !== selectUserId(previousState) ||
          selectConfiguration(currentState).tracking.id !== selectConfiguration(previousState).tracking.id ||
          selectAppContext(currentState).activeOrgId !== selectAppContext(previousState).activeOrgId)
      );
    },
    async effect(_, listenerApi) {
      const state = listenerApi.getState();

      const userId = selectUserId(state);
      const { tracking } = selectConfiguration(state);
      const id = userId ?? tracking.id;
      const orgId = selectAppContext(state).activeOrgId;

      if (id !== undefined) {
        await listenerApi.extra.analytics.identify({ userId: id, orgId });

        // We also set the user ID in Sentry
        Sentry.setUser({ id });
      }
    },
  });

  // Update Active Project ID if the project changes
  middleware.startListening({
    actionCreator: setActiveProject,
    effect(_, listenerApi) {
      const state = listenerApi.getState();
      const { activeProjectId: id } = selectAppContext(state);
      Sentry.setContext('project', { id });
    },
  });

  // Driver events
  middleware.startListening({
    matcher: isAnyOf(connectDriver.fulfilled, connectDriver.rejected),
    effect(action, listenerApi) {
      if (connectDriver.rejected.match(action)) {
        const error =
          isRejectedWithValue(action) && isInvalidUrlConnectionError(action.payload)
            ? action.payload.error
            : action.error;

        listenerApi.dispatch(
          trackEvent({
            event: FRAMEWORK_EVENTS.CONNECT,
            properties: {
              successful: false,
              code: error.code,
              gqlStatus: isNeo4jError(error) ? error.gqlStatus : undefined,
              type: action.meta.arg.credentials.type,
            },
            scope: APP_SCOPE.framework,
          }),
        );
      } else if (connectDriver.fulfilled.match(action)) {
        const neo4jUrl = new URLs.Neo4jURL(action.meta.arg.url);

        listenerApi.dispatch(
          trackEvent({
            event: FRAMEWORK_EVENTS.CONNECT,
            properties: {
              successful: true,
              protocol: neo4jUrl.protocol,
              isLocalhost: URLs.isLocalhost(neo4jUrl.hostname),
              type: action.meta.arg.credentials.type,
            },
            scope: APP_SCOPE.framework,
          }),
        );
      }
    },
  });

  middleware.startListening({
    actionCreator: disconnectDriver.fulfilled,
    effect(_, listenerApi) {
      listenerApi.dispatch(
        trackEvent({
          scope: APP_SCOPE.framework,
          event: FRAMEWORK_EVENTS.DISCONNECT,
        }),
      );
    },
  });

  // Guides events local state
  middleware.startListening({
    actionCreator: setGuideCurrentPage,
    effect(action, listenerApi) {
      const state = listenerApi.getOriginalState();
      const { id, pageNumber } = action.payload;
      const guide = state.guides.guides[id];
      const guideStatus = state.guides.guidesStatuses[id];

      if (guide === undefined || guideStatus === undefined) {
        return;
      }

      // Guide page index starts with 0.
      if (pageNumber > guideStatus.currentPage) {
        listenerApi.dispatch(
          trackEvent({
            event: GUIDE_EVENTS.NEXT,
            properties: { id, pageNumber },
            scope: APP_SCOPE.framework,
          }),
        );
      }

      if (pageNumber === guide.totalPages - 1) {
        listenerApi.dispatch(
          trackEvent({
            event: GUIDE_EVENTS.COMPLETE,
            properties: { id },
            scope: APP_SCOPE.framework,
          }),
        );
      }
    },
  });

  return middleware.middleware;
}
