import type * as ImportShared from '@nx/import-shared';
import { neo4jVersionUtil } from '@nx/neo4j-version-utils';
import { isNullish } from '@nx/stdlib';

import { APP_VERSION } from '../constants';
import { isDataModelJsonStruct } from '../data-model/data-model-json-formatter';
import {
  alignRelationshipDirectionsInArrowsByDataModel,
  eliminateInvalidDataFromDataModel_0_0_1,
} from '../data-model/data-sanitisation';
import type { DeprecatedDataModel } from '../data-model/deprecated/types';
import { isAnyDeprecatedDataModel } from '../data-model/deprecated/types';
import { deserializeArrowsState } from '../deprecated-types/arrows-types';
import type { ConfigurationState } from '../state/reducers/configurations';
import { initialState as configurationsInitialState } from '../state/reducers/configurations';
import type { DataModelState } from '../state/reducers/data-model';
import type { RootState } from '../state/store';
import type { ImportGraphDataModel, SerialisableApplicationState, SerialisableGraphDataModelState } from '../types';
import { dehydrateVisualisationState } from '../visualisation/utils';

export const getDataModelJsonStructFromDataModelState = (state: DataModelState): ImportShared.DataModelJsonStruct => ({
  version: state.version,
  graphSchemaRepresentation: state.graphSchemaRepresentation,
  graphSchemaExtensionsRepresentation: state.graphSchemaExtensionsRepresentation,
  graphMappingRepresentation: state.graphMappingRepresentation,
  configurations: state.configurations,
});

const getGraphModelStateToSerialize = ({
  visualisation,
  dataModel,
}: {
  visualisation: ImportShared.VisualisationState;
  dataModel: DataModelState;
}): ImportShared.SerialisableGraphDataModelStateWithVersion => {
  return {
    version: APP_VERSION,
    visualisation: dehydrateVisualisationState(visualisation),
    dataModel: getDataModelJsonStructFromDataModelState(dataModel),
  };
};

export const serialize = (stateToSerialize: Record<string, unknown>, pretty = false): string => {
  return pretty ? JSON.stringify(stateToSerialize, null, '  ') : JSON.stringify(stateToSerialize);
};

// Serialize local state for user.
export const serializeUserState = (state: RootState): string => {
  const graphModel = getGraphModelStateToSerialize(state);
  const stateToSerialize = {
    ...graphModel,
    configurations: {
      sentryUserId: state.configurations.sentryUserId,
    },
  };
  return serialize(stateToSerialize);
};

// Serialize state which can be shared with other users.
export const serializeShareableState = (state: {
  visualisation: ImportShared.VisualisationState;
  dataModel: DataModelState;
}): string => {
  const stateToSerialize = getGraphModelStateToSerialize(state);
  return serialize(stateToSerialize, true);
};

const isExpectedParsedState = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: any,
): obj is SerialisableGraphDataModelState & { version: string } & { configurations?: ConfigurationState } => {
  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  const containsValidModel = isAnyDeprecatedDataModel(obj.dataModel) || isDataModelJsonStruct(obj.dataModel);
  let containsValidLegacyGraph = false;
  if (!isNullish(obj.graph)) {
    containsValidLegacyGraph = !isNullish(obj.graph.nodes) && !isNullish(obj.graph.relationships);
  }
  let containsValidVisualisationState = false;
  if (!isNullish(obj.visualisation)) {
    containsValidVisualisationState = !isNullish(obj.visualisation.nodes);
  }
  // Either contains new visualisation state or legacy graph state
  const containsValidVisualisation = containsValidLegacyGraph || containsValidVisualisationState;
  return containsValidVisualisation && containsValidModel;
};

const parseSerializedState = (
  serializedState: string,
): SerialisableGraphDataModelState & { configurations?: ConfigurationState } & { version: string } => {
  if (!serializedState) {
    throw new Error('deserialized model was missing');
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const parsedState = JSON.parse(serializedState);
  if (!isExpectedParsedState(parsedState)) {
    throw new Error('deserialized model was missing graph or valid data model');
  }
  const { version, visualisation, graph, dataModel } = parsedState;

  let dataModelCleaned = dataModel;

  // Check if version is undefined, as isExpectedParsedState check above doesn't verify this
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (version === undefined || neo4jVersionUtil.satisfies(version, '<=0.0.1')) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const oldDataModel = dataModel as DeprecatedDataModel['0.0.1'];
    dataModelCleaned = eliminateInvalidDataFromDataModel_0_0_1(oldDataModel);
  }

  let graphCleaned = graph;
  if (dataModel !== undefined && !isDataModelJsonStruct(dataModel) && graph !== undefined) {
    graphCleaned = alignRelationshipDirectionsInArrowsByDataModel(dataModel, graph);
  }

  return {
    ...parsedState,
    visualisation: visualisation,
    dataModel: dataModelCleaned,
    graph: graphCleaned,
  };
};

// Deserialize local state from browser.
export const deserializeUserState = (serializedState: string | null): SerialisableApplicationState | null => {
  if (isNullish(serializedState)) {
    return null;
  }
  const { version, visualisation, graph, dataModel, configurations } = parseSerializedState(serializedState);
  return {
    version,
    visualisation,
    graph: !isNullish(graph) ? deserializeArrowsState(graph) : undefined,
    dataModel: dataModel,
    configurations: configurations ?? configurationsInitialState,
  };
};

// Deserialize state from external file.
export const deserializeShareableState = (serializedState: string): ImportGraphDataModel => {
  const { version, graph, dataModel, visualisation } = parseSerializedState(serializedState);
  return {
    version,
    graph: !isNullish(graph) ? deserializeArrowsState(graph) : undefined,
    dataModel: dataModel,
    visualisation: visualisation,
  };
};
