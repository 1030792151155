import type { Instance, Usage } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { type ComponentProps } from 'react';

import { InstanceCard } from './instance-card';

interface InstanceListProps {
  type?: ComponentProps<typeof InstanceCard>['type'];
  instances: Instance[];
  usage?: Usage;
  onDisplayInstanceDetails?: (instanceId: string) => void;
  filter?: string;
}

export const InstanceList = ({ instances, type, usage, onDisplayInstanceDetails, filter = '' }: InstanceListProps) => {
  const normalizedFilter = filter.trim().toLocaleLowerCase();
  const filteredInstances = normalizedFilter
    ? instances.filter(({ name, id, tierDisplayName, region, cloudProvider }) =>
        [name, id, tierDisplayName, region, cloudProvider].some((str) =>
          str.toLocaleLowerCase().includes(normalizedFilter),
        ),
      )
    : instances;

  if (filteredInstances.length === 0) {
    return (
      <div className="flex h-[218px] items-center justify-center">
        <h6>No instances found</h6>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-4">
        {filteredInstances.map((instance) => {
          const instanceUsageBreakdown = usage?.breakdown?.filter((u) => u.dbid === instance.id);
          let instanceUsage: number | undefined = undefined;
          if (isNotNullish(instanceUsageBreakdown) && instanceUsageBreakdown.length > 0) {
            instanceUsage = instanceUsageBreakdown.reduce((a, item) => parseFloat(item.cost) + a, 0.0);
          }
          return (
            <InstanceCard
              type={type}
              instance={instance}
              key={instance.id}
              onOpenDetails={() => {
                if (onDisplayInstanceDetails) {
                  onDisplayInstanceDetails(instance.id);
                }
              }}
              usage={instanceUsage}
            />
          );
        })}
      </div>
    </>
  );
};
