import { neo4jVersionUtil } from '@nx/neo4j-version-utils';
import type { QueryResult } from 'neo4j-driver';

import { MINIMUM_SUPPORTED_VERSION } from './constants';
import type { BloomInfoArgs } from './queries/bloom';
import { getBloomInfo } from './queries/bloom';
import { getConfiguration } from './queries/configuration';
import type { ConnectNodesOwnArgs } from './queries/connect-nodes';
import { connectNodes } from './queries/connect-nodes';
import { listFunctions } from './queries/function/functions';
import type { GetNeighboursOwnArgs } from './queries/get-neighbours';
import { getNeighbours } from './queries/get-neighbours';
import { getGraphCounts } from './queries/graph-counts';
import { listProcedures } from './queries/procedure/procedures';
import { getSchemaMetadata } from './queries/schema-metadata';
import { getVersionAndEdition } from './queries/version-and-edition';

type InitNeo4jSDKBaseArgs = { queryCypher: (query: string) => Promise<QueryResult> };
type InitNeo4jSDKFullArgs = InitNeo4jSDKBaseArgs & { neo4jVersion: string };

export type Neo4jSDK = ReturnType<typeof createNeo4jSDK>;

export const createNeo4jSDK = (config: InitNeo4jSDKFullArgs) => {
  if (neo4jVersionUtil.compareLoose(config.neo4jVersion, MINIMUM_SUPPORTED_VERSION) === -1) {
    throw new Error(
      `Minumum supported version is ${MINIMUM_SUPPORTED_VERSION} got neo4jVersion ${config.neo4jVersion}`,
    );
  }
  const args = { ...config, neo4jVersion: config.neo4jVersion };

  return {
    getGraphCounts: () => getGraphCounts(args),
    getSchemaMetadata: () => getSchemaMetadata(args),
    getNeighbours: (own: GetNeighboursOwnArgs) => getNeighbours({ ...args, ...own }),
    getVersionAndEdition: () => getVersionAndEdition(args),
    connectNodes: (own: ConnectNodesOwnArgs) => connectNodes({ ...args, ...own }),
    listFunctions: (cypher25Enabled = false) => listFunctions({ ...args, cypher25Enabled }),
    listProcedures: (cypher25Enabled = false) => listProcedures({ ...args, cypher25Enabled }),
    getConfiguration: () => getConfiguration(args),
    getBloomInfo: (own: BloomInfoArgs) => getBloomInfo({ ...args, ...own }),
    neo4jVersion: args.neo4jVersion,
  };
};

/**
 * If neo4jVersion is not supplied we query neo4j for it first.
 */
export function initNeo4jSDK(config: InitNeo4jSDKBaseArgs): Promise<Neo4jSDK>;
export function initNeo4jSDK(config: InitNeo4jSDKFullArgs): Neo4jSDK;
export function initNeo4jSDK({
  neo4jVersion,
  queryCypher,
}: InitNeo4jSDKBaseArgs & { neo4jVersion?: string }): Neo4jSDK | Promise<Neo4jSDK> {
  if (neo4jVersion === undefined) {
    return getVersionAndEdition({ queryCypher, neo4jVersion: MINIMUM_SUPPORTED_VERSION }).then(({ version }) =>
      createNeo4jSDK({ queryCypher, neo4jVersion: version }),
    );
  }
  return createNeo4jSDK({ neo4jVersion, queryCypher });
}
