// TODO: Might need to be namespaced; it is not right now for easier refactoring
export const isNullish = (value: unknown): value is null | undefined => {
  return value === null || value === undefined;
};

export const isNotNullish = <T>(value: T): value is NonNullable<T> => {
  return value !== null && value !== undefined;
};

export const isNonEmptyString = (value: unknown): value is string => {
  return typeof value === 'string' && value !== '';
};
