import { Banner, Button, Checkbox, TextInput, Tooltip, Typography } from '@neo4j-ndl/react';
import { InformationCircleIconOutline } from '@neo4j-ndl/react/icons';
import type { Address, CardDetails, Customer } from '@nx/state';
import { getApiError } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { ControlPanel } from '@nx/ui/src/control-panel';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Suspense, lazy, useMemo } from 'react';

import { StripeAddressFieldFallback } from '../../../loading-fallbacks';
import { defaultStripeAddress, isSameAddress } from './helpers';

const StripeAddressField = lazy(() => import('./stripe-address-field'));

interface BillingData {
  card?: CardDetails;
  address?: Address;
  serviceAddress?: Address;
  isServiceAddress?: boolean;
  companyName?: string;
  email: string;
}

export interface StripeData extends Customer {
  isServiceAddress: boolean;
  address: Address;
}

export interface BillingAddressSectionProps {
  originalData?: BillingData;
  stripeData: StripeData;
  cardAddress: Address;
  isLoading: boolean;
  onDataChange: (stripeData: StripeData) => void;
  onUpdateCustomer: () => void;
  error: FetchBaseQueryError | SerializedError | undefined;
}

export function BillingAddressSection({
  stripeData: data,
  originalData,
  isLoading,
  cardAddress,
  onDataChange,
  onUpdateCustomer,
  error,
}: BillingAddressSectionProps) {
  const updateButtonDisabled = useMemo(() => {
    const isChangedCompanyName = originalData?.companyName !== data.companyName;
    const isChangedEmail = originalData?.email !== data.email;

    if (isChangedCompanyName || isChangedEmail) {
      return false;
    }

    if (data.isServiceAddress) {
      return (
        Boolean(originalData.isServiceAddress) &&
        originalData.address &&
        isSameAddress(originalData.address, data.address)
      );
    }
    return (
      !(originalData.isServiceAddress ?? false) &&
      originalData.serviceAddress &&
      isSameAddress(originalData.serviceAddress, data.address)
    );
  }, [originalData, data]);

  const handleIsServiceAddressChange = (checked: boolean) => {
    const billingAddress = originalData?.address ?? cardAddress;
    const serviceAddress = originalData?.serviceAddress ?? defaultStripeAddress();
    const address = checked ? billingAddress : serviceAddress;
    onDataChange({
      ...data,
      isServiceAddress: checked,
      address,
    });
  };
  const handleCompanyNameChange = (companyName: string) => {
    onDataChange({ ...data, companyName });
  };

  const handleEmailChange = (email: string) => {
    onDataChange({ ...data, email });
  };

  const handleAddressChange = (address: Address) => {
    onDataChange({ ...data, address });
  };

  const handleUpdateCustomer = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onUpdateCustomer();
  };

  return (
    <ControlPanel
      title={
        <div className="flex items-center gap-2">
          <Typography variant="h5">Service address</Typography>
          <Tooltip type="simple" placement="bottom" isPortaled={false}>
            <Tooltip.Trigger>
              <InformationCircleIconOutline className="size-5" aria-label="Service address information" />
            </Tooltip.Trigger>
            <Tooltip.Content className="max-w-xs">
              <Typography variant="body-medium">
                This is the physical address of the company purchasing Neo4j Aura. It is used to calculate any
                applicable sales taxes.
              </Typography>
            </Tooltip.Content>
          </Tooltip>
        </div>
      }
    >
      <form onSubmit={handleUpdateCustomer}>
        <div className="flex max-w-[532px] flex-col gap-6">
          <Checkbox
            isChecked={data.isServiceAddress}
            onChange={(event) => handleIsServiceAddressChange(event.target.checked)}
            label="Same as billing address"
          />

          <Suspense fallback={<StripeAddressFieldFallback />}>
            <StripeAddressField data={data.address} onChange={handleAddressChange} disabled={data.isServiceAddress} />
          </Suspense>
        </div>

        <div className="ndl-menu-divider mb-[26px] mt-[38px]" />

        <div className="flex max-w-[532px] flex-col gap-4">
          <TextInput
            value={data.companyName}
            onChange={(event) => handleCompanyNameChange(event.target.value)}
            isOptional
            helpText="If specified, this company name will appear on your invoices"
            isFluid
            label="Company name"
            htmlAttributes={{
              'data-testid': 'input-company-name',
            }}
          />

          <TextInput
            value={data.email}
            onChange={(event) => handleEmailChange(event.target.value)}
            isOptional
            isFluid
            label="Billing email"
            htmlAttributes={{
              'data-testid': 'input-email',
            }}
          />

          <Button
            className="self-end"
            isDisabled={updateButtonDisabled}
            type="submit"
            isLoading={isLoading}
            htmlAttributes={{
              'data-testid': 'update-billing-details',
            }}
          >
            Update
          </Button>
        </div>
        {isNotNullish(error) && <Banner description={getApiError(error).message} type="danger" usage="inline" />}
      </form>
    </ControlPanel>
  );
}
