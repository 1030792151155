import { Button, IconButton, Menu } from '@neo4j-ndl/react';
import { useRef, useState } from 'react';

type ActionablePropsUnion =
  | {
      actionableType: 'Button';
      actionableProps: React.ComponentProps<typeof Button> & { 'data-testid'?: string };
    }
  | {
      actionableType: 'IconButton';
      actionableProps: React.ComponentProps<typeof IconButton> & { 'data-testid'?: string };
    };

type MP = Omit<React.ComponentProps<typeof Menu>, 'open' | 'onClose' | 'anchorEl' | 'ref' | 'children'>;
type ControlledMenuProps = MP & {
  open: boolean;
  onOpen: () => void;
  // implement to handle default onClose (e.g. click anywhere outside menu, 2nd click on Actionable)
  onClose?: () => void;
};
type UncontrolledMenuProps = MP & {
  open?: never;
  onOpen?: never;
  onClose?: never;
};

type MenuProps = {
  menuProps: ControlledMenuProps | UncontrolledMenuProps;
  children:
    | React.ComponentProps<typeof Menu>['children']
    | ((
        internalState: Pick<ControlledMenuProps, 'open' | 'onOpen' | 'onClose'>,
      ) => React.ComponentProps<typeof Menu>['children']);
};

type ActionableWithMenuProps = MenuProps & ActionablePropsUnion;

const ActionableWithMenu = ({ actionableType, actionableProps, menuProps, children }: ActionableWithMenuProps) => {
  const { open: outerOpen, onOpen: outerOnOpen, onClose: outerOnClose, ...otherMenuProps } = menuProps;

  const [innerOpen, setInnerOpen] = useState(false);
  const menuAnchorRef = useRef(null);

  const isControlled = typeof outerOpen !== 'undefined' && Boolean(outerOnOpen);

  const open = Boolean(menuAnchorRef.current) && isControlled ? outerOpen : innerOpen;
  const onOpen = isControlled ? outerOnOpen : () => setInnerOpen(true);
  const onClose = isControlled ? outerOnClose : () => setInnerOpen(false);
  const onAction = open ? onClose : onOpen;

  return (
    <>
      {actionableType === 'IconButton' ? (
        <IconButton
          isActive={open}
          onClick={onAction}
          ref={menuAnchorRef}
          {...actionableProps}
          ariaLabel="Open context menu"
          htmlAttributes={{
            'aria-haspopup': 'true',
          }}
        />
      ) : (
        <Button
          onClick={onAction}
          ref={menuAnchorRef}
          {...actionableProps}
          htmlAttributes={{
            'aria-haspopup': 'true',
            'aria-label': 'Open context menu',
          }}
        />
      )}
      <Menu className="z-60" isOpen={open} onClose={onClose} anchorRef={menuAnchorRef} {...otherMenuProps}>
        {typeof children === 'function' ? children({ open, onOpen, onClose }) : children}
      </Menu>
    </>
  );
};

export default ActionableWithMenu;
