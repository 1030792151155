import type { SIGNAL_NAME, SignalListener, SignalPayload } from './constants';

type SignalEvent<S extends SIGNAL_NAME> = {
  type: S;
  payload: SignalPayload[S];
};

class Signals {
  private readonly deferredEvents: { [K in SIGNAL_NAME]?: (listener: SignalListener<K>) => Promise<void> } = {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly registeredListeners = new Map<SIGNAL_NAME, Set<SignalListener<any>>>();

  private deferEvent<S extends SIGNAL_NAME>(event: SignalEvent<S>) {
    const { promise, reject, resolve } = Promise.withResolvers<void>();
    async function flush(listener: SignalListener<typeof event.type>) {
      try {
        await listener(event.payload);
        resolve();
      } catch (error) {
        reject(error);
      }
    }
    // @ts-ignore-next-line could not win against TS
    this.deferredEvents[event.type] = flush;
    return promise;
  }

  on<S extends SIGNAL_NAME>(signalName: S, listener: SignalListener<S>) {
    if (!this.registeredListeners.has(signalName)) {
      this.registeredListeners.set(signalName, new Set());
    }

    const listeners = this.registeredListeners.get(signalName)!;

    listeners.add(listener);

    const deferredEvent = this.deferredEvents[signalName];
    if (deferredEvent !== undefined) {
      void deferredEvent(listener);
      delete this.deferredEvents[signalName];
    }
  }

  off<S extends SIGNAL_NAME>(signalName: S, listener: SignalListener<S>) {
    const listeners = this.registeredListeners.get(signalName);

    if (listeners !== undefined) {
      listeners.delete(listener);
    }
  }

  async emit<S extends SIGNAL_NAME>(signalName: S, payload: SignalPayload[S]) {
    const listeners = this.registeredListeners.get(signalName) ?? new Set();

    if (listeners.size === 0) {
      await this.deferEvent({ type: signalName, payload });
    } else {
      await Promise.allSettled(Array.from(listeners).map((listener) => listener(payload)));
    }
  }
}

export const signals = new Signals();
