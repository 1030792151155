import { useOutletContext } from 'react-router-dom';

import type { User } from './entities/model';

export type ProjectUsersContext = {
  users: User[];
  revokeInvite: (user: string) => void;
};

export function useProjectUsers() {
  return useOutletContext<ProjectUsersContext>();
}
