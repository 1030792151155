import classNames from 'classnames';

import { SizedTag } from '../sized-tag';

const TAG_HEIGHT = 28;
const TAG_WIDTH = 124;
const CHARS_AFTER_ELLIPSIS = 6;
const GAP_SIZE = 8;

type FilterTagsProps = {
  activeFilters: [string, string][];
  activeSearchFilters: string[];
  activeNumericalFilters: string[];
  valueFormatters: Record<string, (value: string) => string>;
};

export const FilterTags = ({
  activeFilters,
  activeSearchFilters,
  valueFormatters,
  activeNumericalFilters,
}: FilterTagsProps) => {
  if (activeFilters.length === 0 && activeSearchFilters.length === 0 && activeNumericalFilters.length === 0) {
    return null;
  }

  return (
    <div data-testid="filter-tags">
      {activeFilters.length + activeNumericalFilters.length > 0 && (
        <div
          className="mt-4 flex"
          style={{
            gap: GAP_SIZE,
            maxHeight: 10.5 * TAG_HEIGHT + 10 * GAP_SIZE,
          }}
        >
          <div className="flex flex-wrap items-center justify-start overflow-auto" style={{ gap: GAP_SIZE }}>
            <span>Filters:</span>
            {activeFilters.map(([filter, value]) => (
              <SizedTag
                htmlAttributes={{ 'data-testid': 'filter-tag' }}
                key={`${filter}${value}`}
                text={valueFormatters[filter]?.(value) ?? value}
                tagWidth={TAG_WIDTH}
                tailLength={CHARS_AFTER_ELLIPSIS}
                tooltipWidth={1.4 * TAG_WIDTH}
              />
            ))}
            {activeNumericalFilters.map((value) => (
              <SizedTag
                data-testid="filter-tag"
                key={value}
                text={value}
                tagWidth={TAG_WIDTH}
                tailLength={CHARS_AFTER_ELLIPSIS}
                tooltipWidth={1.4 * TAG_WIDTH}
              />
            ))}
          </div>
        </div>
      )}
      {activeSearchFilters.length > 0 && (
        <div
          className="mt-4 flex"
          style={{
            gap: GAP_SIZE,
            maxHeight: 10.5 * TAG_HEIGHT + 10 * GAP_SIZE,
          }}
        >
          <div
            className={classNames('flex flex-wrap items-center', 'justify-start overflow-auto')}
            style={{ gap: GAP_SIZE }}
          >
            <span>Search:</span>
            {activeSearchFilters.map((value) => (
              <SizedTag
                htmlAttributes={{ 'data-testid': 'search-tag' }}
                key={value}
                text={value}
                tagWidth={TAG_WIDTH}
                tailLength={CHARS_AFTER_ELLIPSIS}
                tooltipWidth={1.4 * TAG_WIDTH}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
