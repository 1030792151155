import type { Integer } from 'neo4j-driver';
import neo4j from 'neo4j-driver';

import { NATURAL_ORIENTATION, REVERSED_ORIENTATION, UNDIRECTED_ORIENTATION } from '../../state/gds/constants';
import type { Orientation, Procedure } from '../../state/gds/types';
import type { Relationship } from '../../types/graph';
import type { Nullable } from '../../types/utility';
import { isTruthy } from '../../types/utility';
import { numericOrder } from '../sort';

interface CypherQuery {
  cypher: `CALL ${string}` | `UNWIND ${string}`;
  parameters: Record<string, unknown>;
}

interface QueryGeneratorParams {
  projectionId: string;
  procedure: Procedure;
  logProgress?: boolean;
  selectedNodeIds: string[];
  selectedRelIds: string[];
  selectedRelWeightedProperty: Nullable<string>;
  selectedOrientation: Orientation;
}

type ProjectTriple = [Integer, Integer | null, { weighted_property: any } | null];

const getOrientationCypherMap = (orientation: Orientation, isV5OrGreater: boolean) => {
  const idScalarFunc = isV5OrGreater ? 'elementId' : 'id';
  const orientationCypherMap = {
    [NATURAL_ORIENTATION]: `MATCH (n)-[r]->(m) WHERE ${idScalarFunc}(r) IN $rels RETURN ${idScalarFunc}(n) AS source, ${idScalarFunc}(m) AS target`,
    [REVERSED_ORIENTATION]: `MATCH (n)-[r]->(m) WHERE ${idScalarFunc}(r) IN $rels RETURN ${idScalarFunc}(n) AS target, ${idScalarFunc}(m) AS source`,
    [UNDIRECTED_ORIENTATION]: `MATCH (n)-[r]-(m) WHERE ${idScalarFunc}(r) IN $rels RETURN ${idScalarFunc}(n) AS source, ${idScalarFunc}(m) AS target`,
  };

  return orientationCypherMap[orientation];
};

export const createGdsProjectionQuery = (
  projectionId: QueryGeneratorParams['projectionId'],
  procedure: QueryGeneratorParams['procedure'],
  selectedNodeIds: QueryGeneratorParams['selectedNodeIds'] = [],
  selectedRelIds: QueryGeneratorParams['selectedRelIds'] = [],
  relationships: Record<string, Relationship>,
  useDeprecatedCypherProjections: boolean,
  selectedRelWeightedProperty: QueryGeneratorParams['selectedRelWeightedProperty'] = null,
  selectedOrientation: QueryGeneratorParams['selectedOrientation'] = NATURAL_ORIENTATION,
  isV5OrGreater = true,
): CypherQuery & { numIdMap?: Map<string, string> } => {
  if (useDeprecatedCypherProjections) {
    const weightedPropertyString = isTruthy(selectedRelWeightedProperty)
      ? `,r.${selectedRelWeightedProperty} AS weighted_property',`
      : "',";

    const idScalarFunc = isV5OrGreater ? 'elementId' : 'id';

    const sortedSelectedNodeIds = isV5OrGreater
      ? selectedNodeIds.sort()
      : selectedNodeIds.map((nodeId) => parseInt(nodeId)).sort(numericOrder);

    const sortedSelectedRelIds = isV5OrGreater
      ? selectedRelIds.sort()
      : selectedRelIds.map((relId) => parseInt(relId)).sort(numericOrder);

    return {
      cypher: `CALL ${procedure}(
        '${projectionId}',
        'MATCH (n) WHERE ${idScalarFunc}(n) IN $nodes RETURN ${idScalarFunc}(n) AS id, labels(n) AS labels',
        '${getOrientationCypherMap(selectedOrientation, isV5OrGreater)} ${weightedPropertyString}
        {
          validateRelationships: false,
          parameters: {
            nodes: $selectedNodeIds,
            rels: $selectedRelIds
          }
        }
      )
      YIELD graphName
      `,
      parameters: {
        selectedNodeIds: sortedSelectedNodeIds,
        selectedRelIds: sortedSelectedRelIds,
      },
    };
  }
  const getPropertyMap = (rel: Relationship) => {
    if (selectedRelWeightedProperty) {
      return { weighted_property: rel.properties[selectedRelWeightedProperty] };
    }
    return null;
  };

  const data: ProjectTriple[] = [];
  const nodeIds = new Set<string>();
  const gdsIdToDbId = new Map<string, string>();
  const dbIdToGdsId = new Map<string, string>();
  let i = 0;

  const getNewNumericId = (originalId: string) => {
    const newNumId = isV5OrGreater ? neo4j.int((i += 1)) : neo4j.int(originalId);
    gdsIdToDbId.set(newNumId.toString(), originalId);
    dbIdToGdsId.set(originalId, newNumId.toString());
    return newNumId;
  };

  const getNumericIdForIdentifier = (identifier: string) => {
    const existingId = dbIdToGdsId.get(identifier);
    if (existingId) {
      return neo4j.int(existingId);
    }
    return getNewNumericId(identifier);
  };

  selectedRelIds.forEach((relId: string) => {
    const rel = relationships[relId];
    if (!rel) return;

    const startIdentifier = isV5OrGreater ? rel.startNodeElementId : rel.startId;
    const endIdentifier = isV5OrGreater ? rel.endNodeElementId : rel.endId;

    nodeIds.add(startIdentifier);
    nodeIds.add(endIdentifier);

    const startData = getNumericIdForIdentifier(startIdentifier);
    const endData = getNumericIdForIdentifier(endIdentifier);

    if (selectedOrientation === REVERSED_ORIENTATION) {
      data.push([endData, startData, getPropertyMap(rel)]);
    } else {
      data.push([startData, endData, getPropertyMap(rel)]);
    }
  });

  selectedNodeIds
    .filter((nodeId) => !nodeIds.has(nodeId))
    .forEach((nodeId) => {
      data.push([getNumericIdForIdentifier(nodeId), null, null]);
    });

  return {
    cypher: `UNWIND $data AS triple
      RETURN gds.graph.project(
        '${projectionId}', 
        triple[0], 
        triple[1],
        {
          relationshipProperties: triple[2]          
        },
        ${selectedOrientation === UNDIRECTED_ORIENTATION ? "{undirectedRelationshipTypes: ['*']}" : '{}'}
      ) AS g`,
    parameters: {
      data,
    },
    numIdMap: gdsIdToDbId,
  };
};

export const callCentralityQuery = (
  projectionId: QueryGeneratorParams['projectionId'],
  procedure: QueryGeneratorParams['procedure'],
  logProgress: QueryGeneratorParams['logProgress'] = true,
  selectedRelWeightedProperty: QueryGeneratorParams['selectedRelWeightedProperty'] = null,
): CypherQuery => ({
  cypher: `CALL ${procedure}('${projectionId}', {
      concurrency: 1,
      relationshipWeightProperty: $selectedRelWeightedProperty,
      logProgress: ${logProgress}
    })
    YIELD nodeId, score
    WITH COLLECT({nodeId: nodeId, score: score}) AS data, min(score) AS minVal, max(score) AS maxVal
    RETURN data, minVal, maxVal
    `,
  parameters: {
    selectedRelWeightedProperty: isTruthy(selectedRelWeightedProperty) ? 'weighted_property' : null,
  },
});

// betweenness is slow running, so using a concurrency thread of 4, score / 2 as discussed with gds
export const callBetweennessQuery = (
  projectionId: QueryGeneratorParams['projectionId'],
  procedure: QueryGeneratorParams['procedure'],
  logProgress: QueryGeneratorParams['logProgress'] = true,
  selectedRelWeightedProperty: QueryGeneratorParams['selectedRelWeightedProperty'] = null,
): CypherQuery => ({
  cypher: `CALL ${procedure}('${projectionId}', {
      concurrency: 4,
      logProgress: ${logProgress},
      relationshipWeightProperty: $selectedRelWeightedProperty
    })
    YIELD nodeId, score
    WITH COLLECT({nodeId: nodeId, score: score / 2}) AS data, min(score) / 2 AS minVal, max(score) / 2 AS maxVal
    RETURN data, minVal, maxVal
    `,
  parameters: {
    selectedRelWeightedProperty: isTruthy(selectedRelWeightedProperty) ? 'weighted_property' : null,
  },
});

// export const callClosenessQuery = (procedure) => ({
//   cypher:
//     `CALL ${procedure}('temporaryGdsGraph', {
//       concurrency: 1
//     })
//     YIELD nodeId, score
//     WITH COLLECT({nodeId: nodeId, score: score}) AS data, min(score) AS minVal, max(score) AS maxVal
//     RETURN data, minVal, maxVal
//     `,
//   parameters: {
//   }
// })

// Louvain, Label Propagation
export const callCommunityQuery = (
  projectionId: QueryGeneratorParams['projectionId'],
  procedure: QueryGeneratorParams['procedure'],
  logProgress: QueryGeneratorParams['logProgress'] = true,
  selectedRelWeightedProperty: QueryGeneratorParams['selectedRelWeightedProperty'] = null,
): CypherQuery => ({
  cypher: `CALL ${procedure}('${projectionId}', {
      relationshipWeightProperty: $selectedRelWeightedProperty,
      concurrency: 1,
      logProgress: ${logProgress}
    })
    YIELD nodeId, communityId
    WITH COLLECT({nodeId: nodeId, communityId: communityId}) AS data, min(communityId) AS minVal, max(communityId) AS maxVal
    RETURN data, minVal, maxVal
    `,
  parameters: {
    selectedRelWeightedProperty: isTruthy(selectedRelWeightedProperty) ? 'weighted_property' : null,
  },
});

export const callTriangleCountQuery = (
  projectionId: QueryGeneratorParams['projectionId'],
  procedure: QueryGeneratorParams['procedure'],
  logProgress: QueryGeneratorParams['logProgress'] = true,
): CypherQuery => ({
  cypher: `CALL ${procedure}('${projectionId}', {
      concurrency: 1,
      logProgress: ${logProgress}
    })
    YIELD nodeId, triangleCount
    WITH COLLECT({nodeId: nodeId, triangleCount: triangleCount}) AS data, min(triangleCount) AS minVal, max(triangleCount) AS maxVal
    RETURN data, minVal, maxVal
    `,
  parameters: {},
});

export const callWccQuery = (
  projectionId: QueryGeneratorParams['projectionId'],
  procedure: QueryGeneratorParams['procedure'],
  logProgress: QueryGeneratorParams['logProgress'] = true,
  selectedRelWeightedProperty: QueryGeneratorParams['selectedRelWeightedProperty'] = null,
): CypherQuery => ({
  cypher: `CALL ${procedure}('${projectionId}', {
      relationshipWeightProperty: $selectedRelWeightedProperty,
      concurrency: 1,
      logProgress: ${logProgress}
    })
    YIELD nodeId, componentId as communityId
    WITH COLLECT({nodeId: nodeId, communityId: communityId}) AS data, min(communityId) AS minVal, max(communityId) AS maxVal
    RETURN data, minVal, maxVal
    `,
  parameters: {
    selectedRelWeightedProperty: isTruthy(selectedRelWeightedProperty) ? 'weighted_property' : null,
  },
});

export const deleteGdsProjectionQuery = (
  projectionId: QueryGeneratorParams['projectionId'],
  procedure: QueryGeneratorParams['procedure'],
): CypherQuery => ({
  cypher: `CALL ${procedure}('${projectionId}') YIELD graphName;`,
  parameters: {},
});
