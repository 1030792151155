import type { PROJECT_ROLE_NAME } from './project';
import type { PLAN_TYPE } from './shared';

export enum INVITE_STATUS {
  PENDING = 'pending',
  REGISTERED = 'registered',
  EXPIRED = 'expired',
  RESCINDED = 'rescinded',
  REVOKED = 'revoked',
  DECLINED = 'declined',
}

export interface ProjectInvite {
  id: string;
  status: INVITE_STATUS;
  projectId: string;
  roles: PROJECT_ROLE_NAME[];

  invitedBy: string;
  invitedAt: string;
  expiresAt: string;

  email?: string;
  userId?: string;
  subjectId?: string;

  acceptedAt?: string;
  revokedAt?: string;
  declinedAt?: string;
}

export type InviteeProjectsWithAffectedInstances = {
  friendlyName: string;
  projectId: string;
};

export type ProjectInviteDetails = {
  id: string;
  email: string;
  projectId: string;
  projectName: string;
  invitedBy: string;
  planType: PLAN_TYPE;
  roles: PROJECT_ROLE_NAME[];
  hasRunningInstance: boolean;
  inviteeProjectsWithAffectedInstances: InviteeProjectsWithAffectedInstances[];
};
