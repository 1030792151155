import { Button, IconButton, InlineEdit, Switch, Tooltip, Typography, toast } from '@neo4j-ndl/react';
import { ArrowRightIconOutline } from '@neo4j-ndl/react/icons';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import {
  ACTION,
  consoleApi,
  getApiError,
  getErrorMessage,
  isErrorWithMessage,
  isFetchBaseQueryError,
  useActiveOrg,
  useNotificationActions,
  usePermissions,
} from '@nx/state';
import type { InstanceSeamlessConnectionEnabled, ProjectDefaultSeamlessConnectionEnabled } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { ClipboardCopier, Stack } from '@nx/ui';
import { ControlPanel } from '@nx/ui/src/control-panel';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { validateYup } from '../../utils/validation';
import { ConfirmDisableGdsSessionModal, EnableGdsSessionsModal, ModifyGdsSessionsLimitsModal } from './gds-sessions';
import { usePrefetchDisableSessionsModal } from './gds-sessions/disable-modal';
import { DeactivateSeamlessConnectionModal } from './seamless-connection/confirm-deactivate-modal';
import { SeamlessConnectionSettingsDrawer } from './seamless-connection/settings';

const logger = createLogger(APP_SCOPE.framework);

export const updateOrganizationNameSchema = yup.object({
  name: yup
    .string()
    .min(3, 'Organization name must be longer than 3 characters')
    .max(255, 'Organization name can be no longer than 255 characters')
    .required('Organization name cannot be empty'),
});

export const validate = (data: { name: string }, { onlyRequired = false }: { onlyRequired?: boolean } = {}) =>
  validateYup(updateOrganizationNameSchema, data, onlyRequired);

export interface SeamlessConfigState {
  projects: Record<string, boolean>;
  instances: Record<string, boolean>;
}

export function Settings() {
  const navigate = useNavigate();
  const activeOrg = useActiveOrg();
  const { addNotification } = useNotificationActions();
  const { isAllowed } = usePermissions();
  const allowReadSsoSettings = isAllowed(ACTION.READ, `organizations/${activeOrg.id}/sso-configs`);
  const allowEditOrg = isAllowed(ACTION.UPDATE, `organizations/${activeOrg.id}`);
  const allowUpdateSeamless = isAllowed(ACTION.UPDATE, `organizations/${activeOrg.id}/seamless`);
  const orgSeamlessProfileRes = consoleApi.useGetOrganizationSeamlessProfileQuery(activeOrg.id, {
    skip: !activeOrg.capabilities.seamless_connection || !allowUpdateSeamless,
  });
  const [updateOrganizationSeamlessProfile, updateOrgSeamlessProfileRes] =
    consoleApi.useUpdateOrganizationSeamlessProfileMutation();
  const [showSeamlessConnectionSettings, setShowSeamlessConnectionSettings] = useState<boolean>(false);
  const [showDeactivateSeamlessConnectionModal, setShowDeactivateSeamlessConnectionModal] = useState<boolean>(false);
  const [seamlessConfigState, setSeamlessConfigState] = useState<SeamlessConfigState>({ instances: {}, projects: {} });

  const initialSeamlessConfigState = useMemo(() => {
    const initialState: SeamlessConfigState = { instances: {}, projects: {} };
    orgSeamlessProfileRes.data?.projects.forEach((project) => {
      initialState.projects[project.id] = project.defaultSeamlessConnectionEnabled;

      project.instances.forEach((instance) => {
        initialState.instances[instance.id] = instance.seamlessConnectionEnabled;
      });
    });
    return initialState;
  }, [orgSeamlessProfileRes.data]);

  // Only update seamless connection state when opening/closing settings & when data is (done) loading,
  // otherwise user input may get overwritten.
  useEffect(() => {
    setSeamlessConfigState(initialSeamlessConfigState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSeamlessConnectionSettings, orgSeamlessProfileRes.isLoading]);

  const showDeactivateSeamlessConnection =
    orgSeamlessProfileRes.data?.projects.some(
      (project) =>
        project.defaultSeamlessConnectionEnabled ||
        project.instances.some((instance) => instance.seamlessConnectionEnabled),
    ) ?? false;

  useEffect(() => {
    if (orgSeamlessProfileRes.isError) {
      const { error } = orgSeamlessProfileRes;
      const msg =
        (isFetchBaseQueryError(error) && getErrorMessage(getApiError(error))) ||
        (isErrorWithMessage(error) && error.message) ||
        `Unknown error: ${JSON.stringify(error)}`;
      logger.error(msg);

      addNotification({
        type: 'danger',
        title: 'Failed to fetch tool authentication settings',
        description: msg,
        timeout: 5000,
      });
    }
  }, [addNotification, orgSeamlessProfileRes]);

  // Using state for more responsive UI
  const [copilotEnabled, setCopilotEnabled] = useState(false);

  const [updateOrg] = consoleApi.useUpdateOrganizationMutation();

  const handleUpdateOrganizationName = (name: string) => {
    updateOrg({ name: name.trim(), organizationId: activeOrg.id })
      .unwrap()
      .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(e);
        if (isNotNullish(error.message)) {
          logger.error(error.message);
        }
        addNotification({
          type: 'danger',
          title: 'Failed to update organization name',
          description: getErrorMessage(error),
          timeout: 5000,
        });
      });
  };

  const handleUpdateCopilotEnabled = () => {
    const currentlyEnabled = copilotEnabled;
    setCopilotEnabled(!currentlyEnabled);
    updateOrg({ copilotEnabled: !currentlyEnabled, organizationId: activeOrg.id })
      .unwrap()
      .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(e);
        if (isNotNullish(error.message)) {
          logger.error(error.message);
        }
        addNotification({
          type: 'danger',
          title: `Failed to ${currentlyEnabled ? 'disable' : 'enable'} copilots`,
          description: getErrorMessage(error),
          timeout: 5000,
        });
        setCopilotEnabled(currentlyEnabled);
      });
  };

  const [showEnableGdsSessionsModal, setShowEnableGdsSessionsModal] = useState(false);
  const [showModifyGdsSessionLimitsModal, setShowModifyGdsSessionLimitsModal] = useState(false);
  const [showDisableGdsSessionsModal, setShowDisableGdsSessionsModal] = useState(false);

  const prefetchDisableSessionsModal = usePrefetchDisableSessionsModal();

  const handleOnFeatureAccessMouseEnter = useCallback(() => {
    if (activeOrg.gdsSessions.enabled) {
      prefetchDisableSessionsModal();
    }
  }, [activeOrg.gdsSessions.enabled, prefetchDisableSessionsModal]);

  useEffect(() => {
    setCopilotEnabled(activeOrg.copilotEnabled);
  }, [activeOrg]);

  const seamlessConfigStateIsUpdated = useMemo(() => {
    if (isNotNullish(orgSeamlessProfileRes.data)) {
      for (const project of orgSeamlessProfileRes.data.projects) {
        if (project.defaultSeamlessConnectionEnabled !== seamlessConfigState.projects[project.id]) {
          return true;
        }

        for (const instance of project.instances) {
          if (instance.seamlessConnectionEnabled !== seamlessConfigState.instances[instance.id]) {
            return true;
          }
        }
      }
    }

    return false;
  }, [orgSeamlessProfileRes, seamlessConfigState]);

  const handleUpdateseamlessConfigState = ({
    projects = [],
    instances = [],
  }: {
    projects?: ProjectDefaultSeamlessConnectionEnabled[];
    instances?: InstanceSeamlessConnectionEnabled[];
  }) => {
    const updatedInstances = instances.reduce((o, curr) => ({ ...o, [curr.dbId]: curr.seamlessConnectionEnabled }), {});
    const updatedProjects = projects.reduce(
      (o, curr) => ({ ...o, [curr.projectId]: curr.defaultSeamlessConnectionEnabled }),
      {},
    );
    setSeamlessConfigState((prev) => ({
      instances: { ...prev.instances, ...updatedInstances },
      projects: { ...prev.projects, ...updatedProjects },
    }));
  };

  const handleSubmitseamlessConfigState = () => {
    const projects: ProjectDefaultSeamlessConnectionEnabled[] = Object.keys(seamlessConfigState.projects).map(
      (key) => ({
        projectId: key,
        defaultSeamlessConnectionEnabled: seamlessConfigState.projects[key] ?? false,
      }),
    );
    const instances: InstanceSeamlessConnectionEnabled[] = Object.keys(seamlessConfigState.instances).map((key) => ({
      dbId: key,
      seamlessConnectionEnabled: seamlessConfigState.instances[key] ?? false,
    }));

    updateOrganizationSeamlessProfile({ organizationId: activeOrg.id, projects, instances })
      .unwrap()
      .then(() => {
        setShowSeamlessConnectionSettings(false);
        toast.success('Settings saved', {
          isCloseable: true,
          shouldAutoClose: true,
        });
      })
      .catch((error) => {
        const msg =
          (isFetchBaseQueryError(error) && getErrorMessage(getApiError(error))) ||
          (isErrorWithMessage(error) && error.message) ||
          `Unknown error: ${JSON.stringify(error)}`;

        logger.error(msg);

        addNotification({
          type: 'danger',
          title: 'Failed to update tool authentication settings',
          description: msg,
          timeout: 5000,
        });
      });
  };

  const handleDeactivateSeamlessConnection = () => {
    const deactivate = async () => {
      await updateOrganizationSeamlessProfile({
        organizationId: activeOrg.id,
        organization: { seamlessConnectionEnabled: false },
      });
      setShowDeactivateSeamlessConnectionModal(false);
    };

    void deactivate();
  };

  return (
    <div className="h-full overflow-auto p-4">
      <Typography variant="h2" className="mb-6 px-2 pt-2">
        Organization settings
      </Typography>
      <div className="flex flex-col gap-6">
        <ControlPanel>
          <div className="flex flex-wrap gap-x-[64px] gap-y-4">
            <ControlPanel.Cell label="Organization name">
              {allowEditOrg ? (
                <InlineEdit
                  defaultValue={activeOrg.displayName}
                  validate={(name) => validate({ name })?.name?.message ?? true}
                  onConfirm={(name) => handleUpdateOrganizationName(name)}
                  hasEditIcon
                  variant="subheading-medium"
                  htmlAttributes={{
                    'aria-label': 'Organization name',
                  }}
                />
              ) : (
                <h5 className="ml-1">{activeOrg.displayName}</h5>
              )}
            </ControlPanel.Cell>

            <ControlPanel.Cell label="Organization ID">
              <div className="flex items-center gap-1.5">
                <Typography variant="subheading-medium">{activeOrg.id}</Typography>
                {/* TODO replace with Needle's ClipboardButton when updating Needle React to >= 2.7.0 */}
                <ClipboardCopier
                  ariaLabel="Copy organization ID"
                  title="Copy organization ID"
                  textToCopy={activeOrg.id}
                  iconButtonSize="small"
                />
              </div>
            </ControlPanel.Cell>

            {/* {isNonEmptyString(activeOrg.createdAt) && (
              <ControlPanel.Cell label="Created on">
                {activeOrg.createdAt.slice(0, 16).replace('T', ' - ')}
              </ControlPanel.Cell>
            )} */}
          </div>
        </ControlPanel>
        <ControlPanel title="Preferences">
          <Stack space="48px" divider>
            <ControlPanel.Row
              title="Enable copilots"
              description="Include copilot features in tools that are backed by large language models. For these features to work, your database's schema is shared to assist with the creation of Cypher queries. No data values are shared."
              action={
                <Switch
                  ariaLabel="Enable Copilot for organization"
                  onChange={handleUpdateCopilotEnabled}
                  isDisabled={!allowEditOrg}
                  htmlAttributes={{
                    checked: copilotEnabled,
                  }}
                />
              }
            />
          </Stack>
        </ControlPanel>
        {activeOrg.capabilities.gds_sessions && (
          <div onMouseEnter={handleOnFeatureAccessMouseEnter}>
            <ControlPanel title="Feature access">
              <Stack space="48px" divider>
                <ControlPanel.Row
                  title="Sessions"
                  description="Sessions is an add-on service to run graph data science algorithms with flexible compute"
                  action={
                    <div className="flex gap-x-2">
                      {activeOrg.gdsSessions.enabled ? (
                        <>
                          <Button
                            size="small"
                            onClick={() => setShowModifyGdsSessionLimitsModal(true)}
                            color="neutral"
                            fill="outlined"
                            aria-label="Modify GDS Sessions limits"
                            data-testid="modify-gds-sessions-limits-button"
                          >
                            Modify limits
                          </Button>
                          <Button
                            size="small"
                            color="danger"
                            fill="outlined"
                            onClick={() => setShowDisableGdsSessionsModal(true)}
                            aria-label="Disable GDS Sessions"
                            data-testid="deactivate-gds-sessions-button"
                          >
                            Disable
                          </Button>
                        </>
                      ) : (
                        <Button
                          size="small"
                          onClick={() => setShowEnableGdsSessionsModal(true)}
                          color="neutral"
                          fill="outlined"
                          aria-label="Enable GDS Sessions"
                          data-testid="enable-gds-sessions-button"
                        >
                          Enable
                        </Button>
                      )}
                    </div>
                  }
                />
              </Stack>
            </ControlPanel>
          </div>
        )}

        {((activeOrg.capabilities.seamless_connection && allowUpdateSeamless) ||
          activeOrg.capabilities.organization_sso) && (
          <ControlPanel title="Security">
            <Stack space="48px" divider>
              {activeOrg.capabilities.seamless_connection && allowUpdateSeamless && (
                <ControlPanel.Row
                  title="Tool authentication with Aura user"
                  description="Allow tools to connect with permissions from the user's project role."
                  action={
                    showDeactivateSeamlessConnection ? (
                      <div className="flex flex-row gap-2">
                        <Button
                          size="small"
                          onClick={() => setShowSeamlessConnectionSettings(true)}
                          color="neutral"
                          fill="outlined"
                          htmlAttributes={{
                            'aria-label': 'Open tool authentication with Aura user settings',
                            'data-testid': 'configure-seamless-connection-button',
                          }}
                        >
                          Configure
                        </Button>
                        <Button
                          size="small"
                          color="danger"
                          fill="outlined"
                          onClick={() => setShowDeactivateSeamlessConnectionModal(true)}
                          isLoading={updateOrgSeamlessProfileRes.isLoading}
                          htmlAttributes={{
                            'data-testid': 'deactivate-seamless-connection-button',
                          }}
                        >
                          Deactivate
                        </Button>
                      </div>
                    ) : (
                      <Button
                        size="small"
                        onClick={() => setShowSeamlessConnectionSettings(true)}
                        color="neutral"
                        fill="outlined"
                        isLoading={orgSeamlessProfileRes.isLoading}
                        htmlAttributes={{
                          'aria-label': 'Open tool authentication with Aura user settings',
                          'data-testid': 'activate-seamless-connection-button',
                        }}
                      >
                        Activate
                      </Button>
                    )
                  }
                />
              )}
              {activeOrg.capabilities.organization_sso && (
                <ControlPanel.Row
                  title="Single sign-on"
                  description="Configure single sign-on for your organization and instances."
                  action={
                    <Tooltip type="simple" isDisabled={allowReadSsoSettings}>
                      <Tooltip.Trigger hasButtonWrapper>
                        <IconButton
                          isClean
                          ariaLabel="Single sign-on settings"
                          onClick={() => navigate(`/org/${activeOrg.id}/settings/single-sign-on`)}
                          htmlAttributes={{
                            'data-testid': 'sso-settings',
                          }}
                          isDisabled={!allowReadSsoSettings}
                        >
                          <ArrowRightIconOutline />
                        </IconButton>
                      </Tooltip.Trigger>
                      <Tooltip.Content style={{ maxWidth: 200 }}>
                        You need Organization Admin or Owner privileges to access this page. Talk with your Admin to
                        request the needed role.
                      </Tooltip.Content>
                    </Tooltip>
                  }
                />
              )}
            </Stack>
          </ControlPanel>
        )}
        {showSeamlessConnectionSettings && isNotNullish(orgSeamlessProfileRes.data) && (
          <SeamlessConnectionSettingsDrawer
            data={orgSeamlessProfileRes.data}
            state={seamlessConfigState}
            onClose={() => setShowSeamlessConnectionSettings(false)}
            onSubmit={handleSubmitseamlessConfigState}
            updateState={handleUpdateseamlessConfigState}
            fetchIsLoading={orgSeamlessProfileRes.isLoading}
            submitIsLoading={updateOrgSeamlessProfileRes.isLoading}
            canSubmit={seamlessConfigStateIsUpdated}
          />
        )}
        {showDeactivateSeamlessConnectionModal && (
          <DeactivateSeamlessConnectionModal
            onClose={() => setShowDeactivateSeamlessConnectionModal(false)}
            onConfirm={handleDeactivateSeamlessConnection}
            isLoading={updateOrgSeamlessProfileRes.isLoading}
          />
        )}
        {showEnableGdsSessionsModal && <EnableGdsSessionsModal onClose={() => setShowEnableGdsSessionsModal(false)} />}
        {showModifyGdsSessionLimitsModal && (
          <ModifyGdsSessionsLimitsModal onClose={() => setShowModifyGdsSessionLimitsModal(false)} />
        )}
        {showDisableGdsSessionsModal && (
          <ConfirmDisableGdsSessionModal onClose={() => setShowDisableGdsSessionsModal(false)} />
        )}
      </div>
    </div>
  );
}
