const SVG_NS = 'http://www.w3.org/2000/svg';

const highlightClassName = 'nx-highlight';
const highlightedClassName = 'nx-highlighted';

export const registerHighlight = (element?: string) => {
  const addHighlight = () => {
    const body = document.querySelector('body');

    if (body?.classList.contains(highlightedClassName) === true) {
      return;
    }

    body?.classList.add(highlightedClassName);

    const [main] = document.getElementsByTagName('main');

    const el = document.querySelector(`[data-highlightid="${element}"]`);

    if (body && main && el) {
      el.scrollIntoView();

      const { top: svgTop, right: svgRight, bottom: svgBottom, left: svgLeft } = main.getBoundingClientRect();

      const { top: elTop, left: elLeft, width: elWidth, height: elHeight } = el.getBoundingClientRect();

      const maskId = 'mask';

      const paddingX = 2;
      const paddingY = 3;

      const svgElement = document.createElementNS(SVG_NS, 'svg');
      svgElement.setAttribute('id', highlightClassName);
      svgElement.setAttribute('width', '100%');
      svgElement.setAttribute('height', '100%');

      const defs = document.createElementNS(SVG_NS, 'defs');

      const mask = document.createElementNS(SVG_NS, 'mask');
      mask.setAttribute('id', maskId);

      const maskInclude = document.createElementNS(SVG_NS, 'rect');
      maskInclude.setAttribute('x', '0');
      maskInclude.setAttribute('y', '0');
      maskInclude.setAttribute('width', '100%');
      maskInclude.setAttribute('height', '100%');
      maskInclude.setAttribute('fill', 'white');

      const maskExclude = document.createElementNS(SVG_NS, 'rect');
      maskExclude.setAttribute('x', (elLeft - svgLeft - paddingX).toFixed(0));
      maskExclude.setAttribute('y', (elTop - svgTop - paddingY).toFixed(0));
      maskExclude.setAttribute('width', (elWidth + paddingX * 2).toFixed(0));
      maskExclude.setAttribute('height', (elHeight + paddingY * 2).toFixed(0));
      maskExclude.setAttribute('rx', '3');
      maskExclude.setAttribute('fill', 'black');

      mask.appendChild(maskInclude);
      mask.appendChild(maskExclude);
      defs.appendChild(mask);
      svgElement.appendChild(defs);

      const rect = document.createElementNS(SVG_NS, 'rect');
      rect.setAttribute('x', '0');
      rect.setAttribute('y', '0');
      rect.setAttribute('width', svgRight.toFixed(0));
      rect.setAttribute('height', svgBottom.toFixed(0));
      rect.setAttribute('fill', 'red');
      rect.setAttribute('mask', `url(#${maskId})`);
      rect.classList.add('nx-highlight-mask');

      svgElement.appendChild(rect);

      // remove highlight on click
      // onMouseLeave will remove the hightlight. But if that doesn't happen, this will remove it also.
      svgElement.addEventListener('click', () => {
        svgElement.remove();
        body.classList.remove(highlightedClassName);
      });

      main.appendChild(svgElement);
    }
  };

  // Listen for highlight remove signal
  const removeHighlight = () => {
    const body = document.querySelector('body');

    body?.classList.remove(highlightedClassName);

    const highlight = document.getElementById(highlightClassName);
    if (highlight) {
      highlight.parentElement?.removeChild(highlight);
    }
  };

  return { addHighlight, removeHighlight };
};
