import type { BannerCommonProps } from '@neo4j-ndl/react';
import { Banner } from '@neo4j-ndl/react';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import classNames from 'classnames';
import type { ComponentProps, PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

export const ApiErrorBanner = ({
  error,
  className,
  title = 'API Error',
  description,
  hasIcon,
}: {
  error: FetchBaseQueryError | SerializedError;
} & Pick<BannerCommonProps, 'title' | 'description' | 'className' | 'hasIcon'>) => {
  return (
    <Banner
      className={classNames(className)}
      description={description ?? JSON.stringify(error, null, 2)}
      title={title}
      hasIcon={hasIcon}
      isCloseable={false}
      type="danger"
      usage="inline"
    />
  );
};

type SingletonContextType = {
  props: ComponentProps<typeof ApiErrorBanner> | null;
  setProps: (props: ComponentProps<typeof ApiErrorBanner> | null) => void;
};

const SingletonContext = createContext<SingletonContextType | undefined>(undefined);

const useSingletonContext = () => {
  const context = useContext(SingletonContext);
  if (!context) {
    throw new Error('useSingletonContext must be used within a SingletonProvider');
  }
  return context;
};

function Provider({ children }: PropsWithChildren) {
  const [props, setProps] = useState<SingletonContextType['props']>(null);
  const value = useMemo<SingletonContextType>(() => ({ props, setProps }), [props]);
  return <SingletonContext.Provider value={value}>{children}</SingletonContext.Provider>;
}

function Singleton() {
  const { props, setProps } = useSingletonContext();

  useEffect(() => {
    // clear error banner when unmounting
    return () => setProps(null);
  }, [setProps]);

  return props ? <ApiErrorBanner {...props} /> : null;
}

const Client = (props: ComponentProps<typeof ApiErrorBanner>) => {
  const { setProps } = useSingletonContext();
  useEffect(() => {
    setProps(props);
  }, [setProps, props]);
  return null;
};

/**
 * Context provider that manages a single instance of `ApiErrorBanner`.
 * Useful for displaying errors outside their originating component's hierarchy,
 * such as showing row-level errors at the table level.
 * Wrap your subtree with this provider to enable singleton functionality.
 */
ApiErrorBanner.SingletonProvider = Provider;

/**
 * Renders a single `ApiErrorBanner.Singleton`. Place this component where you want the banner to appear.
 */
ApiErrorBanner.Singleton = Singleton;

/**
 * Client component that triggers the display of an error banner via `ApiErrorBanner.Singleton`.
 * Multiple clients can exist, but only the most recently updated one will be shown.
 */
ApiErrorBanner.SingletonClient = Client;
