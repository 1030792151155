import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';

const HttpError = {
  HTTP_ERROR: 'HTTP_ERROR',
  PARSING_ERROR: 'PARSING_ERROR',
  CUSTOM_ERROR: 'CUSTOM_ERROR',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  TIMEOUT_ERROR: 'TIMEOUT_ERROR',
  FETCH_ERROR: 'FETCH_ERROR',
} as const;

type HttpError = keyof typeof HttpError;

export type ApiError = {
  type: HttpError;
  reason: string | undefined;
  message: string | undefined;
  code: number | undefined;
  extra: Record<string, unknown> | undefined;
  validationErrors: Record<string, string[]> | undefined;
};

const isStringRecord = (obj: unknown): obj is Record<string, unknown> => {
  return isNotNullish(obj) && typeof obj === 'object';
};

const isStringArrayRecord = (obj: unknown): obj is Record<string, string[]> => {
  return (
    isStringRecord(obj) &&
    Object.values(obj).every((value) => Array.isArray(value) && value.every((item) => typeof item === 'string'))
  );
};

/**
 * This function takes the RTK error and verifies that it is a Console API error.
 * It then deconstructs the error into something consumable by the frontend.
 */
export const getApiError = (fetchError: FetchBaseQueryError | SerializedError | undefined): ApiError => {
  const { data, status } =
    isNotNullish(fetchError) && 'data' in fetchError ? fetchError : { data: null, status: null, ...fetchError };
  let type = undefined;
  let code = undefined;
  let reason = undefined;
  let message = undefined;
  let extra = undefined;
  let validationErrors = undefined;
  if (typeof status === 'number') {
    code = status;
    type = HttpError.HTTP_ERROR;
  } else {
    type = status ?? HttpError.UNKNOWN_ERROR;
  }

  if (isNotNullish(data) && typeof data === 'object') {
    // If the API doesn't use Snake-Case header we will get the error message in Capitalized form so we need to
    // take both cases in to account util everything is snake_cased
    if (
      'Message' in data &&
      typeof data.Message === 'string' &&
      'Reason' in data &&
      typeof data.Reason === 'string' &&
      typeof status === 'number'
    ) {
      reason = data.Reason;
      message = data.Message;
    }
    if (
      'message' in data &&
      typeof data.message === 'string' &&
      'reason' in data &&
      typeof data.reason === 'string' &&
      typeof status === 'number'
    ) {
      reason = data.reason;
      message = data.message;
    }
    if ('Extra' in data && isStringRecord(data.Extra)) {
      extra = data.Extra;
    }
    if ('extra' in data && isStringRecord(data.extra)) {
      extra = data.extra;
    }

    if ('Errors' in data && isStringArrayRecord(data.Errors)) {
      validationErrors = data.Errors;
    }
    if ('errors' in data && isStringArrayRecord(data.errors)) {
      validationErrors = data.errors;
    }
  } else if (isNotNullish(fetchError) && 'error' in fetchError) {
    message = fetchError.error;
  }
  return { type, reason, message, code, extra, validationErrors };
};

export const getErrorMessage = (error: ApiError) => {
  if (error.reason === 'quota-exceeded' && error.code === 402) {
    return "You've reached the maximum number of free instances allowed.";
  }

  if (error.type === 'FETCH_ERROR') {
    return 'Our system is experiencing problems right now. Try again later or contact support if the problem persists.';
  }

  return (
    error.message ??
    'Our system is experiencing problems right now. Try again later or contact support if the problem persists.'
  );
};

export const getApiErrorMessage = (fetchError: FetchBaseQueryError | SerializedError | undefined) => {
  const apiError = getApiError(fetchError);
  return getErrorMessage(apiError);
};

// Type predicate to narrow an unknown error to `FetchBaseQueryError`
export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error !== null && 'status' in error;
}

// Type predicate to narrow an unknown error to an object with a string 'message' property
export function isErrorWithMessage(error: unknown): error is { message: string } {
  return typeof error === 'object' && error !== null && 'message' in error && typeof error.message === 'string';
}
