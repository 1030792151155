import type { TIER, TrafficConfig } from '@nx/state';

export const isSameTrafficConfig = (trafficConfigA: TrafficConfig, trafficConfigB: TrafficConfig) => {
  return (
    trafficConfigA.cloudProvider === trafficConfigB.cloudProvider &&
    trafficConfigA.tier === trafficConfigB.tier &&
    trafficConfigA.region === trafficConfigB.region
  );
};

export type TierOption = {
  key: TIER;
  value: TIER;
  label: string;
};

export type RegionOption = {
  key: string;
  value: string;
  label: string;
};
