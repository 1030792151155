import { Checkbox, DataGrid, DataGridComponents, IconButton, Label, TextLink, Typography } from '@neo4j-ndl/react';
import { ArrowLongLeftIconOutline, FunnelIconOutline } from '@neo4j-ndl/react/icons';
import { DataGridHelpers, SearchField } from '@nx/ui';
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { PropertyDisplay } from './property-display';
import classes from './roles.module.css';

interface Permission {
  id: string;
  description: string;
  type: string;
  access: boolean;
}

const MOCK_PERMISSIONS: Permission[] = [
  {
    id: 'project.instances',
    description: 'Description of resource goes here',
    type: 'Lorem ipsum',
    access: true,
  },
  {
    id: 'project.instances.create',
    description: 'Description of resource goes here',
    type: 'Lorem ipsum',
    access: false,
  },
  {
    id: 'project.instances.upgrade',
    description: 'Description of resource goes here',
    type: 'Lorem ipsum',
    access: true,
  },
];

const columnHelper = createColumnHelper<Permission>();

const columns = [
  columnHelper.display({
    id: 'select',
    header: '',
    size: 50,
    cell: ({ row }) => (
      <div style={{ marginTop: '2px' }}>
        <Checkbox
          isDisabled
          ariaLabel={`row-${row.original.id}`}
          isChecked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
        />
      </div>
    ),
  }),
  columnHelper.accessor('id', {
    header: 'Resource',
    enableResizing: false,
  }),
  columnHelper.accessor('description', {
    header: 'Description',
  }),
  columnHelper.accessor('type', {
    header: 'Type',
  }),
  columnHelper.display({
    id: 'actions',
    cell: (cell) => <DataGridComponents.RowActionCell cell={cell} />,
    meta: {
      isActionCell: {
        actions: [{ title: 'item action 1' }, { title: 'item action 2' }],
      },
    },
  }),
];

const filterPermissions = (permissions: Permission[], filter: string) => {
  const normalizedFilter = filter.trim().toLocaleLowerCase();
  const filteredPermissions = normalizedFilter
    ? permissions.filter(({ id }) => id.toLocaleLowerCase().includes(normalizedFilter))
    : permissions;

  return filteredPermissions;
};

const getInitialSelection = (permissions: Permission[]) =>
  permissions.reduce<Record<string, boolean>>(
    (selection, permission) => (permission.access ? ((selection[permission.id] = true), selection) : selection),
    {},
  );

export function Resources() {
  const permissions = MOCK_PERMISSIONS;

  const { roleId } = useParams();
  const [filter, setFilter] = useState('');
  const [rowSelection, setRowSelection] = useState(() => getInitialSelection(permissions));

  const filteredPermissions = filterPermissions(permissions, filter);

  const table = useReactTable({
    columns,
    data: filteredPermissions,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowId: (row) => row.id,
    enableRowSelection: true,
    state: {
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
    initialState: {
      pagination: { pageSize: filteredPermissions.length },
    },
    columnResizeMode: 'onChange',
  });

  return (
    <div className={`h-full overflow-auto p-4 ${classes['grid-table']}`}>
      <DataGridHelpers.Wrapper>
        <DataGridHelpers.OuterHeader className="flex gap-4">
          <Link to="/projects/proj23/roles">
            <ArrowLongLeftIconOutline className="h-6 w-6" />
          </Link>
          <Label color="default" fill="semi-filled">
            {roleId?.toUpperCase()}
          </Label>
        </DataGridHelpers.OuterHeader>

        <DataGridHelpers.OuterHeader>
          <div className="flex flex-col gap-4">
            <Typography variant="body-medium">
              Roles define what resources that can be used once by the user once assigned.
              <TextLink isExternalLink>Documentation</TextLink>
            </Typography>
            {/* TODO. role name */}
            <PropertyDisplay label="Name" value={roleId?.toLocaleUpperCase()} />
            <PropertyDisplay label="ID" value={roleId} allowCopyText />
          </div>
        </DataGridHelpers.OuterHeader>

        <DataGridHelpers.OuterHeader>
          <div className="flex basis-[400px] gap-2">
            <SearchField
              className="min-w-36 grow"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              htmlAttributes={{ 'aria-label': 'Filter roles' }}
            />

            <IconButton ariaLabel="Filter roles">
              <FunnelIconOutline />
            </IconButton>
          </div>
        </DataGridHelpers.OuterHeader>

        <DataGrid
          isResizable={true}
          tableInstance={table}
          styling={{ headerStyle: 'clean' }}
          components={{ Navigation: null }}
          isKeyboardNavigable={false}
        />
      </DataGridHelpers.Wrapper>
    </div>
  );
}
