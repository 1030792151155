import type { DialogProps } from '@neo4j-ndl/react';
import { Banner, Button, Checkbox, Dialog, Radio, TextInput, Typography } from '@neo4j-ndl/react';
import { OpsTypes } from '@nx/state';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import classNames from 'classnames';
import { useState } from 'react';

import { ApiErrorBanner, FormErrorText } from '../../shared/components';
import { getLogger } from '../../shared/logger';
import { standardFormatAbsoluteNumber } from '../../shared/ui-helpers';
import type { TabKey } from '../shared/types';

type DownloadDialogProps = Omit<DialogProps, 'children'> & {
  onClose: () => void;
  handleInitiateDownload: (props: HandleInitiateDownloadProps) => Promise<void>;
  isLoading: boolean;
  isStaleData: boolean;
  selectedTab: TabKey;
  totalLogs: number;
  logsRowLimit: number;
  initDetailsDownloadError: SerializedError | FetchBaseQueryError | undefined;
  initSummaryDownloadError: SerializedError | FetchBaseQueryError | undefined;
};

export type HandleInitiateDownloadProps = {
  logTypes: TabKey[];
  fileFormat: OpsTypes.Api.LOG_FORMAT;
  includeHeaders: boolean;
  delimiter: string;
};

export const DownloadDialog = ({
  handleInitiateDownload,
  onClose,
  isLoading,
  isStaleData,
  selectedTab,
  totalLogs,
  logsRowLimit,
  initDetailsDownloadError,
  initSummaryDownloadError,
  modalProps,
  ...props
}: DownloadDialogProps) => {
  const [logTypes, setLogTypes] = useState<TabKey[]>([selectedTab]);
  const [fileFormat, setFileFormat] = useState<OpsTypes.Api.LOG_FORMAT>(OpsTypes.Api.LOG_FORMAT.JSON);
  const [includeHeaders, setIncludeHeaders] = useState(true);
  const [delimiter, setDelimiter] = useState(',');

  const isValidForm =
    // CSV validation
    ((fileFormat === OpsTypes.Api.LOG_FORMAT.CSV && delimiter.length === 1) ||
      // JSON validation
      fileFormat === OpsTypes.Api.LOG_FORMAT.JSON) &&
    // Other validation
    logTypes.length > 0;

  const handleSelectLogType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (!(name === 'DETAILS' || name === 'SUMMARY')) {
      getLogger().error('[InitiateDownloadModal] Invalid log type selected', name);
      return;
    }
    if (checked) {
      setLogTypes([...logTypes, name]);
    } else {
      setLogTypes(logTypes.filter((type) => type !== name));
    }
  };

  return (
    <Dialog
      {...props}
      size="small"
      modalProps={{
        'data-testid': 'initiate-logs-download-modal',
        ...modalProps,
        className: classNames('mt-12 align-top mx-auto', modalProps?.className),
      }}
      onClose={onClose}
    >
      <Dialog.Header>Download logs</Dialog.Header>
      <Dialog.Content>
        {/* Section 1: Query log types */}
        <div className="flex flex-col gap-2">
          <Typography variant="subheading-small" className="">
            Log type
          </Typography>
          <div className="flex flex-col gap-2">
            <Checkbox
              isChecked={logTypes.includes('SUMMARY')}
              onChange={handleSelectLogType}
              label="Summary"
              htmlAttributes={{
                name: 'SUMMARY',
              }}
            />
            <Checkbox
              isChecked={logTypes.includes('DETAILS')}
              onChange={handleSelectLogType}
              label="Details"
              htmlAttributes={{
                name: 'DETAILS',
              }}
            />
          </div>
          {!(logTypes.length > 0) && <FormErrorText errorText="Please select at least one log type" />}
        </div>

        {/* Section 2: Download options */}
        <div className="mt-6 flex flex-col gap-2">
          <Typography variant="subheading-small">Format</Typography>

          <div className="flex w-full flex-col items-start gap-2">
            <Radio
              label={OpsTypes.Api.LOG_FORMAT.JSON}
              value={OpsTypes.Api.LOG_FORMAT.JSON}
              isChecked={fileFormat === OpsTypes.Api.LOG_FORMAT.JSON}
              onChange={(e) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
                if (e.target.value === OpsTypes.Api.LOG_FORMAT.JSON) {
                  setFileFormat(e.target.value);
                }
              }}
            />
            <div className="flex flex-col gap-2">
              <Radio
                label={OpsTypes.Api.LOG_FORMAT.CSV}
                value={OpsTypes.Api.LOG_FORMAT.CSV}
                isChecked={fileFormat === OpsTypes.Api.LOG_FORMAT.CSV}
                onChange={(e) => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
                  if (e.target.value === OpsTypes.Api.LOG_FORMAT.CSV) {
                    setFileFormat(e.target.value);
                  }
                }}
              />

              {fileFormat === OpsTypes.Api.LOG_FORMAT.CSV && (
                <div className="ml-8 flex flex-col gap-2">
                  <Checkbox
                    isChecked={includeHeaders}
                    onChange={(e) => setIncludeHeaders(e.target.checked)}
                    label="Include CSV headers"
                  />
                  <TextInput
                    label="CSV field delimiter"
                    value={delimiter}
                    onChange={(e) => setDelimiter(e.target.value)}
                    errorText={delimiter.length !== 1 ? 'Please enter a single character' : undefined}
                    htmlAttributes={{
                      placeholder: 'Enter delimiter',
                      minLength: 1,
                      maxLength: 1,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {totalLogs > logsRowLimit && (
          <Banner type="warning" title="Download Limit Exceeded" className="mt-6" usage="inline">
            The selection contains <span className="font-semibold">{standardFormatAbsoluteNumber(totalLogs)}</span>{' '}
            logs, which exceeds the download limit of{' '}
            <span className="font-semibold">{standardFormatAbsoluteNumber(logsRowLimit)}</span>. Only the first logs
            within this limit will be included. Consider refining your filters for a more specific selection.
          </Banner>
        )}

        {isStaleData && (
          <Banner type="warning" title="Stale Selection" className="mt-6" usage="inline">
            The selected time period has changed, and the current table data no longer matches the timeline selection.
            Please fetch logs again to update the table, or confirm below to download the existing data.
          </Banner>
        )}

        {initSummaryDownloadError && (
          <ApiErrorBanner
            title="Error initiating summary log download"
            className="mt-6"
            error={initSummaryDownloadError}
          />
        )}

        {initDetailsDownloadError && (
          <ApiErrorBanner
            title="Error initiating details log download"
            className="mt-6"
            error={initDetailsDownloadError}
          />
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Button className="ml-auto mr-2" fill="outlined" color="neutral" isDisabled={false} onClick={onClose}>
          Cancel
        </Button>
        <Button
          size="medium"
          color="primary"
          isDisabled={!isValidForm}
          onClick={() => {
            void handleInitiateDownload({ logTypes, fileFormat, includeHeaders, delimiter });
          }}
          isLoading={isLoading}
          htmlAttributes={{
            'data-testid': 'initiate-logs-download',
          }}
        >
          Confirm
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
