import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import type { AnyAction } from 'redux';

import type { Category } from '../../types/category';
import { isFalsy } from '../../types/utility';
import { REHYDRATE } from '../persistence/constants';
import { CLEAR_SCENE } from '../rootActions';
import { getStyleRulesForCategory } from '../styles/styles';
import type { RootState } from '../types';
import { NATURAL_ORIENTATION, algorithmOptionsMap } from './constants';
import type { Algorithm, GdsState, Result, Rule } from './types';
import { countAndAppendStr } from './utils';

export const NAME = 'gds';

export const initialState: GdsState = {
  gdsRules: [],
  gdsResults: {},
  isGdsAvailable: false,
  gdsVersion: null,
  gdsProcedures: [],
  gdsProcedureDescMap: {},
};

/**
 * Selectors
 */
export const getGdsState = (state: RootState): GdsState => {
  return state[NAME];
};

export const getIsGdsAvailable = (state: RootState): GdsState['isGdsAvailable'] => {
  return state[NAME]?.isGdsAvailable;
};

export const getGdsProcedures = (state: RootState): GdsState['gdsProcedures'] => {
  return state[NAME]?.gdsProcedures;
};

export const getGdsVersion = (state: RootState): GdsState['gdsVersion'] => {
  return state[NAME]?.gdsVersion;
};

export const getGdsProcedureDescMap = (state: RootState): GdsState['gdsProcedureDescMap'] => {
  return state[NAME]?.gdsProcedureDescMap;
};

export const getGdsRules = createSelector(
  (state: RootState) => state[NAME]?.gdsRules,
  (gdsRules) => gdsRules ?? [],
);

export const getAlgorithmIds = createSelector(
  (state: GdsState) => state.gdsRules,
  (gdsRules) => gdsRules.map((rule) => rule.id),
);

export const findRuleIndex = (state: GdsState, id: Rule['id']) => state.gdsRules.findIndex((rule) => rule.id === id);

export const makeSelectGdsRuleById = () =>
  createSelector(
    getGdsRules,
    (state: RootState, gdsRuleId: Rule['id']) => gdsRuleId,
    (gdsRules, gdsRuleId) => {
      return gdsRules.find(({ id }) => id === gdsRuleId);
    },
  );

export const isAnyGdsRuleActive = (state: RootState) => {
  return getGdsRules(state).some((rule) => rule != null && getIsGdsStyleRuleApplied(state, rule.id));
};

export const getIsGdsStyleRuleApplied = (state: RootState, id: Rule['id']) => {
  const gdsRules = getGdsRules(state);
  const rule = gdsRules.find((rule) => rule.id === id);
  if (rule !== undefined) {
    const { selectedCategories, styleRuleId } = rule;
    if (!isFalsy(selectedCategories) && !isFalsy(styleRuleId)) {
      for (const category of selectedCategories) {
        const styleRules = getStyleRulesForCategory(state, category) ?? [];
        if (styleRules.some((styleRule) => styleRule.id === styleRuleId)) {
          return true;
        }
      }
    }
  }
  return false;
};

export const getGdsResults = (state: RootState): GdsState['gdsResults'] => {
  return state[NAME].gdsResults ?? {};
};

export const makeSelectGdsResultByRuleId = () =>
  createSelector(
    getGdsResults,
    (state: RootState, gdsRuleId: Rule['id']) => gdsRuleId,
    (gdsResults, gdsRuleId) => {
      return gdsResults[gdsRuleId];
    },
  );

export const getGdsDataByNodeId = createSelector(
  (state: RootState) => getGdsRules(state),
  (state: RootState) => getGdsResults(state),
  (gdsRules, gdsResults) => (nodeId: string) => {
    const nodeResults = gdsRules.reduce<Record<string, number>>((acc, { id: gdsRuleId }) => {
      const gdsData = gdsResults[gdsRuleId]?.data?.[nodeId];
      if (gdsData !== undefined) {
        acc[gdsRuleId] = gdsData;
      }
      return acc;
    }, {});
    return nodeResults;
  },
);

export const makeSelectGdsDataByNodeId = () =>
  createSelector(
    (state: RootState) => getGdsResults(state),
    (state: RootState) => getGdsRules(state),
    (state: RootState, nodeId: string) => nodeId,
    (gdsResults, gdsRules, nodeId) => {
      return gdsRules.reduce<Record<string, number>>((acc, gdsRule) => {
        const gdsData = gdsResults[gdsRule.id]?.data?.[nodeId];
        if (gdsData !== undefined) {
          acc[gdsRule.id] = gdsData;
        }
        return acc;
      }, {});
    },
  );

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    addGdsRule: (state) => {
      const newGdsRuleId = countAndAppendStr('undefined', getAlgorithmIds(state));
      const newGdsRule = {
        id: newGdsRuleId,
      };
      state.gdsRules.unshift(newGdsRule);
    },
    setAlgorithmType: (
      state,
      action: PayloadAction<{
        id: Rule['id'];
        algorithm: Algorithm;
        defaultCategories: Category['id'][];
        defaultRelTypes: string[];
      }>,
    ) => {
      const { id, algorithm, defaultCategories, defaultRelTypes } = action.payload;
      const algorithmName = algorithmOptionsMap[algorithm]?.name;
      const newGdsRuleId = countAndAppendStr(algorithmName, getAlgorithmIds(state));
      const index = findRuleIndex(state, id);
      if (index !== -1) {
        state.gdsRules[index] = {
          ...state.gdsRules[index],
          selectedAlgorithm: algorithm,
          id: newGdsRuleId,
          selectedOrientation: algorithmOptionsMap[algorithm].available_orientations[0],
          selectedCategories: defaultCategories,
          selectedRelTypes: defaultRelTypes,
        };
      }
    },
    updateGdsRule: (state, action: PayloadAction<{ id: Rule['id']; update: Partial<Rule> }>) => {
      const { id, update } = action.payload;
      const index = findRuleIndex(state, id);
      const rule = state.gdsRules[index];
      if (index !== -1 && rule !== undefined) {
        state.gdsRules[index] = {
          ...rule,
          ...update,
        };
      }
    },
    deleteGdsRule: (state, action: PayloadAction<Rule['id']>) => {
      state.gdsRules = state.gdsRules.filter(({ id }) => id !== action.payload);
    },
    resetGds: (state) => {
      state.gdsRules = initialState.gdsRules;
      state.gdsResults = initialState.gdsResults;
    },
    resetGdsRule: (state, action: PayloadAction<Rule['id']>) => {
      const index = findRuleIndex(state, action.payload);
      const rule = state.gdsRules[index];
      if (index !== -1 && rule !== undefined) {
        const { id, selectedCategories, selectedRelTypes, selectedRelWeightedProperty, selectedOrientation } = rule;
        state.gdsRules[index] = {
          id,
          selectedCategories,
          selectedRelTypes,
          selectedRelWeightedProperty,
          selectedOrientation,
        };
      }
    },
    clearGdsResults: (state, action: PayloadAction<string>) => {
      state.gdsResults[action.payload] = {};
    },
    resetGdsRuleConfig: (
      state,
      action: PayloadAction<{
        id: Rule['id'];
        defaultCategories: number[];
        defaultRelTypes: string[];
      }>,
    ) => {
      const { id, defaultCategories, defaultRelTypes } = action.payload;

      const index = findRuleIndex(state, id);
      const rule = state.gdsRules[index];
      if (index !== -1 && rule !== undefined) {
        const { id, selectedAlgorithm } = rule;
        state.gdsRules[index] = {
          id,
          selectedAlgorithm,
          selectedOrientation: NATURAL_ORIENTATION,
          selectedCategories: defaultCategories,
          selectedRelWeightedProperty: '',
          selectedRelTypes: defaultRelTypes,
        };
      }
    },
    setGdsState: (state, action: PayloadAction<Partial<GdsState>>) => {
      return action.payload !== undefined ? { ...state, ...action.payload } : state;
    },
    updateGdsResults: (state, action: PayloadAction<{ id: Rule['id']; result: Result }>) => {
      const { id, result } = action.payload;
      state.gdsResults[id] = result;
    },
    setIsGdsAvailable: (state, action: PayloadAction<boolean>) => {
      state.isGdsAvailable = action.payload;
    },
    setGdsVersion: (state, action: PayloadAction<GdsState['gdsVersion']>) => {
      state.gdsVersion = action.payload;
    },
    setGdsProcedures: (state, action: PayloadAction<GdsState['gdsProcedures']>) => {
      state.gdsProcedures = action.payload;
    },
    setGdsProcedureDescMap: (state, action: PayloadAction<GdsState['gdsProcedureDescMap']>) => {
      state.gdsProcedureDescMap = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<GdsState>) => {
    builder.addCase(REHYDRATE, (state, action: AnyAction) => {
      const payload = action?.payload[NAME] ?? {};
      return { ...state, ...payload };
    });
    builder.addCase(CLEAR_SCENE, (state) => {
      state.gdsRules = initialState.gdsRules;
      state.gdsResults = initialState.gdsResults;
    });
  },
});

export const {
  addGdsRule,
  setAlgorithmType,
  updateGdsRule,
  deleteGdsRule,
  resetGds,
  resetGdsRule,
  clearGdsResults,
  resetGdsRuleConfig,
  setGdsState,
  updateGdsResults,
  setIsGdsAvailable,
  setGdsVersion,
  setGdsProcedures,
  setGdsProcedureDescMap,
} = slice.actions;

export default slice.reducer;
