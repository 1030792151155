import type { Record } from 'neo4j-driver';

import { getMetadataForApp } from '../../modules/App/metadataFetcher';
import type { Role } from '../../state/connections/types';
import {
  addLabelsAsCategoriesToPerspective,
  addPerspective,
  addSearchTemplate,
  showCaseTemplate,
} from '../../state/perspectives/perspectives';
import { createPerspectiveThunk } from '../../state/perspectives/perspectives.thunks';
import { setCurrentPerspective } from '../../state/rootReducer';
import type { Database } from '../../types/database';
import type { Perspective } from '../../types/perspective';
import { PerspectiveType } from '../../types/perspective';
import type { Nullable } from '../../types/utility';
import { isFalsy } from '../../types/utility';
import bolt, { MANAGED_ROLLBACK_ERROR, USER_QUERY } from '../bolt/bolt';
import { isForbiddenError } from '../errors/errorUtils';
import { log } from '../logging';
import { METADATA_AUTO_SCAN_SAMPLE } from '../metagraph';
import { updateBasedOnDiff, updatePerspectiveStatsAndIndexes } from './metadataDiff';
import {
  assignRoleToPerspectiveQuery,
  checkPerspectiveWritePermissionQuery,
  createRoleQuery,
  fetchAllPerspectivesQuery,
  fetchPerspectiveQuery,
  fetchPerspectiveRolesQuery,
  fetchPerspectiveShaQuery,
  getDeletePerspectiveQuery,
  getStorePerspectiveQuery,
  removeRoleFromPerspectiveQuery,
  replacePerspectiveIdQuery,
} from './queryGenerator';
import {
  fetchPerspectiveRolesResultMapper,
  fetchPerspectivesResultMapper,
  fetchPerspectivesShaResultMapper,
  perspectiveResultMapper,
} from './resultMapper';
import type { CreateAutoPerspective, PerspectiveError, RefreshPerspective, UpdateQueue } from './types';

const updateQueue: UpdateQueue[] = [];

const performUpdate = async () => {
  if (updateQueue.length === 0) {
    return;
  }

  const [update] = updateQueue;
  if (!update) {
    return;
  }
  const { perspective, responseHandler, database } = update;

  const { sha } = perspective;
  delete perspective.sha;
  const { cypher, parameters } = getStorePerspectiveQuery(perspective, sha);

  const dbName = database != null ? database.name : undefined;
  try {
    const result = await bolt.writeTransaction(cypher, { parameters, database: dbName });
    if (responseHandler != null) {
      responseHandler(perspectiveResultMapper(result));
    }
    updateQueue.shift();
    void performUpdate();
  } catch (error) {
    responseHandler?.({ error });
    updateQueue.shift();
    void performUpdate();
  }
};

export const storePerspective = ({
  perspective,
  database,
  responseHandler,
}: {
  perspective: Perspective;
  database: Database;
  responseHandler: (result: unknown) => void;
}) => {
  updateQueue.push({ perspective, responseHandler, database });
  if (updateQueue.length === 1) {
    void performUpdate();
  }
};

export const fetchAllPerspectives = async ({ dbmsId, databases }: { dbmsId: string; databases: Database[] }) => {
  try {
    const databaseResults = await Promise.all(
      databases.map(async (database) => bolt.readTransaction(fetchAllPerspectivesQuery, { database: database.name })),
    );
    return databaseResults.reduce((acc: Perspective[], r: { records: Record[] }, i: number) => {
      const database = databases[i];
      if (!database) {
        return acc;
      }
      const { id: dbId, name: dbName } = database;
      acc = acc.concat(
        fetchPerspectivesResultMapper(r).map((perspective) => ({
          ...perspective,
          dbmsId,
          dbId,
          dbName,
          isPlugin: true,
        })),
      );
      return acc;
    }, []);
  } catch (error) {
    return { error };
  }
};

export const replacePerspectiveNodeId = async (id: string, newId: string, databaseName: string) => {
  const { cypher, parameters } = replacePerspectiveIdQuery(id, newId);
  try {
    await bolt.writeTransaction(cypher, { parameters, database: databaseName });
  } catch (e) {
    log.info('failed to replace duplicated perspective id: ', e);
  }
};

export const fetchPerspective = async ({ database, perspectiveId }: { database: Database; perspectiveId: string }) => {
  const { cypher, parameters } = fetchPerspectiveQuery(perspectiveId);
  const fetchResult = await bolt.readTransaction(cypher, { parameters, database: database.name });
  return fetchPerspectivesResultMapper(fetchResult)[0];
};

export const fetchPerspectiveSha = async ({
  database,
  perspectiveId,
}: {
  database: Database;
  perspectiveId: string;
}) => {
  const { cypher, parameters } = fetchPerspectiveShaQuery(perspectiveId);
  const fetchResult = await bolt.readTransaction(cypher, { parameters, database: database.name });
  return fetchPerspectivesShaResultMapper(fetchResult)[0];
};

export const deletePerspective = async ({
  perspectiveId,
  database,
}: {
  perspectiveId: string;
  database: Nullable<Database>;
}) => {
  const { cypher, parameters } = getDeletePerspectiveQuery(perspectiveId);
  const result = await bolt.writeTransaction(cypher, { parameters, database: database?.name });
  return perspectiveResultMapper(result);
};

export const fetchPerspectiveRoles = async (perspectiveId: string) => {
  const { cypher, parameters } = fetchPerspectiveRolesQuery(perspectiveId);
  try {
    const result = await bolt.readTransaction(cypher, { parameters });
    return fetchPerspectiveRolesResultMapper(result);
  } catch (error) {
    log.error(error);
    return [];
  }
};

export const assignRoleToPerspective = async (
  perspectiveId: string,
  role: Role,
  availableRoles: Role[],
  createRoleIfNew: boolean,
) => {
  const perspectiveRoles = await fetchPerspectiveRoles(perspectiveId);
  if (perspectiveRoles.includes(role)) {
    throw new Error('Failed to fetch roles: Invalid role name. The role is already assigned to the perspective.');
  }

  const query = createRoleQuery(role);
  const assign = assignRoleToPerspectiveQuery(perspectiveId, role);
  let createdRole = false;
  let result;

  try {
    if (!availableRoles.includes(role) && createRoleIfNew) {
      await bolt.writeSystemTransaction(query);

      createdRole = true;
    }
  } catch (e) {
    switch ((e as PerspectiveError).code) {
      case 'Neo.ClientError.General.InvalidArguments':
        throw new Error(`Invalid role name. Please check that the name only 
contains alphanumeric characters and the “_” character.`);
      case 'Neo.ClientError.Procedure.ProcedureNotFound':
        throw new Error("Couldn't create role.");
      default:
        throw new Error(`Invalid role name: ${(e as PerspectiveError).message}`);
    }
  }

  try {
    result = await bolt.writeTransaction(assign.cypher, { parameters: assign.parameters, type: USER_QUERY });
  } catch (e) {
    throw new Error(`Failed to assign perspective: ${(e as PerspectiveError).message}`);
  }

  const mappedResult = perspectiveResultMapper(result);
  if (mappedResult?.success !== true) {
    throw new Error(mappedResult?.message);
  }

  return {
    ...mappedResult,
    createdRole,
  };
};

export const removeRoleFromPerspective = async (perspectiveId: string, role: Role) => {
  try {
    const { cypher, parameters } = removeRoleFromPerspectiveQuery(perspectiveId, role);
    const result = await bolt.writeTransaction(cypher, { parameters });
    return perspectiveResultMapper(result);
  } catch (e) {
    log.error(e);
  }
};

export const refreshPerspective = async ({
  scanMode,
  database,
  serverVersion,
  currentPerspective,
  requestId,
  dispatch,
  shouldUpdateProps = true,
}: RefreshPerspective) => {
  if (isFalsy(currentPerspective)) {
    return;
  }
  try {
    const metadata = await getMetadataForApp(serverVersion, database, false, scanMode, requestId);

    const { hasChanged } = updateBasedOnDiff(currentPerspective, dispatch, shouldUpdateProps)(metadata);
    hasChanged && updatePerspectiveStatsAndIndexes(currentPerspective, dispatch)(metadata);

    return metadata;
  } catch (err) {
    !(err as PerspectiveError).ignore && log.error(err);
  }
};

export const checkPerspectiveWritePermission = async (database: string) => {
  const { cypher, parameters } = checkPerspectiveWritePermissionQuery();
  try {
    await bolt.writeAndRollbackTransaction(cypher, { parameters, database });
    return true;
  } catch (err) {
    if ((err as PerspectiveError).message === MANAGED_ROLLBACK_ERROR) {
      log.debug('User is allowed to update Perspective');
      return true;
    }
    if (isForbiddenError(err as PerspectiveError)) {
      log.info('User is not allowed to update Perspectives');
      log.debug(err);
    }
  }
  return false;
};

export const createAutoPerspective = async ({ database, serverVersion, dispatch, dbmsId }: CreateAutoPerspective) => {
  const newPerspective = await dispatch(
    createPerspectiveThunk({
      perspectiveName: 'Untitled Perspective 1',
      dbmsId,
      dbmsVersion: serverVersion,
      dbId: database?.id,
      dbName: database?.name,
      isPlugin: false,
      parentPerspectiveId: null,
      type: PerspectiveType.AUTO,
    }),
  ).unwrap();

  if (newPerspective === null) {
    log.error('Failed to generate a perspective');
    return;
  }

  dispatch(addPerspective(newPerspective));

  const metadata = await refreshPerspective({
    scanMode: METADATA_AUTO_SCAN_SAMPLE,
    database,
    serverVersion,
    // @ts-expect-error TODO typing for this is broken, because we run this on perspectives without styles as well
    currentPerspective: newPerspective,
    dispatch,
  });

  const { labels = [], labelsMap = {} } = metadata ?? {};

  dispatch(addLabelsAsCategoriesToPerspective(labels, newPerspective.id, labelsMap));

  if (isFalsy(newPerspective.isPlugin)) {
    dispatch(
      addSearchTemplate({
        templateName: showCaseTemplate.description,
        perspectiveId: newPerspective.id,
        templateData: {
          text: showCaseTemplate.name,
          cypher: showCaseTemplate.cypher,
        },
      }),
    );
  }

  dispatch(setCurrentPerspective(newPerspective.id));
};

export const createDefaultPerspective = async ({
  database,
  serverVersion,
  dispatch,
  dbmsId,
}: CreateAutoPerspective) => {
  const newPerspective = await dispatch(
    createPerspectiveThunk({
      perspectiveName: 'Default Perspective',
      dbmsId,
      dbmsVersion: serverVersion,
      dbId: database?.id,
      dbName: database?.name,
      isPlugin: false,
      parentPerspectiveId: null,
      type: PerspectiveType.DEFAULT,
    }),
  ).unwrap();

  if (newPerspective === null) {
    log.error('Failed to generate a perspective');
    return;
  }

  dispatch(addPerspective(newPerspective));

  const metadata = await refreshPerspective({
    scanMode: METADATA_AUTO_SCAN_SAMPLE,
    database,
    serverVersion,
    // @ts-expect-error TODO typing for this is broken, because we run this on perspectives without styles as well
    currentPerspective: newPerspective,
    dispatch,
  });

  const { labels = [], labelsMap = {} } = metadata ?? {};

  dispatch(addLabelsAsCategoriesToPerspective(labels, newPerspective.id, labelsMap));

  if (isFalsy(newPerspective.isPlugin)) {
    dispatch(
      addSearchTemplate({
        templateName: showCaseTemplate.description,
        perspectiveId: newPerspective.id,
        templateData: {
          text: showCaseTemplate.name,
          cypher: showCaseTemplate.cypher,
        },
      }),
    );
  }

  return newPerspective;
};
