import * as Needle from '@neo4j-ndl/react';
import * as React from 'react';

export function AnimationGroup(props: React.PropsWithChildren) {
  return <div className="animate-pulse">{props.children}</div>;
}

export function TextField(props: { label: string }) {
  return (
    <div className="ndl-text-input ndl-medium">
      <label className="ndl-form-item-label ndl-fluid w-full">
        <div className="ndl-label-text-wrapper">
          <span className="n-body-medium ndl-label-text !text-transparent">
            <span className="inline-flex gap-1">
              {props.label.split(' ').map((word, i) => (
                <span className="bg-palette-neutral-bg-strong rounded-md" key={i}>
                  {word}
                </span>
              ))}
            </span>
          </span>
        </div>
        <div className="ndl-input-wrapper !bg-palette-neutral-bg-strong !border-transparent">
          <div className="ndl-input" />
        </div>
      </label>
    </div>
  );
}

export function Typography(
  props: React.PropsWithChildren<{
    variant:
      | 'label'
      | 'code'
      | 'h1'
      | 'h2'
      | 'h3'
      | 'h4'
      | 'h5'
      | 'h6'
      | 'subheading-large'
      | 'subheading-medium'
      | 'subheading-small'
      | 'body-large'
      | 'body-medium'
      | 'body-small';
  }>,
) {
  return (
    <Needle.Typography variant={props.variant}>
      <span className="bg-palette-neutral-bg-strong rounded-md !text-transparent">{props.children}</span>
    </Needle.Typography>
  );
}
