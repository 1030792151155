import type { ObjectSchema } from 'yup';
import { array, boolean, number, object, string } from 'yup';

import type { CategoryPropertyKey, PerspectiveCategory } from '../../../types/perspective';

const categoryPropertyBasicSchema: ObjectSchema<CategoryPropertyKey> = object({
  name: string().required(),
  exclude: boolean().required(),
  dataType: string().required(),
});

const categorySchema: ObjectSchema<PerspectiveCategory> = object({
  id: number().required(),
  name: string().required(),
  color: string().required(),
  icon: string().required(),
  labels: array().of(string().defined().nonNullable()).required(),
  properties: array().of(categoryPropertyBasicSchema.required()).required(),
  captions: array().of(object()).nullable(),
  createdAt: number(),
  lastEditedAt: number(),
});

const searchPhraseSchema = object({
  id: string().required(),
  createdAt: number(),
  name: string().required(),
  text: string(),
  cypher: string(),
  params: array().of(
    object({
      name: string().required(),
      suggestionLabel: string().nullable(),
      suggestionProp: string().nullable(),
      cypher: string().nullable(),
    }).required(),
  ),
  hasCypherErrors: boolean(),
});

export const perspectiveBasicSchema = object({
  name: string().required(),
  id: string().required(),
  categories: array().of(categorySchema).required(),
  palette: object({
    colors: array().of(string()).required(),
    currentIndex: number().required().default(0),
  }),
  templates: array().of(searchPhraseSchema),
  createdAt: number(),
  lastEditedAt: number(),
  hiddenRelationshipTypes: array().of(string()).nullable(),
  hideUncategorisedData: boolean(),
});
