import type { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { isEmpty, isNil, isNumber } from 'lodash-es';

import { log } from '../../services/logging';
import migrate from '../../services/migrate';
import { REHYDRATE } from '../persistence/constants';
import { CLEAR_SCENE } from '../rootActions';
import type { RootState } from '../types';
import migrations from './migrations';

export const NAME = 'settings';

export const DEFAULT_QUERY_RESULT_LIMIT = 1000;
export const MAX_QUERY_RESULT_LIMIT = 10000;
export const UPDATE_QUERY_RESULT_LIMIT = 1000;
export const DEFAULT_TIMEOUT = 120;
export const DEFAULT_LOGOUT_TIMEOUT_IN_SECONDS = 3600;
export const LOGOUT_TIMEOUT_OFF = 'OFF';
const MAX_TIMEOUT = 600;
const MIN_TIMEOUT = 10;
export const SEARCH_HISTORY_LIMIT = 20;

export interface SettingsState {
  experimental: boolean;
  showRestoreModal: boolean;
  perspectiveAutoSyncEnabled: boolean;
  queryResultLimit: number;
  timeout: number;
  logoutTimeout: number;
  deepLinkLogoutTimeout: number | typeof LOGOUT_TIMEOUT_OFF | null;
  restoreScene: boolean;
  compatibilityMode: boolean;
  isCaseInsensitiveEnabled: boolean;
  autoSelectNewNodes: boolean;
  presentationMode: boolean;
  hideLegend: boolean;
  savedHideLegend: boolean;
  assetBaseUrl: string | null;
  searchPhraseWriteEnabled: boolean;
  isSearchExperimentValidationEnabled: boolean;
  username?: string;
  enableCytoscape: boolean;
}

export const initialState: SettingsState = {
  experimental: false,
  showRestoreModal: true,
  perspectiveAutoSyncEnabled: true,
  queryResultLimit: DEFAULT_QUERY_RESULT_LIMIT,
  timeout: DEFAULT_TIMEOUT,
  logoutTimeout: DEFAULT_LOGOUT_TIMEOUT_IN_SECONDS,
  deepLinkLogoutTimeout: null,
  restoreScene: true,
  compatibilityMode: false,
  isCaseInsensitiveEnabled: false,
  autoSelectNewNodes: false,
  presentationMode: false,
  hideLegend: false,
  savedHideLegend: false,
  assetBaseUrl: null,
  searchPhraseWriteEnabled: false,
  isSearchExperimentValidationEnabled: true,
  enableCytoscape: true,
};

const getState = (state: RootState): SettingsState => state[NAME] ?? initialState;
export const getPerspectiveAutoSyncEnabled = (state: RootState) => getState(state).perspectiveAutoSyncEnabled;
export const getRestoreSceneOption = (state: RootState) => getState(state).restoreScene;
export const getQueryResultLimit = (state: RootState) => getState(state).queryResultLimit;
export const getLogoutTimeout = (state: RootState) => {
  const { logoutTimeout, deepLinkLogoutTimeout } = getState(state);
  return deepLinkLogoutTimeout ?? logoutTimeout ?? DEFAULT_LOGOUT_TIMEOUT_IN_SECONDS;
};
export const getAutoSelectionState = (state: RootState) => getState(state).autoSelectNewNodes;
export const getPresentationMode = (state: RootState) => getState(state).presentationMode;
export const getHideLegend = (state: RootState) => getState(state).hideLegend;
export const getIsSearchExperimentValidationEnabled = (state: RootState) =>
  getState(state).isSearchExperimentValidationEnabled;
export const getEnableCytoscape = (state: RootState) => getState(state).enableCytoscape;
export const getAssetUrlForMapper = createSelector(
  (state: RootState) => getState(state).assetBaseUrl ?? '',
  (baseUrl) => (target: string) => {
    if (!isEmpty(target)) {
      if (!isEmpty(baseUrl)) {
        return baseUrl + target;
      }
      return `assets\\${target}`;
    }
    return baseUrl;
  },
);

export const getNewSessionExpiryTimestamp = (
  logoutTimeout: number | typeof LOGOUT_TIMEOUT_OFF = DEFAULT_LOGOUT_TIMEOUT_IN_SECONDS,
) => {
  return logoutTimeout !== LOGOUT_TIMEOUT_OFF ? Date.now() + logoutTimeout * 1000 : 0;
};

const updateForPresentationModeChange = (oldState: SettingsState, newState: SettingsState) => {
  if (oldState.presentationMode !== newState.presentationMode) {
    if (newState.presentationMode) {
      newState.savedHideLegend = newState.hideLegend;
      newState.hideLegend = true;
    } else {
      newState.hideLegend = newState.savedHideLegend;
    }
  }
  return newState;
};

const updateForHideLegendChange = (oldState: SettingsState, newState: SettingsState) => {
  if (oldState.hideLegend !== newState.hideLegend) {
    newState.savedHideLegend = newState.presentationMode && newState.hideLegend;
  }
  return newState;
};

export const getAssetUrl = createSelector(
  (state: RootState) => getState(state).assetBaseUrl ?? '',
  (baseUrl) => (target: string) => {
    if (!isEmpty(target)) {
      if (!isEmpty(baseUrl)) {
        return baseUrl + target;
      }
      return `assets\\${target}`;
    }
    return baseUrl;
  },
);

const settingsSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setShowRestoreModal: (state, action: PayloadAction<boolean>) => {
      state.showRestoreModal = action.payload;
    },
    setQueryResultLimit: (state, action: PayloadAction<string | number | null | undefined>) => {
      const limit = action.payload;
      if (isNil(limit)) {
        state.queryResultLimit = DEFAULT_QUERY_RESULT_LIMIT;
      } else if (typeof limit === 'string') {
        const limitNumber = parseInt(limit, 10);
        if (isNumber(limitNumber) && !isNaN(limitNumber)) {
          state.queryResultLimit = limitNumber;
        }
      } else if (typeof limit === 'number') {
        if (isNumber(limit) && !isNaN(limit) && limit >= 0) {
          state.queryResultLimit = limit;
        }
      } else {
        log.warn(`Value '${String(limit)}' is not recognized as a valid Query Result Limit`);
      }
    },
    setLogoutTimeout: (state, action: PayloadAction<number>) => {
      state.logoutTimeout = action.payload;
    },
    setDeepLinkLogoutTimeout: (state, action: PayloadAction<string | number | null | undefined>) => {
      const logoutTimeout = action.payload;
      if (!isNil(logoutTimeout)) {
        if (typeof logoutTimeout === 'string' && logoutTimeout.toUpperCase() === LOGOUT_TIMEOUT_OFF) {
          log.info('DeepLink: Logout Timeout is turned OFF');
          state.deepLinkLogoutTimeout = LOGOUT_TIMEOUT_OFF;
        } else if (typeof logoutTimeout === 'string') {
          const timer = parseInt(logoutTimeout, 10);
          if (isNumber(timer) && !isNaN(timer)) {
            log.info(`DeepLink: Logout Timeout is set to ${timer !== 0 ? `${timer.toString()}s` : LOGOUT_TIMEOUT_OFF}`);
            state.deepLinkLogoutTimeout = timer;
          }
        } else if (typeof logoutTimeout === 'number') {
          if (isNumber(logoutTimeout) && !isNaN(logoutTimeout) && logoutTimeout >= 0) {
            log.info(
              `DeepLink: Logout Timeout is set to ${
                logoutTimeout !== 0 ? `${logoutTimeout.toString()}s` : LOGOUT_TIMEOUT_OFF
              }`,
            );
            state.deepLinkLogoutTimeout = logoutTimeout;
          }
        }
      } else {
        log.warn(`DeepLink: Value '${logoutTimeout}' is not recognized as a valid Logout Timeout`);
        state.deepLinkLogoutTimeout = null;
      }
    },
    setTimeout: (state, action: PayloadAction<number>) => {
      const timeout = action.payload;
      if (timeout < MIN_TIMEOUT) {
        state.timeout = MIN_TIMEOUT;
      } else if (timeout > MAX_TIMEOUT) {
        state.timeout = MAX_TIMEOUT;
      } else {
        state.timeout = timeout;
      }
    },
    setRestoreSceneOption: (state, action: PayloadAction<boolean>) => {
      state.restoreScene = action.payload;
    },
    setAutoSelectNewNodes: (state, action: PayloadAction<boolean>) => {
      state.autoSelectNewNodes = action.payload;
    },
    togglePresentationMode: (state) => {
      const newState = updateForPresentationModeChange(state, { ...state, presentationMode: !state.presentationMode });
      state.presentationMode = newState.presentationMode;
      state.hideLegend = newState.hideLegend;
      state.savedHideLegend = newState.savedHideLegend;
    },
    setHideLegend: (state, action: PayloadAction<boolean>) => {
      const newState = updateForHideLegendChange(state, { ...state, hideLegend: action.payload });
      state.hideLegend = newState.hideLegend;
    },
    toggleHideLegend: (state) => {
      const newState = updateForHideLegendChange(state, { ...state, hideLegend: !state.hideLegend });
      state.hideLegend = newState.hideLegend;
    },
    setAssetBaseUrl: (state, action: PayloadAction<string | null>) => {
      state.assetBaseUrl = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action: AnyAction) => {
      if (!isNil(action.payload?.[NAME])) {
        const { settings } = action.payload;

        const migratedSettings = migrate(settings, migrations, action.payload.version);
        return {
          ...state,
          ...migratedSettings,
        };
      }
      return { ...state };
    });
    builder.addCase(CLEAR_SCENE, (state) => {
      return updateForPresentationModeChange(state, { ...state, presentationMode: false });
    });
  },
});

export const {
  setShowRestoreModal,
  setQueryResultLimit,
  setLogoutTimeout,
  setDeepLinkLogoutTimeout,
  setTimeout,
  setRestoreSceneOption,
  setAutoSelectNewNodes,
  togglePresentationMode,
  setHideLegend,
  toggleHideLegend,
  setAssetBaseUrl,
} = settingsSlice.actions;

export default settingsSlice.reducer;
