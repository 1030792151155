import { contrast, oklchToRgb, rgbToHex } from './utils';

// Based on https://stackoverflow.com/a/13532993
const shadeColor = (color: string, percent: number) =>
  `#${[parseInt(color.substring(1, 3), 16), parseInt(color.substring(3, 5), 16), parseInt(color.substring(5, 7), 16)]
    .map((c) => {
      let a = parseInt(((c * (100 + percent)) / 100).toString(), 10);
      const b = (a = a < 255 ? a : 255).toString(16);
      return b.length === 1 ? `0${b}` : b;
    })
    .join('')}`;

// Details on hash algo https://stackoverflow.com/a/33647870
function getHash(str: string) {
  let hash = 0;
  let i = 0;
  const len = str.length;
  while (i < len) {
    hash = ((hash << 5) - hash + str.charCodeAt(i)) << 0;
    i += 1;
  }
  return hash;
}

export function positiveHash(str: string) {
  return getHash(str) + 2147483647 + 1;
}

/** 
 returns the most readable color for the given background color 
 * @param {string} backgroundColor The background color in hex format used to determine contrast.
 * @param {string[]} colors Foreground colors in hex format to find the most readable color from.
 * @param {boolean} fallbackColors If no colors is considred readable should black and white be used as fallback?
 * @param {number} minContrastRatio The minumum contrast ratio for color to be considred readable.
 * @return {string} The most readable color from colors or fallback if enabled.
*/
export function mostReadable(
  backgroundColor: string,
  colors: string[],
  fallbackColors = false,
  // Default WCAG AA contrast ratio
  minContrastRatio = 4.5,
): string {
  let bestColor = colors[0] ?? '';
  let bestContrast = -1;
  colors.forEach((color) => {
    const colorContrast = contrast(backgroundColor, color);
    if (colorContrast > bestContrast) {
      bestColor = color;
      bestContrast = colorContrast;
    }
  });

  if (bestContrast < minContrastRatio && fallbackColors) {
    return mostReadable(backgroundColor, [bestColor, '#000000', '#ffffff'], false, minContrastRatio);
  }
  return bestColor;
}

type CalcOptions = { lightMax?: number; lightMin?: number; chromaMax?: number; chromaMin?: number };
export function calcWordColor(word: string, config: CalcOptions = {}): string {
  const { lightMax = 95, lightMin = 70, chromaMax = 20, chromaMin = 5 } = config;
  const first = positiveHash(getHash(word).toString());
  const second = positiveHash(first.toString());
  const third = positiveHash(second.toString());
  const between = (hash: number, from: number, to: number) => (hash % (to - from)) + from;

  const rgb = oklchToRgb(
    between(first, lightMin, lightMax) / 100,
    between(second, chromaMin, chromaMax) / 100,
    between(third, 0, 360),
  );

  return rgbToHex(rgb);
}

export function calculateDefaultNodeColors(
  nodeLabel: string,
  config?: CalcOptions,
): {
  backgroundColor: string;
  borderColor: string;
  textColor: string;
} {
  const bkg = calcWordColor(nodeLabel, config);
  const border = shadeColor(bkg, -20);
  const text = mostReadable(bkg, ['#2A2C34', '#FFFFFF']);

  return {
    backgroundColor: bkg,
    borderColor: border,
    textColor: text,
  };
}
