import { ERROR_REASONS } from './model';

export const isEmailNotVerified = (reason: string | undefined) => {
  if (reason === ERROR_REASONS.EMAIL_UNVERIFIED) {
    return true;
  }
  return false;
};

export const isAccountLinkingRequired = (reason: string | undefined) => {
  if (reason === ERROR_REASONS.ACCOUNT_LINK_REQUIRED) {
    return true;
  }
  return false;
};
