export const LEGACY_DATA_SOURCE_OPTIONS = ['postgresql', 'mysql', 'sqlserver', 'oracle', 'snowflake'] as const;
export type LegacyDataSourceType = (typeof LEGACY_DATA_SOURCE_OPTIONS)[number];

export const isLegacyDataSource = (driver: string): driver is LegacyDataSourceType => {
  const availableDrivers: string[] = [...LEGACY_DATA_SOURCE_OPTIONS];
  return availableDrivers.includes(driver);
};

type DataSourceCommonConfiguration = {
  name: string;
  driver: LegacyDataSourceType;
  host: string;
  port: number;
  user: string;
  password: string;
  database?: string;
  schema?: string;
  warehouse?: string;
  role?: string;
};

type OracleDataSourceServiceConfiguration = {
  service?: string;
};

export type LegacyDataSourceConfig = DataSourceCommonConfiguration & OracleDataSourceServiceConfiguration;

// Legacy format of data source configuration, will be changed when moved to store in import service storage
export type LegacyDataSourceToSave = Omit<LegacyDataSourceConfig, 'password'>;
