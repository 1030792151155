import {
  DateTimeFormatter,
  ZonedDateTime as JodaZonedDateTime, // @ts-expect-error: Outdated @types/js-joda
  YearConstants,
  ZoneId,
  ZoneOffset,
  ZoneRegion,
} from '@js-joda/core';
import '@js-joda/timezone';
import { isEmpty, isNil } from 'lodash-es';
import type { Moment } from 'moment';

import { log } from '../logging';
import { BaseTemporal } from './BaseTemporal';
import { Date } from './Date';
import { LocalDateTime } from './LocalDateTime';
import type { DateTimeProperties, TimeZoneProperties } from './types';
import { DATETIME_REGEX, DATE_REGEX } from './utils.const';

export class DateTime extends BaseTemporal {
  neo4jDateTime: DateTimeProperties;

  localDateTime: LocalDateTime;

  timezone: ZoneId;

  constructor(obj: DateTimeProperties & TimeZoneProperties) {
    super();

    this.neo4jDateTime = obj;

    const { timeZoneOffsetSeconds, timeZoneId, zoneStr } = obj;

    const zone = timeZoneId ?? zoneStr ?? BaseTemporal.convertSecondsToZoneStr(timeZoneOffsetSeconds ?? 0);

    this.localDateTime = new LocalDateTime(obj);
    this.timezone = ZoneId.of(zone);
  }

  static parseNumberToString(dateTime: number, hideTime = false) {
    const dateString = Date.parseNumberToString(dateTime);
    return hideTime ? dateString : `${dateString}T00:00:00Z`;
  }

  static parseStringToObj(stringVal: string) {
    const newStringVal = this.addSymbolToYearIfNeeded(stringVal);
    let year, month, day, hour, minute, second, nanosecond, timezone;

    try {
      const dateTimeMatch = DATETIME_REGEX.exec(newStringVal);

      if (!isNil(dateTimeMatch)) {
        year = (dateTimeMatch[1] ?? '+') + dateTimeMatch[2];
        month = dateTimeMatch[3];
        day = dateTimeMatch[4];
        hour = dateTimeMatch[5];
        minute = dateTimeMatch[6];
        second = dateTimeMatch[7];
        nanosecond = this.convertNanoStringToNum(dateTimeMatch[8]);
        timezone = dateTimeMatch[9] ?? dateTimeMatch[10];
      }
    } catch (e) {
      log.debug('Invalid Date Time value');
    }

    const yearInt = parseInt(year ?? '');
    const monthInt = parseInt(month ?? '');
    const dayInt = parseInt(day ?? '');
    const hourInt = parseInt(hour ?? '');
    const minuteInt = parseInt(minute ?? '');
    const secondInt = parseInt(second ?? '');
    const nanosecondInt = parseInt(nanosecond ?? '');
    const zoneStr = (timezone ?? 'Z').replace('[', '').replace(']', '');

    return new DateTime({
      year: yearInt,
      month: monthInt,
      day: dayInt,
      hour: hourInt,
      minute: minuteInt,
      second: secondInt,
      nanosecond: nanosecondInt,
      zoneStr,
    });
  }

  static parseStringToNumber(date: string) {
    if (isEmpty(date)) {
      return NaN;
    }

    const dateTimeMatch = DATE_REGEX.exec(date);
    const dateTimeStringWithoutTime = !isNil(dateTimeMatch) ? dateTimeMatch[0] : date;

    return Date.parseStringToNumber(dateTimeStringWithoutTime);
  }

  static parseMomentObjToObj(momentObj: Moment, addedNanoseconds = 0, zoneStr = 'Z') {
    return new DateTime({
      year: momentObj.year(),
      month: momentObj.month() + 1,
      day: momentObj.date(),
      hour: momentObj.hour(),
      minute: momentObj.minute(),
      second: momentObj.second(),
      nanosecond: momentObj.millisecond() * 1000000 + addedNanoseconds,
      zoneStr,
    });
  }

  getYear() {
    return this.neo4jDateTime.year;
  }

  getMonth() {
    return this.neo4jDateTime.month;
  }

  getDay() {
    return this.neo4jDateTime.day;
  }

  getHour() {
    return this.neo4jDateTime.hour;
  }

  getMinute() {
    return this.neo4jDateTime.minute;
  }

  getSecond() {
    return this.neo4jDateTime.second;
  }

  getNanosecond() {
    return this.neo4jDateTime.nanosecond;
  }

  getTimeZoneOffsetSeconds() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return this.timezone.totalSeconds?.();
  }

  getTimeZoneId() {
    return this.timezone.id();
  }

  getTimezone() {
    return this.timezone;
  }

  setTimezone(zoneStr: string) {
    return new DateTime({
      year: this.localDateTime.getYear(),
      month: this.localDateTime.getMonth(),
      day: this.localDateTime.getDay(),
      hour: this.localDateTime.getHour(),
      minute: this.localDateTime.getMinute(),
      second: this.localDateTime.getSecond(),
      nanosecond: this.localDateTime.getNanosecond(),
      zoneStr,
    });
  }

  // shift the datetime to a new timezone, effectively the datetime remains the same
  // e.g., 04:00:00Z with +01:00 -> 05:00:00+01:00 (equivalent to 04:00:00Z)
  setTimezoneShiftTo(zoneStr: string) {
    const fullYear = this.getYear();
    // Joda can only handle year range [-999999, 999999]
    const jodaCompatibleYear = fullYear < YearConstants.MIN_VALUE || fullYear > YearConstants.MAX_VALUE ? 0 : fullYear;

    const jodaZonedDateTime = JodaZonedDateTime.of(
      jodaCompatibleYear,
      this.getMonth(),
      this.getDay(),
      this.getHour(),
      this.getMinute(),
      this.getSecond(),
      this.getNanosecond(),
      this.getTimezone(),
    );

    const convertedJodaZonedDateTime = jodaZonedDateTime.withZoneSameInstant(ZoneId.of(zoneStr));

    // delta should be the same: convertedJodaZonedDateTime.year() - jodaCompatibleYear = convertedYear - fullYear
    const convertedYear = fullYear + (convertedJodaZonedDateTime.year() - jodaCompatibleYear);

    const shiftedDateTime = new DateTime({
      year: convertedYear,
      month: convertedJodaZonedDateTime.monthValue(),
      day: convertedJodaZonedDateTime.dayOfMonth(),
      hour: convertedJodaZonedDateTime.hour(),
      minute: convertedJodaZonedDateTime.minute(),
      second: convertedJodaZonedDateTime.second(),
      nanosecond: convertedJodaZonedDateTime.nano(),
      zoneStr,
    });

    return shiftedDateTime;
  }

  static _getDateTimeFormatter(numberOfNanoDigits = 0) {
    if (numberOfNanoDigits === 0) {
      return DateTimeFormatter.ofPattern("yyyy-MM~-dd'T'HH:mm:ssXXXXX");
    }
    return DateTimeFormatter.ofPattern(`yyyy-MM-dd'T'HH:mm:ss.${'S'.repeat(numberOfNanoDigits)}XXXXX`);
  }

  toString() {
    if (this.timezone instanceof ZoneRegion) {
      return `${this.localDateTime.toString()}[${this.timezone.toString()}]`;
    } else if (this.timezone instanceof ZoneOffset) {
      return this.localDateTime.toString() + this.timezone.toString();
    }
  }

  // the toString() result of neo4j.types.LocalTime
  toNeo4jString() {
    return BaseTemporal.addZeroesToYearIfNeeded(this.toString() ?? '');
  }

  toPropertyObject(): DateTimeProperties & TimeZoneProperties {
    return {
      year: this.localDateTime.getYear(),
      month: this.localDateTime.getMonth(),
      day: this.localDateTime.getDay(),
      hour: this.localDateTime.getHour(),
      minute: this.localDateTime.getMinute(),
      second: this.localDateTime.getSecond(),
      nanosecond: this.localDateTime.getNanosecond(),
      timeZoneId: this.timezone instanceof ZoneRegion ? this.timezone.toString() : null,
      timeZoneOffsetSeconds: this.timezone instanceof ZoneOffset ? this.timezone.totalSeconds() : null,
    };
  }

  toMomentObj() {
    return this.localDateTime.toMomentObj();
  }
}
