import type { EndpointBuilder } from '@reduxjs/toolkit/query';

import type { DynamicBaseQueryFn } from '../../../utils/create-dynamic-base-query';

export type TagTypes =
  | 'Snapshot'
  | 'Instance'
  | 'Database'
  | 'Project'
  | 'ProjectMember'
  | 'ProjectInvite'
  | 'UserInvite'
  | 'UserDetails'
  | 'EncryptionKey'
  | 'Usage'
  | 'Consumption'
  | 'Invoice'
  | 'Customer'
  | 'Card'
  | 'OauthClient'
  | 'Log'
  | 'Organization'
  | 'OrganizationMember'
  | 'SsoConfig'
  | 'TrafficConfig'
  | 'LogForwardingList'
  | 'LogForwardingIdentities'
  | 'CustomEndpoint'
  | 'CustomEndpointDetails'
  | 'GDSSession'
  | 'OrganizationSeamlessProfile'
  | 'OrganizationProject'
  | 'ProjectNotification'
  | 'BillingAccount';

export const tagTypes: TagTypes[] = [
  'Snapshot',
  'Instance',
  'Database',
  'Project',
  'ProjectMember',
  'ProjectInvite',
  'UserInvite',
  'UserDetails',
  'EncryptionKey',
  'Usage',
  'Consumption',
  'Invoice',
  'Customer',
  'Card',
  'OauthClient',
  'Log',
  'Organization',
  'OrganizationMember',
  'SsoConfig',
  'TrafficConfig',
  'LogForwardingList',
  'LogForwardingIdentities',
  'CustomEndpoint',
  'CustomEndpointDetails',
  'GDSSession',
  'OrganizationSeamlessProfile',
  'OrganizationProject',
  'ProjectNotification',
  'BillingAccount',
] as const;

export type Builder = EndpointBuilder<DynamicBaseQueryFn, TagTypes, 'consoleApi'>;

export const LIST_ID = 'LIST';
