import type { Label } from '@neo4j-ndl/react';
import type { Instance, ProjectSummary } from '@nx/state';
import type { ComponentProps, PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';

/**
 * Importing code defined in the upx app would create a circular dependency.
 * This context is a workaround to allow the upx app to provide us with the necessary functions.
 * Should be used as a last resort, after weighting out alternatives (e.g. move dependency
 * in a shared package, redefine dependency in this app).
 */
// TODO: remove console-owned parts when console gets its own app.

type UpxContextType = {
  // console owned
  getOnlineStatusColor: (instance: Instance) => ComponentProps<typeof Label>['color'];
  // console owned
  instanceStatusText: (instance: Instance, project: ProjectSummary | null) => string;
};

const UpxContext = createContext<UpxContextType | null>(null);

const UpxContextProvider = ({
  upxContext,
  children,
}: PropsWithChildren<{
  /** upx owned code which would introduce a circular dependency when directly imported */
  upxContext: UpxContextType;
}>) => {
  return <UpxContext.Provider value={upxContext}>{children}</UpxContext.Provider>;
};

export const withUpxContext =
  (WrappedComponent: () => React.JSX.Element) =>
  // eslint-disable-next-line react/display-name
  ({ upxContext }: Pick<ComponentProps<typeof UpxContextProvider>, 'upxContext'>) => (
    <UpxContextProvider upxContext={upxContext}>
      <WrappedComponent />
    </UpxContextProvider>
  );

export const useUpxContext = () => {
  const context = useContext(UpxContext);
  if (!context) {
    throw new Error('useUpxContext must be used within a UpxContextProvider');
  }
  return context;
};
