import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../store';

export type UiAsyncLoadingStatus = 'idle' | 'loading' | 'success' | 'error';

export const IMPORT_TABS = ['sources', 'models', 'jobs'] as const;
export type ImportTab = (typeof IMPORT_TABS)[number];
type LastVisited =
  | {
      type: 'tabs';
      tab: ImportTab;
    }
  | {
      type: 'model-view';
      importModelId: string;
    };

// TODO: better types
export type UiAsyncLoadingError = { message: string; data: unknown };

export type UiState = {
  leftPanelWidth: number;
  rightPanelWidth: number;
  windowSize: { width: number; height: number };
  dataSourcePanel: {
    isVisible: boolean;
    panelType: 'overlay' | 'push';
  };
  modelPanel: {
    status: UiAsyncLoadingStatus;
    shouldFitToError: boolean;
  };
  detailsPanel: {
    selectedDetailsTab: number;
  };
  loadPreviewDialog: {
    sidePanel: {
      isVisibile: boolean;
    };
  };
  generateGraphDialog: {
    isVisible: boolean;
  };
  lastVisited: LastVisited;
};

export const initialState: UiState = {
  leftPanelWidth: 400,
  rightPanelWidth: 460,
  windowSize: { width: 1200, height: 800 },
  dataSourcePanel: {
    isVisible: false,
    panelType: 'push',
  },
  detailsPanel: {
    selectedDetailsTab: 0,
  },
  modelPanel: {
    status: 'idle',
    shouldFitToError: false,
  },
  loadPreviewDialog: {
    sidePanel: {
      isVisibile: true,
    },
  },
  generateGraphDialog: {
    isVisible: false,
  },
  lastVisited: {
    type: 'tabs',
    tab: 'sources',
  },
};

const uiSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setWindowSize(state, action: PayloadAction<{ width: number; height: number }>) {
      state.windowSize = action.payload;
    },
    setLeftPanelWidth(state, action: PayloadAction<number>) {
      state.leftPanelWidth = action.payload;
    },
    setRightPanelWidth(state, action: PayloadAction<number>) {
      state.rightPanelWidth = action.payload;
    },
    setDataSourcePanelVisibility(state, action: PayloadAction<boolean>) {
      state.dataSourcePanel.isVisible = action.payload;
    },
    setDataSourcePanelType(state, action: PayloadAction<'overlay' | 'push'>) {
      state.dataSourcePanel.panelType = action.payload;
    },
    setGraphLoadingStatus(state, action: PayloadAction<UiAsyncLoadingStatus>) {
      state.modelPanel.status = action.payload;
    },
    setShouldFitToError(state, action: PayloadAction<boolean>) {
      state.modelPanel.shouldFitToError = action.payload;
    },
    setSelectedDetailsTab(state, action: PayloadAction<number>) {
      state.detailsPanel.selectedDetailsTab = action.payload;
    },
    setLoadPreviewPanelVisibility(state, action: PayloadAction<boolean>) {
      state.loadPreviewDialog.sidePanel.isVisibile = action.payload;
    },
    setGenerateGraphDialogVisibility(state, action: PayloadAction<boolean>) {
      state.generateGraphDialog.isVisible = action.payload;
    },
    setLastVisited(state, action: PayloadAction<LastVisited>) {
      state.lastVisited = action.payload;
    },
  },
});

export const selectWindowSize = (state: RootState) => state.ui.windowSize;

export const selectLeftPanelWidth = (state: RootState) => state.ui.leftPanelWidth;

export const selectRightPanelWidth = (state: RootState) => state.ui.rightPanelWidth;

export const selectDataSourcePanel = (state: RootState) => state.ui.dataSourcePanel;

export const selectModelPanel = (state: RootState) => state.ui.modelPanel;

export const selectLoadPreviewDialog = (state: RootState) => state.ui.loadPreviewDialog;

export const selectGenerateGraphDialogVisibility = (state: RootState) => state.ui.generateGraphDialog.isVisible;

export const selectLastVisited: (state: RootState) => ReturnType<(state: RootState) => LastVisited> = createSelector(
  (state: RootState) => state.ui,
  (state) => state.lastVisited,
);

export const {
  setWindowSize,
  setLeftPanelWidth,
  setRightPanelWidth,
  setDataSourcePanelVisibility,
  setDataSourcePanelType,
  setGraphLoadingStatus,
  setShouldFitToError,
  setSelectedDetailsTab,
  setLoadPreviewPanelVisibility,
  setGenerateGraphDialogVisibility,
  setLastVisited,
} = uiSlice.actions;
export default uiSlice.reducer;
