import type { MetricsResult } from '@nx/state';
import { useDarkMode } from '@nx/ui';
import { memo, useEffect, useMemo } from 'react';
import useResizeObserver from 'use-resize-observer';

import { arePropsDeepEqual } from '../../../shared/ui-helpers';
import { metricKey } from '../../../shared/utils';
import { chartKey, prepareDashboardPlotOptionsBuilder, useChartState, useMultiChartProps } from '../../hooks';
import { Plot } from '../../plot';
import type { ChartContext, MultiChartProps } from '../types';
import { LoadingBanner, NoDataBanner } from './components/banners';
import { DashboardCard } from './components/cards';

interface DashboardLineCardProps {
  multiChartProps: MultiChartProps;
  context: ChartContext;
  metricsResult: MetricsResult;
}

export const DashboardLineCard = memo(({ multiChartProps, context, metricsResult }: DashboardLineCardProps) => {
  const isDarkTheme = useDarkMode();

  const { chartProps } = useMultiChartProps(multiChartProps);

  const { state, processData } = useChartState(chartProps);

  const plotParentRO = useResizeObserver();

  const data = useMemo(
    () => metricsResult.metrics?.[metricKey(chartProps, false, context.selectedDB)],
    [chartProps, context.selectedDB, metricsResult.metrics],
  );

  const dataExistence =
    (data &&
      Object.keys(data.dataSeries ?? {}).length > 0 &&
      Object.values(data.dataSeries ?? {}).some((elementMetrics) => elementMetrics.length > 0)) ??
    false;

  const plotOptions = useMemo(() => {
    const builder = prepareDashboardPlotOptionsBuilder({
      state,
      chartProps,
      ignoredInstances: context.ignoredInstances,
      isDarkTheme,
    });

    builder.updateXAxis({
      size: 25,
      values: ['Last 24 hours'],
      ticks: { show: false },
    });

    builder.updateScales({ x: { auto: false, range: undefined } });

    // disable zooming
    builder.updateCursor({
      drag: {
        setScale: false,
      },
    });
    builder.updateSelect({ show: false, left: 0, width: 0, top: 0, height: 0 });

    return builder.getOptions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartProps, context.ignoredInstances, state, isDarkTheme]);

  useEffect(() => {
    if (dataExistence) {
      processData({ data: data ?? {}, timeRange: context.timeRange, chartProps });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dataExistence, processData, chartProps]);

  return (
    <DashboardCard
      aria-label={`Metric card for ${chartProps.metricName}`}
      title={chartProps.title}
      loading={dataExistence && metricsResult.isLoading}
      controls={[]}
      key={chartKey(chartProps)}
      style={{
        maxHeight: '350px',
      }}
    >
      <div className="h-full w-full" ref={plotParentRO.ref}>
        {metricsResult.isLoading && !dataExistence && <LoadingBanner />}
        {!metricsResult.error && (
          <>
            {dataExistence || metricsResult.isLoading ? null : <NoDataBanner />}
            {dataExistence && (
              <Plot
                width={plotParentRO.width ?? 0}
                height={200}
                data={state.alignedDataDisplay}
                options={plotOptions}
              />
            )}
          </>
        )}
      </div>
    </DashboardCard>
  );
}, arePropsDeepEqual);

DashboardLineCard.displayName = 'DashboardLineCard';
