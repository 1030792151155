import { DataGrid } from '@neo4j-ndl/react';
import type {
  TimeRangeQueryLog,
  TimeRangeQueryLogAggregation,
  TimeRangeSecurityLog,
  TimeRangeSecurityLogSummary,
} from '@nx/state';
import { useActiveProject, useColumnPreferences } from '@nx/state';
import { DataGridHelpers } from '@nx/ui';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { ColumnDef, OnChangeFn, PaginationState, SortingState } from '@tanstack/react-table';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { produce } from 'immer';
import { useEffect, useRef } from 'react';

import { capitalizeFirstLowerRest } from '../../shared/ui-helpers';
import classes from '../logs.module.css';
import { FilterTags } from '../shared/components/log-filters/filter-tags';
import { MessageTag } from '../shared/components/message-tag';
import { ColumnPreferencesHeaderCell, NoDataPlaceholder, TableResults } from '../shared/helpers/table-overrides';
import { useIsFirstRender } from '../shared/hooks/use-is-first-render';
import { ACTIONS_COLUMN_ID } from '../shared/types';
import { TableControls } from './helpers/table-controls';
import { useColumns } from './hooks/use-columns';
import { useLogsContext } from './hooks/use-logs-context';
import { getActiveFilters, getActiveSearchFilters } from './shared/mappers';

interface QueryLogsDataGridProps {
  dataGridType: 'Summary' | 'Details';
  logs: TimeRangeSecurityLog[] | TimeRangeSecurityLogSummary[];
  securityLogsLoading: boolean;
  pageInfo: {
    pageCount: number;
    itemCount: number;
  };
  detailedSecurityLog?: TimeRangeSecurityLog | null;
  securityLogsError: FetchBaseQueryError | SerializedError | undefined;
  selectedDbmsId: string;
  controlledPagination: {
    pageIndex: number;
    pageSize: number;
  };
  controlledSorting: SortingState;
  onPaginationChange?: OnChangeFn<PaginationState>;
  onSortingChange?: OnChangeFn<SortingState>;
}

export const SecurityLogsDataGrid = ({
  dataGridType,
  logs,
  securityLogsLoading,
  pageInfo,
  detailedSecurityLog,
  securityLogsError,
  selectedDbmsId,
  controlledPagination,
  controlledSorting,
  onPaginationChange,
  onSortingChange,
}: QueryLogsDataGridProps) => {
  const activeProject = useActiveProject();
  const selectedTenantId = activeProject.id;
  const { rawFilters, filterInput, setRawFilters, hasRequestedData } = useLogsContext();

  const hasData = useRef(Boolean(logs.length));
  if (!securityLogsLoading) {
    hasData.current = Boolean(logs.length);
  }

  const columns = useColumns(dataGridType, hasData.current);
  const columnPrefs = useColumnPreferences(
    'security',
    dataGridType,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    columns as typeof dataGridType extends 'Summary'
      ? ColumnDef<TimeRangeQueryLogAggregation>[]
      : ColumnDef<TimeRangeQueryLog>[],
  );
  const tableProps = useReactTable({
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    columns: columns as typeof dataGridType extends 'Summary'
      ? ColumnDef<TimeRangeSecurityLogSummary>[]
      : ColumnDef<TimeRangeSecurityLog>[],
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    data: logs as typeof dataGridType extends 'Summary' ? TimeRangeSecurityLogSummary[] : TimeRangeSecurityLog[],
    defaultColumn: {
      enableColumnFilter: false,
      sortDescFirst: true,
    },
    manualPagination: true,
    manualSorting: true,
    pageCount: pageInfo.pageCount,
    enableColumnPinning: true,
    state: {
      pagination: controlledPagination,
      sorting: controlledSorting,
      columnOrder: columnPrefs.prefs.columnOrder,
      columnVisibility: columnPrefs.prefs.columnVisibility,
      columnPinning: {
        right: [ACTIONS_COLUMN_ID],
      },
    },
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onChange',
    onPaginationChange,
    onSortingChange,
    onColumnOrderChange: columnPrefs.onColumnOrderChange,
    onColumnVisibilityChange: columnPrefs.onColumnVisibilityChange,
  });

  const isFirstRender = useIsFirstRender();

  // Listen to changes in table data and reset the paging accordingly
  useEffect(() => {
    if (isFirstRender && logs.length > 0) {
      // Do not reset paging if the useEffect is triggered by component re-mounting with existing data
      return;
    }
    controlledPagination.pageIndex = 0;
    if (logs.length === 0) {
      pageInfo.pageCount = 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterInput, tableProps, selectedDbmsId, selectedTenantId]);

  return (
    <>
      <DataGridHelpers.OuterHeader>
        <TableControls fetchError={securityLogsError} selectedDbmsId={selectedDbmsId}>
          <FilterTags
            activeFilters={getActiveFilters(rawFilters.securityLogFilters)}
            activeSearchFilters={getActiveSearchFilters(rawFilters)}
            activeNumericalFilters={[]}
            valueFormatters={{ statuses: capitalizeFirstLowerRest }}
          />
          {detailedSecurityLog && (
            <div className="mt-4">
              <MessageTag
                message={detailedSecurityLog.message}
                onRemove={() =>
                  setRawFilters(
                    produce((draft) => {
                      draft.message = undefined;
                    }),
                  )
                }
              />
            </div>
          )}
        </TableControls>
      </DataGridHelpers.OuterHeader>
      <DataGrid
        rootProps={{
          className: `[&_.ndl-data-grid-pinned-cell-right]:!grow [&_[role=columnheader].ndl-data-grid-pinned-cell-right]:border-l [&_[role=columnheader].ndl-data-grid-pinned-cell-right]:border-l-palette-neutral-border-weak ${classes['clean-actions-header']}`,
        }}
        styling={{ headerStyle: 'clean' }}
        isLoading={securityLogsLoading}
        isAutoResizingColumns={false}
        isKeyboardNavigable={false}
        tableInstance={tableProps}
        components={{
          BodyRow: DataGridHelpers.HoverBodyRow,
          HeaderCell: ColumnPreferencesHeaderCell,
          TableResults: () => (
            <TableResults
              pageIndex={controlledPagination.pageIndex}
              pageLength={controlledPagination.pageSize}
              rowsLength={pageInfo.itemCount}
            />
          ),
          ...(!hasRequestedData && { NoDataPlaceholder }),
        }}
      />
    </>
  );
};
