import { tokens } from '@neo4j-ndl/base';
import { Drawer, Label, Tabs, useMediaQuery } from '@neo4j-ndl/react';
import { useGetLocalInstanceQuery } from '@nx/state';
import type { LocalInstanceInfo } from '@nx/state';
import { useMemo, useState } from 'react';

import { dbStatusText, getOnlineStatusColor } from './entities/helpers';
import { Plugins } from './plugins/plugins';
import { PropertyDisplay } from './property-display';
import { Upgrade } from './upgrade/upgrade';

function OverviewPanel({ instance }: { instance: LocalInstanceInfo }) {
  return (
    <div className="my-3">
      <PropertyDisplay label="Connection URI" text={instance.connectionUri} allowCopyText />
      <PropertyDisplay label="Version" text={instance.version} />
      <PropertyDisplay label="Edition" text={instance.edition} />
      <PropertyDisplay label="ID" text={instance.id} allowCopyText />
      {instance.rootPath !== undefined && <PropertyDisplay label="Path" text={instance.rootPath} allowCopyText />}
    </div>
  );
}

interface InstanceDetailsProps {
  instanceId: string;
  onClose: () => void;
}
export const InstanceDetails = ({ instanceId, onClose }: InstanceDetailsProps) => {
  const [tabValue, setTabValue] = useState(0);
  const { breakpoints } = tokens;
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.sm})`);

  const { data: instance, isSuccess } = useGetLocalInstanceQuery(instanceId);

  const defaultWidth = useMemo(() => {
    if (isMobile) {
      return window.innerWidth;
    }
    return 640;
  }, [isMobile]);

  if (!isSuccess) {
    return null;
  }

  return (
    <Drawer
      isExpanded
      onExpandedChange={onClose}
      position="right"
      className="!border-l"
      isResizeable
      resizeableProps={{
        defaultSize: { height: '100%', width: defaultWidth },
        minWidth: defaultWidth,
      }}
    >
      <Drawer.Header className="flex items-center justify-between gap-2">
        <h5>{instance.name}</h5>
        <Label fill="clean" hasIcon color={getOnlineStatusColor(instance)} className="font-normal uppercase">
          {dbStatusText(instance)}
        </Label>
      </Drawer.Header>
      <Drawer.Body className="flex flex-col">
        <Tabs value={tabValue} onChange={setTabValue}>
          <Tabs.Tab tabId={0}>Overview</Tabs.Tab>
          <Tabs.Tab tabId={1}>Plugins</Tabs.Tab>
          <Tabs.Tab tabId={2}>Upgrade</Tabs.Tab>
        </Tabs>
        <div className="flex flex-col gap-2">
          <Tabs.TabPanel value={tabValue} tabId={0}>
            <OverviewPanel instance={instance} />
          </Tabs.TabPanel>

          <Tabs.TabPanel value={tabValue} tabId={1}>
            <Plugins instanceVersion={instance.version} instanceId={instance.id} />
          </Tabs.TabPanel>

          <Tabs.TabPanel value={tabValue} tabId={2}>
            <Upgrade instance={instance} />
          </Tabs.TabPanel>
        </div>
      </Drawer.Body>
    </Drawer>
  );
};
