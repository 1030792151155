import { combineReducers } from 'redux';
import undoable from 'redux-undo';

import { getClientMigrationVersion } from '../services/versions/version';
import appReducer, { NAME as app } from './app/appDuck';
import cardsReducer, { NAME as cards } from './cards/cards';
import connectionsReducer, { NAME as connections } from './connections/connectionsDuck';
import contentExportReducer, { NAME as contentexport } from './contentexport/contentexport';
import contextMenuReducer, { NAME as contextmenu } from './contextmenu/contextmenu';
import drawerReducer, { NAME as drawer } from './drawer/drawer';
import filterReducer, { NAME as filter } from './filter/filter';
import focusedReducer, { NAME as focused } from './focused/focused';
import gdsReducer, { NAME as gds } from './gds/gds';
import graphReducer, { NAME as graph } from './graph/graph';
import { addToVisible, removeFromVisible } from './graph/graph.actions';
import { addToVisibleNodes, NAME as nodes } from './graph/nodes';
import { addToVisibleRelationships } from './graph/relationships';
import modalReducer, { NAME as modal } from './modal/modal';
import notificationsReducer, { NAME as notifications } from './notifications/notifications';
import perspectivesReducer, {
  SET_CURRENT_PERSPECTIVE,
  UNSET_CURRENT_PERSPECTIVE,
  NAME as perspectives,
} from './perspectives/perspectives';
import propertyPreviewReducer, { NAME as propertyPreview } from './propertypreview/propertyPreview';
import { CLEAR_SCENE } from './rootActions';
import sceneReducer, { NAME as scene } from './scene/scene';
import searchPrototypeReducer from './search-prototype/search-prototype';
import { NAME as searchPrototype } from './search-prototype/search-prototype.const';
import searchReducer, { NAME as search } from './search/searchDuck';
import settingsReducer, { NAME as settings } from './settings/settings';
import { sharedStorageApi } from './shared-storage/shared-storage.api';
import slicerReducer, { NAME as slicer } from './slicer/slicer';
import stylesReducer, { NAME as styles } from './styles/styles';
import userviewReducer, { NAME as userview } from './userview/userview';
import visualizationReducer, { NAME as visualization } from './visualization/visualization';

const undoPoints = [
  addToVisible.toString(),
  addToVisibleNodes.toString(),
  addToVisibleRelationships.toString(),
  removeFromVisible.toString(),
  CLEAR_SCENE,
];

let delayedStore = false;
export const reducers = {
  [connections]: connectionsReducer,
  [graph]: undoable(graphReducer, {
    limit: 10,
    filter: (action, currentState) => {
      const activeNodes =
        currentState && currentState[nodes] && currentState[nodes].activated ? currentState[nodes].activated : {};
      const activated = Object.keys(activeNodes).length > 0;

      let includeAction;
      if (undoPoints.includes(action.type)) {
        includeAction = !activated;
        delayedStore = activated;
      } else {
        includeAction = delayedStore && !activated;
      }

      if (includeAction) {
        delayedStore = false;
      }
      return includeAction;
    },
  }),
  [focused]: focusedReducer,
  [contextmenu]: contextMenuReducer,
  [modal]: modalReducer,
  [cards]: cardsReducer,
  [visualization]: visualizationReducer,
  [perspectives]: perspectivesReducer,
  [drawer]: drawerReducer,
  [filter]: filterReducer,
  [scene]: sceneReducer,
  [gds]: gdsReducer,
  [slicer]: slicerReducer,
  [contentexport]: contentExportReducer,
  [settings]: settingsReducer,
  [userview]: userviewReducer,
  [search]: searchReducer,
  [notifications]: notificationsReducer,
  [app]: appReducer,
  [propertyPreview]: propertyPreviewReducer,
  [styles]: stylesReducer,
  [searchPrototype]: searchPrototypeReducer,
  [sharedStorageApi.reducerPath]: sharedStorageApi.reducer,
  version: getClientMigrationVersion,
  currentPerspectiveId: (state, { perspectiveId, type }) => {
    switch (type) {
      case SET_CURRENT_PERSPECTIVE:
        return perspectiveId;
      case UNSET_CURRENT_PERSPECTIVE:
        return null;
      default: {
        return !state ? null : state;
      }
    }
  },
};

export const clearState = () => {
  return {
    type: CLEAR_SCENE,
  };
};

export const setCurrentPerspective = (perspectiveId) => ({
  type: SET_CURRENT_PERSPECTIVE,
  perspectiveId,
});

export const unsetCurrentPerspective = () => ({
  type: UNSET_CURRENT_PERSPECTIVE,
});

export default combineReducers(reducers);
