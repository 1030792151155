export enum IMPORT_ACTIONS_EVENTS {
  REMOVE_TABLE = 'REMOVE_TABLE',
  CONNECT_NODES = 'CONNECT_NODES',
  CREATE_NODES_AND_RELS = 'CREATE_NODES_AND_RELS',
  UPDATE_NODE_SCHEMA_ID = 'UPDATE_NODE_SCHEMA_ID',
  UPDATE_REL_MAPPING_SOURCE = 'UPDATE_REL_MAPPING_SOURCE',
  UPDATE_REL_MAPPING_TARGET = 'UPDATE_REL_MAPPING_TARGET',
  REVERSE_RELATIONSHIP = 'REVERSE_RELATIONSHIP',
}

export enum IMPORT_EVENTS {
  RUN_IMPORT = 'runImport',
  RUN_CLOUD_IMPORT = 'RUN_CLOUD_IMPORT',
  IMPORT_COMPLETE = 'importComplete',
  IMPORT_ERROR = 'importError',
  RUN_PREVIEW = 'runPreview',
  PREVIEW_COMPLETE = 'previewComplete',
  PREVIEW_ERROR = 'previewError',
  IMPORT_MODEL = 'importModel',
  IMPORT_ZIP = 'importZip',
  EXPORT_MODEL = 'exportModel',
  EXPORT_ZIP = 'exportZip',
  ADD_FILES = 'addFiles',
  OPEN_SETTINGS = 'openSettings',
  CLOSE_SETTINGS_WITH_CHANGES = 'closeSettingsWithChanges',
  GENERATE_CYPHER_SCRIPT = 'generateCypherScript',
  DOWNLOAD_GENERATED_CYPHER = 'downloadGeneratedCypher',
  COPY_GENERATED_CYPHER_SCRIPT_TO_CLIPBOARD = 'copyGeneratedCypherScriptToClipboard',
  EXPAND_DATA_SOURCE_PANEL = 'expandDataSourcePanel',
  COLLAPSE_DATA_SOURCE_PANEL = 'collapseDataSourcePanel',
  MAPPING_FILTER_ENABLE = 'mappingFilterEnable',
  MAPPING_FILTER_DISABLE = 'mappingFilterDisable',
  RESIZE_WINDOW_ENDS = 'resizeWindowEnds',
  ADD_NODE_PROPERTY = 'ADD_NODE_PROPERTY',
  REMOVE_NODE_PROPERTY = 'REMOVE_NODE_PROPERTY',
  ADD_REL_PROPERTY = 'ADD_REL_PROPERTY',
  REMOVE_REL_PROPERTY = 'REMOVE_REL_PROPERTY',
  ADD_NODE_MAPPED_PROPERTIES = 'ADD_NODE_MAPPED_PROPERTIES',
  ADD_REL_MAPPED_PROPERTIES = 'ADD_REL_MAPPED_PROPERTIES',
  UPDATE_NODE_MAPPING_PROPERTY_FIELD = 'UPDATE_NODE_MAPPING_PROPERTY_FIELD',
  UPDATE_REL_MAPPING_PROPERTY_FIELD = 'UPDATE_REL_MAPPING_PROPERTY_FIELD',
  ADD_INDEX = 'ADD_INDEX',
  UPDATE_INDEX = 'UPDATE_INDEX',
  REMOVE_INDEXES = 'REMOVE_INDEXES',
  ADD_DATA_SOURCE_VERIFY_CONNECTION = 'ADD_DATA_SOURCE_VERIFY_CONNECTION',
  ADD_DATA_SOURCE = 'ADD_DATA_SOURCE',
  CREATE_MODEL_FROM_SOURCE = 'CREATE_MODEL_FROM_SOURCE',
  LINK_MODEL_TO_SOURCE = 'LINK_MODEL_TO_SOURCE',
  GENERATE_CANDIDATE_GRAPH = 'GENERATE_CANDIDATE_GRAPH',
  // Keep name of old event to not break tracking trace
  DELETE_NODES_AND_RELATIONSHIPS = 'DELETE_NODES_AND_RELS',
}
