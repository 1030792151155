import { isNotNullish } from '@nx/stdlib';

// eslint-disable-next-line import/no-cycle
import consoleApi from '../console.api';
import type {
  AddTrialCustomerInfoRequest,
  ExtendTrialRequest,
  ListOrganizationProjectsRequest,
  OrganizationMemberResponse,
  OrganizationParam,
  OrganizationResponse,
  OrganizationSeamlessProfileResponse,
  OrganizationSsoIdResponse,
  ProfilingInfoResponse,
  ProjectSummaryResponse,
  UpdateOnboardingProfileRequest,
  UpdateOrganizationMemberRolesRequest,
  UpdateOrganizationRequest,
  UpdateOrganizationSeamlessProfileRequest,
} from '../console.api.types';
import type {
  Organization,
  OrganizationMember,
  OrganizationSeamlessProfile,
  OrganizationSsoId,
  ProfilingInfo,
  ProjectSummary,
  TrialCustomerInfo,
} from '../console.types';
import {
  transformAddTrialCustomerInfoRequest,
  transformOrganizationMemberResponse,
  transformOrganizationResponse,
  transformOrganizationSeamlessProfileResponse,
  transformOrganizationSsoIdResponse,
  transformUpdateOnboardingProfileRequest,
  transformUpdateOnboardingProfileResponse,
  transformUpdateOrganizationMemberRolesRequest,
  transformUpdateOrganizationRequest,
  transformUpdateOrganizationSeamlessProfileRequest,
} from '../transformers/organizations';
import { transformProjectSummaryResponse } from '../transformers/projects';
import { type Builder, LIST_ID } from './types';

export const organizationEndpoints = (builder: Builder) => ({
  listOrganizationsByUser: builder.query<Organization[], string>({
    query: (userId) => `users/${userId}/organizations`,
    transformResponse: (organizations: OrganizationResponse[]) => {
      return organizations.map((org) => transformOrganizationResponse(org));
    },
    providesTags: (result) => {
      if (isNotNullish(result)) {
        return [
          ...result.map(({ id }) => ({ type: 'Organization' as const, id })),
          { type: 'Organization', id: LIST_ID },
        ];
      }
      return [{ type: 'Organization', id: LIST_ID }];
    },
  }),
  listOrganizations: builder.query<Organization[], void>({
    query: () => 'organizations',
    transformResponse: (organizations: OrganizationResponse[]) => {
      return organizations.map((organization) => transformOrganizationResponse(organization));
    },
  }),
  getOrganization: builder.query<Organization, string>({
    query: (orgId) => `organizations/${orgId}`,
    transformResponse: (organization: OrganizationResponse) => transformOrganizationResponse(organization),
    providesTags: (result, error, orgId) => [{ type: 'Organization', id: orgId }],
  }),
  listOrganizationProjects: builder.query<ProjectSummary[], ListOrganizationProjectsRequest>({
    query: ({ organizationId, ssoConfigId }) => ({
      url: `organizations/${organizationId}/tenants`,
      params: { sso_config_id: ssoConfigId },
    }),
    transformResponse: (projectSummaries: ProjectSummaryResponse[]) => {
      return projectSummaries.map((projectSummary) => transformProjectSummaryResponse(projectSummary));
    },
    providesTags: (result, err, { organizationId, ssoConfigId }) => {
      return [{ type: 'OrganizationProject', organizationId, ssoConfigId }];
    },
  }),
  listOrganizationMembers: builder.query<OrganizationMember[], string>({
    query: (orgId) => `organizations/${orgId}/members`,
    transformResponse: (orgMembers: OrganizationMemberResponse[]) => {
      return orgMembers.map((member) => transformOrganizationMemberResponse(member));
    },
    providesTags: (result, err, orgId) => {
      if (isNotNullish(result)) {
        return [
          ...result.map(({ id, organizationId }) => ({ type: 'OrganizationMember' as const, id, organizationId })),
          { type: 'OrganizationMember', organizationId: orgId, id: LIST_ID },
        ];
      }
      return [{ type: 'OrganizationMember', id: LIST_ID }];
    },
  }),
  updateUserOrganizationRoles: builder.mutation<void, UpdateOrganizationMemberRolesRequest>({
    query: (request) => ({
      url: `organizations/${request.organizationId}/members/${request.userId}`,
      method: 'PATCH',
      body: transformUpdateOrganizationMemberRolesRequest(request),
    }),
    invalidatesTags: (res, err, { organizationId, userId }) => [
      { type: 'OrganizationMember', organizationId, id: userId },
      'UserDetails',
    ],
  }),
  updateOrganization: builder.mutation<void, UpdateOrganizationRequest>({
    query: (request) => ({
      url: `organizations/${request.organizationId}`,
      method: 'PATCH',
      body: transformUpdateOrganizationRequest(request),
    }),
    onQueryStarted: (
      {
        organizationId,
        copilotEnabled,
        gdsSessionsEnabled,
        gdsSessionsMaxConcurrentSessions,
        gdsSessionsMaxMemoryPerSession,
        passwordLoginEnabled,
        googleLoginEnabled,
      },
      { dispatch, queryFulfilled },
    ) => {
      const patchOrg = dispatch(
        consoleApi.util.updateQueryData('getOrganization', organizationId, (draft) => {
          if (gdsSessionsEnabled !== undefined) {
            draft.gdsSessions.enabled = gdsSessionsEnabled;
          }
          if (gdsSessionsMaxMemoryPerSession !== undefined) {
            draft.gdsSessions.maxMemoryPerSession = gdsSessionsMaxMemoryPerSession;
          }
          if (gdsSessionsMaxConcurrentSessions !== undefined) {
            draft.gdsSessions.maxConcurrentSessions = gdsSessionsMaxConcurrentSessions;
          }
          if (passwordLoginEnabled !== undefined) {
            draft.passwordLoginEnabled = passwordLoginEnabled;
          }
          if (googleLoginEnabled !== undefined) {
            draft.googleLoginEnabled = googleLoginEnabled;
          }
        }),
      );
      queryFulfilled.catch(() => {
        patchOrg.undo();
      });
    },
    invalidatesTags: (res, err, { organizationId }) => [{ type: 'Organization', id: organizationId }],
  }),
  extendTrial: builder.mutation<Organization, ExtendTrialRequest>({
    query: ({ organizationId }) => ({ url: `organizations/${organizationId}/trial/extend`, method: 'POST' }),
    transformResponse: (organization: OrganizationResponse) => transformOrganizationResponse(organization),
    invalidatesTags: (res, err, { organizationId }) => [{ type: 'Organization', id: organizationId }],
  }),
  addTrialCustomerInfo: builder.mutation<TrialCustomerInfo, AddTrialCustomerInfoRequest>({
    query: (request) => ({
      url: `organizations/${request.organizationId}/trial/customer-info`,
      method: 'POST',
      body: transformAddTrialCustomerInfoRequest(request),
    }),
    invalidatesTags: (result, error, { organizationId }) => [{ type: 'Organization', id: organizationId }],
  }),
  checkTrialCustomerInfoCanBeSubmitted: builder.query<boolean, string>({
    query: (orgId) => ({
      url: `organizations/${orgId}/trial/customer-info`,
    }),
    transformResponse: () => false,
    providesTags: (result, error, orgId) => [{ type: 'Organization', id: orgId }],
  }),
  getOrganizationSsoId: builder.query<OrganizationSsoId, string>({
    query: (orgId) => ({
      url: `organizations/${orgId}/sso-id`,
      headers: {
        Authorization: 'SKIP',
      },
    }),
    transformResponse: (response: OrganizationSsoIdResponse) => transformOrganizationSsoIdResponse(response),
    providesTags: (result, error, orgId) => [{ type: 'Organization', id: orgId }],
  }),
  getOrganizationSeamlessProfile: builder.query<OrganizationSeamlessProfile, string>({
    query: (orgId) => ({
      url: `organizations/${orgId}/seamless`,
    }),
    transformResponse: (seamlessProfile: OrganizationSeamlessProfileResponse) =>
      transformOrganizationSeamlessProfileResponse(seamlessProfile),
    providesTags: (result, error, orgId) => [{ type: 'OrganizationSeamlessProfile', id: orgId }],
  }),
  updateOrganizationSeamlessProfile: builder.mutation<void, UpdateOrganizationSeamlessProfileRequest>({
    query: (request) => ({
      url: `organizations/${request.organizationId}/seamless`,
      method: 'POST',
      body: transformUpdateOrganizationSeamlessProfileRequest(request),
    }),
    invalidatesTags: (result, error, { organizationId }) => [
      { type: 'OrganizationSeamlessProfile', id: organizationId },
    ],
  }),
  updateProfilingInfo: builder.mutation<ProfilingInfo, UpdateOnboardingProfileRequest>({
    query: (request) => ({
      url: `organizations/${request.organizationId}/profiling`,
      method: 'PATCH',
      body: transformUpdateOnboardingProfileRequest(request),
    }),
    // invalidatesTags: (result, error, { organizationId }) => [{ type: 'Organization', id: organizationId }],
  }),
  getProfilingInfo: builder.query<ProfilingInfo, OrganizationParam>({
    query: (request) => `organizations/${request.organizationId}/profiling`,
    transformResponse: (profileInfo: ProfilingInfoResponse) => transformUpdateOnboardingProfileResponse(profileInfo),
  }),
});
