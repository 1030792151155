import { nanoid } from '@reduxjs/toolkit';

import type { Invoice } from './entities/invoice';
import type { TransformedBreakdownItem, TransformedUsage } from './entities/usage';

const MOCK_ITEM_COUNT = 22;

export const MOCK_INVOICES = Array.from({ length: MOCK_ITEM_COUNT }, (_, i): Invoice => {
  const max = 2000;
  const amountDue = Math.floor(Math.random() * max);
  const randomId = nanoid().replace(/-/g, '');
  const addDays = (days: number, date: Date = new Date()) =>
    new Date(new Date(date).setDate(date.getDate() + days)).toISOString();
  return {
    number: randomId.slice(0, 10),
    customer_id: randomId.slice(-10),
    created: addDays(-i * 30),
    period_start: addDays((-i - 1) * 30),
    period_end: addDays(-i * 30),
    status: amountDue > max * 0.7 ? 'pending' : 'paid',
    amount_due: String(amountDue),
    due_date: addDays((-i + 1) * 30),
    currency: 'usd',
  };
});

const breakdown = Array.from({ length: MOCK_ITEM_COUNT }, (_, i): TransformedBreakdownItem => {
  const maxConsumptionUnits = 200;
  const consumptionUnits = Math.floor(Math.random() * maxConsumptionUnits);
  const unitCostInDollars = consumptionUnits < maxConsumptionUnits * 0.7 ? '0.09' : '0.125';
  const randomId = nanoid().replace(/-/g, '');
  return {
    dbid: randomId.slice(0, 10),
    name: `Instance${i}`,
    consumptionUnits,
    unitType: 'GB-hour',
    unitCostInDollars,
    cost: String(Number.parseFloat(unitCostInDollars) * consumptionUnits),
    costType: 'dollars',
    billingCategory: consumptionUnits > maxConsumptionUnits * 0.7 ? 'running' : 'paused',
    endTime: '2023-05-23T11:07:34Z',
    projectId: randomId.slice(-10),
    projectName: consumptionUnits < maxConsumptionUnits * 0.5 ? 'My First Project' : 'Acme Prod',
  };
});
const totalCost = breakdown
  .reduce((sum, item) => sum + item.consumptionUnits * Number.parseFloat(item.unitCostInDollars), 0)
  .toString();
const totalCostType = 'dollars';

export const MOCK_USAGE: TransformedUsage = {
  breakdown,
  discount: undefined,
  totalCost,
  totalCostType,
};
