import { DataGrid, DataGridComponents, IconButton, Typography } from '@neo4j-ndl/react';
import { ChevronDownIconSolid, FunnelIconOutline } from '@neo4j-ndl/react/icons';
import { DataGridHelpers, SearchField } from '@nx/ui';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo } from 'react';

import { useCommonExpandedTable } from '../../components/table';
import { type ProjectRole, ROLE_TYPE, type User } from '../entities/model';
import { MOCK_USERS } from '../mock-data';
import { ExpandableBodyRow, ExpandableCell, LastActive, RoleLabel } from './common';
import { mapUserToCommonUserRow } from './helpers';
import type { CommonUserRow } from './types';

type UserRow = CommonUserRow & {
  projectRole: ProjectRole;
};

type ProjectRow = {
  projectId: string;
  projectName: string;
  users: UserRow[];
};

interface TableRow {
  project: ProjectRow | undefined;
  user: UserRow | undefined;
}

const groupUsersByProject = (users: User[]): TableRow[] => {
  const groupedUsers: Record<string, ProjectRow> = {};
  users.forEach((user) =>
    user.projectRoles.forEach((projectRole) => {
      groupedUsers[projectRole.projectId] ??= {
        projectId: projectRole.projectId,
        projectName: projectRole.projectName,
        users: [],
      };
      groupedUsers[projectRole.projectId]?.users.push({
        ...mapUserToCommonUserRow(user),
        projectRole,
      });
    }),
  );
  return Object.values(groupedUsers).map((projectRow) => ({ project: projectRow, user: undefined }));
};

const columnHelper = createColumnHelper<TableRow>();
const columns = [
  columnHelper.accessor('project.projectName', {
    header: 'Project / User',
    cell: (cx) => {
      return cx.row.getCanExpand() ? (
        <ExpandableCell cx={cx}>
          <Typography variant="subheading-medium">{cx.getValue()}</Typography>
        </ExpandableCell>
      ) : (
        cx.row.original.user?.email
      );
    },
  }),
  columnHelper.accessor('user.projectRole.name', {
    header: 'Project role',
    cell: (cx) => {
      return (
        !cx.row.getCanExpand() && (
          <div className="flex w-full justify-between gap-2">
            <RoleLabel roleType={ROLE_TYPE.PROJECT}>{cx.getValue()}</RoleLabel>
            <ChevronDownIconSolid className="size-6 min-w-6" />
          </div>
        )
      );
    },
  }),
  columnHelper.accessor('user.metadata', {
    header: 'Metadata',
  }),
  columnHelper.accessor('user.lastActive', {
    header: 'Last active',
    cell: (cx) => !cx.row.getCanExpand() && <LastActive user={cx.row.original.user} />,
  }),
  columnHelper.display({
    id: 'actions',
    cell: (cx) => !cx.row.getCanExpand() && <DataGridComponents.RowActionCell cell={cx} />,
    meta: {
      isActionCell: {
        actions: [
          {
            title: 'User action',
            onClick: () => {
              // eslint-disable-next-line no-alert
              alert('Perform user action...');
            },
          },
        ],
      },
    },
  }),
];
export function OrgUsersByProject() {
  const data = useMemo(() => groupUsersByProject(MOCK_USERS), []);
  const table = useCommonExpandedTable({
    columns,
    data,
    getRowCanExpand: (row) => Boolean(row.original.project),
    getSubRows: (row) => row.project?.users.map((user) => ({ project: undefined, user })),
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => table.toggleAllRowsExpanded(true), []);

  return (
    <DataGridHelpers.Wrapper tabbed>
      <DataGridHelpers.OuterHeader>
        <div className="flex w-full flex-wrap justify-between gap-2">
          <div className="flex basis-[400px] gap-2">
            <SearchField className="min-w-36 grow" />
            <IconButton ariaLabel="Filter users">
              <FunnelIconOutline />
            </IconButton>
          </div>
        </div>
      </DataGridHelpers.OuterHeader>
      <DataGrid
        tableInstance={table}
        components={{ BodyRow: ExpandableBodyRow }}
        styling={{
          headerStyle: 'clean',
          borderStyle: 'all-sides',
        }}
        isKeyboardNavigable={false}
      />
    </DataGridHelpers.Wrapper>
  );
}
