import { toast } from '@neo4j-ndl/react';
import { type Connection, addStateEventListener, selectActiveConnection, useActiveOrg } from '@nx/state';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface ConnectionToastProps {
  name: string;
  navigateToSettings?: () => void;
}
export const connectionToast = ({ name, navigateToSettings: navigate }: ConnectionToastProps) => {
  toast.success(`Connected to ${name}!`, {
    isCloseable: true,
    shouldAutoClose: true,
    shouldCloseOnAction: true,
    actionLabel: navigate !== undefined ? 'Manage auto connection' : undefined,
    onAction: navigate,
    sonnerToastProps: {
      className: '[&>.ndl-toast]:w-max',
    },
  });
};

export const useConnectionNotifications = () => {
  const navigate = useNavigate();
  const activeOrg = useActiveOrg(false);

  const onConnect = useCallback(
    (connection: Connection) => {
      const hasSeamlessCapability = activeOrg !== undefined && activeOrg.capabilities.seamless_connection;

      connectionToast({
        name: connection.name,
        navigateToSettings: hasSeamlessCapability
          ? () => navigate(`/org/${activeOrg.id}/settings`, { replace: true })
          : undefined,
      });
    },
    [activeOrg, navigate],
  );

  useEffect(() => {
    const removeEventListener = addStateEventListener('connectDriver', (listenerApi) => {
      const connection = selectActiveConnection(listenerApi.getState());

      if (connection !== null) {
        onConnect(connection);
      }
    });

    return removeEventListener;
  }, [onConnect]);
};
