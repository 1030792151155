import { useConfigurationActions } from '@nx/state';
import { useEffect } from 'react';

export const useFirstLoad = () => {
  const { setIsFirstLoad } = useConfigurationActions();

  useEffect(() => {
    setIsFirstLoad();
  }, [setIsFirstLoad]);
};
