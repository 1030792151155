import { useMediaQuery } from '@neo4j-ndl/react';
import { useCapability, useSetting } from '@nx/state';
import { useEffect } from 'react';

export type Theme = 'system' | 'dark' | 'light';

export const isDarkTheme = (value: Theme, defaultValue: boolean) => {
  switch (value) {
    case 'dark':
      return true;
    case 'light':
      return false;
    case 'system':
      return defaultValue;
    default:
      return false;
  }
};

export function useDarkMode(): boolean {
  const [isThemeSwitcherOn] = useCapability('framework:theme-switcher');
  const preferredTheme = useMediaQuery('(prefers-color-scheme: dark)');
  const [themeSetting] = useSetting('global', 'theme');

  useEffect(() => {
    if (isThemeSwitcherOn) {
      if (isDarkTheme(themeSetting, preferredTheme)) {
        document.documentElement.classList.add('ndl-theme-dark');
      } else {
        document.documentElement.classList.remove('ndl-theme-dark');
      }
    } else {
      document.documentElement.classList.remove('ndl-theme-dark');
    }
  }, [isThemeSwitcherOn, preferredTheme, themeSetting]);

  return isThemeSwitcherOn && isDarkTheme(themeSetting, preferredTheme);
}
