import { Banner, LoadingSpinner, Select, Typography } from '@neo4j-ndl/react';
import { useCurrentGuide, useGuidesActions } from '@nx/state';
import { useManifest, usePlaylist } from '@nx/ui';

import { GuideContent } from './guide-content';
import { Resources } from './resources';

type Option = {
  label: string;
  value: string;
};

const getOptions = (guides: { title: string; id: string }[]): Option[] =>
  guides.map((guide) => ({
    label: guide.title,
    value: guide.id,
  }));

const DROPDOWN: Record<string, { title: string; label: string }> = {
  Drivers: { title: 'Select driver', label: 'Driver' },
};

const GuideSelector = () => {
  const manifest = useManifest();
  const { data: playlist } = usePlaylist();
  const { selectGuide } = useGuidesActions();
  const currentGuide = useCurrentGuide();

  if (playlist === undefined) {
    return null;
  }

  const driverOptions = getOptions(playlist.steps);
  const defaultOption = driverOptions.find(({ value }) => value === currentGuide.guide?.id) ?? driverOptions[0];

  const handleGuideChange = ({ value: guideId, label: title }: Option) => {
    const manifestEntry = manifest.data?.guides[guideId];

    if (manifestEntry !== undefined) {
      void selectGuide({
        id: guideId,
        title,
        url: manifestEntry.url,
      });
    }
  };

  return (
    <>
      <Typography variant="h6" className="pb-4">
        1. {DROPDOWN[playlist.title]?.title ?? 'Select guide'}
      </Typography>
      <div className="text-neutral-text-weak mb-5 flex gap-4">
        <Select
          key={playlist.title}
          className="w-72"
          size="medium"
          isFluid={false}
          selectProps={{
            defaultValue: defaultOption,
            options: driverOptions,
            onChange: (option) => {
              if (option) {
                void handleGuideChange(option);
              }
            },
          }}
          type="select"
          label={DROPDOWN[playlist.title]?.label ?? 'guide'}
        />
      </div>
    </>
  );
};

interface Props {
  uri: string;
}

export const Playlist = ({ uri }: Props) => {
  const manifest = useManifest();
  const { isFetching, isError, data: playlist } = usePlaylist();

  const { selectGuide } = useGuidesActions();
  const currentGuide = useCurrentGuide();

  if (isFetching) {
    return <LoadingSpinner />;
  }

  if (isError || playlist === undefined || playlist.steps.length === 0) {
    return <Banner type="danger" title="Could not load guide" description={<p>Try again later.</p>} usage="inline" />;
  }

  // if current guide is not in the playlist, select the first guide from the playlist
  if (
    currentGuide.fetchStatus !== 'loading' &&
    !playlist.steps.some((step) => step.id === currentGuide.guide?.id) &&
    playlist.steps[0] !== undefined
  ) {
    const [{ id, title }] = playlist.steps;

    const manifestEntry = manifest.data?.guides[id];

    if (manifestEntry !== undefined && currentGuide.guide?.id !== id) {
      void selectGuide({
        id,
        title,
        url: manifestEntry.url,
      });
    }
  }

  const hasMultipleGuides = playlist.steps.length > 1;

  return (
    // px-[2px] is a workaround to avoid <Select/> outline and border clipped by the overflow-y-auto.
    // It is impossible to have overflow-x-visible and overflow-y-auto at the same time.
    <div className="h-full overflow-y-auto px-[2px] pt-8">
      {hasMultipleGuides && <GuideSelector />}
      {/* if playlist has multiple guides first header would be guide dropdown, 
      otherwise it would be the first guide header */}
      <GuideContent uri={uri} startHeadersIndex={hasMultipleGuides ? 2 : 1} />
      <Resources />
    </div>
  );
};
