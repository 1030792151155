import {
  DateTimeFormatter,
  Instant,
  LocalDate as JodaLocalDate,
  LocalTime as JodaLocalTime,
  ZonedDateTime as JodaZonedDateTime,
  ZoneId,
} from '@js-joda/core';
import { isEmpty, isNil } from 'lodash-es';
import type { Moment } from 'moment';

import { log } from '../logging';
import { BaseTemporal } from './BaseTemporal';
import { LocalTime } from './LocalTime';
import type { TimeProperties, TimeZoneProperties } from './types';
import { MILLISECOND_PER_SECOND, NANO_PER_DAY, NANO_PER_MILLISECOND, TIME_REGEX, ZONE_Z } from './utils.const';

export class Time extends BaseTemporal {
  neo4jLocalTime: TimeProperties & TimeZoneProperties;

  jodaZonedDateTime: JodaZonedDateTime;

  localTime: LocalTime;

  constructor(obj: TimeProperties & TimeZoneProperties) {
    super();

    this.neo4jLocalTime = obj;
    const { hour, minute, second, nanosecond, timeZoneOffsetSeconds, zoneStr } = obj;

    // no jodaZonedTime, so we will use jodaZonedDateTime but leave date defaulted
    const finalZoneStr = zoneStr ?? BaseTemporal.convertSecondsToZoneStr(timeZoneOffsetSeconds ?? NaN);
    this.jodaZonedDateTime = JodaZonedDateTime.of(
      JodaLocalDate.parse('1970-01-01'),
      JodaLocalTime.of(hour, minute, second, nanosecond),
      ZoneId.of(finalZoneStr),
    );

    this.localTime = new LocalTime({
      hour,
      minute,
      second,
      nanosecond,
    });
  }

  static formatNumber(nanoOfDay: number) {
    // @ts-expect-error: Outdated
    return JodaZonedDateTime.ofNanoOfDay(nanoOfDay).toString();
  }

  static parseStringToObj(stringVal: string) {
    const pseudoDateString = '1970-01-01T';
    const finalStringVal = this.addZeroToHourIfNeeded(stringVal);
    const jodaZonedDateTime = JodaZonedDateTime.parse(pseudoDateString + finalStringVal);
    return new Time({
      hour: jodaZonedDateTime.hour(),
      minute: jodaZonedDateTime.minute(),
      second: jodaZonedDateTime.second(),
      nanosecond: jodaZonedDateTime.nano(),
      zoneStr: jodaZonedDateTime.zone().toString(),
    });
  }

  static parseMomentObjToObj(momentObj: Moment, addedNanoseconds = 0, zoneStr = 'Z') {
    return new Time({
      hour: momentObj.hour(),
      minute: momentObj.minute(),
      second: momentObj.second(),
      nanosecond: momentObj.millisecond() * NANO_PER_MILLISECOND + addedNanoseconds,
      zoneStr,
    });
  }

  static parseStringToNumber(time: string, timezoneOverride?: string, isTimeZoneConvertEnabled = false) {
    if (isEmpty(time)) {
      return NaN;
    }
    let hour, minute, second, nanosecond, zoneStr;
    try {
      const timeMatch = TIME_REGEX.exec(time);

      if (!isNil(timeMatch)) {
        hour = timeMatch[1];
        minute = timeMatch[2];
        second = timeMatch[3];
        nanosecond = this.convertNanoStringToNum(timeMatch[4]);
        zoneStr = timeMatch[5] !== undefined && timeMatch[6] !== undefined ? timeMatch[5] + timeMatch[6] : undefined;
      }
    } catch (e) {
      log.debug('Invalid Date Time value');
    }

    const hourInt = parseInt(hour ?? '');
    const minuteInt = parseInt(minute ?? '');
    const secondInt = parseInt(second ?? '');
    const nanosecondInt = parseInt(nanosecond ?? '');

    const timeObj = new Time({
      hour: hourInt,
      minute: minuteInt,
      second: secondInt,
      nanosecond: nanosecondInt,
      zoneStr,
    });

    if (isTimeZoneConvertEnabled) {
      // get the equivalent time in the overridden time zone
      // effectively speak, the time remains the same
      const zone = ZoneId.of(timezoneOverride ?? 'Z');
      const convertedZonedDateTime = timeObj.jodaZonedDateTime
        .withZoneSameInstant(zone)
        .withYear(1970) // reset to 1970-01-01, since we only care time
        .withMonth(1)
        .withDayOfMonth(1);

      const ms = Instant.from(convertedZonedDateTime).toEpochMilli(); // epoch time is 00:00:00Z
      // @ts-expect-error: Outdated
      const timezoneOffsetMs = zone.totalSeconds() * MILLISECOND_PER_SECOND;

      // ms since 00:00:00[zone]
      return ms + timezoneOffsetMs;
    }
    // get the time in Zulu timezone, by just replacing the timezone
    // effectively speak, the time changes
    const localZonedDateTime = timeObj.jodaZonedDateTime.withZoneSameLocal(ZONE_Z);
    return Instant.from(localZonedDateTime).toEpochMilli();
  }

  static parseNumberToString(millisecondOfDay: number, timezoneOverride?: string) {
    const zone = timezoneOverride != null ? ZoneId.of(timezoneOverride) : ZONE_Z;

    // make sure the max does not exceed 23:59:59.999999999
    const nanoOfDay = Math.min(millisecondOfDay * NANO_PER_MILLISECOND, NANO_PER_DAY - 1);

    const jodaZonedDateTime = JodaZonedDateTime.of(
      JodaLocalDate.parse('1970-01-01'),
      JodaLocalTime.ofNanoOfDay(nanoOfDay),
      zone,
    );

    return jodaZonedDateTime.format(DateTimeFormatter.ISO_OFFSET_TIME).toString();
  }

  getHour() {
    return this.neo4jLocalTime.hour;
  }

  getMinute() {
    return this.neo4jLocalTime.minute;
  }

  getSecond() {
    return this.neo4jLocalTime.second;
  }

  getNanosecond() {
    return this.neo4jLocalTime.nanosecond;
  }

  getTimeZoneOffsetSeconds() {
    // @ts-expect-error: Outdated
    return this.jodaZonedDateTime.zone().totalSeconds?.();
  }

  hasMs() {
    return this.jodaZonedDateTime.nano() > 0;
  }

  getTimezone() {
    return this.jodaZonedDateTime.zone();
  }

  setTimezone(zoneStr: string) {
    return new Time({
      hour: this.jodaZonedDateTime.hour(),
      minute: this.jodaZonedDateTime.minute(),
      second: this.jodaZonedDateTime.second(),
      nanosecond: this.jodaZonedDateTime.nano(),
      zoneStr,
    });
  }

  setTimezoneShiftTo(zoneStr: string) {
    const newJodaZonedDateTime = this.jodaZonedDateTime.withZoneSameInstant(ZoneId.of(zoneStr));

    return new Time({
      hour: newJodaZonedDateTime.hour(),
      minute: newJodaZonedDateTime.minute(),
      second: newJodaZonedDateTime.second(),
      nanosecond: newJodaZonedDateTime.nano(),
      zoneStr,
    });
  }

  toString() {
    return this.jodaZonedDateTime.format(DateTimeFormatter.ISO_OFFSET_TIME).toString();
  }

  // the toString() result of neo4j.types.LocalTime
  toNeo4jString() {
    return this.toString();
  }

  toPropertyObject() {
    return {
      hour: this.jodaZonedDateTime.hour(),
      minute: this.jodaZonedDateTime.minute(),
      second: this.jodaZonedDateTime.second(),
      nanosecond: this.jodaZonedDateTime.nano(),
      // @ts-expect-error: Outdated
      timeZoneOffsetSeconds: this.jodaZonedDateTime.zone().totalSeconds(),
    };
  }

  toMomentObj() {
    return this.localTime.toMomentObj();
  }
}
