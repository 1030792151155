import type { JSONSchemaType } from 'ajv';

import type { BaseArguments } from '../types/sdk-types';
import type { Procedure } from './procedure/procedures';

export type BloomInfoArgs = {
  procedures: Procedure[];
};

export type BloomInformation = {
  pluginVersion: string | null;
};

export async function getBloomInfo({
  queryCypher,
  procedures,
}: BaseArguments & BloomInfoArgs): Promise<BloomInformation> {
  let pluginVersion = null;
  const hasProcedure = procedures.find((procedure) => procedure.name === 'bloom.getPluginVersion') !== undefined;
  if (hasProcedure) {
    const pluginVersionQuery = 'CALL bloom.getPluginVersion()';
    const res = await queryCypher(pluginVersionQuery);

    // Type is verified in integration tests
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const version = res.records[0]?.get('version') as string;
    pluginVersion = version.length > 0 ? version : null;
  }

  return {
    pluginVersion: pluginVersion,
  };
}

export const bloomInfoSchema: JSONSchemaType<BloomInformation> = {
  type: 'object',
  properties: {
    pluginVersion: {
      oneOf: [{ type: 'string' }, { type: 'null', nullable: true }],
    },
  },
  required: ['pluginVersion'],
  additionalProperties: false,
};
