import { Button, TextLink, Typography, toast } from '@neo4j-ndl/react';
import { consoleApi } from '@nx/state';

import mail from '../../assets/mail.svg';

export const EmailVerifiedGuard = () => {
  const [resendEmail, resendEmailRes] = consoleApi.useEmailVerificationMutation();
  const handleResendEmail = () => {
    resendEmail()
      .unwrap()
      .then(() => {
        toast.success('Email resent!', { isCloseable: true });
      })
      .catch(() => {
        toast.danger('Something went wrong sending the verification email!', { isCloseable: true });
      });
  };

  return (
    <div className="flex h-full flex-row items-center justify-center gap-12">
      <img src={mail} alt="image of an email" draggable="false" />
      <div className="flex max-w-md flex-col gap-10">
        <div>
          <h3>Email verification required</h3>
          <Typography variant="body-large" as="div" className="text-neutral-text-weak mt-4">
            You should have received an email with a link to verify your email address. Check your spam folder if you
            have trouble finding it.
          </Typography>
        </div>
        <div className="flex flex-col gap-6">
          <div className="flex gap-4">
            <Button onClick={handleResendEmail} isLoading={resendEmailRes.isLoading}>
              Resend
            </Button>
            <Button color="neutral" fill="outlined" onClick={() => window.location.reload()}>
              Dashboard
            </Button>
          </div>
          <Typography variant="body-medium">
            <TextLink href="https://support.neo4j.com/s/" isExternalLink>
              Get help
            </TextLink>
          </Typography>
        </div>
      </div>
    </div>
  );
};
