import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { createAsyncThunk } from '../context';

/** main sidebar auto-closing timeout duration */
const TIMEOUT_DURATION = 300;

export interface SidebarState {
  mainSidebar: {
    isOpen: boolean;
    isTemporaryOpen: boolean;
    closeTimeoutId: number;
  };
  guidesIsOpen: boolean;
  settingsIsOpen: boolean;
}
export const SIDEBAR_PERSISTED_KEYS: (keyof SidebarState)[] = ['mainSidebar', 'guidesIsOpen', 'settingsIsOpen'];

const initialState: SidebarState = {
  mainSidebar: {
    isOpen: true,
    isTemporaryOpen: false,
    closeTimeoutId: -1,
  },
  guidesIsOpen: false,
  settingsIsOpen: false,
};

export const enterMainSidebar = createAsyncThunk('sidebars/enterMainSidebar', (payload: undefined, thunkApi) => {
  const { closeTimeoutId } = thunkApi.getState().sidebar.mainSidebar;
  clearTimeout(closeTimeoutId);
  return payload;
});

type ToggleMainSidebarAction = ReturnType<(typeof sideBarsSlice.actions)['toggleMainSidebar']>;

export const leaveMainSidebar = createAsyncThunk('sidebars/leaveMainSidebar', (payload: undefined, thunkApi) => {
  const { isOpen, isTemporaryOpen, closeTimeoutId } = thunkApi.getState().sidebar.mainSidebar;
  let timeoutId = closeTimeoutId;
  if (isOpen && isTemporaryOpen) {
    timeoutId = window.setTimeout(() => {
      thunkApi.dispatch({ type: 'sidebars/toggleMainSidebar', payload: false } satisfies ToggleMainSidebarAction);
    }, TIMEOUT_DURATION);
  }
  return timeoutId;
});

const sideBarsSlice = createSlice({
  name: 'sidebars',
  initialState,
  reducers: {
    toggleMainSidebar: (state, action: PayloadAction<boolean | undefined>) => {
      const { isOpen, isTemporaryOpen: openFromHover } = state.mainSidebar;
      state.mainSidebar.isTemporaryOpen = false;
      if (typeof action.payload === 'boolean') {
        state.mainSidebar.isOpen = action.payload;
      } else if (!isOpen || openFromHover) {
        state.mainSidebar.isOpen = true;
      } else {
        state.mainSidebar.isOpen = false;
      }
    },
    toggleGuidesSidebar: (state) => {
      state.guidesIsOpen = !state.guidesIsOpen;
    },
    toggleSettingsSidebar: (state) => {
      state.settingsIsOpen = !state.settingsIsOpen;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(enterMainSidebar.fulfilled, (state) => {
      state.mainSidebar.closeTimeoutId = -1;
      if (!state.mainSidebar.isOpen) {
        state.mainSidebar.isOpen = true;
        state.mainSidebar.isTemporaryOpen = true;
      }
    });
    builder.addCase(leaveMainSidebar.fulfilled, (state, { payload }) => {
      state.mainSidebar.closeTimeoutId = payload;
    });
  },
});

export const { toggleMainSidebar, toggleGuidesSidebar, toggleSettingsSidebar } = sideBarsSlice.actions;
export default sideBarsSlice.reducer;
