/**
 * Guides status can be stored in the Storage API or in the local state.
 * Hooks will fetch the data and dispatch actions to the appropriate source
 * based on the `framework:storage-api` capability.
 */
import { GUIDE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { useFlags } from '@nx/launch-darkly-service';
import { skipToken } from '@reduxjs/toolkit/query';
import { mapValues, pick } from 'lodash-es';
import { useMemo } from 'react';

import { trackEvent } from '../../actions';
import { useDispatch, useSelector, useStore } from '../../context';
import { useCapability, useUnsafeAppContext } from '../../hooks';
import * as Sidebar from '../sidebar-slice';
import * as Guides from './guides-slice';
import guidesApi, { type GuideStatus } from './guides.api';

function useIsStorageApiEnabled(): boolean {
  const [isStorageApiEnabled] = useCapability('framework:storage-api');
  const { upxGuidesProgressStorageApi: isFeatureFlagEnabled } = useFlags();
  const localGuides = useSelector((state) => state.guides.guidesStatuses);
  const hasGuidesBeenMigrated = Object.keys(localGuides).length === 0;

  return isStorageApiEnabled && isFeatureFlagEnabled && hasGuidesBeenMigrated;
}

function useGuidesStatusesApi(): Record<string, Pick<GuideStatus, 'id' | 'currentPage' | 'isCompleted'>> {
  const isStorageApiEnabled = useIsStorageApiEnabled();

  const { activeProjectId } = useUnsafeAppContext();
  const skip = !isStorageApiEnabled || activeProjectId === null;
  const { data } = guidesApi.useGetGuidesStatusesQuery(skip ? skipToken : undefined);

  const guidesStatusesObjectEntries = data?.map(
    (guideStatus) =>
      [
        guideStatus.name,
        { id: guideStatus.id, currentPage: guideStatus.currentPage, isCompleted: guideStatus.isCompleted },
      ] as const,
  );

  const guidesStatuses = Object.fromEntries(guidesStatusesObjectEntries ?? []);

  return guidesStatuses;
}

function useGuidesStatusesLocal(): Guides.GuidesStatuses {
  return useSelector((state) => state.guides.guidesStatuses);
}

function useGuidesStatuses(): Guides.GuidesStatuses {
  const isStorageApiEnabled = useIsStorageApiEnabled();

  const guidesStatusesApi = useGuidesStatusesApi();
  const guidesStatusesLocal = useGuidesStatusesLocal();

  const guidesStatusesApiData = mapValues(guidesStatusesApi, (status) => pick(status, 'currentPage', 'isCompleted'));
  return isStorageApiEnabled ? guidesStatusesApiData : guidesStatusesLocal;
}

export type GuideWithStatus = Guides.Guide & Guides.GuideStatus;

export function useGuides(): Record<string, GuideWithStatus> {
  const guides = useSelector((state) => state.guides.guides);
  const guidesStatuses = useGuidesStatuses();

  const initStatus = { currentPage: 0, isCompleted: false };
  const guidesWithStatuses = mapValues(guides, (guide, id) => {
    const status = guidesStatuses[id];
    return { ...guide, ...(status ?? initStatus) };
  });

  return guidesWithStatuses;
}

export type CurrentGuide = {
  guide: GuideWithStatus | undefined;
  fetchStatus: Guides.GuidesState['fetchStatus'];
  fetchError: Guides.GuidesState['fetchError'];
};

export function useCurrentGuide(): CurrentGuide {
  const currentGuideId = useSelector((state) => state.guides.currentGuideId);
  const fetchStatus = useSelector((state) => state.guides.fetchStatus);
  const fetchError = useSelector((state) => state.guides.fetchError);
  const guides = useGuides();

  const currentGuide = useMemo(() => {
    if (currentGuideId === undefined || guides[currentGuideId] === undefined) {
      return {
        guide: undefined,
        fetchStatus,
        fetchError,
      };
    }

    return {
      guide: guides[currentGuideId],
      fetchStatus,
      fetchError,
    };
  }, [currentGuideId, guides, fetchStatus, fetchError]);

  return currentGuide;
}

export type DeselectGuide = () => void;
export type SelectGuide = (args: Guides.SelectGuideParams) => Promise<void>;
export type OpenGuideParams = Guides.SelectGuideParams & { target: 'sidebar' };
export type OpenGuide = (args: OpenGuideParams) => Promise<void>;
export type SetGuideCurrentPage = (args: Guides.SetGuideCurrentPageParams) => void;

type GuidesAction = {
  selectGuide: SelectGuide;
  openGuide: OpenGuide;
  deselectGuide: DeselectGuide;
  setGuideCurrentPage: SetGuideCurrentPage;
};

function useGuidesActionsLocal(): GuidesAction {
  const dispatch = useDispatch();
  const store = useStore();
  const { guidesIsOpen } = useSelector((state) => state.sidebar);

  const deselectGuide = () => {
    dispatch(Guides.deselectGuide());
  };

  const setGuideCurrentPage = (args: Guides.SetGuideCurrentPageParams) => {
    dispatch(Guides.setGuideCurrentPage(args));
  };

  const selectGuide = async (args: Guides.SelectGuideParams) => {
    await dispatch(Guides.selectGuide(args));

    const guideStatus = store.getState().guides.guidesStatuses[args.id];
    if (guideStatus === undefined) {
      dispatch(Guides.createGuideStatus({ id: args.id, currentPage: 0, isCompleted: false }));
    } else {
      const guide = store.getState().guides.guides[args.id];
      if (guide && guideStatus.currentPage >= guide.totalPages) {
        setGuideCurrentPage({ id: args.id, pageNumber: guide.totalPages - 1 });
      }
    }
  };

  const openGuide = async (args: OpenGuideParams) => {
    const { target, ...selectArgs } = args;
    await selectGuide(selectArgs);

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!guidesIsOpen && target === 'sidebar') {
      dispatch(Sidebar.toggleGuidesSidebar());
    }
  };

  return {
    selectGuide,
    openGuide,
    deselectGuide,
    setGuideCurrentPage,
  };
}

function useGuidesActionsApi(): GuidesAction {
  const guidesStatuses = useGuidesStatusesApi();
  const guides = useGuides();
  const dispatch = useDispatch();
  const { guidesIsOpen } = useSelector((state) => state.sidebar);

  const [updateGuideStatus] = guidesApi.useUpdateGuideStatusMutation();
  const [createGuideStatus] = guidesApi.useCreateGuideStatusMutation();

  const deselectGuide = () => {
    return dispatch(Guides.deselectGuide());
  };

  const setGuideCurrentPage = ({ id, pageNumber }: Guides.SetGuideCurrentPageParams) => {
    const guide = guides[id];
    const guideStatus = guidesStatuses[id];

    if (guide && guideStatus) {
      // clamp the page number to the total number of pages
      const currentPage = Math.min(pageNumber, guide.totalPages - 1);

      // When the user navigates to the last page, we mark it as completed.
      const isCompleted = pageNumber === guide.totalPages - 1;

      void updateGuideStatus({
        id: guideStatus.id,
        guideStatus: { currentPage, ...(isCompleted ? { isCompleted } : undefined) },
      });

      if (currentPage > guideStatus.currentPage) {
        trackEvent({
          event: GUIDE_EVENTS.NEXT,
          properties: { id, currentPage },
          scope: APP_SCOPE.framework,
        });
      }

      if (isCompleted) {
        trackEvent({
          event: GUIDE_EVENTS.COMPLETE,
          properties: { id },
          scope: APP_SCOPE.framework,
        });
      }
    }
  };

  const selectGuide = async (args: Guides.SelectGuideParams) => {
    void dispatch(Guides.selectGuide(args));

    const guideStatus = guidesStatuses[args.id];
    if (guideStatus === undefined) {
      await createGuideStatus({ name: args.id, currentPage: 0, isCompleted: false, version: 1 });
    } else {
      const guide = guides[args.id];
      if (guide && guideStatus.currentPage >= guide.totalPages) {
        setGuideCurrentPage({ id: args.id, pageNumber: guide.totalPages - 1 });
      }
    }
  };

  const openGuide = async (args: Parameters<typeof Guides.selectGuide>[0] & { target: 'sidebar' }) => {
    const { target, ...selectArgs } = args;
    await selectGuide(selectArgs);

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!guidesIsOpen && target === 'sidebar') {
      dispatch(Sidebar.toggleGuidesSidebar());
    }
  };

  return {
    selectGuide,
    openGuide,
    deselectGuide,
    setGuideCurrentPage,
  };
}

export function useGuidesActions(): GuidesAction {
  const isStorageApiEnabled = useIsStorageApiEnabled();

  const actionsLocal = useGuidesActionsLocal();
  const actionsApi = useGuidesActionsApi();

  return isStorageApiEnabled ? actionsApi : actionsLocal;
}
