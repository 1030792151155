import type { BannerActionsProps } from '@neo4j-ndl/react';
import { Banner, TextLink, Typography } from '@neo4j-ndl/react';
import {
  type FormattedNeo4jError,
  formatNeo4jError,
  formatNeo4jErrorGqlStatusObject,
  isNeo4jError,
  isSerializedError,
} from '@nx/errors';
import { useFeatureFlag } from '@nx/state';
import type { SerializedError } from '@reduxjs/toolkit';
import type { Neo4jError } from 'neo4j-driver';

import ShowHide from './show-hide';

interface Props extends React.PropsWithChildren {
  actions?: BannerActionsProps[];
  id?: string;
  title: string;
}

const FormattedError = ({ error }: { error: FormattedNeo4jError }) => (
  <>
    <Typography variant="body-medium" className="mb-1 block font-bold">
      {error.title}
    </Typography>
    <Typography variant="body-medium" className="block">
      {error.description}
    </Typography>
    {error.originalError?.gqlStatus !== undefined && error.documentationUrl !== undefined && (
      <TextLink as="a" className="text-sm" href={error.documentationUrl} type="external">
        Read documentation for {error.originalError.gqlStatus}
      </TextLink>
    )}
  </>
);

const ErrorBanner = ({ actions, children, id, title }: Props) => {
  return (
    <Banner
      type="danger"
      title={title}
      actions={actions}
      description={<div id={id}>{children}</div>}
      hasIcon
      usage="inline"
    />
  );
};

const Neo4jErrorBanner = ({
  actions,
  children,
  error,
  id,
  title,
}: Props & { error?: Neo4jError | SerializedError }) => {
  const [showGqlErrorsAndNotifications] = useFeatureFlag('nx:enable-gql-errors-and-notifications');
  let formattedError;
  if (showGqlErrorsAndNotifications && isNeo4jError(error)) {
    formattedError = formatNeo4jErrorGqlStatusObject(error);
  } else if (isSerializedError(error)) {
    formattedError = formatNeo4jError(error);
  }

  return (
    <ErrorBanner title={title} actions={actions} id={id}>
      {children}
      {formattedError !== undefined && (
        <ShowHide>
          <FormattedError error={formattedError} />
        </ShowHide>
      )}
    </ErrorBanner>
  );
};

export { ErrorBanner, Neo4jErrorBanner };
