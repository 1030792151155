import type * as ImportShared from '@nx/import-shared';
import { neo4jVersionUtil } from '@nx/neo4j-version-utils';
import { isNullish } from '@nx/stdlib';

import { isDataModelJsonStruct } from '../data-model/data-model-json-formatter';
import { emptyDataModel, toNodeObjectTypeId, toRelationshipObjectTypeId } from '../data-model/data-model.json.helpers';
import { isLatestDataModelVersion, migrateDataModelToLatestVersion } from '../data-model/migrations';
import type { SerialisableArrowGraphState } from '../deprecated-types/arrows-types';
import { previewCsv } from '../file-loader/file-loader';
import type { EnhancedJSZipObject } from '../file-loader/types';
import { getFileForReading, getZipFiles } from '../file-loader/utils';
import { getZipDataFiles } from '../helpers/import-export';
import type { ImportGraphDataModel, SerialisableApplicationState } from '../types';
import { truncateSampleValue } from '../utils/text';
import { hydrateVisualisationState } from '../visualisation/utils';

/**
 * If migrated from earlier versions, then extracts field types from data when loading zip and set in model.
 * If model has been migrated from 0.6.2 or earlier the types need to be extracted from the csv files.
 *  Before 0.6.2 they were always assumed to be strings.
 * @param currentDataModel the latest data model after migration to current version
 * @param oldVersion the version of the model before migration
 * @param zipFile
 */
export const extractFieldTypeFromDataAndSetInModel = async (
  currentDataModel: ImportShared.DataModelJsonStruct,
  oldVersion: string | undefined,
  zipFile: File,
): Promise<ImportShared.DataModelJsonStruct> => {
  if (isNullish(oldVersion) || neo4jVersionUtil.lt(oldVersion, '0.6.2')) {
    const { dataFiles } = await getZipDataFiles(zipFile);

    let zipFiles: Record<string, EnhancedJSZipObject> = {};
    zipFiles = await getZipFiles(zipFile);
    const fileFields: Record<string, ImportShared.TableSchemaFieldJsonStruct[]> = {};
    const previewCsvPromises: (() => Promise<void>)[] = [];
    for (const fileName of Object.keys(dataFiles)) {
      previewCsvPromises.push(async () => {
        const { resultFields: fields } = await previewCsv(getFileForReading(fileName, {}, zipFiles, dataFiles, 10000));
        fileFields[fileName] = fields.map((f) => ({
          name: f.name,
          sample: truncateSampleValue(f.sample[0]),
          recommendedType: { type: f.type },
        }));
      });
    }
    await Promise.allSettled(previewCsvPromises.map((f) => f()));

    // Update file model files type.
    const newFileSchemas: ImportShared.TableSchemaJsonStruct[] = [];
    for (const fileName of Object.keys(fileFields)) {
      const foundFileFields: ImportShared.TableSchemaFieldJsonStruct[] = fileFields[fileName] ?? [];
      const currentFileSchemas = currentDataModel.graphMappingRepresentation.dataSourceSchema.tableSchemas;
      if (currentFileSchemas.filter((t) => t.name === fileName).length === 1 && foundFileFields.length > 0) {
        const [currentFileSchema] = currentFileSchemas.filter((t) => t.name === fileName);
        if (currentFileSchema !== undefined) {
          const newFileSchema: ImportShared.TableSchemaJsonStruct = {
            ...currentFileSchema,
            fields: foundFileFields,
          };
          newFileSchemas.push(newFileSchema);
        }
      }
    }
    const graphMappingRepresentation: ImportShared.GraphMappingRepresentationJsonStruct = {
      ...currentDataModel.graphMappingRepresentation,
      dataSourceSchema: {
        type: currentDataModel.graphMappingRepresentation.dataSourceSchema.type,
        tableSchemas: newFileSchemas,
      },
    };
    return {
      ...currentDataModel,
      graphMappingRepresentation,
    };
  }
  return currentDataModel;
};

// We switched from the Arrows graph format to Nevada graph format between v1.4.0 => v2.0.0
export const migrateArrowsGraphToVisualisationState = (
  arrowsGraph: SerialisableArrowGraphState,
): ImportShared.VisualisationState => ({
  nodes: arrowsGraph.nodes.map((n) => ({
    id: toNodeObjectTypeId(n.id),
    position: { x: n.position.x, y: n.position.y },
    selected: false,
  })),
  relationships: arrowsGraph.relationships.map((r) => ({ id: toRelationshipObjectTypeId(r.id), selected: false })),
});

export const getDataModelFromAnyModel = (
  model: ImportGraphDataModel | SerialisableApplicationState,
): ImportShared.DataModelJsonStruct => {
  let modelToLoad = emptyDataModel;

  // @TODO: better schema validation
  if (!isNullish(model.dataModel)) {
    // The model is the latest version
    if (isDataModelJsonStruct(model.dataModel) && isLatestDataModelVersion(model.version)) {
      modelToLoad = model.dataModel;
    }
    // Loaded model is an old version, migrate it to the latest version and clean up
    else {
      modelToLoad = migrateDataModelToLatestVersion(model.dataModel, model.version);
    }
  } else {
    throw Error('No data model found.');
  }
  return modelToLoad;
};

export const getVisualisationFromAnyModel = (
  model: ImportGraphDataModel | SerialisableApplicationState,
  latestDataModel: ImportShared.DataModelJsonStruct,
): ImportShared.VisualisationState => {
  if (!isNullish(model.graph)) {
    return migrateArrowsGraphToVisualisationState(model.graph);
  } else if (!isNullish(model.visualisation)) {
    return hydrateVisualisationState(model.visualisation, latestDataModel);
  }
  throw Error('No visualisation found.');
};
