import type { formatters } from '@neo4j/graph-schema-utils';
import type * as ImportShared from '@nx/import-shared';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { difference, memoize } from 'lodash-es';

import type { NodeModel, RelationshipModel, TableSchema } from '../../data-model/data-model';
import type {
  DataModelErrors,
  NodeErrors,
  RelationshipErrors,
  TableFieldError,
  TableSchemaErrors,
} from '../../data-model/data-model-errors';
import { getDataModelErrors, setDataModelErrorsVisible } from '../../data-model/data-model-errors';
import { dataModelJsonFormatter } from '../../data-model/data-model-json-formatter';
import * as jsonDataModelHelpers from '../../data-model/data-model.json.helpers';
import { toIdFromRef } from '../../data-model/data-model.json.helpers';
import { hasErrorsInUsedTableSchemas } from '../../utils/import-model-utils';
import {
  addNode,
  addNodeAndRelationship,
  addRelationship,
  clearModel,
  deleteNodesAndRelationships,
} from '../actions/common-actions';
import type { RootState } from '../store';

export type DataModelState = ImportShared.DataModelJsonStruct & { errors: DataModelErrors };

export const emptyErrors: DataModelErrors = {
  tableSchemaErrors: [],
  nodeErrors: [],
  relationshipErrors: [],
};

export const initialState: DataModelState = {
  ...jsonDataModelHelpers.emptyDataModel,
  errors: emptyErrors,
};

const updateErrors = (dataModelState: DataModelState) => {
  const errors = getDataModelErrors(dataModelState);
  dataModelState.errors = errors;
};

const initialiseDataModelHelper = (state: DataModelState, dataModel: ImportShared.DataModelJsonStruct) => {
  return { ...dataModel, errors: getDataModelErrors({ ...dataModel, errors: emptyErrors }) };
};

const dataModelSlice = createSlice({
  name: 'dataModel',
  initialState,
  reducers: {
    initialiseDataModel(state, action: PayloadAction<ImportShared.DataModelJsonStruct>) {
      return initialiseDataModelHelper(state, action.payload);
    },
    clearDataModel() {
      return initialState;
    },
    runDataModelValidation(state) {
      setDataModelErrorsVisible(state.errors);
    },
    addNodeIndex(state, action: PayloadAction<{ nodeLabelId: string; propertyId?: string }>) {
      const { nodeLabelId, propertyId } = action.payload;
      jsonDataModelHelpers.addNodeIndex(state, nodeLabelId, propertyId);
      updateErrors(state);
    },
    addNodeConstraint(state, action: PayloadAction<{ nodeLabelId: string; propertyId: string }>) {
      const { nodeLabelId, propertyId } = action.payload;
      jsonDataModelHelpers.addNodeConstraint(state, nodeLabelId, propertyId);
      updateErrors(state);
    },
    setNodeLabel(state, action: PayloadAction<{ nodeObjectTypeId: string; label: string }>) {
      const { label } = action.payload;
      jsonDataModelHelpers.setNodeLabel(state, action.payload.nodeObjectTypeId, label);
      updateErrors(state);
    },
    setRelationshipType(state, action: PayloadAction<{ relationshipObjectTypeId: string; type: string }>) {
      const { type } = action.payload;
      jsonDataModelHelpers.setRelationshipType(state, action.payload.relationshipObjectTypeId, type);
      updateErrors(state);
    },
    addProperty(state, action: PayloadAction<{ nodeId: string } | { relationshipId: string }>) {
      if ('nodeId' in action.payload) {
        const { nodeId } = action.payload;
        jsonDataModelHelpers.addNodeProperty(state, nodeId);
      } else {
        const { relationshipId } = action.payload;
        jsonDataModelHelpers.addRelationshipProperty(state, relationshipId);
      }
      updateErrors(state);
    },
    updateProperty(
      state,
      action: PayloadAction<
        | { nodeId: string; property: formatters.json.types.PropertyJsonStruct }
        | { relationshipId: string; property: formatters.json.types.PropertyJsonStruct }
      >,
    ) {
      const { property } = action.payload;
      if ('nodeId' in action.payload) {
        const { nodeId } = action.payload;
        jsonDataModelHelpers.updateNodeProperty(state, nodeId, property);
      } else {
        const { relationshipId } = action.payload;
        jsonDataModelHelpers.updateRelationshipProperty(state, relationshipId, property);
      }
      updateErrors(state);
    },
    updateIndexProperty(state, action: PayloadAction<{ newPropertyId: string; indexId: string }>) {
      const { newPropertyId, indexId } = action.payload;
      jsonDataModelHelpers.updateIndexProperty(state, newPropertyId, indexId);
      updateErrors(state);
    },
    updateIndexesName(state, action: PayloadAction<{ nodeLabelId: string; propertyId?: string }>) {
      if ('propertyId' in action.payload) {
        const { nodeLabelId, propertyId } = action.payload;
        jsonDataModelHelpers.updateIndexesName(state, nodeLabelId, propertyId);
      } else {
        const { nodeLabelId } = action.payload;
        jsonDataModelHelpers.updateIndexesName(state, nodeLabelId);
      }
      updateErrors(state);
    },
    removeProperty(
      state,
      action: PayloadAction<{ nodeId: string; propertyId: string } | { relationshipId: string; propertyId: string }>,
    ) {
      const { propertyId } = action.payload;
      if ('nodeId' in action.payload) {
        const { nodeId } = action.payload;
        jsonDataModelHelpers.removeNodeProperty(state, nodeId, propertyId);
      } else {
        const { relationshipId } = action.payload;
        jsonDataModelHelpers.removeRelationshipProperty(state, relationshipId, propertyId);
      }
      updateErrors(state);
    },
    setNodeKeyProperty(state, action: PayloadAction<{ nodeId: string; propertyId: string }>) {
      const { nodeId, propertyId } = action.payload;
      jsonDataModelHelpers.setNodeKeyProperty(state, nodeId, propertyId);
      jsonDataModelHelpers.tryAutoPopulateRelationshipMappingsForNode(state, nodeId);
      updateErrors(state);
    },
    setTableSchema(
      state,
      action: PayloadAction<{ nodeId: string; tableName: string } | { relationshipId: string; tableName: string }>,
    ) {
      if ('nodeId' in action.payload) {
        const { nodeId, tableName } = action.payload;
        jsonDataModelHelpers.setNodeMappingTableSchema(state, nodeId, tableName);
      } else {
        const { relationshipId, tableName } = action.payload;
        jsonDataModelHelpers.setRelationshipMappingTableSchema(state, relationshipId, tableName);
      }
      updateErrors(state);
    },
    setMappingFilter(
      state,
      action: PayloadAction<
        | { nodeId: string; mappingFilter: ImportShared.MappingFilterJsonStruct | undefined }
        | { relationshipId: string; mappingFilter: ImportShared.MappingFilterJsonStruct | undefined }
      >,
    ) {
      if ('nodeId' in action.payload) {
        const { nodeId, mappingFilter } = action.payload;
        jsonDataModelHelpers.setNodeMappingFilter(state, nodeId, mappingFilter);
      } else {
        const { relationshipId, mappingFilter } = action.payload;
        jsonDataModelHelpers.setRelationshipMappingFilter(state, relationshipId, mappingFilter);
      }
    },
    setPropertyMapping(
      state,
      action: PayloadAction<
        | { nodeId: string; propertyId: string; fieldName: string }
        | { relationshipId: string; propertyId: string; fieldName: string }
      >,
    ) {
      if ('nodeId' in action.payload) {
        const { nodeId, propertyId, fieldName } = action.payload;
        jsonDataModelHelpers.setNodePropertyMapping(state, nodeId, propertyId, fieldName);
      } else {
        const { relationshipId, propertyId, fieldName } = action.payload;
        jsonDataModelHelpers.setRelationshipPropertyMapping(state, relationshipId, propertyId, fieldName);
      }
      updateErrors(state);
    },
    addPropertyMappings(
      state,
      action: PayloadAction<
        | { nodeId: string; fields: ImportShared.TableSchemaFieldJsonStruct[]; indexesEnabled?: boolean }
        | { relationshipId: string; fields: ImportShared.TableSchemaFieldJsonStruct[] }
      >,
    ) {
      if ('nodeId' in action.payload) {
        const { nodeId, fields } = action.payload;
        jsonDataModelHelpers.addNodePropertyMappings(state, nodeId, fields);
      } else {
        const { relationshipId, fields } = action.payload;
        jsonDataModelHelpers.addRelationshipPropertyMappings(state, relationshipId, fields);
      }
      updateErrors(state);
    },
    setRelationshipSourceMapping(state, action: PayloadAction<{ relationshipId: string; fieldName: string }>) {
      const { relationshipId, fieldName } = action.payload;
      jsonDataModelHelpers.setRelationshipSourceMapping(state, relationshipId, fieldName);
      updateErrors(state);
    },
    setRelationshipTargetMapping(state, action: PayloadAction<{ relationshipId: string; fieldName: string }>) {
      const { relationshipId, fieldName } = action.payload;
      jsonDataModelHelpers.setRelationshipTargetMapping(state, relationshipId, fieldName);
      updateErrors(state);
    },

    deleteIndexByIds(state, action: PayloadAction<{ indexesIds: string[] }>) {
      const { indexesIds } = action.payload;
      jsonDataModelHelpers.deleteIndexByIds(state, indexesIds);
      updateErrors(state);
    },
    reverseRelationship(state, action: PayloadAction<{ relationshipObjectTypeId: string }>) {
      jsonDataModelHelpers.reverseRelationship(state, action.payload.relationshipObjectTypeId);
      updateErrors(state);
    },
    setDataSourceLocation(state, action: PayloadAction<ImportShared.DataSourceLocation>) {
      state.graphMappingRepresentation.dataSourceSchema.type = action.payload;
    },
    addTableSchema(state, action: PayloadAction<ImportShared.TableSchemaJsonStruct>) {
      jsonDataModelHelpers.addTableSchema(state, action.payload);
      updateErrors(state);
    },
    addTableSchemas(state, action: PayloadAction<ImportShared.TableSchemaJsonStruct[]>) {
      action.payload.forEach((tableSchema) => jsonDataModelHelpers.addTableSchema(state, tableSchema));
      updateErrors(state);
    },
    removeTableSchema(state, action: PayloadAction<{ name: string }>) {
      jsonDataModelHelpers.removeTableSchemas(state, [action.payload.name]);
      if (state.graphMappingRepresentation.dataSourceSchema.tableSchemas.length === 0) {
        state.graphMappingRepresentation.dataSourceSchema.type = null;
      }
      updateErrors(state);
    },
    removeAllTableSchemas(state) {
      const allTableNames = state.graphMappingRepresentation.dataSourceSchema.tableSchemas.map((t) => t.name);
      jsonDataModelHelpers.removeTableSchemas(state, allTableNames);
      state.graphMappingRepresentation.dataSourceSchema.type = null;
      updateErrors(state);
    },
    updateTableSchemas(state, action: PayloadAction<ImportShared.TableSchemaJsonStruct[]>) {
      const currentTableSchemas = state.graphMappingRepresentation.dataSourceSchema.tableSchemas;
      const newTableSchemas = action.payload;
      const currentTableSchemasNames = currentTableSchemas.map((t) => t.name);
      const newTableSchemasNames = newTableSchemas.map((t) => t.name);
      const tableSchemasToRemove = currentTableSchemasNames.filter((name) => !newTableSchemasNames.includes(name));
      jsonDataModelHelpers.removeTableSchemas(state, tableSchemasToRemove);
      newTableSchemas.forEach((tableSchema) => jsonDataModelHelpers.addTableSchema(state, tableSchema));
      updateErrors(state);
    },
    toggleTableSchemaExpanded(state, action: PayloadAction<{ name: string }>) {
      jsonDataModelHelpers.toggleTableSchemaExpanded(state, action.payload.name);
    },
    expandAllTableSchemas(state) {
      jsonDataModelHelpers.expandAllTableSchemas(state);
    },
    collapseAllTableSchemas(state) {
      jsonDataModelHelpers.collapseAllTableSchemas(state);
    },
    setIdsToIgnore(state, action: PayloadAction<{ idsToIgnore: string[] }>) {
      const { idsToIgnore } = action.payload;
      state.configurations.idsToIgnore = idsToIgnore;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearModel, () => initialState);
    builder.addCase(addNode, (state, action) => {
      const { newNodeId, label } = action.payload;
      jsonDataModelHelpers.addNode(state, newNodeId, label ?? '');
      updateErrors(state);
    });
    builder.addCase(addRelationship, (state, action) => {
      const { sourceNodeId, targetNodeId, newRelationshipId } = action.payload;
      jsonDataModelHelpers.addRelationship(state, newRelationshipId, sourceNodeId, targetNodeId);
      jsonDataModelHelpers.tryAutoPopulateRelationshipMapping({
        dataModel: state,
        relationshipObjectId: newRelationshipId,
        currentRelationshipMapping: undefined,
        sourceNodeId: sourceNodeId,
        targetNodeId: targetNodeId,
      });
      updateErrors(state);
    });
    builder.addCase(addNodeAndRelationship, (state, action) => {
      const { sourceNodeId, targetNodeId, newRelationshipId } = action.payload;
      jsonDataModelHelpers.addNode(state, targetNodeId, '');
      jsonDataModelHelpers.addRelationship(state, newRelationshipId, sourceNodeId, targetNodeId);
      updateErrors(state);
    });
    builder.addCase(deleteNodesAndRelationships, (state, action) => {
      const { nodeIds, relationshipIds } = action.payload;
      jsonDataModelHelpers.deleteNodeIndexes(state, nodeIds);
      jsonDataModelHelpers.deleteNodeConstraints(state, nodeIds);
      jsonDataModelHelpers.deleteNodes(state, nodeIds);
      jsonDataModelHelpers.deleteRelationships(state, relationshipIds);
      updateErrors(state);
    });
  },
});

export const selectDataModel: (state: RootState) => ReturnType<typeof dataModelJsonFormatter.fromJsonStruct> =
  createSelector(
    (state: RootState) => state.dataModel,
    (dataModel: DataModelState) => dataModelJsonFormatter.fromJsonStruct(dataModel),
  );

export const selectDataModelState: (state: RootState) => ReturnType<(state: RootState) => DataModelState> =
  createSelector(
    (state: RootState) => state,
    (state) => state.dataModel,
  );

export const selectDataModelJsonStruct: (state: RootState) => ImportShared.DataModelJsonStruct = createSelector(
  (state: RootState) => state.dataModel,
  (dataModel: DataModelState) => ({
    version: dataModel.version,
    graphSchemaRepresentation: dataModel.graphSchemaRepresentation,
    graphSchemaExtensionsRepresentation: dataModel.graphSchemaExtensionsRepresentation,
    graphMappingRepresentation: dataModel.graphMappingRepresentation,
    configurations: dataModel.configurations,
  }),
);

export const selectErrors: (state: RootState) => ReturnType<(state: RootState) => DataModelErrors> = createSelector(
  [selectDataModelState],
  (dataModel) => dataModel.errors,
);

export const selectHasDataModelErrors: (state: RootState) => ReturnType<(state: RootState) => boolean> = createSelector(
  [selectErrors],
  (dataModelErrors) =>
    hasErrorsInUsedTableSchemas(dataModelErrors.tableSchemaErrors) ||
    dataModelErrors.nodeErrors.length > 0 ||
    dataModelErrors.relationshipErrors.length > 0,
);

export const selectTableErrors = (
  tableName: string | undefined,
): ((state: RootState) => ReturnType<(state: RootState, tableName: string) => TableFieldError[]>) =>
  createSelector(selectErrors, (errors) => {
    if (tableName === undefined) {
      return [];
    }
    const tableErrors = errors.tableSchemaErrors;
    const tableErrorsForSpecificTable = tableErrors.filter((error) => error.tableName === tableName);
    return tableErrorsForSpecificTable[0] ? tableErrorsForSpecificTable[0].fieldErrors : [];
  });

export const selectIndexes = (state: RootState) => state.dataModel.graphSchemaRepresentation.graphSchema.indexes;
export const selectConstraints = (state: RootState) =>
  state.dataModel.graphSchemaRepresentation.graphSchema.constraints;

export const selectIndexesByNodeLabelId: (
  nodeLabelId?: string,
) => (state: RootState) => ReturnType<(state: RootState, nodeLabelId: string) => ImportShared.IndexesJsonStruct[]> =
  memoize(
    (
      nodeLabelId?: string,
    ): ((
      state: RootState,
    ) => ReturnType<(state: RootState, nodeLabelId: string) => ImportShared.IndexesJsonStruct[]>) =>
      createSelector([selectIndexes], (indexes) => {
        if (nodeLabelId === undefined) {
          return [];
        }
        return indexes.filter((index) => index.nodeLabel?.$ref === jsonDataModelHelpers.toRefString(nodeLabelId));
      }),
  );

export const selectConstraintsByNodeLabelId: (
  nodeLabelId?: string,
) => (state: RootState) => ReturnType<(state: RootState, nodeLabelId: string) => ImportShared.ConstraintsJsonStruct[]> =
  memoize(
    (
      nodeLabelId?: string,
    ): ((
      state: RootState,
    ) => ReturnType<(state: RootState, nodeLabelId: string) => ImportShared.ConstraintsJsonStruct[]>) =>
      createSelector([selectConstraints], (constraints) => {
        if (nodeLabelId === undefined) {
          return [];
        }
        return constraints.filter(
          (constraint) => constraint.nodeLabel?.$ref === jsonDataModelHelpers.toRefString(nodeLabelId),
        );
      }),
  );

type NodeData = { nodeModel?: NodeModel; tableErrors?: TableSchemaErrors; nodeErrors?: NodeErrors };
export const selectNode = (
  nodeId: string,
): ((state: RootState) => ReturnType<(state: RootState, nodeId: string) => NodeData>) =>
  createSelector([selectErrors, selectDataModel], (errors, dataModel) => {
    const nodeModel = dataModel.nodeModels.find((n) => n.nodeObjectType.$id === nodeId);
    const nodeRef = nodeModel?.nodeObjectType
      ? jsonDataModelHelpers.toRefString(nodeModel.nodeObjectType.$id)
      : undefined;
    const tableName = nodeModel?.nodeMapping?.tableSchema.name;
    const nodeErrors = errors.nodeErrors.find((e) => e.node.$ref === nodeRef);
    const tableErrors = errors.tableSchemaErrors.find((e) => e.tableName === tableName);
    return { nodeModel, nodeErrors, tableErrors };
  });

type RelationshipData = {
  relationshipModel?: RelationshipModel;
  tableErrors?: TableSchemaErrors;
  relationshipErrors?: RelationshipErrors;
};
export const selectRelationship = (
  relationshipId: string,
): ((state: RootState) => ReturnType<(state: RootState, relationshipId: string) => RelationshipData>) =>
  createSelector([selectErrors, selectDataModel], (errors, dataModel) => {
    const relationshipModel = dataModel.relationshipModels.find((r) => r.relationshipObjectType.$id === relationshipId);
    const relationshipRef = relationshipModel?.relationshipObjectType
      ? jsonDataModelHelpers.toRefString(relationshipModel.relationshipObjectType.$id)
      : undefined;
    const tableName = relationshipModel?.relationshipMapping?.tableSchema.name;
    const relationshipErrors = errors.relationshipErrors.find((e) => e.relationship.$ref === relationshipRef);
    const tableErrors = errors.tableSchemaErrors.find((e) => e.tableName === tableName);
    return { relationshipModel, relationshipErrors, tableErrors };
  });

export const selectEntityValidationIdsGroupedByVisibilities: (state: RootState) => ReturnType<
  (state: RootState) => {
    correctNodeIds: string[];
    visibleErrorNodeIds: string[];
    invisibleErrorNodeIds: string[];
    correctRelationshipIds: string[];
    visibleErrorRelationshipIds: string[];
    invisibleErrorRelationshipIds: string[];
  }
> = createSelector([selectErrors, selectDataModel], (errors, dataModel) => {
  const { nodeModels, relationshipModels } = dataModel;
  const nodeIds = nodeModels.map((nodeModel) => nodeModel.nodeObjectType.$id);
  const relationshipIds = relationshipModels.map((relationshipModel) => relationshipModel.relationshipObjectType.$id);
  const { nodeErrors, relationshipErrors } = errors;

  const nodeErrorsIdAndVisibility = nodeErrors.map((nodeError) => ({
    id: toIdFromRef(nodeError.node.$ref),
    visible: nodeError.visible,
  }));
  const errorNodeIds = nodeErrorsIdAndVisibility.map((nodeErrorRefAndVisibility) => nodeErrorRefAndVisibility.id);
  const visibleErrorNodeIds = nodeErrorsIdAndVisibility
    .filter((nodeErrorRefAndVisibility) => nodeErrorRefAndVisibility.visible)
    .map((nodeErrorRefAndVisibility) => nodeErrorRefAndVisibility.id);

  const correctNodeIds = difference(nodeIds, errorNodeIds);
  const invisibleErrorNodeIds = difference(errorNodeIds, visibleErrorNodeIds);

  const relationshipErrorsIdAndVisibility = relationshipErrors.map((relationshipError) => ({
    id: toIdFromRef(relationshipError.relationship.$ref),
    visible: relationshipError.visible,
  }));
  const errorRelationshipIds = relationshipErrorsIdAndVisibility.map(
    (relationshipErrorRefAndVisibility) => relationshipErrorRefAndVisibility.id,
  );
  const visibleErrorRelationshipIds = relationshipErrorsIdAndVisibility
    .filter((relationshipErrorRefAndVisibility) => relationshipErrorRefAndVisibility.visible)
    .map((relationshipErrorRefAndVisibility) => relationshipErrorRefAndVisibility.id);

  const correctRelationshipIds = difference(relationshipIds, errorRelationshipIds);
  const invisibleErrorRelationshipIds = difference(errorRelationshipIds, visibleErrorRelationshipIds);
  return {
    correctNodeIds,
    visibleErrorNodeIds,
    invisibleErrorNodeIds,
    correctRelationshipIds,
    visibleErrorRelationshipIds,
    invisibleErrorRelationshipIds,
  };
});

export const selectNvlNodes: (
  state: RootState,
) => ReturnType<(state: RootState) => { id: string; labels: string[] }[]> = createSelector(
  [selectDataModel],
  (dataModel) => {
    const { nodeModels } = dataModel;
    return nodeModels.map((nodeModel) => ({
      id: nodeModel.nodeObjectType.$id,
      labels: nodeModel.nodeObjectType.labels.map((nodeLabel) => nodeLabel.token),
    }));
  },
);

export const selectNvlRelationships: (
  state: RootState,
) => ReturnType<
  (state: RootState) => { id: string; fromNodeObjectId: string; toNodeObjectId: string; type: string }[]
> = createSelector([selectDataModel], (dataModel) => {
  const { relationshipModels } = dataModel;
  return relationshipModels.map((relationshipModel) => ({
    id: relationshipModel.relationshipObjectType.$id,
    fromNodeObjectId: relationshipModel.from.nodeObjectType.$id,
    toNodeObjectId: relationshipModel.to.nodeObjectType.$id,
    type: relationshipModel.relationshipObjectType.type.token,
  }));
});

export const selectDataSourceSchemaType: (
  state: RootState,
) => ReturnType<(state: RootState) => ImportShared.DataSourceLocation> = createSelector(
  [selectDataModelState],
  (dataModel) => dataModel.graphMappingRepresentation.dataSourceSchema.type,
);

export const selectTableSchemas: (state: RootState) => ReturnType<(state: RootState) => TableSchema[]> = createSelector(
  [selectDataModel],
  (dataModel) =>
    dataModel.graphMappingRepresentation.dataSourceSchema.tableSchemas.slice().sort((a, b) => {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    }),
);

export const selectTableSchemasJsonStruct = (state: RootState) =>
  state.dataModel.graphMappingRepresentation.dataSourceSchema.tableSchemas;

type NodeOrRelationshipId = { nodeId: string } | { relationshipId: string };
export const selectTableSchema = (
  nodeOrRelationshipId: NodeOrRelationshipId,
): ((
  state: RootState,
) => ReturnType<(state: RootState, nodeOrRelationshipId: NodeOrRelationshipId) => TableSchema | undefined>) =>
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      if ('nodeId' in nodeOrRelationshipId) {
        const { nodeModel } = selectNode(nodeOrRelationshipId.nodeId)(state);
        return nodeModel?.nodeMapping?.tableSchema;
      }
      const { relationshipModel } = selectRelationship(nodeOrRelationshipId.relationshipId)(state);
      return relationshipModel?.relationshipMapping?.tableSchema;
    },
  );

export const selectTablesExist: (state: RootState) => ReturnType<(state: RootState) => boolean> = createSelector(
  (state: RootState) => state.dataModel.graphMappingRepresentation.dataSourceSchema.tableSchemas,
  (tableSchemas) => tableSchemas.length > 0,
);

export const selectNodesExist: (state: RootState) => ReturnType<(state: RootState) => boolean> = createSelector(
  (state: RootState) => state.dataModel.graphSchemaRepresentation.graphSchema.nodeObjectTypes,
  (nodeObjectTypes) => nodeObjectTypes.length > 0,
);

export const {
  initialiseDataModel,
  clearDataModel,
  runDataModelValidation,
  setNodeLabel,
  setRelationshipType,
  addProperty,
  addNodeIndex,
  addNodeConstraint,
  updateProperty,
  updateIndexProperty,
  updateIndexesName,
  removeProperty,
  setNodeKeyProperty,
  setTableSchema,
  setMappingFilter,
  setPropertyMapping,
  addPropertyMappings,
  setRelationshipSourceMapping,
  setRelationshipTargetMapping,
  deleteIndexByIds,
  reverseRelationship,
  setDataSourceLocation,
  addTableSchema,
  addTableSchemas,
  removeTableSchema,
  removeAllTableSchemas,
  updateTableSchemas,
  toggleTableSchemaExpanded,
  expandAllTableSchemas,
  collapseAllTableSchemas,
  setIdsToIgnore,
} = dataModelSlice.actions;

export default dataModelSlice.reducer;
