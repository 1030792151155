import type { DeprecationLogFilterValues } from '../api-types';
import type { AvailableDbmsFilters } from '../types/migration';

export function transformDeprecationLogFilterValuesResponse(res: DeprecationLogFilterValues): AvailableDbmsFilters {
  return {
    databases: [...res.databases].sort(),
    users: [...res.users].sort(),
    drivers: [...res.drivers].sort(),
    apps: [...res.apps].sort(),
    initiationTypes: [...res.initiationTypes].sort(),
    deprecationNotificationNames: [...res.deprecationNotificationNames].sort(),
  };
}
