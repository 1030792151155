import type { InstanceLogParams, LogResponse, LogUrlResponse, LogsRequest } from '../console.api.types';
import type { Log } from '../console.types';
import { transformLogResponse, transformLogsRequest } from '../transformers/log';
import { type Builder, LIST_ID } from './types';

export const logEndpoints = (builder: Builder) => ({
  listLogs: builder.query<Log[], string>({
    query: (dbId) => ({ url: `databases/${dbId}/logs` }),
    transformResponse: ({ data }: { data: LogResponse[] }) => data.map((log) => transformLogResponse(log)),
    providesTags: (result, error, dbId) => [{ type: 'Log', id: LIST_ID, dbId }],
  }),
  requestLogs: builder.mutation<Log, LogsRequest>({
    query: (request) => ({
      url: `databases/${request.dbId}/logs`,
      method: 'POST',
      body: transformLogsRequest(request),
    }),
    invalidatesTags: (result, error, data) => [{ type: 'Log', dbId: data.dbId, id: LIST_ID }],
  }),
  getLogDownloadLink: builder.query<LogUrlResponse, InstanceLogParams>({
    query: (params) => `databases/${params.dbId}/logs/${params.logId}`,
  }),
});
