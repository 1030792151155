import { Accordion, Banner, Checkbox, Radio, Select, Switch, TextInput, TextLink, Typography } from '@neo4j-ndl/react';
import { AURA_CONSOLE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import type { Instance, InstanceSize, Project, TierConfig } from '@nx/state';
import { CLOUD_PROVIDER, NEO4J_MANAGED_KEY, PLAN_TYPE, PROJECT_TYPE, TIER, getNeo4jVersionText } from '@nx/state';
import { Objects, isNotNullish, isNullish } from '@nx/stdlib';
import { useDarkMode } from '@nx/ui';
import cn from 'classnames';
import type { MouseEventHandler } from 'react';
import { useCallback, useMemo, useState } from 'react';

import aws_dark from '../../assets/aws-dark.svg';
import aws from '../../assets/aws.svg';
import azure from '../../assets/azure.svg';
import gcp from '../../assets/gcp.svg';
import { useTrackUpxEvent } from '../../services/segment/analytics';
import { calcMonthlyCost, formatDollars } from '../../utils';
import type { Validation } from '../../utils/validation';
import { isMultiProductProject, isPAYG, isSizeAvailableForFormData } from '../entities/helpers';
import type { InstanceFormData, Product } from '../entities/model';
import { EncryptionKeySection } from '../shared/encryption-keys';
import {
  GdsPluginField,
  VectorOptimizedField,
  getValidGdsPluginValue,
  getValidVectorOptimizedValue,
} from './form-additional-settings';
import { getValidRegion } from './helper';
import { InstanceSizePicker } from './size-picker';

type ProductSelectorProps = {
  isSelected: boolean;
  onChange: () => void;
  title: string;
  'aria-label': string;
};

const ProductSelector = ({ isSelected, onChange, 'aria-label': ariaLabel, title }: ProductSelectorProps) => {
  return (
    <button
      className={cn('flex basis-96 flex-col gap-2 rounded-3xl border p-4', {
        'border-primary-border-strong': isSelected,
        'border-neutral-border-strong': !isSelected,
      })}
      onClick={onChange}
      type="button"
      tabIndex={0}
      onMouseDown={(e) => e.preventDefault()}
      aria-label={ariaLabel}
      aria-checked={isSelected}
      role="radio"
    >
      <div className="flex gap-3">
        <Radio isChecked={isSelected} />
        <Typography variant="body-medium">{title}</Typography>
      </div>
    </button>
  );
};
type ProviderIcon = 'gcp' | 'aws' | 'aws-dark' | 'azure';

const providerImgs: Record<ProviderIcon, string> = {
  gcp,
  azure,
  aws,
  'aws-dark': aws_dark,
};

interface CloudProviderCardProps {
  icon: ProviderIcon;
  selected: boolean;
  'aria-label': string;
  onSelect?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

export const CloudProviderCard = ({
  icon,
  selected,
  onSelect,
  'aria-label': ariaLabel,
  disabled = false,
}: CloudProviderCardProps) => {
  const classes = cn('flex h-16 w-36 items-center justify-center rounded-3xl border', {
    'border-neutral-border-strong': !selected,
    'border-primary-border-strong': selected,
    'border-neutral-border-weak relative cursor-not-allowed': disabled,
  });

  const Icon = providerImgs[icon];

  return (
    <button
      className={classes}
      onClick={onSelect}
      type="button"
      tabIndex={0}
      onMouseDown={(e) => e.preventDefault()}
      aria-label={ariaLabel}
      aria-checked={selected}
      role="radio"
      disabled={disabled}
    >
      <img src={Icon} width="45" height="36" title="" className="flex-shrink-0" />
      {disabled && <div className="bg-neutral-bg-strong absolute h-full w-full rounded-3xl opacity-60" />}
    </button>
  );
};

enum CREATE_FORM_ITEM {
  PRODUCT_TYPE = 'product-type',
  CP_AND_REGION = 'cloud-provider-and-region',
  INSTANCE_DETAILS = 'instance-details',
  INSTANCE_SIZE = 'instance-size',
  ADDITIONAL_SETTINGS = 'additional-settings',
}

interface CreateInstanceFormProps {
  data: InstanceFormData;
  onDataChange: (data: InstanceFormData) => void;
  tierConfig: TierConfig;
  validation: Validation<InstanceFormData> | null;
  project: Project;
  instance?: Instance;
  onProductChange?: (product: Product) => void;
  product?: Product;
}

export const CreateInstanceForm = ({
  data,
  onDataChange,
  tierConfig,
  validation,
  project,
  instance,
  onProductChange,
  product,
}: CreateInstanceFormProps) => {
  const trackEvent = useTrackUpxEvent();
  const isDarkTheme = useDarkMode();
  const [showProTrialOptions, setShowProTrialOptions] = useState(data.size?.isTrial ?? false);
  const showProductSelector = project.planType === PLAN_TYPE.ENTERPRISE && isMultiProductProject(project.tierConfigs);

  const [expandedItemIds, setExpandedItemIds] = useState<CREATE_FORM_ITEM[]>([
    ...(showProductSelector ? [CREATE_FORM_ITEM.PRODUCT_TYPE] : []),
    CREATE_FORM_ITEM.CP_AND_REGION,
    CREATE_FORM_ITEM.INSTANCE_SIZE,
    CREATE_FORM_ITEM.INSTANCE_DETAILS,
    CREATE_FORM_ITEM.ADDITIONAL_SETTINGS,
  ]);
  const cloudProviders = Objects.keys(tierConfig.cloudProviderRegions);
  const regions = useMemo(() => {
    if (isNotNullish(data.cloudProvider)) {
      return tierConfig.cloudProviderRegions[data.cloudProvider];
    }
    return [];
  }, [data.cloudProvider, tierConfig]);

  const showRegionalSupportTerms = [TIER.MTE, TIER.ENTERPRISE].includes(data.tier);
  const costPerHour = data.size?.costPerHour ?? '0';
  const showCmekWarning = project.capabilities.cmek && data.encryptionKeyRef !== NEO4J_MANAGED_KEY;

  const costPerMonth = calcMonthlyCost(costPerHour);

  const regionOptions = useMemo(
    () => regions?.map((region) => ({ label: region.friendly, value: region.name, key: region.name })) ?? [],
    [regions],
  );
  const selectedRegion = useMemo(() => {
    return regionOptions.find((region) => region.value === data.region) ?? null;
  }, [regionOptions, data.region]);
  const versionOptions = tierConfig.versions.map((version) => ({
    label: getNeo4jVersionText(version),
    value: version,
    key: version,
  }));
  const visibleSizes = useMemo(() => {
    let { sizes } = tierConfig;
    if (isNotNullish(data.cloudProvider)) {
      sizes = sizes.filter((size) => size.cloudProvider === data.cloudProvider);
    }

    return sizes.filter((size) => size.isTrial === showProTrialOptions);
  }, [data.cloudProvider, tierConfig, showProTrialOptions]);

  const handleAccordionChange = (items: CREATE_FORM_ITEM[]) => {
    setExpandedItemIds(items);
  };

  const resetDataIfNeeded = useCallback(
    (newData: InstanceFormData) => {
      const newSize = isSizeAvailableForFormData(newData, project, visibleSizes, { isTrial: showProTrialOptions })
        ? newData.size
        : undefined;
      const newRegion = getValidRegion(tierConfig.cloudProviderRegions, newData.cloudProvider, newData.region);
      const newerData = { ...newData, size: newSize, region: newRegion };
      const resetData = {
        ...newData,
        region: newRegion,
        size: newSize,
        gdsPlugin: getValidGdsPluginValue(newerData),
        vectorOptimized: getValidVectorOptimizedValue(newerData),
        confirmed: [newSize, newData.region].some(isNullish) ? false : newData.confirmed,
      };

      return resetData;
    },
    [project, visibleSizes, showProTrialOptions, tierConfig],
  );

  const handleDataChange = useCallback(
    (changedData: InstanceFormData) => {
      onDataChange(resetDataIfNeeded(changedData));
    },
    [resetDataIfNeeded, onDataChange],
  );

  const handleCloudProviderChange = (cloudProvider: CLOUD_PROVIDER) => {
    if (cloudProviders.length === 1) {
      return;
    }

    if (data.cloudProvider === cloudProvider) {
      return;
    }

    handleDataChange({ ...data, cloudProvider });
  };

  const handleRegionChange = (region: string) => {
    handleDataChange({ ...data, region });
  };

  const handleVersionChange = (version: string) => {
    handleDataChange({ ...data, version });
  };

  const handleSizeChange = useCallback(
    (size: InstanceSize | undefined) => {
      handleDataChange({ ...data, size });
    },
    [data, handleDataChange],
  );

  const handleNameChange = (name: string) => {
    handleDataChange({ ...data, name });
  };

  const handleConfirmedChange = (confirmed: boolean) => {
    handleDataChange({ ...data, confirmed });
  };

  const handleEncryptionKeyChange = (key: string) => {
    handleDataChange({ ...data, encryptionKeyRef: key });
  };

  const handleGdsPluginChange = (gdsPlugin: boolean) => {
    handleDataChange({ ...data, gdsPlugin });
  };

  const handleVectorOptimizedChange = (vectorOptimized: boolean) => {
    handleDataChange({ ...data, vectorOptimized });
  };

  const handleShowProTrialOptionsChange = (checked: boolean) => {
    if (checked) {
      trackEvent({
        event: AURA_CONSOLE_EVENTS.INSTANCE_PRO_TRIAL_TOGGLE,
        scope: APP_SCOPE.aura,
      });
    } else {
      trackEvent({
        event: AURA_CONSOLE_EVENTS.INSTANCE_PRO_TRIAL_UNTOGGLE,
        scope: APP_SCOPE.aura,
      });
    }
    setShowProTrialOptions(checked);
    handleDataChange({ ...data, size: tierConfig.sizes.find((i) => i.isTrial === checked) });
  };

  return (
    <div>
      <Accordion expandedItemIds={expandedItemIds} isMultiple onChange={handleAccordionChange}>
        {showProductSelector && isNotNullish(product) && isNotNullish(onProductChange) && (
          <Accordion.Item title="Product type" itemId={CREATE_FORM_ITEM.PRODUCT_TYPE}>
            <div className="flex flex-col gap-6">
              <div className="flex w-full grow flex-row gap-4">
                <ProductSelector
                  title="AuraDB"
                  aria-label="Select AuraDB"
                  isSelected={product === 'AuraDB'}
                  onChange={() => onProductChange('AuraDB')}
                />

                <ProductSelector
                  title="AuraDS"
                  aria-label="Select AuraDS"
                  isSelected={product === 'AuraDS'}
                  onChange={() => onProductChange('AuraDS')}
                />
              </div>
            </div>
          </Accordion.Item>
        )}
        <Accordion.Item title="Cloud provider and region" itemId={CREATE_FORM_ITEM.CP_AND_REGION}>
          <div className="flex flex-col gap-6">
            <Typography variant="subheading-medium" as="div" className="mb-2">
              Provider
            </Typography>
            <div className="flex gap-4">
              {cloudProviders.includes(CLOUD_PROVIDER.GCP) && (
                <CloudProviderCard
                  icon="gcp"
                  aria-label="Select cloud provider GCP"
                  selected={data.cloudProvider === CLOUD_PROVIDER.GCP}
                  onSelect={() => handleCloudProviderChange(CLOUD_PROVIDER.GCP)}
                />
              )}
              {cloudProviders.includes(CLOUD_PROVIDER.AWS) && (
                <CloudProviderCard
                  aria-label="Select cloud provider AWS"
                  icon={isDarkTheme ? 'aws-dark' : 'aws'}
                  selected={data.cloudProvider === CLOUD_PROVIDER.AWS}
                  onSelect={() => handleCloudProviderChange(CLOUD_PROVIDER.AWS)}
                />
              )}
              {cloudProviders.includes(CLOUD_PROVIDER.AZURE) && (
                <CloudProviderCard
                  icon="azure"
                  aria-label="Select cloud provider Azure"
                  selected={data.cloudProvider === CLOUD_PROVIDER.AZURE}
                  onSelect={() => handleCloudProviderChange(CLOUD_PROVIDER.AZURE)}
                />
              )}
            </div>
            <Select
              type="select"
              size="medium"
              errorText={validation?.region?.message}
              selectProps={{
                menuPosition: 'fixed',
                placeholder: isNullish(data.cloudProvider)
                  ? 'Select a cloud provider to see available regions...'
                  : 'Select...',
                onChange: (o) => isNotNullish(o) && handleRegionChange(o.value),
                isMulti: false,
                value: selectedRegion,
                options: regionOptions,
                // Need to have due to bug in ndl
                onKeyDown: (e) => e.stopPropagation(),
              }}
              helpText={
                <div>
                  Need a different region?{' '}
                  <TextLink href="https://aura.feedback.neo4j.com/auradb-regions" isExternalLink>
                    Let us know!
                  </TextLink>
                </div>
              }
              label="Region"
            />
          </div>
        </Accordion.Item>
        <Accordion.Item title="Instance size" itemId={CREATE_FORM_ITEM.INSTANCE_SIZE}>
          <div className="mt-4 flex flex-col gap-4">
            {project.availableActions.create_pro_trial.enabled &&
              data.tier === TIER.PROFESSIONAL &&
              isNotNullish(tierConfig.sizes.find((size) => size.isTrial)) && (
                <Switch
                  onChange={(event) => handleShowProTrialOptionsChange(event.target.checked)}
                  label="Show trial offers"
                  isChecked={showProTrialOptions}
                />
              )}
            <InstanceSizePicker
              data={data}
              options={visibleSizes}
              errorMessage={validation?.size?.message}
              onChange={handleSizeChange}
              hidePricing={!isPAYG(data.tier, project.billingMethod)}
              tier={data.tier}
            />
          </div>
        </Accordion.Item>
        <Accordion.Item title="Instance details" itemId={CREATE_FORM_ITEM.INSTANCE_DETAILS}>
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-2">
              <TextInput
                isFluid
                value={data.name ?? ''}
                onChange={(e) => handleNameChange(e.target.value)}
                errorText={
                  validation?.name?.message ??
                  ((data.name ?? '').length > 30 ? 'Instance name must be at most 30 characters' : null)
                }
                label="Instance Name"
                htmlAttributes={{
                  // Need to have due to bug in ndl
                  onKeyDown: (e) => e.stopPropagation(),

                  'data-testid': 'instance-name-input',
                }}
              />
              {versionOptions.length > 1 && (
                <Select
                  type="select"
                  size="medium"
                  errorText={validation?.version?.message}
                  isFluid
                  htmlAttributes={{
                    'data-testid': 'version-select',
                  }}
                  selectProps={{
                    menuPosition: 'fixed',
                    onChange: (o) => isNotNullish(o) && handleVersionChange(o.value),
                    isMulti: false,
                    value: {
                      key: data.version,
                      label: getNeo4jVersionText(data.version),
                      value: data.version,
                    },
                    options: versionOptions,
                    // Need to have due to bug in ndl
                    onKeyDown: (e) => e.stopPropagation(),
                  }}
                  label="Version"
                />
              )}
              <EncryptionKeySection
                data={data}
                onChange={handleEncryptionKeyChange}
                errorMessage={validation?.encryptionKeyRef?.message}
                instance={instance}
              />
            </div>
            {showCmekWarning && (
              <Banner
                description="By encrypting this instance with a Customer Managed Key, you take full responsibility for the management and security of the key. Neo4j cannot recover your data if the key is lost or invalid. There is a risk of permanent data loss."
                type="warning"
                hasIcon
                usage="inline"
              />
            )}
          </div>
        </Accordion.Item>
        <Accordion.Item title="Additional settings (optional)" itemId={CREATE_FORM_ITEM.ADDITIONAL_SETTINGS}>
          {project.capabilities.gds_plugin && data.tier === TIER.PROFESSIONAL && (
            <GdsPluginField data={data} onChange={handleGdsPluginChange} />
          )}
          <VectorOptimizedField data={data} onChange={handleVectorOptimizedChange} />
        </Accordion.Item>
      </Accordion>
      {!isNullish(data.size) && (
        <div className="m-4 flex flex-col gap-4">
          {!data.size.isTrial ? (
            <>
              {isPAYG(data.tier, project.billingMethod) ? (
                <Banner usage="inline">
                  <Typography variant="body-large" as="div" className="flex flex-col gap-4">
                    This instance costs {formatDollars(data.size.costPerHour)} per hour. Continuous running for 1 month
                    will cost {formatDollars(costPerMonth)} based on a 730 hour average month.
                    {showRegionalSupportTerms && (
                      <>
                        <div>
                          During the Subscription Term, Neo4j will provide Support Services - Support Level
                          &quot;Regional&quot; - according to current terms at{' '}
                          <TextLink href="http://www.neo4j.com/support-terms/" isExternalLink>
                            Neo4j Product &amp; Support Terms
                          </TextLink>{' '}
                          and Services availability according to the{' '}
                          <TextLink href="https://neo4j.com/terms/sla/aurabc/" isExternalLink>
                            Neo4j Aura Service Level
                          </TextLink>
                          .
                        </div>
                        <div>
                          Neo4j may modify Support Services and availability Service Levels provided no such
                          modification shall result in a material reduction in Support Services or Services availability
                          during the Subscription Term.
                        </div>
                      </>
                    )}
                  </Typography>
                  {project.projectType === PROJECT_TYPE.N4GCP && (
                    <>
                      Billing will be applied to your Google Project{' '}
                      <TextLink
                        href={`https://console.cloud.google.com/home/dashboard?project=${project.googleProjectId}`}
                        isExternalLink
                      >
                        {project.googleProjectId}
                      </TextLink>
                      .
                    </>
                  )}
                  {project.projectType === PROJECT_TYPE.MARKETPLACE_AZURE && (
                    <>Billing will be applied to your Azure subscription/resource group.</>
                  )}
                  {project.projectType === PROJECT_TYPE.MARKETPLACE_AWS && (
                    <>Billing will be applied to your AWS Marketplace account.</>
                  )}
                </Banner>
              ) : (
                <Banner
                  className="font-light"
                  hasIcon
                  usage="inline"
                  htmlAttributes={{
                    'data-testid': 'custom-pricing',
                  }}
                >
                  Please refer to your contract for pricing.
                </Banner>
              )}
            </>
          ) : (
            <Banner usage="inline">
              Your 14 day trial of Aura Professional will begin after you create an instance.
            </Banner>
          )}
          <Checkbox
            isChecked={data.confirmed}
            onChange={(event) => handleConfirmedChange(event.target.checked)}
            key="confirm-checkbox"
            label="I accept"
          />
        </div>
      )}
    </div>
  );
};

interface CreateInstanceWrapperProps {
  data: InstanceFormData;
  onDataChange: (data: InstanceFormData) => void;
  tierConfig?: TierConfig;
  validation: Validation<InstanceFormData> | null;
  project: Project;
  instance?: Instance;
  onProductChange?: (product: Product) => void;
  product?: Product;
}

const CreateInstanceFormWrapper = ({ tierConfig, ...rest }: CreateInstanceWrapperProps) => {
  if (isNullish(tierConfig)) {
    return (
      <Banner
        description="There is something wrong with the configuration of your project. Reload the page and if the error persists contact customer support."
        type="danger"
        className="mt-4"
        usage="inline"
      />
    );
  }
  return <CreateInstanceForm tierConfig={tierConfig} {...rest} />;
};

export default CreateInstanceFormWrapper;
