import { Button, Dialog, TextInput } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { type LocalInstance, useUninstallLocalInstanceMutation } from '@nx/state';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';

const logger = createLogger(APP_SCOPE.framework);

interface Props {
  onClose: () => void;
  onConfirm: () => void;
  instance: LocalInstance;
}

export const DeleteInstanceModal = ({ onClose, onConfirm, instance }: Props) => {
  const [confirmText, setConfirmText] = useState('');
  const [uninstall, uninstallResponse] = useUninstallLocalInstanceMutation();
  const { name } = instance;

  const handleConfirmTextChange = (value: string) => {
    setConfirmText(value);
  };

  const handleClose = () => {
    onClose();
    setConfirmText('');
  };

  const handleDelete = (event: SyntheticEvent) => {
    event.preventDefault();
    uninstall(instance.id)
      .unwrap()
      .then(() => {
        onConfirm();
      })
      .catch((error) => logger.error(error));
  };

  return (
    <Dialog type="danger" isOpen onClose={handleClose} size="large">
      <form onSubmit={handleDelete}>
        <Dialog.Header>Delete {name}</Dialog.Header>
        <Dialog.Subtitle>This action is irreversible. We will delete your instance.</Dialog.Subtitle>
        <Dialog.Description>
          <TextInput
            value={confirmText}
            onChange={(e) => handleConfirmTextChange(e.target.value)}
            label="Type the name of the instance below to confirm"
            htmlAttributes={{
              autoFocus: true,
              placeholder: 'E.g. InstanceName',
            }}
          />
        </Dialog.Description>
        <Dialog.Actions>
          <Button color="neutral" fill="outlined" onClick={handleClose} isDisabled={uninstallResponse.isLoading}>
            Cancel
          </Button>
          <Button
            color="danger"
            type="submit"
            isDisabled={confirmText !== name}
            isLoading={uninstallResponse.isLoading}
          >
            Delete
          </Button>
        </Dialog.Actions>
      </form>
    </Dialog>
  );
};
