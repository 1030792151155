import type { PaginationInput } from '@nx/state';
import type { OnChangeFn, PaginationState } from '@tanstack/react-table';
import { functionalUpdate } from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';

const DEFAULT_PAGE_INDEX = 0;
const DEFAULT_PAGE_SIZE = 10;

const DEFAULT_PAGINATION: PaginationState = {
  pageIndex: DEFAULT_PAGE_INDEX,
  pageSize: DEFAULT_PAGE_SIZE,
};

export type PaginationHook = {
  controlledPagination: PaginationState;
  logsPagination: PaginationInput;
  onPaginationChange: OnChangeFn<PaginationState>;
};

export const usePagination = (initialPagination = DEFAULT_PAGINATION): PaginationHook => {
  const [controlledPagination, setControlledPagination] = useState(initialPagination);

  const logsPagination = useMemo<PaginationInput>(
    () => ({
      page: controlledPagination.pageIndex,
      perPage: controlledPagination.pageSize,
    }),
    [controlledPagination.pageIndex, controlledPagination.pageSize],
  );

  const onPaginationChange: OnChangeFn<PaginationState> = useCallback(
    (updater) => {
      const newPagination = functionalUpdate(updater, controlledPagination);
      setControlledPagination(newPagination);
    },
    [controlledPagination],
  );

  return { controlledPagination, logsPagination, onPaginationChange };
};
