import { Typography } from '@neo4j-ndl/react';
import { ExclamationCircleIconSolid } from '@neo4j-ndl/react/icons';

export const FormErrorText = ({ errorText }: { errorText: string }) => {
  return (
    <div className="text-palette-danger-text flex items-center gap-1">
      <div className="ndl-error-icon size-5">
        <ExclamationCircleIconSolid />
      </div>
      <Typography className="ndl-error-text" variant="body-small">
        {errorText}
      </Typography>
    </div>
  );
};
