import { nanoid } from '@reduxjs/toolkit';

import {
  type QuerySavedCypherFolder,
  type QuerySavedCypherItem,
  generatedSavedCypherApi,
} from './generated-saved-cypher-api';

const transformResponse = <T extends QuerySavedCypherFolder | QuerySavedCypherItem>(response: T[]) => {
  return response.map((item) => {
    if ('query' in item) {
      return { ...item, parentId: item.parent?.id ?? null };
    }

    return { ...item, parentId: item.parent?.id ?? null };
  });
};

const savedCypherApi = generatedSavedCypherApi.enhanceEndpoints({
  addTagTypes: ['SavedCypher'],
  endpoints: {
    getSavedCyphers: {
      providesTags: (result, _, __) => {
        return result
          ? [...result.map(({ id }) => ({ type: 'SavedCypher' as const, id })), 'SavedCypher']
          : ['SavedCypher'];
      },
      transformResponse,
    },
    updateSavedCypher: {
      onQueryStarted: async ({ updateQuerySavedCypherBody }, { dispatch, queryFulfilled }) => {
        const result = dispatch(
          savedCypherApi.util.updateQueryData('getSavedCyphers', undefined, (draft) => {
            updateQuerySavedCypherBody.forEach((item) => {
              const index = draft.findIndex((d) => d.id === item.id);
              const updatedItem = {
                id: item.id,
                name: item.name ?? '',
                parent: item.parentId !== null ? { id: item.parentId ?? '' } : null,
                ...('query' in item && { query: item.query ?? '' }),
                order: item.order ?? 0,
              };

              if (index !== -1) {
                draft[index] = updatedItem;
              } else {
                draft.push(updatedItem);
              }
            });
          }),
        );

        try {
          await queryFulfilled;
          dispatch(savedCypherApi.util.invalidateTags(['SavedCypher']));
        } catch {
          result.undo();
        }
      },
    },
    createSavedCypherItem: {
      onQueryStarted: async ({ createQuerySavedCypherItemBody }, { dispatch, queryFulfilled }) => {
        const result = dispatch(
          savedCypherApi.util.updateQueryData('getSavedCyphers', undefined, (draft) => {
            draft.push({
              id: nanoid(),
              name: createQuerySavedCypherItemBody.name,
              query: createQuerySavedCypherItemBody.query,
              parent:
                createQuerySavedCypherItemBody.parentId !== null
                  ? { id: createQuerySavedCypherItemBody.parentId }
                  : null,
              order: createQuerySavedCypherItemBody.order,
            });
          }),
        );

        try {
          await queryFulfilled;
          dispatch(savedCypherApi.util.invalidateTags(['SavedCypher']));
        } catch {
          result.undo();
        }
      },
    },
    createSavedCypherFolder: {
      onQueryStarted: async ({ createSavedCypherFolderBody }, { dispatch, queryFulfilled }) => {
        const result = dispatch(
          savedCypherApi.util.updateQueryData('getSavedCyphers', undefined, (draft) => {
            draft.push({
              id: nanoid(),
              name: createSavedCypherFolderBody.name,
              parent:
                createSavedCypherFolderBody.parentId !== null ? { id: createSavedCypherFolderBody.parentId } : null,
              order: createSavedCypherFolderBody.order,
            });
          }),
        );

        try {
          await queryFulfilled;
          dispatch(savedCypherApi.util.invalidateTags(['SavedCypher']));
        } catch {
          result.undo();
        }
      },
    },
    patchSavedCypherItemById: {
      onQueryStarted: async ({ id, updateQuerySavedCypherItemBody }, { dispatch, queryFulfilled }) => {
        const result = dispatch(
          savedCypherApi.util.updateQueryData('getSavedCyphers', undefined, (draft) => {
            const index = draft.findIndex((d) => d.id === id);
            if (index !== -1) {
              const cypherItem = draft[index] ?? {};
              Object.assign(cypherItem, updateQuerySavedCypherItemBody);
            }
          }),
        );

        try {
          await queryFulfilled;
          dispatch(savedCypherApi.util.invalidateTags([{ type: 'SavedCypher', id }]));
        } catch {
          result.undo();
        }
      },
    },
    patchSavedCypherFolderById: {
      onQueryStarted: async ({ id, updateSavedCypherFolderBody }, { dispatch, queryFulfilled }) => {
        const result = dispatch(
          savedCypherApi.util.updateQueryData('getSavedCyphers', undefined, (draft) => {
            const index = draft.findIndex((d) => d.id === id);
            if (index !== -1) {
              const cypherFolder = draft[index] ?? {};
              Object.assign(cypherFolder, updateSavedCypherFolderBody);
            }
          }),
        );

        try {
          await queryFulfilled;
          dispatch(savedCypherApi.util.invalidateTags([{ type: 'SavedCypher', id }]));
        } catch {
          result.undo();
        }
      },
    },
    deleteSavedCyphers: {
      onQueryStarted: async ({ deleteSavedCyphersBody }, { dispatch, queryFulfilled }) => {
        const result = dispatch(
          savedCypherApi.util.updateQueryData('getSavedCyphers', undefined, (draft) => {
            deleteSavedCyphersBody.ids.forEach((id) => {
              const index = draft.findIndex((d) => d.id === id);
              if (index !== -1) {
                draft.splice(index, 1);
              }
            });
          }),
        );

        try {
          await queryFulfilled;
          dispatch(savedCypherApi.util.invalidateTags(['SavedCypher']));
        } catch {
          result.undo();
        }
      },
    },
  },
});

export const {
  useGetSavedCyphersQuery,
  useUpdateSavedCypherMutation,
  useCreateSavedCypherItemMutation,
  useCreateSavedCypherFolderMutation,
  usePatchSavedCypherItemByIdMutation,
  usePatchSavedCypherFolderByIdMutation,
  useDeleteSavedCyphersMutation,
} = savedCypherApi;
