import { ChevronRightIconOutline } from '@neo4j-ndl/react/icons';
import cx from 'classnames';
import { useState } from 'react';

const ShowHide = ({ active = true, children }: React.PropsWithChildren & { active?: boolean }) => {
  const [visible, setVisible] = useState(false);

  if (!active) {
    return null;
  }

  return (
    <div className="mt-4">
      <span
        className="text-palette-primary-text flex cursor-pointer items-center whitespace-nowrap"
        role="button"
        aria-controls="error-message"
        aria-expanded={visible}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setVisible(!visible);
          }
        }}
        onClick={() => setVisible(!visible)}
        tabIndex={0}
      >
        <ChevronRightIconOutline className={cx('mr-1 h-4 w-4 transition-all', { 'rotate-90': visible })} /> Error
        details
      </span>
      <div
        className={cx('mt-4', {
          hidden: !visible,
        })}
        id="error-message"
        role="region"
        tabIndex={-1}
      >
        {children}
      </div>
    </div>
  );
};

export default ShowHide;
