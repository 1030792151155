import type { GuidePlaylistStep } from '@neo4j-devtools/workspace-guides';
import * as Icons from '@neo4j-ndl/react/icons';
import { APP_SCOPE, QUERY_TYPE } from '@nx/constants';
import type { GuideWithStatus } from '@nx/state';
import {
  changePlaylist,
  selectCurrentPlaylist,
  useAppSelector,
  useCypherQuery,
  useGetManifestQuery,
  useGetPlaylistQuery,
  useGuides,
  useNetworkPolicies,
} from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import { useMemo } from 'react';

import { type GuideMetadataProps } from './types';

type IconName = keyof typeof Icons;

const calculateGuideProgress = (state: GuideWithStatus | undefined) => {
  return isNullish(state) ? 0 : Math.floor((state.currentPage / (state.totalPages - 1)) * 100);
};

export const findRecommended = (metadata: GuideMetadataProps[]) => {
  const firstIncompleteGuide = metadata.findIndex((guide) => guide.progress === undefined || guide.progress < 100);

  return metadata[firstIncompleteGuide]?.id ?? null;
};

export const hasGuideInProgress = (metadata: GuideMetadataProps[]) => {
  return metadata.some((guide) => !isNullish(guide.progress) && guide.progress > 0 && guide.progress < 100);
};

export const useGuideWarning = (paused: boolean) => {
  const [cypherQuery] = useCypherQuery(
    { query: 'MATCH (n) RETURN count(n) LIMIT 1' },
    {
      metadata: { appScope: APP_SCOPE.framework, queryType: QUERY_TYPE.System },
      paused,
      transactionMode: 'read',
    },
  );

  if (cypherQuery.fetching || cypherQuery.records === undefined) {
    return false;
  }

  return Number(cypherQuery.records[0]?.get(0)) > 0;
};

export const getDialogHeader = (fallback: string, metadata: GuideMetadataProps[]) => {
  for (const guide of metadata) {
    // Prioritize guide completion message over an in progress message.
    if (guide.progress === 100) {
      return `Congratulations! You completed ${guide.title} guide!`;
    }
    if (!isNullish(guide.progress) && guide.progress > 0) {
      return 'Continue your Neo4j guide';
    }
  }

  return fallback;
};

function isIconName(name: unknown): name is IconName {
  if (typeof name !== 'string') {
    return false;
  }

  if (name in Icons) {
    return true;
  }

  return false;
}

function toGuideCardProps(
  step: GuidePlaylistStep & { iconName?: string },
  guides: Partial<Record<string, GuideWithStatus>>,
): GuideMetadataProps {
  return {
    id: step.id,
    description: step.description,
    duration: step.duration,
    icon: isIconName(step.iconName) ? step.iconName : undefined,
    iconActive: step.iconActive,
    iconDefault: step.iconDefault,
    thumbnail: step.thumbnail,
    label: step.label,
    type: step.type,
    progress: calculateGuideProgress(isNotNullish(step.id) ? guides[step.id] : undefined),
    title: step.title,
    comingSoon: step.comingSoon,
  };
}

export function useManifest() {
  const networkPolicies = useNetworkPolicies();

  const remoteManifest = useGetManifestQuery(undefined, {
    skip: !networkPolicies.outgoingConnectionsAllowed,
  });

  return remoteManifest;
}

export function usePlaylist() {
  const networkPolicies = useNetworkPolicies();
  const playlistId = useAppSelector(selectCurrentPlaylist);
  const guides = useGuides();

  const remotePlaylist = useGetPlaylistQuery(playlistId, {
    skip: !networkPolicies.outgoingConnectionsAllowed,
  });

  const data = useMemo(() => {
    if (remotePlaylist.data === undefined) {
      return undefined;
    }

    return {
      ...remotePlaylist.data,
      steps: remotePlaylist.data.steps.map((step) => toGuideCardProps(step, guides)),
    };
  }, [guides, remotePlaylist.data]);

  return {
    id: playlistId,
    data,
    error: remotePlaylist.error,
    isFetching: remotePlaylist.isFetching,
    isError: remotePlaylist.isError,
    changePlaylist,
  };
}
