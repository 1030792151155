import { useEffect, useRef } from 'react';

import { useAppSelector } from '../../../../state/context';
import type { TransformedPerspectivePropertyKeys } from '../../../../types/perspective';
import {
  getHiddenRelationshipTypes,
  getVisibleRelationshipTypes,
  selectCategoriesWithoutExcludedProperties,
  selectCategoryPropertyKeys,
  selectPropertyKeysForVisibleRelationshipTypes,
} from '../../../perspectives/perspectives';
import type {
  Partition,
  PathEntryVerificationResult,
  SinglePathVerificationResult,
} from './structured-suggestions.types';

export const useVerificationResultsCache = () => {
  //  added as useEffect dependency to fix cache not clearing when hidden relationships are updated
  const hiddenRelationshipTypes = useAppSelector(getHiddenRelationshipTypes);

  const categories = useAppSelector(selectCategoriesWithoutExcludedProperties);
  const relationshipTypes = useAppSelector(getVisibleRelationshipTypes);
  const relationshipPropertyKeys = useAppSelector(selectPropertyKeysForVisibleRelationshipTypes);
  const labelPropertyKeys: TransformedPerspectivePropertyKeys['labels'] = useAppSelector(selectCategoryPropertyKeys);

  // pathEntry -> multiple node results or a single relationship result
  const pathEntryVerificationsCacheRef = useRef(new Map<string, PathEntryVerificationResult>());
  // path -> multiple verified path
  const pathVerificationResultsCacheRef = useRef(new Map<string, SinglePathVerificationResult[]>());

  // clear cache when perspective updates
  useEffect(() => {
    pathEntryVerificationsCacheRef.current.clear();
    pathVerificationResultsCacheRef.current.clear();
  }, [categories, relationshipTypes, relationshipPropertyKeys, labelPropertyKeys, hiddenRelationshipTypes]);

  return {
    pathEntryVerificationResultsCache: pathEntryVerificationsCacheRef.current,
    pathVerificationResultsCache: pathVerificationResultsCacheRef.current,
  };
};

export const getKeyFromPartition = (partition: Partition) => `${partition.value.join(' ')}+${partition.type}`;

export const getCacheKeyFromPath = (path: Partition[]) => path.map(getKeyFromPartition).join('---');
