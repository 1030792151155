import { tokens } from '@neo4j-ndl/base';
import { DataGridComponents, IconButton, Label, Menu, Tooltip } from '@neo4j-ndl/react';
import { ChevronDownIconSolid, EllipsisHorizontalIconOutline, TrashIconOutline } from '@neo4j-ndl/react/icons';
import type { OrganizationMember, Project, ProjectSummary } from '@nx/state';
import {
  ACTION,
  INVITE_STATUS,
  ORGANIZATION_ROLE_NAME,
  consoleApi,
  getApiError,
  getErrorMessage,
  LEGACY_setActiveOrg as setActiveOrg,
  LEGACY_setActiveProject as setActiveProject,
  LEGACY_setUserHomePath as setUserHomePath,
  useActiveOrg,
  usePermissions,
} from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import { DangerConfirmModal, PermissionTooltip } from '@nx/ui';
import type { SerializedError } from '@reduxjs/toolkit';
import { type FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/query';
import type { CellContext } from '@tanstack/react-table';
import cn from 'classnames';
import { formatDistanceToNowStrict } from 'date-fns';
import React, { type ComponentProps, type PropsWithChildren, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useProjectUsers } from '../contexts';
import { ROLE_TYPE } from '../entities/model';
import { EditOrgMemberRoleModal } from './modal/edit-org-role';
import { EditProjectMemberRoleModal } from './modal/edit-project-role';
import type { CommonUserRow, UserRow } from './types';

export const ExpandableBodyRow: typeof DataGridComponents.BodyRow = (props) => {
  const innerProps: typeof props.innerProps = {
    ...props.innerProps,
    ...(props.row.getCanExpand()
      ? {
          className: 'hover:cursor-pointer',
          onClick: props.row.getToggleExpandedHandler(),
        }
      : null),
  };

  return <DataGridComponents.BodyRow {...props} innerProps={innerProps} />;
};

export const RoleLabel = ({ roleType = ROLE_TYPE.ORG, children }: PropsWithChildren<{ roleType: ROLE_TYPE }>) => {
  let styleProps: Pick<ComponentProps<typeof Label>, 'color' | 'fill' | 'className'>;
  switch (roleType) {
    case ROLE_TYPE.PROJECT:
      styleProps = { color: 'default', fill: 'semi-filled' };
      break;
    case ROLE_TYPE.DB:
      styleProps = {
        color: 'default',
        fill: 'semi-filled',
        className: cn('text-neutral-text-default !rounded-sm !border-none font-normal'),
      };
      break;
    case ROLE_TYPE.ORG:
    default:
      styleProps = { color: 'default', fill: 'filled' };
  }

  return (
    <Label {...styleProps} className={cn('overflow-auto normal-case', styleProps.className)}>
      {children}
    </Label>
  );
};

export const LastActive = ({ user }: { user: CommonUserRow | undefined }) => {
  if (!user) {
    return null;
  }

  if (user.inviteStatus === INVITE_STATUS.PENDING) {
    return '<invitation pending>';
  }

  return user.lastActive && formatDistanceToNowStrict(new Date(user.lastActive), { addSuffix: true });
};

export const UserRowAction = <T, U>({ user, cx }: { user: CommonUserRow | undefined; cx: CellContext<T, U> }) => {
  const { revokeInvite } = useProjectUsers();

  if (!user || cx.row.getCanExpand()) {
    return null;
  }

  if (user.inviteStatus === INVITE_STATUS.PENDING) {
    /* TODO @team-nx add aria label*/
    return (
      <IconButton ariaLabel="Delete" isClean onClick={() => revokeInvite(user.email)}>
        <TrashIconOutline />
      </IconButton>
    );
  }

  return <DataGridComponents.RowActionCell cell={cx} />;
};

export const ExpandableCell = <TData, TValue>({
  cx,
  children,
}: PropsWithChildren<{ cx: CellContext<TData, TValue> }>) => (
  <>
    {cx.row.getCanExpand() ? (
      <div className="flex items-center gap-2">
        <ChevronDownIconSolid
          className="size-4"
          style={{
            transform: cx.row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(0deg)',
            transitionDuration: tokens.transitions.values.duration.quick,
          }}
        />
        {children}
      </div>
    ) : (
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      <span>{cx.getValue() as React.ReactNode}</span>
    )}
  </>
);

export const ProjectMemberRowAction = <T, U>({
  user,
  cx,
  project,
}: {
  user: UserRow;
  cx: CellContext<T, U>;
  project: Project;
}) => {
  const { data: userDetails } = consoleApi.useGetUserDetailsQuery();
  const { data: projects = [] } = consoleApi.useListProjectsByUserQuery(
    isNotNullish(user.userId) && userDetails?.id === user.userId ? user.userId : skipToken,
  );
  const activeOrg = useActiveOrg();
  const navigate = useNavigate();
  const { isAllowed } = usePermissions();
  const canEditMember = isAllowed(ACTION.UPDATE, `namespaces/${user.projectId}/members/${user.userId}`);
  const canDeleteMember = isAllowed(ACTION.DELETE, `namespaces/${user.projectId}/members/${user.userId}`);
  const [removeProjectMember, request] = consoleApi.useRemoveUserFromProjectMutation();
  const [openMenu, setOpenMenu] = useState(false);
  const [openRemoveUserModal, setOpenRemoveUserModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [openEditRoleModal, setOpenEditRoleModal] = useState(false);
  const actionsButtonRef = useRef<HTMLButtonElement>(null);

  const handleRemoveProjectMember = () => {
    setErrorMessage(null);
    if (isNotNullish(user.userId)) {
      const params: { userId: string; projectId: string; organizationId?: string } = {
        userId: user.userId,
        projectId: user.projectId,
      };
      // We need the org parameter to invalidate the list organization endpoint but we only need to do this when the
      // user being removed is the user initiating the removal. So if the user removes themselves we refetch the orgs
      // to keep it up to date.
      if (userDetails?.id === user.userId) {
        params.organizationId = activeOrg.id;
      }
      removeProjectMember(params)
        .unwrap()
        .then(() => {
          if (isNotNullish(userDetails) && userDetails.id === user.userId) {
            const availableProjects = projects.filter((p) => p.id !== user.projectId);
            const samePlanTypeProjects = availableProjects.filter((p) => p.planType === userDetails.planType);
            const sameOrgProjects = samePlanTypeProjects.filter((p) => p.organizationId === activeOrg.id);
            let newProject: ProjectSummary | undefined = undefined;
            if (sameOrgProjects.length > 0) {
              newProject = sameOrgProjects[0];
            } else if (samePlanTypeProjects.length > 0) {
              newProject = samePlanTypeProjects[0];
            } else if (availableProjects.length > 0) {
              newProject = availableProjects[0];
            }
            if (isNotNullish(newProject)) {
              setActiveProject(newProject.id);
              setUserHomePath(`projects/${newProject.id}/instances`);
              setActiveOrg(newProject.organizationId);
              localStorage.setItem(`default-project-${userDetails.id}`, newProject.id);
              navigate(`org/${newProject.organizationId}/projects`);
            }
          }
          setOpenRemoveUserModal(false);
        })
        .catch((err: FetchBaseQueryError | SerializedError | undefined) => {
          const apiError = getApiError(err);
          const message = getErrorMessage(apiError);
          setErrorMessage(message);
        });
    }
  };

  const removeUserDialogTitle =
    user.email === userDetails?.email
      ? `Remove yourself from project ${project.name}`
      : `Remove ${user.email} from project`;
  const removeUserDialogMessage =
    user.email === userDetails?.email ? (
      <>
        You are about to remove yourself from this project. This action is irreversible and you will need to receive an
        invitation to access the project again.
      </>
    ) : (
      <>
        You are about to remove <i>{user.email}</i> from this project. This action is irreversible and you will need to
        re-invite the user to the project.
      </>
    );

  return (
    <>
      {(canEditMember || canDeleteMember) && (
        <div className="flex flex-grow justify-end">
          <IconButton
            onClick={() => setOpenMenu(true)}
            isLoading={request.isLoading}
            isClean
            ref={actionsButtonRef}
            ariaLabel={`Open menu for ${user.email}`}
          >
            <EllipsisHorizontalIconOutline />
          </IconButton>
        </div>
      )}
      <Menu isOpen={openMenu} onClose={() => setOpenMenu(false)} anchorRef={actionsButtonRef}>
        <Menu.Items>
          {canEditMember && (
            <>
              <Menu.Item
                title="Edit"
                onClick={() => {
                  setOpenEditRoleModal(true);
                  setOpenMenu(false);
                }}
              />
              <Menu.Divider />
            </>
          )}

          <Menu.Item
            title={
              <PermissionTooltip hasPermission={canDeleteMember} hasButtonWrapper>
                {userDetails?.id === user.userId ? 'Leave' : 'Remove'}
              </PermissionTooltip>
            }
            className="text-danger-text"
            isDisabled={!canDeleteMember}
            onClick={() => {
              setOpenRemoveUserModal(true);
              setOpenMenu(false);
            }}
          />
        </Menu.Items>
      </Menu>
      {openRemoveUserModal && (
        <DangerConfirmModal
          open={openRemoveUserModal}
          title={removeUserDialogTitle}
          content={<div>{removeUserDialogMessage}</div>}
          onConfirm={handleRemoveProjectMember}
          loading={request.isLoading}
          onClose={() => setOpenRemoveUserModal(false)}
          error={errorMessage}
          confirmButtonText="Remove"
        />
      )}
      {openEditRoleModal && (
        <EditProjectMemberRoleModal
          open={openEditRoleModal}
          onClose={() => setOpenEditRoleModal(false)}
          onConfirm={() => setOpenEditRoleModal(false)}
          user={user}
        />
      )}
    </>
  );
};

export const ProjectInviteRowAction = <T, U>({ user, cx }: { user: UserRow; cx: CellContext<T, U> }) => {
  const [deleteProjectInvite, request] = consoleApi.useDeleteProjectInviteMutation();
  const [openDeleteInviteModal, setOpenDeleteInviteModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleInviteDelete = () => {
    setErrorMessage(null);
    if (isNotNullish(user.inviteId)) {
      deleteProjectInvite({ inviteId: user.inviteId, projectId: user.projectId })
        .unwrap()
        .then(() => setOpenDeleteInviteModal(false))
        .catch((err: FetchBaseQueryError | SerializedError | undefined) => {
          const apiError = getApiError(err);
          const message = getErrorMessage(apiError);
          setErrorMessage(message);
        });
    }
  };

  return (
    <>
      <div className="flex flex-grow justify-end">
        <IconButton
          onClick={() => setOpenDeleteInviteModal(true)}
          isLoading={request.isLoading}
          isClean
          ariaLabel={`Delete invite for ${user.email}`}
        >
          <TrashIconOutline />
        </IconButton>
      </div>
      {openDeleteInviteModal && (
        <DangerConfirmModal
          open={openDeleteInviteModal}
          title={`Delete invite to ${user.email}`}
          content={
            <div>
              You are about to delete the invite to <i>{user.email}</i>.
            </div>
          }
          loading={request.isLoading}
          onConfirm={handleInviteDelete}
          onClose={() => setOpenDeleteInviteModal(false)}
          error={errorMessage}
          confirmButtonText="Delete"
        />
      )}
    </>
  );
};

export const OrgMemberRowAction = <T, U>({ orgUser, cx }: { orgUser: OrganizationMember; cx: CellContext<T, U> }) => {
  const { isAllowed } = usePermissions();
  const canEditMember = isAllowed(ACTION.UPDATE, `organizations/${orgUser.organizationId}/members/${orgUser.id}`);
  const canDeleteOwnerRoles = isAllowed(ACTION.DELETE, `organizations/${orgUser.organizationId}/owner-roles`);
  const canCreateOwnerRoles = isAllowed(ACTION.CREATE, `organizations/${orgUser.organizationId}/owner-roles`);
  const orgUserIsOwner = orgUser.roles.find((role) => role.name === ORGANIZATION_ROLE_NAME.ORG_OWNER);
  const [openMenu, setOpenMenu] = useState(false);
  const [openEditRoleModal, setOpenEditRoleModal] = useState(false);
  const actionsButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      {canEditMember && (
        <div className="flex flex-grow justify-end">
          <Tooltip type="simple" isDisabled={canDeleteOwnerRoles || isNullish(orgUserIsOwner)}>
            <Tooltip.Trigger hasButtonWrapper>
              <IconButton
                onClick={() => setOpenMenu(true)}
                isClean
                ref={actionsButtonRef}
                ariaLabel="Open menu"
                isDisabled={!canDeleteOwnerRoles && isNotNullish(orgUserIsOwner)}
              >
                <EllipsisHorizontalIconOutline />
              </IconButton>
            </Tooltip.Trigger>
            <Tooltip.Content>You cannot edit the role of an owner.</Tooltip.Content>
          </Tooltip>
        </div>
      )}
      <Menu isOpen={openMenu} onClose={() => setOpenMenu(false)} anchorRef={actionsButtonRef}>
        <Menu.Items>
          <Menu.Item
            title="Edit"
            onClick={() => {
              setOpenEditRoleModal(true);
              setOpenMenu(false);
            }}
          />
        </Menu.Items>
      </Menu>
      {openEditRoleModal && (
        <EditOrgMemberRoleModal
          hasOwnerPrivileges={canCreateOwnerRoles}
          onClose={() => setOpenEditRoleModal(false)}
          onConfirm={() => setOpenEditRoleModal(false)}
          user={orgUser}
        />
      )}
    </>
  );
};
