import { Accordion, Button, Tooltip } from '@neo4j-ndl/react';
import type { MetricsResult, TimePeriod, TimeRange } from '@nx/state';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { TimelineCard, type TimelineCardProps } from '../../metrics/charts/line-cards';
import timelineChartConfigs from '../../metrics/charts/timeline-charts';
import type { ChartContext, MultiChartProps } from '../../metrics/charts/types';
import { TimePeriodAndRefresh } from '../../metrics/shared/components/time-window-selector';
import { datesWithin24H } from '../shared/utils';

interface QueryTimelineProps {
  metricsResult: MetricsResult;
  setIsRequestDialogOpen: (a: boolean) => void;
  chartContext: ChartContext;
  setTimePeriod: (t: TimePeriod | undefined) => void;
  zoomIn: (t: TimeRange | null) => void;
  refreshTimeRange: () => void;
  setExpandedChart: (m: MultiChartProps | null) => void;
  setHasChangedSelection: (a: boolean) => void;
  hasChangedSelection: boolean;
  hasQueries: boolean;
  setHasQueries: (a: boolean) => void;
}

const latencyChartProps = timelineChartConfigs.find((p) => p.subtitle === 'Latency');

const pills: TimelineCardProps['pills'] = [
  {
    id: 'Count',
    name: 'Queries per minute',
    displayName: 'Queries per minute',
  },
  {
    id: 'Failure Count',
    name: 'Failed queries per minute',
    displayName: 'Failed queries per minute',
  },
  {
    id: 'Latency',
    name: 'Query latency',
    displayName: 'Query latency (99th percentile)',
  },
];

export const QueryTimelineCard = ({
  metricsResult,
  chartContext,
  setTimePeriod,
  zoomIn,
  refreshTimeRange,
  setIsRequestDialogOpen,
  setHasChangedSelection,
  hasChangedSelection,
  hasQueries,
  setHasQueries,
}: QueryTimelineProps) => {
  const [showTimeLine, setShowTimeLine] = useState(true);
  const [selectedTimelineAccordionItems, setSelectedTimelineAccordionItems] = useState(['timelineChart']);

  const onZoom = useCallback(
    (timeRange: TimeRange | null = null) => {
      zoomIn(timeRange);
    },
    [zoomIn],
  );

  const onRefresh = useCallback(() => {
    refreshTimeRange();
    setHasChangedSelection(true);
    if (!showTimeLine) {
      setShowTimeLine(true);
    }
  }, [refreshTimeRange, setHasChangedSelection, showTimeLine]);

  const onTimePeriodChange = useCallback(
    (timePeriod: TimePeriod | undefined) => {
      setTimePeriod(timePeriod);
      setHasChangedSelection(!hasChangedSelection);
      if (!showTimeLine) {
        setShowTimeLine(true);
      }
    },
    [hasChangedSelection, setHasChangedSelection, setTimePeriod, showTimeLine],
  );

  const onZoomReset = useCallback(() => {
    // Instantly zooms out since timeRangeDerived metrics are cached
    zoomIn(null);
  }, [zoomIn]);

  const datesTooFarApart = useMemo(
    () => !datesWithin24H(chartContext.timeRange.startTime, chartContext.timeRange.endTime),
    [chartContext.timeRange],
  );

  useEffect(() => {
    setHasQueries(hasQueries);
  }, [hasQueries, setHasQueries]);

  const hasToolTooltip = datesTooFarApart || !hasChangedSelection;

  return (
    <div className="timeline m-4 flex flex-col" aria-label="Query timeline">
      <div className="bg-palette-neutral-bg-weak w-full rounded-3xl">
        <Accordion
          className="relative p-1"
          expandedItemIds={selectedTimelineAccordionItems}
          isMultiple
          onChange={(expandedItemIds) => setSelectedTimelineAccordionItems(expandedItemIds)}
        >
          <div className="z-10 flex justify-end">
            <div className="absolute m-1.5 flex flex-row flex-wrap items-center gap-2">
              <TimePeriodAndRefresh
                timePeriod={chartContext.timePeriod}
                timeRange={chartContext.timeRange}
                onTimePeriodChange={onTimePeriodChange}
                onRefresh={onRefresh}
                warningText={
                  datesTooFarApart
                    ? "At most 24 hours' worth of logs can be fetched. Drag in the chart to narrow time window."
                    : undefined
                }
              />
              <Tooltip type="simple" isDisabled={!hasToolTooltip}>
                <Tooltip.Trigger hasButtonWrapper>
                  <Button
                    size="medium"
                    color="primary"
                    isDisabled={datesTooFarApart}
                    onClick={() => setIsRequestDialogOpen(true)}
                    htmlAttributes={{
                      'data-testid': 'request-log-start',
                    }}
                  >
                    Fetch logs
                  </Button>
                </Tooltip.Trigger>
                <Tooltip.Content>
                  {datesTooFarApart ? (
                    <>At most 24 hours&apos; worth of logs can be requested</>
                  ) : (
                    !hasChangedSelection && 'Current selection already requested'
                  )}
                </Tooltip.Content>
              </Tooltip>
            </div>
          </div>
          <Accordion.Item
            itemId="timelineChart"
            // these classes are defined in migration-assistant-page.css, imported in migration-assistant-page.tsx
            className="timeline-chart-container accordion-item-no-padding borderless-accordion accordion-hover-rounded-3xl !border-b-0"
            title="Query timeline"
          >
            <TimelineCard
              key="timeline-chart"
              chartConfigs={timelineChartConfigs}
              mainChartConfigIdx={0}
              context={chartContext}
              metricsResult={metricsResult}
              pills={pills}
              onZoom={onZoom}
              onZoomReset={onZoomReset}
              builderModifier={(builder, data) => {
                const latencyData = data.dataSeries?.[latencyChartProps?.subtitle ?? ''];
                if (latencyData && latencyChartProps) {
                  builder.addExtraAxis({ label: latencyChartProps.yAxisLabel });
                }

                builder.series.forEach((config) => {
                  if (config.label === latencyChartProps?.subtitle) {
                    config.scale = 'right';
                    // originally set because formatUplot is called with timelineCharts[0] (of type AREA)
                    delete config.fill;
                  }
                });
              }}
              timeRangePadded
            />
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};
