import { Button, Dialog, TextLink } from '@neo4j-ndl/react';
import { ExclamationCircleIconOutline } from '@neo4j-ndl/react/icons';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { consoleApi, dataScienceApi, getApiError, getErrorMessage, useActiveOrg, useNotifications } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useCallback, useMemo } from 'react';

const logger = createLogger(APP_SCOPE.framework);

interface Props {
  onClose: () => void;
}

const DisableWarningMessage = () => {
  const activeOrg = useActiveOrg();

  const getSessionsQuery = dataScienceApi.useGetSessionsQuery({ organizationId: activeOrg.id });
  const getProjectsQuery = consoleApi.useListOrganizationProjectsQuery({ organizationId: activeOrg.id });

  const projectsWithRunningSessions = useMemo(() => {
    const projects = getProjectsQuery.data;
    const sessions = getSessionsQuery.data;
    if (!projects || !sessions) {
      return [];
    }
    const sessionProjectIds = sessions.map(({ projectId }) => projectId);
    const uniqueSessionprojectIds = new Set(sessionProjectIds);
    return Array.from(uniqueSessionprojectIds)
      .map((id) => projects.find((p) => p.id === id))
      .filter((t) => t !== undefined);
  }, [getSessionsQuery.data, getProjectsQuery.data]);

  if (getSessionsQuery.isError) {
    // todo: handle error
    return null;
  }

  if (getProjectsQuery.isError) {
    // todo: handle error
    return null;
  }

  if (projectsWithRunningSessions.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-2">
      <p>There are running sessions in the following projects: </p>
      <ul>
        {projectsWithRunningSessions.map((project) => (
          <li key={project.id} className="flex gap-y-2 italic">
            <div className="flex flex-row items-center gap-x-1">
              <TextLink href={`/projects/${project.id}/sessions`} isExternalLink>
                {project.name}
              </TextLink>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const ConfirmDisableGdsSessionModal = ({ onClose }: Props) => {
  const activeOrg = useActiveOrg();
  const [updateOrg] = consoleApi.useUpdateOrganizationMutation();
  const { addNotification } = useNotifications();

  const handleDisableGdsSessions = useCallback(() => {
    updateOrg({
      organizationId: activeOrg.id,
      gdsSessionsEnabled: false,
    })
      .unwrap()
      .catch((e: FetchBaseQueryError | SerializedError) => {
        const error = getApiError(e);
        if (isNotNullish(error.message)) {
          logger.error(error.message);
        }
        addNotification({
          type: 'danger',
          title: `Failed to disable GDS Sessions`,
          description: getErrorMessage(error),
          timeout: 5000,
        });
      });
    // deliberately close before promise resolved
    onClose();
  }, [activeOrg.id, addNotification, onClose, updateOrg]);

  return (
    <Dialog isOpen onClose={onClose}>
      <div className="flex justify-items-start gap-x-6 p-2">
        <div className="py-2">
          <ExclamationCircleIconOutline strokeWidth="2" className="text-danger-border-strong h-16" />
        </div>
        <div>
          <Dialog.Header>Disable Sessions</Dialog.Header>
          <Dialog.Content>
            <div className="flex flex-col gap-y-4">
              <p>
                Once Sessions are disabled, no users will be able to create new sessions. Existing sessions will
                continue to run until they are manually terminated or have been running for 30 days in total.
              </p>
              <DisableWarningMessage />
            </div>
          </Dialog.Content>
          <Dialog.Actions>
            <Button fill="outlined" color="neutral" onClick={onClose}>
              Cancel
            </Button>
            <Button color="danger" onClick={handleDisableGdsSessions}>
              Confirm
            </Button>
          </Dialog.Actions>
        </div>
      </div>
    </Dialog>
  );
};

export const usePrefetchDisableSessionsModal = () => {
  const activeOrg = useActiveOrg();
  const prefetchProjects = consoleApi.usePrefetch('listOrganizationProjects', { ifOlderThan: 30 });
  const prefetchSessions = dataScienceApi.usePrefetch('getSessions', { ifOlderThan: 30 });
  return useCallback(() => {
    prefetchProjects({ organizationId: activeOrg.id });
    prefetchSessions({ organizationId: activeOrg.id });
  }, [activeOrg.id, prefetchProjects, prefetchSessions]);
};
