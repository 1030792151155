import type { CmiScrapeConfig, CmiStatusResponse, UpdateScrapeConfigRequest } from '../api-types';
import { type Builder, tagTypes } from './types';

export const cmiEndpoints = (builder: Builder) => ({
  getCmiStatus: builder.query<CmiStatusResponse, string>({
    query: (projectId) => ({
      url: `/cmi/status/${projectId}`,
      method: 'GET',
      headers: { 'Project-Id': projectId },
    }),
  }),
  getCmiConfig: builder.query<CmiScrapeConfig, string>({
    query: (projectId) => ({
      url: `/cmi/config/${projectId}`,
      method: 'GET',
      headers: { 'Project-Id': projectId },
    }),
    providesTags: [tagTypes[3]],
  }),
  updateCmiConfig: builder.mutation<void, UpdateScrapeConfigRequest>({
    query: (request) => ({
      url: `/cmi/config/${request.config.projectId}`,
      method: 'POST',
      headers: { 'Project-Id': request.config.projectId },
      body: request,
    }),
    invalidatesTags: [tagTypes[3]],
  }),
});
