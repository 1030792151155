import type * as Neo4jHttp from '@neo4j-labs/experimental-query-api-wrapper';
import neo4jHttpApi from '@neo4j-labs/experimental-query-api-wrapper';
import type * as Neo4jBolt from 'neo4j-driver';
import neo4jBoltApi from 'neo4j-driver';
import type * as DriverCore from 'neo4j-driver-core';

export type Neo4jBoltApi = typeof neo4jBoltApi;
export type Neo4jHttpApi = typeof neo4jHttpApi;

export type Neo4jApi = Neo4jBoltApi | Neo4jHttpApi;

export type SessionConfig = DriverCore.SessionConfig & Neo4jHttp.WrapperSessionConfig;
export type Neo4jError = Neo4jBolt.Neo4jError;

export function isNeo4jBoltApi(api: unknown): api is Neo4jBoltApi {
  return api === neo4jBoltApi;
}

export function isNeo4jHttpApi(api: unknown): api is Neo4jHttpApi {
  return api === neo4jHttpApi;
}

export { neo4jBoltApi, neo4jHttpApi };
