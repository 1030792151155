import { Button, Dialog } from '@neo4j-ndl/react';
import type { EncryptionKey } from '@nx/state';
import { CopyTextInput } from '@nx/ui';
import React from 'react';

export interface GcpCmekPermissionsInstructionsProps {
  encryptionKey: EncryptionKey;
  onClose: () => void;
}

export const GcpCmekPermissionsInstructions = ({ encryptionKey, onClose }: GcpCmekPermissionsInstructionsProps) => {
  const serviceAccounts = encryptionKey.gcpProperties?.permissions.serviceAccounts ?? [];

  return (
    <>
      <Dialog.Header>Customer Managed Key Permissions</Dialog.Header>
      <Dialog.Content className="space-y-6">
        <div>
          <p className="mb-4">
            Within GCP Key Management, grant the built-in{' '}
            <code>&quot;Cloud KMS CryptoKey Encrypter/Decrypter&quot;</code> and{' '}
            <code>&quot;Cloud KMS Viewer&quot;</code> roles to the following service accounts:
          </p>
          {serviceAccounts.map((serviceAccount: string, index: number) => {
            return <CopyTextInput value={`${serviceAccount}`} label="" key={`sa-${index}`} isPortaled={false} />;
          })}
        </div>
      </Dialog.Content>
      <Dialog.Actions className="justify-between">
        <Button fill="outlined" color="neutral" onClick={onClose}>
          Close
        </Button>
      </Dialog.Actions>
    </>
  );
};
