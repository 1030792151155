import { LoadingSpinner, Typography } from '@neo4j-ndl/react';
import cn from 'classnames';
import type { ButtonHTMLAttributes } from 'react';

type OnboardingItemButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isLoading?: boolean;
  size: 'medium' | 'large';
  isDisabled?: boolean;
};
export const OnboardingItemButton = ({
  children,
  size,
  className,
  isLoading = false,
  isDisabled = false,
  ...props
}: OnboardingItemButtonProps) => {
  const classes = cn(
    'text-neutral-text-default border-neutral-border-strong bg-neutral-bg-weak hover:bg-neutral-hover active:bg-neutral-pressed flex w-full shrink-0 items-center justify-center gap-3 rounded-sm border',
    className,
    {
      'p-4': size === 'large',
      'p-2': size === 'medium',
      'text-neutral-text-weaker': isLoading || isDisabled,
    },
  );
  return (
    <Typography
      variant={size === 'large' ? 'body-large' : 'body-medium'}
      className={classes}
      htmlAttributes={{
        ...props,
        disabled: isLoading || isDisabled,
      }}
      as="button"
    >
      {isLoading && <LoadingSpinner />}
      {children}
    </Typography>
  );
};
