import { Banner } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { type AuraDatabase, type AuraDatabaseSummary, type Instance, consoleApi, useActiveProject } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import { DataGridHelpers } from '@nx/ui';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import { InstanceStatus } from '../shared/instance-status';
import { DatabaseTableRowActions } from './database-actions';

const logger = createLogger(APP_SCOPE.aura);
const numberFormatter = new Intl.NumberFormat();

export const InstanceDatabasesList = ({ instance }: { instance: Instance }) => {
  const activeProject = useActiveProject();
  const { data, isLoading, isError } = consoleApi.useListDatabasesQuery(instance.id);

  const [getInstance] = consoleApi.useLazyGetDatabaseQuery();
  const [databaseDetails, setDatabaseDetails] = useState<AuraDatabase[] | null>(null);
  const [databaseDetailsLoading, setDatabaseDetailsLoading] = useState(false);
  const [databaseDetailsError, setDatabaseDetailsError] = useState(false);

  useEffect(() => {
    const fetchDatabaseDetails = async (instanceId: string, databaseSummaries: AuraDatabaseSummary[]) => {
      setDatabaseDetailsLoading(true);
      setDatabaseDetailsError(false);
      const promises = databaseSummaries.map((databaseSummary) =>
        getInstance({ instanceId: instance.id, id: databaseSummary.id }).unwrap(),
      );
      const details = await Promise.all(promises);
      setDatabaseDetails(details);
    };

    if (isNullish(data)) {
      return;
    }
    fetchDatabaseDetails(instance.id, data)
      .catch((err) => {
        logger.error(err);
        setDatabaseDetailsError(true);
      })
      .finally(() => setDatabaseDetailsLoading(false));
  }, [getInstance, data, instance.id]);

  const formatCount = (count?: number): string => {
    return isNotNullish(count) ? numberFormatter.format(count) : 'N/A';
  };

  const columnHelper = createColumnHelper<AuraDatabase>();
  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
        cell: (cx) => <DataGridHelpers.TruncatedColumn value={cx.getValue()} />,
        minSize: 100,
        size: 160,
        maxSize: 300,
      }),
      columnHelper.accessor('nodes', {
        header: 'Nodes',
        cell: (ctx) => formatCount(ctx.getValue()),
        maxSize: 130,
        size: 80,
        minSize: 80,
      }),
      columnHelper.accessor('relationships', {
        header: 'Relationships',
        cell: (ctx) => formatCount(ctx.getValue()),
        maxSize: 150,
        size: 140,
        minSize: 100,
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        maxSize: 200,
        size: 180,
        minSize: 100,
        // TODO: render actual Database status instead of Instance Status.
        // That will require some refactoring though.
        cell: (ctx) => <InstanceStatus instance={instance} project={activeProject} />,
      }),
      columnHelper.display({
        id: 'actions',
        cell: (ctx) => {
          const database = ctx.row.original;
          return <DatabaseTableRowActions database={database} project={activeProject} />;
        },
        minSize: 60,
        size: 70,
        maxSize: 200,
        enableResizing: false,
      }),
    ],
    [activeProject, columnHelper, instance],
  );

  const table = useReactTable({
    columns,
    data: databaseDetails ?? [],
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onChange',
    enableColumnPinning: true,
    state: {
      columnPinning: {
        right: ['actions'],
      },
    },
  });

  return (
    <>
      {isError || databaseDetailsError ? (
        <Banner
          type="danger"
          description="Something went wrong while fetching the databases. Please try again."
          usage="inline"
        />
      ) : (
        <DataGridHelpers.Wrapper>
          <DataGridHelpers.DataGridRightColumnPinned
            tableInstance={table}
            isLoading={isLoading || databaseDetailsLoading}
            isResizable={false}
            styling={{ headerStyle: 'clean' }}
            isAutoResizingColumns={true}
          />
        </DataGridHelpers.Wrapper>
      )}
    </>
  );
};
