import { Select, TextInput } from '@neo4j-ndl/react';
import { isNotNullish } from '@nx/stdlib';
import type { ChangeEvent } from 'react';
import * as yup from 'yup';

import countryList from '../../../project/billing/shared/country-list';
import type { Validation } from '../../../utils/validation';
import { validateYup } from '../../../utils/validation';
import { type ProfileInformationFormData } from './form-data';
import './form.css';
import {
  JOB_ROLE,
  USE_CASE,
  getSubdivisionOptions,
  isUnspecifiedJobRole,
  jobRoleOptions,
  requiresSubDivision,
  useCaseOptions,
} from './helpers';

export const schema: yup.ObjectSchema<ProfileInformationFormData> = yup.object({
  firstName: yup.string().required().label('First (Given) name').min(1).max(50),
  lastName: yup.string().required().label('Last (Family) name').min(1).max(50),
  jobRole: yup.string().required().label('Role'),
  jobRoleOther: yup
    .string()
    .label('Role')
    .when('jobRole', {
      is: JOB_ROLE.OtherBusiness,
      then: (outerSchema) => outerSchema.required().min(1).max(100),
    })
    .when('jobRole', {
      is: JOB_ROLE.OtherTechnical,
      then: (outerSchema) => outerSchema.required().min(1).max(100),
    }),
  primaryUseCase: yup.string().optional().label('Primary use case'),
  primaryUseCaseOther: yup
    .string()
    .optional()
    .label('Primary use case')
    .when('primaryUseCase', {
      is: USE_CASE.Other,
      then: (outerSchema) => outerSchema.min(1).max(100).required(),
    }),
  companyName: yup.string().label('Company/organization name').required().min(1).max(200),
  country: yup.string().label('Country').required(),
  subDivision: yup
    .string()
    .when('country', {
      is: 'US',
      then: (outerSchema) => outerSchema.required().label('US state'),
    })
    .when('country', {
      is: 'CA',
      then: (outerSchema) => outerSchema.required().label('Canadian province or territory'),
    }),
});

export const validate = (data: ProfileInformationFormData, { onlyRequired }: { onlyRequired?: boolean } = {}) => {
  return validateYup(schema, data, onlyRequired ?? false);
};

interface Props {
  data: ProfileInformationFormData;
  validationErrors?: Validation<ProfileInformationFormData> | null;
  setValidationErrors: (errors: Validation<ProfileInformationFormData> | null) => void;
  onChange: (data: ProfileInformationFormData) => void;
}

export const ProfileInformationForm = ({ data, validationErrors, setValidationErrors, onChange }: Props) => {
  function resetValidationError(property: keyof ProfileInformationFormData) {
    if (isNotNullish(validationErrors) && isNotNullish(validationErrors[property]?.error)) {
      let errors: Validation<ProfileInformationFormData> | null = { ...validationErrors };
      delete errors[property];
      if (Object.keys(errors).length === 0) {
        errors = null;
      }
      setValidationErrors(errors);
    }
  }

  const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    resetValidationError('firstName');
    onChange({ ...data, firstName: e.target.value });
  };
  const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    resetValidationError('lastName');
    onChange({ ...data, lastName: e.target.value });
  };
  const handleJobRoleChange = (value?: string) => {
    resetValidationError('jobRole');
    onChange({ ...data, jobRole: value });
  };
  const handleJobRoleOtherChange = (e: ChangeEvent<HTMLInputElement>) => {
    resetValidationError('jobRoleOther');
    onChange({ ...data, jobRoleOther: e.target.value });
  };
  const handlePrimaryUseCaseChange = (value?: string) => {
    resetValidationError('primaryUseCase');
    onChange({ ...data, primaryUseCase: value });
  };
  const handlePrimaryUseCaseOtherChange = (e: ChangeEvent<HTMLInputElement>) => {
    resetValidationError('primaryUseCaseOther');
    onChange({ ...data, primaryUseCaseOther: e.target.value });
  };
  const handleCompanyNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    resetValidationError('companyName');
    onChange({ ...data, companyName: e.target.value });
  };
  const handleCountryChange = (value?: string) => {
    resetValidationError('country');
    resetValidationError('subDivision');
    onChange({ ...data, country: value, subDivision: undefined });
  };
  const handleSubDivisionChange = (value?: string) => {
    resetValidationError('subDivision');
    onChange({ ...data, subDivision: value });
  };

  const subDivisionOptions: { key: string; value: string; label: string }[] =
    isNotNullish(data.country) && (data.country === 'US' || data.country === 'CA')
      ? getSubdivisionOptions(data.country)
      : [];

  return (
    <>
      <h5 className="mb-6">Set up your account</h5>
      <div className="mb-6 mt-4">
        <div className="profile-information-form">
          <div className="first-name">
            <TextInput
              isFluid
              value={data.firstName}
              onChange={handleFirstNameChange}
              errorText={validationErrors?.firstName?.message}
              label="First (Given) name"
            />
          </div>
          <div className="last-name">
            <TextInput
              isFluid
              value={data.lastName}
              onChange={handleLastNameChange}
              errorText={validationErrors?.lastName?.message}
              label="Last (Family) name"
            />
          </div>
          <div className="role">
            <Select
              isFluid
              size="medium"
              type="select"
              selectProps={{
                options: jobRoleOptions,
                menuPosition: 'fixed',
                onChange: (entry) => handleJobRoleChange(entry?.value),
                value: jobRoleOptions.find((option) => option.label === data.jobRole),
                placeholder: 'Select a role...',
              }}
              errorText={validationErrors?.jobRole?.message}
              label="What is your role?"
              htmlAttributes={{
                'data-testid': 'select-job-role',
              }}
            />
          </div>
          {isUnspecifiedJobRole(data.jobRole) && (
            <div className="role-other">
              <TextInput
                isFluid
                value={data.jobRoleOther ?? ''}
                onChange={handleJobRoleOtherChange}
                errorText={validationErrors?.jobRoleOther?.message}
                label="Role (other)"
              />
            </div>
          )}
          <div className="primary-use-case">
            <Select
              isFluid
              size="medium"
              type="select"
              selectProps={{
                options: useCaseOptions,
                menuPosition: 'fixed',
                value: useCaseOptions.find((option) => option.value === data.primaryUseCase),
                onChange: (entry) => handlePrimaryUseCaseChange(entry?.value),
                placeholder: 'Select a primary use case...',
              }}
              errorText={validationErrors?.primaryUseCase?.message}
              label="What is your primary use case?"
              htmlAttributes={{
                'data-testid': 'select-primary-use-case',
              }}
            />
          </div>
          {data.primaryUseCase === USE_CASE.Other && (
            <div className="primary-use-case-other">
              <TextInput
                isFluid
                value={data.primaryUseCaseOther ?? ''}
                onChange={handlePrimaryUseCaseOtherChange}
                errorText={validationErrors?.primaryUseCaseOther?.message}
                isOptional
                label="Primary use case (other)"
              />
            </div>
          )}
          <div className="company-name">
            <TextInput
              isFluid
              value={data.companyName}
              onChange={handleCompanyNameChange}
              errorText={validationErrors?.companyName?.message}
              label="What is your company or organization name?"
              htmlAttributes={{
                'data-testid': 'input-company-name',
              }}
            />
          </div>
          <div className="country">
            <Select
              isFluid
              size="medium"
              type="select"
              selectProps={{
                options: countryList,
                menuPosition: 'fixed',
                value: countryList.find((option) => option.value === data.country),
                onChange: (entry) => handleCountryChange(entry?.value),
                placeholder: 'Select a country...',
              }}
              errorText={validationErrors?.country?.message}
              label={'Primary business location'}
              htmlAttributes={{
                'data-testid': 'select-primary-business-location',
              }}
            />
          </div>
          {requiresSubDivision(data.country) && (
            <div className="sub-division">
              <Select
                isFluid
                size="medium"
                type="select"
                selectProps={{
                  options: subDivisionOptions,
                  menuPosition: 'fixed',
                  value: subDivisionOptions.find((option) => option.value === data.subDivision),
                  onChange: (entry) => handleSubDivisionChange(entry?.value),
                  placeholder: `Select ${data.country === 'US' ? 'state' : 'province or territory'}...`,
                }}
                errorText={validationErrors?.subDivision?.message}
                label={`Primary business location (${data.country === 'US' ? 'US state' : 'Canadian province or territory'})`}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
