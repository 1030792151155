import { isNullish } from '@nx/stdlib';
import { DateTime } from 'luxon';

export const isValidNumber = (number: string): boolean => !isNaN(Number(number));

export const parseDateTime = (date: string): Date => new Date(date);

export const isValidJSDateTime = (date: string): boolean => !isNaN(parseDateTime(date).getTime());

export const isValidLuxonDateTime = (date: string): boolean => DateTime.fromISO(date).isValid;

export const hexToRgb = (hex: string): { r: number; g: number; b: number } | null => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return !isNullish(result) && !isNullish(result[1]) && !isNullish(result[2]) && !isNullish(result[3])
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};
