import { NEO4J_IS_HANDLING_SSO_CALLBACK, NEO4J_LOCATION_BEFORE_SSO_REDIRECT, useConnectionStatus } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function SSORedirect() {
  const navigate = useNavigate();
  const connectionStatus = useConnectionStatus();

  useEffect(() => {
    const locationBeforeRedirect = window.sessionStorage.getItem(NEO4J_LOCATION_BEFORE_SSO_REDIRECT);
    const isHandlingSsoRedirect = sessionStorage.getItem(NEO4J_IS_HANDLING_SSO_CALLBACK) === 'true';
    const wasRedirectedBackFromSSOProvider = isNotNullish(locationBeforeRedirect);

    if (wasRedirectedBackFromSSOProvider && connectionStatus.isConnected && !isHandlingSsoRedirect) {
      window.sessionStorage.removeItem(NEO4J_LOCATION_BEFORE_SSO_REDIRECT);
      window.sessionStorage.removeItem(NEO4J_IS_HANDLING_SSO_CALLBACK);
      const toUrl = new URL(locationBeforeRedirect);
      navigate({ pathname: toUrl.pathname }, { replace: true });
    }
  }, [navigate, connectionStatus]);

  return null;
}
