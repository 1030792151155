import { trackSidebarOpened } from '@query/services/analytics';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { cmdRan } from './editor-slice';
import type { RootState } from './store';

export type SidebarTabId = 'DB_INFO' | 'SAVED_CYPHER' | 'HISTORY' | 'CHEAT_SHEET' | 'PARAMETERS';

type SidebarState = {
  width: number;
  openTab: SidebarTabId | null;
  tabButtonPingAnimationEnabled: boolean;
};

export const SIDEBAR_PERSISTED_KEYS = ['openTab', 'width'];
export const INITIAL_SIDEBAR_WIDTH = 400;

const initialState: SidebarState = {
  width: INITIAL_SIDEBAR_WIDTH,
  openTab: 'DB_INFO',
  tabButtonPingAnimationEnabled: false,
};

const sidebar = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebarTab: (
      state,
      action: PayloadAction<{ tabId: SidebarTabId | null; tabButtonPingAnimationEnabled?: boolean }>,
    ) => {
      if (state.openTab === action.payload.tabId) {
        state.openTab = null;
        return;
      }
      if (action.payload.tabId !== null) {
        trackSidebarOpened(action.payload.tabId);
      }
      state.openTab = action.payload.tabId;
      state.tabButtonPingAnimationEnabled = action.payload.tabButtonPingAnimationEnabled ?? false;
    },
    openSidebarTab: (
      state,
      action: PayloadAction<{ tabId: SidebarTabId; tabButtonPingAnimationEnabled?: boolean }>,
    ) => {
      if (state.openTab === action.payload.tabId) {
        return;
      }
      trackSidebarOpened(action.payload.tabId);
      state.openTab = action.payload.tabId;
      state.tabButtonPingAnimationEnabled = action.payload.tabButtonPingAnimationEnabled ?? false;
    },
    resizeSidebar: (state, action: PayloadAction<{ width: number }>) => {
      state.width = action.payload.width;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(cmdRan, (state, action) => {
      const { parsedCmd } = action.payload;
      if (parsedCmd.type === 'open-history-sidebar') {
        state.openTab = 'HISTORY';
      }
    });
  },
});

export const selectOpenedTab = (state: RootState) => state.sidebar.openTab;
export const selectSidebarWidth = (state: RootState) => state.sidebar.width;
export const selectTabButtonPingAnimationEnabled = (state: RootState) => state.sidebar.tabButtonPingAnimationEnabled;

export const { toggleSidebarTab, openSidebarTab, resizeSidebar } = sidebar.actions;

export default sidebar.reducer;
