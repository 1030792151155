import { store } from '../../store';
import * as Configuration from './configuration-slice';

export function consentToTracking(...args: Parameters<typeof Configuration.consentToTracking>) {
  return store.dispatch(Configuration.consentToTracking(...args));
}

export function updateBasePath(...args: Parameters<typeof Configuration.updateBasePath>) {
  store.dispatch(Configuration.updateBasePath(...args));
}

export function updateFallbackRoute(...args: Parameters<typeof Configuration.updateFallbackRoute>) {
  store.dispatch(Configuration.updateFallbackRoute(...args));
}

export function updateToolConfiguration(...args: Parameters<typeof Configuration.updateTool>) {
  store.dispatch(Configuration.updateTool(...args));
}

export function updateAuraConfiguration(...args: Parameters<typeof Configuration.updateAura>) {
  store.dispatch(Configuration.updateAura(...args));
}

export function updateOnboardingConfiguration(...args: Parameters<typeof Configuration.updateOnboarding>) {
  store.dispatch(Configuration.updateOnboarding(...args));
}

export function updateWindowTitle(...args: Parameters<typeof Configuration.updateWindowTitle>) {
  store.dispatch(Configuration.updateWindowTitle(...args));
}

export function updateAppManifest(...args: Parameters<typeof Configuration.updateAppManifest>) {
  store.dispatch(Configuration.updateAppManifest(...args));
}

export function setSentryConfiguration(...args: Parameters<typeof Configuration.setSentryConfiguration>) {
  return store.dispatch(Configuration.setSentryConfiguration(...args));
}

export function setSegmentConfiguration(...args: Parameters<typeof Configuration.setSegmentConfiguration>) {
  return store.dispatch(Configuration.setSegmentConfiguration(...args));
}

export function setLaunchDarklyConfiguration(...args: Parameters<typeof Configuration.setLaunchDarklyConfiguration>) {
  return store.dispatch(Configuration.setLaunchDarklyConfiguration(...args));
}

export const startAppUpdateCheck = async () => {
  // daily check for app updates
  const CHECK_INTERVAL = 1000 * 60 * 60 * 24;

  await store.dispatch(Configuration.fetchAppManifest()).unwrap();

  setTimeout(() => {
    void startAppUpdateCheck();
  }, CHECK_INTERVAL);
};
