import { DataGrid } from '@neo4j-ndl/react';
import type { ColumnDef } from '@tanstack/react-table';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';

export const SimpleTable = <T extends Record<string, unknown>>({
  columns,
  data,
}: {
  columns: ColumnDef<T>[];
  data: T[];
}) => {
  const tableInstance = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  const hideHeader = columns.every((column) => column.header === undefined);

  return (
    <DataGrid
      components={{ Header: hideHeader ? () => <thead className="hidden"></thead> : undefined }}
      tableInstance={tableInstance}
      isKeyboardNavigable={false}
    />
  );
};
