import type { Chart, ChartTypeRegistry, Color, TooltipModel } from 'chart.js';

import { isDateTimeType } from '../../services/temporal/utils';
import { getNumberToStringMapper } from '../Filter/NumberAndDateTimeConditionUtils';
import type { Position } from './SlicerHistogram.types';
import type { Range } from './types';

const BOX_BACKGROUND_COLOR = '#E6F8FF';
const BOX_BORDER_COLOR = '#5CC3C9';

export const VISIBLE_BAR_COLOR = '#55BDC5';
export const INVISIBLE_BAR_COLOR = 'rgba(85, 189, 197, .33)';
export const PADDING = 10;
export const RESIZE_BOX_LEFT = 'resizebox-left';
export const RESIZE_BOX_RIGHT = 'resizebox-right';
export const DRAG_BOX = 'dragbox';
export const SIDE_BOX_WIDTH = 16;
const SIDE_BOX_RADIUS = 8;
const SIDE_BOX_HEIGHT = 48;
export const PLOT_HEIGHT = 155;

export const PLOT_CONFIGURATION = {
  responsive: true,
  maintainAspectRatio: false,
  layout: { padding: PADDING },
  animation: { duration: 0 },
  events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove', 'mousedown', 'mouseup'],
  scales: {
    y: { display: false, stacked: true, grid: { display: false } },
    x: { display: false, stacked: true, grid: { display: false } },
  },
};

const drawBox = (
  ctx: CanvasRenderingContext2D,
  rect: {
    x: number;
    y: number;
    width: number;
    height: number;
  },
  useFill: boolean,
) => {
  const { x, y, width, height } = rect;
  ctx.save();
  ctx.fillStyle = BOX_BACKGROUND_COLOR;
  ctx.strokeStyle = BOX_BORDER_COLOR;
  ctx.lineWidth = 1;
  ctx.beginPath();
  ctx.rect(x, y, width, height);
  ctx.closePath();
  if (useFill) {
    ctx.fill();
    ctx.stroke();
  } else {
    ctx.stroke();
  }
  ctx.restore();
};

const drawRoundBox = (
  ctx: CanvasRenderingContext2D,
  rect: {
    x: number;
    y: number;
    width: number;
    height: number;
  },
  radius: number,
) => {
  const { x, y, width, height } = rect;
  if (width < 2 * radius) radius = width / 2;
  if (height < 2 * radius) radius = height / 2;
  ctx.save();
  ctx.strokeStyle = BOX_BORDER_COLOR;
  ctx.fillStyle = 'white';
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.arcTo(x + width, y, x + width, y + height, radius);
  ctx.arcTo(x + width, y + height, x, y + height, radius);
  ctx.arcTo(x, y + height, x, y, radius);
  ctx.arcTo(x, y, x + width, y, radius);
  ctx.moveTo(x + radius, y + radius);
  ctx.lineTo(x + width - radius, y + height - radius);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
};

export const drawSlicerWindow = (chart: Chart<'bar'>, isBefore = false, rangePosition: Position) => {
  const { ctx, height } = chart;
  const top = 0;
  const rect = {
    y: height / 2 - SIDE_BOX_HEIGHT / 2,
    width: SIDE_BOX_WIDTH,
    height: SIDE_BOX_HEIGHT,
  };
  const box = {
    x: rangePosition.start!,
    y: top,
    width: Math.abs(rangePosition.end! - rangePosition.start!),
    height: PLOT_HEIGHT,
  };
  drawBox(ctx, box, isBefore);
  // left
  drawRoundBox(
    ctx,
    {
      ...rect,
      x: rangePosition.start! - SIDE_BOX_WIDTH / 2,
      y: top + height / 2 - SIDE_BOX_WIDTH,
    },
    SIDE_BOX_RADIUS,
  );
  // right
  drawRoundBox(
    ctx,
    {
      ...rect,
      x: rangePosition.end! - SIDE_BOX_WIDTH / 2,
      y: top + height / 2 - SIDE_BOX_WIDTH,
    },
    SIDE_BOX_RADIUS,
  );
};

export const getDragMode = (x: number, rangePosition: Position) => {
  if (x >= rangePosition.start! - SIDE_BOX_WIDTH && x <= rangePosition.start! + SIDE_BOX_WIDTH) {
    return RESIZE_BOX_LEFT;
  } else if (x >= rangePosition.end! - SIDE_BOX_WIDTH && x <= rangePosition.end! + SIDE_BOX_WIDTH) {
    return RESIZE_BOX_RIGHT;
  } else if (x >= rangePosition.start! + SIDE_BOX_WIDTH && x <= rangePosition.end!) {
    return DRAG_BOX;
  }
  return null;
};

export const getBarsPositions = (obj: Chart<'bar'>) => {
  const metaset = obj.getDatasetMeta(0);
  if (!metaset) return [];
  const bars = metaset.data.map((m) => Math.max(2 * PADDING, m.x));
  return bars;
};

export const getBarWidth = (obj: Chart<'bar'>) => {
  const metaset = obj.getDatasetMeta(0);
  if (!metaset) return PADDING;
  return metaset.data[0]?.getProps(['width'])?.width || PADDING;
};

export const getPadding = (obj: Chart<'bar'>) => {
  const metaset = obj.getDatasetMeta(0);
  if (!metaset) return PADDING;
  const { data } = metaset;
  if (!data || data.length < 2) return PADDING;
  return Math.min((data[1]!.x - data[0]!.x - data[0]!.getProps(['width']).width) / 2, PADDING);
};

const getOrCreateTooltip = (chart: Chart<keyof ChartTypeRegistry>) => {
  let tooltipEl = chart.canvas.parentNode?.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = '1';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.height = 'max-content';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode?.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const externalTooltipHandler = (
  context: {
    chart: Chart<keyof ChartTypeRegistry>;
    tooltip: TooltipModel<'bar'>;
  },
  currentRange: Range,
) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  const getTooltipTitle = (title: string[], currentRange: Range) => {
    const isDateTime = isDateTimeType(currentRange.propertyType);

    if (!isDateTime) {
      return title[0]!;
    }

    const valueLabelMapper = getNumberToStringMapper(
      currentRange.propertyType,
      currentRange.timezoneTranslation,
      currentRange.timezoneTranslationEnabled,
    );

    const values = JSON.parse(title[0]!) as unknown[];
    return Array.isArray(values) ? `[${values.map((v) => `${valueLabelMapper(v)}`)}]` : valueLabelMapper(title[0]!);
  };

  const isColorString = (color: Color | undefined): color is string => typeof color === 'string';

  // Set Text
  if (tooltip.body) {
    const title = getTooltipTitle(tooltip.title, currentRange);
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tableHead = document.createElement('tr');

    const tr = document.createElement('span');
    tr.style.borderWidth = '0';

    const th = document.createElement('span');
    th.style.borderWidth = '0';
    const text = document.createTextNode(title);

    th.appendChild(text);
    tr.appendChild(th);
    tableHead.appendChild(tr);

    const tableBody = document.createElement('tbody');
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const innerTr = document.createElement('tr');
      const tdColor = document.createElement('td');
      const divColor = document.createElement('div');
      if (isColorString(colors?.backgroundColor)) {
        divColor.style.background = colors.backgroundColor;
      }
      if (isColorString(colors?.borderColor)) {
        divColor.style.borderColor = colors.borderColor;
      }
      divColor.style.borderWidth = '2px';
      divColor.style.padding = '4px';

      const tr = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = '0';

      const td = document.createElement('td');
      td.style.borderWidth = '0';

      const tdText = document.createElement('td');
      tdText.style.paddingLeft = '4px';
      const text = document.createTextNode(body.join(' '));

      tdColor.appendChild(divColor);
      innerTr.appendChild(tdColor);
      tdText.appendChild(text);
      innerTr.appendChild(tdText);
      td.appendChild(innerTr);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    if (tableRoot) {
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }
  }

  const getTooltipLeftPosition = () => {
    if (tooltip.caretX < tooltip.width) {
      return tooltip.width / 2 + tooltip.x + PADDING;
    } else if (tooltip.width + tooltip.caretX > tooltip.chart?.width) {
      return tooltip.chart?.width - tooltip.width / 2 - PADDING;
    }
    return tooltip.caretX;
  };
  // Display, position, and set styles
  tooltipEl.style.opacity = '1';
  tooltipEl.style.left = `${getTooltipLeftPosition()}px`;
  tooltipEl.style.bottom = '80px';
  tooltipEl.style.top = '10px';
  tooltipEl.style.padding = '8px';
};
