import { Banner, LoadingSpinner } from '@neo4j-ndl/react';
import { consoleApi, logout, useAuth } from '@nx/state';
import { Center } from '@nx/ui';
import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const N4GCPMarketplaceRegistration = () => {
  const navigate = useNavigate();
  const userDetailsRes = consoleApi.useGetUserDetailsQuery();
  const listUserProjectsRes = consoleApi.useListProjectsByUserQuery(userDetailsRes.data?.id ?? skipToken);
  const [consoleLogout] = consoleApi.useLogoutMutation();
  const [invalidProjectId, setInvalidProjectId] = useState(false);
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const gcpProjectId = searchParams.get('gcp_project_id');
  const googleUserId = searchParams.get('google_user_id');

  useEffect(() => {
    if (listUserProjectsRes.data === undefined) {
      return;
    }

    const n4gcpProject = listUserProjectsRes.data.find((project) => project.googleProjectId === gcpProjectId);
    if (n4gcpProject) {
      navigate({ pathname: `/projects/${n4gcpProject.id}/instances` });
    } else {
      setInvalidProjectId(true);
    }
  }, [gcpProjectId, listUserProjectsRes.data, navigate]);

  if (gcpProjectId === null || gcpProjectId === '') {
    return (
      <Banner
        className="m-4"
        title="Invalid URL"
        description="Missing query parameter gcp_project_id"
        isCloseable={false}
        type="danger"
        usage="inline"
      />
    );
  }

  if (invalidProjectId) {
    const userIdMismatch =
      googleUserId !== null &&
      googleUserId !== '' &&
      user !== null &&
      user.profile.sub !== `google-oauth2|${googleUserId}`;

    if (userIdMismatch) {
      return (
        <Banner
          className="m-4"
          title="Authentication mismatch"
          description="Your authenticated user ID does not match the user ID belonging to your GCP email. Please log out and log in again using Google OAuth."
          isCloseable={false}
          type="danger"
          usage="inline"
          actions={[
            {
              label: 'Log out',
              onClick: () => {
                void consoleLogout();
                logout();
              },
            },
          ]}
        />
      );
    }

    return (
      <Banner
        className="m-4"
        title="Project not found"
        description={`Could not find an Aura project matching the GCP project ${gcpProjectId}.`}
        isCloseable={false}
        type="danger"
        usage="inline"
      />
    );
  }

  return (
    <Center className="flex-col">
      <LoadingSpinner size="large" />
    </Center>
  );
};
