import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { isNil } from 'lodash-es';

import type { Nullable } from '../../types/utility';
import { SEARCH_HISTORY_LIMIT } from '../settings/settings';
import type { RootState } from '../types';

export const NAME = 'search';

export interface SearchDuckState {
  history: string[];
  initialSearchInput: Nullable<string>;
  initialSearchRun: boolean;
  searchRequestId: Nullable<string>;
}

const initialState: SearchDuckState = {
  history: [],
  initialSearchInput: null,
  initialSearchRun: false,
  searchRequestId: null,
};

const getState = (state: RootState): SearchDuckState => state[NAME];

export const getSearchRequestId = (state: RootState) => getState(state).searchRequestId;
export const getSearchHistory = (state: RootState) => getState(state).history;
export const getInitialSearchInput = (state: RootState) => getState(state).initialSearchInput;
export const getInitialSearchRun = (state: RootState) => getState(state).initialSearchRun;

const searchDuckSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    addToHistory(state, action: PayloadAction<string | undefined | null>) {
      if (!isNil(action.payload)) state.history = addHistoryHelper(state.history, action.payload, SEARCH_HISTORY_LIMIT);
    },
    setInitialSearchInput(state, action: PayloadAction<{ input?: Nullable<string>; run?: boolean }>) {
      const { input, run } = action.payload;
      state.initialSearchInput = input ?? null;
      state.initialSearchRun = run ?? false;
    },
    resetInitialSearchInput(state) {
      state.initialSearchInput = null;
      state.initialSearchRun = false;
    },
    setSearchRequestId(state, action: PayloadAction<Nullable<string>>) {
      state.searchRequestId = action.payload;
    },
  },
});

export const { addToHistory, setInitialSearchInput, resetInitialSearchInput, setSearchRequestId } =
  searchDuckSlice.actions;

export default searchDuckSlice.reducer;

function addHistoryHelper(history: string[], input: string | undefined, maxHistory: number) {
  if (isNil(input) || input.length === 0 || (!isNil(history) && history.length > 0 && history[0] === input)) {
    return history;
  }

  const newHistory = [...history];
  newHistory.unshift(input);
  return newHistory.slice(0, maxHistory);
}
