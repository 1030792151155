import { customCreateApi } from '../../context';
import { createDynamicBaseQuery } from '../../utils/create-dynamic-base-query';
import type { DesktopState } from './desktop';
import { dbEndpoints } from './endpoints/db';
import { dbmsEndpoints } from './endpoints/dbms';
import { eventEndpoints } from './endpoints/events';
import { pluginEndpoints } from './endpoints/plugin';
import { type Builder, tagTypes } from './endpoints/types';

const isDesktopState = (state: unknown): state is { desktop: DesktopState } => {
  if (state !== null && typeof state === 'object' && 'desktop' in state) {
    return true;
  }
  return false;
};

const baseQuery = createDynamicBaseQuery(
  ({ desktop }) => `${desktop.relate.protocol ?? ''}${desktop.relate.url ?? ''}`,
  {
    prepareHeaders: (headers, { getState }) => {
      const state = getState();
      if (isDesktopState(state)) {
        const { apiToken, clientId } = state.desktop.relate;

        if (apiToken !== undefined) {
          headers.set('X-API-Token', apiToken);
        }

        if (clientId !== undefined) {
          headers.set('X-Client-Id', clientId);
        }
      }

      return headers;
    },
  },
);

const relateApi = customCreateApi({
  baseQuery,
  reducerPath: 'relateApi',
  tagTypes,
  endpoints: (builder: Builder) => ({
    ...dbmsEndpoints(builder),
    ...dbEndpoints(builder),
    ...pluginEndpoints(builder),
    ...eventEndpoints(builder),

    appInfo: builder.query<{ version: string }, void>({
      query: () => 'app-info',
    }),

    getCredentials: builder.query<{ username: string; password: string } | null, string>({
      query: (dbmsId: string) => ({
        url: 'get-credentials',
        params: { key: `dbms:${dbmsId}` },
      }),
    }),

    openDirectory: builder.mutation<void, string>({
      query: (filePath: string) => ({
        url: 'open-directory',
        method: 'POST',
        body: { filePath },
      }),
    }),

    openDialog: builder.mutation<
      string,
      {
        title?: string;
        properties?: (
          | 'openFile'
          | 'openDirectory'
          | 'multiSelections'
          | 'showHiddenFiles'
          | 'createDirectory'
          | 'promptToCreate'
          | 'noResolveAliases'
          | 'treatPackageAsDirectory'
          | 'dontAddToRecent'
        )[];
        message?: string;
        defaultPath?: string;
      }
    >({
      query: (options) => ({
        url: 'open-dialog',
        method: 'POST',
        body: { options },
      }),
    }),
  }),
});

export const {
  useGetLocalInstanceQuery,
  useListLocalInstancesQuery,
  useInfoLocalInstancesQuery,
  useListNeo4jVersionsQuery,
  useStartLocalInstanceMutation,
  useStopLocalInstanceMutation,
  useInstallLocalInstanceMutation,
  useUninstallLocalInstanceMutation,
  useCloneLocalInstanceMutation,
  useUpgradeLocalInstanceMutation,
  useWatchProgressQuery,

  useListDatabasesQuery,
  useCreateDatabaseMutation,
  useDropDatabaseMutation,
  useDumpDatabaseMutation,
  useLoadDatabaseMutation,

  useListInstalledPluginsQuery,
  useInstallPluginMutation,
  useUninstallPluginMutation,
  useOpenDirectoryMutation,

  useOpenDialogMutation,
  useAppInfoQuery,

  useLazyGetCredentialsQuery,
} = relateApi;

// TODO: add relateApi to the main Store, for the Bloom Standalone release we remove it as it was making a hard coded
// request to http://localhost:4000 which might be a problem for our customer. Enable it again when we can dynamically
// load the state so we can disable this part when we are building Bloom Standalone
export default relateApi;
