import { Code, Dialog, TextLink, Typography } from '@neo4j-ndl/react';
import type { GQLError } from '@nx/state';
import { useState } from 'react';

export const GQLErrorModal = ({ gqlError }: { gqlError: GQLError }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleClick = () => setIsOpen((prev) => !prev);
  const handleClose = () => setIsOpen(false);

  return (
    <div>
      <TextLink as="button" className="cursor-pointer !p-0" htmlAttributes={{ onClick: handleClick }}>
        Show GQL Error
      </TextLink>
      <Dialog size="large" isOpen={isOpen} onClose={handleClose}>
        <Dialog.Header>GQL Error</Dialog.Header>
        <Dialog.Content>
          <div className="flex flex-col gap-3">
            <Code code={JSON.stringify(gqlError, null, '\t')} type="block" language="json" />
            <Typography variant={'body-small'}>
              Read more about the error{' '}
              <TextLink
                type="external"
                href={`https://neo4j.com/docs/status-codes/current/errors/gql-errors/#_${gqlError.gqlStatus.toLowerCase()}`}
              >
                here
              </TextLink>
            </Typography>
          </div>
        </Dialog.Content>
      </Dialog>
    </div>
  );
};
