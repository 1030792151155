import { tokens } from '@neo4j-ndl/base';
import { Button, Typography, useMediaQuery } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import type { DataApiBatchResult, DataApiError, DataApiModify, Instance } from '@nx/state';
import { graphqlApi, useActiveProject } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import deepEqual from 'fast-deep-equal';
import { useCallback, useEffect, useState } from 'react';

import { ApiErrorBanner } from '../components/api-error-banner';
import { CredentialsModal } from '../components/credentials-modal';
import { DataApiErrorBanner } from '../components/data-api-error-banner';
import { ModifyAccordion } from '../modules/modify-accordion';
import { type CredentialsModalData, MODIFY_MODE, type OriginalDataApiModify } from '../types';
import type { Validation } from '../utils/validation';
import { validateYup } from '../utils/validation';
import { updateSchema } from '../utils/validation-schemas';
import { handleDataApiUpdate } from './modify-helper';

const logger = createLogger(APP_SCOPE.dataApi);

interface ModifyDataApiProps {
  closeModifyPage: () => void;
  runningInstances: Instance[] | null;
  activeModifyDataApi: DataApiBatchResult | null;
}

const emptyState: DataApiModify = {
  id: '',
  name: '',
  instanceId: '',
  instanceUsername: '',
  instancePassword: '',
  typeDefinitions: '',
  allowedOrigins: [],
  authProviders: [],
};

const validateUpdateData = (data: DataApiModify) => validateYup(updateSchema, data, false);

export const EditDataApi = ({ closeModifyPage, runningInstances, activeModifyDataApi }: ModifyDataApiProps) => {
  const activeProject = useActiveProject();
  const [updateDataApi, { isLoading: isUpdatingDataApi, error: errorUpdatingDataApi }] =
    graphqlApi.useUpdateDataApiMutation();
  const [getAuthProvidersBatch, { isLoading: isLoadingAuthProviderBatch, error: errorAuthProviderBatch }] =
    graphqlApi.useLazyGetAuthProvidersBatchQuery();
  const [getDataApi, { isLoading: isGettingDataApi, error: errorGettingDataApi }] = graphqlApi.useLazyGetDataApiQuery();
  const [updateAuthProvider, { isLoading: isUpdatingAuthProvider, error: errorUpdatingAuthProvider }] =
    graphqlApi.useUpdateAuthProviderMutation();
  const [createAuthProvider, { isLoading: isCreatingAuthProvider, error: errorCreatingAuthProvider }] =
    graphqlApi.useCreateAuthProviderMutation();
  const [deleteAuthProvider, { isLoading: isDeletingAuthProvider, error: errorDeletingAuthProvider }] =
    graphqlApi.useDeleteAuthProviderMutation();

  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [endButtonText, setEndButtonText] = useState<string>('Back');
  const [isSavingDisabled, setIsSavingDisabled] = useState<boolean>(true);

  const [validationError, setValidationError] = useState<Validation<DataApiModify> | null>(null);
  const [dataApiErrors, setDataApiErrors] = useState<DataApiError[] | null>(null);
  const [data, setData] = useState<DataApiModify>(emptyState);
  const [showCredentialsModal, setShowCredentialsModal] = useState<boolean>(false);
  const [credentialsModalData, setCredentialsModalData] = useState<CredentialsModalData | null>(null);
  const [originalDataApi, setOriginalDataApi] = useState<OriginalDataApiModify | null>(null);

  const isLgOrAbove = useMediaQuery(`(min-width:${tokens.breakpoints.lg})`);

  useEffect(() => {
    if (originalDataApi) {
      // If any data has changed, enable the save button and change end button's text.
      const [text, isDisabled] = !deepEqual(originalDataApi, data) ? ['Cancel', false] : ['Back', true];
      setEndButtonText(text);
      setIsSavingDisabled(isDisabled);
    }
  }, [originalDataApi, data]);

  const getDataForEdit = useCallback(() => {
    if (activeModifyDataApi) {
      setIsInitialized(false);
      Promise.all([
        getDataApi({
          projectId: activeProject.id,
          instanceId: activeModifyDataApi.instanceId,
          dataApiId: activeModifyDataApi.id,
        }).unwrap(),
        getAuthProvidersBatch({
          projectId: activeProject.id,
          instanceId: activeModifyDataApi.instanceId,
          dataApiId: activeModifyDataApi.id,
        }).unwrap(),
      ])
        .then(([dataApiResponse, authProvidersResponse]) => {
          const combinedData = { ...dataApiResponse.data, authProviders: authProvidersResponse };
          setOriginalDataApi(combinedData);
          setData(() => combinedData);

          if (dataApiResponse.errors) {
            setDataApiErrors(dataApiResponse.errors);
          }

          setIsInitialized(true);
        })
        .catch((err: FetchBaseQueryError | SerializedError | undefined) => {
          logger.error(err);
          setIsInitialized(true);
        });
    }
  }, [activeModifyDataApi, activeProject.id, getAuthProvidersBatch, getDataApi]);

  useEffect(() => {
    getDataForEdit();
  }, [getDataForEdit]);

  const handleUpdateDataApi = () => {
    Promise.all([validateUpdateData(data)])
      .then(([validation]) => {
        if (isNotNullish(validation)) {
          setValidationError(validation);
          return;
        }
        setValidationError(null);

        if (!activeModifyDataApi) {
          logger.info('the Data API to modify is not set');
          return;
        }

        handleDataApiUpdate({
          activeProject,
          activeModifyDataApi,
          originalDataApi,
          data,
          logger,
          updateDataApi,
          createAuthProvider,
          updateAuthProvider,
          deleteAuthProvider,
          showCredentialsModal: (credsModalData) => {
            setCredentialsModalData(credsModalData);
            setShowCredentialsModal(true);
          },
          onFinished: () => {
            getDataForEdit();
          },
        });
      })
      .catch((err) => {
        logger.error(err);
      });
  };

  const handleCloseCredentialsModal = () => {
    setCredentialsModalData(null);
    setShowCredentialsModal(false);
  };

  const handleAction = () => {
    const element = document.getElementById('title-wrapper');
    element?.scrollIntoView({ behavior: 'instant' });

    handleUpdateDataApi();
  };

  return (
    <>
      {showCredentialsModal && (
        <CredentialsModal data={credentialsModalData} onClose={() => handleCloseCredentialsModal()} />
      )}
      <div className="flex flex-col items-center justify-center">
        <div className="w-full max-w-[65rem]">
          <div className={`flex justify-between ${isLgOrAbove ? 'items-center' : 'flex-col items-start'}`}>
            <div className="flex items-center gap-4" id="title-wrapper">
              <Typography variant="h2">Edit GraphQL Data API</Typography>
            </div>
            <div className={`${!isLgOrAbove && 'ml-auto mt-4'}`}>
              <Button
                color="neutral"
                className="mr-3"
                fill="outlined"
                onClick={() => closeModifyPage()}
                isDisabled={isUpdatingDataApi}
              >
                {endButtonText}
              </Button>
              <Button
                isLoading={
                  isGettingDataApi ||
                  isLoadingAuthProviderBatch ||
                  isUpdatingDataApi ||
                  isUpdatingAuthProvider ||
                  isCreatingAuthProvider ||
                  isDeletingAuthProvider
                }
                isDisabled={isSavingDisabled}
                type="submit"
                onClick={handleAction}
              >
                Save
              </Button>
            </div>
          </div>
          <main className="my-6">
            {dataApiErrors && (
              <div className="mb-4">
                <DataApiErrorBanner errors={dataApiErrors} hasIcon />
              </div>
            )}
            <div className="mb-4">
              {errorGettingDataApi && <ApiErrorBanner error={errorGettingDataApi} hasIcon />}
              {errorUpdatingDataApi && <ApiErrorBanner error={errorUpdatingDataApi} hasIcon />}
              {errorAuthProviderBatch && <ApiErrorBanner error={errorAuthProviderBatch} hasIcon />}
              {errorUpdatingAuthProvider && <ApiErrorBanner error={errorUpdatingAuthProvider} hasIcon />}
              {errorCreatingAuthProvider && <ApiErrorBanner error={errorCreatingAuthProvider} hasIcon />}
              {errorDeletingAuthProvider && <ApiErrorBanner error={errorDeletingAuthProvider} hasIcon />}
            </div>
            <ModifyAccordion
              mode={MODIFY_MODE.edit}
              runningInstances={runningInstances}
              isLoading={!isInitialized}
              data={data}
              validationError={validationError}
              setData={setData}
              setValidationError={setValidationError}
            />
          </main>
          <div className="flex items-center justify-end">
            <Button
              isLoading={
                isGettingDataApi ||
                isLoadingAuthProviderBatch ||
                isUpdatingDataApi ||
                isUpdatingAuthProvider ||
                isCreatingAuthProvider ||
                isDeletingAuthProvider
              }
              isDisabled={isSavingDisabled}
              type="submit"
              onClick={handleAction}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
