import { APP_SCOPE, type SSOProviderOriginal } from '@nx/constants';
import { ssoProviderToUserManager } from '@nx/neo4j-token-provider';
import * as StdLib from '@nx/stdlib';
import { WebStorageStateStore } from 'oidc-client-ts';

import { Persist } from '../../persist/persist';
import { authLog } from './auth-log';

const NEO4J_SSO_REDIRECT_URI = 'neo4j-sso-redirect-uri';
const NEO4J_PROVIDER = 'auth.neo4j.provider';
export const NEO4J_LOCATION_BEFORE_SSO_REDIRECT = 'auth.neo4j.location';
export const NEO4J_IS_HANDLING_SSO_CALLBACK = 'auth.neo4j.is-handling-callback';

export const createUserAndStateWebStorageStores = () => ({
  userStore: new WebStorageStateStore({ prefix: `neo4j.`, store: window.sessionStorage }),
  stateStore: new WebStorageStateStore({ prefix: `neo4j.`, store: window.localStorage }),
});

export const getSsoWebStorageContents = async () => {
  const { userStore, stateStore } = createUserAndStateWebStorageStores();
  const userStoreKeys = await userStore.getAllKeys();
  const userStoreContents = await Promise.all(
    userStoreKeys.map(async (key) => ({ key, value: await userStore.get(key) })),
  );
  const stateStoreKeys = await stateStore.getAllKeys();
  const stateStoreContents = await Promise.all(
    stateStoreKeys.map(async (key) => ({ key, value: await stateStore.get(key) })),
  );
  return { userStoreContents, stateStoreContents };
};

export const getRedirectUri = (): string =>
  localStorage.getItem(Persist.createKey(APP_SCOPE.framework, NEO4J_SSO_REDIRECT_URI)) ?? location.origin;

export function initializeSsoRedirectUri(redirectUri: string) {
  localStorage.setItem(Persist.createKey(APP_SCOPE.framework, NEO4J_SSO_REDIRECT_URI), redirectUri);
}

export async function ssoSigninRedirect(provider: SSOProviderOriginal): Promise<void> {
  const authClient = ssoProviderToUserManager(provider, createUserAndStateWebStorageStores(), getRedirectUri);

  window.sessionStorage.setItem(NEO4J_PROVIDER, JSON.stringify(provider));
  window.sessionStorage.setItem(NEO4J_LOCATION_BEFORE_SSO_REDIRECT, location.href);

  authLog(`Starting client side SSO flow, redirecting to provider: ${provider.id}`);
  authLog(`Using redirect URI: ${authClient.settings.redirect_uri}`);
  await authClient.signinRedirect();
}

export const getCurrentSSOProvider = (): SSOProviderOriginal | null => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const provider: SSOProviderOriginal | null = JSON.parse(window.sessionStorage.getItem(NEO4J_PROVIDER) ?? 'null');
  return provider;
};

export async function clearSSOData(): Promise<void> {
  const provider = getCurrentSSOProvider();

  if (StdLib.isNullish(provider)) {
    // No SSO provider was selected, nothing to do.
    return;
  }

  const authClient = ssoProviderToUserManager(provider, createUserAndStateWebStorageStores(), getRedirectUri);
  await authClient.removeUser();

  window.sessionStorage.removeItem(NEO4J_PROVIDER);
}

export function isSsoRedirect(): boolean {
  const provider = getCurrentSSOProvider();
  const location = window.sessionStorage.getItem(NEO4J_LOCATION_BEFORE_SSO_REDIRECT);

  if (StdLib.isNullish(provider) || StdLib.isNullish(location)) {
    // No SSO provider was selected, nothing to do.
    return false;
  }

  return true;
}
