export enum QUERY_LOG_STATUS {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  UNFINISHED = 'UNFINISHED',
}

export enum QUERY_LOG_SORT_PROPERTY {
  QUERY = 'QUERY',
  QUERY_LANGUAGE = 'QUERY_LANGUAGE',
  ELAPSED = 'ELAPSED',
  PLANNING_TIME = 'PLANNING_TIME',
  WAITING_TIME = 'WAITING_TIME',
  ALLOCATED_BYTES = 'ALLOCATED_BYTES',
  PAGE_FAULTS = 'PAGE_FAULTS',
  PAGE_HITS = 'PAGE_HITS',
  TIMESTAMP = 'TIMESTAMP',
  STATUS = 'STATUS',
  USER = 'USER',
  DRIVER = 'DRIVER',
  APP = 'APP',
  INITIATION_TYPE = 'INITIATION_TYPE',
  DATABASE = 'DATABASE',
}

export enum QUERY_LOG_AGGREGATION_SORT_PROPERTY {
  QUERY = 'QUERY',
  QUERY_LANGUAGE = 'QUERY_LANGUAGE',
  COUNT = 'COUNT',
  TOTAL_TIME_SPENT = 'TOTAL_TIME_SPENT',
  AVG_ELAPSED = 'AVG_ELAPSED',
  MAX_ELAPSED = 'MAX_ELAPSED',
  MIN_ELAPSED = 'MIN_ELAPSED',
  AVG_PLANNING_TIME = 'AVG_PLANNING_TIME',
  AVG_WAITING_TIME = 'AVG_WAITING_TIME',
  AVG_ALLOCATED_BYTES = 'AVG_ALLOCATED_BYTES',
  AVG_PAGE_FAULTS = 'AVG_PAGE_FAULTS',
  AVG_PAGE_HITS = 'AVG_PAGE_HITS',
  TIMESTAMP = 'TIMESTAMP',
  STATUS = 'STATUS',
  AVG_CPU_TIME = 'AVG_CPU_TIME',
}

export enum SORT_ORDER {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

export enum LOG_FORMAT {
  CSV = 'CSV',
  JSON = 'JSON',
}

export enum DOWNLOADS_LOG_TYPE {
  QUERY = 'QUERY',
  SECURITY = 'SECURITY',
}

export enum LOG_SUB_TYPE {
  SUMMARY = 'SUMMARY',
  DETAILS = 'DETAILS',
}

export enum DOWNLOAD_JOB_STATUS {
  UNKNOWN = 'UNKNOWN',
  RUNNING = 'RUNNING',
  DONE = 'DONE',
  FAILED = 'FAILED',
}

export enum SECURITY_LOG_STATUS {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
  UNKNOWN = 'UNKNOWN',
}

export enum SECURITY_LOG_SORT_PROPERTY {
  MESSAGE = 'MESSAGE',
  TIMESTAMP = 'TIMESTAMP',
  STATUS = 'STATUS',
  AUTHENTICATED_USER = 'AUTHENTICATED_USER',
  EXECUTING_USER = 'EXECUTING_USER',
  DRIVER = 'DRIVER',
  DATABASE = 'DATABASE',
}

export enum SECURITY_LOG_SUMMARY_SORT_PROPERTY {
  MESSAGE = 'MESSAGE',
  LOG_COUNT = 'LOG_COUNT',
  TIMESTAMP = 'TIMESTAMP',
  STATUS = 'STATUS',
  EXECUTING_USER = 'EXECUTING_USER',
  AUTHENTICATED_USERS = 'AUTHENTICATED_USERS',
}

// Request bodies

export interface QueryLogFilterValuesRequest {
  tenantId: string;
  dbmsId: string;
  from: number;
  to: number;
}

export interface QueryLogsRequest {
  tenantId: string;
  dbmsId: string;
  args: QueryLogArgs;
}

export interface QueryLogArgs {
  filter: QueryLogFilterInput;
  sort: QueryLogSort[];
  pagination: PaginationInput;
}

export interface QueryLogFilterInput {
  from: number;
  to: number;
  query: string;
  querySearchString: string;
  errorSearchString: string;
  users: string[];
  databases: string[];
  apps: string[];
  initiationTypes: string[];
  drivers: string[];
  statuses: QUERY_LOG_STATUS[];
  minimumDuration: number;
  queryLanguages: string[];
  gqlStatuses: string[];
}

export interface QueryLogSort {
  property: QUERY_LOG_SORT_PROPERTY;
  sortOrder: SORT_ORDER;
}

export interface PaginationInput {
  page: number;
  perPage: number;
}

export interface QueryLogsSummaryRequest {
  tenantId: string;
  dbmsId: string;
  args: QueryLogAggregationArgs;
}

export interface QueryLogAggregationArgs {
  filter: QueryLogFilterInput;
  sort: QueryLogAggregationSort[];
  pagination: PaginationInput;
}

export interface QueryLogAggregationSort {
  property: QUERY_LOG_AGGREGATION_SORT_PROPERTY;
  sortOrder: SORT_ORDER;
}

export interface QueryLogCountsRequest {
  tenantId: string;
  dbmsId: string;
  filter: QueryLogFilterInput;
}

type LogsInitiateDownloadRequestBodyBase = {
  format: LOG_FORMAT;
  csvHeader: boolean;
  csvDelimiter: string;
};

export type QueryLogsInitiateDownloadDetailsRequestBody = LogsInitiateDownloadRequestBodyBase & QueryLogsRequest;

export type QueryLogsInitiateDownloadSummaryRequestBody = LogsInitiateDownloadRequestBodyBase & QueryLogsSummaryRequest;

export type SecurityLogsInitiateDownloadDetailsRequestBody = LogsInitiateDownloadRequestBodyBase & SecurityLogsRequest;

export type SecurityLogsInitiateDownloadSummaryRequestBody = LogsInitiateDownloadRequestBodyBase &
  SecurityLogsSummaryRequest;

export interface LogsDownloadsRequestBody {
  tenantId: string;
  dbmsId: string;
}

export interface LogsDownloadUrlRequest {
  tenantId: string;
  dbmsId: string;
  jobId: string;
}

export interface LogsDownloadDeleteRequest {
  tenantId: string;
  dbmsId: string;
  jobId: string;
}

export interface SecurityLogsRequest {
  tenantId: string;
  dbmsId: string;
  args: SecurityLogArgs;
}

export interface SecurityLogArgs {
  filter: SecurityLogFilterInput;
  sort: SecurityLogSort[];
  pagination: PaginationInput;
}

export interface SecurityLogFilterInput {
  from: number;
  to: number;
  message: string;
  messageSearchString: string;
  executingUsers: string[];
  authenticatedUsers: string[];
  databases: string[];
  drivers: string[];
  statuses: SECURITY_LOG_STATUS[];
}

export interface SecurityLogSort {
  property: SECURITY_LOG_SORT_PROPERTY;
  order: SORT_ORDER;
}

export interface SecurityLogsSummaryRequest {
  tenantId: string;
  dbmsId: string;
  args: SecurityLogSummaryArgs;
}

export interface SecurityLogSummaryArgs {
  filter: SecurityLogFilterInput;
  sort: SecurityLogSummarySort[];
  pagination: PaginationInput;
}

export interface SecurityLogSummarySort {
  property: SECURITY_LOG_SUMMARY_SORT_PROPERTY;
  order: SORT_ORDER;
}

export interface SecurityLogFilterValuesRequest {
  tenantId: string;
  dbmsId: string;
  from: number;
  to: number;
}

export interface SecurityLogCountsRequest {
  tenantId: string;
  dbmsId: string;
  filter: SecurityLogFilterInput;
}

// Response payloads

export interface QueryLogFilterValues {
  statuses?: QUERY_LOG_STATUS[];
  users?: string[];
  databases?: string[];
  apps?: string[];
  initiationTypes?: string[];
  drivers?: string[];
  queryLanguages?: string[];
  gqlStatuses?: string[];
}

export interface PaginatedQueryLogs {
  logs: QueryLog[];
  pageInfo: PaginationInfo;
}

export interface QueryLog {
  id: string;
  executionId: string;
  txId: number;
  query: string;
  queryLanguage: string;
  error: string;
  gqlError: GQLError | null;
  startTime: number;
  endTime: number;
  status: QUERY_LOG_STATUS;
  executionTimeMs: number;
  planningTimeMs: number;
  waitingTimeMs: number;
  allocatedBytes: number;
  pageHits: number;
  pageFaults: number;
  user: string;
  database: string;
  driver: string;
  runtime: string;
  app: string;
  initiationType: string;
}

export interface PaginationInfo {
  totalItems: number;
  page: number;
  perPage: number;
}

export interface PaginatedQueryLogAggregations {
  logAggregations: QueryLogAggregation[];
  pageInfo: PaginationInfo;
}

export interface QueryLogAggregation {
  id: string;
  queryId: string;
  query: string;
  queryLanguage: string;
  error: string;
  executionCount: number;
  totalTimeSpent: number;
  from: number;
  to: number;
  status: QUERY_LOG_STATUS;
  avgExecutionTimeMs: number;
  minExecutionTimeMs: number;
  maxExecutionTimeMs: number;
  avgPlanningTimeMs: number;
  avgWaitingTimeMs: number;
  avgAllocatedBytes: number;
  avgPageHits: number;
  avgPageFaults: number;
}

export interface GQLError {
  gqlStatus: string;
  classification: string;
  statusDescription: string;
  position: Position | null;
  cause: GQLError | null;
}

export interface Position {
  offset: number;
  line: number;
  column: number;
}

export interface QueryLogCounts {
  completedQueries: number;
  failedQueries: number;
  unfinishedQueries: number;
}

export interface LogsInitiateDownloadResponse {
  jobId: string;
}

export type LogsDownloadsResponse = LogDownloadInfo[];

export interface LogDownloadInfo {
  jobId: string;
  // fields populated from BQ job metadata
  status: DOWNLOAD_JOB_STATUS;
  requestTimestamp: number;
  exportedRows: number;
  metaFileContent: MetaFileContent;
}

type BaseMetaFileContent = {
  logSubType: LOG_SUB_TYPE;
  format: LOG_FORMAT;
  csvHeader: boolean;
  csvDelimiter: string;
  wasRetrieved: boolean;
  userId: string | null;
  userEmail: string | null;
};

export type QueryMetaFileContent = BaseMetaFileContent & {
  logType: DOWNLOADS_LOG_TYPE.QUERY;
  filters: QueryLogFilterInput;
  sort: QueryLogSort[];
};

export type SecurityMetaFileContent = BaseMetaFileContent & {
  logType: DOWNLOADS_LOG_TYPE.SECURITY;
  filters: SecurityLogFilterInput;
  sort: SecurityLogSort[];
};

export type MetaFileContent = QueryMetaFileContent | SecurityMetaFileContent;

export interface LogsDownloadUrlResponse {
  signedUrl: string;
}

export interface LogsDownloadDeleteResponse {
  deleted: boolean;
}

export interface PaginatedSecurityLogs {
  logs: SecurityLog[];
  pageInfo: PaginationInfo;
}

export interface SecurityLog {
  id: string;
  message: string;
  time: number;
  status: SECURITY_LOG_STATUS;
  authenticatedUser: string;
  executingUser: string;
  driver: string;
  database: string;
}

export interface PaginatedSecurityLogsSummary {
  logs: SecurityLogSummary[];
  pageInfo: PaginationInfo;
}

export interface SecurityLogSummary {
  id: string;
  from: number;
  to: number;
  message: string;
  logCount: number;
  status: SECURITY_LOG_STATUS;
  executingUser: string;
  authenticatedUsers: string;
}

export interface SecurityLogCounts {
  infoLogs: number;
  warningLogs: number;
  errorLogs: number;
}

export interface SecurityLogFilterValues {
  statuses?: SECURITY_LOG_STATUS[];
  executingUsers?: string[];
  authenticatedUsers?: string[];
  databases?: string[];
  drivers?: string[];
}
