import { type PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import {
  INITIAL_FILTER,
  INITIAL_INPUT_TEXT,
  INITIAL_LOCKED_SUGGESTIONS,
  INITIAL_SELECTED_SUGGESTION_PATH_IDX,
} from '../../../modules/SearchBar/SearchBar.const';
import {
  isActionSuggestion,
  isFullTextSearchSuggestion,
  isNodeSuggestion,
  isRelationshipSuggestion,
  isSearchPhraseSuggestion,
  lockFullTextSearchSuggestion,
  lockNodeSuggestion,
  lockRelationshipSuggestion,
} from '../../../modules/SearchBar/SearchBar.utils';
import { updateSearchTemplate } from '../../perspectives/perspectives';
import { fetchSearchPhraseSuggestionsThunk } from '../suggestions/search-phrase-suggestions/search-phrase-suggestions.thunk';
import { fetchSuggestionsThunk } from '../suggestions/search-suggestions.thunk';
import { NAME } from './search-core.const';
import { runSearchThunk } from './search-core.thunks';
import type { SearchCoreState } from './search-core.types';
import { SEARCH_STATUS } from './search-core.types';
import { generateSearchStatus } from './search-core.utils';

export const initialState: SearchCoreState = {
  filter: INITIAL_FILTER,
  inputText: INITIAL_INPUT_TEXT,
  selectedSuggestionPathIdx: INITIAL_SELECTED_SUGGESTION_PATH_IDX,
  lockedSuggestions: INITIAL_LOCKED_SUGGESTIONS,
  labelsWithNodesUnderLimit: [],
  autoRun: false,
  initialFocus: false,
  isGenAIAssistantEnabled: false,
  status: {
    status: SEARCH_STATUS.IDLE,
  },
};

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    updateFilter: (state, action: PayloadAction<SearchCoreState['filter']>) => {
      const filter = action.payload;
      state.filter = state.filter === filter ? INITIAL_FILTER : filter;
    },
    updateInputText: (state, action: PayloadAction<SearchCoreState['inputText']>) => {
      state.inputText = action.payload;
    },
    updateSelectedSuggestionPathIdx: (state, action: PayloadAction<SearchCoreState['selectedSuggestionPathIdx']>) => {
      state.selectedSuggestionPathIdx = action.payload;
    },
    updateLockedSuggestions: (state, action: PayloadAction<SearchCoreState['lockedSuggestions']>) => {
      state.lockedSuggestions = action.payload;
    },
    lockSuggestion: (state, action: PayloadAction<SearchCoreState['lockedSuggestions']>) => {
      let newLockedSuggestions = [...state.lockedSuggestions];
      const suggestion = action.payload;
      const head = suggestion?.at(0);
      let inputText = INITIAL_INPUT_TEXT;
      let selectedSuggestionPathIdx = INITIAL_SELECTED_SUGGESTION_PATH_IDX;

      if (isRelationshipSuggestion(head)) {
        newLockedSuggestions = lockRelationshipSuggestion(suggestion, newLockedSuggestions);
      } else if (isNodeSuggestion(head)) {
        newLockedSuggestions = lockNodeSuggestion(suggestion, newLockedSuggestions);
      } else if (isSearchPhraseSuggestion(head)) {
        newLockedSuggestions = suggestion;
        inputText = head.text;
      } else if (isActionSuggestion(head)) {
        newLockedSuggestions = suggestion;
        inputText = head.title;
      } else if (isFullTextSearchSuggestion(head)) {
        newLockedSuggestions = lockFullTextSearchSuggestion(head, newLockedSuggestions);
        inputText = state.inputText;
        selectedSuggestionPathIdx = state.selectedSuggestionPathIdx;
      }

      state.selectedSuggestionPathIdx = selectedSuggestionPathIdx;
      state.inputText = inputText;
      state.filter = INITIAL_FILTER;
      state.lockedSuggestions = newLockedSuggestions;
    },
    updateLabelsWithNodesUnderLimit: (state, action: PayloadAction<SearchCoreState['labelsWithNodesUnderLimit']>) => {
      state.labelsWithNodesUnderLimit = action.payload;
    },
    updateAutoRun: (state, action: PayloadAction<SearchCoreState['autoRun']>) => {
      state.autoRun = action.payload;
    },
    updateInitialFocus: (state, action: PayloadAction<SearchCoreState['initialFocus']>) => {
      state.initialFocus = action.payload;
    },
    updateIsGenAIAssistantEnabled: (state, action: PayloadAction<SearchCoreState['isGenAIAssistantEnabled']>) => {
      state.isGenAIAssistantEnabled = action.payload;
    },
    resetSearchState: (state) => {
      state.lockedSuggestions = INITIAL_LOCKED_SUGGESTIONS;
      state.selectedSuggestionPathIdx = INITIAL_SELECTED_SUGGESTION_PATH_IDX;
      state.inputText = INITIAL_INPUT_TEXT;
      state.filter = INITIAL_FILTER;
    },
    updateGenAiLastAskedQuestion: (
      state,
      action: PayloadAction<SearchCoreState['genAiLastAskedQuestion'] | undefined>,
    ) => {
      state.genAiLastAskedQuestion = action.payload;
    },
    updateStatus: (state, action: PayloadAction<SearchCoreState['status']>) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(runSearchThunk.pending, (state) => {
        state.status = { status: SEARCH_STATUS.FETCHING_DATA };
      })
      .addCase(fetchSuggestionsThunk.pending, (state) => {
        state.status = { status: SEARCH_STATUS.FETCHING_SUGGESTIONS };
      })
      .addCase(fetchSuggestionsThunk.fulfilled, (state) => {
        state.status = { status: SEARCH_STATUS.IDLE };
      })
      .addCase(fetchSuggestionsThunk.rejected, (state, action) => {
        state.status = generateSearchStatus({ error: action.payload });
      })
      .addCase(fetchSearchPhraseSuggestionsThunk.rejected, (state, action) => {
        state.status = generateSearchStatus({ error: action.payload });
      })
      .addCase(updateSearchTemplate, (state, action) => {
        const shouldResetState = state.lockedSuggestions.some(
          (suggestion) => isSearchPhraseSuggestion(suggestion) && suggestion.id === action.payload.templateId,
        );
        if (shouldResetState) {
          state.lockedSuggestions = INITIAL_LOCKED_SUGGESTIONS;
          state.selectedSuggestionPathIdx = INITIAL_SELECTED_SUGGESTION_PATH_IDX;
          state.inputText = INITIAL_INPUT_TEXT;
          state.filter = INITIAL_FILTER;
        }
      });
  },
});

export const {
  updateFilter,
  updateInputText,
  updateSelectedSuggestionPathIdx,
  updateLockedSuggestions,
  lockSuggestion,
  updateLabelsWithNodesUnderLimit,
  updateAutoRun,
  updateInitialFocus,
  updateIsGenAIAssistantEnabled,
  resetSearchState,
  updateGenAiLastAskedQuestion,
  updateStatus,
} = slice.actions;
export default slice.reducer;
