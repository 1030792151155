import { Banner, Button, Dialog, Select, TextLink, Typography } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { consoleApi, dataScienceApi, getApiError, getErrorMessage, useActiveOrg, useNotifications } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useMemo, useState } from 'react';

import { GdsOffering } from '../../../utils/gds-offering';
import { maxConcurrentSessionOptions } from './options';

const logger = createLogger(APP_SCOPE.framework);

interface ModifyLimitsWarningProps {
  candidateMaxMemoryPerSession: Option<string>;
  candidateMaxConcurrentSessions: Option<number>;
}

const ModifyWarningMessage = (props: ModifyLimitsWarningProps) => {
  const activeOrg = useActiveOrg();

  const getSessionsQuery = dataScienceApi.useGetSessionsQuery({ organizationId: activeOrg.id });
  const getProjectsQuery = consoleApi.useListOrganizationProjectsQuery({ organizationId: activeOrg.id });

  const currentNumberOfSessionsInOrganization = (getSessionsQuery.data ?? []).length;

  const [sessionsExceedingMaxMemoryLimit, projectsWithSessionsExceedingMaxMemoryLimit] = useMemo(() => {
    let projects = getProjectsQuery.data ?? [];
    let sessions = getSessionsQuery.data ?? [];

    const maxOffering = GdsOffering.parse(props.candidateMaxMemoryPerSession.value);

    sessions = sessions.filter(({ memory }) => GdsOffering.parse(memory).compareTo(maxOffering) > 0);

    projects = Array.from(new Set(sessions.map(({ projectId }) => projectId)))
      .map((projectId) => projects.find((project) => project.id === projectId))
      .filter((project) => project !== undefined);

    return [sessions, projects];
  }, [getProjectsQuery.data, getSessionsQuery.data, props.candidateMaxMemoryPerSession.value]);

  if (!getProjectsQuery.isSuccess || !getSessionsQuery.isSuccess) {
    // todo: handle errors
    return null;
  }

  return (
    <div className="flex flex-col gap-y-6">
      {sessionsExceedingMaxMemoryLimit.length > 0 && (
        <Banner type="warning" hasIcon>
          Lowering max memory per session to {props.candidateMaxMemoryPerSession.label} will <b>not</b> impact the{' '}
          {sessionsExceedingMaxMemoryLimit.length} running sessions exceeding this limit in
          <ul className="mt-2">
            {projectsWithSessionsExceedingMaxMemoryLimit.map((project) => (
              <li key={project.id} className="italic">
                <TextLink href={`/projects/${project.id}/sessions`} isExternalLink>
                  {project.name}
                </TextLink>
              </li>
            ))}
          </ul>
        </Banner>
      )}
      {props.candidateMaxConcurrentSessions.value < currentNumberOfSessionsInOrganization && (
        <Banner type="warning" hasIcon>
          Lowering the max concurrent sessions limit to {props.candidateMaxConcurrentSessions.label} will <b>not</b>{' '}
          impact any of the {currentNumberOfSessionsInOrganization} active sessions currently running in the
          organization.
        </Banner>
      )}
    </div>
  );
};

interface ModifyGdsSessionsLimitsModalProps {
  onClose: () => void;
}

interface Option<T> {
  value: T;
  label: string;
}

export const ModifyGdsSessionsLimitsModal = ({ onClose }: ModifyGdsSessionsLimitsModalProps) => {
  const { addNotification } = useNotifications();
  const [selectedMaxMemoryPerSession, setSelectedMaxMemoryPerSession] = useState<Option<string> | null>(null);
  const [selectedMaxConcurrentSessions, setSelectedMaxConcurrentSessions] = useState<Option<number> | null>(null);

  const activeOrg = useActiveOrg();

  const gdsOfferingsQuery = consoleApi.useGetGdsOfferingsQuery();

  const sizingOptions = gdsOfferingsQuery.isSuccess
    ? gdsOfferingsQuery.data.offerings.map(({ offering }) => ({ label: offering, value: offering }))
    : [];

  const currentMaxMemoryPerSession = {
    value: activeOrg.gdsSessions.maxMemoryPerSession,
    label: activeOrg.gdsSessions.maxMemoryPerSession,
  };

  const currentMaxConcurrentSessions = {
    value: activeOrg.gdsSessions.maxConcurrentSessions,
    label: String(activeOrg.gdsSessions.maxConcurrentSessions),
  };

  const [updateOrg] = consoleApi.useUpdateOrganizationMutation();

  const handleModifyLimits = () => {
    updateOrg({
      organizationId: activeOrg.id,
      gdsSessionsMaxMemoryPerSession: (selectedMaxMemoryPerSession ?? currentMaxMemoryPerSession).value,
      gdsSessionsMaxConcurrentSessions: (selectedMaxConcurrentSessions ?? currentMaxConcurrentSessions).value,
    })
      .unwrap()
      .then(() => {
        addNotification({
          type: 'success',
          title: `Update succeeded`,
          description: 'Sucessfully updated session limits',
          timeout: 5000,
        });
      })
      .catch((e: FetchBaseQueryError | SerializedError) => {
        const error = getApiError(e);
        if (isNotNullish(error.message)) {
          logger.error(error.message);
        }
        addNotification({
          type: 'danger',
          title: `Failed to modify limits`,
          description: getErrorMessage(error),
          timeout: 5000,
        });
      })
      .finally(() => {
        setSelectedMaxMemoryPerSession(null);
        setSelectedMaxConcurrentSessions(null);
      });
    // deliberately close before promise resolved
    onClose();
  };

  const confirmIsDisabled = [selectedMaxMemoryPerSession, selectedMaxConcurrentSessions].every(
    (selection) => selection === null,
  );

  return (
    <Dialog isOpen onClose={onClose}>
      <Dialog.Header>Modify Limits</Dialog.Header>
      <Dialog.Content>
        <Typography variant="h6" className="mb-4 font-bold">
          Limits
        </Typography>
        <div className="mb-8 flex flex-col gap-y-4">
          <Select
            type="select"
            size="medium"
            selectProps={{
              menuPosition: 'fixed',
              placeholder: 'Select',
              isLoading: gdsOfferingsQuery.isLoading,
              options: sizingOptions,
              value: selectedMaxMemoryPerSession ?? currentMaxMemoryPerSession,
              onChange: setSelectedMaxMemoryPerSession,
            }}
            label="Max memory per session"
            htmlAttributes={{
              'aria-label': 'Dropdown menu for Max memory per session',
            }}
          />

          <Select
            type="select"
            size="medium"
            isDisabled={false}
            selectProps={{
              menuPosition: 'fixed',
              placeholder: 'Select',
              options: maxConcurrentSessionOptions,
              value: selectedMaxConcurrentSessions ?? currentMaxConcurrentSessions,
              onChange: setSelectedMaxConcurrentSessions,
            }}
            label="Max concurrent sessions in organization"
            htmlAttributes={{
              'aria-label': 'Dropdown menu for Max concurrent sessions in organization',
            }}
          />
        </div>
        <ModifyWarningMessage
          candidateMaxMemoryPerSession={selectedMaxMemoryPerSession ?? currentMaxMemoryPerSession}
          candidateMaxConcurrentSessions={selectedMaxConcurrentSessions ?? currentMaxConcurrentSessions}
        />
      </Dialog.Content>
      <Dialog.Actions>
        <Button fill="outlined" color="neutral" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleModifyLimits} isDisabled={confirmIsDisabled}>
          Confirm
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
