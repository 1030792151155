//
// Request and response types
//
export interface GetDataApisRequest {
  projectId: string;
  instanceId: string;
}

export interface GetDataApisResponse {
  data: DataApi[];
}

export interface GetDataApisBatchRequest {
  projectId: string;
  instanceId: string;
  instanceName: string;
}

export type GetDataApisBatchResponse = DataApiBatchResult[];

export interface GetDataApiRequest {
  projectId: string;
  instanceId: string;
  dataApiId: string;
}

export interface GetDataApiResponse {
  data: {
    id: string;
    name: string;
    status: DATA_API_STATUS;
    aura_instance: {
      id: string;
    };
    url: string;
    type_definitions: string;
    features: {
      subgraph: boolean;
    };
    security: {
      cors_policy: {
        allowed_origins: string[];
      };
    };
  };
  errors?: DataApiError[];
}

export interface GetDataApiResponseTransformed {
  data: DataApiModify;
  errors?: DataApiError[];
}

export type CreateDataApiRequest = DataApiModify & {
  projectId: string;
};

export interface CreateDataApiRequestBody {
  name: string;
  aura_instance: {
    username: string;
    password: string;
  };
  type_definitions: string;
  features?: {
    subgraph: boolean;
  };
  security?: {
    cors_policy?: {
      allowed_origins?: string[];
    };
    authentication_providers: CreateDataApiAuthProvider[];
  };
}

export interface CreateDataApiResponse {
  data: {
    id: string;
    name: string;
    status: DATA_API_STATUS;
    url: string;
    authentication_providers: DataApiAuthProvider[];
  };
}

export interface DeleteDataApiRequest {
  projectId: string;
  instanceId: string;
  dataApiId: string;
}

export interface DeleteDataApiResponse {
  data: {
    id: string;
    name: string;
    status: DATA_API_STATUS;
    url: string;
  };
}

export type UpdateDataApiRequest = DataApiModify & {
  projectId: string;
  instanceId: string;
  dataApiId: string;
};

export interface UpdateDataApiRequestBody {
  name?: string;
  type_definitions?: string;
  aura_instance?: {
    username?: string;
    password?: string;
  };
  features?: {
    subgraph?: boolean;
  };
  security?: {
    cors_policy?: {
      allowed_origins?: string[];
    };
  };
}

export interface UpdateDataApiResponse {
  data: {
    id: string;
    name: string;
    status: DATA_API_STATUS;
    url: string;
  };
}

export interface PauseDataApiRequest {
  projectId: string;
  instanceId: string;
  dataApiId: string;
}

export interface PauseDataApiResponse {
  data: {
    id: string;
    name: string;
    status: DATA_API_STATUS;
    url: string;
  };
}

export interface ResumeDataApiRequest {
  projectId: string;
  instanceId: string;
  dataApiId: string;
}

export interface ResumeDataApiResponse {
  data: {
    id: string;
    name: string;
    status: DATA_API_STATUS;
    url: string;
  };
}

// Auth providers

export interface GetDataApiAuthProvidersBatchRequest {
  projectId: string;
  instanceId: string;
  dataApiId: string;
}

export type GetDataApiAuthProvidersResponse = {
  data: DataApiAuthProvidersResult[];
};

export type GetDataApiDetailedAuthProvidersResponse = {
  data: DataApiAuthProviderBatchResult;
};

export type GetDataApiAuthProvidersBatchResponse = DataApiAuthProviderBatchResult[];

export interface UpdateDataApiAuthProviderRequest {
  projectId: string;
  instanceId: string;
  dataApiId: string;
  authProviderId: string;
  name?: string;
  enabled?: boolean;
  url?: string;
}

export interface UpdateDataApiAuthProviderRequestBody {
  name?: string;
  enabled?: boolean;
  url?: string;
}

export interface UpdateDataApiAuthProviderResponse {
  data: DataApiAuthProviderBatchResult;
}

export type CreateDataApiAuthProviderRequest = CreateDataApiAuthProvider & {
  projectId: string;
  instanceId: string;
  dataApiId: string;
};

export type CreateDataApiAuthProviderRequestBody = CreateDataApiAuthProvider;

export interface CreateDataApiAuthProviderResponse {
  data: DataApiAuthProvider;
}

export interface DeleteDataApiAuthProviderRequest {
  projectId: string;
  instanceId: string;
  dataApiId: string;
  authProviderId: string;
}

export interface DeleteDataApiAuthProviderResponse {
  data: DataApiAuthProviderBatchResult;
}

//
// General types
//
export interface DataApi {
  id: string;
  name: string;
  status: DATA_API_STATUS;
  url: string;
}

export interface DataApiBatchResult extends DataApi {
  instanceId: string;
  instanceName: string;
}

export interface DataApiModify {
  id: string;
  name: string;
  instanceId: string;
  instanceUsername: string;
  instancePassword: string;
  typeDefinitions: string;
  featuresSubgraph?: boolean;
  allowedOrigins: string[];
  authProviders: DataApiAuthProviderModify[];
}

export interface DataApiAuthProviderModify {
  id?: string;
  name: string;
  type: DATA_API_AUTH_PROVIDER_TYPE;
  enabled: boolean;
  url?: string;
}

export interface DataApiAuthProvider {
  id: string;
  name: string;
  type: DATA_API_AUTH_PROVIDER_TYPE;
  enabled: boolean;
  url?: string;
  key?: string;
}

export type DataApiAuthProvidersResult = Omit<DataApiAuthProvider, 'key' | 'url'>;

export type DataApiAuthProviderBatchResult = Omit<DataApiAuthProvider, 'key'>;

export interface CreateDataApiAuthProvider {
  name: string;
  type: DATA_API_AUTH_PROVIDER_TYPE;
  enabled: boolean;
  url?: string;
}

export type ModifyDataApiAuthProvider = CreateDataApiAuthProvider & {
  id?: string;
};

export interface DataApiError {
  reason: string;
  message: string;
}

export enum DATA_API_STATUS {
  creating = 'creating',
  updating = 'updating',
  ready = 'ready',
  deleting = 'deleting',
  pausing = 'pausing',
  paused = 'paused',
  resuming = 'resuming',
  error = 'error',
}

export enum DATA_API_AUTH_PROVIDER_TYPE {
  apiKey = 'api-key',
  jwks = 'jwks',
}
