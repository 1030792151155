import { Divider } from '@neo4j-ndl/react';
import { isNullish } from '@nx/stdlib';
import cx from 'classnames';

export const DividerWithLabel = ({ label, className }: { label: string; className?: string }) => (
  <div className={cx('flex items-center', className)}>
    <Divider />
    {!isNullish(label) && <span className="px-[32px] py-[0] font-semibold uppercase">{label}</span>}
    <Divider />
  </div>
);
