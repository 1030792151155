import { QUERY_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE, type QueryResultWithLimit } from '@nx/constants';
import { LEGACY_store, trackEvent } from '@nx/state';
import type { FrameType } from '@query/redux/commands';
import { cmdNotInWorkspace } from '@query/redux/editor-slice';
import type { SidebarTabId } from '@query/redux/sidebar-slice';
import type { FormatChange } from '@query/utils/format-analytics-utils';
import {
  getIndentationCount,
  getNewlineCount,
  getSpaceCount,
  levenshteinDistance,
} from '@query/utils/format-analytics-utils';
import { parseParamArgs } from '@query/utils/params-arg-parser';

import type { ResultViewName } from '../../stream/cypher-frame/view-picker';

export type CommandSource =
  | 'background-cmd'
  | 'other-nx-app'
  | 'main-editor-click'
  | 'main-editor-enter'
  | 'frame-editor-click'
  | 'frame-editor-enter'
  | 'main-codemirror-click'
  | 'main-codemirror-enter'
  | 'frame-codemirror-click'
  | 'frame-codemirror-enter'
  | 'db-info-label'
  | 'db-info-property'
  | 'db-info-relationship'
  | 'error-frame-rerun-button'
  | 'error-frame-example-codeblock'
  | 'saved-cypher-sidebar-click'
  | 'post-connect-cmd'
  | 'sysinfo-frame-auto-refresh';

export const NON_TRACKABLE_COMMAND_SOURCES = ['sysinfo-frame-auto-refresh'];

const track = (name: QUERY_EVENTS, properties?: Record<string, unknown>) =>
  LEGACY_store.dispatch(trackEvent({ scope: APP_SCOPE.query, event: name, properties }));

export const trackFrameFullscreen = (on: boolean) => track(QUERY_EVENTS.FRAME_FULLSCREEN, { on });

export const trackFrameMinimized = (on: boolean) => track(QUERY_EVENTS.FRAME_MINIMIZED, { on });

export const trackFrameClosed = () => track(QUERY_EVENTS.FRAME_CLOSED);

export const trackEditorToggled = (on: boolean) => track(QUERY_EVENTS.EDITOR_TOGGLED, { on });

export const trackSidebarOpened = (tabId: SidebarTabId) => track(QUERY_EVENTS.SIDEBAR_PANEL_OPENED, { drawer: tabId });

export const trackResultViewToggled = (view: ResultViewName, pretty?: boolean) =>
  track(QUERY_EVENTS.RESULT_VIEW_TOGGLED, { view, pretty });

// We can get rid of this once we remove the old editor
const normalizeCommandSource: Partial<Record<CommandSource, CommandSource>> = {
  'main-codemirror-click': 'main-editor-click',
  'main-codemirror-enter': 'main-editor-enter',
  'frame-codemirror-click': 'frame-editor-click',
  'frame-codemirror-enter': 'frame-editor-enter',
};

export const trackFrameCreated = ({
  frameType,
  source,
  wasRerun,
  cmd,
  lintingEnabled = false,
}: {
  frameType: FrameType;
  source: CommandSource;
  wasRerun: boolean;
  cmd: string;
  lintingEnabled?: boolean;
}) => {
  const dataToSend: Record<string, unknown> = {
    frameType,
    source: normalizeCommandSource[source] ?? source,
    wasRerun,
    lintingEnabled,
  };

  if (frameType === 'parameter') {
    // Reparsing to give better data without having to pass the result across the whole flow
    const paramData = parseParamArgs(cmd);
    dataToSend.frameSubType = paramData.arg === 'error' ? paramData.error.code : paramData.arg;
  }

  if (frameType === 'no-such-command') {
    if (cmdNotInWorkspace(cmd)) {
      dataToSend.isBrowserCmd = cmd.split(' ')[0];
    }
  }

  if (!NON_TRACKABLE_COMMAND_SOURCES.includes(source)) {
    track(QUERY_EVENTS.COMMAND_RAN, dataToSend);
  }
};

export const trackSuccessfulCypherQuery = (
  { records, summary, recordLimitHit }: QueryResultWithLimit,
  wasFormatted?: boolean,
) => {
  track(QUERY_EVENTS.CYPHER_QUERY, {
    success: true,
    recordCount: records.length,
    recordLimitHit: recordLimitHit ?? false,
    linesOfCypher: summary.query.text.split('\n').length,
    queryType: summary.queryType,
    containsSystemUpdates: summary.updateStatistics.containsSystemUpdates(),
    containsUpdates: summary.updateStatistics.containsUpdates(),
    wasFormatted: wasFormatted,
  });
};

export const trackFailedCypherQuery = (wasFormatted?: boolean, errorCode = 'Unknown') => {
  track(QUERY_EVENTS.CYPHER_QUERY, {
    success: false,
    wasFormatted: wasFormatted,
    errorCode,
  });
};

export const trackGraphInteraction = (action: 'NODE_EXPAND' | 'NODE_UNPINNED' | 'NODE_DISMISSED') =>
  track(QUERY_EVENTS.GRAPH_INTERACTION, { action });

export const trackNewSavedCypherFolderCreated = () => track(QUERY_EVENTS.SAVED_CYPHER_FOLDER_CREATED);

export const trackSavedCypherFolderUpdated = (id: string) => track(QUERY_EVENTS.SAVED_CYPHER_FOLDER_UPDATED, { id });

export const trackSavedCypherCreated = () => track(QUERY_EVENTS.SAVED_CYPHER_CREATED);

export const trackSavedCypherOverWrite = () => track(QUERY_EVENTS.SAVED_CYPHER_OVERWRITE);

export const trackSavedCypherUpdated = (id: string) => track(QUERY_EVENTS.SAVED_CYPHER_UPDATED, { id });

export const trackSavedCypherDeleted = ({ items, folders }: { items: number; folders: number }) =>
  track(QUERY_EVENTS.SAVED_CYPHER_DELETED, { items, folders });

export const trackSavedCypherInsertedIntoEditor = () => track(QUERY_EVENTS.SAVED_CYPHER_INSERTED_INTO_EDITOR);

export const trackSavedCypherMoved = (type: 'FOLDER' | 'ITEM') => track(QUERY_EVENTS.SAVED_CYPHER_MOVED, { type });

export const trackSavedCypherExported = ({ items, folders }: { items: number; folders: number }) =>
  track(QUERY_EVENTS.SAVED_CYPHER_EXPORTED, { items, folders });

export const trackSavedCypherImported = ({
  items,
  folders,
  conflicts,
}: {
  items: number;
  folders: number;
  conflicts: 'none' | 'accepted' | 'rejected';
}) => track(QUERY_EVENTS.SAVED_CYPHER_IMPORTED, { items, folders, conflicts });

export const trackDownloads = (
  type:
    | 'PLAN_VIEW_PNG'
    | 'PLAN_VIEW_SVG'
    | 'PLAN_VIEW_TXT'
    | 'GRAPH_VIEW_PNG'
    | 'TABLE_VIEW_CSV'
    | 'TABLE_VIEW_JSON'
    | 'HISTORY'
    | 'SYSINFO_CSV'
    | 'SYSINFO_JSON',
) => track(QUERY_EVENTS.DOWNLOAD, { type });

export const trackDeleteHistory = ({ countBefore, countAfter }: { countBefore: number; countAfter: number }) =>
  track(QUERY_EVENTS.DELETE_HISTORY_ENTRIES, { countBefore, countAfter });

export const trackEditorShortcutRan = (type: 'QUICK_HISTORY_SEARCH' | 'SAVE_CYPHER') =>
  track(QUERY_EVENTS.EDITOR_SHORTCUT_RAN, { type });

export type FormatType = 'FORMAT_CYPHER_BUTTON' | 'FORMAT_CYPHER_SHORTCUT';

export const trackFormatRan = (change: FormatChange, type: FormatType) =>
  track(QUERY_EVENTS.FORMAT_CYPHER_QUERY, {
    type,
    cost: levenshteinDistance(change),
    spaces: getSpaceCount(change),
    indentation: getIndentationCount(change),
    newline: getNewlineCount(change),
  });

export const trackTableFilterClicked = () => track(QUERY_EVENTS.TABLE_FILTER_BUTTON_CLICKED);

type CypherQueryGeneratedProperties = {
  success: boolean;
  error?: string;
  isRetry: boolean;
  schemaUsed?: boolean;
};

export const trackCypherQueryGenerated = (properties: CypherQueryGeneratedProperties) =>
  track(QUERY_EVENTS.CYPHER_QUERY_GENERATED, properties);

export const trackPreviewPageLoaded = (properties: { previewUI: boolean; hasTriedPreviewUI: boolean }) =>
  track(QUERY_EVENTS.PREVIEW_PAGE_LOAD, properties);

export const trackPreviewUiSwitched = (properties: {
  switchedTo: 'browser' | 'preview';
  timeSinceLastSwitch: number | null;
}) => track(QUERY_EVENTS.PREVIEW_UI_SWITCH, properties);

export const trackSuccessfulSysInfoCmd = () => track(QUERY_EVENTS.SYSINFO_CMD_RAN);

export const trackFailedSysInfoCmd = (errorCode = 'Unknown') =>
  track(QUERY_EVENTS.SYSINFO_CMD_FAILED, {
    success: false,
    errorCode,
  });
