import { ForceDirectedLayoutType, FreeLayoutType, GridLayoutType, HierarchicalLayoutType } from '@neo4j-nvl/base';
import type { NvlOptions } from '@neo4j-nvl/base';

import type { Layout } from './types';

export const CoordinateLayout = 'coordinate';
export const DEFAULT_COORDINATE_X_SCALE = 7000;
export const DEFAULT_COORDINATE_Y_SCALE = 4000;
export const layoutTypes: Layout[] = [ForceDirectedLayoutType, HierarchicalLayoutType, CoordinateLayout] as const;
export const layoutTypeMetadata: Record<Layout, { name: string; desc: string; nvlLayoutType: NvlOptions['layout'] }> = {
  [ForceDirectedLayoutType]: {
    name: 'Force-based layout',
    desc: 'Layout which mimics physics to give an evenly spread layout',
    nvlLayoutType: ForceDirectedLayoutType,
  },
  [HierarchicalLayoutType]: {
    name: 'Hierarchical layout',
    desc: 'Layout which highlights the main flow within a directed graph',
    nvlLayoutType: HierarchicalLayoutType,
  },
  [CoordinateLayout]: {
    name: 'Coordinate layout',
    desc: 'Layout which positions nodes according to coordinates defined on their properties',
    nvlLayoutType: FreeLayoutType,
  },
  [GridLayoutType]: {
    name: 'Grid layout',
    desc: 'Simple layout for tests',
    nvlLayoutType: GridLayoutType,
  },
};
