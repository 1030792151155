import { Banner, Button, DataGrid, IconButton, Label, LoadingSpinner } from '@neo4j-ndl/react';
import { ArrowDownTrayIconOutline, ExclamationTriangleIconOutline } from '@neo4j-ndl/react/icons';
import { type Invoice, consoleApi } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { DataGridHelpers, SearchField } from '@nx/ui';
import { ControlPanel } from '@nx/ui/src/control-panel';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { parseISO } from 'date-fns';
import { useState } from 'react';

import { formatDollars, safeFormat } from '../../../utils';

const columnHelper = createColumnHelper<Invoice>();

const columns = [
  columnHelper.accessor('number', {
    header: 'Invoice',
  }),
  columnHelper.accessor('periodStart', {
    header: 'Period',
    cell: (cx) => safeFormat(parseISO(cx.getValue()), 'MMM yyyy'),
  }),
  columnHelper.accessor('created', {
    header: 'Invoice date',
    cell: (cx) => safeFormat(parseISO(cx.getValue()), 'dd MMM yyyy'),
  }),
  columnHelper.accessor('dueDate', {
    header: 'Due date',
    cell: (cx) => {
      const dueDate = cx.getValue() ?? '';
      return dueDate.length > 0 ? safeFormat(parseISO(dueDate), 'dd MMM yyyy') : '';
    },
  }),
  columnHelper.accessor('amountDue', {
    header: 'Amount',
    cell: (cx) => {
      const currency = cx.row.original.currency ?? '';
      if (currency.toLowerCase() === 'usd') {
        return formatDollars(cx.getValue());
      } else if (currency.length > 0) {
        return (
          <>
            <span>{cx.getValue()}</span>
            <span className="ml-2">({currency.toUpperCase()})</span>
          </>
        );
      }
      return cx.getValue();
    },
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    cell: (cx) => {
      const status = cx.getValue() ?? '';
      return (
        status.length > 0 && (
          <Label
            color={status.toLowerCase() === 'paid' ? 'success' : 'default'}
            className="capitalize"
            fill="outlined"
            htmlAttributes={{
              'data-testid': 'invoice-status',
            }}
          >
            {status}
          </Label>
        )
      );
    },
    enableResizing: false,
  }),
  columnHelper.display({
    id: 'actions',
    cell: (cell) => {
      const invoice = cell.row.original;
      return (
        <div className="flex grow flex-row justify-end gap-4">
          {isNotNullish(invoice.hostedInvoiceUrl) && invoice.status === 'open' && (
            <Button
              as="a"
              className="shrink-0"
              fill="outlined"
              color="danger"
              size="small"
              htmlAttributes={{
                href: invoice.hostedInvoiceUrl,
                target: '_blank',
              }}
            >
              <ExclamationTriangleIconOutline className="size-4" />
              Pay invoice
            </Button>
          )}
          {isNotNullish(invoice.invoicePdf) && (
            <IconButton
              as="a"
              size="small"
              isClean
              ariaLabel="Download invoice"
              htmlAttributes={{
                href: invoice.invoicePdf,
                target: '_blank',
              }}
            >
              <ArrowDownTrayIconOutline />
            </IconButton>
          )}
        </div>
      );
    },
  }),
];

type Props = {
  projectId: string;
};

export const Invoices = ({ projectId }: Props) => {
  const { data: invoices = [], isLoading } = consoleApi.useGetInvoicesQuery(projectId);
  const [globalFilter, setGlobalFilter] = useState('');

  const table = useReactTable({
    data: invoices,
    columns,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    state: {
      globalFilter,
    },
  });

  if (isLoading) {
    return (
      <ControlPanel className="flex justify-center rounded-tl-none">
        <LoadingSpinner size="large" />
      </ControlPanel>
    );
  }

  if (invoices.length === 0) {
    return (
      <Banner
        type="info"
        hasIcon
        className="my-4"
        usage="inline"
        name="notes"
        htmlAttributes={{
          'data-testid': 'invoices-no-data',
        }}
      >
        There are no invoices for this project.
      </Banner>
    );
  }

  return (
    <DataGridHelpers.Wrapper tabbed>
      <DataGridHelpers.OuterHeader>
        <div className="flex w-full flex-wrap justify-between gap-2">
          <div className="flex basis-[400px] gap-2">
            <SearchField
              isFluid
              className="min-w-36 grow"
              onChange={(e) => setGlobalFilter(e.target.value)}
              htmlAttributes={{
                'aria-label': 'Filter invoices',
              }}
            />
          </div>
        </div>
      </DataGridHelpers.OuterHeader>
      <DataGrid
        isResizable={true}
        tableInstance={table}
        styling={{ headerStyle: 'clean' }}
        isKeyboardNavigable={false}
      />
    </DataGridHelpers.Wrapper>
  );
};
