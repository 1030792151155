import { FOLDERS_COLLAPSED_KEY } from '@query/redux/saved-cypher-slice';
import type { FlatSavedCypherFolder, FlatSavedCypherItem } from '@query/types/saved-cypher';
import { isFlatSavedCypherFolder } from '@query/types/saved-cypher';

export const getItemFromLocalStorage = <T>(key: string, initialValue: T): T => {
  const value = localStorage.getItem(key);
  if (value === null) {
    return initialValue;
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return JSON.parse(value) as T;
};

export const setItemInLocalStorage = <T>(key: string, value: T): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getChildrenForSideLine = (items: FlatSavedCypherItem[], parent: FlatSavedCypherFolder): string[] => {
  const children = items.filter((item) => item.parentId === parent.id);

  if (children.length === 0) {
    return [];
  }

  const foldersCollapsed = getItemFromLocalStorage<Record<string, boolean>>(FOLDERS_COLLAPSED_KEY, {});

  const result = children.reduce<string[]>((acc, child, index) => {
    if (isFlatSavedCypherFolder(child)) {
      const childItems = [...acc, child.id];
      const collapsed = foldersCollapsed[child.id] ?? false;

      if (!collapsed && index !== children.length - 1) {
        return [...childItems, ...getChildrenForSideLine(items, child)];
      }
    }
    return [...acc, child.id];
  }, []);

  return result;
};
