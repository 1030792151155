import { Banner, LoadingSpinner } from '@neo4j-ndl/react';
import { AURA_CONSOLE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { consoleApi, getApiError, getErrorMessage } from '@nx/state';
import { Center } from '@nx/ui';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useTrackUpxEvent } from '../services/segment/analytics';

type Props = {
  cloudProvider: 'aws' | 'azure';
  customerIdQueryParam: string;
  productIdQueryParam: string;
};

const friendlyCloudProviderNameMap = {
  aws: 'AWS',
  azure: 'Azure',
};

const TackleMarketplaceRegistration = ({ cloudProvider, customerIdQueryParam, productIdQueryParam }: Props) => {
  const trackEvent = useTrackUpxEvent();
  const navigate = useNavigate();
  const [registerMarketplaceOrder] = consoleApi.useRegisterMarketplaceOrderMutation();
  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  // React 18+ in Strict Mode "helpfully" unmounts and mounts everything,
  // causing every useEffect to run twice. This is not what we want when
  // our useEffect calls a mutating API endpoint, hence the weird useRef.
  // see: https://react.dev/reference/react/StrictMode?#fixing-bugs-found-by-re-running-effects-in-development
  const hasCalledRegister = useRef(false);

  const customerId = searchParams.get(customerIdQueryParam);
  const productId = searchParams.get(productIdQueryParam);
  const invalidCustomerId = customerId === null || customerId === '';
  const invalidProductId = productId === null || productId === '';

  useEffect(() => {
    if (hasCalledRegister.current) {
      return;
    }
    if (invalidCustomerId || invalidProductId) {
      return;
    }

    void registerMarketplaceOrder({
      customerId,
      productId,
      marketplace: cloudProvider,
    })
      .unwrap()
      .then((projectId) => {
        navigate({ pathname: `/projects/${projectId}/instances` });
      })
      .catch((err: FetchBaseQueryError | SerializedError) => {
        const apiError = getApiError(err);
        if (apiError.code === 404) {
          setErrorMessage('Failed to find marketplace order.');
        } else if (apiError.code === 409) {
          setErrorMessage(
            'To gain access to this project, request an invitation from the administrator.' +
              ' Access is controlled via the Neo4j Aura console.',
          );
        } else {
          setErrorMessage(getErrorMessage(apiError));
        }
      });

    // eslint-disable-next-line consistent-return
    return () => {
      hasCalledRegister.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  if (invalidCustomerId || invalidProductId) {
    return (
      <Banner
        className="m-4"
        title="Invalid URL"
        description={
          <>
            {invalidCustomerId && <p>Missing query parameter {customerIdQueryParam}</p>}
            {invalidProductId && <p>Missing query parameter {productIdQueryParam}</p>}
          </>
        }
        isCloseable={false}
        type="danger"
        usage="inline"
      />
    );
  }

  if (errorMessage !== null) {
    return (
      <Banner
        className="m-4"
        description={
          <div>
            <p>{errorMessage}</p>
            <p className="mt-4">
              If you need immediate help from our customer service team about an ongoing order, please{' '}
              <a
                className="ndl-text-link"
                onClick={() => {
                  trackEvent({
                    event: AURA_CONSOLE_EVENTS.MARKETPLACE_SUPPORT_LINK_CLICK,
                    scope: APP_SCOPE.aura,
                  });
                }}
                href="https://support.neo4j.com/s/"
                target="_blank"
                rel="noreferrer"
              >
                contact us
              </a>{' '}
              and include the following Customer ID: {customerId} in your ticket.
            </p>
          </div>
        }
        isCloseable={false}
        type="danger"
        usage="inline"
      />
    );
  }

  return (
    <Center className="flex-col">
      <LoadingSpinner size="large" />
      <p className="mt-4">Verifying {friendlyCloudProviderNameMap[cloudProvider]} marketplace order details</p>
    </Center>
  );
};

export const AWSMarketplaceRegistration = () => {
  return (
    <TackleMarketplaceRegistration
      cloudProvider="aws"
      customerIdQueryParam="aws_customer_id"
      productIdQueryParam="aws_product_id"
    />
  );
};

export const AzureMarketplaceRegistration = () => {
  return (
    <TackleMarketplaceRegistration
      cloudProvider="azure"
      customerIdQueryParam="azure_customer_id"
      productIdQueryParam="azure_product_id"
    />
  );
};
