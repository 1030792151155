import { LoadingSpinner, Typography } from '@neo4j-ndl/react';
import { ExclamationTriangleIconOutline } from '@neo4j-ndl/react/icons';

type BannerProps = {
  text: string;
  icon?: JSX.Element;
};

const Banner = ({ text, icon }: BannerProps) => (
  <div className="flex h-full w-full items-center justify-center gap-2">
    <Typography variant="h6" className="text-palette-neutral-text-weaker">
      {text}
    </Typography>
    {icon}
  </div>
);

export const FetchingErrorBanner = ({ classification }: { classification?: unknown }) => {
  let text = 'Error while fetching data';
  if (classification === 'RateLimited') {
    text = 'Too many requests - try again later';
  }
  return <Banner text={text} icon={<ExclamationTriangleIconOutline className="text-palette-danger-icon square-5" />} />;
};

export const NoDataBanner = () => <Banner text="No data available" />;

export const LoadingBanner = ({ text }: { text?: string }) => (
  <Banner text={text ?? 'Loading data'} icon={<LoadingSpinner />} />
);
