import { PROPERTY_CONDITION_EQUALS } from '../../../modules/SearchBar/SearchBar.const';
import type { NodeSuggestion, Suggestion } from '../../../modules/SearchBar/types';
import type { NonUndefined } from '../../../types/utility';

export interface SuggestionsState {
  suggestions: (Suggestion | Suggestion[])[];
  hasSearchPhraseSuggestionFilter: boolean;
  locked: boolean;
}

type CategorySuggestion = {
  categoryName: NodeSuggestion['categoryName'];
  type: NodeSuggestion['type'];
};

export const isCategorySuggestion = (item: Suggestion | Suggestion[]): item is CategorySuggestion => {
  return (
    !Array.isArray(item) &&
    Object.keys(item).length === 2 &&
    'categoryName' in item &&
    typeof item.categoryName === 'string' &&
    'type' in item &&
    item.type === 'NODE'
  );
};

type NodePropertyEqualitySuggestion = {
  categoryName: NodeSuggestion['categoryName'];
  type: NodeSuggestion['type'];
  propertyCondition: 'equals';
  propertyConditionValue: NonUndefined<NodeSuggestion['propertyConditionValue']>;
};

export const isNodePropertyEqualitySuggestion = (
  item: Suggestion | Suggestion[],
): item is NodePropertyEqualitySuggestion => {
  return (
    !Array.isArray(item) &&
    'categoryName' in item &&
    typeof item.categoryName === 'string' &&
    'type' in item &&
    item.type === 'NODE' &&
    'propertyCondition' in item &&
    item.propertyCondition === PROPERTY_CONDITION_EQUALS &&
    'propertyConditionValue' in item &&
    typeof item.propertyConditionValue === 'string'
  );
};
