import type { MarketplaceRegisterPayload, TransformedMarketplaceRegisterPayload } from '../console.api.types';

export const transformMarketplaceRegisterPayload = ({
  customerId,
  productId,
  marketplace,
}: MarketplaceRegisterPayload): TransformedMarketplaceRegisterPayload => {
  return {
    CustomerId: customerId,
    ProductId: productId,
    Marketplace: marketplace,
  };
};
