import { isNotNullish } from '@nx/stdlib';

import type {
  CreateSsoConfigRequest,
  OrganizationParam,
  ProjectParam,
  SsoConfigParams,
  SsoConfigResponse,
  UpdateSsoConfigRequest,
} from '../console.api.types';
import type { SsoConfig } from '../console.types';
import {
  transformCreateSsoConfigRequest,
  transformSsoConfigResponse,
  transformUpdateSsoConfigRequest,
} from '../transformers/sso-config';
import { type Builder, LIST_ID } from './types';

export const ssoConfigEndpoints = (builder: Builder) => ({
  getSsoConfig: builder.query<SsoConfig, SsoConfigParams>({
    query: ({ organizationId, ssoConfigId }) => `organizations/${organizationId}/sso-configs/${ssoConfigId}`,
    transformResponse: (ssoConfig: SsoConfigResponse) => transformSsoConfigResponse(ssoConfig),
    providesTags: (result, error, { organizationId, ssoConfigId }) => [
      { type: 'SsoConfig', organizationId, ssoConfigId },
    ],
  }),
  listSsoConfigsByOrganization: builder.query<SsoConfig[], OrganizationParam>({
    query: ({ organizationId }) => `organizations/${organizationId}/sso-configs`,
    transformResponse: (ssoConfigs: SsoConfigResponse[]) =>
      ssoConfigs.map((ssoConfig) => transformSsoConfigResponse(ssoConfig)),
    providesTags: (result, error, { organizationId }) => {
      if (isNotNullish(result)) {
        return [
          ...result.map(({ id: ssoConfigId }) => ({ type: 'SsoConfig' as const, organizationId, ssoConfigId })),
          { type: 'SsoConfig', organizationId, ssoConfigId: LIST_ID },
        ];
      }
      return [{ type: 'SsoConfig', organizationId, ssoConfigId: LIST_ID }];
    },
  }),
  listSsoConfigsByProject: builder.query<SsoConfig[], ProjectParam>({
    query: ({ projectId }) => `namespaces/${projectId}/sso-configs`,
    transformResponse: (ssoConfigs: SsoConfigResponse[]) =>
      ssoConfigs.map((ssoConfig) => transformSsoConfigResponse(ssoConfig)),
    providesTags: (result, error, { projectId }) => {
      if (isNotNullish(result)) {
        return [
          ...result.map(({ id: ssoConfigId }) => ({ type: 'SsoConfig' as const, projectId, ssoConfigId })),
          { type: 'SsoConfig', projectId, ssoConfigId: LIST_ID },
        ];
      }
      return [{ type: 'SsoConfig', projectId, ssoConfigId: LIST_ID }];
    },
  }),
  createSsoConfig: builder.mutation<SsoConfig, CreateSsoConfigRequest>({
    query: (config) => ({
      url: `organizations/${config.organizationId}/sso-configs`,
      body: transformCreateSsoConfigRequest(config),
      method: 'POST',
    }),
    transformResponse: (ssoConfig: SsoConfigResponse) => transformSsoConfigResponse(ssoConfig),
    invalidatesTags: (result, error, { organizationId }) => [
      { type: 'SsoConfig', organizationId, ssoConfigId: result?.id },
      { type: 'Organization', id: organizationId },
    ],
  }),
  updateSsoConfig: builder.mutation<SsoConfig, UpdateSsoConfigRequest>({
    query: (config) => ({
      url: `organizations/${config.organizationId}/sso-configs/${config.ssoConfigId}`,
      body: transformUpdateSsoConfigRequest(config),
      method: 'PUT',
    }),
    transformResponse: (ssoConfig: SsoConfigResponse) => transformSsoConfigResponse(ssoConfig),
    invalidatesTags: (result, error, { organizationId }) => [
      { type: 'SsoConfig', organizationId, ssoConfigId: result?.id },
    ],
  }),
  deleteSsoConfig: builder.mutation<void, SsoConfigParams>({
    query: ({ organizationId, ssoConfigId }) => ({
      url: `organizations/${organizationId}/sso-configs/${ssoConfigId}`,
      method: 'DELETE',
    }),
    invalidatesTags: (result, error, { organizationId, ssoConfigId }) => [
      { type: 'SsoConfig', organizationId, ssoConfigId },
    ],
  }),
});
