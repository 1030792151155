import { FRAMEWORK_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { type RootState, selectCapability } from '@nx/state';
import { createSelector } from '@reduxjs/toolkit';

import type { HelpAndLearnProps } from './help-and-learn-button';

const IMPORT_MANUALS_URL = 'https://neo4j.com/docs/aura/import/introduction/';

export const selectHelpAndLearnConfig: (state: RootState) => Record<APP_SCOPE, HelpAndLearnProps> = createSelector(
  (state: RootState) => selectCapability(state, { deps: ['import:enabled'], key: 'import:changelog' }),
  (state: RootState) => selectCapability(state, { deps: ['import:enabled'], key: 'import:welcome-tutorial' }),
  (importChangelogEnabled, importWelcomeTutorialEnabled) => ({
    [APP_SCOPE.aura]: {
      appCues: [],
      guides: [],
      name: 'Aura',
      resources: [
        {
          title: 'Documentation',
          href: 'https://neo4j.com/docs/aura/preview/',
          name: 'documentation',
        },
        {
          title: 'Graph Academy',
          href: 'https://graphacademy.neo4j.com/?ref=aura-help',
          name: 'graph-academy',
        },
        {
          title: 'Community',
          href: 'https://dev.neo4j.com/discuss-aura',
          name: 'community',
        },
        {
          title: 'Support',
          href: 'https://support.neo4j.com/s/',
          name: 'support',
        },
      ],
      links: [
        {
          title: 'Status',
          href: 'https://status.neo4j.io/',
          name: 'status',
        },
        {
          title: 'Changelog',
          href: 'https://neo4j-aura.canny.io/changelog',
          name: 'changelog',
        },
      ],
      scope: APP_SCOPE.aura,
    },
    [APP_SCOPE.dashboards]: {
      appCues: [],
      guides: [],
      name: 'Dashboards',
      resources: [],
      links: [],
      scope: APP_SCOPE.dashboards,
    },
    [APP_SCOPE.conversations]: {
      appCues: [],
      guides: [],
      name: 'Conversations',
      resources: [],
      links: [],
      scope: APP_SCOPE.conversations,
    },
    [APP_SCOPE.debugger]: {
      appCues: [],
      guides: [],
      name: 'Debugger',
      resources: [],
      links: [
        {
          title: 'Status',
          href: 'https://status.neo4j.io/',
          name: 'status',
        },
        {
          title: 'Changelog',
          href: 'https://neo4j-aura.canny.io/changelog',
          name: 'changelog',
        },
      ],
      scope: APP_SCOPE.debugger,
    },
    [APP_SCOPE.explore]: {
      aboutLink: 'https://neo4j.com/product/bloom/',
      appCues: [],
      feedbackLink: 'https://neo4j-bloom.canny.io/feature-requests',
      guides: [],
      name: 'Explore',
      resources: [
        {
          title: 'Documentation',
          href: 'https://neo4j.com/docs/bloom-user-guide/current/',
          name: 'documentation',
        },
        {
          title: 'What’s New',
          href: 'https://neo4j-bloom.canny.io/changelog',
          name: 'whats-new',
        },
        {
          title: 'Graph Academy',
          href: 'https://neo4j.com/graphacademy/',
          name: 'graph-academy',
        },
        {
          title: 'Community',
          href: 'https://community.neo4j.com/',
          name: 'community',
        },
      ],
      links: [
        {
          title: 'Status',
          href: 'https://status.neo4j.io/',
          name: 'status',
        },
        {
          title: 'Changelog',
          href: 'https://neo4j-aura.canny.io/changelog',
          name: 'changelog',
        },
      ],
      scope: APP_SCOPE.explore,
    },
    [APP_SCOPE.import]: {
      appCues: importWelcomeTutorialEnabled
        ? [
            {
              title: 'Welcome tutorial',
              description: 'Watch a video on how to easily import your CSV data into Neo4j',
              event: FRAMEWORK_EVENTS.TRIGGER_WELCOME_APPCUES,
            },
          ]
        : [],
      guides: [],
      name: 'Import',
      resources: importChangelogEnabled
        ? [
            {
              title: 'What’s new',
              href: 'https://feedback.neo4j.com/changelog?labels=data-importer',
              name: 'whats-new',
            },
            {
              title: 'Documentation',
              href: IMPORT_MANUALS_URL,
              name: 'documentation',
            },
          ]
        : [
            {
              title: 'Documentation',
              href: IMPORT_MANUALS_URL,
              name: 'documentation',
            },
          ],
      links: [
        {
          title: 'Status',
          href: 'https://status.neo4j.io/',
          name: 'status',
        },
        {
          title: 'Changelog',
          href: 'https://neo4j-aura.canny.io/changelog',
          name: 'changelog',
        },
      ],
      scope: APP_SCOPE.import,
    },
    [APP_SCOPE.ops]: {
      appCues: [],
      guides: [],
      name: 'Ops',
      resources: [],
      links: [],
      scope: APP_SCOPE.ops,
    },
    [APP_SCOPE.framework]: {
      appCues: [],
      guides: [],
      name: 'Workspace',
      resources: [],
      links: [],
      scope: APP_SCOPE.framework,
    },
    [APP_SCOPE.query]: {
      appCues: [],
      guides: [],
      feedbackLink: 'https://feedback.neo4j.com/query',
      name: 'Query',
      resources: [
        {
          title: 'Documentation',
          href: 'https://neo4j.com/docs/browser-manual/current/',
          name: 'documentation',
        },
        {
          title: 'What’s new',
          href: 'https://feedback.neo4j.com/changelog?labels=query',
          name: 'whats-new',
        },
        {
          title: 'Graph Academy',
          href: 'https://neo4j.com/graphacademy/',
          name: 'graph-academy',
        },
        {
          title: 'Community',
          href: 'https://community.neo4j.com/',
          name: 'community',
        },
      ],
      links: [
        {
          title: 'Status',
          href: 'https://status.neo4j.io/',
          name: 'status',
        },
        {
          title: 'Changelog',
          href: 'https://neo4j-aura.canny.io/changelog',
          name: 'changelog',
        },
      ],
      scope: APP_SCOPE.query,
    },
    [APP_SCOPE.guides]: {
      appCues: [],
      guides: [],
      name: 'Guides',
      resources: [],
      links: [],
      scope: APP_SCOPE.guides,
    },
    [APP_SCOPE.test]: {
      appCues: [],
      guides: [],
      name: 'Test',
      resources: [],
      links: [],
      scope: APP_SCOPE.test,
    },
    [APP_SCOPE.desktop]: {
      appCues: [],
      guides: [],
      name: 'Test',
      resources: [],
      links: [],
      scope: APP_SCOPE.desktop,
    },
    [APP_SCOPE.dataApi]: {
      appCues: [],
      guides: [],
      name: 'GraphQL Data API',
      resources: [],
      links: [],
      scope: APP_SCOPE.dataApi,
    },
  }),
);
