import { isNotNullish } from '@nx/stdlib';
import classNames from 'classnames';
import { type ReactNode } from 'react';

import './instance-bar.css';

export const InstanceBar = ({ shadow, children }: { shadow?: boolean; children?: ReactNode | undefined }) => {
  return (
    <div className="min-h-8">
      {isNotNullish(shadow) && (
        <div
          className={classNames('shadow-animation shadow-overlay absolute min-h-8 w-full', {
            'opacity-0': !shadow,
          })}
        ></div>
      )}
      <header
        className={classNames(
          'bg-palette-neutral-bg-weak border-palette-neutral-border-weak flex min-h-8 w-full flex-wrap items-center justify-between border-b px-4',
          {
            absolute: isNotNullish(shadow),
          },
        )}
      >
        {children}
      </header>
    </div>
  );
};
