import { tokens } from '@neo4j-ndl/base';
import { Button, IconButton, TextLink, Typography } from '@neo4j-ndl/react';
import { ArrowPathIconOutline, ChevronRightIconOutline } from '@neo4j-ndl/react/icons';
import { OPS_EVENTS } from '@nx/analytics-service';
import {
  type Instance,
  MODAL_TYPE,
  type MetricsResult,
  TIER,
  useActiveProject,
  useModal,
  useOpsContext,
} from '@nx/state';
import { isNonEmptyString } from '@nx/stdlib';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { track } from '../services/segment/analytics';
import { ApiErrorBanner } from '../shared/components';
import { fakeMetrics } from '../shared/utils';
import { InstanceDashboardCharts } from './charts/instance-dashboard-charts';
import { DashboardLineCard } from './charts/line-cards';
import type { ChartContext } from './charts/types';
import { chartKey, useMetricsQuery, useMetricsViewState } from './hooks';
import { useViewCharts } from './hooks/use-view-charts';

interface InstanceDashboardMetricsProps {
  instance: Instance;
  chartContext: ChartContext;
}

const InstanceDashboardMetrics = ({ instance, chartContext }: InstanceDashboardMetricsProps) => {
  const instanceDashboardCharts = useViewCharts(instance, false).INSTANCE_DASHBOARD;
  const metricsResult = useMetricsQuery({
    selectedInstanceDetails: instance,
    timeRange: chartContext.timeRange,
    chartProps: instanceDashboardCharts,
    chartType: 'INSTANCE_DASHBOARD',
    useAggregations: false,
  });

  if (metricsResult.error) {
    return <ApiErrorBanner error={metricsResult.error} />;
  }

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
      {instanceDashboardCharts.map((chart) => (
        <DashboardLineCard
          key={chartKey(chart)}
          multiChartProps={chart}
          context={chartContext}
          metricsResult={metricsResult}
        />
      ))}
    </div>
  );
};

const FakeInstanceDashboardMetrics = ({
  instanceName,
  chartContext,
  overlayText,
  onTextClick,
}: Omit<InstanceDashboardMetricsProps, 'instance'> & {
  overlayText?: string;
  onTextClick?: () => void;
  instanceName: string;
}) => {
  const fakeMetricsResult: MetricsResult = useMemo(
    () => ({
      metrics: fakeMetrics(instanceName, '24H', InstanceDashboardCharts, 40),
      isLoading: false,
    }),
    [instanceName],
  );

  return (
    <div className="relative">
      <div
        className="pointer-events-none grid grid-cols-1 gap-4 opacity-40 blur-[2px] md:grid-cols-2 xl:grid-cols-3"
        aria-disabled={true}
      >
        {InstanceDashboardCharts.map((chart) => (
          <DashboardLineCard
            key={chartKey(chart)}
            multiChartProps={chart}
            context={chartContext}
            metricsResult={fakeMetricsResult}
          />
        ))}
      </div>
      {isNonEmptyString(overlayText) && (
        <TextLink
          className="absolute left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2 cursor-pointer"
          href="#"
          htmlAttributes={{
            onClick: onTextClick,
            tabIndex: 0,
          }}
        >
          {overlayText}
        </TextLink>
      )}
    </div>
  );
};

export const InstanceResources = ({ instance }: { instance: Instance }) => {
  const [showResources, setShowResources] = useState(false);
  const activeProject = useActiveProject();
  const navigate = useNavigate();
  const { setSelectedInstance } = useOpsContext();
  const upgradeModal = useModal(MODAL_TYPE.UPGRADE);

  const { chartContext, refreshTimeRange } = useMetricsViewState({ timePeriod: '24H' });

  const handleToggleResource = () => {
    setShowResources((show) => !show);
    if (!showResources) {
      track(OPS_EVENTS.INSTANCE_CARD_VIEW_RESOURCES);
    }
  };

  const handleViewAllMetrics = () => {
    setSelectedInstance(instance.id);
    navigate(`/projects/${activeProject.id}/metrics`);
    track(OPS_EVENTS.INSTANCE_CARD_VIEW_ALL_METRICS);
  };

  return (
    <div aria-label={`Instance metrics for ${instance.name}`} className="relative flex flex-col gap-4 p-4">
      <div className="flex w-full justify-between">
        <div
          role="button"
          tabIndex={0}
          aria-label="Toggle Metrics"
          aria-pressed={showResources}
          onKeyDown={(e) => e.key === 'Enter' && handleToggleResource()}
          className="text-palette-neutral-text-weak flex cursor-pointer items-center gap-2"
          onClick={handleToggleResource}
        >
          <ChevronRightIconOutline
            aria-label="Show Metrics"
            className="size-4"
            style={{
              transform: showResources ? 'rotate(90deg)' : 'rotate(0deg)',
              transitionDuration: tokens.transitions.values.duration.quick,
            }}
          />
          {/* add margin right to balance tab outline */}
          <Typography variant="subheading-small" className="mr-2">
            Metrics
          </Typography>
        </div>

        {instance.tier !== TIER.FREE && (
          <div className="flex items-center gap-2">
            {showResources && (
              <IconButton
                ariaLabel="Click to refresh charts"
                size="small"
                onClick={refreshTimeRange}
                isClean={true}
                htmlAttributes={{
                  title: 'Click to refresh charts',
                }}
              >
                <ArrowPathIconOutline />
              </IconButton>
            )}
            <Button fill="outlined" size="small" color="neutral" onClick={handleViewAllMetrics}>
              View all metrics
            </Button>
          </div>
        )}
      </div>
      {showResources &&
        (instance.tier !== TIER.FREE ? (
          <InstanceDashboardMetrics instance={instance} chartContext={chartContext} />
        ) : (
          <FakeInstanceDashboardMetrics
            chartContext={chartContext}
            overlayText="Upgrade to Professional for metrics"
            instanceName={instance.name}
            onTextClick={() => {
              upgradeModal.open({ instance, project: activeProject });
            }}
          />
        ))}
    </div>
  );
};
