import { type LDFlagSet, useFlags } from '@nx/launch-darkly-service';
import { type Instance, type TIER } from '@nx/state';
import { useCallback, useMemo } from 'react';

import { AuraDatabaseCharts } from '../charts/database-charts';
import type { ChartType, ChartWidgetPartialProps } from '../charts/fullstack-chart-props';
import { AuraInstanceCharts } from '../charts/instance-charts';
import { InstanceDashboardCharts } from '../charts/instance-dashboard-charts';
import { AuraSystemCharts } from '../charts/system-charts';
import type { MultiChartProps } from '../charts/types';

function tierFilter(chartProps: ChartWidgetPartialProps, tier: TIER): boolean {
  return !chartProps.enabledFor || chartProps.enabledFor.length === 0 || chartProps.enabledFor.includes(tier);
}

function featureFlagFilter(chartProps: ChartWidgetPartialProps, lDFlags: LDFlagSet): boolean {
  return (
    !chartProps.controlledBy ||
    chartProps.controlledBy.length === 0 ||
    chartProps.controlledBy.reduce(
      (result, cv) => result && ((cv.state === 'ON' && lDFlags[cv.name]) || (cv.state === 'OFF' && !lDFlags[cv.name])),
      true,
    )
  );
}

export const useViewCharts = (instance: Instance, useAZGrouping: boolean): Record<ChartType, MultiChartProps[]> => {
  const flags = useFlags();

  const { tier } = instance;

  const applyFiltersTo = useCallback(
    (chartProps: MultiChartProps[]): MultiChartProps[] => {
      return chartProps
        .filter((cp) => {
          if (Array.isArray(cp)) {
            return true;
          }
          return tierFilter(cp, tier) && featureFlagFilter(cp, flags);
        })
        .map((cp) => {
          if (Array.isArray(cp)) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            return (cp as ChartWidgetPartialProps[]).filter((p) => tierFilter(p, tier) && featureFlagFilter(p, flags));
          }
          return cp;
        })
        .map((cp) => {
          if (!Array.isArray(cp) && useAZGrouping && cp.additionalMetric !== undefined) {
            const additionalMetric = Object.assign({}, cp.additionalMetric);
            return [cp, additionalMetric];
          }

          return cp;
        });
    },
    [tier, useAZGrouping, flags],
  );

  return useMemo(
    () => ({
      HOST: applyFiltersTo(AuraSystemCharts),
      INSTANCE: applyFiltersTo(AuraInstanceCharts),
      DB: applyFiltersTo(AuraDatabaseCharts),
      INSTANCE_DASHBOARD: applyFiltersTo(InstanceDashboardCharts),
    }),
    [applyFiltersTo],
  );
};
