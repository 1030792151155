import semver from 'semver';

export const coerce = (version?: string | number | semver.SemVer, options?: semver.RangeOptions) => {
  const coerced = semver.coerce(version, { ...options, loose: true });
  if (coerced === null) {
    throw new Error(`Invalid version: ${version?.toString()}`);
  }
  return coerced;
};

export const eq = (v1: string | semver.SemVer, v2: string | semver.SemVer, options?: semver.RangeOptions) => {
  const cv1 = coerce(v1);
  const cv2 = coerce(v2);
  return semver.eq(cv1, cv2, { ...options, loose: true });
};

export const gt = (v1: string | semver.SemVer, v2: string | semver.SemVer, options?: semver.RangeOptions) => {
  const cv1 = coerce(v1);
  const cv2 = coerce(v2);
  return semver.gt(cv1, cv2, { ...options, loose: true });
};

export const gte = (v1: string | semver.SemVer, v2: string | semver.SemVer, options?: semver.RangeOptions) => {
  const cv1 = coerce(v1);
  const cv2 = coerce(v2);
  return semver.gte(cv1, cv2, { ...options, loose: true });
};

export const lt = (v1: string | semver.SemVer, v2: string | semver.SemVer, options?: semver.RangeOptions) => {
  const cv1 = coerce(v1);
  const cv2 = coerce(v2);
  return semver.lt(cv1, cv2, { ...options, loose: true });
};

export const lte = (v1: string | semver.SemVer, v2: string | semver.SemVer, options?: semver.RangeOptions) => {
  const cv1 = coerce(v1);
  const cv2 = coerce(v2);
  return semver.lte(cv1, cv2, { ...options, loose: true });
};

export const compareLoose = (v1: string | semver.SemVer, v2: string | semver.SemVer) => {
  const cv1 = coerce(v1);
  const cv2 = coerce(v2);
  return semver.compareLoose(cv1, cv2);
};

export const compare = compareLoose;

export const rcompare = (v1: string | semver.SemVer, v2: string | semver.SemVer, options?: semver.RangeOptions) => {
  const cv1 = coerce(v1);
  const cv2 = coerce(v2);
  return semver.rcompare(cv1, cv2, { ...options, loose: true });
};

export const parse = (v?: string | semver.SemVer, options?: semver.RangeOptions) => {
  const cv = coerce(v);
  return semver.parse(cv, { ...options, loose: true });
};

export const satisfies = (v: string | semver.SemVer, range: string, options?: semver.RangeOptions) => {
  const cv = coerce(v);
  return semver.satisfies(cv, range, { ...options, loose: true });
};

/**
 * @deprecated For CalVer, the minor equals a month and can not
 * be safely used to check for breaking changes.
 */
export const diff = (v1: string | semver.SemVer, v2: string | semver.SemVer) => {
  const cv1 = coerce(v1);
  const cv2 = coerce(v2);
  return semver.diff(cv1, cv2);
};

export default {
  coerce,
  eq,
  gt,
  gte,
  lt,
  lte,
  compare,
  compareLoose,
  parse,
  rcompare,
  satisfies,
  diff,
};
