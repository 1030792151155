import { Banner, Button, Dialog, TextInput, TextLink } from '@neo4j-ndl/react';
import { DESTINATION_TYPE } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import React, { useState } from 'react';

import type { InnerCreateDialogProps } from '../helpers/props';
import { validateUUID } from '../helpers/validation';

export const LogAnalyticsCreationSteps = ({ onCreate, loading, error, region, setStep }: InnerCreateDialogProps) => {
  const [workspaceId, setWorkspaceId] = useState('');
  const [azureSharedKey, setAzureSharedKey] = useState('');
  const [workspaceIdValidationError, setWorkspaceIdValidationError] = useState<string | null>();

  const handleWorkspaceIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setWorkspaceId(value);

    if (isNotNullish(workspaceIdValidationError)) {
      setWorkspaceIdValidationError(validateUUID(value));
    }
  };

  const handleAzureSharedKeyChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setAzureSharedKey(e.target.value.trim());

  const handleWorkspaceIdBlur = () => {
    setWorkspaceIdValidationError(validateUUID(workspaceId));
  };

  const handleCreate = () => {
    if (isNullish(region)) {
      return;
    }
    onCreate({
      type: DESTINATION_TYPE.LOG_ANALYTICS,
      logAnalytics: {
        customerId: workspaceId,
        sharedKey: azureSharedKey,
      },
    });
  };

  const isValidPayload = Boolean(workspaceId) && Boolean(azureSharedKey) && workspaceIdValidationError === null;

  return (
    <>
      <Dialog.Content className="space-y-6">
        {error && (
          <Banner type="danger" usage="inline">
            {String(error.message)}
          </Banner>
        )}
        <p>Follow these steps:</p>
        <ol className="my-6 list-decimal pl-6">
          <li>
            Go to the{' '}
            <TextLink href="https://portal.azure.com/" isExternalLink>
              Azure portal
            </TextLink>{' '}
            and log in with your Azure account.
          </li>
          <li>
            Locate and select your Log Analytics workspace or create a new one. If your workspace is within a resource
            group, start by navigating to that resource group.
          </li>
          <li>In the left-hand menu, click on &quot;Agents&quot; in the &quot;Settings&quot; section.</li>
          <li>
            To access the &quot;Workspace ID&quot;, expand the "Log Analytics agent instructions" section. Click the
            &quot;Copy&quot; button next to the Workspace ID to copy it to your clipboard.
          </li>
          <li>
            Look for the &quot;Primary key&quot; listed on the same page. Use the &quot;Copy&quot; button next to the
            Primary key to copy it to your clipboard.
          </li>
        </ol>
        <TextInput
          label="Workspace ID"
          value={workspaceId}
          onChange={handleWorkspaceIdChange}
          isFluid
          errorText={workspaceIdValidationError}
          htmlAttributes={{
            placeholder: 'your-workspace-id',
            'data-testid': 'log-forwarding-azure-workspace-id-input',
            onBlur: handleWorkspaceIdBlur,
          }}
        />
        <TextInput
          label="Primary Key"
          value={azureSharedKey}
          onChange={handleAzureSharedKeyChange}
          isFluid
          htmlAttributes={{
            placeholder: 'your-primary-key',
            'data-testid': 'log-forwarding-azure-shared-key-input',
          }}
        />
      </Dialog.Content>
      <Dialog.Actions>
        <Button fill="outlined" color="neutral" onClick={() => setStep(0)}>
          Previous
        </Button>
        <Button
          color="primary"
          onClick={handleCreate}
          isLoading={loading}
          isDisabled={!isValidPayload}
          htmlAttributes={{
            'data-testid': 'log-forwarding-create',
          }}
        >
          Create
        </Button>
      </Dialog.Actions>
    </>
  );
};
