import type { TransactionMetadataConfig } from '@nx/constants';
import { useCallback } from 'react';

import { useDispatch, useSelector } from '../../context';
import type { connectDriver } from './connections-slice';
import {
  CONNECTION_ERROR,
  CONNECTION_STATUS,
  clearActiveConnectionError,
  disconnectAndConnect,
  disconnectDriver,
  provisionedSso,
  renamed,
  runQuery,
  skipConnection,
  switchedDatabase,
  updateDatabases,
} from './connections-slice';
import { nxMetadata } from './constants';
import type { Database } from './database.types';
import {
  selectActiveConnection,
  selectActiveDatabase,
  selectActiveDatabaseName,
  selectConnections,
  selectCurrentUser,
  selectDatabases,
  selectRawDatabases,
  selectStaticConnectionUrl,
  selectVersionAndEdition,
} from './selectors';
import type { ActiveDatabase } from './types';

export function useConnectionsActions() {
  const dispatch = useDispatch();

  const updateDatabasesMemo = useCallback(
    (config?: { metadata: TransactionMetadataConfig }) => {
      return dispatch(updateDatabases({ metadata: config?.metadata ?? nxMetadata }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  return {
    updateDatabases: updateDatabasesMemo,
  };
}

export function useConnections() {
  return useSelector(selectConnections);
}

export function useStaticConnectionUrl() {
  return useSelector(selectStaticConnectionUrl);
}

export function useConnectionStatus() {
  const status = useSelector((state) => state.connections.status);
  const connection = useSelector(selectActiveConnection);

  return {
    isConnected: status === CONNECTION_STATUS.CONNECTED,
    isConnecting: status === CONNECTION_STATUS.CONNECTING,
    isDisconnected: status === CONNECTION_STATUS.DISCONNECTED,
    isDisconnecting: status === CONNECTION_STATUS.DISCONNECTING,
    isReconnecting: status === CONNECTION_STATUS.RECONNECTING,
    areCredentialsExpired: connection?.error?.code === CONNECTION_ERROR.CREDENTIALS_EXPIRED,
    value: status,
  };
}

export function useConnection() {
  const dispatch = useDispatch();
  const metadata = useSelector(selectActiveConnection);
  const status = useConnectionStatus();
  const user = useSelector(selectCurrentUser);
  const versionAndEdition = useSelector(selectVersionAndEdition);

  const connect = useCallback(
    (...args: Parameters<typeof connectDriver>) => {
      return dispatch(disconnectAndConnect(...args));
    },
    [dispatch],
  );

  const disconnect = useCallback(() => dispatch(disconnectDriver()), [dispatch]);

  const renameMemo = useCallback(
    (...args: Parameters<typeof renamed>) => {
      return dispatch(renamed(...args));
    },
    [dispatch],
  );

  const switchDatabaseMemo = useCallback(
    (...args: Parameters<typeof switchedDatabase>) => {
      return dispatch(switchedDatabase(...args));
    },
    [dispatch],
  );

  const clearConnectionError = useCallback(
    (...args: Parameters<typeof clearActiveConnectionError>) => {
      return dispatch(clearActiveConnectionError(...args));
    },
    [dispatch],
  );

  const skipConnectionMemo = useCallback(() => {
    return dispatch(skipConnection());
  }, [dispatch]);

  const runQueryMemo = useCallback(
    (...args: Parameters<typeof runQuery>) => {
      return dispatch(runQuery(...args));
    },
    [dispatch],
  );

  const provisionSsoMemo = useCallback(
    (...args: Parameters<typeof provisionedSso>) => {
      return dispatch(provisionedSso(...args));
    },
    [dispatch],
  );

  return {
    metadata,
    status,
    user,
    versionAndEdition,
    connect,
    disconnect,
    rename: renameMemo,
    clearConnectionError,
    switchDatabase: switchDatabaseMemo,
    skipConnection: skipConnectionMemo,
    runQuery: runQueryMemo,
    provisionSso: provisionSsoMemo,
  };
}

export function useActiveDatabase(): ActiveDatabase | undefined {
  const activeDatabaseName = useSelector(selectActiveDatabaseName);
  const activeDatabase = useSelector(selectActiveDatabase);

  if (activeDatabase === null || activeDatabaseName === undefined) {
    return undefined;
  }

  return { ...activeDatabase, selectedName: activeDatabaseName };
}

export function useDatabases(): Database[] {
  return useSelector(selectDatabases);
}

export function useRawDatabases(): Database[] {
  return useSelector(selectRawDatabases);
}
