import type { Logger } from '@nx/logger';
import type { AuthAction } from '@nx/state';
import { ACTIVE_ORG_KEY, initializeSession, selectActiveConnection, LEGACY_store as store } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import type { UserManagerSettings } from 'oidc-client-ts';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';

export type AuthConfig = {
  authority?: string;
  clientId?: string;
  audience?: string;
  loginRequired?: boolean;
};

async function setupSession(logger: Logger, action: AuthAction, config: UserManagerSettings) {
  try {
    // TODO: Replace action with preloading state via store factory
    await store.dispatch(initializeSession({ config, action }));
  } catch (e) {
    logger.warn('Could not initialize session', e);
  }
}

export async function setupAppLogin(logger: Logger, auth?: AuthConfig) {
  const currentConnection = selectActiveConnection(store.getState());

  if (isNullish(auth)) {
    return;
  }

  const { clientId, authority, audience } = auth;

  if (
    typeof clientId !== 'string' ||
    typeof authority !== 'string' ||
    typeof audience !== 'string' ||
    clientId === '' ||
    authority === '' ||
    audience === ''
  ) {
    const errorMessage =
      'Cannot initialize auth session because VITE_OIDC_AUTHORITY, VITE_OIDC_CLIENT_ID or VITE_OIDC_AUDIENCE is not set';

    logger.warn('Could not initialize session', errorMessage);
    return;
  }

  const prefix = localStorage.getItem(ACTIVE_ORG_KEY) ?? 'workspace.';
  const config: UserManagerSettings = {
    userStore: new WebStorageStateStore({ prefix, store: window.localStorage }),
    stateStore: new WebStorageStateStore({ prefix, store: window.localStorage }),
    authority,
    metadataSeed: {
      // Auth0 requires this as they don't list this endpoint in the
      // well-known file. It works with Keycloak even though it's not needed,
      // but could potentially break with other providers.
      end_session_endpoint: `${authority}/oidc/logout`,
    },
    client_id: clientId,
    scope: 'openid profile email',
    redirect_uri: `${location.origin}/login-callback`,
    post_logout_redirect_uri: `${location.origin}/logout-callback`,
    silentRequestTimeoutInSeconds: 10,
    extraQueryParams: { audience },
  };

  //
  // --- N4GCP ---
  //
  // This code does not belong to frameweork as it is specific to UPX only but unfortunately the
  // entire authentication code lives here, so this must too.
  //
  // N4GCP - "Neo4j 4 GCP" - is the name of our marketplace integration with Google Cloud Platform.
  // Within GCP, users can enable Neo4j as a product. From there, they access a "Manage on Neo4j"
  // link that sends them to our marketplace-integration component in Aura, where the request is
  // authenticated. An org + project is provisioned for the user that is billed through the GCP
  // integration. The user is then redirected to the following url:
  //
  // console.neo4j.io/gcp-marketplace-registration?google_user_id=xxx&gcp_project_id=yyy
  //
  const searchParams = new URLSearchParams(location.search);
  const googleUserId = searchParams.get('google_user_id');
  const gcpProjectId = searchParams.get('gcp_project_id');
  const isN4GCP =
    location.pathname.startsWith('/gcp-marketplace-registration') &&
    isNotNullish(googleUserId) &&
    isNotNullish(gcpProjectId);

  if (isN4GCP) {
    // We need to check if the user is authenticated as the same Google user that punched out from GCP.
    const authClient = new UserManager(config);
    const user = await authClient.getUser();
    if (user) {
      // The user may or may not be authenticated with the correct subject ID – we won't be checking
      // for that here. The route matching /gcp-marketplace-registration will display an error message
      // if the user needs to reauthenticate with Google OAuth.
      // Do nothing and continue with the regular authentication flow.
    } else {
      // Hijack the regular login flow to enforce google-oauth2 connection with a login hint
      await authClient.signinRedirect({
        login_hint: googleUserId,
        extraQueryParams: { connection: 'google-oauth2', audience },
        state: { returnTo: location.pathname + location.search },
      });
      return;
    }
  }
  //
  // --- End of N4GCP ---
  //

  if (location.pathname.startsWith('/login-callback')) {
    await setupSession(logger, 'sign-in-callback', config);
    return;
  }

  if (location.pathname.startsWith('/logout-callback')) {
    await setupSession(logger, 'sign-out-callback', config);
    return;
  }

  const loginRequired = auth.loginRequired ?? false;
  if (loginRequired || currentConnection?.credentials.type === 'seamless') {
    await setupSession(logger, 'sign-in', config);
    return;
  }

  void setupSession(logger, 'sign-in', config);
}
