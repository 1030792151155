export enum EXPLORE_EVENTS {
  ENVIRONMENTAL_CONFIG = 'ENV_READY',
  GEN_AI_SUGGESTION = 'GENAI_GENERATE_SUGGESTION',

  GDS_RE_RUN_ALGORITHM = 'GDS_RERUN_ALGORITHM',
  GDS_RUN_ALGORITHM = 'GDS_RUN_ALGORITHM',
  GDS_ADD_ALGORITHM = 'GDS_ADD_ALGORITHM',
  GDS_ADD_RULE_BASED_STYLING = 'GDS_ADD_STYLING',
  GDS_PANEL_OPEN = 'GDS_PANEL_OPEN',

  INTERACTIONS_CSV_EXPORT = 'CSV_EXPORT',
  INTERACTIONS_STYLE_RULE_APPLY = 'STYLERULE_APPLY',
  INTERACTIONS_LAYOUT_SWITCH = 'LAYOUT_SWITCH',
  INTERACTIONS_CARD_LIST_FIND = 'CARDSLIST_FIND',
  INTERACTIONS_SCREENSHOT_EXPORT = 'SCREENSHOT_EXPORT',
  INTERACTIONS_FILTER_APPLY = 'FILTER_APPLY',
  INTERACTIONS_FILTERED_ELEMENTS_DISMISS = 'FILTER_DISMISS',
  INTERACTIONS_SEARCH_PHRASE_TRANSACTION_MODE = 'SEARCH_TRANSACTION',

  PERSPECTIVE_LOAD = 'PERSP_LOAD',
  PERSPECTIVE_CREATE = 'PERSP_CREATE',
  PERSPECTIVE_REMOVE = 'PERSP_REMOVE',
  PERSPECTIVE_REFRESH = 'PERSP_REFRESH',
  PERSPECTIVE_STYLE_APPLY_BUTTON = 'PERSP_STYLE_APPLY_BUTTON',

  QUERIES_USER_QUERY = 'USER_QUERY',
  QUERIES_USER_QUERY_CANCEL = 'USER_QUERY_CANCEL',
  QUERIES_SUGGESTION_QUERY = 'SUGGEST_QUERY',

  SLICER_LAUNCH = 'SLICER_LAUNCH',
  SLICER_ADDRANGE = 'SLICER_ADDRANGE',
  SLICER_PLAY = 'SLICER_PLAY',
  SLICER_KEEPCLOSE = 'SLICER_KEEPCLOSE',
  SLICER_CLOSE = 'SLICER_CLOSE',

  SCENE_ACTION_EXECUTION = 'ACTION_CATEGORY',

  SCENE_CREATE = 'SCENE_CREATE',
  SCENE_DUPLICATE = 'SCENE_DUPLICATE',
  SCENE_EDIT_TOGGLE = 'SCENE_EDIT_TOGGLE',
  SCENE_LINK_COPY = 'SCENE_LINK_COPY',
  SCENE_LOAD = 'SCENE_LOAD',
  SCENE_SHARE = 'SCENE_SHARE',

  SHORTCUT_ACTION = 'SHORTCUT_ACTION',
  STATISTICS_SCENE_METRICS = 'STATS',
}
