import { isNotNullish } from '@nx/stdlib';
import { format } from 'date-fns';

import type { ConsumptionParams, ConsumptionResponse } from '../console.api.types';
import type { Consumption } from '../console.types';
import { transformConsumptionResponse } from '../transformers/consumption';
import { type Builder } from './types';

const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'Z'";

export const consumptionEndpoints = (builder: Builder) => ({
  getConsumption: builder.query<Consumption, ConsumptionParams>({
    query: (params) => {
      // Validate query params and build object
      const queryParams = {
        ...(isNotNullish(params.startDate) && { StartDate: encodeURI(format(params.startDate, DATE_FORMAT)) }),
        ...(isNotNullish(params.endDate) && { EndDate: encodeURI(format(params.endDate, DATE_FORMAT)) }),
        ...{ Page: params.page },
        ...{ PageSize: params.pageSize },
        ...{ SortBy: params.sortBy },
        ...{ SortOrder: params.sortOrder },
        ...{ Search: params.search },
      };

      return {
        url: `tenants/${params.projectId}/consumption`,
        params: queryParams,
      };
    },
    transformResponse: (usage: ConsumptionResponse) => transformConsumptionResponse(usage),
    providesTags: (result, error, params) => [{ type: 'Consumption', id: params.projectId }],
  }),
});
