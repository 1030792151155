class Vector {
  dx: number;

  dy: number;

  constructor(dx: number, dy: number) {
    this.dx = dx;
    this.dy = dy;
  }

  plus(otherVector: Vector) {
    return new Vector(this.dx + otherVector.dx, this.dy + otherVector.dy);
  }

  minus(otherVector: Vector) {
    return new Vector(this.dx - otherVector.dx, this.dy - otherVector.dy);
  }

  scale(scaleFactor: number) {
    return new Vector(this.dx * scaleFactor, this.dy * scaleFactor);
  }

  invert() {
    return new Vector(-this.dx, -this.dy);
  }

  rotate(angle: number) {
    return new Vector(
      this.dx * Math.cos(angle) - this.dy * Math.sin(angle),
      this.dx * Math.sin(angle) + this.dy * Math.cos(angle),
    );
  }

  perpendicular() {
    return new Vector(-this.dy, this.dx);
  }

  distance() {
    return Math.sqrt(this.dx * this.dx + this.dy * this.dy);
  }

  angle() {
    return Math.atan2(this.dy, this.dx);
  }

  get dxdy() {
    return [this.dx, this.dy];
  }

  asCSSTransform() {
    return `translate(${this.dx}px,${this.dy}px)`;
  }
}

/** @deprecated */
export class ArrowsPoint {
  x: number;

  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  vectorFrom(otherPoint: ArrowsPoint) {
    return new Vector(this.x - otherPoint.x, this.y - otherPoint.y);
  }

  vectorFromOrigin() {
    return new Vector(this.x, this.y);
  }

  scale(scaleFactor: number) {
    return new ArrowsPoint(this.x * scaleFactor, this.y * scaleFactor);
  }

  translate(vector: Vector) {
    return new ArrowsPoint(this.x + vector.dx, this.y + vector.dy);
  }

  rotate(angle: number) {
    return new ArrowsPoint(
      this.x * Math.cos(angle) - this.y * Math.sin(angle),
      this.x * Math.sin(angle) + this.y * Math.cos(angle),
    );
  }

  isEqual(point: ArrowsPoint) {
    return this.x === point.x && this.y === point.y;
  }

  get xy() {
    return [this.x, this.y];
  }
}

type ArrowEntityPropertyType = string | { [key: string]: string | ArrowEntityPropertyType };

type ArrowEntityStyleType = string | number | number[];

type ArrowEntity = {
  id: string;
  properties: Record<string, ArrowEntityPropertyType>;
  style: Record<string, ArrowEntityStyleType>;
};

/** @deprecated */
export type ArrowNode = ArrowEntity & {
  id: string;
  caption: string;
  position: ArrowsPoint;
  labels: string[];
};

/** @deprecated */
export type ArrowRelationship = ArrowEntity & {
  fromId: string;
  toId: string;
  id: string;
  type: string;
};

type SerialisableArrowNode = Pick<ArrowNode, 'id' | 'caption' | 'position'>;

type SerialisableArrowRelationship = Pick<ArrowRelationship, 'id' | 'type' | 'fromId' | 'toId'>;

/** @deprecated */
export type ArrowGraph = {
  nodes: ArrowNode[];
  relationships: ArrowRelationship[];
  style: Record<string, ArrowEntityStyleType>;
};

/** @deprecated */
export type SerialisableArrowGraphState = {
  nodes: SerialisableArrowNode[];
  relationships: SerialisableArrowRelationship[];
};

export const deserializeArrowsState = (
  deserialisedGraphState: SerialisableArrowGraphState,
): Omit<ArrowGraph, 'style'> => {
  const { nodes, relationships } = deserialisedGraphState;
  return {
    nodes: nodes.map(({ position, ...rest }) => ({
      ...rest,
      position: new ArrowsPoint(position.x, position.y),
      style: {},
      labels: [],
      properties: {},
    })),
    relationships: relationships.map((relationship) => ({
      ...relationship,
      style: {},
      properties: {},
    })),
  };
};
