import { Code, Tooltip, useCopyToClipboard } from '@neo4j-ndl/react';
import {
  ArrowRightCircleIconOutline,
  DocumentDuplicateIconOutline,
  PlayCircleIconOutline,
} from '@neo4j-ndl/react/icons';
import { APP_SCOPE } from '@nx/constants';
import { SIGNAL_NAME, signals } from '@nx/signals';
import { useState } from 'react';

import { useNxNavigate } from '../hooks/use-nx-navigate';
import type { SvgIcon } from '../svg-icon';

const RunnableButton = ({
  Icon,
  className,
  ariaLabel,
  callback,
  tooltip,
}: {
  Icon: SvgIcon;
  className?: string;
  ariaLabel: string;
  callback: () => Promise<void>;
  tooltip: string;
}) => {
  const [shouldShowTooltip, setShouldShowTooltip] = useState(false);

  return (
    <div className="relative">
      <Icon
        className={className}
        aria-label={ariaLabel}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={callback}
        onMouseOver={() => setShouldShowTooltip(true)}
        onMouseOut={() => setShouldShowTooltip(false)}
      />
      {shouldShowTooltip && (
        <Tooltip isOpen type="simple" placement="left">
          <Tooltip.Content className="absolute right-[40px] top-[-12px] w-[144px]">{tooltip}</Tooltip.Content>
        </Tooltip>
      )}
    </div>
  );
};

const RunnableCodeBlock = ({
  code,
  headerTitle,
  language,
  showCopyIcon = false,
}: {
  code: string;
  headerTitle?: JSX.Element | null;
  language: React.ComponentProps<typeof Code>['language'];
  showCopyIcon?: boolean;
}) => {
  const navigate = useNxNavigate();
  const [, copy] = useCopyToClipboard();

  const actions: React.ComponentProps<typeof Code>['actions'] = [];

  if (showCopyIcon) {
    actions.push({
      ariaLabel: 'copy',
      children: <DocumentDuplicateIconOutline className="text-palette-neutral-text-icon" />,
      onClick: () => {
        void copy(code);
      },
      htmlAttributes: {
        title: 'copy',
      },
    });
  }

  if (language === 'cypher') {
    actions.push(
      {
        ariaLabel: 'Edit query',
        children: (
          <RunnableButton
            Icon={ArrowRightCircleIconOutline}
            ariaLabel="Edit query"
            callback={async () => {
              navigate(APP_SCOPE.query);
              await signals.emit(SIGNAL_NAME.QuerySetMainEditorValue, { query: code });
            }}
            tooltip="Edit in Cypher editor"
          />
        ),
      },
      {
        ariaLabel: 'Run query',
        children: (
          <RunnableButton
            className="text-palette-primary-icon"
            Icon={PlayCircleIconOutline}
            ariaLabel="Run query"
            callback={async () => {
              navigate(APP_SCOPE.query);
              await signals.emit(SIGNAL_NAME.QueryExecuteQueryCommands, { query: code });
            }}
            tooltip="Execute in Query tab"
          />
        ),
      },
    );
  }

  return (
    <Code
      type="block"
      className="min-h-fit"
      maxHeight={320}
      theme="solarizedlight"
      language={language ?? 'text'}
      code={code}
      headerTitle={headerTitle ?? undefined}
      actions={actions}
    />
  );
};

export default RunnableCodeBlock;
