import { Banner, Button, Dialog, Select, TextInput } from '@neo4j-ndl/react';
import type { OrganizationMember } from '@nx/state';
import { ORGANIZATION_ROLE_NAME, consoleApi, getApiError, getErrorMessage } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useState } from 'react';

import { getOrganizationRoleOptions } from '../../helpers';

type EditOrgMemberRoleModalProps = {
  user: OrganizationMember;
  onClose: () => void;
  onConfirm: () => void;
  hasOwnerPrivileges: boolean;
};

const roleHierarchy = {
  [ORGANIZATION_ROLE_NAME.ORG_MEMBER]: 1,
  [ORGANIZATION_ROLE_NAME.ORG_ADMIN]: 10,
  [ORGANIZATION_ROLE_NAME.ORG_OWNER]: 100,
} as const;

const isUpgrade = (oldRole: ORGANIZATION_ROLE_NAME, newRole: ORGANIZATION_ROLE_NAME) =>
  roleHierarchy[oldRole] < roleHierarchy[newRole];
const isDowngrade = (oldRole: ORGANIZATION_ROLE_NAME, newRole: ORGANIZATION_ROLE_NAME) =>
  roleHierarchy[oldRole] > roleHierarchy[newRole];

const changeRolePermissionMessage = (oldRole?: ORGANIZATION_ROLE_NAME, newRole?: ORGANIZATION_ROLE_NAME) => {
  if (isNullish(oldRole) || isNullish(newRole)) {
    return undefined;
  }
  if (isUpgrade(oldRole, newRole)) {
    return 'Changing to a more permissive role will grant additional access and privileges to the user.';
  }

  if (isDowngrade(oldRole, newRole)) {
    return 'Changing to a less permissive role may stop the user from accessing certain pages and performing certain actions.';
  }
  return undefined;
};

const mapRoleToOption = (role?: ORGANIZATION_ROLE_NAME) => {
  const options = getOrganizationRoleOptions();
  const roleLabel = options.find((r) => r.value === role)?.label;
  if (isNotNullish(role) && isNotNullish(roleLabel)) {
    return {
      label: roleLabel,
      value: role,
    };
  }
  return undefined;
};

export const EditOrgMemberRoleModal = ({
  hasOwnerPrivileges,
  user,
  onClose,
  onConfirm,
}: EditOrgMemberRoleModalProps) => {
  const [editRole, request] = consoleApi.useUpdateUserOrganizationRolesMutation();
  const [currentRole] = user.roles;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [newRole, setNewRole] = useState<ORGANIZATION_ROLE_NAME | undefined>(currentRole?.name);

  const handleConfirm = () => {
    setErrorMessage(null);
    if (isNotNullish(user.id) && isNotNullish(newRole)) {
      editRole({ userId: user.id, organizationId: user.organizationId, roles: [newRole] })
        .unwrap()
        .then(() => onConfirm())
        .catch((err: FetchBaseQueryError | SerializedError | undefined) => {
          const apiError = getApiError(err);
          const message = getErrorMessage(apiError);
          setErrorMessage(message);
        });
    }
  };

  const handleRoleChange = (value?: ORGANIZATION_ROLE_NAME) => {
    setNewRole(value);
  };

  const options = getOrganizationRoleOptions().filter(
    (role) => hasOwnerPrivileges || role.value !== ORGANIZATION_ROLE_NAME.ORG_OWNER,
  );

  const warningMessage = changeRolePermissionMessage(currentRole?.name, newRole);

  return (
    <Dialog isOpen onClose={onClose}>
      <Dialog.Header>Edit role for {user.email}</Dialog.Header>
      <Dialog.Content>
        <div className="flex flex-col gap-4">
          <TextInput value={user.email} isReadOnly isFluid label="User" />
          <Select
            size="medium"
            selectProps={{
              options,
              value: mapRoleToOption(newRole),
              onChange: (value) => handleRoleChange(value?.value),
              menuPosition: 'fixed',
            }}
            type="select"
            label="Organization role"
          />
          {currentRole?.name !== newRole && (
            <Banner description={warningMessage} type="warning" hasIcon usage="inline" />
          )}
          {isNotNullish(errorMessage) && <Banner description={errorMessage} type="danger" usage="inline" />}
        </div>
      </Dialog.Content>
      <Dialog.Actions>
        <Button onClick={onClose} color="neutral" fill="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          isLoading={request.isLoading}
          isDisabled={isNullish(newRole) || user.roles.map((role) => role.name).includes(newRole)}
        >
          Save
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
