import { METRIC_TYPE } from '@nx/state';

import { combineOptions } from '../plot/utils';
import type { ChartWidgetPartialProps } from './fullstack-chart-props';
import { BytesChartConfig, IntChartConfig, plotOptionsDuplicateBytesFormatter } from './helpers';

export const CPUTotalProps: ChartWidgetPartialProps = {
  title: 'CPU Usage',
  subtitle: 'Total',
  yLabel: '%',
  metricType: METRIC_TYPE.Gauge,
  metricName: 'neo4j_aura_cpu_usage',
  chartType: 'HOST',
  referenceMetricConfig: {
    toggledByDefault: true,
    name: 'neo4j_aura_cpu_limit',
    type: METRIC_TYPE.Gauge,
    aggregation: 'MAX',
    hidden: true,
  },
  ...IntChartConfig,
};

export const StorageUsedChartPropsOld: ChartWidgetPartialProps = {
  title: 'Storage',
  subtitle: 'Used',
  yLabel: 'Space',
  metricType: METRIC_TYPE.Gauge,
  metricName: 'neo4j_database_store_size_database',
  chartType: 'HOST',
  aggregations: ['MAX'],
  controlledBy: [{ name: 'useVirtualDiskMetricForStorageLimit', state: 'OFF' }],
  referenceMetricConfig: {
    toggledByDefault: true,
    name: 'neo4j_aura_storage_limit_calculated',
    type: METRIC_TYPE.Gauge,
    aggregation: 'MAX',
  },
  ...BytesChartConfig,
  uplotOptions: combineOptions(BytesChartConfig.uplotOptions, plotOptionsDuplicateBytesFormatter),
};

export const StorageUsedChartProps: ChartWidgetPartialProps = {
  title: 'Storage',
  subtitle: 'Used',
  yLabel: 'Space',
  metricType: METRIC_TYPE.Gauge,
  metricName: 'neo4j_database_store_size_database',
  chartType: 'HOST',
  aggregations: ['MAX'],
  controlledBy: [{ name: 'useVirtualDiskMetricForStorageLimit', state: 'ON' }],
  referenceMetricConfig: {
    toggledByDefault: true,
    name: 'neo4j_aura_storage_limit',
    type: METRIC_TYPE.Gauge,
    aggregation: 'MAX',
  },
  ...BytesChartConfig,
  uplotOptions: combineOptions(BytesChartConfig.uplotOptions, plotOptionsDuplicateBytesFormatter),
};

export const OOMErrorsChartProps: ChartWidgetPartialProps = {
  title: 'Out of Memory Errors',
  yLabel: 'Count',
  metricType: METRIC_TYPE.Counter,
  metricName: 'neo4j_aura_out_of_memory_errors_total',
  chartType: 'HOST',
  aggregations: ['SUM'],
  ...IntChartConfig,
};

export const AuraSystemCharts = [CPUTotalProps, StorageUsedChartPropsOld, StorageUsedChartProps, OOMErrorsChartProps];
