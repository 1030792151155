import { isNotNullish } from '@nx/stdlib';

export const getEnv = (env: string | undefined) => (typeof env === 'string' && env.length !== 0 ? env : undefined);
export const getFirstEnv = (...envs: (string | undefined)[]) => envs.find(getEnv);

// Used by the mock server, vite proxy, and app-ingress
const apiUrlsRelative = {
  console: '/console',
  token: '/console/token',
  opsManager: '/ops',
  import: '/import',
  storage: '/storage',
  graphql: '/graphql',
  dataScience: '/data-science',
};

// URL overrides to be used in development when overriding one or more backends
const apiUrlsOverrides: Record<keyof typeof apiUrlsRelative, string | undefined> = {
  console: getEnv(import.meta.env.VITE_AURA_CONSOLE_API_URL),
  token: getEnv(import.meta.env.VITE_TOKEN_API_URL),
  opsManager: getEnv(import.meta.env.VITE_OPS_MANAGER_API_URL),
  import: getEnv(import.meta.env.VITE_IMPORT_API_URL),
  storage: getEnv(import.meta.env.VITE_STORAGE_API_URL),
  graphql: getEnv(import.meta.env.VITE_GRAPHQL_API_URL),
  dataScience: getEnv(import.meta.env.VITE_DATA_SCIENCE_API_URL),
};

export const auraManagementApiUrl = getFirstEnv(
  import.meta.env.R_VITE_AURA_MANAGEMENT_API_URL,
  import.meta.env.VITE_AURA_MANAGEMENT_API_URL,
);

export const useMockApi = getFirstEnv(import.meta.env.R_VITE_USE_MOCK_API, import.meta.env.VITE_USE_MOCK_API) === '1';
export const useMockPlanType = getFirstEnv(
  import.meta.env.R_VITE_MOCK_API_PLAN_TYPE,
  import.meta.env.VITE_MOCK_API_PLAN_TYPE,
);

export const useMockFirstTimeUser = getFirstEnv(
  import.meta.env.R_VITE_MOCK_API_FIRST_TIME_USER,
  import.meta.env.VITE_MOCK_API_FIRST_TIME_USER,
);

const useViteProxy = getEnv(import.meta.env.VITE_AURA_MANAGEMENT_API_PROXY) === '1';

export const getApiUrl = (api: keyof typeof apiUrlsRelative): string => {
  // The vite proxy is using a relative path to be able to catch requests to the
  // various APIs, and then rewrite them to use the proper URL overrides, while
  // also rewriting the origin header to avoid CORS errors.
  if (useMockApi || useViteProxy) {
    return apiUrlsRelative[api];
  }

  const urlOverride = apiUrlsOverrides[api];
  if (isNotNullish(urlOverride)) {
    return urlOverride;
  }

  return (auraManagementApiUrl?.replace(/\/$/, '') ?? '') + apiUrlsRelative[api];
};
