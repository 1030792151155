import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';

let lazyLogger: ReturnType<typeof createLogger> | undefined;
export const getLogger = () => {
  if (!lazyLogger) {
    lazyLogger = createLogger(APP_SCOPE.ops);
  }
  return lazyLogger;
};
