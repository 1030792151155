import type { MetricsByNameAndInstanceId, TimePeriod } from '@nx/state';
import { random, range } from 'lodash-es';

import type { ChartWidgetPartialProps } from '../metrics/charts/fullstack-chart-props';
import type { MultiChartProps } from '../metrics/charts/types';

export const getPrefixedMetricName = (
  metricName: ChartWidgetPartialProps['metricName'] | ChartWidgetPartialProps['azMetricName'],
  type: ChartWidgetPartialProps['chartType'],
  selectedDB?: string,
) => {
  if (type === 'DB') {
    return metricName?.replace('**', `neo4j.database.${selectedDB}`);
  }
  if (type === 'INSTANCE') {
    return metricName?.replace('**', 'neo4j.dbms');
  }
  return metricName;
};

export const calculateStartTime = (timePeriod: TimePeriod, endTime: Date) => {
  const startTime = new Date(endTime);
  switch (timePeriod) {
    case 'LIVE':
      startTime.setMinutes(startTime.getMinutes() - 30);
      return startTime;
    case '1H':
      startTime.setHours(startTime.getHours() - 1);
      return startTime;
    case '2H':
      startTime.setHours(startTime.getHours() - 2);
      return startTime;
    case '6H':
      startTime.setHours(startTime.getHours() - 6);
      return startTime;
    case '24H':
      startTime.setHours(startTime.getHours() - 24);
      return startTime;
    case '3D':
      startTime.setDate(startTime.getDate() - 3);
      return startTime;
    case '1W':
      startTime.setDate(startTime.getDate() - 7);
      return startTime;
    case '2W':
      startTime.setDate(startTime.getDate() - 14);
      return startTime;
    case '1M':
      startTime.setDate(startTime.getDate() - 30);
      return startTime;
    default:
      return endTime;
  }
};

export const replaceDynamicDbLabels = (labels: ChartWidgetPartialProps['labels'], selectedDb?: string) => {
  if (!labels || selectedDb === undefined) {
    return undefined;
  }

  const dynamicLabelNames = ['store', 'path'];
  const replaced = { ...labels };

  dynamicLabelNames.forEach((label) => {
    if (replaced[label] !== undefined) {
      replaced[label] = replaced[label].replace('%dbName%', selectedDb);
    }
  });

  return replaced;
};

export const metricKey = (chartProps: ChartWidgetPartialProps, useAzGrouping: boolean, selectedDb?: string) => {
  const { metricName, chartType, labels, azMetricName } = chartProps;
  const prefixedName = getPrefixedMetricName(
    useAzGrouping ? (azMetricName ?? metricName) : metricName,
    chartType,
    selectedDb,
  );
  const replacedLabels = replaceDynamicDbLabels(labels, selectedDb);
  const labelsStrings = replacedLabels ? Object.entries(replacedLabels).map((label) => label.join(':')) : [];
  const labelsKey = labelsStrings.length > 0 ? `-${labelsStrings.join('-')}` : '';
  return `${chartType}-${prefixedName}${labelsKey}`;
};

export const fakeMetrics = (
  instanceName: string,
  timePeriod: TimePeriod,
  multiChartProps: MultiChartProps[],
  dataPoints?: number,
  seriesNameMapper?: (props: ChartWidgetPartialProps) => string,
): MetricsByNameAndInstanceId => {
  const noOfDataPoints = dataPoints ?? 10;
  const now = new Date();
  const startTime = Math.floor(calculateStartTime(timePeriod, now).getTime() / 1000);
  const endTime = Math.floor(now.getTime() / 1000);

  const interval = (endTime - startTime) / noOfDataPoints;
  const metricsData: MetricsByNameAndInstanceId = {};

  function generateRefMetricValue(i: number): { timestamp: number; value: number } {
    return {
      timestamp: startTime + i * interval,
      value: random(0, i * noOfDataPoints * noOfDataPoints),
    };
  }

  function generateMetricValue(i: number): { timestamp: number; value: number } {
    return { timestamp: startTime + i * interval, value: random(0, i * noOfDataPoints) };
  }

  function generateMetricsData(chartProps: ChartWidgetPartialProps) {
    let seriesName = chartProps.additionalMetric ? (chartProps.subtitle ?? chartProps.title) : instanceName;
    if (seriesNameMapper) {
      seriesName = seriesNameMapper(chartProps);
    }
    metricsData[metricKey(chartProps, false)] = {
      dataSeries: {
        [seriesName]: range(noOfDataPoints).map((i) => generateMetricValue(i)),
        ...(chartProps.additionalMetric && {
          [chartProps.additionalMetric.subtitle ?? chartProps.additionalMetric.title]: range(noOfDataPoints).map((i) =>
            generateMetricValue(i),
          ),
        }),
      },
      ...(Boolean(chartProps.referenceMetricConfig) && {
        refSeries: range(noOfDataPoints).map((i) => generateRefMetricValue(i)),
      }),
    };
  }

  multiChartProps.forEach((chartProps) => {
    if (Array.isArray(chartProps)) {
      chartProps.forEach((subChartProps) => {
        generateMetricsData(subChartProps);
      });
    } else {
      generateMetricsData(chartProps);
    }
  });

  return metricsData;
};

const EMPTY_ARRAY: unknown[] = [];
/**
 * Returns the input list if all its elements are in the containing list, otherwise returns the default list.
 * @returns The original list or default list (empty array if not specified)
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const getListIfSubsetOrDefault = <T,>(list: T[], containingList: T[], defaultList = EMPTY_ARRAY as T[]) => {
  return list.some((item) => !containingList.includes(item)) ? defaultList : list;
};
