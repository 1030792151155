import type { ConnectionCredentials } from '@nx/constants';
import * as StdLib from '@nx/stdlib';
import type { PersistedState } from 'redux-persist';

import { getConnectionIdFromUrl } from './connection-utils';
import type { ConnectionState } from './connections-slice';

type ConnectionsStateV1 = {
  recent: {
    dbid: string;
    boltUrl: string;
    username: string;
    instanceName?: string;
  }[];
  current: {
    boltUrl?: string;
    ssoDiscoveryUrl?: string;
    databases?: unknown[];
    selectedDatabase?: string;
    instanceName?: string;
  };
};

// Types for V2 connections state
enum DATABASE_STATUS {
  ONLINE = 'online',
  OFFLINE = 'offline',
  INITIAL = 'initial',
  STORE_COPYING = 'store copying',
  UNKOWN = 'unknown',
}

type NormalizedDatabase = {
  access?: 'read-write' | 'read-only';
  aliases?: string[];
  constituents?: string[];
  error?: string;
  statusMessage?: string;
  type?: 'system' | 'standard' | 'composite';
  writer?: string;
  address: string;
  currentStatus: DATABASE_STATUS;
  default: boolean;
  home: boolean;
  name: string;
  requestedStatus: DATABASE_STATUS;
  role: 'primary' | 'secondary' | 'unknown';
};

interface NormalizedConnection {
  name?: string;
  credentials: ConnectionCredentials;
  databaseId?: string;
  databases: NormalizedDatabase[];
  lastUsedAt?: number;
  url: string;
  discoveryUrls?: string[];
}

type ConnectionsStateV2 = {
  activeItemId: string | undefined;
  items: Record<string, NormalizedConnection>;
};

type ConnectionsStateV3 = Pick<ConnectionState, 'activeConnectionId' | 'connections'>;

export function getHost(url: string): string {
  const parsedUrl = new StdLib.URLs.Neo4jURL(url);
  return parsedUrl.host;
}

function isPersistedState(input: unknown): input is PersistedState {
  if (input === undefined || input === null || typeof input !== 'object') {
    return false;
  }

  if ('_persist' in input) {
    // eslint-disable-next-line no-underscore-dangle
    if (input._persist === undefined || input._persist === null || typeof input._persist !== 'object') {
      return false;
    }

    // eslint-disable-next-line no-underscore-dangle
    return 'rehydrated' in input._persist && 'version' in input._persist;
  }

  return false;
}

/**
 * Check if given input is Connections state (v1)
 *
 * Note: check is shallow and loose and is made for redux-persist migration
 */
function isConnectionsStateV1(input: unknown): input is ConnectionsStateV1 {
  if (input === null || typeof input !== 'object') {
    return false;
  }

  const hasCurrentProperty = 'current' in input;
  const hasRecentProperty = 'recent' in input;

  return hasCurrentProperty && hasRecentProperty;
}

function isConnectionsStateV2(input: unknown): input is ConnectionsStateV2 {
  if (input === null || typeof input !== 'object') {
    return false;
  }

  const hasActiveItemIdProperty = 'activeItemId' in input;
  const hasItemsProperty = 'items' in input;

  return hasActiveItemIdProperty && hasItemsProperty;
}

const mapV1toV2 = (state: ConnectionsStateV1): ConnectionsStateV2 & PersistedState => {
  const items: ConnectionsStateV2['items'] = state.recent.reduce((acc, connection) => {
    return {
      ...acc,
      [getHost(connection.boltUrl)]: {
        credentials: { type: 'basic', username: connection.username },
        databases: [],
        name: connection.instanceName,
        url: connection.boltUrl,
      },
    };
  }, {});

  const activeItemId = state.current.boltUrl === undefined ? undefined : getHost(state.current.boltUrl);

  return {
    activeItemId,
    items,
    _persist: {
      version: 2,
      rehydrated: false,
    },
  };
};

const mapV2toV3 = (state: ConnectionsStateV2): ConnectionsStateV3 & PersistedState => {
  const entities: ConnectionState['connections']['entities'] = Object.values(state.items).reduce((acc, connection) => {
    return {
      ...acc,
      [getConnectionIdFromUrl(connection.url)]: {
        ...connection,
        name: getConnectionIdFromUrl(connection.url),
      },
    };
  }, {});

  return {
    activeConnectionId: state.activeItemId,
    connections: {
      ids: Object.keys(entities),
      entities,
    },
    _persist: {
      version: 3,
      rehydrated: false,
    },
  };
};

export const migrateConnections = (state: PersistedState, version: number) => {
  let migratedState: PersistedState;
  // eslint-disable-next-line no-underscore-dangle
  if (isPersistedState(state) && state?._persist.version === 1 && isConnectionsStateV1(state)) {
    migratedState = mapV2toV3(mapV1toV2(state));
  }

  // eslint-disable-next-line no-underscore-dangle
  if (isPersistedState(state) && state?._persist.version === 2 && isConnectionsStateV2(state)) {
    migratedState = mapV2toV3(state);
  }

  if (migratedState) {
    return Promise.resolve(migratedState);
  }

  return Promise.resolve(state);
};
