import { IconButton, Menu } from '@neo4j-ndl/react';
import { QuestionMarkCircleIconOutline } from '@neo4j-ndl/react/icons';
import type { FRAMEWORK_EVENTS } from '@nx/analytics-service';
import { UI_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { type SelectGuideParams } from '@nx/state';
import {
  MODAL_TYPE,
  useCapability,
  useGuidesActions,
  useModal,
  useMode,
  useNetworkPolicies,
  useToolsConfiguration,
} from '@nx/state';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTrackUpxEvent } from '../services/segment/analytics';

type ResourceType = 'about' | 'guide' | 'link' | 'ts&cs';

type ResourceName = 'community' | 'documentation' | 'graph-academy' | 'whats-new' | 'support' | 'status' | 'changelog';

type HelpAndLearnMenuItemTemplate = {
  description?: string;
  icon?: JSX.Element;
  title: string;
};

type AppCueTemplate = HelpAndLearnMenuItemTemplate & { event: FRAMEWORK_EVENTS };

type GuideTemplate = HelpAndLearnMenuItemTemplate & { guideId: string; guideUrl: string };

type ResourceTemplate = HelpAndLearnMenuItemTemplate & { name?: ResourceName; href: string };

export type HelpAndLearnProps = {
  aboutLink?: string;
  appCues: AppCueTemplate[];
  feedbackLink?: string;
  guides: SelectGuideParams[];
  name: string;
  resources: ResourceTemplate[];
  links: ResourceTemplate[];
  scope: APP_SCOPE;
};

type ResourceViewEventProperties = {
  type: ResourceType;
  path: APP_SCOPE;
} & (
  | {
      type: 'about';
    }
  | {
      type: 'guide';
      name: string;
    }
  | {
      type: 'link';
      name: ResourceName;
    }
  | {
      type: 'ts&cs';
    }
);

function toGuideTemplate(guideInfo: SelectGuideParams): GuideTemplate {
  return {
    guideId: guideInfo.id,
    guideUrl: guideInfo.url ?? '',
    title: guideInfo.title,
  };
}

export const HelpAndLearnMenuItems = ({ handleClose, ...props }: HelpAndLearnProps & { handleClose?: () => void }) => {
  const trackEvent = useTrackUpxEvent();
  const { openGuide } = useGuidesActions();
  const termsAndConditionsModal = useModal(MODAL_TYPE.AURA_TERMS);
  const navigate = useNavigate();

  const { isStandalone } = useMode();
  const [exploreEnabled] = useCapability('explore:enabled');
  const [workspaceGuidesEnabled] = useCapability('guides:enabled');
  const isStandaloneExplore = isStandalone && exploreEnabled;
  const toolsConfiguration = useToolsConfiguration();
  const networkPolicies = useNetworkPolicies();
  const guidesRoute = toolsConfiguration.guides?.route;
  const showGuides = workspaceGuidesEnabled && guidesRoute !== undefined;

  const guides: GuideTemplate[] = props.guides.map(toGuideTemplate);

  const hasAppCues = props.appCues.length > 0;
  const hasResources = props.resources.length > 0;

  const close = () => {
    if (handleClose !== undefined) {
      handleClose();
    }
  };

  function trackResourceView(properties: ResourceViewEventProperties) {
    trackEvent({ event: UI_EVENTS.VIEW_RESOURCE, properties, scope: APP_SCOPE.framework });
  }

  return (
    <>
      <Menu.Items
        // Hide dividers when there are no items between them
        className="[&>.ndl-menu-divider]:hidden [&>.ndl-menu-item~.ndl-menu-divider]:block"
      >
        {props.aboutLink !== undefined ? (
          <Menu.Item
            title="About"
            onClick={() => {
              trackResourceView({
                type: 'about',
                path: props.scope,
              });
              window.open(props.aboutLink, '_blank');
              close();
            }}
          />
        ) : null}
        {showGuides && (
          <>
            {props.aboutLink !== undefined ? <Menu.Divider /> : null}
            <Menu.Subheader title="Guides" />
            <Menu.Item
              isDisabled={!networkPolicies.outgoingConnectionsAllowed}
              description="Learn Neo4j basics with sample data"
              title="Interactive guides"
              onClick={() => {
                trackEvent({ event: UI_EVENTS.VIEW_LEARN, scope: APP_SCOPE.framework });
                navigate(guidesRoute);
                close();
              }}
            />
            {guides.map((resource) => (
              <Menu.Item
                key={resource.guideId}
                description={resource.description}
                title={resource.title}
                onClick={() => {
                  trackResourceView({
                    type: 'guide',
                    name: resource.guideId,
                    path: props.scope,
                  });
                  void openGuide({
                    id: resource.guideId,
                    title: resource.title,
                    url: resource.guideUrl,
                    target: 'sidebar',
                  });
                  close();
                }}
              />
            ))}
            {hasResources ? <Menu.Divider /> : null}
          </>
        )}

        {hasAppCues || hasResources ? <Menu.Subheader title="Resources" /> : null}
        {props.appCues.map((resource) => (
          <Menu.Item
            key={resource.event}
            description={resource.description}
            title={resource.title}
            onClick={() => {
              trackEvent({ event: resource.event, scope: props.scope });
              close();
            }}
          />
        ))}

        {props.resources.map((resource) => (
          <Menu.Item
            key={resource.href}
            description={resource.description}
            title={resource.title}
            onClick={() => {
              if (resource.name !== undefined) {
                trackResourceView({
                  type: 'link',
                  name: resource.name,
                  path: props.scope,
                });
              }
              window.open(resource.href, '_blank');
              close();
            }}
          />
        ))}

        <Menu.Divider />

        {props.links.map((link) => (
          <Menu.Item
            key={link.href}
            description={link.description}
            title={link.title}
            onClick={() => {
              if (link.name !== undefined) {
                trackResourceView({
                  type: 'link',
                  name: link.name,
                  path: props.scope,
                });
              }
              window.open(link.href, '_blank');
              close();
            }}
          />
        ))}

        {!isStandaloneExplore && (
          <>
            <Menu.Item
              description="This is an Early Access Program version"
              title="Terms and Conditions"
              onClick={() => {
                trackResourceView({
                  type: 'ts&cs',
                  path: props.scope,
                });
                termsAndConditionsModal.open();
                close();
              }}
            />
          </>
        )}
      </Menu.Items>
    </>
  );
};

export function HelpAndLearnButton(props: HelpAndLearnProps) {
  const trackEvent = useTrackUpxEvent();
  const [open, setOpen] = useState(false);
  const anchorEl = useRef<HTMLButtonElement>(null);

  function handleClose() {
    setOpen(false);
  }

  function handleToggle() {
    setOpen((state) => !state);
    trackEvent({ event: UI_EVENTS.VIEW_RESOURCES, scope: APP_SCOPE.framework });
  }

  return (
    <>
      <IconButton
        ref={anchorEl}
        isActive={open}
        isClean
        ariaLabel="Help & learn"
        onClick={handleToggle}
        htmlAttributes={{
          title: 'Help & learn',
        }}
      >
        <QuestionMarkCircleIconOutline />
      </IconButton>
      <Menu anchorRef={anchorEl} isOpen={open} onClose={handleClose}>
        <HelpAndLearnMenuItems {...props} handleClose={handleClose} />
      </Menu>
    </>
  );
}
