import type { formatters, model } from '@neo4j/graph-schema-utils';
import type * as ImportShared from '@nx/import-shared';
import { isNullish } from '@nx/stdlib';

import {
  APP_VERSION,
  CONSTRAINT_ID_PREFIX,
  GRAPH_SCHEMA_VERSION,
  INDEX_ID_PREFIX,
  NODE_ID_PREFIX,
  NODE_LABEL_ID_PREFIX,
  PROPERTY_ID_PREFIX,
  RELATIONSHIP_ID_PREFIX,
  RELATIONSHIP_TYPE_ID_PREFIX,
} from '../constants';
import { getUniqCounterWithPrefixString } from '../utils/import-model-utils';

export const emptyDataModel: ImportShared.DataModelJsonStruct = {
  version: APP_VERSION,
  graphSchemaRepresentation: {
    version: GRAPH_SCHEMA_VERSION,
    graphSchema: {
      nodeLabels: [],
      relationshipTypes: [],
      nodeObjectTypes: [],
      relationshipObjectTypes: [],
      constraints: [],
      indexes: [],
    },
  },
  graphSchemaExtensionsRepresentation: {
    nodeKeyProperties: [],
  },
  graphMappingRepresentation: {
    dataSourceSchema: {
      type: null,
      tableSchemas: [],
    },
    nodeMappings: [],
    relationshipMappings: [],
  },
  configurations: {
    idsToIgnore: [],
  },
};

export const toRefString = (id: string) => `#${id}`;
export const toRefObject = (id: string) => ({ $ref: toRefString(id) });
export const toIdFromRef = (ref: string) => ref.slice(1);

export const toNodeLabelId = (idSuffix: string) => `${NODE_LABEL_ID_PREFIX}${idSuffix}`;
export const toNodeObjectTypeId = (idSuffix: string) => `${NODE_ID_PREFIX}${idSuffix}`;
export const toRelationshipTypeId = (idSuffix: string) => `${RELATIONSHIP_TYPE_ID_PREFIX}${idSuffix}`;
export const toRelationshipObjectTypeId = (idSuffix: string) => `${RELATIONSHIP_ID_PREFIX}${idSuffix}`;
export const toPropertyId = (idSuffix: number | string) => `${PROPERTY_ID_PREFIX}${idSuffix}`;
export const toIndexId = (idSuffix: number | string) => `${INDEX_ID_PREFIX}${idSuffix}`;
export const toConstraintId = (idSuffix: number | string) => `${CONSTRAINT_ID_PREFIX}${idSuffix}`;
// Remove prefixing n: and r: from object type ids
const removeObjectTypePrefix = (id: string) => id.slice(2);

const generateNextPropertyId = (graphSchema: formatters.json.types.GraphSchemaJsonStruct): string => {
  const properties = [
    ...graphSchema.nodeLabels.flatMap((nl) => nl.properties),
    ...graphSchema.relationshipTypes.flatMap((rt) => rt.properties),
  ];
  return getUniqCounterWithPrefixString(
    properties.map((p) => p.$id),
    PROPERTY_ID_PREFIX,
  );
};

const generateNextIndexId = (indexes: formatters.json.types.IndexJsonStruct[]): string => {
  return getUniqCounterWithPrefixString(
    indexes.map((i) => i.$id),
    INDEX_ID_PREFIX,
  );
};

const generateNextConstraintId = (constraints: formatters.json.types.ConstraintJsonStruct[]): string => {
  return getUniqCounterWithPrefixString(
    constraints.map((c) => c.$id),
    CONSTRAINT_ID_PREFIX,
  );
};

const generateNextPropertyToken = (properties: formatters.json.types.PropertyJsonStruct[]): string => {
  return getUniqCounterWithPrefixString(
    properties.map((p) => p.token),
    'Property ',
    properties.length + 1,
  );
};

const generateNextProperty = (
  dataModel: ImportShared.DataModelJsonStruct,
  labelOrType: formatters.json.types.NodeLabelJsonStruct | formatters.json.types.RelationshipTypeJsonStruct,
  token?: string,
  type?: model.PrimitivePropertyTypes,
): formatters.json.types.PropertyJsonStruct => ({
  // Property id is generated to be different to all existing properties in the data model
  $id: generateNextPropertyId(dataModel.graphSchemaRepresentation.graphSchema),
  // Property name is generated to be different to existing properties on node/rel
  token: token ?? generateNextPropertyToken(labelOrType.properties),
  type: { type: type ?? 'string' },
  nullable: true,
});

export const getNodeLabel = (
  dataModel: ImportShared.DataModelJsonStruct,
  $id: string,
): formatters.json.types.NodeLabelJsonStruct | undefined =>
  dataModel.graphSchemaRepresentation.graphSchema.nodeLabels.find((n) => n.$id === $id);

export const getNodeObjectType = (
  dataModel: ImportShared.DataModelJsonStruct,
  $id: string,
): formatters.json.types.NodeObjectTypeJsonStruct | undefined =>
  dataModel.graphSchemaRepresentation.graphSchema.nodeObjectTypes.find((n) => n.$id === $id);

export const getNodeMapping = (
  dataModel: ImportShared.DataModelJsonStruct,
  $id: string,
): ImportShared.NodeMappingJsonStruct | undefined =>
  dataModel.graphMappingRepresentation.nodeMappings.find((n) => n.node.$ref === toRefString($id));

const getRelationshipType = (
  dataModel: ImportShared.DataModelJsonStruct,
  $id: string,
): formatters.json.types.RelationshipTypeJsonStruct | undefined =>
  dataModel.graphSchemaRepresentation.graphSchema.relationshipTypes.find((r) => r.$id === $id);

const getRelationshipObjectType = (
  dataModel: ImportShared.DataModelJsonStruct,
  $id: string,
): formatters.json.types.RelationshipObjectTypeJsonStruct | undefined =>
  dataModel.graphSchemaRepresentation.graphSchema.relationshipObjectTypes.find((r) => r.$id === $id);

export const getNodeProperties = (
  dataModel: ImportShared.DataModelJsonStruct,
  nodeId: string,
): formatters.json.types.PropertyJsonStruct[] | undefined => {
  const nodeObjectType = getNodeObjectType(dataModel, nodeId);
  const nodeLabelRef = nodeObjectType?.labels[0]?.$ref ?? '';
  const nodeLabel = getNodeLabel(dataModel, toIdFromRef(nodeLabelRef));
  return nodeLabel?.properties;
};

export const getRelationshipProperties = (
  dataModel: ImportShared.DataModelJsonStruct,
  relationshipObjectTypeId: string,
): formatters.json.types.PropertyJsonStruct[] | undefined => {
  const relationshipObjectType = getRelationshipObjectType(dataModel, relationshipObjectTypeId);
  const relationshipTypeRef = relationshipObjectType?.type.$ref ?? '';
  const relationshipType = getRelationshipType(dataModel, toIdFromRef(relationshipTypeRef));
  return relationshipType?.properties;
};

const getRelationshipMapping = (
  dataModel: ImportShared.DataModelJsonStruct,
  relationshipObjectTypeId: string,
): ImportShared.RelationshipMappingJsonStruct | undefined =>
  dataModel.graphMappingRepresentation.relationshipMappings.find(
    (r) => r.relationship.$ref === toRefString(relationshipObjectTypeId),
  );

export const getNodeOrRelationshipProperty = (
  labelOrType: formatters.json.types.NodeLabelJsonStruct | formatters.json.types.RelationshipTypeJsonStruct,
  propertyId: string,
) => labelOrType.properties.find((p) => p.$id === propertyId);

const getPropertyMapping = (
  mapping: ImportShared.NodeMappingJsonStruct | ImportShared.RelationshipMappingJsonStruct,
  propertyId: string,
) => mapping.propertyMappings.find((p) => p.property.$ref === toRefString(propertyId));

const getTableSchema = (dataModel: ImportShared.DataModelJsonStruct, tableName: string) => {
  return dataModel.graphMappingRepresentation.dataSourceSchema.tableSchemas.find((t) => t.name === tableName);
};

export const getIndexOrConstraintName = (propertyName: string, nodeLabel: string, key?: boolean) => {
  const nodeLabelName = nodeLabel.length > 0 ? `_${nodeLabel.replace(' ', '_')}` : '';
  let name = `${propertyName.replace(' ', '_')}${nodeLabelName}`;
  name = key === true ? `${name}_uniq` : name;
  return name;
};

const updateProperty = (
  currentProperty: formatters.json.types.PropertyJsonStruct,
  newProperty: formatters.json.types.PropertyJsonStruct,
) => {
  currentProperty.nullable = newProperty.nullable;
  currentProperty.token = newProperty.token;
  currentProperty.type = newProperty.type;
};

export const updateIndexProperty = (
  dataModel: ImportShared.DataModelJsonStruct,
  newPropertyId: string,
  indexId: string,
): void => {
  let { indexes } = dataModel.graphSchemaRepresentation.graphSchema;
  const { nodeObjectTypes } = dataModel.graphSchemaRepresentation.graphSchema;

  const indexToUpdate = indexes.find((i) => i.$id === indexId);

  if (indexToUpdate) {
    // one propertyId should only show in one index
    indexes = indexes.filter((i) => !(i.properties && i.properties[0]?.$ref === toRefString(newPropertyId)));

    const nodeObject = nodeObjectTypes.find(({ labels }) => labels[0]?.$ref === indexToUpdate.nodeLabel?.$ref);
    if (!nodeObject) {
      return;
    }

    const currentKeyProperty = dataModel.graphSchemaExtensionsRepresentation.nodeKeyProperties.find(
      ({ node }) => node.$ref === toRefString(nodeObject.$id),
    );
    const isKeyProperty = currentKeyProperty?.keyProperty.$ref === toRefString(newPropertyId);

    indexToUpdate.properties = [toRefObject(newPropertyId)];
    // node index
    if (indexToUpdate.nodeLabel) {
      const nodeLabel = getNodeLabel(dataModel, toIdFromRef(indexToUpdate.nodeLabel.$ref));

      const property = nodeLabel ? getNodeOrRelationshipProperty(nodeLabel, newPropertyId) : undefined;
      const newIndexName =
        property && nodeLabel ? getIndexOrConstraintName(property.token, nodeLabel.token, isKeyProperty) : undefined;
      if (newIndexName !== undefined) {
        indexToUpdate.name = newIndexName;
      }
    }
  }
  dataModel.graphSchemaRepresentation.graphSchema.indexes = indexes.map((i) => {
    if (i.$id === indexToUpdate?.$id) {
      return indexToUpdate;
    }
    return i;
  });
};

export const updateIndexesName = (
  dataModel: ImportShared.DataModelJsonStruct,
  nodeLabelId: string,
  propertyId?: string,
) => {
  const { indexes, nodeObjectTypes } = dataModel.graphSchemaRepresentation.graphSchema;
  const nodeLabel = getNodeLabel(dataModel, nodeLabelId);

  if (!nodeLabel) {
    return;
  }
  const nodeObject = nodeObjectTypes.find(({ labels }) => labels[0]?.$ref === toRefString(nodeLabelId));
  if (!nodeObject) {
    return;
  }
  const currentKeyProperty = dataModel.graphSchemaExtensionsRepresentation.nodeKeyProperties.find(
    ({ node }) => node.$ref === toRefString(nodeObject.$id),
  );

  dataModel.graphSchemaRepresentation.graphSchema.indexes = indexes.map((i) => {
    // TODO: check if i is a NodeLabelIndexJsonStruct

    // No need to update
    if (i.nodeLabel?.$ref !== toRefString(nodeLabelId) || !i.properties?.[0]) {
      return i;
    }
    const isKeyProperty = currentKeyProperty?.keyProperty.$ref === i.properties[0].$ref;

    if (propertyId !== undefined) {
      // update only the index with correct nodeLabelId and propertyId
      const property = getNodeOrRelationshipProperty(nodeLabel, propertyId);
      if (i.properties[0].$ref === toRefString(propertyId) && property) {
        i.name = getIndexOrConstraintName(property.token, nodeLabel.token, isKeyProperty);
      }
    } else {
      // update all indexes under this node
      const property = getNodeOrRelationshipProperty(nodeLabel, toIdFromRef(i.properties[0].$ref));
      if (property) {
        i.name = getIndexOrConstraintName(property.token, nodeLabel.token, isKeyProperty);
      }
    }
    return i;
  });
};

const getIndexByLabelId = (dataModel: ImportShared.DataModelJsonStruct, nodeLabelId: string, propertyId: string) => {
  const { indexes } = dataModel.graphSchemaRepresentation.graphSchema;
  return indexes.find(
    (i) =>
      i.nodeLabel?.$ref === toRefString(nodeLabelId) &&
      i.properties &&
      i.properties[0]?.$ref === toRefString(propertyId),
  );
};

const removeMissingMappingsAfterTableUpdate = (
  mapping: ImportShared.NodeMappingJsonStruct | ImportShared.RelationshipMappingJsonStruct,
  updatedTableSchema: ImportShared.TableSchemaJsonStruct,
) => {
  if (mapping.tableName === updatedTableSchema.name) {
    const newTableSchemaFieldNames = updatedTableSchema.fields.map((f) => f.name);
    mapping.propertyMappings = mapping.propertyMappings.filter((propertyMapping) =>
      newTableSchemaFieldNames.includes(propertyMapping.fieldName),
    );
    if (!isNullish(mapping.mappingFilter) && !newTableSchemaFieldNames.includes(mapping.mappingFilter.fieldName)) {
      mapping.mappingFilter = undefined;
    }
    if (
      'fromMapping' in mapping &&
      !isNullish(mapping.fromMapping) &&
      !newTableSchemaFieldNames.includes(mapping.fromMapping.fieldName)
    ) {
      mapping.fromMapping = undefined;
    }
    if (
      'toMapping' in mapping &&
      !isNullish(mapping.toMapping) &&
      !newTableSchemaFieldNames.includes(mapping.toMapping.fieldName)
    ) {
      mapping.toMapping = undefined;
    }
  }
};

export const setNodeMappingTableSchema = (
  dataModel: ImportShared.DataModelJsonStruct,
  nodeObjectTypeId: string,
  tableName: string,
) => {
  const nodeMapping = getNodeMapping(dataModel, nodeObjectTypeId);
  // Table schema doesn't exist should never happen, but just in case
  if (isNullish(getTableSchema(dataModel, tableName))) {
    return;
  }
  if (isNullish(nodeMapping)) {
    dataModel.graphMappingRepresentation.nodeMappings.push({
      node: { $ref: toRefString(nodeObjectTypeId) },
      tableName,
      propertyMappings: [],
    });
  } else if (nodeMapping.tableName !== tableName) {
    nodeMapping.tableName = tableName;
    nodeMapping.propertyMappings = [];
    nodeMapping.mappingFilter = undefined;
  }
};

export const addNode = (dataModel: ImportShared.DataModelJsonStruct, nodeObjectTypeId: string, label: string): void => {
  const nodeLabelId = toNodeLabelId(removeObjectTypePrefix(nodeObjectTypeId));
  const { graphSchema } = dataModel.graphSchemaRepresentation;
  // We don't expect this to ever happen, but just in case ids already exists
  if (
    graphSchema.nodeLabels.some((n) => n.$id === nodeLabelId) ||
    graphSchema.nodeObjectTypes.some((n) => n.$id === nodeObjectTypeId)
  ) {
    return;
  }
  graphSchema.nodeLabels.push({ $id: nodeLabelId, token: label, properties: [] });
  graphSchema.nodeObjectTypes.push({
    $id: nodeObjectTypeId,
    labels: [{ $ref: toRefString(nodeLabelId) }],
  });
  // If only one table present, auto select that for the node
  if (dataModel.graphMappingRepresentation.dataSourceSchema.tableSchemas.length === 1) {
    setNodeMappingTableSchema(
      dataModel,
      nodeObjectTypeId,
      dataModel.graphMappingRepresentation.dataSourceSchema.tableSchemas[0]?.name ?? '',
    );
  }
};

export const addNodeIndex = (
  dataModel: ImportShared.DataModelJsonStruct,
  nodeLabelId: string,
  propertyId?: string,
): void => {
  let { indexes } = dataModel.graphSchemaRepresentation.graphSchema;
  if (propertyId !== undefined) {
    indexes = indexes.filter((i) => !(i.properties !== undefined && i.properties[0]?.$ref === toRefString(propertyId)));
  }

  const indexId = generateNextIndexId(indexes);
  const nodeLabel = getNodeLabel(dataModel, nodeLabelId);
  let indexName;
  let properties: { $ref: string }[] = [];

  if (propertyId !== undefined && nodeLabel !== undefined) {
    const property = getNodeOrRelationshipProperty(nodeLabel, propertyId);
    properties = [toRefObject(propertyId)];
    const currentNodeKeyProperty = dataModel.graphSchemaExtensionsRepresentation.nodeKeyProperties.find(
      ({ keyProperty }) => keyProperty.$ref === toRefString(propertyId),
    );
    indexName =
      currentNodeKeyProperty &&
      property &&
      getIndexOrConstraintName(
        property.token,
        nodeLabel.token,
        currentNodeKeyProperty.keyProperty.$ref === toRefString(property.$id),
      );
  }
  const newIndex: formatters.json.types.IndexJsonStruct = {
    $id: indexId,
    name: indexName ?? '',
    indexType: 'default',
    entityType: 'node',
    nodeLabel: toRefObject(nodeLabelId),
    properties: properties,
  };
  indexes.push(newIndex);
  dataModel.graphSchemaRepresentation.graphSchema.indexes = indexes;
};
export const addNodeConstraint = (
  dataModel: ImportShared.DataModelJsonStruct,
  nodeLabelId: string,
  propertyId: string,
): void => {
  let { constraints } = dataModel.graphSchemaRepresentation.graphSchema;
  const nodeLabel = getNodeLabel(dataModel, nodeLabelId);
  const property = nodeLabel && getNodeOrRelationshipProperty(nodeLabel, propertyId);
  const constraintName = property && getIndexOrConstraintName(property.token, nodeLabel.token, true);
  constraints = constraints.filter((c) => {
    if (c.nodeLabel && c.nodeLabel.$ref === toRefString(nodeLabelId)) {
      return false;
    }
    return true;
  });
  const newConstraint: formatters.json.types.ConstraintJsonStruct = {
    $id: generateNextConstraintId(constraints),
    name: constraintName ?? '',
    constraintType: 'uniqueness',
    entityType: 'node',
    nodeLabel: toRefObject(nodeLabelId),
    properties: [toRefObject(propertyId)],
  };
  constraints.push(newConstraint);

  dataModel.graphSchemaRepresentation.graphSchema.constraints = constraints;
};

export const addRelationship = (
  dataModel: ImportShared.DataModelJsonStruct,
  relationshipObjectTypeId: string,
  sourceNodeObjectTypeId: string,
  targetNodeObjectTypeId: string,
) => {
  const relationshipTypeId = toRelationshipTypeId(removeObjectTypePrefix(relationshipObjectTypeId));
  const { graphSchema } = dataModel.graphSchemaRepresentation;
  // We don't expect this to ever happen, but just in case ids already exists
  if (
    graphSchema.relationshipTypes.some((r) => r.$id === relationshipTypeId) ||
    graphSchema.relationshipObjectTypes.some((r) => r.$id === relationshipObjectTypeId)
  ) {
    return;
  }
  graphSchema.relationshipTypes.push({ $id: relationshipTypeId, token: '', properties: [] });
  graphSchema.relationshipObjectTypes.push({
    $id: relationshipObjectTypeId,
    type: { $ref: toRefString(relationshipTypeId) },
    from: { $ref: toRefString(sourceNodeObjectTypeId) },
    to: { $ref: toRefString(targetNodeObjectTypeId) },
  });
};

export const setNodeLabel = (dataModel: ImportShared.DataModelJsonStruct, nodeObjectTypeId: string, label: string) => {
  const nodeObjectType = getNodeObjectType(dataModel, nodeObjectTypeId);
  const nodeLabelRef = nodeObjectType?.labels[0]?.$ref ?? '';
  const nodeLabel = getNodeLabel(dataModel, toIdFromRef(nodeLabelRef));
  if (!isNullish(nodeLabel)) {
    nodeLabel.token = label;
  }
};

export const setRelationshipType = (
  dataModel: ImportShared.DataModelJsonStruct,
  relationshipObjectTypeId: string,
  type: string,
) => {
  const relaitonshipObjectType = getRelationshipObjectType(dataModel, relationshipObjectTypeId);
  const relationshipTypeRef = relaitonshipObjectType?.type.$ref ?? '';
  const relationshipType = getRelationshipType(dataModel, toIdFromRef(relationshipTypeRef));
  if (!isNullish(relationshipType)) {
    relationshipType.token = type;
  }
};

export const setNodeKeyProperty = (
  dataModel: ImportShared.DataModelJsonStruct,
  nodeObjectTypeId: string,
  propertyId: string,
) => {
  const nodeObjectType = getNodeObjectType(dataModel, nodeObjectTypeId);
  const nodeLabelRef = nodeObjectType?.labels[0]?.$ref ?? '';
  const nodeLabel = getNodeLabel(dataModel, toIdFromRef(nodeLabelRef));
  const property = nodeLabel && getNodeOrRelationshipProperty(nodeLabel, propertyId);
  const currentNodeKeyProperty = dataModel.graphSchemaExtensionsRepresentation.nodeKeyProperties.find(
    ({ node }) => node.$ref === toRefString(nodeObjectTypeId),
  );
  let oldKeyPropertyId = undefined;
  if (!isNullish(nodeObjectType) && !isNullish(property)) {
    if (isNullish(currentNodeKeyProperty)) {
      dataModel.graphSchemaExtensionsRepresentation.nodeKeyProperties.push({
        node: { $ref: toRefString(nodeObjectTypeId) },
        keyProperty: { $ref: toRefString(propertyId) },
      });
    } else {
      oldKeyPropertyId = toIdFromRef(currentNodeKeyProperty.keyProperty.$ref);
      currentNodeKeyProperty.keyProperty.$ref = toRefString(propertyId);
    }
    if (nodeLabel) {
      if (oldKeyPropertyId === undefined) {
        addNodeIndex(dataModel, nodeLabel.$id, propertyId);
      } else {
        const indexToUpdate = getIndexByLabelId(dataModel, nodeLabel.$id, oldKeyPropertyId);
        if (indexToUpdate) {
          updateIndexProperty(dataModel, propertyId, indexToUpdate.$id);
        } else {
          addNodeIndex(dataModel, nodeLabel.$id, propertyId);
        }
      }
      addNodeConstraint(dataModel, nodeLabel.$id, propertyId);
    }
  }
};

export const tryAutoPopulateRelationshipMapping = ({
  dataModel,
  relationshipObjectId,
  currentRelationshipMapping,
  sourceNodeId,
  targetNodeId,
}: {
  dataModel: ImportShared.DataModelJsonStruct;
  relationshipObjectId: string;
  currentRelationshipMapping: ImportShared.RelationshipMappingJsonStruct | undefined;
  sourceNodeId: string;
  targetNodeId: string;
}) => {
  const sourceNodeMapping = getNodeMapping(dataModel, sourceNodeId);
  const targetNodeMapping = getNodeMapping(dataModel, targetNodeId);
  const sourceNodeObjectType = getNodeObjectType(dataModel, sourceNodeId);
  const targetNodeObjectType = getNodeObjectType(dataModel, targetNodeId);
  const { nodeKeyProperties } = dataModel.graphSchemaExtensionsRepresentation;
  if (
    !isNullish(sourceNodeObjectType) &&
    !isNullish(targetNodeObjectType) &&
    !isNullish(sourceNodeMapping) &&
    !isNullish(targetNodeMapping)
  ) {
    const doNodesUseSameTable = sourceNodeMapping.tableName === targetNodeMapping.tableName;
    const doesSourceNodeHaveKey = nodeKeyProperties.some((n) => n.node.$ref === toRefString(sourceNodeId));
    const doesTargetNodeHaveKey = nodeKeyProperties.some((n) => n.node.$ref === toRefString(targetNodeId));
    const isSelfRelationship = sourceNodeObjectType.$id === targetNodeObjectType.$id;
    const isRelationshipUnmapped =
      isNullish(currentRelationshipMapping) || isNullish(currentRelationshipMapping.tableName);

    if (
      doNodesUseSameTable &&
      doesSourceNodeHaveKey &&
      doesTargetNodeHaveKey &&
      !isSelfRelationship &&
      isRelationshipUnmapped
    ) {
      let relMapping = currentRelationshipMapping;
      if (isNullish(relMapping)) {
        relMapping = {
          relationship: { $ref: toRefString(relationshipObjectId) },
          tableName: sourceNodeMapping.tableName,
          propertyMappings: [],
        };
        dataModel.graphMappingRepresentation.relationshipMappings.push(relMapping);
      }
      const sourceNodeKeyProperty = nodeKeyProperties.find(
        (n) => n.node.$ref === toRefString(sourceNodeId),
      )?.keyProperty;
      const targetNodeKeyProperty = nodeKeyProperties.find(
        (n) => n.node.$ref === toRefString(targetNodeId),
      )?.keyProperty;

      const sourceNodeKeyPropertyFieldName = sourceNodeMapping.propertyMappings.find(
        (p) => p.property.$ref === sourceNodeKeyProperty?.$ref,
      )?.fieldName;

      const targetNodeKeyPropertyFieldName = targetNodeMapping.propertyMappings.find(
        (p) => p.property.$ref === targetNodeKeyProperty?.$ref,
      )?.fieldName;

      if (!isNullish(sourceNodeKeyPropertyFieldName)) {
        relMapping.fromMapping = {
          fieldName: sourceNodeKeyPropertyFieldName,
        };
      }
      if (!isNullish(targetNodeKeyPropertyFieldName)) {
        relMapping.toMapping = {
          fieldName: targetNodeKeyPropertyFieldName,
        };
      }
    }
  }
};

export const tryAutoPopulateRelationshipMappingsForNode = (
  dataModel: ImportShared.DataModelJsonStruct,
  nodeObjectTypeId: string,
) => {
  dataModel.graphSchemaRepresentation.graphSchema.relationshipObjectTypes.forEach((relationshipObjectType) => {
    const isOutboundRelationship = relationshipObjectType.from.$ref === toRefString(nodeObjectTypeId);
    const isInboundRelationship = relationshipObjectType.to.$ref === toRefString(nodeObjectTypeId);
    if (isOutboundRelationship || isInboundRelationship) {
      const currentRelationshipMapping = getRelationshipMapping(dataModel, relationshipObjectType.$id);
      const sourceNodeId = toIdFromRef(relationshipObjectType.from.$ref);
      const targetNodeId = toIdFromRef(relationshipObjectType.to.$ref);
      tryAutoPopulateRelationshipMapping({
        dataModel,
        relationshipObjectId: relationshipObjectType.$id,
        currentRelationshipMapping,
        sourceNodeId,
        targetNodeId,
      });
    }
  });
};

const tryAutoPopulateNodeId = (dataModel: ImportShared.DataModelJsonStruct, nodeObjectTypeId: string) => {
  const nodeObjectType = getNodeObjectType(dataModel, nodeObjectTypeId);
  const nodeLabelRef = nodeObjectType?.labels[0]?.$ref ?? '';
  const nodeLabel = getNodeLabel(dataModel, toIdFromRef(nodeLabelRef));
  const properties = nodeLabel?.properties ?? [];
  if (properties.length === 1 && !isNullish(properties[0]) && !isNullish(properties[0].$id)) {
    setNodeKeyProperty(dataModel, nodeObjectTypeId, properties[0].$id);
  } else {
    const propertiesNamedId = properties.filter((p) => p.token.toLowerCase() === 'id');
    const propertiesEndingInId = properties.filter((p) => p.token.toLowerCase().endsWith('id'));
    if (propertiesNamedId.length === 1 && !isNullish(propertiesNamedId[0]) && !isNullish(propertiesNamedId[0].$id)) {
      setNodeKeyProperty(dataModel, nodeObjectTypeId, propertiesNamedId[0].$id);
    } else if (
      propertiesEndingInId.length === 1 &&
      !isNullish(propertiesEndingInId[0]) &&
      !isNullish(propertiesEndingInId[0].$id)
    ) {
      setNodeKeyProperty(dataModel, nodeObjectTypeId, propertiesEndingInId[0].$id);
    } else {
      return;
    }
  }
  tryAutoPopulateRelationshipMappingsForNode(dataModel, nodeObjectTypeId);
};

export const setRelationshipMappingTableSchema = (
  dataModel: ImportShared.DataModelJsonStruct,
  relationshipObjectTypeId: string,
  tableName: string,
) => {
  const relationshipMapping = getRelationshipMapping(dataModel, relationshipObjectTypeId);
  // Table schema doesn't exist should never happen, but just in case
  if (isNullish(getTableSchema(dataModel, tableName))) {
    return;
  }
  if (isNullish(relationshipMapping)) {
    dataModel.graphMappingRepresentation.relationshipMappings.push({
      relationship: { $ref: toRefString(relationshipObjectTypeId) },
      tableName,
      propertyMappings: [],
    });
  } else if (relationshipMapping.tableName !== tableName) {
    relationshipMapping.tableName = tableName;
    relationshipMapping.fromMapping = undefined;
    relationshipMapping.toMapping = undefined;
    relationshipMapping.propertyMappings = [];
    relationshipMapping.mappingFilter = undefined;
  }
};

export const setNodePropertyMapping = (
  dataModel: ImportShared.DataModelJsonStruct,
  nodeObjectTypeId: string,
  propertyId: string,
  fieldName: string,
) => {
  const nodeMapping = getNodeMapping(dataModel, nodeObjectTypeId);
  const nodeObjectType = getNodeObjectType(dataModel, nodeObjectTypeId);
  const nodeLabelRef = nodeObjectType?.labels[0]?.$ref ?? '';
  const nodeLabel = getNodeLabel(dataModel, toIdFromRef(nodeLabelRef));
  if (
    !isNullish(nodeMapping) &&
    !isNullish(nodeObjectType) &&
    !isNullish(nodeLabel) &&
    !isNullish(getNodeOrRelationshipProperty(nodeLabel, propertyId))
  ) {
    const propertyMapping = getPropertyMapping(nodeMapping, propertyId);
    if (isNullish(propertyMapping)) {
      nodeMapping.propertyMappings.push({
        property: { $ref: toRefString(propertyId) },
        fieldName,
      });
    } else {
      propertyMapping.fieldName = fieldName;
    }
  }
};

export const setRelationshipPropertyMapping = (
  dataModel: ImportShared.DataModelJsonStruct,
  relationshipObjectTypeId: string,
  propertyId: string,
  fieldName: string,
) => {
  const relationshipMapping = getRelationshipMapping(dataModel, relationshipObjectTypeId);
  const relationshipObjectType = getRelationshipObjectType(dataModel, relationshipObjectTypeId);
  const relationshipTypeRef = relationshipObjectType?.type.$ref ?? '';
  const relationshipType = getRelationshipType(dataModel, toIdFromRef(relationshipTypeRef));
  if (
    !isNullish(relationshipMapping) &&
    !isNullish(relationshipObjectType) &&
    !isNullish(relationshipType) &&
    !isNullish(getNodeOrRelationshipProperty(relationshipType, propertyId))
  ) {
    const propertyMapping = getPropertyMapping(relationshipMapping, propertyId);
    if (isNullish(propertyMapping)) {
      relationshipMapping.propertyMappings.push({
        property: { $ref: toRefString(propertyId) },
        fieldName,
      });
    } else {
      propertyMapping.fieldName = fieldName;
    }
  }
};

export const addNodePropertyMappings = (
  dataModel: ImportShared.DataModelJsonStruct,
  nodeObjectTypeId: string,
  fields: ImportShared.TableSchemaFieldJsonStruct[],
) => {
  const nodeObjectType = getNodeObjectType(dataModel, nodeObjectTypeId);
  const nodeLabelRef = nodeObjectType?.labels[0]?.$ref ?? '';
  const nodeLabel = getNodeLabel(dataModel, toIdFromRef(nodeLabelRef));
  const nodeMapping = getNodeMapping(dataModel, nodeObjectTypeId);
  if (!isNullish(nodeObjectType) && !isNullish(nodeMapping) && !isNullish(nodeLabel)) {
    const nodeHasNoProperties = nodeLabel.properties.length === 0;
    fields.forEach((field) => {
      const existingPropertyWithFieldName = nodeLabel.properties.find((p) => p.token === field.name);
      if (isNullish(existingPropertyWithFieldName)) {
        // If there isn't a property already with this name, add one and add a property mapping
        const type = field.recommendedType?.type;
        const newProperty = generateNextProperty(dataModel, nodeLabel, field.name, type);
        nodeLabel.properties.push(newProperty);
        if (!isNullish(newProperty.$id)) {
          nodeMapping.propertyMappings.push({
            fieldName: field.name,
            property: { $ref: toRefString(newProperty.$id) },
          });
        }
      } else if (
        !isNullish(existingPropertyWithFieldName.$id) &&
        isNullish(getPropertyMapping(nodeMapping, existingPropertyWithFieldName.$id))
      ) {
        // If there is already a property with this name, but there isn't an associated mapping, add a property mapping
        nodeMapping.propertyMappings.push({
          fieldName: field.name,
          property: { $ref: toRefString(existingPropertyWithFieldName.$id) },
        });
      }
    });

    if (nodeHasNoProperties) {
      tryAutoPopulateNodeId(dataModel, nodeObjectTypeId);
    }
  }
};

export const addRelationshipPropertyMappings = (
  dataModel: ImportShared.DataModelJsonStruct,
  relationshipObjectTypeId: string,
  fields: ImportShared.TableSchemaFieldJsonStruct[],
) => {
  const relationshipObjectType = getRelationshipObjectType(dataModel, relationshipObjectTypeId);
  const relationshipTypeRef = relationshipObjectType?.type.$ref ?? '';
  const relationshipType = getRelationshipType(dataModel, toIdFromRef(relationshipTypeRef));
  const relationshipMapping = getRelationshipMapping(dataModel, relationshipObjectTypeId);
  if (!isNullish(relationshipObjectType) && !isNullish(relationshipMapping) && !isNullish(relationshipType)) {
    fields.forEach((field) => {
      const existingPropertyWithFieldName = relationshipType.properties.find((p) => p.token === field.name);
      if (isNullish(existingPropertyWithFieldName)) {
        // If there isn't a property already with this name, add one and add a property mapping
        const type = field.recommendedType?.type;
        const newProperty = generateNextProperty(dataModel, relationshipType, field.name, type);
        relationshipType.properties.push(newProperty);
        if (!isNullish(newProperty.$id)) {
          relationshipMapping.propertyMappings.push({
            fieldName: field.name,
            property: { $ref: toRefString(newProperty.$id) },
          });
        }
      } else if (
        !isNullish(existingPropertyWithFieldName.$id) &&
        isNullish(getPropertyMapping(relationshipMapping, existingPropertyWithFieldName.$id))
      ) {
        // If there is already a property with this name, but there isn't an associated mapping, add a property mapping
        relationshipMapping.propertyMappings.push({
          fieldName: field.name,
          property: { $ref: toRefString(existingPropertyWithFieldName.$id) },
        });
      }
    });
  }
};

export const setRelationshipSourceMapping = (
  dataModel: ImportShared.DataModelJsonStruct,
  relationshipObjectTypeId: string,
  fieldName: string,
) => {
  const relationshipMapping = getRelationshipMapping(dataModel, relationshipObjectTypeId);
  if (!isNullish(relationshipMapping)) {
    relationshipMapping.fromMapping = { fieldName };
  }
};

export const setRelationshipTargetMapping = (
  dataModel: ImportShared.DataModelJsonStruct,
  relationshipObjectTypeId: string,
  fieldName: string,
) => {
  const relationshipMapping = getRelationshipMapping(dataModel, relationshipObjectTypeId);
  if (!isNullish(relationshipMapping)) {
    relationshipMapping.toMapping = { fieldName };
  }
};

export const setNodeMappingFilter = (
  dataModel: ImportShared.DataModelJsonStruct,
  nodeObjectTypeId: string,
  mappingFilter: ImportShared.MappingFilterJsonStruct | undefined,
) => {
  const nodeMapping = getNodeMapping(dataModel, nodeObjectTypeId);
  if (!isNullish(nodeMapping)) {
    nodeMapping.mappingFilter = mappingFilter;
  }
};

export const setRelationshipMappingFilter = (
  dataModel: ImportShared.DataModelJsonStruct,
  relationshipObjectTypeId: string,
  mappingFilter: ImportShared.MappingFilterJsonStruct | undefined,
) => {
  const relationshipMapping = getRelationshipMapping(dataModel, relationshipObjectTypeId);
  if (!isNullish(relationshipMapping)) {
    relationshipMapping.mappingFilter = mappingFilter;
  }
};

export const addNodeProperty = (dataModel: ImportShared.DataModelJsonStruct, nodeObjectTypeId: string) => {
  const nodeObjectType = getNodeObjectType(dataModel, nodeObjectTypeId);
  const nodeLabelRef = nodeObjectType?.labels[0]?.$ref ?? '';
  const nodeLabel = getNodeLabel(dataModel, toIdFromRef(nodeLabelRef));
  const hasNoOtherProperties = nodeLabel?.properties.length === 0;
  if (!isNullish(nodeLabel)) {
    const newProperty = generateNextProperty(dataModel, nodeLabel);
    nodeLabel.properties.push(newProperty);
    if (hasNoOtherProperties) {
      tryAutoPopulateNodeId(dataModel, nodeObjectTypeId);
    }
  }
};

export const addRelationshipProperty = (
  dataModel: ImportShared.DataModelJsonStruct,
  relationshipObjectTypeId: string,
) => {
  const relationshipObjectType = getRelationshipObjectType(dataModel, relationshipObjectTypeId);
  const relationshipTypeRef = relationshipObjectType?.type.$ref ?? '';
  const relationshipType = getRelationshipType(dataModel, toIdFromRef(relationshipTypeRef));
  if (!isNullish(relationshipType)) {
    const newProperty = generateNextProperty(dataModel, relationshipType);
    relationshipType.properties.push(newProperty);
  }
};

export const updateNodeProperty = (
  dataModel: ImportShared.DataModelJsonStruct,
  nodeObjectTypeId: string,
  property: formatters.json.types.PropertyJsonStruct,
) => {
  const nodeObjectType = getNodeObjectType(dataModel, nodeObjectTypeId);
  const nodeLabelRef = nodeObjectType?.labels[0]?.$ref ?? '';
  const nodeLabel = getNodeLabel(dataModel, toIdFromRef(nodeLabelRef));

  if (!isNullish(nodeLabel)) {
    const currentProperty = getNodeOrRelationshipProperty(nodeLabel, property.$id);
    const currentPropertyName = currentProperty?.token;
    if (!isNullish(currentProperty)) {
      updateProperty(currentProperty, property);
      if (currentPropertyName !== property.token && nodeObjectType) {
        updateIndexesName(dataModel, nodeLabel.$id, property.$id);

        const currentKeyProperty = dataModel.graphSchemaExtensionsRepresentation.nodeKeyProperties.find(
          ({ node }) => node.$ref === toRefString(nodeObjectType.$id),
        );
        const isKeyProperty = currentKeyProperty?.keyProperty.$ref === toRefString(property.$id);
        if (isKeyProperty) {
          addNodeConstraint(dataModel, nodeLabel.$id, property.$id);
        }
      }
    }
  }
};

export const updateRelationshipProperty = (
  dataModel: ImportShared.DataModelJsonStruct,
  relationshipObjectTypeId: string,
  property: formatters.json.types.PropertyJsonStruct,
) => {
  const relationshipObjectType = getRelationshipObjectType(dataModel, relationshipObjectTypeId);
  const relationshipTypeRef = relationshipObjectType?.type.$ref ?? '';
  const relationshipType = getRelationshipType(dataModel, toIdFromRef(relationshipTypeRef));
  if (!isNullish(relationshipType)) {
    const currentProperty = getNodeOrRelationshipProperty(relationshipType, property.$id);
    if (!isNullish(currentProperty)) {
      updateProperty(currentProperty, property);
    }
  }
};

const deleteIndexesByPropertyId = (dataModel: ImportShared.DataModelJsonStruct, propertyId: string) => {
  const { indexes } = dataModel.graphSchemaRepresentation.graphSchema;
  dataModel.graphSchemaRepresentation.graphSchema.indexes = indexes.filter((i) => {
    if (i.properties && i.properties[0]?.$ref === toRefString(propertyId)) {
      return false;
    }
    return true;
  });
};

const deleteNodeConstraint = (dataModel: ImportShared.DataModelJsonStruct, nodeLabelId: string) => {
  const { constraints } = dataModel.graphSchemaRepresentation.graphSchema;
  dataModel.graphSchemaRepresentation.graphSchema.constraints = constraints.filter((c) => {
    if (c.nodeLabel && c.nodeLabel.$ref === toRefString(nodeLabelId)) {
      return false;
    }
    return true;
  });
};

export const removeNodeProperty = (
  dataModel: ImportShared.DataModelJsonStruct,
  nodeObjectTypeId: string,
  propertyId: string,
) => {
  const nodeObjectType = getNodeObjectType(dataModel, nodeObjectTypeId);
  const nodeLabelRef = nodeObjectType?.labels[0]?.$ref ?? '';
  const nodeLabel = getNodeLabel(dataModel, toIdFromRef(nodeLabelRef));

  if (nodeLabel !== undefined) {
    const constraintsUnderNode = dataModel.graphSchemaRepresentation.graphSchema.constraints.filter(
      (c) => c.nodeLabel?.$ref === toRefString(nodeLabel.$id) && c.properties[0]?.$ref === toRefString(propertyId),
    );
    if (constraintsUnderNode[0] !== undefined) {
      deleteNodeConstraint(dataModel, nodeLabel.$id);
    }
  }
  deleteIndexesByPropertyId(dataModel, propertyId);

  if (!isNullish(nodeObjectType) && !isNullish(nodeLabel)) {
    // Remove property from schema
    nodeLabel.properties = nodeLabel.properties.filter((p) => p.$id !== propertyId);

    // Remove property mapping
    const nodeMapping = getNodeMapping(dataModel, nodeObjectTypeId);
    if (!isNullish(nodeMapping)) {
      nodeMapping.propertyMappings = nodeMapping.propertyMappings.filter(
        (propertyMapping) => propertyMapping.property.$ref !== toRefString(propertyId),
      );
    }

    // Remove key property
    dataModel.graphSchemaExtensionsRepresentation.nodeKeyProperties =
      dataModel.graphSchemaExtensionsRepresentation.nodeKeyProperties.filter(
        (nodeKeyProperty) =>
          nodeKeyProperty.node.$ref !== toRefString(nodeObjectTypeId) ||
          nodeKeyProperty.keyProperty.$ref !== toRefString(propertyId),
      );
  }
};

export const removeRelationshipProperty = (
  dataModel: ImportShared.DataModelJsonStruct,
  relationshipObjectTypeId: string,
  propertyId: string,
) => {
  const relationshipObjectType = getRelationshipObjectType(dataModel, relationshipObjectTypeId);
  const relationshipTypeRef = relationshipObjectType?.type.$ref ?? '';
  const relationshipType = getRelationshipType(dataModel, toIdFromRef(relationshipTypeRef));
  if (!isNullish(relationshipObjectType) && !isNullish(relationshipType)) {
    // Remove property from schema
    relationshipType.properties = relationshipType.properties.filter((p) => p.$id !== propertyId);

    // Remove property mapping
    const relationshipMapping = getRelationshipMapping(dataModel, relationshipObjectTypeId);
    if (!isNullish(relationshipMapping)) {
      relationshipMapping.propertyMappings = relationshipMapping.propertyMappings.filter(
        (propertyMapping) => propertyMapping.property.$ref !== toRefString(propertyId),
      );
    }
  }
};

export const deleteNodes = (dataModel: ImportShared.DataModelJsonStruct, nodeObjectTypeIds: string[]) => {
  const nodeLabelIds = nodeObjectTypeIds
    .map((nodeObjectTypeId) => getNodeObjectType(dataModel, nodeObjectTypeId)?.labels ?? [])
    .flat()
    .map(({ $ref }) => toIdFromRef($ref));

  const { nodeLabels, nodeObjectTypes } = dataModel.graphSchemaRepresentation.graphSchema;
  const { nodeMappings } = dataModel.graphMappingRepresentation;
  const { nodeKeyProperties } = dataModel.graphSchemaExtensionsRepresentation;

  dataModel.graphSchemaRepresentation.graphSchema.nodeLabels = nodeLabels.filter((n) => !nodeLabelIds.includes(n.$id));
  dataModel.graphSchemaRepresentation.graphSchema.nodeObjectTypes = nodeObjectTypes.filter(
    (n) => !nodeObjectTypeIds.includes(n.$id),
  );
  dataModel.graphMappingRepresentation.nodeMappings = nodeMappings.filter(
    (n) => !nodeObjectTypeIds.includes(toIdFromRef(n.node.$ref)),
  );
  dataModel.graphSchemaExtensionsRepresentation.nodeKeyProperties = nodeKeyProperties.filter(
    (n) => !nodeObjectTypeIds.includes(toIdFromRef(n.node.$ref)),
  );
};

export const deleteNodeIndexes = (dataModel: ImportShared.DataModelJsonStruct, nodeObjectTypeIds: string[]) => {
  const nodeLabelIds = nodeObjectTypeIds
    .map((nodeObjectTypeId) => getNodeObjectType(dataModel, nodeObjectTypeId)?.labels ?? [])
    .flat()
    .map(({ $ref }) => toIdFromRef($ref));
  const { indexes } = dataModel.graphSchemaRepresentation.graphSchema;
  dataModel.graphSchemaRepresentation.graphSchema.indexes = indexes.filter((i) => {
    if (i.nodeLabel && nodeLabelIds.includes(toIdFromRef(i.nodeLabel.$ref))) {
      return false;
    }
    return true;
  });
};

export const deleteNodeConstraints = (dataModel: ImportShared.DataModelJsonStruct, nodeObjectTypeIds: string[]) => {
  const nodeLabelIds = nodeObjectTypeIds
    .map((nodeObjectTypeId) => getNodeObjectType(dataModel, nodeObjectTypeId)?.labels ?? [])
    .flat()
    .map(({ $ref }) => toIdFromRef($ref));
  const { constraints } = dataModel.graphSchemaRepresentation.graphSchema;
  dataModel.graphSchemaRepresentation.graphSchema.constraints = constraints.filter((c) => {
    if (c.nodeLabel && nodeLabelIds.includes(toIdFromRef(c.nodeLabel.$ref))) {
      return false;
    }
    return true;
  });
};

export const deleteIndexByIds = (dataModel: ImportShared.DataModelJsonStruct, indexesId: string[]) => {
  const { indexes } = dataModel.graphSchemaRepresentation.graphSchema;
  dataModel.graphSchemaRepresentation.graphSchema.indexes = indexes.filter((i) => !indexesId.includes(i.$id));
};

export const deleteRelationships = (
  dataModel: ImportShared.DataModelJsonStruct,
  relationshipObjectTypeIds: string[],
) => {
  const relationshipTypeIds = relationshipObjectTypeIds
    .map((relationshipObjectTypeId) => getRelationshipObjectType(dataModel, relationshipObjectTypeId)?.type.$ref)
    .filter((r): r is string => !isNullish(r))
    .map(toIdFromRef);

  const { relationshipTypes, relationshipObjectTypes } = dataModel.graphSchemaRepresentation.graphSchema;
  const { relationshipMappings } = dataModel.graphMappingRepresentation;

  dataModel.graphSchemaRepresentation.graphSchema.relationshipTypes = relationshipTypes.filter(
    (r) => !relationshipTypeIds.includes(r.$id),
  );
  dataModel.graphSchemaRepresentation.graphSchema.relationshipObjectTypes = relationshipObjectTypes.filter(
    (r) => !relationshipObjectTypeIds.includes(r.$id),
  );
  dataModel.graphMappingRepresentation.relationshipMappings = relationshipMappings.filter(
    (r) => !relationshipObjectTypeIds.includes(toIdFromRef(r.relationship.$ref)),
  );
};

export const reverseRelationship = (dataModel: ImportShared.DataModelJsonStruct, relationshipObjectTypeId: string) => {
  const relationship = getRelationshipObjectType(dataModel, relationshipObjectTypeId);
  if (!isNullish(relationship)) {
    const { from, to } = relationship;
    relationship.to = from;
    relationship.from = to;
  }
  const relationshipMapping = getRelationshipMapping(dataModel, relationshipObjectTypeId);
  if (!isNullish(relationshipMapping)) {
    const { fromMapping, toMapping } = relationshipMapping;
    relationshipMapping.toMapping = fromMapping;
    relationshipMapping.fromMapping = toMapping;
  }
};

export const addTableSchema = (
  dataModel: ImportShared.DataModelJsonStruct,
  tableSchema: ImportShared.TableSchemaJsonStruct,
) => {
  const existingTableSchemaWithName = dataModel.graphMappingRepresentation.dataSourceSchema.tableSchemas.find(
    (t) => t.name === tableSchema.name,
  );
  if (isNullish(existingTableSchemaWithName)) {
    dataModel.graphMappingRepresentation.dataSourceSchema.tableSchemas.push(tableSchema);
  } else {
    existingTableSchemaWithName.expanded = tableSchema.expanded;
    existingTableSchemaWithName.fields = tableSchema.fields;
    existingTableSchemaWithName.foreignKeys = tableSchema.foreignKeys;
    existingTableSchemaWithName.primaryKeys = tableSchema.primaryKeys;

    dataModel.graphMappingRepresentation.nodeMappings.forEach((nodeMapping) =>
      removeMissingMappingsAfterTableUpdate(nodeMapping, existingTableSchemaWithName),
    );
    dataModel.graphMappingRepresentation.relationshipMappings.forEach((relationshipMapping) =>
      removeMissingMappingsAfterTableUpdate(relationshipMapping, existingTableSchemaWithName),
    );
  }
};

export const removeTableSchemas = (dataModel: ImportShared.DataModelJsonStruct, tableNames: string[]) => {
  const { dataSourceSchema, nodeMappings, relationshipMappings } = dataModel.graphMappingRepresentation;
  dataModel.graphMappingRepresentation.dataSourceSchema.tableSchemas = dataSourceSchema.tableSchemas.filter(
    (t) => !tableNames.includes(t.name),
  );
  dataModel.graphMappingRepresentation.nodeMappings = nodeMappings.filter(
    (nodeMapping) => !tableNames.includes(nodeMapping.tableName),
  );
  dataModel.graphMappingRepresentation.relationshipMappings = relationshipMappings.filter(
    (relationshipMapping) => !tableNames.includes(relationshipMapping.tableName),
  );
};

export const toggleTableSchemaExpanded = (dataModel: ImportShared.DataModelJsonStruct, name: string) => {
  const tableSchema = getTableSchema(dataModel, name);
  if (!isNullish(tableSchema)) {
    tableSchema.expanded = !tableSchema.expanded;
  }
};

export const expandAllTableSchemas = (dataModel: ImportShared.DataModelJsonStruct) => {
  const { tableSchemas } = dataModel.graphMappingRepresentation.dataSourceSchema;
  tableSchemas.forEach((tableSchema) => {
    tableSchema.expanded = true;
  });
};

export const collapseAllTableSchemas = (dataModel: ImportShared.DataModelJsonStruct) => {
  const { tableSchemas } = dataModel.graphMappingRepresentation.dataSourceSchema;
  tableSchemas.forEach((tableSchema) => {
    tableSchema.expanded = false;
  });
};
