import { createSlice } from '@reduxjs/toolkit';

export type NetworkState = {
  online: boolean;
};
export const initialState: NetworkState = {
  online: true,
};

export const slice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    networkUp() {
      return { online: true };
    },
    networkDown() {
      return { online: false };
    },
  },
});

export const { networkUp, networkDown } = slice.actions;
export default slice.reducer;
