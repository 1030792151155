export type DomJson = {
  name: string;
  type: number;
  value: ChildNode['nodeValue'];
  classList: string[];
  children: DomJson[];
  attributes?: {
    href?: string | null;
    src?: string;
    alt?: string | null;
    caption?: string | null;
    language?: string | null;
    role?: string | null;
    endpoint?: string | null;
    search?: string | null;
    tab?: string | null;
    copy?: string | null;
    // HTML Element ID for highlight action, eg: btn::color[role="NX_HIGHLIGHT",element="element-id-attribute"]
    element?: string | null;
  };
};

export enum TEST_GUIDE_PAGES {
  NX_INTERNAL_TEST = 'nxInternalTest',
}
