import { LoadingSpinner } from '@neo4j-ndl/react';
import * as React from 'react';

import type { FallbackType } from './error-boundary';
import { NxErrorBoundary } from './error-boundary';

export function PageBoundary({ children, fallback }: { children: React.ReactNode; fallback?: FallbackType }) {
  return (
    <NxErrorBoundary fallback={fallback}>
      <React.Suspense fallback={<LoadingSpinner size="large" className="absolute inset-0 m-auto" />}>
        {children}
      </React.Suspense>
    </NxErrorBoundary>
  );
}
