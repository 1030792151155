import { Banner, Button, Dialog, IconButton, LoadingSpinner, TextInput } from '@neo4j-ndl/react';
import { TrashIconOutline } from '@neo4j-ndl/react/icons';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import type { EncryptionKey, Instance, Project } from '@nx/state';
import { consoleApi, getApiError } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useEffect, useState } from 'react';

import { friendlyCloudProviderName, getEncryptionKeyId } from '../../instance/entities/helpers';
import { keyHeader } from './entities/helpers';

const logger = createLogger(APP_SCOPE.framework);

const DeleteEncryptionKeyDialog = ({
  project,
  encryptionKey,
  onClose,
}: {
  project: Project;
  encryptionKey: EncryptionKey;
  onClose: () => void;
}) => {
  const [modalType, setModalType] = useState<'warning' | 'delete' | null>(null);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const [linkedInstances, setLinkedInstances] = useState<Instance[]>([]);
  const [confirmText, setConfirmText] = useState('');
  // Check if key is currently used and open corresponding modal
  const [listInstances, listInstancesRes] = consoleApi.useLazyListInstancesQuery();
  const [getInstance, getInstanceRes] = consoleApi.useLazyGetInstanceQuery();
  const handleConfirmTextChange = (value: string) => {
    setConfirmText(value);
  };
  useEffect(() => {
    listInstances(project.id)
      .unwrap()
      .then(async (instanceSummaries) => {
        const promises = instanceSummaries.map(({ id }) => getInstance(id).unwrap());
        const instances = (await Promise.all(promises)).filter(
          (instance) => instance.encryptionKeyRef === encryptionKey.encryptionKeyRef,
        );
        setLinkedInstances(instances);
        setModalType(instances.length > 0 ? 'warning' : 'delete');
      })
      .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(e);
        if (isNotNullish(error.message)) {
          logger.error(error.message);
        }
      });
  }, [encryptionKey.encryptionKeyRef, encryptionKey.name, getInstance, listInstances, project.id]);

  const handleClose = () => {
    setModalType(null);
    setConfirmText('');
    onClose();
  };

  const [deleteKeyResource, deleteKeyResourceRes] = consoleApi.useDeleteEncryptionKeyMutation();
  const handleDeleteKey = () => {
    deleteKeyResource({ projectId: project.id, encryptionKeyRef: encryptionKey.encryptionKeyRef })
      .unwrap()
      .then(handleClose)
      .catch((err: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(err);
        const defaultErrorMessage = `There was an error deleting the encryption key "${encryptionKey.name}". Try again later. If the problem persists, contact support.`;
        if (error.code === 422) {
          setDeleteError(error.message ?? defaultErrorMessage);
        } else {
          setDeleteError(defaultErrorMessage);
        }
      });
  };

  return (
    <Dialog
      isOpen
      type="danger"
      size="large"
      modalProps={{
        'data-testid': 'delete-cmek-dialog',
      }}
      onClose={handleClose}
    >
      <Dialog.Header>Delete Customer Managed Key?</Dialog.Header>
      {(listInstancesRes.isFetching || getInstanceRes.isFetching) && (
        <Dialog.Content>
          <LoadingSpinner className="m-auto" size="large" />
        </Dialog.Content>
      )}
      {(listInstancesRes.isError || getInstanceRes.isError) && (
        <>
          <Dialog.Content>
            <Banner
              type="danger"
              description={`There was an error fetching instances that use the encryption key "${encryptionKey.name}". Try again later. If the problem persists, contact support.`}
              usage="inline"
            />
          </Dialog.Content>
          <Dialog.Actions>
            <Button color="neutral" fill="outlined" onClick={handleClose}>
              Close
            </Button>
          </Dialog.Actions>
        </>
      )}
      {modalType === 'warning' && (
        <>
          <Dialog.Content>
            <p>
              The Customer Managed Key <b>{encryptionKey.name}</b> is currently being used by the following instance
              {`${linkedInstances.length > 1 ? 's' : ''}`}:
            </p>
            <ul className="ml-10 mt-2 list-disc">
              {linkedInstances.map((instance, index) => {
                return <li className="list-item" key={index}>{`${instance.name} (${instance.id})`}</li>;
              })}
            </ul>
            <br></br>
            <p>To delete the key from Aura, ensure it is not linked to any active instance.</p>
          </Dialog.Content>
          <Dialog.Actions>
            <Button color="neutral" fill="outlined" onClick={handleClose}>
              Close
            </Button>
          </Dialog.Actions>
        </>
      )}
      {modalType === 'delete' && (
        <>
          <Dialog.Content>
            <div className="flex flex-col gap-4">
              <div>
                <p>
                  Are you sure you want to delete the Customer Managed Key <strong>{encryptionKey.name}</strong> with{' '}
                  {keyHeader(encryptionKey.cloudProvider)} <strong>{getEncryptionKeyId(encryptionKey)} </strong>?
                </p>
                <br />
                <p>
                  Deleting it will remove the key from Aura. If the key still exists in your{' '}
                  {friendlyCloudProviderName(encryptionKey.cloudProvider)} account, it will remain there.
                </p>
              </div>
              <TextInput
                isFluid
                value={confirmText}
                onChange={(e) => handleConfirmTextChange(e.target.value)}
                label="Are you sure"
                htmlAttributes={{
                  placeholder: `Type "CONFIRM" to confirm`,
                  autoFocus: true,
                  'data-testid': 'confirm-delete-cmek',
                }}
              />
              {isNotNullish(deleteError) && <Banner description={deleteError} type="danger" usage="inline" />}
            </div>
          </Dialog.Content>
          <Dialog.Actions>
            <Button color="neutral" fill="outlined" onClick={handleClose} isDisabled={deleteKeyResourceRes.isLoading}>
              Cancel
            </Button>
            <Button
              color="danger"
              isDisabled={confirmText !== 'CONFIRM'}
              isLoading={deleteKeyResourceRes.isLoading}
              onClick={handleDeleteKey}
            >
              Delete
            </Button>
          </Dialog.Actions>
        </>
      )}
    </Dialog>
  );
};

export const DeleteEncryptionKey = ({ project, encryptionKey }: { project: Project; encryptionKey: EncryptionKey }) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <IconButton
        ariaLabel="Delete cmek"
        isDanger
        onClick={() => setOpenModal(true)}
        htmlAttributes={{
          'data-testid': `delete-cmek-button-${encryptionKey.encryptionKeyRef}`,
          title: 'Delete encryption key',
        }}
      >
        <TrashIconOutline />
      </IconButton>
      {openModal && (
        <DeleteEncryptionKeyDialog
          project={project}
          encryptionKey={encryptionKey}
          onClose={() => setOpenModal(false)}
        />
      )}
    </>
  );
};
