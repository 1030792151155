import { flatten, isEmpty, isNil, mapValues, zipObject } from 'lodash-es';
import {
  concat,
  escapeRegExp,
  filter,
  flatMap,
  identity,
  last,
  map,
  pipe,
  slice,
  sortBy,
  sortedUniqBy,
} from 'lodash/fp';
import neo4j from 'neo4j-driver';
import { v4 as uuidv4 } from 'uuid';
import XRegExp from 'xregexp';

import { Date } from '../../../services/temporal/Date';
import { DateTime } from '../../../services/temporal/DateTime';
import { LocalDateTime } from '../../../services/temporal/LocalDateTime';
import { LocalTime } from '../../../services/temporal/LocalTime';
import { Time } from '../../../services/temporal/Time';
import { getNeo4jDateTimeType, parseStringToObj } from '../../../services/temporal/utils';
import { DATE, DATETIME, LOCAL_DATETIME, LOCAL_TIME, TIME } from '../../../services/temporal/utils.const';
import type { FullTextIndex } from '../../../types/databaseIndexes';
import type { Template as ITemplate } from '../../../types/template';
import type { Nullable } from '../../../types/utility';
import { getSafeBackTicksString } from '../../graph/cypherUtils';
import { readSearchTransaction } from '../readSearchTransaction';
import { SEARCH_PHRASE } from '../structured/suggestion';
import { BooleanSuggestion, Cypher, LabelProperty, ParamFinder, searchPhraseSuggestionLimit } from './template.const';
import type { CharType, InputCharType, Parameters } from './template.types';

type RecordConstructorArgs = ConstructorParameters<typeof neo4j.types.Record>;

const parseBoolean = (value: string) => {
  switch (value.toLowerCase()) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return null;
  }
};

// TODO rename to SearchPhrase
class Template {
  template: ITemplate;

  caseInsensitive: boolean;

  ftsIndexes: FullTextIndex[];

  databaseCallCounter: number;

  params: string[];

  paramSuggestions: any;

  texts: string[];

  requestIds: string[];

  constructor(template: ITemplate, caseInsensitive: boolean, ftsIndexes: FullTextIndex[]) {
    this.template = template;
    this.caseInsensitive = caseInsensitive;
    this.ftsIndexes = ftsIndexes;
    this.databaseCallCounter = 0;
    this.requestIds = [];

    const texts = [];
    const params = [];
    const paramSuggestionData = [];
    let curText = template.text;
    let match;
    while ((match = ParamFinder.exec(curText)) !== null) {
      const paramIndex = match[0].startsWith('$') ? match.index : match.index + 1; // Need to increase by one because the regex is matching one char before $ to avoid \$
      texts.push(curText.substring(0, paramIndex));

      const restOfString = curText.substring(paramIndex + 1); // + 1 to skip $
      const endIndex = this.regexIndexOf(restOfString, /[-\s]/g);

      const param = restOfString.substring(0, endIndex !== -1 ? endIndex : undefined);
      params.push(param);

      const found = template.params.find(({ name }) => name === `$${param}`);
      const paramQuery = found?.cypher;

      if (found?.suggestionBoolean === true) {
        paramSuggestionData.push({ type: BooleanSuggestion });
      } else if (!isNil(paramQuery) && !isEmpty(paramQuery)) {
        paramSuggestionData.push({
          type: Cypher,
          getPromise: async (params: Parameters) => this.getSuggestionPromise(paramQuery, params, true),
        });
      } else if (!isNil(found?.suggestionLabel) && found?.suggestionProp != null) {
        paramSuggestionData.push({
          type: LabelProperty,
          getPromise: async (curParam: string) => {
            const { cypher, parameters } = this.getSuggestionQuery(
              curParam ?? '',
              found.suggestionLabel ?? '',
              found.suggestionProp ?? '',
              found.dataType,
              searchPhraseSuggestionLimit,
            );
            return this.getSuggestionPromise(cypher, parameters, false);
          },
        });
      } else {
        paramSuggestionData.push(null);
      }

      curText = restOfString.substring(param.length);
    }

    // Push the text after the last parameter as another text segment
    if (curText.trim() !== '') {
      texts.push(curText);
    }

    this.params = params;
    this.paramSuggestions = paramSuggestionData;
    this.texts = texts.map((t) => t.replace(/\\\$/g, '$'));
  }

  getSuggestionPromise = async (query: string, params: Parameters, castParams: boolean) => {
    const requestId = uuidv4();
    this.requestIds.push(requestId);
    this.databaseCallCounter++;
    const castedParams = castParams ? this.castParameterValues(params) : params;

    return readSearchTransaction(query, { parameters: castedParams, requestId }).then(
      pipe(
        ({ records }) => records,
        filter((record: { _fields: RecordConstructorArgs[1] }) => !isNil(record?._fields) && record._fields.length > 0),
        flatMap((record: { _fields: RecordConstructorArgs[1] }) => {
          const [val] = record._fields;
          const isNumber =
            !isNil(val.low) && !isNil(val.high) && typeof val.low === 'number' && typeof val.high === 'number';
          return isNumber ? Number(val.toString()) : val;
        }),
        sortedUniqBy(identity),
      ),
    );
  };

  regexIndexOf(text: string, regex: RegExp, startPos?: number) {
    const indexOf = text.substring(startPos ?? 0).search(regex);
    return indexOf >= 0 ? indexOf + (startPos ?? 0) : indexOf;
  }

  getSuggestionQuery(
    value: string,
    label: string,
    property: string,
    dataType: string,
    limit: number,
  ): { cypher: string; parameters: Parameters } {
    const safeLabel = `\`${getSafeBackTicksString(label)}\``;
    const safeProp = `\`${getSafeBackTicksString(property)}\``;
    const ftsIndex = this.ftsIndexes.find(
      (idx) => idx.tokenNames.includes(label) && idx.propertyNames.includes(property),
    );
    if (this.caseInsensitive && ftsIndex !== undefined && value !== '') {
      return {
        cypher: `
          CALL db.index.fulltext.queryNodes($index, $query) YIELD node
          WITH node as n
          MATCH (n)
          WHERE toUpper(n.${safeProp}) STARTS WITH toUpper($value)
          RETURN DISTINCT n.${safeProp} AS prop
          ORDER BY prop LIMIT ${limit}
        `,
        parameters: { value, query: `${value}~`, index: ftsIndex.name },
      };
    }
    const whereClause = `ANY(element in n.${safeProp} WHERE toString(element) STARTS WITH $value)`;
    return {
      cypher: `
          MATCH (n:${safeLabel})
          WHERE ${whereClause}
          RETURN DISTINCT n.${safeProp} AS prop
          ORDER BY prop LIMIT ${limit}
        `,
      parameters: { value },
    };
  }

  castParameterValues(params: Parameters) {
    const findParameterType = (paramName: string) => {
      const param = this.template.params.find((p) => p.name === `$${paramName}`);
      return param !== undefined ? param.dataType : null;
    };

    const castType = (value: string, dataType: string | null) => {
      let result: any = value;
      switch (dataType) {
        case 'String':
          result = value.toString();
          break;
        case 'Integer':
          result = neo4j.int(parseInt(value));
          break;
        case 'Float':
          result = parseFloat(value);
          break;
        case 'Boolean':
          result = typeof value === 'string' ? parseBoolean(value) : value;
          break;
        case DATE:
          if (!isEmpty(value)) {
            if (neo4j.temporal.isDate(value)) {
              result = value;
            } else {
              const parsedDate = parseStringToObj(value, dataType);
              result =
                parsedDate instanceof Date
                  ? new neo4j.types.Date(parsedDate.getYear(), parsedDate.getMonth(), parsedDate.getDay())
                  : null;
            }
          }
          break;
        case LOCAL_TIME:
          if (!isEmpty(value)) {
            if (neo4j.temporal.isLocalTime(value)) {
              result = value;
            } else {
              const parsedLocalTime = parseStringToObj(value, dataType);
              result =
                parsedLocalTime instanceof LocalTime
                  ? new neo4j.types.LocalTime(
                      parsedLocalTime.getHour(),
                      parsedLocalTime.getMinute(),
                      parsedLocalTime.getSecond(),
                      parsedLocalTime.getNanosecond(),
                    )
                  : null;
            }
          }
          break;
        case LOCAL_DATETIME:
          if (!isEmpty(value)) {
            if (neo4j.temporal.isLocalDateTime(value)) {
              result = value;
            } else {
              const parsedLocalDateTime = parseStringToObj(value, dataType);
              result =
                parsedLocalDateTime instanceof LocalDateTime
                  ? new neo4j.types.LocalDateTime(
                      parsedLocalDateTime.getYear(),
                      parsedLocalDateTime.getMonth(),
                      parsedLocalDateTime.getDay(),
                      parsedLocalDateTime.getHour(),
                      parsedLocalDateTime.getMinute(),
                      parsedLocalDateTime.getSecond(),
                      parsedLocalDateTime.getNanosecond(),
                    )
                  : null;
            }
          }
          break;
        case DATETIME:
          if (!isEmpty(value)) {
            if (neo4j.temporal.isDateTime(value)) {
              result = value;
            } else {
              const parsedDateTime = parseStringToObj(value, dataType);
              result =
                parsedDateTime instanceof DateTime
                  ? new neo4j.types.DateTime(
                      parsedDateTime.getYear(),
                      parsedDateTime.getMonth(),
                      parsedDateTime.getDay(),
                      parsedDateTime.getHour(),
                      parsedDateTime.getMinute(),
                      parsedDateTime.getSecond(),
                      parsedDateTime.getNanosecond(),
                      parsedDateTime.getTimeZoneOffsetSeconds(),
                    )
                  : null;
            }
          }
          break;
        case TIME:
          if (!isEmpty(value)) {
            if (neo4j.temporal.isTime(value)) {
              result = value;
            } else {
              const parsedTime = parseStringToObj(value, dataType);
              result =
                parsedTime instanceof Time
                  ? new neo4j.types.Time(
                      parsedTime.getHour(),
                      parsedTime.getMinute(),
                      parsedTime.getSecond(),
                      parsedTime.getNanosecond(),
                      parsedTime.getTimeZoneOffsetSeconds(),
                    )
                  : null;
            }
          }
          break;
        default:
          throw Error("Unrecognized Data Type for param - this shouldn't happened");
      }

      return result;
    };

    return mapValues(params, (val, key) => castType(val, findParameterType(key)));
  }

  buildRegex(texts: string[], paramAtEnd: boolean) {
    let string = '^';
    string += texts.map((t) => escapeRegExp(escapeRegExp(t))).join('(.*)');
    string += paramAtEnd ? '(.*)' : '\\s*';
    string += '$';
    return XRegExp(string, 'is');
  }

  findFormat(searchPhraseTitle = '') {
    const hasTextInTheEnd = [...searchPhraseTitle.matchAll(/\s[^$]+$/gi)].length > 0;
    const paramMatches = [...searchPhraseTitle.matchAll(/\$\w+/gi)];
    const paramRanges = paramMatches.map((m) => ({
      type: 'param',
      start: m.index,
      range: [m.index, (m.index ?? 0) + m[0].length],
    }));

    let firstParamIndex = 0;

    const textRanges = paramRanges
      .map((m) => {
        if (searchPhraseTitle.substring(firstParamIndex, m.range[0]).length > 0) {
          const d = {
            type: 'text',
            start: firstParamIndex,
            range: [firstParamIndex, firstParamIndex + (m.range[0] ?? 0) - 1],
          };
          firstParamIndex = (m.range[1] ?? 0) + 1;
          return d;
        }
        return null;
      })
      .filter((t) => t);

    // Example of format: If search title => 'movies with $title and $released' => [text, param, text, param]
    const format = pipe(
      concat(paramRanges),
      sortBy('start'),
      map((t: { type: InputCharType }) => t.type),
    )(textRanges);

    if (hasTextInTheEnd) {
      format.push('text');
    } // as we iterate through paramRanges for calculating the textRanges we miss the case a text is found at the end of the search phrase
    return format;
  }

  findCharsType(input = '', searchPhraseTitle = '', params: string[] = [], texts: string[] = []) {
    const getParameterCharLength = (p: any) => {
      const isString = (s: any) => typeof s === 'string';
      return isString(p) ? p.length : p.toString().length;
    };
    const hasParams = !(params.filter(getParameterCharLength).length === 0);

    if (hasParams) {
      const format = this.findFormat(searchPhraseTitle);
      let cacheType = format[0] ?? 'text';
      let paramIndex = 0;
      let textIndex = 0;

      // Based on the template replacing the texts and params in order to find the type of each char ('text' or 'param')
      // Search phrase: 'movies with $actor actor'
      // Example: params => ['Tom Hanks'], texts => ['movies with ', ' actor']
      // format: [text, param, text]
      // replacing: ['movies with ', 'Tom Hanks', ' actor'] => we now know the position of the texts/params
      const charsByPosition: InputCharType[][] = format.reduce(
        (acc: InputCharType[][], curValue: CharType, index: number) => {
          if (curValue === 'param' && cacheType === 'param' && index > 0) {
            // case two params in a row
            acc.push([{ pos: null, type: 'text' }]);
          }
          if (curValue === 'param' && !isNil(params[paramIndex])) {
            // we check params[paramIndex] in case the search phrase in not completed
            acc.push(
              (params[paramIndex] ?? '')
                .toString()
                .split('')
                .map(() => ({ pos: null, type: 'param' })),
            ); // we format to string in case the parameter is a number
            paramIndex++;
          } else if (curValue === 'text' && !isNil(texts[textIndex])) {
            acc.push(
              (texts[textIndex] ?? '')
                .toString()
                .split('')
                .map(() => ({ pos: null, type: 'text' })),
            );
            textIndex++;
          }
          cacheType = curValue;
          return acc;
        },
        [],
      );

      return flatten(charsByPosition).map((c: InputCharType, i: number) => ({
        pos: i,
        type: c.type,
      }));
    }
    return input.split('').map((m, i) => ({
      pos: i,
      type: 'text',
    }));
  }

  matches(input: string) {
    const safeInput = escapeRegExp(input);

    const isMatch = this.texts.some((text) => escapeRegExp(text).match(new RegExp(safeInput, 'gi')));

    const paramAtEnd = this.params.length === this.texts.length;

    const regexes = this.texts
      .map((_, i) => this.buildRegex(this.texts.slice(0, i + 1), paramAtEnd || i < this.texts.length - 1))
      .reverse(); // Reverse to start matching from the end (whole phrase first)

    const index = regexes.findIndex((r) => safeInput.match(r));
    const params = [];
    if (index !== -1) {
      for (let i = 0; i < this.texts.length; i++) {
        if (input.length === 0) {
          break;
        }
        input = input.substring((this.texts[i] ?? '').length);
        let param = input;
        if (i < this.texts.length - 1) {
          const nextIndex = input.indexOf(this.texts[i + 1] ?? '');
          if (nextIndex > 0) {
            param = input.substring(0, nextIndex);
          }
        }
        if (params.length < this.params.length) {
          params.push(param);
        }
        input = input.substring(param.length);
      }

      const lastParam = last(params);

      if (lastParam?.includes(' ') === true) {
        // Check if the param input matches the next part of the search phrase
        const phraseAfterLastParam = this.texts[this.texts.length - index];

        if (!isNil(phraseAfterLastParam)) {
          for (let i = 0; i < lastParam.length; i++) {
            if (phraseAfterLastParam.match(new RegExp(`^${escapeRegExp(lastParam.substring(i))}`)) != null) {
              // Trimming the param input by removing the substring that matches the next part of the search phrase
              params.pop();
              params.push(lastParam.substring(0, i));
              break;
            }
          }
        }
      }
    }

    return {
      index: index === -1 ? index : regexes.length - index - 1,
      isMatch: isMatch || index > -1,
      templateText: this.template.text,
      params,
    };
  }

  getDisplayText(params: string[], suggestion?: Nullable<string>, index = 0) {
    if (this.params?.length === 0) {
      return this.texts[0];
    }

    return this.texts.reduce((display, text, i) => {
      display += text;

      if (i < index) {
        display += params[i];
      } else if (i > index) {
        display += !isNil(this.params[i]) ? `$${this.params[i]}` : '';
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      } else {
        display += suggestion ?? (isNil(params[i]) ? (!isNil(this.params[i]) ? `$${this.params[i]}` : '') : params[i]);
      }

      return display;
    }, '');
  }

  getReplacementText(params: string[], suggestion?: Nullable<string>, index = 0) {
    if ((params?.length === 0 || this.params?.length === 0) && isNil(suggestion)) {
      return this.texts[0];
    }

    let replacementText = '';
    for (let i = 0; i < this.texts.length; i++) {
      replacementText += this.texts[i];

      if (i >= params.length) {
        return replacementText;
      }

      if (isNil(suggestion)) {
        replacementText += params[i];
      } else {
        replacementText += i < index ? params[i] : suggestion;
      }
    }

    return this.texts.length > this.params.length && this.texts.length > params.length
      ? replacementText + this.texts[this.texts.length - 1]
      : replacementText;
  }

  generateQuery(values: string[]) {
    // TODO: revisit after param index/map issue resolved
    const parameters = this.params.reduce((obj, parameterName, i) => ({ ...obj, [parameterName]: values[i] }), {});
    return { cypher: this.template.cypher, parameters: this.castParameterValues(parameters) };
  }

  getSuggestions(text: string, isNewSearchPhrase = false) {
    this.databaseCallCounter = 0;
    const suggestions = [];
    const matched = this.matches(text);

    if (matched.isMatch) {
      if (matched.index < 0) {
        const complete = this.params.length === 0 || this.params.length === matched.params.length;
        suggestions.push(
          Promise.resolve([
            {
              // wrap in array because the other promises resolve to arrays
              id: this.template.id,
              type: SEARCH_PHRASE,
              text: this.getReplacementText(matched.params),
              displayText: this.getDisplayText(matched.params),
              description: this.template.name,
              query: complete ? this.generateQuery([]) : null,
              inputCharTypes: [],
              complete,
              hasCypherErrors: this.template.hasCypherErrors,
              isUpdateQuery: this.template.isUpdateQuery,
            },
          ]),
        );
      } else {
        const findSuggestions = pipe(
          concat(matched.params[matched.index] ?? ''),
          filter((sug) => sug !== ''),
          filter(
            (sug) =>
              (getNeo4jDateTimeType(sug) ??
                sug.toString().match(new RegExp(`${escapeRegExp(matched.params[matched.index] ?? '')}`, 'gi'))) != null,
          ),
          slice(0, searchPhraseSuggestionLimit),
          map((suggestion) => {
            const complete = this.params.length <= matched.params.length;
            const values = [...matched.params];
            values.pop();
            values.push(suggestion);

            const displayText = this.getDisplayText(values, suggestion, matched.index);
            const replacementText = this.getReplacementText(values, suggestion, matched.index);
            const inputCharTypes = this.findCharsType(replacementText, matched.templateText, values, this.texts);
            return {
              type: SEARCH_PHRASE,
              inputCharTypes,
              text: replacementText,
              displayText,
              description: this.template.name,
              query: complete ? this.generateQuery(values) : null,
              complete,
              hasCypherErrors: this.template.hasCypherErrors,
              isUpdateQuery: this.template.isUpdateQuery,
            };
          }),
        );

        const queryInfo = this.paramSuggestions[matched.index];
        if (queryInfo?.type === Cypher) {
          const paramMapping = zipObject(this.params.slice(0, matched.index), matched.params.slice(0, matched.index));
          const promise = queryInfo.getPromise(paramMapping).then(findSuggestions);
          suggestions.push(promise);
        } else if (queryInfo?.type === LabelProperty) {
          const promise = queryInfo.getPromise(matched.params[matched.index]).then(findSuggestions);
          suggestions.push(promise);
        } else if (queryInfo?.type === BooleanSuggestion) {
          suggestions.push(Promise.resolve(findSuggestions(['true', 'false'])));
        } else {
          const complete = this.params.length === 0 || this.params.length <= matched.params.length;
          suggestions.push(
            Promise.resolve([
              {
                type: SEARCH_PHRASE,
                text: this.getReplacementText(matched.params, null, matched.index),
                displayText: this.getDisplayText(matched.params, null, matched.index),
                description: this.template.name,
                query: complete ? this.generateQuery(matched.params) : null,
                inputCharTypes: [],
                complete,
                hasCypherErrors: this.template.hasCypherErrors,
                isUpdateQuery: this.template.isUpdateQuery,
              },
            ]),
          );
        }
      }
    }

    const tempRequestIds = [...this.requestIds];
    this.requestIds = [];

    if (isNewSearchPhrase) {
      return {
        suggestions,
        requestIds: tempRequestIds,
      };
    }

    return suggestions;
  }

  getParams() {
    return this.params;
  }

  getTexts() {
    return this.texts;
  }
}

export default Template;
