import { INVITE_STATUS, PROJECT_ROLE_NAME } from '@nx/state';

import { ORG_ROLE_NAME } from './entities/model';
import type { User } from './entities/model';

export const MOCK_PROJECTS = [
  {
    projectId: 'proj23',
    projectName: 'data-project-production',
  },
  {
    projectId: '1',
    projectName: 'data-project-exploration',
  },
  {
    projectId: '2',
    projectName: 'data-project-staging',
  },
] as const;

export const MOCK_INSTANCES = [
  {
    dbId: '01',
    dbName: 'Instance01',
  },
  {
    dbId: '02',
    dbName: 'Instance02',
  },
] as const;

export const MOCK_USERS: User[] = [
  {
    id: '0',
    email: 'sebastian.wictorin@neo4j.com',
    lastActive: new Date(1708959005000),
    inviteStatus: undefined,
    metadata: '',
    orgRole: { name: ORG_ROLE_NAME.OWNER },
    projectRoles: [
      { name: PROJECT_ROLE_NAME.PROJECT_ADMIN, ...MOCK_PROJECTS[0] },
      { name: PROJECT_ROLE_NAME.PROJECT_ADMIN, ...MOCK_PROJECTS[1] },
      { name: PROJECT_ROLE_NAME.PROJECT_ADMIN, ...MOCK_PROJECTS[2] },
    ],
    dbRoles: [
      { name: 'admin', ...MOCK_INSTANCES[0] },
      { name: 'admin', ...MOCK_INSTANCES[1] },
    ],
  },
  {
    id: '1',
    email: 'thomas.wiss@neo4j.com',
    lastActive: new Date(1708953005000),
    inviteStatus: undefined,
    metadata: '',
    orgRole: { name: ORG_ROLE_NAME.MEMBER },
    projectRoles: [{ name: PROJECT_ROLE_NAME.PROJECT_ADMIN, ...MOCK_PROJECTS[1] }],
    dbRoles: [{ name: 'reader', ...MOCK_INSTANCES[0] }],
  },
  {
    id: '2',
    email: 'ian.pollard@neo4j.com',
    lastActive: new Date(1702159005000),
    inviteStatus: undefined,
    metadata: '',
    orgRole: { name: ORG_ROLE_NAME.ADMIN },
    projectRoles: [{ name: PROJECT_ROLE_NAME.PROJECT_VIEWER, ...MOCK_PROJECTS[0] }],
    dbRoles: [{ name: 'reader', ...MOCK_INSTANCES[0] }],
  },
  {
    id: '3',
    email: 'nissanka.fernando@neo4j.com',
    lastActive: new Date(1552159055000),
    inviteStatus: undefined,
    metadata: '',
    orgRole: { name: ORG_ROLE_NAME.MEMBER },
    projectRoles: [{ name: PROJECT_ROLE_NAME.PROJECT_ADMIN, ...MOCK_PROJECTS[1] }],
    dbRoles: [{ name: 'custom_role1', ...MOCK_INSTANCES[0] }],
  },
  {
    id: '4',
    email: 'the.intern@neo4j.com',
    lastActive: undefined,
    inviteStatus: INVITE_STATUS.PENDING,
    metadata: 'Summer internship for students',
    orgRole: { name: ORG_ROLE_NAME.MEMBER },
    projectRoles: [{ name: PROJECT_ROLE_NAME.PROJECT_VIEWER, ...MOCK_PROJECTS[0] }],
    dbRoles: [{ name: 'reader', ...MOCK_INSTANCES[0] }],
  },
];
