import { Button, Dialog, Typography } from '@neo4j-ndl/react';
import { consoleApi } from '@nx/state';
import { useEffect } from 'react';

export function DeleteApiKeyModal({ clientId, onClose }: { clientId: string; onClose: () => void }) {
  const clientsQueryRes = consoleApi.useListOauthClientsQuery();
  const [deleteClient, deleteClientRes] = consoleApi.useDeleteOauthClientMutation();

  useEffect(() => {
    if (deleteClientRes.isSuccess && !clientsQueryRes.isFetching) {
      onClose();
    }
  }, [deleteClientRes.isSuccess, clientsQueryRes.isFetching, onClose]);

  return (
    <Dialog isOpen onClose={onClose} size="small">
      <Dialog.Header>Delete API key</Dialog.Header>
      <Typography variant="body-large">
        Are you sure you want to delete API Key with Client ID <span className="font-bold">{clientId}</span>?
      </Typography>
      <Dialog.Actions>
        <Button fill="outlined" onClick={onClose} color="neutral">
          Close
        </Button>
        <Button
          color="danger"
          onClick={() => {
            void deleteClient(clientId);
          }}
          isLoading={deleteClientRes.isLoading || deleteClientRes.isSuccess}
        >
          Delete
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}
