import {
  isFeatureFlag,
  LEGACY_purgeFeatureFlags as purgeFeatureFlags,
  LEGACY_toggleFeatureFlag as toggleFeatureFlag,
} from '@nx/state';

export function setupFeatureFlags() {
  const { searchParams } = new URL(window.location.href);
  const enabledFlags = searchParams.getAll('enableFlag');

  for (const enabledFlag of enabledFlags) {
    if (isFeatureFlag(enabledFlag)) {
      toggleFeatureFlag({ key: enabledFlag, value: true });
    }
  }

  purgeFeatureFlags();
}
