import type { Node, Relationship } from '@neo4j-nvl/base';
import type { TierConfig, User } from '@nx/state';
import { CLOUD_PROVIDER, ONBOARDING_EXPERIENCE, REGIONAL_GDPR_COMPLIANCE_TIER, TIER } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';

import type { InstanceFormData, OnboardingData } from './model';
import { JOB_ROLE, ONBOARDING_INTENT, intentLabel, jobRoleLabels, useCaseLabels } from './model';

export const profilingDefaults = ({
  firstName = '',
  lastName = '',
}: {
  firstName?: string;
  lastName?: string;
}): OnboardingData => ({
  firstName,
  lastName,
  companyName: '',
  onboardingIntent: null,
  jobRole: null,
  primaryUseCase: null,
});

const FREE_MEMORY = '1GB';
const FREE_STORAGE = '2GB';
const TRIAL_MEMORY = '4GB';
const TRIAL_STORAGE = '8GB';

export const freeDefaults = (tierConfig: TierConfig): InstanceFormData => {
  const freeTierCloudProvider = CLOUD_PROVIDER.GCP;
  const region = tierConfig.cloudProviderRegions[freeTierCloudProvider]?.[0]?.name;

  return {
    name: 'Free instance',
    tier: TIER.FREE,
    region,
    cloudProvider: freeTierCloudProvider,
    version: tierConfig.defaultVersion,
    memory: FREE_MEMORY,
    storage: FREE_STORAGE,
  };
};

export const proTrialDefaults = (tierConfig: TierConfig): InstanceFormData => {
  const { cloudProviderRegions } = tierConfig;

  const cloudProvider = CLOUD_PROVIDER.GCP;
  // Get the first available region for the GCP
  const [region] = cloudProviderRegions[cloudProvider] ?? [];

  return {
    name: 'Trial instance',
    version: tierConfig.defaultVersion,
    tier: TIER.PROFESSIONAL,
    region: region?.name,
    memory: TRIAL_MEMORY,
    storage: TRIAL_STORAGE,
    cloudProvider: cloudProvider,
  };
};

export const constructNodes = (data: OnboardingData): Node[] => {
  const nodes: Node[] = [];
  const { companyName: company, lastName, firstName, onboardingIntent, jobRole: role, primaryUseCase: useCase } = data;
  if (firstName !== '' || lastName !== '') {
    nodes.push({ id: 'name', captions: [{ value: `${firstName} ${lastName}` }], color: '#FFDF81', x: 0, y: 0 });
  }
  if (company !== '') {
    nodes.push({ id: 'company', captions: [{ value: company }], color: '#C990C0', x: 300, y: 0 });
  }
  if (isNotNullish(onboardingIntent)) {
    nodes.push({
      id: 'intent',
      captions: [{ value: intentLabel[onboardingIntent] }],
      color: '#55BDC5',
      x: 250,
      y: -200,
    });
  }
  if (isNotNullish(useCase)) {
    // TODO: Uncomment when we allow to have text "other" text
    // const caption = useCase === USE_CASE.Other ? (data.primaryUseCaseOther ?? '') : useCaseLabels[useCase];
    const caption = useCaseLabels[useCase];
    nodes.push({ id: 'useCase', captions: [{ value: caption }], color: '#DBBF40', x: 230, y: 105 });
  }
  if (isNotNullish(role)) {
    nodes.push({ id: 'role', captions: [{ value: jobRoleLabels[role] }], color: '#78DE7C', x: 150, y: 200 });
  }
  return nodes;
};

export const constructRels = (nodes: Node[]) => {
  const relationships: Relationship[] = [];
  const name = nodes.find((node) => node.id === 'name');
  const company = nodes.find((node) => node.id === 'company');
  const project = nodes.find((node) => node.id === 'intent');
  const role = nodes.find((node) => node.id === 'role');
  const useCase = nodes.find((node) => node.id === 'useCase');

  if (isNotNullish(name) && isNotNullish(company)) {
    relationships.push({ id: 'works_at', from: name.id, to: company.id, captions: [{ value: 'WORKS_AT' }] });
  }

  if (isNotNullish(name) && isNotNullish(project)) {
    relationships.push({
      id: 'will_use_neo4j_for',
      from: name.id,
      to: project.id,
      captions: [{ value: 'WILL_USE_NEO4J_FOR' }],
    });
  }

  if (isNotNullish(name) && isNotNullish(useCase)) {
    relationships.push({ id: 'has_use_case', from: name.id, to: useCase.id, captions: [{ value: 'HAS_USE_CASE' }] });
  }

  if (isNotNullish(name) && isNotNullish(role)) {
    relationships.push({ id: 'role_is', from: name.id, to: role.id, captions: [{ value: 'ROLE_IS' }] });
  }
  return relationships;
};

export const determineInstanceType = (
  user: User | undefined,
  intent: ONBOARDING_INTENT | null,
  role: JOB_ROLE | null,
): ONBOARDING_EXPERIENCE => {
  if (
    isNullish(user) ||
    isNullish(intent) ||
    isNullish(role) ||
    intent !== ONBOARDING_INTENT.Work ||
    role === JOB_ROLE.Student ||
    // The default onboarding experience for that country
    user.onboardingExperience === ONBOARDING_EXPERIENCE.Free ||
    // Default all users with the education top domain to free
    user.email.endsWith('.edu')
  ) {
    return ONBOARDING_EXPERIENCE.Free;
  }

  return ONBOARDING_EXPERIENCE.Trial;
};

export const friendlyCloudProviderName = (cloudProvider: CLOUD_PROVIDER) => {
  switch (cloudProvider) {
    case CLOUD_PROVIDER.GCP:
      return 'Google Cloud Platform';
    case CLOUD_PROVIDER.AWS:
      return 'AWS';
    case CLOUD_PROVIDER.AZURE:
      return 'Azure';
    default:
      return null;
  }
};

export const determineMarketingPreferenceFromRegionalTier = (
  regionalTier: REGIONAL_GDPR_COMPLIANCE_TIER,
  marketingPreference: boolean,
) => {
  if (regionalTier === REGIONAL_GDPR_COMPLIANCE_TIER.TIER_1) {
    return true;
  }
  return regionalTier !== REGIONAL_GDPR_COMPLIANCE_TIER.TIER_2 ? marketingPreference : !marketingPreference;
};
