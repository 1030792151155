export const CAPTION_TYPE_CATEGORY = 'category';
export const CAPTION_TYPE_LABEL = 'label';
export const CAPTION_TYPE_REL = 'relationship';
export const CAPTION_TYPE_PROPERTY = 'property';

export type CaptionType =
  | typeof CAPTION_TYPE_CATEGORY
  | typeof CAPTION_TYPE_LABEL
  | typeof CAPTION_TYPE_REL
  | typeof CAPTION_TYPE_PROPERTY;

export type CaptionStyle = 'bold' | 'italic' | 'underline';

export interface Caption {
  key?: string;
  type: CaptionType;
  isCaption: boolean;
  inTooltip: boolean;

  isGdsData?: boolean;
  styles: CaptionStyle[];
  value?: string;
}
