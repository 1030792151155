import { scale as colorScale, valid as validColor } from 'chroma-js';
import { clamp, isEmpty, isNumber } from 'lodash-es';
import neo4j from 'neo4j-driver';

import { isComparablePropertyType } from '../../modules/Filter/NumberAndDateTimeConditionUtils';
import { validTypeForUniqueValues } from '../../modules/Legend/Popups/RuleBasedStyling/helpers';
import {
  getBaseTypeSuffixFromRuleBase,
  getTitleFromRuleBase,
} from '../../modules/Legend/Popups/RuleBasedStyling/rules';
import {
  compareTemporalValue,
  convertDateTimePropertiesToObj,
  isDateTimeType,
  isZonedType,
  parseStringToNumber,
  parseStringToObj,
  setTimezoneShiftToForString,
} from '../../services/temporal/utils';
import {
  AFTER,
  BEFORE,
  BETWEEN,
  EQUALS,
  GREATER_THAN,
  GREATER_THAN_OR_EQUAL_TO,
  LESS_THAN,
  LESS_THAN_OR_EQUAL_TO,
  NOT_BETWEEN,
  NOT_EQUALS,
} from './ruleEvaluators.const';

const parseBetweenCondValues = (nodeValue, ruleValue, rangeRuleValue) => {
  let parsedNodeVal = null;
  if (neo4j.isInt(nodeValue)) {
    parsedNodeVal = nodeValue.toBigInt();
  } else if (typeof nodeValue === 'number') {
    parsedNodeVal = nodeValue;
  }
  const condVal = castValue(ruleValue, parsedNodeVal);
  const rangeVal = castValue(rangeRuleValue, parsedNodeVal);
  return { parsedNodeVal, condVal, rangeVal };
};

const castValue = (value, reference) => {
  if (reference === null || reference === undefined) {
    return value;
    /* eslint-disable valid-typeof */
  } else if (typeof reference === 'number' || typeof reference === 'bigint') {
    return parseFloat(value);
  } else if (typeof reference === 'string') {
    return value;
  } else if (value) {
    return JSON.stringify(value);
  } else {
    return null;
  }
};

const ruleValueIsNotSet = (condition, ruleValue) => {
  if (condition === 'is-true' || condition === 'is-false') {
    return false;
  }
  return ruleValue === undefined || ruleValue === '';
};

export const checkDatetimeRuleCondition = (
  propertyValue,
  ruleBase,
  condition,
  conditionValue,
  conditionValueRange,
  selectedTimeZone,
  isTimeZoneConvertEnabled,
) => {
  if (isEmpty(ruleBase) || isEmpty(condition) || isEmpty(conditionValue) || isEmpty(propertyValue)) {
    return false;
  }

  const isSameType = isComparablePropertyType(propertyValue.type, ruleBase);
  if (!isSameType) {
    return false;
  }

  const nodeDateTime = convertDateTimePropertiesToObj(propertyValue.dateTimeProperties, ruleBase);
  const conditionDateTime = parseStringToObj(conditionValue, ruleBase);
  const conditionRangeDateTime = parseStringToObj(conditionValueRange, ruleBase);

  const comparingResult = compareTemporalValue(
    ruleBase,
    conditionDateTime,
    nodeDateTime,
    selectedTimeZone,
    isTimeZoneConvertEnabled,
  );
  const comparingResultRange = compareTemporalValue(
    ruleBase,
    nodeDateTime,
    conditionRangeDateTime,
    selectedTimeZone,
    isTimeZoneConvertEnabled,
  );

  switch (condition) {
    case EQUALS:
      return comparingResult === 0;
    case NOT_EQUALS:
      return comparingResult !== 0;
    case BEFORE:
      return comparingResult > 0;
    case AFTER:
      return comparingResult < 0;
    case BETWEEN:
      if (!conditionRangeDateTime) {
        return false;
      }

      const comparingResultBetween = compareTemporalValue(
        ruleBase,
        conditionDateTime,
        conditionRangeDateTime,
        selectedTimeZone,
        isTimeZoneConvertEnabled,
      );

      return comparingResultBetween < 0
        ? comparingResult <= 0 && comparingResultRange <= 0
        : comparingResult >= 0 && comparingResultRange >= 0;
    case NOT_BETWEEN:
      if (!conditionRangeDateTime) {
        return false;
      }

      const comparingResultNotBetween = compareTemporalValue(
        ruleBase,
        conditionDateTime,
        conditionRangeDateTime,
        selectedTimeZone,
        isTimeZoneConvertEnabled,
      );

      return comparingResultNotBetween < 0
        ? comparingResult > 0 || comparingResultRange > 0
        : comparingResult < 0 || comparingResultRange < 0;
    default:
      return false;
  }
};

export const checkSingleRuleCondition = (condition, ruleValue, nodeValue, rangeRuleValue = null) => {
  if (ruleValueIsNotSet(condition, ruleValue) || nodeValue === undefined) {
    return false;
  }

  switch (condition) {
    case 'contains':
      if (typeof nodeValue === 'string') {
        return nodeValue.includes(ruleValue);
      }
      break;
    case 'starts-with':
      if (typeof nodeValue === 'string') {
        return nodeValue.startsWith(ruleValue);
      }
      break;
    case 'ends-with':
      if (typeof nodeValue === 'string') {
        return nodeValue.endsWith(ruleValue);
      }
      break;
    case EQUALS:
      if (neo4j.isInt(nodeValue)) {
        return nodeValue.equals(parseFloat(ruleValue));
      } else {
        return castValue(ruleValue, nodeValue) === nodeValue;
      }
    case NOT_EQUALS:
      if (neo4j.isInt(nodeValue)) {
        return !nodeValue.equals(ruleValue);
      } else {
        return castValue(ruleValue, nodeValue) !== nodeValue;
      }
    case GREATER_THAN:
      if (neo4j.isInt(nodeValue)) {
        return nodeValue.greaterThan(ruleValue);
      } else if (typeof nodeValue === 'number') {
        return nodeValue > castValue(ruleValue, nodeValue);
      }
      break;
    case LESS_THAN:
      if (neo4j.isInt(nodeValue)) {
        return nodeValue.lessThan(ruleValue);
      } else if (typeof nodeValue === 'number') {
        return nodeValue < castValue(ruleValue, nodeValue);
      }
      break;
    case GREATER_THAN_OR_EQUAL_TO:
      if (neo4j.isInt(nodeValue)) {
        return nodeValue.greaterThanOrEqual(ruleValue);
      } else if (typeof nodeValue === 'number') {
        return nodeValue >= castValue(ruleValue, nodeValue);
      }
      break;
    case LESS_THAN_OR_EQUAL_TO:
      if (neo4j.isInt(nodeValue)) {
        return nodeValue.lessThanOrEqual(ruleValue);
      } else if (typeof nodeValue === 'number') {
        return nodeValue <= castValue(ruleValue, nodeValue);
      }
      break;
    case BETWEEN:
      const { parsedNodeVal, condVal, rangeVal } = parseBetweenCondValues(nodeValue, ruleValue, rangeRuleValue);
      return parsedNodeVal >= Math.min(condVal, rangeVal) && parsedNodeVal <= Math.max(condVal, rangeVal);
    case NOT_BETWEEN:
      const {
        parsedNodeVal: parsedNodeValue,
        condVal: condValue,
        rangeVal: rangeValue,
      } = parseBetweenCondValues(nodeValue, ruleValue, rangeRuleValue);
      return parsedNodeValue < Math.min(condValue, rangeValue) || parsedNodeValue > Math.max(condValue, rangeValue);
    case 'is-true':
      if (typeof nodeValue === 'boolean') {
        return nodeValue;
      }
      break;
    case 'is-false':
      if (typeof nodeValue === 'boolean') {
        return !nodeValue;
      }
      break;
  }

  return false;
};

export const getMappedValue = (value = 0, inMin, inMax, outMin, outMax) => {
  if (value === inMin && value === inMax) {
    return outMax;
  }

  // converting a given value to an integer
  let intValue = 0;
  if (neo4j.isInt(value)) {
    intValue = value.subtract(inMin).toNumber();
  } else {
    intValue = value - inMin;
  }

  const mappedValue = (intValue * (outMax - outMin)) / (inMax - inMin) + outMin;
  const outValue = outMin < outMax ? clamp(mappedValue, outMin, outMax) : clamp(mappedValue, outMax, outMin);
  return Number.isNaN(outValue) ? undefined : outValue;
};

const getRuleMatch = (rule) => ({
  size: rule.size,
  color: rule.color,
  textSize: rule.textSize,
  textAlign: rule.textAlign,
  captions: rule.captions,
  applyRule: false,
});

const checkRangeRuleCondition = (rule, propertyValue) => {
  const {
    applySize,
    applyColor,
    minColor,
    midColor,
    maxColor,
    minSizeValue = 0,
    maxSizeValue = 0,
    minColorValue = 0,
    midColorValue,
    maxColorValue = 0,
    minSize = 1,
    maxSize = 1,
    basedOn,
    isTimeZoneConvertEnabled,
    selectedTimeZone,
  } = rule;

  const result = getRuleMatch(rule);

  const ruleBase = getBaseTypeSuffixFromRuleBase(basedOn);
  if (propertyValue === undefined || propertyValue === null) {
    return result;
  }

  let value;
  let minColorVal;
  let maxColorVal;
  let midColorVal;
  let minSizeVal;
  let maxSizeVal;
  if (isDateTimeType(ruleBase)) {
    const isSameType = isComparablePropertyType(propertyValue.type, ruleBase);
    if (!isSameType) {
      return result;
    }

    value = parseStringToNumber(propertyValue.value, ruleBase, selectedTimeZone, isTimeZoneConvertEnabled);
    minColorVal = parseStringToNumber(minColorValue, ruleBase, selectedTimeZone, isTimeZoneConvertEnabled);
    maxColorVal = parseStringToNumber(maxColorValue, ruleBase, selectedTimeZone, isTimeZoneConvertEnabled);
    midColorVal = parseStringToNumber(midColorValue, ruleBase, selectedTimeZone, isTimeZoneConvertEnabled);
    minSizeVal = parseStringToNumber(minSizeValue, ruleBase, selectedTimeZone, isTimeZoneConvertEnabled);
    maxSizeVal = parseStringToNumber(maxSizeValue, ruleBase, selectedTimeZone, isTimeZoneConvertEnabled);
  } else {
    value = neo4j.isInt(propertyValue) ? neo4j.integer.toNumber(propertyValue) : parseFloat(propertyValue);
    minColorVal = parseFloat(minColorValue);
    maxColorVal = parseFloat(maxColorValue);
    midColorVal = parseFloat(midColorValue);
    minSizeVal = parseFloat(minSizeValue);
    maxSizeVal = parseFloat(maxSizeValue);
  }

  if (applySize) {
    result.size = getMappedValue(value, minSizeVal, maxSizeVal, minSize, maxSize);
    result.applyRule = true;
  }
  if (minColor && maxColor && applyColor) {
    const colorRange = [minColor, midColor, maxColor].map((color) => (validColor(color) ? color : '#CCCCCC'));
    const valueRange = [minColorVal || 0, midColorVal || 0, maxColorVal || 0];
    if (midColorVal === undefined || midColorVal === '' || isNaN(midColorVal)) {
      colorRange.splice(1, 1);
      valueRange.splice(1, 1);
    }
    if (minColorVal === maxColorVal) {
      result.color = value < minColorVal ? minColor : maxColor;
    } else {
      const ruleColorScale = colorScale(colorRange).domain(valueRange);
      result.color = ruleColorScale(value).hex();
    }
    result.applyRule = true;
  }
  return result;
};

const checkColorByValueCondition = (rule, propertyValue, ruleBase) => {
  const result = getRuleMatch(rule);

  if (!propertyValue) return result;
  const { value } = propertyValue;
  const { selectedTimeZone, isTimeZoneConvertEnabled } = rule;

  if (!validTypeForUniqueValues.includes(ruleBase) || !value) {
    return result;
  }

  let valuesMapper = rule.valuesMapper;
  if (isZonedType(ruleBase) && isTimeZoneConvertEnabled) {
    valuesMapper = valuesMapper.map((valueMap, index) => {
      return {
        ...valueMap,
        value: setTimezoneShiftToForString(valueMap.value, ruleBase, selectedTimeZone) || valueMap.value,
      };
    });
  }

  let colorMappingMatch = null;
  if (isNumber(value)) {
    colorMappingMatch = valuesMapper.find((v) => typeof v.value === 'number' && value === v.value);
  } else {
    colorMappingMatch = valuesMapper.find((v) => {
      if (typeof value === 'boolean' && typeof v.value === 'boolean') {
        return v.value === value;
      } else if (isDateTimeType(ruleBase)) {
        return (
          checkDatetimeRuleCondition(
            propertyValue,
            ruleBase,
            EQUALS,
            v.value,
            null,
            selectedTimeZone,
            isTimeZoneConvertEnabled,
          ) || castValue(v.value, value) === value
        );
      } else {
        return castValue(v.value, value) === value;
      }
    });
  }
  const hasMatchingColor = colorMappingMatch && colorMappingMatch.color;
  if (hasMatchingColor) {
    result.applyRule = true;
    result.color = colorMappingMatch.color;
  }
  return result;
};

const checkOtherRuleCondition = (condition, ruleValue, labels = [], properties = {}) => {
  switch (condition) {
    case 'has-label':
      return labels.includes(ruleValue);
    case 'does-not-have-label':
      return !labels.includes(ruleValue);
    case 'has-property':
      return properties[ruleValue] !== undefined && properties[ruleValue] !== null;
    case 'does-not-have-property':
      return properties[ruleValue] === undefined || properties[ruleValue] === null;
    default:
      return false;
  }
};

const findRule = (rules, item, labels, filerFunc) => {
  for (const rule of rules.filter(filerFunc)) {
    const { basedOn, type, condition, conditionValue, rangeValue, selectedTimeZone, isTimeZoneConvertEnabled } = rule;
    const ruleBase = getBaseTypeSuffixFromRuleBase(basedOn);
    const title = getTitleFromRuleBase(basedOn);
    let propertyValue;

    let ruleMatch = getRuleMatch(rule);

    switch (type) {
      case 'range':
        propertyValue = isDateTimeType(ruleBase) ? item.mappedProperties[title] : item.properties[title];
        ruleMatch = checkRangeRuleCondition(rule, propertyValue);
        break;
      case 'single':
        propertyValue = isDateTimeType(ruleBase) ? item.mappedProperties[title] : item.properties[title];
        if (ruleBase === 'other') {
          ruleMatch.applyRule = checkOtherRuleCondition(condition, conditionValue, labels, item.properties);
        } else if (isDateTimeType(ruleBase)) {
          ruleMatch.applyRule = checkDatetimeRuleCondition(
            propertyValue,
            ruleBase,
            condition,
            conditionValue,
            rangeValue,
            selectedTimeZone,
            isTimeZoneConvertEnabled,
          );
        } else {
          ruleMatch.applyRule = checkSingleRuleCondition(condition, conditionValue, propertyValue, rangeValue);
        }
        break;
      case 'unique-values':
        propertyValue = item.mappedProperties[title];
        ruleMatch = checkColorByValueCondition(rule, propertyValue, ruleBase);
        break;
    }
    if (ruleMatch.applyRule) {
      return ruleMatch;
    }
  }
};

export const getRuleBasedSize = (rules = [], item, labels = []) => {
  const ruleMatch = isEmpty(item) ? null : findRule(rules, item, labels, (r) => r.applySize);
  return ruleMatch?.size;
};

export const getRuleBasedColor = (rules = [], item, labels = []) => {
  const ruleMatch = isEmpty(item) ? null : findRule(rules, item, labels, (r) => r.applyColor);
  return ruleMatch?.color;
};

export const getRuleBasedTextSize = (rules = [], item, labels = []) => {
  const ruleMatch = isEmpty(item) ? null : findRule(rules, item, labels, (r) => r.applyCaption);
  return ruleMatch?.textSize;
};

export const getRuleBasedTextAlign = (rules = [], item, labels = []) => {
  const ruleMatch = isEmpty(item) ? null : findRule(rules, item, labels, (r) => r.applyCaption);
  return ruleMatch?.textAlign;
};

export const getRuleBasedCaptions = (rules = [], item, labels = []) => {
  const ruleMatch = isEmpty(item) ? null : findRule(rules, item, labels, (r) => r.applyCaption);
  return ruleMatch?.captions;
};
