import type { QueryEditorHistoryEntry } from './generated-editor-history-api';
import { generatedEditorHistoryApi } from './generated-editor-history-api';

const transformResponse = <T extends QueryEditorHistoryEntry>(response: T[]) => {
  return response.map((item) => {
    return { ...item, savedId: item.saved?.id ?? null };
  });
};

export const editorHistoryApi = generatedEditorHistoryApi.enhanceEndpoints({
  addTagTypes: ['EditorHistory'],
  endpoints: {
    getEditorHistoryEntries: {
      providesTags: (result, _, __) => {
        return result
          ? [...result.map(({ id }) => ({ type: 'EditorHistory' as const, id })), 'EditorHistory']
          : ['EditorHistory'];
      },
      transformResponse,
    },
    updateEditorHistoryEntries: {
      onQueryStarted: async ({ updateQueryEditorHistoryEntriesBody }, { dispatch, queryFulfilled }) => {
        const result = dispatch(
          editorHistoryApi.util.updateQueryData('getEditorHistoryEntries', { limit: 0 }, (draft) => {
            updateQueryEditorHistoryEntriesBody.forEach((entry) => {
              const index = draft.findIndex((d) => d.id === entry.id);
              const updatedEntry = {
                ...entry,
                saved: entry.savedId !== null ? { id: entry.savedId } : null,
              };

              if (index !== -1) {
                draft[index] = updatedEntry;
              } else {
                draft.push(updatedEntry);
              }
            });
          }),
        );

        try {
          await queryFulfilled;
          dispatch(editorHistoryApi.util.invalidateTags(['EditorHistory']));
        } catch {
          result.undo();
        }
      },
    },
    deleteEditorHistoryEntriesById: {
      onQueryStarted: async ({ deleteQueryEditorHistoryEntriesByIdBody }, { dispatch, queryFulfilled }) => {
        const result = dispatch(
          editorHistoryApi.util.updateQueryData('getEditorHistoryEntries', { limit: 0 }, (draft) => {
            deleteQueryEditorHistoryEntriesByIdBody.ids.forEach((id) => {
              const index = draft.findIndex((d) => d.id === id);
              if (index !== -1) {
                draft.splice(index, 1);
              }
            });
          }),
        );

        try {
          await queryFulfilled;
          dispatch(editorHistoryApi.util.invalidateTags(['EditorHistory']));
        } catch {
          result.undo();
        }
      },
    },
    deleteEditorHistoryEntriesOlderThanTimestamp: {
      onQueryStarted: async ({ timestamp }, { dispatch, queryFulfilled }) => {
        const result = dispatch(
          editorHistoryApi.util.updateQueryData('getEditorHistoryEntries', { limit: 0 }, (draft) => {
            const draftCopy = [...draft];
            draftCopy.forEach((dc) => {
              if (dc.timestamp < timestamp) {
                const index = draft.findIndex((d) => d.id === dc.id);
                if (index !== -1) {
                  draft.splice(index, 1);
                }
              }
            });
          }),
        );

        try {
          await queryFulfilled;
          dispatch(editorHistoryApi.util.invalidateTags(['EditorHistory']));
        } catch {
          result.undo();
        }
      },
    },
  },
});

export const {
  useGetEditorHistoryEntriesQuery,
  useDeleteEditorHistoryEntriesByIdMutation,
  useDeleteEditorHistoryEntriesOlderThanTimestampMutation,
  useUpdateEditorHistoryEntriesMutation,
} = editorHistoryApi;
