import React from 'react';

export const RolesIconOutline = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    fill="none"
    strokeWidth="1.5"
    stroke="currentColor"
    aria-hidden="true"
    className="ndl-icon-svg"
    {...props}
  >
    <path
      id="Vector"
      d="M12.5914 12.1667C12.2563 11.5015 11.7431 10.9426 11.109 10.552C10.4749 10.1614 9.74477 9.95459 9.00003 9.95459C8.25528 9.95459 7.52517 10.1614 6.89106 10.552C6.25695 10.9426 5.74376 11.5015 5.40869 12.1667M16.5 7.62467C16.5003 9.75176 15.7472 11.8102 14.3743 13.4349C13.0013 15.0596 11.0973 16.1455 9 16.5C6.90266 16.1455 4.99867 15.0596 3.62574 13.4349C2.25282 11.8102 1.49971 9.75176 1.5 7.62467V2.5C1.5 2.23478 1.60536 1.98043 1.79289 1.79289C1.98043 1.60536 2.23478 1.5 2.5 1.5H15.5C15.7652 1.5 16.0196 1.60536 16.2071 1.79289C16.3946 1.98043 16.5 2.23478 16.5 2.5V7.62467ZM6.75 6.75C6.75 7.34674 6.98705 7.91903 7.40901 8.34099C7.83097 8.76295 8.40326 9 9 9C9.59674 9 10.169 8.76295 10.591 8.34099C11.0129 7.91903 11.25 7.34674 11.25 6.75C11.25 6.15326 11.0129 5.58097 10.591 5.15901C10.169 4.73705 9.59674 4.5 9 4.5C8.40326 4.5 7.83097 4.73705 7.40901 5.15901C6.98705 5.58097 6.75 6.15326 6.75 6.75Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
