import type { CLOUD_PROVIDER, Instance, TIER, TierConfigs } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';

import { getProductFromTier, getRegionsForTier, getTiers, regionNameWithInstanceCount } from '../helpers/utils';

export const useTierAndRegionOptions = (
  instances: Instance[],
  tier: TIER,
  cloudProvider: CLOUD_PROVIDER,
  tierConfigs: TierConfigs,
) => {
  const availableTiers = getTiers(tierConfigs);

  const tierOptions = availableTiers.map((t) => {
    const product = getProductFromTier(t);
    return {
      key: t.toString(),
      value: t,
      label: product,
    };
  });

  const availableRegions = getRegionsForTier(tierConfigs, tier, cloudProvider);
  const regionOptions = availableRegions.map((r) => {
    const dbsInRegion = isNotNullish(instances)
      ? instances.filter((db) => db.region === r.name && db.tier === tier)
      : [];
    return {
      key: r.name,
      label: regionNameWithInstanceCount(r.friendly, dbsInRegion),
      value: r.name,
      databases: dbsInRegion,
    };
  });

  return { tierOptions, regionOptions };
};
