import { Banner, Button, Dialog, LoadingSpinner, Select, TextInput, TextLink, Typography } from '@neo4j-ndl/react';
import { PlusIconOutline, TrashIconOutline } from '@neo4j-ndl/react/icons';
import type { Instance, Project, TrafficConfig } from '@nx/state';
import { TIER, TRAFFIC_ENABLEMENT, consoleApi, getApiError } from '@nx/state';
import { isNonEmptyString, isNotNullish, isNullish } from '@nx/stdlib';
import { CopyTextInput } from '@nx/ui';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { ComponentProps, ReactNode } from 'react';
import { useMemo, useState } from 'react';
import * as yup from 'yup';

import type { Validation } from '../../utils/validation';
import { validateYup } from '../../utils/validation';
import { getProductFromTier, getRegionsForTier } from '../entities/helpers';
import { ConfirmCheckbox, ConnectionRequestAcceptor, PrivateConnection, VpnMessage } from './shared';
import type { RegionOption, TierOption } from './utils';

type FormData = {
  tier?: TIER;
  region?: string;
  subscriptionIds: string[];
  privateTraffic: TRAFFIC_ENABLEMENT;
  publicTraffic: TRAFFIC_ENABLEMENT;
  endpointIds: string[];
  endpointIdField: string;
};

const schema = yup.object({
  tier: yup.string().oneOf(Object.values(TIER)).required().label('Instance Type'),
  region: yup.string().required().label('Region'),
  subscriptionIds: yup.array().min(1).required().of(yup.string().required().uuid().label('Azure Subscription ID')),
  privateTraffic: yup.string().oneOf(Object.values(TRAFFIC_ENABLEMENT)).required(),
  publicTraffic: yup.string().oneOf(Object.values(TRAFFIC_ENABLEMENT)).required(),
  endpointIds: yup.array().required().of(yup.string().min(1).label('Azure Endpoint ID')),
});

const validate = (data: FormData): Validation<FormData> | null => {
  return validateYup(schema, data);
};

const defaults = (trafficConfig?: TrafficConfig): FormData => ({
  tier: trafficConfig?.tier,
  region: trafficConfig?.region,
  subscriptionIds: trafficConfig?.azureProperties ? trafficConfig.azureProperties.subscriptionIds : [],
  endpointIds: trafficConfig?.azureProperties ? trafficConfig.azureProperties.endpointIds : [],
  endpointIdField: '',
  privateTraffic: trafficConfig?.privateTraffic ?? TRAFFIC_ENABLEMENT.ENABLED,
  publicTraffic: trafficConfig?.publicTraffic ?? TRAFFIC_ENABLEMENT.ENABLED,
});

type AzureTrafficConfigProps = {
  project: Project;
  trafficConfig?: TrafficConfig;
  existingTrafficConfigs?: TrafficConfig[];
  existingTierRegions?: Partial<Record<TIER, string[]>>;
  onClose: () => void;
  onSuccess?: (trafficConfig: TrafficConfig) => void;
  instances: Instance[];
};

/**
 * This dialog is quite complicated, so to try to simplify things
 * a bit, it has no `open` prop. The consumer should just unmount
 * and remount it. This helps make managing state easier
 * as there is no chance of bleeding state between dialog open/closes
 */
export const AzureTrafficConfigDialog = ({
  project,
  trafficConfig,
  onClose,
  onSuccess,
  instances,
  // Only needed in create mode
  // Used to prepopulate the props
  // when creating
  existingTrafficConfigs = [],
  // Only needed in create mode
  // Used to determine if a tier/region combo
  // is already configured
  existingTierRegions = {},
}: AzureTrafficConfigProps) => {
  const editMode = isNotNullish(trafficConfig);
  const [data, setData] = useState<FormData>(() => defaults(trafficConfig));
  const [validation, setValidation] = useState<Validation<FormData>>({});
  const [step, setStep] = useState(1);
  const [confirm, setConfirm] = useState(false);
  const [enableError, setEnableError] = useState<string | null>(null);
  const [endpointConnectionMessage, setEndpointConnectionMessage] = useState<{
    message: ReactNode;
    messageType: ComponentProps<typeof Banner>['type'];
  } | null>(null);
  const [updateTrafficConfig, updateTrafficConfigRes] = consoleApi.useUpdateTrafficConfigMutation();
  const affectedInstances = useMemo(() => {
    if (isNotNullish(data.region) && isNotNullish(data.tier) && data.publicTraffic === TRAFFIC_ENABLEMENT.DISABLED) {
      return instances.filter((instance) => instance.region === data.region && instance.tier === data.tier);
    }
    return [];
  }, [data.publicTraffic, data.region, data.tier, instances]);

  const handleClose = () => {
    onClose();
  };

  const handleNextStep = () => setStep(Math.min(step + 1, 4));
  const handlePreviousStep = () => setStep(Math.max(step - 1, 1));

  const handleFinishLater = () => {
    onClose();
  };

  const handleTierChange = (option: TierOption) => {
    setData((prev) => ({ ...prev, tier: option.value, region: undefined }));
  };
  const handleRegionChange = (option: RegionOption) => {
    const newRegion = option.value;
    setData((prev) => {
      const existingTrafficConfig = existingTrafficConfigs.find((c) => c.tier === prev.tier && c.region === newRegion);
      // Existing traffic config may be undefined
      // if for example in dev environments you use the same
      // isolation id for enterprisedb and enterpriseds
      const existingEndpointIds = existingTrafficConfig?.azureProperties?.endpointIds ?? [];
      const existingSubscriptionIds = existingTrafficConfig?.azureProperties?.subscriptionIds ?? [];
      return {
        ...prev,
        region: newRegion,
        endpointIds: existingEndpointIds,
        subscriptionIds: existingSubscriptionIds,
      };
    });
  };

  const handleSubscriptionIdChange = (value: string, index: number) => {
    const newValue = value;
    setData((prev) => {
      const newSubscriptionIds = [...prev.subscriptionIds];
      newSubscriptionIds[index] = newValue;
      return { ...prev, subscriptionIds: newSubscriptionIds };
    });
  };
  const handleDeleteSubscriptionId = (index: number) => {
    setData((prev) => ({
      ...prev,
      subscriptionIds: data.subscriptionIds.filter((_, i) => i !== index),
    }));
  };
  const handleAddSubscriptionId = () => {
    setData((prev) => ({ ...prev, subscriptionIds: [...prev.subscriptionIds, ''] }));
  };

  const handleSubmitWithData = (toSubmit: FormData, { close = false, next = true } = {}) => {
    const errors = validate(toSubmit);
    if (errors) {
      setValidation(errors);
      return;
    }
    setEnableError(null);
    setValidation({});
    if (isNullish(toSubmit.tier) || isNullish(toSubmit.region)) {
      return;
    }

    updateTrafficConfig({
      projectId: project.id,
      tier: toSubmit.tier,
      region: toSubmit.region,
      privateTraffic: toSubmit.privateTraffic,
      publicTraffic: toSubmit.publicTraffic,
      azureProperties: {
        endpointIds: toSubmit.endpointIds,
        subscriptionIds: toSubmit.subscriptionIds,
      },
    })
      .unwrap()
      .then((res) => {
        if (onSuccess) {
          onSuccess(res);
        }
        if (next) {
          handleNextStep();
        }
        if (close) {
          handleClose();
        }
      })
      .catch((err: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(err);
        if (error.code === 422) {
          setEnableError(error.message ?? 'Failed to update network security configuration');
        } else {
          setEnableError('Failed to update network security configuration');
        }
      });
  };

  const handleEndpointIdFieldChange = (value: string) => {
    setEndpointConnectionMessage(null);
    const newValue = value;
    setData((prev) => {
      return { ...prev, endpointIdField: newValue };
    });
  };
  const handleAddEndpointId = () => {
    setData((prev) => {
      const endpointConnection = trafficConfig?.status.azureStatus?.privateEndpointConnections.find(
        (conn) => conn.privateEndpointId === prev.endpointIdField,
      );

      if (!endpointConnection) {
        setEndpointConnectionMessage({
          message:
            'Endpoint connection request could not be found. Please follow the instructions on the previous step to create an endpoint connection request, and/or check that your endpoint id is correct.',
          messageType: 'danger',
        });
        return prev;
      }

      if (prev.endpointIds.includes(endpointConnection.privateEndpointId)) {
        setEndpointConnectionMessage({
          message: (
            <>
              The endpoint connection accept request for <b>{endpointConnection.privateEndpointId}</b> has already been
              sent. The setup can take a couple of minutes.
            </>
          ),
          messageType: 'info',
        });
        return prev;
      }

      setEndpointConnectionMessage({
        message: (
          <>
            The endpoint connection accept request for <b>{endpointConnection.privateEndpointId}</b> has been sent. The
            setup can take a couple of minutes.
          </>
        ),
        messageType: 'success',
      });

      const newData = {
        ...prev,
        endpointIds: [...prev.endpointIds, prev.endpointIdField],
        endpointIdField: '',
      };

      handleSubmitWithData(newData, { next: false });

      return newData;
    });
  };

  const handleConfirmChange = (checked: boolean) => {
    setConfirm(checked);
  };

  const handlePublicTrafficDisabledChange = (checked: boolean) => {
    const newValue = !checked;
    setData((prev) => ({
      ...prev,
      publicTraffic: newValue ? TRAFFIC_ENABLEMENT.ENABLED : TRAFFIC_ENABLEMENT.DISABLED,
    }));
  };

  const handleSubmit = (close = false) => {
    handleSubmitWithData(data, { close });
  };

  const handleSubmitAndClose = () => handleSubmit(true);

  const regionOptions = useMemo(() => {
    if (isNullish(data.tier)) {
      return [];
    }
    const alreadyConfiguredRegions = existingTierRegions[data.tier] ?? [];
    const regionsToConfigure = existingTrafficConfigs.filter((tc) => tc.tier === data.tier).map((tc) => tc.region);
    const regions = getRegionsForTier(data.tier, project.tierConfigs).filter(
      (r) => !alreadyConfiguredRegions.includes(r.name) && regionsToConfigure.includes(r.name),
    );
    return regions.map((r) => ({
      key: r.name,
      label: r.friendly,
      value: r.name,
    }));
  }, [data.tier, existingTierRegions, existingTrafficConfigs, project.tierConfigs]);

  const tierOptions = useMemo(() => {
    const tiers = Array.from(new Set(existingTrafficConfigs.map((config) => config.tier)));
    return tiers
      .filter((t) => [TIER.ENTERPRISE, TIER.AURA_DSE].includes(t))
      .map((tier) => {
        return {
          key: tier,
          value: tier,
          label: getProductFromTier(tier),
        };
      });
  }, [existingTrafficConfigs]);

  const privateLinkServiceName = trafficConfig?.status.azureStatus?.privateLinkServiceName;
  const endpointConnections = trafficConfig?.status.azureStatus?.privateEndpointConnections ?? [];
  const configCreating = !trafficConfig || !isNonEmptyString(privateLinkServiceName);

  // Always render at least one subscription id field
  const subscriptionIds = data.subscriptionIds.length > 0 ? data.subscriptionIds : [''];

  return (
    <>
      <Dialog.Content className="flex flex-col gap-4">
        <p>Step {step} of 4</p>
        {step === 1 && (
          <>
            <Select
              size="medium"
              type="select"
              label="Instance Type"
              selectProps={{
                options: tierOptions,
                value: tierOptions.find((t) => t.value === data.tier),
                onChange: (value) => value && handleTierChange(value),
                isDisabled: editMode,
              }}
              errorText={validation.tier?.message}
              htmlAttributes={{
                'data-testid': 'azure-dialog-select-product',
              }}
            />

            {regionOptions.length === 0 && isNotNullish(data.tier) && (
              <Banner
                type="warning"
                title="All regions configured"
                description={`All ${getProductFromTier(data.tier)} regions have been configured.`}
                usage="inline"
              />
            )}
            {regionOptions.length > 0 && (
              <Select
                size="medium"
                type="select"
                label="Region"
                helpText="Azure PrivateLink applies to all instances in the region."
                selectProps={{
                  options: regionOptions,
                  value: regionOptions.find((r) => r.value === data.region),
                  onChange: (value) => value && handleRegionChange(value),
                  isDisabled: editMode,
                }}
                errorText={validation.region?.message}
                htmlAttributes={{
                  'data-testid': 'azure-dialog-select-region',
                }}
              />
            )}

            {isNotNullish(data.region) && (
              <>
                {subscriptionIds.map((subscriptionId, index) => {
                  // Disable the first N items where N is the number of items already existing
                  // in the project ids config
                  const disabled = (trafficConfig?.azureProperties?.subscriptionIds ?? []).length > index;
                  const validationKey = `subscriptionIds[${index}]`;
                  // Array validation creates keys not in FormData.
                  // To make typescript happy we transform the object to a map with string keys
                  const validationItem = new Map(Object.entries(validation)).get(validationKey);
                  return (
                    <TextInput
                      isFluid
                      key={index}
                      label={index === 0 ? "Target Azure Subscription ID's" : ''}
                      value={subscriptionId}
                      onChange={(v) => handleSubscriptionIdChange(v.target.value, index)}
                      errorText={validationItem?.message}
                      isDisabled={disabled}
                      rightElement={
                        index > 0 && !disabled ? (
                          <TrashIconOutline
                            onClick={() => handleDeleteSubscriptionId(index)}
                            data-testid={`delete-subscription-id-button-${index + 1}`}
                          />
                        ) : undefined
                      }
                      htmlAttributes={{
                        'data-testid': `azure-dialog-subscription-id`,
                        'aria-label': 'Target Azure Subscription ID',
                        placeholder: 'Enter a Azure Subscription ID...',
                      }}
                    />
                  );
                })}
                <div>
                  <Button
                    fill="outlined"
                    onClick={handleAddSubscriptionId}
                    htmlAttributes={{
                      'data-testid': 'azure-dialog-add-subscription-id',
                    }}
                  >
                    <PlusIconOutline className="h-full" />
                    Add subscription ID
                  </Button>
                </div>
                {isNotNullish(enableError) && <Banner description={enableError} type="danger" usage="inline" />}
              </>
            )}
          </>
        )}

        {step === 2 && (
          <>
            {configCreating && (
              <Banner
                title={
                  <div className="flex items-center gap-2">
                    <LoadingSpinner size="small" />
                    Configuring...
                  </div>
                }
                description="PrivateLink is being configured..."
                usage="inline"
              />
            )}
            {!configCreating && (
              <>
                <Banner hasIcon type="success" title="Accepted" usage="inline" />
                <CopyTextInput label="PrivateLink Service Name" value={privateLinkServiceName} isPortaled={false} />
              </>
            )}

            <div className="mt-2">
              <h6>
                <span className="font-normal">Create Private Endpoint</span>
              </h6>
              <ol className="console-network-instructions ml-8 mt-3 list-decimal">
                <li>
                  Log in to the{' '}
                  <TextLink href="https://portal.azure.com/" isExternalLink>
                    Azure Portal
                  </TextLink>
                  .
                </li>
                <li>
                  Search for <i>Private endpoints</i> in the global search bar, and click on the{' '}
                  <b>Private endpoints</b> item in the dropdown.
                </li>

                <li>
                  With <b>Private endpoints</b> selected in the side navigation, click <b>+ Create</b>.
                </li>

                <li>
                  Set <b>Subscription</b> to the subscription you entered on the previous step for{' '}
                  <b>Target Subscription ID</b>.
                </li>

                <li>
                  Set <b>Resource group</b> to an appropriate resource group, or create a new one.
                </li>

                <li>
                  Set <b>Name</b> and <b>Network Interface Name</b> to appropriate names.
                </li>
                <li>
                  Set <b>Region</b> to the same region as the virtual network you want to connect to your Neo4j
                  instances from.
                </li>
                <li>
                  Click <b>Next</b>.
                </li>
                <li>
                  Set <b>Connection Method</b> to <b>Connect to an Azure resource by resource ID or alias</b>.
                </li>

                {configCreating && (
                  <li>
                    Once PrivateLink is done configuring, set <b>Resource ID or alias</b> to the PrivateLink Service
                    Name.
                  </li>
                )}
                {!configCreating && (
                  <li>
                    Set <b>Resource ID or alias</b> to <i>{privateLinkServiceName}</i>.
                  </li>
                )}

                <li>
                  Click <b>Next</b>.
                </li>
                <li>
                  Set <b>Virtual Network</b> and <b>Subnet</b> to the virtual network and subnet you want to connect to
                  your instances from.
                </li>
                <li>
                  Click <b>Next</b>.
                </li>

                <li>
                  Leave the DNS tab as the defaults and click <b>Next</b>.
                </li>
                <li>
                  After the deployment is complete, go back to the <b>Private endpoints</b> page.
                </li>
                <li>
                  Save the <b>Private IP</b> of your newly created endpoint for later.
                </li>
              </ol>
              <Typography variant="body-medium" className="mt-4" as="div">
                *{' '}
                <TextLink
                  href="https://learn.microsoft.com/en-us/azure/private-link/create-private-endpoint-portal"
                  isExternalLink
                >
                  <i>Azure Private Link documentation</i>
                </TextLink>
              </Typography>
            </div>
          </>
        )}
        {step === 3 && (
          <div className="flex flex-col gap-8">
            <ConnectionRequestAcceptor
              value={data.endpointIdField}
              onChange={handleEndpointIdFieldChange}
              onAccept={handleAddEndpointId}
              inputErrorText={validation.endpointIdField?.message}
              acceptMessage={endpointConnectionMessage}
              loading={updateTrafficConfigRes.isLoading}
              connectionRequests={endpointConnections.map((c) => ({
                id: c.privateEndpointId,
                state: c.state,
              }))}
            />
            <div>
              <h6>
                <span className="font-normal">Enable Private DNS in Azure console</span>
              </h6>
              <ol className="console-network-instructions ml-8 mt-3 list-decimal">
                <li>
                  Log in to the{' '}
                  <TextLink href="https://portal.azure.com/" isExternalLink>
                    Azure Portal
                  </TextLink>
                  .
                </li>
                <li>
                  Search for <i>Private DNS zones</i> in the global search bar, and click on the{' '}
                  <b>Private DNS zones</b> item in the dropdown.
                </li>
                <li>
                  Create a Private DNS Zone:
                  <ul className="ml-6 list-[lower-alpha]">
                    <li>
                      Click <b>+ Create</b>.
                    </li>
                    <li>
                      Set <b>Subscription</b> to the same subscription you used for the Private Endpoint.
                    </li>
                    <li>
                      Set <b>Resource group</b> to the same resource group you used for the Private Endpoint.
                    </li>
                    <li>
                      Set <b>Name</b> to <i>{trafficConfig?.status.dnsDomain}</i>.
                    </li>
                    <li>
                      Click <b>Next</b>.
                    </li>
                    <li>
                      Click <b>Review Create</b>.
                    </li>
                    <li>
                      Click <b>Create</b>.
                    </li>
                  </ul>
                </li>
                <li>
                  Once the deployment is complete, click <b>Go to resource</b>.
                </li>

                <li>
                  Create a Private DNS record set:
                  <ul className="ml-6 list-[lower-alpha]">
                    <li>
                      Click <b>+ Record set</b>.
                    </li>
                    <li>
                      Set <b>Name</b> to &quot;*&quot; (an asterisk).
                    </li>
                    <li>
                      Set <b>Type</b> to <i>A</i>.
                    </li>
                    <li>
                      Set <b>IP Address</b> to the Private IP of the Private endpoint you saved earlier.
                    </li>
                    <li>
                      Click <b>OK</b>.
                    </li>
                  </ul>
                </li>
                <li>
                  Select <b>Virtual network links</b> from the side navigation.
                </li>
                <li>
                  Link the Private Endpoint to your virtual network:
                  <ul className="ml-6 list-[lower-alpha]">
                    <li>
                      Click <b>+ Add</b>.
                    </li>
                    <li>
                      Set <b>Name</b> to an appropriate name.
                    </li>

                    <li>
                      Set <b>Subscription</b> to the same subscription you used for the Private Endpoint.
                    </li>
                    <li>
                      Set <b>Virtual network</b> to the virtual network you want to connect to your Neo4j instances
                      from.
                    </li>
                    <li>
                      Click <b>OK</b>.
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
            <div className="mt-4">
              The configuration should now be complete. Once you have an Aura instance created you should be able to
              test the connection using{' '}
              <TextLink
                href="https://support.neo4j.com/s/article/13174783967507-How-To-Test-Connectivity-Through-The-Private-Endpoint"
                isExternalLink
              >
                this guide.
              </TextLink>
            </div>
            <Typography variant="body-medium" className="mt-4" as="div">
              *{' '}
              <TextLink href="https://learn.microsoft.com/en-us/azure/dns/private-dns-getstarted-portal" isExternalLink>
                <i>Azure Private Link documentation</i>
              </TextLink>
            </Typography>
          </div>
        )}
        {step === 4 && (
          <>
            <PrivateConnection
              publicTraffic={data.publicTraffic}
              dnsDomain={trafficConfig?.status.dnsDomain}
              onPublicTrafficDisabledChange={handlePublicTrafficDisabledChange}
              affectedInstances={affectedInstances}
            />
            <VpnMessage href="https://neo4j.com/docs/aura/platform/security/#_azure_private_endpoints" />
            <ConfirmCheckbox isConfirmed={confirm} onConfirmChange={handleConfirmChange} />
          </>
        )}
      </Dialog.Content>
      <Dialog.Actions className="justify-between">
        <Button
          onClick={handleFinishLater}
          fill="outlined"
          htmlAttributes={{
            'data-testid': 'azure-dialog-later',
          }}
        >
          Finish later
        </Button>
        {step === 1 && !editMode && (
          <Button
            onClick={() => handleSubmit()}
            fill="filled"
            isLoading={updateTrafficConfigRes.isLoading}
            isDisabled={isNullish(data.region) || isNullish(data.tier)}
            htmlAttributes={{
              'data-testid': 'azure-dialog-enable-privatelink',
            }}
          >
            Enable PrivateLink
          </Button>
        )}
        {step === 1 && editMode && (
          <Button
            onClick={() => handleSubmit()}
            isLoading={updateTrafficConfigRes.isLoading}
            fill="filled"
            htmlAttributes={{
              'data-testid': 'azure-dialog-next',
            }}
          >
            Next
          </Button>
        )}
        {step === 2 && (
          <div className="flex gap-2">
            <Button onClick={handlePreviousStep} fill="outlined">
              Back
            </Button>
            <Button
              onClick={handleNextStep}
              fill="filled"
              isDisabled={isNullish(privateLinkServiceName)}
              htmlAttributes={{
                'data-testid': 'azure-dialog-next',
              }}
            >
              Next
            </Button>
          </div>
        )}
        {step === 3 && (
          <div className="flex gap-2">
            <Button onClick={handlePreviousStep} fill="outlined">
              Back
            </Button>
            <Button onClick={handleNextStep} fill="filled" htmlAttributes={{ 'data-testid': 'azure-dialog-next' }}>
              Next
            </Button>
          </div>
        )}
        {step === 4 && (
          <div className="flex gap-2">
            <Button onClick={handlePreviousStep} fill="outlined">
              Back
            </Button>
            <Button
              onClick={handleSubmitAndClose}
              fill="filled"
              isLoading={updateTrafficConfigRes.isLoading}
              isDisabled={!confirm}
              htmlAttributes={{
                'data-testid': 'azure-dialog-save',
              }}
            >
              Save
            </Button>
          </div>
        )}
      </Dialog.Actions>
    </>
  );
};
