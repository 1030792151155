import { Typography } from '@neo4j-ndl/react';
import { isNotNullish, isNullish } from '@nx/stdlib';
import { ClipboardCopier } from '@nx/ui';
import type { ComponentProps, ReactNode } from 'react';

interface Props {
  label: string;
  text?: string | null;
  allowCopyText?: boolean;
}

export const PropertyDisplay = ({ label, text, allowCopyText = false }: Props) => {
  return (
    <div className="flex items-center gap-1">
      <Typography variant="label" className="text-neutral-text-weak">
        {label}:
      </Typography>
      <Typography variant="body-medium" className="text-neutral-text-weaker">
        {text ?? '-'}
      </Typography>
      {allowCopyText && !isNullish(text) && <ClipboardCopier textToCopy={text} ariaLabel="Copy to clipboard" />}
    </div>
  );
};

type SmallPropertyDisplayProps = Partial<ComponentProps<typeof Typography>> & {
  label?: ReactNode;
  text?: ReactNode;
};

export const SmallPropertyDisplay = ({ label, text, ...rest }: SmallPropertyDisplayProps) => {
  return (
    <Typography {...rest} variant="body-small" className="flex items-center gap-1">
      {isNotNullish(label) && <span className="text-neutral-text-weak">{label}:</span>}
      {text ?? '-'}
    </Typography>
  );
};
