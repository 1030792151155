import { createSelector } from '@reduxjs/toolkit';
import { isNil } from 'lodash-es';

import { mapProperties } from '../../services/queries/mappers';
import type { Category } from '../../types/category';
import type { Node } from '../../types/graph';
import type { Prettify } from '../../types/prettify';
import type { NonUndefined } from '../../types/utility';
import { getGdsDataByNodeId, getGdsRules } from '../gds/gds';
import type { Rule } from '../gds/types';
import { getNodes, getVisibleNodes, getVisibleNodesPerCategory } from '../graph/nodes';
import { getCategoriesWithCurrentStyle } from '../styles/styles';
import type { RootState } from '../types';

export const getVisibleNodesWithGdsData = createSelector(
  // (state) => ... used here because of circular deps: https://github.com/reduxjs/reselect/issues/169#issuecomment-386266985
  (state: RootState) => getVisibleNodes(state),
  (state: RootState) => getGdsDataByNodeId(state),
  (visibleNodes, getGdsDataByNodeId) => {
    return visibleNodes.reduce<Node[]>((acc, visibleNode) => {
      if (!isNil(visibleNode)) {
        const gdsData = getGdsDataByNodeId(visibleNode.id);
        const mappedProperties = mapProperties(gdsData);
        const finalNode = {
          ...visibleNode,
          properties: { ...visibleNode.properties, ...gdsData },
          mappedProperties: { ...visibleNode.mappedProperties, ...mappedProperties },
        };
        acc.push(finalNode);
      }
      return acc;
    }, []);
  },
);

export const getNodesWithGdsData = createSelector(
  (state: RootState) => getNodes(state),
  (state: RootState) => getGdsDataByNodeId(state),
  (nodes, getGdsDataByNodeId) => {
    return Object.keys(nodes).reduce<Record<string, Node>>((acc, nodeId) => {
      const gdsData = getGdsDataByNodeId(nodeId);
      const mappedProperties = mapProperties(gdsData);
      const node = nodes[nodeId];
      if (!node) return acc;
      const finalNode = {
        ...node,
        properties: { ...node.properties, ...gdsData },
        mappedProperties: { ...node.mappedProperties, ...mappedProperties },
      };
      acc[nodeId] = finalNode;
      return acc;
    }, {});
  },
);

export const getCategoriesWithCurrentStyleAndGdsRules = createSelector(
  (state: RootState) => getCategoriesWithCurrentStyle(state),
  (state: RootState) => getGdsRules(state),
  (categories, gdsRules) => {
    const finalCategories = categories.map((category) => {
      const gdsProperties = !isNil(gdsRules)
        ? gdsRules.reduce<Prettify<NonUndefined<Rule['property']> & { isGdsData: true }>[]>((acc, gdsRule) => {
            const { selectedCategories, property } = gdsRule;
            if (!isNil(selectedCategories) && selectedCategories?.includes(category.id) && !isNil(property)) {
              const prop = { ...property, isGdsData: true } as const;
              acc.push(prop);
            }
            return acc;
          }, [])
        : [];
      return {
        ...category,
        properties: [...category.properties, ...gdsProperties],
      };
    });
    return finalCategories;
  },
);

export const makeSelectMappedPropertiesWithGdsData = () =>
  createSelector(
    getNodesWithGdsData,
    getVisibleNodesPerCategory,
    (state: RootState, categoryId: Category['id']) => categoryId,
    (state: RootState, categoryId: Category['id'], propertyName: string) => propertyName,
    (nodeInventory, visibleNodesPerCategory, categoryId, propertyName): Node['properties'] => {
      const visibleNodeIds = visibleNodesPerCategory[categoryId];
      if (visibleNodeIds === undefined) return [];

      return visibleNodeIds.reduce<Node['properties']>((properties, nodeId) => {
        const node = nodeInventory[nodeId];
        const value = node?.mappedProperties?.[propertyName];
        if (isNil(value)) return properties;
        properties.push(value);
        return properties;
      }, []);
    },
  );
