import { isNotNullish } from '@nx/stdlib';
import { useEffect, useState } from 'react';

export const useIsScrolled = (elem: HTMLElement | null | undefined) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (!elem) {
        return;
      }
      setIsScrolled(elem.scrollTop > 0);
    };
    if (isNotNullish(elem)) {
      elem.addEventListener('scroll', onScroll);
    }

    return () => {
      if (isNotNullish(elem)) {
        elem.removeEventListener('scroll', onScroll);
      }
    };
  }, [elem]);

  return { isScrolled };
};
