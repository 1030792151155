export type Unit = 'B' | 'KB' | 'MB' | 'GB' | 'TB' | 'KiB' | 'MiB' | 'GiB' | 'TiB';

export class GdsOffering {
  private static SI_UNITS: Record<Unit, number> = {
    B: 1,
    KB: 1000,
    MB: 1000 ** 2,
    GB: 1000 ** 3,
    TB: 1000 ** 4,
    KiB: 1024,
    MiB: 1024 ** 2,
    GiB: 1024 ** 3,
    TiB: 1024 ** 4,
  };

  private static validUnits = ['B', 'KB', 'MB', 'GB', 'TB', 'KiB', 'MiB', 'GiB', 'TiB'];

  private static isValidUnit(unit: string): unit is Unit {
    return GdsOffering.validUnits.includes(unit);
  }

  private static formatter = new Intl.NumberFormat('en', {
    style: 'unit',
    unit: 'gigabyte',
    unitDisplay: 'short',
  });

  static parse(sizeString: string): GdsOffering {
    const regex = /^([\d.,]+)\s?(B|KB|MB|GB|TB|KiB|MiB|GiB|TiB)$/i;
    const match = sizeString.trim().match(regex);

    if (!match) {
      throw new Error(`Invalid size format: ${sizeString}`);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, value, unit] = match;

    if (value === undefined || unit === undefined) {
      throw new Error(`Unable to parse GdsOffering: ${sizeString}`);
    }

    const numericValue = parseFloat(value.replace(/,/g, ''));

    if (!GdsOffering.isValidUnit(unit)) {
      throw new Error(`Invalid unit: ${unit}`);
    }

    return new GdsOffering(numericValue, unit);
  }

  private sizeInBytes: number;

  constructor(size: number, unit: Unit = 'B') {
    if (size < 0) {
      throw new Error('Size cannot be negative.');
    }
    this.sizeInBytes = size * GdsOffering.SI_UNITS[unit];
  }

  to(unit: Unit): number {
    return this.sizeInBytes / GdsOffering.SI_UNITS[unit];
  }

  format(): string {
    const sizeInGB = this.sizeInBytes / 1000 ** 3;
    return GdsOffering.formatter.format(sizeInGB).replace(' ', '');
  }

  valueOf(): number {
    return this.sizeInBytes;
  }

  toString(): string {
    return this.format();
  }

  compareTo(other: GdsOffering): number {
    if (this.sizeInBytes < other.sizeInBytes) {
      return -1;
    }
    if (this.sizeInBytes > other.sizeInBytes) {
      return 1;
    }
    return 0;
  }

  equals(other: GdsOffering): boolean {
    return this.sizeInBytes === other.sizeInBytes;
  }
}
