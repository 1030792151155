import { metricsIntegrationApiVersion } from '../shared/constants';

const promConfigTemplate = (
  metricsPathParam: string | undefined,
  auraApiDomain: string,
  cmiDomain: string,
  jobName?: string,
) => {
  return `  
  - job_name: '${jobName}'
    metrics_path: '/api/${metricsIntegrationApiVersion}/${metricsPathParam}/metrics'
    scheme: 'https'
    static_configs:
      - targets: ['${cmiDomain}']
    oauth2:
        client_id: '<AURA_CLIENT_ID>'
        client_secret: '<AURA_CLIENT_SECRET>'
        token_url: 'https://${auraApiDomain}/oauth/token'
`;
};

export default promConfigTemplate;
