import { type PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { difference } from 'lodash-es';

import { fetchDataThunk } from '../core/search-core.thunks';
import { NAME } from './search-requests.const';
import { clearRequestsThunk } from './search-requests.thunks';
import type { RequestId, SearchRequestsState } from './search-requests.types';

const initialState: SearchRequestsState = {
  requests: [],
};

const asyncThunks = [fetchDataThunk] as const;

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    createRequest: (state, action: PayloadAction<RequestId>) => {
      state.requests.push(action.payload);
    },
    createRequests: (state, action: PayloadAction<RequestId[]>) => {
      state.requests.concat(action.payload);
    },
    clearRequests: (state, action: PayloadAction<RequestId[]>) => {
      state.requests = difference(state.requests, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clearRequestsThunk.fulfilled, (state, action) => {
        state.requests = difference(state.requests, action.payload.requestIds);
      })
      .addMatcher(isAnyOf(...asyncThunks.map((thunk) => thunk.fulfilled)), (state, action) => {
        state.requests = difference(state.requests, [action.meta.arg.requestId]);
      })
      .addMatcher(isAnyOf(...asyncThunks.map((thunk) => thunk.rejected)), (state, action) => {
        state.requests = difference(state.requests, [action.meta.arg.requestId]);
      });
  },
});

export const { createRequest, createRequests, clearRequests } = slice.actions;
export default slice.reducer;
