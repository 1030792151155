import { calculateDefaultNodeColors } from '@nx/word-color';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { type RootState } from '../../store';
import { updateDataSummary } from '../data-summary/data-summary-slice';

export const DEFAULT_NVL_NODE_RADIUS = 25;
export const DEFAULT_REL_WIDTH = 1;

type GraphStylingState = {
  nodeStyles: Record<string, Partial<NodeStyling>>;
  relStyles: Record<string, Partial<RelationStyling>>;
  stylingPriorityOrder: string[];
};
export const GRAPH_STYLE_PERSISTED_KEYS = ['nodeStyles', 'relStyles', 'stylingPriorityOrder'];

export type CaptionOption =
  | {
      type: 'id';
      captionSize?: number;
    }
  | {
      type: 'type';
      captionSize?: number;
    }
  | {
      type: 'property';
      captionKey: string;
      captionSize?: number;
    };

export type NodeStyling = {
  color: string;
  size: number;
  captions: CaptionOption[];
  priority: number;
};

export type RelationStyling = {
  color: string;
  captions: CaptionOption[];
  width: number;
};

const initialState: GraphStylingState = { nodeStyles: {}, relStyles: {}, stylingPriorityOrder: [] };

export type GraphStyling = {
  node: Record<string, Partial<NodeStyling>>;
  relationship: Record<string, Partial<RelationStyling>>;
  stylingPrecedence: string[];
};

export const selectNvlStyling: (state: RootState) => GraphStyling = createSelector(
  (state: RootState) => state.graphStyling,
  ({ nodeStyles, relStyles, stylingPriorityOrder }: GraphStylingState) => {
    const nvlNodeStyles: Record<string, Partial<NodeStyling>> = {};
    Object.keys(nodeStyles).forEach((key) => {
      const colors = calculateDefaultNodeColors(key);
      nvlNodeStyles[key] = {
        color: colors.backgroundColor,
        size: DEFAULT_NVL_NODE_RADIUS,
        ...nodeStyles[key],
        priority: stylingPriorityOrder.length - 1 - stylingPriorityOrder.indexOf(key),
      };
    });

    const nvlRelStyles: Record<string, Partial<RelationStyling>> = {};

    Object.keys(relStyles).forEach((key) => {
      nvlRelStyles[key] = {
        width: DEFAULT_REL_WIDTH,
        ...relStyles[key],
      };
    });

    return {
      node: nvlNodeStyles,
      relationship: nvlRelStyles,
      stylingPrecedence: stylingPriorityOrder,
    };
  },
);

const stylingSlice = createSlice({
  name: 'styling',
  initialState,
  reducers: {
    stylingUpdateForLabel(state, action: PayloadAction<{ label: string; styling: Partial<NodeStyling> }>) {
      const { label, styling } = action.payload;
      state.nodeStyles[label] = {
        ...state.nodeStyles[label],
        ...styling,
      };
    },
    stylingUpdateForRelType(state, action: PayloadAction<{ relType: string; styling: Partial<RelationStyling> }>) {
      const { relType, styling } = action.payload;
      state.relStyles[relType] = {
        ...state.relStyles[relType],
        ...styling,
      };
    },
    batchStylingUpdate(state, action: PayloadAction<GraphStyling>) {
      const { node, relationship } = action.payload;
      state.nodeStyles = {
        ...state.nodeStyles,
        ...node,
      };
      state.relStyles = {
        ...state.relStyles,
        ...relationship,
      };
    },
    sortingPriorityReordered(state, action: PayloadAction<string[]>) {
      state.stylingPriorityOrder = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateDataSummary.fulfilled, (state, action) => {
      for (const label of action.payload.labels) {
        if (!state.stylingPriorityOrder.includes(label)) {
          state.stylingPriorityOrder.push(label);
        }

        if (state.nodeStyles[label] === undefined) {
          state.nodeStyles[label] = {};
        }
      }
      state.stylingPriorityOrder = state.stylingPriorityOrder.filter((label) => action.payload.labels.includes(label));
    });
  },
});

export const { stylingUpdateForLabel, stylingUpdateForRelType, batchStylingUpdate, sortingPriorityReordered } =
  stylingSlice.actions;

export default stylingSlice.reducer;
