import { addListener, createListenerMiddleware } from '@reduxjs/toolkit';
import type { Dispatch, TypedAddListener, TypedStartListening } from '@reduxjs/toolkit';

import { saveToStorage } from '../../helpers/storage';
import type { RootState } from '../store';

export const storageListenerMiddleware = createListenerMiddleware();

export const startAppListening: TypedStartListening<RootState, Dispatch> =
  storageListenerMiddleware.startListening.withTypes<RootState, Dispatch>();

export const addAppListener: TypedAddListener<RootState, Dispatch> = addListener.withTypes<RootState, Dispatch>();

/** Save to local storage is triggered on every action */
storageListenerMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    // Always sync to local storage after every action
    return true;
  },
  effect: (action, listenerApi) => {
    const latestState = listenerApi.getState();
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    saveToStorage(latestState as RootState);
  },
});
