import type { Instance } from '@nx/state';
import { TIER } from '@nx/state';
import * as yup from 'yup';

import { validateYup } from '../../../../utils/validation';
import type { CloneToExistingData } from './model';

const schema = yup.object({
  name: yup.string().max(30, 'Instance name can not be longer than 30 characters').required(),
  targetInstance: yup.object().required('You must select an instance to overwrite'),
  confirmed: yup
    .bool()
    .required('You must confirm that you understand the consequences of cloning into an existing instance')
    .isTrue('You must confirm that you understand the consequences of cloning into an existing instance'),
});

export const validate = (data: CloneToExistingData, { onlyRequired }: { onlyRequired?: boolean } = {}) =>
  validateYup(schema, data, onlyRequired ?? false);

export const filterAvailableCloneInstances = (instance: Instance, instances: Instance[]) => {
  const filterFunc = (target: Instance) =>
    target.cloudProvider === instance.cloudProvider &&
    (instance.tier === TIER.FREE ? TIER.PROFESSIONAL : instance.tier) === target.tier &&
    instance.id !== target.id &&
    target.desiredSettings.version >= instance.desiredSettings.version &&
    target.availableActions.overwrite.enabled;
  return instances.filter((i) => filterFunc(i));
};
