import { DataGridComponents } from '@neo4j-ndl/react';
import { type CellContext, flexRender } from '@tanstack/react-table';

import { standardFormatAbsoluteNumber, timePeriodFormatter } from '../../../../shared/ui-helpers';
import { ACTIONS_COLUMN_ID } from '../../types';

type HeaderCellProps<T> = React.ComponentProps<typeof DataGridComponents.HeaderCell<T>>;

const HeaderCell = <T,>(props: HeaderCellProps<T>) => {
  const { cell } = props;
  return cell.column.id === ACTIONS_COLUMN_ID ? (
    <div className="bg-palette-neutral-bg-weak border-l-palette-neutral-border-weak flex items-center justify-center border-l">
      <DataGridComponents.HeaderCell {...props}>
        <div className="ndl-header-group !mr-0">
          <div className="ndl-header-cell">{flexRender(cell.column.columnDef.header, cell.getContext())}</div>
        </div>
      </DataGridComponents.HeaderCell>
    </div>
  ) : (
    <DataGridComponents.HeaderCell {...props} />
  );
};

const ColumnPreferencesHeaderCell = <T,>(props: HeaderCellProps<T>) => {
  const { cell } = props;
  return cell.column.id === ACTIONS_COLUMN_ID ? (
    <DataGridComponents.HeaderCell {...props}>
      <div className="ndl-header-cell">{flexRender(cell.column.columnDef.header, cell.getContext())}</div>
    </DataGridComponents.HeaderCell>
  ) : (
    <DataGridComponents.HeaderCell {...props} />
  );
};

const TableResults = ({
  pageIndex,
  pageLength,
  rowsLength,
}: Record<'pageIndex' | 'pageLength' | 'rowsLength', number>) => {
  const from = Math.min(rowsLength, 1 + pageIndex * pageLength);
  const to = Math.min(rowsLength, pageIndex * pageLength + pageLength);
  return (
    <span className="ndl-data-grid-results">
      <span>
        Showing{' '}
        <b>
          {from}
          {to !== from && `-${to}`}
        </b>{' '}
        of <b>{rowsLength}</b> results
      </span>
    </span>
  );
};

const CommonNoDataPlaceholder = ({ children }: React.PropsWithChildren) => (
  <div className="nld-table-placeholder-wrapper" style={{ pointerEvents: 'all' }} role="row">
    <span>{children}</span>
  </div>
);

const NoChartDataPlaceholder = () => (
  <CommonNoDataPlaceholder>
    Select a time range in the chart above and click <b>Fetch Logs</b>.
  </CommonNoDataPlaceholder>
);

const NoDataPlaceholder = () => (
  <CommonNoDataPlaceholder>
    Click <b>Fetch Logs</b> to request data.
  </CommonNoDataPlaceholder>
);

const TimestampCell = <TData,>({ getValue }: CellContext<TData, Date | undefined>) => {
  const date = getValue();
  return date && timePeriodFormatter(new Date(date));
};
const WithMs = <TData,>({ getValue }: CellContext<TData, Date | undefined>) => {
  const date = getValue();
  return date && timePeriodFormatter(new Date(date), true);
};
TimestampCell.WithMs = WithMs;

const NumberCell = <TData, TValue extends number | undefined>({ getValue }: CellContext<TData, TValue>) => {
  const n = getValue();
  return (
    <div className="ml-auto">{typeof n === 'number' ? standardFormatAbsoluteNumber(n) : `Unknown value: ${n}`}</div>
  );
};

const ValueListCell = <TData,>({ getValue }: CellContext<TData, string[]>) => (
  <div className="gap-token-4 flex flex-col">
    <div className="line-clip whitespace-pre-wrap" title={getValue().join(', ')}>
      {getValue().join(', ')}
    </div>
  </div>
);

export {
  HeaderCell,
  ColumnPreferencesHeaderCell,
  CommonNoDataPlaceholder,
  NoChartDataPlaceholder,
  NoDataPlaceholder,
  TableResults,
  TimestampCell,
  NumberCell,
  ValueListCell,
};
