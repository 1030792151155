import { difference, uniq } from 'lodash-es';
import {
  differenceWith,
  equals,
  filter,
  flatten,
  get,
  head,
  isEmpty,
  keyBy,
  map,
  mapValues,
  mergeWith,
  negate,
  pick,
  pickBy,
  pipe,
  split,
} from 'lodash/fp';

import {
  addIndexes,
  clearFullTextIndexes,
  clearIndexes,
  removeIndexedProperties,
  resetPathSegments,
  setPathSegments,
} from '../../state/perspectives/perspectiveMetadata';
import { indexTypes } from '../../state/perspectives/perspectiveMetadata.types';
import {
  addPropertiesToCategory,
  addPropertiesToRelationships,
  addRelationshipsToPerspective,
  removeLabelsFromPerspective,
  removePropertiesFromCategory,
  removePropertiesFromRelationships,
  removeRelationshipsFromPerspective,
  setMetaLabelsToPerspective,
} from '../../state/perspectives/perspectives';
import { updateStyleRuleForCategory, updateStyleRuleForRelationship } from '../../state/styles/styles';
import type { AppDispatch } from '../../state/types';
import type { GeneralPropertyKey, Perspective, PerspectiveWithStyle } from '../../types/perspective';
import { isEmptyArray, isFalsy, isTruthy } from '../../types/utility';
import type { PropertyMap, UpdatedMetadata } from './types';

const findDifferentProps = (from: PropertyMap, to: PropertyMap) =>
  pipe(mergeWith(differenceWith(equals))(from), pickBy(negate(isEmpty)))(to) as PropertyMap;

const keywordsRegex = '_Bloom_';

const findDiffProps = (
  oldCategoriesProps: PropertyMap,
  oldRelsProps: PropertyMap,
  newCategoriesProps: PropertyMap,
  newRelsProps: PropertyMap,
) => ({
  removedProps: {
    categories: findDifferentProps(oldCategoriesProps, newCategoriesProps),
    rels: findDifferentProps(oldRelsProps, newRelsProps),
  },
  addedProps: {
    categories: findDifferentProps(newCategoriesProps, oldCategoriesProps),
    rels: findDifferentProps(newRelsProps, oldRelsProps),
  },
});

const findDiff = (updated: string[], previous: string[]) => ({
  removed: difference(previous, updated),
  added: difference(updated, previous),
});

export const updateBasedOnDiff =
  (perspective: PerspectiveWithStyle, dispatch: AppDispatch, shouldUpdateProps = true) =>
  (updatedMetadata: UpdatedMetadata) => {
    const {
      labels: updatedLabels,
      labelsMap: updatedPropertiesPerLabel,
      relPropsMap: updatedPropertiesPerRelationship,
      relTypes: updatedRelationships,
      metadataPropsIndexes,
      indexes,
    } = updatedMetadata;
    const {
      relationshipTypes,
      labels,
      id: perspectiveId,
      categories: perspectiveCategories,
      metadata: perspectiveMetadata,
    } = perspective;
    const perspectiveRelationshipTypes = map(get('id'))(relationshipTypes);
    const perspectiveLabels = Object.keys(labels);

    const perspectiveRelProperties = relationshipTypes
      .filter((relType) => !isEmptyArray(relType.properties))
      .reduce((acc: Record<string, GeneralPropertyKey[]>, relType) => {
        acc[relType.id] = relType.properties;
        return acc;
      }, {});

    const updatedFilteredLabels = updatedLabels.filter((label) => !label.startsWith(keywordsRegex));
    // Add Labels with no properties to updatedPropertiesPerLabel
    updatedFilteredLabels.forEach((label) => {
      updatedPropertiesPerLabel[label] = updatedPropertiesPerLabel[label] ?? [];
    });
    const labelsUpdates = findDiff(updatedFilteredLabels, perspectiveLabels);
    const relsUpdates = findDiff(updatedRelationships, perspectiveRelationshipTypes);

    if (labelsUpdates.removed.length > 0) {
      dispatch(removeLabelsFromPerspective({ labels: labelsUpdates.removed, perspectiveId }));
    }
    if (relsUpdates.removed.length > 0) {
      dispatch(removeRelationshipsFromPerspective({ perspectiveId, relationshipTypes: relsUpdates.removed }));
    }
    if (relsUpdates.added.length > 0) {
      dispatch(
        addRelationshipsToPerspective({
          perspectiveId,
          propertyKeysForRelationshipsTypesMap: updatedPropertiesPerRelationship,
          relationshipTypes: relsUpdates.added,
        }),
      );
    }
    if (isTruthy(updatedPropertiesPerLabel)) {
      dispatch(
        setMetaLabelsToPerspective({
          perspectiveId,
          propertyKeysForLabelsMap: updatedPropertiesPerLabel,
        }),
      );
    }

    const categoriesWithLabels = pipe(
      filter(negate(pipe(get('labels'), isEmpty))),
      keyBy(get('name')),
    )(perspectiveCategories);

    const perspectiveCatProperties = pipe(
      mapValues(get('properties')),
      mapValues(map(({ name, dataType }: { name: string; dataType: string }) => ({ propertyKey: name, dataType }))),
    )(categoriesWithLabels);

    const updatedPropertiesPerCategory = pipe(
      mapValues(
        pipe(
          get('labels'),
          map((l: string) => updatedPropertiesPerLabel[l]),
          flatten,
          uniq,
          map(pick(['propertyKey', 'dataType'])),
          filter(negate(isEmpty)),
        ),
      ),
    )(categoriesWithLabels);

    const { removedProps, addedProps } = findDiffProps(
      perspectiveCatProperties,
      perspectiveRelProperties,
      updatedPropertiesPerCategory,
      updatedPropertiesPerRelationship,
    );
    const hasLabelsUpdates = labelsUpdates.removed.length > 0 || labelsUpdates.added.length > 0;
    const hasRelsUpdates = relsUpdates.removed.length > 0 || relsUpdates.added.length > 0;
    const hasRemovedRelsProps = Object.keys(removedProps.rels).length > 0;
    const hasAddedRelsProps = Object.keys(addedProps.rels).length > 0;
    const hasRemovedCategoriesProps = Object.keys(removedProps.categories).length > 0;
    const hasAddedCategoriesProps = Object.keys(addedProps.categories).length > 0;

    if (shouldUpdateProps) {
      if (hasRemovedRelsProps) {
        dispatch(removePropertiesFromRelationships({ relationshipsProps: removedProps.rels, perspectiveId }));
      }
      if (hasAddedRelsProps) {
        dispatch(addPropertiesToRelationships({ relationshipsProps: addedProps.rels, perspectiveId }));
      }

      if (hasRelsUpdates || hasRemovedRelsProps || hasAddedRelsProps) {
        relationshipTypes.forEach((rel) => {
          if (isTruthy(rel.styleRules) && isTruthy(removedProps.rels[rel.name])) {
            const removedPropsKeys = removedProps.rels[rel.name]?.map(({ propertyKey }) => propertyKey) ?? [];

            rel.styleRules.forEach((rule, i: number) => {
              const isUsedByRule = pipe(get('basedOn'), split('_'), head, equals)(rule);
              if (removedPropsKeys.some(isUsedByRule)) {
                dispatch(
                  updateStyleRuleForRelationship({
                    styleRuleIndex: i,
                    relType: rel.id,
                    styleId: perspectiveId,
                    styleRuleUpdate: {
                      applyColor: false,
                      applySize: false,
                    },
                  }),
                );
              }
            });
          }
        });
      }

      if (hasLabelsUpdates || hasRemovedCategoriesProps || hasAddedCategoriesProps) {
        perspective.categories.forEach((cat) => {
          const toRemoveCategoryProps = (removedProps.categories[cat.name] ?? []).map(({ propertyKey }) => propertyKey);
          const toAddCategoryProps = addedProps.categories[cat.name] ?? [];

          if (toRemoveCategoryProps.length > 0) {
            dispatch(
              removePropertiesFromCategory({
                properties: toRemoveCategoryProps,
                categoryId: cat.id,
                perspectiveId,
              }),
            );
          }
          if (toAddCategoryProps.length > 0) {
            dispatch(
              addPropertiesToCategory({
                properties: toAddCategoryProps,
                categoryId: cat.id,
                perspectiveId,
              }),
            );
          }

          if (isTruthy(cat.styleRules)) {
            cat.styleRules.forEach((rule, i) => {
              const isUsedByRule = pipe(get('basedOn'), split('_'), head, equals)(rule);

              if (toRemoveCategoryProps.some(isUsedByRule)) {
                dispatch(
                  updateStyleRuleForCategory({
                    styleRuleIndex: i,
                    categoryId: cat.id,
                    styleId: perspectiveId,
                    styleRuleUpdate: {
                      applyColor: false,
                      applySize: false,
                    },
                  }),
                );
              }
            });
          }
        });
      }
    }

    let hasIndexUpdates = false;

    const { indexes: metadataIndexes } = perspectiveMetadata;
    if (!isEmptyArray(metadataPropsIndexes)) {
      const perspectiveIndexes: Record<string, { key: string; metadataProp: boolean }[]> = {};

      if (isTruthy(metadataIndexes)) {
        for (const { label, propertyKeys } of metadataIndexes) {
          perspectiveIndexes[label] = isTruthy(perspectiveIndexes[label])
            ? uniq([...(perspectiveIndexes[label] ?? []), ...propertyKeys])
            : propertyKeys;
        }
      }

      for (const { label, propertyKeys } of metadataPropsIndexes) {
        for (const propertyKey of propertyKeys) {
          if (
            isFalsy(perspectiveIndexes[label]) ||
            isFalsy((perspectiveIndexes[label]?.filter(({ key }) => key === propertyKey) ?? []).length > 0)
          ) {
            hasIndexUpdates = true;
          }
        }
      }
    }

    const fullTextIndexes = indexes?.filter(({ type }) => type === indexTypes.FULL_TEXT_INDEX) ?? [];
    const currentFullTextIndexes = metadataIndexes.filter(({ type }) => type === indexTypes.FULL_TEXT_INDEX) ?? [];

    if (fullTextIndexes.length > 0 || currentFullTextIndexes.length > 0) {
      const { added, removed } = findDiff(
        fullTextIndexes.map(({ name = '' }) => name),
        currentFullTextIndexes.map(({ name = '' }) => name),
      );
      if (added.length > 0 || removed.length > 0) {
        hasIndexUpdates = true;
      }
    }

    const hasChanged = shouldUpdateProps
      ? hasRelsUpdates ||
        hasRemovedRelsProps ||
        hasAddedRelsProps ||
        hasLabelsUpdates ||
        hasRemovedCategoriesProps ||
        hasAddedCategoriesProps ||
        hasIndexUpdates
      : hasRelsUpdates || hasLabelsUpdates || hasIndexUpdates;

    return {
      hasChanged,
      labelsUpdates,
      relsUpdates,
      addedProps,
      removedProps,
    };
  };

export const updatePerspectiveStatsAndIndexes =
  (perspective: Perspective, dispatch: AppDispatch) => (metadata: UpdatedMetadata) => {
    if (isFalsy(perspective)) return null;
    const { pathSegments, indexes, metadataPropsIndexes, uniformProperties } = metadata;

    const { id: perspectiveId } = perspective;
    if (isTruthy(pathSegments)) {
      dispatch(resetPathSegments({ perspectiveId }));
    }
    if (isTruthy(pathSegments)) {
      dispatch(setPathSegments({ pathSegments, perspectiveId }));
    }

    // Set Indexes
    if (isTruthy(indexes)) {
      const fullTextIndexes = indexes.filter(({ type }) => type === indexTypes.FULL_TEXT_INDEX);
      if (fullTextIndexes.length > 0) {
        dispatch(clearFullTextIndexes({ perspectiveId }));
      }
      if (metadataPropsIndexes?.length > 0) {
        dispatch(clearIndexes({ perspectiveId }));
      }
      dispatch(addIndexes({ indexes, isMetadataProp: false, perspectiveId }));
    }
    if (isTruthy(uniformProperties)) {
      dispatch(removeIndexedProperties({ properties: uniformProperties, perspectiveId }));
    }

    if (metadataPropsIndexes?.length > 0) {
      dispatch(addIndexes({ indexes: metadataPropsIndexes, isMetadataProp: true, perspectiveId }));
    }

    return metadata;
  };
