import { Banner, Button, Checkbox, Dialog, Divider, Tooltip, Typography } from '@neo4j-ndl/react';
import { DATA_API_AUTH_PROVIDER_TYPE } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { CopyTextInput } from '@nx/ui';
import { useMemo, useState } from 'react';

import type { CredentialsModalData } from '../types';

interface CredentialsModalProps {
  data: CredentialsModalData | null;
  onClose: () => void;
}

export const CredentialsModal = ({ data, onClose }: CredentialsModalProps) => {
  const [hasConfirmed, setHasConfirmed] = useState<boolean>(false);
  const credentialsFilename = `Neo4j-Data-API-${data?.id}-Created-${new Date().toISOString().slice(0, 10)}.txt`;
  const credentialsURL = useMemo(() => {
    let content: string =
      '# Wait 15 seconds before connecting using these details, or login to https://console.neo4j.io to validate that the Data API is available\n' +
      `DATA_API_ID=${data?.id}\n` +
      `DATA_API_NAME=${data?.name}\n` +
      `DATA_API_URL=${data?.url}\n`;
    data?.authProviders
      .filter((provider) => provider.type === DATA_API_AUTH_PROVIDER_TYPE.apiKey)
      .forEach((provider) => {
        content +=
          '----\n' +
          `DATA_API_AUTH_PROVIDER_ID=${provider.id}\n` +
          `DATA_API_AUTH_PROVIDER_NAME=${provider.name}\n` +
          `DATA_API_AUTH_PROVIDER_API_KEY=${provider.key}\n`;
      });
    const blob = new Blob([content], { type: 'text/plain' });
    return window.URL.createObjectURL(blob);
  }, [data]);

  return (
    <Dialog
      modalProps={{
        'data-testid': 'generated-auth-provider-key-modal',
        'data-data-api-id': data?.id,
      }}
      size="small"
      isOpen
      hasDisabledCloseButton
    >
      <Dialog.Header>API credentials</Dialog.Header>
      <Dialog.Content className="space-y-6">
        {data?.authProviders
          .filter((provider) => provider.type === DATA_API_AUTH_PROVIDER_TYPE.apiKey)
          .map((provider, idx) => (
            <div key={idx} className="flex flex-col space-y-4">
              {idx > 0 && <Divider orientation="horizontal" />}
              <div className="readonly-field">
                <Typography variant="body-medium" className="text-neutral-text-weak">
                  Authentication provider name
                </Typography>
                <p>{provider.name}</p>
              </div>
              <CopyTextInput label={'Authentication provider ID'} value={provider.id} />
              {isNotNullish(provider.key) && (
                <CopyTextInput label={'Authentication provider key'} value={provider.key} />
              )}
            </div>
          ))}
        <Banner
          type="warning"
          title="IMPORTANT"
          description="After closing this window, you will no longer be able to access your API key. We recommend to store it securely."
          hasIcon
          usage="inline"
        />
      </Dialog.Content>
      <Dialog.Actions className="flex items-center justify-between">
        <Checkbox
          label="I understand"
          ariaLabel="I understand"
          htmlAttributes={{ 'aria-label': 'I understand' }}
          isChecked={hasConfirmed}
          onChange={(event) => setHasConfirmed(event.target.checked)}
        />
        <div className="flex gap-4 ">
          <Button
            fill="outlined"
            color="neutral"
            onClick={onClose}
            htmlAttributes={{
              'data-testid': 'close-auth-provider-key-modal',
            }}
          >
            Close
          </Button>
          <Tooltip type="simple" isDisabled={hasConfirmed} placement="top" isPortaled={false}>
            <Tooltip.Trigger hasButtonWrapper>
              <Button
                as="a"
                onClick={onClose}
                isDisabled={!hasConfirmed}
                htmlAttributes={{
                  href: credentialsURL,
                  download: credentialsFilename,
                  'data-testid': 'download-and-continue',
                }}
              >
                Download and continue
              </Button>
            </Tooltip.Trigger>
            <Tooltip.Content className="!fixed !w-[200px]">
              You need to confirm that you understand that you will no longer be able to access your API key after
              closing this window.
            </Tooltip.Content>
          </Tooltip>
        </div>
      </Dialog.Actions>
    </Dialog>
  );
};
