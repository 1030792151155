import { ForceDirectedLayoutType, HierarchicalLayoutType } from '@neo4j-nvl/base';

import { CoordinateLayout, DEFAULT_COORDINATE_X_SCALE, DEFAULT_COORDINATE_Y_SCALE } from './constants';
import type { VisualizationState } from './types';

export default (isCytoscapeEnabled = true) => [
  {
    version: '2.4.0',
    up: (visualization: Partial<VisualizationState>) => {
      // move layoutDirection and isCytoscapeEnabled
      const newVisualization = {
        ...visualization,
        layoutOptions: {
          [ForceDirectedLayoutType]: {
            isCytoscapeEnabled,
            simulationStopVelocity: 100,
            gravity: 150,
          },
          // @ts-expect-error data shape was changed in newer versions
          [HierarchicalLayoutType]: { direction: visualization?.layoutDirection ?? 'down' },
          ...visualization?.layoutOptions,
        },
      };
      // @ts-expect-error data shape was changed in newer versions
      delete newVisualization.layoutDirection;
      return newVisualization;
    },
  },
  {
    version: '2.7.0',
    up: (visualization: Partial<VisualizationState>) => {
      const coordinateOptions = visualization?.layoutOptions?.[CoordinateLayout];
      const newVisualization = {
        ...visualization,
        layoutOptions: {
          ...visualization?.layoutOptions,
          [CoordinateLayout]: {
            X: coordinateOptions?.X ?? null,
            Y: coordinateOptions?.Y ?? null,
            XScale: coordinateOptions?.XScale ?? DEFAULT_COORDINATE_X_SCALE,
            YScale: coordinateOptions?.YScale ?? DEFAULT_COORDINATE_Y_SCALE,
          },
        },
      };
      return newVisualization;
    },
  },
  {
    version: '2.15.0',
    up: (visualization: Partial<VisualizationState>) => {
      const currentForceDirectedOptions = visualization?.layoutOptions?.[ForceDirectedLayoutType];
      const newVisualization = {
        ...visualization,
        layoutOptions: {
          ...visualization?.layoutOptions,
          [ForceDirectedLayoutType]: {
            ...currentForceDirectedOptions,
            // @ts-expect-error data shape was changed in newer versions
            enableCytoscape: currentForceDirectedOptions?.isCytoscapeEnabled,
          },
        },
      };
      // @ts-expect-error data shape was changed in newer versions
      delete newVisualization.layoutOptions[ForceDirectedLayoutType].isCytoscapeEnabled;
      return newVisualization;
    },
  },
];
