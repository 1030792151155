import { opsApi, useActiveProject } from '@nx/state';
import { useMemo } from 'react';

import { usePageInfo } from '../shared/hooks/use-page-info';
import type { PaginationHook } from '../shared/hooks/use-pagination';
import { mapQueryLogAggregations } from '../shared/mappers';
import { useLogsContext } from './hooks/use-logs-context';
import type { SortingHook } from './hooks/use-sorting';
import { QueryLogsDataGrid } from './query-logs-data-grid';

export const AuraLogsSummary = ({
  selectedDbmsId,
  pagination,
  sorting,
}: {
  selectedDbmsId: string;
  pagination: PaginationHook;
  sorting: SortingHook<'Summary'>;
}) => {
  const activeProject = useActiveProject();
  const selectedTenantId = activeProject.id;
  const { filterInput, hasRequestedData } = useLogsContext();

  const {
    data: aggregationsData,
    isFetching: aggregationsLoading,
    error: aggregationsError,
  } = opsApi.useGetQueryLogAggregationsQuery(
    {
      tenantId: selectedTenantId,
      dbmsId: selectedDbmsId,
      args: {
        filter: filterInput,
        pagination: pagination.logsPagination,
        sort: sorting.querySorting,
      },
    },
    { skip: !selectedDbmsId || !selectedTenantId || !hasRequestedData },
  );
  const logAggregations = useMemo(
    () => (hasRequestedData ? mapQueryLogAggregations(aggregationsData?.logAggregations) : []),
    [hasRequestedData, aggregationsData],
  );
  const pageInfo = usePageInfo(aggregationsData?.pageInfo);

  return (
    <QueryLogsDataGrid
      dataGridType="Summary"
      logs={logAggregations}
      queryLogsLoading={aggregationsLoading}
      pageInfo={pageInfo}
      queryLogsError={aggregationsError}
      selectedDbmsId={selectedDbmsId}
      controlledPagination={pagination.controlledPagination}
      controlledSorting={sorting.controlledSorting}
      onPaginationChange={pagination.onPaginationChange}
      onSortingChange={sorting.onSortingChange}
    />
  );
};

export default AuraLogsSummary;
