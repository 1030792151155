import type { QueryLogFilterInput, RawFilterSelection, TimeRange } from '@nx/state';
import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext, useMemo } from 'react';

import { mapRawFiltersToInput } from '../../shared/mappers';
import type { TabKey } from '../../shared/types';

type LogsContextValue = {
  setIsRequestDialogOpen: (a: boolean) => void;
  selectedTab: TabKey;
  setSelectedTab: Dispatch<SetStateAction<TabKey>>;
  rawFilters: RawFilterSelection;
  setRawFilters: Dispatch<SetStateAction<RawFilterSelection>>;
  hasRequestedData: boolean;
  setHasRequestedData: Dispatch<SetStateAction<boolean>>;
  chartTimeRange: TimeRange;
};

export type LogsContext = LogsContextValue & {
  filterInput: QueryLogFilterInput;
};

const LogsContext = createContext<LogsContextValue | null>(null);

export const LogsContextProvider = LogsContext.Provider;

export function useLogsContext(): LogsContext {
  const logsContext = useContext(LogsContext);

  if (!logsContext) {
    throw new Error('useLogsContext must be used within a LogsContextProvider');
  }

  const {
    setIsRequestDialogOpen,
    selectedTab,
    setSelectedTab,
    rawFilters,
    setRawFilters,
    hasRequestedData,
    setHasRequestedData,
    chartTimeRange,
  } = logsContext;

  const filterInput = useMemo(() => mapRawFiltersToInput(rawFilters), [rawFilters]);
  return {
    setIsRequestDialogOpen,
    selectedTab,
    setSelectedTab,
    rawFilters,
    setRawFilters,
    hasRequestedData,
    setHasRequestedData,
    chartTimeRange,
    filterInput,
  };
}

export function useLogsContextIsStaleData(): boolean {
  const { filterInput, chartTimeRange } = useLogsContext();
  return useMemo(() => {
    const dateFrom = new Date(filterInput.from);
    const dateTo = new Date(filterInput.to);
    return (
      chartTimeRange.startTime.getTime() !== dateFrom.getTime() || chartTimeRange.endTime.getTime() !== dateTo.getTime()
    );
  }, [chartTimeRange.endTime, chartTimeRange.startTime, filterInput.from, filterInput.to]);
}
