import { IconButton } from '@neo4j-ndl/react';
import { ArrowRightCircleIconOutline } from '@neo4j-ndl/react/icons';
import type { TimeRangeSecurityLog, TimeRangeSecurityLogSummary } from '@nx/state';
import { dataGridHelpersClasses } from '@nx/ui';
import type { ColumnDefResolved } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';
import { produce } from 'immer';
import { useCallback, useMemo } from 'react';

import { columnPreferencesColumn } from '../../shared/components/column-preferences';
import { NumberCell, TimestampCell } from '../../shared/helpers/table-overrides';
import type { TabText } from '../../shared/types';
import { StatusLabel } from '../helpers';
import { MessageCell } from '../helpers/message-cell';
import type { TypedColumnDef } from '../shared/types';
import { useLogsContext } from './use-logs-context';

type CommonKeys = keyof (TimeRangeSecurityLog | TimeRangeSecurityLogSummary);
type CommonFields = Pick<TimeRangeSecurityLog, CommonKeys> & Pick<TimeRangeSecurityLogSummary, CommonKeys>;

const COMPACT_SIZES: Record<string, number> = {
  status: 135,
  query: 135,
  // summary columns
  from: 100,
  to: 100,
  // details columns
  endTime: 150,
};

const columnHelperCommon = createColumnHelper<CommonFields>();
const columnHelperSummary = createColumnHelper<TimeRangeSecurityLogSummary>();
const columnHelperDetails = createColumnHelper<TimeRangeSecurityLog>();

const commonColumns = {
  status: columnHelperCommon.accessor('status', {
    header: 'Status',
    size: 150,
    cell: ({ getValue }) => <StatusLabel level={getValue()} />,
    enableColumnFilter: true,
    enableResizing: false,
    meta: {
      logs: {
        isPersistent: true,
      },
    },
  }),
  executingUser: columnHelperCommon.accessor('executingUser', {
    header: 'Executing User',
    enableColumnFilter: true,
    size: 250,
  }),
  message: columnHelperCommon.accessor('message', {
    header: 'Message',
    cell: ({ getValue, row, column }) => (
      <MessageCell value={getValue()} message={row.original.message} width={column.getSize()} />
    ),
    size: 400,
    meta: {
      logs: {
        isPersistent: true,
      },
    },
  }),
};

const summaryColumns = [
  commonColumns.status,
  commonColumns.message,
  commonColumns.executingUser,
  columnHelperSummary.accessor('logCount', {
    header: 'Count',
    cell: NumberCell,
    size: 110,
  }),
  columnHelperSummary.accessor('from', {
    header: 'From',
    cell: TimestampCell,
    size: 210,
    enableSorting: false,
  }),
  columnHelperSummary.accessor('to', {
    header: 'To',
    cell: TimestampCell,
    size: 210,
    enableSorting: false,
  }),
  columnHelperSummary.accessor('authenticatedUsers', {
    header: 'Authenticated Users',
    size: 250,
  }),
  columnHelperSummary.display({
    ...columnPreferencesColumn,
    cell: function Cell({ row }) {
      const { message, id } = row.original;
      const { setRawFilters, setSelectedTab } = useLogsContext();
      const onDetails = useCallback(() => {
        setRawFilters(
          produce((draft) => {
            draft.message = message;
          }),
        );
        setSelectedTab('DETAILS');
        // track(OPS_EVENTS.LOGS_VIEW_QUERY_DETAILS);
      }, [message, setRawFilters, setSelectedTab]);

      return (
        <div
          className={classNames(dataGridHelpersClasses['visible-on-row-hover'], ' gap-token-4 flex items-center !p-0')}
        >
          <IconButton
            ariaLabel="Go to details for message"
            className="bg-palette-neutral-bg-weak rounded-lg"
            data-testid={`message-details-${id}`}
            isClean
            isGrouped
            onClick={onDetails}
            htmlAttributes={{
              title: 'Go to details for message',
              'data-testid': `message-details-${id}`,
            }}
          >
            <ArrowRightCircleIconOutline />
          </IconButton>
        </div>
      );
    },
  }),
];

const detailsColumns = [
  commonColumns.status,
  commonColumns.message,
  commonColumns.executingUser,
  columnHelperDetails.accessor('time', {
    header: 'Time',
    cell: TimestampCell.WithMs,
    size: 210,
  }),
  columnHelperDetails.accessor('authenticatedUser', {
    header: 'Authenticated User',
    enableColumnFilter: true,
    size: 250,
  }),
  columnHelperDetails.accessor('database', {
    header: 'Database',
    enableColumnFilter: true,
    size: 160,
  }),
  columnHelperDetails.accessor('driver', {
    header: 'Driver',
    enableColumnFilter: true,
    size: 250,
  }),
  columnHelperDetails.display(columnPreferencesColumn),
];

export const useColumns = <T extends TabText>(table: T, hasData: boolean) => {
  const { filterInput } = useLogsContext();

  const columns = useMemo(() => {
    if (table === 'Summary') {
      const cols = hasData
        ? summaryColumns
        : summaryColumns.map((col) => {
            const identifier =
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              (col as ColumnDefResolved<T>).accessorKey ?? (col as ColumnDefResolved<T>).id;
            return identifier !== undefined && (COMPACT_SIZES[identifier] ?? 0)
              ? { ...col, size: COMPACT_SIZES[identifier] }
              : col;
          });
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return cols as TypedColumnDef<T>[];
    }

    if (table === 'Details') {
      const singleQueryDetails = Boolean(filterInput.message);
      const cols =
        singleQueryDetails || !hasData
          ? detailsColumns.map((col) => {
              const identifier =
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                (col as ColumnDefResolved<T>).accessorKey ?? (col as ColumnDefResolved<T>).accessorKey;

              if (!hasData && identifier !== undefined && (COMPACT_SIZES[identifier] ?? 0)) {
                return { ...col, size: COMPACT_SIZES[identifier] };
              }

              if (identifier === 'message') {
                return {
                  ...col,
                  size: COMPACT_SIZES[identifier],
                  cell: 'See above',
                };
              }

              return col;
            })
          : detailsColumns;
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return cols as TypedColumnDef<T>[];
    }

    return [];
  }, [filterInput.message, hasData, table]);

  return columns;
};
