import { Banner, Button, Dialog, Radio } from '@neo4j-ndl/react';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import { type Instance, LOG_FILE, consoleApi, getApiError, getErrorMessage } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { formatISO } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

import type { REQUEST_LOGS_TIME_SELECTION } from './entities/helpers';
import { determineInterval, isSecurityLogsEnabled, settingsForFile } from './entities/helpers';

type RequestLogsProps = {
  instance: Instance;
  onClose: () => void;
  onSuccess: () => void;
};

const logger = createLogger(APP_SCOPE.framework);

const RequestLogsModal = ({ instance, onClose, onSuccess }: RequestLogsProps) => {
  const [fileType, setFileType] = useState<LOG_FILE>(LOG_FILE.QUERY);
  const [timeSelection, setTimeSelection] = useState<REQUEST_LOGS_TIME_SELECTION | null>(null);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null);

  const [requestLog, requestLogRes] = consoleApi.useRequestLogsMutation();

  const settings = settingsForFile[fileType];

  const apiErrorMessage = useMemo(() => {
    if (!requestLogRes.isError) {
      return null;
    }
    const error = getApiError(requestLogRes.error);
    const message = getErrorMessage(error);
    return message;
  }, [requestLogRes.error, requestLogRes.isError]);

  useEffect(() => {
    setSubmitEnabled(timeSelection !== null);
  }, [timeSelection]);

  useEffect(() => {
    setTimeSelection(null);
  }, [fileType]);

  const handleSubmit = () => {
    const [startDate, endDate] = determineInterval(timeSelection);
    if (!startDate || !endDate) {
      setFormErrorMessage('Invalid date range');
      return;
    }
    const start = formatISO(startDate);
    const end = formatISO(endDate);

    const requestLogPayload = {
      dbId: instance.id,
      start: formatISO(start),
      end: formatISO(end),
      files: [fileType],
    };
    requestLog(requestLogPayload)
      .unwrap()
      .then(() => {
        onSuccess();
      })
      .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(e);
        if (isNotNullish(error.message)) {
          logger.error(error.message);
        }
      });
  };

  return (
    <Dialog
      modalProps={{
        className: 'request-logs-dialog',
      }}
      isOpen={true}
      onClose={() => {
        onClose();
      }}
      size="small"
    >
      <Dialog.Header>Request Log</Dialog.Header>
      <Dialog.Subtitle>All times presented here are in UTC.</Dialog.Subtitle>
      <Dialog.Content>
        {isNotNullish(apiErrorMessage) && (
          <Banner type="danger" description={apiErrorMessage} className="mb-6" usage="inline" />
        )}
        {isNotNullish(formErrorMessage) && (
          <Banner type="danger" title={formErrorMessage} className="mb-6" usage="inline" />
        )}

        {isSecurityLogsEnabled(instance) && (
          <div className="mb-3">
            <p>Type</p>
            <Radio
              className="my-1"
              value={LOG_FILE.QUERY}
              isChecked={fileType === LOG_FILE.QUERY}
              onChange={() => setFileType(LOG_FILE.QUERY)}
              label="Query log"
            />
            <Radio
              className="my-1"
              value={LOG_FILE.SECURITY}
              isChecked={fileType === LOG_FILE.SECURITY}
              onChange={() => setFileType(LOG_FILE.SECURITY)}
              label="Security log"
            />
          </div>
        )}

        <p>Range</p>
        {settings.timeSelectionOptions.map(({ label, value }) => (
          <Radio
            className="my-1"
            isChecked={timeSelection === value}
            onChange={() => {
              setTimeSelection(value);
            }}
            value={value}
            key={value}
            label={label}
          />
        ))}

        {/* TODO: Implement Custom time select */}

        <Dialog.Actions>
          <Button onClick={onClose} fill="text" color="primary">
            Cancel
          </Button>
          <Button
            isLoading={requestLogRes.isLoading}
            isDisabled={!submitEnabled}
            onClick={handleSubmit}
            htmlAttributes={{
              'data-testid': 'clone-button',
            }}
          >
            Request
          </Button>
        </Dialog.Actions>
      </Dialog.Content>
    </Dialog>
  );
};

export default RequestLogsModal;
