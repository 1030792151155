import type { NVL, Node, Relationship } from '@neo4j-nvl/base';
import { InteractiveNvlWrapper } from '@neo4j-nvl/react';
import { AURA_CONSOLE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import type { CreatedInstance, ProfilingInfo } from '@nx/state';
import {
  ONBOARDING_EXPERIENCE,
  TIER,
  consoleApi,
  getApiErrorMessage,
  useActiveOrg,
  useActiveProject,
  useAuth,
  useNotificationActions,
  useTrackEvent,
  useUnsafeAppContext,
} from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  constructNodes,
  constructRels,
  determineInstanceType,
  determineMarketingPreferenceFromRegionalTier,
  freeDefaults,
  proTrialDefaults,
  profilingDefaults,
} from './entities/helpers';
import type { InstanceFormData, JOB_ROLE, ONBOARDING_INTENT, OnboardingData, USE_CASE } from './entities/model';
import { CreateInstanceSection, IntentSection, RoleSection, UseCaseSection, WelcomeSection } from './sections';
import { WaitingPage } from './waiting-page';

const logger = createLogger(APP_SCOPE.aura);

export const OnboardingPage = () => {
  const trackEvent = useTrackEvent();
  const { addNotification } = useNotificationActions();
  const [step, setStep] = useState(0);
  const auth = useAuth();
  const defaultName = (auth.user?.profile.sub.startsWith('auth0') ?? true) ? '' : (auth.user?.profile.name ?? '');
  const [profilingData, setProfilingData] = useState(
    profilingDefaults({
      firstName: defaultName.split(' ').slice(0, -1).join(' '),
      lastName: defaultName.split(' ').slice(-1).join(' '),
    }),
  );

  const [createInstance, createInstanceRes] = consoleApi.useCreateInstanceMutation();
  // For Multi-db
  const [createAuraInstance, createAuraInstanceRes] = consoleApi.useCreateAuraInstanceMutation();

  const [createdInstance, setCreatedInstance] = useState<CreatedInstance | null>(null);

  const ref = useRef<NVL>(null);
  const project = useActiveProject();
  const org = useActiveOrg();
  const { data: user } = consoleApi.useGetUserDetailsQuery();
  const [updateProfiling, updateProfilingRes] = consoleApi.useUpdateProfilingInfoMutation();
  const [updateMarketingOptIn, updateMarketingOptInRes] = consoleApi.useUpdateMarketingOptInMutation();

  const [marketingPreference, setMarketingPreference] = useState(false);

  const handleAcceptMarketingOptIn = () => {
    if (isNullish(user) || isNullish(user.marketingChoiceRequired)) {
      return;
    }
    updateMarketingOptIn({
      marketingPreference: determineMarketingPreferenceFromRegionalTier(
        user.marketingChoiceRequired,
        marketingPreference,
      ),
    })
      .unwrap()
      .catch((error: FetchBaseQueryError | SerializedError) => {
        const errorMessage = getApiErrorMessage(error);
        logger.error(errorMessage);
      });
  };

  const [nodes, setNodes] = useState<Node[]>(constructNodes(profilingData));
  const [rels, setRels] = useState<Relationship[]>([]);
  const instanceType = useMemo(
    () => determineInstanceType(user, profilingData.onboardingIntent, profilingData.jobRole),

    [profilingData.onboardingIntent, profilingData.jobRole, user],
  );
  const tierConfig = useMemo(() => {
    if (instanceType === ONBOARDING_EXPERIENCE.Trial && isNotNullish(project.tierConfigs.professional)) {
      return project.tierConfigs.professional;
    }

    if (instanceType === ONBOARDING_EXPERIENCE.Free && isNotNullish(project.tierConfigs.free)) {
      return project.tierConfigs.free;
    }

    throw new Error('Tier is not supported');
  }, [instanceType, project.tierConfigs.free, project.tierConfigs.professional]);
  const [instanceData, setInstanceData] = useState<InstanceFormData>(
    instanceType === ONBOARDING_EXPERIENCE.Trial ? proTrialDefaults(tierConfig) : freeDefaults(tierConfig),
  );

  const handleZoomToFit = () => {
    ref.current?.fit(
      nodes.map((node) => node.id),
      {},
    );
  };

  const handleProfilingDataChange = (newData: Partial<OnboardingData>) => {
    const d = { ...profilingData, ...newData };
    setProfilingData((oldData) => ({ ...oldData, ...newData }));
    const newNodes = constructNodes(d);

    setNodes(newNodes);
    setRels(constructRels(newNodes));
  };

  const handleInstanceDataChange = (newData: Partial<InstanceFormData>) => {
    setInstanceData((oldData) => ({ ...oldData, ...newData }));
  };

  useEffect(() => {
    handleZoomToFit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rels]);

  const handleSubmitOnboardingData = (data: Partial<ProfilingInfo>, onSuccess: () => void, onFail?: () => void) => {
    updateProfiling({ ...data, organizationId: org.id })
      .unwrap()
      .then(() => {
        onSuccess();
      })
      .catch((error: FetchBaseQueryError | SerializedError) => {
        onFail?.();
        const errorMessage = getApiErrorMessage(error);
        addNotification({ type: 'danger', title: 'Error', description: errorMessage, timeout: 5000 });
      });
  };

  const handleCreate = () => {
    if (isNullish(instanceData)) {
      return;
    }
    if (isNullish(instanceData.region)) {
      return;
    }
    const multiDbFree = project.capabilities.multi_db_free && instanceData.tier === TIER.FREE;
    const createMutation = multiDbFree ? createAuraInstance : createInstance;

    createMutation({
      name: instanceData.name,
      cloudProvider: instanceData.cloudProvider,
      memory: instanceData.memory,
      storage: instanceData.storage,
      region: instanceData.region,
      version: instanceData.version,
      tier: instanceData.tier,
      projectId: project.id,
      ...(instanceType === ONBOARDING_EXPERIENCE.Trial && {
        isTrial: true,
      }),
    })
      .unwrap()
      .then((res) => {
        setStep(5);
        setCreatedInstance(res);
        const event = {
          tier: instanceData.tier,
          ...(instanceType === ONBOARDING_EXPERIENCE.Trial && {
            proTrial: 'proTrial_included',
          }),
        };
        trackEvent({ event: AURA_CONSOLE_EVENTS.INSTANCE_CREATE, properties: event, scope: APP_SCOPE.aura });
      })
      .catch((error: FetchBaseQueryError | SerializedError) => {
        const errorMessage = getApiErrorMessage(error);
        addNotification({ type: 'danger', title: 'Error', description: errorMessage, timeout: 5000 });
      });
  };

  return (
    <>
      {step < 5 ? (
        <div
          className="bg-neutral-bg-weak border-neutral-border-weak m-6 flex w-full justify-center gap-10 rounded-[48px] border p-6 pl-10"
          data-testid="onboarding-layout"
        >
          {step === 0 && (
            <WelcomeSection
              data={profilingData}
              onDataChange={handleProfilingDataChange}
              onNextStep={() => {
                handleAcceptMarketingOptIn();
                handleSubmitOnboardingData(
                  {
                    firstName: profilingData.firstName.trim(),
                    lastName: profilingData.lastName.trim(),
                    companyName: profilingData.companyName.trim(),
                  },
                  () => {
                    setStep(1);
                  },
                );
              }}
              isLoading={updateProfilingRes.isLoading || updateMarketingOptInRes.isLoading}
              regionalTier={user?.marketingChoiceRequired}
              marketingPreference={marketingPreference}
              onMarketingPreferenceChange={() => setMarketingPreference(!marketingPreference)}
            />
          )}
          {step === 1 && (
            <IntentSection
              onNextStep={(intent: ONBOARDING_INTENT) => {
                handleProfilingDataChange({ onboardingIntent: intent });
                handleSubmitOnboardingData(
                  { onboardingIntent: intent.valueOf() },
                  () => {
                    setStep(2);
                  },
                  () => {
                    handleProfilingDataChange({ onboardingIntent: null, onboardingIntentOther: null });
                  },
                );
              }}
              onBack={() => setStep(0)}
              isLoading={updateProfilingRes.isLoading}
              data={profilingData}
            />
          )}
          {step === 2 && (
            <RoleSection
              onNextStep={(jobRole: JOB_ROLE) => {
                handleProfilingDataChange({ jobRole });
                handleSubmitOnboardingData(
                  { jobRole: jobRole.valueOf() },
                  () => {
                    setStep(3);
                  },
                  () => {
                    handleProfilingDataChange({ jobRole: null, jobRoleOther: null });
                  },
                );
              }}
              onBack={() => {
                handleProfilingDataChange({ onboardingIntent: null, onboardingIntentOther: null });
                setStep(1);
              }}
              isLoading={updateProfilingRes.isLoading}
              data={profilingData}
            />
          )}
          {step === 3 && (
            <UseCaseSection
              onNextStep={(primaryUseCase: USE_CASE) => {
                handleProfilingDataChange({ primaryUseCase });
                handleSubmitOnboardingData(
                  { primaryUseCase: primaryUseCase.valueOf() },
                  () => {
                    setStep(4);
                    setInstanceData(
                      instanceType === ONBOARDING_EXPERIENCE.Trial
                        ? proTrialDefaults(tierConfig)
                        : freeDefaults(tierConfig),
                    );
                  },
                  () => {
                    handleProfilingDataChange({ primaryUseCase: null, primaryUseCaseOther: null });
                  },
                );
              }}
              onBack={() => {
                handleProfilingDataChange({ jobRole: null, jobRoleOther: null });
                setStep(2);
              }}
              isLoading={updateProfilingRes.isLoading}
              data={profilingData}
            />
          )}

          {step === 4 && (
            <CreateInstanceSection
              instanceType={instanceType}
              data={instanceData}
              isLoading={createInstanceRes.isLoading || createAuraInstanceRes.isLoading}
              onDataChange={handleInstanceDataChange}
              tierConfig={tierConfig}
              project={project}
              onCreate={handleCreate}
            />
          )}
          <div className="bg-neutral-bg-default hidden h-full w-full rounded-[24px] sm:block">
            <InteractiveNvlWrapper
              ref={ref}
              nodes={nodes}
              rels={rels}
              nvlOptions={{
                minZoom: 1,
                maxZoom: 3,
                relationshipThreshold: 0.55,
              }}
              layout="free"
              mouseEventCallbacks={{
                onHover: true,
                onZoom: false,
              }}
            />
          </div>
        </div>
      ) : (
        <>{isNotNullish(createdInstance) && <WaitingPage createdInstance={createdInstance} project={project} />}</>
      )}
    </>
  );
};

export const GuardedOnboardingPage = () => {
  const org = useActiveOrg();
  const project = useActiveProject();
  const { userHomePath } = useUnsafeAppContext();
  const navigate = useNavigate();

  // Navigate the user back to the home page if they either have completed the onboarding or if the feature toggle is
  // disabled. Also don't allow the user to get to this page if they might not be able to complete the onboard flow.
  if (
    org.onboardingCompleted ||
    !org.featureToggles['plg-new-onboarding'] ||
    [project.tierConfigs.free, project.tierConfigs.professional].some((item) => isNullish(item))
  ) {
    const path = isNotNullish(userHomePath) ? `/${userHomePath}` : `/projects/${project.id}/instances`;
    navigate(path, { replace: true });
    return null;
  }

  return <OnboardingPage />;
};
