import { Select, Typography } from '@neo4j-ndl/react';
import { useCapability, useSetting } from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { ControlPanel } from '@nx/ui/src/control-panel';

const themeOptions = [
  { label: 'Light', value: 'light' as const },
  { label: 'Dark', value: 'dark' as const },
  { label: 'System default', value: 'system' as const },
];

export const PreferencesLayout = () => {
  const [isThemeSwitcherOn] = useCapability('framework:theme-switcher');
  const [theme, setTheme] = useSetting('global', 'theme');

  return (
    <div className="mx-auto mt-2 h-full max-w-[1024px] overflow-auto p-4">
      <Typography variant="h2" className="mb-6 px-2 pt-2">
        Preferences
      </Typography>
      <div className="flex flex-col gap-6">
        {isThemeSwitcherOn ? (
          <ControlPanel>
            <div className="flex items-center justify-between gap-2">
              <div>
                <Typography variant="body-medium">Theme</Typography>
                <p>
                  <Typography variant="body-medium" className="text-palette-neutral-text-weaker">
                    Choose your preferred UI theme: Light, Dark, or follow the System setting.
                  </Typography>
                </p>
              </div>
              <Select
                className="w-[180px]"
                type="select"
                size="medium"
                selectProps={{
                  'aria-label': 'Select theme',
                  options: themeOptions,
                  value: themeOptions.find((option) => option.value === theme),
                  onChange: (selected) => {
                    if (isNotNullish(selected?.value)) {
                      setTheme(selected.value);
                    }
                  },
                }}
              ></Select>
            </div>
          </ControlPanel>
        ) : null}
      </div>
    </div>
  );
};
