export enum QUERY_EVENTS {
  FRAME_FULLSCREEN = 'FRAME_FULLSCREEN',
  FRAME_MINIMIZED = 'FRAME_MINIMIZED',
  FRAME_CLOSED = 'FRAME_CLOSED',
  EDITOR_TOGGLED = 'EDITOR_TOGGLED',
  SIDEBAR_PANEL_OPENED = 'SIDEBAR_PANEL_OPENED',
  RESULT_VIEW_TOGGLED = 'RESULT_VIEW_TOGGLED',
  COMMAND_RAN = 'COMMAND_RAN',
  CYPHER_QUERY = 'CYPHER_QUERY',
  FORMAT_CYPHER_QUERY = 'FORMAT_CYPHER_QUERY',
  GRAPH_INTERACTION = 'GRAPH_INTERACTION',
  SAVED_CYPHER_FOLDER_CREATED = 'SAVED_CYPHER_FOLDER_CREATED',
  SAVED_CYPHER_FOLDER_UPDATED = 'SAVED_CYPHER_FOLDER_UPDATED',
  SAVED_CYPHER_CREATED = 'SAVED_CYPHER_CREATED',
  SAVED_CYPHER_OVERWRITE = 'SAVED_CYPHER_OVERWRITE',
  SAVED_CYPHER_UPDATED = 'SAVED_CYPHER_UPDATED',
  SAVED_CYPHER_DELETED = 'SAVED_CYPHER_DELETED',
  SAVED_CYPHER_INSERTED_INTO_EDITOR = 'SAVED_CYPHER_INSERTED_INTO_EDITOR',
  SAVED_CYPHER_MOVED = 'SAVED_CYPHER_MOVED',
  SAVED_CYPHER_EXPORTED = 'SAVED_CYPHER_EXPORTED',
  SAVED_CYPHER_IMPORTED = 'SAVED_CYPHER_IMPORTED',
  DOWNLOAD = 'DOWNLOAD',
  DELETE_HISTORY_ENTRIES = 'DELETE_HISTORY_ENTRIES',
  EDITOR_SHORTCUT_RAN = 'EDITOR_SHORTCUT_RAN',
  TABLE_FILTER_BUTTON_CLICKED = 'TABLE_FILTER_BUTTON_CLICKED',
  CYPHER_QUERY_GENERATED = 'CYPHER_QUERY_GENERATED',
  PREVIEW_PAGE_LOAD = 'PREVIEW_PAGE_LOAD',
  PREVIEW_UI_SWITCH = 'PREVIEW_UI_SWITCH',
  SYSINFO_CMD_RAN = 'SYSINFO_CMD_RAN',
  SYSINFO_CMD_FAILED = 'SYSINFO_CMD_FAILED',
}
