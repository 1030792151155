import { LoadingSpinner, Tabs } from '@neo4j-ndl/react';
import { OPS_EVENTS } from '@nx/analytics-service';
import type { Instance, InstanceSummary, RawFilterSelection } from '@nx/state';
import { MODAL_TYPE, TIER, useActiveProject, useModal } from '@nx/state';
import { Center, DataGridHelpers } from '@nx/ui';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';

import type { ChartContext } from '../../metrics/charts/types';
import { useMetricsViewState } from '../../metrics/hooks';
import { track } from '../../services/segment/analytics';
import { NoInstancesOverlay, UpgradeOverlay } from '../../shared/components';
import { usePagination } from '../shared/hooks/use-pagination';
import { DEFAULT_TAB, TAB_OPTIONS } from '../shared/types';
import { FakeQueryLogs } from './fake-query-logs';
import { LogsContextProvider } from './hooks/use-logs-context';
import { useRawFilters } from './hooks/use-raw-filters';
import { useSorting } from './hooks/use-sorting';
import { QueryLogFilters } from './query-log-filters/query-log-filters';
import AuraLogsDetails from './query-logs-details';
import { InitiateDownloadButtonWithModal } from './query-logs-download';
import AuraLogsSummary from './query-logs-summary';
import { QueryTimeline } from './query-timeline';

const QueryLogs = ({
  chartContext,
  selectedInstance,
  isRequestDialogOpen,
  setIsRequestDialogOpen,
  setHasChangedSelection,
  hasQueries,
  rawFilters,
  setRawFilters,
  hasRequestedData,
  setHasRequestedData,
}: {
  selectedInstance: Instance | undefined;
  setIsRequestDialogOpen: (a: boolean) => void;
  chartContext: ChartContext;
  setHasChangedSelection: (a: boolean) => void;
  isRequestDialogOpen: boolean;
  hasQueries: boolean;
  rawFilters: RawFilterSelection;
  setRawFilters: Dispatch<SetStateAction<RawFilterSelection>>;
  hasRequestedData: boolean;
  setHasRequestedData: Dispatch<SetStateAction<boolean>>;
}) => {
  const [selectedTab, setSelectedTab] = useState(DEFAULT_TAB);

  // Define pagination and sorting hooks to not reset them when switching tabs
  const summaryPagination = usePagination();
  const detailsPagination = usePagination();
  const summarySorting = useSorting('Summary');
  const detailsSorting = useSorting('Details');

  const onTabChange = (tab: typeof DEFAULT_TAB) => {
    // Reset detailed query when switching tabs
    setRawFilters((f) => ({ ...f, query: undefined }));
    setSelectedTab(tab);
    track(OPS_EVENTS.LOGS_VIEW, { view: tab });
  };

  return (
    selectedInstance && (
      <div className="logs m-4">
        <DataGridHelpers.Wrapper>
          <LogsContextProvider
            value={{
              setIsRequestDialogOpen,
              selectedTab,
              setSelectedTab,
              rawFilters,
              setRawFilters,
              hasRequestedData,
              setHasRequestedData,
              chartTimeRange: {
                startTime: chartContext.timeRange.startTime,
                endTime: chartContext.timeRange.endTime,
              },
            }}
          >
            <div className="mx-4 mt-4 flex justify-between">
              <div className="flex items-center">
                <Tabs value={selectedTab} onChange={onTabChange} className="logs-tab-header w-fit" fill="filled">
                  {TAB_OPTIONS.map((t) => (
                    <Tabs.Tab tabId={t.key} key={t.key}>
                      {t.text}
                    </Tabs.Tab>
                  ))}
                </Tabs>
              </div>
              {selectedInstance.tier !== TIER.FREE && <InitiateDownloadButtonWithModal />}
            </div>
            <Tabs.TabPanel value={selectedTab} tabId="SUMMARY">
              <AuraLogsSummary
                selectedDbmsId={selectedInstance.id}
                pagination={summaryPagination}
                sorting={summarySorting}
              />
            </Tabs.TabPanel>
            <Tabs.TabPanel value={selectedTab} tabId="DETAILS">
              <AuraLogsDetails
                selectedDbmsId={selectedInstance.id}
                pagination={detailsPagination}
                sorting={detailsSorting}
              />
            </Tabs.TabPanel>
            {isRequestDialogOpen && (
              <QueryLogFilters
                setIsRequestDialogOpen={setIsRequestDialogOpen}
                hasQueries={hasQueries}
                chartContext={chartContext}
                setHasChangedSelection={setHasChangedSelection}
                selectedInstance={selectedInstance}
              />
            )}
          </LogsContextProvider>
        </DataGridHelpers.Wrapper>
      </div>
    )
  );
};

export const UpgradeQueryLogOverlay = ({ instance }: { instance: Instance | undefined }) => {
  const activeProject = useActiveProject();
  const upgradeModal = useModal(MODAL_TYPE.UPGRADE);
  return (
    <UpgradeOverlay
      title="Upgrade to Professional for logs"
      body="Upgrading to professional enables access to a broad range of different instance and database logs."
      onUpgrade={() => instance && upgradeModal.open({ project: activeProject, instance })}
    >
      <FakeQueryLogs />
    </UpgradeOverlay>
  );
};

export const QueryLogsView = ({
  isInstancesLoading,
  instanceSummaries,
  selectedInstanceSummary,
  selectedInstance,
  hasRequestedData,
  setHasRequestedData,
}: {
  isInstancesLoading: boolean;
  instanceSummaries: InstanceSummary[];
  selectedInstanceSummary: InstanceSummary | undefined;
  selectedInstance: Instance | undefined;
  hasRequestedData: boolean;
  setHasRequestedData: Dispatch<SetStateAction<boolean>>;
}) => {
  const [isRequestDialogOpen, setIsRequestDialogOpen] = useState(false);
  const [hasChangedSelection, setHasChangedSelection] = useState<boolean>(true);
  const [hasQueries, setHasQueries] = useState(false);
  const { rawFilters, setRawFilters } = useRawFilters();
  const timeRangeFromFilters = rawFilters.timeRange;
  const checkedTimeRange = timeRangeFromFilters.startTime
    ? {
        startTime: timeRangeFromFilters.startTime,
        endTime: timeRangeFromFilters.endTime,
      }
    : undefined;
  const { chartContext, setTimePeriod, zoomIn, refreshTimeRange, setExpandedChart } = useMetricsViewState({
    timeRangeDerived: checkedTimeRange,
  });

  if (isInstancesLoading) {
    return (
      <Center>
        <LoadingSpinner size="large" />
      </Center>
    );
  }

  if (instanceSummaries.length === 0 || !selectedInstanceSummary) {
    return (
      <NoInstancesOverlay
        title="No instances found"
        body="Create an instance to enable access to a broad range of different instance and database logs."
      >
        <FakeQueryLogs />
      </NoInstancesOverlay>
    );
  }

  return (
    <div>
      {!selectedInstance && (
        <Center className="mt-[10%]">
          <LoadingSpinner size="large" />
        </Center>
      )}
      {selectedInstance && selectedInstance.tier === TIER.FREE && (
        <UpgradeQueryLogOverlay instance={selectedInstance} />
      )}
      {selectedInstance && selectedInstance.tier !== TIER.FREE && (
        <>
          <QueryTimeline
            selectedInstance={selectedInstance}
            chartContext={chartContext}
            setTimePeriod={setTimePeriod}
            zoomIn={zoomIn}
            refreshTimeRange={refreshTimeRange}
            setExpandedChart={setExpandedChart}
            setIsRequestDialogOpen={setIsRequestDialogOpen}
            setHasChangedSelection={setHasChangedSelection}
            hasChangedSelection={hasChangedSelection}
            setHasQueries={setHasQueries}
          />
          <QueryLogs
            selectedInstance={selectedInstance}
            chartContext={chartContext}
            isRequestDialogOpen={isRequestDialogOpen}
            setIsRequestDialogOpen={setIsRequestDialogOpen}
            setHasChangedSelection={setHasChangedSelection}
            hasQueries={hasQueries}
            rawFilters={rawFilters}
            setRawFilters={setRawFilters}
            hasRequestedData={hasRequestedData}
            setHasRequestedData={setHasRequestedData}
          />
        </>
      )}
    </div>
  );
};
