import { isNullish } from '@nx/stdlib';
import { debounce } from 'lodash-es';
import {
  combineMergers,
  trimergeArrayCreator,
  trimergeEquality,
  trimergeJsonDeepEqual,
  trimergeJsonObject,
} from 'trimerge';

import { securityError } from '../../constants';
import { log } from '../../services/logging';
import { fetchPerspective, fetchPerspectiveSha, storePerspective } from '../../services/perspectives';
import { getDatabases, getDbmsId, getServerVersion, getUserId } from '../connections/connectionsDuck';
import { refreshNodePerCategories } from '../graph/nodes';
import { refreshRelationshipPerTypes } from '../graph/relationships';
import {
  addErrorNotification,
  getErrorNotificationsByTarget,
  removeErrorNotification,
} from '../notifications/notifications';
import { getLatestPerspectiveVersion } from './migrations';
import {
  getOriginalPerspective,
  loadPerspective,
  updatePerspectiveHistory,
  updatePerspectiveSha,
} from './perspectives';
import { getPerspective } from './perspectives.selector';
import { validatePerspectiveAgainstCompleteSchema } from './validation/validations';

const errorsToIgnore = ['Neo.ClientError.Procedure.ProcedureCallFailed', securityError.SECURITY_FORBIDDEN_ERROR];

const PLUGIN_ERROR_PERSPECTIVE_CONFLICT = 'PERSPECTIVE_CONFLICT';
const DEBOUNCE_INTERVAL = 1000;

export const clearGdsStyleRulesFromPerspective = (perspective) => {
  if (perspective.categories) {
    for (const category of perspective.categories) {
      if (category.styleRules) {
        category.styleRules = category.styleRules.filter((styleRule) => !styleRule.isGdsRule);
      }
    }
  }
  return perspective;
};

export const transformPerspectiveForExport = (perspective, isFileExport = false) => {
  let versionedPerspective = { ...perspective, version: getLatestPerspectiveVersion() };

  // Don't export database info, perspective will be tied to the database we import to
  delete versionedPerspective.dbmsId;
  delete versionedPerspective.dbmsVersion;
  delete versionedPerspective.dbId;
  delete versionedPerspective.dbName;
  delete versionedPerspective.isPlugin;
  if (isFileExport) {
    delete versionedPerspective.history;
  }

  versionedPerspective = clearGdsStyleRulesFromPerspective(versionedPerspective);
  return versionedPerspective;
};

const fetchAndUpdatePerspectiveSha = async (database, perspectiveId, store) => {
  const { sha, id } = await fetchPerspectiveSha({ database, perspectiveId });
  store.dispatch(updatePerspectiveSha({ sha, perspectiveId: id }));
};

const perspectiveMerger = combineMergers(
  trimergeEquality,
  trimergeJsonObject,
  trimergeJsonDeepEqual,
  trimergeArrayCreator(
    (item) =>
      item.id ||
      item.name ||
      item.propertyKey || // Properties
      item.basedOn + item.condition || // Style Rules
      item.userId, // Perspective History
    true,
  ),
  (_orig, _left, _right, path = []) => {
    return _right || _left;
  },
);

const isCurrentPerspective = (perspective, store) => {
  const state = store.getState();
  const currentPerspective = getPerspective(state);
  return currentPerspective?.id && perspective.id === currentPerspective.id;
};

export const mergeAndUpdatePerspectives = debounce(async (perspective, database, store, onError) => {
  let sha = null;

  try {
    const shaObj = await fetchPerspectiveSha({ database, perspectiveId: perspective.id });
    sha = shaObj?.sha;
  } catch (e) {
    log.info('Error when fetching perspective from db');
    log.info(e);
    onError && onError(e);
  }

  if (!sha || sha === perspective.sha) {
    return;
  }

  let dbPerspective = null;
  try {
    dbPerspective = await fetchPerspective({ database, perspectiveId: perspective.id });
  } catch (e) {
    log.info('Error when fetching perspective from db');
    log.info(e);
    onError && onError(e);
    return;
  }

  const state = store.getState();
  const dbmsId = getDbmsId(state);
  const serverVersion = getServerVersion(state);
  const originalPerspective = { ...getOriginalPerspective(state), sha: null };
  const currentPerspective = { ...perspective, sha: null };
  try {
    const mergedPerspective = perspectiveMerger(originalPerspective, dbPerspective, currentPerspective);

    const perspectiveWithDbInfo = {
      ...mergedPerspective,
      sha: dbPerspective.sha,
      dbmsId,
      dbmsVersion: serverVersion,
      dbId: database.id,
      dbName: database.name,
      isPlugin: true,
    };

    store.dispatch(loadPerspective(perspectiveWithDbInfo));
    store.dispatch(refreshNodePerCategories(perspectiveWithDbInfo.categories));
    store.dispatch(refreshRelationshipPerTypes());
  } catch (e) {
    log.info('Error when merging the perspective');
    log.info(e);
    onError && onError(e);
  }
}, DEBOUNCE_INTERVAL);

const sendErrorNotification = async (result, perspective, store) => {
  const msg = `Perspective could not be stored! ${result.error ? result.error.message : result.message}`;
  const existingErrors = getErrorNotificationsByTarget(store.getState(), perspective.id);
  if (!existingErrors.find(({ message }) => message === msg)) {
    store.dispatch(addErrorNotification({ type: 'perspective/sync', target: perspective.id, message: msg }));
  }
};

const removePerspectiveErrorNotifications = async (perspective, store) => {
  getErrorNotificationsByTarget(store.getState(), perspective.id)
    .filter(({ type }) => type === 'perspective/sync')
    .forEach(({ id }) => store.dispatch(removeErrorNotification(id)));
};

export const storePerspectiveToDb = debounce(
  (perspective, store, state, shouldUpdatePerspectiveHistory, setPerspectiveFetcher) => {
    const { dbId } = perspective; // transformPerspectiveForExport deletes the dbId!
    perspective = transformPerspectiveForExport(perspective, false);
    perspective = validatePerspectiveAgainstCompleteSchema(perspective);

    if (isNullish(perspective)) {
      return;
    }

    const databases = getDatabases(state);
    const database = databases.find(({ id }) => id === dbId);

    storePerspective({
      perspective,
      database,
      responseHandler: (result) => {
        const code = result.error && result.error.code;
        if (!result.success && !errorsToIgnore.includes(code)) {
          if (result.code === PLUGIN_ERROR_PERSPECTIVE_CONFLICT) {
            if (isCurrentPerspective(perspective, store)) {
              mergeAndUpdatePerspectives(perspective, database, store);
            }
          } else {
            sendErrorNotification(result, perspective, store);
            setPerspectiveFetcher(store);
          }
        } else {
          // get rid of all sync error notifications
          if (isCurrentPerspective(perspective, store) && !code) {
            fetchAndUpdatePerspectiveSha(database, perspective.id, store).then(() => {
              if (shouldUpdatePerspectiveHistory) {
                store.dispatch(
                  updatePerspectiveHistory({
                    userId: getUserId(state),
                    timestamp: Date.now(),
                    perspectiveId: perspective.id,
                  }),
                );
              }
            });
          }
          removePerspectiveErrorNotifications(perspective, store);
          setPerspectiveFetcher(store);
        }
      },
    });
  },
  DEBOUNCE_INTERVAL,
);
