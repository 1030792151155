import type { PaginationInfo, PaginationInput, SORT_ORDER } from './logs';

export enum DEPRECATION_LOG_AGGREGATION_SORT_PROPERTY {
  COUNT = 'COUNT',
  DEPRECATION_NOTIFICATIONS = 'DEPRECATION_NOTIFICATIONS',
  LAST_TIMESTAMP = 'LAST_TIMESTAMP',
  QUERY = 'QUERY',
}

export enum CLIENT {
  AcLaravel = 'AC_LARAVEL',
  Bloom = 'BLOOM',
  Browser = 'BROWSER',
  CypherShell = 'CYPHER_SHELL',
  DatabricksConnector = 'DATABRICKS_CONNECTOR',
  Desktop = 'DESKTOP',
  Dotnet = 'DOTNET',
  Goc = 'GOC',
  Golang = 'GOLANG',
  Graphql = 'GRAPHQL',
  GraphqlJs = 'GRAPHQL_JS',
  Import = 'IMPORT',
  Java = 'JAVA',
  Javascript = 'JAVASCRIPT',
  KafkaConnect = 'KAFKA_CONNECT',
  KeymakerAdminApiExecution = 'KEYMAKER_ADMIN_API_EXECUTION',
  LiqubaseNeo4J = 'LIQUBASE_NEO4J',
  Neobolt = 'NEOBOLT',
  Neodash = 'NEODASH',
  Neomodel = 'NEOMODEL',
  OpsManager = 'OPS_MANAGER',
  PhpClient = 'PHP_CLIENT',
  Py2Neo = 'PY2NEO',
  Python = 'PYTHON',
  Query = 'QUERY',
  Ruby = 'RUBY',
  SolutionsWorkbenchApi = 'SOLUTIONS_WORKBENCH_API',
  SparkConnector = 'SPARK_CONNECTOR',
  Unknown = 'UNKNOWN',
  Workspace = 'WORKSPACE',
}

export enum CLIENT_TYPE {
  Application = 'APPLICATION',
  Driver = 'DRIVER',
}

export enum DRIVER_VERSION_STATUS {
  CommunityDriver = 'COMMUNITY_DRIVER',
  Compatible = 'COMPATIBLE',
  DeprecatedDriver = 'DEPRECATED_DRIVER',
  Incompatible = 'INCOMPATIBLE',
  UnknownDriver = 'UNKNOWN_DRIVER',
  UnknownDriverVersion = 'UNKNOWN_DRIVER_VERSION',
  UpgradeRecommended = 'UPGRADE_RECOMMENDED',
}

// Request bodies

export type DeprecationLogFilterValuesBody = {
  tenantId: string;
  dbmsId: string;
  from: number;
  to: number;
};

export type DeprecationLogAggregationsBody = {
  tenantId: string;
  dbmsId: string;
  args: DeprecationLogAggregationArgs;
};

export type DeprecationLogAggregationArgs = {
  filter: DeprecationLogFilterInput;
  pagination: PaginationInput;
  sort: DeprecationLogAggregationSortInput[];
};

export type DeprecationLogFilterInput = {
  apps: string[];
  databases: string[];
  deprecationNotificationNames: string[];
  drivers: string[];
  from: number;
  initiationTypes: string[];
  querySearchString: string;
  to: number;
  users: string[];
};

export type DeprecationLogAggregationSortInput = {
  sortOrder: SORT_ORDER;
  property: DEPRECATION_LOG_AGGREGATION_SORT_PROPERTY;
};

export type DriverLogAggregationsBody = {
  tenantId: string;
  dbmsId: string;
  from: number;
  to: number;
};

// Response payloads

export type DeprecationLogFilterValues = {
  apps: string[];
  databases: string[];
  deprecationNotificationNames: string[];
  drivers: string[];
  initiationTypes: string[];
  users: string[];
};

export type PaginatedDeprecationLogAggregations = {
  logAggregations: DeprecationLogAggregation[];
  pageInfo: PaginationInfo;
};

export type DeprecationLogAggregation = {
  apps: string[];
  databases: string[];
  deprecationNotifications: string;
  drivers: string[];
  executionCount: number;
  initiationTypes: string[];
  lastTimestamp: number;
  query: string;
  users: string[];
};

export type DriverLogAggregation = {
  app?: string;
  client: CLIENT;
  clientType: CLIENT_TYPE;
  databases: string[];
  driverVersion?: string;
  driverVersionStatus: DRIVER_VERSION_STATUS;
  initiationTypes: string[];
  lastTimestamp: number;
  queryCount: number;
  rawDriver?: string;
  users: string[];
};
