import { METRIC_TYPE } from '@nx/state';

import { combineOptions } from '../plot/utils';
import type { ChartWidgetPartialProps } from './fullstack-chart-props';
import {
  BigCountersConfig,
  IntChartConfig,
  PercentageChartConfig,
  RateChartConfig,
  SmallPercentageChartConfig,
  TimeChartConfig,
  plotOptionsCumulativeDurationsFormatter,
  toPercentage,
} from './helpers';

export const HeapUsedChartProps: ChartWidgetPartialProps = {
  title: 'Heap',
  subtitle: 'Used',
  yLabel: 'Memory',
  metricName: 'neo4j_dbms_vm_heap_used_ratio',
  metricType: METRIC_TYPE.Gauge,
  chartType: 'INSTANCE',
  valueMapper: toPercentage,
  ...PercentageChartConfig,
};

export const HitRatioChartProps: ChartWidgetPartialProps = {
  title: 'Page Cache',
  subtitle: 'Hit Ratio',
  yLabel: 'Ratio %',
  metricType: METRIC_TYPE.Gauge,
  metricName: 'neo4j_dbms_page_cache_hit_ratio_per_minute',
  chartType: 'INSTANCE',
  valueMapper: toPercentage,
  aggregations: ['AVG'],
  ...PercentageChartConfig,
};

export const UsageRatioChartProps: ChartWidgetPartialProps = {
  title: 'Page Cache',
  subtitle: 'Usage Ratio',
  yLabel: 'Ratio %',
  metricType: METRIC_TYPE.Gauge,
  metricName: 'neo4j_dbms_page_cache_usage_ratio',
  chartType: 'INSTANCE',
  valueMapper: toPercentage,
  ...PercentageChartConfig,
};

export const PageCacheEvictionsChartProps: ChartWidgetPartialProps = {
  title: 'Page Cache Evictions',
  yLabel: 'Evictions per minute',
  metricName: 'neo4j_dbms_page_cache_evictions_per_minute',
  cmiMetricName: 'neo4j_dbms_page_cache_evictions_total',
  metricType: METRIC_TYPE.Gauge,
  chartType: 'INSTANCE',
  chartPathType: 'BAR',
  aggregations: ['MAX'],
  ...BigCountersConfig,
};

export const BoltConnRunningChartProps: ChartWidgetPartialProps = {
  title: 'Bolt Connections',
  subtitle: 'Running',
  yLabel: 'Count',
  metricType: METRIC_TYPE.Gauge,
  metricName: 'neo4j_dbms_bolt_connections_running',
  chartType: 'INSTANCE',
  aggregations: ['MIN', 'MAX', 'SUM'],
  ...IntChartConfig,
};

export const BoltConnIdleChartProps: ChartWidgetPartialProps = {
  title: 'Bolt Connections',
  subtitle: 'Idle',
  yLabel: 'Count',
  metricType: METRIC_TYPE.Gauge,
  metricName: 'neo4j_dbms_bolt_connections_idle',
  chartType: 'INSTANCE',
  aggregations: ['SUM', 'MAX', 'MIN'],
  ...IntChartConfig,
};

export const BoltConnClosedChartProps: ChartWidgetPartialProps = {
  title: 'Bolt Connections',
  subtitle: 'Closed (count)',
  yLabel: 'Count',
  metricType: METRIC_TYPE.Gauge,
  metricName: 'neo4j_dbms_bolt_connections_closed_total',
  chartType: 'INSTANCE',
  aggregations: ['SUM', 'MAX', 'MIN'],
  ...IntChartConfig,
};

export const BoltConnClosedRateChartProps: ChartWidgetPartialProps = {
  title: 'Bolt Connections',
  subtitle: 'Closed (rate)',
  yLabel: 'Per minute',
  metricType: METRIC_TYPE.Gauge,
  metricName: 'neo4j_dbms_bolt_connections_closed_per_minute',
  cmiMetricName: 'NO_CMI_METRIC',
  chartType: 'INSTANCE',
  aggregations: ['SUM', 'MAX', 'MIN'],
  ...RateChartConfig,
};

export const BoltConnOpenedChartProps: ChartWidgetPartialProps = {
  title: 'Bolt Connections',
  subtitle: 'Opened (count)',
  yLabel: 'Count',
  metricType: METRIC_TYPE.Counter,
  metricName: 'neo4j_dbms_bolt_connections_opened_total',
  chartType: 'INSTANCE',
  aggregations: ['SUM', 'MAX', 'MIN'],
  ...IntChartConfig,
};

export const BoltConnOpenedRateChartProps: ChartWidgetPartialProps = {
  title: 'Bolt Connections',
  subtitle: 'Opened (rate)',
  yLabel: 'Per minute',
  metricType: METRIC_TYPE.Counter,
  metricName: 'neo4j_dbms_bolt_connections_opened_per_minute',
  cmiMetricName: 'NO_CMI_METRIC',
  chartType: 'INSTANCE',
  aggregations: ['SUM', 'MAX', 'MIN'],
  ...RateChartConfig,
};

export const GarbageChartProps: ChartWidgetPartialProps = {
  title: 'Garbage Collection',
  subtitle: 'Total (cumulative)',
  yLabel: 'Cumulative time',
  metricType: METRIC_TYPE.Counter,
  metricName: 'neo4j_dbms_vm_gc_time_total',
  cmiMetricName: 'NO_CMI_METRIC',
  chartType: 'INSTANCE',
  ...TimeChartConfig,
  uplotOptions: combineOptions(TimeChartConfig.uplotOptions, plotOptionsCumulativeDurationsFormatter),
};

export const GarbageChartRateProps: ChartWidgetPartialProps = {
  title: 'Garbage Collection',
  subtitle: 'Total (%)',
  yLabel: 'Time spent in GC (%)',
  metricType: METRIC_TYPE.Counter,
  metricName: 'neo4j_dbms_vm_gc_time_ratio',
  cmiMetricName: 'NO_CMI_METRIC',
  chartType: 'INSTANCE',
  ...SmallPercentageChartConfig,
};

export const GarbageYoungChartProps: ChartWidgetPartialProps = {
  title: 'Garbage Collection',
  subtitle: 'Young Gen (cumulative)',
  yLabel: 'Cumulative time',
  metricType: METRIC_TYPE.Counter,
  metricName: 'neo4j_dbms_vm_gc_time_g1_young_generation_total',
  chartType: 'INSTANCE',
  ...TimeChartConfig,
  uplotOptions: combineOptions(TimeChartConfig.uplotOptions, plotOptionsCumulativeDurationsFormatter),
};

export const GarbageYoungRateChartProps: ChartWidgetPartialProps = {
  title: 'Garbage Collection',
  subtitle: 'Young Gen (%)',
  yLabel: 'Time spent in GC (%)',
  metricType: METRIC_TYPE.Counter,
  metricName: 'neo4j_dbms_vm_gc_time_g1_young_generation_ratio',
  cmiMetricName: 'NO_CMI_METRIC',
  chartType: 'INSTANCE',
  ...SmallPercentageChartConfig,
};

export const GarbageOldChartProps: ChartWidgetPartialProps = {
  title: 'Garbage Collection',
  subtitle: 'Old Gen (cumulative)',
  yLabel: 'Cumulative time',
  metricType: METRIC_TYPE.Counter,
  metricName: 'neo4j_dbms_vm_gc_time_g1_old_generation_total',
  chartType: 'INSTANCE',
  ...TimeChartConfig,
  uplotOptions: combineOptions(TimeChartConfig.uplotOptions, plotOptionsCumulativeDurationsFormatter),
};

export const GarbageOldRateChartProps: ChartWidgetPartialProps = {
  title: 'Garbage Collection',
  subtitle: 'Old Gen (%)',
  yLabel: 'Time spent in GC (%)',
  metricType: METRIC_TYPE.Counter,
  metricName: 'neo4j_dbms_vm_gc_time_g1_old_generation_ratio',
  cmiMetricName: 'NO_CMI_METRIC',
  chartType: 'INSTANCE',
  ...SmallPercentageChartConfig,
};

export const AuraInstanceCharts = [
  HeapUsedChartProps,
  [HitRatioChartProps, UsageRatioChartProps],
  PageCacheEvictionsChartProps,
  [
    BoltConnRunningChartProps,
    BoltConnOpenedChartProps,
    BoltConnOpenedRateChartProps,
    BoltConnClosedChartProps,
    BoltConnClosedRateChartProps,
    BoltConnIdleChartProps,
  ],
  [
    GarbageChartRateProps,
    GarbageChartProps,
    GarbageOldRateChartProps,
    GarbageOldChartProps,
    GarbageYoungRateChartProps,
    GarbageYoungChartProps,
  ],
];
