import type {
  DeprecationLogAggregationsBody,
  DeprecationLogFilterValues,
  DeprecationLogFilterValuesBody,
  DriverLogAggregation,
  DriverLogAggregationsBody,
  PaginatedDeprecationLogAggregations,
} from '../api-types/migration';
import { transformDeprecationLogFilterValuesResponse } from '../transformers/migration';
import type { AvailableDbmsFilters } from '../types/migration';
import type { Builder } from './types';

export const migrationEndpoints = (builder: Builder) => ({
  getDeprecationLogFilterValues: builder.query<AvailableDbmsFilters, DeprecationLogFilterValuesBody>({
    query: (body) => ({
      url: '/migration/deprecation/logs/filter-values',
      method: 'POST',
      body,
      headers: { 'Project-Id': body.tenantId },
    }),
    transformResponse: (res: DeprecationLogFilterValues) => transformDeprecationLogFilterValuesResponse(res),
  }),
  getDeprecationLogAggregations: builder.query<PaginatedDeprecationLogAggregations, DeprecationLogAggregationsBody>({
    query: (body) => ({
      url: '/migration/deprecation/logs/summary',
      method: 'POST',
      body,
      headers: { 'Project-Id': body.tenantId },
    }),
  }),
  getDriverLogAggregations: builder.query<DriverLogAggregation[], DriverLogAggregationsBody>({
    query: (body) => ({
      url: '/migration/driver/logs/summary',
      method: 'POST',
      body,
      headers: { 'Project-Id': body.tenantId },
    }),
  }),
});
