import type { Nullable } from '../../types/utility';

export interface Result {
  error?: {
    message?: string;
  };
  summary?: {
    notifications?: unknown;
    plan?: Plan;
  };
}

interface Plan {
  arguments?: {
    EstimatedRows?: number;
  };
  children?: Plan[];
}

export const mapCypherSummary = (result: Result) => {
  if (result.error != null) {
    return [
      {
        severity: 'ERROR',
        title: result.error.message ?? result.error,
      },
    ];
  }
  return result.summary?.notifications ?? [];
};

export const getEstimatedRows = (result: Nullable<Result>, stopLimit = 1000) => {
  if (result?.summary?.plan != null) {
    const estimatedRowsList: number[] = [];
    traverseExecutionPlan(result.summary.plan, stopLimit, estimatedRowsList);
    return estimatedRowsList;
  }
  return null;
};

const traverseExecutionPlan = (plan: Plan, stopLimit: number, estimatedRowsList: number[]) => {
  let planEstimatedRows = stopLimit;
  if (plan.arguments?.EstimatedRows != null) {
    planEstimatedRows = plan.arguments.EstimatedRows;
    estimatedRowsList.push(planEstimatedRows);
  }

  if (planEstimatedRows < stopLimit && plan.children != null) {
    plan.children.forEach((childPlan) => traverseExecutionPlan(childPlan, stopLimit, estimatedRowsList));
  }
};
