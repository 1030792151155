import type { GraphStyling, NodeStyling, RelationStyling } from '@nx/state';
import {
  addStateEventListener,
  LEGACY_store as frameworkStore,
  selectNvlStyling,
  stylingUpdateForLabel,
  stylingUpdateForRelType,
} from '@nx/state';
import { isAnyOf } from '@reduxjs/toolkit';

import { SIZE_OPTIONS } from '../../modules/Legend/Popups/common/size-picker';
import { radiusToSize, sizeToRadius } from '../../modules/Visualization/mapper';
import type { Perspective } from '../../types/perspective';
import { PerspectiveType } from '../../types/perspective';
import type { Style, StyleCategory, StyleRelationshipType } from '../../types/style';
import type { Nullable } from '../../types/utility';
import { SET_CURRENT_PERSPECTIVE } from '../perspectives/perspectives';
import { getPerspective, getPerspectiveById } from '../perspectives/perspectives.selector';
import type { AppDispatch, AppMiddleware, RootState } from '../types';
import {
  addCategories,
  addRelationshipTypes,
  getCurrentStyle,
  getStyleById,
  updateColorForCategory,
  updateColorForRelType,
  updateSizeForCategory,
  updateSizeForRelType,
} from './styles';

const MIN_SIZE = SIZE_OPTIONS[0] as number;
const MAX_SIZE = SIZE_OPTIONS[SIZE_OPTIONS.length - 1] as number;

const clamp = (num: number, min: number, max: number) => Math.max(min, Math.min(max, num));

type CategoryVisitor = (
  style: Style,
  category: StyleCategory,
  frameworkStyling: Partial<NodeStyling>,
  dispatch: AppDispatch,
) => void;
type RelTypeVisitor = (
  style: Style,
  relType: StyleRelationshipType,
  frameworkStyling: Partial<RelationStyling>,
  dispatch: AppDispatch,
) => void;

interface SyncParams {
  newColor?: string;
  newSize?: number;
  categoryId?: number;
  relType?: string;
  styleId: string;
}

const syncFactory =
  (categoryVisitor: Nullable<CategoryVisitor>, relTypeVisitor: Nullable<RelTypeVisitor>) =>
  (perspective: Perspective, appState: RootState, dispatch: AppDispatch) => {
    const { id: perspectiveId, categories: perspectiveCats, relationshipTypes: perspectiveRels, type } = perspective;

    if (type !== PerspectiveType.DEFAULT) {
      return;
    }

    const frameworkState = frameworkStore.getState();
    const frameworkStyling: GraphStyling = selectNvlStyling(frameworkState);
    const { node, relationship } = frameworkStyling;
    const appStyling: Style = getStyleById(appState)(perspectiveId)!;
    const { categories = [], relationshipTypes = [] } = appStyling;

    if (categoryVisitor) {
      categories.forEach((cat) => {
        const { id: categoryId } = cat;
        const { labels } = perspectiveCats.find((c) => c.id === categoryId) ?? {};
        const label = (labels ?? [''])[0] ?? '';
        const nodeStyling = node[label];
        if (cat !== undefined && nodeStyling !== undefined) {
          categoryVisitor(appStyling, cat, nodeStyling, dispatch);
        }
      });
    }

    if (relTypeVisitor) {
      relationshipTypes.forEach((rel) => {
        const { id: relType } = rel;
        const { name = '' } = perspectiveRels.find((r) => r.id === relType) ?? {};
        const relStyling = relationship[name];
        if (rel !== undefined && relStyling !== undefined) {
          relTypeVisitor(appStyling, rel, relStyling, dispatch);
        }
      });
    }
  };

const updateCategoryFromFramework = (
  style: Style,
  category: StyleCategory,
  frameworkStyling: Partial<NodeStyling>,
  dispatch: AppDispatch,
) => {
  const { id: styleId } = style;
  const { id: categoryId, color, size } = category;
  const { color: frameworkColor, size: frameworkSize } = frameworkStyling;
  if (frameworkColor !== undefined && frameworkColor !== color) {
    dispatch(updateColorForCategory({ styleId, categoryId, newColor: frameworkColor }));
  }
  if (frameworkSize) {
    const mappedSize = radiusToSize(frameworkSize);
    const clampSize = clamp(mappedSize, MIN_SIZE, MAX_SIZE);
    if (Number.isFinite(clampSize) && clampSize !== size) {
      dispatch(updateSizeForCategory({ styleId, categoryId, newSize: clampSize }));
    }
  }
};

const updateRelTypeFromFramework = (
  style: Style,
  relType: StyleRelationshipType,
  frameworkStyling: Partial<RelationStyling>,
  dispatch: AppDispatch,
) => {
  const { id: styleId } = style;
  const { id, color, size } = relType;
  const { color: frameworkColor, width: frameworkSize } = frameworkStyling;
  if (frameworkColor !== undefined && frameworkColor !== color) {
    dispatch(updateColorForRelType({ styleId, relType: id, newColor: frameworkColor }));
  }
  if (frameworkSize) {
    const clampSize = clamp(frameworkSize, MIN_SIZE, MAX_SIZE);
    if (Number.isFinite(clampSize) && clampSize !== size) {
      dispatch(updateSizeForRelType({ styleId, relType: id, newSize: clampSize }));
    }
  }
};

export const syncStyleFromFramework = syncFactory(updateCategoryFromFramework, updateRelTypeFromFramework);
const syncCategoriesFromFramework = syncFactory(updateCategoryFromFramework, null);
const syncRelTypesFromFramework = syncFactory(null, updateRelTypeFromFramework);

export const syncStyleToFramework = (params: SyncParams, state: RootState) => {
  const { newColor, newSize, categoryId, relType, styleId } = params;
  const { id } = (styleId ? getStyleById(state)(styleId) : getCurrentStyle(state)) ?? {};
  if (!id) return;
  const perspective = getPerspectiveById(state)(id);
  if (!perspective) return;

  const { categories, relationshipTypes, type } = perspective;

  if (type !== PerspectiveType.DEFAULT) return;

  if (categoryId !== undefined) {
    const { labels } = categories.find((c) => c.id === categoryId) ?? {};
    const label = (labels ?? [''])[0] ?? '';
    const mappedSize = newSize ? sizeToRadius(newSize) : null;
    frameworkStore.dispatch(
      stylingUpdateForLabel({
        label,
        styling: {
          ...(newColor ? { color: newColor } : {}),
          ...(mappedSize ? { size: mappedSize } : {}),
        },
      }),
    );
  }

  if (relType !== undefined) {
    const { name = '' } = relationshipTypes.find((r) => r.id === relType) ?? {};
    frameworkStore.dispatch(
      stylingUpdateForRelType({
        relType: name,
        styling: {
          ...(newColor ? { color: newColor } : {}),
          ...(newSize ? { width: newSize } : {}),
        },
      }),
    );
  }
};

export const initiateStyleSync = (getState: () => RootState, dispatch: AppDispatch) => {
  addStateEventListener('stylingUpdate', (_listenerApi) => {
    const state = getState();
    const perspective = getPerspective(state);
    if (!perspective) return;
    syncStyleFromFramework(perspective, state, dispatch);
  });
};

export const syncMiddleware: AppMiddleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    const nextResult = next(action);
    const state = getState();

    const { perspectiveId, type } = action as { perspectiveId: string; type: string };
    if (type === SET_CURRENT_PERSPECTIVE && perspectiveId !== undefined) {
      const perspective = getPerspectiveById(state)(perspectiveId);
      if (!perspective) return nextResult;
      syncStyleFromFramework(perspective, state, dispatch);
    }

    if (isAnyOf(addCategories, addRelationshipTypes)(action)) {
      const { styleId } = action.payload;
      const { id } = (styleId ? getStyleById(state)(styleId) : getCurrentStyle(state)) ?? {};
      if (!id) return nextResult;
      const perspective = getPerspectiveById(state)(id);
      if (!perspective) return nextResult;
      if (isAnyOf(addCategories)(action)) {
        syncCategoriesFromFramework(perspective, state, dispatch);
      } else {
        syncRelTypesFromFramework(perspective, state, dispatch);
      }
    }

    if (isAnyOf(updateColorForCategory, updateSizeForCategory, updateColorForRelType, updateSizeForRelType)(action)) {
      syncStyleToFramework(action.payload as SyncParams, state);
    }

    return nextResult;
  };
