import { useState } from 'react';

import { initialFilters } from '../shared/constants';

export const useRawFilters = () => {
  const [rawFilters, setRawFilters] = useState<ReturnType<typeof initialFilters>>({
    ...initialFilters(),
  });
  return { rawFilters, setRawFilters };
};
