import { Tag, Tooltip } from '@neo4j-ndl/react';
import classNames from 'classnames';
import type { ComponentProps } from 'react';
import { useLayoutEffect, useRef, useState } from 'react';

type SizedTagProps = Omit<ComponentProps<typeof Tag>, 'children'> & {
  tagWidth: number;
  text: string;
  tailLength: number;
  tailWords?: number;
  tooltipContent?: JSX.Element;
  tooltipWidth?: number;
};

export const SizedTag = ({
  tagWidth,
  text,
  tailLength,
  tailWords = 0,
  tooltipContent,
  tooltipWidth,
  className,
  ...rest
}: SizedTagProps) => {
  const [hasEllipsis, setHasEllipsis] = useState(false);
  const tagElRef = useRef<HTMLDivElement | null>(null);
  const ellipsisElRef = useRef<HTMLSpanElement | null>(null);

  useLayoutEffect(() => {
    /**
     * https://stackoverflow.com/a/10017343/14485696 but,
     * offsetWidth is not good for texts that almost almost fit; e.g. nonoccaecati -> nono... + aecati
     * ellipsis element "nono..." has scrollWidth 46, offsetWidth 46, clientRectWidth 45.6640625
     */
    const ellipsisEl = ellipsisElRef.current;
    const tagEl = tagElRef.current;
    if (!ellipsisEl || !tagEl) {
      return;
    }

    const tagWidthMaxed = tagEl.offsetWidth >= tagWidth;
    const elHasEllipsis = tagWidthMaxed && ellipsisEl.scrollWidth > ellipsisEl.getBoundingClientRect().width;

    if (elHasEllipsis) {
      setHasEllipsis(true);
    }
  }, [tagWidth]);

  const escapedText = text.replace(' ', '\u00a0');

  let ellipsisPosition = tailLength;
  if (tailWords) {
    const tailWordsLength = escapedText.split(' ').slice(-tailWords).join(' ').length;
    ellipsisPosition = Math.min(tailWordsLength, ellipsisPosition);
  }

  const left = escapedText.slice(0, -ellipsisPosition);
  const right = escapedText.slice(-ellipsisPosition);

  const TagComponent = () => {
    return (
      <>
        {/* @ts-ignore - needle needs to update its types */}
        <Tag ref={tagElRef} className={classNames('[&>.ndl-remove-icon]:shrink-0', className)} {...rest}>
          <div className="flex overflow-hidden" style={{ maxWidth: tagWidth }}>
            <span ref={ellipsisElRef} className="overflow-hidden text-ellipsis whitespace-nowrap ">
              {left}
            </span>
            <span className="shrink-0">{right}</span>
          </div>
        </Tag>
      </>
    );
  };

  return (
    <>
      {hasEllipsis && (
        <Tooltip placement="top" type="simple">
          <Tooltip.Trigger>
            <TagComponent />
          </Tooltip.Trigger>
          <Tooltip.Content style={{ maxWidth: tooltipWidth }}>{tooltipContent ?? text}</Tooltip.Content>
        </Tooltip>
      )}
      {!hasEllipsis && <TagComponent />}
    </>
  );
};
