import type { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { difference, intersection, isEmpty, isNil, union, unionBy, uniq } from 'lodash-es';

import type { Category } from '../../types/category';
import type { Node } from '../../types/graph';
import { REHYDRATE } from '../persistence/constants';
import { DEFAULT_UNCATEGORISED_ID } from '../perspectives/constants';
import { CLEAR_SCENE } from '../rootActions';
import type { RootState } from '../types';
import {
  addToExpanded,
  addToInventory,
  addToVisible,
  clearExpanded,
  deselectAll as graphDeselectAll,
  invertSelection,
  purgeInvisibleItems,
  removeFromVisible,
  setSelected,
  updateInventory,
} from './graph.actions';
import { getSelectedRelationshipMap } from './relationships';

export const NAME = 'nodes';
const GRAPH = 'graph';

export interface NodesState {
  inventory: Record<string, Node>;
  labelGroups: Record<string, string[]>;
  labelPropertyGroups: Record<string, Record<string, string[]>>;
  labelValueGroups: Record<string, Record<string, Record<string, string[]>>>;
  nodesPerCategory: Record<string, string[]>;
  visible: string[];
  visibleNodes: Node[];
  selected: Record<string, true>;
  expanded: Record<string, boolean>;
  activated: Record<string, boolean>;
  disabled: Record<string, true>;
  selectedArray?: string[];
}

export interface NodeCategory extends Pick<Category, 'id' | 'labels'> {
  name?: string;
}

export const initialState: NodesState = {
  inventory: {},
  labelGroups: {},
  labelPropertyGroups: {},
  labelValueGroups: {},
  nodesPerCategory: {},
  visible: [],
  visibleNodes: [],
  selected: {},
  expanded: {},
  activated: {},
  disabled: {},
};

/**
 * Selectors
 */

export const getNodesState = (state: RootState): NodesState => state[GRAPH].present[NAME];

export function getActivatedNodes(state: RootState): NodesState['activated'] {
  return getNodesState(state)?.activated ?? initialState.activated;
}
export function getNodes(state: RootState): NodesState['inventory'] {
  return getNodesState(state)?.inventory ?? initialState.inventory;
}
export function getSelectedNodeMap(state: RootState): NodesState['selected'] {
  return getNodesState(state)?.selected ?? initialState.selected;
}
export function getDisabledNodeMap(state: RootState): NodesState['disabled'] {
  return getNodesState(state)?.disabled ?? initialState.disabled;
}
export function getLabelGroups(state: RootState): NodesState['labelGroups'] {
  return getNodesState(state)?.labelGroups ?? initialState.labelGroups;
}
export function getVisibleNodeIds(state: RootState): NodesState['visible'] {
  return getNodesState(state)?.visible ?? initialState.visible;
}
export function getVisibleNodes(state: RootState): NodesState['visibleNodes'] {
  return getNodesState(state)?.visibleNodes ?? initialState.visibleNodes;
}
export function getVisibleNodesPerCategory(state: RootState): NodesState['nodesPerCategory'] {
  return getNodesState(state)?.nodesPerCategory ?? initialState.nodesPerCategory;
}

export const getCategoriesForVisibleNodes = createSelector(
  (state: RootState) => getVisibleNodesPerCategory(state),
  (visibleNodes) => Object.keys(visibleNodes),
);
export const getAllSelectedNodes = createSelector(
  (state: RootState) => getNodesState(state),
  (nodesState) => Object.keys(nodesState.selected),
);

export const getSelectedNodeIds = createSelector(getSelectedNodeMap, (selectedNodeMap) => Object.keys(selectedNodeMap));

export const getSelectedRelationshipIds = createSelector(
  (state: RootState) => getSelectedRelationshipMap(state),
  (selectedRelationshipIds) => Object.keys(selectedRelationshipIds),
);

export const getSelectedNodes = createSelector(
  (state: RootState) => getNodesState(state),
  ({ visible, selected, inventory }) => {
    return visible.reduce<Node[]>((nodes, id) => {
      const node = inventory[id];
      return selected[id] && !isNil(node) ? [...nodes, node] : nodes;
    }, []);
  },
);

export const getPropertyValuesOfVisibleNodes = createSelector(
  getNodes,
  getVisibleNodesPerCategory,
  (nodeInventory, visibleNodesPerCategory) => (categoryId: Category['id'] | null, propertyKey: string | null) => {
    const result: string[] = [];
    const visNodesOfCat = !isNil(categoryId) ? visibleNodesPerCategory[categoryId] : undefined;

    if (isNil(visNodesOfCat) || visNodesOfCat.length === 0) return result;

    visNodesOfCat
      .map((nodeId) => nodeInventory[nodeId])
      .forEach((node) => {
        const nodeProperty = !isNil(propertyKey) ? node?.properties?.[propertyKey] : undefined;
        if (nodeProperty?.length === 0) return;
        if (!isNil(nodeProperty)) result.push(nodeProperty);
      });
    return uniq(result);
  },
);

/**
 * Reducer and helpers
 */

export function mapNodes(nodesArray: Node[], stateToAddTo?: NodesState) {
  let inventory: NodesState['inventory'] = {};
  const labelGroups: NodesState['labelGroups'] = {};
  const labelPropertyGroups: NodesState['labelPropertyGroups'] = {};
  const labelValueGroups: NodesState['labelValueGroups'] = {};
  let nodesToMapLabelsOn = nodesArray;

  if (!isNil(stateToAddTo)) {
    inventory = { ...stateToAddTo.inventory };
    nodesToMapLabelsOn = unionBy(nodesArray, Object.values(stateToAddTo.inventory), 'id');
  }

  for (const node of nodesToMapLabelsOn) {
    const nodeId = node.id;
    inventory[nodeId] = node;
    const { labels } = node;
    const propertyKeys = Object.keys(node.properties);

    for (const label of labels) {
      if (!(label in labelGroups)) {
        labelGroups[label] = [];
        labelPropertyGroups[label] = {};
        labelValueGroups[label] = {};
      }
      // @ts-expect-error we are checking for undefined in the code above
      labelGroups[label].push(nodeId);

      for (const prop of propertyKeys) {
        // @ts-expect-error we are checking for undefined in the code above
        if (!(prop in labelPropertyGroups[label])) {
          // @ts-expect-error we are checking for undefined in the code above
          labelPropertyGroups[label][prop] = [];
          // @ts-expect-error we are checking for undefined in the code above
          labelValueGroups[label][prop] = {};
        }
        // @ts-expect-error we are checking for undefined in the code above
        labelPropertyGroups[label][prop].push(nodeId);

        // @ts-expect-error we are checking for undefined in the code above
        if (!(node.properties[prop] in labelValueGroups[label][prop])) {
          // @ts-expect-error we are checking for undefined in the code above
          labelValueGroups[label][prop][node.properties[prop]] = [];
        }
        // @ts-expect-error we are checking for undefined in the code above
        labelValueGroups[label][prop][node.properties[prop]].push(nodeId);
      }
    }
  }

  return {
    inventory,
    labelGroups,
    labelPropertyGroups,
    labelValueGroups,
  };
}

export function getCategoryForNode(node: Pick<Node, 'labels'> | undefined | null, categories: NodeCategory[]) {
  return categories.find((category) => intersection(category.labels, node?.labels ?? []).length > 0);
}

export function getCategoryIdForNode(node: Pick<Node, 'labels'> | undefined | null, categories: NodeCategory[]) {
  const found = getCategoryForNode(node, categories);
  return found?.id ?? DEFAULT_UNCATEGORISED_ID;
}

function getNodesPerCategoryMapper(
  visibleNodesIds: string[],
  nodeInventory: Record<string, Node>,
  categories: NodeCategory[],
) {
  const mappedNodesByCategory = visibleNodesIds.map((nodeId) =>
    getCategoryIdForNode(nodeInventory[nodeId], categories),
  );
  const categoriesIds = uniq(mappedNodesByCategory);
  return categoriesIds.reduce<NodesState['nodesPerCategory']>((acc, val) => {
    acc[val] = mappedNodesByCategory
      .map((n, index) => (n === val ? visibleNodesIds[index] : false))
      .filter((n): n is string => n !== false);
    return acc;
  }, {});
}

function getVisibleNodesMapper(visibleNodesIds: string[], nodeInventory: Record<string, Node>) {
  return visibleNodesIds.reduce<Node[]>((visibleNodeList: Node[], nodeId: string) => {
    const node = nodeInventory[nodeId];
    if (!isNil(node)) {
      visibleNodeList.push(node);
    }
    return visibleNodeList;
  }, []);
}

const nodesSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    updateNodeInventory: (state, action: PayloadAction<{ nodes: Node[]; categories: NodeCategory[] }>) => {
      const { nodes, categories } = action.payload;
      if (isNil(nodes)) return state;
      const { selected, activated, disabled, visible, inventory } = state;
      const updatedNodeIds = nodes.map((node) => node.id);

      const newSelected: NodesState['selected'] = {};
      const newActivated: NodesState['activated'] = {};

      updatedNodeIds.forEach((id) => {
        if (selected[id] && !disabled[id]) {
          newSelected[id] = true;
        }
        if (activated[id] && !disabled[id]) {
          newActivated[id] = true;
        }
      });

      const visibleNodesIds = visible.filter((nodeId) => updatedNodeIds.includes(nodeId));

      const mappedNodes = mapNodes(nodes);
      const newInventory: NodesState['inventory'] = {
        ...inventory,
        ...mappedNodes.inventory,
      };
      const visibleNodeList = getVisibleNodesMapper(visibleNodesIds, newInventory);
      const nodesPerCategory = getNodesPerCategoryMapper(visibleNodesIds, newInventory, categories);

      return {
        ...state,
        ...mappedNodes,
        selected: newSelected,
        selectedArray: Object.keys(newSelected),
        activated: newActivated,
        visible: visibleNodesIds,
        visibleNodes: visibleNodeList,
        nodesPerCategory,
      };
    },
    addToNodeInventory: (state, action: PayloadAction<Node[]>) => {
      const nodes = action.payload;
      if (isNil(nodes)) return state;

      const newState = mapNodes(nodes, state);

      return {
        ...state,
        ...newState,
      };
    },
    removeFromNodeInventory: (state, action: PayloadAction<Node['id'][]>) => {
      const nodeIds = action.payload;
      if (isNil(nodeIds)) return state;
      const { selected, activated, disabled, visible, visibleNodes, inventory } = state;

      const nodeList = Object.values(inventory).filter((node) => !nodeIds.includes(node.id));

      const newSelected: NodesState['selected'] = { ...selected };
      const newActivated: NodesState['activated'] = { ...activated };
      const newDisabled: NodesState['disabled'] = { ...disabled };

      nodeIds.forEach((id) => {
        delete newSelected[id];
        delete newActivated[id];
        delete newDisabled[id];
      });

      return {
        ...state,
        ...mapNodes(nodeList),
        selected: newSelected,
        selectedArray: Object.keys(newSelected),
        activated: newActivated,
        disabled: newDisabled,
        visible: visible.filter((nodeId) => !nodeIds.includes(nodeId)),
        visibleNodes: visibleNodes.filter((rel) => !nodeIds.includes(rel.id)),
      };
    },
    addToVisibleNodes: (state, action: PayloadAction<{ nodeIds: Node['id'][]; categories: NodeCategory[] }>) => {
      const { nodeIds, categories } = action.payload;
      if (isNil(nodeIds)) return;

      const visibleNodesIds = union(state.visible, nodeIds);
      const visibleNodeList = getVisibleNodesMapper(visibleNodesIds, state.inventory);
      const nodesPerCategory = getNodesPerCategoryMapper(visibleNodesIds, state.inventory, categories);

      state.visible = visibleNodesIds;
      state.visibleNodes = visibleNodeList;
      state.nodesPerCategory = nodesPerCategory;
    },
    removeFromVisibleNodes: (state, action: PayloadAction<{ nodeIds: Node['id'][]; categories: NodeCategory[] }>) => {
      const { nodeIds, categories } = action.payload;

      const newVisibleNodeIds = difference(state.visible, nodeIds);
      const newVisibleNodeList = getVisibleNodesMapper(newVisibleNodeIds, state.inventory);

      state.visible = newVisibleNodeIds;
      state.visibleNodes = newVisibleNodeList;
      state.nodesPerCategory = getNodesPerCategoryMapper(newVisibleNodeIds, state.inventory, categories);
    },
    setSelectedNodes: (state, action: PayloadAction<Node['id'][]>) => {
      const nodeIds = action.payload;
      const { selected, disabled } = state;

      const currentSelected = Object.keys(selected);
      if (
        nodeIds.length === currentSelected.length &&
        nodeIds.reduce((id, val) => id && currentSelected.includes(val), true)
      ) {
        return;
      }

      const newSelected: NodesState['selected'] = {};
      nodeIds.forEach((id) => {
        if (!disabled[id]) {
          newSelected[id] = true;
        } else {
          delete newSelected[id];
        }
      });

      state.selected = newSelected;
      state.selectedArray = Object.keys(newSelected);
    },
    selectNode: (state, action: PayloadAction<Node['id']>) => {
      const nodeId = action.payload;
      const { selected, disabled } = state;

      if (selected[nodeId]) {
        return;
      }

      const newSelected: NodesState['selected'] = { ...selected };
      if (!disabled[nodeId]) {
        newSelected[nodeId] = true;
      } else {
        delete newSelected[nodeId];
      }

      state.selected = newSelected;
    },
    selectNodes: (state, action: PayloadAction<Node['id'][]>) => {
      const nodeIds = action.payload;
      const { selected, disabled } = state;

      const newSelected: NodesState['selected'] = { ...selected };
      nodeIds.forEach((id) => {
        if (!disabled[id]) {
          newSelected[id] = true;
        } else {
          delete newSelected[id];
        }
      });
      state.selected = newSelected;
    },
    deselectNode: (state, action: PayloadAction<Node['id']>) => {
      const nodeId = action.payload;
      const { selected } = state;

      const newSelected: NodesState['selected'] = { ...selected };
      delete newSelected[nodeId];

      state.selected = newSelected;
    },
    deselectNodes: (state, action: PayloadAction<Node['id'][]>) => {
      const nodeIds = action.payload;
      const { selected } = state;

      const newSelected: NodesState['selected'] = { ...selected };
      nodeIds.forEach((id) => {
        delete newSelected[id];
      });

      state.selected = newSelected;
    },
    deselectAll: (state) => {
      state.selected = {};
    },
    activateNodes: (state, action: PayloadAction<Node['id'][]>) => {
      const nodeIds = action.payload;

      const newActivated: NodesState['activated'] = { ...state.activated };
      intersection(state.visible, nodeIds).forEach((id) => {
        if (!state.disabled[id]) {
          newActivated[id] = true;
        } else {
          delete newActivated[id];
        }
      });
      state.activated = newActivated;
    },
    deactivateNodes: (state, action: PayloadAction<Node['id'][]>) => {
      const nodeIds = action.payload;

      const newActivated: NodesState['activated'] = { ...state.activated };
      nodeIds.forEach((id) => delete newActivated[id]);
      state.activated = newActivated;
    },
    disableNodes: (state, action: PayloadAction<Node['id'][]>) => {
      const nodeIds = action.payload;
      const { selected, activated } = state;

      const newDisabled: NodesState['disabled'] = {};
      const newSelected: NodesState['selected'] = { ...selected };
      const newActivated: NodesState['activated'] = { ...activated };

      nodeIds.forEach((id) => {
        newDisabled[id] = true;
        delete newSelected[id];
        delete newActivated[id];
      });

      state.disabled = newDisabled;
      state.selected = newSelected;
      state.activated = newActivated;
    },
    enableNodes: (state, action: PayloadAction<Node['id'][]>) => {
      const nodeIds = action.payload;
      const { disabled } = state;

      const newDisabled: NodesState['disabled'] = { ...disabled };

      nodeIds.forEach((id) => {
        delete newDisabled[id];
      });

      state.disabled = newDisabled;
    },
    clearActivated: (state) => {
      state.activated = {};
    },
    clearDisabled: (state) => {
      state.disabled = {};
    },
    refreshNodePerCategories: (state, action: PayloadAction<NodeCategory[]>) => {
      if (isEmpty(state.inventory)) return;

      const categories = action.payload;
      state.nodesPerCategory = getNodesPerCategoryMapper(state.visible, state.inventory, categories);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action: AnyAction) => {
      if (isNil(action.payload?.[GRAPH])) {
        return state;
      }
      const nodes = action.payload[GRAPH].present[NAME];

      return {
        ...state,
        ...nodes,
        activated: initialState.activated,
      };
    });
    builder.addCase(CLEAR_SCENE, () => initialState);
    builder.addCase(updateInventory, (state, action) => {
      const { nodes, categories } = action.payload;
      if (isNil(nodes) || isNil(categories)) return state;
      const { selected, activated, disabled, visible, inventory } = state;
      const updatedNodeIds = nodes.map((node) => node.id);

      const newSelected: NodesState['selected'] = {};
      const newActivated: NodesState['activated'] = {};

      updatedNodeIds.forEach((id) => {
        if (selected[id] && !disabled[id]) {
          newSelected[id] = true;
        }
        if (activated[id] && !disabled[id]) {
          newActivated[id] = true;
        }
      });

      const visibleNodesIds = visible.filter((nodeId) => updatedNodeIds.includes(nodeId));

      const mappedNodes = mapNodes(nodes);
      const newInventory: NodesState['inventory'] = {
        ...inventory,
        ...mappedNodes.inventory,
      };
      const visibleNodeList = getVisibleNodesMapper(visibleNodesIds, newInventory);
      const nodesPerCategory = getNodesPerCategoryMapper(visibleNodesIds, newInventory, categories);

      return {
        ...state,
        ...mappedNodes,
        selected: newSelected,
        selectedArray: Object.keys(newSelected),
        activated: newActivated,
        visible: visibleNodesIds,
        visibleNodes: visibleNodeList,
        nodesPerCategory,
      };
    });
    builder.addCase(addToInventory, (state, action) => {
      const { nodes } = action.payload;
      if (isNil(nodes)) return state;

      const newState = mapNodes(nodes, state);

      return {
        ...state,
        ...newState,
      };
    });
    builder.addCase(addToVisible, (state, action) => {
      const { nodeIds, categories } = action.payload;
      if (isNil(nodeIds) || isNil(categories)) return;

      const visibleNodesIds = union(state.visible, nodeIds);
      const visibleNodeList = getVisibleNodesMapper(visibleNodesIds, state.inventory);
      const nodesPerCategory = getNodesPerCategoryMapper(visibleNodesIds, state.inventory, categories);

      state.visible = visibleNodesIds;
      state.visibleNodes = visibleNodeList;
      state.nodesPerCategory = nodesPerCategory;
    });
    builder.addCase(removeFromVisible, (state, action) => {
      const { nodeIds } = action.payload;
      if (isNil(nodeIds)) return state;
      const { selected, activated, disabled, visible, visibleNodes } = state;

      const newSelected = { ...selected };
      const newActivated = { ...activated };
      const newDisabled = { ...disabled };

      nodeIds.forEach((id) => {
        delete newSelected[id];
        delete newActivated[id];
        delete newDisabled[id];
      });

      const visibleNodeIds = visible.filter((nodeId) => !nodeIds.includes(nodeId));
      const nodesPerCategory: NodesState['nodesPerCategory'] = {};
      for (const [category, categoryNodes] of Object.entries(state.nodesPerCategory)) {
        const newCategoryNodes = categoryNodes.filter((nodeId) => !nodeIds.includes(nodeId));
        if (newCategoryNodes.length > 0) {
          nodesPerCategory[category] = newCategoryNodes;
        }
      }

      return {
        ...state,
        visible: visibleNodeIds,
        visibleNodes: visibleNodes.filter((node) => !nodeIds.includes(node.id)),
        selected: newSelected,
        selectedArray: Object.keys(newSelected),
        activated: newActivated,
        disabled: newDisabled,
        nodesPerCategory,
      };
    });
    builder.addCase(setSelected, (state, action) => {
      const { nodeIds } = action.payload;
      if (isNil(nodeIds)) return;
      const { selected, disabled } = state;

      const currentSelected = Object.keys(selected);
      if (
        nodeIds.length === currentSelected.length &&
        nodeIds.reduce((id, val) => id && currentSelected.includes(val), true)
      ) {
        return;
      }

      const newSelected: NodesState['selected'] = {};
      nodeIds.forEach((id) => {
        if (!disabled[id]) {
          newSelected[id] = true;
        } else {
          delete newSelected[id];
        }
      });

      state.selected = newSelected;
      state.selectedArray = Object.keys(newSelected);
    });
    builder.addCase(invertSelection, (state) => {
      const { selected, visible, disabled } = state;

      if (Object.keys(selected).length > 0 || visible.length > 0) {
        const invertedSelected: NodesState['selected'] = {};
        visible.forEach((id) => {
          if (!selected[id] && !disabled[id]) invertedSelected[id] = true;
        });

        state.selected = invertedSelected;
        state.selectedArray = Object.keys(invertedSelected);
      }
    });
    builder.addCase(graphDeselectAll, (state) => {
      state.selected = {};
    });
    builder.addCase(purgeInvisibleItems, (state) => {
      const { inventory, visible, expanded } = state;
      const visibleMap = visible.reduce<Record<string, boolean>>((map, id) => {
        map[id] = true;
        return map;
      }, {});

      const newInventory: NodesState['inventory'] = {};

      Object.keys(inventory).forEach((id) => {
        const node = inventory[id];
        if ((visibleMap[id] ?? expanded[id]) && !isNil(node)) {
          newInventory[id] = node;
        }
      });
      state.inventory = newInventory;
    });
    builder.addCase(addToExpanded, (state, action) => {
      const { nodeIds } = action.payload;
      if (isNil(nodeIds)) return;
      const newExpanded = {
        ...state.expanded,
        ...nodeIds.reduce<NodesState['expanded']>((map, nodeId) => {
          map[nodeId] = true;
          return map;
        }, {}),
      };
      state.expanded = newExpanded;
    });
    builder.addCase(clearExpanded, (state) => {
      state.expanded = {};
    });
  },
});

export const {
  updateNodeInventory,
  addToNodeInventory,
  removeFromNodeInventory,
  addToVisibleNodes,
  removeFromVisibleNodes,
  setSelectedNodes,
  selectNode,
  selectNodes,
  deselectNode,
  deselectNodes,
  deselectAll,
  activateNodes,
  deactivateNodes,
  disableNodes,
  enableNodes,
  clearActivated,
  clearDisabled,
  refreshNodePerCategories,
} = nodesSlice.actions;

export default nodesSlice.reducer;

export const positionNode = (
  node: Node,
  position = {
    x: Math.random() * 100,
    y: Math.random() * 100,
  },
  center = 'screen',
) => {
  node.placement = {
    x: position.x,
    y: position.y,
    center,
  };

  node.pinned = true;
};
