import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

import type { DataFileMetadata } from '../file-loader/types';

export type FileContextType = {
  standaloneFiles: Record<string, File>;
  setStandaloneFiles: Dispatch<SetStateAction<Record<string, File>>>;
  zipFile: File | null;
  setZipFile: (file: File | null) => void | null;
  zipDataFiles: Record<string, DataFileMetadata>;
  setZipDataFiles: Dispatch<SetStateAction<Record<string, DataFileMetadata>>>;
};

function notImplemented(): never {
  throw new Error('Not implemented');
}

export const FileContext = createContext<FileContextType>({
  standaloneFiles: {},
  setStandaloneFiles: notImplemented,
  zipFile: null,
  setZipFile: notImplemented,
  zipDataFiles: {},
  setZipDataFiles: notImplemented,
});
