import { kebabCase } from 'lodash-es';

import type { LDFlagSet } from './flags';
import { ldClient } from './ld-provider';

type Keys = keyof LDFlagSet;

/**
 * Typed wrapper around LDClient.variation methods
 * https://launchdarkly.github.io/js-client-sdk/interfaces/LDClient.html#variation.variation-1
 */
export function getFlag<K extends Keys, T extends LDFlagSet[K] | undefined>(
  flagKey: K,
  defaultValue: T,
): T extends boolean ? boolean : T {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-unsafe-return
  return ldClient?.variation(kebabCase(flagKey), defaultValue) ?? defaultValue;
}
