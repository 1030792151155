import type { Role } from '../../state/connections/types';
import { getSafeBackTicksString } from '../../state/graph/cypherUtils';
import { getLatestPerspectiveVersion } from '../../state/perspectives/migrations';
import type { Perspective } from '../../types/perspective';

export const getStorePerspectiveQuery = (perspective: Perspective, sha = '') => ({
  cypher: 'CALL bloom.storePerspective($perspectiveId, $name, $version, $data, $sha)',
  parameters: {
    perspectiveId: perspective.id,
    name: perspective.name,
    version: getLatestPerspectiveVersion(),
    data: JSON.stringify(perspective),
    sha,
  },
});

export const getDeletePerspectiveQuery = (perspectiveId: string) => ({
  cypher: 'CALL bloom.deletePerspective($perspectiveId)',
  parameters: {
    perspectiveId,
  },
});

export const fetchAllPerspectivesQuery = 'CALL bloom.fetchPerspectives';

export const replacePerspectiveIdQuery = (id: string, newId: string) => ({
  cypher: `
    MATCH (n:\`_Bloom_Perspective_\`)
    WHERE n.id = $id 
    SET n.id = $newId 
    SET n.data = replace(n.data, $id, $newId)
    RETURN n
  `,
  parameters: {
    id,
    newId,
  },
});

export const fetchPerspectiveQuery = (perspectiveId: string) => ({
  cypher: 'CALL bloom.fetchPerspective($perspectiveId)',
  parameters: {
    perspectiveId,
  },
});

export const fetchPerspectiveShaQuery = (perspectiveId: string) => ({
  cypher: 'CALL bloom.fetchPerspectiveSha($perspectiveId)',
  parameters: {
    perspectiveId,
  },
});

export const fetchPerspectiveRolesQuery = (perspectiveId: string) => ({
  cypher: 'CALL bloom.fetchPerspectiveRoles($perspectiveId)',
  parameters: {
    perspectiveId,
  },
});

export const assignRoleToPerspectiveQuery = (perspectiveId: string, role: Role) => ({
  cypher: 'CALL bloom.assignPerspective($perspectiveId, $role)',
  parameters: {
    perspectiveId,
    role,
  },
});

export const removeRoleFromPerspectiveQuery = (perspectiveId: string, role: Role) => ({
  cypher: 'CALL bloom.removeRole($perspectiveId, $role)',
  parameters: {
    perspectiveId,
    role,
  },
});

export const createRoleQuery = (role: Role) => `CREATE ROLE \`${getSafeBackTicksString(role)}\``;

export const checkPerspectiveWritePermissionQuery = () => ({
  cypher: `
    CREATE (n:\`_Bloom_Perspective_\`)
    SET n.id = 'test'
    SET n.data = 'test'
    SET n.name = 'test'
    RETURN n
    `,
  parameters: {},
});
