/**
 * Adds null to a type
 */
export type Nullable<Type> = Type | null;

/**
 * Removes undefined from a type
 */
export type NonUndefined<Type> = Type extends undefined ? never : Type;

/**
 * Describes empty object
 */
export type EmptyObj = Record<PropertyKey, never>;

/**
 * Type predicate for checking for non-undefined values.
 * Useful for type-aware filtering of undefined from arrays.
 */
export const isNonUndefined = <Type>(value: Type | undefined): value is Type => value !== undefined;

/**
 * Tests for falsy values by coercing to Boolean
 */
export const isFalsy = (value: unknown): value is false | '' | 0 | null | undefined => !value; // eslint-disable-line @typescript-eslint/strict-boolean-expressions

/**
 * Tests for truthy values by coercing to Boolean
 */
export const isTruthy = <T>(value: T | null | undefined | '' | 0 | false): value is T => !!value; // eslint-disable-line @typescript-eslint/strict-boolean-expressions

/**
 * Tests for empty array
 */
export const isEmptyArray = (value: any[] | undefined | null): value is null | undefined =>
  !(Array.isArray(value) && value.length > 0); // eslint-disable-line @typescript-eslint/strict-boolean-expressions

/**
 * Object.hasOwnProperty with TS support
 */
export const hasOwnProperty = <Type, Key extends PropertyKey>(
  object: Type,
  property: Key,
): object is Type & Record<Key, unknown> => Object.prototype.hasOwnProperty.call(object, property);
