import { toast } from '@neo4j-ndl/react';

export const dispatchToast = (message: string, type: 'success' | 'danger') => {
  toast[type](message, { isCloseable: true, shouldAutoClose: true });
};

type ApiError = {
  status: number | string;
};

type ErrorWithData = {
  status: number | string;
  data: [{ message: string }] | { message: string };
};

const CONNECTION_ERROR_MESSAGE = 'Check your internet connection.';

const isApiError = (error: unknown): error is ApiError => {
  return typeof error === 'object' && error !== null;
};

const isErrorWithData = (error: unknown): error is ErrorWithData => {
  return isApiError(error) && 'data' in error;
};

const isConstraintError = (error: unknown): error is ApiError => {
  if (isErrorWithData(error)) {
    if (Array.isArray(error.data)) {
      return error.data.some((p) => {
        return /constraint validation failed/gi.test(p.message);
      });
    }

    return /constraint validation failed/gi.test(error.data.message);
  }

  return false;
};

const isBadRequestError = (error: unknown): error is ErrorWithData => {
  if (isErrorWithData(error) && error.status === 400) {
    return true;
  }

  return false;
};

const badRequestErrorMessage = (error: ErrorWithData) => {
  if (Array.isArray(error.data)) {
    return error.data.map((p) => p.message).join(', ');
  }

  return error.data.message;
};

const isFetchError = (error: unknown): error is ApiError => isApiError(error) && error.status === 'FETCH_ERROR';

const isRawError = (error: unknown): error is Error => error instanceof Error;

const errorMessage = (error: unknown, baseMessage: string, constraintMessage: string) => {
  if (isFetchError(error)) {
    return `${baseMessage} ${CONNECTION_ERROR_MESSAGE}`;
  }

  if (isConstraintError(error)) {
    return `${baseMessage} ${constraintMessage}`;
  }

  if (isBadRequestError(error)) {
    return `${baseMessage} ${badRequestErrorMessage(error)}`;
  }

  if (isRawError(error)) {
    return `${baseMessage} ${error.message}`;
  }

  return baseMessage;
};

export const cypherItemErrorMessage = (error: unknown) => {
  const baseMessage = 'Failed to save Cypher.';
  const constraintMessage = 'A Cypher item with the same name already exists in this folder.';
  return errorMessage(error, baseMessage, constraintMessage);
};

export const cypherFolderErrorMessage = (error: unknown): string => {
  const baseMessage = 'Failed to save Cypher folder.';
  const constraintMessage = 'A Cypher folder with the same name already exists in this folder.';
  return errorMessage(error, baseMessage, constraintMessage);
};

export const cypherDeletionErrorMessage = (error: unknown) => {
  const baseMessage = 'Failed to delete Cypher.';
  return errorMessage(error, baseMessage, 'The Cypher item could not be deleted.');
};

export const cypherImportErrorMessage = (error: unknown) => {
  const baseMessage = 'Failed to import Cypher.';
  const constraintMessage = 'One or more Cypher items or folders with the same name already exist in this folder.';
  return errorMessage(error, baseMessage, constraintMessage);
};
