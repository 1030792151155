import type { OpsTypes, TimeRange } from '@nx/state';
import { dataGridHelpersClasses } from '@nx/ui';
import type { ColumnDef } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';
import { useMemo } from 'react';

import { columnPreferencesColumn } from '../../logs/shared/components/column-preferences';
import { QueryCell } from '../../logs/shared/components/query-cell';
import { NumberCell, TimestampCell, ValueListCell } from '../../logs/shared/helpers/table-overrides';
import { ACTIONS_COLUMN_ID } from '../../logs/shared/types';
import { DeprecationCell, DeprecationExpand, NavigateToLogsButton } from '../helpers';

export const useDeprecationColumns = (hasData: boolean, timeRange: TimeRange) =>
  useMemo(() => {
    const columnHelper = createColumnHelper<OpsTypes.Migration.DeprecationLogAggregation>();
    const columns = [
      columnHelper.display({
        id: 'main_action',
        header: 'Action',
        size: 85,
        enableResizing: false,
        cell: function Cell({ row }) {
          return (
            <DeprecationExpand deprecations={row.original.deprecationNotifications} query={row.original.query || ''} />
          );
        },
        meta: {
          logs: {
            isPersistent: true,
          },
        },
      }),
      columnHelper.accessor('query', {
        header: 'Query',
        cell: ({ getValue, row, column }) => (
          <QueryCell value={getValue()} query={row.original.query} width={column.getSize()} />
        ),
        size: 300,
        meta: {
          logs: {
            isPersistent: true,
          },
        },
      }),
      columnHelper.accessor('deprecationNotifications', {
        header: 'Deprecation',
        cell: ({ row }) => <DeprecationCell deprecationNotifications={row.original.deprecationNotifications} />,
        size: 300,
        meta: {
          logs: {
            isPersistent: true,
          },
        },
        enableSorting: false,
      }),
      columnHelper.accessor('executionCount', {
        header: 'Count',
        cell: NumberCell,
        size: 110,
      }),
      columnHelper.accessor('lastTimestamp', {
        header: 'Last Execution',
        cell: TimestampCell,
        size: 210,
      }),
      columnHelper.accessor('users', {
        header: 'Users',
        cell: ValueListCell,
        enableSorting: false,
      }),
      columnHelper.accessor('databases', {
        header: 'Databases',
        cell: ValueListCell,
        enableSorting: false,
      }),
      columnHelper.accessor('drivers', {
        header: 'Drivers',
        cell: ValueListCell,
        enableSorting: false,
      }),
      columnHelper.accessor('apps', {
        header: 'Applications',
        cell: ValueListCell,
        enableSorting: false,
        size: 200,
      }),
      columnHelper.accessor('initiationTypes', {
        header: 'Initiation Types',
        cell: ValueListCell,
        enableSorting: false,
      }),
      columnHelper.display({
        ...columnPreferencesColumn,
        id: ACTIONS_COLUMN_ID,
        size: 85,
        enableSorting: false,
        cell: ({ row }) => (
          <div className={classNames(dataGridHelpersClasses['visible-on-row-hover'])}>
            <NavigateToLogsButton
              timeRange={timeRange}
              databases={row.original.databases}
              querySearchString={row.original.query}
            />
          </div>
        ),
      }),
    ];

    const resizedColumns = hasData
      ? columns
      : columns.map((col) => ((col.size ?? 0) > 85 ? { ...col, size: 135 } : col));
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return resizedColumns as ColumnDef<OpsTypes.Migration.DeprecationLogAggregation>[];
  }, [hasData, timeRange]);
