import type { GetSessionsResponse } from '../api-types/session';
import type { Session } from '../types/session';

export const transformGetSessionsResponse = (response: GetSessionsResponse): Session[] =>
  response.data.map((session) => ({
    id: session.id,
    dbId: session.instance_id,
    userId: session.user_id,
    projectId: session.tenant_id,
    name: session.name,
    memory: session.memory,
    status: session.status,
    createdAt: session.created_at,
    host: session.host,
    expiryDate: session.expiry_date,
    ttl: session.ttl,
  }));
