import {
  MODAL_TYPE,
  PLAN_TYPE,
  consoleApi,
  selectConfiguration,
  useActiveProjectQuery,
  useAppSelector,
  useAuth,
  useCurrentModal,
  useModal,
} from '@nx/state';
import { isNullish } from '@nx/stdlib';
import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect } from 'react';

import { useTermsAndConditionsStatus } from './terms/hooks';

export const useModalPrompts = () => {
  const { initialPendingInvitesShown } = useAppSelector(selectConfiguration);
  const terms = useTermsAndConditionsStatus();
  const auth = useAuth();
  const { data: user } = consoleApi.useGetUserDetailsQuery(undefined, {
    skip: isNullish(auth.user),
  });
  const project = useActiveProjectQuery();
  const invites = consoleApi.useGetUserProjectInvitesQuery(user?.id ?? skipToken);

  const currentModal = useCurrentModal();
  const termsModal = useModal(MODAL_TYPE.AURA_TERMS);
  const inviteModal = useModal(MODAL_TYPE.INVITES);

  let modalToOpen = null;

  if (
    currentModal === null &&
    !terms.isLoading &&
    !(project.isUninitialized || project.isLoading) &&
    !(invites.isUninitialized || invites.isLoading)
  ) {
    if (!terms.isAccepted && project.data?.planType === PLAN_TYPE.SELF_SERVE) {
      modalToOpen = termsModal;
    } else if (invites.data && invites.data.length > 0 && !initialPendingInvitesShown) {
      modalToOpen = inviteModal;
    }
  }

  useEffect(() => {
    if (modalToOpen !== null) {
      modalToOpen.open();
    }
  }, [modalToOpen]);
};
