import { DataGrid } from '@neo4j-ndl/react';
import { DataGridHelpers, SearchField } from '@nx/ui';
import { createColumnHelper, getFilteredRowModel } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import { useCommonExpandedTable } from '../../components/table';
import { useProjectUsers } from '../contexts';
import { type DbRole, ROLE_TYPE, type User } from '../entities/model';
import { ExpandableBodyRow, ExpandableCell, LastActive, RoleLabel, UserRowAction } from './common';
import { mapUserToCommonUserRow } from './helpers';
import type { CommonUserRow } from './types';

type UserRow = CommonUserRow & {
  resource: string;
};

type RoleRow = {
  roleName: DbRole['name'];
  users: UserRow[];
};

type TableRow = {
  role: RoleRow | undefined;
  user: UserRow | undefined;
};

const groupUsersByDbRole = (users: User[]): TableRow[] => {
  const usersByDbRole = new Map<DbRole['name'], RoleRow>();
  users.forEach((user) => {
    const userDatabasesByDbRole = new Map<DbRole['name'], string[]>();
    user.dbRoles.forEach((dbRole) => {
      userDatabasesByDbRole
        .set(dbRole.name, userDatabasesByDbRole.get(dbRole.name) ?? [])
        .get(dbRole.name)
        ?.push(dbRole.dbName);
    });

    [...userDatabasesByDbRole].forEach(([dbRoleName, dbNames]) => {
      usersByDbRole
        .set(dbRoleName, usersByDbRole.get(dbRoleName) ?? { roleName: dbRoleName, users: [] })
        .get(dbRoleName)
        ?.users.push({
          ...mapUserToCommonUserRow(user),
          resource: dbNames.join(', '),
        });
    });
  });
  return [...usersByDbRole.values()].map((roleRow) => ({ role: roleRow, user: undefined }));
};

const columnHelper = createColumnHelper<TableRow>();

const columns = [
  columnHelper.accessor('role.roleName', {
    header: () => 'Database role / User',
    cell: (cx) => {
      return cx.row.getCanExpand() ? (
        <ExpandableCell cx={cx}>
          <RoleLabel roleType={ROLE_TYPE.DB}>{cx.getValue()}</RoleLabel>
        </ExpandableCell>
      ) : (
        cx.row.original.user?.email
      );
    },
  }),
  columnHelper.accessor('user.resource', {
    header: () => 'Resource',
    cell: (cx) => !cx.row.getCanExpand() && cx.getValue(),
  }),
  columnHelper.accessor('user.metadata', {
    header: () => 'Metadata',
  }),
  columnHelper.accessor('user.lastActive', {
    header: () => 'Last active',
    cell: (cx) => !cx.row.getCanExpand() && <LastActive user={cx.row.original.user} />,
  }),
  columnHelper.display({
    id: 'actions',
    cell: (cx) => <UserRowAction user={cx.row.original.user} cx={cx} />,
    meta: {
      isActionCell: {
        actions: [
          {
            title: 'User action',
            onClick: () => {
              // eslint-disable-next-line no-alert
              alert('Perform user action...');
            },
          },
        ],
      },
    },
  }),
];

export function ProjectUsersByDbRole() {
  const { users } = useProjectUsers();
  const data = useMemo(() => groupUsersByDbRole(users), [users]);
  const [globalFilter, setGlobalFilter] = useState('');
  const table = useCommonExpandedTable({
    columns,
    data,
    getRowCanExpand: (row) => Boolean(row.original.role),
    getSubRows: (row) => row.role?.users.map((user) => ({ role: undefined, user })),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    state: {
      globalFilter,
    },
  });

  useEffect(() => table.toggleAllRowsExpanded(true), [table]);

  return (
    <DataGridHelpers.Wrapper tabbed>
      <DataGridHelpers.OuterHeader>
        <div className="flex w-full flex-wrap justify-between gap-2">
          <div className="flex basis-[400px] gap-2">
            <SearchField
              className="min-w-36 grow"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
              htmlAttributes={{ 'aria-label': 'Filter users' }}
            />
          </div>
        </div>
      </DataGridHelpers.OuterHeader>
      <DataGrid
        tableInstance={table}
        components={{ BodyRow: ExpandableBodyRow }}
        styling={{
          headerStyle: 'clean',
          borderStyle: 'all-sides',
        }}
        isKeyboardNavigable={false}
      />
    </DataGridHelpers.Wrapper>
  );
}
