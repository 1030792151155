import type { PaginationInfo } from '@nx/state';
import { useRef } from 'react';

/**
 * pageCount cannot reset in between re-fetches otherwise pagination footer will flicker
 */
const INITIAL_PAGE_INFO = {
  itemCount: 0,
  pageCount: 1,
};

export const usePageInfo = (pageInfo?: PaginationInfo) => {
  const info = useRef(INITIAL_PAGE_INFO);

  if (pageInfo) {
    const { perPage, totalItems } = pageInfo;
    const pageCount = Math.ceil(totalItems / perPage);

    info.current.itemCount = totalItems;
    info.current.pageCount = pageCount;
  }

  return info.current;
};
