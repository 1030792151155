import { Button, SpotlightTarget } from '@neo4j-ndl/react';
import { AcademicCapIconOutline } from '@neo4j-ndl/react/icons';
import { UI_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { useToolsConfiguration } from '@nx/state';
import { useNavigate } from 'react-router-dom';

import { useTrackUpxEvent } from '../services/segment/analytics';

export const GuideButton = () => {
  const trackEvent = useTrackUpxEvent();
  const navigate = useNavigate();
  const toolsConfiguration = useToolsConfiguration();
  const guidesRoute = toolsConfiguration.guides?.route;

  if (guidesRoute === undefined) {
    return null;
  }

  return (
    <SpotlightTarget id="welcome-tour-0-learn" borderRadius={4}>
      <Button
        htmlAttributes={{
          'aria-label': 'Guide selection',
          title: 'Guide selection',
        }}
        fill="outlined"
        color="neutral"
        className="px-2"
        onClick={() => {
          navigate(guidesRoute);
        }}
      >
        <AcademicCapIconOutline className="size-4" />
        <span
          className="ml-1 hidden lg:inline"
          onClick={() => trackEvent({ event: UI_EVENTS.VIEW_LEARN, scope: APP_SCOPE.framework })}
        >
          Learn
        </span>
      </Button>
    </SpotlightTarget>
  );
};
