import { Divider } from '@neo4j-ndl/react';
import { useListInstalledPluginsQuery } from '@nx/state';
import type { LocalInstanceInfo, LocalInstanceInstalledPlugin } from '@nx/state';

import { isNeo4jGTE4x, isNeo4jGTE5x, isNeo4jLT5x } from '../neo4j-version';
import { Plugin } from './plugin';
import { PLUGINS_INFO } from './plugins-info';

export function Plugins({
  instanceId,
  instanceVersion,
}: {
  instanceId: LocalInstanceInfo['id'];
  instanceVersion: LocalInstanceInfo['version'];
}) {
  const installedPlugins = useListInstalledPluginsQuery(instanceId);

  const plugins: Record<string, LocalInstanceInstalledPlugin | undefined> = {};
  Object.entries(PLUGINS_INFO).forEach(([pluginId, plugin]) => {
    if (
      (isNeo4jGTE4x(instanceVersion) && pluginId === 'graphql') ||
      (isNeo4jGTE5x(instanceVersion) && pluginId === 'apoc') ||
      (isNeo4jLT5x(instanceVersion) && pluginId === 'apoc5plus') ||
      pluginId === 'neo4j-jwt-addon' ||
      plugin === undefined
    ) {
      return;
    }

    plugins[pluginId] = undefined;
  });

  if (installedPlugins.isSuccess) {
    installedPlugins.data.forEach((plugin) => {
      if (plugin.name === 'neo4j-jwt-addon') {
        return;
      }

      plugins[plugin.name] = plugin;
    });
  }

  return Object.entries(plugins).map(([pluginId, plugin]) => (
    <div key={pluginId}>
      <Plugin loading={installedPlugins.isFetching} instanceId={instanceId} plugin={plugin} pluginId={pluginId} />
      <Divider orientation="horizontal" />
    </div>
  ));
}
