import type { Perspective } from '../../../types/perspective';
import type { StoragePerspective } from '../api-types/perspective';

const BATCHING_TIMEOUT = 500;

/**
 * Batches perspective updates and submits a merged update after a timeout.
 */
export const batchedUpdatePerspective = () => {
  const cache = new Map<Perspective['id'], Partial<StoragePerspective>>();

  return (
    perspectiveId: Perspective['id'],
    update: Partial<StoragePerspective>,
    updater: (perspectiveId: Perspective['id'], update: Partial<StoragePerspective>) => void,
  ) => {
    if (cache.has(perspectiveId)) {
      const existingEntry = cache.get(perspectiveId);
      cache.set(perspectiveId, { ...(existingEntry ?? {}), ...update });
    } else {
      cache.set(perspectiveId, update);

      setTimeout(() => {
        const entry = cache.get(perspectiveId);
        if (entry) {
          updater(perspectiveId, entry);
          cache.delete(perspectiveId);
        }
      }, BATCHING_TIMEOUT);
    }
  };
};
