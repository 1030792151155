import type { UpdaterInput } from '@nx/constants';
import { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from '../context';
import * as Selectors from '../selectors';
import * as FeatureFlags from '../slices/feature-flags-slice';

export function useFeatureFlag(key: FeatureFlags.FeatureFlag, override = false) {
  const dispatch = useDispatch();
  const selectFeatureFlag = useMemo(() => Selectors.makeSelectFeatureFlag(key, override), [key, override]);

  const value = useSelector(selectFeatureFlag);

  const updater = useCallback(
    (nextValue?: UpdaterInput<typeof value>) => {
      if (nextValue instanceof Function) {
        dispatch(FeatureFlags.toggle({ key, value: nextValue(value) }));
      } else {
        dispatch(FeatureFlags.toggle({ key, value: nextValue }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, value],
  );

  return [value, updater] as const;
}
