import { forIn, isEmpty, isNil, keyBy, pick } from 'lodash-es';

import { PRE_4_DB_NAME } from '../../services/initialization';
import { log } from '../../services/logging';
import { getSetPerspectiveAndSceneOnDatabaseChange } from '../app/appDuck';
import { getIsFeatureAvailable, setDatabase } from '../connections/connectionsDuck';
import { getDocument, storeDocument } from '../document/documentStore';
import { UNSET_CURRENT_PERSPECTIVE } from '../perspectives/perspectives';
import localStorage from '../storage/localStorage';
import { cleanStateBeforeSaving } from './persistorHelper';

class DocumentPersistor {
  constructor() {
    this.previousState = {};
  }

  shouldLoadState({ action }) {
    return !action || setDatabase.match(action);
  }

  async loadFromStorage({ store, payload, action }) {
    const { getState } = store;
    const currentState = getState();
    const keepList = this.getCurrentKeepList(currentState);

    const documentState = await getPartialStateFromDocument(currentState, keepList);
    if (payload) {
      const isFirstConnection = isEmpty(documentState) && isEmpty(payload.currentPerspectiveId);

      payload = {
        ...payload,
        ...(isEmpty(documentState) ? {} : documentState),
        connections: { ...payload.connections, isFirstConnection },
        version: payload.version || documentState.version,
      };
    }

    if (currentState?.connections && payload?.perspectives?.perspectives?.length) {
      payload = await handleUnattachedPerspectives(currentState, payload);
    }

    if (!isNil(action) && setDatabase.match(action) && !getSetPerspectiveAndSceneOnDatabaseChange(currentState)) {
      delete payload.currentPerspectiveId;
      delete payload.scene?.currentSceneId;
    }

    return payload;
  }

  async saveToStorage({ state }) {
    const connections = state.connections;
    const currentDocumentKeepList = this.getCurrentKeepList(state);

    const cleanedDocument = cleanStateBeforeSaving(state, null, currentDocumentKeepList, documentTransformation);
    const newDocument = {
      ...cleanedDocument,
      dbId: connections.database.id,
      dbmsId: connections.dbmsId,
      userId: connections.userId,
      version: state.version,
    };

    try {
      await storeDocument(newDocument);
    } catch (err) {
      log.info('Save Document:', err);
    }
  }

  isSceneFeaturePresent(state) {
    return state?.connections?.availableProcedures && getIsFeatureAvailable(state, 'perspectiveSharing');
  }

  getCurrentKeepList(state) {
    return this.isSceneFeaturePresent(state) ? documentKeepListWithScene : documentKeepList;
  }

  shouldSaveState({ state, action }) {
    let shouldSaveState = false;
    if (!state.connections?.database?.id) {
      return false;
    }
    if (action && [setDatabase.toString(), UNSET_CURRENT_PERSPECTIVE].includes(action.type)) {
      return false;
    }

    for (const property of this.getCurrentKeepList(state)) {
      if (this.previousState[property] !== state[property]) {
        // Property "graph" is changing even if there are no changes (redux-undo), checking another level
        if (property === 'graph') {
          forIn(state[property], (value, key) => {
            if (!this.previousState[property] || this.previousState[property][key] !== value) {
              shouldSaveState = true;
            }
          });
        } else {
          shouldSaveState = true;
        }

        this.previousState[property] = state[property];
      }
    }
    return shouldSaveState;
  }
}

export default DocumentPersistor;

const getPartialStateFromDocument = async (currentState, keepList) => {
  if (currentState?.connections?.database) {
    const connections = currentState.connections;
    const dbmsId = connections.dbmsId;
    const dbId = connections.database.id;

    let doc = await getDocument(dbmsId, dbId, connections.userId);

    if (!doc) {
      // prior to 2.7.0, key is dbId.userId
      doc = await getDocument(null, connections.database.legacyId, connections.userId);
    }

    if (doc) {
      return pick(doc, keepList);
    }
  }
  return {};
};

/**
 * Handle migration for perspective from 3.5 to 4.X when the persepctives are not connected to a valid dbms
 * @param {*} currentState
 * @param {*} payload
 * @returns
 */
const handleUnattachedPerspectives = async (currentState, payload) => {
  const { dbmsVersion, dbmsId } = currentState.connections;
  const { perspectives: perspectiveHolder, currentPerspectiveId } = payload;
  const { perspectives } = perspectiveHolder;
  const unattachedPerspectives = perspectives.filter(({ dbmsId, id } = {}) => !dbmsId);
  const attachData = [];

  const onDone = () => {
    if (attachData.length) {
      const perspectivesById = keyBy(perspectives, 'id');
      attachData.forEach(({ dbmsId, dbmsVersion, dbId, dbName, perspectiveIds }) => {
        perspectiveIds.forEach((perspectiveId) => {
          const perspective = perspectivesById[perspectiveId];
          if (perspective) {
            perspective.dbmsId = dbmsId;
            perspective.dbmsVersion = dbmsVersion;
            perspective.dbId = dbId;
            perspective.dbName = dbName;
          }
        });
      });
    }
    return payload;
  };

  if (!unattachedPerspectives.length) {
    return onDone();
  }

  const unattachedPerspectivesById = keyBy(unattachedPerspectives, 'id');

  const attachPerspective = (perspectiveId, dbmsId, dbmsVersion = undefined) => {
    const perspective = unattachedPerspectivesById[perspectiveId];
    if (perspective) {
      attachData.push({
        dbmsId,
        dbmsVersion,
        dbId: dbmsId,
        dbName: PRE_4_DB_NAME,
        perspectiveIds: [perspectiveId],
      });
      delete unattachedPerspectivesById[perspectiveId];
    }
  };

  if (currentPerspectiveId) {
    attachPerspective(currentPerspectiveId, dbmsId, dbmsVersion);
  }

  if (isEmpty(unattachedPerspectivesById)) {
    return onDone();
  }

  try {
    const keys = await localStorage.keys();
    const documentKeys = keys.filter((key) => key.startsWith('neo4j.bloomDocument'));

    if (documentKeys.length === 0) {
      return onDone();
    }

    for (const documentKey of documentKeys) {
      const { storeId, dbmsId, perspectiveId } = await localStorage.getItem(documentKey);
      const theDbmsId = dbmsId || storeId;
      if (theDbmsId && perspectiveId) {
        attachPerspective(perspectiveId, theDbmsId);
      }
    }
  } catch (error) {
    log.info('handleUnattachedPerspectives', error);
  }
  return onDone();
};

const documentKeepListWithScene = ['drawer', 'currentPerspectiveId', 'scene'];
export const documentKeepList = ['drawer', 'filter', 'graph', 'visualization', 'currentPerspectiveId', 'gds', 'slicer'];
const documentTransformation = (key, value) => {
  // Every transformation need to have a corresponding "REHYDRATE" action handler in the Reducer
  switch (key) {
    case 'graph':
      delete value.past;
      delete value.future;
      delete value._latestUnfiltered;

      // Persisting activated state doesn'tmake sense, ongoing queries are not resumed
      if (value.present && value.present.nodes) {
        value.present.nodes = pick(value.present.nodes, ['visible']);
      }
      if (value.present && value.present.relationships) {
        value.present.relationships = pick(value.present.relationships, ['visible']);
      }
      break;
    case 'drawer':
      return {
        perspectiveHistoryLastSeen: value.perspectiveHistoryLastSeen,
      };
    case 'slicer':
      return {
        ranges: value.ranges?.map((range) => ({ ...range, isActive: false })),
        isSlicerOpen: false, // this has to remain in order to close slicer on refresh
      };
    case 'filter':
      value.collapsed = true;
      break;
    case 'visualization':
      delete value.layoutIsComputing;
      delete value.exportScreenshotCounter;
      delete value.zoomedTo;
      delete value.zoomedToCounter;
      break;
    case 'scene':
      return pick(value, ['currentSceneId']);
    case 'gds':
      delete value.isGdsAvailable;
      delete value.gdsProcedures;
      delete value.gdsProcedureDescMap;
      delete value.gdsVersion;
  }
};
