import type { UpdaterInput } from '@nx/constants';
import { createSelector } from '@reduxjs/toolkit';
import { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from '../context';
import * as Selectors from '../selectors';
import type { SettingsKey, SettingsScope } from '../slices/settings-slice';
import * as Settings from '../slices/settings-slice';
import { makeSelectSetting } from '../slices/settings-slice';

/**
 * Get setting value and updater function
 *
 * The API is similar to React’s `useState` hook. It returns a tuple with first
 * item being setting’s value and second being an updater function. Updater
 * function either takes a new value or callback that receives current value
 * and returns new value.
 *
 * @example
 * const [crashReports, setCrashReports] = useSetting("global", "crashReports")
 *
 * // Update using value
 * setCrashReports("allowed")
 *
 * // Update using callback
 * // setCrashReports((prev) => prev === "disallowed" ? "allowed" : "disallowed")
 *
 * @param settings
 * @returns
 */

export function useSetting<
  Scope extends SettingsScope = SettingsScope,
  Key extends SettingsKey<Scope> = SettingsKey<Scope>,
>(scope: Scope, key: Key) {
  const dispatch = useDispatch();
  const selectSetting = useMemo(
    () => createSelector(Selectors.selectSettings, makeSelectSetting(scope, key)),
    [scope, key],
  );

  const value = useSelector(selectSetting);

  const updater = useCallback(
    (nextValue: UpdaterInput<typeof value>) => {
      if (nextValue instanceof Function) {
        dispatch(Settings.update({ scope, key, value: nextValue(value) }));
      } else {
        dispatch(Settings.update({ scope, key, value: nextValue }));
      }
    },

    // TODO: Comment why key and scope is not included
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, value],
  );

  return [value, updater] as const;
}
