import { Code, Dialog, IconButton, TextLink, Typography } from '@neo4j-ndl/react';
import { CheckIconOutline, ClipboardDocumentIconOutline, MagnifyingGlassIconOutline } from '@neo4j-ndl/react/icons';
import type { OpsTypes } from '@nx/state';
import { useState } from 'react';

import { toDisplayName } from '../mappers';
import { getDeprecationsDocsLink } from '../metadata';

export const DeprecationExpand = ({
  deprecations,
  query,
}: {
  deprecations: OpsTypes.Migration.DeprecationNotification[];
  query: string;
}) => {
  // TODO reuse
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleClick = () => setIsOpen((prev) => !prev);
  const handleClose = () => setIsOpen(false);
  const [copying, setCopying] = useState(false);

  const copyCode = () => {
    setCopying(true);
    void navigator.clipboard.writeText(query);
    setTimeout(() => setCopying(false), 1500);
  };

  const getLine = (text: string, nbr: number, pos: number) => {
    let line = text.split('\n')[nbr - 1];
    const space = ' ';
    if (pos === 0) {
      return `${line}\n^`;
    }
    line = `${line}\n${space.repeat(pos - 1)}^`;
    return line;
  };

  const deprecationId = (d: OpsTypes.Migration.DeprecationNotification) =>
    `${d.name}-${d.position.offset}-${d.position.line}-${d.position.column}`;
  return (
    <div>
      <IconButton
        ariaLabel="Show deprecation details"
        className="bg-palette-neutral-bg-weak rounded-lg"
        isClean
        isGrouped
        onClick={handleClick}
        htmlAttributes={{
          'data-testid': `${query}-deprecation-details`,
        }}
      >
        <MagnifyingGlassIconOutline />
      </IconButton>
      <Dialog size="large" isOpen={isOpen} onClose={handleClose}>
        <Dialog.Header>
          <div className="flex items-center gap-2">
            Resolution guide
            <Typography variant="body-large">
              <TextLink
                isExternalLink
                href="https://neo4j.com/docs/cypher-manual/current/deprecations-additions-removals-compatibility/"
              >
                Docs
              </TextLink>
            </Typography>
          </div>
        </Dialog.Header>
        <Dialog.Content>
          <div className="flex flex-col gap-4">
            <div>
              <Typography variant="subheading-large">Query</Typography>
              <Code
                language="cypher"
                code={query}
                showLineNumbers
                actions={[
                  {
                    ariaLabel: 'copy',
                    htmlAttributes: { title: 'copy' },
                    children: copying ? <CheckIconOutline /> : <ClipboardDocumentIconOutline />,
                    onClick: () => {
                      copyCode();
                    },
                  },
                ]}
              />
            </div>
            {deprecations.map((deprecation, index) => (
              <div key={deprecationId(deprecation)}>
                <Typography variant="subheading-large">
                  Issue{deprecations.length > 1 ? ` ${index + 1}` : ''}: {toDisplayName(deprecation.name)}
                </Typography>
                <div className="flex flex-col">
                  <Code
                    className="mrr-single-issue"
                    language="cypher"
                    code={getLine(query, deprecation.position.line, deprecation.position.column)}
                  />
                </div>
                <div className="mt-2">
                  {deprecation.description}{' '}
                  <TextLink isExternalLink href={getDeprecationsDocsLink(deprecation.name)}>
                    Docs
                  </TextLink>
                </div>
              </div>
            ))}
          </div>
        </Dialog.Content>
      </Dialog>
    </div>
  );
};
