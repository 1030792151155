import type { RouterState } from '@nx/constants';
import { isRouterState } from '@nx/constants';
import { useLocation } from 'react-router-dom';

export function useRouterState(): RouterState {
  const location = useLocation();

  if (isRouterState(location.state)) {
    return location.state;
  }

  return undefined;
}
