import { isNotNullish } from '@nx/stdlib';
import { createSelector } from '@reduxjs/toolkit';

import * as Capabilities from './slices/capabilities-slice';
import { selectActiveConnection } from './slices/connections/connections-slice';
import * as FeatureFlags from './slices/feature-flags-slice';
import type { ConsentAndGenaiState } from './slices/genai-api-slice';
import type { FullNxNotification } from './slices/notifications-slice';
import * as Session from './slices/session-slice';
import { makeSelectSetting } from './slices/settings-slice';
import type { CombinedRootState, RootState } from './store';

export const selectUserId = <State extends { [Session.slice.name]: Session.SessionState }>(state: State) => {
  if (state.session.type === Session.SESSION_STATUS.Authenticated) {
    return state.session.userId;
  }

  return null;
};

export const selectAppContext = (state: Pick<RootState, 'appContext'>) => state.appContext;

export const selectFeatureFlags = <State extends { [FeatureFlags.slice.name]: FeatureFlags.FeatureFlagsState }>(
  state: State,
) => state.featureFlags;

export const selectEnabledFeatureFlags: (
  state: RootState,
) => ReturnType<typeof FeatureFlags.selectEnabledFeatureFlags> = createSelector(
  (state: RootState) => state.featureFlags,
  FeatureFlags.selectEnabledFeatureFlags,
);

export function makeSelectFeatureFlag(
  key: FeatureFlags.FeatureFlag,
  override = false,
): <State extends { [FeatureFlags.slice.name]: FeatureFlags.FeatureFlagsState }>(state: State) => boolean {
  return createSelector(selectFeatureFlags, FeatureFlags.makeSelectFeatureFlag(key, override));
}

export const selectSettings = (state: Pick<RootState, 'settings'>) => state.settings;

type NetworkPolicies = {
  // outgoingConnectonsAllowd is a catch all property, forbidding usageTracking and crashReports if false
  outgoingConnectionsAllowed: boolean;
  usageTrackingAllowed: boolean;
  crashReportsAllowed: boolean;
  policySource: 'app' | 'dbms';
  policySourceUpdated: boolean;
};

/**
 *  DBMS configuration have settings that can be used to control network policies in the client web application:
 *  client.allow_telemetry and browser.allow_outgoing_connections.
 *  NOTE: these settings control the behavior of the client web application, not the DBMS.
 *  These settings could be respected or ignored based on the capability framework:respect-dbms-neo4j-conf-settings.
 *  DBMS configuration settings are read in the metadata.configuration object.
 *  WARNING: The whole setup of getting client web application settings from the DBMS could be confusing in a
 *  framework environment, where we build cloud and non-cloud products (standalone, self-served, bundled, desktop,etc).
 *  There are a lot of edge cases, different deployments and tools have different requirements.
 *  The reason we use it are largely historical, there might be other alternatives to handle it.
 *  The following is a best effort to make sense out of current setup.
 *  See: https://docs.google.com/document/d/1Iz7ysaj0laJgctn_fkOTN0urfLm1ejndFBvZr5Mf8JY
 */
export const selectNetworkPolicies = (
  state: Pick<RootState, 'settings' | 'metadata' | 'capabilities' | 'connections'>,
): NetworkPolicies => {
  const usageTracking = makeSelectSetting('global', 'usageTracking')(selectSettings(state));
  const crashReports = makeSelectSetting('global', 'crashReports')(selectSettings(state));
  const isMetadataLoaded = state.metadata.state === 'LOADED';
  const allowOutgoingConnections = state.metadata.configuration?.allowOutgoingConnections === true;
  const allowTelemetry = state.metadata.configuration?.allowTelemetry === true;
  const respectDbmsNetworkPolicies = Capabilities.selectCapability(state, {
    key: 'framework:respect-dbms-neo4j-conf-settings',
  });
  const isConnected = isNotNullish(selectActiveConnection(state.connections));

  // Use app settings if we don't need to respect DBMS network policies
  if (!respectDbmsNetworkPolicies) {
    return {
      outgoingConnectionsAllowed: true,
      usageTrackingAllowed: usageTracking,
      crashReportsAllowed: crashReports,
      policySource: 'app',
      policySourceUpdated: true,
    };
  }

  // If we need to respect DBMS network policies,
  // check if metadata (including neo4j.conf settings) is loaded to determine network policies
  if (isConnected && isMetadataLoaded) {
    const dbmsAllowTelemetry = allowOutgoingConnections && allowTelemetry;
    return {
      outgoingConnectionsAllowed: allowOutgoingConnections,
      usageTrackingAllowed: dbmsAllowTelemetry && usageTracking,
      crashReportsAllowed: dbmsAllowTelemetry && crashReports,
      policySource: dbmsAllowTelemetry ? 'app' : 'dbms',
      policySourceUpdated: true,
    };
  }

  // otherwise, don't allow any network connections
  return {
    outgoingConnectionsAllowed: false,
    usageTrackingAllowed: false,
    crashReportsAllowed: false,
    policySource: 'dbms',
    policySourceUpdated: false,
  };
};

export const selectNotifications = (state: RootState): FullNxNotification[] => state.notifications.notifications;

export const selectGenaiApiState = createSelector(
  (state: Pick<CombinedRootState, 'genaiApi' | 'settings' | 'session'>) => ({
    sessionType: state.session.type,
    genaiConsent: state.settings.global.genaiConsent,
    genaiApi: state.genaiApi,
  }),
  ({ sessionType, genaiConsent, genaiApi }): ConsentAndGenaiState => {
    if (sessionType === Session.SESSION_STATUS.Inactive) {
      return { status: 'no-login-endpoint' };
    }

    if (genaiConsent === 'consent-unknown') {
      return { status: 'consent-unknown' };
    }

    if (genaiConsent === 'consent-declined') {
      return { status: 'consent-declined' };
    }

    return genaiApi;
  },
);
