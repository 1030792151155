import { OPS_EVENTS } from '@nx/analytics-service';
import type { Instance, QueryLogFilterableFields } from '@nx/state';
import { opsApi, useActiveProject } from '@nx/state';
import { Functions, Objects, isNonEmptyString } from '@nx/stdlib';
import { skipToken } from '@reduxjs/toolkit/query';
import { produce } from 'immer';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { ChartContext } from '../../../metrics/charts/types';
import { analyticsPropsFromTimeRange } from '../../../metrics/shared/utils';
import { track } from '../../../services/segment/analytics';
import { getListIfSubsetOrDefault } from '../../../shared/utils';
import type { FilterField, NumericField, SearchField } from '../../shared/components/log-filters/request-dialog';
import { RequestDialog } from '../../shared/components/log-filters/request-dialog';
import { WIDTH_MD, initialFilters } from '../../shared/constants';
import { FriendlyQueryLogFilterName } from '../../shared/mappers';
import { useLogsContext } from '../hooks/use-logs-context';
import { useRawFilters } from '../hooks/use-raw-filters';

export interface QueryLogFiltersProps {
  setIsRequestDialogOpen: (a: boolean) => void;
  hasQueries: boolean;
  chartContext: ChartContext;
  setHasChangedSelection: (a: boolean) => void;
  selectedInstance: Instance | undefined;
}

const filterNames: QueryLogFilterableFields[] = [
  'statuses',
  'gqlStatuses',
  'users',
  'drivers',
  'apps',
  'initiationTypes',
  'queryLanguages',
];

export const QueryLogFilters = ({
  setIsRequestDialogOpen,
  hasQueries,
  chartContext,
  setHasChangedSelection,
  selectedInstance,
}: QueryLogFiltersProps) => {
  const activeProject = useActiveProject();

  const { rawFilters: localFilters, setRawFilters: setLocalFilters } = useRawFilters();
  const { rawFilters, setRawFilters, setHasRequestedData } = useLogsContext();
  const [shouldLoadFilters, setShouldLoadFilters] = useState(false);

  const didApplyFilters =
    filterNames.some((name) => rawFilters.dbmsFilters[name].length > 0) ||
    Boolean(rawFilters.querySearchString) ||
    Boolean(rawFilters.errorSearchString) ||
    // Note: this assumes numeric field initial value is falsy (e.g. 0)
    Boolean(rawFilters.minDuration);

  useEffect(() => {
    setHasChangedSelection(true);
  }, [localFilters, setHasChangedSelection]);

  useEffect(() => {
    setLocalFilters(
      produce((draft) => {
        draft.dbmsFilters = rawFilters.dbmsFilters;
        draft.errorSearchString = rawFilters.errorSearchString;
        draft.querySearchString = rawFilters.querySearchString;
      }),
    );
  }, [rawFilters.dbmsFilters, rawFilters.errorSearchString, rawFilters.querySearchString, setLocalFilters]);

  const shouldFetch = isNonEmptyString(selectedInstance?.id) && hasQueries && (shouldLoadFilters || didApplyFilters);
  const filterValuesQueryRes = opsApi.useGetQueryLogFilterValuesQuery(
    shouldFetch
      ? {
          tenantId: activeProject.id,
          dbmsId: selectedInstance.id,
          from: chartContext.timeRange.startTime.getTime(),
          to: chartContext.timeRange.endTime.getTime(),
        }
      : skipToken,
  );

  const availableDbmsFilters = useMemo(
    () => filterValuesQueryRes.data ?? initialFilters().dbmsFilters,
    [filterValuesQueryRes.data],
  );

  const MakeFilterField = useCallback(
    (filterName: QueryLogFilterableFields): FilterField => {
      const init = initialFilters();
      return {
        id: filterName,
        label: FriendlyQueryLogFilterName[filterName],
        value: getListIfSubsetOrDefault(rawFilters.dbmsFilters[filterName], availableDbmsFilters[filterName]),
        defaultValue: init.dbmsFilters[filterName],
        values: availableDbmsFilters[filterName],
        valueFormatter: Functions.identity,
      };
    },
    [availableDbmsFilters, rawFilters.dbmsFilters],
  );

  const memoizedFilterFields = useMemo(() => {
    return Objects.fromEntries(filterNames.map((name) => [name, MakeFilterField(name)]));
  }, [MakeFilterField]);

  const memoizedSearchFields = useMemo(() => {
    const init = initialFilters();
    const queryTextField: SearchField = {
      id: 'Query Text',
      label: 'Query text',
      value: rawFilters.querySearchString ?? '',
      defaultValue: init.querySearchString ?? '',
      placeholder: "e.g. 'MATCH (n:Node)'",
    };
    const errorTextField: SearchField = {
      id: 'Error Text',
      label: 'Error text',
      value: rawFilters.errorSearchString ?? '',
      defaultValue: init.errorSearchString ?? '',
      placeholder: "e.g. 'the transaction has been terminated'",
    };

    return [queryTextField, errorTextField] satisfies [SearchField, SearchField];
  }, [rawFilters]);

  const memoizedNumericFields = useMemo(() => {
    const minDurationField: NumericField = {
      id: 'Minimum duration (ms)',
      label: 'Minimum duration (ms)',
      value: rawFilters.minDuration,
      defaultValue: initialFilters().minDuration,
    };

    return [minDurationField] satisfies [NumericField];
  }, [rawFilters.minDuration]);

  return (
    <RequestDialog
      header="Fetch logs"
      modalProps={{ style: { width: WIDTH_MD } }}
      isOpen
      onClose={() => {
        setIsRequestDialogOpen(false);
        setShouldLoadFilters(false);
      }}
      onLoadFilters={() => setShouldLoadFilters(true)}
      onAccept={(fields, search, numericFields) => {
        const [queryTextField, errorTextField] = memoizedSearchFields;
        const [minDurationField] = memoizedNumericFields;

        setRawFilters(
          produce((draft) => {
            filterNames.forEach((name) => {
              if (fields[name]?.value) {
                draft.dbmsFilters[name] = fields[name].value;
              }
            });
            draft.timeRange = chartContext.timeRange;
            draft.querySearchString = search.find((f) => f.id === queryTextField.id)?.value ?? '';
            draft.errorSearchString = search.find((f) => f.id === errorTextField.id)?.value ?? '';
            draft.minDuration = numericFields.find((f) => f.id === minDurationField.id)?.value ?? 0;
          }),
        );
        setHasRequestedData(true);
        setHasChangedSelection(false);
        track(OPS_EVENTS.LOGS_REQUEST, analyticsPropsFromTimeRange(chartContext.timeRange));
      }}
      timeRange={chartContext.timeRange}
      hasQueries={hasQueries}
      filterFields={memoizedFilterFields}
      searchFields={memoizedSearchFields}
      numericFields={memoizedNumericFields}
      showFiltersDefault={didApplyFilters}
      isLoading={filterValuesQueryRes.isLoading}
      filterError={filterValuesQueryRes.error}
    />
  );
};
