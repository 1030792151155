import type { DomJson } from '@nx/constants';
import { TEST_GUIDE_PAGES } from '@nx/constants';
import type { PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { asyncThunkCreator, buildCreateSlice } from '@reduxjs/toolkit';

export interface SelectGuideParams {
  title: string;
  id: string;
  url?: string;
  localGuide?: string;
}

export interface SetGuideCurrentPageParams {
  id: string;
  pageNumber: number;
}

export type Guide = {
  id: string;
  title: string;
  url?: string;
  localGuide?: string;
  contents: DomJson[];
  totalPages: number;
};

export type GuideStatus = {
  currentPage: number;
  isCompleted: boolean;
};

export type Guides = Record<string, Guide>;
export type GuidesStatuses = Record<string, GuideStatus>;

export interface GuidesState {
  guides: Guides;
  guidesStatuses: GuidesStatuses;
  currentGuideId: string | undefined;

  // TODO: move status and error into a guide?
  fetchStatus?: 'error' | 'loading' | 'success';
  fetchError?: SerializedError;
}

export const GUIDES_PERSISTED_KEYS: (keyof GuidesState)[] = ['guides', 'guidesStatuses', 'currentGuideId'];

const fetchRemoteGuide = async (url: string, disableCache: boolean | undefined = false) => {
  const response = await fetch(url, disableCache ? { cache: 'no-cache' } : undefined);
  const text = await response.text();
  return text;
};

const initialState: GuidesState = {
  guides: {},
  guidesStatuses: {},
  currentGuideId: undefined,
};

const createSliceWithThunks = buildCreateSlice({
  creators: { asyncThunk: asyncThunkCreator },
});

const ERROR_MSG: DomJson[] = [
  {
    name: 'H2',
    type: 1,
    value: null,
    classList: [],
    children: [
      {
        name: '#text',
        type: 3,
        value: 'Error. Failed to load guides: The data format is incorrect or corrupted.',
        classList: [],
        children: [],
      },
    ],
  },
];

const guidesSlice = createSliceWithThunks({
  name: 'guides',
  initialState,
  reducers: (create) => ({
    deselectGuide: create.reducer((state) => {
      state.currentGuideId = undefined;
    }),
    createGuideStatus: create.reducer((state, action: PayloadAction<{ id: string } & GuideStatus>) => {
      const { id, ...status } = action.payload;
      state.guidesStatuses[id] = status;
    }),
    deleteGuideStatus: create.reducer((state, action: PayloadAction<string>) => {
      const id = action.payload;
      delete state.guidesStatuses[id];
    }),
    setGuideCurrentPage: create.reducer((state, action: PayloadAction<SetGuideCurrentPageParams>) => {
      const { id, pageNumber } = action.payload;
      const guide = state.guides[id];
      const guideStatus = state.guidesStatuses[id];

      if (guide === undefined || guideStatus === undefined) {
        return;
      }

      // clamp the page number to the total number of pages
      const currentPage = Math.min(pageNumber, guide.totalPages - 1);

      // When the user navigates to the last page, we mark it as completed.
      if (currentPage === guide.totalPages - 1) {
        guideStatus.isCompleted = true;
      }

      guideStatus.currentPage = currentPage;
    }),
    selectGuide: create.asyncThunk(
      async ({ id, title, url, localGuide }: SelectGuideParams, thunkApi) => {
        const disableCache = id === TEST_GUIDE_PAGES.NX_INTERNAL_TEST.toString();
        const text = localGuide ?? (await fetchRemoteGuide(url ?? '', disableCache));

        let contents: DomJson[];
        try {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          contents = JSON.parse(text);
        } catch (e: unknown) {
          contents = ERROR_MSG;
        }

        return {
          id,
          title,
          url,
          contents,
        };
      },

      {
        pending: (state) => {
          state.fetchStatus = 'loading';
          state.fetchError = undefined;
        },

        rejected: (state, action) => {
          state.fetchStatus = 'error';
          state.fetchError = action.error;
          state.currentGuideId = undefined;
        },
        fulfilled: (state, action) => {
          state.fetchStatus = 'success';
          state.fetchError = undefined;

          const { id, contents } = action.payload;

          state.guides[id] = {
            ...action.payload,
            totalPages: contents.length,
          };

          state.currentGuideId = id;
        },
      },
    ),
  }),
});

export const { deselectGuide, createGuideStatus, deleteGuideStatus, setGuideCurrentPage, selectGuide } =
  guidesSlice.actions;
export default guidesSlice.reducer;
