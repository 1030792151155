import type { QueryResultWithLimit } from '@nx/constants';
import { APP_SCOPE, QUERY_TYPE } from '@nx/constants';
import { runQuery } from '@nx/state';
import type { CypherRequestId } from '@query/redux/requests-slice';
import { registerAbortFunction, unregisterAbortFunction } from '@query/services/abortable-frame-service';
import { trackFailedCypherQuery, trackSuccessfulCypherQuery } from '@query/services/analytics';
import { cacheQueryResult } from '@query/services/frame-queryresult-service';
import type { QueryError } from '@query/types/query';
import { isQueryError } from '@query/utils/error-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

import type { ExecuteCommandPayload } from './command-thunks';

export type ExecutedCypherCommandPayload = {
  requestId: CypherRequestId;
  command: ExecuteCommandPayload;
  parameterSnapshot: Record<string, unknown>;
};

export type ExecutedCypherCommand = { requestId: CypherRequestId };

export const executeCypherCommandThunk = createAsyncThunk<
  ExecutedCypherCommand,
  ExecutedCypherCommandPayload,
  {
    rejectValue: { error: QueryError; aborted: boolean };
  }
>('stream/executeCypherCommand', async (payload: ExecutedCypherCommandPayload, { rejectWithValue }) => {
  const { requestId, command, parameterSnapshot } = payload;
  const database = command.connection?.database;

  try {
    const query = runQuery({
      recordLimit: command.recordLimit,
      useFrameworkStoreRecordLimit: command.recordLimit === undefined,
      parameters: parameterSnapshot,
      sessionConfig: { database: database ?? undefined },
      query: command.cmd,
      metadata: { appScope: APP_SCOPE.query, queryType: QUERY_TYPE.UserDirect },
      transactionMode: 'write',
    });

    registerAbortFunction(requestId, query.abort);

    const queryResult: QueryResultWithLimit = await query.unwrap();

    cacheQueryResult(requestId, queryResult);
    trackSuccessfulCypherQuery(queryResult, command.wasFormatted);

    return { requestId };
  } catch (error) {
    if (isQueryError(error)) {
      trackFailedCypherQuery(command.wasFormatted, error.code);
      return rejectWithValue({ error, aborted: error.name === 'AbortError' });
    }

    trackFailedCypherQuery(command.wasFormatted, 'Unknown');
    return rejectWithValue({ error: { code: 'Unknown', message: 'An unknown error occurred' }, aborted: false });
  } finally {
    unregisterAbortFunction(requestId);
  }
});
