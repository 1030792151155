import { neo4jVersionUtil } from '@nx/neo4j-version-utils';
import { isNotNullish } from '@nx/stdlib';
import { captureException } from '@sentry/react';
import { sortBy, unionBy } from 'lodash-es';
import { isNull, map, pipe } from 'lodash/fp';
import type { Schema } from 'yup';

import { log } from '../../../services/logging';
import migrate, { FIRST_VERSION } from '../../../services/migrate';
import { getClientMigrationVersion } from '../../../services/versions/version';
import type { CategoryWithStyle, PerspectiveWithStyle } from '../../../types/perspective';
import type { Nullable } from '../../../types/utility';
import { getLatestPerspectiveVersion, trasformations } from '../migrations';
import { perspectiveBasicSchema } from './perspectiveSchemaBasic';
import { perspectiveSchemaComplete } from './perspectiveSchemaComplete';
import type { ExportedPerspectiveWithStyle } from './perspectiveSchemaComplete.ts';

const { gt: versionLater, diff: versionsDifference, eq: versionsEqual } = neo4jVersionUtil;

const ensureItIsArray = (item: unknown) => (Array.isArray(item) ? item : [item]);
const addNumberToName = (category: CategoryWithStyle, i: number) => ({
  ...category,
  name: `${category.name} ${i + 1}`,
});

const byDuplicatedNames = (category: CategoryWithStyle, _: number, categories: CategoryWithStyle[]) => {
  const categoryDuplicatedNameCount = categories.reduce((acc, cat) => (cat.name === category.name ? ++acc : acc), 0);
  return categoryDuplicatedNameCount > 1;
};

const mergeAndReplaceWithUpdated = (
  categories: CategoryWithStyle[],
  updated: CategoryWithStyle[],
): CategoryWithStyle[] => {
  const merged = unionBy(categories, updated, 'id');
  return sortBy(merged, 'id');
};

const renameDuplicateCategories = (result: ValidationResult) => {
  if (!result.perspective) {
    return result;
  }

  const { categories } = result.perspective;
  const perspective = { ...result.perspective };

  if (categories) {
    const updatedCategories = categories.filter(byDuplicatedNames).map(addNumberToName);
    if (updatedCategories.length !== 0) {
      perspective.categories = mergeAndReplaceWithUpdated(categories, updatedCategories);
    }
  }

  return { perspective, error: result.error };
};

interface ValidationResult {
  perspective: Nullable<ExportedPerspectiveWithStyle>;
  error: Nullable<string>;
}

const validatePerspective = (
  perspectiveInput: string | ExportedPerspectiveWithStyle,
  schema: Schema,
): ExportedPerspectiveWithStyle | null => {
  let perspectiveJson: ExportedPerspectiveWithStyle | null = null;
  try {
    if (typeof perspectiveInput === 'string') {
      perspectiveJson = JSON.parse(perspectiveInput);
    } else {
      if (!perspectiveInput) {
        return perspectiveInput;
      }
      perspectiveJson = perspectiveInput;
    }

    // eslint-disable-next-line no-sync
    const perspective = schema.validateSync(perspectiveJson);

    return perspective || null;
  } catch (e) {
    log.error('getValidatedPerspective - exception:', e, (e as any)?.value);
    // Capturing the error so we improve the schema validation
    captureException(e);
    return null;
  }
};

const validatePerspectiveAgainstBasicSchema = (perspective: ExportedPerspectiveWithStyle | string) =>
  validatePerspective(perspective, perspectiveBasicSchema);

export const validatePerspectiveAgainstCompleteSchema = (perspective: ExportedPerspectiveWithStyle | string) =>
  validatePerspective(perspective, perspectiveSchemaComplete);

export const migrateOrRejectPerspective = (perspective: Nullable<ExportedPerspectiveWithStyle>): ValidationResult => {
  const result: ValidationResult = {
    perspective: null,
    error: null,
  };

  if (!perspective) {
    return result;
  }

  const currentVersion = getLatestPerspectiveVersion() ?? FIRST_VERSION;
  const perspectiveVersion = perspective.version ?? FIRST_VERSION;

  if (versionsEqual(currentVersion, perspectiveVersion)) {
    result.perspective = perspective;
    return result;
  } else if (versionLater(currentVersion, perspectiveVersion)) {
    const migratedPerspectives = migrate(
      {
        perspectives: [perspective],
      },
      trasformations,
      perspective.version,
    );

    if (!migratedPerspectives || !migratedPerspectives.perspectives || migratedPerspectives.perspectives.length === 0) {
      result.error = 'Empty or malformed perspective';
      return result;
    }

    const [migratedPerspective] = migratedPerspectives.perspectives;
    migratedPerspective.version = currentVersion;

    result.perspective = migratedPerspective;
    return result;
  }
  const versionDiff = versionsDifference(currentVersion, perspectiveVersion);
  if (versionDiff !== null && ['patch', 'prepatch'].includes(versionDiff)) {
    result.perspective = perspective;
    return result;
  }
  const message = `The perspective ${perspective.name} is from a later version of Neo4j Bloom. Please upgrade the Neo4j Bloom app (${getClientMigrationVersion()}) to use this perspective.`;
  log.warn(message);

  result.error = message;
  return result;
};

export const processForImport = (perspectives: Nullable<ExportedPerspectiveWithStyle[] | string[]>) => {
  const perspectiveResults: ValidationResult[] = pipe(
    ensureItIsArray,
    map(validatePerspectiveAgainstBasicSchema),
    map(migrateOrRejectPerspective),
    map((result) => {
      if (result.error === null && result.perspective) {
        result.perspective = validatePerspectiveAgainstCompleteSchema(result.perspective);
      }
      return result;
    }),
    map(renameDuplicateCategories),
  )(perspectives);

  let error = null;
  let processedPerspectives: Nullable<ExportedPerspectiveWithStyle[]> = null;

  if (perspectiveResults.some((p) => isNull(p.perspective) && isNull(p.error))) {
    error = 'Perspective file could not be loaded, check file format';
  } else if (perspectiveResults.some((perspective) => perspective.error)) {
    error = perspectiveResults.find((perspective) => perspective.error)?.error ?? null;
  } else {
    processedPerspectives = perspectiveResults.map((p) => p.perspective).filter(isNotNullish);
  }

  return {
    error,
    perspectives: processedPerspectives,
  };
};

export const perspectiveNeedsMetadataMigration = (perspective: PerspectiveWithStyle) =>
  perspective && (!perspective.labels || Object.keys(perspective.labels).length === 0);
