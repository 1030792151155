import { Banner, Button, Dialog, Select, TextInput, TextLink, Tooltip, Typography } from '@neo4j-ndl/react';
import { QuestionMarkCircleIconOutline } from '@neo4j-ndl/react/icons';
import type { EncryptionKey, Project } from '@nx/state';
import { CLOUD_PROVIDER, TIER, consoleApi, getApiError } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useMemo, useState } from 'react';
import * as yup from 'yup';

import type { Validation } from '../../../utils/validation';
import { validateYup } from '../../../utils/validation';
import { getCloudProviders, getProductFromTier, getRegionsForTier } from '../../entities/helpers';
import { getKeyLabel } from '../entities/helpers';

type TierOption = {
  key: TIER;
  value: TIER;
  label: string;
};

type RegionOption = {
  key: string;
  value: string;
  label: string;
};

interface EncryptionKeyFormData {
  tier?: TIER;
  region?: string;
  name: string;
  keyId: string;
}

const schema = yup.object({
  tier: yup.string().oneOf(Object.values(TIER)).required().label('Instance Type'),
  region: yup.string().required().label('Region'),
  name: yup.string().required().label('Name'),
  keyId: yup.string().required().label('KeyId'),
});

const validate = (data: EncryptionKeyFormData): Validation<EncryptionKeyFormData> | null => {
  return validateYup(schema, data);
};

const keyDefaults: EncryptionKeyFormData = {
  tier: undefined,
  region: undefined,
  name: '',
  keyId: '',
};

export const CmekForm = ({
  project,
  updateStep,
  onClose,
  updateEncryptionKey,
}: {
  project: Project;
  updateStep: () => void;
  onClose: () => void;
  updateEncryptionKey: (encryptionKey: EncryptionKey) => void;
}) => {
  const [data, setData] = useState<EncryptionKeyFormData>({ ...keyDefaults });
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  // const defaultErrorHandler = useDefaultErrorHandler();
  const [validation, setValidation] = useState<Validation<EncryptionKeyFormData>>({});
  const [createValidationError, setCreateValidationError] = useState<string>('');
  const { tierConfigs } = project;
  const cloudProviders = getCloudProviders(tierConfigs);

  const [createEncryptionKey, createEncryptionKeyRes] = consoleApi.useCreateEncryptionKeyMutation();

  const handleSubmitAndNextStep = () => {
    setCreateValidationError('');
    const errors = validate(data);
    if (errors) {
      setValidation(errors);
      return;
    }
    setValidation({});

    if (isNullish(data.tier) || isNullish(data.region) || !data.name || !data.keyId || isNullish(cloudProviders[0])) {
      return;
    }

    createEncryptionKey({
      projectId: project.id,
      tier: data.tier,
      region: data.region,
      name: data.name,
      keyId: data.keyId,
      cloudProvider: cloudProviders[0],
    })
      .unwrap()
      .then((res) => {
        setData({ ...keyDefaults });
        updateStep();
        updateEncryptionKey(res);
      })
      .catch((err: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(err);
        if (error.code === 422) {
          const errorMessage = isNotNullish(error.message) ? error.message : 'Ensure that the key name is unique.';
          const errorText = `Error: Unable to add key. ${errorMessage}`;
          setCreateValidationError(errorText);
        } else {
          const errorText = `Error: Unable to add key.`;
          setCreateValidationError(errorText);
        }
      });
  };
  const handleTierChange = (option: TierOption) =>
    setData((prev) => ({ ...prev, tier: option.value, region: undefined }));

  const handleRegionChange = (option: RegionOption) => setData((prev) => ({ ...prev, region: option.value }));

  const handleNameChange = (e: { target: { value: string } }) => {
    const newValue = e.target.value;
    setData((prev) => ({ ...prev, name: newValue }));
  };

  const handleKeyIdChange = (e: { target: { value: string } }) => {
    const newValue = e.target.value;
    setData((prev) => ({ ...prev, keyId: newValue }));
  };

  const tierOptions = useMemo(() => {
    const productTiers = [tierConfigs.enterprise, tierConfigs.dsenterprise].filter(isNotNullish);
    return productTiers.map((tier) => {
      return {
        key: tier.tier,
        value: tier.tier,
        label: getProductFromTier(tier.tier),
      };
    });
  }, [tierConfigs]);

  const regionOption = useMemo(() => {
    const regions = isNotNullish(data.tier) ? getRegionsForTier(data.tier, tierConfigs) : [];
    return regions.map((r) => ({
      key: r.name,
      label: r.friendly,
      value: r.name,
    }));
  }, [data.tier, tierConfigs]);

  const dataIsValid = isNotNullish(data.tier) && isNotNullish(data.region) && data.keyId !== '' && data.name !== '';
  return (
    <>
      <Dialog.Header>New Customer Managed Key</Dialog.Header>
      <Dialog.Content className="flex flex-col gap-4">
        <Banner type="warning" usage="inline">
          Neo4j Aura will not be able to administer instances where keys are deleted or permissions revoked.
        </Banner>
        <Select
          size="medium"
          type="select"
          label="Instance Type"
          errorText={validation.tier?.message}
          selectProps={{
            options: tierOptions,
            value: tierOptions.find((t) => t.value === data.tier),
            onChange: (value) => value && handleTierChange(value),
          }}
          htmlAttributes={{
            'data-testid': 'cmek-select-product',
          }}
        />
        {/* Select Region*/}
        <Select
          size="medium"
          type="select"
          label="Region"
          errorText={validation.region?.message}
          selectProps={{
            options: regionOption,
            value: regionOption.find((r) => r.value === data.region),
            onChange: (value) => value && handleRegionChange(value),
          }}
          htmlAttributes={{
            'data-testid': 'cmek-select-region',
          }}
        />
        {/* Enter name*/}
        <TextInput
          isFluid
          label="Encryption Key Name"
          value={data.name}
          onChange={handleNameChange}
          errorText={validation.name?.message}
          htmlAttributes={{
            'aria-label': 'Encryption Key Name',
            placeholder: 'Enter a name...',
            'data-testid': 'cmek-name-input',
          }}
        />
        {/* Key ID*/}
        <div>
          <TextInput
            isFluid
            label={
              cloudProviders[0] === CLOUD_PROVIDER.AZURE ? (
                <div className="flex">
                  <div>Key Identifier</div>
                  <div onMouseLeave={() => setIsTooltipOpen(false)}>
                    <Tooltip type="simple" isOpen={isTooltipOpen} isPortaled={false}>
                      <Tooltip.Trigger
                        htmlAttributes={{
                          onMouseEnter: () => setIsTooltipOpen(true),
                        }}
                      >
                        <span className="inline">
                          <QuestionMarkCircleIconOutline
                            name="QuestionMarkCircleIconOutline"
                            type="solid"
                            className="mb-1 ml-1 inline-block h-5 w-5"
                            aria-label="Help Azure Key Identifier"
                          />
                        </span>
                      </Tooltip.Trigger>
                      <Tooltip.Content
                        className="max-w-72 sm:max-w-sm"
                        htmlAttributes={{
                          onMouseLeave: () => setIsTooltipOpen(false),
                        }}
                      >
                        <Typography variant="body-small" as="p">
                          Follow the documentation{' '}
                          <TextLink
                            href="https://neo4j.com/docs/aura/platform/security/encryption/#_azure_keys"
                            className="text-neutral-text-inverse hover:text-neutral-text-inverse"
                            isExternalLink
                          >
                            Azure keys
                          </TextLink>{' '}
                          for where to find your key identifier
                        </Typography>
                      </Tooltip.Content>
                    </Tooltip>
                  </div>
                </div>
              ) : (
                getKeyLabel(cloudProviders[0])
              )
            }
            value={data.keyId}
            onChange={handleKeyIdChange}
            errorText={validation.keyId?.message}
            htmlAttributes={{
              'data-testid': 'cmek-key-id-input',
              'aria-label': getKeyLabel(cloudProviders[0]),
              placeholder: `Enter the ${getKeyLabel(cloudProviders[0])}...`,
            }}
          />
          {cloudProviders[0] === CLOUD_PROVIDER.GCP && (
            <p className="mt-2 text-xs font-light">
              <span>Example format:</span>
              <br />
              <code className="text-xs font-light">
                {'projects/<project-name>/locations/<location-name>/keyRings/<keyring-name>/cryptoKeys/<key-name>'}
              </code>
            </p>
          )}
        </div>
        {createValidationError && (
          <Banner className="mt-4" description={createValidationError} type="danger" usage="inline" />
        )}
      </Dialog.Content>
      <Dialog.Actions className="justify-between">
        <div className="flex gap-x-4">
          <Button fill="outlined" color="neutral" onClick={onClose}>
            Cancel
          </Button>
        </div>
        <Button
          isDisabled={!dataIsValid}
          color="primary"
          onClick={handleSubmitAndNextStep}
          isLoading={createEncryptionKeyRes.isLoading}
          htmlAttributes={{
            'data-testid': 'submit-cmek-button',
          }}
        >
          Add Key
        </Button>
      </Dialog.Actions>
    </>
  );
};
