import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';

export const FEATURE_FLAGS = [
  'example:hello-world',
  'import:json-schema',
  'import:more-cloud-sources',
  'import:generate-csv-model-with-llm',
  'import:genai-flow',
  'import:api-dynamic-data-sources',
  'import:visualise-neo4j-schema',
  'nx:enable-debug',
  'nx:debugger',
  'nx:centralized-storage',
  'nx:graph-styling-shared-storage',
  'nx:enable-gql-errors-and-notifications',
  'query:import-grass',
  'query:saved-cypher-shared-storage',
  'query:editor-history-shared-storage',
  'query:enable-sysinfo-frame',
  'query:enable-cypher25-support',
  'query:parameters-panel',
  'query:format',
  'shared:unit-test',
  'explore:default-perspective',
  'explore:verlet-layout',
  'explore:shared-storage',
  'upx:manage-project-roles',
  'upx:remote-connections',
  'dashboards:enabled',
  'dashboards:show-grid',
  'local:allow-unsupported-neo4j-versions',
] as const;

export type FeatureFlag = (typeof FEATURE_FLAGS)[number];

export type FeatureFlagsState = Record<string, boolean>;

export function isFeatureFlag(input: string): input is FeatureFlag {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return FEATURE_FLAGS.includes(input as FeatureFlag);
}

/**
 * Map dependent flags to their dependencies
 */
const FLAG_DEPENDENCIES: Partial<Record<FeatureFlag, FeatureFlag[]>> = {};

function getFlagDependencies(flag: FeatureFlag): FeatureFlag[] {
  return FLAG_DEPENDENCIES[flag] ?? [];
}

function getDependentFlags(flag: FeatureFlag): FeatureFlag[] {
  return Object.entries(FLAG_DEPENDENCIES).reduce((flags: FeatureFlag[], [key, value]) => {
    // This redundant check makes TS happy
    if (!isFeatureFlag(key)) {
      return flags;
    }

    return value.includes(flag) ? [...flags, key] : flags;
  }, []);
}

export const FEATURE_FLAGS_PERSISTED_KEYS: (keyof FeatureFlagsState)[] = FEATURE_FLAGS.slice();

export const initialState: FeatureFlagsState = {};

export const slice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    purged(state: FeatureFlagsState) {
      for (const key in state) {
        if (!isFeatureFlag(key)) {
          // remove outdated flags
          delete state[key];
        }
      }
    },
    /**
     * Toggle or set feature flag value
     * @param state
     * @param action
     */
    toggled(state: FeatureFlagsState, action: PayloadAction<{ key: FeatureFlag; value: boolean | undefined }>) {
      const currentFlagState = state[action.payload.key] ?? false;
      const desiredFlagState = action.payload.value ?? !currentFlagState;
      state[action.payload.key] = desiredFlagState;

      if (desiredFlagState) {
        getFlagDependencies(action.payload.key).forEach((flagDependency) => {
          state[flagDependency] = true;
        });
      } else {
        getDependentFlags(action.payload.key).forEach((dependentFlag) => {
          state[dependentFlag] = false;
        });
      }
    },
  },
});

export const { purged: purge, toggled: toggle } = slice.actions;

export const selectEnabledFeatureFlags: (state: FeatureFlagsState) => FeatureFlag[] = createSelector(
  (state: FeatureFlagsState) => state,
  (flags) => {
    const enabledFlags: FeatureFlag[] = [];
    for (const key in flags) {
      if (isFeatureFlag(key) && flags[key] === true) {
        enabledFlags.push(key);
      }
    }
    return enabledFlags;
  },
);

export function makeSelectFeatureFlag(key: FeatureFlag, override = false): (state: FeatureFlagsState) => boolean {
  return (state: FeatureFlagsState) => override || (state[key] ?? false);
}

export default slice.reducer;
