import { WithStatusIndicator } from '@nx/ui';
import classNames from 'classnames';
import type { ComponentProps } from 'react';

import classes from './common.module.css';

export const WithUnretrievedStatusIndicator = (
  props: Pick<ComponentProps<typeof WithStatusIndicator>, 'active' | 'className' | 'children'>,
) => {
  return (
    <WithStatusIndicator
      active={props.active}
      indicatorProps={{
        type: 'info',
        color: 'info',
        className: classNames('right-[2px] top-[6px]', classes.unretrievedDownloadStatusIndicator),
      }}
      className={props.className}
    >
      {props.children}
    </WithStatusIndicator>
  );
};
