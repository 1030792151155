import { Banner, LoadingSpinner, TextLink, Typography } from '@neo4j-ndl/react';
import { consoleApi, login, useAuth } from '@nx/state';
import { isNullish } from '@nx/stdlib';
import { Center, useRouterState } from '@nx/ui';
import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const DEFAULT_ROUTE_AFTER_LOGIN = '/';

export const usePrefetchUserQueries = () => {
  const auth = useAuth();
  const userDetailsRes = consoleApi.useGetUserDetailsQuery(auth.user ? undefined : skipToken);
  const listOrgsRes = consoleApi.useListOrganizationsByUserQuery(userDetailsRes.data?.id ?? skipToken);
  const listProjectsRes = consoleApi.useListProjectsByUserQuery(userDetailsRes.data?.id ?? skipToken);
  return {
    isLoading: userDetailsRes.isLoading || listOrgsRes.isLoading || listProjectsRes.isLoading,
  };
};

export function Login() {
  const auth = useAuth();
  const userQueries = usePrefetchUserQueries();
  const routerState = useRouterState();
  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();
  const returnTo =
    auth.user?.state?.returnTo ??
    routerState?.returnTo ??
    `${DEFAULT_ROUTE_AFTER_LOGIN}${queryString !== '' ? `?${queryString}` : ''}`;

  useEffect(() => {
    if (auth.isLoading) {
      return;
    }

    if (auth.isAuthenticated) {
      // Hard redirect to restart application bootstrapping
      window.location.replace(returnTo);
    } else {
      void login(undefined, { state: { returnTo } }, isNullish(auth.userId));
    }
  }, [auth.isAuthenticated, auth.isLoading, returnTo, auth.userId]);

  if (auth.isLoading || userQueries.isLoading) {
    return (
      <Center>
        <LoadingSpinner size="large" />
      </Center>
    );
  }

  return null;
}

export function LoginCallback() {
  const auth = useAuth();
  const userQueries = usePrefetchUserQueries();
  const routerState = useRouterState();
  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();
  const returnTo =
    auth.user?.state?.returnTo ??
    routerState?.returnTo ??
    `${DEFAULT_ROUTE_AFTER_LOGIN}${queryString !== '' ? `?${queryString}` : ''}}`;

  useEffect(() => {
    if (auth.isAuthenticated) {
      // Hard redirect to restart application bootstrapping
      window.location.replace(returnTo);
    }
  }, [auth.isAuthenticated, returnTo]);

  if (auth.isLoading || userQueries.isLoading) {
    return (
      <Center>
        <LoadingSpinner size="large" />
      </Center>
    );
  }

  if (auth.error && !auth.isAuthenticated) {
    return (
      <Center>
        <Banner
          title={auth.error.message}
          description={
            <Typography variant="body-large">
              Click{' '}
              <TextLink
                className="cursor-pointer"
                htmlAttributes={{
                  onClick: () => {
                    void login(undefined, { prompt: 'login' });
                  },
                }}
              >
                here
              </TextLink>{' '}
              to login again
            </Typography>
          }
          type="danger"
          usage="inline"
        />
      </Center>
    );
  }

  return null;
}
