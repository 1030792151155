import { type CSSProperties, type SVGProps, useMemo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BlobThreeNodes = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={167} height={234} fill="#E2E3E5" {...props}>
    <path d="M97.058 206.955c-3.36-12.999-9.507-25.768-20.084-33.163-20.617-14.544-43.22-2.261-61.59-13.99-15.768-11.124-19.54-32.928-8.422-48.693C18.613 94.013 45.59 98.455 60.575 77.192c15.559-22.08 1.74-45.195 13.92-61.918C85.613-.5 107.406-4.27 123.165 6.855c15.769 11.124 19.54 32.928 8.423 48.693-11.652 17.096-38.425 12.377-53.613 33.918-13.945 21.287-9.077 52.562 11.264 66.911 22.215 15.672 45.164 1.751 61.891 13.939 48.961 41.487-33.072 97.627-54.08 36.641l.01-.002Z" />
  </svg>
);

const BlobFiveNodes = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={307} height={344} fill="#E2E3E5" {...props}>
    <path d="M172.369 338.269c21.89-12.555 20.78-41.551 43.844-56.285 20.594-14.162 50.579 3.089 70.802-8.792 18.864-10.713 25.482-34.689 14.766-53.547-10.715-18.859-34.686-25.475-53.56-14.763-20.562 11.247-21.155 45.879-43.845 56.285-17.825 9.422-37.951-2.515-39.014-23.191-1.722-25.783 27.327-40.71 27.786-65.706.323-25.296-27.084-40.288-26.925-66.722-.789-33.281 43.076-49.27 22.601-85.704-12.842-24.16-49.656-26.784-65.184-4.298-9.723 15.945-15.067 35.706-32.221 45.82-20.594 14.162-50.579-3.088-70.802 8.793C1.753 80.871-4.865 104.847 5.85 123.706c10.715 18.858 34.686 25.475 53.56 14.762 20.562-11.246 21.156-45.878 43.845-56.284 17.509-10.111 39.546 3.02 39.014 23.19.292 26.352-27.327 40.71-27.786 65.706-.323 25.297 27.94 40.498 26.925 66.723-.616 33.324-43.076 49.268-22.601 85.703 10.715 18.859 34.686 25.475 53.561 14.763Z" />
  </svg>
);

type BlobPosition = 't' | 'b';
type BlobLength = 1 | 2 | 3;
type Blob<T extends BlobPosition = BlobPosition> = `${T}${BlobLength}`;

const commonBlobStyles: CSSProperties = { position: 'absolute', right: 0 };

const blobStyles: { [key in Blob]?: CSSProperties } = {
  t3: { top: 0, scale: '.65 .65', translate: '-3% -42%', rotate: '66deg' },
  t2: { top: 0, scale: '-.65 .65', translate: '-5% -59%', rotate: '-75deg' },
  t1: { top: 0, scale: '.65 .65', translate: '25% -77%', rotate: '66deg' },
  b3: { bottom: 0, scale: '-.65 .65', translate: '-4% 42%', rotate: '-66deg' },
  b2: { bottom: 0, scale: '.65 .65', translate: '-3% 63%', rotate: '66deg' },
  b1: { bottom: 0, scale: '-.65 .65', translate: '30% 77%', rotate: '-75deg' },
};

const blobPairs = [
  { top: blobStyles.t3, bottom: blobStyles.b2 },
  { top: blobStyles.t3, bottom: blobStyles.b1 },
  { top: blobStyles.t2, bottom: blobStyles.b3 },
  { top: blobStyles.t2, bottom: blobStyles.b2 },
  { top: blobStyles.t2, bottom: blobStyles.b1 },
  { top: blobStyles.t1, bottom: blobStyles.b3 },
  { top: blobStyles.t1, bottom: blobStyles.b2 },
];

const randomBlobPair = () => {
  const rand = Math.floor(Math.random() * blobPairs.length);
  return blobPairs[rand] ?? { top: blobStyles.t1, bottom: blobStyles.b3 };
};

export const RandomBlobs = () => {
  const { top, bottom } = useMemo(() => {
    return randomBlobPair();
  }, []);
  return (
    <>
      <BlobFiveNodes style={{ ...commonBlobStyles, ...top, fill: 'rgb(var(--theme-palette-neutral-bg-default' }} />
      <BlobFiveNodes style={{ ...commonBlobStyles, ...bottom, fill: 'rgb(var(--theme-palette-neutral-bg-default' }} />
    </>
  );
};
