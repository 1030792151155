import type { Node } from '@neo4j-nvl/base';
import type NVL from '@neo4j-nvl/base';
import { scaleLog } from 'd3-scale';
import { debounce } from 'lodash-es';

import type { ZoomOptions } from '../../state/visualization/types';

export const ZoomFloor = 0.075;
export const ZoomCeiling = 5;
export const FitZoomFloor = 0.1;
export const FitZoomCeiling = 2.5;
export const WebGLThreshold = 0.75;
export const DefaultZoomLevel = 0.75;
const ZoomFactor = 0.04;
const ZoomThrottling = 25;

export const getZoomScale = (): ((value: number) => number) => {
  const scale = scaleLog().domain([ZoomFloor, DefaultZoomLevel, ZoomCeiling]).range([1, 100, 400]);
  return scale.clamp(true);
};

export const zoomUpdated = debounce((zoomLevel, setZoomLevelAction, hidePropertyPreview): void => {
  setZoomLevelAction(zoomLevel);
  hidePropertyPreview();
}, ZoomThrottling);

const normalizeZoomValue = (value: number): number => {
  if (value < ZoomFloor) {
    return ZoomFloor;
  } else if (value > ZoomCeiling) {
    return ZoomCeiling;
  }
  return value;
};

export const panUpdated = debounce((panCoordinates, setPanCoordinatesAction) => {
  setPanCoordinatesAction(panCoordinates);
}, ZoomThrottling);

export const zoomIn = (nvl: NVL | null, setZoomLevelAction: (zoomLevel: number) => void) => {
  if (!nvl) {
    return;
  }
  const currentScale = nvl.getScale();
  const newScale = normalizeZoomValue((1 + ZoomFactor) * currentScale);

  if (currentScale !== newScale) {
    nvl.setZoom(newScale);
    setZoomLevelAction(newScale);
  }
};

export const zoomOut = (nvl: NVL | null, setZoomLevelAction: (zoomLevel: number) => void) => {
  if (!nvl) {
    return;
  }
  const currentScale = nvl.getScale();
  const newScale = normalizeZoomValue((1 - ZoomFactor) * currentScale);

  if (currentScale !== newScale) {
    nvl.setZoom(newScale);
    setZoomLevelAction(newScale);
  }
};

export const fitNodes = (nvl: NVL | null, nodes: Node[], options: ZoomOptions = {}) => {
  nvl?.fit(
    nodes.map((n) => n.id),
    {
      minZoom: FitZoomFloor,
      maxZoom: FitZoomCeiling,
      ...options,
    },
  );
};

export const hasRendererThresholdBeenCrossed = (currentZoomLevel: number, newZoomLevel: number): boolean => {
  return (
    (currentZoomLevel <= WebGLThreshold && newZoomLevel > WebGLThreshold) ||
    (currentZoomLevel > WebGLThreshold && newZoomLevel < WebGLThreshold)
  );
};

export const getFittingDelay = (addedElementsCount: number): number => {
  return Math.min(3000, Math.max(250, addedElementsCount));
};

export const forceJumpToWebGL = (nvl: NVL, currentZoomLevel: number) => {
  if (!nvl || currentZoomLevel <= WebGLThreshold) {
    return;
  }
  nvl.setUseWebGLRenderer(true);
  nvl.setZoom(WebGLThreshold);
};
