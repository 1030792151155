import { Checkbox, Tooltip } from '@neo4j-ndl/react';
import type { ComponentPropsWithoutRef } from 'react';

type MultiInstanceSeamlessConnectionCheckboxProps = {
  id: string;
  name: string;
  checked: boolean;
  indeterminate: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
} & ComponentPropsWithoutRef<'div'>;

export const MultiInstanceSeamlessConnectionCheckbox = ({
  id,
  name,
  checked,
  indeterminate,
  onChange,
  disabled,
  ...divProps
}: MultiInstanceSeamlessConnectionCheckboxProps) => {
  return (
    <div {...divProps}>
      <Tooltip type="simple">
        <Tooltip.Trigger>
          <Checkbox
            isChecked={!disabled && checked}
            ref={(node) => {
              if (node) {
                node.indeterminate = indeterminate;
              }
            }}
            onChange={onChange}
            ariaLabel={`Enable connection via users' project roles for all instances in ${name}`}
            isDisabled={disabled}
            label={<div className="break-words text-base font-semibold">{name}</div>}
            htmlAttributes={{
              'data-testid': `enable-seamless-connection-for-all-${id}`,
            }}
          />
        </Tooltip.Trigger>
        <Tooltip.Content>
          {!disabled
            ? `${checked ? 'Disable' : 'Enable'} connection via users' project roles for all instances in ${name}`
            : `There are no instances in ${name}`}
        </Tooltip.Content>
      </Tooltip>
    </div>
  );
};

type InstanceSeamlessConnectionCheckboxProps = {
  id: string;
  name: string;
  immutable: boolean;
  immutableReason: string | null;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & ComponentPropsWithoutRef<'div'>;

export const InstanceSeamlessConnectionCheckbox = ({
  id,
  name,
  immutable,
  immutableReason,
  checked,
  onChange,
  ...divProps
}: InstanceSeamlessConnectionCheckboxProps) => {
  let tooltipText: string;
  if (immutableReason !== null) {
    tooltipText = immutableReason;
  } else if (checked) {
    tooltipText = `Disable connection via users' project roles for ${name}`;
  } else {
    tooltipText = `Enable connection via users' project roles for ${name}`;
  }

  return (
    <div {...divProps}>
      <Tooltip type="simple">
        <Tooltip.Trigger>
          <Checkbox
            isChecked={checked}
            onChange={onChange}
            isDisabled={immutable}
            ariaLabel={`Enable connection via users' project roles for ${name}`}
            label={
              <div className="break-words">
                {name} ({id})
              </div>
            }
            htmlAttributes={{
              'data-testid': `enable-seamless-connection-${id}`,
            }}
          />
        </Tooltip.Trigger>

        <Tooltip.Content>{tooltipText}</Tooltip.Content>
      </Tooltip>
    </div>
  );
};

type DefaultSeamlessConnectionCheckboxProps = {
  id: string;
  name: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & ComponentPropsWithoutRef<'div'>;

export const DefaultSeamlessConnectionCheckbox = ({
  id,
  name,
  checked,
  onChange,
  ...divProps
}: DefaultSeamlessConnectionCheckboxProps) => {
  return (
    <div {...divProps}>
      <Checkbox
        isChecked={checked}
        onChange={onChange}
        label="Enable for new instances"
        ariaLabel={`Enable connection via users' project roles for new instances in ${name}`}
        htmlAttributes={{
          'data-testid': `enable-seamless-connection-by-default-${id}`,
        }}
      />
    </div>
  );
};
