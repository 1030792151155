import { isNotNullish, isNullish } from '@nx/stdlib';

import type {
  CreateDataApiAuthProviderRequest,
  CreateDataApiAuthProviderResponse,
  CreateDataApiRequest,
  CreateDataApiResponse,
  DeleteDataApiAuthProviderRequest,
  DeleteDataApiAuthProviderResponse,
  DeleteDataApiRequest,
  DeleteDataApiResponse,
  GetDataApiAuthProvidersBatchRequest,
  GetDataApiAuthProvidersBatchResponse,
  GetDataApiAuthProvidersResponse,
  GetDataApiDetailedAuthProvidersResponse,
  GetDataApiRequest,
  GetDataApiResponse,
  GetDataApiResponseTransformed,
  GetDataApisBatchRequest,
  GetDataApisBatchResponse,
  GetDataApisRequest,
  GetDataApisResponse,
  PauseDataApiRequest,
  PauseDataApiResponse,
  ResumeDataApiRequest,
  ResumeDataApiResponse,
  UpdateDataApiAuthProviderRequest,
  UpdateDataApiAuthProviderResponse,
  UpdateDataApiRequest,
  UpdateDataApiResponse,
} from '../api-types/graphql';
import {
  transformCreateDataApiAuthProviderRequest,
  transformCreateDataApiRequest,
  transformGetDataApiResponse,
  transformUpdateDataApiAuthProviderRequest,
  transformUpdateDataApiRequest,
} from '../transformers/graphql';
import type { Builder } from './types';

export const graphqlEndpoints = (builder: Builder) => ({
  getDataApis: builder.query<GetDataApisResponse, GetDataApisRequest>({
    query: (dataApiRequest) => ({
      url: `/instances/${dataApiRequest.instanceId}/data-apis/graphql`,
      method: 'GET',
      headers: { 'Project-Id': dataApiRequest.projectId },
    }),
    providesTags: (result, error, dataApiRequest) => [{ type: 'GraphQL', id: dataApiRequest.instanceId }],
  }),
  getDataApisBatch: builder.query<GetDataApisBatchResponse, GetDataApisBatchRequest[]>({
    async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
      try {
        const results = await Promise.all(
          args.map(async ({ instanceId, instanceName, projectId }) => {
            const result = await fetchWithBQ({
              url: `/instances/${instanceId}/data-apis/graphql`,
              method: 'GET',
              headers: { 'Project-Id': projectId },
            });

            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            return { instanceId, instanceName, result: result.data as GetDataApisResponse, error: result.error };
          }),
        );

        const hasError = results.some((result) => result.error);
        if (hasError) {
          const errors = results.filter((result) => isNotNullish(result.error)).map((result) => result.error);
          // eslint-disable-next-line no-console
          console.error('Failed to fetch Data APIs. Request error(s):', { errors });
          return {
            error: {
              status: 'CUSTOM_ERROR',
              error: 'Failed to fetch Data APIs, request(s) failed.',
            },
          };
        }

        return {
          data: results
            .filter(({ result }) => !isNullish(result))
            .map(({ instanceId, instanceName, result }) =>
              result.data.map((data) => ({ instanceId, instanceName, ...data })),
            )
            .flat(),
        };
      } catch (error) {
        if (error instanceof Error) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              error: `Failed to fetch Data APIs, error: ${error.message}`,
            },
          };
        }
        return { error: { status: 'CUSTOM_ERROR', error: 'Failed to fetch Data APIs, unknown error' } };
      }
    },
  }),
  getDataApi: builder.query<GetDataApiResponseTransformed, GetDataApiRequest>({
    query: (dataApiRequest) => ({
      url: `/instances/${dataApiRequest.instanceId}/data-apis/graphql/${dataApiRequest.dataApiId}`,
      method: 'GET',
      headers: { 'Project-Id': dataApiRequest.projectId },
    }),
    transformResponse: (response: GetDataApiResponse, _, arg) => transformGetDataApiResponse(response, arg),
    providesTags: (result, error, dataApiRequest) => [{ type: 'GraphQL', id: dataApiRequest.dataApiId }],
  }),
  createDataApi: builder.mutation<CreateDataApiResponse, CreateDataApiRequest>({
    query: (dataApiRequest) => ({
      url: `/instances/${dataApiRequest.instanceId}/data-apis/graphql`,
      method: 'POST',
      headers: { 'Project-Id': dataApiRequest.projectId },
      body: transformCreateDataApiRequest(dataApiRequest),
    }),
    invalidatesTags: (result, error, dataApiRequest) => [{ type: 'GraphQL', id: dataApiRequest.instanceId }],
  }),
  deleteDataApi: builder.mutation<DeleteDataApiResponse, DeleteDataApiRequest>({
    query: (dataApiRequest) => ({
      url: `/instances/${dataApiRequest.instanceId}/data-apis/graphql/${dataApiRequest.dataApiId}`,
      method: 'DELETE',
      headers: { 'Project-Id': dataApiRequest.projectId },
    }),
    invalidatesTags: (result, error, dataApiRequest) => [{ type: 'GraphQL', id: dataApiRequest.instanceId }],
  }),
  updateDataApi: builder.mutation<UpdateDataApiResponse, UpdateDataApiRequest>({
    query: (dataApiRequest) => ({
      url: `/instances/${dataApiRequest.instanceId}/data-apis/graphql/${dataApiRequest.dataApiId}`,
      method: 'PATCH',
      headers: { 'Project-Id': dataApiRequest.projectId },
      body: transformUpdateDataApiRequest(dataApiRequest),
    }),
    invalidatesTags: (result, error, dataApiRequest) => [{ type: 'GraphQL', id: dataApiRequest.instanceId }],
  }),
  pauseDataApi: builder.mutation<PauseDataApiResponse, PauseDataApiRequest>({
    query: (dataApiRequest) => ({
      url: `/instances/${dataApiRequest.instanceId}/data-apis/graphql/${dataApiRequest.dataApiId}/pause`,
      method: 'POST',
      headers: { 'Project-Id': dataApiRequest.projectId },
    }),
    invalidatesTags: (result, error, dataApiRequest) => [{ type: 'GraphQL', id: dataApiRequest.instanceId }],
  }),
  resumeDataApi: builder.mutation<ResumeDataApiResponse, ResumeDataApiRequest>({
    query: (dataApiRequest) => ({
      url: `/instances/${dataApiRequest.instanceId}/data-apis/graphql/${dataApiRequest.dataApiId}/resume`,
      method: 'POST',
      headers: { 'Project-Id': dataApiRequest.projectId },
    }),
    invalidatesTags: (result, error, dataApiRequest) => [{ type: 'GraphQL', id: dataApiRequest.instanceId }],
  }),
  getAuthProvidersBatch: builder.query<GetDataApiAuthProvidersBatchResponse, GetDataApiAuthProvidersBatchRequest>({
    async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
      try {
        const resultAllAuthProviders = await fetchWithBQ({
          url: `/instances/${args.instanceId}/data-apis/graphql/${args.dataApiId}/auth-providers`,
          method: 'GET',
          headers: { 'Project-Id': args.projectId },
        });

        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const authProviders = resultAllAuthProviders.data as GetDataApiAuthProvidersResponse;

        if (resultAllAuthProviders.error) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              error: `Failed to fetch all Authentication providers for Data API, error:' ${JSON.stringify(resultAllAuthProviders.error)}`,
            },
          };
        }

        const resultDetailedAuthProviders = await Promise.all(
          authProviders.data.map(async ({ id }) => {
            const result = await fetchWithBQ({
              url: `/instances/${args.instanceId}/data-apis/graphql/${args.dataApiId}/auth-providers/${id}`,
              method: 'GET',
              headers: { 'Project-Id': args.projectId },
            });

            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            return { result: result.data as GetDataApiDetailedAuthProvidersResponse, error: result.error };
          }),
        );

        const hasError = resultDetailedAuthProviders.some((result) => result.error);
        if (hasError) {
          const errors = resultDetailedAuthProviders
            .filter((result) => isNotNullish(result.error))
            .map((result) => result.error);
          return {
            error: {
              status: 'CUSTOM_ERROR',
              error: `Failed to fetch individual Authentication providers for Data API, request(s) failed, error(s):' ${JSON.stringify(errors)}`,
            },
          };
        }

        return {
          data: resultDetailedAuthProviders
            .filter(({ result }) => !isNullish(result))
            .map(({ result }) => result.data)
            .flat(),
        };
      } catch (error) {
        if (error instanceof Error) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              error: `Failed to fetch Authentication providers for Data API, error: ${error.message}`,
            },
          };
        }
        return {
          error: {
            status: 'CUSTOM_ERROR',
            error: 'Failed to fetch Authentication providers for Data API, unknown error',
          },
        };
      }
    },
  }),
  createAuthProvider: builder.mutation<CreateDataApiAuthProviderResponse, CreateDataApiAuthProviderRequest>({
    query: (args) => ({
      url: `/instances/${args.instanceId}/data-apis/graphql/${args.dataApiId}/auth-providers`,
      method: 'POST',
      headers: { 'Project-Id': args.projectId },
      body: transformCreateDataApiAuthProviderRequest(args),
    }),
    invalidatesTags: (result, error, authProviderRequest) => [{ type: 'GraphQL', id: authProviderRequest.instanceId }],
  }),
  deleteAuthProvider: builder.mutation<DeleteDataApiAuthProviderResponse, DeleteDataApiAuthProviderRequest>({
    query: (args) => ({
      url: `/instances/${args.instanceId}/data-apis/graphql/${args.dataApiId}/auth-providers/${args.authProviderId}`,
      method: 'DELETE',
      headers: { 'Project-Id': args.projectId },
    }),
    invalidatesTags: (result, error, authProviderRequest) => [{ type: 'GraphQL', id: authProviderRequest.instanceId }],
  }),
  updateAuthProvider: builder.mutation<UpdateDataApiAuthProviderResponse, UpdateDataApiAuthProviderRequest>({
    query: (args) => ({
      url: `/instances/${args.instanceId}/data-apis/graphql/${args.dataApiId}/auth-providers/${args.authProviderId}`,
      method: 'PATCH',
      headers: { 'Project-Id': args.projectId },
      body: transformUpdateDataApiAuthProviderRequest(args),
    }),
    invalidatesTags: (result, error, authProviderRequest) => [{ type: 'GraphQL', id: authProviderRequest.instanceId }],
  }),
});
