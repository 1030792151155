import { isNonEmptyString } from '@nx/stdlib';
import type {
  ColumnDef,
  ColumnDefResolved,
  ColumnOrderState,
  ColumnOrderTableState,
  OnChangeFn,
  VisibilityState,
  VisibilityTableState,
} from '@tanstack/react-table';
import { functionalUpdate } from '@tanstack/react-table';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';

type TablePrefs = VisibilityTableState & ColumnOrderTableState;

type UseColumnPreferences = <T>(
  logType: string,
  table: string,
  columns: ColumnDef<T>[],
) => {
  prefs: TablePrefs;
  onColumnOrderChange: OnChangeFn<ColumnOrderState>;
  onColumnVisibilityChange: OnChangeFn<VisibilityState>;
};

// one localStorage item per table
const makeKey = (logType: string, table: string) => `${logType}LogPrefs${table}`;

const storeColPrefs = (newPrefs: TablePrefs, logType: string, table: string) => {
  try {
    window.localStorage.setItem(makeKey(logType, table), JSON.stringify(newPrefs));
  } catch (error) {
    /* empty */
  }
};

const validateColumnPrefs = (initial: TablePrefs, stored: TablePrefs) => {
  const initialOrder = [...initial.columnOrder].sort();
  const storedOrder = [...stored.columnOrder].sort();
  const { columnVisibility } = stored;

  const hasColumnDiff = !isEqual(initialOrder, storedOrder);
  if (hasColumnDiff) {
    return false;
  }

  const allColumnsHidden =
    storedOrder.length <= Object.values(columnVisibility).filter((visibility) => !visibility).length;
  if (allColumnsHidden) {
    return false;
  }

  const diffInHidden = Object.keys(columnVisibility).some((hidden) => !storedOrder.includes(hidden));
  if (diffInHidden) {
    return false;
  }

  return true;
};

export const useColumnPreferences: UseColumnPreferences = (logType, table, columns) => {
  const initialPrefs = useMemo<TablePrefs>(() => {
    const prefs: TablePrefs = {
      columnVisibility: {},
      columnOrder: columns
        .map(
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          (col) => col.id ?? (col as ColumnDefResolved<unknown, unknown>).accessorKey,
        )
        .filter(isNonEmptyString),
    };

    try {
      const item = window.localStorage.getItem(makeKey(logType, table));
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const storedPrefs = isNonEmptyString(item) ? (JSON.parse(item) as TablePrefs) : null;

      return storedPrefs && validateColumnPrefs(prefs, storedPrefs) ? storedPrefs : prefs;
    } catch (error) {
      return prefs;
    }
  }, [columns, logType, table]);

  const [prefs, setPrefs] = useState(initialPrefs);

  useEffect(() => {
    storeColPrefs(prefs, logType, table);
  }, [prefs, logType, table]);

  const onColumnVisibilityChange: OnChangeFn<VisibilityState> = useCallback(
    (updater) => {
      const newVisibility = functionalUpdate(updater, prefs.columnVisibility);
      setPrefs((oldPrefs) => ({
        ...oldPrefs,
        columnVisibility: newVisibility,
      }));
    },
    [prefs.columnVisibility],
  );

  const onColumnOrderChange: OnChangeFn<ColumnOrderState> = useCallback(
    (updater) => {
      const newOrder = functionalUpdate(updater, prefs.columnOrder);
      setPrefs((oldPrefs) => ({ ...oldPrefs, columnOrder: newOrder }));
    },
    [prefs.columnOrder],
  );

  return {
    prefs,
    onColumnOrderChange,
    onColumnVisibilityChange,
  };
};
