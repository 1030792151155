import { IconButton, TextInput } from '@neo4j-ndl/react';
import { EyeIconOutline, EyeSlashIconOutline } from '@neo4j-ndl/react/icons';
import { useState } from 'react';

import type { ConnectionFormValues } from '../connection-form.types';

export const ConnectionFormBasicAuth = ({
  autoFocus,
  displayPasswordVisibilityToggle,
  formValues,
  handleInputChange,
}: {
  autoFocus?: boolean;
  displayPasswordVisibilityToggle?: boolean;
  formValues: ConnectionFormValues;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } }) => void;
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <>
      {formValues.dbName !== undefined && (
        <TextInput
          size="medium"
          isFluid
          value={formValues.dbName}
          onChange={handleInputChange}
          helpText="Leave empty for default"
          label="Database"
          isOptional
          htmlAttributes={{
            'aria-label': 'Database',
            name: 'dbName',
          }}
        />
      )}
      <TextInput
        size="medium"
        isFluid
        value={formValues.username}
        onChange={handleInputChange}
        label="Database user"
        htmlAttributes={{
          'aria-label': 'Database user',
          name: 'username',
        }}
      />
      <TextInput
        size="medium"
        isFluid
        value={formValues.password}
        onChange={handleInputChange}
        label="Password"
        htmlAttributes={{
          autoFocus: Boolean(formValues.hostname) && Boolean(autoFocus),
          type: passwordVisible ? 'text' : 'password',
          'aria-label': 'Password',
          name: 'password',
        }}
        rightElement={
          displayPasswordVisibilityToggle === true ? (
            <IconButton
              htmlAttributes={{
                type: 'button',
              }}
              onClick={(event) => {
                event.preventDefault();
                setPasswordVisible(!passwordVisible);
              }}
              isClean
              ariaLabel={passwordVisible ? 'hide password' : 'show password'}
              className="relative left-1.5"
            >
              {passwordVisible ? <EyeIconOutline /> : <EyeSlashIconOutline />}
            </IconButton>
          ) : undefined
        }
      />
    </>
  );
};
