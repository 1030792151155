import { METRIC_TYPE, TIER } from '@nx/state';

import { combineOptions } from '../plot/utils';
import type { ChartWidgetPartialProps, TransformerMetricConfig } from './fullstack-chart-props';
import * as tooltips from './help-tooltips';
import {
  BigCountersConfig,
  BytesChartConfig,
  RateChartConfig,
  TimeChartConfig,
  plotOptionsCumulativeDurationsFormatter,
  plotOptionsDuplicateBytesFormatter,
  plotOptionsYAxisIncrements,
  plotOptionsYAxisLocalRangeIncrements,
  plotOptionsYAxisValuesFormatter,
  valuesFormatter,
} from './helpers';

const isLeaderTransformerMetricConfig: TransformerMetricConfig = {
  name: 'neo4j_cluster_raft_is_leader',
  type: METRIC_TYPE.Gauge,
  aggregation: 'MAX',
  transform(transformerSeries, targetMetric) {
    if (!transformerSeries || !targetMetric) {
      return null;
    }

    const { timestamp, value } = targetMetric;

    const isLeaderAtTS = transformerSeries.find((m) => m.timestamp === timestamp)?.value;

    if (value === undefined || isLeaderAtTS === undefined) {
      return null;
    }

    return (value <= 0 && isLeaderAtTS !== 0) || value * isLeaderAtTS !== 0 ? value : null;
  },
};

export const TransactionsActiveReadChartProps: ChartWidgetPartialProps = {
  title: 'Active Transactions',
  subtitle: 'Read',
  yLabel: 'Count',
  metricName: 'neo4j_database_transaction_active_read',
  metricType: METRIC_TYPE.Gauge,
  chartType: 'DB',
  aggregations: ['SUM', 'MAX', 'MIN'],
  transformerMetricConfig: isLeaderTransformerMetricConfig,
  ...BigCountersConfig,
};

export const TransactionsActiveWriteChartProps: ChartWidgetPartialProps = {
  title: 'Active Transactions',
  subtitle: 'Write',
  yLabel: 'Count',
  metricName: 'neo4j_database_transaction_active_write',
  metricType: METRIC_TYPE.Gauge,
  chartType: 'DB',
  aggregations: ['SUM', 'MAX', 'MIN'],
  referenceMetricConfig: {
    toggledByDefault: false,
    hidden: true,
    name: 'neo4j_cluster_raft_is_leader',
    type: METRIC_TYPE.Gauge,
    aggregation: 'MAX',
  },
  transformerMetricConfig: isLeaderTransformerMetricConfig,
  ...BigCountersConfig,
};

export const TransactionsCommittedChartProps: ChartWidgetPartialProps = {
  title: 'Transactions',
  subtitle: 'Committed (count)',
  yLabel: 'Count',
  metricName: 'neo4j_database_transaction_committed_total',
  metricType: METRIC_TYPE.Gauge,
  chartType: 'DB',
  aggregations: ['SUM', 'MAX', 'MIN'],
  transformerMetricConfig: isLeaderTransformerMetricConfig,
  uplotOptions: combineOptions(
    plotOptionsYAxisValuesFormatter(valuesFormatter('bigCount', 0, 1)),
    plotOptionsYAxisIncrements(),
  ),
};

export const TransactionsCommittedRateChartProps: ChartWidgetPartialProps = {
  title: 'Transactions',
  subtitle: 'Committed (rate)',
  yLabel: 'Per minute',
  metricName: 'neo4j_database_transaction_committed_per_minute',
  cmiMetricName: 'NO_CMI_METRIC',
  metricType: METRIC_TYPE.Gauge,
  chartType: 'DB',
  aggregations: ['SUM', 'MAX', 'MIN'],
  transformerMetricConfig: isLeaderTransformerMetricConfig,
  ...RateChartConfig,
};

export const TransactionsPeakConcurrentChartProps: ChartWidgetPartialProps = {
  title: 'Transactions',
  subtitle: 'Peak Concurrent',
  yLabel: 'Count',
  metricName: 'neo4j_database_transaction_peak_concurrent_total',
  metricType: METRIC_TYPE.Gauge,
  chartType: 'DB',
  transformerMetricConfig: isLeaderTransformerMetricConfig,
  ...BigCountersConfig,
};

export const TransactionsRollbackChartProps: ChartWidgetPartialProps = {
  title: 'Transactions',
  subtitle: 'Rollbacks (count)',
  yLabel: 'Count',
  metricName: 'neo4j_database_transaction_rollbacks_total',
  metricType: METRIC_TYPE.Gauge,
  chartType: 'DB',
  aggregations: ['SUM', 'MAX', 'MIN'],
  transformerMetricConfig: isLeaderTransformerMetricConfig,
  ...BigCountersConfig,
};

export const TransactionsRollbackRateChartProps: ChartWidgetPartialProps = {
  title: 'Transactions',
  subtitle: 'Rollbacks (rate)',
  yLabel: 'Per minute',
  metricName: 'neo4j_database_transaction_rollbacks_per_minute',
  cmiMetricName: 'NO_CMI_METRIC',
  metricType: METRIC_TYPE.Gauge,
  chartType: 'DB',
  aggregations: ['SUM', 'MAX', 'MIN'],
  transformerMetricConfig: isLeaderTransformerMetricConfig,
  ...RateChartConfig,
};

export const TransactionLastCommittedTxIdChartProps: ChartWidgetPartialProps = {
  title: 'Transactions',
  subtitle: 'Last Committed Transaction ID',
  yLabel: 'Last ID',
  metricName: 'neo4j_database_transaction_last_committed_tx_id_total',
  metricType: METRIC_TYPE.Counter,
  chartType: 'DB',
  aggregations: ['MAX', 'MIN'],
  defaultAggregation: 'MAX',
  enabledFor: [TIER.ENTERPRISE, TIER.MTE],
  transformerMetricConfig: isLeaderTransformerMetricConfig,
  uplotOptions: combineOptions(plotOptionsYAxisLocalRangeIncrements()),
};

export const CypherReplanChartProps: ChartWidgetPartialProps = {
  title: 'Replan Events',
  subtitle: 'Total count',
  yLabel: 'Count',
  metricName: 'neo4j_database_cypher_replan_events_total',
  metricType: METRIC_TYPE.Gauge,
  chartType: 'DB',
  aggregations: ['SUM', 'MAX', 'MIN'],
  transformerMetricConfig: isLeaderTransformerMetricConfig,
  ...BigCountersConfig,
};

export const CypherReplanRateChartProps: ChartWidgetPartialProps = {
  title: 'Replan Events',
  subtitle: 'Rate',
  yLabel: 'Per minute',
  metricName: 'neo4j_database_cypher_replan_events_per_minute',
  cmiMetricName: 'NO_CMI_METRIC',
  metricType: METRIC_TYPE.Gauge,
  chartType: 'DB',
  aggregations: ['SUM', 'MAX', 'MIN'],
  transformerMetricConfig: isLeaderTransformerMetricConfig,
  ...RateChartConfig,
};

export const StoreSizeDatabaseChartPropsOld: ChartWidgetPartialProps = {
  title: 'Store Size',
  subtitle: 'Allocated',
  seriesName: 'Allocated space',
  aggregations: ['MAX'],
  yLabel: '%',
  metricType: METRIC_TYPE.Gauge,
  metricName: 'neo4j_database_store_size_database',
  controlledBy: [{ name: 'useVirtualDiskMetricForStorageLimit', state: 'OFF' }],
  additionalMetric: {
    title: 'Store Size',
    metricName: 'neo4j_database_store_size_used',
    subtitle: 'Used',
    seriesName: 'Used space',
    yLabel: 'Space',
    showBands: true,
    metricType: METRIC_TYPE.Gauge,
    chartType: 'DB',
    referenceMetricConfig: {
      toggledByDefault: true,
      hidden: true,
      name: 'neo4j_aura_storage_limit_calculated',
      type: METRIC_TYPE.Gauge,
      aggregation: 'MAX',
    },
  },
  referenceMetricConfig: {
    toggledByDefault: true,
    hidden: true,
    name: 'neo4j_aura_storage_limit_calculated',
    type: METRIC_TYPE.Gauge,
    aggregation: 'MAX',
  },
  chartType: 'DB',
  ...BytesChartConfig,
  tooltipComponent: tooltips.StoreSizeAllocated,
  uplotOptions: combineOptions(BytesChartConfig.uplotOptions, plotOptionsDuplicateBytesFormatter),
};

export const StoreSizeDatabaseChartProps: ChartWidgetPartialProps = {
  title: 'Store Size',
  subtitle: 'Allocated',
  seriesName: 'Allocated space',
  aggregations: ['MAX'],
  yLabel: '%',
  metricType: METRIC_TYPE.Gauge,
  metricName: 'neo4j_database_store_size_database',
  controlledBy: [{ name: 'useVirtualDiskMetricForStorageLimit', state: 'ON' }],
  additionalMetric: {
    title: 'Store Size',
    metricName: 'neo4j_database_store_size_used',
    subtitle: 'Used',
    seriesName: 'Used space',
    yLabel: 'Space',
    showBands: true,
    metricType: METRIC_TYPE.Gauge,
    chartType: 'DB',
    referenceMetricConfig: {
      toggledByDefault: true,
      hidden: true,
      name: 'neo4j_aura_storage_limit',
      type: METRIC_TYPE.Gauge,
      aggregation: 'MAX',
    },
  },
  referenceMetricConfig: {
    toggledByDefault: true,
    hidden: true,
    name: 'neo4j_aura_storage_limit',
    type: METRIC_TYPE.Gauge,
    aggregation: 'MAX',
  },
  chartType: 'DB',
  ...BytesChartConfig,
  tooltipComponent: tooltips.StoreSizeAllocated,
  uplotOptions: combineOptions(BytesChartConfig.uplotOptions, plotOptionsDuplicateBytesFormatter),
};

export const NodeCountProps: ChartWidgetPartialProps = {
  title: 'Entities',
  subtitle: 'Nodes (count)',
  yLabel: 'Count',
  metricName: 'neo4j_database_count_node',
  metricType: METRIC_TYPE.Gauge,
  chartType: 'DB',
  transformerMetricConfig: isLeaderTransformerMetricConfig,
  ...BigCountersConfig,
};

export const NodeCountRateProps: ChartWidgetPartialProps = {
  title: 'Entities',
  subtitle: 'Nodes (rate)',
  yLabel: 'Added per minute',
  metricName: 'neo4j_database_count_node_per_minute',
  cmiMetricName: 'NO_CMI_METRIC',
  metricType: METRIC_TYPE.Gauge,
  chartType: 'DB',
  transformerMetricConfig: isLeaderTransformerMetricConfig,
  ...RateChartConfig,
};

export const RelationshipCountProps: ChartWidgetPartialProps = {
  title: 'Entities',
  subtitle: 'Relationships (count)',
  yLabel: 'Count',
  metricName: 'neo4j_database_count_relationship',
  metricType: METRIC_TYPE.Gauge,
  chartType: 'DB',
  transformerMetricConfig: isLeaderTransformerMetricConfig,
  ...BigCountersConfig,
};

export const RelationshipCountRateProps: ChartWidgetPartialProps = {
  title: 'Entities',
  subtitle: 'Relationships (rate)',
  yLabel: 'Added per minute',
  metricName: 'neo4j_database_count_relationship_per_minute',
  cmiMetricName: 'NO_CMI_METRIC',
  metricType: METRIC_TYPE.Gauge,
  chartType: 'DB',
  transformerMetricConfig: isLeaderTransformerMetricConfig,
  ...RateChartConfig,
};

export const CheckPointEventsProps: ChartWidgetPartialProps = {
  title: 'Checkpoint Events',
  subtitle: 'Total count',
  yLabel: 'Count',
  metricName: 'neo4j_database_check_point_events_total',
  metricType: METRIC_TYPE.Counter,
  chartType: 'DB',
  aggregations: ['MIN', 'MAX'],
  transformerMetricConfig: isLeaderTransformerMetricConfig,
  ...BigCountersConfig,
};

export const CheckPointEventsRateProps: ChartWidgetPartialProps = {
  title: 'Checkpoint Events',
  subtitle: 'Rate',
  yLabel: 'Per minute',
  metricName: 'neo4j_database_check_point_events_per_minute',
  cmiMetricName: 'NO_CMI_METRIC',
  metricType: METRIC_TYPE.Counter,
  chartType: 'DB',
  aggregations: ['MIN', 'MAX'],
  transformerMetricConfig: isLeaderTransformerMetricConfig,
  ...BigCountersConfig,
};

export const CheckPointTotalTimeProps: ChartWidgetPartialProps = {
  title: 'Checkpoint Events',
  subtitle: 'Cumulative time',
  yLabel: 'Cumulative time',
  metricName: 'neo4j_database_check_point_total_time_total',
  metricType: METRIC_TYPE.Counter,
  chartType: 'DB',
  ...TimeChartConfig,
  transformerMetricConfig: isLeaderTransformerMetricConfig,
  uplotOptions: combineOptions(TimeChartConfig.uplotOptions, plotOptionsCumulativeDurationsFormatter),
};

export const CheckPointDurationProps: ChartWidgetPartialProps = {
  title: 'Checkpoint Events',
  subtitle: 'Duration',
  yLabel: 'Last checkpoint duration',
  metricName: 'neo4j_database_check_point_duration',
  metricType: METRIC_TYPE.Gauge,
  chartType: 'DB',
  ...TimeChartConfig,
  transformerMetricConfig: isLeaderTransformerMetricConfig,
  uplotOptions: combineOptions(TimeChartConfig.uplotOptions, plotOptionsCumulativeDurationsFormatter),
};

export const QueryExecutions: ChartWidgetPartialProps = {
  title: 'Query Rate',
  subtitle: 'Successful',
  seriesName: 'Queries per minute',
  yLabel: 'Count',
  additionalMetric: {
    title: 'Query Rate',
    metricName: 'neo4j_db_query_execution_failure_per_minute',
    azMetricName: 'neo4j_db_query_execution_failure_total',
    seriesName: 'Failed queries per minute',
    subtitle: 'Failed',
    yLabel: 'Count',
    showBands: false,
    metricType: METRIC_TYPE.Counter,
    chartType: 'DB',
    transformerMetricConfig: isLeaderTransformerMetricConfig,
  },
  metricName: 'neo4j_db_query_execution_success_per_minute',
  azMetricName: 'neo4j_db_query_execution_success_total',
  cmiMetricName: 'neo4j_db_query_execution_success_total',
  metricType: METRIC_TYPE.Counter,
  chartType: 'DB',
  tooltipComponent: tooltips.QueryExecutions,
  aggregations: ['SUM'],
  showLinkToLogs: true,
  transformerMetricConfig: isLeaderTransformerMetricConfig,
};

const QueryLatencyQ99: ChartWidgetPartialProps = {
  title: 'Query Latency',
  subtitle: '99th percentile',
  yLabel: 'Duration',
  metricType: METRIC_TYPE.Gauge,
  metricName: 'neo4j_db_query_execution_internal_latency_q99',
  chartType: 'HOST',
  aggregations: ['MAX'],
  chartPathType: 'LINE',
  minimumRequiredDatabaseVersion: '5.0.0',
  ...TimeChartConfig,
  showLinkToLogs: true,
  transformerMetricConfig: isLeaderTransformerMetricConfig,
};

const QueryLatencyQ75: ChartWidgetPartialProps = {
  title: 'Query Latency',
  subtitle: '75th percentile',
  yLabel: 'Duration',
  metricType: METRIC_TYPE.Gauge,
  metricName: 'neo4j_db_query_execution_internal_latency_q75',
  chartType: 'HOST',
  aggregations: ['MAX'],
  chartPathType: 'LINE',
  minimumRequiredDatabaseVersion: '5.0.0',
  ...TimeChartConfig,
  showLinkToLogs: true,
  transformerMetricConfig: isLeaderTransformerMetricConfig,
};

const QueryLatencyQ50: ChartWidgetPartialProps = {
  title: 'Query Latency',
  subtitle: '50th percentile',
  yLabel: 'Duration',
  metricType: METRIC_TYPE.Gauge,
  metricName: 'neo4j_db_query_execution_internal_latency_q50',
  chartType: 'HOST',
  aggregations: ['MAX'],
  chartPathType: 'LINE',
  minimumRequiredDatabaseVersion: '5.0.0',
  ...TimeChartConfig,
  showLinkToLogs: true,
  transformerMetricConfig: isLeaderTransformerMetricConfig,
};

export const AuraDatabaseCharts = [
  StoreSizeDatabaseChartPropsOld,
  StoreSizeDatabaseChartProps,
  QueryExecutions,
  [QueryLatencyQ99, QueryLatencyQ75, QueryLatencyQ50],
  [NodeCountProps, NodeCountRateProps, RelationshipCountProps, RelationshipCountRateProps],
  [TransactionsActiveReadChartProps, TransactionsActiveWriteChartProps],
  [
    TransactionsCommittedChartProps,
    TransactionsCommittedRateChartProps,
    TransactionsPeakConcurrentChartProps,
    TransactionsRollbackChartProps,
    TransactionsRollbackRateChartProps,
    TransactionLastCommittedTxIdChartProps,
  ],
  [CypherReplanChartProps, CypherReplanRateChartProps],
  [CheckPointEventsProps, CheckPointEventsRateProps, CheckPointTotalTimeProps, CheckPointDurationProps],
];
