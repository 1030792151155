import { generatedGraphStylingApi } from './generated-graph-styling.api';

function mergeObjects<T extends object>(source: T, update: Partial<T>): T {
  const definedProperties = Object.entries(update).reduce<Partial<T>>((acc, [key, value]) => {
    if (value !== undefined) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      acc[key as keyof T] = value as T[keyof T];
    }
    return acc;
  }, {});

  return { ...source, ...definedProperties };
}

export const graphStylingApi = generatedGraphStylingApi.enhanceEndpoints({
  addTagTypes: ['GraphStyling'],
  endpoints: {
    getGraphStyle: {
      providesTags: (_, __, arg) => [{ type: 'GraphStyling', id: `database-${arg.database}` }],
    },
    setGraphStyle: {
      onQueryStarted: async ({ updateGraphStyleBody }, { dispatch, queryFulfilled }) => {
        const { database } = updateGraphStyleBody;
        const result = dispatch(
          graphStylingApi.util.updateQueryData('getGraphStyle', { database }, (draft) => {
            if (updateGraphStyleBody.stylingPrecedence !== undefined) {
              draft.stylingPrecedence = updateGraphStyleBody.stylingPrecedence;
            }

            if (updateGraphStyleBody.node !== undefined) {
              Object.entries(updateGraphStyleBody.node).forEach(([key, value]) => {
                const sourceObject = draft.node[key];
                if (sourceObject !== undefined) {
                  const updatedObject = mergeObjects(sourceObject, value);
                  Object.assign(sourceObject, updatedObject);
                }
              });
            }

            if (updateGraphStyleBody.relationship !== undefined) {
              Object.entries(updateGraphStyleBody.relationship).forEach(([key, value]) => {
                const sourceObject = draft.node[key];
                if (sourceObject !== undefined) {
                  const updatedObject = mergeObjects(sourceObject, value);
                  Object.assign(sourceObject, updatedObject);
                }
              });
            }
          }),
        );

        try {
          await queryFulfilled;
          dispatch(graphStylingApi.util.invalidateTags([{ type: 'GraphStyling', id: `database-${database}` }]));
        } catch {
          result.undo();
        }
      },
    },
  },
});

export const { useGetGraphStyleQuery, useSetGraphStyleMutation } = graphStylingApi;
