import { useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import { FileContext, type FileContextType } from '../context/file-context';
import { IsUpxContext } from '../context/is-upx-context';
import type { DataFileMetadata } from '../file-loader/types';
import { store } from '../state/store';

export function Provider(props: { children: React.ReactNode }) {
  const [standaloneFiles, setStandaloneFiles] = useState<Record<string, File>>({});
  const [zipFile, setZipFile] = useState<File | null>(null);
  const [zipDataFiles, setZipDataFiles] = useState<Record<string, DataFileMetadata>>({});

  const fileContextValue: FileContextType = {
    standaloneFiles,
    setStandaloneFiles,
    zipFile,
    setZipFile,
    zipDataFiles,
    setZipDataFiles,
  };

  return (
    <IsUpxContext.Provider value={{ isUpx: true }}>
      <ReduxProvider store={store}>
        <FileContext.Provider value={fileContextValue}>{props.children}</FileContext.Provider>
      </ReduxProvider>
    </IsUpxContext.Provider>
  );
}
