import { uniqBy } from 'lodash-es';
import type { Record } from 'neo4j-driver';

import { config } from '../../../config';
import { PRIVILEGE_SHOW_CONSTRAINT, PRIVILEGE_SHOW_INDEX, appStateError } from '../../constants';
import { MSG_USER_NOT_AUTHORIZED, getErrorMessage } from '../../modules/errorHandler';
import { builtInRoles } from '../../state/connections/helpers';
import { getNoProcedurePermissionMessage } from '../../state/connections/procedures';
import type { Role, UserPrivilege } from '../../state/connections/types';
import { isFalsy } from '../../types/utility';
import bolt from '../bolt/bolt';
import { isConfigValTruthy } from '../bolt/bolt.utils';
import { DATABASE_TYPE_STANDARD, SYSTEM_DATABASE } from '../bolt/constants';
import { isForbiddenError, isNoProcedureError } from '../errors/errorUtils';
import type { WithCode } from '../errors/errorUtils';
import { log } from '../logging';
import { getIndexes } from '../metagraph';
import { checkPerspectiveWritePermission } from '../perspectives';
import {
  authResultMapper,
  checkPerspectiveUniquenessConstraint,
  licenseResultMapper,
  procedureMapper,
  rolesDetailsMapper,
  userDetailsMapper,
  userPrivilegesMapper,
  versionResultMapper,
} from '../queries/resultMapper';
import { checkSceneWritePermission } from '../scene';
import { getClientMigrationVersion } from '../versions/version';
import { compareVersions } from '../versions/versionUtils';
import {
  createPerspectiveUniqueConstraint,
  getAllDatabases,
  getAllProcedures,
  getAuthSetting,
  getAuthorization,
  getAvailableRoles,
  getConstraints,
  getCurrentDatabaseInfo,
  getCurrentUserDetails,
  getCurrentUserPrivileges,
  getDatabaseComponents,
  getLicense,
  getServerPluginVersion,
  getUpgradeStatus as getUpgradeStatusQuery,
} from './queryGenerator';
import type { DB, Result, Status } from './types';

export const getAvailableProcedures = async (mapper = procedureMapper) => {
  try {
    const result = await bolt.readSystemTransaction(getAllProcedures());
    return mapper(result.records);
  } catch (error) {
    log.error(error);
    return { error };
  }
};

export const checkShowIndexesAndConstrains = async (serverVersion: string, userPrivileges: UserPrivilege[]) => {
  const newPrivileges = [...userPrivileges];
  const privilegeTemplate = {
    access: 'GRANTED',
    resource: 'database',
    segment: 'database',
    graph: '*',
  };

  try {
    await getIndexes({ database: '*', parentRequestId: undefined });
    newPrivileges.push({
      ...privilegeTemplate,
      action: PRIVILEGE_SHOW_INDEX,
    });
  } catch (e) {
    log.debug(e);
  }

  try {
    await bolt.readTransaction(getConstraints());
    newPrivileges.push({
      ...privilegeTemplate,
      action: PRIVILEGE_SHOW_CONSTRAINT,
    });
  } catch (e) {
    log.debug(e);
  }

  return newPrivileges;
};

const getNoDatabasesError = () => ({ error: getErrorMessage({ code: appStateError.NO_ACCESS_TO_DB }) });

export const PRE_4_DB_NAME = 'neo4j';

export const setDatabaseId = (db: DB, databaseId: string) => {
  return {
    ...db,
    // since id does not guarantee uniqueness within a single DBMS
    // for nonSystemDb use database name as unique Id, which guarantees uniqueness
    // for systemDb, use databaseId since one DBMS only has one systemDB and its name is always system
    id: db.name === SYSTEM_DATABASE ? databaseId : db.name,
    // in Bloom <= 2.7, id is used to generate document key
    legacyId: databaseId,
  };
};

// this is to support neo4j <= 4.3
const getAccessibleDatabaseInfoWithIds = async (databases: DB[]) => {
  const results = await Promise.all(
    databases.map(async (db) => {
      try {
        // in neo4j <= 4.3, use call db.info() to get id
        const infoResult = await bolt.readTransaction(getCurrentDatabaseInfo, { database: db.name });

        const infoRecord = infoResult?.records?.[0];
        const infoId = infoRecord?.get('id');
        if (infoId != null) {
          return setDatabaseId(db, infoId);
        }
        return db; // no id found, but database is accessible
      } catch (error) {
        if (isForbiddenError(error as WithCode)) {
          return null;
        } else if (isNoProcedureError(error as WithCode)) {
          return db; // early versions of 4.0.x do not have db.info()
        }
        throw error;
      }
    }),
  );
  return results.filter((db) => db != null);
};

const databaseInfoResultMapper = (record: Record) => ({
  // in neo4j <= 4.3, databaseId isn't available with SHOW DATABASES YIELD *, use name instead
  id: record.get('name'),
  name: record.get('name'),
  default: isConfigValTruthy(record.get('default')),
  home: record.has('home') ? isConfigValTruthy(record.get('home')) : false,
  online: record.get('currentStatus').toLowerCase() === 'online',
  type: record.has('type') ? record.get('type') : DATABASE_TYPE_STANDARD,
});

const appendUserPermissionPerDatabase = async (databases: DB[]) =>
  Promise.all(
    databases.map(async (db) => {
      const promises = [checkPerspectiveWritePermission(db.name), checkSceneWritePermission(db.name)];
      const [userCanSavePerspective, userCanSaveScene] = await Promise.all(promises);

      return {
        ...db,
        userCanSavePerspective,
        userCanSaveScene,
      };
    }),
  );

export const getDatabaseInfo = async (serverVersion: string, includePermissions = true) => {
  try {
    const allDatabasesResult = await bolt.readSystemTransaction(getAllDatabases);

    if (!isFalsy(allDatabasesResult?.records?.length)) {
      const allOnlineDatabases = allDatabasesResult.records
        .map(databaseInfoResultMapper)
        .filter(({ online }) => online);

      const uniqueDatabases = uniqBy(allOnlineDatabases, 'name');

      if (uniqueDatabases != null) {
        try {
          const accessibleDatabases = await getAccessibleDatabaseInfoWithIds(uniqueDatabases);
          const systemDatabase = accessibleDatabases.find((db) => db.name === SYSTEM_DATABASE);

          const accessibleDatabasesWithoutSystemDatabase = accessibleDatabases.filter(
            ({ name }) => name !== SYSTEM_DATABASE,
          );

          let databasesWithPermissions;

          if (includePermissions) {
            databasesWithPermissions = await appendUserPermissionPerDatabase(accessibleDatabasesWithoutSystemDatabase);
          }

          const databases = databasesWithPermissions ?? accessibleDatabasesWithoutSystemDatabase;

          if (databases.length > 0) {
            return {
              dbmsId: systemDatabase?.id,
              databases,
            };
          }
        } catch (error) {
          log.error(error);
          return { error };
        }
      }
    }
  } catch (error) {
    log.error(error);
    return { error };
  }
  return getNoDatabasesError();
};

export const getServerInformation = async () => {
  try {
    const result = await bolt.readSystemTransaction(getDatabaseComponents);
    const [record] = result.records;
    if (record) {
      return { version: record.get('versions')[0], edition: record.get('edition') };
    }
    return new Error('could not load database components for server information');
  } catch (error) {
    if (isForbiddenError(error as WithCode)) {
      return new Error(getNoProcedurePermissionMessage('dbms.components'));
    }
    return error;
  }
};

export const checkUserAuthorization = async () => {
  try {
    const result = await bolt.readSystemTransaction(getAuthorization);

    const mapped: {
      success: boolean;
      message: string;
    } = authResultMapper(result);
    return { authorized: mapped.success, message: mapped.success ? null : MSG_USER_NOT_AUTHORIZED };
  } catch (error) {
    if (isNoProcedureError(error as WithCode)) {
      // Let Bloom run if the auth procedure is missing
      return { authorized: true, message: null };
    }
    const { message } = getErrorMessage(error) ?? {};
    return { authorized: false, message };
  }
};

export const checkLicense = async (responseHandler: (result: unknown) => void) => {
  try {
    const result = await bolt.readSystemTransaction(getLicense);
    responseHandler?.(licenseResultMapper(result));
  } catch (error) {
    if (isNoProcedureError(error as WithCode)) {
      // Let Bloom run if the license procedure is missing
      responseHandler?.({ status: 'noplugin' });
    } else {
      responseHandler?.({
        status: 'unknown',
        message: (
          error as {
            message: string;
          }
        ).message,
      });
    }
  }
};

export const checkServerPluginVersion = async (responseHandler: (result: unknown) => void) => {
  if (responseHandler == null) {
    return;
  }

  try {
    const result = await bolt.readSystemTransaction(getServerPluginVersion);
    const clientVersion = getClientMigrationVersion() as string;
    const pluginVersion = versionResultMapper(result) as string;
    if (String(config.NO_CHECKS) === 'true') {
      responseHandler({ success: true, pluginVersion });
      return;
    }

    // from Bloom 2.6.0, Bloom accepts all plugin versions from 2.5.1
    if (
      clientVersion != null &&
      (compareVersions(clientVersion, '2.6.0') ?? -1) >= 0 &&
      pluginVersion != null &&
      (compareVersions(pluginVersion, '2.5.1') ?? -1) >= 0
    ) {
      responseHandler({ success: true, pluginVersion });
      return;
    }

    if (pluginVersion != null && clientVersion != null && compareVersions(clientVersion, pluginVersion) === 0) {
      responseHandler({ success: true, pluginVersion });
    } else {
      const adviceMessage =
        (compareVersions(clientVersion, pluginVersion) ?? -1) < 0
          ? 'Please upgrade Neo4j Bloom app to match the server.'
          : 'Please contact your system administrator.';

      const compatibilityMessage =
        (compareVersions(clientVersion, '2.6.0') ?? -1) >= 0
          ? 'Neo4j Bloom is only compatible with Bloom Server version 2.5.1 and later.'
          : 'Neo4j Bloom is only compatible with the same version Bloom Server.';

      responseHandler({
        clientVersion,
        pluginVersion,
        success: false,
        message: `${compatibilityMessage} (App version: ${clientVersion}, Server version: ${pluginVersion}). ${adviceMessage}`,
      });
    }
  } catch (error) {
    log.warn(error);
    responseHandler?.({
      success: false,
      message: 'Could not detect Neo4j Bloom Server version. Please contact your system administrator.',
    });
  }
};

const authEnabled = async (responseHandler: (result: unknown) => void) => {
  try {
    const result = await bolt.readSystemTransaction(getAuthSetting());
    if (result?.records?.length !== 0) {
      responseHandler({ serverHasAuth: isConfigValTruthy(result.records?.[0]?.get('value')) });
    } else {
      responseHandler?.({ error: new Error('auth setting could not be read from the server') });
    }
  } catch (error) {
    responseHandler?.({ error });
  }
};

export const getUserDetails = async (responseHandler: (result: unknown) => void) => {
  try {
    const result = await bolt.readSystemTransaction(getCurrentUserDetails());
    const userDetails = userDetailsMapper(result);
    responseHandler({ userId: userDetails?.username });
  } catch (error) {
    log.error(error);
    responseHandler({ error });
  }
};

export const canUserWriteToDatabase = async (responseHandler: (result: unknown) => void) => {
  const handleRoles = async (
    userRoles: Role[],
    userName: string,
    availableRolesResult?: {
      records: Record[];
    },
  ) => {
    const allRoles = availableRolesResult != null ? rolesDetailsMapper(availableRolesResult) : [];
    try {
      const privilegesResult = await bolt.readSystemTransaction(getCurrentUserPrivileges(userName));
      const userPrivileges = userPrivilegesMapper(privilegesResult);
      responseHandler({ allRoles, userRoles, userPrivileges, authEnabled: true });
    } catch (error) {
      log.error(error);
      responseHandler?.({ error });
    }
  };
  const fetchUserWritePermissions = async (serverHasAuth: boolean) => {
    try {
      const userDetailsResult = await bolt.readSystemTransaction(getCurrentUserDetails());
      if (responseHandler != null) {
        const userDetails = userDetailsMapper(userDetailsResult);
        const userRoles = userDetails?.roles ?? [];
        const userName = userDetails?.username ?? '';
        try {
          const availableRolesResult = await bolt.readSystemTransaction(getAvailableRoles());
          await handleRoles(userRoles, userName, availableRolesResult);
        } catch (error) {
          if (isNoProcedureError(error as WithCode) || isForbiddenError(error as WithCode)) {
            // This happens if user doesn't have permission to list all roles and on Neo4j Aura that has a special auth without listRoles
            await handleRoles(userRoles, userName, undefined);
          } else {
            log.error(error);
            responseHandler?.({ error });
          }
        }
      }
    } catch (error) {
      log.error(error);
      if (responseHandler != null) {
        if (!serverHasAuth && isNoProcedureError(error as WithCode)) {
          responseHandler({ userPrivileges: builtInRoles.admin, authEnabled: false });
        } else if (isForbiddenError(error as WithCode)) {
          responseHandler({ error: { message: getNoProcedurePermissionMessage('dbms.showCurrentUser') } });
        } else {
          responseHandler({ error });
        }
      }
    }
  };

  await authEnabled((result) => {
    if (
      (result as Result).serverHasAuth ||
      ((result as Result).error != null && isForbiddenError((result as Result).error))
    ) {
      void fetchUserWritePermissions(!!(result as Result).serverHasAuth);
    } else {
      responseHandler?.({ userPrivileges: builtInRoles.admin, authEnabled: false });
    }
  });
};

export const ensurePerspectiveUniquenessConstraintExists = async (serverVersion: string) => {
  let result;
  try {
    result = await bolt.readTransaction(getConstraints());
  } catch (error) {
    log.warn('Get constraints failed', error);
  }
  if (result != null && (checkPerspectiveUniquenessConstraint(result.records, serverVersion) ?? false)) {
    log.info('Perspective unique constraint already exists');
  } else {
    try {
      await bolt.writeTransaction(createPerspectiveUniqueConstraint(serverVersion));
      log.info('Perspective unique constraint created');
    } catch (error) {
      log.warn('Error while ensuring perspective unique constraint', error);
    }
  }
};

export const getUpgradeStatus = async () => {
  const status: Status = {};

  try {
    const result = await bolt.readSystemTransaction(getUpgradeStatusQuery);
    const record = result?.records[0];
    if (record != null) {
      status.needsUpgrade = record.get('status') !== 'CURRENT';
      status.message =
        'Neo4j database needs to be upgraded, contact your system administrator or check dbms.upgradeStatus';
    } else {
      status.hasError = true;
      status.message = 'dbms.upgradeStatus returned no records, contact your system administrator';
    }
  } catch (error) {
    if (isNoProcedureError(error as WithCode)) {
      // dbms.upgradeStatus not avaliable pre 4.1, just ignore
      status.needsUpgrade = false;
    } else if (isForbiddenError(error as WithCode)) {
      // dbms.upgradeStatus not avaliable for non admin by default, just ignore
      status.needsUpgrade = false;
    } else {
      status.hasError = true;
      status.message = `Failed to check Neo4j upgrade status, error: ${(error as WithCode)?.code}`;
    }
  }

  return status;
};
