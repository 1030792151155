export enum LOCAL_INSTANCE_STATUS {
  STARTED = 'started',
  STOPPED = 'stopped',
}

export enum LOCAL_INSTANCE_SERVER_STATUS {
  ONLINE = 'online',
  OFFLINE = 'offline',
  UNKNOWN = 'unknown',
}

export enum NEO4J_EDITION {
  COMMUNITY = 'community',
  ENTERPRISE = 'enterprise',
}

export type LocalInstance = {
  id: string;
  name: string;
  description: string;
  tags: string[];
  metadata: Record<string, unknown>;
  connectionUri: string;
};

export type LocalInstanceInfo = LocalInstance & {
  status: LOCAL_INSTANCE_STATUS;
  serverStatus: LOCAL_INSTANCE_SERVER_STATUS;
  edition?: NEO4J_EDITION;
  version?: string;
  prerelease?: string;
  rootPath?: string;
};

export type LocalInstanceVersion = {
  edition: NEO4J_EDITION;
  version: 'string';
  origin: 'cached' | 'online';
  dist: 'string';
};

export type LocalInstancePluginSource = {
  /** Plugin name */
  name: string;
  /** Plugin homepage, users are directed here for information/support */
  homepageUrl: string;
  /** URL from which to fetch available plugin versions */
  versionsUrl: string;
  /** Whether the plugin source is officially supported */
  isOfficial?: boolean;
};

export type LocalInstancePluginVersion = {
  /** Plugin semver */
  version: string;
  /** Supported DBMS semver (specific or range) */
  neo4jVersion: string;
  /** Plugin homepage URL */
  homepageUrl?: string;
  /** Plugin download URL */
  downloadUrl: string;
  /** sha256b checksum */
  sha256?: string;
  /** neo4j.conf changes needed */
  config: Record<string, string | string[] | boolean | undefined>;
};

export type LocalInstanceInstalledPlugin = LocalInstancePluginSource & {
  version: LocalInstancePluginVersion;
};

export type LocalInstanceInstallArgs = {
  name: string;
  credentials: string;
  version: string;
  edition?: NEO4J_EDITION;
  environmentNameOrId?: string;
  noCaching?: boolean;
  limited?: boolean;
  installPath?: string;
};

export type LocalInstancePluginInstallArgs = {
  dbmsIds: string[];
  pluginName: string;
};

export type OperationName =
  | 'dbmsInstall'
  | 'dbmsUpgrade'
  | 'dbmsUninstall'
  | 'dbmsPluginInstall'
  | 'javaDownload'
  | 'test';

export enum PROGRESS_STATUS {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type ProgressEvent = {
  operation: OperationName;

  /**
   * ID used to identify specific instances of an operation. For example if the
   * operation is dbmsInstall, the ID would refer to a specific dbmsInstall
   * operation.
   */
  operationId: string;

  /**
   * User facing message describing the sub-task tracked by the event.
   *
   * @example "Downloading Neo4j archive"
   */
  message: string;
  status: PROGRESS_STATUS;
  percent?: number;
  error?: unknown;
};

export type Db4 = {
  name: string;
  address: string;
  role: string;
  requestedStatus: string;
  currentStatus: string;
  error: string;
  default: boolean;
  home: boolean;
};

export type Db5 = {
  name: string;
  type: string;
  aliases: string;
  access: string;
  address: string;
  role: string;
  writer: string;
  requestedStatus: string;
  currentStatus: string;
  statusMessage: string;
  default: boolean;
  home: boolean;
  constituents: string;
};

export type DbList = {
  cached: boolean;
  dbs: (Db4 | Db5)[];
};
