import { IconButton, Tooltip } from '@neo4j-ndl/react';
import { PauseCircleIconOutline, PlayCircleIconOutline } from '@neo4j-ndl/react/icons';
import { AURA_CONSOLE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import type { Instance, Project } from '@nx/state';
import {
  ACTION,
  INSTANCE_STATUS,
  MODAL_TYPE,
  TIER,
  consoleApi,
  getApiError,
  useConnection,
  useModal,
  usePermissions,
} from '@nx/state';
import { isNotNullish } from '@nx/stdlib';
import { PermissionTooltip } from '@nx/ui';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { useTrackUpxEvent } from '../services/segment/analytics';
import { ConnectMenu } from './connect-menu';
import { getNotPauseableMessage, isPausable, isProTrialInstance, isResumeVisible } from './entities/helpers';
import { InstanceActionMenu } from './instance-action-menu';

const logger = createLogger(APP_SCOPE.framework);

type InstanceTableRowActionsProps = {
  instance: Instance;
  project: Project;
  onOpenInstanceDetails: () => void;
};

export const InstanceTableRowActions = ({ project, instance, onOpenInstanceDetails }: InstanceTableRowActionsProps) => {
  const trackEvent = useTrackUpxEvent();
  const { isAllowed } = usePermissions();
  const [resume, resumeRes] = consoleApi.useResumeInstanceMutation();
  const [pause, pauseRes] = consoleApi.usePauseInstanceMutation();
  const connection = useConnection();

  const allowPause = isAllowed(ACTION.PAUSE, `namespaces/${project.id}/databases/${instance.id}`);
  const allowResume = isAllowed(ACTION.RESUME, `namespaces/${project.id}/databases/${instance.id}`);

  const isProTrial = isProTrialInstance(instance);

  const handleResume = () => {
    resume(instance.id)
      .unwrap()
      .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(e);
        if (isNotNullish(error.message)) {
          logger.error(error.message);
        }
      });

    trackEvent({
      event: AURA_CONSOLE_EVENTS.INSTANCE_RESUME,
      properties: { tier: instance.tier },
      scope: APP_SCOPE.aura,
    });
  };

  const handlePause = () => {
    pause(instance.id)
      .unwrap()
      .catch((e: FetchBaseQueryError | SerializedError | undefined) => {
        const error = getApiError(e);
        if (isNotNullish(error.message)) {
          logger.error(error.message);
        }
      });

    trackEvent({
      event: AURA_CONSOLE_EVENTS.INSTANCE_PAUSE,
      properties: { tier: instance.tier },
      scope: APP_SCOPE.aura,
    });
  };

  const resumeModal = useModal(MODAL_TYPE.RESUME);
  const pauseModal = useModal(MODAL_TYPE.PAUSE);

  const connectButtonEnabled =
    !connection.status.isDisconnecting &&
    !connection.status.isConnecting &&
    instance.instanceStatus === INSTANCE_STATUS.RUNNING;

  return (
    <>
      <div className="flex flex-grow flex-row items-center justify-end gap-2">
        <ConnectMenu instance={instance} type="table" isDisabled={!connectButtonEnabled} />

        {isResumeVisible(instance) ? (
          <PermissionTooltip hasPermission={allowResume} hasButtonWrapper>
            <Tooltip type="simple" isDisabled={instance.availableActions.resume.enabled || !allowResume}>
              <Tooltip.Trigger hasButtonWrapper>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    if (instance.tier === TIER.FREE || isProTrial) {
                      handleResume();
                    } else {
                      resumeModal.open({ instance });
                    }
                  }}
                  isDisabled={!instance.availableActions.resume.enabled || !allowResume}
                  ariaLabel="Resume instance"
                  size="small"
                  isLoading={resumeRes.isLoading}
                  isClean
                >
                  <PlayCircleIconOutline />
                </IconButton>
              </Tooltip.Trigger>
              <Tooltip.Content>
                {!instance.availableActions.resume.enabled ? instance.availableActions.resume.message : ''}
              </Tooltip.Content>
            </Tooltip>
          </PermissionTooltip>
        ) : (
          <PermissionTooltip hasPermission={allowPause} hasButtonWrapper>
            <Tooltip type="simple" isDisabled={isPausable(instance) || !allowPause}>
              <Tooltip.Trigger hasButtonWrapper>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    if (isProTrial) {
                      handlePause();
                    } else {
                      pauseModal.open({ instance });
                    }
                  }}
                  isDisabled={!isPausable(instance) || !allowPause}
                  isLoading={pauseRes.isLoading}
                  ariaLabel="Pause instance"
                  size="small"
                  isClean
                >
                  <PauseCircleIconOutline />
                </IconButton>
              </Tooltip.Trigger>
              <Tooltip.Content>{getNotPauseableMessage(instance)}</Tooltip.Content>
            </Tooltip>
          </PermissionTooltip>
        )}
        <InstanceActionMenu instance={instance} onOpenDetails={onOpenInstanceDetails} type="table" />
      </div>
    </>
  );
};
