import { APP_SCOPE } from '@nx/constants';
import { createLogger } from '@nx/logger';
import type { ApiError, Organization, ProjectSummary, User } from '@nx/state';
import {
  consoleApi,
  getAccessToken,
  getApiError,
  getErrorMessage,
  isErrorWithMessage,
  isFetchBaseQueryError,
  login,
  LEGACY_setActiveOrg as setActiveOrg,
  LEGACY_setActiveProject as setActiveProject,
  LEGACY_setUserHomePath as setUserHomePath,
  useAuth,
  useInternalConfiguration,
  useOnboardingConfiguration,
  useToolsConfiguration,
  useUnsafeAppContext,
} from '@nx/state';
import { isNonEmptyString, isNotNullish, isNullish } from '@nx/stdlib';
import type { JwtPayload } from 'jwt-decode';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { useIsOrgContext } from './hooks';

const logger = createLogger(APP_SCOPE.aura);

export const maybeAuthenticateWithSsoOrg = async (ssoOrg: string | undefined) => {
  try {
    const accessToken = await getAccessToken();
    if (isNotNullish(ssoOrg)) {
      if (isNotNullish(accessToken)) {
        const token = jwtDecode<JwtPayload & { org_id?: string }>(accessToken);
        const tokenOrgId = token.org_id;
        if (isNullish(tokenOrgId) || tokenOrgId !== ssoOrg) {
          await login(ssoOrg, {
            extraQueryParams: { organization: ssoOrg, screen_hint: 'signin' },
            state: { returnTo: location.pathname + location.search },
          });
        }
      } else {
        await login(ssoOrg, {
          extraQueryParams: { organization: ssoOrg, screen_hint: 'signin' },
          state: { returnTo: location.pathname + location.search },
        });
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      // Intercept the error and log it for app monitoring
      logger.info('Attempt to authenticate with organization SSO resulted in error', error.message);
    }
  }
};

const parseRouteParams = (route: string) => {
  const orgId = matchPath('/org/:orgId/*', route)?.params.orgId;
  const projectId = matchPath('/projects/:projectId/*', route)?.params.projectId;
  return { orgId, projectId };
};

enum ACTION {
  Redirect,
  SetOrg,
  SetProject,
  Onboarding,
}

type ResolvedAppContext =
  | { action: ACTION.Redirect; route: string }
  | { action: ACTION.SetOrg; orgId: string }
  | { action: ACTION.SetProject; orgId: string; projectId: string }
  | { action: ACTION.Onboarding; orgId: string; projectId?: string };

const resolveAppContext = (
  orgs: Organization[],
  projects: ProjectSummary[],
  user: User,
  route: string,
  isAppRoute: boolean,
): ResolvedAppContext => {
  const { orgId, projectId } = parseRouteParams(route);
  // If orgId param is valid set active org
  const org = orgs.find((o) => o.id === orgId);
  if (isNonEmptyString(orgId) && isNotNullish(org) && user.planType === org.planType) {
    if (!org.onboardingCompleted) {
      return { action: ACTION.Onboarding, orgId };
    }
    return { action: ACTION.SetOrg, orgId };
  }

  const project = projects.find((p) => p.id === projectId) ?? null;
  const storedProjectId = localStorage.getItem(`default-project-${user.id}`);
  const defaultProject =
    projects.find((p) => p.id === storedProjectId) ??
    projects.find((p) => p.id === user.defaultProject) ??
    projects.find(Boolean) ??
    null;

  if (isNonEmptyString(projectId) && isNotNullish(project) && user.planType === project.planType) {
    void maybeAuthenticateWithSsoOrg(project.ssoOrganizationId);
    const projectOrg = orgs.find((o) => o.id === project.organizationId);
    if (isNotNullish(projectOrg) && !projectOrg.onboardingCompleted) {
      return { action: ACTION.Onboarding, orgId: projectOrg.id, projectId: project.id };
    }
    return {
      action: ACTION.SetProject,
      orgId: project.organizationId,
      projectId: project.id,
    };
  }

  if (!defaultProject) {
    // If no project, find first org and redirect to the org context
    const firstOrg = orgs.find(Boolean);
    if (firstOrg) {
      void maybeAuthenticateWithSsoOrg(firstOrg.ssoOrgId);
      if (!firstOrg.onboardingCompleted) {
        return { action: ACTION.Onboarding, orgId: firstOrg.id };
      }
      return { action: ACTION.Redirect, route: `/org/${firstOrg.id}/projects` };
    }
    throw new Error('No org or project found');
  }
  void maybeAuthenticateWithSsoOrg(defaultProject.ssoOrganizationId);
  const projectOrg = orgs.find((o) => o.id === defaultProject.organizationId);

  if (isNotNullish(projectOrg) && !projectOrg.onboardingCompleted) {
    return { action: ACTION.Onboarding, orgId: projectOrg.id, projectId: defaultProject.id };
  }

  // If the current route is a tool route, set active project
  if (isAppRoute) {
    return {
      action: ACTION.SetProject,
      orgId: defaultProject.organizationId,
      projectId: defaultProject.id,
    };
  }

  // Redirect to the project context
  return { action: ACTION.Redirect, route: `/projects/${defaultProject.id}/instances` };
};

export const useAppContextInit = () => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const isOrgContext = useIsOrgContext();
  const internalConfiguration = useInternalConfiguration();
  const toolsConfiguration = useToolsConfiguration();
  const onboardingConfiguration = useOnboardingConfiguration();

  const [getUserDetails] = consoleApi.useLazyGetUserDetailsQuery();
  const [listOrgs] = consoleApi.useLazyListOrganizationsByUserQuery();
  const [listProjects] = consoleApi.useLazyListProjectsByUserQuery();

  const { activeOrgId, activeProjectId } = useUnsafeAppContext();
  const [error, setError] = useState<ApiError | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    void (async function init() {
      try {
        setIsLoading(true);
        setError(null);

        if (
          (isOrgContext && isNonEmptyString(activeOrgId)) ||
          (!isOrgContext && isNonEmptyString(activeProjectId)) ||
          isNullish(auth.user)
        ) {
          return;
        }

        const userDetails = await getUserDetails(undefined).unwrap();
        const [orgs, projects] = await Promise.all([
          listOrgs(userDetails.id).unwrap(),
          listProjects(userDetails.id).unwrap(),
        ]);

        const guidesPath = toolsConfiguration.guides?.route;
        const onboardingPath = onboardingConfiguration?.route;

        const appContext = resolveAppContext(
          orgs,
          projects,
          userDetails,
          location.pathname,
          location.pathname.startsWith(internalConfiguration.basePath) ||
            (guidesPath !== undefined && location.pathname.startsWith(guidesPath)) ||
            (onboardingPath !== undefined && location.pathname.startsWith(onboardingPath)),
        );

        if (appContext.action === ACTION.Redirect) {
          navigate(appContext.route, { replace: true });
        } else if (appContext.action === ACTION.SetOrg) {
          setActiveOrg(appContext.orgId);
          setUserHomePath(`org/${appContext.orgId}/projects`);
        } else if (appContext.action === ACTION.Onboarding) {
          setActiveOrg(appContext.orgId);
          if (isNotNullish(appContext.projectId)) {
            setActiveProject(appContext.projectId);
            setUserHomePath(`projects/${appContext.projectId}/instances`);
          } else {
            setUserHomePath(`org/${appContext.orgId}/projects`);
          }
          if (isNotNullish(onboardingConfiguration)) {
            navigate(onboardingConfiguration.route, { replace: true });
          }
        } else {
          setActiveOrg(appContext.orgId);
          setActiveProject(appContext.projectId);
          setUserHomePath(`projects/${appContext.projectId}/instances`);
        }
      } catch (err) {
        const isFetchError = isFetchBaseQueryError(err);
        let consoleError: ApiError | null = null;
        if (isFetchError) {
          consoleError = getApiError(err);
        }
        const msg =
          (isNotNullish(consoleError) && getErrorMessage(consoleError)) ||
          (isErrorWithMessage(err) && err.message) ||
          `Unknown error: ${JSON.stringify(err)}`;
        const apiError: ApiError = {
          type: 'UNKNOWN_ERROR',
          reason: undefined,
          code: undefined,
          extra: undefined,
          validationErrors: undefined,
          ...consoleError,
          message: msg,
        };
        setError(apiError);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [
    activeOrgId,
    activeProjectId,
    auth.user,
    getUserDetails,
    internalConfiguration.basePath,
    isOrgContext,
    listOrgs,
    listProjects,
    location.pathname,
    navigate,
    toolsConfiguration.guides?.route,
    onboardingConfiguration,
  ]);

  return {
    isLoading,
    error,
  };
};
