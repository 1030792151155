import { GUIDE_EVENTS } from '@nx/analytics-service';
import { APP_SCOPE } from '@nx/constants';
import { getFlag, waitForLDClient } from '@nx/launch-darkly-service';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import { trackEvent } from '../../middlewares/analytics-events';
import { selectAppContext } from '../../selectors';
import type { AppDispatch, RootState } from '../../store';
import * as Capabilities from '../capabilities-slice';
import * as Guides from './guides-slice';
import GuidesApi from './guides.api';

const createGuideStatus = GuidesApi.endpoints.createGuideStatus.initiate;

export function createGuidesMiddleware() {
  const middleware = createListenerMiddleware<RootState>();
  const startListening = middleware.startListening.withTypes<RootState, AppDispatch>();

  /**
   * Migrate guides statuses from local storage to storage API
   */
  startListening({
    predicate(action, currentState, previousState) {
      return (
        currentState.appContext.activeProjectId !== null &&
        currentState.appContext.activeProjectId !== previousState.appContext.activeProjectId
      );
    },
    effect: async (_, listenerApi) => {
      const state = listenerApi.getState();
      const { dispatch } = listenerApi;
      const { activeProjectId } = selectAppContext(state);

      await waitForLDClient();
      const isStorageApiEnabled = Capabilities.selectCapability(state, { key: 'framework:storage-api' });
      const isFeatureFlagEnabled = getFlag('upxGuidesProgressStorageApi', false);
      const useStorageApi = isStorageApiEnabled && isFeatureFlagEnabled;

      const guidesStatusesLocal = state.guides.guidesStatuses;

      const migrationRequired = useStorageApi && Object.keys(guidesStatusesLocal).length > 0;
      if (migrationRequired) {
        dispatch(
          trackEvent({
            event: GUIDE_EVENTS.MIGRATE_TO_STORAGE_API,
            properties: { projectId: activeProjectId },
            scope: APP_SCOPE.framework,
          }),
        );

        const migrateGuide = async (id: string, status: Guides.GuideStatus) => {
          await dispatch(
            createGuideStatus({
              name: id,
              currentPage: status.currentPage,
              isCompleted: status.isCompleted,
              version: 1,
            }),
          );

          dispatch(Guides.deleteGuideStatus(id));
        };

        const migrateGuides = async (statuses: Guides.GuidesStatuses) => {
          const apiCalls = [];
          for (const [id, status] of Object.entries(statuses)) {
            apiCalls.push(migrateGuide(id, status));
          }
          await Promise.all(apiCalls);
        };

        void migrateGuides(guidesStatusesLocal);
      }
    },
  });

  return middleware.middleware;
}
