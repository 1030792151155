import { type EventTypeLabelProps, Label, TextLink, Typography } from '@neo4j-ndl/react';
import type { OpsTypes, TimeRange } from '@nx/state';
import { CLIENT, CLIENT_TYPE, DRIVER_VERSION_STATUS } from '@nx/state';
import { isNonEmptyString } from '@nx/stdlib';
import { dataGridHelpersClasses } from '@nx/ui';
import type { ColumnDef } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';
import { useMemo } from 'react';

import { columnPreferencesColumn } from '../../logs/shared/components/column-preferences';
import { initialFilters } from '../../logs/shared/constants';
import { NumberCell, TimestampCell, ValueListCell } from '../../logs/shared/helpers/table-overrides';
import { capitalizeFirstLowerRest } from '../../logs/shared/utils';
import { NavigateToLogsButton } from '../helpers';
import { toDriverStatusPriority } from '../mappers';

const getLabelProps = (driverVersionStatus: DRIVER_VERSION_STATUS): [EventTypeLabelProps['color'], string] => {
  switch (driverVersionStatus) {
    case DRIVER_VERSION_STATUS.Compatible:
      return ['success', 'Compatible'];
    case DRIVER_VERSION_STATUS.UpgradeRecommended:
      return ['warning', 'Upgrade recommended'];
    case DRIVER_VERSION_STATUS.Incompatible:
      return ['danger', 'Incompatible'];
    case DRIVER_VERSION_STATUS.CommunityDriver:
      return ['warning', 'Community driver'];
    case DRIVER_VERSION_STATUS.DeprecatedDriver:
      return ['danger', 'Deprecated driver'];
    case DRIVER_VERSION_STATUS.UnknownDriverVersion:
      return ['default', 'Unknown driver version'];
    case DRIVER_VERSION_STATUS.UnknownDriver:
    default:
      return ['warning', 'Unknown driver'];
  }
};

const getDriverGitHubRepositoryName = (client: CLIENT) => {
  switch (client) {
    case CLIENT.Java:
      return 'neo4j/neo4j-java-driver';
    case CLIENT.Javascript:
      return 'neo4j/neo4j-javascript-driver';
    case CLIENT.Python:
      return 'neo4j/neo4j-python-driver';
    case CLIENT.Dotnet:
      return 'neo4j/neo4j-dotnet-driver';
    case CLIENT.Golang:
      return 'neo4j/neo4j-go-driver';
    case CLIENT.KafkaConnect:
      return 'neo4j/neo4j-kafka-connector';
    case CLIENT.SparkConnector:
      return 'neo4j-contrib/neo4j-spark-connector';
    // TODO show repositories of community drivers?
    case CLIENT.Unknown:
    default:
      return undefined;
  }
};

const getDownloadUrl = (client: CLIENT) => {
  switch (client) {
    case CLIENT.Bloom:
      return 'https://neo4j.com/deployment-center/?bloom#tools-tab';
    case CLIENT.Desktop:
      return 'https://neo4j.com/deployment-center/#tools-tab';
    case CLIENT.CypherShell:
      return 'https://neo4j.com/deployment-center/?cypher-shell#tools-tab';
    case CLIENT.OpsManager:
      return 'https://neo4j.com/deployment-center/?ops-manager#tools-tab';
    case CLIENT.SparkConnector:
      return '';
    default:
      return undefined;
  }
};

const getDriverDocsUrl = (client: CLIENT) => {
  switch (client) {
    case CLIENT.Java:
      return 'https://neo4j.com/docs/java-manual/current/';
    case CLIENT.Javascript:
      return 'https://neo4j.com/docs/javascript-manual/current/';
    case CLIENT.Python:
      return 'https://neo4j.com/docs/python-manual/current/';
    case CLIENT.Dotnet:
      return 'https://neo4j.com/docs/dotnet-manual/current/';
    case CLIENT.Golang:
      return 'https://neo4j.com/docs/go-manual/current/';
    case CLIENT.Desktop:
      return 'https://neo4j.com/docs/desktop-manual/current/';
    case CLIENT.Browser:
      return 'https://neo4j.com/docs/browser-manual/current/';
    case CLIENT.Query:
      return 'https://neo4j.com/docs/browser-manual/current/';
    case CLIENT.Import:
      return 'https://neo4j.com/docs/data-importer/current/';
    case CLIENT.Workspace:
      return 'https://neo4j.com/product/workspace/';
    case CLIENT.Bloom:
      return 'https://neo4j.com/docs/bloom-user-guide/current/';
    case CLIENT.KafkaConnect:
      return 'https://neo4j.com/docs/kafka/current/';
    case CLIENT.SparkConnector:
      return 'https://neo4j.com/docs/spark/current/';
    case CLIENT.Py2Neo:
      return 'https://neo4j.com/developer-blog/py2neo-end-migration-guide/';
    case CLIENT.Neobolt:
      return 'https://pypi.org/project/neobolt/';
    case CLIENT.Ruby:
      return 'https://neo4j.com/docs/getting-started/languages-guides/community-drivers/#neo4j-ruby';
    case CLIENT.PhpClient:
      return 'https://neo4j.com/docs/getting-started/languages-guides/community-drivers/#neo4j-php';
    case CLIENT.Neomodel:
      return 'https://neo4j.com/docs/getting-started/languages-guides/community-drivers/#neomodel-lib';
    case CLIENT.OpsManager:
      return 'https://neo4j.com/docs/ops-manager/current/';
    case CLIENT.Unknown:
    default:
      return undefined;
  }
};

function toDriverDisplayName(client: CLIENT, rawDriver?: string) {
  switch (client) {
    case CLIENT.Java:
      return 'Java Driver';
    case CLIENT.Javascript:
      return 'JavaScript Driver';
    case CLIENT.Dotnet:
      return '.NET Driver';
    case CLIENT.Golang:
      return 'Go Driver';
    case CLIENT.KafkaConnect:
      return 'Neo4j Connector for Kafka';
    case CLIENT.DatabricksConnector:
      return 'Neo4j Connector for Databricks';
    case CLIENT.Desktop:
      return 'Neo4j Desktop';
    case CLIENT.Browser:
      return 'Neo4j Browser';
    case CLIENT.Graphql:
      return 'Neo4j GraphQL Library';
    case CLIENT.Py2Neo:
      return 'Py2neo Driver';
    case CLIENT.Ruby:
      return 'Neo4j.rb Driver';
    case CLIENT.PhpClient:
      return 'Neo4j PHP Client';
    case CLIENT.Bloom:
      return 'Neo4j Bloom (Aura Explore)';
    case CLIENT.Workspace:
      return 'Neo4j Workspace (Aura Console)';
    case CLIENT.Query:
      return 'Neo4j Browser (Aura Query)';
    case CLIENT.Import:
      return 'Neo4j Data Importer (Aura Import)';
    case CLIENT.OpsManager:
      return 'Neo4j Ops Manager';
    case CLIENT.SparkConnector:
      return 'Neo4j Connector for Apache Spark';
    case CLIENT.Unknown:
      return isNonEmptyString(rawDriver) ? `"${rawDriver}"` : '';
    default:
      return capitalizeFirstLowerRest(client);
  }
}

const getReleaseNotesUrl = (client: CLIENT) => {
  const repositoryName = getDriverGitHubRepositoryName(client);
  if (!isNonEmptyString(repositoryName)) {
    return undefined;
  }
  switch (client) {
    case CLIENT.SparkConnector:
    case CLIENT.KafkaConnect:
      // no specific page for release notes
      return undefined;
    default:
      return `https://github.com/${repositoryName}/wiki`;
  }
};

export const useDriverColumns = (hasData: boolean, timeRange: TimeRange) =>
  useMemo(() => {
    const columnHelper = createColumnHelper<OpsTypes.Migration.DriverLogAggregation>();
    const columns = [
      columnHelper.accessor('rawDriver', {
        id: 'client',
        header: 'Client',
        size: 250,
        meta: {
          logs: {
            isPersistent: true,
          },
        },
        cell: function Cell({ row }) {
          const { client, clientType, rawDriver } = row.original;
          return (
            <div>
              <div title={clientType === CLIENT_TYPE.Driver ? `Driver: ${rawDriver}` : undefined}>
                {toDriverDisplayName(client, rawDriver)}
              </div>
              {clientType === CLIENT_TYPE.Application && (
                <Typography variant="body-small">Driver: {rawDriver}</Typography>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor('driverVersion', {
        header: 'Driver version',
        meta: {
          logs: {
            isPersistent: true,
          },
        },
      }),
      columnHelper.accessor('driverVersionStatus', {
        id: 'driverVersionStatus',
        header: 'Status',
        size: 200,
        meta: {
          logs: {
            isPersistent: true,
          },
        },
        sortingFn: (a, b) =>
          toDriverStatusPriority(b.original.driverVersionStatus) -
          toDriverStatusPriority(a.original.driverVersionStatus),
        cell: function Cell({ row }) {
          const [color, text] = getLabelProps(row.original.driverVersionStatus);
          return (
            <div>
              <Label color={color}>{text}</Label>
            </div>
          );
        },
      }),
      columnHelper.accessor('client', {
        id: 'latestVersion',
        header: 'Latest version',
        cell: function Cell({ row }) {
          const { client } = row.original;
          if (client === CLIENT.Unknown) {
            return '';
          }
          const repositoryName = getDriverGitHubRepositoryName(client);
          const driverDocsUrl = getDriverDocsUrl(client);
          const downloadUrl = getDownloadUrl(client);
          const releaseNotesUrl = getReleaseNotesUrl(client);
          return (
            <div>
              {repositoryName && (
                <TextLink
                  href={`https://github.com/${repositoryName}/releases`}
                  htmlAttributes={{
                    target: '_blank',
                  }}
                >
                  <img
                    src={`https://img.shields.io/github/v/release/${repositoryName}.svg?style=flat`}
                    alt={`Latest version of ${client} driver`}
                  />
                </TextLink>
              )}
              {isNonEmptyString(releaseNotesUrl) && (
                <TextLink isExternalLink href={releaseNotesUrl}>
                  Release notes
                </TextLink>
              )}
              {isNonEmptyString(downloadUrl) && (
                <TextLink isExternalLink href={downloadUrl}>
                  Download
                </TextLink>
              )}
              {driverDocsUrl && (
                <TextLink isExternalLink href={driverDocsUrl}>
                  Docs
                </TextLink>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor('queryCount', {
        header: 'Query count',
        cell: NumberCell,
      }),
      columnHelper.accessor('lastTimestamp', {
        header: 'Last Execution',
        cell: TimestampCell,
      }),
      columnHelper.accessor('users', {
        header: 'Users',
        cell: ValueListCell,
        enableSorting: false,
      }),
      columnHelper.accessor('databases', {
        header: 'Databases',
        cell: ValueListCell,
        enableSorting: false,
      }),
      columnHelper.accessor('app', {
        header: 'Application',
      }),
      columnHelper.accessor('initiationTypes', {
        header: 'Initiation Types',
        cell: ValueListCell,
        enableSorting: false,
      }),
      columnHelper.display({
        ...columnPreferencesColumn,
        cell: ({ row }) => {
          const dbmsFilters = {
            // TODO: should use initialFilters() defined in ../constants.ts but NavigateToLogsButton.dbmsFilters prop
            // expects this type of filter. This is more of a Typescript refactoring issue.
            ...initialFilters().dbmsFilters,
            ...(isNonEmptyString(row.original.rawDriver) && { drivers: [row.original.rawDriver] }),
          };
          if (isNonEmptyString(row.original.app)) {
            // TODO if app is null in BigQuery (this is sometimes the case and is allowed by
            // the GraphQL interface definition), app will be null here but
            // navigating to QLA would produce no results since QLA filter criteria can't be null.
            // So just ignoring nulls so that no filtering on "apps" will be done in QLA and
            // the user would at least get some results.
            dbmsFilters.apps = [row.original.app];
          }
          return (
            <div className={classNames(dataGridHelpersClasses['visible-on-row-hover'])}>
              <NavigateToLogsButton
                timeRange={timeRange}
                databases={row.original.databases}
                dbmsFilters={dbmsFilters}
              />
            </div>
          );
        },
      }),
    ];

    const resizedColumns = hasData
      ? columns
      : columns.map((col) => ((col.size ?? 0) > 85 ? { ...col, size: 135 } : col));

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return resizedColumns as ColumnDef<OpsTypes.Migration.DriverLogAggregation>[];
  }, [hasData, timeRange]);
