import { LEGACY_disconnectDriver as disconnectDriver } from '@nx/state';
import { type ParsedCommand, parseCommand } from '@query/redux/commands';
import type { EvaluateParameterPayload } from '@query/redux/params-thunks';
import { queryEvaluateParametersThunk } from '@query/redux/params-thunks';
import { clearRequests, createRequests, isDefinedRequestId, stopRequests } from '@query/redux/requests-slice';
import type { StopRequestsReason } from '@query/redux/requests-slice';
import type { Frame } from '@query/redux/stream-slice';
import type { QueryError } from '@query/types/query';
import { type ThunkDispatch, type UnknownAction, createAsyncThunk, nanoid } from '@reduxjs/toolkit';

import type { ExecutedCypherCommand, ExecutedCypherCommandPayload } from './cypher-thunks';
import { executeCypherCommandThunk } from './cypher-thunks';
import { type Command, cmdRan } from './editor-slice';
import { selectParametersSnapshot } from './params-slice';
import type { FrameIdAndDatabaseName } from './request-database-switch';
import { requestDatabaseSwitch } from './request-database-switch';
import type { RootState } from './store';
import type { ExecutedSysInfoCommand, ExecutedSysInfoCommandPayload } from './sysinfo-thunks';
import { executeSysInfoCommandThunk } from './sysinfo-thunks';

export type ExecuteCommandPayload = Omit<Command, 'parsedCmd' | 'frameId' | 'parameterSnapshot' | 'requestId'> & {
  frameId?: string;
};

export type ExecutedCommand = { historyId?: string };

type FulfilledBaseMeta = { requestId: string; requestStatus: 'fulfilled' };

type FulfilledParameterMeta = FulfilledBaseMeta & {
  arg: EvaluateParameterPayload;
};
type FulfilledCypherMeta = FulfilledBaseMeta & {
  arg: ExecutedCypherCommandPayload;
};
type FulfilledSwitchDatabaseMeta = FulfilledBaseMeta & {
  arg: FrameIdAndDatabaseName;
};
type FulfilledSysInfoMeta = FulfilledBaseMeta & {
  arg: ExecutedSysInfoCommandPayload;
};
type RejectedBaseMeta = { arg: unknown; requestId: string; requestStatus: 'rejected'; aborted: boolean };

type Meta =
  | FulfilledParameterMeta
  | FulfilledCypherMeta
  | FulfilledSwitchDatabaseMeta
  | FulfilledSysInfoMeta
  | RejectedBaseMeta;

const isFulfilledSwitchDatabaseMeta = (meta: Meta | undefined): meta is FulfilledSwitchDatabaseMeta =>
  meta !== undefined && meta.requestStatus === 'fulfilled' && 'requestedDatabaseName' in meta.arg;

const dispatchStopRequestsAction = (
  dispatch: ThunkDispatch<RootState, unknown, UnknownAction>,
  parsedCommands: ParsedCommand[],
  reason: StopRequestsReason,
) => {
  const requestIds = parsedCommands.map((parsedCommand) => parsedCommand.requestId).filter(isDefinedRequestId);
  dispatch(stopRequests(requestIds, reason));
};

const dispatchOperationThunk = async (
  dispatch: ThunkDispatch<RootState, unknown, UnknownAction>,
  parsedCmd: ParsedCommand,
  command: ExecuteCommandPayload,
  parameterSnapshot: Record<string, unknown>,
  frameId: string,
): Promise<{
  meta: Meta | undefined;
  payload:
    | ExecutedCypherCommand
    | ExecutedSysInfoCommand
    | {
        error: QueryError;
        aborted: boolean;
      }
    | undefined;
}> => {
  let meta;
  let payload;

  if (parsedCmd.type === 'parameter' && parsedCmd.requestId !== null) {
    ({ meta } = await dispatch(
      queryEvaluateParametersThunk({
        requestId: parsedCmd.requestId,
        parsed: parsedCmd.args,
      }),
    ));
  } else if (parsedCmd.type === 'cypher') {
    ({ meta, payload } = await dispatch(
      executeCypherCommandThunk({
        requestId: parsedCmd.requestId,
        command,
        parameterSnapshot,
      }),
    ));
  } else if (parsedCmd.type === 'use') {
    const { requestedDatabaseName } = parsedCmd;
    ({ meta } = await dispatch(
      requestDatabaseSwitch({
        requestId: parsedCmd.requestId,
        requestedDatabaseName,
      }),
    ));
  } else if (parsedCmd.type === 'sysinfo') {
    ({ meta, payload } = await dispatch(
      executeSysInfoCommandThunk({ requestId: parsedCmd.requestId, command, frameId }),
    ));
  }

  return { meta, payload };
};

const clearPreviousRequests = (
  dispatch: ThunkDispatch<RootState, unknown, UnknownAction>,
  frame: Frame | undefined,
) => {
  if (frame !== undefined) {
    const requestIds = frame.requestIds.map((requestId) => requestId).filter(isDefinedRequestId);

    if (requestIds.length > 0) {
      void dispatch(clearRequests(requestIds));
    }
  }
};

const operations: string[] = ['cypher', 'parameter', 'use', 'sysinfo'];

export const executeCommand = createAsyncThunk<
  ExecutedCommand,
  ExecuteCommandPayload,
  { rejectValue: { historyId?: string; canceled: boolean }; state: RootState }
>(
  'stream/executeCommand',
  async (commandPayload: ExecuteCommandPayload, { dispatch, getState, rejectWithValue }): Promise<ExecutedCommand> => {
    if (commandPayload.frameId !== undefined) {
      const frame = getState().stream.byId[commandPayload.frameId];
      clearPreviousRequests(dispatch, frame);
    }

    const parsedCmd = parseCommand(commandPayload.cmd);
    const newFrameId = nanoid();
    const frameId = commandPayload.frameId ?? newFrameId;
    const parameterSnapshot = selectParametersSnapshot(getState());

    dispatch(createRequests(parsedCmd));

    const executedCommand = cmdRan({ ...commandPayload, parameterSnapshot, parsedCmd, frameId });

    dispatch(executedCommand);

    let meta;
    let payload;

    if (parsedCmd.type === 'multistatement') {
      const parsedCommands = parsedCmd.commands.map((command) => command.parsed);
      const { connection } = commandPayload;
      let currentDatabase = connection?.database ?? null;

      if (parsedCommands.some((command) => command.requestId === null)) {
        dispatchStopRequestsAction(dispatch, parsedCommands, 'abort');
        throw rejectWithValue({ ...executedCommand.payload, canceled: false });
      }

      for (const command of parsedCmd.commands) {
        const currentParameterSnapshot = selectParametersSnapshot(getState());
        const currentConnection = {
          boltUrl: connection?.boltUrl ?? '',
          database: currentDatabase,
          instanceName: connection?.instanceName,
          username: connection?.username ?? null,
        };

        if (command.parsed.type === 'parameter' || command.parsed.type === 'cypher' || command.parsed.type === 'use') {
          const commandWithRecordLimit = {
            ...commandPayload,
            cmd: command.raw,
            recordLimit: 0,
            connection: currentConnection,
          };
          // eslint-disable-next-line no-await-in-loop
          ({ meta, payload } = await dispatchOperationThunk(
            dispatch,
            command.parsed,
            commandWithRecordLimit,
            currentParameterSnapshot,
            frameId,
          ));

          if (command.parsed.type === 'use' && isFulfilledSwitchDatabaseMeta(meta)) {
            currentDatabase = meta.arg.requestedDatabaseName;
          }

          if (meta?.requestStatus === 'rejected') {
            const canceled = payload !== undefined && 'error' in payload ? payload.aborted : meta.aborted;
            dispatchStopRequestsAction(dispatch, parsedCommands, canceled ? 'cancel' : 'abort');
            throw rejectWithValue({ ...executedCommand.payload, canceled });
          }
        }
      }
    } else if (operations.includes(parsedCmd.type)) {
      ({ meta, payload } = await dispatchOperationThunk(
        dispatch,
        parsedCmd,
        commandPayload,
        parameterSnapshot,
        frameId,
      ));

      if (meta?.requestStatus === 'rejected') {
        const canceled = payload !== undefined && 'error' in payload ? payload.aborted : meta.aborted;
        throw rejectWithValue({ ...executedCommand.payload, canceled });
      }
    } else if (parsedCmd.type === 'connection' && parsedCmd.command.arg === 'disconnect') {
      void disconnectDriver();
    }

    return executedCommand.payload;
  },
);
