import { MODAL_TYPE, useCurrentModal } from '@nx/state';

export const usePassedConnectionDetails = () => {
  // This hooks let's you access the connectionDetails passed to the `connectionModal.open` call
  // Usually from using the `connectURL` url param
  // The connection details used to be stored globally on the router state
  // they should be passed as props to the modal but pulling them from
  // redux directly instead of doing the larger refactor as the connnection
  // modals and the URL param handling will likely be refactored soon
  const connModalProps = useCurrentModal();
  if (connModalProps?.type === MODAL_TYPE.DATA_SOURCE && connModalProps.connectionDetails !== undefined) {
    return connModalProps.connectionDetails;
  }

  return undefined;
};
