import type { EndpointBuilder } from '@reduxjs/toolkit/query';

import { customCreateApi } from '../../context';
import { createDynamicBaseQuery } from '../../utils/create-dynamic-base-query';
import type { DynamicBaseQueryFn } from '../../utils/create-dynamic-base-query';
import * as AppContext from '../app-context-slice';
import { selectAuraConfiguration } from '../configuration/configuration-slice';
import { addNotification } from '../notifications-slice';
import { getAccessToken } from '../session-slice';

export const tagTypes = ['Status'] as const;
export const LIST_ID = 'LIST';

export type TagType = (typeof tagTypes)[number];

export type Builder = EndpointBuilder<DynamicBaseQueryFn, TagType, 'guidesApi'>;

export type GuideStatus = {
  id: string;
  name: string;
  currentPage: number;
  isCompleted: boolean;
  version: number;
  createdAt: string;
  updatedAt: string;
};

export type GuideStatusCreateInput = Pick<GuideStatus, 'name' | 'currentPage' | 'isCompleted' | 'version'>;

export type GuideStatusUpdateInput = Partial<GuideStatusCreateInput>;

const BASE_PATH = 'unstable/guides-status';

const isAppContextState = (state: unknown): state is { appContext: AppContext.AppContextSliceState } => {
  return state !== null && typeof state === 'object' && AppContext.slice.name in state;
};

const guidesApi = customCreateApi({
  baseQuery: createDynamicBaseQuery((state) => selectAuraConfiguration(state)?.storageApiUrl, {
    prepareHeaders: async (headers, { getState }) => {
      const accessToken = await getAccessToken();
      headers.set('Authorization', `Bearer ${accessToken}`);
      const state = getState();

      if (isAppContextState(state)) {
        const projectId = state.appContext.activeProjectId;
        if (projectId !== null) {
          headers.set('Project-Id', projectId);
        }
      }
    },
  }),
  reducerPath: 'guidesApi',
  tagTypes,
  endpoints: (builder) => ({
    getGuidesStatuses: builder.query<GuideStatus[], void>({
      query: () => ({
        url: BASE_PATH,
        method: 'GET',
      }),
      providesTags: [{ type: 'Status', id: LIST_ID }],
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.catch((error) => {
          dispatch(
            addNotification({
              type: 'warning',
              title: 'Failed to restore guides progress',
              description: 'Please try again later',
            }),
          );
        });
      },
    }),

    getGuideStatus: builder.query<GuideStatus, string>({
      query: (id) => ({
        url: `${BASE_PATH}/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'Status', id }],
    }),

    createGuideStatus: builder.mutation<GuideStatus, GuideStatusCreateInput>({
      query: (guideStatus) => ({
        url: BASE_PATH,
        method: 'POST',
        body: guideStatus,
      }),
      invalidatesTags: [{ type: 'Status', id: LIST_ID }],
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.catch((error) => {
          dispatch(
            addNotification({
              type: 'warning',
              title: 'Failed to save guide progress',
              description: '',
            }),
          );
        });
      },
    }),

    updateGuideStatus: builder.mutation<GuideStatus, { id: string; guideStatus: GuideStatusUpdateInput }>({
      query: ({ id, guideStatus }) => ({
        url: `${BASE_PATH}/${id}`,
        method: 'PATCH',
        body: guideStatus,
      }),
      onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        dispatch(
          guidesApi.util.updateQueryData('getGuidesStatuses', undefined, (draft) => {
            const status = draft.find((s) => s.id === id);
            if (status) {
              Object.assign(status, patch.guideStatus);
            }
          }),
        );

        queryFulfilled.catch(() => {
          dispatch(
            addNotification({
              type: 'warning',
              title: 'Failed to save guide progress',
              description: '',
            }),
          );
        });
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Status', id },
        { type: 'Status', id: LIST_ID },
      ],
    }),

    deleteGuideStatus: builder.mutation<void, string>({
      query: (id) => ({
        url: `${BASE_PATH}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Status', id },
        { type: 'Status', id: LIST_ID },
      ],
    }),
  }),
});

export default guidesApi;
