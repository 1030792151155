import * as ImportShared from '@nx/import-shared';
import { isNullish } from '@nx/stdlib';

import CSVIcon from '../../assets/csv-icon.svg';
import defaultLogo from '../../assets/data-sources-splash.svg';
import MySQLLogo from '../../assets/mysql-logo.svg';
import OracleLogo from '../../assets/oracle-logo.svg';
import PostgreSQLLogo from '../../assets/postgresql-logo.svg';
import SnowflakeLogo from '../../assets/snowflake-logo.svg';
import SQLServerLogo from '../../assets/sql-server-logo.svg';

const DATA_SOURCE_ICON: Record<string, string> = {
  snowflake: SnowflakeLogo,
  postgresql: PostgreSQLLogo,
  sqlserver: SQLServerLogo,
  oracle: OracleLogo,
  mysql: MySQLLogo,
  csv: CSVIcon,
};

export const DEFAULT_DB_ICON = defaultLogo;

export const getDataSourceIcon = (type: string) => DATA_SOURCE_ICON[type] ?? DEFAULT_DB_ICON;

export const getDataSourceTypeGroupLabelFromDefinitions = (
  type: string,
  dataSourceDefinitions?: ImportShared.DataSourceDefinition[],
) => {
  const dataSource = dataSourceDefinitions?.find((d) => d.type === type);
  return dataSource?.groupLabel;
};

export const getNumberValue = (value: ImportShared.FormFieldValue | undefined) => {
  return !isNaN(Number(value)) ? Number(value) : 0;
};

export const getStringValue = (value: ImportShared.FormFieldValue | undefined) => {
  return value?.toString() ?? '';
};

export const getBooleanValue = (value: ImportShared.FormFieldValue | undefined) => {
  return value === 'true';
};

const getValidFieldValue = (
  field: ImportShared.Field | ImportShared.FieldDefinition,
  value: ImportShared.FormFieldValue | undefined,
) => {
  if (field.type === ImportShared.INTEGER_CONFIGURATION_TYPE) {
    return getNumberValue(value);
  } else if (field.type === ImportShared.BOOLEAN_CONFIGURATION_TYPE) {
    return getBooleanValue(value);
  }
  return getStringValue(value);
};

export const getDefaultFieldValueFromDefinition = (field: ImportShared.FieldDefinition) => {
  return getValidFieldValue(field, field.default);
};

const getNumberFormField = (
  field: ImportShared.FormField | ImportShared.FieldDefinition,
  value: ImportShared.FormFieldValue,
): ImportShared.NumberFormField => {
  const numberValue = getNumberValue(value);
  return {
    ...field,
    type: ImportShared.INTEGER_CONFIGURATION_TYPE,
    value: numberValue,
  };
};

const getBooleanFormField = (
  field: ImportShared.FormField | ImportShared.FieldDefinition,
  value: ImportShared.FormFieldValue,
): ImportShared.BooleanFormField => {
  return {
    ...field,
    type: ImportShared.BOOLEAN_CONFIGURATION_TYPE,
    value: getBooleanValue(value),
  };
};

const getTextFormField = (
  field: ImportShared.FormField | ImportShared.FieldDefinition,
  value: ImportShared.FormFieldValue,
): ImportShared.TextFormField => {
  return {
    ...field,
    type: ImportShared.TEXT_CONFIGURATION_TYPE,
    value: getStringValue(value),
  };
};

const getUserNameFormField = (
  field: ImportShared.FormField | ImportShared.FieldDefinition,
  value: ImportShared.FormFieldValue,
): ImportShared.TextFormField => {
  return {
    ...field,
    type: ImportShared.USERNAME_CONFIGURATION_TYPE,
    value: getStringValue(value),
  };
};

const getPasswordFormField = (
  field: ImportShared.FormField | ImportShared.FieldDefinition,
  value: ImportShared.FormFieldValue,
): ImportShared.TextFormField => {
  return {
    ...field,
    type: ImportShared.PASSWORD_CONFIGURATION_TYPE,
    value: getStringValue(value),
  };
};

export const getFormFieldWithCorrectType = (
  field: ImportShared.FormField | ImportShared.FieldDefinition,
  value: ImportShared.FormFieldValue,
): ImportShared.FormField => {
  switch (field.type) {
    case ImportShared.INTEGER_CONFIGURATION_TYPE:
      return getNumberFormField(field, value);
    case ImportShared.BOOLEAN_CONFIGURATION_TYPE:
      return getBooleanFormField(field, value);
    case ImportShared.USERNAME_CONFIGURATION_TYPE:
      return getUserNameFormField(field, value);
    case ImportShared.PASSWORD_CONFIGURATION_TYPE:
      return getPasswordFormField(field, value);
    default:
      return getTextFormField(field, value);
  }
};

export const getFieldWithCorrectType = (
  field: ImportShared.FieldDefinition,
  value: ImportShared.FormFieldValue,
): ImportShared.Field => {
  let formField: ImportShared.FormField;
  if (field.type === ImportShared.INTEGER_CONFIGURATION_TYPE) {
    formField = getNumberFormField(field, value);
    // Have to return a Field type here otherwise TS will complain
    return {
      name: formField.name,
      type: formField.type,
      value: formField.value,
    };
  } else if (field.type === ImportShared.BOOLEAN_CONFIGURATION_TYPE) {
    formField = getBooleanFormField(field, value);
    // Have to return a Field type here otherwise TS will complain
    return {
      name: formField.name,
      type: formField.type,
      value: formField.value,
    };
  } else if (field.type === ImportShared.USERNAME_CONFIGURATION_TYPE) {
    formField = getUserNameFormField(field, value);
  } else if (field.type === ImportShared.PASSWORD_CONFIGURATION_TYPE) {
    formField = getPasswordFormField(field, value);
  } else {
    formField = getTextFormField(field, value);
  }
  return {
    name: formField.name,
    type: formField.type,
    value: formField.value,
  };
};

export const getFormFieldWithValueFromDefinition = (field: ImportShared.FieldDefinition) => {
  const value = getDefaultFieldValueFromDefinition(field);
  return getFormFieldWithCorrectType(field, value);
};

export const getFieldValueFromDataSourceConfig = (
  fieldName: string,
  dataSourceConfig: ImportShared.DataSourceConfig,
): ImportShared.FormFieldValue => {
  const field = dataSourceConfig.fields.find((f) => f.name === fieldName);
  if (!isNullish(field)) {
    return getValidFieldValue(field, field.value);
  }
  return '';
};

export const getStringFieldValueByName = (
  fieldName: string,
  dataSourceConfig: ImportShared.DataSourceConfig,
): string => {
  const value = getFieldValueFromDataSourceConfig(fieldName, dataSourceConfig);
  return value.toString();
};

export const getAllFieldsInDefinition = (dataSourceConfig: ImportShared.DataSourceDefinition) => {
  return dataSourceConfig.sections.flatMap((section) => section.fields);
};

export const getDataSourceConfigWithUserAndPassword = (
  dataSourceConfig: ImportShared.DataSourceConfig,
  user: string,
  password: string,
) => {
  const userField: ImportShared.TextField = {
    name: 'user',
    value: user,
    type: ImportShared.USERNAME_CONFIGURATION_TYPE,
  } as const;
  const passwordField: ImportShared.TextField = {
    name: 'password',
    value: password,
    type: ImportShared.PASSWORD_CONFIGURATION_TYPE,
  };

  const fields: ImportShared.Field[] = dataSourceConfig.fields.filter(
    (f) => f.name !== 'user' && f.name !== 'password',
  );

  const newFields = [...fields, userField, passwordField];
  const newDataSourceConfig: ImportShared.DataSourceConfig = { ...dataSourceConfig, fields: newFields };
  return newDataSourceConfig;
};

export const getFieldValue = (field: ImportShared.FieldDefinition, value: ImportShared.FormFieldValue) => {
  if (field.type === ImportShared.INTEGER_CONFIGURATION_TYPE) {
    return Number(value);
  } else if (field.type === ImportShared.BOOLEAN_CONFIGURATION_TYPE) {
    return value === true;
  }
  return value;
};
