import { ClipboardButton, TextInput } from '@neo4j-ndl/react';

export function CopyTextInput({ label, value, isPortaled }: { label: string; value: string; isPortaled?: boolean }) {
  return (
    <TextInput
      label={label}
      isFluid
      size="medium"
      value={value}
      htmlAttributes={{
        'aria-label': label,
        readOnly: true,
      }}
      rightElement={
        <span className="relative">
          <ClipboardButton
            textToCopy={value}
            htmlAttributes={{ 'aria-label': 'Copy to clipboard' }}
            tooltipProps={{
              isPortaled,
              type: 'simple',
            }}
          />
        </span>
      }
    />
  );
}
