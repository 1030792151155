import type { StaticDecode, TSchema } from '@sinclair/typebox';
import { Value } from '@sinclair/typebox/value';

export function validate<T extends TSchema>(schema: T, data: unknown): StaticDecode<T> {
  const errors = [...Value.Errors(schema, data)];

  if (errors.length > 0) {
    errors.forEach((error) => {
      const path = error.path
        // Remove first character (slash)
        .slice(1)
        // Split into segments
        .split('/')
        // Join segments into more typical object notation
        .join('.');

      // eslint-disable-next-line no-console
      console.error(`Validation error:`, error.message, 'at', path);
    });

    throw new Error(`Given input is not valid`);
  }

  return Value.Decode(schema, data);
}
