import { Button, Label, Tooltip } from '@neo4j-ndl/react';
import { Cog8ToothIconOutline, ShieldCheckIconOutline } from '@neo4j-ndl/react/icons';
import type { Project, TrafficConfig } from '@nx/state';
import { TRAFFIC_ENABLEMENT, TRAFFIC_STATUS } from '@nx/state';
import { DataGridHelpers } from '@nx/ui';
import { createColumnHelper, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';

import { getProductFromTier, getRegionsForAllCsps } from '../entities/helpers';
import { DeleteNetworkConfig } from './delete-network-config';
import { TrafficConfigDialog } from './dialog';

const columnHelper = createColumnHelper<TrafficConfig>();
const INTERMEDIATE_STATUS = [TRAFFIC_STATUS.CREATING, TRAFFIC_STATUS.UPDATING, TRAFFIC_STATUS.DELETING];

type TrafficEnablementLabelProps = {
  value: TRAFFIC_ENABLEMENT;
  status: TRAFFIC_STATUS;
  desiredStatus: TRAFFIC_STATUS;
  dataTestId?: string;
};

const TrafficEnablementLabel = ({ value, status, desiredStatus, dataTestId }: TrafficEnablementLabelProps) => {
  let color: 'default' | 'warning' | 'success' = 'default';
  let displayValue: TRAFFIC_ENABLEMENT | TRAFFIC_STATUS = value;
  let fill: React.ComponentProps<typeof Label>['fill'] = desiredStatus === status ? 'semi-filled' : 'clean';
  color = desiredStatus === status ? 'success' : 'warning';

  if (INTERMEDIATE_STATUS.includes(status)) {
    color = 'warning';
    displayValue = status;
  } else if (status === TRAFFIC_STATUS.RUNNING) {
    // Override display value to say enabled as long as
    // status is still running, otherwise we get "Disabled"
    // but status is running so it has the success label
    displayValue = TRAFFIC_ENABLEMENT.ENABLED;
    fill = 'semi-filled';
  }

  return (
    <Label
      fill={fill}
      color={color}
      hasIcon
      htmlAttributes={{
        'data-testid': dataTestId,
      }}
    >
      {displayValue === TRAFFIC_STATUS.DELETING ? 'Disabling' : displayValue}
    </Label>
  );
};

interface StatusComponentProps {
  trafficConfig: TrafficConfig;
  project: Project;
}

const StatusComponent = ({ trafficConfig, project }: StatusComponentProps) => {
  const [editOpen, setEditOpen] = useState(false);
  const [loading] = useState(false);

  const handleConfigureClick = () => {
    setEditOpen(true);
  };
  const handleClose = () => {
    setEditOpen(false);
  };

  return (
    <div className="align-center flex flex-grow justify-end gap-2">
      <Button onClick={handleConfigureClick} fill="outlined" isDisabled={loading}>
        <Cog8ToothIconOutline className="h-full" />
        Configure
      </Button>
      <DeleteNetworkConfig project={project} trafficConfig={trafficConfig} />
      {editOpen && (
        <TrafficConfigDialog
          project={project}
          trafficConfig={trafficConfig}
          title="Edit network access configuration"
          onClose={handleClose}
        />
      )}
    </div>
  );
};

type TrafficConfigsTableProps = { project: Project; configs: TrafficConfig[] };

export const TrafficConfigsTable = ({ project, configs }: TrafficConfigsTableProps) => {
  const regions = useMemo(() => getRegionsForAllCsps(project.tierConfigs), [project.tierConfigs]);
  const columns = useMemo(
    () => [
      columnHelper.accessor('region', {
        cell: (c) => {
          const trafficConfig = c.row.original;
          const isProtected =
            trafficConfig.status.privateTraffic === TRAFFIC_STATUS.RUNNING &&
            trafficConfig.status.publicTraffic === TRAFFIC_STATUS.DELETED;
          const value = c.getValue();
          const region = regions.find((r) => r.name === value);
          return (
            <span className="relative">
              {isProtected && (
                <Tooltip type="simple">
                  <Tooltip.Trigger hasButtonWrapper>
                    <ShieldCheckIconOutline
                      aria-label="Traffic protected"
                      height="20px"
                      className="absolute"
                      style={{ left: '-15px' }}
                    />
                  </Tooltip.Trigger>
                  <Tooltip.Content>
                    <Tooltip.Body>Traffic protected</Tooltip.Body>
                  </Tooltip.Content>
                </Tooltip>
              )}
              <span className="ml-2">{region?.friendly}</span>
            </span>
          );
        },
        header: () => {
          return (
            <span title="Region" className="ml-2">
              Region
            </span>
          );
        },
        minSize: 300,
      }),
      columnHelper.accessor('tier', {
        cell: (c) => {
          const product = getProductFromTier(c.getValue());
          return <span>{product}</span>;
        },
        header: 'Instance Type',
      }),
      columnHelper.accessor('privateTraffic', {
        cell: (c) => {
          const value = c.getValue();
          const trafficConfig = c.row.original;
          return (
            <TrafficEnablementLabel
              value={value}
              status={trafficConfig.status.privateTraffic}
              desiredStatus={TRAFFIC_STATUS.RUNNING}
              dataTestId="private-traffic-label"
            />
          );
        },
        header: 'Private traffic',
      }),
      columnHelper.accessor('publicTraffic', {
        cell: (c) => {
          const value = c.getValue();
          const trafficConfig = c.row.original;
          return (
            <TrafficEnablementLabel
              value={value}
              status={trafficConfig.status.publicTraffic}
              desiredStatus={TRAFFIC_STATUS.DELETED}
              dataTestId="public-traffic-label"
            />
          );
        },
        header: 'Public traffic',
      }),
      columnHelper.display({
        id: 'status',
        cell: (c): React.ReactNode => {
          const trafficConfig = c.row.original;
          return <StatusComponent trafficConfig={trafficConfig} project={project} />;
        },
        size: 220,
        maxSize: 220,
      }),
    ],
    [project, regions],
  );

  const table = useReactTable({
    data: configs,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      columnPinning: {
        right: ['status'],
      },
    },
  });

  return (
    <DataGridHelpers.Wrapper>
      <DataGridHelpers.DataGridRightColumnPinned<TrafficConfig>
        tableInstance={table}
        styling={{ headerStyle: 'clean', borderStyle: 'horizontal' }}
        isResizable={false}
      />
    </DataGridHelpers.Wrapper>
  );
};
