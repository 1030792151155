import { tokens } from '@neo4j-ndl/base';
import {
  Button,
  Checkbox,
  Divider,
  IconButton,
  Select,
  TextInput,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@neo4j-ndl/react';
import { ExclamationCircleIconSolid, InformationCircleIconOutline, TrashIconOutline } from '@neo4j-ndl/react/icons';
import type { DataApiModify, ModifyDataApiAuthProvider } from '@nx/state';
import { DATA_API_AUTH_PROVIDER_TYPE } from '@nx/state';
import { isNotNullish, isNullish } from '@nx/stdlib';

import { MODIFY_MODE } from '../types';
import type { Validation } from '../utils/validation';

const optionsAuthProviderType = [
  { label: 'API Key', value: DATA_API_AUTH_PROVIDER_TYPE.apiKey },
  { label: 'JWKS', value: DATA_API_AUTH_PROVIDER_TYPE.jwks },
];

const emptyAuthProvider: ModifyDataApiAuthProvider = {
  type: DATA_API_AUTH_PROVIDER_TYPE.apiKey,
  name: '',
  enabled: true,
};

interface AuthProviderListProps {
  mode: MODIFY_MODE;
  data: DataApiModify;
  setData: React.Dispatch<React.SetStateAction<DataApiModify>>;
  validationError: Validation<DataApiModify> | null;
  setValidationError: React.Dispatch<React.SetStateAction<Validation<DataApiModify> | null>>;
}

export const AuthProviderList = ({
  mode,
  data,
  validationError,
  setData,
  setValidationError,
}: AuthProviderListProps) => {
  const isXlOrBelow = useMediaQuery(`(max-width:${tokens.breakpoints.xl})`);
  const isLgOrBelow = useMediaQuery(`(max-width:${tokens.breakpoints.lg})`);

  const handleTypeUpdate = (type: DATA_API_AUTH_PROVIDER_TYPE, index: number) => {
    setValidationError(null);
    const authProviders = data.authProviders.map((prov, idx) => (index === idx ? { ...prov, type } : prov));
    setData((prev) => ({ ...prev, authProviders }));
  };

  const handleNameUpdate = (name: string, index: number) => {
    setValidationError(null);
    const authProviders = data.authProviders.map((prov, idx) => (index === idx ? { ...prov, name } : prov));
    setData((prev) => ({ ...prev, authProviders }));
  };

  const handleUrlUpdate = (url: string, index: number) => {
    setValidationError(null);
    const authProviders = data.authProviders.map((prov, idx) => (index === idx ? { ...prov, url } : prov));
    setData((prev) => ({ ...prev, authProviders }));
  };

  const handleEnableUpdate = (enabled: boolean, index: number) => {
    const authProviders = data.authProviders.map((prov, idx) => (index === idx ? { ...prov, enabled } : prov));
    setData((prev) => ({ ...prev, authProviders }));
  };

  const handleAdd = () => {
    setData((prev: DataApiModify) => ({
      ...prev,
      authProviders: [...prev.authProviders, emptyAuthProvider],
    }));
  };

  const handleDelete = (index: number) => {
    const authProviders = data.authProviders.filter((_, idx) => index !== idx);
    setData((prev) => ({ ...prev, authProviders }));
  };

  const LabelType = (provider: ModifyDataApiAuthProvider) => {
    const modifyModeText = mode === MODIFY_MODE.edit ? 'save' : 'create';
    const infoText = `The generated API key will be shown on ${modifyModeText}`;
    const isDisplayInfo = provider.type === DATA_API_AUTH_PROVIDER_TYPE.apiKey && isNullish(provider.id);

    return (
      <div className="flex flex-row">
        Type
        {isDisplayInfo && (
          <Tooltip type="simple" placement="bottom">
            <Tooltip.Trigger>
              <InformationCircleIconOutline className="ml-1 h-4 w-4" />
            </Tooltip.Trigger>
            <Tooltip.Content className="left-3/4 z-50">{infoText}</Tooltip.Content>
          </Tooltip>
        )}
      </div>
    );
  };

  const ErrorText = (text: string) => {
    return (
      <div className="text-palette-danger-text flex items-center gap-1">
        <div className="ndl-error-icon size-5">
          <ExclamationCircleIconSolid />
        </div>
        <Typography className="ndl-error-text" variant="body-small">
          {text}
        </Typography>
      </div>
    );
  };

  const AuthProviderRow = (provider: ModifyDataApiAuthProvider, idx: number) => {
    return (
      <div
        key={`auth-provider-${idx}`}
        className={`bg-palette-neutral-bg-default rounded-4xl mb-4 flex w-full flex-row items-end justify-between p-4 ${isXlOrBelow && 'mr-auto flex-col-reverse'}`}
      >
        <div
          className={`flex justify-normal gap-4 ${isLgOrBelow ? 'mr-auto flex-col' : 'flex-row'} ${isXlOrBelow && 'mr-auto'}`}
        >
          <Select
            label={LabelType(provider)}
            type="select"
            size="medium"
            className="w-[8rem]"
            htmlAttributes={{
              'aria-label': 'Select an authentication provider type',
              id: `auth-provider-type-${idx}`,
            }}
            isDisabled={isNotNullish(provider.id)}
            selectProps={{
              isSearchable: false,
              options: optionsAuthProviderType,
              value: {
                value: provider.type,
                label: optionsAuthProviderType.find((prov) => prov.value === provider.type)?.label,
              },
              onChange: (option) => {
                if (!isNullish(option)) {
                  handleTypeUpdate(option.value, idx);
                }
              },
            }}
            errorText={validationError?.[`authProviders[${idx}].type`]?.message}
          />
          <TextInput
            label="Name"
            isFluid
            className="w-56"
            htmlAttributes={{
              'aria-label': 'input authentication provider name',
            }}
            value={provider.name}
            onChange={(event) => handleNameUpdate(event.target.value, idx)}
            errorText={validationError?.[`authProviders[${idx}].name`]?.message}
          />
          {provider.type === DATA_API_AUTH_PROVIDER_TYPE.jwks && (
            <TextInput
              label="URL"
              isFluid
              className="w-72"
              htmlAttributes={{
                'aria-label': 'input authentication provider url',
              }}
              value={(provider.url ?? '') || ''}
              onChange={(event) => handleUrlUpdate(event.target.value, idx)}
              errorText={validationError?.[`authProviders[${idx}].url`]?.message}
            />
          )}
        </div>

        <div className="flex flex-row items-center gap-4">
          <Checkbox
            label="Enabled"
            htmlAttributes={{
              'aria-label': 'checkbox enable authentication provider',
            }}
            isChecked={provider.enabled}
            onChange={(event) => handleEnableUpdate(event.target.checked, idx)}
          />
          <Divider orientation="vertical" />
          <IconButton isClean ariaLabel="Delete auth provider" onClick={() => handleDelete(idx)}>
            <TrashIconOutline className="h-full w-full" />
          </IconButton>
        </div>
      </div>
    );
  };

  return (
    <div className="py-2">
      {data.authProviders.map((authProvider, idx) => AuthProviderRow(authProvider, idx))}
      {validationError?.['authProviders.general'] && ErrorText(validationError['authProviders.general'].message)}
      <Button fill="outlined" size="small" className="mb-4 ml-4 mt-3" onClick={() => handleAdd()}>
        Add authentication provider
      </Button>
    </div>
  );
};
