/* eslint max-depth: ["error", 5]*/
import { isNullish } from '@nx/stdlib';
import { difference, every, isNull } from 'lodash-es';

import type { DataModel_0_0_2 } from '../0.0.2/types';
import { duplicatedError, emptyFieldError, noKeyPropertyError, nonSpecifiedError } from '../../validation-errors';
import { VALIDATION_ERROR_CODE } from './types';
import type {
  DataModelErrors,
  DataModel_0_8_0,
  ErrorList,
  ErrorModel,
  FieldMapping,
  FileSchema,
  FileSchemaError,
  MappingError,
  MappingField,
  MappingModel,
  NodeMapping,
  NodeMappingError,
  NodeSchema,
  NodeSchemaError,
  Property,
  RelationshipMapping,
  RelationshipMappingError,
  RelationshipSchema,
  RelationshipSchemaError,
  SchemaError,
} from './types';

export const createDataModel = (): DataModel_0_8_0 => {
  return {
    fileModel: {
      // keyed by id (filename)
      fileSchemas: {},
    },
    graphModel: {
      // keyed by id (random id - generated by arrows)
      nodeSchemas: {},
      // keyed by id (random id - generated by arrows)
      relationshipSchemas: {},
    },
    mappingModel: {
      // keyed by id (random id - generated by arrows)
      nodeMappings: {},
      // keyed by id (random id - generated by arrows)
      relationshipMappings: {},
    },
    configurations: {
      idsToIgnore: [],
    },
  };
};

export const getFieldMappings = (
  nodeMappings: MappingModel['nodeMappings'],
  relationshipMappings: MappingModel['relationshipMappings'],
) => {
  const fieldMappings: Record<string, Record<string, FieldMapping[]>> = {};

  const addMapping = (filename: string, field: string, mapping: FieldMapping) => {
    if (isNullish(fieldMappings[filename])) {
      fieldMappings[filename] = {};
    }
    if (isNullish(fieldMappings[filename][field])) {
      fieldMappings[filename][field] = [];
    }
    fieldMappings[filename][field].push(mapping);
  };

  Object.entries(nodeMappings).forEach(([nodeId, nodeMapping]) => {
    const { fileSchema, mappings } = nodeMapping;
    if (mappings.length > 0) {
      mappings.forEach(({ field }, i) => {
        if (!isNullish(fileSchema) && !isNullish(field)) {
          addMapping(fileSchema, field, { nodeSchema: nodeId, propertyIndex: i });
        }
      });
    }
  });
  Object.entries(relationshipMappings).forEach(([relationshipId, relationshipMapping]) => {
    const { fileSchema, mappings, sourceMappings, targetMappings } = relationshipMapping;
    if (mappings.length > 0 || sourceMappings.length > 0 || targetMappings.length > 0) {
      mappings.forEach(({ field }, i) => {
        if (!isNullish(fileSchema) && !isNullish(field)) {
          addMapping(fileSchema, field, { relationshipSchema: relationshipId, propertyIndex: i });
        }
      });
      sourceMappings.forEach(({ field }, i) => {
        if (!isNullish(fileSchema) && !isNullish(field)) {
          addMapping(fileSchema, field, {
            relationshipSchema: relationshipId,
            keyIndex: i,
            source: true,
          });
        }
      });
      targetMappings.forEach(({ field }, i) => {
        if (!isNullish(fileSchema) && !isNullish(field)) {
          addMapping(fileSchema, field, {
            relationshipSchema: relationshipId,
            keyIndex: i,
            target: true,
          });
        }
      });
    }
  });
  return fieldMappings;
};

const isEmptyString = (string?: string) => string === undefined || string.trim().length === 0;

const findArrayDuplicates = (array: string[]) => array.filter((item, index) => array.indexOf(item) !== index);

const findDuplicatePropertyIndices = (properties: Property[]) => {
  const duplicateIndices: Record<string, boolean> = {};
  const propertyCounts: Record<string, number> = {};
  properties.forEach(({ property }) => {
    if (!isNullish(propertyCounts[property])) {
      propertyCounts[property] += 1;
    } else {
      propertyCounts[property] = 1;
    }
  });
  properties.forEach(({ property }, i) => {
    const propertyCount = propertyCounts[property];
    if (!isNullish(propertyCount) && propertyCount > 1) {
      duplicateIndices[i] = true;
    }
  });
  return duplicateIndices;
};

const getFileSchemaErrors = (fileSchema: FileSchema): FileSchemaError | null => {
  const { fields } = fileSchema;
  const errors: FileSchemaError = {};
  const duplicatedFields = findArrayDuplicates(fields.map((f) => f.name));
  fields.forEach((field, i) => {
    const { name } = field;
    if (isEmptyString(name)) {
      errors[i] = emptyFieldError;
    } else if (duplicatedFields.includes(name)) {
      errors[i] = duplicatedError;
    }
  });

  return Object.keys(errors).length > 0 ? errors : null;
};

const addPropertyError = <E extends SchemaError>(errors: E, index: number, message: ErrorModel) => {
  errors.properties = errors.properties ?? {};
  errors.properties[index] = errors.properties[index] ?? [];
  errors.properties[index].push(message);
};

const validateProperties = <E extends SchemaError>(errors: E, properties: Property[]) => {
  const duplicateIndices = findDuplicatePropertyIndices(properties);

  properties.forEach(({ property }, i) => {
    if (isEmptyString(property)) {
      addPropertyError(errors, i, emptyFieldError);
    } else if (!isNullish(duplicateIndices[i])) {
      addPropertyError(errors, i, duplicatedError);
    }
  });
};

export const getNodeSchemaErrors = (nodeSchema: NodeSchema): NodeSchemaError | null => {
  const { label, properties, key } = nodeSchema;
  const errors: NodeSchemaError = {};

  if (isEmptyString(label)) {
    errors.label = emptyFieldError;
  }
  validateProperties(errors, properties);
  const { properties: keyProperties } = key;
  if (
    keyProperties.length === 0 ||
    keyProperties.some((keyProperty) => !properties.find((property) => property.identifier === keyProperty))
  ) {
    errors.key = noKeyPropertyError;
  }
  return Object.keys(errors).length > 0 ? errors : null;
};

export const getRelationshipSchemaErrors = (relationSchema: RelationshipSchema): RelationshipSchemaError | null => {
  const { type, properties } = relationSchema;
  const errors: RelationshipSchemaError = {};
  if (isEmptyString(type)) {
    errors.type = emptyFieldError;
  }
  validateProperties(errors, properties);
  return Object.keys(errors).length > 0 ? errors : null;
};

const addMappingError = <E extends MappingError>(errors: E, index: number, message: ErrorModel) => {
  errors.mappings = errors.mappings ?? {};
  errors.mappings[index] = errors.mappings[index] ?? [];
  errors.mappings[index].push(message);
};

const validateMappings = <E extends MappingError, M extends MappingField[]>(errors: E, mappings: M) => {
  mappings.forEach(({ field }, i) => {
    if (isNullish(field)) {
      addMappingError(errors, i, nonSpecifiedError);
    }
  });
};

export const getNodeMappingErrors = (nodeMapping: NodeMapping): NodeMappingError | null => {
  const { fileSchema, mappings } = nodeMapping;
  const errors: NodeMappingError = {};

  if (isNullish(fileSchema)) {
    errors.fileSchema = nonSpecifiedError;
  }
  validateMappings(errors, mappings);
  return Object.keys(errors).length > 0 ? errors : null;
};

export const getRelationshipMappingErrors = (
  relationshipMapping: RelationshipMapping,
): RelationshipMappingError | null => {
  const { fileSchema, mappings, sourceMappings, targetMappings } = relationshipMapping;
  const errors: RelationshipMappingError = {};
  if (fileSchema === undefined) {
    errors.fileSchema = nonSpecifiedError;
  }
  validateMappings(errors, mappings);
  if (sourceMappings.length === 0 || sourceMappings.some(({ field }) => isEmptyString(field))) {
    errors.sourceMappings = nonSpecifiedError;
  }
  if (targetMappings.length === 0 || targetMappings.some(({ field }) => isEmptyString(field))) {
    errors.targetMappings = nonSpecifiedError;
  }
  return Object.keys(errors).length > 0 ? errors : null;
};

export const getDataModelErrors = (dataModel: DataModel_0_8_0 | DataModel_0_0_2): DataModelErrors | null => {
  const { fileModel, graphModel, mappingModel } = dataModel;
  const { fileSchemas } = fileModel;
  const { nodeSchemas, relationshipSchemas } = graphModel;
  const { nodeMappings, relationshipMappings } = mappingModel;
  const filenames = Object.keys(fileSchemas);
  const nodeIds = Object.keys(nodeSchemas);
  const relationshipIds = Object.keys(relationshipSchemas);

  const fileErrors: DataModelErrors['fileErrors'] = {};
  const nodeErrors: DataModelErrors['nodeErrors'] = {};
  const relationshipErrors: DataModelErrors['relationshipErrors'] = {};
  const errors: DataModelErrors = {
    fileErrors,
    nodeErrors,
    relationshipErrors,
  };
  const addFileErrors = (filename: string, newErrors: FileSchemaError): void => {
    const existingErrors = fileErrors[filename] ?? {};
    fileErrors[filename] = { ...existingErrors, ...newErrors };
  };
  const addNodeErrors = (nodeId: string, newErrors: NodeSchemaError | NodeMappingError): void => {
    const existingErrors = nodeErrors[nodeId] ?? {};
    nodeErrors[nodeId] = { ...existingErrors, ...newErrors };
  };
  const addRelationshipErrors = (
    relationshipId: string,
    newErrors: RelationshipSchemaError | RelationshipMappingError,
  ): void => {
    const existingErrors = relationshipErrors[relationshipId] ?? {};
    relationshipErrors[relationshipId] = { ...existingErrors, ...newErrors };
  };
  let hasError = false;

  for (const filename of filenames) {
    const fileSchema = fileSchemas[filename];
    const schemaErrors = !isNullish(fileSchema) ? getFileSchemaErrors(fileSchema) : null;
    if (!isNullish(schemaErrors)) {
      addFileErrors(filename, schemaErrors);
      hasError = true;
    }
  }
  for (const nodeId of nodeIds) {
    const nodeSchema = nodeSchemas[nodeId];
    const schemaErrors = !isNullish(nodeSchema) ? getNodeSchemaErrors(nodeSchema) : null;
    if (!isNullish(schemaErrors)) {
      addNodeErrors(nodeId, schemaErrors);
      hasError = true;
    }
    const nodeMapping = nodeMappings[nodeId];
    const mappingErrors = !isNullish(nodeMapping) ? getNodeMappingErrors(nodeMapping) : null;
    if (!isNullish(mappingErrors)) {
      addNodeErrors(nodeId, mappingErrors);
      hasError = true;
    }
  }
  for (const relationshipId of relationshipIds) {
    const relationshipSchema = relationshipSchemas[relationshipId];
    const schemaErrors = !isNullish(relationshipSchema) ? getRelationshipSchemaErrors(relationshipSchema) : null;
    if (!isNullish(schemaErrors)) {
      addRelationshipErrors(relationshipId, schemaErrors);
      hasError = true;
    }
    const relationshipMapping = relationshipMappings[relationshipId];
    const mappingErrors = !isNullish(relationshipMapping) ? getRelationshipMappingErrors(relationshipMapping) : null;
    if (!isNullish(mappingErrors)) {
      addRelationshipErrors(relationshipId, mappingErrors);
      hasError = true;
    }
  }

  return hasError ? errors : null;
};

export const isErrorModel = (value: unknown): value is ErrorModel =>
  typeof value === 'object' &&
  !isNull(value) &&
  Object.keys(value).length === 2 &&
  difference(['code', 'message'], Object.keys(value)).length === 0;

export const isErrorList = (value: unknown): value is ErrorList => {
  return (
    typeof value === 'object' &&
    !isNullish(value) &&
    Object.keys(value).length > 0 &&
    Object.values(value).filter((v) => !Array.isArray(v)).length === 0 &&
    Object.values(value).filter((v) => !every(v, isErrorModel)).length === 0
  );
};

export const isEntityModelComplete = <
  N extends keyof DataModelErrors['nodeErrors'],
  R extends keyof DataModelErrors['relationshipErrors'],
>(
  entityErrors: DataModelErrors['nodeErrors'][N] | DataModelErrors['relationshipErrors'][R] | undefined,
): boolean => {
  if (isNullish(entityErrors)) {
    return true;
  }
  for (const [, value] of Object.entries(entityErrors)) {
    if (isErrorModel(value)) {
      if (value.code === VALIDATION_ERROR_CODE.INCOMPLETE) {
        return false;
      }
    } else if (isErrorList(value)) {
      for (const [, list] of Object.entries(value)) {
        for (const error of list) {
          if (error.code === VALIDATION_ERROR_CODE.INCOMPLETE) {
            return false;
          }
        }
      }
    }
  }
  return true;
};

export const entityModelHasError = <
  N extends keyof DataModelErrors['nodeErrors'],
  R extends keyof DataModelErrors['relationshipErrors'],
>(
  entityErrors: DataModelErrors['nodeErrors'][N] | DataModelErrors['relationshipErrors'][R] | undefined,
): boolean => {
  if (isNullish(entityErrors)) {
    return false;
  }
  for (const [, value] of Object.entries(entityErrors)) {
    if (isErrorModel(value)) {
      if (value.code !== VALIDATION_ERROR_CODE.INCOMPLETE) {
        return true;
      }
    } else if (isErrorList(value)) {
      for (const [, list] of Object.entries(value)) {
        for (const error of list) {
          if (error.code !== VALIDATION_ERROR_CODE.INCOMPLETE) {
            return true;
          }
        }
      }
    }
  }
  return false;
};
