import { APP_SCOPE } from '@nx/constants';
import * as ImportShared from '@nx/import-shared';
import { createLogger } from '@nx/logger';
import type { RootState } from '@nx/state';
import { createDynamicBaseQuery, prepareHeaders, LEGACY_store as store } from '@nx/state';
import { createApi } from '@reduxjs/toolkit/query/react';

import { APP_VERSION } from '../../constants';
import { dataModelJsonFormatter } from '../../data-model/data-model-json-formatter';
import { generateVisualisationFromNodesAndRelationships } from '../../utils/import-model-utils';
import {
  buildDynamicDataSourceParamsPayloadByDataSourceConfig,
  getErrorMessage,
  getImportUrls,
  isApiDataSourceSchema,
  isMeta,
  isSpawnNewSourceSchemaJobResultData,
  mapFromApiTableSchemaToTableSchemaJsonStruct,
  sleep,
} from './utils';

const logger = createLogger(APP_SCOPE.import);

export type AuraConnectParams = {
  dbId: string;
  user: string;
  password: string;
};

const TAG = 'SourceSchema' as const;

export const sourceSchemaSlice = createApi({
  tagTypes: [TAG],
  reducerPath: 'sourceSchema',
  baseQuery: createDynamicBaseQuery(
    (state: RootState) => getImportUrls(state).rootEndpoint,
    {
      prepareHeaders,
    },
    (): RootState => store.getState(),
  ),
  endpoints: (builder) => ({
    fetchDataSourceSchema: builder.query<
      ImportShared.ApiDataSourceSchema,
      // TODO: remove useDynamicDataSource once dynamic data sources are fully released
      { dataSource: ImportShared.DataSourceConfig; useDynamicDataSource?: boolean }
    >({
      queryFn: async (_arg, _api, _extraOptions, fetchWithBaseQuery) => {
        const { dataSource, useDynamicDataSource = false } = _arg;
        const emptyRecord: Record<string, ImportShared.FormFieldValue | undefined> = {};
        const fields: Record<string, ImportShared.FormFieldValue | undefined> = dataSource.fields.reduce(
          (acc, field) => {
            acc[field.name] = field.value;
            return acc;
          },
          emptyRecord,
        );
        const params: ImportShared.NewDataSourceConnectParams = {
          driver: dataSource.type,
          ...fields,
        };
        // https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#performing-multiple-requests-with-a-single-query
        // This is a workaround to perform multiple requests with a single query.

        // https://stackoverflow.com/a/73982276/10890476
        // The types for the redux-toolkit fetchWithBQ are defined in a strict way where the JSON data
        // that is returned from the server has type unknown (rather than defining it loosely with any).
        // And fetchWithBQ is defined as a function that returns a Promise<QueryReturnValue<T, E, R>>,
        // which is not TypeScript generic.
        // So you do need to make an as assertion or type guard.
        const spawnNewSourceSchemaJobResult = await fetchWithBaseQuery({
          url: '/v2/sources/schema',
          method: 'POST',
          body: useDynamicDataSource
            ? { dataSource: buildDynamicDataSourceParamsPayloadByDataSourceConfig(dataSource) }
            : { dataSourceCredentials: params },
        });
        const { data: spawnNewSourceSchemaJobResultData, meta: spawnNewSourceSchemaJobResultMeta } =
          spawnNewSourceSchemaJobResult;
        if (!isSpawnNewSourceSchemaJobResultData(spawnNewSourceSchemaJobResultData)) {
          return {
            error: getErrorMessage(
              spawnNewSourceSchemaJobResult.error ?? 'No data returned from spawning a new source schema job',
              isMeta(spawnNewSourceSchemaJobResultMeta) ? spawnNewSourceSchemaJobResultMeta.response.status : undefined,
            ),
          };
        }
        const { id: jobId } = spawnNewSourceSchemaJobResultData;
        let isJobCompleted = false;
        const startTime = Date.now();
        let getSourceSchemaJobResultData: unknown;
        let getSourceSchemaJobResultError: unknown;
        let getSourceSchemaJobResultMeta: { response: { status: number } } | undefined;
        while (!isJobCompleted) {
          // Timeout after 10 minutes
          if (Date.now() - startTime > 1000 * 60 * 10) {
            return {
              // 408 indicates that the server has terminated a connection due to a slow client request.
              // But in this case, it is not a client request that is slow, but the job is taking too long to complete.
              // So we should not use 408, but 504 (Gateway Timeout) instead.
              error: getErrorMessage('Timeout waiting for job to complete', 504),
            };
          }

          // eslint-disable-next-line no-await-in-loop
          const getSourceSchemaJobResult = await fetchWithBaseQuery({
            url: `/v2/sources/schema/${jobId}`,
            method: 'GET',
          });
          const { data, error, meta } = getSourceSchemaJobResult;
          if (!isMeta(meta)) {
            return { error: getErrorMessage('Unable to get job status') };
          }
          getSourceSchemaJobResultData = data;
          getSourceSchemaJobResultError = error;
          getSourceSchemaJobResultMeta = meta;

          isJobCompleted = getSourceSchemaJobResultMeta.response.status !== 204;
          // eslint-disable-next-line no-await-in-loop
          await sleep(1000);
        }
        return isApiDataSourceSchema(getSourceSchemaJobResultData)
          ? { data: getSourceSchemaJobResultData }
          : { error: getErrorMessage(getSourceSchemaJobResultError, getSourceSchemaJobResultMeta?.response.status) };
      },
    }),
    fetchCandidateGraph: builder.query<
      { dataModel: ImportShared.DataModelJsonStruct; visualisation: ImportShared.VisualisationState },
      {
        tableSchemas: ImportShared.TableSchemaJsonStruct[];
        type: ImportShared.DataSourceLocation;
        options?: { genAI?: boolean };
      }
    >({
      query: ({ tableSchemas, type, options }) => {
        const apiTableSchemas: ImportShared.ApiTableSchema[] = tableSchemas.map(
          (tableSchema): ImportShared.ApiTableSchema => ({
            name: tableSchema.name,
            fields: tableSchema.fields.map((field) => ({
              name: field.name,
              size: 0,
              // @TODO: This should be always here but in table schema it is optional
              // until we migrated type to rawType
              rawType: 'rawType' in field ? field.rawType : '',
              recommendedType: field.recommendedType,
              supportedTypes: 'supportedTypes' in field ? field.supportedTypes : undefined,
            })),
            primaryKeys: tableSchema.primaryKeys ?? [],
            foreignKeys: tableSchema.foreignKeys ?? [],
          }),
        );

        const body: ImportShared.CandidateGraphRequestBody = {
          schema: {
            type: type ?? '',
            tableSchemas: apiTableSchemas,
          },
          ai: options?.genAI === true,
        };

        return {
          url: '/v1/sources/candidate',
          method: 'POST',
          body: body,
        };
      },
      transformResponse: (candidateGraph: ImportShared.ApiCandidateGraphResponse) => {
        const { dataSourceSchema } = candidateGraph.graphMappingRepresentation;
        if (dataSourceSchema.type !== 'cloud' && dataSourceSchema.type !== 'local') {
          throw new Error('Candidate graph has empty or invalid data source location');
        }
        const tableSchemas = dataSourceSchema.tableSchemas.map(
          mapFromApiTableSchemaToTableSchemaJsonStruct(dataSourceSchema.type === 'local'),
        );

        const dataModel: ImportShared.DataModelJsonStruct = {
          ...candidateGraph,
          version: APP_VERSION,
          graphMappingRepresentation: {
            ...candidateGraph.graphMappingRepresentation,
            dataSourceSchema: {
              type: dataSourceSchema.type,
              tableSchemas,
            },
          },
        };

        const { nodeObjectTypes, relationshipObjectTypes } =
          dataModelJsonFormatter.fromJsonStruct(dataModel).graphSchemaRepresentation;
        const visualisation = generateVisualisationFromNodesAndRelationships(nodeObjectTypes, relationshipObjectTypes);

        return { dataModel, visualisation };
      },
    }),
    fetchDataSourceDeclarations: builder.query<ImportShared.DataSourceDefinition[], void>({
      query: () => {
        return {
          url: '/v1/sources/declaration',
          method: 'GET',
        };
      },
      transformResponse: (data: ImportShared.ApiDataSourceDeclarations) => {
        if ('sources' in data) {
          const { sources } = data;
          if (!Array.isArray(sources)) {
            throw new Error('Invalid data source declarations: "Sources" is not an array');
          }
          // Return all valid sources and log invalid ones to make sure it doesn't break the app usability
          const validSources: ImportShared.DataSourceDefinition[] = [];
          sources.forEach((source) => {
            const { valid, errors: error } = ImportShared.validateDataSourceDefinition(source);
            if (valid) {
              validSources.push(source);
            } else {
              logger.error('Invalid data source declaration', source, error);
            }
          });
          // Change default field type from string to relevant type depending on the type
          validSources.forEach((source) => {
            source.sections.forEach((section) => {
              section.fields.forEach((field) => {
                if (field.default === undefined) {
                  return field;
                }
                let defaultField: ImportShared.FormFieldValue;
                if (field.type === 'integer') {
                  defaultField = !isNaN(Number(field.default)) ? Number(field.default) : 0;
                } else if (field.type === 'boolean') {
                  defaultField = Boolean(field.default);
                } else {
                  defaultField = field.default;
                }
                const newField: ImportShared.FieldDefinition = {
                  ...field,
                  default: defaultField,
                };
                return newField;
              });
            });
          });
          return validSources;
        }
        throw new Error(`Invalid data source declarations: Doesn't contain sources`);
      },
    }),
  }),
});

export const {
  useLazyFetchDataSourceSchemaQuery,
  useLazyFetchCandidateGraphQuery,
  useFetchDataSourceDeclarationsQuery,
} = sourceSchemaSlice;
