import type { ConnectionDescriptor, DiscoveryResult, SSOProvider4dot4Format, SSOProviderOriginal } from '@nx/constants';
import { DiscoveryFetchError, DiscoveryNoProviderError, DiscoverySuccess } from '@nx/constants';
import * as StdLib from '@nx/stdlib';
import { isNullish, validate } from '@nx/stdlib';
import type { StaticDecode } from '@sinclair/typebox';
import { Type } from '@sinclair/typebox';

import { authLog } from './auth-log';

const mandatoryKeysForSSOProviders = ['id', 'name', 'auth_flow', 'params', 'auth_endpoint'] as const;

const mandatoryKeysForSSOProviderParams = ['client_id', 'response_type', 'scope'] as const;

export const getValidSSOProviders = (
  discoveredSSOProviders?: Partial<SSOProviderOriginal>[],
): SSOProviderOriginal[] => {
  if (!discoveredSSOProviders) {
    return [];
  }

  if (!Array.isArray(discoveredSSOProviders)) {
    authLog(`Discovered SSO providers should be a list, got ${String(discoveredSSOProviders)}`, 'warn');
  }

  if (discoveredSSOProviders.length === 0) {
    authLog('List of discovered SSO providers was empty', 'warn');
    return [];
  }

  const validSSOProviders = discoveredSSOProviders.filter((provider) => {
    const missingKeys = mandatoryKeysForSSOProviders.filter(
      (key) => !Object.prototype.hasOwnProperty.call(provider, key),
    );
    if (missingKeys.length !== 0) {
      authLog(
        `Dropping invalid discovered sso provider with id: "${provider.id}", missing key(s) ${missingKeys.join(', ')}`,
        'warn',
      );
      return false;
    }

    const missingParamKeys = mandatoryKeysForSSOProviderParams.filter(
      (key) => !Object.prototype.hasOwnProperty.call(provider.params, key),
    );
    if (missingParamKeys.length !== 0) {
      authLog(
        `Dropping invalid discovered SSO provider with id: "${
          provider.id
        }", missing params key(s) ${missingParamKeys.join(', ')}`,
      );
      return false;
    }

    return true;
  });

  authLog('Checked SSO providers');
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return validSSOProviders.map((ssoProvider) => ({
    // visibility was introduced in 5.14.0 and defaults to true
    visible: true,
    ...ssoProvider,
  })) as SSOProviderOriginal[];
};

const prepareOtherDataDiscovered = (result: Record<string, unknown>): Record<string, unknown> => {
  const otherDataDiscovered = result;
  delete otherDataDiscovered.auth_config;
  delete otherDataDiscovered.sso_providers;
  return otherDataDiscovered;
};

export const transform = (
  toTransformSSOProviders: Partial<SSOProvider4dot4Format>[],
): Partial<SSOProviderOriginal>[] => {
  return toTransformSSOProviders.map((provider): Partial<SSOProviderOriginal> => {
    const tmpProvider = {
      ...provider,
      params: {
        ...provider.params,
        redirect_uri: provider.redirect_uri,
      },
    };
    if (isNullish(tmpProvider.params.redirect_uri)) {
      delete tmpProvider.params.redirect_uri;
    }
    if (Object.keys(tmpProvider.params).length === 0) {
      // @ts-ignore For some reason TS doesn't realize that params is already optional.
      delete tmpProvider.params;
    }
    delete tmpProvider.redirect_uri;
    // @ts-ignore incompatible client_id type
    return tmpProvider;
  });
};

export const fetchDiscoveryData = async (url: string): Promise<DiscoveryResult> => {
  try {
    const response = await fetch(url, {
      method: 'get',
      headers: {
        Accept: 'application/json',
      },
    });

    if (response.ok) {
      const result: unknown = await response.json();

      if (typeof result !== 'object' || isNullish(result)) {
        const noProviderMsg = `No SSO providers found on endpoint: ${url}`;
        authLog(noProviderMsg);
        return {
          status: DiscoveryNoProviderError,
          message: noProviderMsg,
          otherDataDiscovered: {},
          SSOProviders: [],
        };
      }

      const DiscoveryDataSchema = Type.Object({
        auth_config: Type.Optional(Type.Object({ oidc_providers: Type.Unknown() })),
        sso_providers: Type.Optional(Type.Unknown()),
        ssoproviders: Type.Optional(Type.Unknown()),
        ssoProviders: Type.Optional(Type.Unknown()),
        neo4j_version: Type.Optional(Type.String()),
        neo4j_edition: Type.Optional(Type.String()),
      });

      let validatedResult: StaticDecode<typeof DiscoveryDataSchema>;
      try {
        validatedResult = validate(DiscoveryDataSchema, result);
      } catch {
        validatedResult = {};
      }

      const ssoProviderField: unknown = !isNullish(validatedResult.auth_config)
        ? validatedResult.auth_config.oidc_providers
        : (validatedResult.sso_providers ?? validatedResult.ssoproviders ?? validatedResult.ssoProviders);

      if (isNullish(ssoProviderField)) {
        const noProviderMsg = `No SSO providers found on endpoint: ${url}`;
        authLog(noProviderMsg);
        return {
          status: DiscoveryNoProviderError,
          message: noProviderMsg,
          otherDataDiscovered: prepareOtherDataDiscovered(validatedResult),
          SSOProviders: [],
          neo4jVersion: validatedResult.neo4j_version,
          neo4jEdition: validatedResult.neo4j_edition,
        };
      }

      const unifiedSSOProviders = !isNullish(validatedResult.auth_config)
        ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          transform(ssoProviderField as Partial<SSOProvider4dot4Format>[])
        : ssoProviderField;
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const SSOProviders = getValidSSOProviders(unifiedSSOProviders as Partial<SSOProviderOriginal>[]);
      if (SSOProviders.length === 0) {
        authLog(`None of the SSO providers found at ${url} were valid`);
      } else {
        authLog(`Found SSO providers with ids: ${SSOProviders.map((p) => p.id).join(', ')} on ${url}`);
      }
      return {
        status: DiscoverySuccess,
        message: DiscoverySuccess,
        otherDataDiscovered: prepareOtherDataDiscovered(validatedResult),
        SSOProviders,
        neo4jVersion: validatedResult.neo4j_version,
        neo4jEdition: validatedResult.neo4j_edition,
      };
    }

    const invalidResponseMsg = `Invalid response for SSO provider discovery attempt, endpoint: ${url}`;
    const noHttpPrefixMessage = url.toLowerCase().startsWith('http')
      ? ''
      : 'Double check that the url is a valid url (including HTTP(S)).';
    const noJsonSuffixMessage = url.toLowerCase().endsWith('.json')
      ? ''
      : 'Double check that the discovery url returns a valid JSON file.';

    const messages = [invalidResponseMsg, noHttpPrefixMessage, noJsonSuffixMessage];
    messages.forEach((m) => authLog(m));

    return {
      status: DiscoveryFetchError,
      message: invalidResponseMsg,
      otherDataDiscovered: {},
      SSOProviders: [],
    };
  } catch (err) {
    const errMsg = `SSO provider discovery attempt failed on endpoint: ${url} error: ${String(err)}`;
    authLog(errMsg);
    return {
      status: DiscoveryFetchError,
      message: errMsg,
      otherDataDiscovered: {},
      SSOProviders: [],
    };
  }
};

export function discoveryToConnectionDetails(
  connectURL: StdLib.URLs.Neo4jURL,
  discovery: DiscoveryResult,
  instanceName?: string | null,
  dbName?: string,
) {
  let credentials: ConnectionDescriptor = {
    type: 'basic',
    username: connectURL.username || 'neo4j',
  };

  if (discovery.SSOProviders.length > 0) {
    credentials = {
      type: 'sso',
      providers: discovery.SSOProviders,
    };
  }

  if (discovery.seamless === true) {
    credentials = {
      type: 'seamless',
    };
  }

  return {
    ...(!StdLib.isNullish(instanceName) ? { instanceName } : {}),
    ...(dbName !== undefined ? { dbName } : {}),
    url: connectURL.toDriverUrl(),
    credentials,
  };
}

export const useFetchDiscoveryData = () => {
  return {
    fetchDiscoveryData: (url: string) => fetchDiscoveryData(url),
  };
};
