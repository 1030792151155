import type { DatabaseResponse, DatabaseSummaryResponse } from '../console.api.types';
import type { AuraDatabase, AuraDatabaseSummary } from '../console.types';

export const transformDatabaseSummaryResponse = (response: DatabaseSummaryResponse): AuraDatabaseSummary[] =>
  response.aura_databases.map((database) => ({
    id: database.aura_database_id,
    instanceId: database.instance_id,
  }));

export const transformDatabaseResponse = (response: DatabaseResponse): AuraDatabase => ({
  id: response.id,
  instanceId: response.instance_id,
  name: response.name,
  status: response.status,
  relationships: response.relationships,
  nodes: response.nodes,
});
