import { Button, TextLink, Tooltip, Typography } from '@neo4j-ndl/react';
import { InformationCircleIconOutline } from '@neo4j-ndl/react/icons';
import { ACTION, TIER, consoleApi, usePermissions } from '@nx/state';
import type { Instance } from '@nx/state';
import { PermissionTooltip } from '@nx/ui';
import { useState } from 'react';

import { isSecurityLogsEnabled } from './entities/helpers';
import { LogTable } from './logs-table';
import RequestLogsModal from './modal';

type LogsSectionProps = {
  instance: Instance;
};

const FreeTierLogsSection = () => {
  return (
    <div className="flex h-full flex-col gap-4">
      <div className="flex justify-between gap-6">
        <Typography variant="body-medium" className="text-base">
          Logs are only available for AuraDB Professional instances. Clone to, or create one of these instances to get
          logs.
          <br />
          <TextLink
            as="a"
            href="https://neo4j.com/docs/aura/auradb/managing-databases/database-actions/#_cloning_an_instance"
            htmlAttributes={{
              rel: 'noreferrer',
              target: '_blank',
            }}
          >
            Learn more
          </TextLink>
        </Typography>
      </div>
    </div>
  );
};

const NonFreeLogsSection = ({ instance }: LogsSectionProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { data: logs = [], isError, isLoading } = consoleApi.useListLogsQuery(instance.id);

  const { isAllowed } = usePermissions();
  const allowCreateLog = isAllowed(ACTION.CREATE, `namespaces/${instance.projectId}/databases/${instance.id}/logs`);
  const allowDownload = isAllowed(ACTION.READ, `namespaces/${instance.projectId}/databases/${instance.id}/logs/*`);
  const logTableProps = {
    instance,
    logs,
    isLoading,
    isError,
    allowDownload,
  };

  return (
    <div className="flex h-full flex-col gap-4">
      <div className="flex flex-row justify-between gap-6">
        <div className="flex items-center gap-1">
          <Typography variant="body-medium" className="text-neutral-text-weaker flex-wrap">
            {isSecurityLogsEnabled(instance) ? (
              <p>Query and security logs up to 30 days old can be requested.</p>
            ) : (
              <p>Query logs up to 30 days old can be requested.</p>
            )}
          </Typography>
          <Tooltip type="simple">
            <Tooltip.Trigger hasButtonWrapper>
              <InformationCircleIconOutline className="size-token-6" />
            </Tooltip.Trigger>
            <Tooltip.Content className="w-96">
              <p>Requested logs will appear here for up to 7 days, at which point they will expire and be removed.</p>
              <p className="my-2">All times presented here are in UTC.</p>
            </Tooltip.Content>
          </Tooltip>
        </div>
        <PermissionTooltip hasPermission={allowCreateLog} hasButtonWrapper className="shrink-0">
          <Button size="small" isDisabled={!allowCreateLog} onClick={() => setModalOpen(true)}>
            Request Logs
          </Button>
        </PermissionTooltip>
      </div>
      <LogTable {...logTableProps} />
      {modalOpen && (
        <RequestLogsModal
          instance={instance}
          onClose={() => setModalOpen(false)}
          onSuccess={() => setModalOpen(false)}
        />
      )}
    </div>
  );
};

export const LogsSection = ({ instance }: LogsSectionProps) => {
  if (instance.tier === TIER.FREE) {
    return <FreeTierLogsSection />;
  }

  return <NonFreeLogsSection instance={instance} />;
};
